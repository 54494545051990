/* tslint:disable */
import { Component, OnInit } from '@angular/core';
import { timeStamp } from 'console';
import { ConfirmationService, GalleriaThumbnails, MessageService } from 'primeng';
import { TransactionService } from '../loginpage/Transaction.service';
import { addlncomponent, addlncompsforgrid, componenttypewithcodeanddesc, containerTypeCode } from '../loginpage/transactions';

@Component({
  selector: 'app-componentadmin',
  templateUrl: './componentadmin.component.html',
  styleUrls: ['./componentadmin.component.css'],
  providers:[MessageService,ConfirmationService]
})
export class ComponentadminComponent implements OnInit {

  public cols:any[];
  componentgrid:addlncompsforgrid[]=[];
  componentdetails:addlncomponent;
  componentdetailsforedit:addlncomponent;

  compAddEnable:boolean;
  compAddDetailsEnable:boolean;
  compDetailsEnable:boolean;
  enableeditgetdetails:boolean;
  enableeditcompdetails:boolean;


  comp_type:containerTypeCode[]; // variable to get component type from API
  comp_type_and_desc:componenttypewithcodeanddesc[];
  selected_cntr_type_and_desc:componenttypewithcodeanddesc;
  edit_selected_cntr_type_and_desc:componenttypewithcodeanddesc;


  edit_measure_comp_addc='';
  edit_measure_comp_display='';
  edit_measure_comp_edit='';

  unitom='E';

  current_region_comp='';
  cdsid_comp='';
  current_region_code_comp='';

  reg_uom = false;

  current_routeid=0;

  contBaseInValid = false;
  contBaseInValidReason: string = null;
  contSuffixInValid = false;
  contSuffixInValidReason: string = null;
  contDescInValid = false;
  contDescInValidReason: string = null;
  qntPerMinShipUnitInValid = false;
  qntPerMinShipUnitInValidReason: string = null;
  nestFoldHeightInValid = false;
  nestFoldHeightInValidReason: string = null;
  compWeightInValid = false;
  compWeightInValidReason: string = null;
  compDimLenInValid = false;
  compDimLenInValidReason: string = null;
  compDimWidInValid = false;
  compDimWidInValidReason: string = null;
  compDimHghtInValid = false;
  compDimHghtInValidReason: string = null;
  compRetTypeInValid = false;
  compRetTypeInValidReason: string = null;


  constructor(private transactionService:TransactionService,private messageService:MessageService,private confirmationService:ConfirmationService) { }

  ngOnInit(): void
  {


    this.current_region_comp=this.transactionService.getregion();
    this.cdsid_comp=this.transactionService.getcdsid();
    this.current_region_code_comp=this.transactionService.getregioncode(this.current_region_comp)
    this.loadcomponenttype();
    this.populategrid();
    this.componentdetailsinit();
    this.expPckAidsInputValidationInit();
    this.compAddDetailsEnable=false;
    this.compAddEnable=true;
    this.compDetailsEnable=false;
    this.enableeditgetdetails=false;
    this.enableeditcompdetails=false;


    this.current_routeid=106;

console.log(this.current_routeid)


if(this.current_region_comp=='Asia Pacific & Africa')
{
  sessionStorage.setItem('aprouteid', JSON.stringify(this.current_routeid));
}

else if(this.current_region_comp=='Europe')
{
  sessionStorage.setItem('eurouteid', JSON.stringify(this.current_routeid));
}

else if (this.current_region_comp=='North America')

{

  sessionStorage.setItem('narouteid', JSON.stringify(this.current_routeid));
}

else if(this.current_region_comp=='South America')
{
  sessionStorage.setItem('sarouteid', JSON.stringify(this.current_routeid));
}

this.transactionService.get_region_details(this.current_region_code_comp).subscribe(uomtype=>{

  this.unitom=uomtype.uom;

  if(this.unitom=='E')
  {
    this.edit_measure_comp_addc='lbs/inch';
    console.log('UOMlb',this.unitom,this.edit_measure_comp_addc);
  }
  else
  {
    this.edit_measure_comp_addc='kg/cm'
    console.log('UOMkg',this.unitom,this.edit_measure_comp_addc);
  }

  console.log('UOMREG',this.unitom,this.edit_measure_comp_addc);
});

  }

  componentdetailsinit()
  {
    this.componentdetails=
    {
      compid:0,
      comp_base:'',
      comp_suffix:'',
      comp_desc:'',
      comp_type:'',
      comp_ret_type:'E',
      qt_per_min_su:0,
      updated_by:'',
      updated_dt:'',
      comp_l:0,
      comp_w:0,
      comp_h:0,
      comp_wt:0,
      nestedfoldedht:0,
      comp_type_desc:''
    }
  }

  componentdetailsforeditinit()
  {
    this.componentdetailsforedit=
    {
      compid:0,
      comp_base:'',
      comp_suffix:'',
      comp_desc:'',
      comp_type:'',
      comp_ret_type:'E',
      qt_per_min_su:0,
      updated_by:'',
      updated_dt:'',
      comp_l:0,
      comp_w:0,
      comp_h:0,
      comp_wt:0,
      nestedfoldedht:0,
      comp_type_desc:''
    }
  }
  expPckAidsInputValidationInit(){
    this.contBaseInValid = false;
    this.contBaseInValidReason= null;
    this.contSuffixInValid = false;
    this.contSuffixInValidReason = null;
    this.contDescInValid = false;
    this.contDescInValidReason = null;
    this.qntPerMinShipUnitInValid = false;
    this.qntPerMinShipUnitInValidReason = null;
    this.nestFoldHeightInValid = false;
    this.nestFoldHeightInValidReason = null;
    this.compWeightInValid = false;
    this.compWeightInValidReason = null;
    this.compDimLenInValid = false;
    this.compDimLenInValidReason = null;
    this.compDimWidInValid = false;
    this.compDimWidInValidReason = null;
    this.compDimHghtInValid = false;
    this.compDimHghtInValidReason = null;
    this.compRetTypeInValid = false;
    this.compRetTypeInValidReason = null;
  }


  loadcomponenttype()
  {
    this.transactionService.getContainerTypeCode().subscribe(comptypes=>{
      this.comp_type=comptypes;
      console.log(this.comp_type);

      const tempcompstypeanddesc:componenttypewithcodeanddesc[]=[];
      for(const i of this.comp_type)
      {
        if(['06','07','08','09','10','11','12','13','14','15'].includes(i.cd_cntr_typ.toString()))
        tempcompstypeanddesc.push({cd_cntr_typ:i.cd_cntr_typ,cntr_typ_desc:i.cntr_typ_desc,cntr_typ_and_desc:i.cd_cntr_typ+'-'+i.cntr_typ_desc,typ_no:i.typ_no})
      }

      this.comp_type_and_desc=tempcompstypeanddesc;
      this.selected_cntr_type_and_desc=this.comp_type_and_desc[0];
    })
  }


  populategrid()
  {
    this.transactionService.populateAddlnComponentGrid().subscribe(addlncomps=>{
      this.componentgrid=JSON.parse(JSON.stringify(addlncomps));

      for(let c=0;c<this.componentgrid.length;c++)
      {
        if(this.componentgrid[c].comp_suffix!=null)
        {
          this.componentgrid[c].comp_bs=this.componentgrid[c].comp_base+'-'+this.componentgrid[c].comp_suffix;
        }
        else
        {
          this.componentgrid[c].comp_bs=this.componentgrid[c].comp_base;
        }
        this.componentgrid[c].comp_lwh=this.componentgrid[c].comp_l+'x'+this.componentgrid[c].comp_w+'x'+this.componentgrid[c].comp_h;

      }
      console.log('componentgrid',this.componentgrid);
    })

    this.cols=[
      { field: 'comp_bs', header:'Component Base-Suffix'},
     // { field: 'comp_suffix', header:'Component Suffix'},
      { field: 'comp_desc', header:'Description'},
      { field: 'comp_type_desc', header:'Component Type'},
      { field: 'comp_ret_type', header:'E/R'},
      { field: 'comp_lwh', header:'Length x Width x Height'} ,
      { field: 'qt_per_min_su', header:'Quantity Per Min Shipping Unit'} ,
     // { field: 'comp_w', header:'Width'} ,
     // { field: 'comp_h', header:'Height'} ,
      { field: 'comp_wt', header:'Component Weight'},
      { field: 'nestedfoldedht', header:'Nested Folded Height'},
      // { field: 'nestedfoldedht', header:'Nested Folded Height'}
  ];
  }


  exportExcel() {
    import('xlsx').then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.componentgrid);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, 'Component Table');
    });
}

saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
}


  deletecomponentfromgrid(comptodelete:addlncompsforgrid)
  {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete component ' + comptodelete.comp_base +' - '+comptodelete.comp_suffix+ '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () =>
      {
          const tempaddlncomp:addlncomponent=
          {
            compid:comptodelete.compid,
            comp_base:comptodelete.comp_base,
            comp_suffix:comptodelete.comp_suffix,
            comp_desc:comptodelete.comp_desc,
            comp_type:comptodelete.comp_type,
            comp_ret_type:comptodelete.comp_ret_type,
            qt_per_min_su:comptodelete.qt_per_min_su,
            updated_by:comptodelete.updated_by,
            updated_dt:comptodelete.updated_dt,
            comp_l:comptodelete.comp_l,
            comp_w:comptodelete.comp_w,
            comp_h:comptodelete.comp_h,
            comp_wt:comptodelete.comp_wt,
            nestedfoldedht:comptodelete.nestedfoldedht,
            comp_type_desc:comptodelete.comp_type_desc
          }
          console.log('comptodelete',JSON.stringify(tempaddlncomp));
          this.transactionService.deleteaddlncomp(comptodelete.compid).subscribe(deletecompresp=>{
          const deletecompstatus=deletecompresp;
          console.log('Success',deletecompstatus);

          if(deletecompstatus=='deleted')
          {
            this.messageService.add({severity:'success', summary:'Success', detail:'Additional Component Deleted Successfully'});
            this.populategrid();
            this.compAddEnable=true;
            this.compDetailsEnable=false;
            this.compAddDetailsEnable=false;
            this.enableeditcompdetails=false;
            this.enableeditgetdetails=false;

          }
          else
          {
            this.messageService.add({severity:'error', summary: 'Error', detail: 'Component Not Deleted,Please Try Again'});
          }
        });
    }
    });
  }

  addcomponent()
  {
    this.compAddDetailsEnable=true;
    this.compAddEnable=false;
    this.enableeditgetdetails=false;
    this.enableeditcompdetails=false;
    this.compDetailsEnable=false;

    if(this.unitom=='E')
    {
      this.edit_measure_comp_addc='lbs/inch';
    }
    else
    {
      this.edit_measure_comp_addc='kg/cm'
    }

    console.log('UOMREF',this.unitom, this.edit_measure_comp_addc)

    this.expPckAidsInputValidationInit();

  }


  on_change_measure_comp_addc()
  {
    console.log(this.edit_measure_comp_addc);
    console.log(this.componentdetails);

    if(this.unitom=='E')
    {
      this.edit_measure_comp_addc='lbs/inch';
    }
    else
    {
      this.edit_measure_comp_addc='kg/cm'
    }

  //   if(this.edit_measure_comp_addc=="lbs/inch")
  //   {
  //   if(this.componentdetails.comp_l!=null)
  //   {
  //     this.componentdetails.comp_l=Math.round((0.393701*this.componentdetails.comp_l)*1000)/1000;

  //   }
  //   if(this.componentdetails.comp_w!=null)
  //   {
  //     this.componentdetails.comp_w=Math.round((0.393701*this.componentdetails.comp_w)*1000)/1000;

  //   }
  //   if(this.componentdetails.comp_h!=null)
  //   {
  //     this.componentdetails.comp_h=Math.round((0.393701*this.componentdetails.comp_h)*1000)/1000;

  //   }
  //   if(this.componentdetails.nestedfoldedht!=null)
  //   {
  //     this.componentdetails.nestedfoldedht=Math.round((0.393701*this.componentdetails.nestedfoldedht)*1000)/1000;

  //   }
  //   if(this.componentdetails.comp_wt!=null)
  //   {
  //     this.componentdetails.comp_wt=Math.round((2.20462*this.componentdetails.comp_wt)*1000)/1000;

  //   }
  // }


  //   else if(this.edit_measure_comp_addc=="kg/cm")
  //   {

  //     if(this.componentdetails.comp_l!=null)
  //   {
  //     this.componentdetails.comp_l=Math.round((2.54*this.componentdetails.comp_l)*1000)/1000;

  //   }
  //   if(this.componentdetails.comp_w!=null)
  //   {
  //     this.componentdetails.comp_w=Math.round((2.54*this.componentdetails.comp_w)*1000)/1000;

  //   }
  //   if(this.componentdetails.comp_h!=null)
  //   {
  //     this.componentdetails.comp_h=Math.round((2.54*this.componentdetails.comp_h)*1000)/1000;

  //   }
  //   if(this.componentdetails.nestedfoldedht!=null)
  //   {
  //     this.componentdetails.nestedfoldedht=Math.round((2.54*this.componentdetails.nestedfoldedht)*1000)/1000;

  //   }
  //   if(this.componentdetails.comp_wt!=null)
  //   {
  //     this.componentdetails.comp_wt=Math.round((0.453592*this.componentdetails.comp_wt)*1000)/1000;

  //   }
  //   }

  }

  saveaddcomp()
  {
    this.componentdetails.comp_type_desc=this.selected_cntr_type_and_desc.cntr_typ_desc;
    this.componentdetails.comp_type=String(this.selected_cntr_type_and_desc.cd_cntr_typ);
    this.componentdetails.updated_by=this.transactionService.getcdsid();
    this.componentdetails.updated_dt=new Date().toISOString().split('T')[0];


    if(this.unitom=='E')
    {
      this.edit_measure_comp_addc='lbs/inch';
    }
    else
    {
      this.edit_measure_comp_addc='kg/cm'
    }

  //   if(this.edit_measure_comp_addc=="kg/cm")
  //   {
  //   if(this.componentdetails.comp_l!=null)
  //   {
  //     this.componentdetails.comp_l=Math.round((0.393701*this.componentdetails.comp_l)*1000)/1000;

  //   }
  //   if(this.componentdetails.comp_w!=null)
  //   {
  //     this.componentdetails.comp_w=Math.round((0.393701*this.componentdetails.comp_w)*1000)/1000;

  //   }
  //   if(this.componentdetails.comp_h!=null)
  //   {
  //     this.componentdetails.comp_h=Math.round((0.393701*this.componentdetails.comp_h)*1000)/1000;

  //   }
  //   if(this.componentdetails.nestedfoldedht!=null)
  //   {
  //     this.componentdetails.nestedfoldedht=Math.round((0.393701*this.componentdetails.nestedfoldedht)*1000)/1000;

  //   }
  //   if(this.componentdetails.comp_wt!=null)
  //   {
  //     this.componentdetails.comp_wt=Math.round((2.20462*this.componentdetails.comp_wt)*1000)/1000;

  //   }
  // }

    console.log('In save of add component',this.componentdetails);

    this.addCompBaseValidation();
    this.addCompSuffixValidation();
    this.addCompDescValidation();
    /* this.addCompDimHghtValidation();
    this.addCompDimLenValidation();
    this.addCompDimWidValidation();
    this.addCompWeightValidation();
    this.addNestFoldHeightValidation(); */
    this.addQntPerMinSupValidation();
    this.addCompRetTypeValidation();

    if(!this.contBaseInValid && !this.contDescInValid &&  !this.compRetTypeInValid && !this.qntPerMinShipUnitInValid)
    // !this.compDimLenInValid && !this.compDimHghtInValid && !this.compDimWidInValid && !this.compWeightInValid && !this.nestFoldHeightInValid )
    {
      if(this.componentdetails.comp_l==null || this.componentdetails.comp_l==undefined)
      {
       this.componentdetails.comp_l=0;
      }
      if(this.componentdetails.comp_w==null || this.componentdetails.comp_w==undefined)
      {
       this.componentdetails.comp_w=0;
      }
      if(this.componentdetails.comp_h==null || this.componentdetails.comp_h==undefined)
      {
       this.componentdetails.comp_h=0;
      }
      if(this.componentdetails.comp_wt==null || this.componentdetails.comp_wt==undefined)
      {
       this.componentdetails.comp_wt=0;
      }
      if(this.componentdetails.nestedfoldedht==null || this.componentdetails.nestedfoldedht==undefined)
      {
       this.componentdetails.nestedfoldedht=0;
      }
      this.transactionService.saveaddlncomp(this.componentdetails).subscribe(addsave=>{
        const status=addsave;
        console.log('success',status);
        if(status=='success')
        {
          this.messageService.add({severity:'success', summary:'Success', detail:'Additional Component Added Successfully'});
          this.componentdetailsinit();
          this.populategrid();
          this.expPckAidsInputValidationInit();
          this.selected_cntr_type_and_desc=this.comp_type_and_desc[0];
          this.compAddDetailsEnable=true;
          this.compAddEnable=false;
        }
        else
        {
          this.expPckAidsInputValidationInit();
          this.messageService.add({severity:'error', summary: 'Error', detail: 'Component Not Added,Please Try Again'});

          if(this.unitom=='E')
          {
            this.edit_measure_comp_addc='lbs/inch';
          }
          else
          {
            this.edit_measure_comp_addc='kg/cm'
          }

        //    if(this.edit_measure_comp_addc=="kg/cm")
        //   {

        //     if(this.componentdetails.comp_l!=null)
        //   {
        //     this.componentdetails.comp_l=Math.round((2.54*this.componentdetails.comp_l)*1000)/1000;

        //   }
        //   if(this.componentdetails.comp_w!=null)
        //   {
        //     this.componentdetails.comp_w=Math.round((2.54*this.componentdetails.comp_w)*1000)/1000;

        //   }
        //   if(this.componentdetails.comp_h!=null)
        //   {
        //     this.componentdetails.comp_h=Math.round((2.54*this.componentdetails.comp_h)*1000)/1000;

        //   }
        //   if(this.componentdetails.nestedfoldedht!=null)
        //   {
        //     this.componentdetails.nestedfoldedht=Math.round((2.54*this.componentdetails.nestedfoldedht)*1000)/1000;

        //   }
        //   if(this.componentdetails.comp_wt!=null)
        //   {
        //     this.componentdetails.comp_wt=Math.round((0.453592*this.componentdetails.comp_wt)*1000)/1000;

        //   }
        // }
        }
      });
    }

  }
// Input Validation for Add
  addCompBaseValidation(){
      if(this.componentdetails.comp_base.length>=10)
    {
      this.contBaseInValid=true;
      this.contBaseInValidReason='Only 9 characters are valid';
    }
    else if(this.componentdetails.comp_base.length==null || this.componentdetails.comp_base.trim()==''){
      this.contBaseInValid=true;
      this.contBaseInValidReason='Required';
    }
    else
    {
      this.contBaseInValid=false;
      this.contBaseInValidReason=null
    }
  }
  addCompSuffixValidation(){
    if(this.componentdetails.comp_suffix.length>=9)
  {
    this.contSuffixInValid=true;
    this.contSuffixInValidReason='Only 8 characters are valid';
  }
  else if(this.componentdetails.comp_suffix.length==null || this.componentdetails.comp_suffix.trim()==''){
    this.contSuffixInValid=false;
    this.contSuffixInValidReason=null;
  }
  else
  {
    this.contBaseInValid=false;
    this.contBaseInValidReason=null;
  }
}
  addCompDescValidation(){
      if(this.componentdetails.comp_desc.length>=501)
    {
      this.contDescInValid=true;
      this.contDescInValidReason='Only 500 characters are valid';
    }
    else if(this.componentdetails.comp_desc.length==null || this.componentdetails.comp_desc.trim()==''){
      this.contDescInValid=true;
      this.contDescInValidReason='Required';
    }
    else
    {
      this.contDescInValid=false;
      this.contDescInValidReason=null
    }
  }
  addQntPerMinSupValidation(){
    if(this.componentdetails.qt_per_min_su<0 || this.componentdetails.qt_per_min_su>=10000)
    {
      this.qntPerMinShipUnitInValid=true;
      this.qntPerMinShipUnitInValidReason='Only 1 to 9999 numbers are allowed';
    }
    else if(this.componentdetails.qt_per_min_su==null || this.componentdetails.qt_per_min_su==0){
      this.qntPerMinShipUnitInValid=true;
      this.qntPerMinShipUnitInValidReason='Required';
    }
    else
    {
      this.qntPerMinShipUnitInValid=false;
      this.qntPerMinShipUnitInValidReason=null
    }
  }
  addNestFoldHeightValidation(){
    if(this.componentdetails.nestedfoldedht<0 || this.componentdetails.nestedfoldedht>=10000)
    {
      this.nestFoldHeightInValid=true;
      this.nestFoldHeightInValidReason='Only 0 to 9999 numbers are allowed';
    }
    else if (this.componentdetails.nestedfoldedht>0 && this.componentdetails.comp_h>0 && this.componentdetails.nestedfoldedht>this.componentdetails.comp_h)
    {
    this.nestFoldHeightInValid=true;
    this.nestFoldHeightInValidReason='Nested Folded Height cannot be greater than Component Height';
    }
   /*  else if(this.componentdetails.nestedfoldedht==null || this.componentdetails.nestedfoldedht==0){
      this.nestFoldHeightInValid=true;
      this.nestFoldHeightInValidReason="Required";
    } */
    else
    {
      this.nestFoldHeightInValid=false;
      this.nestFoldHeightInValidReason=null
    }
  }
  addCompWeightValidation(){
    if(this.componentdetails.comp_wt<0 || this.componentdetails.comp_wt>=10000)
    {
      this.compWeightInValid=true;
      this.compWeightInValidReason='Only 0 to 9999 numbers are allowed';
    }
   /*  else if(this.componentdetails.comp_wt==null || this.componentdetails.comp_wt==0){
      this.compWeightInValid=true;
      this.compWeightInValidReason="Required";
    } */
    else
    {
      this.compWeightInValid=false;
      this.compWeightInValidReason=null
    }
  }
  addCompDimLenValidation(){
    if(this.componentdetails.comp_l<0 || this.componentdetails.comp_l>=10000)
    {
      this.compDimLenInValid=true;
      this.compDimLenInValidReason='Only 0 to 9999 numbers are allowed';
    }
    /* else if(this.componentdetails.comp_l==null || this.componentdetails.comp_l==0){
      this.compDimLenInValid=true;
      this.compDimLenInValidReason="Required";
    } */
    else
    {
      this.compDimLenInValid=false;
      this.compDimLenInValidReason=null
    }
  }
  addCompDimWidValidation(){
    if(this.componentdetails.comp_w<0 || this.componentdetails.comp_w>=10000)
    {
      this.compDimWidInValid=true;
      this.compDimWidInValidReason='Only 0 to 9999 numbers are allowed';
    }
    /* else if(this.componentdetails.comp_w==null || this.componentdetails.comp_w==0){
      this.compDimWidInValid=true;
      this.compDimWidInValidReason="Required";
    } */
    else
    {
      this.compDimWidInValid=false;
      this.compDimWidInValidReason=null
    }
  }
  addCompDimHghtValidation(){
    if(this.componentdetails.comp_h<0 || this.componentdetails.comp_h>=10000)
    {
      this.compDimHghtInValid=true;
      this.compDimHghtInValidReason='Only 0 to 9999 numbers are allowed';
    }
    /* else if(this.componentdetails.comp_h==null || this.componentdetails.comp_h==0){
      this.compDimHghtInValid=true;
      this.compDimHghtInValidReason="Required";
    } */
    else
    {
      this.compDimHghtInValid=false;
      this.compDimHghtInValidReason=null
    }
  }
  addCompRetTypeValidation()
  {
    if(this.componentdetails.comp_ret_type == null || this.componentdetails.comp_ret_type.trim()==''){
      this.compRetTypeInValid=true;
      this.compRetTypeInValidReason='Required';
    }
    else{
      this.compRetTypeInValid=false;
      this.compRetTypeInValidReason=null
    }
  }

  cancel_add()
  {
    this.componentdetailsinit();
    this.expPckAidsInputValidationInit();
    this.selected_cntr_type_and_desc=this.comp_type_and_desc[0];
    this.compAddDetailsEnable=false;
    this.compAddEnable=true;
    console.log('In Cancel of add component',this.componentdetails);
  }

  displaydetails(cdetails:any)
  {
    this.componentdetailsinit();

    this.compAddEnable=false;
    this.compAddDetailsEnable=false;
    this.enableeditcompdetails=false;
    this.enableeditgetdetails=false;

    this.transactionService.getaddlncompbyid(cdetails.compid).subscribe(addlncompout=>{
      this.componentdetails=addlncompout;
      console.log('additional component by id',JSON.stringify(this.componentdetails));

    //  this. edit_measure_comp_display="lbs/inch";
          if(this.unitom=='E')
          {
            this.edit_measure_comp_display='lbs/inch';
          }
          else
          {
            this.edit_measure_comp_display='kg/cm'
          }

      this.compDetailsEnable=true;
    })
  }


  on_change_measure_comp_display()
  {
    console.log(this.edit_measure_comp_edit);
    console.log(this.componentdetails);

    if(this.unitom=='E')
    {
      this.edit_measure_comp_display='lbs/inch';
    }
    else
    {
      this.edit_measure_comp_display='kg/cm'
    }

  //   if(this.edit_measure_comp_display=="lbs/inch")
  //   {
  //   if(this.componentdetails.comp_l!=null)
  //   {
  //     this.componentdetails.comp_l=Math.round((0.393701*this.componentdetails.comp_l)*1000)/1000;

  //   }
  //   if(this.componentdetails.comp_w!=null)
  //   {
  //     this.componentdetails.comp_w=Math.round((0.393701*this.componentdetails.comp_w)*1000)/1000;

  //   }
  //   if(this.componentdetails.comp_h!=null)
  //   {
  //     this.componentdetails.comp_h=Math.round((0.393701*this.componentdetails.comp_h)*1000)/1000;

  //   }
  //   if(this.componentdetails.nestedfoldedht!=null)
  //   {
  //     this.componentdetails.nestedfoldedht=Math.round((0.393701*this.componentdetails.nestedfoldedht)*1000)/1000;

  //   }
  //   if(this.componentdetails.comp_wt!=null)
  //   {
  //     this.componentdetails.comp_wt=Math.round((2.20462*this.componentdetails.comp_wt)*1000)/1000;

  //   }
  // }


  //   else if(this.edit_measure_comp_display=="kg/cm")
  //   {

  //     if(this.componentdetails.comp_l!=null)
  //   {
  //     this.componentdetails.comp_l=Math.round((2.54*this.componentdetails.comp_l)*1000)/1000;

  //   }
  //   if(this.componentdetails.comp_w!=null)
  //   {
  //     this.componentdetails.comp_w=Math.round((2.54*this.componentdetails.comp_w)*1000)/1000;

  //   }
  //   if(this.componentdetails.comp_h!=null)
  //   {
  //     this.componentdetails.comp_h=Math.round((2.54*this.componentdetails.comp_h)*1000)/1000;

  //   }
  //   if(this.componentdetails.nestedfoldedht!=null)
  //   {
  //     this.componentdetails.nestedfoldedht=Math.round((2.54*this.componentdetails.nestedfoldedht)*1000)/1000;

  //   }
  //   if(this.componentdetails.comp_wt!=null)
  //   {
  //     this.componentdetails.comp_wt=Math.round((0.453592*this.componentdetails.comp_wt)*1000)/1000;

  //   }
  //   }

  }


  // Function called when clicking edit button on componnent details display
  getcompdetails_edit()
  {
    this.compDetailsEnable=false;
    this.enableeditgetdetails=true;

   this.edit_measure_comp_edit=this.edit_measure_comp_display;
    this.componentdetailsforeditinit();
    this.expPckAidsInputValidationInit();


    if(this.unitom=='E')
    {
      this.edit_measure_comp_edit='lbs/inch';
    }
    else
    {
      this.edit_measure_comp_edit='kg/cm'
    }


    console.log('componentdetails before editing',this.componentdetails);

    this.componentdetailsforedit=this.componentdetails;
    for(let j=0;j<this.comp_type_and_desc.length;j++)
    {
      if(String(this.comp_type_and_desc[j].cd_cntr_typ)==this.componentdetails.comp_type)
      {
        this.edit_selected_cntr_type_and_desc=this.comp_type_and_desc[j];
      }
    }
  }


  // function when cancel is clicked from container details
  getcompdetails_cancel()
  {
    this.compAddEnable=true;
    this.componentdetailsinit();
    this.compDetailsEnable=false;
    this.expPckAidsInputValidationInit();
  }


  on_change_measure_comp_edit()
  {
    console.log(this.edit_measure_comp_edit);
    console.log(this.componentdetailsforedit);
    if(this.unitom=='E')
    {
      this.edit_measure_comp_edit='lbs/inch';
    }
    else
    {
      this.edit_measure_comp_edit='kg/cm'
    }

  //   if(this.edit_measure_comp_edit=="lbs/inch")
  //   {
  //   if(this.componentdetailsforedit.comp_l!=null)
  //   {
  //     this.componentdetailsforedit.comp_l=Math.round((0.393701*this.componentdetailsforedit.comp_l)*1000)/1000;

  //   }
  //   if(this.componentdetailsforedit.comp_w!=null)
  //   {
  //     this.componentdetailsforedit.comp_w=Math.round((0.393701*this.componentdetailsforedit.comp_w)*1000)/1000;

  //   }
  //   if(this.componentdetailsforedit.comp_h!=null)
  //   {
  //     this.componentdetailsforedit.comp_h=Math.round((0.393701*this.componentdetailsforedit.comp_h)*1000)/1000;

  //   }
  //   if(this.componentdetailsforedit.nestedfoldedht!=null)
  //   {
  //     this.componentdetailsforedit.nestedfoldedht=Math.round((0.393701*this.componentdetailsforedit.nestedfoldedht)*1000)/1000;

  //   }
  //   if(this.componentdetailsforedit.comp_wt!=null)
  //   {
  //     this.componentdetailsforedit.comp_wt=Math.round((2.20462*this.componentdetailsforedit.comp_wt)*1000)/1000;

  //   }
  // }


  //   else if(this.edit_measure_comp_edit=="kg/cm")
  //   {

  //     if(this.componentdetailsforedit.comp_l!=null)
  //   {
  //     this.componentdetailsforedit.comp_l=Math.round((2.54*this.componentdetailsforedit.comp_l)*1000)/1000;

  //   }
  //   if(this.componentdetailsforedit.comp_w!=null)
  //   {
  //     this.componentdetailsforedit.comp_w=Math.round((2.54*this.componentdetailsforedit.comp_w)*1000)/1000;

  //   }
  //   if(this.componentdetailsforedit.comp_h!=null)
  //   {
  //     this.componentdetailsforedit.comp_h=Math.round((2.54*this.componentdetailsforedit.comp_h)*1000)/1000;

  //   }
  //   if(this.componentdetailsforedit.nestedfoldedht!=null)
  //   {
  //     this.componentdetailsforedit.nestedfoldedht=Math.round((2.54*this.componentdetailsforedit.nestedfoldedht)*1000)/1000;

  //   }
  //   if(this.componentdetailsforedit.comp_wt!=null)
  //   {
  //     this.componentdetailsforedit.comp_wt=Math.round((0.453592*this.componentdetailsforedit.comp_wt)*1000)/1000;

  //   }
  //   }

  }
  // Input Validation for Edit
  editCompBaseValidation(){
    if(this.componentdetailsforedit.comp_base.length>=10)
  {
    this.contBaseInValid=true;
    this.contBaseInValidReason='Only 9 characters are valid';
  }
  else if(this.componentdetailsforedit.comp_base.length==null || this.componentdetailsforedit.comp_base.trim()==''){
    this.contBaseInValid=true;
    this.contBaseInValidReason='Required';
  }
  else
  {
    this.contBaseInValid=false;
    this.contBaseInValidReason=null
  }
}
editCompSuffixValidation(){
  if(this.componentdetailsforedit.comp_suffix.length>=9)
{
  this.contSuffixInValid=true;
  this.contSuffixInValidReason='Only 8 characters are valid';
}
else if(this.componentdetailsforedit.comp_suffix.length==null || this.componentdetailsforedit.comp_suffix.trim()==''){
  this.contSuffixInValid=false;
  this.contSuffixInValidReason=null;
}
else
{
  this.contBaseInValid=false;
  this.contBaseInValidReason=null
}
}
editCompDescValidation(){
    if(this.componentdetailsforedit.comp_desc.length>=500)
  {
    this.contDescInValid=true;
    this.contDescInValidReason='Only 500 characters are valid';
  }
  else if(this.componentdetailsforedit.comp_desc.length==null || this.componentdetailsforedit.comp_desc.trim()==''){
    this.contDescInValid=true;
    this.contDescInValidReason='Required';
  }
  else
  {
    this.contDescInValid=false;
    this.contDescInValidReason=null
  }
}
editQntPerMinSupValidation(){
  if(this.componentdetailsforedit.qt_per_min_su<0 || this.componentdetailsforedit.qt_per_min_su>=10000)
  {
    this.qntPerMinShipUnitInValid=true;
    this.qntPerMinShipUnitInValidReason='Only 1 to 9999 numbers are allowed';
  }
  else if(this.componentdetailsforedit.qt_per_min_su==null || this.componentdetailsforedit.qt_per_min_su==0){
    this.qntPerMinShipUnitInValid=true;
    this.qntPerMinShipUnitInValidReason='Required';
  }
  else
  {
    this.qntPerMinShipUnitInValid=false;
    this.qntPerMinShipUnitInValidReason=null
  }
}
editNestFoldHeightValidation(){
  if(this.componentdetailsforedit.nestedfoldedht<0 || this.componentdetailsforedit.nestedfoldedht>=10000)
  {
    this.nestFoldHeightInValid=true;
    this.nestFoldHeightInValidReason='Only 0 to 9999 numbers are allowed';
  }
  else if (this.componentdetailsforedit.nestedfoldedht>0 && this.componentdetailsforedit.comp_h>0 && this.componentdetailsforedit.nestedfoldedht>this.componentdetailsforedit.comp_h)
  {
    this.nestFoldHeightInValid=true;
    this.nestFoldHeightInValidReason='Nested Folded Height cannot be greater than Component Height';
  }
  /* else if(this.componentdetailsforedit.nestedfoldedht==null || this.componentdetailsforedit.nestedfoldedht==0){
    this.nestFoldHeightInValid=true;
    this.nestFoldHeightInValidReason="Required";
  } */
  else
  {
    this.nestFoldHeightInValid=false;
    this.nestFoldHeightInValidReason=null
  }
}
editCompWeightValidation(){
  if(this.componentdetailsforedit.comp_wt<0 || this.componentdetailsforedit.comp_wt>=10000)
  {
    this.compWeightInValid=true;
    this.compWeightInValidReason='Only 0 to 9999 numbers are allowed';
  }
 /*  else if(this.componentdetailsforedit.comp_wt==null || this.componentdetailsforedit.comp_wt==0){
    this.compWeightInValid=true;
    this.compWeightInValidReason="Required";
  } */
  else
  {
    this.compWeightInValid=false;
    this.compWeightInValidReason=null
  }
}
editCompDimLenValidation(){
  if(this.componentdetailsforedit.comp_l<0 || this.componentdetailsforedit.comp_l>=10000)
  {
    this.compDimLenInValid=true;
    this.compDimLenInValidReason='Only 0 to 9999 numbers are allowed';
  }
  /* else if(this.componentdetailsforedit.comp_l==null || this.componentdetailsforedit.comp_l==0){
    this.compDimLenInValid=true;
    this.compDimLenInValidReason="Required";
  } */
  else
  {
    this.compDimLenInValid=false;
    this.compDimLenInValidReason=null
  }
}
editCompDimWidValidation(){
  if(this.componentdetailsforedit.comp_w<0 || this.componentdetailsforedit.comp_w>=10000)
  {
    this.compDimWidInValid=true;
    this.compDimWidInValidReason='Only 0 to 9999 numbers are allowed';
  }
  /* else if(this.componentdetailsforedit.comp_w==null || this.componentdetailsforedit.comp_w==0){
    this.compDimWidInValid=true;
    this.compDimWidInValidReason="Required";
  } */
  else
  {
    this.compDimWidInValid=false;
    this.compDimWidInValidReason=null
  }
}
editCompDimHghtValidation(){
  if(this.componentdetailsforedit.comp_h<0 || this.componentdetailsforedit.comp_h>=10000)
  {
    this.compDimHghtInValid=true;
    this.compDimHghtInValidReason='Only 0 to 9999 numbers are allowed';
  }
 /*  else if(this.componentdetailsforedit.comp_h==null || this.componentdetailsforedit.comp_h==0){
    this.compDimHghtInValid=true;
    this.compDimHghtInValidReason="Required";
  } */
  else
  {
    this.compDimHghtInValid=false;
    this.compDimHghtInValidReason=null
  }
}
editCompRetTypeValidation()
  {
    if(this.componentdetailsforedit.comp_ret_type == null || this.componentdetailsforedit.comp_ret_type.trim()==''){
      this.compRetTypeInValid=true;
      this.compRetTypeInValidReason='Required';
    }
    else{
      this.compRetTypeInValid=false;
      this.compRetTypeInValidReason=null
    }
  }




  // function when save is clicked after going into edit mode from container details
  saveeditcomp()
  {
    this.componentdetailsforedit.comp_type_desc=this.edit_selected_cntr_type_and_desc.cntr_typ_desc;
    this.componentdetailsforedit.comp_type=String(this.edit_selected_cntr_type_and_desc.cd_cntr_typ);
    this.componentdetailsforedit.updated_by=this.transactionService.getcdsid();
    this.componentdetailsforedit.updated_dt=new Date().toISOString().split('T')[0];

    if(this.unitom=='E')
    {
      this.edit_measure_comp_edit='lbs/inch';
    }
    else
    {
      this.edit_measure_comp_edit='kg/cm'
    }

    if(this.edit_measure_comp_edit=='kg/cm')
    {
    if(this.componentdetailsforedit.comp_l!=null)
    {
      this.componentdetailsforedit.comp_l=Math.round((0.393701*this.componentdetailsforedit.comp_l)*1000)/1000;

    }
    if(this.componentdetailsforedit.comp_w!=null)
    {
      this.componentdetailsforedit.comp_w=Math.round((0.393701*this.componentdetailsforedit.comp_w)*1000)/1000;

    }
    if(this.componentdetailsforedit.comp_h!=null)
    {
      this.componentdetailsforedit.comp_h=Math.round((0.393701*this.componentdetailsforedit.comp_h)*1000)/1000;

    }
    if(this.componentdetailsforedit.nestedfoldedht!=null)
    {
      this.componentdetailsforedit.nestedfoldedht=Math.round((0.393701*this.componentdetailsforedit.nestedfoldedht)*1000)/1000;

    }
    if(this.componentdetailsforedit.comp_wt!=null)
    {
      this.componentdetailsforedit.comp_wt=Math.round((2.20462*this.componentdetailsforedit.comp_wt)*1000)/1000;

    }
  }


    console.log('In save of edit component from component details',this.componentdetailsforedit);
  this.editCompBaseValidation();
  this.editCompDescValidation();
  this.editQntPerMinSupValidation();
 /*  this.editNestFoldHeightValidation();
  this.editCompWeightValidation();
  this.editCompDimLenValidation();
  this.editCompDimWidValidation();
  this.editCompDimHghtValidation(); */
  this.editCompRetTypeValidation();

  if(!this.contBaseInValid && !this.contDescInValid && !this.qntPerMinShipUnitInValid &&  !this.compRetTypeInValid
    // !this.nestFoldHeightInValid && !this.compWeightInValid && !this.compDimLenInValid && !this.compDimWidInValid && !this.compDimHghtInValid
    )
    {
       if(this.componentdetailsforedit.comp_l==null || this.componentdetailsforedit.comp_l==undefined)
       {
        this.componentdetailsforedit.comp_l=0;
       }
       if(this.componentdetailsforedit.comp_w==null || this.componentdetailsforedit.comp_w==undefined)
       {
        this.componentdetailsforedit.comp_w=0;
       }
       if(this.componentdetailsforedit.comp_h==null || this.componentdetailsforedit.comp_h==undefined)
       {
        this.componentdetailsforedit.comp_h=0;
       }
       if(this.componentdetailsforedit.comp_wt==null || this.componentdetailsforedit.comp_wt==undefined)
       {
        this.componentdetailsforedit.comp_wt=0;
       }
       if(this.componentdetailsforedit.nestedfoldedht==null || this.componentdetailsforedit.nestedfoldedht==undefined)
       {
        this.componentdetailsforedit.nestedfoldedht=0;
       }

    this.transactionService.saveaddlncomp(this.componentdetailsforedit).subscribe(editsave=>{
      const editstatus=editsave;
      console.log('success',editstatus);
      if(editstatus=='success')
      {
        this.messageService.add({severity:'success', summary:'Success', detail:'Additional Component Updated Successfully'});
        this.populategrid();
        this.compAddDetailsEnable=false;
        this.compAddEnable=false;
        this.enableeditgetdetails=false;
        this.displaydetails(this.componentdetailsforedit);
      }
      else
      {
        this.messageService.add({severity:'error', summary: 'Error', detail: 'Component Not Updated,Please Try Again'});

        if(this.unitom=='E')
        {
          this.edit_measure_comp_edit='lbs/inch';
        }
        else
        {
          this.edit_measure_comp_edit='kg/cm'
        }

      //   if(this.edit_measure_comp_edit=="kg/cm")
      //   {
      //   if(this.componentdetailsforedit.comp_l!=null)
      //   {
      //     this.componentdetailsforedit.comp_l=Math.round((2.54*this.componentdetailsforedit.comp_l)*1000)/1000;

      //   }
      //   if(this.componentdetailsforedit.comp_w!=null)
      //   {
      //     this.componentdetailsforedit.comp_w=Math.round((2.54*this.componentdetailsforedit.comp_w)*1000)/1000;

      //   }
      //   if(this.componentdetailsforedit.comp_h!=null)
      //   {
      //     this.componentdetailsforedit.comp_h=Math.round((2.54*this.componentdetailsforedit.comp_h)*1000)/1000;

      //   }
      //   if(this.componentdetailsforedit.nestedfoldedht!=null)
      //   {
      //     this.componentdetailsforedit.nestedfoldedht=Math.round((2.54*this.componentdetailsforedit.nestedfoldedht)*1000)/1000;

      //   }
      //   if(this.componentdetailsforedit.comp_wt!=null)
      //   {
      //     this.componentdetailsforedit.comp_wt=Math.round((0.453592*this.componentdetailsforedit.comp_wt)*1000)/1000;

      //   }
      // }
      }
    });
  }
  }


  // function when cancel is clicked after going into edit mode from container details
  canceleditcomp()
  {
    this.enableeditgetdetails=false;
    this.componentdetailsforeditinit();
    this.expPckAidsInputValidationInit();
    this.displaydetails(this.componentdetails);
  }

  // Functions called when edit button is clicked from GRID
  editcompdetls(compdetals:any)
  {
    this.compAddEnable=false;
    this.compDetailsEnable=false;
    this.enableeditgetdetails=false;
    this.compAddDetailsEnable=false;
    this.enableeditcompdetails=true;
    this.componentdetailsforeditinit();
    // this.edit_measure_comp_edit="lbs/inch";

    if(this.unitom=='E')
    {
      this.edit_measure_comp_edit='lbs/inch';
    }
    else
    {
      this.edit_measure_comp_edit='kg/cm'
    }


    this.transactionService.getaddlncompbyid(compdetals.compid).subscribe(editcomponent=>{
      this.componentdetailsforedit=editcomponent;

      for(let k=0;k<this.comp_type_and_desc.length;k++)
      {
        if(String(this.comp_type_and_desc[k].cd_cntr_typ)==this.componentdetailsforedit.comp_type)
        {
          this.edit_selected_cntr_type_and_desc=this.comp_type_and_desc[k];
        }
      }

      console.log('componennt details after edit button from grid pressed is',this.componentdetailsforedit);
    })

  }

  // Function called when save is pressed on edit mode from GRID
  saveeditcompfromgrid()
  {
    this.componentdetailsforedit.comp_type_desc=this.edit_selected_cntr_type_and_desc.cntr_typ_desc;
    this.componentdetailsforedit.comp_type=String(this.edit_selected_cntr_type_and_desc.cd_cntr_typ);
    this.componentdetailsforedit.updated_by=this.transactionService.getcdsid();
    this.componentdetailsforedit.updated_dt=new Date().toISOString().split('T')[0];

    if(this.unitom=='E')
    {
      this.edit_measure_comp_edit='lbs/inch';
    }
    else
    {
      this.edit_measure_comp_edit='kg/cm'
    }


  //   if(this.edit_measure_comp_edit=="kg/cm")
  //   {
  //   if(this.componentdetailsforedit.comp_l!=null)
  //   {
  //     this.componentdetailsforedit.comp_l=Math.round((0.393701*this.componentdetailsforedit.comp_l)*1000)/1000;

  //   }
  //   if(this.componentdetailsforedit.comp_w!=null)
  //   {
  //     this.componentdetailsforedit.comp_w=Math.round((0.393701*this.componentdetailsforedit.comp_w)*1000)/1000;

  //   }
  //   if(this.componentdetailsforedit.comp_h!=null)
  //   {
  //     this.componentdetailsforedit.comp_h=Math.round((0.393701*this.componentdetailsforedit.comp_h)*1000)/1000;

  //   }
  //   if(this.componentdetailsforedit.nestedfoldedht!=null)
  //   {
  //     this.componentdetailsforedit.nestedfoldedht=Math.round((0.393701*this.componentdetailsforedit.nestedfoldedht)*1000)/1000;

  //   }
  //   if(this.componentdetailsforedit.comp_wt!=null)
  //   {
  //     this.componentdetailsforedit.comp_wt=Math.round((2.20462*this.componentdetailsforedit.comp_wt)*1000)/1000;

  //   }
  // }





    console.log('In save of edit component from component GRID',this.componentdetailsforedit);
    this.editCompBaseValidation();
    this.editCompDescValidation();
    this.editQntPerMinSupValidation();
   /*  this.editNestFoldHeightValidation();
    this.editCompWeightValidation();
    this.editCompDimLenValidation();
    this.editCompDimWidValidation();
    this.editCompDimHghtValidation(); */
    this.editCompRetTypeValidation();

    if(!this.contBaseInValid && !this.contDescInValid && !this.qntPerMinShipUnitInValid  && !this.compRetTypeInValid
      // !this.nestFoldHeightInValid && !this.compWeightInValid&& !this.compDimLenInValid && !this.compDimWidInValid && !this.compDimHghtInValid
      )
       {
        if(this.componentdetailsforedit.comp_l==null || this.componentdetailsforedit.comp_l==undefined)
        {
         this.componentdetailsforedit.comp_l=0;
        }
        if(this.componentdetailsforedit.comp_w==null || this.componentdetailsforedit.comp_w==undefined)
        {
         this.componentdetailsforedit.comp_w=0;
        }
        if(this.componentdetailsforedit.comp_h==null || this.componentdetailsforedit.comp_h==undefined)
        {
         this.componentdetailsforedit.comp_h=0;
        }
        if(this.componentdetailsforedit.comp_wt==null || this.componentdetailsforedit.comp_wt==undefined)
        {
         this.componentdetailsforedit.comp_wt=0;
        }
        if(this.componentdetailsforedit.nestedfoldedht==null || this.componentdetailsforedit.nestedfoldedht==undefined)
        {
         this.componentdetailsforedit.nestedfoldedht=0;
        }
    this.transactionService.saveaddlncomp(this.componentdetailsforedit).subscribe(editsavefromgrid=>{
      const editstatusforgrid=editsavefromgrid;
      console.log('success',editstatusforgrid);
      if(editstatusforgrid=='success')
      {
        this.messageService.add({severity:'success', summary:'Success', detail:'Additional Component Updated Successfully'});
        this.populategrid();
        this.expPckAidsInputValidationInit();
        this.compAddDetailsEnable=false;
        this.compAddEnable=false;
        this.enableeditgetdetails=false;
        this.enableeditcompdetails=false;
        this.displaydetails(this.componentdetailsforedit);
      }
      else
      {
        this.expPckAidsInputValidationInit();
        this.messageService.add({severity:'error', summary: 'Error', detail: 'Component Not Updated,Please Try Again'});

        if(this.unitom=='E')
    {
      this.edit_measure_comp_edit='lbs/inch';
    }
    else
    {
      this.edit_measure_comp_edit='kg/cm'
    }


      //   if(this.edit_measure_comp_edit=="kg/cm")
      //   {
      //   if(this.componentdetailsforedit.comp_l!=null)
      //   {
      //     this.componentdetailsforedit.comp_l=Math.round((2.54*this.componentdetailsforedit.comp_l)*1000)/1000;

      //   }
      //   if(this.componentdetailsforedit.comp_w!=null)
      //   {
      //     this.componentdetailsforedit.comp_w=Math.round((2.54*this.componentdetailsforedit.comp_w)*1000)/1000;

      //   }
      //   if(this.componentdetailsforedit.comp_h!=null)
      //   {
      //     this.componentdetailsforedit.comp_h=Math.round((2.54*this.componentdetailsforedit.comp_h)*1000)/1000;

      //   }
      //   if(this.componentdetailsforedit.nestedfoldedht!=null)
      //   {
      //     this.componentdetailsforedit.nestedfoldedht=Math.round((2.54*this.componentdetailsforedit.nestedfoldedht)*1000)/1000;

      //   }
      //   if(this.componentdetailsforedit.comp_wt!=null)
      //   {
      //     this.componentdetailsforedit.comp_wt=Math.round((0.453592*this.componentdetailsforedit.comp_wt)*1000)/1000;

      //   }
      // }
      }
    });
  }
  }

  // Function called when cancel is pressed on edit mode from GRID
  canceleditcompfromgrid()
  {
    this.compAddDetailsEnable=false;
    this.compAddEnable=true;
    this.enableeditgetdetails=false;
    this.enableeditcompdetails=false;
    this.compDetailsEnable=false;
    this.componentdetailsforeditinit();
    this.expPckAidsInputValidationInit();
  }

}
