/* tslint:disable */
import { Component,HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {AppComponent} from './app.component';
import { TransactionService } from './loginpage/Transaction.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit{





  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {


    // console.log(event)

    console.log(document.getElementById('test'))
      if (!(event.target == document.getElementById('test'))) {
        const dropdowns = document.getElementsByClassName('dropdown-contenttop');
        let i;
        for (i = 0; i < dropdowns.length; i++) {
          const openDropdown = dropdowns[i];
          if (openDropdown.classList.contains('show')) {
            // console.log("inpoooo1")
            openDropdown.classList.remove('show');
          }
        }
      }
    }
  constructor(public app: AppComponent,private router:Router,private transactionService : TransactionService) {}

  ngOnInit():void {


  }

/*

  userid():string{
    this.Useridtp=this.transactionServices.getcdsid();
    return this.Useridtp;
  }
*/


  userid():string{
    let user='';

    user=this.transactionService.getcdsid();
    if(user!=null)
    {
      return user;
    }
    else
    {
      user=''
      return user;
    }
  }


  logoactive()
  {
    console.log(this.app.mobileMenuActive);
    return this.app.mobileMenuActive

  }


  func()
  {
    // console.log("inpooo")
    document.getElementById('myDropdowntop').classList.toggle('show');
  }



  logOut()
  {
    this.transactionService.logout();
    this.router.navigate(['/exitpage']);
  }


  isuserpresent():boolean
	{
		let user_exist
		user_exist=this.transactionService.getcdsid();
		// console.log(user_exist)
		if(user_exist==null)
		{

			return false;
		}
		else{

      const isActiveUser=this.transactionService.getActiveUserOrNot();

      if(isActiveUser!=null && isActiveUser==true)
			{
        return true;
      }
      else
      {
        return false;
      }
		}
  }


  goToHome()
  {
    console.log('go To Home');


    const cur_region=this.transactionService.getregion();
    if(cur_region != null )
    {
      let reg_map_url='';
      reg_map_url=this.transactionService.getregionurlvalue(cur_region);
      reg_map_url+='packagingprocessdashboard/packagingprocesshome';

      this.router.navigate([reg_map_url]);

    }

    else
    {
      this.router.navigate(['/exitpage']);
    }

  }




}
