/* tslint:disable */
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { TransactionService } from '../loginpage/Transaction.service';
import { addContainer, cntrDetailsFromCMMS, containerCurrencyList,  containerDetailsCntrAdmin, containerDetailsSaveCntrAdmin, containerEditCmmsFields, containerGroupActiveList, containerMasterCategory, containerMaterialList, containerOwnershipCategoryList, containerOwnershipList, containerRequestReject, containerTypeCode, DBIAContainerRequests, DBIAContainerRequestsGrid, plantlist, userdetails } from '../loginpage/transactions';

@Component({
  selector: 'app-dbiareqmgnt',
  templateUrl: './dbiareqmgnt.component.html',
  styleUrls: ['./dbiareqmgnt.component.css'],
  providers:[MessageService]
})
export class DbiareqmgntComponent implements OnInit {



  enableCntrEditPendingNotification=false;
    enableAddingNewContainerNotification=false;
    enableUpdateExistingCntrNotification=false;

    enableCompEditPendingNotification=false;
    enableAddingNewCompNotification=false;
    enableUpdateExistingCompNotification=false;
    enableUpdateNgppFieldsNotification: boolean = false;
    enableUpdateNgppFieldsNotificationForComp: boolean = false;

  containerRequestGrid:DBIAContainerRequestsGrid[]=[]

  // not used ...
  approveContainerRequest:addContainer={


    function:'A',
   cntr_suff_ind:'',
   exp_refundable:'',
   cntr_active_ind:'',
   cntr_base:'',
   cntr_suffix:'',
   cntr_desc:'',
   card_call:'',
   cntr_mstr_catg:'',
   cntr_typ_desc:'',
   ownrshp_strat_code:'',
   ownrshp_catg:'',
   cntr_grp_desc:'',
   cntr_exterior_l:0,
   cntr_exterior_w:0,
   cntr_exterior_h:0,
   collapsed_ht:0.0,
   nest_ratio:0,
   cntr_interior_l:0,
   cntr_interior_w:0,
   cntr_interior_h:0,
   cntr_tare_wt:0,
   cntr_capacity:0,
   cntr_per_layer:0,
   max_layers_shpg_unit:0,
   max_cntr_per_shpg_unit:0,
   cntr_matr_desc:'',
   cntr_drawing_no:'',
   shpg_unit_exterior_l:0,
   shpg_unit_exterior_w:0,
   shpg_unit_exterior_h:0,
   shpg_unit_nstdorflded_ht:0,
   shpg_unit_tare_wt:0,
   shpg_unit_stack_ht:0,
   warehouse_stack_ht:0,
   service_life:'',
   currency_desc:'',
   purchase_cost:0,
   rental_issue_cost:0,
   sup_rntl_dly_cost:0,
   ford_rntl_dly_cost:0,
   return_rntl_cost:0,
   tooling_dev_cost:0,
   extr_cntr_dsgn_img_ind:'No',
   intr_cntr_dsgn_img_ind:'No',
   shpg_unit_img_ind:'No',
   cad_img_ind:'No',
   img_notes:'',
   cd_region:'',
   iscontainercolapsble:'',
   cd_plant:'',
   cntr_qt_per_min_su:0,
   nest_ratio_1:0,
   ford_cntct:'',
   cntct_phone:'',
   id_user:'',
   ts_last_update:'',
   req_stat:'',
   components:[{
       mp_function:'',
       no_comp_base:'',
       no_comp_suffix:'',
       ds_comp:'',
       comp_qt_per_min_su:0,
       comp_cd_owner:'',
       in_comp:'',
       comp_cd_type:''
   }],


   UoM:'E',
   dbia_req_no:0,
   typ_no:0

  }
  // not used ...
  rejectContainerRequest:addContainer={

    function:'A',
   cntr_suff_ind:'',
   exp_refundable:'',
   cntr_active_ind:'',
   cntr_base:'',
   cntr_suffix:'',
   cntr_desc:'',
   card_call:'',
   cntr_mstr_catg:'',
   cntr_typ_desc:'',
   ownrshp_strat_code:'',
   ownrshp_catg:'',
   cntr_grp_desc:'',
   cntr_exterior_l:0,
   cntr_exterior_w:0,
   cntr_exterior_h:0,
   collapsed_ht:0.0,
   nest_ratio:0,
   cntr_interior_l:0,
   cntr_interior_w:0,
   cntr_interior_h:0,
   cntr_tare_wt:0,
   cntr_capacity:0,
   cntr_per_layer:0,
   max_layers_shpg_unit:0,
   max_cntr_per_shpg_unit:0,
   cntr_matr_desc:'',
   cntr_drawing_no:'',
   shpg_unit_exterior_l:0,
   shpg_unit_exterior_w:0,
   shpg_unit_exterior_h:0,
   shpg_unit_nstdorflded_ht:0,
   shpg_unit_tare_wt:0,
   shpg_unit_stack_ht:0,
   warehouse_stack_ht:0,
   service_life:'',
   currency_desc:'',
   purchase_cost:0,
   rental_issue_cost:0,
   sup_rntl_dly_cost:0,
   ford_rntl_dly_cost:0,
   return_rntl_cost:0,
   tooling_dev_cost:0,
   extr_cntr_dsgn_img_ind:'No',
   intr_cntr_dsgn_img_ind:'No',
   shpg_unit_img_ind:'No',
   cad_img_ind:'No',
   img_notes:'',
   cd_region:'',
   iscontainercolapsble:'',
   cd_plant:'',
   cntr_qt_per_min_su:0,
   nest_ratio_1:0,
   ford_cntct:'',
   cntct_phone:'',
   id_user:'',
   ts_last_update:'',
   req_stat:'',
   components:[{
       mp_function:'',
       no_comp_base:'',
       no_comp_suffix:'',
       ds_comp:'',
       comp_qt_per_min_su:0,
       comp_cd_owner:'',
       in_comp:'',
       comp_cd_type:''
   }],


   UoM:'E',
   dbia_req_no:0,
   typ_no:0
  }
  region_code:string="";
  region:string="";
  cdsid:string="";
  userdetails:userdetails={
    gppuserid: "",
    title: "",
    name: "",
    company: "",
    designation: "",
    email: "",
    isforduser: "",
    isactive: "",
    createddt: "",
    createdby: "",
    phone: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    postalcode: "",
    cntrycodeiso: "",
    lastlogin: "",
    fax: "",
    country: "",
    usertype: "",
   
  }


  enableApproveRejectRequest=false;
  selectedRequestNumber=0;
  suppGSDBCode:string=null;
  suppName:string=null;
  SuppCntct:number=null;
  suppEmail:string=null;

  rejectionCommentsComp:string=null;
  rejectionComments:string=null;
  enable_dialog_notification_cntr:boolean=false;
  enable_dialog_notification_comp:boolean=false;
  cntrRequestRejectionEmailFormat:containerRequestReject[]=[];
  compRequestRejectionEmailFormat:containerRequestReject[]=[];

  cntrRequestApprovalEmailFormat:containerRequestReject[]=[];
  compRequestApprovalEmailFormat:containerRequestReject[]=[];

  containerOriginalRequestDetials:addContainer={


    function:'A',
   cntr_suff_ind:'',
   exp_refundable:'',
   cntr_active_ind:'',
   cntr_base:'',
   cntr_suffix:'',
   cntr_desc:'',
   card_call:'',
   cntr_mstr_catg:'',
   cntr_typ_desc:'',
   ownrshp_strat_code:'',
   ownrshp_catg:'',
   cntr_grp_desc:'',
   cntr_exterior_l:0,
   cntr_exterior_w:0,
   cntr_exterior_h:0,
   collapsed_ht:0.0,
   nest_ratio:0,
   cntr_interior_l:0,
   cntr_interior_w:0,
   cntr_interior_h:0,
   cntr_tare_wt:0,
   cntr_capacity:0,
   cntr_per_layer:0,
   max_layers_shpg_unit:0,
   max_cntr_per_shpg_unit:0,
   cntr_matr_desc:'',
   cntr_drawing_no:'',
   shpg_unit_exterior_l:0,
   shpg_unit_exterior_w:0,
   shpg_unit_exterior_h:0,
   shpg_unit_nstdorflded_ht:0,
   shpg_unit_tare_wt:0,
   shpg_unit_stack_ht:0,
   warehouse_stack_ht:0,
   service_life:'',
   currency_desc:'',
   purchase_cost:0,
   rental_issue_cost:0,
   sup_rntl_dly_cost:0,
   ford_rntl_dly_cost:0,
   return_rntl_cost:0,
   tooling_dev_cost:0,
   extr_cntr_dsgn_img_ind:'No',
   intr_cntr_dsgn_img_ind:'No',
   shpg_unit_img_ind:'No',
   cad_img_ind:'No',
   img_notes:'',
   cd_region:'',
   iscontainercolapsble:'',
   cd_plant:'',
   cntr_qt_per_min_su:0,
   nest_ratio_1:0,
   ford_cntct:'',
   cntct_phone:'',
   id_user:'',
   ts_last_update:'',
   req_stat:'',
   components:[{
       mp_function:'',
       no_comp_base:'',
       no_comp_suffix:'',
       ds_comp:'',
       comp_qt_per_min_su:0,
       comp_cd_owner:'',
       in_comp:'',
       comp_cd_type:''
   }],


   UoM:'E',
   dbia_req_no:0,
   typ_no:0

  }

  containerRequestDetialsForPost:addContainer={


    function:'A',
   cntr_suff_ind:'',
   exp_refundable:'',
   cntr_active_ind:'',
   cntr_base:'',
   cntr_suffix:'',
   cntr_desc:'',
   card_call:'',
   cntr_mstr_catg:'',
   cntr_typ_desc:'',
   ownrshp_strat_code:'',
   ownrshp_catg:'',
   cntr_grp_desc:'',
   cntr_exterior_l:0,
   cntr_exterior_w:0,
   cntr_exterior_h:0,
   collapsed_ht:0.0,
   nest_ratio:0,
   cntr_interior_l:0,
   cntr_interior_w:0,
   cntr_interior_h:0,
   cntr_tare_wt:0,
   cntr_capacity:0,
   cntr_per_layer:0,
   max_layers_shpg_unit:0,
   max_cntr_per_shpg_unit:0,
   cntr_matr_desc:'',
   cntr_drawing_no:'',
   shpg_unit_exterior_l:0,
   shpg_unit_exterior_w:0,
   shpg_unit_exterior_h:0,
   shpg_unit_nstdorflded_ht:0,
   shpg_unit_tare_wt:0,
   shpg_unit_stack_ht:0,
   warehouse_stack_ht:0,
   service_life:'',
   currency_desc:'',
   purchase_cost:0,
   rental_issue_cost:0,
   sup_rntl_dly_cost:0,
   ford_rntl_dly_cost:0,
   return_rntl_cost:0,
   tooling_dev_cost:0,
   extr_cntr_dsgn_img_ind:'No',
   intr_cntr_dsgn_img_ind:'No',
   shpg_unit_img_ind:'No',
   cad_img_ind:'No',
   img_notes:'',
   cd_region:'',
   iscontainercolapsble:'',
   cd_plant:'',
   cntr_qt_per_min_su:0,
   nest_ratio_1:0,
   ford_cntct:'',
   cntct_phone:'',
   id_user:'',
   ts_last_update:'',
   req_stat:'',
   components:[{
       mp_function:'',
       no_comp_base:'',
       no_comp_suffix:'',
       ds_comp:'',
       comp_qt_per_min_su:0,
       comp_cd_owner:'',
       in_comp:'',
       comp_cd_type:''
   }],


   UoM:'E',
   dbia_req_no:0,
   typ_no:0

  }

  containerEditRequestDetials:addContainer={


    function:'A',
   cntr_suff_ind:'',
   exp_refundable:'',
   cntr_active_ind:'',
   cntr_base:'',
   cntr_suffix:'',
   cntr_desc:'',
   card_call:'',
   cntr_mstr_catg:'',
   cntr_typ_desc:'',
   ownrshp_strat_code:'',
   ownrshp_catg:'',
   cntr_grp_desc:'',
   cntr_exterior_l:0,
   cntr_exterior_w:0,
   cntr_exterior_h:0,
   collapsed_ht:0.0,
   nest_ratio:0,
   cntr_interior_l:0,
   cntr_interior_w:0,
   cntr_interior_h:0,
   cntr_tare_wt:0,
   cntr_capacity:0,
   cntr_per_layer:0,
   max_layers_shpg_unit:0,
   max_cntr_per_shpg_unit:0,
   cntr_matr_desc:'',
   cntr_drawing_no:'',
   shpg_unit_exterior_l:0,
   shpg_unit_exterior_w:0,
   shpg_unit_exterior_h:0,
   shpg_unit_nstdorflded_ht:0,
   shpg_unit_tare_wt:0,
   shpg_unit_stack_ht:0,
   warehouse_stack_ht:0,
   service_life:'',
   currency_desc:'',
   purchase_cost:0,
   rental_issue_cost:0,
   sup_rntl_dly_cost:0,
   ford_rntl_dly_cost:0,
   return_rntl_cost:0,
   tooling_dev_cost:0,
   extr_cntr_dsgn_img_ind:'No',
   intr_cntr_dsgn_img_ind:'No',
   shpg_unit_img_ind:'No',
   cad_img_ind:'No',
   img_notes:'',
   cd_region:'',
   iscontainercolapsble:'',
   cd_plant:'',
   cntr_qt_per_min_su:0,
   nest_ratio_1:0,
   ford_cntct:'',
   cntct_phone:'',
   id_user:'',
   ts_last_update:'',
   req_stat:'',
   components:[{
       mp_function:'',
       no_comp_base:'',
       no_comp_suffix:'',
       ds_comp:'',
       comp_qt_per_min_su:0,
       comp_cd_owner:'',
       in_comp:'',
       comp_cd_type:''
   }],


   UoM:'E',
   dbia_req_no:0,
   typ_no:0

  }


  collapsedHtInValid=false;
  collapsedHtInValidReason:string=null;
  nestRatioInValid=false;
  nestRatioInValidReason:string=null;
  nestRatio1InValid=false;
  nestRatio1InValidReason:string=null;
  cntrTareWtInValid=false;
  cntrTareWtInValidReason:string=null;
  cntrCapWtInValid=false;
  cntrCapWtInValidReason:string=null;
  cntrPerLayerInValid=false;
  cntrPerLayerInValidReason:string=null;
  maxLayerPerSuInValid=false;
  maxLayerPerSuInValidReason:string=null;
  cntrQtPerMinSuInValid=false;
  cntrQtPerMinSuInvalidReason:string=null;
  MaxCntrPerSuInValid=false;
  MaxCntrPerMinSuInvalidReason:string=null;
  cntrExtLInValid=false;
  cntrExtLInValidReason:string=null;
  cntrExtWInValid=false;
  cntrExtWInValidReason:string=null;
  cntrExtHInValid=false;
  cntrExtHInValidReason:string=null;
  cntrIntLInValid=false;
  cntrIntLInValidReason:string=null;
  cntrIntWInValid=false;
  cntrIntWInValidReason:string=null;
  cntrIntHInValid=false;
  cntrIntHInValidReason:string=null;
  suNestedHtInValid=false;
  suNestedHtInValidReason:string=null;
  suTareWtInvalid=false;
  suTareWtInValidReason:string=null;
  wareHourseStackHtInvaid=false;
  wareHourseStackHtInvaidReason:string=null;
  suStackHtInvalid=false;
  suStackHtInvalidReason:string=null;
  suExtLInValid=false;
  suExtLInValidReason:string=null;
  suExtWInValid=false;
  suExtWInValidReason:string=null;
  suExtHInValid=false;
  suExtHInValidReason:string=null;
  cntrBaseInvalid=true;
  cntrBaseInvalidReason:string=null;
  cntrDescInvalid=false;
  cntrDescInvalidReason:string=null;
  plantCodeInvalid=false;
  plantCodeInvalidReason:string=null;
  cardOrCallInvalid=false;
  cardOrCallInvalidReason:string=null;
  mastrCatgInvalid=false;
  mastrCatgInvalidReason:string=null;
  retTypeInvalid=false;
  retTypeInvalidReason:string=null;
  cntrTypeInvalid=false;
  cntrTypeInvalidReason:string=null;
  cntrMaterialInvalid=false;
  cntrMaterialInvalidReason:string=null;
  cosInvalid=false;
  cosInvalidReason:string=null;
  cntrGrpInvalid=false;
  cntrGrpInvalidReason:string=null;
  isCollapsibleInvalid=false;
  isCollapsibleInvalidReason:string=null;
  cntrCntctInvalid=false;
  cntrCntctInvalidReason:string=null;
  cntctPhoneInvalid=false;
  cntctPhoneInvlidReason:string=null;
  cntrActiveInvalid=false;
  cntrActiveInvalidReason=null;


  plantList:plantlist[]=[];

  editSelectedPlantCode:plantlist={cd_plant:'',plantGSDBCode:'',plant_active_stat:'',plant_name:'',plant_type_name:''};


  cntrMasterCategory:containerMasterCategory[]=[];
  edit_selected_cntr_master_category:containerMasterCategory={cd_cntr_mstr_catg:0,cntr_mstr_catg_desc:'',updated_by:'',updated_dt:'',cntr_mstr_catg:'',active_stat:'',active_stat_display:false};

  // Added
  cntrTypeCode:containerTypeCode[]=[];
  unmodifiedTypeCodes:containerTypeCode[]=[];

  edit_selected_cntr_type_code:containerTypeCode={cd_cntr_typ:'',cntr_typ_desc:'',typ_no:0};


  cntrOwnershipstratcode:containerOwnershipList[]=[];
  edit_selected_cntr_ownrship_strat_code:containerOwnershipList={cd_region:'',ownrshp_strat_code:'',ownrshp_desc:'',cd_ownrshp_strat:0};

  cntrGrp:containerGroupActiveList[]=[];
  edit_selected_cntr_group:containerGroupActiveList={cntr_grp:''};

  cntrMaterial:containerMaterialList[]=[];
  edit_selected_cntr_material:containerMaterialList={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};

  cntrCurrencyList:containerCurrencyList[]=[];
  edit_selected_cntr_currency_list:containerCurrencyList={cd_curr:0,curr_desc:''};

  ownrship_catg:containerOwnershipCategoryList[]=[{ownrshp_catg:'Ford',ownrshp_catg_val:'FORD'}, {ownrshp_catg:'Supplier',ownrshp_catg_val:'SUPP'},{ownrshp_catg:'Third Party',ownrshp_catg_val:'TPT'}];
  edit_selected_ownrship_catg:containerOwnershipCategoryList={ownrshp_catg:'',ownrshp_catg_val:''};

  cntrCmmsFieldsAfterUpdate:containerEditCmmsFields={   
    cntr_base:"",
		cntr_suffix:"",
		cntr_desc:"",
		cntr_typ_desc:"",
		cd_plant:"",
		collapsed_ht:0,
		nest_ratio:0,
		nest_ratio_1:0,
		cntr_tare_wt:0,
		cntr_capacity:0,
		cntr_qt_per_min_su:0,
    ford_cntct:"",
    cntct_phone:"",
		cntr_exterior_l:0,
		cntr_exterior_w:0,
		cntr_exterior_h:0,
		shpg_unit_stack_ht:0,
		warehouse_stack_ht:0,
    ownrshp_catg:""
    }
    cntrCmmsFieldsBeforeUpdate:containerEditCmmsFields={   
      cntr_base:"",
      cntr_suffix:"",
      cntr_desc:"",
      cntr_typ_desc:"",
      cd_plant:"",
      collapsed_ht:0,
      nest_ratio:0,
      nest_ratio_1:0,
      cntr_tare_wt:0,
      cntr_capacity:0,
      cntr_qt_per_min_su:0,
      ford_cntct:"",
      cntct_phone:"",
      cntr_exterior_l:0,
      cntr_exterior_w:0,
      cntr_exterior_h:0,
      shpg_unit_stack_ht:0,
      warehouse_stack_ht:0,
      ownrshp_catg:""
      }

  enable_material_dropdown=false;


  edit_measure=''
  prev_edit_measure=''

  public cols:any[]=[]


  fromRejectButton=false;
  confirmPopUp=false;


  current_routeid=0

  containerdetails: containerDetailsCntrAdmin;

  cntr_countDets: number;


  /**Component */


  enableApproveRejectCompRequest=false;
  // selectedRequestNumber=0;

  componentOriginalRequestDetials:addContainer={


    function:'A',
   cntr_suff_ind:'',
   exp_refundable:'',
   cntr_active_ind:'',
   cntr_base:'',
   cntr_suffix:'',
   cntr_desc:'',
   card_call:'',
   cntr_mstr_catg:'',
   cntr_typ_desc:'',
   ownrshp_strat_code:'',
   ownrshp_catg:'',
   cntr_grp_desc:'',
   cntr_exterior_l:0,
   cntr_exterior_w:0,
   cntr_exterior_h:0,
   collapsed_ht:0.0,
   nest_ratio:0,
   cntr_interior_l:0,
   cntr_interior_w:0,
   cntr_interior_h:0,
   cntr_tare_wt:0,
   cntr_capacity:0,
   cntr_per_layer:0,
   max_layers_shpg_unit:0,
   max_cntr_per_shpg_unit:0,
   cntr_matr_desc:'',
   cntr_drawing_no:'',
   shpg_unit_exterior_l:0,
   shpg_unit_exterior_w:0,
   shpg_unit_exterior_h:0,
   shpg_unit_nstdorflded_ht:0,
   shpg_unit_tare_wt:0,
   shpg_unit_stack_ht:0,
   warehouse_stack_ht:0,
   service_life:'',
   currency_desc:'',
   purchase_cost:0,
   rental_issue_cost:0,
   sup_rntl_dly_cost:0,
   ford_rntl_dly_cost:0,
   return_rntl_cost:0,
   tooling_dev_cost:0,
   extr_cntr_dsgn_img_ind:'No',
   intr_cntr_dsgn_img_ind:'No',
   shpg_unit_img_ind:'No',
   cad_img_ind:'No',
   img_notes:'',
   cd_region:'',
   iscontainercolapsble:'',
   cd_plant:'',
   cntr_qt_per_min_su:0,
   nest_ratio_1:0,
   ford_cntct:'',
   cntct_phone:'',
   id_user:'',
   ts_last_update:'',
   req_stat:'',
   components:[{
       mp_function:'',
       no_comp_base:'',
       no_comp_suffix:'',
       ds_comp:'',
       comp_qt_per_min_su:0,
       comp_cd_owner:'',
       in_comp:'',
       comp_cd_type:''
   }],


   UoM:'E',
   dbia_req_no:0,
   typ_no:0

  }

  componentRequestDetialsForPost:addContainer={


    function:'A',
   cntr_suff_ind:'',
   exp_refundable:'',
   cntr_active_ind:'',
   cntr_base:'',
   cntr_suffix:'',
   cntr_desc:'',
   card_call:'',
   cntr_mstr_catg:'',
   cntr_typ_desc:'',
   ownrshp_strat_code:'',
   ownrshp_catg:'',
   cntr_grp_desc:'',
   cntr_exterior_l:0,
   cntr_exterior_w:0,
   cntr_exterior_h:0,
   collapsed_ht:0.0,
   nest_ratio:0,
   cntr_interior_l:0,
   cntr_interior_w:0,
   cntr_interior_h:0,
   cntr_tare_wt:0,
   cntr_capacity:0,
   cntr_per_layer:0,
   max_layers_shpg_unit:0,
   max_cntr_per_shpg_unit:0,
   cntr_matr_desc:'',
   cntr_drawing_no:'',
   shpg_unit_exterior_l:0,
   shpg_unit_exterior_w:0,
   shpg_unit_exterior_h:0,
   shpg_unit_nstdorflded_ht:0,
   shpg_unit_tare_wt:0,
   shpg_unit_stack_ht:0,
   warehouse_stack_ht:0,
   service_life:'',
   currency_desc:'',
   purchase_cost:0,
   rental_issue_cost:0,
   sup_rntl_dly_cost:0,
   ford_rntl_dly_cost:0,
   return_rntl_cost:0,
   tooling_dev_cost:0,
   extr_cntr_dsgn_img_ind:'No',
   intr_cntr_dsgn_img_ind:'No',
   shpg_unit_img_ind:'No',
   cad_img_ind:'No',
   img_notes:'',
   cd_region:'',
   iscontainercolapsble:'',
   cd_plant:'',
   cntr_qt_per_min_su:0,
   nest_ratio_1:0,
   ford_cntct:'',
   cntct_phone:'',
   id_user:'',
   ts_last_update:'',
   req_stat:'',
   components:[{
       mp_function:'',
       no_comp_base:'',
       no_comp_suffix:'',
       ds_comp:'',
       comp_qt_per_min_su:0,
       comp_cd_owner:'',
       in_comp:'',
       comp_cd_type:''
   }],


   UoM:'E',
   dbia_req_no:0,
   typ_no:0

  }

  componentEditRequestDetials:addContainer={


    function:'A',
   cntr_suff_ind:'',
   exp_refundable:'',
   cntr_active_ind:'',
   cntr_base:'',
   cntr_suffix:'',
   cntr_desc:'',
   card_call:'',
   cntr_mstr_catg:'',
   cntr_typ_desc:'',
   ownrshp_strat_code:'',
   ownrshp_catg:'',
   cntr_grp_desc:'',
   cntr_exterior_l:0,
   cntr_exterior_w:0,
   cntr_exterior_h:0,
   collapsed_ht:0.0,
   nest_ratio:0,
   cntr_interior_l:0,
   cntr_interior_w:0,
   cntr_interior_h:0,
   cntr_tare_wt:0,
   cntr_capacity:0,
   cntr_per_layer:0,
   max_layers_shpg_unit:0,
   max_cntr_per_shpg_unit:0,
   cntr_matr_desc:'',
   cntr_drawing_no:'',
   shpg_unit_exterior_l:0,
   shpg_unit_exterior_w:0,
   shpg_unit_exterior_h:0,
   shpg_unit_nstdorflded_ht:0,
   shpg_unit_tare_wt:0,
   shpg_unit_stack_ht:0,
   warehouse_stack_ht:0,
   service_life:'',
   currency_desc:'',
   purchase_cost:0,
   rental_issue_cost:0,
   sup_rntl_dly_cost:0,
   ford_rntl_dly_cost:0,
   return_rntl_cost:0,
   tooling_dev_cost:0,
   extr_cntr_dsgn_img_ind:'No',
   intr_cntr_dsgn_img_ind:'No',
   shpg_unit_img_ind:'No',
   cad_img_ind:'No',
   img_notes:'',
   cd_region:'',
   iscontainercolapsble:'',
   cd_plant:'',
   cntr_qt_per_min_su:0,
   nest_ratio_1:0,
   ford_cntct:'',
   cntct_phone:'',
   id_user:'',
   ts_last_update:'',
   req_stat:'',
   components:[{
       mp_function:'',
       no_comp_base:'',
       no_comp_suffix:'',
       ds_comp:'',
       comp_qt_per_min_su:0,
       comp_cd_owner:'',
       in_comp:'',
       comp_cd_type:''
   }],


   UoM:'E',
   dbia_req_no:0,
   typ_no:0

  }

  saveEditContainerCntrAdmin:containerDetailsSaveCntrAdmin={


    cd_cntr:0,
    cntr_suff_ind:'',
    exp_refundable:'',
    cntr_active_ind:'',
    cntr_base:'',
    cntr_suffix:'',
    cntr_desc:'',
    card_call:'',
    cntr_mstr_catg:'',
    cntr_typ_desc:'',
    ownrshp_strat_code:'',
    ownrshp_catg:'',
    cntr_grp_desc:'',
    cntr_exterior_l:0,
    cntr_exterior_w:0,
    cntr_exterior_h:0,
    collapsed_ht:0,
    nest_ratio:0,
    cntr_interior_l:0,
    cntr_interior_w:0,
    cntr_interior_h:0,
    cntr_tare_wt:0,
    cntr_capacity:0,
    cntr_per_layer:0,
    max_layers_shpg_unit:0,
    max_cntr_per_shpg_unit:0,
    cntr_matr_desc:'',
    cntr_drawing_no:'',
    shpg_unit_exterior_l:0,
    shpg_unit_exterior_w:0,
    shpg_unit_exterior_h:0,
    shpg_unit_nstdorflded_ht:0,
    shpg_unit_tare_wt:0,
    shpg_unit_stack_ht:0,
    warehouse_stack_ht:0,
    service_life:'',
    currency_desc:'',
    purchase_cost:0,
    rental_issue_cost:0,
    sup_rntl_dly_cost:0,
    ford_rntl_dly_cost:0,
    return_rntl_cost:0,
    tooling_dev_cost:0,
    extr_cntr_dsgn_img_ind:'',
    intr_cntr_dsgn_img_ind:'',
    shpg_unit_img_ind:'',
    cad_img_ind:'',
    img_notes:'',
    cd_region:'',
    iscontainercolapsble:'',
    cd_plant: "",
    cnt_qt_per_min_su: 0,
    nest_ratio_1: 0,
    ford_cntct: "",
    cntct_phone: "",
    req_stat: "",
    id_user: "",
    ts_last_update: "",
    uom:'E',
    typ_no:0,

  }


    compTareWtInValid:boolean=false;
    compTareWtInValidReason:string=null;
    compCapWtInValid=false;
    compCapWtInValidReason:string=null;
    compQtPerMinSuInValid=false;
    compQtPerMinSuInvalidReason:string=null;
    compExtLInValid=false;
    compExtLInValidReason:string=null;
    compExtWInValid=false;
    compExtWInValidReason:string=null;
    compExtHInValid=false;
    compExtHInValidReason:string=null;
    wareHourseStackHtInvaidComp=false;
    wareHourseStackHtInvaidReasonComp:string=null;
    compBaseInvalid=true;
    compBaseInvalidReason:string=null;
    compDescInvalid=false;
    compDescInvalidReason:string=null;
    plantCodeCompInvalid=false;
    plantCodeCompInvalidReason:string=null;
    mastrCatgInvalidComp=false;
    mastrCatgInvalidReasonComp:string=null;
    retTypeInvalidComp=false;
    retTypeInvalidReasonComp:string=null;
    compTypeInvalid=false;
    compTypeInvalidReason:string=null;
    compMaterialInvalid=false;
    compMaterialInvalidReason:string=null;
    cosInvalidComp=false;
    cosInvalidReasonComp:string=null;
    compGrpInvalid=false;
    compGrpInvalidReason:string=null;
    compCntctInvalid=false;
    compCntctInvalidReason:string=null;
    cntctPhoneInvalidComp=false;
    cntctPhoneInvlidReasonComp:string=null;
    compActiveInvalid=false;
    compActiveInvalidReason=null;





  editSelectedPlantCodeComp:plantlist={cd_plant:'',plantGSDBCode:'',plant_active_stat:'',plant_name:'',plant_type_name:''};



  edit_selected_cntr_master_category_comp:containerMasterCategory={cd_cntr_mstr_catg:0,cntr_mstr_catg_desc:'',updated_by:'',updated_dt:'',cntr_mstr_catg:'',active_stat:'',active_stat_display:false};

  // Added
  compTypeCode:containerTypeCode[]=[];

  edit_selected_cntr_type_code_comp:containerTypeCode={cd_cntr_typ:'',cntr_typ_desc:'',typ_no:0};


  edit_selected_cntr_ownrship_strat_code_comp:containerOwnershipList={cd_region:'',ownrshp_strat_code:'',ownrshp_desc:'',cd_ownrshp_strat:0};


  edit_selected_cntr_group_comp:containerGroupActiveList={cntr_grp:''};


  edit_selected_cntr_material_comp:containerMaterialList={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};


  edit_selected_cntr_currency_list_comp:containerCurrencyList={cd_curr:0,curr_desc:''};

   edit_selected_ownrship_catg_comp:containerOwnershipCategoryList={ownrshp_catg:'',ownrshp_catg_val:''};




  enable_material_dropdown_comp=false;





  fromRejectButtonComp=false;
  confirmPopUpComp=false;

  constructor(private transactionService:TransactionService,private messageService:MessageService,private date:DatePipe) { }

  ngOnInit(): void {

    this.region=this.transactionService.getregion();
    this.region_code=this.transactionService.getregioncode(this.region);
    this.cdsid=this.transactionService.getcdsid();
    this.userdetails=this.transactionService.getUserCdsidDetails();

    this.rejectionComments=null;
    this.rejectionCommentsComp=null;
    this.enable_dialog_notification_comp=false;
    this.enable_dialog_notification_cntr=false;
    this.cntrRequestRejectionEmailFormat=[];
    this.compRequestRejectionEmailFormat=[];

    this.cntrRequestApprovalEmailFormat=[];
    this.compRequestApprovalEmailFormat=[]


    this.current_routeid=201;

    console.log(this.current_routeid)


    if(this.region=='Asia Pacific & Africa')
    {
      sessionStorage.setItem('aprouteid', JSON.stringify(this.current_routeid));
    }

    else if(this.region=='Europe')
    {
      sessionStorage.setItem('eurouteid', JSON.stringify(this.current_routeid));
    }

    else if (this.region=='North America')

    {

      sessionStorage.setItem('narouteid', JSON.stringify(this.current_routeid));
    }

    else if(this.region=='South America')
    {
      sessionStorage.setItem('sarouteid', JSON.stringify(this.current_routeid));
    }


    this.transactionService.getPlantListonRegion(this.region_code).subscribe(plantcodes=>{
      console.log('plantcodes',plantcodes);
      this.plantList=plantcodes.filter(e=>e.plant_active_stat.toLowerCase()=='yes');

    })

    this.transactionService.getMasterCategory().subscribe(mastcatg=>{
      console.log('mastcatg',mastcatg);
      this.cntrMasterCategory=mastcatg;


    });

  this.transactionService.getContainerTypeCode().subscribe(cntrtypecode=>{

      this.unmodifiedTypeCodes=cntrtypecode;
      this.cntrTypeCode=this.unmodifiedTypeCodes.filter(e=>['01'].includes(String(e.cd_cntr_typ)));
      this.compTypeCode=this.unmodifiedTypeCodes.filter(e=>['02','03'].includes(String(e.cd_cntr_typ)));
// removed ,"04","05"
  });

  this.transactionService.getOwnershipStrategyCode(this.region_code).subscribe(cntrownrship=>{
      this.cntrOwnershipstratcode=cntrownrship;

  });



// get active container group list
  this.transactionService.getActiveContainerGroup(this.region_code).subscribe(cntrgrp=>{
    this.cntrGrp=cntrgrp;

});



  this.transactionService.getCurrencyList().subscribe(cntrcurrency=>{
      console.log('Currency List',cntrcurrency);
      this.cntrCurrencyList=cntrcurrency;

  });








        this.populateDbiaRequestGrid();

  }


  populateDbiaRequestGrid()
  {
    // service to get open request , by passing status as open and regioncode
    const req_status='open'
    this.transactionService.getDBIAReqByStatus(req_status,this.region_code).subscribe(dbiareqgrid=>{
      console.log('DBIA open status requests grid',dbiareqgrid)

    this.containerRequestGrid=[];
    this.containerRequestGrid=dbiareqgrid;

    if(this.containerRequestGrid != null && this.containerRequestGrid.length != 0)
    {
      for(let i=0; i<this.containerRequestGrid.length; i++)
      {
        this.containerRequestGrid[i].cntr_base_suffix=this.containerRequestGrid[i].cntr_base;
        if(this.containerRequestGrid[i].cntr_suffix != null && this.containerRequestGrid[i].cntr_suffix.trim() != '')
        {
          this.containerRequestGrid[i].cntr_base_suffix+='-'+this.containerRequestGrid[i].cntr_suffix;
        }
      }
    }

    this.cols=[
    {field:'cntr_base_suffix',header:'Container Number'},
    { field: 'cntr_desc', header:'Description'},
    { field: 'ownrshp_strat_code', header:'Ownership Strategy'},
    { field: 'exp_refundable', header:'E / R'},
    {field:'id_user',header:'Supplier GSDB Code'}
    // { field: 'cntr_active_ind', header:'Active'}
    ]
  })
  }

// below two functions not used ...
  approveRequestInit()
  {
    this.approveContainerRequest={


      function:'A',
     cntr_suff_ind:'',
     exp_refundable:'',
     cntr_active_ind:'',
     cntr_base:'',
     cntr_suffix:'',
     cntr_desc:'',
     card_call:'',
     cntr_mstr_catg:'',
     cntr_typ_desc:'',
     ownrshp_strat_code:'',
     ownrshp_catg:'',
     cntr_grp_desc:'',
     cntr_exterior_l:0,
     cntr_exterior_w:0,
     cntr_exterior_h:0,
     collapsed_ht:0.0,
     nest_ratio:0,
     cntr_interior_l:0,
     cntr_interior_w:0,
     cntr_interior_h:0,
     cntr_tare_wt:0,
     cntr_capacity:0,
     cntr_per_layer:0,
     max_layers_shpg_unit:0,
     max_cntr_per_shpg_unit:0,
     cntr_matr_desc:'',
     cntr_drawing_no:'',
     shpg_unit_exterior_l:0,
     shpg_unit_exterior_w:0,
     shpg_unit_exterior_h:0,
     shpg_unit_nstdorflded_ht:0,
     shpg_unit_tare_wt:0,
     shpg_unit_stack_ht:0,
     warehouse_stack_ht:0,
     service_life:'',
     currency_desc:'',
     purchase_cost:0,
     rental_issue_cost:0,
     sup_rntl_dly_cost:0,
     ford_rntl_dly_cost:0,
     return_rntl_cost:0,
     tooling_dev_cost:0,
     extr_cntr_dsgn_img_ind:'No',
     intr_cntr_dsgn_img_ind:'No',
     shpg_unit_img_ind:'No',
     cad_img_ind:'No',
     img_notes:'',
     cd_region:'',
     iscontainercolapsble:'',
     cd_plant:'',
     cntr_qt_per_min_su:0,
     nest_ratio_1:0,
     ford_cntct:'',
     cntct_phone:'',
     id_user:'',
     ts_last_update:'',
     req_stat:'',
     components:[{
         mp_function:'',
         no_comp_base:'',
         no_comp_suffix:'',
         ds_comp:'',
         comp_qt_per_min_su:0,
         comp_cd_owner:'',
         in_comp:'',
         comp_cd_type:''
     }],


     UoM:'E',
     dbia_req_no:0,
     typ_no:0

    }
  }

  rejectRequestInit()
  {
    this.rejectContainerRequest={
      function:'A',
   cntr_suff_ind:'',
   exp_refundable:'',
   cntr_active_ind:'',
   cntr_base:'',
   cntr_suffix:'',
   cntr_desc:'',
   card_call:'',
   cntr_mstr_catg:'',
   cntr_typ_desc:'',
   ownrshp_strat_code:'',
   ownrshp_catg:'',
   cntr_grp_desc:'',
   cntr_exterior_l:0,
   cntr_exterior_w:0,
   cntr_exterior_h:0,
   collapsed_ht:0.0,
   nest_ratio:0,
   cntr_interior_l:0,
   cntr_interior_w:0,
   cntr_interior_h:0,
   cntr_tare_wt:0,
   cntr_capacity:0,
   cntr_per_layer:0,
   max_layers_shpg_unit:0,
   max_cntr_per_shpg_unit:0,
   cntr_matr_desc:'',
   cntr_drawing_no:'',
   shpg_unit_exterior_l:0,
   shpg_unit_exterior_w:0,
   shpg_unit_exterior_h:0,
   shpg_unit_nstdorflded_ht:0,
   shpg_unit_tare_wt:0,
   shpg_unit_stack_ht:0,
   warehouse_stack_ht:0,
   service_life:'',
   currency_desc:'',
   purchase_cost:0,
   rental_issue_cost:0,
   sup_rntl_dly_cost:0,
   ford_rntl_dly_cost:0,
   return_rntl_cost:0,
   tooling_dev_cost:0,
   extr_cntr_dsgn_img_ind:'No',
   intr_cntr_dsgn_img_ind:'No',
   shpg_unit_img_ind:'No',
   cad_img_ind:'No',
   img_notes:'',
   cd_region:'',
   iscontainercolapsble:'',
   cd_plant:'',
   cntr_qt_per_min_su:0,
   nest_ratio_1:0,
   ford_cntct:'',
   cntct_phone:'',
   id_user:'',
   ts_last_update:'',
   req_stat:'',
   components:[{
       mp_function:'',
       no_comp_base:'',
       no_comp_suffix:'',
       ds_comp:'',
       comp_qt_per_min_su:0,
       comp_cd_owner:'',
       in_comp:'',
       comp_cd_type:''
   }],


   UoM:'E',
   dbia_req_no:0,
   typ_no:0
    }
  }

  requestEditDetailsInit()
  {
    this.enable_material_dropdown=false;
    this.edit_selected_cntr_material={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''}

    this.containerEditRequestDetials={
      function:'A',
     cntr_suff_ind:'',
     exp_refundable:'',
     cntr_active_ind:'',
     cntr_base:'',
     cntr_suffix:'',
     cntr_desc:'',
     card_call:'',
     cntr_mstr_catg:'',
     cntr_typ_desc:'',
     ownrshp_strat_code:'',
     ownrshp_catg:'',
     cntr_grp_desc:'',
     cntr_exterior_l:0,
     cntr_exterior_w:0,
     cntr_exterior_h:0,
     collapsed_ht:0.0,
     nest_ratio:0,
     cntr_interior_l:0,
     cntr_interior_w:0,
     cntr_interior_h:0,
     cntr_tare_wt:0,
     cntr_capacity:0,
     cntr_per_layer:0,
     max_layers_shpg_unit:0,
     max_cntr_per_shpg_unit:0,
     cntr_matr_desc:'',
     cntr_drawing_no:'',
     shpg_unit_exterior_l:0,
     shpg_unit_exterior_w:0,
     shpg_unit_exterior_h:0,
     shpg_unit_nstdorflded_ht:0,
     shpg_unit_tare_wt:0,
     shpg_unit_stack_ht:0,
     warehouse_stack_ht:0,
     service_life:'',
     currency_desc:'',
     purchase_cost:0,
     rental_issue_cost:0,
     sup_rntl_dly_cost:0,
     ford_rntl_dly_cost:0,
     return_rntl_cost:0,
     tooling_dev_cost:0,
     extr_cntr_dsgn_img_ind:'No',
     intr_cntr_dsgn_img_ind:'No',
     shpg_unit_img_ind:'No',
     cad_img_ind:'No',
     img_notes:'',
     cd_region:'',
     iscontainercolapsble:'',
     cd_plant:'',
     cntr_qt_per_min_su:0,
     nest_ratio_1:0,
     ford_cntct:'',
     cntct_phone:'',
     id_user:'',
     ts_last_update:'',
     req_stat:'',
     components:[{
         mp_function:'',
         no_comp_base:'',
         no_comp_suffix:'',
         ds_comp:'',
         comp_qt_per_min_su:0,
         comp_cd_owner:'',
         in_comp:'',
         comp_cd_type:''
     }],


     UoM:'E',
     dbia_req_no:0,
     typ_no:0

    }
  }

  requestOriginalDetailsInit()
  {

    this.containerOriginalRequestDetials={
      function:'A',
   cntr_suff_ind:'',
   exp_refundable:'',
   cntr_active_ind:'',
   cntr_base:'',
   cntr_suffix:'',
   cntr_desc:'',
   card_call:'',
   cntr_mstr_catg:'',
   cntr_typ_desc:'',
   ownrshp_strat_code:'',
   ownrshp_catg:'',
   cntr_grp_desc:'',
   cntr_exterior_l:0,
   cntr_exterior_w:0,
   cntr_exterior_h:0,
   collapsed_ht:0.0,
   nest_ratio:0,
   cntr_interior_l:0,
   cntr_interior_w:0,
   cntr_interior_h:0,
   cntr_tare_wt:0,
   cntr_capacity:0,
   cntr_per_layer:0,
   max_layers_shpg_unit:0,
   max_cntr_per_shpg_unit:0,
   cntr_matr_desc:'',
   cntr_drawing_no:'',
   shpg_unit_exterior_l:0,
   shpg_unit_exterior_w:0,
   shpg_unit_exterior_h:0,
   shpg_unit_nstdorflded_ht:0,
   shpg_unit_tare_wt:0,
   shpg_unit_stack_ht:0,
   warehouse_stack_ht:0,
   service_life:'',
   currency_desc:'',
   purchase_cost:0,
   rental_issue_cost:0,
   sup_rntl_dly_cost:0,
   ford_rntl_dly_cost:0,
   return_rntl_cost:0,
   tooling_dev_cost:0,
   extr_cntr_dsgn_img_ind:'No',
   intr_cntr_dsgn_img_ind:'No',
   shpg_unit_img_ind:'No',
   cad_img_ind:'No',
   img_notes:'',
   cd_region:'',
   iscontainercolapsble:'',
   cd_plant:'',
   cntr_qt_per_min_su:0,
   nest_ratio_1:0,
   ford_cntct:'',
   cntct_phone:'',
   id_user:'',
   ts_last_update:'',
   req_stat:'',
   components:[{
       mp_function:'',
       no_comp_base:'',
       no_comp_suffix:'',
       ds_comp:'',
       comp_qt_per_min_su:0,
       comp_cd_owner:'',
       in_comp:'',
       comp_cd_type:''
   }],


   UoM:'E',
   dbia_req_no:0,
   typ_no:0
    }
  }

  requestDetailsForPostInit()
  {
    this.containerRequestDetialsForPost={
      function:'A',
   cntr_suff_ind:'',
   exp_refundable:'',
   cntr_active_ind:'',
   cntr_base:'',
   cntr_suffix:'',
   cntr_desc:'',
   card_call:'',
   cntr_mstr_catg:'',
   cntr_typ_desc:'',
   ownrshp_strat_code:'',
   ownrshp_catg:'',
   cntr_grp_desc:'',
   cntr_exterior_l:0,
   cntr_exterior_w:0,
   cntr_exterior_h:0,
   collapsed_ht:0.0,
   nest_ratio:0,
   cntr_interior_l:0,
   cntr_interior_w:0,
   cntr_interior_h:0,
   cntr_tare_wt:0,
   cntr_capacity:0,
   cntr_per_layer:0,
   max_layers_shpg_unit:0,
   max_cntr_per_shpg_unit:0,
   cntr_matr_desc:'',
   cntr_drawing_no:'',
   shpg_unit_exterior_l:0,
   shpg_unit_exterior_w:0,
   shpg_unit_exterior_h:0,
   shpg_unit_nstdorflded_ht:0,
   shpg_unit_tare_wt:0,
   shpg_unit_stack_ht:0,
   warehouse_stack_ht:0,
   service_life:'',
   currency_desc:'',
   purchase_cost:0,
   rental_issue_cost:0,
   sup_rntl_dly_cost:0,
   ford_rntl_dly_cost:0,
   return_rntl_cost:0,
   tooling_dev_cost:0,
   extr_cntr_dsgn_img_ind:'No',
   intr_cntr_dsgn_img_ind:'No',
   shpg_unit_img_ind:'No',
   cad_img_ind:'No',
   img_notes:'',
   cd_region:'',
   iscontainercolapsble:'',
   cd_plant:'',
   cntr_qt_per_min_su:0,
   nest_ratio_1:0,
   ford_cntct:'',
   cntct_phone:'',
   id_user:'',
   ts_last_update:'',
   req_stat:'',
   components:[{
       mp_function:'',
       no_comp_base:'',
       no_comp_suffix:'',
       ds_comp:'',
       comp_qt_per_min_su:0,
       comp_cd_owner:'',
       in_comp:'',
       comp_cd_type:''
   }],


   UoM:'E',
   dbia_req_no:0,
   typ_no:0
    }
  }

  validationInit()
  {
  this.collapsedHtInValid=false;
  this.collapsedHtInValidReason=null;
  this.nestRatioInValid=false;
  this.nestRatioInValidReason=null;
  this.nestRatio1InValid=false;
  this.nestRatio1InValidReason=null;
  this.cntrTareWtInValid=false;
  this.cntrTareWtInValidReason=null;
  this.cntrCapWtInValid=false;
  this.cntrCapWtInValidReason=null;
  this.cntrPerLayerInValid=false;
  this.cntrPerLayerInValidReason=null;
  this.maxLayerPerSuInValid=false;
  this.maxLayerPerSuInValidReason=null;
  this.cntrQtPerMinSuInValid=false;
  this.cntrQtPerMinSuInvalidReason=null;
  this.MaxCntrPerSuInValid=false;
  this.MaxCntrPerMinSuInvalidReason=null;
  this.cntrExtLInValid=false;
  this.cntrExtLInValidReason=null;
  this.cntrExtWInValid=false;
  this.cntrExtWInValidReason=null;
  this.cntrExtHInValid=false;
  this.cntrExtHInValidReason=null;
  this.cntrIntLInValid=false;
  this.cntrIntLInValidReason=null;
  this.cntrIntWInValid=false;
  this.cntrIntWInValidReason=null;
  this.cntrIntHInValid=false;
  this.cntrIntHInValidReason=null;
  this.suNestedHtInValid=false;
  this.suNestedHtInValidReason=null;
  this.suTareWtInvalid=false;
  this.suTareWtInValidReason=null;
  this.wareHourseStackHtInvaid=false;
  this.wareHourseStackHtInvaidReason=null;
  this.suStackHtInvalid=false;
  this.suStackHtInvalidReason=null;
  this.suExtLInValid=false;
  this.suExtLInValidReason=null;
  this.suExtWInValid=false;
  this.suExtWInValidReason=null;
  this.suExtHInValid=false;
  this.suExtHInValidReason=null;

  this.cntrBaseInvalid=false;
  this.cntrBaseInvalidReason=null;
  this.cntrDescInvalid=false;
  this.cntrDescInvalidReason=null;
  this.plantCodeInvalid=false;
  this.plantCodeInvalidReason=null;
  this.cardOrCallInvalid=false;
  this.cardOrCallInvalidReason=null;
  this.mastrCatgInvalid=false;
  this.mastrCatgInvalidReason=null;
  this.retTypeInvalid=false;
  this.retTypeInvalidReason=null;
  this.cntrTypeInvalid=false;
  this.cntrTypeInvalidReason=null;
  this.cntrMaterialInvalid=false;
  this.cntrMaterialInvalidReason=null;
  this.cosInvalid=false;
  this.cosInvalidReason=null;
  this.cntrGrpInvalid=false;
  this.cntrGrpInvalidReason=null;
  this.isCollapsibleInvalid=false;
  this.isCollapsibleInvalidReason=null;
  this.cntrCntctInvalid=false;
  this.cntrCntctInvalidReason=null;
  this.cntctPhoneInvalid=false;
  this.cntctPhoneInvlidReason=null;
  this.cntrActiveInvalid=false;
  this.cntrActiveInvalidReason=null;
  }

  saveCntrAdminDetsInit()
  {
    this.saveEditContainerCntrAdmin = 
    { 
      cd_cntr:0,
      cntr_suff_ind:'',
      exp_refundable:'',
      cntr_active_ind:'',
      cntr_base:'',
      cntr_suffix:'',
      cntr_desc:'',
      card_call:'',
      cntr_mstr_catg:'',
      cntr_typ_desc:'',
      ownrshp_strat_code:'',
      ownrshp_catg:'',
      cntr_grp_desc:'',
      cntr_exterior_l:0,
      cntr_exterior_w:0,
      cntr_exterior_h:0,
      collapsed_ht:0,
      nest_ratio:0,
      cntr_interior_l:0,
      cntr_interior_w:0,
      cntr_interior_h:0,
      cntr_tare_wt:0,
      cntr_capacity:0,
      cntr_per_layer:0,
      max_layers_shpg_unit:0,
      max_cntr_per_shpg_unit:0,
      cntr_matr_desc:'',
      cntr_drawing_no:'',
      shpg_unit_exterior_l:0,
      shpg_unit_exterior_w:0,
      shpg_unit_exterior_h:0,
      shpg_unit_nstdorflded_ht:0,
      shpg_unit_tare_wt:0,
      shpg_unit_stack_ht:0,
      warehouse_stack_ht:0,
      service_life:'',
      currency_desc:'',
      purchase_cost:0,
      rental_issue_cost:0,
      sup_rntl_dly_cost:0,
      ford_rntl_dly_cost:0,
      return_rntl_cost:0,
      tooling_dev_cost:0,
      extr_cntr_dsgn_img_ind:'',
      intr_cntr_dsgn_img_ind:'',
      shpg_unit_img_ind:'',
      cad_img_ind:'',
      img_notes:'',
      cd_region:'',
      iscontainercolapsble:'',
      cd_plant: "",
      cnt_qt_per_min_su: 0,
      nest_ratio_1: 0,
      ford_cntct: "",
      cntct_phone: "",
      req_stat: "",
      id_user: "",
      ts_last_update: "",
      uom:'E',
      typ_no:0,
      }
  
  }

  openRequestByClickingCntr(rowData:DBIAContainerRequestsGrid)
  {
    this.selectedRequestNumber=rowData.dbia_req_no;
    if(['01'].includes(rowData.cntr_typ_desc))
    {
      this.enableRequestDetails()
    }
    else if(['02','03'].includes(rowData.cntr_typ_desc)) // removed ,"04","05"
    {
      this.enableRequestDetailsComp();
    }

  }


  openRequestByClickingTableApprove(rowData:DBIAContainerRequestsGrid)
  {
    this.selectedRequestNumber=rowData.dbia_req_no;
    if(['01'].includes(rowData.cntr_typ_desc))
    {
      this.enableRequestDetails()
    }
    else if(['02','03'].includes(rowData.cntr_typ_desc)) // removed ,"04","05"
    {
      this.enableRequestDetailsComp();
    }
  }


  onChangeRetTypeEditCntr()
{
  console.log(this.containerEditRequestDetials.cntr_matr_desc);

  let edit_ret_type='';

  if(this.containerEditRequestDetials.exp_refundable != '')
  {
  if(this.edit_selected_cntr_type_code != null && this.edit_selected_cntr_type_code.cntr_typ_desc != '')
  {

    if(this.containerEditRequestDetials.exp_refundable.toLowerCase() =='returnable')
    {
      edit_ret_type= 'R';

    }
    else
    {
      edit_ret_type= 'E';
    }
   this.edit_selected_cntr_material={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
    // api to get material list

    this.transactionService.getContainerMaterial(this.edit_selected_cntr_type_code.typ_no,edit_ret_type).subscribe(mat_list=>{

      this.cntrMaterial=[];
      this.cntrMaterial=mat_list;


    if(this.cntrMaterial!= null && this.cntrMaterial.length!=0 && this.containerEditRequestDetials!= null && this.containerEditRequestDetials.cntr_matr_desc != null)
    {
    for(const t of this.cntrMaterial)
    {
      if(t.cntr_matr_name.toLowerCase() == this.containerEditRequestDetials.cntr_matr_desc.toLowerCase())
      {
        this.edit_selected_cntr_material=t;
        break;
      }
    }
    }

  })
    this.enable_material_dropdown=true;
  }
    else
  {
    this.enable_material_dropdown=false;
    this.edit_selected_cntr_material={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
  }


}

else
{
  this.enable_material_dropdown=false;
  this.edit_selected_cntr_material={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
}




}

// material api changes

onChangeCntrTypEditCntr()
{


  console.log(this.containerEditRequestDetials.cntr_matr_desc);

  let edit_ret_type='';


  if(this.edit_selected_cntr_type_code != null && this.edit_selected_cntr_type_code.cntr_typ_desc != '')
  {
    if(this.containerEditRequestDetials.exp_refundable != '')
    {

    if(this.containerEditRequestDetials.exp_refundable.toLowerCase() =='returnable')
    {
      edit_ret_type= 'R';

    }
    else
    {
      edit_ret_type= 'E';
    }

     this.edit_selected_cntr_material={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
    // api to get material list


    this.transactionService.getContainerMaterial(this.edit_selected_cntr_type_code.typ_no,edit_ret_type).subscribe(mat_list=>{

      this.cntrMaterial=[];
      this.cntrMaterial=mat_list;


    if(this.cntrMaterial!= null && this.cntrMaterial.length!=0 && this.containerEditRequestDetials!= null && this.containerEditRequestDetials.cntr_matr_desc != null)
    {
    for(const t of this.cntrMaterial)
    {
      if(t.cntr_matr_name.toLowerCase() == this.containerEditRequestDetials.cntr_matr_desc.toLowerCase())
      {
        this.edit_selected_cntr_material=t;
        break;
      }
    }
    }
  })
    this.enable_material_dropdown=true;
  }
  else
  {
    this.enable_material_dropdown=false;
    this.edit_selected_cntr_material={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
  }


}

else
{
  this.enable_material_dropdown=false;
  this.edit_selected_cntr_material={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
}


}


  enableRequestDetails()
  {
    console.log('Request Number DBIA',this.selectedRequestNumber);
    // service to get request detials by passing request number
    this.edit_selected_cntr_master_category={cd_cntr_mstr_catg:0,cntr_mstr_catg_desc:'',updated_by:'',updated_dt:'',cntr_mstr_catg:'',active_stat:'',active_stat_display:false};
    this.edit_selected_cntr_type_code={cd_cntr_typ:'',cntr_typ_desc:'',typ_no:0};
    this.edit_selected_cntr_ownrship_strat_code={cd_region:'',ownrshp_strat_code:'',ownrshp_desc:'',cd_ownrshp_strat:0};
    this.edit_selected_ownrship_catg={ownrshp_catg:'',ownrshp_catg_val:''};
    this.edit_selected_cntr_group={cntr_grp:''}
    this.edit_selected_cntr_material={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
    this.edit_selected_cntr_currency_list={cd_curr:0,curr_desc:''};
    this.editSelectedPlantCode={cd_plant:'',plantGSDBCode:'',plant_name:'',plant_active_stat:'',plant_type_name:''}

    this.transactionService.getContainerRequestDetailsByReqNo(this.selectedRequestNumber).subscribe(savecntrdetails=>{
      console.log('Container details by req no',savecntrdetails)


    this.validationInit();
    this.requestEditDetailsInit();
    this.enableApproveRejectCompRequest=false;
    this.enableApproveRejectRequest=true;
    this.rejectionComments=null;
    this.enable_dialog_notification_cntr=false;

    this.transactionService.getSupInfo(savecntrdetails.id_user).subscribe(suppdetls=>{
      if(suppdetls!=null)
      {
        console.log(suppdetls)
        this.suppGSDBCode=suppdetls.suppliergsdbcode;
        this.suppName=suppdetls.fullname;
        this.SuppCntct=suppdetls.contactphone;
        this.suppEmail=suppdetls.contactemail;
      }
    })

   // this.enableeditcntrdetails=true;
    this.containerEditRequestDetials.function=savecntrdetails.function;
    this.containerEditRequestDetials.dbia_req_no=savecntrdetails.dbia_req_no
    this.containerEditRequestDetials.cntr_base=savecntrdetails.cntr_base;
    this.containerEditRequestDetials.cntr_suff_ind=savecntrdetails.cntr_suff_ind;
    this.containerEditRequestDetials.exp_refundable=savecntrdetails.exp_refundable;
    this.containerEditRequestDetials.cntr_active_ind=savecntrdetails.cntr_active_ind;
    this.containerEditRequestDetials.cntr_suffix=savecntrdetails.cntr_suffix;
    // this.containerEditRequestDetials.cntr_suffix=this.selectedSuffixList.suppliergsdbcode;
    if(savecntrdetails.cntr_desc != null)
    {
    this.containerEditRequestDetials.cntr_desc=savecntrdetails.cntr_desc.trim();
    }
    this.containerEditRequestDetials.card_call=savecntrdetails.card_call;
    this.containerEditRequestDetials.cntr_mstr_catg=savecntrdetails.cntr_mstr_catg;
    this.containerEditRequestDetials.cntr_typ_desc=savecntrdetails.cntr_typ_desc;
    this.containerEditRequestDetials.typ_no=savecntrdetails.typ_no;
    this.containerEditRequestDetials.ownrshp_strat_code=savecntrdetails.ownrshp_strat_code;
    this.containerEditRequestDetials.ownrshp_catg=savecntrdetails.ownrshp_catg;
    this.containerEditRequestDetials.cntr_grp_desc=savecntrdetails.cntr_grp_desc;
    this.containerEditRequestDetials.cntr_matr_desc=savecntrdetails.cntr_matr_desc;
    this.containerEditRequestDetials.currency_desc=savecntrdetails.currency_desc;
    this.containerEditRequestDetials.iscontainercolapsble=savecntrdetails.iscontainercolapsble;



  /* Removing add suffix to container
  if(this.containerEditRequestDetials.cntr_suff_ind=='Yes')
  {
    this.cntrSuffixEditenabled=true;
  }
  else
  {
    this.cntrSuffixEditenabled=false;
  }*/


     // this.cntrSuffixEditenabled=false;

  if(this.cntrMasterCategory != null && this.cntrMasterCategory.length != 0)
  {
    for(let i=0;i<this.cntrMasterCategory.length;i++)
    {
      if(this.containerEditRequestDetials.cntr_mstr_catg != null && this.containerEditRequestDetials.cntr_mstr_catg==this.cntrMasterCategory[i].cntr_mstr_catg)
      {
        this.edit_selected_cntr_master_category=this.cntrMasterCategory[i];
      }
    }
  }

  if(this.cntrTypeCode != null && this.cntrTypeCode.length != 0)
  {
    for(let i=0;i<this.cntrTypeCode.length;i++)
    {
      if(this.containerEditRequestDetials.typ_no != null && this.containerEditRequestDetials.typ_no==this.cntrTypeCode[i].typ_no)
      {
        this.edit_selected_cntr_type_code=this.cntrTypeCode[i];
        console.log('edit_selected_cntr_type_code',this.edit_selected_cntr_type_code);
      }
    }
  }

  if(this.cntrOwnershipstratcode != null && this.cntrOwnershipstratcode.length != 0)
  {
    for(let i=0;i<this.cntrOwnershipstratcode.length;i++)
    {
      if(this.containerEditRequestDetials.ownrshp_strat_code != null && this.containerEditRequestDetials.ownrshp_strat_code==this.cntrOwnershipstratcode[i].ownrshp_strat_code)
      {
        this.edit_selected_cntr_ownrship_strat_code=this.cntrOwnershipstratcode[i];
      }
    }
  }

  if(this.ownrship_catg != null && this.ownrship_catg.length != 0)
  {
    for(let i=0;i<this.ownrship_catg.length;i++)
    {
      if(this.containerEditRequestDetials.ownrshp_catg != null && this.containerEditRequestDetials.ownrshp_catg.toLowerCase().indexOf(this.ownrship_catg[i].ownrshp_catg_val.toLowerCase())>-1)
      {
        this.edit_selected_ownrship_catg=this.ownrship_catg[i];
      }
    }
    console.log('ownshp cat',this.edit_selected_ownrship_catg)
  }

  if(this.cntrGrp != null && this.cntrGrp.length != 0)
  {
    for(let i=0;i<this.cntrGrp.length;i++)
    {
      if(this.containerEditRequestDetials.cntr_grp_desc != null && this.containerEditRequestDetials.cntr_grp_desc==this.cntrGrp[i].cntr_grp)
      {
        this.edit_selected_cntr_group=this.cntrGrp[i];
      }
    }
  }

    // for(let i=0;i<this.cntrMaterial.length;i++)
    // {
    //   if(this.containerEditRequestDetials.cntr_matr_desc==this.cntrMaterial[i].cntr_matr_name)
    //   {
    //     this.edit_selected_cntr_material=this.cntrMaterial[i];
    //   }
    // }
    this.onChangeCntrTypEditCntr();

    if(this.cntrCurrencyList != null && this.cntrCurrencyList.length != 0)
    {
    for(let i=0;i<this.cntrCurrencyList.length;i++)
    {
      if(this.containerEditRequestDetials.currency_desc != null && this.containerEditRequestDetials.currency_desc==this.cntrCurrencyList[i].curr_desc)
      {
        this.edit_selected_cntr_currency_list=this.cntrCurrencyList[i];
      }
    }
  }

    this.containerEditRequestDetials.cntr_exterior_l=savecntrdetails.cntr_exterior_l;
    this.containerEditRequestDetials.cntr_exterior_w=savecntrdetails.cntr_exterior_w;
    this.containerEditRequestDetials.cntr_exterior_h=savecntrdetails.cntr_exterior_h;
    this.containerEditRequestDetials.collapsed_ht=savecntrdetails.collapsed_ht;
    this.containerEditRequestDetials.nest_ratio=savecntrdetails.nest_ratio;
    this.containerEditRequestDetials.cntr_interior_l=savecntrdetails.cntr_interior_l;
    this.containerEditRequestDetials.cntr_interior_w=savecntrdetails.cntr_interior_w;
    this.containerEditRequestDetials.cntr_interior_h=savecntrdetails.cntr_interior_h;
    this.containerEditRequestDetials.cntr_tare_wt=savecntrdetails.cntr_tare_wt;
    this.containerEditRequestDetials.cntr_capacity=savecntrdetails.cntr_capacity;
    this.containerEditRequestDetials.cntr_per_layer=savecntrdetails.cntr_per_layer;
    this.containerEditRequestDetials.max_layers_shpg_unit=savecntrdetails.max_layers_shpg_unit;
    this.containerEditRequestDetials.max_cntr_per_shpg_unit=savecntrdetails.max_cntr_per_shpg_unit;
    this.containerEditRequestDetials.cntr_drawing_no=savecntrdetails.cntr_drawing_no;
    this.containerEditRequestDetials.shpg_unit_exterior_l=savecntrdetails.shpg_unit_exterior_l;
    this.containerEditRequestDetials.shpg_unit_exterior_w=savecntrdetails.shpg_unit_exterior_w;
    this.containerEditRequestDetials.shpg_unit_exterior_h=savecntrdetails.shpg_unit_exterior_h;
    this.containerEditRequestDetials.shpg_unit_nstdorflded_ht=savecntrdetails.shpg_unit_nstdorflded_ht;
    this.containerEditRequestDetials.shpg_unit_tare_wt=savecntrdetails.shpg_unit_tare_wt;
    this.containerEditRequestDetials.shpg_unit_stack_ht=savecntrdetails.shpg_unit_stack_ht;
    this.containerEditRequestDetials.warehouse_stack_ht=savecntrdetails.warehouse_stack_ht;
    this.containerEditRequestDetials.service_life=savecntrdetails.service_life;
    this.containerEditRequestDetials.purchase_cost=savecntrdetails.purchase_cost;
    this.containerEditRequestDetials.rental_issue_cost=savecntrdetails.rental_issue_cost;
    this.containerEditRequestDetials.sup_rntl_dly_cost=savecntrdetails.sup_rntl_dly_cost;
    this.containerEditRequestDetials.ford_rntl_dly_cost=savecntrdetails.ford_rntl_dly_cost;
    this.containerEditRequestDetials.return_rntl_cost=savecntrdetails.return_rntl_cost;
    this.containerEditRequestDetials.tooling_dev_cost=savecntrdetails.tooling_dev_cost;
    this.containerEditRequestDetials.extr_cntr_dsgn_img_ind=savecntrdetails.extr_cntr_dsgn_img_ind;
    this.containerEditRequestDetials.intr_cntr_dsgn_img_ind=savecntrdetails.intr_cntr_dsgn_img_ind;
    this.containerEditRequestDetials.cad_img_ind=savecntrdetails.cad_img_ind;
    this.containerEditRequestDetials.img_notes=savecntrdetails.img_notes;
    this.containerEditRequestDetials.cd_region=savecntrdetails.cd_region;

    console.log('containerEditRequestDetials',this.containerEditRequestDetials);





    console.log('edit_selected_plant_code',savecntrdetails.cd_plant);
    if(savecntrdetails.cd_plant != null)
    {
    this.containerEditRequestDetials.cd_plant= savecntrdetails.cd_plant;

    for(let i=0;i<this.plantList.length;i++)
    {
      if(this.containerEditRequestDetials.cd_plant.trim()==this.plantList[i].plantGSDBCode)
      {
        this.editSelectedPlantCode=this.plantList[i];
        console.log('edit_selected_plant_code',this.editSelectedPlantCode);
      }
    }

    }
    if(savecntrdetails.cntr_qt_per_min_su!= null)
    {
      this.containerEditRequestDetials.cntr_qt_per_min_su=savecntrdetails.cntr_qt_per_min_su;

    }
    if(savecntrdetails.nest_ratio_1 != null)
    {
      this.containerEditRequestDetials.nest_ratio_1=savecntrdetails.nest_ratio_1;

    }
    /*if(savecntrdetails.ford_cntct != null)
    {
      this.containerEditRequestDetials.ford_cntct= savecntrdetails.ford_cntct.trim();

    }*/

    this.containerEditRequestDetials.ford_cntct=this.transactionService.getcdsid();
    if(savecntrdetails.cntct_phone != null)
    {
      this.containerEditRequestDetials.cntct_phone=savecntrdetails.cntct_phone.trim();
    }


    if(savecntrdetails.img_notes != null)
    {
      this.containerEditRequestDetials.img_notes=savecntrdetails.img_notes.trim();
    }


    // this.containerEditRequestDetials.req_stat="New";
   // this.containerEditRequestDetials.id_user=this.transactionService.getcdsid();;


     this.containerEditRequestDetials.req_stat=savecntrdetails.req_stat;
    this.containerEditRequestDetials.id_user=savecntrdetails.id_user;
    this.containerEditRequestDetials.ts_last_update='';
    if(savecntrdetails.uoM != null && savecntrdetails.uoM.trim() != '')
    {
    this.containerEditRequestDetials.UoM=savecntrdetails.uoM;
    }
    else
    {
      this.containerEditRequestDetials.UoM = 'E'
    }

    if(this.containerEditRequestDetials.UoM.toLowerCase() == 'm')
    {

      this.edit_measure='kg/cm'
      this.prev_edit_measure='kg/cm'
    }
    else
    {
      this.edit_measure='lbs/inch'
      this.prev_edit_measure='lbs/inch'
    }



    this.requestOriginalDetailsInit();
    this.containerOriginalRequestDetials=JSON.parse(JSON.stringify(this.containerEditRequestDetials));


  })




  }




validateCntrBaseForEdit()
{
  if(this.containerEditRequestDetials.cntr_base==null || this.containerEditRequestDetials.cntr_base.trim()=='')
  {
    this.cntrBaseInvalid=true;
    this.cntrBaseInvalidReason='Required'
  }
  else
  {
    this.cntrBaseInvalid=false;
    this.cntrBaseInvalidReason=null
  }
}

validateCntrDescForEdit()
{
  if(this.containerEditRequestDetials.cntr_desc==null || this.containerEditRequestDetials.cntr_desc.trim()=='')
  {
    this.cntrDescInvalid=true;
    this.cntrDescInvalidReason='Required'
  }
  else
  {
    this.cntrDescInvalid=false;
    this.cntrDescInvalidReason=null
  }
}

validatePlantCodeForEdit()
{
  if(this.editSelectedPlantCode.plantGSDBCode==null || this.editSelectedPlantCode.plantGSDBCode.trim()=='')
  {
    this.plantCodeInvalid=true;
    this.plantCodeInvalidReason='Required'
  }
  else
  {
    this.plantCodeInvalid=false;
    this.plantCodeInvalidReason=null
  }
}

validateCardOrCallForEdit()
{
  if(this.containerEditRequestDetials.card_call==null || this.containerEditRequestDetials.card_call.trim()=='')
  {
    this.cardOrCallInvalid=true;
    this.cardOrCallInvalidReason='Required'
  }
  else
  {
    this.cardOrCallInvalid=false;
    this.cardOrCallInvalidReason=null
  }
}


validateMastCatgForEdit()
{
  if(this.edit_selected_cntr_master_category.cntr_mstr_catg==null || this.edit_selected_cntr_master_category.cntr_mstr_catg.trim()=='')
  {
    this.mastrCatgInvalid=true;
    this.mastrCatgInvalidReason='Required'
  }
  else
  {
    this.mastrCatgInvalid=false;
    this.mastrCatgInvalidReason=null
  }
}

validateCntrRetTypeForEdit()
{
  if(this.containerEditRequestDetials.exp_refundable==null || this.containerEditRequestDetials.exp_refundable.trim()=='')
  {
    this.retTypeInvalid=true;
    this.retTypeInvalidReason='Required'
  }
  else
  {
    this.retTypeInvalid=false;
    this.retTypeInvalidReason=null;
  }
}

validateCntrTypeCodeForEdit()
{
  if(this.edit_selected_cntr_type_code.cntr_typ_desc==null || this.edit_selected_cntr_type_code.cntr_typ_desc.trim()=='')
  {
    this.cntrTypeInvalid=true;
    this.cntrTypeInvalidReason='Required';
  }
  else
  {
    this.cntrTypeInvalid=false;
    this.cntrTypeInvalidReason=null;
  }
}

validateCntrMaterialForEdit()
{
  if(this.edit_selected_cntr_material.cntr_matr_name==null || this.edit_selected_cntr_material.cntr_matr_name.trim()=='')
  {
    this.cntrMaterialInvalid=true;
    this.cntrMaterialInvalidReason='Required';
  }
  else
  {
    this.cntrMaterialInvalid=false;
    this.cntrMaterialInvalidReason=null;
  }
}

validateCOSForEdit()
{
  if(this.edit_selected_cntr_ownrship_strat_code.ownrshp_strat_code==null || this.edit_selected_cntr_ownrship_strat_code.ownrshp_strat_code.trim()=='')
  {
    this.cosInvalid=true;
    this.cosInvalidReason='Required';
  }
  else
  {
    this.cosInvalid=false;
    this.cosInvalidReason=null;
  }
}

validateCntrGrpForEdit()
{
  if(this.edit_selected_cntr_group.cntr_grp==null || this.edit_selected_cntr_group.cntr_grp.trim()=='')
  {
    this.cntrGrpInvalid=true;
    this.cntrGrpInvalidReason='Required';
  }
  else
  {
    this.cntrGrpInvalid=false;
    this.cntrGrpInvalidReason=null;
  }
}

validateCntrCollapsibleForEdit()
{
  if(this.containerEditRequestDetials.iscontainercolapsble==null || this.containerEditRequestDetials.iscontainercolapsble.trim()=='')
  {
    this.isCollapsibleInvalid=true;
    this.isCollapsibleInvalidReason='Required';
  }
  else{
    this.isCollapsibleInvalid=false;
    this.isCollapsibleInvalidReason=null;
  }
}

validateCntrContactForEdit()
{
  if(this.containerEditRequestDetials.ford_cntct==null || this.containerEditRequestDetials.ford_cntct.trim()=='')
  {
    this.cntrCntctInvalid=true;
    this.cntrCntctInvalidReason='Required';
  }
  else
  {
    this.cntrCntctInvalid=false;
    this.cntrCntctInvalidReason=null;
  }
}

validateCntrContactPhoneForEdit()
{
  if(this.containerEditRequestDetials.cntct_phone==null || this.containerEditRequestDetials.cntct_phone.trim()=='')
  {
    this.cntctPhoneInvalid=true;
    this.cntctPhoneInvlidReason='Required';
  }
  else
  {
    this.cntctPhoneInvalid=false;
    this.cntctPhoneInvlidReason=null;
  }
}

validateCntrActiveForEdit()
{
  if(this.containerEditRequestDetials.cntr_active_ind==null || this.containerEditRequestDetials.cntr_active_ind.trim()=='')
  {
    this.cntrActiveInvalid=true;
    this.cntrActiveInvalidReason='Required';
  }
  else
  {
    this.cntrActiveInvalid=false;
    this.cntrActiveInvalidReason=null;
  }
}


validateCollapsedHtForEdit()
{
  if(this.containerEditRequestDetials.iscontainercolapsble.toLowerCase()=='y' && (this.containerEditRequestDetials.collapsed_ht==null || this.containerEditRequestDetials.collapsed_ht==0))
  {
    this.collapsedHtInValid=true;
    this.collapsedHtInValidReason='Required';
  }

  else if(this.containerEditRequestDetials.iscontainercolapsble.toLowerCase()=='y' && (this.containerEditRequestDetials.collapsed_ht<=0 || this.containerEditRequestDetials.collapsed_ht>999.999 ))
  {
    this.collapsedHtInValid=true;
    this.collapsedHtInValidReason='Should be of range 0 to 999.999';
  }
  else if(this.containerEditRequestDetials.iscontainercolapsble.toLowerCase()=='y' && this.containerEditRequestDetials.collapsed_ht > this.containerEditRequestDetials.cntr_exterior_h)
  {
    this.collapsedHtInValid=true;
    this.collapsedHtInValidReason='Collapsed Height cannot be greater than Container height';
  }
  else if(this.containerEditRequestDetials.iscontainercolapsble.toLowerCase()=='y' && String(this.containerEditRequestDetials.collapsed_ht).includes('.'))
  {
    const colap_ht = String(this.containerEditRequestDetials.collapsed_ht).split('.')
    if(colap_ht[1].length > 3)
    {
      this.collapsedHtInValid=true;
      this.collapsedHtInValidReason='Max Length Of decimal values can be 3';
    }
    else
    {
      this.collapsedHtInValid=false;
      this.collapsedHtInValidReason=null;
    }
  }

  else
  {
    this.collapsedHtInValid=false;
    this.collapsedHtInValidReason=null;
  }
}

validateNestRatioForEdit()
{
  if(this.containerEditRequestDetials.nest_ratio==null || this.containerEditRequestDetials.nest_ratio==0)
  {
    this.nestRatioInValid=true;
    this.nestRatioInValidReason='Required';
  }
  else if(this.containerEditRequestDetials.nest_ratio<1 || this.containerEditRequestDetials.nest_ratio>99 || String(this.containerEditRequestDetials.nest_ratio).includes('.'))
  {
    this.nestRatioInValid=true;
    this.nestRatioInValidReason='Should be of range 1 to 99 and cannot contain Decimal';
  }
  else
  {
    this.nestRatioInValid=false;
    this.nestRatioInValidReason=null;
  }
}
validateNestRatio1ForEdit()
{
  if(this.containerEditRequestDetials.nest_ratio_1==null || this.containerEditRequestDetials.nest_ratio_1==0)
  {
    this.nestRatio1InValid=true;
    this.nestRatio1InValidReason='Required';
  }
  else if(this.containerEditRequestDetials.nest_ratio_1<1 || this.containerEditRequestDetials.nest_ratio_1>9 || String(this.containerEditRequestDetials.nest_ratio_1).includes('.'))
  {
    this.nestRatio1InValid=true;
    this.nestRatio1InValidReason='Should be of range 1 to 9 and cannot contain Decimal';
  }
  else
  {
    this.nestRatio1InValid=false;
    this.nestRatio1InValidReason=null;
  }
}
validateCntrTareWeightForEdit()
{
  const cont_tar_wt = String(this.containerEditRequestDetials.cntr_tare_wt).split('.');
  if(this.containerEditRequestDetials.cntr_tare_wt==null || this.containerEditRequestDetials.cntr_tare_wt==0)
  {
    this.cntrTareWtInValid=true;
    this.cntrTareWtInValidReason='Required';
  }
  else if(!String(this.containerEditRequestDetials.cntr_tare_wt).includes('.'))
  {
    /*if( String(this.containerEditRequestDetials.cntr_tare_wt).length>10)
    {
      this.cntrTareWtInValid=true;
      this.cntrTareWtInValidReason="Max Length Of Characters can be 10";
    }
    else*/
     if(this.containerEditRequestDetials.cntr_tare_wt<=0 || this.containerEditRequestDetials.cntr_tare_wt>99999.999)
    {
      this.cntrTareWtInValid=true;
      this.cntrTareWtInValidReason='Should be of range 0 to 99999.999';
    }
    else
    {
      this.cntrTareWtInValid=false;
      this.cntrTareWtInValidReason=null;
    }
  }
  else if(String(this.containerEditRequestDetials.cntr_tare_wt).includes('.'))
  {
    if(String(cont_tar_wt[0]).length<0 || String(cont_tar_wt[0]).length>5)
    {
      this.cntrTareWtInValid=true;
      this.cntrTareWtInValidReason='Should be of range 0 to 99999.999';
    }
    else if(String(cont_tar_wt[1]).length<0 || String(cont_tar_wt[1]).length>3)
    {
      this.cntrTareWtInValid=true;
      this.cntrTareWtInValidReason='Max Length Of decimal values can be 3';
    }
    else
    {
      this.cntrTareWtInValid=false;
      this.cntrTareWtInValidReason=null;
    }
  }
  else
  {
    this.cntrTareWtInValid=false;
    this.cntrTareWtInValidReason=null;
  }
}
validateCntrCapWtForEdit()
{
  const cont_cap_wt = String(this.containerEditRequestDetials.cntr_capacity).split('.');
  if(this.containerEditRequestDetials.cntr_capacity==null || this.containerEditRequestDetials.cntr_capacity==0)
  {
    this.cntrCapWtInValid=true;
    this.cntrCapWtInValidReason='Required';
  }
  else if(this.containerEditRequestDetials.cntr_capacity<=0 || this.containerEditRequestDetials.cntr_capacity>99999.999)
  {
    this.cntrCapWtInValid=true;
    this.cntrCapWtInValidReason='Should be of range 0 to 99999.999';
  }
  else if(String(this.containerEditRequestDetials.cntr_capacity).includes('.'))
    {
      if(cont_cap_wt[1].length > 3)
      {
        this.cntrCapWtInValid=true;
        this.cntrCapWtInValidReason='Max Length Of decimal values can be 3';
      }
      else
      {
        this.cntrCapWtInValid=false;
        this.cntrCapWtInValidReason=null;
      }
    }
  else
  {
    this.cntrCapWtInValid=false;
    this.cntrCapWtInValidReason=null;
  }
}
validateCntrPerLayerForEdit()
{
  if(this.containerEditRequestDetials.cntr_per_layer==null || this.containerEditRequestDetials.cntr_per_layer==0)
  {
    this.cntrPerLayerInValid=true;
    this.cntrPerLayerInValidReason='Required';
  }
  else if(this.containerEditRequestDetials.cntr_per_layer<1 || this.containerEditRequestDetials.cntr_per_layer>99999 || String(this.containerEditRequestDetials.cntr_per_layer).includes('.'))
  {
    this.cntrPerLayerInValid=true;
    this.cntrPerLayerInValidReason='Should be of range 1 to 99999 and cannot contain Decimal';
  }
  else
  {
    this.cntrPerLayerInValid=false;
    this.cntrPerLayerInValidReason=null;
  }
}
validateMaxLayerPerSuForEdit()
{
  if(this.containerEditRequestDetials.max_layers_shpg_unit==null || this.containerEditRequestDetials.max_layers_shpg_unit==0)
  {
    this.maxLayerPerSuInValid=true;
    this.maxLayerPerSuInValidReason='Required';
  }
  else if(this.containerEditRequestDetials.max_layers_shpg_unit<1 || this.containerEditRequestDetials.max_layers_shpg_unit>99999 || String(this.containerEditRequestDetials.max_layers_shpg_unit).includes('.'))
  {
    this.maxLayerPerSuInValid=true;
    this.maxLayerPerSuInValidReason='Should be of range 1 to 99999  and cannot contain Decimal';
  }
  else
  {
    this.maxLayerPerSuInValid=false;
    this.maxLayerPerSuInValidReason=null;
  }
}
validateCntrQtPerMinSuForEdit()
{
  if(this.containerEditRequestDetials.cntr_qt_per_min_su==null || this.containerEditRequestDetials.cntr_qt_per_min_su==0)
  {
    this.cntrQtPerMinSuInValid=true;
    this.cntrQtPerMinSuInvalidReason='Required';
  }
  else if(this.containerEditRequestDetials.cntr_qt_per_min_su<1 || this.containerEditRequestDetials.cntr_qt_per_min_su>99 || String(this.containerEditRequestDetials.cntr_qt_per_min_su).includes('.'))
  {
    this.cntrQtPerMinSuInValid=true;
    this.cntrQtPerMinSuInvalidReason='Should be of range 1 to 99  and cannot contain Decimal';
  }
  else
  {
    this.cntrQtPerMinSuInValid=false;
    this.cntrQtPerMinSuInvalidReason=null;
  }
}
validateMaxCntrPerSuForEdit()
{

  if( (this.containerEditRequestDetials.max_cntr_per_shpg_unit>99999 || String(this.containerEditRequestDetials.max_cntr_per_shpg_unit).includes('.')))
  {
    this.MaxCntrPerSuInValid=true;
    this.MaxCntrPerMinSuInvalidReason='Should be of range 1 to 99999  and cannot contain Decimal';
  }
  else
  {
    this.MaxCntrPerSuInValid=false;
    this.MaxCntrPerMinSuInvalidReason=null;
  }
}
validateCntrExtLForEdit()
{
  const cont_ext_l = String(this.containerEditRequestDetials.cntr_exterior_l).split('.');
  if(this.containerEditRequestDetials.cntr_exterior_l==null || this.containerEditRequestDetials.cntr_exterior_l==0)
  {
    this.cntrExtLInValid=true;
    this.cntrExtLInValidReason='Required';
  }
  else if(!String(this.containerEditRequestDetials.cntr_exterior_l).includes('.'))
  {
    /*if( String(this.containerEditRequestDetials.cntr_exterior_l).length>7)
    {
      this.cntrExtLInValid=true;
      this.cntrExtLInValidReason="Max Length Of Characters can be 7";
    }
    else*/
     if(this.containerEditRequestDetials.cntr_exterior_l<=0 || this.containerEditRequestDetials.cntr_exterior_l>999.999)
    {
      this.cntrExtLInValid=true;
      this.cntrExtLInValidReason='Should be of range 0 to 999.999';
    }
    else
    {
      this.cntrExtLInValid=false;
      this.cntrExtLInValidReason=null;
    }
  }
  else if(String(this.containerEditRequestDetials.cntr_exterior_l).includes('.'))
    {
        if(String(cont_ext_l[0]).length<0 || String(cont_ext_l[0]).length>3)
        {
          this.cntrExtLInValid=true;
          this.cntrExtLInValidReason='Should be of range 0 to 999.999';
        }
        else if(this.containerEditRequestDetials.cntr_exterior_l<=0 || this.containerEditRequestDetials.cntr_exterior_l>999.999 )
        {
          this.cntrExtLInValid=true;
          this.cntrExtLInValidReason='Should be of range 0 to 999.999';
        }
        else if(String(cont_ext_l[1]).length<0 || String(cont_ext_l[1]).length>3)
        {
          this.cntrExtLInValid=true;
          this.cntrExtLInValidReason='Max Length Of decimal values can be 3';
        }
        else
        {
          this.cntrExtLInValid=false;
          this.cntrExtLInValidReason=null;
        }
    }
  else
  {
    this.cntrExtLInValid=false;
    this.cntrExtLInValidReason=null;
  }
}
validateCntrExtWForEdit()
{
  const cont_ext_w = String(this.containerEditRequestDetials.cntr_exterior_w).split('.');
  if(this.containerEditRequestDetials.cntr_exterior_w==null || this.containerEditRequestDetials.cntr_exterior_w==0)
  {
    this.cntrExtWInValid=true;
    this.cntrExtWInValidReason='Required';
  }
  else if(!String(this.containerEditRequestDetials.cntr_exterior_w).includes('.'))
  {
    /*if( String(this.containerEditRequestDetials.cntr_exterior_w).length>7)
    {
      this.cntrExtWInValid=true;
      this.cntrExtWInValidReason="Max Length Of Characters can be 7";
    }
    else*/
     if(this.containerEditRequestDetials.cntr_exterior_w<=0 || this.containerEditRequestDetials.cntr_exterior_w>999.999)
    {
      this.cntrExtWInValid=true;
      this.cntrExtWInValidReason='Should be of range 0 to 999.999';
    }
    else
    {
      this.cntrExtWInValid=false;
      this.cntrExtWInValidReason=null;
    }
  }
  else if(String(this.containerEditRequestDetials.cntr_exterior_w).includes('.'))
  {
      if(String(cont_ext_w[0]).length<0 || String(cont_ext_w[0]).length>3)
      {
        this.cntrExtWInValid=true;
        this.cntrExtWInValidReason='Should be of range 0 to 999.999';
      }
      else if(this.containerEditRequestDetials.cntr_exterior_w<=0 || this.containerEditRequestDetials.cntr_exterior_w>999.999 )
      {
        this.cntrExtWInValid=true;
        this.cntrExtWInValidReason='Should be of range 0 to 999.999';
      }
      else if(String(cont_ext_w[1]).length<0 || String(cont_ext_w[1]).length>3)
      {
        this.cntrExtWInValid=true;
        this.cntrExtWInValidReason='Max Length Of decimal values can be 3';
      }

      else
      {
        this.cntrExtWInValid=false;
        this.cntrExtWInValidReason=null;
      }
  }
  else
  {
    this.cntrExtWInValid=false;
    this.cntrExtWInValidReason=null;
  }
}
validateCntrExtHForEdit()
{
  const cont_ext_h = String(this.containerEditRequestDetials.cntr_exterior_h).split('.');
  if(this.containerEditRequestDetials.cntr_exterior_h==null || this.containerEditRequestDetials.cntr_exterior_h==0)
  {
    this.cntrExtHInValid=true;
    this.cntrExtHInValidReason='Required';
  }
  else if(!String(this.containerEditRequestDetials.cntr_exterior_h).includes('.'))
  {
  if(this.containerEditRequestDetials.cntr_exterior_h<=0 || this.containerEditRequestDetials.cntr_exterior_h>999.999 )
    {
      this.cntrExtHInValid=true;
      this.cntrExtHInValidReason='Should be of range 0 to 999.999';
    }
    /*else if( String(this.containerEditRequestDetials.cntr_exterior_h).length>7)
    {
      this.cntrExtHInValid=true;
      this.cntrExtHInValidReason="Max Length Of Characters can be 7";
    }*/
    else
    {
      this.cntrExtHInValid=false;
      this.cntrExtHInValidReason=null;
    }
  }
  else if(String(this.containerEditRequestDetials.cntr_exterior_h).includes('.'))
  {
    if(String(cont_ext_h[0]).length<0 || String(cont_ext_h[0]).length>3)
    {
      this.cntrExtHInValid=true;
      this.cntrExtHInValidReason='Should be of range 0 to 999.999';
    }
    else if(this.containerEditRequestDetials.cntr_exterior_h<=0 || this.containerEditRequestDetials.cntr_exterior_h>999.999 )
    {
      this.cntrExtHInValid=true;
      this.cntrExtHInValidReason='Should be of range 0 to 999.999';
    }
    else if(String(cont_ext_h[1]).length<0 || String(cont_ext_h[1]).length>3)
    {
      this.cntrExtHInValid=true;
      this.cntrExtHInValidReason='Max Length Of decimal values can be 3';
    }
    else
    {
      this.cntrExtHInValid=false;
      this.cntrExtHInValidReason=null;
    }
  }
  else
  {
    this.cntrExtHInValid=false;
    this.cntrExtHInValidReason=null;
  }
}
validateCntrIntLForEdit()
{
  if(this.containerEditRequestDetials.cntr_interior_l!=null && (this.containerEditRequestDetials.cntr_interior_l>99999))
  {
    this.cntrIntLInValid=true;
    this.cntrIntLInValidReason='Should be of range 1 to 99999';
  }
  else if(this.containerEditRequestDetials.cntr_interior_l!=null && ( this.containerEditRequestDetials.cntr_interior_l>this.containerEditRequestDetials.cntr_exterior_l))
  {
    this.cntrIntLInValid=true;
    this.cntrIntLInValidReason='Interior Length cannot be Greater than Exterior Length';
  }
  else
  {
    this.cntrIntLInValid=false;
    this.cntrIntLInValidReason=null;
  }
}
validateCntrIntWForEdit()
{
  if(this.containerEditRequestDetials.cntr_interior_w!=null && (this.containerEditRequestDetials.cntr_interior_w>99999))
  {
    this.cntrIntWInValid=true;
    this.cntrIntWInValidReason='Should be of range 1 to 99999';
  }
  else if(this.containerEditRequestDetials.cntr_interior_w!=null && (this.containerEditRequestDetials.cntr_interior_w>this.containerEditRequestDetials.cntr_exterior_w))
  {
    this.cntrIntWInValid=true;
    this.cntrIntWInValidReason='Interior width cannot be Greater than Exterior width';
  }
  else
  {
    this.cntrIntWInValid=false;
    this.cntrIntWInValidReason=null;
  }
}
validateCntrIntHForEdit()
{
  if(this.containerEditRequestDetials.cntr_interior_h!=null && (this.containerEditRequestDetials.cntr_interior_h>99999))
  {
    this.cntrIntHInValid=true;
    this.cntrIntHInValidReason='Should be of range 1 to 99999';
  }
  else if(this.containerEditRequestDetials.cntr_interior_h!=null && (this.containerEditRequestDetials.cntr_interior_h>this.containerEditRequestDetials.cntr_exterior_h))
  {
    this.cntrIntHInValid=true;
    this.cntrIntHInValidReason='Interior height cannot be Greater than Exterior height';
  }
  else
  {
    this.cntrIntHInValid=false;
    this.cntrIntHInValidReason=null;
  }
}
validateSuNestedFldedHtForEdit()
{
 /*  if(this.containerEditRequestDetials.shpg_unit_nstdorflded_ht==null || this.containerEditRequestDetials.shpg_unit_nstdorflded_ht==0)
  {
    this.suNestedHtInValid=true;
    this.suNestedHtInValidReason="Required";
  }
  else  */
  if(this.containerEditRequestDetials.shpg_unit_nstdorflded_ht<0 || this.containerEditRequestDetials.shpg_unit_nstdorflded_ht>99999)
  {
    this.suNestedHtInValid=true;
    this.suNestedHtInValidReason='Should be of range 0 to 99999';
  }
  else if(this.containerEditRequestDetials.shpg_unit_nstdorflded_ht>0 && this.containerEditRequestDetials.shpg_unit_exterior_h>0 && this.containerEditRequestDetials.shpg_unit_nstdorflded_ht>this.containerEditRequestDetials.shpg_unit_exterior_h)
  {
    this.suNestedHtInValid=true;
    this.suNestedHtInValidReason='Nested Folded Height cannot be Greater than Exterior height';
  }
  else
  {
    this.suNestedHtInValid=false;
    this.suNestedHtInValidReason=null;
  }
}
validateSuTareWeightForEdit()
{
  const shp_tar_wt = String(this.containerEditRequestDetials.shpg_unit_tare_wt).split('.');
  if(!String(this.containerEditRequestDetials.shpg_unit_tare_wt).includes('.'))
  {
    // if(this.cntrdetailsadd.shpg_unit_tare_wt==null || this.cntrdetailsadd.shpg_unit_tare_wt==0)
    //     {
    //       this.suTareWtInvalid=true;
    //       this.suTareWtInValidReason="Required";
    //     }
    if( String(this.containerEditRequestDetials.shpg_unit_tare_wt).length>10)
    {
      this.suTareWtInvalid=true;
      this.suTareWtInValidReason='Max Length Of Characters can be 10';
    }
    else if(this.containerEditRequestDetials.shpg_unit_tare_wt<1 || this.containerEditRequestDetials.shpg_unit_tare_wt>999999.999)
    {
      this.suTareWtInvalid=true;
      this.suTareWtInValidReason='Should be of range 1 to 999999.999';
    }
    else
    {
      this.suTareWtInvalid=false;
      this.suTareWtInValidReason=null;
    }
  }
  else if(String(this.containerEditRequestDetials.shpg_unit_tare_wt).includes('.'))
  {
    if(String(shp_tar_wt[0]).length<0 || String(shp_tar_wt[0]).length>6)
    {
      this.suTareWtInvalid=true;
      this.suTareWtInValidReason='Should be of range 1 to 999999.999';
    }
    else if(String(shp_tar_wt[1]).length<0 || String(shp_tar_wt[1]).length>3)
    {
      this.suTareWtInvalid=true;
      this.suTareWtInValidReason='Max Length Of decimal values can be 3';
    }
    else
    {
      this.suTareWtInvalid=false;
      this.suTareWtInValidReason=null;
    }
  }
  else
  {
    this.suTareWtInvalid=false;
    this.suTareWtInValidReason=null;
  }
}
validateWareHourseStckHtForEdit()
{
  if(this.containerEditRequestDetials.warehouse_stack_ht==null || this.containerEditRequestDetials.warehouse_stack_ht==0)
  {
    this.wareHourseStackHtInvaid=true;
    this.wareHourseStackHtInvaidReason='Required';
  }
  else if(this.containerEditRequestDetials.warehouse_stack_ht<1 || this.containerEditRequestDetials.warehouse_stack_ht>99 || String(this.containerEditRequestDetials.warehouse_stack_ht).includes('.'))
  {
    this.wareHourseStackHtInvaid=true;
    this.wareHourseStackHtInvaidReason='Should be of range 1 to 99 and cannot contain Decimal';
  }
  else
  {
    this.wareHourseStackHtInvaid=false;
    this.wareHourseStackHtInvaidReason=null;
  }
}
validateShippingUnitStackHtForEdit()
{
  if(this.containerEditRequestDetials.shpg_unit_stack_ht==null || this.containerEditRequestDetials.shpg_unit_stack_ht==0)
  {
    this.suStackHtInvalid=true;
    this.suStackHtInvalidReason='Required';
  }
  else if(this.containerEditRequestDetials.shpg_unit_stack_ht<1 || this.containerEditRequestDetials.shpg_unit_stack_ht>9 || String(this.containerEditRequestDetials.shpg_unit_stack_ht).includes('.'))
  {
    this.suStackHtInvalid=true;
    this.suStackHtInvalidReason='Should be of range 1 to 9 and cannot contain Decimal';
  }
  else
  {
    this.suStackHtInvalid=false;
    this.suStackHtInvalidReason=null;
  }
}
validateSUExtLForEdit()
{
  const shipunit_ext_l = String(this.containerEditRequestDetials.shpg_unit_exterior_l).split('.');
  /* if(this.containerEditRequestDetials.shpg_unit_exterior_l<this.containerEditRequestDetials.cntr_exterior_l)
    {
      this.suExtLInValid=true;
      this.suExtLInValidReason="Shipping Unit Exterior Length cannot be less than Container Exterior Length";
    } */
  if(!String(this.containerEditRequestDetials.shpg_unit_exterior_l).includes('.'))
  {
   /*  if(this.containerEditRequestDetials.shpg_unit_exterior_l==null || this.containerEditRequestDetials.shpg_unit_exterior_l==0)
    {
      this.suExtLInValid=true;
      this.suExtLInValidReason="Required";
    } */
    if(this.containerEditRequestDetials.shpg_unit_exterior_l<0 || this.containerEditRequestDetials.shpg_unit_exterior_l>9999.999 )
    {
      this.suExtLInValid=true;
      this.suExtLInValidReason='Should be of range 0 to 9999.999';
    }
    else if( String(this.containerEditRequestDetials.shpg_unit_exterior_l).length>8)
    {
      this.suExtLInValid=true;
      this.suExtLInValidReason='Max Length Of Characters can be 8';
    }

    else if(this.containerEditRequestDetials.shpg_unit_exterior_l>0 && this.containerEditRequestDetials.shpg_unit_exterior_l<this.containerEditRequestDetials.cntr_exterior_l)
    {
      this.suExtLInValid=true;
      this.suExtLInValidReason='Shipping Unit Exterior Length cannot be less than Container Exterior Length';
    }

    else
    {
      this.suExtLInValid=false;
      this.suExtLInValidReason=null;
    }
  }
  else if(String(this.containerEditRequestDetials.shpg_unit_exterior_l).includes('.'))
  {
    if(String(shipunit_ext_l[0]).length<0 || String(shipunit_ext_l[0]).length>4)
    {
      this.suExtLInValid=true;
      this.suExtLInValidReason='Should be of range 0 to 9999.999';
    }
    else if(this.containerEditRequestDetials.shpg_unit_exterior_l<0 || this.containerEditRequestDetials.shpg_unit_exterior_l>9999.999 )
    {
      this.suExtLInValid=true;
      this.suExtLInValidReason='Should be of range 0 to 9999.999';
    }
    else if(String(shipunit_ext_l[1]).length<0 || String(shipunit_ext_l[1]).length>3)
    {
      this.suExtLInValid=true;
      this.suExtLInValidReason='Max Length Of decimal values can be 3';
    }
    else if(this.containerEditRequestDetials.shpg_unit_exterior_l>0 && this.containerEditRequestDetials.shpg_unit_exterior_l<this.containerEditRequestDetials.cntr_exterior_l)
    {
      this.suExtLInValid=true;
      this.suExtLInValidReason='Shipping Unit Exterior Length cannot be less than Container Exterior Length';
    }
    else
    {
      this.suExtLInValid=false;
      this.suExtLInValidReason=null;
    }
  }
  else
  {
    this.suExtLInValid=false;
    this.suExtLInValidReason=null;
  }
}
validateSUExtWForEdit()
{
  const shipunit_ext_w = String(this.containerEditRequestDetials.shpg_unit_exterior_w).split('.');
  /* if(this.containerEditRequestDetials.shpg_unit_exterior_w<this.containerEditRequestDetials.cntr_exterior_w)
  {
    this.suExtWInValid=true;
    this.suExtWInValidReason="Shipping Unit Exterior Width cannot be less than Container Exterior Width";
  } */
  if(!String(this.containerEditRequestDetials.shpg_unit_exterior_w).includes('.'))
  {
    /* if(this.containerEditRequestDetials.shpg_unit_exterior_w==null || this.containerEditRequestDetials.shpg_unit_exterior_w==0)
    {
      this.suExtWInValid=true;
      this.suExtWInValidReason="Required";
    } */

    if(this.containerEditRequestDetials.shpg_unit_exterior_w<0 || this.containerEditRequestDetials.shpg_unit_exterior_w>9999.999)
    {
      this.suExtWInValid=true;
      this.suExtWInValidReason='Should be of range 0 to 9999.999';
    }
    else if( String(this.containerEditRequestDetials.shpg_unit_exterior_w).length>8)
    {
      this.suExtWInValid=true;
      this.suExtWInValidReason='Max Length Of Characters can be 8';
    }
    else if(this.containerEditRequestDetials.shpg_unit_exterior_w>0 && this.containerEditRequestDetials.shpg_unit_exterior_w<this.containerEditRequestDetials.cntr_exterior_w)
    {
      this.suExtWInValid=true;
      this.suExtWInValidReason='Shipping Unit Exterior Width cannot be less than Container Exterior Width';
    }
    else
      {
        this.suExtWInValid=false;
        this.suExtWInValidReason=null;
      }
  }
  else if(String(this.containerEditRequestDetials.shpg_unit_exterior_w).includes('.'))
  {
      if(String(shipunit_ext_w[0]).length<0 || String(shipunit_ext_w[0]).length>4)
      {
        this.suExtWInValid=true;
        this.suExtWInValidReason='Should be of range 0 to 9999.999';
      }
      else if(this.containerEditRequestDetials.shpg_unit_exterior_w<0 || this.containerEditRequestDetials.shpg_unit_exterior_w>9999.999 )
      {
        this.suExtWInValid=true;
        this.suExtWInValidReason='Should be of range 0 to 9999.999';
      }
      else if(String(shipunit_ext_w[1]).length<0 || String(shipunit_ext_w[1]).length>3)
      {
        this.suExtWInValid=true;
        this.suExtWInValidReason='Max Length Of decimal values can be 3';
      }
      else if(this.containerEditRequestDetials.shpg_unit_exterior_w>0 && this.containerEditRequestDetials.shpg_unit_exterior_w<this.containerEditRequestDetials.cntr_exterior_w)
      {
        this.suExtWInValid=true;
        this.suExtWInValidReason='Shipping Unit Exterior Width cannot be less than Container Exterior Width';
      }
      else
      {
        this.suExtWInValid=false;
        this.suExtWInValidReason=null;
      }
  }
  else
  {
    this.suExtWInValid=false;
    this.suExtWInValidReason=null;
  }
}
validateSUExtHForEdit()
{
  const shipunit_ext_h = String(this.containerEditRequestDetials.shpg_unit_exterior_h).split('.');
    /* if(this.containerEditRequestDetials.shpg_unit_exterior_h<this.containerEditRequestDetials.cntr_exterior_h)
    {
      this.suExtHInValid=true;
      this.suExtHInValidReason="Shipping Unit Exterior Height cannot be less than Container Exterior Height";
    } */
    if(!String(this.containerEditRequestDetials.shpg_unit_exterior_h).includes('.'))
    {
      /* if(this.containerEditRequestDetials.shpg_unit_exterior_h==null || this.containerEditRequestDetials.shpg_unit_exterior_h==0)
      {
        this.suExtHInValid=true;
        this.suExtHInValidReason="Required";
      } */
      if(this.containerEditRequestDetials.shpg_unit_exterior_h<0 || this.containerEditRequestDetials.shpg_unit_exterior_h>9999.999)
      {
        this.suExtHInValid=true;
        this.suExtHInValidReason='Should be of range 0 to 9999.999';
      }
      else if( String(this.containerEditRequestDetials.shpg_unit_exterior_h).length>8)
      {
        this.suExtHInValid=true;
        this.suExtHInValidReason='Max Length Of Characters can be 8';
      }
      else if(this.containerEditRequestDetials.shpg_unit_exterior_h>0 && this.containerEditRequestDetials.shpg_unit_exterior_h<this.containerEditRequestDetials.cntr_exterior_h)
      {
        this.suExtHInValid=true;
        this.suExtHInValidReason='Shipping Unit Exterior Height cannot be less than Container Exterior Height';
      }
      else
      {
        this.suExtHInValid=false;
        this.suExtHInValidReason=null;
      }
  }
  else if(String(this.containerEditRequestDetials.shpg_unit_exterior_h).includes('.'))
  {
      if(String(shipunit_ext_h[0]).length<0 || String(shipunit_ext_h[0]).length>4)
      {
        this.suExtHInValid=true;
        this.suExtHInValidReason='Should be of range 0 to 9999.999';
      }
      else if(this.containerEditRequestDetials.shpg_unit_exterior_h<0 || this.containerEditRequestDetials.shpg_unit_exterior_h>9999.999 )
      {
        this.suExtHInValid=true;
        this.suExtHInValidReason='Should be of range 0 to 9999.999';
      }
      else if(String(shipunit_ext_h[1]).length<0 || String(shipunit_ext_h[1]).length>3)
      {
        this.suExtHInValid=true;
        this.suExtHInValidReason='Max Length Of decimal values can be 3';
      }
      else if(this.containerEditRequestDetials.shpg_unit_exterior_h>0 && this.containerEditRequestDetials.shpg_unit_exterior_h<this.containerEditRequestDetials.cntr_exterior_h)
      {
        this.suExtHInValid=true;
        this.suExtHInValidReason='Shipping Unit Exterior Height cannot be less than Container Exterior Height';
      }
      else
      {
        this.suExtHInValid=false;
        this.suExtHInValidReason=null;
      }
  }
  else
  {
    this.suExtHInValid=false;
    this.suExtHInValidReason=null;
  }
}

approveOrRejectButtonClick(value:string)
{
  
  this.cntrRequestRejectionEmailFormat=[]; // This varaible will have values only in rejection case
  this.cntrRequestApprovalEmailFormat=[];

  this.validateCollapsedHtForEdit();
  this.validateNestRatioForEdit();
  this.validateNestRatio1ForEdit();
  this.validateCntrTareWeightForEdit();
  this.validateCntrCapWtForEdit();
  this.validateCntrPerLayerForEdit();
  this.validateMaxLayerPerSuForEdit();
  this.validateCntrQtPerMinSuForEdit();
  this.validateMaxCntrPerSuForEdit();
  this.validateCntrExtLForEdit();
  this.validateCntrExtWForEdit();
  this.validateCntrExtHForEdit();
  this.validateCntrIntLForEdit();
  this.validateCntrIntWForEdit();
  this.validateCntrIntHForEdit();
  this.validateSuNestedFldedHtForEdit();
  this.validateSuTareWeightForEdit();
  this.validateWareHourseStckHtForEdit();
  this.validateShippingUnitStackHtForEdit();
  this.validateSUExtLForEdit();
  this.validateSUExtWForEdit();
  this.validateSUExtHForEdit();
  this.validateCntrBaseForEdit()
  this.validateCntrDescForEdit()
  // this.validatePlantCodeForEdit()
  this.validateCardOrCallForEdit()
  this.validateMastCatgForEdit()
  this.validateCntrRetTypeForEdit()
  this.validateCntrTypeCodeForEdit()
  this.validateCntrMaterialForEdit()
  this.validateCOSForEdit()
  this.validateCntrGrpForEdit()
  this.validateCntrCollapsibleForEdit()
  this.validateCntrContactForEdit()
  this.validateCntrContactPhoneForEdit()
  this.validateCntrActiveForEdit()

  if(!this.collapsedHtInValid && !this.nestRatioInValid && !this.nestRatio1InValid &&
    !this.cntrTareWtInValid && !this.cntrCapWtInValid && !this.cntrPerLayerInValid &&
    !this.maxLayerPerSuInValid && !this.cntrQtPerMinSuInValid && !this.MaxCntrPerSuInValid &&
    !this.cntrExtLInValid && !this.cntrExtWInValid && !this.cntrExtHInValid && !this.cntrIntLInValid &&
    !this.cntrIntWInValid && !this.cntrIntHInValid && !this.suNestedHtInValid && !this.suTareWtInvalid &&
    !this.wareHourseStackHtInvaid && !this.suStackHtInvalid && !this.suExtLInValid && !this.suExtWInValid &&
    !this.suExtHInValid && !this.cntrBaseInvalid && !this.cntrDescInvalid && !this.plantCodeInvalid &&
    !this.cardOrCallInvalid && !this.mastrCatgInvalid && !this.retTypeInvalid && !this.cntrTypeInvalid &&
    !this.cntrMaterialInvalid && !this.cosInvalid && !this.cntrGrpInvalid && !this.isCollapsibleInvalid &&
    !this.cntrCntctInvalid && !this.cntctPhoneInvalid && !this.cntrActiveInvalid)
  {
    if(value != null && value.toLowerCase().trim() == 'approve')
    {
      this.approveButtonContainerRequest()
    }
    else if(value != null && value.toLowerCase().trim() == 'reject')
    {
      if(this.rejectionComments!=null && this.rejectionComments.trim()!='')
      {
        this.enable_dialog_notification_cntr=false;
        this.rejectButtonContainerRequest()
      }
      else
      {
        this.enable_dialog_notification_cntr=true;
      }
      
      
    }
  }
}

ok_notification_dialog_cntr()
{
  this.enable_dialog_notification_cntr=false;
}

approveButtonContainerRequest()
{
  this.fromRejectButton=false;

  this.containerEditRequestDetials.cd_plant=this.editSelectedPlantCode.plantGSDBCode.trim();
  // this.containerEditRequestDetials.ownrshp_catg="Ford"
  this.containerEditRequestDetials.cntr_grp_desc=this.edit_selected_cntr_group.cntr_grp;
  this.containerEditRequestDetials.cntr_matr_desc=this.edit_selected_cntr_material.cntr_matr_name;
  this.containerEditRequestDetials.cntr_mstr_catg=this.edit_selected_cntr_master_category.cntr_mstr_catg;
  this.containerEditRequestDetials.cntr_typ_desc=String(this.edit_selected_cntr_type_code.cd_cntr_typ);
  this.containerEditRequestDetials.typ_no=this.edit_selected_cntr_type_code.typ_no;
  this.containerEditRequestDetials.ownrshp_catg=this.edit_selected_ownrship_catg.ownrshp_catg_val;
  this.containerEditRequestDetials.ownrshp_strat_code=this.edit_selected_cntr_ownrship_strat_code.ownrshp_strat_code;
  // this.cntrdetailsedit.cntr_suffix=this.selectedSuffixList.suppliergsdbcode;

  if(this.containerEditRequestDetials.cntr_suffix!=null && this.containerEditRequestDetials.cntr_suffix.trim()=="")
  {
    this.containerEditRequestDetials.cntr_suffix=null;
  }


  if(this.containerEditRequestDetials.iscontainercolapsble!=null && this.containerEditRequestDetials.iscontainercolapsble.toLowerCase()=='n')
  {
    this.containerEditRequestDetials.collapsed_ht=1;
  }

  if(this.containerEditRequestDetials.cntr_desc != null)
  {
  this.containerEditRequestDetials.cntr_desc=this.containerEditRequestDetials.cntr_desc.trim();
  }

  if(this.containerEditRequestDetials.img_notes != null)
  {
    this.containerEditRequestDetials.img_notes=this.containerEditRequestDetials.img_notes.trim();
  }

  if(this.containerEditRequestDetials.ford_cntct != null)
  {
  this.containerEditRequestDetials.ford_cntct=this.containerEditRequestDetials.ford_cntct.trim();
  }
  if(this.containerEditRequestDetials.cntct_phone != null)
  {
  this.containerEditRequestDetials.cntct_phone=this.containerEditRequestDetials.cntct_phone.trim();
  }

  if(this.edit_measure=='kg/cm')
{

 this.containerEditRequestDetials.UoM='M'

}
else
{
  this.containerEditRequestDetials.UoM='E'
}

console.log("approveButtonContainerRequest",this.containerEditRequestDetials)

this.containerRequestDetialsForPost=this.containerEditRequestDetials;
this.postUpdatedDetailsToApi();

}
rejectButtonContainerRequest()
{
  

  this.containerEditRequestDetials.cd_plant=this.editSelectedPlantCode.plantGSDBCode.trim();
  //this.containerEditRequestDetials.ownrshp_catg="Ford"
  this.containerEditRequestDetials.cntr_grp_desc=this.edit_selected_cntr_group.cntr_grp;
  this.containerEditRequestDetials.cntr_matr_desc=this.edit_selected_cntr_material.cntr_matr_name;
  this.containerEditRequestDetials.cntr_mstr_catg=this.edit_selected_cntr_master_category.cntr_mstr_catg;
  this.containerEditRequestDetials.cntr_typ_desc=String(this.edit_selected_cntr_type_code.cd_cntr_typ);
  this.containerEditRequestDetials.typ_no=this.edit_selected_cntr_type_code.typ_no;
  this.containerEditRequestDetials.ownrshp_catg=this.edit_selected_ownrship_catg.ownrshp_catg_val;
  this.containerEditRequestDetials.ownrshp_strat_code=this.edit_selected_cntr_ownrship_strat_code.ownrshp_strat_code;
  //this.cntrdetailsedit.cntr_suffix=this.selectedSuffixList.suppliergsdbcode;

  if(this.containerEditRequestDetials.cntr_suffix!=null && this.containerEditRequestDetials.cntr_suffix.trim()=="")
  {
    this.containerEditRequestDetials.cntr_suffix=null;
  }

  if(this.containerEditRequestDetials.iscontainercolapsble!=null && this.containerEditRequestDetials.iscontainercolapsble.toLowerCase()=='n')
  {
    this.containerEditRequestDetials.collapsed_ht=1;
  }

  if(this.containerEditRequestDetials.cntr_desc != null)
  {
  this.containerEditRequestDetials.cntr_desc=this.containerEditRequestDetials.cntr_desc.trim();
  }

  if(this.containerEditRequestDetials.img_notes != null)
  {
    this.containerEditRequestDetials.img_notes=this.containerEditRequestDetials.img_notes.trim();
  }

  if(this.containerEditRequestDetials.ford_cntct != null)
  {
  this.containerEditRequestDetials.ford_cntct=this.containerEditRequestDetials.ford_cntct.trim();
  }
  if(this.containerEditRequestDetials.cntct_phone != null)
  {
  this.containerEditRequestDetials.cntct_phone=this.containerEditRequestDetials.cntct_phone.trim();
  }

  if(this.edit_measure=='kg/cm')
{

 this.containerEditRequestDetials.UoM="M"

}
else
{
  this.containerEditRequestDetials.UoM="E"
}

console.log("RejectButtonContainerRequest",this.containerEditRequestDetials)


this.fromRejectButton=true

  /*const keys1 = Object.keys(this.containerEditRequestDetials);
  var fieldsChanged:boolean=false
  for (let key of keys1) {
  if((typeof(this.containerEditRequestDetials[key]) == "number"  && (this.containerEditRequestDetials[key] !== this.containerOriginalRequestDetials[key])) ||
    (typeof(this.containerEditRequestDetials[key]) == "string"  && (this.containerEditRequestDetials[key] != null && (this.containerEditRequestDetials[key].trim() !== this.containerOriginalRequestDetials[key].trim()))) )
    {
      fieldsChanged=true;
      break;
    }
  }*/

  console.log('original',this.containerOriginalRequestDetials)
  console.log('changed',this.containerEditRequestDetials)

/*
  if(JSON.stringify(this.containerOriginalRequestDetials) !== JSON.stringify(this.containerEditRequestDetials))
  {
    this.confirmPopUp=true;
    this.containerOriginalRequestDetials.components
  } && key != "req_stat"*/
  var fieldsChanged=false;
  const keys1 = Object.keys(this.containerOriginalRequestDetials);
  for (const key of keys1) {
  if(key != 'components' && (
    (typeof(this.containerOriginalRequestDetials[key]) == 'number'  && (this.containerOriginalRequestDetials[key] !== this.containerEditRequestDetials[key])) ||
    (typeof(this.containerOriginalRequestDetials[key]) == 'string'  && (
    (this.containerOriginalRequestDetials[key] != null && this.containerEditRequestDetials[key] != null && this.containerOriginalRequestDetials[key].trim().toLowerCase() !== this.containerEditRequestDetials[key].trim().toLowerCase())
    ||
    (this.containerOriginalRequestDetials[key] != null && this.containerEditRequestDetials[key] == null)
    ||
    (this.containerOriginalRequestDetials[key] == null && this.containerEditRequestDetials[key] != null)
    ))
    ) )
    {
      fieldsChanged=true
    }
  }

  if(fieldsChanged == true)
  {
    this.confirmPopUp=true;
  }

  else
  {
    this.rejectWithOriginalDetails();
  }




}

rejectWithOriginalDetails()
{
  this.confirmPopUp=false;
  this.containerRequestDetialsForPost=this.containerOriginalRequestDetials;
  this.postUpdatedDetailsToApi();

}
rejectWithChangedDetails()
{
  this.confirmPopUp=false;
  this.containerRequestDetialsForPost=this.containerEditRequestDetials;
  this.postUpdatedDetailsToApi();
}

cntrBaseChangeTrigger()
{
  if(this.enableApproveRejectRequest && this.containerEditRequestDetials!=null)
  {
    let cntrSuffixToApi:string=this.containerEditRequestDetials.cntr_suffix;
    if(this.containerEditRequestDetials.cntr_suffix==null || this.containerEditRequestDetials.cntr_suffix.trim()=='')
    {
      cntrSuffixToApi='%20%20%20%20%20%20%20%20';
    }

    this.transactionService.getContainerDetailsFromCMMS(this.containerEditRequestDetials.cntr_base.toUpperCase(),cntrSuffixToApi).subscribe(cntrdetails=>{

      const cntrDetailsFromAPI:cntrDetailsFromCMMS=cntrdetails;
      // this.cntrdetailsfromload=cntrdetails;

      if(cntrDetailsFromAPI!=null)
      {
        this.containerEditRequestDetials.function='C';
      }
      else
      {
        this.containerEditRequestDetials.function='A';
      }
    });
  }

  if(this.enableApproveRejectCompRequest && this.componentEditRequestDetials!=null)
  {
    let cntrSuffixToApi:string=this.componentEditRequestDetials.cntr_suffix;
    if(this.componentEditRequestDetials.cntr_suffix==null || this.componentEditRequestDetials.cntr_suffix.trim()=='')
    {
      cntrSuffixToApi='%20%20%20%20%20%20%20%20';
    }

    this.transactionService.getContainerDetailsFromCMMS(this.componentEditRequestDetials.cntr_base.toUpperCase(),cntrSuffixToApi).subscribe(cntrdetails=>{

      const cntrDetailsFromAPI:cntrDetailsFromCMMS=cntrdetails;
      // this.cntrdetailsfromload=cntrdetails;

      if(cntrDetailsFromAPI!=null)
      {
        this.componentEditRequestDetials.function='C';
      }
      else
      {
        this.componentEditRequestDetials.function='A';
      }
    });
  }
}

postUpdatedDetailsToApi()
{

  console.log('postUpdatedDetailsToApi',this.containerRequestDetialsForPost)

  // this.containerRequestDetialsForPost.id_user=this.transactionService.getcdsid();
  if(this.fromRejectButton == true)
  {
    this.containerRequestDetialsForPost.req_stat="Rejected";

    this.cntrRequestRejectionEmailFormat=[];
    this.cntrRequestRejectionEmailFormat.push({
      "suppGsdbCode":this.suppGSDBCode,
      "cntrBase":this.containerRequestDetialsForPost.cntr_base,
      "cntrSuffix":this.containerRequestDetialsForPost.cntr_suffix,
      "cos":this.containerRequestDetialsForPost.ownrshp_strat_code,
      "retType":this.containerRequestDetialsForPost.exp_refundable,              // Returnable / Expendable
      "cntrType":this.edit_selected_cntr_type_code.cd_cntr_typ+'-'+this.edit_selected_cntr_type_code.cntr_typ_desc,
      "reqFor":1,           //1-Container / 2-Component
      "comments":"Rejected by "+this.userdetails.title+this.userdetails.name+"("+this.userdetails.email+")"+"<br> <br> <u> Rejection Comments: </u> <br>"+this.rejectionComments,
      "plantcode":this.containerRequestDetialsForPost.cd_plant,
      "region":this.region_code,
      "isSupp":"Y"
    })
  }
  else
  {
    this.containerRequestDetialsForPost.req_stat="New";

    this.cntrRequestApprovalEmailFormat=[];
    this.cntrRequestApprovalEmailFormat.push({
      "suppGsdbCode":this.suppGSDBCode,
      "cntrBase":this.containerRequestDetialsForPost.cntr_base,
      "cntrSuffix":this.containerRequestDetialsForPost.cntr_suffix,
      "cos":this.containerRequestDetialsForPost.ownrshp_strat_code,
      "retType":this.containerRequestDetialsForPost.exp_refundable,              // Returnable / Expendable
      "cntrType":this.edit_selected_cntr_type_code.cd_cntr_typ+'-'+this.edit_selected_cntr_type_code.cntr_typ_desc,
      "reqFor":1,           //1-Container / 2-Component
      "comments":"",
      "plantcode":this.containerRequestDetialsForPost.cd_plant,
      "region":this.region_code,
      "isSupp":"Y"
    })
  }
  this.containerRequestDetialsForPost.ts_last_update=this.date.transform(new Date(),'yyyy-MM-dd');

  this.containerEditRequestDetials.currency_desc=this.edit_selected_cntr_currency_list.curr_desc;

  this.containerRequestDetialsForPost.extr_cntr_dsgn_img_ind='No';
  this.containerRequestDetialsForPost.intr_cntr_dsgn_img_ind='No';
  this.containerRequestDetialsForPost.shpg_unit_img_ind='No';
  this.containerRequestDetialsForPost.cad_img_ind='No';


  if(this.containerRequestDetialsForPost.service_life==null)
  {
    this.containerRequestDetialsForPost.service_life='';
  }


  if(this.containerRequestDetialsForPost.cntr_drawing_no == null)
  {
    this.containerRequestDetialsForPost.cntr_drawing_no=''
  }




  this.containerRequestDetialsForPost.ford_rntl_dly_cost=0;
  this.containerRequestDetialsForPost.purchase_cost=0;
  this.containerRequestDetialsForPost.rental_issue_cost=0;
  this.containerRequestDetialsForPost.return_rntl_cost=0;
  this.containerRequestDetialsForPost.sup_rntl_dly_cost=0;
  this.containerRequestDetialsForPost.tooling_dev_cost=0;




  this.validateCollapsedHtForEdit();
  this.validateNestRatioForEdit();
  this.validateNestRatio1ForEdit();
  this.validateCntrTareWeightForEdit();
  this.validateCntrCapWtForEdit();
  this.validateCntrPerLayerForEdit();
  this.validateMaxLayerPerSuForEdit();
  this.validateCntrQtPerMinSuForEdit();
  this.validateMaxCntrPerSuForEdit();
  this.validateCntrExtLForEdit();
  this.validateCntrExtWForEdit();
  this.validateCntrExtHForEdit();
  this.validateCntrIntLForEdit();
  this.validateCntrIntWForEdit();
  this.validateCntrIntHForEdit();
  this.validateSuNestedFldedHtForEdit();
  this.validateSuTareWeightForEdit();
  this.validateWareHourseStckHtForEdit();
  this.validateShippingUnitStackHtForEdit();
  this.validateSUExtLForEdit();
  this.validateSUExtWForEdit();
  this.validateSUExtHForEdit();
  this.validateCntrBaseForEdit()
  this.validateCntrDescForEdit()
  // this.validatePlantCodeForEdit()
  this.validateCardOrCallForEdit()
  this.validateMastCatgForEdit()
  this.validateCntrRetTypeForEdit()
  this.validateCntrTypeCodeForEdit()
  this.validateCntrMaterialForEdit()
  this.validateCOSForEdit()
  this.validateCntrGrpForEdit()
  this.validateCntrCollapsibleForEdit()
  this.validateCntrContactForEdit()
  this.validateCntrContactPhoneForEdit()
  this.validateCntrActiveForEdit()

  if(!this.collapsedHtInValid && !this.nestRatioInValid && !this.nestRatio1InValid &&
    !this.cntrTareWtInValid && !this.cntrCapWtInValid && !this.cntrPerLayerInValid &&
    !this.maxLayerPerSuInValid && !this.cntrQtPerMinSuInValid && !this.MaxCntrPerSuInValid &&
    !this.cntrExtLInValid && !this.cntrExtWInValid && !this.cntrExtHInValid && !this.cntrIntLInValid &&
    !this.cntrIntWInValid && !this.cntrIntHInValid && !this.suNestedHtInValid && !this.suTareWtInvalid &&
    !this.wareHourseStackHtInvaid && !this.suStackHtInvalid && !this.suExtLInValid && !this.suExtWInValid &&
    !this.suExtHInValid && !this.cntrBaseInvalid && !this.cntrDescInvalid && !this.plantCodeInvalid &&
    !this.cardOrCallInvalid && !this.mastrCatgInvalid && !this.retTypeInvalid && !this.cntrTypeInvalid &&
    !this.cntrMaterialInvalid && !this.cosInvalid && !this.cntrGrpInvalid && !this.isCollapsibleInvalid &&
    !this.cntrCntctInvalid && !this.cntctPhoneInvalid && !this.cntrActiveInvalid)
  {


    console.log('postUpdatedDetailsToApi',this.containerRequestDetialsForPost)

    if(!this.fromRejectButton)
    {
    let suffixToAPI:string=this.containerRequestDetialsForPost.cntr_suffix;

    if(this.containerRequestDetialsForPost.cntr_suffix==null || this.containerRequestDetialsForPost.cntr_suffix.trim()=='')
    {
      suffixToAPI='%20%20%20%20%20%20%20%20';
    }

    this.transactionService.getRequestStatusOfContainer(this.containerRequestDetialsForPost.cntr_base,suffixToAPI,this.containerRequestDetialsForPost.ownrshp_strat_code,this.containerRequestDetialsForPost.cd_region).subscribe(requestStatus=>{
      console.log('Request Status',requestStatus);
      const requestStatusReceived=requestStatus;

        console.log(requestStatusReceived)
      if(requestStatusReceived!= null && requestStatusReceived.trim() != '' && requestStatusReceived.toLowerCase() =='pending')
      {
        this.enableCntrEditPendingNotification=true;
      }
      else
      {
        if(this.containerRequestDetialsForPost.function!=null && this.containerRequestDetialsForPost.function!='' && this.containerRequestDetialsForPost.function=='A')
        {
          this.enableAddingNewContainerNotification=true;
        }
        else if(this.containerRequestDetialsForPost.function!=null && this.containerRequestDetialsForPost.function!='' && this.containerRequestDetialsForPost.function=='C')
        {
            if(this.containerRequestDetialsForPost.cntr_suffix==null || this.containerRequestDetialsForPost.cntr_suffix.trim()=='')
            {
              suffixToAPI="-";
            }
            // Checking if NGPP feilds are changed 

            console.log("Came in 3330",this.containerRequestDetialsForPost.cntr_base,suffixToAPI,this.containerRequestDetialsForPost.ownrshp_strat_code,this.containerRequestDetialsForPost.cd_region,this.containerRequestDetialsForPost.cntr_typ_desc)


            this.transactionService.checkWhetherCntrInNgppCount(this.containerRequestDetialsForPost.cntr_base,suffixToAPI,this.containerRequestDetialsForPost.ownrshp_strat_code,this.containerRequestDetialsForPost.cd_region,this.containerRequestDetialsForPost.cntr_typ_desc).subscribe(countDets=>{
              console.log(countDets);
              
              if(countDets == null)
              {
                this.enableUpdateExistingCntrNotification=true;
              }
              else
              {
                this.cntr_countDets = countDets.cd_cntr;
                this.cntrDetsUpdate();
              }
            })
          }
        }
       })
    }
    else
    {
      this.saveCntrToDBIA();
    }
        
  }

  }

  cntrDetsUpdate()
  {
      console.log("Edit Container Request Dets", this.containerEditRequestDetials)
      this.transactionService.editbutton_get_contr_details(this.containerEditRequestDetials.cntr_base,this.cntr_countDets).subscribe(cntrdetails=>{
          this.containerdetails=cntrdetails;

          this.cntrCmmsFieldsBeforeUpdate.cntr_base = this.containerdetails.cntr_base
          this.cntrCmmsFieldsBeforeUpdate.cntr_suffix = this.containerdetails.cntr_suffix
          this.cntrCmmsFieldsBeforeUpdate.cntr_desc = this.containerdetails.cntr_desc
          this.cntrCmmsFieldsBeforeUpdate.cntr_typ_desc = this.containerdetails.cntr_typ_desc
          this.cntrCmmsFieldsBeforeUpdate.cd_plant = this.containerdetails.cd_plant 
          this.cntrCmmsFieldsBeforeUpdate.collapsed_ht = this.containerdetails.collapsed_ht
          this.cntrCmmsFieldsBeforeUpdate.nest_ratio = this.containerdetails.nest_ratio
          this.cntrCmmsFieldsBeforeUpdate.nest_ratio_1 = this.containerdetails.nest_ratio_1
          this.cntrCmmsFieldsBeforeUpdate.cntr_tare_wt = this.containerdetails.cntr_tare_wt
          this.cntrCmmsFieldsBeforeUpdate.cntr_capacity = this.containerdetails.cntr_capacity
          this.cntrCmmsFieldsBeforeUpdate.cntr_qt_per_min_su = this.containerdetails.cnt_qt_per_min_su
          this.cntrCmmsFieldsBeforeUpdate.ford_cntct = this.containerdetails.ford_cntct
          this.cntrCmmsFieldsBeforeUpdate.cntct_phone = this.containerdetails.cntct_phone
          this.cntrCmmsFieldsBeforeUpdate.cntr_exterior_l = this.containerdetails.cntr_exterior_l
          this.cntrCmmsFieldsBeforeUpdate.cntr_exterior_w = this.containerdetails.cntr_exterior_w
          this.cntrCmmsFieldsBeforeUpdate.cntr_exterior_h = this.containerdetails.cntr_exterior_h
          this.cntrCmmsFieldsBeforeUpdate.shpg_unit_stack_ht = this.containerdetails.shpg_unit_stack_ht
          this.cntrCmmsFieldsBeforeUpdate.warehouse_stack_ht = this.containerdetails.warehouse_stack_ht
          this.cntrCmmsFieldsBeforeUpdate.ownrshp_catg = this.containerdetails.ownrshp_catg


          console.log("container Dets", this.containerdetails)
          this.cntrCmmsFieldsAfterUpdate.cntr_base = JSON.parse(JSON.stringify(this.containerEditRequestDetials.cntr_base));
          this.cntrCmmsFieldsAfterUpdate.cntr_suffix = JSON.parse(JSON.stringify(this.containerEditRequestDetials.cntr_suffix));
          this.cntrCmmsFieldsAfterUpdate.cntr_desc = JSON.parse(JSON.stringify(this.containerEditRequestDetials.cntr_desc));
          this.cntrCmmsFieldsAfterUpdate.cntr_typ_desc = JSON.parse(JSON.stringify(this.containerEditRequestDetials.cntr_typ_desc));
          this.cntrCmmsFieldsAfterUpdate.cd_plant = JSON.parse(JSON.stringify(this.containerEditRequestDetials.cd_plant));
          this.cntrCmmsFieldsAfterUpdate.collapsed_ht = JSON.parse(JSON.stringify(this.containerEditRequestDetials.collapsed_ht));
          this.cntrCmmsFieldsAfterUpdate.nest_ratio = JSON.parse(JSON.stringify(this.containerEditRequestDetials.nest_ratio));
          this.cntrCmmsFieldsAfterUpdate.nest_ratio_1 = JSON.parse(JSON.stringify(this.containerEditRequestDetials.nest_ratio_1));
          this.cntrCmmsFieldsAfterUpdate.cntr_tare_wt = JSON.parse(JSON.stringify(this.containerEditRequestDetials.cntr_tare_wt));
          this.cntrCmmsFieldsAfterUpdate.cntr_capacity = JSON.parse(JSON.stringify(this.containerEditRequestDetials.cntr_capacity));
          this.cntrCmmsFieldsAfterUpdate.cntr_qt_per_min_su = JSON.parse(JSON.stringify(this.containerEditRequestDetials.cntr_qt_per_min_su));
          this.cntrCmmsFieldsAfterUpdate.ford_cntct = JSON.parse(JSON.stringify(this.containerEditRequestDetials.ford_cntct ));
          this.cntrCmmsFieldsAfterUpdate.cntct_phone = JSON.parse(JSON.stringify(this.containerEditRequestDetials.cntct_phone));
          this.cntrCmmsFieldsAfterUpdate.cntr_exterior_l = JSON.parse(JSON.stringify(this.containerEditRequestDetials.cntr_exterior_l));
          this.cntrCmmsFieldsAfterUpdate.cntr_exterior_w = JSON.parse(JSON.stringify(this.containerEditRequestDetials.cntr_exterior_w));
          this.cntrCmmsFieldsAfterUpdate.cntr_exterior_h = JSON.parse(JSON.stringify(this.containerEditRequestDetials.cntr_exterior_h));
          this.cntrCmmsFieldsAfterUpdate.shpg_unit_stack_ht = JSON.parse(JSON.stringify(this.containerEditRequestDetials.shpg_unit_stack_ht));
          this.cntrCmmsFieldsAfterUpdate.warehouse_stack_ht = JSON.parse(JSON.stringify(this.containerEditRequestDetials.warehouse_stack_ht));
          this.cntrCmmsFieldsAfterUpdate.ownrshp_catg = JSON.parse(JSON.stringify(this.containerEditRequestDetials.ownrshp_catg));

         
          var cmmsFieldsChanged=false;
            const keys1 = Object.keys(this.cntrCmmsFieldsBeforeUpdate);
            for (let key of keys1) {
              console.log("KEY", key);
            if(
              (typeof(this.cntrCmmsFieldsBeforeUpdate[key]) == "number"  && (this.cntrCmmsFieldsBeforeUpdate[key] !== this.cntrCmmsFieldsAfterUpdate[key])) ||
              (typeof(this.cntrCmmsFieldsBeforeUpdate[key]) == "string"  && (this.cntrCmmsFieldsBeforeUpdate[key].trim() !== this.cntrCmmsFieldsAfterUpdate[key].trim())) )
              {
                cmmsFieldsChanged=true
              }
            }

            console.log("Iscmmsfieldschanged", cmmsFieldsChanged);
            if(cmmsFieldsChanged)
            {
              this.enableUpdateNgppFieldsNotification = false;
              this.saveCntrToDBIA();
            }
            else{
              this.enableUpdateNgppFieldsNotification = true;
            }

      })

    
  }

  okUpdateCntrDetsToCntrAdmin()
  {
    if(this.enableUpdateNgppFieldsNotification == true)
    {
      this.containerRequestDetialsForPost = this.containerEditRequestDetials;

      var suffixToAPI:string=this.containerRequestDetialsForPost.cntr_suffix;

      console.log("post container details", this.containerRequestDetialsForPost)

      if(this.containerRequestDetialsForPost.cntr_suffix==null || this.containerRequestDetialsForPost.cntr_suffix.trim()=='')
      {
        suffixToAPI="%20%20%20%20%20%20%20%20";
      }  

      console.log("REQSTAT", this.containerRequestDetialsForPost.cntr_base,suffixToAPI,this.containerRequestDetialsForPost.ownrshp_strat_code,this.containerRequestDetialsForPost.cd_region)
      this.enableUpdateNgppFieldsNotification = false;
      this.transactionService.getRequestStatusOfContainer(this.containerRequestDetialsForPost.cntr_base,suffixToAPI,this.containerRequestDetialsForPost.ownrshp_strat_code,this.containerRequestDetialsForPost.cd_region).subscribe(requestStatus=>{
        console.log("Request Status",requestStatus);
        var requestStatusReceived=requestStatus;

        

        if(this.cntr_countDets != 0 && this.containerRequestDetialsForPost != null)
        {
          //service to call api to push data 
          this.saveEditContainerCntrAdmin.cd_cntr=this.cntr_countDets;
          this.saveEditContainerCntrAdmin.cntr_base=this.containerRequestDetialsForPost.cntr_base;
          this.saveEditContainerCntrAdmin.cntr_suff_ind= this.containerRequestDetialsForPost.cntr_suff_ind;
          this.saveEditContainerCntrAdmin.exp_refundable=this.containerRequestDetialsForPost.exp_refundable;
          this.saveEditContainerCntrAdmin.cntr_active_ind=this.containerRequestDetialsForPost.cntr_active_ind
          this.saveEditContainerCntrAdmin.cntr_suffix=this.containerRequestDetialsForPost.cntr_suffix;
          this.saveEditContainerCntrAdmin.cntr_desc=this.containerRequestDetialsForPost.cntr_desc;
          this.saveEditContainerCntrAdmin.card_call=this.containerRequestDetialsForPost.card_call
          this.saveEditContainerCntrAdmin.cntr_mstr_catg=this.containerRequestDetialsForPost.cntr_mstr_catg;
          this.saveEditContainerCntrAdmin.cntr_typ_desc=this.containerRequestDetialsForPost.cntr_typ_desc;
          this.saveEditContainerCntrAdmin.ownrshp_strat_code=this.containerRequestDetialsForPost.ownrshp_strat_code;
          this.saveEditContainerCntrAdmin.ownrshp_catg=this.containerRequestDetialsForPost.ownrshp_catg;
          this.saveEditContainerCntrAdmin.cntr_grp_desc=this.containerRequestDetialsForPost.cntr_grp_desc;
          this.saveEditContainerCntrAdmin.cntr_matr_desc=this.containerRequestDetialsForPost.cntr_matr_desc;
          this.saveEditContainerCntrAdmin.currency_desc=this.containerRequestDetialsForPost.currency_desc;
          this.saveEditContainerCntrAdmin.iscontainercolapsble=this.containerRequestDetialsForPost.iscontainercolapsble;
          this.saveEditContainerCntrAdmin.cntr_exterior_l=this.containerRequestDetialsForPost.cntr_exterior_l;
          this.saveEditContainerCntrAdmin.cntr_exterior_w=this.containerRequestDetialsForPost.cntr_exterior_w;
          this.saveEditContainerCntrAdmin.cntr_exterior_h=this.containerRequestDetialsForPost.cntr_exterior_h;
          this.saveEditContainerCntrAdmin.collapsed_ht=this.containerRequestDetialsForPost.collapsed_ht;
          this.saveEditContainerCntrAdmin.nest_ratio=this.containerRequestDetialsForPost.nest_ratio;
          this.saveEditContainerCntrAdmin.cntr_interior_l=this.containerRequestDetialsForPost.cntr_interior_l;
          this.saveEditContainerCntrAdmin.cntr_interior_w=this.containerRequestDetialsForPost.cntr_interior_w;
          this.saveEditContainerCntrAdmin.cntr_interior_h=this.containerRequestDetialsForPost.cntr_interior_h;
          this.saveEditContainerCntrAdmin.cntr_tare_wt=this.containerRequestDetialsForPost.cntr_tare_wt;
          this.saveEditContainerCntrAdmin.cntr_capacity=this.containerRequestDetialsForPost.cntr_capacity;
          this.saveEditContainerCntrAdmin.cntr_per_layer=this.containerRequestDetialsForPost.cntr_per_layer;
          this.saveEditContainerCntrAdmin.max_layers_shpg_unit=this.containerRequestDetialsForPost.max_layers_shpg_unit;
          this.saveEditContainerCntrAdmin.max_cntr_per_shpg_unit=this.containerRequestDetialsForPost.max_cntr_per_shpg_unit;
          this.saveEditContainerCntrAdmin.cntr_drawing_no=this.containerRequestDetialsForPost.cntr_drawing_no;
          this.saveEditContainerCntrAdmin.shpg_unit_exterior_l=this.containerRequestDetialsForPost.shpg_unit_exterior_l;
          this.saveEditContainerCntrAdmin.shpg_unit_exterior_w=this.containerRequestDetialsForPost.shpg_unit_exterior_w;
          this.saveEditContainerCntrAdmin.shpg_unit_exterior_h=this.containerRequestDetialsForPost.shpg_unit_exterior_h;
          this.saveEditContainerCntrAdmin.shpg_unit_nstdorflded_ht=this.containerRequestDetialsForPost.shpg_unit_nstdorflded_ht;
          this.saveEditContainerCntrAdmin.shpg_unit_tare_wt=this.containerRequestDetialsForPost.shpg_unit_tare_wt;
          this.saveEditContainerCntrAdmin.shpg_unit_stack_ht=this.containerRequestDetialsForPost.shpg_unit_stack_ht;
          this.saveEditContainerCntrAdmin.warehouse_stack_ht=this.containerRequestDetialsForPost.warehouse_stack_ht;
          this.saveEditContainerCntrAdmin.service_life=this.containerRequestDetialsForPost.service_life;
          this.saveEditContainerCntrAdmin.purchase_cost=this.containerRequestDetialsForPost.purchase_cost;
          this.saveEditContainerCntrAdmin.rental_issue_cost=this.containerRequestDetialsForPost.rental_issue_cost;
          this.saveEditContainerCntrAdmin.sup_rntl_dly_cost=this.containerRequestDetialsForPost.sup_rntl_dly_cost;
          this.saveEditContainerCntrAdmin.ford_rntl_dly_cost=this.containerRequestDetialsForPost.ford_rntl_dly_cost;
          this.saveEditContainerCntrAdmin.return_rntl_cost=this.containerRequestDetialsForPost.return_rntl_cost;
          this.saveEditContainerCntrAdmin.tooling_dev_cost=this.containerRequestDetialsForPost.tooling_dev_cost;
          this.saveEditContainerCntrAdmin.extr_cntr_dsgn_img_ind=this.containerRequestDetialsForPost.extr_cntr_dsgn_img_ind;
          this.saveEditContainerCntrAdmin.intr_cntr_dsgn_img_ind=this.containerRequestDetialsForPost.intr_cntr_dsgn_img_ind;
          this.saveEditContainerCntrAdmin.shpg_unit_img_ind=this.containerRequestDetialsForPost.shpg_unit_img_ind
          this.saveEditContainerCntrAdmin.cad_img_ind=this.containerRequestDetialsForPost.cad_img_ind;
          this.saveEditContainerCntrAdmin.img_notes=this.containerRequestDetialsForPost.img_notes;
          this.saveEditContainerCntrAdmin.cd_region=this.containerRequestDetialsForPost.cd_region;
          this.saveEditContainerCntrAdmin.cd_plant=this.containerRequestDetialsForPost.cd_plant;
          this.saveEditContainerCntrAdmin.cnt_qt_per_min_su=this.containerRequestDetialsForPost.cntr_qt_per_min_su;
          this.saveEditContainerCntrAdmin.nest_ratio_1=this.containerRequestDetialsForPost.nest_ratio_1;
          this.saveEditContainerCntrAdmin.ford_cntct=this.containerRequestDetialsForPost.ford_cntct;   
          this.saveEditContainerCntrAdmin.cntct_phone=this.containerRequestDetialsForPost.cntct_phone;
          this.saveEditContainerCntrAdmin.uom=this.containerRequestDetialsForPost.UoM;
          this.saveEditContainerCntrAdmin.id_user= this.containerRequestDetialsForPost.id_user
          this.saveEditContainerCntrAdmin.req_stat="Success";
          this.saveEditContainerCntrAdmin.ts_last_update=this.containerRequestDetialsForPost.ts_last_update;

          this.saveEditContainerCntrAdmin.typ_no=this.containerRequestDetialsForPost.typ_no;
          


          console.log("save direct to container admin post data", this.saveEditContainerCntrAdmin)

          
        var saveEditContainerCntrAdminArray=[];
        saveEditContainerCntrAdminArray.push(this.saveEditContainerCntrAdmin);

        if(requestStatusReceived.toLowerCase() != "pending")
        {
        this.transactionService.cntrAdminSaveCntrDetails(saveEditContainerCntrAdminArray).subscribe(cntr_save_res=>{
          console.log("direct cntr admin save edit",cntr_save_res)
          if(cntr_save_res.toLowerCase() == "success")
          {
            this.messageService.add({severity:'success', summary:'Success', detail:'Container Details got saved Successfully'});
            this.closeRequestDetails();
          }

      })
      
        }
      }
    })

    }
  }

  okCntrEditPendingNotification()
  {
    this.enableCntrEditPendingNotification=false;
  }

  cancelUpdateNgppFiledsNotifications()
  {
    this.enableUpdateNgppFieldsNotification = false;
    this.saveCntrAdminDetsInit();
  
  }
  okAddingNewContainerNotification()
  {
    this.enableAddingNewContainerNotification=false;
    this.saveCntrToDBIA();
  }

  cancelAddingNewContainerNotification()
  {
    this.enableAddingNewContainerNotification=false;
  }

  okUpdateExistingCntrNotification()
  {
    this.enableUpdateExistingCntrNotification=false;

    if(this.containerRequestDetialsForPost!=null )
    {
      let base;
      let suffix
      if(this.containerRequestDetialsForPost.cntr_base!=null)
      {
        base=this.containerRequestDetialsForPost.cntr_base.toUpperCase();
      }

      if(this.containerRequestDetialsForPost.cntr_suffix!=null && this.containerRequestDetialsForPost.cntr_suffix.trim()!='')
      {
        suffix=this.containerRequestDetialsForPost.cntr_suffix.toUpperCase();
      }
      else
      {
        suffix='%20%20%20%20%20%20%20%20'
      }
    }

    // @ts-ignore
      this.transactionService.getContainerDetailsFromCMMS(base,suffix).subscribe(cntrdetails=>{

      const cntrDetailsFromAPI:cntrDetailsFromCMMS=cntrdetails;

      if(cntrDetailsFromAPI!=null)
      {
        this.containerRequestDetialsForPost.function='C'
      }
      else
      {
        this.containerRequestDetialsForPost.function='A';
      }

      console.log('details before approve/reject',this.containerRequestDetialsForPost)
       this.saveCntrToDBIA();
    });
  }

  cancelUpdateExistingCntrNotification()
  {
    this.enableUpdateExistingCntrNotification=false;
  }


  saveCntrToDBIA()
  {
    this.transactionService.edit_savecntrdetails(this.containerRequestDetialsForPost).subscribe(savecntr=>
    {
      const res=savecntr;
      console.log('successful',res);
      if(res.toLowerCase() == 'success')
      {
        if(this.fromRejectButton == true)
        {
          this.messageService.add({severity:'success', summary:'Success', detail:'Container Rejected Successfully'});
          // email call to trigger reject container
          console.log("Email Format",this.cntrRequestRejectionEmailFormat);
          this.transactionService.triggerContainerOrComponentRejectionEmail(this.cntrRequestRejectionEmailFormat).subscribe((result=>{
            var responseCNtr=result;
            if(responseCNtr.toLowerCase()=="success")
            {
              this.cntrRequestRejectionEmailFormat=[];
              this.rejectionComments=null;
              this.enable_dialog_notification_cntr=false;
              this.messageService.add({severity:'success', summary:'Success', detail:'Email Sent Successfully'});

            }
            else
            {
              this.messageService.add({severity:'error', summary: 'Error', detail: 'Email Not sent successfully'});
            }
          }))
          
        }
        else
        {
          this.messageService.add({severity:'success', summary:'Success', detail:'Container Approved Successfully'});

          // email call to trigger approve container
          console.log("Email Format",this.cntrRequestApprovalEmailFormat);
          this.transactionService.triggerContainerOrComponentApprovalEmail(this.cntrRequestApprovalEmailFormat).subscribe((result=>{
            var responseaCNtr=result;
            if(responseaCNtr.toLowerCase()=="success")
            {
              this.cntrRequestApprovalEmailFormat=[];
              this.messageService.add({severity:'success', summary:'Success', detail:'Email Sent Successfully'});

            }
            else
            {
              this.messageService.add({severity:'error', summary: 'Error', detail: 'Email Not sent successfully'});
            }
          }))
        }
        

        this.validationInit();
        this.enableApproveRejectRequest=false;
        this.enableApproveRejectCompRequest=false;
        this.suppGSDBCode=null;
        this.suppName=null;
        this.SuppCntct=null;
        this.suppEmail=null;
        this.requestOriginalDetailsInit();
        this.requestDetailsForPostInit();
        this.requestEditDetailsInit();
        this.populateDbiaRequestGrid();

      }
      else
      {
        if(this.fromRejectButton == true)
        {
          this.messageService.add({severity:'error', summary: 'Error', detail: 'Container Not Rejected,Please Try Again'});
        }
        else
        {
          this.messageService.add({severity:'error', summary: 'Error', detail: 'Container Not Approved,Please Try Again'});
        }
          // this.addplantdetailsinit();
          this.validationInit();

      }
    })

  }

  closeRequestDetails()
  {
    this.enableApproveRejectRequest=false;
    this.enableApproveRejectCompRequest=false;
    this.rejectionComments=null;
    this.enable_dialog_notification_cntr=false;
    this.suppName=null;
    this.SuppCntct=null;
    this.suppGSDBCode=null;
    this.suppEmail=null;
    this.requestOriginalDetailsInit();
    this.requestDetailsForPostInit();
    this.requestEditDetailsInit();
    this.populateDbiaRequestGrid();
  }


  /***********************Component****************************************************** */


  requestCompEditDetailsInit()
  {
    this.enable_material_dropdown_comp=false;
    this.edit_selected_cntr_material_comp={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''}

    this.componentEditRequestDetials={
      function:'A',
     cntr_suff_ind:'',
     exp_refundable:'',
     cntr_active_ind:'',
     cntr_base:'',
     cntr_suffix:'',
     cntr_desc:'',
     card_call:'',
     cntr_mstr_catg:'',
     cntr_typ_desc:'',
     ownrshp_strat_code:'',
     ownrshp_catg:'',
     cntr_grp_desc:'',
     cntr_exterior_l:0,
     cntr_exterior_w:0,
     cntr_exterior_h:0,
     collapsed_ht:0.0,
     nest_ratio:0,
     cntr_interior_l:0,
     cntr_interior_w:0,
     cntr_interior_h:0,
     cntr_tare_wt:0,
     cntr_capacity:0,
     cntr_per_layer:0,
     max_layers_shpg_unit:0,
     max_cntr_per_shpg_unit:0,
     cntr_matr_desc:'',
     cntr_drawing_no:'',
     shpg_unit_exterior_l:0,
     shpg_unit_exterior_w:0,
     shpg_unit_exterior_h:0,
     shpg_unit_nstdorflded_ht:0,
     shpg_unit_tare_wt:0,
     shpg_unit_stack_ht:0,
     warehouse_stack_ht:0,
     service_life:'',
     currency_desc:'',
     purchase_cost:0,
     rental_issue_cost:0,
     sup_rntl_dly_cost:0,
     ford_rntl_dly_cost:0,
     return_rntl_cost:0,
     tooling_dev_cost:0,
     extr_cntr_dsgn_img_ind:'No',
     intr_cntr_dsgn_img_ind:'No',
     shpg_unit_img_ind:'No',
     cad_img_ind:'No',
     img_notes:'',
     cd_region:'',
     iscontainercolapsble:'',
     cd_plant:'',
     cntr_qt_per_min_su:0,
     nest_ratio_1:0,
     ford_cntct:'',
     cntct_phone:'',
     id_user:'',
     ts_last_update:'',
     req_stat:'',
     components:[{
         mp_function:'',
         no_comp_base:'',
         no_comp_suffix:'',
         ds_comp:'',
         comp_qt_per_min_su:0,
         comp_cd_owner:'',
         in_comp:'',
         comp_cd_type:''
     }],


     UoM:'E',
     dbia_req_no:0,
     typ_no:0

    }
  }

  requestCompOriginalDetailsInit()
  {

    this.componentOriginalRequestDetials={
      function:'A',
   cntr_suff_ind:'',
   exp_refundable:'',
   cntr_active_ind:'',
   cntr_base:'',
   cntr_suffix:'',
   cntr_desc:'',
   card_call:'',
   cntr_mstr_catg:'',
   cntr_typ_desc:'',
   ownrshp_strat_code:'',
   ownrshp_catg:'',
   cntr_grp_desc:'',
   cntr_exterior_l:0,
   cntr_exterior_w:0,
   cntr_exterior_h:0,
   collapsed_ht:0.0,
   nest_ratio:0,
   cntr_interior_l:0,
   cntr_interior_w:0,
   cntr_interior_h:0,
   cntr_tare_wt:0,
   cntr_capacity:0,
   cntr_per_layer:0,
   max_layers_shpg_unit:0,
   max_cntr_per_shpg_unit:0,
   cntr_matr_desc:'',
   cntr_drawing_no:'',
   shpg_unit_exterior_l:0,
   shpg_unit_exterior_w:0,
   shpg_unit_exterior_h:0,
   shpg_unit_nstdorflded_ht:0,
   shpg_unit_tare_wt:0,
   shpg_unit_stack_ht:0,
   warehouse_stack_ht:0,
   service_life:'',
   currency_desc:'',
   purchase_cost:0,
   rental_issue_cost:0,
   sup_rntl_dly_cost:0,
   ford_rntl_dly_cost:0,
   return_rntl_cost:0,
   tooling_dev_cost:0,
   extr_cntr_dsgn_img_ind:'No',
   intr_cntr_dsgn_img_ind:'No',
   shpg_unit_img_ind:'No',
   cad_img_ind:'No',
   img_notes:'',
   cd_region:'',
   iscontainercolapsble:'',
   cd_plant:'',
   cntr_qt_per_min_su:0,
   nest_ratio_1:0,
   ford_cntct:'',
   cntct_phone:'',
   id_user:'',
   ts_last_update:'',
   req_stat:'',
   components:[{
       mp_function:'',
       no_comp_base:'',
       no_comp_suffix:'',
       ds_comp:'',
       comp_qt_per_min_su:0,
       comp_cd_owner:'',
       in_comp:'',
       comp_cd_type:''
   }],


   UoM:'E',
   dbia_req_no:0,
   typ_no:0
    }
  }

  requestCompDetailsForPostInit()
  {
    this.componentRequestDetialsForPost={
      function:'A',
   cntr_suff_ind:'',
   exp_refundable:'',
   cntr_active_ind:'',
   cntr_base:'',
   cntr_suffix:'',
   cntr_desc:'',
   card_call:'',
   cntr_mstr_catg:'',
   cntr_typ_desc:'',
   ownrshp_strat_code:'',
   ownrshp_catg:'',
   cntr_grp_desc:'',
   cntr_exterior_l:0,
   cntr_exterior_w:0,
   cntr_exterior_h:0,
   collapsed_ht:0.0,
   nest_ratio:0,
   cntr_interior_l:0,
   cntr_interior_w:0,
   cntr_interior_h:0,
   cntr_tare_wt:0,
   cntr_capacity:0,
   cntr_per_layer:0,
   max_layers_shpg_unit:0,
   max_cntr_per_shpg_unit:0,
   cntr_matr_desc:'',
   cntr_drawing_no:'',
   shpg_unit_exterior_l:0,
   shpg_unit_exterior_w:0,
   shpg_unit_exterior_h:0,
   shpg_unit_nstdorflded_ht:0,
   shpg_unit_tare_wt:0,
   shpg_unit_stack_ht:0,
   warehouse_stack_ht:0,
   service_life:'',
   currency_desc:'',
   purchase_cost:0,
   rental_issue_cost:0,
   sup_rntl_dly_cost:0,
   ford_rntl_dly_cost:0,
   return_rntl_cost:0,
   tooling_dev_cost:0,
   extr_cntr_dsgn_img_ind:'No',
   intr_cntr_dsgn_img_ind:'No',
   shpg_unit_img_ind:'No',
   cad_img_ind:'No',
   img_notes:'',
   cd_region:'',
   iscontainercolapsble:'',
   cd_plant:'',
   cntr_qt_per_min_su:0,
   nest_ratio_1:0,
   ford_cntct:'',
   cntct_phone:'',
   id_user:'',
   ts_last_update:'',
   req_stat:'',
   components:[{
       mp_function:'',
       no_comp_base:'',
       no_comp_suffix:'',
       ds_comp:'',
       comp_qt_per_min_su:0,
       comp_cd_owner:'',
       in_comp:'',
       comp_cd_type:''
   }],


   UoM:'E',
   dbia_req_no:0,
   typ_no:0
    }
  }

  validationInitComp()
  {

      this.compTareWtInValid=false;
      this.compTareWtInValidReason=null;
      this.compCapWtInValid=false;
      this.compCapWtInValidReason=null;
      this.compQtPerMinSuInValid=false;
      this.compQtPerMinSuInvalidReason=null;
      this.compExtLInValid=false;
      this.compExtLInValidReason=null;
      this.compExtWInValid=false;
      this.compExtWInValidReason=null;
      this.compExtHInValid=false;
      this.compExtHInValidReason=null;
      this.wareHourseStackHtInvaidComp=false;
      this.wareHourseStackHtInvaidReasonComp=null;

      this.compBaseInvalid=false;
      this.compBaseInvalidReason=null;
      this.compDescInvalid=false;
      this.compDescInvalidReason=null;
      this.plantCodeCompInvalid=false;
      this.plantCodeCompInvalidReason=null;
      this.mastrCatgInvalidComp=false;
      this.mastrCatgInvalidReasonComp=null;
      this.retTypeInvalidComp=false;
      this.retTypeInvalidReasonComp=null;
      this.compTypeInvalid=false;
      this.compTypeInvalidReason=null;
      this.compMaterialInvalid=false;
      this.compMaterialInvalidReason=null;
      this.cosInvalidComp=false;
      this.cosInvalidReasonComp=null;
      this.compGrpInvalid=false;
      this.compGrpInvalidReason=null;
      this.compCntctInvalid=false;
      this.compCntctInvalidReason=null;
      this.cntctPhoneInvalidComp=false;
      this.cntctPhoneInvlidReasonComp=null;
      this.compActiveInvalid=false;
      this.compActiveInvalidReason=null;
  }





  onChangeRetTypeEditComp()
{
  console.log(this.componentEditRequestDetials.cntr_matr_desc);

  let edit_ret_type='';

  if(this.componentEditRequestDetials.exp_refundable != '')
  {
  if(this.edit_selected_cntr_type_code_comp != null && this.edit_selected_cntr_type_code_comp.cntr_typ_desc != '')
  {

    if(this.componentEditRequestDetials.exp_refundable.toLowerCase() =='returnable')
    {
      edit_ret_type= 'R';

    }
    else
    {
      edit_ret_type= 'E';
    }
   this.edit_selected_cntr_material_comp={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
    // api to get material list

    this.transactionService.getContainerMaterial(this.edit_selected_cntr_type_code_comp.typ_no,edit_ret_type).subscribe(mat_list=>{

      this.cntrMaterial=[];
      this.cntrMaterial=mat_list;


    if(this.cntrMaterial!= null && this.cntrMaterial.length!=0 && this.componentEditRequestDetials!= null && this.componentEditRequestDetials.cntr_matr_desc != null)
    {
    for(const t of this.cntrMaterial)
    {
      if(t.cntr_matr_name.toLowerCase() == this.componentEditRequestDetials.cntr_matr_desc.toLowerCase())
      {
        this.edit_selected_cntr_material_comp=t;
        break;
      }
    }
    }

  })
    this.enable_material_dropdown_comp=true;
  }
    else
  {
    this.enable_material_dropdown_comp=false;
    this.edit_selected_cntr_material_comp={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
  }


}

else
{
  this.enable_material_dropdown_comp=false;
  this.edit_selected_cntr_material_comp={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
}




}

// material api changes

onChangeCntrTypEditComp()
{


  console.log(this.componentEditRequestDetials.cntr_matr_desc);

  let edit_ret_type='';


  if(this.edit_selected_cntr_type_code_comp != null && this.edit_selected_cntr_type_code_comp.cntr_typ_desc != '')
  {
    if(this.componentEditRequestDetials.exp_refundable != '')
    {

    if(this.componentEditRequestDetials.exp_refundable.toLowerCase() =='returnable')
    {
      edit_ret_type= 'R';

    }
    else
    {
      edit_ret_type= 'E';
    }

     this.edit_selected_cntr_material_comp={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
    // api to get material list


    this.transactionService.getContainerMaterial(this.edit_selected_cntr_type_code_comp.typ_no,edit_ret_type).subscribe(mat_list=>{

      this.cntrMaterial=[];
      this.cntrMaterial=mat_list;


    if(this.cntrMaterial!= null && this.cntrMaterial.length!=0 && this.componentEditRequestDetials!= null && this.componentEditRequestDetials.cntr_matr_desc != null)
    {
    for(const t of this.cntrMaterial)
    {
      if(t.cntr_matr_name.toLowerCase() == this.componentEditRequestDetials.cntr_matr_desc.toLowerCase())
      {
        this.edit_selected_cntr_material_comp=t;
        break;
      }
    }
    }
  })
    this.enable_material_dropdown_comp=true;
  }
  else
  {
    this.enable_material_dropdown_comp=false;
    this.edit_selected_cntr_material_comp={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
  }


}

else
{
  this.enable_material_dropdown_comp=false;
  this.edit_selected_cntr_material_comp={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
}


}

// not used
  enableRequestDetailsComp()
  {
    console.log('Request Number DBIA',this.selectedRequestNumber);
    // service to get request detials by passing request number
    this.edit_selected_cntr_master_category_comp={cd_cntr_mstr_catg:0,cntr_mstr_catg_desc:'',updated_by:'',updated_dt:'',cntr_mstr_catg:'',active_stat:'',active_stat_display:false};
    this.edit_selected_cntr_type_code_comp={cd_cntr_typ:'',cntr_typ_desc:'',typ_no:0};
    this.edit_selected_cntr_ownrship_strat_code_comp={cd_region:'',ownrshp_strat_code:'',ownrshp_desc:'',cd_ownrshp_strat:0};
    this.edit_selected_ownrship_catg_comp={ownrshp_catg:'',ownrshp_catg_val:''};
    this.edit_selected_cntr_group_comp={cntr_grp:''}
    this.edit_selected_cntr_material_comp={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
    this.edit_selected_cntr_currency_list_comp={cd_curr:0,curr_desc:''};
    this.editSelectedPlantCodeComp={cd_plant:'',plantGSDBCode:'',plant_name:'',plant_active_stat:'',plant_type_name:''}

    this.transactionService.getContainerRequestDetailsByReqNo(this.selectedRequestNumber).subscribe(savecntrdetails=>{
      console.log('Container details by req no',savecntrdetails)

    // var savecntrdetails;

    this.validationInitComp();

    this.requestCompEditDetailsInit();
    this.enableApproveRejectRequest=false;
    this.enableApproveRejectCompRequest=true;
    this.rejectionCommentsComp=null;
    this.enable_dialog_notification_comp=false;

    this.transactionService.getSupInfo(savecntrdetails.id_user).subscribe(suppdetls=>{
      if(suppdetls!=null)
      {
        console.log(suppdetls)
        this.suppGSDBCode=suppdetls.suppliergsdbcode;
        this.suppName=suppdetls.fullname;
        this.SuppCntct=suppdetls.contactphone;
        this.suppEmail=suppdetls.contactemail;
      }
    })


   // this.enableeditcntrdetails=true;
    this.componentEditRequestDetials.function=savecntrdetails.function;
    this.componentEditRequestDetials.dbia_req_no=savecntrdetails.dbia_req_no
    this.componentEditRequestDetials.cntr_base=savecntrdetails.cntr_base;
    this.componentEditRequestDetials.cntr_suff_ind=savecntrdetails.cntr_suff_ind;
    this.componentEditRequestDetials.exp_refundable=savecntrdetails.exp_refundable;
    this.componentEditRequestDetials.cntr_active_ind=savecntrdetails.cntr_active_ind;
    this.componentEditRequestDetials.cntr_suffix=savecntrdetails.cntr_suffix;
    // this.containerEditRequestDetials.cntr_suffix=this.selectedSuffixList.suppliergsdbcode;
    if(savecntrdetails.cntr_desc != null)
    {
    this.componentEditRequestDetials.cntr_desc=savecntrdetails.cntr_desc.trim();
    }
    this.componentEditRequestDetials.card_call=savecntrdetails.card_call;
    this.componentEditRequestDetials.cntr_mstr_catg=savecntrdetails.cntr_mstr_catg;
    this.componentEditRequestDetials.cntr_typ_desc=savecntrdetails.cntr_typ_desc;
    this.componentEditRequestDetials.typ_no=savecntrdetails.typ_no;
    this.componentEditRequestDetials.ownrshp_strat_code=savecntrdetails.ownrshp_strat_code;
    this.componentEditRequestDetials.ownrshp_catg=savecntrdetails.ownrshp_catg;
    this.componentEditRequestDetials.cntr_grp_desc=savecntrdetails.cntr_grp_desc;
    this.componentEditRequestDetials.cntr_matr_desc=savecntrdetails.cntr_matr_desc;
    this.componentEditRequestDetials.currency_desc=savecntrdetails.currency_desc;
    this.componentEditRequestDetials.iscontainercolapsble=savecntrdetails.iscontainercolapsble;



  /* Removing add suffix to container
  if(this.containerEditRequestDetials.cntr_suff_ind=='Yes')
  {
    this.cntrSuffixEditenabled=true;
  }
  else
  {
    this.cntrSuffixEditenabled=false;
  }*/


     // this.cntrSuffixEditenabled=false;

  if(this.cntrMasterCategory != null && this.cntrMasterCategory.length != 0)
  {
    for(let i=0;i<this.cntrMasterCategory.length;i++)
    {
      if(this.componentEditRequestDetials.cntr_mstr_catg != null && this.componentEditRequestDetials.cntr_mstr_catg==this.cntrMasterCategory[i].cntr_mstr_catg)
      {
        this.edit_selected_cntr_master_category_comp=this.cntrMasterCategory[i];
      }
    }
  }

  if(this.compTypeCode != null && this.compTypeCode.length != 0)
  {
    for(let i=0; i<this.compTypeCode.length; i++)
    {
      if(this.componentEditRequestDetials.typ_no != null && this.componentEditRequestDetials.typ_no==this.compTypeCode[i].typ_no)
      {
        this.edit_selected_cntr_type_code_comp=this.compTypeCode[i];
        console.log('edit_selected_cntr_type_code',this.edit_selected_cntr_type_code_comp);
      }
    }
  }

  if(this.cntrOwnershipstratcode != null && this.cntrOwnershipstratcode.length != 0)
  {
    for(let i=0;i<this.cntrOwnershipstratcode.length;i++)
    {
      if(this.componentEditRequestDetials.ownrshp_strat_code != null && this.componentEditRequestDetials.ownrshp_strat_code==this.cntrOwnershipstratcode[i].ownrshp_strat_code)
      {
        this.edit_selected_cntr_ownrship_strat_code_comp=this.cntrOwnershipstratcode[i];
      }
    }
  }

  if(this.ownrship_catg != null && this.ownrship_catg.length != 0)
  {
    for(let i=0;i<this.ownrship_catg.length;i++)
    {
      if(this.componentEditRequestDetials.ownrshp_catg != null && this.componentEditRequestDetials.ownrshp_catg.toLowerCase().indexOf(this.ownrship_catg[i].ownrshp_catg_val.toLowerCase())>-1)
      {
        this.edit_selected_ownrship_catg_comp=this.ownrship_catg[i];
      }
    }
    console.log('ownshp cat',this.edit_selected_ownrship_catg_comp)
  }

  if(this.cntrGrp != null && this.cntrGrp.length != 0)
  {
    for(let i=0;i<this.cntrGrp.length;i++)
    {
      if(this.componentEditRequestDetials.cntr_grp_desc != null && this.componentEditRequestDetials.cntr_grp_desc==this.cntrGrp[i].cntr_grp)
      {
        this.edit_selected_cntr_group_comp=this.cntrGrp[i];
      }
    }
  }

    // for(let i=0;i<this.cntrMaterial.length;i++)
    // {
    //   if(this.containerEditRequestDetials.cntr_matr_desc==this.cntrMaterial[i].cntr_matr_name)
    //   {
    //     this.edit_selected_cntr_material=this.cntrMaterial[i];
    //   }
    // }
    this.onChangeCntrTypEditComp();

  if(this.cntrCurrencyList != null && this.cntrCurrencyList.length != 0)
  {
    for(let i=0;i<this.cntrCurrencyList.length;i++)
    {
      if(this.componentEditRequestDetials.currency_desc != null && this.componentEditRequestDetials.currency_desc==this.cntrCurrencyList[i].curr_desc)
      {
        this.edit_selected_cntr_currency_list_comp=this.cntrCurrencyList[i];
      }
    }
  }

    this.componentEditRequestDetials.cntr_exterior_l=savecntrdetails.cntr_exterior_l;
    this.componentEditRequestDetials.cntr_exterior_w=savecntrdetails.cntr_exterior_w;
    this.componentEditRequestDetials.cntr_exterior_h=savecntrdetails.cntr_exterior_h;
    this.componentEditRequestDetials.collapsed_ht=savecntrdetails.collapsed_ht;
    this.componentEditRequestDetials.nest_ratio=savecntrdetails.nest_ratio;
    this.componentEditRequestDetials.cntr_interior_l=savecntrdetails.cntr_interior_l;
    this.componentEditRequestDetials.cntr_interior_w=savecntrdetails.cntr_interior_w;
    this.componentEditRequestDetials.cntr_interior_h=savecntrdetails.cntr_interior_h;
    this.componentEditRequestDetials.cntr_tare_wt=savecntrdetails.cntr_tare_wt;
    this.componentEditRequestDetials.cntr_capacity=savecntrdetails.cntr_capacity;
    this.componentEditRequestDetials.cntr_per_layer=savecntrdetails.cntr_per_layer;
    this.componentEditRequestDetials.max_layers_shpg_unit=savecntrdetails.max_layers_shpg_unit;
    this.componentEditRequestDetials.max_cntr_per_shpg_unit=savecntrdetails.max_cntr_per_shpg_unit;
    this.componentEditRequestDetials.cntr_drawing_no=savecntrdetails.cntr_drawing_no;
    this.componentEditRequestDetials.shpg_unit_exterior_l=savecntrdetails.shpg_unit_exterior_l;
    this.componentEditRequestDetials.shpg_unit_exterior_w=savecntrdetails.shpg_unit_exterior_w;
    this.componentEditRequestDetials.shpg_unit_exterior_h=savecntrdetails.shpg_unit_exterior_h;
    this.componentEditRequestDetials.shpg_unit_nstdorflded_ht=savecntrdetails.shpg_unit_nstdorflded_ht;
    this.componentEditRequestDetials.shpg_unit_tare_wt=savecntrdetails.shpg_unit_tare_wt;
    this.componentEditRequestDetials.shpg_unit_stack_ht=savecntrdetails.shpg_unit_stack_ht;
    this.componentEditRequestDetials.warehouse_stack_ht=savecntrdetails.warehouse_stack_ht;
    this.componentEditRequestDetials.service_life=savecntrdetails.service_life;
    this.componentEditRequestDetials.purchase_cost=savecntrdetails.purchase_cost;
    this.componentEditRequestDetials.rental_issue_cost=savecntrdetails.rental_issue_cost;
    this.componentEditRequestDetials.sup_rntl_dly_cost=savecntrdetails.sup_rntl_dly_cost;
    this.componentEditRequestDetials.ford_rntl_dly_cost=savecntrdetails.ford_rntl_dly_cost;
    this.componentEditRequestDetials.return_rntl_cost=savecntrdetails.return_rntl_cost;
    this.componentEditRequestDetials.tooling_dev_cost=savecntrdetails.tooling_dev_cost;
    this.componentEditRequestDetials.extr_cntr_dsgn_img_ind=savecntrdetails.extr_cntr_dsgn_img_ind;
    this.componentEditRequestDetials.intr_cntr_dsgn_img_ind=savecntrdetails.intr_cntr_dsgn_img_ind;
    this.componentEditRequestDetials.cad_img_ind=savecntrdetails.cad_img_ind;
    this.componentEditRequestDetials.img_notes=savecntrdetails.img_notes;
    this.componentEditRequestDetials.cd_region=savecntrdetails.cd_region;

    console.log('containerEditRequestDetials',this.componentEditRequestDetials);





    console.log('edit_selected_plant_code',savecntrdetails.cd_plant);
    if(savecntrdetails.cd_plant != null)
    {
    this.componentEditRequestDetials.cd_plant= savecntrdetails.cd_plant;

    for(let i=0;i<this.plantList.length;i++)
    {
      if(this.componentEditRequestDetials.cd_plant.trim()==this.plantList[i].plantGSDBCode)
      {
        this.editSelectedPlantCodeComp=this.plantList[i];
        console.log('edit_selected_plant_code',this.editSelectedPlantCodeComp);
      }
    }

    }
    if(savecntrdetails.cntr_qt_per_min_su!= null)
    {
      this.componentEditRequestDetials.cntr_qt_per_min_su=savecntrdetails.cntr_qt_per_min_su;

    }
    if(savecntrdetails.nest_ratio_1 != null)
    {
      this.componentEditRequestDetials.nest_ratio_1=savecntrdetails.nest_ratio_1;

    }
    /*if(savecntrdetails.ford_cntct != null)
    {
      this.containerEditRequestDetials.ford_cntct= savecntrdetails.ford_cntct.trim();

    }*/

    this.componentEditRequestDetials.ford_cntct=this.transactionService.getcdsid();
    if(savecntrdetails.cntct_phone != null)
    {
      this.componentEditRequestDetials.cntct_phone=savecntrdetails.cntct_phone.trim();
    }


    if(savecntrdetails.img_notes != null)
    {
      this.componentEditRequestDetials.img_notes=savecntrdetails.img_notes.trim();
    }


    // this.containerEditRequestDetials.req_stat="New";
   // this.containerEditRequestDetials.id_user=this.transactionService.getcdsid();;


     this.componentEditRequestDetials.req_stat=savecntrdetails.req_stat;
    this.componentEditRequestDetials.id_user=savecntrdetails.id_user;
    this.componentEditRequestDetials.ts_last_update='';
    if(savecntrdetails.uoM != null && savecntrdetails.uoM.trim() != '')
    {
    this.componentEditRequestDetials.UoM=savecntrdetails.uoM;
    }
    else
    {
      this.componentEditRequestDetials.UoM = 'E'
    }

    if(this.componentEditRequestDetials.UoM.toLowerCase() == 'm')
    {

      this.edit_measure='kg/cm'
      this.prev_edit_measure='kg/cm'
    }
    else
    {
      this.edit_measure='lbs/inch'
      this.prev_edit_measure='lbs/inch'
    }



    this.requestCompOriginalDetailsInit();
    this.componentOriginalRequestDetials=JSON.parse(JSON.stringify(this.componentEditRequestDetials));


  })




  }




validateCompBaseForEdit()
{
  if(this.componentEditRequestDetials.cntr_base==null || this.componentEditRequestDetials.cntr_base.trim()=='')
  {
    this.compBaseInvalid=true;
    this.compBaseInvalidReason='Required'
  }
  else
  {
    this.compBaseInvalid=false;
    this.compBaseInvalidReason=null
  }
}

validateCompDescForEdit()
{
  if(this.componentEditRequestDetials.cntr_desc==null || this.componentEditRequestDetials.cntr_desc.trim()=='')
  {
    this.compDescInvalid=true;
    this.compDescInvalidReason='Required'
  }
  else
  {
    this.compDescInvalid=false;
    this.compDescInvalidReason=null
  }
}

validatePlantCodeCompForEdit()
{
  if(this.editSelectedPlantCodeComp.plantGSDBCode==null || this.editSelectedPlantCodeComp.plantGSDBCode.trim()=='')
  {
    this.plantCodeCompInvalid=true;
    this.plantCodeCompInvalidReason='Required'
  }
  else
  {
    this.plantCodeCompInvalid=false;
    this.plantCodeCompInvalidReason=null
  }
}




validateMastCatgCompForEdit()
{
  if(this.edit_selected_cntr_master_category_comp.cntr_mstr_catg==null || this.edit_selected_cntr_master_category_comp.cntr_mstr_catg.trim()=='')
  {
    this.mastrCatgInvalidComp=true;
    this.mastrCatgInvalidReasonComp='Required'
  }
  else
  {
    this.mastrCatgInvalidComp=false;
    this.mastrCatgInvalidReasonComp=null
  }
}

validateCompRetTypeForEdit()
{
  if(this.componentEditRequestDetials.exp_refundable==null || this.componentEditRequestDetials.exp_refundable.trim()=='')
  {
    this.retTypeInvalidComp=true;
    this.retTypeInvalidReasonComp='Required'
  }
  else
  {
    this.retTypeInvalidComp=false;
    this.retTypeInvalidReasonComp=null;
  }
}

validateCntrTypeCodeCompForEdit()
{
  if(this.edit_selected_cntr_type_code_comp.cntr_typ_desc==null || this.edit_selected_cntr_type_code_comp.cntr_typ_desc.trim()=='')
  {
    this.compTypeInvalid=true;
    this.compTypeInvalidReason='Required';
  }
  else
  {
    this.compTypeInvalid=false;
    this.compTypeInvalidReason=null;
  }
}

validateCompMaterialForEdit()
{
  if(this.edit_selected_cntr_material_comp.cntr_matr_name==null || this.edit_selected_cntr_material_comp.cntr_matr_name.trim()=='')
  {
    this.compMaterialInvalid=true;
    this.compMaterialInvalidReason='Required';
  }
  else
  {
    this.compMaterialInvalid=false;
    this.compMaterialInvalidReason=null;
  }
}

validateCOSCompForEdit()
{
  if(this.edit_selected_cntr_ownrship_strat_code_comp.ownrshp_strat_code==null || this.edit_selected_cntr_ownrship_strat_code_comp.ownrshp_strat_code.trim()=='')
  {
    this.cosInvalidComp=true;
    this.cosInvalidReasonComp='Required';
  }
  else
  {
    this.cosInvalidComp=false;
    this.cosInvalidReasonComp=null;
  }
}

validateCompGrpForEdit()
{
  if(this.edit_selected_cntr_group_comp.cntr_grp==null || this.edit_selected_cntr_group_comp.cntr_grp.trim()=='')
  {
    this.compGrpInvalid=true;
    this.compGrpInvalidReason='Required';
  }
  else
  {
    this.compGrpInvalid=false;
    this.compGrpInvalidReason=null;
  }
}



validateCompContactForEdit()
{
  if(this.componentEditRequestDetials.ford_cntct==null || this.componentEditRequestDetials.ford_cntct.trim()=='')
  {
    this.compCntctInvalid=true;
    this.compCntctInvalidReason='Required';
  }
  else
  {
    this.compCntctInvalid=false;
    this.compCntctInvalidReason=null;
  }
}

validateCompContactPhoneForEdit()
{
  if(this.componentEditRequestDetials.cntct_phone==null || this.componentEditRequestDetials.cntct_phone.trim()=='')
  {
    this.cntctPhoneInvalidComp=true;
    this.cntctPhoneInvlidReasonComp='Required';
  }
  else
  {
    this.cntctPhoneInvalidComp=false;
    this.cntctPhoneInvlidReasonComp=null;
  }
}

validateCompActiveForEdit()
{
  if(this.componentEditRequestDetials.cntr_active_ind==null || this.componentEditRequestDetials.cntr_active_ind.trim()=='')
  {
    this.compActiveInvalid=true;
    this.compActiveInvalidReason='Required';
  }
  else
  {
    this.compActiveInvalid=false;
    this.compActiveInvalidReason=null;
  }
}




validateCompTareWeightForEdit()
{

  const comp_tar_wt = String(this.componentEditRequestDetials.cntr_tare_wt).split('.');
  if(this.componentEditRequestDetials.cntr_tare_wt==null || this.componentEditRequestDetials.cntr_tare_wt==0)
  {
    this.compTareWtInValid=true;
    this.compTareWtInValidReason='Required';
  }
  else if(!String(this.componentEditRequestDetials.cntr_tare_wt).includes('.'))
  {
    /*if( String(this.componentEditRequestDetials.cntr_tare_wt).length>10)
      {
        this.compTareWtInValid=true;
        this.compTareWtInValidReason="Max Length Of Characters can be 10";
      }
      else*/
       if(this.componentEditRequestDetials.cntr_tare_wt<=0 || this.componentEditRequestDetials.cntr_tare_wt>99999.999)
      {
        this.compTareWtInValid=true;
        this.compTareWtInValidReason='Should be of range 0 to 99999.999';
      }
    else
      {
        this.compTareWtInValid=false;
        this.compTareWtInValidReason=null;
      }
  }
  else if(String(this.componentEditRequestDetials.cntr_tare_wt).includes('.'))
  {
      if(String(comp_tar_wt[0]).length<0 || String(comp_tar_wt[0]).length>5)
      {
        this.compTareWtInValid=true;
        this.compTareWtInValidReason='Should be of range 0 to 99999.999';
      }
      else if(String(comp_tar_wt[1]).length<0 || String(comp_tar_wt[1]).length>3)
      {
        this.compTareWtInValid=true;
        this.compTareWtInValidReason='Max Length Of decimal values can be 3';
      }
      else
      {
        this.compTareWtInValid=false;
        this.compTareWtInValidReason=null;
      }
    }
  else
  {
  this.compTareWtInValid=false;
  this.compTareWtInValidReason=null;
  }
}
validateCompCapWtForEdit()
{
  const cont_cap_wt = String(this.componentEditRequestDetials.cntr_capacity).split('.');
  if(this.componentEditRequestDetials.cntr_capacity==null || this.componentEditRequestDetials.cntr_capacity==0)
  {
    this.compCapWtInValid=true;
    this.compCapWtInValidReason='Required';
  }
  else if(this.componentEditRequestDetials.cntr_capacity<=0 || this.componentEditRequestDetials.cntr_capacity>99999.999)
  {
    this.compCapWtInValid=true;
    this.compCapWtInValidReason='Should be of range 0 to 99999.999';
  }
  else if(String(this.componentEditRequestDetials.cntr_capacity).includes('.'))
  {
    if(cont_cap_wt[1].length > 3)
    {
      this.compCapWtInValid=true;
      this.compCapWtInValidReason='Max Length Of decimal values can be 3';
    }
    else
    {
      this.compCapWtInValid=false;
      this.compCapWtInValidReason=null;
    }
  }
  else
  {
    this.compCapWtInValid=false;
    this.compCapWtInValidReason=null;
  }
}


validateCompQtPerMinSuForEdit()
{
  if(this.componentEditRequestDetials.cntr_qt_per_min_su==null || this.componentEditRequestDetials.cntr_qt_per_min_su==0)
  {
    this.compQtPerMinSuInValid=true;
    this.compQtPerMinSuInvalidReason='Required';
  }
  else if(this.componentEditRequestDetials.cntr_qt_per_min_su<1 || this.componentEditRequestDetials.cntr_qt_per_min_su>99 || String(this.componentEditRequestDetials.cntr_qt_per_min_su).includes('.'))
  {
    this.compQtPerMinSuInValid=true;
    this.compQtPerMinSuInvalidReason='Should be of range 1 to 99  and cannot contain Decimal';
  }
  else
  {
    this.compQtPerMinSuInValid=false;
    this.compQtPerMinSuInvalidReason=null;
  }
}

validateCompExtLForEdit()
{
  const cont_ext_l = String(this.componentEditRequestDetials.cntr_exterior_l).split('.');
  if(this.componentEditRequestDetials.cntr_exterior_l==null || this.componentEditRequestDetials.cntr_exterior_l==0)
  {
    this.compExtLInValid=true;
    this.compExtLInValidReason='Required';
  }
  else if(!String(this.componentEditRequestDetials.cntr_exterior_l).includes('.'))
    {
    if(this.componentEditRequestDetials.cntr_exterior_l<=0 || this.componentEditRequestDetials.cntr_exterior_l>999.999 )
        {
          this.compExtLInValid=true;
          this.compExtLInValidReason='Should be of range 0 to 999.999';
        }
        /*else if( String(this.componentEditRequestDetials.cntr_exterior_l).length>7)
        {
          this.compExtLInValid=true;
          this.compExtLInValidReason="Max Length Of Characters can be 7";
        }*/
        else
        {
          this.compExtLInValid=false;
          this.compExtLInValidReason=null;
        }
    }
    else if(String(this.componentEditRequestDetials.cntr_exterior_l).includes('.'))
    {
        if(String(cont_ext_l[0]).length<0 || String(cont_ext_l[0]).length>3)
        {
          this.compExtLInValid=true;
          this.compExtLInValidReason='Should be of range 0 to 999.999';
        }
        else if(this.componentEditRequestDetials.cntr_exterior_l<=0 || this.componentEditRequestDetials.cntr_exterior_l>999.999 )
        {
          this.compExtLInValid=true;
          this.compExtLInValidReason='Should be of range 0 to 999.999';
        }
        else if(String(cont_ext_l[1]).length<0 || String(cont_ext_l[1]).length>3)
        {
          this.compExtLInValid=true;
          this.compExtLInValidReason='Max Length Of decimal values can be 3';
        }
        else
        {
          this.compExtLInValid=false;
          this.compExtLInValidReason=null;
      }
    }
    else
    {
    this.compExtLInValid=false;
    this.compExtLInValidReason=null;
    }
}
validateCompExtWForEdit()
{
  const cont_ext_w = String(this.componentEditRequestDetials.cntr_exterior_w).split('.');
  if(this.componentEditRequestDetials.cntr_exterior_w==null || this.componentEditRequestDetials.cntr_exterior_w==0)
  {
    this.compExtWInValid=true;
    this.compExtWInValidReason='Required';
  }
  else if(!String(this.componentEditRequestDetials.cntr_exterior_w).includes('.'))
    {
   if(this.componentEditRequestDetials.cntr_exterior_w<=0 || this.componentEditRequestDetials.cntr_exterior_w>999.999 )
        {
          this.compExtWInValid=true;
          this.compExtWInValidReason='Should be of range 0 to 999.999';
        }
        /*else if( String(this.componentEditRequestDetials.cntr_exterior_w).length>7)
        {
          this.compExtWInValid=true;
          this.compExtWInValidReason="Max Length Of Characters can be 7";
        }*/
        else
        {
          this.compExtWInValid=false;
          this.compExtWInValidReason=null;
        }
    }
    else if(String(this.componentEditRequestDetials.cntr_exterior_w).includes('.'))
    {

        if(String(cont_ext_w[0]).length<0 || String(cont_ext_w[0]).length>3)
        {
          this.compExtWInValid=true;
          this.compExtWInValidReason='Should be of range 0 to 999.999';
        }
        else if(this.componentEditRequestDetials.cntr_exterior_w<=0 || this.componentEditRequestDetials.cntr_exterior_w>999.999 )
        {
          this.compExtWInValid=true;
          this.compExtWInValidReason='Should be of range 0 to 999.999';
        }
        else if(String(cont_ext_w[1]).length<0 || String(cont_ext_w[1]).length>3)
        {
          this.compExtWInValid=true;
          this.compExtWInValidReason='Max Length Of decimal values can be 3 ';
        }
        else
        {
          this.compExtWInValid=false;
          this.compExtWInValidReason=null;
      }
    }
    else
    {
    this.compExtWInValid=false;
    this.compExtWInValidReason=null;
    }
}
validateCompExtHForEdit()
{
  const cont_ext_h = String(this.componentEditRequestDetials.cntr_exterior_h).split('.');
  if(this.componentEditRequestDetials.cntr_exterior_h==null || this.componentEditRequestDetials.cntr_exterior_h==0)
  {
    this.compExtHInValid=true;
    this.compExtHInValidReason='Required';
  }
  else if(!String(this.componentEditRequestDetials.cntr_exterior_h).includes('.'))
  {
  if(this.componentEditRequestDetials.cntr_exterior_h<=0 || this.componentEditRequestDetials.cntr_exterior_h>999.999 )
      {
        this.compExtHInValid=true;
        this.compExtHInValidReason='Should be of range 0 to 999.999';
      }
      /*else if( String(this.componentEditRequestDetials.cntr_exterior_h).length>7)
      {
        this.compExtHInValid=true;
        this.compExtHInValidReason="Max Length Of Characters can be 7";
      }*/
      else
      {
        this.compExtHInValid=false;
        this.compExtHInValidReason=null;
      }
  }
  else if(String(this.componentEditRequestDetials.cntr_exterior_h).includes('.'))
  {

      if(String(cont_ext_h[0]).length<0 || String(cont_ext_h[0]).length>3)
      {
        this.compExtHInValid=true;
        this.compExtHInValidReason='Should be of range 0 to 999.999';
      }
      else if(this.componentEditRequestDetials.cntr_exterior_h<=0 || this.componentEditRequestDetials.cntr_exterior_h>999.999 )
      {
        this.compExtHInValid=true;
        this.compExtHInValidReason='Should be of range 0 to 999.999';
      }
      else if(String(cont_ext_h[1]).length<0 || String(cont_ext_h[1]).length>3)
      {
        this.compExtHInValid=true;
        this.compExtHInValidReason='Max Length Of decimal values can be 3';
      }
      else
      {
        this.compExtHInValid=false;
        this.compExtHInValidReason=null;
    }
  }
  else
  {
  this.compExtHInValid=false;
  this.compExtHInValidReason=null;
  }
}



validateWareHourseStckHtCompForEdit()
{
  if(this.componentEditRequestDetials.warehouse_stack_ht==null || this.componentEditRequestDetials.warehouse_stack_ht==0)
  {
    this.wareHourseStackHtInvaidComp=true;
    this.wareHourseStackHtInvaidReasonComp='Required';
  }
  else if(this.componentEditRequestDetials.warehouse_stack_ht<1 || this.componentEditRequestDetials.warehouse_stack_ht>99 || String(this.componentEditRequestDetials.warehouse_stack_ht).includes('.'))
  {
    this.wareHourseStackHtInvaidComp=true;
    this.wareHourseStackHtInvaidReasonComp='Should be of range 1 to 99 and cannot contain Decimal';
  }
  else
  {
    this.wareHourseStackHtInvaidComp=false;
    this.wareHourseStackHtInvaidReasonComp=null;
  }
}





approveOrRejectButtonClickComp(value:string)
{
  this.compRequestRejectionEmailFormat=[]; // This varaible will have values only in rejection case
  this.compRequestApprovalEmailFormat=[];

  this.validateCompTareWeightForEdit();
  this.validateCompCapWtForEdit();;
  this.validateCompQtPerMinSuForEdit();
  this.validateCompExtLForEdit();
  this.validateCompExtWForEdit();
  this.validateCompExtHForEdit();
  this.validateWareHourseStckHtCompForEdit();
  this.validateCompBaseForEdit()
  this.validateCompDescForEdit()
  // this.validatePlantCodeCompForEdit()
  this.validateMastCatgCompForEdit()
  this.validateCompRetTypeForEdit()
  this.validateCntrTypeCodeCompForEdit()
  this.validateCompMaterialForEdit()
  this.validateCOSCompForEdit()
  // this.validateCompGrpForEdit()
  this.validateCompContactForEdit()
  this.validateCompContactPhoneForEdit()
  this.validateCompActiveForEdit()

  if(
    !this.compTareWtInValid && !this.compCapWtInValid && !this.compQtPerMinSuInValid &&
    !this.compExtLInValid && !this.compExtWInValid && !this.compExtHInValid &&
    !this.wareHourseStackHtInvaidComp &&
     !this.compBaseInvalid && !this.compDescInvalid && !this.plantCodeCompInvalid &&
     !this.mastrCatgInvalidComp && !this.retTypeInvalidComp && !this.compTypeInvalid &&
    !this.compMaterialInvalid && !this.cosInvalidComp &&
    // !this.compGrpInvalid &&
    !this.compCntctInvalid && !this.cntctPhoneInvalidComp && !this.compActiveInvalid)
  {
    if(value != null && value.toLowerCase().trim() == 'approve')
    {
      this.approveButtonComponentRequest()
    }
    else if(value != null && value.toLowerCase().trim() == 'reject')
    {
      
      if(this.rejectionCommentsComp!=null && this.rejectionCommentsComp.trim()!="")
      {
        this.enable_dialog_notification_comp=false;
        this.rejectButtonComponentRequest()
      }
      else
      {
        this.enable_dialog_notification_comp=true;
      }

    }
  }
}

approveButtonComponentRequest()
{
  this.fromRejectButtonComp=false;

  this.componentEditRequestDetials.cd_plant=this.editSelectedPlantCodeComp.plantGSDBCode.trim();
  // this.containerEditRequestDetials.ownrshp_catg="Ford"
 this.componentEditRequestDetials.cntr_grp_desc=this.edit_selected_cntr_group_comp.cntr_grp;
  this.componentEditRequestDetials.cntr_matr_desc=this.edit_selected_cntr_material_comp.cntr_matr_name;
  this.componentEditRequestDetials.cntr_mstr_catg=this.edit_selected_cntr_master_category_comp.cntr_mstr_catg;
  this.componentEditRequestDetials.cntr_typ_desc=String(this.edit_selected_cntr_type_code_comp.cd_cntr_typ);
  this.componentEditRequestDetials.typ_no=this.edit_selected_cntr_type_code_comp.typ_no;
  this.componentEditRequestDetials.ownrshp_catg=this.edit_selected_ownrship_catg_comp.ownrshp_catg_val;
  this.componentEditRequestDetials.ownrshp_strat_code=this.edit_selected_cntr_ownrship_strat_code_comp.ownrshp_strat_code;

  if(this.componentEditRequestDetials.cntr_suffix!=null && this.componentEditRequestDetials.cntr_suffix.trim()=="")
  {
    this.componentEditRequestDetials.cntr_suffix=null;
  }
  //this.cntrdetailsedit.cntr_suffix=this.selectedSuffixList.suppliergsdbcode;


 /* if(this.componentEditRequestDetials.iscontainercolapsble!=null && this.componentEditRequestDetials.iscontainercolapsble.toLowerCase()=='n')
  {
    this.componentEditRequestDetials.collapsed_ht=1;
  }
*/
if(this.componentEditRequestDetials.cntr_desc != null)
{
  this.componentEditRequestDetials.cntr_desc=this.componentEditRequestDetials.cntr_desc.trim();
}

  if(this.componentEditRequestDetials.img_notes != null)
  {
    this.componentEditRequestDetials.img_notes=this.componentEditRequestDetials.img_notes.trim();
  }

  if(this.componentEditRequestDetials.ford_cntct != null)
  {
    this.componentEditRequestDetials.ford_cntct=this.componentEditRequestDetials.ford_cntct.trim();
  }
  if(this.componentEditRequestDetials.cntct_phone != null)
  {
    this.componentEditRequestDetials.cntct_phone=this.componentEditRequestDetials.cntct_phone.trim();
  }

  if(this.edit_measure=='kg/cm')
{

 this.componentEditRequestDetials.UoM='M'

}
else
{
  this.componentEditRequestDetials.UoM='E'
}

console.log('approveButtonContainerRequest',this.componentEditRequestDetials)

this.componentRequestDetialsForPost=this.componentEditRequestDetials;
this.postUpdatedCompDetailsToApi();

}
rejectButtonComponentRequest()
{


  this.componentEditRequestDetials.cd_plant=this.editSelectedPlantCodeComp.plantGSDBCode.trim();
  // this.containerEditRequestDetials.ownrshp_catg="Ford"
  this.componentEditRequestDetials.cntr_grp_desc=this.edit_selected_cntr_group_comp.cntr_grp;
  this.componentEditRequestDetials.cntr_matr_desc=this.edit_selected_cntr_material_comp.cntr_matr_name;
  this.componentEditRequestDetials.cntr_mstr_catg=this.edit_selected_cntr_master_category_comp.cntr_mstr_catg;
  this.componentEditRequestDetials.cntr_typ_desc=String(this.edit_selected_cntr_type_code_comp.cd_cntr_typ);
  this.containerEditRequestDetials.typ_no=this.edit_selected_cntr_type_code_comp.typ_no;
  this.componentEditRequestDetials.ownrshp_catg=this.edit_selected_ownrship_catg_comp.ownrshp_catg_val;
  this.componentEditRequestDetials.ownrshp_strat_code=this.edit_selected_cntr_ownrship_strat_code_comp.ownrshp_strat_code;
  
  if(this.componentEditRequestDetials.cntr_suffix!=null && this.componentEditRequestDetials.cntr_suffix.trim()=="")
  {
    this.componentEditRequestDetials.cntr_suffix=null;
  }
  //this.cntrdetailsedit.cntr_suffix=this.selectedSuffixList.suppliergsdbcode;


 /* if(this.componentEditRequestDetials.iscontainercolapsble!=null && this.componentEditRequestDetials.iscontainercolapsble.toLowerCase()=='n')
  {
    this.componentEditRequestDetials.collapsed_ht=1;
  }
*/
 if(this.componentEditRequestDetials.cntr_desc != null)
  {
  this.componentEditRequestDetials.cntr_desc=this.componentEditRequestDetials.cntr_desc.trim();
  }

  if(this.componentEditRequestDetials.img_notes != null)
  {
    this.componentEditRequestDetials.img_notes=this.componentEditRequestDetials.img_notes.trim();
  }

  if(this.componentEditRequestDetials.ford_cntct != null)
  {
  this.componentEditRequestDetials.ford_cntct=this.componentEditRequestDetials.ford_cntct.trim();
  }
  if(this.componentEditRequestDetials.cntct_phone != null)
  {
  this.componentEditRequestDetials.cntct_phone=this.componentEditRequestDetials.cntct_phone.trim();
  }

  if(this.edit_measure=='kg/cm')
{

 this.componentEditRequestDetials.UoM='M'

}
else
{
  this.componentEditRequestDetials.UoM='E'
}

console.log('RejectButtonContainerRequest',this.componentEditRequestDetials)


this.fromRejectButtonComp=true

  /*const keys1 = Object.keys(this.containerEditRequestDetials);
  var fieldsChanged:boolean=false
  for (let key of keys1) {
  if((typeof(this.containerEditRequestDetials[key]) == "number"  && (this.containerEditRequestDetials[key] !== this.containerOriginalRequestDetials[key])) ||
    (typeof(this.containerEditRequestDetials[key]) == "string"  && (this.containerEditRequestDetials[key] != null && (this.containerEditRequestDetials[key].trim() !== this.containerOriginalRequestDetials[key].trim()))) )
    {
      fieldsChanged=true;
      break;
    }
  }*/

  console.log('original',this.componentOriginalRequestDetials)
  console.log('changed',this.componentEditRequestDetials)

/*
  if(JSON.stringify(this.containerOriginalRequestDetials) !== JSON.stringify(this.containerEditRequestDetials))
  {
    this.confirmPopUp=true;
    this.containerOriginalRequestDetials.components
  }*/
  let fieldsChanged=false;
  const keys1 = Object.keys(this.componentOriginalRequestDetials);
  for (const key of keys1) {
  if(key != "components" && key != "req_stat" && (
    (typeof(this.componentOriginalRequestDetials[key]) == 'number'  && (this.componentOriginalRequestDetials[key] !== this.componentEditRequestDetials[key])) ||
    (typeof(this.componentOriginalRequestDetials[key]) == 'string'  && (
    (this.componentOriginalRequestDetials[key] != null && this.componentEditRequestDetials[key] != null && this.componentOriginalRequestDetials[key].trim().toLowerCase() !== this.componentEditRequestDetials[key].trim().toLowerCase())
    ||
    (this.componentOriginalRequestDetials[key] != null && this.componentEditRequestDetials[key] == null)
    ||
    (this.componentOriginalRequestDetials[key] == null && this.componentEditRequestDetials[key] != null)
    ))
    ) )
    {
      fieldsChanged=true
    }
  }

  if(fieldsChanged == true)
  {
    this.confirmPopUpComp=true;
  }

  else
  {
    this.rejectWithOriginalCompDetails();
  }




}

ok_notification_dialog_comp()
{
  this.enable_dialog_notification_comp=false;
}

rejectWithOriginalCompDetails()
{
  this.confirmPopUpComp=false;
  this.componentRequestDetialsForPost=this.componentOriginalRequestDetials;
  this.postUpdatedCompDetailsToApi();

}
rejectWithChangedCompDetails()
{
  this.confirmPopUpComp=false;
  this.componentRequestDetialsForPost=this.componentEditRequestDetials;
  this.postUpdatedCompDetailsToApi();
}

postUpdatedCompDetailsToApi()
{

  console.log('postUpdatedDetailsToApi',this.componentRequestDetialsForPost)

  // this.containerRequestDetialsForPost.id_user=this.transactionService.getcdsid();
  if(this.fromRejectButtonComp == true)
  {
  this.componentRequestDetialsForPost.req_stat='Rejected';
    this.compRequestRejectionEmailFormat=[];

    this.compRequestRejectionEmailFormat.push({
      "suppGsdbCode":this.suppGSDBCode,
      "cntrBase":this.componentRequestDetialsForPost.cntr_base,
      "cntrSuffix":this.componentRequestDetialsForPost.cntr_suffix,
      "cos":this.componentRequestDetialsForPost.ownrshp_strat_code,
      "retType":this.componentRequestDetialsForPost.exp_refundable,              // Returnable / Expendable
      "cntrType":this.edit_selected_cntr_type_code_comp.cd_cntr_typ+'-'+this.edit_selected_cntr_type_code_comp.cntr_typ_desc,
      "reqFor":2,           //1-Container / 2-Component
      "comments":"Rejected by "+this.userdetails.title+this.userdetails.name+"("+this.userdetails.email+")"+"<br> <br> <u> Rejection Comments: </u> <br>"+this.rejectionCommentsComp,
      "plantcode":this.componentRequestDetialsForPost.cd_plant,
      "region":this.region_code,
      "isSupp":"Y"
    })
  }
  else
  {

    this.compRequestApprovalEmailFormat=[];

    this.compRequestApprovalEmailFormat.push({
      "suppGsdbCode":this.suppGSDBCode,
      "cntrBase":this.componentRequestDetialsForPost.cntr_base,
      "cntrSuffix":this.componentRequestDetialsForPost.cntr_suffix,
      "cos":this.componentRequestDetialsForPost.ownrshp_strat_code,
      "retType":this.componentRequestDetialsForPost.exp_refundable,              // Returnable / Expendable
      "cntrType":this.edit_selected_cntr_type_code_comp.cd_cntr_typ+'-'+this.edit_selected_cntr_type_code_comp.cntr_typ_desc,
      "reqFor":2,           //1-Container / 2-Component
      "comments":"",
      "plantcode":this.componentRequestDetialsForPost.cd_plant,
      "region":this.region_code,
      "isSupp":"Y"
    })

    this.componentRequestDetialsForPost.req_stat="New";
  }
  this.componentRequestDetialsForPost.ts_last_update=this.date.transform(new Date(),'yyyy-MM-dd');

  this.componentEditRequestDetials.currency_desc=this.edit_selected_cntr_currency_list_comp.curr_desc;

  this.componentRequestDetialsForPost.extr_cntr_dsgn_img_ind='No';
  this.componentRequestDetialsForPost.intr_cntr_dsgn_img_ind='No';
  this.componentRequestDetialsForPost.shpg_unit_img_ind='No';
  this.componentRequestDetialsForPost.cad_img_ind='No';


  if(this.componentRequestDetialsForPost.service_life==null)
  {
    this.componentRequestDetialsForPost.service_life='';
  }


  if(this.componentRequestDetialsForPost.cntr_drawing_no == null)
  {
    this.componentRequestDetialsForPost.cntr_drawing_no=''
  }



  this.componentRequestDetialsForPost.ford_rntl_dly_cost=0;
  this.componentRequestDetialsForPost.purchase_cost=0;
  this.componentRequestDetialsForPost.rental_issue_cost=0;
  this.componentRequestDetialsForPost.return_rntl_cost=0;
  this.componentRequestDetialsForPost.sup_rntl_dly_cost=0;
  this.componentRequestDetialsForPost.tooling_dev_cost=0;

  this.componentRequestDetialsForPost.collapsed_ht=1;
  this.componentRequestDetialsForPost.nest_ratio=1;
  this.componentRequestDetialsForPost.nest_ratio_1=1;
  this.componentRequestDetialsForPost.shpg_unit_stack_ht=1;



  this.validateCompTareWeightForEdit();
  this.validateCompCapWtForEdit();
  this.validateCompQtPerMinSuForEdit();
  this.validateCompExtLForEdit();
  this.validateCompExtWForEdit();
  this.validateCompExtHForEdit();
  this.validateWareHourseStckHtCompForEdit();
  this.validateCompBaseForEdit()
  this.validateCompDescForEdit()
  // this.validatePlantCodeCompForEdit()
  this.validateMastCatgCompForEdit()
  this.validateCompRetTypeForEdit()
  this.validateCntrTypeCodeCompForEdit()
  this.validateCompMaterialForEdit()
  this.validateCOSCompForEdit()
  // this.validateCompGrpForEdit()
  this.validateCompContactForEdit()
  this.validateCompContactPhoneForEdit()
  this.validateCompActiveForEdit()

  if(
    !this.compTareWtInValid && !this.compCapWtInValid && !this.compQtPerMinSuInValid &&
    !this.compExtLInValid && !this.compExtWInValid && !this.compExtHInValid &&
    !this.wareHourseStackHtInvaidComp &&  !this.compBaseInvalid && !this.compDescInvalid && !this.plantCodeCompInvalid &&
     !this.mastrCatgInvalidComp && !this.retTypeInvalidComp && !this.compTypeInvalid &&
    !this.compMaterialInvalid && !this.cosInvalidComp &&
    // !this.compGrpInvalid &&
    !this.compCntctInvalid && !this.cntctPhoneInvalidComp && !this.compActiveInvalid)
  {


    console.log('postUpdatedDetailsToApi',this.componentRequestDetialsForPost)

    if(!this.fromRejectButtonComp)
    {
    let suffixToAPI:string=this.componentRequestDetialsForPost.cntr_suffix;

    if(this.componentRequestDetialsForPost.cntr_suffix==null || this.componentRequestDetialsForPost.cntr_suffix.trim()=='')
    {
      suffixToAPI='%20%20%20%20%20%20%20%20';
    }

    this.transactionService.getRequestStatusOfContainer(this.componentRequestDetialsForPost.cntr_base,suffixToAPI,this.componentRequestDetialsForPost.ownrshp_strat_code,this.componentRequestDetialsForPost.cd_region).subscribe(requestStatus=>{
      console.log('Request Status',requestStatus);
      const requestStatusReceived=requestStatus;

      if(requestStatusReceived!= null && requestStatusReceived.trim() != '' && requestStatusReceived.toLowerCase() =='pending')
      {
        this.enableCompEditPendingNotification=true;
      }
      else
      {
        if(this.componentRequestDetialsForPost.function!=null && this.componentRequestDetialsForPost.function!='' && this.componentRequestDetialsForPost.function=='A')
        {
          this.enableAddingNewCompNotification=true;
        }
        else if(this.componentRequestDetialsForPost.function!=null && this.componentRequestDetialsForPost.function!='' && this.componentRequestDetialsForPost.function=='C')
        {
          // this.enableUpdateExistingCompNotification=true;
          if(this.componentRequestDetialsForPost.cntr_suffix==null || this.componentRequestDetialsForPost.cntr_suffix.trim()=='')
          {
            suffixToAPI="-";
          }
      
          // Checking if NGPP feilds are changed 

          console.log("Came in 5581",this.componentRequestDetialsForPost.cntr_base,suffixToAPI,this.componentRequestDetialsForPost.ownrshp_strat_code,this.componentRequestDetialsForPost.cd_region,this.componentRequestDetialsForPost.cntr_typ_desc)


          this.transactionService.checkWhetherCntrInNgppCount(this.componentRequestDetialsForPost.cntr_base,suffixToAPI,this.componentRequestDetialsForPost.ownrshp_strat_code,this.componentRequestDetialsForPost.cd_region,this.componentRequestDetialsForPost.cntr_typ_desc).subscribe(countDets=>{
            console.log(countDets);
            
            if(countDets == null)
            {
              this.enableUpdateExistingCompNotification=true;
            }
            else
            {
              this.cntr_countDets = countDets.cd_cntr;
              this.compDetsUpdate();
            }
          })
        }

      }
    })
  }
  else
  {
    console.log("else reject")
    this.saveCompDBIA();
  }


  }

}
compDetsUpdate()
{
    console.log("Edit Container Request Dets", this.componentRequestDetialsForPost)
    this.transactionService.editbutton_get_contr_details(this.componentRequestDetialsForPost.cntr_base,this.cntr_countDets).subscribe(compdetails=>{
        this.containerdetails=compdetails;

        this.cntrCmmsFieldsBeforeUpdate.cntr_base = this.containerdetails.cntr_base
        this.cntrCmmsFieldsBeforeUpdate.cntr_suffix = this.containerdetails.cntr_suffix
        this.cntrCmmsFieldsBeforeUpdate.cntr_desc = this.containerdetails.cntr_desc
        this.cntrCmmsFieldsBeforeUpdate.cntr_typ_desc = this.containerdetails.cntr_typ_desc
        this.cntrCmmsFieldsBeforeUpdate.cd_plant = this.containerdetails.cd_plant 
        this.cntrCmmsFieldsBeforeUpdate.collapsed_ht = this.containerdetails.collapsed_ht
        this.cntrCmmsFieldsBeforeUpdate.nest_ratio = this.containerdetails.nest_ratio
        this.cntrCmmsFieldsBeforeUpdate.nest_ratio_1 = this.containerdetails.nest_ratio_1
        this.cntrCmmsFieldsBeforeUpdate.cntr_tare_wt = this.containerdetails.cntr_tare_wt
        this.cntrCmmsFieldsBeforeUpdate.cntr_capacity = this.containerdetails.cntr_capacity
        this.cntrCmmsFieldsBeforeUpdate.cntr_qt_per_min_su = this.containerdetails.cnt_qt_per_min_su
        this.cntrCmmsFieldsBeforeUpdate.ford_cntct = this.containerdetails.ford_cntct
        this.cntrCmmsFieldsBeforeUpdate.cntct_phone = this.containerdetails.cntct_phone
        this.cntrCmmsFieldsBeforeUpdate.cntr_exterior_l = this.containerdetails.cntr_exterior_l
        this.cntrCmmsFieldsBeforeUpdate.cntr_exterior_w = this.containerdetails.cntr_exterior_w
        this.cntrCmmsFieldsBeforeUpdate.cntr_exterior_h = this.containerdetails.cntr_exterior_h
        this.cntrCmmsFieldsBeforeUpdate.shpg_unit_stack_ht = this.containerdetails.shpg_unit_stack_ht
        this.cntrCmmsFieldsBeforeUpdate.warehouse_stack_ht = this.containerdetails.warehouse_stack_ht
        this.cntrCmmsFieldsBeforeUpdate.ownrshp_catg = this.containerdetails.ownrshp_catg


        console.log("container Dets", this.containerdetails)
        this.cntrCmmsFieldsAfterUpdate.cntr_base = JSON.parse(JSON.stringify(this.componentEditRequestDetials.cntr_base));
        this.cntrCmmsFieldsAfterUpdate.cntr_suffix = JSON.parse(JSON.stringify(this.componentEditRequestDetials.cntr_suffix));
        this.cntrCmmsFieldsAfterUpdate.cntr_desc = JSON.parse(JSON.stringify(this.componentEditRequestDetials.cntr_desc));
        this.cntrCmmsFieldsAfterUpdate.cntr_typ_desc = JSON.parse(JSON.stringify(this.componentEditRequestDetials.cntr_typ_desc));
        this.cntrCmmsFieldsAfterUpdate.cd_plant = JSON.parse(JSON.stringify(this.componentEditRequestDetials.cd_plant));
        this.cntrCmmsFieldsAfterUpdate.collapsed_ht = JSON.parse(JSON.stringify(this.componentEditRequestDetials.collapsed_ht));
        this.cntrCmmsFieldsAfterUpdate.nest_ratio = JSON.parse(JSON.stringify(this.componentEditRequestDetials.nest_ratio));
        this.cntrCmmsFieldsAfterUpdate.nest_ratio_1 = JSON.parse(JSON.stringify(this.componentEditRequestDetials.nest_ratio_1));
        this.cntrCmmsFieldsAfterUpdate.cntr_tare_wt = JSON.parse(JSON.stringify(this.componentEditRequestDetials.cntr_tare_wt));
        this.cntrCmmsFieldsAfterUpdate.cntr_capacity = JSON.parse(JSON.stringify(this.componentEditRequestDetials.cntr_capacity));
        this.cntrCmmsFieldsAfterUpdate.cntr_qt_per_min_su = JSON.parse(JSON.stringify(this.componentEditRequestDetials.cntr_qt_per_min_su));
        this.cntrCmmsFieldsAfterUpdate.ford_cntct = JSON.parse(JSON.stringify(this.componentEditRequestDetials.ford_cntct ));
        this.cntrCmmsFieldsAfterUpdate.cntct_phone = JSON.parse(JSON.stringify(this.componentEditRequestDetials.cntct_phone));
        this.cntrCmmsFieldsAfterUpdate.cntr_exterior_l = JSON.parse(JSON.stringify(this.componentEditRequestDetials.cntr_exterior_l));
        this.cntrCmmsFieldsAfterUpdate.cntr_exterior_w = JSON.parse(JSON.stringify(this.componentEditRequestDetials.cntr_exterior_w));
        this.cntrCmmsFieldsAfterUpdate.cntr_exterior_h = JSON.parse(JSON.stringify(this.componentEditRequestDetials.cntr_exterior_h));
        this.cntrCmmsFieldsAfterUpdate.shpg_unit_stack_ht = JSON.parse(JSON.stringify(this.componentEditRequestDetials.shpg_unit_stack_ht));
        this.cntrCmmsFieldsAfterUpdate.warehouse_stack_ht = JSON.parse(JSON.stringify(this.componentEditRequestDetials.warehouse_stack_ht));
        this.cntrCmmsFieldsAfterUpdate.ownrshp_catg = JSON.parse(JSON.stringify(this.componentEditRequestDetials.ownrshp_catg));

       
        var cmmsFieldsChanged=false;
          const keys1 = Object.keys(this.cntrCmmsFieldsBeforeUpdate);
          for (let key of keys1) {
            console.log("KEY", key, (
              (typeof(this.cntrCmmsFieldsBeforeUpdate[key]) == "number"  && (this.cntrCmmsFieldsBeforeUpdate[key] !== this.cntrCmmsFieldsAfterUpdate[key])) ||
              (typeof(this.cntrCmmsFieldsBeforeUpdate[key]) == "string"  && (this.cntrCmmsFieldsBeforeUpdate[key].trim() !== this.cntrCmmsFieldsAfterUpdate[key].trim())) ))
          if(
            (typeof(this.cntrCmmsFieldsBeforeUpdate[key]) == "number"  && (this.cntrCmmsFieldsBeforeUpdate[key] !== this.cntrCmmsFieldsAfterUpdate[key])) ||
            (typeof(this.cntrCmmsFieldsBeforeUpdate[key]) == "string"  && (this.cntrCmmsFieldsBeforeUpdate[key].trim() !== this.cntrCmmsFieldsAfterUpdate[key].trim())) )
            {
              cmmsFieldsChanged=true
            }
          }

          console.log("Iscmmsfieldschanged", cmmsFieldsChanged);
          if(cmmsFieldsChanged)
          {
            this.enableUpdateNgppFieldsNotificationForComp = false;
            this.saveCompDBIA();
          }
          else{
            this.enableUpdateNgppFieldsNotificationForComp = true;
          }

    })

  
}

okUpdateCompDetsToCntrAdmin()
{
  if(this.enableUpdateNgppFieldsNotificationForComp == true)
  {
    this.componentRequestDetialsForPost = this.componentEditRequestDetials;

    var suffixToAPI:string=this.componentRequestDetialsForPost.cntr_suffix;

    console.log("post container details", this.componentRequestDetialsForPost)

    if(this.componentRequestDetialsForPost.cntr_suffix==null || this.componentRequestDetialsForPost.cntr_suffix.trim()=='')
    {
      suffixToAPI="%20%20%20%20%20%20%20%20";
    }  

    console.log("REQSTAT", this.componentRequestDetialsForPost.cntr_base,suffixToAPI,this.componentRequestDetialsForPost.ownrshp_strat_code,this.componentRequestDetialsForPost.cd_region)
    this.enableUpdateNgppFieldsNotificationForComp = false;
    this.transactionService.getRequestStatusOfContainer(this.componentRequestDetialsForPost.cntr_base,suffixToAPI,this.componentRequestDetialsForPost.ownrshp_strat_code,this.componentRequestDetialsForPost.cd_region).subscribe(requestStatus=>{
      console.log("Request Status",requestStatus);
      var requestStatusReceived=requestStatus;

      

      if(this.cntr_countDets != 0 && this.componentRequestDetialsForPost != null)
      {
        //service to call api to push data 
        this.saveEditContainerCntrAdmin.cd_cntr=this.cntr_countDets;
        this.saveEditContainerCntrAdmin.cntr_base=this.componentRequestDetialsForPost.cntr_base;
        this.saveEditContainerCntrAdmin.cntr_suff_ind= this.componentRequestDetialsForPost.cntr_suff_ind;
        this.saveEditContainerCntrAdmin.exp_refundable=this.componentRequestDetialsForPost.exp_refundable;
        this.saveEditContainerCntrAdmin.cntr_active_ind=this.componentRequestDetialsForPost.cntr_active_ind
        this.saveEditContainerCntrAdmin.cntr_suffix=this.componentRequestDetialsForPost.cntr_suffix;
        this.saveEditContainerCntrAdmin.cntr_desc=this.componentRequestDetialsForPost.cntr_desc;
        this.saveEditContainerCntrAdmin.card_call=this.componentRequestDetialsForPost.card_call
        this.saveEditContainerCntrAdmin.cntr_mstr_catg=this.componentRequestDetialsForPost.cntr_mstr_catg;
        this.saveEditContainerCntrAdmin.cntr_typ_desc=this.componentRequestDetialsForPost.cntr_typ_desc;
        this.saveEditContainerCntrAdmin.ownrshp_strat_code=this.componentRequestDetialsForPost.ownrshp_strat_code;
        this.saveEditContainerCntrAdmin.ownrshp_catg=this.componentRequestDetialsForPost.ownrshp_catg;
        this.saveEditContainerCntrAdmin.cntr_grp_desc=this.componentRequestDetialsForPost.cntr_grp_desc;
        this.saveEditContainerCntrAdmin.cntr_matr_desc=this.componentRequestDetialsForPost.cntr_matr_desc;
        this.saveEditContainerCntrAdmin.currency_desc=this.componentRequestDetialsForPost.currency_desc;
        this.saveEditContainerCntrAdmin.iscontainercolapsble=this.componentRequestDetialsForPost.iscontainercolapsble;
        this.saveEditContainerCntrAdmin.cntr_exterior_l=this.componentRequestDetialsForPost.cntr_exterior_l;
        this.saveEditContainerCntrAdmin.cntr_exterior_w=this.componentRequestDetialsForPost.cntr_exterior_w;
        this.saveEditContainerCntrAdmin.cntr_exterior_h=this.componentRequestDetialsForPost.cntr_exterior_h;
        this.saveEditContainerCntrAdmin.collapsed_ht=this.componentRequestDetialsForPost.collapsed_ht;
        this.saveEditContainerCntrAdmin.nest_ratio=this.componentRequestDetialsForPost.nest_ratio;
        this.saveEditContainerCntrAdmin.cntr_interior_l=this.componentRequestDetialsForPost.cntr_interior_l;
        this.saveEditContainerCntrAdmin.cntr_interior_w=this.componentRequestDetialsForPost.cntr_interior_w;
        this.saveEditContainerCntrAdmin.cntr_interior_h=this.componentRequestDetialsForPost.cntr_interior_h;
        this.saveEditContainerCntrAdmin.cntr_tare_wt=this.componentRequestDetialsForPost.cntr_tare_wt;
        this.saveEditContainerCntrAdmin.cntr_capacity=this.componentRequestDetialsForPost.cntr_capacity;
        this.saveEditContainerCntrAdmin.cntr_per_layer=this.componentRequestDetialsForPost.cntr_per_layer;
        this.saveEditContainerCntrAdmin.max_layers_shpg_unit=this.componentRequestDetialsForPost.max_layers_shpg_unit;
        this.saveEditContainerCntrAdmin.max_cntr_per_shpg_unit=this.componentRequestDetialsForPost.max_cntr_per_shpg_unit;
        this.saveEditContainerCntrAdmin.cntr_drawing_no=this.componentRequestDetialsForPost.cntr_drawing_no;
        this.saveEditContainerCntrAdmin.shpg_unit_exterior_l=this.componentRequestDetialsForPost.shpg_unit_exterior_l;
        this.saveEditContainerCntrAdmin.shpg_unit_exterior_w=this.componentRequestDetialsForPost.shpg_unit_exterior_w;
        this.saveEditContainerCntrAdmin.shpg_unit_exterior_h=this.componentRequestDetialsForPost.shpg_unit_exterior_h;
        this.saveEditContainerCntrAdmin.shpg_unit_nstdorflded_ht=this.componentRequestDetialsForPost.shpg_unit_nstdorflded_ht;
        this.saveEditContainerCntrAdmin.shpg_unit_tare_wt=this.componentRequestDetialsForPost.shpg_unit_tare_wt;
        this.saveEditContainerCntrAdmin.shpg_unit_stack_ht=this.componentRequestDetialsForPost.shpg_unit_stack_ht;
        this.saveEditContainerCntrAdmin.warehouse_stack_ht=this.componentRequestDetialsForPost.warehouse_stack_ht;
        this.saveEditContainerCntrAdmin.service_life=this.componentRequestDetialsForPost.service_life;
        this.saveEditContainerCntrAdmin.purchase_cost=this.componentRequestDetialsForPost.purchase_cost;
        this.saveEditContainerCntrAdmin.rental_issue_cost=this.componentRequestDetialsForPost.rental_issue_cost;
        this.saveEditContainerCntrAdmin.sup_rntl_dly_cost=this.componentRequestDetialsForPost.sup_rntl_dly_cost;
        this.saveEditContainerCntrAdmin.ford_rntl_dly_cost=this.componentRequestDetialsForPost.ford_rntl_dly_cost;
        this.saveEditContainerCntrAdmin.return_rntl_cost=this.componentRequestDetialsForPost.return_rntl_cost;
        this.saveEditContainerCntrAdmin.tooling_dev_cost=this.componentRequestDetialsForPost.tooling_dev_cost;
        this.saveEditContainerCntrAdmin.extr_cntr_dsgn_img_ind=this.componentRequestDetialsForPost.extr_cntr_dsgn_img_ind;
        this.saveEditContainerCntrAdmin.intr_cntr_dsgn_img_ind=this.componentRequestDetialsForPost.intr_cntr_dsgn_img_ind;
        this.saveEditContainerCntrAdmin.shpg_unit_img_ind=this.componentRequestDetialsForPost.shpg_unit_img_ind
        this.saveEditContainerCntrAdmin.cad_img_ind=this.componentRequestDetialsForPost.cad_img_ind;
        this.saveEditContainerCntrAdmin.img_notes=this.componentRequestDetialsForPost.img_notes;
        this.saveEditContainerCntrAdmin.cd_region=this.componentRequestDetialsForPost.cd_region;
        this.saveEditContainerCntrAdmin.cd_plant=this.componentRequestDetialsForPost.cd_plant;
        this.saveEditContainerCntrAdmin.cnt_qt_per_min_su=this.componentRequestDetialsForPost.cntr_qt_per_min_su;
        this.saveEditContainerCntrAdmin.nest_ratio_1=this.componentRequestDetialsForPost.nest_ratio_1;
        this.saveEditContainerCntrAdmin.ford_cntct=this.componentRequestDetialsForPost.ford_cntct;   
        this.saveEditContainerCntrAdmin.cntct_phone=this.componentRequestDetialsForPost.cntct_phone;
        this.saveEditContainerCntrAdmin.uom=this.componentRequestDetialsForPost.UoM;
        this.saveEditContainerCntrAdmin.id_user= this.componentRequestDetialsForPost.id_user
        this.saveEditContainerCntrAdmin.req_stat="Success";
        this.saveEditContainerCntrAdmin.ts_last_update=this.componentRequestDetialsForPost.ts_last_update;
        this.saveEditContainerCntrAdmin.typ_no=this.componentRequestDetialsForPost.typ_no;
        


        console.log("save direct to container admin post data", this.saveEditContainerCntrAdmin)

        
      var saveEditContainerCntrAdminArray=[];
      saveEditContainerCntrAdminArray.push(this.saveEditContainerCntrAdmin);

      if(requestStatusReceived.toLowerCase() != "pending")
      {
      this.transactionService.cntrAdminSaveCntrDetails(saveEditContainerCntrAdminArray).subscribe(cntr_save_res=>{
        console.log("direct cntr admin save edit",cntr_save_res)
        if(cntr_save_res.toLowerCase() == "success")
        {
          this.messageService.add({severity:'success', summary:'Success', detail:'Component Details got saved Successfully'});
          this.closeCompRequestDetails();
        }

    })
    
      }
    }
  })

  }
}
cancelUpdateNgppFiledsNotificationsForComp()
{
  this.enableUpdateNgppFieldsNotificationForComp = false;
  this.saveCntrAdminDetsInit();

}

okCompEditPendingNotification()
{
  this.enableCompEditPendingNotification=false;
}

okAddingNewCompNotification()
{
  this.enableAddingNewCompNotification=false;
  this.saveCompDBIA();
}

cancelAddingNewCompNotification()
{
  this.enableAddingNewCompNotification=false;
}

okUpdateExistingCompNotification()
{
  this.enableUpdateExistingCompNotification=false;
  if(this.componentRequestDetialsForPost!=null )
  {
    let base;
    let suffix
    if(this.componentRequestDetialsForPost.cntr_base!=null)
    {
      base=this.componentRequestDetialsForPost.cntr_base.toUpperCase();
    }

    if(this.componentRequestDetialsForPost.cntr_suffix!=null && this.componentRequestDetialsForPost.cntr_suffix.trim()!='')
    {
      suffix=this.componentRequestDetialsForPost.cntr_suffix.toUpperCase();
    }
    else
    {
      suffix='%20%20%20%20%20%20%20%20'
    }
  }

  // @ts-ignore
    this.transactionService.getContainerDetailsFromCMMS(base,suffix).subscribe(cntrdetails=>{

    const cntrDetailsFromAPI:cntrDetailsFromCMMS=cntrdetails;

    if(cntrDetailsFromAPI!=null)
    {
      this.componentRequestDetialsForPost.function='C'
    }
    else
    {
      this.componentRequestDetialsForPost.function='A';
    }

    console.log('details before approve/reject',this.componentRequestDetialsForPost)
    this.saveCompDBIA();
  });
}

cancelUpdateExistingCompNotification()
{
  this.enableUpdateExistingCompNotification=false;
}


  saveCompDBIA()
  {
    this.transactionService.edit_savecntrdetails(this.componentRequestDetialsForPost).subscribe(savecntr=>
      {
        const res=savecntr;
        console.log('successful',res);
        if(res.toLowerCase() == 'success')
        {
          this.rejectionCommentsComp=null;
          
          
          if(this.fromRejectButtonComp == true)
          {
            this.messageService.add({severity:'success', summary:'Success', detail:'Component Rejected Successfully'});

            //Trigger Email for rejection
            console.log("Rejection format",this.compRequestRejectionEmailFormat) 

            this.transactionService.triggerContainerOrComponentRejectionEmail(this.compRequestRejectionEmailFormat).subscribe((result=>{
              var responseComp=result;
              if(responseComp.toLowerCase()=="success")
              {
                this.compRequestRejectionEmailFormat=[];
                this.rejectionCommentsComp=null;
                this.enable_dialog_notification_comp=false;
                this.messageService.add({severity:'success', summary:'Success', detail:'Email Sent Successfully'});
              }
              else
              {
                this.messageService.add({severity:'error', summary: 'Error', detail: 'Email Not sent successfully'});
              }
            }))
          }
          else
          {
            this.messageService.add({severity:'success', summary:'Success', detail:'Component Approved Successfully'});
             //Trigger Email for rejection
             console.log("Rejection format",this.compRequestApprovalEmailFormat) 

             this.transactionService.triggerContainerOrComponentApprovalEmail(this.compRequestApprovalEmailFormat).subscribe((result=>{
               var responseaComp=result;
               if(responseaComp.toLowerCase()=="success")
               {
                 this.compRequestApprovalEmailFormat=[];
                 this.rejectionCommentsComp=null;
                 this.enable_dialog_notification_comp=false;
                 this.messageService.add({severity:'success', summary:'Success', detail:'Email Sent Successfully'});
               }
               else
               {
                 this.messageService.add({severity:'error', summary: 'Error', detail: 'Email Not sent successfully'});
               }
             }))
          }


          this.validationInitComp();
          this.enableApproveRejectCompRequest=false;
          this.enableApproveRejectRequest=false;
          this.suppGSDBCode=null;
          this.suppName=null;
          this.SuppCntct=null;
          this.suppEmail=null;
          this.requestCompOriginalDetailsInit();
          this.requestCompDetailsForPostInit();
          this.requestCompEditDetailsInit();
          this.populateDbiaRequestGrid();

        }
        else
        {
          if(this.fromRejectButtonComp == true)
          {
            this.messageService.add({severity:'error', summary: 'Error', detail: 'Container Not Rejected,Please Try Again'});
          }
          else
          {
            this.messageService.add({severity:'error', summary: 'Error', detail: 'Container Not Approved,Please Try Again'});
          }
            // this.addplantdetailsinit();
            this.validationInitComp();

        }
      })

  }
  closeCompRequestDetails()
  {
    this.enableApproveRejectCompRequest=false;
    this.enableApproveRejectRequest=false;
    this.rejectionCommentsComp=null;
    this.suppGSDBCode=null;
    this.suppName=null;
    this.SuppCntct=null;
    this.suppEmail=null;
    this.requestCompOriginalDetailsInit();
    this.requestCompDetailsForPostInit();
    this.requestCompEditDetailsInit();
    this.populateDbiaRequestGrid();
  }




}
