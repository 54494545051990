/* tslint:disable */
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng';
import { TransactionService } from '../loginpage/Transaction.service';
import { rolesDetails, rolesDetailsMappingEditAccess,containerOwnershipList, programsbasedonplants, ActivePlants, getUserType, editAccessGridFormat, userdetails, supplist, editSupplierProgramDets, progList, editAccessUserSuppProg } from '../loginpage/transactions';

@Component({
  selector: '[app-editaccess]',
  templateUrl: './editaccess.component.html',
  styleUrls: ['./editaccess.component.css'],
  providers:[MessageService,ConfirmationService]
})
export class EditaccessComponent implements OnInit {



  cdsid='';
  currentRegion='';
  region_code='';
  currentRouteid=0;


  plantList:ActivePlants[]=[];
  programList:programsbasedonplants[]=[];
  cosList:containerOwnershipList[]=[];
  userTypeList:getUserType[]=[];
  roleList:rolesDetailsMappingEditAccess[]=[];
  roleListApi:rolesDetails[]=[];

  // Supplier code
  supplierList: supplist[]=[];
  supplierListUnchanged: supplist[] = [];
  programListUnchanged: progList[] = [];
  selectedsuppliers: any = [];
  suppliercode:string="";
  all_selected_on_supplier_code:boolean=false;
  all_selected_programs: boolean=false;
  supplierCodeToAPI:any = [];
  enableRemoveSupsButton: boolean = false;
  editSuppProgAccesDets: editSupplierProgramDets[] = [];
  cd_programForEdit: number;
  programListEdit:progList[]=[];
  selectedprogramForEdit: any = [];
  selectedprograms: any[];
  programname:string;
  

  selectedWslID='';
  selectedUserName='';
  selectedGSDBCode='';
  selectedUserType:getUserType={userType:'',UserDisplay:''};
  selectedRoleList:rolesDetailsMappingEditAccess={cd_region:'',createdby:'',createddt:'',role_cd:0,role_id:0,role_desc:'',role_name:''};
  selectedCOS:containerOwnershipList={cd_region:'',cd_ownrshp_strat:0,ownrshp_strat_code:'',ownrshp_desc:''};
  selectedPlantCode:ActivePlants={cd_plant:'',plantGSDBCode:'',plant_name:'',plant_type_name:''};
  selectedPlantName:ActivePlants={cd_plant:'',plantGSDBCode:'',plant_name:'',plant_type_name:''};
  selectedProgram:programsbasedonplants={cd_region:'',plantgsdbcode:'',programname:''};


  wslID='';
  userName='';
  gsdbCode='';
  userType='';
  role='';
  cos='';
  plantcode='';
  plantname='';
  program='';


  enableProgram=false;
  enableGSDBCode=false;

  disablePlantCode=false;
  disablePlantName=false;
  disableCos=false;

  @Input('app-editaccess') valueToBeModified:any;

  editAcessGrid:editAccessGridFormat[]=[];
  cols:any;
  userInfoPopUp=false;
  userDetails:userdetails={
        gppuserid: '',
        title: '',
        name: '',
        company: '',
        designation: '',
        email: '',
        isforduser: '',
        isactive: '',
        createddt: '',
        createdby: '',
        phone: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        postalcode: '',
        cntrycodeiso: '',
        lastlogin: '',
        fax: '',
        country: '',
        usertype: ''
  }

  accessForDelete:editAccessGridFormat={
      gppuserid: '',
      user_name: '',
      usrtype: '',
      role_id: 0,
      role_name: '',
      cd_region: '',
      plantgsdbcode: '',
      plantname: '',
      program_name:'',
      cos: '',
      lastlogin: '',
      cd_supplier:'',
      "email":''
  }

  accessForEdit:editAccessGridFormat={
    "gppuserid": "",
    "user_name": "",
    "usrtype": "",
    "role_id": 0,
    "role_name": "",
    "cd_region": "",
    "plantgsdbcode": "",
    "plantname": "",
    "program_name":"",
    "cos": "",
    "lastlogin": "",
    "cd_supplier":"",
    "email":""
  }
  accessForEditToAPI:editAccessUserSuppProg={
    "gppuserid":'',
    "role_id":0,
    "cd_region":'',
    "plantgsdbcode":'',
    "cd_supplier":'',
    "cd_program":0,
    "ownershp_strat_code":'',
    "plant_name":'',
    "program_name":'',
    "createddt":'',
    "createdby":''
  }

  roleListforMapping=[{role_shot_name:'Supplier',rolefullname:'Supplier'},
  {role_shot_name:'design_src',rolefullname:'Design Source'},
  {role_shot_name:'prelimnry_appr',rolefullname:'Preliminary Approver'},
  {role_shot_name:'addntl_appvr',rolefullname:'Additional Approver'},
  {role_shot_name:'plt_appvr',rolefullname:'Plant Approver'},
  {role_shot_name:'plt_final_apprvr',rolefullname:'Plant Final Approver'},
  {role_shot_name:'rel_for_eval',rolefullname:'Release for Evaluation'},
  {role_shot_name:'rel_for_prod',rolefullname:'Release for Production'},
  {role_shot_name:'regnl_admin',rolefullname:'Regional Admin'},
  {role_shot_name:'reg_usr_admin',rolefullname:'Regional User Admin'},
  {role_shot_name:'reg_admin_supvsr',rolefullname:'Regional Admin Supervisor'},
  {role_shot_name:'reg_prg_admin',rolefullname:'Regional Program Admin'},
  {role_shot_name:'eqo_admin',rolefullname:'EQO Admin'},
  {role_shot_name:'bailment_supplier',rolefullname:'Bailment Supplier'},
  {role_shot_name:'plt_appvr_offsite',rolefullname:'Plant Approver - Bailment supplier'},
  {role_shot_name:'plt_final_apprvr_offsite',rolefullname:'Plant Final Approver - Bailment supplier'},
  {role_shot_name:'view_only',rolefullname:'View Only'},
  {role_shot_name:'concurent_user',rolefullname:'Concurrent User'},
  {role_shot_name:'reg_bom_upld',rolefullname:'Region Bom Upload'},
  {role_shot_name:'reg_news_authr',rolefullname:'Regional User Author'},
  {role_shot_name:'glbl_news_authr',rolefullname:'Global News Author'},
  {role_shot_name:'a_packaging',rolefullname:'Packaging Process'},
  {role_shot_name:'a_partplanning',rolefullname:'Part Planning'},
  {role_shot_name:'a_fedebom',rolefullname:'FEDEBOM View'},

  ]



  enableEditUserDets: boolean = false;

  constructor(private transactionService:TransactionService,private router:Router,private messageService:MessageService,private confirmationService:ConfirmationService,private date:DatePipe) { }

  ngOnInit(): void {


  this.cdsid=this.transactionService.getcdsid();
  this.currentRegion=this.transactionService.getregion();
  this.region_code=this.transactionService.getregioncode(this.currentRegion);


  this.userTypeList=[
    {userType:'all',UserDisplay:'[ALL]'},
    {userType:'Ford',UserDisplay:'Ford'},
    {userType:'Supplier',UserDisplay:'Supplier'}
  ]

  if(this.valueToBeModified==null)
  {
    this.valueToBeModified={plantcode:'',plantname:'',programcode:'',coscode:'',modify:false,calledFrom:''};;
  }



  this.selectedUserType=this.userTypeList[0];

 this.currentRouteid=52;

 console.log(this.currentRouteid)


 if(this.currentRegion=='Asia Pacific & Africa')
 {
   sessionStorage.setItem('aprouteid', JSON.stringify(this.currentRouteid));
 }

 else if(this.currentRegion=='Europe')
 {
   sessionStorage.setItem('eurouteid', JSON.stringify(this.currentRouteid));
 }

 else if (this.currentRegion=='North America')

 {

   sessionStorage.setItem('narouteid', JSON.stringify(this.currentRouteid));
 }

 else if(this.currentRegion=='South America')
 {
   sessionStorage.setItem('sarouteid', JSON.stringify(this.currentRouteid));
 }



 this.transactionService.getRolesThatCanBeGivenAccess(this.region_code).subscribe(rolelist=>{
  console.log(rolelist);
  this.roleListApi=[];
  this.roleList=[];
  this.roleListApi=rolelist;


  this.roleList.push({role_cd:0,
    role_id:-999,
    role_name:'all',
    cd_region:this.region_code,
    createddt:'',
    createdby:'',
    role_desc:'[Select a Role]'})

  for(const i of this.roleListforMapping)
  {
    const roleName=i.role_shot_name;
    const indRole=this.roleListApi.findIndex(el=>el.role_name.toLowerCase()==roleName.toLowerCase());
    if(indRole!=-1)
    {
      this.roleList.push({
        role_cd:this.roleListApi[indRole].role_cd,
        role_id:this.roleListApi[indRole].role_id,
        role_name:this.roleListApi[indRole].role_name,
        cd_region:this.roleListApi[indRole].cd_region,
        createddt:this.roleListApi[indRole].createddt,
        createdby:this.roleListApi[indRole].createdby,
        role_desc:i.rolefullname

      })
    }
  }


  console.log(this.roleList)


  this.selectedRoleList=this.roleList[0];
})






this.transactionService.getactiveplantslist(this.region_code).subscribe(access_allplants=>{


  console.log(access_allplants);
  this.plantList=[];
  this.plantList.push({cd_plant:'all',plantGSDBCode:'[No Filter]',plant_name:'[No Filter]',plant_type_name:'all'})

if(access_allplants.length!=0)
{
  this.plantList.push(...access_allplants);
}

  if(this.valueToBeModified.modify==true && this.valueToBeModified.calledFrom.toLowerCase()=='plantadmin' || this.valueToBeModified.calledFrom.toLowerCase()== 'programadmin')
  {
    this.applyFilter();
  }
  else
  {
    this.selectedPlantCode=this.plantList[0];
    this.selectedPlantName=this.plantList[0];
  }




})


this.transactionService.getOwnershipStrategyCode(this.region_code).subscribe(access_coslist=>{


  console.log(access_coslist);
  this.cosList=[];
  this.cosList.push({cd_ownrshp_strat:0,cd_region:this.region_code,ownrshp_strat_code:'all',ownrshp_desc:'[No filter]'})


  if(access_coslist.length!=0)
  {
    this.cosList.push(...access_coslist)
  }

  if(this.valueToBeModified.modify==true && this.valueToBeModified.calledFrom.toLowerCase()=='cosadmin')
  {
    this.applyFilter();
  }
  else
  {
    this.selectedCOS=this.cosList[0];
  }




})



    if(this.valueToBeModified.modify==true)
    {
      this.applyFilter()
    }





  }

  editAccessUserInit()
  {
    this.accessForEdit = 
  {
    "gppuserid": '',
    "user_name": '',
    "usrtype": '',
    "role_id": 0,
    "role_name": '',
    "cd_region": '',
    "plantgsdbcode": '',
    "plantname": '',
    "program_name":'',
    "cos": '',
    "lastlogin": '',
    "cd_supplier":'',
    "email":''
  }
  this.selectedsuppliers = [];
  this.selectedprogramForEdit = [];
}


  onChangePlantCode()
  {


    console.log(this.selectedPlantCode);
    this.selectedPlantName=this.selectedPlantCode;
    if(this.selectedPlantCode.plantGSDBCode!='[No Filter]')
    {
    this.getProgramCodes();
    this.enableProgram=true;
    }
    else
    {
      this.enableProgram=false;
    }

  }


  onChangePlantName()
  {


    console.log(this.selectedPlantCode);
    this.selectedPlantCode=this.selectedPlantName;
    if(this.selectedPlantCode.plant_name!='[No Filter]')
    {
    this.getProgramCodes();
    this.enableProgram=true;
    }
    else
    {
      this.enableProgram=false;
    }


  }


  getProgramCodes()
  {


    this.transactionService.getprogmlistbasedonplantgsdb(this.selectedPlantCode.plantGSDBCode,this.region_code).subscribe(prog_output=>{


      console.log(prog_output);
      this.programList=[];
      this.programList.push({cd_region:this.region_code,plantgsdbcode:this.selectedPlantCode.plantGSDBCode,programname:'[No Filter]'})
      if(prog_output.length!=0)
      {
        this.programList.push(...prog_output);
      }


      this.selectedProgram=this.programList[0];

      if(this.valueToBeModified.modify==true && this.valueToBeModified.calledFrom.toLowerCase()=='programadmin' && this.programList.length!=0)
      {
        this.program=this.valueToBeModified.programcode;
        const prgIndex=this.programList.findIndex(ele=>ele.programname.toLowerCase()==this.program.toLowerCase())
        if(prgIndex>=0)
        {
          this.selectedProgram=this.programList[prgIndex];
          this.enableProgram=false;
        }
        else
        {
          this.messageService.add({severity:'error',summary:'Info',detail:'No Programs Matched'})
        }
      }


    })

  }


  onChangeRole()
  {
    if(this.selectedRoleList.role_id==1 || this.selectedRoleList.role_id==2)
    {
      this.enableGSDBCode=true;
    }

    else
    {


      this.enableGSDBCode=false;
      this.selectedGSDBCode='';

    }
  }



  disableApplyFilter():boolean
  {

     console.log(this.selectedWslID);

    if((this.selectedWslID!=null && this.selectedWslID.trim()!='') || (this.selectedUserName!=null && this.selectedUserName.trim()!='') || (this.selectedUserType.userType!='' && this.selectedUserType.userType!='all')
    || (this.selectedRoleList.role_id!=0 && this.selectedRoleList.role_id!=-999) || (this.selectedGSDBCode!=null && this.selectedGSDBCode.trim()!='') || (this.selectedPlantCode.plantGSDBCode!='' && this.selectedPlantCode.plantGSDBCode!='[No Filter]')
   || (this.selectedPlantName.plant_name!='' && this.selectedPlantName.plant_name!='[No Filter]') || (this.selectedProgram.programname!='' && this.selectedProgram.programname!='[No Filter]') || (this.selectedCOS.ownrshp_strat_code!='' && this.selectedCOS.ownrshp_strat_code!='all'))


    return false;

    else
    {
      return true;
    }

  }



  applyFilter()
  {
    console.log(this.selectedUserName);


    if(this.selectedUserName!=null && this.selectedUserName!='')
    {
      this.userName=this.selectedUserName;
    }
    else
    {
      this.userName='all';
    }


    console.log(this.selectedWslID);


    if(this.selectedWslID!=null && this.selectedWslID!='')
    {
      this.wslID=this.selectedWslID;
    }
    else
    {
      this.wslID='all';
    }


    console.log(this.selectedGSDBCode);

if(this.enableGSDBCode==true)
{
    if(this.selectedGSDBCode!=null && this.selectedGSDBCode!='')
    {
      this.gsdbCode=this.selectedGSDBCode;
    }
    else
    {
      this.gsdbCode='all';
    }
  }

  else
  {
    this.gsdbCode='all';
  }


    console.log(this.selectedUserType);

    this.userType=this.selectedUserType.userType;

    console.log(this.selectedRoleList.role_id)

// role id is converted to string and given as input
// if role shot name(as in db) should be given as input give role_name

    if(this.selectedRoleList.role_id!=0)
    {
      if(this.selectedRoleList.role_id!=-999)
      {
        this.role=this.selectedRoleList.role_id.toString();
      }


      else
      {
        this.role='all';
      }
    }


    else
    {
      this.role='all';
    }


    console.log(this.selectedPlantCode);

    if(this.selectedPlantCode.plantGSDBCode!='')
    {


    if(this.selectedPlantCode.plantGSDBCode!='[No Filter]')
    {
      this.plantcode=this.selectedPlantCode.plantGSDBCode;

    }
    else
    {
      this.plantcode='all'
    }
  }

  else
  {
    this.plantcode='all'
  }



  console.log(this.selectedPlantName);


  if(this.selectedPlantName.plant_name!='')
  {
    if(this.selectedPlantName.plant_name!='[No Filter]')
    {
      this.plantname=this.selectedPlantName.plant_name
    }

    else
    {
      this.plantname='all'
    }
  }


  else
  {
    this.plantname='all'
  }


    console.log(this.selectedProgram)


    if(this.selectedProgram.programname!='')
    {


    if(this.selectedProgram.programname!='[No Filter]')
    {
      this.program=this.selectedProgram.programname
    }
    else
    {
      this.program='all'
    }
  }
  else
  {
    this.program='all'
  }


  console.log(this.selectedCOS);


  if(this.selectedCOS.ownrshp_strat_code!='')
  {
    this.cos=this.selectedCOS.ownrshp_strat_code;
  }


  else
  {
    this.cos='all'
  }


  console.log(this.userName,this.wslID,this.userType,this.role,this.gsdbCode,this.plantcode,this.plantname,this.program,this.cos);


    if(this.valueToBeModified.modify==true)
    {
      if(this.valueToBeModified.calledFrom.toLowerCase()=='plantadmin' || this.valueToBeModified.calledFrom.toLowerCase()=='programadmin' && this.plantList.length!=0)
      {
        this.plantcode=this.valueToBeModified.plantcode;


        const plntIndex=this.plantList.findIndex(e=>e.plantGSDBCode.trim()==this.plantcode.trim())
        if(plntIndex>=0)
        {
          this.selectedPlantCode=this.plantList[plntIndex];
          this.selectedPlantName=this.plantList[plntIndex];
          this.plantname=this.selectedPlantName.plant_name
          this.disablePlantCode=true;
          this.disablePlantName=true;
          this.enableProgram=true;
          this.getProgramCodes();
          this.populateGridForEditAccess();
        }
        else
        {
          this.messageService.add({severity:'error',summary:'Info',detail:'No Plants Matched'})
        }
      }
      else if(this.valueToBeModified.calledFrom.toLowerCase()=='cosadmin' && this.cosList.length!=0 )
      {
        this.cos=this.valueToBeModified.coscode;
        const cosIndex=this.cosList.findIndex(ele=>ele.ownrshp_strat_code.toLowerCase()==this.cos.toLowerCase())
        if(cosIndex>=0)
        {
          this.selectedCOS=this.cosList[cosIndex];
          this.disableCos=true;
          this.populateGridForEditAccess();
        }
      }

    }
    else
    {
      this.populateGridForEditAccess();
    }

  }

  populateGridForEditAccess()
  {
    this.transactionService.getEditUserAccessTable(this.wslID,this.userName,this.userType,this.role,this.gsdbCode,this.plantcode,this.plantname,this.program,this.cos,this.region_code).subscribe(gridoutput=>{
      this.editAcessGrid=gridoutput;

      if(this.editAcessGrid.length==0)
      {
        this.messageService.add({severity:'error',summary:'Info',detail:'No Data Found'})
      }

      // write code to map role short name to role full name and add supplier gsdb code field

      for(let accessIter=0;accessIter<this.editAcessGrid.length;accessIter++)
      {
        const ind=this.roleListforMapping.findIndex(elemnt=>elemnt.role_shot_name.toLowerCase()==this.editAcessGrid[accessIter].role_name.toLowerCase())

        if(ind>-1)
        {
         this.editAcessGrid[accessIter].role_name=this.roleListforMapping[ind].rolefullname;
        }


      }

      this.cols=[
        {field:'gppuserid',header:'GPP User ID'},
        {field:'user_name',header:'User Name'},
        {field:'usrtype',header:'User Type'},
        {field:"email",header:"Email"},
        {field:'role_name',header:'Role'},
        // {field:"cd_region",header:"Region"},
        {field:'plantgsdbcode',header:'Plant GSDB Code'},
        {field:'plantname',header:'Plant Name'},
        {field:'program_name',header:'Program Name'},
        {field:'cd_supplier',header:'GSDB Code'},
        {field:'cos',header:'COS'},
        {field:'lastlogin',header:'Last Login'}
      ]
    })

  }

  exportExcel() {

    console.log(this.editAcessGrid);

    var editAccessGridExcelFormat:any[]=[];

    this.editAcessGrid.forEach(e=>{
      editAccessGridExcelFormat.push({
        "gppuserid": e.gppuserid,
        "user_name": e.user_name,
        "usrtype": e.usrtype,
        "role_name": e.role_name,
        "plantgsdbcode": e.plantgsdbcode,
        "plantname": e.plantname,
        "program_name":e.program_name,
        "cos": e.cos,
        "lastlogin": e.lastlogin,
        "cd_supplier":e.cd_supplier,
        "email":e.email
      })
    })
  
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(editAccessGridExcelFormat,{header:[
        'gppuserid',
        'user_name',
        'usrtype',
        'email',
        'role_name',
        'plantgsdbcode',
        'plantname',
        'program_name',
        'cd_supplier',
        'cos',
        'lastlogin'
      ]});
  
      worksheet.A1.v="GPPUserID";
      worksheet.B1.v="User Name";
      worksheet.C1.v="User Type";
      worksheet.D1.v="Email";
      worksheet.E1.v="Role";
      worksheet.F1.v="Plant GSDB Code";
      worksheet.G1.v="Plant Name";
      worksheet.H1.v="Program Name";
      worksheet.I1.v="GSDB Code";
      worksheet.J1.v="COS";
      worksheet.K1.v="Last Login";

      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "Access List");
    });
}

saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
}



  deleteUserAccess(userAccessToDelete:editAccessGridFormat)
  {
    this.accessForDelete=Object.assign({},userAccessToDelete);

    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this access ' + userAccessToDelete.role_name +' for '+userAccessToDelete.user_name+ '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () =>
      {
        this.transactionService.deleteAlreadyAvailableAccess(this.accessForDelete.gppuserid,this.accessForDelete.role_id,this.accessForDelete.cd_region,this.accessForDelete.plantgsdbcode).subscribe(delete_status=>{
          if(delete_status.toLowerCase()=='success')
          {
            this.messageService.add({severity:'success',summary:'Success',detail:'Access Deleted Successfully'});
            this.populateGridForEditAccess();
          }
          else
          {
            this.messageService.add({severity:'error',summary:'Error',detail:'Access Not Deleted, Try Again !!'})
          }
        })
      }
    });
  }

  editUserAccess(userAccessToEdit: editAccessGridFormat)
  {
    console.log("Edit:Access roloes");
    this.accessForEdit=Object.assign({},userAccessToEdit);

    console.log("Edit user details", this.accessForEdit);

    if(this.accessForEdit.role_name == "Supplier" || this.selectedRoleList.role_id == 1)
    {
    this.enableEditUserDets = true;
    this.supplierList = [];
   let supplierListofStr =  this.accessForEdit.cd_supplier.split(',');
   supplierListofStr.forEach((ele) => {
     let obj = { 'suppliercode': ele}
     this.supplierList.push(obj);
   });

   this.programListEdit = [];
   let programListofStr = this.accessForEdit.program_name.split(',');
   programListofStr.forEach((value) => {
    let progobj = { 'program_name': value}
    this.programListEdit.push(progobj);
  });
  
    this.supplierListUnchanged = this.supplierList;
    this.programListUnchanged = this.programListEdit;
    console.log("Supplier Details", this.supplierList);
    console.log("Program Details", this.programListEdit);
   }
   else
   {
     this.enableEditUserDets = false;
     this.messageService.add({severity:'error', summary: 'Error', detail: 'Access is Non-editable'});

   }
  }

  onHideSuppCodes()
  {

   
    this.all_selected_on_supplier_code=false;
    this.suppliercode="";

    /* checking whether length of selected list is less than one */
    var supplen:boolean=false;
    if(this.selectedsuppliers.length == this.supplierList.length-1)
    {
      supplen=true;
    }
    for(var ptc=0;ptc<this.selectedsuppliers.length;ptc++)
    {
      this.suppliercode+=this.selectedsuppliers[ptc].suppliercode;
      if(ptc < this.selectedsuppliers.length-1)
      {
        this.suppliercode+=",";
      }
      if(this.selectedsuppliers[ptc].suppliercode.toLowerCase() == 'all')
      {
        this.all_selected_on_supplier_code=true;
        this.suppliercode="all";
        break;
      }
    }
    /* If selectedvariables length is one less than total length but all is not selected
       which means except all remaining are selected so make
       all_selected_on_supplier_code as true and make code  send all to backend */

    if(this.all_selected_on_supplier_code == false && supplen == true)
    {
      this.suppliercode="all";
      this.all_selected_on_supplier_code=true;
    }
    console.log("supplier codes in Hide",this.suppliercode,this.selectedsuppliers);
    const supplierCodesToRemove = this.selectedsuppliers.map(obj => obj.suppliercode);
    // Filter the original array to remove specific items
    this.supplierList = this.supplierList.filter(obj => !supplierCodesToRemove.includes(obj.suppliercode));
    console.log("supplierList", this.supplierList);
  
  }

 
  // onHideProgNames()
  // {
    
   
  //   this.all_selected_programs=false;
  //   this.programname="";
  //   /* checking whether length of selected list is less than one */
  //   var proglen:boolean=false;
  //   if(this.selectedprogramForEdit.length == this.programListEdit.length-1)
  //   {
  //     proglen=true;
  //   }
  //   for(var ptc=0;ptc<this.selectedprogramForEdit.length;ptc++)
  //   {
  //     this.programname+=this.selectedprogramForEdit[ptc].program_name;
  //     if(ptc < this.selectedprogramForEdit.length-1)
  //     {
  //       this.programname+=",";
  //     }
  //     if(this.selectedprogramForEdit[ptc].program_name.toLowerCase() == 'all')
  //     {
  //       this.all_selected_programs=true;
  //       this.programname="all";
  //       break;
  //     }
  //   }
  //   /* If selectedvariables length is one less than total length but all is not selected
  //      which means except all remaining are selected so make
  //      all_selected_programs as true and make code  send all to backend */

  //   if(this.all_selected_programs == false && proglen == true)
  //   {
  //     this.programname="all";
  //     this.all_selected_programs=true;
  //   }
  //   console.log("Programs in Hide",this.programname,this.programListEdit,this.selectedprogramForEdit);
  //   const supplierCodesToRemove = this.selectedprogramForEdit.map(obj => obj.program_name);
  //   // Filter the original array to remove specific items
  //   this.programListEdit = this.programListEdit.filter(obj => !supplierCodesToRemove.includes(obj.program_name));
  //   console.log("programList", this.programListEdit);
  
  // }

  removeSuppliers()
  {
    if(this.enableEditUserDets = true)
    {

    this.onHideSuppCodes();
    // this.onHideProgNames();
    
    this.supplierCodeToAPI = [];
    this.supplierCodeToAPI = this.supplierList.map(obj => obj.suppliercode).join(',');

    // let programListForAPI = this.programListEdit.map(obj => obj.program_name).join(',');
    console.log("Dets for delete",  this.supplierCodeToAPI);
    this.accessForEditToAPI={
      "cd_program":101,
      "cd_region":this.accessForEdit.cd_region,
      "cd_supplier":this.supplierCodeToAPI,
      "createdby":this.cdsid,
      "createddt":this.date.transform(new Date(),"yyyy-MM-dd HH:mm:ss"),
      "gppuserid":this.accessForEdit.gppuserid,
      "ownershp_strat_code":this.accessForEdit.cos,
      "plant_name":this.accessForEdit.plantname,
     "plantgsdbcode":this.accessForEdit.plantgsdbcode,
     "program_name":this.accessForEdit.program_name,
    //  "program_name":programListForAPI,
      //  "createddt":created_date,
      "role_id":this.accessForEdit.role_id
    }
    console.log("Edit details For API", this.editSuppProgAccesDets);

    const JsonObjectForAPI = JSON.stringify(this.editSuppProgAccesDets);
    console.log("Data for API", JSON.stringify(this.accessForEditToAPI), typeof(this.accessForEditToAPI));

    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this access ?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () =>
      {
        this.transactionService.removeProgramSupplierAccessDets(this.accessForEditToAPI).subscribe(removalstatus=>{
        
            if(removalstatus.toLowerCase() == "success")
            {
              this.messageService.add({severity:'success', summary: 'success', detail: 'Supplier access updated successfully'});
             this.closeEditUserPopUp();
             this.applyFilter();
            
            }
            else
            {
              this.messageService.add({severity:'error', summary: 'Error', detail: 'Supplier access not updated'});
            }
          })
      },
      reject: () =>
      {
        this.supplierList = this.supplierListUnchanged;
        this.programListEdit = this.programListUnchanged;
        console.log("Unchanged", this.supplierListUnchanged, this.supplierList);
      }
    });

  }
  }
  closeEditUserPopUp()
  {
    this.editAccessUserInit();
    this.selectedsuppliers = [];
    this.selectedprogramForEdit = [];
    this.programListEdit =[];
    this.supplierList = [];
    this.supplierCodeToAPI = [];
    console.log("Close", this.accessForEdit)
    this.enableEditUserDets = false;
  }

  


  getRequestdetails(reqDetails:editAccessGridFormat)
  {
    this.transactionService.user_details(reqDetails.gppuserid).subscribe(userdetailsinfo=>{
      this.userDetails=userdetailsinfo;
      this.userInfoPopUp=true;
    })
  }






}
