/* tslint:disable */
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Console } from 'console';
import { ConfirmationService, MessageService } from 'primeng';
import { TransactionService } from '../loginpage/Transaction.service';
import { ActivePlants, containerOwnershipList, editAccessGridFormat, getUserType, myUserAccessForGrid, myUserAccessRoles, programsbasedonplants, rolesDetails, rolesDetailsMappingEditAccess, userdetails } from '../loginpage/transactions';

@Component({
  selector: 'app-myuseraccess',
  templateUrl: './myuseraccess.component.html',
  styleUrls: ['./myuseraccess.component.css'],
  providers: [ConfirmationService,MessageService,]
})
export class MyuseraccessComponent implements OnInit {

  cdsid='';
  currentRegion='';
  region_code='';
  currentRouteid=0;

  plantList:ActivePlants[]=[];
  programList:programsbasedonplants[]=[];
  cosList:containerOwnershipList[]=[];
  userTypeList:getUserType[]=[];
  roleList:rolesDetailsMappingEditAccess[]=[];
  roleListApi:rolesDetails[]=[];


  selectedWslID='';
  selectedUserName='';
  selectedGSDBCode='';
  selectedUserType:getUserType={userType:'',UserDisplay:''};
  selectedRoleList:rolesDetailsMappingEditAccess={cd_region:'',createdby:'',createddt:'',role_cd:0,role_id:0,role_desc:'',role_name:''};
  selectedCOS:containerOwnershipList={cd_region:'',cd_ownrshp_strat:0,ownrshp_strat_code:'',ownrshp_desc:''};
  selectedPlantCode:ActivePlants={cd_plant:'',plantGSDBCode:'',plant_name:'',plant_type_name:''};
  selectedPlantName:ActivePlants={cd_plant:'',plantGSDBCode:'',plant_name:'',plant_type_name:''};
  selectedProgram:programsbasedonplants={cd_region:'',plantgsdbcode:'',programname:''};

  wslID='';
  userName='';
  regionaccess='';
  gsdbCode='';
  userType='';
  role='';
  cos='';
  plantcode='';
  plantname='';
  program='';
  role_name='';

  enableProgram=false;
  enableGSDBCode=false;

  disablePlantCode=false;
  disablePlantName=false;
  disableCos=false;

  testingarray:editAccessGridFormat []= [];

  userAccessGrid:myUserAccessRoles[]=[];
  userAccessGridWithRoles: myUserAccessForGrid[]=[];
  temp: myUserAccessForGrid[]=[];
  // userRegionList:myUserAccessRoles={
  //   gppuserid: '',
	// 		role_id: 0,
	// 		cd_region:'',
	// 		plantgsdbcode: '',
	// 		cd_supplier: '',
	// 		cd_program: 0,
	// 		ownershp_strat_code: '',
	// 		plant_name: '',
	// 		program_name: '',
	// 		createddt: '',
	// 		createdby: '',
  //     name: '',
  //     usrtype: '',
	// 		lastlogin: ''
  // };
  editAcessGrid:editAccessGridFormat[]=[];

  public cols:any=[];
  userInfoPopUp=false;
  userDetails:userdetails={
        gppuserid: '',
        title: '',
        name: '',
        company: '',
        designation: '',
        email: '',
        isforduser: '',
        isactive: '',
        createddt: '',
        createdby: '',
        phone: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        postalcode: '',
        cntrycodeiso: '',
        lastlogin: '',
        fax: '',
        country: '',
        usertype: ''
  }


  roleListforMapping=[
    {role_id:1,role_shot_name:'Supplier',rolefullname:'Supplier'},
  {role_id:2,role_shot_name:'design_src',rolefullname:'Design Source'},
  {role_id:3,role_shot_name:'prelimnry_appr',rolefullname:'Preliminary Approver'},
  {role_id:4,role_shot_name:'addntl_appvr',rolefullname:'Additional Approver'},
  {role_id:5,role_shot_name:'plt_appvr',rolefullname:'Plant Approver'},
  {role_id:6,role_shot_name:'plt_final_apprvr',rolefullname:'Plant Final Approver'},
  {role_id:7,role_shot_name:'concurent_user',rolefullname:'Concurrent User'},
  {role_id:8,role_shot_name:'reg_bom_upld',rolefullname:'Region Bom Upload'},
  {role_id:9,role_shot_name:'rel_for_eval',rolefullname:'Release for Evaluation'},
  {role_id:11,role_shot_name:'rel_for_prod',rolefullname:'Release for Production'},
  {role_id:12,role_shot_name:'regnl_admin',rolefullname:'Regional Admin'},
  {role_id:13,role_shot_name:'reg_usr_admin',rolefullname:'Regional User Admin'},
  {role_id:14,role_shot_name:'reg_admin_supvsr',rolefullname:'Regional Admin Supervisor'},
  {role_id:15,role_shot_name:'reg_news_authr',rolefullname:'Regional User Author'},
  {role_id:16,role_shot_name:'glbl_news_authr',rolefullname:'Global News Author'},
  {role_id:17,role_shot_name:'reg_prg_admin',rolefullname:'Regional Program Admin'},
  {role_id:18,role_shot_name:'bailment_supplier',rolefullname:'Bailment Supplier'},
  {role_id:21,role_shot_name:'eqo_admin',rolefullname:'EQO Admin'},
  {role_id:99,role_shot_name:'view_only',rolefullname:'View Only'},
  {role_id:101,role_shot_name:'a_packaging',rolefullname:'Packaging Process'},
  {role_id:102,role_shot_name:'a_partplanning',rolefullname:'Part Planning'},
  {role_id:103,role_shot_name:'a_fedebom',rolefullname:'FEDEBOM View'}
]


  // @Input('app-myuseraccess') valueToBeModified:any;
  constructor(private transactionService:TransactionService,private router:Router,private messageService:MessageService,private confirmationService:ConfirmationService) { }

  ngOnInit(): void {


  this.cdsid=this.transactionService.getcdsid();
  this.currentRegion=this.transactionService.getregion();
  this.region_code=this.transactionService.getregioncode(this.currentRegion);


  console.log('Region code1',this.region_code);

  this.populateGridForAccess();


  // this.selectedUserType=this.userTypeList[0];







//  this.transactionService.getRolesThatCanBeGivenAccess(this.region_code).subscribe(rolelist=>{
//   console.log(rolelist);
//   this.roleListApi=[];
//   this.roleList=[];
//   this.roleListApi=rolelist;


//   this.roleList.push({role_cd:0,
//     role_id:-999,
//     role_name:"all",
//     cd_region:this.region_code,
//     createddt:"",
//     createdby:"",
//     role_desc:"[Select a Role]"})

//   for(var i of this.roleListforMapping)
//   {
//     var roleName=i.role_shot_name;
//     var indRole=this.roleListApi.findIndex(el=>el.role_name.toLowerCase()==roleName.toLowerCase());
//     if(indRole!=-1)
//     {
//       this.roleList.push({
//         role_cd:this.roleListApi[indRole].role_cd,
//         role_id:this.roleListApi[indRole].role_id,
//         role_name:this.roleListApi[indRole].role_name,
//         cd_region:this.roleListApi[indRole].cd_region,
//         createddt:this.roleListApi[indRole].createddt,
//         createdby:this.roleListApi[indRole].createdby,
//         role_desc:i.rolefullname

//       })
//     }
//   }


//   console.log(this.roleList)


//   this.selectedRoleList=this.roleList[0];
// })


  }

  populateGridForAccess()
  {

    console.log('before calling');

  this.transactionService.getMyUserAccessRoles(this.cdsid).subscribe(gridoutput =>{

    this.userAccessGridWithRoles=[];
    this.userAccessGrid=[];
       this.userAccessGrid=gridoutput;

      //  this.userAccessGridWithRoles = gridoutput;
      // console.log("EditaccessPrathyusha",this.userAccessGrid);
      console.log('EditaccessPrathyusha',gridoutput);

      for(let accessIter=0;accessIter<this.userAccessGrid.length;accessIter++)
      {
        let ind=this.roleListforMapping.findIndex(elemnt=>elemnt.role_id==this.userAccessGrid[accessIter].role_id)

        if(ind>-1)
        {
         this.role_name=this.roleListforMapping[ind].rolefullname;
         this.userAccessGridWithRoles.push({
          gppuserid: this.userAccessGrid[accessIter].gppuserid,
			role_id: this.userAccessGrid[accessIter].role_id,
			role_name:this.role_name,
			cd_region:this.userAccessGrid[accessIter].cd_region,
			plantgsdbcode: this.userAccessGrid[accessIter].plantgsdbcode,
			cd_supplier: this.userAccessGrid[accessIter].cd_supplier,
			cd_program: this.userAccessGrid[accessIter].cd_program,
			ownershp_strat_code: this.userAccessGrid[accessIter].ownershp_strat_code,
			plant_name: this.userAccessGrid[accessIter].plant_name,
			program_name: this.userAccessGrid[accessIter].program_name,
			createddt: this.userAccessGrid[accessIter].createddt,
			createdby: this.userAccessGrid[accessIter].createdby,
      name:this.userAccessGrid[accessIter].name,
      usrtype: this.userAccessGrid[accessIter].usrtype,
			lastlogin: this.userAccessGrid[accessIter].lastlogin
         })
        }


      }

      this.cols=[
        {field:'gppuserid',header:'ID'},
        {field:'name',header:'User Name'},
        {field:'usrtype',header:'User Type'},
        {field:'cd_region',header:'Region'},
        {field:'role_name',header:'Role'},
        {field:'plantgsdbcode',header:'Plant GSDB Code'},
        {field:'plant_name',header:'Plant Name'},
        {field:'program_name',header:'Program Name'},
        {field:'cd_supplier',header:'Supplier GSDB Code'},
        {field:'ownershp_strat_code',header:'COS'}
      ]
})


  }


  onBackOrCloseMyAccess()
  {
    // console.log("inside close function");

    let cur_region=this.transactionService.getregion();
    if(cur_region != null )
    {
      let reg_map_url='';
      reg_map_url=this.transactionService.getregionurlvalue(cur_region);
      reg_map_url+='packagingprocessdashboard/packagingprocesshome';

      this.router.navigate([reg_map_url]);

    }

    else
    {
      this.router.navigate(['/exitpage']);
    }
  }
}
