/* tslint:disable */
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutes } from './app.routes';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, HashLocationStrategy, DatePipe } from '@angular/common';

import { AppComponent } from './app.component';
import { AppTopBarComponent } from './app.topbar.component';
import { AppSideBarComponent } from './app.sidebar.component';
import { AppEditorComponent } from './app.editor.component';
import {ScrollPanelModule} from 'primeng/scrollpanel';

/*
import { SampleDemoComponent } from './demo/view/sampledemo.component';
import { FormsDemoComponent } from './demo/view/formsdemo.component';
import { DataDemoComponent } from './demo/view/datademo.component';
import { PanelsDemoComponent } from './demo/view/panelsdemo.component';
import { OverlaysDemoComponent } from './demo/view/overlaysdemo.component';
import { MenusDemoComponent } from './demo/view/menusdemo.component';
import { MessagesDemoComponent } from './demo/view/messagesdemo.component';
import { ChartsDemoComponent } from './demo/view/chartsdemo.component';
import { FileDemoComponent } from './demo/view/filedemo.component';
import { MiscDemoComponent } from './demo/view/miscdemo.component';
import { DocumentationComponent } from './demo/view/documentation.component';
*/

import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { FullCalendarModule } from 'primeng/fullcalendar';
import { GalleriaModule } from 'primeng/galleria';
import { InplaceModule } from 'primeng/inplace';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { LightboxModule } from 'primeng/lightbox';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SpinnerModule } from 'primeng/spinner';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TerminalModule } from 'primeng/terminal';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
// import {FieldsetModule} from 'primeng/fieldset';

// import { CarService } from './demo/service/carservice';
// import { CountryService } from './demo/service/countryservice';
// import { EventService } from './demo/service/eventservice';
// import { NodeService } from './demo/service/nodeservice';


import {RouterModule, RouterStateSnapshot} from '@angular/router';

import {NavComponent} from './nav/nav.component';

import {SidebarModule} from 'primeng/sidebar';

import {ExamplePageComponent} from './example-page/example-page.component';

import {NotFoundComponent} from './not-found/not-found.component';
import {MessageService} from 'primeng/api';

import { LoginpageComponent } from './loginpage/loginpage.component';
// import { AuthService } from './services/auth.service';

// import {ConfirmationService} from 'primeng/api';


import { ScrollingModule } from '@angular/cdk/scrolling';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
// import { NgxSpinnerModule } from "ngx-spinner";



import { HomeComponent } from './home/home.component';
import { OAuthCallbackComponent} from './oauth/oauth-callback.component';

import {LoginComponent} from './oauth/login.component';
import {OauthModule} from './oauth/oauth.module';
import {HomeModule} from './home/home.module';
import { ExitpageComponent } from './exitpage/exitpage.component';
import { DashboardpageComponent } from './dashboardpage/dashboardpage.component';




// import { FleetComponent } from './Fleet/Fleet.component';

import { NotaccesspageComponent } from './notaccesspage/notaccesspage.component';
import { ExamplePageResolve } from './example-page/example-page.resolve';
import { NorthamericaComponent } from './northamerica/northamerica.component';
import { SouthamericaComponent } from './southamerica/southamerica.component';
import { EuropeComponent } from './europe/europe.component';
import { AsiapacificComponent } from './asiapacific/asiapacific.component';
import { PackplantadminComponent } from './packplantadmin/packplantadmin.component';
import { SouthamericasidebarComponent } from './southamericasidebar/southamericasidebar.component';
import { EuropesidebarComponent } from './europesidebar/europesidebar.component';
import { AsiapacificsidebarComponent } from './asiapacificsidebar/asiapacificsidebar.component';
import { PackadminComponent } from './packadmin/packadmin.component';



import { PrgadminComponent } from './prgadmin/prgadmin.component';
import { CosadminComponent } from './cosadmin/cosadmin.component';

import {ContadminComponent} from './contadmin/contadmin.component';
import { TestpageComponent } from './testpage/testpage.component';
import { RegionadminComponent } from './regionadmin/regionadmin.component';
import { SubmiterdashboardComponent } from './submiterdashboard/submiterdashboard.component';
import { PacksupplierComponent } from './packsupplier/packsupplier.component';
import { PackapproverComponent } from './packapprover/packapprover.component';
import { PackagingprocessComponent } from './packagingprocess/packagingprocess.component';
import { PacksupplieradminComponent } from './packsupplieradmin/packsupplieradmin.component';
import { PackagingprocessdashboardComponent } from './packagingprocessdashboard/packagingprocessdashboard.component';
import {  PackagingproposalComponent } from './packagingproposal/packagingproposal.component';

import { PackproposaltabtwoComponent } from './packproposaltabtwo/packproposaltabtwo.component';
import { PackproposaltabthreeComponent } from './packproposaltabthree/packproposaltabthree.component';
import { PackproposaltabfourComponent } from './packproposaltabfour/packproposaltabfour.component';
import { PartsandatributesComponent } from './partsandatributes/partsandatributes.component';
import { PackdesignsourceadminComponent } from './packdesignsourceadmin/packdesignsourceadmin.component';
import { PackdesignsourceComponent } from './packdesignsource/packdesignsource.component';
import { FormtemplateComponent } from './formtemplate/formtemplate.component';
import { FormnewComponent } from './formnew/formnew.component';


import { ComponentadminComponent } from './componentadmin/componentadmin.component';
import { ApprovaladminComponent } from './approvaladmin/approvaladmin.component';
import { PreliminaryappComponent } from './preliminaryapp/preliminaryapp.component';
import { AdditionalappComponent } from './additionalapp/additionalapp.component';
import { PlantappComponent } from './plantapp/plantapp.component';
import { PlantfinalappComponent } from './plantfinalapp/plantfinalapp.component';
import { ReleasefrevalappComponent } from './releasefrevalapp/releasefrevalapp.component';
import { ReleasefrprodappComponent } from './releasefrprodapp/releasefrprodapp.component';
import { ApprovaltemplateComponent } from './approvaltemplate/approvaltemplate.component';


import { SearchexistingproposalComponent } from './searchexistingproposal/searchexistingproposal.component';
import { PackagingprocesshomeComponent } from './packagingprocesshome/packagingprocesshome.component';
import { EepartsandatributesComponent } from './eepartsandatributes/eepartsandatributes.component';
import { EepackproposaltabtwoComponent } from './eepackproposaltabtwo/eepackproposaltabtwo.component';
import { EepackproposaltabthreeComponent } from './eepackproposaltabthree/eepackproposaltabthree.component';
import { EepackproposaltabfourComponent } from './eepackproposaltabfour/eepackproposaltabfour.component';
import { RequestuseraccessComponent } from './requestuseraccess/requestuseraccess.component';


import { UseradminComponent } from './useradmin/useradmin.component';
import { PendingaccessrequestComponent } from './pendingaccessrequest/pendingaccessrequest.component';
import { EdituserprofileComponent } from './edituserprofile/edituserprofile.component';
import { EditaccessComponent } from './editaccess/editaccess.component';
import { AdditionalrequestaccessComponent } from './additionalrequestaccess/additionalrequestaccess.component';
import { EditsupplierprofileComponent } from './editsupplierprofile/editsupplierprofile.component';
import { EqoconfigComponent } from './eqoconfig/eqoconfig.component';
import { MaterialmanagementComponent } from './materialmanagement/materialmanagement.component';
import { OtherconfigComponent } from './otherconfig/otherconfig.component';



import { PlantappoffsiteComponent } from './plantappoffsite/plantappoffsite.component';
import { PlantfinalappoffsiteComponent } from './plantfinalappoffsite/plantfinalappoffsite.component';
import { UnsavedchangesGuard } from './oauth/unsavedchanges.guard';
import { ImprovementtaboneComponent } from './improvementtabone/improvementtabone.component';
import { ImprovementtabtwoComponent } from './improvementtabtwo/improvementtabtwo.component';
import { ImprovementtabthreeComponent } from './improvementtabthree/improvementtabthree.component';
import { ImprovementtabfourComponent } from './improvementtabfour/improvementtabfour.component';


import { ImprovementeetaboneComponent } from './improvementeetabone/improvementeetabone.component';
import { ImprovementeetabtwoComponent } from './improvementeetabtwo/improvementeetabtwo.component';
import { ImprovementeetabthreeComponent } from './improvementeetabthree/improvementeetabthree.component';
import { ImprovementeetabfourComponent } from './improvementeetabfour/improvementeetabfour.component';
import { ImprovementpackagingproposalComponent } from './improvementpackagingproposal/improvementpackagingproposal.component';



import { MyuseraccessComponent } from './myuseraccess/myuseraccess.component';
import { DbiareqmgntComponent } from './dbiareqmgnt/dbiareqmgnt.component';
import { DbiareqstsComponent } from './dbiareqsts/dbiareqsts.component';
import { DbiaadminComponent } from './dbiaadmin/dbiaadmin.component';
import { ContainerrequestComponent } from './containerrequest/containerrequest.component';
import { LoginotheruserComponent } from './loginotheruser/loginotheruser.component';
import { ContainerreqstsComponent } from './containerreqsts/containerreqsts.component';
import { MigrationsubmitterdashboardComponent } from './migrationsubmitterdashboard/migrationsubmitterdashboard.component';
import { MigrationapprovalComponent } from './migrationapproval/migrationapproval.component';

import { MypendingaccessComponent } from './mypendingaccess/mypendingaccess.component';
import { AppfootercomponentComponent } from './appfootercomponent/appfootercomponent.component';
import { HelpcomponentComponent } from './helpcomponent/helpcomponent.component';
import { PackaginglinkscomponentComponent } from './packaginglinkscomponent/packaginglinkscomponent.component';

import { InactiveuserpageComponent } from './inactiveuserpage/inactiveuserpage.component';
import { SearchPartsComponent } from './search-parts/search-parts.component';
import { ReportsComponent } from './reports/reports.component';
import { PfepdataexportreportComponent } from './pfepdataexportreport/pfepdataexportreport.component';
import { ReportsadminComponent } from './reportsadmin/reportsadmin.component';
import { PackagingsummaryreportComponent } from './packagingsummaryreport/packagingsummaryreport.component';
import { AlladditionalapprovalComponent } from './alladditionalapproval/alladditionalapproval.component';
import { Form1271datareportComponent } from './form1271datareport/form1271datareport.component';
import { GpparchivesearchpartComponent } from './gpparchivesearchpart/gpparchivesearchpart.component';
import { LomreportComponent } from './lomreport/lomreport.component';
import { GpparchivereportsadminComponent } from './gpparchivereportsadmin/gpparchivereportsadmin.component';
import { GpparchivereportsComponent } from './gpparchivereports/gpparchivereports.component';
import { GpparchivepackagingsummaryreportComponent } from './gpparchivepackagingsummaryreport/gpparchivepackagingsummaryreport.component';
import { GpparchivelomreportComponent } from './gpparchivelomreport/gpparchivelomreport.component';
import { Gpparchiveform1271reportComponent } from './gpparchiveform1271report/gpparchiveform1271report.component';
import { GpparchivepfepdataexportreportComponent } from './gpparchivepfepdataexportreport/gpparchivepfepdataexportreport.component';
import { SuppliercontactreportComponent } from './suppliercontactreport/suppliercontactreport.component';
import { ProgramlevelemailComponent } from './programlevelemail/programlevelemail.component';
import { TraininglinksComponent } from './traininglinks/traininglinks.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { GpparchivesuppliercontactreportComponent } from './gpparchivesuppliercontactreport/gpparchivesuppliercontactreport.component';
import { NgppInterceptorService } from './oauth/interceptors';


@NgModule({
    declarations: [
        AppComponent,
        AppTopBarComponent,
        AppEditorComponent,
        AppSideBarComponent,
    /*    SampleDemoComponent,
        FormsDemoComponent,
        DataDemoComponent,
        PanelsDemoComponent,
        OverlaysDemoComponent,
        MenusDemoComponent,
        MessagesDemoComponent,
        ChartsDemoComponent,
        FileDemoComponent,
        MiscDemoComponent,
        DocumentationComponent,*/
        NavComponent,
		ExamplePageComponent,
		NotFoundComponent,
		LoginpageComponent,
       ExitpageComponent,
	   DashboardpageComponent,
	   NotaccesspageComponent,
	   NorthamericaComponent,
	   SouthamericaComponent,
	   EuropeComponent,
	   AsiapacificComponent,
	   PackplantadminComponent,
	   SouthamericasidebarComponent,
	   EuropesidebarComponent,
	   AsiapacificsidebarComponent,
       PackadminComponent,
       PrgadminComponent,
       CosadminComponent,
       ContadminComponent,
       TestpageComponent,
       RegionadminComponent,
       SubmiterdashboardComponent,
       PacksupplierComponent,
       PackapproverComponent,
       PackagingprocessComponent,
       PacksupplieradminComponent,
       PackagingprocessdashboardComponent,
       PackagingproposalComponent,
       PackproposaltabtwoComponent,
       PackproposaltabthreeComponent,
       PackproposaltabfourComponent,
       PartsandatributesComponent,
       PackdesignsourceadminComponent,
       PackdesignsourceComponent,
       FormtemplateComponent,
       FormnewComponent,
       ComponentadminComponent,
       ApprovaladminComponent,
       PreliminaryappComponent,
       AdditionalappComponent,
       PlantappComponent,
       PlantfinalappComponent,
       ReleasefrevalappComponent,
       ReleasefrprodappComponent,
       ApprovaltemplateComponent,
       SearchexistingproposalComponent,
       PackagingprocesshomeComponent,
       EepartsandatributesComponent,
       EepackproposaltabtwoComponent,
       EepackproposaltabthreeComponent,
       EepackproposaltabfourComponent,
       RequestuseraccessComponent,
       UseradminComponent,
       PendingaccessrequestComponent,
       EdituserprofileComponent,
       EditaccessComponent,
       AdditionalrequestaccessComponent,
       EditsupplierprofileComponent,
       EqoconfigComponent,
       MaterialmanagementComponent,
       OtherconfigComponent,
       PlantappoffsiteComponent,
       PlantfinalappoffsiteComponent,
       ImprovementtaboneComponent,
       ImprovementtabtwoComponent,
       ImprovementtabthreeComponent,
       ImprovementtabfourComponent,
       PlantfinalappoffsiteComponent,
       ImprovementeetaboneComponent,
       ImprovementeetabtwoComponent,
       ImprovementeetabthreeComponent,
       ImprovementeetabfourComponent,
       ImprovementpackagingproposalComponent,
       MyuseraccessComponent,
       DbiareqmgntComponent,
       DbiareqstsComponent,
       DbiaadminComponent,
       ContainerrequestComponent,
       LoginotheruserComponent,
       ContainerreqstsComponent,
       MigrationsubmitterdashboardComponent,
       MigrationapprovalComponent,
       MypendingaccessComponent,
       AppfootercomponentComponent,
       HelpcomponentComponent,
       PackaginglinkscomponentComponent,
       InactiveuserpageComponent,
       SearchPartsComponent,
       ReportsComponent,
       PfepdataexportreportComponent,
       ReportsadminComponent,
       PackagingsummaryreportComponent,
       AlladditionalapprovalComponent,
       Form1271datareportComponent,
       GpparchivesearchpartComponent,
       LomreportComponent,
       GpparchivereportsadminComponent,
       GpparchivereportsComponent,
       GpparchivepackagingsummaryreportComponent,
       GpparchivelomreportComponent,
       Gpparchiveform1271reportComponent,
       GpparchivepfepdataexportreportComponent,
       SuppliercontactreportComponent,
       ProgramlevelemailComponent,
       TraininglinksComponent,
       GpparchivesuppliercontactreportComponent

    ],
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutes,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        ChartModule,
        CheckboxModule,
        ChipsModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DropdownModule,
        EditorModule,
        FieldsetModule,
        FileUploadModule,
        FullCalendarModule,
        GalleriaModule,
        InplaceModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        SelectButtonModule,
        SlideMenuModule,
        SliderModule,
        SpinnerModule,
        SplitButtonModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TerminalModule,
        TieredMenuModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        PdfViewerModule,

        ProgressSpinnerModule,
        RouterModule,
        ScrollingModule ,
        SidebarModule,
        OauthModule,
        HomeModule,
        ScrollPanelModule




    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        ExamplePageResolve,DatePipe,SubmiterdashboardComponent,NavComponent,PackagingproposalComponent,PartsandatributesComponent,MessageService,PackapproverComponent,PackproposaltabtwoComponent,PackagingprocessComponent,EepartsandatributesComponent,EepackproposaltabtwoComponent,PackadminComponent,UseradminComponent,AppTopBarComponent,UnsavedchangesGuard,
        ImprovementtaboneComponent,ImprovementtabtwoComponent,ImprovementeetaboneComponent,ImprovementeetabtwoComponent,PackagingprocessdashboardComponent ,ImprovementpackagingproposalComponent,PackagingprocesshomeComponent,PackagingprocessdashboardComponent,DbiaadminComponent,LoginpageComponent,
       // CarService, CountryService, EventService, NodeService,MessageService
       //Added for Authorization Token header
       {
        provide: HTTP_INTERCEPTORS,
        useClass: NgppInterceptorService,
        multi: true  
      }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
