import { Component, OnInit } from '@angular/core';


import {TransactionService} from '../loginpage/Transaction.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
    // tslint:disable-next-line:component-selector
  selector: 'exitpage',
  templateUrl: './exitpage.component.html',
  styleUrls: ['./exitpage.component.scss']
})
export class ExitpageComponent implements OnInit {


  loginUrl='';

  constructor(private transactionServices: TransactionService , private router:Router) { }

  ngOnInit(): void {

    this.loginUrl=environment.loginUrl;
    // this.transactionServices.logout();
   // window.close();

  }

}
