/* tslint:disable */
import { Component, OnInit ,OnDestroy} from '@angular/core';



import { MenuItem, MegaMenuItem } from 'primeng/api';


import {TransactionService} from '../loginpage/Transaction.service';
import { Router,ActivatedRoute } from '@angular/router';
import { NavComponent } from '../nav/nav.component';

@Component({
  selector: 'app-packadmin',
  templateUrl: './packadmin.component.html',
  styleUrls: ['./packadmin.component.css']
})
export class PackadminComponent implements OnInit,OnDestroy {



  tabpackadminMenuItems: MenuItem[];
  activeItem: MenuItem;
  region:string;
  name:string;
  plt:string;
  prg:string;
  cos:string;
  cont:string;
  regionadmin:string;
  componentadmin:string;
  dbiaadmin:string;
  dbiastsadmin:string;


  region_code='';
  cdsid='';


  routing_by_role:any[]=[];
  selected_routing_by_role:any[]=[];
  current_route_id=0;


  loaderpckadm=false;


  constructor(private transactionService: TransactionService,private router:Router,private navigation:NavComponent) { }

  ngOnInit(): void {
    this.region=this.transactionService.getregion();
    this.region_code=this.transactionService.getregioncode(this.region);
    this.cdsid=this.transactionService.getcdsid();

    this.current_route_id= this.transactionService.getrouteid(this.region);
    if(this.region=='Asia Pacific & Africa')
    {
      this.name='asiapacific';
    }

    else if(this.region=='Europe')
    {
      this.name='europe';
    }

    else if (this.region=='North America')
    {
     this.name='northamerica';
    }

    else if(this.region=='South America')
    {
     this.name='southamerica';
    }


    this.plt='/'+this.name+'/packagingprocess'+'/packadmin/packplantadmin';
    this.prg='/'+this.name+'/packagingprocess'+'/packadmin/prgadmin';
    this.cos='/'+this.name+'/packagingprocess'+'/packadmin/cosadmin';
    this.cont='/'+this.name+'/packagingprocess'+'/packadmin/app-contadmin';
    this.regionadmin='/'+this.name+'/packagingprocess'+'/packadmin/regionadmin';
    this.componentadmin='/'+this.name+'/packagingprocess'+'/packadmin/componentadmin';

    let val=false;



    this.routing_by_role=[
      {funcid:'57',value: {label: 'Plant Admin',routerLink: this.plt, icon: 'fas fa-industry'},id:101},
      {funcid:'55',value: {label: 'Program Admin',routerLink: this.prg, icon: 'fas fa-person-booth'},id:102},
      {funcid:'52',value: {label: 'COS Admin',routerLink: this.cos, icon: 'fas fa-person-booth'},id:103},
      {funcid:'53',value: {label: 'Container / Component Admin', routerLink: this.cont, icon: 'fas fa-cubes'},id:104},
      {funcid:'47,48,49,50,51',value:   {label: 'Region Admin',routerLink: this.regionadmin, icon: 'fas fa-person-booth'},id:105},
      {funcid:'54',value: {label: 'Expendable Aids Admin',routerLink: this.componentadmin, icon: 'fas fa-person-booth'},id:106},


  ]

  this.selected_routing_by_role=[];

  /*
  if(document.getElementById("overlayadm") != null )
  {
  document.getElementById("overlayadm").style.display = "block";
  }
  if(document.getElementById("loaderadm") != null )
  {
  document.getElementById("loaderadm").style.display = "block";
  }*/

  this.loaderpckadm=true;

this.loadRoles();

  this.transactionService.getUserAccessRole(this.cdsid).subscribe(out=>{

    this.tabpackadminMenuItems=[];
    console.log(out);
    let roles=[];
    if(out!=null && out.length!=0)
    {

      let roleidString='';
      let glbIndexPresent=out.findIndex(elglbpres=>elglbpres.role_id==100);
      if(glbIndexPresent!=-1)
      {


        if(JSON.parse(sessionStorage.getItem('isGlobalAdmin'))==false)
					{
						sessionStorage.removeItem('isGlobalAdmin');
					sessionStorage.setItem('isGlobalAdmin', JSON.stringify(true));

					this.updateRegionList();
					}



        roles.push({roleid:100,roles:[]})
          roleidString='100';

      }



    for(let reg=0;reg<out.length;reg++)
    {
      if(out[reg].cd_region==this.region_code)
      {
        if(roles.length==0)
        {
          roles.push({roleid:out[reg].role_id,roles:[]})
          roleidString=out[reg].role_id.toString();
        }
        else{


          let role_present=false;

          for(let len=0;len<roles.length;len++)
          {


            if(out[reg].role_id==roles[len])
            {

              role_present=true;
              break;

            }

          }


          if(role_present==false)
          {
            roles.push({roleid:out[reg].role_id,roles:[]})
            roleidString+=',';
            roleidString+=out[reg].role_id.toString();
          }
        }
      }
    }


    console.log(roles);


    if(roles.length!=0)
    {



this.transactionService.getuserrolefunctionality(roleidString,this.region_code).subscribe(userrolefuncaccess=>{


  console.log(userrolefuncaccess);

  if(userrolefuncaccess!=null || userrolefuncaccess.length!=0)
  {
  for(let ieu=0;ieu<roles.length;ieu++)
  {
    roles[ieu].roles=[]
    let rolefunc=[];
    for(let jeu=0;jeu<userrolefuncaccess.length;jeu++)
    {
      if(roles[ieu].roleid==userrolefuncaccess[jeu].role_id)
      {


        roles[ieu].roles.push(userrolefuncaccess[jeu]);


      }
    }
  }


  let name=this.region_code+'useraccess';
  sessionStorage.setItem(name,JSON.stringify(roles));

   console.log('user',roles);
let all_present=false


for(let i=0;i<this.routing_by_role.length;i++)
{
   let func_arr=this.routing_by_role[i].funcid.split(',');
   let route_req=false;

   for(let j=0;j<roles.length;j++)
   {
     let sys_func_arr=[];
      for(let k=0;k<roles[j].roles.length;k++)
      {

        sys_func_arr.push(roles[j].roles[k].sysfunc_id.toString())
      }
     if(func_arr.some(ele=>sys_func_arr.includes(ele)))
     {

      route_req=true;
      break;
     }

  }

   if(route_req == true)
   {

    if(this.tabpackadminMenuItems.length!=0)
         {

          let prsntIndex=this.selected_routing_by_role.findIndex(elpre=>elpre.id==this.routing_by_role[i].id)

          if(prsntIndex==-1)
          {
            this.tabpackadminMenuItems.push(this.routing_by_role[i].value);
            this.selected_routing_by_role.push(this.routing_by_role[i]);
          }


          if(this.tabpackadminMenuItems.length==this.routing_by_role.length)
          {
            all_present=true;
            break;
          }

         }
         else
         {
           this.tabpackadminMenuItems.push(this.routing_by_role[i].value);
           this.selected_routing_by_role.push(this.routing_by_role[i]);
         }

   }
}

/*
  for(var i=0;i<roles.length;i++)
  {
    for(var j=0;j<this.routing_by_role.length;j++)
    {
      var roles_arr=this.routing_by_role[j].funcid.split(',');
      for(var ik of roles_arr)
      {
        var routeIndex=roles[i].roles.findIndex(elrte=>elrte.sysfunc_id.toString()==ik)


        if(routeIndex!=-1)
        {
          break;
        }

      }



       if(routeIndex!=-1)
       {
         if(this.tabpackadminMenuItems.length!=0)
         {

          var prsntIndex=this.selected_routing_by_role.findIndex(elpre=>elpre.id==this.routing_by_role[j].id)

          if(prsntIndex==-1)
          {
            this.tabpackadminMenuItems.push(this.routing_by_role[j].value);
            this.selected_routing_by_role.push(this.routing_by_role[j]);
          }


          if(this.tabpackadminMenuItems.length==this.routing_by_role.length)
          {
            all_present=true;
            break;
          }

         }
         else
         {
           this.tabpackadminMenuItems.push(this.routing_by_role[j].value);
           this.selected_routing_by_role.push(this.routing_by_role[j]);
         }
       }
    }


    if(all_present==true)
    {
      break;
    }
  }
  */


  console.log('admin',this.tabpackadminMenuItems);
  console.log(this.navigation.buttonenabled_ap);




let route_present=false;

if(this.current_route_id!=null && this.current_route_id!= undefined)
{
  if(this.current_route_id==101 || this.current_route_id==102 ||  this.current_route_id==103 ||  this.current_route_id==104 ||  this.current_route_id==105 ||  this.current_route_id==106)
  {

if(this.selected_routing_by_role.length!=0)
{
    for(let rls=0;rls<this.selected_routing_by_role.length;rls++)
    {

      if(this.selected_routing_by_role[rls].id==this.current_route_id)
      {


        let test=this.selected_routing_by_role[rls].value
        console.log(test);
        this.activeItem=test;
        route_present=true;




       // this.activeItem.push(this.routing_by_role[rls].value);
        break;

      }

    }
  }



    if(route_present==false)
    {
      let test_new:MenuItem
  test_new=this.tabpackadminMenuItems[0];




  if(this.tabpackadminMenuItems.length==0)
{
  console.log('else1')
  this.navigation.buttonenabled_ap=true;
  this.routeRegion();
}

else
{

  console.log(test_new);
  this.activeItem=test_new;
  this.navigation.buttonenabled_ap=true;
 // document.getElementById("overlayadm").style.display = "none";
  // document.getElementById("loaderadm").style.display = "none";
  this.loaderpckadm=false;
  this.router.navigate([this.activeItem.routerLink]);
}

    }


    else if(route_present==true)
    {
      console.log('naviagtepackadm')
      this.navigation.buttonenabled_ap=true;
      // document.getElementById("overlayadm").style.display = "none";
      // document.getElementById("loaderadm").style.display = "none";
      this.loaderpckadm=false;
      this.router.navigate([this.activeItem.routerLink]);
    }

  }
  else
{
  let test_new:MenuItem
  test_new=this.tabpackadminMenuItems[0];
  if(this.tabpackadminMenuItems.length==0)
  {
    console.log('else2')
    this.navigation.buttonenabled_ap=true;
    this.routeRegion();
  }

  else
  {
  console.log(test_new);
  this.activeItem=test_new;
  console.log('naviagtepackadm1')
  this.navigation.buttonenabled_ap=true;
  // document.getElementById("overlayadm").style.display = "none";
  // document.getElementById("loaderadm").style.display = "none";
  this.loaderpckadm=false;
  this.router.navigate([this.activeItem.routerLink]);
  }
}

}




else{

  let test_new_s=this.tabpackadminMenuItems[0];

  console.log(this.tabpackadminMenuItems)
  if(this.tabpackadminMenuItems.length==0)
  {
    console.log('else3')
    console.log(this.tabpackadminMenuItems);
    this.navigation.buttonenabled_ap=true;
    this.routeRegion();
  }

  else
  {
  console.log(test_new_s);
  this.activeItem=test_new_s;
  console.log('naviagtepackadm3')
  this.navigation.buttonenabled_ap=true;
  // document.getElementById("overlayadm").style.display = "none";
  // document.getElementById("loaderadm").style.display = "none";
  this.loaderpckadm=false;
  this.router.navigate([this.activeItem.routerLink]);
  }
}

  }
  else
  {
    this.navigation.buttonenabled_ap=true;
    this.routeRegion();
  }







})

}
else
{
  this.navigation.buttonenabled_ap=true;
  this.routeRegion();
}
}

else
{
  this.navigation.buttonenabled_ap=true;
  this.routeRegion();
}







   })

  /*  this.tabpackadminMenuItems = [
      {label: 'Plant Admin',
      routerLink: this.plt, icon: 'fas fa-industry'},
      {label: 'Program Admin',
      routerLink: this.prg, icon: 'fas fa-person-booth'},
      {label: 'COS Admin',
      routerLink: this.cos, icon: 'fas fa-person-booth'},
      {label: 'Container Admin',
      routerLink: this.cont, icon: 'fas fa-cubes'},
     {label: 'Region Admin',
      routerLink: this.regionadmin, icon: 'fas fa-person-booth'},
      {label: 'Component Admin',
      routerLink: this.componentadmin, icon: 'fas fa-person-booth'}
      //visible:val}


  ];*/


  // this.activeItem = this.tabpackadminMenuItems[0];
  }


  routeRegion()
  {

    console.log('regpck',this.region_code);

    if(this.region_code=='NA')

    {

   this.navigation.navigatena(true);

    }
    else if(this.region_code=='AP')
    {

   this.navigation.navigateap(true);

    }
    else if(this.region_code=='EU')
    {
   this.navigation.navigateeu(true);
    }
    else if(this.region_code=='SA')
    {
      console.log('insodesapack')
   this.navigation.navigatesa(true);
    }

  }



  loadRoles()
  {


    console.log('regpck',this.region_code);

    if(this.region_code=='NA')

    {
   this.navigation.navigateap(false);
   this.navigation.navigateeu(false);
   this.navigation.navigatesa(false);

    }
    else if(this.region_code=='AP')
    {

      this.navigation.navigatena(false);
      this.navigation.navigateeu(false);
      this.navigation.navigatesa(false);

    }
    else if(this.region_code=='EU')
    {

      this.navigation.navigatena(false);
      this.navigation.navigateap(false);
      this.navigation.navigatesa(false);
    }
    else if(this.region_code=='SA')
    {
      console.log('insodesapack')

      this.navigation.navigatena(false);
      this.navigation.navigateeu(false);
      this.navigation.navigateap(false);
    }

  }


  updateRegionList()
	{


		console.log('update')
		let glbadmin=JSON.parse(sessionStorage.getItem('isGlobalAdmin'))
		if(glbadmin==true)
		{


			let regionlistupdate=JSON.parse(sessionStorage.getItem('user_regionaccess'))


			if(regionlistupdate.length!=4)
			{
				let user_region=[];
				let regionListact=['AP','EU','NA','SA']

				user_region.push(...regionListact);


				sessionStorage.removeItem('user_regionaccess');
						   sessionStorage.setItem('user_regionaccess', JSON.stringify(user_region));

			}




		}
	}




  activateMenu(event) {
    console.log('click on ' + event.tabpackadminMenuItem.label + ' tab!');
}


ngOnDestroy(){
  console.log('test');

}

}
