/* tslint:disable */
import { Component, OnInit } from '@angular/core';


import { Router } from '@angular/router';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import { TransactionService } from '../loginpage/Transaction.service';
import { selec_prt_pro_details, containerOwnershipList, contoncoslist, addContainer, cntrandsudetailspost, colorreglist, primarypartdetails, fromdiplayvalues_all, tab4details, searchProposalGrid, pinPalletTypeForGet, containerDetailsCntrAdmin, containerMaterialList, storecomponentforpost } from '../loginpage/transactions';
import { PackagingprocessComponent } from '../packagingprocess/packagingprocess.component';
import { PackagingproposalComponent } from '../packagingproposal/packagingproposal.component';
import { EepartsandatributesComponent } from '../eepartsandatributes/eepartsandatributes.component';
import { SubmiterdashboardComponent } from '../submiterdashboard/submiterdashboard.component';

@Component({
  selector: 'app-eepackproposaltabtwo',
  templateUrl: './eepackproposaltabtwo.component.html',
  styleUrls: ['./eepackproposaltabtwo.component.css'],
  providers:[MessageService,ConfirmationService]
})
export class EepackproposaltabtwoComponent implements OnInit {



  tab3menuitem_ee:MenuItem;
  name_ee:string;
  tab3_ee:string;
  flag_ee:boolean;
  region_ee:string;
  tab1route_ee:string;
  tab2route_ee:string;
  tab3route_ee:string;
  tab4route_ee:string;

  containerdetails_ee:containerDetailsCntrAdmin;
  iscntrselected_ee:boolean;
  cntrdetforpost_ee:cntrandsudetailspost;
  colorforcntrorrack_ee:colorreglist[]=[];
  selectedcolor_ee:colorreglist;

  select_contcos_dialog_ee:boolean=false;
  contandcos_cdsid_ee:string;
  current_region_contandcos_ee:string;
  current_region_code_contandcos_ee:string;
  supp_code_contandcos_ee:string;
  selectedpart_submitter_contandcos_ee:selec_prt_pro_details={simplifiedpartno:"-",proposalid:0,bompartmasterid:0,plantgsdbcode:"",proposallastsaved:0,part_status:"",supplier_code:"",proposallastsaved_ee:1,is_ee_proposal:true,currentview_ee:true,from_draft:false,propsl_type:"",imp_part_status:""};
  id_proposal_contandcos_ee:number;
  noSupDialog_contandcos_ee:boolean=false;
  content_supp_contandcos_ee:string;
  noPrtDialog_contandcos_ee:boolean=false;
  content_selectedprt_contandcos_ee:string;
  nocosdialog_ee:boolean=false;
  content_cos_contandcos_ee:string;


  disable_cos_dropdown_ee:boolean=false;


  enable_details_dialog_ee:boolean=false;
  current_region_code_prt_ee:string;


  cos_from_pfep_ee:string="";
  deviate_from_pfep_ee:boolean=false;
  cntrOwnershipstratcodelist_ee:containerOwnershipList[]=[];
  selected_cntr_ownrship_strat_code_ee:containerOwnershipList;


  selected_container_ee:contoncoslist=null;
  table_selected_container_ee:contoncoslist=null;
  exsisting_value_from_pfep_ee:containerOwnershipList;


  selected_container_cos_ee:containerOwnershipList;
  selected_container_deviate_from_pfep_ee:boolean;



  contlist_oncos_ee:contoncoslist[];
  cont_grid_text_ee:string="";
  cont_present_table_ee:boolean=false;
  time_ee:string="first";
  dropdown_time_ee:string="first"
  disable_deviate_ee:boolean=false;
  public cont_cols_ee:any
  roleid_contandcos_ee:number;
  designsource_code_contandcos_ee:string;


  coscodes_ee:containerOwnershipList[]=[];
  existingtab2details_ee:cntrandsudetailspost;
  getlastsavedtabfromapi_ee:primarypartdetails;
  containerlist_ee:contoncoslist[]=[];

noDesignsourceDialog_contandcos_ee:boolean=false;
content_Designsource_contandcos_ee:string="";


noroleselectedDialog_ee:boolean=false;
  norolecontent_ee:string=""


  edit_measure_contcos_ee:string="lbs/inch";
  edit_measure_contcos_carton_ee:string="PSI";

  get_measure_ee:string="lbs/inch";

  unitom_ee:string = "";


  enabledetails_cntr_ee:boolean=false;
  cntrdetails_display_ee:containerDetailsCntrAdmin;
  stampingprop_ee:boolean=false;
  pinpallettyp_ee=[{label:"N/A",value:"N/A"},{label:"Hexagon",value:"Hexagon"},{label:"Square",value:"Square"},{label:"Round",value:"Round"}]
  pinpallettypeList_ee:pinPalletTypeForGet[]=[];
  selectedPinPalletType_ee:pinPalletTypeForGet={cd_pinpallettyp:0,pinpallettyp:"",pinpallettyp_desc:"",active_stat:"",updated_by:"",updated_dt:"",active_stat_display:false};


  pallet_type_ee:string="";

  copy_cntr_dialog_ee:boolean=false;
  copy_cntr_input_ee:any;
  cntr_dtls_to_be_copied_ee:fromdiplayvalues_all;
  enable_cntr_selectn_from_copy_cntr_dilg_notice_ee:boolean=false;


  tab4_output_cntr_ee:tab4details;


  cntrMat_ee:containerMaterialList[]=[];
  selected_cntr_mat_ee:containerMaterialList={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};


  saveandnextenable_ee:boolean=false;
  saveandcloseenable_ee:boolean=false;

  disp_lbs : boolean = false;
  disp_kg  : boolean = false;

  contextlenInvalid_ee:boolean=false;
 contextlenInValidReason_ee:string=null;
 contextwidInvalid_ee:boolean=false;
 contextwidInValidReason_ee:string=null;
 contexthgtInvalid_ee:boolean=false;
 contexthgtInValidReason_ee:string=null;
 contarewgtInvalid_ee:boolean=false;
 contarewgtInValidReason_ee:string=null;
 contNestedHtInValid_ee:boolean=false;
 contNestedHtInValidReason_ee:string=null;
 partsprcntrInValid_ee:boolean=false;
 partsprcntrInValidReason_ee:string=null;
 maxlayerprshpInValid_ee:boolean=false;
 maxlayerprshpInValidReason_ee:string=null;
 cntrperlaysInValid_ee:boolean=false;
 cntrperlaysInValidReason_ee:string=null;
 suextlenInvalid_ee:boolean=false;
 suextlenInValidReason_ee:string=null;
 suextwidInvalid_ee:boolean=false;
 suextwidInValidReason_ee:string=null;
 suexthgtInvalid_ee:boolean=false;
 suexthgtInValidReason_ee:string=null;
 suNestedHtInValid_ee:boolean=false;
 suNestedHtInValidReason_ee:string=null;

 
 cont_retype:string="";
 selected_cont_retype_ee: string = "";
 ret_type_ee: string = "";
 cont_typ_no_ee: number = 1;

 tab3Data_ee:storecomponentforpost[]=[];
 originalTab2Details_ee:cntrandsudetailspost;

 
 emptyTraysList_ee:any[]=[];
 selectedEmptyTrays_ee:any={label:'0',value:0};

 enableExteriorHeightPopUp_ee:boolean=false;
 savedSuExteriorHeight_ee:number=0;
 isSaveAndNext_ee:boolean=false;
 cntrColorRackDisable_ee: boolean = false;

  constructor(private submitterdashboard: SubmiterdashboardComponent,private messageService:MessageService,private transactionService:TransactionService, private router:Router,private packagingproposal:PackagingproposalComponent,private partsandattri:EepartsandatributesComponent,private packagingprocess:PackagingprocessComponent,private confirmationService:ConfirmationService) { }

  ngOnInit(): void {
    /* code for menu creation starts*/
    this.region_ee=this.transactionService.getregion();
this.current_region_code_prt_ee=this.transactionService.getregioncode(this.region_ee);

    this.tab1route_ee=this.transactionService.getregionurlvalue(this.region_ee)+'packagingprocess/proposalcreation/eepartsandattri';
    this.tab2route_ee=this.transactionService.getregionurlvalue(this.region_ee)+'packagingprocess/proposalcreation/eepackproposaltabtwo';
    this.tab3route_ee=this.transactionService.getregionurlvalue(this.region_ee)+'packagingprocess/proposalcreation/eepackproposaltabthree';
    this.tab4route_ee=this.transactionService.getregionurlvalue(this.region_ee)+'packagingprocess/proposalcreation/eepackproposaltabfour';


    console.log("tab2 initialised");
   // this.packagingproposal.savedtab=this.packagingproposal.getlastsavedtabfromapi.lst_saved_tab
    console.log("savedtab",this.packagingproposal.savedtab);


    //  this.getContainerMaterial_ee();

    this.cont_retype="Returnable";
    this.selected_cont_retype_ee="Returnable"

    // UOM Freeze
    this.transactionService.get_region_details(this.current_region_code_prt_ee).subscribe(unitype=>{
      this.unitom_ee = unitype.uom;

      console.log("UOMREG",this.unitom_ee);

      if(this.unitom_ee=="E")
      {
        this.edit_measure_contcos_ee = "lbs/inch";
        this.get_measure_ee = "lbs/inch";
        this.disp_lbs = true;
        console.log("UOMLB",this.edit_measure_contcos_ee, this.get_measure_ee)
      }
      else
      {
        this.edit_measure_contcos_ee = "kg/cm";
        this.get_measure_ee = "kg/cm";
        this.disp_kg = true;
        console.log("UOMKG",this.edit_measure_contcos_ee, this.get_measure_ee)
      }
    })


    if(this.packagingproposal.savedtab==2 )
    {
      for(let i of this.packagingproposal.tabpackagingproposalmenuitems.values())
      {
            console.log(i);
            if(i.label!='EE-Component & Material' )
            {
                this.packagingproposal.flag2_ee=true;
            }
            else{
                this.packagingproposal.flag2_ee=false;
                break;
            }
      }

      if(this.packagingproposal.flag2_ee)
      {
        console.log("tab2 component added component material");
         this.packagingproposal.tabpackagingproposalmenuitems.push({label:'EE-Component & Material',routerLink:this.tab3route_ee, icon: 'fas fa-person-booth'});
        }
    }

    else if(this.packagingproposal.savedtab==3 || this.packagingproposal.savedtab==4)
    {
      for(let i of this.packagingproposal.tabpackagingproposalmenuitems.values())
      {
            console.log(i);
            if(i.label!='EE-Component & Material' && i.label!='EE-Images/Photos & Comments')
            {
                this.packagingproposal.flag3_ee=true;
            }
            else{
                this.packagingproposal.flag3_ee=false;
                break;
            }
      }

      if(this.packagingproposal.flag3_ee)
      {
        console.log("tab2 component loaded images/photos & Comments");
        this.packagingproposal.tabpackagingproposalmenuitems.push({label:'EE-Component & Material',routerLink:this.tab3route_ee, icon: 'fas fa-person-booth'});
        this.packagingproposal.tabpackagingproposalmenuitems.push({label:'EE-Images/Photos & Comments',routerLink:this.tab4route_ee, icon: 'pi pi-images'});}
    }

    this.select_contcos_dialog_ee=false;

/* code for menu creation ends*/


this.contandcos_cdsid_ee = this.transactionService.getcdsid();
this.current_region_contandcos_ee = this.transactionService.getregion();
this.roleid_contandcos_ee=this.transactionService.getcurrentrole(this.current_region_contandcos_ee)



if(this.roleid_contandcos_ee==null ||(this.roleid_contandcos_ee != 1 && this.roleid_contandcos_ee != 2))
{


  this.noroleselectedDialog_ee=true;
  this.norolecontent_ee="No role selected "
}
if(this.roleid_contandcos_ee==1)
{
this.supp_code_contandcos_ee = this.transactionService.get_supplier(this.current_region_contandcos_ee);
console.log(this.supp_code_contandcos_ee);
}
else if( this.roleid_contandcos_ee==2)
{
  this.designsource_code_contandcos_ee = this.transactionService.get_designsource(this.current_region_contandcos_ee);
  console.log(this.designsource_code_contandcos_ee)
}
this.current_region_code_contandcos_ee = this.transactionService.getregioncode(this.current_region_contandcos_ee);
//this.transactionService.storepage(this.current_region_contandcos, "packagingprocess/proposalcreation/packproposaltabtwo");
//this.selectedpart_submitter=this.submitterdashboard.pushdata();
this.selectedpart_submitter_contandcos_ee = this.transactionService.getpartno_packpro(this.current_region_contandcos_ee);

console.log(this.selectedpart_submitter_contandcos_ee)

//Get details TAB2
this.cntrdetailsinit_ee();

this.cntrdetails_display_popup_init_ee();
this.cntrdetforpostinit_ee();
this.getactivecolorforregion_ee();
this.getActivePinPalletType_ee();

this.iscntrselected_ee=false;


if(this.roleid_contandcos_ee==1)
{

if (this.supp_code_contandcos_ee != null && this.selectedpart_submitter_contandcos_ee != null && this.selectedpart_submitter_contandcos_ee.simplifiedpartno!="-" && this.selectedpart_submitter_contandcos_ee.proposalid!=0 ) {

  this.id_proposal_contandcos_ee=this.selectedpart_submitter_contandcos_ee.proposalid;
  this.transactionService.get_draft_Tab1_data(this.id_proposal_contandcos_ee).subscribe(dtab1out=>{
    if(dtab1out.isstampblank=="Yes" || dtab1out.isstampblank=="yes")
    {
      this.stampingprop_ee=true;
    }
  })

if(this.selectedpart_submitter_contandcos_ee.proposallastsaved_ee>=2)
{
  console.log("this.selectedpart_submitter_contandcos",this.selectedpart_submitter_contandcos_ee.proposalid);
  this.loadtab2detlsifexist_ee();
}
else
{
  if(sessionStorage.getItem(String(this.selectedpart_submitter_contandcos_ee.proposalid))!=null)
  {
    sessionStorage.removeItem(String(this.selectedpart_submitter_contandcos_ee.proposalid))
  }

}

}

else if (this.supp_code_contandcos_ee == null) {
  this.noSupDialog_contandcos_ee = true;
  this.content_supp_contandcos_ee = "Please select supplier";
}


else if (this.supp_code_contandcos_ee != null && (this.selectedpart_submitter_contandcos_ee == null || this.selectedpart_submitter_contandcos_ee.simplifiedpartno=="-" || this.selectedpart_submitter_contandcos_ee.proposalid==0)) {
  this.noPrtDialog_contandcos_ee = true;
  this.content_selectedprt_contandcos_ee = "Please select part from submitter dashboard";

}
}
else if (this.roleid_contandcos_ee==2)
{



if (this.designsource_code_contandcos_ee != null && this.selectedpart_submitter_contandcos_ee != null && this.selectedpart_submitter_contandcos_ee.simplifiedpartno!="-" && this.selectedpart_submitter_contandcos_ee.proposalid!=0 ) {

  this.id_proposal_contandcos_ee=this.selectedpart_submitter_contandcos_ee.proposalid;
  this.transactionService.get_draft_Tab1_data(this.id_proposal_contandcos_ee).subscribe(dtab1outs=>{
    if(dtab1outs.isstampblank=="Yes" || dtab1outs.isstampblank=="yes")
    {
      this.stampingprop_ee=true;
    }
  })

  if(this.selectedpart_submitter_contandcos_ee.proposallastsaved_ee>=2)
  {
    console.log("this.selectedpart_submitter_contandcos",this.selectedpart_submitter_contandcos_ee.proposalid);
  this.loadtab2detlsifexist_ee();
  }
  else
  {
    if(sessionStorage.getItem(String(this.selectedpart_submitter_contandcos_ee.proposalid))!=null)
    {
      sessionStorage.removeItem(String(this.selectedpart_submitter_contandcos_ee.proposalid))
    }
  }


}

else if (this.designsource_code_contandcos_ee == null) {
  this.noDesignsourceDialog_contandcos_ee = true;
  this.content_Designsource_contandcos_ee = "Please select Design source";
}


else if (this.designsource_code_contandcos_ee != null && (this.selectedpart_submitter_contandcos_ee == null || this.selectedpart_submitter_contandcos_ee.simplifiedpartno=="-" || this.selectedpart_submitter_contandcos_ee.proposalid==0)) {
  this.noPrtDialog_contandcos_ee = true;
  this.content_selectedprt_contandcos_ee = "Please select part from submitter dashboard";
}

}



  }

  emptyTraysChange_ee()
  {
    this.cntrdetforpost_ee.empty_trays_cntr_layrs=this.selectedEmptyTrays_ee;
    console.log("emptyTraysChange_ee()",this.cntrdetforpost_ee.empty_trays_cntr_layrs,this.selectedEmptyTrays_ee)
  }

  getEmptyTraysList_ee()
  {
    var maxTraysValue=this.cntrdetforpost_ee.max_layrs_shp_unt < 9 ? this.cntrdetforpost_ee.max_layrs_shp_unt : 8; 
    this.emptyTraysList_ee=[];
    if(this.cntrdetforpost_ee.max_layrs_shp_unt != null && this.cntrdetforpost_ee.max_layrs_shp_unt != 0)
    {
    for(var i=0; i<maxTraysValue; i++)
    {
      this.emptyTraysList_ee.push({label:i.toString(),value:i});
    }
    }
    else
    {
      this.emptyTraysList_ee.push({label:"0",value:0})
    }
    return this.emptyTraysList_ee;
  }


  getContainerMaterial_ee(){

    if(this.selected_cont_retype_ee=="Expendable"){
      this.ret_type_ee = "E";
    }
    else{
      this.ret_type_ee = "R";
    }

    // Commented this so that we fetch material based on container return type
    //this.ret_type_ee="E";

    this.transactionService.getContainerMaterial(this.cont_typ_no_ee,this.ret_type_ee).subscribe(contmat=>{
      this.cntrMat_ee=[];
      this.cntrMat_ee=contmat;

      console.log("Cont Material_EE",this.cntrMat_ee,this.containerdetails_ee.cntr_matr_desc,this.cntrdetforpost_ee.cntr_material);
      console.log("post value EE",this.cntrdetforpost_ee.cntr_material);


    if(this.cntrMat_ee != null && this.cntrMat_ee.length !=0  && this.cntrdetforpost_ee.cntr_material != null)
    {
      for(var t of this.cntrMat_ee)
      {
       if(t.cntr_matr_name.toLowerCase() == this.cntrdetforpost_ee.cntr_material.toLowerCase())
       {
          this.selected_cntr_mat_ee=t;
          console.log("Material in existingdets EE",this.selected_cntr_mat_ee,this.cntrdetforpost_ee.cntr_material)
          break;
       }
      }
     }
     if(this.selected_cntr_mat_ee.cntr_matr_desc == "Corrugated Cardboard")
     {
       this.cntrColorRackDisable_ee = true;
       this.selectedcolor_ee.color_desc = "NA";
       console.log("Mat for disable", this.selected_cntr_mat_ee);
     }
     else
     {
       this.cntrColorRackDisable_ee = false;
     }
     this.cntrdetforpost_ee.cntr_material=this.selected_cntr_mat_ee.cntr_matr_desc
    })

  }



  copy_cntr_ee()
  {
    this.copy_cntr_dialog_ee=true;
    //this.selectedpart_submitter_contandcos
    this.copy_cntr_input_ee={"dialogstatus":this.copy_cntr_dialog_ee,"suppliercode":this.selectedpart_submitter_contandcos_ee.supplier_code,"id_proposal":this.selectedpart_submitter_contandcos_ee.proposalid};
  }


  setCopyCntrPopUpWithSelectedDeatils_ee()
  {
    if(sessionStorage.getItem("partsfromcopycntrdialog")!=null)
    {
      let proposalInApproveForProductionStatus:searchProposalGrid[]
      proposalInApproveForProductionStatus=JSON.parse(sessionStorage.getItem("partsfromcopycntrdialog"));

      if(this.cntrdetforpost_ee.ref_bompartmasterid!=null)
      {
        for(var i of proposalInApproveForProductionStatus)
        {
          if(i.bompartmasterid==this.cntrdetforpost_ee.ref_bompartmasterid)
          {
            sessionStorage.setItem(String(this.selectedpart_submitter_contandcos_ee.proposalid),JSON.stringify(i));
            break;
          }
        }
      }

    }
  }

  copy_cntr_dialog_close_ee()
  {
    this.copy_cntr_dialog_ee=false;
    this.selectedpart_submitter_contandcos_ee.from_draft=true;
    this.store_primary_part_exist_ee();
    this.copy_cntr_input_ee={"dialogstatus":this.copy_cntr_dialog_ee,"suppliercode":this.selectedpart_submitter_contandcos_ee.supplier_code,"id_proposal":this.selectedpart_submitter_contandcos_ee.proposalid};
    //alert("copy_cntr_dialog_close"+this.copy_cntr_dialog);
  }

  changedialogvalue_ee(event:boolean)
  {
    //alert("came here"+event)

    this.copy_cntr_input_ee={"dialogstatus":this.copy_cntr_dialog_ee,"suppliercode":this.selectedpart_submitter_contandcos_ee.supplier_code,"id_proposal":this.selectedpart_submitter_contandcos_ee.proposalid};

    /* Selected Proposal From Copy container dialog (entire row) is saved sessionly with key as proposalid
    so fetching the proposal from session storage and sending it's proposal id to api to fetch container details */

    if(sessionStorage.getItem(String(this.selectedpart_submitter_contandcos_ee.proposalid))!=null)
    {
      this.cntrdetforpost_ee.cntrselfrm="copycntr";
      this.enable_cntr_selectn_from_copy_cntr_dilg_notice_ee=true;
      const proposalidtobecopiedfrom=JSON.parse(sessionStorage.getItem(String(this.selectedpart_submitter_contandcos_ee.proposalid)));
      this.transactionService.loadtransacmasterdetails(proposalidtobecopiedfrom.id_proposal).subscribe(cntrdetfromtransmas=>{
        //alert(JSON.stringify(cntrdetfromtransmas));
        this.cntr_dtls_to_be_copied_ee=cntrdetfromtransmas

        this.iscntrselected_ee=true;
        console.log("cntrdetailstobecopied",this.cntr_dtls_to_be_copied_ee);

        this.cntrdetforpost_ee.id_proposal=this.selectedpart_submitter_contandcos_ee.proposalid;
        this.cntrdetforpost_ee.cntr_ret_type=this.cntr_dtls_to_be_copied_ee.cntr_ret_type;
        this.cntrdetforpost_ee.used_carry_ovr_pck=this.cntr_dtls_to_be_copied_ee.used_carry_ovr_pck
        this.cntrdetforpost_ee.cntr_base =this.cntr_dtls_to_be_copied_ee.cntr_base;
        this.cntrdetforpost_ee.cntr_suff=this.cntr_dtls_to_be_copied_ee.cntr_suff;
        this.cntrdetforpost_ee.cntr_desc=this.cntr_dtls_to_be_copied_ee.cntr_desc;
        this.cntrdetforpost_ee.cntr_ext_len=this.cntr_dtls_to_be_copied_ee.cntr_ext_len;
        this.cntrdetforpost_ee.cntr_width=this.cntr_dtls_to_be_copied_ee.cntr_width;
        this.cntrdetforpost_ee.cntr_height=this.cntr_dtls_to_be_copied_ee.cntr_height;
        this.cntrdetforpost_ee.cntr_material=this.cntr_dtls_to_be_copied_ee.cntr_material;
        console.log("copy cont",this.cntr_dtls_to_be_copied_ee.cntr_material)
        this.cntrdetforpost_ee.cntr_tar_weight=this.cntr_dtls_to_be_copied_ee.cntr_tar_weight;
        //this.cntrdetforpost_ee.cntr_per_layer=this.cntr_dtls_to_be_copied_ee.cntr_per_layer;
        //this.cntrdetforpost_ee.max_layrs_shp_unt=this.cntr_dtls_to_be_copied_ee.max_layrs_shp_unt;
        this.cntrdetforpost_ee.empty_trays_cntr_layrs=this.cntr_dtls_to_be_copied_ee.empty_trays_cntr_layrs
        this.selectedEmptyTrays_ee=this.cntrdetforpost_ee.empty_trays_cntr_layrs;
        this.cntrdetforpost_ee.max_cntr_per_shp_unt=this.cntr_dtls_to_be_copied_ee.max_cntr_per_shp_unt;
        this.cntrdetforpost_ee.shp_unt_desc=this.cntr_dtls_to_be_copied_ee.shp_unt_desc;
        this.cntrdetforpost_ee.shp_unt_ext_height=this.cntr_dtls_to_be_copied_ee.shp_unit_ext_len;
        this.cntrdetforpost_ee.shp_unit_ext_len=this.cntr_dtls_to_be_copied_ee.shp_unt_ext_width;
        this.cntrdetforpost_ee.shp_unt_ext_width=this.cntr_dtls_to_be_copied_ee.shp_unt_ext_height;
        this.cntrdetforpost_ee.shp_unt_nestd_fld_height=this.cntr_dtls_to_be_copied_ee.shp_unt_nestd_fld_height;
        this.cntrdetforpost_ee.card_call=this.cntr_dtls_to_be_copied_ee.card_call;
        this.cntrdetforpost_ee.cntr_grp_id=this.cntr_dtls_to_be_copied_ee.cntr_grp_id;
        this.cntrdetforpost_ee.cntr_ownrshp_strat_code=this.cntr_dtls_to_be_copied_ee.cntr_ownrshp_strat_code;
        this.cntrdetforpost_ee.cd_ownrshp_strat=this.cntr_dtls_to_be_copied_ee.cd_ownrshp_strat;
        this.cntrdetforpost_ee.cd_cntr=this.cntr_dtls_to_be_copied_ee.cd_cntr;

        for(var color=0; color<this.colorforcntrorrack_ee.length; color++)
        {
          if(this.colorforcntrorrack_ee[color].color_desc==this.cntr_dtls_to_be_copied_ee.cntr_rck_colr)
          {
            this.selectedcolor_ee=this.colorforcntrorrack_ee[color];
            this.cntrdetforpost_ee.cntr_rck_colr=this.selectedcolor_ee.color_desc;

          }
        }


        this.getContainerMaterial_ee();

        this.cntrdetforpost_ee.cntr_nsted_fld_height=this.cntr_dtls_to_be_copied_ee.cntr_nsted_fld_height;
        this.cntrdetforpost_ee.shp_unt_base=this.cntr_dtls_to_be_copied_ee.shp_unt_base;
        this.cntrdetforpost_ee.shp_unt_suff=this.cntr_dtls_to_be_copied_ee.shp_unt_suff;
        // <!-- New changes are here  -->
        this.cntrdetforpost_ee.cntr_per_layer=this.cntr_dtls_to_be_copied_ee.cntr_per_layer;
        this.cntrdetforpost_ee.max_layrs_shp_unt=this.cntr_dtls_to_be_copied_ee.max_layrs_shp_unt;
        //  <!-- New Changes ends here  -->
        this.cntrdetforpost_ee.part_per_shp_unit=this.cntr_dtls_to_be_copied_ee.part_per_shp_unit;
        this.cntrdetforpost_ee.parts_per_cntr=this.cntr_dtls_to_be_copied_ee.parts_per_cntr;
        this.cntrdetforpost_ee.shp_unt_ret_type=this.cntr_dtls_to_be_copied_ee.shp_unt_ret_type;
        this.cntrdetforpost_ee.pallettype=this.cntr_dtls_to_be_copied_ee.pallettype,
        this.pallet_type_ee=this.cntrdetforpost_ee.pallettype
        this.cntrdetforpost_ee.woodispmcmplnt= this.cntr_dtls_to_be_copied_ee.woodispmcmplnt,
        this.cntrdetforpost_ee.offstmpmartprsnt= this.cntr_dtls_to_be_copied_ee.offstmpmartprsnt,
        this.cntrdetforpost_ee.blanksperstack= this.cntr_dtls_to_be_copied_ee.blanksperstack,
        this.cntrdetforpost_ee.stacksperpallet=this.cntr_dtls_to_be_copied_ee.stacksperpallet,
       // this.cntrdetforpost_ee.palletpintyp= this.cntr_dtls_to_be_copied_ee.palletpintyp,
        this.cntrdetforpost_ee.iscartonimcspec= null,
        this.cntrdetforpost_ee.cartonburststrnth=null,
        this.cntrdetforpost_ee.imcwtdisc=null,
        // ADD for cntr owner also
        this.cntrdetforpost_ee.cntrowner=this.cntr_dtls_to_be_copied_ee.cntrOwner;
        this.cntrdetforpost_ee.ref_bompartmasterid=proposalidtobecopiedfrom.bompartmasterid;


        this.transactionService.getActivePinPalletTypeList().subscribe(pin=>{

          this.pinpallettypeList_ee=[];
          this.pinpallettypeList_ee=pin;

          //return this.colorforcntrorrack;
           //this.colorforcntrorrack=colr;
           console.log("Pin Pallet tYpe List",this.pinpallettypeList_ee)
        if(this.pinpallettypeList_ee.length !=0 && this.cntr_dtls_to_be_copied_ee.palletpintyp != null && this.cntr_dtls_to_be_copied_ee.palletpintyp != "")
        {
          var pintypeIndex = this.pinpallettypeList_ee.findIndex(pintyp=>pintyp.pinpallettyp.toLowerCase() == this.cntr_dtls_to_be_copied_ee.palletpintyp.toLowerCase())

          if(pintypeIndex != -1)
          {
            this.selectedPinPalletType_ee=this.pinpallettypeList_ee[pintypeIndex];
            this.cntrdetforpost_ee.palletpintyp= this.selectedPinPalletType_ee.pinpallettyp;
          }
          else
          {
            this.selectedPinPalletType_ee={cd_pinpallettyp:0,pinpallettyp:"",pinpallettyp_desc:"",active_stat:"",updated_by:"",updated_dt:"",active_stat_display:false};
          }

        }
        else
          {
            this.selectedPinPalletType_ee={cd_pinpallettyp:0,pinpallettyp:"",pinpallettyp_desc:"",active_stat:"",updated_by:"",updated_dt:"",active_stat_display:false};
          }
        })

        if(this.cntr_dtls_to_be_copied_ee.bct==null)
        {
          this.cntrdetforpost_ee.bct=null;
        }
        else
        {
          this.cntrdetforpost_ee.bct=this.cntr_dtls_to_be_copied_ee.bct;
        }

        if(this.cntr_dtls_to_be_copied_ee.ect==null)
        {
          this.cntrdetforpost_ee.ect=null;
        }
        else
        {
          this.cntrdetforpost_ee.bct=this.cntr_dtls_to_be_copied_ee.ect;
        }


        this.containerdetails_ee.cd_cntr=this.cntr_dtls_to_be_copied_ee.cd_cntr;
        this.containerdetails_ee.cntr_base=this.cntr_dtls_to_be_copied_ee.cntr_base;
        this.containerdetails_ee.cntr_suffix=this.cntr_dtls_to_be_copied_ee.cntr_suff;
        this.containerdetails_ee.cntr_desc=this.cntr_dtls_to_be_copied_ee.cntr_desc;
        this.containerdetails_ee.cntr_tare_wt=this.cntr_dtls_to_be_copied_ee.cntr_tar_weight;
        this.containerdetails_ee.exp_refundable=this.cntr_dtls_to_be_copied_ee.cntr_ret_type;
        this.containerdetails_ee.cntr_matr_desc=this.cntr_dtls_to_be_copied_ee.cntr_material;
        this.containerdetails_ee.cntr_grp_desc=this.cntr_dtls_to_be_copied_ee.cntr_grp_id;
        this.containerdetails_ee.card_call=this.cntr_dtls_to_be_copied_ee.card_call;
        this.containerdetails_ee.cntr_exterior_l=this.cntr_dtls_to_be_copied_ee.cntr_ext_len;
        this.containerdetails_ee.cntr_exterior_w=this.cntr_dtls_to_be_copied_ee.cntr_width;
        this.containerdetails_ee.cntr_exterior_h=this.cntr_dtls_to_be_copied_ee.cntr_height;
        //this.containerdetails_ee.max_layers_shpg_unit=this.cntr_dtls_to_be_copied_ee.max_layrs_shp_unt;
        //this.containerdetails_ee.cntr_per_layer=this.cntr_dtls_to_be_copied_ee.cntr_per_layer;
        this.containerdetails_ee.max_cntr_per_shpg_unit=this.cntr_dtls_to_be_copied_ee.max_cntr_per_shp_unt;
        this.containerdetails_ee.max_cntr_per_shpg_unit=this.cntr_dtls_to_be_copied_ee.max_cntr_per_shp_unt;
        this.containerdetails_ee.shpg_unit_exterior_l=this.cntr_dtls_to_be_copied_ee.shp_unit_ext_len;
        this.containerdetails_ee.shpg_unit_exterior_w=this.cntr_dtls_to_be_copied_ee.shp_unt_ext_width;
        this.containerdetails_ee.shpg_unit_exterior_h=this.cntr_dtls_to_be_copied_ee.shp_unt_ext_height;
        this.containerdetails_ee.shpg_unit_nstdorflded_ht=this.cntr_dtls_to_be_copied_ee.shp_unt_nestd_fld_height;

        console.log("ctrdetailscopied",this.cntrdetforpost_ee);
      });
      this.selected_container_ee=null;
      this.table_selected_container_ee=null;
      this.selected_container_cos_ee=null;
      this.selected_cntr_ownrship_strat_code_ee=null;
      this.copy_cntr_dialog_ee=event;
    }
    else
    {
      this.copy_cntr_dialog_ee=event;
    }
  }
  maxLayerChange_ee()
  {
    if(this.cntrdetforpost_ee.max_layrs_shp_unt == null || this.cntrdetforpost_ee.max_layrs_shp_unt <= this.cntrdetforpost_ee.empty_trays_cntr_layrs)
    {
      this.cntrdetforpost_ee.empty_trays_cntr_layrs=0;
      this.selectedEmptyTrays_ee=0;
    }
  }

  onChangeExpRet(){


    // this.cont_retype = "Returnable"
    console.log("Return Type value change",this.cont_retype);


  /*  if(this.cont_retype.toLowerCase() == "Returnable")
    {
      this.containerdetails.exp_refundable="Returnable";
      this.select_contcos_dialog=false;
      this.enable_cntr_selectn_from_copy_cntr_dilg_notice=false;
      this.cont_present_table=true;
      this.selected_container=null;


      
    }
    else if(this.cont_retype.toLowerCase() == "Expendable")
    {
      this.containerdetails.exp_refundable="Expendable";
      this.select_contcos_dialog=false;
      this.enable_cntr_selectn_from_copy_cntr_dilg_notice=false;
      this.cont_present_table=true;
      this.selected_container=null;
    }*/


     this.on_change_cos_dropdown_ee();
  }


  loadtab2detlsifexist_ee()
  {

    /* check if proposal already exists and tab2 has been already saved in proposal*/

    console.log("entered into loadtab2detailsifexits",this.packagingproposal.savedtab);

    /*
    this.transactionService.get_exist_proposalid(this.selectedpart_submitter_contandcos.simplifiedpartno,this.selectedpart_submitter_contandcos.plantgsdbcode).subscribe(result=>
      {

        //when this component is refreshed, packagingpropsal.saved tab will become undefined, so setting it by calling api

        this.getlastsavedtabfromapi=result;
        console.log("getlastsavedtabfromapi",this.getlastsavedtabfromapi);
        //console.log(result.lst_saved_tab);
        this.packagingproposal.savedtab=result.lst_saved_tab;

       // if(this.selectedpart_submitter_contandcos.proposalexist=="Yes" &&
      */ if(this.selectedpart_submitter_contandcos_ee.proposallastsaved_ee>=2)
        {
          console.log("inside if condition");

          this.transactionService.loadtab2detailsifproposalexistalready_ee(this.selectedpart_submitter_contandcos_ee.proposalid).subscribe(check=>
          {
            this.existingtab2details_ee=check;

            this.containerdetails_ee.cd_cntr=this.existingtab2details_ee.cd_cntr;
            this.containerdetails_ee.cntr_base=this.existingtab2details_ee.cntr_base;
            this.containerdetails_ee.cntr_suffix=this.existingtab2details_ee.cntr_suff;
            this.containerdetails_ee.cntr_desc=this.existingtab2details_ee.cntr_desc;
            this.containerdetails_ee.cntr_tare_wt=this.existingtab2details_ee.cntr_tar_weight;
            this.containerdetails_ee.exp_refundable=this.existingtab2details_ee.cntr_ret_type;
           
            // Code added to know if the selected container is expendable / returnable
            if(this.containerdetails_ee.exp_refundable != null && this.containerdetails_ee.exp_refundable.toLowerCase() == "returnable")
            {
              this.selected_cont_retype_ee="Returnable"
            }
            else if(this.containerdetails_ee.exp_refundable != null && this.containerdetails_ee.exp_refundable.toLowerCase() == "expendable")
            {
              this.selected_cont_retype_ee="Expendable"
            }
            this.containerdetails_ee.cntr_matr_desc=this.existingtab2details_ee.cntr_material;
            this.containerdetails_ee.cntr_grp_desc=this.existingtab2details_ee.cntr_grp_id;
            this.containerdetails_ee.card_call=this.existingtab2details_ee.card_call;
           // this.selectedcolor.color_desc=this.existingtab2details.cntr_rck_colr;

           for(var color=0; color<this.colorforcntrorrack_ee.length; color++)
           {
              if(this.colorforcntrorrack_ee[color].color_desc==this.existingtab2details_ee.cntr_rck_colr)
              {
                this.selectedcolor_ee=this.colorforcntrorrack_ee[color];
                this.cntrdetforpost_ee.cntr_rck_colr=this.selectedcolor_ee.color_desc;

              }
           }

           this.cntrdetforpost_ee.cntr_material=this.existingtab2details_ee.cntr_material;
           this.containerdetails_ee.cntr_matr_desc=this.existingtab2details_ee.cntr_material;
           console.log("CONT MAT IN EXISTING EE", this.existingtab2details_ee.cntr_material);
           this.getContainerMaterial_ee();

            this.cntrdetforpost_ee.cntr_nsted_fld_height=this.existingtab2details_ee.cntr_nsted_fld_height;
            this.containerdetails_ee.cntr_exterior_l=this.existingtab2details_ee.cntr_ext_len;
            this.containerdetails_ee.cntr_exterior_w=this.existingtab2details_ee.cntr_width;
            this.containerdetails_ee.cntr_exterior_h=this.existingtab2details_ee.cntr_height;
            this.cntrdetforpost_ee.parts_per_cntr=this.existingtab2details_ee.parts_per_cntr;
            this.containerdetails_ee.max_layers_shpg_unit=this.existingtab2details_ee.max_layrs_shp_unt;
            this.containerdetails_ee.cntr_per_layer=this.existingtab2details_ee.cntr_per_layer;
            this.cntrdetforpost_ee.empty_trays_cntr_layrs=this.existingtab2details_ee.empty_trays_cntr_layrs;
            this.selectedEmptyTrays_ee=this.cntrdetforpost_ee.empty_trays_cntr_layrs;
            this.containerdetails_ee.max_cntr_per_shpg_unit=this.existingtab2details_ee.max_cntr_per_shp_unt;
            this.cntrdetforpost_ee.used_carry_ovr_pck=this.existingtab2details_ee.used_carry_ovr_pck;
            this.cntrdetforpost_ee.shp_unt_base=this.existingtab2details_ee.shp_unt_base;
            this.cntrdetforpost_ee.shp_unt_desc=this.existingtab2details_ee.shp_unt_desc;
            this.cntrdetforpost_ee.shp_unt_suff=this.existingtab2details_ee.shp_unt_suff;
            this.containerdetails_ee.shpg_unit_exterior_l=this.existingtab2details_ee.shp_unit_ext_len;
            this.containerdetails_ee.shpg_unit_exterior_w=this.existingtab2details_ee.shp_unt_ext_width;
            this.containerdetails_ee.shpg_unit_exterior_h=this.existingtab2details_ee.shp_unt_ext_height;
            this.cntrdetforpost_ee.shp_unt_ret_type=this.existingtab2details_ee.shp_unt_ret_type;
            this.containerdetails_ee.shpg_unit_nstdorflded_ht=this.existingtab2details_ee.shp_unt_nestd_fld_height;
            this.iscntrselected_ee=true;

            this.cntrdetforpost_ee.id_proposal=this.selectedpart_submitter_contandcos_ee.proposalid;
          this.cntrdetforpost_ee.cntr_ret_type=this.containerdetails_ee.exp_refundable;
          this.cntrdetforpost_ee.cntr_base=this.containerdetails_ee.cntr_base;
          this.cntrdetforpost_ee.cntr_suff=this.containerdetails_ee.cntr_suffix;
          this.cntrdetforpost_ee.cntr_desc=this.containerdetails_ee.cntr_desc;
          this.cntrdetforpost_ee.cntr_ext_len=this.containerdetails_ee.cntr_exterior_l;
          this.cntrdetforpost_ee.cntr_width=this.containerdetails_ee.cntr_exterior_w;
          this.cntrdetforpost_ee.cntr_height=this.containerdetails_ee.cntr_exterior_h;
          // this.cntrdetforpost_ee.cntr_material=this.containerdetails_ee.cntr_matr_desc;
          this.cntrdetforpost_ee.cntr_tar_weight=this.containerdetails_ee.cntr_tare_wt;
          this.cntrdetforpost_ee.cntr_per_layer=this.containerdetails_ee.cntr_per_layer;
          this.cntrdetforpost_ee.max_layrs_shp_unt=this.containerdetails_ee.max_layers_shpg_unit;
          this.cntrdetforpost_ee.max_cntr_per_shp_unt=this.containerdetails_ee.max_cntr_per_shpg_unit;
          this.cntrdetforpost_ee.shp_unt_nestd_fld_height=this.containerdetails_ee.shpg_unit_nstdorflded_ht;
          this.cntrdetforpost_ee.card_call=this.containerdetails_ee.card_call;
          this.cntrdetforpost_ee.cntr_grp_id=this.containerdetails_ee.cntr_grp_desc;
          this.cntrdetforpost_ee.shp_unt_ext_height=this.containerdetails_ee.shpg_unit_exterior_h;
          this.cntrdetforpost_ee.shp_unt_ext_width=this.containerdetails_ee.shpg_unit_exterior_w;
          this.cntrdetforpost_ee.shp_unit_ext_len=this.containerdetails_ee.shpg_unit_exterior_l;
          this.cntrdetforpost_ee.cntr_ownrshp_strat_code=this.existingtab2details_ee.cntr_ownrshp_strat_code;
          this.cntrdetforpost_ee.cd_cntr=this.existingtab2details_ee.cd_cntr;
          this.cntrdetforpost_ee.cd_ownrshp_strat=this.existingtab2details_ee.cd_ownrshp_strat;

          this.savedSuExteriorHeight_ee=this.existingtab2details_ee.shp_unt_ext_height;

          //Fields added
          this.cntrdetforpost_ee.cntrowner=this.existingtab2details_ee.cntrowner;
          this.cntrdetforpost_ee.cntrselfrm=this.existingtab2details_ee.cntrselfrm;
          this.cntrdetforpost_ee.ref_bompartmasterid=this.existingtab2details_ee.ref_bompartmasterid;


          this.cntrdetforpost_ee.pallettype=this.existingtab2details_ee.pallettype;
          if(this.containerdetails_ee.exp_refundable.toLowerCase()=="expendable")
          {
            this.pallet_type_ee=this.cntrdetforpost_ee.pallettype;

          }
          else{
            this.pallet_type_ee="";
          }
          this.cntrdetforpost_ee.woodispmcmplnt=this.existingtab2details_ee.woodispmcmplnt;
          this.cntrdetforpost_ee.offstmpmartprsnt=this.existingtab2details_ee.offstmpmartprsnt;
          this.cntrdetforpost_ee.blanksperstack=this.existingtab2details_ee.blanksperstack;
          this.cntrdetforpost_ee.stacksperpallet=this.existingtab2details_ee.stacksperpallet;
         // this.cntrdetforpost_ee.palletpintyp=this.existingtab2details_ee.palletpintyp;
          this.cntrdetforpost_ee.iscartonimcspec=null;
          this.cntrdetforpost_ee.cartonburststrnth=null;
          this.cntrdetforpost_ee.imcwtdisc=null;


          this.cntrdetforpost_ee.bct=this.existingtab2details_ee.bct;
          this.cntrdetforpost_ee.ect=this.existingtab2details_ee.ect;



          this.transactionService.getActivePinPalletTypeList().subscribe(pin=>{

            this.pinpallettypeList_ee=[];
            this.pinpallettypeList_ee=pin;

            //return this.colorforcntrorrack;
             //this.colorforcntrorrack=colr;
             console.log("Pin Pallet tYpe List",this.pinpallettypeList_ee)

          if(this.pinpallettypeList_ee.length !=0 && this.existingtab2details_ee.palletpintyp != null && this.existingtab2details_ee.palletpintyp != "")
          {
            var pintypeIndex = this.pinpallettypeList_ee.findIndex(pintyp=>pintyp.pinpallettyp.toLowerCase() == this.existingtab2details_ee.palletpintyp.toLowerCase())

            if(pintypeIndex != -1)
            {
              this.selectedPinPalletType_ee=this.pinpallettypeList_ee[pintypeIndex];
              this.cntrdetforpost_ee.palletpintyp= this.selectedPinPalletType_ee.pinpallettyp;
            }
            else
            {
              this.selectedPinPalletType_ee={cd_pinpallettyp:0,pinpallettyp:"",pinpallettyp_desc:"",active_stat:"",updated_by:"",updated_dt:"",active_stat_display:false};
            }

          }
          else
            {
              this.selectedPinPalletType_ee={cd_pinpallettyp:0,pinpallettyp:"",pinpallettyp_desc:"",active_stat:"",updated_by:"",updated_dt:"",active_stat_display:false};
            }

          })

          //this.selected_container_cos=null;
          this.originalTab2Details_ee=JSON.parse(JSON.stringify(this.cntrdetforpost_ee));

          if(this.cntrdetforpost_ee.cntrselfrm=='copycntr')
          {
            if(sessionStorage.getItem(String(this.selectedpart_submitter_contandcos_ee.proposalid))!=null)
            {
              sessionStorage.removeItem(String(this.selectedpart_submitter_contandcos_ee.proposalid))
            }
            //Remove the previous lines and add code to set the session storage for the bompartmasterid they have selected for copy

            this.enable_cntr_selectn_from_copy_cntr_dilg_notice_ee=true;
            this.selected_container_cos_ee=null;
            this.selected_container_ee=null;
          }
          else
          {
            this.enable_cntr_selectn_from_copy_cntr_dilg_notice_ee=false;


            this.transactionService.getOwnershipStrategyCode(this.current_region_code_contandcos_ee).subscribe(cntrownrshipcode=>{
              this.coscodes_ee=cntrownrshipcode;
              console.log("coscodes",this.coscodes_ee);

              for(var l=0; l<this.coscodes_ee.length; l++)
              {
                if(this.coscodes_ee[l].ownrshp_strat_code==this.existingtab2details_ee.cntr_ownrshp_strat_code)
                {
                  this.selected_container_cos_ee=this.coscodes_ee[l];
                  console.log("selected_container_cos",this.selected_container_cos_ee);
                }
              }
            });

          }

            console.log("selected_container_cos",this.selected_container_cos_ee);
            console.log("existingtab2details",this.existingtab2details_ee);
         });
        }
     // });

  }

  cntrdetailsinit_ee()
  {
    this.containerdetails_ee=
    {
        cd_cntr:0,
        cntr_suff_ind:"",
        exp_refundable:"",
        cntr_active_ind:"",
        cntr_base:"",
        cntr_suffix:"",
        cntr_desc:"",
        card_call:"",
        cntr_mstr_catg:"",
        cntr_typ_desc:"",
        ownrshp_strat_code:"",
        ownrshp_catg:"",
        cntr_grp_desc:"",
        cntr_exterior_l:0,
	      cntr_exterior_w:0,
	      cntr_exterior_h:0,
        collapsed_ht:0.0,
        nest_ratio:0,
        cntr_interior_l:0,
        cntr_interior_w:0,
        cntr_interior_h:0,
        cntr_tare_wt:0,
        cntr_capacity:0,
        cntr_per_layer:0,
        max_layers_shpg_unit:0,
        max_cntr_per_shpg_unit:0,
        cntr_matr_desc:"",
        cntr_drawing_no:"",
        shpg_unit_exterior_l:0,
        shpg_unit_exterior_w:0,
        shpg_unit_exterior_h:0,
        shpg_unit_nstdorflded_ht:0,
        shpg_unit_tare_wt:0,
        shpg_unit_stack_ht:0,
        warehouse_stack_ht:0,
        service_life:"",
        currency_desc:"",
        purchase_cost:0,
        rental_issue_cost:0,
        sup_rntl_dly_cost:0,
        ford_rntl_dly_cost:0,
        return_rntl_cost:0,
        tooling_dev_cost:0,
        extr_cntr_dsgn_img_ind:"",
        intr_cntr_dsgn_img_ind:"",
        shpg_unit_img_ind:"",
        cad_img_ind:"",
        img_notes:"",
        cd_region:"",
        iscontainercolapsble:"",
        cd_plant: "",
        cnt_qt_per_min_su: 0,
        nest_ratio_1: 0,
        ford_cntct: "",
        cntct_phone: "",
        req_stat: "",
        id_user: "",
        ts_last_update: "",
        uom:"E",
        typ_no:0
      }
  }

  cntrdetforpostinit_ee()
  {
    this.cntrdetforpost_ee={
      id_proposal:0,
      cntr_ret_type:"",
      used_carry_ovr_pck:"No",
      cntr_base:"",
      cntr_suff:"",
      cntr_desc:"",
      parts_per_cntr:0,
      cntr_ext_len:0,
      cntr_width:0,
      cntr_height:0,
      cntr_nsted_fld_height:0,
      cntr_material:"",
      cntr_tar_weight:0,
      cntr_per_layer:0,
      max_layrs_shp_unt:0,
      empty_trays_cntr_layrs:0,
      max_cntr_per_shp_unt:0,
      cntr_rck_colr:"",
      shp_unt_base:"",
      shp_unt_suff:"",
      shp_unt_desc:"",
      part_per_shp_unit:0,
      shp_unt_ret_type:"",
      shp_unit_ext_len:0,
      shp_unt_ext_width:0,
      shp_unt_ext_height:0,
      shp_unt_nestd_fld_height:0,
      card_call:"",
      cntr_grp_id:"",
      cntr_ownrshp_strat_code:"",
      cd_cntr:0,
      cd_ownrshp_strat:0,
      pallettype: "",
      woodispmcmplnt: "No",
      offstmpmartprsnt: "No",
      blanksperstack: 0,
      stacksperpallet: 0,
      palletpintyp: "",
      iscartonimcspec: null,
      cartonburststrnth:null,
      imcwtdisc: null,
      cntrowner:"",
      cntrselfrm:"",
      ref_bompartmasterid:0,
      bct:null,
      ect:null
    }
  }

  cntrdetails_display_popup_init_ee()
  {


    this.cntrdetails_display_ee=
    {
        cd_cntr:0,
        cntr_suff_ind:"",
        exp_refundable:"",
        cntr_active_ind:"",
        cntr_base:"",
        cntr_suffix:"",
        cntr_desc:"",
        card_call:"",
        cntr_mstr_catg:"",
        cntr_typ_desc:"",
        ownrshp_strat_code:"",
        ownrshp_catg:"",
        cntr_grp_desc:"",
        cntr_exterior_l:0,
	      cntr_exterior_w:0,
	      cntr_exterior_h:0,
        collapsed_ht:0.0,
        nest_ratio:0,
        cntr_interior_l:0,
        cntr_interior_w:0,
        cntr_interior_h:0,
        cntr_tare_wt:0,
        cntr_capacity:0,
        cntr_per_layer:0,
        max_layers_shpg_unit:0,
        max_cntr_per_shpg_unit:0,
        cntr_matr_desc:"",
        cntr_drawing_no:"",
        shpg_unit_exterior_l:0,
        shpg_unit_exterior_w:0,
        shpg_unit_exterior_h:0,
        shpg_unit_nstdorflded_ht:0,
        shpg_unit_tare_wt:0,
        shpg_unit_stack_ht:0,
        warehouse_stack_ht:0,
        service_life:"",
        currency_desc:"",
        purchase_cost:0,
        rental_issue_cost:0,
        sup_rntl_dly_cost:0,
        ford_rntl_dly_cost:0,
        return_rntl_cost:0,
        tooling_dev_cost:0,
        extr_cntr_dsgn_img_ind:"",
        intr_cntr_dsgn_img_ind:"",
        shpg_unit_img_ind:"",
        cad_img_ind:"",
        img_notes:"",
        cd_region:"",
        iscontainercolapsble:"",
        cd_plant: "",
        cnt_qt_per_min_su: 0,
        nest_ratio_1: 0,
        ford_cntct: "",
        cntct_phone: "",
        req_stat: "",
        id_user: "",
        ts_last_update: "",
        uom:"E",
        typ_no:0
      }

  }

  getactivecolorforregion_ee()
  {
    this.transactionService.get_region_color_details(this.current_region_code_contandcos_ee).subscribe(colr=>{
     /* for(let v of colr.values())
      {
        if(v.active_stat=="Yes")
        {
          this.colorforcntrorrack.push(v);
        }
     }*/

     this.colorforcntrorrack_ee=colr.filter(colrs=>colrs.active_stat.toLowerCase()=="yes");
     this.selectedcolor_ee=this.colorforcntrorrack_ee[0];

     //return this.colorforcntrorrack;
      //this.colorforcntrorrack=colr;
      console.log("colors for rack",this.colorforcntrorrack_ee)
    })

  }



  getActivePinPalletType_ee()
  {

    this.transactionService.getActivePinPalletTypeList().subscribe(pin=>{

      this.pinpallettypeList_ee=[];
      this.pinpallettypeList_ee=pin;

      //return this.colorforcntrorrack;
       //this.colorforcntrorrack=colr;
       console.log("Pin Pallet tYpe List",this.pinpallettypeList_ee)
     })

  }


  ok_noroledialog_ee()
  {
    var url
    url=this.transactionService.getregionurlvalue(this.current_region_contandcos_ee);
    url+="packagingprocessdashboard"
    this.router.navigate([url]);

  }




    //if no supplier present

    ok_nosupdialog_ee() {
      console.log(this.current_region_contandcos_ee)
      var reg_url: string
      reg_url = this.transactionService.getregionurlvalue(this.current_region_contandcos_ee);
      reg_url += "packagingprocess/packsupplieradmin/packsupplier";

      this.router.navigate([reg_url]);

    }


    ok_noDesignsourcedialog_ee() {
      console.log(this.current_region_contandcos_ee)
      var reg_url: string
      reg_url = this.transactionService.getregionurlvalue(this.current_region_contandcos_ee);
      reg_url += "packagingprocess/ppackdesignsourceadmin/packdesignsource";

      this.router.navigate([reg_url]);

    }



    go_to_supp_main_page_ee() {
      var reg_supp_url: string;
      reg_supp_url = this.transactionService.getregionurlvalue(this.current_region_contandcos_ee);
      if(this.roleid_contandcos_ee==1)
      {
        reg_supp_url += "packagingprocess/packsupplieradmin/packsupplier";
      }
      else if ( this.roleid_contandcos_ee==2)
      {
        reg_supp_url += "packagingprocess/packdesignsourceadmin/packdesignsource";
      }
      this.router.navigate([reg_supp_url]);

    }

    //if no part present
    ok_noprtdialog_ee() {
      console.log(this.current_region_contandcos_ee)

      var reg_url_prt: string
      reg_url_prt = this.transactionService.getregionurlvalue(this.current_region_contandcos_ee);
      this.name_ee=this.transactionService.getregionNameInSmallLetters();
      var proposalFor=sessionStorage.getItem("proposaltypefor");
      if(proposalFor!=null && proposalFor != "null" && proposalFor.toLowerCase()=="migration")
  {
    if(this.roleid_contandcos_ee==1)
    {
      this.router.navigate(['/'+this.name_ee+'/packagingprocess/packsupplieradmin/migrationsubmitter'])
    }
    else if(this.roleid_contandcos_ee==2)
    {
      this.router.navigate(['/'+this.name_ee+'/packagingprocess/packdesignsourceadmin/migrationsubmitter'])
    }
  }
  else //if(this.selectedpart_submitter.propsl_type!=null && this.selectedpart_submitter.propsl_type.toLowerCase()=="normal")
  {
      if(this.roleid_contandcos_ee==1)
      {
      reg_url_prt += "packagingprocess/packsupplieradmin/submiterdashboard";
      }
      else if ( this.roleid_contandcos_ee==2)
      {
        reg_url_prt += "packagingprocess/packdesignsourceadmin/submiterdashboard";
      }

      this.router.navigate([reg_url_prt]);
    }

    }




  //in main page click of select button
    select_contcos_button_ee()
    {

      this.select_contcos_dialog_ee=true;
      this.time_ee="first"
      this.dropdown_time_ee="first";




      var primary_partid:string;
      this.transactionService.get_draft_selectedparts_data(this.selectedpart_submitter_contandcos_ee.proposalid).subscribe(list_of_parts=>{

        console.log("list of parts",list_of_parts);

        if(list_of_parts != null && list_of_parts.length !=0)
        {
          var index_primary=list_of_parts.findIndex(eleprt=>eleprt.isprimary.toLowerCase() == 'yes')
          if(index_primary != -1)
          {
            primary_partid=list_of_parts[index_primary].bompartmasterid.toString();
          }
          else
          {
            primary_partid =this.selectedpart_submitter_contandcos_ee.bompartmasterid .toString();
          }
        }
        else
        {
          primary_partid =this.selectedpart_submitter_contandcos_ee.bompartmasterid .toString();
        }
      this.transactionService.getSelectedPartsDetails(primary_partid).subscribe(ppartdet=>{
        console.log(ppartdet);
        console.log(ppartdet[0].cntrsuggestedownershpstrat);
        this.cos_from_pfep_ee=ppartdet[0].cntrsuggestedownershpstrat;


       // this.deviate_from_pfep=false;

      this.transactionService.getOwnershipStrategyCode(this.current_region_code_contandcos_ee).subscribe(cntrownrshipcode=>{
       // this.cntrOwnershipstratcodelist=cntrownrshipcode;
       console.log(cntrownrshipcode)
       this.cntrOwnershipstratcodelist_ee=[];
       this.cntrOwnershipstratcodelist_ee.push({cd_region:this.current_region_code_contandcos_ee,ownrshp_strat_code:"Sel",ownrshp_desc:"Select a COS from List",cd_ownrshp_strat:0});

       for(var ilist=0;ilist<cntrownrshipcode.length;ilist++)
       {


        this.cntrOwnershipstratcodelist_ee.push(cntrownrshipcode[ilist]);

       }





        console.log(this.cntrOwnershipstratcodelist_ee);
        //only if container already doesnt exist
        //if(this.selected_cntr_ownrship_strat_code==null && this.selected_container==null)
        if(this.selected_container_cos_ee==null)
      {
        if(this.cos_from_pfep_ee!=null)
        {
          console.log(this.cos_from_pfep_ee);
          var cos_present=false;
          var cos_index;
          for(var icos=0; icos<this.cntrOwnershipstratcodelist_ee.length; icos++)
          {
            if(this.cntrOwnershipstratcodelist_ee[icos].ownrshp_strat_code==this.cos_from_pfep_ee)
            {
              cos_present=true;
              cos_index=icos;
              break;

            }

          }
          if(cos_present==true)
          {
            this.selected_cntr_ownrship_strat_code_ee=this.cntrOwnershipstratcodelist_ee[cos_index];
            this.exsisting_value_from_pfep_ee=this.selected_cntr_ownrship_strat_code_ee;
            this.deviate_from_pfep_ee=false;
            this.on_change_deviate_ee();

          }

          else{


           // this.nocosdialog=true;
            this.content_cos_contandcos_ee="COS Not prsent,Please add cos in COS Admin"
            this.deviate_from_pfep_ee=true;
        this.selected_cntr_ownrship_strat_code_ee=this.cntrOwnershipstratcodelist_ee[0];
        this.exsisting_value_from_pfep_ee=this.selected_cntr_ownrship_strat_code_ee;
        this.disable_deviate_ee=true;
        this.on_change_deviate_ee();

          }


          console.log(this.deviate_from_pfep_ee)

        }
        else{
          this.deviate_from_pfep_ee=true;
        this.selected_cntr_ownrship_strat_code_ee=this.cntrOwnershipstratcodelist_ee[0];
        this.exsisting_value_from_pfep_ee=this.selected_cntr_ownrship_strat_code_ee
        this.disable_deviate_ee=true;
        this.on_change_deviate_ee();
        }


      }
      //Get details TAB 2
//for get details api if implementated in ng on init
//inside the below else ---assign the selected cos to the selected_container_cos by comparing the cos we got from get details with cos List only when getdetails is present and firsttime
//and check if the cos is same as from pfep then make selected_container_deviate_from_pfep true or false
//assign the selected container by comparing with table in cos on_dropdown_Change function and assign it to table_selected_container
//if the container ownership already exist


      else
      {

        console.log("entered else part in 664");
        console.log(this.cntrOwnershipstratcodelist_ee);
        //console.log("this.existingtab2details.cntr_ownrshp_strat_code",this.existingtab2details.cntr_ownrshp_strat_code);
        var cos_present=false;
        var pfep_cos_present=false;

        console.log("cos_from_pfep",this.cos_from_pfep_ee);
        if(this.cos_from_pfep_ee!=null)
        {
          for(var h=0; h<this.cntrOwnershipstratcodelist_ee.length; h++)
          {
            if(this.cntrOwnershipstratcodelist_ee[h].ownrshp_strat_code.toLowerCase()==this.cos_from_pfep_ee.toLowerCase())
            {
              var pfep_cos_present=true;
              this.exsisting_value_from_pfep_ee=this.cntrOwnershipstratcodelist_ee[h];
            }
  
          }
        }

        if(pfep_cos_present==false)
        {
          this.exsisting_value_from_pfep_ee=this.cntrOwnershipstratcodelist_ee[0];
        }

/*
        for(var k=0; k<this.cntrOwnershipstratcodelist_ee.length; k++)
        {
          console.log(this.cntrOwnershipstratcodelist_ee[k]);
          if(this.time_ee=="first" && this.existingtab2details_ee!=null)
          {
          if(this.cntrOwnershipstratcodelist_ee[k].ownrshp_strat_code.toLowerCase()==this.existingtab2details_ee.cntr_ownrshp_strat_code.toLowerCase())
          {

              console.log("entered in 695");
              this.selected_container_cos_ee=this.cntrOwnershipstratcodelist_ee[k];
              //this.exsisting_value_from_pfep=this.selected_container_cos;
              cos_present=true;
               console.log("exsisting_value_from_pfep",this.exsisting_value_from_pfep_ee);
           }
          }
        }

*/
            console.log("exsisting_value_from_pfep in line 705",this.exsisting_value_from_pfep_ee);



          if(this.exsisting_value_from_pfep_ee==this.selected_container_cos_ee)
          {
          this.selected_container_deviate_from_pfep_ee=false;
          }
            else{
              console.log(this.exsisting_value_from_pfep_ee);
              this.selected_container_deviate_from_pfep_ee=true;

            }

        console.log(this.exsisting_value_from_pfep_ee);
        console.log("selected_container_cos in line 725",this.selected_container_cos_ee)
        this.selected_cntr_ownrship_strat_code_ee= this.selected_container_cos_ee
        this.deviate_from_pfep_ee=this.selected_container_deviate_from_pfep_ee
        console.log(this.selected_cntr_ownrship_strat_code_ee);
        console.log("executed line 729");
        this.cont_retype=this.selected_cont_retype_ee;

        this.on_change_deviate_ee();

      }

    });


      })
    })






    }



  //on chagne of check box
    on_change_deviate_ee()
    {
      console.log("deviate_from_pfep",this.deviate_from_pfep_ee)



    if(this.deviate_from_pfep_ee==true)
    {
      this.disable_cos_dropdown_ee=false;
      //when value is sel for it only first time call dropdown
      if(this.time_ee=="first")
  {
    this.time_ee="second";

   this.on_change_cos_dropdown_ee();
  }
    }
    else{
      //each time deviate is made false assign the cos from pfep and load the table
      this.disable_cos_dropdown_ee=true;
      this.time_ee="second"
      this.selected_cntr_ownrship_strat_code_ee=this.exsisting_value_from_pfep_ee;
      console.log("exsisting_value_from_pfep",this.exsisting_value_from_pfep_ee);
      console.log("selected_cntr_ownrshp_code",this.selected_cntr_ownrship_strat_code_ee);
      this.cntrdetforpost_ee.cntr_ownrshp_strat_code=this.selected_cntr_ownrship_strat_code_ee.ownrshp_strat_code;
      this.on_change_cos_dropdown_ee();

    }



    }






  //on change of dropdown value
    on_change_cos_dropdown_ee()
    {



      console.log(this.selected_cntr_ownrship_strat_code_ee);
      console.log(this.current_region_code_contandcos_ee);


      var supplier_gsdb_code=this.selectedpart_submitter_contandcos_ee.supplier_code



     //onchange of value in dropdown to value to cos from pfep disable drop down
      if(this.selected_cntr_ownrship_strat_code_ee==this.exsisting_value_from_pfep_ee && this.exsisting_value_from_pfep_ee.ownrshp_strat_code!="Sel")
      {
        this.deviate_from_pfep_ee=false;
        this.disable_cos_dropdown_ee=true;
      }






//each time change of selection clear the selected value
      this.selected_container_ee=null

//only first time opening of dialog , assign already selected container

     if(this.dropdown_time_ee=="first")
      {
        this.dropdown_time_ee="second";


        console.log("selectedcontainer")
        console.log(this.table_selected_container_ee)
        console.log(this.selected_container_ee)
        this.selected_container_ee=this.table_selected_container_ee
      }





      //service call to get table data passing cos and regioncode


      // Modilfied API from getcontoncoslist_ee to getcontoncoslist

this.transactionService.getcontoncoslist(this.selected_cntr_ownrship_strat_code_ee.ownrshp_strat_code,this.current_region_code_contandcos_ee,this.cont_retype,supplier_gsdb_code).subscribe((contlist => {

  this.contlist_oncos_ee=contlist

  //Get details TAB2
  //only wen the details exist and first time load of page compare the contlist_oncos with the container base from the get details and assign it to selected_container and table_selected_container
  //code here ...

 //this.suppcode=[];
/* if(this.suppget.length==0)
 {
   this.valueback=true;
 }
 else{
 this.valueback=false;
}*/



console.log("successful",this.contlist_oncos_ee);



if(this.contlist_oncos_ee.length!=0 && this.contlist_oncos_ee!=null)
{


  this.cont_grid_text_ee="";
  this.cont_present_table_ee=true;
  for(var i=0; i<this.contlist_oncos_ee.length; i++)
  {

    if(this.contlist_oncos_ee[i].cntr_suffix!=null)
    {
    this.contlist_oncos_ee[i].cntr_base_suffix=this.contlist_oncos_ee[i].cntr_base+" - "+this.contlist_oncos_ee[i].cntr_suffix
    }
    else{
      this.contlist_oncos_ee[i].cntr_base_suffix=this.contlist_oncos_ee[i].cntr_base
    }
    var length="";
    var width="";
    var height="";
    if(this.contlist_oncos_ee[i].cntr_exterior_l!=null)
    {
      length=this.contlist_oncos_ee[i].cntr_exterior_l.toString();
    }
    if(this.contlist_oncos_ee[i].cntr_exterior_w!=null)
    {
      width=this.contlist_oncos_ee[i].cntr_exterior_w.toString();
    }
    if(this.contlist_oncos_ee[i].cntr_exterior_l!=null)
    {
      height=this.contlist_oncos_ee[i].cntr_exterior_h.toString();
    }

    this.contlist_oncos_ee[i].external_l_w_h=length+"x"+width+"x"+height;

console.log("oppppp",this.containerdetails_ee);
    //if(this.contlist_oncos[i].cntr_base==this.containerdetails.cntr_base)
    if(this.contlist_oncos_ee[i].cd_cntr==this.containerdetails_ee.cd_cntr && this.enable_cntr_selectn_from_copy_cntr_dilg_notice_ee==false)
    {
      this.table_selected_container_ee=this.contlist_oncos_ee[i];
      this.selected_container_ee=this.contlist_oncos_ee[i];
    }
  }

this.cont_cols_ee = [
  { field: 'cntr_base_suffix', header:'Container Base - Suffix'},
  {field:'cntr_desc',header:'Container Description'},
  { field: 'exp_refundable', header:'E/R'},
  { field: 'external_l_w_h', header:'External LxWxH'},
  { field: 'card_call', header:'Card or Call'},
  { field: 'cntr_grp_desc', header:'Container Group ID'},

];
}


else{
  this.cont_present_table_ee=false;
  this.cont_cols_ee=[];
  this.cont_grid_text_ee="No Container Found"
}

}

 ))



    }

/*
//each time when the value is selected store its cos , so used while reloading
    assign_selected_container_cos()
    {
      on-click="assign_selected_container_cos()" --- html code in p-table radio button
      console.log("insidetest");
      this.selected_container_cos=this.selected_cntr_ownrship_strat_code;
      this.selected_container_deviate_from_pfep=this.deviate_from_pfep;
    }
    */

  //clicking ok in dialog after seleing container
    onclick_ok_select_contandcos_ee()
    {
      console.log("selected_container",this.selected_container_ee);
      this.select_contcos_dialog_ee=false;
      this.enable_cntr_selectn_from_copy_cntr_dilg_notice_ee=false;


      if(sessionStorage.getItem(String(this.selectedpart_submitter_contandcos_ee.proposalid))!=null)
    {

      this.enable_cntr_selectn_from_copy_cntr_dilg_notice_ee=false;
      sessionStorage.removeItem(String(this.selectedpart_submitter_contandcos_ee.proposalid))
      this.cntrdetforpost_ee.parts_per_cntr=0;
      this.cntrdetforpost_ee.part_per_shp_unit=0;
      this.cntrdetforpost_ee.empty_trays_cntr_layrs=0;
      this.cntrdetforpost_ee.used_carry_ovr_pck="No";
      this.cntrdetforpost_ee.max_layrs_shp_unt=0;
      this.selectedEmptyTrays_ee=0;
      this.cntrdetforpost_ee.cntr_per_layer=0;
      this.cntrdetforpost_ee.max_cntr_per_shp_unt=0;

      this.getactivecolorforregion_ee();
      this.getActivePinPalletType_ee();

      this.cntrdetforpost_ee.cntr_material=this.selected_cntr_mat_ee.cntr_matr_desc;
      console.log("Material in onclick_changeCOS",this.cntrdetforpost_ee.cntr_material);
      this.cntrdetforpost_ee.cntr_rck_colr=this.selectedcolor_ee.color_desc;
      this.cntrdetforpost_ee.pallettype="";
     
      this.cntrdetforpost_ee.blanksperstack=0,
      this.cntrdetforpost_ee.stacksperpallet=0,
      this.cntrdetforpost_ee.palletpintyp="",
      this.cntrdetforpost_ee.iscartonimcspec=null,
      this.cntrdetforpost_ee.cartonburststrnth=null,
      this.cntrdetforpost_ee.imcwtdisc=null,
      this.cntrdetforpost_ee.shp_unt_desc="",

      this.cntrdetforpost_ee.bct=null;
      this.cntrdetforpost_ee.ect=null;


      this.selectedPinPalletType_ee={cd_pinpallettyp:0,pinpallettyp:"",pinpallettyp_desc:"",active_stat:"",updated_by:"",updated_dt:"",active_stat_display:false};


    }
      this.cntrdetforpost_ee.cntrselfrm="cntradm";
     var prev=this.table_selected_container_ee;
      this.table_selected_container_ee=this.selected_container_ee;
      if(this.table_selected_container_ee!=prev && prev!=null)
      {
        this.cntrdetforpost_ee.parts_per_cntr=0;
       this.cntrdetforpost_ee.part_per_shp_unit=0;
       this.cntrdetforpost_ee.empty_trays_cntr_layrs=0;
       this.selectedEmptyTrays_ee=0;
       this.cntrdetforpost_ee.used_carry_ovr_pck="No";
      this.cntrdetforpost_ee.max_layrs_shp_unt=0;
      this.cntrdetforpost_ee.cntr_per_layer=0;
      this.cntrdetforpost_ee.max_cntr_per_shp_unt=0;

       this.getactivecolorforregion_ee();
       this.getActivePinPalletType_ee();

       this.cntrdetforpost_ee.cntr_material=this.selected_cntr_mat_ee.cntr_matr_desc;
       console.log("Material in onclick_changeCOS2",this.cntrdetforpost_ee.cntr_material,this.selected_cntr_mat_ee);
       this.cntrdetforpost_ee.cntr_rck_colr=this.selectedcolor_ee.color_desc;
       this.cntrdetforpost_ee.pallettype="";
      
       this.cntrdetforpost_ee.blanksperstack=0,
       this.cntrdetforpost_ee.stacksperpallet=0,
       this.cntrdetforpost_ee.palletpintyp="",
       this.cntrdetforpost_ee.iscartonimcspec=null,
       this.cntrdetforpost_ee.cartonburststrnth=null,
       this.cntrdetforpost_ee.imcwtdisc=null,
       this.cntrdetforpost_ee.shp_unt_desc="",
       this.cntrdetforpost_ee.bct=null;
       this.cntrdetforpost_ee.ect=null;


       this.selectedPinPalletType_ee={cd_pinpallettyp:0,pinpallettyp:"",pinpallettyp_desc:"",active_stat:"",updated_by:"",updated_dt:"",active_stat_display:false};
       this.selected_cntr_mat_ee={cntr_matr_desc:"",cntr_matr_name:"",comp_type_flag:""}

      }
      this.selected_container_cos_ee=this.selected_cntr_ownrship_strat_code_ee;
      this.selected_container_deviate_from_pfep_ee=this.deviate_from_pfep_ee;
      this.selected_cont_retype_ee=this.cont_retype

      if(this.table_selected_container_ee != null )
      {
        // if((prev!=null && this.table_selected_container_ee!=prev) || prev == null )
        //  {

          if(this.table_selected_container_ee.exp_refundable.toLowerCase() == "expendable")
          {
            this.cntrdetforpost_ee.woodispmcmplnt = "Yes";
            this.cntrdetforpost_ee.offstmpmartprsnt = "Yes";
          }

        console.log(this.table_selected_container_ee.cntr_base,this.table_selected_container_ee.cd_cntr);
        this.transactionService.editbutton_get_contr_details(this.table_selected_container_ee.cntr_base,this.table_selected_container_ee.cd_cntr).subscribe(ret=>{
          this.containerdetails_ee=ret;
          this.iscntrselected_ee=true;
          console.log("cntrdetails",this.containerdetails_ee);
          this.cntrdetforpost_ee.id_proposal=this.id_proposal_contandcos_ee;
          this.cntrdetforpost_ee.cntr_ret_type=this.containerdetails_ee.exp_refundable;
          this.cntrdetforpost_ee.cntr_base=this.containerdetails_ee.cntr_base;
          this.cntrdetforpost_ee.cntr_suff=this.containerdetails_ee.cntr_suffix;
          this.cntrdetforpost_ee.cntr_desc=this.containerdetails_ee.cntr_desc;
          this.cntrdetforpost_ee.cntr_ext_len=this.containerdetails_ee.cntr_exterior_l;
          this.cntrdetforpost_ee.cntr_width=this.containerdetails_ee.cntr_exterior_w;
          this.cntrdetforpost_ee.cntr_height=this.containerdetails_ee.cntr_exterior_h;
          this.cntrdetforpost_ee.cntr_material=this.containerdetails_ee.cntr_matr_desc;
          console.log("material desc",this.cntrdetforpost_ee.cntr_material)
          this.getContainerMaterial_ee();
          this.cntrdetforpost_ee.cntr_tar_weight=this.containerdetails_ee.cntr_tare_wt;
          this.cntrdetforpost_ee.cntr_per_layer=this.containerdetails_ee.cntr_per_layer;
          this.cntrdetforpost_ee.max_layrs_shp_unt=this.containerdetails_ee.max_layers_shpg_unit;
          this.cntrdetforpost_ee.max_cntr_per_shp_unt=this.containerdetails_ee.max_cntr_per_shpg_unit;
          this.cntrdetforpost_ee.shp_unt_ext_height=this.containerdetails_ee.shpg_unit_exterior_h;
          this.cntrdetforpost_ee.shp_unit_ext_len=this.containerdetails_ee.shpg_unit_exterior_l;
          this.cntrdetforpost_ee.shp_unt_ext_width=this.containerdetails_ee.shpg_unit_exterior_w;
          this.cntrdetforpost_ee.shp_unt_nestd_fld_height=this.containerdetails_ee.shpg_unit_nstdorflded_ht;
          this.cntrdetforpost_ee.card_call=this.containerdetails_ee.card_call;
          this.cntrdetforpost_ee.cntr_grp_id=this.containerdetails_ee.cntr_grp_desc;
          this.cntrdetforpost_ee.cntr_ownrshp_strat_code=this.selected_container_cos_ee.ownrshp_strat_code;
          this.cntrdetforpost_ee.cntrowner=this.containerdetails_ee.ownrshp_catg;
          this.cntrdetforpost_ee.ref_bompartmasterid=null;

          this.cntrdetforpost_ee.cd_ownrshp_strat=this.selected_container_cos_ee.cd_ownrshp_strat;
          this.cntrdetforpost_ee.cd_cntr=this.containerdetails_ee.cd_cntr;
          this.cntrdetforpost_ee.cntr_rck_colr=this.selectedcolor_ee.color_desc;


          this.cntrdetforpost_ee.cntr_nsted_fld_height=this.containerdetails_ee.collapsed_ht;
          this.cntrdetforpost_ee.shp_unt_base=this.containerdetails_ee.cntr_base;
          this.cntrdetforpost_ee.shp_unt_suff=this.containerdetails_ee.cntr_suffix;

          this.selectedEmptyTrays_ee=0;
          this.cntrdetforpost_ee.empty_trays_cntr_layrs=0;
          if(this.packagingproposal.savedtab<2)
          {
          this.cntrdetforpost_ee.part_per_shp_unit=0;
          this.cntrdetforpost_ee.parts_per_cntr=0;

          }
          this.cntrdetforpost_ee.shp_unt_ret_type=this.containerdetails_ee.exp_refundable;

          if(this.selected_cntr_mat_ee.cntr_matr_desc == "Corrugated Cardboard")
          {
            this.cntrColorRackDisable_ee = true;
            this.selectedcolor_ee.color_desc = "NA";
            console.log("Mat for disable - onclick", this.selected_cntr_mat_ee);
          }
          else
          {
            this.cntrColorRackDisable_ee = false;
          }

          console.log("selected ownership",this.cntrdetforpost_ee.cntr_ownrshp_strat_code);
          console.log("partspersu",this.cntrdetforpost_ee.part_per_shp_unit);
          console.log("cntrdetpost-cd_cntr",this.cntrdetforpost_ee.cd_cntr);
          console.log("cntrdetforpost-cd_ownsershipstrat",this.cntrdetforpost_ee.cd_ownrshp_strat);
        })
      // }
    }
      else{

        this.iscntrselected_ee=false;
        this.cntrdetailsinit_ee();
        this.cntrdetforpostinit_ee();
      }

      // call function to get cd_cntr using table_selected_container
    }

  //cancel in dialog
    onclick_cancel_select_contandcos_ee()
    {
      this.select_contcos_dialog_ee=false;
      if(this.table_selected_container_ee!=undefined && this.iscntrselected_ee==false)
      {
      this.iscntrselected_ee=false;
      this.cntrdetailsinit_ee();
      this.cntrdetforpostinit_ee();
      }
      console.log("seleted container in cancel",this.table_selected_container_ee);
    }


    store_primary_part_exist_ee()
    {

        if(this.current_region_code_prt_ee=="NA")
        {

          sessionStorage.setItem('napackpropart', JSON.stringify(this.selectedpart_submitter_contandcos_ee));
        }


        if(this.current_region_code_prt_ee=="EU")
        {
          sessionStorage.setItem('eupackpropart', JSON.stringify(this.selectedpart_submitter_contandcos_ee));
        }



        if(this.current_region_code_prt_ee=="AP")
        {
          sessionStorage.setItem('appackpropart', JSON.stringify(this.selectedpart_submitter_contandcos_ee));
        }



        if(this.current_region_code_prt_ee=="SA")
        {
          sessionStorage.setItem('sapackpropart', JSON.stringify(this.selectedpart_submitter_contandcos_ee));
        }
    }

    colorchanged_ee()
    {
      this.cntrdetforpost_ee.cntr_rck_colr=this.selectedcolor_ee.color_desc;
    }

    on_cntr_mat_change_ee()
    {
      this.cntrdetforpost_ee.cntr_material=this.selected_cntr_mat_ee.cntr_matr_desc;
      console.log("Container Mat on click EE",this.cntrdetforpost_ee.cntr_material)
      if(this.selected_cntr_mat_ee.cntr_matr_desc == "Corrugated Cardboard")
      {
        this.cntrColorRackDisable_ee = true;
        this.selectedcolor_ee.color_desc = "NA";
        console.log("Mat for disable - onchnagw", this.selected_cntr_mat_ee);
      }
      else
      {
        this.cntrColorRackDisable_ee = false;
      }
    }


    tab2InValidationInit_ee()
    {
      this.contextlenInvalid_ee=false;
      this.contextlenInValidReason_ee=null;
      this.contextwidInvalid_ee=false;
      this.contextwidInValidReason_ee=null;
      this.contexthgtInvalid_ee=false;
      this.contexthgtInValidReason_ee=null;
      this.contarewgtInvalid_ee=false;
      this.contarewgtInValidReason_ee=null;
      this.contNestedHtInValid_ee=false;
      this.contNestedHtInValidReason_ee=null;
      this.partsprcntrInValid_ee=false;
      this.partsprcntrInValidReason_ee=null;
      this.maxlayerprshpInValid_ee=false;
      this.maxlayerprshpInValidReason_ee=null;
      this.cntrperlaysInValid_ee=false;
      this.cntrperlaysInValidReason_ee=null;
      this.suextlenInvalid_ee=false;
      this.suextlenInValidReason_ee=null;
      this.suextwidInvalid_ee=false;
      this.suextwidInValidReason_ee=null;
      this.suexthgtInvalid_ee=false;
      this.suexthgtInValidReason_ee=null;
      this.suNestedHtInValid_ee=false;
      this.suNestedHtInValidReason_ee=null;
    }

    on_change_measure_contcos_ee()
    {

      if(this.unitom_ee=="E")
      {
        this.get_measure_ee = "lbs/inch";
        this.edit_measure_contcos_ee = "lbs/inch";
        this.disp_lbs = true;
      }
      else
      {
        this.get_measure_ee = "kg/cm";
        this.edit_measure_contcos_ee = "kg/cm";
        this.disp_kg = true;
      }

      console.log(this.edit_measure_contcos_ee);
      console.log(this.containerdetails_ee);


      // if(this.edit_measure_contcos_ee=="lbs/inch")
      // {
      //   if(this.containerdetails_ee.cntr_exterior_l!=null)
      //   {
      //     this.containerdetails_ee.cntr_exterior_l=Math.round((0.393701*this.containerdetails_ee.cntr_exterior_l)*1000)/1000;
      //   }
      //   if(this.containerdetails_ee.cntr_exterior_w!=null)
      //   {
      //     this.containerdetails_ee.cntr_exterior_w=Math.round((0.393701*this.containerdetails_ee.cntr_exterior_w)*1000)/1000;
      //   }
      //   if(this.containerdetails_ee.cntr_exterior_h!=null)
      //   {
      //     this.containerdetails_ee.cntr_exterior_h=Math.round((0.393701*this.containerdetails_ee.cntr_exterior_h)*1000)/1000;
      //   }
      //   if(this.containerdetails_ee.shpg_unit_exterior_l!=null)
      //   {
      //     this.containerdetails_ee.shpg_unit_exterior_l=Math.round((0.393701*this.containerdetails_ee.shpg_unit_exterior_l)*1000)/1000;
      //   }
      //   if(this.containerdetails_ee.shpg_unit_exterior_w!=null)
      //   {
      //     this.containerdetails_ee.shpg_unit_exterior_w=Math.round((0.393701*this.containerdetails_ee.shpg_unit_exterior_w)*1000)/1000;
      //   }
      //   if(this.containerdetails_ee.shpg_unit_exterior_h!=null)
      //   {
      //     this.containerdetails_ee.shpg_unit_exterior_h=Math.round((0.393701*this.containerdetails_ee.shpg_unit_exterior_h)*1000)/1000;
      //   }
      //   if(this.containerdetails_ee.shpg_unit_nstdorflded_ht!=null)
      //   {
      //     this.containerdetails_ee.shpg_unit_nstdorflded_ht=Math.round((0.393701*this.containerdetails_ee.shpg_unit_nstdorflded_ht)*1000)/1000;
      //   }
      //   if(this.containerdetails_ee.cntr_tare_wt!=null)
      //   {
      //     this.containerdetails_ee.cntr_tare_wt=Math.round((2.20462*this.containerdetails_ee.cntr_tare_wt)*1000)/1000;
      //   }
      //   if(this.cntrdetforpost_ee.cntr_nsted_fld_height!=null)
      //   {
      //     this.cntrdetforpost_ee.cntr_nsted_fld_height=Math.round((0.393701*this.cntrdetforpost_ee.cntr_nsted_fld_height)*1000)/1000;

      //   }



      // }

      // else if(this.edit_measure_contcos_ee=="kg/cm")
      // {


      //   if(this.containerdetails_ee.cntr_exterior_l!=null)
      //   {
      //     this.containerdetails_ee.cntr_exterior_l=Math.round((2.54*this.containerdetails_ee.cntr_exterior_l)*1000)/1000;
      //   }
      //   if(this.containerdetails_ee.cntr_exterior_w!=null)
      //   {
      //     this.containerdetails_ee.cntr_exterior_w=Math.round((2.54*this.containerdetails_ee.cntr_exterior_w)*1000)/1000;
      //   }
      //   if(this.containerdetails_ee.cntr_exterior_h!=null)
      //   {
      //     this.containerdetails_ee.cntr_exterior_h=Math.round((2.54*this.containerdetails_ee.cntr_exterior_h)*1000)/1000;
      //   }
      //   if(this.containerdetails_ee.shpg_unit_exterior_l!=null)
      //   {
      //     this.containerdetails_ee.shpg_unit_exterior_l=Math.round((2.54*this.containerdetails_ee.shpg_unit_exterior_l)*1000)/1000;
      //   }
      //   if(this.containerdetails_ee.shpg_unit_exterior_w!=null)
      //   {
      //     this.containerdetails_ee.shpg_unit_exterior_w=Math.round((2.54*this.containerdetails_ee.shpg_unit_exterior_w)*1000)/1000;
      //   }
      //   if(this.containerdetails_ee.shpg_unit_exterior_h!=null)
      //   {
      //     this.containerdetails_ee.shpg_unit_exterior_h=Math.round((2.54*this.containerdetails_ee.shpg_unit_exterior_h)*1000)/1000;
      //   }
      //   if(this.containerdetails_ee.shpg_unit_nstdorflded_ht!=null)
      //   {
      //     this.containerdetails_ee.shpg_unit_nstdorflded_ht=Math.round((2.54*this.containerdetails_ee.shpg_unit_nstdorflded_ht)*1000)/1000;
      //   }
      //   if(this.containerdetails_ee.cntr_tare_wt!=null)
      //   {
      //     this.containerdetails_ee.cntr_tare_wt=Math.round((0.453592*this.containerdetails_ee.cntr_tare_wt)*1000)/1000;
      //   }
      //   if(this.cntrdetforpost_ee.cntr_nsted_fld_height!=null)
      //   {
      //     this.cntrdetforpost_ee.cntr_nsted_fld_height=Math.round((2.54*this.cntrdetforpost_ee.cntr_nsted_fld_height)*1000)/1000;

      //   }

      // }





    }



    on_change_measure_contcos_carton_ee()
    {
      console.log(this.edit_measure_contcos_carton_ee);
      //after carton is added do it for kpato psi and psi to kpa
    }



    on_click_cntrdetails_ee(cntrdata:any)
    {
      this.enabledetails_cntr_ee=true;
      // this.get_measure_ee="lbs/inch";

      if(this.unitom_ee=="E")
      {
        this.get_measure_ee = "lbs/inch";
      }
      else
      {
        this.get_measure_ee = "kg/cm";
      }
      console.log(cntrdata);

      this.transactionService.editbutton_get_contr_details(cntrdata.cntr_base,cntrdata.cd_cntr).subscribe(cntval=>{
        this.cntrdetails_display_ee=cntval;
      })


    }



on_change_get_measure_ee()
{

       if(this.unitom_ee=="E")
      {
        this.get_measure_ee = "lbs/inch";
      }
      else
      {
        this.get_measure_ee = "kg/cm";
      }

  console.log(this.get_measure_ee);



//   if(this.get_measure_ee=="lbs/inch")
//   {

//       console.log("inside lbs")
// if(this.cntrdetails_display_ee.collapsed_ht!=null)
// {
//     this.cntrdetails_display_ee.collapsed_ht=Math.round((0.393701*this.cntrdetails_display_ee.collapsed_ht)*1000)/1000;
// }
// if(this.cntrdetails_display_ee.cntr_interior_l!=null)
// {
//     this.cntrdetails_display_ee.cntr_interior_l=Math.round((0.393701*this.cntrdetails_display_ee.cntr_interior_l)*1000)/1000;
// }
// if(this.cntrdetails_display_ee.cntr_interior_w!=null)
// {
//         this.cntrdetails_display_ee.cntr_interior_w=Math.round((0.393701*this.cntrdetails_display_ee.cntr_interior_w)*1000)/1000;
// }
// if(this.cntrdetails_display_ee.cntr_interior_h!=null)
// {
//         this.cntrdetails_display_ee.cntr_interior_h=Math.round((0.393701*this.cntrdetails_display_ee.cntr_interior_h)*1000)/1000;
// }
// if(this.cntrdetails_display_ee.cntr_tare_wt!=null)
// {
//         this.cntrdetails_display_ee.cntr_tare_wt=Math.round((2.20462*this.cntrdetails_display_ee.cntr_tare_wt)*1000)/1000;
// }
// if(this.cntrdetails_display_ee.cntr_capacity!=null)
// {
//         this.cntrdetails_display_ee.cntr_capacity=Math.round((0.393701*0.393701*0.393701*this.cntrdetails_display_ee.cntr_capacity)*1000)/1000;
// }

// if(this.cntrdetails_display_ee.cntr_exterior_l!=null)
// {
//          this.cntrdetails_display_ee.cntr_exterior_l=Math.round((0.393701*this.cntrdetails_display_ee.cntr_exterior_l)*1000)/1000;
// }
// if(this.cntrdetails_display_ee.cntr_exterior_w!=null)
// {
//         this.cntrdetails_display_ee.cntr_exterior_w=Math.round((0.393701*this.cntrdetails_display_ee.cntr_exterior_w)*1000)/1000;
// }
// if(this.cntrdetails_display_ee.cntr_exterior_h!=null)
// {
//         this.cntrdetails_display_ee.cntr_exterior_h=Math.round((0.393701*this.cntrdetails_display_ee.cntr_exterior_h)*1000)/1000;
// }
// if(this.cntrdetails_display_ee.shpg_unit_exterior_l!=null)
// {
//        this.cntrdetails_display_ee.shpg_unit_exterior_l=Math.round((0.393701*this.cntrdetails_display_ee.shpg_unit_exterior_l)*1000)/1000;
// }
// if(this.cntrdetails_display_ee.shpg_unit_exterior_w!=null)
// {
//         this.cntrdetails_display_ee.shpg_unit_exterior_w=Math.round((0.393701*this.cntrdetails_display_ee.shpg_unit_exterior_w)*1000)/1000;
// }
// if(this.cntrdetails_display_ee.shpg_unit_exterior_h!=null)
// {
//         this.cntrdetails_display_ee.shpg_unit_exterior_h=Math.round((0.393701*this.cntrdetails_display_ee.shpg_unit_exterior_h)*1000)/1000;
// }
// if(this.cntrdetails_display_ee.shpg_unit_nstdorflded_ht!=null)
// {
//         this.cntrdetails_display_ee.shpg_unit_nstdorflded_ht=Math.round((0.393701*this.cntrdetails_display_ee.shpg_unit_nstdorflded_ht)*1000)/1000;
// }
// if(this.cntrdetails_display_ee.shpg_unit_tare_wt!=null)
// {
//         this.cntrdetails_display_ee.shpg_unit_tare_wt=Math.round((2.20462*this.cntrdetails_display_ee.shpg_unit_tare_wt)*1000)/1000;
// }
// if(this.cntrdetails_display_ee.shpg_unit_stack_ht!=null)
// {
//         this.cntrdetails_display_ee.shpg_unit_stack_ht=Math.round((0.393701*this.cntrdetails_display_ee.shpg_unit_stack_ht)*1000)/1000;
// }
// if(this.cntrdetails_display_ee.warehouse_stack_ht!=null)
// {
//         this.cntrdetails_display_ee.warehouse_stack_ht=Math.round((0.393701*this.cntrdetails_display_ee.warehouse_stack_ht)*1000)/1000;
// }



// }


//   else if(this.get_measure_ee=="kg/cm")
//   {


//       console.log("inside kg")

//     if(this.cntrdetails_display_ee.collapsed_ht!=null)
//     {
//         this.cntrdetails_display_ee.collapsed_ht=Math.round((2.54*this.cntrdetails_display_ee.collapsed_ht)*1000)/1000;
//     }
//     if(this.cntrdetails_display_ee.cntr_interior_l!=null)
//     {
//         this.cntrdetails_display_ee.cntr_interior_l=Math.round((2.54*this.cntrdetails_display_ee.cntr_interior_l)*1000)/1000;
//     }
//     if(this.cntrdetails_display_ee.cntr_interior_w!=null)
//     {
//             this.cntrdetails_display_ee.cntr_interior_w=Math.round((2.54*this.cntrdetails_display_ee.cntr_interior_w)*1000)/1000;
//     }
//     if(this.cntrdetails_display_ee.cntr_interior_h!=null)
//     {
//             this.cntrdetails_display_ee.cntr_interior_h=Math.round((2.54*this.cntrdetails_display_ee.cntr_interior_h)*1000)/1000;
//     }
//     if(this.cntrdetails_display_ee.cntr_tare_wt!=null)
//     {
//             this.cntrdetails_display_ee.cntr_tare_wt=Math.round((0.453592*this.cntrdetails_display_ee.cntr_tare_wt)*1000)/1000;
//     }
//     if(this.cntrdetails_display_ee.cntr_capacity!=null)
//     {
//             this.cntrdetails_display_ee.cntr_capacity=Math.round((2.54*2.54*2.54*this.cntrdetails_display_ee.cntr_capacity)*1000)/1000;
//     }

//     if(this.cntrdetails_display_ee.cntr_exterior_l!=null)
//     {
//              this.cntrdetails_display_ee.cntr_exterior_l=Math.round((2.54*this.cntrdetails_display_ee.cntr_exterior_l)*1000)/1000;
//     }
//     if(this.cntrdetails_display_ee.cntr_exterior_w!=null)
//     {
//             this.cntrdetails_display_ee.cntr_exterior_w=Math.round((2.54*this.cntrdetails_display_ee.cntr_exterior_w)*1000)/1000;
//     }
//     if(this.cntrdetails_display_ee.cntr_exterior_h!=null)
//     {
//             this.cntrdetails_display_ee.cntr_exterior_h=Math.round((2.54*this.cntrdetails_display_ee.cntr_exterior_h)*1000)/1000;
//     }
//     if(this.cntrdetails_display_ee.shpg_unit_exterior_l!=null)
//     {
//            this.cntrdetails_display_ee.shpg_unit_exterior_l=Math.round((2.54*this.cntrdetails_display_ee.shpg_unit_exterior_l)*1000)/1000;
//     }
//     if(this.cntrdetails_display_ee.shpg_unit_exterior_w!=null)
//     {
//             this.cntrdetails_display_ee.shpg_unit_exterior_w=Math.round((2.54*this.cntrdetails_display_ee.shpg_unit_exterior_w)*1000)/1000;
//     }
//     if(this.cntrdetails_display_ee.shpg_unit_exterior_h!=null)
//     {
//             this.cntrdetails_display_ee.shpg_unit_exterior_h=Math.round((2.54*this.cntrdetails_display_ee.shpg_unit_exterior_h)*1000)/1000;
//     }
//     if(this.cntrdetails_display_ee.shpg_unit_nstdorflded_ht!=null)
//     {
//             this.cntrdetails_display_ee.shpg_unit_nstdorflded_ht=Math.round((2.54*this.cntrdetails_display_ee.shpg_unit_nstdorflded_ht)*1000)/1000;
//     }
//     if(this.cntrdetails_display_ee.shpg_unit_tare_wt!=null)
//     {
//             this.cntrdetails_display_ee.shpg_unit_tare_wt=Math.round((0.453592*this.cntrdetails_display_ee.shpg_unit_tare_wt)*1000)/1000;
//     }
//     if(this.cntrdetails_display_ee.shpg_unit_stack_ht!=null)
//     {
//             this.cntrdetails_display_ee.shpg_unit_stack_ht=Math.round((2.54*this.cntrdetails_display_ee.shpg_unit_stack_ht)*1000)/1000;
//     }
//     if(this.cntrdetails_display_ee.warehouse_stack_ht!=null)
//     {
//             this.cntrdetails_display_ee.warehouse_stack_ht=Math.round((2.54*this.cntrdetails_display_ee.warehouse_stack_ht)*1000)/1000;
//     }




//   }
}

conTareWgtValidation_ee()
{
  let cont_tar_wt = String(this.cntrdetforpost_ee.cntr_tar_weight).split(".");

  if(this.cntrdetforpost_ee.cntr_tar_weight==null || this.cntrdetforpost_ee.cntr_tar_weight==0)
  {
    this.contarewgtInvalid_ee=true;
    this.contarewgtInValidReason_ee="Required";
  }

  else if(!String(this.cntrdetforpost_ee.cntr_tar_weight).includes("."))
   {
  if(this.cntrdetforpost_ee.cntr_tar_weight<0 || this.cntrdetforpost_ee.cntr_tar_weight>999999.999 )
       {
         this.contarewgtInvalid_ee=true;
         this.contarewgtInValidReason_ee="Should be of range 0 to 999999.999";
       }
       /* else if( String(this.cntrdetforpost_ee.cntr_tar_weight).length>11)
       {
         this.contarewgtInvalid_ee=true;
         this.contarewgtInValidReason_ee="Max Length Of Characters can be 10";
       } */

       else
       {
         this.contarewgtInvalid_ee=false;
         this.contarewgtInValidReason_ee=null;
       }
   }
   else if(String(this.cntrdetforpost_ee.cntr_tar_weight).includes("."))
   {
      /*  if(String(cont_tar_wt[0]).length<0 || String(cont_tar_wt[0]).length>6)
       {
         this.contarewgtInvalid_ee=true;
         this.contarewgtInValidReason_ee="Should be of range 1 to 999999.999";
       }
       else */ if(this.cntrdetforpost_ee.cntr_tar_weight<0 || this.cntrdetforpost_ee.cntr_tar_weight>999999.999)
       {
         this.contarewgtInvalid_ee=true;
         this.contarewgtInValidReason_ee="Should be of range 0 to 999999.999";
       }
       else if(String(cont_tar_wt[1]).length<0 || String(cont_tar_wt[1]).length>3)
       {
         this.contarewgtInvalid_ee=true;
         this.contarewgtInValidReason_ee="Max Length Of decimal values can be 3";
       }
       else
       {
         this.contarewgtInvalid_ee=false;
         this.contarewgtInValidReason_ee=null;
       }
   }
   else
   {
   this.contarewgtInvalid_ee=false;
   this.contarewgtInValidReason_ee=null;
   }
}

conExtLenValidation_ee()
{
  let cont_ext_l = String(this.cntrdetforpost_ee.cntr_ext_len).split(".");

  if(this.cntrdetforpost_ee.cntr_ext_len==null || this.cntrdetforpost_ee.cntr_ext_len==0)
  {
    this.contextlenInvalid_ee=true;
    this.contextlenInValidReason_ee="Required";
  }
  else if(!String(this.cntrdetforpost_ee.cntr_ext_len).includes("."))
   {
   if(this.cntrdetforpost_ee.cntr_ext_len>this.cntrdetforpost_ee.shp_unit_ext_len)
       {
        this.contextlenInvalid_ee=true;
        this.contextlenInValidReason_ee="Container Exterior Length cannot be greater than Shipping Unit Exterior Length";
       }

       else if(this.cntrdetforpost_ee.cntr_ext_len<0 || this.cntrdetforpost_ee.cntr_ext_len>9999.999 )
       {
         this.contextlenInvalid_ee=true;
         this.contextlenInValidReason_ee="Should be of range 0 to 9999.999";
       }
       /*else if( String(this.cntrdetforpost_ee.cntr_ext_len).length>9)
       {
         this.contextlenInvalid_ee=true;
         this.contextlenInValidReason_ee="Max Length Of Characters can be 8";
       }*/


       else
       {
         this.contextlenInvalid_ee=false;
         this.contextlenInValidReason_ee=null;
       }
   }
   else if(String(this.cntrdetforpost_ee.cntr_ext_len).includes("."))
   {
     if(this.cntrdetforpost_ee.cntr_ext_len>this.cntrdetforpost_ee.shp_unit_ext_len)
    {
     this.contextlenInvalid_ee=true;
     this.contextlenInValidReason_ee="Container Exterior Length cannot be greater than Shipping Unit Exterior Length";
    }
      else  if(String(cont_ext_l[0]).length<0 || String(cont_ext_l[0]).length>4)
       {
         this.contextlenInvalid_ee=true;
         this.contextlenInValidReason_ee="Should be of range 0 to 9999.999";
       }
       else if(this.cntrdetforpost_ee.cntr_ext_len<0 || this.cntrdetforpost_ee.cntr_ext_len>9999.999)
       {
         this.contextlenInvalid_ee=true;
         this.contextlenInValidReason_ee="Should be of range 0 to 9999.999";
       }
       else if(String(cont_ext_l[1]).length<0 || String(cont_ext_l[1]).length>3)
       {
         this.contextlenInvalid_ee=true;
         this.contextlenInValidReason_ee="Max Length Of decimal values can be 3";
       }
       else
       {
         this.contextlenInvalid_ee=false;
         this.contextlenInValidReason_ee=null;
       }
   }
   else
   {
   this.contextlenInvalid_ee=false;
   this.contextlenInValidReason_ee=null;
   }
}
conExtWidValidation_ee()
{
  let cont_ext_w = String(this.cntrdetforpost_ee.cntr_width).split(".");

  if(this.cntrdetforpost_ee.cntr_width==null || this.cntrdetforpost_ee.cntr_width==0)
  {
    this.contextwidInvalid_ee=true;
    this.contextwidInValidReason_ee="Required";
  }
  else if(!String(this.cntrdetforpost_ee.cntr_width).includes("."))
    {
    if(this.cntrdetforpost_ee.cntr_width>this.cntrdetforpost_ee.shp_unt_ext_width)
    {
      this.contextwidInvalid_ee=true;
      this.contextwidInValidReason_ee="Container Exterior Width cannot be greater than Shipping Unit Exterior Width";
    }

        else if(this.cntrdetforpost_ee.cntr_width<0 || this.cntrdetforpost_ee.cntr_width>9999.999 )
        {
          this.contextwidInvalid_ee=true;
          this.contextwidInValidReason_ee="Should be of range 0 to 9999.999";
        }
        /*else if( String(this.cntrdetforpost_ee.cntr_width).length>9)
        {
          this.contextwidInvalid_ee=true;
          this.contextwidInValidReason_ee="Max Length Of Characters can be 8";
        }*/

        else
        {
          this.contextwidInvalid_ee=false;
          this.contextwidInValidReason_ee=null;
        }
    }
    else if(String(this.cntrdetforpost_ee.cntr_width).includes("."))
    {
      if(this.cntrdetforpost_ee.cntr_width>this.cntrdetforpost_ee.shp_unt_ext_width)
    {
     this.contextwidInvalid_ee=true;
     this.contextwidInValidReason_ee="Container Exterior Width cannot be greater than Shipping Unit Exterior Width";
    }
        else if(String(cont_ext_w[0]).length<0 || String(cont_ext_w[0]).length>4)
        {
          this.contextwidInvalid_ee=true;
          this.contextwidInValidReason_ee="Should be of range 0 to 9999.999";
        }
        else if(this.cntrdetforpost_ee.cntr_width<0 || this.cntrdetforpost_ee.cntr_width>9999.999)
        {
          this.contextwidInvalid_ee=true;
          this.contextwidInValidReason_ee="Should be of range 0 to 9999.999";
        }
        else if(String(cont_ext_w[1]).length<0 || String(cont_ext_w[1]).length>3)
        {
          this.contextwidInvalid_ee=true;
          this.contextwidInValidReason_ee="Max Length Of decimal values can be 3";
        }
        else
        {
          this.contextwidInvalid_ee=false;
          this.contextwidInValidReason_ee=null;
        }
    }
    else
    {
    this.contextwidInvalid_ee=false;
    this.contextwidInValidReason_ee=null;
    }
}
conExtHgtValidation_ee()
{
  let cont_ext_h = String(this.cntrdetforpost_ee.cntr_height).split(".");

  if(this.cntrdetforpost_ee.cntr_height==null || this.cntrdetforpost_ee.cntr_height==0)
  {
    this.contexthgtInvalid_ee=true;
    this.contexthgtInValidReason_ee="Required";
  }
  else if(!String(this.cntrdetforpost_ee.cntr_height).includes("."))
    {
    /*if(this.cntrdetforpost_ee.cntr_height>this.cntrdetforpost_ee.shp_unt_ext_height)
    {
     this.contexthgtInvalid_ee=true;
     this.contexthgtInValidReason_ee="Container Exterior Height cannot be greater than Shipping Unit Exterior Height";
    }
        else*/
         if(this.cntrdetforpost_ee.cntr_height<0 || this.cntrdetforpost_ee.cntr_height>9999.999 )
        {
          this.contexthgtInvalid_ee=true;
          this.contexthgtInValidReason_ee="Should be of range 0 to 9999.999";
        }
       /* else if( String(this.cntrdetforpost_ee.cntr_height).length>9)
        {
          this.contexthgtInvalid_ee=true;
          this.contexthgtInValidReason_ee="Max Length Of Characters can be 8";
        }*/

        else
        {
          this.contexthgtInvalid_ee=false;
          this.contexthgtInValidReason_ee=null;
        }
    }
    else if(String(this.cntrdetforpost_ee.cntr_height).includes("."))
    {
      /*if(this.cntrdetforpost_ee.cntr_height>this.cntrdetforpost_ee.shp_unt_ext_height)
    {
      this.contexthgtInvalid_ee=true;
      this.contexthgtInValidReason_ee="Container Exterior Height cannot be greater than Shipping Unit Exterior Height";
     }
        else */
        if(String(cont_ext_h[0]).length<0 || String(cont_ext_h[0]).length>4)
        {
          this.contexthgtInvalid_ee=true;
          this.contexthgtInValidReason_ee="Should be of range 0 to 9999.999";
        }
        else if(this.cntrdetforpost_ee.cntr_height<0 || this.cntrdetforpost_ee.cntr_height>9999.999)
        {
          this.contexthgtInvalid_ee=true;
          this.contexthgtInValidReason_ee="Should be of range 0 to 9999.999";
        }
        else if(String(cont_ext_h[1]).length<0 || String(cont_ext_h[1]).length>3)
        {
          this.contexthgtInvalid_ee=true;
          this.contexthgtInValidReason_ee="Max Length Of decimal values can be 3";
        }
        else
        {
          this.contexthgtInvalid_ee=false;
          this.contexthgtInValidReason_ee=null;
        }
    }
    else
    {
    this.contexthgtInvalid_ee=false;
    this.contexthgtInValidReason_ee=null;
    }
}
contNestedFldedHtValidation_ee()
{
  if(this.cntrdetforpost_ee.cntr_nsted_fld_height==null || this.cntrdetforpost_ee.cntr_nsted_fld_height==0)
  {
    this.contNestedHtInValid_ee=true;
    this.contNestedHtInValidReason_ee="Required";
  }
  else if(this.cntrdetforpost_ee.cntr_nsted_fld_height<0 || this.cntrdetforpost_ee.cntr_nsted_fld_height>999.999)
  {
    this.contNestedHtInValid_ee=true;
    this.contNestedHtInValidReason_ee="Should be of range 0 to 999.99";//took validation from collapsed height in container admin have to verify and might change
  }
  /*
  else if(this.cntrdetforpost_ee.cntr_nsted_fld_height>this.cntrdetforpost_ee.cntr_height)
  {
    this.contNestedHtInValid_ee=true;
    this.contNestedHtInValidReason_ee="Nested Folded Height cannot be Greater than Exterior height";
  }*/
  else
  {
    this.contNestedHtInValid_ee=false;
    this.contNestedHtInValidReason_ee=null;
  }
}
partsPerContainerValidation_ee()
{
  if(this.cntrdetforpost_ee.parts_per_cntr == null || this.cntrdetforpost_ee.parts_per_cntr == 0)
  {
    this.partsprcntrInValid_ee = true;
    this.partsprcntrInValidReason_ee = "Required";
  }
  else
  {
    this.partsprcntrInValid_ee = false;
    this.partsprcntrInValidReason_ee = null;
  }
}
maxlayprshpunitValidation_ee()
{
  if(this.cntrdetforpost_ee.max_layrs_shp_unt == null || this.cntrdetforpost_ee.max_layrs_shp_unt == 0)
  {
    this.maxlayerprshpInValid_ee = true;
    this.maxlayerprshpInValidReason_ee = "Required";
  }
  else
  {
    this.maxlayerprshpInValid_ee = false;
    this.maxlayerprshpInValidReason_ee = null;
  }
}

cntrPerLayersValidation_ee()
{
  if(this.cntrdetforpost_ee.cntr_per_layer == null || this.cntrdetforpost_ee.cntr_per_layer == 0)
  {
    this.cntrperlaysInValid_ee = true;
    this.cntrperlaysInValidReason_ee = "Required";
  }
  else
  {
    this.cntrperlaysInValid_ee = false;
    this.cntrperlaysInValidReason_ee = null;
  }
}
suExtLenValidation_ee()
{
  let shp_ext_l = String(this.cntrdetforpost_ee.shp_unit_ext_len).split(".");
  // if(this.cntrdetforpost_ee.shp_unit_ext_len>this.cntrdetforpost_ee.shp_unit_ext_len)
  //   {
  //   this.suextlenInvalid_ee=true;
  //   this.suextlenInValidReason_ee="Container Exterior Length cannot be greater than Shipping Unit Exterior Length";
  // }
    if(this.cntrdetforpost_ee.shp_unit_ext_len==null || this.cntrdetforpost_ee.shp_unit_ext_len==0)
    {
      this.suextlenInvalid_ee=true;
      this.suextlenInValidReason_ee="Required";
    }
    else
    {
      if(!String(this.cntrdetforpost_ee.shp_unit_ext_len).includes("."))
      {

          // else if(this.cntrdetforpost_ee.shp_unit_ext_len>this.cntrdetforpost_ee.shp_unit_ext_len)
          // {
          // this.suextlenInvalid_ee=true;
          // this.suextlenInValidReason_ee="Container Exterior Length cannot be greater than Shipping Unit Exterior Length";
          // }
          if(this.cntrdetforpost_ee.shp_unit_ext_len<1 || this.cntrdetforpost_ee.shp_unit_ext_len>9999.999 )
          {
            this.suextlenInvalid_ee=true;
            this.suextlenInValidReason_ee="Should be of range 1 to 9999.999";
          }
          else if( String(this.cntrdetforpost_ee.shp_unit_ext_len).length>8)
          {
            this.suextlenInvalid_ee=true;
            this.suextlenInValidReason_ee="Max Length Of Characters can be 8";
          }
          else if(this.cntrdetforpost_ee.shp_unit_ext_len>0 && this.cntrdetforpost_ee.cntr_ext_len>this.cntrdetforpost_ee.shp_unit_ext_len)
          {
             this.suextlenInvalid_ee=true;
            this.suextlenInValidReason_ee="Shipping Unit Exterior Length cannot be lesser than Container Exterior Length";
          }

          else
          {
            this.suextlenInvalid_ee=false;
            this.suextlenInValidReason_ee=null;
          }
      }
      else if(String(this.cntrdetforpost_ee.shp_unit_ext_len).includes("."))
      {
          if(String(shp_ext_l[0]).length<0 || String(shp_ext_l[0]).length>4)
          {
            this.suextlenInvalid_ee=true;
            this.suextlenInValidReason_ee="Should be of range 1 to 9999.999";
          }
          else if(this.cntrdetforpost_ee.shp_unit_ext_len<1 || this.cntrdetforpost_ee.shp_unit_ext_len>9999.999)
          {
            this.suextlenInvalid_ee=true;
            this.suextlenInValidReason_ee="Should be of range 1 to 9999.999";
          }
          else if(String(shp_ext_l[1]).length<0 || String(shp_ext_l[1]).length>3)
          {
            this.suextlenInvalid_ee=true;
            this.suextlenInValidReason_ee="Max Length Of decimal values can be 3";
          }
          else if(this.cntrdetforpost_ee.shp_unit_ext_len>0 && this.cntrdetforpost_ee.cntr_ext_len>this.cntrdetforpost_ee.shp_unit_ext_len)
          {
             this.suextlenInvalid_ee=true;
             this.suextlenInValidReason_ee="Shipping Unit Exterior Length cannot be lesser than Container Exterior Length";
          }
          else
          {
            this.suextlenInvalid_ee=false;
            this.suextlenInValidReason_ee=null;
          }
      }
    }


}
suExtWidValidation_ee()
{
  let shp_ext_w = String(this.cntrdetforpost_ee.shp_unt_ext_width).split(".");
  // if(this.cntrdetforpost_ee.shp_unt_ext_width>this.cntrdetforpost_ee.shp_unt_ext_width)
  //   {
  //   this.suextwidInvalid_ee=true;
  //   this.suextwidInValidReason_ee="Container Exterior Length cannot be greater than Shipping Unit Exterior Length";
  // }
  if(this.cntrdetforpost_ee.shp_unt_ext_width==null || this.cntrdetforpost_ee.shp_unt_ext_width==0)
        {
          this.suextwidInvalid_ee=true;
          this.suextwidInValidReason_ee="Required";
        }

  else
  {
    if(!String(this.cntrdetforpost_ee.shp_unt_ext_width).includes("."))
    {

         if(this.cntrdetforpost_ee.shp_unt_ext_width<1 || this.cntrdetforpost_ee.shp_unt_ext_width>9999.999 )
        {
          this.suextwidInvalid_ee=true;
          this.suextwidInValidReason_ee="Should be of range 1 to 9999.999";
        }
        else if( String(this.cntrdetforpost_ee.shp_unt_ext_width).length>8)
        {
          this.suextwidInvalid_ee=true;
          this.suextwidInValidReason_ee="Max Length Of Characters can be 8";
        }
        else if(this.cntrdetforpost_ee.shp_unt_ext_width>0 && this.cntrdetforpost_ee.cntr_width>this.cntrdetforpost_ee.shp_unt_ext_width)
        {
        this.suextwidInvalid_ee=true;
        this.suextwidInValidReason_ee="Shipping Unit Exterior Width cannot be lesser than Container Exterior Width";
        }

        else
        {
          this.suextwidInvalid_ee=false;
          this.suextwidInValidReason_ee=null;
        }
    }
    else if(String(this.cntrdetforpost_ee.shp_unt_ext_width).includes("."))
    {
        if(String(shp_ext_w[0]).length<0 || String(shp_ext_w[0]).length>4)
        {
          this.suextwidInvalid_ee=true;
          this.suextwidInValidReason_ee="Should be of range 1 to 9999.999";
        }
        else if(this.cntrdetforpost_ee.shp_unt_ext_width<1 || this.cntrdetforpost_ee.shp_unt_ext_width>9999.999)
        {
          this.suextwidInvalid_ee=true;
          this.suextwidInValidReason_ee="Should be of range 1 to 9999.999";
        }
        else if(String(shp_ext_w[1]).length<0 || String(shp_ext_w[1]).length>3)
        {
          this.suextwidInvalid_ee=true;
          this.suextwidInValidReason_ee="Max Length Of decimal values can be 3";
        }
       else if(this.cntrdetforpost_ee.shp_unt_ext_width>0 && this.cntrdetforpost_ee.cntr_width>this.cntrdetforpost_ee.shp_unt_ext_width)
       {
         this.suextwidInvalid_ee=true;
         this.suextwidInValidReason_ee="Shipping Unit Exterior Width cannot be lesser than Container Exterior Width";
        }
        else
        {
          this.suextwidInvalid_ee=false;
          this.suextwidInValidReason_ee=null;
        }
    }
  }


}
suExtHgtValidation_ee()
{
  let shp_ext_h = String(this.cntrdetforpost_ee.shp_unt_ext_height).split(".");
  // if(this.cntrdetforpost_ee.shp_unt_ext_height>this.cntrdetforpost_ee.shp_unt_ext_height)
  //   {
  //   this.suexthgtInvalid_ee=true;
  //   this.suexthgtInValidReason_ee="Container Exterior Length cannot be greater than Shipping Unit Exterior Length";
  // }
  if(this.cntrdetforpost_ee.shp_unt_ext_height==null || this.cntrdetforpost_ee.shp_unt_ext_height==0)
  {
    this.suexthgtInvalid_ee=true;
    this.suexthgtInValidReason_ee="Required";
  }
  else
  {
    if(!String(this.cntrdetforpost_ee.shp_unt_ext_height).includes("."))
    {

         if(this.cntrdetforpost_ee.shp_unt_ext_height<1 || this.cntrdetforpost_ee.shp_unt_ext_height>9999.999 )
        {
          this.suexthgtInvalid_ee=true;
          this.suexthgtInValidReason_ee="Should be of range 1 to 9999.999";
        }
        else if( String(this.cntrdetforpost_ee.shp_unt_ext_height).length>8)
        {
          this.suexthgtInvalid_ee=true;
          this.suexthgtInValidReason_ee="Max Length Of Characters can be 8";
        }
        else if(this.cntrdetforpost_ee.shp_unt_ext_height>0 && this.cntrdetforpost_ee.cntr_height>this.cntrdetforpost_ee.shp_unt_ext_height)
        {
        this.suexthgtInvalid_ee=true;
        this.suexthgtInValidReason_ee="Shipping Unit Exterior Height cannot be lesser than Container Exterior Height";
        }

        else
        {
          this.suexthgtInvalid_ee=false;
          this.suexthgtInValidReason_ee=null;
        }
    }
    else if(String(this.cntrdetforpost_ee.shp_unt_ext_height).includes("."))
    {
        if(String(shp_ext_h[0]).length<0 || String(shp_ext_h[0]).length>4)
        {
          this.suexthgtInvalid_ee=true;
          this.suexthgtInValidReason_ee="Should be of range 1 to 9999.999";
        }
        else if(this.cntrdetforpost_ee.shp_unt_ext_height<1 || this.cntrdetforpost_ee.shp_unt_ext_height>9999.999)
        {
          this.suexthgtInvalid_ee=true;
          this.suexthgtInValidReason_ee="Should be of range 1 to 9999.999";
        }
        else if(String(shp_ext_h[1]).length<0 || String(shp_ext_h[1]).length>3)
        {
          this.suexthgtInvalid_ee=true;
          this.suexthgtInValidReason_ee="Max Length Of decimal values can be 3";
        }
        else if(this.cntrdetforpost_ee.shp_unt_ext_height>0 && this.cntrdetforpost_ee.cntr_height>this.cntrdetforpost_ee.shp_unt_ext_height)
        {
          this.suexthgtInvalid_ee=true;
          this.suexthgtInValidReason_ee="Shipping Unit Exterior Height cannot be lesser than Container Exterior Height";
        }
        else
        {
          this.suexthgtInvalid_ee=false;
          this.suexthgtInValidReason_ee=null;
        }
    }
  }


}
suNestedFldedHtValidation_ee()
{
  if(this.cntrdetforpost_ee.shp_unt_nestd_fld_height==null || this.cntrdetforpost_ee.shp_unt_nestd_fld_height==0)
  {
    this.suNestedHtInValid_ee=true;
    this.suNestedHtInValidReason_ee="Required";
  }
  else if(this.cntrdetforpost_ee.shp_unt_nestd_fld_height<1 || this.cntrdetforpost_ee.shp_unt_nestd_fld_height>99999)
  {
    this.suNestedHtInValid_ee=true;
    this.suNestedHtInValidReason_ee="Should be of range 1 to 99999";
  }
  else if(this.cntrdetforpost_ee.shp_unt_nestd_fld_height>0 && this.cntrdetforpost_ee.shp_unt_ext_height>0 && this.cntrdetforpost_ee.shp_unt_nestd_fld_height>this.cntrdetforpost_ee.shp_unt_ext_height)
  {
    this.suNestedHtInValid_ee=true;
    this.suNestedHtInValidReason_ee="Nested Folded Height cannot be Greater than Exterior height";
  }
  else
  {
    this.suNestedHtInValid_ee=false;
    this.suNestedHtInValidReason_ee=null;
  }
}

checkAndSaveNext_ee()
{
  console.log(this.cntrdetforpost_ee);
  console.log(this.selectedpart_submitter_contandcos_ee)
  this.conTareWgtValidation_ee();
  this.conExtLenValidation_ee();
  this.conExtWidValidation_ee();
  this.conExtHgtValidation_ee();
  this.contNestedFldedHtValidation_ee();
  this.partsPerContainerValidation_ee();
  this.maxlayprshpunitValidation_ee();
  this.cntrPerLayersValidation_ee();
  this.suExtLenValidation_ee();
  this.suExtWidValidation_ee();
  //this.suExtHgtValidation_ee();
  //this.suNestedFldedHtValidation_ee();
  
  console.log("cntrsupost",this.cntrdetforpost_ee);

  if(!this.contarewgtInvalid_ee &&  !this.contextlenInvalid_ee  && !this.contextwidInvalid_ee && !this.contexthgtInvalid_ee && !this.partsprcntrInValid_ee && !this.maxlayerprshpInValid_ee && 
    !this.cntrperlaysInValid_ee && !this.suextlenInvalid_ee && !this.suextwidInvalid_ee &&  !this.suextlenInvalid_ee && !this.suextwidInvalid_ee &&
    !this.contNestedHtInValid_ee)
    {
  if(this.selectedpart_submitter_contandcos_ee.proposallastsaved_ee>=2)
  {
    console.log(this.existingtab2details_ee);
    if(this.existingtab2details_ee.cd_cntr!=this.cntrdetforpost_ee.cd_cntr)
    {
      this.confirmationService.confirm({
        message: 'Are you sure you want to change EE-container and flush Component and Image details associated with the container ?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.tab2InValidationInit_ee();
          this.saveandnextenable_ee=true;
          this.flushdata_ee();
            },
         reject: ()=>{


          console.log("reject")
          this.tab2InValidationInit_ee();
          this.loadtab2detlsifexist_ee();

         }
    });


    }
    else
    {



      console.log("flush data tab2 if")
      this.tab2InValidationInit_ee();
      this.saveThisAndEnableThirdForm_ee();
    }
  }


  else{

    console.log("flush data tab2 else")
    this.tab2InValidationInit_ee();
    this.saveThisAndEnableThirdForm_ee();
  }
}



}


checkAndSaveClose_ee()
{


  console.log(this.cntrdetforpost_ee);
  console.log(this.selectedpart_submitter_contandcos_ee)
  this.conTareWgtValidation_ee();
  this.conExtLenValidation_ee();
  this.conExtWidValidation_ee();
  this.conExtHgtValidation_ee();
  this.contNestedFldedHtValidation_ee();
  this.partsPerContainerValidation_ee();
  this.maxlayprshpunitValidation_ee();
  this.cntrPerLayersValidation_ee();
  this.suExtLenValidation_ee();
  this.suExtWidValidation_ee();
  //this.suExtHgtValidation_ee();
  //this.suNestedFldedHtValidation_ee();
  
  console.log("cntrsupost",this.cntrdetforpost_ee);

  if(!this.contarewgtInvalid_ee &&  !this.contextlenInvalid_ee  && !this.contextwidInvalid_ee && !this.contexthgtInvalid_ee && !this.partsprcntrInValid_ee && !this.maxlayerprshpInValid_ee && 
    !this.cntrperlaysInValid_ee && !this.suextlenInvalid_ee && !this.suextwidInvalid_ee &&  !this.suextlenInvalid_ee && !this.suextwidInvalid_ee &&
    !this.contNestedHtInValid_ee)
    {
  if(this.selectedpart_submitter_contandcos_ee.proposallastsaved_ee>=2)
  {
    console.log(this.existingtab2details_ee);
    if(this.existingtab2details_ee.cd_cntr!=this.cntrdetforpost_ee.cd_cntr)
    {
      this.confirmationService.confirm({
        message: 'Are you sure you want to change EE-container and flush Component and Image details associated with the container ?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.tab2InValidationInit_ee();
          this.saveandcloseenable_ee=true;
          this.flushdata_ee();
            },
         reject: ()=>{

          this.tab2InValidationInit_ee();
          this.loadtab2detlsifexist_ee();

         }
    });
    }
    else
    {
      this.tab2InValidationInit_ee();
      this.save_tab2_ee();
    }
  }


  else{
    this.tab2InValidationInit_ee();
    this.save_tab2_ee();
  }
}




}


flushdata_ee()
{
  if(this.selectedpart_submitter_contandcos_ee.proposallastsaved_ee>=4)
        {



  this.transactionService.loadtab4details_ee(this.selectedpart_submitter_contandcos_ee.proposalid).subscribe(tab4_api_op_ee=>{


    console.log(tab4_api_op_ee);


    this.tab4_output_cntr_ee=tab4_api_op_ee;

    // should not delete part and metal id photo

/*
    if(this.tab4_output_cntr_ee.f_partphoto!="" && this.tab4_output_cntr_ee.f_partphoto!=null)
    {
      var prt=this.tab4_output_cntr_ee.f_partphoto;
      this.transactionService.delete_image(prt).subscribe(out_prt=>{

        console.log(out_prt);

      })

    }
    */


if(this.tab4_output_cntr_ee.f_intcntrdsgnphoto!="" && this.tab4_output_cntr_ee.f_intcntrdsgnphoto!=null)
{
    var inter=this.tab4_output_cntr_ee.f_intcntrdsgnphoto;
    this.transactionService.delete_image(inter).subscribe(out_inter=>{

        console.log(out_inter);

      })

}

if(this.tab4_output_cntr_ee.f_extcntrdsgnphoto!="" && this.tab4_output_cntr_ee.f_extcntrdsgnphoto!=null)
{
    var exter=this.tab4_output_cntr_ee.f_extcntrdsgnphoto;
    this.transactionService.delete_image(exter).subscribe(out_exter=>{

        console.log(out_exter);

      })

}

/*
if(this.tab4_output_cntr_ee.f_metelidplatephoto!="" && this.tab4_output_cntr_ee.f_metelidplatephoto!=null)
{
    var label=this.tab4_output_cntr_ee.f_metelidplatephoto;
    this.transactionService.delete_image(label).subscribe(out_label=>{

        console.log("label",out_label);

      })

}
*/


if(this.tab4_output_cntr_ee.f_shpunitphoto!="" && this.tab4_output_cntr_ee.f_shpunitphoto!=null)
{
    var ship=this.tab4_output_cntr_ee.f_shpunitphoto;
    this.transactionService.delete_image(ship).subscribe(out_ship=>{

        console.log(out_ship);

      })

}





   this.deleteTab3Tab4data_ee();



})





          }


          else{


            this.deleteTab3Tab4data_ee();

          }



}





deleteTab3Tab4data_ee()
{
  console.log(this.selectedpart_submitter_contandcos_ee);

  this.transactionService.flushcompandimagedata_ee(this.selectedpart_submitter_contandcos_ee.proposalid).subscribe(flush_out=>{


    console.log(flush_out);
    if(flush_out.toLowerCase()=="success")
    {


      this.packagingproposal.addtoast({severity:'success', summary: 'success', detail: 'Component and Image details deleted  successfully'});


      this.selectedpart_submitter_contandcos_ee.proposallastsaved_ee=1;
      this.store_primary_part_exist_ee();
       this.transactionService.loadmenuwhenclickedfromdashboard("Yes");

        this.packagingproposal.ngOnInit();

      this.saveTAb2Flush_ee();


      }


      else{
        this.saveandnextenable_ee=false;
                this.saveandcloseenable_ee=false;
      }



  })

}


saveTAb2Flush_ee()
{



  this.cntrdetforpost_ee.empty_trays_cntr_layrs=Number(this.cntrdetforpost_ee.empty_trays_cntr_layrs);
  this.cntrdetforpost_ee.part_per_shp_unit=(this.cntrdetforpost_ee.cntr_per_layer* (this.cntrdetforpost_ee.max_layrs_shp_unt-this.cntrdetforpost_ee.empty_trays_cntr_layrs))* this.cntrdetforpost_ee.parts_per_cntr;
  this.cntrdetforpost_ee.max_cntr_per_shp_unt=this.cntrdetforpost_ee.cntr_per_layer* (this.cntrdetforpost_ee.max_layrs_shp_unt-this.cntrdetforpost_ee.empty_trays_cntr_layrs);



  if(this.colorforcntrorrack_ee.some(e=>e.color_desc==this.selectedcolor_ee.color_desc))
  {
    this.cntrdetforpost_ee.cntr_rck_colr=this.selectedcolor_ee.color_desc;
  }
  if(this.pinpallettypeList_ee.some(e=>e.pinpallettyp.toLowerCase() == this.selectedPinPalletType_ee.pinpallettyp.toLowerCase() ))
  {
    this.cntrdetforpost_ee.palletpintyp=this.selectedPinPalletType_ee.pinpallettyp;
  }

  if(this.cntrdetforpost_ee.shp_unt_ret_type.toLowerCase()=="expendable")
  {
    this.cntrdetforpost_ee.pallettype=this.pallet_type_ee

  }
  else  if(this.cntrdetforpost_ee.shp_unt_ret_type.toLowerCase()=="returnable"){
    this.cntrdetforpost_ee.pallettype="Returnable"
  }

      var shpUntHgtOnlyCntr=0;
      shpUntHgtOnlyCntr=this.cntrdetforpost_ee.cntr_height*(this.cntrdetforpost_ee.max_layrs_shp_unt-this.cntrdetforpost_ee.empty_trays_cntr_layrs);
        console.log("Calculated Shipping unit exterior height before post last saved tab 2 ee",shpUntHgtOnlyCntr)
        this.cntrdetforpost_ee.shp_unt_ext_height=this.transactionService.roundValueToNDecimal(shpUntHgtOnlyCntr,3);

        if(this.cntrdetforpost_ee.cntr_ret_type.toLowerCase() == "returnable")
        {
          this.cntrdetforpost_ee.woodispmcmplnt = "N/A";
          this.cntrdetforpost_ee.offstmpmartprsnt = "N/A";
        }
        
  this.transactionService.savetab2details_ee(this.cntrdetforpost_ee).subscribe(result=>
    {
      var msgs:any[];
      if(result=='Success')
      {

        this.packagingproposal.addtoast({severity:'success', summary: 'success', detail: 'EE-COS,Container and Shipping Unit details saved successfully'});
        this.selectedpart_submitter_contandcos_ee.proposallastsaved_ee=2;

        this.tab2InValidationInit_ee();
        this.store_primary_part_exist_ee();
        if(this.saveandnextenable_ee==true)
        {
          this.saveandnextenable_ee=false;
          console.log("output printing")
          console.log(this.transactionService.getpartno_packpro(this.current_region_contandcos_ee))
          this.transactionService.loadmenuwhenclickedfromdashboard("Yes");

        this.packagingproposal.ngOnInit();
        var reg_supp_cntr_url:string;
        reg_supp_cntr_url=this.transactionService.getregionurlvalue(this.current_region_contandcos_ee);
        reg_supp_cntr_url+="packagingprocess/proposalcreation/eepackproposaltabthree";

        this.router.navigate([reg_supp_cntr_url]);

        }


       else  if(this.saveandcloseenable_ee==true)
        {


          this.saveandcloseenable_ee=false;

          //this.store_primary_part_exist();
            console.log(this.selectedpart_submitter_contandcos_ee);
            this.region_ee=this.transactionService.getregion();
            this.name_ee=this.transactionService.getregionNameInSmallLetters();
            if(this.selectedpart_submitter_contandcos_ee.propsl_type!=null && this.selectedpart_submitter_contandcos_ee.propsl_type.toLowerCase()=="migration")
            {
              if(this.roleid_contandcos_ee==1)
              {
                this.router.navigate(['/'+this.name_ee+'/packagingprocess/packsupplieradmin/migrationsubmitter'])
              }
              else if(this.roleid_contandcos_ee==2)
              {
                this.router.navigate(['/'+this.name_ee+'/packagingprocess/packdesignsourceadmin/migrationsubmitter'])
              }
            }
            else //if(this.selectedpart_submitter.propsl_type!=null && this.selectedpart_submitter.propsl_type.toLowerCase()=="normal")
            {


            if(this.roleid_contandcos_ee==1)
            {
              this.router.navigate(['/'+this.name_ee+'/packagingprocess/packsupplieradmin/submiterdashboard'])
            }
            else if(this.roleid_contandcos_ee==2)
            {
              this.router.navigate(['/'+this.name_ee+'/packagingprocess/packdesignsourceadmin/submiterdashboard'])
            }
          }


        }


      }


      else
        {

            this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'EE-COS,Container and Shipping Unit details is not saved! try again'});

             this.saveandnextenable_ee=false;
            this.saveandcloseenable_ee=false;
            this.tab2InValidationInit_ee();

        }
    })
}

exteriorHeightConfirmationPopUp_ee()
{
 this.enableExteriorHeightPopUp_ee=true;
}

okUpdateExteriorHeight_ee()
{
  this.enableExteriorHeightPopUp_ee=false;
  var shpUntHgtOnlyCntr=0;
  shpUntHgtOnlyCntr=this.cntrdetforpost_ee.cntr_height*(this.cntrdetforpost_ee.max_layrs_shp_unt-this.cntrdetforpost_ee.empty_trays_cntr_layrs);
        
  this.tab3Data_ee=[];
  this.transactionService.getaddedcomponentdetailsfortab3_ee(this.selectedpart_submitter_contandcos_ee.proposalid).subscribe(gridout=>
    {
      this.tab3Data_ee=JSON.parse(JSON.stringify(gridout))
      if(this.tab3Data_ee != null && this.tab3Data_ee.length != 0)
      {
        for(var i=0; i<this.tab3Data_ee.length; i++ )
        {
          if(this.tab3Data_ee[i].typ_no == 2 || this.tab3Data_ee[i].typ_no == 3 || this.tab3Data_ee[i].typ_no == 4)
          {
            if(this.tab3Data_ee[i].compselctnfor.toLowerCase() == "shipping unit")
            {
              shpUntHgtOnlyCntr+=this.tab3Data_ee[i].comp_h*this.tab3Data_ee[i].qt_per_min_su;
            }
            /*else
            {
              shpUntHgtOnlyCntr+=this.tab3Data_ee[i].comp_h*this.tab3Data_ee[i].qt_per_min_su*(this.cntrdetforpost_ee.max_layrs_shp_unt-this.cntrdetforpost_ee.empty_trays_cntr_layrs);
            }*/
          }
        }

        console.log("Calculated Shipping unit exterior height before post with components data",shpUntHgtOnlyCntr)
        this.cntrdetforpost_ee.shp_unt_ext_height=this.transactionService.roundValueToNDecimal(shpUntHgtOnlyCntr,3);
        if(this.isSaveAndNext_ee)
        {
          this.postDataToTab2EEMoveToNextTab();
        }
        else
        {
          this.postDataToTAb2EESaveAndClose();
        }

      }
      else
      {
        console.log("Calculated Shipping unit exterior height before post without components data",shpUntHgtOnlyCntr)
        this.cntrdetforpost_ee.shp_unt_ext_height=this.transactionService.roundValueToNDecimal(shpUntHgtOnlyCntr,3);
        if(this.isSaveAndNext_ee)
        {
          this.postDataToTab2EEMoveToNextTab();
        }
        else
        {
          this.postDataToTAb2EESaveAndClose();
        }
      }
    },
    (error) => {                              //Error callback
      console.error('Error in Loading components API call - getaddedcomponentdetailsfortab3',error)
      console.log("Calculated Shipping unit exterior height before post error call back",shpUntHgtOnlyCntr);
      this.cntrdetforpost_ee.shp_unt_ext_height=this.transactionService.roundValueToNDecimal(shpUntHgtOnlyCntr,3);
      if(this.isSaveAndNext_ee)
      {
        this.postDataToTab2EEMoveToNextTab();
      }
      else
      {
        this.postDataToTAb2EESaveAndClose();
      }
    }
    )
}
noUpdateExteriorHeight_ee()
{
  this.enableExteriorHeightPopUp_ee=false;
  console.log(this.savedSuExteriorHeight_ee)
  this.cntrdetforpost_ee.shp_unt_ext_height=this.savedSuExteriorHeight_ee;
  if(this.isSaveAndNext_ee)
  {
     this.postDataToTab2EEMoveToNextTab();
  }
  else
  {
    this.postDataToTAb2EESaveAndClose();
  }
}

      saveThisAndEnableThirdForm_ee()
      {
        this.enableExteriorHeightPopUp_ee=false;
        this.isSaveAndNext_ee=true;

        this.cntrdetforpost_ee.empty_trays_cntr_layrs=Number(this.cntrdetforpost_ee.empty_trays_cntr_layrs);
        this.cntrdetforpost_ee.part_per_shp_unit=(this.cntrdetforpost_ee.cntr_per_layer* (this.cntrdetforpost_ee.max_layrs_shp_unt-this.cntrdetforpost_ee.empty_trays_cntr_layrs))* this.cntrdetforpost_ee.parts_per_cntr;
        this.cntrdetforpost_ee.max_cntr_per_shp_unt=this.cntrdetforpost_ee.cntr_per_layer* (this.cntrdetforpost_ee.max_layrs_shp_unt-this.cntrdetforpost_ee.empty_trays_cntr_layrs);

        if(this.cntrdetforpost_ee.cntr_material == "Corrugated Cardboard")
        {
          this.cntrdetforpost_ee.cntr_rck_colr=this.selectedcolor_ee.color_desc;
        }
        if(this.colorforcntrorrack_ee.some(e=>e.color_desc==this.selectedcolor_ee.color_desc))
        {
          this.cntrdetforpost_ee.cntr_rck_colr=this.selectedcolor_ee.color_desc;
        }
        if(this.pinpallettypeList_ee.some(e=>e.pinpallettyp.toLowerCase() == this.selectedPinPalletType_ee.pinpallettyp.toLowerCase() ))
        {
          this.cntrdetforpost_ee.palletpintyp=this.selectedPinPalletType_ee.pinpallettyp;
        }

        if(this.cntrdetforpost_ee.shp_unt_ret_type.toLowerCase()=="expendable")
        {
          this.cntrdetforpost_ee.pallettype=this.pallet_type_ee

        }
        else  if(this.cntrdetforpost_ee.shp_unt_ret_type.toLowerCase()=="returnable"){
          this.cntrdetforpost_ee.pallettype="Returnable"
        }

        if(this.unitom_ee=="E")
      {
        this.get_measure_ee = "lbs/inch";
        this.edit_measure_contcos_ee = "lbs/inch";
        this.disp_lbs = true;
      }
      else
      {
        this.get_measure_ee = "kg/cm";
        this.edit_measure_contcos_ee = "kg/cm";
        this.disp_kg = true;
      }

        // if(this.edit_measure_contcos_ee=="kg/cm")
        // {
        //   if(this.cntrdetforpost_ee.cntr_nsted_fld_height!=null)
        // {
        //   this.cntrdetforpost_ee.cntr_nsted_fld_height=Math.round((0.393701*this.cntrdetforpost_ee.cntr_nsted_fld_height)*1000)/1000;

        // }
        // }

        var shpUntHgtOnlyCntr=0;
        shpUntHgtOnlyCntr=this.cntrdetforpost_ee.cntr_height*(this.cntrdetforpost_ee.max_layrs_shp_unt-this.cntrdetforpost_ee.empty_trays_cntr_layrs);
        
        
        if(this.selectedpart_submitter_contandcos_ee.proposallastsaved_ee >= 2) 
        {

        if(this.originalTab2Details_ee.max_layrs_shp_unt != this.cntrdetforpost_ee.max_layrs_shp_unt || this.originalTab2Details_ee.empty_trays_cntr_layrs != this.cntrdetforpost_ee.empty_trays_cntr_layrs || this.originalTab2Details_ee.cntr_height != this.cntrdetforpost_ee.cntr_height)
        {
           
          this.exteriorHeightConfirmationPopUp_ee()
        }
        else
        {
          this.postDataToTab2EEMoveToNextTab();
        }
      }
        else 
        {
          console.log("Calculated Shipping unit exterior height before post last saved tab 2",shpUntHgtOnlyCntr)
          this.cntrdetforpost_ee.shp_unt_ext_height=this.transactionService.roundValueToNDecimal(shpUntHgtOnlyCntr,3);
          this.postDataToTab2EEMoveToNextTab();
        }
        
        
      }

      postDataToTab2EEMoveToNextTab()
      {
        this.conTareWgtValidation_ee();
        this.conExtLenValidation_ee();
        this.conExtWidValidation_ee();
        this.conExtHgtValidation_ee();
        this.contNestedFldedHtValidation_ee();
        this.partsPerContainerValidation_ee();
        this.maxlayprshpunitValidation_ee();
        this.cntrPerLayersValidation_ee();
        this.suExtLenValidation_ee();
        this.suExtWidValidation_ee();
        //this.suExtHgtValidation_ee();
        //this.suNestedFldedHtValidation_ee();
        
        console.log("cntrsupost",this.cntrdetforpost_ee);

        if(this.cntrdetforpost_ee.cntr_ret_type.toLowerCase() == "returnable")
        {
          this.cntrdetforpost_ee.woodispmcmplnt = "N/A";
          this.cntrdetforpost_ee.offstmpmartprsnt = "N/A";
        }

        if(!this.contarewgtInvalid_ee &&  !this.contextlenInvalid_ee  && !this.contextwidInvalid_ee && !this.partsprcntrInValid_ee && 
          !this.maxlayerprshpInValid_ee && !this.cntrperlaysInValid_ee  && !this.contexthgtInvalid_ee  &&  !this.suextlenInvalid_ee && !this.suextwidInvalid_ee &&
           !this.contNestedHtInValid_ee)
          {


        this.transactionService.savetab2details_ee(this.cntrdetforpost_ee).subscribe(result=>
          {
            var msgs:any[];
            if(result=='Success')
            {

              this.packagingproposal.addtoast({severity:'success', summary: 'success', detail: 'EE-COS,Container and Shipping Unit details saved successfully'});
                var check=false;
                this.tab2InValidationInit_ee();


            }
            if(check==false)
            {
                this.region_ee=this.transactionService.getregion();
              this.name_ee=this.transactionService.getregionNameInSmallLetters();

              this.tab3_ee='/'+this.name_ee+'/packagingprocess/proposalcreation/eepackproposaltabthree';
              this.tab3menuitem_ee={label: 'EE-Component & Material', routerLink: this.tab3_ee, icon: 'fas fa-person-booth'};
              var currentab=2;
              console.log("insave of tab2 ",this.packagingproposal.savedtab,this.selectedpart_submitter_contandcos_ee.proposallastsaved_ee)

             // if(currentab>this.packagingproposal.savedtab)
             // {
                //this.packagingproposal.savedtab=currentab;
                if(currentab>this.selectedpart_submitter_contandcos_ee.proposallastsaved_ee)
                {

                this.selectedpart_submitter_contandcos_ee.proposallastsaved_ee=2;
                this.store_primary_part_exist_ee();
                console.log(this.selectedpart_submitter_contandcos_ee);

                //this.transactionService.storelastsavedtab(this.packagingproposal.savedtab,this.region);
               // this.transactionService.storepage(this.region,'packagingprocess/proposalcreation/packproposaltabtwo');
              }
              console.log("savedtabfortesting",this.partsandattri.savedtabfortesting_ee);
              for(let i of this.packagingproposal.tabpackagingproposalmenuitems.values())
              {
                  console.log(i);
                  if(i.label!='EE-Component & Material')
                  {
                      this.flag_ee=true;
                  }
                  else{
                      this.flag_ee=false;
                      break;
                  }
              }

              if(this.flag_ee)
              {
                  this.packagingproposal.tabpackagingproposalmenuitems.push(this.tab3menuitem_ee);
              }

              this.packagingproposal.setActiveItemDynamically(this.tab3_ee,this.tab3menuitem_ee);





          }
            else
            {
                console.log(result,"Success");
                this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'EE-COS,Container and Shipping Unit details is not saved! try again'});
                this.tab2InValidationInit_ee();
            }

          });

          }
      }

      save_tab2_ee()
      {
        this.enableExteriorHeightPopUp_ee=false;
        this.isSaveAndNext_ee=false;

        this.cntrdetforpost_ee.empty_trays_cntr_layrs=Number(this.cntrdetforpost_ee.empty_trays_cntr_layrs);
        this.cntrdetforpost_ee.part_per_shp_unit=(this.cntrdetforpost_ee.cntr_per_layer* (this.cntrdetforpost_ee.max_layrs_shp_unt-this.cntrdetforpost_ee.empty_trays_cntr_layrs))* this.cntrdetforpost_ee.parts_per_cntr;
        this.cntrdetforpost_ee.max_cntr_per_shp_unt=this.cntrdetforpost_ee.cntr_per_layer* (this.cntrdetforpost_ee.max_layrs_shp_unt-this.cntrdetforpost_ee.empty_trays_cntr_layrs);

        if(this.cntrdetforpost_ee.cntr_material == "Corrugated Cardboard")
       {
         this.cntrdetforpost_ee.cntr_rck_colr=this.selectedcolor_ee.color_desc;
       }

        if(this.colorforcntrorrack_ee.some(e=>e.color_desc==this.selectedcolor_ee.color_desc))
        {
          this.cntrdetforpost_ee.cntr_rck_colr=this.selectedcolor_ee.color_desc;
        }
        if(this.pinpallettypeList_ee.some(e=>e.pinpallettyp.toLowerCase() == this.selectedPinPalletType_ee.pinpallettyp.toLowerCase() ))
        {
          this.cntrdetforpost_ee.palletpintyp=this.selectedPinPalletType_ee.pinpallettyp;
        }

        if(this.cntrdetforpost_ee.shp_unt_ret_type.toLowerCase()=="expendable")
        {
          this.cntrdetforpost_ee.pallettype=this.pallet_type_ee

        }
        else  if(this.cntrdetforpost_ee.shp_unt_ret_type.toLowerCase()=="returnable"){
          this.cntrdetforpost_ee.pallettype="Returnable"
        }

        if(this.unitom_ee=="E")
        {
          this.get_measure_ee = "lbs/inch";
          this.edit_measure_contcos_ee = "lbs/inch";
          this.disp_lbs = true;
        }
        else
        {
          this.get_measure_ee = "kg/cm";
          this.edit_measure_contcos_ee = "kg/cm";
          this.disp_kg = true;
        }


        // if(this.edit_measure_contcos_ee=="kg/cm")
        // {
        //   if(this.cntrdetforpost_ee.cntr_nsted_fld_height!=null)
        // {
        //   this.cntrdetforpost_ee.cntr_nsted_fld_height=Math.round((0.393701*this.cntrdetforpost_ee.cntr_nsted_fld_height)*1000)/1000;

        // }
        // }

        var shpUntHgtOnlyCntr=0;
        shpUntHgtOnlyCntr=this.cntrdetforpost_ee.cntr_height*(this.cntrdetforpost_ee.max_layrs_shp_unt-this.cntrdetforpost_ee.empty_trays_cntr_layrs);
        

        if((this.selectedpart_submitter_contandcos_ee.proposallastsaved_ee >= 2) && (this.originalTab2Details_ee.max_layrs_shp_unt != this.cntrdetforpost_ee.max_layrs_shp_unt || this.originalTab2Details_ee.empty_trays_cntr_layrs != this.cntrdetforpost_ee.empty_trays_cntr_layrs || this.originalTab2Details_ee.cntr_height != this.cntrdetforpost_ee.cntr_height))
        {
          this.exteriorHeightConfirmationPopUp_ee()
        }
        else
        {
          console.log("Calculated Shipping unit exterior height before post last saved tab 2",shpUntHgtOnlyCntr)
          this.cntrdetforpost_ee.shp_unt_ext_height=this.transactionService.roundValueToNDecimal(shpUntHgtOnlyCntr,3);
          this.postDataToTAb2EESaveAndClose();
        }
        
        
      }

      postDataToTAb2EESaveAndClose()
      {
        this.conTareWgtValidation_ee();
        this.conExtLenValidation_ee();
        this.conExtWidValidation_ee();
        this.conExtHgtValidation_ee();
        this.contNestedFldedHtValidation_ee();
        this.partsPerContainerValidation_ee();
        this.maxlayprshpunitValidation_ee();
        this.cntrPerLayersValidation_ee();
        this.suExtLenValidation_ee();
        this.suExtWidValidation_ee();
        //this.suExtHgtValidation_ee();
        //this.suNestedFldedHtValidation_ee();
        
        console.log("cntrsupost",this.cntrdetforpost_ee);

        if(this.cntrdetforpost_ee.cntr_ret_type.toLowerCase() == "returnable")
        {
          this.cntrdetforpost_ee.woodispmcmplnt = "N/A";
          this.cntrdetforpost_ee.offstmpmartprsnt = "N/A";
        }

        if(!this.contarewgtInvalid_ee &&  !this.contextlenInvalid_ee  && !this.contextwidInvalid_ee && !this.contexthgtInvalid_ee && !this.partsprcntrInValid_ee && !this.maxlayerprshpInValid_ee && 
          !this.cntrperlaysInValid_ee && !this.suextlenInvalid_ee && !this.suextwidInvalid_ee &&  !this.suextlenInvalid_ee && !this.suextwidInvalid_ee &&
          !this.contNestedHtInValid_ee)
          {

        this.transactionService.savetab2details_ee(this.cntrdetforpost_ee).subscribe(result=>
          {
            var msgs:any[];
            if(result=='Success')
            {
              this.packagingprocess.addtoastinpackagingprocess({severity:'success', summary: 'success', detail: 'EE-COS,Container and Shipping Unit details saved successfully'});
              var check=false;
              this.tab2InValidationInit_ee();
            }
            if(check==false)
            {
              this.region_ee=this.transactionService.getregion();
              this.name_ee=this.transactionService.getregionNameInSmallLetters();

              var currentab=2;
              console.log("insave of tab2 ",this.packagingproposal.savedtab,this.selectedpart_submitter_contandcos_ee.proposallastsaved_ee)


                if(currentab>this.selectedpart_submitter_contandcos_ee.proposallastsaved_ee)
                {

                this.selectedpart_submitter_contandcos_ee.proposallastsaved_ee=2;
                this.store_primary_part_exist_ee();
                console.log(this.selectedpart_submitter_contandcos_ee);
                }

                if(this.selectedpart_submitter_contandcos_ee.propsl_type!=null && this.selectedpart_submitter_contandcos_ee.propsl_type.toLowerCase()=="migration")
                {
                  if(this.roleid_contandcos_ee==1)
                  {
                    this.router.navigate(['/'+this.name_ee+'/packagingprocess/packsupplieradmin/migrationsubmitter'])
                  }
                  else if(this.roleid_contandcos_ee==2)
                  {
                    this.router.navigate(['/'+this.name_ee+'/packagingprocess/packdesignsourceadmin/migrationsubmitter'])
                  }
                }
                else //if(this.selectedpart_submitter.propsl_type!=null && this.selectedpart_submitter.propsl_type.toLowerCase()=="normal")
                {



                if(this.roleid_contandcos_ee==1)
                {
                  this.router.navigate(['/'+this.name_ee+'/packagingprocess/packsupplieradmin/submiterdashboard'])
                }
                else if(this.roleid_contandcos_ee==2)
                {
                  this.router.navigate(['/'+this.name_ee+'/packagingprocess/packdesignsourceadmin/submiterdashboard'])
                }
              }


            }
            else
            {
                console.log(result,"Success");
                this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'EE-COS,Container and Shipping Unit details is not saved! try again'});
                this.tab2InValidationInit_ee();
            }
          }
        );

          }
      }


      disable_save_func_ee()
      {
       //  // <!-- New changes are here, just added --  || !cntrdetforpost_ee.max_layrs_shp_unt || !this.cntrdetforpost_ee.cntr_per_layer ||  -->
       if( !this.iscntrselected_ee || !this.cntrdetforpost_ee.used_carry_ovr_pck  || !this.cntrdetforpost_ee.parts_per_cntr || !this.cntrdetforpost_ee.max_layrs_shp_unt || !this.cntrdetforpost_ee.cntr_per_layer ||
        !(this.stampingprop_ee==false || ((this.stampingprop_ee==true) && ((this.cntrdetforpost_ee.blanksperstack>0) && (this.cntrdetforpost_ee.stacksperpallet>0) && (this.selectedPinPalletType_ee.pinpallettyp!=='')))) ||
        !(this.containerdetails_ee.exp_refundable=='Returnable' || (this.containerdetails_ee.exp_refundable=='Expendable' && this.pallet_type_ee!==''))  ||
        !(
            !((this.cntrdetforpost_ee.cntr_base != null && this.cntrdetforpost_ee.cntr_base.indexOf('IMC')>-1 && this.containerdetails_ee.exp_refundable=='Expendable' && this.containerdetails_ee.cntr_grp_desc != null && this.containerdetails_ee.cntr_grp_desc.toLowerCase().indexOf('imc')>-1 )) ||

            (
              (this.cntrdetforpost_ee.cntr_base != null && this.cntrdetforpost_ee.cntr_base.indexOf('IMC')>-1) && (this.containerdetails_ee.exp_refundable=='Expendable') && (this.containerdetails_ee.cntr_grp_desc != null && this.containerdetails_ee.cntr_grp_desc.toLowerCase().indexOf('imc')>-1 )
               //Removed && this.cntrdetforpost_ee.iscartonimcspec && this.cntrdetforpost_ee.imcwtdisc && this.cntrdetforpost_ee.cartonburststrnth>0  
            )
         ) ||
        !(
            !(( this.containerdetails_ee.exp_refundable=='Expendable' && (this.selected_cntr_mat_ee.cntr_matr_desc!=null && this.selected_cntr_mat_ee.cntr_matr_desc.toLowerCase().includes('cardboard')))) ||

             (
                (this.containerdetails_ee.exp_refundable=='Expendable') && (this.selected_cntr_mat_ee.cntr_matr_desc!=null && this.selected_cntr_mat_ee.cntr_matr_desc.toLowerCase().includes('cardboard'))  &&
                ((this.cntrdetforpost_ee.bct != null && this.cntrdetforpost_ee.bct>0) || (this.cntrdetforpost_ee.ect != null && this.cntrdetforpost_ee.ect>0)) //&& this.cntrdetforpost_ee.imcwtdisc && this.cntrdetforpost_ee.iscartonimcspec && this.cntrdetforpost_ee.cartonburststrnth>0
              )
        )//(this.containerdetails_ee.exp_refundable=='Expendable' &&
        ||!(this.cntrdetforpost_ee.cntr_material!=null && this.cntrdetforpost_ee.cntr_material!="")
        || !(this.cntrdetforpost_ee.cntr_ext_len) || !(this.cntrdetforpost_ee.cntr_width) || !(this.cntrdetforpost_ee.cntr_height) || !(this.cntrdetforpost_ee.shp_unit_ext_len) || !(this.cntrdetforpost_ee.cntr_tar_weight) || !(this.cntrdetforpost_ee.shp_unt_ext_width) || !(this.cntrdetforpost_ee.cntr_nsted_fld_height)
      )
    {

      return true;
    }
    else
    {
      return false;
    }
      }


}
