/* tslint:disable */
import { DatePipe } from '@angular/common';
import { IfStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TransactionService } from '../loginpage/Transaction.service';
import { addContainer, cntrDetailsFromCMMS, containerCurrencyList, containerDetailsCntrAdmin, containerGroupActiveList, containerMasterCategory, containerMaterialList, containerOwnershipList, containerTypeCode, contoncoslist, GridContInr, plantlist, regiondetails } from '../loginpage/transactions';
import { PackagingprocessComponent } from '../packagingprocess/packagingprocess.component';

@Component({
  selector: 'app-containerrequest',
  templateUrl: './containerrequest.component.html',
  styleUrls: ['./containerrequest.component.css']
})
export class ContainerrequestComponent implements OnInit {

  containerRequestScreenCdsid:string=null;
  containerRequestScreenCurrentRegion:string=null;
  currentRegionCodeSub:string=null;
  containerRequestScreenRoleid:number=null;

  regionDetails:regiondetails={
    cd_region: '',
    cntr_ergo_limit:0,
    bom_err_thld: 0,
    allow_nonstd_comp_ind: '',
    urge_prt_notice_email_freq: 0,
    allow_multi_prt_prop_ind: '',
    limit_prts_recvd_plt: '',
    emg_exp_pkg_reqd_ind: '',
    pkg_rel_level_src: '',
    suspd_duratn_thld:0,
    uom:''
  }

  screenName='';
  add_measure='';
  prev_add_measure='';
  noRoleSelectedDialog=false;
  noRoleContent:string=null;
  noSupDialog=false;
  content:string=null;
  noDesignsourceDialog=false;
  contentDesignsource:string=null;

  enteredCntrBase:string=null;
  prevCntrSuffixInd:string=null;

  selectedCntrFromAutoCmplete:any;
  cntrBaseListForAutoComplete:GridContInr[]=[];

  userCode:string=null;

  selectedFromContainerAdmin=false;

  cntrSuffixAdd:string=null;
  cntrSuffixEnabled=false;

  plantList:plantlist[]=[];
  selectedPlantList:plantlist={cd_plant:'',plantGSDBCode:'',plant_active_stat:'',plant_name:'',plant_type_name:''};

  cntrMasterCategory:containerMasterCategory[]=[];
  selected_cntr_master_category:containerMasterCategory={cd_cntr_mstr_catg:0,cntr_mstr_catg_desc:'',updated_by:'',updated_dt:'',cntr_mstr_catg:'',active_stat:'',active_stat_display:false};

  cntrTypeCode:containerTypeCode[]=[];
  unmodifiedTypeCodes:containerTypeCode[]=[];
  selected_cntr_type_code:containerTypeCode={cd_cntr_typ:'',cntr_typ_desc:'',typ_no:0};

  cntrOwnershipstratcode:containerOwnershipList[]=[];
  selected_cntr_ownrship_strat_code:containerOwnershipList={cd_region:'',ownrshp_strat_code:'',ownrshp_desc:'',cd_ownrshp_strat:0};

  cntrGrp:containerGroupActiveList[]=[];
  selected_cntr_group:containerGroupActiveList={cntr_grp:''};

  cntrMaterial:containerMaterialList[]=[];
  selected_cntr_material:containerMaterialList={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
  enable_material_dropdown=false;

  cntrCurrencyList:containerCurrencyList[]=[];
  selected_cntr_currency_list:containerCurrencyList={cd_curr:0,curr_desc:''};

  ownrship_catg=[{ownrshp_catg:'Ford'}, {ownrshp_catg:'Supplier'},{ownrshp_catg:'Third Party'}];
  selected_ownrship_catg={ownrshp_catg:''};

  AddSuffixAddedBecauseOfOwnership:boolean=false;
  containerOrComponentBase:string=null;
  ownershipCodeSelected:string=null;
  disabledAddSuffixToCntrButton=false;
  disabledAddSuffixToCompButton=false; 

  // This variable will true until we check the CMMS table to know whether base - suffix combination exist or not
  disableCntrDetailsWhenBaseOrSuffixChanged=true;

  collapsedHtInValid=false;
  collapsedHtInValidReason:string=null;
  nestRatioInValid=false;
  nestRatioInValidReason:string=null;
  nestRatio1InValid=false;
  nestRatio1InValidReason:string=null;
  cntrTareWtInValid=false;
  cntrTareWtInValidReason:string=null;
  cntrCapWtInValid=false;
  cntrCapWtInValidReason:string=null;
  cntrPerLayerInValid=false;
  cntrPerLayerInValidReason:string=null;
  maxLayerPerSuInValid=false;
  maxLayerPerSuInValidReason:string=null;
  cntrQtPerMinSuInValid=false;
  cntrQtPerMinSuInvalidReason:string=null;
  MaxCntrPerSuInValid=false;
  MaxCntrPerMinSuInvalidReason:string=null;
  cntrExtLInValid=false;
  cntrExtLInValidReason:string=null;
  cntrExtWInValid=false;
  cntrExtWInValidReason:string=null;
  cntrExtHInValid=false;
  cntrExtHInValidReason:string=null;
  cntrIntLInValid=false;
  cntrIntLInValidReason:string=null;
  cntrIntWInValid=false;
  cntrIntWInValidReason:string=null;
  cntrIntHInValid=false;
  cntrIntHInValidReason:string=null;
  suNestedHtInValid=false;
  suNestedHtInValidReason:string=null;
  suTareWtInvalid=false;
  suTareWtInValidReason:string=null;
  wareHourseStackHtInvaid=false;
  wareHourseStackHtInvaidReason:string=null;
  suStackHtInvalid=false;
  suStackHtInvalidReason:string=null;
  suExtLInValid=false;
  suExtLInValidReason:string=null;
  suExtWInValid=false;
  suExtWInValidReason:string=null;
  suExtHInValid=false;
  suExtHInValidReason:string=null;
  cntrBaseInvalid=true;
  cntrBaseInvalidReason:string=null;
  cntrSuffixInvalid=false;
  cntrSuffixInvalidReason=null;
  cntrDescInvalid=false;
  cntrDescInvalidReason:string=null;
  plantCodeInvalid=false;
  plantCodeInvalidReason:string=null;
  cardOrCallInvalid=false;
  cardOrCallInvalidReason:string=null;
  mastrCatgInvalid=false;
  mastrCatgInvalidReason:string=null;
  retTypeInvalid=false;
  retTypeInvalidReason:string=null;
  cntrTypeInvalid=false;
  cntrTypeInvalidReason:string=null;
  cntrMaterialInvalid=false;
  cntrMaterialInvalidReason:string=null;
  cosInvalid=false;
  cosInvalidReason:string=null;
  cntrGrpInvalid=false;
  cntrGrpInvalidReason:string=null;
  isCollapsibleInvalid=false;
  isCollapsibleInvalidReason:string=null;
  cntrCntctInvalid=false;
  cntrCntctInvalidReason:string=null;
  cntctPhoneInvalid=false;
  cntctPhoneInvlidReason:string=null;
  cntrActiveInvalid=false;
  cntrActiveInvalidReason=null;


  isCmmsLoadedContainer=false;
  isCmmsLoadedComponent=false;

  enableProcessingNotifiacationDialog=false;

  enableCntrEditPendingNotification=false;
    enableAddingNewContainerNotification=false;
    enableUpdateExistingCntrNotification=false;

  cntrdetailsfromload:cntrDetailsFromCMMS={
    no_cntr_base:'',
      no_cntr_suffix:'',
      ms_cntr_lt:0,
      ms_cntr_ht:0,
      ms_cntr_wd:0,
      ms_cntr_clpsd_ht:0,
      ms_cntr_tare_wt:0,
      ms_max_cap_wt:0,
      qt_cntr_stack_ht:0,
      qt_whse_stack_ht:0,
      qt_per_min_su:0,
      cd_cntr_type:'',
      cd_owner:'',
      na_ford_cntct:'',
      no_cntct_phone:'',
      cs_cntr_cost:'',
      ds_cntr_note:'',
      ds_cntr:'',
      id_user:'',
      ts_last_update:'',
      no_nesting_ratio1:0,
      no_nesting_ratio2:0,
      no_cntct_phone_1:''
  }

  cntrdetailsadd:addContainer=
  {
      // cd_cntr:0,
      function:'',
      cntr_suff_ind:'',
      exp_refundable:'',
      cntr_active_ind:'',
      cntr_base:'',
      cntr_suffix:'',
      cntr_desc:'',
      card_call:'',
      cntr_mstr_catg:'',
      cntr_typ_desc:'',
      ownrshp_strat_code:'',
      ownrshp_catg:'',
      cntr_grp_desc:'',
      cntr_exterior_l:0,
      cntr_exterior_w:0,
      cntr_exterior_h:0,
      collapsed_ht:0.0,
      nest_ratio:0,
      cntr_interior_l:0,
      cntr_interior_w:0,
      cntr_interior_h:0,
      cntr_tare_wt:0,
      cntr_capacity:0,
      cntr_per_layer:0,
      max_layers_shpg_unit:0,
      max_cntr_per_shpg_unit:0,
      cntr_matr_desc:'',
      cntr_drawing_no:'',
      shpg_unit_exterior_l:0,
      shpg_unit_exterior_w:0,
      shpg_unit_exterior_h:0,
      shpg_unit_nstdorflded_ht:0,
      shpg_unit_tare_wt:0,
      shpg_unit_stack_ht:0,
      warehouse_stack_ht:0,
      service_life:'',
      currency_desc:'',
      purchase_cost:0,
      rental_issue_cost:0,
      sup_rntl_dly_cost:0,
      ford_rntl_dly_cost:0,
      return_rntl_cost:0,
      tooling_dev_cost:0,
      extr_cntr_dsgn_img_ind:'No',
      intr_cntr_dsgn_img_ind:'No',
      shpg_unit_img_ind:'No',
      cad_img_ind:'No',
      img_notes:'',
      cd_region:'',
      iscontainercolapsble:'',
      cd_plant:'',
      cntr_qt_per_min_su:0,
      nest_ratio_1:0,
      ford_cntct:'',
      cntct_phone:'',
      id_user:'',
      ts_last_update:'',
      req_stat:'',
      components:[{
          mp_function:'',
          no_comp_base:'',
          no_comp_suffix:'',
          ds_comp:'',
          comp_qt_per_min_su:0,
          comp_cd_owner:'',
          in_comp:'',
          comp_cd_type:''
      }],
      UoM:'E',
      dbia_req_no:0,
      typ_no:0
  }

  cntrOwnershipstratcodelist:containerOwnershipList[]=[];
  selected_cntr_ownrship_strat_code_in_popup:containerOwnershipList={cd_region:'',ownrshp_strat_code:'',ownrshp_desc:'',cd_ownrshp_strat:0};

  get_measure='';
  cont_retype='Returnable'; // default value for copy container pop up
  cont_grid_text='';
  cont_present_table=false;
  cont_cols:any;
  enabledetails_cntr=false;
  cntrdetails_display:containerDetailsCntrAdmin;
  containerDetailsCopied=false;
  cntrDetailsForCopying={
    cd_cntr:0,
    cntr_suff_ind:'',
    exp_refundable:'',
    cntr_active_ind:'',
    cntr_base:'',
    cntr_suffix:'',
    cntr_desc:'',
    card_call:'',
    cntr_mstr_catg:'',
    cntr_typ_desc:'',
    ownrshp_strat_code:'',
    ownrshp_catg:'',
    cntr_grp_desc:'',
    cntr_exterior_l:0,
	  cntr_exterior_w:0,
	  cntr_exterior_h:0,
    collapsed_ht:0.0,
    nest_ratio:0,
    cntr_interior_l:0,
    cntr_interior_w:0,
    cntr_interior_h:0,
    cntr_tare_wt:0,
    cntr_capacity:0,
    cntr_per_layer:0,
    max_layers_shpg_unit:0,
    max_cntr_per_shpg_unit:0,
    cntr_matr_desc:'',
    cntr_drawing_no:'',
    shpg_unit_exterior_l:0,
    shpg_unit_exterior_w:0,
    shpg_unit_exterior_h:0,
    shpg_unit_nstdorflded_ht:0,
    shpg_unit_tare_wt:0,
    shpg_unit_stack_ht:0,
    warehouse_stack_ht:0,
    service_life:'',
    currency_desc:'',
    purchase_cost:0,
    rental_issue_cost:0,
    sup_rntl_dly_cost:0,
    ford_rntl_dly_cost:0,
    return_rntl_cost:0,
    tooling_dev_cost:0,
    extr_cntr_dsgn_img_ind:'',
    intr_cntr_dsgn_img_ind:'',
    shpg_unit_img_ind:'',
    cad_img_ind:'',
    img_notes:'',
    cd_region:'',
    iscontainercolapsble:'',
    cd_plant: '',
    cnt_qt_per_min_su: 0,
    nest_ratio_1: 0,
    ford_cntct: '',
    cntct_phone: '',
    req_stat: '',
    id_user: '',
    ts_last_update: '',
    uom:'E',

  }


  select_contcos_dialog=false;
  contlist_oncos:contoncoslist[]=[];

  selected_container:contoncoslist=null;
  table_selected_container:contoncoslist=null;

  tableSelectedReturnType='';
  table_selected_cntr_ownrship_strat_code_in_popup:containerOwnershipList={cd_region:'',ownrshp_strat_code:'',ownrshp_desc:'',cd_ownrshp_strat:0};


  containerOrComponentInDetails:string;


  cntrAddDetailsEnable=false;
  compAddDetailsEnable=false;


  enteredCompBase:string=null;
  prevCompSuffixInd:string=null;

  selectedCompFromAutoCmplete:any;
  compBaseListForAutoComplete:GridContInr[]=[];


  selectedFromComponentAdmin=false;

  compSuffixAdd:string=null;
  compSuffixEnabled=false;


  selectedPlantListComp:plantlist={cd_plant:'',plantGSDBCode:'',plant_active_stat:'',plant_name:'',plant_type_name:''};


  selected_comp_master_category:containerMasterCategory={cd_cntr_mstr_catg:0,cntr_mstr_catg_desc:'',updated_by:'',updated_dt:'',cntr_mstr_catg:'',active_stat:'',active_stat_display:false};

  cntrTypeCodeComp:containerTypeCode[]=[];
  // unmodifiedTypeCodes:containerTypeCode[]=[];
  selected_comp_type_code:containerTypeCode={cd_cntr_typ:'',cntr_typ_desc:'',typ_no:0};


  selected_comp_ownrship_strat_code:containerOwnershipList={cd_region:'',ownrshp_strat_code:'',ownrshp_desc:'',cd_ownrshp_strat:0};


  selected_comp_group:containerGroupActiveList={cntr_grp:''};


  selected_comp_material:containerMaterialList={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
  enable_material_dropdown_comp=false;


  selected_comp_currency_list:containerCurrencyList={cd_curr:0,curr_desc:''};

  selected_ownrship_catg_comp={ownrshp_catg:''};

  // This variable will true until we check the CMMS table to know whether base - suffix combination exist or not
  disableCompDetailsWhenBaseOrSuffixChanged=true;



  compTareWtInValid=false;
  compTareWtInValidReason:string=null;
  compCapWtInValid=false;
  compCapWtInValidReason:string=null;
  compQtPerMinSuInValid=false;
  compQtPerMinSuInvalidReason:string=null;
  compExtLInValid=false;
  compExtLInValidReason:string=null;
  compExtWInValid=false;
  compExtWInValidReason:string=null;
  compExtHInValid=false;
  compExtHInValidReason:string=null;
  wareHourseStackHtInvaidComp=false;
  wareHourseStackHtInvaidReasonComp:string=null;
  compBaseInvalid=false;
  compBaseInvalidReason:string=null;
  compSuffixInvalid=false;
  compSuffixInvalidReason=null;
  compDescInvalid=false;
  compDescInvalidReason:string=null;
  plantCodeInvalidComp=false;
  plantCodeInvalidReasonComp:string=null;
  mastrCatgInvalidComp=false;
  mastrCatgInvalidReasonComp:string=null;
  retTypeInvalidComp=false;
  retTypeInvalidReasonComp:string=null;
  compTypeInvalid=false;
  compTypeInvalidReason:string=null;
  compMaterialInvalid=false;
  compMaterialInvalidReason:string=null;
  cosInvalidComp=false;
  cosInvalidReasonComp:string=null;
  compGrpInvalid=false;
  compGrpInvalidReason:string=null;
  compCntctInvalid=false;
  compCntctInvalidReason:string=null;
  cntctPhoneInvalidComp=false;
  cntctPhoneInvlidReasonComp:string=null;
  compActiveInvalid=false;
  compActiveInvalidReason=null;

  compdetailsfromload:cntrDetailsFromCMMS={
    no_cntr_base:'',
      no_cntr_suffix:'',
      ms_cntr_lt:0,
      ms_cntr_ht:0,
      ms_cntr_wd:0,
      ms_cntr_clpsd_ht:0,
      ms_cntr_tare_wt:0,
      ms_max_cap_wt:0,
      qt_cntr_stack_ht:0,
      qt_whse_stack_ht:0,
      qt_per_min_su:0,
      cd_cntr_type:'',
      cd_owner:'',
      na_ford_cntct:'',
      no_cntct_phone:'',
      cs_cntr_cost:'',
      ds_cntr_note:'',
      ds_cntr:'',
      id_user:'',
      ts_last_update:'',
      no_nesting_ratio1:0,
      no_nesting_ratio2:0,
      no_cntct_phone_1:''
  }

  compdetailsadd:addContainer=
  {
      // cd_cntr:0,
      function:'',
      cntr_suff_ind:'',
      exp_refundable:'',
      cntr_active_ind:'',
      cntr_base:'',
      cntr_suffix:'',
      cntr_desc:'',
      card_call:'',
      cntr_mstr_catg:'',
      cntr_typ_desc:'',
      ownrshp_strat_code:'',
      ownrshp_catg:'',
      cntr_grp_desc:'',
      cntr_exterior_l:0,
      cntr_exterior_w:0,
      cntr_exterior_h:0,
      collapsed_ht:0.0,
      nest_ratio:0,
      cntr_interior_l:0,
      cntr_interior_w:0,
      cntr_interior_h:0,
      cntr_tare_wt:0,
      cntr_capacity:0,
      cntr_per_layer:0,
      max_layers_shpg_unit:0,
      max_cntr_per_shpg_unit:0,
      cntr_matr_desc:'',
      cntr_drawing_no:'',
      shpg_unit_exterior_l:0,
      shpg_unit_exterior_w:0,
      shpg_unit_exterior_h:0,
      shpg_unit_nstdorflded_ht:0,
      shpg_unit_tare_wt:0,
      shpg_unit_stack_ht:0,
      warehouse_stack_ht:0,
      service_life:'',
      currency_desc:'',
      purchase_cost:0,
      rental_issue_cost:0,
      sup_rntl_dly_cost:0,
      ford_rntl_dly_cost:0,
      return_rntl_cost:0,
      tooling_dev_cost:0,
      extr_cntr_dsgn_img_ind:'No',
      intr_cntr_dsgn_img_ind:'No',
      shpg_unit_img_ind:'No',
      cad_img_ind:'No',
      img_notes:'',
      cd_region:'',
      iscontainercolapsble:'',
      cd_plant:'',
      cntr_qt_per_min_su:0,
      nest_ratio_1:0,
      ford_cntct:'',
      cntct_phone:'',
      id_user:'',
      ts_last_update:'',
      req_stat:'',
      components:[{
          mp_function:'',
          no_comp_base:'',
          no_comp_suffix:'',
          ds_comp:'',
          comp_qt_per_min_su:0,
          comp_cd_owner:'',
          in_comp:'',
          comp_cd_type:''
      }],
      UoM:'E',
      dbia_req_no:0,
      typ_no:0
  }



  selected_comp_ownrship_strat_code_in_popup:containerOwnershipList={cd_region:'',ownrshp_strat_code:'',ownrshp_desc:'',cd_ownrshp_strat:0};


  comp_retype='Returnable'; // default value for copy container pop up
  comp_grid_text='';
  comp_present_table=false;
  comp_cols:any;
  enabledetails_comp=false;
  compdetails_display:containerDetailsCntrAdmin;
  componentDetailsCopied=false;
  compDetailsForCopying={
    cd_cntr:0,
    cntr_suff_ind:'',
    exp_refundable:'',
    cntr_active_ind:'',
    cntr_base:'',
    cntr_suffix:'',
    cntr_desc:'',
    card_call:'',
    cntr_mstr_catg:'',
    cntr_typ_desc:'',
    ownrshp_strat_code:'',
    ownrshp_catg:'',
    cntr_grp_desc:'',
    cntr_exterior_l:0,
	  cntr_exterior_w:0,
	  cntr_exterior_h:0,
    collapsed_ht:0.0,
    nest_ratio:0,
    cntr_interior_l:0,
    cntr_interior_w:0,
    cntr_interior_h:0,
    cntr_tare_wt:0,
    cntr_capacity:0,
    cntr_per_layer:0,
    max_layers_shpg_unit:0,
    max_cntr_per_shpg_unit:0,
    cntr_matr_desc:'',
    cntr_drawing_no:'',
    shpg_unit_exterior_l:0,
    shpg_unit_exterior_w:0,
    shpg_unit_exterior_h:0,
    shpg_unit_nstdorflded_ht:0,
    shpg_unit_tare_wt:0,
    shpg_unit_stack_ht:0,
    warehouse_stack_ht:0,
    service_life:'',
    currency_desc:'',
    purchase_cost:0,
    rental_issue_cost:0,
    sup_rntl_dly_cost:0,
    ford_rntl_dly_cost:0,
    return_rntl_cost:0,
    tooling_dev_cost:0,
    extr_cntr_dsgn_img_ind:'',
    intr_cntr_dsgn_img_ind:'',
    shpg_unit_img_ind:'',
    cad_img_ind:'',
    img_notes:'',
    cd_region:'',
    iscontainercolapsble:'',
    cd_plant: '',
    cnt_qt_per_min_su: 0,
    nest_ratio_1: 0,
    ford_cntct: '',
    cntct_phone: '',
    req_stat: '',
    id_user: '',
    ts_last_update: '',
    uom:'E'
  }


  select_contcos_dialog_comp=false;
  contlist_oncos_comp:contoncoslist[]=[];

  selected_component:contoncoslist=null;
  table_selected_component:contoncoslist=null;

  tableSelectedReturnTypeComp='';
  table_selected_comp_ownrship_strat_code_in_popup:containerOwnershipList={cd_region:'',ownrshp_strat_code:'',ownrshp_desc:'',cd_ownrshp_strat:0};



  enableCompEditPendingNotification=false;
    enableAddingNewCompNotification=false;
    enableUpdateExistingCompNotification=false;



  constructor(private transactionService:TransactionService,private router:Router,private date:DatePipe,private packagingprocess:PackagingprocessComponent) { }

  ngOnInit(): void {
    this.containerRequestScreenCdsid=this.transactionService.getcdsid();
    this.containerRequestScreenCurrentRegion=this.transactionService.getregion();

    this.disableCntrDetailsWhenBaseOrSuffixChanged=true;
    this.screenName='';
    this.validationInit();

    this.AddSuffixAddedBecauseOfOwnership=false;
    this.containerOrComponentBase=null;
    this.ownershipCodeSelected=null;
    this.disabledAddSuffixToCntrButton=false;
    this.disabledAddSuffixToCompButton=false; 

    this.cont_retype="Returnable";
    this.cont_grid_text="";
    this.cont_present_table=false;
    this.enabledetails_cntr=false;
    this.containerDetailsCopied=false;

    this.comp_retype='Returnable';
    this.comp_grid_text='';
    this.comp_present_table=false;
    this.enabledetails_comp=false;
    this.componentDetailsCopied=false;


    this.currentRegionCodeSub = this.transactionService.getregioncode(this.containerRequestScreenCurrentRegion)

    this.containerRequestScreenRoleid=this.transactionService.getcurrentrole(this.containerRequestScreenCurrentRegion)

    console.log('containerRequestScreenRoleid',this.containerRequestScreenRoleid);


    this.userCode='';
    this.containerOrComponentInDetails='container';
    this.transactionService.get_region_details(this.currentRegionCodeSub).subscribe(regiondetails=>{
      this.regionDetails=regiondetails;
      // alert(JSON.stringify(this.regionDetails));
      if(this.regionDetails.uom!='' && this.regionDetails.uom!=undefined && this.regionDetails.uom.toLowerCase() == 'm')
      {
        this.add_measure='kg/cm';
        this.prev_add_measure='kg/cm';
      }
      else
      {
        this.add_measure='lbs/inch';
        this.prev_add_measure='lbs/inch';
      }
    })

    if(this.containerRequestScreenRoleid==null && this.containerRequestScreenRoleid!=1 && this.containerRequestScreenRoleid!=2)
    {
      this.noRoleSelectedDialog=true;
      this.noRoleContent='No role selected '
    }


    if(this.containerRequestScreenRoleid==1)
    {
      this.userCode=this.transactionService.get_supplier(this.containerRequestScreenCurrentRegion);
      this.screenName='Supplier Container Request Form'
      console.log(this.userCode);
    }

    else if(this.containerRequestScreenRoleid==2)
    {
      this.userCode=this.transactionService.get_designsource(this.containerRequestScreenCurrentRegion);
      this.screenName='Design Source Container Request Form'
      console.log(this.userCode);
    }

    if(this.containerRequestScreenRoleid==1)
    {
      if(this.userCode==null)
      {
        this.noSupDialog=true;
        this.content='Please select supplier';
      }
    }

    else if(this.containerRequestScreenRoleid==2)
    {
      if(this.userCode==null)
      {
        this.noDesignsourceDialog=true;
        this.contentDesignsource='Please select Design Source'
      }
    }

   if(this.containerRequestScreenRoleid!=null && (this.containerRequestScreenRoleid==1 || this.containerRequestScreenRoleid==2) && this.userCode!=null)
    {
      this.cntrdetails_display_popup_init();
      this.compdetails_display_popup_init();


      this.transactionService.getPlantListonRegion(this.currentRegionCodeSub).subscribe(plantcodes=>{
        console.log('plantcodes',plantcodes);
        this.plantList=plantcodes.filter(e=>e.plant_active_stat.toLowerCase()=='yes');
        /*if(this.plantList.length!=0)
         {
          this.selectedPlantList=this.plantList[0];
        } */
      })

      this.transactionService.getMasterCategory().subscribe(mastcatg=>{
        console.log('mastcatg',mastcatg);
        this.cntrMasterCategory=mastcatg;
        
      });

      this.transactionService.getContainerTypeCode().subscribe(cntrtypecode=>{
          // this.cntrTypeCode=cntrtypecode.filter(e=>["01"].includes(String(e.cd_cntr_typ)));
          this.unmodifiedTypeCodes=cntrtypecode;
          this.containerChange();
          this.componentChange();
          //
          // this.selected_cntr_type_code=this.cntrTypeCode[0];

      });

      this.transactionService.getOwnershipStrategyCode(this.currentRegionCodeSub).subscribe(cntrownrship=>{
        this.cntrOwnershipstratcodelist=cntrownrship;
        this.selected_cntr_ownrship_strat_code_in_popup=this.cntrOwnershipstratcodelist[0];
        this.selected_comp_ownrship_strat_code_in_popup=this.cntrOwnershipstratcodelist[0];
        this.cntrOwnershipstratcode=cntrownrship.filter(e=>['sps','spu','sos','sou','fps','fpu','spe','tpm'].includes(String(e.ownrshp_strat_code.toLowerCase())));
         //this.selected_cntr_ownrship_strat_code=this.cntrOwnershipstratcode[0];
      });


      this.transactionService.getActiveContainerGroup(this.currentRegionCodeSub).subscribe(cntrgrp=>{
        this.cntrGrp=cntrgrp;
        // this.selected_cntr_group=this.cntrGrp[0];
      });

      // check if it is neeeded
      this.transactionService.getCurrencyList().subscribe(cntrcurrency=>{
          console.log('Currency List',cntrcurrency);
          this.cntrCurrencyList=cntrcurrency;
          // this.selected_cntr_currency_list=this.cntrCurrencyList[0];
      });

      // this.selected_ownrship_catg=this.ownrship_catg[0];
      this.cntrAddDetailsEnable=true;
      this.compAddDetailsEnable=false;

    }

  }


  containerChange()
  {


      this.cntrTypeCode=this.unmodifiedTypeCodes.filter(e=>['01'].includes(String(e.cd_cntr_typ)));
      this.selected_cntr_type_code=this.cntrTypeCode[0];

  }

  componentChange()
  {

    // Removed ,"04","05"
      this.cntrTypeCodeComp=this.unmodifiedTypeCodes.filter(e=>['02','03'].includes(String(e.cd_cntr_typ)));
      this.selected_comp_type_code=this.cntrTypeCodeComp[0];



  }


  changeInContainerOrComponentOptionForRequest()
  {
    this.disabledAddSuffixToCompButton=false;
    this.disabledAddSuffixToCntrButton=false; 
    this.AddSuffixAddedBecauseOfOwnership=false;
    this.containerOrComponentBase=null;

    if(this.containerOrComponentInDetails=="container")
    {

      this.cntrAddDetailsEnable=true;
      this.compAddDetailsEnable=false;
      this.getMeasurementFromRegion()
      // this.add_measure='lbs/inch';
        console.log('container')
      if(this.containerRequestScreenRoleid==1)
      {
        this.screenName='Supplier Container Request Form'
        console.log(this.userCode);
      }

      else if(this.containerRequestScreenRoleid==2)
      {
        this.screenName='Design Source Container Request Form'
        console.log(this.userCode);
      }
      this.containerDetailsInit();
      this.validationInit();
      this.clear_add();
     this.containerChange();

    }
    else if(this.containerOrComponentInDetails=='component')
    {
      this.cntrAddDetailsEnable=false;
      this.compAddDetailsEnable=true;
      this.getMeasurementFromRegion()
      if(this.containerRequestScreenRoleid==1)
      {
        this.screenName='Supplier Component Request Form'
        console.log(this.userCode);
      }

      else if(this.containerRequestScreenRoleid==2)
      {
        this.screenName='Design Source Component Request Form'
        console.log(this.userCode);
      }
      // this.add_measure='lbs/inch';
      this.componentDetailsInit();
      this.validationInitComp();
      this.clear_add_comp();
      this.componentChange();
    }
  }


  getMeasurementFromRegion()
  {
  this.transactionService.get_region_details(this.currentRegionCodeSub).subscribe(regiondetails=>{
    this.regionDetails=regiondetails;
    // alert(JSON.stringify(this.regionDetails));
    if(this.regionDetails.uom!='' && this.regionDetails.uom!=undefined && this.regionDetails.uom.toLowerCase()=='m')
    {
      this.add_measure='kg/cm';
      this.prev_add_measure='kg/cm';
    }
    else
    {
      this.add_measure='lbs/inch';
      this.prev_add_measure='lbs/inch';
    }
  })
}

  okNoSupDialog()
  {
    console.log(this.containerRequestScreenCurrentRegion)
    let reg_url:string
    reg_url=this.transactionService.getregionurlvalue(this.containerRequestScreenCurrentRegion);
    reg_url+='packagingprocess/packsupplieradmin/packsupplier';

    this.router.navigate([reg_url]);

  }


  okNoDesignsourceDialog()
  {
    console.log(this.containerRequestScreenCurrentRegion)
    let reg_url:string
    reg_url=this.transactionService.getregionurlvalue(this.containerRequestScreenCurrentRegion);
    reg_url+='packagingprocess/packdesignsourceadmin/packdesignsource';

    this.router.navigate([reg_url]);

  }



  okNoRoleDialog()
  {
    let url
    url=this.transactionService.getregionurlvalue(this.containerRequestScreenCurrentRegion);
    url+='packagingprocessdashboard'
    this.router.navigate([url]);

  }

  ok_notification_processing_container_details()
  {
    this.enableProcessingNotifiacationDialog=false;
  }

  change_add_measure()
  {
    console.log(this.add_measure);

    if(this.containerOrComponentInDetails.toLowerCase() == 'container')
    {
    if(this.add_measure=='lbs/inch')
    {
      if(this.prev_add_measure!='lbs/inch')
      {
        console.log('inside lbs')

        if(this.cntrdetailsadd.collapsed_ht!=null)
        {
            this.cntrdetailsadd.collapsed_ht=Math.round((0.393701*this.cntrdetailsadd.collapsed_ht)*1000)/1000;
        }
        if(this.cntrdetailsadd.cntr_interior_l!=null)
        {
            this.cntrdetailsadd.cntr_interior_l=Math.round((0.393701*this.cntrdetailsadd.cntr_interior_l)*1000)/1000;
        }
        if(this.cntrdetailsadd.cntr_interior_w!=null)
        {
                this.cntrdetailsadd.cntr_interior_w=Math.round((0.393701*this.cntrdetailsadd.cntr_interior_w)*1000)/1000;
        }
        if(this.cntrdetailsadd.cntr_interior_h!=null)
        {
                this.cntrdetailsadd.cntr_interior_h=Math.round((0.393701*this.cntrdetailsadd.cntr_interior_h)*1000)/1000;
        }
        if(this.cntrdetailsadd.cntr_tare_wt!=null)
        {
                this.cntrdetailsadd.cntr_tare_wt=Math.round((2.20462*this.cntrdetailsadd.cntr_tare_wt)*1000)/1000;
        }
        if(this.cntrdetailsadd.cntr_capacity!=null)
        {
                this.cntrdetailsadd.cntr_capacity=Math.round((0.393701*0.393701*0.393701*this.cntrdetailsadd.cntr_capacity)*1000)/1000;
        }
        if(this.cntrdetailsadd.cntr_exterior_l!=null)
        {
                this.cntrdetailsadd.cntr_exterior_l=Math.round((0.393701*this.cntrdetailsadd.cntr_exterior_l)*1000)/1000;
        }
        if(this.cntrdetailsadd.cntr_exterior_w!=null)
        {
                this.cntrdetailsadd.cntr_exterior_w=Math.round((0.393701*this.cntrdetailsadd.cntr_exterior_w)*1000)/1000;
        }
        if(this.cntrdetailsadd.cntr_exterior_h!=null)
        {
                this.cntrdetailsadd.cntr_exterior_h=Math.round((0.393701*this.cntrdetailsadd.cntr_exterior_h)*1000)/1000;
        }
        if(this.cntrdetailsadd.shpg_unit_exterior_l!=null)
        {
              this.cntrdetailsadd.shpg_unit_exterior_l=Math.round((0.393701*this.cntrdetailsadd.shpg_unit_exterior_l)*1000)/1000;
        }
        if(this.cntrdetailsadd.shpg_unit_exterior_w!=null)
        {
                this.cntrdetailsadd.shpg_unit_exterior_w=Math.round((0.393701*this.cntrdetailsadd.shpg_unit_exterior_w)*1000)/1000;
        }
        if(this.cntrdetailsadd.shpg_unit_exterior_h!=null)
        {
                this.cntrdetailsadd.shpg_unit_exterior_h=Math.round((0.393701*this.cntrdetailsadd.shpg_unit_exterior_h)*1000)/1000;
        }
        if(this.cntrdetailsadd.shpg_unit_nstdorflded_ht!=null)
        {
                this.cntrdetailsadd.shpg_unit_nstdorflded_ht=Math.round((0.393701*this.cntrdetailsadd.shpg_unit_nstdorflded_ht)*1000)/1000;
        }
        if(this.cntrdetailsadd.shpg_unit_tare_wt!=null)
        {
                this.cntrdetailsadd.shpg_unit_tare_wt=Math.round((2.20462*this.cntrdetailsadd.shpg_unit_tare_wt)*1000)/1000;
        }
        if(this.cntrdetailsadd.shpg_unit_stack_ht!=null)
        {
                this.cntrdetailsadd.shpg_unit_stack_ht=Math.round((0.393701*this.cntrdetailsadd.shpg_unit_stack_ht)*1000)/1000;
        }
        if(this.cntrdetailsadd.warehouse_stack_ht!=null)
        {
                this.cntrdetailsadd.warehouse_stack_ht=Math.round((0.393701*this.cntrdetailsadd.warehouse_stack_ht)*1000)/1000;
        }

        this.prev_add_measure='lbs/inch';
        if(this.disableCntrDetailsWhenBaseOrSuffixChanged==false)
        {
        this.validateCollapsedHtForAdd();
        this.validateCntrTareWeightForAdd();
        this.validateCntrCapWtForAdd();
        this.validateCntrExtLForAdd();
        this.validateCntrExtWForAdd();
        this.validateCntrExtHForAdd();
        this.validateCntrIntLForAdd();
        this.validateCntrIntWForAdd();
        this.validateCntrIntHForAdd();
        this.validateSuNestedFldedHtForAdd();
        this.validateSuTareWeightForAdd();
        this.validateWareHourseStckHtForAdd();
        this.validateShippingUnitStackHtForAdd();
        this.validateSUExtLForAdd();
        this.validateSUExtWForAdd();
        this.validateSUExtHForAdd();
        }
      }
    }

    else if(this.add_measure=='kg/cm')
    {

      if(this.prev_add_measure!='kg/cm')
      {
        console.log('inside kg')


        if(this.cntrdetailsadd.collapsed_ht!=null)
        {
            this.cntrdetailsadd.collapsed_ht=Math.round((2.54*this.cntrdetailsadd.collapsed_ht)*1000)/1000;
        }
        if(this.cntrdetailsadd.cntr_interior_l!=null)
        {
            this.cntrdetailsadd.cntr_interior_l=Math.round((2.54*this.cntrdetailsadd.cntr_interior_l)*1000)/1000;
        }
        if(this.cntrdetailsadd.cntr_interior_w!=null)
        {
                this.cntrdetailsadd.cntr_interior_w=Math.round((2.54*this.cntrdetailsadd.cntr_interior_w)*1000)/1000;
        }
        if(this.cntrdetailsadd.cntr_interior_h!=null)
        {
                this.cntrdetailsadd.cntr_interior_h=Math.round((2.54*this.cntrdetailsadd.cntr_interior_h)*1000)/1000;
        }
        if(this.cntrdetailsadd.cntr_tare_wt!=null)
        {
                this.cntrdetailsadd.cntr_tare_wt=Math.round((0.453592*this.cntrdetailsadd.cntr_tare_wt)*1000)/1000;
        }
        if(this.cntrdetailsadd.cntr_capacity!=null)
        {
                this.cntrdetailsadd.cntr_capacity=Math.round((2.54*2.54*2.54*this.cntrdetailsadd.cntr_capacity)*1000)/1000;
        }
        if(this.cntrdetailsadd.cntr_exterior_l!=null)
        {
                this.cntrdetailsadd.cntr_exterior_l=Math.round((2.54*this.cntrdetailsadd.cntr_exterior_l)*1000)/1000;
        }
        if(this.cntrdetailsadd.cntr_exterior_w!=null)
        {
                this.cntrdetailsadd.cntr_exterior_w=Math.round((2.54*this.cntrdetailsadd.cntr_exterior_w)*1000)/1000;
        }
        if(this.cntrdetailsadd.cntr_exterior_h!=null)
        {
                this.cntrdetailsadd.cntr_exterior_h=Math.round((2.54*this.cntrdetailsadd.cntr_exterior_h)*1000)/1000;
        }
        if(this.cntrdetailsadd.shpg_unit_exterior_l!=null)
        {
              this.cntrdetailsadd.shpg_unit_exterior_l=Math.round((2.54*this.cntrdetailsadd.shpg_unit_exterior_l)*1000)/1000;
        }
        if(this.cntrdetailsadd.shpg_unit_exterior_w!=null)
        {
                this.cntrdetailsadd.shpg_unit_exterior_w=Math.round((2.54*this.cntrdetailsadd.shpg_unit_exterior_w)*1000)/1000;
        }
        if(this.cntrdetailsadd.shpg_unit_exterior_h!=null)
        {
                this.cntrdetailsadd.shpg_unit_exterior_h=Math.round((2.54*this.cntrdetailsadd.shpg_unit_exterior_h)*1000)/1000;
        }
        if(this.cntrdetailsadd.shpg_unit_nstdorflded_ht!=null)
        {
                this.cntrdetailsadd.shpg_unit_nstdorflded_ht=Math.round((2.54*this.cntrdetailsadd.shpg_unit_nstdorflded_ht)*1000)/1000;
        }
        if(this.cntrdetailsadd.shpg_unit_tare_wt!=null)
        {
                this.cntrdetailsadd.shpg_unit_tare_wt=Math.round((0.453592*this.cntrdetailsadd.shpg_unit_tare_wt)*1000)/1000;
        }
        if(this.cntrdetailsadd.shpg_unit_stack_ht!=null)
        {
                this.cntrdetailsadd.shpg_unit_stack_ht=Math.round((2.54*this.cntrdetailsadd.shpg_unit_stack_ht)*1000)/1000;
        }
        if(this.cntrdetailsadd.warehouse_stack_ht!=null)
        {
                this.cntrdetailsadd.warehouse_stack_ht=Math.round((2.54*this.cntrdetailsadd.warehouse_stack_ht)*1000)/1000;
        }
        this.prev_add_measure='kg/cm';
        if(this.disableCntrDetailsWhenBaseOrSuffixChanged==false)
        {
          this.validateCollapsedHtForAdd();
          this.validateCntrTareWeightForAdd();
          this.validateCntrCapWtForAdd();
          this.validateCntrExtLForAdd();
          this.validateCntrExtWForAdd();
          this.validateCntrExtHForAdd();
          this.validateCntrIntLForAdd();
          this.validateCntrIntWForAdd();
          this.validateCntrIntHForAdd();
          this.validateSuNestedFldedHtForAdd();
          this.validateSuTareWeightForAdd();
          this.validateWareHourseStckHtForAdd();
          this.validateShippingUnitStackHtForAdd();
          this.validateSUExtLForAdd();
          this.validateSUExtWForAdd();
          this.validateSUExtHForAdd();
        }

      }

    }
  }

  else if(this.containerOrComponentInDetails.toLowerCase() == 'component')
  {

    console.log(this.add_measure);
    if(this.add_measure=='lbs/inch')
    {
      if(this.prev_add_measure!='lbs/inch')
      {
        console.log('inside lbs')


        if(this.compdetailsadd.cntr_tare_wt!=null)
        {
                this.compdetailsadd.cntr_tare_wt=Math.round((2.20462*this.compdetailsadd.cntr_tare_wt)*1000)/1000;
        }
        if(this.compdetailsadd.cntr_capacity!=null)
        {
                this.compdetailsadd.cntr_capacity=Math.round((0.393701*0.393701*0.393701*this.compdetailsadd.cntr_capacity)*1000)/1000;
        }
        if(this.compdetailsadd.cntr_exterior_l!=null)
        {
                this.compdetailsadd.cntr_exterior_l=Math.round((0.393701*this.compdetailsadd.cntr_exterior_l)*1000)/1000;
        }
        if(this.compdetailsadd.cntr_exterior_w!=null)
        {
                this.compdetailsadd.cntr_exterior_w=Math.round((0.393701*this.compdetailsadd.cntr_exterior_w)*1000)/1000;
        }
        if(this.compdetailsadd.cntr_exterior_h!=null)
        {
                this.compdetailsadd.cntr_exterior_h=Math.round((0.393701*this.compdetailsadd.cntr_exterior_h)*1000)/1000;
        }
        if(this.compdetailsadd.warehouse_stack_ht!=null)
        {
                this.compdetailsadd.warehouse_stack_ht=Math.round((0.393701*this.compdetailsadd.warehouse_stack_ht)*1000)/1000;
        }

        this.prev_add_measure='lbs/inch';
        if(this.disableCompDetailsWhenBaseOrSuffixChanged==false)
        {
        this.validateCompTareWeightForAdd();
        this.validateCompCapWtForAdd();
        this.validateCompExtLForAdd();
        this.validateCompExtWForAdd();
        this.validateCompExtHForAdd();
        this.validateWareHourseStckHtForAddComp();
        }
      }
    }

    else if(this.add_measure=='kg/cm')
    {

      if(this.prev_add_measure!='kg/cm')
      {
        console.log('inside kg')



        if(this.compdetailsadd.cntr_tare_wt!=null)
        {
                this.compdetailsadd.cntr_tare_wt=Math.round((0.453592*this.compdetailsadd.cntr_tare_wt)*1000)/1000;
        }
        if(this.compdetailsadd.cntr_capacity!=null)
        {
                this.compdetailsadd.cntr_capacity=Math.round((2.54*2.54*2.54*this.compdetailsadd.cntr_capacity)*1000)/1000;
        }
        if(this.compdetailsadd.cntr_exterior_l!=null)
        {
                this.compdetailsadd.cntr_exterior_l=Math.round((2.54*this.compdetailsadd.cntr_exterior_l)*1000)/1000;
        }
        if(this.compdetailsadd.cntr_exterior_w!=null)
        {
                this.compdetailsadd.cntr_exterior_w=Math.round((2.54*this.compdetailsadd.cntr_exterior_w)*1000)/1000;
        }
        if(this.compdetailsadd.cntr_exterior_h!=null)
        {
                this.compdetailsadd.cntr_exterior_h=Math.round((2.54*this.compdetailsadd.cntr_exterior_h)*1000)/1000;
        }


        if(this.compdetailsadd.warehouse_stack_ht!=null)
        {
                this.compdetailsadd.warehouse_stack_ht=Math.round((2.54*this.compdetailsadd.warehouse_stack_ht)*1000)/1000;
        }
        this.prev_add_measure='kg/cm';
        if(this.disableCompDetailsWhenBaseOrSuffixChanged==false)
        {
          this.validateCompTareWeightForAdd();
          this.validateCompCapWtForAdd();
          this.validateCompExtLForAdd();
          this.validateCompExtWForAdd();
          this.validateCompExtHForAdd();
          this.validateWareHourseStckHtForAddComp();

        }

      }

    }
  }

  }

  validationInit()
  {
    this.collapsedHtInValid=false;
    this.collapsedHtInValidReason=null;
    this.nestRatioInValid=false;
    this.nestRatioInValidReason=null;
    this.nestRatio1InValid=false;
    this.nestRatio1InValidReason=null;
    this.cntrTareWtInValid=false;
    this.cntrTareWtInValidReason=null;
    this.cntrCapWtInValid=false;
    this.cntrCapWtInValidReason=null;
    this.cntrPerLayerInValid=false;
    this.cntrPerLayerInValidReason=null;
    this.maxLayerPerSuInValid=false;
    this.maxLayerPerSuInValidReason=null;
    this.cntrQtPerMinSuInValid=false;
    this.cntrQtPerMinSuInvalidReason=null;
    this.MaxCntrPerSuInValid=false;
    this.MaxCntrPerMinSuInvalidReason=null;
    this.cntrExtLInValid=false;
    this.cntrExtLInValidReason=null;
    this.cntrExtWInValid=false;
    this.cntrExtWInValidReason=null;
    this.cntrExtHInValid=false;
    this.cntrExtHInValidReason=null;
    this.cntrIntLInValid=false;
    this.cntrIntLInValidReason=null;
    this.cntrIntWInValid=false;
    this.cntrIntWInValidReason=null;
    this.cntrIntHInValid=false;
    this.cntrIntHInValidReason=null;
    this.suNestedHtInValid=false;
    this.suNestedHtInValidReason=null;
    this.suTareWtInvalid=false;
    this.suTareWtInValidReason=null;
    this.wareHourseStackHtInvaid=false;
    this.wareHourseStackHtInvaidReason=null;
    this.suStackHtInvalid=false;
    this.suStackHtInvalidReason=null;
    this.suExtLInValid=false;
    this.suExtLInValidReason=null;
    this.suExtWInValid=false;
    this.suExtWInValidReason=null;
    this.suExtHInValid=false;
    this.suExtHInValidReason=null;

    this.cntrBaseInvalid=false;
    this.cntrBaseInvalidReason=null;
    this.cntrSuffixInvalid=false;
    this.cntrSuffixInvalidReason=null;
    this.cntrDescInvalid=false;
    this.cntrDescInvalidReason=null;
    this.plantCodeInvalid=false;
    this.plantCodeInvalidReason=null;
    this.cardOrCallInvalid=false;
    this.cardOrCallInvalidReason=null;
    this.mastrCatgInvalid=false;
    this.mastrCatgInvalidReason=null;
    this.retTypeInvalid=false;
    this.retTypeInvalidReason=null;
    this.cntrTypeInvalid=false;
    this.cntrTypeInvalidReason=null;
    this.cntrMaterialInvalid=false;
    this.cntrMaterialInvalidReason=null;
    this.cosInvalid=false;
    this.cosInvalidReason=null;
    this.cntrGrpInvalid=false;
    this.cntrGrpInvalidReason=null;
    this.isCollapsibleInvalid=false;
    this.isCollapsibleInvalidReason=null;
    this.cntrCntctInvalid=false;
    this.cntrCntctInvalidReason=null;
    this.cntctPhoneInvalid=false;
    this.cntctPhoneInvlidReason=null;
    this.cntrActiveInvalid=false;
    this.cntrActiveInvalidReason=null;
  }

  cntrdetails_display_popup_init()
  {
    this.cntrdetails_display=
    {
        cd_cntr:0,
        cntr_suff_ind:'',
        exp_refundable:'',
        cntr_active_ind:'',
        cntr_base:'',
        cntr_suffix:'',
        cntr_desc:'',
        card_call:'',
        cntr_mstr_catg:'',
        cntr_typ_desc:'',
        ownrshp_strat_code:'',
        ownrshp_catg:'',
        cntr_grp_desc:'',
        cntr_exterior_l:0,
	      cntr_exterior_w:0,
	      cntr_exterior_h:0,
        collapsed_ht:0.0,
        nest_ratio:0,
        cntr_interior_l:0,
        cntr_interior_w:0,
        cntr_interior_h:0,
        cntr_tare_wt:0,
        cntr_capacity:0,
        cntr_per_layer:0,
        max_layers_shpg_unit:0,
        max_cntr_per_shpg_unit:0,
        cntr_matr_desc:'',
        cntr_drawing_no:'',
        shpg_unit_exterior_l:0,
        shpg_unit_exterior_w:0,
        shpg_unit_exterior_h:0,
        shpg_unit_nstdorflded_ht:0,
        shpg_unit_tare_wt:0,
        shpg_unit_stack_ht:0,
        warehouse_stack_ht:0,
        service_life:'',
        currency_desc:'',
        purchase_cost:0,
        rental_issue_cost:0,
        sup_rntl_dly_cost:0,
        ford_rntl_dly_cost:0,
        return_rntl_cost:0,
        tooling_dev_cost:0,
        extr_cntr_dsgn_img_ind:'',
        intr_cntr_dsgn_img_ind:'',
        shpg_unit_img_ind:'',
        cad_img_ind:'',
        img_notes:'',
        cd_region:'',
        iscontainercolapsble:'',
        cd_plant: '',
        cnt_qt_per_min_su: 0,
        nest_ratio_1: 0,
        ford_cntct: '',
        cntct_phone: '',
        req_stat: '',
        id_user: '',
        ts_last_update: '',
        uom:'E',
        typ_no:0
      }
  }
  copyCntrDetails()
  {
    this.select_contcos_dialog=true;

    if(this.containerDetailsCopied)
    {
      this.cont_retype=this.tableSelectedReturnType;
      for(const i of this.cntrOwnershipstratcodelist)
      {
        if(i.ownrshp_strat_code==this.table_selected_cntr_ownrship_strat_code_in_popup.ownrshp_strat_code)
        {
          this.selected_cntr_ownrship_strat_code_in_popup=this.table_selected_cntr_ownrship_strat_code_in_popup;
        }
      }

    }
    else
    {
      this.cont_retype='Returnable';
      this.selected_cntr_ownrship_strat_code_in_popup=this.cntrOwnershipstratcodelist[0];
      this.selected_container=null;
    }

    this.loadContainersInPopUp();

  }

  onclick_ok_select_contandcos()
  {
    this.select_contcos_dialog=false;

    this.containerDetailsCopied=true;
    this.isCmmsLoadedContainer=false;

    this.table_selected_container=this.selected_container;
    this.tableSelectedReturnType=this.cont_retype;
    this.table_selected_cntr_ownrship_strat_code_in_popup=this.selected_cntr_ownrship_strat_code_in_popup;

    this.transactionService.editbutton_get_contr_details(this.table_selected_container.cntr_base,this.table_selected_container.cd_cntr).subscribe(cntval=>{
      this.cntrDetailsForCopying=cntval;

      this.cntrdetailsadd.cntr_base='';
      this.cntrdetailsadd.cntr_suffix='';
      this.cntrdetailsadd.cntr_suff_ind='';
      this.cntrSuffixAdd='';
      this.cntrSuffixEnabled=false;
      this.disableCntrDetailsWhenBaseOrSuffixChanged=true;
      this.prevCntrSuffixInd=null;
      this.enteredCntrBase=null;
      this.containerDetailsInit();

      // this.cntrdetailsadd.function:'',
      // this.cntrdetailsadd.cntr_suff_ind=this.cntrDetailsForCopying.,
      // this.cntrdetailsadd.exp_refundable=this.cntrDetailsForCopying.exp_refundable;
      this.cntrdetailsadd.cntr_active_ind=this.cntrDetailsForCopying.cntr_active_ind;
      // this.cntrdetailsadd.cntr_base=this.cntrDetailsForCopying.,
      // this.cntrdetailsadd.cntr_suffix=this.cntrDetailsForCopying.,
      // this.cntrdetailsadd.cntr_desc=this.cntrDetailsForCopying.
      this.cntrdetailsadd.card_call=this.cntrDetailsForCopying.card_call;
      if(this.cntrDetailsForCopying.cntr_mstr_catg!=null && this.cntrDetailsForCopying.cntr_mstr_catg!='' && this.cntrMasterCategory!=null && this.cntrMasterCategory.length!=0)
      {
        for(const mc of this.cntrMasterCategory)
        {
          if(mc.cntr_mstr_catg.toLowerCase()==this.cntrDetailsForCopying.cntr_mstr_catg.toLowerCase())
          {
            this.cntrdetailsadd.cntr_mstr_catg=this.cntrDetailsForCopying.cntr_mstr_catg;
            this.selected_cntr_master_category=mc;
            break;
          }
        }
      }

      if(this.ownrship_catg!=null && this.ownrship_catg.length!=0)
      {

        this.selected_ownrship_catg=this.ownrship_catg[1];
        this.cntrdetailsadd.ownrshp_catg='Supp';
      }

      if(this.cntrTypeCode!=null && this.cntrTypeCode.length!=0)
      {

        this.selected_cntr_type_code=this.cntrTypeCode[0];
        this.cntrdetailsadd.cntr_typ_desc=this.selected_cntr_type_code.cntr_typ_desc;

      }

      // this.cntrdetailsadd.cntr_typ_desc=this.cntrDetailsForCopying.
      // this.cntrdetailsadd.ownrshp_strat_code=this.cntrDetailsForCopying.
      // this.cntrdetailsadd.ownrshp_catg=this.cntrDetailsForCopying.
      if(this.cntrDetailsForCopying.cntr_grp_desc!=null && this.cntrDetailsForCopying.cntr_grp_desc!='' && this.cntrGrp!=null && this.cntrGrp.length!=0)
      {
        for(const cg of this.cntrGrp)
        {
          if(cg.cntr_grp.toLowerCase()==this.cntrDetailsForCopying.cntr_grp_desc.toLowerCase())
          {
            this.cntrdetailsadd.cntr_grp_desc=this.cntrDetailsForCopying.cntr_grp_desc;
            this.selected_cntr_group=cg;
            break;
          }
        }
      }

      this.cntrdetailsadd.cntr_exterior_l=this.cntrDetailsForCopying.cntr_exterior_l
      this.cntrdetailsadd.cntr_exterior_w=this.cntrDetailsForCopying.cntr_exterior_w
      this.cntrdetailsadd.cntr_exterior_h=this.cntrDetailsForCopying.cntr_exterior_h
      this.cntrdetailsadd.collapsed_ht=this.cntrDetailsForCopying.collapsed_ht
      this.cntrdetailsadd.nest_ratio=this.cntrDetailsForCopying.nest_ratio
      this.cntrdetailsadd.cntr_interior_l=this.cntrDetailsForCopying.cntr_interior_l
      this.cntrdetailsadd.cntr_interior_w=this.cntrDetailsForCopying.cntr_interior_w
      this.cntrdetailsadd.cntr_interior_h=this.cntrDetailsForCopying.cntr_interior_h
      this.cntrdetailsadd.cntr_tare_wt=this.cntrDetailsForCopying.cntr_tare_wt
      this.cntrdetailsadd.cntr_capacity=this.cntrDetailsForCopying.cntr_capacity
      this.cntrdetailsadd.cntr_per_layer=this.cntrDetailsForCopying.cntr_per_layer
      this.cntrdetailsadd.max_layers_shpg_unit=this.cntrDetailsForCopying.max_layers_shpg_unit
      this.cntrdetailsadd.max_cntr_per_shpg_unit=this.cntrDetailsForCopying.max_cntr_per_shpg_unit
      // this.cntrdetailsadd.cntr_matr_desc=this.cntrDetailsForCopying.
      this.cntrdetailsadd.cntr_drawing_no=this.cntrDetailsForCopying.cntr_drawing_no
      this.cntrdetailsadd.shpg_unit_exterior_l=this.cntrDetailsForCopying.shpg_unit_exterior_l
      this.cntrdetailsadd.shpg_unit_exterior_w=this.cntrDetailsForCopying.shpg_unit_exterior_w
      this.cntrdetailsadd.shpg_unit_exterior_h=this.cntrDetailsForCopying.shpg_unit_exterior_h
      this.cntrdetailsadd.shpg_unit_nstdorflded_ht=this.cntrDetailsForCopying.shpg_unit_nstdorflded_ht,
      this.cntrdetailsadd.shpg_unit_tare_wt=this.cntrDetailsForCopying.shpg_unit_tare_wt
      this.cntrdetailsadd.shpg_unit_stack_ht=this.cntrDetailsForCopying.shpg_unit_stack_ht
      this.cntrdetailsadd.warehouse_stack_ht=this.cntrDetailsForCopying.warehouse_stack_ht
      this.cntrdetailsadd.service_life=this.cntrDetailsForCopying.service_life
      this.cntrdetailsadd.currency_desc=this.cntrDetailsForCopying.currency_desc
      this.cntrdetailsadd.purchase_cost=this.cntrDetailsForCopying.purchase_cost
      this.cntrdetailsadd.rental_issue_cost=this.cntrDetailsForCopying.rental_issue_cost
      this.cntrdetailsadd.sup_rntl_dly_cost=this.cntrDetailsForCopying.sup_rntl_dly_cost
      this.cntrdetailsadd.ford_rntl_dly_cost=this.cntrDetailsForCopying.ford_rntl_dly_cost
      this.cntrdetailsadd.return_rntl_cost=this.cntrDetailsForCopying.return_rntl_cost
      this.cntrdetailsadd.tooling_dev_cost=this.cntrDetailsForCopying.tooling_dev_cost
      this.cntrdetailsadd.extr_cntr_dsgn_img_ind=this.cntrDetailsForCopying.extr_cntr_dsgn_img_ind
      this.cntrdetailsadd.intr_cntr_dsgn_img_ind=this.cntrDetailsForCopying.intr_cntr_dsgn_img_ind
      this.cntrdetailsadd.shpg_unit_img_ind=this.cntrDetailsForCopying.shpg_unit_img_ind
      this.cntrdetailsadd.cad_img_ind=this.cntrDetailsForCopying.cad_img_ind,
      this.cntrdetailsadd.img_notes=this.cntrDetailsForCopying.img_notes
      this.cntrdetailsadd.cd_region=this.currentRegionCodeSub;
      this.cntrdetailsadd.iscontainercolapsble=this.cntrDetailsForCopying.iscontainercolapsble;
      if(this.cntrDetailsForCopying.cd_plant!=null && this.cntrDetailsForCopying.cd_plant!='' && this.plantList!=null && this.plantList.length!=0 )
      {
        for(const pc of this.plantList)
        {
          if(pc.plantGSDBCode.toLowerCase()==this.cntrDetailsForCopying.cd_plant.toLowerCase())
          {
            this.cntrdetailsadd.cd_plant=this.cntrDetailsForCopying.cd_plant;
            this.selectedPlantList=pc;
            break;
          }
        }
      }

      this.cntrdetailsadd.cntr_qt_per_min_su=this.cntrDetailsForCopying.cnt_qt_per_min_su
      this.cntrdetailsadd.nest_ratio_1=this.cntrDetailsForCopying.nest_ratio_1
      this.cntrdetailsadd.ford_cntct=null;
      // this.cntrdetailsadd.cntct_phone=this.cntrDetailsForCopying.
      this.cntrdetailsadd.id_user=this.userCode
      this.cntrdetailsadd.ts_last_update=this.date.transform(new Date(),'yyyy-MM-dd');
      this.cntrdetailsadd.req_stat='open';
      this.cntrdetailsadd.components=[{
        mp_function:'',
        no_comp_base:'',
        no_comp_suffix:'',
        ds_comp:'',
        comp_qt_per_min_su:0,
        comp_cd_owner:'',
        in_comp:'',
        comp_cd_type:''
      }],
      // this.cntrdetailsadd.UoM='E';
      this.cntrdetailsadd.dbia_req_no=0;

      console.log('container details from container admin on pop up',this.cntrDetailsForCopying);

      this.checkContainerValidate();

      if(this.cntrdetailsadd.cntr_base!=null && this.cntrdetailsadd.cntr_suff_ind!=null && this.cntrdetailsadd.cntr_base.trim()!='' && this.cntrdetailsadd.cntr_suff_ind!='')
      {
        this.cntrsuffixchangedadd();
      }
    })
  }

  onclick_cancel_select_contandcos()
  {
    this.select_contcos_dialog=false;
    this.selected_container=null;
    this.cont_retype='Returnable';
    this.selected_cntr_ownrship_strat_code_in_popup=this.cntrOwnershipstratcodelist[0];
  }

  on_change_cos_dropdown()
  {
    this.selected_container=null;
    this.loadContainersInPopUp();
  }

  onChangeExpRet()
  {
    this.selected_container=null;
    this.loadContainersInPopUp();
  }

  loadContainersInPopUp()
  {
    console.log(this.containerDetailsCopied)

    this.transactionService.getcontoncoslist(this.selected_cntr_ownrship_strat_code_in_popup.ownrshp_strat_code,this.currentRegionCodeSub,this.cont_retype,this.userCode).subscribe(stdcntrs=>{
      this.contlist_oncos=stdcntrs;

      console.log('successful',this.contlist_oncos);

      if(this.contlist_oncos.length!=0 && this.contlist_oncos!=null)
      {
        this.cont_grid_text='';
        this.cont_present_table=true;
        for(let i=0;i<this.contlist_oncos.length;i++)
        {

          if(this.contlist_oncos[i].cntr_suffix!=null)
          {
          this.contlist_oncos[i].cntr_base_suffix=this.contlist_oncos[i].cntr_base+' - '+this.contlist_oncos[i].cntr_suffix
          }
          else
          {
            this.contlist_oncos[i].cntr_base_suffix=this.contlist_oncos[i].cntr_base
          }

          let length='';
          let width='';
          let height='';
          if(this.contlist_oncos[i].cntr_exterior_l!=null)
          {
            length=this.contlist_oncos[i].cntr_exterior_l.toString();
          }
          if(this.contlist_oncos[i].cntr_exterior_w!=null)
          {
            width=this.contlist_oncos[i].cntr_exterior_w.toString();
          }
          if(this.contlist_oncos[i].cntr_exterior_l!=null)
          {
            height=this.contlist_oncos[i].cntr_exterior_h.toString();
          }

          this.contlist_oncos[i].external_l_w_h=length+'x'+width+'x'+height;

          console.log(this.containerDetailsCopied)

        }

        if(this.containerDetailsCopied && this.table_selected_cntr_ownrship_strat_code_in_popup==this.selected_cntr_ownrship_strat_code_in_popup && this.tableSelectedReturnType==this.cont_retype)
        {
          this.selected_container=this.table_selected_container;
        }

        this.cont_cols = [
          { field: 'cntr_base_suffix', header:'Container Base - Suffix'},
          {field:'cntr_desc',header:'Container Description'},
          { field: 'exp_refundable', header:'E/R'},
          { field: 'external_l_w_h', header:'External LxWxH'},
          { field: 'card_call', header:'Card or Call'},
          { field: 'cntr_grp_desc', header:'Container Group ID'},

        ];
      }
      else
      {
        this.cont_present_table=false;
        this.cont_cols=[];
        this.cont_grid_text='No Container Found'
      }

    })
  }

  on_click_cntrdetails(cntrdata:any)
  {
    this.enabledetails_cntr=true;
    // this.get_measure="lbs/inch";

    console.log(cntrdata);

    this.transactionService.editbutton_get_contr_details(cntrdata.cntr_base,cntrdata.cd_cntr).subscribe(cntval=>{
      this.cntrdetails_display=cntval;
      if(this.cntrdetails_display.uom!=null && this.cntrdetails_display.uom.toLowerCase()=='e')
      {
        this.get_measure='lbs/inch';
      }
      if(this.cntrdetails_display.uom!=null && this.cntrdetails_display.uom.toLowerCase()=='m')
      {
        this.get_measure='kg/cm';
      }
      else
      {
        this.get_measure=this.add_measure;// if value is null assigning region admin value
      }
      console.log('container details from container admin on pop up',this.cntrdetails_display)
    })


  }
  cntrBaseChanged()
  {
    // API call to check whether the container exist in CMMS , if yes make request_status as "c" else make it as 'A'
    // disable all the field until api returns output


    if(this.enteredCntrBase!=null)
    {
      if(this.enteredCntrBase.trim()!=this.cntrdetailsadd.cntr_base.trim())
        {
          this.enteredCntrBase=this.cntrdetailsadd.cntr_base;
          if(this.containerDetailsCopied==false)
          {
            this.disableCntrDetailsWhenBaseOrSuffixChanged=true;
            // this.clearCntrDetailsExceptBaseAndSuffixAndPlantCode();
          }

          if(this.cntrdetailsadd.cntr_suff_ind.toLowerCase()=='no')
          {
            this.cntrSuffixAdd=''
            this.cntrdetailsadd.cntr_suffix='%20%20%20%20%20%20%20%20';
          }

          if(this.cntrdetailsadd.cntr_base!=null && this.cntrdetailsadd.cntr_base.trim()!='' && (this.cntrdetailsadd.cntr_suff_ind.toLowerCase()=='yes' || this.cntrdetailsadd.cntr_suff_ind.toLowerCase()=='no'))
          {
            this.filterByCntrBaseAndSuffix()
           // this.cntrsuffixchangedadd()
          }
      }

    }
    else  // This case will be exceuted when user enters base for the first time
    {
      this.enteredCntrBase=this.cntrdetailsadd.cntr_base;
      this.disableCntrDetailsWhenBaseOrSuffixChanged=true;
      if(this.containerDetailsCopied==false)
      {
        // this.clearCntrDetailsExceptBaseAndSuffixAndPlantCode();
      }

      if(this.cntrdetailsadd.cntr_suff_ind.toLowerCase()=='no')
      {
        this.cntrSuffixAdd=''
        this.cntrdetailsadd.cntr_suffix='%20%20%20%20%20%20%20%20';
      }

      if(this.cntrdetailsadd.cntr_base!=null && this.cntrdetailsadd.cntr_base.trim()!='' && (this.cntrdetailsadd.cntr_suff_ind.toLowerCase()=='yes' || this.cntrdetailsadd.cntr_suff_ind.toLowerCase()=='no'))
      {
        this.filterByCntrBaseAndSuffix()
       // this.cntrsuffixchangedadd()
      }
    }


  }

  cntrsuffixchangedadd()
  {
    if(this.prevCntrSuffixInd!=null && this.prevCntrSuffixInd!=this.cntrdetailsadd.cntr_suff_ind)
    {
      if(this.cntrdetailsadd.cntr_suff_ind=='No')
      {
       this.cntrSuffixEnabled=true;
        this.cntrSuffixAdd='';
        this.cntrdetailsadd.cntr_suffix='%20%20%20%20%20%20%20%20';
        console.log(this.cntrSuffixEnabled);
      }
      else
      {
        if(this.userCode!=null && this.userCode!='')
        {
          this.cntrSuffixAdd=this.userCode;
          this.cntrdetailsadd.cntr_suffix=this.userCode;
          console.log('checking',this.cntrdetailsadd.cntr_suffix);
          this.cntrSuffixEnabled=true;
        }

      }

      this.prevCntrSuffixInd=this.cntrdetailsadd.cntr_suff_ind;

      if(this.containerDetailsCopied==false)
      {
        this.disableCntrDetailsWhenBaseOrSuffixChanged=true;
        // this.clearCntrDetailsExceptBaseAndSuffixAndPlantCode();
      }

      if(this.cntrdetailsadd.cntr_base!=null && this.cntrdetailsadd.cntr_base.trim()!='')
      {
        this.filterByCntrBaseAndSuffix();
      }
    }
    else if(this.prevCntrSuffixInd==null)  // executed when user selects suffix for first time
    {
      if(this.cntrdetailsadd.cntr_suff_ind=='No')
      {
       this.cntrSuffixEnabled=true;
        this.cntrSuffixAdd='';
        this.cntrdetailsadd.cntr_suffix='%20%20%20%20%20%20%20%20';
        console.log(this.cntrSuffixEnabled);
      }
      else
      {
        if(this.userCode!=null && this.userCode!='')
        {
          this.cntrSuffixAdd=this.userCode;
          this.cntrdetailsadd.cntr_suffix=this.userCode;
          console.log('checking',this.cntrdetailsadd.cntr_suffix);
          this.cntrSuffixEnabled=true;
        }

      }
      this.disableCntrDetailsWhenBaseOrSuffixChanged=true;

       if(this.containerDetailsCopied==false)
      {
        // this.clearCntrDetailsExceptBaseAndSuffixAndPlantCode();
      }

      this.prevCntrSuffixInd=this.cntrdetailsadd.cntr_suff_ind;

      if(this.cntrdetailsadd.cntr_base!=null && this.cntrdetailsadd.cntr_base.trim()!='')
      {
        this.filterByCntrBaseAndSuffix();
      }
    }

  }

  onChangeRetTypeAddCntr()
  {
    let ret_type='';
    this.selected_cntr_material={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
    if(this.cntrdetailsadd.exp_refundable != '')
    {
      if(this.selected_cntr_type_code != null && this.selected_cntr_type_code.cntr_typ_desc != '')
      {
        if(this.cntrdetailsadd.exp_refundable.toLowerCase() =='returnable')
        {
          ret_type= 'R';
        }
        else
        {
          ret_type= 'E';
        }
        this.transactionService.getContainerMaterial(this.selected_cntr_type_code.typ_no,ret_type).subscribe(mat_list=>{
          this.cntrMaterial=[];
          this.cntrMaterial=mat_list;
        })
        this.enable_material_dropdown=true;
      }
   }
  }


onChangeCntrTypAddCntr()
{
  let ret_type='';
  console.log(this.selected_cntr_type_code,this.cntrdetailsadd.exp_refundable)
  this.selected_cntr_material={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
  if(this.selected_cntr_type_code != null && this.selected_cntr_type_code.cntr_typ_desc != '')
  {
    if(this.cntrdetailsadd.exp_refundable != '')
    {


    if(this.cntrdetailsadd.exp_refundable.toLowerCase() == 'returnable')
    {
      ret_type= 'R';

    }
    else
    {
      ret_type= 'E';
    }


    this.transactionService.getContainerMaterial(this.selected_cntr_type_code.typ_no,ret_type).subscribe(mat_list=>{

      this.cntrMaterial=[];
      this.cntrMaterial=mat_list;

    })


    // api to get material list
    this.enable_material_dropdown=true;
  }
}


}

  filterByCntrBaseAndSuffix()
  {

   /*  this.selectedFromContainerAdmin=false;
    this.clearCntrRequestDetails(); */
     // alert(this.cntrdetailsadd.cntr_base+this.cntrdetailsadd.cntr_suff_ind+this.cntrdetailsadd.cntr_suffix)
    this.transactionService.getContainerDetailsFromCMMS(this.cntrdetailsadd.cntr_base.toUpperCase(),this.cntrdetailsadd.cntr_suffix).subscribe(cntrdetails=>{

      const cntrDetailsFromAPI:cntrDetailsFromCMMS=cntrdetails;
      this.cntrdetailsfromload=cntrdetails;

      if(cntrDetailsFromAPI!=null)
      {

        /* Removing this condition because even when the container is copied
          and user goes out of focus container details present in cmms should be loaded
          so emptying the copying container variables if exists

        if(this.containerDetailsCopied==false)
        {
        */

        this.clearCntrDetailsExceptBaseAndSuffixAndPlantCode();
        this.containerDetailsCopied=false;
        this.cntrdetailsadd.function='C';
        this.isCmmsLoadedContainer=true;

        /*code Added */
        this.selected_container=null;
        this.cont_retype='Returnable';
        this.cntrdetails_display_popup_init();
        this.selected_cntr_ownrship_strat_code_in_popup=this.cntrOwnershipstratcodelist[0];
        /*code Added */

        this.cntrdetailsadd.cntr_base=this.cntrdetailsfromload.no_cntr_base.trim();
        this.cntrdetailsadd.cntr_suffix=this.cntrdetailsfromload.no_cntr_suffix.trim();

        if(this.cntrdetailsfromload.ds_cntr!=null)
        {
          this.cntrdetailsadd.cntr_desc=this.cntrdetailsfromload.ds_cntr.trim();
        }


        this.selected_ownrship_catg=this.ownrship_catg[1];
        // Sending Ownership category "Supp" by default
        this.cntrdetailsadd.ownrshp_catg='Supp';
        // this.cntrdetailsadd.ownrshp_catg=this.ownrship_catg[1].ownrshp_catg;


        this.cntrdetailsadd.cntr_exterior_l=this.cntrdetailsfromload.ms_cntr_lt;
        this.cntrdetailsadd.cntr_exterior_w=this.cntrdetailsfromload.ms_cntr_wd;
        this.cntrdetailsadd.cntr_exterior_h=this.cntrdetailsfromload.ms_cntr_ht;

        if(this.cntrdetailsfromload.ms_cntr_clpsd_ht!=null && this.cntrdetailsfromload.ms_cntr_clpsd_ht>0)
        {
          this.cntrdetailsadd.iscontainercolapsble='Y';
          this.cntrdetailsadd.collapsed_ht=this.cntrdetailsfromload.ms_cntr_clpsd_ht;
        }
        else
        {
          this.cntrdetailsadd.iscontainercolapsble='N';
        }

        this.cntrdetailsadd.nest_ratio=this.cntrdetailsfromload.no_nesting_ratio1,
        this.cntrdetailsadd.nest_ratio_1=this.cntrdetailsfromload.no_nesting_ratio2,
        this.cntrdetailsadd.cntr_tare_wt=this.cntrdetailsfromload.ms_cntr_tare_wt,
        this.cntrdetailsadd.cntr_capacity=this.cntrdetailsfromload.ms_max_cap_wt,
        this.cntrdetailsadd.cntr_qt_per_min_su=this.cntrdetailsfromload.qt_per_min_su;
        this.cntrdetailsadd.warehouse_stack_ht=this.cntrdetailsfromload.qt_whse_stack_ht;
        if(this.cntrdetailsfromload.na_ford_cntct!=null && this.cntrdetailsfromload.na_ford_cntct.trim()!='')
        {
        this.cntrdetailsadd.ford_cntct=this.cntrdetailsfromload.na_ford_cntct.trim();
        }
        if(this.cntrdetailsfromload.no_cntct_phone_1!=null && this.cntrdetailsfromload.no_cntct_phone_1.trim()!='')
        {
        this.cntrdetailsadd.cntct_phone=this.cntrdetailsfromload.no_cntct_phone_1.trim();
        }
        if(this.cntrdetailsfromload.ds_cntr_note!=null && this.cntrdetailsfromload.ds_cntr_note.trim()!='')
        {
        this.cntrdetailsadd.img_notes=this.cntrdetailsfromload.ds_cntr_note.trim();
        }

        this.cntrdetailsadd.shpg_unit_stack_ht=this.cntrdetailsfromload.qt_cntr_stack_ht;
        this.selected_cntr_type_code=this.cntrTypeCode[0];
        this.cntrdetailsadd.cntr_typ_desc=this.selected_cntr_type_code.cntr_typ_desc;
        
        this.cntrdetailsadd.card_call="Call";
        this.cntrdetailsadd.cntr_active_ind="Yes";
        var index=this.cntrMasterCategory.findIndex(e=>e.cntr_mstr_catg.toLowerCase()=='container');
        if(index>-1)
        {
          this.selected_cntr_master_category=this.cntrMasterCategory[index];
          this.cntrdetailsadd.cntr_matr_desc=this.selected_cntr_master_category.cntr_mstr_catg
        }

        var indexgrp=this.cntrGrp.findIndex(e=>e.cntr_grp.toLowerCase()=="standard")
        if(indexgrp>-1)
        {
          this.selected_cntr_group=this.cntrGrp[indexgrp];
          this.cntrdetailsadd.cntr_grp_desc=this.selected_cntr_group.cntr_grp
        }
        
      //}
        
      this.checkContainerValidate();
      this.disableCntrDetailsWhenBaseOrSuffixChanged=false;

      if( this.isCmmsLoadedContainer==true)
      {
       //  If Data is loaded from the CMMS, and if the measure is in kg/cm or (m) then the data is converted into cm
       if(this.add_measure=='kg/cm')
       {

           console.log('inside kg')

           if(this.cntrdetailsadd.collapsed_ht!=null)
           {
               this.cntrdetailsadd.collapsed_ht=Math.round((2.54*this.cntrdetailsadd.collapsed_ht)*1000)/1000;
           }
           if(this.cntrdetailsadd.cntr_interior_l!=null)
           {
               this.cntrdetailsadd.cntr_interior_l=Math.round((2.54*this.cntrdetailsadd.cntr_interior_l)*1000)/1000;
           }
           if(this.cntrdetailsadd.cntr_interior_w!=null)
           {
                   this.cntrdetailsadd.cntr_interior_w=Math.round((2.54*this.cntrdetailsadd.cntr_interior_w)*1000)/1000;
           }
           if(this.cntrdetailsadd.cntr_interior_h!=null)
           {
                   this.cntrdetailsadd.cntr_interior_h=Math.round((2.54*this.cntrdetailsadd.cntr_interior_h)*1000)/1000;
           }
           if(this.cntrdetailsadd.cntr_tare_wt!=null)
           {
                   this.cntrdetailsadd.cntr_tare_wt=Math.round((0.453592*this.cntrdetailsadd.cntr_tare_wt)*1000)/1000;
           }
           if(this.cntrdetailsadd.cntr_capacity!=null)
           {
                   this.cntrdetailsadd.cntr_capacity=Math.round((0.453592*this.cntrdetailsadd.cntr_capacity)*1000)/1000;
           }
           if(this.cntrdetailsadd.cntr_exterior_l!=null)
           {
                   this.cntrdetailsadd.cntr_exterior_l=Math.round((2.54*this.cntrdetailsadd.cntr_exterior_l)*1000)/1000;
           }
           if(this.cntrdetailsadd.cntr_exterior_w!=null)
           {
                   this.cntrdetailsadd.cntr_exterior_w=Math.round((2.54*this.cntrdetailsadd.cntr_exterior_w)*1000)/1000;
           }
           if(this.cntrdetailsadd.cntr_exterior_h!=null)
           {
                   this.cntrdetailsadd.cntr_exterior_h=Math.round((2.54*this.cntrdetailsadd.cntr_exterior_h)*1000)/1000;
           }
           if(this.cntrdetailsadd.shpg_unit_exterior_l!=null)
           {
                 this.cntrdetailsadd.shpg_unit_exterior_l=Math.round((2.54*this.cntrdetailsadd.shpg_unit_exterior_l)*1000)/1000;
           }
           if(this.cntrdetailsadd.shpg_unit_exterior_w!=null)
           {
                   this.cntrdetailsadd.shpg_unit_exterior_w=Math.round((2.54*this.cntrdetailsadd.shpg_unit_exterior_w)*1000)/1000;
           }
           if(this.cntrdetailsadd.shpg_unit_exterior_h!=null)
           {
                   this.cntrdetailsadd.shpg_unit_exterior_h=Math.round((2.54*this.cntrdetailsadd.shpg_unit_exterior_h)*1000)/1000;
           }
           if(this.cntrdetailsadd.shpg_unit_nstdorflded_ht!=null)
           {
                   this.cntrdetailsadd.shpg_unit_nstdorflded_ht=Math.round((2.54*this.cntrdetailsadd.shpg_unit_nstdorflded_ht)*1000)/1000;
           }
           if(this.cntrdetailsadd.shpg_unit_tare_wt!=null)
           {
                   this.cntrdetailsadd.shpg_unit_tare_wt=Math.round((0.453592*this.cntrdetailsadd.shpg_unit_tare_wt)*1000)/1000;
           }
           /*
           if(this.cntrdetailsadd.shpg_unit_stack_ht!=null)
           {
                   this.cntrdetailsadd.shpg_unit_stack_ht=Math.round((2.54*this.cntrdetailsadd.shpg_unit_stack_ht)*1000)/1000;
           }
           if(this.cntrdetailsadd.warehouse_stack_ht!=null)
           {
                   this.cntrdetailsadd.warehouse_stack_ht=Math.round((2.54*this.cntrdetailsadd.warehouse_stack_ht)*1000)/1000;
           }
           */
           this.prev_add_measure='kg/cm';
           if(this.disableCntrDetailsWhenBaseOrSuffixChanged==false)
           {
             this.validateCollapsedHtForAdd();
             this.validateCntrTareWeightForAdd();
             this.validateCntrCapWtForAdd();
             this.validateCntrExtLForAdd();
             this.validateCntrExtWForAdd();
             this.validateCntrExtHForAdd();
             this.validateCntrIntLForAdd();
             this.validateCntrIntWForAdd();
             this.validateCntrIntHForAdd();
             this.validateSuNestedFldedHtForAdd();
             this.validateSuTareWeightForAdd();
             this.validateWareHourseStckHtForAdd();
             this.validateShippingUnitStackHtForAdd();
             this.validateSUExtLForAdd();
             this.validateSUExtWForAdd();
             this.validateSUExtHForAdd();
           }



       }
      }

    }
      else
      {
        // alert("came here")
        this.isCmmsLoadedContainer=false;

        this.cntrdetailsadd.cntr_active_ind="Yes";
       
        if(this.containerDetailsCopied==false)
        {
          this.selected_ownrship_catg=this.ownrship_catg[1];
          //Sending Ownership category "Supp" by default
          this.cntrdetailsadd.ownrshp_catg="Supp";

          this.cntrdetailsadd.card_call="Call";
          var index=this.cntrMasterCategory.findIndex(e=>e.cntr_mstr_catg.toLowerCase()=='container');
          if(index>-1)
          {
            this.selected_cntr_master_category=this.cntrMasterCategory[index];
            this.cntrdetailsadd.cntr_matr_desc=this.selected_cntr_master_category.cntr_mstr_catg
          }

          var indexgrp=this.cntrGrp.findIndex(e=>e.cntr_grp.toLowerCase()=="standard")
          if(indexgrp>-1)
          {
            this.selected_cntr_group=this.cntrGrp[indexgrp];
            this.cntrdetailsadd.cntr_grp_desc=this.selected_cntr_group.cntr_grp
          }
       
          //this.cntrdetailsadd.ownrshp_catg=this.ownrship_catg[1].ownrshp_catg;
        }
        else
        {
          if(this.cntrdetailsadd.card_call==null || (this.cntrdetailsadd.card_call!=null && (this.cntrdetailsadd.card_call=='' || this.cntrdetailsadd.card_call=="null" )))
          {
            this.cntrdetailsadd.card_call="Call";
          }

          if(this.cntrdetailsadd.cntr_mstr_catg==null || (this.cntrdetailsadd.cntr_mstr_catg!=null && this.cntrdetailsadd.cntr_mstr_catg==''))
          {
            var index=this.cntrMasterCategory.findIndex(e=>e.cntr_mstr_catg.toLowerCase()=='container');
            if(index>-1)
            {
              this.selected_cntr_master_category=this.cntrMasterCategory[index];
              this.cntrdetailsadd.cntr_matr_desc=this.selected_cntr_master_category.cntr_mstr_catg
            }
          }

          if(this.cntrdetailsadd.cntr_grp_desc==null || (this.cntrdetailsadd.cntr_grp_desc!=null && this.cntrdetailsadd.cntr_grp_desc==''))
          {
            var indexgrp=this.cntrGrp.findIndex(e=>e.cntr_grp.toLowerCase()=="standard")
            if(indexgrp>-1)
            {
              this.selected_cntr_group=this.cntrGrp[indexgrp];
              this.cntrdetailsadd.cntr_grp_desc=this.selected_cntr_group.cntr_grp
            }
          } 
        }
        this.cntrdetailsadd.function='A';
        this.validationInit();
        this.disableCntrDetailsWhenBaseOrSuffixChanged=false;
      }

    })
    
  }

  validateCOSandSuffix()
  {
    this.checkIfUniqueCosIsSelected(this.selected_cntr_ownrship_strat_code,"container");
  }

  
  validateCOSandSuffixForComp()
  {
    this.checkIfUniqueCosIsSelected(this.selected_comp_ownrship_strat_code,"component");
  }

  checkIfUniqueCosIsSelected(selectedOwnershipCode:containerOwnershipList,containerOrComponent:string)
  {
    if(this.currentRegionCodeSub.toLowerCase()=="na")
    {
      if(selectedOwnershipCode!=null  && selectedOwnershipCode.ownrshp_strat_code && containerOrComponent)
      {
        if(selectedOwnershipCode.ownrshp_strat_code.toLowerCase()=="spu" || selectedOwnershipCode.ownrshp_strat_code.toLowerCase()=="sou")
        {
          if(containerOrComponent.toLowerCase()=="container")
          {
            if(this.cntrdetailsadd.cntr_suff_ind==null || (this.cntrdetailsadd.cntr_suff_ind!=null && this.cntrdetailsadd.cntr_suff_ind.toLowerCase()=="no"))
            {
              this.AddSuffixAddedBecauseOfOwnership=true;
              this.containerOrComponentBase=this.cntrdetailsadd.cntr_base;
              this.cntrdetailsadd.cntr_suff_ind="Yes";
              this.disabledAddSuffixToCntrButton=true;
              this.ownershipCodeSelected=selectedOwnershipCode.ownrshp_strat_code;
              console.log("enable container suffix");
              this.cntrsuffixchangedadd();
            }
            else
            {
              this.disabledAddSuffixToCntrButton=false;
            }
          }
          else
          {
            if(this.compdetailsadd.cntr_suff_ind==null || (this.compdetailsadd.cntr_suff_ind!=null && this.compdetailsadd.cntr_suff_ind.toLowerCase()=="no"))
            {
                
              this.AddSuffixAddedBecauseOfOwnership=true;
              this.containerOrComponentBase=this.compdetailsadd.cntr_base;
              this.compdetailsadd.cntr_suff_ind="Yes";
              this.disabledAddSuffixToCompButton=true;
              this.ownershipCodeSelected=selectedOwnershipCode.ownrshp_strat_code;
              console.log("enable component suffix")
              this.compsuffixchangedadd();
            }
            else
            {
            this.disabledAddSuffixToCompButton=false;
            }
          }
        }
        else{
          this.disabledAddSuffixToCompButton=false;
          this.disabledAddSuffixToCntrButton=false; 
        }
      }
    }
  }

  /* disableAddCntrToSuffix()
  {
    if(this.selected_cntr_ownrship_strat_code!=null && this.selected_cntr_ownrship_strat_code.ownrshp_strat_code!=null &&
       (this.selected_cntr_ownrship_strat_code.ownrshp_strat_code.toLowerCase()=='spu' || this.selected_cntr_ownrship_strat_code.ownrshp_strat_code.toLowerCase()=='sou'))
       {
         if(this.cntrdetailsadd.cntr_suff_ind!=null && this.cntrdetailsadd.cntr_suff_ind.toLowerCase()=="no")
         {
          this.cntrdetailsadd.cntr_suff_ind="Yes";
          //this.cntrsuffixchangedadd();
          return true;
          //this.checkIfUniqueCosIsSelected(this.selected_cntr_ownrship_strat_code,"container");
         }
       }
  }
 */
  okCntrSuffixAdded()
  {
    this.AddSuffixAddedBecauseOfOwnership=false;
    this.containerOrComponentBase=null;

  }

 checkContainerValidate() // to again check for valiadtion if submit is pressed before sopy container/data load from cmms
 {

    if(this.collapsedHtInValid )
    {
      this.validateCollapsedHtForAdd();
    }
    if( this.nestRatioInValid )
    {
      this.validateNestRatioForAdd();
    }
    if( this.nestRatio1InValid )
    {
      this.validateNestRatio1ForAdd();
    }
    if( this.cntrTareWtInValid )
    {
      this.validateCntrTareWeightForAdd();
    }
    if( this.cntrCapWtInValid )
    {
      this.validateCntrCapWtForAdd();
    }
    if( this.cntrPerLayerInValid )
    {
      this.validateCntrPerLayerForAdd();
    }
    if( this.maxLayerPerSuInValid )
    {
      this.validateMaxLayerPerSuForAdd();
    }
    if( this.cntrQtPerMinSuInValid )
    {
      this.validateCntrQtPerMinSuForAdd();
    }
    if( this.MaxCntrPerSuInValid )
    {
      this.validateMaxCntrPerSuForAdd();
    }
    if( this.cntrExtLInValid )
    {
      this.validateCntrExtLForAdd();
    }
    if( this.cntrExtWInValid )
    {
      this.validateCntrExtWForAdd();
    }
    if( this.cntrExtHInValid )
    {
      this.validateCntrExtHForAdd();
    }
    if( !this.cntrIntLInValid)
    {
      this.validateCntrIntLForAdd();
    }
    if( this.cntrIntWInValid )
    {
      this.validateCntrIntWForAdd();
    }
    if( this.cntrIntHInValid )
    {
      this.validateCntrIntHForAdd();
    }
    if( this.suNestedHtInValid )
    {
      this.validateSuNestedFldedHtForAdd();
    }
    if( this.suTareWtInvalid )
    {
      this.validateSuTareWeightForAdd();
    }
    if( this.wareHourseStackHtInvaid )
    {
      this.validateWareHourseStckHtForAdd();
    }
    if( this.suStackHtInvalid )
    {
      this.validateShippingUnitStackHtForAdd();
    }
    if( this.suExtLInValid )
    {
      this.validateSUExtLForAdd();
    }
    if( this.suExtWInValid )
    {
      this.validateSUExtWForAdd();
    }
    if( this.suExtHInValid )
    {
      this.validateSUExtHForAdd();
    }
    if( this.cntrBaseInvalid )
    {
      this.validateCntrBaseForAdd();
    }
    if( this.cntrSuffixInvalid )
    {
      this.validateCntrSuffixForAdd();
    }
    if( this.cntrDescInvalid )
    {
      this.validateCntrDescForAdd()
    }
    /* if( this.plantCodeInvalid )
    {
      this.validatePlantCodeForAdd()
    } */
    /* if( this.cardOrCallInvalid ) 
    {
      this.validateCardOrCallForAdd()
    } */
    if( this.mastrCatgInvalid ) 
    {
      this.validateMastCatgForAdd()
    }
    if( this.retTypeInvalid )
    {
      this.validateCntrRetTypeForAdd()
    }
    if( this.cntrTypeInvalid )
    {
      this.validateCntrTypeCodeForAdd()
    }
    if( this.cntrMaterialInvalid )
    {
      this.validateCntrMaterialForAdd()
    }
   /*  if( this.cosInvalid ) 
    {
      this.validateCOSForAdd()
    } */
    if( this.cntrGrpInvalid ) 
    {
      this.validateCntrGrpForAdd()
    }
    if( this.isCollapsibleInvalid )
    {
      this.validateCntrCollapsible()
    }
    if( this.cntctPhoneInvalid )
    {
      this.validateCntrContactPhone()
    }
    if( this.cntrActiveInvalid)
    {
      this.validateCntrActive()
    }
 }

  clearCntrDetailsExceptBaseAndSuffixAndPlantCode()
  {
    // this.selectedPlantList={cd_plant:"",plantGSDBCode:"",plant_active_stat:"",plant_name:"",plant_type_name:""};

    this.selected_cntr_master_category={cd_cntr_mstr_catg:0,cntr_mstr_catg_desc:'',updated_by:'',updated_dt:'',cntr_mstr_catg:'',active_stat:'',active_stat_display:false};

    this.selected_cntr_type_code={cd_cntr_typ:'',cntr_typ_desc:'',typ_no:0};

    this.selected_cntr_ownrship_strat_code={cd_region:'',ownrshp_strat_code:'',ownrshp_desc:'',cd_ownrshp_strat:0};

    this.selected_cntr_group={cntr_grp:''};

    this.selected_cntr_material={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
    this.enable_material_dropdown=false;

    this.selected_cntr_currency_list={cd_curr:0,curr_desc:''};

    this.selected_ownrship_catg={ownrshp_catg:''};

    this.cntrdetailsadd=
    {
        // cd_cntr:0,
        function:'',
        cntr_suff_ind:this.cntrdetailsadd.cntr_suff_ind,
        exp_refundable:'',
        cntr_active_ind:'',
        cntr_base:this.cntrdetailsadd.cntr_base,
        cntr_suffix:this.cntrdetailsadd.cntr_suffix,
        cntr_desc:'',
        card_call:'',
        cntr_mstr_catg:'',
        cntr_typ_desc:'',
        ownrshp_strat_code:'',
        ownrshp_catg:'',
        cntr_grp_desc:'',
        cntr_exterior_l:0,
        cntr_exterior_w:0,
        cntr_exterior_h:0,
        collapsed_ht:0.0,
        nest_ratio:0,
        cntr_interior_l:0,
        cntr_interior_w:0,
        cntr_interior_h:0,
        cntr_tare_wt:0,
        cntr_capacity:0,
        cntr_per_layer:0,
        max_layers_shpg_unit:0,
        max_cntr_per_shpg_unit:0,
        cntr_matr_desc:'',
        cntr_drawing_no:'',
        shpg_unit_exterior_l:0,
        shpg_unit_exterior_w:0,
        shpg_unit_exterior_h:0,
        shpg_unit_nstdorflded_ht:0,
        shpg_unit_tare_wt:0,
        shpg_unit_stack_ht:0,
        warehouse_stack_ht:0,
        service_life:'',
        currency_desc:'',
        purchase_cost:0,
        rental_issue_cost:0,
        sup_rntl_dly_cost:0,
        ford_rntl_dly_cost:0,
        return_rntl_cost:0,
        tooling_dev_cost:0,
        extr_cntr_dsgn_img_ind:'No',
        intr_cntr_dsgn_img_ind:'No',
        shpg_unit_img_ind:'No',
        cad_img_ind:'No',
        img_notes:'',
        cd_region:'',
        iscontainercolapsble:'',
        cd_plant:this.cntrdetailsadd.cd_plant,
        cntr_qt_per_min_su:0,
        nest_ratio_1:0,
        ford_cntct:'',
        cntct_phone:'',
        id_user:'',
        ts_last_update:'',
        req_stat:'',
        components:[{
            mp_function:'',
            no_comp_base:'',
            no_comp_suffix:'',
            ds_comp:'',
            comp_qt_per_min_su:0,
            comp_cd_owner:'',
            in_comp:'',
            comp_cd_type:''
        }],
        UoM:'E',
        dbia_req_no:0,
        typ_no:0
    }
  }


  containerDetailsInit()
  {
    this.selectedPlantList={cd_plant:'',plantGSDBCode:'',plant_active_stat:'',plant_name:'',plant_type_name:''};

    this.selected_cntr_master_category={cd_cntr_mstr_catg:0,cntr_mstr_catg_desc:'',updated_by:'',updated_dt:'',cntr_mstr_catg:'',active_stat:'',active_stat_display:false};

    this.selected_cntr_type_code={cd_cntr_typ:'',cntr_typ_desc:'',typ_no:0};

    this.selected_cntr_ownrship_strat_code={cd_region:'',ownrshp_strat_code:'',ownrshp_desc:'',cd_ownrshp_strat:0};

    this.selected_cntr_group={cntr_grp:''};

    this.selected_cntr_material={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
    this.enable_material_dropdown=false;

    this.selected_cntr_currency_list={cd_curr:0,curr_desc:''};

    this.selected_ownrship_catg={ownrshp_catg:''};

    this.cntrdetailsadd=
    {
        // cd_cntr:0,
        function:'',
        cntr_suff_ind:'',
        exp_refundable:'',
        cntr_active_ind:'',
        cntr_base:'',
        cntr_suffix:'',
        cntr_desc:'',
        card_call:'',
        cntr_mstr_catg:'',
        cntr_typ_desc:'',
        ownrshp_strat_code:'',
        ownrshp_catg:'',
        cntr_grp_desc:'',
        cntr_exterior_l:0,
        cntr_exterior_w:0,
        cntr_exterior_h:0,
        collapsed_ht:0.0,
        nest_ratio:0,
        cntr_interior_l:0,
        cntr_interior_w:0,
        cntr_interior_h:0,
        cntr_tare_wt:0,
        cntr_capacity:0,
        cntr_per_layer:0,
        max_layers_shpg_unit:0,
        max_cntr_per_shpg_unit:0,
        cntr_matr_desc:'',
        cntr_drawing_no:'',
        shpg_unit_exterior_l:0,
        shpg_unit_exterior_w:0,
        shpg_unit_exterior_h:0,
        shpg_unit_nstdorflded_ht:0,
        shpg_unit_tare_wt:0,
        shpg_unit_stack_ht:0,
        warehouse_stack_ht:0,
        service_life:'',
        currency_desc:'',
        purchase_cost:0,
        rental_issue_cost:0,
        sup_rntl_dly_cost:0,
        ford_rntl_dly_cost:0,
        return_rntl_cost:0,
        tooling_dev_cost:0,
        extr_cntr_dsgn_img_ind:'No',
        intr_cntr_dsgn_img_ind:'No',
        shpg_unit_img_ind:'No',
        cad_img_ind:'No',
        img_notes:'',
        cd_region:'',
        iscontainercolapsble:'',
        cd_plant:'',
        cntr_qt_per_min_su:0,
        nest_ratio_1:0,
        ford_cntct:'',
        cntct_phone:'',
        id_user:'',
        ts_last_update:'',
        req_stat:'',
        components:[{
            mp_function:'',
            no_comp_base:'',
            no_comp_suffix:'',
            ds_comp:'',
            comp_qt_per_min_su:0,
            comp_cd_owner:'',
            in_comp:'',
            comp_cd_type:''
        }],
        UoM:'E',
        dbia_req_no:0,
        typ_no:0
    }
  }

  goToSuppMainPage()
  {
    let reg_supp_url:string;
    reg_supp_url=this.transactionService.getregionurlvalue(this.containerRequestScreenCurrentRegion);
    if(this.containerRequestScreenRoleid==1)
    {
    reg_supp_url+='packagingprocess/packsupplieradmin/packsupplier';
    }
    else if(this.containerRequestScreenRoleid==2){
      reg_supp_url+='packagingprocess/packdesignsourceadmin/packdesignsource';

    }
    this.router.navigate([reg_supp_url]);

  }

  addContainerRequest()
  {
    this.cntrdetailsadd.cntr_base=this.cntrdetailsadd.cntr_base.toUpperCase();
    this.cntrdetailsadd.cd_region=this.currentRegionCodeSub;
    this.cntrdetailsadd.extr_cntr_dsgn_img_ind='No';
    this.cntrdetailsadd.intr_cntr_dsgn_img_ind='No';
    this.cntrdetailsadd.shpg_unit_img_ind='No';
    this.cntrdetailsadd.cad_img_ind='No';

    if(this.cntrdetailsadd.iscontainercolapsble!=null && this.cntrdetailsadd.iscontainercolapsble.toLowerCase()=='n')
    {
      this.cntrdetailsadd.collapsed_ht=1;
    }

    this.cntrdetailsadd.cd_plant=this.selectedPlantList.plantGSDBCode.trim();
    this.cntrdetailsadd.cntr_grp_desc=this.selected_cntr_group.cntr_grp;
    this.cntrdetailsadd.cntr_matr_desc=this.selected_cntr_material.cntr_matr_name;
    this.cntrdetailsadd.cntr_mstr_catg=this.selected_cntr_master_category.cntr_mstr_catg;
    this.cntrdetailsadd.cntr_typ_desc=String(this.selected_cntr_type_code.cd_cntr_typ);
    this.cntrdetailsadd.typ_no=this.selected_cntr_type_code.typ_no;
    this.cntrdetailsadd.currency_desc=this.selected_cntr_currency_list.curr_desc;
    this.cntrdetailsadd.ownrshp_strat_code=this.selected_cntr_ownrship_strat_code.ownrshp_strat_code;


    if(this.add_measure=='kg/cm')
    {
       this.cntrdetailsadd.UoM='M'
    }
    else
    {
      this.cntrdetailsadd.UoM='E'
    }

    // this.cntrdetailsadd.id_user=this.containerRequestScreenCdsid;
    this.cntrdetailsadd.id_user=this.userCode;
    this.cntrdetailsadd.ts_last_update=this.date.transform(new Date(),'yyyy-MM-dd');
    this.cntrdetailsadd.req_stat='open';

    if(this.cntrdetailsadd.cntr_drawing_no == null )
    {
      this.cntrdetailsadd.cntr_drawing_no=''
    }

    if(this.cntrdetailsadd.service_life == null)
    {
      this.cntrdetailsadd.service_life='';
    }

    this.validateCollapsedHtForAdd();
    this.validateNestRatioForAdd();
    this.validateNestRatio1ForAdd();
    this.validateCntrTareWeightForAdd();
    this.validateCntrCapWtForAdd();
    this.validateCntrPerLayerForAdd();
    this.validateMaxLayerPerSuForAdd();
    this.validateCntrQtPerMinSuForAdd();
    this.validateMaxCntrPerSuForAdd();
    this.validateCntrExtLForAdd();
    this.validateCntrExtWForAdd();
    this.validateCntrExtHForAdd();
    this.validateCntrIntLForAdd();
    this.validateCntrIntWForAdd();
    this.validateCntrIntHForAdd();
    this.validateSuNestedFldedHtForAdd();
    this.validateSuTareWeightForAdd();
    this.validateWareHourseStckHtForAdd();
    this.validateShippingUnitStackHtForAdd();
    this.validateSUExtLForAdd();
    this.validateSUExtWForAdd();
    this.validateSUExtHForAdd();
    this.validateCntrBaseForAdd();
    this.validateCntrSuffixForAdd();
    this.validateCntrDescForAdd()
    //this.validatePlantCodeForAdd()
    //this.validateCardOrCallForAdd()
    this.validateMastCatgForAdd()
    this.validateCntrRetTypeForAdd()
    this.validateCntrTypeCodeForAdd()
    this.validateCntrMaterialForAdd()
    //this.validateCOSForAdd()
    this.validateCntrGrpForAdd()
    this.validateCntrCollapsible()
    // this.validateCntrContact() -- Removed because we will send null for ford_cntct field in this page
    this.validateCntrContactPhone()
    this.validateCntrActive()


    if(!this.collapsedHtInValid && !this.nestRatioInValid && !this.nestRatio1InValid &&
      !this.cntrTareWtInValid && !this.cntrCapWtInValid && !this.cntrPerLayerInValid &&
      !this.maxLayerPerSuInValid && !this.cntrQtPerMinSuInValid && !this.MaxCntrPerSuInValid &&
      !this.cntrExtLInValid && !this.cntrExtWInValid && !this.cntrExtHInValid && !this.cntrIntLInValid &&
      !this.cntrIntWInValid && !this.cntrIntHInValid && !this.suNestedHtInValid && !this.suTareWtInvalid &&
      !this.wareHourseStackHtInvaid && !this.suStackHtInvalid && !this.suExtLInValid && !this.suExtWInValid &&
      !this.suExtHInValid && !this.cntrBaseInvalid && !this.cntrSuffixInvalid && !this.cntrDescInvalid && !this.plantCodeInvalid &&
      !this.cardOrCallInvalid && !this.mastrCatgInvalid && !this.retTypeInvalid && !this.cntrTypeInvalid &&
      !this.cntrMaterialInvalid && !this.cosInvalid && !this.cntrGrpInvalid && !this.isCollapsibleInvalid &&
      !this.cntrCntctInvalid && !this.cntctPhoneInvalid && !this.cntrActiveInvalid)
    {
      console.log('details before adding',this.cntrdetailsadd);
      let suffixToApi='';
      if(this.cntrdetailsadd.cntr_suff_ind.toLowerCase()=='no')  {
        suffixToApi='%20%20%20'
      }
      else
      {
        suffixToApi=this.cntrdetailsadd.cntr_suffix;
      }

      /*this.transactionService.getRequestStatusOfContainer(this.cntrdetailsadd.cntr_base,suffixToApi,this.cntrdetailsadd.ownrshp_strat_code,this.cntrdetailsadd.cd_region).subscribe(requestStatus=>{
        console.log('Request Status',requestStatus);
        const requestStatusReceived=requestStatus;

        if(requestStatusReceived!= null && requestStatusReceived.trim() != '' && requestStatusReceived.toLowerCase() =='pending')
        {
          this.enableCntrEditPendingNotification=true;
        }
        else
        {*/
          if(this.cntrdetailsadd.function!=null && this.cntrdetailsadd.function!='' && this.cntrdetailsadd.function=='A')
          {
            this.enableAddingNewContainerNotification=true;
          }
          else if(this.cntrdetailsadd.function!=null && this.cntrdetailsadd.function!='' && this.cntrdetailsadd.function=='C')
          {
            this.enableUpdateExistingCntrNotification=true;
          }

          if(this.cntrdetailsadd.cntr_suffix=='%20%20%20%20%20%20%20%20' || this.cntrdetailsadd.cntr_suffix=='')
          {
            this.cntrdetailsadd.cntr_suffix=null;
          }

       // }
      //})
    }
  }

  addCntrDBIARequest()
  {
    this.transactionService.savecntrdetails(this.cntrdetailsadd).subscribe(savecntr=>
      {
          const res=savecntr;
          console.log('successful',res);
          if(res.toLowerCase() == 'success')
          {
            this.packagingprocess.addtoastinpackagingprocess({severity:'success', summary:'Success', detail:'Submitted Requested Successfully'});
            // this.containerDetailsInit();
            // this.validationInit();
            this.clear_add();
           // this.goToSuppMainPage();
          }
          else
          {
              this.packagingprocess.addtoastinpackagingprocess({severity:'error', summary: 'Error', detail: 'Request Not Submitted,Please Try Again'});
              this.validationInit();
          }
      });
  }

  okCntrEditPendingNotification()
  {
    this.enableCntrEditPendingNotification=false;
    // this.addCntrRequestToDBIA();
  }

  okAddingNewContainerNotification()
  {
    this.enableAddingNewContainerNotification=false;
    this.addCntrDBIARequest();
  }

  cancelAddingNewContainerNotification()
  {
    this.enableAddingNewContainerNotification=false;
  }

  okUpdateExistingCntrNotification()
  {
    this.enableUpdateExistingCntrNotification=false;
    this.addCntrDBIARequest();
  }

  cancelUpdateExistingCntrNotification()
  {
    this.enableUpdateExistingCntrNotification=false;
  }

  clear_add()
  {
    this.cntrdetailsadd.cntr_base='';
    this.cntrdetailsadd.cntr_suffix='';
    this.cntrdetailsadd.cntr_suff_ind='';
    this.cntrSuffixAdd='';
    this.cntrSuffixEnabled=true;
    this.disableCntrDetailsWhenBaseOrSuffixChanged=true;
    this.prevCntrSuffixInd=null;
    this.enteredCntrBase=null;
    this.selected_container=null;
    this.table_selected_container=null;

    this.disabledAddSuffixToCntrButton=false;
    this.AddSuffixAddedBecauseOfOwnership=false;
    this.containerOrComponentBase=null;

    this.cont_retype="Returnable";
    this.tableSelectedReturnType=null;

    this.isCmmsLoadedComponent=false;
    this.isCmmsLoadedContainer=false;

    this.containerDetailsCopied=false;

    this.selected_cntr_ownrship_strat_code_in_popup=this.cntrOwnershipstratcodelist[0];
    this.table_selected_cntr_ownrship_strat_code_in_popup=null;

    this.selectedPlantList={cd_plant:'',plantGSDBCode:'',plant_active_stat:'',plant_name:'',plant_type_name:''};
    this.cntrdetailsadd.cd_plant='';

    this.clearCntrDetailsExceptBaseAndSuffixAndPlantCode();
    this.validationInit();
  }

  cancel_add()
  {
    this.goToSuppMainPage();
  }


  // Validations
  validateCntrBaseForAdd()
  {
    if(this.cntrdetailsadd.cntr_base==null || this.cntrdetailsadd.cntr_base.trim()=='')
    {
      this.cntrBaseInvalid=true;
      this.cntrBaseInvalidReason='Required'
    }
    else
    {
      this.cntrBaseInvalid=false;
      this.cntrBaseInvalidReason=null
    }
  }

  validateCntrSuffixForAdd()
  {
    if(this.cntrdetailsadd.cntr_suff_ind==null || this.cntrdetailsadd.cntr_suff_ind.trim()=='')
    {
      this.cntrSuffixInvalid=true;
      this.cntrSuffixInvalidReason='Required'
    }
    else
    {
      this.cntrSuffixInvalid=false;
      this.cntrSuffixInvalidReason=null
    }
  }


validateCntrDescForAdd()
{
  if(!this.isCmmsLoadedContainer)
  {
    if(this.cntrdetailsadd.cntr_desc==null || this.cntrdetailsadd.cntr_desc.trim()=='')
    {
      this.cntrDescInvalid=true;
      this.cntrDescInvalidReason='Required'
    }
    else
    {
      this.cntrDescInvalid=false;
      this.cntrDescInvalidReason=null
    }
  }

}

validatePlantCodeForAdd()
{if(this.selectedPlantList.plantGSDBCode==null || this.selectedPlantList.plantGSDBCode.trim()=='')
    {
      this.plantCodeInvalid=true;
      this.plantCodeInvalidReason='Required'
    }
    else
    {
      this.plantCodeInvalid=false;
      this.plantCodeInvalidReason=null
    }

}

validateCardOrCallForAdd()
{
  if(this.cntrdetailsadd.card_call==null || this.cntrdetailsadd.card_call.trim()=='')
  {
    this.cardOrCallInvalid=true;
    this.cardOrCallInvalidReason='Required'
  }
  else
  {
    this.cardOrCallInvalid=false;
    this.cardOrCallInvalidReason=null
  }
}


validateMastCatgForAdd()
{
  if(this.selected_cntr_master_category.cntr_mstr_catg==null || this.selected_cntr_master_category.cntr_mstr_catg.trim()=='')
  {
    this.mastrCatgInvalid=true;
    this.mastrCatgInvalidReason='Required'
  }
  else
  {
    this.mastrCatgInvalid=false;
    this.mastrCatgInvalidReason=null
  }
}

validateCntrRetTypeForAdd()
{
  if(this.cntrdetailsadd.exp_refundable==null || this.cntrdetailsadd.exp_refundable.trim()=='')
  {
    this.retTypeInvalid=true;
    this.retTypeInvalidReason='Required'
  }
  else
  {
    this.retTypeInvalid=false;
    this.retTypeInvalidReason=null;
  }
}

validateCntrTypeCodeForAdd()
{
  if(!this.isCmmsLoadedContainer)
  {
    if(this.selected_cntr_type_code.cntr_typ_desc==null || this.selected_cntr_type_code.cntr_typ_desc.trim()=='')
    {
      this.cntrTypeInvalid=true;
      this.cntrTypeInvalidReason='Required';
    }
    else
    {
      this.cntrTypeInvalid=false;
      this.cntrTypeInvalidReason=null;
    }
  }
}

validateCntrMaterialForAdd()
{
  if(this.selected_cntr_material.cntr_matr_name==null || this.selected_cntr_material.cntr_matr_name.trim()=='')
  {
    this.cntrMaterialInvalid=true;
    this.cntrMaterialInvalidReason='Required';
  }
  else
  {
    this.cntrMaterialInvalid=false;
    this.cntrMaterialInvalidReason=null;
  }
}
/* validateCntrOwnershpCatgForAdd()
{
  if(this.selected_ownrship_catg.ownrshp_catg==null || this.selected_ownrship_catg.ownrshp_catg.trim()=="")
  {
    this.cntrOwnerShpCatgInvalid=true;
    this.cntrOwnerShpCatgInvalidReason="Required";
  }
  else
  {
    this.cntrOwnerShpCatgInvalid=false;
    this.cntrOwnerShpCatgInvalidReason=null;
  }
} */

validateCOSForAdd()
{
  if(this.selected_cntr_ownrship_strat_code.ownrshp_strat_code==null || this.selected_cntr_ownrship_strat_code.ownrshp_strat_code.trim()=='')
  {
    this.cosInvalid=true;
    this.cosInvalidReason='Required';
  }
  else
  {
    this.cosInvalid=false;
    this.cosInvalidReason=null;
  }
}

validateCntrGrpForAdd()
{
  if(this.selected_cntr_group.cntr_grp==null || this.selected_cntr_group.cntr_grp.trim()=='')
  {
    this.cntrGrpInvalid=true;
    this.cntrGrpInvalidReason='Required';
  }
  else
  {
    this.cntrGrpInvalid=false;
    this.cntrGrpInvalidReason=null;
  }
}

validateCntrCollapsible()
{
  if(this.cntrdetailsadd.iscontainercolapsble==null || this.cntrdetailsadd.iscontainercolapsble.trim()=='')
  {
    this.isCollapsibleInvalid=true;
    this.isCollapsibleInvalidReason='Required';
  }
  else{
    this.isCollapsibleInvalid=false;
    this.isCollapsibleInvalidReason=null;
  }
}

validateCntrContact()
{
  if(!this.isCmmsLoadedContainer)
  {
    if(this.cntrdetailsadd.ford_cntct==null || this.cntrdetailsadd.ford_cntct.trim()=='')
    {
      this.cntrCntctInvalid=true;
      this.cntrCntctInvalidReason='Required';
    }
    else
    {
      this.cntrCntctInvalid=false;
      this.cntrCntctInvalidReason=null;
    }
  }
}

validateCntrContactPhone()
{
  if(!this.isCmmsLoadedContainer)
  {
    if(this.cntrdetailsadd.cntct_phone==null || this.cntrdetailsadd.cntct_phone.trim()=='')
    {
      this.cntctPhoneInvalid=true;
      this.cntctPhoneInvlidReason='Required';
    }
    else
    {
      this.cntctPhoneInvalid=false;
      this.cntctPhoneInvlidReason=null;
    }
  }
}

validateCntrActive()
{
  if(this.cntrdetailsadd.cntr_active_ind==null || this.cntrdetailsadd.cntr_active_ind.trim()=='')
  {
    this.cntrActiveInvalid=true;
    this.cntrActiveInvalidReason='Required';
  }
  else
  {
    this.cntrActiveInvalid=false;
    this.cntrActiveInvalidReason=null;
  }
}


validateCollapsedHtForAdd()
{
  if(!this.isCmmsLoadedContainer)
  {
    if(this.cntrdetailsadd.iscontainercolapsble.toLowerCase()=='y' && (this.cntrdetailsadd.collapsed_ht==null || this.cntrdetailsadd.collapsed_ht==0))
    {
      this.collapsedHtInValid=true;
      this.collapsedHtInValidReason='Required';
    }

    else if(this.cntrdetailsadd.iscontainercolapsble.toLowerCase()=='y' && (this.cntrdetailsadd.collapsed_ht<=0 || this.cntrdetailsadd.collapsed_ht>999.999 ))
    {
      this.collapsedHtInValid=true;
      this.collapsedHtInValidReason='Should be of range 0 to 999.999';
    }
    else if(this.cntrdetailsadd.iscontainercolapsble.toLowerCase()=='y' && this.cntrdetailsadd.collapsed_ht > this.cntrdetailsadd.cntr_exterior_h)
    {
      this.collapsedHtInValid=true;
      this.collapsedHtInValidReason='Collapsed Height cannot be greater than Container height';
    }
    else if(this.cntrdetailsadd.iscontainercolapsble.toLowerCase()=='y' && String(this.cntrdetailsadd.collapsed_ht).includes('.'))
    {
      const colap_ht = String(this.cntrdetailsadd.collapsed_ht).split('.')
      if(colap_ht[1].length > 3)
      {
        this.collapsedHtInValid=true;
        this.collapsedHtInValidReason='Max Length Of decimal values can be 3';
      }
      else
      {
        this.collapsedHtInValid=false;
        this.collapsedHtInValidReason=null;
      }
    }
    else
    {
      this.collapsedHtInValid=false;
      this.collapsedHtInValidReason=null;
    }
  }
}

validateNestRatioForAdd()
{
  if(!this.isCmmsLoadedContainer)
  {
    if(this.cntrdetailsadd.nest_ratio==null || this.cntrdetailsadd.nest_ratio==0)
    {
      this.nestRatioInValid=true;
      this.nestRatioInValidReason='Required';
    }
    else if(this.cntrdetailsadd.nest_ratio<1 || this.cntrdetailsadd.nest_ratio>99 || String(this.cntrdetailsadd.nest_ratio).includes('.'))
    {
      this.nestRatioInValid=true;
      this.nestRatioInValidReason='Should be of range 1 to 99 and cannot contain Decimal';
    }
    else
    {
      this.nestRatioInValid=false;
      this.nestRatioInValidReason=null;
    }
  }
}
validateNestRatio1ForAdd()
{
  if(!this.isCmmsLoadedContainer)
  {
    if(this.cntrdetailsadd.nest_ratio_1==null || this.cntrdetailsadd.nest_ratio_1==0)
    {
      this.nestRatio1InValid=true;
      this.nestRatio1InValidReason='Required';
    }
    else if(this.cntrdetailsadd.nest_ratio_1<1 || this.cntrdetailsadd.nest_ratio_1>9 || String(this.cntrdetailsadd.nest_ratio_1).includes('.'))
    {
      this.nestRatio1InValid=true;
      this.nestRatio1InValidReason='Should be of range 1 to 9 and cannot contain Decimal';
    }
    else
    {
      this.nestRatio1InValid=false;
      this.nestRatio1InValidReason=null;
    }
  }
}
validateCntrTareWeightForAdd()
{
  if(!this.isCmmsLoadedContainer)
  {
    const cont_tar_wt = String(this.cntrdetailsadd.cntr_tare_wt).split('.');
    if(this.cntrdetailsadd.cntr_tare_wt==null || this.cntrdetailsadd.cntr_tare_wt==0)
    {
      this.cntrTareWtInValid=true;
      this.cntrTareWtInValidReason='Required';
    }
   else if(!String(this.cntrdetailsadd.cntr_tare_wt).includes('.'))
    {
   /*if( String(this.cntrdetailsadd.cntr_tare_wt).length>10)
        {
          this.cntrTareWtInValid=true;
          this.cntrTareWtInValidReason="Max Length Of Characters can be 10";
        }
        else*/
         if(this.cntrdetailsadd.cntr_tare_wt<=0 || this.cntrdetailsadd.cntr_tare_wt>99999.999)
        {
          this.cntrTareWtInValid=true;
          this.cntrTareWtInValidReason='Should be of range 0 to 99999.999';
        }
      else
        {
          this.cntrTareWtInValid=false;
          this.cntrTareWtInValidReason=null;
        }
    }
    else if(String(this.cntrdetailsadd.cntr_tare_wt).includes('.'))
    {
        if(String(cont_tar_wt[0]).length<0 || String(cont_tar_wt[0]).length>5)
        {
          this.cntrTareWtInValid=true;
          this.cntrTareWtInValidReason='Should be of range 0 to 99999.999';
        }
        else if(String(cont_tar_wt[1]).length<0 || String(cont_tar_wt[1]).length>3)
        {
          this.cntrTareWtInValid=true;
          this.cntrTareWtInValidReason='Max Length Of decimal values can be 3';
        }
        else
        {
          this.cntrTareWtInValid=false;
          this.cntrTareWtInValidReason=null;
        }
    }
    else
    {
    this.cntrTareWtInValid=false;
    this.cntrTareWtInValidReason=null;
    }
  }
}
validateCntrCapWtForAdd()
{
  if(!this.isCmmsLoadedContainer)
  {
    const cont_cap_wt = String(this.cntrdetailsadd.cntr_capacity).split('.');
    if(this.cntrdetailsadd.cntr_capacity==null || this.cntrdetailsadd.cntr_capacity==0)
    {
      this.cntrCapWtInValid=true;
      this.cntrCapWtInValidReason='Required';
    }
    else if(this.cntrdetailsadd.cntr_capacity<=0 || this.cntrdetailsadd.cntr_capacity>99999.999)
    {
      this.cntrCapWtInValid=true;
      this.cntrCapWtInValidReason='Should be of range 0 to 99999.999';
    }
    else if(String(this.cntrdetailsadd.cntr_capacity).includes('.'))
    {
      if(cont_cap_wt[1].length > 3)
      {
        this.cntrCapWtInValid=true;
        this.cntrCapWtInValidReason='Max Length Of decimal values can be 3';
      }
      else
      {
        this.cntrCapWtInValid=false;
        this.cntrCapWtInValidReason=null;
      }
    }

    else
    {
      this.cntrCapWtInValid=false;
      this.cntrCapWtInValidReason=null;
    }
  }
}
validateCntrPerLayerForAdd()
{
  if(this.cntrdetailsadd.cntr_per_layer==null || this.cntrdetailsadd.cntr_per_layer==0)
  {
    this.cntrPerLayerInValid=true;
    this.cntrPerLayerInValidReason='Required';
  }
  else if(this.cntrdetailsadd.cntr_per_layer<1 || this.cntrdetailsadd.cntr_per_layer>99999 || String(this.cntrdetailsadd.cntr_per_layer).includes('.'))
  {
    this.cntrPerLayerInValid=true;
    this.cntrPerLayerInValidReason='Should be of range 1 to 99999 and cannot contain Decimal';
  }
  else
  {
    this.cntrPerLayerInValid=false;
    this.cntrPerLayerInValidReason=null;
  }
}
validateMaxLayerPerSuForAdd()
{
  if(this.cntrdetailsadd.max_layers_shpg_unit==null || this.cntrdetailsadd.max_layers_shpg_unit==0)
  {
    this.maxLayerPerSuInValid=true;
    this.maxLayerPerSuInValidReason='Required';
  }
  else if(this.cntrdetailsadd.max_layers_shpg_unit<1 || this.cntrdetailsadd.max_layers_shpg_unit>99999 || String(this.cntrdetailsadd.max_layers_shpg_unit).includes('.'))
  {
    this.maxLayerPerSuInValid=true;
    this.maxLayerPerSuInValidReason='Should be of range 1 to 99999  and cannot contain Decimal';
  }
  else
  {
    this.maxLayerPerSuInValid=false;
    this.maxLayerPerSuInValidReason=null;
  }
}
validateCntrQtPerMinSuForAdd()
{
  if(!this.isCmmsLoadedContainer)
  {
    if(this.cntrdetailsadd.cntr_qt_per_min_su==null || this.cntrdetailsadd.cntr_qt_per_min_su==0)
    {
      this.cntrQtPerMinSuInValid=true;
      this.cntrQtPerMinSuInvalidReason='Required';
    }
    else if(this.cntrdetailsadd.cntr_qt_per_min_su<1 || this.cntrdetailsadd.cntr_qt_per_min_su>99 || String(this.cntrdetailsadd.cntr_qt_per_min_su).includes('.'))
    {
      this.cntrQtPerMinSuInValid=true;
      this.cntrQtPerMinSuInvalidReason='Should be of range 1 to 99  and cannot contain Decimal';
    }
    else
    {
      this.cntrQtPerMinSuInValid=false;
      this.cntrQtPerMinSuInvalidReason=null;
    }
  }
}
validateMaxCntrPerSuForAdd()
{

  if( (this.cntrdetailsadd.max_cntr_per_shpg_unit>99999 || String(this.cntrdetailsadd.max_cntr_per_shpg_unit).includes('.')))
  {
    this.MaxCntrPerSuInValid=true;
    this.MaxCntrPerMinSuInvalidReason='Should be of range 1 to 99999  and cannot contain Decimal';
  }
  else
  {
    this.MaxCntrPerSuInValid=false;
    this.MaxCntrPerMinSuInvalidReason=null;
  }
}
validateCntrExtLForAdd()
{
  if(!this.isCmmsLoadedContainer)
  {
    const cont_ext_l = String(this.cntrdetailsadd.cntr_exterior_l).split('.');
    if(this.cntrdetailsadd.cntr_exterior_l==null || this.cntrdetailsadd.cntr_exterior_l==0)
    {
      this.cntrExtLInValid=true;
      this.cntrExtLInValidReason='Required';
    }
    else if(!String(this.cntrdetailsadd.cntr_exterior_l).includes('.'))
    {
    /*if( String(this.cntrdetailsadd.cntr_exterior_l).length>7)
        {
          this.cntrExtLInValid=true;
          this.cntrExtLInValidReason="Max Length Of Characters can be 7";
        }
        else*/
         if(this.cntrdetailsadd.cntr_exterior_l<=0 || this.cntrdetailsadd.cntr_exterior_l>999.999 )
        {
          this.cntrExtLInValid=true;
          this.cntrExtLInValidReason='Should be of range 0 to 999.999';
        }
      else
        {
          this.cntrExtLInValid=false;
          this.cntrExtLInValidReason=null;
        }
    }
    else if(String(this.cntrdetailsadd.cntr_exterior_l).includes('.'))
    {
        if(String(cont_ext_l[0]).length<0 || String(cont_ext_l[0]).length>3)
        {
          this.cntrExtLInValid=true;
          this.cntrExtLInValidReason='Should be of range 0 to 999.999';
        }
        else if(this.cntrdetailsadd.cntr_exterior_l<=0 || this.cntrdetailsadd.cntr_exterior_l>999.999 )
        {
          this.cntrExtLInValid=true;
          this.cntrExtLInValidReason='Should be of range 0 to 999.999';
        }
        else if(String(cont_ext_l[1]).length<0 || String(cont_ext_l[1]).length>3)
        {
          this.cntrExtLInValid=true;
          this.cntrExtLInValidReason='Max Length Of decimal values can be 3';
        }
        else
        {
          this.cntrExtLInValid=false;
          this.cntrExtLInValidReason=null;
        }
    }
    else
    {
    this.cntrExtLInValid=false;
    this.cntrExtLInValidReason=null;
    }
  }
}
validateCntrExtWForAdd()
{
  if(!this.isCmmsLoadedContainer)
  {
    const cont_ext_w = String(this.cntrdetailsadd.cntr_exterior_w).split('.');
    if(this.cntrdetailsadd.cntr_exterior_w==null || this.cntrdetailsadd.cntr_exterior_w==0)
    {
      this.cntrExtWInValid=true;
      this.cntrExtWInValidReason='Required';
    }
    else if(!String(this.cntrdetailsadd.cntr_exterior_w).includes('.'))
    {
      /*if( String(this.cntrdetailsadd.cntr_exterior_w).length>7)
      {
        this.cntrExtWInValid=true;
        this.cntrExtWInValidReason="Max Length Of Characters can be 7";
      }
      else*/
       if(this.cntrdetailsadd.cntr_exterior_w<=0 || this.cntrdetailsadd.cntr_exterior_w>999.999 )
      {
        this.cntrExtWInValid=true;
        this.cntrExtWInValidReason='Should be of range 0 to 999.999';
      }
      else
      {
        this.cntrExtWInValid=false;
        this.cntrExtWInValidReason=null;
      }
    }
    else if(String(this.cntrdetailsadd.cntr_exterior_w).includes('.'))
    {
      if(String(cont_ext_w[0]).length<0 || String(cont_ext_w[0]).length>3)
      {
        this.cntrExtWInValid=true;
        this.cntrExtWInValidReason='Should be of range 0 to 999.999';
      }
      else if(this.cntrdetailsadd.cntr_exterior_w<=0 || this.cntrdetailsadd.cntr_exterior_w>999.999 )
      {
        this.cntrExtWInValid=true;
        this.cntrExtWInValidReason='Should be of range 0 to 999.999';
      }
      else if(String(cont_ext_w[1]).length<0 || String(cont_ext_w[1]).length>3)
      {
        this.cntrExtWInValid=true;
        this.cntrExtWInValidReason='Max Length Of decimal values can be 3';
      }

      else
      {
        this.cntrExtWInValid=false;
        this.cntrExtWInValidReason=null;
      }
    }
    else
    {
    this.cntrExtWInValid=false;
    this.cntrExtWInValidReason=null;
    }
  }
}
validateCntrExtHForAdd()
{
  if(!this.isCmmsLoadedContainer)
  {
    const cont_ext_h = String(this.cntrdetailsadd.cntr_exterior_h).split('.');
    if(this.cntrdetailsadd.cntr_exterior_h==null || this.cntrdetailsadd.cntr_exterior_h==0)
    {
      this.cntrExtHInValid=true;
      this.cntrExtHInValidReason='Required';
    }
    else if(!String(this.cntrdetailsadd.cntr_exterior_h).includes('.'))
    {
      if(this.cntrdetailsadd.cntr_exterior_h<=0 || this.cntrdetailsadd.cntr_exterior_h>999.999 )
        {
          this.cntrExtHInValid=true;
          this.cntrExtHInValidReason='Should be of range 0 to 999.999';
        }
       /* else if( String(this.cntrdetailsadd.cntr_exterior_h).length>7)
        {
          this.cntrExtHInValid=true;
          this.cntrExtHInValidReason="Max Length Of Characters can be 7";
        }*/
        else
        {
          this.cntrExtHInValid=false;
          this.cntrExtHInValidReason=null;
        }
    }
    else if(String(this.cntrdetailsadd.cntr_exterior_h).includes('.'))
    {
        if(String(cont_ext_h[0]).length<0 || String(cont_ext_h[0]).length>3)
        {
          this.cntrExtHInValid=true;
          this.cntrExtHInValidReason='Should be of range 0 to 999.999';
        }
        else if(this.cntrdetailsadd.cntr_exterior_h<=0 || this.cntrdetailsadd.cntr_exterior_h>999.999 )
        {
          this.cntrExtHInValid=true;
          this.cntrExtHInValidReason='Should be of range 0 to 999.999';
        }
        else if(String(cont_ext_h[1]).length<0 || String(cont_ext_h[1]).length>3)
        {
          this.cntrExtHInValid=true;
          this.cntrExtHInValidReason='Max Length Of decimal values can be 3';
        }
        else
        {
          this.cntrExtHInValid=false;
          this.cntrExtHInValidReason=null;
        }
    }
    else
    {
    this.cntrExtHInValid=false;
    this.cntrExtHInValidReason=null;
    }
  }
}
validateCntrIntLForAdd()
{
  if(this.cntrdetailsadd.cntr_interior_l!=null && (this.cntrdetailsadd.cntr_interior_l>99999))
  {
    this.cntrIntLInValid=true;
    this.cntrIntLInValidReason='Should be of range 1 to 99999';
  }
  else if(this.cntrdetailsadd.cntr_interior_l!=null && ( this.cntrdetailsadd.cntr_interior_l>this.cntrdetailsadd.cntr_exterior_l))
  {
    this.cntrIntLInValid=true;
    this.cntrIntLInValidReason='Interior Length cannot be Greater than Exterior Length';
  }
  else
  {
    this.cntrIntLInValid=false;
    this.cntrIntLInValidReason=null;
  }
}
validateCntrIntWForAdd()
{
  if(this.cntrdetailsadd.cntr_interior_w!=null && (this.cntrdetailsadd.cntr_interior_w>99999))
  {
    this.cntrIntWInValid=true;
    this.cntrIntWInValidReason='Should be of range 1 to 99999';
  }
  else if(this.cntrdetailsadd.cntr_interior_w!=null && (this.cntrdetailsadd.cntr_interior_w>this.cntrdetailsadd.cntr_exterior_w))
  {
    this.cntrIntWInValid=true;
    this.cntrIntWInValidReason='Interior width cannot be Greater than Exterior width';
  }
  else
  {
    this.cntrIntWInValid=false;
    this.cntrIntWInValidReason=null;
  }
}
validateCntrIntHForAdd()
{
  if(this.cntrdetailsadd.cntr_interior_h!=null && (this.cntrdetailsadd.cntr_interior_h>99999))
  {
    this.cntrIntHInValid=true;
    this.cntrIntHInValidReason='Should be of range 1 to 99999';
  }
  else if(this.cntrdetailsadd.cntr_interior_h!=null && (this.cntrdetailsadd.cntr_interior_h>this.cntrdetailsadd.cntr_exterior_h))
  {
    this.cntrIntHInValid=true;
    this.cntrIntHInValidReason='Interior height cannot be Greater than Exterior height';
  }
  else
  {
    this.cntrIntHInValid=false;
    this.cntrIntHInValidReason=null;
  }
}
validateSuNestedFldedHtForAdd()
{
  /* if(this.cntrdetailsadd.shpg_unit_nstdorflded_ht==null || this.cntrdetailsadd.shpg_unit_nstdorflded_ht==0)
  {
    this.suNestedHtInValid=true;
    this.suNestedHtInValidReason="Required";
  } */
  if(this.cntrdetailsadd.shpg_unit_nstdorflded_ht<0 || this.cntrdetailsadd.shpg_unit_nstdorflded_ht>99999)
  {
    this.suNestedHtInValid=true;
    this.suNestedHtInValidReason='Should be of range 0 to 99999';
  }
  else if(this.cntrdetailsadd.shpg_unit_nstdorflded_ht>0 && this.cntrdetailsadd.shpg_unit_exterior_h>0 && this.cntrdetailsadd.shpg_unit_nstdorflded_ht>this.cntrdetailsadd.shpg_unit_exterior_h)
  {
    this.suNestedHtInValid=true;
    this.suNestedHtInValidReason='Nested Folded Height cannot be Greater than Exterior height';
  }
  else
  {
    this.suNestedHtInValid=false;
    this.suNestedHtInValidReason=null;
  }
}
validateSuTareWeightForAdd()
{
  const shp_tar_wt = String(this.cntrdetailsadd.shpg_unit_tare_wt).split('.');
  if(!String(this.cntrdetailsadd.shpg_unit_tare_wt).includes('.'))
  {
  if(this.cntrdetailsadd.shpg_unit_tare_wt==null || this.cntrdetailsadd.shpg_unit_tare_wt==0)
      {
        this.suTareWtInvalid=true;
        this.suTareWtInValidReason='Shipping Unit Tare weight can not be 0';
      }
      if( String(this.cntrdetailsadd.shpg_unit_tare_wt).length>10)
      {
        this.suTareWtInvalid=true;
        this.suTareWtInValidReason='Max Length Of Characters can be 10';
      }
      else if(this.cntrdetailsadd.shpg_unit_tare_wt<1 || this.cntrdetailsadd.shpg_unit_tare_wt>999999.999)
      {
        this.suTareWtInvalid=true;
        this.suTareWtInValidReason='Should be of range 1 to 999999.999';
      }
    else
      {
        this.suTareWtInvalid=false;
        this.suTareWtInValidReason=null;
      }
  }
  else if(String(this.cntrdetailsadd.shpg_unit_tare_wt).includes('.'))
  {
      if(String(shp_tar_wt[0]).length<0 || String(shp_tar_wt[0]).length>6)
      {
        this.suTareWtInvalid=true;
        this.suTareWtInValidReason='Should be of range 1 to 999999.999';
      }
      else if(String(shp_tar_wt[1]).length<0 || String(shp_tar_wt[1]).length>3)
      {
        this.suTareWtInvalid=true;
        this.suTareWtInValidReason='Max Length Of decimal values can be 3';
      }
      else
      {
        this.suTareWtInvalid=false;
        this.suTareWtInValidReason=null;
      }
  }
  else
  {
  this.suTareWtInvalid=false;
  this.suTareWtInValidReason=null;
  }
}
validateWareHourseStckHtForAdd()
{
  if(!this.isCmmsLoadedContainer)
  {
    if(this.cntrdetailsadd.warehouse_stack_ht==null || this.cntrdetailsadd.warehouse_stack_ht==0)
    {
      this.wareHourseStackHtInvaid=true;
      this.wareHourseStackHtInvaidReason='Required';
    }
    else if(this.cntrdetailsadd.warehouse_stack_ht<1 || this.cntrdetailsadd.warehouse_stack_ht>99 || String(this.cntrdetailsadd.warehouse_stack_ht).includes('.'))
    {
      this.wareHourseStackHtInvaid=true;
      this.wareHourseStackHtInvaidReason='Should be of range 1 to 99 and cannot contain Decimal';
    }
    else
    {
      this.wareHourseStackHtInvaid=false;
      this.wareHourseStackHtInvaidReason=null;
    }
  }
}
validateShippingUnitStackHtForAdd()
{
  if(!this.isCmmsLoadedContainer)
  {
    if(this.cntrdetailsadd.shpg_unit_stack_ht==null || this.cntrdetailsadd.shpg_unit_stack_ht==0)
    {
      this.suStackHtInvalid=true;
      this.suStackHtInvalidReason='Required';
    }
    else if(this.cntrdetailsadd.shpg_unit_stack_ht<1 || this.cntrdetailsadd.shpg_unit_stack_ht>9 || String(this.cntrdetailsadd.shpg_unit_stack_ht).includes('.'))
    {
      this.suStackHtInvalid=true;
      this.suStackHtInvalidReason='Should be of range 1 to 9 and cannot contain Decimal';
    }
    else
    {
      this.suStackHtInvalid=false;
      this.suStackHtInvalidReason=null;
    }
  }
}

validateSUExtLForAdd()
{
  const shipunit_ext_l = String(this.cntrdetailsadd.shpg_unit_exterior_l).split('.');
  /* if(this.cntrdetailsadd.shpg_unit_exterior_l<this.cntrdetailsadd.cntr_exterior_l)
    {
      this.suExtLInValid=true;
      this.suExtLInValidReason="Shipping Unit Exterior Length cannot be less than Container Exterior Length";
    } */
  if(!String(this.cntrdetailsadd.shpg_unit_exterior_l).includes('.'))
  {
   /*  if(this.cntrdetailsadd.shpg_unit_exterior_l==null || this.cntrdetailsadd.shpg_unit_exterior_l==0)
    {
      this.suExtLInValid=true;
      this.suExtLInValidReason="Required";
    } */
    if(this.cntrdetailsadd.shpg_unit_exterior_l<0 || this.cntrdetailsadd.shpg_unit_exterior_l>9999.999 )
    {
      this.suExtLInValid=true;
      this.suExtLInValidReason='Should be of range 0 to 9999.999';
    }
    else if( String(this.cntrdetailsadd.shpg_unit_exterior_l).length>8)
    {
      this.suExtLInValid=true;
      this.suExtLInValidReason='Max Length Of Characters can be 8';
    }
    else if(this.cntrdetailsadd.shpg_unit_exterior_l>0 && this.cntrdetailsadd.shpg_unit_exterior_l<this.cntrdetailsadd.cntr_exterior_l)
    {
      this.suExtLInValid=true;
      this.suExtLInValidReason='Shipping Unit Exterior Length cannot be less than Container Exterior Length';
    }

    else
    {
      this.suExtLInValid=false;
      this.suExtLInValidReason=null;
    }
  }
  else if(String(this.cntrdetailsadd.shpg_unit_exterior_l).includes('.'))
  {
    if(String(shipunit_ext_l[0]).length<0 || String(shipunit_ext_l[0]).length>4)
    {
      this.suExtLInValid=true;
      this.suExtLInValidReason='Should be of range 0 to 9999.999';
    }
    else if(this.cntrdetailsadd.shpg_unit_exterior_l<0 || this.cntrdetailsadd.shpg_unit_exterior_l>9999.999 )
    {
      this.suExtLInValid=true;
      this.suExtLInValidReason='Should be of range 0 to 9999.999';
    }
    else if(String(shipunit_ext_l[1]).length<0 || String(shipunit_ext_l[1]).length>3)
    {
      this.suExtLInValid=true;
      this.suExtLInValidReason='Max Length Of decimal values can be 3';
    }
    else if(this.cntrdetailsadd.shpg_unit_exterior_l>0 && this.cntrdetailsadd.shpg_unit_exterior_l<this.cntrdetailsadd.cntr_exterior_l)
    {
      this.suExtLInValid=true;
      this.suExtLInValidReason='Shipping Unit Exterior Length cannot be less than Container Exterior Length';
    }
    else
    {
      this.suExtLInValid=false;
      this.suExtLInValidReason=null;
    }
}
else
{
this.suExtLInValid=false;
this.suExtLInValidReason=null;
}
}
validateSUExtWForAdd()
{
  const shipunit_ext_w = String(this.cntrdetailsadd.shpg_unit_exterior_w).split('.');
  /* if(this.cntrdetailsadd.shpg_unit_exterior_w<this.cntrdetailsadd.cntr_exterior_w)
      {
        this.suExtWInValid=true;
        this.suExtWInValidReason="Shipping Unit Exterior Width cannot be less than Container Exterior Width";
      } */
  if(!String(this.cntrdetailsadd.shpg_unit_exterior_w).includes('.'))
  {
  /* if(this.cntrdetailsadd.shpg_unit_exterior_w==null || this.cntrdetailsadd.shpg_unit_exterior_w==0)
      {
        this.suExtWInValid=true;
        this.suExtWInValidReason="Required";
      } */
      if(this.cntrdetailsadd.shpg_unit_exterior_w<0 || this.cntrdetailsadd.shpg_unit_exterior_w>9999.999 )
      {
        this.suExtWInValid=true;
        this.suExtWInValidReason='Should be of range 0 to 9999.999';
      }
      else if( String(this.cntrdetailsadd.shpg_unit_exterior_w).length>8)
      {
        this.suExtWInValid=true;
        this.suExtWInValidReason='Max Length Of Characters can be 8';
      }
      else if(this.cntrdetailsadd.shpg_unit_exterior_w>0 && this.cntrdetailsadd.shpg_unit_exterior_w<this.cntrdetailsadd.cntr_exterior_w)
      {
        this.suExtWInValid=true;
        this.suExtWInValidReason='Shipping Unit Exterior Width cannot be less than Container Exterior Width';
      }

    else
      {
        this.suExtWInValid=false;
        this.suExtWInValidReason=null;
      }
  }
  else if(String(this.cntrdetailsadd.shpg_unit_exterior_w).includes('.'))
  {
      if(String(shipunit_ext_w[0]).length<0 || String(shipunit_ext_w[0]).length>4)
      {
        this.suExtWInValid=true;
        this.suExtWInValidReason='Should be of range 0 to 9999.999';
      }
      else if(this.cntrdetailsadd.shpg_unit_exterior_w<1 || this.cntrdetailsadd.shpg_unit_exterior_w>9999.999 )
      {
        this.suExtWInValid=true;
        this.suExtWInValidReason='Should be of range 0 to 9999.999';
      }
      else if(String(shipunit_ext_w[1]).length<0 || String(shipunit_ext_w[1]).length>3)
      {
        this.suExtWInValid=true;
        this.suExtWInValidReason='Max Length Of decimal values can be 3';
      }
      else if(this.cntrdetailsadd.shpg_unit_exterior_w>0 && this.cntrdetailsadd.shpg_unit_exterior_w<this.cntrdetailsadd.cntr_exterior_w)
      {
        this.suExtWInValid=true;
        this.suExtWInValidReason='Shipping Unit Exterior Width cannot be less than Container Exterior Width';
      }
      else
      {
        this.suExtWInValid=false;
        this.suExtWInValidReason=null;
      }
  }
  else
  {
  this.suExtWInValid=false;
  this.suExtWInValidReason=null;
  }
}

validateSUExtHForAdd()
{

    const shipunit_ext_h = String(this.cntrdetailsadd.shpg_unit_exterior_h).split('.');
    /* if(this.cntrdetailsadd.shpg_unit_exterior_h<this.cntrdetailsadd.cntr_exterior_h)
        {
          this.suExtHInValid=true;
          this.suExtHInValidReason="Shipping Unit Exterior Height cannot be less than Container Exterior Height";
        } */
    if(!String(this.cntrdetailsadd.shpg_unit_exterior_h).includes('.'))
    {
    /* if(this.cntrdetailsadd.shpg_unit_exterior_h==null || this.cntrdetailsadd.shpg_unit_exterior_h==0)
        {
          this.suExtHInValid=true;
          this.suExtHInValidReason="Required";
        } */
        if(this.cntrdetailsadd.shpg_unit_exterior_h<0 || this.cntrdetailsadd.shpg_unit_exterior_h>9999.999 )
        {
          this.suExtHInValid=true;
          this.suExtHInValidReason='Should be of range 0 to 9999.999';
        }
        else if( String(this.cntrdetailsadd.shpg_unit_exterior_h).length>8)
        {
          this.suExtHInValid=true;
          this.suExtHInValidReason='Max Length Of Characters can be 8';
        }
        else if(this.cntrdetailsadd.shpg_unit_exterior_h>0 && this.cntrdetailsadd.shpg_unit_exterior_h<this.cntrdetailsadd.cntr_exterior_h)
        {
          this.suExtHInValid=true;
          this.suExtHInValidReason='Shipping Unit Exterior Height cannot be less than Container Exterior Height';
        }


      else
        {
          this.suExtHInValid=false;
          this.suExtHInValidReason=null;
        }
    }
    else if(String(this.cntrdetailsadd.shpg_unit_exterior_h).includes('.'))
    {
        if(String(shipunit_ext_h[0]).length<0 || String(shipunit_ext_h[0]).length>4)
        {
          this.suExtHInValid=true;
          this.suExtHInValidReason='Should be of range 0 to 9999.999';
        }
        else if(this.cntrdetailsadd.shpg_unit_exterior_h<1 || this.cntrdetailsadd.shpg_unit_exterior_h>9999.999 )
        {
          this.suExtHInValid=true;
          this.suExtHInValidReason='Should be of range 0 to 9999.999';
        }
        else if(String(shipunit_ext_h[1]).length<0 || String(shipunit_ext_h[1]).length>3)
        {
          this.suExtHInValid=true;
          this.suExtHInValidReason='Max Length Of decimal values can be 3';
        }
        else if(this.cntrdetailsadd.shpg_unit_exterior_h>0 && this.cntrdetailsadd.shpg_unit_exterior_h<this.cntrdetailsadd.cntr_exterior_h)
        {
          this.suExtHInValid=true;
          this.suExtHInValidReason='Shipping Unit Exterior Height cannot be less than Container Exterior Height';
        }
        else
        {
          this.suExtHInValid=false;
          this.suExtHInValidReason=null;
        }
    }
    else
    {
    this.suExtHInValid=false;
    this.suExtHInValidReason=null;
    }
}

 /*  onSelectFromCntrAdmin()
  {
    this.selectedFromContainerAdmin=true;
    this.loadcntrdetails();
  }

  loadcntrdetails()
  {
    //alert(this.selectedFromContainerAdmin)
    console.log(this.selectedCntrFromAutoCmplete);
    this.transactionService.editbutton_get_contr_details(this.selectedCntrFromAutoCmplete.cntr_base,this.selectedCntrFromAutoCmplete.cd_cntr).subscribe(cntrdetails=>{

      this.cntrdetailsfromload.function="A";

      if(cntrdetails.cntr_suff_ind!=null && (cntrdetails.cntr_suff_ind.toLowerCase()=="yes" || cntrdetails.cntr_suff_ind.toLowerCase()=='y'))
      {
        this.cntrdetailsfromload.cntr_suff_ind="Yes";
      }
      else
      {
        this.cntrdetailsfromload.cntr_suff_ind="No";
      }

      if(cntrdetails.exp_refundable!=null && (cntrdetails.exp_refundable.toLowerCase()=="expendable" || cntrdetails.cntr_active_ind.toLowerCase()=='e'))
      {
        this.cntrdetailsfromload.exp_refundable="Expendable";
      }
      else
      {
        this.cntrdetailsfromload.exp_refundable="Returnable";
      }


      if(cntrdetails.cntr_active_ind!=null && (cntrdetails.cntr_active_ind.toLowerCase()=="yes" || cntrdetails.cntr_active_ind.toLowerCase()=='y'))
      {
        this.cntrdetailsfromload.cntr_active_ind="Yes";
      }
      else
      {
        this.cntrdetailsfromload.cntr_active_ind="No";
      }

      this.cntrdetailsfromload.cntr_base=cntrdetails.cntr_base;
      this.cntrdetailsfromload.cntr_suffix=cntrdetails.cntr_suffix;
      this.cntrdetailsfromload.cntr_desc=cntrdetails.cntr_desc;
      this.cntrdetailsfromload.card_call=cntrdetails.card_call;

      for(let i=0;i<this.cntrMasterCategory.length;i++)
      {
        if(cntrdetails.cntr_mstr_catg==this.cntrMasterCategory[i].cntr_mstr_catg)
        {
          this.cntrdetailsfromload.cntr_mstr_catg=this.cntrMasterCategory[i].cntr_mstr_catg;
        }
      }

      for(let i=0;i<this.cntrTypeCode.length;i++)
      {
        if(cntrdetails.cntr_typ_desc==String(this.cntrTypeCode[i].cd_cntr_typ))
        {
          this.cntrdetailsfromload.cntr_typ_desc=this.cntrTypeCode[i].cntr_typ_desc;
          console.log('edit_selected_cntr_type_code',this.cntrdetailsfromload.cntr_typ_desc);
        }
      }

      for(let i=0;i<this.cntrOwnershipstratcode.length;i++)
      {
        if(cntrdetails.ownrshp_strat_code==this.cntrOwnershipstratcode[i].ownrshp_strat_code)
        {
          this.cntrdetailsfromload.ownrshp_strat_code=this.cntrOwnershipstratcode[i].ownrshp_strat_code;
        }
      }

      for(let i=0;i<this.ownrship_catg.length;i++)
      {
        if(cntrdetails.ownrshp_catg==this.ownrship_catg[i].ownrshp_catg)
        {
          this.cntrdetailsfromload.ownrshp_catg=this.ownrship_catg[i].ownrshp_catg;
        }
      }

      for(let i=0;i<this.cntrGrp.length;i++)
      {
        if(cntrdetails.cntr_grp_desc==this.cntrGrp[i].cntr_grp)
        {
          this.cntrdetailsfromload.cntr_grp_desc=this.cntrGrp[i].cntr_grp;
        }
      }

      for(let i=0;i<this.cntrCurrencyList.length;i++)
      {
        if(cntrdetails.currency_desc==this.cntrCurrencyList[i].curr_desc)
        {
          this.cntrdetailsfromload.currency_desc=this.cntrCurrencyList[i].curr_desc;
        }
      }

      this.cntrdetailsfromload.cntr_exterior_l=cntrdetails.cntr_exterior_l,
      this.cntrdetailsfromload.cntr_exterior_w=cntrdetails.cntr_exterior_w,
      this.cntrdetailsfromload.cntr_exterior_h=cntrdetails.cntr_exterior_h,
      this.cntrdetailsfromload.collapsed_ht=cntrdetails.collapsed_ht,
      this.cntrdetailsfromload.nest_ratio=cntrdetails.nest_ratio,
      this.cntrdetailsfromload.cntr_interior_l=cntrdetails.cntr_interior_l,
      this.cntrdetailsfromload.cntr_interior_w=cntrdetails.cntr_interior_w,
      this.cntrdetailsfromload.cntr_interior_h=cntrdetails.cntr_interior_h,
      this.cntrdetailsfromload.cntr_tare_wt=cntrdetails.cntr_tare_wt,
      this.cntrdetailsfromload.cntr_capacity=cntrdetails.cntr_capacity,
      this.cntrdetailsfromload.cntr_per_layer=cntrdetails.cntr_per_layer,
      this.cntrdetailsfromload.max_layers_shpg_unit=cntrdetails.max_layers_shpg_unit,
      this.cntrdetailsfromload.max_cntr_per_shpg_unit=cntrdetails.max_cntr_per_shpg_unit,

      this.cntrdetailsfromload.cntr_matr_desc=cntrdetails.cntr_matr_desc,

      this.cntrdetailsfromload.cntr_drawing_no=cntrdetails.cntr_drawing_no,
      this.cntrdetailsfromload.shpg_unit_exterior_l=cntrdetails.shpg_unit_exterior_l,
      this.cntrdetailsfromload.shpg_unit_exterior_w=cntrdetails.shpg_unit_exterior_w,
      this.cntrdetailsfromload.shpg_unit_exterior_h=cntrdetails.shpg_unit_exterior_h,
      this.cntrdetailsfromload.shpg_unit_nstdorflded_ht=cntrdetails.shpg_unit_nstdorflded_ht,
      this.cntrdetailsfromload.shpg_unit_tare_wt=cntrdetails.shpg_unit_tare_wt,
      this.cntrdetailsfromload.shpg_unit_stack_ht=cntrdetails.shpg_unit_stack_ht,
      this.cntrdetailsfromload.warehouse_stack_ht=cntrdetails.warehouse_stack_ht,
      this.cntrdetailsfromload.service_life=cntrdetails.service_life,
      this.cntrdetailsfromload.currency_desc=cntrdetails.currency_desc,
      this.cntrdetailsfromload.purchase_cost=cntrdetails.purchase_cost,
      this.cntrdetailsfromload.rental_issue_cost=cntrdetails.return_rntl_cost,
      this.cntrdetailsfromload.sup_rntl_dly_cost=cntrdetails.sup_rntl_dly_cost,
      this.cntrdetailsfromload.ford_rntl_dly_cost=cntrdetails.ford_rntl_dly_cost,
      this.cntrdetailsfromload.return_rntl_cost=cntrdetails.return_rntl_cost,
      this.cntrdetailsfromload.tooling_dev_cost=cntrdetails.tooling_dev_cost,
      this.cntrdetailsfromload.extr_cntr_dsgn_img_ind=cntrdetails.extr_cntr_dsgn_img_ind,
      this.cntrdetailsfromload.intr_cntr_dsgn_img_ind=cntrdetails.intr_cntr_dsgn_img_ind,
      this.cntrdetailsfromload.shpg_unit_img_ind=cntrdetails.shpg_unit_img_ind,
      this.cntrdetailsfromload.cad_img_ind=cntrdetails.cad_img_ind,
      this.cntrdetailsfromload.img_notes=cntrdetails.img_notes,
      this.cntrdetailsfromload.cd_region=cntrdetails.cd_region,
      this.cntrdetailsfromload.iscontainercolapsble=cntrdetails.iscontainercolapsble,
      this.cntrdetailsfromload.cd_plant=cntrdetails.cd_plant,
      this.cntrdetailsfromload.cntr_qt_per_min_su=cntrdetails.cnt_qt_per_min_su,
      this.cntrdetailsfromload.nest_ratio_1=cntrdetails.nest_ratio_1,
      this.cntrdetailsfromload.ford_cntct=cntrdetails.ford_cntct,
      this.cntrdetailsfromload.cntct_phone=cntrdetails.cntct_phone,
      this.cntrdetailsfromload.id_user=cntrdetails.id_user,
      this.cntrdetailsfromload.ts_last_update=cntrdetails.ts_last_update,
      this.cntrdetailsfromload.req_stat="open",
      this.cntrdetailsfromload.components=[],
      this.cntrdetailsfromload.UoM=cntrdetails.uom

      this.cntrdetailsadd=JSON.parse(JSON.stringify(this.cntrdetailsfromload));
    })
  } */


  /*-----------------------------Component-----------------------------------------------------------*/




  validationInitComp()
  {

    this.compTareWtInValid=false;
    this.compTareWtInValidReason=null;
    this.compCapWtInValid=false;
    this.compCapWtInValidReason=null;

    this.compQtPerMinSuInValid=false;
    this.compQtPerMinSuInvalidReason=null;

    this.compExtLInValid=false;
    this.compExtLInValidReason=null;
    this.compExtWInValid=false;
    this.compExtWInValidReason=null;
    this.compExtHInValid=false;
    this.compExtHInValidReason=null;
    this.wareHourseStackHtInvaidComp=false;
    this.wareHourseStackHtInvaidReasonComp=null;


    this.compBaseInvalid=false;
    this.compBaseInvalidReason=null;
    this.compSuffixInvalid=false;
    this.compSuffixInvalidReason=null;
    this.compDescInvalid=false;
    this.compDescInvalidReason=null;
    this.plantCodeInvalidComp=false;
    this.plantCodeInvalidReasonComp=null;

    this.mastrCatgInvalidComp=false;
    this.mastrCatgInvalidReasonComp=null;
    this.retTypeInvalidComp=false;
    this.retTypeInvalidReasonComp=null;
    this.compTypeInvalid=false;
    this.compTypeInvalidReason=null;
    this.compMaterialInvalid=false;
    this.compMaterialInvalidReason=null;
    this.cosInvalidComp=false;
    this.cosInvalidReasonComp=null;
    this.compGrpInvalid=false;
    this.compGrpInvalidReason=null;

    this.compCntctInvalid=false;
    this.compCntctInvalidReason=null;
    this.cntctPhoneInvalidComp=false;
    this.cntctPhoneInvlidReasonComp=null;
    this.compActiveInvalid=false;
    this.compActiveInvalidReason=null;
  }


  compdetails_display_popup_init()
  {
    this.compdetails_display=
    {
        cd_cntr:0,
        cntr_suff_ind:'',
        exp_refundable:'',
        cntr_active_ind:'',
        cntr_base:'',
        cntr_suffix:'',
        cntr_desc:'',
        card_call:'',
        cntr_mstr_catg:'',
        cntr_typ_desc:'',
        ownrshp_strat_code:'',
        ownrshp_catg:'',
        cntr_grp_desc:'',
        cntr_exterior_l:0,
	      cntr_exterior_w:0,
	      cntr_exterior_h:0,
        collapsed_ht:0.0,
        nest_ratio:0,
        cntr_interior_l:0,
        cntr_interior_w:0,
        cntr_interior_h:0,
        cntr_tare_wt:0,
        cntr_capacity:0,
        cntr_per_layer:0,
        max_layers_shpg_unit:0,
        max_cntr_per_shpg_unit:0,
        cntr_matr_desc:'',
        cntr_drawing_no:'',
        shpg_unit_exterior_l:0,
        shpg_unit_exterior_w:0,
        shpg_unit_exterior_h:0,
        shpg_unit_nstdorflded_ht:0,
        shpg_unit_tare_wt:0,
        shpg_unit_stack_ht:0,
        warehouse_stack_ht:0,
        service_life:'',
        currency_desc:'',
        purchase_cost:0,
        rental_issue_cost:0,
        sup_rntl_dly_cost:0,
        ford_rntl_dly_cost:0,
        return_rntl_cost:0,
        tooling_dev_cost:0,
        extr_cntr_dsgn_img_ind:'',
        intr_cntr_dsgn_img_ind:'',
        shpg_unit_img_ind:'',
        cad_img_ind:'',
        img_notes:'',
        cd_region:'',
        iscontainercolapsble:'',
        cd_plant: '',
        cnt_qt_per_min_su: 0,
        nest_ratio_1: 0,
        ford_cntct: '',
        cntct_phone: '',
        req_stat: '',
        id_user: '',
        ts_last_update: '',
        uom:'E',
        typ_no:0
      }
  }
  copyCompDetails()
  {
    this.select_contcos_dialog_comp=true;

    if(this.componentDetailsCopied)
    {
      this.comp_retype=this.tableSelectedReturnTypeComp;
      for(const i of this.cntrOwnershipstratcodelist)
      {
        if(i.ownrshp_strat_code==this.table_selected_comp_ownrship_strat_code_in_popup.ownrshp_strat_code)
        {
          this.selected_comp_ownrship_strat_code_in_popup=this.table_selected_comp_ownrship_strat_code_in_popup;
        }
      }

    }
    else
    {
      this.comp_retype='Returnable';
      this.selected_comp_ownrship_strat_code_in_popup=this.cntrOwnershipstratcodelist[0];
      this.selected_component=null;
    }

    this.loadComponentsInPopUp();

  }

  onclick_ok_select_contandcos_comp()
  {
    this.select_contcos_dialog_comp=false;

    this.componentDetailsCopied=true;
    this.isCmmsLoadedContainer=false;
    this.isCmmsLoadedComponent=false;

    this.table_selected_component=this.selected_component;
    this.tableSelectedReturnTypeComp=this.comp_retype;
    this.table_selected_comp_ownrship_strat_code_in_popup=this.selected_comp_ownrship_strat_code_in_popup;

    this.transactionService.editbutton_get_contr_details(this.table_selected_component.cntr_base,this.table_selected_component.cd_cntr).subscribe(cntval=>{
      this.compDetailsForCopying=cntval;

      this.compdetailsadd.cntr_base='';
      this.compdetailsadd.cntr_suffix='';
      this.compdetailsadd.cntr_suff_ind='';
      this.compSuffixAdd='';
      this.compSuffixEnabled=false;
      this.disableCompDetailsWhenBaseOrSuffixChanged=true;
      this.prevCompSuffixInd=null;
      this.enteredCompBase=null;
      this.componentDetailsInit();

      // this.compdetailsadd.function:'',
      // this.compdetailsadd.cntr_suff_ind=this.compDetailsForCopying.,
      // this.compdetailsadd.exp_refundable=this.compDetailsForCopying.exp_refundable;
      this.compdetailsadd.cntr_active_ind=this.compDetailsForCopying.cntr_active_ind;
      // this.compdetailsadd.cntr_base=this.compDetailsForCopying.,
      // this.compdetailsadd.cntr_suffix=this.compDetailsForCopying.,
      // this.compdetailsadd.cntr_desc=this.compDetailsForCopying.
      this.compdetailsadd.card_call=this.compDetailsForCopying.card_call;
      if(this.compDetailsForCopying.cntr_mstr_catg!=null && this.compDetailsForCopying.cntr_mstr_catg!='' && this.cntrMasterCategory!=null && this.cntrMasterCategory.length!=0)
      {
        for(const mc of this.cntrMasterCategory)
        {
          if(mc.cntr_mstr_catg.toLowerCase()==this.compDetailsForCopying.cntr_mstr_catg.toLowerCase())
          {
            this.compdetailsadd.cntr_mstr_catg=this.compDetailsForCopying.cntr_mstr_catg;
            this.selected_comp_master_category=mc;
            break;
          }
        }
      }

      if(this.ownrship_catg!=null && this.ownrship_catg.length!=0)
      {

        this.selected_ownrship_catg_comp=this.ownrship_catg[1];
        this.compdetailsadd.ownrshp_catg='Supp';
      }

      if(this.cntrTypeCode!=null && this.cntrTypeCode.length!=0)
      {
        this.selected_comp_type_code=this.cntrTypeCodeComp[0];
        this.compdetailsadd.cntr_typ_desc=this.selected_comp_type_code.cntr_typ_desc;
      }

      // this.compdetailsadd.cntr_typ_desc=this.compDetailsForCopying.
      // this.compdetailsadd.ownrshp_strat_code=this.compDetailsForCopying.
      // this.compdetailsadd.ownrshp_catg=this.compDetailsForCopying.
      if(this.compDetailsForCopying.cntr_grp_desc!=null && this.compDetailsForCopying.cntr_grp_desc!='' && this.cntrGrp!=null && this.cntrGrp.length!=0)
      {
        for(const cg of this.cntrGrp)
        {
          if(cg.cntr_grp.toLowerCase()==this.compDetailsForCopying.cntr_grp_desc.toLowerCase())
          {
            this.compdetailsadd.cntr_grp_desc=this.compDetailsForCopying.cntr_grp_desc;
            this.selected_comp_group=cg;
            break;
          }
        }
      }

      this.compdetailsadd.cntr_exterior_l=this.compDetailsForCopying.cntr_exterior_l
      this.compdetailsadd.cntr_exterior_w=this.compDetailsForCopying.cntr_exterior_w
      this.compdetailsadd.cntr_exterior_h=this.compDetailsForCopying.cntr_exterior_h
      this.compdetailsadd.collapsed_ht=this.compDetailsForCopying.collapsed_ht
      this.compdetailsadd.nest_ratio=this.compDetailsForCopying.nest_ratio
      this.compdetailsadd.cntr_interior_l=this.compDetailsForCopying.cntr_interior_l
      this.compdetailsadd.cntr_interior_w=this.compDetailsForCopying.cntr_interior_w
      this.compdetailsadd.cntr_interior_h=this.compDetailsForCopying.cntr_interior_h
      this.compdetailsadd.cntr_tare_wt=this.compDetailsForCopying.cntr_tare_wt
      this.compdetailsadd.cntr_capacity=this.compDetailsForCopying.cntr_capacity
      this.compdetailsadd.cntr_per_layer=this.compDetailsForCopying.cntr_per_layer
      this.compdetailsadd.max_layers_shpg_unit=this.compDetailsForCopying.max_layers_shpg_unit
      this.compdetailsadd.max_cntr_per_shpg_unit=this.compDetailsForCopying.max_cntr_per_shpg_unit
      // this.compdetailsadd.cntr_matr_desc=this.compDetailsForCopying.
      this.compdetailsadd.cntr_drawing_no=this.compDetailsForCopying.cntr_drawing_no
      this.compdetailsadd.shpg_unit_exterior_l=this.compDetailsForCopying.shpg_unit_exterior_l
      this.compdetailsadd.shpg_unit_exterior_w=this.compDetailsForCopying.shpg_unit_exterior_w
      this.compdetailsadd.shpg_unit_exterior_h=this.compDetailsForCopying.shpg_unit_exterior_h
      this.compdetailsadd.shpg_unit_nstdorflded_ht=this.compDetailsForCopying.shpg_unit_nstdorflded_ht,
      this.compdetailsadd.shpg_unit_tare_wt=this.compDetailsForCopying.shpg_unit_tare_wt
      this.compdetailsadd.shpg_unit_stack_ht=this.compDetailsForCopying.shpg_unit_stack_ht
      this.compdetailsadd.warehouse_stack_ht=this.compDetailsForCopying.warehouse_stack_ht
      this.compdetailsadd.service_life=this.compDetailsForCopying.service_life
      this.compdetailsadd.currency_desc=this.compDetailsForCopying.currency_desc
      this.compdetailsadd.purchase_cost=this.compDetailsForCopying.purchase_cost
      this.compdetailsadd.rental_issue_cost=this.compDetailsForCopying.rental_issue_cost
      this.compdetailsadd.sup_rntl_dly_cost=this.compDetailsForCopying.sup_rntl_dly_cost
      this.compdetailsadd.ford_rntl_dly_cost=this.compDetailsForCopying.ford_rntl_dly_cost
      this.compdetailsadd.return_rntl_cost=this.compDetailsForCopying.return_rntl_cost
      this.compdetailsadd.tooling_dev_cost=this.compDetailsForCopying.tooling_dev_cost
      this.compdetailsadd.extr_cntr_dsgn_img_ind=this.compDetailsForCopying.extr_cntr_dsgn_img_ind
      this.compdetailsadd.intr_cntr_dsgn_img_ind=this.compDetailsForCopying.intr_cntr_dsgn_img_ind
      this.compdetailsadd.shpg_unit_img_ind=this.compDetailsForCopying.shpg_unit_img_ind
      this.compdetailsadd.cad_img_ind=this.compDetailsForCopying.cad_img_ind,
      this.compdetailsadd.img_notes=this.compDetailsForCopying.img_notes
      this.compdetailsadd.cd_region=this.currentRegionCodeSub;
      this.compdetailsadd.iscontainercolapsble=this.compDetailsForCopying.iscontainercolapsble;
      if(this.compDetailsForCopying.cd_plant!=null && this.compDetailsForCopying.cd_plant!='' && this.plantList!=null && this.plantList.length!=0 )
      {
        for(const pc of this.plantList)
        {
          if(pc.plantGSDBCode.toLowerCase()==this.compDetailsForCopying.cd_plant.toLowerCase())
          {
            this.compdetailsadd.cd_plant=this.compDetailsForCopying.cd_plant;
            this.selectedPlantListComp=pc;
            break;
          }
        }
      }

      this.compdetailsadd.cntr_qt_per_min_su=this.compDetailsForCopying.cnt_qt_per_min_su
      this.compdetailsadd.nest_ratio_1=this.compDetailsForCopying.nest_ratio_1
      this.compdetailsadd.ford_cntct=null;
      // this.compdetailsadd.cntct_phone=this.compDetailsForCopying.
      this.compdetailsadd.id_user=this.userCode
      this.compdetailsadd.ts_last_update=this.date.transform(new Date(),'yyyy-MM-dd');
      this.compdetailsadd.req_stat='open';
      this.compdetailsadd.components=[{
        mp_function:'',
        no_comp_base:'',
        no_comp_suffix:'',
        ds_comp:'',
        comp_qt_per_min_su:0,
        comp_cd_owner:'',
        in_comp:'',
        comp_cd_type:''
      }],
      // this.compdetailsadd.UoM='E';
      this.compdetailsadd.dbia_req_no=0;

      console.log('component details from container admin on pop up',this.compDetailsForCopying);

      this.checkCompValidate();

      if(this.compdetailsadd.cntr_base!=null && this.compdetailsadd.cntr_suff_ind!=null && this.compdetailsadd.cntr_base.trim()!='' && this.compdetailsadd.cntr_suff_ind!='')
      {
        this.compsuffixchangedadd();
      }
    })
  }

  onclick_cancel_select_contandcos_comp()
  {
    this.select_contcos_dialog_comp=false;
    this.selected_component=null;
    this.comp_retype='Returnable';
    this.selected_comp_ownrship_strat_code_in_popup=this.cntrOwnershipstratcodelist[0];
  }

  on_change_cos_dropdown_comp()
  {
    this.selected_component=null;
    this.loadComponentsInPopUp();
  }

  onChangeExpRetComp()
  {
    this.selected_component=null;
    this.loadComponentsInPopUp();
  }

  loadComponentsInPopUp()
  {
    console.log(this.componentDetailsCopied)

    this.transactionService.getcomponcoslist(this.selected_comp_ownrship_strat_code_in_popup.ownrshp_strat_code,this.currentRegionCodeSub,this.comp_retype,this.userCode).subscribe(stdcntrs=>{
      this.contlist_oncos_comp=stdcntrs;

      console.log('successful',this.contlist_oncos_comp);

      if(this.contlist_oncos_comp.length!=0 && this.contlist_oncos_comp!=null)
      {
        this.comp_grid_text='';
        this.comp_present_table=true;
        for(let i=0; i<this.contlist_oncos_comp.length; i++)
        {

          if(this.contlist_oncos_comp[i].cntr_suffix!=null)
          {
          this.contlist_oncos_comp[i].cntr_base_suffix=this.contlist_oncos_comp[i].cntr_base+' - '+this.contlist_oncos_comp[i].cntr_suffix
          }
          else
          {
            this.contlist_oncos_comp[i].cntr_base_suffix=this.contlist_oncos_comp[i].cntr_base
          }

          let length='';
          let width='';
          let height='';
          if(this.contlist_oncos_comp[i].cntr_exterior_l!=null)
          {
            length=this.contlist_oncos_comp[i].cntr_exterior_l.toString();
          }
          if(this.contlist_oncos_comp[i].cntr_exterior_w!=null)
          {
            width=this.contlist_oncos_comp[i].cntr_exterior_w.toString();
          }
          if(this.contlist_oncos_comp[i].cntr_exterior_l!=null)
          {
            height=this.contlist_oncos_comp[i].cntr_exterior_h.toString();
          }

          this.contlist_oncos_comp[i].external_l_w_h=length+'x'+width+'x'+height;

          console.log(this.componentDetailsCopied)

        }

        if(this.componentDetailsCopied && this.table_selected_comp_ownrship_strat_code_in_popup==this.selected_comp_ownrship_strat_code_in_popup && this.tableSelectedReturnTypeComp==this.comp_retype)
        {
          this.selected_component=this.table_selected_component;
        }

        this.comp_cols = [
          { field: 'cntr_base_suffix', header:'Component Base - Suffix'},
          {field:'cntr_desc',header:'Component Description'},
          { field: 'exp_refundable', header:'E/R'},
          { field: 'external_l_w_h', header:'External LxWxH'},
          { field: 'card_call', header:'Card or Call'},
          { field: 'cntr_grp_desc', header:'Component Group ID'},

        ];
      }
      else
      {
        this.comp_present_table=false;
        this.comp_cols=[];
        this.comp_grid_text='No Component Found'
      }

    })
  }

  on_click_compdetails(compdata:any)
  {
    this.enabledetails_comp=true;
    // this.get_measure="lbs/inch";

    console.log(compdata);

    this.transactionService.editbutton_get_contr_details(compdata.cntr_base,compdata.cd_cntr).subscribe(cntval=>{
      this.compdetails_display=cntval;
      if(this.compdetails_display.uom!=null && this.compdetails_display.uom.toLowerCase()=='e')
      {
        this.get_measure='lbs/inch';
      }
      if(this.compdetails_display.uom!=null && this.compdetails_display.uom.toLowerCase()=='m')
      {
        this.get_measure='kg/cm';
      }
      else
      {
        this.get_measure=this.add_measure;// if value is null assigning region admin value
      }
      console.log('component details from container admin on pop up',this.compdetails_display)
    })


  }


  compBaseChanged()
  {
    // API call to check whether the container exist in CMMS , if yes make request_status as "c" else make it as 'A'
    // disable all the field until api returns output


    if(this.enteredCompBase!=null)
    {
      if(this.enteredCompBase.trim()!=this.compdetailsadd.cntr_base.trim())
        {
          this.enteredCompBase=this.compdetailsadd.cntr_base;

          if(this.componentDetailsCopied==false)
          {
            this.disableCompDetailsWhenBaseOrSuffixChanged=true;
            // this.clearCompDetailsExceptBaseAndSuffixAndPlantCode();
          }

          if(this.compdetailsadd.cntr_suff_ind.toLowerCase()=='no')
          {
            this.compSuffixAdd=''
            this.compdetailsadd.cntr_suffix='%20%20%20%20%20%20%20%20';
          }

          if(this.compdetailsadd.cntr_base!=null && this.compdetailsadd.cntr_base.trim()!='' && (this.compdetailsadd.cntr_suff_ind.toLowerCase()=='yes' || this.compdetailsadd.cntr_suff_ind.toLowerCase()=='no'))
          {
            this.filterByCompBaseAndSuffix()
           // this.cntrsuffixchangedadd()
          }
      }

    }
    else  // This case will be exceuted when user enters base for the first time
    {
      this.enteredCompBase=this.compdetailsadd.cntr_base;
      this.disableCompDetailsWhenBaseOrSuffixChanged=true;

      if(this.componentDetailsCopied==false)
      {
        // this.clearCompDetailsExceptBaseAndSuffixAndPlantCode();
      }
      if(this.compdetailsadd.cntr_suff_ind.toLowerCase()=='no')
      {
        this.compSuffixAdd=''
        this.compdetailsadd.cntr_suffix='%20%20%20%20%20%20%20%20';
      }

      if(this.compdetailsadd.cntr_base!=null && this.compdetailsadd.cntr_base.trim()!='' && (this.compdetailsadd.cntr_suff_ind.toLowerCase()=='yes' || this.compdetailsadd.cntr_suff_ind.toLowerCase()=='no'))
      {
        this.filterByCompBaseAndSuffix()
       // this.cntrsuffixchangedadd()
      }
    }


  }

  compsuffixchangedadd()
  {
    if(this.prevCompSuffixInd!=null && this.prevCompSuffixInd!=this.compdetailsadd.cntr_suff_ind)
    {
      if(this.compdetailsadd.cntr_suff_ind=='No')
      {
       this.compSuffixEnabled=true;
        this.compSuffixAdd='';
        this.compdetailsadd.cntr_suffix='%20%20%20%20%20%20%20%20';
        console.log(this.compSuffixEnabled);
      }
      else
      {
        if(this.userCode!=null && this.userCode!='')
        {
          this.compSuffixAdd=this.userCode;
          this.compdetailsadd.cntr_suffix=this.userCode;
          console.log('checking',this.compdetailsadd.cntr_suffix);
          this.compSuffixEnabled=true;
        }

      }

      this.prevCompSuffixInd=this.compdetailsadd.cntr_suff_ind;
      if(this.componentDetailsCopied==false)
      {
        this.disableCompDetailsWhenBaseOrSuffixChanged=true;
        // this.clearCompDetailsExceptBaseAndSuffixAndPlantCode();
      }

      if(this.compdetailsadd.cntr_base!=null && this.compdetailsadd.cntr_base.trim()!='')
      {
        this.filterByCompBaseAndSuffix();
      }
    }
    else if(this.prevCompSuffixInd==null)  // executed when user selects suffix for first time
    {
      if(this.compdetailsadd.cntr_suff_ind=='No')
      {
       this.compSuffixEnabled=true;
        this.compSuffixAdd='';
        this.compdetailsadd.cntr_suffix='%20%20%20%20%20%20%20%20';
        console.log(this.compSuffixEnabled);
      }
      else
      {
        if(this.userCode!=null && this.userCode!='')
        {
          this.compSuffixAdd=this.userCode;
          this.compdetailsadd.cntr_suffix=this.userCode;
          console.log('checking',this.compdetailsadd.cntr_suffix);
          this.compSuffixEnabled=true;
        }

      }
      this.disableCompDetailsWhenBaseOrSuffixChanged=true;

      if(this.componentDetailsCopied==false)
      {
        // this.clearCompDetailsExceptBaseAndSuffixAndPlantCode();
      }
      this.prevCompSuffixInd=this.compdetailsadd.cntr_suff_ind;

      if(this.compdetailsadd.cntr_base!=null && this.compdetailsadd.cntr_base.trim()!='')
      {
        this.filterByCompBaseAndSuffix();
      }
    }

  }

  onChangeRetTypeAddComp()
  {
    let ret_type='';
    this.selected_comp_material={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
    if(this.compdetailsadd.exp_refundable != '')
    {
      if(this.selected_comp_type_code != null && this.selected_comp_type_code.cntr_typ_desc != '')
      {
        if(this.compdetailsadd.exp_refundable.toLowerCase() =='returnable')
        {
          ret_type= 'R';
        }
        else
        {
          ret_type= 'E';
        }
        this.transactionService.getContainerMaterial(this.selected_comp_type_code.typ_no,ret_type).subscribe(mat_list=>{
          this.cntrMaterial=[];
          this.cntrMaterial=mat_list;
        })
        this.enable_material_dropdown_comp=true;
      }
   }
  }


onChangeCntrTypAddComp()
{
  let ret_type='';
  console.log(this.selected_comp_type_code,this.compdetailsadd.exp_refundable)
  this.selected_comp_material={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
  if(this.selected_comp_type_code != null && this.selected_comp_type_code.cntr_typ_desc != '')
  {
    if(this.compdetailsadd.exp_refundable != '')
    {


    if(this.compdetailsadd.exp_refundable.toLowerCase() == 'returnable')
    {
      ret_type= 'R';

    }
    else
    {
      ret_type= 'E';
    }


    this.transactionService.getContainerMaterial(this.selected_comp_type_code.typ_no,ret_type).subscribe(mat_list=>{

      this.cntrMaterial=[];
      this.cntrMaterial=mat_list;

    })


    // api to get material list
    this.enable_material_dropdown_comp=true;
  }
}


}

  filterByCompBaseAndSuffix()
  {

   /*  this.selectedFromContainerAdmin=false;
    this.clearCntrRequestDetails(); */
    console.log('filterByCompBaseAndSuffix')
    this.transactionService.getContainerDetailsFromCMMS(this.compdetailsadd.cntr_base.toUpperCase(),this.compdetailsadd.cntr_suffix).subscribe(cntrdetails=>{

      const cntrDetailsFromAPI:cntrDetailsFromCMMS=cntrdetails;
      this.compdetailsfromload=cntrdetails;
      console.log(cntrdetails)




      if(cntrDetailsFromAPI!=null)
      {

        /* Removing this condition because even when the container is copied
          and user goes out of focus container details present in cmms should be loaded
          so emptying the copying container variables if exists

        if(this.componentDetailsCopied==false)
        {*/

          this.clearCompDetailsExceptBaseAndSuffixAndPlantCode();
          this.componentDetailsCopied=false;
          this.compdetailsadd.function='C';
          this.isCmmsLoadedComponent=true;
          this.isCmmsLoadedContainer=false;

          /*code Added */
          this.selected_component=null;
          this.comp_retype='Returnable';
          this.compdetails_display_popup_init();
          this.selected_comp_ownrship_strat_code_in_popup=this.cntrOwnershipstratcodelist[0];
          /*code Added */

        this.compdetailsadd.cntr_base=this.compdetailsfromload.no_cntr_base.trim();
        this.compdetailsadd.cntr_suffix=this.compdetailsfromload.no_cntr_suffix.trim();

        if(this.compdetailsfromload.ds_cntr!=null)
        {
          this.compdetailsadd.cntr_desc=this.compdetailsfromload.ds_cntr.trim();
        }


        this.selected_ownrship_catg_comp=this.ownrship_catg[1];
        // Sending Ownership category "Supp" by default
        this.compdetailsadd.ownrshp_catg='Supp';
        // this.cntrdetailsadd.ownrshp_catg=this.ownrship_catg[1].ownrshp_catg;


        this.compdetailsadd.cntr_exterior_l=this.compdetailsfromload.ms_cntr_lt;
        this.compdetailsadd.cntr_exterior_w=this.compdetailsfromload.ms_cntr_wd;
        this.compdetailsadd.cntr_exterior_h=this.compdetailsfromload.ms_cntr_ht;

        /*Not used for comp , but values are simply assigned, while requesting changed back to standard values */

        if(this.compdetailsfromload.ms_cntr_clpsd_ht!=null && this.compdetailsfromload.ms_cntr_clpsd_ht>0)
        {
          this.compdetailsadd.iscontainercolapsble='Y';
          this.compdetailsadd.collapsed_ht=this.compdetailsfromload.ms_cntr_clpsd_ht;
        }
        else
        {
          this.compdetailsadd.iscontainercolapsble='N';
        }

        this.compdetailsadd.nest_ratio=this.compdetailsfromload.no_nesting_ratio1,
        this.compdetailsadd.nest_ratio_1=this.compdetailsfromload.no_nesting_ratio2
        this.compdetailsadd.cntr_active_ind="Yes";

        if(this.compdetailsfromload.na_ford_cntct!=null && this.compdetailsfromload.na_ford_cntct.trim()!='')
        {
        this.compdetailsadd.ford_cntct=this.compdetailsfromload.na_ford_cntct.trim();
        }

        /*Not used for comp , but values are simply assigned, while requesting changed back to standard values */

        this.compdetailsadd.cntr_tare_wt=this.compdetailsfromload.ms_cntr_tare_wt,
        this.compdetailsadd.cntr_capacity=this.compdetailsfromload.ms_max_cap_wt,
        this.compdetailsadd.cntr_qt_per_min_su=this.compdetailsfromload.qt_per_min_su;
        this.compdetailsadd.warehouse_stack_ht=this.compdetailsfromload.qt_whse_stack_ht;
        this.compdetailsadd.shpg_unit_stack_ht=this.compdetailsfromload.qt_cntr_stack_ht;

        if(this.compdetailsfromload.no_cntct_phone_1!=null && this.compdetailsfromload.no_cntct_phone_1.trim()!='')
        {
        this.compdetailsadd.cntct_phone=this.compdetailsfromload.no_cntct_phone_1.trim();
        }
        if(this.compdetailsfromload.ds_cntr_note!=null && this.compdetailsfromload.ds_cntr_note.trim()!='')
        {
        this.compdetailsadd.img_notes=this.compdetailsfromload.ds_cntr_note.trim();
        }

        // this.selected_comp_type_code=this.cntrTypeCodeComp[0];
        // this.compdetailsadd.cntr_typ_desc=this.selected_comp_type_code.cntr_typ_desc;



        // }

        console.log('Inside out',this.isCmmsLoadedComponent,this.add_measure)
        if(this.isCmmsLoadedComponent == true)
        {
           //  If Data is loaded from the CMMS, and if the measure is in kg/cm or (m) then the data is converted into cm.
      if(this.add_measure=='kg/cm')
      {
          console.log('inside kg')

          if(this.compdetailsadd.cntr_tare_wt!=null)
          {
                  this.compdetailsadd.cntr_tare_wt=Math.round((0.453592*this.compdetailsadd.cntr_tare_wt)*1000)/1000;
          }
          if(this.compdetailsadd.cntr_capacity!=null)
          {
                  this.compdetailsadd.cntr_capacity=Math.round((0.453592*this.compdetailsadd.cntr_capacity)*1000)/1000;
          }
          if(this.compdetailsadd.cntr_exterior_l!=null)
          {
                  this.compdetailsadd.cntr_exterior_l=Math.round((2.54*this.compdetailsadd.cntr_exterior_l)*1000)/1000;
          }
          if(this.compdetailsadd.cntr_exterior_w!=null)
          {
                  this.compdetailsadd.cntr_exterior_w=Math.round((2.54*this.compdetailsadd.cntr_exterior_w)*1000)/1000;
          }
          if(this.compdetailsadd.cntr_exterior_h!=null)
          {
                  this.compdetailsadd.cntr_exterior_h=Math.round((2.54*this.compdetailsadd.cntr_exterior_h)*1000)/1000;
          }
  
         /*
          if(this.compdetailsadd.warehouse_stack_ht!=null)
          {
                  this.compdetailsadd.warehouse_stack_ht=Math.round((2.54*this.compdetailsadd.warehouse_stack_ht)*1000)/1000;
          }
          */
          this.prev_add_measure='kg/cm';
          if(this.disableCompDetailsWhenBaseOrSuffixChanged==false)
          {
            this.validateCompTareWeightForAdd();
            this.validateCompCapWtForAdd();
            this.validateCompExtLForAdd();
            this.validateCompExtWForAdd();
            this.validateCompExtHForAdd();
            this.validateWareHourseStckHtForAddComp();

          }
      }

        }


      this.checkCompValidate();
      this.disableCompDetailsWhenBaseOrSuffixChanged=false;
      }
      else
      {
        this.isCmmsLoadedContainer=false;
        this.isCmmsLoadedComponent=false;

        this.compdetailsadd.cntr_active_ind="Yes";

        if(this.componentDetailsCopied==false)
        {
        this.selected_ownrship_catg_comp=this.ownrship_catg[1];
        // Sending Ownership category "Supp" by default
        this.compdetailsadd.ownrshp_catg='Supp';
        // this.cntrdetailsadd.ownrshp_catg=this.ownrship_catg[1].ownrshp_catg;
        }
        this.compdetailsadd.function='A';

        this.disableCompDetailsWhenBaseOrSuffixChanged=false;
        this.validationInitComp();
      }
      console.log(this.compdetailsadd)
    })

  }

  checkCompValidate() // to again check for valiadtion if submit is pressed before sopy container/data load from cmms
  {
    if(this.compTareWtInValid )
    {
      this.validateCompTareWeightForAdd()
    }
    if(this.compCapWtInValid )
    {
      this.validateCompCapWtForAdd()
    }
    if(this.compQtPerMinSuInValid )
    {
      this.validateCompQtPerMinSuForAdd()
    }
    if(this.compExtLInValid )
    {
      this.validateCompExtLForAdd()
    }
    if(this.compExtWInValid )
    {
      this.validateCompExtWForAdd()
    }
    if(this.compExtHInValid )
    {
      this.validateCompExtHForAdd()
    }
    if(this.wareHourseStackHtInvaidComp )
    {
      this.validateWareHourseStckHtForAddComp()
    }
    if(this.compBaseInvalid )
    {
      this.validateCompBaseForAdd()
    }
    if(this.compDescInvalid )
    {
      this.validateCompDescForAdd()
    }
   /*  if(this.plantCodeInvalidComp )
    {
      this.validatePlantCodeForAddComp()
    }  */
    if(this.mastrCatgInvalidComp )
    {
      this.validateMastCatgForAddComp()
    }
    if(this.retTypeInvalidComp )
    {
      this.validateCompRetTypeForAdd()
    }
    if(this.compTypeInvalid )
    {
      this.validateCntrTypeCodeForAddComp()
    }
    if(this.compMaterialInvalid )
    {
      this.validateCompMaterialForAdd()
    } 
    /*if(this.cosInvalidComp ) 
    {
      this.validateCOSForAddComp()
    } */
    if(this.compActiveInvalid) 
    {
      this.validateCompActive()
    }
    if(this.cntctPhoneInvalidComp )
    {
      this.validateCompContactPhone()
    }
    if(this.compSuffixInvalid)
    {
      this.validateCompSuffixForAdd()
    }
  }

  clearCompDetailsExceptBaseAndSuffixAndPlantCode()
  {
    // this.selectedPlantListComp={cd_plant:"",plantGSDBCode:"",plant_active_stat:"",plant_name:"",plant_type_name:""};

    this.selected_comp_master_category={cd_cntr_mstr_catg:0,cntr_mstr_catg_desc:'',updated_by:'',updated_dt:'',cntr_mstr_catg:'',active_stat:'',active_stat_display:false};

    this.selected_comp_type_code={cd_cntr_typ:'',cntr_typ_desc:'',typ_no:0};

    this.selected_comp_ownrship_strat_code={cd_region:'',ownrshp_strat_code:'',ownrshp_desc:'',cd_ownrshp_strat:0};

    this.selected_comp_group={cntr_grp:''};

    this.selected_comp_material={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
    this.enable_material_dropdown_comp=false;

    this.selected_comp_currency_list={cd_curr:0,curr_desc:''};

    this.selected_ownrship_catg_comp={ownrshp_catg:''};

    this.compdetailsadd=
    {
        // cd_cntr:0,
        function:'',
        cntr_suff_ind:this.compdetailsadd.cntr_suff_ind,
        exp_refundable:'',
        cntr_active_ind:'',
        cntr_base:this.compdetailsadd.cntr_base,
        cntr_suffix:this.compdetailsadd.cntr_suffix,
        cntr_desc:'',
        card_call:'',
        cntr_mstr_catg:'',
        cntr_typ_desc:'',
        ownrshp_strat_code:'',
        ownrshp_catg:'',
        cntr_grp_desc:'',
        cntr_exterior_l:0,
        cntr_exterior_w:0,
        cntr_exterior_h:0,
        collapsed_ht:1,
        nest_ratio:1,
        cntr_interior_l:0,
        cntr_interior_w:0,
        cntr_interior_h:0,
        cntr_tare_wt:0,
        cntr_capacity:0,
        cntr_per_layer:0,
        max_layers_shpg_unit:0,
        max_cntr_per_shpg_unit:0,
        cntr_matr_desc:'',
        cntr_drawing_no:'',
        shpg_unit_exterior_l:0,
        shpg_unit_exterior_w:0,
        shpg_unit_exterior_h:0,
        shpg_unit_nstdorflded_ht:0,
        shpg_unit_tare_wt:0,
        shpg_unit_stack_ht:1,
        warehouse_stack_ht:0,
        service_life:'',
        currency_desc:'',
        purchase_cost:0,
        rental_issue_cost:0,
        sup_rntl_dly_cost:0,
        ford_rntl_dly_cost:0,
        return_rntl_cost:0,
        tooling_dev_cost:0,
        extr_cntr_dsgn_img_ind:'No',
        intr_cntr_dsgn_img_ind:'No',
        shpg_unit_img_ind:'No',
        cad_img_ind:'No',
        img_notes:'',
        cd_region:'',
        iscontainercolapsble:'',
        cd_plant:this.compdetailsadd.cd_plant,
        cntr_qt_per_min_su:0,
        nest_ratio_1:1,
        ford_cntct:'',
        cntct_phone:'',
        id_user:'',
        ts_last_update:'',
        req_stat:'',
        components:[{
            mp_function:'',
            no_comp_base:'',
            no_comp_suffix:'',
            ds_comp:'',
            comp_qt_per_min_su:0,
            comp_cd_owner:'',
            in_comp:'',
            comp_cd_type:''
        }],
        UoM:'E',
        dbia_req_no:0,
        typ_no:0
    }
  }


  componentDetailsInit()
  {
    this.selectedPlantListComp={cd_plant:'',plantGSDBCode:'',plant_active_stat:'',plant_name:'',plant_type_name:''};

    this.selected_comp_master_category={cd_cntr_mstr_catg:0,cntr_mstr_catg_desc:'',updated_by:'',updated_dt:'',cntr_mstr_catg:'',active_stat:'',active_stat_display:false};

    this.selected_comp_type_code={cd_cntr_typ:'',cntr_typ_desc:'',typ_no:0};

    this.selected_comp_ownrship_strat_code={cd_region:'',ownrshp_strat_code:'',ownrshp_desc:'',cd_ownrshp_strat:0};

    this.selected_comp_group={cntr_grp:''};

    this.selected_comp_material={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
    this.enable_material_dropdown_comp=false;

    this.selected_comp_currency_list={cd_curr:0,curr_desc:''};

    this.selected_ownrship_catg_comp={ownrshp_catg:''};

    this.compdetailsadd=
    {
        // cd_cntr:0,
        function:'',
        cntr_suff_ind:'',
        exp_refundable:'',
        cntr_active_ind:'',
        cntr_base:'',
        cntr_suffix:'',
        cntr_desc:'',
        card_call:'',
        cntr_mstr_catg:'',
        cntr_typ_desc:'',
        ownrshp_strat_code:'',
        ownrshp_catg:'',
        cntr_grp_desc:'',
        cntr_exterior_l:0,
        cntr_exterior_w:0,
        cntr_exterior_h:0,
        collapsed_ht:1,
        nest_ratio:1,
        cntr_interior_l:0,
        cntr_interior_w:0,
        cntr_interior_h:0,
        cntr_tare_wt:0,
        cntr_capacity:0,
        cntr_per_layer:0,
        max_layers_shpg_unit:0,
        max_cntr_per_shpg_unit:0,
        cntr_matr_desc:'',
        cntr_drawing_no:'',
        shpg_unit_exterior_l:0,
        shpg_unit_exterior_w:0,
        shpg_unit_exterior_h:0,
        shpg_unit_nstdorflded_ht:0,
        shpg_unit_tare_wt:0,
        shpg_unit_stack_ht:1,
        warehouse_stack_ht:0,
        service_life:'',
        currency_desc:'',
        purchase_cost:0,
        rental_issue_cost:0,
        sup_rntl_dly_cost:0,
        ford_rntl_dly_cost:0,
        return_rntl_cost:0,
        tooling_dev_cost:0,
        extr_cntr_dsgn_img_ind:'No',
        intr_cntr_dsgn_img_ind:'No',
        shpg_unit_img_ind:'No',
        cad_img_ind:'No',
        img_notes:'',
        cd_region:'',
        iscontainercolapsble:'',
        cd_plant:'',
        cntr_qt_per_min_su:0,
        nest_ratio_1:1,
        ford_cntct:'',
        cntct_phone:'',
        id_user:'',
        ts_last_update:'',
        req_stat:'',
        components:[{
            mp_function:'',
            no_comp_base:'',
            no_comp_suffix:'',
            ds_comp:'',
            comp_qt_per_min_su:0,
            comp_cd_owner:'',
            in_comp:'',
            comp_cd_type:''
        }],
        UoM:'E',
        dbia_req_no:0,
        typ_no:0
    }
  }



  addComponentRequest()
  {
    this.compdetailsadd.cntr_base=this.compdetailsadd.cntr_base.toUpperCase();
    this.compdetailsadd.cd_region=this.currentRegionCodeSub;
    this.compdetailsadd.extr_cntr_dsgn_img_ind='No';
    this.compdetailsadd.intr_cntr_dsgn_img_ind='No';
    this.compdetailsadd.shpg_unit_img_ind='No';
    this.compdetailsadd.cad_img_ind='No';



    this.compdetailsadd.nest_ratio=1;
    this.compdetailsadd.nest_ratio_1=1;
    this.compdetailsadd.collapsed_ht=1;
    this.compdetailsadd.shpg_unit_stack_ht=1;
    this.compdetailsadd.ford_cntct=''


    this.compdetailsadd.cd_plant=this.selectedPlantListComp.plantGSDBCode.trim();
    this.compdetailsadd.cntr_grp_desc=this.selected_comp_group.cntr_grp;
    this.compdetailsadd.cntr_matr_desc=this.selected_comp_material.cntr_matr_name;
    this.compdetailsadd.cntr_mstr_catg=this.selected_comp_master_category.cntr_mstr_catg;
    this.compdetailsadd.cntr_typ_desc=String(this.selected_comp_type_code.cd_cntr_typ);
    this.compdetailsadd.typ_no=this.selected_comp_type_code.typ_no;
    this.compdetailsadd.currency_desc=this.selected_comp_currency_list.curr_desc;
    this.compdetailsadd.ownrshp_strat_code=this.selected_comp_ownrship_strat_code.ownrshp_strat_code;


    if(this.add_measure=='kg/cm')
    {
       this.compdetailsadd.UoM='M'
    }
    else
    {
      this.compdetailsadd.UoM='E'
    }

   // this.compdetailsadd.id_user=this.containerRequestScreenCdsid;
    this.compdetailsadd.id_user = this.userCode;
    this.compdetailsadd.ts_last_update=this.date.transform(new Date(),'yyyy-MM-dd');
    this.compdetailsadd.req_stat='open';

    if(this.compdetailsadd.cntr_drawing_no == null )
    {
      this.compdetailsadd.cntr_drawing_no=''
    }

    if(this.compdetailsadd.service_life == null)
    {
      this.compdetailsadd.service_life='';
    }




    this.validateCompTareWeightForAdd();
    this.validateCompCapWtForAdd();
    this.validateCompQtPerMinSuForAdd();
    this.validateCompExtLForAdd();
    this.validateCompExtWForAdd();
    this.validateCompExtHForAdd();
    this.validateWareHourseStckHtForAddComp();
    this.validateCompBaseForAdd();
    this.validateCompSuffixForAdd();
    this.validateCompDescForAdd()
    // this.validatePlantCodeForAddComp()
    this.validateMastCatgForAddComp()
    this.validateCompRetTypeForAdd()
    this.validateCntrTypeCodeForAddComp()
    this.validateCompMaterialForAdd()
    //this.validateCOSForAddComp()
    //this.validateCompGrpForAdd()
    //this.validateCompContact()
    this.validateCompContactPhone()
    this.validateCompActive()


    if(
      !this.compTareWtInValid && !this.compCapWtInValid && !this.compQtPerMinSuInValid &&
      !this.compExtLInValid && !this.compExtWInValid && !this.compExtHInValid &&
      !this.wareHourseStackHtInvaidComp &&
      !this.compBaseInvalid && !this.compSuffixInvalid && !this.compDescInvalid && !this.plantCodeInvalidComp &&
      !this.mastrCatgInvalidComp && !this.retTypeInvalidComp && !this.compTypeInvalid &&
      !this.compMaterialInvalid && !this.cosInvalidComp &&
      // !this.compGrpInvalid && !this.compCntctInvalid &&
      !this.cntctPhoneInvalidComp && !this.compActiveInvalid)
    {
      console.log('details before adding',this.compdetailsadd);
      let suffixToApi='';
      if(this.compdetailsadd.cntr_suff_ind != null && this.compdetailsadd.cntr_suff_ind.toLocaleLowerCase() == 'no')
      {
        suffixToApi='%20%20%20'
      }
      else
      {
        suffixToApi=this.compdetailsadd.cntr_suffix;
      }

      /*this.transactionService.getRequestStatusOfContainer(this.compdetailsadd.cntr_base,suffixToApi,this.compdetailsadd.ownrshp_strat_code,this.compdetailsadd.cd_region).subscribe(requestStatus=>{
        console.log("Request Status",requestStatus);
        var requestStatusReceived=requestStatus;
        console.log("after reque",this.compdetailsadd)

        if(requestStatusReceived!= '' && requestStatusReceived!= null && requestStatusReceived.toLowerCase() =='pending')
        {
          this.enableCompEditPendingNotification=true;
        }
        else
        {*/
          if(this.compdetailsadd.function != null && this.compdetailsadd.function.trim() != "" && this.compdetailsadd.function.trim().toLowerCase() == "a")
          {
            this.enableAddingNewCompNotification=true;
          }
          else if(this.compdetailsadd.function != null && this.compdetailsadd.function.trim() != '' && this.compdetailsadd.function.trim().toLowerCase() == 'c')
          {
            this.enableUpdateExistingCompNotification=true;
          }

       /* }
        console.log(" reque",this.compdetailsadd)
      })*/
    }
  }


compDetailsAddToDB()
{
  if((this.compdetailsadd.cntr_suff_ind != null && this.compdetailsadd.cntr_suff_ind.toLocaleLowerCase() == 'no' ) || (this.compdetailsadd.cntr_suffix=='%20%20%20%20%20%20%20%20' || this.compdetailsadd.cntr_suffix==''))
  {
    this.compdetailsadd.cntr_suffix=null
  }
  console.log('details to add api',this.compdetailsadd);
  this.transactionService.savecntrdetails(this.compdetailsadd).subscribe(savecntr=>
    {
        const res=savecntr;
        console.log('successful',res);
        if(res.toLowerCase() == 'success')
        {
          this.packagingprocess.addtoastinpackagingprocess({severity:'success', summary:'Success', detail:'Submitted Requested Successfully'});
          // this.componentDetailsInit();
          this.clear_add_comp()
          // this.validationInitComp();
          // this.goToSuppMainPage();
        }
        else
        {
            this.packagingprocess.addtoastinpackagingprocess({severity:'error', summary: 'Error', detail: 'Request Not Submitted,Please Try Again'});
            this.validationInitComp();
        }
    });
}


okCompEditPendingNotification()
{
  this.enableCompEditPendingNotification=false;
  // this.addCntrRequestToDBIA();
}

okAddingNewCompNotification()
{
  this.enableAddingNewCompNotification=false;
  this.compDetailsAddToDB();
}

cancelAddingNewCompNotification()
{
  this.enableAddingNewCompNotification=false;
}

okUpdateExistingCompNotification()
{
  this.enableUpdateExistingCompNotification=false;
  this.compDetailsAddToDB();
}

cancelUpdateExistingCompNotification()
{
  this.enableUpdateExistingCompNotification=false;
}




  clear_add_comp()
  {
    this.compdetailsadd.cntr_base='';
    this.compdetailsadd.cntr_suffix='';
    this.compdetailsadd.cntr_suff_ind='';
    this.compSuffixAdd='';
    this.compSuffixEnabled=true;
    this.disableCompDetailsWhenBaseOrSuffixChanged=true;
    this.prevCompSuffixInd=null;
    this.enteredCompBase=null;

    this.selected_component=null;
    this.table_selected_component=null;

    this.disabledAddSuffixToCompButton=false;
    this.AddSuffixAddedBecauseOfOwnership=false;
    this.containerOrComponentBase=null;

    this.comp_retype="Returnable";
    this.tableSelectedReturnTypeComp=null;

    this.componentDetailsCopied=false;

    this.isCmmsLoadedComponent=false;
    this.isCmmsLoadedContainer=false;

    this.selected_comp_ownrship_strat_code_in_popup=this.cntrOwnershipstratcodelist[0];
    this.table_selected_comp_ownrship_strat_code_in_popup=null;
    this.selectedPlantListComp={cd_plant:'',plantGSDBCode:'',plant_active_stat:'',plant_name:'',plant_type_name:''};
    this.compdetailsadd.cd_plant='';

    this.clearCompDetailsExceptBaseAndSuffixAndPlantCode();
    this.validationInitComp();
  }


  cancel_add_comp()
  {
    this.goToSuppMainPage();
  }


  // Validations
  validateCompBaseForAdd()
  {
    if(this.compdetailsadd.cntr_base==null || this.compdetailsadd.cntr_base.trim()=='')
    {
      this.compBaseInvalid=true;
      this.compBaseInvalidReason='Required'
    }
    else
    {
      this.compBaseInvalid=false;
      this.compBaseInvalidReason=null
    }
  }

  validateCompSuffixForAdd()
  {
    if(this.compdetailsadd.cntr_suff_ind==null || this.compdetailsadd.cntr_suff_ind.trim()=='')
    {
      this.compSuffixInvalid=true;
      this.compSuffixInvalidReason='Required'
    }
    else
    {
      this.compSuffixInvalid=false;
      this.compSuffixInvalidReason=null
    }
  }

  validateCompDescForAdd()
  {
    if(!this.isCmmsLoadedComponent)
    {
      if(this.compdetailsadd.cntr_desc==null || this.compdetailsadd.cntr_desc.trim()=='')
      {
        this.compDescInvalid=true;
        this.compDescInvalidReason='Required'
      }
      else
      {
        this.compDescInvalid=false;
        this.compDescInvalidReason=null
      }
    }

  }

  validatePlantCodeForAddComp()
  {
      if(this.selectedPlantListComp.plantGSDBCode==null || this.selectedPlantListComp.plantGSDBCode.trim()=='')
      {
        this.plantCodeInvalidComp=true;
        this.plantCodeInvalidReasonComp='Required'
      }
      else
      {
        this.plantCodeInvalidComp=false;
        this.plantCodeInvalidReasonComp=null
      }

  }




  validateMastCatgForAddComp()
  {
    if(this.selected_comp_master_category.cntr_mstr_catg==null || this.selected_comp_master_category.cntr_mstr_catg.trim()=='')
    {
      this.mastrCatgInvalidComp=true;
      this.mastrCatgInvalidReasonComp='Required'
    }
    else
    {
      this.mastrCatgInvalidComp=false;
      this.mastrCatgInvalidReasonComp=null
    }
  }

  validateCompRetTypeForAdd()
  {
    if(this.compdetailsadd.exp_refundable==null || this.compdetailsadd.exp_refundable.trim()=='')
    {
      this.retTypeInvalidComp=true;
      this.retTypeInvalidReasonComp='Required'
    }
    else
    {
      this.retTypeInvalidComp=false;
      this.retTypeInvalidReasonComp=null;
    }
  }

  validateCntrTypeCodeForAddComp()
  {

    if(this.selected_comp_type_code.cntr_typ_desc==null || this.selected_comp_type_code.cntr_typ_desc.trim()=='')
    {
      this.compTypeInvalid=true;
      this.compTypeInvalidReason='Required';
    }
    else
    {
      this.compTypeInvalid=false;
      this.compTypeInvalidReason=null;
    }

  }

  validateCompMaterialForAdd()
  {
    if(this.selected_comp_material.cntr_matr_name==null || this.selected_comp_material.cntr_matr_name.trim()=='')
    {
      this.compMaterialInvalid=true;
      this.compMaterialInvalidReason='Required';
    }
    else
    {
      this.compMaterialInvalid=false;
      this.compMaterialInvalidReason=null;
    }
  }

  validateCOSForAddComp()
  {
    if(this.selected_comp_ownrship_strat_code.ownrshp_strat_code==null || this.selected_comp_ownrship_strat_code.ownrshp_strat_code.trim()=='')
    {
      this.cosInvalidComp=true;
      this.cosInvalidReasonComp='Required';
    }
    else
    {
      this.cosInvalidComp=false;
      this.cosInvalidReasonComp=null;
    }
  }

  validateCompGrpForAdd()
  {
    if(this.selected_comp_group.cntr_grp==null || this.selected_comp_group.cntr_grp.trim()=='')
    {
      this.compGrpInvalid=true;
      this.compGrpInvalidReason='Required';
    }
    else
    {
      this.compGrpInvalid=false;
      this.compGrpInvalidReason=null;
    }
  }



  validateCompContact()
  {
    if(!this.isCmmsLoadedComponent)
    {
      if(this.compdetailsadd.ford_cntct==null || this.compdetailsadd.ford_cntct.trim()=='')
      {
        this.compCntctInvalid=true;
        this.compCntctInvalidReason='Required';
      }
      else
      {
        this.compCntctInvalid=false;
        this.compCntctInvalidReason=null;
      }
    }
  }

  validateCompContactPhone()
  {
    if(!this.isCmmsLoadedComponent)
    {
      if(this.compdetailsadd.cntct_phone==null || this.compdetailsadd.cntct_phone.trim()=='')
      {
        this.cntctPhoneInvalidComp=true;
        this.cntctPhoneInvlidReasonComp='Required';
      }
      else
      {
        this.cntctPhoneInvalidComp=false;
        this.cntctPhoneInvlidReasonComp=null;
      }
    }
  }

  validateCompActive()
  {
    if(this.compdetailsadd.cntr_active_ind==null || this.compdetailsadd.cntr_active_ind.trim()=='')
    {
      this.compActiveInvalid=true;
      this.compActiveInvalidReason='Required';
    }
    else
    {
      this.compActiveInvalid=false;
      this.compActiveInvalidReason=null;
    }
  }





  validateCompTareWeightForAdd()
  {
    if(!this.isCmmsLoadedComponent)
    {
      const comp_tar_wt = String(this.compdetailsadd.cntr_tare_wt).split('.');
      if(this.compdetailsadd.cntr_tare_wt==null || this.compdetailsadd.cntr_tare_wt==0)
      {
        this.compTareWtInValid=true;
        this.compTareWtInValidReason='Required';
      }
      else if(!String(this.compdetailsadd.cntr_tare_wt).includes('.'))
      {
        /*if( String(this.compdetailsadd.cntr_tare_wt).length>10)
        {
          this.compTareWtInValid=true;
          this.compTareWtInValidReason="Max Length Of Characters can be 10";
        }
        else*/
         if(this.compdetailsadd.cntr_tare_wt<=0 || this.compdetailsadd.cntr_tare_wt>99999.999)
        {
          this.compTareWtInValid=true;
          this.compTareWtInValidReason='Should be of range 0 to 99999.999';
        }
        else
        {
          this.compTareWtInValid=false;
          this.compTareWtInValidReason=null;
        }
      }
      else if(String(this.compdetailsadd.cntr_tare_wt).includes('.'))
    {

        if(String(comp_tar_wt[0]).length<0 || String(comp_tar_wt[0]).length>5)
        {
          this.compTareWtInValid=true;
          this.compTareWtInValidReason='Should be of range 0 to 99999.999';
        }
        else if(String(comp_tar_wt[1]).length<0 || String(comp_tar_wt[1]).length>3)
        {
          this.compTareWtInValid=true;
          this.compTareWtInValidReason='Max Length Of decimal values can be 3';
        }
        else
        {
          this.compTareWtInValid=false;
          this.compTareWtInValidReason=null;
      }
      }
      else
      {
      this.compTareWtInValid=false;
      this.compTareWtInValidReason=null;
      }
    }
  }
  validateCompCapWtForAdd()
  {
    if(!this.isCmmsLoadedComponent)
    {
      const cont_cap_wt = String(this.compdetailsadd.cntr_capacity).split('.');
      if(this.compdetailsadd.cntr_capacity==null || this.compdetailsadd.cntr_capacity==0)
      {
        this.compCapWtInValid=true;
        this.compCapWtInValidReason='Required';
      }
      else if(this.compdetailsadd.cntr_capacity<=0 || this.compdetailsadd.cntr_capacity>99999.999)
      {
        this.compCapWtInValid=true;
        this.compCapWtInValidReason='Should be of range 0 to 99999.999';
      }
      else if(String(this.compdetailsadd.cntr_capacity).includes('.'))
      {
        if(cont_cap_wt[1].length > 3)
        {
          this.compCapWtInValid=true;
          this.compCapWtInValidReason='Max Length Of decimal values can be 3';
        }
        else
        {
          this.compCapWtInValid=false;
          this.compCapWtInValidReason=null;
        }
      }
      else
      {
        this.compCapWtInValid=false;
        this.compCapWtInValidReason=null;
      }
    }
  }


  validateCompQtPerMinSuForAdd()
  {
    if(!this.isCmmsLoadedComponent)
    {
      if(this.compdetailsadd.cntr_qt_per_min_su==null || this.compdetailsadd.cntr_qt_per_min_su==0)
      {
        this.compQtPerMinSuInValid=true;
        this.compQtPerMinSuInvalidReason='Required';
      }
      else if(this.compdetailsadd.cntr_qt_per_min_su<1 || this.compdetailsadd.cntr_qt_per_min_su>99 || String(this.compdetailsadd.cntr_qt_per_min_su).includes('.'))
      {
        this.compQtPerMinSuInValid=true;
        this.compQtPerMinSuInvalidReason='Should be of range 1 to 99  and cannot contain Decimal';
      }
      else
      {
        this.compQtPerMinSuInValid=false;
        this.compQtPerMinSuInvalidReason=null;
      }
    }
  }

  validateCompExtLForAdd()
  {
    if(!this.isCmmsLoadedComponent)
    {
    const cont_ext_l = String(this.compdetailsadd.cntr_exterior_l).split('.');
    if(this.compdetailsadd.cntr_exterior_l==null || this.compdetailsadd.cntr_exterior_l==0)
    {
      this.compExtLInValid=true;
      this.compExtLInValidReason='Required';
    }
    else if(!String(this.compdetailsadd.cntr_exterior_l).includes('.'))
    {
    if(this.compdetailsadd.cntr_exterior_l<=0 || this.compdetailsadd.cntr_exterior_l>999.999 )
        {
          this.compExtLInValid=true;
          this.compExtLInValidReason='Should be of range 0 to 999.999';
        }
        /*else if( String(this.compdetailsadd.cntr_exterior_l).length>7)
        {
          this.compExtLInValid=true;
          this.compExtLInValidReason="Max Length Of Characters can be 7";
        }*/
        else
        {
          this.compExtLInValid=false;
          this.compExtLInValidReason=null;
        }
    }
    else if(String(this.compdetailsadd.cntr_exterior_l).includes('.'))
    {
        if(String(cont_ext_l[0]).length<0 || String(cont_ext_l[0]).length>3)
        {
          this.compExtLInValid=true;
          this.compExtLInValidReason='Should be of range 0 to 999.999';
        }
        else if(this.compdetailsadd.cntr_exterior_l<=0 || this.compdetailsadd.cntr_exterior_l>999.999 )
        {
          this.compExtLInValid=true;
          this.compExtLInValidReason='Should be of range 0 to 999.999';
        }
        else if(String(cont_ext_l[1]).length<0 || String(cont_ext_l[1]).length>3)
        {
          this.compExtLInValid=true;
          this.compExtLInValidReason='Max Length Of decimal values can be 3';
        }
        else
        {
          this.compExtLInValid=false;
          this.compExtLInValidReason=null;
      }
    }
    else
    {
    this.compExtLInValid=false;
    this.compExtLInValidReason=null;
    }
  }
  }
  validateCompExtWForAdd()
  {
    if(!this.isCmmsLoadedComponent)
    {
    const cont_ext_w = String(this.compdetailsadd.cntr_exterior_w).split('.');
    if(this.compdetailsadd.cntr_exterior_w==null || this.compdetailsadd.cntr_exterior_w==0)
    {
      this.compExtWInValid=true;
      this.compExtWInValidReason='Required';
    }
    else if(!String(this.compdetailsadd.cntr_exterior_w).includes('.'))
    {
   if(this.compdetailsadd.cntr_exterior_w<=0 || this.compdetailsadd.cntr_exterior_w>999.999 )
        {
          this.compExtWInValid=true;
          this.compExtWInValidReason='Should be of range 0 to 999.999';
        }
       /* else if( String(this.compdetailsadd.cntr_exterior_w).length>7)
        {
          this.compExtWInValid=true;
          this.compExtWInValidReason="Max Length Of Characters can be 7";
        }*/
        else
        {
          this.compExtWInValid=false;
          this.compExtWInValidReason=null;
        }
    }
    else if(String(this.compdetailsadd.cntr_exterior_w).includes('.'))
    {

        if(String(cont_ext_w[0]).length<0 || String(cont_ext_w[0]).length>3)
        {
          this.compExtWInValid=true;
          this.compExtWInValidReason='Should be of range 0 to 999.999';
        }
        else if(this.compdetailsadd.cntr_exterior_w<=0 || this.compdetailsadd.cntr_exterior_w>999.999 )
        {
          this.compExtWInValid=true;
          this.compExtWInValidReason='Should be of range 0 to 999.999';
        }
        else if(String(cont_ext_w[1]).length<0 || String(cont_ext_w[1]).length>3)
        {
          this.compExtWInValid=true;
          this.compExtWInValidReason='Max Length Of decimal values can be 3';
        }
        else
        {
          this.compExtWInValid=false;
          this.compExtWInValidReason=null;
        }
    }
    else
    {
    this.compExtWInValid=false;
    this.compExtWInValidReason=null;
    }
  }
  }
  validateCompExtHForAdd()
  {
    if(!this.isCmmsLoadedComponent)
    {
    const cont_ext_h = String(this.compdetailsadd.cntr_exterior_h).split('.');
    if(this.compdetailsadd.cntr_exterior_h==null || this.compdetailsadd.cntr_exterior_h==0)
    {
      this.compExtHInValid=true;
      this.compExtHInValidReason='Required';
    }
    else if(!String(this.compdetailsadd.cntr_exterior_h).includes('.'))
    {
    if(this.compdetailsadd.cntr_exterior_h<=0 || this.compdetailsadd.cntr_exterior_h>999.999 )
        {
          this.compExtHInValid=true;
          this.compExtHInValidReason='Should be of range 0 to 999.999';
        }
        /*else if( String(this.compdetailsadd.cntr_exterior_h).length>7)
        {
          this.compExtHInValid=true;
          this.compExtHInValidReason="Max Length Of Characters can be 7";
        }*/
        else
        {
          this.compExtHInValid=false;
          this.compExtHInValidReason=null;
          console.log('Split First else')
        }
    }
    else if(String(this.compdetailsadd.cntr_exterior_h).includes('.'))
    {
        if(String(cont_ext_h[0]).length<0 || String(cont_ext_h[0]).length>3)
        {
          this.compExtHInValid=true;
          this.compExtHInValidReason='Should be of range 0 to 999.999';
        }
        else if(this.compdetailsadd.cntr_exterior_h<=0 || this.compdetailsadd.cntr_exterior_h>999.999 )
        {
          this.compExtHInValid=true;
          this.compExtHInValidReason='Should be of range 0 to 999.999';
        }
        else if(String(cont_ext_h[1]).length<0 || String(cont_ext_h[1]).length>3)
        {
          this.compExtHInValid=true;
          this.compExtHInValidReason='Max Length Of decimal values can be 3';
        }
        else
        {
          this.compExtHInValid=false;
          this.compExtHInValidReason=null;
      }
    }
    else
    {
    this.compExtHInValid=false;
    this.compExtHInValidReason=null;
    }
  }
  }





  validateWareHourseStckHtForAddComp()
  {
    if(!this.isCmmsLoadedComponent)
    {
      if(this.compdetailsadd.warehouse_stack_ht==null || this.compdetailsadd.warehouse_stack_ht==0)
      {
        this.wareHourseStackHtInvaidComp=true;
        this.wareHourseStackHtInvaidReasonComp='Required';
      }
      else if(this.compdetailsadd.warehouse_stack_ht<1 || this.compdetailsadd.warehouse_stack_ht>99 || String(this.compdetailsadd.warehouse_stack_ht).includes('.'))
      {
        this.wareHourseStackHtInvaidComp=true;
        this.wareHourseStackHtInvaidReasonComp='Should be of range 1 to 99 and cannot contain Decimal';
      }
      else
      {
        this.wareHourseStackHtInvaidComp=false;
        this.wareHourseStackHtInvaidReasonComp=null;
      }
    }
  }





 /*  onSelectFromCntrAdmin()
  {
    this.selectedFromContainerAdmin=true;
    this.loadcntrdetails();
  }

  loadcntrdetails()
  {
    //alert(this.selectedFromContainerAdmin)
    console.log(this.selectedCntrFromAutoCmplete);
    this.transactionService.editbutton_get_contr_details(this.selectedCntrFromAutoCmplete.cntr_base,this.selectedCntrFromAutoCmplete.cd_cntr).subscribe(cntrdetails=>{

      this.cntrdetailsfromload.function="A";

      if(cntrdetails.cntr_suff_ind!=null && (cntrdetails.cntr_suff_ind.toLowerCase()=="yes" || cntrdetails.cntr_suff_ind.toLowerCase()=='y'))
      {
        this.cntrdetailsfromload.cntr_suff_ind="Yes";
      }
      else
      {
        this.cntrdetailsfromload.cntr_suff_ind="No";
      }

      if(cntrdetails.exp_refundable!=null && (cntrdetails.exp_refundable.toLowerCase()=="expendable" || cntrdetails.cntr_active_ind.toLowerCase()=='e'))
      {
        this.cntrdetailsfromload.exp_refundable="Expendable";
      }
      else
      {
        this.cntrdetailsfromload.exp_refundable="Returnable";
      }


      if(cntrdetails.cntr_active_ind!=null && (cntrdetails.cntr_active_ind.toLowerCase()=="yes" || cntrdetails.cntr_active_ind.toLowerCase()=='y'))
      {
        this.cntrdetailsfromload.cntr_active_ind="Yes";
      }
      else
      {
        this.cntrdetailsfromload.cntr_active_ind="No";
      }

      this.cntrdetailsfromload.cntr_base=cntrdetails.cntr_base;
      this.cntrdetailsfromload.cntr_suffix=cntrdetails.cntr_suffix;
      this.cntrdetailsfromload.cntr_desc=cntrdetails.cntr_desc;
      this.cntrdetailsfromload.card_call=cntrdetails.card_call;

      for(let i=0;i<this.cntrMasterCategory.length;i++)
      {
        if(cntrdetails.cntr_mstr_catg==this.cntrMasterCategory[i].cntr_mstr_catg)
        {
          this.cntrdetailsfromload.cntr_mstr_catg=this.cntrMasterCategory[i].cntr_mstr_catg;
        }
      }

      for(let i=0;i<this.cntrTypeCodeComp.length;i++)
      {
        if(cntrdetails.cntr_typ_desc==String(this.cntrTypeCodeComp[i].cd_cntr_typ))
        {
          this.cntrdetailsfromload.cntr_typ_desc=this.cntrTypeCodeComp[i].cntr_typ_desc;
          console.log('edit_selected_cntr_type_code',this.cntrdetailsfromload.cntr_typ_desc);
        }
      }

      for(let i=0;i<this.cntrOwnershipstratcode.length;i++)
      {
        if(cntrdetails.ownrshp_strat_code==this.cntrOwnershipstratcode[i].ownrshp_strat_code)
        {
          this.cntrdetailsfromload.ownrshp_strat_code=this.cntrOwnershipstratcode[i].ownrshp_strat_code;
        }
      }

      for(let i=0;i<this.ownrship_catg.length;i++)
      {
        if(cntrdetails.ownrshp_catg==this.ownrship_catg[i].ownrshp_catg)
        {
          this.cntrdetailsfromload.ownrshp_catg=this.ownrship_catg[i].ownrshp_catg;
        }
      }

      for(let i=0;i<this.cntrGrp.length;i++)
      {
        if(cntrdetails.cntr_grp_desc==this.cntrGrp[i].cntr_grp)
        {
          this.cntrdetailsfromload.cntr_grp_desc=this.cntrGrp[i].cntr_grp;
        }
      }

      for(let i=0;i<this.cntrCurrencyList.length;i++)
      {
        if(cntrdetails.currency_desc==this.cntrCurrencyList[i].curr_desc)
        {
          this.cntrdetailsfromload.currency_desc=this.cntrCurrencyList[i].curr_desc;
        }
      }

      this.cntrdetailsfromload.cntr_exterior_l=cntrdetails.cntr_exterior_l,
      this.cntrdetailsfromload.cntr_exterior_w=cntrdetails.cntr_exterior_w,
      this.cntrdetailsfromload.cntr_exterior_h=cntrdetails.cntr_exterior_h,
      this.cntrdetailsfromload.collapsed_ht=cntrdetails.collapsed_ht,
      this.cntrdetailsfromload.nest_ratio=cntrdetails.nest_ratio,
      this.cntrdetailsfromload.cntr_interior_l=cntrdetails.cntr_interior_l,
      this.cntrdetailsfromload.cntr_interior_w=cntrdetails.cntr_interior_w,
      this.cntrdetailsfromload.cntr_interior_h=cntrdetails.cntr_interior_h,
      this.cntrdetailsfromload.cntr_tare_wt=cntrdetails.cntr_tare_wt,
      this.cntrdetailsfromload.cntr_capacity=cntrdetails.cntr_capacity,
      this.cntrdetailsfromload.cntr_per_layer=cntrdetails.cntr_per_layer,
      this.cntrdetailsfromload.max_layers_shpg_unit=cntrdetails.max_layers_shpg_unit,
      this.cntrdetailsfromload.max_cntr_per_shpg_unit=cntrdetails.max_cntr_per_shpg_unit,

      this.cntrdetailsfromload.cntr_matr_desc=cntrdetails.cntr_matr_desc,

      this.cntrdetailsfromload.cntr_drawing_no=cntrdetails.cntr_drawing_no,
      this.cntrdetailsfromload.shpg_unit_exterior_l=cntrdetails.shpg_unit_exterior_l,
      this.cntrdetailsfromload.shpg_unit_exterior_w=cntrdetails.shpg_unit_exterior_w,
      this.cntrdetailsfromload.shpg_unit_exterior_h=cntrdetails.shpg_unit_exterior_h,
      this.cntrdetailsfromload.shpg_unit_nstdorflded_ht=cntrdetails.shpg_unit_nstdorflded_ht,
      this.cntrdetailsfromload.shpg_unit_tare_wt=cntrdetails.shpg_unit_tare_wt,
      this.cntrdetailsfromload.shpg_unit_stack_ht=cntrdetails.shpg_unit_stack_ht,
      this.cntrdetailsfromload.warehouse_stack_ht=cntrdetails.warehouse_stack_ht,
      this.cntrdetailsfromload.service_life=cntrdetails.service_life,
      this.cntrdetailsfromload.currency_desc=cntrdetails.currency_desc,
      this.cntrdetailsfromload.purchase_cost=cntrdetails.purchase_cost,
      this.cntrdetailsfromload.rental_issue_cost=cntrdetails.return_rntl_cost,
      this.cntrdetailsfromload.sup_rntl_dly_cost=cntrdetails.sup_rntl_dly_cost,
      this.cntrdetailsfromload.ford_rntl_dly_cost=cntrdetails.ford_rntl_dly_cost,
      this.cntrdetailsfromload.return_rntl_cost=cntrdetails.return_rntl_cost,
      this.cntrdetailsfromload.tooling_dev_cost=cntrdetails.tooling_dev_cost,
      this.cntrdetailsfromload.extr_cntr_dsgn_img_ind=cntrdetails.extr_cntr_dsgn_img_ind,
      this.cntrdetailsfromload.intr_cntr_dsgn_img_ind=cntrdetails.intr_cntr_dsgn_img_ind,
      this.cntrdetailsfromload.shpg_unit_img_ind=cntrdetails.shpg_unit_img_ind,
      this.cntrdetailsfromload.cad_img_ind=cntrdetails.cad_img_ind,
      this.cntrdetailsfromload.img_notes=cntrdetails.img_notes,
      this.cntrdetailsfromload.cd_region=cntrdetails.cd_region,
      this.cntrdetailsfromload.iscontainercolapsble=cntrdetails.iscontainercolapsble,
      this.cntrdetailsfromload.cd_plant=cntrdetails.cd_plant,
      this.cntrdetailsfromload.cntr_qt_per_min_su=cntrdetails.cnt_qt_per_min_su,
      this.cntrdetailsfromload.nest_ratio_1=cntrdetails.nest_ratio_1,
      this.cntrdetailsfromload.ford_cntct=cntrdetails.ford_cntct,
      this.cntrdetailsfromload.cntct_phone=cntrdetails.cntct_phone,
      this.cntrdetailsfromload.id_user=cntrdetails.id_user,
      this.cntrdetailsfromload.ts_last_update=cntrdetails.ts_last_update,
      this.cntrdetailsfromload.req_stat="open",
      this.cntrdetailsfromload.components=[],
      this.cntrdetailsfromload.UoM=cntrdetails.uom

      this.cntrdetailsadd=JSON.parse(JSON.stringify(this.cntrdetailsfromload));
    })
  } */






}

