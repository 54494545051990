/* tslint:disable */
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng';
import { threadId } from 'worker_threads';
import { LoggerService } from '../loginpage/logger.service';
import { TransactionService } from '../loginpage/Transaction.service';
import { containerMaterial, materialForPost } from '../loginpage/transactions';

export interface activeValues{
  valueLabel:string;
  selectedValue:string;
}

@Component({
  selector: 'app-materialmanagement',
  templateUrl: './materialmanagement.component.html',
  styleUrls: ['./materialmanagement.component.css'],
  providers:[MessageService,ConfirmationService]
})


export class MaterialmanagementComponent implements OnInit {


  current_region:string;
  cdsid:string;
  current_region_code:string;

  materialAddButtonEnableRet:boolean;
  materialAddDetailsEnbaleRet:boolean;
  materialDetailsEnableRet:boolean;
  materialDetailsEditEnableRet:boolean;  //When user click on edit button from the details section
  materialEditButtonDetailsEnableRet:boolean; //when user click on edit button from Grid

  materialListGridRet:containerMaterial[]=[];
  materialAddRet:materialForPost;

  materialEditRet:containerMaterial;
  materialDetailsRet:containerMaterial;
  materialDeleteRet:containerMaterial;


  displayValues:activeValues[]=[{valueLabel:"Yes",selectedValue:"Y"},{valueLabel:"No",selectedValue:"N"},{valueLabel:"Restricted",selectedValue:"R"}]

  selectedContainerAllowedRet:activeValues={valueLabel:"",selectedValue:""}
  editSelectedContainerAllowedRet:activeValues={valueLabel:"",selectedValue:""}
  detailsSelectedContainerAllowedRet:activeValues={valueLabel:"",selectedValue:""}


  selectedBasePalletAllowedRet:activeValues={valueLabel:"",selectedValue:""}
  editSelectedBasePalletAllowedRet:activeValues={valueLabel:"",selectedValue:""}
  detailsSelectedBasePalletAllowedRet:activeValues={valueLabel:"",selectedValue:""}


  selectedPalletLidComboAllowedRet:activeValues={valueLabel:"",selectedValue:""}
  editSelectedPalletLidComboAllowedRet:activeValues={valueLabel:"",selectedValue:""}
  detailsSelectedPalletLidComboAllowedRet:activeValues={valueLabel:"",selectedValue:""}


  selectedLidAllowedRet:activeValues={valueLabel:"",selectedValue:""}
  editSelectedLidAllowedRet:activeValues={valueLabel:"",selectedValue:""}
  detailsSelectedLidAllowedRet:activeValues={valueLabel:"",selectedValue:""}


  selectedDunnageInsertAllowedRet:activeValues={valueLabel:"",selectedValue:""}
  editSelectedDunnageInsertAllowedRet:activeValues={valueLabel:"",selectedValue:""}
  detailsSelectedDunnageInsertAllowedRet:activeValues={valueLabel:"",selectedValue:""}


  selectedLayerPadAllowedRet:activeValues={valueLabel:"",selectedValue:""}
  editSelectedLayerPadAllowedRet:activeValues={valueLabel:"",selectedValue:""}
  detailsSelectedLayerPadAllowedRet:activeValues={valueLabel:"",selectedValue:""}

  selectedVCIAllowedRet:activeValues={valueLabel:"",selectedValue:""}
  editSelectedVCIAllowedRet:activeValues={valueLabel:"",selectedValue:""}
  detailsSelectedVCIAllowedRet:activeValues={valueLabel:"",selectedValue:""}

  selectedWrappingAllowedRet:activeValues={valueLabel:"",selectedValue:""}
  editSelectedWrappingAllowedRet:activeValues={valueLabel:"",selectedValue:""}
  detailsSelectedWrappingAllowedRet:activeValues={valueLabel:"",selectedValue:""}

  selectedBagsAllowedRet:activeValues={valueLabel:"",selectedValue:""}
  editSelectedBagsAllowedRet:activeValues={valueLabel:"",selectedValue:""}
  detailsSelectedBagsAllowedRet:activeValues={valueLabel:"",selectedValue:""}

  selectedShipingCapPlugAllowedRet:activeValues={valueLabel:"",selectedValue:""}
  editSelectedShipingCapPlugAllowedRet:activeValues={valueLabel:"",selectedValue:""}
  detailsSelectedShipingCapPlugAllowedRet:activeValues={valueLabel:"",selectedValue:""}

  selectedBandingStrappingAllowedRet:activeValues={valueLabel:"",selectedValue:""}
  editSelectedBandingStrappingAllowedRet:activeValues={valueLabel:"",selectedValue:""}
  detailsSelectedBandingStrappingAllowedRet:activeValues={valueLabel:"",selectedValue:""}

  selectedOtherAllowedRet:activeValues={valueLabel:"",selectedValue:""}
  editSelectedOtherAllowedRet:activeValues={valueLabel:"",selectedValue:""}
  detailsSelectedOtherAllowedRet:activeValues={valueLabel:"",selectedValue:""}




  materialAddButtonEnableExp:boolean;
  materialAddDetailsEnbaleExp:boolean;
  materialDetailsEnableExp:boolean;
  materialDetailsEditEnableExp:boolean;  //When user click on edit button from the details section
  materialEditButtonDetailsEnableExp:boolean; //when user click on edit button from Grid

  materialListGridExp:containerMaterial[]=[];
  materialAddExp:materialForPost;

  materialEditExp:containerMaterial;
  materialDetailsExp:containerMaterial;
  materialDeleteExp:containerMaterial;




  selectedContainerAllowedExp:activeValues={valueLabel:"",selectedValue:""}
  editSelectedContainerAllowedExp:activeValues={valueLabel:"",selectedValue:""}
  detailsSelectedContainerAllowedExp:activeValues={valueLabel:"",selectedValue:""}


  selectedBasePalletAllowedExp:activeValues={valueLabel:"",selectedValue:""}
  editSelectedBasePalletAllowedExp:activeValues={valueLabel:"",selectedValue:""}
  detailsSelectedBasePalletAllowedExp:activeValues={valueLabel:"",selectedValue:""}


  selectedPalletLidComboAllowedExp:activeValues={valueLabel:"",selectedValue:""}
  editSelectedPalletLidComboAllowedExp:activeValues={valueLabel:"",selectedValue:""}
  detailsSelectedPalletLidComboAllowedExp:activeValues={valueLabel:"",selectedValue:""}


  selectedLidAllowedExp:activeValues={valueLabel:"",selectedValue:""}
  editSelectedLidAllowedExp:activeValues={valueLabel:"",selectedValue:""}
  detailsSelectedLidAllowedExp:activeValues={valueLabel:"",selectedValue:""}


  selectedDunnageInsertAllowedExp:activeValues={valueLabel:"",selectedValue:""}
  editSelectedDunnageInsertAllowedExp:activeValues={valueLabel:"",selectedValue:""}
  detailsSelectedDunnageInsertAllowedExp:activeValues={valueLabel:"",selectedValue:""}


  selectedLayerPadAllowedExp:activeValues={valueLabel:"",selectedValue:""}
  editSelectedLayerPadAllowedExp:activeValues={valueLabel:"",selectedValue:""}
  detailsSelectedLayerPadAllowedExp:activeValues={valueLabel:"",selectedValue:""}

  selectedVCIAllowedExp:activeValues={valueLabel:"",selectedValue:""}
  editSelectedVCIAllowedExp:activeValues={valueLabel:"",selectedValue:""}
  detailsSelectedVCIAllowedExp:activeValues={valueLabel:"",selectedValue:""}

  selectedWrappingAllowedExp:activeValues={valueLabel:"",selectedValue:""}
  editSelectedWrappingAllowedExp:activeValues={valueLabel:"",selectedValue:""}
  detailsSelectedWrappingAllowedExp:activeValues={valueLabel:"",selectedValue:""}

  selectedBagsAllowedExp:activeValues={valueLabel:"",selectedValue:""}
  editSelectedBagsAllowedExp:activeValues={valueLabel:"",selectedValue:""}
  detailsSelectedBagsAllowedExp:activeValues={valueLabel:"",selectedValue:""}

  selectedShipingCapPlugAllowedExp:activeValues={valueLabel:"",selectedValue:""}
  editSelectedShipingCapPlugAllowedExp:activeValues={valueLabel:"",selectedValue:""}
  detailsSelectedShipingCapPlugAllowedExp:activeValues={valueLabel:"",selectedValue:""}

  selectedBandingStrappingAllowedExp:activeValues={valueLabel:"",selectedValue:""}
  editSelectedBandingStrappingAllowedExp:activeValues={valueLabel:"",selectedValue:""}
  detailsSelectedBandingStrappingAllowedExp:activeValues={valueLabel:"",selectedValue:""}

  selectedOtherAllowedExp:activeValues={valueLabel:"",selectedValue:""}
  editSelectedOtherAllowedExp:activeValues={valueLabel:"",selectedValue:""}
  detailsSelectedOtherAllowedExp:activeValues={valueLabel:"",selectedValue:""}




  changeReturnTypeValue:string="";
  selectedReturnType:string="";


  showReturnableDetails:boolean=false;
  showExpendableDetails:boolean=false;


  colsRet:any[]=[];
  colsExp:any[]=[];


  current_routeid:number;
  activestatusfilter=[{label:'All',value:null},{label:'Active',value:"Yes"},{label:'In-Active',value:"No"}]



  constructor(private transactionService:TransactionService,private messageService:MessageService,private confirmationService:ConfirmationService,private date:DatePipe,private logger: LoggerService) { }

  ngOnInit(): void {

    this.current_region=this.transactionService.getregion();
    this.cdsid=this.transactionService.getcdsid();
    this.current_region_code=this.transactionService.getregioncode(this.current_region);

    this.current_routeid=151;

    console.log(this.current_routeid)


    if(this.current_region=="Asia Pacific & Africa")
    {
      sessionStorage.setItem('aprouteid', JSON.stringify(this.current_routeid));
    }

    else if(this.current_region=="Europe")
    {
      sessionStorage.setItem('eurouteid', JSON.stringify(this.current_routeid));
    }

    else if (this.current_region=="North America")

    {

      sessionStorage.setItem('narouteid', JSON.stringify(this.current_routeid));
    }

    else if(this.current_region=="South America")
    {
      sessionStorage.setItem('sarouteid', JSON.stringify(this.current_routeid));
    }


    this.changeReturnTypeValue="expendable";
    this.onChangeReturnType();


  }


  onChangeReturnType()
  {
    console.log("change value of return type",this.changeReturnTypeValue);

    if(this.changeReturnTypeValue.toLowerCase() == "returnable")
    {
      this.showReturnableDetails=true;
      this.showExpendableDetails=false;


      this.materialAddButtonEnableRet=true;
      this.materialAddDetailsEnbaleRet=false;
      this.materialDetailsEditEnableRet=false;
      this.materialDetailsEnableRet=false;
      this.materialEditButtonDetailsEnableRet=false;

      this.materialAddInitRet();
      this.materialDetailsInitRet();
      this.materialEditInitRet();
      this.materialDeleteInitRet();

      this.populateMaterialsGridRet();


    }

    else if (this.changeReturnTypeValue.toLowerCase() == "expendable")
    {


      this.showReturnableDetails=false;
      this.showExpendableDetails=true;


      this.materialAddButtonEnableExp=true;
      this.materialAddDetailsEnbaleExp=false;
      this.materialDetailsEditEnableExp=false;
      this.materialDetailsEnableExp=false;
      this.materialEditButtonDetailsEnableExp=false;

      this.materialAddInitExp();
      this.materialDetailsInitExp();
      this.materialEditInitExp();
      this.materialDeleteInitExp();

      this.populateMaterialsGridExp();


    }


  }


  /*-----------------------------------------Returnable ------------------------------------------- */

  materialAddInitRet()
  {
    this.materialAddRet={
      cntr_matr_desc:"",
      cntr_matr_name:"",
      active_stat:"",
      updated_dt:"",
      updated_by:"",
      rettype:"E",
      container:"",
      basepallet:"",
      palletlidcombo:"",
      lid:"",
      dunnageinsert:"",
      layerpad:"",
      vci:"",
      wrapping:"",
      bags:"",
      shipingcapplug:"",
      bandingstrapping:"",
      other:""
    }


    this.selectedBasePalletAllowedRet={valueLabel:"",selectedValue:""}
    this.selectedContainerAllowedRet={valueLabel:'',selectedValue:''}
    this.selectedDunnageInsertAllowedRet={valueLabel:"",selectedValue:""}
    this.selectedLayerPadAllowedRet={valueLabel:"",selectedValue:""}
    this.selectedLidAllowedRet={valueLabel:"",selectedValue:""}
    this.selectedOtherAllowedRet={valueLabel:"",selectedValue:""}
    this.selectedPalletLidComboAllowedRet={valueLabel:"",selectedValue:""}



    this.selectedBagsAllowedRet={valueLabel:"",selectedValue:""};
    this.selectedBagsAllowedRet=this.displayValues[1]
    this.selectedBandingStrappingAllowedRet={valueLabel:"",selectedValue:""}
    this.selectedBandingStrappingAllowedRet=this.displayValues[1]
    this.selectedShipingCapPlugAllowedRet={valueLabel:"",selectedValue:""}
    this.selectedShipingCapPlugAllowedRet=this.displayValues[1]
    this.selectedVCIAllowedRet={valueLabel:"",selectedValue:""}
    this.selectedVCIAllowedRet=this.displayValues[1]
    this.selectedWrappingAllowedRet={valueLabel:"",selectedValue:""}
    this.selectedWrappingAllowedRet=this.displayValues[1]



  }

  materialDetailsInitRet()
  {
    this.materialDetailsRet={
      cd_cntr_matr:0,
      cntr_matr_desc:"",
      cntr_matr_name:"",
      active_stat:"",
      updated_dt:"",
      updated_by:"",
      rettype:"E",
      container:"",
      basepallet:"",
      palletlidcombo:"",
      lid:"",
      dunnageinsert:"",
      layerpad:"",
      vci:"",
      wrapping:"",
      bags:"",
      shipingcapplug:"",
      bandingstrapping:"",
      other:""
    }



    this.detailsSelectedBasePalletAllowedRet={valueLabel:"",selectedValue:""}
    this.detailsSelectedContainerAllowedRet={valueLabel:"",selectedValue:""}
    this.detailsSelectedDunnageInsertAllowedRet={valueLabel:"",selectedValue:""}
    this.detailsSelectedLayerPadAllowedRet={valueLabel:"",selectedValue:""}
    this.detailsSelectedLidAllowedRet={valueLabel:"",selectedValue:""}
    this.detailsSelectedOtherAllowedRet={valueLabel:"",selectedValue:""}
    this.detailsSelectedPalletLidComboAllowedRet={valueLabel:"",selectedValue:""}

    this.detailsSelectedBagsAllowedRet={valueLabel:"",selectedValue:""};
    this.detailsSelectedBagsAllowedRet=this.displayValues[1]
    this.detailsSelectedBandingStrappingAllowedRet={valueLabel:"",selectedValue:""}
    this.detailsSelectedBandingStrappingAllowedRet=this.displayValues[1]
    this.detailsSelectedShipingCapPlugAllowedRet={valueLabel:"",selectedValue:""}
    this.detailsSelectedShipingCapPlugAllowedRet=this.displayValues[1]
    this.detailsSelectedVCIAllowedRet={valueLabel:"",selectedValue:""}
    this.detailsSelectedVCIAllowedRet=this.displayValues[1]
    this.detailsSelectedWrappingAllowedRet={valueLabel:"",selectedValue:""}
    this.detailsSelectedWrappingAllowedRet=this.displayValues[1]
  }

  materialEditInitRet()
  {
    this.materialEditRet={
      cd_cntr_matr:0,
      cntr_matr_desc:"",
      cntr_matr_name:"",
      active_stat:"",
      updated_dt:"",
      updated_by:"",
      rettype:"R",
      container:"",
      basepallet:"",
      palletlidcombo:"",
      lid:"",
      dunnageinsert:"",
      layerpad:"",
      vci:"N",
      wrapping:"N",
      bags:"N",
      shipingcapplug:"N",
      bandingstrapping:"N",
      other:""
    }



    this.editSelectedBasePalletAllowedRet={valueLabel:"",selectedValue:""}
    this.editSelectedContainerAllowedRet={valueLabel:"",selectedValue:""}
    this.editSelectedDunnageInsertAllowedRet={valueLabel:"",selectedValue:""}
    this.editSelectedLayerPadAllowedRet={valueLabel:"",selectedValue:""}
    this.editSelectedLidAllowedRet={valueLabel:"",selectedValue:""}
    this.editSelectedOtherAllowedRet={valueLabel:"",selectedValue:""}
    this.editSelectedPalletLidComboAllowedRet={valueLabel:"",selectedValue:""}

    this.editSelectedBagsAllowedRet={valueLabel:"",selectedValue:""};
    this.editSelectedBagsAllowedRet=this.displayValues[1]
    this.editSelectedBandingStrappingAllowedRet={valueLabel:"",selectedValue:""}
    this.editSelectedBandingStrappingAllowedRet=this.displayValues[1]
    this.editSelectedShipingCapPlugAllowedRet={valueLabel:"",selectedValue:""}
    this.editSelectedShipingCapPlugAllowedRet=this.displayValues[1]
    this.editSelectedVCIAllowedRet={valueLabel:"",selectedValue:""}
    this.editSelectedVCIAllowedRet=this.displayValues[1]
    this.editSelectedWrappingAllowedRet={valueLabel:"",selectedValue:""}
    this.editSelectedWrappingAllowedRet=this.displayValues[1]
  }

  materialDeleteInitRet()
  {
    this.materialDeleteRet={
      cd_cntr_matr:0,
      cntr_matr_desc:"",
      cntr_matr_name:"",
      active_stat:"",
      updated_dt:"",
      updated_by:"",
      rettype:"R",
      container:"",
      basepallet:"",
      palletlidcombo:"",
      lid:"",
      dunnageinsert:"",
      layerpad:"",
      vci:"N",
      wrapping:"N",
      bags:"N",
      shipingcapplug:"N",
      bandingstrapping:"N",
      other:""
    }
  }

  populateMaterialsGridRet()
  {
    var return_type="";
    if(this.changeReturnTypeValue.toLowerCase() == "returnable")
    {
      return_type="R";
    }

    else if(this.changeReturnTypeValue.toLowerCase() == "expendable")
    {
      return_type="E";
    }
    this.transactionService.getAllMaterialList().subscribe(materialList=>{
      this.materialListGridRet=[];
      if(materialList != null && materialList.length != 0)
      {
        for(var iret=0;iret<materialList.length;iret++)
        {
          if(materialList[iret].rettype != null && materialList[iret].rettype.toLowerCase() == "r")
          {
            this.materialListGridRet.push(materialList[iret]);
          }
        }

      }
      console.log("ret material list",this.materialListGridRet);


      this.colsRet=[
        //{field:"cd_cntr_matr",header:"Material Code"},
        {field:"cntr_matr_name",header:"Material Name"},
        {field:"cntr_matr_desc",header:"Material Description"},
        {field:"active_stat",header:"Active"},
        {field:"rettype",header:"E/R"},
        {field:"container",header:"Container"},
        {field:"basepallet",header:"Base Pallet"},
        {field:"palletlidcombo",header:"Pallet+ Lid"},
        {field:"lid",header:"Lid"},
        {field:"dunnageinsert",header:"Dunnage Insert"},
        {field:"layerpad",header:"Layer Pad"},
        {field:"other",header:"Other"}


      ]
    })
  }

  addMaterialRet()
  {
    this.materialAddButtonEnableRet=false;
    this.materialAddDetailsEnbaleRet=true;
    this.materialDetailsEnableRet=false;
    this.materialDetailsEditEnableRet=false;
    this.materialEditButtonDetailsEnableRet=false;
    this.materialAddInitRet();
  }

  saveAddMaterialRet()
  {
    this.materialAddRet.updated_dt=this.date.transform(new Date(),"yyyy-MM-dd hh:mm:ss");
    this.materialAddRet.updated_by=this.cdsid;

    if(this.changeReturnTypeValue.toLowerCase() == "returnable")
    {
      this.materialAddRet.rettype="R";
    }

    else if(this.changeReturnTypeValue.toLowerCase() == "expendable")
    {
      this.materialAddRet.rettype="E";
    }
    this.materialAddRet.basepallet=this.selectedBasePalletAllowedRet.selectedValue;
    this.materialAddRet.container=this.selectedContainerAllowedRet.selectedValue;
    this.materialAddRet.dunnageinsert=this.selectedDunnageInsertAllowedRet.selectedValue;
    this.materialAddRet.layerpad=this.selectedLayerPadAllowedRet.selectedValue;
    this.materialAddRet.lid=this.selectedLidAllowedRet.selectedValue;
    this.materialAddRet.other=this.selectedOtherAllowedRet.selectedValue;
    this.materialAddRet.palletlidcombo=this.selectedPalletLidComboAllowedRet.selectedValue;
    this.materialAddRet.vci='N';
    this.materialAddRet.wrapping='N';
    this.materialAddRet.bags='N';
    this.materialAddRet.bandingstrapping='N';
    this.materialAddRet.shipingcapplug='N';

    console.log("post add material", this.materialAddRet);
    //alert(JSON.stringify(this.materialAdd));

    var materialAddRetArr=[];
    materialAddRetArr.push(this.materialAddRet)
    this.transactionService.saveMaterial(materialAddRetArr).subscribe(addstatus=>{
      console.log("succesfull",addstatus)
      if(addstatus.toLowerCase()=="success")
      {
        this.messageService.add({severity:"success",summary:"Success",detail:"Material Added Successfully"})
        //this.materialAddButtonEnable=true;
        this.materialAddInitRet();
        this.populateMaterialsGridRet();
      }
      else
      {
        this.messageService.add({severity:"error",summary:"Error",detail:"Try Again!"})

      }
    })
  }

  cancelAddMaterialRet()
  {
    this.materialAddButtonEnableRet=true;
    this.materialAddDetailsEnbaleRet=false;
    this.materialAddInitRet();
  }

  disableAddRet()
  {
    if(!this.materialAddRet.cntr_matr_name || !this.materialAddRet.cntr_matr_desc || !this.materialAddRet.active_stat || (this.selectedContainerAllowedRet.selectedValue == "")
      || (this.selectedBagsAllowedRet.selectedValue == "") || (this.selectedBandingStrappingAllowedRet.selectedValue == "") || (this.selectedBasePalletAllowedRet.selectedValue == "") || (this.selectedDunnageInsertAllowedRet.selectedValue == "")
      || (this.selectedLayerPadAllowedRet.selectedValue == "") || (this.selectedLidAllowedRet.selectedValue == "") || (this.selectedOtherAllowedRet.selectedValue == "") || (this.selectedPalletLidComboAllowedRet.selectedValue == "") || (this.selectedShipingCapPlugAllowedRet.selectedValue == "")
      || (this.selectedVCIAllowedRet.selectedValue == "") || (this.selectedWrappingAllowedRet.selectedValue == "") || !this.materialAddRet.rettype  )
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  displaydetailsRet(materialdet:containerMaterial)
  {
    this.materialDetailsInitRet();
    this.transactionService.getMaterialByCode(materialdet.cd_cntr_matr).subscribe(matdetls=>{
      this.materialDetailsRet=JSON.parse(JSON.stringify(matdetls));
/*
      if(this.materialDetailsRet.bags != null )
      {
      for(var i=0; i<this.displayValues.length; i++)
      {
        if(this.materialDetailsRet.bags.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
        {
          this.detailsSelectedBagsAllowedRet=this.displayValues[i];
        }
      }
    }

    if(this.materialDetailsRet.bandingstrapping != null)
    {
      for(var i=0; i<this.displayValues.length; i++)
      {
        if( this.materialDetailsRet.bandingstrapping.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
        {
          this.detailsSelectedBandingStrappingAllowedRet=this.displayValues[i];
        }
      }
    }
    */

    if(this.materialDetailsRet.basepallet != null)
    {
      for(var i=0; i<this.displayValues.length; i++)
      {
        if( this.materialDetailsRet.basepallet.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
        {
          this.detailsSelectedBasePalletAllowedRet=this.displayValues[i];
        }
      }
    }



    if(this.materialDetailsRet.container != null)
    {
      for(var i=0; i<this.displayValues.length; i++)
      {
        if( this.materialDetailsRet.container.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
        {
          this.detailsSelectedContainerAllowedRet=this.displayValues[i];
        }
      }
    }

    if(this.materialDetailsRet.dunnageinsert != null)
    {
      for(var i=0; i<this.displayValues.length; i++)
      {
        if( this.materialDetailsRet.dunnageinsert.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
        {
          this.detailsSelectedDunnageInsertAllowedRet=this.displayValues[i];
        }
      }
    }

    if(this.materialDetailsRet.layerpad != null)
    {
      for(var i=0; i<this.displayValues.length; i++)
      {
        if( this.materialDetailsRet.layerpad.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
        {
          this.detailsSelectedLayerPadAllowedRet=this.displayValues[i];
        }
      }
    }


    if(this.materialDetailsRet.lid != null)
    {
      for(var i=0; i<this.displayValues.length; i++)
      {
        if( this.materialDetailsRet.lid.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
        {
          this.detailsSelectedLidAllowedRet=this.displayValues[i];
        }
      }
    }

    if(this.materialDetailsRet.other != null )
    {
      for(var i=0; i<this.displayValues.length; i++)
      {
        if( this.materialDetailsRet.other.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
        {
          this.detailsSelectedOtherAllowedRet=this.displayValues[i];
        }
      }
    }

    if(this.materialDetailsRet.palletlidcombo != null )
    {
      for(var i=0; i<this.displayValues.length; i++)
      {
        if(this.materialDetailsRet.palletlidcombo.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
        {
          this.detailsSelectedPalletLidComboAllowedRet=this.displayValues[i];
        }
      }
    }
/*
    if(this.materialDetailsRet.shipingcapplug != null)
    {
      for(var i=0; i<this.displayValues.length; i++)
      {
        if(this.materialDetailsRet.shipingcapplug.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
        {
          this.detailsSelectedShipingCapPlugAllowedRet=this.displayValues[i];
        }
      }
    }

    if(this.materialDetailsRet.vci != null )
    {
      for(var i=0; i<this.displayValues.length; i++)
      {
        if( this.materialDetailsRet.vci.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
        {
          this.detailsSelectedVCIAllowedRet=this.displayValues[i];
        }
      }
    }

    if(this.materialDetailsRet.wrapping != null)
    {
      for(var i=0; i<this.displayValues.length; i++)
      {
        if(this.materialDetailsRet.wrapping.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
        {
          this.detailsSelectedWrappingAllowedRet=this.displayValues[i];
        }
      }
    }
    */

      this.materialDetailsEnableRet=true;
      this.materialDetailsEditEnableRet=false;
      this.materialEditButtonDetailsEnableRet=false;
      this.materialAddButtonEnableRet=false;
      this.materialAddDetailsEnbaleRet=false;
    })
  }

  materialDetailsEditRet()
  {
    this.materialEditInitRet();
    this.materialDetailsEnableRet=false;
    this.materialDetailsEditEnableRet=true;
    this.materialEditButtonDetailsEnableRet=false;
    this.materialAddButtonEnableRet=false;
    this.materialAddDetailsEnbaleRet=false;
    this.materialEditRet=JSON.parse(JSON.stringify(this.materialDetailsRet));



/*
    if(this.materialEditRet.bags != null )
    {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if(this.materialEditRet.bags.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedBagsAllowedRet=this.displayValues[i];
      }
    }
  }

  if(this.materialEditRet.bandingstrapping != null)
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if( this.materialEditRet.bandingstrapping.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedBandingStrappingAllowedRet=this.displayValues[i];
      }
    }
  }
  */

  if(this.materialEditRet.basepallet != null)
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if( this.materialEditRet.basepallet.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedBasePalletAllowedRet=this.displayValues[i];
      }
    }
  }



  if(this.materialEditRet.container != null)
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if( this.materialEditRet.container.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedContainerAllowedRet=this.displayValues[i];
      }
    }
  }

  if(this.materialEditRet.dunnageinsert != null)
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if( this.materialEditRet.dunnageinsert.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedDunnageInsertAllowedRet=this.displayValues[i];
      }
    }
  }

  if(this.materialEditRet.layerpad != null)
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if( this.materialEditRet.layerpad.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedLayerPadAllowedRet=this.displayValues[i];
      }
    }
  }


  if(this.materialEditRet.lid != null)
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if( this.materialEditRet.lid.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedLidAllowedRet=this.displayValues[i];
      }
    }
  }

  if(this.materialEditRet.other != null )
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if( this.materialEditRet.other.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedOtherAllowedRet=this.displayValues[i];
      }
    }
  }

  if(this.materialEditRet.palletlidcombo != null )
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if(this.materialEditRet.palletlidcombo.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedPalletLidComboAllowedRet=this.displayValues[i];
      }
    }
  }
/*
  if(this.materialEditRet.shipingcapplug != null)
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if(this.materialEditRet.shipingcapplug.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedShipingCapPlugAllowedRet=this.displayValues[i];
      }
    }
  }

  if(this.materialEditRet.vci != null )
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if( this.materialEditRet.vci.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedVCIAllowedRet=this.displayValues[i];
      }
    }
  }

  if(this.materialEditRet.wrapping != null)
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if(this.materialEditRet.wrapping.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedWrappingAllowedRet=this.displayValues[i];
      }
    }
  }
  */



  }

  saveEditMatrFromDetlsRet()
  {
    this.materialEditRet.updated_by=this.cdsid;
    this.materialEditRet.updated_dt=this.date.transform(new Date(),"yyyy-MM-dd hh:mm:ss")
    //alert(JSON.stringify(this.materialEdit))

    var return_type="";
    if(this.changeReturnTypeValue.toLowerCase() == "returnable")
    {
      return_type="R";
    }

    else if(this.changeReturnTypeValue.toLowerCase() == "expendable")
    {
      return_type="E";
    }



    var materialEdited=[]
    materialEdited.push({
      cd_cntr_matr:this.materialEditRet.cd_cntr_matr,
      cntr_matr_name:this.materialEditRet.cntr_matr_name,
      cntr_matr_desc:this.materialEditRet.cntr_matr_desc,
      active_stat:this.materialEditRet.active_stat,
      updated_by:this.materialEditRet.updated_by,
      updated_dt:this.materialEditRet.updated_dt,
      rettype:return_type,
      container:this.editSelectedContainerAllowedRet.selectedValue,
      basepallet:this.editSelectedBasePalletAllowedRet.selectedValue,
      palletlidcombo:this.editSelectedPalletLidComboAllowedRet.selectedValue,
      lid:this.editSelectedLidAllowedRet.selectedValue,
      dunnageinsert:this.editSelectedDunnageInsertAllowedRet.selectedValue,
      layerpad:this.editSelectedLayerPadAllowedRet.selectedValue,
      vci:'N',
      wrapping:'N',
      bags:'N',
      shipingcapplug:'N',
      bandingstrapping:'N',
      other:this.editSelectedOtherAllowedRet.selectedValue


    })


    this.transactionService.saveMaterial(materialEdited).subscribe(editsavestat=>{
      console.log("success",editsavestat);

      if(editsavestat.toLowerCase()=="success")
      {
        this.messageService.add({severity:"success",summary:"Success",detail:"Material Edited Successfully"})
        this.materialDetailsEditEnableRet=false;
        this.populateMaterialsGridRet();

        this.materialDetailsEnableRet=true;
        this.displaydetailsRet(this.materialEditRet);

        this.materialEditInitRet();

        this.materialEditButtonDetailsEnableRet=false;
        this.materialAddButtonEnableRet=false;
        this.materialAddDetailsEnbaleRet=false;
      }
      else
      {
        this.messageService.add({severity:"error",summary:"Error",detail:"Try Again!"})

      }

    })
  }

  cancelEditMatrFromDetlsRet()
  {
    this.materialDetailsEditEnableRet=false;
    this.materialDetailsEnableRet=true;
    this.materialEditButtonDetailsEnableRet=false;
    this.materialAddButtonEnableRet=false;
    this.materialAddDetailsEnbaleRet=false;
    this.materialEditInitRet();
  }

  materialDetailsCancelRet()
  {
    this.materialDetailsEnableRet=false;
    this.materialDetailsEditEnableRet=false;
    this.materialEditButtonDetailsEnableRet=false;
    this.materialAddButtonEnableRet=true;
    this.materialAddDetailsEnbaleRet=false;
    this.materialDetailsInitRet();
  }

  editMatrFromGridRet(materialForEdit:containerMaterial)
  {
    this.materialEditInitRet();
    this.materialDetailsEnableRet=false;
    this.materialDetailsEditEnableRet=false;
    this.materialEditButtonDetailsEnableRet=true;
    this.materialAddButtonEnableRet=false;
    this.materialAddDetailsEnbaleRet=false;

    this.materialEditRet=JSON.parse(JSON.stringify(materialForEdit));


/*
    if(this.materialEditRet.bags != null )
    {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if(this.materialEditRet.bags.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedBagsAllowedRet=this.displayValues[i];
      }
    }
  }

  if(this.materialEditRet.bandingstrapping != null)
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if( this.materialEditRet.bandingstrapping.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedBandingStrappingAllowedRet=this.displayValues[i];
      }
    }
  }
  */

  if(this.materialEditRet.basepallet != null)
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if( this.materialEditRet.basepallet.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedBasePalletAllowedRet=this.displayValues[i];
      }
    }
  }



  if(this.materialEditRet.container != null)
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if( this.materialEditRet.container.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedContainerAllowedRet=this.displayValues[i];
      }
    }
  }

  if(this.materialEditRet.dunnageinsert != null)
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if( this.materialEditRet.dunnageinsert.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedDunnageInsertAllowedRet=this.displayValues[i];
      }
    }
  }

  if(this.materialEditRet.layerpad != null)
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if( this.materialEditRet.layerpad.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedLayerPadAllowedRet=this.displayValues[i];
      }
    }
  }


  if(this.materialEditRet.lid != null)
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if( this.materialEditRet.lid.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedLidAllowedRet=this.displayValues[i];
      }
    }
  }

  if(this.materialEditRet.other != null )
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if( this.materialEditRet.other.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedOtherAllowedRet=this.displayValues[i];
      }
    }
  }

  if(this.materialEditRet.palletlidcombo != null )
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if(this.materialEditRet.palletlidcombo.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedPalletLidComboAllowedRet=this.displayValues[i];
      }
    }
  }
/*
  if(this.materialEditRet.shipingcapplug != null)
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if(this.materialEditRet.shipingcapplug.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedShipingCapPlugAllowedRet=this.displayValues[i];
      }
    }
  }

  if(this.materialEditRet.vci != null )
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if( this.materialEditRet.vci.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedVCIAllowedRet=this.displayValues[i];
      }
    }
  }

  if(this.materialEditRet.wrapping != null)
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if(this.materialEditRet.wrapping.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedWrappingAllowedRet=this.displayValues[i];
      }
    }
  }
  */
  }

  saveEditMatrFromGridRet()
  {
    this.materialEditRet.updated_by=this.cdsid;
    this.materialEditRet.updated_dt=this.date.transform(new Date(),"yyyy-MM-dd hh:mm:ss");


    var return_type="";
    if(this.changeReturnTypeValue.toLowerCase() == "returnable")
    {
      return_type="R";
    }

    else if(this.changeReturnTypeValue.toLowerCase() == "expendable")
    {
      return_type="E";
    }

    var materialEdited=[]
    materialEdited.push({
      cd_cntr_matr:this.materialEditRet.cd_cntr_matr,
      cntr_matr_name:this.materialEditRet.cntr_matr_name,
      cntr_matr_desc:this.materialEditRet.cntr_matr_desc,
      active_stat:this.materialEditRet.active_stat,
      updated_by:this.materialEditRet.updated_by,
      updated_dt:this.materialEditRet.updated_dt,
      rettype:return_type,
      container:this.editSelectedContainerAllowedRet.selectedValue,
      basepallet:this.editSelectedBasePalletAllowedRet.selectedValue,
      palletlidcombo:this.editSelectedPalletLidComboAllowedRet.selectedValue,
      lid:this.editSelectedLidAllowedRet.selectedValue,
      dunnageinsert:this.editSelectedDunnageInsertAllowedRet.selectedValue,
      layerpad:this.editSelectedLayerPadAllowedRet.selectedValue,
      vci:'N',
      wrapping:'N',
      bags:'N',
      shipingcapplug:'N',
      bandingstrapping:'N',
      other:this.editSelectedOtherAllowedRet.selectedValue
    })

    this.transactionService.saveMaterial(materialEdited).subscribe(editsavestat=>{
      console.log("success",editsavestat);

      if(editsavestat.toLowerCase()=="success")
      {
        this.messageService.add({severity:"success",summary:"Success",detail:"Material Edited Successfully"})
        this.materialEditButtonDetailsEnableRet=false;
        this.populateMaterialsGridRet();

        this.materialDetailsEnableRet=true;
        this.displaydetailsRet(this.materialEditRet);

        this.materialEditInitRet();
        this.materialDetailsEditEnableRet=false;

        this.materialAddButtonEnableRet=false;
        this.materialAddDetailsEnbaleRet=false;
      }
      else
      {
        this.messageService.add({severity:"error",summary:"Error",detail:"Try Again!"})

      }

    })

  }

  cancelEditMatrFromGridRet()
  {
    this.materialDetailsEnableRet=false;
    this.materialDetailsEditEnableRet=false;
    this.materialEditButtonDetailsEnableRet=false;
    this.materialAddButtonEnableRet=true;
    this.materialAddDetailsEnbaleRet=false;
    this.materialEditInitRet();
  }


  disableEditRet()
  {
    if(!this.materialEditRet.cntr_matr_name || !this.materialEditRet.cntr_matr_desc || !this.materialEditRet.active_stat || (this.editSelectedContainerAllowedRet.selectedValue == "")
    || (this.editSelectedBagsAllowedRet.selectedValue == "") || (this.editSelectedBandingStrappingAllowedRet.selectedValue == "") || (this.editSelectedBasePalletAllowedRet.selectedValue == "") || (this.editSelectedDunnageInsertAllowedRet.selectedValue == "")
    || (this.editSelectedLayerPadAllowedRet.selectedValue == "") || (this.editSelectedLidAllowedRet.selectedValue == "") || (this.editSelectedOtherAllowedRet.selectedValue == "") || (this.editSelectedPalletLidComboAllowedRet.selectedValue == "") || (this.editSelectedShipingCapPlugAllowedRet.selectedValue == "")
    || (this.editSelectedVCIAllowedRet.selectedValue == "") || (this.editSelectedWrappingAllowedRet.selectedValue == "") )
    {
      return true;
    }
    else
    {
      return false;
    }
  }


  deleteMatrFromGridRet(materialForDelete:containerMaterial)
  {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete Material ' + '"'+materialForDelete.cntr_matr_name+'"' + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () =>
      {
        this.transactionService.deleteMaterial(materialForDelete.cd_cntr_matr).subscribe(deletestat=>{
          console.log("Success",deletestat);
          if(deletestat.toLowerCase()=="success")
          {
            this.messageService.add({severity:"success",summary:"Success",detail:"Material Deleted Successfully"})
            this.populateMaterialsGridRet();
          }
          else
          {
            this.messageService.add({severity:"error",summary:"Error",detail:"Try Again!"})
          }
        })
      }
    })

  }

  /*--------------------------------Expendable---------------------------------------- */


  materialAddInitExp()
  {
    this.materialAddExp={
      cntr_matr_desc:"",
      cntr_matr_name:"",
      active_stat:"",
      updated_dt:"",
      updated_by:"",
      rettype:"E",
      container:"",
      basepallet:"",
      palletlidcombo:"",
      lid:"",
      dunnageinsert:"",
      layerpad:"",
      vci:"",
      wrapping:"",
      bags:"",
      shipingcapplug:"",
      bandingstrapping:"",
      other:""
    }

    this.selectedBagsAllowedExp={valueLabel:"",selectedValue:""};
    this.selectedBandingStrappingAllowedExp={valueLabel:"",selectedValue:""}
    this.selectedBasePalletAllowedExp={valueLabel:"",selectedValue:""}
    this.selectedContainerAllowedExp={valueLabel:'',selectedValue:''}
    this.selectedDunnageInsertAllowedExp={valueLabel:"",selectedValue:""}
    this.selectedLayerPadAllowedExp={valueLabel:"",selectedValue:""}
    this.selectedLidAllowedExp={valueLabel:"",selectedValue:""}
    this.selectedOtherAllowedExp={valueLabel:"",selectedValue:""}
    this.selectedPalletLidComboAllowedExp={valueLabel:"",selectedValue:""}
    this.selectedShipingCapPlugAllowedExp={valueLabel:"",selectedValue:""}
    this.selectedVCIAllowedExp={valueLabel:"",selectedValue:""}
    this.selectedWrappingAllowedExp={valueLabel:"",selectedValue:""}
  }

  materialDetailsInitExp()
  {
    this.materialDetailsExp={
      cd_cntr_matr:0,
      cntr_matr_desc:"",
      cntr_matr_name:"",
      active_stat:"",
      updated_dt:"",
      updated_by:"",
      rettype:"E",
      container:"",
      basepallet:"",
      palletlidcombo:"",
      lid:"",
      dunnageinsert:"",
      layerpad:"",
      vci:"",
      wrapping:"",
      bags:"",
      shipingcapplug:"",
      bandingstrapping:"",
      other:""
    }


    this.detailsSelectedBagsAllowedExp={valueLabel:"",selectedValue:""};
    this.detailsSelectedBandingStrappingAllowedExp={valueLabel:"",selectedValue:""}
    this.detailsSelectedBasePalletAllowedExp={valueLabel:"",selectedValue:""}
    this.detailsSelectedContainerAllowedExp={valueLabel:"",selectedValue:""}
    this.detailsSelectedDunnageInsertAllowedExp={valueLabel:"",selectedValue:""}
    this.detailsSelectedLayerPadAllowedExp={valueLabel:"",selectedValue:""}
    this.detailsSelectedLidAllowedExp={valueLabel:"",selectedValue:""}
    this.detailsSelectedOtherAllowedExp={valueLabel:"",selectedValue:""}
    this.detailsSelectedPalletLidComboAllowedExp={valueLabel:"",selectedValue:""}
    this.detailsSelectedShipingCapPlugAllowedExp={valueLabel:"",selectedValue:""}
    this.detailsSelectedVCIAllowedExp={valueLabel:"",selectedValue:""}
    this.detailsSelectedWrappingAllowedExp={valueLabel:"",selectedValue:""}
  }

  materialEditInitExp()
  {
    this.materialEditExp={
      cd_cntr_matr:0,
      cntr_matr_desc:"",
      cntr_matr_name:"",
      active_stat:"",
      updated_dt:"",
      updated_by:"",
      rettype:"E",
      container:"",
      basepallet:"",
      palletlidcombo:"",
      lid:"",
      dunnageinsert:"",
      layerpad:"",
      vci:"",
      wrapping:"",
      bags:"",
      shipingcapplug:"",
      bandingstrapping:"",
      other:""
    }


    this.editSelectedBagsAllowedExp={valueLabel:"",selectedValue:""};
    this.editSelectedBandingStrappingAllowedExp={valueLabel:"",selectedValue:""}
    this.editSelectedBasePalletAllowedExp={valueLabel:"",selectedValue:""}
    this.editSelectedContainerAllowedExp={valueLabel:"",selectedValue:""}
    this.editSelectedDunnageInsertAllowedExp={valueLabel:"",selectedValue:""}
    this.editSelectedLayerPadAllowedExp={valueLabel:"",selectedValue:""}
    this.editSelectedLidAllowedExp={valueLabel:"",selectedValue:""}
    this.editSelectedOtherAllowedExp={valueLabel:"",selectedValue:""}
    this.editSelectedPalletLidComboAllowedExp={valueLabel:"",selectedValue:""}
    this.editSelectedShipingCapPlugAllowedExp={valueLabel:"",selectedValue:""}
    this.editSelectedVCIAllowedExp={valueLabel:"",selectedValue:""}
    this.editSelectedWrappingAllowedExp={valueLabel:"",selectedValue:""}
  }

  materialDeleteInitExp()
  {
    this.materialDeleteExp={
      cd_cntr_matr:0,
      cntr_matr_desc:"",
      cntr_matr_name:"",
      active_stat:"",
      updated_dt:"",
      updated_by:"",
      rettype:"E",
      container:"",
      basepallet:"",
      palletlidcombo:"",
      lid:"",
      dunnageinsert:"",
      layerpad:"",
      vci:"",
      wrapping:"",
      bags:"",
      shipingcapplug:"",
      bandingstrapping:"",
      other:""
    }
  }

  populateMaterialsGridExp()
  {
    var return_type="";
    if(this.changeReturnTypeValue.toLowerCase() == "returnable")
    {
      return_type="R";
    }

    else if(this.changeReturnTypeValue.toLowerCase() == "expendable")
    {
      return_type="E";
    }
    this.transactionService.getAllMaterialList().subscribe(materialList=>{
      this.materialListGridExp=[];
      if(materialList != null && materialList.length != 0)
      {
        for(var iexp=0;iexp<materialList.length;iexp++)
        {
          if(materialList[iexp].rettype != null && materialList[iexp].rettype.toLowerCase() == "e")
          {
            this.materialListGridExp.push(materialList[iexp]);
          }
        }

      }
      console.log("ret material list",this.materialListGridExp);
    // this.logger.info('converting data to export',this.materialListGridExp);

      this.colsExp=[
        //{field:"cd_cntr_matr",header:"Material Code"},
        {field:"cntr_matr_name",header:"Material Name"},
        {field:"cntr_matr_desc",header:"Material Description"},
        {field:"active_stat",header:"Active"},
        {field:"rettype",header:"E/R"},
        {field:"container",header:"Container"},
        {field:"basepallet",header:"Base Pallet"},
        {field:"palletlidcombo",header:"Pallet+ Lid"},
        {field:"lid",header:"Lid"},
        {field:"dunnageinsert",header:"Dunnage Insert"},
        {field:"layerpad",header:"Layer Pad"},
        {field:"other",header:"Other"},
        {field:"wrapping",header:"Wrapping"},
        {field:"bags",header:"Bags"},
        {field:"vci",header:"VCI"},
        {field:"shipingcapplug",header:"Shipping Cap/Plug"},
        {field:"bandingstrapping",header:"Banding/Strapping"},





      ]
    })
  }

  addMaterialExp()
  {
    this.materialAddButtonEnableExp=false;
    this.materialAddDetailsEnbaleExp=true;
    this.materialDetailsEnableExp=false;
    this.materialDetailsEditEnableExp=false;
    this.materialEditButtonDetailsEnableExp=false;
    this.materialAddInitExp();
  }

  saveAddMaterialExp()
  {
    this.materialAddExp.updated_dt=this.date.transform(new Date(),"yyyy-MM-dd hh:mm:ss");
    this.materialAddExp.updated_by=this.cdsid;

    if(this.changeReturnTypeValue.toLowerCase() == "returnable")
    {
      this.materialAddExp.rettype="R";
    }

    else if(this.changeReturnTypeValue.toLowerCase() == "expendable")
    {
      this.materialAddExp.rettype="E";
    }
    this.materialAddExp.bags=this.selectedBagsAllowedExp.selectedValue;
    this.materialAddExp.bandingstrapping=this.selectedBandingStrappingAllowedExp.selectedValue;
    this.materialAddExp.basepallet=this.selectedBasePalletAllowedExp.selectedValue;
    this.materialAddExp.container=this.selectedContainerAllowedExp.selectedValue;
    this.materialAddExp.dunnageinsert=this.selectedDunnageInsertAllowedExp.selectedValue;
    this.materialAddExp.layerpad=this.selectedLayerPadAllowedExp.selectedValue;
    this.materialAddExp.lid=this.selectedLidAllowedExp.selectedValue;
    this.materialAddExp.other=this.selectedOtherAllowedExp.selectedValue;
    this.materialAddExp.palletlidcombo=this.selectedPalletLidComboAllowedExp.selectedValue;
    this.materialAddExp.shipingcapplug=this.selectedShipingCapPlugAllowedExp.selectedValue;
    this.materialAddExp.vci=this.selectedVCIAllowedExp.selectedValue;
    this.materialAddExp.wrapping=this.selectedWrappingAllowedExp.selectedValue;
    console.log("post add material", this.materialAddExp);
    //alert(JSON.stringify(this.materialAdd));
    var materialAddExpArr=[];
    materialAddExpArr.push(this.materialAddExp)
    this.transactionService.saveMaterial(materialAddExpArr).subscribe(addstatus=>{
      console.log("succesfull",addstatus)
      if(addstatus.toLowerCase()=="success")
      {
        this.messageService.add({severity:"success",summary:"Success",detail:"Material Added Successfully"})
        //this.materialAddButtonEnable=true;
        this.materialAddInitExp();
        this.populateMaterialsGridExp();
      }
      else
      {
        this.messageService.add({severity:"error",summary:"Error",detail:"Try Again!"})

      }
    })
  }

  cancelAddMaterialExp()
  {
    this.materialAddButtonEnableExp=true;
    this.materialAddDetailsEnbaleExp=false;
    this.materialAddInitExp();
  }

  disableAddExp()
  {
    if(!this.materialAddExp.cntr_matr_name || !this.materialAddExp.cntr_matr_desc || !this.materialAddExp.active_stat || (this.selectedContainerAllowedExp.selectedValue == "")
      || (this.selectedBagsAllowedExp.selectedValue == "") || (this.selectedBandingStrappingAllowedExp.selectedValue == "") || (this.selectedBasePalletAllowedExp.selectedValue == "") || (this.selectedDunnageInsertAllowedExp.selectedValue == "")
      || (this.selectedLayerPadAllowedExp.selectedValue == "") || (this.selectedLidAllowedExp.selectedValue == "") || (this.selectedOtherAllowedExp.selectedValue == "") || (this.selectedPalletLidComboAllowedExp.selectedValue == "") || (this.selectedShipingCapPlugAllowedExp.selectedValue == "")
      || (this.selectedVCIAllowedExp.selectedValue == "") || (this.selectedWrappingAllowedExp.selectedValue == "") || !this.materialAddExp.rettype  )
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  displaydetailsExp(materialdet:containerMaterial)
  {
    this.materialDetailsInitExp();
    this.transactionService.getMaterialByCode(materialdet.cd_cntr_matr).subscribe(matdetls=>{
      this.materialDetailsExp=JSON.parse(JSON.stringify(matdetls));

      if(this.materialDetailsExp.bags != null )
      {
      for(var i=0; i<this.displayValues.length; i++)
      {
        if(this.materialDetailsExp.bags.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
        {
          this.detailsSelectedBagsAllowedExp=this.displayValues[i];
        }
      }
    }

    if(this.materialDetailsExp.bandingstrapping != null)
    {
      for(var i=0; i<this.displayValues.length; i++)
      {
        if( this.materialDetailsExp.bandingstrapping.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
        {
          this.detailsSelectedBandingStrappingAllowedExp=this.displayValues[i];
        }
      }
    }

    if(this.materialDetailsExp.basepallet != null)
    {
      for(var i=0; i<this.displayValues.length; i++)
      {
        if( this.materialDetailsExp.basepallet.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
        {
          this.detailsSelectedBasePalletAllowedExp=this.displayValues[i];
        }
      }
    }



    if(this.materialDetailsExp.container != null)
    {
      for(var i=0; i<this.displayValues.length; i++)
      {
        if( this.materialDetailsExp.container.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
        {
          this.detailsSelectedContainerAllowedExp=this.displayValues[i];
        }
      }
    }

    if(this.materialDetailsExp.dunnageinsert != null)
    {
      for(var i=0; i<this.displayValues.length; i++)
      {
        if( this.materialDetailsExp.dunnageinsert.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
        {
          this.detailsSelectedDunnageInsertAllowedExp=this.displayValues[i];
        }
      }
    }

    if(this.materialDetailsExp.layerpad != null)
    {
      for(var i=0; i<this.displayValues.length; i++)
      {
        if( this.materialDetailsExp.layerpad.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
        {
          this.detailsSelectedLayerPadAllowedExp=this.displayValues[i];
        }
      }
    }


    if(this.materialDetailsExp.lid != null)
    {
      for(var i=0; i<this.displayValues.length; i++)
      {
        if( this.materialDetailsExp.lid.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
        {
          this.detailsSelectedLidAllowedExp=this.displayValues[i];
        }
      }
    }

    if(this.materialDetailsExp.other != null )
    {
      for(var i=0; i<this.displayValues.length; i++)
      {
        if( this.materialDetailsExp.other.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
        {
          this.detailsSelectedOtherAllowedExp=this.displayValues[i];
        }
      }
    }

    if(this.materialDetailsExp.palletlidcombo != null )
    {
      for(var i=0; i<this.displayValues.length; i++)
      {
        if(this.materialDetailsExp.palletlidcombo.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
        {
          this.detailsSelectedPalletLidComboAllowedExp=this.displayValues[i];
        }
      }
    }

    if(this.materialDetailsExp.shipingcapplug != null)
    {
      for(var i=0; i<this.displayValues.length; i++)
      {
        if(this.materialDetailsExp.shipingcapplug.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
        {
          this.detailsSelectedShipingCapPlugAllowedExp=this.displayValues[i];
        }
      }
    }

    if(this.materialDetailsExp.vci != null )
    {
      for(var i=0; i<this.displayValues.length; i++)
      {
        if( this.materialDetailsExp.vci.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
        {
          this.detailsSelectedVCIAllowedExp=this.displayValues[i];
        }
      }
    }

    if(this.materialDetailsExp.wrapping != null)
    {
      for(var i=0; i<this.displayValues.length; i++)
      {
        if(this.materialDetailsExp.wrapping.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
        {
          this.detailsSelectedWrappingAllowedExp=this.displayValues[i];
        }
      }
    }

      this.materialDetailsEnableExp=true;
      this.materialDetailsEditEnableExp=false;
      this.materialEditButtonDetailsEnableExp=false;
      this.materialAddButtonEnableExp=false;
      this.materialAddDetailsEnbaleExp=false;
    })
  }

  materialDetailsEditExp()
  {
    this.materialEditInitExp();
    this.materialDetailsEnableExp=false;
    this.materialDetailsEditEnableExp=true;
    this.materialEditButtonDetailsEnableExp=false;
    this.materialAddButtonEnableExp=false;
    this.materialAddDetailsEnbaleExp=false;
    this.materialEditExp=JSON.parse(JSON.stringify(this.materialDetailsExp));




    if(this.materialEditExp.bags != null )
    {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if(this.materialEditExp.bags.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedBagsAllowedExp=this.displayValues[i];
      }
    }
  }

  if(this.materialEditExp.bandingstrapping != null)
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if( this.materialEditExp.bandingstrapping.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedBandingStrappingAllowedExp=this.displayValues[i];
      }
    }
  }

  if(this.materialEditExp.basepallet != null)
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if( this.materialEditExp.basepallet.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedBasePalletAllowedExp=this.displayValues[i];
      }
    }
  }



  if(this.materialEditExp.container != null)
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if( this.materialEditExp.container.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedContainerAllowedExp=this.displayValues[i];
      }
    }
  }

  if(this.materialEditExp.dunnageinsert != null)
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if( this.materialEditExp.dunnageinsert.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedDunnageInsertAllowedExp=this.displayValues[i];
      }
    }
  }

  if(this.materialEditExp.layerpad != null)
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if( this.materialEditExp.layerpad.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedLayerPadAllowedExp=this.displayValues[i];
      }
    }
  }


  if(this.materialEditExp.lid != null)
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if( this.materialEditExp.lid.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedLidAllowedExp=this.displayValues[i];
      }
    }
  }

  if(this.materialEditExp.other != null )
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if( this.materialEditExp.other.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedOtherAllowedExp=this.displayValues[i];
      }
    }
  }

  if(this.materialEditExp.palletlidcombo != null )
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if(this.materialEditExp.palletlidcombo.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedPalletLidComboAllowedExp=this.displayValues[i];
      }
    }
  }

  if(this.materialEditExp.shipingcapplug != null)
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if(this.materialEditExp.shipingcapplug.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedShipingCapPlugAllowedExp=this.displayValues[i];
      }
    }
  }

  if(this.materialEditExp.vci != null )
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if( this.materialEditExp.vci.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedVCIAllowedExp=this.displayValues[i];
      }
    }
  }

  if(this.materialEditExp.wrapping != null)
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if(this.materialEditExp.wrapping.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedWrappingAllowedExp=this.displayValues[i];
      }
    }
  }



  }

  saveEditMatrFromDetlsExp()
  {
    this.materialEditExp.updated_by=this.cdsid;
    this.materialEditExp.updated_dt=this.date.transform(new Date(),"yyyy-MM-dd hh:mm:ss")
    //alert(JSON.stringify(this.materialEdit))

    var return_type="";
    if(this.changeReturnTypeValue.toLowerCase() == "returnable")
    {
      return_type="R";
    }

    else if(this.changeReturnTypeValue.toLowerCase() == "expendable")
    {
      return_type="E";
    }



    var materialEdited=[]
    materialEdited.push({
      cd_cntr_matr:this.materialEditExp.cd_cntr_matr,
      cntr_matr_name:this.materialEditExp.cntr_matr_name,
      cntr_matr_desc:this.materialEditExp.cntr_matr_desc,
      active_stat:this.materialEditExp.active_stat,
      updated_by:this.materialEditExp.updated_by,
      updated_dt:this.materialEditExp.updated_dt,
      rettype:return_type,
      container:this.editSelectedContainerAllowedExp.selectedValue,
      basepallet:this.editSelectedBasePalletAllowedExp.selectedValue,
      palletlidcombo:this.editSelectedPalletLidComboAllowedExp.selectedValue,
      lid:this.editSelectedLidAllowedExp.selectedValue,
      dunnageinsert:this.editSelectedDunnageInsertAllowedExp.selectedValue,
      layerpad:this.editSelectedLayerPadAllowedExp.selectedValue,
      vci:this.editSelectedVCIAllowedExp.selectedValue,
      wrapping:this.editSelectedWrappingAllowedExp.selectedValue,
      bags:this.editSelectedBagsAllowedExp.selectedValue,
      shipingcapplug:this.editSelectedShipingCapPlugAllowedExp.selectedValue,
      bandingstrapping:this.editSelectedBandingStrappingAllowedExp.selectedValue,
      other:this.editSelectedOtherAllowedExp.selectedValue


    })


    this.transactionService.saveMaterial(materialEdited).subscribe(editsavestat=>{
      console.log("success",editsavestat);

      if(editsavestat.toLowerCase()=="success")
      {
        this.messageService.add({severity:"success",summary:"Success",detail:"Material Edited Successfully"})
        this.materialDetailsEditEnableExp=false;
        this.populateMaterialsGridExp();

        this.materialDetailsEnableExp=true;
        this.displaydetailsExp(this.materialEditExp);

        this.materialEditInitExp();

        this.materialEditButtonDetailsEnableExp=false;
        this.materialAddButtonEnableExp=false;
        this.materialAddDetailsEnbaleExp=false;
      }
      else
      {
        this.messageService.add({severity:"error",summary:"Error",detail:"Try Again!"})

      }

    })
  }

  cancelEditMatrFromDetlsExp()
  {
    this.materialDetailsEditEnableExp=false;
    this.materialDetailsEnableExp=true;
    this.materialEditButtonDetailsEnableExp=false;
    this.materialAddButtonEnableExp=false;
    this.materialAddDetailsEnbaleExp=false;
    this.materialEditInitExp();
  }

  materialDetailsCancelExp()
  {
    this.materialDetailsEnableExp=false;
    this.materialDetailsEditEnableExp=false;
    this.materialEditButtonDetailsEnableExp=false;
    this.materialAddButtonEnableExp=true;
    this.materialAddDetailsEnbaleExp=false;
    this.materialDetailsInitExp();
  }

  editMatrFromGridExp(materialForEdit:containerMaterial)
  {
    this.materialEditInitExp();
    this.materialDetailsEnableExp=false;
    this.materialDetailsEditEnableExp=false;
    this.materialEditButtonDetailsEnableExp=true;
    this.materialAddButtonEnableExp=false;
    this.materialAddDetailsEnbaleExp=false;

    this.materialEditExp=JSON.parse(JSON.stringify(materialForEdit));



    if(this.materialEditExp.bags != null )
    {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if(this.materialEditExp.bags.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedBagsAllowedExp=this.displayValues[i];
      }
    }
  }

  if(this.materialEditExp.bandingstrapping != null)
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if( this.materialEditExp.bandingstrapping.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedBandingStrappingAllowedExp=this.displayValues[i];
      }
    }
  }

  if(this.materialEditExp.basepallet != null)
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if( this.materialEditExp.basepallet.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedBasePalletAllowedExp=this.displayValues[i];
      }
    }
  }



  if(this.materialEditExp.container != null)
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if( this.materialEditExp.container.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedContainerAllowedExp=this.displayValues[i];
      }
    }
  }

  if(this.materialEditExp.dunnageinsert != null)
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if( this.materialEditExp.dunnageinsert.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedDunnageInsertAllowedExp=this.displayValues[i];
      }
    }
  }

  if(this.materialEditExp.layerpad != null)
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if( this.materialEditExp.layerpad.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedLayerPadAllowedExp=this.displayValues[i];
      }
    }
  }


  if(this.materialEditExp.lid != null)
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if( this.materialEditExp.lid.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedLidAllowedExp=this.displayValues[i];
      }
    }
  }

  if(this.materialEditExp.other != null )
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if( this.materialEditExp.other.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedOtherAllowedExp=this.displayValues[i];
      }
    }
  }

  if(this.materialEditExp.palletlidcombo != null )
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if(this.materialEditExp.palletlidcombo.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedPalletLidComboAllowedExp=this.displayValues[i];
      }
    }
  }

  if(this.materialEditExp.shipingcapplug != null)
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if(this.materialEditExp.shipingcapplug.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedShipingCapPlugAllowedExp=this.displayValues[i];
      }
    }
  }

  if(this.materialEditExp.vci != null )
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if( this.materialEditExp.vci.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedVCIAllowedExp=this.displayValues[i];
      }
    }
  }

  if(this.materialEditExp.wrapping != null)
  {
    for(var i=0; i<this.displayValues.length; i++)
    {
      if(this.materialEditExp.wrapping.toLowerCase() == this.displayValues[i].selectedValue.toLowerCase())
      {
        this.editSelectedWrappingAllowedExp=this.displayValues[i];
      }
    }
  }
  }

  saveEditMatrFromGridExp()
  {
    this.materialEditExp.updated_by=this.cdsid;
    this.materialEditExp.updated_dt=this.date.transform(new Date(),"yyyy-MM-dd hh:mm:ss");


    var return_type="";
    if(this.changeReturnTypeValue.toLowerCase() == "returnable")
    {
      return_type="R";
    }

    else if(this.changeReturnTypeValue.toLowerCase() == "expendable")
    {
      return_type="E";
    }

    var materialEdited=[]
    materialEdited.push({
      cd_cntr_matr:this.materialEditExp.cd_cntr_matr,
      cntr_matr_name:this.materialEditExp.cntr_matr_name,
      cntr_matr_desc:this.materialEditExp.cntr_matr_desc,
      active_stat:this.materialEditExp.active_stat,
      updated_by:this.materialEditExp.updated_by,
      updated_dt:this.materialEditExp.updated_dt,
      rettype:return_type,
      container:this.editSelectedContainerAllowedExp.selectedValue,
      basepallet:this.editSelectedBasePalletAllowedExp.selectedValue,
      palletlidcombo:this.editSelectedPalletLidComboAllowedExp.selectedValue,
      lid:this.editSelectedLidAllowedExp.selectedValue,
      dunnageinsert:this.editSelectedDunnageInsertAllowedExp.selectedValue,
      layerpad:this.editSelectedLayerPadAllowedExp.selectedValue,
      vci:this.editSelectedVCIAllowedExp.selectedValue,
      wrapping:this.editSelectedWrappingAllowedExp.selectedValue,
      bags:this.editSelectedBagsAllowedExp.selectedValue,
      shipingcapplug:this.editSelectedShipingCapPlugAllowedExp.selectedValue,
      bandingstrapping:this.editSelectedBandingStrappingAllowedExp.selectedValue,
      other:this.editSelectedOtherAllowedExp.selectedValue
    })

    this.transactionService.saveMaterial(materialEdited).subscribe(editsavestat=>{
      console.log("success",editsavestat);

      if(editsavestat.toLowerCase()=="success")
      {
        this.messageService.add({severity:"success",summary:"Success",detail:"Material Edited Successfully"})
        this.materialEditButtonDetailsEnableExp=false;
        this.populateMaterialsGridExp();

        this.materialDetailsEnableExp=true;
        this.displaydetailsExp(this.materialEditExp);

        this.materialEditInitExp();
        this.materialDetailsEditEnableExp=false;

        this.materialAddButtonEnableExp=false;
        this.materialAddDetailsEnbaleExp=false;
      }
      else
      {
        this.messageService.add({severity:"error",summary:"Error",detail:"Try Again!"})

      }

    })

  }

  cancelEditMatrFromGridExp()
  {
    this.materialDetailsEnableExp=false;
    this.materialDetailsEditEnableExp=false;
    this.materialEditButtonDetailsEnableExp=false;
    this.materialAddButtonEnableExp=true;
    this.materialAddDetailsEnbaleExp=false;
    this.materialEditInitExp();
  }


  disableEditExp()
  {
    if(!this.materialEditExp.cntr_matr_name || !this.materialEditExp.cntr_matr_desc || !this.materialEditExp.active_stat || (this.editSelectedContainerAllowedExp.selectedValue == "")
    || (this.editSelectedBagsAllowedExp.selectedValue == "") || (this.editSelectedBandingStrappingAllowedExp.selectedValue == "") || (this.editSelectedBasePalletAllowedExp.selectedValue == "") || (this.editSelectedDunnageInsertAllowedExp.selectedValue == "")
    || (this.editSelectedLayerPadAllowedExp.selectedValue == "") || (this.editSelectedLidAllowedExp.selectedValue == "") || (this.editSelectedOtherAllowedExp.selectedValue == "") || (this.editSelectedPalletLidComboAllowedExp.selectedValue == "") || (this.editSelectedShipingCapPlugAllowedExp.selectedValue == "")
    || (this.editSelectedVCIAllowedExp.selectedValue == "") || (this.editSelectedWrappingAllowedExp.selectedValue == "") )
    {
      return true;
    }
    else
    {
      return false;
    }
  }


  deleteMatrFromGridExp(materialForDelete:containerMaterial)
  {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete Material ' + '"'+materialForDelete.cntr_matr_name+'"' + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () =>
      {
        this.transactionService.deleteMaterial(materialForDelete.cd_cntr_matr).subscribe(deletestat=>{
          console.log("Success",deletestat);
          if(deletestat.toLowerCase()=="success")
          {
            this.messageService.add({severity:"success",summary:"Success",detail:"Material Deleted Successfully"})
            this.populateMaterialsGridExp();
          }
          else
          {
            this.messageService.add({severity:"error",summary:"Error",detail:"Try Again!"})
          }
        })
      }
    })

  }

}
