import { Component, OnInit } from '@angular/core';




@Component({
    // tslint:disable-next-line:component-selector
  selector: '[app-formtemplate]',
  templateUrl: './formtemplate.component.html',
  styles: [`

   `]
})
export class FormtemplateComponent {

  constructor() { }




}
