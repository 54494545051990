/* tslint:disable */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng';
import { TransactionService } from '../loginpage/Transaction.service';
import { ActivePlants, ApprovalStatus, fromdiplayvalues_all, partsbulkemailpost, programsbasedonplants, programsbasedonplantsforsearchprop, RevHistFromAPi, RevisionObj, searchpartgrid, searchpartgridfromapi, searchProposalGrid, searchProposalGridFromAPI, selec_prt_pro_details, substatuslist, subtradco, supplierForSearchPart, supplierforsearchproposal, supp_country, plantcodeList, programlistApi, suppliergsdbcodelist, tradingcompany, search_proposalstatus, cosList, search_COS, gpp_supp_contry,  GPP_search_grid_API, GPP_Search_Prt_grid } from '../loginpage/transactions';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { from } from 'rxjs';
import { concatMap, retry, tap } from 'rxjs/operators';

@Component({
  selector: 'app-gpparchivesearchpart',
  templateUrl: './gpparchivesearchpart.component.html',
  styleUrls: ['./gpparchivesearchpart.component.css']
})


export class GpparchivesearchpartComponent implements OnInit {

  current_region:string;
  region_code:string;
  userid:string;
  isloadersearchprop=false;

  part_prefix='';
  part_base='';
  part_suffix='';
  part_desc='';
  selectedpartprefix='';
  selectedpartbase='';
  selectedpartsuffix='';
  selectedpartdesc='';

  plantcode='';
  selected_plant_code:plantcodeList={
   
    plantgsdbcode:""
  
    
  
  }
  selected_plant_codes_list:plantcodeList[]=[];
  plantcodelist:plantcodeList[]=[];
  all_selected_on_plant_code=false;
  plantcodeselected=false;


  programsList:programlistApi[]=[];
  programListalongwithPlants:programsbasedonplantsforsearchprop[]=[];
  selected_programs_list:programsbasedonplantsforsearchprop[]=[];
  programcodes='';
  all_selected_on_program=false;

  selectedcntrbase:string="";
  cntr_base:string="";
  selectedcntrsuffix:string="";
  cntr_suffix:string="";

  regionlist:any=[];
  selected_region_from_list='';

  part_sup_code='';
  part_sup_name='';
  apprv_stat_grid_inp='';
  part_trad_cmp='';
  cos_in_grid='';
  part_sp_cntry='';

  part_plant_code='';
  part_prg_code='';


  supplierGSDBcode='';
  supplierfullname='';

  supplierList:suppliergsdbcodelist[]=[];
  selected_supplier:suppliergsdbcodelist={suppliergsdbcode:''};
  suupliercode='';

  suppliercountryList:gpp_supp_contry[]=[];
  selected_suppliercountry:gpp_supp_contry[]=[];
  suppliercountry='';
  all_selected_supp_cntry=false;

  tradCmpyList:tradingcompany[]=[];
  selectedtrdcmpy:tradingcompany[]=[];
  all_selected_trad_comp=false;
  trdcmpy='';


  apprvr_status_list:search_proposalstatus[];
  selected_approval_status:search_proposalstatus[]=[];
  approval_status='';
  all_selected_in_approval_status=false;


  Cntr_ownershipList:search_COS[]=[];
  selectedcoslist:search_COS[]=[];
  cos_stat='';
  all_selected_cos=false;

  searchpartgrid:GPP_search_grid_API[]=[];
  searchpartgridfromapi:GPP_search_grid_API[]=[];
  cols:any;

  view_proposal=false;
  search_result='';

  revHistFromServiceAPI:RevHistFromAPi[]=[];

  proposal_details_search:fromdiplayvalues_all;
  revision_obj_search:RevisionObj[]=[];

  enabledetails_search=false;

  selectedpartnodetails:selec_prt_pro_details={simplifiedpartno:'-',bompartmasterid:0,proposalid:0,plantgsdbcode:'',proposallastsaved:0,part_status:'',supplier_code:'',proposallastsaved_ee:0,currentview_ee:false,is_ee_proposal:false,from_draft:false,propsl_type:'',imp_part_status:''};

  selectedPartsForBulkEmail:GPP_search_grid_API[]=[];

  base_url_get_archieve_report: string;
  base_path_archive_report: string;


  enableSendEmailPopUp=false;
  bulkEmailSubject='';
  bulkEmailBody=''
  copyMeOnBulkEmail=false;

  selectedPartsForBulkEmailPost:partsbulkemailpost={

    partDetls:[
      {
        partNumberCommonized:'',
        plantCode:'',
        programName:'',
        supCode:''
      }
    ],
    subject:'',
    emailBody:'',
    copyMe:'',
    user:''

  }



  enableViewSelectedPartsPopup=false;

  cols_view:any;

  enableBulkMailOptionOnUserAccess=false;

  enable=false;
  formlink:string="";
  historylink:string="";

  loadProposalText:string="";
  formId:string="";
  disableDownloadPdfButton:boolean=false;
  disableDownloadHistoryButton:boolean=false;
  isloaderloadFormprop:boolean=false;
  isloaderloadHistoryprop:boolean=false;
  downloadPdfText:string="";
  loadFormPDFerrorText:string="";
  loadHistoryPDFerrorText:string=""

  errorLoadingData:string="";

  prevFormId:string="";

  constructor(private router:Router,private transactionService:TransactionService,private messageService:MessageService, private http: HttpClient,private sanitizer:DomSanitizer) { }


  ngOnInit(): void {
    this.current_region=this.transactionService.getregion();
    this.region_code=this.transactionService.getregioncode(this.current_region);
    this.userid=this.transactionService.getcdsid();

    this.base_url_get_archieve_report = environment.nfsBaseAPIUrl+"/downloadNonImgFile?fPath=";
    //this.base_path_archive_report = environment.nfsBasePath+"gpparchive/";
    this.base_path_archive_report = "gpparchive/";

    this.loadProposalText="";

    this.loadRegionDropDown();
    
    


  }

  loadRegionDropDown()
  {
    const reg_lst=this.transactionService.getuserregion();
    for(let i=0;i<reg_lst.length;i++)
    {
      const regcode=reg_lst[i].toString();
      const regname=this.transactionService.getregionnamebycode(regcode);
      this.regionlist.push({label:regname,value:regname});
      console.log('Region list', this.regionlist, this.current_region)
    }

    if(this.regionlist.length!=0)
    {
      if(this.regionlist.filter(ep=>ep.value==this.current_region))
      {
        // console.log("Region_selected111111111", this.selected_region_from_list);
        this.selected_region_from_list=this.current_region;
        console.log('Region_selected', this.selected_region_from_list);
      }
      else{
        this.selected_region_from_list  = this.regionlist.value;
        console.log('Region_selected_else', this.selected_region_from_list);
      }

    }

    
    console.log("Region_selected for plant", this.selected_region_from_list);
    this.loadPlantCode();
    this.loadsupplierdetails();
    this.loadtradingcompany();
    this.loadSupplierCountry();
    this.loadProposalStatus();
    this.loadCOS();

  }

  loadPlantCode()
  {
          console.log('Plantcodelist - 1')
          const regforinput=this.transactionService.getregioncode(this.selected_region_from_list);
          // var regforinput=this.selected_region_from_list

          console.log('Reg selected from list', regforinput)
          console.log('Reg selected from list2', this.transactionService.getregioncode(this.selected_region_from_list))
          this.transactionService.get_GPP_arc_searchplantcodes(regforinput).subscribe(plantco=>{

            console.log('Plantcodelist', plantco)
            const resultplant:plantcodeList[]=plantco;
            const plantcodetemp:plantcodeList[]=[];

            plantcodetemp.push({plantgsdbcode:'ALL'});
            for(let h=0;h<resultplant.length;h++)
            {
              plantcodetemp.push({plantgsdbcode:resultplant[h].plantgsdbcode})
            }
            this.plantcodelist=[];
            this.plantcodelist=plantcodetemp
            this.selected_plant_codes_list=[];
            this.selected_plant_code={plantgsdbcode: "" }
            console.log("plantcodes from backend",this.plantcodelist);
          })
  }

 
  /*change_plant_codes()
  {
    let all_selected_on_plant_code=false;
    let lenlessthan1_plant_code=false;
    this.plantcode='';

    // When plants is changed programs variable shd be emptied and
    // when click outside after selecting plants,these variables will have loaded data
    this.programsList=[];
    this.selected_programs_list=[];
    this.programcodes='';
    this.programListalongwithPlants=[];



    if(this.selected_plant_codes_list.length==this.plantcodelist.length-1)
    {
      lenlessthan1_plant_code=true;
    }
    for(let j=0;j<this.selected_plant_codes_list.length;j++)
    {
      if(this.selected_plant_codes_list[j].plantgsdbcode=='ALL')
      {
        all_selected_on_plant_code=true;
      }
    }

    if(all_selected_on_plant_code==false && lenlessthan1_plant_code==true)
    {
      all_selected_on_plant_code=true;
    }

    if(all_selected_on_plant_code==true)
    {
      this.plantcode='all';
      this.selected_plant_codes_list=[];
      this.selected_plant_codes_list.push({plantgsdbcode:'ALL'});
    }
  }*/

  /*onhide_plant_codes()
  {
    this.all_selected_on_plant_code=false;
    this.plantcode='';

    // checking whether length of selected list is less than one 
    var plantslen:boolean=false;
    if(this.selected_plant_codes_list.length==this.plantcodelist.length-1)
    {
      plantslen=true;
    }

    for(let ptc=0;ptc<this.selected_plant_codes_list.length;ptc++)
    {
      this.plantcode+=this.selected_plant_codes_list[ptc].plantgsdbcode;
      if(ptc < this.selected_plant_codes_list.length-1)
      {
        this.plantcode+=',';
      }

      if(this.selected_plant_codes_list[ptc].plantgsdbcode=='ALL')
      {
        this.all_selected_on_plant_code=true;
        this.plantcode='all';
        break;
      }
    }

    // If selectedvariables length is one less than total length but all is not selected
     //  which means except all remaining are selected so make 
      // all_selected_on_plant_code as true and make code  send all to backend 

    if(this.all_selected_on_plant_code==false && plantslen==true)
    {
      this.plantcode='all';
      this.all_selected_on_plant_code=true;
    }
    console.log('plant codes in Hide',this.plantcode,typeof(this.plantcode));

    if(this.plantcode.length>0)
    {
      this.plantcodeselected=true;
      this.loadprogramcodes();
      this.loadtradingcompany(this.plantcode);
      console.log('plantcode selected is true', this.selected_plant_codes_list)
    }
    else
    {
      this.plantcodeselected=false;
      this.programsList=[];
      this.selected_programs_list=[];
      this.programListalongwithPlants=[];
      this.programcodes='';
      this.loadtradingcompany('all');
    }
  }*/

  plantCodeChanged()
  {
    this.plantcodeselected=true;
    this.programsList=[];
    this.selected_programs_list=[];
    this.programcodes="";
    this.programListalongwithPlants=[];
    
    this.plantcode=this.selected_plant_code.plantgsdbcode;
    this.loadprogramcodes();
  }

  // loadprogramcodes()
  // {
  //   var tempobj:programlistApi[]=[];

  //   //selected_region_from_list will have correct region even when the component is called from any parent
  //   // because selected_region_from_list will be set as current region when this component is called for copy container dialog

  //   var regval=this.transactionService.getregioncode(this.selected_region_from_list);
  //   this.transactionService.getprogmlistwithplant(regval,this.plantcode).subscribe(progs=>{
  //     this.programsList=progs;
  //     console.log("Program_List", this.programsList)
  //     if(this.programsList.length>0)
  //     {
  //       tempobj.push({plantgsdbcode:"",programname:"All"});
  //     }
  //     for(var l=0;l<this.programsList.length;l++)
  //     {
  //       var programwasalreadypresent:boolean=false;
  //       if(tempobj.length!=0)
  //       {
  //         if(tempobj.some(o=>o.programname.toLowerCase() == this.programsList[l].programname.toLowerCase()))
  //         {
  //           var index=tempobj.findIndex(object=>{return object.programname.toLowerCase()==this.programsList[l].programname.toLowerCase()});
  //           // var newvalue=tempobj[index].programnamewithplant.slice(0,tempobj[index].programnamewithplant.length-1) ;
  //           // tempobj[index].programnamewithplant=newvalue+', '+this.programsList[l].plantgsdbcode+')';

  //           tempobj[index].programname = tempobj[index].programname;
  //           programwasalreadypresent=true;
  //         }
  //       }
  //       if(!programwasalreadypresent)
  //       {
  //         tempobj.push({plantgsdbcode:this.programsList[l].plantgsdbcode,programname:this.programsList[l].programname});
  //       }

  //     }
  //     this.programListalongwithPlants=JSON.parse(JSON.stringify(tempobj));

  //     console.log("programList",this.programsList)
  //     console.log("programListalongwithPlants",this.programListalongwithPlants);
  //   })
  // }
  loadprogramcodes()
  {
    var tempobj:programsbasedonplantsforsearchprop[]=[];
    this.programListalongwithPlants=[];

    // selected_region_from_list will have correct region even when the component is called from any parent
    // because selected_region_from_list will be set as current region when this component is called for copy container dialog

    const regval=this.transactionService.getregioncode(this.selected_region_from_list);
    this.transactionService.getprogmlistwithplant(regval,this.plantcode).subscribe(progs=>{
      this.programsList=progs;
      if(this.programsList.length>0)
      {
        tempobj.push({plantgsdbcode:"",programname:"All",programnamewithplant:"ALL",cd_region:regval});
      }
      for(let l=0;l<this.programsList.length;l++)
      {
        let programwasalreadypresent=false;
        if(tempobj.length!=0)
        {
          if(tempobj.some(o=>o.programname.toLowerCase() == this.programsList[l].programname.toLowerCase()))
          {
            const index=tempobj.findIndex(object=>{return object.programname.toLowerCase()==this.programsList[l].programname.toLowerCase()});
            // var newvalue=tempobj[index].programnamewithplant.slice(0,tempobj[index].programnamewithplant.length-1) ;
            // tempobj[index].programnamewithplant=newvalue+', '+this.programsList[l].plantgsdbcode+')';
            programwasalreadypresent=true;
          }
        }
        if(!programwasalreadypresent)
        {
          tempobj.push({plantgsdbcode:this.programsList[l].plantgsdbcode,programname:this.programsList[l].programname,programnamewithplant:this.programsList[l].programname+' ('+this.programsList[l].plantgsdbcode+')',cd_region:regval});
        }

      }
      this.programListalongwithPlants=tempobj;
      this.selected_programs_list=[];
      
      console.log("programList",this.programsList)
      console.log("programListalongwithPlants",this.programListalongwithPlants);
    })
  }
  onhide_program_codes()
  {
    this.all_selected_on_program=false;
    this.programcodes='';

    /* checking whether length of selected list is less than one */
    let prglen=false;
    if(this.selected_programs_list.length==this.programListalongwithPlants.length-1)
    {
      prglen=true;
    }

    for(let prgi=0;prgi<this.selected_programs_list.length;prgi++)
    {
      this.programcodes+=this.selected_programs_list[prgi].programname;
      console.log('Program_onhide', this.programcodes);
      if(prgi < this.selected_programs_list.length-1)
      {
        this.programcodes+=',';
        console.log('362', this.programcodes, prgi)
      }

      if(this.selected_programs_list[prgi].programname=='All')
      {
        this.all_selected_on_program=true;
        this.programcodes='all';
        break;
      }
    }

    /* If selectedvariables length is one less than total length but all is not selected
       which means except all remaining are selected so make
       all_selected_on_program as true and make code  send all to backend */

    if(this.all_selected_on_program==false && prglen==true)
    {
      this.programcodes='all';
      this.all_selected_on_program=true;
    }
    console.log('program codes in Hide',this.programcodes,typeof(this.programcodes));

  }

  change_prgm_codes()
  {
    let all_selected_on_program=false;
    let lenlessthan1_prg_code=false;
    this.programcodes='';

    if(this.selected_programs_list.length==this.programListalongwithPlants.length-1)
    {
      lenlessthan1_prg_code=true;
    }
    for(let j=0;j<this.selected_programs_list.length;j++)
    {
      if(this.selected_programs_list[j].programname=='All')
      {
        all_selected_on_program=true;
      }
    }

    if(all_selected_on_program==false && lenlessthan1_prg_code==true)
    {
      all_selected_on_program=true;
    }

    if(all_selected_on_program==true)
    {
      this.programcodes='all';
      this.selected_programs_list=[];
      const regval=this.transactionService.getregioncode(this.selected_region_from_list);
      this.selected_programs_list.push({cd_region:regval,plantgsdbcode:'',programname:'All',programnamewithplant:'All'});
    }
  }


  regionchanged()
  {
    // alert("New Region"+this.selected_region_from_list);
    const newregion=this.transactionService.getregioncode(this.selected_region_from_list);

    this.selected_suppliercountry=[];
    this.suppliercountry="";
    
    this.selected_plant_codes_list=[];
    this.selected_plant_code={plantgsdbcode: "" }
    this.plantcode="";
    this.selected_programs_list=[];
    this.programcodes="";
    this.plantcodeselected=false;
    this.selectedtrdcmpy=[];
    this.trdcmpy="";
   

    
      this.selected_approval_status=[];
      this.approval_status="";


    this.selectedcoslist=[];
    this.cos_stat="";

  

    this.selected_approval_status=[];
    this.approval_status="";

    this.selected_supplier={suppliergsdbcode:''};
    this.suupliercode="";
    this.loadPlantCode();
    this.loadsupplierdetails();
    this.loadtradingcompany();
    this.loadSupplierCountry();
    this.loadProposalStatus();
    this.loadCOS();
  }



  loadsupplierdetails()
  {
      const regcode=this.transactionService.getregioncode(this.selected_region_from_list);
      this.transactionService.get_suppliergsdbcode(regcode).subscribe(suplis=>{
        console.log('suppliers',suplis,this.region_code);
        this.supplierList=suplis;

        if(this.supplierList!=null)
        {
          for(const supplier of this.supplierList)
          {
            // this.selected_supplier.suppliergsdbcode=supplier.suppliergsdbcode;
            // console.log("Selected_supplier", this.selected_supplier, supplier)
            //console.log("Selected_supplier2", this.selected_supplier.suppliergsdbcode, supplier.suppliergsdbcode)
          }
        }
      })

  }

  supplierchanged()
  {
    // alert(JSON.stringify(this.selected_supplier));
    // this.transactionService.getsuppdet(this.selected_supplier.suppliergsdbcode,"N").subscribe(supdet=>{
    //   console.log("supdet",supdet)
    //   this.supplierfullname=supdet.supgsdbcode;
    //   this.suupliercode=this.selected_supplier.suppliergsdbcode;
    // })

  }
  loadSupplierCountry()
  {
    var regcode=this.transactionService.getregioncode(this.selected_region_from_list);
    this.transactionService.get_suppliercountry(regcode).subscribe(supp_country_list=>{

      const resultsuppcntry=supp_country_list;
      const suppcntrytemp:gpp_supp_contry[]=[];
      this.suppliercountryList=supp_country_list;
      if(resultsuppcntry!=null && resultsuppcntry.length!=0)
      {
        suppcntrytemp.push({cd_country_sup:'ALL'})


        for(let s=0;s<resultsuppcntry.length;s++)
        {
          // suppcntrytemp[s+1]=resultsuppcntry[s];
          if(resultsuppcntry[s].cd_country_sup!=null)
          // {
          //   suppcntrytemp[s+1].code_name=resultsuppcntry[s].cd_country_iso+'-'+resultsuppcntry[s].country_name
          // }
          // else
          // {
            // suppcntrytemp[s+1].code_name=resultsuppcntry[s].country_name;
            // suppcntrytemp[s+1].cd_country_sup=resultsuppcntry[s].cd_country_sup;

            suppcntrytemp.push(...resultsuppcntry);
          // }
        }

        this.selected_suppliercountry=[];
        this.suppliercountryList=suppcntrytemp;
        console.log('countrylist',this.suppliercountryList);

      }
    });
  }

  onhidesuppcountry()
  {
    this.all_selected_supp_cntry=false;
    this.suppliercountry='';

    /* checking whether length of selected list is less than one */
    let suppcntrylen=false;
    if(this.selected_suppliercountry.length==this.suppliercountryList.length-1)
    {
      suppcntrylen=true;
    }

    for(let spct=0;spct<this.selected_suppliercountry.length;spct++)
    {
      this.suppliercountry+=this.selected_suppliercountry[spct].cd_country_sup;
      console.log('Selected_SUPLIESS', this.selected_suppliercountry)
      if(spct < this.selected_suppliercountry.length-1)
      {
        this.suppliercountry+=',';
      }

      if(this.selected_suppliercountry[spct].cd_country_sup=='ALL')
      {
        this.all_selected_supp_cntry=true;
        this.suppliercountry='all';
        break;
      }
    }

    /* If selectedvariables length is one less than total length but all is not selected
       which means except all remaining (both expendable and returnable are selected) so make
       all_selected_in_ownrshp_catg as true and make code  send all to backend */

    if(this.all_selected_supp_cntry==false && suppcntrylen==true)
    {
      this.suppliercountry='all';
      this.all_selected_supp_cntry=true;
    }
    console.log('Supplier Country',this.selected_suppliercountry);

  }

  change_supp_cntry()
  {
    let all_present_in_supp_cntry=false;
    let lenlessthan1_supp_cntry=false;
    this.suppliercountry='';

    if(this.selected_suppliercountry.length==this.suppliercountryList.length-1)
    {
      lenlessthan1_supp_cntry=true;
    }
    for(let j=0;j<this.selected_suppliercountry.length;j++)
    {
      if(this.selected_suppliercountry[j].cd_country_sup=='ALL')
      {
        all_present_in_supp_cntry=true;
      }
    }

    if(all_present_in_supp_cntry==false && lenlessthan1_supp_cntry==true)
    {
      all_present_in_supp_cntry=true;
    }

    if(all_present_in_supp_cntry==true)
    {
      this.suppliercountry='all';
      this.selected_suppliercountry=[];
      this.selected_suppliercountry.push({cd_country_sup:'ALL'});
      console.log('Selected_supplier', this.selected_suppliercountry, this.selected_supplier);
    }
  }

  loadtradingcompany()
  {

    const region_code = this.transactionService.getregioncode(this.selected_region_from_list);
   // this.transactionService.gettradingcompany().subscribe(sub_tradcmpy=>{
    this.transactionService.get_tradingcompany(region_code).subscribe(sub_tradcmpy=>{
      console.log(sub_tradcmpy);
      this.tradCmpyList=[];
      this.tradCmpyList.push({trading_company:'All'});
      this.tradCmpyList.push({trading_company:'[No Trading Company]'})
      if(sub_tradcmpy != null && sub_tradcmpy.length != 0)
      {
      for(let trd=0;trd<sub_tradcmpy.length;trd++)
      {
      this.tradCmpyList.push(sub_tradcmpy[trd]);
      }
      }
      console.log('trad',this.tradCmpyList)
      this.selectedtrdcmpy=[];

    });
  }

  onhidetradcomp()
  {
    this.all_selected_trad_comp=false;
    this.trdcmpy='';
    /* checking whether length of selected list is less than one */
    let tradcomplen=false;
    if(this.selectedtrdcmpy.length==this.tradCmpyList.length-1)
    {
      tradcomplen=true;
    }

    for(let trct=0;trct<this.selectedtrdcmpy.length;trct++)
    {
      if(this.selectedtrdcmpy[trct].trading_company=='[No Trading Company]')
      {
        this.trdcmpy+='notradco'
      }
      else
      {
        this.trdcmpy+=this.selectedtrdcmpy[trct].trading_company;
      }
      if (trct < this.selectedtrdcmpy.length-1)
      {
        this.trdcmpy+=',';
      }

      if(this.selectedtrdcmpy[trct].trading_company=='All')
      {
        this.all_selected_trad_comp=true;
        this.trdcmpy='all';
        break;
      }
    }

    /* If selectedvariables length is one less than total length but all is not selected
       which means except all remaining (both expendable and returnable are selected) so make
       all_selected_in_ownrshp_catg as true and make code  send all to backend */

    if(this.all_selected_trad_comp==false && tradcomplen==true)
    {
      this.trdcmpy='all';
      this.all_selected_trad_comp=true;
    }
    console.log('Trading Company',this.trdcmpy,typeof(this.trdcmpy));

  }

  changetradcomp()
  {
    let all_selected_trad_comp=false;
    let lenlessthan1_trad_cmp=false;
    this.trdcmpy='';

    if(this.selectedtrdcmpy.length==this.tradCmpyList.length-1)
    {
      lenlessthan1_trad_cmp=true;
    }
    for(let j=0;j<this.selectedtrdcmpy.length;j++)
    {
      if(this.selectedtrdcmpy[j].trading_company=='All')
      {
        all_selected_trad_comp=true;
      }
    }

    if(all_selected_trad_comp==false && lenlessthan1_trad_cmp==true)
    {
      all_selected_trad_comp=true;
    }

    if(all_selected_trad_comp==true)
    {
      this.trdcmpy='all';
      this.selectedtrdcmpy=[];
      this.selectedtrdcmpy.push({trading_company:'All'});
    }
  }

  loadProposalStatus()
  {
    const region_code = this.transactionService.getregioncode(this.selected_region_from_list);
    this.transactionService.get_proposalstatus(region_code).subscribe(apprstat=>{
      // this.apprvr_status_list=apprstat;
      const result:search_proposalstatus[]=apprstat;
      const apprtemp:search_proposalstatus[]=[];


        apprtemp.push({approval_status:'All'})
        for(let p=0;p<result.length;p++)
        {
          if(result[p].approval_status!=null)
          {
            apprtemp.push({approval_status:result[p].approval_status});
          }
        }
        this.apprvr_status_list=JSON.parse(JSON.stringify(apprtemp));
        this.selected_approval_status=[];

    });
  }

  onhide_apprvl_status()
  {
    this.all_selected_in_approval_status=false;
    this.approval_status='';

    /* checking whether length of selected list is less than one */
    let approval_stat_len=false;
    if(this.selected_approval_status.length==this.apprvr_status_list.length-1)
    {
      approval_stat_len=true;
    }

    for(let apst=0;apst<this.selected_approval_status.length;apst++)
    {
      this.approval_status+=this.selected_approval_status[apst].approval_status;
      // if(apst < this.selected_approval_status.length-1)
      // {
      //   this.approval_status+=",";
      // }

      if(this.selected_approval_status[apst].approval_status.toLowerCase()=='all')
      {
        this.all_selected_in_approval_status=true;
        this.approval_status='all';
        break;
      }
    }

    /* If selectedvariables length is one less than total length but all is not selected
       which means except all remaining (both expendable and returnable are selected) so make
       all_selected_in_ownrshp_catg as true and make code  send all to backend */

    if(this.all_selected_in_approval_status==false && approval_stat_len==true)
    {
      this.approval_status='all';
      this.all_selected_in_approval_status=true;
    }
    console.log('Approval Status',this.approval_status,typeof(this.approval_status));

  }

  change_apprvl_status()
  {
    let all_present_in_apprvr_stat=false;
    let lenlessthan1_apprvr_stat=false;
    this.approval_status='';

    if(this.selected_approval_status.length==this.apprvr_status_list.length-1)
    {
      lenlessthan1_apprvr_stat=true;
    }
    for(let j=0;j<this.selected_approval_status.length;j++)
    {
      if(this.selected_approval_status[j].approval_status.toLowerCase()=='all')
      {
        all_present_in_apprvr_stat=true;
      }
    }

    if(all_present_in_apprvr_stat==false && lenlessthan1_apprvr_stat==true)
    {
      all_present_in_apprvr_stat=true;
    }

    if(all_present_in_apprvr_stat==true)
    {
      this.approval_status='all';
      this.selected_approval_status=[];
      this.selected_approval_status.push({approval_status:'All'});
    }

  }


  loadCOS()
  {
    const region_code = this.transactionService.getregioncode(this.selected_region_from_list);
   // this.transactionService.gettradingcompany().subscribe(sub_tradcmpy=>{
    this.transactionService.get_COS(region_code).subscribe(cos_list=>{
      console.log(cos_list);
      this.Cntr_ownershipList=[];
      this.Cntr_ownershipList.push({cos:'All'});
      // this.Cntr_ownershipList.push({cos:"[No COS]"})
      for(let trd=0;trd<cos_list.length;trd++)
      {
      this.Cntr_ownershipList.push(cos_list[trd]);
      }
      console.log('COS_LIST',this.Cntr_ownershipList)
      this.selectedcoslist=[];

    });
  }

  onhidecos()
  {
    // this.all_selected_cos=false;
    // this.cos="";
    /* checking whether length of selected list is less than one */
    let cos_list_len=false;
    if(this.selectedcoslist.length==this.selectedcoslist.length-1)
    {
      cos_list_len=true;
    }

    for(let cos_iter=0;cos_iter<this.selectedcoslist.length;cos_iter++)
    {
      this.cos_stat+=this.selectedcoslist[cos_iter].cos;
      if(cos_iter < this.selectedcoslist.length-1)
      {
        this.cos_stat+=',';
      }

      if(this.selectedcoslist[cos_iter].cos=='All')
      {
        this.all_selected_cos=true;
        this.cos_stat='all';
        break;
      }
    }

    /* If selectedvariables length is one less than total length but all is not selected
       which means except all remaining (both expendable and returnable are selected) so make
       all_selected_in_ownrshp_catg as true and make code  send all to backend */

    if(this.all_selected_cos==false && cos_list_len==true)
    {
      this.cos_stat='all';
      this.all_selected_cos=true;
    }
    console.log('Part Status',this.cos_stat,typeof(this.cos_stat));

  }

  onchangecos()
  {
    let all_selected_cos=false;
    let lenlessthan1_prt_status=false;
    this.cos_stat='';

    if(this.selectedcoslist.length==this.selectedcoslist.length-1)
    {
      lenlessthan1_prt_status=true;
    }
    for(let j=0;j<this.selectedcoslist.length;j++)
    {
      if(this.selectedcoslist[j].cos=='All')
      {
        all_selected_cos=true;
      }
    }

    if(all_selected_cos==false && lenlessthan1_prt_status==true)
    {
      all_selected_cos=true;
    }

    if(all_selected_cos==true)
    {
      this.cos_stat='all';
      this.selectedcoslist=[];
      this.selectedcoslist.push({cos:'All'});
    }
  }

  
  partDescriptionRemoveSpecialCharecter()
  {
    this.selectedpartdesc=this.selectedpartdesc.replace(/[^a-zA-Z0-9-_ ]/g,'');
  }

  search()
  {
    this.errorLoadingData="";
    this.isloadersearchprop=true;
    this.loadProposalText="";
    this.search_result="";
    this.searchpartgrid=[];
    console.log(this.selectedpartprefix);
    console.log(this.selectedpartbase);
    console.log(this.selectedpartsuffix);

    if(this.selectedpartprefix!=null && this.selectedpartprefix!='')
    {
      this.part_prefix=this.selectedpartprefix

    }
    else{
      this.part_prefix='all'
    }

    if(this.selectedpartbase!=null && this.selectedpartbase!='')
    {
      this.part_base=this.selectedpartbase

    }
    else{
      this.part_base='all'
    }

    if(this.selectedpartsuffix!=null && this.selectedpartsuffix!='')
    {
      this.part_suffix=this.selectedpartsuffix

    }
    else{
      this.part_suffix='all'
    }

    if(this.selectedpartdesc!=null && this.selectedpartdesc!='')
    {
      this.part_desc=this.selectedpartdesc

    }
    else{
      this.part_desc="all"
    }

    if(this.selectedcntrbase!=null && this.selectedcntrbase!="")
    {
      this.cntr_base=this.selectedcntrbase
    }
    else
    {
      this.cntr_base="all"
    }

    if(this.selectedcntrsuffix!=null && this.selectedcntrsuffix!="")
    {
      this.cntr_suffix=this.selectedcntrsuffix
    }
    else
    {
      this.cntr_suffix="all"
    }

    // supplier code and name

    if(this.selected_supplier != null && this.selected_supplier.suppliergsdbcode !="")
    {
      this.part_sup_code=this.selected_supplier.suppliergsdbcode;
      this.part_sup_name=this.supplierfullname;
    }
    else{
      
      this.part_sup_code="all";
      this.part_sup_name="all";
    }

    // Trading company

    if(this.trdcmpy=='')// || this.trdcmpy=="all" )
    {
      this.part_trad_cmp='all'
    }
    else
    {
      this.part_trad_cmp=this.trdcmpy;
    }

    // COS

    if(this.cos_stat=='')
    {
      this.cos_in_grid='all';
    }
    else
    {
      this.cos_in_grid=this.cos_stat;
    }



    // Supplier country
    if(this.suppliercountry=='' )// || this.suppliercountry=="all")
    {
      this.part_sp_cntry='all';
    }
    else{
      this.part_sp_cntry=this.suppliercountry;
    }



    // approval status
    if(this.approval_status=='')// || this.approval_status=="all")
    {
      this.apprv_stat_grid_inp='all';
    }
    else{
      this.apprv_stat_grid_inp=this.approval_status;
    }


    // plant

    if(this.plantcode=='' )// || this.plantcode=="all")
    {
      this.part_plant_code='all';
    }
    else{
      this.part_plant_code=this.plantcode;
    }

    // programcode

    if(this.programcodes=='')// || this.programcodes=="all")
    {
      this.part_prg_code='all';
    }
    else{
      this.part_prg_code=this.programcodes;
    }



    console.log("region in grid",this.transactionService.getregioncode(this.selected_region_from_list));
    
    console.log("prefix in grid",this.part_prefix);
    console.log("base in grid",this.part_base);
    console.log("suffix in grid",this.part_suffix);
    
    console.log("desc in grid",this.part_desc);
    console.log("container base in grid",this.cntr_base);
    console.log("container suffix in grid",this.cntr_suffix);

    console.log('supploercode in grid',this.part_sup_code);
    console.log('suplier name in grid',this.part_sup_name);
    console.log('approval status in grid',this.apprv_stat_grid_inp);
    console.log('trading company in grid',this.part_trad_cmp);
    console.log('COS in grid',this.cos_in_grid);
    console.log('suppler country in grid',this.part_sp_cntry)

    console.log('plant code in grid',this.part_plant_code);
    console.log('program code in grid',this.part_prg_code);

 this.testAPICALL();


  
  
  
    /*  this.transactionService.get_GPP_SearchPartDetails(this.userid,this.transactionService.getregioncode(this.selected_region_from_list),this.part_prefix,this.part_base,this.part_suffix,this.part_desc,this.part_plant_code,this.part_prg_code,this.part_sup_code,this.part_sp_cntry,this.part_trad_cmp,this.apprv_stat_grid_inp,this.cos_in_grid,this.cntr_base,this.cntr_suffix).subscribe(griddetls=>{
  
      // this.transactionService.getSearchPartDetails(this.transactionService.getregioncode(this.selected_region_from_list),this.apprv_stat_grid_inp,this.part_prefix,this.part_base,this.part_suffix,this.part_desc,"all",this.part_sup_code,this.part_plant_code,this.part_prg_code,this.part_sp_cntry,this.part_trad_cmp,this.userid).subscribe(griddetls=>{

      const temporaryObj:GPP_Search_Prt_grid[]=[];

      this.searchpartgridfromapi=JSON.parse(JSON.stringify(griddetls));

      console.log('searchpartgridfromapi',this.searchpartgridfromapi);
      if(this.searchpartgridfromapi!=null)
      {
        for (let gd=0;gd<this.searchpartgridfromapi.length;gd++)
        {
          //console.log('outputtesting',this.searchpartgridfromapi[gd]);



    let part_id = '';
    if(this.searchpartgridfromapi[gd].part_prefix!=null && this.searchpartgridfromapi[gd].part_prefix.trim() != '')
    {
      part_id = this.searchpartgridfromapi[gd].part_prefix+'-';
    }
    else
    {
      part_id = ' '+'-';
    }
    if(this.searchpartgridfromapi[gd].part_base!=null && this.searchpartgridfromapi[gd].part_base.trim() != '')
    {
      part_id += this.searchpartgridfromapi[gd].part_base+'-';
    }
    else
    {
      part_id += ' '+'-';
    }
    if(this.searchpartgridfromapi[gd].part_suffix!=null && this.searchpartgridfromapi[gd].part_suffix.trim() != '')
    {
      part_id += this.searchpartgridfromapi[gd].part_suffix;
    }
    else
    {
      part_id += ' ';
    }
    
    
    var cntrbaseAndSuffix=this.searchpartgridfromapi[gd].container_base;
    if(this.searchpartgridfromapi[gd].container_suffix!=null && this.searchpartgridfromapi[gd].container_suffix.trim()!="")
    {
      cntrbaseAndSuffix+="-";
      cntrbaseAndSuffix+=this.searchpartgridfromapi[gd].container_suffix;
    }
      
       

          temporaryObj.push(
            {
              
             "form_id": this.searchpartgridfromapi[gd].form_id,
             "partnumber":part_id,
              "plantgsdbcode": this.searchpartgridfromapi[gd].plant_gsdb_code,
              "partdesc": this.searchpartgridfromapi[gd].part_description,
              "partnumbercommonized": this.searchpartgridfromapi[gd].simplified_part_number,
              "proposal_status_desc": this.searchpartgridfromapi[gd].approval_status,
              "tradingcogsdbcode": this.searchpartgridfromapi[gd].trading_company_gsdb,
              "supcountrycode": this.searchpartgridfromapi[gd].supplier_country_code_sup,
              "partIntroDate": this.searchpartgridfromapi[gd].part_intro_date,
              "supplierGSDBcode":this.searchpartgridfromapi[gd].supplier_gsdb,
              "cntrbaseandsuffix":cntrbaseAndSuffix
              
            }
          )
        }
      }
        this.searchpartgrid=JSON.parse(JSON.stringify(temporaryObj));
        console.log("archive searchpartgrid",this.searchpartgrid);
        //sessionStorage.setItem("partsfromcopycntrdialog",JSON.stringify(this.searchpartgrid))
        //alert("partsfromcopycntrdialog"+JSON.stringify(this.searchpropgrid))
        this.isloadersearchprop=false;


        if(this.searchpartgrid.length==0)
        {
          this.search_result='No Match Found'
        }

    this.cols=[



      { field: 'partnumbercommonized', header:'Simplified Part Number'},
      { field: 'partdesc', header:'Part Description'},
      { field: 'partnumber', header:'Part Number'},
      { field: 'proposal_status_desc', header:'Proposal Status'},
      { field: 'supplierGSDBcode', header:'Supplier GSDB Code'},
      { field: 'cntrbaseandsuffix', header:'Container Base - Suffix'},
      { field: 'plantgsdbcode', header:'Plant GSDB Code'},
      { field: 'supcountrycode', header:'Supplier Country'},
      {field:'tradingcogsdbcode', header:'Trading Company'},
      {field:'partIntroDate', header:'Part Intro Date'},
       
    ];
    },
    (error) => {                              //Error callback
      console.error('Error in Archive Search Part API call - get_GPP_SearchPartDetails',error)
      this.errorLoadingData = "Error Occured, Please Try Again";
      this.isloadersearchprop=false;
  
    }
    )*/
  }

  async testAPICALL()
  {

   //var data:searchProposalGridFromAPI[]=[]
   var i=0;
   var loopCompleted=false;
  
   do
   {
    if(i>=0)
    {
      var tempsearchpartgridfromapi:any;
      var tempsearchpartgrid:GPP_search_grid_API[];

      let result:any= await this.transactionService.get_GPP_SearchPartDetails(this.userid,this.transactionService.getregioncode(this.selected_region_from_list),this.part_prefix,this.part_base,this.part_suffix,this.part_desc,this.part_plant_code,this.part_prg_code,this.part_sup_code,this.part_sp_cntry,this.part_trad_cmp,this.apprv_stat_grid_inp,this.cos_in_grid,this.cntr_base,this.cntr_suffix,i);
      
      var check:GPP_search_grid_API[]=[];
      tempsearchpartgridfromapi = result.map(o => Object.assign(o))
    
      console.log(tempsearchpartgridfromapi.keys.length)
      if(tempsearchpartgridfromapi!=null)//&& tempsearchpartgridfromapi.keys.length!=0
      {
        if(tempsearchpartgridfromapi[tempsearchpartgridfromapi.length-1])
        {
          i=tempsearchpartgridfromapi[tempsearchpartgridfromapi.length-1].seqno;
          loopCompleted=false;
  
          
          const temporaryObj:GPP_Search_Prt_grid[]=[];
  
  
          console.log('tempsearchpartgridfromapi',tempsearchpartgridfromapi);
          if(tempsearchpartgridfromapi!=null)
          {
            for (let gd=0;gd<tempsearchpartgridfromapi.length;gd++)
            {
              //console.log('outputtesting',this.searchpartgridfromapi[gd]);
  
  
  
              let part_id = '';
              if(tempsearchpartgridfromapi[gd].part_prefix!=null && tempsearchpartgridfromapi[gd].part_prefix.trim() != '')
              {
                part_id = tempsearchpartgridfromapi[gd].part_prefix+'-';
              }
              else
              {
                part_id = ' '+'-';
              }
              if(tempsearchpartgridfromapi[gd].part_base!=null && tempsearchpartgridfromapi[gd].part_base.trim() != '')
              {
                part_id += tempsearchpartgridfromapi[gd].part_base+'-';
              }
              else
              {
                part_id += ' '+'-';
              }
              if(tempsearchpartgridfromapi[gd].part_suffix!=null && tempsearchpartgridfromapi[gd].part_suffix.trim() != '')
              {
                part_id += tempsearchpartgridfromapi[gd].part_suffix;
              }
              else
              {
                part_id += ' ';
              }
              
              
              var cntrbaseAndSuffix=tempsearchpartgridfromapi[gd].container_base;
              if(tempsearchpartgridfromapi[gd].container_suffix!=null && tempsearchpartgridfromapi[gd].container_suffix.trim()!="")
              {
                cntrbaseAndSuffix+="-";
                cntrbaseAndSuffix+=tempsearchpartgridfromapi[gd].container_suffix;
              }
                
                
  
              temporaryObj.push(
                {
                  
                "form_id": tempsearchpartgridfromapi[gd].form_id,
                "partnumber":part_id,
                  "plantgsdbcode": tempsearchpartgridfromapi[gd].plant_gsdb_code,
                  "partdesc": tempsearchpartgridfromapi[gd].part_description,
                  "partnumbercommonized": tempsearchpartgridfromapi[gd].simplified_part_number,
                  "proposal_status_desc": tempsearchpartgridfromapi[gd].approval_status,
                  "tradingcogsdbcode": tempsearchpartgridfromapi[gd].trading_company_gsdb,
                  "supcountrycode": tempsearchpartgridfromapi[gd].supplier_country_code_sup,
                  "partIntroDate": tempsearchpartgridfromapi[gd].part_intro_date,
                  "supplierGSDBcode":tempsearchpartgridfromapi[gd].supplier_gsdb,
                  "cntrbaseandsuffix":cntrbaseAndSuffix
                  
                }
              )
            }
          }
          tempsearchpartgrid=JSON.parse(JSON.stringify(temporaryObj));
          console.log("archive temp searchpartgrid",tempsearchpartgrid);
  
          //Object.assign(this.searchpartgrid,tempsearchpartgrid)
          this.searchpartgrid.push(...tempsearchpartgrid);
        }
    
      }
    }
    else
    {
      i=-1;
      loopCompleted=true;
    }
   }while(i>=0 && tempsearchpartgridfromapi.length==1000)

    /*if(loopCompleted==true)
    {*/
      this.isloadersearchprop=false;


      if(this.searchpartgrid.length==0)
      {
        this.search_result='No Match Found'
      }

     this.cols=[



      { field: 'partnumbercommonized', header:'Simplified Part Number'},
      { field: 'partdesc', header:'Part Description'},
      { field: 'partnumber', header:'Part Number'},
      { field: 'proposal_status_desc', header:'Proposal Status'},
      { field: 'supplierGSDBcode', header:'Supplier GSDB Code'},
      { field: 'cntrbaseandsuffix', header:'Container Base - Suffix'},
      { field: 'plantgsdbcode', header:'Plant GSDB Code'},
      { field: 'supcountrycode', header:'Supplier Country'},
      {field:'tradingcogsdbcode', header:'Trading Company'},
      {field:'partIntroDate', header:'Part Intro Date'},
       
      ];
   // }
   
   /* var result:GPP_Search_Prt_grid[]=[];

    var a=[];
    var i=0;
    a.push(i);

    from(a)
    .pipe(
      concatMap(currentItem => {
       console.log("value of i",i);
        return this.transactionService.get_GPP_SearchPartDetails(this.userid,this.transactionService.getregioncode(this.selected_region_from_list),this.part_prefix,this.part_base,this.part_suffix,this.part_desc,this.part_plant_code,this.part_prg_code,this.part_sup_code,this.part_sp_cntry,this.part_trad_cmp,this.apprv_stat_grid_inp,this.cos_in_grid,this.cntr_base,this.cntr_suffix)
      }),
      tap(data=>{
        if(data.length>500)
        {
         ++i;
         retry();
        }
      }
      )
        
    )
    .subscribe((data) => {
      Array.prototype.push.apply(result, data);
      if(data.length>500)
        {
          ++i;
          retry();
        }
    },
    (error) => {                              //Error callback
      console.error('Error in Archive Search Part API call - get_GPP_SearchPartDetails',error)
      this.errorLoadingData = "Error Occured, Please Try Again";
      this.isloadersearchprop=false;
  
    },
    ()=>{
     
      
      const temporaryObj:GPP_Search_Prt_grid[]=[];

      this.searchpartgridfromapi=JSON.parse(JSON.stringify(result));

      console.log('searchpartgridfromapi',this.searchpartgridfromapi);
      if(this.searchpartgridfromapi!=null)
      {
        for (let gd=0;gd<this.searchpartgridfromapi.length;gd++)
        {
          //console.log('outputtesting',this.searchpartgridfromapi[gd]);



    let part_id = '';
    if(this.searchpartgridfromapi[gd].part_prefix!=null && this.searchpartgridfromapi[gd].part_prefix.trim() != '')
    {
      part_id = this.searchpartgridfromapi[gd].part_prefix+'-';
    }
    else
    {
      part_id = ' '+'-';
    }
    if(this.searchpartgridfromapi[gd].part_base!=null && this.searchpartgridfromapi[gd].part_base.trim() != '')
    {
      part_id += this.searchpartgridfromapi[gd].part_base+'-';
    }
    else
    {
      part_id += ' '+'-';
    }
    if(this.searchpartgridfromapi[gd].part_suffix!=null && this.searchpartgridfromapi[gd].part_suffix.trim() != '')
    {
      part_id += this.searchpartgridfromapi[gd].part_suffix;
    }
    else
    {
      part_id += ' ';
    }
    
    
    var cntrbaseAndSuffix=this.searchpartgridfromapi[gd].container_base;
    if(this.searchpartgridfromapi[gd].container_suffix!=null && this.searchpartgridfromapi[gd].container_suffix.trim()!="")
    {
      cntrbaseAndSuffix+="-";
      cntrbaseAndSuffix+=this.searchpartgridfromapi[gd].container_suffix;
    }
      
       

          temporaryObj.push(
            {
              
             "form_id": this.searchpartgridfromapi[gd].form_id,
             "partnumber":part_id,
              "plantgsdbcode": this.searchpartgridfromapi[gd].plant_gsdb_code,
              "partdesc": this.searchpartgridfromapi[gd].part_description,
              "partnumbercommonized": this.searchpartgridfromapi[gd].simplified_part_number,
              "proposal_status_desc": this.searchpartgridfromapi[gd].approval_status,
              "tradingcogsdbcode": this.searchpartgridfromapi[gd].trading_company_gsdb,
              "supcountrycode": this.searchpartgridfromapi[gd].supplier_country_code_sup,
              "partIntroDate": this.searchpartgridfromapi[gd].part_intro_date,
              "supplierGSDBcode":this.searchpartgridfromapi[gd].supplier_gsdb,
              "cntrbaseandsuffix":cntrbaseAndSuffix
              
            }
          )
        }
      }
        this.searchpartgrid=JSON.parse(JSON.stringify(temporaryObj));
        console.log("archive searchpartgrid",this.searchpartgrid);
        //sessionStorage.setItem("partsfromcopycntrdialog",JSON.stringify(this.searchpartgrid))
        //alert("partsfromcopycntrdialog"+JSON.stringify(this.searchpropgrid))
        this.isloadersearchprop=false;


        if(this.searchpartgrid.length==0)
        {
          this.search_result='No Match Found'
        }

    this.cols=[



      { field: 'partnumbercommonized', header:'Simplified Part Number'},
      { field: 'partdesc', header:'Part Description'},
      { field: 'partnumber', header:'Part Number'},
      { field: 'proposal_status_desc', header:'Proposal Status'},
      { field: 'supplierGSDBcode', header:'Supplier GSDB Code'},
      { field: 'cntrbaseandsuffix', header:'Container Base - Suffix'},
      { field: 'plantgsdbcode', header:'Plant GSDB Code'},
      { field: 'supcountrycode', header:'Supplier Country'},
      {field:'tradingcogsdbcode', header:'Trading Company'},
      {field:'partIntroDate', header:'Part Intro Date'},
       
    ];

      ; 
    } ); */
  }

  clearFilters()
  {
    this.selectedpartprefix="";
    this.selectedpartbase="";
    this.selectedpartsuffix="";
    this.selectedpartdesc="";

    this.selectedcntrbase="";
    this.selectedcntrsuffix="";
    
    this.errorLoadingData = "";
    
    this.selected_suppliercountry=[];
    this.suppliercountry='';

    this.selected_plant_codes_list=[];
    this.selected_plant_code={plantgsdbcode: "" }
    this.plantcode="";
    this.selected_programs_list=[];
    this.programcodes='';
    this.plantcodeselected=false;
    this.selectedtrdcmpy=[];
    this.trdcmpy='';



      this.selected_approval_status=[];
      this.approval_status='';


    this.selectedcoslist=[];
    this.cos_stat='';



    this.selected_approval_status=[];
    this.approval_status='';
    // this.selected_supplier={supcode:'',fullname:'',supplierid:''};
    // this.suupliercode=""
    // this.supplierfullname="";

    this.selected_supplier = {suppliergsdbcode:''}

    if(this.regionlist.length!=0)
    {
      if(this.regionlist.filter(reg=>reg.value==this.current_region))
      {
        this.selected_region_from_list=this.current_region;
      }
    }

    
    this.loadPlantCode();
    this.loadsupplierdetails();
    this.loadtradingcompany();
    this.loadSupplierCountry();
    this.loadProposalStatus();
    this.loadCOS();
    

    this.searchpartgrid=[];
    this.loadProposalText="";
 
  }

  loadproposal(rowData){

    console.log(rowData);
    if(rowData.form_id != null)
    {
      console.log(rowData.form_id);
      if(this.prevFormId != null && this.prevFormId.trim() != "" && this.prevFormId == rowData.form_id)
    {
      console.log("else formid")
      this.enable=true;
    }
    else{

    this.loadProposalText=""
    this.formId=""
    this.formlink="";
    this.historylink="";
    console.log("Plantcode", rowData.plantgsdbcode);
    console.log("Form ID",rowData.form_id);
    this.disableDownloadPdfButton=true;
    this.disableDownloadHistoryButton=true;
    this.isloaderloadFormprop=true;
    this.isloaderloadHistoryprop=true
    this.loadFormPDFerrorText=""
    this.loadHistoryPDFerrorText=""

    if(rowData.plantgsdbcode != null && rowData.plantgsdbcode.trim() != "" && rowData.form_id != null && rowData.form_id.trim() != "")
    {


    let form_link = this.base_url_get_archieve_report+this.base_path_archive_report+rowData.plantgsdbcode+'/'+'Form1271_'+rowData.form_id+'.pdf';
    let history_link = this.base_url_get_archieve_report+this.base_path_archive_report+rowData.plantgsdbcode+'/'+'History_'+rowData.form_id+'.pdf';

    this.formId=rowData.form_id;
    this.transactionService.getPdfFile(form_link).subscribe((res) => {
      this.formlink = URL.createObjectURL(res);
    }, error => {
      this.loadFormPdferror();
      this.loadHistoryPdferror();
    });
    this.transactionService.getPdfFile(history_link).subscribe((res) => {
      this.historylink = URL.createObjectURL(res);
    }, error => {
      this.loadFormPdferror();
      this.loadHistoryPdferror();
    });
    this.prevFormId=rowData.form_id;
      
    console.log("Form Link", this.formlink);
    console.log("History Link",this.historylink);

    //window.open(form_link);

    this.enable=true;

    }

    else
    {
    this.isloaderloadFormprop=false;
    this.isloaderloadHistoryprop=false;
    console.log("Either plant code or form id is not correct")
    if(rowData.partnumbercommonized != null && rowData.partnumbercommonized.trim() != "")
    {
      this.loadProposalText="Error Occured, cannot load Form 1271 for Part Number :"+"rowData.partnumbercommonized";
    }
    else
    {
      this.loadProposalText="Error Occured, cannot load Form 1271";
    }
    
    
    }
    }
}
else
{
  this.enable=true;
  this.formId=""
  this.formlink="";
  this.historylink="";
  this.loadProposalText="Error occured, Incoorect Form Id";
  console.log("Form Id Null");
}
    
  }

  loadFormPdfComplete()
  {
    this.disableDownloadPdfButton=false;
    this.isloaderloadFormprop=false;
  }
  loadFormPdferror()
  {
    this.loadFormPDFerrorText="Form missing or Error occured";
    this.isloaderloadFormprop=false;
  }
  
  loadHistoryComplete()
  {
    this.disableDownloadHistoryButton=false;
    this.isloaderloadHistoryprop=false;
  }
  loadHistoryPdferror()
  {
    this.loadHistoryPDFerrorText="Revision History missing or Error occured ";
    this.isloaderloadHistoryprop=false;
  }

  downloadArchivePdf()
  {
    console.log("downloadArchivePdf fn ");
    this.downloadPdfText=""

    var links=[];
    if(!this.disableDownloadPdfButton)
    {
      links.push( {
        link_name: this.formlink,
         doc_name:'Form1271_'+this.formId+'.pdf'})
    }
    else
    {
      this.downloadPdfText="No Form 1271 to download."
    }
    if(!this.disableDownloadHistoryButton)
    {
    links.push({
        link_name: this.historylink,
       doc_name: 'History_'+this.formId+'.pdf'})
    }
    else
    {
      this.downloadPdfText+="No Revision History to download."
    }


       if(links.length != 0)
       {
        links.forEach(link=>{
          this.http.get(link.link_name,{ responseType:'blob'}).subscribe(data=>{
          const blob = new Blob([data],{ type : 'application/pdf'});
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href=url;
          a.download=link.doc_name;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          
          })
          })
       }
       


  }
}
function forcecast(result: any) {
  throw new Error('Function not implemented.');
}

