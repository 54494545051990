/* tslint:disable */
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { analyzeFileForInjectables, CompileShallowModuleMetadata } from '@angular/compiler';
import { temporaryAllocator } from '@angular/compiler/src/render3/view/util';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { defaultCipherList } from 'constants';
import { copyFile } from 'fs';
import { Table } from 'primeng';
import { ConfirmationService, MenuItem, MessageService, TreeNode } from 'primeng/api';
import { Observable } from 'rxjs';
import { TransactionService } from '../loginpage/Transaction.service';
import { addContainer, addlncompdetls, addlncomponentIds, cntrandsudetailspost, compdetailsfortype01to05, compmaterialpost, componentlist, componenttypewithcodeanddesc, containerDetailsCntrAdmin, containerMaterial, containerMaterialList, containerTypeCode, partDetailForPost, regiondetails, releaselevelList, selec_prt_pro_details, storecomponent, storecomponentforpost, transportList, transportListWithActiveStatus } from '../loginpage/transactions';
import { PackagingprocessComponent } from '../packagingprocess/packagingprocess.component';
import { PackagingproposalComponent } from '../packagingproposal/packagingproposal.component';
import { EepackproposaltabtwoComponent } from '../eepackproposaltabtwo/eepackproposaltabtwo.component';
import { EepartsandatributesComponent } from '../eepartsandatributes/eepartsandatributes.component';

@Component({
  selector: 'app-eepackproposaltabthree',
  templateUrl: './eepackproposaltabthree.component.html',
  styleUrls: ['./eepackproposaltabthree.component.css'],
  providers:[MessageService,EepackproposaltabtwoComponent,ConfirmationService]
})
export class EepackproposaltabthreeComponent implements OnInit {


  name_ee:string;
  tab4_ee:string;
  tab4menuitem_ee:MenuItem;
  flag_ee:boolean;
  region_ee:string;



  region_code_ee:string;


  id_proposal_compmat_ee:number;
  noSupDialog_compmat_ee=false;
  content_supp_compmat_ee:string;
  noPrtDialog_compmat_ee=false;
  content_selectedprt_compmat_ee:string;
  roleid_compmat_ee:number;
  designsource_code_compmat_ee:string;
  supp_code_compmat_ee:string;
  noDesignsourceDialog_compmat_ee=false;
content_Designsource_compmat_ee='';


noroleselectedDialog_ee=false;
  norolecontent_ee='';

  cntrbasedisp_ee = '';
  cntrsuffixdisp_ee = '';




  comp_material_forpost:compmaterialpost={
    id_proposal:0,
  cntr_shp_unit:'',
  shp_unt_tar_wt:0.000,
  shp_unt_gross_wt:0.000,
  cntr_gross_wt:0.000,
  ld_unload_inst:'',
  cntr_automtn_intf:'No',
  transp_tested:'No',
  tst_reprt_num:'',
  mot:'',
  dangrs_good:'No',
  dangrs_good_ship_detls:'-',
  rel_level:''
}


disbale_gds_details_value_ee=true;
disable_reports_value_ee=true;
// selected_mode_transport_ee:transportList={	cd_transport: "",transport_desc: ""};
// transport_list_ee:transportList[]=[];
selected_mode_transport_ee:transportListWithActiveStatus={	cd_transport: 0,transport_desc: '',active_stat:'',updated_dt:'',updated_by:''};
transport_list_ee:transportListWithActiveStatus[]=[];
selected_release_level_ee:releaselevelList={releaselevel:'',releaselevel_desc:''}
releaselevel_list_ee:releaselevelList[]=[];
flagc_ee=false;


  tab1route_ee:string;
  tab2route_ee:string;
  tab3route_ee:string;
  tab4route_ee:string;
  selectedpart_submitter_tab3_ee:selec_prt_pro_details={simplifiedpartno:'-',proposalid:0,bompartmasterid:0,plantgsdbcode:'',proposallastsaved:0,part_status:'',supplier_code:'',proposallastsaved_ee:1,is_ee_proposal:true,currentview_ee:true,from_draft:false,propsl_type:'',imp_part_status:''};
  current_region_code_prt_ee:any;
  erg_lim_frm_reg_admin:regiondetails={
    cd_region: '',
    cntr_ergo_limit:0,
    bom_err_thld: 0,
    allow_nonstd_comp_ind: '',
    urge_prt_notice_email_freq: 0,
    allow_multi_prt_prop_ind: '',
    limit_prts_recvd_plt: '',
    emg_exp_pkg_reqd_ind: '',
    pkg_rel_level_src: '',
    suspd_duratn_thld:0,
    uom:''
  }

  cntrorshpunitradio_ee='Container';
  componentOrAid_ee='Component';
  exp_returnable_ee='E';
  rowadded_ee:boolean;
  rowedited_ee:boolean;


  region_Details_comp:regiondetails={
    cd_region: '',
    cntr_ergo_limit:0,
    bom_err_thld: 0,
    allow_nonstd_comp_ind: '',
    urge_prt_notice_email_freq: 0,
    allow_multi_prt_prop_ind: '',
    limit_prts_recvd_plt: '',
    emg_exp_pkg_reqd_ind: '',
    pkg_rel_level_src: '',
    suspd_duratn_thld:0,
    uom:''
  }


  is_bom_resource_ee=false;

  /*********************************Use these details from API**************************************************************** */
  shippingunittareweight_ee=0;
  shippingunitgrossweight_ee=0;
  containergrossweight_ee=0;
  temp_cntr_gross_weight_ee=0;
  temp_shp_unt_tare_wt_ee=0;
  no_of_container_ee=0;
  deleteflagbeforesave_ee=false;
  deleteflag_ee=false; // variable will be set when the tab is already saved and they are deleting componnets
                            // so even when populate grid is called in delete function weight not will be calculated from grid

  containergrossweightInKGS_ee=0;
  shippingunitgrossweightInKGS_ee=0;
  /*********************************Use these details from API**************************************************************** */

 // cntrMatr_ee:containerMaterial[]=[];
  // selected_material_ee:containerMaterial;


  cntrMatr_ee:containerMaterialList[]=[];
  selected_material_ee:containerMaterialList;

  componenentassociatedwithcontainer_ee:compdetailsfortype01to05[]=[];
  componentdetails_ee:storecomponent={
    id_proposal:0,
    id_comp:0,
    comp_base:'',
    comp_suffix:'',
    comp_desc:'',
    qt_per_min_su:0,
    comp_ret_type:'',
    updated_by:'',
    updated_dt:'',
    comp_l:0,
    comp_w:0,
    comp_h:0,
    comp_tar_wt:0,
    nestedfolderHt:0,
    comp_code:'',
    comp_ownershp:'',
    comp_material:'',
    tooledcomp:'',
    is_compulsary:'',
    compselctnfor:'',

    /* added */
    comp_bsd:'',
    comp_lwh:'',
    typ_no:0

  };  // varaible to store componeny details when component is selected from component number list

  componentdetailsforedit_ee:storecomponent={
    id_proposal:0,
    id_comp:0,
    comp_base:'',
    comp_suffix:'',
    comp_desc:'',
    qt_per_min_su:0,
    comp_ret_type:'',
    updated_by:'',
    updated_dt:'',
    comp_l:0,
    comp_w:0,
    comp_h:0,
    comp_tar_wt:0,
    nestedfolderHt:0,
    comp_code:'',
    comp_ownershp:'',
    comp_material:'',
    tooledcomp:'',
    is_compulsary:'',
    compselctnfor:'',

    /* added */
    comp_bsd:'',
    comp_lwh:'',
    typ_no:0

  };
  componentdetailsfordelete_ee:storecomponent;
 componentdetailsgrid_ee:storecomponent[]=[]; //variable used to populate rows in Grid
//componentdetailsgrid:Observable<any>;


componentsStoredInProposal:storecomponent[]=[];
componentsFetchedFromCMMS:compdetailsfortype01to05[]=[];
compulsoryComponentFromCMMS:storecomponentforpost[]=[];

  additionalcomponentIDs_ee:addlncomponentIds[]=[];
  additionalcomponentDetails_ee:addlncompdetls;
  alladdlncompdetls_ee:addlncompdetls[]=[];

  http_ee:HttpClient

  comp_typ_list_ee:TreeNode[] // List of component type to be showed in componenttype dropdown
  items_ee:TreeNode[]

  comp_type_ee:containerTypeCode[]; // variable to get component type from API
  comp_type_and_desc_ee:componenttypewithcodeanddesc[];
  comp_type_and_desc_master_ee:componenttypewithcodeanddesc[];
  comp_type_desc_ee=''

  val_ee:TreeNode; // selected component type from UI
  component_type_ee:componenttypewithcodeanddesc={cd_cntr_typ:'',cntr_typ_and_desc:'',cntr_typ_desc:'',typ_no:0}; // selected component type from UI is assigned here

  componentlistbasedontype_ee:storecomponent[]=[]; // variable that has component number list
  componentnumberselected_ee:storecomponent; // component number selected from component number list box
  tempcomps_ee:storecomponent[]=[];
  tempcomplist_ee:componentlist[]=[];
  tempo_ee:storecomponentforpost[];

  displaydialog_ee:boolean; // variable to control the component details dialog
  displayeditdialog_ee:boolean;
  compulsorycompdialog_ee:boolean;
  compulsorycomponents_ee:storecomponentforpost[]=[];

  contadmindetls_ee:containerDetailsCntrAdmin;

  cols_ee:any;
  cols2_ee:any;
  frozenCols_ee:any;

  editcancel_click_ee=false;

  variableforsave_ee:storecomponentforpost;

  edit_measure_comp_ee='lbs';
  edit_measure_comp_add_ee='lbs/inch';
  edit_measure_comp_gridedit_ee='lbs/inch';

  unitom_ee = '';
  editComponentTypeDisplay_ee='';

  disableExpReturnable=false;

  sucontarewtInvalid_ee = false;
  sucontarewtInvalidReason_ee: string = null;
  sugrosswtInvalid_ee = false;
  sugrossewtInvalidReason_ee: string = null;
  cntrgrosswtInvalid_ee = false;
  cntrgrosswtInvalidReason_ee: string = null;

  comp_descInValid_ee = false;
  comp_descInValidReason_ee: string = null;
  comp_l_InValid_ee = false;
  comp_l_InValidReason_ee: string = null;
  comp_w_InValid_ee = false;
  comp_w_InValidReason_ee: string = null;
  comp_h_InValid_ee = false;
  comp_h_InValidReason_ee: string = null;
  comp_tare_wgtInValid_ee = false;
  comp_tare_wgtInValidReason_ee: string = null;
  qt_pershp_untInValid_ee = false;
  qt_pershp_untInValidReason_ee: string= null;
  ownrshpInValid_ee = false;
  ownrshpInValidReason_ee: string = null;
  // tooledCompInValid_ee: boolean = false;
  // tooledCompInValidReason_ee: string = null;
  selectMaterialInValid_ee = false;
  selectMaterialInValidReason_ee: string = null;
  sumwgts_InValid_ee = false;
  sumwgts_InValidReason_ee: string = null;

  totl_wt_InValid_ee = false;
  totl_wt_InValidReason_ee: string = null;

  comp_totl_wt_InValid_ee = false;
  comp_totl_wt_InValidReason_ee: string = null;

  num_of_cntr_InValid_ee = false;

  shp_comp_wt_add_ee = 0;
  temp_shp_compwt_add_ee = 0;

  shp_comp_wt_edit_ee = 0;
  temp_shp_compwt_edit_ee = 0;

  temp_comwgt_add_ee = 0;
  temp_comwgt_edit_ee = 0;


  comb_wgt_ee: number;
  comb_wgtadd_ee : number;

  cntr_comp_wt=0;
  shp_comp_wt=0;
  temp_shp_compwt = 0;

  temp_comwgt = 0;
  temp_shpcomwgt = 0;

  enable_savecomps = true;
  save_comdets = false;

  wgt_flag = true;
  shippingunittareweight_ee_display:number=0;
   shippingunitgrossweight_ee_display:number=0;
   containergrossweight_ee_display:number=0;
   
   
  shippingUnitExteriorHeightDisplay_ee:number=0;
  shippingUnitExteriorHeightCalculated_ee:number=0;
  initialShippingUnitExteriorHeightCalculated_ee:number=0;
  Tab2DataForPost_ee:cntrandsudetailspost;
  noLayersPerShippingUnit_ee:number=0;
  compAddedChangeHeight_ee:boolean=false;
  compEdidtedChangeHeight_ee:boolean=false;
  compCompulsuryAdded_ee:boolean=false;
  suexthgtInvalid_ee:boolean=false;
  suexthgtInValidReason_ee="";
  originalComponentDetailsForEdit_ee:storecomponent={       
    id_proposal:0,
    id_comp:0,
    comp_base:"",
    comp_suffix:"",
    comp_desc:"",
    qt_per_min_su:0,
    comp_ret_type:"",
    updated_by:"",
    updated_dt:"",
    comp_l:0,
    comp_w:0,
    comp_h:0,
    comp_tar_wt:0,
    nestedfolderHt:0,
    comp_code:"",
    comp_ownershp:"",
    comp_material:"",
    tooledcomp:"",
    is_compulsary:"",
    compselctnfor:"",

    /* added */
    comp_bsd:"",
    comp_lwh:"",
    typ_no:0
  }

  shippinUnitNestedFoldedHeight_ee:number=0;
  suNestedHtInValid_ee:boolean=false;
  suNestedHtInValidReason_ee:string=null;

  constructor(private transactionService:TransactionService, private router:Router,private packagingproposal:PackagingproposalComponent,private partsandattri:EepartsandatributesComponent,private packproposaltabtwo:EepackproposaltabtwoComponent,
    private confirmationService:ConfirmationService,private datepipe:DatePipe,private packagingprocess:PackagingprocessComponent){}


  ngOnInit(): void {
    this.region_ee=this.transactionService.getregion();


    this.region_code_ee=this.transactionService.getregioncode(this.region_ee);

    this.editcancel_click_ee=false;

    this.releaselevel_list_ee=[
      {releaselevel_desc:'1-Shipping Unit',releaselevel:'1'},
      {releaselevel_desc:'2-Level Layer',releaselevel:'2'},
      {releaselevel_desc:'3-Container/Carton',releaselevel:'3'},
      {releaselevel_desc:'4-Released (ILVS/CBR/PIA)',releaselevel:'4'},
  ]





  this.selected_release_level_ee=this.releaselevel_list_ee[0];
  this.comp_material_forpost.rel_level=this.releaselevel_list_ee[0].releaselevel

  console.log(this.selected_release_level_ee)
  this.selectedpart_submitter_tab3_ee = this.transactionService.getpartno_packpro(this.transactionService.getregion());


console.log('this.selectedpart_submitter_tab3',this.selectedpart_submitter_tab3_ee)

console.log(this.selectedpart_submitter_tab3_ee)


    this.tab1route_ee=this.transactionService.getregionurlvalue(this.region_ee)+'packagingprocess/proposalcreation/eepartsandattri';
    this.tab2route_ee=this.transactionService.getregionurlvalue(this.region_ee)+'packagingprocess/proposalcreation/eepackproposaltabtwo';
    this.tab3route_ee=this.transactionService.getregionurlvalue(this.region_ee)+'packagingprocess/proposalcreation/eepackproposaltabthree';
    this.tab4route_ee=this.transactionService.getregionurlvalue(this.region_ee)+'packagingprocess/proposalcreation/eepackproposaltabfour';
    this.selectedpart_submitter_tab3_ee = this.transactionService.getpartno_packpro(this.transactionService.getregion());

    console.log('this.selectedpart_submitter_tab3',this.selectedpart_submitter_tab3_ee)
    console.log('this.savedtab',this.packagingproposal.savedtab);

    this.packagingproposal.savedtab=this.packagingproposal.savedtab;


        if(this.packagingproposal.savedtab==3 || this.packagingproposal.savedtab==4 )
        {
          for(const i of this.packagingproposal.tabpackagingproposalmenuitems.values())
          {
                console.log(i);
                if(i.label!='EE-Images/Photos & Comments')
                {
                    this.packagingproposal.flag3_ee=true;
                }
                else{
                    this.packagingproposal.flag3_ee=false;
                    break;
                }
          }

          if(this.packagingproposal.flag3_ee)
          {
            // this.packagingproposal.tabpackagingproposalmenuitems.push({label: 'COS, Container & Shipping Unit', routerLink:'/northamerica/proposalcreation/packproposaltabtwo', icon: 'fas fa-person-booth'});
           // this.packagingproposal.tabpackagingproposalmenuitems.push({label:'Component & Material',routerLink:'/northamerica/proposalcreation/packproposaltabthree', icon: 'fas fa-person-booth'});
           this.packagingproposal.tabpackagingproposalmenuitems.push({label:'EE-Images/Photos & Comments',routerLink:this.tab4route_ee, icon: 'pi pi-images'});}
        }


        this.roleid_compmat_ee=this.transactionService.getcurrentrole(this.region_ee)
        console.log(this.roleid_compmat_ee)



        if(this.roleid_compmat_ee==null ||(this.roleid_compmat_ee != 1 && this.roleid_compmat_ee != 2))
        {


          this.noroleselectedDialog_ee=true;
          this.norolecontent_ee='No role selected '
        }
        if(this.roleid_compmat_ee==1)
        {
        this.supp_code_compmat_ee = this.transactionService.get_supplier(this.region_ee);
        console.log(this.supp_code_compmat_ee);
        }
        else if( this.roleid_compmat_ee==2)
        {
          this.designsource_code_compmat_ee = this.transactionService.get_designsource(this.region_ee);
          console.log(this.designsource_code_compmat_ee)
        }

        // this.transactionService.storepage(this.current_region_contandcos, "packagingprocess/proposalcreation/packproposaltabtwo");
        // this.selectedpart_submitter=this.submitterdashboard.pushdata();

         // Getting Region detais and freezing UOM

         this.transactionService.get_region_details(this.region_code_ee).subscribe(unitype=>{
          this.unitom_ee = unitype.uom;
          console.log('UOMREGTAB3',this.unitom_ee);

          if(this.unitom_ee == 'E')
          {
            this.edit_measure_comp_ee = "lbs/inch";
            this.edit_measure_comp_add_ee = 'lbs/inch';
            this.edit_measure_comp_gridedit_ee = 'lbs/inch';
            console.log('UOMLB3',this.unitom_ee,this.edit_measure_comp_ee,this.edit_measure_comp_add_ee,this.edit_measure_comp_gridedit_ee);
          }
          else
          {
            this.edit_measure_comp_ee = "kg/cm";
            this.edit_measure_comp_add_ee = 'kg/cm';
            this.edit_measure_comp_gridedit_ee = 'kg/cm';
            console.log('UOMKG3',this.unitom_ee,this.edit_measure_comp_ee,this.edit_measure_comp_add_ee,this.edit_measure_comp_gridedit_ee);
          }
        });

        // Get details TAB2
        this.post_tab3init_ee();


        console.log(this.selectedpart_submitter_tab3_ee)

        if(this.roleid_compmat_ee==1)
        {

        if (this.supp_code_compmat_ee != null && this.selectedpart_submitter_tab3_ee != null && this.selectedpart_submitter_tab3_ee.simplifiedpartno!='-' && this.selectedpart_submitter_tab3_ee.proposalid!=0 ) {

          this.id_proposal_compmat_ee=this.selectedpart_submitter_tab3_ee.proposalid;
          console.log(this.id_proposal_compmat_ee)
          // this.get_transport_list_ee();
          this.get_erg_limit_ee();

          this.compmateriallistinit_ee();
          this.componentdetailsinit();
          this.componentdetailseditinit();
          this.componentdeletedetailsinit();
          this.displaydialog_ee=false;
          this.displayeditdialog_ee=false;
          this.compulsorycompdialog_ee=false;
          this.getcntrtypelist_ee();
          this.compInValidationInit_ee();


          // get tab3 details if last saved proposal is greater than current
          if(this.selectedpart_submitter_tab3_ee.proposallastsaved_ee>2)
          {
            this.getcomponentassociatedforcntr_ee();
            // this.populateListOfMaterialsGrid([]);

            this.transactionService.loadtab3detailsfromAPI_ee(this.selectedpart_submitter_tab3_ee.proposalid).subscribe(tab3output=>{
              let tab3detailsoutput:compmaterialpost;
              tab3detailsoutput=tab3output;
              console.log('Tab3output',tab3detailsoutput);
              this.cntrorshpunitradio_ee=tab3detailsoutput.cntr_shp_unit;
              this.shippingunittareweight_ee=tab3detailsoutput.shp_unt_tar_wt;
              this.shippingunitgrossweight_ee=tab3detailsoutput.shp_unt_gross_wt;
              this.containergrossweight_ee=tab3detailsoutput.cntr_gross_wt;

              this.comp_material_forpost.id_proposal=tab3detailsoutput.id_proposal;
              this.comp_material_forpost.cntr_shp_unit=this.cntrorshpunitradio_ee;
              this.comp_material_forpost.shp_unt_tar_wt=this.shippingunittareweight_ee;
              this.comp_material_forpost.shp_unt_gross_wt=this.shippingunitgrossweight_ee;
              this.comp_material_forpost.cntr_gross_wt=this.containergrossweight_ee;
              this.comp_material_forpost.cntr_automtn_intf=tab3detailsoutput.cntr_automtn_intf;
              this.comp_material_forpost.transp_tested=tab3detailsoutput.transp_tested;
              this.comp_material_forpost.tst_reprt_num=tab3detailsoutput.tst_reprt_num;
              this.comp_material_forpost.dangrs_good=tab3detailsoutput.dangrs_good;
              // this.comp_material_forpost.dangrs_good_ship_detls=tab3detailsoutput.dangrs_good_ship_detls;
              this.comp_material_forpost.dangrs_good_ship_detls="-";
              this.comp_material_forpost.rel_level=tab3detailsoutput.rel_level;



              // this.disable_gds_details_ee();
              this.disable_report_ee();

              this.comp_material_forpost.mot=tab3detailsoutput.mot;


              this.comp_material_forpost.ld_unload_inst=tab3detailsoutput.ld_unload_inst;


              for(let ir=0; ir<this.releaselevel_list_ee.length; ir++)
              {
                if(tab3detailsoutput.rel_level==this.releaselevel_list_ee[ir].releaselevel)
                {

                  this.selected_release_level_ee=this.releaselevel_list_ee[ir];
                }
              }


              this.transactionService.get_region_details(this.region_code_ee).subscribe(reg_det=>{
                console.log(reg_det);


                this.region_Details_comp=reg_det;
                console.log(this.region_Details_comp.pkg_rel_level_src);
                if(this.region_Details_comp.pkg_rel_level_src.toLowerCase()=='bom')
                {
                  this.is_bom_resource_ee=true;
                }
                else{
                  this.is_bom_resource_ee=false;
                }

              })
              // check and include code forload/unload and mode of transport alone


              // for(var i=0; i<this.transport_list_ee.length; i++)
              // {

              //   if(tab3detailsoutput.mot.toLowerCase()==this.transport_list_ee[i].transport_desc.toLowerCase())
              //   {

              //   this.selected_mode_transport_ee=this.transport_list_ee[i];
              //   }


              // }


            })

          }
          else{
            this.getcomponentassociatedforcntr_ee();
            this.transactionService.get_region_details(this.region_code_ee).subscribe(reg_det=>{
              console.log('regdet',reg_det);


              this.region_Details_comp=reg_det;
              console.log(this.region_Details_comp.pkg_rel_level_src);
              if(this.region_Details_comp.pkg_rel_level_src.toLowerCase()=='bom')
              {
                const grid1_prim_parts:partDetailForPost[]=[];



                this.transactionService.get_draft_selectedparts_data(this.selectedpart_submitter_tab3_ee.proposalid).subscribe(selectedparts_data_comp=>{


                  console.log('tab1data',selectedparts_data_comp);
                  if(selectedparts_data_comp!=null && selectedparts_data_comp.length!=0)
                  {



                    for(let pri=0;pri<selectedparts_data_comp.length;pri++)
                    {
                      if(selectedparts_data_comp[pri].isprimary.toLowerCase()=='yes')
                      {
                        grid1_prim_parts.push(selectedparts_data_comp[pri]);
                        break;

                      }
                    }

                    console.log(grid1_prim_parts[0].cmmspackagingreleaselevel);


                    if(grid1_prim_parts[0].cmmspackagingreleaselevel!=null && grid1_prim_parts[0].cmmspackagingreleaselevel!=' ')
                    {


                      this.is_bom_resource_ee=true;


                      for(let ir=0; ir<this.releaselevel_list_ee.length; ir++)
                     {
                       if(grid1_prim_parts[0].cmmspackagingreleaselevel==this.releaselevel_list_ee[ir].releaselevel)
                         {

                           this.selected_release_level_ee=this.releaselevel_list_ee[ir];
                          }
                     }


                    }


                    else{


                      this.is_bom_resource_ee=false;
                      this.selected_release_level_ee=this.releaselevel_list_ee[0];


                    }



                  }


                  else{


                    this.is_bom_resource_ee=false;
                      this.selected_release_level_ee=this.releaselevel_list_ee[0];


                  }
                })

              }
              else{
                this.is_bom_resource_ee=false;
                this.selected_release_level_ee=this.releaselevel_list_ee[0];

              }

            })
          }


        }

        else if (this.supp_code_compmat_ee == null) {
          this.noSupDialog_compmat_ee = true;
          this.content_supp_compmat_ee = 'Please select supplier';
        }


        else if (this.supp_code_compmat_ee != null && (this.selectedpart_submitter_tab3_ee == null || this.selectedpart_submitter_tab3_ee.simplifiedpartno=='-' || this.selectedpart_submitter_tab3_ee.proposalid==0)) {
          this.noPrtDialog_compmat_ee = true;
          this.content_selectedprt_compmat_ee = 'Please select part from submitter dashboard';

        }
        }
        else if (this.roleid_compmat_ee==2)
        {



        if (this.designsource_code_compmat_ee != null && this.selectedpart_submitter_tab3_ee != null && this.selectedpart_submitter_tab3_ee.simplifiedpartno!='-' && this.selectedpart_submitter_tab3_ee.proposalid!=0 ) {

          this.id_proposal_compmat_ee=this.selectedpart_submitter_tab3_ee.proposalid;

          // this.get_transport_list_ee();
          this.get_erg_limit_ee();
          this.compmateriallistinit_ee();

          this.componentdetailsinit();
          this.componentdetailseditinit();
          this.componentdeletedetailsinit();
          this.displaydialog_ee=false;
          this.displayeditdialog_ee=false;
          this.compulsorycompdialog_ee=false;
          this.getcntrtypelist_ee();
          this.compInValidationInit_ee();
          // this.getcomponentassociatedforcntr();

         // get tab3 details if last saved proposal is greater than current
        // get tab3 details if last saved proposal is greater than current
        if(this.selectedpart_submitter_tab3_ee.proposallastsaved_ee>2)
        {

          this.getcomponentassociatedforcntr_ee();
          // this.populateListOfMaterialsGrid([]);

          this.transactionService.loadtab3detailsfromAPI_ee(this.selectedpart_submitter_tab3_ee.proposalid).subscribe(tab3output=>{
            let tab3detailsoutput:compmaterialpost;
            tab3detailsoutput=tab3output
            this.cntrorshpunitradio_ee=tab3detailsoutput.cntr_shp_unit;
            this.shippingunittareweight_ee=tab3detailsoutput.shp_unt_tar_wt;
            this.shippingunitgrossweight_ee=tab3detailsoutput.cntr_gross_wt;
            this.containergrossweight_ee=tab3detailsoutput.cntr_gross_wt;
            this.comp_material_forpost.cntr_automtn_intf=tab3detailsoutput.cntr_automtn_intf;
            this.comp_material_forpost.transp_tested=tab3detailsoutput.transp_tested;
            this.comp_material_forpost.tst_reprt_num=tab3detailsoutput.tst_reprt_num;
            this.comp_material_forpost.dangrs_good=tab3detailsoutput.dangrs_good;
            // this.comp_material_forpost.dangrs_good_ship_detls=tab3detailsoutput.dangrs_good_ship_detls;
            this.comp_material_forpost.dangrs_good_ship_detls="-";
            this.comp_material_forpost.rel_level=tab3detailsoutput.rel_level;


            // this.disable_gds_details_ee();
            this.disable_report_ee();

            // check and include code forload/unload and mode of transport alone
            this.comp_material_forpost.mot=tab3detailsoutput.mot;

            this.comp_material_forpost.ld_unload_inst=tab3detailsoutput.ld_unload_inst;


            for(let ir=0; ir<this.releaselevel_list_ee.length; ir++)
            {
              if(tab3detailsoutput.rel_level==this.releaselevel_list_ee[ir].releaselevel)
              {

                this.selected_release_level_ee=this.releaselevel_list_ee[ir];
              }
            }

            this.transactionService.get_region_details(this.region_code_ee).subscribe(reg_det=>{
              console.log(reg_det);


              this.region_Details_comp=reg_det;
              console.log(this.region_Details_comp.pkg_rel_level_src);
              if(this.region_Details_comp.pkg_rel_level_src.toLowerCase()=='bom')
              {
                this.is_bom_resource_ee=true;
              }
              else{
                this.is_bom_resource_ee=false;
              }

            })

            // check and include code forload/unload and mode of transport alone


            // for(var i=0; i<this.transport_list_ee.length; i++)
            // {

            //   if(tab3detailsoutput.mot.toLowerCase()==this.transport_list_ee[i].transport_desc.toLowerCase())
            //   {

            //   this.selected_mode_transport_ee=this.transport_list_ee[i];
            //   }


            // }




          })
        }
        else{
          this.getcomponentassociatedforcntr_ee();


          this.transactionService.get_region_details(this.region_code_ee).subscribe(reg_det=>{
            console.log(reg_det);


            this.region_Details_comp=reg_det;
            console.log(this.region_Details_comp.pkg_rel_level_src);
            if(this.region_Details_comp.pkg_rel_level_src.toLowerCase()=='bom')
            {
              const grid1_prim_parts:partDetailForPost[]=[];



              this.transactionService.get_draft_selectedparts_data(this.selectedpart_submitter_tab3_ee.proposalid).subscribe(selectedparts_data_comp=>{


                console.log(selectedparts_data_comp);
                if(selectedparts_data_comp!=null && selectedparts_data_comp.length!=0)
                {



                  for(let pri=0;pri<selectedparts_data_comp.length;pri++)
                  {
                    if(selectedparts_data_comp[pri].isprimary.toLowerCase()=='yes')
                    {
                      grid1_prim_parts.push(selectedparts_data_comp[pri]);
                      break;

                    }
                  }

                  console.log(grid1_prim_parts[0].cmmspackagingreleaselevel);


                  if(grid1_prim_parts[0].cmmspackagingreleaselevel!=null && grid1_prim_parts[0].cmmspackagingreleaselevel!=' ')
                  {


                    this.is_bom_resource_ee=true;


                    for(let ir=0; ir<this.releaselevel_list_ee.length; ir++)
                   {
                     if(grid1_prim_parts[0].cmmspackagingreleaselevel==this.releaselevel_list_ee[ir].releaselevel)
                       {

                         this.selected_release_level_ee=this.releaselevel_list_ee[ir];
                        }
                   }


                  }


                  else{


                    this.is_bom_resource_ee=false;
                    this.selected_release_level_ee=this.releaselevel_list_ee[0];


                  }



                }


                else{


                  this.is_bom_resource_ee=false;
                    this.selected_release_level_ee=this.releaselevel_list_ee[0];


                }
              })

            }
            else{
              this.is_bom_resource_ee=false;
              this.selected_release_level_ee=this.releaselevel_list_ee[0];

            }

          })

        }

        }

        else if (this.designsource_code_compmat_ee == null) {
          this.noDesignsourceDialog_compmat_ee = true;
          this.content_Designsource_compmat_ee = 'Please select Design source';
        }


        else if (this.designsource_code_compmat_ee != null && (this.selectedpart_submitter_tab3_ee == null || this.selectedpart_submitter_tab3_ee.simplifiedpartno=='-' || this.selectedpart_submitter_tab3_ee.proposalid==0)) {
          this.noPrtDialog_compmat_ee = true;
          this.content_selectedprt_compmat_ee = 'Please select part from submitter dashboard';
        }

        }

          }



          tab3InValidationInit_ee()
          {
                 this.sucontarewtInvalid_ee = false;
                  this.sucontarewtInvalidReason_ee= null;
                  this.sugrosswtInvalid_ee = false;
                  this.sugrossewtInvalidReason_ee= null;
                  this.cntrgrosswtInvalid_ee = false;
                  this.cntrgrosswtInvalidReason_ee= null;
		  this.suexthgtInvalid_ee=false;
                  this.suexthgtInValidReason_ee=null;
		  this.suNestedHtInValid_ee=false;
                  this.suNestedHtInValidReason_ee=null;
  
          }

          compInValidationInit_ee()
          {
                  this.comp_descInValid_ee = false;
                  this.comp_descInValidReason_ee = null;
                  this.comp_l_InValid_ee = false;
                  this.comp_l_InValidReason_ee= null;
                  this.comp_w_InValid_ee = false;
                  this.comp_w_InValidReason_ee= null;
                  this.comp_h_InValid_ee= false;
                  this.comp_h_InValidReason_ee= null;
                  this.comp_tare_wgtInValid_ee= false;
                  this.comp_tare_wgtInValidReason_ee = null;
                  this.qt_pershp_untInValid_ee = false;
                  this.qt_pershp_untInValidReason_ee = null;
                  this.ownrshpInValid_ee = false;
                  this.ownrshpInValidReason_ee = null;
                  // this.tooledCompInValid_ee = false;
                  // this.tooledCompInValidReason_ee = null;
                  this.selectMaterialInValid_ee = false;
                  this.selectMaterialInValidReason_ee = null;
                  this.sumwgts_InValid_ee = false;
                  this.sumwgts_InValidReason_ee = null;
                  this.totl_wt_InValid_ee = false;
                  this.totl_wt_InValidReason_ee = null;
                  this.comp_totl_wt_InValid_ee = false;
                  this.comp_totl_wt_InValidReason_ee = null;
                  this.num_of_cntr_InValid_ee = false;

          }

        ok_noroledialog_ee()
        {
          let url
          url=this.transactionService.getregionurlvalue(this.region_ee);
          url+='packagingprocessdashboard'
          this.router.navigate([url]);

        }




          // if no supplier present

          ok_nosupdialog_ee() {
            console.log(this.region_ee)
            let reg_url: string
            reg_url = this.transactionService.getregionurlvalue(this.region_ee);
            reg_url += 'packagingprocess/packsupplieradmin/packsupplier';

            this.router.navigate([reg_url]);

          }


          ok_noDesignsourcedialog_ee() {
            console.log(this.region_ee)
            let reg_url: string
            reg_url = this.transactionService.getregionurlvalue(this.region_ee);
            reg_url += 'packagingprocess/ppackdesignsourceadmin/packdesignsource';

            this.router.navigate([reg_url]);

          }



          go_to_supp_main_page_ee() {
            let reg_supp_url: string;
            reg_supp_url = this.transactionService.getregionurlvalue(this.region_ee);
            if(this.roleid_compmat_ee==1)
            {
              reg_supp_url += 'packagingprocess/packsupplieradmin/packsupplier';
            }
            else if ( this.roleid_compmat_ee==2)
            {
              reg_supp_url += 'packagingprocess/packdesignsourceadmin/packdesignsource';
            }
            this.router.navigate([reg_supp_url]);

          }

          // if no part present
          ok_noprtdialog_ee() {
            console.log(this.region_ee)

            let reg_url_prt: string
            reg_url_prt = this.transactionService.getregionurlvalue(this.region_ee);
            this.name_ee=this.transactionService.getregionNameInSmallLetters();
            const proposalFor=sessionStorage.getItem('proposaltypefor');
            if(proposalFor!=null && proposalFor != 'null' && proposalFor.toLowerCase()=='migration')
            {
              if(this.roleid_compmat_ee==1)
              {
                this.router.navigate(['/'+this.name_ee+'/packagingprocess/packsupplieradmin/migrationsubmitter'])
              }
              else if(this.roleid_compmat_ee==2)
              {
                this.router.navigate(['/'+this.name_ee+'/packagingprocess/packdesignsourceadmin/migrationsubmitter'])
              }
            }
            else // if(this.selectedpart_submitter.propsl_type!=null && this.selectedpart_submitter.propsl_type.toLowerCase()=="normal")
            {
            if(this.roleid_compmat_ee==1)
            {
            reg_url_prt += 'packagingprocess/packsupplieradmin/submiterdashboard';
            }
            else if ( this.roleid_compmat_ee==2)
            {
              reg_url_prt += 'packagingprocess/packdesignsourceadmin/submiterdashboard';
            }

            this.router.navigate([reg_url_prt]);
          }

          }


          post_tab3init_ee()
          {


            this.comp_material_forpost={


              id_proposal:0,
          cntr_shp_unit:'',
          shp_unt_tar_wt:0.000,
          shp_unt_gross_wt:0.000,
          cntr_gross_wt:0.000,
          ld_unload_inst:'',
          cntr_automtn_intf:'No',
          transp_tested:'No',
          tst_reprt_num:'',
          mot:'',
          dangrs_good:'No',
          dangrs_good_ship_detls:'-',
          rel_level:''

            }


            // this.disable_gds_details_ee();
            this.disable_report_ee();
            this.comp_material_forpost.rel_level=this.releaselevel_list_ee[0].releaselevel

          }


          // get_transport_list_ee()
          // {


          //   this.transactionService.gettransportlist().subscribe(translist=>{

          //     this.transport_list_ee=translist;
          //     this.selected_mode_transport_ee=this.transport_list_ee[0]

          //   })



          // }


          // disable_gds_details_ee()
          // {
          //   console.log(this.comp_material_forpost.dangrs_good);
          //   if(this.comp_material_forpost.dangrs_good.toLowerCase()=="yes")
          //   {
          //  this.disbale_gds_details_value_ee=false;
          //   }
          //   else if(this.comp_material_forpost.dangrs_good.toLowerCase()=="no"){

          //     this.disbale_gds_details_value_ee=true;
          //     this.comp_material_forpost.dangrs_good_ship_detls=""

          //   }
          // }

          disable_report_ee()
          {


            console.log(this.comp_material_forpost.transp_tested);
            if(this.comp_material_forpost.transp_tested.toLowerCase()=='yes')
            {
           this.disable_reports_value_ee=false;
            }
            else if(this.comp_material_forpost.transp_tested.toLowerCase()=='no'){

              this.disable_reports_value_ee=true;
              this.comp_material_forpost.tst_reprt_num=''

            }



          }


  // Code ends

  get_erg_limit_ee()
  {
    this.transactionService.get_region_details(this.region_code_ee).subscribe(regdata=>
      {
        this.erg_lim_frm_reg_admin=regdata;
        console.log('erg_lim_frm_reg_admin',this.erg_lim_frm_reg_admin);
      });
  }



  componentdetailsinit()
  {
    this.componentdetails_ee=
    {
      id_proposal:this.selectedpart_submitter_tab3_ee.proposalid,
      id_comp:0,
      comp_base:'',
      comp_suffix:'',
      comp_desc:'',
      qt_per_min_su:0,
      comp_ret_type:'',
      updated_by:'',
      updated_dt:'',
      comp_l:0,
      comp_w:0,
      comp_h:0,
      comp_tar_wt:0,
      nestedfolderHt:0,
      comp_code:'',
      comp_ownershp:'',
      comp_material:'',
      tooledcomp:'',
      is_compulsary:'',
      compselctnfor:'',

      /* added */
      comp_bsd:'',
      comp_lwh:'',
      typ_no:0


    }
  }

  componentdetailseditinit()
  {
    this.componentdetailsforedit_ee=
    {
      id_proposal:this.selectedpart_submitter_tab3_ee.proposalid,
      id_comp:0,
      comp_base:'',
      comp_suffix:'',
      comp_desc:'',
      qt_per_min_su:0,
      comp_ret_type:'',
      updated_by:'',
      updated_dt:'',
      comp_l:0,
      comp_w:0,
      comp_h:0,
      comp_tar_wt:0,
      nestedfolderHt:0,
      comp_code:'',
      comp_ownershp:'',
      comp_material:'',
      tooledcomp:'',
      is_compulsary:'',
      comp_lwh:'',
      comp_bsd:'',
      compselctnfor:'',
      typ_no:0

    }
  }
  expendableContainerRestriction_ee()
  {
    if(this.contadmindetls_ee!=null && this.contadmindetls_ee.exp_refundable.toLowerCase()=='expendable')
    {
      if(this.cntrorshpunitradio_ee=='Container')
      {
        // alert("came here")
        this.exp_returnable_ee='E'
        this.disableExpReturnable=true;
      }
      else
      {
        this.disableExpReturnable=false;
      }
    }
    else
    {
      this.disableExpReturnable=false;
    }
  }

  getcomponentassociatedforcntr_ee()
  {
    this.transactionService.loadtab2detailsifproposalexistalready_ee(this.selectedpart_submitter_tab3_ee.proposalid).subscribe(results=>
      {
        let tab2_data=results;
        var cntr_suffix="";

        if(tab2_data.cntr_suff=="" || tab2_data.cntr_suff==null)
        {
          cntr_suffix="nosuffix";
        }
        else
        {
          cntr_suffix=tab2_data.cntr_suff;
        }

        this.cntrbasedisp_ee = tab2_data.cntr_base;

            if(tab2_data.cntr_suff=="" || tab2_data.cntr_suff==null)
            {
              this.cntrsuffixdisp_ee = '';
              console.log('SUFFIXEE',this.cntrsuffixdisp_ee);
            }
            else
            {
              this.cntrsuffixdisp_ee = tab2_data.cntr_suff;
              console.log('SUFFIXEE1',this.cntrsuffixdisp_ee);
            }


        // setting shipping unit tare weight and container gross weight columns

        // if(this.selectedpart_submitter_tab3.proposallastsaved_ee<=2)
        // {
          console.log('cd_cntr',tab2_data.cd_cntr);
          console.log("tab 2 data",tab2_data);
          this.Tab2DataForPost_ee=tab2_data;

          this.shippingUnitExteriorHeightDisplay_ee=0;
          if(tab2_data.shp_unt_ext_height != null)
          {
          this.shippingUnitExteriorHeightDisplay_ee=tab2_data.shp_unt_ext_height;
          }
          this.shippingUnitExteriorHeightDisplay_ee=this.transactionService.roundValueToNDecimal(this.shippingUnitExteriorHeightDisplay_ee,3);
  
          this.noLayersPerShippingUnit_ee=tab2_data.max_layrs_shp_unt-tab2_data.empty_trays_cntr_layrs;
       
          this.shippingUnitExteriorHeightCalculated_ee=tab2_data.cntr_height* this.noLayersPerShippingUnit_ee;
          //this.shippingUnitExteriorHeightCalculated=this.transactionService.roundValueToNDecimal(this.shippingUnitExteriorHeightCalculated,3);
          this.initialShippingUnitExteriorHeightCalculated_ee=this.shippingUnitExteriorHeightCalculated_ee;

              this.shippinUnitNestedFoldedHeight_ee=0;
            if(this.shippinUnitNestedFoldedHeight_ee != null)
            {
            this.shippinUnitNestedFoldedHeight_ee=this.Tab2DataForPost_ee.shp_unt_nestd_fld_height;
            }
          this.transactionService.editbutton_get_contr_details(tab2_data.cntr_base,tab2_data.cd_cntr).subscribe(contadminout=>{
            this.contadmindetls_ee=contadminout;
            console.log('contadmindetls',this.contadmindetls_ee);
            this.expendableContainerRestriction_ee();
            //this.shippingunittareweight_ee=this.contadmindetls_ee.shpg_unit_tare_wt;

             /* In tab2ee expendable contaiers are made editable so details has to be taken from tab 2 ee instead of container admin */
            // this.no_of_container_ee=this.contadmindetls_ee.cntr_per_layer*(this.contadmindetls_ee.max_layers_shpg_unit-tab2_data.empty_trays_cntr_layrs);
            this.no_of_container_ee=tab2_data.cntr_per_layer*(tab2_data.max_layrs_shp_unt-tab2_data.empty_trays_cntr_layrs);

            this.shippingunittareweight_ee=this.no_of_container_ee*tab2_data.cntr_tar_weight;
            this.shippingunittareweight_ee_display=this.transactionService.roundValueToNDecimal(this.shippingunittareweight_ee,3);
            
              this.transactionService.get_draft_selectedparts_data(this.selectedpart_submitter_tab3_ee.proposalid).subscribe(tab1out=>{
              const tab1_data=tab1out;
              let partweight=0;
              for(const hk of tab1_data)
              {
                if(hk.isprimary.toLowerCase() == 'yes')
                {
                partweight+=hk.ngpp_part_wt;
                break;
                }
              }
             this.containergrossweight_ee=tab2_data.cntr_tar_weight+(tab2_data.parts_per_cntr*partweight);

             // trunc grossweight
             console.log('container grossweight before Round Off',this.containergrossweight_ee)
             this.containergrossweight_ee_display=this.transactionService.roundValueToNDecimal(this.containergrossweight_ee,3)
             console.log('container grossweight after Round Off',this.containergrossweight_ee)

             //this.shippingunitgrossweight_ee=(this.no_of_container_ee*this.containergrossweight_ee)+this.shippingunittareweight_ee;
             this.shippingunitgrossweight_ee=(this.no_of_container_ee*this.containergrossweight_ee);

  	         console.log('shippingunitgrossweight grossweight before Round Off',this.shippingunitgrossweight_ee)
             this.shippingunitgrossweight_ee_display=this.transactionService.roundValueToNDecimal(this.shippingunitgrossweight_ee,3)
             console.log('shippingunitgrossweight grossweight after Round Off',this.shippingunitgrossweight_ee)

             this.temp_cntr_gross_weight_ee=this.containergrossweight_ee;
             this.temp_shp_unt_tare_wt_ee=this.shippingunittareweight_ee;
             // alert("Container gross weight"+this.containergrossweight);
             // alert("Shipping unit gross weight"+this.shippingunitgrossweight);
              if(this.selectedpart_submitter_tab3_ee.proposallastsaved_ee<=2)
              {
                // this.containergrossweight=tab2_data.cntr_tar_weight+(tab2_data.parts_per_cntr*partweight);

               // this.temp_cntr_gross_weight=this.containergrossweight;

              // this.shippingunitgrossweight=(this.no_of_container*this.containergrossweight)+this.shippingunittareweight;
              }
            })


        // }

        // adding compulsory components if exists

          this.transactionService.getcomponentdetailsbybaseandsuffix(tab2_data.cntr_base,cntr_suffix,this.contadmindetls_ee.ownrshp_strat_code,this.region_code_ee).subscribe(out=>
          {

            this.componenentassociatedwithcontainer_ee=out;

            console.log('componnetassociatewithcontainer',this.componenentassociatedwithcontainer_ee);
            const compulsorycomponent:storecomponentforpost[]=[];
            let i=0;

            if(this.componenentassociatedwithcontainer_ee)
            {
              for (const comp of this.componenentassociatedwithcontainer_ee)
              {
                let c;
                // var componentweight=comp.comp_tare_wt*comp.qt_per_min_su;
                if(comp.is_compulsary=='Y')
                {
                  if(comp.comp_ret_type=='Returnable')
                {
                  c='R';
                }
                else
                {
                  c='E';
                }
                var ownershp_ee;
                if(comp.comp_ownershp != null && comp.comp_ownershp.toLowerCase() == "ford")
                {
                  ownershp_ee = "Ford";
                }
                else if(comp.comp_ownershp != null && comp.comp_ownershp.toLowerCase().indexOf("supp")>-1)
                {
                  ownershp_ee = "Supplier";
                }
                else if(comp.comp_ownershp != null && (comp.comp_ownershp.toLowerCase() == "tpt" || comp.comp_ownershp.toLowerCase() == "third party"))
                {
                  ownershp_ee = "Third Party";
                }
                  compulsorycomponent.push(
                    {
                      id_proposal:this.selectedpart_submitter_tab3_ee.proposalid,
                      id_comp:0,
                      comp_base:comp.no_comp_base,
                      comp_suffix:comp.no_comp_suffix,
                      comp_desc:comp.comp_desc,
                      qt_per_min_su:comp.qt_per_min_su,
                      comp_ret_type:c,
                      upated_by:this.transactionService.getcdsid(),
                      updated_dt:new Date().toISOString().split('T')[0],
                      comp_l:comp.comp_l,
                      comp_w:comp.comp_w,
                      comp_h:comp.comp_h,
                      comp_tare_wt:comp.comp_tare_wt,
                      nestedfolderht:comp.nestedfolderht,
                      comp_code:comp.comp_code,
                      comp_ownershp:ownershp_ee,
                      comp_material:comp.comp_material,
                      tooledcomp:"No",

                      // comp_bsd:comp.no_comp_base+"-"+comp.no_comp_suffix+"-"+comp.comp_desc,
                    // comp_lwh:comp.comp_l+"x"+comp.comp_w+"x"+comp.comp_h
                   // is_compulsary:comp.is_compulsary,
                    is_compulsary:'Yes',
                    compselctnfor:'Shipping Unit',
                    material_flag:'',
                    typ_no:comp.typ_no
                    // material flag added
                    });
                    i++;
                  }

                }
            }
            if(i>0) // && this.selectedpart_submitter_tab3.proposallastsaved_ee<=2)
            {
              let addedcompslist:storecomponentforpost[]=[];
              this.compulsorycomponents_ee=compulsorycomponent; // will have all compulsory components associated with selected container
              console.log('came in compulsory component',this.compulsorycomponents_ee);

              /* calling getaddedcomponentdetailsfortab3 function to check whether this compulsory components
                are already added to DB. This is done to ensure even when the same page reloads, the same compulsory component
                will not be added to DB again
              */
              this.transactionService.getaddedcomponentdetailsfortab3_ee(this.selectedpart_submitter_tab3_ee.proposalid).subscribe(addedcompsout=>
              {
                addedcompslist=addedcompsout;
                console.log('addedcompslist',addedcompslist);
                // alert("addedcompslist"+addedcompslist.length);
                let ccomp;
                for(ccomp=0; ccomp<this.compulsorycomponents_ee.length; ccomp++)
                {


                  if(addedcompslist.length==0 || ( addedcompslist.length>0 && (!(addedcompslist.some(el=> el.comp_base.toLowerCase()===this.compulsorycomponents_ee[ccomp].comp_base.toLowerCase() && el.comp_suffix.toLowerCase()===this.compulsorycomponents_ee[ccomp].comp_suffix.toLowerCase()))) ))// || addedcompslist.length==0)
                  {
                    console.log('comp comp before save',this.compulsorycomponents_ee[ccomp]);
                    this.compCompulsuryAdded_ee=true;
                    this.transactionService.storecomponentdetails_ee(this.compulsorycomponents_ee[ccomp]).subscribe(storecompout=>{
                      const compstorestatus=storecompout;
                      console.log('Success',compstorestatus);
                      if(compstorestatus=='Success')
                      {
                        this.packagingproposal.addtoast({severity:'success', summary:'Success', detail:'Compulsory Component saved Successfully'})
                        this.populateListOfMaterialsGrid_ee(this.compulsorycomponents_ee[ccomp]);
                        this.populateGrid_ee();
                      }
                      else
                      {
                      this.packagingproposal.addtoast({severity:'error', summary:'Error', detail:' Component Details not saved'});
                      }
                    });
                  }

                  else
                  {
                    this.populateListOfMaterialsGrid_ee([]);
                    this.populateGrid_ee();
                  }

                }

             });
            }
            else
            {
            this.populateGrid_ee();
            }


          });
        });
      });
  }


  componentdeletedetailsinit()
  {
    this.componentdetailsforedit_ee=
    {
      id_proposal:this.selectedpart_submitter_tab3_ee.proposalid,
      id_comp:0,
      comp_base:'',
      comp_suffix:'',
      comp_desc:'',
      qt_per_min_su:0,
      comp_ret_type:'',
      updated_by:'',
      updated_dt:'',
      comp_l:0,
      comp_w:0,
      comp_h:0,
      comp_tar_wt:0,
      nestedfolderHt:0,
      comp_code:'',
      comp_ownershp:'',
      comp_material:'',
      tooledcomp:'',
      is_compulsary:'',
      comp_lwh:'',
      comp_bsd:'',
      compselctnfor:'',
      typ_no:0


    }
  }

  compmateriallistinit_ee()
  {
    /* below Not used
    this.transactionService.getAllMaterialList().subscribe(cntrmatrial=>
      {
        if(cntrmatrial.length!=0 && cntrmatrial!=null)
        {
          this.cntrMatr_ee=cntrmatrial.filter(ele=>ele.active_stat.toLowerCase()=='yes');
          this.selected_material_ee=this.cntrMatr_ee[0];
        }

      }
    );*/
  }


  edit_comp_details_ee(rowData:storecomponent)
  {
    // this.edit_measure_comp_gridedit_ee="lbs/inch"
    if(this.unitom_ee == 'E')
    {
      this.edit_measure_comp_gridedit_ee = 'lbs/inch';
    }
    else
    {
      this.edit_measure_comp_gridedit_ee = 'kg/cm';
    }

    this.componentdetailsforedit_ee=JSON.parse(JSON.stringify(rowData));
    this.originalComponentDetailsForEdit_ee=JSON.parse(JSON.stringify(rowData));
    this.comp_type_desc_ee='';

      console.log('rowData',rowData)

      this.comb_wgt_ee = rowData.qt_per_min_su*rowData.comp_tar_wt;
      console.log('comb_wgt',this.comb_wgt_ee);

      console.log('comp type list',this.comp_type_and_desc_master_ee)
      if(this.comp_type_and_desc_master_ee != null && this.comp_type_and_desc_master_ee.length != 0)
      {
        if(rowData.typ_no != null)
        {
        const typeIndex=this.comp_type_and_desc_master_ee.findIndex(elcmp=>elcmp.typ_no == rowData.typ_no)
        }
        else
        {
          const typeIndex=this.comp_type_and_desc_master_ee.findIndex(elcmp=>String(elcmp.cd_cntr_typ) == String(rowData.comp_code))
        }

          this.selected_material_ee={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''}
        // @ts-ignore
          if(typeIndex != -1)
        {
          // @ts-ignore
            this.comp_type_desc_ee=this.comp_type_and_desc_master_ee[typeIndex].cntr_typ_and_desc
          // @ts-ignore
            console.log('comp type no ',this.comp_type_and_desc_master_ee[typeIndex])
            console.log(rowData.comp_ret_type);


            // @ts-ignore
            this.transactionService.getContainerMaterial(this.comp_type_and_desc_master_ee[typeIndex].typ_no,rowData.comp_ret_type).subscribe( cntr_mat=>{
              console.log(cntr_mat);
              this.cntrMatr_ee=[];
              this.cntrMatr_ee=cntr_mat;



              if(this.cntrMatr_ee!= null && this.cntrMatr_ee.length!=0)
              {

              for ( const i of this.cntrMatr_ee)
              {
                if(i.cntr_matr_name==rowData.comp_material)
                {
                  this.selected_material_ee=i;
                  break;
                }

              }
            }

            })

            // use above two to get container material details and map with container list
            // api getContainerMaterial(type no, ret type)
        }
          else
        {
          this.selected_material_ee={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''}
        }
      }
      else
      {
        this.selected_material_ee={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''}
      }

        this.editComponentTypeDisplay_ee='';
      if(this.comp_type_and_desc_master_ee != null && this.comp_type_and_desc_master_ee.length != 0)
      {
          if(rowData.typ_no != null)
          {
            const compTypeIndex = this.comp_type_and_desc_master_ee.findIndex(elcmptyp=>elcmptyp.typ_no == rowData.typ_no)
          }
          else
          {
            const compTypeIndex=this.comp_type_and_desc_master_ee.findIndex(elcmp=>String(elcmp.cd_cntr_typ) == String(rowData.comp_code))
          }

        // @ts-ignore
          if(compTypeIndex != -1)
        {
          // @ts-ignore
            this.editComponentTypeDisplay_ee=this.comp_type_and_desc_master_ee[compTypeIndex].cntr_typ_desc
        }

      }

    console.log('componentdetailsforedit',this.componentdetailsforedit_ee);
    this.displaydialog_ee=false;
    this.displayeditdialog_ee=true;

    this.editcancel_click_ee=false;
    this.compInValidationInit_ee();
  }

  on_change_measure_comp_add_ee()
  {
    console.log(this.edit_measure_comp_add_ee);
    console.log(this.componentdetails_ee);

    if(this.unitom_ee == 'E')
    {
      this.edit_measure_comp_add_ee = 'lbs/inch';
      console.log('UOMLBON',this.unitom_ee,this.edit_measure_comp_ee,this.edit_measure_comp_add_ee,this.edit_measure_comp_gridedit_ee);
    }
    else
    {
      this.edit_measure_comp_add_ee = 'kg/cm';
      console.log('UOMKGON',this.unitom_ee,this.edit_measure_comp_ee,this.edit_measure_comp_add_ee,this.edit_measure_comp_gridedit_ee);
    }



    // if(this.edit_measure_comp_add_ee=="lbs/inch")
    // {
    //   if(this.componentdetails_ee.comp_l!=null)
    //   {
    //     this.componentdetails_ee.comp_l=Math.round((0.393701*this.componentdetails_ee.comp_l)*1000)/1000;

    //   }
    //   if(this.componentdetails_ee.comp_w!=null)
    //   {
    //     this.componentdetails_ee.comp_w=Math.round((0.393701*this.componentdetails_ee.comp_w)*1000)/1000;

    //   }
    //   if(this.componentdetails_ee.comp_h!=null)
    //   {
    //     this.componentdetails_ee.comp_h=Math.round((0.393701*this.componentdetails_ee.comp_h)*1000)/1000;

    //   }
    //   if(this.componentdetails_ee.nestedfolderHt!=null)
    //   {
    //     this.componentdetails_ee.nestedfolderHt=Math.round((0.393701*this.componentdetails_ee.nestedfolderHt)*1000)/1000;

    //   }
    //   if(this.componentdetails_ee.comp_tar_wt!=null)
    //   {
    //     this.componentdetails_ee.comp_tar_wt=Math.round((2.20462*this.componentdetails_ee.comp_tar_wt)*1000)/1000;

    //   }


    // }

    // else if(this.edit_measure_comp_add_ee=="kg/cm")
    // {



    //   if(this.componentdetails_ee.comp_l!=null)
    //   {
    //     this.componentdetails_ee.comp_l=Math.round((2.54*this.componentdetails_ee.comp_l)*1000)/1000;

    //   }
    //   if(this.componentdetails_ee.comp_w!=null)
    //   {
    //     this.componentdetails_ee.comp_w=Math.round((2.54*this.componentdetails_ee.comp_w)*1000)/1000;

    //   }
    //   if(this.componentdetails_ee.comp_h!=null)
    //   {
    //     this.componentdetails_ee.comp_h=Math.round((2.54*this.componentdetails_ee.comp_h)*1000)/1000;

    //   }
    //   if(this.componentdetails_ee.nestedfolderHt!=null)
    //   {
    //     this.componentdetails_ee.nestedfolderHt=Math.round((2.54*this.componentdetails_ee.nestedfolderHt)*1000)/1000;

    //   }
    //   if(this.componentdetails_ee.comp_tar_wt!=null)
    //   {
    //     this.componentdetails_ee.comp_tar_wt=Math.round((0.453592*this.componentdetails_ee.comp_tar_wt)*1000)/1000;

    //   }



    // }


  }

  comp_tarwgt_shpunitwgt_validation_add_ee()
  {

    console.log('Code_check_in_save',this.componentdetails_ee.compselctnfor)
    if(this.componentdetails_ee.compselctnfor == 'Container' && (this.component_type_ee.cd_cntr_typ > '05' && this.component_type_ee.cd_cntr_typ <'16'))
    {
      this.comb_wgtadd_ee = this.no_of_container_ee*(this.componentdetails_ee.comp_tar_wt * this.componentdetails_ee.qt_per_min_su);
    }
    else
    {
      if(this.component_type_ee.cd_cntr_typ > '05' && this.component_type_ee.cd_cntr_typ <'16')
      {
        this.comb_wgtadd_ee = this.componentdetails_ee.comp_tar_wt * this.componentdetails_ee.qt_per_min_su;
        console.log('Inside the comb_add wgt of ShipUnit_ee', this.comb_wgtadd_ee)
      }
    }
    // console.log("Inside count",this.componentdetailsgrid.length)
    // if(this.comb_wgtadd_ee < 1 || this.comb_wgtadd_ee > 999.9999)
    // {
    //   this.sumwgts_InValid_ee = true;
    //   this.sumwgts_InValidReason_ee = "The individual component weight should not exceed 999.9999";
    //   console.log("Check1",this.comb_wgtadd_ee);
    // }
    // else
    // {
    //   this.sumwgts_InValid_ee = false;
    //   this.sumwgts_InValidReason_ee = null;
    //   console.log("CheckNot1",this.comb_wgtadd_ee);
    // }

    // this.expAidsWgtValidationForAdd();


    // this.comp_totl_wt_InValid = false;


  }

  comp_tarwgt_shpunitwgt_validation_edit_ee()
  {
    this.comb_wgt_ee = this.componentdetailsforedit_ee.comp_tar_wt * this.componentdetailsforedit_ee.qt_per_min_su;


    console.log('Inside_compeditcombwgt_ee',this.comb_wgt_ee);
    // if(this.comb_wgt_ee < 1 || this.comb_wgt_ee > 9.999)
    // {
    //   this.sumwgts_InValid_ee = true;
    //   this.sumwgts_InValidReason_ee = "The individual component weight should not exceed 999.9999";

    // }
    // else
    // {
    //   this.sumwgts_InValid_ee = false;
    //   this.sumwgts_InValidReason_ee = null;

    // }
  }
  compDescInValidationForAdd_ee()
  {
    if(this.componentdetails_ee.comp_desc == null || this.componentdetails_ee.comp_desc.trim() == '')
    {
      this.comp_descInValid_ee=true;
      this.comp_descInValidReason_ee='Required';
    }
    else if(this.componentdetails_ee.comp_desc.length < 1 ||  this.componentdetails_ee.comp_desc.length > 99.999)
    {
      this.comp_descInValid_ee=true;
      this.comp_descInValidReason_ee='Should be of range 1 to 99.999';
    }
    else
    {
      this.comp_descInValid_ee=false;
      this.comp_descInValidReason_ee=null;
    }
  }
  compLenInValidationForAdd_ee()
  {
    const comp_l = String(this.componentdetails_ee.comp_l).split('.');
    if(!String(this.componentdetails_ee.comp_l).includes('.'))
    {
    if(this.componentdetails_ee.comp_l==null || this.componentdetails_ee.comp_l==0)
        {
          this.comp_l_InValid_ee=true;
          this.comp_l_InValidReason_ee='Required';
        }
       else if( String(this.componentdetails_ee.comp_l).length>8)
        {
          this.comp_l_InValid_ee=true;
          this.comp_l_InValidReason_ee='Max Length Of Characters can be 8';
        }
        else if(this.componentdetails_ee.comp_l<1 || this.componentdetails_ee.comp_l>9999.999)
        {
          this.comp_l_InValid_ee=true;
          this.comp_l_InValidReason_ee='Should be of range 1 to 9999.999';
        }
      else
        {
          this.comp_l_InValid_ee=false;
          this.comp_l_InValidReason_ee=null;
        }
    }
    else if(String(this.componentdetails_ee.comp_l).includes('.'))
    {
        if(String(comp_l[0]).length<0 || String(comp_l[0]).length>4)
        {
          this.comp_l_InValid_ee=true;
          this.comp_l_InValidReason_ee='Should be of range 1 to 9999.999';
        }
        else if(String(comp_l[1]).length<0 || String(comp_l[1]).length>3)
        {
          this.comp_l_InValid_ee=true;
          this.comp_l_InValidReason_ee='Max Length Of decimal values can be 3';
        }
        else
        {
          this.comp_l_InValid_ee=false;
          this.comp_l_InValidReason_ee=null;
        }
    }
    else
    {
    this.comp_l_InValid_ee=false;
    this.comp_l_InValidReason_ee=null;
    }
  }
  compWidInValidationForAdd_ee()
  {

    const comp_w = String(this.componentdetails_ee.comp_w).split('.');
    if(!String(this.componentdetails_ee.comp_w).includes('.'))
    {
    if(this.componentdetails_ee.comp_w==null || this.componentdetails_ee.comp_w==0)
        {
          this.comp_w_InValid_ee=true;
          this.comp_w_InValidReason_ee='Required';
        }
       else if( String(this.componentdetails_ee.comp_w).length>8)
        {
          this.comp_w_InValid_ee=true;
          this.comp_w_InValidReason_ee='Max Length Of Characters can be 8';
        }
        else if(this.componentdetails_ee.comp_w<1 || this.componentdetails_ee.comp_w>9999.999)
        {
          this.comp_w_InValid_ee=true;
          this.comp_w_InValidReason_ee='Should be of range 1 to 9999.999';
        }
      else
        {
          this.comp_w_InValid_ee=false;
          this.comp_w_InValidReason_ee=null;
        }
    }
    else if(String(this.componentdetails_ee.comp_w).includes('.'))
    {
        if(String(comp_w[0]).length<0 || String(comp_w[0]).length>4)
        {
          this.comp_w_InValid_ee=true;
          this.comp_w_InValidReason_ee='Should be of range 1 to 9999.999';
        }
        else if(String(comp_w[1]).length<0 || String(comp_w[1]).length>3)
        {
          this.comp_w_InValid_ee=true;
          this.comp_w_InValidReason_ee='Max Length Of decimal values can be 3';
        }
        else
        {
          this.comp_w_InValid_ee=false;
          this.comp_w_InValidReason_ee=null;
        }
    }
    else
    {
    this.comp_w_InValid_ee=false;
    this.comp_w_InValidReason_ee=null;
    }
  }
  compHgtInValidationForAdd_ee()
  {
    const comp_h = String(this.componentdetails_ee.comp_h).split('.');
    if(!String(this.componentdetails_ee.comp_h).includes('.'))
    {
    if(this.componentdetails_ee.comp_h==null || this.componentdetails_ee.comp_h==0)
        {
          this.comp_h_InValid_ee=true;
          this.comp_h_InValidReason_ee='Required';
        }
       else if( String(this.componentdetails_ee.comp_h).length>8)
        {
          this.comp_h_InValid_ee=true;
          this.comp_h_InValidReason_ee='Max Length Of Characters can be 8';
        }
        else if(this.componentdetails_ee.comp_h<1 || this.componentdetails_ee.comp_h>9999.999)
        {
          this.comp_h_InValid_ee=true;
          this.comp_h_InValidReason_ee='Should be of range 1 to 9999.999';
        }
      else
        {
          this.comp_h_InValid_ee=false;
          this.comp_h_InValidReason_ee=null;
        }
    }
    else if(String(this.componentdetails_ee.comp_h).includes('.'))
    {
        if(String(comp_h[0]).length<0 || String(comp_h[0]).length>4)
        {
          this.comp_h_InValid_ee=true;
          this.comp_h_InValidReason_ee='Should be of range 1 to 9999.999';
        }
        else if(String(comp_h[1]).length<0 || String(comp_h[1]).length>3)
        {
          this.comp_h_InValid_ee=true;
          this.comp_h_InValidReason_ee='Max Length Of decimal values can be 3';
        }
        else
        {
          this.comp_h_InValid_ee=false;
          this.comp_h_InValidReason_ee=null;
        }
    }
    else
    {
    this.comp_h_InValid_ee=false;
    this.comp_h_InValidReason_ee=null;
    }
  }
  compTareWgtInValidationForAdd_ee()
  {
    const comp_tar_wt = String(this.componentdetails_ee.comp_tar_wt).split('.');
    if(!String(this.componentdetails_ee.comp_tar_wt).includes('.'))
    {
    if(this.componentdetails_ee.comp_tar_wt==null || this.componentdetails_ee.comp_tar_wt==0)
        {
          this.comp_tare_wgtInValid_ee=true;
          this.comp_tare_wgtInValidReason_ee='Required';
		   this.comp_totl_wt_InValid_ee = false;
        }
       else if( String(this.componentdetails_ee.comp_tar_wt).length>8)
        {
          this.comp_tare_wgtInValid_ee=true;
          this.comp_tare_wgtInValidReason_ee='Max Length Of Characters can be 8';
		   this.comp_totl_wt_InValid_ee = false;
        }
        else if(this.componentdetails_ee.comp_tar_wt<1 || this.componentdetails_ee.comp_tar_wt>9999.999)
        {
          this.comp_tare_wgtInValid_ee=true;
          this.comp_tare_wgtInValidReason_ee='Should be of range 1 to 9999.999';
		   this.comp_totl_wt_InValid_ee = false;
        }
      else
        {
          this.comp_tare_wgtInValid_ee=false;
          this.comp_tare_wgtInValidReason_ee=null;
		  this.comp_tarwgt_shpunitwgt_validation_add_ee();
        }
    }
    else if(String(this.componentdetails_ee.comp_tar_wt).includes('.'))
    {
        if(String(comp_tar_wt[0]).length<0 || String(comp_tar_wt[0]).length>4)
        {
          this.comp_tare_wgtInValid_ee=true;
          this.comp_tare_wgtInValidReason_ee='Should be of range 1 to 9999.999';
		   this.comp_totl_wt_InValid_ee = false;
        }
        else if(String(comp_tar_wt[1]).length<0 || String(comp_tar_wt[1]).length>3)
        {
          this.comp_tare_wgtInValid_ee=true;
          this.comp_tare_wgtInValidReason_ee='Max Length Of decimal values can be 3';
		   this.comp_totl_wt_InValid_ee = false;
        }
        else
        {
          this.comp_tare_wgtInValid_ee=false;
          this.comp_tare_wgtInValidReason_ee=null;
		  this.comp_tarwgt_shpunitwgt_validation_add_ee();
        }
    }
    else
    {
    this.comp_tare_wgtInValid_ee=false;
    this.comp_tare_wgtInValidReason_ee=null;
	this.comp_tarwgt_shpunitwgt_validation_add_ee();
    }
  }
  compQtPerShpUntInValidationForAdd_ee()
  {
    if(this.componentdetails_ee.qt_per_min_su==0 || this.componentdetails_ee.qt_per_min_su==null)
    {
      this.qt_pershp_untInValid_ee=true;
      this.qt_pershp_untInValidReason_ee='Required';
      // this.comp_tarwgt_shpunitwgt_validation_add();
    }
    else if(this.componentdetails_ee.qt_per_min_su<1 || this.componentdetails_ee.qt_per_min_su>999)
    {
      this.qt_pershp_untInValid_ee=true;
      this.qt_pershp_untInValidReason_ee='Should be of range 1 to 999';
      // this.comp_tarwgt_shpunitwgt_validation_add();
    }
    else
    {
      this.qt_pershp_untInValid_ee=false;
      this.qt_pershp_untInValidReason_ee=null;
      // this.comp_tarwgt_shpunitwgt_validation_add_ee();
      console.log('CompQtShpUnt in validations', this.componentdetails_ee.qt_per_min_su);
    }
  }
  ownershpInValidationForAdd_ee()
  {
    if(this.componentdetails_ee.comp_ownershp==null || this.componentdetails_ee.comp_ownershp.trim()=='')
    {
      this.ownrshpInValid_ee=true;
      this.ownrshpInValidReason_ee='Required'
    }
    else
    {
      this.ownrshpInValid_ee=false;
      this.ownrshpInValidReason_ee=null
    }
  }

  // tooledCompInValidationForAdd_ee()
  // {
  //   if(this.componentdetails_ee.tooledcomp==null || this.componentdetails_ee.tooledcomp.trim()=="")
  //   {
  //     this.tooledCompInValid_ee=true;
  //     this.tooledCompInValidReason_ee="Required"
  //   }
  //   else
  //   {
  //     this.tooledCompInValid_ee=false;
  //     this.tooledCompInValidReason_ee=null
  //   }
  // }
  selectedMaterialInValidationForAdd_ee()
{
  if(this.selected_material_ee.cntr_matr_name==null || this.selected_material_ee.cntr_matr_name.trim()=='')
  {
    this.selectMaterialInValid_ee=true;
    this.selectMaterialInValidReason_ee='Required';
  }
  else
  {
    this.selectMaterialInValid_ee=false;
    this.selectMaterialInValidReason_ee=null;
  }
}


  expAidswgtcalulation_add_ee()
  {
    this.comp_totl_wt_InValid_ee = false;
    // if(this.no_of_container_ee<999.9999)
    // {
      if(this.wgt_flag)
         {
         this.shp_comp_wt_add_ee = 0;
         this.temp_shp_compwt_add_ee = 0;
         }
           if(this.component_type_ee.cd_cntr_typ > '05' && this.component_type_ee.cd_cntr_typ <'16')
           {
                 if(this.componentdetailsgrid_ee.length == 0 || this.componentdetailsgrid_ee.length == null || !this.componentdetailsgrid_ee)
                 {

                        console.log('Inside First IF');

                       this.temp_comwgt_add_ee = this.comb_wgtadd_ee;
                       console.log('Inside The Nothing', this.temp_comwgt_add_ee);
                       if(this.temp_comwgt_add_ee > 999.9999)
                       {
                             this.comp_totl_wt_InValid_ee = true;
                             console.log('Inside Grid 0', this.temp_comwgt_add_ee)
                       }
                       else
                       {
                         this.comp_totl_wt_InValid_ee = false;
                         this.comp_totl_wt_InValidReason_ee = null;
                       }
                 }
             for( const i of this.componentdetailsgrid_ee)
               {

                if(i.compselctnfor == 'Container' && (i.comp_code > '05' && i.comp_code < '16'))
                {

                  this.cntr_comp_wt = 0;
                  this.cntr_comp_wt+=(i.qt_per_min_su *i.comp_tar_wt);
                  this.shp_comp_wt_add_ee +=( this.no_of_container_ee*(this.cntr_comp_wt));
                  this.temp_comwgt_add_ee = this.shp_comp_wt_add_ee + this.comb_wgtadd_ee;

                }

                else if(i.compselctnfor== 'Shipping Unit' && (i.comp_code > '05' && i.comp_code < '16'))
                  {
                    this.temp_shp_compwt_add_ee = 0;
                  this.shp_comp_wt_add_ee+=(i.qt_per_min_su *i.comp_tar_wt);
                  this.temp_shp_compwt_add_ee+= this.shp_comp_wt;
                  this.temp_comwgt_add_ee = this.shp_comp_wt_add_ee + this.comb_wgtadd_ee;

                }
                else {
                if (this.componentdetailsgrid_ee.length == 1  &&  !(i.comp_code > '05' && i.comp_code < '16'))
                {
                  if(this.component_type_ee.cd_cntr_typ > '05' && this.component_type_ee.cd_cntr_typ <'16')
                  {
                              this.temp_comwgt_add_ee = this.comb_wgtadd_ee;
                              console.log('Inside The NonCOmp', this.temp_comwgt_add_ee);
                  }
                }
               }

               if(this.temp_comwgt_add_ee > 999.9999)
               {
                     this.comp_totl_wt_InValid_ee = true;
                     console.log('Inside came here on last validation check add');
               }
               else
               {
                 this.comp_totl_wt_InValid_ee = false;
                 this.comp_totl_wt_InValidReason_ee = null;
               }
              }
              if(this.no_of_container_ee>999.9999)
              {

              if(this.componentdetails_ee.compselctnfor == 'Container' && (this.component_type_ee.cd_cntr_typ > '05' && this.component_type_ee.cd_cntr_typ < '16')){
                this.temp_comwgt_add_ee = this.comb_wgtadd_ee+this.shp_comp_wt_add_ee;
                console.log('Inside the else part - Cont', this.temp_comwgt_add_ee, this.temp_comwgt_add_ee)
                if(this.temp_comwgt_add_ee != 0 || this.temp_comwgt_add_ee != null || this.temp_comwgt_add_ee > 999.9999 || this.comp_totl_wt_InValid_ee)
                {
                  this.num_of_cntr_InValid_ee = true;
                  this.comp_totl_wt_InValid_ee = false;
                }
                else
                {
                  this.num_of_cntr_InValid_ee = false;
                }

              }
              else
              {
                console.log('Inside The Else part of no.of cntrs', this.no_of_container_ee,this.temp_comwgt_add_ee,this.comb_wgtadd_ee);
                if(this.componentdetails_ee.compselctnfor == 'Shipping Unit' && (this.component_type_ee.cd_cntr_typ > '05' && this.component_type_ee.cd_cntr_typ < '16'))
                {
                this.temp_comwgt_add_ee = this.comb_wgtadd_ee;
                console.log('Inside the else part - Shipunit', this.temp_comwgt_add_ee)
                if(this.temp_comwgt_add_ee != 0 || this.temp_comwgt_add_ee != null || this.temp_comwgt_add_ee > 999.9999 || this.comp_totl_wt_InValid_ee)
                {
                  this.num_of_cntr_InValid_ee = true;
                  this.comp_totl_wt_InValid_ee = false;
                }
                else
                {
                  this.num_of_cntr_InValid_ee = false;
                }
               }
              }
              }
            }
            else
            {

              console.log('Inside Else Part', this.no_of_container_ee)
              if(this.component_type_ee.cd_cntr_typ > '05' && this.component_type_ee.cd_cntr_typ < '16')
              {
                this.temp_comwgt_add_ee = this.comb_wgtadd_ee;
                console.log('Inside The else Part - No.of contrs', this.temp_comwgt_add_ee);
                if(this.temp_comwgt_add_ee != 0 || this.temp_comwgt_add_ee != null || this.temp_comwgt_add_ee > 999.9999)
                {
                  this.num_of_cntr_InValid_ee = true;
                }
                else
                {
                  this.num_of_cntr_InValid_ee = false;
                }

              }
            }
  // }



  }
  // expAidswgtcalulation_add()
  // {
  //     if(this.no_of_container_ee < 99.9999)
  //      {
  //      if(this.wgt_flag)
  //      {
  //      this.shp_comp_wt_add_ee  = 0;
  //      this.temp_shp_compwt_add_ee  = 0;
  //      }
  //        if(this.component_type_ee .cd_cntr_typ > "05" && this.component_type_ee.cd_cntr_typ <"15")
  //        {
  //          for( var i of this.componentdetailsgrid_ee)
  //            {
  //             if(i.compselctnfor == "Container" && (i.comp_code > "05" && i.comp_code < "15"))
  //             {

  //               this.cntr_comp_wt = 0;
  //               this.cntr_comp_wt+=(i.qt_per_min_su *i.comp_tar_wt);
  //               this.shp_comp_wt_add_ee +=( this.no_of_container_ee*(this.cntr_comp_wt));
  //               this.temp_comwgt_add_ee = this.shp_comp_wt_add_ee + this.comb_wgtadd_ee;
  //               if(this.temp_comwgt_add_ee > 99.9999)
  //               {
  //                     this.comp_totl_wt_InValid_ee = true;
  //               }
  //               else
  //               {
  //                 this.comp_totl_wt_InValid_ee = false;
  //                 this.comp_totl_wt_InValidReason_ee = null;
  //               }

  //             }
  //             else
  //             {
  //               if(i.compselctnfor== "Shipping Unit" && (i.comp_code > "05" && i.comp_code < "15"))
  //               {
  //                 this.temp_shp_compwt_add_ee = 0;
  //               this.shp_comp_wt_add_ee+=(i.qt_per_min_su *i.comp_tar_wt);
  //               this.temp_shp_compwt_add_ee+= this.shp_comp_wt;
  //               this.temp_comwgt_add_ee = this.shp_comp_wt_add_ee + this.comb_wgtadd_ee;
  //               if(this.temp_comwgt_add_ee > 99.9999)
  //               {
  //                     this.comp_totl_wt_InValid_ee = true;
  //               }
  //               else
  //               {
  //                 this.comp_totl_wt_InValid_ee = false;
  //                 this.comp_totl_wt_InValidReason_ee = null;
  //               }


  //             }

  //             }
  //            }
  //         }
  //   }
  //   else
  //   {
  //     this.num_of_cntr_InValid_ee = true;
  //     if(this.componentdetails_ee.compselctnfor == "Container" && (this.component_type_ee.cd_cntr_typ > "05" && this.component_type_ee.cd_cntr_typ < "15")){
  //       this.temp_comwgt_add_ee = (this.comb_wgtadd_ee);
  //       if(this.temp_comwgt_add_ee != 0 || this.temp_comwgt_add_ee != null)
  //       {
  //         this.num_of_cntr_InValid_ee = true;
  //       }
  //       else
  //       {
  //         this.num_of_cntr_InValid_ee = false;
  //       }

  //     }
  //     else{
  //       if(this.componentdetails_ee.compselctnfor == "Shipping Unit" && (this.component_type_ee.cd_cntr_typ > "05" && this.component_type_ee.cd_cntr_typ < "15"))
  //       {
  //       this.temp_comwgt_add_ee = this.comb_wgtadd_ee + this.no_of_container_ee;
  //       if(this.temp_comwgt_add_ee != 0 || this.temp_comwgt_add_ee != null)
  //       {
  //         this.num_of_cntr_InValid_ee = true;
  //       }
  //       else
  //       {
  //         this.num_of_cntr_InValid_ee = false;
  //       }
  //      }
  //     }
  //   }



  // }

  save_componentdetails_ee()
  {
    this.displaydialog_ee=false;

    this.rowadded_ee=true;
    this.componentdetails_ee.comp_ret_type=this.exp_returnable_ee;
    this.componentdetails_ee.comp_lwh=this.componentdetails_ee.comp_l+'x'+this.componentdetails_ee.comp_w+'x'+this.componentdetails_ee.comp_h;
    this.componentdetails_ee.updated_dt=new Date().toISOString().split('T')[0];
    this.componentdetails_ee.updated_by=this.transactionService.getcdsid();
    this.componentdetails_ee.comp_material=this.selected_material_ee.cntr_matr_name;

    if((this.componentdetails_ee.typ_no == 2 || this.componentdetails_ee.typ_no == 3 || this.componentdetails_ee.typ_no == 4)
    && this.componentdetails_ee.compselctnfor.toLowerCase() == 'shipping unit')
    {
      this.compAddedChangeHeight_ee=true;
    }
    if(this.unitom_ee == "E")
    {
      this.edit_measure_comp_add_ee = 'lbs/inch';
    }
    else
    {
      this.edit_measure_comp_add_ee = 'kg/cm';
    }

    // if(this.edit_measure_comp_add_ee=="kg/cm")
    // {
    //   if(this.componentdetails_ee.comp_l!=null)
    //   {
    //     this.componentdetails_ee.comp_l=Math.round((0.393701*this.componentdetails_ee.comp_l)*1000)/1000;

    //   }
    //   if(this.componentdetails_ee.comp_w!=null)
    //   {
    //     this.componentdetails_ee.comp_w=Math.round((0.393701*this.componentdetails_ee.comp_w)*1000)/1000;

    //   }
    //   if(this.componentdetails_ee.comp_h!=null)
    //   {
    //     this.componentdetails_ee.comp_h=Math.round((0.393701*this.componentdetails_ee.comp_h)*1000)/1000;

    //   }
    //   if(this.componentdetails_ee.nestedfolderHt!=null)
    //   {
    //     this.componentdetails_ee.nestedfolderHt=Math.round((0.393701*this.componentdetails_ee.nestedfolderHt)*1000)/1000;

    //   }
    //   if(this.componentdetails_ee.comp_tar_wt!=null)
    //   {
    //     this.componentdetails_ee.comp_tar_wt=Math.round((2.20462*this.componentdetails_ee.comp_tar_wt)*1000)/1000;

    //   }


    // }

    this.variableforsave_ee=
    {
      id_proposal:this.componentdetails_ee.id_proposal,
      id_comp:0,
      comp_base:this.componentdetails_ee.comp_base,
      comp_suffix:this.componentdetails_ee.comp_suffix,
      comp_desc:this.componentdetails_ee.comp_desc,
      qt_per_min_su:this.componentdetails_ee.qt_per_min_su,
      comp_ret_type:this.componentdetails_ee.comp_ret_type,
      upated_by:this.componentdetails_ee.updated_by,
      updated_dt:this.componentdetails_ee.updated_dt,
      comp_l:Number(this.componentdetails_ee.comp_l),
      comp_w:Number(this.componentdetails_ee.comp_w),
      comp_h:Number(this.componentdetails_ee.comp_h),
      comp_tare_wt:this.componentdetails_ee.comp_tar_wt,
      nestedfolderht:Number(this.componentdetails_ee.nestedfolderHt),
      comp_code:this.componentdetails_ee.comp_code,
      comp_ownershp:this.componentdetails_ee.comp_ownershp,
      comp_material:this.componentdetails_ee.comp_material,
      tooledcomp:this.componentdetails_ee.tooledcomp,
      is_compulsary:this.componentdetails_ee.is_compulsary,
      compselctnfor:this.cntrorshpunitradio_ee,
      material_flag:this.selected_material_ee.comp_type_flag,
      typ_no:this.componentdetails_ee.typ_no
      // material flag added
    }

    console.log('variableforsave',this.variableforsave_ee);

    this.compDescInValidationForAdd_ee();
    this.compLenInValidationForAdd_ee();
    this.compWidInValidationForAdd_ee();
    this.compHgtInValidationForAdd_ee();
    this.compTareWgtInValidationForAdd_ee();
    this.compQtPerShpUntInValidationForAdd_ee();
    this.ownershpInValidationForAdd_ee();
    // this.tooledCompInValidationForAdd_ee();
    this.selectedMaterialInValidationForAdd_ee();

    this.comp_tarwgt_shpunitwgt_validation_add_ee();

    // this.expAidsWgtValidationForAdd();

    this.expAidswgtcalulation_add_ee();

    console.log('Temp_comp_after_save',this.temp_comwgt_add_ee,this.shp_comp_wt_add_ee,this.comb_wgtadd_ee)

    console.log('Inside EXPCAL_save',this.comp_totl_wt_InValid_ee,this.num_of_cntr_InValid_ee)

    if(!this.comp_descInValid_ee && !this.comp_l_InValid_ee && !this.comp_w_InValid_ee && !this.comp_h_InValid_ee && !this.comp_tare_wgtInValid_ee && !this.qt_pershp_untInValid_ee && !this.sumwgts_InValid_ee && !this.ownrshpInValid_ee 
        && !this.selectMaterialInValid_ee && !this.comp_totl_wt_InValid_ee && !this.num_of_cntr_InValid_ee)
    {
      this.transactionService.storecomponentdetails_ee(this.variableforsave_ee).subscribe(storeout=>{
      const res=storeout;
      console.log('Success',res);
      if(res=='Success')
      {
        this.packagingproposal.addtoast({severity:'success', summary:'Success', detail:' Component Details saved Successfully'})
        this.populateListOfMaterialsGrid_ee(this.componentdetails_ee);
        this.populateGrid_ee();
        this.componentdetailsinit();
        this.compInValidationInit_ee();
        console.log('Inside_if_wtinvalid',this.comp_totl_wt_InValid_ee)
      }
      else
      {
        this.packagingproposal.addtoast({severity:'error', summary:'Error', detail:' Component Details not saved'});
        this.compInValidationInit_ee();
        if(this.unitom_ee == 'E')
    {
      this.edit_measure_comp_add_ee = 'lbs/inch';
    }
    else
    {
      this.edit_measure_comp_add_ee = 'kg/cm';
    }
        // if(this.edit_measure_comp_add_ee=="kg/cm")
        // {



        //   if(this.componentdetails_ee.comp_l!=null)
        //   {
        //     this.componentdetails_ee.comp_l=Math.round((2.54*this.componentdetails_ee.comp_l)*1000)/1000;

        //   }
        //   if(this.componentdetails_ee.comp_w!=null)
        //   {
        //     this.componentdetails_ee.comp_w=Math.round((2.54*this.componentdetails_ee.comp_w)*1000)/1000;

        //   }
        //   if(this.componentdetails_ee.comp_h!=null)
        //   {
        //     this.componentdetails_ee.comp_h=Math.round((2.54*this.componentdetails_ee.comp_h)*1000)/1000;

        //   }
        //   if(this.componentdetails_ee.nestedfolderHt!=null)
        //   {
        //     this.componentdetails_ee.nestedfolderHt=Math.round((2.54*this.componentdetails_ee.nestedfolderHt)*1000)/1000;

        //   }
        //   if(this.componentdetails_ee.comp_tar_wt!=null)
        //   {
        //     this.componentdetails_ee.comp_tar_wt=Math.round((0.453592*this.componentdetails_ee.comp_tar_wt)*1000)/1000;

        //   }
        // }
      }
    });
   }
   else
   {
     this.displaydialog_ee = true;
     this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Check the Component details and Try Again'});
   }
  }

  cancel_componentdetails_ee()
  {
    this.displaydialog_ee=false;

    // on cancelling we shd make the popup load the existing component details which we get from API
    this.loadComponentNumberbasedOnContainerSelected_ee();
    this.componentdetailsinit();
    this.compInValidationInit_ee();
  }

  on_change_measure_comp_gridedit_ee()
  {


    console.log(this.edit_measure_comp_gridedit_ee);
    console.log(this.componentdetailsforedit_ee);

    if(this.unitom_ee == 'E')
    {
      this.edit_measure_comp_gridedit_ee = 'lbs/inch';
    }
    else
    {
      this.edit_measure_comp_gridedit_ee = 'kg/cm';
    }

    // if(this.edit_measure_comp_gridedit_ee=="lbs/inch")
    // {
    //   if(this.componentdetailsforedit_ee.comp_l!=null)
    //   {
    //     this.componentdetailsforedit_ee.comp_l=Math.round((0.393701*this.componentdetailsforedit_ee.comp_l)*1000)/1000;

    //   }
    //   if(this.componentdetailsforedit_ee.comp_w!=null)
    //   {
    //     this.componentdetailsforedit_ee.comp_w=Math.round((0.393701*this.componentdetailsforedit_ee.comp_w)*1000)/1000;

    //   }
    //   if(this.componentdetailsforedit_ee.comp_h!=null)
    //   {
    //     this.componentdetailsforedit_ee.comp_h=Math.round((0.393701*this.componentdetailsforedit_ee.comp_h)*1000)/1000;

    //   }
    //   if(this.componentdetailsforedit_ee.nestedfolderHt!=null)
    //   {
    //     this.componentdetailsforedit_ee.nestedfolderHt=Math.round((0.393701*this.componentdetailsforedit_ee.nestedfolderHt)*1000)/1000;

    //   }
    //   if(this.componentdetailsforedit_ee.comp_tar_wt!=null)
    //   {
    //     this.componentdetailsforedit_ee.comp_tar_wt=Math.round((2.20462*this.componentdetailsforedit_ee.comp_tar_wt)*1000)/1000;

    //   }


    // }

    // else if(this.edit_measure_comp_gridedit_ee=="kg/cm")
    // {



    //   if(this.componentdetailsforedit_ee.comp_l!=null)
    //   {
    //     this.componentdetailsforedit_ee.comp_l=Math.round((2.54*this.componentdetailsforedit_ee.comp_l)*1000)/1000;

    //   }
    //   if(this.componentdetailsforedit_ee.comp_w!=null)
    //   {
    //     this.componentdetailsforedit_ee.comp_w=Math.round((2.54*this.componentdetailsforedit_ee.comp_w)*1000)/1000;

    //   }
    //   if(this.componentdetailsforedit_ee.comp_h!=null)
    //   {
    //     this.componentdetailsforedit_ee.comp_h=Math.round((2.54*this.componentdetailsforedit_ee.comp_h)*1000)/1000;

    //   }
    //   if(this.componentdetailsforedit_ee.nestedfolderHt!=null)
    //   {
    //     this.componentdetailsforedit_ee.nestedfolderHt=Math.round((2.54*this.componentdetailsforedit_ee.nestedfolderHt)*1000)/1000;

    //   }
    //   if(this.componentdetailsforedit_ee.comp_tar_wt!=null)
    //   {
    //     this.componentdetailsforedit_ee.comp_tar_wt=Math.round((0.453592*this.componentdetailsforedit_ee.comp_tar_wt)*1000)/1000;

    //   }



    // }
  }
    compDescInValidationForEdit_ee()
  {
    if(this.componentdetailsforedit_ee.comp_desc == null || this.componentdetailsforedit_ee.comp_desc.trim() == '')
    {
      this.comp_descInValid_ee=true;
      this.comp_descInValidReason_ee='Required';
    }
    else if(this.componentdetailsforedit_ee.comp_desc.length < 1 ||  this.componentdetailsforedit_ee.comp_desc.length > 99.999)
    {
      this.comp_descInValid_ee=true;
      this.comp_descInValidReason_ee='Should be of range 1 to 99.999';
    }
    else
    {
      this.comp_descInValid_ee=false;
      this.comp_descInValidReason_ee=null;
    }
  }
  compLenInValidationForEdit_ee()
  {
    const comp_l = String(this.componentdetailsforedit_ee.comp_l).split('.');
    if(!String(this.componentdetailsforedit_ee.comp_l).includes('.'))
    {
    if(this.componentdetailsforedit_ee.comp_l==null || this.componentdetailsforedit_ee.comp_l==0)
        {
          this.comp_l_InValid_ee=true;
          this.comp_l_InValidReason_ee='Required';
        }
       else if( String(this.componentdetailsforedit_ee.comp_l).length>8)
        {
          this.comp_l_InValid_ee=true;
          this.comp_l_InValidReason_ee='Max Length Of Characters can be 8';
        }
        else if(this.componentdetailsforedit_ee.comp_l<1 || this.componentdetailsforedit_ee.comp_l>9999.999)
        {
          this.comp_l_InValid_ee=true;
          this.comp_l_InValidReason_ee='Should be of range 1 to 9999.999';
        }
      else
        {
          this.comp_l_InValid_ee=false;
          this.comp_l_InValidReason_ee=null;
        }
    }
    else if(String(this.componentdetailsforedit_ee.comp_l).includes('.'))
    {
        if(String(comp_l[0]).length<0 || String(comp_l[0]).length>4)
        {
          this.comp_l_InValid_ee=true;
          this.comp_l_InValidReason_ee='Should be of range 1 to 9999.999';
        }
        else if(String(comp_l[1]).length<0 || String(comp_l[1]).length>3)
        {
          this.comp_l_InValid_ee=true;
          this.comp_l_InValidReason_ee='Max Length Of decimal values can be 3';
        }
        else
        {
          this.comp_l_InValid_ee=false;
          this.comp_l_InValidReason_ee=null;
        }
    }
    else
    {
    this.comp_l_InValid_ee=false;
    this.comp_l_InValidReason_ee=null;
    }
  }
  compWidInValidationForEdit_ee()
  {
    const comp_w = String(this.componentdetailsforedit_ee.comp_w).split('.');
    if(!String(this.componentdetailsforedit_ee.comp_w).includes('.'))
    {
    if(this.componentdetailsforedit_ee.comp_w==null || this.componentdetailsforedit_ee.comp_w==0)
        {
          this.comp_w_InValid_ee=true;
          this.comp_w_InValidReason_ee='Required';
        }
       else if( String(this.componentdetailsforedit_ee.comp_w).length>8)
        {
          this.comp_w_InValid_ee=true;
          this.comp_w_InValidReason_ee='Max Length Of Characters can be 8';
        }
        else if(this.componentdetailsforedit_ee.comp_w<1 || this.componentdetailsforedit_ee.comp_w>9999.999)
        {
          this.comp_w_InValid_ee=true;
          this.comp_w_InValidReason_ee='Should be of range 1 to 9999.999';
        }
      else
        {
          this.comp_w_InValid_ee=false;
          this.comp_w_InValidReason_ee=null;
        }
    }
    else if(String(this.componentdetailsforedit_ee.comp_w).includes('.'))
    {
        if(String(comp_w[0]).length<0 || String(comp_w[0]).length>4)
        {
          this.comp_w_InValid_ee=true;
          this.comp_w_InValidReason_ee='Should be of range 1 to 9999.999';
        }
        else if(String(comp_w[1]).length<0 || String(comp_w[1]).length>3)
        {
          this.comp_w_InValid_ee=true;
          this.comp_w_InValidReason_ee='Max Length Of decimal values can be 3';
        }
        else
        {
          this.comp_w_InValid_ee=false;
          this.comp_w_InValidReason_ee=null;
        }
    }
    else
    {
    this.comp_w_InValid_ee=false;
    this.comp_w_InValidReason_ee=null;
    }
  }
  compHgtInValidationForEdit_ee()
  {
    const comp_h = String(this.componentdetailsforedit_ee.comp_h).split('.');
    if(!String(this.componentdetailsforedit_ee.comp_h).includes('.'))
    {
    if(this.componentdetailsforedit_ee.comp_h==null || this.componentdetailsforedit_ee.comp_h==0)
        {
          this.comp_h_InValid_ee=true;
          this.comp_h_InValidReason_ee='Required';
        }
       else if( String(this.componentdetailsforedit_ee.comp_h).length>8)
        {
          this.comp_h_InValid_ee=true;
          this.comp_h_InValidReason_ee='Max Length Of Characters can be 8';
        }
        else if(this.componentdetailsforedit_ee.comp_h<1 || this.componentdetailsforedit_ee.comp_h>9999.999)
        {
          this.comp_h_InValid_ee=true;
          this.comp_h_InValidReason_ee='Should be of range 1 to 9999.999';
        }
      else
        {
          this.comp_h_InValid_ee=false;
          this.comp_h_InValidReason_ee=null;
        }
    }
    else if(String(this.componentdetailsforedit_ee.comp_h).includes('.'))
    {
        if(String(comp_h[0]).length<0 || String(comp_h[0]).length>4)
        {
          this.comp_h_InValid_ee=true;
          this.comp_h_InValidReason_ee='Should be of range 1 to 9999.999';
        }
        else if(String(comp_h[1]).length<0 || String(comp_h[1]).length>3)
        {
          this.comp_h_InValid_ee=true;
          this.comp_h_InValidReason_ee='Max Length Of decimal values can be 3';
        }
        else
        {
          this.comp_h_InValid_ee=false;
          this.comp_h_InValidReason_ee=null;
        }
    }
    else
    {
    this.comp_h_InValid_ee=false;
    this.comp_h_InValidReason_ee=null;
    }
  }
  compTareWgtInValidationForEdit_ee()
  {
    const comp_tar_wt = String(this.componentdetailsforedit_ee.comp_tar_wt).split('.');
    if(!String(this.componentdetailsforedit_ee.comp_tar_wt).includes('.'))
    {
    if(this.componentdetailsforedit_ee.comp_tar_wt==null || this.componentdetailsforedit_ee.comp_tar_wt==0)
        {
          this.comp_tare_wgtInValid_ee=true;
          this.comp_tare_wgtInValidReason_ee='Required';
		   this.comp_totl_wt_InValid_ee = false;
        }
       else if( String(this.componentdetailsforedit_ee.comp_tar_wt).length>8)
        {
          this.comp_tare_wgtInValid_ee=true;
          this.comp_tare_wgtInValidReason_ee='Max Length Of Characters can be 8';
		   this.comp_totl_wt_InValid_ee = false;
        }
        else if(this.componentdetailsforedit_ee.comp_tar_wt<1 || this.componentdetailsforedit_ee.comp_tar_wt>9999.999)
        {
          this.comp_tare_wgtInValid_ee=true;
          this.comp_tare_wgtInValidReason_ee='Should be of range 1 to 9999.999';
		   this.comp_totl_wt_InValid_ee= false;
        }
      else
        {
          this.comp_tare_wgtInValid_ee=false;
          this.comp_tare_wgtInValidReason_ee=null;
		      this.comp_tarwgt_shpunitwgt_validation_edit_ee();
        }
    }
    else if(String(this.componentdetailsforedit_ee.comp_tar_wt).includes('.'))
    {
        if(String(comp_tar_wt[0]).length<0 || String(comp_tar_wt[0]).length>4)
        {
          this.comp_tare_wgtInValid_ee=true;
          this.comp_tare_wgtInValidReason_ee='Should be of range 1 to 9999.999';
		   this.comp_totl_wt_InValid_ee = false;
        }
        else if(String(comp_tar_wt[1]).length<0 || String(comp_tar_wt[1]).length>3)
        {
          this.comp_tare_wgtInValid_ee=true;
          this.comp_tare_wgtInValidReason_ee='Max Length Of decimal values can be 3';
		   this.comp_totl_wt_InValid_ee = false;
        }
        else
        {
          this.comp_tare_wgtInValid_ee=false;
          this.comp_tare_wgtInValidReason_ee=null;
		  this.comp_tarwgt_shpunitwgt_validation_edit_ee();
        }
    }
    else
    {
    this.comp_tare_wgtInValid_ee=false;
    this.comp_tare_wgtInValidReason_ee=null;
	this.comp_tarwgt_shpunitwgt_validation_edit_ee();
    }
  }
  compQtPerShpUntInValidationForEdit_ee()
  {
    if(this.componentdetailsforedit_ee.qt_per_min_su==0 || this.componentdetailsforedit_ee.qt_per_min_su==null)
    {
      this.qt_pershp_untInValid_ee=true;
      this.qt_pershp_untInValidReason_ee='Required';
    }
    else if(this.componentdetailsforedit_ee.qt_per_min_su<1 || this.componentdetailsforedit_ee.qt_per_min_su>999  || String(this.componentdetailsforedit_ee.qt_per_min_su).includes('.'))
    {
      this.qt_pershp_untInValid_ee=true;
      this.qt_pershp_untInValidReason_ee='Should be of range 1 to 999 and cannot contain Decimal';
    }
    else
    {
      this.qt_pershp_untInValid_ee=false;
      this.qt_pershp_untInValidReason_ee=null;
      this.comp_tarwgt_shpunitwgt_validation_edit_ee();
      console.log('CompQtShpUnt in validations', this.componentdetailsforedit_ee.qt_per_min_su);
    }
  }
  ownershpInValidationForEdit_ee()
  {
    if(this.componentdetailsforedit_ee.comp_ownershp==null || this.componentdetailsforedit_ee.comp_ownershp.trim()=='')
    {
      this.ownrshpInValid_ee=true;
      this.ownrshpInValidReason_ee='Required'
    }
    else
    {
      this.ownrshpInValid_ee=false;
      this.ownrshpInValidReason_ee=null
    }
  }

  // tooledCompInValidationForEdit_ee()
  // {
  //   if(this.componentdetailsforedit_ee.tooledcomp==null || this.componentdetailsforedit_ee.tooledcomp.trim()=="")
  //   {
  //     this.tooledCompInValid_ee=true;
  //     this.tooledCompInValidReason_ee="Required"
  //   }
  //   else
  //   {
  //     this.tooledCompInValid_ee=false;
  //     this.tooledCompInValidReason_ee=null
  //   }
  // }
  selectedMaterialInValidationForEdit_ee()
{
  if(this.selected_material_ee.cntr_matr_name==null || this.selected_material_ee.cntr_matr_name.trim()=='')
  {
    this.selectMaterialInValid_ee=true;
    this.selectMaterialInValidReason_ee='Required';
  }
  else
  {
    this.selectMaterialInValid_ee=false;
    this.selectMaterialInValidReason_ee=null;
  }
}
  expAidswgtcalulation_edit_ee()
  {

      console.log('Check Initial')
      if(this.wgt_flag)
      {
      this.shp_comp_wt_edit_ee = 0;
      this.temp_shp_compwt_edit_ee = 0;
      }
        // if(this.component_type_ee.cd_cntr_typ > "05" || this.component_type_ee.cd_cntr_typ <"15")
        // {
          for( const i of this.componentdetailsgrid_ee)
            {
             console.log('Inside For I',this.componentdetailsgrid_ee.indexOf(i))

             console.log('Inside IF_EDIT COMPSEL',i.compselctnfor)

             if(i.compselctnfor == 'Container' && (i.comp_code > '05' && i.comp_code < '16'))
             {

               this.cntr_comp_wt = 0;


               const wtindex = (this.componentdetailsgrid_ee.findIndex(ind => (ind.id_comp) == (this.componentdetailsforedit_ee.id_comp)))


               if(this.componentdetailsgrid_ee.indexOf(i) == wtindex)
               {
                i.comp_tar_wt = this.componentdetailsforedit_ee.comp_tar_wt;
                i.qt_per_min_su = this.componentdetailsforedit_ee.qt_per_min_su;
               }

               this.cntr_comp_wt+=(i.qt_per_min_su *i.comp_tar_wt);
               this.shp_comp_wt_edit_ee +=( this.no_of_container_ee*(this.cntr_comp_wt));
               this.temp_comwgt_edit_ee = this.shp_comp_wt_edit_ee;

               if(this.temp_comwgt_edit_ee > 999.9999)
               {
                     this.comp_totl_wt_InValid_ee = true;
               }
               else
               {
                 this.comp_totl_wt_InValid_ee = false;
                 this.comp_totl_wt_InValidReason_ee = null;
               }

             }
             else
             {
               if(i.compselctnfor == 'Shipping Unit' && (i.comp_code > '05' && i.comp_code < '16'))
               {


                 this.temp_shp_compwt_edit_ee = 0;
               const wtshpindex = (this.componentdetailsgrid_ee.findIndex(ind => (ind.id_comp) == (this.componentdetailsforedit_ee.id_comp)))

               if(this.componentdetailsgrid_ee.indexOf(i) == wtshpindex)
               {
                  i.comp_tar_wt = this.componentdetailsforedit_ee.comp_tar_wt;
                  i.qt_per_min_su = this.componentdetailsforedit_ee.qt_per_min_su;
               }

               this.shp_comp_wt_edit_ee+=(i.qt_per_min_su *i.comp_tar_wt);
              this.temp_comwgt_edit_ee = this.shp_comp_wt_edit_ee;

               if(this.temp_comwgt_edit_ee > 999.999)
               {
                     this.comp_totl_wt_InValid_ee = true;
               }
               else
               {
                 this.comp_totl_wt_InValid_ee = false;
                 this.comp_totl_wt_InValidReason_ee = null;
               }
             }


             }
            }
        //  }


  }

  save_componentdetailsforedit_ee()
  {

    this.displayeditdialog_ee=false;
    this.rowedited_ee=true;
    this.componentdetailsforedit_ee.comp_lwh=this.componentdetailsforedit_ee.comp_l+'x'+this.componentdetailsforedit_ee.comp_w+'x'+this.componentdetailsforedit_ee.comp_h;
    this.componentdetailsforedit_ee.updated_dt=new Date().toISOString().split('.')[0];
    this.componentdetailsforedit_ee.updated_by=this.transactionService.getcdsid();
    this.componentdetailsforedit_ee.comp_material=this.selected_material_ee.cntr_matr_name;

  if((this.componentdetailsforedit_ee.typ_no == 2 || this.componentdetailsforedit_ee.typ_no == 3 || this.componentdetailsforedit_ee.typ_no == 4) 
    && (this.originalComponentDetailsForEdit_ee.comp_h != this.componentdetailsforedit_ee.comp_h || this.originalComponentDetailsForEdit_ee.qt_per_min_su != this.componentdetailsforedit_ee.qt_per_min_su)
    && this.componentdetailsforedit_ee.compselctnfor.toLowerCase() == 'shipping unit')
       {
    this.compEdidtedChangeHeight_ee=true;
    }
    if(this.unitom_ee == 'E')
    {
      this.edit_measure_comp_gridedit_ee = 'lbs/inch';
    }
    else
    {
      this.edit_measure_comp_gridedit_ee = 'kg/cm';
    }

    // if(this.edit_measure_comp_gridedit_ee=="kg/cm")
    // {
    //   if(this.componentdetailsforedit_ee.comp_l!=null)
    //   {
    //     this.componentdetailsforedit_ee.comp_l=Math.round((0.393701*this.componentdetailsforedit_ee.comp_l)*1000)/1000;

    //   }
    //   if(this.componentdetailsforedit_ee.comp_w!=null)
    //   {
    //     this.componentdetailsforedit_ee.comp_w=Math.round((0.393701*this.componentdetailsforedit_ee.comp_w)*1000)/1000;

    //   }
    //   if(this.componentdetailsforedit_ee.comp_h!=null)
    //   {
    //     this.componentdetailsforedit_ee.comp_h=Math.round((0.393701*this.componentdetailsforedit_ee.comp_h)*1000)/1000;

    //   }
    //   if(this.componentdetailsforedit_ee.nestedfolderHt!=null)
    //   {
    //     this.componentdetailsforedit_ee.nestedfolderHt=Math.round((0.393701*this.componentdetailsforedit_ee.nestedfolderHt)*1000)/1000;

    //   }
    //   if(this.componentdetailsforedit_ee.comp_tar_wt!=null)
    //   {
    //     this.componentdetailsforedit_ee.comp_tar_wt=Math.round((2.20462*this.componentdetailsforedit_ee.comp_tar_wt)*1000)/1000;

    //   }


    // }

    /* Even when compulsory component is edited by selecting container radio button it shd be saved
      as componnet for shipping unit only- code starts */
    let compissavedfor;
    if(this.componentdetailsforedit_ee.is_compulsary=='Yes')
    {
      compissavedfor='Shipping Unit'
    }
    else
    {
      compissavedfor=this.componentdetailsforedit_ee.compselctnfor;
    }

    /* Even when compulsory component is edited by selecting container radio button it shd be saved
      as componnet for shipping unit only - code ends*/

    this.variableforsave_ee=
    {
      id_proposal:this.componentdetailsforedit_ee.id_proposal,
      id_comp:Number(this.componentdetailsforedit_ee.id_comp),
      comp_base:this.componentdetailsforedit_ee.comp_base,
      comp_suffix:this.componentdetailsforedit_ee.comp_suffix,
      comp_desc:this.componentdetailsforedit_ee.comp_desc,
      qt_per_min_su:this.componentdetailsforedit_ee.qt_per_min_su,
      comp_ret_type:this.componentdetailsforedit_ee.comp_ret_type,
      upated_by:this.componentdetailsforedit_ee.updated_by,
      updated_dt:this.componentdetailsforedit_ee.updated_dt,
      comp_l:Number(this.componentdetailsforedit_ee.comp_l),
      comp_w:Number(this.componentdetailsforedit_ee.comp_w),
      comp_h:Number(this.componentdetailsforedit_ee.comp_h),
      comp_tare_wt:this.componentdetailsforedit_ee.comp_tar_wt,
      nestedfolderht:Number(this.componentdetailsforedit_ee.nestedfolderHt),
      comp_code:this.componentdetailsforedit_ee.comp_code,
      comp_ownershp:this.componentdetailsforedit_ee.comp_ownershp,
      comp_material:this.componentdetailsforedit_ee.comp_material,
      tooledcomp:this.componentdetailsforedit_ee.tooledcomp,
      is_compulsary:this.componentdetailsforedit_ee.is_compulsary,
      compselctnfor:compissavedfor,
      material_flag:this.selected_material_ee.comp_type_flag,
      typ_no:this.componentdetailsforedit_ee.typ_no
      // material flag added
    }

    this.compDescInValidationForEdit_ee();
    this.compLenInValidationForEdit_ee();
    this.compWidInValidationForEdit_ee();
    this.compHgtInValidationForEdit_ee();
    this.compTareWgtInValidationForEdit_ee();
    this.compQtPerShpUntInValidationForEdit_ee();
    this.ownershpInValidationForEdit_ee();
    // this.tooledCompInValidationForEdit_ee();
    this.selectedMaterialInValidationForEdit_ee();

    this.comp_tarwgt_shpunitwgt_validation_edit_ee();

    this.expAidswgtcalulation_edit_ee();

    console.log("wgt_in_save", this.temp_comwgt_edit_ee,this.shp_comp_wt_edit_ee,this.comb_wgt_ee);
    console.log("Inside save_edit ",this.ownrshpInValid_ee,this.selectMaterialInValid_ee)
   
    if(!this.comp_descInValid_ee && !this.comp_l_InValid_ee && !this.comp_w_InValid_ee && !this.comp_h_InValid_ee && !this.comp_tare_wgtInValid_ee && !this.qt_pershp_untInValid_ee 
      && !this.sumwgts_InValid_ee && !this.ownrshpInValid_ee && !this.selectMaterialInValid_ee && !this.comp_totl_wt_InValid_ee)
    {
    this.transactionService.storecomponentdetails_ee(this.variableforsave_ee).subscribe(editout=>{
      const editstatus=editout;
      console.log('Success',editstatus);
      if(editstatus=='Success')
      {
        this.packagingproposal.addtoast({severity:'success', summary:'Success', detail:' Component Details Updated Successfully'})
        this.populateListOfMaterialsGrid_ee([]);
        this.populateGrid_ee();
        this.componentdetailseditinit();
        this.componentdetailsinit();
        this.compInValidationInit_ee();
      }
      else{
        this.packagingproposal.addtoast({severity:'error', summary:'Error', detail:' Component Details not Updated'})

        if(this.unitom_ee == 'E')
        {
          this.edit_measure_comp_gridedit_ee = 'lbs/inch';
        }
        else
        {
          this.edit_measure_comp_gridedit_ee = 'kg/cm';
        }
        // if(this.edit_measure_comp_gridedit_ee=="kg/cm")
        // {



        //   if(this.componentdetailsforedit_ee.comp_l!=null)
        //   {
        //     this.componentdetailsforedit_ee.comp_l=Math.round((2.54*this.componentdetailsforedit_ee.comp_l)*1000)/1000;

        //   }
        //   if(this.componentdetailsforedit_ee.comp_w!=null)
        //   {
        //     this.componentdetailsforedit_ee.comp_w=Math.round((2.54*this.componentdetailsforedit_ee.comp_w)*1000)/1000;

        //   }
        //   if(this.componentdetailsforedit_ee.comp_h!=null)
        //   {
        //     this.componentdetailsforedit_ee.comp_h=Math.round((2.54*this.componentdetailsforedit_ee.comp_h)*1000)/1000;

        //   }
        //   if(this.componentdetailsforedit_ee.nestedfolderHt!=null)
        //   {
        //     this.componentdetailsforedit_ee.nestedfolderHt=Math.round((2.54*this.componentdetailsforedit_ee.nestedfolderHt)*1000)/1000;

        //   }
        //   if(this.componentdetailsforedit_ee.comp_tar_wt!=null)
        //   {
        //     this.componentdetailsforedit_ee.comp_tar_wt=Math.round((0.453592*this.componentdetailsforedit_ee.comp_tar_wt)*1000)/1000;

        //   }



        // }
      }
    })
  }

  else
  {
    this.displayeditdialog_ee = true;
    this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Check the Component details and Try Again'});
  }

  }

  closeAlertDialog()
  {
    this.enable_savecomps = true;

    console.log('Inside Enable_func',this.enable_savecomps)
  }

  cancel_componentdetailsforedit_ee()
  {
    this.editcancel_click_ee=true;
    this.displayeditdialog_ee=false;
    this.populateListOfMaterialsGrid_ee([]);
    this.populateGrid_ee();
    this.componentdetailseditinit();
    this.componentdetailsinit();
    this.compInValidationInit_ee();

  }

  updateCompulsoryComponents_ee()
  {
    this.componentsStoredInProposal=JSON.parse(JSON.stringify(this.componentdetailsgrid_ee));

    var cntr_base=this.Tab2DataForPost_ee.cntr_base;
    var cntr_suffix="";

    if(this.Tab2DataForPost_ee.cntr_suff=="" || this.Tab2DataForPost_ee.cntr_suff==null)
    {
      cntr_suffix="nosuffix";
    }
    else
    {
      cntr_suffix=this.Tab2DataForPost_ee.cntr_suff;
    }

    this.transactionService.getcomponentdetailsbybaseandsuffix(cntr_base,cntr_suffix,this.contadmindetls_ee.ownrshp_strat_code,this.region_code_ee).subscribe(compFromCMMS=>
    {
        
        this.componentsFetchedFromCMMS=compFromCMMS;
        
        console.log("componentsFetchedFromCMMS",this.componentsFetchedFromCMMS);

        const compulsorycomponent:storecomponentforpost[]=[];
        var i=0;
        
        if(this.componentsFetchedFromCMMS)
        {
          for (var comp of this.componentsFetchedFromCMMS)
          {
            var c;
            if(comp.is_compulsary=="Y")
            {
              if(comp.comp_ret_type=="Returnable")
              {
                c="R";
              }
              else
              {
                c="E";
              }

              var ownershp;
              if(comp.comp_ownershp != null && comp.comp_ownershp.toLowerCase() == "ford")
              {
                ownershp = "Ford";
              }
              else if(comp.comp_ownershp != null && comp.comp_ownershp.toLowerCase().indexOf("supp")>-1)
              {
                ownershp = "Supplier";
              }
              else if(comp.comp_ownershp != null && (comp.comp_ownershp.toLowerCase() == "tpt" || comp.comp_ownershp.toLowerCase() == "third party"))
              {
                ownershp = "Third Party";
              }
              compulsorycomponent.push(
              { 
                  id_proposal:this.selectedpart_submitter_tab3_ee.proposalid,
                  id_comp:0,
                  comp_base:comp.no_comp_base,
                  comp_suffix:comp.no_comp_suffix,
                  comp_desc:comp.comp_desc,
                  qt_per_min_su:comp.qt_per_min_su,
                  comp_ret_type:c,
                  upated_by:this.transactionService.getcdsid(),
                  updated_dt:new Date().toISOString().split('T')[0],
                  comp_l:comp.comp_l,
                  comp_w:comp.comp_w,
                  comp_h:comp.comp_h,
                  comp_tare_wt:comp.comp_tare_wt,
                  nestedfolderht:comp.nestedfolderht,
                  comp_code:comp.comp_code,
                  comp_ownershp:ownershp,
                  comp_material:comp.comp_material,
                  tooledcomp:"No",
                  is_compulsary:"Yes",
                  compselctnfor:"Shipping Unit",
                  material_flag:"",
                  typ_no:comp.typ_no
                  //material flag added
              });
              i++;
            }
            
          }
        }
   
          this.compulsoryComponentFromCMMS=JSON.parse(JSON.stringify(compulsorycomponent)); // will have all compulsory components associated with selected container
          console.log("compulsoryComponentFromCMMS",this.compulsoryComponentFromCMMS);
          console.log("Components from draft proposal",this.componentsStoredInProposal);
          

          var compulsoryComponentToBeAdded:storecomponentforpost[]=[];
          var compulsoryComponentToBeMadeToNo:storecomponent[]=[];


          //case 1: compulsory component are same in draft and cmms
          for(var i=0;i<2;i++)
          {
            var componentExistInProposal=false;
            var componentNotInCMMS=false;

            //check if compulsory component present in cmms is not in draft, if yes add it to componenttobeadded list
           if(this.componentsStoredInProposal!=null && this.componentsStoredInProposal.length>0)
           {
            if(this.compulsoryComponentFromCMMS!=null && i<this.compulsoryComponentFromCMMS.length && this.compulsoryComponentFromCMMS.length>0 && this.compulsoryComponentFromCMMS.length<=2)
            {
              var component = this.componentsStoredInProposal.findIndex(e=>e.comp_base.trim().toLowerCase()== this.compulsoryComponentFromCMMS[i].comp_base.trim().toLowerCase() && e.comp_suffix.trim()==this.compulsoryComponentFromCMMS[i].comp_suffix.trim() && e.is_compulsary.toLowerCase()=="yes")
              if(component!=-1)
              {
                componentExistInProposal=true;
              }
              else{
                componentExistInProposal=false;
                //alert("came to add case");
                compulsoryComponentToBeAdded.push(this.compulsoryComponentFromCMMS[i]);
              }
            }
            else
            {    
              componentExistInProposal=true;    
            }
           }
           else
           {
            if(this.compulsoryComponentFromCMMS!=null && i<this.compulsoryComponentFromCMMS.length && this.compulsoryComponentFromCMMS.length>0 && this.compulsoryComponentFromCMMS.length<=2)
            {
              // If there is no components in draft but compuslory comp is in CMMS add all of them to proposal
             compulsoryComponentToBeAdded.push(this.compulsoryComponentFromCMMS[i])
            }
           }
        

            //check if compulsory component present in draft proposal is not present in CMMS, then add it to compulsoryComponentToBeMadeToNo list
            var compulsoryComponentsInDraftProposal=this.componentsStoredInProposal.filter(e=>e.is_compulsary.toLowerCase()=="yes");

            if(compulsoryComponentsInDraftProposal!=null && compulsoryComponentsInDraftProposal.length!=0 && i<compulsoryComponentsInDraftProposal.length && compulsoryComponentsInDraftProposal.length>0 && compulsoryComponentsInDraftProposal.length<=2 )
            {
              if(this.compulsoryComponentFromCMMS!=null && this.compulsoryComponentFromCMMS.length>0 && this.compulsoryComponentFromCMMS.length<=2 )
              {
                var componentPresentCheck=this.compulsoryComponentFromCMMS.findIndex(e=>e.comp_base.trim().toLowerCase()==compulsoryComponentsInDraftProposal[i].comp_base.trim().toLowerCase() && e.comp_suffix.trim()==compulsoryComponentsInDraftProposal[i].comp_suffix.trim())
           
                if(componentPresentCheck==-1)
                {
                  componentNotInCMMS=true;
                  //alert("came to make no case");
                  compulsoryComponentToBeMadeToNo.push(compulsoryComponentsInDraftProposal[i]);
                }
                else
                {
                  componentNotInCMMS=false;
                }
              }
              else
              {
                // Because in CMMS there is no compulsary component at all
                componentNotInCMMS=true;
                compulsoryComponentToBeMadeToNo=[];
                compulsoryComponentToBeMadeToNo.push(compulsoryComponentsInDraftProposal[i]);
                break;
              }
              
            }
          }

          console.log(compulsoryComponentToBeMadeToNo.length,compulsoryComponentToBeAdded.length)
          if(compulsoryComponentToBeMadeToNo.length==0 && compulsoryComponentToBeAdded.length==0)
          {
            this.packagingproposal.addtoast({severity:'success', summary:'Success', detail:'Compulsory Component Updated Successfully!'})
          }

          // Making Components as Non Compulsory
          if(compulsoryComponentToBeMadeToNo!=null && compulsoryComponentToBeMadeToNo.length>0)
          {
            
            for(var comps of compulsoryComponentToBeMadeToNo)
            {
              var variableforsavecase1:storecomponentforpost;

              variableforsavecase1={ 
                id_proposal:comps.id_proposal,
                id_comp:comps.id_comp,
                comp_base:comps.comp_base,
                comp_suffix:comps.comp_suffix,
                comp_desc:comps.comp_desc,
                qt_per_min_su:comps.qt_per_min_su,
                comp_ret_type:comps.comp_ret_type,
                upated_by:comps.updated_by,
                updated_dt:new Date().toISOString().split('T')[0],
                comp_l:comps.comp_l,
                comp_w:comps.comp_w,
                comp_h:comps.comp_h,
                comp_tare_wt:comps.comp_tar_wt,
                nestedfolderht:comps.nestedfolderHt,
                comp_code:comps.comp_code,
                comp_ownershp:comps.comp_ownershp,
                comp_material:comps.comp_material,
                tooledcomp:"No",
                is_compulsary:"No",
                compselctnfor:"Shipping Unit",
                material_flag:"",
                typ_no:comp.typ_no
              }

              this.transactionService.storecomponentdetails_ee(variableforsavecase1).subscribe(output=>{
                let editstatus=output;
                console.log("Success",editstatus);
                if(editstatus=="Success")
                {
                  this.packagingproposal.addtoast({severity:'success', summary:'Success', detail:'Compulsory Component Updated Successfully'})
                  this.populateListOfMaterialsGrid_ee([]);
                  this.populateGrid_ee();
                  this.componentdetailseditinit();
                  this.componentdetailsinit();
                  this.compInValidationInit_ee(); 
          
          
                }
                else{
                  this.packagingproposal.addtoast({severity:'error', summary:'Error', detail:' Component Details not Updated'})
          
                }
              });          
            }
            
          }

          //Adding or Updating Compulsory Component
          if(compulsoryComponentToBeAdded!=null && compulsoryComponentToBeAdded.length>0)
          {
            for(let comp of compulsoryComponentToBeAdded)
            {
              let findInGrid=this.componentsStoredInProposal.findIndex(e=>e.comp_base.trim().toLowerCase()==comp.comp_base.trim().toLowerCase() && e.comp_suffix.trim()==comp.comp_suffix.trim());

              if(findInGrid==-1)
              {
                this.transactionService.storecomponentdetails_ee(comp).subscribe(output2=>{
                  let editstatus2=output2;
                  console.log("Success",editstatus2);
                  if(editstatus2=="Success")
                  {
                    this.packagingproposal.addtoast({severity:'success', summary:'Success', detail:'Compulsory Component Updated Successfully'})
                    this.populateListOfMaterialsGrid_ee([]);
                    this.populateGrid_ee();
                    this.componentdetailseditinit();
                    this.componentdetailsinit();
                    this.compInValidationInit_ee();
            
            
                  }
                  else{
                    this.packagingproposal.addtoast({severity:'error', summary:'Error', detail:' Component Details not Updated'})
            
                  }
                }); 
              }

              else  // When component already exist as non-compulsory component
              {

                let variableforsave:storecomponentforpost=
                {
                  id_proposal:this.componentsStoredInProposal[findInGrid].id_proposal,
                  comp_base:this.componentsStoredInProposal[findInGrid].comp_base,
                  comp_suffix:this.componentsStoredInProposal[findInGrid].comp_suffix,
                  comp_desc:this.componentsStoredInProposal[findInGrid].comp_desc,
                  qt_per_min_su:this.componentsStoredInProposal[findInGrid].qt_per_min_su,
                  comp_ret_type:this.componentsStoredInProposal[findInGrid].comp_ret_type,
                  upated_by:this.componentsStoredInProposal[findInGrid].updated_by,
                  updated_dt:this.componentsStoredInProposal[findInGrid].updated_dt,
                  comp_l:this.componentsStoredInProposal[findInGrid].comp_l,
                  comp_w:this.componentsStoredInProposal[findInGrid].comp_w,
                  comp_h:this.componentsStoredInProposal[findInGrid].comp_h,
                  comp_tare_wt:this.componentsStoredInProposal[findInGrid].comp_tar_wt,
                  nestedfolderht:this.componentsStoredInProposal[findInGrid].nestedfolderHt,
                  comp_code:this.componentsStoredInProposal[findInGrid].comp_code,
                  comp_ownershp:this.componentsStoredInProposal[findInGrid].comp_ownershp,
                  comp_material:this.componentsStoredInProposal[findInGrid].comp_material,
                  tooledcomp:this.componentsStoredInProposal[findInGrid].tooledcomp,
                  is_compulsary:"Yes",
                  id_comp:this.componentsStoredInProposal[findInGrid].id_comp,
                  compselctnfor:this.componentsStoredInProposal[findInGrid].compselctnfor,
                  material_flag:'',
                  typ_no:this.componentsStoredInProposal[findInGrid].typ_no
                }
                

                console.log(variableforsave)
                this.transactionService.storecomponentdetails_ee(variableforsave).subscribe(output3=>{
                  let editstatus3=output3;
                  console.log("Success",editstatus3);
                  if(editstatus3=="Success")
                  {
                    this.packagingproposal.addtoast({severity:'success', summary:'Success', detail:'Compulsory Component Updated Successfully'})
                     this.populateListOfMaterialsGrid_ee([]);
                    this.populateGrid_ee();
                    this.componentdetailseditinit();
                    this.componentdetailsinit();
                    this.compInValidationInit_ee(); 
            
            
                  }
                  else{
                    this.packagingproposal.addtoast({severity:'error', summary:'Error', detail:' Component Details not Updated'})
            
                  }
                }); 

              }
            }
          }

      });

  }




  deletecompdetails_ee(rowData)
  {
    let flagfordelete=false;
    var ComponentHeightTobeChangedDeleteFlag_ee=false;
    this.componentdetailsfordelete_ee=rowData;
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete component ' + this.componentdetailsfordelete_ee.comp_base +this.componentdetailsfordelete_ee.comp_suffix+ '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
          console.log(this.componentdetailsfordelete_ee);

          for(const i of this.componentdetailsgrid_ee)
          {
            if(i.comp_base.trim()==this.componentdetailsfordelete_ee.comp_base.trim())
            {
              if(this.componentdetailsfordelete_ee.comp_suffix!=null && i.comp_suffix!=null)
                {
                  if(this.componentdetailsfordelete_ee.comp_suffix.trim()==i.comp_suffix.trim())
                  {
                    flagfordelete=true;
                  }
                }
                else if(this.componentdetailsfordelete_ee.comp_suffix==null && i.comp_suffix==null)
                {
                  flagfordelete=true;
                }
            }

          }
            if(flagfordelete)
            {
              this.containergrossweightInKGS_ee=this.containergrossweight_ee;
              this.shippingunitgrossweightInKGS_ee=this.shippingunitgrossweight_ee;

              this.transactionService.deletecomponentdetails_ee(this.componentdetailsfordelete_ee.id_proposal,this.componentdetailsfordelete_ee.id_comp).subscribe(delout=>{
                const deletionstatus=delout;
                console.log(deletionstatus,'Success');
                if(deletionstatus=='Success')
                {
                  this.packagingproposal.addtoast({severity:'success', summary:'success', detail:'Component Deleted Successfully'});

                  if(this.componentdetailsfordelete_ee.compselctnfor=='Container')
                  {
                   // alert("subtracted container gross weight"+this.containergrossweight)



                  if(this.unitom_ee == 'E')
                  {
                    this.edit_measure_comp_add_ee = 'lbs/inch';
                  }
                  else
                  {
                    this.edit_measure_comp_add_ee = 'kg/cm';
                  }

                    // if(this.edit_measure_comp_ee=="kg")
                    // {
                    //   this.containergrossweightInKGS_ee-=Math.round(0.453592*(this.componentdetailsfordelete_ee.qt_per_min_su*this.componentdetailsfordelete_ee.comp_tar_wt)*1000)/1000;
                    //   this.shippingunitgrossweightInKGS_ee-=Math.round(0.453592*(this.no_of_container_ee*this.componentdetailsfordelete_ee.qt_per_min_su*this.componentdetailsfordelete_ee.comp_tar_wt)*1000)/1000;
                    // }

                    this.containergrossweight_ee-=(this.componentdetailsfordelete_ee.qt_per_min_su*this.componentdetailsfordelete_ee.comp_tar_wt);
                    this.shippingunitgrossweight_ee-=(this.no_of_container_ee*this.componentdetailsfordelete_ee.qt_per_min_su*this.componentdetailsfordelete_ee.comp_tar_wt);

                    // trunc grossweight
                    console.log('container grossweight before Round Off',this.containergrossweight_ee)
                    this.containergrossweight_ee_display=this.transactionService.roundValueToNDecimal(this.containergrossweight_ee,3)
                    console.log('container grossweight after Round Off',this.containergrossweight_ee)

                    console.log('shippingunitgrossweight grossweight before Round Off',this.shippingunitgrossweight_ee)
                    this.shippingunitgrossweight_ee_display=this.transactionService.roundValueToNDecimal(this.shippingunitgrossweight_ee,3)
                    console.log('shippingunitgrossweight grossweight after Round Off',this.shippingunitgrossweight_ee)

                    this.shp_comp_wt-=(this.no_of_container_ee*this.componentdetailsfordelete_ee.qt_per_min_su*this.componentdetailsfordelete_ee.comp_tar_wt)
                    this.shippingunittareweight_ee-=(this.no_of_container_ee*this.componentdetailsfordelete_ee.qt_per_min_su*this.componentdetailsfordelete_ee.comp_tar_wt);
                    this.shippingunittareweight_ee_display=this.transactionService.roundValueToNDecimal(this.shippingunittareweight_ee,3)

                    /*if(this.componentdetailsfordelete_ee.typ_no == 2 || this.componentdetailsfordelete_ee.typ_no == 3 || this.componentdetailsfordelete_ee.typ_no == 4)
                    {
                      ComponentHeightTobeChangedDeleteFlag_ee=true;
                      this.shippingUnitExteriorHeightCalculated_ee-=this.noLayersPerShippingUnit_ee*this.componentdetailsfordelete_ee.comp_h*this.componentdetailsfordelete_ee.qt_per_min_su;
                      //this.shippingUnitExteriorHeightCalculated_ee=this.transactionService.roundValueToNDecimal(this.shippingUnitExteriorHeightCalculated_ee,3);
                    }*/



                  }
                  else
                  {
                    // alert("subtracted Shipping unit gross weight"+this.shippingunitgrossweight+'-'+this.componentdetailsfordelete.qt_per_min_su*this.componentdetailsfordelete.comp_tar_wt)

                    if(this.unitom_ee == 'E')
                  {
                    this.edit_measure_comp_add_ee = 'lbs/inch';
                  }
                  else
                  {
                    this.edit_measure_comp_add_ee = 'kg/cm';
                  }
                    // if(this.edit_measure_comp_ee=="kg")
                    // {
                    //   this.shippingunitgrossweightInKGS_ee-=Math.round(0.453592*(this.componentdetailsfordelete_ee.qt_per_min_su*this.componentdetailsfordelete_ee.comp_tar_wt)*1000)/1000;
                    // }

                    this.shippingunitgrossweight_ee-=(this.componentdetailsfordelete_ee.qt_per_min_su*this.componentdetailsfordelete_ee.comp_tar_wt);
                    this.shp_comp_wt-=(this.componentdetailsfordelete_ee.qt_per_min_su*this.componentdetailsfordelete_ee.comp_tar_wt);

                    // trunc grossweight
                    console.log('shippingunitgrossweight grossweight before Round Off',this.shippingunitgrossweight_ee)
                    this.shippingunitgrossweight_ee_display=this.transactionService.roundValueToNDecimal(this.shippingunitgrossweight_ee,3)
                    console.log('shippingunitgrossweight grossweight after Round Off',this.shippingunitgrossweight_ee)
                    this.shippingunittareweight_ee-=(this.componentdetailsfordelete_ee.qt_per_min_su*this.componentdetailsfordelete_ee.comp_tar_wt);
                    this.shippingunittareweight_ee_display=this.transactionService.roundValueToNDecimal(this.shippingunittareweight_ee,3)
                    if(this.componentdetailsfordelete_ee.typ_no == 2 || this.componentdetailsfordelete_ee.typ_no == 3 || this.componentdetailsfordelete_ee.typ_no == 4)
                    {
                      ComponentHeightTobeChangedDeleteFlag_ee=true;
                      this.shippingUnitExteriorHeightCalculated_ee-=this.componentdetailsfordelete_ee.comp_h*this.componentdetailsfordelete_ee.qt_per_min_su;
                      //this.shippingUnitExteriorHeightCalculated_ee=this.transactionService.roundValueToNDecimal(this.shippingUnitExteriorHeightCalculated_ee,3);
                    }
                  }
                  console.log("INSIDEDELCON",this.shp_comp_wt,this.temp_shp_compwt)
		            if(ComponentHeightTobeChangedDeleteFlag_ee)
                  {
                    this.shippingUnitExteriorHeightDisplay_ee=this.transactionService.roundValueToNDecimal(this.shippingUnitExteriorHeightCalculated_ee,3);
                  }
                      
                  if(this.selectedpart_submitter_tab3_ee.proposallastsaved_ee>=3)
                  {
                    this.deleteflag_ee=true;
                    if(this.componentdetailsfordelete_ee.compselctnfor=='Container')
                    {
                    this.comp_material_forpost.cntr_gross_wt=this.containergrossweight_ee_display;
                    this.comp_material_forpost.shp_unt_gross_wt=this.shippingunitgrossweight_ee_display;
                    this.comp_material_forpost.shp_unt_tar_wt=this.shippingunittareweight_ee_display;
                    }
                    else
                    {
                    this.comp_material_forpost.shp_unt_gross_wt=this.shippingunitgrossweight_ee_display;
                    this.comp_material_forpost.shp_unt_tar_wt=this.shippingunittareweight_ee_display;
                    }
                    this.transactionService.savetab3details_ee(this.comp_material_forpost).subscribe(weightupdd=>
                      {
                        const weightupdatestatfordelete=weightupdd;
                          let msgs:any[];
                          if(weightupdatestatfordelete=='Success')
                          {
                            // componentweightshdbeupdated=false;
                            this.packagingproposal.addtoast({severity:'success', summary: 'success', detail: 'Shipping Unit Gross Weight Updated'});
                          }
                          else{
                            this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Shipping Unit Gross weight not Updated'});
                          }
                      });
                      this.convertweightaftercomponentdeletion_ee()
                  }
                  else
                  {
                    this.deleteflagbeforesave_ee=true;
                    this.convertweightaftercomponentdeletion_ee();
                  }
                  this.loadComponentNumberbasedOnContainerSelected_ee();
                  this.populateListOfMaterialsGrid_ee([]);
                  this.populateGrid_ee();

                }
                else
                {
                  this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Component Not Deleted,Please Try Again'})
                }
              });
            }


      }
  });
  }

  convertweightaftercomponentdeletion_ee()
  {


    if(this.unitom_ee == 'E')
    {
      this.edit_measure_comp_ee = 'lbs';
    }
    else
    {
      this.edit_measure_comp_ee = 'kg';
    }
    if(this.edit_measure_comp_ee=="kg/cm")
    {
      this.containergrossweight_ee=this.containergrossweightInKGS_ee;
      this.shippingunitgrossweight_ee=this.shippingunitgrossweightInKGS_ee;
    }
  }

  getcntrtypelist_ee() // function to populate dropdown
  {
    this.component_type_ee={cd_cntr_typ:'',cntr_typ_and_desc:'',cntr_typ_desc:'select component type',typ_no:0};
    this.comp_typ_list_ee=[];
    this.items_ee=[];

    this.componentlistbasedontype_ee=[];


    this.transactionService.getContainerTypeCode().subscribe(typ=>{
      this.comp_type_ee=typ;
      console.log(this.comp_type_ee);


      const tempcompstypeanddesc:componenttypewithcodeanddesc[]=[];
      for(const i of this.comp_type_ee)
      {
        tempcompstypeanddesc.push({cd_cntr_typ:i.cd_cntr_typ,cntr_typ_desc:i.cntr_typ_desc,cntr_typ_and_desc:i.cd_cntr_typ+'-'+i.cntr_typ_desc,typ_no:i.typ_no})
      }

      this.comp_type_and_desc_master_ee=tempcompstypeanddesc;

      if(this.exp_returnable_ee!=null && this.componentOrAid_ee!=null && this.comp_type_and_desc_master_ee!=null )
      {
        if(this.componentOrAid_ee.toLowerCase()=='component')
        {
          this.comp_type_and_desc_ee=JSON.parse(JSON.stringify(this.comp_type_and_desc_master_ee.filter(e=>['01','02','03'].includes(String(e.cd_cntr_typ)))))

        }
        if(this.componentOrAid_ee.toLowerCase()=='aid')
        {
          if(this.exp_returnable_ee.toLowerCase()=='e')
          {
            this.comp_type_and_desc_ee=JSON.parse(JSON.stringify(this.comp_type_and_desc_master_ee.filter(e=>(!['01','02','03'].includes(String(e.cd_cntr_typ))))))
          }
          if(this.exp_returnable_ee.toLowerCase()=='r')
          {
            this.comp_type_and_desc_ee=JSON.parse(JSON.stringify(this.comp_type_and_desc_master_ee.filter(e=>(['04','05'].includes(String(e.cd_cntr_typ))))))
          }
        }
      }

      /*for(var i of this.comp_type)
      {
        console.log(i.cd_cntr_typ,typeof(i.cd_cntr_typ));
        if( ["1","2","3","4"].includes(i.cd_cntr_typ.toString()))
        {
          console.log(i.cntr_typ_desc);
          this.comp_typ_list.push(<TreeNode>{label:i.cd_cntr_typ+"-"+i.cntr_typ_desc,data:i.cd_cntr_typ+"-"+i.cntr_typ_desc,expandedIcon:"pi pi-folder-open" });
        }
        else if(!["5"].includes(i.cd_cntr_typ.toString())){
          this.items.push(<TreeNode>{label:i.cd_cntr_typ+"-"+i.cntr_typ_desc,data:i.cd_cntr_typ+"-"+i.cntr_typ_desc,expandedIcon:"pi pi-folder-open"});
        }
      }
      this.comp_typ_list.push(<TreeNode>{label:'Other',data:'Other',children:this.items,selectable:false});

      console.log(this.comp_typ_list);*/

    });
  }

  // getselectedtype(event,op) //function to set dropdown with selected value and load the component selected
  getselectedtype_ee(op)
  {
    console.log('came here');
    /*console.log("selected component type is ",this.val.data,event.label,event.node.data);
    op.hide(event);

    var cnum=this.val.data.split("-")[0];
    var ctype=this.val.data.split("-")[1];
    this.component_type=this.val.data.split("-")[1];
  */

  console.log('op',op.cntr_typ_and_desc);

   this.loadComponentNumberbasedOnContainerSelected_ee();

  }


  loadComponentNumberbasedOnContainerSelected_ee()
  {

    this.transactionService.loadtab2detailsifproposalexistalready_ee(this.selectedpart_submitter_tab3_ee.proposalid).subscribe(results=>
      {
        const selected_container_from_tab2=results;

        if(this.component_type_ee.cntr_typ_and_desc != null && this.component_type_ee.cntr_typ_and_desc != '')
        {
        const typecode=this.component_type_ee.cntr_typ_and_desc.split('-')[0];
        const typedesc=this.component_type_ee.cntr_typ_and_desc.split('-')[1];
        const typeNo=this.component_type_ee.typ_no;
        }

        console.log('selected_container_from_tab2',selected_container_from_tab2);
        if(selected_container_from_tab2.cntr_suff=='' || selected_container_from_tab2.cntr_suff==null)
        {
          selected_container_from_tab2.cntr_suff='0';
        }


      if(this.component_type_ee != null && this.component_type_ee.cntr_typ_and_desc != '' && this.componentOrAid_ee!=null )
      {
        // Removed ["01","02","03","04","05"].includes(this.component_type_ee.cd_cntr_typ)
        if( this.componentOrAid_ee.toLowerCase()=='component' && this.componenentassociatedwithcontainer_ee)
        {
          this.tempcomps_ee=[];


          for(const compns of this.componenentassociatedwithcontainer_ee)
          {
              let c;
              let compc;
              let suffix;

              if(compns.no_comp_suffix==undefined || compns.no_comp_suffix==null)
              {
                  suffix=null
              }
              else
              {
                suffix=compns.no_comp_suffix;
              }
              if(compns.comp_ret_type=='Returnable')
              {
                c='R';
              }
              else
              {
                c='E';
              }
              if(compns.is_compulsary=='Y')
              {
                compc='Yes'
              }
              else
              {
                compc='No'
              }

              var ownershp_ee;
                if(compns.comp_ownershp != null && compns.comp_ownershp.toLowerCase() == "ford")
                {
                  ownershp_ee = "Ford";
                }
                else if(compns.comp_ownershp != null && compns.comp_ownershp.toLowerCase().indexOf("supp")>-1)
                {
                  ownershp_ee = "Supplier";
                }
                else if(compns.comp_ownershp != null && (compns.comp_ownershp.toLowerCase() == "tpt" || compns.comp_ownershp.toLowerCase() == "third party"))
                {
                  ownershp_ee = "Third Party";
                }

              if (c==this.exp_returnable_ee)
              {
                if(this.component_type_ee.cd_cntr_typ=='01')
                {

                  this.tempcomps_ee.push
                  ({
                    id_proposal:this.selectedpart_submitter_tab3_ee.proposalid,
                    id_comp:compns.id_comp,
                    comp_base:compns.no_comp_base,
                    comp_suffix:suffix,
                    comp_desc:compns.comp_desc,
                    qt_per_min_su:compns.qt_per_min_su,
                    comp_ret_type:compns.comp_ret_type,
                    updated_by:'',
                    updated_dt:'',
                    comp_l:compns.comp_l,
                    comp_w:compns.comp_w,
                    comp_h:compns.comp_h,
                    comp_tar_wt:compns.comp_tare_wt,
                    nestedfolderHt:compns.nestedfolderht,
                    comp_code:compns.comp_code,
                    comp_ownershp:ownershp_ee,
                    comp_material:compns.comp_material,
                    tooledcomp:'',
                    is_compulsary:compc,
                    comp_bsd:compns.no_comp_base+'-'+suffix+'-'+compns.comp_desc,
                    comp_lwh:compns.comp_l+'x'+compns.comp_w+'x'+compns.comp_h,
                    compselctnfor:this.cntrorshpunitradio_ee,
                    typ_no:compns.typ_no

                    });
                }
                else
                {
                // @ts-ignore
                    if(compns.typ_no==typeNo )// &&  compns.is_compulsary=="Y"))
                {

                  this.tempcomps_ee.push
                  ({
                    id_proposal:this.selectedpart_submitter_tab3_ee.proposalid,
                    id_comp:compns.id_comp,
                    comp_base:compns.no_comp_base,
                    comp_suffix:suffix,
                    comp_desc:compns.comp_desc,
                    qt_per_min_su:compns.qt_per_min_su,
                    comp_ret_type:compns.comp_ret_type,
                    updated_by:'',
                    updated_dt:'',
                    comp_l:compns.comp_l,
                    comp_w:compns.comp_w,
                    comp_h:compns.comp_h,
                    comp_tar_wt:compns.comp_tare_wt,
                    nestedfolderHt:compns.nestedfolderht,
                    comp_code:compns.comp_code,
                    comp_ownershp:ownershp_ee,
                    comp_material:compns.comp_material,
                    tooledcomp:'',
                    is_compulsary:compc,
                    comp_bsd:compns.no_comp_base+'-'+suffix+'-'+compns.comp_desc,
                    comp_lwh:compns.comp_l+'x'+compns.comp_w+'x'+compns.comp_h,
                    compselctnfor:this.cntrorshpunitradio_ee,
                    typ_no:compns.typ_no

                    });
                  console.log('components for the container is ',this.tempcomps_ee);


                }
              }

              }

            }
            this.componentlistbasedontype_ee=this.tempcomps_ee;
            this.checkcomponentdetailsalreadypresentingrid_ee();


        console.log('componenrtlistbasedontype in line 949'+JSON.stringify(this.componentlistbasedontype_ee))


        }
        else if(this.componentOrAid_ee.toLowerCase()=='aid')
        {
          this.tempcomps_ee=[];
          this.tempcomps_ee.push(
            {

              id_proposal:this.selectedpart_submitter_tab3_ee.proposalid,
              id_comp:0,
              comp_base:this.component_type_ee.cntr_typ_desc,
              comp_suffix:' ',
              comp_desc:'',
              qt_per_min_su:0,
              comp_ret_type:this.exp_returnable_ee,
              updated_by:'',
              updated_dt:'',
              comp_l:0,
              comp_w:0,
              comp_h:0,
              comp_tar_wt:0,
              nestedfolderHt:0,
              comp_code:this.component_type_ee.cd_cntr_typ,
              comp_ownershp:'',
              comp_material:'',
              tooledcomp:'',
              is_compulsary:'No',
              comp_bsd:this.component_type_ee.cntr_typ_desc,
              comp_lwh:"0",
              compselctnfor:this.cntrorshpunitradio_ee,
              typ_no:this.component_type_ee.typ_no
            });

          this.componentlistbasedontype_ee=JSON.parse(JSON.stringify(this.tempcomps_ee));
          console.log('in load function',this.componentlistbasedontype_ee);

         /* this.transactionService.getaddlncompids(this.component_type_ee.cd_cntr_typ,this.exp_returnable_ee).subscribe(ret=>
          {
            this.additionalcomponentIDs_ee=ret;

            if(this.additionalcomponentIDs_ee.length==1)
            {
              var lwh;
              var suffix;
              this.transactionService.getaddlncompdetls(this.additionalcomponentIDs_ee[0].comp_id).subscribe(outputss=>
                {
                  this.additionalcomponentDetails_ee=outputss;

                  if(this.additionalcomponentDetails_ee.comp_l!=null || this.additionalcomponentDetails_ee.comp_w!=null || this.additionalcomponentDetails_ee.comp_h!=null)
                    {
                      lwh="0x0x0";
                    }
                    else{
                      lwh=this.additionalcomponentDetails_ee.comp_l+'x'+this.additionalcomponentDetails_ee.comp_w+'x'+this.additionalcomponentDetails_ee.comp_h;
                    }

                  if(this.additionalcomponentIDs_ee[0].comp_suffix==undefined || this.additionalcomponentIDs_ee[0].comp_suffix==null)
                  {
                    suffix=null;
                  }
                  else{
                    suffix=this.additionalcomponentIDs_ee[0].comp_suffix;
                  }
                  this.tempcomps_ee.push
                  ({
                    id_proposal:this.selectedpart_submitter_tab3_ee.proposalid,
                    id_comp:0,
                    comp_base:this.additionalcomponentIDs_ee[0].comp_base,
                    comp_suffix:suffix,
                    comp_desc:this.additionalcomponentIDs_ee[0].comp_desc,
                    qt_per_min_su:Number(this.additionalcomponentDetails_ee.qt_per_min_su),
                    comp_ret_type:this.additionalcomponentIDs_ee[0].comp_ret_type,
                    updated_by:this.additionalcomponentDetails_ee.updated_by,
                    updated_dt:this.additionalcomponentDetails_ee.updated_dt,
                    comp_l:this.additionalcomponentDetails_ee.comp_l,
                    comp_w:this.additionalcomponentDetails_ee.comp_w,
                    comp_h:this.additionalcomponentDetails_ee.comp_h,
                    comp_tar_wt:this.additionalcomponentDetails_ee.comp_wt,
                    nestedfolderHt:this.additionalcomponentDetails_ee.nestedfoldedht,
                    comp_code:this.additionalcomponentIDs_ee[0].comp_type,
                    comp_ownershp:this.additionalcomponentDetails_ee.comp_ownershp,
                    comp_material:this.additionalcomponentDetails_ee.comp_material,
                    tooledcomp:this.additionalcomponentDetails_ee.tooledcomp,
                    is_compulsary:"No",
                    comp_bsd:this.additionalcomponentIDs_ee[0].comp_base+"-"+suffix+"-"+this.additionalcomponentIDs_ee[0].comp_desc,
                    comp_lwh:lwh,
                    compselctnfor:this.cntrorshpunitradio_ee


                  });

                  this.componentlistbasedontype_ee=JSON.parse(JSON.stringify(this.tempcomps_ee));
                  console.log("in load function",this.componentlistbasedontype_ee);
                  this.checkcomponentdetailsalreadypresentingrid_ee();

                });



            }

            else if(this.additionalcomponentIDs_ee.length>1)
            {


              for(let opl=0; opl<this.additionalcomponentIDs_ee.length; opl++)
              {

                this.transactionService.getaddlncompdetls(this.additionalcomponentIDs_ee[opl].comp_id).subscribe(detlist=>
                {

                  var lwh;
                  var suffix;

                  this.additionalcomponentDetails_ee=detlist;

                  if(this.additionalcomponentDetails_ee.comp_l!=null || this.additionalcomponentDetails_ee.comp_w!=null || this.additionalcomponentDetails_ee.comp_h!=null)
                  {
                    lwh="0x0x0";
                  }
                  else{
                    lwh=this.additionalcomponentDetails_ee.comp_l+'x'+this.additionalcomponentDetails_ee.comp_w+'x'+this.additionalcomponentDetails_ee.comp_h;
                  }

                  if(this.additionalcomponentIDs_ee[opl].comp_suffix==undefined || this.additionalcomponentIDs_ee[opl].comp_suffix==null)
                  {
                  suffix=null
                  }
                  else{
                    suffix=this.additionalcomponentIDs_ee[opl].comp_suffix;
                  }

                  this.tempcomps_ee.push
                  ({
                    id_proposal:this.selectedpart_submitter_tab3_ee.proposalid,
                    id_comp:0,
                    comp_base:this.additionalcomponentIDs_ee[opl].comp_base,
                    comp_suffix:suffix,
                    comp_desc:this.additionalcomponentIDs_ee[opl].comp_desc,
                    qt_per_min_su:Number(this.additionalcomponentDetails_ee.qt_per_min_su),
                    comp_ret_type:this.additionalcomponentIDs_ee[opl].comp_ret_type,
                    updated_by:this.additionalcomponentDetails_ee.updated_by,
                    updated_dt:this.additionalcomponentDetails_ee.updated_dt,
                    comp_l:this.additionalcomponentDetails_ee.comp_l,
                    comp_w:this.additionalcomponentDetails_ee.comp_w,
                    comp_h:this.additionalcomponentDetails_ee.comp_h,
                    comp_tar_wt:this.additionalcomponentDetails_ee.comp_wt,
                    nestedfolderHt:this.additionalcomponentDetails_ee.nestedfoldedht,
                    comp_code:this.additionalcomponentIDs_ee[opl].comp_type,
                    comp_ownershp:this.additionalcomponentDetails_ee.comp_ownershp,
                    comp_material:this.additionalcomponentDetails_ee.comp_material,
                    tooledcomp:this.additionalcomponentDetails_ee.tooledcomp,
                    is_compulsary:"No",
                    comp_bsd:this.additionalcomponentIDs_ee[opl].comp_base+"-"+suffix+"-"+this.additionalcomponentIDs_ee[opl].comp_desc,
                    comp_lwh:lwh,
                    compselctnfor:this.cntrorshpunitradio_ee
                    });//push ends

                    this.componentlistbasedontype_ee=JSON.parse(JSON.stringify(this.tempcomps_ee));
                    console.log("in load function",this.componentlistbasedontype_ee);
                    this.checkcomponentdetailsalreadypresentingrid_ee();

                });
              }

            }
            else // when there is no additional component for the selected type
            {
              this.componentlistbasedontype_ee=[];
            }
          });
          */
        }
      }


    })
  }

  close_add_componnet_dialog_ee()
  {
    this.displaydialog_ee=false;
  }


  checkcomponentdetailsalreadypresentingrid_ee()
  {

    if(this.componentdetailsgrid_ee==null || this.componentdetailsgrid_ee.length==0)
    {
      console.log('componentlistbaseddontype',this.componentlistbasedontype_ee);
    }
    else if(this.componentlistbasedontype_ee!=null && this.componentdetailsgrid_ee!=null && this.componentdetailsgrid_ee.length!=0 && this.componentlistbasedontype_ee.length!=0)
    {
      const tempcomposs=JSON.parse(JSON.stringify(this.componentlistbasedontype_ee));
      const actualComps_ee=[];

      for(let i=0;i<tempcomposs.length;i++)
      {
        let flagcomp=false;

        for(let j=0; j<this.componentdetailsgrid_ee.length; j++)
        {
          if(['01','02','03'].includes(String(tempcomposs[i].comp_code)))
        {
          if(tempcomposs[i].comp_base.trim()==this.componentdetailsgrid_ee[j].comp_base.trim() )
          {
            if(tempcomposs[i].is_compulsary=='Yes' || tempcomposs[i].compselctnfor==this.componentdetailsgrid_ee[j].compselctnfor)
            {
              if(tempcomposs[i].comp_suffix!=null && this.componentdetailsgrid_ee[j].comp_suffix!=null)
              {
                if(tempcomposs[i].comp_suffix.trim()==this.componentdetailsgrid_ee[j].comp_suffix.trim())
                {
                  console.log('came here to remove',tempcomposs[i]);
                  // tempcomposs.splice(i,1);
                  flagcomp=true;
                  // alert(JSON.stringify(this.componentlistbasedontype));
                }
              }
              else if(tempcomposs[i].comp_suffix==null && this.componentdetailsgrid_ee[j].comp_suffix==null)
              {
                // tempcomposs.splice(i,1);
                flagcomp=true;
              }

            }
          }
        }

        }
        if(flagcomp==false)
        {
          actualComps_ee.push(tempcomposs[i]);
        }

      }
      this.componentlistbasedontype_ee=JSON.parse(JSON.stringify(actualComps_ee));
      // alert(JSON.stringify(this.componentlistbasedontype)+this.componentlistbasedontype.length);


    }

  }

  // when add component is clicked
  // will be setting the component details dialog from componentlistbasedontype got from UI
  feeddetailsfortheselectedcomponent_ee(componentnumber:storecomponent)
  {
    this.displaydialog_ee=true;
    this.componentdetails_ee=componentnumber;
    this.componentnumberselected_ee=null;
    this.componentdetails_ee.id_comp=0;
     this.componentdetails_ee.tooledcomp = "No";
    // this.edit_measure_comp_add_ee="lbs/inch";

    if(this.unitom_ee == 'E')
     {
       this.edit_measure_comp_add_ee = 'lbs/inch';
     }
     else
     {
       this.edit_measure_comp_add_ee = 'kg/cm';
     }

    console.log('component details in feeddetailsforselectedcomponent',this.componentdetails_ee);





    let selected_comp_type_no =0;
    selected_comp_type_no=this.component_type_ee.typ_no


   this.selected_material_ee={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''}
        this.transactionService.getContainerMaterial(selected_comp_type_no,this.exp_returnable_ee).subscribe( cntr_mat=>{
          console.log(cntr_mat);
          this.cntrMatr_ee=[];
          this.cntrMatr_ee=cntr_mat;

      // service to get material list using return type and comp type code , inside the service add the below mapping code
      //
        if(this.cntrMatr_ee!= null && this.cntrMatr_ee.length!=0)
        {
        for(const t of this.cntrMatr_ee)
        {
          if(t.cntr_matr_name==componentnumber.comp_material)
          {
            this.selected_material_ee=t;
            break;
          }
        }
        }
      })


  }

  populateListOfMaterialsGrid_ee(value)
  {
    const a=0;
    // alert("debug populateListOfMaterialsGrid "+a++);
    const templist=this.componentlistbasedontype_ee;
    this.componentdetailsgrid_ee=[];

    if((this.selectedpart_submitter_tab3_ee.proposallastsaved_ee<=2 && this.deleteflagbeforesave_ee==false) || this.rowedited_ee || this.rowadded_ee)
    {
      this.containergrossweight_ee=this.temp_cntr_gross_weight_ee;
      this.shippingunittareweight_ee=this.temp_shp_unt_tare_wt_ee;
      this.shippingUnitExteriorHeightCalculated_ee=this.initialShippingUnitExteriorHeightCalculated_ee;
    //this.shippingunitgrossweight_ee=(this.no_of_container_ee*this.containergrossweight_ee)+this.shippingunittareweight_ee;
    this.shippingunitgrossweight_ee=(this.no_of_container_ee*this.containergrossweight_ee);

            // trunc grossweight

            console.log("container grossweight before Round Off",this.containergrossweight_ee)
            this.containergrossweight_ee_display=this.transactionService.roundValueToNDecimal(this.containergrossweight_ee,3)
             console.log("container grossweight after Round Off",this.containergrossweight_ee)

  	          console.log("shippingunitgrossweight grossweight before Round Off",this.shippingunitgrossweight_ee)
              this.shippingunitgrossweight_ee_display=this.transactionService.roundValueToNDecimal(this.shippingunitgrossweight_ee,3)
             console.log("shippingunitgrossweight grossweight after Round Off",this.shippingunitgrossweight_ee) 

             console.log("shipping unit tare weight before Round Off",this.shippingunittareweight_ee)
            this.shippingunittareweight_ee_display=this.transactionService.roundValueToNDecimal(this.shippingunittareweight_ee,3)
            console.log("shipping unit tare weight after Round Off",this.shippingunittareweight_ee)

    console.log('shipping unit gross weight in 1389',this.shippingunitgrossweight_ee)// +this.containergrossweight,this.shippingunittareweight,this.shippingunitgrossweight);
    }

    // checking value.comp_base is not null because when there is value in componentlistbasedontype and
    // if this function is called in delete or anyoother place where we send empty object it will throw error
    // so validating the passed parameter to ensure it is not empty

    if(this.componentlistbasedontype_ee!=null && this.componentlistbasedontype_ee.length!=0 && value.comp_base!=null)
    {


      let flag=false;
      const templist:storecomponent[]=this.componentlistbasedontype_ee;
      for(let kj=0;kj<templist.length;kj++)
      {
        if(['01','02','03'].includes(templist[kj].comp_code))
        {
        if (templist[kj].comp_base.trim()==value.comp_base.trim())
        {
          if(templist[kj].comp_suffix!=null && value.comp_suffix!=null)
                {
                  if(templist[kj].comp_suffix.trim()==value.comp_suffix.trim())
                    {

                      templist.splice(kj,1);
                      flag=true;
                    }
                }
                else
                {
                  flag=true;
                  templist.splice(kj,1);
                }
      }
    }
      }
      if(flag)
      {
        this.componentlistbasedontype_ee=JSON.parse(JSON.stringify(templist));
      }
    }

  }

  populateGrid_ee()
  {
    // this.componentdetailsgrid=[];

    this.transactionService.getaddedcomponentdetailsfortab3_ee(this.selectedpart_submitter_tab3_ee.proposalid).subscribe(gridout=>
    {

     this.tempo_ee=JSON.parse(JSON.stringify(gridout))
     const tempobj=this.tempo_ee;
     console.log(this.tempo_ee,'tempo');
     let componentweight;
     let componentweightshdbeupdated=false;

     var compulsoryCount=0
     var onlyCompulsoryComponentPresent=false;
     //console.log("tempo len"+tempobj.length+this.tempo[0].comp_tare_wt);

     for(let ki=0;ki<tempobj.length;ki++)
     {
      if(tempobj[ki].is_compulsary.toLowerCase() == "yes")
      {
        compulsoryCount+=1;
      }

        if(!(this.componentdetailsgrid_ee.some(ele=>ele.id_comp==tempobj[ki].id_comp)))
        {

          // rowadded and rowedited condition is checked to know whether the user have added components or edited components,accordingly shippingunit gross weight will be updated
          if((this.selectedpart_submitter_tab3_ee.proposallastsaved_ee<=2 && this.deleteflagbeforesave_ee==false )|| (this.selectedpart_submitter_tab3_ee.proposallastsaved_ee>=3 && this.deleteflag_ee==false && this.editcancel_click_ee==false) ||this.rowedited_ee ||this.rowadded_ee)
          {

            componentweight=tempobj[ki].qt_per_min_su*tempobj[ki].comp_tare_wt;
            console.log('component weight fetched',componentweight)
            if(tempobj[ki].compselctnfor=='Shipping Unit')
            {
              this.shippingunitgrossweight_ee+=componentweight;
              this.shippingunittareweight_ee+=componentweight

              if(tempobj[ki].typ_no == 2 || tempobj[ki].typ_no == 3 || tempobj[ki].typ_no == 4)
              {
                this.shippingUnitExteriorHeightCalculated_ee+=tempobj[ki].comp_h*tempobj[ki].qt_per_min_su;
              }
            }
            else
            {
              this.containergrossweight_ee+=componentweight;
              this.shippingunitgrossweight_ee+=(this.no_of_container_ee*componentweight);
              this.shippingunittareweight_ee+=(this.no_of_container_ee*componentweight);
              /*if(tempobj[ki].typ_no == 2 || tempobj[ki].typ_no == 3 || tempobj[ki].typ_no == 4)
              {
                this.shippingUnitExteriorHeightCalculated_ee+=tempobj[ki].comp_h*tempobj[ki].qt_per_min_su*this.noLayersPerShippingUnit_ee;
              }*/
            }
            console.log('came into 1436'+this.shippingunitgrossweight_ee);


            // when they have saved that tab already and now if they have added/Edited new componnet,
            // shipping unit gross weight shd be updated in DB too
            if(this.selectedpart_submitter_tab3_ee.proposallastsaved_ee>=3 && (this.rowadded_ee || this.rowedited_ee ))
            {
              this.comp_material_forpost.cntr_gross_wt=this.containergrossweight_ee;
              this.comp_material_forpost.shp_unt_gross_wt=this.shippingunitgrossweight_ee;
              this.comp_material_forpost.shp_unt_tar_wt=this.shippingunittareweight_ee;
              //alert(JSON.stringify(this.comp_material_forpost));
              console.log(this.comp_material_forpost);
              componentweightshdbeupdated=true;
            }
          }


          this.componentdetailsgrid_ee.push(
            {
            id_proposal:this.selectedpart_submitter_tab3_ee.proposalid,
            id_comp:tempobj[ki].id_comp,
            comp_base:tempobj[ki].comp_base,
            comp_suffix:tempobj[ki].comp_suffix,
            comp_desc:tempobj[ki].comp_desc,
            comp_l:tempobj[ki].comp_l,
            comp_w:tempobj[ki].comp_w,
            comp_h:tempobj[ki].comp_h,
            comp_ret_type:tempobj[ki].comp_ret_type,
            qt_per_min_su:tempobj[ki].qt_per_min_su,
            updated_by:tempobj[ki].upated_by,
            updated_dt:tempobj[ki].updated_dt,
            comp_tar_wt:tempobj[ki].comp_tare_wt,
            nestedfolderHt:tempobj[ki].nestedfolderht,
            comp_code:tempobj[ki].comp_code,
            comp_ownershp:tempobj[ki].comp_ownershp,
            comp_material:tempobj[ki].comp_material,
            tooledcomp:tempobj[ki].tooledcomp,
            is_compulsary:tempobj[ki].is_compulsary,
            comp_lwh:tempobj[ki].comp_l+'x'+tempobj[ki].comp_w+'x'+tempobj[ki].comp_h,
            comp_bsd:tempobj[ki].comp_base+'-'+tempobj[ki].comp_suffix+'-'+tempobj[ki].comp_desc,
            compselctnfor:tempobj[ki].compselctnfor,
            typ_no:tempobj[ki].typ_no
            });
            this.componentdetailsgrid_ee.sort((a, b)=>(a.comp_bsd<b.comp_bsd)?-1:1)
            console.log('componentdetailsgrid',this.componentdetailsgrid_ee);

            this.checkcomponentdetailsalreadypresentingrid_ee();
        }


      }

    //below not used
      if(compulsoryCount == tempobj.length && this.selectedpart_submitter_tab3_ee.proposallastsaved_ee<=2)
      {
        onlyCompulsoryComponentPresent=true;
      }
            if(this.compAddedChangeHeight_ee || this.compEdidtedChangeHeight_ee || this.compCompulsuryAdded_ee || this.selectedpart_submitter_tab3_ee.proposallastsaved_ee<=2)
            {
              this.shippingUnitExteriorHeightDisplay_ee=this.transactionService.roundValueToNDecimal(this.shippingUnitExteriorHeightCalculated_ee,3);;
            }
      if(this.unitom_ee == "E")
      {
        this.edit_measure_comp_ee = 'lbs';
      }
      else
      {
        this.edit_measure_comp_ee = 'kg';
      }

      // if(this.edit_measure_comp_ee=='kg')
      // {

      //   console.log("shipping unit tare weight",this.shippingunittareweight_ee);
      //   console.log("shippingunit gross weight",this.shippingunitgrossweight_ee);
      //   console.log("container gross wt",this.containergrossweight_ee)
      //   //alert(this.shippingunittareweight+" "+this.shippingunitgrossweight+" "+this.containergrossweight)
      //   if(this.shippingunittareweight_ee!=null)
      //   {
      //     this.shippingunittareweight_ee=Math.round((0.453592*this.shippingunittareweight_ee)*1000)/1000
      //   }
      //   if(this.shippingunitgrossweight_ee!=null)
      //   {
      //     this.shippingunitgrossweight_ee=Math.round((0.453592*this.shippingunitgrossweight_ee)*1000)/1000;
      //   }
      //   if(this.containergrossweight_ee!=null)
      //   {
      //     this.containergrossweight_ee=Math.round((0.453592*this.containergrossweight_ee)*1000)/1000;
      //   }
      // }
      
      
      //trunc grossweight
            console.log("container grossweight before Round Off",this.containergrossweight_ee)
            this.containergrossweight_ee_display=this.transactionService.roundValueToNDecimal(this.containergrossweight_ee,3)
             console.log("container grossweight after Round Off",this.containergrossweight_ee)

  	         console.log("shippingunitgrossweight grossweight before Round Off",this.shippingunitgrossweight_ee)
             this.shippingunitgrossweight_ee_display=this.transactionService.roundValueToNDecimal(this.shippingunitgrossweight_ee,3)
             console.log("shippingunitgrossweight grossweight after Round Off",this.shippingunitgrossweight_ee) 

             console.log("shipping unit tare weight before Round Off",this.shippingunittareweight_ee)
             this.shippingunittareweight_ee_display=this.transactionService.roundValueToNDecimal(this.shippingunittareweight_ee,3)
             console.log("shipping unit tare weight after Round Off",this.shippingunittareweight_ee)

      
      if(componentweightshdbeupdated)
      {
              this.comp_material_forpost.cntr_gross_wt=this.containergrossweight_ee_display;
              this.comp_material_forpost.shp_unt_gross_wt=this.shippingunitgrossweight_ee_display;
              this.comp_material_forpost.shp_unt_tar_wt=this.shippingunittareweight_ee_display;
        this.transactionService.savetab3details_ee(this.comp_material_forpost).subscribe(weightupd=>
          {
            const weightupdatestat=weightupd;
              let msgs:any[];
              if(weightupdatestat=='Success')
              {
                componentweightshdbeupdated=false;
                this.packagingproposal.addtoast({severity:'success', summary: 'success', detail: 'Shipping Unit Gross Weight Updated'});
              }
              else{
                this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Shipping Unit Gross weight not Updated'});
              }
          });
      }

      if(this.edit_measure_comp_ee=='kg')
      {

        console.log('shipping unit tare weight',this.shippingunittareweight_ee);
        console.log('shippingunit gross weight',this.shippingunitgrossweight_ee);
        console.log('container gross wt',this.containergrossweight_ee)
        // alert(this.shippingunittareweight+" "+this.shippingunitgrossweight+" "+this.containergrossweight)
        if(this.shippingunittareweight_ee!=null)
        {
          this.shippingunittareweight_ee=Math.round((0.453592*this.shippingunittareweight_ee)*1000)/1000
        }
        if(this.shippingunitgrossweight_ee!=null)
        {
          this.shippingunitgrossweight_ee=Math.round((0.453592*this.shippingunitgrossweight_ee)*1000)/1000;
        }
        if(this.containergrossweight_ee!=null)
        {
          this.containergrossweight_ee=Math.round((0.453592*this.containergrossweight_ee)*1000)/1000;
        }
      }


      this.rowedited_ee=false;
      this.rowadded_ee=false;
      this.deleteflag_ee=false;
      this.deleteflagbeforesave_ee=false;

      
      this.compAddedChangeHeight_ee=false;
      this.compEdidtedChangeHeight_ee=false;
      this.compCompulsuryAdded_ee=false;
    }


    );




    this.cols_ee=[
      { field: 'comp_bsd', header:'Base-Suffix-Description'},
      { field: 'compselctnfor', header:'Component Selection For'},
      // { field: 'comp_suffix', header:'Component Suffix'},
      // { field: 'comp_desc', header:'Component Description'},
      { field: 'comp_lwh', header:'LxWxH'},
     // { field: 'comp_w', header:'Compoenent Width'}  ,
     // { field: 'comp_h', header:'Compoenent Height'},
      { field: 'qt_per_min_su', header:'Quantity Per Shipping Unit'},
      { field: 'comp_tar_wt', header:'Component Tare Weight'},
      { field: 'nestedfolderHt', header:'Nested Folded Height'},
      { field: 'comp_ret_type', header:'E/R'}    ,
      { field: 'comp_code', header:'Component Code'},
      { field: 'comp_ownershp', header:'Component Ownership'},
      { field: 'comp_material', header:'Component Material'},
      { field: 'tooledcomp', header:'Tooled Component'} ,
      // { field: 'is_compulsary', header:'Is Compulsory'},
      // {field:'',header:'Edit/Delete Component'}
    ];

    this.frozenCols_ee=[
      {field:'',header:'Edit/Delete Component'},
      {field:'is_compulsary', header:'Is Compulsory'}
  ]
  }

  store_primary_part_exist_ee()
  {

    this.current_region_code_prt_ee=this.transactionService.getregioncode(this.region_ee);
    if(this.current_region_code_prt_ee=='NA')
    {
      sessionStorage.setItem('napackpropart', JSON.stringify(this.selectedpart_submitter_tab3_ee));
    }

    if(this.current_region_code_prt_ee=='EU')
    {
      sessionStorage.setItem('eupackpropart', JSON.stringify(this.selectedpart_submitter_tab3_ee));
    }

    if(this.current_region_code_prt_ee=='AP')
    {
      sessionStorage.setItem('appackpropart', JSON.stringify(this.selectedpart_submitter_tab3_ee));
    }

    if(this.current_region_code_prt_ee=='SA')
    {
       sessionStorage.setItem('sapackpropart', JSON.stringify(this.selectedpart_submitter_tab3_ee));
    }
  }


  on_change_measure_comp_ee()
  {


    console.log(this.edit_measure_comp_ee);
    console.log(this.shippingunittareweight_ee)
    console.log(this.shippingunitgrossweight_ee);
    console.log(this.containergrossweight_ee);

    if(this.unitom_ee == 'E')
    {
      this.edit_measure_comp_ee = 'lbs';
    }
    else
    {
      this.edit_measure_comp_ee = 'kg';
    }

    // if(this.edit_measure_comp_ee=="lbs")
    // {


    //   if(this.shippingunittareweight_ee!=null)
    //   {

    //     this.shippingunittareweight_ee=Math.round((2.20462*this.shippingunittareweight_ee)*1000)/1000;

    //   }
    //   if(this.shippingunitgrossweight_ee!=null)
    //   {

    //     this.shippingunitgrossweight_ee=Math.round((2.20462*this.shippingunitgrossweight_ee)*1000)/1000;


    //   }
    //   if(this.containergrossweight_ee!=null)
    //   {



    //     this.containergrossweight_ee=Math.round((2.20462*this.containergrossweight_ee)*1000)/1000;



    //   }

    // }
    // else if(this.edit_measure_comp_ee=="kg")
    // {



    //   if(this.shippingunittareweight_ee!=null)
    //   {

    //     this.shippingunittareweight_ee=Math.round((0.453592*this.shippingunittareweight_ee)*1000)/1000;

    //   }
    //   if(this.shippingunitgrossweight_ee!=null)
    //   {

    //     this.shippingunitgrossweight_ee=Math.round((0.453592*this.shippingunitgrossweight_ee)*1000)/1000;


    //   }
    //   if(this.containergrossweight_ee!=null)
    //   {



    //     this.containergrossweight_ee=Math.round((0.453592*this.containergrossweight_ee)*1000)/1000;



    //   }


    // }

  }

  calculateShippingUnitExtHeight_ee()
  {
     var shpExthgt=0;
     shpExthgt=this.Tab2DataForPost_ee.cntr_height*(this.Tab2DataForPost_ee.max_layrs_shp_unt-this.Tab2DataForPost_ee.empty_trays_cntr_layrs);

     if(this.componentdetailsgrid_ee != null && this.componentdetailsgrid_ee.length != 0)
     {
       for(var i of this.componentdetailsgrid_ee)
       {
         if(i.typ_no == 2 || i.typ_no == 3 || i.typ_no == 4)
         {
           if(i.compselctnfor.toLowerCase() == "shipping unit")
           {
             shpExthgt+=i.qt_per_min_su*i.comp_h;
           }
           /*else
           {
             shpExthgt+=(this.Tab2DataForPost_ee.max_layrs_shp_unt-this.Tab2DataForPost_ee.empty_trays_cntr_layrs)*i.qt_per_min_su*i.comp_h;
           }*/
         }
       }
     }

     this.shippingUnitExteriorHeightDisplay_ee=this.transactionService.roundValueToNDecimal(shpExthgt,3);
     this.suExtHgtValidation_ee();
  }

  suGrossWtInValidation_ee()
  {
    if(this.shippingunitgrossweight_ee_display<=0 || this.shippingunitgrossweight_ee_display==null || this.shippingunitgrossweight_ee_display>999999.999)
    {
      this.sugrosswtInvalid_ee=true;
      this.sugrossewtInvalidReason_ee="Should be of between 0 to 999999.999";
      console.log("SUGROSSWGT");
    }
    else
    {
      this.sugrosswtInvalid_ee=false;
      this.sugrossewtInvalidReason_ee=null;
      console.log('SUGROSSWGTNOT');
    }
  }
  suTareWtInValidation_ee()
  {
    if(this.shippingunittareweight_ee_display<=0 || this.shippingunittareweight_ee_display==null ||this.shippingunittareweight_ee_display>999999.999)
    {
      this.sucontarewtInvalid_ee=true;
      this.sucontarewtInvalidReason_ee="Should be of between 0 to 999999.999";
    }
    else
    {
      this.sucontarewtInvalid_ee=false;
      this.sucontarewtInvalidReason_ee=null;
    }
  }
  contGrossWtInValidation_ee()
  {
    if(this.containergrossweight_ee_display<=0 || this.containergrossweight_ee_display==null || this.containergrossweight_ee_display>999999.999)
    {
      this.cntrgrosswtInvalid_ee=true;
      this.cntrgrosswtInvalidReason_ee="Should be of between 0 to 999999.999";
    }
    else
    {
      this.cntrgrosswtInvalid_ee=false;
      this.cntrgrosswtInvalidReason_ee=null;
    }
  }

  suExtHgtValidation_ee()
  {
    let shp_ext_h = String(this.shippingUnitExteriorHeightDisplay_ee).split(".");
    // if(this.cntrdetforpost_ee.shp_unt_ext_height>this.cntrdetforpost_ee.shp_unt_ext_height)
    //   {
    //   this.suexthgtInvalid_ee=true;
    //   this.suexthgtInValidReason_ee="Container Exterior Length cannot be greater than Shipping Unit Exterior Length";
    // }
    if(this.shippingUnitExteriorHeightDisplay_ee==null || this.shippingUnitExteriorHeightDisplay_ee==0)
    {
      this.suexthgtInvalid_ee=true;
      this.suexthgtInValidReason_ee="Required";
    }
    else
    {
      if(!String(this.shippingUnitExteriorHeightDisplay_ee).includes("."))
      {
      
           if(this.shippingUnitExteriorHeightDisplay_ee<1 || this.shippingUnitExteriorHeightDisplay_ee>9999.999 )
          {
            this.suexthgtInvalid_ee=true;
            this.suexthgtInValidReason_ee="Should be of range 1 to 9999.999";
          }
          else if( String(this.shippingUnitExteriorHeightDisplay_ee).length>8)
          {
            this.suexthgtInvalid_ee=true;
            this.suexthgtInValidReason_ee="Max Length Of Characters can be 8";
          }
          else if(this.shippingUnitExteriorHeightDisplay_ee>0 && this.Tab2DataForPost_ee.cntr_height>this.shippingUnitExteriorHeightDisplay_ee)
          {
          this.suexthgtInvalid_ee=true;
          this.suexthgtInValidReason_ee="Shipping Unit Exterior Height cannot be lesser than Container Exterior Height";
          }
    
          else
          {
            this.suexthgtInvalid_ee=false;
            this.suexthgtInValidReason_ee=null;
          }
      }
      else if(String(this.shippingUnitExteriorHeightDisplay_ee).includes("."))
      {
          if(String(shp_ext_h[0]).length<0 || String(shp_ext_h[0]).length>4)
          {
            this.suexthgtInvalid_ee=true;
            this.suexthgtInValidReason_ee="Should be of range 1 to 9999.999";
          }
          else if(this.shippingUnitExteriorHeightDisplay_ee<1 || this.shippingUnitExteriorHeightDisplay_ee>9999.999)
          {
            this.suexthgtInvalid_ee=true;
            this.suexthgtInValidReason_ee="Should be of range 1 to 9999.999";
          }
          else if(String(shp_ext_h[1]).length<0 || String(shp_ext_h[1]).length>3)
          {
            this.suexthgtInvalid_ee=true;
            this.suexthgtInValidReason_ee="Max Length Of decimal values can be 3";
          }
          else if(this.shippingUnitExteriorHeightDisplay_ee>0 && this.Tab2DataForPost_ee.cntr_height>this.shippingUnitExteriorHeightDisplay_ee)
          {
            this.suexthgtInvalid_ee=true;
            this.suexthgtInValidReason_ee="Shipping Unit Exterior Height cannot be lesser than Container Exterior Height";
          }
          else
          {
            this.suexthgtInvalid_ee=false;
            this.suexthgtInValidReason_ee=null;
          }
      }   
    }
     
      
  }
  suNestedFldedHtValidation_ee()
  {
    if(this.shippinUnitNestedFoldedHeight_ee==null || this.shippinUnitNestedFoldedHeight_ee==0)
    {
      this.suNestedHtInValid_ee=true;
      this.suNestedHtInValidReason_ee="Required";
    }
    else if(this.shippinUnitNestedFoldedHeight_ee<1 || this.shippinUnitNestedFoldedHeight_ee>99999)
    {
      this.suNestedHtInValid_ee=true;
      this.suNestedHtInValidReason_ee="Should be of range 1 to 99999";
    }
    else if(this.shippinUnitNestedFoldedHeight_ee>0 && this.shippingUnitExteriorHeightDisplay_ee>0 && this.shippinUnitNestedFoldedHeight_ee>this.shippingUnitExteriorHeightDisplay_ee)
    {
      this.suNestedHtInValid_ee=true;
      this.suNestedHtInValidReason_ee="Nested Folded Height cannot be Greater than Exterior height";
    }
    else
    {
      this.suNestedHtInValid_ee=false;
      this.suNestedHtInValidReason_ee=null;
    }
  }
  expAidsWgtValidation_ee()
  {

    if(this.wgt_flag)
    {
    this.shp_comp_wt = 0;
    // this.temp_shp_compwt = 0;
    }
      // if(this.component_type_ee.cd_cntr_typ > "05" && this.component_type_ee.cd_cntr_typ <"15")
      // {
        for( const i of this.componentdetailsgrid_ee)
          {
        if(i.compselctnfor == 'Container' && (i.comp_code > '05' && i.comp_code < '16'))
        {

          // this.shp_comp_wt = 0;
          this.cntr_comp_wt = 0;
          this.cntr_comp_wt+=(i.qt_per_min_su *i.comp_tar_wt);
          this.shp_comp_wt +=( this.no_of_container_ee*(this.cntr_comp_wt));

          if(this.shp_comp_wt > 999.9999)
          {
                this.totl_wt_InValid_ee = true;
                // this.totl_wt_InValidReason_ee = "The weights are exceeding 999.9999"
                this.enable_savecomps = false;
          }
          else
          {
            this.totl_wt_InValid_ee = false;
            this.enable_savecomps = true;
            this.totl_wt_InValidReason_ee = null;
          }
        }
        else
        {
          if(i.compselctnfor== 'Shipping Unit' && (i.comp_code > '05' && i.comp_code < '16'))
          {
            // this.temp_shp_compwt = 0;
          this.shp_comp_wt+=(i.qt_per_min_su *i.comp_tar_wt);
          // this.temp_shp_compwt+= this.shp_comp_wt;
          if(this.shp_comp_wt > 999.9999)
          {
                this.totl_wt_InValid_ee = true;
                this.enable_savecomps = false;
                // this.totl_wt_InValidReason_ee = "The weights are exceeding 999.9999"
          }
          else
          {
            this.totl_wt_InValid_ee = false;
            this.totl_wt_InValidReason_ee = null;
            this.enable_savecomps = true;
          }

        }
        }
        }



      // }

  }


  saveThisAndEnableFourthForm_ee()
  {

    if(this.componentdetailsgrid_ee.some(element=>element.is_compulsary=='Yes' && (element.qt_per_min_su==0 || element.comp_tar_wt==0)))
    {
      this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Enter Compulsory Component Details and then Save !!'});

    }
    else
    {
      console.log(this.comp_material_forpost);
      // this.comp_material_forpost.mot=this.selected_mode_transport_ee.transport_desc;
      this.comp_material_forpost.mot="NA";
      this.comp_material_forpost.rel_level=this.selected_release_level_ee.releaselevel;
      this.comp_material_forpost.id_proposal=this.id_proposal_compmat_ee
      console.log(this.id_proposal_compmat_ee)
      console.log(this.comp_material_forpost.id_proposal);

      // for now manually intitialize and assign calculated values
      // this.comp_material_forpost.cntr_shp_unit="Container";

      if(this.unitom_ee == 'E')
      {
        this.edit_measure_comp_ee = 'lbs';
      }
      else
      {
        this.edit_measure_comp_ee = 'kg';
      }

      // if(this.edit_measure_comp_ee=="kg")
      // {


      //   if(this.shippingunittareweight_ee!=null)
      //   {

      //     this.shippingunittareweight_ee=Math.round((2.20462*this.shippingunittareweight_ee)*1000)/1000;

      //   }
      //   if(this.shippingunitgrossweight_ee!=null)
      //   {

      //     this.shippingunitgrossweight_ee=Math.round((2.20462*this.shippingunitgrossweight_ee)*1000)/1000;


      //   }
      //   if(this.containergrossweight_ee!=null)
      //   {



      //     this.containergrossweight_ee=Math.round((2.20462*this.containergrossweight_ee)*1000)/1000;



      //   }

      // }

      this.comp_material_forpost.cntr_shp_unit=this.cntrorshpunitradio_ee;
      this.comp_material_forpost.cntr_gross_wt=this.containergrossweight_ee_display;
      this.comp_material_forpost.shp_unt_gross_wt=this.shippingunitgrossweight_ee_display;
      this.comp_material_forpost.shp_unt_tar_wt=this.shippingunittareweight_ee_display;

      this.suGrossWtInValidation_ee();
      this.suTareWtInValidation_ee();
      this.contGrossWtInValidation_ee();

      this.expAidsWgtValidation_ee();
       this.suExtHgtValidation_ee();
      this.suNestedFldedHtValidation_ee();

      // alert(JSON.stringify(this.comp_material_forpost));

      console.log('Inside Save_next last console',this.shp_comp_wt,this.temp_shp_compwt)

      if(!this.sugrosswtInvalid_ee  && !this.sucontarewtInvalid_ee && !this.cntrgrosswtInvalid_ee && !this.totl_wt_InValid_ee && !this.suexthgtInvalid_ee && !this.suNestedHtInValid_ee)
      {
        console.log('Save and Next tab test weights Nott');
        this.transactionService.savetab3details_ee(this.comp_material_forpost).subscribe(response=>
      {
          let msgs:any[];
          if(response=='Success')
          {
            this.packagingproposal.addtoast({severity:'success', summary: 'success', detail: 'EE-Component and Material details saved successfully'});
            var check=false;
            this.postTab2EEAndMovetONextTab();
          }
          else
          {
            this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'EE-Component and Material details is not saved! try again'});
          }
          
         

      })
       }
    /*else
    {
      console.log("Save and Next tab test weights");
      this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Check the Container Tare weight, Shipping unit weights and Try again'});
    }*/
    }
  }

  postTab2EEAndMovetONextTab()
  {
    this.Tab2DataForPost_ee.shp_unt_ext_height=this.shippingUnitExteriorHeightDisplay_ee;
    this.Tab2DataForPost_ee.shp_unt_nestd_fld_height=this.shippinUnitNestedFoldedHeight_ee;
    this.transactionService.savetab2details_ee(this.Tab2DataForPost_ee).subscribe(tab2_output=>{
      if(tab2_output.toLowerCase() == "success")
      {
        this.packagingproposal.addtoast({severity:'success', summary: 'success', detail: 'Height Updated Successfully'});
        var check = false;
      }
        if(check==false)

          {
            this.name_ee=this.transactionService.getregionNameInSmallLetters();
            this.tab4_ee='/'+this.name_ee+'/packagingprocess/proposalcreation/eepackproposaltabfour';
            this.tab4menuitem_ee={label: 'EE-Images/Photos & Comments', routerLink: this.tab4_ee, icon: 'pi pi-images'};
            const currentab=3;
            console.log('in save of tab3 component',this.selectedpart_submitter_tab3_ee.proposallastsaved_ee);

            if(currentab>this.selectedpart_submitter_tab3_ee.proposallastsaved_ee)
            {
              this.selectedpart_submitter_tab3_ee.proposallastsaved_ee=3;
              this.store_primary_part_exist_ee();
              console.log('After saving the tab','')
            }

            console.log('savedtab',this.packagingproposal.savedtab);
            console.log(this.partsandattri.savedtabfortesting_ee);

            for(const i of this.packagingproposal.tabpackagingproposalmenuitems.values())
            {
                console.log(i);
                if(i.label!='EE-Images/Photos & Comments')
                {
                    this.flag_ee=true;
                }
              else{
                  this.flag_ee=false;
                  break;
              }
            }

            if(this.flag_ee)
            {
                this.packagingproposal.tabpackagingproposalmenuitems.push(this.tab4menuitem_ee);
            }

            this.packagingproposal.setActiveItemDynamically(this.tab4_ee,this.tab4menuitem_ee);

          }
          else
          {
           // this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Component and Material details is not saved! try again'});
        this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Height not saved! try again'});
            if(this.unitom_ee == "E")
            {
              this.edit_measure_comp_ee = 'lbs';
            }
            else
            {
              this.edit_measure_comp_ee = 'kg';
            }

            // if(this.edit_measure_comp_ee=="kg")
            // {



            //   if(this.shippingunittareweight_ee!=null)
            //   {

            //     this.shippingunittareweight_ee=Math.round((0.453592*this.shippingunittareweight_ee)*1000)/1000;

            //   }
            //   if(this.shippingunitgrossweight_ee!=null)
            //   {

            //     this.shippingunitgrossweight_ee=Math.round((0.453592*this.shippingunitgrossweight_ee)*1000)/1000;


            //   }
            //   if(this.containergrossweight_ee!=null)
            //   {



            //     this.containergrossweight_ee=Math.round((0.453592*this.containergrossweight_ee)*1000)/1000;



            //   }
            // }
          }

    
    // else
    // {
    //   console.log("Save and Next tab test weights");
    //   this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Check the Container Tare weight, Shipping unit weights and Try again'});
    // }
  })
}


  savetab3_ee()
  {
    if(this.componentdetailsgrid_ee.some(element=>element.is_compulsary=='Yes' && (element.qt_per_min_su==0 || element.comp_tar_wt==0)))
    {
      this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Enter Compulsory Component Details and then Save !!'});

    }
    else
    {
      console.log(this.comp_material_forpost);
      // this.comp_material_forpost.mot=this.selected_mode_transport_ee.transport_desc;
      this.comp_material_forpost.mot="NA";
      this.comp_material_forpost.rel_level=this.selected_release_level_ee.releaselevel;
      this.comp_material_forpost.id_proposal=this.id_proposal_compmat_ee
      console.log(this.id_proposal_compmat_ee)
      console.log(this.comp_material_forpost.id_proposal);

      // for now manually intitialize and assign calculated values
      // this.comp_material_forpost.cntr_shp_unit="Container";

      if(this.unitom_ee == 'E')
      {
        this.edit_measure_comp_ee = 'lbs';
      }
      else
      {
        this.edit_measure_comp_ee = 'kg';
      }

      // if(this.edit_measure_comp_ee=="kg")
      // {


      //   if(this.shippingunittareweight_ee!=null)
      //   {

      //     this.shippingunittareweight_ee=Math.round((2.20462*this.shippingunittareweight_ee)*1000)/1000;

      //   }
      //   if(this.shippingunitgrossweight_ee!=null)
      //   {

      //     this.shippingunitgrossweight_ee=Math.round((2.20462*this.shippingunitgrossweight_ee)*1000)/1000;


      //   }
      //   if(this.containergrossweight_ee!=null)
      //   {



      //     this.containergrossweight_ee=Math.round((2.20462*this.containergrossweight_ee)*1000)/1000;



      //   }

      // }

      this.comp_material_forpost.cntr_shp_unit=this.cntrorshpunitradio_ee;
      this.comp_material_forpost.cntr_gross_wt=this.containergrossweight_ee_display;
      this.comp_material_forpost.shp_unt_gross_wt=this.shippingunitgrossweight_ee_display;
      this.comp_material_forpost.shp_unt_tar_wt=this.shippingunittareweight_ee_display;

      this.suGrossWtInValidation_ee();
      this.suTareWtInValidation_ee();
      this.contGrossWtInValidation_ee();

      this.expAidsWgtValidation_ee();
      this.suExtHgtValidation_ee();
      this.suNestedFldedHtValidation_ee();

      
      if(!this.sugrosswtInvalid_ee  && !this.sucontarewtInvalid_ee && !this.cntrgrosswtInvalid_ee && !this.totl_wt_InValid_ee && !this.suexthgtInvalid_ee  && !this.suNestedHtInValid_ee)
      {
     this.transactionService.savetab3details_ee(this.comp_material_forpost).subscribe(response=>
      {
          let msgs:any[];
          if(response=='Success')
          {
            this.packagingprocess.addtoastinpackagingprocess({severity:'success', summary: 'success', detail: 'EE-Component and Material details saved successfully'});
            var check=false;
            this.postTab2EESaveAndClose();
          }
          
          else
          {
            this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'EE-Component and Material details is not saved! try again'});
          }

      })
       }
      /*else
      {
        console.log("Save and close tab test for weights");
        this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Check the Container Tare weight, Shipping unit weights and Try again'});
      }*/
    }
  }

  postTab2EESaveAndClose()
  {
    this.Tab2DataForPost_ee.shp_unt_ext_height=this.shippingUnitExteriorHeightDisplay_ee;
    this.Tab2DataForPost_ee.shp_unt_nestd_fld_height=this.shippinUnitNestedFoldedHeight_ee;
    this.transactionService.savetab2details_ee(this.Tab2DataForPost_ee).subscribe(tab2_output=>{
      if(tab2_output.toLowerCase() == "success")
      {
        this.packagingproposal.addtoast({severity:'success', summary: 'success', detail: 'Height Updated Successfully'});
        var check = false;
      }
      if(check==false)
          {
            this.name_ee=this.transactionService.getregionNameInSmallLetters();
            const currentab=3;
            console.log('in save of tab3 component',this.selectedpart_submitter_tab3_ee.proposallastsaved_ee);

            if(currentab>this.selectedpart_submitter_tab3_ee.proposallastsaved_ee)
            {
              this.selectedpart_submitter_tab3_ee.proposallastsaved_ee=3;
              this.store_primary_part_exist_ee();
              console.log('After saving the tab','')
            }

            console.log('savedtab',this.packagingproposal.savedtab);
            console.log(this.partsandattri.savedtabfortesting_ee);
            if(this.selectedpart_submitter_tab3_ee.propsl_type!=null && this.selectedpart_submitter_tab3_ee.propsl_type.toLowerCase()=='migration')
            {
              if(this.roleid_compmat_ee==1)
              {
                this.router.navigate(['/'+this.name_ee+'/packagingprocess/packsupplieradmin/migrationsubmitter'])
              }
              else if(this.roleid_compmat_ee==2)
              {
                this.router.navigate(['/'+this.name_ee+'/packagingprocess/packdesignsourceadmin/migrationsubmitter'])
              }
            }
            else // if(this.selectedpart_submitter.propsl_type!=null && this.selectedpart_submitter.propsl_type.toLowerCase()=="normal")
            {


            if(this.roleid_compmat_ee==1)
            {
              this.router.navigate(['/'+this.name_ee+'/packagingprocess/packsupplieradmin/submiterdashboard'])
            }
            else if(this.roleid_compmat_ee==2)
            {
              this.router.navigate(['/'+this.name_ee+'/packagingprocess/packdesignsourceadmin/submiterdashboard'])
            }
            }

          }
          else
          {
            this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Height not saved! try again'});

            if(this.unitom_ee == 'E')
            {
              this.edit_measure_comp_ee = 'lbs';
            }
            else
            {
              this.edit_measure_comp_ee = 'kg';
            }

            // if(this.edit_measure_comp_ee=="kg")
            // {



            //   if(this.shippingunittareweight_ee!=null)
            //   {

            //     this.shippingunittareweight_ee=Math.round((0.453592*this.shippingunittareweight_ee)*1000)/1000;

            //   }
            //   if(this.shippingunitgrossweight_ee!=null)
            //   {

            //     this.shippingunitgrossweight_ee=Math.round((0.453592*this.shippingunitgrossweight_ee)*1000)/1000;


            //   }
            //   if(this.containergrossweight_ee!=null)
            //   {



            //     this.containergrossweight_ee=Math.round((0.453592*this.containergrossweight_ee)*1000)/1000;



            //   }
            // }
          }

     
      // else
      // {
      //   console.log("Save and close tab test for weights");
      //   this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Check the Container Tare weight, Shipping unit weights and Try again'});
      // }
    })
  }




 /* if(this.packagingproposal.savedtab==1)
        {
          for(let i of this.packagingproposal.tabpackagingproposalmenuitems.values())
          {
                console.log(i);
                if(i.label!='COS, Container & Shipping Unit')
                {
                    this.packagingproposal.flag1=true;
                }
                else{
                    this.packagingproposal.flag1=false;
                    break;
                }
          }

          if(this.packagingproposal.flag1)
          {
              this.packagingproposal.tabpackagingproposalmenuitems.push({label: 'COS, Container & Shipping Unit', routerLink: this.router.url, icon: 'fas fa-person-booth'});
          }
        }

        else if(this.packagingproposal.savedtab==2 )
        {
          for(let i of this.packagingproposal.tabpackagingproposalmenuitems.values())
          {
                console.log(i);
                if(i.label!='Component & Material')
                {
                    this.packagingproposal.flag2=true;
                }
                else{
                    this.packagingproposal.flag2=false;
                    break;
                }
          }

          if(this.packagingproposal.flag2)
          {
              this.packagingproposal.tabpackagingproposalmenuitems.push({label: 'COS, Container & Shipping Unit', routerLink:'/northamerica/proposalcreation/packproposaltabtwo', icon: 'fas fa-person-booth'});
              this.packagingproposal.tabpackagingproposalmenuitems.push({label:'Component & Material',routerLink:this.router.url, icon: 'fas fa-person-booth'});
          }
        }*/
      }

