/* tslint:disable */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng';
import {ActivePlants,plantlist,GridPlantCode,programsbasedonplants,programsbasedonplantsforsearchprop,containerOwnershipList,supp_country,listOfSupForBulkEmail,proglevelgrpemaildetailspost,supemaildetails,COSCodeDesc,subpltlist,concatSupDetails} from '../loginpage/transactions';
import {TransactionService} from '../loginpage/Transaction.service';
import { DatePipe } from '@angular/common';
import {PickListModule} from 'primeng/picklist';


@Component({
  selector: 'app-programlevelemail',
  templateUrl: './programlevelemail.component.html',
  styleUrls: ['./programlevelemail.component.css']
})

export class ProgramlevelemailComponent implements OnInit {

  userid:string;
  current_region_code_prg:string;

  // selectedPlantCode:GridPlantCode={'plantCode':'','plant_active_stat':''};



  selectedProgramName:programsbasedonplants={cd_region: '',
                                            programname:'',
	                                          plantgsdbcode:''};
  enableProgram=false;

  current_region_prg:string;

  // plants:plantlist[];
  // plantcodeList:GridPlantCode[]=[];

  subAllPlants:ActivePlants[]=[];
  plantCodeList:subpltlist[]=[];
  selectedPlantCode:subpltlist={plantcode:'',plant:''};

  // plts:any=[];
  programName:any[]=[];
  programList:programsbasedonplants[]=[];
  selectedProgram:any={cd_region: '', programname:'',plantgsdbcode:''}


  current_region:string;
  region_code:string;

  partsaddedwithindays:any=[];
  selectedDaysRange:any={days_range : ''};
  submitStat:any=[];
  selectedSubmitStat:any={status : '' };
  cosList:containerOwnershipList[]=[];
  selectedCOS:any={cd_ownrshp_strat:0,cd_region:'',ownrshp_strat_code:'',ownrshp_desc:''};
  cosCodeDesc:COSCodeDesc[]=[];

  // any[]=[{ownrshp_strat_code:'',ownrshpCodeAndDesc : ''}];

  supplierCountryList:supp_country[]=[];
  selected_suppliercountry:supp_country[]=[];
  suppliercountry='';
  all_selected_supp_cntry=false;



  pltcode:string;
  cos:string;
  dayrange:string;
  sup_cntry:string;
  submit_status:string;
  program_name:string;
  supBulkEmail:listOfSupForBulkEmail[]=[];
  enable_sup_pick_list=false;
  supSelectedList:listOfSupForBulkEmail[]=[];
  supgsdbcode:string;
  supname:string;
  supemail:string;

  supDetails:concatSupDetails[]=[];

  enableGoToEmailEditor=false;
  enableSendEmailPopUp=false;
  bulkEmailSubject='';
  bulkEmailBody=''
  copyMeOnBulkEmail=false;
  isloaderproglvlemail=false;
  enableSendEmailButton=false;

  search_result='';
  cos_selected:boolean;




selectedproglevelgrpemail:proglevelgrpemaildetailspost={
  supCntDetls:[{
    supcode:'',
	email:'',
  }],
	subject:'',
	emailbody:'',
	copyme:'',
	userid:''
}




  constructor(private date:DatePipe ,private transactionService: TransactionService,private messageService: MessageService ) {
   }

  ngOnInit(): void {

    this.userid=this.transactionService.getcdsid();
    this.current_region_prg=this.transactionService.getregion();
    this.current_region_code_prg=this.transactionService.getregioncode(this.current_region_prg);

   // this.loadPlant();
   this.loadPlantNew();
    this.loadSupplierCountry();
    this.partsaddedwithindays=[{days_range : 'ALL'},
    {days_range : '0-7 Days'},
    {days_range : '8-30 Days'},
    {days_range : '31-60 Days'},
    {days_range : '61-90 Days'},
    {days_range : 'More than 90 Days'}];
    this.loadCOS();
    this.submitStat=[{label : 'Not Started', value : 'Not Started'},
                    {label : 'In Process',   value :'In-Process'},
                    {label : 'Submitted',    value :'Submitted'}];


  }

  loadPlantNew()
  {

    const sub_allplants=[]
  this.transactionService.getactiveplantslist(this.current_region_code_prg).subscribe(sub_allplants=>{
      console.log('sub_useracces',sub_allplants);
     let position:number;
       this.subAllPlants=sub_allplants;
       this.plantCodeList=[];
      // this.progList=[];



       // this.plantCodeList.push({plantcode:"all",plant:"[ALL]"});


       if(this.subAllPlants.length!=0)
       {
       for(let i=0;i<this.subAllPlants.length;i++)
       {
         // console.log(this.subAllPlants[temp]);
         let plt_name:string;
         plt_name=this.subAllPlants[i].plantGSDBCode+'-'+this.subAllPlants[i].plant_name;

          this.plantCodeList.push({plantcode:this.subAllPlants[i].plantGSDBCode,plant:plt_name})

       }
      }


       console.log('plantCodeList ' + this.plantCodeList[1].plantcode);

    });
}

  /*loadPlant()
  {
  this.transactionService.getPlantListonRegion(this.current_region_code_prg).subscribe((Plt => {
  this.plants=Plt
  console.log("only plants list",this.plants);
  this.plantcodeList=[];
  console.log("getPlantListonRegion api o/p",this.plants);
  //console.log(this.plants)
    if(this.plants != null && this.plants.length != 0)
    {
          for(var i=0;i<this.plants.length;i++)
          {
          this.plantcodeList.push({plantCode:this.plants[i].plantGSDBCode,plant_active_stat:this.plants[i].plant_active_stat});
           this.plts=this.plants[i];
          }
          console.log("All plants list",this.plantcodeList);
          var activePlantcodeList=[];
          var allPlantcodeList=[];
          activePlantcodeList=this.plantcodeList.filter(ele=>(ele.plant_active_stat != null && ele.plant_active_stat.toLowerCase() == "yes"))
          allPlantcodeList=this.plantcodeList;
          this.plantcodeList=[];
          this.plantcodeList.push(...activePlantcodeList)
          console.log("Active Plant code list",this.plantcodeList);

          }
      }
  ));
  }
*/


  change_plant_codes(){

    console.log('selected plant',this.selectedPlantCode);
    this.enableProgram=true;
    this.programList=[];
    this.programName=[];
    this.selectedProgram='';
    this.program_name='';
    this.loadprogramcodes();

  }


 loadCOS(){
  this.transactionService.getOwnershipStrategyCode(this.current_region_code_prg).subscribe(access_coslist=>{
    console.log(access_coslist);
   // this.cosList=[];
    // this.cosList.push({cd_ownrshp_strat:0,cd_region:this.current_region_code_prg,ownrshp_strat_code:"ALL",ownrshp_desc:"[No filter]"})

    /*if(access_coslist.length!=0)
    {
      this.cosList.push(...access_coslist);
    }*/

    this.cosCodeDesc=[];
    this.cosCodeDesc.push({ownrshp_strat_code:'ALL',ownrshpCodeAndDesc:'ALL'})
    if(access_coslist.length!=0){
    for (let i=0;i < access_coslist.length;i++){

    this.cosCodeDesc.push({ownrshp_strat_code : access_coslist[i].ownrshp_strat_code, ownrshpCodeAndDesc : access_coslist[i].ownrshp_strat_code + ' - ' + access_coslist[i].ownrshp_desc});
    }
  }
    console.log('cosDesc ' + this.cosCodeDesc[1]);

  })

 }

  loadprogramcodes(){

    console.log('current_region_code_prg',this.current_region_code_prg);
    this.transactionService.getprogmlistbasedonplantgsdb(this.selectedPlantCode.plantcode,this.current_region_code_prg).subscribe(progs=>{
      this.programList=[];
      this.programList.push({cd_region: '', programname:'ALL',plantgsdbcode:''})

      if (progs.length > 0 ){

        this.programList.push(...progs);

      }
      console.log('programList',this.programList);
    })

  }

  submitStatChanged(){
    console.log('submitter status changed ',this.selectedSubmitStat);
    console.log('status',this.selectedSubmitStat.value);
  }



  loadSupplierCountry()
  {
    this.transactionService.getsupplier_country_list().subscribe(supp_country_list=>{

      const resultsuppcntry=supp_country_list;
      const suppcntrytemp:supp_country[]=[];

      if(resultsuppcntry!=null && resultsuppcntry.length!=0)
      {
        suppcntrytemp.push({cd_country:'all',cd_country_iso:'all',country_name:'all',code_name:'ALL'})

        for(let s=0;s<resultsuppcntry.length;s++)
        {
          suppcntrytemp[s+1]=resultsuppcntry[s];
          if(resultsuppcntry[s].cd_country_iso!=null)
          {
            suppcntrytemp[s+1].code_name=resultsuppcntry[s].cd_country_iso+'-'+resultsuppcntry[s].country_name
          }
          else
          {
            suppcntrytemp[s+1].code_name=resultsuppcntry[s].country_name;
          }
        }

        this.selected_suppliercountry=[];
        this.supplierCountryList=suppcntrytemp;
        console.log('countrylist',this.supplierCountryList);

      }
    });
  }

  change_supp_cntry()
  {
    let all_present_in_supp_cntry=false;
    let lenlessthan1_supp_cntry=false;
    this.suppliercountry='';

    if(this.selected_suppliercountry.length==this.supplierCountryList.length-1)
    {
      lenlessthan1_supp_cntry=true;
    }
    for(let j=0;j<this.selected_suppliercountry.length;j++)
    {
      if(this.selected_suppliercountry[j].code_name=='ALL')
      {
        all_present_in_supp_cntry=true;
      }
    }

    if(all_present_in_supp_cntry==false && lenlessthan1_supp_cntry==true)
    {
      all_present_in_supp_cntry=true;
    }

    if(all_present_in_supp_cntry==true)
    {
      this.suppliercountry='all';
      this.selected_suppliercountry=[];
      this.selected_suppliercountry.push({cd_country:'all',cd_country_iso:'all',country_name:'all',code_name:'ALL'});
    }
  }

  onhidesuppcountry()
  {
    this.all_selected_supp_cntry=false;
    this.suppliercountry='';

    /* checking whether length of selected list is less than one */
    let suppcntrylen=false;
    if(this.selected_suppliercountry.length==this.supplierCountryList.length-1)
    {
      suppcntrylen=true;
    }

    for(let spct=0;spct<this.selected_suppliercountry.length;spct++)
    {
      this.suppliercountry+=this.selected_suppliercountry[spct].cd_country;
      if(spct < this.selected_suppliercountry.length-1)
      {
        this.suppliercountry+=',';
      }

      if(this.selected_suppliercountry[spct].code_name=='ALL')
      {
        this.all_selected_supp_cntry=true;
        this.suppliercountry='all';
        break;
      }
    }

        /* If selectedvariables length is one less than total length but all is not selected
       which means except all remaining (both expendable and returnable are selected) so make
       all_selected_in_ownrshp_catg as true and make code  send all to backend */

       if(this.all_selected_supp_cntry==false && suppcntrylen==true)
       {
         this.suppliercountry='all';
         this.all_selected_supp_cntry=true;
       }
       console.log('Supplier Country',this.suppliercountry,typeof(this.suppliercountry));

  }

  applyFilters(){
    this.isloaderproglvlemail=true;
    this.supSelectedList=[];

    console.log('suppliercountry',this.suppliercountry);
    console.log('selectedSubmitStat',this.selectedSubmitStat.value);
    console.log('selectedCOS',this.selectedCOS.ownrshp_strat_code);
    console.log('selectedProgramName',this.selectedProgramName.programname);
    console.log('selectedPlantCode',this.selectedPlantCode.plantcode);
    console.log('selectedDaysRange',this.selectedDaysRange.days_range);

if (this.selectedPlantCode != null ){
this.pltcode = this.selectedPlantCode.plantcode;
}

if (this.selectedProgramName != null ){
  if (this.selectedProgramName.programname != 'ALL' && this.selectedProgramName.programname !=''){
  this.program_name = this.selectedProgramName.programname;
  }
  else
  {
    this.program_name='all';
  }
}
else
{
  this.program_name='all';
}

console.log('program_name',this.program_name);

if (this.selectedCOS != null && this.selectedCOS !='' ){
  this.cos_selected=true;
  if (this.selectedCOS.ownrshp_strat_code != 'ALL' && this.selectedCOS.ownrshp_strat_code !='' ){
    this.cos = this.selectedCOS.ownrshp_strat_code;
  }
  else
  {
  this.cos='all';
  }
}
else
{
  this.cos_selected=false;
  this.cos='all';
}

  console.log('this.cos ', this.cos);

  if (this.selectedSubmitStat != null && this.selectedSubmitStat !=''){
      this.submit_status = this.selectedSubmitStat.value;

      }


  if (this.selectedDaysRange != null && this.selectedDaysRange !=''){

    if (this.selectedDaysRange.days_range=='0-7 Days'){
      this.dayrange='1';
    // this.dayrange = this.selectedDaysRange.days_range;
    }
    else if (this.selectedDaysRange.days_range=='8-30 Days'){
      this.dayrange='2';
    }
    else if (this.selectedDaysRange.days_range=='31-60 Days'){
      this.dayrange='3';
    }
    else if (this.selectedDaysRange.days_range== '61-90 Days'){
      this.dayrange='4';
    }
    else if (this.selectedDaysRange.days_range== 'More than 90 Days'){
      this.dayrange='5';
    }
    else{
    this.dayrange='0'; // all case
    }
  }
    else { // all case
      this.dayrange='0';
    }

    console.log('dayrange',this.dayrange);


   if (this.suppliercountry != null && this.suppliercountry !=''){
        this.sup_cntry = this.suppliercountry;
    }
    else
    {
      this.sup_cntry = 'all';
    }


    this.transactionService.getListOfSuppliersForBulkEmail(this.pltcode,this.cos,this.dayrange,this.sup_cntry, this.submit_status,this.program_name).subscribe(supEmailList=> {
      this.supBulkEmail=[];

      if (supEmailList.length > 0) {

      this.supDetails=[];
      for (let i=0; i< supEmailList.length;i++ ){
        if (supEmailList[i].supplier_gsdbcode == null){
          this.supgsdbcode = '';
        }
        else
        {
          this.supgsdbcode = supEmailList[i].supplier_gsdbcode;
        }
        if (supEmailList[i].suppliername == null){
          this.supname = '';
        }
        else
        {
          this.supname = supEmailList[i].suppliername;
        }
        if (supEmailList[i].useremail == null){
          this.supemail = '';
        }
        else
        {
          this.supemail = supEmailList[i].useremail;
        }

          this.supemail = supEmailList[i].useremail;
          this.supBulkEmail.push({suppliername:supEmailList[i].suppliername ,
                                  gpp_userid : supEmailList[i].gpp_userid ,
                                  contactphone : supEmailList[i].contactphone ,
                                  contactfax : supEmailList[i].contactfax ,
                                  supplier_gsdbcode : supEmailList[i].supplier_gsdbcode,
                                  useremail : supEmailList[i].useremail,
                                   supplierDet: this.supgsdbcode + '-' + this.supname + '(' + this.supemail+ ')'})
      }

       this.enable_sup_pick_list=true;
      }
      else if (this.supBulkEmail.length==0) {
        this.search_result = 'No data found';
        this.enable_sup_pick_list=false;

      }

      this.isloaderproglvlemail=false;



    }
  )

}

 clearFilters()
  {
    this.selected_suppliercountry=[];
    this.selectedDaysRange='';
    this.selectedSubmitStat='';
    this.selectedCOS='';
    this.selectedProgramName={cd_region: '', programname:'',plantgsdbcode:''};
    this.selectedPlantCode={plantcode:'',plant:''};
    this.supSelectedList=[];
    this.supBulkEmail=[];
    this.search_result='';
    this.enable_sup_pick_list=false;
  }

  moveRecentAddedSupToFirst()
  {
    console.log('moveRecentAddedPlantToFirst');
    console.log('source list',this.supBulkEmail);
    const supToBePoppedOut=this.supBulkEmail.pop();
    this.supBulkEmail.splice(0,0,supToBePoppedOut)
  }



  checkIfSupSelected(){
    console.log('checkIfSupSelected');
    console.log('selected list',this.supSelectedList);
    if (this.supSelectedList.length > 0){
      this.enableGoToEmailEditor=true;
    }
    else
    {
      this.enableGoToEmailEditor=false;
    }
  }

  goToEmailEditor(){
    this.enableSendEmailPopUp=true;
    this.copyMeOnBulkEmail=true;
    this.bulkEmailSubject='ATTENTION : Action Required';
    this.enableSendEmailButton=false;
  }

  closeBulEmailPopUp()
  {
    console.log('close event');
    this.enableSendEmailPopUp=false;
    this.bulkEmailBody='';
    this.bulkEmailSubject='';
    this.copyMeOnBulkEmail=false;

  }

  selectedProgLevelGrpEmailPostInit()
  {
    this.selectedproglevelgrpemail={
      supCntDetls:[{
        supcode:'',
      email:'',
      }],
      subject:'',
      emailbody:'',
      copyme:'',
      userid:''
    }

  }

  sendProgLevelGrpEmail(){
    console.log('selectedproglevelgrpemail');
    console.log('selected email body',this.bulkEmailBody);
    console.log('selected email subject',this.bulkEmailSubject);
    this.selectedProgLevelGrpEmailPostInit();
    console.log('selected supplier email',this.supSelectedList);
    this.selectedproglevelgrpemail.supCntDetls=[];
    for (let i=0; i <  this.supSelectedList.length; i++ ){
      this.selectedproglevelgrpemail.supCntDetls.push({
        supcode:this.supSelectedList[i].supplier_gsdbcode,
        email:this.supSelectedList[i].useremail
      })
    }

    this.selectedproglevelgrpemail.emailbody=this.bulkEmailBody;
    this.selectedproglevelgrpemail.subject=this.bulkEmailSubject;
    let copyMe='N'
      if(this.copyMeOnBulkEmail)
      {
        copyMe='Y'
      }
    this.selectedproglevelgrpemail.copyme=copyMe;
    this.selectedproglevelgrpemail.userid=this.userid;

    console.log('program level group email post before api call',this.selectedproglevelgrpemail)

    this.transactionService.sendProgLevelGroupEmailForPost(this.selectedproglevelgrpemail).subscribe(selectedproglevelgrpemailRes=>{
    console.log('Program Level group Email For Post Response',selectedproglevelgrpemailRes);

    if(selectedproglevelgrpemailRes != null && selectedproglevelgrpemailRes.toLowerCase() == 'success')
      {
        this.enableSendEmailPopUp=false;
        this.bulkEmailBody='';
        this.bulkEmailSubject='';
        this.copyMeOnBulkEmail=false;
        this.supSelectedList=[];
        this.selectedProgLevelGrpEmailPostInit();
        this.enableGoToEmailEditor=false;
        this.messageService.add({severity:'success', summary: 'success', detail: 'Email sent successfully'});

      }
      else
      {
        this.messageService.add({severity:'error', summary: 'Error', detail: 'Email not sent,Please Try Again'})
      }


    })
    this.applyFilters();
  }

  }
