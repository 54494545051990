/* tslint:disable */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TransactionService } from '../loginpage/Transaction.service';

export interface linksTemplate
{
  linkurl:string;
  name:string;
}

@Component({
  selector: 'app-helpcomponent',
  templateUrl: './helpcomponent.component.html',
  styleUrls: ['./helpcomponent.component.css']
})

export class HelpcomponentComponent implements OnInit {

  constructor(private transactionService:TransactionService, private router:Router) { }

  // component not used
  enableApLinks=false;
  enableEuLinks=false;
  enableNaLinks=false;
  enableSaLinks=false;

  linksList:linksTemplate[]=[];

  linksAp:linksTemplate[]=[];
  linksEu:linksTemplate[]=[];
  linksNa:linksTemplate[]=[];
  linksSa:linksTemplate[]=[];
  commonLinks:linksTemplate[]=[];

  regionName='';
  regionDisplay=''

  ngOnInit(): void {


    this.linksList=[];
    this.regionName=this.transactionService.getregion();
    this.regionDisplay=this.regionName;
    let url=this.router.url.split('/');

    if(url[1].toLowerCase() == 'asiapacific')
    {
      this.linksList=this.linksAp;


    }
    else if(url[1].toLowerCase() == 'europe')
    {
      this.linksList=this.linksEu;
    }
    else if(url[1].toLowerCase() == 'northamerica')
    {
      this.linksList=this.linksNa;
    }
    else if(url[1].toLowerCase() == 'southamerica')
		{
      this.linksList=this.linksSa;
    }
    else
    {
      this.linksList=this.commonLinks;
      this.regionDisplay='Common Links';
    }
  }


  goToHomePage()
  {
    let cur_region=this.transactionService.getregion();
    if(cur_region != null )
    {
      let reg_map_url='';
      reg_map_url=this.transactionService.getregionurlvalue(cur_region);
      reg_map_url+='packagingprocessdashboard/packagingprocesshome';

      this.router.navigate([reg_map_url]);

    }

    else
    {
      this.router.navigate(['/exitpage']);
    }
  }

}
