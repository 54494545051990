import {Resolve} from '@angular/router';
import {
	Observable,
	of
} from 'rxjs';
import {Injectable} from '@angular/core';
import {ServiceResult} from '../model/service_result';
import {ApicallerService} from '../../services/apicaller.service';
import { catchError } from 'rxjs/internal/operators';

@Injectable()
export class ServiceResolve implements Resolve<ServiceResult> {
	constructor(private apicallerService: ApicallerService) {
	}

	resolve(): Observable<ServiceResult> {
		return this.apicallerService.callService().pipe(
			catchError(error => {
				console.error(`Error calling backing service`);
				console.error(error);
				return of(null);
			}));
	}
}

/*
  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<ProductResolved> {
    const id = route.paramMap.get('id');
    if (isNaN(+id)) {
      const message = `Product id was not a number: ${id}`;
      console.error(message);
      return of({ product: null, error: message });
    }

    return this.productService.getProduct(+id)
      .pipe(
        map(product => ({ product: product })),
        catchError(error => {
          const message = `Retrieval error: ${error}`;
          console.error(message);
          return of({ product: null, error: message });
        })
      );
  }
 */
