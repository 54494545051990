/* tslint:disable */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng';
import { TransactionService } from '../loginpage/Transaction.service';
import { ActivePlants, containerOwnershipList, countrylistreqacc, getUserType, mypendingaccessreq, mypendingaccessreqwithrole, programsbasedonplants, rolesDetails, rolesDetailsMappingEditAccess, userdetails } from '../loginpage/transactions';

@Component({
  selector: 'app-mypendingaccess',
  templateUrl: './mypendingaccess.component.html',
  styleUrls: ['./mypendingaccess.component.css']
})
export class MypendingaccessComponent implements OnInit {

  cdsid='';
  currentRegion='';
  region_code='';
  currentRouteid=0;

  plantList:ActivePlants[]=[];
  programList:programsbasedonplants[]=[];
  cosList:containerOwnershipList[]=[];
  userTypeList:getUserType[]=[];
  roleList:rolesDetailsMappingEditAccess[]=[];
  roleListApi:rolesDetails[]=[];


  // selectedWslID:string="";
  selectedUserName:any;
  // selectedGSDBCode:string="";
  // selectedUserType:getUserType={userType:"",UserDisplay:""};
  // selectedRoleList:rolesDetailsMappingEditAccess={cd_region:"",createdby:"",createddt:"",role_cd:0,role_id:0,role_desc:"",role_name:""};
  // selectedCOS:containerOwnershipList={cd_region:"",cd_ownrshp_strat:0,ownrshp_strat_code:"",ownrshp_desc:""};
  // selectedPlantCode:ActivePlants={cd_plant:"",plantGSDBCode:"",plant_name:"",plant_type_name:""};
  // selectedPlantName:ActivePlants={cd_plant:"",plantGSDBCode:"",plant_name:"",plant_type_name:""};
  // selectedProgram:programsbasedonplants={cd_region:"",plantgsdbcode:"",programname:""};

  prefix_list=[{prefix_name:'Mrs.'},{prefix_name:'Mr.'},{prefix_name:'Ms.'}];
  country_list:countrylistreqacc[]=[];
  selected_prefix:any;
  selected_countrylist:any;

  wslID='';
  userName='';
  regionaccess='';
  gsdbCode='';
  userType='';
  role='';
  cos='';
  plantcode='';
  plantname='';
  program='';
  role_name='';


  userAccessGrid:mypendingaccessreq[] = [];
  mypendingaccessreqwithrole:mypendingaccessreqwithrole[] = [];
  mypendingaccreqdets:mypendingaccessreqwithrole = {
        reqno: 0,
        gppuserid:'',
        user_name:'',
        name_prefix: '',
        cmpny_name: '',
        desig: '',
        phone: '',
        fax: '',
        email: '',
        address1: '',
        address2: '',
        city:'',
        state: '',
        postcode: '',
        country: '',
        reqstd_access:'',
		    role_name: '',
        req_supgsdbcodes:'',
        req_dsgnsrcids: '',
        req_plantgsdbcodes: '',
        region: '',
        user_type: '',
        req_type: '',
        req_date: ''
  };


  public cols:any=[];
  userInfoPopUp=false;


  userDetails:userdetails={
        gppuserid: '',
        title: '',
        name: '',
        company: '',
        designation: '',
        email: '',
        isforduser: '',
        isactive: '',
        createddt: '',
        createdby: '',
        phone: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        postalcode: '',
        cntrycodeiso: '',
        lastlogin: '',
        fax: '',
        country: '',
        usertype: ''
  }


  // userDetails:mypendingaccessreq={
  //   "reqno": 0,
  //   "gppuserid": "",
  //   "user_name": "",
  //   "name_prefix": "",
  //   "cmpny_name": "",
  //   "desig": "",
  //   "phone": "",
  //   "fax": "",
  //   "email": "",
  //   "address1": "",
  //   "address2": "",
  //   "city":"",
  //   "state": "",
  //   "postcode": "",
  //   "country": "",
  //   "reqstd_access":"",
  //   "req_supgsdbcodes":"",
  //   "req_dsgnsrcids": "",
  //   "req_plantgsdbcodes": "",
  //   "region": "",
  //   "user_type": "",
  //   "req_type": "",
  //   "req_date": ""
  // }

  // requestDetailsInit()
  // {
  //   this.mypendingaccreqdets={

  //   "reqno": 0,
  //   "gppuserid": "",
  //   "user_name": "",
  //   "name_prefix": "",
  //   "cmpny_name": "",
  //   "desig": "",
  //   "phone": "",
  //   "fax": "",
  //   "email": "",
  //   "address1": "",
  //   "address2": "",
  //   "city":"",
  //   "state": "",
  //   "postcode": "",
  //   "country": "",
  //   "reqstd_access":"",
  //   "role_name": "",
  //   "req_supgsdbcodes":"",
  //   "req_dsgnsrcids": "",
  //   "req_plantgsdbcodes": "",
  //   "region": "",
  //   "user_type": "",
  //   "req_type": "",
  //   "req_date": ""

  //   }
  // }

  // userDetails:mypendingaccessreq[]=[];

  roleListforMapping=[
  {role_id:'1',role_shot_name:'Supplier',rolefullname:'Supplier'},
  {role_id:'2',role_shot_name:'design_src',rolefullname:'Design Source'},
  {role_id:'3',role_shot_name:'prelimnry_appr',rolefullname:'Preliminary Approver'},
  {role_id:'4',role_shot_name:'addntl_appvr',rolefullname:'Additional Approver'},
  {role_id:'5',role_shot_name:'plt_appvr',rolefullname:'Plant Approver'},
  {role_id:'6',role_shot_name:'plt_final_apprvr',rolefullname:'Plant Final Approver'},
  {role_id:'7',role_shot_name:'concurent_user',rolefullname:'Concurrent User'},
  {role_id:'8',role_shot_name:'reg_bom_upld',rolefullname:'Region Bom Upload'},
  {role_id:'9',role_shot_name:'rel_for_eval',rolefullname:'Release for Evaluation'},
  {role_id:'11',role_shot_name:'rel_for_prod',rolefullname:'Release for Production'},
  {role_id:'12',role_shot_name:'regnl_admin',rolefullname:'Regional Admin'},
  {role_id:'13',role_shot_name:'reg_usr_admin',rolefullname:'Regional User Admin'},
  {role_id:'14',role_shot_name:'reg_admin_supvsr',rolefullname:'Regional Admin Supervisor'},
  {role_id:'15',role_shot_name:'reg_news_authr',rolefullname:'Regional User Author'},
  {role_id:'16',role_shot_name:'glbl_news_authr',rolefullname:'Global News Author'},
  {role_id:'17',role_shot_name:'reg_prg_admin',rolefullname:'Regional Program Admin'},
  {role_id:'18',role_shot_name:'bailment_supplier',rolefullname:'Bailment Supplier'},
  {role_id:'21',role_shot_name:'eqo_admin',rolefullname:'EQO Admin'},
  {role_id:'99',role_shot_name:'view_only',rolefullname:'View Only'},
  {role_id:'101',role_shot_name:'a_packaging',rolefullname:'Packaging Process'},
  {role_id:'102',role_shot_name:'a_partplanning',rolefullname:'Part Planning'},
  {role_id:'103',role_shot_name:'a_fedebom',rolefullname:'FEDEBOM View'}
]


  constructor(private transactionService:TransactionService,private router:Router,private messageService:MessageService) { }

  ngOnInit(): void {


  this.cdsid=this.transactionService.getcdsid();
  this.currentRegion=this.transactionService.getregion();
  this.region_code=this.transactionService.getregioncode(this.currentRegion);



  console.log('Region code1',this.region_code);

  this.populateGridForAccess();
  }

  populateGridForAccess()
  {

    console.log('before calling');

  this.transactionService.getMyUserPendingAccess(this.cdsid).subscribe(gridoutput =>{

   this.mypendingaccessreqwithrole=[];
    this.userAccessGrid=[];
       this.userAccessGrid=gridoutput;

      //  this.userAccessGridWithRoles = gridoutput;
      // console.log("EditaccessPrathyusha",this.userAccessGrid);
      console.log('EditaccessPrathyusha',gridoutput);



      for(let accessIter=0;accessIter<this.userAccessGrid.length;accessIter++)
      {
        let ind=this.roleListforMapping.findIndex(elemnt=>elemnt.role_id==this.userAccessGrid[accessIter].reqstd_access)
        console.log('ROLEREQ',ind)
        if(ind>-1)
        {
         this.role_name=this.roleListforMapping[ind].rolefullname;
         console.log('ROLEREQ2',this.role_name)
         this.mypendingaccessreqwithrole.push({

          reqno: this.userAccessGrid[accessIter].reqno,
        gppuserid:this.userAccessGrid[accessIter].gppuserid,
        user_name: this.userAccessGrid[accessIter].user_name,
        name_prefix:this.userAccessGrid[accessIter].name_prefix,
        cmpny_name:this.userAccessGrid[accessIter].cmpny_name,
        desig:this.userAccessGrid[accessIter].desig,
        phone: this.userAccessGrid[accessIter].phone,
        fax: this.userAccessGrid[accessIter].fax,
        email:this.userAccessGrid[accessIter].email,
        address1:this.userAccessGrid[accessIter].address1,
        address2:this.userAccessGrid[accessIter].address2,
        city:this.userAccessGrid[accessIter].city,
        state: this.userAccessGrid[accessIter].state,
        postcode:this.userAccessGrid[accessIter].postcode,
        country:this.userAccessGrid[accessIter].country,
        reqstd_access:this.userAccessGrid[accessIter].reqstd_access,
		    role_name: this.role_name,    // For role name to be displayed when role id is mapped with name
        req_supgsdbcodes:this.userAccessGrid[accessIter].req_supgsdbcodes,
        req_dsgnsrcids:this.userAccessGrid[accessIter].req_dsgnsrcids,
        req_plantgsdbcodes:this.userAccessGrid[accessIter].req_plantgsdbcodes,
        region:this.userAccessGrid[accessIter].region,
        user_type: this.userAccessGrid[accessIter].user_type,
        req_type:this.userAccessGrid[accessIter].req_type,
        req_date:this.userAccessGrid[accessIter].req_date,
         })


        }

      }

      this.cols=[
        {field:'gppuserid',header:'ID'},
        {field:'user_name',header:'User Name'},
        {field:'user_type',header:'User Type'},
        {field:'region',header:'Region'},
        {field:'role_name',header:'Requested Access'},
        {field:'req_plantgsdbcodes',header:'Plant GSDB Code'},
        {field:'req_dsgnsrcids',header:'Requested Design Source ID'},
        {field:'req_date',header:'Request Date'},
        {field:'req_supgsdbcodes',header:'Supplier GSDB Code'}
      ]
})


  }


  onBackOrCloseMyAccess()
  {
    // console.log("inside close function");

    let cur_region=this.transactionService.getregion();
    if(cur_region != null )
    {
      let reg_map_url='';
      reg_map_url=this.transactionService.getregionurlvalue(cur_region);
      reg_map_url+='packagingprocessdashboard/packagingprocesshome';

      this.router.navigate([reg_map_url]);

    }

    else
    {
      this.router.navigate(['/exitpage']);
    }
  }


  // getRequestdetails(reqDetails:mypendingaccessreq)
  // {
    // this.transactionService.user_details(reqDetails.gppuserid).subscribe(userdetailsinfo=>{
    //   this.userDetails=userdetailsinfo;
    //   this.userInfoPopUp=true;
    //   console.log("userDetails",this.userDetails);
    // })
  // }

  // getRequestdetails(reqDetails:mypendingaccessreq)
  // {

  //   // this.requestDetailsInit();
  //   // this.userDetails=Object.assign({},reqDetails);

    // var Index = this.userAccessGrid.findIndex(e=>e.user_name==this.getRequestdetails.name);
    // if(Index>=0)
    // {
    //   this.selectedUserName=this.userAccessGrid[Index];
    //   this.transactionService.getMyUserPendingAccess(reqDetails.gppuserid).subscribe(userdetailsinfo=>{

    //     this.userDetails=userdetailsinfo;
    //     this.userInfoPopUp=true;
    //     console.log("userDetails",this.userDetails);
    //   })
    // }
  //   else{
  //     this.selectedUserName=null;
  //   }


  // }


  getRequestdetails(reqDetails:mypendingaccessreqwithrole)
  {

    // this.requestDetailsInit();
    this.mypendingaccreqdets=Object.assign({},reqDetails);

    console.log('ROLECHECK',this.mypendingaccreqdets)

    this.transactionService.user_details(reqDetails.gppuserid).subscribe(userdetailsinfo=>{
      this.userDetails=userdetailsinfo;
      this.userInfoPopUp=true;
      console.log('userDetails',this.userDetails);

    })

    console.log('ROLEBEF')

    // var Index = this.userAccessGrid.findIndex(e=>e.reqstd_access==this.mypendingaccreqdets.reqstd_access);
    // console.log("ROLEAFTER",Index)
    // if(Index>-1)
    // {
      // console.log("ROLEENTER1",Index)
      // this.selectedUserName=this.userAccessGrid[Index];
      // this.transactionService.getMyUserPendingAccess(reqDetails.gppuserid).subscribe(userdetailsinfo=>{
      //  console.log("ROLEENTER")
      //   for(var accessIter=0;accessIter<this.mypendingaccessreqwithrole.length;accessIter++)
      //   {
      //     console.log("ROLEIN")
      //     var ind=this.roleListforMapping.findIndex(elemnt=>elemnt.role_id==this.mypendingaccessreqwithrole[accessIter].reqstd_access)
      //     console.log("ROLEREQUP",ind)
      //     if(ind>-1)
      //     {
      //      this.role_name=this.roleListforMapping[ind].rolefullname;
      //      console.log("ROLEREQ2UP",this.role_name)



      //       this.mypendingaccreqdets[accessIter].reqno = userdetailsinfo[accessIter].reqno;
      //       this.mypendingaccreqdets[accessIter].gppuserid = userdetailsinfo[accessIter].gppuserid;
      //       this.mypendingaccreqdets[accessIter].user_name = userdetailsinfo[accessIter].user_name;
      //       this.mypendingaccreqdets[accessIter].name_prefix = userdetailsinfo[accessIter].name_prefix;
      //       this.mypendingaccreqdets[accessIter].cmpny_name = userdetailsinfo[accessIter].cmpny_name;
      //       this.mypendingaccreqdets[accessIter].desig = userdetailsinfo[accessIter].desig;
      //       this.mypendingaccreqdets[accessIter].phone = userdetailsinfo[accessIter].phone;
      //       this.mypendingaccreqdets[accessIter].fax = userdetailsinfo[accessIter].fax;
      //       this.mypendingaccreqdets[accessIter].email = userdetailsinfo[accessIter].email;
      //       this.mypendingaccreqdets[accessIter].address1 = userdetailsinfo[accessIter].address1;
      //       this.mypendingaccreqdets[accessIter].address2 = userdetailsinfo[accessIter].address2;
      //       this.mypendingaccreqdets[accessIter].city = userdetailsinfo[accessIter].city;
      //       this.mypendingaccreqdets[accessIter].state = userdetailsinfo[accessIter].state;
      //       this.mypendingaccreqdets[accessIter].postcode = userdetailsinfo[accessIter].postcode;
      //       this.mypendingaccreqdets[accessIter].country = userdetailsinfo[accessIter].country;
      //       this.mypendingaccreqdets[accessIter].reqstd_access = userdetailsinfo[accessIter].reqstd_access;
      //       this.mypendingaccreqdets[accessIter].role_name = this.role_name;
      //       this.mypendingaccreqdets[accessIter].req_supgsdbcodes = userdetailsinfo[accessIter].req_supgsdbcodes;
      //       this.mypendingaccreqdets[accessIter].req_dsgnsrcids = userdetailsinfo[accessIter].req_dsgnsrcids;
      //       this.mypendingaccreqdets[accessIter].req_plantgsdbcodes = userdetailsinfo[accessIter].req_plantgsdbcodes;
      //       this.mypendingaccreqdets[accessIter].region = userdetailsinfo[accessIter].region;
      //       this.mypendingaccreqdets[accessIter].user_type = userdetailsinfo[accessIter].user_type;
      //       this.mypendingaccreqdets[accessIter].req_type = userdetailsinfo[accessIter].req_type;
      //       this.mypendingaccreqdets[accessIter].req_date = userdetailsinfo[accessIter].req_date;

      //     }

      //   }
      // })
    // }

  }
}

