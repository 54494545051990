/* tslint:disable */
import { PrefixNot } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { countryreglist, namePrefix, userInfo ,rolesDetails, regionMapping, rolesDetailsMappingRequestAccess, ActivePlants, requestGrid, suppdesgnList, roleMapping, countrylistreqacc, reqAcessSubmit, userdetails, bailmentSuppList, userDetailsEmailTriggerPost, userRequestDetialsEmailTrigger} from '../loginpage/transactions';
import {TransactionService} from '../loginpage/Transaction.service';
import { Router,ActivatedRoute } from '@angular/router';
import{DatePipe}from '@angular/common';
import { MessageService } from 'primeng/api';
import { stringify } from 'querystring';

@Component({
  selector: 'app-requestuseraccess',
  templateUrl: './requestuseraccess.component.html',
  styleUrls: ['./requestuseraccess.component.css']
})
export class RequestuseraccessComponent implements OnInit {



  userInfoGet:userInfo={gppuserid:"",user_name:"",name_prefix:"",desig:"",cmpny_name:"",phone:"",fax:"",email:"",address1:"",address2:"",city:"",state:"",postcode:"",country:""};
  userInfoGetApi:userdetails={gppuserid:"",name:"",title:"",company:"",email:"",city:"",cntrycodeiso:"",createdby:"",createddt:"",designation:"",isforduser:"",isactive:"",phone:"",postalcode:"",address1:"",address2:"",state:"",lastlogin:"",country:"",fax:"",usertype:""};


  selectedPrefix:namePrefix={prefix:""};
  selectedCountry:countrylistreqacc={cd_Country:"",country_name:"",active_stat:""};
  prefixList:namePrefix[]=[];
  countryList:countrylistreqacc[]=[];


  userId:string="";
  isValidsds:boolean=false;

  userType:string="";
  isFordUser:boolean=true;


  rolesListApi:rolesDetails[]=[];
  rolesList:rolesDetailsMappingRequestAccess[]=[];
  selectedRole:rolesDetailsMappingRequestAccess={ role_cd:0,role_id:0,role_name:"",cd_region:"",createddt:"",createdby:"",role_desc:"",enable_designsource:false,enable_plantcode:false,enable_supplier:false,enable_bailment_supplier:false}
  enable_supplier:boolean=false;
  enable_bailment_supplier:boolean=false;
  enable_designsource:boolean=false;
  enable_plantcode:boolean=false;


  plantList:ActivePlants[]=[];
  selectedPlantList:ActivePlants[]=[];
  selectedPlant:string="";



 supplierList:suppdesgnList[]=[]
 selectedSupplierList:suppdesgnList[]=[];
 selectedSupplier:string="";


 bailmentSupplierList:bailmentSuppList[]=[]
 selectedBailmentSupplierList:bailmentSuppList[]=[];



 designsourceList:suppdesgnList[]=[]
 selectedDesignsourceList:suppdesgnList[]=[];
 selectedDesignsource:string="";



 editRolesListApi:rolesDetails[]=[];
 editRolesList:rolesDetailsMappingRequestAccess[]=[];
 editSelectedRole:rolesDetailsMappingRequestAccess={ role_cd:0,role_id:0,role_name:"",cd_region:"",createddt:"",createdby:"",role_desc:"",enable_designsource:false,enable_plantcode:false,enable_supplier:false,enable_bailment_supplier:false}
 edit_enable_supplier:boolean=false;
 edit_enable_designsource:boolean=false;
 edit_enable_plantcode:boolean=false;
 edit_enable_bailment_supplier:boolean=false;


 editPlantList:ActivePlants[]=[];
 editSelectedPlantList:ActivePlants[]=[];
 editSelectedPlant:string="";




editSelectedSupplierList:suppdesgnList[]=[];
editSelectedBailmentSupplierList:bailmentSuppList[]=[];
editSelectedSupplier:string="";



editSelectedDesignsourceList:suppdesgnList[]=[];
editSelectedDesignsource:string="";


  regionList:regionMapping[]=[];


  req_id:number=0;


  reqGrid:requestGrid[]=[];
  editData:requestGrid={	role_id:0,
    role_name:"",
    role_desc:"",
    suppliercodes:"",
    designsourcecode:"",
    plantcodes:"",
    request_id:0,
    region:""}
    selectedRequestsRemoved:requestGrid[]=[];


    enableEditDialog:boolean=false;
    enableNotificationExist:boolean=false;


    edit_disableRegion:boolean=false;
    edit_disableRole:boolean=false;



selectedRegionList:regionMapping={region:"",regioncode:""}
editSelectedRegionList:regionMapping={region:"",regioncode:""}


acceptTermsConditions:boolean=false;


userRequestSubmit:reqAcessSubmit[]=[];



enableNotificationDialog:boolean=false;
notificationText:string="";





  roleListforMapping_ford:roleMapping[]=[{role_shot_name:"Supplier",rolefullname:"Supplier",enable_supplier:true,enable_designsource:false,enable_plantcode:false,enable_bailment_supplier:false},
  {role_shot_name:"design_src",rolefullname:"Design Source",enable_supplier:false,enable_designsource:true,enable_plantcode:false,enable_bailment_supplier:false},
  {role_shot_name:"prelimnry_appr",rolefullname:"Preliminary Approver",enable_supplier:false,enable_designsource:false,enable_plantcode:true,enable_bailment_supplier:false},
  {role_shot_name:"addntl_appvr",rolefullname:"Additional Approver",enable_supplier:false,enable_designsource:false,enable_plantcode:true,enable_bailment_supplier:false},
  {role_shot_name:"plt_appvr",rolefullname:"Plant Approver",enable_supplier:false,enable_designsource:false,enable_plantcode:true,enable_bailment_supplier:false},
  {role_shot_name:"plt_final_apprvr",rolefullname:"Plant Final Approver",enable_supplier:false,enable_designsource:false,enable_plantcode:true,enable_bailment_supplier:false},
  {role_shot_name:"rel_for_eval",rolefullname:"Release for Evaluation",enable_supplier:false,enable_designsource:false,enable_plantcode:true,enable_bailment_supplier:false},
  {role_shot_name:"rel_for_prod",rolefullname:"Release for Production",enable_supplier:false,enable_designsource:false,enable_plantcode:true,enable_bailment_supplier:false},
  {role_shot_name:"regnl_admin",rolefullname:"Regional Admin",enable_supplier:false,enable_designsource:false,enable_plantcode:false,enable_bailment_supplier:false},
  {role_shot_name:"cntr_admin",rolefullname:"Container/Component Admin",enable_supplier:false,enable_designsource:false,enable_plantcode:false,enable_bailment_supplier:false},
  {role_shot_name:"eqo_admin",rolefullname:"EQO Admin",enable_supplier:false,enable_designsource:false,enable_plantcode:false,enable_bailment_supplier:false},
  {role_shot_name:"reg_usr_admin",rolefullname:"Regional User Admin",enable_supplier:false,enable_designsource:false,enable_plantcode:false,enable_bailment_supplier:false},
  {role_shot_name:"reg_admin_supvsr",rolefullname:"Regional Admin Supervisor",enable_supplier:false,enable_designsource:false,enable_plantcode:false,enable_bailment_supplier:false},
  {role_shot_name:"reg_prg_admin",rolefullname:"Regional Program Admin",enable_supplier:false,enable_designsource:false,enable_plantcode:false,enable_bailment_supplier:false},
  {role_shot_name:"view_only",rolefullname:"View Only",enable_supplier:false,enable_designsource:false,enable_plantcode:false,enable_bailment_supplier:false},

  ]


  roleListforMapping_supplier:roleMapping[]=[
  {role_shot_name:"view_only",rolefullname:"View Only",enable_supplier:true,enable_designsource:false,enable_plantcode:false,enable_bailment_supplier:false},
  {role_shot_name:"Supplier",rolefullname:"Supplier",enable_supplier:true,enable_designsource:false,enable_plantcode:false,enable_bailment_supplier:false},
  {role_shot_name:"addntl_appvr",rolefullname:"Additional Approver",enable_supplier:true,enable_designsource:false,enable_plantcode:true,enable_bailment_supplier:false},
  {role_shot_name:"bailment_supplier",rolefullname:"Bailment Supplier",enable_supplier:false,enable_designsource:false,enable_plantcode:false,enable_bailment_supplier:true},

  //{role_shot_name:"plt_final_apprvr",rolefullname:"Plant Final Approver",enable_supplier:true,enable_designsource:false,enable_plantcode:true},
]


  roleListforMapping:roleMapping[]=[];


  userAccess:string;


  heading:string="";



  buttonName:string;



  public cols: any[];


  disableSubmitReqButton:boolean=true;
  disableSaveEdit:boolean=true;

  name_InValid : boolean = false;
  name_InValidReason: string = null;
  companyName_InValid : boolean = false;
  companyName_InValidReason: string = null;
  role_InValid : boolean = false;
  role_InValidReason: string = null;
  phone_InValid : boolean = false;
  phone_InValidReason: string = null;
  fax_InValid : boolean = false;
  fax_InValidReason: string = null;
  email_InValid : boolean = false;
  email_InValidReason: string = null;
  address_InValid : boolean = false;
  address_InValidReason: string = null;
  address2_InValid : boolean = false;
  address2_InValidReason: string = null;
  city_InValid : boolean = false;
  city_InValidReason: string = null;
  state_InValid : boolean = false;
  state_InValidReason: string = null;
  postalCode_InValid : boolean = false;
  postalCode_InValidReason: string = null;
  country_InValid : boolean = false;
  country_InValidReason: string = null;

  requestNotPresent : boolean = false;
  requestNotPresentReason : string = null;
  checkboxInvalid : boolean = false;
  checkboxInvalidReason : string = null;

  userRequestDetialsEmail:userRequestDetialsEmailTrigger[]=[];
  userDetailsForEmailPost:userDetailsEmailTriggerPost={
    gppuserid:"",
    function:0,
    userRequestAccessList:[]
  };






  constructor(private transactionService: TransactionService,private router:Router,private date:DatePipe,private messageService:MessageService) { }

  ngOnInit(): void {


    this.prefixList=[{prefix:"Mr."},{prefix:"Ms."},{prefix:"Mrs."}];
    this.regionList=[{region:"Asia Pacific",regioncode:"AP"},
    {region:"Europe",regioncode:"EU"},
    {region:"North America",regioncode:"NA"},
    {region:"South America",regioncode:"SA"}]


    this.cols=[  { field: 'region', header: 'Region' },
    { field: 'role_desc', header: 'Role' },
    { field: 'suppliercodes', header: 'SupplierGSDBcodes' },
    { field: 'designsourcecode', header:'DesignSourcecodes'},
    { field: 'plantcodes', header: 'Plantcodes' },]
    this.selectedPrefix=this.prefixList[0];
    this.selectedRegionList=this.regionList[0];


    this.buttonName="Add Request";
    this.disableSubmitReqButton=false;
    this.disableSaveEdit=true;

    this.transactionService.getSupplierListReqAccess().subscribe(supout=>{
      console.log(supout);
      this.supplierList=supout
    })


    this.transactionService.getDesignsourceListReqAccess().subscribe(desgout=>{
      console.log(desgout);
      this.designsourceList=desgout
    })


    this.transactionService.getBailmentSupplierListReqAccess().subscribe(bailmentsuppout=>{
      console.log(bailmentsuppout);
      this.bailmentSupplierList=bailmentsuppout
    })

    //api to get bailment supplier list


    //country list api
    //supplier list api
    //designsource api
    //role list api




   this.isValidsds = JSON.parse(sessionStorage.getItem('isValidCdsid'));

   console.log("this.userAccess",this.isValidsds)
   //this.isValidsds =false;
    if(this.isValidsds==false)
    {
      this.heading="Request System Access"
      this.userId = JSON.parse(sessionStorage.getItem('InvalidCdsid'));
      //this.userId = "l-pmonish1";
      this.userAccess="New"
      if(this.userId!=null)
      {
      var cdsArr=[];
      cdsArr=this.userId.split('-');
      //if(cdsArr.length>1 && cdsArr[0].toLowerCase()=="l")
      if(cdsArr.length==1)
      {
        this.userType="Ford"
        this.isFordUser=true;


      }
      else
      {
        this.userType="Supplier";
        this.isFordUser=false;


      }


      this.transactionService.getCountryListReqAccess().subscribe(cntryout=>{
        console.log(cntryout);
        this.countryList=cntryout;
      })

      this.onChangeRegion();




      }



      else
      {
        //try again notification
      }




    }
    else if(this.isValidsds==true)
    {


      this.heading="Request Additional Access"

      this.userId=this.transactionService.getcdsid();
      this.userAccess="Update"


      console.log(this.userAccess)




      this.transactionService.getCountryListReqAccess().subscribe(cntryout=>{
        console.log(cntryout);
        this.countryList=[];
        this.countryList=cntryout;


        this.transactionService.user_details(this.userId).subscribe(user_det=>{
          console.log(user_det);
          this.userInfoGetApi=user_det;


          this.userInfoGet.gppuserid=this.userInfoGetApi.gppuserid;
          this.userInfoGet.name_prefix=this.userInfoGetApi.title;
          this.userInfoGet.phone=this.userInfoGetApi.phone;
          this.userInfoGet.cmpny_name=this.userInfoGetApi.company;
          this.userInfoGet.city=this.userInfoGetApi.city;
          this.userInfoGet.country=this.userInfoGetApi.country
          this.userInfoGet.address1=this.userInfoGetApi.address1
          this.userInfoGet.address2=this.userInfoGetApi.address2
          this.userInfoGet.fax=this.userInfoGetApi.fax
          this.userInfoGet.desig=this.userInfoGetApi.designation
          this.userInfoGet.email=this.userInfoGetApi.email
          this.userInfoGet.state=this.userInfoGetApi.state
          this.userInfoGet.postcode=this.userInfoGetApi.postalcode;
          this.userInfoGet.user_name=this.userInfoGetApi.name;



          if(this.userInfoGet.name_prefix!=null && this.userInfoGet.name_prefix!="")
          {


          var prefixIndex=this.prefixList.findIndex(elprefix=>elprefix.prefix.toLowerCase()==this.userInfoGet.name_prefix.toLowerCase())

          if(prefixIndex!=-1)
          {
            this.selectedPrefix=this.prefixList[prefixIndex];
          }
        }

        if(this.userInfoGet.country!=null && this.userInfoGet.country!="")
        {

          var countryIndex=this.countryList.findIndex(elcntrylist=>elcntrylist.country_name.toLowerCase()==this.userInfoGet.country.toLowerCase())


          if(countryIndex!=-1)
          {
            this.selectedCountry=this.countryList[countryIndex];
          }
        }

          //console.log(this.userInfoGet);

      if(this.userId!=null)
      {
        if(this.userInfoGetApi.isforduser!=null)
        {

           if(this.userInfoGetApi.isforduser.toLowerCase()=="yes")
           {
            this.userType="Ford"
            this.isFordUser=true;
           }
          else
           {
            this.userType="Supplier";
            this.isFordUser=false;
           }
        }
      else
     {


      var cdsArrUpdate=[];
      cdsArrUpdate=this.userId.split('-');
      //if(cdsArr.length>1 && cdsArr[0].toLowerCase()=="l")
      if(cdsArrUpdate.length==1)
      {
        this.userType="Ford"
        this.isFordUser=true;


      }
      else
      {
        this.userType="Supplier";
        this.isFordUser=false;


      }


    }

    }

      this.onChangeRegion();

        })


       //load user config details;
      //assign user type
      })






    }














  }

userInfoInitValidation()
{
  this.name_InValid = false;
  this.name_InValidReason = null;
  this.companyName_InValid = false;
  this.companyName_InValidReason = null;
  this.role_InValid = false;
  this.role_InValidReason = null;
  this.phone_InValid = false;
  this.phone_InValidReason = null;
  this.fax_InValid = false;
  this.fax_InValidReason = null;
  this.email_InValid = false;
  this.email_InValidReason = null;
  this.address_InValid  = false;
  this.address_InValidReason = null;
  this.address2_InValid = false;
  this.address2_InValidReason = null;
  this.city_InValid = false;
  this.city_InValidReason = null;
  this.state_InValid = false;
  this.state_InValidReason= null;
  this.postalCode_InValid = false;
  this.postalCode_InValidReason = null;
  this.country_InValid = false;
  this.country_InValidReason = null;

  this.checkboxInvalid = false;
  this.checkboxInvalidReason = null;
  this.requestNotPresent = false;
  this.requestNotPresentReason = null;

}
  onBackOrClose()
  {
    console.log("inside close function");

    var cur_region=this.transactionService.getregion();
    if(this.isValidsds == true && cur_region != null )
    {
      var reg_map_url="";
      reg_map_url=this.transactionService.getregionurlvalue(cur_region);
      reg_map_url+="packagingprocessdashboard/packagingprocesshome";

      this.router.navigate([reg_map_url]);

    }

    else
    {
      this.router.navigate(['/exitpage']);
    }
  }




  onChangeRegion()
  {
    this.rolesList=[];
    this.selectedRole={ role_cd:0,role_id:0,role_name:"",cd_region:"",createddt:"",createdby:"",role_desc:"",enable_designsource:false,enable_plantcode:false,enable_supplier:false,enable_bailment_supplier:false}
    this.selectedPlant="";
    this.selectedPlantList=[];

    this.enable_supplier=false;
    this.enable_designsource=false;
    this.enable_plantcode=false;
    this.enable_bailment_supplier=false;



    this.selectedSupplierList=[];
    this.selectedPlantList=[];
    this.selectedDesignsourceList=[];
    this.selectedBailmentSupplierList=[];




    if(this.userType.toLowerCase()=="ford")
      {
        this.roleListforMapping=this.roleListforMapping_ford;

      }

      else  if(this.userType.toLowerCase()=="supplier")
      {
        this.roleListforMapping=this.roleListforMapping_supplier;

      }








    this.transactionService.getRolesThatCanBeGivenAccess(this.selectedRegionList.regioncode).subscribe(rolelist=>{
      console.log(rolelist);
      this.rolesListApi=[];
      this.rolesList=[];
      this.rolesListApi=rolelist;

      for(var i of this.roleListforMapping)
      {
        var roleShortName=i.role_shot_name;
        console.log(roleShortName);
        console.log(this.rolesListApi);
        var indRole=this.rolesListApi.findIndex(el=>el.role_name.toLowerCase()==roleShortName.toLowerCase());
        console.log(indRole);
        if(indRole!=-1)
        {
          this.rolesList.push({
            role_cd:this.rolesListApi[indRole].role_cd,
            role_id:this.rolesListApi[indRole].role_id,
            role_name:this.rolesListApi[indRole].role_name,
            cd_region:this.rolesListApi[indRole].cd_region,
            createddt:this.rolesListApi[indRole].createddt,
            createdby:this.rolesListApi[indRole].createdby,
            role_desc:i.rolefullname,
            enable_designsource:i.enable_designsource,
            enable_supplier:i.enable_supplier,
            enable_plantcode:i.enable_plantcode,
            enable_bailment_supplier:i.enable_bailment_supplier

          })
        }
      }





      console.log(this.rolesList)
    })








    this.transactionService.getactiveplantslist(this.selectedRegionList.regioncode).subscribe(plantco=>{
      this.plantList=[];

     console.log("Plant List Reqauest access",plantco);
     this.plantList=plantco;

      console.log("plantcodes from backend",this.plantList);
    })

  }


  onChangeRole()
  {


    this.selectedSupplier="";
    this.selectedDesignsource="";


    this.selectedSupplierList=[];
    this.selectedPlantList=[];
    this.selectedDesignsourceList=[];
    this.selectedBailmentSupplierList=[];


    this.enable_supplier=this.selectedRole.enable_supplier;
  this.enable_designsource=this.selectedRole.enable_designsource;
  this.enable_plantcode=this.selectedRole.enable_plantcode;
  this.enable_bailment_supplier=this.selectedRole.enable_bailment_supplier;









  }



  addRequest()
  {


    if(this.selectedRequestsRemoved.length!=0)
    {
      this.selectedRequestsRemoved=[];
    }

    if(this.enable_plantcode==true)
    {

    if(this.selectedPlantList.length!=0)
    {

      if(this.selectedPlantList.length==this.plantList.length)
      {
        this.selectedPlant="all"

      }
      else
      {
        this.selectedPlant="";
              var plt_count=0;
              for(var p_plt=0;p_plt<this.selectedPlantList.length;p_plt++)
              {
                 this.selectedPlant+=this.selectedPlantList[p_plt].plantGSDBCode
                 plt_count+=1;
                 if(plt_count!=this.selectedPlantList.length)
                 {
                   this.selectedPlant+=",";
                 }
              }

      }

    }
            else
              {
                 this.selectedPlant=""
                }
    }
    else
    {
      this.selectedPlant=""
    }



    if(this.enable_supplier == true && this.enable_bailment_supplier == false)
    {

    if(this.selectedSupplierList.length!=0)
    {

    /*  if(this.selectedSupplierList.length==this.supplierList.length)
      {
        this.selectedSupplier="all"

      }
      else
      {*/
        this.selectedSupplier="";
              var sup_count=0;
              for(var p_plt=0;p_plt<this.selectedSupplierList.length;p_plt++)
              {
                 this.selectedSupplier+=this.selectedSupplierList[p_plt].suppliergsdbcode
                 sup_count+=1;
                 if(sup_count!=this.selectedSupplierList.length)
                 {
                   this.selectedSupplier+=",";
                 }
              }

     // }

    }
            else
              {
                 this.selectedSupplier=""
                }
    }







   else if(this.enable_supplier==false && this.enable_bailment_supplier == true)
    {

  if(this.selectedBailmentSupplierList.length!=0)
    {

     /* if(this.selectedBailmentSupplierList.length==this.bailmentSupplierList.length)
      {
        this.selectedSupplier="all"

      }
      else
      {*/
        this.selectedSupplier="";
              var sup_count=0;
              for(var p_plt=0;p_plt<this.selectedBailmentSupplierList.length;p_plt++)
              {
                 this.selectedSupplier+=this.selectedBailmentSupplierList[p_plt].offsitesupplierid
                 sup_count+=1;
                 if(sup_count!=this.selectedBailmentSupplierList.length)
                 {
                   this.selectedSupplier+=",";
                 }
              }

      //}

    }
            else
              {
                 this.selectedSupplier=""
                }
    }
    else
    {
      this.selectedSupplier=""
    }





    if(this.enable_designsource==true)
    {

    if(this.selectedDesignsourceList.length!=0)
    {

    /*  if(this.selectedDesignsourceList.length==this.designsourceList.length)
      {
        this.selectedDesignsource="all"

      }
      else
      {*/
        this.selectedDesignsource="";
              var des_count=0;
              for(var p_plt=0;p_plt<this.selectedDesignsourceList.length;p_plt++)
              {
                 this.selectedDesignsource+=this.selectedDesignsourceList[p_plt].suppliergsdbcode
                 des_count+=1;
                 if(des_count!=this.selectedDesignsourceList.length)
                 {
                   this.selectedDesignsource+=",";
                 }
              }

    //  }

    }
            else
              {
                 this.selectedDesignsource=""
                }
    }
    else
    {
      this.selectedDesignsource=""
    }

    this.req_id+=1;


    if(this.reqGrid.length!=0)
    {

      var findgridindex=this.reqGrid.findIndex(elgf=>elgf.role_id==this.selectedRole.role_id && (elgf.designsourcecode.toLowerCase()==this.selectedDesignsource.toLowerCase() || elgf.designsourcecode.toLowerCase()=="all") &&
     ( elgf.suppliercodes.toLowerCase()==this.selectedSupplier|| elgf.suppliercodes.toLowerCase()=="all") && (elgf.plantcodes.toLowerCase()== this.selectedPlant.toLowerCase()|| elgf.plantcodes.toLowerCase()=="all") &&
      elgf.region.toLowerCase()==this.selectedRegionList.regioncode.toLowerCase())


      if(findgridindex!=-1 )
      {
        this.enableNotificationExist=true;
      }
      else
      {

        if(this.enable_plantcode==true && this.enable_supplier==false && this.enable_bailment_supplier == false && this.enable_designsource==false && this.selectedPlant.toLowerCase()!="all" )
        {




             var temp_req_grid=this.reqGrid;
           for(var re=0; re<temp_req_grid.length;re++ )
           {


            if(temp_req_grid[re].role_id==this.selectedRole.role_id && (temp_req_grid[re].designsourcecode.toLowerCase()==this.selectedDesignsource.toLowerCase()) &&
            (temp_req_grid[re].suppliercodes.toLowerCase()==this.selectedSupplier.toLowerCase())&&
            temp_req_grid[re].region.toLowerCase()==this.selectedRegionList.regioncode.toLowerCase())
             {

              this.reqGrid.splice(re,1);
              console.log("plant",this.reqGrid)

             }

           }


           console.log("plant",this.reqGrid)





        }
        else if(this.enable_supplier==true && this.enable_bailment_supplier == false && this.enable_plantcode==false && this.enable_designsource==false && this.selectedSupplier.toLowerCase()!="all")
        {




             var temp_req_grid=this.reqGrid;
           for(var re=0; re<temp_req_grid.length;re++ )
           {


            if(temp_req_grid[re].role_id==this.selectedRole.role_id && (temp_req_grid[re].designsourcecode.toLowerCase()==this.selectedDesignsource.toLowerCase()) &&
            (temp_req_grid[re].plantcodes.toLowerCase()==this.selectedPlant.toLowerCase())&&
            temp_req_grid[re].region.toLowerCase()==this.selectedRegionList.regioncode.toLowerCase())
             {

              this.reqGrid.splice(re,1);
              console.log("plant",this.reqGrid)

             }

           }


           console.log("plant",this.reqGrid)





        }

        else if(this.enable_bailment_supplier == true && this.enable_supplier==false && this.enable_plantcode==false && this.enable_designsource==false && this.selectedSupplier.toLowerCase()!="all")
        {




             var temp_req_grid=this.reqGrid;
           for(var re=0; re<temp_req_grid.length;re++ )
           {


            if(temp_req_grid[re].role_id==this.selectedRole.role_id && (temp_req_grid[re].designsourcecode.toLowerCase()==this.selectedDesignsource.toLowerCase()) &&
            (temp_req_grid[re].plantcodes.toLowerCase()==this.selectedPlant.toLowerCase())&&
            temp_req_grid[re].region.toLowerCase()==this.selectedRegionList.regioncode.toLowerCase())
             {

              this.reqGrid.splice(re,1);
              console.log("plant",this.reqGrid)

             }

           }


           console.log("plant",this.reqGrid)





        }

        else if(this.enable_designsource==true && this.enable_supplier==false && this.enable_bailment_supplier == false && this.enable_plantcode==false &&  this.selectedDesignsource.toLowerCase()!="all")
        {




             var temp_req_grid=this.reqGrid;
           for(var re=0; re<temp_req_grid.length;re++ )
           {


            if(temp_req_grid[re].role_id==this.selectedRole.role_id && (temp_req_grid[re].plantcodes.toLowerCase()==this.selectedPlant.toLowerCase()) &&
            (temp_req_grid[re].suppliercodes.toLowerCase()==this.selectedSupplier.toLowerCase())&&
            temp_req_grid[re].region.toLowerCase()==this.selectedRegionList.regioncode.toLowerCase())
             {

              this.reqGrid.splice(re,1);
              console.log("plant",this.reqGrid)

             }

           }


           console.log("plant",this.reqGrid)





        }
        else if(this.enable_supplier==true && this.enable_plantcode==true && this.enable_bailment_supplier == false && this.enable_designsource==false &&  this.selectedPlant.toLowerCase()!="all" &&  this.selectedSupplier.toLowerCase()!="all")
        {




             var temp_req_grid=this.reqGrid;
             for(var re=0; re<temp_req_grid.length;re++ )
             {

            var reIndex=this.reqGrid.findIndex(elresp=>elresp.role_id==this.selectedRole.role_id && (elresp.designsourcecode.toLowerCase()==this.selectedDesignsource.toLowerCase()) &&
            elresp.region.toLowerCase()==this.selectedRegionList.regioncode.toLowerCase())


             if(reIndex!=-1)
             {
              this.reqGrid.splice(reIndex,1);
              console.log("plant",this.reqGrid)
             }

             else
             {

              var reIndexS=this.reqGrid.findIndex(elrespt=>elrespt.role_id==this.selectedRole.role_id && (elrespt.designsourcecode.toLowerCase()==this.selectedDesignsource.toLowerCase()) &&
            (elrespt.plantcodes.toLowerCase()==this.selectedPlant.toLowerCase())&&
            elrespt.region.toLowerCase()==this.selectedRegionList.regioncode.toLowerCase())


            if(reIndexS!=-1)
            {
             this.reqGrid.splice(reIndexS,1);
             console.log("plant",this.reqGrid)
            }





             }
            }


           /*  if(reIndex!=-1)
             {
              this.reqGrid.splice(reIndex,1);
              console.log("plant",this.reqGrid)
             }*/





           console.log("plant",this.reqGrid)





        }


        ////////all cases

       else if(this.selectedPlant.toLowerCase()=="all" && this.selectedSupplier.toLowerCase()!="all")
        {




             var temp_req_grid=this.reqGrid;
           for(var re=0; re<temp_req_grid.length;re++ )
           {


            if(temp_req_grid[re].role_id==this.selectedRole.role_id && (temp_req_grid[re].designsourcecode.toLowerCase()==this.selectedDesignsource.toLowerCase()) &&
            (temp_req_grid[re].suppliercodes.toLowerCase()==this.selectedSupplier.toLowerCase())&&
            temp_req_grid[re].region.toLowerCase()==this.selectedRegionList.regioncode.toLowerCase())
             {

              this.reqGrid.splice(re,1);
              console.log("plant",this.reqGrid)

             }

           }


           console.log("plant",this.reqGrid)





        }


        else  if(this.selectedPlant.toLowerCase()!="all" && this.selectedSupplier.toLowerCase()=="all")
        {


          var temp_req_grid=this.reqGrid;
          for(var re=0; re< temp_req_grid.length;re++ )
          {


           if(temp_req_grid[re].role_id==this.selectedRole.role_id && (temp_req_grid[re].designsourcecode.toLowerCase()==this.selectedDesignsource.toLowerCase()) &&
           ( temp_req_grid[re].plantcodes.toLowerCase()==this.selectedPlant.toLowerCase())&&
           temp_req_grid[re].region.toLowerCase()==this.selectedRegionList.regioncode.toLowerCase())
            {

             this.reqGrid.splice(re,1);

            }

          }


        }


        else  if(this.selectedPlant.toLowerCase()=="all" && this.selectedSupplier.toLowerCase()=="all")
        {

          var temp_req_grid=this.reqGrid;
          for(var re=0; re< temp_req_grid.length;re++ )
          {


           if(temp_req_grid[re].role_id==this.selectedRole.role_id && (temp_req_grid[re].designsourcecode.toLowerCase()==this.selectedDesignsource.toLowerCase()) &&
           temp_req_grid[re].region.toLowerCase()==this.selectedRegionList.regioncode.toLowerCase())
            {

             this.reqGrid.splice(re,1);

            }

          }


        }

        else  if(this.selectedDesignsource.toLowerCase()=="all")
        {

          var temp_req_grid=this.reqGrid;
          for(var re=0; re< temp_req_grid.length;re++ )
          {


           if(temp_req_grid[re].role_id==this.selectedRole.role_id && (temp_req_grid[re].plantcodes.toLowerCase()==this.selectedPlant.toLowerCase()) &&
           ( temp_req_grid[re].suppliercodes.toLowerCase()==this.selectedSupplier.toLowerCase())&&
           temp_req_grid[re].region.toLowerCase()==this.selectedRegionList.regioncode.toLowerCase())
            {

             this.reqGrid.splice(re,1);

            }

          }


        }
        var sortReqGrid=this.reqGrid;
        this.reqGrid=[];
        this.reqGrid.push({
          region:this.selectedRegionList.regioncode,
          request_id:this.req_id,
          role_desc:this.selectedRole.role_desc,
          role_id:this.selectedRole.role_id,
          suppliercodes:this.selectedSupplier,
          designsourcecode:this.selectedDesignsource,
          plantcodes:this.selectedPlant,
          role_name:this.selectedRole.role_name
        })


        this.reqGrid.push(...sortReqGrid);
      }
    }

    else
    {
      this.reqGrid.push({
        region:this.selectedRegionList.regioncode,
        request_id:this.req_id,
        role_desc:this.selectedRole.role_desc,
        role_id:this.selectedRole.role_id,
        suppliercodes:this.selectedSupplier,
        designsourcecode:this.selectedDesignsource,
        plantcodes:this.selectedPlant,
        role_name:this.selectedRole.role_name
      })

    }


    console.log(this.reqGrid)


    this.selectedRole={ role_cd:0,role_id:0,role_name:"",cd_region:"",createddt:"",createdby:"",role_desc:"",enable_designsource:false,enable_plantcode:false,enable_supplier:false,enable_bailment_supplier:false}
    this.enable_supplier=false;
    this.enable_designsource=false;
    this.enable_plantcode=false;
    this.enable_bailment_supplier=false;
    this.selectedSupplierList=[];
    this.selectedPlantList=[];
    this.selectedDesignsourceList=[];
    this.selectedBailmentSupplierList=[];


    if(this.reqGrid.length>=1)
    {
      this.buttonName="Add Another Request"
    }

    if(this.reqGrid.length != 0 && this.requestNotPresent == true)
    {
      this.requestNotPresent = false;
      this.requestNotPresentReason = null;
    }







  }






  isDisbaleAddRequest():boolean
  {


    var disable_plant_button=false;
    var disable_supplier_button=false;
    var disable_designsource_button=false;
    var disable_bailment_supplier_button=false;

    if(this.selectedRole.role_name!="")
    {

    if(this.enable_plantcode==true && this.selectedPlantList.length==0)
    {
      disable_plant_button=true

    }
    else
    {
      disable_plant_button=false;
    }



    if(this.enable_supplier==true && this.enable_bailment_supplier == false && this.selectedSupplierList.length==0)
    {
      disable_supplier_button=true

    }
    else
    {
      disable_supplier_button=false;
    }

    if(this.enable_designsource==true && this.selectedDesignsourceList.length==0)
    {
      disable_designsource_button=true

    }
    else
    {
      disable_designsource_button=false;
    }
    if(this.enable_bailment_supplier==true && this.enable_supplier==false && this.selectedBailmentSupplierList.length==0)
    {
      disable_bailment_supplier_button=true

    }
    else
    {
      disable_bailment_supplier_button=false;
    }

    return (disable_plant_button|| disable_supplier_button|| disable_designsource_button || disable_bailment_supplier_button)
  }
  else
  {
    return true;
  }



  }


  enableGrid():boolean
  {

    if(this.reqGrid.length!=0)
    {
      return true
    }

    else
    {
      return false
    }

  }


  okNotificationDialog()
  {

    this.enableNotificationExist=false;

  }






  editRequest(rowData:any)
  {
    this.enableEditDialog=true;
    this.editData=rowData;
    this.edit_enable_supplier=false;
    this.edit_enable_designsource=false;
    this.edit_enable_plantcode=false;
    this.editDialog();
  }

  editDialog()
  {
    this.disableSaveEdit=true;
    this.edit_disableRegion=true;
    this.edit_disableRole=true;


    this.editSelectedRegionList={region:"",regioncode:""}


    this.editRolesList=[];
    this.editSelectedRole={ role_cd:0,role_id:0,role_name:"",cd_region:"",createddt:"",createdby:"",role_desc:"",enable_designsource:false,enable_plantcode:false,enable_supplier:false,enable_bailment_supplier:false}
    this.editSelectedPlant="";
    this.editSelectedPlantList=[];

    this.editSelectedSupplier="";
    this.editSelectedSupplierList=[];
    this.editSelectedDesignsource="";
    this.editSelectedDesignsourceList=[];

    //this.editSelectedBailmentSupplierList=[];


    var regIndex=this.regionList.findIndex(elreg=>elreg.regioncode.toLowerCase()==this.editData.region.toLowerCase())

    if(regIndex!=-1)
    {
      this.editSelectedRegionList=this.regionList[regIndex]
    }

    console.log(this.editData)

    this.transactionService.getRolesThatCanBeGivenAccess(this.editData.region).subscribe(rolelist=>{
      this.editRolesListApi=[];
      this.editRolesList=[];

      console.log("getRolesThatCanBeGivenAccess api o/p",rolelist)
      if(rolelist != null && rolelist.length != 0)
      {
      this.editRolesListApi=rolelist;
      var roles_count=0;


      for(var i of this.roleListforMapping)
      {
        var roleShortName=i.role_shot_name;

        var indRole=this.editRolesListApi.findIndex(el=>el.role_name.toLowerCase()==roleShortName.toLowerCase());
        if(indRole!=-1)
        {
          this.editRolesList.push({
            role_cd:this.editRolesListApi[indRole].role_cd,
            role_id:this.editRolesListApi[indRole].role_id,
            role_name:this.editRolesListApi[indRole].role_name,
            cd_region:this.editRolesListApi[indRole].cd_region,
            createddt:this.editRolesListApi[indRole].createddt,
            createdby:this.editRolesListApi[indRole].createdby,
            role_desc:i.rolefullname,
            enable_designsource:i.enable_designsource,
            enable_supplier:i.enable_supplier,
            enable_plantcode:i.enable_plantcode,
            enable_bailment_supplier:i.enable_bailment_supplier

          })
          roles_count+=1;
        }


      }

      var indRoleID=this.editRolesList.findIndex(el=>el.role_id==this.editData.role_id);
      if(indRoleID != -1)
      {
        this.editSelectedRole=this.editRolesList[indRoleID];
        this.edit_enable_supplier=this.editSelectedRole.enable_supplier;
      this.edit_enable_designsource=this.editSelectedRole.enable_designsource;
      this.edit_enable_plantcode=this.editSelectedRole.enable_plantcode;
      this.edit_enable_bailment_supplier=this.editSelectedRole.enable_bailment_supplier;

      }



            if( this.editData.suppliercodes!=null && this.editData.suppliercodes!="")
            {
              if(this.edit_enable_bailment_supplier == false && this.edit_enable_supplier == true)
              {
                    var sel_supplier=[]
                    sel_supplier=this.editData.suppliercodes.split(',');



                        if((sel_supplier.length==1 && sel_supplier[0].toLowerCase()=="all") || (sel_supplier.length == this.supplierList.length))
                        {
                          this.editSelectedSupplierList.push(...this.supplierList);
                        }
                        else
                        {

                          for(var is=0;is<sel_supplier.length;is++)
                          {
                            var suppindex=this.supplierList.findIndex(els=>els.suppliergsdbcode.toLowerCase()==sel_supplier[is].toLowerCase());
                            if(suppindex!=-1)
                            {
                              this.editSelectedSupplierList.push(this.supplierList[suppindex]);
                            }
                          }
                        }
                        console.log(this.editSelectedSupplierList)
            }

          else if(this.edit_enable_bailment_supplier == true && this.edit_enable_supplier == false)
              {
                    var sel_supplier=[]
                    sel_supplier=this.editData.suppliercodes.split(',');

                    this.editSelectedBailmentSupplierList=[];


                  if((sel_supplier.length==1 && sel_supplier[0].toLowerCase()=="all") || ( sel_supplier.length == this.bailmentSupplierList.length))
                    {
                    this.editSelectedBailmentSupplierList.push(...this.bailmentSupplierList);
                    }
                  else
                    {

                            for(var is=0;is<sel_supplier.length;is++)
                            {
                              var suppindex=this.bailmentSupplierList.findIndex(els=>els.offsitesupplierid.toLowerCase()==sel_supplier[is].toLowerCase());
                              if(suppindex!=-1)
                              {
                                this.editSelectedBailmentSupplierList.push(this.bailmentSupplierList[suppindex]);
                              }
                            }
                          }
                  }
        }

           if( this.editData.designsourcecode!=null && this.editData.designsourcecode!="")
            {
            var sel_desgn=[]
            sel_desgn=this.editData.designsourcecode.split(',');


            if((sel_desgn.length==1 && sel_desgn[0].toLowerCase()=="all") || (sel_desgn.length == this.designsourceList.length))
          {
          this.editSelectedDesignsourceList.push(...this.designsourceList);
          }
          else
          {

                  for(var id=0;id<sel_desgn.length;id++)
                  {
                    var desgnindex=this.designsourceList.findIndex(eld=>eld.suppliergsdbcode.toLowerCase()==sel_desgn[id].toLowerCase());
                    if(desgnindex!=-1)
                    {
                      this.editSelectedDesignsourceList.push(this.designsourceList[desgnindex]);
                    }
                  }
                  }
            }



                  this.transactionService.getactiveplantslist(this.editData.region).subscribe(plantco=>{
                    this.editPlantList=[];

                    console.log("Plant List Reqauest access",plantco);

                    if(plantco != null && plantco.length != 0)
                    {
                    this.editPlantList=plantco;

                    console.log("plantcodes from backend",this.editPlantList);
                    this.editSelectedPlantList=[];

                      if( this.editData.plantcodes!=null && this.editData.plantcodes!="")
                      {
                      var sel_plants=[]
                      sel_plants=this.editData.plantcodes.split(',');

                      console.log("sel",sel_plants)

                      if(sel_plants.length==1 && sel_plants[0].toLowerCase()=="all")
                      {
                        this.editSelectedPlantList.push(...this.editPlantList);
                      }
                      else
                      {

                            for(var ip=0;ip<sel_plants.length;ip++)
                            {
                              var plantindex=this.editPlantList.findIndex(elp=>elp.plantGSDBCode.toLowerCase()==sel_plants[ip].toLowerCase());
                              if(plantindex!=-1)
                              {
                                this.editSelectedPlantList.push(this.editPlantList[plantindex]);
                              }
                            }
                            }
                      }


                  this.disableSaveEdit=false;
            console.log(this.supplierList);
            console.log(this.designsourceList);
            }

     })
    }
    })





  }




  saveEdit()
  {



    if(this.edit_enable_plantcode==true)
    {

    if(this.editSelectedPlantList.length!=0)
    {

      if(this.editSelectedPlantList.length==this.editPlantList.length)
      {
        this.editSelectedPlant="all"

      }
      else
      {
        this.editSelectedPlant="";
              var plt_count=0;
              for(var p_plt=0;p_plt<this.editSelectedPlantList.length;p_plt++)
              {
                 this.editSelectedPlant+=this.editSelectedPlantList[p_plt].plantGSDBCode
                 plt_count+=1;
                 if(plt_count!=this.editSelectedPlantList.length)
                 {
                   this.editSelectedPlant+=",";
                 }
              }

      }

    }
            else
              {
                 this.editSelectedPlant=""
                }
    }
    else
    {
      this.editSelectedPlant=""
    }



    if(this.edit_enable_supplier==true && this.edit_enable_bailment_supplier == false)
    {

    if(this.editSelectedSupplierList.length!=0)
    {

     /* if(this.editSelectedSupplierList.length==this.supplierList.length)
      {
        this.editSelectedSupplier="all"

      }
      else
      {*/
        this.editSelectedSupplier="";
              var sup_count=0;
              for(var p_plt=0;p_plt<this.editSelectedSupplierList.length;p_plt++)
              {
                 this.editSelectedSupplier+=this.editSelectedSupplierList[p_plt].suppliergsdbcode
                 sup_count+=1;
                 if(sup_count!=this.editSelectedSupplierList.length)
                 {
                   this.editSelectedSupplier+=",";
                 }
              }

    //  }

    }
            else
              {
                 this.editSelectedSupplier=""
                }
    }


    else if(this.edit_enable_supplier==false && this.edit_enable_bailment_supplier == true)
    {

    if(this.editSelectedBailmentSupplierList.length!=0)
    {

     /* if(this.editSelectedBailmentSupplierList.length==this.bailmentSupplierList.length)
      {
        this.editSelectedSupplier="all"

      }
      else
      {*/
        this.editSelectedSupplier="";
              var sup_count=0;
              for(var p_plt=0;p_plt<this.editSelectedBailmentSupplierList.length;p_plt++)
              {
                 this.editSelectedSupplier+=this.editSelectedBailmentSupplierList[p_plt].offsitesupplierid
                 sup_count+=1;
                 if(sup_count!=this.editSelectedBailmentSupplierList.length)
                 {
                   this.editSelectedSupplier+=",";
                 }
              }

    //  }

    }
            else
              {
                 this.editSelectedSupplier=""
                }
    }
    else
    {
      this.editSelectedSupplier=""
    }






    if(this.edit_enable_designsource==true)
    {

    if(this.editSelectedDesignsourceList.length!=0)
    {

    /*  if(this.editSelectedDesignsourceList.length==this.designsourceList.length)
      {
        this.editSelectedDesignsource="all"

      }
      else
      {*/
        this.editSelectedDesignsource="";
              var des_count=0;
              for(var p_plt=0;p_plt<this.editSelectedDesignsourceList.length;p_plt++)
              {
                 this.editSelectedDesignsource+=this.editSelectedDesignsourceList[p_plt].suppliergsdbcode
                 des_count+=1;
                 if(des_count!=this.editSelectedDesignsourceList.length)
                 {
                   this.editSelectedDesignsource+=",";
                 }
              }

     // }

    }
            else
              {
                 this.editSelectedDesignsource=""
                }
    }
    else
    {
      this.editSelectedDesignsource=""
    }



    console.log(this.editData.request_id);

    if(this.edit_enable_plantcode==true && this.edit_enable_supplier==false && this.edit_enable_bailment_supplier == false && this.edit_enable_designsource==false && this.editSelectedPlant.toLowerCase()!="all" )
        {




             var temp_req_grid=this.reqGrid;
           for(var re=0; re<temp_req_grid.length;re++ )
           {


            if(temp_req_grid[re].role_id==this.selectedRole.role_id && (temp_req_grid[re].designsourcecode.toLowerCase()==this.editSelectedDesignsource.toLowerCase()) &&
            (temp_req_grid[re].suppliercodes.toLowerCase()==this.editSelectedSupplier.toLowerCase())&&
            temp_req_grid[re].region.toLowerCase()==this.editSelectedRegionList.regioncode.toLowerCase() && temp_req_grid[re].request_id!=this.editData.request_id)
             {

              this.reqGrid.splice(re,1);
              console.log("plant",this.reqGrid)

             }

           }


           console.log("plant",this.reqGrid)





        }
        else if(this.edit_enable_supplier==true && this.edit_enable_bailment_supplier == false && this.edit_enable_plantcode==false && this.edit_enable_designsource==false && this.editSelectedSupplier.toLowerCase()!="all")
        {




             var temp_req_grid=this.reqGrid;
           for(var re=0; re<temp_req_grid.length;re++ )
           {


            if(temp_req_grid[re].role_id==this.selectedRole.role_id && (temp_req_grid[re].designsourcecode.toLowerCase()==this.editSelectedDesignsource.toLowerCase()) &&
            (temp_req_grid[re].plantcodes.toLowerCase()==this.editSelectedPlant.toLowerCase())&&
            temp_req_grid[re].region.toLowerCase()==this.editSelectedRegionList.regioncode.toLowerCase() && temp_req_grid[re].request_id!=this.editData.request_id)
             {

              this.reqGrid.splice(re,1);
              console.log("plant",this.reqGrid)

             }

           }


           console.log("plant",this.reqGrid)





        }

        else if(this.edit_enable_bailment_supplier == true && this.edit_enable_supplier==false  && this.edit_enable_plantcode==false && this.edit_enable_designsource==false && this.editSelectedSupplier.toLowerCase()!="all")
        {




             var temp_req_grid=this.reqGrid;
           for(var re=0; re<temp_req_grid.length;re++ )
           {


            if(temp_req_grid[re].role_id==this.selectedRole.role_id && (temp_req_grid[re].designsourcecode.toLowerCase()==this.editSelectedDesignsource.toLowerCase()) &&
            (temp_req_grid[re].plantcodes.toLowerCase()==this.editSelectedPlant.toLowerCase())&&
            temp_req_grid[re].region.toLowerCase()==this.editSelectedRegionList.regioncode.toLowerCase() && temp_req_grid[re].request_id!=this.editData.request_id)
             {

              this.reqGrid.splice(re,1);
              console.log("plant",this.reqGrid)

             }

           }


           console.log("plant",this.reqGrid)





        }

        else if(this.edit_enable_designsource==true && this.edit_enable_supplier==false && this.edit_enable_bailment_supplier == false && this.edit_enable_plantcode==false &&  this.editSelectedDesignsource.toLowerCase()!="all")
        {




             var temp_req_grid=this.reqGrid;
           for(var re=0; re<temp_req_grid.length;re++ )
           {


            if(temp_req_grid[re].role_id==this.editSelectedRole.role_id && (temp_req_grid[re].plantcodes.toLowerCase()==this.editSelectedPlant.toLowerCase()) &&
            (temp_req_grid[re].suppliercodes.toLowerCase()==this.editSelectedSupplier.toLowerCase())&&
            temp_req_grid[re].region.toLowerCase()==this.editSelectedRegionList.regioncode.toLowerCase() && temp_req_grid[re].request_id!=this.editData.request_id)
             {

              this.reqGrid.splice(re,1);
              console.log("plant",this.reqGrid)

             }

           }


           console.log("plant",this.reqGrid)





        }
        else if(this.edit_enable_supplier==true && this.edit_enable_plantcode==true && this.edit_enable_designsource==false && this.edit_enable_bailment_supplier == false &&  this.editSelectedPlant.toLowerCase()!="all" &&  this.editSelectedSupplier.toLowerCase()!="all")
        {




             var temp_req_grid=this.reqGrid;

             for(var re=0; re<temp_req_grid.length;re++ )
             {
            var reIndex=this.reqGrid.findIndex(elresp=>elresp.role_id==this.selectedRole.role_id && (elresp.designsourcecode.toLowerCase()==this.editSelectedDesignsource.toLowerCase()) &&
            (elresp.suppliercodes.toLowerCase()==this.editSelectedSupplier.toLowerCase())&&
            elresp.region.toLowerCase()==this.editSelectedRegionList.regioncode.toLowerCase() && temp_req_grid[re].request_id!=this.editData.request_id)


             if(reIndex!=-1)
             {
              this.reqGrid.splice(reIndex,1);
              console.log("plant",this.reqGrid)
             }

             else
             {

              var reIndexS=this.reqGrid.findIndex(elrespt=>elrespt.role_id==this.editSelectedRole.role_id && (elrespt.designsourcecode.toLowerCase()==this.editSelectedDesignsource.toLowerCase()) &&
            (elrespt.plantcodes.toLowerCase()==this.editSelectedPlant.toLowerCase())&&
            elrespt.region.toLowerCase()==this.editSelectedRegionList.regioncode.toLowerCase() && temp_req_grid[re].request_id!=this.editData.request_id)


            if(reIndexS!=-1)
            {
             this.reqGrid.splice(reIndexS,1);
             console.log("plant",this.reqGrid)
            }




             }
            }




           console.log("plant",this.reqGrid)





        }


        ////////all cases

       else if(this.editSelectedPlant.toLowerCase()=="all" && this.editSelectedSupplier.toLowerCase()!="all")
    {




         var temp_req_grid=this.reqGrid;
       for(var re=0; re<temp_req_grid.length;re++ )
       {


        if(temp_req_grid[re].role_id==this.editSelectedRole.role_id && (temp_req_grid[re].designsourcecode.toLowerCase()==this.editSelectedDesignsource.toLowerCase()) &&
        (temp_req_grid[re].suppliercodes.toLowerCase()==this.editSelectedSupplier.toLowerCase())&&
        temp_req_grid[re].region.toLowerCase()==this.editSelectedRegionList.regioncode.toLowerCase() && temp_req_grid[re].request_id!=this.editData.request_id)
         {

          this.reqGrid.splice(re,1);
          console.log("plant",this.reqGrid)

         }

       }


       console.log("plant",this.reqGrid)





    }


    else  if(this.editSelectedPlant.toLowerCase()!="all" && this.editSelectedSupplier.toLowerCase()=="all")
    {


      var temp_req_grid=this.reqGrid;
      for(var re=0; re< temp_req_grid.length;re++ )
      {


        if(temp_req_grid[re].role_id==this.editSelectedRole.role_id && (temp_req_grid[re].designsourcecode.toLowerCase()==this.editSelectedDesignsource.toLowerCase()) &&
        (temp_req_grid[re].plantcodes.toLowerCase()==this.editSelectedPlant.toLowerCase())&&
        temp_req_grid[re].region.toLowerCase()==this.editSelectedRegionList.regioncode.toLowerCase() && temp_req_grid[re].request_id!=this.editData.request_id)
        {

         this.reqGrid.splice(re,1);

        }

      }


    }


    else  if(this.editSelectedPlant.toLowerCase()=="all" && this.editSelectedSupplier.toLowerCase()=="all")
    {

      var temp_req_grid=this.reqGrid;
      for(var re=0; re< temp_req_grid.length;re++ )
      {

        if(temp_req_grid[re].role_id==this.editSelectedRole.role_id && (temp_req_grid[re].designsourcecode.toLowerCase()==this.editSelectedDesignsource.toLowerCase()) &&
        temp_req_grid[re].region.toLowerCase()==this.editSelectedRegionList.regioncode.toLowerCase() && temp_req_grid[re].request_id!=this.editData.request_id)
        {

         this.reqGrid.splice(re,1);

        }

      }


    }

    else  if(this.editSelectedDesignsource.toLowerCase()=="all")
    {

      var temp_req_grid=this.reqGrid;
      for(var re=0; re< temp_req_grid.length;re++ )
      {


        if(temp_req_grid[re].role_id==this.editSelectedRole.role_id && (temp_req_grid[re].plantcodes.toLowerCase()==this.editSelectedPlant.toLowerCase()) &&
        (temp_req_grid[re].suppliercodes.toLowerCase()==this.editSelectedSupplier.toLowerCase())&&
        temp_req_grid[re].region.toLowerCase()==this.editSelectedRegionList.regioncode.toLowerCase() && temp_req_grid[re].request_id!=this.editData.request_id)
        {

         this.reqGrid.splice(re,1);

        }

      }


    }

 /*
    var getIndex=this.reqGrid.findIndex(elreget=>elreget.role_id==this.editSelectedRole.role_id && (elreget.designsourcecode.toLowerCase()==this.editSelectedDesignsource.toLowerCase()) &&
    (elreget.suppliercodes.toLowerCase()==this.editSelectedSupplier.toLowerCase())&& (elreget.plantcodes.toLowerCase()==this.editSelectedPlant.toLowerCase())&&
    elreget.region.toLowerCase()==this.editSelectedRegionList.regioncode.toLowerCase() && temp_req_grid[re].request_id!=this.editData.request_id)


    if(getIndex!=-1)
    {

      this.reqGrid.splice(getIndex,1);

    }
  }*/

    var indexGrid=this.reqGrid.findIndex(elg=>elg.request_id==this.editData.request_id)


    if(indexGrid!=-1)
    {
      this.reqGrid[indexGrid].plantcodes=this.editSelectedPlant;
      this.reqGrid[indexGrid].designsourcecode=this.editSelectedDesignsource;
      this.reqGrid[indexGrid].suppliercodes=this.editSelectedSupplier;



   this.enableEditDialog=false;


    }

    else
    {
      //try again
    }


    if(this.reqGrid.length>=1)
    {
      this.buttonName="Add Another Request"
    }





  }


  cancelEditDialog()
  {
    this.enableEditDialog=false;


  }







  isDisbaleSaveEdit():boolean{


    var edit_disable_plant_button=false;
    var edit_disable_supplier_button=false;
    var edit_disable_designsource_button=false;

    if(this.edit_enable_plantcode==true && this.editSelectedPlantList.length==0)
    {
      edit_disable_plant_button=true

    }
    else
    {
      edit_disable_plant_button=false;
    }



    if(this.edit_enable_supplier==true && this.editSelectedSupplierList.length==0)
    {
      edit_disable_supplier_button=true

    }
    else
    {
      edit_disable_supplier_button=false;
    }

    if(this.edit_enable_designsource==true && this.editSelectedDesignsourceList.length==0)
    {
      edit_disable_designsource_button=true

    }
    else
    {
      edit_disable_designsource_button=false;
    }

    return (edit_disable_plant_button|| edit_disable_supplier_button|| edit_disable_designsource_button)


  }


  removeRequest()
  {


    console.log(this.selectedRequestsRemoved);
    var temp_reqGrid:requestGrid[]=[];

    if(this.selectedRequestsRemoved.length!=0)
    {
      for(var rem of this.reqGrid)
      {
        var remindex=this.selectedRequestsRemoved.findIndex(elrem=>elrem.request_id==rem.request_id)
        if(remindex==-1)
        {
          temp_reqGrid.push(rem);

        }

      }
      console.log(temp_reqGrid);

      this.selectedRequestsRemoved=[];

      this.reqGrid=temp_reqGrid;


    }

  }


  isDisableRemove():boolean
  {

    if(this.selectedRequestsRemoved.length!=0)
    {
      return false
    }
    else
    {
      return true
    }


  }




  submitRequest()
  {

    //console.log("submitRequest")

    this.disableSubmitReqButton=true;
    var cur_date;
    var cur_date_transform
    cur_date=new Date();
    cur_date_transform=this.date.transform(cur_date, 'yyyy-MM-dd HH:mm:ss');


    console.log(this.reqGrid);
    this.userRequestSubmit=[];

    this.userRequestDetialsEmail=[];

    for(var sub of this.reqGrid)
    {
      this.userRequestSubmit.push({
        reqno:0,
        gppuserid:this.userId,
        user_name:this.userInfoGet.user_name,
        name_prefix:this.selectedPrefix.prefix,
        cmpny_name:this.userInfoGet.cmpny_name,
        desig:this.userInfoGet.desig,
        phone:this.userInfoGet.phone,
        fax:this.userInfoGet.fax,
        email:this.userInfoGet.email,
        address1:this.userInfoGet.address1,
        address2:this.userInfoGet.address2,
        city:this.userInfoGet.city,
        state:this.userInfoGet.state,
        postcode:this.userInfoGet.postcode,
        country:this.selectedCountry.country_name,
        reqstd_access:sub.role_id.toString(),
        req_supgsdbcodes:sub.suppliercodes,
        req_dsgnsrcids:sub.designsourcecode,
        req_plantgsdbcodes:sub.plantcodes,
        region:sub.region,
        user_type:this.userType,
        req_type:this.userAccess,
        req_date:cur_date_transform
      })

      this.userRequestDetialsEmail.push(
        {
          region:sub.region,
          roleRequested:sub.role_desc,
          supGSDBcodes:sub.suppliercodes,
          designSourceIDs:sub.designsourcecode,
          plantCodes:sub.plantcodes,
          programs:null,
          cos:null,
          comments:"Your application access request has been submitted for approval once approved you will get notification."
        }
      )

    }


    console.log(this.userRequestSubmit);

    this.nameValidation();
    this.companyNameValidation();
    this.roleValidation();
    this.phoneValidation();
    this.faxValidation();
    this.emailValidation();
    this.addressValidation();
    this.address2Validation();
    this.cityValidation();
    this.stateValidation();
    this.postalCodeValidation();
    this.countryValidation();
    this.checkboxValidation();
    this.requestPresentValidation();


    if(!this.name_InValid && !this.companyName_InValid && !this.role_InValid && !this.phone_InValid && !this.email_InValid &&
      !this.address_InValid && !this.city_InValid && !this.state_InValid && !this.postalCode_InValid && !this.country_InValid &&
      !this.checkboxInvalid && !this.requestNotPresent)
      {
      this.transactionService.submitUserRequest(this.userRequestSubmit).subscribe(subResult=>{
        console.log(subResult);
        if(subResult.toLowerCase()=="success")
        {


        this.messageService.add({severity:'success', summary: 'success', detail: 'Request submitted successfully'});



        this.enableNotificationDialog=true;
        this.disableSubmitReqButton=false;
        this.userInfoInitValidation();

        if(this.isValidsds==false)
        {
         

          this.notificationText="Your request has been Submitted successfully, Please close the tab and login again after you got access"
        }

        else
        {
          this.notificationText="Your request has been Submitted successfully"
        }


        this.reqGrid=[];
        this.buttonName="Add Request"
        this.selectedRole={ role_cd:0,role_id:0,role_name:"",cd_region:"",createddt:"",createdby:"",role_desc:"",enable_designsource:false,enable_plantcode:false,enable_supplier:false,enable_bailment_supplier:false}
        this.enable_supplier=false;
        this.enable_designsource=false;
        this.enable_plantcode=false;
        this.selectedSupplierList=[];
        this.selectedPlantList=[];
        this.selectedBailmentSupplierList=[];
        this.selectedDesignsourceList=[];
        this.acceptTermsConditions=false;

        // email trigger request submitted successfully

        const func_id = 1 // for request submitted the fuction id is 1
        this.userDetailsForEmailPost.userRequestAccessList=[];

        this.userDetailsForEmailPost={
          gppuserid:this.userId,
          function:func_id,
          userRequestAccessList:this.userRequestDetialsEmail
        }

        console.log("Email request details - userRequestEmailTrigger before API call",this.userDetailsForEmailPost)

        this.transactionService.userRequestEmailTrigger(this.userDetailsForEmailPost).subscribe(emailTriggerStatus=>{

          console.log("Email request details email triger - userRequestEmailTrigger API o/p ", emailTriggerStatus)

          if(emailTriggerStatus.toLowerCase() == "success")
          {
            this.messageService.add({severity:'success', summary: 'success', detail: 'Email sent to the user successfully'});
          }
          else
          {
            this.messageService.add({severity:'error', summary: 'Error', detail: 'Email not sent to the user'});
          }
        })


      }


      
      else
      {

        this.disableSubmitReqButton=false;
        this.messageService.add({severity:'error', summary: 'Error', detail: 'Request not submitted, Try again'});
        this.userInfoInitValidation();

        }
      })
      }
  }


  isDisableSubmitRequestAccess()
  {

    if(this.userId==null || this.userId==""|| this.selectedPrefix.prefix==""||  this.userInfoGet.user_name==null || this.userInfoGet.user_name=="" || this.userInfoGet.cmpny_name == null || this.userInfoGet.cmpny_name == "" ||
      this.userInfoGet.desig==null ||this.userInfoGet.desig=="" ||this.userInfoGet.phone== null ||this.userInfoGet.phone== "" || this.userInfoGet.email==null ||this.userInfoGet.email=="" ||
      this.userInfoGet.address1==null ||this.userInfoGet.address1=="" || this.userInfoGet.city==null || this.userInfoGet.city=="" ||
      this.userInfoGet.state==null || this.userInfoGet.state=="" || this.userInfoGet.postcode==null || this.userInfoGet.postcode==""||
      this.selectedCountry.country_name=="" || this.reqGrid.length==0 || this.acceptTermsConditions==false)

      {
        return true;
      }

      else
      {
        return false;
      }

  }

  onPaste(event):void
  {
    event.preventDefault();

    const text = event.clipboardData?.getData('text/plain') || '';
    if(/^[A-Za-z .-]+$/.test(text))
    {
      this.userInfoGet.user_name=text;
    }
    else
    {
      console.log(text);
      this.userInfoGet.user_name=text.replace(/[^a-zA-Z.-\s]/g,'');
      console.log(this.userInfoGet.user_name);
    }
    
  }


  okEnbaleNotification()
  {


    this.enableNotificationDialog=false;

  }


  nameValidation()
  {
    console.log("name validation",String(this.userInfoGet.user_name).length )

   if(this.userInfoGet.user_name == null || this.userInfoGet.user_name.trim() == "")
   {
    this.name_InValid = true;
    this.name_InValidReason = "Required";
   }
   else if(String(this.userInfoGet.user_name).length <= 0 || String(this.userInfoGet.user_name).length > 49)
   {
    this.name_InValid = true;
    this.name_InValidReason = "Name can not exceed 49 characters";
   }
   else
   {
    this.name_InValid = false;
    this.name_InValidReason = null;
   }
  }
  companyNameValidation()
  {
    console.log("company validation",String(this.userInfoGet.cmpny_name).length )

   if(this.userInfoGet.cmpny_name == null || this.userInfoGet.cmpny_name.trim() == "")
   {
    this.companyName_InValid = true;
    this.companyName_InValidReason = "Required";
   }
   else if(String(this.userInfoGet.user_name).length <= 0 || String(this.userInfoGet.user_name).length > 49)
   {
    this.companyName_InValid = true;
    this.companyName_InValidReason = "Company Name can not exceed 49 characters";
   }
   else
   {
    this.companyName_InValid = false;
    this.companyName_InValidReason = null;
   }
  }
  roleValidation()
  {
   if(this.userInfoGet.desig == null || this.userInfoGet.desig.trim() == "")
   {
    this.role_InValid = true;
    this.role_InValidReason = "Required";
   }
   else if(String(this.userInfoGet.desig).length <= 0 || String(this.userInfoGet.desig).length > 24)
   {
    this.role_InValid = true;
    this.role_InValidReason = "Role can not exceed 24 characters";
   }
   else
   {
    this.role_InValid = false;
    this.role_InValidReason = null;
   }
  }
  phoneValidation()
  {
    console.log("Phone validation",String(this.userInfoGet.phone).length )
   if(this.userInfoGet.phone == null || this.userInfoGet.phone.trim() == "")
   {
    this.phone_InValid = true;
    this.phone_InValidReason = "Required";
   }
   else if(String(this.userInfoGet.phone).length <= 0 || String(this.userInfoGet.phone).length > 99)
   {
    this.phone_InValid = true;
    this.phone_InValidReason = "Phone Number can not exceed 99 characters";
   }
   else
   {
    this.phone_InValid = false;
    this.phone_InValidReason = null;
   }
  }
  faxValidation()
  {

   if(String(this.userInfoGet.fax).length > 19) {
    this.fax_InValid = true;
    this.fax_InValidReason = "Fax can not exceed 19 characters";
    } else if(!this.faxNumberValidation(this.userInfoGet.fax)) {
    this.fax_InValid = true;
    this.fax_InValidReason = "Not a valid fax number";
    } else {
    this.fax_InValid = false;
    this.fax_InValidReason = null;
   }

  }

  faxNumberValidation(faxNum) {
    return String(faxNum).toLowerCase().match(/^\+?[0-9]{6,}$/);
  }

  emailValidation()
  {
   if(this.userInfoGet.email == null || this.userInfoGet.email.trim() == "")
   {
    this.email_InValid = true;
    this.email_InValidReason = "Required";
   }
   else if(String(this.userInfoGet.email).length <= 0 || String(this.userInfoGet.email).length > 49)
   {
    this.email_InValid = true;
    this.email_InValidReason = "Email ID can not exceed 49 characters";
   }
   else
   {
    this.email_InValid = false;
    this.email_InValidReason = null;
   }
  }
  addressValidation()
  {
   if(this.userInfoGet.address1 == null || this.userInfoGet.address1.trim() == "")
   {
    this.address_InValid = true;
    this.address_InValidReason = "Required";
   }
   else if(String(this.userInfoGet.address1).length <= 0 || String(this.userInfoGet.address1).length > 99)
   {
    this.address_InValid = true;
    this.address_InValidReason = "Address can not exceed 99 characters";
   }
   else
   {
    this.address_InValid = false;
    this.address_InValidReason = null;
   }
  }
  address2Validation()
  {
   if(String(this.userInfoGet.address2).length > 99)
   {
    this.address2_InValid = true;
    this.address2_InValidReason = "Address can not exceed 99 characters";
   }
   else
   {
    this.address2_InValid = false;
    this.address2_InValidReason = null;
   }
  }
  cityValidation()
  {
   if(this.userInfoGet.city == null || this.userInfoGet.city.trim() == "")
   {
    this.city_InValid = true;
    this.city_InValidReason = "Required";
   }
   else if(String(this.userInfoGet.city).length <= 0 || String(this.userInfoGet.city).length > 29)
   {
    this.city_InValid = true;
    this.city_InValidReason = "City can not exceed 29 characters";
   }
   else
   {
    this.city_InValid = false;
    this.city_InValidReason = null;
   }
  }
  stateValidation()
  {
   if(this.userInfoGet.state == null || this.userInfoGet.state.trim() == "")
   {
    this.state_InValid = true;
    this.state_InValidReason = "Required";
   }
   else if(String(this.userInfoGet.state).length <= 0 || String(this.userInfoGet.state).length > 29)
   {
    this.state_InValid = true;
    this.state_InValidReason = "State can not exceed 29 characters";
   }
   else
   {
    this.state_InValid = false;
    this.state_InValidReason = null;
   }
  }
  postalCodeValidation()
  {
   if(this.userInfoGet.postcode == null || this.userInfoGet.postcode.trim() == "")
   {
    this.postalCode_InValid = true;
    this.postalCode_InValidReason = "Required";
   }
   else if(String(this.userInfoGet.postcode).length <= 0 || String(this.userInfoGet.postcode).length > 24)
   {
    this.postalCode_InValid = true;
    this.postalCode_InValidReason = "Postal Code can not exceed 24 characters";
   }
   else
   {
    this.postalCode_InValid = false;
    this.postalCode_InValidReason = null;
   }
  }
  countryValidation()
  {
   if(this.selectedCountry == null || this.selectedCountry.country_name == null || this.selectedCountry.country_name.trim() == "")
   {
    this.country_InValid = true;
    this.country_InValidReason = "Required";
   }
   else
   {
    this.country_InValid = false;
    this.country_InValidReason = null;
   }
  }

  checkboxValidation()
  {
    if(this.acceptTermsConditions == null || this.acceptTermsConditions == false)
    {
      this.checkboxInvalid = true;
      this.checkboxInvalidReason = "Required to check the checkbox";
    }
    else
    {
      this.checkboxInvalid = false;
      this.checkboxInvalidReason = null;
    }
  }

  requestPresentValidation()
  {
    if(this.reqGrid == null || this.reqGrid.length == 0 )
    {
      this.requestNotPresent = true;
      this.requestNotPresentReason = "Atleast one request need to be added to submit request"
    }
    else
    {
      this.requestNotPresent = false;
      this.requestNotPresentReason = null
    }
  }


}
