import {Component, OnInit,Injectable} from '@angular/core';


import {TransactionService} from '../loginpage/Transaction.service';


import { DatePipe } from '@angular/common';


import { Router,Resolve,ActivatedRouteSnapshot } from '@angular/router';


import {fedebomfilters,dropdownlist,putuserfiltervalues,fedebomfiltervalues} from '../loginpage/transactions';
import { Observable } from 'rxjs';




/** Component that has no functionality other than plain html text in the template */



@Injectable()
export class ExamplePageResolve implements Resolve<fedebomfilters> {




	constructor(private transactionService: TransactionService, private date:DatePipe,
		private router: Router) {
	}


	resolve(route:ActivatedRouteSnapshot):Observable<fedebomfilters>{

		return this.transactionService.getallfilters('FEDEBOM_MASTER');
	}

}
