/* tslint:disable */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TransactionService } from '../loginpage/Transaction.service';
import { sign } from 'crypto';

export interface linksTemplate
{
  linkurl:string;
  name:string;
}

@Component({
  selector: 'app-traininglinks',
  templateUrl: './traininglinks.component.html',
  styleUrls: ['./traininglinks.component.css']
})
export class TraininglinksComponent implements OnInit {

  europeTrainingLinksList:linksTemplate[]=[];
  asiaPacificTrainingLinksList:linksTemplate[]=[];
  trainingOrCertificationLinks:linksTemplate[]=[];
  base_url_get_training_links:String='';
  base_path_training_links='';
  base_path_training_video_link='';

  supplierSubmissionTrainingSignedLink='';
  containerComponenntRequestTrainingSignedLink='';

  showLoader: boolean;

  constructor(private transactionService:TransactionService, private router:Router) { }

  ngOnInit(): void {
    this.showLoader = true;
    this.base_url_get_training_links = environment.nfsBaseAPIUrl+'/downloadNonImgFile?fPath=';
    //this.base_path_training_links = environment.nfsBasePath+'training/';
    this.base_path_training_links ='training/';
    this.base_path_training_video_link=environment.nfsBaseAPIUrl+'/downloadVideoFile?fPath=';

    this.supplierSubmissionTrainingSignedLink=environment.nfsBaseAPIUrl+'/getSignedUrl?fPath=training/NGGPP_Supplier_Submission_Training.mp4';
    this.containerComponenntRequestTrainingSignedLink=environment.nfsBaseAPIUrl+'/getSignedUrl?fPath=training/Supplier_Container_Component_Request_Form_Training.mp4'

    this.setEuropeTrainingLinksList();
    this.setAsiaPacificTrainingLinksList();
    this.trainingOrCertificationLinks=[{
      linkurl:'https://efds.fa.em5.oraclecloud.com:443/fscmUI/redwood/learner/learn/redirect?learningItemId=300003544452895&learningItemType=ORA_CLASS',
      name:'NextGen-GPP Supplier Packaging Submission Training'
    }]
  }

  setEuropeTrainingLinksList() {

    this.europeTrainingLinksList=[];
    const url1 = this.base_url_get_training_links+this.base_path_training_links+'NextGenGPP_User_Guide_for_Suppliers_Shipping_to_Europe.pdf';
    this.transactionService.getPdfFile(url1).subscribe((res) => {
      const url = URL.createObjectURL(res);
      const obj1 = {
          linkurl: url,
          name:'NextGenGPP User Guide for Suppliers Shipping to Europe'
      }
      this.europeTrainingLinksList.push(obj1);
      const video: any = document.querySelector('#guideSSE');
      video.href = url;
    });

    const url2 = this.base_url_get_training_links+this.base_path_training_links+'NextGenGPP_User_Guide_for_Ford_Viewers_and_Approvers.pdf';
    this.transactionService.getPdfFile(url2).subscribe((res) => {
      const url = URL.createObjectURL(res);
      const obj1 = {
        linkurl:url,
        name:'NextGenGPP User Guide for Ford Viewers and Approvers (Europe)'
      }
      this.europeTrainingLinksList.push(obj1);
      const video: any = document.querySelector('#guideAPE');
      video.href = url;
    });

    const url3 = this.base_url_get_training_links+this.base_path_training_links+'NGGPP-Submission-Directions-China-Region.pdf';
    this.transactionService.getPdfFile(url3).subscribe((res) => {
      const url = URL.createObjectURL(res);
      const obj1 = {
        linkurl: url,
        name:'NGGPP Submission Directions (China)'
      }
      this.europeTrainingLinksList.push(obj1);
      const video: any = document.querySelector('#guideSDC');
      video.href = url;
    });

  }

  setAsiaPacificTrainingLinksList() {
    this.asiaPacificTrainingLinksList=[];
    //const url1 = this.base_path_training_video_link+this.base_path_training_links+'NGGPP_Supplier_Submission_Training.mp4';
    //const url1="https://storage.cloud.google.com/nggpp_storage_bucket/training/NGGPP_Supplier_Submission_Training.mp4"
  
    this.transactionService.getVideoSignedUrl(this.supplierSubmissionTrainingSignedLink).subscribe((res) => {
      if(res!=null)
      {
        var signedURL1=res.signedUrl;
        //const url = URL.createObjectURL(signedURL1);
        const obj1 = {
          linkurl: signedURL1,
          name:'NGGPP Supplier Submission Training'
        };
        const video: any = document.querySelector('#supSubTraining');
        video.src = signedURL1;
        this.asiaPacificTrainingLinksList.push(obj1);
      }
      
    });

    //const url2 = this.base_path_training_video_link+this.base_path_training_links+'Supplier_Container_Component_Request_Form_Training.mp4';
    //const url2="https://storage.cloud.google.com/nggpp_storage_bucket/training/Supplier_Container_Component_Request_Form_Training.mp4"
    
    this.transactionService.getVideoSignedUrl(this.containerComponenntRequestTrainingSignedLink).subscribe((res) => {
      
      if(res!=null)
      {
        var signedURL2=res.signedUrl;
        //const url = URL.createObjectURL(signedURL2);
        const obj2 = {
          linkurl: signedURL2,
          name:'Supplier Container Component Request Form Training'
        }
        this.asiaPacificTrainingLinksList.push(obj2);
        const video: any = document.querySelector('#supConTraining');
        video.src = signedURL2;
        this.showLoader = false;
      }
      
      
    });
  }


  onBackOrCloseTrainingLinks()
  {


    const cur_region=this.transactionService.getregion();
    if(cur_region != null )
    {
      let reg_map_url='';
      reg_map_url=this.transactionService.getregionurlvalue(cur_region);
      reg_map_url+='packagingprocessdashboard/packagingprocesshome';

      this.router.navigate([reg_map_url]);

    }

    else
    {
      this.router.navigate(['/exitpage']);
    }
  }

}
