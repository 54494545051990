/* tslint:disable */
import { Component, InjectFlags, OnInit } from '@angular/core';
import {TransactionService} from '../loginpage/Transaction.service';
import { Router,ActivatedRoute } from '@angular/router';




import { DatePipe } from '@angular/common';



import { ConfirmationService } from 'primeng/api';
import {regiondetails, list_email_templates,countryreglist, upd_countryreglist,currencyreglist,colorreglist,upd_currencyreglist,upd_colorreglist,add_colorreglist,languagereglist,upd_languagereglist,email, email_templates,email_templates_upd, addLanguage, addCurrencyType, addCountryRegList} from '../loginpage/transactions';
import { MessageService } from 'primeng/api';
import { elementAt } from 'rxjs/operators';


@Component({
  selector: 'app-regionadmin',
  templateUrl: './regionadmin.component.html',
  styleUrls: ['./regionadmin.component.css'],
  providers: [ConfirmationService,MessageService,DatePipe]

})
export class RegionadminComponent implements OnInit {


  regionDetails:regiondetails={
    cd_region: "",
    cntr_ergo_limit: 0,
    bom_err_thld: 0,
    allow_nonstd_comp_ind:"" ,
    urge_prt_notice_email_freq: 0,
    allow_multi_prt_prop_ind: "",
    limit_prts_recvd_plt: "No",
    emg_exp_pkg_reqd_ind: "",
    pkg_rel_level_src: "",
    suspd_duratn_thld: 0,
    uom: "E"


    }


    emailTemplates : list_email_templates={


      newparts:"",
      urgentparts:"",
      containerbasechg:"",
      addapprover:"",
      proposalrej:""

    }

    newPartsMail:email_templates={	cd_region: "",
      email_templ_name: "",
      email_templ_desc: "",
      updated_by: "",
      updated_dt: "",
      email_content: ""};

    urgentPartsMail:email_templates={	cd_region: "",
    email_templ_name: "",
    email_templ_desc: "",
    updated_by: "",
    updated_dt: "",
    email_content: ""};

    containerBaseChgMail:email_templates={	cd_region: "",
    email_templ_name: "",
    email_templ_desc: "",
    updated_by: "",
    updated_dt: "",
    email_content: ""};

    addApproverMail:email_templates={	cd_region: "",
    email_templ_name: "",
    email_templ_desc: "",
    updated_by: "",
    updated_dt: "",
    email_content: ""};

    proposalRejMail:email_templates={	cd_region: "",
    email_templ_name: "",
    email_templ_desc: "",
    updated_by: "",
    updated_dt: "",
    email_content: ""};




    newPartsMailUpd:email_templates_upd[]=[];

    urgentPartsMailUpd:email_templates_upd[]=[];

    containerBaseChgMailUpd:email_templates_upd[]=[];

    addApproverMailUpd:email_templates_upd[]=[];

    proposalRejMailUpd:email_templates_upd[]=[];




   newPartsMailTemp:string="";
   urgentPartsMailTemp:string="";
   containerBaseChgMailTemp:string="";
   addApproverMailTemp:string="";
   proposalRejMailTemp:string="";


   newPartsEnableDialog:boolean=false;
   urgentPartsEnableDialog:boolean=false;
   containerBaseChgEnableDialog:boolean=false;
   addApproverEnableDialog:boolean=false;
   proposalRejEnableDialog:boolean=false;


   emailName: any[];

    selectedeMail: email={name:"",code:""};

    changeValue:string="";

    country:countryreglist[]=[];
    oldCountry:countryreglist[]=[];
    updNewCountry:upd_countryreglist[]=[];

    countryAddButtonEnable:boolean=true;
    countryAddDetailsEnable:boolean=false;
    countryAdd:addCountryRegList={cd_country:"",cd_country_iso:"",cd_region:"",country_name:"",active_stat:"",updated_dt:"",updated_by:""}

    editCountryDetailsEnable:boolean=false;
    editCountryDetails:upd_countryreglist={cd_country:"",cd_region:"",country_name:"",active_stat:"",cd_country_iso:"",updated_dt:"",updated_by:""}
    //editCountryDetails:addCountryRegList={cd_country:"",cd_country_iso:"",cd_region:"",country_name:"",active_stat:"",updated_dt:"",updated_by:""};

    language:languagereglist[]=[];
    oldLanguage:languagereglist[]=[];
    updNewLanguage:upd_languagereglist[]=[];

    languageAddButtonEnable:boolean=true;
    languageAddDetailsEnable:boolean=false;
    languageAdd:addLanguage={updated_dt:"",updated_by:"",lang_desc:"",cd_lang:"",cd_region:"",active_stat:""}

    editLanguageDetailsEnable:boolean=false;
    editLanguageDetails:upd_languagereglist={lang_desc:"",cd_lang:"",cd_region:"",active_stat:"",updated_by:""}


    currency:currencyreglist[]=[];
    oldCurrency:currencyreglist[]=[];
    updNewCurrency:upd_currencyreglist[]=[];

    editCurrencyDetailsEnable:boolean=false;
    editCurrencyDetails:upd_currencyreglist={cd_curr:"",curr_desc:"",updated_by:"",active_stat:""};
    showAddCurrencyButton:boolean=true;
    showAddCurrency:boolean=false;
    addNewCurrency:addCurrencyType={curr_desc:"",updated_by:"",updated_dt:"",active_stat:""};



    color:colorreglist[]=[];
    oldColor:colorreglist[]=[];
    updNewColor:upd_colorreglist[]=[];
    addNewColor:add_colorreglist={color_desc:"",active_stat:"",cd_region:"",cd_color:0,updated_by:""}
    showAddColor:boolean=false;
    editColorDetailsEnable:boolean=false;
    editColorDetails:upd_colorreglist={cd_color:0,cd_region:"",color_desc:"",active_stat:"",updated_by:""}


    public countryCols:any[];
    public languageCols:any[];
    public currencyCols:any[];
    public colorCols:any[];


    showCountry:boolean=false;
    showLanguage:boolean=false;
    showCurrency:boolean=false;
    showColor:boolean=false;


    currentRegionAdmin:string;
    currentRegionCodeAdmin:string;
    regCdsid:string;
    showAddColorButton:boolean=true;


    currentRouteid:number=0;
    rolesAndFunctionalities:any[]=[];
    enableCountryRadio:boolean=false;
    enableLangRadio:boolean=false;
    enableCurrRadio:boolean=false;
    enableColorRadio:boolean=false;

    activeStatusFilterCountry=[{label:'All',value:null},{label:'Active',value:true},{label:'In-Active',value:false}]
  activeStatusFilterLanguage=[{label:'All',value:null},{label:'Active',value:true},{label:'In-Active',value:false}]
  activeStatusFilterCurrency=[{label:'All',value:null},{label:'Active',value:true},{label:'In-Active',value:false}]
  activeStatusFilterColor=[{label:'All',value:null},{label:'Active',value:true},{label:'In-Active',value:false}]


  contErgLimitInValid:boolean=false;
  contErgLimitInValidReason:string=null;
  sendUrgPartNotifyEmailEveryInValid:boolean=false;
  sendUrgPartNotifyEmailEveryInValidReason:string=null;
  emailTempBodyNewPartsInValid:boolean=false;
  emailTempBodyNewPartsInValidReason:string=null;
  emailTempBodyUrgentPartsInValid:boolean=false;
  emailTempBodyUrgentPartsInValidReason:string=null;
  emailTempBodyContrBaseChngInValid:boolean=false;
  emailTempBodyContrBaseChngInValidReason:string=null;
  emailTempBodyAddApproverInValid:boolean=false;
  emailTempBodyAddApproverInValidReason:string=null;
  emailTempBodyPckPropRejInValid:boolean=false;
  emailTempBodyPckPropRejInValidReason:string=null;


  unitValue="Lbs/in"





  constructor(private transactionService: TransactionService,private router:Router,private route: ActivatedRoute,private confirmationService: ConfirmationService,private messageService: MessageService,private date:DatePipe) { }

  ngOnInit(): void {
    this.emailName = [
      {name: 'E-mail Template for New Parts Awaiting Packaging Proposal', code: '1'},
      {name: 'E-mail Template for Urgent Parts Awaiting Packaging Proposal', code: '2'},
      {name: 'E-mail Template for Container Base Changed E-mail Template', code: '3'},
      {name: 'E-mail Template for Additional Approver', code: '4'},
      {name: 'E-mail Template for Packaging Proposal Rejected', code: '5'}
  ];




  this.regCdsid=this.transactionService.getcdsid();
  this.currentRegionAdmin=this.transactionService.getregion();
this.transactionService.storepage(this.currentRegionAdmin,"packagingprocess/packadmin/regionadmin");
this.currentRegionCodeAdmin=this.transactionService.getregioncode(this.currentRegionAdmin);
console.log(this.currentRegionCodeAdmin);



this.currentRouteid=105;

console.log(this.currentRouteid)


if(this.currentRegionAdmin=="Asia Pacific & Africa")
{
  sessionStorage.setItem('aprouteid', JSON.stringify(this.currentRouteid));
}

else if(this.currentRegionAdmin=="Europe")
{
  sessionStorage.setItem('eurouteid', JSON.stringify(this.currentRouteid));
}

else if (this.currentRegionAdmin=="North America")

{

  sessionStorage.setItem('narouteid', JSON.stringify(this.currentRouteid));
}

else if(this.currentRegionAdmin=="South America")
{
  sessionStorage.setItem('sarouteid', JSON.stringify(this.currentRouteid));
}




  //this.selectedemail.push({name:"E-mail Template for New Parts Awaiting Packaging Proposal",code:"1"})
  this.selectedeMail.code="1"

  this.displayEmail()



this.getregionDetails();
/*this.changeValue="country"
this.changeOfType();*/
this.rolesAndFunctionalities=JSON.parse(sessionStorage.getItem(this.currentRegionCodeAdmin+'useraccess'));
this.enableRadioButtons();


//this.isEnableEditAccess()

  }

  regionAdminInputValidationInit(){
    this.contErgLimitInValid = false;
    this.contErgLimitInValidReason = null;
    this.sendUrgPartNotifyEmailEveryInValid = false;
    this.sendUrgPartNotifyEmailEveryInValidReason = null;
    this.emailTempBodyNewPartsInValid = false;
    this.emailTempBodyNewPartsInValidReason = null;
    this.emailTempBodyUrgentPartsInValid = false;
    this.emailTempBodyUrgentPartsInValidReason = null;
    this.emailTempBodyContrBaseChngInValid = false;
    this.emailTempBodyContrBaseChngInValidReason = null;
    this.emailTempBodyAddApproverInValid = false;
    this.emailTempBodyAddApproverInValidReason = null;
    this.emailTempBodyPckPropRejInValid = false;
    this.emailTempBodyPckPropRejInValidReason = null;
  }



  regionDetailsInit()
  {
    this.regionDetails={
      cd_region: "",
      cntr_ergo_limit: 0,
      bom_err_thld: 0,
      allow_nonstd_comp_ind:"" ,
      urge_prt_notice_email_freq: 0,
      allow_multi_prt_prop_ind: "",
      limit_prts_recvd_plt: "",
      emg_exp_pkg_reqd_ind: "",
      pkg_rel_level_src: "",
      suspd_duratn_thld: 0,
      uom:"E"
    }
    this.unitValue="Lbs/in"
  }
  changeUnitValue()
  {
    if(this.regionDetails.uom != null && this.regionDetails.uom.trim() != "" && this.regionDetails.uom != "E")
    {
      this.unitValue="Kg/cm"
    }
    else
    {
      this.unitValue="Lbs/in"
      this.regionDetails.uom = "E";

    }
    this.changeValueOnUOM()
  }

  enableRadioButtons()
  {
    if(this.rolesAndFunctionalities!=null && this.rolesAndFunctionalities.length!=0)
    {
      var countryInd=this.rolesAndFunctionalities.some(e=>e.roles.some(ele=>ele.sysfunc_id==47));
      var colorInd=this.rolesAndFunctionalities.some(e=>e.roles.some(ele=>ele.sysfunc_id==50));
      var currencyInd=this.rolesAndFunctionalities.some(e=>e.roles.some(ele=>ele.sysfunc_id==49));
      var languagInd=this.rolesAndFunctionalities.some(e=>e.roles.some(ele=>ele.sysfunc_id==48));

      if(countryInd)
      {
        this.enableCountryRadio=true;
        this.changeValue="country"
        this.changeOfType();
      }
      else
      {
        this.enableCountryRadio=false;
      }
      if(languagInd)
      {
        this.enableLangRadio=true;
        if(this.enableCountryRadio==false)
        {
          this.changeValue="language"
          this.changeOfType();
        }
      }
      else
      {
        this.enableLangRadio=false;
      }
      if(currencyInd)
      {
        this.enableCurrRadio=true;
        if(this.enableCountryRadio==false && this.enableLangRadio==false)
        {
          this.changeValue="currency"
          this.changeOfType()
        }
      }
      else
      {
        this.enableCurrRadio=false;
      }

      if(colorInd)
      {
        this.enableColorRadio=true;
        if(this.enableCountryRadio==false && this.enableLangRadio==false && this.enableCurrRadio==false)
        {
          this.changeValue="color"
          this.changeOfType()
        }
      }
      else
      {
        this.enableColorRadio=false;
      }
    }
  }

getregionDetails()
{


  console.log(this.regionDetails);
  this.regionDetailsInit();
  this.transactionService.get_region_details( this.currentRegionCodeAdmin).subscribe((regdtl => {

    if(regdtl != null)
    {
    this.regionDetails=regdtl;
    //this.changeUnitValue();
    if(this.regionDetails.uom != null && this.regionDetails.uom.trim() != "" && this.regionDetails.uom != "E")
    {
      this.unitValue="Kg/cm"
    }
    else
    {
      this.unitValue="Lbs/in"
      this.regionDetails.uom = "E";

    }
    this.changeValueOnUOMOnLoad();
    console.log(this.regionDetails);

  }

  }
  ))

}
changeValueOnUOMOnLoad()
{

  if( this.regionDetails.cntr_ergo_limit != null && this.regionDetails.uom != null && this.regionDetails.uom.trim() != "" && this.regionDetails.uom == "M")
  {
    this.regionDetails.cntr_ergo_limit=Math.round((0.453592*this.regionDetails.cntr_ergo_limit)*1000)/1000;
  }

}


changeValueOnUOM()
{

  if( this.regionDetails.cntr_ergo_limit != null && this.regionDetails.uom != null && this.regionDetails.uom.trim() != "" && this.regionDetails.uom == "M")
  {
    this.regionDetails.cntr_ergo_limit=Math.round((0.453592*this.regionDetails.cntr_ergo_limit)*1000)/1000;
  }
  if( this.regionDetails.cntr_ergo_limit != null && this.regionDetails.uom != null && this.regionDetails.uom.trim() != "" && this.regionDetails.uom == "E")
  {
    this.regionDetails.cntr_ergo_limit=Math.round((2.20462* this.regionDetails.cntr_ergo_limit)*1000)/1000;
  }

}




/*
isEnableEditAccess()
{

  var adminroles=this.transactionService.getroles(this.current_region_code_admin);
  console.log(adminroles);
  var present_admin=false;
  if(adminroles!=null)
  {
  if(adminroles.length!=0)
  {

  for(var i=0;i<adminroles.length;i++)
  {
    var admin_subroles=adminroles[i].roles
    for(var j=0;j<admin_subroles.length;j++)
    {
    if(admin_subroles[j].sysfunc_id==3)
    {
      present_admin=true;
      break;
    }
  }
  if(present_admin==true)
  {
    break;
  }
  }



  return present_admin;
}
}

else{
  return present_admin;
}

  }

}
*/


saveRegiondetails()
{

  console.log(this.regionDetails)
this.regionDetails.cd_region=this.currentRegionCodeAdmin;

      if( this.regionDetails.cntr_ergo_limit != null && this.regionDetails.uom != null && this.regionDetails.uom.trim() != "" && this.regionDetails.uom == "M")
      {
        this.regionDetails.cntr_ergo_limit=Math.round((2.20462* this.regionDetails.cntr_ergo_limit)*1000)/1000;
      }
      console.log(this.regionDetails)
  this.transactionService.save_region_details(this.regionDetails).subscribe((savedtl => {
    //this.resultst=output;
    let res=savedtl;
    console.log("successful",res);

    this.contErgLimitValidation();
    this.sendUrgPartNotifyEmailEveryValidation();

    if(!this.contErgLimitInValid && !this.sendUrgPartNotifyEmailEveryInValid)
    {

    if(res == "Success"){

      this.messageService.add({severity:'success', summary:'Success', detail:' Details saved Successfully'});
      this.getregionDetails();
      this.regionAdminInputValidationInit();

    }

    else{
      this.regionAdminInputValidationInit();
      if( this.regionDetails.cntr_ergo_limit != null && this.regionDetails.uom != null && this.regionDetails.uom.trim() != "" && this.regionDetails.uom == "M")
      {
        this.regionDetails.cntr_ergo_limit=Math.round((0.453592*this.regionDetails.cntr_ergo_limit)*1000)/1000;
      }

      this.messageService.add({severity:'error', summary: 'Error', detail: 'Please Try Again'});
      //this.addplantdetailsinit();
     }
    }
    }

    ))




}

contErgLimitValidation(){
  if(this.regionDetails.cntr_ergo_limit <=0 || this.regionDetails.cntr_ergo_limit>=10000)
  {
    this.contErgLimitInValid=true;
    this.contErgLimitInValidReason="Only 1 to 9999 numbers are valid";
  }
  else{
    this.contErgLimitInValid=false;
    this.contErgLimitInValidReason=null;
  }
}

sendUrgPartNotifyEmailEveryValidation(){
  if(this.regionDetails.urge_prt_notice_email_freq<=0 || this.regionDetails.urge_prt_notice_email_freq>=100){
    this.sendUrgPartNotifyEmailEveryInValid=true;
    this.sendUrgPartNotifyEmailEveryInValidReason="Only 1 to 99 numbers are valid";
  }
  else{
    this.sendUrgPartNotifyEmailEveryInValid=false;
    this.sendUrgPartNotifyEmailEveryInValidReason=null;
  }
}

emailTempBodyNewPartsValidation(){
  if(this.newPartsMail.email_content.length >= 4001){
    this.emailTempBodyNewPartsInValid=true;
    this.emailTempBodyNewPartsInValidReason="Only 4000 Characters are valid";
  }
  else{
    this.emailTempBodyNewPartsInValid=false;
    this.emailTempBodyNewPartsInValidReason=null;
  }
}
emailTempBodyUrgentPartsValidation(){
  if(this.urgentPartsMail.email_content.length >= 4001){
    this.emailTempBodyUrgentPartsInValid=true;
    this.emailTempBodyUrgentPartsInValidReason="Only 4000 Characters are valid";
  }
  else{
    this.emailTempBodyUrgentPartsInValid=false;
    this.emailTempBodyUrgentPartsInValidReason=null;
  }
}
emailTempBodyContrBaseChngValidation(){
  if(this.containerBaseChgMail.email_content.length >= 4001){
    this.emailTempBodyContrBaseChngInValid=true;
    this.emailTempBodyContrBaseChngInValidReason="Only 4000 Characters are valid";
  }
  else{
    this.emailTempBodyContrBaseChngInValid=false;
    this.emailTempBodyContrBaseChngInValidReason=null;
  }
}
emailTempBodyAddApproverValidation(){
  if(this.addApproverMail.email_content.length >= 4001){
    this.emailTempBodyAddApproverInValid=true;
    this.emailTempBodyAddApproverInValidReason="Only 4000 Characters are valid";
  }
  else{
    this.emailTempBodyAddApproverInValid=false;
    this.emailTempBodyAddApproverInValidReason=null;
  }
}
emailTempBodyPckPropRejValidation(){
  if(this.proposalRejMail.email_content.length >= 4001){
    this.emailTempBodyPckPropRejInValid=true;
    this.emailTempBodyPckPropRejInValidReason="Only 4000 Characters are valid";
  }
  else{
    this.emailTempBodyPckPropRejInValid=false;
    this.emailTempBodyPckPropRejInValidReason=null;
  }
}
cancelRegionDetails()
{
  this.getregionDetails();
  this.regionAdminInputValidationInit();
}


changeOfType()
{
  console.log(this.changeValue);
  if(this.changeValue=="country")
  {
    this.showCurrency=false;
    this.showColor=false;
    this.showLanguage=false;
    this.showCountry=true;

    this.getRegionCountryData();
  }


  else if(this.changeValue=="language")
{
  this.showCountry=false;
  this.showColor=false;
  this.showCurrency=false;
  this.showLanguage=true;

  this.getRegionLanguageData();

}

else if(this.changeValue=="currency")
{
  this.showCountry=false;
  this.showColor=false;
  this.showLanguage=false;
  this.showCurrency=true;

  this.getRegionCurrencyData();

}


else if(this.changeValue=="color")
{
  this.showCountry=false;
  this.showCurrency=false;
  this.showLanguage=false;
  this.showColor=true;


  this.getRegionColorData();


}
}






getRegionCountryData()
{

this.countryCols=[
  {field:'cd_Country',header:'Country code'},
  {field:'cd_country_iso',header:'ISO code'},
  { field: 'country_name', header:'Country',width:250},
      {  field: 'active', header:'Active',width:50}
]
 /* this.country=[
    {country:"Australia",active:"true",active_stat:false},
    {country:"Canada",active:"false",active_stat:false},
    {country:"US",active:"true",active_stat:false}
  ]

*/


this.transactionService.get_region_country_details( this.currentRegionCodeAdmin).subscribe((regctydtl => {
  this.country=regctydtl;
if(this.country != null && this.country.length !=0)
{
  for(var i=0;i<this.country.length;i++)

{
  this.country[i].active_stat=this.country[i].active_stat.toLowerCase();
  if(this.country[i].active_stat=="yes")
  {
    this.country[i].active=true;
  }
  else{
    this.country[i].active=false;
  }
}

this.oldCountry=JSON.parse(JSON.stringify(this.country));
}


}))

}

saveRegionCountryData()
{
  this.updNewCountry=[];
  console.log(this.country);
  for(var i=0;i<this.country.length;i++)
  {
    if(this.country[i].active==true)
    {
      this.country[i].active_stat="yes";
    }
    else{
      this.country[i].active_stat="no";
    }
  }

var cntry=0;
var date_val =new Date();
console.log(date_val);
this.updNewCountry=[]
//var new_date_val=this.date.transform(date_val, 'yyyy-MM-dd hh:mm:ss');

  for(var j=0;j<this.country.length;j++)
  {
    if(this.country[j].active!=this.oldCountry[j].active)
    {
      console.log(this.country[j]);
      this.updNewCountry.push({

        country_name:this.country[j].country_name,
        active_stat:this.country[j].active_stat,
        cd_country:this.country[j].cd_Country,
        cd_region:this.currentRegionCodeAdmin,
        updated_by:this.regCdsid,
        updated_dt:new Date().toISOString(),
        cd_country_iso:this.country[j].cd_country_iso



      })

         cntry++;
    }

  }


  this.transactionService.update_region_country_details(this.updNewCountry).subscribe((updctrydtl => {
    //this.resultst=output;
    let res=updctrydtl;
    console.log("successful",res);
    if(res == "Success"){

      this.messageService.add({severity:'success', summary:'Success', detail:' Country updated Successfully'});
      this.getRegionCountryData();

    }

    else{

      this.messageService.add({severity:'error', summary: 'Error', detail: 'Please Try Again'});
      this.getRegionCountryData();
      //this.addplantdetailsinit();
     }

    }

    ))



 // this.save_region_country_data();

}


cancelRegionCountryData()
{
  this.getRegionCountryData();
}

addCountryInit()
{
  this.countryAdd={
    cd_country:"",
    cd_country_iso:"",
    country_name:"",
    cd_region:"",
    active_stat:"",
    updated_by:"",
    updated_dt:""
  }
}

addCountry()
{
  this.addCountryInit();
  this.countryAddButtonEnable=false;
  this.countryAddDetailsEnable=true;
  this.editCountryDetailsEnable=false;
}

saveAddCountry()
{
  this.countryAdd.cd_region=this.currentRegionCodeAdmin;
  this.countryAdd.updated_by=this.regCdsid;
  this.countryAdd.updated_dt=new Date().toISOString();

  //alert(JSON.stringify(this.countryAdd))

  this.transactionService.addRegionCountryDetails(this.countryAdd).subscribe(countryaddstat=>{
    console.log("success",countryaddstat)
    if(countryaddstat.toLowerCase()=="success")
    {
      this.messageService.add({severity:'success', summary:'Success', detail:' Country Added Successfully'});
      this.getRegionCountryData();
      this.addCountryInit();
      this.editCountryDetailsEnable=false;
      this.countryAddButtonEnable=false;
      this.countryAddDetailsEnable=true;
    }
    else{

      this.messageService.add({severity:'error', summary: 'Error', detail: 'Please Try Again'});
      this.getRegionCountryData();
      //this.addplantdetailsinit();
     }
  })

}

cancelAddCountry()
{
  this.countryAddDetailsEnable=false;
  this.editCountryDetailsEnable=false;
  this.countryAddButtonEnable=true;

  this.addCountryInit();
}

editCountry(cntrytoedit:countryreglist)
{
  this.countryAddButtonEnable=false;
  this.countryAddDetailsEnable=false;
  this.editCountryDetailsEnable=true;

  this.editCountryDetails={
    cd_country:"",
    cd_country_iso:"",
    cd_region:this.currentRegionAdmin,
    active_stat:"",
    updated_by:"",
    updated_dt:"",
    country_name:""
  }

  this.editCountryDetails.cd_country=cntrytoedit.cd_Country;
  this.editCountryDetails.country_name=cntrytoedit.country_name;
  this.editCountryDetails.cd_region=this.currentRegionCodeAdmin;
  this.editCountryDetails.active_stat=cntrytoedit.active_stat;


  if(cntrytoedit.cd_country_iso)
  {
    this.editCountryDetails.cd_country_iso=cntrytoedit.cd_country_iso;
  }


}

saveEditCountry()
{
  this.editCountryDetails.updated_by=this.regCdsid;
  this.editCountryDetails.updated_dt=new Date().toISOString();
  var upd_contry=[];
  upd_contry.push(this.editCountryDetails);

  //alert(JSON.stringify(this.editCountryDetails))
  this.transactionService.update_region_country_details(upd_contry).subscribe((cntryst => {
    //this.resultst=output;
    let res=cntryst;
    console.log("successful",res);
    if(res == "Success"){

      this.messageService.add({severity:'success', summary:'Success', detail:' Country Edited Successfully'});
      this.getRegionCountryData();
      this.editCountryDetailsEnable=false;
      this.countryAddButtonEnable=true;
      this.countryAddDetailsEnable=false;

    }

    else{

      this.messageService.add({severity:'error', summary: 'Error', detail: 'Please Try Again'});
      this.getRegionCountryData();
      //this.addplantdetailsinit();
     }

    }

    ))
}

cancelEditCountry()
{
  this.editCountryDetailsEnable=false;
  this.countryAddButtonEnable=true;
  this.countryAddDetailsEnable=false;
}


deleteCountry(countrytodelete:countryreglist)
{
  this.editCountryDetailsEnable=false;
  this.countryAddButtonEnable=true;
  this.countryAddDetailsEnable=false;

  this.confirmationService.confirm({
    message: 'Are you sure you want to delete country ' + '"'+countrytodelete.country_name+'"' + '?',
    header: 'Confirm',
    icon: 'pi pi-exclamation-triangle',
    accept: () =>
    {
      this.transactionService.deleteCountryRegionDetails(countrytodelete.cd_Country,this.currentRegionCodeAdmin).subscribe(delstatuscountry=>{
        console.log("Success",delstatuscountry);

        if(delstatuscountry.toLowerCase()=="success")
        {
          this.messageService.add({severity:"success",summary:"Success",detail:"Country Deleted Successfully"})
          this.getRegionCountryData();
        }
        else
        {
          this.messageService.add({severity:"error",summary:"Error",detail:"Try Again!"});
          this.getRegionCountryData();
        }
      })
    }
  });
}


getRegionLanguageData()
{

this.languageCols=[
  {field:'cd_lang',header:'Language Code'},
  { field: 'lang_desc', header:'Language',width:250},

      {  field: 'active', header:'Active',width:50}
]



this.transactionService.get_region_language_details( this.currentRegionCodeAdmin).subscribe((reglngdtl => {

  this.language=[];
  this.language=reglngdtl;
  if(this.language != null && this.language.length !=0)
{
  console.log("language",this.language);
  for(var i=0;i<this.language.length;i++)

{
  this.language[i].active_stat=this.language[i].active_stat.toLowerCase();
  if(this.language[i].active_stat=="yes")
  {
    this.language[i].active=true;
  }
  else{
    this.language[i].active=false;
  }
}

this.oldLanguage=JSON.parse(JSON.stringify(this.language));

console.log("old language",this.oldLanguage)
}
}
))




}

saveRegionLanguageData()
{
  this.updNewLanguage=[];
  console.log(this.language);
  for(var i=0;i<this.language.length;i++)
  {
    if(this.language[i].active==true)
    {
      this.language[i].active_stat="yes";
    }
    else{
      this.language[i].active_stat="no";
    }
  }

var lang=0;
var lang_date_val =new Date();
console.log(lang_date_val);
var lang_new_date_val=this.date.transform(lang_date_val, 'yyyy-MM-dd hh:mm:ss');

  for(var j=0;j<this.language.length;j++)
  {
    if(this.language[j].active_stat!=this.oldLanguage[j].active_stat)
    {
      console.log(this.language[j]);
      this.updNewLanguage.push({

        lang_desc:this.language[j].lang_desc,
        active_stat:this.language[j].active_stat,
        cd_lang:this.language[j].cd_lang,
        cd_region:this.language[j].cd_region,
        updated_by:this.regCdsid,
       // updated_date:lang_new_date_val

      })

         lang++;
    }

  }


  this.transactionService.update_region_language_details(this.updNewLanguage).subscribe((updlangdtl => {
    //this.resultst=output;
    let res=updlangdtl;
    console.log("successful",res);
    if(res == "Success"){

      this.messageService.add({severity:'success', summary:'Success', detail:' Language updated Successfully'});
      this.getRegionLanguageData();

    }

    else{

      this.messageService.add({severity:'error', summary: 'Error', detail: 'Please Try Again'});
      this.getRegionLanguageData();
      //this.addplantdetailsinit();
     }

    }

    ))


 // this.save_region_language_data();

}


cancelRegionLanguageData()
{
  this.getRegionLanguageData();
}

addLangageInit()
{
  this.languageAdd={
    lang_desc:"",
    cd_lang:"",
    active_stat:"",
    updated_by:"",
    cd_region:"",
    updated_dt:""
  }
}

addLanguage()
{
  this.addLangageInit();
  this.languageAddButtonEnable=false;
  this.languageAddDetailsEnable=true;
  this.editLanguageDetailsEnable=false;
}

saveAddLanguage()
{
  var add_lang=[];
  //this.languageAdd.cd_lang="0"
  this.languageAdd.cd_region=this.currentRegionCodeAdmin;
  this.languageAdd.updated_by=this.regCdsid;
  this.languageAdd.updated_dt=new Date().toISOString();
  //add_lang.push(this.languageAdd);

  //alert(JSON.stringify(this.editLanguageDetails))
  this.transactionService.addLanguageInRegion(this.languageAdd).subscribe((updlang => {
    //this.resultst=output;
    let res=updlang;
    console.log("successful",res);
    if(res == "Success"){

      this.messageService.add({severity:'success', summary:'Success', detail:' Language Added Successfully'});
      this.getRegionLanguageData();
      this.addLangageInit();
      this.editLanguageDetailsEnable=false;
      this.languageAddButtonEnable=false;
      this.languageAddDetailsEnable=true;

    }

    else{

      this.messageService.add({severity:'error', summary: 'Error', detail: 'Please Try Again'});
      this.getRegionLanguageData();
      //this.addplantdetailsinit();
     }

    }

    ))
}

cancelAddLanguage()
{
  this.addLangageInit();
  this.editLanguageDetailsEnable=false;
  this.languageAddButtonEnable=true;
  this.languageAddDetailsEnable=false;
}

editLanguage(editLang:languagereglist)
{
  this.languageAddButtonEnable=false;
  this.languageAddDetailsEnable=false;
  this.editLanguageDetailsEnable=true;

  this.editLanguageDetails.cd_lang=editLang.cd_lang;
  this.editLanguageDetails.lang_desc=editLang.lang_desc;
  this.editLanguageDetails.cd_region=editLang.cd_region;
  this.editLanguageDetails.active_stat=editLang.active_stat;
  this.editLanguageDetails.updated_by=this.regCdsid;
}

saveEditLang()
{
  var upd_lang=[];
  upd_lang.push(this.editLanguageDetails);

  //alert(JSON.stringify(this.editLanguageDetails))
  this.transactionService.update_region_language_details(upd_lang).subscribe((updlang => {
    //this.resultst=output;
    let res=updlang;
    console.log("successful",res);
    if(res == "Success"){

      this.messageService.add({severity:'success', summary:'Success', detail:' Language Edited Successfully'});
      this.getRegionLanguageData();
      this.editLanguageDetailsEnable=false;
      this.languageAddButtonEnable=true;
      this.languageAddDetailsEnable=false;

    }

    else{

      this.messageService.add({severity:'error', summary: 'Error', detail: 'Please Try Again'});
      this.getRegionLanguageData();
      //this.addplantdetailsinit();
     }

    }

    ))
}

cancelEditLang()
{
  this.editLanguageDetailsEnable=false;
  this.languageAddButtonEnable=true;
  this.languageAddDetailsEnable=false;
}

deleteLanguage(delLang:languagereglist)
{
  this.editLanguageDetailsEnable=false;
  this.languageAddButtonEnable=true;
  this.languageAddDetailsEnable=false;

  this.confirmationService.confirm({
    message: 'Are you sure you want to delete language ' + '"'+delLang.lang_desc+'"' + '?',
    header: 'Confirm',
    icon: 'pi pi-exclamation-triangle',
    accept: () =>
    {
      this.transactionService.deleteLanguage(delLang.cd_lang,delLang.cd_region).subscribe(delstatus=>{
        console.log("Success",delstatus);

        if(delstatus.toLowerCase()=="success")
        {
          this.messageService.add({severity:"success",summary:"Success",detail:"Language Deleted Successfully"})
          this.getRegionLanguageData();
        }
        else
        {
          this.messageService.add({severity:"error",summary:"Error",detail:"Try Again!"});
          this.getRegionLanguageData();
        }
      })
    }
  });
}







getRegionCurrencyData()
{

this.currencyCols=[
  { field: 'curr_desc', header:'Currency',width:250},
      {  field: 'active', header:'Active',width:50}
]
/*  this.currency=[
    {curr_desc:"Dollar",active_stat:"true",active:false},
    {currency:"Euro",active:"false",active_stat:false},
    {currency:"Ruppee",active:"true",active_stat:false},
    {currency:"yen",active:"true",active_stat:false}
  ]*/

  this.transactionService.get_region_currency_details().subscribe((regcurrdtl => {
    this.currency=[]
    this.currency=regcurrdtl
if(this.currency != null && this.currency.length !=0)
{
  for(var i=0;i<this.currency.length;i++)

{
  this.currency[i].active_stat=this.currency[i].active_stat.toLowerCase();
  if(this.currency[i].active_stat=="yes")
  {
    this.currency[i].active=true;
  }
  else{
    this.currency[i].active=false;
  }
}

this.oldCurrency=JSON.parse(JSON.stringify(this.currency));;
  }
}
  ))

}



saveRegionCurrencyData()
{
  this.updNewCurrency=[];
  console.log(this.oldCurrency);
  for(var i=0;i<this.currency.length;i++)
  {
    if(this.currency[i].active==true)
    {
      this.currency[i].active_stat="yes";
    }
    else{
      this.currency[i].active_stat="no";
    }
  }


  var crncy=0;
var crncy_date_val =new Date();
console.log(crncy_date_val);
this.updNewCurrency=[];
var crncy_new_date_val=this.date.transform(crncy_date_val, 'yyyy-MM-dd hh:mm:ss');

  for(var j=0;j<this.currency.length;j++)
  {
    if(this.currency[j].active_stat!=this.oldCurrency[j].active_stat)
    {


      console.log(this.currency[j].curr_desc);
      this.updNewCurrency.push({


        curr_desc :this.currency[j].curr_desc,
         active_stat:this.currency[j].active_stat,
         updated_by:this.regCdsid,
         cd_curr:this.currency[j].cd_curr
        // updated_date:crncy_new_date_val

      })

         crncy++;
    }

  }



  this.transactionService.update_region_currency_details(this.updNewCurrency).subscribe((updcurrdtl => {
    //this.resultst=output;
    let res=updcurrdtl;
    console.log("successful",res);
    if(res == "Success"){

      this.messageService.add({severity:'success', summary:'Success', detail:' Currency updated Successfully'});
      this.getRegionCurrencyData();

    }

    else{

      this.messageService.add({severity:'error', summary: 'Error', detail: 'Please Try Again'});
      this.getRegionCurrencyData();
      //this.addplantdetailsinit();
     }

    }

    ))





 // this.save_region_currency_data();

}


cancelRegionCurrencyData()
{
  this.getRegionCurrencyData();
}

addCurrencyInit()
{
  this.addNewCurrency={
    updated_dt:"",
    curr_desc:"",
    active_stat:"",
    updated_by:""
  }
}

addCurrency()
{
  this.addCurrencyInit();
  this.showAddCurrencyButton=false;
  this.showAddCurrency=true;
  this.editCurrencyDetailsEnable=false;

}

saveAddCurrency()
{
  //var addCurr:upd_currencyreglist[]=[];
  this.addNewCurrency.updated_by=this.regCdsid;
  this.addNewCurrency.updated_dt=new Date().toISOString();

  //addCurr.push(this.addNewCurrency);

 // alert(JSON.stringify(addCurr))

  this.transactionService.addCurrencyDetailsRegionAdmin(this.addNewCurrency).subscribe((addcurrstat => {
    //this.resultst=output;
    let res=addcurrstat;
    console.log("successful",res);
    if(res == "Success"){

      this.messageService.add({severity:'success', summary:'Success', detail:' Currency Added Successfully'});
      this.getRegionCurrencyData();
      this.addCurrencyInit();
      this.showAddCurrency=true;
      this.showAddCurrencyButton=false;
      this.editCurrencyDetailsEnable=false;

    }

    else{

      this.messageService.add({severity:'error', summary: 'Error', detail: 'Please Try Again'});
      this.getRegionCurrencyData();
      //this.addplantdetailsinit();
     }

    }

    ))
}

cancelAddCurrency()
{
  this.showAddCurrency=false;
  this.showAddCurrencyButton=true;
  this.editCurrencyDetailsEnable=false;
  this.addCurrencyInit();
}


editCurrencyData(currencydet:currencyreglist)
{
  this.editCurrencyDetailsEnable=true;
  this.showAddCurrencyButton=false;
  this.showAddCurrency=false;

  this.editCurrencyDetails.cd_curr=currencydet.cd_curr;
  this.editCurrencyDetails.curr_desc=currencydet.curr_desc;
  this.editCurrencyDetails.active_stat=currencydet.active_stat;
  this.editCurrencyDetails.updated_by=this.regCdsid;
}

saveCurrencyEditDetls()
{
  var updCurr:upd_currencyreglist[]=[];
  updCurr.push(this.editCurrencyDetails);

  this.transactionService.update_region_currency_details(updCurr).subscribe((updcurr => {
    //this.resultst=output;
    let res=updcurr;
    console.log("successful",res);
    if(res == "Success"){

      this.messageService.add({severity:'success', summary:'Success', detail:' Currency updated Successfully'});
      this.getRegionCurrencyData();
      this.editCurrencyDetailsEnable=false;
      this.showAddCurrencyButton=true;
      this.showAddCurrency=false;

    }

    else{

      this.messageService.add({severity:'error', summary: 'Error', detail: 'Please Try Again'});
      this.getRegionCurrencyData();
      //this.addplantdetailsinit();
     }

    }

    ))

}

cancelCurrencyEdit()
{
  this.editCurrencyDetailsEnable=false;
  this.showAddCurrencyButton=true;
  this.showAddCurrency=false;
}

deleteCurrency(currencyToBeDeleted:currencyreglist)
{
  this.editCurrencyDetailsEnable=false;
  this.showAddCurrencyButton=true;
  this.showAddCurrency=false;

  this.confirmationService.confirm({
    message: 'Are you sure you want to delete currency ' + '"'+currencyToBeDeleted.curr_desc+'"' + '?',
    header: 'Confirm',
    icon: 'pi pi-exclamation-triangle',
    accept: () =>
    {
      this.transactionService.deleteCurrencyDetails(currencyToBeDeleted.cd_curr).subscribe(delcurrstat=>{
        console.log("Success",delcurrstat);

        if(delcurrstat.toLowerCase()=="success")
          {
            this.messageService.add({severity:"success",summary:"Success",detail:"Currency Deleted Successfully"})
            this.getRegionCurrencyData();
          }
          else
          {
            this.messageService.add({severity:"error",summary:"Error",detail:"Try Again!"})
          }
      })
    }

  })
}



getRegionColorData()
{

this.colorCols=[
  { field: 'color_desc', header:'Color',width:250},
      {  field: 'active', header:'Active',width:50}
]

this.transactionService.get_region_color_details( this.currentRegionCodeAdmin).subscribe((regclrdtl => {

  this.color=regclrdtl;
  console.log(this.color);
  this.oldColor=[];
  //this.old_color=this.color;
  if(this.color != null && this.color.length !=0)
  {
  for(var i=0;i<this.color.length;i++)

  {
    this.color[i].active_stat=this.color[i].active_stat.toLowerCase();
    if(this.color[i].active_stat=="yes")
    {
      this.color[i].active=true;
    }
    else{
      this.color[i].active=false;
    }
  }
  //const a=Object.assign({}, this.color)
  this.oldColor=JSON.parse(JSON.stringify(this.color));;
  }
}
))
/*
  this.color=[
    {color_desc:"green",active_stat:"Yes",active:false,cd_color:1,cd_region:"NA"},
    {color_desc:"red",active_stat:"No",active:false, cd_color:1,cd_region:"NA"},
    {color_desc:"pink",active_stat:"Yes",active:false,cd_color:1,cd_region:"NA"}
  ]

*/

}
saveRegionColorData()
{
  this.updNewColor=[];
  console.log(this.color);
  console.log(this.oldColor);
  for(var i=0;i<this.color.length;i++)
  {
    if(this.color[i].active==true)
    {
      this.color[i].active_stat="yes";
    }
    else{
      this.color[i].active_stat="no";
    }
  }


  console.log(this.color)


  var colr=0;
  var colr_date_val =new Date();
  console.log(colr_date_val);
  this.updNewColor=[];
  var colr_new_date_val=this.date.transform(colr_date_val, 'yyyy-MM-dd hh:mm:ss');

    for(var j=0;j<this.color.length;j++)
    {
      if(this.color[j].active_stat!=this.oldColor[j].active_stat)
      {
        console.log(this.color[j].color_desc)
console.log(this.color[j].cd_Color);
        this.updNewColor.push({
          color_desc :this.color[j].color_desc,
          active_stat:this.color[j].active_stat,
          cd_color:this.color[j].cd_Color,
         cd_region:this.color[j].cd_region,
          updated_by:this.regCdsid,
         // updated_date:colr_new_date_val,
      })

           colr++;
      }

    }


    this.transactionService.update_region_color_details(this.updNewColor).subscribe((updclrdtl => {
      //this.resultst=output;
      let res=updclrdtl;
      console.log("successful",res);
      if(res == "Success"){

        this.messageService.add({severity:'success', summary:'Success', detail:' Color updated Successfully'});
        this.getRegionColorData();

      }

      else{

        this.messageService.add({severity:'error', summary: 'Error', detail: 'Please Try Again'});
        this.getRegionColorData();
        //this.addplantdetailsinit();
       }

      }

      ))


   // this.save_region_color_data();
}


cancelRegionColorData()
{
  this.getRegionColorData();
}




addColorButton()
{
  this.showAddColor=true;
  this.addColorInit();
  this.showAddColorButton=false;
}


addColorInit()
{
  this.addNewColor={
    color_desc:"",
    active_stat:"",
    cd_region:"",
    cd_color:0,
    updated_by:""
   // updated_by:"",
    //updated_date:""

}
}



saveAddColor()
{
  console.log(this.addNewColor);
 // this.add_new_color.updated_by=this.reg_cdsid;
  var add_colr_date_val =new Date();
  this.addNewColor.updated_by=this.regCdsid;
  this.addNewColor.cd_region=this.currentRegionCodeAdmin;
  console.log(add_colr_date_val);
  var add_colr_new_date_val=this.date.transform(add_colr_date_val, 'yyyy-MM-dd hh:mm:ss');

  //this.add_new_color.updated_date=add_colr_new_date_val;

  this.transactionService.save_region_color_details(this.addNewColor).subscribe((saveclrdtl => {
    //this.resultst=output;
    let res=saveclrdtl;
    console.log("successful",res);
    if(res == "Success"){

      this.messageService.add({severity:'success', summary:'Success', detail:' Color saved Successfully'});
      this.getRegionColorData();
      this.addColorInit();
      this.editColorDetailsEnable=false;
      this.showAddColor=true;
      this.showAddColorButton=false;
    }

    else{

      this.messageService.add({severity:'error', summary: 'Error', detail: 'Please Try Again'});
      this.getRegionColorData();
      //this.addplantdetailsinit();
     }

    }

    ))



}

cancelAddColor()
{
  this.showAddColor=false;
  this.addColorInit();
  this.showAddColorButton=true;
  this.editColorDetailsEnable=false;
}


editColor(colorDetailsForEdit:colorreglist)
{
  this.editColorDetailsEnable=true;
  this.showAddColor=false;
  this.showAddColorButton=false;
  this.addColorInit();

  this.editColorDetails.cd_color=colorDetailsForEdit.cd_Color;
  this.editColorDetails.color_desc=colorDetailsForEdit.color_desc;
  this.editColorDetails.cd_region=colorDetailsForEdit.cd_region;
  this.editColorDetails.active_stat=colorDetailsForEdit.active_stat;
  this.editColorDetails.updated_by=this.regCdsid;
  //alert(JSON.stringify(colorDetailsForEdit));
}

saveEditColor()
{
  //alert(JSON.stringify(this.editColorDetails));

  var updColor=[];
  updColor.push(this.editColorDetails);

  this.transactionService.update_region_color_details(updColor).subscribe(editclorstat=>{
    let res=editclorstat;
    console.log("successful",res);
    if(res == "Success"){

      this.messageService.add({severity:'success', summary:'Success', detail:' Color Edited Successfully'});
      this.getRegionColorData();
      this.editColorDetailsEnable=false;
      this.showAddColorButton=true;

    }

    else{

      this.messageService.add({severity:'error', summary: 'Error', detail: 'Please Try Again'});
      this.getRegionColorData();
      //this.addplantdetailsinit();
     }
  })
}

cancelEditColor()
{
  this.editColorDetailsEnable=false;
  this.showAddColorButton=true;
  this.editColorDetails={cd_color:0,color_desc:"",cd_region:"",active_stat:"",updated_by:""}
}

deleteColor(colorDetailsForDelete:colorreglist)
{

  this.editColorDetailsEnable=false;
  this.showAddColor=false;
  this.showAddColorButton=true;

  this.confirmationService.confirm({
    message: 'Are you sure you want to delete color ' + '"'+colorDetailsForDelete.color_desc+'"' + '?',
    header: 'Confirm',
    icon: 'pi pi-exclamation-triangle',
    accept: () =>
    {
      this.transactionService.deleteColorDetails(colorDetailsForDelete.cd_Color,colorDetailsForDelete.cd_region).subscribe(colordeletestat=>{
        console.log("success",colordeletestat);

        if(colordeletestat.toLowerCase()=="success"){

          this.messageService.add({severity:'success', summary:'Success', detail:' Color Deleted Successfully'});
          this.getRegionColorData();


        }

        else{

          this.messageService.add({severity:'error', summary: 'Error', detail: 'Please Try Again'});
          this.getRegionColorData();
          //this.addplantdetailsinit();
         }
      })
    }
  })
  //this.editColorDetailsEnable=false;
}




displayEmail(){

  console.log(this.selectedeMail);
  if(this.selectedeMail.code=="1")
  {
    this.urgentPartsEnableDialog=false;
    this.containerBaseChgEnableDialog=false;
    this.addApproverEnableDialog=false;
    this.proposalRejEnableDialog=false;
    this.newPartsEnableDialog=true;



   this.newPartsMailButton();



  }

  else  if(this.selectedeMail.code=="2")
  {
    this.newPartsEnableDialog=false;
    this.containerBaseChgEnableDialog=false;
    this.addApproverEnableDialog=false;
    this.proposalRejEnableDialog=false;
    this.urgentPartsEnableDialog=true;


this.urgentPartsMailButton();
  }

  else  if(this.selectedeMail.code=="3")
  {
    this.newPartsEnableDialog=false;
    this.urgentPartsEnableDialog=false;
    this.addApproverEnableDialog=false;
    this.proposalRejEnableDialog=false;
    this.containerBaseChgEnableDialog=true;


this.containerBaseChgMailButton()
  }

  else  if(this.selectedeMail.code=="4")
  {
    this.newPartsEnableDialog=false;
    this.urgentPartsEnableDialog=false;
    this.containerBaseChgEnableDialog=false;
    this.proposalRejEnableDialog=false;
    this.addApproverEnableDialog=true;

    this.addApproverMailButton();
  }


  else  if(this.selectedeMail.code=="5")
  {
    this.newPartsEnableDialog=false;
    this.urgentPartsEnableDialog=false;
    this.containerBaseChgEnableDialog=false;
    this.addApproverEnableDialog=false;
    this.proposalRejEnableDialog=true;


this.proposalRejMailButton();
  }

}






newPartsMailButton()
{
 // this.newparts_enable_dialog=true;
//service code to get all email
//this.email_templates.newparts=result;


var newpart="NEW_PRT_AWTNG"

this.transactionService.get_region_email_details( this.currentRegionCodeAdmin,newpart).subscribe((regnewprtdtl => {

  this.newPartsMailTemp="";
  if(regnewprtdtl.length!=0){
  this.newPartsMail=regnewprtdtl[0];


  this.newPartsMailTemp=this.newPartsMail.email_content;

  }

}
))



}

newPartsMailOk()
{

  this.newPartsMail.email_content=this.newPartsMailTemp;
  this.newPartsMailUpd=[]
  this.newPartsMailUpd.push({
    cd_region:this.currentRegionCodeAdmin,
    email_content:this.newPartsMail.email_content,
    email_templ_desc:"New Part awaiting for Packaging proposal",
    email_templ_name:"NEW_PRT_AWTNG",
    updated_by:this.regCdsid
  })

  this.emailTempBodyNewPartsValidation();

  //service to post all data of email template;
if(this.newPartsEnableDialog==true)
{
  if(!this.emailTempBodyNewPartsInValid)
  {
  this.transactionService.save_region_email_details(this.newPartsMailUpd).subscribe((saveemldtl => {
    //this.resultst=output;
    let res=saveemldtl;
    console.log("successful",res);
    if(res == "Success"){

      this.messageService.add({severity:'success', summary:'Success', detail:' New Parts Waiting email template saved Successfully'});
      this.newPartsMailButton();
      // this.regionAdminInputValidationInit();

    }

    else{

      this.messageService.add({severity:'error', summary: 'Error', detail: 'Please Try Again'});
      if(this.proposalRejMail.email_content.length >= 4001){
      this.newPartsMailButton();
      // this.regionAdminInputValidationInit();
      //this.addplantdetailsinit();
     }
    }

    }

    ))
  }
  //this.newparts_mail_button()
 // this.newparts_enable_dialog=false;
}
else
{
  this.regionAdminInputValidationInit();
}


}

newPartsMailCancel()
{
  //this.newparts_enable_dialog=false;
  this.newPartsMailButton();
  this.regionAdminInputValidationInit();

}



urgentPartsMailButton()
{
//this.urgentparts_enable_dialog=true;
//service code to get all email
//this.email_templates.newparts=result;


var urgpart="URGNT_PRT_AWTNG"

this.transactionService.get_region_email_details( this.currentRegionCodeAdmin,urgpart).subscribe((regurgprtdtl => {
  this.urgentPartsMailTemp=""
  if(regurgprtdtl.length!=0){
  this.urgentPartsMail=regurgprtdtl[0];


  this.urgentPartsMailTemp=this.urgentPartsMail.email_content;

  }

}
))



}

urgentpartsMailOk()
{

  this.urgentPartsMail.email_content=this.urgentPartsMailTemp;
  this.urgentPartsMailUpd=[]
  this.urgentPartsMailUpd.push({
    cd_region:this.currentRegionCodeAdmin,
    email_content:this.urgentPartsMail.email_content,
    email_templ_desc:"Urgent part awiting for package proposal",
    email_templ_name:"URGNT_PRT_AWTNG",
    updated_by:this.regCdsid
  })

  this.emailTempBodyUrgentPartsValidation();



  //service to post all data of email template;
if(this.urgentPartsEnableDialog==true)
{
  if(!this.emailTempBodyUrgentPartsInValid)
    {
    this.transactionService.save_region_email_details(this.urgentPartsMailUpd).subscribe((saveemldtl => {
    //this.resultst=output;
    let res=saveemldtl;
    console.log("successful",res);
    if(res == "Success"){

      this.messageService.add({severity:'success', summary:'Success', detail:' Urgent Parts Waiting email template saved Successfully'});
      this.urgentPartsMailButton();
      this.regionAdminInputValidationInit();

    }

    else{

      this.messageService.add({severity:'error', summary: 'Error', detail: 'Please Try Again'});
      if(this.proposalRejMail.email_content.length >= 4001){
      this.urgentPartsMailButton();
      }
      // this.regionAdminInputValidationInit();
      //this.addplantdetailsinit();
     }

    }

    ))
  }
}
else{
  this.regionAdminInputValidationInit();
}

 // this.urgentparts_mail_button()
  //this.urgentparts_enable_dialog=false;

}

urgentpartsMailCancel()
{
 // this.urgentparts_enable_dialog=false;
 this.urgentPartsMailButton();
 this.regionAdminInputValidationInit();
}








containerBaseChgMailButton()
{
//this.containerbasechg_enable_dialog=true;
//service code to get all email
//this.email_templates.newparts=result;


var cntbaschgpart="CNTR_BASE_CHNGE"

this.transactionService.get_region_email_details( this.currentRegionCodeAdmin,cntbaschgpart).subscribe((regcntbaschgdtl => {

  this.containerBaseChgMailTemp=""
  if(regcntbaschgdtl.length!=0){
  this.containerBaseChgMail=regcntbaschgdtl[0];


  this.containerBaseChgMailTemp=this.containerBaseChgMail.email_content;

  }

}
))
//this.containerbasechg_mail_temp=this.email_templates.containerbasechg;

}

containerBaseChgMailOk()
{

  this.containerBaseChgMail.email_content=this.containerBaseChgMailTemp;
  this.containerBaseChgMailUpd=[]
  this.containerBaseChgMailUpd.push({
    cd_region:this.currentRegionCodeAdmin,
    email_content:this.containerBaseChgMail.email_content,
    email_templ_desc:"Container base changed",
    email_templ_name:"CNTR_BASE_CHNGE",
    updated_by:this.regCdsid
  })

  this.emailTempBodyContrBaseChngValidation();

  //service to post all data of email template;
if(this.containerBaseChgEnableDialog==true)
{
  if(!this.emailTempBodyContrBaseChngInValid)
    {
  this.transactionService.save_region_email_details(this.containerBaseChgMailUpd).subscribe((saveemldtl => {
    //this.resultst=output;
    let res=saveemldtl;
    console.log("successful",res);
    if(res == "Success"){

      this.messageService.add({severity:'success', summary:'Success', detail:' Cntr Base chg email template saved Successfully'});
      this.containerBaseChgMailButton();
      // this.regionAdminInputValidationInit();

    }

    else{

      this.messageService.add({severity:'error', summary: 'Error', detail: 'Please Try Again'});
      if(this.proposalRejMail.email_content.length >= 4001){
      this.containerBaseChgMailButton();
      // this.regionAdminInputValidationInit();
      //this.addplantdetailsinit();
     }
    }
    }

    ))

  }
 // this.containerbasechg_mail_button();
  //this.containerbasechg_enable_dialog=false;
}
else{
  this.regionAdminInputValidationInit();
}
}

containerBaseChgMailCancel()
{
 // this.containerbasechg_enable_dialog=false;;
  this.containerBaseChgMailButton();
  this.regionAdminInputValidationInit();
}









addApproverMailButton()
{
//this.addapprover_enable_dialog=true;
//service code to get all email
//this.email_templates.newparts=result;



var addapr="ADDTNAL_APPRVR"

this.transactionService.get_region_email_details( this.currentRegionCodeAdmin,addapr).subscribe((regaddaprdtl => {

  this.addApproverMailTemp="";
  if(regaddaprdtl.length!=0)
  {
  this.addApproverMail=regaddaprdtl[0];


  this.addApproverMailTemp=this.addApproverMail.email_content;

  }

}
))

//this.addapprover_mail_temp=this.email_templates.addapprover;

}

addApproverMailOk()
{

  this.addApproverMail.email_content=this.addApproverMailTemp;
  this.addApproverMailUpd=[]
  this.addApproverMailUpd.push({
    cd_region:this.currentRegionCodeAdmin,
    email_content:this.addApproverMail.email_content,
    email_templ_desc:"Additional approvar added",
    email_templ_name:"ADDTNAL_APPRVR",
    updated_by:this.regCdsid
  })

  this.emailTempBodyAddApproverValidation();



  //service to post all data of email template;
if(this.addApproverEnableDialog==true){
  if(!this.emailTempBodyAddApproverInValid){
    {
  this.transactionService.save_region_email_details(this.addApproverMailUpd).subscribe((saveemldtl => {
    //this.resultst=output;
    let res=saveemldtl;
    console.log("successful",res);
    if(res == "Success"){

      this.messageService.add({severity:'success', summary:'Success', detail:' Add approver email template saved Successfully'});

      if(this.addApproverMail.email_content.length >= 4001){
      this.addApproverMailButton();
      // this.regionAdminInputValidationInit();
    }
    }

    else{

      this.messageService.add({severity:'error', summary: 'Error', detail: 'Please Try Again'});

        this.addApproverMailButton();


      // this.regionAdminInputValidationInit();
      //this.addplantdetailsinit();
     }

    }

    ))
  }
  }
}
  else{
    this.regionAdminInputValidationInit();
  }


  //this.addapprover_mail_button();
  //this.addapprover_enable_dialog=false;

}

addApproverMailCancel()
{
  //this.addapprover_enable_dialog=false;
 this.addApproverMailButton();
 this.regionAdminInputValidationInit();
}






proposalRejMailButton()
{
//this.proposalrej_enable_dialog=true;
//service code to get all email
//this.email_templates.newparts=result;



var prorej="PCK_PRPSL_RJCTD"

this.transactionService.get_region_email_details( this.currentRegionCodeAdmin,prorej).subscribe((regprorejdtl => {

  this.proposalRejMailTemp="";
  if(regprorejdtl.length!=0){
  this.proposalRejMail=regprorejdtl[0];


  this.proposalRejMailTemp=this.proposalRejMail.email_content;

  }

}
))


//this.proposalrej_mail_temp=this.email_templates.proposalrej;

}

proposalRejMailOk()
{

  this.proposalRejMail.email_content=this.proposalRejMailTemp;
  this.proposalRejMailUpd=[]
  this.proposalRejMailUpd.push({
    cd_region:this.currentRegionCodeAdmin,
    email_content:this.proposalRejMail.email_content,
    email_templ_desc:"Packaging proposal rejected",
    email_templ_name:"PCK_PRPSL_RJCTD",
    updated_by:this.regCdsid
  })


  this.emailTempBodyPckPropRejValidation();
  //service to post all data of email template;

  if(this.proposalRejEnableDialog==true)
    {
      if(!this.emailTempBodyPckPropRejInValid){

      this.transactionService.save_region_email_details(this.proposalRejMailUpd).subscribe((saveemldtl => {
        //this.resultst=output;
        let res=saveemldtl;
        console.log("successful",res);

        if(res == "Success"){

          this.messageService.add({severity:'success', summary:'Success', detail:' Proposal Rej email template saved Successfully'});
          if(this.proposalRejMail.email_content.length >= 4001){
            this.proposalRejMailButton();
          }
          // this.regionAdminInputValidationInit();

        }

        else{

          this.messageService.add({severity:'error', summary: 'Error', detail: 'Please Try Again'});

            this.proposalRejMailButton();

          // this.regionAdminInputValidationInit();
          //this.addplantdetailsinit();
         }

        }

        ))
    }


  }
  else{
    this.regionAdminInputValidationInit();
  }


  //service to post all data of email template;
 // this.proposalrej_mail_button();
  //this.proposalrej_enable_dialog=false;

}

proposalRejMailCancel()
{
  //this.proposalrej_enable_dialog=false;
  this.proposalRejMailButton();
  this.regionAdminInputValidationInit();
}



}
