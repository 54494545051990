/* tslint:disable */
import { Component, OnInit,Output,EventEmitter, Pipe, ViewChild, ElementRef } from '@angular/core';


import {TransactionService} from '../loginpage/Transaction.service';
import { Router,ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import {plantlist,addplant,getplant,planttype,countrylist, useraccessrole,subpltlist,subprglist,subcoslist, substatuslist, subgrid, subtradco, selec_prt_pro_details, subnewprt_latesbom, subnewpart_latestbom_designsource, supp_country,containerOwnershipList, approver_grid_list, ActivePlants, programsbasedonplants, fromdiplayvalues_all, RevisionObj, releaselevelList, additionalApprForPost, AdditionalApproversForUI, colorreglist, hostamp_upload_apr, stamping_upload_apr, migrationApprovalPost, RevHistFromAPi, ApprovalStatus, partsbulkemailpost, approverFiltersStored, subCosListWithCdCode, allPropslDtlsBulkAppPost, userdetails, propIdpltDtlsBulkApproval} from '../loginpage/transactions';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { runInThisContext } from 'vm';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-approvaltemplate',
  templateUrl: './approvaltemplate.component.html',
  styleUrls: ['./approvaltemplate.component.css']
})


export class ApprovaltemplateComponent implements OnInit {

  disableProgDrp_apr:boolean=true;
  current_region_apr:string=" ";
  current_region_code_apr:string;
  subUserAccess:useraccessrole[]=[];
  plantCodeList_apr:subpltlist[]=[];
  selectedplantlist_apr:subpltlist[]=[];

  progList_apr:subprglist[]=[];
  selectedproglist_apr:subprglist[]=[];

  cosList_apr:subCosListWithCdCode[]=[];
  selectedcoslist_apr:subCosListWithCdCode[]=[];



 selectedpartprefix_apr:string="";
 selectedpartbase_apr:string="";
 selectedpartsuffix_apr:string="";

  part_prefix_apr:string="";
  part_base_apr:string="";
  part_suffix_apr:string="";



  plantcode_apr:string="";
  programcode_apr:string="";
  cos_apr:string="";

  status_apr:string="";
  trdcmpy_apr:string="";


  testpckreq_apr:string="";



  subAllPlants_apr:ActivePlants[]=[];
  subCosList_apr:containerOwnershipList[]=[];
  progListOnPlt_apr:programsbasedonplants[]=[];



  tradCmpyList_apr:subtradco[]=[];
  selectedtrdcmpy_apr:subtradco[]=[]

  statusList_apr:substatuslist[]=[];
  selectedstatuslist_apr:substatuslist[]=[];
  selectedsimpliedpartnodetails_apr:selec_prt_pro_details={simplifiedpartno:"-",bompartmasterid:0,proposalid:0,plantgsdbcode:"",proposallastsaved:0,part_status:"",supplier_code:"",proposallastsaved_ee:0,is_ee_proposal:false,currentview_ee:false,from_draft:false,propsl_type:"",imp_part_status:""};


  suppliercountryList_apr:supp_country[]=[];
  selected_suppliercountry_apr:supp_country[]=[];
  suppliercountry_apr:string=""


  apr_cdsid:string;


  grid_text:string="";
  present_table:boolean=false;
  apr_current_roleid:number;

  apprvrGridList:approver_grid_list[];
  selectedPartsToApprove:approver_grid_list[]=[];
  enableApproveAll:boolean = true;

  exceptionQueueGrid:approver_grid_list[]=[];
  selectedFromExceptionQueue:approver_grid_list[]=[];

  enableSendEmailPopUp:boolean=false;
  bulkEmailSubject:string="";
  bulkEmailBody:string=""
  copyMeOnBulkEmail:boolean=false;

  selectedPartsForBulkEmailPost:partsbulkemailpost={

    partDetls:[
      {
        partNumberCommonized:"",
        plantCode:"",
        programName:"",
        supCode:""
      }
    ],
    subject:"",
    emailBody:"",
    copyMe:"",
    user:""

  }

  enableViewSelectedPartsPopup:boolean=false;


  cols_view:any;
  cols3:any;
  present_table_excp:boolean=false;
  grid_text_excp:string=""

  public cols: any[];


  selectedsuppliergsdbcode_apr:string="";
  suppliergsdbcode_apr:string="";



  display_notall_plant:boolean=true;


  view_proposal_apprv:boolean=false;


  prelimStatEnable:boolean=false;
  plantaprStatEnable:boolean=false;


  releaseForEvalEnable:boolean=false;
  releaseForProductionEnable:boolean=false;
  allAdditionalApproverEnable:boolean=false;
  emailSentUponAcceptance:boolean=false;
  cosForEmailAPI:string="";

  proposal_details_prelim:fromdiplayvalues_all;
  revision_obj:RevisionObj[]=[];

  revHistFromServiceAPI:RevHistFromAPi[]=[];

  enabledetails:boolean=false;


  enableEffectiveDateForImprovementProposal:boolean=false;
  effectiveDateForImprovementProposal:Date;
  effectiveDateInString:string=null;
  currentDate:Date;
  disableApproveButtonForRelProd:boolean=false;
  userid:string="";
  userid_apr:string;



  current_roleid:number;



  base_change:string="NA";
  selected_release_level:releaselevelList={releaselevel:"",releaselevel_desc:""};
  upload_cmms_packaging:string="NA";
  comments:string="";


  isAdditionalApproverAddedForMigrationAppStage:string="No";



  disable_base_change:boolean=true;
  disable_rejection_flow:boolean=true;
  disable_recall_flow:boolean=true;
  enable_dialog_notification_recall:boolean=false;


  releaselevel_list_apr:releaselevelList[]=[];


  exist_cntr_base:string="";
  exist_chgd_base:string="";


  exist_cntr_base_offsite:string="";
  exist_chgd_base_offsite:string="";


  roles_passed:string="";


  enable_dialog_notification:boolean=false;

  aprStatEnable:boolean=true;


  cmms_checked:boolean=false;



  apprv_status:number=0;

  //prm_final_apr:boolean=false;


  enable_dialog_notification_prm:boolean=false;


 email_list:string="";


 email_notification_eventid:string="";


 pcksgnoff_exist:string="";
 pckgsgnoff_filename:string="";
 pcksgnoff_filepath:string="";
 pcksgnoff_uploaded:boolean=false;
 upload_pcksgnoff_req:boolean=false;
 upload_pcksgnoff_display:boolean=false;


 baseurl_get_pcksgnoff:string="";


 enable_dialog_notification_upload_pcksgnoff:boolean=false;
 pcksgnoff_upload_dialog:boolean=false;


 data: string;
 region:string;
 myfiles: any = [];
 uploadedFiles: any[] = [];
 uplo: File;


 image_folder_path=""


 respdata="";


 imgsave_path="";



 pcksgnoff_doc_onrole:boolean=false;


 hostampdetails:hostamp_upload_apr={id_proposal:0,hotstamidplatecolor:"",hotstampcntrcolor:"",hotstampcntrid:"",hotstampcolor:"",hotstampcomments:"",hotstampfontcolor:"",hotstamppalletbaselidcolor:"",hotstamppalletboxcolor:""}
 stamping_blank_details:stamping_upload_apr={id_proposal:0,pressline:"",stmpdstackmthd:"",stmpwarehseloc:""}


 press_line:string="";
 stamp_destack_method:string="";
 stamp_warehouse_loc:string="";


 colorforhotstamp:colorreglist[]=[];


 selected_hotstampcntrid:string="";



 selected_hotstampcntrcolor:colorreglist={cd_Color:0,color_desc:"",cd_region:"",active:true,active_stat:"",updated_dt:"",updated_by:""}
 selected_hotstamppalletboxcolor:colorreglist={cd_Color:0,color_desc:"",cd_region:"",active:true,active_stat:"",updated_dt:"",updated_by:""}
 selected_hotstamppalletbaselidcolor:colorreglist={cd_Color:0,color_desc:"",cd_region:"",active:true,active_stat:"",updated_dt:"",updated_by:""}
 selected_hotstamidplatecolor:colorreglist={cd_Color:0,color_desc:"",cd_region:"",active:true,active_stat:"",updated_dt:"",updated_by:""}
 selected_hotstampcolor:colorreglist={cd_Color:0,color_desc:"",cd_region:"",active:true,active_stat:"",updated_dt:"",updated_by:""}
 selected_hotstampfontcolor:colorreglist={cd_Color:0,color_desc:"",cd_region:"",active:true,active_stat:"",updated_dt:"",updated_by:""}
 selected_hotstampcomments:string="";



enable_stamp_details:boolean=false;
enable_hotstamp:boolean=false;


inside_reject_fn:boolean=false;




 enable_additionalapr_aprove_button:boolean=false;


 access_additional_aprove_button:boolean=true;



 enableAdditionalApproverDialog:boolean;


 additionalApproversList:AdditionalApproversForUI[]=[];
 getAddedAddlnApprvrList:AdditionalApproversForUI[]=[];
 choosedAddlnAprovers:AdditionalApproversForUI[]=[];
 removedAddlnApprovers:AdditionalApproversForUI[]=[];

 AddedAddlnApproverForPOST:additionalApprForPost[]=[];
 AdditionalApproverToBeDeleted:string="";
 alreadyAddedApprovers:additionalApprForPost[]=[];

 cols1:any;
 cols2:any;

enableRequestTestPackApproveButton:boolean=false;
enableRequestTestPackRejectButton:boolean=false;

existing_test_pack_requested_status:string="";
existing_test_pack_received_status:string="";
existing_test_pack_evaluated_status:string="";

diable_approve_btn_rqst_tst_pack:boolean=true;
disable_reject_btn_rqst_tst_pack:boolean=true;

show_request_test_pack_button:boolean=false;
enable_radio_buttons_for_tst_pack:boolean=false;
show_request_tst_pack_pop_up:boolean=false;
request_test_pack_email_body:string="";
request_test_pack_email_subject:string="";
request_copy_me_on_email:boolean=false;
selected_proposal_plant_code:string="";


//offsite supplier

plantaprStatOffsiteEnable:boolean=false;

enableRequestTestPackOffsiteApproveButton:boolean=false;
enableRequestTestPackOffsiteRejectButton:boolean=false;

existing_test_pack_requested_status_offsite:string="";
existing_test_pack_received_status_offsite:string="";
existing_test_pack_evaluated_status_offsite:string="";

diable_approve_btn_rqst_tst_pack_offsite:boolean=true;
disable_reject_btn_rqst_tst_pack_offsite:boolean=true;

show_request_test_pack_button_offsite:boolean=false;
enable_radio_buttons_for_tst_pack_offsite:boolean=false;
show_request_tst_pack_pop_up_offsite:boolean=false;
request_test_pack_email_body_offsite:string="";
request_test_pack_email_subject_offsite:string="";
request_copy_me_on_email_offsite:boolean=false;
selected_proposal_plant_code_offsite:string="";


enable_dialog_notification_bailmentsupplier:boolean=false;
offstprtaprvd:string="";
is_bailmnt_prt_prsnt:string=""

isOffsiteAprvr:boolean=false;
partBailmentSupplierId:string="";
partBailmentPlantcode:string="";



accessBailmentSupplierbuttons:boolean=true;


//  @Output() proposalEmitter = new EventEmitter < boolean > ();


loaderapr:boolean=false;
errorLoadingData:string = "";




selectedpart_partstatus_onproposaltype:string="";


disable_pck_sgn_off_uplod:boolean=false


disable_upload_cmms:boolean=false;

enableMigrationApproveButton:boolean=false;

enableReleaseLevelOnRegionAdmin:boolean=true;


approvalStatusList:ApprovalStatus[]=[];

preliminaryApproverOrExceptionQueue:string="PreliminaryApprover";
showExceptionQueueTable:boolean=false;
apiInputs={"fromPrelim":false,"pprefix":"","pbase":"","psuffix":"","supplier":"","suppCntry":"","plant":"","prgm":"","trdngCmpny":"","partstatus":""};

    emailRejectDirectOrRejReqAccept:string="01"; 
    /*
    Note : 
    01 - direct reject
    02 - rejection request accept
    */

approverFilters:approverFiltersStored=
{
  prefix:null,
	base:null,
	suffix:null,
	plantcode:[],
	programcode:[],
	suppliercode:null,
	suppliercountry:[],
	cos:[],
	tradingcompany:[],
	partstatus:[],
	requesttestpack:null,
  userId:null
  
};
isFiltersSaved:boolean=false;
filterSessionStorageName:string="";

enableCheckBoxOnSingleCos:boolean=false;
enablecheckBoxOnPckSignoffUploadedIsNotRequired:boolean=true;
packSignOffPresentForAll:boolean=false;

enableApproveAllOnCos:boolean=true;

selectedBulkApprovalDataOnCos:allPropslDtlsBulkAppPost={

             
  gppuserid:"",         
  timestamp:"",          
  comments:"",           
  cos:"",            
  region:"",          
  cmmsuploadstat:"",          
  aprvlStg:"",
  pltidPrpsl:[] 
}

userdetails:userdetails={
  gppuserid: "",
  title: "",
  name: "",
  company: "",
  designation: "",
  email: "",
  isforduser: "",
  isactive: "",
  createddt: "",
  createdby: "",
  phone: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  postalcode: "",
  cntrycodeiso: "",
  lastlogin: "",
  fax: "",
  country: "",
  usertype: "",
 
}



  constructor(private sanitizer: DomSanitizer,private transactionService: TransactionService,private router:Router,private date:DatePipe,private route: ActivatedRoute,private messageService:MessageService) { }

  ngOnInit(): void {

    this.errorLoadingData = "";
    this.loader();
    this.currentDate=new Date();
    this.disableApproveButtonForRelProd=false;
    this.apr_cdsid=this.transactionService.getcdsid();
    this.current_region_apr=this.transactionService.getregion();
    this.current_region_code_apr=this.transactionService.getregioncode(this.current_region_apr);

    this.apr_current_roleid=this.transactionService.getcurrentrole(this.current_region_apr);
    this.roles_passed=this.apr_current_roleid.toString();

    this.userdetails=this.transactionService.getUserCdsidDetails();


    this.baseurl_get_pcksgnoff=environment.nfsBaseAPIUrl+"/downloadNonImgFile?fPath=";
    //this.imgsave_path=environment.nfsBasePath;
    this.imgsave_path="";
    //this.apr_current_roleid=3;

console.log("aprvrrole",this.apr_current_roleid);


    this.display_notall_plant=true;


    this.releaselevel_list_apr=[
      {releaselevel_desc:"1-Shipping Unit",releaselevel:"1"},
      {releaselevel_desc:"2-Level Layer",releaselevel:"2"},
      {releaselevel_desc:"3-Container/Carton",releaselevel:"3"},
      {releaselevel_desc:"4-Released (ILVS/CBR/PIA)",releaselevel:"4"},
  ]
  this.selectedstatuslist_apr=[];
  this.selectedtrdcmpy_apr=[];

  this.selectedproglist_apr=[];

 
  this.selectedplantlist_apr=[];
  this.disableProgDrp_apr=true;

  this.selectedcoslist_apr=[];

  this.approveSavedFiltersInit();



    if(this.apr_current_roleid==3)
    {
      this.prelimStatEnable=true;
      this.apprv_status=3;
      this.showExceptionQueueTable=false;
      this.preliminaryApproverOrExceptionQueue="PreliminaryApprover";

      this.filterSessionStorageName=this.current_region_code_apr+"-ApproverFilters";

      
      this.apiInputs={"fromPrelim":false,"pprefix":"","pbase":"","psuffix":"","supplier":"","suppCntry":"","plant":"","prgm":"","trdngCmpny":"","partstatus":""};


      this.enable_additionalapr_aprove_button=false;
      this.enableRequestTestPackApproveButton=false;
      this.enableRequestTestPackRejectButton=false;


      this.enableRequestTestPackOffsiteApproveButton=false;
      this.enableRequestTestPackOffsiteRejectButton=false;
      this.enableMigrationApproveButton=false;


      this.isOffsiteAprvr=false;
    }


    this.testpckreq_apr="all";


    if(this.apr_current_roleid==5)
    {
      this.plantaprStatEnable=true;
      this.apprv_status=5;
      this.pcksgnoff_doc_onrole=true;


      this.enable_additionalapr_aprove_button=false;

      this.enableRequestTestPackApproveButton=true;
      this.enableRequestTestPackRejectButton=true;

      this.filterSessionStorageName=this.current_region_code_apr+"-ApproverFilters";


      this.enableRequestTestPackOffsiteApproveButton=false;
      this.enableRequestTestPackOffsiteRejectButton=false;
      this.isOffsiteAprvr=false;

      this.enableMigrationApproveButton=false;
    }



    if(this.apr_current_roleid==4)
    {
      this.aprStatEnable=false;
      this.apprv_status=4;


      this.enable_additionalapr_aprove_button=true;
      this.enableRequestTestPackApproveButton=false;
      this.enableRequestTestPackRejectButton=false;


      this.enableRequestTestPackOffsiteApproveButton=false;
      this.enableRequestTestPackOffsiteRejectButton=false;
      this.isOffsiteAprvr=false;

      this.enableMigrationApproveButton=false;

      this.filterSessionStorageName=this.current_region_code_apr+"-ApproverFilters";
    }


    if(this.apr_current_roleid==6)
    {

      this.apprv_status=6;
      this.pcksgnoff_doc_onrole=true;


      this.enable_additionalapr_aprove_button=false;
      this.enableRequestTestPackApproveButton=false;
      this.enableRequestTestPackRejectButton=false;


      this.enableRequestTestPackOffsiteApproveButton=false;
      this.enableRequestTestPackOffsiteRejectButton=false;
      this.isOffsiteAprvr=false;

      this.enableMigrationApproveButton=false;

      this.filterSessionStorageName=this.current_region_code_apr+"-ApproverFilters";
    }


    if(this.apr_current_roleid==9)
    {

      this.apprv_status=7;
      this.pcksgnoff_doc_onrole=true;
      this.releaseForEvalEnable=true;





      this.enable_additionalapr_aprove_button=false;
      this.enableRequestTestPackApproveButton=false;
      this.enableRequestTestPackRejectButton=false;


      this.enableRequestTestPackOffsiteApproveButton=false;
      this.enableRequestTestPackOffsiteRejectButton=false;
      this.isOffsiteAprvr=false;

      this.enableMigrationApproveButton=false;

      this.filterSessionStorageName=this.current_region_code_apr+"-ApproverFilters";
    }

    if(this.apr_current_roleid==11)
    {

      this.apprv_status=8;

      this.releaseForProductionEnable=true;
      this.aprStatEnable=false;
      this.enable_additionalapr_aprove_button=false;
      this.enableRequestTestPackApproveButton=false;
      this.enableRequestTestPackRejectButton=false;


      this.enableRequestTestPackOffsiteApproveButton=false;
      this.enableRequestTestPackOffsiteRejectButton=false;
      this.isOffsiteAprvr=false;

      this.enableMigrationApproveButton=false;

      this.filterSessionStorageName=this.current_region_code_apr+"-ApproverFilters";
    }

    if(this.apr_current_roleid==19)
    {

      this.apprv_status=16;
     /* this.aprStatEnable=false;
      this.plantaprStatOffsiteEnable=true;
      this.enableRequestTestPackOffsiteApproveButton=true;
      this.enableRequestTestPackOffsiteRejectButton=true;
      this.enable_additionalapr_aprove_button=false;
      this.enableRequestTestPackApproveButton=false;
      this.enableRequestTestPackRejectButton=false
      this.isOffsiteAprvr=true;*/


      this.plantaprStatEnable=true;

      this.pcksgnoff_doc_onrole=true;


      this.enable_additionalapr_aprove_button=false;

      this.enableRequestTestPackApproveButton=true;
      this.enableRequestTestPackRejectButton=true;


      this.enableRequestTestPackOffsiteApproveButton=false;
      this.enableRequestTestPackOffsiteRejectButton=false;

      this.enableMigrationApproveButton=false;

      this.filterSessionStorageName=this.current_region_code_apr+"-ApproverFilters";
    }

    if(this.apr_current_roleid==20)
    {

      this.apprv_status=17;


    /*  this.aprStatEnable=false;
      this.plantaprStatOffsiteEnable=false;
      this.enableRequestTestPackOffsiteApproveButton=false;
      this.enableRequestTestPackOffsiteRejectButton=false;
      this.enable_additionalapr_aprove_button=false;
      this.enableRequestTestPackApproveButton=false;
      this.enableRequestTestPackRejectButton=false
      this.isOffsiteAprvr=true;
      */
     this.pcksgnoff_doc_onrole=true;


     this.enable_additionalapr_aprove_button=false;
     this.enableRequestTestPackApproveButton=false;
     this.enableRequestTestPackRejectButton=false;


     this.enableRequestTestPackOffsiteApproveButton=false;
     this.enableRequestTestPackOffsiteRejectButton=false;

     this.enableMigrationApproveButton=false;

     this.filterSessionStorageName=this.current_region_code_apr+"-ApproverFilters";
    }


    if(this.apr_current_roleid==21)
    {
      this.prelimStatEnable=false;
      this.apprv_status=19;
//19
      //this.aprStatEnable=false; // allowing to add additional approver so default value true will be taken
      this.enable_additionalapr_aprove_button=false;
      this.enableRequestTestPackApproveButton=false;
      this.enableRequestTestPackRejectButton=false;


      this.enableRequestTestPackOffsiteApproveButton=false;
      this.enableRequestTestPackOffsiteRejectButton=false;

      this.enableMigrationApproveButton=true;

      this.isOffsiteAprvr=false;

      this.filterSessionStorageName=this.current_region_code_apr+"-ApproverFilters";
    }

    if(this.apr_current_roleid==22) //For All Additional Approver SCreen
    {
      this.allAdditionalApproverEnable=true;
      this.aprStatEnable=false;

      //this.enable_additionalapr_aprove_button=false;
      this.enableRequestTestPackApproveButton=false;
      this.enableRequestTestPackRejectButton=false;


      this.enableRequestTestPackOffsiteApproveButton=false;
      this.enableRequestTestPackOffsiteRejectButton=false;
      this.isOffsiteAprvr=false;

      //this.enableMigrationApproveButton=false;

      this.filterSessionStorageName=this.current_region_code_apr+"-ApproverFilters";
    }

    
    if(sessionStorage.getItem(this.filterSessionStorageName) != null)
    {
    this.approverFilters=JSON.parse(sessionStorage.getItem(this.filterSessionStorageName));
    console.log(this.approverFilters);
    this.isFiltersSaved=true;
    }


    this.transactionService.getapprovalstatuslist().subscribe(approvalList=>{

      console.log("Approver List",approvalList)
      this.approvalStatusList=approvalList

    })

    this.transactionService.getsupplier_country_list().subscribe(supp_country_list=>{
      console.log(supp_country_list)
      var supp_arr=[];
      this.suppliercountryList_apr=[];
      supp_arr=supp_country_list;
      this.suppliercountryList_apr.push({cd_country:"all",cd_country_iso:"all",code_name:"Select all",country_name:"all"})
      this.suppliercountryList_apr.push(...supp_arr);
      if(this.suppliercountryList_apr!=null && this.suppliercountryList_apr.length!=0)
      {
        for(var s=1;s<this.suppliercountryList_apr.length;s++)
        {
          if(this.suppliercountryList_apr[s].cd_country_iso!=null)
          {
      this.suppliercountryList_apr[s].code_name=this.suppliercountryList_apr[s].cd_country_iso+'-'+this.suppliercountryList_apr[s].country_name
          }
          else
          {
            this.suppliercountryList_apr[s].code_name=this.suppliercountryList_apr[s].country_name;

          }
        }
      }
    }
    )







  this.transactionService.getpartstatus("approver").subscribe(apr_status=>{


    console.log(apr_status);
    this.statusList_apr=[];
    this.statusList_apr.push({status_id:0,status_desc:"all",status:"all",functions:"approver"});
   // this.statusList=sub_status;
   this.statusList_apr.push(...apr_status);
   /* for(var i=0;i<sub_status.length;i++)
    {
      if(sub_status[i].status!="Submitted")
      {
      this.statusList_apr.push(sub_status[i]);
      }
    }*/





this.transactionService.gettradingcompany().subscribe(apr_tradcmpy=>{


  console.log(apr_tradcmpy);

  this.tradCmpyList_apr=[];
  this.tradCmpyList_apr.push({tradingcogsdbcode:"all"})
  this.tradCmpyList_apr.push({tradingcogsdbcode:"[No Trading Company]"})
  for(var trd=0;trd<apr_tradcmpy.length;trd++)
  {
  this.tradCmpyList_apr.push(apr_tradcmpy[trd]);
  }

console.log("trad",this.tradCmpyList_apr)
  


})





this.transactionService.getOwnershipStrategyCode(this.current_region_code_apr).subscribe(apr_coslist=>{

  console.log(apr_coslist);
  this.subCosList_apr=apr_coslist
  this.cosList_apr=[];
  this.cosList_apr.push({cos:"all",cos_name:"Select All",cd_cos:0});
  if(this.subCosList_apr.length!=0)
  {
  for(var icos=0;icos<this.subCosList_apr.length;icos++)
  {
  this.cosList_apr.push({cos:this.subCosList_apr[icos].ownrshp_strat_code,cos_name:this.subCosList_apr[icos].ownrshp_desc,cd_cos:this.subCosList_apr[icos].cd_ownrshp_strat});
  }
}

 })



this.list_plantcode_apr();




  
  this.selectedPartsToApprove=[];
})

if(!this.isFiltersSaved)
{
  this.defaultGridAPICall();
}

 

  }


  defaultGridAPICall()
  {
    if(this.apprv_status!=3)
    {
      this.roles_passed=this.apprv_status.toString();
    }
    else
    {
    this.roles_passed="3,13,14";
    }
  
  
    console.log("role",this.roles_passed);
  
  
    if(this.apr_current_roleid==4)
    {
  
  
      this.transactionService.getApprGrid_addnlaprv(this.apr_cdsid,this.current_region_code_apr).subscribe(apr_grid_addnl=>{
  
        this.apprvrGridList=[];
        console.log(apr_grid_addnl);
    
    
        this.apprvrGridList=apr_grid_addnl;
        this.approver_grid_output();
        
      },
      (error) => {                              //Error callback
        console.error('Error in Additional approver list API call - getApprGrid_addnlaprv',error)
        this.errorLoadingData = "Error Occured, Please Try Again";
        this.loaderapr=false;
    
      }
      )
  
    }
  
  
  // offsite supplier 
    else if(this.apr_current_roleid==19 || this.apr_current_roleid == 20)
    {
      //api to get bailment spplier approver 
  
  
      this.transactionService.getApprGridOffsite(this.apr_cdsid,this.roles_passed,this.current_region_code_apr,this.apr_current_roleid).subscribe(apr_grid_offsite=>{
  
        console.log("grid",apr_grid_offsite);
    
    
        this.apprvrGridList=apr_grid_offsite;
        this.approver_grid_output();
        
      },
      (error) => {                              //Error callback
        console.error('Error in Offsite approver list API call - getApprGridOffsite',error)
        this.errorLoadingData = "Error Occured, Please Try Again";
        this.loaderapr=false;
    
      }
      )
    
  
  
  
      console.log("inside")
    }
    else if(this.apr_current_roleid==22) //For All Additional Approver screen
    {
       this.transactionService.loadAllAddlnApprvrWithoutFilters(this.current_region_code_apr).subscribe(all_adlm_grid=>{
        console.log("Approver List without filter - INIT",this.apprvrGridList) 
        this.apprvrGridList=all_adlm_grid;
         this.approver_grid_output();
       },
       (error) => {                              //Error callback
        console.error('Error in All additional approver list API call - loadAllAddlnApprvrWithoutFilters',error)
        this.errorLoadingData = "Error Occured, Please Try Again";
        this.loaderapr=false;
    
      }
      )
    }
  
    else{
  
      var role_id=0
      role_id=this.apr_current_roleid
      if(this.apr_current_roleid == 21) //For Migration Approval screen
      {
        role_id=3
      }
  
      
      
    this.transactionService.getApprGrid(this.apr_cdsid,this.roles_passed,this.current_region_code_apr,role_id).subscribe(apr_grid=>{
  
      console.log("grid",apr_grid);
      console.log(this.apr_cdsid,this.roles_passed,this.current_region_code_apr,role_id)
  
      this.apprvrGridList=apr_grid;
      this.approver_grid_output();
      
    },
    (error) => {                              //Error callback
      console.error('Error in Approver list API call - getApprGrid',error)
      this.errorLoadingData = "Error Occured, Please Try Again";
      this.loaderapr=false;
  
    }
    )
  
  
  }
  }

  approveSavedFiltersInit()
  {
    this.approverFilters=
    {
      prefix:null,
      base:null,
      suffix:null,
      plantcode:[],
      programcode:[],
      suppliercode:null,
      suppliercountry:[],
      cos:[],
      tradingcompany:[],
      partstatus:[],
      requesttestpack:null,
      userId:null
      
    };
  this.isFiltersSaved=false;
  this.filterSessionStorageName="";
  }
  makePrlimButtonActive()
  {
    if(this.preliminaryApproverOrExceptionQueue=="PreliminaryApprover")
    {
      return "active";
    }
  }

  makeExcepButtonActive()
  {
    if(this.preliminaryApproverOrExceptionQueue=="ExecptionQueue")
    {
      return "active";
    }
  }

changeInPreliminaryApproverOrExceptionQueue(buttonClicked:string)
{
  this.preliminaryApproverOrExceptionQueue=buttonClicked;
  if(this.prelimStatEnable)
  {
    if(this.preliminaryApproverOrExceptionQueue=="ExecptionQueue")
    {
      //background-color:#1a252f;border-color:#151e27
      this.showExceptionQueueTable=true;
      //this.clearApproverFilterValues();

      this.approveSavedFiltersInit();
      //this.filterSessionStorageName=this.current_region_code_apr+"-ExceptionQueueFilters"; 
      this.filterSessionStorageName=this.current_region_code_apr+"-ApproverFilters";

      this.transactionService.getpartstatus("Submitter").subscribe(apr_status=>{
        console.log(apr_status);
        this.statusList_apr=[];
        this.statusList_apr.push({status_id:0,status_desc:"all",status:"all",functions:"approver"});
        this.statusList_apr.push(...apr_status);
         //this.selectedstatuslist_apr=[];
       })
    }
    else
    {
      this.showExceptionQueueTable=false;

      this.transactionService.getpartstatus("approver").subscribe(apr_status=>{
        console.log(apr_status);
        this.statusList_apr=[];
        this.statusList_apr.push({status_id:0,status_desc:"all",status:"all",functions:"approver"});
        this.statusList_apr.push(...apr_status);
         //this.selectedstatuslist_apr=[];
       })
       //this.clearApproverFilterValues();

       this.approveSavedFiltersInit();
      this.filterSessionStorageName=this.current_region_code_apr+"-ApproverFilters";
    
       


    }


    this.clearFiltersToggleBetweenPrelimAppExceptQueue();
       
    if(sessionStorage.getItem(this.filterSessionStorageName) != null)
    {
    this.approverFilters=JSON.parse(sessionStorage.getItem(this.filterSessionStorageName));
    this.isFiltersSaved=true;
    }

    this.loadSavedFilterDataApplyFilter();

  }
}

clearFiltersToggleBetweenPrelimAppExceptQueue()
{
  this.selectedpartprefix_apr="";
  this.selectedpartbase_apr="";
  this.selectedpartsuffix_apr="";
  this.selectedplantlist_apr=[];
  this.disableProgDrp_apr=true;
  this.selectedproglist_apr=[];
  this.selectedcoslist_apr=[];
  this.selectedtrdcmpy_apr=[];
  this.selectedstatuslist_apr=[];
  this.selectedsuppliergsdbcode_apr="";
  this.selected_suppliercountry_apr=[];
  this.userid="";
  this.testpckreq_apr="all";
  this.errorLoadingData = "";

  this.selectedFromExceptionQueue=[];
}
  
transformYourHtml(htmlTextWithStyle) {
  return this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle);
}
 HTMLEncode(str) {
  return str.replace(/&/g, "&amp;").replace(/>/g, "&gt;").replace(/</g, "&lt;").replace(/"/g, "&quot;").replace(/~/g,"&tilde");
}


loader()
{
  /*if(document.getElementById("overlayallaprinit") != null )
    {
    document.getElementById("overlayallaprinit").style.display = "block";
    }
    if(document.getElementById("loaderallaprinit") != null )
    {
    document.getElementById("loaderallaprinit").style.display = "block";
    }*/


    this.loaderapr=true;
}



  list_plantcode_apr()
  {

    console.log("get plant list")

var apr_allplants=[]
  this.transactionService.getactiveplantslist(this.current_region_code_apr).subscribe(apr_allplants=>{
      console.log("sub_useracces",apr_allplants);
     var position:number;
       this.subAllPlants_apr=apr_allplants;
       this.plantCodeList_apr=[];
       this.progList_apr=[];



       //this.plantCodeList.push({plantcode:"all",plant:"[ALL]"});

       this.plantCodeList_apr.push({plantcode:"all",plant:"Select All"})

       if(this.subAllPlants_apr.length!=0)
       {
       for(var temp=0;temp<this.subAllPlants_apr.length;temp++)
       {
         //console.log(this.subAllPlants_apr[temp]);
         var plt_name:string;
         plt_name=this.subAllPlants_apr[temp].plantGSDBCode+"-"+this.subAllPlants_apr[temp].plant_name;

          this.plantCodeList_apr.push({plantcode:this.subAllPlants_apr[temp].plantGSDBCode,plant:plt_name})

       }
      }


       console.log(this.plantCodeList_apr);







     //service to get trading company
     //assign slected trading company full list of trading companies;
   
   
 
 
     this.selectedproglist_apr=[];

 
     this.selectedplantlist_apr=[];
     this.disableProgDrp_apr=true;
 
 //this.selectedproglist=this.progList;
 //this.selectedcoslist=this.cosList;

 //this.selectedplantlist=this.plantCodeList;
 //this.disableProgDrp=false;
 
      
 if(this.isFiltersSaved)
 {
   this.loadSavedFilterDataApplyFilter();
 }

 
 
 //this.apply_filter_apr();
 
           
         
     })

  }

  loadSavedFilterDataApplyFilter()
  {
    console.log(this.approverFilters)
    if(this.approverFilters.prefix != null && this.approverFilters.prefix.trim() != "")
    {
      this.selectedpartprefix_apr=this.approverFilters.prefix;

    }
    else{
      this.selectedpartprefix_apr=""
    }

    if(this.approverFilters.base != null && this.approverFilters.base.trim() != "")
    {
      this.selectedpartbase_apr=this.approverFilters.base

    }
    else{
      this.selectedpartbase_apr=""
    }



    if(this.approverFilters.suffix != null && this.approverFilters.suffix.trim() != "")
    {
      this.selectedpartsuffix_apr=this.approverFilters.suffix

    }
    else{
      this.selectedpartsuffix_apr=""
    }


    if(this.approverFilters.plantcode.length!=0)
    {

      this.selectedplantlist_apr=[];
      this.selectedplantlist_apr.push(...this.approverFilters.plantcode);
      this.onchange_plant();
    }

    else{

      this.selectedplantlist_apr=[];
      this.selectedproglist_apr=[];
      this.disableProgDrp_apr=true;

    }




    //program

    if(this.approverFilters.programcode.length!=0)
    {
          
      this.selectedproglist_apr=[];
      this.selectedproglist_apr.push(...this.approverFilters.programcode);
    }

    else{
      this.selectedproglist_apr=[];
      
    }



    //cos

    if(this.approverFilters.cos.length!=0)
    {
      this.selectedcoslist_apr=[];
      this.selectedcoslist_apr.push(...this.approverFilters.cos);
    }

    else{
      this.selectedcoslist_apr=[]; 
    }


    // trading company

    if(this.approverFilters.tradingcompany.length!=0)
    {
      this.selectedtrdcmpy_apr=[];
      this.selectedtrdcmpy_apr.push(...this.approverFilters.tradingcompany);
    }

    else{
      this.selectedtrdcmpy_apr=[]
      
    }

    
//status



if(this.approverFilters.partstatus.length!=0)
{  
  this.selectedstatuslist_apr=[];
  this.selectedstatuslist_apr.push(...this.approverFilters.partstatus);
}

else{


  this.selectedstatuslist_apr=[];
  
}


if(this.approverFilters.suppliercode != null && this.approverFilters.suppliercode.trim() != "")

  {
    this.selectedsuppliergsdbcode_apr=this.approverFilters.suppliercode;
  }

  else{
    this.selectedsuppliergsdbcode_apr=""
  }

  if(this.approverFilters.userId != null && this.approverFilters.userId.trim() != "")

  {
    this.userid=this.approverFilters.userId;
  }

  else{
    this.userid=""
  }


  if(this.approverFilters.suppliercountry.length!=0)
{
  this.selected_suppliercountry_apr=[];
  this.selected_suppliercountry_apr.push(...this.approverFilters.suppliercountry);
}
else{

  this.selected_suppliercountry_apr=[];

}

if(this.approverFilters.requesttestpack != null && this.approverFilters.requesttestpack.trim() != "")
{
  this.testpckreq_apr=this.approverFilters.requesttestpack;
}
else
{
  this.testpckreq_apr="all"
}

this.apply_filter_apr();

  }
  
  onchange_plant()
  {


    console.log(this.selectedplantlist_apr);

if(this.selectedplantlist_apr.length!=0)
{
  this.disableProgDrp_apr=false;


  var plantcodes_selected_commasep_apr:string="";
  plantcodes_selected_commasep_apr=this.selectedplantlist_apr[0].plantcode;



  if(this.selectedplantlist_apr.length>1)
  {
  for(var iconv=1;iconv<this.selectedplantlist_apr.length;iconv++)
  {

    plantcodes_selected_commasep_apr+=",";
    plantcodes_selected_commasep_apr+=this.selectedplantlist_apr[iconv].plantcode;

  }
}


console.log(plantcodes_selected_commasep_apr);

var prog_output=[];
this.transactionService.getprogmlistbasedonplantgsdb(plantcodes_selected_commasep_apr,this.current_region_code_apr).subscribe(prog_output=>{

  console.log(prog_output);
  this.progList_apr=[];
  this.progListOnPlt_apr=prog_output
  if(this.progListOnPlt_apr.length!=0)
  {
    var prog_plt:string="";


    this.progList_apr.push({programcode:"all",programdisplay:"Select All"})
    for(var prg=0; prg<this.progListOnPlt_apr.length;prg++)
    {


      var present_prg=false;
      prog_plt="";
      for(var kprg=1;kprg<this.progList_apr.length;kprg++)
      {
             if(this.progListOnPlt_apr[prg].programname.toLowerCase()==this.progList_apr[kprg].programcode.toLowerCase() )
              {

               this.progList_apr[kprg].programdisplay= this.progList_apr[kprg].programdisplay+",("+this.progListOnPlt_apr[prg].plantgsdbcode+")";

                   present_prg=true;
                                      break;
              }
       }


    if(present_prg==false)
            {
              prog_plt=this.progListOnPlt_apr[prg].programname+"("+this.progListOnPlt_apr[prg].plantgsdbcode+")";

      this.progList_apr.push({programcode: this.progListOnPlt_apr[prg].programname,programdisplay:prog_plt})

                }



    }

  }


  console.log(this.selectedproglist_apr);


  if(this.progList_apr.length!=0 && this.selectedproglist_apr.length!=0)
{
  var selprgarr=[];
  console.log(this.selectedproglist_apr);
  console.log(this.progList_apr)
  var prog_plt:string="";
          for(var iprg=0;iprg<this.progList_apr.length;iprg++)
          {
            console.log(this.progList_apr[iprg])
          for(var jprg=0;jprg<this.selectedproglist_apr.length;jprg++)
          {
            console.log(this.selectedproglist_apr[jprg])

            if(this.progList_apr[iprg].programcode.toLowerCase()==this.selectedproglist_apr[jprg].programcode.toLowerCase())
            {
              this.selectedproglist_apr[jprg].programdisplay=this.progList_apr[iprg].programdisplay
              selprgarr.push(this.selectedproglist_apr[jprg]);
              console.log(selprgarr)
              break;
            }
          }

          }




          this.selectedproglist_apr=selprgarr;

          console.log(this.selectedproglist_apr)
}


else if(this.progList_apr.length==0)
{
  this.selectedproglist_apr=[];
}


})

//trading cmpny



if(this.selectedplantlist_apr[0].plantcode.toLowerCase()=="all")
{
  this.transactionService.gettradingcompany().subscribe(apr_tradcmpy=>{


    console.log("trada",apr_tradcmpy);

    this.tradCmpyList_apr=[];
    this.tradCmpyList_apr.push({tradingcogsdbcode:"all"})
    this.tradCmpyList_apr.push({tradingcogsdbcode:"[No Trading Company]"})
    for(var trd=0;trd<apr_tradcmpy.length;trd++)
    {
    this.tradCmpyList_apr.push(apr_tradcmpy[trd]);
    }

  console.log("trad",this.tradCmpyList_apr)



this.changing_of_trad()



})


}


else{

  this.transactionService.getTradingCompaniesBasedOnPlantGSDBCode(plantcodes_selected_commasep_apr).subscribe(trad_output=>{


    console.log(trad_output);


    console.log("trad1",trad_output);

    this.tradCmpyList_apr=[];
    this.tradCmpyList_apr.push({tradingcogsdbcode:"all"})
    this.tradCmpyList_apr.push({tradingcogsdbcode:"[No Trading Company]"})
    for(var trd=0;trd<trad_output.length;trd++)
    {
    this.tradCmpyList_apr.push(trad_output[trd]);
    }

  console.log("trad",this.tradCmpyList_apr)


  this.changing_of_trad();


   // this.selectedtrdcmpy_apr=[];


  })
}

}

else{
  this.disableProgDrp_apr=true;
  this.selectedplantlist_apr=[];
 // this.selectedcoslist=[];
  this.selectedproglist_apr=[];


}


  }



  changing_of_trad()
  {
    if(this.tradCmpyList_apr.length!=0 && this.selectedtrdcmpy_apr.length!=0)
    {
      var seltradarr=[];
      console.log(this.selectedtrdcmpy_apr);
      console.log(this.tradCmpyList_apr)
      var prog_plt:string="";
              for(var itrd=0;itrd<this.tradCmpyList_apr.length;itrd++)
              {
                console.log(this.tradCmpyList_apr[itrd])
              for(var jtrd=0;jtrd<this.selectedtrdcmpy_apr.length;jtrd++)
              {
                console.log(this.selectedtrdcmpy_apr[jtrd])

                if(this.tradCmpyList_apr[itrd].tradingcogsdbcode.toLowerCase()==this.selectedtrdcmpy_apr[jtrd].tradingcogsdbcode.toLowerCase())
                {
                  seltradarr.push(this.selectedtrdcmpy_apr[jtrd]);
                  console.log(seltradarr)
                  break;
                }
              }

              }




              this.selectedtrdcmpy_apr=seltradarr;

              console.log(this.selectedtrdcmpy_apr)
    }


    else if(this.tradCmpyList_apr.length==0)
    {
      this.selectedtrdcmpy_apr=[];
    }


  }






  plant_check()
  {


    console.log("selectedplant",this.selectedplantlist_apr)
    var all_present:boolean=false;
    for(var i=0;i<this.selectedplantlist_apr.length;i++)
    {
      if(this.selectedplantlist_apr[i].plantcode=="all")
      {all_present=true;
        break;

      }
    }


    if(all_present==true)
    {


      this.selectedplantlist_apr=[];
      this.selectedplantlist_apr.push({plantcode:"all",plant:"Select All"})
    }


    else if(all_present==false && this.selectedplantlist_apr.length==this.plantCodeList_apr.length-1)
    {

      this.selectedplantlist_apr=[];
      this.selectedplantlist_apr.push({plantcode:"all",plant:"Select All"})

    }


/*
    if(all_present==true && this.selectedplantlist_apr.length==this.plantCodeList_apr.length-1)
    {

       console.log(this.selectedplantlist_apr);
       this.display_notall_plant=true;


       var index;
       index=this.selectedplantlist_apr.findIndex((object) => {
        return object.plantcode === "all";
      });


      if(index!=-1)
      {
        this.selectedplantlist_apr.splice(index,1);
      }

    }



    else if(all_present==true)
    {


      this.selectedproglist_apr=[];
     // this.selectedplantlist_apr.push({plantcode:"all",plant:"Select All"})
     this.selectedplantlist_apr.push(...this.plantCodeList_apr)
    }


    else if(all_present==false && this.selectedplantlist_apr.length==this.plantCodeList_apr.length-1)
    {


      this.selectedproglist_apr=[];
      this.selectedplantlist_apr.push({plantcode:"all",plant:"Select All"})

    }

*/
  /*  if(all_present==true && this.selectedplantlist_apr.length==this.plantCodeList_apr.length-1)
    {

       console.log(this.selectedplantlist_apr);
       this.display_notall_plant=true;


       var index;
       index=this.selectedplantlist_apr.findIndex((object) => {
        return object.plantcode === "all";
      });


      if(index!=-1)
      {
        this.selectedplantlist_apr.splice(index,1);
      }

    }


    else if(all_present==false && this.selectedplantlist_apr.length==this.plantCodeList_apr.length-1)
    {


      this.selectedplantlist_apr=[];
      this.selectedplantlist_apr.push(...this.plantCodeList_apr);


      this.display_notall_plant=false;

    }


    else if(all_present==true)
    {



      this.selectedplantlist_apr=[];
      this.selectedplantlist_apr.push(...this.plantCodeList_apr);



      this.display_notall_plant=false;

    }


    else if(all_present==false)
    {
      this.display_notall_plant=true;
    }*/


  }


  program_check()
  {


    console.log("selectedprg",this.selectedproglist_apr)
    var all_present_prg:boolean=false;
    for(var i=0;i<this.selectedproglist_apr.length;i++)
    {
      if(this.selectedproglist_apr[i].programcode=="all")
      {all_present_prg=true;
        break;

      }
    }


    if(all_present_prg==true)
    {


      this.selectedproglist_apr=[];
      this.selectedproglist_apr.push({programcode:"all",programdisplay:"Select All"})
    }


    else if(all_present_prg==false && this.selectedproglist_apr.length==this.progList_apr.length-1)
    {


      this.selectedproglist_apr=[];
      this.selectedproglist_apr.push({programcode:"all",programdisplay:"Select All"})

    }

  }


  supplier_country_check()
  {


    console.log("selectedsup",this.selected_suppliercountry_apr)
    var all_present_sup:boolean=false;
    for(var i=0;i<this.selected_suppliercountry_apr.length;i++)
    {
      if(this.selected_suppliercountry_apr[i].cd_country.toLowerCase()=="all")
      {all_present_sup=true;
        break;

      }
    }


    if(all_present_sup==true)
    {


      this.selected_suppliercountry_apr=[];
      this.selected_suppliercountry_apr.push({cd_country:"all",cd_country_iso:"all",code_name:"Select all",country_name:"all"})
    }


    else if(all_present_sup==false && this.selected_suppliercountry_apr.length==this.suppliercountryList_apr.length-1)
    {


      this.selected_suppliercountry_apr=[];
      this.selected_suppliercountry_apr.push({cd_country:"all",cd_country_iso:"all",code_name:"Select all",country_name:"all"})
    }

  }


  tradcmpy_check()
  {


    console.log("selectedtrad",this.selectedtrdcmpy_apr)
    var all_present_trd:boolean=false;
    for(var i=0;i<this.selectedtrdcmpy_apr.length;i++)
    {
      if(this.selectedtrdcmpy_apr[i].tradingcogsdbcode.toLowerCase()=="all")
      {all_present_trd=true;
        break;

      }
    }


    if(all_present_trd==true)
    {


      this.selectedtrdcmpy_apr=[];
      this.selectedtrdcmpy_apr.push({tradingcogsdbcode:"all"})
    }


    else if(all_present_trd==false && this.selectedtrdcmpy_apr.length==this.tradCmpyList_apr.length-1)
    {


      this.selectedtrdcmpy_apr=[];
      this.selectedtrdcmpy_apr.push({tradingcogsdbcode:"all"})
    }




  }


  cos_check()
  {


    console.log("selectedcos",this.selectedcoslist_apr)
    var all_present_cos:boolean=false;
    for(var i=0;i<this.selectedcoslist_apr.length;i++)
    {
      if(this.selectedcoslist_apr[i].cos.toLowerCase()=="all")
      {all_present_cos=true;
        break;

      }
    }


    if(all_present_cos==true)
    {


      this.selectedcoslist_apr=[];
      this.selectedcoslist_apr.push({cos:"all",cos_name:"Select All",cd_cos:0});
    }


    else if(all_present_cos==false && this.selectedcoslist_apr.length==this.cosList_apr.length-1)
    {


      this.selectedcoslist_apr=[];
      this.selectedcoslist_apr.push({cos:"all",cos_name:"Select All",cd_cos:0});
    }




  }


  status_check()
  {


    console.log("selectedstatus",this.selectedstatuslist_apr)
    var all_present_sts:boolean=false;
    for(var i=0;i<this.selectedstatuslist_apr.length;i++)
    {
      if(this.selectedstatuslist_apr[i].status.toLowerCase()=="all")
      {all_present_sts=true;
        break;

      }
    }


    if(all_present_sts==true)
    {


      this.selectedstatuslist_apr=[];
      this.selectedstatuslist_apr.push({status_id:0,status_desc:"all",status:"all",functions:"approver"});
    }


    else if(all_present_sts==false && this.selectedstatuslist_apr.length==this.statusList_apr.length-1)
    {


      this.selectedstatuslist_apr=[];
      this.selectedstatuslist_apr.push({status_id:0,status_desc:"all",status:"all",functions:"approver"});
    }


  }








  apply_filter_apr()
  {

    this.errorLoadingData = "";
    this.present_table=false;
    this.present_table_excp=false;
    this.grid_text="";
    this.grid_text_excp="";
    this.apprvrGridList=[];
    this.exceptionQueueGrid=[];
    this.selectedPartsToApprove=[];
    this.selectedFromExceptionQueue=[];

    this.enablecheckBoxOnPckSignoffUploadedIsNotRequired=true;
    this.enableCheckBoxOnSingleCos=false;
  /*  if(document.getElementById("overlayallapr") != null )
    {
    document.getElementById("overlayallapr").style.display = "block";
    }
    if(document.getElementById("loaderallapr") != null )
    {
    document.getElementById("loaderallapr").style.display = "block";
    }*/


    this.loaderapr=true;


    this.grid_text="";


    console.log("insideapplyfilter")

    console.log(this.selectedpartprefix_apr);
    console.log(this.selectedpartbase_apr);
    console.log(this.selectedpartsuffix_apr);
    console.log(this.selectedplantlist_apr);
    if(this.selectedpartprefix_apr!=null && this.selectedpartprefix_apr!="")
    {
      this.part_prefix_apr=this.selectedpartprefix_apr

    }
    else{
      this.part_prefix_apr="all"
    }

    if(this.selectedpartbase_apr!=null && this.selectedpartbase_apr!="")
    {
      this.part_base_apr=this.selectedpartbase_apr

    }
    else{
      this.part_base_apr="all"
    }



    if(this.selectedpartsuffix_apr!=null && this.selectedpartsuffix_apr!="")
    {
      this.part_suffix_apr=this.selectedpartsuffix_apr

    }
    else{
      this.part_suffix_apr="all"
    }


    if(this.selectedplantlist_apr.length!=0)
    {

      console.log("inside")


              this.plantcode_apr="";
              var plt_count=0;
              for(var p_plt=0;p_plt<this.selectedplantlist_apr.length;p_plt++)
              {
                 this.plantcode_apr+=this.selectedplantlist_apr[p_plt].plantcode
                 plt_count+=1;
                 if(plt_count!=this.selectedplantlist_apr.length)
                 {
                   this.plantcode_apr+=",";
                 }
              }

            console.log(this.plantcode_apr);

    }

    else{

      this.plantcode_apr="all";

    }




    //program

    if(this.selectedproglist_apr.length!=0)
    {


              this.programcode_apr="";
              var prg_count=0;
              for(var p_prg=0;p_prg<this.selectedproglist_apr.length;p_prg++)
              {
                 this.programcode_apr+=this.selectedproglist_apr[p_prg].programcode
                 prg_count+=1;
                 if(prg_count!=this.selectedproglist_apr.length)
                 {
                   this.programcode_apr+=",";
                 }
              }





            console.log(this.programcode_apr);

    }

    else{
      this.programcode_apr="all";

    }



    //cos

    if(this.selectedcoslist_apr.length!=0)
    {


              this.cos_apr="";
              var cos_count=0;
              for(var p_cos=0;p_cos<this.selectedcoslist_apr.length;p_cos++)
              {
                 this.cos_apr+=this.selectedcoslist_apr[p_cos].cos
                 cos_count+=1;
                 if(cos_count!=this.selectedcoslist_apr.length)
                 {
                   this.cos_apr+=",";
                 }
              }





            console.log(this.cos_apr);

    }

    else{
      this.cos_apr="all";
    }

    if(this.selectedcoslist_apr.length != 0 &&  this.selectedcoslist_apr.length == 1 && this.selectedcoslist_apr[0].cos.toLowerCase() != "all")
    {
      console.log(this.selectedcoslist_apr[0].cos);
      var selectedCos=this.selectedcoslist_apr[0].cos;
      var selectedCosCode=this.selectedcoslist_apr[0].cd_cos;

      if(this.apr_current_roleid == 9 || this.apr_current_roleid == 11)
      {
        this.enableCheckBoxOnSingleCos = true;
        this.enablecheckBoxOnPckSignoffUploadedIsNotRequired = true;
      }
      if(this.apr_current_roleid == 6)
      {
        this.enableCheckBoxOnSingleCos = true;
        this.transactionService.getcosdetails(selectedCos,selectedCosCode).subscribe(cosDetails=>{
          console.log("getcosdetails selected cos on apply filter",cosDetails);

          if(cosDetails != null)
          {

            if(cosDetails.pkg_sign_off.toLowerCase() == "required")
            {
              this.enablecheckBoxOnPckSignoffUploadedIsNotRequired = false;
            }
            else
            {
              this.enablecheckBoxOnPckSignoffUploadedIsNotRequired = true;
            }
          }

        })
      }
      


    }



    // trading company

    if(this.selectedtrdcmpy_apr.length!=0)
    {


              this.trdcmpy_apr="";
              var trdcmpy_count=0;

              for(var p_trdcmpy=0;p_trdcmpy<this.selectedtrdcmpy_apr.length;p_trdcmpy++)
              {
                if(this.selectedtrdcmpy_apr[p_trdcmpy].tradingcogsdbcode=="[No Trading Company]")
                {
                  this.trdcmpy_apr+="notradco"
                }
                else
                {
                 this.trdcmpy_apr+=this.selectedtrdcmpy_apr[p_trdcmpy].tradingcogsdbcode
                }
                 trdcmpy_count+=1;
                 if(trdcmpy_count!=this.selectedtrdcmpy_apr.length)
                 {
                   this.trdcmpy_apr+=",";
                 }
              }





              console.log(this.trdcmpy_apr);

    }

    else{
      this.trdcmpy_apr="all"

    }


//status



if(this.selectedstatuslist_apr.length!=0)
{


          this.status_apr="";
          var sts_count=0;
          for(var p_sts=0;p_sts<this.selectedstatuslist_apr.length;p_sts++)
          {
             this.status_apr+=this.selectedstatuslist_apr[p_sts].status;
             sts_count+=1;
             if(sts_count!=this.selectedstatuslist_apr.length)
             {
               this.status_apr+=",";
             }
          }





          console.log(this.status_apr);


}

else{


  this.status_apr="all";

}


if(this.selectedsuppliergsdbcode_apr!=null && this.selectedsuppliergsdbcode_apr!="")

  {
    this.suppliergsdbcode_apr=this.selectedsuppliergsdbcode_apr;
  }

  else{
    this.suppliergsdbcode_apr="all"
  }

  if(this.userid!=null && this.userid!="")

  {
    this.userid_apr=this.userid;
  }

  else{
    this.userid_apr="all"
  }




this.suppliercountry_apr="";

  if(this.selected_suppliercountry_apr.length!=0)
{

          this.suppliercountry_apr="";
          var supp_count=0;
          console.log("sss",this.selected_suppliercountry_apr)
          for(var p_sts=0;p_sts<this.selected_suppliercountry_apr.length;p_sts++)
          {
             this.suppliercountry_apr+=this.selected_suppliercountry_apr[p_sts].cd_country
             supp_count+=1;
             if(supp_count!=this.selected_suppliercountry_apr.length)
             {
               this.suppliercountry_apr+=",";
             }
          }





          console.log("ooooooo",this.suppliercountry_apr);


}
else{


  this.suppliercountry_apr="all";

}


this.approverFilters.prefix=this.selectedpartprefix_apr;
this.approverFilters.base=this.selectedpartbase_apr;
this.approverFilters.suffix=this.selectedpartsuffix_apr;
this.approverFilters.suppliercode=this.selectedsuppliergsdbcode_apr;
this.approverFilters.suppliercountry=this.selected_suppliercountry_apr;
this.approverFilters.cos=this.selectedcoslist_apr;
this.approverFilters.partstatus=this.selectedstatuslist_apr;
this.approverFilters.plantcode=this.selectedplantlist_apr;
this.approverFilters.programcode=this.selectedproglist_apr;
this.approverFilters.tradingcompany=this.selectedtrdcmpy_apr;
this.approverFilters.userId=this.userid_apr;
this.approverFilters.requesttestpack=this.testpckreq_apr;

console.log(this.filterSessionStorageName,sessionStorage.getItem(this.filterSessionStorageName))
  sessionStorage.setItem(this.filterSessionStorageName,JSON.stringify(this.approverFilters));


console.log(this.testpckreq_apr);

if(this.apprv_status!=3)
{
  this.roles_passed=this.apprv_status.toString();
}
else
{

  console.log("ins")
this.roles_passed="3,13,14";
}


console.log("roles passed inside api call",this.roles_passed);



 if (this.apr_current_roleid==4)
 {


  if(this.plantcode_apr.toLowerCase()=="all" && this.programcode_apr.toLowerCase()=="all" && this.cos_apr.toLowerCase()=="all" && this.part_prefix_apr.toLowerCase()=="all" && this.part_base_apr.toLowerCase()=="all" && this.part_suffix_apr.toLowerCase()=="all" && this.suppliergsdbcode_apr.toLowerCase()=="all" && this.suppliercountry_apr.toLowerCase()=="all" && this.status_apr.toLowerCase()=="all" && this.trdcmpy_apr.toLowerCase()=="all" && this.testpckreq_apr.toLowerCase()=="all")
{
  this.transactionService.getApprGrid_addnlaprv(this.apr_cdsid,this.current_region_code_apr).subscribe(apr_grid_addnl=>{

    this.apprvrGridList=[];
    console.log(apr_grid_addnl);


    this.apprvrGridList=apr_grid_addnl;
    this.approver_grid_output();
    
  },
  (error) => {                              //Error callback
    console.error('Error in Additional approver list API call - getApprGrid_addnlaprv',error)
    this.errorLoadingData = "Error Occured, Please Try Again";
    this.loaderapr=false;

  }
  )
}




else
{
  this.transactionService.getApprGridonfilters_addnlaprv(this.current_region_code_apr,this.part_prefix_apr,this.part_base_apr,this.part_suffix_apr,this.suppliergsdbcode_apr,this.plantcode_apr,this.programcode_apr,this.cos_apr,this.suppliercountry_apr,this.trdcmpy_apr,this.apr_cdsid,this.apr_current_roleid).subscribe(apr_grid_onfilt_addnl=>{

    this.apprvrGridList=[]
    console.log(apr_grid_onfilt_addnl);



    this.apprvrGridList=apr_grid_onfilt_addnl;
    this.approver_grid_output();
    
  },
  (error) => {                              //Error callback
    console.error('Error in Additional approver list on filters API call - getApprGridonfilters_addnlaprv',error)
    this.errorLoadingData = "Error Occured, Please Try Again";
    this.loaderapr=false;

  }
  )
}





 }


// offsite supplier

 else if(this.apr_current_roleid == 19 || this.apr_current_roleid==20)
{

//default

  if(this.plantcode_apr.toLowerCase()=="all" && this.programcode_apr.toLowerCase()=="all" && this.cos_apr.toLowerCase()=="all" && this.part_prefix_apr.toLowerCase()=="all" && this.part_base_apr.toLowerCase()=="all" && this.part_suffix_apr.toLowerCase()=="all" && this.suppliergsdbcode_apr.toLowerCase()=="all" && this.suppliercountry_apr.toLowerCase()=="all" && this.status_apr.toLowerCase()=="all" && this.trdcmpy_apr.toLowerCase()=="all" && this.testpckreq_apr.toLowerCase()=="all")
{



  this.transactionService.getApprGridOffsite(this.apr_cdsid,this.roles_passed,this.current_region_code_apr,this.apr_current_roleid).subscribe(apr_grid_offsite=>{

    console.log("grid",apr_grid_offsite);


    this.apprvrGridList=apr_grid_offsite;
    this.approver_grid_output();
    
  },
  (error) => {                              //Error callback
    console.error('Error in Offsite approver list API call - getApprGridOffsite',error)
    this.errorLoadingData = "Error Occured, Please Try Again";
    this.loaderapr=false;

  }
  )



}



else
{


  this.transactionService.getApprGridonfiltersOffsite(this.current_region_code_apr,this.roles_passed,this.part_prefix_apr,this.part_base_apr,this.part_suffix_apr,this.status_apr,this.suppliergsdbcode_apr,this.plantcode_apr,this.programcode_apr,this.cos_apr,this.suppliercountry_apr,this.trdcmpy_apr,this.apr_cdsid,this.testpckreq_apr,this.apr_current_roleid).subscribe(apr_grid_onfilt_offsite=>{

    this.apprvrGridList=[]
    console.log(apr_grid_onfilt_offsite);



    this.apprvrGridList=apr_grid_onfilt_offsite;
    this.approver_grid_output();



  //with filters
 
  },
  (error) => {                              //Error callback
    console.error('Error in Offsite approver list on filters API call - getApprGridonfiltersOffsite',error)
    this.errorLoadingData = "Error Occured, Please Try Again";
    this.loaderapr=false;

  }
  )



}
}

else if(this.apr_current_roleid == 22)
{
  var role_id=4;
  if(this.plantcode_apr.toLowerCase()=="all" && this.programcode_apr.toLowerCase()=="all" && this.cos_apr.toLowerCase()=="all" && this.part_prefix_apr.toLowerCase()=="all" && this.part_base_apr.toLowerCase()=="all" && this.part_suffix_apr.toLowerCase()=="all" && this.suppliergsdbcode_apr.toLowerCase()=="all" && this.suppliercountry_apr.toLowerCase()=="all" && this.trdcmpy_apr.toLowerCase()=="all" && this.userid_apr.toLowerCase()=="all")
  {
    this.transactionService.loadAllAddlnApprvrWithoutFilters(this.current_region_code_apr).subscribe(allAddlnApprvers=>{
      this.apprvrGridList=allAddlnApprvers;
      console.log("Approver List without filter",this.apprvrGridList)
      this.approver_grid_output();
    },
    (error) => {                              //Error callback
      console.error('Error in All additional approver list API call - loadAllAddlnApprvrWithoutFilters',error)
      this.errorLoadingData = "Error Occured, Please Try Again";
      this.loaderapr=false;
  
    }
    )
  }
  else
  {
    this.transactionService.loadAllAddlnApprvrWithFilters(this.current_region_code_apr,this.part_prefix_apr,this.part_base_apr,this.part_suffix_apr,this.suppliergsdbcode_apr,this.plantcode_apr,this.programcode_apr,this.cos_apr,this.suppliercountry_apr,this.trdcmpy_apr,this.userid_apr,role_id).subscribe(alladdlnapprovr=>{
      this.apprvrGridList=alladdlnapprovr;
      console.log("Approver List with filter",this.apprvrGridList)
      this.approver_grid_output();
    },
    (error) => {                              //Error callback
      console.error('Error in All additional approver list on filters API call - loadAllAddlnApprvrWithFilters',error)
      this.errorLoadingData = "Error Occured, Please Try Again";
      this.loaderapr=false;
  
    }
    )
  }
}

else
{
  var role_id=0
    role_id=this.apr_current_roleid
    if(this.apr_current_roleid == 21)
    {
      role_id=3
    }

    if(role_id==3 && this.showExceptionQueueTable)
    {
      this.transactionService.getExceptionQueueGrid(this.current_region_code_apr,this.apprv_status,this.part_prefix_apr,this.part_base_apr,
        this.part_suffix_apr,this.status_apr,this.suppliergsdbcode_apr,this.plantcode_apr,this.programcode_apr,this.cos_apr,
        this.suppliercountry_apr,this.trdcmpy_apr,this.apr_cdsid,role_id).subscribe(excepGrid=>{

        console.log(this.part_prefix_apr,this.part_base_apr,this.part_suffix_apr,this.suppliergsdbcode_apr,this.suppliercountry_apr,this.plantcode_apr,this.programcode_apr,this.cos_apr,this.trdcmpy_apr,this.apr_cdsid,this.status_apr,this.current_region_code_apr,this.apprv_status)


        this.exceptionQueueGrid=[];
        this.exceptionQueueGrid=excepGrid;

        this.exception_grid_output();
      },
      (error) => {                              //Error callback
        console.error('Error in Exception queue list API call - getExceptionQueueGrid',error)
        this.errorLoadingData = "Error Occured, Please Try Again";
        this.loaderapr=false;
    
      }
      )
    }
    else
    {
      if(this.plantcode_apr.toLowerCase()=="all" && this.programcode_apr.toLowerCase()=="all" && this.cos_apr.toLowerCase()=="all" && this.part_prefix_apr.toLowerCase()=="all" && this.part_base_apr.toLowerCase()=="all" && this.part_suffix_apr.toLowerCase()=="all" && this.suppliergsdbcode_apr.toLowerCase()=="all" && this.suppliercountry_apr.toLowerCase()=="all" && this.status_apr.toLowerCase()=="all" && this.trdcmpy_apr.toLowerCase()=="all" && this.testpckreq_apr.toLowerCase()=="all")
      {
        this.transactionService.getApprGrid(this.apr_cdsid,this.roles_passed,this.current_region_code_apr,role_id).subscribe(apr_grid=>{

          this.apprvrGridList=[];
          console.log(apr_grid);


          this.apprvrGridList=apr_grid;
          this.approver_grid_output();
          
        },
        (error) => {                              //Error callback
          console.error('Error in Approver list API call - getApprGrid',error)
          this.errorLoadingData = "Error Occured, Please Try Again";
          this.loaderapr=false;
      
        }
        )
      }



      else
      {
        this.transactionService.getApprGridonfilters(this.current_region_code_apr,this.roles_passed,this.part_prefix_apr,this.part_base_apr,this.part_suffix_apr,this.status_apr,this.suppliergsdbcode_apr,this.plantcode_apr,this.programcode_apr,this.cos_apr,this.suppliercountry_apr,this.trdcmpy_apr,this.apr_cdsid,this.testpckreq_apr,role_id).subscribe(apr_grid_onfilt=>{

          this.apprvrGridList=[]
          console.log(apr_grid_onfilt);



          this.apprvrGridList=apr_grid_onfilt;
          this.approver_grid_output();
          
        },
        (error) => {                              //Error callback
          console.error('Error in Approver list on filters API call - getApprGridonfilters',error)
          this.errorLoadingData = "Error Occured, Please Try Again";
          this.loaderapr=false;
      
        })
      }
    }
  }











  }




  approver_grid_output()
  {

   /* document.getElementById("overlayallaprinit").style.display = "none";
    document.getElementById("loaderallaprinit").style.display = "none";
  document.getElementById("overlayallapr").style.display = "none";
  document.getElementById("loaderallapr").style.display = "none";
*/
      this.loaderapr=false;

    console.log(this.apprvrGridList);

var grid_display_part_status="";
this.packSignOffPresentForAll=false;

var countpckSignOff=0;
if(this.apprvrGridList.length!=0 && this.apprvrGridList!=null)
{

  for(var i=0;i<this.apprvrGridList.length;i++)
  {
 grid_display_part_status="";

 // improvement proposal changes

 if(this.apprvrGridList[i].propsl_type != null && this.apprvrGridList[i].propsl_type.toLowerCase() =="improvement" )
 {
  grid_display_part_status+=this.apprvrGridList[i].imp_ngpp_part_status;
 }
 else
 {
  grid_display_part_status+=this.apprvrGridList[i].ngpp_part_status;

  if(this.apprvrGridList[i].isstatusurgent!=null && this.apprvrGridList[i].isstatusurgent.toLowerCase()=='y')
  {
    grid_display_part_status+=" (Urgent)"
  }
  if(this.apprvrGridList[i].isdeliquent!=null && this.apprvrGridList[i].isdeliquent.toLowerCase()=='y')
  {
    grid_display_part_status+=" (Delinquent)"
  }
 }

 this.apprvrGridList[i].displaypartstatus=grid_display_part_status;

  if(this.apprvrGridList[i].pkgsignoff != null && this.apprvrGridList[i].pkgsignoff.trim() != "")
  {
    this.apprvrGridList[i].isPackagingSignOffPresent = true;
    countpckSignOff+=1;
  }
  else
  {
    this.apprvrGridList[i].isPackagingSignOffPresent = false;
  }
}


 if(countpckSignOff == this.apprvrGridList.length)
 {
  this.packSignOffPresentForAll=true;
 }
 else
 {
   this.packSignOffPresentForAll=false;
 }


  this.grid_text="";
  this.present_table=true;


  if(this.apr_current_roleid==22)
  {
    this.cols = [
      { field: 'partnumbercommonized', header:'Simplified Part Number'},
      { field:'gppuserid',header:'User ID'},
      {field:'partdesc',header:'Part Description'},
      {field:'propsl_type',header:'Proposal Type'},
      { field: 'supgsdbcode', header:'Supplier GSDB'},
      { field: 'supcountrycode', header:'Supplier Country'},
      { field: 'plantgsdbcode', header:'Plant'},
      { field: 'tradingcogsdbcode', header:'Trading Company'},
      { field: 'programname', header:'Program(s)'},
      { field: 'parteffectiveindate', header:'Effective In Dates'},
      { field: 'cntr_ownrshp_strat_code', header:'COS'},
      { field: 'dis', header:'DIS'},
      { field: 'diq', header:'DIQ'},
      {field: 'displaypartstatus', header:'Status'}
    ];
  }
  else
  {
    this.cols = [
      { field: 'partnumbercommonized', header:'Simplified Part Number'},
      {field:'partdesc',header:'Part Description'},
      {field:'propsl_type',header:'Proposal Type'},
      { field: 'supgsdbcode', header:'Supplier GSDB'},
      { field: 'supcountrycode', header:'Supplier Country'},
      { field: 'plantgsdbcode', header:'Plant'},
      { field: 'tradingcogsdbcode', header:'Trading Company'},
      { field: 'programname', header:'Program(s)'},
      { field: 'parteffectiveindate', header:'Effective In Dates'},
      { field: 'cntr_ownrshp_strat_code', header:'COS'},
      { field: 'dis', header:'DIS'},
      { field: 'diq', header:'DIQ'},
      {field: 'displaypartstatus', header:'Status'}
    ];
  }

}


else{
  this.present_table=false;
  this.cols=[];
  this.grid_text="No Match Found"
}

}

exception_grid_output()
{

  this.loaderapr=false;

  console.log(this.exceptionQueueGrid);

  var grid_display_part_status="";
  if(this.exceptionQueueGrid.length!=0 && this.exceptionQueueGrid!=null)
  {

  for(var i=0;i<this.exceptionQueueGrid.length;i++)
  {
    grid_display_part_status="";

    // improvement proposal changes

    if(this.exceptionQueueGrid[i].propsl_type != null && this.exceptionQueueGrid[i].propsl_type.toLowerCase() =="improvement" )
    {
    grid_display_part_status+=this.exceptionQueueGrid[i].imp_ngpp_part_status;
    }
    else
    {
      grid_display_part_status+=this.exceptionQueueGrid[i].ngpp_part_status;

      if(this.exceptionQueueGrid[i].isstatusurgent!=null && this.exceptionQueueGrid[i].isstatusurgent.toLowerCase()=='y')
      {
      grid_display_part_status+=" (Urgent)"
      }
      if(this.exceptionQueueGrid[i].isdeliquent!=null && this.exceptionQueueGrid[i].isdeliquent.toLowerCase()=='y')
      {
      grid_display_part_status+=" (Delinquent)"
      }
      }


      this.exceptionQueueGrid[i].displaypartstatus=grid_display_part_status
    }





    this.grid_text_excp="";
    this.present_table_excp=true;


      this.cols3 = [
      { field: 'partnumbercommonized', header:'Simplified Part Number'},
      {field:'partdesc',header:'Part Description'},
      {field:'propsl_type',header:'Proposal Type'},
      { field: 'supgsdbcode', header:'Supplier GSDB'},
      { field: 'supcountrycode', header:'Supplier Country'},
      { field: 'plantgsdbcode', header:'Plant'},
      { field: 'tradingcogsdbcode', header:'Trading Company'},
      { field: 'programname', header:'Program(s)'},
      { field: 'parteffectiveindate', header:'Effective In Dates'},
      { field: 'cntr_ownrshp_strat_code', header:'COS'},
      { field: 'dis', header:'DIS'},
      { field: 'diq', header:'DIQ'},
      {field: 'displaypartstatus', header:'Status'}
      ];


  }

  else
  {
    this.present_table_excp=false;
    this.cols3=[];
    this.grid_text_excp="No Match Found"
  }
}




clearApproverFilterValues()
{
  this.selectedpartprefix_apr="";
  this.selectedpartbase_apr="";
  this.selectedpartsuffix_apr="";
  this.selectedplantlist_apr=[];
  this.disableProgDrp_apr=true;
  this.selectedproglist_apr=[];
  this.selectedcoslist_apr=[];
  this.selectedtrdcmpy_apr=[];
  this.selectedstatuslist_apr=[];
  this.selectedsuppliergsdbcode_apr="";
  this.selected_suppliercountry_apr=[];
  this.userid="";
  this.testpckreq_apr="all";
  this.errorLoadingData = "";

  this.selectedFromExceptionQueue=[];
  this.apply_filter_apr();

}

deSelectSelectedParts()
{
  this.selectedFromExceptionQueue=[];
}

viewSelectedParts()
{
  console.log("View Selected Parts", this.selectedFromExceptionQueue);

  this.enableViewSelectedPartsPopup=true;


  this.cols_view=[
    { field: 'plantgsdbcode', header:'Plant GSDB Code'},
    //{field:'propsl_type', header:'Proposal Type'},
    { field: 'partnumbercommonized', header:'Part Number'},
    { field: 'partdesc', header:'Part Description'},
    { field: 'displaypartstatus', header:'Part Status'},
    //{ field: 'proposal_status_desc', header:'Proposal Status'},
    { field: 'supgsdbcode', header:'Supplier GSDB Code'},
    //{field:'partIntroDate', header:'Part Intro Date'},
    //{field:'offsitesuppid', header:'Offsite Supplier ID'},
  ]

}

sendSelectedPartsBulkEmailButton()
{
  console.log("sendSelectedPartsBulkEmailButton - selected parts list for bulk email",this.selectedFromExceptionQueue);
  this.enableSendEmailPopUp=true;
  this.bulkEmailSubject="ATTENTION : Action Required"
}

selectedPartDetailsForBulkemailPostInit()
{
  this.selectedPartsForBulkEmailPost={

    partDetls:[
      {
        partNumberCommonized:"",
        plantCode:"",
        programName:"",
        supCode:""
      }
    ],
    subject:"",
    emailBody:"",
    copyMe:"",
    user:""


  }
}
sendBulkEmailPostPopUpButton()
{
  console.log("sendBulkEmailPostPopUpButton - Selected parts list for bulk email",this.selectedFromExceptionQueue);
  console.log("Bulk email subject", this.bulkEmailSubject)
  console.log("Bulk email Body",this.bulkEmailBody);
  console.log("Bulk email Copy me Check box",this.copyMeOnBulkEmail);

  this.selectedPartDetailsForBulkemailPostInit();

  if(this.selectedFromExceptionQueue != null && this.selectedFromExceptionQueue.length != 0)
  {
    this.selectedPartsForBulkEmailPost.partDetls=[];
    for(var i=0; i<this.selectedFromExceptionQueue.length; i++)
    {
      this.selectedPartsForBulkEmailPost.partDetls.push(
          {
            partNumberCommonized:this.selectedFromExceptionQueue[i].partnumbercommonized,
            plantCode:this.selectedFromExceptionQueue[i].plantgsdbcode,
            programName:this.selectedFromExceptionQueue[i].programname,
            supCode:this.selectedFromExceptionQueue[i].supgsdbcode
          }
      )
    }

    this.selectedPartsForBulkEmailPost.subject=this.bulkEmailSubject;
    this.selectedPartsForBulkEmailPost.emailBody=this.bulkEmailBody;
    var copyMe='N'
    if(this.copyMeOnBulkEmail)
    {
      copyMe='Y'
    }
    this.selectedPartsForBulkEmailPost.copyMe=copyMe;
    this.selectedPartsForBulkEmailPost.user=this.transactionService.getcdsid();

    console.log("Selected parts for bulk email post before api call",this.selectedPartsForBulkEmailPost)

    this.transactionService.searchPartsBulkEmailForPost(this.selectedPartsForBulkEmailPost).subscribe(searchPartsBulkEmailRes=>{
      console.log("Selected parts Bulk Email For Post Response",searchPartsBulkEmailRes)
      if(searchPartsBulkEmailRes != null && searchPartsBulkEmailRes.toLowerCase() == "success")
      {
        this.enableSendEmailPopUp=false;
        this.bulkEmailBody="";
        this.bulkEmailSubject="";
        this.copyMeOnBulkEmail=false;
        this.selectedFromExceptionQueue=[];
        this.selectedPartDetailsForBulkemailPostInit();

        this.messageService.add({severity:'success', summary: 'success', detail: 'Email sent successfully'});

      }
      else
      {
        this.messageService.add({severity:'error', summary: 'Error', detail: 'Email not sent,Please Try Again'})
      }
    })
  }




}
closeBulEmailPopUp()
{
  this.enableSendEmailPopUp=false;
  this.bulkEmailBody="";
  this.bulkEmailSubject="";
  this.copyMeOnBulkEmail=false;
}

disableEmailPopUp():boolean
{
  if(this.bulkEmailSubject == null || this.bulkEmailSubject.trim() == "" || this.bulkEmailBody == null || this.bulkEmailBody.trim() == "")
  {
    return true;
  }
  else
  {
    return false;
  }
}


exportExcel() {

  var approvalExcelDownload:any[]=[];

  this.apprvrGridList.forEach(e=>{

    if(this.apr_current_roleid==22)
    {
      approvalExcelDownload.push({
        'partnumbercommonized':e.partnumbercommonized,
       'gppuserid':e.gppuserid,
        'partdesc':e.partdesc,
        'propsl_type':e.propsl_type,
        'supgsdbcode':e.supgsdbcode,
        'supcountrycode':e.supcountrycode,
        'plantgsdbcode':e.plantgsdbcode,
        'tradingcogsdbcode':e.tradingcogsdbcode,
        'programname':e.programname,
        'parteffectiveindate':e.parteffectiveindate,
        'cntr_ownrshp_strat_code':e.cntr_ownrshp_strat_code,
        'dis':e.dis,
        'diq':e.diq,
        'displaypartstatus':e.displaypartstatus
      })

  
    }
    else
    {
      approvalExcelDownload.push({
        'partnumbercommonized':e.partnumbercommonized,
        'partdesc':e.partdesc,
        'propsl_type':e.propsl_type,
        'supgsdbcode':e.supgsdbcode,
        'supcountrycode':e.supcountrycode,
        'plantgsdbcode':e.plantgsdbcode,
        'tradingcogsdbcode':e.tradingcogsdbcode,
        'programname':e.programname,
        'parteffectiveindate':e.parteffectiveindate,
        'cntr_ownrshp_strat_code':e.cntr_ownrshp_strat_code,
        'dis':e.dis,
        'diq':e.diq,
        'displaypartstatus':e.displaypartstatus
      })

    }

    if(this.apr_current_roleid==22)
    {
      import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(approvalExcelDownload,{header:[
          'partnumbercommonized',
          'gppuserid',
          'partdesc',
          'propsl_type',
          'supgsdbcode',
          'supcountrycode',
          'plantgsdbcode',
          'tradingcogsdbcode',
          'programname',
          'parteffectiveindate',
          'cntr_ownrshp_strat_code',
          'dis',
          'diq',
          'displaypartstatus'
        ]});

        worksheet.A1.v="Part Number Commonized";
        worksheet.B1.v="GPPUserId";
        worksheet.C1.v="Part Description";
        worksheet.D1.v="Proposal Type";
        worksheet.E1.v="Supplier GSDB Code";
        worksheet.F1.v="Supplier Country";
        worksheet.G1.v="Plant GSDB Code";
        worksheet.H1.v="Trading Company";
        worksheet.I1.v="Program Name";
        worksheet.J1.v="Part Effective Date";
        worksheet.K1.v="Container Ownership Strategy Code";
        worksheet.L1.v="DIS";
        worksheet.M1.v="DIQ";
        worksheet.N1.v="Part Status";
       

        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "Approval List");
      });
    }

    else{
      import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(approvalExcelDownload,{header:[
          'partnumbercommonized',
          'partdesc',
          'propsl_type',
          'supgsdbcode',
          'supcountrycode',
          'plantgsdbcode',
          'tradingcogsdbcode',
          'programname',
          'parteffectiveindate',
          'cntr_ownrshp_strat_code',
          'dis',
          'diq',
          'displaypartstatus'
        ]});

        worksheet.A1.v="Part Number Commonized";
        worksheet.B1.v="Part Description";
        worksheet.C1.v="Proposal Type";
        worksheet.D1.v="Supplier GSDB Code";
        worksheet.E1.v="Supplier Country";
        worksheet.F1.v="Plant GSDB Code";
        worksheet.G1.v="Trading Company";
        worksheet.H1.v="Program Name";
        worksheet.I1.v="Part Effective Date";
        worksheet.J1.v="Container Ownership Strategy Code";
        worksheet.K1.v="DIS";
        worksheet.L1.v="DIQ";
        worksheet.M1.v="Part Status";
       

        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "Approval List");
      });
    }
  })

  console.log(approvalExcelDownload);


}

exportExcelForExceptionQueue()
{
  console.log(this.exceptionQueueGrid,this.cols3);
  var exceptionQueueExcelDownload:any[]=[];

  this.exceptionQueueGrid.forEach(e=>{
    exceptionQueueExcelDownload.push({
      'partnumbercommonized':e.partnumbercommonized,
      'partdesc':e.partdesc,
      'propsl_type':'Normal',
      'supgsdbcode':e.supgsdbcode,
      'supcountrycode':e.supcountrycode,
      'plantgsdbcode':e.plantgsdbcode,
      'tradingcogsdbcode':e.tradingcogsdbcode,
      'programname':e.programname,
      'parteffectiveindate':e.parteffectiveindate,
      'cntr_ownrshp_strat_code':e.cntr_ownrshp_strat_code,
      'dis':e.dis,
      'diq':e.diq,
      'displaypartstatus':e.displaypartstatus
    })
  })
  

  
  import("xlsx").then(xlsx => {
    const worksheet = xlsx.utils.json_to_sheet(exceptionQueueExcelDownload,{header:[
      'partnumbercommonized',
      'partdesc',
      'propsl_type',
      'supgsdbcode',
      'supcountrycode',
      'plantgsdbcode',
      'tradingcogsdbcode',
      'programname',
      'parteffectiveindate',
      'cntr_ownrshp_strat_code',
      'dis',
      'diq',
      'displaypartstatus'
    ]});

    worksheet.A1.v="Part Number Commonized";
    worksheet.B1.v="Part Description";
    worksheet.C1.v="Proposal Type";
    worksheet.D1.v="Supplier GSDB Code";
    worksheet.E1.v="Supplier Country";
    worksheet.F1.v="Plant GSDB Code";
    worksheet.G1.v="Trading Company";
    worksheet.H1.v="Program Name";
    worksheet.I1.v="Part Effective Date";
    worksheet.J1.v="Container Ownership Strategy Code";
    worksheet.K1.v="DIS";
    worksheet.L1.v="DIQ";
    worksheet.M1.v="Part Status";
   

    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, "Exception Queue List");
  });
}

exportExcelForBulkEmailSelectedParts()
{
  console.log(this.selectedFromExceptionQueue,this.cols_view);

  var bulkEmailSelectedPartsEmailFormat:any[]=[];

  this.selectedFromExceptionQueue.forEach(e=>{
    bulkEmailSelectedPartsEmailFormat.push({
      'plantgsdbcode':e.plantgsdbcode,
      'partnumbercommonized':e.partnumbercommonized,
      'partdesc':e.partdesc,
      'displaypartstatus':e.displaypartstatus,
      'supgsdbcode':e.supgsdbcode,
    })
  })

  import("xlsx").then(xlsx => {
    const worksheet = xlsx.utils.json_to_sheet(bulkEmailSelectedPartsEmailFormat,{header:[
      'plantgsdbcode',
      'partnumbercommonized',
      'partdesc',
      'displaypartstatus',
      'supgsdbcode',
    ]});

    worksheet.A1.v="Plant GSDB Code";
    worksheet.B1.v="Part Number Commonized";
    worksheet.C1.v="Part Description";
    worksheet.D1.v="Part Status";
    worksheet.E1.v="Supplier GSDB Code";

    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, "Selected Parts For Bulk Email List");
  });
}

saveAsExcelFile(buffer: any, fileName: string): void {
  import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
          type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  });
}




view_revision_history()
{


this.enabledetails=true;
console.log("revision history");

/*
this.transactionService.loadtransacmasterdetails(this.selectedsimpliedpartnodetails_apr.proposalid).subscribe(transdet=>{

  console.log(transdet);
 this.proposal_details_prelim=transdet;


 console.log(this.proposal_details_prelim.revision_hstry);
  var revision_arr=[];
  this.revision_obj=[];

  if(this.proposal_details_prelim.revision_hstry!=null)

 //if(transdet!=null)
  {
     revision_arr=this.proposal_details_prelim.revision_hstry.split('|')
    //revision_arr=transdet.split('|')
    console.log(revision_arr)


         for(var i=0;i<revision_arr.length;i++)
         {
           var rev_arr=[];
           var rev_htm_str="";
           rev_arr=revision_arr[i].split('`');




           if(rev_arr.length==7)
           {

           rev_htm_str="<b>"+rev_arr[5]+";"+rev_arr[1]+"("+rev_arr[2]+")"+"</b>"+"<br>"+rev_arr[3]+"-"+rev_arr[4];
           if(rev_arr[6]!="-")
           {
             rev_htm_str+="<br>"+"Comments-"+rev_arr[6];
           }
          }


          this.revision_obj.push({rev_string:revision_arr[i],rev_html_string:rev_htm_str})

         }

  }


  console.log(this.revision_obj);


  this.revision_obj=this.revision_obj.reverse();
  console.log(this.revision_obj)

})*/

this.revision_obj=[];
this.transactionService.getRevisionHistoryByProposalId(this.selectedsimpliedpartnodetails_apr.proposalid).subscribe(revHistArray=>{

  this.revHistFromServiceAPI=[];
  this.revHistFromServiceAPI=revHistArray;
  this.revision_obj=[];
  console.log("getRevisionHistoryByProposalId API o/p ",this.revHistFromServiceAPI);



  if(this.revHistFromServiceAPI != null && this.revHistFromServiceAPI.length != 0)
  {
    for(var i=0; i<this.revHistFromServiceAPI.length; i++)
    {
      var rev_htm_str="";
     /* var approvalStage="";

      if(this.approvalStatusList != null && this.approvalStatusList.length !=0 && this.revHistFromServiceAPI[i].proposalstatuscurr != null)
      {
      var approvalStatusIndex=this.approvalStatusList.findIndex(ele=>ele.cd_propsl == this.revHistFromServiceAPI[i].proposalstatuscurr)
      if(approvalStatusIndex != -1)
      {
        approvalStage=this.approvalStatusList[approvalStatusIndex].propsl_stat;
      }
      }*/


      if(this.revHistFromServiceAPI[i].evnttime != null && this.revHistFromServiceAPI[i].usrname != null)
      {
        rev_htm_str="<b>"+this.revHistFromServiceAPI[i].evnttime+";"+this.revHistFromServiceAPI[i].usrname+"("+this.revHistFromServiceAPI[i].email+")"+"</b>"+"<br>"+this.revHistFromServiceAPI[i].proposalstatuscurr+"-"+this.revHistFromServiceAPI[i].apprvractn;
      }
      else
      {
        rev_htm_str="<b>"+this.revHistFromServiceAPI[i].proposalstatuscurr+"-"+this.revHistFromServiceAPI[i].apprvractn+"</b>";
      }
      if(this.revHistFromServiceAPI[i].comments != null && this.revHistFromServiceAPI[i].comments != "-")
      {
        rev_htm_str+="<br>"+"Comments-"+this.revHistFromServiceAPI[i].comments;
      }

      this.revision_obj.push({rev_obj:this.revHistFromServiceAPI[i],rev_html_string:rev_htm_str})
    }
    console.log("Revision History Display",this.revision_obj)
  }

})
}


close_revision_history()
{
this.enabledetails=false;

}



getselectedpart(partData:approver_grid_list){

  console.log(partData.plantgsdbcode);
  console.log(partData.partnumbercommonized);




  //var sub_exist_proposalid=0;

  //service to get proposal id





  this.selectedsimpliedpartnodetails_apr.bompartmasterid=partData.bompartmasterid;
this.selectedsimpliedpartnodetails_apr.simplifiedpartno=partData.partnumbercommonized;
this.selectedsimpliedpartnodetails_apr.proposalid=partData.id_proposal;
this.selectedsimpliedpartnodetails_apr.plantgsdbcode=partData.plantgsdbcode;
this.selectedsimpliedpartnodetails_apr.proposallastsaved=4;
this.selectedsimpliedpartnodetails_apr.part_status=partData.ngpp_part_status;
this.selectedsimpliedpartnodetails_apr.supplier_code=partData.supgsdbcode;
this.selectedsimpliedpartnodetails_apr.proposallastsaved_ee=1;
this.selectedsimpliedpartnodetails_apr.currentview_ee=false;
this.selectedsimpliedpartnodetails_apr.is_ee_proposal=false;
this.selectedsimpliedpartnodetails_apr.from_draft=false;


this.selectedsimpliedpartnodetails_apr.propsl_type=partData.propsl_type;
this.selectedsimpliedpartnodetails_apr.imp_part_status=partData.imp_ngpp_part_status


this.partBailmentPlantcode=partData.plantgsdbcode;

// improvement proposal changes
if(this.selectedsimpliedpartnodetails_apr.propsl_type != null && this.selectedsimpliedpartnodetails_apr.propsl_type.toLowerCase() == "improvement")
{
  this.selectedpart_partstatus_onproposaltype=this.selectedsimpliedpartnodetails_apr.imp_part_status;
}
else
{
  this.selectedpart_partstatus_onproposaltype=this.selectedsimpliedpartnodetails_apr.part_status
}



  this.store_primary_part();











}

/* Code Added for Effective Date*/
effectiveDateFromInput()
{
  if(this.effectiveDateForImprovementProposal!=null)
  {
    this.disableApproveButtonForRelProd=false;
    this.effectiveDateInString=this.date.transform(this.effectiveDateForImprovementProposal,"yyyy-MM-dd");
    //alert(this.effectiveDateInString);
  }
  else
  {
    this.disableApproveButtonForRelProd=true;
    this.effectiveDateInString=null;
  }
}
/* Code Added for Effective Date*/


store_primary_part()
{
if(this.current_region_code_apr=="NA")
{

  sessionStorage.setItem('napackpropart', JSON.stringify(this.selectedsimpliedpartnodetails_apr));
}


if(this.current_region_code_apr=="EU")
{
  sessionStorage.setItem('eupackpropart', JSON.stringify(this.selectedsimpliedpartnodetails_apr));
}



if(this.current_region_code_apr=="AP")
{
  sessionStorage.setItem('appackpropart', JSON.stringify(this.selectedsimpliedpartnodetails_apr));
}



if(this.current_region_code_apr=="SA")
{
  sessionStorage.setItem('sapackpropart', JSON.stringify(this.selectedsimpliedpartnodetails_apr));
}




this.transactionService.get_region_color_details(this.current_region_code_apr).subscribe(colr=>{


  this.colorforhotstamp=colr.filter(colrs=>colrs.active_stat.toLowerCase()=="yes");

  //return this.colorforcntrorrack;
   //this.colorforcntrorrack=colr;
   console.log("colors for rack",this.colorforhotstamp)
 })


 this.transactionService.get_region_details(this.current_region_code_apr).subscribe(regionDet=>{
   console.log(regionDet);
   if(regionDet != null)
   {
   if(regionDet.pkg_rel_level_src != null && regionDet.pkg_rel_level_src.toLowerCase() == "bom")
   {
    this.enableReleaseLevelOnRegionAdmin=false;
   }
   else
   {
    this.enableReleaseLevelOnRegionAdmin=true;
   }
  }
 })


console.log(this.apr_current_roleid);


this.selected_hotstampcntrid=""
this.selected_hotstampcntrcolor={cd_Color:0,color_desc:"",cd_region:"",active:true,active_stat:"",updated_dt:"",updated_by:""}
this.selected_hotstamppalletboxcolor={cd_Color:0,color_desc:"",cd_region:"",active:true,active_stat:"",updated_dt:"",updated_by:""}
this.selected_hotstamppalletbaselidcolor={cd_Color:0,color_desc:"",cd_region:"",active:true,active_stat:"",updated_dt:"",updated_by:""}
this.selected_hotstamidplatecolor={cd_Color:0,color_desc:"",cd_region:"",active:true,active_stat:"",updated_dt:"",updated_by:""}
this.selected_hotstampcolor={cd_Color:0,color_desc:"",cd_region:"",active:true,active_stat:"",updated_dt:"",updated_by:""}
this.selected_hotstampfontcolor={cd_Color:0,color_desc:"",cd_region:"",active:true,active_stat:"",updated_dt:"",updated_by:""}
this.selected_hotstampcomments="";


this.press_line="";
this.stamp_destack_method="";
this.stamp_warehouse_loc="";


this.offstprtaprvd="";
this.is_bailmnt_prt_prsnt="";


// improvement proposal changes


if(this.apr_current_roleid==3)
{
  console.log(this.selectedpart_partstatus_onproposaltype);
  if(this.selectedpart_partstatus_onproposaltype.toLowerCase()=="rejection requested")
  {

    this.disable_rejection_flow=false;
    this.disable_recall_flow=true;
  }



 else if(this.selectedpart_partstatus_onproposaltype.toLowerCase()=="recall requested")
  {
    this.disable_recall_flow=false;
    this.disable_rejection_flow=true;
  }
  else{
    this.disable_recall_flow=true;
    this.disable_rejection_flow=true;
  }


  this.access_additional_aprove_button=false;
}
else if(this.apr_current_roleid==4 || this.apr_current_roleid==22)
{

  console.log("addlap",this.selectedpart_partstatus_onproposaltype)
  /*if(this.selectedsimpliedpartnodetails_apr.propsl_type!=null && this.selectedsimpliedpartnodetails_apr.propsl_type.toLowerCase()=="migration")
  {
    this.enable_additionalapr_aprove_button=false;
    this.access_additional_aprove_button=false;
    this.enableMigrationApproveButton=true;
  }
  else
  {*/
    this.enableMigrationApproveButton=false;
    this.enable_additionalapr_aprove_button=true;

    if(this.selectedpart_partstatus_onproposaltype.toLowerCase()=="rejection requested" || this.selectedpart_partstatus_onproposaltype.toLowerCase()=="recall requested")
    {
      this.access_additional_aprove_button=false;

    }
    else{
      this.access_additional_aprove_button=true;

    }
  //}

  console.log("addlap",this.access_additional_aprove_button)
 this.disable_rejection_flow=true;
 this.disable_recall_flow=true;

}



/* offiste supplier removed
// offsite supplier checking removed as flow made linear and not parallel
else if(this.apr_current_roleid==19 || this.apr_current_roleid==20)
{


  if(this.selectedpart_partstatus_onproposaltype.toLowerCase()=="rejection requested" || this.selectedpart_partstatus_onproposaltype.toLowerCase()=="recall requested")
  {
    this.accessBailmentSupplierbuttons=false;
  }
  else{
    this.accessBailmentSupplierbuttons=true;

  }


 this.disable_rejection_flow=true;
 this.disable_recall_flow=true;
 this.access_additional_aprove_button=false;
}*/


else{
  this.access_additional_aprove_button=false;


  this.disable_rejection_flow=true;


  this.disable_recall_flow=true;

}





console.log("reject flow",this.disable_rejection_flow);


this.email_list="";
this.comments="";
this.upload_pcksgnoff_display=false;
this.upload_pcksgnoff_req=false;
this.pcksgnoff_uploaded=false;
this.pckgsgnoff_filename="";
this.pcksgnoff_filepath="";
this.pcksgnoff_exist="";
this.cmms_checked=false;
this.disable_upload_cmms=false;
this.isAdditionalApproverAddedForMigrationAppStage="No";

this.additionalApproversList=[];
this.choosedAddlnAprovers=[];
this.getAddedAddlnApprvrList=[];
this.removedAddlnApprovers=[];

if(this.apr_current_roleid == 9 || this.apr_current_roleid == 11 || this.apr_current_roleid == 21 )
{
  this.cmms_checked=true;
  this.disable_upload_cmms=true
}




this.enable_stamp_details=false;
this.enable_hotstamp=false;



this.enabledetails=false;


console.log(this.enable_hotstamp);
// offsite supplier checking removed as flow made linear and not parallel
/*
if(this.apr_current_roleid == 19 || this.apr_current_roleid == 20)
{

  //if base change required
  // new api bailment supplier get details

  this.transactionService.loadbailmentsuppliertransacmasterdetails(this.selectedsimpliedpartnodetails_apr.proposalid).subscribe(transdet_apr_bailment_supplier_tab=>{


  //do not use the base change from this api(bailment supplier transaction details table) - its dummy for now, can be used in future if requires

  this.existing_test_pack_requested_status_offsite=transdet_apr_bailment_supplier_tab.is_tst_pck_reqstd;
  this.existing_test_pack_received_status_offsite=transdet_apr_bailment_supplier_tab.is_tst_pck_recvd;
  this.existing_test_pack_evaluated_status_offsite=transdet_apr_bailment_supplier_tab.is_tst_pck_evaltd;

  console.log(this.existing_test_pack_requested_status_offsite,this.existing_test_pack_received_status_offsite,this.existing_test_pack_evaluated_status_offsite)


  if(this.existing_test_pack_requested_status_offsite != null && this.existing_test_pack_requested_status_offsite.toLowerCase() == "yes")
  {
    this.show_request_test_pack_button_offsite=false;
    this.enable_radio_buttons_for_tst_pack_offsite=true;


    if(this.existing_test_pack_received_status_offsite != null && this.existing_test_pack_received_status_offsite.toLowerCase()=="yes")
    {
      if(this.existing_test_pack_evaluated_status_offsite != null && this.existing_test_pack_evaluated_status_offsite.toLowerCase()=="yes")
      {
        this.diable_approve_btn_rqst_tst_pack_offsite=false;
        this.disable_reject_btn_rqst_tst_pack_offsite=false;
      }
      else
      {
        this.diable_approve_btn_rqst_tst_pack_offsite=true;
        this.disable_reject_btn_rqst_tst_pack_offsite=false;
      }
    }
    else
    {
      if(this.existing_test_pack_evaluated_status_offsite != null && this.existing_test_pack_received_status_offsite.toLowerCase()=="no")
      {
        this.diable_approve_btn_rqst_tst_pack_offsite=true;
        this.disable_reject_btn_rqst_tst_pack_offsite=false;
      }
      else
      {
        this.diable_approve_btn_rqst_tst_pack_offsite=true;
        this.disable_reject_btn_rqst_tst_pack_offsite=false;
      }
    }
  }
  else
  {
    this.show_request_test_pack_button_offsite=true;
    this.enable_radio_buttons_for_tst_pack_offsite=false;
    this.diable_approve_btn_rqst_tst_pack_offsite=false;
    this.disable_reject_btn_rqst_tst_pack_offsite=false;
  }


  this.transactionService.loadtransacmasterdetails(this.selectedsimpliedpartnodetails_apr.proposalid).subscribe(transdet_apr_off=>{


    this.exist_cntr_base_offsite=transdet_apr_off.cntr_base;
    this.exist_chgd_base_offsite=transdet_apr_off.chngdbase;


    this.selected_proposal_plant_code_offsite=transdet_apr_off.plantgsdbcode;

    if(transdet_apr_off.cntr_ownrshp_strat_code.toLowerCase()=="spu" || transdet_apr_off.cntr_ownrshp_strat_code.toLowerCase()=="fou")
    {


      if(this.exist_chgd_base_offsite!=null && this.exist_chgd_base_offsite!="")
      {
        this.base_change=this.exist_chgd_base_offsite;
      }
      else{
      this.base_change="";
      }
      this.disable_base_change=false;
    }

    else{
      this.base_change="NA";
      this.disable_base_change=true;
    }




  console.log(transdet_apr_off.rel_level);



  for(var ri=0; ri<this.releaselevel_list_apr.length; ri++)
  {
    if(transdet_apr_off.rel_level==this.releaselevel_list_apr[ri].releaselevel)
    {
      this.selected_release_level=this.releaselevel_list_apr[ri];
      break;
    }
  }


    this.transactionService.loadtransacmasterparts(this.selectedsimpliedpartnodetails_apr.proposalid).subscribe(partList=>{

      console.log("partlist",partList)

      var partindex
      partindex=partList.findIndex(prtinx=>prtinx.partnumbercommonized == this.selectedsimpliedpartnodetails_apr.simplifiedpartno);

      if(partindex != -1)
      {
        this.partBailmentSupplierId=partList[partindex].offsitesupplierid;
      }
      else
      {
        this.partBailmentSupplierId="";
      }


      console.log("bailment supplier id",this.partBailmentSupplierId);

    })

  })
})

}*/



/*
else
{ */
this.transactionService.loadtransacmasterdetails(this.selectedsimpliedpartnodetails_apr.proposalid).subscribe(transdet_apr=>{
  console.log("transdet_apr",transdet_apr);
  console.log(transdet_apr.cos_name);

  /* Code Added for Effective Date*/

  if(this.releaseForProductionEnable)
  {
    if(transdet_apr.propsl_type!=null && transdet_apr.propsl_type.toLowerCase()=="improvement")
    {
      this.enableEffectiveDateForImprovementProposal=true;
      this.effectiveDateForImprovementProposal=null;
      this.effectiveDateInString=null;
      this.disableApproveButtonForRelProd=true;
    }
    else
    {
      this.enableEffectiveDateForImprovementProposal=false;
      this.effectiveDateForImprovementProposal=null;
      this.effectiveDateInString=null;
      this.disableApproveButtonForRelProd=false;
    }
  }
  else
  {
    this.enableEffectiveDateForImprovementProposal=false;
      this.effectiveDateForImprovementProposal=null;
      this.effectiveDateInString=null;
      this.disableApproveButtonForRelProd=false;
  }



  this.exist_cntr_base=transdet_apr.cntr_base;


  this.exist_chgd_base=transdet_apr.chngdbase;


  this.offstprtaprvd=transdet_apr.offstprtaprvd;
  this.is_bailmnt_prt_prsnt=transdet_apr.is_bailmnt_prt_prsnt;

  this.existing_test_pack_requested_status=transdet_apr.isTstPckReqstd;
  this.existing_test_pack_received_status=transdet_apr.isTstPckRecvd;
  this.existing_test_pack_evaluated_status=transdet_apr.isTstPckEvaltd;

  this.selected_proposal_plant_code=transdet_apr.plantgsdbcode;

  if(this.existing_test_pack_requested_status.toLowerCase()=="yes")
  {
    this.show_request_test_pack_button=false;
    this.enable_radio_buttons_for_tst_pack=true;


    if(this.existing_test_pack_received_status.toLowerCase()=="yes")
    {
      if(this.existing_test_pack_evaluated_status.toLowerCase()=="yes")
      {
        this.diable_approve_btn_rqst_tst_pack=false;
        this.disable_reject_btn_rqst_tst_pack=false;
      }
      else
      {
        this.diable_approve_btn_rqst_tst_pack=true;
        this.disable_reject_btn_rqst_tst_pack=false;
      }
    }
    else
    {
      if(this.existing_test_pack_received_status.toLowerCase()=="no")
      {
        this.diable_approve_btn_rqst_tst_pack=true;
        this.disable_reject_btn_rqst_tst_pack=false;
      }
      else
      {
        this.diable_approve_btn_rqst_tst_pack=true;
        this.disable_reject_btn_rqst_tst_pack=false;
      }
    }
  }
  else
  {
    this.show_request_test_pack_button=true;
    this.enable_radio_buttons_for_tst_pack=false;
    this.diable_approve_btn_rqst_tst_pack=false;
    this.disable_reject_btn_rqst_tst_pack=false;
  }

if(transdet_apr.isstampblank.toLowerCase()=="yes")
{

  if(this.apr_current_roleid==5 || this.apr_current_roleid==6)
  {


    this.press_line=transdet_apr.pressline;
    this.stamp_destack_method=transdet_apr.stmpdstackmthd;
    this.stamp_warehouse_loc=transdet_apr.stmpwarehseloc;


    this.enable_stamp_details=true;

  }


  if(transdet_apr.cntr_ret_type.toLowerCase()=='returnable' && this.apr_current_roleid==11)
  {





    this.selected_hotstampcntrid=transdet_apr.cntr_base;


    if(transdet_apr.cntr_suff!=null && transdet_apr.cntr_suff!="" &&transdet_apr.cntr_suff.trim()!=null)
    {


      this.selected_hotstampcntrid+=transdet_apr.cntr_suff;

    }


    this.selected_hotstampcomments=transdet_apr.hotstampcomments;


    for(var color=0;color<this.colorforhotstamp.length;color++)
    {


      if(transdet_apr.hotstamidplatecolor!=null && transdet_apr.hotstamidplatecolor!="")
      {
      if(this.colorforhotstamp[color].color_desc==transdet_apr.hotstamidplatecolor)
      {
        this.selected_hotstamidplatecolor=this.colorforhotstamp[color];


      }
    }


    if(transdet_apr.hotstampcntrcolor!=null && transdet_apr.hotstampcntrcolor!="")
    {
    if(this.colorforhotstamp[color].color_desc==transdet_apr.hotstampcntrcolor)
    {
      this.selected_hotstampcntrcolor=this.colorforhotstamp[color];


    }
  }










if(transdet_apr.hotstampcolor!=null && transdet_apr.hotstampcolor!="")
{
if(this.colorforhotstamp[color].color_desc==transdet_apr.hotstampcolor)
{
  this.selected_hotstampcolor=this.colorforhotstamp[color];


}
}



if(transdet_apr.hotstampfontcolor!=null && transdet_apr.hotstampfontcolor!="")
{
if(this.colorforhotstamp[color].color_desc==transdet_apr.hotstampfontcolor)
{
  this.selected_hotstampfontcolor=this.colorforhotstamp[color];


}
}



if(transdet_apr.hotstamppalletbaselidcolor!=null && transdet_apr.hotstamppalletbaselidcolor!="")
{
if(this.colorforhotstamp[color].color_desc==transdet_apr.hotstamppalletbaselidcolor)
{
  this.selected_hotstamppalletbaselidcolor=this.colorforhotstamp[color];


}
}



if(transdet_apr.hotstamppalletboxcolor!=null && transdet_apr.hotstamppalletboxcolor!="")
{
if(this.colorforhotstamp[color].color_desc==transdet_apr.hotstamppalletboxcolor)
{
  this.selected_hotstamppalletboxcolor=this.colorforhotstamp[color];


}
}


    }


    this.enable_hotstamp=true;

  }





}


console.log("stamp",this.enable_hotstamp);






  if((transdet_apr.cntr_ownrshp_strat_code.toLowerCase()=="spu" || transdet_apr.cntr_ownrshp_strat_code.toLowerCase()=="fou") && transdet_apr.propsl_type.toLowerCase()!="migration")
  {

    if(this.exist_chgd_base!=null && this.exist_chgd_base!="")
    {
      this.base_change=this.exist_chgd_base;
    }
    else{
    this.base_change="";
    }
    this.disable_base_change=false;
  }

  else{
    this.base_change="NA";
    this.disable_base_change=true;
  }


  console.log(transdet_apr.rel_level);



  for(var i=0;i<this.releaselevel_list_apr.length;i++)
  {
    if(transdet_apr.rel_level==this.releaselevel_list_apr[i].releaselevel)
    {
      this.selected_release_level=this.releaselevel_list_apr[i];
      break;
    }
  }


  if(transdet_apr.cd_ownrshp_strat!=null && transdet_apr.cd_ownrshp_strat!=0 && transdet_apr.cntr_ownrshp_strat_code!=null && transdet_apr.cntr_ownrshp_strat_code!="")
  {

    console.log("entergetcosdet")

    this.transactionService.getcosdetails(transdet_apr.cntr_ownrshp_strat_code,transdet_apr.cd_ownrshp_strat).subscribe(cosdetails_apr=>{
      console.log("cosdetails_apr",cosdetails_apr);


      if(cosdetails_apr != null && cosdetails_apr.acptnce_email_ind != null && cosdetails_apr.acptnce_email_ind.toLowerCase()=="yes")
      {
        this.cosForEmailAPI=cosdetails_apr.ownrshp_strat_code;
        this.emailSentUponAcceptance=true;
      }
      else
      {
        this.emailSentUponAcceptance=false;
      }

      if(cosdetails_apr.pkg_sign_off.toLowerCase()=="required")
      {
        if(this.apr_current_roleid==6)
        {
          console.log("enter 6");
        this.upload_pcksgnoff_req=true;
        }
        else
        {
          this.upload_pcksgnoff_req=false;
        }
        this.upload_pcksgnoff_display=true;


        console.log("enter99999",this.upload_pcksgnoff_display)
        console.log(cosdetails_apr)
      }


      else if(cosdetails_apr.pkg_sign_off.toLowerCase()=="optional")
      {
        this.upload_pcksgnoff_req=false;
        this.upload_pcksgnoff_display=true;
      }
      else{
        this.upload_pcksgnoff_req=false;
        this.upload_pcksgnoff_display=false;
      }




    })

  }


  else
  {
    this.upload_pcksgnoff_req=false;
        this.upload_pcksgnoff_display=false;



  }


  this.pcksgnoff_exist=transdet_apr.pkgsignoff;
  this.get_pcksgnoff();












})
//}


this.view_proposal_apprv=true;


//this.proposalEmitter.emit(this.view_proposal_apprv);


}

on_change_values_inreq_tst_pack_radio_btn()
{
  if(this.existing_test_pack_requested_status.toLowerCase()=="yes")
  {
    this.show_request_test_pack_button=false;
    this.enable_radio_buttons_for_tst_pack=true;


    if(this.existing_test_pack_received_status.toLowerCase()=="yes")
    {
      if(this.existing_test_pack_evaluated_status.toLowerCase()=="yes")
      {
        this.diable_approve_btn_rqst_tst_pack=false;
        this.disable_reject_btn_rqst_tst_pack=false;
      }
      else
      {
        this.diable_approve_btn_rqst_tst_pack=true;
        this.disable_reject_btn_rqst_tst_pack=false;
      }
    }
    else
    {
      if(this.existing_test_pack_received_status.toLowerCase()=="no")
      {
        this.diable_approve_btn_rqst_tst_pack=true;
        this.disable_reject_btn_rqst_tst_pack=false;
      }
      else
      {
        this.diable_approve_btn_rqst_tst_pack=true;
        this.disable_reject_btn_rqst_tst_pack=false;
      }
    }

    this.transactionService.updateRequestTestPackDetails(this.selectedsimpliedpartnodetails_apr.proposalid,this.existing_test_pack_received_status,this.existing_test_pack_evaluated_status).subscribe(updstat=>{
      console.log("Success",updstat);
      if(updstat=="Success")
      {
        //this.messageService.add({severity:'success', summary: 'Success', detail: 'Test Pack Details updated!!'});
      }
      else
      {
        this.messageService.add({severity:'error', summary: 'Error', detail: 'Test Pack Details not updated!!'});
      }
    })
  }
  else
  {
    this.show_request_test_pack_button=true;
    this.enable_radio_buttons_for_tst_pack=false;
    this.diable_approve_btn_rqst_tst_pack=false;
    this.disable_reject_btn_rqst_tst_pack=false;
  }
}


/* not used offsite supplier butons
// offsite supplier checking removed as flow made linear and not parallel
on_change_values_inreq_tst_pack_radio_btn_offsite()
{
  if(this.existing_test_pack_requested_status_offsite != null && this.existing_test_pack_requested_status_offsite.toLowerCase()=="yes")
  {
    this.show_request_test_pack_button_offsite=false;
    this.enable_radio_buttons_for_tst_pack_offsite=true;


    if(this.existing_test_pack_received_status_offsite != null && this.existing_test_pack_received_status_offsite.toLowerCase()=="yes")
    {
      if(this.existing_test_pack_evaluated_status_offsite != null && this.existing_test_pack_evaluated_status_offsite.toLowerCase()=="yes")
      {
        this.diable_approve_btn_rqst_tst_pack_offsite=false;
        this.disable_reject_btn_rqst_tst_pack_offsite=false;
      }
      else
      {
        this.diable_approve_btn_rqst_tst_pack_offsite=true;
        this.disable_reject_btn_rqst_tst_pack_offsite=false;
      }
    }
    else
    {
      if(this.existing_test_pack_evaluated_status_offsite != null && this.existing_test_pack_received_status_offsite.toLowerCase()=="no")
      {
        this.diable_approve_btn_rqst_tst_pack_offsite=true;
        this.disable_reject_btn_rqst_tst_pack_offsite=false;
      }
      else
      {
        this.diable_approve_btn_rqst_tst_pack_offsite=true;
        this.disable_reject_btn_rqst_tst_pack_offsite=false;
      }
    }

    this.transactionService.updateRequestTestPackDetailsOffsite(this.existing_test_pack_received_status_offsite,this.existing_test_pack_evaluated_status_offsite,this.selectedsimpliedpartnodetails_apr.proposalid).subscribe(updstat=>{
      console.log("Success",updstat);
      if(updstat.toLowerCase() == "success")
      {
        this.messageService.add({severity:'success', summary: 'Success', detail: 'Test Pack Details updated!!'});
      }
      else
      {
        this.messageService.add({severity:'error', summary: 'Error', detail: 'Test Pack Details not updated!!'});
      }
    })
  }
  else
  {
    this.show_request_test_pack_button_offsite=true;
    this.enable_radio_buttons_for_tst_pack_offsite=false;
    this.diable_approve_btn_rqst_tst_pack_offsite=false;
    this.disable_reject_btn_rqst_tst_pack_offsite=false;
  }
}
*/
get_pcksgnoff()
{


  console.log("upload pck1");
  console.log(this.pcksgnoff_exist);


  console.log(this.upload_pcksgnoff_req);
  console.log(this.upload_pcksgnoff_display);
  if(this.pcksgnoff_exist!=null && this.pcksgnoff_exist!="")
  {


    var pcksgnoff_arr=[];
    pcksgnoff_arr=this.pcksgnoff_exist.split('/');
    var len_pck=pcksgnoff_arr.length
    this.pckgsgnoff_filename=pcksgnoff_arr[len_pck-1];
    let url = this.baseurl_get_pcksgnoff+this.pcksgnoff_exist;
    this.transactionService.getPdfFile(url).subscribe((res) => {
      this.pcksgnoff_filepath = URL.createObjectURL(res);
    });
    this.pcksgnoff_uploaded=true;


  }
  else{


    this.pckgsgnoff_filename="";
    this.pcksgnoff_filepath="";
    this.pcksgnoff_uploaded=false;

  }
}



browse_pcksgnoff_doc()
{


  this.pcksgnoff_upload_dialog=true;

}



pcksgnoff_imageUpload(event, form1) {
  this.disable_pck_sgn_off_uplod=true;
  console.log("sbupl_imageUpload photo upload is working");
  for(let file of event.files) {
     this.uploadedFiles.push(file);
     this.uplo=file;
  }
  form1.clear();
          this.image_folder_path=this.selectedsimpliedpartnodetails_apr.proposalid.toString();


          var path_pcksgnoff="pkgsignoff/"
          var extention=this.uplo.name.split('?')[0].split('.').pop();
    //alert("Propsal ID "+this.image_folder_path);
    this.transactionService.pcksgnoff_postFile(this.uplo,this.image_folder_path).subscribe((data => {
      let res=data;
      this.respdata=res;
      console.log("Response",res);
         if(res == this.imgsave_path+path_pcksgnoff+this.image_folder_path+"_pkgsignoff."+extention) {
        // alert("successfully uploaded "+res)
          console.log("Upload is success "+ res);


         // this.pcksgnoff_uploaded=true;

        setTimeout( ()=>{
          console.log('works')
          this.pckgsgnoff_filename="";
          }, 5000);

          setTimeout( ()=>{
            console.log('works1')
          //this.ShippingUnitPhotoImage();
          this.disable_pck_sgn_off_uplod=false;
         this.pcksgnoff_exist=res//assign the return value after success
          //this.save_tab4_image();
             this.get_pcksgnoff();
          }, 5000)
       this.pcksgnoff_upload_dialog=false;
           }
            else   {
              this.disable_pck_sgn_off_uplod=false;
           console.log("Upload is not success "+res);
           this.pcksgnoff_upload_dialog=false;
           this.messageService.add({severity:'error', summary: 'Error', detail: 'pcksgnoff not uploaded,Please Try Again'});



                   }
      }));

}






additionalApproverEnable()
{
  this.enableAdditionalApproverDialog=true;
  this.populateListOfAddlnApproversToAdd();
}

additionalApproverSaveAndClosed()
{
  // data will be already there in the array so just closing
  this.enableAdditionalApproverDialog=false;
}


cancelAdditionalApproverAdded()
{
  this.additionalApproversList=[];
  this.choosedAddlnAprovers=[];
  this.getAddedAddlnApprvrList=[];
  this.removedAddlnApprovers=[];
  this.enableAdditionalApproverDialog=false;
}

populateListOfAddlnApproversToAdd()
{
 /* this.additionalApproversList=[];
  this.choosedAddlnAprovers=[];
  this.getAddedAddlnApprvrList=[];
  this.removedAddlnApprovers=[];*/
/* not used
  this.transactionService.loadAlreadyAddedAddlnApprvr(this.selectedsimpliedpartnodetails_apr.proposalid).subscribe(response=>{
    this.alreadyAddedApprovers=response;
    if(this.alreadyAddedApprovers.length==0)
    {
      this.LoadingAddlnAppvrList(false);
    }

    else
    {
      this.LoadingAddlnAppvrList(true);
    }


  })*/


  this.LoadingAddlnAppvrList(false);
}

LoadingAddlnAppvrList(approversAlreadyPresentOrAdded:boolean)
{
  /*this.additionalApproversList=[];
  this.choosedAddlnAprovers=[];
  this.getAddedAddlnApprvrList=[];
  this.removedAddlnApprovers=[];*/

  console.log("selectedsimpliedpartnodetails_apr",this.selectedsimpliedpartnodetails_apr);
  this.transactionService.getAdditionalApproversList(this.selectedsimpliedpartnodetails_apr.plantgsdbcode,this.current_region_code_apr).subscribe(apprlist=>{
    console.log("Approver List",apprlist);
    var suppname;
    const tempApprlist:AdditionalApproversForUI[]=[]
    this.transactionService.getsuppdet(this.selectedsimpliedpartnodetails_apr.supplier_code,'N').subscribe(supout=>
    {

      suppname=supout.fullname;

      for(var i=0;i<apprlist.length;i++)
      {
        var disp=apprlist[i].name+' - '+suppname.trim()+ '('+apprlist[i].email+')';
        tempApprlist.push({name:apprlist[i].name,email:apprlist[i].email,isforduser:apprlist[i].isforduser,gppuserid:apprlist[i].gppuserid,cd_supplier:apprlist[i].cd_supplier,suppliername:apprlist[i].suppname,for_display:disp,isaprvd:'No'})
      }

      this.additionalApproversList=JSON.parse(JSON.stringify(tempApprlist));

      this.cols1=[
        {field:'for_display',header:'Available Additional Approvers'}
      ]

      this.cols2=[
        {field:'for_display',header:'Assigned Additional Approvers'}
      ]

      if(this.getAddedAddlnApprvrList.length != 0)
      {
        console.log("call load selected approver list loadExistingApproverList")
        this.loadExistingApproverList(this.getAddedAddlnApprvrList)
      }
      if(approversAlreadyPresentOrAdded)
      {
        console.log("call loadAlreadyAddedOrApprovedAdditionalApprovers fn ",this.alreadyAddedApprovers)
        this.loadAlreadyAddedOrApprovedAdditionalApprovers(this.alreadyAddedApprovers);
      }


    });
  })


}
loadExistingApproverList(existApprovers:AdditionalApproversForUI[])
{
  // logic to remove the added additional approver from the total list of additional approver
console.log("exist approver list")
if(this.additionalApproversList.length!=0 )
{
  for(var i=0; i<existApprovers.length;i++)
  {
    for(var j=0;j<this.additionalApproversList.length;j++)
    {
      if(existApprovers[i].gppuserid==this.additionalApproversList[j].gppuserid)
      {

        this.additionalApproversList[j].isaprvd=existApprovers[i].isaprvd
        //this.getAddedAddlnApprvrList.push(this.additionalApproversList[j]);
        this.additionalApproversList.splice(j,1);
        //alert(JSON.stringify(this.getAddedAddlnApprvrList))
        //break;
      }
    }
  }
}
}

// not used
loadAlreadyAddedOrApprovedAdditionalApprovers(alreadyAddedApprovers:additionalApprForPost[])
{
// Write logic to load already added additional Approvers to getAddedAddlnApprvrList (only valid approvers by comparing with additionalApproversList) only if get additinal approver list is empty -> meaning this si the first time they opne form and add approver , other times if additional approver already exsist we should not again load it with data from db

if(this.additionalApproversList.length!=0 && this.getAddedAddlnApprvrList.length == 0)
{
  for(var i=0; i<alreadyAddedApprovers.length;i++)
  {
    for(var j=0;j<this.additionalApproversList.length;j++)
    {
      if(alreadyAddedApprovers[i].gppuserid==this.additionalApproversList[j].gppuserid)
      {

        this.additionalApproversList[j].isaprvd=alreadyAddedApprovers[i].isaprvd
        this.getAddedAddlnApprvrList.push(this.additionalApproversList[j]);
        this.additionalApproversList.splice(j,1);
        //alert(JSON.stringify(this.getAddedAddlnApprvrList))
        break;
      }
    }
  }
}
}




addSelectedAddlnApprvr()
{
    var tempupdatedapprs:AdditionalApproversForUI[]=this.additionalApproversList;
    for(var i of this.choosedAddlnAprovers)
    {
      if(tempupdatedapprs.some((el)=>el.for_display==i.for_display))
      {
        var ind=tempupdatedapprs.indexOf(i);
        tempupdatedapprs.splice(ind,1);

        if(!(this.getAddedAddlnApprvrList.some(po=>po.for_display==i.for_display)))
        {
          this.getAddedAddlnApprvrList.splice(0,0,i);
        }
      }
    }
    this.additionalApproversList=tempupdatedapprs;
  console.log("add selected additional approver ", this.getAddedAddlnApprvrList)
  console.log("add selected additional approver ", this.additionalApproversList)
  this.choosedAddlnAprovers=[];
}

removeSelectedAddlnApprvr()
{
  var tempupdatein2ndgridmaster=this.getAddedAddlnApprvrList;
  for( var l of this.removedAddlnApprovers)
  {
    if(tempupdatein2ndgridmaster.some(ele=>ele.for_display==l.for_display))
    {
      var index=tempupdatein2ndgridmaster.indexOf(l);
      tempupdatein2ndgridmaster.splice(index,1);

      if(!(this.additionalApproversList.some(el=>el.for_display==l.for_display)))
      {
        this.additionalApproversList.splice(0,0,l);
      }
    }
  }
  this.getAddedAddlnApprvrList=tempupdatein2ndgridmaster;
  console.log("removed selected additional approver ", this.getAddedAddlnApprvrList)
  console.log("removed selected additional approver ", this.additionalApproversList)
  this.removedAddlnApprovers=[];

}

SaveAddedAddlnApprvr()
{
  this.AdditionalApproverToBeDeleted="";
  this.AddedAddlnApproverForPOST=[];
  console.log("already added approver list",this.alreadyAddedApprovers)
  console.log("additional approver list",this.getAddedAddlnApprvrList)
    for(var i of this.getAddedAddlnApprvrList)
    {
      console.log("additional approver list",i)
      if(this.alreadyAddedApprovers.every(el=>el.gppuserid!=i.gppuserid))
      {

        var indextobeinserted=this.getAddedAddlnApprvrList.indexOf(i);
        this.AddedAddlnApproverForPOST.push({
          'id_proposal':this.selectedsimpliedpartnodetails_apr.proposalid,
          'email_id':this.getAddedAddlnApprvrList[indextobeinserted].email,
          'gppuserid':this.getAddedAddlnApprvrList[indextobeinserted].gppuserid,
          'isaprvd':this.getAddedAddlnApprvrList[indextobeinserted].isaprvd,
          'isforduser':this.getAddedAddlnApprvrList[indextobeinserted].isforduser,
          'supgsdbcode':this.getAddedAddlnApprvrList[indextobeinserted].cd_supplier
        })
        console.log("additional approver list",this.AddedAddlnApproverForPOST)
      }
    }

    for(var j of this.alreadyAddedApprovers)
    {
      var indextobedeleted=this.alreadyAddedApprovers.indexOf(j);
      if(this.getAddedAddlnApprvrList.every(ele=>ele.gppuserid!=j.gppuserid))
      {
        this.AdditionalApproverToBeDeleted+=this.alreadyAddedApprovers[indextobedeleted].gppuserid;
      }
    }



  //alert(JSON.stringify(this.AddedAddlnApproverForPOST))
  //alert(this.AdditionalApproverToBeDeleted);

  /*for(var op of this.getAddedAddlnApprvrList )
  {
    this.AddedAddlnApproverForPOST.push({'id_proposal':this.selectedsimpliedpartnodetails_prlim_apr.proposalid,'email_id':op.email,'gppuserid':op.gppuserid,'isforduser':op.isforduser,'supgsdbcode':op.cd_supplier,'isaprvd':op.isaprvd})
  }

  alert(JSON.stringify(this.AddedAddlnApproverForPOST))
  alert(this.AdditionalApproverToBeDeleted);*/

}


addDelAdditionalAprroverPostAPICall()
{
  console.log("addDelAdditionalAprroverPostAPICall fn - additional approver list",this.AddedAddlnApproverForPOST)
  if(this.AddedAddlnApproverForPOST.length!=0)
  {
    console.log("additional approver list",this.AddedAddlnApproverForPOST)
    this.transactionService.saveAddlnApprovers(this.AddedAddlnApproverForPOST).subscribe(res=>
      {
        console.log("Success",res);
        if(res=="Success")
        {
          this.messageService.add({severity:'success', summary:'Success', detail:'Additional Approver Added Successfully'});
          this.trigger_email_when_addln_apprvr_added(this.AddedAddlnApproverForPOST);

          this.apply_filter_apr();
        }
        else
        {
          this.messageService.add({severity:'error', summary:'Error', detail:'Additional Approver Not Added'});
        }
      })
  }


  if(this.AdditionalApproverToBeDeleted!="")
  {
    this.transactionService.deleteAddlnApprvr(this.selectedsimpliedpartnodetails_apr.proposalid,this.AdditionalApproverToBeDeleted).subscribe(status=>{
      console.log("Success",status);
      if(status=="Success")
      {
        this.messageService.add({severity:'success', summary:'Success', detail:'Additional Approver Deleted Successfully'});

      }
      else
      {
        this.messageService.add({severity:'error', summary:'Error', detail:'Additional Approver Not Deleted'});
      }
    })
  }

  this.enableAdditionalApproverDialog=false;

  this.apply_filter_apr();
  this.view_proposal_apprv=false;
}

trigger_email_when_addln_apprvr_added(addlnApprvr:additionalApprForPost[])
{
  var cdsids="";
  var cur_date_base_chg;
  var cur_date_transform_base_chg
  cur_date_base_chg=new Date();
  cur_date_transform_base_chg=this.date.transform(cur_date_base_chg, 'yyyy-MM-dd HH:mm:ss');

  for(var i of addlnApprvr)
  {
    cdsids+=i.gppuserid;
    if(addlnApprvr.indexOf(i)<addlnApprvr.length-1)
    {
      cdsids+=",";
    }
  }
  //alert(cdsids);
  this.transactionService.send_email_notification(this.selectedsimpliedpartnodetails_apr.proposalid,"NA","NA",cdsids,"N","06",this.apr_cdsid,cur_date_transform_base_chg,this.current_region_code_apr).subscribe(addlnappraddstat=>
    {
      console.log(addlnappraddstat);

      if(addlnappraddstat.toLowerCase()=="success")
      {
        this.messageService.add({severity:'success', summary: 'Success', detail: 'Additional Approver Email sent successfully'});
      }
      else
      {
        this.messageService.add({severity:'error', summary: 'Error', detail: 'Error in Email sending for Addtional Appprover'});
      }

    })
}

request_test_pack_dialog()
{
  this.show_request_tst_pack_pop_up=true;
  const partselected=this.transactionService.getpartno_packpro(this.current_region_apr);

  this.request_test_pack_email_subject="Requesting Test Pack";
  this.request_copy_me_on_email=false;
  this.request_test_pack_email_body="";
  this.transactionService.getTestPackMailInstruction(partselected.plantgsdbcode).subscribe(inst=>{

    if(inst.test_pack_mail_instns!=null)
    {
      this.request_test_pack_email_body=inst.test_pack_mail_instns;

    }
  })
}

close_req_tst_pck_dialog()
{
  this.show_request_tst_pack_pop_up=false;
}

send_test_pck_mail()
{
  var cur_date_base_chg;
  var cur_date_transform_base_chg
  cur_date_base_chg=new Date();
  cur_date_transform_base_chg=this.date.transform(cur_date_base_chg, 'yyyy-MM-dd HH:mm:ss');

  const details=this.transactionService.getpartno_packpro(this.current_region_apr);


  /* should be moved inside email api call */


  var cc:string="";
  if(this.request_copy_me_on_email)
  {
    cc="Y";
  }
  else
  {
    cc="N";
  }

  /*alert(this.request_test_pack_email_subject);
  alert(this.request_test_pack_email_body);
  alert(cc)*/

  this.transactionService.requestTestPackEmailNotification(details.proposalid,this.apr_cdsid,cur_date_transform_base_chg,this.request_test_pack_email_subject,cc,this.current_region_code_apr,this.request_test_pack_email_body).subscribe(emailstatustestpack=>{

    console.log(emailstatustestpack);

    if(emailstatustestpack.toLowerCase()=="success")
    {
      this.messageService.add({severity:'success', summary: 'success', detail: 'Email sent for Request test pack successfully'});
      this.show_request_tst_pack_pop_up=false;
      this.enable_radio_buttons_for_tst_pack=true;
      this.show_request_test_pack_button=false;
      this.view_proposal_apprv=false;
      //this.show_request_tst_pack_pop_up=false;
    }
    else
    {
      this.messageService.add({severity:'error', summary: 'Error', detail: 'Error in Email sending for base change'});
      this.show_request_tst_pack_pop_up=false;
    }
  })
}


//Offsite supplier -----------------------------------------------------------------------------------
// offsite supplier checking removed as flow made linear and not parallel
/*
request_test_pack_dialog_offsite()
{
  this.show_request_tst_pack_pop_up_offsite=true;
  const partselected_offsite=this.transactionService.getpartno_packpro(this.current_region_apr);

  this.request_test_pack_email_subject_offsite="Requesting Test Pack";
  this.request_copy_me_on_email_offsite=false;
  this.request_test_pack_email_body_offsite="";
  this.transactionService.getTestPackMailInstruction(partselected_offsite.plantgsdbcode).subscribe(inst=>{

    if(inst.test_pack_mail_instns!=null)
    {
      this.request_test_pack_email_body_offsite=inst.test_pack_mail_instns;

    }
  })
}

close_req_tst_pck_dialog_offsite()
{
  this.show_request_tst_pack_pop_up_offsite=false;
}

send_test_pck_mail_offsite()
{
  var cur_date_base_chg;
  var cur_date_transform_base_chg
  cur_date_base_chg=new Date();
  cur_date_transform_base_chg=this.date.transform(cur_date_base_chg, 'yyyy-MM-dd HH:mm:ss');

  const details_offsite=this.transactionService.getpartno_packpro(this.current_region_apr);


  // should be moved inside email api call


  var cc:string="";
  if(this.request_copy_me_on_email_offsite)
  {
    cc="Y";
  }
  else
  {
    cc="N";
  }

  //alert(this.request_test_pack_email_subject);
  //alert(this.request_test_pack_email_body);
  //alert(cc)

  this.transactionService.requestTestPackEmailNotificationOffsite(details_offsite.proposalid,this.apr_cdsid,cur_date_transform_base_chg,this.request_test_pack_email_subject_offsite,cc,this.region,this.request_test_pack_email_body_offsite).subscribe(emailstatustestpackoffsite=>{

    console.log(emailstatustestpackoffsite);

    if(emailstatustestpackoffsite.toLowerCase()=="success")
    {
      this.messageService.add({severity:'success', summary: 'success', detail: 'Email sent for Request test pack successfully'});
      this.show_request_tst_pack_pop_up_offsite=false;
      this.enable_radio_buttons_for_tst_pack_offsite=true;
      this.show_request_test_pack_button_offsite=false;
      this.view_proposal_apprv=false;
      //this.show_request_tst_pack_pop_up=false;
    }
    else
    {
      this.messageService.add({severity:'error', summary: 'Error', detail: 'Error in Email sending for base change'});
      this.show_request_tst_pack_pop_up_offsite=false;
    }
  })
}

accept_proposal_offsite()
{



  var cur_date_accept;
  var cur_date_transform_accept
  cur_date_accept=new Date();
  cur_date_transform_accept=this.date.transform(cur_date_accept, 'yyyy-MM-dd HH:mm:ss');


  console.log(cur_date_transform_accept);


  if(this.cmms_checked==true)
  {
    this.upload_cmms_packaging="X"
  }

  else
  {
    this.upload_cmms_packaging="NA"
  }


  //api to call accept proposal


  console.log("uploadcmms",this.upload_cmms_packaging)


  var alphabet_pres_accept:boolean;
  alphabet_pres_accept=/[a-z]/i.test(this.comments);


  console.log(alphabet_pres_accept)

    if(this.comments==null|| alphabet_pres_accept==false)
  {


    this.comments="-";

  }



  if(this.base_change=="")
    {
      this.base_change="-";
    }


    console.log(this.partBailmentSupplierId,this.partBailmentPlantcode);

    //api call to accept proposal
    var replace_comments=this.comments.replace('|','/').replace('`',"'")
    this.comments=replace_comments;
    console.log("comments accept",this.comments)

    this.transactionService.accept_proposal_offsite_fn(this.selectedsimpliedpartnodetails_apr.proposalid,this.apr_cdsid,cur_date_transform_accept,this.comments,this.selected_release_level.releaselevel,this.base_change,this.partBailmentPlantcode,this.upload_cmms_packaging,this.partBailmentSupplierId).subscribe(result_accept_offsite=>{

      console.log(result_accept_offsite);
      if(result_accept_offsite.toLowerCase()=="success")
      {
        this.messageService.add({severity:'success', summary: 'success', detail: 'Proposal approved successfully'});
    this.apply_filter_apr();
    this.view_proposal_apprv=false;

  }
  else{
    this.messageService.add({severity:'error', summary: 'Error', detail: 'Proposal not approved, Try again'});
  }

    })


}

reject_proposal_offsite()
{

  if(this.base_change=="")
  {
    this.base_change="-";
  }

  var cur_date_reject;
  var cur_date_transform_reject
  cur_date_reject=new Date();
  cur_date_transform_reject=this.date.transform(cur_date_reject, 'dyyyy-MM-dd HH:mm:ss');



  if(this.cmms_checked==true)
  {
    this.upload_cmms_packaging="X"
  }

  else
  {
    this.upload_cmms_packaging="NA"
  }


  var alphabet_pres:boolean;
alphabet_pres=/[a-z]/i.test(this.comments);


console.log(alphabet_pres)

  if(this.comments==null|| alphabet_pres==false)
  {
    //toast stating enter comments


    this.enable_dialog_notification=true;
    this.messageService.add({severity:'error', summary: 'Error', detail: 'Please enter comments to reject proposal'});
  }

  var replace_comments=this.comments.replace('|','/').replace('`',"'")
  this.comments=replace_comments;
  this.transactionService.reject_proposal_offsite_fn(this.selectedsimpliedpartnodetails_apr.proposalid,this.apr_cdsid,cur_date_transform_reject,this.comments,this.selected_release_level.releaselevel,this.base_change,this.upload_cmms_packaging,this.partBailmentSupplierId).subscribe(result_reject_offsite=>{

    console.log(result_reject_offsite);
    if(result_reject_offsite.toLowerCase()=="success")
    {

//if output success
      this.messageService.add({severity:'success', summary: 'success', detail: 'Proposal rejected successfully'});

      this.apply_filter_apr();
        this.view_proposal_apprv=false;
}


else{
  this.messageService.add({severity:'error', summary: 'Error', detail: 'Proposal not rejected tttt, Try again'});
}
})

  //api call to reject proposal

}

//offsite supplier------------------------------------------------
*/
promote_plant_apr()
{


  console.log(this.comments);
  console.log(this.selected_release_level);
  console.log(this.selectedsimpliedpartnodetails_apr.proposalid);
  console.log(this.selectedpart_partstatus_onproposaltype);
  console.log(this.apr_cdsid);
  console.log(this.base_change);




  //api to call accept proposal


  var alphabet_pres_prmt_pltapr:boolean;
  alphabet_pres_prmt_pltapr=/[a-z]/i.test(this.comments);


  console.log(alphabet_pres_prmt_pltapr)

/*
  if(this. disable_rejection_flow== false)

  {

  if(this.comments==null|| alphabet_pres_prmt_pltapr==false)
  {
    //toast stating enter comments


    this.enable_dialog_notification_prm=true;
    this.messageService.add({severity:'error', summary: 'Error', detail: 'Please enter comments to reject proposal'});
  }

  else
  {



this.promote_tofinal_pltapr_fn_call();


  }


}


else{*/


  if(this.comments==null|| alphabet_pres_prmt_pltapr==false)
  {


    this.comments="-";

  }

  this.promote_tofinal_pltapr_fn_call();
//}




}


promote_tofinal_pltapr_fn_call()
{



  var cur_date_prmt_pltapr;
  var cur_date_transform_prmt_pltapr
  cur_date_prmt_pltapr=new Date();
  cur_date_transform_prmt_pltapr=this.date.transform(cur_date_prmt_pltapr, 'yyyy-MM-dd HH:mm:ss');


  console.log(cur_date_transform_prmt_pltapr);

  if(this.base_change=="")
    {
      this.base_change="-";
    }

    if(this.comments==null)
  {


    this.comments="-";

  }


 /*
  if(this.cmms_checked==true)
  {
    this.upload_cmms_packaging="X"
  }

  else
  {
    this.upload_cmms_packaging="NA"
  }*/
  //defaultly upload to cmms as this directly goes to plant final approver  ie crossing release for evaluation
  this.upload_cmms_packaging="X"

  var replace_comments=this.comments.replace('|','/').replace('`',"'")
  this.comments=replace_comments;

  this.transactionService.promote_plt_final_apr_proposal_fn(this.selectedsimpliedpartnodetails_apr.proposalid,this.apr_cdsid,cur_date_transform_prmt_pltapr,this.comments,this.selected_release_level.releaselevel,this.base_change,this.upload_cmms_packaging).subscribe(result_accept=>{

    console.log(result_accept);
    if(result_accept.toLowerCase()=="success")
    {
      this.messageService.add({severity:'success', summary: 'success', detail: 'Proposal approved successfully'});
      // email trigger for release for evaluation once promoted to plant final approver
      if(this.emailSentUponAcceptance)
      {
      this.emailTriggerForRelForEvalAndProd("08");
      }

          var alphabet_pres_base_prm:boolean;
          alphabet_pres_base_prm=/[a-z]/i.test(this.base_change);


          console.log(alphabet_pres_base_prm)
      if(this.base_change.toLowerCase()!=this.exist_cntr_base && alphabet_pres_base_prm!=false && this.base_change!="NA" && this.base_change.toLowerCase()!="tbd"  && this.base_change!="-")
      {

        //api to trigger base change


        this.email_for_basechng();
      }



  this.apply_filter_apr();
  this.view_proposal_apprv=false;

}
else{
  this.messageService.add({severity:'error', summary: 'Error', detail: 'Proposal not approved, Try again'});
}


})
}



accept_proposal()
{
  console.log(this.comments);
  console.log(this.selected_release_level);
  console.log(this.selectedsimpliedpartnodetails_apr.proposalid);
  console.log(this.selectedpart_partstatus_onproposaltype);
  console.log(this.apr_cdsid);
  console.log(this.base_change);


  this.inside_reject_fn=false;



//packaging sign off
    if(this.apr_current_roleid==6)
    {
      console.log("enter role 9")

if(this.upload_pcksgnoff_req==true)
{
  console.log("enter99")
    if(this.pcksgnoff_exist==null || this.pcksgnoff_exist==""||this.pcksgnoff_uploaded==false)
    {
      console.log("enter999")


      this.enable_dialog_notification_upload_pcksgnoff=true;
    this.messageService.add({severity:'error', summary: 'Error', detail: 'Please upload pcksgnoff document to proceed'});

    }


    else{

      this.accept_proposal_service_pck();
    }
  }


  else
  {
    this.accept_proposal_service_pck();
  }
}


else
{
  this.accept_proposal_service_pck();
}


}


accept_proposal_service_pck()
{





    if(this.upload_pcksgnoff_display==true && (this.apr_current_roleid==5 || this.apr_current_roleid==6 || this.apr_current_roleid==9)&& this.pcksgnoff_exist!=null && this.pcksgnoff_exist!="")
    {

      console.log("upload pck");
      console.log(this.pcksgnoff_exist);


      this.transactionService.upload_pcksgnoff_location(this.selectedsimpliedpartnodetails_apr.proposalid,this.pcksgnoff_exist).subscribe(res_apr=>{
        console.log(res_apr);

        if(res_apr.toLowerCase()=="success")
        {
          this.messageService.add({severity:'success', summary: 'success', detail: 'Pcksignoff uploaded successfully'});


          if((this.apr_current_roleid==5||this.apr_current_roleid==6) && this.enable_stamp_details==true)
          {
            this.push_stamping_details();
          }


          else
          {
            this.accept_proposal_service_chk_relprod();
          }




    }
    else
    {

      this.messageService.add({severity:'error', summary: 'Error', detail: 'Pcksignoff not uploaded, so not approved, Try again'});

    }



      })





      //api to post packaging signoff;
    }





else  if((this.apr_current_roleid==5||this.apr_current_roleid==6) && this.enable_stamp_details==true)
{
  this.push_stamping_details();
}


else if( this.apr_current_roleid==11 && this.enable_hotstamp==true)
{



  this.push_hotstamp_details();



}


else{


  this.accept_proposal_service_chk_relprod();

}

}






push_stamping_details()
{
  if((this.press_line!=null && this.press_line!="")|| (this.stamp_destack_method!=null && this.stamp_destack_method!="")||(this.stamp_warehouse_loc!=null && this.stamp_warehouse_loc!=""))
  {
    this.stamping_blank_details.id_proposal=this.selectedsimpliedpartnodetails_apr.proposalid;
    this.stamping_blank_details.pressline=this.press_line;
    this.stamping_blank_details.stmpdstackmthd=this.stamp_destack_method;
    this.stamping_blank_details.stmpwarehseloc=this.stamp_warehouse_loc;


    this.transactionService.upload_stamping_details(this.stamping_blank_details).subscribe(res_stm=>{
      console.log(res_stm);


      if(res_stm.toLowerCase()=="success")
      {
        this.messageService.add({severity:'success', summary: 'success', detail: 'Stamping blank details uploaded successfully'});
        if(this.inside_reject_fn==true)
        {

          this.reject_proposal_service();

        }
        else{


          this.accept_proposal_service_chk_relprod();

        }

  }
  else{
    this.messageService.add({severity:'error', summary: 'Error', detail: 'Stamping blank details not uploaded, Try again'});
  }



    })
  }


  else{
       if(this.inside_reject_fn==true)
        {

          this.reject_proposal_service();
        }

        else
        {
          this.accept_proposal_service_chk_relprod();
        }

  }
}





push_hotstamp_details()
{
  if((this.selected_hotstamidplatecolor!=null && this.selected_hotstamidplatecolor.color_desc!="")|| (this.selected_hotstampcntrcolor!=null && this.selected_hotstampcntrcolor.color_desc!="")||(this.selected_hotstampfontcolor!=null && this.selected_hotstampfontcolor.color_desc!="")||
  (this.selected_hotstamppalletbaselidcolor!=null && this.selected_hotstamppalletbaselidcolor.color_desc!="")||(this.selected_hotstamppalletboxcolor!=null && this.selected_hotstamppalletboxcolor.color_desc!="")||(this.selected_hotstampcolor!=null && this.selected_hotstampcolor.color_desc!="")||
  (this.selected_hotstampcomments!=null && this.selected_hotstampcomments!=""))
  {
    this.hostampdetails.id_proposal=this.selectedsimpliedpartnodetails_apr.proposalid;
    this.hostampdetails.hotstampcntrid=this.selected_hotstampcntrid;
    this.hostampdetails.hotstampcomments=this.selected_hotstampcomments;
    this.hostampdetails.hotstamidplatecolor=this.selected_hotstamidplatecolor.color_desc;
    this.hostampdetails.hotstampcntrcolor=this.selected_hotstampcntrcolor.color_desc;
    this.hostampdetails.hotstampfontcolor=this.selected_hotstampfontcolor.color_desc;
    this.hostampdetails.hotstamppalletbaselidcolor=this.selected_hotstamppalletbaselidcolor.color_desc;
    this.hostampdetails.hotstamppalletboxcolor=this.selected_hotstamppalletboxcolor.color_desc;
    this.hostampdetails.hotstampcolor=this.selected_hotstampcolor.color_desc;



    this.transactionService.upload_hotstamp_details(this.hostampdetails).subscribe(res_hotstm=>{
      console.log(res_hotstm);


      if(res_hotstm.toLowerCase()=="success")
      {
        this.messageService.add({severity:'success', summary: 'success', detail: 'Stamping blank details uploaded successfully'});


        if(this.inside_reject_fn==true)
        {

          this.reject_proposal_service();

        }
        else{


          this.accept_proposal_service_chk_relprod();

        }


  }
  else{
    this.messageService.add({severity:'error', summary: 'Error', detail: 'Stamping blank details not uploaded, Try again'});
  }



    })
  }


  else{
    if(this.inside_reject_fn==true)
    {

      this.reject_proposal_service();

    }
    else
    {
      this.accept_proposal_service_chk_relprod();
    }

  }
}

accept_proposal_service_chk_relprod()
{
  // offsite supplier checking removed as flow made linear and not parallel
 /* if(this.apr_current_roleid==11)
  {


    if(this.is_bailmnt_prt_prsnt != null && this.is_bailmnt_prt_prsnt != "" && this.is_bailmnt_prt_prsnt.toLowerCase() == "yes")
    {


      if(this.offstprtaprvd != null && this.offstprtaprvd != "" && this.offstprtaprvd.toLowerCase() == "yes")
      {
        this.accept_proposal_service();
      }
      else
      {
        this.enable_dialog_notification_bailmentsupplier=true;

      }

    }
    //check for if bailment supplier there and they have approved

    //if not call notification dialog
   // this.enable_dialog_notification_bailmentsupplier=true
   else
   {
     this.accept_proposal_service();

   }
  }
  else
  {
    this.accept_proposal_service();

  }
*/

  this.accept_proposal_service();

}


accept_proposal_service()
{




  var cur_date_accept;
  var cur_date_transform_accept
  cur_date_accept=new Date();
  cur_date_transform_accept=this.date.transform(cur_date_accept, 'yyyy-MM-dd HH:mm:ss');


  console.log(cur_date_transform_accept);


  if(this.cmms_checked==true)
  {
    this.upload_cmms_packaging="X"
  }

  else
  {
    this.upload_cmms_packaging="NA"
  }


  //api to call accept proposal


  console.log("uploadcmms",this.upload_cmms_packaging)


  var alphabet_pres_accept:boolean;
  alphabet_pres_accept=/[a-z]/i.test(this.comments);


  console.log(alphabet_pres_accept)

    if(this.comments==null|| alphabet_pres_accept==false)
  {


    this.comments="-";

  }



  if(this.base_change=="")
    {
      this.base_change="-";
    }
    var replace_comments=this.comments.replace('|','/').replace('`',"'")
    this.comments=replace_comments;
   console.log("effective",this.effectiveDateInString)

   if(this.releaseForProductionEnable)
    {
      this.disableApproveButtonForRelProd=true;
    }

    this.transactionService.accept_proposal_fn(this.selectedsimpliedpartnodetails_apr.proposalid,this.apr_cdsid,cur_date_transform_accept,this.comments,this.selected_release_level.releaselevel,this.base_change,this.selectedsimpliedpartnodetails_apr.plantgsdbcode,this.upload_cmms_packaging).subscribe(result_accept=>{

      console.log(result_accept);
      if(result_accept.toLowerCase()=="success")
      {
        this.messageService.add({severity:'success', summary: 'success', detail: 'Proposal approved successfully'});
  //if output success
            if(this.releaseForProductionEnable && this.enableEffectiveDateForImprovementProposal && this.effectiveDateInString != null)
            {
              this.transactionService.updateEffectiveDateForImprovementProposal(this.selectedsimpliedpartnodetails_apr.proposalid,this.effectiveDateInString).subscribe(updEffDateResp=>{
                console.log("updateEffectiveDateForImprovementProposal api o/p",updEffDateResp)
                if(updEffDateResp.toLowerCase()=="success")
                {
                  this.messageService.add({severity:'success', summary: 'success', detail: 'Effective Date Updated Successfully'});
                }
                else
                {
                  this.messageService.add({severity:'error', summary: 'Error', detail: 'Effective Date not updated! Try Again'});

                }
              })
            }

          var alphabet_pres_base_approve:boolean;
          alphabet_pres_base_approve=/[a-z]/i.test(this.base_change);


          console.log(alphabet_pres_base_approve)
        if(this.base_change.toLowerCase()!=this.exist_cntr_base.toLowerCase() && alphabet_pres_base_approve!=false && this.base_change!="NA" && this.base_change.toLowerCase()!="tbd" && this.base_change!="-")
        {


        this.email_for_basechng();

        //api to trigger base change
        }
        if(this.releaseForEvalEnable && this.emailSentUponAcceptance)
          {

              this.emailTriggerForRelForEvalAndProd("08");

          }
          else if(this.releaseForProductionEnable && this.emailSentUponAcceptance)
          {
            this.emailTriggerForRelForEvalAndProd("09");
          }

          // add additional approver only after accept of proposal in current stage
          this.SaveAddedAddlnApprvr();
          if(this.AddedAddlnApproverForPOST.length != 0)
          {
            this.addDelAdditionalAprroverPostAPICall();
          }
          this.apply_filter_apr();

          this.view_proposal_apprv=false;
          
          if(this.disableApproveButtonForRelProd && this.releaseForProductionEnable)
          {
            this.disableApproveButtonForRelProd=false;
          }

  }
  else{
    this.messageService.add({severity:'error', summary: 'Error', detail: 'Proposal not approved, Try again'});
  }


  })
}


accept_migration_proposal()
{
  console.log("inside migration approver")



  var cur_date_accept;
  var cur_date_transform_accept
  cur_date_accept=new Date();
  cur_date_transform_accept=this.date.transform(cur_date_accept, 'yyyy-MM-dd HH:mm:ss');


  console.log(cur_date_transform_accept);


  if(this.cmms_checked==true)
  {
    this.upload_cmms_packaging="X"
  }

  else
  {
    this.upload_cmms_packaging="NA"
  }


  //api to call accept proposal


  console.log("uploadcmms",this.upload_cmms_packaging)


  var alphabet_pres_accept:boolean;
  alphabet_pres_accept=/[a-z]/i.test(this.comments);


  console.log(alphabet_pres_accept)

    if(this.comments==null|| alphabet_pres_accept==false)
  {


    this.comments="-";

  }



  if(this.base_change=="")
    {
      this.base_change="-";
    }
    var replace_comments=this.comments.replace('|','/').replace('`',"'")
    this.comments=replace_comments;


    var migrationAppData:migrationApprovalPost[]=[]

    migrationAppData.push({
      idProposal:this.selectedsimpliedpartnodetails_apr.proposalid,
        gppuserid:this.apr_cdsid,
        timeaprvd:cur_date_transform_accept,
        comments:this.comments,
        RelLevel:this.selected_release_level.releaselevel,
        ChgBase:this.base_change,
        plant:this.selectedsimpliedpartnodetails_apr.plantgsdbcode,
        cmmsuploadstat:this.upload_cmms_packaging
    })

    //check if additional approver present or not then send value as "Yes"/"No"
    this.isAdditionalApproverAddedForMigrationAppStage="No"
    this.SaveAddedAddlnApprvr();
    if(this.AddedAddlnApproverForPOST.length != 0)
    {
      this.isAdditionalApproverAddedForMigrationAppStage="Yes"
    }


    console.log("post data to approve one migration proposal", migrationAppData,this.isAdditionalApproverAddedForMigrationAppStage)

    this.transactionService.postMigrationDataForApprove(migrationAppData,this.isAdditionalApproverAddedForMigrationAppStage).subscribe(migOutput=>{

      console.log(migOutput)

      if(migOutput.toLowerCase() == "success")
      {
        this.messageService.add({severity:'success', summary: 'success', detail: 'Proposal approved successfully'});

        // in future if email trigger required for migration proposal to be added here
         // add additional approver only after accept of proposal in current stage

         if(this.AddedAddlnApproverForPOST.length != 0)
         {
           this.addDelAdditionalAprroverPostAPICall();
         }
        this.apply_filter_apr();
       this.view_proposal_apprv=false;
      }
      else
      {
        this.messageService.add({severity:'error', summary: 'Error', detail: 'Proposal not approved, Try again'});
      }
    })

}

rejectMigrationProposal()
{
  var cur_date_accept;
  var cur_date_transform_accept
  cur_date_accept=new Date();
  cur_date_transform_accept=this.date.transform(cur_date_accept, 'yyyy-MM-dd HH:mm:ss');


  console.log(cur_date_transform_accept);


  if(this.cmms_checked==true)
  {
    this.upload_cmms_packaging="X"
  }

  else
  {
    this.upload_cmms_packaging="NA"
  }


  //api to call accept proposal


  console.log("uploadcmms",this.upload_cmms_packaging)


  var alphabet_pres_reject:boolean;
  alphabet_pres_reject=/[a-z]/i.test(this.comments);


  console.log(alphabet_pres_reject)

  if(this.comments==null|| alphabet_pres_reject==false)
  {
    //toast stating enter comments
    this.enable_dialog_notification=true;
    this.messageService.add({severity:'error', summary: 'Error', detail: 'Please enter comments to Reject Proposal'});
  }
  else
  {
    if(this.base_change=="")
    {
      this.base_change="-";
    }
    var replace_comments=this.comments.replace('|','/').replace('`',"'")
    this.comments=replace_comments;

    this.emailRejectDirectOrRejReqAccept = "01";


    var migrationAppDataReject:migrationApprovalPost[]=[]

    migrationAppDataReject.push({
      idProposal:this.selectedsimpliedpartnodetails_apr.proposalid,
        gppuserid:this.apr_cdsid,
        timeaprvd:cur_date_transform_accept,
        comments:this.comments,
        RelLevel:this.selected_release_level.releaselevel,
        ChgBase:this.base_change,
        plant:this.selectedsimpliedpartnodetails_apr.plantgsdbcode,
        cmmsuploadstat:this.upload_cmms_packaging
    })

    console.log("post data to Reject one migration proposal", migrationAppDataReject)

    this.transactionService.postMigrationDataForReject(migrationAppDataReject).subscribe(migOutputReject=>{

      console.log(migOutputReject)

      if(migOutputReject.toLowerCase() == "success")
      {
        this.messageService.add({severity:'success', summary: 'success', detail: 'Proposal rejected successfully'});

        // in future if email trigger required for migration proposal to be added here
        this.email_for_reject(this.comments);
        this.apply_filter_apr();
       this.view_proposal_apprv=false;
      }
      else
      {
        this.messageService.add({severity:'error', summary: 'Error', detail: 'Proposal not Rejected, Try again'});
      }
    })
  }
}

addPartsToSelectedList(event)
{
  console.log("addPartsToSelectedList", event)
  console.log(this.selectedPartsToApprove)
  if(this.selectedPartsToApprove.length != this.apprvrGridList.length)
  {
    if(this.apr_current_roleid == 21)
    {
    this.enableApproveAll = false;
    }
    else if(this.apr_current_roleid == 6 || this.apr_current_roleid == 9 || this.apr_current_roleid == 11)
    {
      this.enableApproveAllOnCos = false;
    }

    for(var i of this.apprvrGridList)
    {
      if(i.id_proposal == event.data.id_proposal && i.bompartmasterid != event.data.bompartmasterid)
      {
        this.selectedPartsToApprove.push(i);
      }
    }
    if(this.apr_current_roleid == 21)
    {
      this.enableApproveAll = true;
      this.enableApproveAllOnCos = false;
    }
    else if(this.apr_current_roleid == 6 || this.apr_current_roleid == 9 || this.apr_current_roleid == 11)
    {
      this.enableApproveAllOnCos = true;
      this.enableApproveAll = false;
      console.log(this.enableApproveAllOnCos)
    }

    
  }
  console.log("selectedPartsToApprove after add", this.selectedPartsToApprove, this.enableApproveAllOnCos, this.apr_current_roleid);
}

removePartsFromSelectedList(event)
{
  console.log("removePartsFromSelectedList", event)
  console.log(this.selectedPartsToApprove)

  if(this.selectedPartsToApprove.length != 0)
  {
    if(this.apr_current_roleid == 21)
    {
    this.enableApproveAll = false;
    }
    else if(this.apr_current_roleid == 6 || this.apr_current_roleid == 9 || this.apr_current_roleid == 11)
    {
      this.enableApproveAllOnCos = false;
    }
    var selectedPartsToApproveLocal=this.selectedPartsToApprove.filter(ele =>ele.id_proposal == event.data.id_proposal)
    if(selectedPartsToApproveLocal != null && selectedPartsToApproveLocal.length != 0)
    {
    for(var i of selectedPartsToApproveLocal)
    {
      var index = this.selectedPartsToApprove.findIndex(ele => ele.id_proposal == i.id_proposal)
      if(index != -1)
      {
        this.selectedPartsToApprove.splice(index,1);
      }
    }
  }
  if(this.apr_current_roleid == 21)
  {
    this.enableApproveAll = true;
    this.enableApproveAllOnCos = false;
  }
  else if(this.apr_current_roleid == 6 || this.apr_current_roleid == 9 || this.apr_current_roleid == 11)
  {
    this.enableApproveAllOnCos = true;
    this.enableApproveAll = false;
  }
  }
  console.log("selectedPartsToApprove after removal", this.selectedPartsToApprove)

}


approveAllParts()
{


  var cur_date_accept;
  var cur_date_transform_accept
  cur_date_accept=new Date();
  cur_date_transform_accept=this.date.transform(cur_date_accept, 'yyyy-MM-dd HH:mm:ss');


  console.log(cur_date_transform_accept);


  if(this.cmms_checked==true)
  {
    this.upload_cmms_packaging="X"
  }

  else
  {
    this.upload_cmms_packaging="NA"
  }


  //api to call accept proposal


  console.log("uploadcmms",this.upload_cmms_packaging)


  var alphabet_pres_accept:boolean;
  alphabet_pres_accept=/[a-z]/i.test(this.comments);


  console.log(alphabet_pres_accept)

    if(this.comments==null|| alphabet_pres_accept==false)
  {


    this.comments="-";

  }



  if(this.base_change=="")
    {
      this.base_change="-";
    }
    var replace_comments=this.comments.replace('|','/').replace('`',"'")
    this.comments=replace_comments;


    var migrationAppData:migrationApprovalPost[]=[]

    for(var i of this.selectedPartsToApprove)
    {
      migrationAppData.push({
          idProposal:i.id_proposal,
          gppuserid:this.apr_cdsid,
          timeaprvd:cur_date_transform_accept,
          comments:this.comments,
          RelLevel:'-',
          ChgBase:this.base_change,
          plant:i.plantgsdbcode,
          cmmsuploadstat:this.upload_cmms_packaging
      })
    }

    // as approval for all parts no additional approver provided , the value is "No"

    this.isAdditionalApproverAddedForMigrationAppStage="No";

    console.log("post data to approve one migration proposal", migrationAppData, this.isAdditionalApproverAddedForMigrationAppStage)

    this.transactionService.postMigrationDataForApprove(migrationAppData,this.isAdditionalApproverAddedForMigrationAppStage).subscribe(migOutputbulk=>{

      console.log(migOutputbulk)

      if(migOutputbulk.toLowerCase() == "success")
      {
        this.messageService.add({severity:'success', summary: 'success', detail: 'Proposal approved successfully'});

        // in future if email trigger required for migration proposal to be added here

        this.apply_filter_apr();
       this.view_proposal_apprv=false;
      }
      else
      {
        this.messageService.add({severity:'error', summary: 'Error', detail: 'Proposal not approved, Try again'});
      }
    })

}

selectedBulkApprovalDataOnCosInit()
{
  this.selectedBulkApprovalDataOnCos={
    gppuserid:"",         
    timestamp:"",          
    comments:"",           
    cos:"",            
    region:"",          
    cmmsuploadstat:"",          
    aprvlStg:"",
    pltidPrpsl:[] 
  }
}
approveAllPartsOnCos()
{
  console.log("selected parts to approve on cos", this.selectedPartsToApprove)
  var cur_date_accept;
  var cur_date_transform_accept
  cur_date_accept=new Date();
  cur_date_transform_accept=this.date.transform(cur_date_accept, 'yyyy-MM-dd HH:mm:ss');


  console.log(cur_date_transform_accept);


    this.upload_cmms_packaging="X"


  //api to call accept proposal


  console.log("uploadcmms",this.upload_cmms_packaging)


  var alphabet_pres_accept:boolean;
  alphabet_pres_accept=/[a-z]/i.test(this.comments);
  
  
  console.log(alphabet_pres_accept)
  
    if(this.comments==null|| alphabet_pres_accept==false)
  {


    this.comments="-";

  }



  if(this.base_change=="")
    {
      this.base_change="-";
    }
    var replace_comments=this.comments.replace('|','/').replace('`',"'")
    this.comments=replace_comments;

    this.selectedBulkApprovalDataOnCosInit();

    this.selectedBulkApprovalDataOnCos.gppuserid=this.apr_cdsid;
    this.selectedBulkApprovalDataOnCos.cos=this.selectedcoslist_apr[0].cos;
    this.selectedBulkApprovalDataOnCos.cmmsuploadstat=this.upload_cmms_packaging;
    this.selectedBulkApprovalDataOnCos.comments="Bulk Approval by "+this.userdetails.name+" ("+this.userdetails.email+")";
    this.selectedBulkApprovalDataOnCos.aprvlStg=this.apprv_status.toString();
    this.selectedBulkApprovalDataOnCos.region=this.current_region_code_apr;
    this.selectedBulkApprovalDataOnCos.timestamp=cur_date_transform_accept;
    this.selectedBulkApprovalDataOnCos.pltidPrpsl=[];

    var propslDtls:propIdpltDtlsBulkApproval[]=[];
    for(var i of this.selectedPartsToApprove)
    {
      propslDtls.push({
        plantgsdbcode:i.plantgsdbcode,
        idproposal:i.id_proposal
      })
    }

   this.selectedBulkApprovalDataOnCos.pltidPrpsl=propslDtls;
   
    console.log("post data to approve bulk proposal", propslDtls, this.selectedBulkApprovalDataOnCos)

    this.transactionService.postBulkApprovalData(this.selectedBulkApprovalDataOnCos).subscribe(outputbulk=>{

      console.log(outputbulk)

      if(outputbulk.toLowerCase() == "success")
      {
        this.messageService.add({severity:'success', summary: 'success', detail: 'Proposal approved successfully'});

        // in future if email trigger required for bulk proposal to be added here

        this.apply_filter_apr();
       this.view_proposal_apprv=false;
      }
      else
      {
        this.messageService.add({severity:'error', summary: 'Error', detail: 'Proposal not approved, Try again'});
      }
    })

}

accept_proposal_addnlapr()
{

  var cdsid=this.apr_cdsid;

  console.log(this.comments);
  console.log(this.selected_release_level);
  console.log(this.selectedsimpliedpartnodetails_apr.proposalid);
  console.log(this.selectedpart_partstatus_onproposaltype);
  console.log(cdsid);
  console.log(this.base_change);


  var cur_date_accept_addnlapr;
  var cur_date_transform_accept_addnlapr
  cur_date_accept_addnlapr=new Date();
  cur_date_transform_accept_addnlapr=this.date.transform(cur_date_accept_addnlapr, 'yyyy-MM-dd HH:mm:ss');


  console.log(cur_date_transform_accept_addnlapr);


  //api to call accept proposal


  var alphabet_pres_accept_addnlapr:boolean;
  alphabet_pres_accept_addnlapr=/[a-z]/i.test(this.comments);


  console.log(alphabet_pres_accept_addnlapr)

    if(this.comments==null|| alphabet_pres_accept_addnlapr==false)
  {


    this.comments="-";

  }



  if(this.base_change=="")
    {
      this.base_change="-";
    }



  if(this.cmms_checked==true)
  {
    this.upload_cmms_packaging="X"
  }

  else
  {
    this.upload_cmms_packaging="NA"
  }


    console.log("insideaddnlapr")

    var replace_comments=this.comments.replace('|','/').replace('`',"'")
    this.comments=replace_comments;

    var commentsToAPI=this.comments;

    if(this.apr_current_roleid==22)
    {
      var proposalSelected=this.apprvrGridList.filter(e=>e.id_proposal==this.selectedsimpliedpartnodetails_apr.proposalid)
      cdsid=proposalSelected[0].gppuserid;

      //var existingCommentsFromUI=this.comments;

      //if(existingCommentsFromUI!=null && existingCommentsFromUI!=undefined && existingCommentsFromUI!="")
      if(commentsToAPI!='-')
      {
        commentsToAPI="Marked as Approved By Region Admin - "+commentsToAPI;
      }
      else
      {
        commentsToAPI="Marked as Approved By Region Admin"
      }
    }


    console.log(this.selectedsimpliedpartnodetails_apr.proposalid,cdsid,cur_date_transform_accept_addnlapr,commentsToAPI,this.selected_release_level.releaselevel,this.base_change,this.selectedsimpliedpartnodetails_apr.plantgsdbcode,this.upload_cmms_packaging);

    this.transactionService.accept_addnlapr_proposal_fn(this.selectedsimpliedpartnodetails_apr.proposalid,cdsid,cur_date_transform_accept_addnlapr,commentsToAPI,this.selected_release_level.releaselevel,this.base_change,this.selectedsimpliedpartnodetails_apr.plantgsdbcode,this.upload_cmms_packaging).subscribe(result_accept_addnlapr=>{

      console.log(result_accept_addnlapr);
      if(result_accept_addnlapr.toLowerCase()=="success")
      {
        this.messageService.add({severity:'success', summary: 'success', detail: 'Proposal approved successfully'});
  //if output success


      var alphabet_pres_base_approve:boolean;
      alphabet_pres_base_approve=/[a-z]/i.test(this.base_change);


      console.log(alphabet_pres_base_approve)
      if(this.base_change.toLowerCase()!=this.exist_cntr_base.toLowerCase() && alphabet_pres_base_approve!=false && this.base_change!="NA" && this.base_change.toLowerCase()!="tbd" && this.base_change!="-")
      {


      this.email_for_basechng();

      //api to trigger base change
      }

    this.apply_filter_apr();
    this.view_proposal_apprv=false;

  }
  else{
    this.messageService.add({severity:'error', summary: 'Error', detail: 'Proposal not approved, Try again'});
  }


  })




}


reject_proposal()
{


  console.log(this.comments);
  console.log(this.selected_release_level);
  console.log(this.selectedsimpliedpartnodetails_apr.proposalid);
  console.log(this.selectedpart_partstatus_onproposaltype);
  console.log(this.apr_cdsid);
  console.log(this.base_change);


  this.inside_reject_fn=true;



var alphabet_pres:boolean;
alphabet_pres=/[a-z]/i.test(this.comments);


console.log(alphabet_pres)

  if(this.comments==null|| alphabet_pres==false)
  {
    //toast stating enter comments


    this.enable_dialog_notification=true;
    this.messageService.add({severity:'error', summary: 'Error', detail: 'Please enter comments to reject proposal'});
  }



  else if((this.apr_current_roleid==5||this.apr_current_roleid==6) && this.enable_stamp_details==true)
  {
    this.push_stamping_details();
  }


  else if( this.apr_current_roleid==11 && this.enable_hotstamp==true)
  {



    this.push_hotstamp_details();



  }


  else
  {


    this.reject_proposal_service();

    //api call to
    //success


  }




}


reject_proposal_service()
{


  if(this.base_change=="")
  {
    this.base_change="-";
  }

  var cur_date_reject;
  var cur_date_transform_reject
  cur_date_reject=new Date();
  cur_date_transform_reject=this.date.transform(cur_date_reject, 'yyyy-MM-dd HH:mm:ss');

  var cdsid=this.apr_cdsid;

  console.log(cur_date_transform_reject);



  if(this.cmms_checked==true)
  {
    this.upload_cmms_packaging="X"
  }

  else
  {
    this.upload_cmms_packaging="NA"
  }

 var replace_comments=this.comments.replace('|','/').replace('`',"'")
  this.comments=replace_comments;

  var commentsToAPI=this.comments;

    if(this.apr_current_roleid==22)
    {
      var proposalSelected=this.apprvrGridList.filter(e=>e.id_proposal==this.selectedsimpliedpartnodetails_apr.proposalid)
      cdsid=proposalSelected[0].gppuserid;

      if(proposalSelected[0].propsl_type!=null && proposalSelected[0].propsl_type.toLowerCase()=="migration")
      {
        if(commentsToAPI!=null && commentsToAPI!=undefined)
        {
          commentsToAPI="Rejected By Region Admin - "+commentsToAPI;
        }
        else
        {
          commentsToAPI="Rejected By Region Admin"
        }
      }
      else
      {
        if(commentsToAPI!=null && commentsToAPI!=undefined)
        {
          commentsToAPI="Rejection Requested By Region Admin - "+commentsToAPI;
        }
        else
        {
          commentsToAPI="Rejection Requested By Region Admin"
        }
      }

    }
    console.log("comments",commentsToAPI);

    this.emailRejectDirectOrRejReqAccept = "01";

 this.transactionService.reject_proposal_fn(this.selectedsimpliedpartnodetails_apr.proposalid,cdsid,cur_date_transform_reject,commentsToAPI,this.selected_release_level.releaselevel,this.base_change,this.upload_cmms_packaging).subscribe(result_reject=>{

  console.log(result_reject);
  if(result_reject.toLowerCase()=="success")
  {

//if output success
this.messageService.add({severity:'success', summary: 'success', detail: 'Proposal rejected successfully'});

/* Added (this.selectedsimpliedpartnodetails_apr.propsl_type!=null && this.selectedsimpliedpartnodetails_apr.propsl_type.toLowerCase()=="migration" )
because now migration proposal will be in Additional Approver and all additional approver screen
and email for rejection should be trigeered for the same*/

if(this.apr_current_roleid==3 || this.apr_current_roleid==11 || (this.selectedsimpliedpartnodetails_apr.propsl_type!=null && this.selectedsimpliedpartnodetails_apr.propsl_type.toLowerCase()=="migration" ))
{




//api to trigger email to reject proposal


this.email_for_reject(this.comments);






  var alphabet_pres_base:boolean;
  alphabet_pres_base=/[a-z]/i.test(this.base_change);


  console.log(alphabet_pres_base)

if(this.base_change.toLowerCase()!=this.exist_cntr_base.toLowerCase() && alphabet_pres_base!=false && this.base_change!="NA" && this.base_change.toLowerCase()!="tbd"  && this.base_change!="-")
{

//api to trigger base change


this.email_for_basechng();
}



}

this.apply_filter_apr();
this.view_proposal_apprv=false;
}


else{
this.messageService.add({severity:'error', summary: 'Error', detail: 'Proposal not rejected, Try again'});
}
})


}


reject_proposal_accept()
{


  console.log(this.comments);
  console.log(this.selected_release_level);
  console.log(this.selectedsimpliedpartnodetails_apr.proposalid);
  console.log(this.selectedpart_partstatus_onproposaltype);
  console.log(this.apr_cdsid);
  console.log(this.base_change);



  var cur_date_reject_accept;
  var cur_date_transform_reject_accept
  cur_date_reject_accept=new Date();
  cur_date_transform_reject_accept=this.date.transform(cur_date_reject_accept, 'yyyy-MM-dd HH:mm:ss');


  console.log(cur_date_transform_reject_accept);

  var alphabet_pres_reject_accept:boolean;
  alphabet_pres_reject_accept=/[a-z]/i.test(this.comments);


  console.log(alphabet_pres_reject_accept)

    if(this.comments==null|| alphabet_pres_reject_accept==false)
  {
    //toast stating enter comments
    this.enable_dialog_notification=true;
    this.messageService.add({severity:'error', summary: 'Error', detail: 'Please enter comments to accept rejection'});
  }

  else
  {

    //api call to
    //success


    if(this.base_change=="")
    {
      this.base_change="-";
    }



  if(this.cmms_checked==true)
  {
    this.upload_cmms_packaging="X"
  }

  else
  {
    this.upload_cmms_packaging="NA"
  }

 var replace_comments=this.comments.replace('|','/').replace('`',"'")
 this.comments=replace_comments;

    this.transactionService.reject_proposal_accept_fn(this.selectedsimpliedpartnodetails_apr.proposalid,this.apr_cdsid,cur_date_transform_reject_accept,this.comments,this.selected_release_level.releaselevel,this.base_change,this.upload_cmms_packaging).subscribe(result_reject_accept=>{

      console.log(result_reject_accept);
      if(result_reject_accept.toLowerCase()=="success")
      {
  //if output success

  this.messageService.add({severity:'success', summary: 'success', detail: 'Rejection accepted successfully'});



    if(this.apr_current_roleid==3)
    {



      this.emailRejectDirectOrRejReqAccept = "02";
      //api to trigger email to reject proposal
      this.transactionService.getRevisionHistoryByProposalId(this.selectedsimpliedpartnodetails_apr.proposalid).subscribe(revHistArray=>{


        console.log("getRevisionHistoryByProposalId API o/p  for reject email",revHistArray);
        
        var commentsSent="";

        commentsSent=this.comments;
        commentsSent+=' <br> <br> '
        
        if(revHistArray != null && revHistArray.length != 0)
        {
          commentsSent+='<u> <b> * Rejection comments from rejection requested user '+ revHistArray[1].usrname + ' (' + revHistArray[1].email + ') : </b> </u> <br> ';
          commentsSent+=revHistArray[1].comments       
        }
        //commentsSent+=this.comments
        this.email_for_reject(commentsSent);
      })

      //this.email_for_reject();


      var alphabet_pres_base_reject:boolean;
      alphabet_pres_base_reject=/[a-z]/i.test(this.base_change);


      console.log(alphabet_pres_base_reject)
  if(this.base_change.toLowerCase()!=this.exist_cntr_base.toLowerCase() && alphabet_pres_base_reject!=false && this.base_change!="NA" && this.base_change.toLowerCase()!="tbd" && this.base_change!="-")
  {


    this.email_for_basechng();

    //api to trigger base change
  }



    }

    this.apply_filter_apr();
    this.view_proposal_apprv=false;
  }


  else{


    this.messageService.add({severity:'error', summary: 'Error', detail: 'Rejection not accepted, Try again'});

  }

  })

  }


}


reject_proposal_denied()
{


  console.log(this.comments);
  console.log(this.selected_release_level);
  console.log(this.selectedsimpliedpartnodetails_apr.proposalid);
  console.log(this.selectedpart_partstatus_onproposaltype);
  console.log(this.apr_cdsid);
  console.log(this.base_change);


  var cur_date_reject_denied;
  var cur_date_transform_reject_denied
  cur_date_reject_denied=new Date();
  cur_date_transform_reject_denied=this.date.transform(cur_date_reject_denied, 'yyyy-MM-dd HH:mm:ss');


  var alphabet_pres_reject_denied:boolean;
  alphabet_pres_reject_denied=/[a-z]/i.test(this.comments);


  console.log(alphabet_pres_reject_denied)

    if(this.comments==null|| alphabet_pres_reject_denied==false)
  {
    //toast stating enter comments


    this.enable_dialog_notification=true;
    this.messageService.add({severity:'error', summary: 'Error', detail: 'Please enter comments to deny rejection'});



  }
  else
  {


    if(this.base_change=="")
    {
      this.base_change="-";
    }



  if(this.cmms_checked==true)
  {
    this.upload_cmms_packaging="X"
  }

  else
  {
    this.upload_cmms_packaging="NA"
  }


  var replace_comments=this.comments.replace('|','/').replace('`',"'")
  this.comments=replace_comments;

    this.transactionService.reject_proposal_denied_fn(this.selectedsimpliedpartnodetails_apr.proposalid,this.apr_cdsid,cur_date_transform_reject_denied,this.comments,this.selected_release_level.releaselevel,this.base_change,this.upload_cmms_packaging).subscribe(result_reject_denied=>{

      console.log(result_reject_denied);
      if(result_reject_denied.toLowerCase()=="success")
      {
        this.messageService.add({severity:'success', summary: 'success', detail: 'Rejection denied successfully'});

        var alphabet_pres_base_reject:boolean;
        alphabet_pres_base_reject=/[a-z]/i.test(this.base_change);


        console.log(alphabet_pres_base_reject)
        if(this.base_change.toLowerCase()!=this.exist_cntr_base.toLowerCase() && alphabet_pres_base_reject!=false && this.base_change!="NA" && this.base_change.toLowerCase()!="tbd" && this.base_change!="-")
        {
          this.email_for_basechng();
        }

        this.apply_filter_apr();
        this.view_proposal_apprv=false;
      }


      else
      {
        this.messageService.add({severity:'error', summary: 'Error', detail: 'Rejection not denied, Try again'});

      }
    })

  }

}



recall_proposal_accept()
{


  console.log(this.comments);
  console.log(this.selected_release_level);
  console.log(this.selectedsimpliedpartnodetails_apr.proposalid);
  console.log(this.selectedpart_partstatus_onproposaltype);
  console.log(this.apr_cdsid);
  console.log(this.base_change);



  var cur_date_recall_accept;
  var cur_date_transform_recall_accept
  cur_date_recall_accept=new Date();
  cur_date_transform_recall_accept=this.date.transform(cur_date_recall_accept, 'yyyy-MM-dd HH:mm:ss');


  console.log(cur_date_transform_recall_accept);

  var alphabet_pres_recall_accept:boolean;
  alphabet_pres_recall_accept=/[a-z]/i.test(this.comments);


  console.log(alphabet_pres_recall_accept)

    if(this.comments==null|| alphabet_pres_recall_accept==false)
  {
    //toast stating enter comments
    this.enable_dialog_notification_recall=true;
    this.messageService.add({severity:'error', summary: 'Error', detail: 'Please enter comments to accept recall'});
  }

  else
  {

    //api call to
    //success


    if(this.base_change=="")
    {
      this.base_change="-";
    }



  if(this.cmms_checked==true)
  {
    this.upload_cmms_packaging="X"
  }

  else
  {
    this.upload_cmms_packaging="NA"
  }

 var replace_comments=this.comments.replace('|','/').replace('`',"'")
 this.comments=replace_comments;

    this.transactionService.recall_proposal_accept_fn(this.selectedsimpliedpartnodetails_apr.proposalid,this.apr_cdsid,cur_date_transform_recall_accept,this.comments).subscribe(result_recall_accept=>{

      console.log(result_recall_accept);
      if(result_recall_accept.toLowerCase()=="success")
      {
  //if output success

  this.messageService.add({severity:'success', summary: 'success', detail: 'Recall accepted successfully'});


  //if email need for recall
 /*   if(this.apr_current_roleid==3)
    {




      //api to trigger email to reject proposal


      this.email_for_reject();


      var alphabet_pres_base_reject:boolean;
      alphabet_pres_base_reject=/[a-z]/i.test(this.base_change);


      console.log(alphabet_pres_base_reject)
  if(this.base_change.toLowerCase()!=this.exist_cntr_base.toLowerCase() && alphabet_pres_base_reject!=false && this.base_change!="NA" && this.base_change.toLowerCase()!="tbd" && this.base_change!="-")
  {


    this.email_for_basechng();

    //api to trigger base change
  }



    }*/

    this.apply_filter_apr();
    this.view_proposal_apprv=false;
  }


  else{


    this.messageService.add({severity:'error', summary: 'Error', detail: 'Recall not accepted, Try again'});

  }

  })

  }


}


recall_proposal_denied()
{


  console.log(this.comments);
  console.log(this.selected_release_level);
  console.log(this.selectedsimpliedpartnodetails_apr.proposalid);
  console.log(this.selectedpart_partstatus_onproposaltype);
  console.log(this.apr_cdsid);
  console.log(this.base_change);


  var cur_date_recall_denied;
  var cur_date_transform_recall_denied
  cur_date_recall_denied=new Date();
  cur_date_transform_recall_denied=this.date.transform(cur_date_recall_denied, 'yyyy-MM-dd HH:mm:ss');


  var alphabet_pres_recall_denied:boolean;
  alphabet_pres_recall_denied=/[a-z]/i.test(this.comments);


  console.log(alphabet_pres_recall_denied)

    if(this.comments==null|| alphabet_pres_recall_denied==false)
  {
    //toast stating enter comments


    this.enable_dialog_notification_recall=true;
    this.messageService.add({severity:'error', summary: 'Error', detail: 'Please enter comments to deny recall'});



  }
  else
  {


    if(this.base_change=="")
    {
      this.base_change="-";
    }



  if(this.cmms_checked==true)
  {
    this.upload_cmms_packaging="X"
  }

  else
  {
    this.upload_cmms_packaging="NA"
  }


  var replace_comments=this.comments.replace('|','/').replace('`',"'")
  this.comments=replace_comments;

    this.transactionService.recall_proposal_denied_fn(this.selectedsimpliedpartnodetails_apr.proposalid,this.apr_cdsid,cur_date_transform_recall_denied,this.comments).subscribe(result_recall_denied=>{

      console.log(result_recall_denied);
      if(result_recall_denied.toLowerCase()=="success")
      {
        this.messageService.add({severity:'success', summary: 'success', detail: 'Recall denied successfully'});

        this.apply_filter_apr();
        this.view_proposal_apprv=false;
      }


      else
      {
        this.messageService.add({severity:'error', summary: 'Error', detail: 'Recall not denied, Try again'});

      }
    })

  }

}


ok_notification_dialog()
{
  this.enable_dialog_notification=false;
}


ok_notification_dialog_recall()
{
  this.enable_dialog_notification_recall=false;
}



ok_notification_dialog_prm()
{
  this.enable_dialog_notification_prm=false;
}


ok_notification_dialog_upload_pcksgnoff()
{
  this.enable_dialog_notification_upload_pcksgnoff=false;
}



ok_notification_dialog_bailmentsupplier()
{
  this.enable_dialog_notification_bailmentsupplier=false;
}



email_for_basechng()
{


  console.log(this.comments);
  console.log(this.selectedsimpliedpartnodetails_apr.proposalid);
  console.log(this.selectedpart_partstatus_onproposaltype);
  console.log(this.apr_cdsid);
  console.log(this.base_change);


  this.email_list="NA";


  this.email_notification_eventid="05";



  var cur_date_base_chg;
  var cur_date_transform_base_chg
  cur_date_base_chg=new Date();
  cur_date_transform_base_chg=this.date.transform(cur_date_base_chg, 'yyyy-MM-dd HH:mm:ss');

 var copy_me_basechg;
 copy_me_basechg="N";
  this.transactionService.send_email_notification(this.selectedsimpliedpartnodetails_apr.proposalid,this.base_change,this.comments,this.email_list,copy_me_basechg,this.email_notification_eventid,this.apr_cdsid,cur_date_transform_base_chg,this.current_region_code_apr).subscribe(email_status_basechg=>{



    console.log(email_status_basechg);


    if(email_status_basechg.toLowerCase()=="success")
    {


      this.messageService.add({severity:'success', summary: 'success', detail: 'Email sent for base change successfully'});




    }


    else{


      this.messageService.add({severity:'error', summary: 'Error', detail: 'Error in Email sending for base change'});

    }
  })





}



email_for_reject(commentsReceived:any)
{


  console.log(commentsReceived);
  console.log(this.selectedsimpliedpartnodetails_apr.proposalid);
  console.log(this.selectedpart_partstatus_onproposaltype);
  console.log(this.apr_cdsid);
  console.log(this.emailRejectDirectOrRejReqAccept);


  this.email_list="NA";


  this.email_notification_eventid="04";



  var cur_date_email_reject;
  var cur_date_transform_email_reject
  cur_date_email_reject=new Date();
  cur_date_transform_email_reject=this.date.transform(cur_date_email_reject, 'yyyy-MM-dd HH:mm:ss');

var copy_me_reject
copy_me_reject="N"
var encodedcomments=encodeURIComponent(commentsReceived);

// in the place of base change send its direct reject(01) or reject req accept code(02) 

this.transactionService.send_email_notification(this.selectedsimpliedpartnodetails_apr.proposalid,this.emailRejectDirectOrRejReqAccept,encodedcomments,this.email_list,copy_me_reject,this.email_notification_eventid,this.apr_cdsid,cur_date_transform_email_reject,this.current_region_code_apr).subscribe(email_status_reject=>{


    console.log(email_status_reject);


    if(email_status_reject.toLowerCase()=="success")
    {


      this.messageService.add({severity:'success', summary: 'success', detail: 'Email sent for rejection successfully'});




    }


    else{


      this.messageService.add({severity:'error', summary: 'Error', detail: 'Error in Email sending rejection'});

    }
  })

}

emailTriggerForRelForEvalAndProd(eventid:string)
{
  this.email_list="NA";


var copy_me_rel_for_eval_prod
copy_me_rel_for_eval_prod="N"

var cur_date_email_rel_for_eval_prod;
  var cur_date_transform_email_rel_for_eval_prod
  cur_date_email_rel_for_eval_prod=new Date();
  cur_date_transform_email_rel_for_eval_prod=this.date.transform(cur_date_email_rel_for_eval_prod, 'yyyy-MM-dd HH:mm:ss');


  this.transactionService.send_email_notification(this.selectedsimpliedpartnodetails_apr.proposalid,this.base_change,this.cosForEmailAPI,this.email_list,copy_me_rel_for_eval_prod,eventid,this.apr_cdsid,cur_date_transform_email_rel_for_eval_prod,this.current_region_code_apr).subscribe(email_for_rel_for_eval_and_prod_stat=>
    {
      console.log("Success",email_for_rel_for_eval_and_prod_stat);

      if(email_for_rel_for_eval_and_prod_stat.toLowerCase()=="success")
      {
        this.messageService.add({severity:'success', summary: 'success', detail: 'Email sent for Approval successfully'});

      }
      else
      {
        this.messageService.add({severity:'error', summary: 'Error', detail: 'Error in Email sending Approval (Rel for eval /Prod)'});

      }
    })
}





}
