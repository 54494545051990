/* tslint:disable */
import { Component, OnInit } from '@angular/core';

import {TransactionService} from '../loginpage/Transaction.service';
import { NavComponent } from '../nav/nav.component';

@Component({
  selector: 'app-europe',
  templateUrl: './europe.component.html',
  styleUrls: ['./europe.component.css']
})
export class EuropeComponent implements OnInit {



  region_code='';
  region='';
  cdsid='';

  constructor(private transactionService: TransactionService,private navigation:NavComponent){}
  ngOnInit(): void {

  }


  }



