import { Component, OnInit } from '@angular/core';


import {TransactionService} from '../loginpage/Transaction.service';
import { NavComponent } from '../nav/nav.component';

@Component({
  selector: 'app-southamerica',
  templateUrl: './southamerica.component.html',
  styleUrls: ['./southamerica.component.css']
})
export class SouthamericaComponent implements OnInit {



  region='';
    // tslint:disable-next-line:variable-name
  region_code='';
  cdsid='';

  constructor(private transactionService: TransactionService,private navigation:NavComponent){}
  ngOnInit(): void {

  }

}
