/* tslint:disable */



import {Component, OnInit,AfterViewInit} from '@angular/core';


import {TransactionService} from '../loginpage/Transaction.service';


import { DatePipe } from '@angular/common';


import { ActivatedRoute, Router } from '@angular/router';


import {fedebomfilters,dropdownlist,putuserfiltervalues,fedebomfiltervalues,fedebomgridop} from '../loginpage/transactions';




/** Component that has no functionality other than plain html text in the template */
@Component({
	selector: 'app-example-page',
	templateUrl: './example-page.component.html',
	styleUrls: ['./example-page.component.scss'],
	providers: [DatePipe]
})

export class ExamplePageComponent implements OnInit  {


	test:any[]=[
		{
			labels:'plant',
			values:[{plant:' AP06A'},{plant:' 0146A'}],
			selectedvalues:[{plant:' '}],
			enable:true

	},


	{
		labels:'route',
		values:[{route:' test'},{route:' testroute'}],
		selectedvalues:[{route:' '}],
		enable:false

},


{
	labels:'prefix',
	values:[{prefix:' pre'},{prefix:' pretest'}],
	selectedvalues:[{prefix:' '}],
	enable:true

},


];



tryingmandatory:dropdownlist[]=[];
tryingothers:dropdownlist[]=[];


trying:dropdownlist[]=[];


listoffields:fedebomfiltervalues[]=[]
// 	{"fieldname":"part_base","filtertype":"P"},{"fieldname":"usage_id","filtertype":"P"},{"fieldname":"supplier_mfg_site_code","filtertype":"P"},{"fieldname":"program_code","filtertype":"P"},{"fieldname":"plant_name","filtertype":"P"},{"fieldname":"plant_code","filtertype":"P"},{"fieldname":"part_suffix","filtertype":"P"},{"fieldname":"part_prefix","filtertype":"P"},{"fieldname":"make_buy","filtertype":"S"},{"fieldname":"part_class","filtertype":"S"},{"fieldname":"pmt_part","filtertype":"S"},{"fieldname":"product","filtertype":"S"},{"fieldname":"production_availability","filtertype":"S"},{"fieldname":"production_lead_time","filtertype":"S"},{"fieldname":"production_mfg_supplier_loc","filtertype":"S"},{"fieldname":"prototype_availability","filtertype":"S"},{"fieldname":"prototype_lead_time","filtertype":"S"},{"fieldname":"prototype_mfg_supplier_loc","filtertype":"S"},{"fieldname":"replacedby","filtertype":"S"},{"fieldname":"replaces","filtertype":"S"},{"fieldname":"sourcing_path","filtertype":"S"},{"fieldname":"usage_pmt","filtertype":"S"},{"fieldname":"lead_product","filtertype":"S"},{"fieldname":"key_commodity_name","filtertype":"S"},{"fieldname":"effect_out","filtertype":"S"},{"fieldname":"effect_in","filtertype":"S"},{"fieldname":"cpsc","filtertype":"S"},{"fieldname":"commonality_ind","filtertype":"S"},{"fieldname":"using_plants","filtertype":"S"},{"fieldname":"Fedebom_level","filtertype":"S"},{"fieldname":"cad_required","filtertype":"S"}



mandatoryfields:any[]=[];
/*=[
	{name:"plant"},
	{name:"partno"},
	{name:"supplier"}
]*/


savedlist:any='';

csvmandate:any='';

otherfields:any[]=[];

selectedlistedvalues:any=[];


oldlist:any[]=[];
newlist:any[]=[];

show=false;
screenname:string;
cdsid:string;
timestamp:string;
timestampstrfil:any;
merr='';
err='';
griderr='';
errorpopup=false;
merrorpopup=false;
griderrorpopup=false;
fromclearfilters=false;


current_region_fedebom:string;

jsonvalueslist:any;
gridjsonvalueslist:any;


enablegrid=true;

mtestval:any={
    values:[

/*
		{"plant_code":"AP06A","part_prefix":"HC3B","part_base":"2650887","part_suffix":"AC"},
		{"plant_code":"AP06A","part_prefix":"HC3B","part_base":"2502039","part_suffix":"AD"},
		{"plant_code":"AP06A","part_prefix":"HC3B","part_base":"2650886","part_suffix":"AC"}


			]}*/



                 {plant_code:[
					          {plant:'select'},
                              {plant:'AP06A'},
                              {plant:'AP06B'},
                              {plant:'0146A'},
							]},
							{ part_prefix:[
								{part_prefix:'select'},
								{part_prefix:'2021-09-15999999'},
								{part_prefix:'2021-09-16'},
								{part_prefix:'2021-09-17'},
								{part_prefix:'2021-09-18'},
								{part_prefix:'2021-09-19'}
							  ]},
               { part_base:[

                  {part_base:'2021-09-70'},
                  {part_base:'2021-09-16'},
                  {part_base:'2021-09-17'},
                  {part_base:'2021-09-18'},
                  {part_base:'2021-09-19'}
				]},

				{ part_suffix:[
					{part_suffix:'2021-09-90'},
					{part_suffix:'2021-09-16'},
					{part_suffix:'2021-09-17'},
					{part_suffix:'2021-09-18'},
					{part_suffix:'2021-09-19'}
				  ]}

				]}


testval:any={
    values:[

/*

		{"plant_code":"AP06A"},
		{"plant_code":"TC02A"},
		{"plant_code":"AP06B"}*/



                 {plant_code:[
					        {plant:'AP06A'},
                              {plant:'AP06A'},
                              {plant:'AP06B'},
                              {plant:'0146A'},
							]},
							{ part_prefix:[
								{part_prefix:'select'},
								{part_prefix:'2021-09-1899999'},
								{part_prefix:'2021-09-16'},
								{part_prefix:'2021-09-17'},
								{part_prefix:'2021-09-18'},
								{part_prefix:'2021-09-19'}
							  ]},
               { part_base:[
                  {part_base:'2021-09-70'},
                  {part_base:'2021-09-16'},
                  {part_base:'2021-09-17'},
                  {part_base:'2021-09-18'},
                  {part_base:'2021-09-19'}
                ]}

			]}


			storeuserselfilvalues:putuserfiltervalues={screenname:'',userid:'',lastselfilters:'',txntimestamp:''}
		fedebomgrid:fedebomgridop[]=[]



		public cols: any[];
		public frozenCols: any[];
		loading=false;
		dataavailable=' ';
		pltprgval:any[]=[];

	/** empty constructor */
	constructor(private transactionService: TransactionService, private date:DatePipe,
		private router: Router,private route:ActivatedRoute) {
	}

	/** used for functionality that doesn't belong in the constructor but still
	 * needs to be run at the beginning of the lifecycle of the component.
	 */


	ngOnInit() {
		this.screenname='FEDEBOM_MASTER';
		this.cdsid=this.transactionService.getcdsid();
		this.fromclearfilters=false;
		this.show=false;
		this.current_region_fedebom=this.transactionService.getregion();
    this.transactionService.storepage(this.current_region_fedebom,'example-page');
		// this.cdsid="Test11"
console.log(this.test);
/*this.otherfields=[{name: 'make_buy'},
 {name: 'part_class'},
 {name: 'pmt_part'},
 {name: 'product'},
{name: 'production_availability'},
 {name: 'production_lead_time'},
 {name: 'production_mfg_supplier_loc'},
]*/







/*
this.frozenCols=[
	{ field: 'plant_code', header:'Plantcode'},
    { field: 'plant_name', header:'Plantname'},
    { field: 'program_code', header:'Plantprogram'},
    { field: 'part_prefix', header:'Prefix'},
    { field: 'part_base', header:'Base'},
	{ field: 'part_suffix', header:'Suffix'},
]*/




this.cols = [
    { field: 'plant_code', header:'Plantcode'},
    { field: 'plant_name', header:'Plantname'},
    { field: 'program_code', header:'Plantprogram'},
    { field: 'part_prefix', header:'Prefix'},
    { field: 'part_base', header:'Base'},
	{ field: 'part_suffix', header:'Suffix'},
	{field:'part_name',header:'Partname'},
	{field:'part_class',header:'Partclass'},
	{field:'part_d_and_r_cdsid',header:'Part d and r cdsid'},
	{field:'usage_id',header:'Usageid'},
	{field:'usage_d_and_r_cdsid',header:'Usage d and r cdsid'},
	{field:'usage_pmt',header:'Using pmt'},
	{field:'using_plants',header:'Using plants'},
	{field:'pmt_part',header:' Pmt part'},
    {field:'fede_snapshot_id',header:'Snapshotid'},
	{ field: 'product', header:'Product'},
	{ field: 'production_mfg_supplier_loc', header:'Product mfg supp loc'},
	{field:'prototype_mfg_supplier_loc',header:'Prototype mfg supplier loc'},
	{ field: 'effect_in', header:'Effetin'},
	{ field: 'effect_out', header:'Effectout'},
	{ field: 'absolute_weight', header:'Absolute weight'},
	{ field: 'apw_unflexed', header:'Apw unflexed'},
	{ field: 'cad_owner', header:'Cad owner'},
	{ field: 'commonality_ind', header:'Commonality Ind'},
	{ field: 'cpsc', header:'CSPC'},
	{ field: 'd_r_weight_comm', header:'D r weight_comm'},
	{ field: 'engineering_commodity', header:'Engineering commodity'},
	{ field: 'feature_string_minor', header:'Feature string minor'},
	{ field: 'feature_string_primary', header:'Feature string primary'},
	{ field: 'feature_list', header:'Feature list'},
	{ field: 'flexed_apw', header:'Flexed apw'},
	{ field: 'flexed_mpw', header:'Flexed mpw'},
	{ field: 'key_commodity_name', header:'Key commodity name'},
	{ field: 'lead_product', header:'Lead product'},
	{ field: 'make_buy', header:'Make buy'},
	{ field: 'maturity_weight', header:'Maturity weight'},
	{ field: 'module_name', header:'Module name'},
	{ field: 'module_type', header:'Module type'},
	{ field: 'package_dunnage', header:'Package dunnage'},

	{ field: 'purch_comm_code', header:'Purch comm code'},
	{ field: 'qty', header:'Qty'},
	{ field: 'replaces', header:'Replaces'},
	{ field: 'replacedby', header:'ReplacedBy'},
	{ field: 'sign_off', header:'Sign off'},

	{ field: 'sourcing_path', header:'Sourcing path'},
	{ field: 'supplier_mfg_site_code', header:'Supplier mfg site code'},
	{ field: 'target_wt', header:'Target wt'},
	{ field: 'target_wt_uom', header:'Target wt Uom'},
	{ field: 'true_base', header:'True base'},
	{ field: 'un_up', header:'Un up'},
	{ field: 'uom_wt', header:'Uom wt'},


	{ field: 'weight', header:'Weight'},
	{ field: 'production_lead_time', header:'Production lead time'},
	{ field: 'prototype_lead_time', header:'Prototype lead time'},

	{ field: 'production_availability', header:'Production availability'},
	{ field: 'prototype_availability', header:'Prototype availability'},
	{ field: 'cad_required', header:'Cad required'},
	{ field: 'wers_authority', header:'Wers authority'},
	{ field: 'uom_quantity', header:'Uom quantity'},
	{ field: 'app_rowid', header:'App rowid'}



  ];

 /* this.frozenCols = [
	{ field: 'plantcode', header:'Plantcode',width: 100},
    { field: 'plantname', header:'Plantname',width: 100},
    { field: 'plantprogram', header:'Plantprogram',width: 150},
    { field: 'prefix', header:'Prefix',width: 100},
    { field: 'base', header:'Base',width: 100},
    { field: 'suffix', header:'Suffix',width: 100}

  ]
*/


/*this.transactionService.getallfilters(this.screenname).subscribe((allfiltervalues => {
	console.log(allfiltervalues);
	if(allfiltervalues.screenname==this.screenname)
	{
		this.listoffields=[]*/
		const allfiltervalues=this.route.snapshot.data.examplepage
		this.listoffields=allfiltervalues.values;
		console.log(this.listoffields);
	/*	for(var k=0;k<this.listoffields.length;k++)
		{
			if(this.listoffields[k].filtertype=="S")
	{
		this.otherfields.push({name:this.listoffields[k].fieldname});
	}
		}
		console.log(this.otherfields);*/

		this.otherfields=[]

for(let w=0;w<this.listoffields.length;w++)
{
	this.trying.push({uilabels:' ',backendlabels:' ',labels:' ',values:[],selectedvalues:'select',enable:false})
	if(this.listoffields[w].filtertype=='S')
	{
		this.otherfields.push({name:this.listoffields[w].fieldname});
		this.trying[w].uilabels=this.listoffields[w].displayname;
		this.trying[w].backendlabels=this.listoffields[w].fieldname;

		// this.trying[i].push({labels:" ",values:[],selectedvalues:[],enable:true})
	}
	else{
		this.mandatoryfields.push({name:this.listoffields[w].fieldname});
		this.trying[w].enable=true;
		this.trying[w].uilabels=this.listoffields[w].displayname;
		this.trying[w].backendlabels=this.listoffields[w].fieldname;
	}

}

console.log(this.otherfields)

// this.selectedlistedvalues.push({name:this.otherfields[0].name})

this.transactionService.getpltprg().subscribe((pltprgvalues =>{
	console.log(pltprgvalues);
	console.log(this.trying);


	this.pltprgval=pltprgvalues;




	for(let i=0; i<this.pltprgval.length;i++)
	{
		for (const key in this.pltprgval[i])
		{
		if (this.pltprgval[i].hasOwnProperty(key))
		{
			console.log(key);
			const prgplt=key;
			console.log(this.listoffields);
			for(let j=0;j<this.listoffields.length;j++)
			{
				if(key==this.listoffields[j].fieldname && this.trying[j].enable==true)
				{
					let selectpresprg=false;
					if(this.trying[j].selectedvalues!='select')
					{
						console.log(this.trying[j].labels)
						for (const labelkey in this.trying[j].selectedvalues)
						{

						 if (this.trying[j].selectedvalues.hasOwnProperty(labelkey))
						  {
							 console.log(labelkey);
							   if(this.trying[j].selectedvalues[labelkey]=='select')
								{
								 selectpresprg=true;
								}

						  }
					   }

					}
				 console.log(this.trying[j].selectedvalues)

				 if(key==this.listoffields[j].fieldname && (this.trying[j].selectedvalues=='select'||selectpresprg==true))
				 {
				console.log(this.pltprgval[i][key]);
				console.log(this.trying[j].values)
				if(i==0)
				{
					this.trying[j].values=[];
					this.trying[j].labels=key;
				}
				this.trying[j].values.push({[key]:this.pltprgval[i][key]});


				// this.trying[j].values[key]=this.testval.values[i][key];
				}
			}
			}
			console.log(this.trying);
		}
		}
	}


	for(let tr=0;tr<this.trying.length;tr++)
	{
		console.log(this.trying[tr].enable)
		if(this.trying[tr].enable==true)
		{
			this.trying[tr].selectedvalues={};

			const inp=this.trying[tr].labels
			this.trying[tr].selectedvalues=this.trying[tr].values[0]
		}
	}







this.transactionService.getallstoredfilters(this.screenname,this.cdsid).subscribe((storedfiltervalues => {
	console.log('val',storedfiltervalues);
	if(storedfiltervalues!=null)
	{
	if(storedfiltervalues.userid==this.cdsid && storedfiltervalues.screenname==this.screenname)
	{

	this.savedlist=storedfiltervalues.lastselfilters

console.log(this.savedlist);
if(this.savedlist!=null && this.savedlist!='')
{
	this.newlist=[];
	this.selectedlistedvalues=[];
let savedlistarr;
savedlistarr=this.savedlist.split(',')
for( let sav=0;sav<savedlistarr.length;sav++)
{
	for(let u=0;u<this.listoffields.length;u++)
	{
		if(this.listoffields[u].filtertype=='S')
		{

		if(savedlistarr[sav]==this.listoffields[u].fieldname)
		{
					  this.trying[u].enable=true;
					  this.selectedlistedvalues.push({name:this.listoffields[u].fieldname})
					  this.show=true;

		}

	}

	}
}


this.getallselectiontabvalues();

}


else{

	this.getonlyselectionvalues();
	}






	}
}



// this.getonlyselectionvalues();
}))
// 	}
// }))


}))
	}





	gettxntimestamp()
	{
		const dateval =new Date();
		console.log(dateval);
		const timestamp=this.date.transform(dateval, 'yyyy-MM-dd hh:mm:ss');
		// var timestamp= date.toISOString();
		console.log(timestamp);
		this.timestampstrfil=new Date();

		this.timestamp=timestamp;
	}








	getallselectiontabvalues()
	{

		console.log('getallselectiontabvalues')


	this.enablefunction();
		this.getjsonvalues();
		this.enablegridval();

console.log('o/penable',this.listoffields)
console.log(this.jsonvalueslist)


this.transactionService.putonlyfilterselectvalues(this.jsonvalueslist).subscribe((filtersreqno => {
      // this.resultst=output;
      console.log(filtersreqno);
	  if(filtersreqno.status=='Success')
	  {
      if(filtersreqno.screenname==this.screenname && filtersreqno.userid==this.cdsid){
		console.log(filtersreqno.reqno)
		this.err='';



this.transactionService.getonlyfilterselectvalues(this.cdsid,this.screenname,filtersreqno.sessionid,filtersreqno.reqno).subscribe((filtervalues => {
	console.log(filtervalues)
// 	if(filtervalues!=null)
// 	{
	if(filtervalues.userid==this.cdsid && filtervalues.reqno==filtersreqno.reqno)
	{
	/*	this.testval=filtervalues

		console.log(this.testval);


		for(var i=0; i<this.testval.values.length;i++)
		{
			for (var key in this.testval.values[i])
			{
			if (this.testval.values[i].hasOwnProperty(key))
			{
				console.log(key);
				var testvsl=key;
				console.log(this.listoffields);
				for(var j=0;j<this.listoffields.length;j++)
				{
					if(this.trying[j].enable==true)
					console.log(this.trying[j].values)
					{
						var selectpres:boolean=false;
						if(this.trying[j].selectedvalues!="select")
						{
							console.log(this.trying[j].labels)
							for (var labelkey in this.trying[j].selectedvalues)
			                {

			                 if (this.trying[j].selectedvalues.hasOwnProperty(labelkey))
			                  {
				                 console.log(labelkey);
						           if(this.trying[j].selectedvalues[labelkey]=="select")
						            {
							         selectpres=true;
				                    }

			                  }
		                   }

					    }
					 console.log(this.trying[j].selectedvalues)

					 if(key==this.listoffields[j].fieldname && (this.trying[j].selectedvalues=="select"||selectpres==true))
					 {
					console.log(this.testval.values[i][key]);
					console.log(this.trying[j].values)
					if(i==0)
					{
						this.trying[j].values=[];
						this.trying[j].labels=key;
						this.trying[j].values.push({[key]:"select"});
					}
					this.trying[j].values.push({[key]:this.testval.values[i][key]});

					//this.trying[j].values[key]=this.testval.values[i][key];
					}
				}
				}
				console.log(this.trying);
			}
			}
		}


*/

		// -----------------------------------------------------------------------
		console.log('test')
		this.testval=filtervalues
		console.log(this.testval);
		if(this.testval.values.length==0)
		{


			for(let kj=0;kj<this.listoffields.length;kj++)
			{
				if(this.trying[kj].enable==true)
				{
					let kselectpres=false;
					if(this.trying[kj].selectedvalues!='select')
					{
						console.log(this.trying[kj].labels)
						for (const labelkey in this.trying[kj].selectedvalues)
						{

						 if (this.trying[kj].selectedvalues.hasOwnProperty(labelkey))
						  {
							 console.log(labelkey);
							   if(this.trying[kj].selectedvalues[labelkey]=='select')
								{
								 kselectpres=true;
								}

						  }
					   }

					}


					if( this.trying[kj].selectedvalues=='select'||kselectpres==true)
					{
						this.trying[kj].values=[];
						this.trying[kj].selectedvalues='select';

					}
				}
			}

		}
		for(let i=0; i<this.testval.values.length;i++)
		{
		   for (const key in this.testval.values[i])
			{

			if (this.testval.values[i].hasOwnProperty(key))
			{
				for(let j=0;j<this.listoffields.length;j++)
				{
					if(this.trying[j].enable==true)
					{
						let selectpres=false;
						if(this.trying[j].selectedvalues!='select')
						{
							console.log(this.trying[j].labels)
							for (const labelkey in this.trying[j].selectedvalues)
			                {

			                 if (this.trying[j].selectedvalues.hasOwnProperty(labelkey))
			                  {
				                 console.log(labelkey);
						           if(this.trying[j].selectedvalues[labelkey]=='select')
						            {
							         selectpres=true;
				                    }

			                  }
		                   }

					    }
					 console.log(this.trying[j].selectedvalues)

					 if(key==this.listoffields[j].fieldname && (this.trying[j].selectedvalues=='select'||selectpres==true))
					 {

						for(let rt=0;rt<this.testval.values[i][key].length;rt++)
						{

							if(rt==0)
							{
						 for(const keyname in this.testval.values[i][key][rt])
						  {
							  console.log(keyname);
							if (this.testval.values[i][key][rt].hasOwnProperty(keyname))
							{
						this.trying[j].labels=keyname;
						const arr1=[];
						let arr2=[];
						arr1.push({[keyname]:'select'})
						arr2=this.testval.values[i][key];
						this.trying[j].values=arr1.concat(arr2);
						// this.trying[j].values.push({[keyname]:"select"});
						// this.trying[j].values.push(this.testval.values[i][key]);
						// this.tryingmandatory[j].selectedvalues.push(this.testval.values[i][key][0]);
						break;
							}
							break;
						  }
						}

						}
					}
				}
				}
				console.log(this.trying);
			}
		}
	  }


console.log('ppp',this.listoffields)

}
// 	}
}))



}
}


else{
	console.log(filtersreqno.status)
	console.log(filtersreqno.error)
	this.errorpopup=true
	this.err=filtersreqno.error;
}


}))

// -----------------------------------------------------------------------------------

	}








	getonlyselectionvalues()
	{

if(this.fromclearfilters==false)
{
		this.enablefunction();
}



if(this.fromclearfilters==true)
{
	this.fromclearfilters=false;
}

		this.getjsonvalues();

		let storefields='';
		let count=0;
		for(let stf=0;stf<this.trying.length;stf++)
		{
			if(this.trying[stf].enable==true)
			{
				count+=1;
			}
		}


		let countstf=0;


		for(let stfv=0;stfv<this.trying.length;stfv++)
		{
			if(this.trying[stfv].enable==true)
			{
				storefields+=this.trying[stfv].backendlabels;
				countstf+=1;

			if(countstf!=count)
			{
				storefields+=',';
			}

		}
		}


		console.log(storefields);
		this.gettxntimestamp();

this.storeuserselfilvalues.screenname=this.screenname;
this.storeuserselfilvalues.userid=this.cdsid;
this.storeuserselfilvalues.lastselfilters=storefields;
// this.storeuserselfilvalues.txntimestamp="2022-02-03T01:28:56.782Z";
this.storeuserselfilvalues.txntimestamp=this.timestampstrfil;
// this.timestamp;
console.log('filtersav',this.storeuserselfilvalues);


          this.transactionService.putuserselfilters(this.storeuserselfilvalues).subscribe((filter => {
                   // this.resultst=output;
				  const resopf=filter;
				  console.log(filter);
				  if(filter=='success')
				  {
				   console.log('successful',resopf);


				   this.enablegridval();





console.log(this.jsonvalueslist);

		this.transactionService.putonlyfilterselectvalues(this.jsonvalueslist).subscribe((mfiltersreqno => {
	  // this.resultst=output;
	  console.log(mfiltersreqno);
	  if(mfiltersreqno.status=='Success')
	  {
	  if(mfiltersreqno.screenname==this.screenname && mfiltersreqno.userid==this.cdsid)
	  {
		console.log(mfiltersreqno.reqno)
		this.merr=''

		this.transactionService.getonlyfilterselectvalues(this.cdsid,this.screenname,mfiltersreqno.sessionid,mfiltersreqno.reqno).subscribe((mfiltervalues => {
			console.log(mfiltervalues)
			if(mfiltervalues.userid==this.cdsid && mfiltervalues.reqno==mfiltersreqno.reqno)
			{
				this.mtestval=mfiltervalues



/*
		console.log(this.mtestval);


		for(var mi=0; mi<this.mtestval.values.length;mi++)
		{
		   for (var mkey in this.mtestval.values[mi])
			{

			if (this.mtestval.values[mi].hasOwnProperty(mkey))
			{
				for(var mj=0;mj<this.listoffields.length;mj++)
				{
					if(this.trying[mj].enable==true)
					{
						var mselectpres:boolean=false;
						if(this.trying[mj].selectedvalues!="select")
						{
							console.log(this.trying[mj].selectedvalues)
							console.log(this.trying[mj].labels)
							for (var mlabelkey in this.trying[mj].selectedvalues)
			                {

			                 if (this.trying[mj].selectedvalues.hasOwnProperty(mlabelkey))
			                  {
				                 console.log(mlabelkey);
						           if(this.trying[mj].selectedvalues[mlabelkey]=="select")
						            {
							         mselectpres=true;
				                    }

			                  }
		                    }

					    }
					console.log(this.trying[mj].selectedvalues)
					console.log(mselectpres);

					if(mkey==this.listoffields[mj].fieldname && (this.trying[mj].selectedvalues=="select"||mselectpres==true))
					{
						{
							console.log(this.mtestval.values[mi][mkey]);
							console.log(this.trying[mj].values)
							if(mi==0)
							{
								this.trying[mj].values=[];
								this.trying[mj].labels=mkey;
								this.trying[mj].values.push({[mkey]:"select"});
							}
							this.trying[mj].values.push({[mkey]:this.mtestval.values[mi][mkey]});


							//this.trying[j].values[key]=this.testval.values[i][key];
							}


					}
				}
				}
				console.log(this.trying);
			}
		}
	  }
*/

		// ----------------------------------------------------------------------------
		console.log('mtest')
		if(this.mtestval.values.length==0)
		{


			for(let mkj=0;mkj<this.listoffields.length;mkj++)
			{
				if(this.trying[mkj].enable==true)
				{
					let mkselectpres=false;
					if(this.trying[mkj].selectedvalues!='select')
					{
						console.log(this.trying[mkj].labels)
						for (const labelkey in this.trying[mkj].selectedvalues)
						{

						 if (this.trying[mkj].selectedvalues.hasOwnProperty(labelkey))
						  {
							 console.log(labelkey);
							   if(this.trying[mkj].selectedvalues[labelkey]=='select')
								{
								 mkselectpres=true;
								}

						  }
					   }

					}


					if( this.trying[mkj].selectedvalues=='select'||mkselectpres==true)
					{
						this.trying[mkj].values=[];
						this.trying[mkj].selectedvalues='select';

					}
				}
			}

		}
		for(let mi=0; mi<this.mtestval.values.length;mi++)
		{
		   for (const mkey in this.mtestval.values[mi])
			{

			if (this.mtestval.values[mi].hasOwnProperty(mkey))
			{
				for(let mj=0;mj<this.listoffields.length;mj++)
				{
					if(this.trying[mj].enable==true)
					{
						let mselectpres=false;
						if(this.trying[mj].selectedvalues!='select')
						{
							console.log(this.trying[mj].selectedvalues)
							console.log(this.trying[mj].labels)
							for (const mlabelkey in this.trying[mj].selectedvalues)
			                {

			                 if (this.trying[mj].selectedvalues.hasOwnProperty(mlabelkey))
			                  {
				                 console.log(mlabelkey);
						           if(this.trying[mj].selectedvalues[mlabelkey]=='select')
						            {
							         mselectpres=true;
				                    }

			                  }
		                    }

					    }
					console.log(this.trying[mj].selectedvalues)
					console.log(mselectpres);

					if(mkey==this.listoffields[mj].fieldname && (this.trying[mj].selectedvalues=='select'||mselectpres==true))
					{
						for(let rt=0;rt<this.mtestval.values[mi][mkey].length;rt++)
						{
							console.log(this.mtestval.values[mi][mkey][rt])

							if(rt==0)
							{
								console.log(this.mtestval.values[mi][mkey][rt])
						 for(const mkeyname in this.mtestval.values[mi][mkey][rt])
						  {
							  console.log(mkeyname);
							if (this.mtestval.values[mi][mkey][rt].hasOwnProperty(mkeyname))
							{
						this.trying[mj].labels=mkeyname
						const marr1=[];
						let marr2=[];
						marr1.push({[mkeyname]:'select'})
						marr2=this.mtestval.values[mi][mkey];
						this.trying[mj].values=marr1.concat(marr2);
						// this.trying[mj].values.push({[mkeyname]:"select"});
						// this.trying[mj].values.push(this.mtestval.values[mi][mkey]);
						// this.tryingmandatory[j].selectedvalues.push(this.testval.values[i][key][0]);
						break;
							}
							break;
						  }
						}

						}
					}
				}
				}
				console.log(this.trying);
			}
		}
	  }

// ------------------------------------------------------------------------------------------------



}
}))


}
}

else{
	console.log(mfiltersreqno.status)
	console.log(mfiltersreqno.error)
	this.merrorpopup=true
	this.merr=mfiltersreqno.error;
}


}))
			 }

}))


	}



	getjsonvalues()
	{
		 this.jsonvalueslist={};
			  console.log(this.listoffields);

			for(let r=0; r<this.listoffields.length; r++)
			{
				if(this.trying[r].enable==true)
				{
				if(this.trying[r].selectedvalues!='select')
				{
				{
					const mtestkey=this.listoffields[r].fieldname


		         for (const tkey in this.trying[r].selectedvalues)
			       {

			          if (this.trying[r].selectedvalues.hasOwnProperty(tkey))
			          {
				         console.log(tkey);
				          this.jsonvalueslist[mtestkey]=this.trying[r].selectedvalues[tkey];
					        console.log(this.jsonvalueslist);

			              }
		              }
					}
			}
			else
			{
				console.log(this.trying[r].selectedvalues)
				console.log(this.listoffields[r].fieldname)
				const testkey=this.listoffields[r].fieldname
				this.jsonvalueslist[testkey]=this.trying[r].selectedvalues;
			}
		}
			}

			console.log(this.jsonvalueslist);
			this.jsonvalueslist.userid=this.cdsid;
			this.jsonvalueslist.sessionid=0;
			this.jsonvalueslist.screenname=this.screenname;
			this.gettxntimestamp();
			this.jsonvalueslist.txntimestamp=this.timestamp;



			console.log(this.jsonvalueslist);
	}


	getgridjsonvalues()
	{


		this.gridjsonvalueslist={};
		console.log(this.listoffields);

	  for(let r=0; r<this.listoffields.length; r++)
	  {
		  if(this.trying[r].enable==true)
		  {
		  if(this.trying[r].selectedvalues!='select')
		  {
		  {
			  const mgridtestkey=this.listoffields[r].fieldname


		   for (const tgridkey in this.trying[r].selectedvalues)
			 {

				if (this.trying[r].selectedvalues.hasOwnProperty(tgridkey))
				{
				   console.log(tgridkey);
				   if(this.trying[r].selectedvalues[tgridkey]!='select')
				   {
					this.gridjsonvalueslist[mgridtestkey]=this.trying[r].selectedvalues[tgridkey];
				   }
					  console.log(this.gridjsonvalueslist);

					}
				}
			  }
	  }

  }
	  }

	  console.log(this.gridjsonvalueslist);
	  this.gridjsonvalueslist.userid=this.cdsid;
	  this.gridjsonvalueslist.sessionid=0;
	  this.gridjsonvalueslist.screenname=this.screenname;
	  this.gettxntimestamp();
	  this.gridjsonvalueslist.txntimestamp=this.timestamp;



	  console.log(this.gridjsonvalueslist);

	}

	changesubselection(){

		console.log(this.trying);
		// this.enablegridval()
		this.getallselectiontabvalues();
	}

enablegridval(){

	this.enablegrid=true;

	let enableplantcode=false
	let enableprogramcode=false
	for(let t=0;t<this.listoffields.length;t++)
	{

			if(this.listoffields[t].fieldname=='plant_code')
			{
				if(this.trying[t].selectedvalues!='select')
				{

				for (const plabelkey in this.trying[t].selectedvalues)
						{
							if (this.trying[t].selectedvalues.hasOwnProperty(plabelkey))
							{
							   console.log(plabelkey);
								 if(this.trying[t].selectedvalues[plabelkey]!='select')
							  {
								  enableplantcode=true;
								  }
					}
				}

			}
		}



			if(this.listoffields[t].fieldname=='program_code')
			{
				if(this.trying[t].selectedvalues!='select')
				{

				for (const pclabelkey in this.trying[t].selectedvalues)
						{
							if (this.trying[t].selectedvalues.hasOwnProperty(pclabelkey))
							{
							   console.log(pclabelkey);
								 if(this.trying[t].selectedvalues[pclabelkey]!='select')
							  {
								  enableprogramcode=true;
								  }
					}
				}

			}

			}

	}



console.log(enableprogramcode,enableplantcode)
	if(enableplantcode==true && enableprogramcode==true)
	{
		this.enablegrid=false;
	}
	else{
		this.enablegrid=true;
	}
	console.log('grid',this.enablegrid)
	console.log('enableplt',this.listoffields)

}


	changemultiselect()
	{
		console.log(this.selectedlistedvalues);
		if(this.selectedlistedvalues.length!=0)
		{
			this.show=true;
        /*    this.newlist=[]
		for(var l=0;l<this.selectedlistedvalues.length;l++)
		{
			this.newlist.push(this.selectedlistedvalues[l].name)

		}*/
			this.getonlyselectionvalues()
	// for now deploy
	// this.enablefunction();
		}

		else if(this.selectedlistedvalues.length==0)
		{
			this.show=false;
			this.clearfilters();
		}
	}


	enablefunction()
	{
		console.log('enable function');
		console.log(this.selectedlistedvalues);
		console.log(this.oldlist)

		this.newlist=[]
		if(this.selectedlistedvalues.length!=0)
		{
		for(let l=0;l<this.selectedlistedvalues.length;l++)
		{
			this.newlist.push(this.selectedlistedvalues[l].name)

		}
	}

		console.log(this.newlist);


		const list='';

if(this.newlist.length!=0)
{
			for(let i=0;i<this.newlist.length;i++)
			{

			for(let j=0;j<this.listoffields.length;j++)
			{
				console.log(this.newlist[i]);
				console.log(this.listoffields[j]);
				if(this.listoffields[j].filtertype=='S')
				{
				if(this.newlist[i]==this.listoffields[j].fieldname)
				{
					console.log(this.listoffields[j].fieldname)
					this.trying[j].enable=true;
					// this.trying[j].uilabels=this.newlist[i];
					break;
				}
			}

			}



		}
	}


		console.log(this.trying);

		console.log(list);
		console.log(this.oldlist)
		if (this.oldlist.length!=0)
		{
			let present;
			for(let k=0;k<this.oldlist.length;k++)
			{
				present=false;
				for(let m=0;m<this.newlist.length;m++)
				{
					if(this.oldlist[k]==this.newlist[m])
					{
						present=true;
						break;

					}
				}
				if(present==false)
				{
					for(let n=0;n<this.listoffields.length;n++)
					{
						if(this.listoffields[n].filtertype=='S')
						{
						if(this.oldlist[k]==this.listoffields[n].fieldname)
						{
							this.trying[n].enable=false;
							this.trying[n].values=[];
							this.trying[n].selectedvalues={};
							this.trying[n].selectedvalues='select';
							break;
						}
					}
					}
				}
			}
		}

         this.oldlist=[];
		for(let y=0;y<this.newlist.length;y++)
		{
			this.oldlist.push(this.newlist[y])
		}


		console.log(this.oldlist);
		console.log(this.newlist);
		console.log(this.trying);


	}


	clearfilters()
	{
		this.selectedlistedvalues=[];
		this.show=false;
		console.log(this.oldlist);
		this.fromclearfilters=true;





		for(let z=0;z<this.oldlist.length;z++)
		{
		for(let t=0;t<this.listoffields.length;t++)
		{
			if(this.oldlist[z]==this.listoffields[t].fieldname)
			{
				this.trying[t].enable=false;
				this.trying[t].values=[];
							this.trying[t].selectedvalues={};
							this.trying[t].selectedvalues='select';
				break;
			}
		}
		}

		this.getonlyselectionvalues();
		console.log(this.trying);
	}



retrivetable()
{
	this.dataavailable=' ';
	console.log(this.fedebomgrid);
	console.log(this.listoffields);
	let usage_id
let program_code
let plant_code
let plant_name
let supplier_mfg_site_code
let part_base
let part_prefix
let part_suffix

console.log(this.trying);
	for(let i=0;i<this.listoffields.length;i++)
	{
		if(this.listoffields[i].filtertype=='P')
		{
			if(this.listoffields[i].fieldname=='plant_code')
			{
				const plabel=this.trying[i].labels
				console.log('gridop',plabel);
				plant_code=this.trying[i].selectedvalues[plabel]
				console.log(plant_code);


			}
			if(this.listoffields[i].fieldname=='plant_name')
			{
				console.log('plantname')
				const pnlabel=this.trying[i].labels
				if(pnlabel==' ')
				{
					plant_name=this.trying[i].selectedvalues
				}
				else{
				plant_name=this.trying[i].selectedvalues[pnlabel]
				}

			}
			if(this.listoffields[i].fieldname=='program_code')
			{
				const pclabel=this.trying[i].labels
				if(pclabel==' ')
				{
					program_code=this.trying[i].selectedvalues
				}
				else{
				program_code=this.trying[i].selectedvalues[pclabel]
				}

			}
			if(this.listoffields[i].fieldname=='usage_id')
			{
				const ulabel=this.trying[i].labels
				if(ulabel==' ')
				{
					usage_id=this.trying[i].selectedvalues
				}
				else{
				usage_id=this.trying[i].selectedvalues[ulabel]
				}

			}
			if(this.listoffields[i].fieldname=='part_base')
			{
				const pblabel=this.trying[i].labels
				if(pblabel==' ')
				{
					part_base=this.trying[i].selectedvalues
				}
				else{
				part_base=this.trying[i].selectedvalues[pblabel]
				}

			}
			if(this.listoffields[i].fieldname=='part_prefix')
			{
				const pplabel=this.trying[i].labels
				if(pplabel==' ')
				{
					part_prefix=this.trying[i].selectedvalues
				}
				else{
				part_prefix=this.trying[i].selectedvalues[pplabel]
				}

			}
			if(this.listoffields[i].fieldname=='part_suffix')
			{
				const pslabel=this.trying[i].labels
				if(pslabel==' ')
				{
					part_suffix=this.trying[i].selectedvalues
				}
				else{
				part_suffix=this.trying[i].selectedvalues[pslabel]
				}

			}
			if(this.listoffields[i].fieldname=='supplier_mfg_site_code')
			{
				const smslabel=this.trying[i].labels
				if(smslabel==' ')
				{
					supplier_mfg_site_code=this.trying[i].selectedvalues
				}
				else{
				supplier_mfg_site_code=this.trying[i].selectedvalues[smslabel]
				}

			}

		}
	}



	console.log(this.listoffields);
	console.log(usage_id);
console.log(program_code);
console.log(plant_code);
console.log(plant_name);
console.log(supplier_mfg_site_code);
console.log(part_base);
console.log(part_prefix);
console.log(part_suffix);


	this.loading=true;
this.enablegrid=true;

this.transactionService.getgridvalues(usage_id,program_code,plant_code,plant_name,supplier_mfg_site_code,part_base,part_prefix,part_suffix).subscribe((gridvalues => {
	console.log(gridvalues)
	this.loading=false;
	this.enablegrid=false;

		this.fedebomgrid=gridvalues;
		if(this.fedebomgrid.length==0)
		{
			this.dataavailable='No Data Found';
		}

		console.log(this.listoffields)
		console.log(this.fedebomgrid);

  }))

}


getgriddata()
{
	this.getgridjsonvalues();
	this.enablegrid=true;
	this.dataavailable=' ';
	this.transactionService.putonlyfilterselectvalues(this.gridjsonvalueslist).subscribe((gridreqno => {
		// this.resultst=output;
		console.log(gridreqno);
		if(gridreqno.status=='Success')
		{
		if(gridreqno.screenname==this.screenname && gridreqno.userid==this.cdsid){
		  console.log(gridreqno.reqno)
		  this.griderr=''

		 this.transactionService.getgriddynamicvalues(this.cdsid,gridreqno.reqno).subscribe((gridvalues => {
			  console.log(gridvalues)
			 // if(gridvalues.userid==this.cdsid && gridvalues.reqno==gridreqno.reqno)
			  // {
				this.enablegrid=false;
				  this.fedebomgrid=gridvalues;
				  if(this.fedebomgrid.length==0)
				  {
					  this.dataavailable='No Data Found';
				  }
			  // }
			}))

		}
	}

	else{

		console.log(gridreqno.status)
	console.log(gridreqno.error)
	this.dataavailable='Error occured try again';
this.enablegrid=false;
	this.griderrorpopup=true
	this.griderr=gridreqno.error;

	}


	}))
}


}
