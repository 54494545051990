/* tslint:disable */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TransactionService } from '../loginpage/Transaction.service';

@Component({
  selector: 'app-appfootercomponent',
  templateUrl: './appfootercomponent.component.html',
  styleUrls: ['./appfootercomponent.component.css']
})
export class AppfootercomponentComponent implements OnInit {

  constructor(private transactionService:TransactionService,private router:Router) { }

  ngOnInit(): void {
  }

  isuserpresent():boolean
	{
		let user_exist
		user_exist=this.transactionService.getcdsid();
		// console.log(user_exist)
		if(user_exist==null)
		{
		  return false;
		}
		else{
      const isActiveUser=this.transactionService.getActiveUserOrNot();

      if(isActiveUser!=null && isActiveUser==true)
			{
        return true;
      }
      else
      {
        return false;
      }
		}
	}



	isuserregion():boolean
	{
		const url_arr=this.router.url.split('/');
	// 	console.log(url_arr[1]);
		if(url_arr[1].toLowerCase()=='asiapacific'||url_arr[1].toLowerCase()=='europe'||url_arr[1].toLowerCase()=='northamerica'||url_arr[1].toLowerCase()=='southamerica')
		{
			return true;
		}

		else
		{
			return false;
		}



  }

  navigateToPackagingLinks()
  {

    const url_arr=this.router.url.split('/');
   // console.log(url_arr[1]);
    /*
		if(url_arr[1].toLowerCase() == "asiapacific"||url_arr[1].toLowerCase() == "europe"||url_arr[1].toLowerCase() == "northamerica"||url_arr[1].toLowerCase() == "southamerica")
		{
      console.log('/'+url_arr[1]+'/helpcomponent')
      this.router.navigate(['/'+url_arr[1]+'/helpcomponent'])
    }
    else
    {
      this.router.navigate(['/helpcomponent'])
    }
    */

    const region=this.transactionService.getregion();
    let reg_url=this.transactionService.getregionurlvalue(region);
    reg_url+='packaginglinksdocument';

    this.router.navigate([reg_url]);



  }
  navigateToTranings()
  {
    this.router.navigate(["traininglinksdocument"]);
  }

  versionDetails()
  {

  }

  goToHome()
  {
    const cur_region=this.transactionService.getregion();
    if(cur_region != null )
    {
      let reg_map_url='';
      reg_map_url=this.transactionService.getregionurlvalue(cur_region);
      reg_map_url+='packagingprocessdashboard/packagingprocesshome';

      this.router.navigate([reg_map_url]);
    }
    else
    {
      this.router.navigate(['/exitpage']);
    }
  }

  logout()
  {
    this.transactionService.logout();
    this.router.navigate(['/exitpage']);
  }
}
