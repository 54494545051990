/* tslint:disable */
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TransactionService } from '../loginpage/Transaction.service';
import { selec_prt_pro_details, fromdiplayvalues_all, RevisionObj } from '../loginpage/transactions';

@Component({
  selector: 'app-plantfinalappoffsite',
  templateUrl: './plantfinalappoffsite.component.html',
  styleUrls: ['./plantfinalappoffsite.component.css']
})
export class PlantfinalappoffsiteComponent implements OnInit {





  region:string;
  regionCode:string;
  viewPltfinalAprOffsite:boolean=false;
  currentRoleidPltFinalOffsite:number;

  current_routeid:number=0;

  constructor(private transactionService: TransactionService,private router:Router,private date:DatePipe,private route: ActivatedRoute) { }

  ngOnInit(): void {



    this.region=this.transactionService.getregion();
    this.regionCode=this.transactionService.getregioncode(this.region);


        this.currentRoleidPltFinalOffsite=20;

        console.log(this.currentRoleidPltFinalOffsite)


        if(this.region=="Asia Pacific & Africa")
        {
          sessionStorage.setItem('aprole', JSON.stringify(this.currentRoleidPltFinalOffsite));
        }

        else if(this.region=="Europe")
        {
          sessionStorage.setItem('eurole', JSON.stringify(this.currentRoleidPltFinalOffsite));
        }

        else if (this.region=="North America")

        {
          console.log(this.currentRoleidPltFinalOffsite)
          sessionStorage.setItem('narole', JSON.stringify(this.currentRoleidPltFinalOffsite));
        }

        else if(this.region=="South America")
        {
          sessionStorage.setItem('sarole', JSON.stringify(this.currentRoleidPltFinalOffsite));
        }



        //this.transactionService.storepage(this.region,"/packagingprocess/packsupplieradmin/packsupplier");

        this.current_routeid=258;

        console.log(this.current_routeid)


        if(this.region=='Asia Pacific & Africa')
        {
          sessionStorage.setItem('aprouteid', JSON.stringify(this.current_routeid));
        }

        else if(this.region=='Europe')
        {
          sessionStorage.setItem('eurouteid', JSON.stringify(this.current_routeid));
        }

        else if (this.region=='North America')

        {

          sessionStorage.setItem('narouteid', JSON.stringify(this.current_routeid));
        }

        else if(this.region=='South America')
        {
          sessionStorage.setItem('sarouteid', JSON.stringify(this.current_routeid));
        }


       this.viewPltfinalAprOffsite=true;



  }

}
