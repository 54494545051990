/* tslint:disable */
import {Component, HostListener,EventEmitter,Output} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {TransactionService} from '../loginpage/Transaction.service';
import { Router, RouterStateSnapshot } from '@angular/router';



import {AppComponent} from '../app.component';
import { useraccessrole,subroles,user_roles } from '../loginpage/transactions';
import { PackagingprocesshomeComponent } from '../packagingprocesshome/packagingprocesshome.component';
import { PackagingprocessdashboardComponent } from '../packagingprocessdashboard/packagingprocessdashboard.component';

/** Angular component that utilizes the PrimeNG p-menubar component
 * to display the navigation menu for the application
 */

@Component({
	selector: 'app-nav',
	templateUrl: './nav.component.html',
	styleUrls: ['./nav.component.scss']
})
export class NavComponent {
	/** Array of PrimeNG MenuItems that are used to populate the p-menubar */
	navMenuItems: MenuItem[];
	/** whether or not the sidebar is open or not */
	sidebarVisible: boolean;
	/** toggles visibility of sidebar to true */
	showSidebar = () => { this.sidebarVisible = true; };
	/** toggles visibility of sidebar to false */
	hideSidebar = () => { this.sidebarVisible = false; };
   
   

	Userid:string;
	adminvalue:string;
	adminvaluepl:string;
	adminaccess:string;
	visibility=false
	visibletoall=true;



	na:string;
	ap:string;
	eu:string;
	sa:string;


	UserAccess:useraccessrole[]=[];



	buttonenabled_ap=true;



	@Output() readonly isActiveChange: EventEmitter<boolean> = new EventEmitter();


	/** Create a nav component and populate with necessary MenuItems (static data) */
	constructor(public app: AppComponent ,private transactionService: TransactionService , private router:Router) {
		
	}



	isenabled():boolean{
		console.log(this.buttonenabled_ap)
		return this.buttonenabled_ap;
	}


	ngOnInit(): void {

// this.navigatena();

// get user default region and call its function
// check user present, if not redirect to exit page api
// store which all region user has access



// fro now get user wise region
// for the first region call its function

// for now


// store current region if current rtegion is not null and present in the list of region redirect to it else redirect to first region in the user wise list
let current_region;
current_region=this.transactionService.getregion();



	}








	test:any;


	isna():boolean
	{


		let user_region_na
		user_region_na=this.transactionService.getuserregion();

		if(user_region_na!=null && user_region_na.length!=0)
		{
		for(let nai=0;nai<user_region_na.length;nai++)
		{

			let na_present =false
			if(user_region_na[nai]=='NA')
			{
				na_present=true;
				break;

			}
		}


		// @ts-ignore
            if(na_present==true)
		{

			return true
		}


		else{
			return false
		}
		}


		else{
			return false;
		}



		// return true;








	}


	isap():boolean
	{


		let user_region_ap
		user_region_ap=this.transactionService.getuserregion();
		if(user_region_ap != null && user_region_ap.length != 0)
		{
	     for(let api=0;api<user_region_ap.length;api++)
		{

			let ap_present =false
			if(user_region_ap[api] == 'AP')
			{
				ap_present=true;
				break;

			}
		}


		// @ts-ignore
            if(ap_present==true)
		{
			return true
		}
		else{
			return false
		}
		}
		else{
			return false;
		}


	// 	return true

	}

	iseu():boolean
	{


		let user_region_eu
		user_region_eu=this.transactionService.getuserregion();
		if(user_region_eu!=null && user_region_eu.length!=0)
		{
		for(let eui=0;eui<user_region_eu.length;eui++)
		{

			let eu_present =false
			if(user_region_eu[eui]=='EU')
			{
				eu_present=true;
				break;

			}
		}

// @ts-ignore
		if(eu_present==true)
		{
			return true
		}


		else{
			return false
		}
		}


		else{
			return false;
		}


		// return false

	}

	issa():boolean
	{



		let user_region_sa
		user_region_sa=this.transactionService.getuserregion();
		if(user_region_sa!=null && user_region_sa.length!=0)
		{
		for(let sai=0;sai<user_region_sa.length;sai++)
		{

			let sa_present =false
			if(user_region_sa[sai]=='SA')
			{
				sa_present=true;
				break;

			}
		}

// @ts-ignore
		if(sa_present==true)
		{
			return true
		}


		else{
			return false
		}
		}


		else{
			return false;
		}

		// return false

	}



	isuserpresent():boolean
	{
		let user_exist
		user_exist=this.transactionService.getcdsid();
		// console.log(user_exist)
		if(user_exist==null)
		{

			return false;
		}
		else{

			return true;
		}
	}



	isuserregion():boolean
	{
		let url_arr=this.router.url.split('/');
		// console.log(url_arr[1]);
		if(url_arr[1].toLowerCase()=='asiapacific'||url_arr[1].toLowerCase()=='europe'||url_arr[1].toLowerCase()=='northamerica'||url_arr[1].toLowerCase()=='southamerica')
		{
			return true;
		}

		else
		{
			return false;
		}



	}




	navigateap(isRoutingEnable:boolean)
	{
		let region;
		region=this.transactionService.getregion();
		console.log(region);


         // console.log("apsideeee");
		let user:any;
		user=this.transactionService.getcdsid();

		this.ap=JSON.parse(sessionStorage.getItem('ap'));


		this.UserAccess=[];
		let roles
		// pass value for eavh rwgion and for each region store the roles
	if(isRoutingEnable==true)
	{
		this.navigatena(false);
		this.navigateeu(false);
		this.navigatesa(false);
		sessionStorage.removeItem('region');
		sessionStorage.setItem('region', JSON.stringify('Asia Pacific & Africa'));

	}
		this.transactionService.getUserAccessRole(user).subscribe(useraccess=>{
			console.log('useracces',useraccess);
			this.UserAccess=useraccess;
			if(this.UserAccess!=null)
			{
			if(this.UserAccess.length!=0)
			{
				let ap_roles:user_roles[]
				ap_roles=[];
				let ap_role_ids=''


				let regGlbIndexAP=this.UserAccess.findIndex(elglbregap=>elglbregap.role_id==100)
				let regIndexAP=this.UserAccess.findIndex(elregap=>elregap.cd_region.toLowerCase()=='ap')



				if(regGlbIndexAP!=-1)
				{
					if(JSON.parse(sessionStorage.getItem('isGlobalAdmin'))==false)
					{
						sessionStorage.removeItem('isGlobalAdmin');
					sessionStorage.setItem('isGlobalAdmin', JSON.stringify(true));


					this.updateRegionListGlbAdmin();
					}



					let rolefunc:subroles[]=[];


					ap_roles.push({roleid:100,roles:rolefunc})
					ap_role_ids='100';



				}




				if(regIndexAP!=-1)
				{


					this.updateRegionListOneRegion('AP');

			for(let mtemp=0;mtemp<this.UserAccess.length;mtemp++)
			{
				if(this.UserAccess[mtemp].cd_region=='AP')
				{
					let rolefunc:subroles[]=[];

			            if(ap_roles.length==0)
					{
						// console.log(this.UserAccess[mtemp].role_id);
					ap_roles.push({roleid:this.UserAccess[mtemp].role_id,roles:rolefunc})
					ap_role_ids=this.UserAccess[mtemp].role_id.toString();

					}
					else{


						let present=false;

						for(let mi=0;mi<ap_roles.length;mi++)
						{
							if(ap_roles[mi].roleid==this.UserAccess[mtemp].role_id)
							{
								present=true;
								break;
							}
						}

						if(present==false)
						{
							// console.log(this.UserAccess[mtemp].role_id);


							ap_roles.push({roleid:this.UserAccess[mtemp].role_id,roles:rolefunc})
							ap_role_ids+=','
							ap_role_ids+=this.UserAccess[mtemp].role_id.toString();

						}

					}




				}
			}
		}





			console.log(ap_roles);
			console.log(ap_role_ids)


			if(regIndexAP!=-1 || regGlbIndexAP!=-1)
			{



			this.transactionService.getuserrolefunctionality(ap_role_ids,'AP').subscribe(userrolefuncaccess=>{


				console.log(userrolefuncaccess);
				for(let iap=0;iap<ap_roles.length;iap++)
				{
					ap_roles[iap].roles=[]
                    // @ts-ignore
					rolefunc=[];
					for(let jap=0;jap<userrolefuncaccess.length;jap++)
					{
						if(ap_roles[iap].roleid==userrolefuncaccess[jap].role_id)
						{


							ap_roles[iap].roles.push(userrolefuncaccess[jap]);


						}
					}
				}



			let region_code='AP'
			let name=region_code+'useraccess';
			sessionStorage.setItem(name,JSON.stringify(ap_roles));


			console.log(name,ap_roles);

/*
			if(this.ap!=null)
		{

			console.log(this.ap);*/
			/*

			var ap_url_parameters;
			ap_url_parameters=this.ap.split('/')
			comsole.log(ap_url_parameters[1])
			if(ap_url_parameters[1]=="packsupplier")
			{
				console.log(ap_roles)

				var ap_supp_present
				ap_supp_present=false;
				for(var ni=0;ni<ap_roles.length;ap_roles++)
				{

					if(ap_roles[ni]==1)
					{
						ap_supp_present=true;
						break;
					}


				}

				if(ap_supp_present==true)
				{
					//check for functionality if ap_url_parameter[2] exist
				}



			}


			else if() repeat same for other roles

			else
			{
				route to default url or url they have access
			}
			*/
/*
		this.router.navigate([this.ap]);
		}

		else{*/


			console.log(ap_roles);
			console.log(ap_roles[0]);
		/*	var ap_rolefunc=ap_roles[0].roles
			var ap_defaulturl="";
			ap_defaulturl='/asiapacific/'+ap_rolefunc[0].base_url+'/'+ap_rolefunc[0].func_url;
			this.router.navigate([ap_defaulturl]);*/

			if(isRoutingEnable==true)
			{
			this.router.navigate(['/asiapacific/packagingprocessdashboard/packagingprocesshome'])
			}
	// 	}


	})
}


else
{
	let regionlist=JSON.parse(sessionStorage.getItem('user_regionaccess'))
	 let findApIndex=regionlist.findIndex(elref=>elref.toLowerCase()=='ap')
	 let region_code='AP'
	 let name=region_code+'useraccess';
	 sessionStorage.removeItem(name);

	 if(findApIndex!=-1)
	 {
		 regionlist.splice(findApIndex,1);

		 sessionStorage.removeItem('user_regionaccess');
					sessionStorage.setItem('user_regionaccess', JSON.stringify(regionlist));

	 }


	 if(JSON.parse(sessionStorage.getItem('isGlobalAdmin'))==true)
					{
						sessionStorage.removeItem('isGlobalAdmin');
					sessionStorage.setItem('isGlobalAdmin', JSON.stringify(false));
					}


				if(regionlist!=null && regionlist.length!=0)
				{
					let current_region=regionlist[0];
					if(isRoutingEnable==true)
					{

					this.routeToCurrentRegion(current_region);
					}
				}

				else
				{
					// redirect to exit page

					if(isRoutingEnable==true)
					{
					this.router.navigate(['/exitpage']);
					}



				}
}







			}

			else{

				// redirect to exit page or request access as no user role present
				if(isRoutingEnable==true)
				{
				this.router.navigate(['/exitpage']);
				}
			}







		}


		else
		{
			// redirect to exit page
			if(isRoutingEnable==true)
				{
			this.router.navigate(['/exitpage']);
				}
		}

		})
	}
	navigateeu(isRoutingEnable:boolean)
	{



		let region;
		region=this.transactionService.getregion();
		console.log(region);






		// console.log("eusideeee");


		let user;
		user=this.transactionService.getcdsid()



		this.eu=JSON.parse(sessionStorage.getItem('eu'));

		// pass value for eavh rwgion and for each region store the roles
		if(isRoutingEnable==true)
		{
			sessionStorage.removeItem('region');
		sessionStorage.setItem('region', JSON.stringify('Europe'));
			this.navigatena(false);
			this.navigateap(false);
			this.navigatesa(false);
		}

		// console.log(user,"inghk")
		this.UserAccess=[];
		this.transactionService.getUserAccessRole(user).subscribe(useraccess=>{
			console.log('useracces',useraccess);
			this.UserAccess=useraccess;
			if(this.UserAccess!=null)
			{
				if(this.UserAccess.length!=0)
				{
					let eu_roles:user_roles[]
					eu_roles=[];
					let eu_role_ids='';


					let regGlbIndexEU=this.UserAccess.findIndex(elglbregeu=>elglbregeu.role_id==100)
				let regIndexEU=this.UserAccess.findIndex(elregeu=>elregeu.cd_region.toLowerCase()=='eu')



				if(regGlbIndexEU!=-1)
				{
					if(JSON.parse(sessionStorage.getItem('isGlobalAdmin'))==false)
					{
						sessionStorage.removeItem('isGlobalAdmin');
					sessionStorage.setItem('isGlobalAdmin', JSON.stringify(true));

					this.updateRegionListGlbAdmin();
					}


					let rolefunc:subroles[]=[];


					eu_roles.push({roleid:100,roles:rolefunc})
					eu_role_ids='100';



				}


				if(regIndexEU!=-1)
				{


					this.updateRegionListOneRegion('EU');

				for(let mtemp=0;mtemp<this.UserAccess.length;mtemp++)
				{
					if(this.UserAccess[mtemp].cd_region=='EU')
					{


					let rolefunc:subroles[]=[];





						if(eu_roles.length==0)
						{
							// console.log(this.UserAccess[mtemp].role_id);
						// console.log(rolefunc);


						eu_roles.push({roleid:this.UserAccess[mtemp].role_id,roles:rolefunc})
						eu_role_ids=this.UserAccess[mtemp].role_id.toString();
						}
						else{


							let present=false;

							for(let mi=0;mi<eu_roles.length;mi++)
							{
								if(eu_roles[mi].roleid==this.UserAccess[mtemp].role_id)
								{
									present=true;
									break;
								}
							}

							if(present==false)
							{
								// console.log(this.UserAccess[mtemp].role_id);
						// console.log(rolefunc);
						eu_roles.push({roleid:this.UserAccess[mtemp].role_id,roles:rolefunc})
						eu_role_ids+=',';
						eu_role_ids+=this.UserAccess[mtemp].role_id.toString();

							}

						}





				}

				}
			}

				console.log(eu_roles);
				console.log(eu_role_ids);


				if(regIndexEU!=-1||regGlbIndexEU!=-1)
				{


				this.transactionService.getuserrolefunctionality(eu_role_ids,'EU').subscribe(userrolefuncaccess=>{


					console.log(userrolefuncaccess);
					for(let ieu=0;ieu<eu_roles.length;ieu++)
					{
						eu_roles[ieu].roles=[]
                        // @ts-ignore
						rolefunc=[];
						for(let jeu=0;jeu<userrolefuncaccess.length;jeu++)
						{
							if(eu_roles[ieu].roleid==userrolefuncaccess[jeu].role_id)
							{


								eu_roles[ieu].roles.push(userrolefuncaccess[jeu]);


							}
						}
					}
				let region_code='EU'
				let name=region_code+'useraccess';
				sessionStorage.setItem(name,JSON.stringify(eu_roles));


				console.log(name,eu_roles)


				// console.log("euoptest",this.eu);

	/*
				if(this.eu!=null)
			{

				console.log("euoptest",this.eu);*/ // for now
				/*

				var eu_url_parameters;
				eu_url_parameters=this.eu.split('/')
				comsole.log(eu_url_parameters[1])
				if(eu_url_parameters[1]=="packsupplier")
				{
					console.log(eu_roles)

					var eu_supp_present
					eu_supp_present=false;
					for(var ni=0;ni<eu_roles.length;ni++)
					{

						if(eu_roles[ni]==1)
						{
							eu_supp_present=true;
							break;
						}


					}

					if(eu_supp_present==true)
					{
						//check for functionality if eu_url_parameter[2] exist
					}



				}


				else if() repeat same for other roles

				else
				{
					route to default url or url they have access
				}
				*/
	/*
			this.router.navigate([this.eu]);
			}

			else{*/


				console.log(eu_roles);
				console.log(eu_roles[0]);
				/*var eu_rolefunc=eu_roles[0].roles
				var eu_defaulturl="";
				eu_defaulturl='/europe/'+eu_rolefunc[0].base_url+'/'+eu_rolefunc[0].func_url;
				this.router.navigate([eu_defaulturl]);*/
				console.log(isRoutingEnable)
				if(isRoutingEnable==true)
				{
					console.log('/europe/packagingprocessdashboard/packagingprocesshom')
				this.router.navigate(['/europe/packagingprocessdashboard/packagingprocesshome']);
				}
			// }
		})
	}


	else
{
	let regionlist=JSON.parse(sessionStorage.getItem('user_regionaccess'))
	 let findEuIndex=regionlist.findIndex(elref=>elref.toLowerCase()=='eu')
	 let region_code='EU'
	 let name=region_code+'useraccess';
	 sessionStorage.removeItem(name);


	 if(findEuIndex!=-1)
	 {
		 regionlist.splice(findEuIndex,1);

		 sessionStorage.removeItem('user_regionaccess');
					sessionStorage.setItem('user_regionaccess', JSON.stringify(regionlist));

	 }


	 if(JSON.parse(sessionStorage.getItem('isGlobalAdmin'))==true)
	 {
		 sessionStorage.removeItem('isGlobalAdmin');
	 sessionStorage.setItem('isGlobalAdmin', JSON.stringify(false));
	 }


				if(regionlist!=null && regionlist.length!=0)
				{
					let current_region=regionlist[0];
					if(isRoutingEnable==true)
					{

					this.routeToCurrentRegion(current_region);
					}
				}

				else
				{
					// redirect to exit page

					if(isRoutingEnable==true)
					{
					this.router.navigate(['/exitpage']);
					}



				}
}



				}

				else{

					// redirect to exit page or request access as no user role present

					if(isRoutingEnable==true)
					{
					this.router.navigate(['/exitpage']);
					}
				}



		}


		else
		{
			if(isRoutingEnable==true)
				{
			this.router.navigate(['/exitpage']);
				}
		}

		})

	}
	navigatena(isRoutingEnable:boolean)
	{
		console.log('insidena')
		let region;
		region=this.transactionService.getregion();
		console.log(region);


		this.na=JSON.parse(sessionStorage.getItem('na'));
		console.log('nasideeee');



		let roles
		let user;
		user=this.transactionService.getcdsid();
		// pass value for eavh rwgion and for each region store the roles
		if(isRoutingEnable==true)
		{
			sessionStorage.removeItem('region');
		sessionStorage.setItem('region', JSON.stringify('North America'));

			this.navigateap(false);
			this.navigateeu(false);
			this.navigatesa(false);

		}
		this.transactionService.getUserAccessRole(user).subscribe(useraccess=>{
			console.log('useracces',useraccess);
			this.UserAccess=useraccess;
			if(this.UserAccess!=null)
			{
				if(this.UserAccess.length!=0)
				{
					let na_roles:user_roles[]
					na_roles=[];
					let na_role_ids=''


					let regGlbIndexNA=this.UserAccess.findIndex(elglbregna=>elglbregna.role_id==100)
				let regIndexNA=this.UserAccess.findIndex(elregna=>elregna.cd_region.toLowerCase()=='na')



				if(regGlbIndexNA!=-1)
				{
					if(JSON.parse(sessionStorage.getItem('isGlobalAdmin'))==false)
					{
						sessionStorage.removeItem('isGlobalAdmin');
					sessionStorage.setItem('isGlobalAdmin', JSON.stringify(true));


					this.updateRegionListGlbAdmin();
					}








					let rolefunc:subroles[]=[];


					na_roles.push({roleid:100,roles:rolefunc})
					na_role_ids='100';



				}


				   if(regIndexNA!=-1)
				   {
					this.updateRegionListOneRegion('NA');

				for(let mtemp=0;mtemp<this.UserAccess.length;mtemp++)
				{
					if(this.UserAccess[mtemp].cd_region=='NA')
					{



						console.log(this.UserAccess[mtemp].role_id)


							let rolefunc:subroles[]=[];


							if(na_roles.length==0)
							{

								console.log(this.UserAccess[mtemp].role_id,'insideif')


								na_roles.push({roleid:this.UserAccess[mtemp].role_id,roles:rolefunc})
								na_role_ids=this.UserAccess[mtemp].role_id.toString()




							}


							else{


								let present=false;
								console.log(this.UserAccess[mtemp].role_id,'insideelse')


							for(let mi=0;mi<na_roles.length;mi++)
							{
								console.log(mi);
								console.log(na_roles[mi].roleid)
								if(na_roles[mi].roleid==this.UserAccess[mtemp].role_id)
								{
									console.log(this.UserAccess[mtemp].role_id,'insideelsetrue')


									present=true;
									break;
								}
							}

							if(present==false)
							{
								console.log(this.UserAccess[mtemp].role_id,'insideelsefalse')

								// console.log(this.UserAccess[mtemp].role_id);
						// console.log(rolefunc);

						na_roles.push({roleid:this.UserAccess[mtemp].role_id,roles:rolefunc})
						na_role_ids+=',';
						na_role_ids+=this.UserAccess[mtemp].role_id.toString();

							}


						}


					}






				}
			}



				console.log(na_roles);
				console.log(na_role_ids)


				if(regIndexNA!=-1|| regGlbIndexNA!=-1)
				{




				this.transactionService.getuserrolefunctionality(na_role_ids,'NA').subscribe(userrolefuncaccess=>{


					console.log(userrolefuncaccess);
					for(let ina=0;ina<na_roles.length;ina++)
					{
						na_roles[ina].roles=[]
                        // @ts-ignore
						rolefunc=[];
						for(let jna=0;jna<userrolefuncaccess.length;jna++)
						{
							if(na_roles[ina].roleid==userrolefuncaccess[jna].role_id)
							{


								na_roles[ina].roles.push(userrolefuncaccess[jna]);


							}
						}
					}







				console.log(na_roles)
				let region_code='NA'
				let name=region_code+'useraccess';
				sessionStorage.setItem(name,JSON.stringify(na_roles));

				console.log(name,na_roles)

	/*
				if(this.na!=null)
			{*/

				console.log(this.na);
				/*

				var na_url_parameters;
				na_url_parameters=this.na.split('/')
				comsole.log(na_url_parameters[1])
				if(na_url_parameters[1]=="packsupplier")
				{
					console.log(na_roles)

					var na_supp_present
					na_supp_present=false;
					for(var ni=0;ni<na_roles.length;ni++)
					{

						if(na_roles[ni]==1)
						{
							na_supp_present=true;
							break;
						}


					}

					if(na_supp_present==true)
					{
						//check for functionality if na_url_parameter[2] exist
					}



				}


				else if() repeat same for other roles

				else
				{
					route to default url or url they have access
				}
				*/

		/*	this.router.navigate([this.na]);
			}

			else{*/

				console.log(na_roles);
				console.log(na_roles[0]);
				/*var na_rolefunc=na_roles[0].roles
				var na_defaulturl="";
				na_defaulturl='/northamerica/'+na_rolefunc[0].base_url+'/'+na_rolefunc[0].func_url;
				this.router.navigate([na_defaulturl]);*/
				if(isRoutingEnable==true)
				{
				this.router.navigate(['/northamerica/packagingprocessdashboard/packagingprocesshome']);
				// this.packagingdashboard.ngOnInit();

				}
			// }
		})
	}




else
{
	let regionlist=JSON.parse(sessionStorage.getItem('user_regionaccess'))
	 let findNaIndex=regionlist.findIndex(elref=>elref.toLowerCase()=='na')
	 let region_code='NA'
	 let name=region_code+'useraccess';
	 sessionStorage.removeItem(name);


	 if(findNaIndex!=-1)
	 {
		 regionlist.splice(findNaIndex,1);

		 sessionStorage.removeItem('user_regionaccess');
					sessionStorage.setItem('user_regionaccess', JSON.stringify(regionlist));

	 }


	 if(JSON.parse(sessionStorage.getItem('isGlobalAdmin'))==true)
	 {
		 sessionStorage.removeItem('isGlobalAdmin');
	 sessionStorage.setItem('isGlobalAdmin', JSON.stringify(false));
	 }


				if(regionlist!=null && regionlist.length!=0)
				{
					let current_region=regionlist[0];
					if(isRoutingEnable==true)
					{

					this.routeToCurrentRegion(current_region);
					}
				}

				else
				{
					// redirect to exit page

					if(isRoutingEnable==true)
					{
					this.router.navigate(['/exitpage']);
					}



				}
}

				}

				else{

					// redirect to exit page or request access as no user role present
					if(isRoutingEnable==true)
				{
					this.router.navigate(['/exitpage']);
				}
				}



		}


		else
		{
			if(isRoutingEnable==true)
				{
			this.router.navigate(['/exitpage']);
				}
		}




		})

	}



	navigatesa(isRoutingEnable:boolean)
	{



		let region;
		region=this.transactionService.getregion();
		console.log(region);


		this.sa=JSON.parse(sessionStorage.getItem('sa'));


		// console.log("issidesa")
		// console.log("sasideeee");



		let roles
		let user;
		user=this.transactionService.getcdsid();
		// pass value for eavh rwgion and for each region store the roles
		if(isRoutingEnable==true)
		{
			sessionStorage.removeItem('region');
		sessionStorage.setItem('region', JSON.stringify('South America'));

			this.navigatena(false);
			this.navigateeu(false);
			this.navigateap(false);
		}
		this.transactionService.getUserAccessRole(user).subscribe(useraccess=>{
			console.log('sauseracces',useraccess);
			this.UserAccess=useraccess;
			if(this.UserAccess!=null)
			{
				if(this.UserAccess.length!=0)
				{
					let sa_roles:user_roles[]
					sa_roles=[];
					let sa_role_ids=''



					let regGlbIndexSA=this.UserAccess.findIndex(elglbregsa=>elglbregsa.role_id==100)
				let regIndexSA=this.UserAccess.findIndex(elregsa=>elregsa.cd_region.toLowerCase()=='sa')



				if(regGlbIndexSA!=-1)
				{
					if(JSON.parse(sessionStorage.getItem('isGlobalAdmin'))==false)
					{
						sessionStorage.removeItem('isGlobalAdmin');
					sessionStorage.setItem('isGlobalAdmin', JSON.stringify(true));

					this.updateRegionListGlbAdmin();
					}


					let rolefunc:subroles[]=[];


					sa_roles.push({roleid:100,roles:rolefunc})
					sa_role_ids='100';



				}


				if(regIndexSA!=-1)
				{


					this.updateRegionListOneRegion('SA');
				for(let mtemp=0;mtemp<this.UserAccess.length;mtemp++)
				{
					if(this.UserAccess[mtemp].cd_region=='SA')
					{


						let rolefunc:subroles[]=[];


						if(sa_roles.length==0)
						{
							// console.log(this.UserAccess[mtemp].role_id);
						// console.log(rolefunc);







						sa_roles.push({roleid:this.UserAccess[mtemp].role_id,roles:rolefunc})
						sa_role_ids=this.UserAccess[mtemp].role_id.toString();


					// 	})
						}
						else{


							let present=false;

							for(let mi=0;mi<sa_roles.length;mi++)
							{
								if(sa_roles[mi].roleid==this.UserAccess[mtemp].role_id)
								{
									present=true;
									break;
								}
							}

							if(present==false)
							{
								// console.log(this.UserAccess[mtemp].role_id);
						// console.log(rolefunc);





						sa_roles.push({roleid:this.UserAccess[mtemp].role_id,roles:rolefunc})
						sa_role_ids+=','
						sa_role_ids+=this.UserAccess[mtemp].role_id.toString();


						// })
							}

						}





				}

				}

			}


			console.log(sa_roles)


			console.log(regIndexSA,regGlbIndexSA)

			if(regIndexSA!=-1 || regGlbIndexSA!=-1)
			{


				this.transactionService.getuserrolefunctionality(sa_role_ids,'SA').subscribe(userrolefuncaccess=>{


					console.log(userrolefuncaccess);
					for(let isa=0;isa<sa_roles.length;isa++)
					{
						sa_roles[isa].roles=[]
                        // @ts-ignore
						rolefunc=[];
						for(let jsa=0;jsa<userrolefuncaccess.length;jsa++)
						{
							if(sa_roles[isa].roleid==userrolefuncaccess[jsa].role_id)
							{


								sa_roles[isa].roles.push(userrolefuncaccess[jsa]);


							}
						}
					}






				let region_code='SA'
				let name=region_code+'useraccess';


				console.log(name,sa_roles);
				sessionStorage.setItem(name,JSON.stringify(sa_roles));

	/*
				if(this.sa!=null)
			{

				console.log(this.sa);*/
				/*

				var sa_url_parameters;
				sa_url_parameters=this.sa.split('/')
				comsole.log(sa_url_parameters[1])
				if(sa_url_parameters[1]=="packsupplier")
				{
					console.log(sa_roles)

					var sa_supp_present
					sa_supp_present=false;
					for(var ni=0;ni<sa_roles.length;ni++)
					{

						if(sa_roles[ni]==1)
						{
							sa_supp_present=true;
							break;
						}


					}

					if(sa_supp_present==true)
					{
						//check for functionality if sa_url_parameter[2] exist
					}



				}


				else if() repeat same for other roles

				else
				{
					route to default url or url they have access
				}
				*/

		/*	this.router.navigate([this.sa]);
			}

			else{
*/


				console.log(sa_roles);
				console.log(sa_roles[0]);
			/*	var sa_rolefunc=sa_roles[0].roles
				var sa_defaulturl="";
				sa_defaulturl='/southameriaca/'+sa_rolefunc[0].base_url+'/'+sa_rolefunc[0].func_url;
				this.router.navigate([sa_defaulturl]);*/


				if(isRoutingEnable==true)
				{

				this.router.navigate(['/southamerica/packagingprocessdashboard/packagingprocesshome']);
				}
			// }
		})
	}
	else
	{


		let regionlist=JSON.parse(sessionStorage.getItem('user_regionaccess'))
	 let findSaIndex=regionlist.findIndex(elref=>elref.toLowerCase()=='sa')

	 let region_code='SA'
	 let name=region_code+'useraccess';
	 sessionStorage.removeItem(name)

	 if(findSaIndex!=-1)
	 {
		 regionlist.splice(findSaIndex,1);


		 console.log('regionlist',regionlist)

		 sessionStorage.removeItem('user_regionaccess');
					sessionStorage.setItem('user_regionaccess', JSON.stringify(regionlist));

	 }


	 if(JSON.parse(sessionStorage.getItem('isGlobalAdmin'))==true)
	 {
		 sessionStorage.removeItem('isGlobalAdmin');
	 sessionStorage.setItem('isGlobalAdmin', JSON.stringify(false));
	 }


				if(regionlist!=null && regionlist.length!=0)
				{
					let current_region=regionlist[0];

					if(isRoutingEnable==true)
					{

					this.routeToCurrentRegion(current_region);
					}
				}

				else
				{
					// redirect to exit page

                    if(isRoutingEnable==true)
				{
					this.router.navigate(['/exitpage']);
				}



				}

	}
				}

				else{

					// redirect to exit page or request access as no user role present
					if(isRoutingEnable==true)
				{
					this.router.navigate(['/exitpage']);
				}
				}



		}

		else
		{
			if(isRoutingEnable==true)
				{
			this.router.navigate(['/exitpage']);
				}
		}

		})


	}








	routeToCurrentRegion(current_region:any)
	{
		if(current_region=='NA')

		{

		 this.navigatena(true);

		}
		else if(current_region=='AP')
		{

		 this.navigateap(true);

		}
		else if(current_region=='EU')
		{
		 this.navigateeu(true);
		}
		else if(current_region=='SA')
		{
		 this.navigatesa(true);
		}
	}


	updateRegionListGlbAdmin()
	{


		console.log('update')
		let glbadmin=JSON.parse(sessionStorage.getItem('isGlobalAdmin'))
		if(glbadmin==true)
		{


			let regionlistupdate=JSON.parse(sessionStorage.getItem('user_regionaccess'))


			if(regionlistupdate.length!=4)
			{
				let user_region=[];
				let regionListact=['AP','EU','NA','SA']

				user_region.push(...regionListact);


				sessionStorage.removeItem('user_regionaccess');
						   sessionStorage.setItem('user_regionaccess', JSON.stringify(user_region));

			}




		}
	}



	updateRegionListOneRegion(regionCode:any)
	{


		console.log('Region List Update On Region',regionCode);
		let regionlistupdateonregion=JSON.parse(sessionStorage.getItem('user_regionaccess'))

		if(regionlistupdateonregion.length!=4)
			{

				let regionList=['AP','EU','NA','SA']


				let findRegionIndex=regionlistupdateonregion.findIndex(elref=>elref.toLowerCase()==regionCode.toLowerCase())

				if(findRegionIndex==-1)
				{
					regionlistupdateonregion.push(regionCode);
					sessionStorage.removeItem('user_regionaccess');
						   sessionStorage.setItem('user_regionaccess', JSON.stringify(regionlistupdateonregion));
				}




			}






	}





/*	ngOnInit()
	{
		this.adminvalue=this.transactionServices.getadmin();
		if(this.adminvalue=="global_admin"|| this.adminvalue=="plant_admin")
		{
			this.visibletoall=true

			//return true;
		}
		else{
			this.visibletoall=false;
			//return false;
		}*/


	/*this.navMenuItems = [
		/*{
			label: 'Example Page',
			routerLink: '/example-page',
			command: this.hideSidebar,
			//visible:this.userid()
		},
		{
			label: 'Dashboard',
			routerLink: '/dashboardpage',
			command: this.hideSidebar,
			//visible:this.userid()
		},

		{
			label: 'Orders',
			//routerLink: '/FleetPage',
			command: this.hideSidebar,
			//visible:this.userid()

		items:[
			{
				label:'Current',
				routerLink: '/orderspage',
				//icon:'pi pi-fw pi-align-left'
			},
			{
				label:'History',
				//icon:'pi pi-fw pi-align-right'
				routerLink: '/ordershistorypage',
			},

		]
	},



		{
			label: 'Fleet',
			routerLink: '/FleetPage',
			command: this.hideSidebar,
			//visible:this.userid()
		},


		{
			label: 'Anomalies',
			//routerLink: '/FleetPage',
			command: this.hideSidebar,
			//visible:this.userid(),

		items:[
			{
				label:'Current',
				routerLink: '/anamoliespage',
				//icon:'pi pi-fw pi-align-left'
			},
			{
				label:'History',
				//icon:'pi pi-fw pi-align-right'
				routerLink: '/anamolieshistorypage',
			},

		]
	},



		{
			label: 'Mapping',
			routerLink: '/mappingpage',
			command: this.hideSidebar,
			//visible:this.admin()|| this.plantadmin()
		},

		{
			label: ' Plant/Supplier',
			routerLink: '/plantsupreg',
			command: this.hideSidebar,
			//visible:this.admin()||this.plantadmin()
		},

		{
			label: ' User',
			routerLink: '/userreg',
			command: this.hideSidebar,
			//visible: this.visibletoall//this.admin()||this.plantadmin()
		},




	];
}


	userid():boolean{
		this.Userid=this.transactionServices.getcdsid();
		console.log(this.Userid)
		if(this.Userid==null)
		{
			this.visibility=false;
			return false;
		}
		else{
			this.visibility=true;
			return true;
		}
	}
	admin()
	{
		this.adminvalue=this.transactionServices.getadmin();
		if(this.adminvalue=="global_admin"|| this.adminvalue=="plant_admin")
		{
			this.visibletoall=true

			//return true;
		}
		else{
			this.visibletoall=false;
			//return false;
		}

	}
/*	plantadmin():boolean
	{
		this.adminvaluepl=this.transactionServices.getadmin();
		if(this.adminvaluepl=="plant_admin")
		{
			return true;
		}
		else{
			return false;
		}

	}

	exitpage() {
		this.transactionServices.logout();
		//alert("Close your winodw and re-login. Thanks! ");
		this.router.navigate(['/exitpage']);
	}*/
}
