/* tslint:disable */
import { DatePipe, UpperCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import {TransactionService} from '../loginpage/Transaction.service';
import {addContainer, containerCurrencyList, containerGroupList, containerMasterCategory, containerMaterial, containerOwnershipList, containerTypeCode, GridContInr,componentlist, cntrbaseandsuffix, cntrDetailsFromCMMS, cntrautocomplete, plantlist, containerDetailsCntrAdmin, containerMaterialList, componentInViewOrModify, containerGroupWithActiveField, expretList, GridContInrFilter, containerEditCmmsFields, containerDetailsSaveCntrAdmin, componentEditCmmsFields, suppdesgnList, containerGroupActiveList, containerOwnershipCategoryList, contoncoslist} from '../loginpage/transactions';




@Component({
  selector: 'app-contadmin',
  templateUrl: './contadmin.component.html',
  styleUrls: ['./contadmin.component.css'],
  providers:[MessageService]
})


export class ContadminComponent implements OnInit {

current_region_prg:string;
current_region_code_prg:string;



public cols:any[];
unModifiedGrid:GridContInr[]=[];
containerGrid:GridContInr[];

cntrSuffixenabled=false;
cntrSuffixEditenabled=false;
cntrSuffixAdd='';
cntrSuffixEdit='';

cntrAddEnable:boolean;
cntrAddDetailsEnable:boolean;

enabledetails:boolean;
enableeditgetdetails:boolean;

enableeditcntrdetails:boolean;
enableCntrExistInNGPPNotification=false;
enableCompExistInNgppNotification=false;

/* for container base autocomplete feature*/

cntrBaseAndSuffixList:cntrbaseandsuffix[]=[];
cntrbaseselected:boolean;
selectedbase:string;
selectedcntrbase:cntrbaseandsuffix={no_cntr_base:'',no_cntr_suffix:''};


cntrBaseAndSuffixListForAutoComplete:cntrautocomplete[]=[];
selectedCntrFromAutoCmplete:cntrautocomplete={no_cntr_base:'',no_cntr_suffix:'',cntr_base_and_suffix:''};



/* for container base autocomplete feature*/


// Added
    plantList:plantlist[]=[];
    selectedPlantList:plantlist={cd_plant:'',plantGSDBCode:'',plant_active_stat:'',plant_name:'',plant_type_name:''};
    editSelectedPlantCode:plantlist={cd_plant:'',plantGSDBCode:'',plant_active_stat:'',plant_name:'',plant_type_name:''};
    getSelectedPlantCode:plantlist={cd_plant:'',plantGSDBCode:'',plant_active_stat:'',plant_name:'',plant_type_name:''};

    cntrMasterCategory:containerMasterCategory[]=[];
    selected_cntr_master_category:containerMasterCategory={cd_cntr_mstr_catg:0,cntr_mstr_catg_desc:'',updated_by:'',updated_dt:'',cntr_mstr_catg:'',active_stat:'',active_stat_display:false};
    edit_selected_cntr_master_category:containerMasterCategory={cd_cntr_mstr_catg:0,cntr_mstr_catg_desc:'',updated_by:'',updated_dt:'',cntr_mstr_catg:'',active_stat:'',active_stat_display:false};
    get_selected_cntr_master_category:containerMasterCategory={cd_cntr_mstr_catg:0,cntr_mstr_catg_desc:'',updated_by:'',updated_dt:'',cntr_mstr_catg:'',active_stat:'',active_stat_display:false};

    // Added
    cntrTypeCode:containerTypeCode[]=[];
    unmodifiedTypeCodes:containerTypeCode[]=[];
    selected_cntr_type_code:containerTypeCode={cd_cntr_typ:'',cntr_typ_desc:'',typ_no:0};
    edit_selected_cntr_type_code:containerTypeCode={cd_cntr_typ:'',cntr_typ_desc:'',typ_no:0};
    get_selected_cntr_type_code:containerTypeCode={cd_cntr_typ:'',cntr_typ_desc:'',typ_no:0};

    cntrOwnershipstratcode:containerOwnershipList[]=[];
    selected_cntr_ownrship_strat_code:containerOwnershipList={cd_region:'',ownrshp_strat_code:'',ownrshp_desc:'',cd_ownrshp_strat:0};
    edit_selected_cntr_ownrship_strat_code:containerOwnershipList={cd_region:'',ownrshp_strat_code:'',ownrshp_desc:'',cd_ownrshp_strat:0};
    get_selected_cntr_ownrship_strat_code:containerOwnershipList={cd_region:'',ownrshp_strat_code:'',ownrshp_desc:'',cd_ownrshp_strat:0};

    cntrGrp:containerGroupActiveList[]=[];
    selected_cntr_group:containerGroupActiveList={cntr_grp:''};
    edit_selected_cntr_group:containerGroupActiveList={cntr_grp:''};
    warningMsgForComp: string;
    warningMsgforCntr: string;

    get_selected_cntr_group:containerGroupActiveList={cntr_grp:''};

    cntrMaterial:containerMaterialList[]=[];
    selected_cntr_material:containerMaterialList={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
    edit_selected_cntr_material:containerMaterialList={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
    get_selected_cntr_material:containerMaterialList={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};

    cntrCurrencyList:containerCurrencyList[]=[];
    selected_cntr_currency_list:containerCurrencyList={cd_curr:0,curr_desc:''};
    edit_selected_cntr_currency_list:containerCurrencyList={cd_curr:0,curr_desc:''};
    get_selected_cntr_currency_list:containerCurrencyList={cd_curr:0,curr_desc:''};

    ownrship_catg:containerOwnershipCategoryList[]=[{ownrshp_catg:'Ford',ownrshp_catg_val:'FORD'}, {ownrshp_catg:'Supplier',ownrshp_catg_val:'SUPP'},{ownrshp_catg:'Third Party',ownrshp_catg_val:'TPT'}];
    selected_ownrship_catg:containerOwnershipCategoryList={ownrshp_catg:'',ownrshp_catg_val:''};
    edit_selected_ownrship_catg:containerOwnershipCategoryList={ownrshp_catg:'',ownrshp_catg_val:''};
    get_selected_ownrship_catg:containerOwnershipCategoryList={ownrshp_catg:'',ownrshp_catg_val:''};
    enableProcessingNotifiacationDialog=false;

    enableCntrEditPendingNotification=false;
    enableAddingNewContainerNotification=false;
    enableUpdateExistingCntrNotification=false;


    comp_list:componentlist[]=[];
    view_modify_container_base=' ';
    view_modify_container_suffix=' ';
    view_modify_cd_cntr=0;
    view_comp_enable=false;
    public compcols:any;
    comp_present_string=' ';
    pag_val=false;


    add_modify_comp_dialog_enable=false;
    componentListAll:componentlist[]=[];
    selectedComponentList:componentlist[]=[];


    componentListFromAPi:GridContInrFilter[]=[];




    newCompToBeAddedList:any[];
    existCompToBeRemovedList:any[];

    isCmmsLoadedContainer=false;
    isCmmsLoadedComponent=false;


    saveAddContainerDirectToCntrAdmin:containerDetailsSaveCntrAdmin={
      cd_cntr:0,
      cntr_suff_ind:'',
      exp_refundable:'',
      cntr_active_ind:'',
      cntr_base:'',
      cntr_suffix:'',
      cntr_desc:'',
      card_call:'',
      cntr_mstr_catg:'',
      cntr_typ_desc:'',
      ownrshp_strat_code:'',
      ownrshp_catg:'',
      cntr_grp_desc:'',
      cntr_exterior_l:0,
      cntr_exterior_w:0,
      cntr_exterior_h:0,
      collapsed_ht:0,
      nest_ratio:0,
      cntr_interior_l:0,
      cntr_interior_w:0,
      cntr_interior_h:0,
      cntr_tare_wt:0,
      cntr_capacity:0,
      cntr_per_layer:0,
      max_layers_shpg_unit:0,
      max_cntr_per_shpg_unit:0,
      cntr_matr_desc:'',
      cntr_drawing_no:'',
      shpg_unit_exterior_l:0,
      shpg_unit_exterior_w:0,
      shpg_unit_exterior_h:0,
      shpg_unit_nstdorflded_ht:0,
      shpg_unit_tare_wt:0,
      shpg_unit_stack_ht:0,
      warehouse_stack_ht:0,
      service_life:'',
      currency_desc:'',
      purchase_cost:0,
      rental_issue_cost:0,
      sup_rntl_dly_cost:0,
      ford_rntl_dly_cost:0,
      return_rntl_cost:0,
      tooling_dev_cost:0,
      extr_cntr_dsgn_img_ind:'',
      intr_cntr_dsgn_img_ind:'',
      shpg_unit_img_ind:'',
      cad_img_ind:'',
      img_notes:'',
      cd_region:'',
      iscontainercolapsble:'',
      cd_plant: '',
      cnt_qt_per_min_su: 0,
      nest_ratio_1: 0,
      ford_cntct: '',
      cntct_phone: '',
      req_stat: '',
      id_user: '',
      ts_last_update: '',
      uom:'E',
      typ_no:0,
    }

    saveAddComponentDirectToCntrAdmin:containerDetailsSaveCntrAdmin={
      cd_cntr:0,
      cntr_suff_ind:'',
      exp_refundable:'',
      cntr_active_ind:'',
      cntr_base:'',
      cntr_suffix:'',
      cntr_desc:'',
      card_call:'',
      cntr_mstr_catg:'',
      cntr_typ_desc:'',
      ownrshp_strat_code:'',
      ownrshp_catg:'',
      cntr_grp_desc:'',
      cntr_exterior_l:0,
      cntr_exterior_w:0,
      cntr_exterior_h:0,
      collapsed_ht:0,
      nest_ratio:0,
      cntr_interior_l:0,
      cntr_interior_w:0,
      cntr_interior_h:0,
      cntr_tare_wt:0,
      cntr_capacity:0,
      cntr_per_layer:0,
      max_layers_shpg_unit:0,
      max_cntr_per_shpg_unit:0,
      cntr_matr_desc:'',
      cntr_drawing_no:'',
      shpg_unit_exterior_l:0,
      shpg_unit_exterior_w:0,
      shpg_unit_exterior_h:0,
      shpg_unit_nstdorflded_ht:0,
      shpg_unit_tare_wt:0,
      shpg_unit_stack_ht:0,
      warehouse_stack_ht:0,
      service_life:'',
      currency_desc:'',
      purchase_cost:0,
      rental_issue_cost:0,
      sup_rntl_dly_cost:0,
      ford_rntl_dly_cost:0,
      return_rntl_cost:0,
      tooling_dev_cost:0,
      extr_cntr_dsgn_img_ind:'',
      intr_cntr_dsgn_img_ind:'',
      shpg_unit_img_ind:'',
      cad_img_ind:'',
      img_notes:'',
      cd_region:'',
      iscontainercolapsble:'',
      cd_plant: '',
      cnt_qt_per_min_su: 0,
      nest_ratio_1: 0,
      ford_cntct: '',
      cntct_phone: '',
      req_stat: '',
      id_user: '',
      ts_last_update: '',
      uom:'E',
      typ_no:0,
    }

    componentListAssociatedContainerApi:componentlist[]=[];
    enableAddRemoveButtons =false;

    dropDownList=[
      {name:'Yes',namevalue:'Y'},
      {name:'No',namevalue:'N'},
   ]

   componentsAssociatedToContainerForPost:componentInViewOrModify[]=[];

   compListAllValuesCols:any[]
   selectedcompcols:any[]


   enable_alert=false;


   enable_notification_associatecontainer=false;


   associateCompFromTableButton=false;


   editing=false;


   cbase = '';
   csuffix = '';
   cdesc = '';
   cntgrpid = '';
   expretype = '';
   cntr_grpidList: containerGroupWithActiveField[]=[];
   select_cntr_grp_id : containerGroupWithActiveField = {cd_cntr_grp: '', cntr_grp:'', cntr_grp_desc:'', cd_region:'',active_stat:'',updated_by:'',updated_dt:'',active:false};

   expreturnList:  expretList[]=[];
   selectedExpRet: expretList={type:''};


   contain_base = '';
   contain_suffix = '';
   contain_desc = '';



   activestatusfilter=[{label:'All',value:null},{label:'Active',value:'Yes'},{label:'In-Active',value:'No'}]


   cntrdetailsforcompmap:addContainer=
   {
     function:'C',
   cntr_suff_ind:'',
   exp_refundable:'',
   cntr_active_ind:'',
   cntr_base:'',
   cntr_suffix:'',
   cntr_desc:'',
   card_call:'',
   cntr_mstr_catg:'',
   cntr_typ_desc:'',
   ownrshp_strat_code:'',
   ownrshp_catg:'',
   cntr_grp_desc:'',
   cntr_exterior_l:0,
   cntr_exterior_w:0,
   cntr_exterior_h:0,
   collapsed_ht:0.0,
   nest_ratio:0,
   cntr_interior_l:0,
   cntr_interior_w:0,
   cntr_interior_h:0,
   cntr_tare_wt:0,
   cntr_capacity:0,
   cntr_per_layer:0,
   max_layers_shpg_unit:0,
   max_cntr_per_shpg_unit:0,
   cntr_matr_desc:'',
   cntr_drawing_no:'',
   shpg_unit_exterior_l:0,
   shpg_unit_exterior_w:0,
   shpg_unit_exterior_h:0,
   shpg_unit_nstdorflded_ht:0,
   shpg_unit_tare_wt:0,
   shpg_unit_stack_ht:0,
   warehouse_stack_ht:0,
   service_life:'',
   currency_desc:'',
   purchase_cost:0,
   rental_issue_cost:0,
   sup_rntl_dly_cost:0,
   ford_rntl_dly_cost:0,
   return_rntl_cost:0,
   tooling_dev_cost:0,
   extr_cntr_dsgn_img_ind:'No',
   intr_cntr_dsgn_img_ind:'No',
   shpg_unit_img_ind:'No',
   cad_img_ind:'No',
   img_notes:'',
   cd_region:'',
   iscontainercolapsble:'',
   cd_plant:'',
   cntr_qt_per_min_su:0,
   nest_ratio_1:0,
   ford_cntct:'',
   cntct_phone:'',
   id_user:'',
   ts_last_update:'',
   req_stat:'',
   typ_no:0,
   components:[{
       mp_function:'',
       no_comp_base:'',
       no_comp_suffix:'',
       ds_comp:'',
       comp_qt_per_min_su:0,
       comp_cd_owner:'',
       in_comp:'',
       comp_cd_type:''
   }],


   UoM:'E',
   dbia_req_no:0
  }

    unitom = 'E';

    add_measure='';
    prev_add_measure:string;


    edit_measure='';
    prev_edit_measure:string;


    get_measure='';
    prev_get_measure:string;

    // Added
    enable_material_dropdown=false;

    // containerOrComponent:string;
    containerOrComponentInDetails:string;
    // containerOrComponentInEdit:string;
    // plantcode:string="";

    // containerquantityminshipunit:number=0;
    // cntrcntct:string="";
    // cntrcntctphonenumber:string="";
    // nestratio2:number;

    get_measure_cntr_details='';
    cntrOwnershipstratcodelist:containerOwnershipList[]=[];
    selected_cntr_ownrship_strat_code_in_popup:containerOwnershipList={cd_region:'',ownrshp_strat_code:'',ownrshp_desc:'',cd_ownrshp_strat:0};

    enteredCntrBase:string=null;
    prevCntrSuffixInd:string=null;
    prevCntrSuffixVal:string=null;

    select_contcos_dialog=false;
    contlist_oncos:contoncoslist[]=[];
    cont_cols:any;

    cntrdetails_display:containerDetailsCntrAdmin;

    selected_container:contoncoslist=null;
    table_selected_container:contoncoslist=null;

    tableSelectedReturnType='';
    table_selected_cntr_ownrship_strat_code_in_popup:containerOwnershipList={cd_region:'',ownrshp_strat_code:'',ownrshp_desc:'',cd_ownrshp_strat:0};

    cont_retype='Returnable';
    cont_grid_text='';
    cont_present_table=false;
    enabledetails_cntr=false;
    containerDetailsCopied=false;
    cntrSuffixEnabled=false;


    cntrSuffixInvalid=false;
    cntrSuffixInvalidReason:string=null

    cntrSuffixIndInvalid=false;
    cntrSuffixIndInvalidReason:string=null



    // This variable will true until we check the CMMS table to know whether base - suffix combination exist or not
    disableCntrDetailsWhenBaseOrSuffixChanged:boolean=true; 

    disableOwnrshpCateg: boolean = true;

    

    cntrdetailsfromload:cntrDetailsFromCMMS={
      no_cntr_base:'',
        no_cntr_suffix:'',
        ms_cntr_lt:0,
        ms_cntr_ht:0,
        ms_cntr_wd:0,
        ms_cntr_clpsd_ht:0,
        ms_cntr_tare_wt:0,
        ms_max_cap_wt:0,
        qt_cntr_stack_ht:0,
        qt_whse_stack_ht:0,
        qt_per_min_su:0,
        cd_cntr_type:'',
        cd_owner:'',
        na_ford_cntct:'',
        no_cntct_phone:'',
        cs_cntr_cost:'',
        ds_cntr_note:'',
        ds_cntr:'',
        id_user:'',
        ts_last_update:'',
        no_nesting_ratio1:0,
        no_nesting_ratio2:0,
        no_cntct_phone_1:''
    }

    cntrDetailsForCopying={
      cd_cntr:0,
      cntr_suff_ind:'',
      exp_refundable:'',
      cntr_active_ind:'',
      cntr_base:'',
      cntr_suffix:'',
      cntr_desc:'',
      card_call:'',
      cntr_mstr_catg:'',
      cntr_typ_desc:'',
      ownrshp_strat_code:'',
      ownrshp_catg:'',
      cntr_grp_desc:'',
      cntr_exterior_l:0,
      cntr_exterior_w:0,
      cntr_exterior_h:0,
      collapsed_ht:0.0,
      nest_ratio:0,
      cntr_interior_l:0,
      cntr_interior_w:0,
      cntr_interior_h:0,
      cntr_tare_wt:0,
      cntr_capacity:0,
      cntr_per_layer:0,
      max_layers_shpg_unit:0,
      max_cntr_per_shpg_unit:0,
      cntr_matr_desc:'',
      cntr_drawing_no:'',
      shpg_unit_exterior_l:0,
      shpg_unit_exterior_w:0,
      shpg_unit_exterior_h:0,
      shpg_unit_nstdorflded_ht:0,
      shpg_unit_tare_wt:0,
      shpg_unit_stack_ht:0,
      warehouse_stack_ht:0,
      service_life:'',
      currency_desc:'',
      purchase_cost:0,
      rental_issue_cost:0,
      sup_rntl_dly_cost:0,
      ford_rntl_dly_cost:0,
      return_rntl_cost:0,
      tooling_dev_cost:0,
      extr_cntr_dsgn_img_ind:'',
      intr_cntr_dsgn_img_ind:'',
      shpg_unit_img_ind:'',
      cad_img_ind:'',
      img_notes:'',
      cd_region:'',
      iscontainercolapsble:'',
      cd_plant: '',
      cnt_qt_per_min_su: 0,
      nest_ratio_1: 0,
      ford_cntct: '',
      cntct_phone: '',
      req_stat: '',
      id_user: '',
      ts_last_update: '',
      uom:'E',
      typ_no:0
    }

    // cntrdetailsfromCMMSforadd - not used currently

    cntrdetailsfromCMMSforadd: cntrDetailsFromCMMS =
    {
      no_cntr_base:'',
      no_cntr_suffix:'',
      ms_cntr_lt:0,
      ms_cntr_ht:0,
      ms_cntr_wd:0,
      ms_cntr_clpsd_ht:0,
      ms_cntr_tare_wt:0,
      ms_max_cap_wt:0,
      qt_cntr_stack_ht:0,
      qt_whse_stack_ht:0,
      qt_per_min_su:0,
      cd_cntr_type:'',
      cd_owner:'',
      na_ford_cntct:'',
      no_cntct_phone:'',
      cs_cntr_cost:'',
      ds_cntr_note:'',
      ds_cntr:'',
      id_user:'',
      ts_last_update:'',
      no_nesting_ratio1:0,
      no_nesting_ratio2:0,
      no_cntct_phone_1:''
    }

    cntrdetailsadd:addContainer=
    {
        // cd_cntr:0,
        function:'A',
        cntr_suff_ind:'',
        exp_refundable:'',
        cntr_active_ind:"Yes",
        cntr_base:'',
        cntr_suffix:'',
        cntr_desc:'',
        card_call:"Call",
        cntr_mstr_catg:'',
        cntr_typ_desc:'',
        ownrshp_strat_code:'',
        ownrshp_catg:'',
        cntr_grp_desc:'',
        cntr_exterior_l:0,
	      cntr_exterior_w:0,
	      cntr_exterior_h:0,
        collapsed_ht:0.0,
        nest_ratio:0,
        cntr_interior_l:0,
        cntr_interior_w:0,
        cntr_interior_h:0,
        cntr_tare_wt:0,
        cntr_capacity:0,
        cntr_per_layer:0,
        max_layers_shpg_unit:0,
        max_cntr_per_shpg_unit:0,
        cntr_matr_desc:'',
        cntr_drawing_no:'',
        shpg_unit_exterior_l:0,
        shpg_unit_exterior_w:0,
        shpg_unit_exterior_h:0,
        shpg_unit_nstdorflded_ht:0,
        shpg_unit_tare_wt:0,
        shpg_unit_stack_ht:0,
        warehouse_stack_ht:0,
        service_life:'',
        currency_desc:'',
        purchase_cost:0,
        rental_issue_cost:0,
        sup_rntl_dly_cost:0,
        ford_rntl_dly_cost:0,
        return_rntl_cost:0,
        tooling_dev_cost:0,
        extr_cntr_dsgn_img_ind:'No',
        intr_cntr_dsgn_img_ind:'No',
        shpg_unit_img_ind:'No',
        cad_img_ind:'No',
        img_notes:'',
        cd_region:'',
        iscontainercolapsble:'',
        cd_plant:'',
        cntr_qt_per_min_su:0,
        nest_ratio_1:0,
        ford_cntct:'',
        cntct_phone:'',
        id_user:'',
        ts_last_update:'',
        req_stat:'',
        components:[{
            mp_function:"",
            no_comp_base:"",
            no_comp_suffix:"",
            ds_comp:"",
            comp_qt_per_min_su:0,
            comp_cd_owner:'',
            in_comp:'',
            comp_cd_type:''
        }],
        UoM:'E',
        typ_no:0,
        dbia_req_no:0
    }

    cntrdetailsedit:addContainer=
    {
        function:'C',
        cntr_suff_ind:'',
        exp_refundable:'',
        cntr_active_ind:'',
        cntr_base:'',
        cntr_suffix:'',
        cntr_desc:'',
        card_call:"Call",
        cntr_mstr_catg:'',
        cntr_typ_desc:'',
        ownrshp_strat_code:'',
        ownrshp_catg:'',
        cntr_grp_desc:'',
        cntr_exterior_l:0,
	      cntr_exterior_w:0,
	      cntr_exterior_h:0,
        collapsed_ht:0,
        nest_ratio:0,
        cntr_interior_l:0,
        cntr_interior_w:0,
        cntr_interior_h:0,
        cntr_tare_wt:0,
        cntr_capacity:0,
        cntr_per_layer:0,
        max_layers_shpg_unit:0,
        max_cntr_per_shpg_unit:0,
        cntr_matr_desc:'',
        cntr_drawing_no:'',
        shpg_unit_exterior_l:0,
        shpg_unit_exterior_w:0,
        shpg_unit_exterior_h:0,
        shpg_unit_nstdorflded_ht:0,
        shpg_unit_tare_wt:0,
        shpg_unit_stack_ht:0,
        warehouse_stack_ht:0,
        service_life:'',
        currency_desc:'',
        purchase_cost:0,
        rental_issue_cost:0,
        sup_rntl_dly_cost:0,
        ford_rntl_dly_cost:0,
        return_rntl_cost:0,
        tooling_dev_cost:0,
        extr_cntr_dsgn_img_ind:'',
        intr_cntr_dsgn_img_ind:'',
        shpg_unit_img_ind:'',
        cad_img_ind:'',
        img_notes:'',
        cd_region:'',
        iscontainercolapsble:'',
        cd_plant:'',
        cntr_qt_per_min_su:0,
        nest_ratio_1:0,
        ford_cntct:'',
        cntct_phone:'',
        id_user:'',
        ts_last_update:'',
        req_stat:'',
        components:[{
            mp_function:'',
            no_comp_base:'',
            no_comp_suffix:'',
            ds_comp:'',
            comp_qt_per_min_su:0,
            comp_cd_owner:'',
            in_comp:'',
            comp_cd_type:''
        }],
        UoM:'E',
        typ_no:0,
        dbia_req_no:0
    }

    cntrdetails:containerDetailsCntrAdmin=
    {
        cd_cntr:0,
        cntr_suff_ind:'',
        exp_refundable:'',
        cntr_active_ind:'',
        cntr_base:'',
        cntr_suffix:'',
        cntr_desc:'',
        card_call:'',
        cntr_mstr_catg:'',
        cntr_typ_desc:'',
        ownrshp_strat_code:'',
        ownrshp_catg:'',
        cntr_grp_desc:'',
        cntr_exterior_l:0,
	      cntr_exterior_w:0,
	      cntr_exterior_h:0,
        collapsed_ht:0,
        nest_ratio:0,
        cntr_interior_l:0,
        cntr_interior_w:0,
        cntr_interior_h:0,
        cntr_tare_wt:0,
        cntr_capacity:0,
        cntr_per_layer:0,
        max_layers_shpg_unit:0,
        max_cntr_per_shpg_unit:0,
        cntr_matr_desc:'',
        cntr_drawing_no:'',
        shpg_unit_exterior_l:0,
        shpg_unit_exterior_w:0,
        shpg_unit_exterior_h:0,
        shpg_unit_nstdorflded_ht:0,
        shpg_unit_tare_wt:0,
        shpg_unit_stack_ht:0,
        warehouse_stack_ht:0,
        service_life:'',
        currency_desc:'',
        purchase_cost:0,
        rental_issue_cost:0,
        sup_rntl_dly_cost:0,
        ford_rntl_dly_cost:0,
        return_rntl_cost:0,
        tooling_dev_cost:0,
        extr_cntr_dsgn_img_ind:'',
        intr_cntr_dsgn_img_ind:'',
        shpg_unit_img_ind:'',
        cad_img_ind:'',
        img_notes:'',
        cd_region:'',
        iscontainercolapsble:'',
        cd_plant: '',
        cnt_qt_per_min_su: 0,
        nest_ratio_1: 0,
        ford_cntct: '',
        cntct_phone: '',
        req_stat: '',
        id_user: '',
        ts_last_update: '',
        uom:'E',
        typ_no:0,

    }

    collapsedHtInValid=false;
    collapsedHtInValidReason:string=null;
    nestRatioInValid=false;
    nestRatioInValidReason:string=null;
    nestRatio1InValid=false;
    nestRatio1InValidReason:string=null;
    cntrTareWtInValid=false;
    cntrTareWtInValidReason:string=null;
    cntrCapWtInValid=false;
    cntrCapWtInValidReason:string=null;
    cntrPerLayerInValid=false;
    cntrPerLayerInValidReason:string=null;
    maxLayerPerSuInValid=false;
    maxLayerPerSuInValidReason:string=null;
    cntrQtPerMinSuInValid=false;
    cntrQtPerMinSuInvalidReason:string=null;
    MaxCntrPerSuInValid=false;
    MaxCntrPerMinSuInvalidReason:string=null;
    cntrExtLInValid=false;
    cntrExtLInValidReason:string=null;
    cntrExtWInValid=false;
    cntrExtWInValidReason:string=null;
    cntrExtHInValid=false;
    cntrExtHInValidReason:string=null;
    cntrIntLInValid=false;
    cntrIntLInValidReason:string=null;
    cntrIntWInValid=false;
    cntrIntWInValidReason:string=null;
    cntrIntHInValid=false;
    cntrIntHInValidReason:string=null;
    suNestedHtInValid=false;
    suNestedHtInValidReason:string=null;
    suTareWtInvalid=false;
    suTareWtInValidReason:string=null;
    wareHourseStackHtInvaid=false;
    wareHourseStackHtInvaidReason:string=null;
    suStackHtInvalid=false;
    suStackHtInvalidReason:string=null;
    suExtLInValid=false;
    suExtLInValidReason:string=null;
    suExtWInValid=false;
    suExtWInValidReason:string=null;
    suExtHInValid=false;
    suExtHInValidReason:string=null;
    cntrBaseInvalid=true;
    cntrBaseInvalidReason:string=null;
    cntrDescInvalid=false;
    cntrDescInvalidReason:string=null;
    plantCodeInvalid=false;
    plantCodeInvalidReason:string=null;
    cardOrCallInvalid=false;
    cardOrCallInvalidReason:string=null;
    mastrCatgInvalid=false;
    mastrCatgInvalidReason:string=null;
    retTypeInvalid=false;
    retTypeInvalidReason:string=null;
    cntrTypeInvalid=false;
    cntrTypeInvalidReason:string=null;
    cntrMaterialInvalid=false;
    cntrMaterialInvalidReason:string=null;
    cosInvalid=false;
    cosInvalidReason:string=null;
    cntrGrpInvalid=false;
    cntrGrpInvalidReason:string=null;
    isCollapsibleInvalid=false;
    isCollapsibleInvalidReason:string=null;
    cntrCntctInvalid=false;
    cntrCntctInvalidReason:string=null;
    cntctPhoneInvalid=false;
    cntctPhoneInvlidReason:string=null;
    cntrActiveInvalid=false;
    cntrActiveInvalidReason=null;
    cntrOwnerShpCatgInvalid=false;
    cntrOwnerShpCatgInvalidReason=null;


    cntrCmmsFieldsBeforeUpdate:containerEditCmmsFields={
    cntr_base:'',
		cntr_suffix:'',
		cntr_desc:'',
		cntr_typ_desc:'',
		cd_plant:'',
		collapsed_ht:0,
		nest_ratio:0,
		nest_ratio_1:0,
		cntr_tare_wt:0,
		cntr_capacity:0,
		cntr_qt_per_min_su:0,
    ford_cntct:'',
    cntct_phone:'',
		cntr_exterior_l:0,
		cntr_exterior_w:0,
		cntr_exterior_h:0,
		shpg_unit_stack_ht:0,
		warehouse_stack_ht:0,
    ownrshp_catg:""
    }
    cntrCmmsFieldsAfterUpdate:containerEditCmmsFields={
      cntr_base:'',
		cntr_suffix:'',
		cntr_desc:'',
		cntr_typ_desc:'',
		cd_plant:'',
		collapsed_ht:0,
		nest_ratio:0,
		nest_ratio_1:0,
		cntr_tare_wt:0,
		cntr_capacity:0,
		cntr_qt_per_min_su:0,
    ford_cntct:'',
    cntct_phone:'',
		cntr_exterior_l:0,
		cntr_exterior_w:0,
		cntr_exterior_h:0,
		shpg_unit_stack_ht:0,
		warehouse_stack_ht:0,
    ownrshp_catg:""
    }


    edit_cntr_cd_cntr=0;


    saveEditContainerCntrAdmin:containerDetailsSaveCntrAdmin={


      cd_cntr:0,
      cntr_suff_ind:'',
      exp_refundable:'',
      cntr_active_ind:'',
      cntr_base:'',
      cntr_suffix:'',
      cntr_desc:'',
      card_call:'',
      cntr_mstr_catg:'',
      cntr_typ_desc:'',
      ownrshp_strat_code:'',
      ownrshp_catg:'',
      cntr_grp_desc:'',
      cntr_exterior_l:0,
      cntr_exterior_w:0,
      cntr_exterior_h:0,
      collapsed_ht:0,
      nest_ratio:0,
      cntr_interior_l:0,
      cntr_interior_w:0,
      cntr_interior_h:0,
      cntr_tare_wt:0,
      cntr_capacity:0,
      cntr_per_layer:0,
      max_layers_shpg_unit:0,
      max_cntr_per_shpg_unit:0,
      cntr_matr_desc:'',
      cntr_drawing_no:'',
      shpg_unit_exterior_l:0,
      shpg_unit_exterior_w:0,
      shpg_unit_exterior_h:0,
      shpg_unit_nstdorflded_ht:0,
      shpg_unit_tare_wt:0,
      shpg_unit_stack_ht:0,
      warehouse_stack_ht:0,
      service_life:'',
      currency_desc:'',
      purchase_cost:0,
      rental_issue_cost:0,
      sup_rntl_dly_cost:0,
      ford_rntl_dly_cost:0,
      return_rntl_cost:0,
      tooling_dev_cost:0,
      extr_cntr_dsgn_img_ind:'',
      intr_cntr_dsgn_img_ind:'',
      shpg_unit_img_ind:'',
      cad_img_ind:'',
      img_notes:'',
      cd_region:'',
      iscontainercolapsble:'',
      cd_plant: '',
      cnt_qt_per_min_su: 0,
      nest_ratio_1: 0,
      ford_cntct: '',
      cntct_phone: '',
      req_stat: '',
      id_user: '',
      ts_last_update: '',
      uom:'E',
      typ_no:0,

    }


/******************Component*******************/

componentGrid:GridContInr[];
public colscompGrid:any=[];


compAddEnable:boolean;
compAddDetailsEnable:boolean;

compenabledetails:boolean;
compenableeditgetdetails:boolean;

enableeditcompdetails:boolean;




// Added

    selectedPlantListComp:plantlist={cd_plant:'',plantGSDBCode:'',plant_active_stat:'',plant_name:'',plant_type_name:''};
    editSelectedPlantCodeComp:plantlist={cd_plant:'',plantGSDBCode:'',plant_active_stat:'',plant_name:'',plant_type_name:''};
    getSelectedPlantCodeComp:plantlist={cd_plant:'',plantGSDBCode:'',plant_active_stat:'',plant_name:'',plant_type_name:''};


    selected_cntr_master_category_comp:containerMasterCategory={cd_cntr_mstr_catg:0,cntr_mstr_catg_desc:'',updated_by:'',updated_dt:'',cntr_mstr_catg:'',active_stat:'',active_stat_display:false};
    edit_selected_cntr_master_category_comp:containerMasterCategory={cd_cntr_mstr_catg:0,cntr_mstr_catg_desc:'',updated_by:'',updated_dt:'',cntr_mstr_catg:'',active_stat:'',active_stat_display:false};
    get_selected_cntr_master_category_comp:containerMasterCategory={cd_cntr_mstr_catg:0,cntr_mstr_catg_desc:'',updated_by:'',updated_dt:'',cntr_mstr_catg:'',active_stat:'',active_stat_display:false};

    // Added
    cntrTypeCodeComp:containerTypeCode[]=[];

    selected_cntr_type_code_comp:containerTypeCode={cd_cntr_typ:'',cntr_typ_desc:'',typ_no:0};
    edit_selected_cntr_type_code_comp:containerTypeCode={cd_cntr_typ:'',cntr_typ_desc:'',typ_no:0};
    get_selected_cntr_type_code_comp:containerTypeCode={cd_cntr_typ:'',cntr_typ_desc:'',typ_no:0};


    selected_cntr_ownrship_strat_code_comp:containerOwnershipList={cd_region:'',ownrshp_strat_code:'',ownrshp_desc:'',cd_ownrshp_strat:0};
    edit_selected_cntr_ownrship_strat_code_comp:containerOwnershipList={cd_region:'',ownrshp_strat_code:'',ownrshp_desc:'',cd_ownrshp_strat:0};
    get_selected_cntr_ownrship_strat_code_comp:containerOwnershipList={cd_region:'',ownrshp_strat_code:'',ownrshp_desc:'',cd_ownrshp_strat:0};


    selected_cntr_group_comp:containerGroupActiveList={cntr_grp:''};
    edit_selected_cntr_group_comp:containerGroupActiveList={cntr_grp:''};
    get_selected_cntr_group_comp:containerGroupActiveList={cntr_grp:''};


    selected_cntr_material_comp:containerMaterialList={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
    edit_selected_cntr_material_comp:containerMaterialList={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
    get_selected_cntr_material_comp:containerMaterialList={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};


    selected_cntr_currency_list_comp:containerCurrencyList={cd_curr:0,curr_desc:''};
    edit_selected_cntr_currency_list_comp:containerCurrencyList={cd_curr:0,curr_desc:''};
    get_selected_cntr_currency_list_comp:containerCurrencyList={cd_curr:0,curr_desc:''};

    // ownrship_catg:containerOwnershipCategoryList[]=[{ownrshp_catg:'Ford',ownrshp_catg_val:'FORD'}, {ownrshp_catg:'Supplier',ownrshp_catg_val:'SUPP'},{ownrshp_catg:'Third Party',ownrshp_catg_val:'TPT'}];
    selected_ownrship_catg_comp:containerOwnershipCategoryList={ownrshp_catg:'',ownrshp_catg_val:''};
    edit_selected_ownrship_catg_comp:containerOwnershipCategoryList={ownrshp_catg:'',ownrshp_catg_val:''};
    get_selected_ownrship_catg_comp:containerOwnershipCategoryList={ownrshp_catg:'',ownrshp_catg_val:''};







    // Added
    enable_material_dropdown_comp=false;

    // containerOrComponent:string;
    // containerOrComponentInDetails:string;
    // containerOrComponentInEdit:string;
    // plantcode:string="";

    // containerquantityminshipunit:number=0;
    // cntrcntct:string="";
    // cntrcntctphonenumber:string="";
    // nestratio2:number;




compTareWtInValid=false;
compTareWtInValidReason:string=null;
compCapWtInValid=false;
compCapWtInValidReason:string=null;
compQtPerMinSuInValid=false;
compQtPerMinSuInvalidReason:string=null;
compExtLInValid=false;
compExtLInValidReason:string=null;
compExtWInValid=false;
compExtWInValidReason:string=null;
compExtHInValid=false;
compExtHInValidReason:string=null;
wareHourseStackHtCompInvaid=false;
wareHourseStackHtCompInvaidReason:string=null;
compBaseInvalid=false;
compBaseInvalidReason:string=null;
compDescInvalid=false;
compDescInvalidReason:string=null;
plantCodeCompInvalid=false;
plantCodeCompInvalidReason:string=null;
mastrCatgCompInvalid=false;
mastrCatgCompInvalidReason:string=null;
retTypeCompInvalid=false;
retTypeCompInvalidReason:string=null;
compTypeInvalid=false;
compTypeInvalidReason:string=null;
compMaterialInvalid=false;
compMaterialInvalidReason:string=null;
cosCompInvalid=false;
cosCompInvalidReason:string=null;
compGrpInvalid=false;
compGrpInvalidReason:string=null;
compCntctInvalid=false;
compCntctInvalidReason:string=null;
cntctPhoneCompInvalid=false;
cntctPhoneCompInvlidReason:string=null;
compActiveInvalid=false;
compActiveInvalidReason:string=null;
compOwnerShpCatgInvalid=false;
compOwnerShpCatgInvalidReason=null;


compSuffixInvalid=false;
compSuffixInvalidReason:string=null

compSuffixIndInvalid=false;
compSuffixIndInvalidReason:string=null



    compdetailsadd:addContainer=
    {
        // cd_cntr:0,
        function:'A',
        cntr_suff_ind:'',
        exp_refundable:'',
        cntr_active_ind:"Yes",
        cntr_base:'',
        cntr_suffix:'',
        cntr_desc:'',
        card_call:'',
        cntr_mstr_catg:'',
        cntr_typ_desc:'',
        ownrshp_strat_code:'',
        ownrshp_catg:'',
        cntr_grp_desc:'',
        cntr_exterior_l:0,
	      cntr_exterior_w:0,
	      cntr_exterior_h:0,
        collapsed_ht:0.0,
        nest_ratio:0,
        cntr_interior_l:0,
        cntr_interior_w:0,
        cntr_interior_h:0,
        cntr_tare_wt:0,
        cntr_capacity:0,
        cntr_per_layer:0,
        max_layers_shpg_unit:0,
        max_cntr_per_shpg_unit:0,
        cntr_matr_desc:'',
        cntr_drawing_no:'',
        shpg_unit_exterior_l:0,
        shpg_unit_exterior_w:0,
        shpg_unit_exterior_h:0,
        shpg_unit_nstdorflded_ht:0,
        shpg_unit_tare_wt:0,
        shpg_unit_stack_ht:0,
        warehouse_stack_ht:0,
        service_life:'',
        currency_desc:'',
        purchase_cost:0,
        rental_issue_cost:0,
        sup_rntl_dly_cost:0,
        ford_rntl_dly_cost:0,
        return_rntl_cost:0,
        tooling_dev_cost:0,
        extr_cntr_dsgn_img_ind:'No',
        intr_cntr_dsgn_img_ind:'No',
        shpg_unit_img_ind:'No',
        cad_img_ind:'No',
        img_notes:'',
        cd_region:'',
        iscontainercolapsble:'',
        cd_plant:'',
        cntr_qt_per_min_su:0,
        nest_ratio_1:0,
        ford_cntct:'',
        cntct_phone:'',
        id_user:'',
        ts_last_update:'',
        req_stat:'',
        components:[{
            mp_function:'',
            no_comp_base:'',
            no_comp_suffix:'',
            ds_comp:'',
            comp_qt_per_min_su:0,
            comp_cd_owner:'',
            in_comp:'',
            comp_cd_type:''
        }],
        UoM:'E',
        typ_no:0,
        dbia_req_no:0
    }

    compdetailsedit:addContainer=
    {
        function:'C',
        cntr_suff_ind:'',
        exp_refundable:'',
        cntr_active_ind:'',
        cntr_base:'',
        cntr_suffix:'',
        cntr_desc:'',
        card_call:'',
        cntr_mstr_catg:'',
        cntr_typ_desc:'',
        ownrshp_strat_code:'',
        ownrshp_catg:'',
        cntr_grp_desc:'',
        cntr_exterior_l:0,
	      cntr_exterior_w:0,
	      cntr_exterior_h:0,
        collapsed_ht:0,
        nest_ratio:0,
        cntr_interior_l:0,
        cntr_interior_w:0,
        cntr_interior_h:0,
        cntr_tare_wt:0,
        cntr_capacity:0,
        cntr_per_layer:0,
        max_layers_shpg_unit:0,
        max_cntr_per_shpg_unit:0,
        cntr_matr_desc:'',
        cntr_drawing_no:'',
        shpg_unit_exterior_l:0,
        shpg_unit_exterior_w:0,
        shpg_unit_exterior_h:0,
        shpg_unit_nstdorflded_ht:0,
        shpg_unit_tare_wt:0,
        shpg_unit_stack_ht:0,
        warehouse_stack_ht:0,
        service_life:'',
        currency_desc:'',
        purchase_cost:0,
        rental_issue_cost:0,
        sup_rntl_dly_cost:0,
        ford_rntl_dly_cost:0,
        return_rntl_cost:0,
        tooling_dev_cost:0,
        extr_cntr_dsgn_img_ind:'',
        intr_cntr_dsgn_img_ind:'',
        shpg_unit_img_ind:'',
        cad_img_ind:'',
        img_notes:'',
        cd_region:'',
        iscontainercolapsble:'',
        cd_plant:'',
        cntr_qt_per_min_su:0,
        nest_ratio_1:0,
        ford_cntct:'',
        cntct_phone:'',
        id_user:'',
        ts_last_update:'',
        req_stat:'',
        components:[{
            mp_function:'',
            no_comp_base:'',
            no_comp_suffix:'',
            ds_comp:'',
            comp_qt_per_min_su:0,
            comp_cd_owner:'',
            in_comp:'',
            comp_cd_type:''
        }],
        UoM:'E',
        typ_no:0,
        dbia_req_no:0
    }

    compdetails:containerDetailsCntrAdmin=
    {
        cd_cntr:0,
        cntr_suff_ind:'',
        exp_refundable:'',
        cntr_active_ind:"Yes",
        cntr_base:'',
        cntr_suffix:'',
        cntr_desc:'',
        card_call:'',
        cntr_mstr_catg:'',
        cntr_typ_desc:'',
        ownrshp_strat_code:'',
        ownrshp_catg:'',
        cntr_grp_desc:'',
        cntr_exterior_l:0,
	      cntr_exterior_w:0,
	      cntr_exterior_h:0,
        collapsed_ht:0,
        nest_ratio:0,
        cntr_interior_l:0,
        cntr_interior_w:0,
        cntr_interior_h:0,
        cntr_tare_wt:0,
        cntr_capacity:0,
        cntr_per_layer:0,
        max_layers_shpg_unit:0,
        max_cntr_per_shpg_unit:0,
        cntr_matr_desc:'',
        cntr_drawing_no:'',
        shpg_unit_exterior_l:0,
        shpg_unit_exterior_w:0,
        shpg_unit_exterior_h:0,
        shpg_unit_nstdorflded_ht:0,
        shpg_unit_tare_wt:0,
        shpg_unit_stack_ht:0,
        warehouse_stack_ht:0,
        service_life:'',
        currency_desc:'',
        purchase_cost:0,
        rental_issue_cost:0,
        sup_rntl_dly_cost:0,
        ford_rntl_dly_cost:0,
        return_rntl_cost:0,
        tooling_dev_cost:0,
        extr_cntr_dsgn_img_ind:'',
        intr_cntr_dsgn_img_ind:'',
        shpg_unit_img_ind:'',
        cad_img_ind:'',
        img_notes:'',
        cd_region:'',
        iscontainercolapsble:'',
        cd_plant: "",
        cnt_qt_per_min_su: 0,
        nest_ratio_1: 0,
        ford_cntct: "",
        cntct_phone: "",
        req_stat: "",
        id_user: "",
        ts_last_update: "",
        uom:'E',
        typ_no:0

    }



    
    compCmmsFieldsBeforeUpdate:componentEditCmmsFields={   
      cntr_base:"",
      cntr_suffix:"",
      cntr_desc:"",
      cntr_typ_desc:"",
      cd_plant:"",
      cntr_tare_wt:0,
      cntr_capacity:0,
      cntr_qt_per_min_su:0,
      ford_cntct:"",
      cntct_phone:"",
      cntr_exterior_l:0,
      cntr_exterior_w:0,
      cntr_exterior_h:0,
      warehouse_stack_ht:0,
      ownrshp_catg:""
      }
      compCmmsFieldsAfterUpdate:componentEditCmmsFields={    
        cntr_base:"",
      cntr_suffix:"",
      cntr_desc:"",
      cntr_typ_desc:"",
      cd_plant:"",
      cntr_tare_wt:0,
      cntr_capacity:0,
      cntr_qt_per_min_su:0,
      ford_cntct:"",
      cntct_phone:"",
      cntr_exterior_l:0,
      cntr_exterior_w:0,
      cntr_exterior_h:0,
      warehouse_stack_ht:0,
      ownrshp_catg:""
      }
  
  
      edit_comp_cd_cntr:number=0;
  
  
      saveEditComponentCntrAdmin:containerDetailsSaveCntrAdmin={
  
  
        cd_cntr:0,
        cntr_suff_ind:'',
        exp_refundable:'',
        cntr_active_ind:'',
        cntr_base:'',
        cntr_suffix:'',
        cntr_desc:'',
        card_call:'',
        cntr_mstr_catg:'',
        cntr_typ_desc:'',
        ownrshp_strat_code:'',
        ownrshp_catg:'',
        cntr_grp_desc:'',
        cntr_exterior_l:0,
	      cntr_exterior_w:0,
	      cntr_exterior_h:0,
        collapsed_ht:0,
        nest_ratio:0,
        cntr_interior_l:0,
        cntr_interior_w:0,
        cntr_interior_h:0,
        cntr_tare_wt:0,
        cntr_capacity:0,
        cntr_per_layer:0,
        max_layers_shpg_unit:0,
        max_cntr_per_shpg_unit:0,
        cntr_matr_desc:'',
        cntr_drawing_no:'',
        shpg_unit_exterior_l:0,
        shpg_unit_exterior_w:0,
        shpg_unit_exterior_h:0,
        shpg_unit_nstdorflded_ht:0,
        shpg_unit_tare_wt:0,
        shpg_unit_stack_ht:0,
        warehouse_stack_ht:0,
        service_life:'',
        currency_desc:'',
        purchase_cost:0,
        rental_issue_cost:0,
        sup_rntl_dly_cost:0,
        ford_rntl_dly_cost:0,
        return_rntl_cost:0,
        tooling_dev_cost:0,
        extr_cntr_dsgn_img_ind:'',
        intr_cntr_dsgn_img_ind:'',
        shpg_unit_img_ind:'',
        cad_img_ind:'',
        img_notes:'',
        cd_region:'',
        iscontainercolapsble:'',
        cd_plant: '',
        cnt_qt_per_min_su: 0,
        nest_ratio_1: 0,
        ford_cntct: '',
        cntct_phone: '',
        req_stat: '',
        id_user: '',
        ts_last_update: '',
        uom:'E',
        typ_no:0
  
      }


    current_routeid=0
;


exportColumns:any[]


suffixList:suppdesgnList[]=[];
selectedSuffixList:suppdesgnList={suppliergsdbcode:''};
selectedSuffix='';


compdetailsfromload:cntrDetailsFromCMMS={
  no_cntr_base:'',
    no_cntr_suffix:'',
    ms_cntr_lt:0,
    ms_cntr_ht:0,
    ms_cntr_wd:0,
    ms_cntr_clpsd_ht:0,
    ms_cntr_tare_wt:0,
    ms_max_cap_wt:0,
    qt_cntr_stack_ht:0,
    qt_whse_stack_ht:0,
    qt_per_min_su:0,
    cd_cntr_type:'',
    cd_owner:'',
    na_ford_cntct:'',
    no_cntct_phone:'',
    cs_cntr_cost:'',
    ds_cntr_note:'',
    ds_cntr:'',
    id_user:'',
    ts_last_update:'',
    no_nesting_ratio1:0,
    no_nesting_ratio2:0,
    no_cntct_phone_1:''
}

enteredCompBase:string=null;
prevCompSuffixInd:string=null;
prevCompSuffixVal:string=null;

compSuffixAdd:string=null;
compSuffixDisabled=true;

disableCompDetailsWhenBaseOrSuffixChanged=true;

enableFunctionChangeNotifiacationDialogComp=false;
enableFunctionAddNotifiacationDialogComp=false;
enableProcessingNotifiacationDialogAddComp=false;

selectedSuffixListComp:suppdesgnList={suppliergsdbcode:''};

selected_comp_ownrship_strat_code_in_popup:containerOwnershipList={cd_region:'',ownrshp_strat_code:'',ownrshp_desc:'',cd_ownrshp_strat:0};


  comp_retype='Returnable'; // default value for copy container pop up
  comp_grid_text='';
  comp_present_table=false;
  comp_cols:any;
  enabledetails_comp=false;
  compdetails_display:containerDetailsCntrAdmin;
  componentDetailsCopied=false;
  compDetailsForCopying={
    cd_cntr:0,
    cntr_suff_ind:'',
    exp_refundable:'',
    cntr_active_ind:'',
    cntr_base:'',
    cntr_suffix:'',
    cntr_desc:'',
    card_call:'',
    cntr_mstr_catg:'',
    cntr_typ_desc:'',
    ownrshp_strat_code:'',
    ownrshp_catg:'',
    cntr_grp_desc:'',
    cntr_exterior_l:0,
	  cntr_exterior_w:0,
	  cntr_exterior_h:0,
    collapsed_ht:0.0,
    nest_ratio:0,
    cntr_interior_l:0,
    cntr_interior_w:0,
    cntr_interior_h:0,
    cntr_tare_wt:0,
    cntr_capacity:0,
    cntr_per_layer:0,
    max_layers_shpg_unit:0,
    max_cntr_per_shpg_unit:0,
    cntr_matr_desc:'',
    cntr_drawing_no:'',
    shpg_unit_exterior_l:0,
    shpg_unit_exterior_w:0,
    shpg_unit_exterior_h:0,
    shpg_unit_nstdorflded_ht:0,
    shpg_unit_tare_wt:0,
    shpg_unit_stack_ht:0,
    warehouse_stack_ht:0,
    service_life:'',
    currency_desc:'',
    purchase_cost:0,
    rental_issue_cost:0,
    sup_rntl_dly_cost:0,
    ford_rntl_dly_cost:0,
    return_rntl_cost:0,
    tooling_dev_cost:0,
    extr_cntr_dsgn_img_ind:'',
    intr_cntr_dsgn_img_ind:'',
    shpg_unit_img_ind:'',
    cad_img_ind:'',
    img_notes:'',
    cd_region:'',
    iscontainercolapsble:'',
    cd_plant: '',
    cnt_qt_per_min_su: 0,
    nest_ratio_1: 0,
    ford_cntct: '',
    cntct_phone: '',
    req_stat: '',
    id_user: '',
    ts_last_update: '',
    uom:'E',
    typ_no:0
  }


  select_contcos_dialog_comp=false;
  contlist_oncos_comp:contoncoslist[]=[];

  selected_component:contoncoslist=null;
  table_selected_component:contoncoslist=null;

  tableSelectedReturnTypeComp='';
  table_selected_comp_ownrship_strat_code_in_popup:containerOwnershipList={cd_region:'',ownrshp_strat_code:'',ownrshp_desc:'',cd_ownrshp_strat:0};




constructor(private transactionService: TransactionService,private messageService:MessageService,private date:DatePipe, private router:Router) { }

  ngOnInit(): void
   {


    // this.containerOrComponent="container";
    // this.containerOrComponentInDetails="component";
    this.containerOrComponentInDetails='container';

    this.cntrdetails_display_popup_init();
    this.disableCntrDetailsWhenBaseOrSuffixChanged=true;
    this.cont_retype='Returnable';
    this.cont_grid_text='';
    this.cont_present_table=false;
    this.enabledetails_cntr=false;
    this.containerDetailsCopied=false;
    this.cntrSuffixEnabled=false;

    this.comp_retype='Returnable';
    this.comp_grid_text='';
    this.comp_present_table=false;
    this.enabledetails_comp=false;
    this.componentDetailsCopied=false;
    this.compSuffixDisabled=true;

    this.current_region_prg=this.transactionService.getregion();
    console.log('get----region---- ', this.current_region_prg)
    this.transactionService.storepage(this.current_region_prg,'packagingprocess/packadmin/app-contadmin');
    this.current_region_code_prg=this.transactionService.getregioncode(this.current_region_prg);
    console.log('get region code--- ', this.current_region_code_prg);
    // this.DisplayGridComp( this.current_region_code_prg);

    this.validationInit();
    this.validationCompInit();
    this.current_routeid=104;

    console.log(this.current_routeid)


    if(this.current_region_prg=='Asia Pacific & Africa')
    {
      sessionStorage.setItem('aprouteid', JSON.stringify(this.current_routeid));
    }

    else if(this.current_region_prg=='Europe')
    {
      sessionStorage.setItem('eurouteid', JSON.stringify(this.current_routeid));
    }

    else if (this.current_region_prg=='North America')

    {

      sessionStorage.setItem('narouteid', JSON.stringify(this.current_routeid));
    }

    else if(this.current_region_prg=='South America')
    {
      sessionStorage.setItem('sarouteid', JSON.stringify(this.current_routeid));
    }

    this.transactionService.getPlantListonRegion(this.current_region_code_prg).subscribe(plantcodes=>{
      console.log('plantcodes',plantcodes);
      this.plantList=plantcodes.filter(e=>e.plant_active_stat.toLowerCase()=='yes');
      if(this.plantList.length!=0)
      {
        this.selectedPlantListComp=this.plantList[0];
        this.selectedPlantList=this.plantList[0];
      }
    })

    this.transactionService.getSupplierListReqAccess().subscribe(supout=>{
      console.log(supout);

      this.suffixList=[];
      // this.suffixList.push({suppliergsdbcode:"Select"});
      // this.cntrdetailsadd.cntr_suffix=this.selectedSuffixList.suppliergsdbcode;
      // this.cntrdetails.cntr_suffix=this.selectedSuffixList.suppliergsdbcode;
      // this.cntrdetailsedit.cntr_suffix=this.selectedSuffixList.suppliergsdbcode;


      if(supout != null && supout.length!=0)
      {
        this.suffixList.push(...supout)
      }

      /*}
      else
      {
        this.selectedSuffixList=this.suffixList[0];

      }*/
      // var Ind = this.suffixList.findIndex(suflist => suflist.suppliergsdbcode.toLowerCase() == this.cntrdetails.cntr_suffix.toLowerCase())
      // if(Ind!=-1){
      //   this.selectedSuffix = this.selectedSuffixList[Ind];
      //   this.cntrdetails.cntr_suffix=this.selectedSuffixList.suppliergsdbcode;
      // }
      // this.suffixList=supout
    })


    this.transactionService.getMasterCategory().subscribe(mastcatg=>{
      console.log('mastcatg',mastcatg);
      this.cntrMasterCategory=mastcatg;
      this.selected_cntr_master_category_comp=this.cntrMasterCategory[0];
      this.selected_cntr_master_category=this.cntrMasterCategory[2];
      

    });

  this.transactionService.getContainerTypeCode().subscribe(cntrtypecode=>{
      this.cntrTypeCodeComp=cntrtypecode;
      this.unmodifiedTypeCodes=cntrtypecode;
      // .filter(e=>["02","03","04","05"].includes(String(e.cd_cntr_typ)));
      this.selected_cntr_type_code_comp=this.cntrTypeCodeComp[0];
      this.containerChange();
      this.componentChange();


      this.changeInContainerOrComponentOption()
  });

  this.transactionService.getOwnershipStrategyCode(this.current_region_code_prg).subscribe(cntrownrship=>{
      this.cntrOwnershipstratcode=cntrownrship;
      this.selected_cntr_ownrship_strat_code_comp=this.cntrOwnershipstratcode[0];
      this.selected_cntr_ownrship_strat_code=this.cntrOwnershipstratcode[0];

      this.cntrOwnershipstratcodelist=JSON.parse(JSON.stringify(cntrownrship));
      this.selected_cntr_ownrship_strat_code_in_popup=this.cntrOwnershipstratcodelist[0];
  });

 /* this.transactionService.getContainerGroup(this.current_region_code_prg).subscribe(cntrgrp=>{
      this.cntrGrp=cntrgrp;
      this.cntr_grpidList = cntrgrp.filter(containergrp => containergrp.active_stat.toLowerCase() == "yes");
      this.cntr_grpidList.push({cd_cntr_grp: "", cntr_grp:"[Select]", cntr_grp_desc:"", cd_region:"",active_stat:"",updated_by:"",updated_dt:"",active:false})
      this.select_cntr_grp_id = {cd_cntr_grp: "", cntr_grp:"[Select]", cntr_grp_desc:"", cd_region:"",active_stat:"",updated_by:"",updated_dt:"",active:false};
      console.log('Container-Group',this.cntrGrp)
      this.selected_cntr_group_comp=this.cntrGrp[0];
      this.selected_cntr_group=this.cntrGrp[0];
  });*/

// get active container group list
  this.transactionService.getActiveContainerGroup(this.current_region_code_prg).subscribe(cntrgrp=>{
    this.cntrGrp=cntrgrp;
    this.selected_cntr_group_comp=this.cntrGrp[0];
    for(var i=0;i<this.cntrGrp.length;i++)
    {
      if(this.cntrGrp[i].cntr_grp.toLowerCase() == "standard" || this.cntrGrp[i].cntr_grp.toLowerCase() == "std")
      {
        console.log("Cntr_Grp", i, this.cntrGrp[i]);
        this.selected_cntr_group = this.cntrGrp[i];
        this.cntrdetailsadd.cntr_grp_desc =  this.cntrGrp[i].cntr_grp;
      }
    }
});

  // this.transactionService.getAllMaterialList().subscribe(cntrmatr=>{
  //     if(cntrmatr!=null && cntrmatr.length!=0)
  //     {
  //     this.cntrMaterial=cntrmatr.filter(ele=>ele.active_stat.toLowerCase()=='y');
  //     this.selected_cntr_material=this.cntrMaterial[0];
  //     }
  // });

  this.transactionService.getCurrencyList().subscribe(cntrcurrency=>{
      console.log('Currency List',cntrcurrency);
      this.cntrCurrencyList=cntrcurrency;
      this.selected_cntr_currency_list_comp=this.cntrCurrencyList[0];
      this.selected_cntr_currency_list_comp=this.cntrCurrencyList[0];
  });

  // this.selected_ownrship_catg_comp=this.ownrship_catg[0];
  // this.selected_ownrship_catg=this.ownrship_catg[0];

  this.transactionService.get_region_details(this.current_region_code_prg).subscribe(uomtype=>{

    this.unitom=uomtype.uom;
  
    if(this.unitom=="E")
    {
      this.prev_get_measure="lbs/inch";
      this.get_measure="lbs/inch";
      console.log("UOMlb",this.unitom,this.prev_get_measure,this.get_measure);
    }
    else
    {
      this.prev_get_measure="kg/cm";
      this.get_measure="kg/cm";
      console.log("UOMlb",this.unitom,this.prev_get_measure,this.get_measure);
    }
  
    console.log("UOMREG",this.prev_get_measure,this.get_measure);
  });


  }

  containerChange()
  {

     
      this.cntrTypeCode=this.unmodifiedTypeCodes.filter(e=>["01"].includes(String(e.cd_cntr_typ)));
      this.selected_cntr_type_code=this.cntrTypeCode[0];
      this.selected_cntr_master_category = this.cntrMasterCategory[2];

      for(var i=0;i<this.cntrGrp.length;i++)
      {
        if(this.cntrGrp[i].cntr_grp.toLowerCase() == "standard" || this.cntrGrp[i].cntr_grp.toLowerCase() == "std")
        {
          console.log("Cntr_Grp", i, this.cntrGrp[i]);
          this.selected_cntr_group = this.cntrGrp[i];
          this.cntrdetailsadd.cntr_grp_desc =  this.cntrGrp[i].cntr_grp;
        }
      }

      this.cntrdetailsadd.cntr_active_ind = "Yes";
      
      if(this.cntrAddDetailsEnable)
      {
        this.cntrdetailsadd.exp_refundable=''
      }
      if(this.enableeditcntrdetails)
      {
        this.cntrdetailsedit.exp_refundable='';
      }
      if(this.enableeditgetdetails)
      {
        this.cntrdetailsedit.exp_refundable='';
      }

  }

  componentChange()
  {


      this.cntrTypeCodeComp=this.unmodifiedTypeCodes.filter(e=>['02','03'].includes(String(e.cd_cntr_typ)));
      this.selected_cntr_type_code_comp=this.cntrTypeCodeComp[0];
      if(this.compAddDetailsEnable)
      {
        this.compdetailsadd.exp_refundable='Returnable'
      }
      if(this.enableeditcompdetails)
      {
        this.compdetailsedit.exp_refundable='Returnable';
      }
      if(this.compenableeditgetdetails)
      {
        this.compdetailsedit.exp_refundable='Returnable';
      }

      this.compdetailsadd.cntr_active_ind = "Yes";
  }

  changeInContainerOrComponentOption()
  {
    if(this.containerOrComponentInDetails=='container')
    {
      this.cntrAddEnable=true;
      this.cntrAddDetailsEnable=false;
      this.enabledetails=false;
      this.enableeditcntrdetails=false;
      this.enableeditgetdetails=false;
      this.editing=false;

      if(this.unitom=='E')
      {
      this.add_measure='lbs/inch';
      this.get_measure='lbs/inch';
      this.edit_measure='lbs/inch';

      }
      else{
        this.add_measure='kg/cm';
      this.get_measure='kg/cm';
      this.edit_measure='kg/cm';
      }
        console.log('container')
     this.DisplayGrid(this.current_region_code_prg);
     this.containerChange();
    }
    else if(this.containerOrComponentInDetails=='component')
    {
      this.compAddEnable=true;
      this.compAddDetailsEnable=false;
      this.compenabledetails=false;
      this.enableeditcompdetails=false;
      this.compenableeditgetdetails=false;
      if(this.unitom=='E')
      {
      this.add_measure='lbs/inch';
      this.get_measure='lbs/inch';
      this.edit_measure='lbs/inch';
      }
      else{
        this.add_measure='kg/cm';
      this.get_measure='kg/cm';
      this.edit_measure='kg/cm';
      }

      this.DisplayGridComp(this.current_region_code_prg);
      this.componentChange();
    }
  }



  filterCntrBase(event)
  {
    this.selectedbase=event.query;

    if(this.selectedbase.trim().length>0)
    {
      this.transactionService.getcntrbaseforadd(this.selectedbase.toUpperCase()).subscribe(data => {
        /*this.cntrBaseAndSuffixList = data;
        if(this.cntrBaseAndSuffixList.length===0)
        {
          this.messageService.add({severity:'error', summary:'Error', detail: 'No Containers found'});
          this.loadcntrdetails();
        }*/

        this.cntrBaseAndSuffixList = data
        if(this.cntrBaseAndSuffixList.length===0)
        {
          this.messageService.add({severity:'error', summary:'Error', detail: 'No Containers found'});
          this.loadcntrdetails();
        }
        else
        {
          const tempObject:cntrautocomplete[]=[];
          for( const cntrIter of this.cntrBaseAndSuffixList)
          {
            let baseandsuffixappended='';

            if(cntrIter.no_cntr_suffix.trim()!='' && cntrIter.no_cntr_suffix!=null)
            {
              baseandsuffixappended=cntrIter.no_cntr_base+'-'+cntrIter.no_cntr_suffix;
              tempObject.push({no_cntr_base:cntrIter.no_cntr_base,no_cntr_suffix:cntrIter.no_cntr_suffix,cntr_base_and_suffix:baseandsuffixappended});
            }
            else
            {
              baseandsuffixappended=cntrIter.no_cntr_base;
              tempObject.push({no_cntr_base:cntrIter.no_cntr_base,no_cntr_suffix:cntrIter.no_cntr_suffix,cntr_base_and_suffix:baseandsuffixappended});
            }
          }
          this.cntrBaseAndSuffixListForAutoComplete=tempObject;
          console.log(this.cntrBaseAndSuffixListForAutoComplete);
        }
      });
    }
  }

  loadcntrdetails()
  {
    console.log('in load cntr details'+this.selectedCntrFromAutoCmplete);

    // checking whether suffix is undefined, to check if the user had manually cleared the input in cntrbase field
    // Checking selectedCntrFromAutoCmplete is null to know whether user have clicked cancel

    /* if(
        this.selectedCntrFromAutoCmplete!=null) && this.selectedCntrFromAutoCmplete.no_cntr_suffix!=undefined &&
        (this.selectedCntrFromAutoCmplete.no_cntr_suffix!=""&& this.selectedCntrFromAutoCmplete.no_cntr_suffix!=="")
      )
    */

    if(this.selectedCntrFromAutoCmplete!=null)
    {

      this.cntrbaseselected=true;

      // Once the container is selected, Container base should be shown in cntr base field
      // So making cntr_base_and_suffix in selectedCntrFromAutoCmplete to have cntrbase only

      this.selectedCntrFromAutoCmplete={no_cntr_base:this.selectedCntrFromAutoCmplete.no_cntr_base,no_cntr_suffix:this.selectedCntrFromAutoCmplete.no_cntr_suffix,cntr_base_and_suffix:this.selectedCntrFromAutoCmplete.no_cntr_base.trim()}

      /* load other details for the container from api based on the base*/

      this.cntrdetailsadd.cntr_base=this.selectedCntrFromAutoCmplete.no_cntr_base;
      this.cntrdetailsadd.cntr_suffix=this.selectedCntrFromAutoCmplete.no_cntr_suffix.trim();


      if(this.selectedCntrFromAutoCmplete.no_cntr_suffix.trim()=='')
      {
      console.log('container suffix was empty');
      this.selectedCntrFromAutoCmplete.no_cntr_suffix='%20';
      }

      console.log('cntrdetailsadd suffix',this.cntrdetailsadd.cntr_suffix);

      this.transactionService.getContainerDetailsFromCMMS(this.selectedCntrFromAutoCmplete.no_cntr_base,this.selectedCntrFromAutoCmplete.no_cntr_suffix).subscribe(data=>
          {
            this.cntrdetailsfromCMMSforadd=data[0];
            console.log(this.cntrdetailsfromCMMSforadd);
            if(this.cntrdetailsfromCMMSforadd.no_cntr_base===this.cntrdetailsadd.cntr_base)
            {
            console.log('cntr base and suffix matched');
            console.log(this.cntrdetailsfromCMMSforadd);
            this.cntrdetailsadd.collapsed_ht=this.cntrdetailsfromCMMSforadd.ms_cntr_clpsd_ht;
            this.cntrdetailsadd.cntr_tare_wt=this.cntrdetailsfromCMMSforadd.ms_cntr_tare_wt;
            this.cntrdetailsadd.warehouse_stack_ht=this.cntrdetailsfromCMMSforadd.qt_whse_stack_ht;
            this.cntrdetailsadd.img_notes=this.cntrdetailsfromCMMSforadd.ds_cntr_note;
            this.cntrdetailsadd.cntr_desc=this.cntrdetailsfromCMMSforadd.ds_cntr;
            this.cntrdetailsadd.nest_ratio=this.cntrdetailsfromCMMSforadd.no_nesting_ratio1;
            this.cntrdetailsadd.nest_ratio_1=this.cntrdetailsfromCMMSforadd.no_nesting_ratio2;
            this.cntrdetailsadd.cntr_exterior_l=this.cntrdetailsfromCMMSforadd.ms_cntr_lt;
            this.cntrdetailsadd.cntr_exterior_w=this.cntrdetailsfromCMMSforadd.ms_cntr_wd;
            this.cntrdetailsadd.cntr_exterior_h=this.cntrdetailsfromCMMSforadd.ms_cntr_ht;

            const cntrtypefrmcmms=this.cntrdetailsfromCMMSforadd.cd_cntr_type;
            const indexofcntrtyp=this.cntrTypeCode.findIndex(e=>e.cd_cntr_typ==cntrtypefrmcmms)

            if(indexofcntrtyp!=-1)
            {
              this.selected_cntr_type_code=this.cntrTypeCode[indexofcntrtyp];
            }
          }
        });

        /* after loading cntr details*/

        console.log(this.cntrbaseselected);
        console.log(this.selectedCntrFromAutoCmplete.no_cntr_base);
        console.log(this.selectedCntrFromAutoCmplete.no_cntr_suffix);

    }
    else
    {
      this.cntrbaseselected=false;
      this.addcntrdetailsinit();
      console.log(this.cntrbaseselected);
    }
  }

DisplayGrid(setRegion:string)
{
this.transactionService.getgridcontainer(setRegion).subscribe(cnotgrid => {
 this.unModifiedGrid=cnotgrid;
 this.gridWithBaseAndSuffixAppended();

});


  // { field: 'cntr_base', header:'Container Number'},,

  this.cols=[

    {field:'cntr_base_suffix',header:'Container Number'},
    { field: 'cntr_desc', header:'Description'},
    { field: 'ownrshp_strat_code', header:'Ownership Strategy'},
    { field: 'exp_refundable', header:'E / R'},
    { field: 'cntr_active_ind', header:'Active'}
];


this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
}

gridWithBaseAndSuffixAppended()
{
  if(this.containerOrComponentInDetails.toLowerCase()=='container')
  {

   this.containerGrid=this.unModifiedGrid.filter(e=>String(e.cntr_typ_desc)=='01');
   console.log('Container Grid values ',this.containerGrid);

   for(let i=0;i<this.containerGrid.length;i++)
   {
     this.containerGrid[i].cntr_base_suffix=this.containerGrid[i].cntr_base;

     if(this.containerGrid[i].cntr_suffix!=null && this.containerGrid[i].cntr_suffix.trim()!='')
     {
       this.containerGrid[i].cntr_base_suffix+='-'+this.containerGrid[i].cntr_suffix;
     }
   }
  }
  else
  {
    // for component
  }
}


exportExcel() {
  import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.containerGrid);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'Container Table');
  });
}

saveAsExcelFile(buffer: any, fileName: string): void {
  import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
          type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  });
}





editcntrdetailsinit()
{
  this.enable_material_dropdown=false;
  this.cntrSuffixEditenabled=false;
  this.componentsAssociatedToContainerForPost=[];
    this.cntrdetailsedit=
    {
        // cd_cntr:0,
        function:'C',
        cntr_suff_ind:'',
        exp_refundable:'',
        cntr_active_ind:'',
        cntr_base:'',
        cntr_suffix:'',
        cntr_desc:'',
        card_call:"Call",
        cntr_mstr_catg:'',
        cntr_typ_desc:'',
        ownrshp_strat_code:'',
        ownrshp_catg:'',
        cntr_grp_desc:'',
        cntr_exterior_l:1,
	      cntr_exterior_w:1,
	      cntr_exterior_h:1,
        collapsed_ht:0.1,
        nest_ratio:1,
        cntr_interior_l:1,
        cntr_interior_w:1,
        cntr_interior_h:1,
        cntr_tare_wt:1,
        cntr_capacity:1,
        cntr_per_layer:1,
        max_layers_shpg_unit:1,
        max_cntr_per_shpg_unit:1,
        cntr_matr_desc:'',
        cntr_drawing_no:'',
        shpg_unit_exterior_l:1,
        shpg_unit_exterior_w:1,
        shpg_unit_exterior_h:1,
        shpg_unit_nstdorflded_ht:1,
        shpg_unit_tare_wt:1,
        shpg_unit_stack_ht:1,
        warehouse_stack_ht:1,
        service_life:'',
        currency_desc:'',
        purchase_cost:1,
        rental_issue_cost:1,
        sup_rntl_dly_cost:1,
        ford_rntl_dly_cost:1,
        return_rntl_cost:1,
        tooling_dev_cost:1,
        extr_cntr_dsgn_img_ind:'',
        intr_cntr_dsgn_img_ind:'',
        shpg_unit_img_ind:'',
        cad_img_ind:'',
        img_notes:'',
        cd_region:'',
        iscontainercolapsble:'',
        cd_plant:'',
        cntr_qt_per_min_su:1,
        nest_ratio_1:1,
        ford_cntct:'',
        cntct_phone:'',
        id_user:'',
        ts_last_update:'',
        req_stat:'',
        components:[{
            mp_function:'',
            no_comp_base:'',
            no_comp_suffix:'',
            ds_comp:'',
            comp_qt_per_min_su:0,
            comp_cd_owner:'',
            in_comp:'',
            comp_cd_type:''
        }],
        UoM:'E',
        typ_no:0,
        dbia_req_no:0
    }
}


containerComptMapDetailsInit()
{
  this.cntrdetailsforcompmap=
   {
     function:'C',
   cntr_suff_ind:'',
   exp_refundable:'',
   cntr_active_ind:'',
   cntr_base:'',
   cntr_suffix:'',
   cntr_desc:'',
   card_call:'',
   cntr_mstr_catg:'',
   cntr_typ_desc:'',
   ownrshp_strat_code:'',
   ownrshp_catg:'',
   cntr_grp_desc:'',
   cntr_exterior_l:0,
   cntr_exterior_w:0,
   cntr_exterior_h:0,
   collapsed_ht:0.1,
   nest_ratio:0,
   cntr_interior_l:0,
   cntr_interior_w:0,
   cntr_interior_h:0,
   cntr_tare_wt:0,
   cntr_capacity:0,
   cntr_per_layer:0,
   max_layers_shpg_unit:0,
   max_cntr_per_shpg_unit:0,
   cntr_matr_desc:'',
   cntr_drawing_no:'',
   shpg_unit_exterior_l:0,
   shpg_unit_exterior_w:0,
   shpg_unit_exterior_h:0,
   shpg_unit_nstdorflded_ht:0,
   shpg_unit_tare_wt:0,
   shpg_unit_stack_ht:0,
   warehouse_stack_ht:0,
   service_life:'',
   currency_desc:'',
   purchase_cost:0,
   rental_issue_cost:0,
   sup_rntl_dly_cost:0,
   ford_rntl_dly_cost:0,
   return_rntl_cost:0,
   tooling_dev_cost:0,
   extr_cntr_dsgn_img_ind:'No',
   intr_cntr_dsgn_img_ind:'No',
   shpg_unit_img_ind:'No',
   cad_img_ind:'No',
   img_notes:'',
   cd_region:'',
   iscontainercolapsble:'',
   cd_plant:'',
   cntr_qt_per_min_su:0,
   nest_ratio_1:0,
   ford_cntct:'',
   cntct_phone:'',
   id_user:'',
   ts_last_update:'',
   req_stat:'',
   components:[{
       mp_function:'',
       no_comp_base:'',
       no_comp_suffix:'',
       ds_comp:'',
       comp_qt_per_min_su:0,
       comp_cd_owner:'',
       in_comp:'',
       comp_cd_type:''
   }],
   UoM:'E',
   typ_no:0,
   dbia_req_no:0
  }
}

addcntrdetailsinit()
{
  this.enable_material_dropdown=false;
    this.cntrSuffixenabled=false;
    // this.selectedcntrbase=null;
    this.selectedCntrFromAutoCmplete=null;
    this.selectedSuffixList={suppliergsdbcode:''}
    this.selected_cntr_material={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''}
    this.cntrdetailsadd=
    {
        // cd_cntr:0,
        function:'A',
        cntr_suff_ind:'',
        exp_refundable:'',
        cntr_active_ind:"Yes",
        cntr_base:'',
        cntr_suffix:'',
        cntr_desc:'',
        card_call:"Call",
        cntr_mstr_catg:'',
        cntr_typ_desc:'',
        ownrshp_strat_code:'',
        ownrshp_catg:'',
        cntr_grp_desc:'',
        cntr_exterior_l:0,
	      cntr_exterior_w:0,
	      cntr_exterior_h:0,
        collapsed_ht:0.1,
        nest_ratio:0,
        cntr_interior_l:0,
        cntr_interior_w:0,
        cntr_interior_h:0,
        cntr_tare_wt:0,
        cntr_capacity:0,
        cntr_per_layer:0,
        max_layers_shpg_unit:0,
        max_cntr_per_shpg_unit:0,
        cntr_matr_desc:'',
        cntr_drawing_no:'',
        shpg_unit_exterior_l:0,
        shpg_unit_exterior_w:0,
        shpg_unit_exterior_h:0,
        shpg_unit_nstdorflded_ht:0,
        shpg_unit_tare_wt:0,
        shpg_unit_stack_ht:0,
        warehouse_stack_ht:0,
        service_life:'',
        currency_desc:'',
        purchase_cost:0,
        rental_issue_cost:0,
        sup_rntl_dly_cost:0,
        ford_rntl_dly_cost:0,
        return_rntl_cost:0,
        tooling_dev_cost:0,
        extr_cntr_dsgn_img_ind:'',
        intr_cntr_dsgn_img_ind:'',
        shpg_unit_img_ind:'',
        cad_img_ind:'',
        img_notes:'',
        cd_region:'',
        iscontainercolapsble:'',
        cd_plant:'',
        cntr_qt_per_min_su:0,
        nest_ratio_1:0,
        ford_cntct:'',
        cntct_phone:'',
        id_user:'',
        ts_last_update:'',
        req_stat:'',
        components:[{
            mp_function:'',
            no_comp_base:'',
            no_comp_suffix:'',
            ds_comp:'',
            comp_qt_per_min_su:0,
            comp_cd_owner:'',
            in_comp:'',
            comp_cd_type:''
        }],
        UoM:'E',
        typ_no:0,
        dbia_req_no:0
    }
}

cntrdetailsinit()
{
  this.cntrdetails=
    {
        cd_cntr:0,
        cntr_suff_ind:'',
        exp_refundable:'',
        cntr_active_ind:'',
        cntr_base:'',
        cntr_suffix:'',
        cntr_desc:'',
        card_call:"Call",
        cntr_mstr_catg:'',
        cntr_typ_desc:'',
        ownrshp_strat_code:'',
        ownrshp_catg:'',
        cntr_grp_desc:'',
        cntr_exterior_l:0,
	      cntr_exterior_w:0,
	      cntr_exterior_h:0,
        collapsed_ht:1,
        nest_ratio:1,
        cntr_interior_l:0,
        cntr_interior_w:0,
        cntr_interior_h:0,
        cntr_tare_wt:0,
        cntr_capacity:0,
        cntr_per_layer:0,
        max_layers_shpg_unit:0,
        max_cntr_per_shpg_unit:0,
        cntr_matr_desc:'',
        cntr_drawing_no:'',
        shpg_unit_exterior_l:0,
        shpg_unit_exterior_w:0,
        shpg_unit_exterior_h:0,
        shpg_unit_nstdorflded_ht:0,
        shpg_unit_tare_wt:0,
        shpg_unit_stack_ht:0,
        warehouse_stack_ht:0,
        service_life:'',
        currency_desc:'',
        purchase_cost:0,
        rental_issue_cost:0,
        sup_rntl_dly_cost:0,
        ford_rntl_dly_cost:0,
        return_rntl_cost:0,
        tooling_dev_cost:0,
        extr_cntr_dsgn_img_ind:'',
        intr_cntr_dsgn_img_ind:'',
        shpg_unit_img_ind:'',
        cad_img_ind:'',
        img_notes:'',
        cd_region:'',
        iscontainercolapsble:'',
        cd_plant: '',
        cnt_qt_per_min_su: 0,
        nest_ratio_1: 0,
        ford_cntct: '',
        cntct_phone: '',
        req_stat: '',
        id_user: '',
        ts_last_update: '',
        uom:'E',
        typ_no:0
    }
}


cntrCmmsFieldsBeforeUpdateInit()
{
  this.cntrCmmsFieldsBeforeUpdate={
    cntr_base:'',
		cntr_suffix:'',
		cntr_desc:'',
		cntr_typ_desc:'',
		cd_plant:'',
		collapsed_ht:0,
		nest_ratio:0,
		nest_ratio_1:0,
		cntr_tare_wt:0,
		cntr_capacity:0,
		cntr_qt_per_min_su:0,
    ford_cntct:'',
    cntct_phone:'',
		cntr_exterior_l:0,
		cntr_exterior_w:0,
		cntr_exterior_h:0,
		shpg_unit_stack_ht:0,
		warehouse_stack_ht:0,
    ownrshp_catg:""

  }
}


cntrCmmsFieldsAfterUpdateInit()
{
  this.cntrCmmsFieldsAfterUpdate={
    cntr_base:'',
		cntr_suffix:'',
		cntr_desc:'',
		cntr_typ_desc:'',
		cd_plant:'',
		collapsed_ht:0,
		nest_ratio:0,
		nest_ratio_1:0,
		cntr_tare_wt:0,
		cntr_capacity:0,
		cntr_qt_per_min_su:0,
    ford_cntct:'',
    cntct_phone:'',
		cntr_exterior_l:0,
		cntr_exterior_w:0,
		cntr_exterior_h:0,
		shpg_unit_stack_ht:0,
		warehouse_stack_ht:0,
    ownrshp_catg:""

  }
}



cntrAdmindirectSaveinit()
{
  this.saveEditContainerCntrAdmin=
    {
        cd_cntr:0,
        cntr_suff_ind:'',
        exp_refundable:'',
        cntr_active_ind:'',
        cntr_base:'',
        cntr_suffix:'',
        cntr_desc:'',
        card_call:'',
        cntr_mstr_catg:'',
        cntr_typ_desc:'',
        ownrshp_strat_code:'',
        ownrshp_catg:'',
        cntr_grp_desc:'',
        cntr_exterior_l:0,
	      cntr_exterior_w:0,
	      cntr_exterior_h:0,
        collapsed_ht:0,
        nest_ratio:0,
        cntr_interior_l:0,
        cntr_interior_w:0,
        cntr_interior_h:0,
        cntr_tare_wt:0,
        cntr_capacity:0,
        cntr_per_layer:0,
        max_layers_shpg_unit:0,
        max_cntr_per_shpg_unit:0,
        cntr_matr_desc:'',
        cntr_drawing_no:'',
        shpg_unit_exterior_l:0,
        shpg_unit_exterior_w:0,
        shpg_unit_exterior_h:0,
        shpg_unit_nstdorflded_ht:0,
        shpg_unit_tare_wt:0,
        shpg_unit_stack_ht:0,
        warehouse_stack_ht:0,
        service_life:'',
        currency_desc:'',
        purchase_cost:0,
        rental_issue_cost:0,
        sup_rntl_dly_cost:0,
        ford_rntl_dly_cost:0,
        return_rntl_cost:0,
        tooling_dev_cost:0,
        extr_cntr_dsgn_img_ind:'',
        intr_cntr_dsgn_img_ind:'',
        shpg_unit_img_ind:'',
        cad_img_ind:'',
        img_notes:'',
        cd_region:'',
        iscontainercolapsble:'',
        cd_plant: '',
        cnt_qt_per_min_su: 0,
        nest_ratio_1: 0,
        ford_cntct: '',
        cntct_phone: '',
        req_stat: '',
        id_user: '',
        ts_last_update: '',
        uom:'E',
        typ_no:0
    }
}


validationInit()
{
this.collapsedHtInValid=false;
this.collapsedHtInValidReason=null;
this.nestRatioInValid=false;
this.nestRatioInValidReason=null;
this.nestRatio1InValid=false;
this.nestRatio1InValidReason=null;
this.cntrTareWtInValid=false;
this.cntrTareWtInValidReason=null;
this.cntrCapWtInValid=false;
this.cntrCapWtInValidReason=null;
this.cntrPerLayerInValid=false;
this.cntrPerLayerInValidReason=null;
this.maxLayerPerSuInValid=false;
this.maxLayerPerSuInValidReason=null;
this.cntrQtPerMinSuInValid=false;
this.cntrQtPerMinSuInvalidReason=null;
this.MaxCntrPerSuInValid=false;
this.MaxCntrPerMinSuInvalidReason=null;
this.cntrExtLInValid=false;
this.cntrExtLInValidReason=null;
this.cntrExtWInValid=false;
this.cntrExtWInValidReason=null;
this.cntrExtHInValid=false;
this.cntrExtHInValidReason=null;
this.cntrIntLInValid=false;
this.cntrIntLInValidReason=null;
this.cntrIntWInValid=false;
this.cntrIntWInValidReason=null;
this.cntrIntHInValid=false;
this.cntrIntHInValidReason=null;
this.suNestedHtInValid=false;
this.suNestedHtInValidReason=null;
this.suTareWtInvalid=false;
this.suTareWtInValidReason=null;
this.wareHourseStackHtInvaid=false;
this.wareHourseStackHtInvaidReason=null;
this.suStackHtInvalid=false;
this.suStackHtInvalidReason=null;
this.suExtLInValid=false;
this.suExtLInValidReason=null;
this.suExtWInValid=false;
this.suExtWInValidReason=null;
this.suExtHInValid=false;
this.suExtHInValidReason=null;

this.cntrBaseInvalid=false;
this.cntrBaseInvalidReason=null;
this.cntrDescInvalid=false;
this.cntrDescInvalidReason=null;
this.plantCodeInvalid=false;
this.plantCodeInvalidReason=null;
// this.cardOrCallInvalid=false;
// this.cardOrCallInvalidReason=null;
this.mastrCatgInvalid=false;
this.mastrCatgInvalidReason=null;
this.retTypeInvalid=false;
this.retTypeInvalidReason=null;
this.cntrTypeInvalid=false;
this.cntrTypeInvalidReason=null;
this.cntrMaterialInvalid=false;
this.cntrMaterialInvalidReason=null;
this.cosInvalid=false;
this.cosInvalidReason=null;
this.cntrGrpInvalid=false;
this.cntrGrpInvalidReason=null;
this.isCollapsibleInvalid=false;
this.isCollapsibleInvalidReason=null;
this.cntrCntctInvalid=false;
this.cntrCntctInvalidReason=null;
this.cntctPhoneInvalid=false;
this.cntctPhoneInvlidReason=null;
this.cntrActiveInvalid=false;
this.cntrActiveInvalidReason=null;
this.cntrOwnerShpCatgInvalid=false;
this.cntrOwnerShpCatgInvalidReason=null;

this.cntrSuffixInvalid=false;
this.cntrSuffixInvalidReason=null;

this.cntrSuffixIndInvalid=false;
this.cntrSuffixIndInvalidReason=null;
}
/* Removing add suffix to container button
cntrsuffixchangedadd()
{

  if(this.cntrdetailsadd.cntr_suff_ind=="No")
  {
    this.cntrSuffixAdd=this.cntrdetailsadd.cntr_suffix;
    this.cntrSuffixenabled=false;
    this.cntrdetailsadd.cntr_suffix="        ";
    console.log(this.cntrSuffixenabled);
  }
  else
  {
    this.cntrdetailsadd.cntr_suffix=this.cntrSuffixAdd;
    console.log("checking",this.cntrSuffixAdd);
    this.cntrSuffixenabled=true;
  }
}

cntrsuffixchangededit()
{

  if(this.cntrdetailsedit.cntr_suff_ind=="No")
  {
    this.cntrSuffixEdit=this.cntrdetailsedit.cntr_suffix;
    this.cntrSuffixEditenabled=false;
    this.cntrdetailsedit.cntr_suffix="        ";
  }
  else
  {
    this.cntrdetailsedit.cntr_suffix=this.cntrSuffixEdit;
    console.log("checking",this.cntrSuffixAdd);
    this.cntrSuffixEditenabled=true;
  }
}
*/
addcntrbutton()
{

    this.selected_container=null;
    this.cont_retype='Returnable';
    this.selected_cntr_ownrship_strat_code_in_popup=this.cntrOwnershipstratcodelist[0];
    this.containerDetailsCopied=false;
    this.cntrAddEnable=false;
    this.cntrAddDetailsEnable=true;
    this.enable_material_dropdown=false;
    this.addcntrdetailsinit();
    this.cntrdetails_display_popup_init();
    this.loadcntrdetails();
    this.validationInit();

    if(this.unitom=='E')
      {
      this.add_measure='lbs/inch';
      this.prev_add_measure='lbs/inch';
      }
      else{
        this.add_measure='kg/cm';
      this.prev_add_measure='kg/cm';
      }
    console.log(this.cntrdetailsadd);
    this.onChangeRetTypeAddCntr();

}




change_add_measure()
{
  console.log(this.add_measure);

  if(this.unitom=='E')
      {
      this.add_measure='lbs/inch';
      this.prev_add_measure='lbs/inch';
      // this.edit_measure='lbs/inch';
      }
      else{
        this.add_measure='kg/cm';
      this.prev_add_measure='kg/cm';
      // this.edit_measure='kg/cm';
      }

  if(this.add_measure=='lbs/inch')
  {
    if(this.prev_add_measure!='lbs/inch')
    {
      console.log('inside lbs')


      if(this.containerOrComponentInDetails.toLowerCase() == 'container')
      {
          if(this.cntrdetailsadd.collapsed_ht!=null)
          {
              this.cntrdetailsadd.collapsed_ht=Math.round((0.393701*this.cntrdetailsadd.collapsed_ht)*1000)/1000;

          }
          if(this.cntrdetailsadd.cntr_interior_l!=null)
          {
              this.cntrdetailsadd.cntr_interior_l=Math.round((0.393701*this.cntrdetailsadd.cntr_interior_l)*1000)/1000;
          }
          if(this.cntrdetailsadd.cntr_interior_w!=null)
          {
                  this.cntrdetailsadd.cntr_interior_w=Math.round((0.393701*this.cntrdetailsadd.cntr_interior_w)*1000)/1000;
          }
          if(this.cntrdetailsadd.cntr_interior_h!=null)
          {
                  this.cntrdetailsadd.cntr_interior_h=Math.round((0.393701*this.cntrdetailsadd.cntr_interior_h)*1000)/1000;
          }
          if(this.cntrdetailsadd.cntr_tare_wt!=null)
          {
                  this.cntrdetailsadd.cntr_tare_wt=Math.round((2.20462*this.cntrdetailsadd.cntr_tare_wt)*1000)/1000;
          }
          if(this.cntrdetailsadd.cntr_capacity!=null)
          {
                  this.cntrdetailsadd.cntr_capacity=Math.round((0.393701*0.393701*0.393701*this.cntrdetailsadd.cntr_capacity)*1000)/1000;
          }

          if(this.cntrdetailsadd.cntr_exterior_l!=null)
          {
                  this.cntrdetailsadd.cntr_exterior_l=Math.round((0.393701*this.cntrdetailsadd.cntr_exterior_l)*1000)/1000;
          }
          if(this.cntrdetailsadd.cntr_exterior_w!=null)
          {
                  this.cntrdetailsadd.cntr_exterior_w=Math.round((0.393701*this.cntrdetailsadd.cntr_exterior_w)*1000)/1000;
          }
          if(this.cntrdetailsadd.cntr_exterior_h!=null)
          {
                  this.cntrdetailsadd.cntr_exterior_h=Math.round((0.393701*this.cntrdetailsadd.cntr_exterior_h)*1000)/1000;
          }
          if(this.cntrdetailsadd.shpg_unit_exterior_l!=null)
          {
                this.cntrdetailsadd.shpg_unit_exterior_l=Math.round((0.393701*this.cntrdetailsadd.shpg_unit_exterior_l)*1000)/1000;
          }
          if(this.cntrdetailsadd.shpg_unit_exterior_w!=null)
          {
                  this.cntrdetailsadd.shpg_unit_exterior_w=Math.round((0.393701*this.cntrdetailsadd.shpg_unit_exterior_w)*1000)/1000;
          }
          if(this.cntrdetailsadd.shpg_unit_exterior_h!=null)
          {
                  this.cntrdetailsadd.shpg_unit_exterior_h=Math.round((0.393701*this.cntrdetailsadd.shpg_unit_exterior_h)*1000)/1000;
          }
          if(this.cntrdetailsadd.shpg_unit_nstdorflded_ht!=null)
          {
                  this.cntrdetailsadd.shpg_unit_nstdorflded_ht=Math.round((0.393701*this.cntrdetailsadd.shpg_unit_nstdorflded_ht)*1000)/1000;
          }
          if(this.cntrdetailsadd.shpg_unit_tare_wt!=null)
          {
                  this.cntrdetailsadd.shpg_unit_tare_wt=Math.round((2.20462*this.cntrdetailsadd.shpg_unit_tare_wt)*1000)/1000;
          }
          if(this.cntrdetailsadd.shpg_unit_stack_ht!=null)
          {
                  this.cntrdetailsadd.shpg_unit_stack_ht=Math.round((0.393701*this.cntrdetailsadd.shpg_unit_stack_ht)*1000)/1000;
          }
          if(this.cntrdetailsadd.warehouse_stack_ht!=null)
          {
                  this.cntrdetailsadd.warehouse_stack_ht=Math.round((0.393701*this.cntrdetailsadd.warehouse_stack_ht)*1000)/1000;
          }

          this.prev_add_measure='lbs/inch'
          this.validateCollapsedHtForAdd();
          this.validateCntrTareWeightForAdd();
          this.validateCntrCapWtForAdd();
          this.validateCntrExtLForAdd();
          this.validateCntrExtWForAdd();
          this.validateCntrExtHForAdd();
          this.validateCntrIntLForAdd();
          this.validateCntrIntWForAdd();
          this.validateCntrIntHForAdd();
          this.validateSuNestedFldedHtForAdd();
          this.validateSuTareWeightForAdd();
          this.validateWareHourseStckHtForAdd();
          this.validateShippingUnitStackHtForAdd();
          this.validateSUExtLForAdd();
          this.validateSUExtWForAdd();
          this.validateSUExtHForAdd();
      }
      else
      {


          if(this.compdetailsadd.cntr_tare_wt!=null)
          {
                  this.compdetailsadd.cntr_tare_wt=Math.round((2.20462*this.compdetailsadd.cntr_tare_wt)*1000)/1000;
          }
          if(this.compdetailsadd.cntr_capacity!=null)
          {
                  this.compdetailsadd.cntr_capacity=Math.round((0.393701*0.393701*0.393701*this.compdetailsadd.cntr_capacity)*1000)/1000;
          }

          if(this.compdetailsadd.cntr_exterior_l!=null)
          {
                  this.compdetailsadd.cntr_exterior_l=Math.round((0.393701*this.compdetailsadd.cntr_exterior_l)*1000)/1000;
          }
          if(this.compdetailsadd.cntr_exterior_w!=null)
          {
                  this.compdetailsadd.cntr_exterior_w=Math.round((0.393701*this.compdetailsadd.cntr_exterior_w)*1000)/1000;
          }
          if(this.compdetailsadd.cntr_exterior_h!=null)
          {
                  this.compdetailsadd.cntr_exterior_h=Math.round((0.393701*this.compdetailsadd.cntr_exterior_h)*1000)/1000;
          }
          if(this.compdetailsadd.warehouse_stack_ht!=null)
          {
                  this.compdetailsadd.warehouse_stack_ht=Math.round((0.393701*this.compdetailsadd.warehouse_stack_ht)*1000)/1000;
          }

          this.prev_add_measure='lbs/inch'
          this.validateCompTareWeightForAdd();
          this.validateCompCapWtForAdd();
          this.validateCompExtLForAdd();
          this.validateCompExtWForAdd();
          this.validateCompExtHForAdd();
          this.validateCompWareHourseStckHtForAdd();

      }
  }

  }
  else if(this.add_measure=='kg/cm')
  {

    if(this.prev_add_measure!='kg/cm')
    {
      console.log('inside kg')
      if(this.containerOrComponentInDetails.toLowerCase() == 'container')
      {

            if(this.cntrdetailsadd.collapsed_ht!=null)
            {
                this.cntrdetailsadd.collapsed_ht=Math.round((2.54*this.cntrdetailsadd.collapsed_ht)*1000)/1000;
            }
            if(this.cntrdetailsadd.cntr_interior_l!=null)
            {
                this.cntrdetailsadd.cntr_interior_l=Math.round((2.54*this.cntrdetailsadd.cntr_interior_l)*1000)/1000;
            }
            if(this.cntrdetailsadd.cntr_interior_w!=null)
            {
                    this.cntrdetailsadd.cntr_interior_w=Math.round((2.54*this.cntrdetailsadd.cntr_interior_w)*1000)/1000;
            }
            if(this.cntrdetailsadd.cntr_interior_h!=null)
            {
                    this.cntrdetailsadd.cntr_interior_h=Math.round((2.54*this.cntrdetailsadd.cntr_interior_h)*1000)/1000;
            }
            if(this.cntrdetailsadd.cntr_tare_wt!=null)
            {
                    this.cntrdetailsadd.cntr_tare_wt=Math.round((0.453592*this.cntrdetailsadd.cntr_tare_wt)*1000)/1000;
            }
            if(this.cntrdetailsadd.cntr_capacity!=null)
            {
                    this.cntrdetailsadd.cntr_capacity=Math.round((2.54*2.54*2.54*this.cntrdetailsadd.cntr_capacity)*1000)/1000;
            }

            if(this.cntrdetailsadd.cntr_exterior_l!=null)
            {
                    this.cntrdetailsadd.cntr_exterior_l=Math.round((2.54*this.cntrdetailsadd.cntr_exterior_l)*1000)/1000;
            }
            if(this.cntrdetailsadd.cntr_exterior_w!=null)
            {
                    this.cntrdetailsadd.cntr_exterior_w=Math.round((2.54*this.cntrdetailsadd.cntr_exterior_w)*1000)/1000;
            }
            if(this.cntrdetailsadd.cntr_exterior_h!=null)
            {
                    this.cntrdetailsadd.cntr_exterior_h=Math.round((2.54*this.cntrdetailsadd.cntr_exterior_h)*1000)/1000;
            }
            if(this.cntrdetailsadd.shpg_unit_exterior_l!=null)
            {
                  this.cntrdetailsadd.shpg_unit_exterior_l=Math.round((2.54*this.cntrdetailsadd.shpg_unit_exterior_l)*1000)/1000;
            }
            if(this.cntrdetailsadd.shpg_unit_exterior_w!=null)
            {
                    this.cntrdetailsadd.shpg_unit_exterior_w=Math.round((2.54*this.cntrdetailsadd.shpg_unit_exterior_w)*1000)/1000;
            }
            if(this.cntrdetailsadd.shpg_unit_exterior_h!=null)
            {
                    this.cntrdetailsadd.shpg_unit_exterior_h=Math.round((2.54*this.cntrdetailsadd.shpg_unit_exterior_h)*1000)/1000;
            }
            if(this.cntrdetailsadd.shpg_unit_nstdorflded_ht!=null)
            {
                    this.cntrdetailsadd.shpg_unit_nstdorflded_ht=Math.round((2.54*this.cntrdetailsadd.shpg_unit_nstdorflded_ht)*1000)/1000;
            }
            if(this.cntrdetailsadd.shpg_unit_tare_wt!=null)
            {
                    this.cntrdetailsadd.shpg_unit_tare_wt=Math.round((0.453592*this.cntrdetailsadd.shpg_unit_tare_wt)*1000)/1000;
            }
            if(this.cntrdetailsadd.shpg_unit_stack_ht!=null)
            {
                    this.cntrdetailsadd.shpg_unit_stack_ht=Math.round((2.54*this.cntrdetailsadd.shpg_unit_stack_ht)*1000)/1000;
            }
            if(this.cntrdetailsadd.warehouse_stack_ht!=null)
            {
                    this.cntrdetailsadd.warehouse_stack_ht=Math.round((2.54*this.cntrdetailsadd.warehouse_stack_ht)*1000)/1000;
            }
        // this.prev_add_measure='kg/cm';
        this.validateCollapsedHtForAdd();
        this.validateCntrTareWeightForAdd();
        this.validateCntrCapWtForAdd();
        this.validateCntrExtLForAdd();
        this.validateCntrExtWForAdd();
        this.validateCntrExtHForAdd();
        this.validateCntrIntLForAdd();
        this.validateCntrIntWForAdd();
        this.validateCntrIntHForAdd();
        this.validateSuNestedFldedHtForAdd();
        this.validateSuTareWeightForAdd();
        this.validateWareHourseStckHtForAdd();
        this.validateShippingUnitStackHtForAdd();
        this.validateSUExtLForAdd();
        this.validateSUExtWForAdd();
        this.validateSUExtHForAdd();
  }
  else
  {


    if(this.compdetailsadd.cntr_tare_wt!=null)
    {
            this.compdetailsadd.cntr_tare_wt=Math.round((0.453592*this.compdetailsadd.cntr_tare_wt)*1000)/1000;
    }
    if(this.compdetailsadd.cntr_capacity!=null)
    {
            this.compdetailsadd.cntr_capacity=Math.round((2.54*2.54*2.54*this.compdetailsadd.cntr_capacity)*1000)/1000;
    }

    if(this.compdetailsadd.cntr_exterior_l!=null)
    {
            this.compdetailsadd.cntr_exterior_l=Math.round((2.54*this.compdetailsadd.cntr_exterior_l)*1000)/1000;
    }
    if(this.compdetailsadd.cntr_exterior_w!=null)
    {
            this.compdetailsadd.cntr_exterior_w=Math.round((2.54*this.compdetailsadd.cntr_exterior_w)*1000)/1000;
    }
    if(this.compdetailsadd.cntr_exterior_h!=null)
    {
            this.compdetailsadd.cntr_exterior_h=Math.round((2.54*this.compdetailsadd.cntr_exterior_h)*1000)/1000;
    }
    if(this.compdetailsadd.warehouse_stack_ht!=null)
    {
            this.compdetailsadd.warehouse_stack_ht=Math.round((2.54*this.compdetailsadd.warehouse_stack_ht)*1000)/1000;
    }
    // this.prev_add_measure='kg/cm';
    this.validateCompTareWeightForAdd();
    this.validateCompCapWtForAdd();
    this.validateCompExtLForAdd();
    this.validateCompExtWForAdd();
    this.validateCompExtHForAdd();
    this.validateCompWareHourseStckHtForAdd();

  }

  }

  }

}


validateCntrBaseForAdd()
{
  if(this.cntrdetailsadd.cntr_base==null || this.cntrdetailsadd.cntr_base.trim()=='')
  {
    this.cntrBaseInvalid=true;
    this.cntrBaseInvalidReason='Required'
  }
  else
  {
    this.cntrBaseInvalid=false;
    this.cntrBaseInvalidReason=null
  }
}

validateCntrDescForAdd()
{
  if(!this.isCmmsLoadedContainer)
  {
    if(this.cntrdetailsadd.cntr_desc==null || this.cntrdetailsadd.cntr_desc.trim()=='')
    {
      this.cntrDescInvalid=true;
      this.cntrDescInvalidReason='Required'
    }
    else
    {
      this.cntrDescInvalid=false;
      this.cntrDescInvalidReason=null
    }
  }

}

validatePlantCodeForAdd()
{
   if(this.selectedPlantList.plantGSDBCode==null || this.selectedPlantList.plantGSDBCode.trim()=='')
    {
      this.plantCodeInvalid=true;
      this.plantCodeInvalidReason='Required'
    }
    else
    {
      this.plantCodeInvalid=false;
      this.plantCodeInvalidReason=null
    }
}

// validateCardOrCallForAdd()
// {
//   if(this.cntrdetailsadd.card_call==null || this.cntrdetailsadd.card_call.trim()=="")
//   {
//     this.cardOrCallInvalid=true;
//     this.cardOrCallInvalidReason="Required"
//   }
//   else
//   {
//     this.cardOrCallInvalid=false;
//     this.cardOrCallInvalidReason=null
//   }
// }


validateMastCatgForAdd()
{
  if(this.selected_cntr_master_category.cntr_mstr_catg==null || this.selected_cntr_master_category.cntr_mstr_catg.trim()=='')
  {
    this.mastrCatgInvalid=true;
    this.mastrCatgInvalidReason='Required'
  }
  else
  {
    this.mastrCatgInvalid=false;
    this.mastrCatgInvalidReason=null
  }
}

validateCntrRetTypeForAdd()
{
  if(this.cntrdetailsadd.exp_refundable==null || this.cntrdetailsadd.exp_refundable.trim()=='')
  {
    this.retTypeInvalid=true;
    this.retTypeInvalidReason='Required'
  }
  else
  {
    this.retTypeInvalid=false;
    this.retTypeInvalidReason=null;
  }
}

validateCntrTypeCodeForAdd()
{
  if(!this.isCmmsLoadedContainer)
  {
    if(this.selected_cntr_type_code.cntr_typ_desc==null || this.selected_cntr_type_code.cntr_typ_desc.trim()=='')
    {
      this.cntrTypeInvalid=true;
      this.cntrTypeInvalidReason='Required';
    }
    else
    {
      this.cntrTypeInvalid=false;
      this.cntrTypeInvalidReason=null;
    }
  }
}

validateCntrMaterialForAdd()
{
  if(this.selected_cntr_material.cntr_matr_name==null || this.selected_cntr_material.cntr_matr_name.trim()=='')
  {
    this.cntrMaterialInvalid=true;
    this.cntrMaterialInvalidReason='Required';
  }
  else
  {
    this.cntrMaterialInvalid=false;
    this.cntrMaterialInvalidReason=null;
  }
}
validateCntrOwnershpCatgForAdd()
{
  if(this.selected_ownrship_catg.ownrshp_catg_val==null || this.selected_ownrship_catg.ownrshp_catg_val.trim()=='')
  {
    this.cntrOwnerShpCatgInvalid=true;
    this.cntrOwnerShpCatgInvalidReason='Required';
  }
  else
  {
    this.cntrOwnerShpCatgInvalid=false;
    this.cntrOwnerShpCatgInvalidReason=null;
  }
}

validateCOSForAdd()
{
  if(this.selected_cntr_ownrship_strat_code.ownrshp_strat_code==null || this.selected_cntr_ownrship_strat_code.ownrshp_strat_code.trim()=='')
  {
    this.cosInvalid=true;
    this.cosInvalidReason='Required';
  }
  else
  {
    this.cosInvalid=false;
    this.cosInvalidReason=null;
  }
}

validateCntrGrpForAdd()
{
  if(this.selected_cntr_group.cntr_grp==null || this.selected_cntr_group.cntr_grp.trim()=='')
  {
    this.cntrGrpInvalid=true;
    this.cntrGrpInvalidReason='Required';
  }
  else
  {
    this.cntrGrpInvalid=false;
    this.cntrGrpInvalidReason=null;
  }
}

validateCntrCollapsible()
{
  if(this.cntrdetailsadd.iscontainercolapsble==null || this.cntrdetailsadd.iscontainercolapsble.trim()=='')
  {
    this.isCollapsibleInvalid=true;
    this.isCollapsibleInvalidReason='Required';
  }
  else{
    this.isCollapsibleInvalid=false;
    this.isCollapsibleInvalidReason=null;
  }
}

validateCntrContact()
{
  if(!this.isCmmsLoadedContainer)
  {
    if(this.cntrdetailsadd.ford_cntct==null || this.cntrdetailsadd.ford_cntct.trim()=='')
    {
      this.cntrCntctInvalid=true;
      this.cntrCntctInvalidReason='Required';
    }
    else
    {
      this.cntrCntctInvalid=false;
      this.cntrCntctInvalidReason=null;
    }
  }
}

validateCntrContactPhone()
{
  if(!this.isCmmsLoadedContainer)
  {
    if(this.cntrdetailsadd.cntct_phone==null || this.cntrdetailsadd.cntct_phone.trim()=='')
    {
      this.cntctPhoneInvalid=true;
      this.cntctPhoneInvlidReason='Required';
    }
    else
    {
      this.cntctPhoneInvalid=false;
      this.cntctPhoneInvlidReason=null;
    }
  }
}

validateCntrActive()
{
  if(this.cntrdetailsadd.cntr_active_ind==null || this.cntrdetailsadd.cntr_active_ind.trim()=='')
  {
    this.cntrActiveInvalid=true;
    this.cntrActiveInvalidReason='Required';
  }
  else
  {
    this.cntrActiveInvalid=false;
    this.cntrActiveInvalidReason=null;
  }
}


validateCollapsedHtForAdd()
{
  if(!this.isCmmsLoadedContainer)
  {
    console.log(this.cntrdetailsadd.collapsed_ht ,this.cntrdetailsadd.cntr_exterior_h)
    if(this.cntrdetailsadd.iscontainercolapsble.toLowerCase()=='y' && (this.cntrdetailsadd.collapsed_ht==null || this.cntrdetailsadd.collapsed_ht==0))
    {
      this.collapsedHtInValid=true;
      this.collapsedHtInValidReason='Required';
    }
    else if(this.cntrdetailsadd.iscontainercolapsble.toLowerCase()=='y' && (this.cntrdetailsadd.collapsed_ht<=0 || this.cntrdetailsadd.collapsed_ht>999.999))
    {
      this.collapsedHtInValid=true;
      this.collapsedHtInValidReason='Should be of range 0 to 999.999';
    }
    else if(this.cntrdetailsadd.iscontainercolapsble.toLowerCase()=='y' && this.cntrdetailsadd.collapsed_ht > this.cntrdetailsadd.cntr_exterior_h)
    {
      this.collapsedHtInValid=true;
      this.collapsedHtInValidReason='Collapsed Height cannot be greater than Container height';
    }
    else if(this.cntrdetailsadd.iscontainercolapsble.toLowerCase()=='y' && String(this.cntrdetailsadd.collapsed_ht).includes('.'))
    {
      const colap_ht = String(this.cntrdetailsadd.collapsed_ht).split('.')
      if(colap_ht[1].length > 3)
      {
        this.collapsedHtInValid=true;
        this.collapsedHtInValidReason='Max Length Of decimal values can be 3';
      }
      else
      {
        this.collapsedHtInValid=false;
        this.collapsedHtInValidReason=null;
      }
    }
    else
    {
      this.collapsedHtInValid=false;
      this.collapsedHtInValidReason=null;
    }
  }
}

validateNestRatioForAdd()
{
  if(!this.isCmmsLoadedContainer)
  {
    if(this.cntrdetailsadd.nest_ratio==null || this.cntrdetailsadd.nest_ratio==0)
    {
      this.nestRatioInValid=true;
      this.nestRatioInValidReason='Required';
    }
    else if(this.cntrdetailsadd.nest_ratio<1 || this.cntrdetailsadd.nest_ratio>99 || String(this.cntrdetailsadd.nest_ratio).includes('.'))
    {
      this.nestRatioInValid=true;
      this.nestRatioInValidReason='Should be of range 1 to 99 and cannot contain Decimal';
    }
    else
    {
      this.nestRatioInValid=false;
      this.nestRatioInValidReason=null;
    }
  }
}
validateNestRatio1ForAdd()
{
  if(!this.isCmmsLoadedContainer)
  {
    if(this.cntrdetailsadd.nest_ratio_1==null || this.cntrdetailsadd.nest_ratio_1==0)
    {
      this.nestRatio1InValid=true;
      this.nestRatio1InValidReason='Required';
    }
    else if(this.cntrdetailsadd.nest_ratio_1<1 || this.cntrdetailsadd.nest_ratio_1>9 || String(this.cntrdetailsadd.nest_ratio_1).includes('.'))
    {
      this.nestRatio1InValid=true;
      this.nestRatio1InValidReason='Should be of range 1 to 9 and cannot contain Decimal';
    }
    else
    {
      this.nestRatio1InValid=false;
      this.nestRatio1InValidReason=null;
    }
  }
}
validateCntrTareWeightForAdd()
{
  if(!this.isCmmsLoadedContainer)
  {
    const cont_tar_wt = String(this.cntrdetailsadd.cntr_tare_wt).split('.');
    if(this.cntrdetailsadd.cntr_tare_wt==null || this.cntrdetailsadd.cntr_tare_wt==0)
    {
      this.cntrTareWtInValid=true;
      this.cntrTareWtInValidReason='Required';
    }
    else if(!String(this.cntrdetailsadd.cntr_tare_wt).includes('.'))
        {
        /*if( String(this.cntrdetailsadd.cntr_tare_wt).length>10)
            {
              this.cntrTareWtInValid=true;
              this.cntrTareWtInValidReason="Max Length Of Characters can be 10";
            }
            else*/
            if(this.cntrdetailsadd.cntr_tare_wt<=0 || this.cntrdetailsadd.cntr_tare_wt>99999.999)
            {
              this.cntrTareWtInValid=true;
              this.cntrTareWtInValidReason='Should be of range 0 to 99999.999';
            }
          else
            {
              this.cntrTareWtInValid=false;
              this.cntrTareWtInValidReason=null;
            }
        }
        else if(String(this.cntrdetailsadd.cntr_tare_wt).includes('.'))
        {
            if(String(cont_tar_wt[0]).length<0 || String(cont_tar_wt[0]).length>5)
            {
              this.cntrTareWtInValid=true;
              this.cntrTareWtInValidReason='Should be of range 0 to 99999.999';
            }
            else if(String(cont_tar_wt[1]).length<0 || String(cont_tar_wt[1]).length>3)
            {
              this.cntrTareWtInValid=true;
              this.cntrTareWtInValidReason='Max Length Of decimal values can be 3';
            }
            else
            {
              this.cntrTareWtInValid=false;
              this.cntrTareWtInValidReason=null;
            }
        }
        else
        {
        this.cntrTareWtInValid=false;
        this.cntrTareWtInValidReason=null;
        }
  }
}
validateCntrCapWtForAdd()
{
  if(!this.isCmmsLoadedContainer)
  {
    const cont_cap_wt = String(this.cntrdetailsadd.cntr_capacity).split('.');
    if(this.cntrdetailsadd.cntr_capacity==null || this.cntrdetailsadd.cntr_capacity==0)
    {
      this.cntrCapWtInValid=true;
      this.cntrCapWtInValidReason='Required';
    }
    else if(this.cntrdetailsadd.cntr_capacity<=0 || this.cntrdetailsadd.cntr_capacity>99999.999)
    {
      this.cntrCapWtInValid=true;
      this.cntrCapWtInValidReason='Should be of range 0 to 99999.999';
    }
    else if(String(this.cntrdetailsadd.cntr_capacity).includes('.'))
    {
      if(cont_cap_wt[1].length > 3)
      {
        this.cntrCapWtInValid=true;
        this.cntrCapWtInValidReason='Max Length Of decimal values can be 3';
      }
      else
      {
        this.cntrCapWtInValid=false;
        this.cntrCapWtInValidReason=null;
      }
    }
    else
    {
      this.cntrCapWtInValid=false;
      this.cntrCapWtInValidReason=null;
    }
  }
}
validateCntrPerLayerForAdd()
{
  if(this.cntrdetailsadd.cntr_per_layer==null || this.cntrdetailsadd.cntr_per_layer==0)
  {
    this.cntrPerLayerInValid=true;
    this.cntrPerLayerInValidReason='Required';
  }
  else if(this.cntrdetailsadd.cntr_per_layer<1 || this.cntrdetailsadd.cntr_per_layer>99999 || String(this.cntrdetailsadd.cntr_per_layer).includes('.'))
  {
    this.cntrPerLayerInValid=true;
    this.cntrPerLayerInValidReason='Should be of range 1 to 99999 and cannot contain Decimal';
  }
  else
  {
    this.cntrPerLayerInValid=false;
    this.cntrPerLayerInValidReason=null;
  }
}
validateMaxLayerPerSuForAdd()
{
  if(this.cntrdetailsadd.max_layers_shpg_unit==null || this.cntrdetailsadd.max_layers_shpg_unit==0)
  {
    this.maxLayerPerSuInValid=true;
    this.maxLayerPerSuInValidReason='Required';
  }
  else if(this.cntrdetailsadd.max_layers_shpg_unit<1 || this.cntrdetailsadd.max_layers_shpg_unit>99999 || String(this.cntrdetailsadd.max_layers_shpg_unit).includes('.'))
  {
    this.maxLayerPerSuInValid=true;
    this.maxLayerPerSuInValidReason='Should be of range 1 to 99999  and cannot contain Decimal';
  }
  else
  {
    this.maxLayerPerSuInValid=false;
    this.maxLayerPerSuInValidReason=null;
  }
}
validateCntrQtPerMinSuForAdd()
{
  if(!this.isCmmsLoadedContainer)
  {
    if(this.cntrdetailsadd.cntr_qt_per_min_su==null || this.cntrdetailsadd.cntr_qt_per_min_su==0)
    {
      this.cntrQtPerMinSuInValid=true;
      this.cntrQtPerMinSuInvalidReason='Required';
    }
    else if(this.cntrdetailsadd.cntr_qt_per_min_su<1 || this.cntrdetailsadd.cntr_qt_per_min_su>99 || String(this.cntrdetailsadd.cntr_qt_per_min_su).includes('.'))
    {
      this.cntrQtPerMinSuInValid=true;
      this.cntrQtPerMinSuInvalidReason='Should be of range 1 to 99  and cannot contain Decimal';
    }
    else
    {
      this.cntrQtPerMinSuInValid=false;
      this.cntrQtPerMinSuInvalidReason=null;
    }
  }
}
validateMaxCntrPerSuForAdd()
{

  if( (this.cntrdetailsadd.max_cntr_per_shpg_unit>99999 || String(this.cntrdetailsadd.max_cntr_per_shpg_unit).includes('.')))
  {
    this.MaxCntrPerSuInValid=true;
    this.MaxCntrPerMinSuInvalidReason='Should be of range 1 to 99999  and cannot contain Decimal';
  }
  else
  {
    this.MaxCntrPerSuInValid=false;
    this.MaxCntrPerMinSuInvalidReason=null;
  }
}
validateCntrExtLForAdd()
{
  if(!this.isCmmsLoadedContainer)
  {
    const cont_ext_l = String(this.cntrdetailsadd.cntr_exterior_l).split('.');
    if(this.cntrdetailsadd.cntr_exterior_l==null || this.cntrdetailsadd.cntr_exterior_l==0)
    {
      this.cntrExtLInValid=true;
      this.cntrExtLInValidReason='Required';
    }
    else if(!String(this.cntrdetailsadd.cntr_exterior_l).includes('.'))
      {
         /*if( String(this.cntrdetailsadd.cntr_exterior_l).length>7)
          {
            this.cntrExtLInValid=true;
            this.cntrExtLInValidReason="Max Length Of Characters can be 7";
          }
          else */
          if(this.cntrdetailsadd.cntr_exterior_l<=0 || this.cntrdetailsadd.cntr_exterior_l>999.999 )
          {
            this.cntrExtLInValid=true;
            this.cntrExtLInValidReason='Should be of range 0 to 999.999';
          }
        else
          {
            this.cntrExtLInValid=false;
            this.cntrExtLInValidReason=null;
          }
      }
      else if(String(this.cntrdetailsadd.cntr_exterior_l).includes('.'))
      {
          if(String(cont_ext_l[0]).length<0 || String(cont_ext_l[0]).length>3)
          {
            this.cntrExtLInValid=true;
            this.cntrExtLInValidReason='Should be of range 0 to 999.999';
          }
          else if(this.cntrdetailsadd.cntr_exterior_l<=0 || this.cntrdetailsadd.cntr_exterior_l>999.999 )
          {
            this.cntrExtLInValid=true;
            this.cntrExtLInValidReason='Should be of range 0 to 999.999';
          }
          else if(String(cont_ext_l[1]).length<0 || String(cont_ext_l[1]).length>3)
          {
            this.cntrExtLInValid=true;
            this.cntrExtLInValidReason='Max Length Of decimal values can be 3';
          }
          else
          {
            this.cntrExtLInValid=false;
            this.cntrExtLInValidReason=null;
          }
      }
      else
      {
      this.cntrExtLInValid=false;
      this.cntrExtLInValidReason=null;
      }
  }
}
validateCntrExtWForAdd()
{
  if(!this.isCmmsLoadedContainer)
  {
    const cont_ext_w = String(this.cntrdetailsadd.cntr_exterior_w).split('.');
    if(this.cntrdetailsadd.cntr_exterior_w==null || this.cntrdetailsadd.cntr_exterior_w==0)
    {
      this.cntrExtWInValid=true;
      this.cntrExtWInValidReason='Required';
    }
    else if(!String(this.cntrdetailsadd.cntr_exterior_w).includes('.'))
    {
        /*if( String(this.cntrdetailsadd.cntr_exterior_w).length>7)
        {
          this.cntrExtWInValid=true;
          this.cntrExtWInValidReason="Max Length Of Characters can be 7";
        }
        else*/
        if(this.cntrdetailsadd.cntr_exterior_w<=0 || this.cntrdetailsadd.cntr_exterior_w>999.999 )
        {
          this.cntrExtWInValid=true;
          this.cntrExtWInValidReason='Should be of range 0 to 999.999';
        }
      else
        {
          this.cntrExtWInValid=false;
          this.cntrExtWInValidReason=null;
        }
    }
    else if(String(this.cntrdetailsadd.cntr_exterior_w).includes('.'))
    {
        if(String(cont_ext_w[0]).length<0 || String(cont_ext_w[0]).length>3)
        {
          this.cntrExtWInValid=true;
          this.cntrExtWInValidReason='Should be of range 0 to 999.999';
        }
        else if(this.cntrdetailsadd.cntr_exterior_w<=0 || this.cntrdetailsadd.cntr_exterior_w>999.999 )
        {
          this.cntrExtWInValid=true;
          this.cntrExtWInValidReason='Should be of range 0 to 999.999';
        }
        else if(String(cont_ext_w[1]).length<0 || String(cont_ext_w[1]).length>3)
        {
          this.cntrExtWInValid=true;
          this.cntrExtWInValidReason='Max Length Of decimal values can be 3';
        }

        else
        {
          this.cntrExtWInValid=false;
          this.cntrExtWInValidReason=null;
        }
    }
    else
    {
    this.cntrExtWInValid=false;
    this.cntrExtWInValidReason=null;
    }
  }
}
validateCntrExtHForAdd()
{
  if(!this.isCmmsLoadedContainer)
  {
    const cont_ext_h = String(this.cntrdetailsadd.cntr_exterior_h).split('.');
    if(this.cntrdetailsadd.cntr_exterior_h==null || this.cntrdetailsadd.cntr_exterior_h==0)
    {
      this.cntrExtHInValid=true;
      this.cntrExtHInValidReason='Required';
    }
    else if(!String(this.cntrdetailsadd.cntr_exterior_h).includes('.'))
      {
      if(this.cntrdetailsadd.cntr_exterior_h<=0 || this.cntrdetailsadd.cntr_exterior_h>999.999 )
          {
            this.cntrExtHInValid=true;
            this.cntrExtHInValidReason='Should be of range 0 to 999.999';
          }
          /*else if( String(this.cntrdetailsadd.cntr_exterior_h).length>7)
          {
            this.cntrExtHInValid=true;
            this.cntrExtHInValidReason="Max Length Of Characters can be 7";
          }*/
          else
          {
            this.cntrExtHInValid=false;
            this.cntrExtHInValidReason=null;
          }
      }
      else if(String(this.cntrdetailsadd.cntr_exterior_h).includes('.'))
      {
          if(String(cont_ext_h[0]).length<0 || String(cont_ext_h[0]).length>3)
          {
            this.cntrExtHInValid=true;
            this.cntrExtHInValidReason='Should be of range 0 to 999.999';
          }
          else if(this.cntrdetailsadd.cntr_exterior_h<=0 || this.cntrdetailsadd.cntr_exterior_h>999.999 )
          {
            this.cntrExtHInValid=true;
            this.cntrExtHInValidReason='Should be of range 0 to 999.999';
          }
          else if(String(cont_ext_h[1]).length<0 || String(cont_ext_h[1]).length>3)
          {
            this.cntrExtHInValid=true;
            this.cntrExtHInValidReason='Max Length Of decimal values can be 3';
          }
          else
          {
            this.cntrExtHInValid=false;
            this.cntrExtHInValidReason=null;
          }
      }
      else
      {
      this.cntrExtHInValid=false;
      this.cntrExtHInValidReason=null;
      }
  }
}
validateCntrIntLForAdd()
{
  if(this.cntrdetailsadd.cntr_interior_l!=null && (this.cntrdetailsadd.cntr_interior_l>99999))
  {
    this.cntrIntLInValid=true;
    this.cntrIntLInValidReason='Should be of range 1 to 99999';
  }
  else if(this.cntrdetailsadd.cntr_interior_l!=null && ( this.cntrdetailsadd.cntr_interior_l>this.cntrdetailsadd.cntr_exterior_l))
  {
    this.cntrIntLInValid=true;
    this.cntrIntLInValidReason='Interior Length cannot be Greater than Exterior Length';
  }
  else
  {
    this.cntrIntLInValid=false;
    this.cntrIntLInValidReason=null;
  }
}
validateCntrIntWForAdd()
{
  if(this.cntrdetailsadd.cntr_interior_w!=null && (this.cntrdetailsadd.cntr_interior_w>99999))
  {
    this.cntrIntWInValid=true;
    this.cntrIntWInValidReason='Should be of range 1 to 99999';
  }
  else if(this.cntrdetailsadd.cntr_interior_w!=null && (this.cntrdetailsadd.cntr_interior_w>this.cntrdetailsadd.cntr_exterior_w))
  {
    this.cntrIntWInValid=true;
    this.cntrIntWInValidReason='Interior width cannot be Greater than Exterior width';
  }
  else
  {
    this.cntrIntWInValid=false;
    this.cntrIntWInValidReason=null;
  }
}
validateCntrIntHForAdd()
{
  if(this.cntrdetailsadd.cntr_interior_h!=null && (this.cntrdetailsadd.cntr_interior_h>99999))
  {
    this.cntrIntHInValid=true;
    this.cntrIntHInValidReason='Should be of range 1 to 99999';
  }
  else if(this.cntrdetailsadd.cntr_interior_h!=null && (this.cntrdetailsadd.cntr_interior_h>this.cntrdetailsadd.cntr_exterior_h))
  {
    this.cntrIntHInValid=true;
    this.cntrIntHInValidReason='Interior height cannot be Greater than Exterior height';
  }
  else
  {
    this.cntrIntHInValid=false;
    this.cntrIntHInValidReason=null;
  }
}
validateSuNestedFldedHtForAdd()
{
  /* if(this.cntrdetailsadd.shpg_unit_nstdorflded_ht==null || this.cntrdetailsadd.shpg_unit_nstdorflded_ht==0)
  {
    this.suNestedHtInValid=true;
    this.suNestedHtInValidReason="Required";
  }
  else */
   if(this.cntrdetailsadd.shpg_unit_nstdorflded_ht<0 || this.cntrdetailsadd.shpg_unit_nstdorflded_ht>99999)
  {
    this.suNestedHtInValid=true;
    this.suNestedHtInValidReason='Should be of range 0 to 99999';
  }
  else if(this.cntrdetailsadd.shpg_unit_exterior_h>0 && this.cntrdetailsadd.shpg_unit_nstdorflded_ht>0 && this.cntrdetailsadd.shpg_unit_nstdorflded_ht>this.cntrdetailsadd.shpg_unit_exterior_h)
  {
    this.suNestedHtInValid=true;
    this.suNestedHtInValidReason='Nested Folded Height cannot be Greater than Exterior height';
  }
  else
  {
    this.suNestedHtInValid=false;
    this.suNestedHtInValidReason=null;
  }
}
validateSuTareWeightForAdd()
{
  const shp_tar_wt = String(this.cntrdetailsadd.shpg_unit_tare_wt).split('.');
  if(this.cntrdetailsadd.shpg_unit_tare_wt==null || this.cntrdetailsadd.shpg_unit_tare_wt==0)
  {
    this.suTareWtInvalid=true;
    this.suTareWtInValidReason='Shipping Unit Tare weight can not be 0';
  }
  else if(!String(this.cntrdetailsadd.shpg_unit_tare_wt).includes('.'))
  {

      if( String(this.cntrdetailsadd.shpg_unit_tare_wt).length>10)
      {
        this.suTareWtInvalid=true;
        this.suTareWtInValidReason='Max Length Of Characters can be 10';
      }
      else if(this.cntrdetailsadd.shpg_unit_tare_wt<1 || this.cntrdetailsadd.shpg_unit_tare_wt>999999.999)
      {
        this.suTareWtInvalid=true;
        this.suTareWtInValidReason='Should be of range 1 to 999999.999';
      }
    else
      {
        this.suTareWtInvalid=false;
        this.suTareWtInValidReason=null;
      }
  }
  else if(String(this.cntrdetailsadd.shpg_unit_tare_wt).includes('.'))
  {
      if(String(shp_tar_wt[0]).length<0 || String(shp_tar_wt[0]).length>6)
      {
        this.suTareWtInvalid=true;
        this.suTareWtInValidReason='Should be of range 1 to 999999.999';
      }
      else if(String(shp_tar_wt[1]).length<0 || String(shp_tar_wt[1]).length>3)
      {
        this.suTareWtInvalid=true;
        this.suTareWtInValidReason='Max Length Of decimal values can be 3';
      }
      else
      {
        this.suTareWtInvalid=false;
        this.suTareWtInValidReason=null;
      }
  }
  else
  {
  this.suTareWtInvalid=false;
  this.suTareWtInValidReason=null;
  }
}
validateWareHourseStckHtForAdd()
{
  if(!this.isCmmsLoadedContainer)
  {
    if(this.cntrdetailsadd.warehouse_stack_ht==null || this.cntrdetailsadd.warehouse_stack_ht==0)
    {
      this.wareHourseStackHtInvaid=true;
      this.wareHourseStackHtInvaidReason='Required';
    }
    else if(this.cntrdetailsadd.warehouse_stack_ht<1 || this.cntrdetailsadd.warehouse_stack_ht>99 || String(this.cntrdetailsadd.warehouse_stack_ht).includes('.'))
    {
      this.wareHourseStackHtInvaid=true;
      this.wareHourseStackHtInvaidReason='Should be of range 1 to 99 and cannot contain Decimal';
    }
    else
    {
      this.wareHourseStackHtInvaid=false;
      this.wareHourseStackHtInvaidReason=null;
    }
  }
}
validateShippingUnitStackHtForAdd()
{
  if(!this.isCmmsLoadedContainer)
  {
    if(this.cntrdetailsadd.shpg_unit_stack_ht==null || this.cntrdetailsadd.shpg_unit_stack_ht==0)
    {
      this.suStackHtInvalid=true;
      this.suStackHtInvalidReason='Required';
    }
    else if(this.cntrdetailsadd.shpg_unit_stack_ht<1 || this.cntrdetailsadd.shpg_unit_stack_ht>9 || String(this.cntrdetailsadd.shpg_unit_stack_ht).includes('.'))
    {
      this.suStackHtInvalid=true;
      this.suStackHtInvalidReason='Should be of range 1 to 9 and cannot contain Decimal';
    }
    else
    {
      this.suStackHtInvalid=false;
      this.suStackHtInvalidReason=null;
    }
  }

}

validateSUExtLForAdd()
{
  const shipunit_ext_l = String(this.cntrdetailsadd.shpg_unit_exterior_l).split('.');
  /*if(this.cntrdetailsadd.shpg_unit_exterior_l<this.cntrdetailsadd.cntr_exterior_l)
    {
      this.suExtLInValid=true;
      alert("came here")
      this.suExtLInValidReason="Shipping Unit Exterior Length cannot be less than Container Exterior Length";
    }
  }*/

  if(!String(this.cntrdetailsadd.shpg_unit_exterior_l).includes('.'))
  {
    /* if(this.cntrdetailsadd.shpg_unit_exterior_l==null || this.cntrdetailsadd.shpg_unit_exterior_l==0)
    {
      this.suExtLInValid=true;
      this.suExtLInValidReason="Required";
    }
    else */

     if( String(this.cntrdetailsadd.shpg_unit_exterior_l).length>8)
    {
      this.suExtLInValid=true;
      this.suExtLInValidReason='Max Length Of Characters can be 8';
    }

    else if(this.cntrdetailsadd.shpg_unit_exterior_l<=0 || this.cntrdetailsadd.shpg_unit_exterior_l>9999.999 )
    {
      this.suExtLInValid=true;
      this.suExtLInValidReason='Should be of range 0 to 9999.999';
    }

    else if(this.cntrdetailsadd.shpg_unit_exterior_l>0 && this.cntrdetailsadd.shpg_unit_exterior_l<this.cntrdetailsadd.cntr_exterior_l)
    {
      this.suExtLInValid=true;
      this.suExtLInValidReason='Shipping Unit Exterior Length cannot be less than Container Exterior Length';
    }
    else
    {
      this.suExtLInValid=false;
      this.suExtLInValidReason=null;
    }
  }
  else if(String(this.cntrdetailsadd.shpg_unit_exterior_l).includes('.'))
  {
    if(String(shipunit_ext_l[0]).length<0 || String(shipunit_ext_l[0]).length>4)
    {
      this.suExtLInValid=true;
      this.suExtLInValidReason='Should be of range 0 to 9999.999';
    }
    else if(this.cntrdetailsadd.shpg_unit_exterior_l<=0 || this.cntrdetailsadd.shpg_unit_exterior_l>9999.999 )
    {
      this.suExtLInValid=true;
      this.suExtLInValidReason='Should be of range 0 to 9999.999';
    }
    else if(String(shipunit_ext_l[1]).length<0 || String(shipunit_ext_l[1]).length>3)
    {
      this.suExtLInValid=true;
      this.suExtLInValidReason='Max Length Of decimal values can be 3';
    }
    else if(this.cntrdetailsadd.shpg_unit_exterior_l>0  && this.cntrdetailsadd.shpg_unit_exterior_l<this.cntrdetailsadd.cntr_exterior_l)
    {
      this.suExtLInValid=true;
      this.suExtLInValidReason='Shipping Unit Exterior Length cannot be less than Container Exterior Length';
    }
    else
    {
      this.suExtLInValid=false;
      this.suExtLInValidReason=null;
    }
  }
  else
  {
    this.suExtLInValid=false;
    this.suExtLInValidReason=null;
  }
}
validateSUExtWForAdd()
{
  const shipunit_ext_w = String(this.cntrdetailsadd.shpg_unit_exterior_w).split('.');
  /* if(this.cntrdetailsadd.shpg_unit_exterior_w<this.cntrdetailsadd.cntr_exterior_w)
      {
        this.suExtWInValid=true;
        this.suExtWInValidReason="Shipping Unit Exterior Width cannot be less than Container Exterior Width";
      } */
  if(!String(this.cntrdetailsadd.shpg_unit_exterior_w).includes('.'))
  {
  /* if(this.cntrdetailsadd.shpg_unit_exterior_w==null || this.cntrdetailsadd.shpg_unit_exterior_w==0)
      {
        this.suExtWInValid=true;
        this.suExtWInValidReason="Required";
      }
      else */

      if(this.cntrdetailsadd.shpg_unit_exterior_w<=0 || this.cntrdetailsadd.shpg_unit_exterior_w>9999.999 )
      {
        this.suExtWInValid=true;
        this.suExtWInValidReason='Should be of range 0 to 9999.999';
      }
      else if( String(this.cntrdetailsadd.shpg_unit_exterior_w).length>8)
      {
        this.suExtWInValid=true;
        this.suExtWInValidReason='Max Length Of Characters can be 8';
      }
      else if(this.cntrdetailsadd.shpg_unit_exterior_w>0  && this.cntrdetailsadd.shpg_unit_exterior_w<this.cntrdetailsadd.cntr_exterior_w)
      {
        this.suExtWInValid=true;
        this.suExtWInValidReason='Shipping Unit Exterior Width cannot be less than Container Exterior Width';
      }
      else
      {
        this.suExtWInValid=false;
        this.suExtWInValidReason=null;
      }
  }
  else if(String(this.cntrdetailsadd.shpg_unit_exterior_w).includes('.'))
  {
      if(String(shipunit_ext_w[0]).length<0 || String(shipunit_ext_w[0]).length>4)
      {
        this.suExtWInValid=true;
        this.suExtWInValidReason='Should be of range 0 to 9999.999';
      }
      else if(this.cntrdetailsadd.shpg_unit_exterior_w<=0 || this.cntrdetailsadd.shpg_unit_exterior_w>9999.999 )
      {
        this.suExtWInValid=true;
        this.suExtWInValidReason='Should be of range 0 to 9999.999';
      }
      else if(String(shipunit_ext_w[1]).length<0 || String(shipunit_ext_w[1]).length>3)
      {
        this.suExtWInValid=true;
        this.suExtWInValidReason='Max Length Of decimal values can be 3';
      }
      else if(this.cntrdetailsadd.shpg_unit_exterior_w>0 && this.cntrdetailsadd.shpg_unit_exterior_w<this.cntrdetailsadd.cntr_exterior_w)
      {
        this.suExtWInValid=true;
        this.suExtWInValidReason='Shipping Unit Exterior Width cannot be less than Container Exterior Width';
      }
      else
      {
        this.suExtWInValid=false;
        this.suExtWInValidReason=null;
      }
  }
  else
  {
  this.suExtWInValid=false;
  this.suExtWInValidReason=null;
  }
}

validateSUExtHForAdd()
{

    const shipunit_ext_h = String(this.cntrdetailsadd.shpg_unit_exterior_h).split('.');
   /*  if(this.cntrdetailsadd.shpg_unit_exterior_h<this.cntrdetailsadd.cntr_exterior_h)
        {
          this.suExtHInValid=true;
          this.suExtHInValidReason="Shipping Unit Exterior Height cannot be less than Container Exterior Height";
        } */
    if(!String(this.cntrdetailsadd.shpg_unit_exterior_h).includes('.'))
    {
    /* if(this.cntrdetailsadd.shpg_unit_exterior_h==null || this.cntrdetailsadd.shpg_unit_exterior_h==0)
        {
          this.suExtHInValid=true;
          this.suExtHInValidReason="Required";
        }
        else */
        if(this.cntrdetailsadd.shpg_unit_exterior_h<=0 || this.cntrdetailsadd.shpg_unit_exterior_h>9999.999 )
        {
          this.suExtHInValid=true;
          this.suExtHInValidReason='Should be of range 0 to 9999.999';
        }
        else if( String(this.cntrdetailsadd.shpg_unit_exterior_h).length>8)
        {
          this.suExtHInValid=true;
          this.suExtHInValidReason='Max Length Of Characters can be 8';
        }

        else if(this.cntrdetailsadd.shpg_unit_exterior_h>0  && this.cntrdetailsadd.shpg_unit_exterior_h<this.cntrdetailsadd.cntr_exterior_h)
        {
          this.suExtHInValid=true;
          this.suExtHInValidReason='Shipping Unit Exterior Height cannot be less than Container Exterior Height';
        }
        else
        {
          this.suExtHInValid=false;
          this.suExtHInValidReason=null;
        }
    }
    else if(String(this.cntrdetailsadd.shpg_unit_exterior_h).includes('.'))
    {
        if(String(shipunit_ext_h[0]).length<0 || String(shipunit_ext_h[0]).length>4)
        {
          this.suExtHInValid=true;
          this.suExtHInValidReason='Should be of range 0 to 9999.999';
        }
        else if(this.cntrdetailsadd.shpg_unit_exterior_h<=0 || this.cntrdetailsadd.shpg_unit_exterior_h>9999.999 )
        {
          this.suExtHInValid=true;
          this.suExtHInValidReason='Should be of range 0 to 9999.999';
        }
        else if(String(shipunit_ext_h[1]).length<0 || String(shipunit_ext_h[1]).length>3)
        {
          this.suExtHInValid=true;
          this.suExtHInValidReason='Max Length Of decimal values can be 3';
        }
        else if(this.cntrdetailsadd.shpg_unit_exterior_h>0  &&  this.cntrdetailsadd.shpg_unit_exterior_h<this.cntrdetailsadd.cntr_exterior_h)
        {
          this.suExtHInValid=true;
          this.suExtHInValidReason='Shipping Unit Exterior Height cannot be less than Container Exterior Height';
        }
        else
        {
          this.suExtHInValid=false;
          this.suExtHInValidReason=null;
        }
    }
    else
    {
    this.suExtHInValid=false;
    this.suExtHInValidReason=null;
    }
}
// validateSUExtHForAdd()
// {
//   if(String(this.cntrdetailsadd.shpg_unit_exterior_h).includes("."))
//   {
//     console.log("Includes .");
//     let shipunit_ext_l = String(this.cntrdetailsadd.shpg_unit_exterior_h).split(".");
//     if(String(this.cntrdetailsadd.shpg_unit_exterior_h).split(".")){
//       console.log("Split .",String(shipunit_ext_l[0]).length,shipunit_ext_l[0]);
//       if(String(shipunit_ext_l[0]).length<0 || String(shipunit_ext_l[0]).length>4)
//       {
//         this.suExtHInValid=true;
//         this.suExtHInValidReason="Should be of range 1 to 9999.999";
//         console.log("Split Integer validation",shipunit_ext_l[0]);
//       }
//       else if(String(shipunit_ext_l[1]).length<0 || String(shipunit_ext_l[1]).length>3)
//       {
//         this.suExtHInValid=true;
//         this.suExtHInValidReason="Should be of range 1 to 9999.999";
//         console.log("Split Decimal validation",shipunit_ext_l[1]);
//       }
//       else
//       {
//         this.suExtHInValid=false;
//         this.suExtHInValidReason=null;
//         console.log("Split First else")
//       }
//     }
//      else if(this.cntrdetailsadd.shpg_unit_exterior_h==null || this.cntrdetailsadd.shpg_unit_exterior_h==0)
//       {
//         this.suExtHInValid=true;
//         this.suExtHInValidReason="Required";
//         console.log("Split Required")
//       }
//       else if(this.cntrdetailsadd.shpg_unit_exterior_h<1 || this.cntrdetailsadd.shpg_unit_exterior_h>9999.999 )
//       {
//         this.suExtHInValid=true;
//         this.suExtHInValidReason="Should be of range 1 to 9999.999";
//         console.log("Split Range")
//       }
//       else if(this.cntrdetailsadd.shpg_unit_exterior_h<this.cntrdetailsadd.cntr_exterior_h)
//       {
//         this.suExtHInValid=true;
//         this.suExtHInValidReason="Shipping Unit Exterior Height cannot be less than Container Exterior Height";
//       }
//       else if( String(this.cntrdetailsadd.shpg_unit_exterior_h).length>9)
//       {
//         this.suExtHInValid=true;
//         this.suExtHInValidReason="Max Length Of Characters can be 8";
//         console.log("Split Total")
//       }
//       else
//       {
//         this.suExtHInValid=false;
//         this.suExtHInValidReason=null;
//         console.log("Split End Else")
//       }

//   }
//    if(this.cntrdetailsadd.shpg_unit_exterior_h==null || this.cntrdetailsadd.shpg_unit_exterior_h==0)
//    {
//      this.suExtHInValid=true;
//      this.suExtHInValidReason="Required";
//    }
//    else if(this.cntrdetailsadd.shpg_unit_exterior_h<1 || this.cntrdetailsadd.shpg_unit_exterior_h>9999.999 )
//    {
//      this.suExtHInValid=true;
//      this.suExtHInValidReason="Should be of range 1 to 9999.999";
//    }
//    else if(this.cntrdetailsadd.shpg_unit_exterior_h<this.cntrdetailsadd.cntr_exterior_h)
//    {
//      this.suExtHInValid=true;
//      this.suExtHInValidReason="Shipping Unit Exterior Height cannot be less than Container Exterior Height";
//    }
//    else if( String(this.cntrdetailsadd.shpg_unit_exterior_h).length>9)
//    {
//      this.suExtHInValid=true;
//      this.suExtHInValidReason="Max Length Of Characters can be 8";
//    }
//    else if(!((this.cntrdetailsadd.shpg_unit_exterior_h).toFixed(3)))
//    {
//      this.suExtHInValid=true;
//      this.suExtHInValidReason="Max Length Of decimal values can be 3";
//    }
//    else
//    {
//      this.suExtHInValid=false;
//      this.suExtHInValidReason=null;
//    }
// }

cntrdetails_display_popup_init()
{
  this.cntrdetails_display=
  {
    cd_cntr:0,
    cntr_suff_ind:'',
    exp_refundable:'',
    cntr_active_ind:'',
    cntr_base:'',
    cntr_suffix:'',
    cntr_desc:'',
    card_call:'',
    cntr_mstr_catg:'',
    cntr_typ_desc:'',
    ownrshp_strat_code:'',
    ownrshp_catg:'',
    cntr_grp_desc:'',
    cntr_exterior_l:0,
    cntr_exterior_w:0,
    cntr_exterior_h:0,
    collapsed_ht:0.0,
    nest_ratio:0,
    cntr_interior_l:0,
    cntr_interior_w:0,
    cntr_interior_h:0,
    cntr_tare_wt:0,
    cntr_capacity:0,
    cntr_per_layer:0,
    max_layers_shpg_unit:0,
    max_cntr_per_shpg_unit:0,
    cntr_matr_desc:'',
    cntr_drawing_no:'',
    shpg_unit_exterior_l:0,
    shpg_unit_exterior_w:0,
    shpg_unit_exterior_h:0,
    shpg_unit_nstdorflded_ht:0,
    shpg_unit_tare_wt:0,
    shpg_unit_stack_ht:0,
    warehouse_stack_ht:0,
    service_life:'',
    currency_desc:'',
    purchase_cost:0,
    rental_issue_cost:0,
    sup_rntl_dly_cost:0,
    ford_rntl_dly_cost:0,
    return_rntl_cost:0,
    tooling_dev_cost:0,
    extr_cntr_dsgn_img_ind:'',
    intr_cntr_dsgn_img_ind:'',
    shpg_unit_img_ind:'',
    cad_img_ind:'',
    img_notes:'',
    cd_region:'',
    iscontainercolapsble:'',
    cd_plant: '',
    cnt_qt_per_min_su: 0,
    nest_ratio_1: 0,
    ford_cntct: '',
    cntct_phone: '',
    req_stat: '',
    id_user: '',
    ts_last_update: '',
    uom:'E',
    typ_no:0
  }
}

copyCntrDetails()
{
  this.select_contcos_dialog=true;

  if(this.containerDetailsCopied)
  {
    this.cont_retype=this.tableSelectedReturnType;
    for(const i of this.cntrOwnershipstratcodelist)
    {
      if(i.ownrshp_strat_code==this.table_selected_cntr_ownrship_strat_code_in_popup.ownrshp_strat_code)
      {
        this.selected_cntr_ownrship_strat_code_in_popup=this.table_selected_cntr_ownrship_strat_code_in_popup;
      }
    }

  }
  else
  {
    this.cont_retype='Returnable';
    this.selected_cntr_ownrship_strat_code_in_popup=this.cntrOwnershipstratcodelist[0];
    this.selected_container=null;
  }

  this.loadContainersInPopUp();

}

onclick_ok_select_contandcos()
{
  this.select_contcos_dialog=false;
  this.containerDetailsCopied=true;
  this.isCmmsLoadedContainer=false;

  this.table_selected_container=this.selected_container;
  this.tableSelectedReturnType=this.cont_retype;
  this.table_selected_cntr_ownrship_strat_code_in_popup=this.selected_cntr_ownrship_strat_code_in_popup;

  this.transactionService.editbutton_get_contr_details(this.table_selected_container.cntr_base,this.table_selected_container.cd_cntr).subscribe(cntval=>{
    this.cntrDetailsForCopying=cntval;

    this.cntrdetailsadd.cntr_base='';
    this.cntrdetailsadd.cntr_suffix='';
    this.cntrdetailsadd.cntr_suff_ind='';
    this.cntrSuffixAdd='';
    this.cntrSuffixEnabled=false;
    this.selectedSuffixList={suppliergsdbcode:''};
    this.disableCntrDetailsWhenBaseOrSuffixChanged=true;
    this.prevCntrSuffixInd=null;
    this.enteredCntrBase=null;
    this.addcntrdetailsinit();

    // this.cntrdetailsadd.function:'',
    // this.cntrdetailsadd.cntr_suff_ind=this.cntrDetailsForCopying.,
    // this.cntrdetailsadd.exp_refundable=this.cntrDetailsForCopying.exp_refundable;
    this.cntrdetailsadd.cntr_active_ind=this.cntrDetailsForCopying.cntr_active_ind;
    // this.cntrdetailsadd.cntr_base=this.cntrDetailsForCopying.,
    // this.cntrdetailsadd.cntr_suffix=this.cntrDetailsForCopying.,
    // this.cntrdetailsadd.cntr_desc=this.cntrDetailsForCopying.
    this.cntrdetailsadd.card_call=this.cntrDetailsForCopying.card_call;
    if(this.cntrDetailsForCopying.cntr_mstr_catg!=null && this.cntrDetailsForCopying.cntr_mstr_catg!='')
    {
      for(const mc of this.cntrMasterCategory)
      {
        if(mc.cntr_mstr_catg.toLowerCase()==this.cntrDetailsForCopying.cntr_mstr_catg.toLowerCase())
        {
          this.cntrdetailsadd.cntr_mstr_catg=this.cntrDetailsForCopying.cntr_mstr_catg;
          this.selected_cntr_master_category=mc;
          break;
        }
      }
    }
    this.selected_ownrship_catg=this.ownrship_catg[0];
    this.cntrdetailsadd.ownrshp_catg=this.selected_ownrship_catg.ownrshp_catg_val;

    this.selected_cntr_type_code=this.cntrTypeCode[0];
    this.cntrdetailsadd.cntr_typ_desc=this.selected_cntr_type_code.cntr_typ_desc;
    this.cntrdetails.typ_no=this.selected_cntr_type_code.typ_no
    if(this.cntrDetailsForCopying.cntr_grp_desc!=null && this.cntrDetailsForCopying.cntr_grp_desc!='')
    {
      for(const cg of this.cntrGrp)
      {
        if(cg.cntr_grp.toLowerCase()==this.cntrDetailsForCopying.cntr_grp_desc.toLowerCase())
        {
          this.cntrdetailsadd.cntr_grp_desc=this.cntrDetailsForCopying.cntr_grp_desc;
          this.selected_cntr_group=cg;
          break;
        }
      }
    }

    this.cntrdetailsadd.cntr_exterior_l=this.cntrDetailsForCopying.cntr_exterior_l
    this.cntrdetailsadd.cntr_exterior_w=this.cntrDetailsForCopying.cntr_exterior_w
    this.cntrdetailsadd.cntr_exterior_h=this.cntrDetailsForCopying.cntr_exterior_h
    this.cntrdetailsadd.collapsed_ht=this.cntrDetailsForCopying.collapsed_ht
    this.cntrdetailsadd.nest_ratio=this.cntrDetailsForCopying.nest_ratio
    this.cntrdetailsadd.cntr_interior_l=this.cntrDetailsForCopying.cntr_interior_l
    this.cntrdetailsadd.cntr_interior_w=this.cntrDetailsForCopying.cntr_interior_w
    this.cntrdetailsadd.cntr_interior_h=this.cntrDetailsForCopying.cntr_interior_h
    this.cntrdetailsadd.cntr_tare_wt=this.cntrDetailsForCopying.cntr_tare_wt
    this.cntrdetailsadd.cntr_capacity=this.cntrDetailsForCopying.cntr_capacity
    this.cntrdetailsadd.cntr_per_layer=this.cntrDetailsForCopying.cntr_per_layer
    this.cntrdetailsadd.max_layers_shpg_unit=this.cntrDetailsForCopying.max_layers_shpg_unit
    this.cntrdetailsadd.max_cntr_per_shpg_unit=this.cntrDetailsForCopying.max_cntr_per_shpg_unit
    // this.cntrdetailsadd.cntr_matr_desc=this.cntrDetailsForCopying.
    this.cntrdetailsadd.cntr_drawing_no=this.cntrDetailsForCopying.cntr_drawing_no
    this.cntrdetailsadd.shpg_unit_exterior_l=this.cntrDetailsForCopying.shpg_unit_exterior_l
    this.cntrdetailsadd.shpg_unit_exterior_w=this.cntrDetailsForCopying.shpg_unit_exterior_w
    this.cntrdetailsadd.shpg_unit_exterior_h=this.cntrDetailsForCopying.shpg_unit_exterior_h
    this.cntrdetailsadd.shpg_unit_nstdorflded_ht=this.cntrDetailsForCopying.shpg_unit_nstdorflded_ht,
    this.cntrdetailsadd.shpg_unit_tare_wt=this.cntrDetailsForCopying.shpg_unit_tare_wt
    this.cntrdetailsadd.shpg_unit_stack_ht=this.cntrDetailsForCopying.shpg_unit_stack_ht
    this.cntrdetailsadd.warehouse_stack_ht=this.cntrDetailsForCopying.warehouse_stack_ht
    this.cntrdetailsadd.service_life=this.cntrDetailsForCopying.service_life
    this.cntrdetailsadd.currency_desc=this.cntrDetailsForCopying.currency_desc
    this.cntrdetailsadd.purchase_cost=this.cntrDetailsForCopying.purchase_cost
    this.cntrdetailsadd.rental_issue_cost=this.cntrDetailsForCopying.rental_issue_cost
    this.cntrdetailsadd.sup_rntl_dly_cost=this.cntrDetailsForCopying.sup_rntl_dly_cost
    this.cntrdetailsadd.ford_rntl_dly_cost=this.cntrDetailsForCopying.ford_rntl_dly_cost
    this.cntrdetailsadd.return_rntl_cost=this.cntrDetailsForCopying.return_rntl_cost
    this.cntrdetailsadd.tooling_dev_cost=this.cntrDetailsForCopying.tooling_dev_cost
    this.cntrdetailsadd.extr_cntr_dsgn_img_ind=this.cntrDetailsForCopying.extr_cntr_dsgn_img_ind
    this.cntrdetailsadd.intr_cntr_dsgn_img_ind=this.cntrDetailsForCopying.intr_cntr_dsgn_img_ind
    this.cntrdetailsadd.shpg_unit_img_ind=this.cntrDetailsForCopying.shpg_unit_img_ind
    this.cntrdetailsadd.cad_img_ind=this.cntrDetailsForCopying.cad_img_ind,
    this.cntrdetailsadd.img_notes=this.cntrDetailsForCopying.img_notes
    this.cntrdetailsadd.cd_region=this.current_region_code_prg;
    this.cntrdetailsadd.iscontainercolapsble=this.cntrDetailsForCopying.iscontainercolapsble;
    if(this.cntrDetailsForCopying.cd_plant!=null && this.cntrDetailsForCopying.cd_plant!='' )
    {
      for(const pc of this.plantList)
      {
        if(pc.plantGSDBCode.toLowerCase()==this.cntrDetailsForCopying.cd_plant.toLowerCase())
        {
          this.cntrdetailsadd.cd_plant=this.cntrDetailsForCopying.cd_plant;
          this.selectedPlantList=pc;
          break;
        }
      }
    }

    this.cntrdetailsadd.cntr_qt_per_min_su=this.cntrDetailsForCopying.cnt_qt_per_min_su
    this.cntrdetailsadd.nest_ratio_1=this.cntrDetailsForCopying.nest_ratio_1


    this.cntrdetailsadd.ford_cntct=this.cntrDetailsForCopying.ford_cntct;
    // this.cntrdetailsadd.cntct_phone=this.cntrDetailsForCopying.
    this.cntrdetailsadd.id_user=this.transactionService.getcdsid();
    this.cntrdetailsadd.ts_last_update=this.date.transform(new Date(),'yyyy-MM-dd');
    this.cntrdetailsadd.req_stat='open';
    this.cntrdetailsadd.components=[{
      mp_function:'',
      no_comp_base:'',
      no_comp_suffix:'',
      ds_comp:'',
      comp_qt_per_min_su:0,
      comp_cd_owner:'',
      in_comp:'',
      comp_cd_type:''
    }],
    // this.cntrdetailsadd.UoM='E';
    this.cntrdetailsadd.dbia_req_no=0;
    this.cntrValidationForAlreadyInvalidFields();
    console.log('container details from container admin on pop up',this.cntrDetailsForCopying);
    if(this.cntrdetailsadd.cntr_base!=null && this.cntrdetailsadd.cntr_suff_ind!=null && this.cntrdetailsadd.cntr_base.trim()!='' && this.cntrdetailsadd.cntr_suff_ind!='')
    {
      this.cntrSuffixIndchangedadd();
    }
  })
}

onclick_cancel_select_contandcos()
{
  this.select_contcos_dialog=false;
  this.selected_container=null;
  this.cont_retype='Returnable';
  this.selected_cntr_ownrship_strat_code_in_popup=this.cntrOwnershipstratcodelist[0];
}

on_change_cos_dropdown()
{
  this.selected_container=null;
  this.loadContainersInPopUp();
}

onChangeExpRet()
{
  this.selected_container=null;
  this.loadContainersInPopUp();
}

loadContainersInPopUp()
{
  console.log(this.containerDetailsCopied)

  this.transactionService.getcontoncoslist(this.selected_cntr_ownrship_strat_code_in_popup.ownrshp_strat_code,this.current_region_code_prg,this.cont_retype,'all').subscribe(stdcntrs=>{
    this.contlist_oncos=stdcntrs;
    console.log('successful',this.contlist_oncos);
    if(this.contlist_oncos.length!=0 && this.contlist_oncos!=null)
    {
      this.cont_grid_text='';
      this.cont_present_table=true;
      for(let i=0;i<this.contlist_oncos.length;i++)
      {

        if(this.contlist_oncos[i].cntr_suffix!=null)
        {
        this.contlist_oncos[i].cntr_base_suffix=this.contlist_oncos[i].cntr_base+' - '+this.contlist_oncos[i].cntr_suffix
        }
        else
        {
          this.contlist_oncos[i].cntr_base_suffix=this.contlist_oncos[i].cntr_base
        }
        let length='';
        let width='';
        let height='';
        if(this.contlist_oncos[i].cntr_exterior_l!=null)
        {
          length=this.contlist_oncos[i].cntr_exterior_l.toString();
        }
        if(this.contlist_oncos[i].cntr_exterior_w!=null)
        {
          width=this.contlist_oncos[i].cntr_exterior_w.toString();
        }
        if(this.contlist_oncos[i].cntr_exterior_l!=null)
        {
          height=this.contlist_oncos[i].cntr_exterior_h.toString();
        }

        this.contlist_oncos[i].external_l_w_h=length+'x'+width+'x'+height;

        console.log(this.containerDetailsCopied)

      }
      if(this.containerDetailsCopied && this.table_selected_cntr_ownrship_strat_code_in_popup==this.selected_cntr_ownrship_strat_code_in_popup && this.tableSelectedReturnType==this.cont_retype)
      {
        this.selected_container=this.table_selected_container;
      }
      this.cont_cols = [
        { field: 'cntr_base_suffix', header:'Container Base - Suffix'},
        {field:'cntr_desc',header:'Container Description'},
        { field: 'exp_refundable', header:'E/R'},
        { field: 'external_l_w_h', header:'External LxWxH'},
        { field: 'card_call', header:'Card or Call'},
        { field: 'cntr_grp_desc', header:'Container Group ID'},
      ];
    }
    else
    {
      this.cont_present_table=false;
      this.cont_cols=[];
      this.cont_grid_text='No Container Found'
    }
  })
}

on_click_cntrdetails(cntrdata:any)
{

  // this.get_measure_cntr_details="lbs/inch";
  console.log(cntrdata);

  this.transactionService.editbutton_get_contr_details(cntrdata.cntr_base,cntrdata.cd_cntr).subscribe(cntval=>{
    this.cntrdetails_display=cntval;
    console.log(this.cntrdetails_display)

    if(this.cntrdetails_display.uom!=null && this.cntrdetails_display.uom.toLowerCase()=='e')
    {
      this.get_measure_cntr_details='lbs/inch';
    }
    else if(this.cntrdetails_display.uom!=null && this.cntrdetails_display.uom.toLowerCase()=='m')
    {
      this.get_measure_cntr_details='kg/cm';
    }
    else
    {
      this.get_measure_cntr_details=this.get_measure
    }
    this.enabledetails_cntr=true;
    console.log('container details from container admin on pop up',this.cntrdetails_display)
  })
}

cntrBaseChanged()
{
  // API call to check whether the container exist in CMMS , if yes make request_status as "c" else make it as 'A'
  // disable all the field until api returns output

  if(this.enteredCntrBase!=null)
  {
    if(this.enteredCntrBase.trim()!=this.cntrdetailsadd.cntr_base.trim())
      {
        this.enteredCntrBase=this.cntrdetailsadd.cntr_base;

        if(this.containerDetailsCopied==false)
        {
          this.disableCntrDetailsWhenBaseOrSuffixChanged=true;
          // this.clearCntrDetailsExceptBaseAndSuffixAndPlantCode();
        }

        if(this.cntrdetailsadd.cntr_suff_ind.toLowerCase()=='no')
        {
          this.cntrSuffixAdd='%20%20%20%20%20%20%20%20'
          this.cntrdetailsadd.cntr_suffix='';
          this.selectedSuffixList={suppliergsdbcode:''}
        }

        if(this.cntrdetailsadd.cntr_base!=null && this.cntrdetailsadd.cntr_base.trim()!='' && ((this.cntrdetailsadd.cntr_suff_ind.toLowerCase()=='yes' && this.cntrdetailsadd.cntr_suffix != null && this.cntrdetailsadd.cntr_suffix.trim() != '') || this.cntrdetailsadd.cntr_suff_ind.toLowerCase()=='no'))
        {
          this.filterByCntrBaseAndSuffix()
        }
    }

  }
  else  // This case will be exceuted when user enters base for the first time
  {
    this.enteredCntrBase=this.cntrdetailsadd.cntr_base;
    this.disableCntrDetailsWhenBaseOrSuffixChanged=true;
    if(this.containerDetailsCopied==false)
    {
      // this.clearCntrDetailsExceptBaseAndSuffixAndPlantCode();
    }
    if(this.cntrdetailsadd.cntr_suff_ind.toLowerCase()=='no')
    {
      this.cntrSuffixAdd='%20%20%20%20%20%20%20%20'
      this.cntrdetailsadd.cntr_suffix='';
      this.selectedSuffixList={suppliergsdbcode:''};
    }

    if(this.cntrdetailsadd.cntr_base!=null && this.cntrdetailsadd.cntr_base.trim()!='' && ((this.cntrdetailsadd.cntr_suff_ind.toLowerCase()=='yes' && this.cntrdetailsadd.cntr_suffix != null && this.cntrdetailsadd.cntr_suffix.trim() != '')  || this.cntrdetailsadd.cntr_suff_ind.toLowerCase()=='no'))
    {
      this.filterByCntrBaseAndSuffix()
     // this.cntrsuffixchangedadd()
    }
  }


}

cntrSuffixIndchangedadd()
{
  if(this.prevCntrSuffixInd!=null && this.prevCntrSuffixInd!=this.cntrdetailsadd.cntr_suff_ind)
  {
    if(this.cntrdetailsadd.cntr_suff_ind=='No')
    {
      console.log('cntrVal')
     this.cntrSuffixEnabled=false;
     this.cntrSuffixAdd='%20%20%20%20%20%20%20%20';
     this.cntrdetailsadd.cntr_suffix='';
     this.selectedSuffixList={suppliergsdbcode:''}
      console.log(this.compSuffixDisabled);

      if(this.containerDetailsCopied==false)
      {
      this.disableCntrDetailsWhenBaseOrSuffixChanged=true;
      // this.clearCntrDetailsExceptBaseAndSuffixAndPlantCode();
      }

      this.prevCntrSuffixInd=this.cntrdetailsadd.cntr_suff_ind;

      if(this.cntrdetailsadd.cntr_base!=null && this.cntrdetailsadd.cntr_base.trim()!='')
      {
        console.log('cntrVal')
        this.filterByCntrBaseAndSuffix();
      }
    }
    else
    {
      if(this.containerDetailsCopied==false)
      {
      this.disableCntrDetailsWhenBaseOrSuffixChanged=true;
      // this.clearCntrDetailsExceptBaseAndSuffixAndPlantCode();
      }

      this.cntrSuffixEnabled=true;
      this.prevCntrSuffixInd=this.cntrdetailsadd.cntr_suff_ind;
      console.log('cntrVal')
      if(this.cntrdetailsadd.cntr_base!=null && this.cntrdetailsadd.cntr_base.trim()!=''&& this.cntrdetailsadd.cntr_suffix != null && this.cntrdetailsadd.cntr_suffix.trim() != '')
      {
        console.log('cntrVal')
        this.prevCntrSuffixVal=this.cntrdetailsadd.cntr_suffix;
        this.cntrSuffixAdd=this.cntrdetailsadd.cntr_suffix
        this.filterByCntrBaseAndSuffix();
      }
    }



  }
  else if(this.prevCntrSuffixInd==null)  // executed when user selects suffix for first time
  {
    if(this.cntrdetailsadd.cntr_suff_ind=='No')
    {
      console.log('cntrVal')
     this.cntrSuffixEnabled=false;
      this.cntrSuffixAdd='%20%20%20%20%20%20%20%20';
      this.cntrdetailsadd.cntr_suffix='';
      this.selectedSuffixList={suppliergsdbcode:''}
      console.log(this.cntrSuffixEnabled);
      this.disableCntrDetailsWhenBaseOrSuffixChanged=true;

      if(this.containerDetailsCopied==false)
      {
        // this.clearCntrDetailsExceptBaseAndSuffixAndPlantCode();
      }
      this.prevCntrSuffixInd=this.cntrdetailsadd.cntr_suff_ind;

      if(this.cntrdetailsadd.cntr_base!=null && this.cntrdetailsadd.cntr_base.trim()!='')
      {
        console.log('cntrVal')
        this.filterByCntrBaseAndSuffix();
      }
    }
    else
    {
      this.disableCntrDetailsWhenBaseOrSuffixChanged=true;

      if(this.containerDetailsCopied==false)
      {
      // this.clearCntrDetailsExceptBaseAndSuffixAndPlantCode();
      }

      this.cntrSuffixEnabled=true;
      this.prevCntrSuffixInd=this.cntrdetailsadd.cntr_suff_ind;
      console.log('cntrVal')
      if(this.cntrdetailsadd.cntr_base!=null && this.cntrdetailsadd.cntr_base.trim()!=''&& this.cntrdetailsadd.cntr_suffix != null && this.cntrdetailsadd.cntr_suffix.trim() != '')
      {
        console.log('cntrVal')
        this.prevCntrSuffixVal=this.cntrdetailsadd.cntr_suffix;
        this.cntrSuffixAdd=this.cntrdetailsadd.cntr_suffix
        this.filterByCntrBaseAndSuffix();
      }
    }

  }
  /* if(this.cntrSuffixInvalid)
  {
    this.validateCompSuffixForAdd();
  } */
}

cntrsuffixchangedadd()
{
  this.cntrdetailsadd.cntr_suffix=this.selectedSuffixList.suppliergsdbcode
  console.log(this.cntrdetailsadd.cntr_suffix)

  if( this.cntrdetailsadd.cntr_suffix != '' && this.prevCntrSuffixVal!=null && this.prevCntrSuffixVal.trim().toLowerCase()!=this.cntrdetailsadd.cntr_suffix.trim().toLowerCase() )
  {

    this.prevCntrSuffixVal=this.cntrdetailsadd.cntr_suffix;
    this.cntrSuffixAdd=this.cntrdetailsadd.cntr_suffix;

    if(this.containerDetailsCopied==false)
    {
      this.disableCntrDetailsWhenBaseOrSuffixChanged=true;
      // this.clearCntrDetailsExceptBaseAndSuffixAndPlantCode();
    }

    if(this.cntrdetailsadd.cntr_base!=null && this.cntrdetailsadd.cntr_base.trim()!='')
    {
      this.filterByCntrBaseAndSuffix();
    }
  }
  else if(this.cntrdetailsadd.cntr_suffix != '' && this.prevCompSuffixVal==null)  // executed when user selects suffix for first time
  {

    this.disableCntrDetailsWhenBaseOrSuffixChanged=true;
    if(this.containerDetailsCopied==false)
    {
      // this.clearCntrDetailsExceptBaseAndSuffixAndPlantCode();
    }

    this.prevCntrSuffixVal=this.cntrdetailsadd.cntr_suffix;
    this.cntrSuffixAdd=this.cntrdetailsadd.cntr_suffix;

    if(this.cntrdetailsadd.cntr_base!=null && this.cntrdetailsadd.cntr_base.trim()!='')
    {
      this.filterByCntrBaseAndSuffix();
    }
  }

}

filterByCntrBaseAndSuffix()
{

 /*  this.selectedFromContainerAdmin=false;
  this.clearCntrRequestDetails(); */
  // alert(this.cntrdetailsadd.cntr_base+this.cntrdetailsadd.cntr_suff_ind+this.cntrdetailsadd.cntr_suffix)
  this.transactionService.getContainerDetailsFromCMMS(this.cntrdetailsadd.cntr_base.toUpperCase(),this.cntrSuffixAdd).subscribe(cntrdetails=>{

    const cntrDetailsFromAPI:cntrDetailsFromCMMS=cntrdetails;
    this.cntrdetailsfromload=cntrdetails;

    if(cntrDetailsFromAPI!=null)
    {
      // alert(JSON.stringify(cntrDetailsFromAPI))

      /* Removing this condition because even when the container is copied
      and user goes out of focus container details present in cmms should be loaded
      so emptying the copying container variables if exists

      if(this.containerDetailsCopied==false )
     {*/
        this.clearCntrDetailsExceptBaseAndSuffixAndPlantCode();
        this.containerDetailsCopied=false;
        this.cntrdetailsadd.function='C';
      this.isCmmsLoadedContainer=true;

        /*code Added */
        this.selected_container=null;
        this.cont_retype='Returnable';
        this.cntrdetails_display_popup_init();
        this.selected_cntr_ownrship_strat_code_in_popup=this.cntrOwnershipstratcodelist[0];
        /*code Added */

        this.cntrdetailsadd.cntr_base=this.cntrdetailsfromload.no_cntr_base.trim();
        this.cntrdetailsadd.cntr_suffix=this.cntrdetailsfromload.no_cntr_suffix.trim();

        this.cntrdetailsadd.cntr_active_ind = "Yes";
        
        if(this.cntrdetailsfromload.ds_cntr!=null)
        {
          this.cntrdetailsadd.cntr_desc=this.cntrdetailsfromload.ds_cntr.trim();
        }


        this.selected_ownrship_catg=this.ownrship_catg[0];
        this.selected_cntr_master_category = this.cntrMasterCategory[2];

        for(var i=0;i<this.cntrGrp.length;i++)
        {
          if(this.cntrGrp[i].cntr_grp.toLowerCase() == "standard" || this.cntrGrp[i].cntr_grp.toLowerCase() == "std")
          {
            console.log("Cntr_Grp", i, this.cntrGrp[i]);
            this.selected_cntr_group = this.cntrGrp[i];
            this.cntrdetailsadd.cntr_grp_desc =  this.cntrGrp[i].cntr_grp;
          }
        }
        
        this.cntrdetailsadd.ownrshp_catg=this.ownrship_catg[0].ownrshp_catg_val;
        this.cntrdetailsadd.cntr_mstr_catg = this.cntrMasterCategory[2].cntr_mstr_catg;
        
        
        this.cntrdetailsadd.cntr_exterior_l=this.cntrdetailsfromload.ms_cntr_lt;
        this.cntrdetailsadd.cntr_exterior_w=this.cntrdetailsfromload.ms_cntr_wd;
        this.cntrdetailsadd.cntr_exterior_h=this.cntrdetailsfromload.ms_cntr_ht;
        this.cntrdetailsadd.shpg_unit_stack_ht=this.cntrdetailsfromload.qt_cntr_stack_ht;

        if(this.cntrdetailsfromload.ms_cntr_clpsd_ht!=null && this.cntrdetailsfromload.ms_cntr_clpsd_ht>0)
        {
          this.cntrdetailsadd.iscontainercolapsble='Y';
          this.cntrdetailsadd.collapsed_ht=this.cntrdetailsfromload.ms_cntr_clpsd_ht;
        }
        else
        {
          this.cntrdetailsadd.iscontainercolapsble='N';
        }

        this.cntrdetailsadd.nest_ratio=this.cntrdetailsfromload.no_nesting_ratio1,
        this.cntrdetailsadd.nest_ratio_1=this.cntrdetailsfromload.no_nesting_ratio2,
        this.cntrdetailsadd.cntr_tare_wt=this.cntrdetailsfromload.ms_cntr_tare_wt,
        this.cntrdetailsadd.cntr_capacity=this.cntrdetailsfromload.ms_max_cap_wt,
        this.cntrdetailsadd.cntr_qt_per_min_su=this.cntrdetailsfromload.qt_per_min_su;
        this.cntrdetailsadd.warehouse_stack_ht=this.cntrdetailsfromload.qt_whse_stack_ht;

        if(this.cntrdetailsfromload.na_ford_cntct!=null && this.cntrdetailsfromload.na_ford_cntct.trim()!='')
        {
        this.cntrdetailsadd.ford_cntct=this.cntrdetailsfromload.na_ford_cntct.trim();
        }

        if(this.cntrdetailsfromload.no_cntct_phone_1!=null && this.cntrdetailsfromload.no_cntct_phone_1.trim()!='')
        {
        this.cntrdetailsadd.cntct_phone=this.cntrdetailsfromload.no_cntct_phone_1.trim();
        }
        if(this.cntrdetailsfromload.ds_cntr_note!=null && this.cntrdetailsfromload.ds_cntr_note.trim()!='')
        {
        this.cntrdetailsadd.img_notes=this.cntrdetailsfromload.ds_cntr_note.trim();
        }

        this.selected_cntr_type_code=this.cntrTypeCode[0];
        this.cntrdetailsadd.cntr_typ_desc=this.selected_cntr_type_code.cntr_typ_desc;


    //  }

      this.disableCntrDetailsWhenBaseOrSuffixChanged=false;
      this.disableOwnrshpCateg = true;
      this.cntrValidationForAlreadyInvalidFields();

      //  If Data is loaded from the CMMS, and if the measure is in kg/cm or (m) then the data is converted into cm
      if(this.add_measure=='kg/cm')
      {
      if(this.cntrdetailsadd.collapsed_ht!=null)
      {
          this.cntrdetailsadd.collapsed_ht=Math.round((2.54*this.cntrdetailsadd.collapsed_ht)*1000)/1000;
      }
      if(this.cntrdetailsadd.cntr_interior_l!=null)
      {
          this.cntrdetailsadd.cntr_interior_l=Math.round((2.54*this.cntrdetailsadd.cntr_interior_l)*1000)/1000;
      }
      if(this.cntrdetailsadd.cntr_interior_w!=null)
      {
              this.cntrdetailsadd.cntr_interior_w=Math.round((2.54*this.cntrdetailsadd.cntr_interior_w)*1000)/1000;
      }
      if(this.cntrdetailsadd.cntr_interior_h!=null)
      {
              this.cntrdetailsadd.cntr_interior_h=Math.round((2.54*this.cntrdetailsadd.cntr_interior_h)*1000)/1000;
      }
      if(this.cntrdetailsadd.cntr_tare_wt!=null)
      {
              this.cntrdetailsadd.cntr_tare_wt=Math.round((0.453592*this.cntrdetailsadd.cntr_tare_wt)*1000)/1000;
      }
      if(this.cntrdetailsadd.cntr_capacity!=null)
      {
              this.cntrdetailsadd.cntr_capacity=Math.round((0.453592*this.cntrdetailsadd.cntr_capacity)*1000)/1000;
      }

      if(this.cntrdetailsadd.cntr_exterior_l!=null)
      {
               this.cntrdetailsadd.cntr_exterior_l=Math.round((2.54*this.cntrdetailsadd.cntr_exterior_l)*1000)/1000;
      }
      if(this.cntrdetailsadd.cntr_exterior_w!=null)
      {
              this.cntrdetailsadd.cntr_exterior_w=Math.round((2.54*this.cntrdetailsadd.cntr_exterior_w)*1000)/1000;
      }
      if(this.cntrdetailsadd.cntr_exterior_h!=null)
      {
              this.cntrdetailsadd.cntr_exterior_h=Math.round((2.54*this.cntrdetailsadd.cntr_exterior_h)*1000)/1000;
      }
      if(this.cntrdetailsadd.shpg_unit_exterior_l!=null)
      {
             this.cntrdetailsadd.shpg_unit_exterior_l=Math.round((2.54*this.cntrdetailsadd.shpg_unit_exterior_l)*1000)/1000;
      }
      if(this.cntrdetailsadd.shpg_unit_exterior_w!=null)
      {
              this.cntrdetailsadd.shpg_unit_exterior_w=Math.round((2.54*this.cntrdetailsadd.shpg_unit_exterior_w)*1000)/1000;
      }
      if(this.cntrdetailsadd.shpg_unit_exterior_h!=null)
      {
              this.cntrdetailsadd.shpg_unit_exterior_h=Math.round((2.54*this.cntrdetailsadd.shpg_unit_exterior_h)*1000)/1000;
      }
      if(this.cntrdetailsadd.shpg_unit_nstdorflded_ht!=null)
      {
              this.cntrdetailsadd.shpg_unit_nstdorflded_ht=Math.round((2.54*this.cntrdetailsadd.shpg_unit_nstdorflded_ht)*1000)/1000;
      }
      if(this.cntrdetailsadd.shpg_unit_tare_wt!=null)
      {
              this.cntrdetailsadd.shpg_unit_tare_wt=Math.round((0.453592*this.cntrdetailsadd.shpg_unit_tare_wt)*1000)/1000;
      }
      /*
      if(this.cntrdetailsadd.shpg_unit_stack_ht!=null)
      {
              this.cntrdetailsadd.shpg_unit_stack_ht=Math.round((2.54*this.cntrdetailsadd.shpg_unit_stack_ht)*1000)/1000;
      }
      if(this.cntrdetailsadd.warehouse_stack_ht!=null)
      {
              this.cntrdetailsadd.warehouse_stack_ht=Math.round((2.54*this.cntrdetailsadd.warehouse_stack_ht)*1000)/1000;
      }
      */
     }
    }
    else
    {
      this.isCmmsLoadedContainer=false;
      if(this.containerDetailsCopied==false)
      {
        this.selected_ownrship_catg=this.ownrship_catg[0];
        this.cntrdetailsadd.ownrshp_catg=this.selected_ownrship_catg.ownrshp_catg_val;
        this.selected_cntr_master_category = this.cntrMasterCategory[2];
        this.cntrdetailsadd.cntr_mstr_catg = this.cntrMasterCategory[2].cntr_mstr_catg;
      }
      this.cntrdetailsadd.function='A';
      this.disableCntrDetailsWhenBaseOrSuffixChanged=false;
      this.disableOwnrshpCateg = false;
      this.validationInit();
    }
  })

}

cntrValidationForAlreadyInvalidFields()
{
  if(this.isCollapsibleInvalid)
  {
    this.validateCntrCollapsible()
  }
  if(this.collapsedHtInValid)
  {
    this.validateCollapsedHtForAdd();
  }
  if(this.nestRatioInValid)
  {
    this.validateNestRatioForAdd();
  }
  if(this.nestRatio1InValid)
  {
    this.validateNestRatio1ForAdd();
  }
  if(this.cntrTareWtInValid)
  {
    this.validateCntrTareWeightForAdd();
  }
  if(this.cntrCapWtInValid)
  {
    this.validateCntrCapWtForAdd();
  }
  if(this.cntrPerLayerInValid)
  {
    this.validateCntrPerLayerForAdd();
  }
  if(this.maxLayerPerSuInValid)
  {
    this.validateMaxLayerPerSuForAdd();
  }
  if(this.cntrQtPerMinSuInValid)
  {
    this.validateCntrQtPerMinSuForAdd();
  }
  if(this.MaxCntrPerSuInValid)
  {
    this.validateMaxCntrPerSuForAdd();
  }
  if(this.cntrExtHInValid)
  {
    this.validateCntrExtLForAdd();
  }
  if(this.cntrExtWInValid)
  {
    this.validateCntrExtWForAdd();
  }
  if(this.cntrExtHInValid)
  {
    this.validateCntrExtHForAdd();
  }
  if(this.cntrIntLInValid)
  {
    this.validateCntrIntLForAdd();
  }
  if(this.cntrIntWInValid)
  {
    this.validateCntrIntWForAdd();
  }
  if(this.cntrIntHInValid)
  {
    this.validateCntrIntHForAdd();
  }
  if(this.suNestedHtInValid)
  {
    this.validateSuNestedFldedHtForAdd();
  }
  if(this.suTareWtInValidReason)
  {
    this.validateSuTareWeightForAdd();
  }
  if(this.wareHourseStackHtInvaid)
  {
    this.validateWareHourseStckHtForAdd();
  }
  if(this.suStackHtInvalid)
  {
    this.validateShippingUnitStackHtForAdd();
  }
  if(this.suExtLInValid)
  {
    this.validateSUExtLForAdd();
  }
  if(this.suExtWInValid)
  {
    this.validateSUExtWForAdd();
  }
  if(this.suExtHInValid)
  {
    this.validateSUExtHForAdd();
  }
  if(this.cntrDescInvalid)
  {
    this.validateCntrDescForAdd()
  }
  /* if(this.plantCodeInvalid)
  {
    this.validatePlantCodeForAdd()
  } */
  // if(this.cardOrCallInvalid)
  // {
  //   this.validateCardOrCallForAdd()
  // }
  if(this.mastrCatgInvalid)
  {
    this.validateMastCatgForAdd()
  }
  if(this.retTypeInvalid)
  {
    this.validateCntrRetTypeForAdd()
  }
  if(this.cntrTypeInvalid)
  {
    this.validateCntrTypeCodeForAdd()
  }
  if(this.cntrMaterialInvalid)
  {
    this.validateCntrMaterialForAdd()
  }
  if(this.cntrOwnerShpCatgInvalid)
  {
    this.validateCntrOwnershpCatgForAdd()
  }
  if(this.cosInvalid)
  {
    this.validateCOSForAdd()
  }
  if(this.cntrGrpInvalid)
  {
    this.validateCntrGrpForAdd()
  }
  if(this.cntrCntctInvalid)
  {
    this.validateCntrContact()
  }
  if(this.cntctPhoneInvalid)
  {
    this.validateCntrContactPhone()
  }
  if(this.cntrActiveInvalid)
  {
    this.validateCntrActive()
  }
}

validateCntrSuffixIndForAdd()
{
  if(this.cntrdetailsadd.cntr_suff_ind==null || this.cntrdetailsadd.cntr_suff_ind.trim()=="")
  {
    this.cntrSuffixIndInvalid=true;
    this.cntrSuffixIndInvalidReason='Required'
  }
  else
  {
    this.cntrSuffixIndInvalid=false;
    this.cntrSuffixIndInvalidReason=null
  }
}

validateCntrSuffixForAdd()
{
  if(this.cntrdetailsadd.cntr_suff_ind != null && this.cntrdetailsadd.cntr_suff_ind.trim() != '' && this.cntrdetailsadd.cntr_suff_ind.toLowerCase() == 'yes' && (this.selectedSuffixList == null || this.selectedSuffixList.suppliergsdbcode.trim() == ''))
  {
    this.cntrSuffixInvalid=true;
    this.cntrSuffixInvalidReason='Required'
  }
  else
  {
    this.cntrSuffixInvalid=false;
    this.cntrSuffixInvalidReason=null
  }
}

clearCntrDetailsExceptBaseAndSuffixAndPlantCode() // Plant code
{
  // this.selectedPlantList={cd_plant:"",plantGSDBCode:"",plant_active_stat:"",plant_name:"",plant_type_name:""};

  this.selected_cntr_master_category={cd_cntr_mstr_catg:0,cntr_mstr_catg_desc:'',updated_by:'',updated_dt:'',cntr_mstr_catg:'',active_stat:'',active_stat_display:false};

  this.selected_cntr_type_code={cd_cntr_typ:'',cntr_typ_desc:'',typ_no:0};

  this.selected_cntr_ownrship_strat_code={cd_region:'',ownrshp_strat_code:'',ownrshp_desc:'',cd_ownrshp_strat:0};

  this.selected_cntr_group={cntr_grp:''};

  this.selected_cntr_material={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
  this.enable_material_dropdown=false;

  this.selected_cntr_currency_list={cd_curr:0,curr_desc:''};

  this.selected_ownrship_catg={ownrshp_catg:'',ownrshp_catg_val:''};

  this.cntrdetailsadd=
  {
      // cd_cntr:0,
      function:'',
      cntr_suff_ind:this.cntrdetailsadd.cntr_suff_ind,
      exp_refundable:'',
      cntr_active_ind:'',
      cntr_base:this.cntrdetailsadd.cntr_base,
      cntr_suffix:this.cntrdetailsadd.cntr_suffix,
      cntr_desc:'',
      card_call:'Call',
      cntr_mstr_catg:'',
      cntr_typ_desc:'',
      ownrshp_strat_code:'',
      ownrshp_catg:'',
      cntr_grp_desc:'',
      cntr_exterior_l:0,
      cntr_exterior_w:0,
      cntr_exterior_h:0,
      collapsed_ht:0.0,
      nest_ratio:0,
      cntr_interior_l:0,
      cntr_interior_w:0,
      cntr_interior_h:0,
      cntr_tare_wt:0,
      cntr_capacity:0,
      cntr_per_layer:0,
      max_layers_shpg_unit:0,
      max_cntr_per_shpg_unit:0,
      cntr_matr_desc:'',
      cntr_drawing_no:'',
      shpg_unit_exterior_l:0,
      shpg_unit_exterior_w:0,
      shpg_unit_exterior_h:0,
      shpg_unit_nstdorflded_ht:0,
      shpg_unit_tare_wt:0,
      shpg_unit_stack_ht:0,
      warehouse_stack_ht:0,
      service_life:'',
      currency_desc:'',
      purchase_cost:0,
      rental_issue_cost:0,
      sup_rntl_dly_cost:0,
      ford_rntl_dly_cost:0,
      return_rntl_cost:0,
      tooling_dev_cost:0,
      extr_cntr_dsgn_img_ind:'No',
      intr_cntr_dsgn_img_ind:'No',
      shpg_unit_img_ind:'No',
      cad_img_ind:'No',
      img_notes:'',
      cd_region:'',
      iscontainercolapsble:'',
      cd_plant:this.cntrdetailsadd.cd_plant,
      cntr_qt_per_min_su:0,
      nest_ratio_1:0,
      ford_cntct:'',
      cntct_phone:'',
      id_user:'',
      ts_last_update:'',
      req_stat:'',
      components:[{
          mp_function:'',
          no_comp_base:'',
          no_comp_suffix:'',
          ds_comp:'',
          comp_qt_per_min_su:0,
          comp_cd_owner:'',
          in_comp:'',
          comp_cd_type:''
      }],
      UoM:'E',
      typ_no:0,
      dbia_req_no:0
  }
}


clearAddCntr()
{
  this.cntrdetailsadd.cntr_base='';
  this.cntrdetailsadd.cntr_suffix='';
  this.cntrdetailsadd.cntr_suff_ind='';
  this.cntrSuffixAdd='';
  this.cntrSuffixEnabled=false;
  this.selectedSuffixList={suppliergsdbcode:''};
  this.disableCntrDetailsWhenBaseOrSuffixChanged=true;
  this.prevCntrSuffixInd=null;
  this.enteredCntrBase=null;

  this.selected_container=null;
  this.table_selected_container=null;

  this.cont_retype='Returnable';
  this.tableSelectedReturnType=null;

  this.containerDetailsCopied=false;
  this.isCmmsLoadedComponent=false;
  this.isCmmsLoadedContainer=false;
  this.cntrdetails_display_popup_init();


  this.selected_cntr_ownrship_strat_code_in_popup=this.cntrOwnershipstratcodelist[0];
  this.table_selected_cntr_ownrship_strat_code_in_popup=null;

  this.selectedPlantList={cd_plant:'',plantGSDBCode:'',plant_active_stat:'',plant_name:'',plant_type_name:''};
  this.cntrdetailsadd.cd_plant='';

  this.clearCntrDetailsExceptBaseAndSuffixAndPlantCode();
  this.validationInit();
}


saveaddcntr()
{
    this.cntrdetailsadd.cntr_base=this.cntrdetailsadd.cntr_base.toUpperCase();
    this.cntrdetailsadd.cd_region=this.current_region_code_prg;
    // this.cntrdetailsadd.function='A';
    this.cntrdetailsadd.extr_cntr_dsgn_img_ind='No';
    this.cntrdetailsadd.intr_cntr_dsgn_img_ind='No';
    this.cntrdetailsadd.shpg_unit_img_ind='No';
    this.cntrdetailsadd.cad_img_ind='No';
    this.cntrdetailsadd.dbia_req_no=0;

    if(this.cntrdetailsadd.iscontainercolapsble!=null && this.cntrdetailsadd.iscontainercolapsble.toLowerCase()=='n')
    {
      this.cntrdetailsadd.collapsed_ht=1;
    }

    this.cntrdetailsadd.cd_plant=this.selectedPlantList.plantGSDBCode.trim();
    this.cntrdetailsadd.cntr_grp_desc=this.selected_cntr_group.cntr_grp;
    this.cntrdetailsadd.cntr_matr_desc=this.selected_cntr_material.cntr_matr_name;
    this.cntrdetailsadd.cntr_mstr_catg=this.selected_cntr_master_category.cntr_mstr_catg;
    this.cntrdetailsadd.cntr_typ_desc=String(this.selected_cntr_type_code.cd_cntr_typ);
    this.cntrdetailsadd.typ_no=this.selected_cntr_type_code.typ_no;
    this.cntrdetailsadd.currency_desc=this.selected_cntr_currency_list.curr_desc;
    // this.cntrdetailsadd.ownrshp_catg="Ford"
    this.cntrdetailsadd.ownrshp_catg=this.selected_ownrship_catg.ownrshp_catg_val
    this.cntrdetailsadd.ownrshp_strat_code=this.selected_cntr_ownrship_strat_code.ownrshp_strat_code;
    this.cntrdetailsadd.cntr_suffix=this.selectedSuffixList.suppliergsdbcode;

    this.cntrdetailsadd.ts_last_update=this.date.transform(new Date(),'yyyy-MM-dd');
    this.cntrdetailsadd.req_stat='New';
    this.cntrdetailsadd.id_user=this.transactionService.getcdsid();

    if(this.cntrdetailsadd.cntr_desc != null)
    {
    this.cntrdetailsadd.cntr_desc=this.cntrdetailsadd.cntr_desc.trim();
    }

    if(this.cntrdetailsadd.img_notes != null)
    {
      this.cntrdetailsadd.img_notes=this.cntrdetailsadd.img_notes.trim();
    }

    if(this.cntrdetailsadd.ford_cntct != null)
    {
    this.cntrdetailsadd.ford_cntct=this.cntrdetailsadd.ford_cntct.trim();
    }
    if(this.cntrdetailsadd.cntct_phone != null)
    {
    this.cntrdetailsadd.cntct_phone=this.cntrdetailsadd.cntct_phone.trim();
    }


    if(this.cntrdetailsadd.cntr_drawing_no == null )
    {
      this.cntrdetailsadd.cntr_drawing_no=''
    }

    if(this.cntrdetailsadd.service_life == null)
    {
      this.cntrdetailsadd.service_life='';
    }


    /*if(this.cntrdetailsadd.cntr_suffix.length>1)
    {
        this.cntrdetailsadd.cntr_suff_ind="Yes";
    }
    else
    {
        this.cntrdetailsadd.cntr_suff_ind="No";
    }
*/

      if(this.unitom=='E')
      {
      this.add_measure='lbs/inch';
      }
      else{
        this.add_measure='kg/cm';
      }
    if(this.add_measure=='kg/cm')
  {

    /*if(this.cntrdetailsadd.collapsed_ht!=null)
    {
        this.cntrdetailsadd.collapsed_ht=Math.round((0.393701*this.cntrdetailsadd.collapsed_ht)*1000)/1000;
    }
    if(this.cntrdetailsadd.cntr_interior_l!=null)
    {
        this.cntrdetailsadd.cntr_interior_l=Math.round((0.393701*this.cntrdetailsadd.cntr_interior_l)*1000)/1000;
    }
    if(this.cntrdetailsadd.cntr_interior_w!=null)
    {
            this.cntrdetailsadd.cntr_interior_w=Math.round((0.393701*this.cntrdetailsadd.cntr_interior_w)*1000)/1000;
    }
    if(this.cntrdetailsadd.cntr_interior_h!=null)
    {
            this.cntrdetailsadd.cntr_interior_h=Math.round((0.393701*this.cntrdetailsadd.cntr_interior_h)*1000)/1000;
    }
    if(this.cntrdetailsadd.cntr_tare_wt!=null)
    {
            this.cntrdetailsadd.cntr_tare_wt=Math.round((2.20462*this.cntrdetailsadd.cntr_tare_wt)*1000)/1000;
    }
    if(this.cntrdetailsadd.cntr_capacity!=null)
    {
            this.cntrdetailsadd.cntr_capacity=Math.round((0.393701*0.393701*0.393701*this.cntrdetailsadd.cntr_capacity)*1000)/1000;
    }

    if(this.cntrdetailsadd.cntr_exterior_l!=null)
    {
             this.cntrdetailsadd.cntr_exterior_l=Math.round((0.393701*this.cntrdetailsadd.cntr_exterior_l)*1000)/1000;
    }
    if(this.cntrdetailsadd.cntr_exterior_w!=null)
    {
            this.cntrdetailsadd.cntr_exterior_w=Math.round((0.393701*this.cntrdetailsadd.cntr_exterior_w)*1000)/1000;
    }
    if(this.cntrdetailsadd.cntr_exterior_h!=null)
    {
            this.cntrdetailsadd.cntr_exterior_h=Math.round((0.393701*this.cntrdetailsadd.cntr_exterior_h)*1000)/1000;
    }
    if(this.cntrdetailsadd.shpg_unit_exterior_l!=null)
    {
           this.cntrdetailsadd.shpg_unit_exterior_l=Math.round((0.393701*this.cntrdetailsadd.shpg_unit_exterior_l)*1000)/1000;
    }
    if(this.cntrdetailsadd.shpg_unit_exterior_w!=null)
    {
            this.cntrdetailsadd.shpg_unit_exterior_w=Math.round((0.393701*this.cntrdetailsadd.shpg_unit_exterior_w)*1000)/1000;
    }
    if(this.cntrdetailsadd.shpg_unit_exterior_h!=null)
    {
            this.cntrdetailsadd.shpg_unit_exterior_h=Math.round((0.393701*this.cntrdetailsadd.shpg_unit_exterior_h)*1000)/1000;
    }
    if(this.cntrdetailsadd.shpg_unit_nstdorflded_ht!=null)
    {
            this.cntrdetailsadd.shpg_unit_nstdorflded_ht=Math.round((0.393701*this.cntrdetailsadd.shpg_unit_nstdorflded_ht)*1000)/1000;
    }
    if(this.cntrdetailsadd.shpg_unit_tare_wt!=null)
    {
            this.cntrdetailsadd.shpg_unit_tare_wt=Math.round((2.20462*this.cntrdetailsadd.shpg_unit_tare_wt)*1000)/1000;
    }
    if(this.cntrdetailsadd.shpg_unit_stack_ht!=null)
    {
            this.cntrdetailsadd.shpg_unit_stack_ht=Math.round((0.393701*this.cntrdetailsadd.shpg_unit_stack_ht)*1000)/1000;
    }
    if(this.cntrdetailsadd.warehouse_stack_ht!=null)
    {
            this.cntrdetailsadd.warehouse_stack_ht=Math.round((0.393701*this.cntrdetailsadd.warehouse_stack_ht)*1000)/1000;
    }
    */
         this.cntrdetailsadd.UoM='M'

  }
  else
  {
    this.cntrdetailsadd.UoM='E'
  }





  this.validateCollapsedHtForAdd();
  this.validateNestRatioForAdd();
  this.validateNestRatio1ForAdd();
  this.validateCntrTareWeightForAdd();
  this.validateCntrCapWtForAdd();
  this.validateCntrPerLayerForAdd();
  this.validateMaxLayerPerSuForAdd();
  this.validateCntrQtPerMinSuForAdd();
  this.validateMaxCntrPerSuForAdd();
  this.validateCntrExtLForAdd();
  this.validateCntrExtWForAdd();
  this.validateCntrExtHForAdd();
  this.validateCntrIntLForAdd();
  this.validateCntrIntWForAdd();
  this.validateCntrIntHForAdd();
  this.validateSuNestedFldedHtForAdd();
  this.validateSuTareWeightForAdd();
  this.validateWareHourseStckHtForAdd();
  this.validateShippingUnitStackHtForAdd();
  this.validateSUExtLForAdd();
  this.validateSUExtWForAdd();
  this.validateSUExtHForAdd();
  this.validateCntrBaseForAdd()
  this.validateCntrDescForAdd()
  //this.validatePlantCodeForAdd()
  // this.validateCardOrCallForAdd()
  this.validateMastCatgForAdd()
  this.validateCntrRetTypeForAdd()
  this.validateCntrTypeCodeForAdd()
  this.validateCntrMaterialForAdd()
  this.validateCntrOwnershpCatgForAdd()
  this.validateCOSForAdd()
  this.validateCntrGrpForAdd()
  this.validateCntrCollapsible()
  this.validateCntrContact()
  this.validateCntrContactPhone()
  this.validateCntrActive()
  this.validateCntrSuffixForAdd();
  this.validateCntrSuffixIndForAdd();

   // Removed !this.cardOrCallInvalid 
  if(!this.collapsedHtInValid && !this.nestRatioInValid && !this.nestRatio1InValid &&
      !this.cntrTareWtInValid && !this.cntrCapWtInValid && !this.cntrPerLayerInValid &&
      !this.maxLayerPerSuInValid && !this.cntrQtPerMinSuInValid && !this.MaxCntrPerSuInValid &&
      !this.cntrExtLInValid && !this.cntrExtWInValid && !this.cntrExtHInValid && !this.cntrIntLInValid &&
      !this.cntrIntWInValid && !this.cntrIntHInValid && !this.suNestedHtInValid && !this.suTareWtInvalid &&
      !this.wareHourseStackHtInvaid && !this.suStackHtInvalid && !this.suExtLInValid && !this.suExtWInValid &&
      !this.suExtHInValid && !this.cntrBaseInvalid && !this.cntrDescInvalid && !this.plantCodeInvalid &&
      !this.mastrCatgInvalid && !this.retTypeInvalid && !this.cntrTypeInvalid &&
      !this.cntrMaterialInvalid && !this.cosInvalid && !this.cntrGrpInvalid && !this.isCollapsibleInvalid &&
      !this.cntrCntctInvalid && !this.cntctPhoneInvalid && !this.cntrActiveInvalid && !this.cntrOwnerShpCatgInvalid &&
      !this.cntrSuffixIndInvalid && !this.cntrSuffixInvalid)
  {


    let suffixToApi='';
    if(this.cntrdetailsadd.cntr_suff_ind.toLowerCase()=='no')  {
      suffixToApi='%20%20%20'
    }
    else
    {
      suffixToApi=this.cntrdetailsadd.cntr_suffix;
    }

    if(this.isCmmsLoadedContainer)
    {

     console.log('cntr admin direct save Add')
     console.log('cntrdetailsadd',this.cntrdetailsadd)
      if(this.cntrdetailsadd != null)
      {
        // service to call api to push data
        this.saveAddContainerDirectToCntrAdmin.cd_cntr=0;
        this.saveAddContainerDirectToCntrAdmin.cntr_base=this.cntrdetailsadd.cntr_base;
        this.saveAddContainerDirectToCntrAdmin.cntr_suff_ind= this.cntrdetailsadd.cntr_suff_ind;
        this.saveAddContainerDirectToCntrAdmin.exp_refundable=this.cntrdetailsadd.exp_refundable;
        this.saveAddContainerDirectToCntrAdmin.cntr_active_ind=this.cntrdetailsadd.cntr_active_ind
        this.saveAddContainerDirectToCntrAdmin.cntr_suffix=this.cntrdetailsadd.cntr_suffix;
        this.saveAddContainerDirectToCntrAdmin.cntr_desc=this.cntrdetailsadd.cntr_desc;
        this.saveAddContainerDirectToCntrAdmin.card_call=this.cntrdetailsadd.card_call
        this.saveAddContainerDirectToCntrAdmin.cntr_mstr_catg=this.cntrdetailsadd.cntr_mstr_catg;
        this.saveAddContainerDirectToCntrAdmin.cntr_typ_desc=this.cntrdetailsadd.cntr_typ_desc;
        this.saveAddContainerDirectToCntrAdmin.ownrshp_strat_code=this.cntrdetailsadd.ownrshp_strat_code;
        this.saveAddContainerDirectToCntrAdmin.ownrshp_catg=this.cntrdetailsadd.ownrshp_catg;
        this.saveAddContainerDirectToCntrAdmin.cntr_grp_desc=this.cntrdetailsadd.cntr_grp_desc;
        this.saveAddContainerDirectToCntrAdmin.cntr_matr_desc=this.cntrdetailsadd.cntr_matr_desc;
        this.saveAddContainerDirectToCntrAdmin.currency_desc=this.cntrdetailsadd.currency_desc;
        this.saveAddContainerDirectToCntrAdmin.iscontainercolapsble=this.cntrdetailsadd.iscontainercolapsble;
        this.saveAddContainerDirectToCntrAdmin.cntr_exterior_l=this.cntrdetailsadd.cntr_exterior_l;
        this.saveAddContainerDirectToCntrAdmin.cntr_exterior_w=this.cntrdetailsadd.cntr_exterior_w;
        this.saveAddContainerDirectToCntrAdmin.cntr_exterior_h=this.cntrdetailsadd.cntr_exterior_h;
        this.saveAddContainerDirectToCntrAdmin.collapsed_ht=this.cntrdetailsadd.collapsed_ht;
        this.saveAddContainerDirectToCntrAdmin.nest_ratio=this.cntrdetailsadd.nest_ratio;
        this.saveAddContainerDirectToCntrAdmin.cntr_interior_l=this.cntrdetailsadd.cntr_interior_l;
        this.saveAddContainerDirectToCntrAdmin.cntr_interior_w=this.cntrdetailsadd.cntr_interior_w;
        this.saveAddContainerDirectToCntrAdmin.cntr_interior_h=this.cntrdetailsadd.cntr_interior_h;
        this.saveAddContainerDirectToCntrAdmin.cntr_tare_wt=this.cntrdetailsadd.cntr_tare_wt;
        this.saveAddContainerDirectToCntrAdmin.cntr_capacity=this.cntrdetailsadd.cntr_capacity;
        this.saveAddContainerDirectToCntrAdmin.cntr_per_layer=this.cntrdetailsadd.cntr_per_layer;
        this.saveAddContainerDirectToCntrAdmin.max_layers_shpg_unit=this.cntrdetailsadd.max_layers_shpg_unit;
        this.saveAddContainerDirectToCntrAdmin.max_cntr_per_shpg_unit=this.cntrdetailsadd.max_cntr_per_shpg_unit;
        this.saveAddContainerDirectToCntrAdmin.cntr_drawing_no=this.cntrdetailsadd.cntr_drawing_no;
        this.saveAddContainerDirectToCntrAdmin.shpg_unit_exterior_l=this.cntrdetailsadd.shpg_unit_exterior_l;
        this.saveAddContainerDirectToCntrAdmin.shpg_unit_exterior_w=this.cntrdetailsadd.shpg_unit_exterior_w;
        this.saveAddContainerDirectToCntrAdmin.shpg_unit_exterior_h=this.cntrdetailsadd.shpg_unit_exterior_h;
        this.saveAddContainerDirectToCntrAdmin.shpg_unit_nstdorflded_ht=this.cntrdetailsadd.shpg_unit_nstdorflded_ht;
        this.saveAddContainerDirectToCntrAdmin.shpg_unit_tare_wt=this.cntrdetailsadd.shpg_unit_tare_wt;
        this.saveAddContainerDirectToCntrAdmin.shpg_unit_stack_ht=this.cntrdetailsadd.shpg_unit_stack_ht;
        this.saveAddContainerDirectToCntrAdmin.warehouse_stack_ht=this.cntrdetailsadd.warehouse_stack_ht;
        this.saveAddContainerDirectToCntrAdmin.service_life=this.cntrdetailsadd.service_life;
        this.saveAddContainerDirectToCntrAdmin.purchase_cost=this.cntrdetailsadd.purchase_cost;
        this.saveAddContainerDirectToCntrAdmin.rental_issue_cost=this.cntrdetailsadd.rental_issue_cost;
        this.saveAddContainerDirectToCntrAdmin.sup_rntl_dly_cost=this.cntrdetailsadd.sup_rntl_dly_cost;
        this.saveAddContainerDirectToCntrAdmin.ford_rntl_dly_cost=this.cntrdetailsadd.ford_rntl_dly_cost;
        this.saveAddContainerDirectToCntrAdmin.return_rntl_cost=this.cntrdetailsadd.return_rntl_cost;
        this.saveAddContainerDirectToCntrAdmin.tooling_dev_cost=this.cntrdetailsadd.tooling_dev_cost;
        this.saveAddContainerDirectToCntrAdmin.extr_cntr_dsgn_img_ind=this.cntrdetailsadd.extr_cntr_dsgn_img_ind;
        this.saveAddContainerDirectToCntrAdmin.intr_cntr_dsgn_img_ind=this.cntrdetailsadd.intr_cntr_dsgn_img_ind;
        this.saveAddContainerDirectToCntrAdmin.shpg_unit_img_ind=this.cntrdetailsadd.shpg_unit_img_ind
        this.saveAddContainerDirectToCntrAdmin.cad_img_ind=this.cntrdetailsadd.cad_img_ind;
        this.saveAddContainerDirectToCntrAdmin.img_notes=this.cntrdetailsadd.img_notes;
        this.saveAddContainerDirectToCntrAdmin.cd_region=this.cntrdetailsadd.cd_region;
        this.saveAddContainerDirectToCntrAdmin.cd_plant=this.cntrdetailsadd.cd_plant;
        this.saveAddContainerDirectToCntrAdmin.cnt_qt_per_min_su=this.cntrdetailsadd.cntr_qt_per_min_su;
        this.saveAddContainerDirectToCntrAdmin.nest_ratio_1=this.cntrdetailsadd.nest_ratio_1;
        this.saveAddContainerDirectToCntrAdmin.ford_cntct=this.cntrdetailsadd.ford_cntct;
        this.saveAddContainerDirectToCntrAdmin.cntct_phone=this.cntrdetailsadd.cntct_phone;
        this.saveAddContainerDirectToCntrAdmin.uom=this.cntrdetailsadd.UoM;
        this.saveAddContainerDirectToCntrAdmin.id_user= this.cntrdetailsadd.id_user
        this.saveAddContainerDirectToCntrAdmin.req_stat=this.cntrdetailsadd.req_stat;
        this.saveAddContainerDirectToCntrAdmin.ts_last_update=this.cntrdetailsadd.ts_last_update;

        this.saveAddContainerDirectToCntrAdmin.typ_no=this.cntrdetailsadd.typ_no;

        console.log('save direct to container admin post data', this.saveAddContainerDirectToCntrAdmin)

        const saveAddContainerDirectToCntrAdminArray=[];
        saveAddContainerDirectToCntrAdminArray.push(this.saveAddContainerDirectToCntrAdmin);

        const base=saveAddContainerDirectToCntrAdminArray[0].cntr_base;
        var suffix="-";
        if(saveAddContainerDirectToCntrAdminArray!=null && saveAddContainerDirectToCntrAdminArray[0].cntr_suff!=null && saveAddContainerDirectToCntrAdminArray[0].cntr_suff.trim()!='')
        {
          suffix=saveAddContainerDirectToCntrAdminArray[0].cntr_suff
        }
        this.transactionService.checkWhetherCntrOrCompInNgppByDesc(base,suffix,saveAddContainerDirectToCntrAdminArray[0].ownrshp_strat_code,saveAddContainerDirectToCntrAdminArray[0].cd_region,saveAddContainerDirectToCntrAdminArray[0].cntr_typ_desc).subscribe(count=>{
          console.log(count,typeof(count));
          if(count==0)
          {
            this.enableCntrExistInNGPPNotification=false;
            this.transactionService.cntrAdminSaveCntrDetails(saveAddContainerDirectToCntrAdminArray).subscribe(cntr_save_res=>{
              console.log('direct cntr admin save add',cntr_save_res)
              if(cntr_save_res.toLowerCase() == 'success')
              {
                this.messageService.add({severity:'success', summary:'Success', detail:'Container Added Successfully'});
                this.DisplayGrid(this.current_region_code_prg);
                this.addcntrdetailsinit();
                this.loadcntrdetails();
                this.clearAddCntr();
              }
              else
              {
                  this.messageService.add({severity:'error', summary: 'Error', detail: 'Container Not Added,Please Try Again'});
                  this.loadcntrdetails();
                  this.validationInit();
              }
            })
          }
          else{
            this.enableCntrExistInNGPPNotification=true;
          }
        })


      }
    }
    else
    {
      this.transactionService.getRequestStatusOfContainerByDesc(this.cntrdetailsadd.cntr_base,suffixToApi,this.cntrdetailsadd.ownrshp_strat_code,this.cntrdetailsadd.cd_region,this.cntrdetailsadd.cntr_typ_desc).subscribe(requestStatus=>{
        console.log('Request Status',requestStatus);
        const requestStatusReceived=requestStatus;

        console.log('add cntr details before dbia request',this.cntrdetailsadd)

        if(requestStatusReceived!= null && requestStatusReceived!='' && requestStatusReceived.toLowerCase() =='pending')
        {
          this.enableCntrEditPendingNotification=true;
        }
        else
        {
          if(this.cntrdetailsadd.function!=null && this.cntrdetailsadd.function!='' && this.cntrdetailsadd.function=='A')
          {
            this.enableAddingNewContainerNotification=true;
          }
          else if(this.cntrdetailsadd.function!=null && this.cntrdetailsadd.function!='' && this.cntrdetailsadd.function=='C')
          {
            this.enableUpdateExistingCntrNotification=true;
            this.warningMsgforCntr = this.compdetailsadd.cntr_base + "--" + suffix + "Container details change/update request submitted by other user and is in progress, please try after some time...";
          }

        }
      });
    }
  }
 }
 okCntrExistInNGPPNotification()
 {
    this.enableCntrExistInNGPPNotification=false;
 }
 addCntrRequestToDBIA()
 {
   console.log('details before adding',this.cntrdetailsadd);
   this.transactionService.savecntrdetails(this.cntrdetailsadd).subscribe(savecntr=>
     {

      const res=savecntr;
      console.log('successful',res);

      if(res.toLowerCase() == 'success')
      {
        this.messageService.add({severity:'success', summary:'Success', detail:'Container Added Successfully'});
        // this.getplantlist();
        this.DisplayGrid(this.current_region_code_prg);
        this.addcntrdetailsinit();
        this.loadcntrdetails();
        this.validationInit();

        this.cntrSuffixAdd='';
        this.cntrSuffixEnabled=false;
        this.selectedSuffixList={suppliergsdbcode:''};
        this.disableCntrDetailsWhenBaseOrSuffixChanged=true;
        this.prevCntrSuffixInd=null;
        this.enteredCntrBase=null;

        this.selected_container=null;
        this.cont_retype='Returnable';
        this.table_selected_container=null;
        this.tableSelectedReturnType=null;
        this.isCmmsLoadedContainer=false;
        this.containerDetailsCopied=false;
        this.cntrdetails_display_popup_init();
        this.isCmmsLoadedContainer=false;
        this.selected_cntr_ownrship_strat_code_in_popup=this.cntrOwnershipstratcodelist[0];
      }
      else
      {
          this.messageService.add({severity:'error', summary: 'Error', detail: 'Container Not Added,Please Try Again'});
          // this.addplantdetailsinit();
          this.loadcntrdetails();
          this.validationInit();
       /*   if(this.add_measure=='kg/cm')
          {
          if(this.cntrdetailsadd.collapsed_ht!=null)
          {
              this.cntrdetailsadd.collapsed_ht=Math.round((2.54*this.cntrdetailsadd.collapsed_ht)*1000)/1000;
          }
          if(this.cntrdetailsadd.cntr_interior_l!=null)
          {
              this.cntrdetailsadd.cntr_interior_l=Math.round((2.54*this.cntrdetailsadd.cntr_interior_l)*1000)/1000;
          }
          if(this.cntrdetailsadd.cntr_interior_w!=null)
          {
                  this.cntrdetailsadd.cntr_interior_w=Math.round((2.54*this.cntrdetailsadd.cntr_interior_w)*1000)/1000;
          }
          if(this.cntrdetailsadd.cntr_interior_h!=null)
          {
                  this.cntrdetailsadd.cntr_interior_h=Math.round((2.54*this.cntrdetailsadd.cntr_interior_h)*1000)/1000;
          }
          if(this.cntrdetailsadd.cntr_tare_wt!=null)
          {
                  this.cntrdetailsadd.cntr_tare_wt=Math.round((0.453592*this.cntrdetailsadd.cntr_tare_wt)*1000)/1000;
          }
          if(this.cntrdetailsadd.cntr_capacity!=null)
          {
                  this.cntrdetailsadd.cntr_capacity=Math.round((2.54*2.54*2.54*this.cntrdetailsadd.cntr_capacity)*1000)/1000;
          }

          if(this.cntrdetailsadd.cntr_exterior_l!=null)
          {
                   this.cntrdetailsadd.cntr_exterior_l=Math.round((2.54*this.cntrdetailsadd.cntr_exterior_l)*1000)/1000;
          }
          if(this.cntrdetailsadd.cntr_exterior_w!=null)
          {
                  this.cntrdetailsadd.cntr_exterior_w=Math.round((2.54*this.cntrdetailsadd.cntr_exterior_w)*1000)/1000;
          }
          if(this.cntrdetailsadd.cntr_exterior_h!=null)
          {
                  this.cntrdetailsadd.cntr_exterior_h=Math.round((2.54*this.cntrdetailsadd.cntr_exterior_h)*1000)/1000;
          }
          if(this.cntrdetailsadd.shpg_unit_exterior_l!=null)
          {
                 this.cntrdetailsadd.shpg_unit_exterior_l=Math.round((2.54*this.cntrdetailsadd.shpg_unit_exterior_l)*1000)/1000;
          }
          if(this.cntrdetailsadd.shpg_unit_exterior_w!=null)
          {
                  this.cntrdetailsadd.shpg_unit_exterior_w=Math.round((2.54*this.cntrdetailsadd.shpg_unit_exterior_w)*1000)/1000;
          }
          if(this.cntrdetailsadd.shpg_unit_exterior_h!=null)
          {
                  this.cntrdetailsadd.shpg_unit_exterior_h=Math.round((2.54*this.cntrdetailsadd.shpg_unit_exterior_h)*1000)/1000;
          }
          if(this.cntrdetailsadd.shpg_unit_nstdorflded_ht!=null)
          {
                  this.cntrdetailsadd.shpg_unit_nstdorflded_ht=Math.round((2.54*this.cntrdetailsadd.shpg_unit_nstdorflded_ht)*1000)/1000;
          }
          if(this.cntrdetailsadd.shpg_unit_tare_wt!=null)
          {
                  this.cntrdetailsadd.shpg_unit_tare_wt=Math.round((0.453592*this.cntrdetailsadd.shpg_unit_tare_wt)*1000)/1000;
          }
          if(this.cntrdetailsadd.shpg_unit_stack_ht!=null)
          {
                  this.cntrdetailsadd.shpg_unit_stack_ht=Math.round((2.54*this.cntrdetailsadd.shpg_unit_stack_ht)*1000)/1000;
          }
          if(this.cntrdetailsadd.warehouse_stack_ht!=null)
          {
                  this.cntrdetailsadd.warehouse_stack_ht=Math.round((2.54*this.cntrdetailsadd.warehouse_stack_ht)*1000)/1000;
          }
        }*/
      }
  });
}
cancel_add()
{
    this.cntrAddDetailsEnable=false;
    this.cntrAddEnable=true;
    this.addcntrdetailsinit();


    this.cntrSuffixAdd='';
    this.cntrSuffixEnabled=false;
    this.selectedSuffixList={suppliergsdbcode:''};
    this.disableCntrDetailsWhenBaseOrSuffixChanged=true;
    this.prevCntrSuffixInd=null;
    this.enteredCntrBase=null;

    this.selected_container=null;
    this.table_selected_container=null;

    this.cont_retype='Returnable';
    this.tableSelectedReturnType=null;

    this.isCmmsLoadedContainer=false;

    this.cont_retype='Returnable';
    this.containerDetailsCopied=false;
    this.cntrdetails_display_popup_init();
    this.selected_cntr_ownrship_strat_code_in_popup=this.cntrOwnershipstratcodelist[0];
    console.log('selectedCntrFromAutoCmplete while giving cancel',this.selectedCntrFromAutoCmplete);
    this.loadcntrdetails();
    this.validationInit();
}


onChangeRetTypeAddCntr()
{
let ret_type='';

           this.selected_cntr_material={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};

if(this.cntrdetailsadd.exp_refundable != '')
{
  if(this.selected_cntr_type_code != null && this.selected_cntr_type_code.cntr_typ_desc != '')
  {

    if(this.cntrdetailsadd.exp_refundable.toLowerCase() =='returnable')
    {
      ret_type= 'R';

    }
    else
    {
      ret_type= 'E';
    }

    // api to get material list


    this.transactionService.getContainerMaterial(this.selected_cntr_type_code.typ_no,ret_type).subscribe(mat_list=>{

      this.cntrMaterial=[];
      this.cntrMaterial=mat_list;

    })
    this.enable_material_dropdown=true;
  }
}



}

// material api changes

onChangeCntrTypAddCntr()
{
  let ret_type='';
  console.log(this.selected_cntr_type_code,this.cntrdetailsadd.exp_refundable)
  this.selected_cntr_material={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
  if(this.selected_cntr_type_code != null && this.selected_cntr_type_code.cntr_typ_desc != '')
  {
    if(this.cntrdetailsadd.exp_refundable != '')
    {


    if(this.cntrdetailsadd.exp_refundable.toLowerCase() == 'returnable')
    {
      ret_type= 'R';

    }
    else
    {
      ret_type= 'E';
    }


    this.transactionService.getContainerMaterial(this.selected_cntr_type_code.typ_no,ret_type).subscribe(mat_list=>{

      this.cntrMaterial=[];
      this.cntrMaterial=mat_list;

    })


    // api to get material list
    this.enable_material_dropdown=true;
  }
}


}



// material api changes

getMaterialValueToDisplay()
{

let get_ret_type=''
  if(this.get_selected_cntr_type_code != null && this.get_selected_cntr_type_code.cntr_typ_desc != '')
  {
    if(this.cntrdetails.exp_refundable != '')
    {

    if(this.cntrdetails.exp_refundable.toLowerCase() =='returnable')
    {
      get_ret_type= 'R';

    }
    else
    {
      get_ret_type= 'E';
    }

     this.edit_selected_cntr_material={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
    // api to get material list


    this.transactionService.getContainerMaterial(this.get_selected_cntr_type_code.typ_no,get_ret_type).subscribe(mat_list=>{

      this.cntrMaterial=[];
      this.cntrMaterial=mat_list;


    if(this.cntrMaterial!= null && this.cntrMaterial.length!=0 && this.cntrdetails != null && this.cntrdetails.cntr_matr_desc != null)
    {
    for(const t of this.cntrMaterial)
    {
      if(t.cntr_matr_name.toLowerCase() == this.cntrdetails.cntr_matr_desc.toLowerCase())
      {
        this.get_selected_cntr_material=t;
        break;
      }
    }
    }
  })
    this.enable_material_dropdown=true;
  }
  else
  {
    this.enable_material_dropdown=false;
     this.edit_selected_cntr_material={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
  }


}
console.log('UOMMATE');

if(this.unitom=='E'){
  this.cntrdetails.uom='E';
  console.log('UOMMATELB');
}
else{
  this.cntrdetails.uom='M';
  console.log('UOMMATEKG');
}

}
// material api changes

onChangeRetTypeEditCntr()
{
  console.log(this.cntrdetailsedit.cntr_matr_desc);

  let edit_ret_type='';

  if(this.cntrdetailsedit.exp_refundable != '')
  {
  if(this.edit_selected_cntr_type_code != null && this.edit_selected_cntr_type_code.cntr_typ_desc != '')
  {

    if(this.cntrdetailsedit.exp_refundable.toLowerCase() =='returnable')
    {
      edit_ret_type= 'R';

    }
    else
    {
      edit_ret_type= 'E';
    }
   this.edit_selected_cntr_material={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
    // api to get material list

    this.transactionService.getContainerMaterial(this.edit_selected_cntr_type_code.typ_no,edit_ret_type).subscribe(mat_list=>{

      this.cntrMaterial=[];
      this.cntrMaterial=mat_list;


    if(this.cntrMaterial!= null && this.cntrMaterial.length!=0 && this.cntrdetailsedit!= null && this.cntrdetailsedit.cntr_matr_desc != null)
    {
    for(const t of this.cntrMaterial)
    {
      if(t.cntr_matr_name.toLowerCase() == this.cntrdetailsedit.cntr_matr_desc.toLowerCase())
      {
        this.edit_selected_cntr_material=t;
        break;
      }
    }
    }

  })
    this.enable_material_dropdown=true;
  }
    else
  {
    this.enable_material_dropdown=false;
    this.edit_selected_cntr_material={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
  }


}

else
{
  this.enable_material_dropdown=false;
  this.edit_selected_cntr_material={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
}

if(this.unitom=='E'){
  this.cntrdetails.uom='E';
  console.log('UOMMATELB');
}
else{
  this.cntrdetails.uom='M';
  console.log('UOMMATEKG');
}


}

// material api changes

onChangeCntrTypEditCntr()
{


  console.log(this.cntrdetailsedit.cntr_matr_desc);

  let edit_ret_type='';


  if(this.edit_selected_cntr_type_code != null && this.edit_selected_cntr_type_code.cntr_typ_desc != '')
  {
    if(this.cntrdetailsedit.exp_refundable != '')
    {

    if(this.cntrdetailsedit.exp_refundable.toLowerCase() =='returnable')
    {
      edit_ret_type= 'R';

    }
    else
    {
      edit_ret_type= 'E';
    }

     this.edit_selected_cntr_material={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
    // api to get material list


    this.transactionService.getContainerMaterial(this.edit_selected_cntr_type_code.typ_no,edit_ret_type).subscribe(mat_list=>{

      this.cntrMaterial=[];
      this.cntrMaterial=mat_list;


    if(this.cntrMaterial!= null && this.cntrMaterial.length!=0 && this.cntrdetailsedit!= null && this.cntrdetailsedit.cntr_matr_desc != null)
    {
    for(const t of this.cntrMaterial)
    {
      if(t.cntr_matr_name.toLowerCase() == this.cntrdetailsedit.cntr_matr_desc.toLowerCase())
      {
        this.edit_selected_cntr_material=t;
        break;
      }
    }
    }
  })
    this.enable_material_dropdown=true;
  }
  else
  {
    this.enable_material_dropdown=false;
    this.edit_selected_cntr_material={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
  }


}

else
{
  this.enable_material_dropdown=false;
  this.edit_selected_cntr_material={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
}


}

edit_cntr_details(contrData:any)
{
  this.enable_material_dropdown=false;
  this.editcntrdetailsinit();
  console.log(contrData);

  if(this.unitom=='E')
      {
      this.edit_measure='lbs/inch';
      this.prev_edit_measure='lbs/inch';
      this.cntrdetailsedit.UoM='E';
      }
      else{
        this.edit_measure='kg/cm';
        this.prev_edit_measure='kg/cm'
        this.cntrdetailsedit.UoM='M';
      }

  console.log('containe code in edit button', contrData.cd_cntr);

  this.edit_selected_cntr_master_category={cd_cntr_mstr_catg:0,cntr_mstr_catg_desc:'',updated_by:'',updated_dt:'',cntr_mstr_catg:'',active_stat:'',active_stat_display:false};
    this.edit_selected_cntr_type_code={cd_cntr_typ:'',cntr_typ_desc:'',typ_no:0};
    this.edit_selected_cntr_ownrship_strat_code={cd_region:'',ownrshp_strat_code:'',ownrshp_desc:'',cd_ownrshp_strat:0};
    this.edit_selected_ownrship_catg={ownrshp_catg:'',ownrshp_catg_val:''};
    this.edit_selected_cntr_group={cntr_grp:''}
    this.edit_selected_cntr_material={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
    this.edit_selected_cntr_currency_list={cd_curr:0,curr_desc:''};
    this.editSelectedPlantCode={cd_plant:'',plantGSDBCode:'',plant_name:'',plant_active_stat:'',plant_type_name:''}

    if(contrData.req_stat != null && contrData.req_stat.toLowerCase() =='pending')
    {
      this.enableProcessingNotifiacationDialog=true;
    }


    else
    {


  if(this.enabledetails==true)
  {
    this.enabledetails=false
  }

  if(this.enableeditgetdetails==true)
  {
    this.enableeditgetdetails=false

  }

this.editing=false;

  if(this.cntrAddEnable=true)
  {
    this.cntrAddEnable=false;
  }
  if(this.cntrAddDetailsEnable=true)
  {
    this.cntrAddDetailsEnable=false;
  }


  this.cntrCmmsFieldsBeforeUpdateInit();

  this.transactionService.editbutton_get_contr_details(contrData.cntr_base,contrData.cd_cntr).subscribe(savecntrdetails=>{
    this.validationInit();

    this.enableeditcntrdetails=true;
    this.cntrdetailsedit.function='C';
    this.cntrdetailsedit.cntr_base=savecntrdetails.cntr_base;
    this.cntrdetailsedit.cntr_suff_ind=savecntrdetails.cntr_suff_ind;
    this.cntrdetailsedit.exp_refundable=savecntrdetails.exp_refundable;
    this.cntrdetailsedit.cntr_active_ind=savecntrdetails.cntr_active_ind;
    this.cntrdetailsedit.cntr_suffix=savecntrdetails.cntr_suffix;
    // this.cntrdetailsedit.cntr_suffix=this.selectedSuffixList.suppliergsdbcode;
    if(savecntrdetails.cntr_desc != null)
    {
    this.cntrdetailsedit.cntr_desc=savecntrdetails.cntr_desc.trim();
    }
    this.cntrdetailsedit.card_call=savecntrdetails.card_call;
    this.cntrdetailsedit.cntr_mstr_catg=savecntrdetails.cntr_mstr_catg;
    this.cntrdetailsedit.cntr_typ_desc=savecntrdetails.cntr_typ_desc;
    this.cntrdetailsedit.typ_no=savecntrdetails.typ_no;
    this.cntrdetailsedit.ownrshp_strat_code=savecntrdetails.ownrshp_strat_code;
    this.cntrdetailsedit.ownrshp_catg=savecntrdetails.ownrshp_catg;
    this.cntrdetailsedit.cntr_grp_desc=savecntrdetails.cntr_grp_desc;
    this.cntrdetailsedit.cntr_matr_desc=savecntrdetails.cntr_matr_desc;
    this.cntrdetailsedit.currency_desc=savecntrdetails.currency_desc;
    this.cntrdetailsedit.iscontainercolapsble=savecntrdetails.iscontainercolapsble;

    this.view_modify_cd_cntr=savecntrdetails.cd_cntr;

    this.edit_cntr_cd_cntr=savecntrdetails.cd_cntr;


  /* Removing add suffix to container
  if(this.cntrdetailsedit.cntr_suff_ind=='Yes')
  {
    this.cntrSuffixEditenabled=true;
  }
  else
  {
    this.cntrSuffixEditenabled=false;
  }*/


      this.cntrSuffixEditenabled=false;

  if(this.cntrMasterCategory != null && this.cntrMasterCategory.length != 0)
  {
    for(let i=0;i<this.cntrMasterCategory.length;i++)
    {
      if(this.cntrdetailsedit.cntr_mstr_catg != null && this.cntrdetailsedit.cntr_mstr_catg==this.cntrMasterCategory[i].cntr_mstr_catg)
      {
        this.edit_selected_cntr_master_category=this.cntrMasterCategory[i];
      }
    }
  }

  if(this.cntrTypeCode != null && this.cntrTypeCode.length != 0)
  {
    for(let i=0;i<this.cntrTypeCode.length;i++)
    {
      if(this.cntrdetailsedit.typ_no != null && this.cntrdetailsedit.typ_no==this.cntrTypeCode[i].typ_no)
      {
        this.edit_selected_cntr_type_code=this.cntrTypeCode[i];
        console.log('edit_selected_cntr_type_code',this.edit_selected_cntr_type_code);
      }
    }
  }

  if(this.cntrOwnershipstratcode != null && this.cntrOwnershipstratcode.length != 0)
  {
    for(let i=0;i<this.cntrOwnershipstratcode.length;i++)
    {
      if(this.cntrdetailsedit.ownrshp_strat_code != null && this.cntrdetailsedit.ownrshp_strat_code==this.cntrOwnershipstratcode[i].ownrshp_strat_code)
      {
        this.edit_selected_cntr_ownrship_strat_code=this.cntrOwnershipstratcode[i];
      }
    }
  }

  if(this.ownrship_catg != null && this.ownrship_catg.length != 0)
  {
    for(let i=0;i<this.ownrship_catg.length;i++)
    {
       if(this.cntrdetailsedit.ownrshp_catg != null && this.cntrdetailsedit.ownrshp_catg.toLowerCase().indexOf(this.ownrship_catg[i].ownrshp_catg_val.toLowerCase())>-1)
      {
        this.edit_selected_ownrship_catg=this.ownrship_catg[i];
      }
    }
  }

  if(this.cntrGrp != null && this.cntrGrp.length != 0)
  {
    for(let i=0;i<this.cntrGrp.length;i++)
    {
      if(this.cntrdetailsedit.cntr_grp_desc != null && this.cntrdetailsedit.cntr_grp_desc==this.cntrGrp[i].cntr_grp)
      {
        this.edit_selected_cntr_group=this.cntrGrp[i];
      }
    }
  }

    // for(let i=0;i<this.cntrMaterial.length;i++)
    // {
    //   if(this.cntrdetailsedit.cntr_matr_desc==this.cntrMaterial[i].cntr_matr_name)
    //   {
    //     this.edit_selected_cntr_material=this.cntrMaterial[i];
    //   }
    // }
    this.onChangeCntrTypEditCntr();

    if(this.cntrCurrencyList != null && this.cntrCurrencyList.length != 0)
    {
    for(let i=0;i<this.cntrCurrencyList.length;i++)
    {
      if(this.cntrdetailsedit.currency_desc != null && this.cntrdetailsedit.currency_desc==this.cntrCurrencyList[i].curr_desc)
      {
        this.edit_selected_cntr_currency_list=this.cntrCurrencyList[i];
      }
    }
  }

    this.cntrdetailsedit.cntr_exterior_l=savecntrdetails.cntr_exterior_l;
    this.cntrdetailsedit.cntr_exterior_w=savecntrdetails.cntr_exterior_w;
    this.cntrdetailsedit.cntr_exterior_h=savecntrdetails.cntr_exterior_h;
    this.cntrdetailsedit.collapsed_ht=savecntrdetails.collapsed_ht;
    this.cntrdetailsedit.nest_ratio=savecntrdetails.nest_ratio;
    this.cntrdetailsedit.cntr_interior_l=savecntrdetails.cntr_interior_l;
    this.cntrdetailsedit.cntr_interior_w=savecntrdetails.cntr_interior_w;
    this.cntrdetailsedit.cntr_interior_h=savecntrdetails.cntr_interior_h;
    this.cntrdetailsedit.cntr_tare_wt=savecntrdetails.cntr_tare_wt;
    this.cntrdetailsedit.cntr_capacity=savecntrdetails.cntr_capacity;
    this.cntrdetailsedit.cntr_per_layer=savecntrdetails.cntr_per_layer;
    this.cntrdetailsedit.max_layers_shpg_unit=savecntrdetails.max_layers_shpg_unit;
    this.cntrdetailsedit.max_cntr_per_shpg_unit=savecntrdetails.max_cntr_per_shpg_unit;
    this.cntrdetailsedit.cntr_drawing_no=savecntrdetails.cntr_drawing_no;
    this.cntrdetailsedit.shpg_unit_exterior_l=savecntrdetails.shpg_unit_exterior_l;
    this.cntrdetailsedit.shpg_unit_exterior_w=savecntrdetails.shpg_unit_exterior_w;
    this.cntrdetailsedit.shpg_unit_exterior_h=savecntrdetails.shpg_unit_exterior_h;
    this.cntrdetailsedit.shpg_unit_nstdorflded_ht=savecntrdetails.shpg_unit_nstdorflded_ht;
    this.cntrdetailsedit.shpg_unit_tare_wt=savecntrdetails.shpg_unit_tare_wt;
    this.cntrdetailsedit.shpg_unit_stack_ht=savecntrdetails.shpg_unit_stack_ht;
    this.cntrdetailsedit.warehouse_stack_ht=savecntrdetails.warehouse_stack_ht;
    this.cntrdetailsedit.service_life=savecntrdetails.service_life;
    this.cntrdetailsedit.purchase_cost=savecntrdetails.purchase_cost;
    this.cntrdetailsedit.rental_issue_cost=savecntrdetails.rental_issue_cost;
    this.cntrdetailsedit.sup_rntl_dly_cost=savecntrdetails.sup_rntl_dly_cost;
    this.cntrdetailsedit.ford_rntl_dly_cost=savecntrdetails.ford_rntl_dly_cost;
    this.cntrdetailsedit.return_rntl_cost=savecntrdetails.return_rntl_cost;
    this.cntrdetailsedit.tooling_dev_cost=savecntrdetails.tooling_dev_cost;
    this.cntrdetailsedit.extr_cntr_dsgn_img_ind=savecntrdetails.extr_cntr_dsgn_img_ind;
    this.cntrdetailsedit.intr_cntr_dsgn_img_ind=savecntrdetails.intr_cntr_dsgn_img_ind;
    this.cntrdetailsedit.cad_img_ind=savecntrdetails.cad_img_ind;
    this.cntrdetailsedit.img_notes=savecntrdetails.img_notes;
    this.cntrdetailsedit.cd_region=savecntrdetails.cd_region;

    console.log('cntrdetailsedit',this.cntrdetailsedit);


    this.cntrCmmsFieldsBeforeUpdate.cntr_base=JSON.parse(JSON.stringify(this.cntrdetailsedit.cntr_base))
    this.cntrCmmsFieldsBeforeUpdate.cntr_suffix=JSON.parse(JSON.stringify(this.cntrdetailsedit.cntr_suffix))
    this.cntrCmmsFieldsBeforeUpdate.cntr_desc=JSON.parse(JSON.stringify(this.cntrdetailsedit.cntr_desc))
    this.cntrCmmsFieldsBeforeUpdate.cntr_typ_desc=JSON.parse(JSON.stringify(this.cntrdetailsedit.cntr_typ_desc))
    this.cntrCmmsFieldsBeforeUpdate.cntr_exterior_l=JSON.parse(JSON.stringify(this.cntrdetailsedit.cntr_exterior_l))
    this.cntrCmmsFieldsBeforeUpdate.cntr_exterior_w=JSON.parse(JSON.stringify(this.cntrdetailsedit.cntr_exterior_w))
    this.cntrCmmsFieldsBeforeUpdate.cntr_exterior_h=JSON.parse(JSON.stringify(this.cntrdetailsedit.cntr_exterior_h))
    this.cntrCmmsFieldsBeforeUpdate.collapsed_ht=JSON.parse(JSON.stringify(this.cntrdetailsedit.collapsed_ht))
    this.cntrCmmsFieldsBeforeUpdate.nest_ratio=JSON.parse(JSON.stringify(this.cntrdetailsedit.nest_ratio))
    this.cntrCmmsFieldsBeforeUpdate.cntr_capacity=JSON.parse(JSON.stringify(this.cntrdetailsedit.cntr_capacity))
    this.cntrCmmsFieldsBeforeUpdate.cntr_tare_wt=JSON.parse(JSON.stringify(this.cntrdetailsedit.cntr_tare_wt))
    this.cntrCmmsFieldsBeforeUpdate.shpg_unit_stack_ht=JSON.parse(JSON.stringify(this.cntrdetailsedit.shpg_unit_stack_ht))
    this.cntrCmmsFieldsBeforeUpdate.warehouse_stack_ht=JSON.parse(JSON.stringify(this.cntrdetailsedit.warehouse_stack_ht))
    this.cntrCmmsFieldsBeforeUpdate.ownrshp_catg=JSON.parse(JSON.stringify(this.cntrdetailsedit.ownrshp_catg))
    



    if(savecntrdetails.cd_plant != null)
    {
    this.cntrdetailsedit.cd_plant= savecntrdetails.cd_plant;
    for(let i=0;i<this.plantList.length;i++)
    {
      if(this.cntrdetailsedit.cd_plant.trim()==this.plantList[i].plantGSDBCode)
      {
        this.editSelectedPlantCode=this.plantList[i];
        console.log('edit_selected_plant_code',this.editSelectedPlantCode);
      }
    }
    this.cntrCmmsFieldsBeforeUpdate.cd_plant=JSON.parse(JSON.stringify(this.cntrdetailsedit.cd_plant))
    }
    if(savecntrdetails.cnt_qt_per_min_su != null)
    {
      this.cntrdetailsedit.cntr_qt_per_min_su=savecntrdetails.cnt_qt_per_min_su;
      this.cntrCmmsFieldsBeforeUpdate.cntr_qt_per_min_su=JSON.parse(JSON.stringify(this.cntrdetailsedit.cntr_qt_per_min_su))
    }
    if(savecntrdetails.nest_ratio_1 != null)
    {
      this.cntrdetailsedit.nest_ratio_1=savecntrdetails.nest_ratio_1;
      this.cntrCmmsFieldsBeforeUpdate.nest_ratio_1=JSON.parse(JSON.stringify(this.cntrdetailsedit.nest_ratio_1))
    }
    if(savecntrdetails.ford_cntct != null)
    {
      this.cntrdetailsedit.ford_cntct= savecntrdetails.ford_cntct.trim();
      this.cntrCmmsFieldsBeforeUpdate.ford_cntct=JSON.parse(JSON.stringify(this.cntrdetailsedit.ford_cntct))
    }
    if(savecntrdetails.cntct_phone != null)
    {
      this.cntrdetailsedit.cntct_phone=savecntrdetails.cntct_phone.trim();
      this.cntrCmmsFieldsBeforeUpdate.cntct_phone=JSON.parse(JSON.stringify(this.cntrdetailsedit.cntct_phone))
    }


    this.cntrdetailsedit.req_stat='New';
    this.cntrdetailsedit.id_user=this.transactionService.getcdsid();;
    this.cntrdetailsedit.ts_last_update='';
    // if(savecntrdetails.uom != null && savecntrdetails.uom.trim() != "")
    // {
    // this.cntrdetailsedit.UoM=savecntrdetails.uom;
    // }
    // else
    // {
    //   this.cntrdetailsedit.UoM = "E"
    // }

    // if(this.cntrdetailsedit.UoM.toLowerCase() == "m")
    // {

    //   this.edit_measure='kg/cm'
    //   this.prev_edit_measure='kg/cm'
    // }
    // else
    // {
    //   this.edit_measure='lbs/inch'
    //   this.prev_edit_measure='lbs/inch'
    // }



    // this.cntrdetailsedit.components=[];
  })
}

}




geteditedcntrdetails(editedcntrdetails:any)
{

  this.cntrdetailsinit();
  this.enabledetails=true;

    if(this.unitom=='E')
      {
        this.get_measure='lbs/inch';
        this.prev_get_measure='lbs/inch';
        this.cntrdetails.uom='E';
        console.log('UOMGETLB',this.unitom,this.prev_get_measure,this.get_measure,this.cntrdetails.uom);
      }
      else{
        this.get_measure='kg/cm';
        this.prev_get_measure='kg/cm';
        this.cntrdetails.uom='M';
        console.log('UOMGETKG',this.unitom,this.prev_get_measure,this.get_measure,this.cntrdetails.uom);
      }


  console.log()

this.get_selected_cntr_master_category={cd_cntr_mstr_catg:0,cntr_mstr_catg_desc:'',updated_by:'',updated_dt:'',cntr_mstr_catg:'',active_stat:'',active_stat_display:false};
this.get_selected_cntr_type_code={cd_cntr_typ:'',cntr_typ_desc:'',typ_no:0};
this.get_selected_cntr_ownrship_strat_code={cd_region:'',ownrshp_strat_code:'',ownrshp_desc:'',cd_ownrshp_strat:0};
this.get_selected_ownrship_catg={ownrshp_catg:'',ownrshp_catg_val:''};
this.get_selected_cntr_group={cntr_grp:''}
this.get_selected_cntr_material={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
this.get_selected_cntr_currency_list={cd_curr:0,curr_desc:''};

  if(this.enableeditgetdetails==true)
  {
    this.enableeditgetdetails=false;

  }
  if(this.enableeditcntrdetails==true)
  {
    this.enableeditcntrdetails=false;
  }
  this.editing=false;

  if(this.cntrAddEnable==true)
  {
    this.cntrAddEnable=false;
  }

  if(this.cntrAddDetailsEnable==true)
  {
    this.cntrAddDetailsEnable=false;
  }

  this.transactionService.editbutton_get_contr_details(editedcntrdetails.cntr_base,editedcntrdetails.cd_cntr).subscribe((getcntrdetails=>{
    this.cntrdetails=getcntrdetails;

   /* if(String(this.cntrdetails.cntr_typ_desc)=="01")
    {
      this.containerOrComponentInDetails="container"
    }
    else
    {
      this.containerOrComponentInDetails="component";
    }*/

    for(let i=0;i<this.cntrMasterCategory.length;i++)
    {
      if(this.cntrdetails.cntr_mstr_catg==this.cntrMasterCategory[i].cntr_mstr_catg)
      {
        this.get_selected_cntr_master_category=this.cntrMasterCategory[i];

      }
    }

    for(let i=0;i<this.unmodifiedTypeCodes.length;i++)
    {
      if(this.cntrdetails.typ_no != null && this.cntrdetails.typ_no==this.unmodifiedTypeCodes[i].typ_no)
      {
        this.get_selected_cntr_type_code=this.unmodifiedTypeCodes[i];
        console.log('edit_selected_cntr_type_code',this.get_selected_cntr_type_code);
      }
    }

    for(let i=0;i<this.cntrOwnershipstratcode.length;i++)
    {
      if(this.cntrdetails.ownrshp_strat_code==this.cntrOwnershipstratcode[i].ownrshp_strat_code)
      {
        this.get_selected_cntr_ownrship_strat_code=this.cntrOwnershipstratcode[i];
      }
    }

    for(let i=0;i<this.ownrship_catg.length;i++)
    {
      if(this.cntrdetails.ownrshp_catg.toLowerCase().indexOf(this.ownrship_catg[i].ownrshp_catg_val.toLowerCase())>-1)
      {
        this.get_selected_ownrship_catg=this.ownrship_catg[i];
      }
    }

    for(let i=0;i<this.cntrGrp.length;i++)
    {
      if(this.cntrdetails.cntr_grp_desc==this.cntrGrp[i].cntr_grp)
      {
        this.get_selected_cntr_group=this.cntrGrp[i];
      }
    }

    // for(let i=0;i<this.cntrMaterial.length;i++)
    // {
    //   if(this.cntrdetails.cntr_matr_desc==this.cntrMaterial[i].cntr_matr_name)
    //   {
    //     this.get_selected_cntr_material=this.cntrMaterial[i];
    //   }
    // }

    this.getMaterialValueToDisplay();

    for(let i=0;i<this.cntrCurrencyList.length;i++)
    {
      if(this.cntrdetails.currency_desc==this.cntrCurrencyList[i].curr_desc)
      {
        this.get_selected_cntr_currency_list=this.cntrCurrencyList[i];
      }
    }


    // if(this.cntrdetails.uom == null || this.cntrdetails.uom.trim() == "")
    // {
    //   this.cntrdetails.uom = "E"
    // }

    // if(this.cntrdetails.uom.toLowerCase() == "m")
    // {

        if(this.unitom=='M')
      {
       this.get_measure='kg/cm'
       this.prev_get_measure='kg/cm'
       this.cntrdetails.uom.toLowerCase() == 'm'
       console.log('UOM',this.unitom,this.cntrdetails.uom);
     }
     else
     {
       this.get_measure='lbs/inch'
       this.prev_get_measure='lbs/inch'
       this.cntrdetails.uom.toLowerCase() == 'e'
       console.log('UOM',this.unitom,this.cntrdetails.uom);
     }

  }))




}


// change_get_measure()
// {


//   console.log(this.get_measure);

//   if(this.unitom=="E")
//   {
//     this.get_measure='lbs/inch';
//     this.prev_get_measure='lbs/inch'
//   }
//   else
// {
//   this.get_measure='kg/cm'
//   this.prev_get_measure='kg/cm'
// }
// //   if(this.get_measure=='lbs/inch')
// //   {
// //     if(this.prev_get_measure!='lbs/inch')
// //     {
// //       console.log('inside lbs')
// //       if(this.containerOrComponentInDetails.toLowerCase() == "container")
// //       {
// //         if(this.cntrdetails.collapsed_ht!=null)
// //         {
// //             this.cntrdetails.collapsed_ht=Math.round((0.393701*this.cntrdetails.collapsed_ht)*1000)/1000;
// //         }
// //         if(this.cntrdetails.cntr_interior_l!=null)
// //         {
// //             this.cntrdetails.cntr_interior_l=Math.round((0.393701*this.cntrdetails.cntr_interior_l)*1000)/1000;
// //         }
// //         if(this.cntrdetails.cntr_interior_w!=null)
// //         {
// //                 this.cntrdetails.cntr_interior_w=Math.round((0.393701*this.cntrdetails.cntr_interior_w)*1000)/1000;
// //         }
// //         if(this.cntrdetails.cntr_interior_h!=null)
// //         {
// //                 this.cntrdetails.cntr_interior_h=Math.round((0.393701*this.cntrdetails.cntr_interior_h)*1000)/1000;
// //         }
// //         if(this.cntrdetails.cntr_tare_wt!=null)
// //         {
// //                 this.cntrdetails.cntr_tare_wt=Math.round((2.20462*this.cntrdetails.cntr_tare_wt)*1000)/1000;
// //         }
// //         if(this.cntrdetails.cntr_capacity!=null)
// //         {
// //                 this.cntrdetails.cntr_capacity=Math.round((0.393701*0.393701*0.393701*this.cntrdetails.cntr_capacity)*1000)/1000;
// //         }

// //         if(this.cntrdetails.cntr_exterior_l!=null)
// //         {
// //                 this.cntrdetails.cntr_exterior_l=Math.round((0.393701*this.cntrdetails.cntr_exterior_l)*1000)/1000;
// //         }
// //         if(this.cntrdetails.cntr_exterior_w!=null)
// //         {
// //                 this.cntrdetails.cntr_exterior_w=Math.round((0.393701*this.cntrdetails.cntr_exterior_w)*1000)/1000;
// //         }
// //         if(this.cntrdetails.cntr_exterior_h!=null)
// //         {
// //                 this.cntrdetails.cntr_exterior_h=Math.round((0.393701*this.cntrdetails.cntr_exterior_h)*1000)/1000;
// //         }
// //         if(this.cntrdetails.shpg_unit_exterior_l!=null)
// //         {
// //               this.cntrdetails.shpg_unit_exterior_l=Math.round((0.393701*this.cntrdetails.shpg_unit_exterior_l)*1000)/1000;
// //         }
// //         if(this.cntrdetails.shpg_unit_exterior_w!=null)
// //         {
// //                 this.cntrdetails.shpg_unit_exterior_w=Math.round((0.393701*this.cntrdetails.shpg_unit_exterior_w)*1000)/1000;
// //         }
// //         if(this.cntrdetails.shpg_unit_exterior_h!=null)
// //         {
// //                 this.cntrdetails.shpg_unit_exterior_h=Math.round((0.393701*this.cntrdetails.shpg_unit_exterior_h)*1000)/1000;
// //         }
// //         if(this.cntrdetails.shpg_unit_nstdorflded_ht!=null)
// //         {
// //                 this.cntrdetails.shpg_unit_nstdorflded_ht=Math.round((0.393701*this.cntrdetails.shpg_unit_nstdorflded_ht)*1000)/1000;
// //         }
// //         if(this.cntrdetails.shpg_unit_tare_wt!=null)
// //         {
// //                 this.cntrdetails.shpg_unit_tare_wt=Math.round((2.20462*this.cntrdetails.shpg_unit_tare_wt)*1000)/1000;
// //         }
// //         if(this.cntrdetails.shpg_unit_stack_ht!=null)
// //         {
// //                 this.cntrdetails.shpg_unit_stack_ht=Math.round((0.393701*this.cntrdetails.shpg_unit_stack_ht)*1000)/1000;
// //         }
// //         if(this.cntrdetails.warehouse_stack_ht!=null)
// //         {
// //                 this.cntrdetails.warehouse_stack_ht=Math.round((0.393701*this.cntrdetails.warehouse_stack_ht)*1000)/1000;
// //         }

// //         this.prev_get_measure='lbs/inch'
// //       }

// //       else
// //       {

// //         if(this.compdetails.cntr_tare_wt!=null)
// //         {
// //                 this.compdetails.cntr_tare_wt=Math.round((2.20462*this.compdetails.cntr_tare_wt)*1000)/1000;
// //         }
// //         if(this.compdetails.cntr_capacity!=null)
// //         {
// //                 this.compdetails.cntr_capacity=Math.round((0.393701*0.393701*0.393701*this.compdetails.cntr_capacity)*1000)/1000;
// //         }

// //         if(this.compdetails.cntr_exterior_l!=null)
// //         {
// //                 this.compdetails.cntr_exterior_l=Math.round((0.393701*this.compdetails.cntr_exterior_l)*1000)/1000;
// //         }
// //         if(this.compdetails.cntr_exterior_w!=null)
// //         {
// //                 this.compdetails.cntr_exterior_w=Math.round((0.393701*this.compdetails.cntr_exterior_w)*1000)/1000;
// //         }
// //         if(this.compdetails.cntr_exterior_h!=null)
// //         {
// //                 this.compdetails.cntr_exterior_h=Math.round((0.393701*this.compdetails.cntr_exterior_h)*1000)/1000;
// //         }

// //         if(this.compdetails.warehouse_stack_ht!=null)
// //         {
// //                 this.compdetails.warehouse_stack_ht=Math.round((0.393701*this.compdetails.warehouse_stack_ht)*1000)/1000;
// //         }

// //         this.prev_get_measure='lbs/inch'

// //       }
// //   }

// // }




//   // else if(this.get_measure=='kg/cm')
//   // {

//   //   if(this.prev_get_measure!='kg/cm')
//   //   {
//   //     console.log('inside kg')

//   //     if(this.containerOrComponentInDetails.toLowerCase() == "container")
//   //     {

//   //         if(this.cntrdetails.collapsed_ht!=null)
//   //         {
//   //             this.cntrdetails.collapsed_ht=Math.round((2.54*this.cntrdetails.collapsed_ht)*1000)/1000;
//   //         }
//   //         if(this.cntrdetails.cntr_interior_l!=null)
//   //         {
//   //             this.cntrdetails.cntr_interior_l=Math.round((2.54*this.cntrdetails.cntr_interior_l)*1000)/1000;
//   //         }
//   //         if(this.cntrdetails.cntr_interior_w!=null)
//   //         {
//   //                 this.cntrdetails.cntr_interior_w=Math.round((2.54*this.cntrdetails.cntr_interior_w)*1000)/1000;
//   //         }
//   //         if(this.cntrdetails.cntr_interior_h!=null)
//   //         {
//   //                 this.cntrdetails.cntr_interior_h=Math.round((2.54*this.cntrdetails.cntr_interior_h)*1000)/1000;
//   //         }
//   //         if(this.cntrdetails.cntr_tare_wt!=null)
//   //         {
//   //                 this.cntrdetails.cntr_tare_wt=Math.round((0.453592*this.cntrdetails.cntr_tare_wt)*1000)/1000;
//   //         }
//   //         if(this.cntrdetails.cntr_capacity!=null)
//   //         {
//   //                 this.cntrdetails.cntr_capacity=Math.round((2.54*2.54*2.54*this.cntrdetails.cntr_capacity)*1000)/1000;
//   //         }

//   //         if(this.cntrdetails.cntr_exterior_l!=null)
//   //         {
//   //                 this.cntrdetails.cntr_exterior_l=Math.round((2.54*this.cntrdetails.cntr_exterior_l)*1000)/1000;
//   //         }
//   //         if(this.cntrdetails.cntr_exterior_w!=null)
//   //         {
//   //                 this.cntrdetails.cntr_exterior_w=Math.round((2.54*this.cntrdetails.cntr_exterior_w)*1000)/1000;
//   //         }
//   //         if(this.cntrdetails.cntr_exterior_h!=null)
//   //         {
//   //                 this.cntrdetails.cntr_exterior_h=Math.round((2.54*this.cntrdetails.cntr_exterior_h)*1000)/1000;
//   //         }
//   //         if(this.cntrdetails.shpg_unit_exterior_l!=null)
//   //         {
//   //               this.cntrdetails.shpg_unit_exterior_l=Math.round((2.54*this.cntrdetails.shpg_unit_exterior_l)*1000)/1000;
//   //         }
//   //         if(this.cntrdetails.shpg_unit_exterior_w!=null)
//   //         {
//   //                 this.cntrdetails.shpg_unit_exterior_w=Math.round((2.54*this.cntrdetails.shpg_unit_exterior_w)*1000)/1000;
//   //         }
//   //         if(this.cntrdetails.shpg_unit_exterior_h!=null)
//   //         {
//   //                 this.cntrdetails.shpg_unit_exterior_h=Math.round((2.54*this.cntrdetails.shpg_unit_exterior_h)*1000)/1000;
//   //         }
//   //         if(this.cntrdetails.shpg_unit_nstdorflded_ht!=null)
//   //         {
//   //                 this.cntrdetails.shpg_unit_nstdorflded_ht=Math.round((2.54*this.cntrdetails.shpg_unit_nstdorflded_ht)*1000)/1000;
//   //         }
//   //         if(this.cntrdetails.shpg_unit_tare_wt!=null)
//   //         {
//   //                 this.cntrdetails.shpg_unit_tare_wt=Math.round((0.453592*this.cntrdetails.shpg_unit_tare_wt)*1000)/1000;
//   //         }
//   //         if(this.cntrdetails.shpg_unit_stack_ht!=null)
//   //         {
//   //                 this.cntrdetails.shpg_unit_stack_ht=Math.round((2.54*this.cntrdetails.shpg_unit_stack_ht)*1000)/1000;
//   //         }
//   //         if(this.cntrdetails.warehouse_stack_ht!=null)
//   //         {
//   //                 this.cntrdetails.warehouse_stack_ht=Math.round((2.54*this.cntrdetails.warehouse_stack_ht)*1000)/1000;
//   //         }
//   //     this.prev_get_measure='kg/cm';
//   //       }


//   //       else
//   //       {



//   //         if(this.compdetails.cntr_tare_wt!=null)
//   //         {
//   //                 this.compdetails.cntr_tare_wt=Math.round((0.453592*this.compdetails.cntr_tare_wt)*1000)/1000;
//   //         }
//   //         if(this.compdetails.cntr_capacity!=null)
//   //         {
//   //                 this.compdetails.cntr_capacity=Math.round((2.54*2.54*2.54*this.compdetails.cntr_capacity)*1000)/1000;
//   //         }

//   //         if(this.compdetails.cntr_exterior_l!=null)
//   //         {
//   //                 this.compdetails.cntr_exterior_l=Math.round((2.54*this.compdetails.cntr_exterior_l)*1000)/1000;
//   //         }
//   //         if(this.compdetails.cntr_exterior_w!=null)
//   //         {
//   //                 this.compdetails.cntr_exterior_w=Math.round((2.54*this.compdetails.cntr_exterior_w)*1000)/1000;
//   //         }
//   //         if(this.compdetails.cntr_exterior_h!=null)
//   //         {
//   //                 this.compdetails.cntr_exterior_h=Math.round((2.54*this.compdetails.cntr_exterior_h)*1000)/1000;
//   //         }

//   //         if(this.compdetails.warehouse_stack_ht!=null)
//   //         {
//   //                 this.compdetails.warehouse_stack_ht=Math.round((2.54*this.compdetails.warehouse_stack_ht)*1000)/1000;
//   //         }
//   //         this.prev_get_measure='kg/cm';

//   //       }

//   // }

//   // }

// }

getcntrdetails_edit()
{


  if(this.cntrdetails.req_stat != null && this.cntrdetails.req_stat.toLowerCase() =='pending')
  {
    this.enableProcessingNotifiacationDialog=true;
  }


  else
  {
  this.enabledetails=false;
  this.enableeditgetdetails=true;
  this.editing=false;
  this.enable_material_dropdown=false;

  this.editcntrdetailsinit();

  if(this.unitom=='E')
  {
    this.get_measure='lbs/inch';
    this.edit_measure='lbs/inch';
    this.prev_get_measure='lbs/inch'
    this.cntrdetails.uom='E'
  }
  else
  {
    this.get_measure='kg/cm'
    this.edit_measure='kg/cm'
    this.prev_get_measure='kg/cm'
    this.cntrdetails.uom='M'
  }



this.edit_selected_cntr_master_category={cd_cntr_mstr_catg:0,cntr_mstr_catg_desc:'',updated_by:'',updated_dt:'',cntr_mstr_catg:'',active_stat:'',active_stat_display:false};
this.edit_selected_cntr_type_code={cd_cntr_typ:'',cntr_typ_desc:'',typ_no:0};
this.edit_selected_cntr_ownrship_strat_code={cd_region:'',ownrshp_strat_code:'',ownrshp_desc:'',cd_ownrshp_strat:0};
this.edit_selected_ownrship_catg={ownrshp_catg:'',ownrshp_catg_val:''};
this.edit_selected_cntr_group={cntr_grp:''}
this.edit_selected_cntr_material={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
this.edit_selected_cntr_currency_list={cd_curr:0,curr_desc:''};
this.editSelectedPlantCode={cd_plant:'',plantGSDBCode:'',plant_name:'',plant_active_stat:'',plant_type_name:''}

  this.edit_measure=this.get_measure;
  this.prev_edit_measure=this.get_measure;

  console.log('container details on clicking edit after save button',this.cntrdetails);

        this.cntrCmmsFieldsBeforeUpdateInit();




   // this.cntrdetailsedit.cd_cntr=this.cntrdetails.cd_cntr;
   this.cntrdetailsedit.function='C';
    this.cntrdetailsedit.cntr_base=this.cntrdetails.cntr_base;
    this.cntrdetailsedit.cntr_suff_ind=this.cntrdetails.cntr_suff_ind;
    this.cntrdetailsedit.exp_refundable=this.cntrdetails.exp_refundable;
    this.cntrdetailsedit.cntr_active_ind=this.cntrdetails.cntr_active_ind;
    this.cntrdetailsedit.cntr_suffix=this.cntrdetails.cntr_suffix;
    if(this.cntrdetails.cntr_desc != null)
    {
    this.cntrdetailsedit.cntr_desc=this.cntrdetails.cntr_desc.trim();
    }
    this.cntrdetailsedit.card_call=this.cntrdetails.card_call;
    this.cntrdetailsedit.cntr_mstr_catg=this.cntrdetails.cntr_mstr_catg;
    this.cntrdetailsedit.cntr_typ_desc=this.cntrdetails.cntr_typ_desc;
    this.cntrdetailsedit.typ_no=this.cntrdetails.typ_no;
    this.cntrdetailsedit.ownrshp_strat_code=this.cntrdetails.ownrshp_strat_code;
    this.cntrdetailsedit.ownrshp_catg=this.cntrdetails.ownrshp_catg;
    this.cntrdetailsedit.cntr_grp_desc=this.cntrdetails.cntr_grp_desc;
    this.cntrdetailsedit.cntr_matr_desc=this.cntrdetails.cntr_matr_desc;
    this.cntrdetailsedit.currency_desc=this.cntrdetails.currency_desc;
    this.cntrdetailsedit.iscontainercolapsble=this.cntrdetails.iscontainercolapsble;
    this.view_modify_cd_cntr=this.cntrdetails.cd_cntr;


    this.edit_cntr_cd_cntr=this.cntrdetails.cd_cntr;


    if(this.cntrMasterCategory != null && this.cntrMasterCategory.length != 0)
    {
    for(let i=0;i<this.cntrMasterCategory.length;i++)
    {
      if(this.cntrdetailsedit.cntr_mstr_catg != null && this.cntrdetailsedit.cntr_mstr_catg==this.cntrMasterCategory[i].cntr_mstr_catg)
      {
        this.edit_selected_cntr_master_category=this.cntrMasterCategory[i];
      }
    }
    }

    for(let i=0;i<this.unmodifiedTypeCodes.length;i++)
    {
      if(this.cntrdetailsedit.typ_no != null && this.cntrdetailsedit.typ_no==this.unmodifiedTypeCodes[i].typ_no)
      {
        this.edit_selected_cntr_type_code=this.unmodifiedTypeCodes[i];
        console.log('edit_selected_cntr_type_code',this.edit_selected_cntr_type_code);
      }
    }
  if(this.cntrOwnershipstratcode != null && this.cntrOwnershipstratcode.length != 0)
  {
    for(let i=0;i<this.cntrOwnershipstratcode.length;i++)
    {
      if(this.cntrdetailsedit.ownrshp_strat_code != null && this.cntrdetailsedit.ownrshp_strat_code==this.cntrOwnershipstratcode[i].ownrshp_strat_code)
      {
        this.edit_selected_cntr_ownrship_strat_code=this.cntrOwnershipstratcode[i];
      }
    }
  }

  if(this.ownrship_catg != null && this.ownrship_catg.length != 0)
  {
    for(let i=0;i<this.ownrship_catg.length;i++)
    {
      if(this.cntrdetailsedit.ownrshp_catg != null && this.cntrdetailsedit.ownrshp_catg.toLowerCase().indexOf(this.ownrship_catg[i].ownrshp_catg_val.toLowerCase())>-1)
      {
        this.edit_selected_ownrship_catg=this.ownrship_catg[i];
      }
    }
  }

  if(this.cntrGrp != null && this.cntrGrp.length != 0)
  {
    for(let i=0;i<this.cntrGrp.length;i++)
    {
      if(this.cntrdetailsedit.cntr_grp_desc != null && this.cntrdetailsedit.cntr_grp_desc==this.cntrGrp[i].cntr_grp)
      {
        this.edit_selected_cntr_group=this.cntrGrp[i];
      }
    }
  }

    // for(let i=0;i<this.cntrMaterial.length;i++)
    // {
    //   if(this.cntrdetailsedit.cntr_matr_desc==this.cntrMaterial[i].cntr_matr_name)
    //   {
    //     this.edit_selected_cntr_material=this.cntrMaterial[i];
    //   }
    // }

    this.onChangeCntrTypEditCntr()

    if(this.cntrCurrencyList.length != null && this.cntrCurrencyList.length != 0)
    {
    for(let i=0;i<this.cntrCurrencyList.length;i++)
    {
      if(this.cntrdetailsedit.currency_desc != null && this.cntrdetailsedit.currency_desc==this.cntrCurrencyList[i].curr_desc)
      {
        // alert("came here")
        this.edit_selected_cntr_currency_list=this.cntrCurrencyList[i];
      }
    }
  }

    this.cntrdetailsedit.cntr_exterior_l=this.cntrdetails.cntr_exterior_l;
    this.cntrdetailsedit.cntr_exterior_w=this.cntrdetails.cntr_exterior_w;
    this.cntrdetailsedit.cntr_exterior_h=this.cntrdetails.cntr_exterior_h;
    this.cntrdetailsedit.collapsed_ht=this.cntrdetails.collapsed_ht;
    this.cntrdetailsedit.nest_ratio=this.cntrdetails.nest_ratio;
    this.cntrdetailsedit.cntr_interior_l=this.cntrdetails.cntr_interior_l;
    this.cntrdetailsedit.cntr_interior_w=this.cntrdetails.cntr_interior_w;
    this.cntrdetailsedit.cntr_interior_h=this.cntrdetails.cntr_interior_h;
    this.cntrdetailsedit.cntr_tare_wt=this.cntrdetails.cntr_tare_wt;
    this.cntrdetailsedit.cntr_capacity=this.cntrdetails.cntr_capacity;
    this.cntrdetailsedit.cntr_per_layer=this.cntrdetails.cntr_per_layer;
    this.cntrdetailsedit.max_layers_shpg_unit=this.cntrdetails.max_layers_shpg_unit;
    this.cntrdetailsedit.max_cntr_per_shpg_unit=this.cntrdetails.max_cntr_per_shpg_unit;
    this.cntrdetailsedit.cntr_drawing_no=this.cntrdetails.cntr_drawing_no;
    this.cntrdetailsedit.shpg_unit_exterior_l=this.cntrdetails.shpg_unit_exterior_l;
    this.cntrdetailsedit.shpg_unit_exterior_w=this.cntrdetails.shpg_unit_exterior_w;
    this.cntrdetailsedit.shpg_unit_exterior_h=this.cntrdetails.shpg_unit_exterior_h;
    this.cntrdetailsedit.shpg_unit_nstdorflded_ht=this.cntrdetails.shpg_unit_nstdorflded_ht;
    this.cntrdetailsedit.shpg_unit_tare_wt=this.cntrdetails.shpg_unit_tare_wt;
    this.cntrdetailsedit.shpg_unit_stack_ht=this.cntrdetails.shpg_unit_stack_ht;
    this.cntrdetailsedit.warehouse_stack_ht=this.cntrdetails.warehouse_stack_ht;
    this.cntrdetailsedit.service_life=this.cntrdetails.service_life;
    this.cntrdetailsedit.purchase_cost=this.cntrdetails.purchase_cost;
    this.cntrdetailsedit.rental_issue_cost=this.cntrdetails.rental_issue_cost;
    this.cntrdetailsedit.sup_rntl_dly_cost=this.cntrdetails.sup_rntl_dly_cost;
    this.cntrdetailsedit.ford_rntl_dly_cost=this.cntrdetails.ford_rntl_dly_cost;
    this.cntrdetailsedit.return_rntl_cost=this.cntrdetails.return_rntl_cost;
    this.cntrdetailsedit.tooling_dev_cost=this.cntrdetails.tooling_dev_cost;
    this.cntrdetailsedit.extr_cntr_dsgn_img_ind=this.cntrdetails.extr_cntr_dsgn_img_ind;
    this.cntrdetailsedit.intr_cntr_dsgn_img_ind=this.cntrdetails.intr_cntr_dsgn_img_ind;
    this.cntrdetailsedit.cad_img_ind=this.cntrdetails.cad_img_ind;
    this.cntrdetailsedit.img_notes=this.cntrdetails.img_notes;
    this.cntrdetailsedit.cd_region=this.cntrdetails.cd_region;
    // added uom field
    // this.cntrdetailsedit.UoM=this.cntrdetails.uom

    // verify to add uom field
    this.cntrCmmsFieldsBeforeUpdate.cntr_base=JSON.parse(JSON.stringify(this.cntrdetailsedit.cntr_base))
    this.cntrCmmsFieldsBeforeUpdate.cntr_suffix=JSON.parse(JSON.stringify(this.cntrdetailsedit.cntr_suffix))
    this.cntrCmmsFieldsBeforeUpdate.cntr_desc=JSON.parse(JSON.stringify(this.cntrdetailsedit.cntr_desc))
    this.cntrCmmsFieldsBeforeUpdate.cntr_typ_desc=JSON.parse(JSON.stringify(this.cntrdetailsedit.cntr_typ_desc))
    this.cntrCmmsFieldsBeforeUpdate.cntr_exterior_l=JSON.parse(JSON.stringify(this.cntrdetailsedit.cntr_exterior_l))
    this.cntrCmmsFieldsBeforeUpdate.cntr_exterior_w=JSON.parse(JSON.stringify(this.cntrdetailsedit.cntr_exterior_w))
    this.cntrCmmsFieldsBeforeUpdate.cntr_exterior_h=JSON.parse(JSON.stringify(this.cntrdetailsedit.cntr_exterior_h))
    this.cntrCmmsFieldsBeforeUpdate.collapsed_ht=JSON.parse(JSON.stringify(this.cntrdetailsedit.collapsed_ht))
    this.cntrCmmsFieldsBeforeUpdate.nest_ratio=JSON.parse(JSON.stringify(this.cntrdetailsedit.nest_ratio))
    this.cntrCmmsFieldsBeforeUpdate.cntr_capacity=JSON.parse(JSON.stringify(this.cntrdetailsedit.cntr_capacity))
    this.cntrCmmsFieldsBeforeUpdate.cntr_tare_wt=JSON.parse(JSON.stringify(this.cntrdetailsedit.cntr_tare_wt))
    this.cntrCmmsFieldsBeforeUpdate.shpg_unit_stack_ht=JSON.parse(JSON.stringify(this.cntrdetailsedit.shpg_unit_stack_ht))
    this.cntrCmmsFieldsBeforeUpdate.warehouse_stack_ht=JSON.parse(JSON.stringify(this.cntrdetailsedit.warehouse_stack_ht))
    this.cntrCmmsFieldsBeforeUpdate.ownrshp_catg=JSON.parse(JSON.stringify(this.cntrdetailsedit.ownrshp_catg))
    


    if(this.cntrdetails.cd_plant != null)
    {
    this.cntrdetailsedit.cd_plant= this.cntrdetails.cd_plant;
    for(let i=0;i<this.plantList.length;i++)
    {
      if(this.cntrdetailsedit.cd_plant.trim()==this.plantList[i].plantGSDBCode)
      {
        this.editSelectedPlantCode=this.plantList[i];
        console.log('edit_selected_plant_code',this.editSelectedPlantCode);
      }
    }
    this.cntrCmmsFieldsBeforeUpdate.cd_plant=JSON.parse(JSON.stringify(this.cntrdetailsedit.cd_plant))
    }
    if(this.cntrdetails.cnt_qt_per_min_su != null)
    {
      this.cntrdetailsedit.cntr_qt_per_min_su=this.cntrdetails.cnt_qt_per_min_su;
      this.cntrCmmsFieldsBeforeUpdate.cntr_qt_per_min_su=JSON.parse(JSON.stringify(this.cntrdetailsedit.cntr_qt_per_min_su))
    }
    if(this.cntrdetails.nest_ratio_1 != null)
    {
      this.cntrdetailsedit.nest_ratio_1=this.cntrdetails.nest_ratio_1;
      this.cntrCmmsFieldsBeforeUpdate.nest_ratio_1=JSON.parse(JSON.stringify(this.cntrdetailsedit.nest_ratio_1))
    }
    if(this.cntrdetails.ford_cntct != null)
    {
      this.cntrdetailsedit.ford_cntct= this.cntrdetails.ford_cntct.trim();
      this.cntrCmmsFieldsBeforeUpdate.ford_cntct=JSON.parse(JSON.stringify(this.cntrdetailsedit.ford_cntct))

    }
    if(this.cntrdetails.cntct_phone != null)
    {
      this.cntrdetailsedit.cntct_phone=this.cntrdetails.cntct_phone.trim();
      this.cntrCmmsFieldsBeforeUpdate.cntct_phone=JSON.parse(JSON.stringify(this.cntrdetailsedit.cntct_phone))
    }




    if(this.cntrdetailsedit.service_life==null)
    {
      this.cntrdetailsedit.service_life='';
    }
   /* Removing add suffix to container
    if(this.cntrdetailsedit.cntr_suff_ind=='Yes')

    {
      this.cntrSuffixEditenabled=true;
    }
    else
    {
      this.cntrSuffixEditenabled=false;
    }
    */


    this.cntrSuffixEditenabled=false;
  }
}

getcntrdetails_cancel()
{
  this.enabledetails=false;
  this.cntrAddEnable=true;
  this.cntrdetailsinit();
}




// change_edit_measure()
// {
//   console.log(this.edit_measure);


//   if(this.unitom=="E")
//   {
//     this.edit_measure='lbs/inch';
//     this.prev_edit_measure='lbs/inch'
//   }
//   else
// {
//   this.edit_measure='kg/cm'
//   this.prev_edit_measure='kg/cm'
// }

// //   if(this.edit_measure=='lbs/inch')
// //   {
// //     if(this.prev_edit_measure!='lbs/inch')
// //     {
// //       console.log('inside lbs')
// //       if(this.containerOrComponentInDetails.toLowerCase() == "container")
// //       {
// //           if(this.cntrdetailsedit.collapsed_ht!=null)
// //           {
// //               this.cntrdetailsedit.collapsed_ht=Math.round((0.393701*this.cntrdetailsedit.collapsed_ht)*1000)/1000;
// //           }
// //           if(this.cntrdetailsedit.cntr_interior_l!=null)
// //           {
// //               this.cntrdetailsedit.cntr_interior_l=Math.round((0.393701*this.cntrdetailsedit.cntr_interior_l)*1000)/1000;
// //           }
// //           if(this.cntrdetailsedit.cntr_interior_w!=null)
// //           {
// //                   this.cntrdetailsedit.cntr_interior_w=Math.round((0.393701*this.cntrdetailsedit.cntr_interior_w)*1000)/1000;
// //           }
// //           if(this.cntrdetailsedit.cntr_interior_h!=null)
// //           {
// //                   this.cntrdetailsedit.cntr_interior_h=Math.round((0.393701*this.cntrdetailsedit.cntr_interior_h)*1000)/1000;
// //           }
// //           if(this.cntrdetailsedit.cntr_tare_wt!=null)
// //           {
// //                   this.cntrdetailsedit.cntr_tare_wt=Math.round((2.20462*this.cntrdetailsedit.cntr_tare_wt)*1000)/1000;
// //           }
// //           if(this.cntrdetailsedit.cntr_capacity!=null)
// //           {
// //                   this.cntrdetailsedit.cntr_capacity=Math.round((0.393701*0.393701*0.393701*this.cntrdetailsedit.cntr_capacity)*1000)/1000;
// //           }

// //           if(this.cntrdetailsedit.cntr_exterior_l!=null)
// //           {
// //                   this.cntrdetailsedit.cntr_exterior_l=Math.round((0.393701*this.cntrdetailsedit.cntr_exterior_l)*1000)/1000;
// //           }
// //           if(this.cntrdetailsedit.cntr_exterior_w!=null)
// //           {
// //                   this.cntrdetailsedit.cntr_exterior_w=Math.round((0.393701*this.cntrdetailsedit.cntr_exterior_w)*1000)/1000;
// //           }
// //           if(this.cntrdetailsedit.cntr_exterior_h!=null)
// //           {
// //                   this.cntrdetailsedit.cntr_exterior_h=Math.round((0.393701*this.cntrdetailsedit.cntr_exterior_h)*1000)/1000;
// //           }
// //           if(this.cntrdetailsedit.shpg_unit_exterior_l!=null)
// //           {
// //                 this.cntrdetailsedit.shpg_unit_exterior_l=Math.round((0.393701*this.cntrdetailsedit.shpg_unit_exterior_l)*1000)/1000;
// //           }
// //           if(this.cntrdetailsedit.shpg_unit_exterior_w!=null)
// //           {
// //                   this.cntrdetailsedit.shpg_unit_exterior_w=Math.round((0.393701*this.cntrdetailsedit.shpg_unit_exterior_w)*1000)/1000;
// //           }
// //           if(this.cntrdetailsedit.shpg_unit_exterior_h!=null)
// //           {
// //                   this.cntrdetailsedit.shpg_unit_exterior_h=Math.round((0.393701*this.cntrdetailsedit.shpg_unit_exterior_h)*1000)/1000;
// //           }
// //           if(this.cntrdetailsedit.shpg_unit_nstdorflded_ht!=null)
// //           {
// //                   this.cntrdetailsedit.shpg_unit_nstdorflded_ht=Math.round((0.393701*this.cntrdetailsedit.shpg_unit_nstdorflded_ht)*1000)/1000;
// //           }
// //           if(this.cntrdetailsedit.shpg_unit_tare_wt!=null)
// //           {
// //                   this.cntrdetailsedit.shpg_unit_tare_wt=Math.round((2.20462*this.cntrdetailsedit.shpg_unit_tare_wt)*1000)/1000;
// //           }
// //           if(this.cntrdetailsedit.shpg_unit_stack_ht!=null)
// //           {
// //                   this.cntrdetailsedit.shpg_unit_stack_ht=Math.round((0.393701*this.cntrdetailsedit.shpg_unit_stack_ht)*1000)/1000;
// //           }
// //           if(this.cntrdetailsedit.warehouse_stack_ht!=null)
// //           {
// //                   this.cntrdetailsedit.warehouse_stack_ht=Math.round((0.393701*this.cntrdetailsedit.warehouse_stack_ht)*1000)/1000;
// //           }

// //           this.prev_edit_measure='lbs/inch'
// //         }
// //         else
// //         {

// //           if(this.compdetailsedit.cntr_tare_wt!=null)
// //           {
// //                   this.compdetailsedit.cntr_tare_wt=Math.round((2.20462*this.compdetailsedit.cntr_tare_wt)*1000)/1000;
// //           }
// //           if(this.compdetailsedit.cntr_capacity!=null)
// //           {
// //                   this.compdetailsedit.cntr_capacity=Math.round((0.393701*0.393701*0.393701*this.compdetailsedit.cntr_capacity)*1000)/1000;
// //           }

// //           if(this.compdetailsedit.cntr_exterior_l!=null)
// //           {
// //                   this.compdetailsedit.cntr_exterior_l=Math.round((0.393701*this.compdetailsedit.cntr_exterior_l)*1000)/1000;
// //           }
// //           if(this.compdetailsedit.cntr_exterior_w!=null)
// //           {
// //                   this.compdetailsedit.cntr_exterior_w=Math.round((0.393701*this.compdetailsedit.cntr_exterior_w)*1000)/1000;
// //           }
// //           if(this.compdetailsedit.cntr_exterior_h!=null)
// //           {
// //                   this.compdetailsedit.cntr_exterior_h=Math.round((0.393701*this.compdetailsedit.cntr_exterior_h)*1000)/1000;
// //           }

// //           if(this.compdetailsedit.warehouse_stack_ht!=null)
// //           {
// //                   this.compdetailsedit.warehouse_stack_ht=Math.round((0.393701*this.compdetailsedit.warehouse_stack_ht)*1000)/1000;
// //           }

// //           this.prev_edit_measure='lbs/inch'

// //         }
// //   }

// // }

// //   else if(this.edit_measure=='kg/cm')
// //   {

// //     if(this.prev_edit_measure!='kg/cm')
// //     {

// //       console.log('inside kg')
// //       if(this.containerOrComponentInDetails.toLowerCase() == "container")
// //       {

// //             if(this.cntrdetailsedit.collapsed_ht!=null)
// //             {
// //                 this.cntrdetailsedit.collapsed_ht=Math.round((2.54*this.cntrdetailsedit.collapsed_ht)*1000)/1000;
// //             }
// //             if(this.cntrdetailsedit.cntr_interior_l!=null)
// //             {
// //                 this.cntrdetailsedit.cntr_interior_l=Math.round((2.54*this.cntrdetailsedit.cntr_interior_l)*1000)/1000;
// //             }
// //             if(this.cntrdetailsedit.cntr_interior_w!=null)
// //             {
// //                     this.cntrdetailsedit.cntr_interior_w=Math.round((2.54*this.cntrdetailsedit.cntr_interior_w)*1000)/1000;
// //             }
// //             if(this.cntrdetailsedit.cntr_interior_h!=null)
// //             {
// //                     this.cntrdetailsedit.cntr_interior_h=Math.round((2.54*this.cntrdetailsedit.cntr_interior_h)*1000)/1000;
// //             }
// //             if(this.cntrdetailsedit.cntr_tare_wt!=null)
// //             {
// //                     this.cntrdetailsedit.cntr_tare_wt=Math.round((0.453592*this.cntrdetailsedit.cntr_tare_wt)*1000)/1000;
// //             }
// //             if(this.cntrdetailsedit.cntr_capacity!=null)
// //             {
// //                     this.cntrdetailsedit.cntr_capacity=Math.round((2.54*2.54*2.54*this.cntrdetailsedit.cntr_capacity)*1000)/1000;
// //             }

// //             if(this.cntrdetailsedit.cntr_exterior_l!=null)
// //             {
// //                     this.cntrdetailsedit.cntr_exterior_l=Math.round((2.54*this.cntrdetailsedit.cntr_exterior_l)*1000)/1000;
// //             }
// //             if(this.cntrdetailsedit.cntr_exterior_w!=null)
// //             {
// //                     this.cntrdetailsedit.cntr_exterior_w=Math.round((2.54*this.cntrdetailsedit.cntr_exterior_w)*1000)/1000;
// //             }
// //             if(this.cntrdetailsedit.cntr_exterior_h!=null)
// //             {
// //                     this.cntrdetailsedit.cntr_exterior_h=Math.round((2.54*this.cntrdetailsedit.cntr_exterior_h)*1000)/1000;
// //             }
// //             if(this.cntrdetailsedit.shpg_unit_exterior_l!=null)
// //             {
// //                   this.cntrdetailsedit.shpg_unit_exterior_l=Math.round((2.54*this.cntrdetailsedit.shpg_unit_exterior_l)*1000)/1000;
// //             }
// //             if(this.cntrdetailsedit.shpg_unit_exterior_w!=null)
// //             {
// //                     this.cntrdetailsedit.shpg_unit_exterior_w=Math.round((2.54*this.cntrdetailsedit.shpg_unit_exterior_w)*1000)/1000;
// //             }
// //             if(this.cntrdetailsedit.shpg_unit_exterior_h!=null)
// //             {
// //                     this.cntrdetailsedit.shpg_unit_exterior_h=Math.round((2.54*this.cntrdetailsedit.shpg_unit_exterior_h)*1000)/1000;
// //             }
// //             if(this.cntrdetailsedit.shpg_unit_nstdorflded_ht!=null)
// //             {
// //                     this.cntrdetailsedit.shpg_unit_nstdorflded_ht=Math.round((2.54*this.cntrdetailsedit.shpg_unit_nstdorflded_ht)*1000)/1000;
// //             }
// //             if(this.cntrdetailsedit.shpg_unit_tare_wt!=null)
// //             {
// //                     this.cntrdetailsedit.shpg_unit_tare_wt=Math.round((0.453592*this.cntrdetailsedit.shpg_unit_tare_wt)*1000)/1000;
// //             }
// //             if(this.cntrdetailsedit.shpg_unit_stack_ht!=null)
// //             {
// //                     this.cntrdetailsedit.shpg_unit_stack_ht=Math.round((2.54*this.cntrdetailsedit.shpg_unit_stack_ht)*1000)/1000;
// //             }
// //             if(this.cntrdetailsedit.warehouse_stack_ht!=null)
// //             {
// //                     this.cntrdetailsedit.warehouse_stack_ht=Math.round((2.54*this.cntrdetailsedit.warehouse_stack_ht)*1000)/1000;
// //             }
// //         this.prev_edit_measure='kg/cm';
// //           }
// //           else
// //           {


// //               if(this.compdetailsedit.cntr_tare_wt!=null)
// //               {
// //                       this.compdetailsedit.cntr_tare_wt=Math.round((0.453592*this.compdetailsedit.cntr_tare_wt)*1000)/1000;
// //               }
// //               if(this.compdetailsedit.cntr_capacity!=null)
// //               {
// //                       this.compdetailsedit.cntr_capacity=Math.round((2.54*2.54*2.54*this.compdetailsedit.cntr_capacity)*1000)/1000;
// //               }

// //               if(this.compdetailsedit.cntr_exterior_l!=null)
// //               {
// //                       this.compdetailsedit.cntr_exterior_l=Math.round((2.54*this.compdetailsedit.cntr_exterior_l)*1000)/1000;
// //               }
// //               if(this.compdetailsedit.cntr_exterior_w!=null)
// //               {
// //                       this.compdetailsedit.cntr_exterior_w=Math.round((2.54*this.compdetailsedit.cntr_exterior_w)*1000)/1000;
// //               }
// //               if(this.compdetailsedit.cntr_exterior_h!=null)
// //               {
// //                       this.compdetailsedit.cntr_exterior_h=Math.round((2.54*this.compdetailsedit.cntr_exterior_h)*1000)/1000;
// //               }

// //               if(this.compdetailsedit.warehouse_stack_ht!=null)
// //               {
// //                       this.compdetailsedit.warehouse_stack_ht=Math.round((2.54*this.compdetailsedit.warehouse_stack_ht)*1000)/1000;
// //               }
// //           this.prev_edit_measure='kg/cm';
// //           }

// //   }

// //   }

// }




validateCntrBaseForEdit()
{
  if(this.cntrdetailsedit.cntr_base==null || this.cntrdetailsedit.cntr_base.trim()=='')
  {
    this.cntrBaseInvalid=true;
    this.cntrBaseInvalidReason='Required'
  }
  else
  {
    this.cntrBaseInvalid=false;
    this.cntrBaseInvalidReason=null
  }
}

validateCntrDescForEdit()
{
  if(this.cntrdetailsedit.cntr_desc==null || this.cntrdetailsedit.cntr_desc.trim()=='')
  {
    this.cntrDescInvalid=true;
    this.cntrDescInvalidReason='Required'
  }
  else
  {
    this.cntrDescInvalid=false;
    this.cntrDescInvalidReason=null
  }
}

validatePlantCodeForEdit()
{
  if(this.editSelectedPlantCode.plantGSDBCode==null || this.editSelectedPlantCode.plantGSDBCode.trim()=='')
  {
    this.plantCodeInvalid=true;
    this.plantCodeInvalidReason='Required'
  }
  else
  {
    this.plantCodeInvalid=false;
    this.plantCodeInvalidReason=null
  }
}

// validateCardOrCallForEdit()
// {
//   if(this.cntrdetailsedit.card_call==null || this.cntrdetailsedit.card_call.trim()=="")
//   {
//     this.cardOrCallInvalid=true;
//     this.cardOrCallInvalidReason="Required"
//   }
//   else
//   {
//     this.cardOrCallInvalid=false;
//     this.cardOrCallInvalidReason=null
//   }
// }


validateMastCatgForEdit()
{
  if(this.edit_selected_cntr_master_category.cntr_mstr_catg==null || this.edit_selected_cntr_master_category.cntr_mstr_catg.trim()=='')
  {
    this.mastrCatgInvalid=true;
    this.mastrCatgInvalidReason='Required'
  }
  else
  {
    this.mastrCatgInvalid=false;
    this.mastrCatgInvalidReason=null
  }
}

validateCntrRetTypeForEdit()
{
  if(this.cntrdetailsedit.exp_refundable==null || this.cntrdetailsedit.exp_refundable.trim()=='')
  {
    this.retTypeInvalid=true;
    this.retTypeInvalidReason='Required'
  }
  else
  {
    this.retTypeInvalid=false;
    this.retTypeInvalidReason=null;
  }
}

validateCntrTypeCodeForEdit()
{
  if(this.edit_selected_cntr_type_code.cntr_typ_desc==null || this.edit_selected_cntr_type_code.cntr_typ_desc.trim()=='')
  {
    this.cntrTypeInvalid=true;
    this.cntrTypeInvalidReason='Required';
  }
  else
  {
    this.cntrTypeInvalid=false;
    this.cntrTypeInvalidReason=null;
  }
}

validateCntrMaterialForEdit()
{
  if(this.edit_selected_cntr_material.cntr_matr_name==null || this.edit_selected_cntr_material.cntr_matr_name.trim()=='')
  {
    this.cntrMaterialInvalid=true;
    this.cntrMaterialInvalidReason='Required';
  }
  else
  {
    this.cntrMaterialInvalid=false;
    this.cntrMaterialInvalidReason=null;
  }
}
validateCntrOwnershpCatgForEdit()
{
  if(this.edit_selected_ownrship_catg.ownrshp_catg_val==null || this.edit_selected_ownrship_catg.ownrshp_catg_val.trim()=='')
  {
    this.cntrOwnerShpCatgInvalid=true;
    this.cntrOwnerShpCatgInvalidReason='Required';
  }
  else
  {
    this.cntrOwnerShpCatgInvalid=false;
    this.cntrOwnerShpCatgInvalidReason=null;
  }
}
validateCOSForEdit()
{
  if(this.edit_selected_cntr_ownrship_strat_code.ownrshp_strat_code==null || this.edit_selected_cntr_ownrship_strat_code.ownrshp_strat_code.trim()=='')
  {
    this.cosInvalid=true;
    this.cosInvalidReason='Required';
  }
  else
  {
    this.cosInvalid=false;
    this.cosInvalidReason=null;
  }
}

validateCntrGrpForEdit()
{
  if(this.edit_selected_cntr_group.cntr_grp==null || this.edit_selected_cntr_group.cntr_grp.trim()=='')
  {
    this.cntrGrpInvalid=true;
    this.cntrGrpInvalidReason='Required';
  }
  else
  {
    this.cntrGrpInvalid=false;
    this.cntrGrpInvalidReason=null;
  }
}

validateCntrCollapsibleForEdit()
{
  if(this.cntrdetailsedit.iscontainercolapsble==null || this.cntrdetailsedit.iscontainercolapsble.trim()=='')
  {
    this.isCollapsibleInvalid=true;
    this.isCollapsibleInvalidReason='Required';
  }
  else{
    this.isCollapsibleInvalid=false;
    this.isCollapsibleInvalidReason=null;
  }
}

validateCntrContactForEdit()
{
  if(this.cntrdetailsedit.ford_cntct==null || this.cntrdetailsedit.ford_cntct.trim()=='')
  {
    this.cntrCntctInvalid=true;
    this.cntrCntctInvalidReason='Required';
  }
  else
  {
    this.cntrCntctInvalid=false;
    this.cntrCntctInvalidReason=null;
  }

}

validateCntrContactPhoneForEdit()
{
  if(this.cntrdetailsedit.cntct_phone==null || this.cntrdetailsedit.cntct_phone.trim()=='')
  {
    this.cntctPhoneInvalid=true;
    this.cntctPhoneInvlidReason='Required';
  }
  else
  {
    this.cntctPhoneInvalid=false;
    this.cntctPhoneInvlidReason=null;
  }

}

validateCntrActiveForEdit()
{
  if(this.cntrdetailsedit.cntr_active_ind==null || this.cntrdetailsedit.cntr_active_ind.trim()=='')
  {
    this.cntrActiveInvalid=true;
    this.cntrActiveInvalidReason='Required';
  }
  else
  {
    this.cntrActiveInvalid=false;
    this.cntrActiveInvalidReason=null;
  }
}


validateCollapsedHtForEdit()
{
  if(this.cntrdetailsedit.iscontainercolapsble.toLowerCase()=='y' && (this.cntrdetailsedit.collapsed_ht==null || this.cntrdetailsedit.collapsed_ht==0))
  {
    this.collapsedHtInValid=true;
    this.collapsedHtInValidReason='Required';
  }

  else if(this.cntrdetailsedit.iscontainercolapsble.toLowerCase()=='y' && (this.cntrdetailsedit.collapsed_ht<=0 || this.cntrdetailsedit.collapsed_ht>999.999))
  {
    this.collapsedHtInValid=true;
    this.collapsedHtInValidReason='Should be of range 0 to 999.999';
  }
  else if(this.cntrdetailsedit.iscontainercolapsble.toLowerCase()=='y' && this.cntrdetailsedit.collapsed_ht > this.cntrdetailsedit.cntr_exterior_h)
  {
    this.collapsedHtInValid=true;
    this.collapsedHtInValidReason='Collapsed Height cannot be greater than Container height';
  }
  else if(this.cntrdetailsedit.iscontainercolapsble.toLowerCase()=='y' && String(this.cntrdetailsedit.collapsed_ht).includes('.'))
    {
      const colap_ht = String(this.cntrdetailsedit.collapsed_ht).split('.')
      if(colap_ht[1].length > 3)
      {
        this.collapsedHtInValid=true;
        this.collapsedHtInValidReason='Max Length Of decimal values can be 3';
      }
      else
      {
        this.collapsedHtInValid=false;
        this.collapsedHtInValidReason=null;
      }
    }
  else
  {
    this.collapsedHtInValid=false;
    this.collapsedHtInValidReason=null;
  }

}

validateNestRatioForEdit()
{
  if(this.cntrdetailsedit.nest_ratio==null || this.cntrdetailsedit.nest_ratio==0)
  {
    this.nestRatioInValid=true;
    this.nestRatioInValidReason='Required';
  }
  else if(this.cntrdetailsedit.nest_ratio<1 || this.cntrdetailsedit.nest_ratio>99 || String(this.cntrdetailsedit.nest_ratio).includes('.'))
  {
    this.nestRatioInValid=true;
    this.nestRatioInValidReason='Should be of range 1 to 99 and cannot contain Decimal';
  }
  else
  {
    this.nestRatioInValid=false;
    this.nestRatioInValidReason=null;
  }

}
validateNestRatio1ForEdit()
{
    if(this.cntrdetailsedit.nest_ratio_1==null || this.cntrdetailsedit.nest_ratio_1==0)
    {
      this.nestRatio1InValid=true;
      this.nestRatio1InValidReason='Required';
    }
    else if(this.cntrdetailsedit.nest_ratio_1<1 || this.cntrdetailsedit.nest_ratio_1>9 || String(this.cntrdetailsedit.nest_ratio_1).includes('.'))
    {
      this.nestRatio1InValid=true;
      this.nestRatio1InValidReason='Should be of range 1 to 9 and cannot contain Decimal';
    }
    else
    {
      this.nestRatio1InValid=false;
      this.nestRatio1InValidReason=null;
    }
}
validateCntrTareWeightForEdit()
{
  const cont_tar_wt = String(this.cntrdetailsedit.cntr_tare_wt).split('.');
  if(this.cntrdetailsedit.cntr_tare_wt==null || this.cntrdetailsedit.cntr_tare_wt==0)
  {
    this.cntrTareWtInValid=true;
    this.cntrTareWtInValidReason='Required';
  }
  else if(!String(this.cntrdetailsedit.cntr_tare_wt).includes('.'))
     {
        /*if( String(this.cntrdetailsedit.cntr_tare_wt).length>10)
         {
           this.cntrTareWtInValid=true;
           this.cntrTareWtInValidReason="Max Length Of Characters can be 10";
         }
         else*/
         if(this.cntrdetailsedit.cntr_tare_wt<=0 || this.cntrdetailsedit.cntr_tare_wt>99999.999)
         {
           this.cntrTareWtInValid=true;
           this.cntrTareWtInValidReason='Should be of range 0 to 99999.999';
         }
       else
         {
           this.cntrTareWtInValid=false;
           this.cntrTareWtInValidReason=null;
         }
     }
     else if(String(this.cntrdetailsedit.cntr_tare_wt).includes('.'))
     {

         if(String(cont_tar_wt[0]).length<0 || String(cont_tar_wt[0]).length>5)
         {
           this.cntrTareWtInValid=true;
           this.cntrTareWtInValidReason='Should be of range 0 to 99999.999';
         }
         else if(String(cont_tar_wt[1]).length<0 || String(cont_tar_wt[1]).length>3)
         {
           this.cntrTareWtInValid=true;
           this.cntrTareWtInValidReason='Max Length Of decimal values can be 3';
         }
         else
         {
           this.cntrTareWtInValid=false;
           this.cntrTareWtInValidReason=null;
       }
     }
     else
     {
     this.cntrTareWtInValid=false;
     this.cntrTareWtInValidReason=null;
     }
 }
validateCntrCapWtForEdit()
{
  const cont_cap_wt = String(this.cntrdetailsedit.cntr_capacity).split('.');
  if(this.cntrdetailsedit.cntr_capacity==null || this.cntrdetailsedit.cntr_capacity==0)
  {
    this.cntrCapWtInValid=true;
    this.cntrCapWtInValidReason='Required';
  }
  else if(this.cntrdetailsedit.cntr_capacity<=0 || this.cntrdetailsedit.cntr_capacity>99999.999)
  {
    this.cntrCapWtInValid=true;
    this.cntrCapWtInValidReason='Should be of range 0 to 99999.999';
  }
  else if(String(this.cntrdetailsedit.cntr_capacity).includes('.'))
  {
    if(cont_cap_wt[1].length > 3)
    {
      this.cntrCapWtInValid=true;
      this.cntrCapWtInValidReason='Max Length Of decimal values can be 3';
    }
    else
    {
      this.cntrCapWtInValid=false;
      this.cntrCapWtInValidReason=null;
    }
  }
  else
  {
    this.cntrCapWtInValid=false;
    this.cntrCapWtInValidReason=null;
  }
}
validateCntrPerLayerForEdit()
{
  if(this.cntrdetailsedit.cntr_per_layer==null || this.cntrdetailsedit.cntr_per_layer==0)
  {
    this.cntrPerLayerInValid=true;
    this.cntrPerLayerInValidReason='Required';
  }
  else if(this.cntrdetailsedit.cntr_per_layer<1 || this.cntrdetailsedit.cntr_per_layer>99999 || String(this.cntrdetailsedit.cntr_per_layer).includes('.'))
  {
    this.cntrPerLayerInValid=true;
    this.cntrPerLayerInValidReason='Should be of range 1 to 99999 and cannot contain Decimal';
  }
  else
  {
    this.cntrPerLayerInValid=false;
    this.cntrPerLayerInValidReason=null;
  }
}
validateMaxLayerPerSuForEdit()
{
  if(this.cntrdetailsedit.max_layers_shpg_unit==null || this.cntrdetailsedit.max_layers_shpg_unit==0)
  {
    this.maxLayerPerSuInValid=true;
    this.maxLayerPerSuInValidReason='Required';
  }
  else if(this.cntrdetailsedit.max_layers_shpg_unit<1 || this.cntrdetailsedit.max_layers_shpg_unit>99999 || String(this.cntrdetailsedit.max_layers_shpg_unit).includes('.'))
  {
    this.maxLayerPerSuInValid=true;
    this.maxLayerPerSuInValidReason='Should be of range 1 to 99999  and cannot contain Decimal';
  }
  else
  {
    this.maxLayerPerSuInValid=false;
    this.maxLayerPerSuInValidReason=null;
  }
}
validateCntrQtPerMinSuForEdit()
{
  if(this.cntrdetailsedit.cntr_qt_per_min_su==null || this.cntrdetailsedit.cntr_qt_per_min_su==0)
  {
    this.cntrQtPerMinSuInValid=true;
    this.cntrQtPerMinSuInvalidReason='Required';
  }
  else if(this.cntrdetailsedit.cntr_qt_per_min_su<1 || this.cntrdetailsedit.cntr_qt_per_min_su>99 || String(this.cntrdetailsedit.cntr_qt_per_min_su).includes('.'))
  {
    this.cntrQtPerMinSuInValid=true;
    this.cntrQtPerMinSuInvalidReason='Should be of range 1 to 99  and cannot contain Decimal';
  }
  else
  {
    this.cntrQtPerMinSuInValid=false;
    this.cntrQtPerMinSuInvalidReason=null;
  }
}
validateMaxCntrPerSuForEdit()
{

  if( (this.cntrdetailsedit.max_cntr_per_shpg_unit>99999 || String(this.cntrdetailsedit.max_cntr_per_shpg_unit).includes('.')))
  {
    this.MaxCntrPerSuInValid=true;
    this.MaxCntrPerMinSuInvalidReason='Should be of range 1 to 99999  and cannot contain Decimal';
  }
  else
  {
    this.MaxCntrPerSuInValid=false;
    this.MaxCntrPerMinSuInvalidReason=null;
  }
}
validateCntrExtLForEdit()
{
  const cont_ext_l = String(this.cntrdetailsedit.cntr_exterior_l).split('.');
  if(this.cntrdetailsedit.cntr_exterior_l==null || this.cntrdetailsedit.cntr_exterior_l==0)
  {
    this.cntrExtLInValid=true;
    this.cntrExtLInValidReason='Required';
  }
  else if(!String(this.cntrdetailsedit.cntr_exterior_l).includes('.'))
    {
    /*if( String(this.cntrdetailsedit.cntr_exterior_l).length>7)
        {
          this.cntrExtLInValid=true;
          this.cntrExtLInValidReason="Max Length Of Characters can be 7";
        }
        else*/
         if(this.cntrdetailsedit.cntr_exterior_l<=0 || this.cntrdetailsedit.cntr_exterior_l>999.999 )
        {
          this.cntrExtLInValid=true;
          this.cntrExtLInValidReason='Should be of range 0 to 999.999';
        }
      else
        {
          this.cntrExtLInValid=false;
          this.cntrExtLInValidReason=null;
          console.log('Split First else')
        }
    }
    else if(String(this.cntrdetailsedit.cntr_exterior_l).includes('.'))
    {

        if(String(cont_ext_l[0]).length<0 || String(cont_ext_l[0]).length>3)
        {
          this.cntrExtLInValid=true;
          this.cntrExtLInValidReason='Should be of range 0 to 999.999';
        }
        else if(this.cntrdetailsedit.cntr_exterior_l<=0 || this.cntrdetailsedit.cntr_exterior_l>999.999 )
        {
          this.cntrExtLInValid=true;
          this.cntrExtLInValidReason='Should be of range 0 to 999.999';
        }
        else if(String(cont_ext_l[1]).length<0 || String(cont_ext_l[1]).length>3)
        {
          this.cntrExtLInValid=true;
          this.cntrExtLInValidReason='Max Length Of decimal values can be 3';
        }
        else
        {
          this.cntrExtLInValid=false;
          this.cntrExtLInValidReason=null;
      }
    }
    else
    {
    this.cntrExtLInValid=false;
    this.cntrExtLInValidReason=null;
    }
}
validateCntrExtWForEdit()
{
    const cont_ext_w = String(this.cntrdetailsedit.cntr_exterior_w).split('.');
    if(this.cntrdetailsedit.cntr_exterior_w==null || this.cntrdetailsedit.cntr_exterior_w==0)
    {
      this.cntrExtWInValid=true;
      this.cntrExtWInValidReason='Required';
    }
    else if(!String(this.cntrdetailsedit.cntr_exterior_w).includes('.'))
    {
    /*if( String(this.cntrdetailsedit.cntr_exterior_w).length>7)
        {
          this.cntrExtWInValid=true;
          this.cntrExtWInValidReason="Max Length Of Characters can be 7";
        }
        else*/
         if(this.cntrdetailsedit.cntr_exterior_w<=0 || this.cntrdetailsedit.cntr_exterior_w>999.999 )
        {
          this.cntrExtWInValid=true;
          this.cntrExtWInValidReason='Should be of range 0 to 999.999';
        }
      else
        {
          this.cntrExtWInValid=false;
          this.cntrExtWInValidReason=null;
        }
    }
    else if(String(this.cntrdetailsedit.cntr_exterior_w).includes('.'))
    {

        if(String(cont_ext_w[0]).length<0 || String(cont_ext_w[0]).length>3)
        {
          this.cntrExtWInValid=true;
          this.cntrExtWInValidReason='Should be of range 0 to 999.999';
        }
        else if(this.cntrdetailsedit.cntr_exterior_w<=0 || this.cntrdetailsedit.cntr_exterior_w>999.999 )
        {
          this.cntrExtWInValid=true;
          this.cntrExtWInValidReason='Should be of range 0 to 999.999';
        }
        else if(String(cont_ext_w[1]).length<0 || String(cont_ext_w[1]).length>3)
        {
          this.cntrExtWInValid=true;
          this.cntrExtWInValidReason='Max Length Of decimal values can be 3';
        }

        else
        {
          this.cntrExtWInValid=false;
          this.cntrExtWInValidReason=null;
      }
    }
    else
    {
    this.cntrExtWInValid=false;
    this.cntrExtWInValidReason=null;
    }
}
validateCntrExtHForEdit()
{
  const cont_ext_h = String(this.cntrdetailsedit.cntr_exterior_h).split('.');
  if(this.cntrdetailsedit.cntr_exterior_h==null || this.cntrdetailsedit.cntr_exterior_h==0)
  {
    this.cntrExtHInValid=true;
    this.cntrExtHInValidReason='Required';
  }
  else if(!String(this.cntrdetailsedit.cntr_exterior_h).includes('.'))
  {
  if(this.cntrdetailsedit.cntr_exterior_h<=0 || this.cntrdetailsedit.cntr_exterior_h>999.999 )
      {
        this.cntrExtHInValid=true;
        this.cntrExtHInValidReason='Should be of range 0 to 999.999';
      }
      /*else if( String(this.cntrdetailsedit.cntr_exterior_h).length>7)
      {
        this.cntrExtHInValid=true;
        this.cntrExtHInValidReason="Max Length Of Characters can be 7";
      }*/
      else
      {
        this.cntrExtHInValid=false;
        this.cntrExtHInValidReason=null;
      }
  }
  else if(String(this.cntrdetailsedit.cntr_exterior_h).includes('.'))
  {

      if(String(cont_ext_h[0]).length<0 || String(cont_ext_h[0]).length>3)
      {
        this.cntrExtHInValid=true;
        this.cntrExtHInValidReason='Should be of range 0 to 999.999';
      }
      else if(this.cntrdetailsedit.cntr_exterior_h<=0 || this.cntrdetailsedit.cntr_exterior_h>999.999 )
      {
        this.cntrExtHInValid=true;
        this.cntrExtHInValidReason='Should be of range 0 to 999.999';
      }
      else if(String(cont_ext_h[1]).length<0 || String(cont_ext_h[1]).length>3)
      {
        this.cntrExtHInValid=true;
        this.cntrExtHInValidReason='Max Length Of decimal values can be 3';
      }
      else
      {
        this.cntrExtHInValid=false;
        this.cntrExtHInValidReason=null;
    }
  }
  else
  {
  this.cntrExtHInValid=false;
  this.cntrExtHInValidReason=null;
  }
}
validateCntrIntLForEdit()
{
  if(this.cntrdetailsedit.cntr_interior_l!=null && (this.cntrdetailsedit.cntr_interior_l>99999))
  {
    this.cntrIntLInValid=true;
    this.cntrIntLInValidReason='Should be of range 1 to 99999';
  }
  else if(this.cntrdetailsedit.cntr_interior_l!=null && ( this.cntrdetailsedit.cntr_interior_l>this.cntrdetailsedit.cntr_exterior_l))
  {
    this.cntrIntLInValid=true;
    this.cntrIntLInValidReason='Interior Length cannot be Greater than Exterior Length';
  }
  else
  {
    this.cntrIntLInValid=false;
    this.cntrIntLInValidReason=null;
  }
}
validateCntrIntWForEdit()
{
  if(this.cntrdetailsedit.cntr_interior_w!=null && (this.cntrdetailsedit.cntr_interior_w>99999))
  {
    this.cntrIntWInValid=true;
    this.cntrIntWInValidReason='Should be of range 1 to 99999';
  }
  else if(this.cntrdetailsedit.cntr_interior_w!=null && (this.cntrdetailsedit.cntr_interior_w>this.cntrdetailsedit.cntr_exterior_w))
  {
    this.cntrIntWInValid=true;
    this.cntrIntWInValidReason='Interior width cannot be Greater than Exterior width';
  }
  else
  {
    this.cntrIntWInValid=false;
    this.cntrIntWInValidReason=null;
  }
}
validateCntrIntHForEdit()
{
  if(this.cntrdetailsedit.cntr_interior_h!=null && (this.cntrdetailsedit.cntr_interior_h>99999))
  {
    this.cntrIntHInValid=true;
    this.cntrIntHInValidReason='Should be of range 1 to 99999';
  }
  else if(this.cntrdetailsedit.cntr_interior_h!=null && (this.cntrdetailsedit.cntr_interior_h>this.cntrdetailsedit.cntr_exterior_h))
  {
    this.cntrIntHInValid=true;
    this.cntrIntHInValidReason='Interior height cannot be Greater than Exterior height';
  }
  else
  {
    this.cntrIntHInValid=false;
    this.cntrIntHInValidReason=null;
  }
}
validateSuNestedFldedHtForEdit()
{
  /* if(this.cntrdetailsedit.shpg_unit_nstdorflded_ht==null || this.cntrdetailsedit.shpg_unit_nstdorflded_ht==0)
  {
    this.suNestedHtInValid=true;
    this.suNestedHtInValidReason="Required";
  }
  else */ if(this.cntrdetailsedit.shpg_unit_nstdorflded_ht<0 || this.cntrdetailsedit.shpg_unit_nstdorflded_ht>99999)
  {
    this.suNestedHtInValid=true;
    this.suNestedHtInValidReason='Should be of range 0 to 99999';
  }
  else if(this.cntrdetailsedit.shpg_unit_nstdorflded_ht>0 && this.cntrdetailsedit.shpg_unit_exterior_h>0 && this.cntrdetailsedit.shpg_unit_nstdorflded_ht>this.cntrdetailsedit.shpg_unit_exterior_h)
  {
    this.suNestedHtInValid=true;
    this.suNestedHtInValidReason='Nested Folded Height cannot be Greater than Exterior height';
  }
  else
  {
    this.suNestedHtInValid=false;
    this.suNestedHtInValidReason=null;
  }
}
validateSuTareWeightForEdit()
{
  const shp_tar_wt = String(this.cntrdetailsedit.shpg_unit_tare_wt).split('.');
  if(!String(this.cntrdetailsedit.shpg_unit_tare_wt).includes('.'))
  {
  if(this.cntrdetailsedit.shpg_unit_tare_wt==null || this.cntrdetailsedit.shpg_unit_tare_wt==0)
      {
        this.suTareWtInvalid=true;
        this.suTareWtInValidReason='Shipping Unit Tare weight can not be 0';
      }
      if( String(this.cntrdetailsedit.shpg_unit_tare_wt).length>10)
      {
        this.suTareWtInvalid=true;
        this.suTareWtInValidReason='Max Length Of Characters can be 10';
      }
      else if(this.cntrdetailsedit.shpg_unit_tare_wt<1 || this.cntrdetailsedit.shpg_unit_tare_wt>999999.999)
      {
        this.suTareWtInvalid=true;
        this.suTareWtInValidReason='Should be of range 1 to 999999.999';
      }
    else
      {
        this.suTareWtInvalid=false;
        this.suTareWtInValidReason=null;
      }
  }
  else if(String(this.cntrdetailsedit.shpg_unit_tare_wt).includes('.'))
  {

      if(String(shp_tar_wt[0]).length<0 || String(shp_tar_wt[0]).length>6)
      {
        this.suTareWtInvalid=true;
        this.suTareWtInValidReason='Should be of range 1 to 999999.999';
      }
      else if(String(shp_tar_wt[1]).length<0 || String(shp_tar_wt[1]).length>3)
      {
        this.suTareWtInvalid=true;
        this.suTareWtInValidReason='Max Length Of decimal values can be 3';
      }
      else
      {
        this.suTareWtInvalid=false;
        this.suTareWtInValidReason=null;
    }
  }
  else
  {
  this.suTareWtInvalid=false;
  this.suTareWtInValidReason=null;
  }
}
validateWareHourseStckHtForEdit()
{
  if(this.cntrdetailsedit.warehouse_stack_ht==null || this.cntrdetailsedit.warehouse_stack_ht==0)
  {
    this.wareHourseStackHtInvaid=true;
    this.wareHourseStackHtInvaidReason='Required';
  }
  else if(this.cntrdetailsedit.warehouse_stack_ht<1 || this.cntrdetailsedit.warehouse_stack_ht>99 || String(this.cntrdetailsedit.warehouse_stack_ht).includes('.'))
  {
    this.wareHourseStackHtInvaid=true;
    this.wareHourseStackHtInvaidReason='Should be of range 1 to 99 and cannot contain Decimal';
  }
  else
  {
    this.wareHourseStackHtInvaid=false;
    this.wareHourseStackHtInvaidReason=null;
  }
}
validateShippingUnitStackHtForEdit()
{
  if(this.cntrdetailsedit.shpg_unit_stack_ht==null || this.cntrdetailsedit.shpg_unit_stack_ht==0)
  {
    this.suStackHtInvalid=true;
    this.suStackHtInvalidReason='Required';
  }
  else if(this.cntrdetailsedit.shpg_unit_stack_ht<1 || this.cntrdetailsedit.shpg_unit_stack_ht>9 || String(this.cntrdetailsedit.shpg_unit_stack_ht).includes('.'))
  {
    this.suStackHtInvalid=true;
    this.suStackHtInvalidReason='Should be of range 1 to 9 and cannot contain Decimal';
  }
  else
  {
    this.suStackHtInvalid=false;
    this.suStackHtInvalidReason=null;
  }
}
validateSUExtLForEdit()
{
  const shipunit_ext_l = String(this.cntrdetailsedit.shpg_unit_exterior_l).split('.');
  /* if(this.cntrdetailsedit.shpg_unit_exterior_l<this.cntrdetailsedit.cntr_exterior_l)
    {
      this.suExtLInValid=true;
      this.suExtLInValidReason="Shipping Unit Exterior Length cannot be less than Container Exterior Length";
    } */
  if(!String(this.cntrdetailsedit.shpg_unit_exterior_l).includes('.'))
  {
  /* if(this.cntrdetailsedit.shpg_unit_exterior_l==null || this.cntrdetailsedit.shpg_unit_exterior_l==0)
    {
      this.suExtLInValid=true;
      this.suExtLInValidReason="Required";
    } */
    if(this.cntrdetailsedit.shpg_unit_exterior_l<0 || this.cntrdetailsedit.shpg_unit_exterior_l>9999.999 )
    {
      this.suExtLInValid=true;
      this.suExtLInValidReason='Should be of range 0 to 9999.999';
    }
    else if( String(this.cntrdetailsedit.shpg_unit_exterior_l).length>8)
    {
      this.suExtLInValid=true;
      this.suExtLInValidReason='Max Length Of Characters can be 8';
    }
    else if(this.cntrdetailsedit.shpg_unit_exterior_l>0 && this.cntrdetailsedit.shpg_unit_exterior_l<this.cntrdetailsedit.cntr_exterior_l)
    {
      this.suExtLInValid=true;
      this.suExtLInValidReason='Shipping Unit Exterior Length cannot be less than Container Exterior Length';
    }
   else
    {
      this.suExtLInValid=false;
      this.suExtLInValidReason=null;
    }
}
else if(String(this.cntrdetailsedit.shpg_unit_exterior_l).includes('.'))
{
    if(String(shipunit_ext_l[0]).length<0 || String(shipunit_ext_l[0]).length>4)
    {
      this.suExtLInValid=true;
      this.suExtLInValidReason='Should be of range 0 to 9999.999';
    }
    else if(this.cntrdetailsedit.shpg_unit_exterior_l<0 || this.cntrdetailsedit.shpg_unit_exterior_l>9999.999 )
    {
      this.suExtLInValid=true;
      this.suExtLInValidReason='Should be of range 0 to 9999.999';
    }
    else if(String(shipunit_ext_l[1]).length<0 || String(shipunit_ext_l[1]).length>3)
    {
      this.suExtLInValid=true;
      this.suExtLInValidReason='Max Length Of decimal values can be 3';
    }
    else if(this.cntrdetailsedit.shpg_unit_exterior_l>0 && this.cntrdetailsedit.shpg_unit_exterior_l<this.cntrdetailsedit.cntr_exterior_l)
    {
      this.suExtLInValid=true;
      this.suExtLInValidReason='Shipping Unit Exterior Length cannot be less than Container Exterior Length';
    }
    else
    {
      this.suExtLInValid=false;
      this.suExtLInValidReason=null;
    }
  }
  else
  {
  this.suExtLInValid=false;
  this.suExtLInValidReason=null;
  }
}
validateSUExtWForEdit()
{

  const shipunit_ext_w = String(this.cntrdetailsedit.shpg_unit_exterior_w).split('.');
 /*  if(this.cntrdetailsedit.shpg_unit_exterior_w<this.cntrdetailsedit.cntr_exterior_w)
      {
        this.suExtWInValid=true;
        this.suExtWInValidReason="Shipping Unit Exterior Width cannot be less than Container Exterior Width";
      } */
  if(!String(this.cntrdetailsedit.shpg_unit_exterior_w).includes('.'))
  {
  /* if(this.cntrdetailsedit.shpg_unit_exterior_w==null || this.cntrdetailsedit.shpg_unit_exterior_w==0)
      {
        this.suExtWInValid=true;
        this.suExtWInValidReason="Required";
      } */

      if(this.cntrdetailsedit.shpg_unit_exterior_w<0 || this.cntrdetailsedit.shpg_unit_exterior_w>9999.999 )
      {
        this.suExtWInValid=true;
        this.suExtWInValidReason='Should be of range 0 to 9999.999';
      }
      else if( String(this.cntrdetailsedit.shpg_unit_exterior_w).length>8)
      {
        this.suExtWInValid=true;
        this.suExtWInValidReason='Max Length Of Characters can be 8';
      }
      else if(this.cntrdetailsedit.shpg_unit_exterior_w>0 && this.cntrdetailsedit.shpg_unit_exterior_w<this.cntrdetailsedit.cntr_exterior_w)
      {
        this.suExtWInValid=true;
        this.suExtWInValidReason='Shipping Unit Exterior Width cannot be less than Container Exterior Width';
      }

     else
      {
        this.suExtWInValid=false;
        this.suExtWInValidReason=null;
      }
  }
  else if(String(this.cntrdetailsedit.shpg_unit_exterior_w).includes('.'))
  {
      if(String(shipunit_ext_w[0]).length<0 || String(shipunit_ext_w[0]).length>4)
      {
        this.suExtWInValid=true;
        this.suExtWInValidReason='Should be of range 0 to 9999.999';
      }
      else if(this.cntrdetailsedit.shpg_unit_exterior_w<0 || this.cntrdetailsedit.shpg_unit_exterior_w>9999.999 )
      {
        this.suExtWInValid=true;
        this.suExtWInValidReason='Should be of range 0 to 9999.999';
      }
      else if(String(shipunit_ext_w[1]).length<0 || String(shipunit_ext_w[1]).length>3)
      {
        this.suExtWInValid=true;
        this.suExtWInValidReason='Max Length Of decimal values can be 3';
      }
      else if(this.cntrdetailsedit.shpg_unit_exterior_w>0 && this.cntrdetailsedit.shpg_unit_exterior_w<this.cntrdetailsedit.cntr_exterior_w)
      {
        this.suExtWInValid=true;
        this.suExtWInValidReason='Shipping Unit Exterior Width cannot be less than Container Exterior Width';
      }
      else
      {
        this.suExtWInValid=false;
        this.suExtWInValidReason=null;
    }
  }
  else
  {
  this.suExtWInValid=false;
  this.suExtWInValidReason=null;
  }
}
validateSUExtHForEdit()
{
  const shipunit_ext_h = String(this.cntrdetailsedit.shpg_unit_exterior_h).split('.');
  /* if(this.cntrdetailsedit.shpg_unit_exterior_h<this.cntrdetailsedit.cntr_exterior_h)
        {
          this.suExtHInValid=true;
          this.suExtHInValidReason="Shipping Unit Exterior Height cannot be less than Container Exterior Height";
        } */
    if(!String(this.cntrdetailsedit.shpg_unit_exterior_h).includes('.'))
    {
   /*  if(this.cntrdetailsedit.shpg_unit_exterior_h==null || this.cntrdetailsedit.shpg_unit_exterior_h==0)
        {
          this.suExtHInValid=true;
          this.suExtHInValidReason="Required";
        } */
         if(this.cntrdetailsedit.shpg_unit_exterior_h<0 || this.cntrdetailsedit.shpg_unit_exterior_h>9999.999)
        {
          this.suExtHInValid=true;
          this.suExtHInValidReason='Should be of range 0 to 9999.999';
        }
        else if( String(this.cntrdetailsedit.shpg_unit_exterior_h).length>8)
        {
          this.suExtHInValid=true;
          this.suExtHInValidReason='Max Length Of Characters can be 8';
        }
        else if(this.cntrdetailsedit.shpg_unit_exterior_h>0 && this.cntrdetailsedit.shpg_unit_exterior_h<this.cntrdetailsedit.cntr_exterior_h)
        {
          this.suExtHInValid=true;
          this.suExtHInValidReason='Shipping Unit Exterior Height cannot be less than Container Exterior Height';
        }

      else
        {
          this.suExtHInValid=false;
          this.suExtHInValidReason=null;
        }
    }
    else if(String(this.cntrdetailsedit.shpg_unit_exterior_h).includes('.'))
    {

        if(String(shipunit_ext_h[0]).length<0 || String(shipunit_ext_h[0]).length>4)
        {
          this.suExtHInValid=true;
          this.suExtHInValidReason='Should be of range 0 to 9999.999';
        }
        else if(this.cntrdetailsedit.shpg_unit_exterior_h<0 || this.cntrdetailsedit.shpg_unit_exterior_h>9999.999)
        {
          this.suExtHInValid=true;
          this.suExtHInValidReason='Should be of range 0 to 9999.999';
        }
        else if(String(shipunit_ext_h[1]).length<0 || String(shipunit_ext_h[1]).length>3)
        {
          this.suExtHInValid=true;
          this.suExtHInValidReason='Max Length Of decimal values can be 3';
        }
        else if(this.cntrdetailsedit.shpg_unit_exterior_h>0 && this.cntrdetailsedit.shpg_unit_exterior_h<this.cntrdetailsedit.cntr_exterior_h)
        {
          this.suExtHInValid=true;
          this.suExtHInValidReason='Shipping Unit Exterior Height cannot be less than Container Exterior Height';
        }
        else
        {
          this.suExtHInValid=false;
          this.suExtHInValidReason=null;
      }
    }
    else
    {
    this.suExtHInValid=false;
    this.suExtHInValidReason=null;
    }
}



save_editcntrdetails()
{
  console.log('in save_editcntrdetails',this.cntrdetailsedit);

    this.cntrdetailsedit.extr_cntr_dsgn_img_ind='No';
    this.cntrdetailsedit.intr_cntr_dsgn_img_ind='No';
    this.cntrdetailsedit.shpg_unit_img_ind='No';
    this.cntrdetailsedit.cad_img_ind='No';

    this.cntrdetailsedit.cd_plant=this.editSelectedPlantCode.plantGSDBCode.trim();
    this.cntrdetailsedit.id_user=this.transactionService.getcdsid();
    this.cntrdetailsedit.req_stat='New';
    this.cntrdetailsedit.ts_last_update=this.date.transform(new Date(),'yyyy-MM-dd');
    // this.cntrdetailsedit.ownrshp_catg="Ford"

    this.cntrdetailsedit.cntr_grp_desc=this.edit_selected_cntr_group.cntr_grp;
    this.cntrdetailsedit.cntr_matr_desc=this.edit_selected_cntr_material.cntr_matr_name;
    this.cntrdetailsedit.cntr_mstr_catg=this.edit_selected_cntr_master_category.cntr_mstr_catg;
    this.cntrdetailsedit.cntr_typ_desc=String(this.edit_selected_cntr_type_code.cd_cntr_typ);
    this.cntrdetailsedit.typ_no=this.edit_selected_cntr_type_code.typ_no;
    this.cntrdetailsedit.currency_desc=this.edit_selected_cntr_currency_list.curr_desc;
    this.cntrdetailsedit.ownrshp_catg=this.edit_selected_ownrship_catg.ownrshp_catg_val;
    this.cntrdetailsedit.ownrshp_strat_code=this.edit_selected_cntr_ownrship_strat_code.ownrshp_strat_code;
    // this.cntrdetailsedit.cntr_suffix=this.selectedSuffixList.suppliergsdbcode;

    this.cntrdetailsedit.ford_rntl_dly_cost=0;
    this.cntrdetailsedit.purchase_cost=0;
    this.cntrdetailsedit.rental_issue_cost=0;
    this.cntrdetailsedit.return_rntl_cost=0;
    this.cntrdetailsedit.sup_rntl_dly_cost=0;
    this.cntrdetailsedit.tooling_dev_cost=0;

    if(this.cntrdetailsedit.iscontainercolapsble!=null && this.cntrdetailsedit.iscontainercolapsble.toLowerCase()=='n')
    {
      this.cntrdetailsedit.collapsed_ht=1;
    }

    if(this.cntrdetailsedit.cntr_desc != null)
    {
    this.cntrdetailsedit.cntr_desc=this.cntrdetailsedit.cntr_desc.trim();
    }

    if(this.cntrdetailsedit.img_notes != null)
    {
      this.cntrdetailsedit.img_notes=this.cntrdetailsedit.img_notes.trim();
    }
    if(this.cntrdetailsedit.ford_cntct != null)
    {
    this.cntrdetailsedit.ford_cntct=this.cntrdetailsedit.ford_cntct.trim();
    }
    if(this.cntrdetailsedit.cntct_phone != null)
    {
    this.cntrdetailsedit.cntct_phone=this.cntrdetailsedit.cntct_phone.trim();
    }


  if(this.unitom=='E')
  {
    this.edit_measure='lbs/inch';
    this.cntrdetailsedit.UoM='E'
  }
  else
{
  this.edit_measure='kg/cm'
  this.cntrdetailsedit.UoM='M'
}

  //   if(this.edit_measure=='kg/cm')
  // {

  //  /* if(this.cntrdetailsedit.collapsed_ht!=null)
  //   {
  //       this.cntrdetailsedit.collapsed_ht=Math.round((0.393701*this.cntrdetailsedit.collapsed_ht)*1000)/1000;
  //   }
  //   if(this.cntrdetailsedit.cntr_interior_l!=null)
  //   {
  //       this.cntrdetailsedit.cntr_interior_l=Math.round((0.393701*this.cntrdetailsedit.cntr_interior_l)*1000)/1000;
  //   }
  //   if(this.cntrdetailsedit.cntr_interior_w!=null)
  //   {
  //           this.cntrdetailsedit.cntr_interior_w=Math.round((0.393701*this.cntrdetailsedit.cntr_interior_w)*1000)/1000;
  //   }
  //   if(this.cntrdetailsedit.cntr_interior_h!=null)
  //   {
  //           this.cntrdetailsedit.cntr_interior_h=Math.round((0.393701*this.cntrdetailsedit.cntr_interior_h)*1000)/1000;
  //   }
  //   if(this.cntrdetailsedit.cntr_tare_wt!=null)
  //   {
  //           this.cntrdetailsedit.cntr_tare_wt=Math.round((2.20462*this.cntrdetailsedit.cntr_tare_wt)*1000)/1000;
  //   }
  //   if(this.cntrdetailsedit.cntr_capacity!=null)
  //   {
  //           this.cntrdetailsedit.cntr_capacity=Math.round((0.393701*0.393701*0.393701*this.cntrdetailsedit.cntr_capacity)*1000)/1000;
  //   }

  //   if(this.cntrdetailsedit.cntr_exterior_l!=null)
  //   {
  //            this.cntrdetailsedit.cntr_exterior_l=Math.round((0.393701*this.cntrdetailsedit.cntr_exterior_l)*1000)/1000;
  //   }
  //   if(this.cntrdetailsedit.cntr_exterior_w!=null)
  //   {
  //           this.cntrdetailsedit.cntr_exterior_w=Math.round((0.393701*this.cntrdetailsedit.cntr_exterior_w)*1000)/1000;
  //   }
  //   if(this.cntrdetailsedit.cntr_exterior_h!=null)
  //   {
  //           this.cntrdetailsedit.cntr_exterior_h=Math.round((0.393701*this.cntrdetailsedit.cntr_exterior_h)*1000)/1000;
  //   }
  //   if(this.cntrdetailsedit.shpg_unit_exterior_l!=null)
  //   {
  //          this.cntrdetailsedit.shpg_unit_exterior_l=Math.round((0.393701*this.cntrdetailsedit.shpg_unit_exterior_l)*1000)/1000;
  //   }
  //   if(this.cntrdetailsedit.shpg_unit_exterior_w!=null)
  //   {
  //           this.cntrdetailsedit.shpg_unit_exterior_w=Math.round((0.393701*this.cntrdetailsedit.shpg_unit_exterior_w)*1000)/1000;
  //   }
  //   if(this.cntrdetailsedit.shpg_unit_exterior_h!=null)
  //   {
  //           this.cntrdetailsedit.shpg_unit_exterior_h=Math.round((0.393701*this.cntrdetailsedit.shpg_unit_exterior_h)*1000)/1000;
  //   }
  //   if(this.cntrdetailsedit.shpg_unit_nstdorflded_ht!=null)
  //   {
  //           this.cntrdetailsedit.shpg_unit_nstdorflded_ht=Math.round((0.393701*this.cntrdetailsedit.shpg_unit_nstdorflded_ht)*1000)/1000;
  //   }
  //   if(this.cntrdetailsedit.shpg_unit_tare_wt!=null)
  //   {
  //           this.cntrdetailsedit.shpg_unit_tare_wt=Math.round((2.20462*this.cntrdetailsedit.shpg_unit_tare_wt)*1000)/1000;
  //   }
  //   if(this.cntrdetailsedit.shpg_unit_stack_ht!=null)
  //   {
  //           this.cntrdetailsedit.shpg_unit_stack_ht=Math.round((0.393701*this.cntrdetailsedit.shpg_unit_stack_ht)*1000)/1000;
  //   }
  //   if(this.cntrdetailsedit.warehouse_stack_ht!=null)
  //   {
  //           this.cntrdetailsedit.warehouse_stack_ht=Math.round((0.393701*this.cntrdetailsedit.warehouse_stack_ht)*1000)/1000;
  //   }
  //   */
  //  this.cntrdetailsedit.UoM="M"

  // }
  // else
  // {
  //   this.cntrdetailsedit.UoM="E"
  // }

  if(this.cntrdetailsedit.service_life==null)
  {
    this.cntrdetailsedit.service_life='';
  }


  if(this.cntrdetailsedit.cntr_drawing_no == null)
  {
    this.cntrdetailsedit.cntr_drawing_no=''
  }


  console.log(this.cntrdetailsedit)

 if(this.componentsAssociatedToContainerForPost != null && this.componentsAssociatedToContainerForPost.length != 0)
 {
  this.cntrdetailsedit.components=[];
  this.cntrdetailsedit.components.push(...this.componentsAssociatedToContainerForPost);
 }

 console.log('components appended container',this.cntrdetailsedit);



 this.validateCollapsedHtForEdit();
  this.validateNestRatioForEdit();
  this.validateNestRatio1ForEdit();
  this.validateCntrTareWeightForEdit();
  this.validateCntrCapWtForEdit();
  this.validateCntrPerLayerForEdit();
  this.validateMaxLayerPerSuForEdit();
  this.validateCntrQtPerMinSuForEdit();
  this.validateMaxCntrPerSuForEdit();
  this.validateCntrExtLForEdit();
  this.validateCntrExtWForEdit();
  this.validateCntrExtHForEdit();
  this.validateCntrIntLForEdit();
  this.validateCntrIntWForEdit();
  this.validateCntrIntHForEdit();
  this.validateSuNestedFldedHtForEdit();
  this.validateSuTareWeightForEdit();
  this.validateWareHourseStckHtForEdit();
  this.validateShippingUnitStackHtForEdit();
  this.validateSUExtLForEdit();
  this.validateSUExtWForEdit();
  this.validateSUExtHForEdit();
  this.validateCntrBaseForEdit()
  this.validateCntrDescForEdit()
  //this.validatePlantCodeForEdit()
  // this.validateCardOrCallForEdit()
  this.validateMastCatgForEdit()
  this.validateCntrRetTypeForEdit()
  this.validateCntrTypeCodeForEdit()
  this.validateCntrMaterialForEdit()
  this.validateCntrOwnershpCatgForEdit()
  this.validateCOSForEdit()
  this.validateCntrGrpForEdit()
  this.validateCntrCollapsibleForEdit()
  this.validateCntrContactForEdit()
  this.validateCntrContactPhoneForEdit()
  this.validateCntrActiveForEdit()

  if(!this.collapsedHtInValid && !this.nestRatioInValid && !this.nestRatio1InValid &&
    !this.cntrTareWtInValid && !this.cntrCapWtInValid && !this.cntrPerLayerInValid &&
    !this.maxLayerPerSuInValid && !this.cntrQtPerMinSuInValid && !this.MaxCntrPerSuInValid &&
    !this.cntrExtLInValid && !this.cntrExtWInValid && !this.cntrExtHInValid && !this.cntrIntLInValid &&
    !this.cntrIntWInValid && !this.cntrIntHInValid && !this.suNestedHtInValid && !this.suTareWtInvalid &&
    !this.wareHourseStackHtInvaid && !this.suStackHtInvalid && !this.suExtLInValid && !this.suExtWInValid &&
    !this.suExtHInValid && !this.cntrBaseInvalid && !this.cntrDescInvalid && !this.plantCodeInvalid &&
    !this.mastrCatgInvalid && !this.retTypeInvalid && !this.cntrTypeInvalid &&
    !this.cntrMaterialInvalid && !this.cosInvalid && !this.cntrGrpInvalid && !this.isCollapsibleInvalid &&
    !this.cntrCntctInvalid && !this.cntctPhoneInvalid && !this.cntrActiveInvalid && !this.cntrOwnerShpCatgInvalid)
{



        // Not:********* if UOM field is not always E while comparing do measurement conversion and compare to check if any cmms fields changed or not -- not required

        this.cntrCmmsFieldsAfterUpdate.cntr_base=JSON.parse(JSON.stringify(this.cntrdetailsedit.cntr_base))
    this.cntrCmmsFieldsAfterUpdate.cntr_suffix=JSON.parse(JSON.stringify(this.cntrdetailsedit.cntr_suffix))
    this.cntrCmmsFieldsAfterUpdate.cntr_desc=JSON.parse(JSON.stringify(this.cntrdetailsedit.cntr_desc))
    this.cntrCmmsFieldsAfterUpdate.cntr_typ_desc=JSON.parse(JSON.stringify(this.cntrdetailsedit.cntr_typ_desc))
    this.cntrCmmsFieldsAfterUpdate.cntr_exterior_l=JSON.parse(JSON.stringify(this.cntrdetailsedit.cntr_exterior_l))
    this.cntrCmmsFieldsAfterUpdate.cntr_exterior_w=JSON.parse(JSON.stringify(this.cntrdetailsedit.cntr_exterior_w))
    this.cntrCmmsFieldsAfterUpdate.cntr_exterior_h=JSON.parse(JSON.stringify(this.cntrdetailsedit.cntr_exterior_h))
    this.cntrCmmsFieldsAfterUpdate.collapsed_ht=JSON.parse(JSON.stringify(this.cntrdetailsedit.collapsed_ht))
    this.cntrCmmsFieldsAfterUpdate.nest_ratio=JSON.parse(JSON.stringify(this.cntrdetailsedit.nest_ratio))
    this.cntrCmmsFieldsAfterUpdate.cntr_capacity=JSON.parse(JSON.stringify(this.cntrdetailsedit.cntr_capacity))
    this.cntrCmmsFieldsAfterUpdate.cntr_tare_wt=JSON.parse(JSON.stringify(this.cntrdetailsedit.cntr_tare_wt))
    this.cntrCmmsFieldsAfterUpdate.shpg_unit_stack_ht=JSON.parse(JSON.stringify(this.cntrdetailsedit.shpg_unit_stack_ht))
    this.cntrCmmsFieldsAfterUpdate.warehouse_stack_ht=JSON.parse(JSON.stringify(this.cntrdetailsedit.warehouse_stack_ht))
    this.cntrCmmsFieldsAfterUpdate.cd_plant=JSON.parse(JSON.stringify(this.cntrdetailsedit.cd_plant))
    this.cntrCmmsFieldsAfterUpdate.nest_ratio_1=JSON.parse(JSON.stringify(this.cntrdetailsedit.nest_ratio_1))
    this.cntrCmmsFieldsAfterUpdate.ford_cntct=JSON.parse(JSON.stringify(this.cntrdetailsedit.ford_cntct))
    this.cntrCmmsFieldsAfterUpdate.cntct_phone=JSON.parse(JSON.stringify(this.cntrdetailsedit.cntct_phone))
    this.cntrCmmsFieldsAfterUpdate.cntr_qt_per_min_su=JSON.parse(JSON.stringify(this.cntrdetailsedit.cntr_qt_per_min_su))
    this.cntrCmmsFieldsAfterUpdate.ownrshp_catg=JSON.parse(JSON.stringify(this.cntrdetailsedit.ownrshp_catg))
    


    console.log('cmms before update',this.cntrCmmsFieldsBeforeUpdate);
    console.log('cmms after update',this.cntrCmmsFieldsAfterUpdate);
    console.log('edit cntr details', this.cntrdetailsedit);


            let cmmsFieldsChanged=false;
            const keys1 = Object.keys(this.cntrCmmsFieldsBeforeUpdate);
            for (const key of keys1) {
            if((typeof(this.cntrCmmsFieldsBeforeUpdate[key]) == 'number'  && (this.cntrCmmsFieldsBeforeUpdate[key] !== this.cntrCmmsFieldsAfterUpdate[key])) ||
              (typeof(this.cntrCmmsFieldsBeforeUpdate[key]) == 'string'  && (this.cntrCmmsFieldsBeforeUpdate[key].trim() !== this.cntrCmmsFieldsAfterUpdate[key].trim())) )
              {
                cmmsFieldsChanged=true
              }
            }

          console.log('cmms field vlaue change',cmmsFieldsChanged)
             this.cntrAdmindirectSaveinit();

          if(cmmsFieldsChanged == false && (this.cntrdetailsedit.components != null && this.cntrdetailsedit.components.length != 0 && this.cntrdetailsedit.components[0].mp_function ==''))
          {


            // api integration for direct save to cntr admin


            console.log('cntr admin direct save edit')
            console.log('cd_cntr',this.edit_cntr_cd_cntr)

            if(this.edit_cntr_cd_cntr != 0 && this.cntrdetailsedit != null)
            {
              // service to call api to push data
              this.saveEditContainerCntrAdmin.cd_cntr=this.edit_cntr_cd_cntr;
              this.saveEditContainerCntrAdmin.cntr_base=this.cntrdetailsedit.cntr_base;
              this.saveEditContainerCntrAdmin.cntr_suff_ind= this.cntrdetailsedit.cntr_suff_ind;
              this.saveEditContainerCntrAdmin.exp_refundable=this.cntrdetailsedit.exp_refundable;
              this.saveEditContainerCntrAdmin.cntr_active_ind=this.cntrdetailsedit.cntr_active_ind
              this.saveEditContainerCntrAdmin.cntr_suffix=this.cntrdetailsedit.cntr_suffix;
              this.saveEditContainerCntrAdmin.cntr_desc=this.cntrdetailsedit.cntr_desc;
              this.saveEditContainerCntrAdmin.card_call=this.cntrdetailsedit.card_call
              this.saveEditContainerCntrAdmin.cntr_mstr_catg=this.cntrdetailsedit.cntr_mstr_catg;
              this.saveEditContainerCntrAdmin.cntr_typ_desc=this.cntrdetailsedit.cntr_typ_desc;
              this.saveEditContainerCntrAdmin.ownrshp_strat_code=this.cntrdetailsedit.ownrshp_strat_code;
              this.saveEditContainerCntrAdmin.ownrshp_catg=this.cntrdetailsedit.ownrshp_catg;
              this.saveEditContainerCntrAdmin.cntr_grp_desc=this.cntrdetailsedit.cntr_grp_desc;
              this.saveEditContainerCntrAdmin.cntr_matr_desc=this.cntrdetailsedit.cntr_matr_desc;
              this.saveEditContainerCntrAdmin.currency_desc=this.cntrdetailsedit.currency_desc;
              this.saveEditContainerCntrAdmin.iscontainercolapsble=this.cntrdetailsedit.iscontainercolapsble;
              this.saveEditContainerCntrAdmin.cntr_exterior_l=this.cntrdetailsedit.cntr_exterior_l;
              this.saveEditContainerCntrAdmin.cntr_exterior_w=this.cntrdetailsedit.cntr_exterior_w;
              this.saveEditContainerCntrAdmin.cntr_exterior_h=this.cntrdetailsedit.cntr_exterior_h;
              this.saveEditContainerCntrAdmin.collapsed_ht=this.cntrdetailsedit.collapsed_ht;
              this.saveEditContainerCntrAdmin.nest_ratio=this.cntrdetailsedit.nest_ratio;
              this.saveEditContainerCntrAdmin.cntr_interior_l=this.cntrdetailsedit.cntr_interior_l;
              this.saveEditContainerCntrAdmin.cntr_interior_w=this.cntrdetailsedit.cntr_interior_w;
              this.saveEditContainerCntrAdmin.cntr_interior_h=this.cntrdetailsedit.cntr_interior_h;
              this.saveEditContainerCntrAdmin.cntr_tare_wt=this.cntrdetailsedit.cntr_tare_wt;
              this.saveEditContainerCntrAdmin.cntr_capacity=this.cntrdetailsedit.cntr_capacity;
              this.saveEditContainerCntrAdmin.cntr_per_layer=this.cntrdetailsedit.cntr_per_layer;
              this.saveEditContainerCntrAdmin.max_layers_shpg_unit=this.cntrdetailsedit.max_layers_shpg_unit;
              this.saveEditContainerCntrAdmin.max_cntr_per_shpg_unit=this.cntrdetailsedit.max_cntr_per_shpg_unit;
              this.saveEditContainerCntrAdmin.cntr_drawing_no=this.cntrdetailsedit.cntr_drawing_no;
              this.saveEditContainerCntrAdmin.shpg_unit_exterior_l=this.cntrdetailsedit.shpg_unit_exterior_l;
              this.saveEditContainerCntrAdmin.shpg_unit_exterior_w=this.cntrdetailsedit.shpg_unit_exterior_w;
              this.saveEditContainerCntrAdmin.shpg_unit_exterior_h=this.cntrdetailsedit.shpg_unit_exterior_h;
              this.saveEditContainerCntrAdmin.shpg_unit_nstdorflded_ht=this.cntrdetailsedit.shpg_unit_nstdorflded_ht;
              this.saveEditContainerCntrAdmin.shpg_unit_tare_wt=this.cntrdetailsedit.shpg_unit_tare_wt;
              this.saveEditContainerCntrAdmin.shpg_unit_stack_ht=this.cntrdetailsedit.shpg_unit_stack_ht;
              this.saveEditContainerCntrAdmin.warehouse_stack_ht=this.cntrdetailsedit.warehouse_stack_ht;
              this.saveEditContainerCntrAdmin.service_life=this.cntrdetailsedit.service_life;
              this.saveEditContainerCntrAdmin.purchase_cost=this.cntrdetailsedit.purchase_cost;
              this.saveEditContainerCntrAdmin.rental_issue_cost=this.cntrdetailsedit.rental_issue_cost;
              this.saveEditContainerCntrAdmin.sup_rntl_dly_cost=this.cntrdetailsedit.sup_rntl_dly_cost;
              this.saveEditContainerCntrAdmin.ford_rntl_dly_cost=this.cntrdetailsedit.ford_rntl_dly_cost;
              this.saveEditContainerCntrAdmin.return_rntl_cost=this.cntrdetailsedit.return_rntl_cost;
              this.saveEditContainerCntrAdmin.tooling_dev_cost=this.cntrdetailsedit.tooling_dev_cost;
              this.saveEditContainerCntrAdmin.extr_cntr_dsgn_img_ind=this.cntrdetailsedit.extr_cntr_dsgn_img_ind;
              this.saveEditContainerCntrAdmin.intr_cntr_dsgn_img_ind=this.cntrdetailsedit.intr_cntr_dsgn_img_ind;
              this.saveEditContainerCntrAdmin.shpg_unit_img_ind=this.cntrdetailsedit.shpg_unit_img_ind
              this.saveEditContainerCntrAdmin.cad_img_ind=this.cntrdetailsedit.cad_img_ind;
              this.saveEditContainerCntrAdmin.img_notes=this.cntrdetailsedit.img_notes;
              this.saveEditContainerCntrAdmin.cd_region=this.cntrdetailsedit.cd_region;
              this.saveEditContainerCntrAdmin.cd_plant=this.cntrdetailsedit.cd_plant;
              this.saveEditContainerCntrAdmin.cnt_qt_per_min_su=this.cntrdetailsedit.cntr_qt_per_min_su;
              this.saveEditContainerCntrAdmin.nest_ratio_1=this.cntrdetailsedit.nest_ratio_1;
              this.saveEditContainerCntrAdmin.ford_cntct=this.cntrdetailsedit.ford_cntct;
              this.saveEditContainerCntrAdmin.cntct_phone=this.cntrdetailsedit.cntct_phone;
              this.saveEditContainerCntrAdmin.uom=this.cntrdetailsedit.UoM;
              this.saveEditContainerCntrAdmin.id_user= this.cntrdetailsedit.id_user
              this.saveEditContainerCntrAdmin.req_stat=this.cntrdetailsedit.req_stat;
              this.saveEditContainerCntrAdmin.ts_last_update=this.cntrdetailsedit.ts_last_update;

              this.saveEditContainerCntrAdmin.typ_no=this.cntrdetailsedit.typ_no;



              console.log('save direct to container admin post data', this.saveEditContainerCntrAdmin)

              const saveEditContainerCntrAdminArray=[];
              saveEditContainerCntrAdminArray.push(this.saveEditContainerCntrAdmin);
              // service call api

              this.transactionService.cntrAdminSaveCntrDetails(saveEditContainerCntrAdminArray).subscribe(cntr_save_res=>{
                console.log('direct cntr admin save edit',cntr_save_res)
                if(cntr_save_res.toLowerCase() == 'success')
                {
                  this.messageService.add({severity:'success', summary:'Success', detail:'Container Edited Successfully'});
                  this.enableeditcntrdetails=false;
                  this.enableeditgetdetails=false;
                  this.editing=false;
                  this.componentsAssociatedToContainerForPost=[];
                  this.selectedComponentList=[];
                  this.componentListAll=[];
                  this.newCompToBeAddedList=[];
                  this.existCompToBeRemovedList=[];
                  this.validationInit();
                  this.cntrAddEnable=true;
                  // this.componentsAssociatedToContainerForPost=[];
                  // Commented because details should not be shown until the record gets updated in cmms
                  // this.geteditedcntrdetails(this.cntrdetailsedit);
                  this.DisplayGrid(this.current_region_code_prg);
                }


                else
                {
                    this.messageService.add({severity:'error', summary: 'Error', detail: 'Container Not Edited,Please Try Again'});
                    // this.addplantdetailsinit();
                    this.validationInit();
                  /*  if(this.edit_measure=='kg/cm')
                    {
                    if(this.cntrdetailsedit.collapsed_ht!=null)
                    {
                        this.cntrdetailsedit.collapsed_ht=Math.round((2.54*this.cntrdetailsedit.collapsed_ht)*1000)/1000;
                    }
                    if(this.cntrdetailsedit.cntr_interior_l!=null)
                    {
                        this.cntrdetailsedit.cntr_interior_l=Math.round((2.54*this.cntrdetailsedit.cntr_interior_l)*1000)/1000;
                    }
                    if(this.cntrdetailsedit.cntr_interior_w!=null)
                    {
                            this.cntrdetailsedit.cntr_interior_w=Math.round((2.54*this.cntrdetailsedit.cntr_interior_w)*1000)/1000;
                    }
                    if(this.cntrdetailsedit.cntr_interior_h!=null)
                    {
                            this.cntrdetailsedit.cntr_interior_h=Math.round((2.54*this.cntrdetailsedit.cntr_interior_h)*1000)/1000;
                    }
                    if(this.cntrdetailsedit.cntr_tare_wt!=null)
                    {
                            this.cntrdetailsedit.cntr_tare_wt=Math.round((0.453592*this.cntrdetailsedit.cntr_tare_wt)*1000)/1000;
                    }
                    if(this.cntrdetailsedit.cntr_capacity!=null)
                    {
                            this.cntrdetailsedit.cntr_capacity=Math.round((2.54*2.54*2.54*this.cntrdetailsedit.cntr_capacity)*1000)/1000;
                    }

                    if(this.cntrdetailsedit.cntr_exterior_l!=null)
                    {
                             this.cntrdetailsedit.cntr_exterior_l=Math.round((2.54*this.cntrdetailsedit.cntr_exterior_l)*1000)/1000;
                    }
                    if(this.cntrdetailsedit.cntr_exterior_w!=null)
                    {
                            this.cntrdetailsedit.cntr_exterior_w=Math.round((2.54*this.cntrdetailsedit.cntr_exterior_w)*1000)/1000;
                    }
                    if(this.cntrdetailsedit.cntr_exterior_h!=null)
                    {
                            this.cntrdetailsedit.cntr_exterior_h=Math.round((2.54*this.cntrdetailsedit.cntr_exterior_h)*1000)/1000;
                    }
                    if(this.cntrdetailsedit.shpg_unit_exterior_l!=null)
                    {
                           this.cntrdetailsedit.shpg_unit_exterior_l=Math.round((2.54*this.cntrdetailsedit.shpg_unit_exterior_l)*1000)/1000;
                    }
                    if(this.cntrdetailsedit.shpg_unit_exterior_w!=null)
                    {
                            this.cntrdetailsedit.shpg_unit_exterior_w=Math.round((2.54*this.cntrdetailsedit.shpg_unit_exterior_w)*1000)/1000;
                    }
                    if(this.cntrdetailsedit.shpg_unit_exterior_h!=null)
                    {
                            this.cntrdetailsedit.shpg_unit_exterior_h=Math.round((2.54*this.cntrdetailsedit.shpg_unit_exterior_h)*1000)/1000;
                    }
                    if(this.cntrdetailsedit.shpg_unit_nstdorflded_ht!=null)
                    {
                            this.cntrdetailsedit.shpg_unit_nstdorflded_ht=Math.round((2.54*this.cntrdetailsedit.shpg_unit_nstdorflded_ht)*1000)/1000;
                    }
                    if(this.cntrdetailsedit.shpg_unit_tare_wt!=null)
                    {
                            this.cntrdetailsedit.shpg_unit_tare_wt=Math.round((0.453592*this.cntrdetailsedit.shpg_unit_tare_wt)*1000)/1000;
                    }
                    if(this.cntrdetailsedit.shpg_unit_stack_ht!=null)
                    {
                            this.cntrdetailsedit.shpg_unit_stack_ht=Math.round((2.54*this.cntrdetailsedit.shpg_unit_stack_ht)*1000)/1000;
                    }
                    if(this.cntrdetailsedit.warehouse_stack_ht!=null)
                    {
                            this.cntrdetailsedit.warehouse_stack_ht=Math.round((2.54*this.cntrdetailsedit.warehouse_stack_ht)*1000)/1000;
                    }
                  }*/
                }

              })



            }


          }
          else
          {


            console.log('dbia save edit',this.cntrdetailsedit)


            this.transactionService.edit_savecntrdetails(this.cntrdetailsedit).subscribe(savecntr=>
            {
              const res=savecntr;
              console.log('successful',res);
              if(res.toLowerCase() == 'success')
              {
                this.messageService.add({severity:'success', summary:'Success', detail:'Container Edited Successfully'});
                this.enableeditcntrdetails=false;
                this.enableeditgetdetails=false;
                this.editing=false;
                this.componentsAssociatedToContainerForPost=[];
                this.selectedComponentList=[];
                this.componentListAll=[];
                this.newCompToBeAddedList=[];
                this.existCompToBeRemovedList=[];
                this.validationInit();
                this.cntrAddEnable=true;
                // this.componentsAssociatedToContainerForPost=[];
                // Commented because details should not be shown until the record gets updated in cmms
                // this.geteditedcntrdetails(this.cntrdetailsedit);
                this.DisplayGrid(this.current_region_code_prg);
              }
              else
              {
                  this.messageService.add({severity:'error', summary: 'Error', detail: 'Container Not Edited,Please Try Again'});
                  // this.addplantdetailsinit();
                  this.validationInit();
                 /* if(this.edit_measure=='kg/cm')
                  {
                        if(this.cntrdetailsedit.collapsed_ht!=null)
                        {
                            this.cntrdetailsedit.collapsed_ht=Math.round((2.54*this.cntrdetailsedit.collapsed_ht)*1000)/1000;
                        }
                        if(this.cntrdetailsedit.cntr_interior_l!=null)
                        {
                            this.cntrdetailsedit.cntr_interior_l=Math.round((2.54*this.cntrdetailsedit.cntr_interior_l)*1000)/1000;
                        }
                        if(this.cntrdetailsedit.cntr_interior_w!=null)
                        {
                                this.cntrdetailsedit.cntr_interior_w=Math.round((2.54*this.cntrdetailsedit.cntr_interior_w)*1000)/1000;
                        }
                        if(this.cntrdetailsedit.cntr_interior_h!=null)
                        {
                                this.cntrdetailsedit.cntr_interior_h=Math.round((2.54*this.cntrdetailsedit.cntr_interior_h)*1000)/1000;
                        }
                        if(this.cntrdetailsedit.cntr_tare_wt!=null)
                        {
                                this.cntrdetailsedit.cntr_tare_wt=Math.round((0.453592*this.cntrdetailsedit.cntr_tare_wt)*1000)/1000;
                        }
                        if(this.cntrdetailsedit.cntr_capacity!=null)
                        {
                                this.cntrdetailsedit.cntr_capacity=Math.round((2.54*2.54*2.54*this.cntrdetailsedit.cntr_capacity)*1000)/1000;
                        }

                        if(this.cntrdetailsedit.cntr_exterior_l!=null)
                        {
                                this.cntrdetailsedit.cntr_exterior_l=Math.round((2.54*this.cntrdetailsedit.cntr_exterior_l)*1000)/1000;
                        }
                        if(this.cntrdetailsedit.cntr_exterior_w!=null)
                        {
                                this.cntrdetailsedit.cntr_exterior_w=Math.round((2.54*this.cntrdetailsedit.cntr_exterior_w)*1000)/1000;
                        }
                        if(this.cntrdetailsedit.cntr_exterior_h!=null)
                        {
                                this.cntrdetailsedit.cntr_exterior_h=Math.round((2.54*this.cntrdetailsedit.cntr_exterior_h)*1000)/1000;
                        }
                        if(this.cntrdetailsedit.shpg_unit_exterior_l!=null)
                        {
                              this.cntrdetailsedit.shpg_unit_exterior_l=Math.round((2.54*this.cntrdetailsedit.shpg_unit_exterior_l)*1000)/1000;
                        }
                        if(this.cntrdetailsedit.shpg_unit_exterior_w!=null)
                        {
                                this.cntrdetailsedit.shpg_unit_exterior_w=Math.round((2.54*this.cntrdetailsedit.shpg_unit_exterior_w)*1000)/1000;
                        }
                        if(this.cntrdetailsedit.shpg_unit_exterior_h!=null)
                        {
                                this.cntrdetailsedit.shpg_unit_exterior_h=Math.round((2.54*this.cntrdetailsedit.shpg_unit_exterior_h)*1000)/1000;
                        }
                        if(this.cntrdetailsedit.shpg_unit_nstdorflded_ht!=null)
                        {
                                this.cntrdetailsedit.shpg_unit_nstdorflded_ht=Math.round((2.54*this.cntrdetailsedit.shpg_unit_nstdorflded_ht)*1000)/1000;
                        }
                        if(this.cntrdetailsedit.shpg_unit_tare_wt!=null)
                        {
                                this.cntrdetailsedit.shpg_unit_tare_wt=Math.round((0.453592*this.cntrdetailsedit.shpg_unit_tare_wt)*1000)/1000;
                        }
                        if(this.cntrdetailsedit.shpg_unit_stack_ht!=null)
                        {
                                this.cntrdetailsedit.shpg_unit_stack_ht=Math.round((2.54*this.cntrdetailsedit.shpg_unit_stack_ht)*1000)/1000;
                        }
                        if(this.cntrdetailsedit.warehouse_stack_ht!=null)
                        {
                                this.cntrdetailsedit.warehouse_stack_ht=Math.round((2.54*this.cntrdetailsedit.warehouse_stack_ht)*1000)/1000;
                        }
                }*/
              }
            })
        }
    }
}


cancel_editcntrdetails()
  {
       this.enableeditcntrdetails=false;
       this.cntrAddEnable=true;
       this.editing=false;
       this.editcntrdetailsinit();
       this.validationInit();

  }

  cancel_edit_cntr_get_details(){
    this.enableeditgetdetails=false;
    this.editing=false;
this.enabledetails=true;
    this.editcntrdetailsinit();
    this.validationInit();
  }


  /* on clicking the container number from grid */

  getcntrdetails_grid(containerdetailsfromgrid:any)
  {
    this.enabledetails=true;
    this.cntrdetailsinit();


    if(this.unitom=='E')
    {
      this.get_measure='lbs/inch';
      this.prev_get_measure='lbs/inch';
      this.cntrdetails.uom = 'E'
    }
    else
  {
    this.get_measure='kg/cm'
    this.prev_get_measure='kg/cm'
    this.cntrdetails.uom = 'M'
  }


    this.get_selected_cntr_master_category={cd_cntr_mstr_catg:0,cntr_mstr_catg_desc:'',updated_by:'',updated_dt:'',cntr_mstr_catg:'',active_stat:'',active_stat_display:false};
    this.get_selected_cntr_type_code={cd_cntr_typ:'',cntr_typ_desc:'',typ_no:0};
    this.get_selected_cntr_ownrship_strat_code={cd_region:'',ownrshp_strat_code:'',ownrshp_desc:'',cd_ownrshp_strat:0};
    this.get_selected_ownrship_catg={ownrshp_catg:'',ownrshp_catg_val:''};
    this.get_selected_cntr_group={cntr_grp:''}
    this.get_selected_cntr_material={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
    this.get_selected_cntr_currency_list={cd_curr:0,curr_desc:''};

    if(this.enableeditgetdetails==true)
    {
      this.enableeditgetdetails=false;

    }
    if(this.enableeditcntrdetails==true)
    {
      this.enableeditcntrdetails=false;
    }
    this.editing=false;

    if(this.cntrAddEnable==true)
    {
      this.cntrAddEnable=false;
    }

    if(this.cntrAddDetailsEnable==true)
    {
      this.cntrAddDetailsEnable=false;
    }




    this.transactionService.editbutton_get_contr_details(containerdetailsfromgrid.cntr_base,containerdetailsfromgrid.cd_cntr).subscribe((getcntrdetails=>{
      this.cntrdetails=getcntrdetails;

      this.validationInit();
     /* if(String(this.cntrdetails.cntr_typ_desc)=="01")
      {
        this.containerOrComponentInDetails="container";
      }
      else
      {
        this.containerOrComponentInDetails="component";
      }*/

      console.log('cntrdetails',this.cntrdetails)

   if(this.cntrMasterCategory != null && this.cntrMasterCategory.length != 0)
   {
      for(let i=0;i<this.cntrMasterCategory.length;i++)
      {
        if(this.cntrdetails.cntr_mstr_catg != null && this.cntrdetails.cntr_mstr_catg==this.cntrMasterCategory[i].cntr_mstr_catg)
        {
          this.get_selected_cntr_master_category=this.cntrMasterCategory[i];
        }
      }
    }

    if(this.unmodifiedTypeCodes != null && this.unmodifiedTypeCodes.length != 0)
    {
    for(let i=0;i<this.unmodifiedTypeCodes.length;i++)
      {
        if(this.cntrdetails.typ_no != null && this.cntrdetails.typ_no==this.unmodifiedTypeCodes[i].typ_no)
        {
          this.get_selected_cntr_type_code=this.unmodifiedTypeCodes[i];
          console.log('edit_selected_cntr_type_code',this.edit_selected_cntr_type_code);
        }
      }
    }

    if(this.cntrOwnershipstratcode != null && this.cntrOwnershipstratcode.length != 0)
    {
      for(let i=0;i<this.cntrOwnershipstratcode.length;i++)
      {
        if(this.cntrdetails.ownrshp_strat_code != null && this.cntrdetails.ownrshp_strat_code==this.cntrOwnershipstratcode[i].ownrshp_strat_code)
        {
          this.get_selected_cntr_ownrship_strat_code=this.cntrOwnershipstratcode[i];
        }
      }
    }

    if(this.ownrship_catg != null && this.ownrship_catg.length !=0)
    {
      for(let i=0;i<this.ownrship_catg.length;i++)
      {
        if(this.cntrdetails.ownrshp_catg != null && this.cntrdetails.ownrshp_catg.toLowerCase().indexOf(this.ownrship_catg[i].ownrshp_catg_val.toLowerCase())>-1)
        {
          this.get_selected_ownrship_catg=this.ownrship_catg[i];
        }
      }
    }

    if(this.cntrGrp != null && this.cntrGrp.length != 0)
    {
      for(let i=0;i<this.cntrGrp.length;i++)
      {
        if(this.cntrdetails.cntr_grp_desc != null && this.cntrdetails.cntr_grp_desc==this.cntrGrp[i].cntr_grp)
        {
          this.get_selected_cntr_group=this.cntrGrp[i];
        }
      }
    }

      // for(let i=0;i<this.cntrMaterial.length;i++)
      // {
      //   if(this.cntrdetails.cntr_matr_desc==this.cntrMaterial[i].cntr_matr_name)
      //   {
      //     this.get_selected_cntr_material=this.cntrMaterial[i];
      //   }
      // }

      this.getMaterialValueToDisplay();

    if(this.cntrCurrencyList != null && this.cntrCurrencyList.length != 0)
    {
      for(let i=0;i<this.cntrCurrencyList.length;i++)
      {
        if(this.cntrdetails.currency_desc != null && this.cntrdetails.currency_desc==this.cntrCurrencyList[i].curr_desc)
        {
          this.get_selected_cntr_currency_list=this.cntrCurrencyList[i];
        }
      }
    }

    if(this.cntrdetails.card_call == "null" || this.cntrdetails.card_call == null || this.cntrdetails.card_call.trim() == "")
    {
      this.cntrdetails.card_call = "Call";
    }

      // if(this.cntrdetails.uom == null || this.cntrdetails.uom.trim() == "")
      // {
      //   this.cntrdetails.uom = "E"
      // }

      // if(this.cntrdetails.uom.toLowerCase() == "m")
      // {

      //   this.get_measure='kg/cm'
      //   this.prev_get_measure='kg/cm'
      // }
      // else
      // {
      //   this.get_measure='lbs/inch'
      //   this.prev_get_measure='lbs/inch'
      // }


      // if(this.unitom=="E")
      // {
      //   this.cntrdetails.uom = "E"
      //   this.get_measure='lbs/inch'
      //   this.prev_get_measure='lbs/inch'
      // }
      // else
      // {
      //   this.cntrdetails.uom = "M"
      //   this.get_measure='kg/cm'
      //   this.prev_get_measure='kg/cm'
      // }

      console.log('containerdetailsfromgrid',this.cntrdetails);



    }))

  }










  veiw_component_dialog()
  {


    this.comp_present_string=' ';
    this.pag_val=false;

    // add
    console.log(this.cntrdetailsadd.cntr_suffix)
    if(this.cntrAddDetailsEnable==true)
    {
      this.view_modify_container_base=this.cntrdetailsadd.cntr_base
      if (this.cntrdetailsadd.cntr_suffix==''||this.cntrdetailsadd.cntr_suffix==null)
      {
        this.view_modify_container_suffix='0';
      }
      else
      {
        this.view_modify_container_suffix=this.cntrdetailsadd.cntr_suffix;
      }
    }
// get
    else if(this.enabledetails==true)
    {
      this.view_modify_container_base=this.cntrdetails.cntr_base
      if (this.cntrdetails.cntr_suffix==''||this.cntrdetails.cntr_suffix==null)
      {
        this.view_modify_container_suffix='0';
      }
      else
      {
        this.view_modify_container_suffix=this.cntrdetails.cntr_suffix;
      }

    }

// edit
    else if (this.enableeditcntrdetails==true)
    {


      this.view_modify_container_base=this.cntrdetailsedit.cntr_base
      if (this.cntrdetailsedit.cntr_suffix==''||this.cntrdetailsedit.cntr_suffix==null)
      {
        this.view_modify_container_suffix='0';
      }
      else
      {
        this.view_modify_container_suffix=this.cntrdetailsedit.cntr_suffix;
      }

    }


    // editget

    else if (this.enableeditgetdetails==true)
    {


      this.view_modify_container_base=this.cntrdetailsedit.cntr_base
      if (this.cntrdetailsedit.cntr_suffix==''||this.cntrdetailsedit.cntr_suffix==null)
      {
        this.view_modify_container_suffix='0';
      }
      else
      {
        this.view_modify_container_suffix=this.cntrdetailsedit.cntr_suffix;
      }

    }

    this.view_comp_enable=true;
    let region;
    region=this.current_region_code_prg;

    this.transactionService.getComponentList(this.current_region_code_prg,this.view_modify_container_base,this.view_modify_container_suffix).subscribe(complist=>{
      this.comp_list=complist


      for(let cmp=0; cmp<this.comp_list.length;cmp++)
      {
        this.comp_list[cmp].cntr_lwh=this.comp_list[cmp].cntr_l+'x'+this.comp_list[cmp].cntr_w+'x'+this.comp_list[cmp].cntr_h;
      }





  if(this.comp_list.length==0)
  {
    this.compcols =[];
    this.comp_present_string='No Components present for the Container:'+this.view_modify_container_base+'-'+this.view_modify_container_suffix;
    this.pag_val=false;
  }
  else
  {

    this.comp_present_string=' ';
    this.pag_val=true;
  this.compcols = [
    { field: 'comp_base', header:'Component Base'},
    {  field: 'comp_suffix', header:'Component Suffix'},
    { field: 'comp_desc', header:'Component Description'},
    { field: 'cntr_lwh', header:'External LxWxH'},
    { field: 'cntr_exp_ret', header:'E/R'},
    { field: 'cos', header:'COS'},
    {field:'qt_per_min_su', header :'Qty Per Min Shipping unit'},
    {field:'cc_ind', header:'Componenet Indicator'}



  ];

}


})



  }

  editDailogAddModifyComponentListOfContainer()
  {


    this.associateCompFromTableButton=false;
   // this.view_modify_cd_cntr=0;

    this.view_modify_container_base=this.cntrdetailsedit.cntr_base
      if (this.cntrdetailsedit.cntr_suffix==''||this.cntrdetailsedit.cntr_suffix==null)
      {
        this.view_modify_container_suffix='0';
      }
      else
      {
        this.view_modify_container_suffix=this.cntrdetailsedit.cntr_suffix;
      }




      this.addModifyComponentListOfContainer();

  }
  buttonClickDailogAddModifyComponentListOfContainer(rowData:GridContInr)
  {
    if(rowData.req_stat != null && rowData.req_stat.toLowerCase()=='pending')
    {
      this.enableProcessingNotifiacationDialog=true;
    }
    else
    {
    this.associateCompFromTableButton=true;


    if(this.enableeditgetdetails==true)
    {
      this.enableeditgetdetails=false;

    }
    if(this.enableeditcntrdetails==true)
    {
      this.enableeditcntrdetails=false;
    }
    this.editing=false;

    if(this.cntrAddEnable==false)
    {
      this.cntrAddEnable=true;
    }

    if(this.cntrAddDetailsEnable==true)
    {
      this.cntrAddDetailsEnable=false;
    }


    if(this.enabledetails==true)
    {
          this.enabledetails=false;
    }


    this.view_modify_container_base=rowData.cntr_base
      if (rowData.cntr_suffix==''||rowData.cntr_suffix==null)
      {
        this.view_modify_container_suffix='0';
      }
      else
      {
        this.view_modify_container_suffix=rowData.cntr_suffix;
      }


      this.view_modify_cd_cntr=Number(rowData.cd_cntr);
      this.existCompToBeRemovedList=[];
      this.componentsAssociatedToContainerForPost=[];
      this.newCompToBeAddedList=[];
      this.componentListAll=[];


    this.addModifyComponentListOfContainer();
    }

  }

  addModifyComponentListOfContainer()
  {

    this.enable_alert=false;



    this.expreturnList=[{type:'[Select]'},{type:'Expendable'},{type:'Returnable'}]
    this.selectedExpRet = this.expreturnList[0];
    this.onchangeExpRet();



// get components associated with the container
this.transactionService.getComponentList(this.current_region_code_prg,this.view_modify_container_base,this.view_modify_container_suffix).subscribe(complist=>{
  this.componentListAll=[];
if(this.associateCompFromTableButton == true || this.editing == false)
{
this.selectedComponentList=[];
this.selectedComponentList=complist
}
console.log(complist);
console.log('selected comp list',this.selectedComponentList);


this.componentListAssociatedContainerApi=JSON.parse(JSON.stringify(complist));

if(this.selectedComponentList != null && this.selectedComponentList.length != 0)
{


let testvalselect=0;
testvalselect=(this.selectedComponentList.filter (({cntr_typ_desc}) => cntr_typ_desc === '01' || cntr_typ_desc === '02' )).filter (({cc_ind}) => cc_ind.toLowerCase() === 'y' ).length


for(let l=0;l<this.selectedComponentList.length;l++)
{
  this.selectedComponentList[l].cntr_lwh=this.selectedComponentList[l].cntr_l+'x'+this.selectedComponentList[l].cntr_w+'x'+this.selectedComponentList[l].cntr_h;
  this.selectedComponentList[l].enable_alert_com=false;
  this.selectedComponentList[l].enable_alert_qty=false;
  if(testvalselect>=2)
{

  if(this.selectedComponentList[l].cc_ind.toLowerCase() == 'y' && (this.selectedComponentList[l].cntr_typ_desc == '01' || this.selectedComponentList[l].cntr_typ_desc == '02'))
  {
    this.selectedComponentList[l].drop=this.dropDownList[0];
    this.selectedComponentList[l].enable=true;
  }


  else
  {
    if(this.selectedComponentList[l].cc_ind.toLowerCase() == 'y')
    {

      this.selectedComponentList[l].drop=this.dropDownList[0];

    }
    else
    {
      this.selectedComponentList[l].drop=this.dropDownList[1];
    }
    this.selectedComponentList[l].enable=false;
  }

}


else
{
  if(this.selectedComponentList[l].cntr_typ_desc == '01' || this.selectedComponentList[l].cntr_typ_desc == '02')
  {
  this.selectedComponentList[l].enable=true;
 }
 else
 {
  this.selectedComponentList[l].enable=false;
 }

 if(this.selectedComponentList[l].cc_ind.toLowerCase() == 'y')
  {

    this.selectedComponentList[l].drop=this.dropDownList[0];

  }
  else
    {
      this.selectedComponentList[l].drop=this.dropDownList[1];
    }

}

}
}


this.selectedcompcols = [
  { field: 'comp_base', header:'Component Base'},
  {  field: 'comp_suffix', header:'Component Suffix'},
  { field: 'comp_desc', header:'Component Description'},
  {field:'cntr_typ_desc',header:'Type'},
  { field: 'cntr_lwh', header:'External LxWxH'},
  { field: 'cntr_exp_ret', header:'E/R'},
  { field: 'cos', header:'COS'},
  {field:'qt_per_min_su', header :'Qty Per Min Shipping unit'},
  {field:'drop',header:'Is Compulsory'}



];

this.add_modify_comp_dialog_enable=true;
// this.applyFilterComp();


})

  }



  onchangeExpRet(){
    console.log('ExpReturn---',this.selectedExpRet);
    if(this.selectedExpRet.type!=null){
      console.log('ExpReturn',this.selectedExpRet.type);
    const expretIndex = this.expreturnList.findIndex(eluom => eluom.type.toLowerCase() == this.selectedExpRet.type.toLocaleLowerCase())
            if(expretIndex!=-1){
              this.selectedExpRet = this.expreturnList[expretIndex]
            }
            else{
              this.selectedExpRet = this.expreturnList[0]
            }
          }
          else{
            this.selectedExpRet = this.expreturnList[0]
          }
   }


applyFilterComp()
{
  // below is for filter output data
  // for now using container admin table api
  // later can be changed to new with filter api



  console.log('Contain_base',this.contain_base);

  if(this.contain_base!=null && this.contain_base!=''){
   this.cbase=this.contain_base;
  }
  else{
   this.cbase='all';
  }
  console.log('contain_suffix',this.contain_suffix);

  if(this.contain_suffix!=null && this.contain_suffix!=''){
   this.csuffix=this.contain_suffix;
  }
  else{
   this.csuffix='all';
  }

  console.log('contain_desc',this.contain_desc);

  if(this.contain_desc!=null && this.contain_desc!=''){
   this.cdesc=this.contain_desc;
  }
  else{
   this.cdesc='all';
  }

  console.log(this.select_cntr_grp_id.cntr_grp);

  if(this.select_cntr_grp_id.cntr_grp!=null && this.select_cntr_grp_id.cntr_grp!=''){
   if(this.select_cntr_grp_id.cntr_grp!='[Select]'){
     this.cntgrpid = this.select_cntr_grp_id.cntr_grp;
     console.log('Groupid', this.cntgrpid);
   }
   else{
     this.cntgrpid = 'all';
    }
  }
  else{
   this.cntgrpid = 'all';
  }
  console.log('selectedExpRet',this.selectedExpRet);
  if(this.selectedExpRet.type!=''){
   if(this.selectedExpRet.type!='[Select]'){
     this.expretype = this.selectedExpRet.type;
   }
   else{
     this.expretype = 'all';
    }
  }
  else{
   this.expretype = 'all';
  }
  console.log(this.cbase,this.csuffix,this.cdesc,this.cntgrpid,this.expretype);



this.enableAddRemoveButtons=false;
this.newCompToBeAddedList=[];
  // this.transactionService.getgridcontainer(this.current_region_code_prg).subscribe(cnotgrid => {
    this.transactionService.getallcontainercomponentonfilter(this.current_region_code_prg,this.cbase,this.csuffix,this.cdesc,this.cntgrpid,this.expretype).subscribe(cnotgrid => {
    this.componentListAll=[];
    this.componentListFromAPi=cnotgrid;
    console.log(this.componentListFromAPi)
    this.add_modify_comp_dialog_enable=true;// to be changed - enabling dialog box
    this.enableAddRemoveButtons=true;

    if(this.componentListFromAPi != null && this.componentListFromAPi.length != 0)
    {
      for(let h=0;h<this.componentListFromAPi.length;h++)
      {
        if(this.componentListFromAPi[h].cd_cntr != this.view_modify_cd_cntr)
        {
      this.componentListAll.push({

                    cd_cntr:this.componentListFromAPi[h].cd_cntr,
                    comp_base:this.componentListFromAPi[h].cntr_base,
                    comp_suffix:this.componentListFromAPi[h].cntr_suffix,
                    comp_desc:this.componentListFromAPi[h].cntr_desc,
                    qt_per_min_su:0,
                    cntr_l:this.componentListFromAPi[h].cntr_exterior_l,
                    cntr_w:this.componentListFromAPi[h].cntr_exterior_w,
                    cntr_h:this.componentListFromAPi[h].cntr_exterior_h,
                    cos:this.componentListFromAPi[h].ownrshp_strat_code,
                    cntr_exp_ret:this.componentListFromAPi[h].exp_refundable,
                    cc_ind:'',
                    cntr_typ_desc:this.componentListFromAPi[h].cntr_typ_desc,
                    func_id:'',
                    drop:{name:'',namevalue:''},
                    enable:false,
                    cntr_lwh:'',
                    enable_alert_com:false,
                    enable_alert_qty:false,
                    cd_owner:this.componentListFromAPi[h].cd_owner


      })
    }
    }

    }

  if(this.componentListAll != null && this.componentListAll.length != 0)
  {
  for(let i=0;i<this.componentListAll.length;i++)
  {
    this.componentListAll[i].cntr_lwh=this.componentListAll[i].cntr_l+'x'+this.componentListAll[i].cntr_w+'x'+this.componentListAll[i].cntr_h;
    this.componentListAll[i].qt_per_min_su=1;

    if(this.componentListAll[i].cntr_typ_desc == '01' || this.componentListAll[i].cntr_typ_desc == '02')
    {
      this.componentListAll[i].enable= true;
      this.componentListAll[i].drop={name:'',namevalue:''};
    }

    else
    {
      this.componentListAll[i].enable=false;
      this.componentListAll[i].drop=this.dropDownList[1];
    }
  }


  console.log(this.selectedComponentList);
  if(this.selectedComponentList != null && this.selectedComponentList.length!=0)
  {
    for(let v=0;v<this.selectedComponentList.length;v++)
    {
      const indexSelectList=this.componentListAll.findIndex(elefin=>elefin.cd_cntr == this.selectedComponentList[v].cd_cntr)
      {
        if(indexSelectList != -1)
        {
          this.componentListAll.splice(indexSelectList,1);
        }
      }
    }
  }

  }

  this.compListAllValuesCols=[
    { field: 'comp_base', header:'Component Base'},
    {  field: 'comp_suffix', header:'Component Suffix'},
    { field: 'comp_desc', header:'Component Description'},
    {field:'cntr_typ_desc',header:'Type'},
    { field: 'cntr_lwh', header:'External LxWxH'},
    { field: 'cntr_exp_ret', header:'E/R'},
    { field: 'cos', header:'COS'},

  ];
})

}


onChangeQtyPerShpUntValue(rowDataQty:componentlist)
{
  console.log('Qty',rowDataQty)
  if(rowDataQty.qt_per_min_su > 0 && rowDataQty.qt_per_min_su < 100000 && rowDataQty.enable_alert_qty == true)
  {
    const finIndex=this.selectedComponentList.findIndex(elef=>elef.cd_cntr == rowDataQty.cd_cntr);
    if(finIndex != -1)
    {
      this.selectedComponentList[finIndex].enable_alert_qty=false;
      // console.log("Qty1",this.selectedComponentList[finIndex])
    }
  }


  else if((rowDataQty.qt_per_min_su < 1 ||  rowDataQty.qt_per_min_su >= 100000) && rowDataQty.enable_alert_qty == false)
  {
    const finIndex=this.selectedComponentList.findIndex(elef=>elef.cd_cntr == rowDataQty.cd_cntr);
    // console.log("Qty3",finIndex);
    // console.log("Qty4",this.selectedComponentList)
    if(finIndex != -1)
    {
      this.selectedComponentList[finIndex].enable_alert_qty=true;
      // console.log("Qty2",this.selectedComponentList[finIndex])
    }
  }
}

onChangeDropdownIsCompulsoryValueFromUI(rowData:componentlist)
{
  if(rowData.drop.name != '' && rowData.enable_alert_com == true)
  {
    const finIndex=this.selectedComponentList.findIndex(elef=>elef.cd_cntr == rowData.cd_cntr);
    if(finIndex != -1)
    {
      this.selectedComponentList[finIndex].enable_alert_com=false;
    }
  }

  this.onChangeDropdownIsCompulsoryValue()

}
onChangeDropdownIsCompulsoryValue()
{

 if(this.selectedComponentList != null && this.selectedComponentList.length != 0)
 {
  let testval=0;
  console.log(this.selectedComponentList)
  const test=JSON.parse(JSON.stringify(this.selectedComponentList))
  console.log(test)


      for(let k=0;k<this.selectedComponentList.length;k++)
      {
        if(this.selectedComponentList[k].drop.name.toLowerCase() == 'yes' &&(this.selectedComponentList[k].cntr_typ_desc == '01' || this.selectedComponentList[k].cntr_typ_desc == '02'))
        {
          testval+=1;
        }
      }


      console.log('testval',testval)
    if(testval>=2)
    {
      // this.corrosionProtectionList.forEach(elecrr=> elecrr.drop=((elecrr.typecode == "01" || elecrr.typecode == "02") && elecrr.enable ==true ? this.dropDownList[1] : elecrr.drop));
      for(let k=0;k<this.selectedComponentList.length;k++)
      {
        if((this.selectedComponentList[k].cntr_typ_desc == '01' || this.selectedComponentList[k].cntr_typ_desc == '02') && this.selectedComponentList[k].enable == true && this.selectedComponentList[k].drop.name.toLowerCase() != 'yes')
        {
          this.selectedComponentList[k].drop=this.dropDownList[1];
          this.selectedComponentList[k].enable= false;

        }
      }
    }

    else
    {
      for(let k=0;k<this.selectedComponentList.length;k++)
      {
        if((this.selectedComponentList[k].cntr_typ_desc == '01' || this.selectedComponentList[k].cntr_typ_desc == '02') && this.selectedComponentList[k].enable == false)
        {

          this.selectedComponentList[k].enable= true;

        }
      }

    }
 }
}


addComponentToContainer()
{


  console.log(this.newCompToBeAddedList);


  if(this.newCompToBeAddedList != null && this.newCompToBeAddedList.length != 0)
  {
    let testvalnew=0;
 if(this.selectedComponentList != null && this.selectedComponentList.length != 0)
 {


  testvalnew=(this.selectedComponentList.filter (({drop}) => drop.name.toLowerCase() === 'yes')).filter (({cntr_typ_desc}) => cntr_typ_desc === '01' || cntr_typ_desc === '02' ).length
 }
      for(let m=0;m<this.newCompToBeAddedList.length;m++)
      {

            if(testvalnew<2)
            {

              if(this.newCompToBeAddedList[m].cntr_typ_desc == '01' || this.newCompToBeAddedList[m].cntr_typ_desc == '02')
              {
                this.newCompToBeAddedList[m].enable=true;
                this.newCompToBeAddedList[m].drop={name:'',namevalue:''}
              }

              else
              {
                this.newCompToBeAddedList[m].enable=false;
                this.newCompToBeAddedList[m].drop=this.dropDownList[1];
              }

            }

            else
            {
              this.newCompToBeAddedList[m].enable=false;
              this.newCompToBeAddedList[m].drop=this.dropDownList[1];
            }
      }


      const arrayToAppendExsistSelectedList=[];
      arrayToAppendExsistSelectedList.push(...this.newCompToBeAddedList);
      if(this.selectedComponentList != null && this.selectedComponentList.length != 0)
      {
      arrayToAppendExsistSelectedList.push(... this.selectedComponentList);
      }



      this.selectedComponentList=[];
      this.selectedComponentList.push(...arrayToAppendExsistSelectedList);


      for(let p=0;p<this.newCompToBeAddedList.length;p++)
      {
        const newIndex=this.componentListAll.findIndex(elere=>elere.cd_cntr == this.newCompToBeAddedList[p].cd_cntr)
        if(newIndex != -1)
        {
          console.log('inside splice')
          this.componentListAll.splice(newIndex,1);



        }
      }


      const test_data=this.componentListAll;
      this.componentListAll=[];
      this.componentListAll.push(...test_data);


      this.newCompToBeAddedList=[];


    }

}


removeComponentOfContainer()
{


  console.log(this.existCompToBeRemovedList);


  if(this.existCompToBeRemovedList != null && this.existCompToBeRemovedList.length != 0)
  {

 for(let s=0;s<this.existCompToBeRemovedList.length;s++)
 {
  const remIndex=this.selectedComponentList.findIndex(elere=>elere.cd_cntr == this.existCompToBeRemovedList[s].cd_cntr)
  if(remIndex != -1)
  {
    this.selectedComponentList.splice(remIndex,1);
  }
 }

      this.onChangeDropdownIsCompulsoryValue();



      const arrayToAppendExsistFilteredList=[];

      arrayToAppendExsistFilteredList.push(...this.existCompToBeRemovedList);
      if(this.componentListAll != null && this.componentListAll.length != 0)
      {
      arrayToAppendExsistFilteredList.push(... this.componentListAll);
      }


      this.componentListAll=[];
      this.componentListAll.push(...arrayToAppendExsistFilteredList)

      this.existCompToBeRemovedList=[];

}


}


saveAssociatedComponentsToCmms()
{

  this.enable_alert == false
  for(let u=0;u<this.selectedComponentList.length;u++)
  {
    if(this.selectedComponentList[u].drop.name == '')
    {
      this.selectedComponentList[u].enable_alert_com=true;
      this.enable_alert=true;
    }

    if(this.selectedComponentList[u].qt_per_min_su < 1 || this.selectedComponentList[u].qt_per_min_su >= 100000)
    {
      this.selectedComponentList[u].enable_alert_qty=true;
      this.enable_alert=true;
    }
  }

  if(this.enable_alert == false)
  {

  let componentsToCmms:componentlist[]=[];
  console.log('component associated to container',this.componentListAssociatedContainerApi);
  console.log('component list selected',this.selectedComponentList)
      if(this.selectedComponentList != null && this.selectedComponentList.length != 0)
        {


          console.log(this.componentListAssociatedContainerApi);
          if(this.componentListAssociatedContainerApi != null && this.componentListAssociatedContainerApi.length != 0)
          {

            for(let z=0;z<this.componentListAssociatedContainerApi.length;z++)
            {
              const valueOldExist= this.selectedComponentList.some(eleol=>eleol.cd_cntr == this.componentListAssociatedContainerApi[z].cd_cntr)
              if(valueOldExist)
              {
                const indexOldExist= this.selectedComponentList.findIndex(eleol=>eleol.cd_cntr == this.componentListAssociatedContainerApi[z].cd_cntr)
                if(this.selectedComponentList[indexOldExist].drop.name.toLowerCase() == 'yes')
                {
                  this.selectedComponentList[indexOldExist].cc_ind='Y'
                }
                else
                {
                  this.selectedComponentList[indexOldExist].cc_ind=' '
                }
                this.selectedComponentList[indexOldExist].func_id='C'
                componentsToCmms.push(this.selectedComponentList[indexOldExist])

              }
              else{
                this.componentListAssociatedContainerApi[z].func_id='D'
                componentsToCmms.push(this.componentListAssociatedContainerApi[z])
              }
            }



          for(let t=0;t<this.selectedComponentList.length;t++)
          {
            const valuesnewcomp=this.componentListAssociatedContainerApi.findIndex(elenec=>elenec.cd_cntr == this.selectedComponentList[t].cd_cntr)
            if(valuesnewcomp == -1)
            {

              if(this.selectedComponentList[t].drop.name.toLowerCase() == 'yes')
                {
                  this.selectedComponentList[t].cc_ind='Y'
                }
                else
                {
                  this.selectedComponentList[t].cc_ind=' '
                }
                this.selectedComponentList[t].func_id='A'
                componentsToCmms.push(this.selectedComponentList[t])

            }
          }

        }


        else
        {


          componentsToCmms=[];
          for(let t=0;t<this.selectedComponentList.length;t++)
          {


              if(this.selectedComponentList[t].drop.name.toLowerCase() == 'yes')
                {
                  this.selectedComponentList[t].cc_ind='Y'
                }
                else
                {
                  this.selectedComponentList[t].cc_ind=' '
                }
                this.selectedComponentList[t].func_id='A'
                componentsToCmms.push(this.selectedComponentList[t])


          }

        }
      }
      else
      {
        if(this.componentListAssociatedContainerApi != null && this.componentListAssociatedContainerApi.length != 0)
        {
          componentsToCmms=[];
          for(let z=0;z<this.componentListAssociatedContainerApi.length;z++)
          {


            this.componentListAssociatedContainerApi[z].func_id='D'
            componentsToCmms.push(this.componentListAssociatedContainerApi[z])

          }
        }

        else
        {
          this.enable_notification_associatecontainer=true;
        }
      }



          console.log(componentsToCmms)

          if(componentsToCmms != null && componentsToCmms.length !=0 )
          {

          this.componentsAssociatedToContainerForPost=[];

          for(let x=0;x<componentsToCmms.length;x++)
          {
            if(componentsToCmms[x].comp_desc != null && componentsToCmms[x].comp_desc.trim()!='' && componentsToCmms[x].comp_desc.trim().length >25)
            {
              const str = componentsToCmms[x].comp_desc.trim().slice(0, 24);
            }
            else
            {
              const str=componentsToCmms[x].comp_desc.trim()
            }
            let owner=''
            if(componentsToCmms[x].cd_owner != null && componentsToCmms[x].cd_owner.toLowerCase().indexOf('supp')>-1)
            {
              owner='SUPP'
            }
            else if(componentsToCmms[x].cd_owner != null && componentsToCmms[x].cd_owner.toLowerCase().indexOf('ford')>-1)
            {
              owner='FORD'
            }
            else if(componentsToCmms[x].cd_owner != null && componentsToCmms[x].cd_owner.toLowerCase().indexOf('third party')>-1)
            {
              owner='TPT'
            }
            else
            {
              owner='FORD'
            }
          // @ts-ignore

              this.componentsAssociatedToContainerForPost.push({
            mp_function:componentsToCmms[x].func_id,
            no_comp_base:componentsToCmms[x].comp_base,
            no_comp_suffix:componentsToCmms[x].comp_suffix,
                  // @ts-ignore
            ds_comp:str,
            comp_qt_per_min_su:componentsToCmms[x].qt_per_min_su,
            comp_cd_owner:owner,
            in_comp:componentsToCmms[x].cc_ind,
            comp_cd_type:componentsToCmms[x].cntr_typ_desc
          })
          }


          console.log('component for post', this.componentsAssociatedToContainerForPost);


          if(this.associateCompFromTableButton == true)
          {

            this.containerComptMapDetailsInit();
            this.editing=false;
            this.transactionService.editbutton_get_contr_details(this.view_modify_container_base,this.view_modify_cd_cntr).subscribe(savecntrdetails=>{
              console.log('cntr details map',savecntrdetails)
              this.cntrdetailsforcompmap.function='C';
              this.cntrdetailsforcompmap.cntr_base=savecntrdetails.cntr_base;
              this.cntrdetailsforcompmap.cntr_suff_ind=savecntrdetails.cntr_suff_ind;
              this.cntrdetailsforcompmap.exp_refundable=savecntrdetails.exp_refundable;
              this.cntrdetailsforcompmap.cntr_active_ind=savecntrdetails.cntr_active_ind;
              this.cntrdetailsforcompmap.cntr_suffix=savecntrdetails.cntr_suffix;
              this.cntrdetailsforcompmap.cntr_desc=savecntrdetails.cntr_desc;
              this.cntrdetailsforcompmap.card_call=savecntrdetails.card_call;
              this.cntrdetailsforcompmap.cntr_mstr_catg=savecntrdetails.cntr_mstr_catg;
              this.cntrdetailsforcompmap.cntr_typ_desc=savecntrdetails.cntr_typ_desc;
              this.cntrdetailsforcompmap.ownrshp_strat_code=savecntrdetails.ownrshp_strat_code;
              this.cntrdetailsforcompmap.ownrshp_catg=savecntrdetails.ownrshp_catg;
              this.cntrdetailsforcompmap.cntr_grp_desc=savecntrdetails.cntr_grp_desc;
              this.cntrdetailsforcompmap.cntr_matr_desc=savecntrdetails.cntr_matr_desc;
              this.cntrdetailsforcompmap.currency_desc=savecntrdetails.currency_desc;
              this.cntrdetailsforcompmap.iscontainercolapsble=savecntrdetails.iscontainercolapsble;
              this.cntrdetailsforcompmap.typ_no=savecntrdetails.typ_no


              this.cntrdetailsforcompmap.cntr_exterior_l=savecntrdetails.cntr_exterior_l;
              this.cntrdetailsforcompmap.cntr_exterior_w=savecntrdetails.cntr_exterior_w;
              this.cntrdetailsforcompmap.cntr_exterior_h=savecntrdetails.cntr_exterior_h;
              this.cntrdetailsforcompmap.collapsed_ht=savecntrdetails.collapsed_ht;
              this.cntrdetailsforcompmap.nest_ratio=savecntrdetails.nest_ratio;
              this.cntrdetailsforcompmap.cntr_interior_l=savecntrdetails.cntr_interior_l;
              this.cntrdetailsforcompmap.cntr_interior_w=savecntrdetails.cntr_interior_w;
              this.cntrdetailsforcompmap.cntr_interior_h=savecntrdetails.cntr_interior_h;
              this.cntrdetailsforcompmap.cntr_tare_wt=savecntrdetails.cntr_tare_wt;
              if(savecntrdetails.cntr_capacity != null)
              {
              this.cntrdetailsforcompmap.cntr_capacity=savecntrdetails.cntr_capacity;
              }
              this.cntrdetailsforcompmap.cntr_per_layer=savecntrdetails.cntr_per_layer;
              this.cntrdetailsforcompmap.max_layers_shpg_unit=savecntrdetails.max_layers_shpg_unit;
              this.cntrdetailsforcompmap.max_cntr_per_shpg_unit=savecntrdetails.max_cntr_per_shpg_unit;
              this.cntrdetailsforcompmap.cntr_drawing_no=savecntrdetails.cntr_drawing_no;
              this.cntrdetailsforcompmap.shpg_unit_exterior_l=savecntrdetails.shpg_unit_exterior_l;
              this.cntrdetailsforcompmap.shpg_unit_exterior_w=savecntrdetails.shpg_unit_exterior_w;
              this.cntrdetailsforcompmap.shpg_unit_exterior_h=savecntrdetails.shpg_unit_exterior_h;
              this.cntrdetailsforcompmap.shpg_unit_nstdorflded_ht=savecntrdetails.shpg_unit_nstdorflded_ht;
              this.cntrdetailsforcompmap.shpg_unit_tare_wt=savecntrdetails.shpg_unit_tare_wt;
              this.cntrdetailsforcompmap.shpg_unit_stack_ht=savecntrdetails.shpg_unit_stack_ht;
              if(savecntrdetails.warehouse_stack_ht != null)
              {
              this.cntrdetailsforcompmap.warehouse_stack_ht=savecntrdetails.warehouse_stack_ht;
              }
              this.cntrdetailsforcompmap.service_life=savecntrdetails.service_life;
              this.cntrdetailsforcompmap.purchase_cost=savecntrdetails.purchase_cost;
              this.cntrdetailsforcompmap.rental_issue_cost=savecntrdetails.rental_issue_cost;
              this.cntrdetailsforcompmap.sup_rntl_dly_cost=savecntrdetails.sup_rntl_dly_cost;
              this.cntrdetailsforcompmap.ford_rntl_dly_cost=savecntrdetails.ford_rntl_dly_cost;
              this.cntrdetailsforcompmap.return_rntl_cost=savecntrdetails.return_rntl_cost;
              this.cntrdetailsforcompmap.tooling_dev_cost=savecntrdetails.tooling_dev_cost;
              this.cntrdetailsforcompmap.extr_cntr_dsgn_img_ind=savecntrdetails.extr_cntr_dsgn_img_ind;
              this.cntrdetailsforcompmap.intr_cntr_dsgn_img_ind=savecntrdetails.intr_cntr_dsgn_img_ind;
              this.cntrdetailsforcompmap.cad_img_ind=savecntrdetails.cad_img_ind;
              this.cntrdetailsforcompmap.img_notes=savecntrdetails.img_notes;
              this.cntrdetailsforcompmap.cd_region=savecntrdetails.cd_region;
              this.cntrdetailsforcompmap.shpg_unit_img_ind=savecntrdetails.shpg_unit_img_ind;


              // uom field added
              if(savecntrdetails.uom == null || savecntrdetails.uom.trim() == '')
              {
                this.cntrdetailsforcompmap.UoM='E'
              }
              else
              {
                this.cntrdetailsforcompmap.UoM=savecntrdetails.uom
              }



              if(savecntrdetails.cd_plant != null)
              {
              this.cntrdetailsforcompmap.cd_plant= savecntrdetails.cd_plant;
              }
              if(savecntrdetails.cnt_qt_per_min_su != null)
              {
                this.cntrdetailsforcompmap.cntr_qt_per_min_su=savecntrdetails.cnt_qt_per_min_su;
              }
              if(savecntrdetails.nest_ratio_1 != null)
              {
                this.cntrdetailsforcompmap.nest_ratio_1=savecntrdetails.nest_ratio_1;
              }
              if(savecntrdetails.ford_cntct != null)
              {
                this.cntrdetailsforcompmap.ford_cntct= savecntrdetails.ford_cntct;
              }
              if(savecntrdetails.cntct_phone != null)
              {
                this.cntrdetailsforcompmap.cntct_phone=savecntrdetails.cntct_phone;
              }



              this.cntrdetailsforcompmap.req_stat='New';
              this.cntrdetailsforcompmap.id_user=this.transactionService.getcdsid();;
              this.cntrdetailsforcompmap.ts_last_update=this.date.transform(new Date(),'yyyy-MM-dd');




              this.cntrdetailsforcompmap.cd_region=this.current_region_code_prg;
              this.cntrdetailsforcompmap.extr_cntr_dsgn_img_ind='No';
              this.cntrdetailsforcompmap.intr_cntr_dsgn_img_ind='No';
              this.cntrdetailsforcompmap.shpg_unit_img_ind='No';
              this.cntrdetailsforcompmap.cad_img_ind='No';

              if(this.cntrdetailsforcompmap.iscontainercolapsble!=null && this.cntrdetailsforcompmap.iscontainercolapsble.toLowerCase()=='n')
              {
                this.cntrdetailsforcompmap.collapsed_ht=1;
              }




              if(this.cntrdetailsforcompmap.cntr_drawing_no == null )
              {
                this.cntrdetailsforcompmap.cntr_drawing_no=''
              }

              if(this.cntrdetailsforcompmap.service_life == null)
              {
                this.cntrdetailsforcompmap.service_life='';
              }





              this.cntrdetailsforcompmap.ford_rntl_dly_cost=0;
              this.cntrdetailsforcompmap.purchase_cost=0;
              this.cntrdetailsforcompmap.rental_issue_cost=0;
              this.cntrdetailsforcompmap.return_rntl_cost=0;
              this.cntrdetailsforcompmap.sup_rntl_dly_cost=0;
              this.cntrdetailsforcompmap.tooling_dev_cost=0;

              if(this.componentsAssociatedToContainerForPost != null && this.componentsAssociatedToContainerForPost.length != 0)
              {
                this.cntrdetailsforcompmap.components=[];
              this.cntrdetailsforcompmap.components.push(...this.componentsAssociatedToContainerForPost);
              }

              console.log('cntrdetailsforcompmap',this.cntrdetailsforcompmap);

                  // confirm needed and then do below

              this.transactionService.edit_savecntrdetails(this.cntrdetailsforcompmap).subscribe(savecntr=>
                {
                    const res=savecntr;
                    console.log('successful',res);
                    if(res.toLowerCase() == 'success')
                    {
                      this.messageService.add({severity:'success', summary:'Success', detail:'Container component mapped successfully'});

                      // Commented because details should not be shown until the record gets updated in cmms
                      // this.geteditedcntrdetails(this.cntrdetailsedit);
                      this.selectedComponentList=[];
                      this.componentListAll=[];
                      this.newCompToBeAddedList=[];
                      this.existCompToBeRemovedList=[];
                      this.componentsAssociatedToContainerForPost=[];
                      this.add_modify_comp_dialog_enable=false;
                      this.associateCompFromTableButton=false;
                      this.DisplayGrid(this.current_region_code_prg);
                    }
                    else
                    {
                        this.messageService.add({severity:'error', summary: 'Error', detail: 'Container component not mapped,Please Try Again'});

                    }
                  })

            })


          }
          // get container details , check for req status if pending dont allow them to save else they can save --- not required

          else
          {

             this.editing=true;
              this.add_modify_comp_dialog_enable=false;
              this.associateCompFromTableButton=false;


          }


        }

      }
}




cancelViewModifycomponentDialog()
{
  this.selectedComponentList=[];
  this.componentListAll=[];
  this.newCompToBeAddedList=[];
  this.existCompToBeRemovedList=[];
  this.componentsAssociatedToContainerForPost=[];
  this.add_modify_comp_dialog_enable=false;
  this.associateCompFromTableButton=false;
  this.editing=false;
}


okNotificationSaveNoComponentSelected()
{
  this.enable_notification_associatecontainer=false;
}



okNotificationAlert()
{
  this.enable_alert=false;
}





  disableAddingContainer() {
      if (!(this.cntrdetailsadd.cntr_base) ||  !(this.cntrdetailsadd.cntr_desc) || !(this.selectedPlantList) ||
          !(this.cntrdetailsadd.card_call) || !(this.selected_cntr_master_category) || !(this.cntrdetailsadd.exp_refundable) ||
          !(this.selected_cntr_type_code) || !(this.selected_cntr_ownrship_strat_code) ||  !(this.selected_cntr_group) ||
          !(this.cntrdetailsadd.iscontainercolapsble) || !((this.cntrdetailsadd.iscontainercolapsble == 'Y' && (this.cntrdetailsadd.collapsed_ht > 0)) ||(this.cntrdetailsadd.iscontainercolapsble == 'N')) ||
          !(this.cntrdetailsadd.nest_ratio>0) || !(this.cntrdetailsadd.nest_ratio_1>0) || !(this.cntrdetailsadd.cntr_tare_wt > 0) ||
          !(this.cntrdetailsadd.cntr_capacity>0) || !(this.cntrdetailsadd.cntr_per_layer > 0) ||   !(this.cntrdetailsadd.max_layers_shpg_unit > 0) ||
          !(this.cntrdetailsadd.ford_cntct) || !(this.cntrdetailsadd.cntr_qt_per_min_su>0) || !(this.cntrdetailsadd.cntct_phone) ||
          !(this.cntrdetailsadd.cntr_exterior_l > 0) || !(this.cntrdetailsadd.cntr_exterior_w > 0) || !(this.cntrdetailsadd.cntr_exterior_h > 0) ||
          !(this.cntrdetailsadd.cntr_active_ind) || !(this.cntrdetailsadd.shpg_unit_nstdorflded_ht > 0) ||!(this.cntrdetailsadd.shpg_unit_exterior_l > 0) ||
          !(this.cntrdetailsadd.shpg_unit_exterior_w > 0) || !(this.cntrdetailsadd.shpg_unit_exterior_h > 0) || !(this.cntrdetailsadd.warehouse_stack_ht >0)   || !(this.selected_cntr_material.cntr_matr_name!='') ||
            !(this.selected_ownrship_catg)
  // || !(this.selected_cntr_material)    || !(this.selected_cntr_currency_list)
          ) {
          return true;
      } else {
          return false;
      }


      /* Removing add suffix to container
      * || !((this.cntrSuffixenabled==true && this.cntrdetailsadd.cntr_suffix)||
     (this.cntrSuffixenabled==false && this.cntrdetailsadd.cntr_suffix.trim()==''))
     * */
  }


  disabledSavingInEditMode()
  {

    if (!(this.cntrdetailsedit.cntr_base) ||  !(this.cntrdetailsedit.cntr_desc) || !(this.editSelectedPlantCode.plantGSDBCode!='') ||
          !(this.cntrdetailsedit.card_call) || !(this.edit_selected_cntr_master_category.cntr_mstr_catg!='') || !(this.cntrdetailsedit.exp_refundable) ||
          !(String(this.edit_selected_cntr_type_code.cd_cntr_typ)!='') || !(this.edit_selected_cntr_ownrship_strat_code.ownrshp_strat_code!='') ||  !(this.edit_selected_cntr_group.cntr_grp!='') ||
          !(this.cntrdetailsedit.iscontainercolapsble) || !((this.cntrdetailsedit.iscontainercolapsble == 'Y' && (this.cntrdetailsedit.collapsed_ht > 0)) ||(this.cntrdetailsedit.iscontainercolapsble == 'N')) ||
          !(this.cntrdetailsedit.nest_ratio>0) || !(this.cntrdetailsedit.nest_ratio_1>0) || !(this.cntrdetailsedit.cntr_tare_wt > 0) ||
          !(this.cntrdetailsedit.cntr_capacity>0) || !(this.cntrdetailsedit.cntr_per_layer > 0) ||   !(this.cntrdetailsedit.max_layers_shpg_unit > 0) ||
          !(this.cntrdetailsedit.ford_cntct) || !(this.cntrdetailsedit.cntr_qt_per_min_su>0) || !(this.cntrdetailsedit.cntct_phone) ||
          !(this.cntrdetailsedit.cntr_exterior_l > 0) || !(this.cntrdetailsedit.cntr_exterior_w > 0) || !(this.cntrdetailsedit.cntr_exterior_h > 0) ||
          !(this.cntrdetailsedit.cntr_active_ind) || !(this.cntrdetailsedit.shpg_unit_nstdorflded_ht > 0) ||!(this.cntrdetailsedit.shpg_unit_exterior_l > 0) ||
          !(this.cntrdetailsedit.shpg_unit_exterior_w > 0) || !(this.cntrdetailsedit.shpg_unit_exterior_h > 0) || !(this.cntrdetailsedit.warehouse_stack_ht >0)   ||  !(this.edit_selected_cntr_material.cntr_matr_name!='')
        || !(this.edit_selected_ownrship_catg)  ||!(this.edit_selected_ownrship_catg.ownrshp_catg_val != '')
  || !(this.selected_cntr_material)    || !(this.selected_cntr_currency_list)
          )

 /*   if(!this.cntrdetailsedit.cntr_base || !this.cntrdetailsedit.cntr_desc|| !(this.cntrdetailsedit.cntr_per_layer>0) || !(this.cntrdetailsedit.shpg_unit_nstdorflded_ht>0) || !(this.cntrdetailsedit.cntr_tare_wt>0) ||
    !(this.cntrdetailsedit.max_layers_shpg_unit>0) || !(this.cntrdetailsedit.shpg_unit_exterior_l>0) ||
    !(this.cntrdetailsedit.shpg_unit_exterior_w>0) || !(this.cntrdetailsedit.shpg_unit_exterior_h>0) ||
    !(this.cntrdetailsedit.cntr_exterior_l>0) || !(this.cntrdetailsedit.cntr_exterior_w>0) ||
    !(this.cntrdetailsedit.cntr_exterior_h>0) || !(this.cntrdetailsedit.card_call=='Card' || this.cntrdetailsedit.card_call=='Call') || !this.cntrdetailsedit.exp_refundable || (this.edit_selected_cntr_master_category.cntr_mstr_catg=='') ||(this.edit_selected_cntr_type_code.cntr_typ_desc=='') ||
    (this.edit_selected_cntr_ownrship_strat_code.ownrshp_desc=='') || (this.edit_selected_ownrship_catg.ownrshp_catg=='') || (this.edit_selected_cntr_group.cntr_grp=='') || (this.edit_selected_cntr_material.cntr_matr_name=='') || (this.edit_selected_cntr_currency_list.curr_desc=='') ||
    !this.cntrdetailsedit.cntr_active_ind || !this.cntrdetailsedit.iscontainercolapsble ||
    !((this.cntrdetailsedit.iscontainercolapsble=='Y'&& (this.cntrdetailsedit.collapsed_ht>0)) || (this.cntrdetailsedit.iscontainercolapsble=='N')))*/
    {
      return true;
    }
    else
    {
      return false;
    }


    /* Removing add suffix to container
    || !((this.cntrSuffixEditenabled==true && this.cntrdetailsedit.cntr_suffix)||
    (this.cntrSuffixEditenabled==false && this.cntrdetailsedit.cntr_suffix.trim()==''))
     */
  }


  ok_notification_processing_container_details()
  {
    this.enableProcessingNotifiacationDialog=false;
  }

  okCntrEditPendingNotification()
  {
    this.enableCntrEditPendingNotification=false;
    // this.addCntrRequestToDBIA();
  }

  okAddingNewContainerNotification()
  {
    this.enableAddingNewContainerNotification=false;
    this.addCntrRequestToDBIA();
  }

  cancelAddingNewContainerNotification()
  {
    this.enableAddingNewContainerNotification=false;
  }

  okUpdateExistingCntrNotification()
  {
    this.enableUpdateExistingCntrNotification=false;
    this.addCntrRequestToDBIA();
  }

  cancelUpdateExistingCntrNotification()
  {
    this.enableUpdateExistingCntrNotification=false;
  }


/*---------------------Component------------------------*/








DisplayGridComp(setRegion:string)
{
this.transactionService.getgridcontainer(setRegion).subscribe(cnotgrid => {
 this.unModifiedGrid=cnotgrid;
 // this.gridWithBaseAndSuffixAppended();

        this.componentGrid=this.unModifiedGrid.filter(e=>['02','03'].includes(String(e.cntr_typ_desc)));
        console.log('Container Grid values ',this.componentGrid);

        for(let i=0; i<this.componentGrid.length; i++)
        {
            this.componentGrid[i].cntr_base_suffix=this.componentGrid[i].cntr_base;

            if(this.componentGrid[i].cntr_suffix!=null && this.componentGrid[i].cntr_suffix.trim()!='')
            {
                this.componentGrid[i].cntr_base_suffix+='-'+this.componentGrid[i].cntr_suffix;
            }
        }


});


  // { field: 'cntr_base', header:'Container Number'},,

  this.colscompGrid=[

    {field:'cntr_base_suffix',header:'Component Number'},
    { field: 'cntr_desc', header:'Description'},
    { field: 'ownrshp_strat_code', header:'Ownership Strategy'},
    { field: 'exp_refundable', header:'E / R'},
    { field: 'cntr_active_ind', header:'Active'}
];


// this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
}










editcompdetailsinit()
{
  this.enable_material_dropdown_comp=false;
  this.cntrSuffixEditenabled=false;
    this.compdetailsedit=
    {
        // cd_cntr:0,
        function:'C',
        cntr_suff_ind:'',
        exp_refundable:'Returnable',
        cntr_active_ind:'',
        cntr_base:'',
        cntr_suffix:'',
        cntr_desc:'',
        card_call:'',
        cntr_mstr_catg:'',
        cntr_typ_desc:'',
        ownrshp_strat_code:'',
        ownrshp_catg:'',
        cntr_grp_desc:'',
        cntr_exterior_l:1,
	      cntr_exterior_w:1,
	      cntr_exterior_h:1,
        collapsed_ht:1,
        nest_ratio:1,
        cntr_interior_l:1,
        cntr_interior_w:1,
        cntr_interior_h:1,
        cntr_tare_wt:1,
        cntr_capacity:1,
        cntr_per_layer:1,
        max_layers_shpg_unit:1,
        max_cntr_per_shpg_unit:1,
        cntr_matr_desc:'',
        cntr_drawing_no:'',
        shpg_unit_exterior_l:1,
        shpg_unit_exterior_w:1,
        shpg_unit_exterior_h:1,
        shpg_unit_nstdorflded_ht:1,
        shpg_unit_tare_wt:1,
       shpg_unit_stack_ht:1,
       warehouse_stack_ht:1,
        service_life:'',
        currency_desc:'',
        purchase_cost:1,
        rental_issue_cost:1,
        sup_rntl_dly_cost:1,
        ford_rntl_dly_cost:1,
        return_rntl_cost:1,
        tooling_dev_cost:1,
        extr_cntr_dsgn_img_ind:'',
        intr_cntr_dsgn_img_ind:'',
        shpg_unit_img_ind:'',
        cad_img_ind:'',
        img_notes:'',
        cd_region:'',
        iscontainercolapsble:'',
        cd_plant:'',
        cntr_qt_per_min_su:1,
        nest_ratio_1:1,
        ford_cntct:'',
        cntct_phone:'',
        id_user:'',
        ts_last_update:'',
        req_stat:'',
        components:[{
            mp_function:'',
            no_comp_base:'',
            no_comp_suffix:'',
            ds_comp:'',
            comp_qt_per_min_su:0,
            comp_cd_owner:'',
            in_comp:'',
            comp_cd_type:''
        }],
        UoM:'E',
        typ_no:0,
        dbia_req_no:0
    }
}



addcompdetailsinit()
{
  this.enable_material_dropdown_comp=false;
    this.cntrSuffixenabled=false;
    // this.selectedcntrbase=null;
    this.selectedCntrFromAutoCmplete=null;
    this.selected_cntr_material={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''}
    this.selectedSuffixListComp={suppliergsdbcode:''}
    this.compdetailsadd=
    {
        // cd_cntr:0,
        function:'A',
        cntr_suff_ind:'',
        exp_refundable:'Returnable',
        cntr_active_ind:"Yes",
        cntr_base:'',
        cntr_suffix:'',
        cntr_desc:'',
        card_call:'',
        cntr_mstr_catg:'',
        cntr_typ_desc:'',
        ownrshp_strat_code:'',
        ownrshp_catg:'',
        cntr_grp_desc:'',
        cntr_exterior_l:1,
	      cntr_exterior_w:1,
	      cntr_exterior_h:1,
        collapsed_ht:1,
        nest_ratio:1,
        cntr_interior_l:1,
        cntr_interior_w:1,
        cntr_interior_h:1,
        cntr_tare_wt:1,
        cntr_capacity:1,
        cntr_per_layer:1,
        max_layers_shpg_unit:1,
        max_cntr_per_shpg_unit:1,
        cntr_matr_desc:'',
        cntr_drawing_no:'',
        shpg_unit_exterior_l:1,
        shpg_unit_exterior_w:1,
        shpg_unit_exterior_h:1,
        shpg_unit_nstdorflded_ht:1,
        shpg_unit_tare_wt:1,
        shpg_unit_stack_ht:1,
        warehouse_stack_ht:1,
        service_life:'',
        currency_desc:'',
        purchase_cost:1,
        rental_issue_cost:1,
        sup_rntl_dly_cost:1,
        ford_rntl_dly_cost:1,
        return_rntl_cost:1,
        tooling_dev_cost:1,
        extr_cntr_dsgn_img_ind:'',
        intr_cntr_dsgn_img_ind:'',
        shpg_unit_img_ind:'',
        cad_img_ind:'',
        img_notes:'',
        cd_region:'',
        iscontainercolapsble:'',
        cd_plant:'',
        cntr_qt_per_min_su:1,
        nest_ratio_1:1,
        ford_cntct:'',
        cntct_phone:'',
        id_user:'',
        ts_last_update:'',
        req_stat:'',
        components:[{
            mp_function:'',
            no_comp_base:'',
            no_comp_suffix:'',
            ds_comp:'',
            comp_qt_per_min_su:0,
            comp_cd_owner:'',
            in_comp:'',
            comp_cd_type:''
        }],
        UoM:'E',
        typ_no:0,
        dbia_req_no:0
    }
}

compdetailsinit()
{
  this.compdetails=
    {
        cd_cntr:0,
        cntr_suff_ind:'',
        exp_refundable:'Returnable',
        cntr_active_ind:"Yes",
        cntr_base:'',
        cntr_suffix:'',
        cntr_desc:'',
        card_call:'',
        cntr_mstr_catg:'',
        cntr_typ_desc:'',
        ownrshp_strat_code:'',
        ownrshp_catg:'',
        cntr_grp_desc:'',
        cntr_exterior_l:1,
	      cntr_exterior_w:1,
	      cntr_exterior_h:1,
        collapsed_ht:1,
        nest_ratio:1,
        cntr_interior_l:1,
        cntr_interior_w:1,
        cntr_interior_h:1,
        cntr_tare_wt:1,
        cntr_capacity:1,
        cntr_per_layer:1,
        max_layers_shpg_unit:1,
        max_cntr_per_shpg_unit:1,
        cntr_matr_desc:'',
        cntr_drawing_no:'',
        shpg_unit_exterior_l:1,
        shpg_unit_exterior_w:1,
        shpg_unit_exterior_h:1,
        shpg_unit_nstdorflded_ht:1,
        shpg_unit_tare_wt:1,
        shpg_unit_stack_ht:1,
        warehouse_stack_ht:1,
        service_life:'',
        currency_desc:'',
        purchase_cost:1,
        rental_issue_cost:1,
        sup_rntl_dly_cost:1,
        ford_rntl_dly_cost:1,
        return_rntl_cost:1,
        tooling_dev_cost:1,
        extr_cntr_dsgn_img_ind:'',
        intr_cntr_dsgn_img_ind:'',
        shpg_unit_img_ind:'',
        cad_img_ind:'',
        img_notes:'',
        cd_region:'',
        iscontainercolapsble:'',
        cd_plant: '',
        cnt_qt_per_min_su: 1,
        nest_ratio_1: 1,
        ford_cntct: '',
        cntct_phone: '',
        req_stat: '',
        id_user: '',
        ts_last_update: '',
        uom:'E',
        typ_no:0
    }
}



compCmmsFieldsBeforeUpdateInit()
{
  this.compCmmsFieldsBeforeUpdate={
    cntr_base:'',
		cntr_suffix:'',
		cntr_desc:'',
		cntr_typ_desc:'',
		cd_plant:'',
		cntr_tare_wt:0,
		cntr_capacity:0,
		cntr_qt_per_min_su:0,
    ford_cntct:'',
    cntct_phone:'',
		cntr_exterior_l:0,
		cntr_exterior_w:0,
		cntr_exterior_h:0,
		warehouse_stack_ht:0,
    ownrshp_catg:""

  }
}


compCmmsFieldsAfterUpdateInit()
{
  this.compCmmsFieldsAfterUpdate={
    cntr_base:'',
		cntr_suffix:'',
		cntr_desc:'',
		cntr_typ_desc:'',
		cd_plant:'',
		cntr_tare_wt:0,
		cntr_capacity:0,
		cntr_qt_per_min_su:0,
    ford_cntct:'',
    cntct_phone:'',
		cntr_exterior_l:0,
		cntr_exterior_w:0,
		cntr_exterior_h:0,
		warehouse_stack_ht:0,
    ownrshp_catg:""

  }
}




compAdmindirectSaveinit()
{
  this.saveEditComponentCntrAdmin=
    {
        cd_cntr:0,
        cntr_suff_ind:'',
        exp_refundable:'',
        cntr_active_ind:'',
        cntr_base:'',
        cntr_suffix:'',
        cntr_desc:'',
        card_call:'',
        cntr_mstr_catg:'',
        cntr_typ_desc:'',
        ownrshp_strat_code:'',
        ownrshp_catg:'',
        cntr_grp_desc:'',
        cntr_exterior_l:0,
	      cntr_exterior_w:0,
	      cntr_exterior_h:0,
        collapsed_ht:0,
        nest_ratio:0,
        cntr_interior_l:0,
        cntr_interior_w:0,
        cntr_interior_h:0,
        cntr_tare_wt:0,
        cntr_capacity:0,
        cntr_per_layer:0,
        max_layers_shpg_unit:0,
        max_cntr_per_shpg_unit:0,
        cntr_matr_desc:'',
        cntr_drawing_no:'',
        shpg_unit_exterior_l:0,
        shpg_unit_exterior_w:0,
        shpg_unit_exterior_h:0,
        shpg_unit_nstdorflded_ht:0,
        shpg_unit_tare_wt:0,
        shpg_unit_stack_ht:0,
        warehouse_stack_ht:0,
        service_life:'',
        currency_desc:'',
        purchase_cost:0,
        rental_issue_cost:0,
        sup_rntl_dly_cost:0,
        ford_rntl_dly_cost:0,
        return_rntl_cost:0,
        tooling_dev_cost:0,
        extr_cntr_dsgn_img_ind:'',
        intr_cntr_dsgn_img_ind:'',
        shpg_unit_img_ind:'',
        cad_img_ind:'',
        img_notes:'',
        cd_region:'',
        iscontainercolapsble:'',
        cd_plant: '',
        cnt_qt_per_min_su: 0,
        nest_ratio_1: 0,
        ford_cntct: '',
        cntct_phone: '',
        req_stat: '',
        id_user: '',
        ts_last_update: '',
        uom:'E',
        typ_no:0
    }
}



validationCompInit(){



this.compTareWtInValid=false;
this.compTareWtInValidReason=null;
this.compCapWtInValid=false;
this.compCapWtInValidReason=null;
this.compQtPerMinSuInValid=false;
this.compQtPerMinSuInvalidReason=null;
this.compExtLInValid=false;
this.compExtLInValidReason=null;
this.compExtWInValid=false;
this.compExtWInValidReason=null;
this.compExtHInValid=false;
this.compExtHInValidReason=null;
this.wareHourseStackHtCompInvaid=false;
this.wareHourseStackHtCompInvaidReason=null;
this.compBaseInvalid=false;
this.compBaseInvalidReason=null;
this.compDescInvalid=false;
this.compDescInvalidReason=null;
this.plantCodeCompInvalid=false;
this.plantCodeCompInvalidReason=null;
this.mastrCatgCompInvalid=false;
this.mastrCatgCompInvalidReason=null;
this.retTypeCompInvalid=false;
this.retTypeCompInvalidReason=null;
this.compTypeInvalid=false;
this.compTypeInvalidReason=null;
this.compMaterialInvalid=false;
this.compMaterialInvalidReason=null;
this.cosCompInvalid=false;
this.cosCompInvalidReason=null;
this.compGrpInvalid=false;
this.compGrpInvalidReason=null;
this.compCntctInvalid=false;
this.compCntctInvalidReason=null;
this.cntctPhoneCompInvalid=false;
this.cntctPhoneCompInvlidReason=null;
this.compActiveInvalid=false;
this.compActiveInvalidReason=null;
this.compOwnerShpCatgInvalid=false;
this.compOwnerShpCatgInvalidReason=null;

this.compSuffixInvalid=false;
this.compSuffixInvalidReason=null;

this.compSuffixIndInvalid=false;
this.compSuffixIndInvalidReason=null;

}
/* Removing add suffix to container button
cntrsuffixchangedadd()
{

  if(this.cntrdetailsadd.cntr_suff_ind=="No")
  {
    this.cntrSuffixAdd=this.cntrdetailsadd.cntr_suffix;
    this.cntrSuffixenabled=false;
    this.cntrdetailsadd.cntr_suffix="        ";
    console.log(this.cntrSuffixenabled);
  }
  else
  {
    this.cntrdetailsadd.cntr_suffix=this.cntrSuffixAdd;
    console.log("checking",this.cntrSuffixAdd);
    this.cntrSuffixenabled=true;
  }
}

cntrsuffixchangededit()
{

  if(this.cntrdetailsedit.cntr_suff_ind=="No")
  {
    this.cntrSuffixEdit=this.cntrdetailsedit.cntr_suffix;
    this.cntrSuffixEditenabled=false;
    this.cntrdetailsedit.cntr_suffix="        ";
  }
  else
  {
    this.cntrdetailsedit.cntr_suffix=this.cntrSuffixEdit;
    console.log("checking",this.cntrSuffixAdd);
    this.cntrSuffixEditenabled=true;
  }
}
*/


compdetails_display_popup_init()
{
  this.compdetails_display=
  {
      cd_cntr:0,
      cntr_suff_ind:'',
      exp_refundable:'',
      cntr_active_ind:'',
      cntr_base:'',
      cntr_suffix:'',
      cntr_desc:'',
      card_call:'',
      cntr_mstr_catg:'',
      cntr_typ_desc:'',
      ownrshp_strat_code:'',
      ownrshp_catg:'',
      cntr_grp_desc:'',
      cntr_exterior_l:0,
      cntr_exterior_w:0,
      cntr_exterior_h:0,
      collapsed_ht:0.0,
      nest_ratio:0,
      cntr_interior_l:0,
      cntr_interior_w:0,
      cntr_interior_h:0,
      cntr_tare_wt:0,
      cntr_capacity:0,
      cntr_per_layer:0,
      max_layers_shpg_unit:0,
      max_cntr_per_shpg_unit:0,
      cntr_matr_desc:'',
      cntr_drawing_no:'',
      shpg_unit_exterior_l:0,
      shpg_unit_exterior_w:0,
      shpg_unit_exterior_h:0,
      shpg_unit_nstdorflded_ht:0,
      shpg_unit_tare_wt:0,
      shpg_unit_stack_ht:0,
      warehouse_stack_ht:0,
      service_life:'',
      currency_desc:'',
      purchase_cost:0,
      rental_issue_cost:0,
      sup_rntl_dly_cost:0,
      ford_rntl_dly_cost:0,
      return_rntl_cost:0,
      tooling_dev_cost:0,
      extr_cntr_dsgn_img_ind:'',
      intr_cntr_dsgn_img_ind:'',
      shpg_unit_img_ind:'',
      cad_img_ind:'',
      img_notes:'',
      cd_region:'',
      iscontainercolapsble:'',
      cd_plant: '',
      cnt_qt_per_min_su: 0,
      nest_ratio_1: 0,
      ford_cntct: '',
      cntct_phone: '',
      req_stat: '',
      id_user: '',
      ts_last_update: '',
      uom:'E',
      typ_no:0
    }
}
copyCompDetails()
{
  this.select_contcos_dialog_comp=true;

  if(this.componentDetailsCopied)
  {
    this.comp_retype=this.tableSelectedReturnTypeComp;
    for(const i of this.cntrOwnershipstratcodelist)
    {
      if(i.ownrshp_strat_code==this.table_selected_comp_ownrship_strat_code_in_popup.ownrshp_strat_code)
      {
        this.selected_comp_ownrship_strat_code_in_popup=this.table_selected_comp_ownrship_strat_code_in_popup;
      }
    }

  }
  else
  {
    this.comp_retype='Returnable';
    this.selected_comp_ownrship_strat_code_in_popup=this.cntrOwnershipstratcodelist[0];
    this.selected_component=null;
  }

  this.loadComponentsInPopUp();

}

onclick_ok_select_contandcos_comp()
{
  this.select_contcos_dialog_comp=false;

  this.componentDetailsCopied=true;
  this.table_selected_component=this.selected_component;
  this.tableSelectedReturnTypeComp=this.comp_retype;
  this.table_selected_comp_ownrship_strat_code_in_popup=this.selected_comp_ownrship_strat_code_in_popup;
  this.isCmmsLoadedComponent=false;
  this.isCmmsLoadedContainer=false;

  this.transactionService.editbutton_get_contr_details(this.table_selected_component.cntr_base,this.table_selected_component.cd_cntr).subscribe(cntval=>{
    this.compDetailsForCopying=cntval;

    this.compdetailsadd.cntr_base='';
    this.compdetailsadd.cntr_suffix='';
    this.compdetailsadd.cntr_suff_ind='';
    this.compSuffixAdd='';
    this.compSuffixDisabled=true;
    this.selectedSuffixList={suppliergsdbcode:''};
    this.disableCompDetailsWhenBaseOrSuffixChanged=true;
    this.prevCompSuffixInd=null;
    this.enteredCompBase=null;
    this.addcompdetailsinit();

    // this.compdetailsadd.function:'',
    // this.compdetailsadd.cntr_suff_ind=this.compDetailsForCopying.,
    // this.compdetailsadd.exp_refundable=this.compDetailsForCopying.exp_refundable;
    this.compdetailsadd.cntr_active_ind=this.compDetailsForCopying.cntr_active_ind;
    // this.compdetailsadd.cntr_base=this.compDetailsForCopying.,
    // this.compdetailsadd.cntr_suffix=this.compDetailsForCopying.,
    // this.compdetailsadd.cntr_desc=this.compDetailsForCopying.
    this.compdetailsadd.card_call=this.compDetailsForCopying.card_call;
    if(this.compDetailsForCopying.cntr_mstr_catg!=null && this.compDetailsForCopying.cntr_mstr_catg!='')
    {
      for(const mc of this.cntrMasterCategory)
      {
        if(mc.cntr_mstr_catg.toLowerCase()==this.compDetailsForCopying.cntr_mstr_catg.toLowerCase())
        {
          this.compdetailsadd.cntr_mstr_catg=this.compDetailsForCopying.cntr_mstr_catg;
          this.selected_cntr_master_category_comp=mc;
          break;
        }
      }
    }
    this.selected_ownrship_catg_comp=this.ownrship_catg[0];
    // this.compdetailsadd.ownrshp_catg="Supp";

    this.selected_cntr_type_code_comp=this.cntrTypeCodeComp[0];
    this.compdetailsadd.cntr_typ_desc=this.selected_cntr_type_code_comp.cntr_typ_desc;
    this.compdetailsadd.typ_no=this.selected_cntr_type_code_comp.typ_no;

    // this.compdetailsadd.cntr_typ_desc=this.compDetailsForCopying.
    // this.compdetailsadd.ownrshp_strat_code=this.compDetailsForCopying.
    // this.compdetailsadd.ownrshp_catg=this.compDetailsForCopying.
    if(this.compDetailsForCopying.cntr_grp_desc!=null && this.compDetailsForCopying.cntr_grp_desc!='')
    {
      for(const cg of this.cntrGrp)
      {
        if(cg.cntr_grp.toLowerCase()==this.compDetailsForCopying.cntr_grp_desc.toLowerCase())
        {
          this.compdetailsadd.cntr_grp_desc=this.compDetailsForCopying.cntr_grp_desc;
          this.selected_cntr_group_comp=cg;
          break;
        }
      }
    }

    this.compdetailsadd.cntr_exterior_l=this.compDetailsForCopying.cntr_exterior_l
    this.compdetailsadd.cntr_exterior_w=this.compDetailsForCopying.cntr_exterior_w
    this.compdetailsadd.cntr_exterior_h=this.compDetailsForCopying.cntr_exterior_h
    this.compdetailsadd.collapsed_ht=this.compDetailsForCopying.collapsed_ht
    this.compdetailsadd.nest_ratio=this.compDetailsForCopying.nest_ratio
    this.compdetailsadd.cntr_interior_l=this.compDetailsForCopying.cntr_interior_l
    this.compdetailsadd.cntr_interior_w=this.compDetailsForCopying.cntr_interior_w
    this.compdetailsadd.cntr_interior_h=this.compDetailsForCopying.cntr_interior_h
    this.compdetailsadd.cntr_tare_wt=this.compDetailsForCopying.cntr_tare_wt
    this.compdetailsadd.cntr_capacity=this.compDetailsForCopying.cntr_capacity
    this.compdetailsadd.cntr_per_layer=this.compDetailsForCopying.cntr_per_layer
    this.compdetailsadd.max_layers_shpg_unit=this.compDetailsForCopying.max_layers_shpg_unit
    this.compdetailsadd.max_cntr_per_shpg_unit=this.compDetailsForCopying.max_cntr_per_shpg_unit
    // this.compdetailsadd.cntr_matr_desc=this.compDetailsForCopying.
    this.compdetailsadd.cntr_drawing_no=this.compDetailsForCopying.cntr_drawing_no
    this.compdetailsadd.shpg_unit_exterior_l=this.compDetailsForCopying.shpg_unit_exterior_l
    this.compdetailsadd.shpg_unit_exterior_w=this.compDetailsForCopying.shpg_unit_exterior_w
    this.compdetailsadd.shpg_unit_exterior_h=this.compDetailsForCopying.shpg_unit_exterior_h
    this.compdetailsadd.shpg_unit_nstdorflded_ht=this.compDetailsForCopying.shpg_unit_nstdorflded_ht,
    this.compdetailsadd.shpg_unit_tare_wt=this.compDetailsForCopying.shpg_unit_tare_wt
    this.compdetailsadd.shpg_unit_stack_ht=this.compDetailsForCopying.shpg_unit_stack_ht
    this.compdetailsadd.warehouse_stack_ht=this.compDetailsForCopying.warehouse_stack_ht
    this.compdetailsadd.service_life=this.compDetailsForCopying.service_life
    this.compdetailsadd.currency_desc=this.compDetailsForCopying.currency_desc
    this.compdetailsadd.purchase_cost=this.compDetailsForCopying.purchase_cost
    this.compdetailsadd.rental_issue_cost=this.compDetailsForCopying.rental_issue_cost
    this.compdetailsadd.sup_rntl_dly_cost=this.compDetailsForCopying.sup_rntl_dly_cost
    this.compdetailsadd.ford_rntl_dly_cost=this.compDetailsForCopying.ford_rntl_dly_cost
    this.compdetailsadd.return_rntl_cost=this.compDetailsForCopying.return_rntl_cost
    this.compdetailsadd.tooling_dev_cost=this.compDetailsForCopying.tooling_dev_cost
    this.compdetailsadd.extr_cntr_dsgn_img_ind=this.compDetailsForCopying.extr_cntr_dsgn_img_ind
    this.compdetailsadd.intr_cntr_dsgn_img_ind=this.compDetailsForCopying.intr_cntr_dsgn_img_ind
    this.compdetailsadd.shpg_unit_img_ind=this.compDetailsForCopying.shpg_unit_img_ind
    this.compdetailsadd.cad_img_ind=this.compDetailsForCopying.cad_img_ind,
    this.compdetailsadd.img_notes=this.compDetailsForCopying.img_notes
    this.compdetailsadd.cd_region=this.current_region_code_prg;
    this.compdetailsadd.iscontainercolapsble=this.compDetailsForCopying.iscontainercolapsble;
    if(this.compDetailsForCopying.cd_plant!=null && this.compDetailsForCopying.cd_plant!='' )
    {
      for(const pc of this.plantList)
      {
        if(pc.plantGSDBCode.toLowerCase()==this.compDetailsForCopying.cd_plant.toLowerCase())
        {
          this.compdetailsadd.cd_plant=this.compDetailsForCopying.cd_plant;
          this.selectedPlantListComp=pc;
          break;
        }
      }
    }

    this.compdetailsadd.cntr_qt_per_min_su=this.compDetailsForCopying.cnt_qt_per_min_su
    this.compdetailsadd.nest_ratio_1=this.compDetailsForCopying.nest_ratio_1
    this.compdetailsadd.ford_cntct=null;
    // this.compdetailsadd.cntct_phone=this.compDetailsForCopying.
    this.compdetailsadd.id_user=this.transactionService.getcdsid();
    this.compdetailsadd.ts_last_update=this.date.transform(new Date(),'yyyy-MM-dd');
    this.compdetailsadd.req_stat='New';
    this.compdetailsadd.components=[{
      mp_function:'',
      no_comp_base:'',
      no_comp_suffix:'',
      ds_comp:'',
      comp_qt_per_min_su:0,
      comp_cd_owner:'',
      in_comp:'',
      comp_cd_type:''
    }],
    // this.compdetailsadd.UoM='E';
    this.compdetailsadd.dbia_req_no=0;

    console.log('component details from container admin on pop up',this.compDetailsForCopying);

    this.checkCompValidate();

    if(this.compdetailsadd.cntr_base!=null && this.compdetailsadd.cntr_suff_ind!=null && this.compdetailsadd.cntr_base.trim()!='' && this.compdetailsadd.cntr_suff_ind!='')
    {
      this.compsuffixchangedadd();
    }
  })
}

onclick_cancel_select_contandcos_comp()
{
  this.select_contcos_dialog_comp=false;
  this.selected_component=null;
  this.comp_retype='Returnable';
  this.selected_comp_ownrship_strat_code_in_popup=this.cntrOwnershipstratcodelist[0];
}

on_change_cos_dropdown_comp()
{
  this.selected_component=null;
  this.loadComponentsInPopUp();
}

onChangeExpRetComp()
{
  this.selected_component=null;
  this.loadComponentsInPopUp();
}

loadComponentsInPopUp()
{
  console.log(this.componentDetailsCopied)

  let suffix
  this.transactionService.getcomponcoslist(this.selected_comp_ownrship_strat_code_in_popup.ownrshp_strat_code,this.current_region_code_prg,this.comp_retype,'all').subscribe(stdcntrs=>{
    this.contlist_oncos_comp=stdcntrs;

    console.log('successful',this.contlist_oncos_comp);

    if(this.contlist_oncos_comp.length!=0 && this.contlist_oncos_comp!=null)
    {
      this.comp_grid_text='';
      this.comp_present_table=true;
      for(let i=0; i<this.contlist_oncos_comp.length; i++)
      {

        if(this.contlist_oncos_comp[i].cntr_suffix!=null)
        {
        this.contlist_oncos_comp[i].cntr_base_suffix=this.contlist_oncos_comp[i].cntr_base+' - '+this.contlist_oncos_comp[i].cntr_suffix
        }
        else
        {
          this.contlist_oncos_comp[i].cntr_base_suffix=this.contlist_oncos_comp[i].cntr_base
        }

        let length='';
        let width='';
        let height='';
        if(this.contlist_oncos_comp[i].cntr_exterior_l!=null)
        {
          length=this.contlist_oncos_comp[i].cntr_exterior_l.toString();
        }
        if(this.contlist_oncos_comp[i].cntr_exterior_w!=null)
        {
          width=this.contlist_oncos_comp[i].cntr_exterior_w.toString();
        }
        if(this.contlist_oncos_comp[i].cntr_exterior_l!=null)
        {
          height=this.contlist_oncos_comp[i].cntr_exterior_h.toString();
        }

        this.contlist_oncos_comp[i].external_l_w_h=length+'x'+width+'x'+height;

        console.log(this.componentDetailsCopied)

      }

      if(this.componentDetailsCopied && this.table_selected_comp_ownrship_strat_code_in_popup==this.selected_comp_ownrship_strat_code_in_popup && this.tableSelectedReturnTypeComp==this.comp_retype)
      {
        this.selected_component=this.table_selected_component;
      }

      this.comp_cols = [
        { field: 'cntr_base_suffix', header:'Component Base - Suffix'},
        {field:'cntr_desc',header:'Component Description'},
        { field: 'exp_refundable', header:'E/R'},
        { field: 'external_l_w_h', header:'External LxWxH'},
        { field: 'card_call', header:'Card or Call'},
        { field: 'cntr_grp_desc', header:'Component Group ID'},

      ];
    }
    else
    {
      this.comp_present_table=false;
      this.comp_cols=[];
      this.comp_grid_text='No Component Found'
    }

  })
}

on_click_compdetails(compdata:any)
{
  this.enabledetails_comp=true;
  // this.get_measure_cntr_details="lbs/inch";

  console.log(compdata);

  this.transactionService.editbutton_get_contr_details(compdata.cntr_base,compdata.cd_cntr).subscribe(cntval=>{
    this.compdetails_display=cntval;
    if(this.compdetails_display.uom!=null && this.compdetails_display.uom.toLowerCase()=='e')
    {
      this.get_measure_cntr_details='lbs/inch';
    }
    else if(this.compdetails_display.uom!=null && this.compdetails_display.uom.toLowerCase()=='m')
    {
      this.get_measure_cntr_details='kg/cm';
    }
    else
    {
      this.get_measure_cntr_details=this.get_measure;// if value is null assigning region admin value
    }
    console.log('component details from container admin on pop up',this.compdetails_display)
  })


}

/************************** Load from cmms for component *******************************  */
compBaseChanged()
{
  // API call to check whether the container exist in CMMS , if yes make request_status as "c" else make it as 'A'
  // disable all the field until api returns output


  if(this.enteredCompBase!=null)
  {
    if(this.enteredCompBase.trim()!=this.compdetailsadd.cntr_base.trim())
      {
        this.enteredCompBase=this.compdetailsadd.cntr_base;
        if(this.componentDetailsCopied == false)
        {
          this.disableCompDetailsWhenBaseOrSuffixChanged=true;
          // this.clearCompDetailsExceptBaseAndSuffixAndPlantCode();
        }
        if(this.compdetailsadd.cntr_suff_ind.toLowerCase()=='no')
        {
          this.compSuffixAdd='%20%20%20%20%20%20%20%20';
          this.compdetailsadd.cntr_suffix='';
          this.selectedSuffixList={suppliergsdbcode:''}
        }

        if(this.compdetailsadd.cntr_base!=null && this.compdetailsadd.cntr_base.trim()!='' && ((this.compdetailsadd.cntr_suff_ind.toLowerCase()=='yes' && this.compdetailsadd.cntr_suffix != null && this.compdetailsadd.cntr_suffix.trim() != '') || this.compdetailsadd.cntr_suff_ind.toLowerCase()=='no'))
        {
          this.filterByCompBaseAndSuffix()
         // this.cntrsuffixchangedadd()
        }
    }

  }
  else  // This case will be exceuted when user enters base for the first time
  {
    this.enteredCompBase=this.compdetailsadd.cntr_base;
    this.disableCompDetailsWhenBaseOrSuffixChanged=true;
    if(this.componentDetailsCopied == false)
    {
    // this.clearCompDetailsExceptBaseAndSuffixAndPlantCode();
    }
    if(this.compdetailsadd.cntr_suff_ind.toLowerCase()=='no')
    {
      this.compSuffixAdd='%20%20%20%20%20%20%20%20';
      this.compdetailsadd.cntr_suffix='';
      this.selectedSuffixList={suppliergsdbcode:''}
    }

    if(this.compdetailsadd.cntr_base!=null && this.compdetailsadd.cntr_base.trim()!='' && ((this.compdetailsadd.cntr_suff_ind.toLowerCase()=='yes' && this.compdetailsadd.cntr_suffix != null && this.compdetailsadd.cntr_suffix.trim() != '')|| this.compdetailsadd.cntr_suff_ind.toLowerCase()=='no'))
    {
      this.filterByCompBaseAndSuffix()
     // this.cntrsuffixchangedadd()
    }
  }


}

compsuffixIndchangedadd()
{
  if(this.prevCompSuffixInd!=null && this.prevCompSuffixInd!=this.compdetailsadd.cntr_suff_ind)
  {
    if(this.compdetailsadd.cntr_suff_ind=='No')
    {
      console.log('compval')
     this.compSuffixDisabled=true;
     this.compSuffixAdd='%20%20%20%20%20%20%20%20';
     this.compdetailsadd.cntr_suffix='';
     this.selectedSuffixListComp={suppliergsdbcode:''}
      console.log(this.compSuffixDisabled);
      if(this.componentDetailsCopied == false)
      {
        this.disableCompDetailsWhenBaseOrSuffixChanged=true;
        // this.clearCompDetailsExceptBaseAndSuffixAndPlantCode();
      }
    this.prevCompSuffixInd=this.compdetailsadd.cntr_suff_ind;

    if(this.compdetailsadd.cntr_base!=null && this.compdetailsadd.cntr_base.trim()!='')
    {
      console.log('compval')
      this.filterByCompBaseAndSuffix();
    }
    }
    else
    {
      /*
      if(this.userCode!=null && this.userCode!="")
      {
        this.compSuffixAdd=this.userCode;
        this.compdetailsadd.cntr_suffix=this.userCode;
        console.log("checking",this.compdetailsadd.cntr_suffix);
        this.compSuffixEnabled=true;
      }
*/
    if(this.componentDetailsCopied == false)
    {
      this.disableCompDetailsWhenBaseOrSuffixChanged=true;
      // this.clearCompDetailsExceptBaseAndSuffixAndPlantCode();
    }
      this.compSuffixDisabled=false;
      this.prevCompSuffixInd=this.compdetailsadd.cntr_suff_ind;
      console.log('compval')
      if(this.compdetailsadd.cntr_base!=null && this.compdetailsadd.cntr_base.trim()!=''&& this.compdetailsadd.cntr_suffix != null && this.compdetailsadd.cntr_suffix.trim() != '')
      {
        console.log('compval')
        this.prevCompSuffixVal=this.compdetailsadd.cntr_suffix;
        this.compSuffixAdd=this.compdetailsadd.cntr_suffix
        this.filterByCompBaseAndSuffix();
      }
    }



  }
  else if(this.prevCompSuffixInd==null)  // executed when user selects suffix for first time
  {
    if(this.compdetailsadd.cntr_suff_ind=='No')
    {
      console.log('compval')
     this.compSuffixDisabled=true;
      this.compSuffixAdd='%20%20%20%20%20%20%20%20';
      this.compdetailsadd.cntr_suffix='';
      this.selectedSuffixListComp={suppliergsdbcode:''}
      console.log(this.compSuffixDisabled);
      this.disableCompDetailsWhenBaseOrSuffixChanged=true;
      if(this.componentDetailsCopied == false)
      {
      // this.clearCompDetailsExceptBaseAndSuffixAndPlantCode();
      }
      this.prevCompSuffixInd=this.compdetailsadd.cntr_suff_ind;

      if(this.compdetailsadd.cntr_base!=null && this.compdetailsadd.cntr_base.trim()!='')
      {
        console.log('compval')
        this.filterByCompBaseAndSuffix();
      }
    }
    else
    {
      /*
      if(this.userCode!=null && this.userCode!="")
      {
        this.compSuffixAdd=this.userCode;
        this.compdetailsadd.cntr_suffix=this.userCode;
        console.log("checking",this.compdetailsadd.cntr_suffix);
        this.compSuffixEnabled=true;
      }
*/
      this.disableCompDetailsWhenBaseOrSuffixChanged=true;
      if(this.componentDetailsCopied == false)
      {
     // this.clearCompDetailsExceptBaseAndSuffixAndPlantCode();
      }
      this.compSuffixDisabled=false;
      this.prevCompSuffixInd=this.compdetailsadd.cntr_suff_ind;
      console.log('compval')
      if(this.compdetailsadd.cntr_base!=null && this.compdetailsadd.cntr_base.trim()!=''&& this.compdetailsadd.cntr_suffix != null && this.compdetailsadd.cntr_suffix.trim() != '')
      {
        console.log('compval')
        this.prevCompSuffixVal=this.compdetailsadd.cntr_suffix;
        this.compSuffixAdd=this.compdetailsadd.cntr_suffix
        this.filterByCompBaseAndSuffix();
      }
    }

  }
  if(this.compSuffixInvalid)
  {
    this.validateCompSuffixForAdd();
  }

}

compsuffixchangedadd()
{
  this.compdetailsadd.cntr_suffix=this.selectedSuffixListComp.suppliergsdbcode
  console.log(this.compdetailsadd.cntr_suffix)
  if( this.compdetailsadd.cntr_suffix != '' && this.prevCompSuffixVal!=null && this.prevCompSuffixVal.trim().toLowerCase()!=this.compdetailsadd.cntr_suffix.trim().toLowerCase() )
  {
    console.log('suffval')
    if(this.componentDetailsCopied == false)
    {
    this.disableCompDetailsWhenBaseOrSuffixChanged=true;
    // this.clearCompDetailsExceptBaseAndSuffixAndPlantCode();
    }
    this.prevCompSuffixVal=this.compdetailsadd.cntr_suffix;
    this.compSuffixAdd=this.compdetailsadd.cntr_suffix
    if(this.compdetailsadd.cntr_base!=null && this.compdetailsadd.cntr_base.trim()!='')
    {
      this.filterByCompBaseAndSuffix();
    }

  }
  else if( this.compdetailsadd.cntr_suffix != '' && this.prevCompSuffixVal==null)  // executed when user selects suffix for first time
  {
    console.log('suffval')
    this.disableCompDetailsWhenBaseOrSuffixChanged=true;
    if(this.componentDetailsCopied == false)
    {
    // this.clearCompDetailsExceptBaseAndSuffixAndPlantCode();
    }
    this.prevCompSuffixVal=this.compdetailsadd.cntr_suffix;
    this.compSuffixAdd=this.compdetailsadd.cntr_suffix
      if(this.compdetailsadd.cntr_base!=null && this.compdetailsadd.cntr_base.trim()!='')
      {
        this.filterByCompBaseAndSuffix();
      }

    }



}


filterByCompBaseAndSuffix()
{

 /*  this.selectedFromContainerAdmin=false;
  this.clearCntrRequestDetails(); */
  console.log('filterByCompBaseAndSuffix')
  console.log('compActiveInvalid', this.compActiveInvalid)
  this.transactionService.getContainerDetailsFromCMMS(this.compdetailsadd.cntr_base.toUpperCase(),this.compSuffixAdd).subscribe(cntrdetails=>{

    const cntrDetailsFromAPI:cntrDetailsFromCMMS=cntrdetails;
    this.compdetailsfromload=cntrdetails;
    console.log(cntrdetails)

    if(cntrDetailsFromAPI!=null)
    {
      /* Removing this condition because even when the container is copied
      and user goes out of focus container details present in cmms should be loaded
      so emptying the copying container variables if exists

      if(this.componentDetailsCopied == false)
      {*/

      this.clearCompDetailsExceptBaseAndSuffixAndPlantCode();
      this.compdetailsadd.function='C';
      this.isCmmsLoadedContainer=false;
      this.isCmmsLoadedComponent=true;
      this.componentDetailsCopied=false;

      /*code Added */
      this.selected_component=null;
      this.comp_retype='Returnable';
      this.compdetails_display_popup_init();
      this.selected_comp_ownrship_strat_code_in_popup=this.cntrOwnershipstratcodelist[0];
      /*code Added */

      this.compdetailsadd.cntr_base=this.compdetailsfromload.no_cntr_base.trim();
      this.compdetailsadd.cntr_suffix=this.compdetailsfromload.no_cntr_suffix.trim();

      if(this.compdetailsfromload.ds_cntr!=null)
      {
        this.compdetailsadd.cntr_desc=this.compdetailsfromload.ds_cntr.trim();
      }


      this.selected_ownrship_catg_comp=this.ownrship_catg[0];
      // Sending Ownership category "Supp" by default
      // this.compdetailsadd.ownrshp_catg="Supp";
      // this.cntrdetailsadd.ownrshp_catg=this.ownrship_catg[1].ownrshp_catg;


      this.compdetailsadd.cntr_exterior_l=this.compdetailsfromload.ms_cntr_lt;
      this.compdetailsadd.cntr_exterior_w=this.compdetailsfromload.ms_cntr_wd;
      this.compdetailsadd.cntr_exterior_h=this.compdetailsfromload.ms_cntr_ht;

      /*Not used for comp , but values are simply assigned, while requesting changed back to standard values */

      if(this.compdetailsfromload.ms_cntr_clpsd_ht!=null && this.compdetailsfromload.ms_cntr_clpsd_ht>0)
      {
        this.compdetailsadd.iscontainercolapsble='Y';
        this.compdetailsadd.collapsed_ht=this.compdetailsfromload.ms_cntr_clpsd_ht;
      }
      else
      {
        this.compdetailsadd.iscontainercolapsble='N';
      }

      this.compdetailsadd.nest_ratio=this.compdetailsfromload.no_nesting_ratio1,
      this.compdetailsadd.nest_ratio_1=this.compdetailsfromload.no_nesting_ratio2

      if(this.compdetailsfromload.na_ford_cntct!=null && this.compdetailsfromload.na_ford_cntct.trim()!='')
      {
      this.compdetailsadd.ford_cntct=this.compdetailsfromload.na_ford_cntct.trim();
      }

      /*Not used for comp , but values are simply assigned, while requesting changed back to standard values */

      this.compdetailsadd.cntr_tare_wt=this.compdetailsfromload.ms_cntr_tare_wt,
      this.compdetailsadd.cntr_capacity=this.compdetailsfromload.ms_max_cap_wt,
      this.compdetailsadd.cntr_qt_per_min_su=this.compdetailsfromload.qt_per_min_su;
      this.compdetailsadd.warehouse_stack_ht=this.compdetailsfromload.qt_whse_stack_ht;
      this.compdetailsadd.shpg_unit_stack_ht=this.compdetailsfromload.qt_cntr_stack_ht;

      if(this.compdetailsfromload.no_cntct_phone_1!=null && this.compdetailsfromload.no_cntct_phone_1.trim()!='')
      {
      this.compdetailsadd.cntct_phone=this.compdetailsfromload.no_cntct_phone_1.trim();
      }
      if(this.compdetailsfromload.ds_cntr_note!=null && this.compdetailsfromload.ds_cntr_note.trim()!='')
      {
      this.compdetailsadd.img_notes=this.compdetailsfromload.ds_cntr_note.trim();
      }

      // this.selected_comp_type_code=this.cntrTypeCodeComp[0];
      // this.compdetailsadd.cntr_typ_desc=this.selected_comp_type_code.cntr_typ_desc;
    // }
      this.checkCompValidate()
      this.disableCompDetailsWhenBaseOrSuffixChanged=false;

      //  If Data is loaded from the CMMS, and if the measure is in kg/cm or (m) then the data is converted into cm
      if(this.add_measure=='kg/cm')
      {
      if(this.compdetailsadd.collapsed_ht!=null)
      {
          this.compdetailsadd.collapsed_ht=Math.round((2.54*this.compdetailsadd.collapsed_ht)*1000)/1000;
      }
      if(this.compdetailsadd.cntr_interior_l!=null)
      {
          this.compdetailsadd.cntr_interior_l=Math.round((2.54*this.compdetailsadd.cntr_interior_l)*1000)/1000;
      }
      if(this.compdetailsadd.cntr_interior_w!=null)
      {
              this.compdetailsadd.cntr_interior_w=Math.round((2.54*this.compdetailsadd.cntr_interior_w)*1000)/1000;
      }
      if(this.compdetailsadd.cntr_interior_h!=null)
      {
              this.compdetailsadd.cntr_interior_h=Math.round((2.54*this.compdetailsadd.cntr_interior_h)*1000)/1000;
      }
      if(this.compdetailsadd.cntr_tare_wt!=null)
      {
              this.compdetailsadd.cntr_tare_wt=Math.round((0.453592*this.compdetailsadd.cntr_tare_wt)*1000)/1000;
      }
      if(this.compdetailsadd.cntr_capacity!=null)
      {
              this.compdetailsadd.cntr_capacity=Math.round((0.453592*this.compdetailsadd.cntr_capacity)*1000)/1000;
      }

          if(this.compdetailsadd.cntr_exterior_l!=null)
          {
                   this.compdetailsadd.cntr_exterior_l=Math.round((2.54*this.compdetailsadd.cntr_exterior_l)*1000)/1000;
          }
          if(this.compdetailsadd.cntr_exterior_w!=null)
          {
                  this.compdetailsadd.cntr_exterior_w=Math.round((2.54*this.compdetailsadd.cntr_exterior_w)*1000)/1000;
          }
          if(this.compdetailsadd.cntr_exterior_h!=null)
          {
                  this.compdetailsadd.cntr_exterior_h=Math.round((2.54*this.compdetailsadd.cntr_exterior_h)*1000)/1000;
          }
          if(this.compdetailsadd.shpg_unit_exterior_l!=null)
          {
                 this.compdetailsadd.shpg_unit_exterior_l=Math.round((2.54*this.compdetailsadd.shpg_unit_exterior_l)*1000)/1000;
          }
          if(this.compdetailsadd.shpg_unit_exterior_w!=null)
          {
                  this.compdetailsadd.shpg_unit_exterior_w=Math.round((2.54*this.compdetailsadd.shpg_unit_exterior_w)*1000)/1000;
          }
          if(this.compdetailsadd.shpg_unit_exterior_h!=null)
          {
                  this.compdetailsadd.shpg_unit_exterior_h=Math.round((2.54*this.compdetailsadd.shpg_unit_exterior_h)*1000)/1000;
          }
          if(this.compdetailsadd.shpg_unit_nstdorflded_ht!=null)
          {
                  this.compdetailsadd.shpg_unit_nstdorflded_ht=Math.round((2.54*this.compdetailsadd.shpg_unit_nstdorflded_ht)*1000)/1000;
          }
          if(this.compdetailsadd.shpg_unit_tare_wt!=null)
          {
                  this.compdetailsadd.shpg_unit_tare_wt=Math.round((0.453592*this.compdetailsadd.shpg_unit_tare_wt)*1000)/1000;
          }
          /*
          if(this.compdetailsadd.shpg_unit_stack_ht!=null)
          {
                  this.compdetailsadd.shpg_unit_stack_ht=Math.round((2.54*this.compdetailsadd.shpg_unit_stack_ht)*1000)/1000;
          }
          if(this.compdetailsadd.warehouse_stack_ht!=null)
          {
                  this.compdetailsadd.warehouse_stack_ht=Math.round((2.54*this.compdetailsadd.warehouse_stack_ht)*1000)/1000;
          }
          */
        }
    }
    else
    {
      // alert("came here")
      this.isCmmsLoadedContainer=false;
      this.isCmmsLoadedComponent=false;
      if(this.componentDetailsCopied == false)
      {
      this.selected_ownrship_catg_comp=this.ownrship_catg[0];
      }
      // Sending Ownership category "Supp" by default
      // this.compdetailsadd.ownrshp_catg="Supp";
      // this.cntrdetailsadd.ownrshp_catg=this.ownrship_catg[1].ownrshp_catg;

      this.compdetailsadd.function='A';

      this.disableCompDetailsWhenBaseOrSuffixChanged=false;
      this.validationCompInit();
    }
    console.log(this.compdetailsadd)
  })

}

checkCompValidate() // to again check for valiadtion if submit is pressed before sopy container/data load from cmms
{
  if(this.compTareWtInValid )
  {
    this.validateCompTareWeightForAdd()
  }
  if(this.compCapWtInValid )
  {
    this.validateCompCapWtForAdd()
  }
  if(this.compQtPerMinSuInValid )
  {
    this.validateCompQtPerMinSuForAdd()
  }
  if(this.compExtLInValid )
  {
    this.validateCompExtLForAdd()
  }
  if(this.compExtWInValid )
  {
    this.validateCompExtWForAdd()
  }
  if(this.compExtHInValid )
  {
    this.validateCompExtHForAdd()
  }
  if(this.wareHourseStackHtCompInvaid )
  {
    this.validateCompWareHourseStckHtForAdd()
  }
  if(this.compBaseInvalid )
  {
    this.validateCompBaseForAdd()
  }
  if(this.compDescInvalid )
  {
    this.validateCompDescForAdd()
  }
  /* if(this.plantCodeCompInvalid )
  {
    this.validateCompPlantCodeForAdd()
  }  */
  if(this.mastrCatgCompInvalid )
  {
    this.validateCompMastCatgForAdd()
  }
  if(this.retTypeCompInvalid )
  {
    this.validateCompRetTypeForAdd()
  }
  if(this.compTypeInvalid )
  {
    this.validateCompTypeCodeForAdd()
  }
  if(this.compMaterialInvalid )
  {
    this.validateCompMaterialForAdd()
  }
  if(this.cosCompInvalid )
  {
    this.validateCompCOSForAdd()
  }
  if(this.compActiveInvalid)
  {
    this.validateCompActive()
  }
  if(this.compCntctInvalid )
  {
    this.validateCompContact()
  }
  if(this.cntctPhoneCompInvalid )
  {
    this.validateCompContactPhone()
  }
  if(this.compOwnerShpCatgInvalid )
  {
    this.validateCompOwnershpCatgForAdd()
  }
  if(this.compSuffixIndInvalid )
  {
    this.validateCompSuffixIndForAdd()
  }
  if(this.compSuffixInvalid)
  {
    this.validateCompSuffixForAdd()
  }
}


clearCompDetailsExceptBaseAndSuffixAndPlantCode()
{
  // this.selectedPlantListComp={cd_plant:"",plantGSDBCode:"",plant_active_stat:"",plant_name:"",plant_type_name:""};

  this.selected_cntr_master_category_comp={cd_cntr_mstr_catg:0,cntr_mstr_catg_desc:'',updated_by:'',updated_dt:'',cntr_mstr_catg:'',active_stat:'',active_stat_display:false};

  this.selected_cntr_type_code_comp={cd_cntr_typ:'',cntr_typ_desc:'',typ_no:0};

  this.selected_cntr_ownrship_strat_code_comp={cd_region:'',ownrshp_strat_code:'',ownrshp_desc:'',cd_ownrshp_strat:0};

  this.selected_cntr_group_comp={cntr_grp:''};

  this.selected_cntr_material_comp={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
  this.enable_material_dropdown_comp=false;

  this.selected_cntr_currency_list_comp={cd_curr:0,curr_desc:''};

  this.selected_ownrship_catg_comp={ownrshp_catg:'',ownrshp_catg_val:''};




  this.compdetailsadd=
  {
      // cd_cntr:0,
      function:'',
      cntr_suff_ind:this.compdetailsadd.cntr_suff_ind,
      exp_refundable:'',
      cntr_active_ind:"Yes",
      cntr_base:this.compdetailsadd.cntr_base,
      cntr_suffix:this.compdetailsadd.cntr_suffix,
      cntr_desc:'',
      card_call:'',
      cntr_mstr_catg:'',
      cntr_typ_desc:'',
      ownrshp_strat_code:'',
      ownrshp_catg:'',
      cntr_grp_desc:'',
      cntr_exterior_l:0,
      cntr_exterior_w:0,
      cntr_exterior_h:0,
      collapsed_ht:1,
      nest_ratio:1,
      cntr_interior_l:0,
      cntr_interior_w:0,
      cntr_interior_h:0,
      cntr_tare_wt:0,
      cntr_capacity:0,
      cntr_per_layer:0,
      max_layers_shpg_unit:0,
      max_cntr_per_shpg_unit:0,
      cntr_matr_desc:'',
      cntr_drawing_no:'',
      shpg_unit_exterior_l:0,
      shpg_unit_exterior_w:0,
      shpg_unit_exterior_h:0,
      shpg_unit_nstdorflded_ht:0,
      shpg_unit_tare_wt:0,
      shpg_unit_stack_ht:1,
      warehouse_stack_ht:0,
      service_life:'',
      currency_desc:'',
      purchase_cost:0,
      rental_issue_cost:0,
      sup_rntl_dly_cost:0,
      ford_rntl_dly_cost:0,
      return_rntl_cost:0,
      tooling_dev_cost:0,
      extr_cntr_dsgn_img_ind:'No',
      intr_cntr_dsgn_img_ind:'No',
      shpg_unit_img_ind:'No',
      cad_img_ind:'No',
      img_notes:'',
      cd_region:'',
      iscontainercolapsble:'',
      cd_plant:this.compdetailsadd.cd_plant,
      cntr_qt_per_min_su:0,
      nest_ratio_1:1,
      ford_cntct:'',
      cntct_phone:'',
      id_user:'',
      ts_last_update:'',
      req_stat:'',
      components:[{
          mp_function:'',
          no_comp_base:'',
          no_comp_suffix:'',
          ds_comp:'',
          comp_qt_per_min_su:0,
          comp_cd_owner:'',
          in_comp:'',
          comp_cd_type:''
      }],
      UoM:'E',
      typ_no:0,
      dbia_req_no:0
  }
}

/************************** Load from cmms for component *******************************  */
addcompbutton()
{
    this.compAddEnable=false;
    this.compAddDetailsEnable=true;
    this.enable_material_dropdown_comp=false;
    this.addcompdetailsinit();
    // this.loadcntrdetails();
    this.clear_add_comp();
    // this.validationCompInit();

    if(this.unitom=='E')
    {
      this.compdetailsadd.UoM='E';
      this.add_measure='lbs/inch';
    this.prev_add_measure='lbs/inch';
    }
    else{
      this.compdetailsadd.UoM='M';
      this.add_measure='kg/cm';
    this.prev_edit_measure='kg/cm';
    }

    // this.add_measure='lbs/inch';
    // this.prev_add_measure='lbs/inch';
    console.log(this.compdetailsadd);
    this.compdetailsadd.exp_refundable='Returnable';
    this.onChangeRetTypeAddComp();

}




validateCompBaseForAdd()
{
  if(this.compdetailsadd.cntr_base==null || this.compdetailsadd.cntr_base.trim()=='')
  {
    this.compBaseInvalid=true;
    this.compBaseInvalidReason='Required'
  }
  else
  {
    this.compBaseInvalid=false;
    this.compBaseInvalidReason=null
  }
}

validateCompDescForAdd()
{
  if(!this.isCmmsLoadedComponent)
  {
    if(this.compdetailsadd.cntr_desc==null || this.compdetailsadd.cntr_desc.trim()=='')
    {
      this.compDescInvalid=true;
      this.compDescInvalidReason='Required'
    }
    else
    {
      this.compDescInvalid=false;
      this.compDescInvalidReason=null
    }
  }
}

validateCompPlantCodeForAdd()
{
  if(!this.isCmmsLoadedComponent)
  {
    if(this.selectedPlantListComp.plantGSDBCode==null || this.selectedPlantListComp.plantGSDBCode.trim()=='')
    {
      this.plantCodeCompInvalid=true;
      this.plantCodeCompInvalidReason='Required'
    }
    else
    {
      this.plantCodeCompInvalid=false;
      this.plantCodeCompInvalidReason=null
    }
  }

}




validateCompMastCatgForAdd()
{
  if(this.selected_cntr_master_category_comp.cntr_mstr_catg==null || this.selected_cntr_master_category_comp.cntr_mstr_catg.trim()=='')
  {
    this.mastrCatgCompInvalid=true;
    this.mastrCatgCompInvalidReason='Required'
  }
  else
  {
    this.mastrCatgCompInvalid=false;
    this.mastrCatgCompInvalidReason=null
  }
}

validateCompRetTypeForAdd()
{
  if(this.compdetailsadd.exp_refundable==null || this.compdetailsadd.exp_refundable.trim()=='')
  {
    this.retTypeCompInvalid=true;
    this.retTypeCompInvalidReason='Required'
  }
  else
  {
    this.retTypeCompInvalid=false;
    this.retTypeCompInvalidReason=null;
  }
}

validateCompTypeCodeForAdd()
{

  if(this.selected_cntr_type_code_comp.cntr_typ_desc==null || this.selected_cntr_type_code_comp.cntr_typ_desc.trim()=='')
  {
    this.compTypeInvalid=true;
    this.compTypeInvalidReason='Required';
  }
  else
  {
    this.compTypeInvalid=false;
    this.compTypeInvalidReason=null;
  }


}

validateCompMaterialForAdd()
{
  if(this.selected_cntr_material_comp.cntr_matr_name==null || this.selected_cntr_material_comp.cntr_matr_name.trim()=='')
  {
    this.compMaterialInvalid=true;
    this.compMaterialInvalidReason='Required';
  }
  else
  {
    this.compMaterialInvalid=false;
    this.compMaterialInvalidReason=null;
  }
}
validateCompOwnershpCatgForAdd()
{
  if(this.selected_ownrship_catg_comp.ownrshp_catg_val==null || this.selected_ownrship_catg_comp.ownrshp_catg_val.trim()=='')
  {
    this.compOwnerShpCatgInvalid=true;
    this.compOwnerShpCatgInvalidReason='Required';
  }
  else
  {
    this.compOwnerShpCatgInvalid=false;
    this.compOwnerShpCatgInvalidReason=null;
  }
}
validateCompCOSForAdd()
{
  if(this.selected_cntr_ownrship_strat_code_comp.ownrshp_strat_code==null || this.selected_cntr_ownrship_strat_code_comp.ownrshp_strat_code.trim()=='')
  {
    this.cosCompInvalid=true;
    this.cosCompInvalidReason='Required';
  }
  else
  {
    this.cosCompInvalid=false;
    this.cosCompInvalidReason=null;
  }
}


validateCompSuffixIndForAdd()
{
  if(this.compdetailsadd.cntr_suff_ind==null || this.compdetailsadd.cntr_suff_ind.trim()=='')
  {
    this.compSuffixIndInvalid=true;
    this.compSuffixIndInvalidReason='Required'
  }
  else
  {
    this.compSuffixIndInvalid=false;
    this.compSuffixIndInvalidReason=null
  }

}

validateCompSuffixForAdd()
{
  if(this.compdetailsadd.cntr_suff_ind != null && this.compdetailsadd.cntr_suff_ind.trim() != '' && this.compdetailsadd.cntr_suff_ind.toLowerCase() == 'yes' && (this.selectedSuffixListComp == null || this.selectedSuffixListComp.suppliergsdbcode.trim() == ''))
  {
    this.compSuffixInvalid=true;
    this.compSuffixInvalidReason='Required'
  }
  else
  {
    this.compSuffixInvalid=false;
    this.compSuffixInvalidReason=null
  }
}

/*
validateCompGrpForAdd()
{
  if(this.selected_cntr_group_comp.cntr_grp==null || this.selected_cntr_group_comp.cntr_grp.trim()=="")
  {
    this.compGrpInvalid=true;
    this.compGrpInvalidReason="Required";
  }
  else
  {
    this.compGrpInvalid=false;
    this.compGrpInvalidReason=null;
  }
}*/


validateCompContact()
{
  if(!this.isCmmsLoadedComponent)
  {
    if(this.compdetailsadd.ford_cntct==null || this.compdetailsadd.ford_cntct.trim()=='')
    {
      this.compCntctInvalid=true;
      this.compCntctInvalidReason='Required';
    }
    else
    {
      this.compCntctInvalid=false;
      this.compCntctInvalidReason=null;
    }
  }

}

validateCompContactPhone()
{
  if(!this.isCmmsLoadedComponent)
  {
    if(this.compdetailsadd.cntct_phone==null || this.compdetailsadd.cntct_phone.trim()=='')
    {
      this.cntctPhoneCompInvalid=true;
      this.cntctPhoneCompInvlidReason='Required';
    }
    else
    {
      this.cntctPhoneCompInvalid=false;
      this.cntctPhoneCompInvlidReason=null;
    }
  }

}

validateCompActive()
{
  if(this.compdetailsadd.cntr_active_ind==null || this.compdetailsadd.cntr_active_ind.trim()=='')
  {
    this.compActiveInvalid=true;
    this.compActiveInvalidReason='Required';
  }
  else
  {
    this.compActiveInvalid=false;
    this.compActiveInvalidReason=null;
  }
}




validateCompTareWeightForAdd()
{
  if(!this.isCmmsLoadedComponent)
  {
    const comp_tar_wt = String(this.compdetailsadd.cntr_tare_wt).split('.');
    if(this.compdetailsadd.cntr_tare_wt==null || this.compdetailsadd.cntr_tare_wt==0)
    {
      this.compTareWtInValid=true;
      this.compTareWtInValidReason='Required';
    }
    else if(!String(this.compdetailsadd.cntr_tare_wt).includes('.'))
    {
    /*if( String(this.compdetailsadd.cntr_tare_wt).length>10)
        {
          this.compTareWtInValid=true;
          this.compTareWtInValidReason="Max Length Of Characters can be 10";
        }
        else*/
         if(this.compdetailsadd.cntr_tare_wt<=0 || this.compdetailsadd.cntr_tare_wt>99999.999)
        {
          this.compTareWtInValid=true;
          this.compTareWtInValidReason='Should be of range 0 to 99999.999';
        }
      else
        {
          this.compTareWtInValid=false;
          this.compTareWtInValidReason=null;
        }
    }
    else if(String(this.compdetailsadd.cntr_tare_wt).includes('.'))
    {

        if(String(comp_tar_wt[0]).length<0 || String(comp_tar_wt[0]).length>5)
        {
          this.compTareWtInValid=true;
          this.compTareWtInValidReason='Should be of range 0 to 99999.999';
        }
        else if(String(comp_tar_wt[1]).length<0 || String(comp_tar_wt[1]).length>3)
        {
          this.compTareWtInValid=true;
          this.compTareWtInValidReason='Max Length Of decimal values can be 3';
        }
        else
        {
          this.compTareWtInValid=false;
          this.compTareWtInValidReason=null;
      }
    }
    else
    {
    this.compTareWtInValid=false;
    this.compTareWtInValidReason=null;
    }
  }

}
validateCompCapWtForAdd()
{
  if(!this.isCmmsLoadedComponent)
  {
    const cont_cap_wt = String(this.compdetailsadd.cntr_capacity).split('.');
    if(this.compdetailsadd.cntr_capacity==null || this.compdetailsadd.cntr_capacity==0)
    {
      this.compCapWtInValid=true;
      this.compCapWtInValidReason='Required';
    }
    else if(this.compdetailsadd.cntr_capacity<=0 || this.compdetailsadd.cntr_capacity>99999.999)
    {
      this.compCapWtInValid=true;
      this.compCapWtInValidReason='Should be of range 0 to 99999.999';
    }

    else if(String(this.compdetailsadd.cntr_capacity).includes('.'))
    {
      if(cont_cap_wt[1].length > 3)
      {
        this.compCapWtInValid=true;
        this.compCapWtInValidReason='Max Length Of decimal values can be 3';
      }
      else
      {
        this.compCapWtInValid=false;
        this.compCapWtInValidReason=null;
      }
    }
    else
    {
      this.compCapWtInValid=false;
      this.compCapWtInValidReason=null;
    }
  }
}

validateCompQtPerMinSuForAdd()
{
  if(!this.isCmmsLoadedComponent)
  {
    if(this.compdetailsadd.cntr_qt_per_min_su==null || this.compdetailsadd.cntr_qt_per_min_su==0)
    {
      this.compQtPerMinSuInValid=true;
      this.compQtPerMinSuInvalidReason='Required';
    }
    else if(this.compdetailsadd.cntr_qt_per_min_su<1 || this.compdetailsadd.cntr_qt_per_min_su>99 || String(this.compdetailsadd.cntr_qt_per_min_su).includes('.'))
    {
      this.compQtPerMinSuInValid=true;
      this.compQtPerMinSuInvalidReason='Should be of range 1 to 99  and cannot contain Decimal';
    }
    else
    {
      this.compQtPerMinSuInValid=false;
      this.compQtPerMinSuInvalidReason=null;
    }
  }
}

validateCompExtLForAdd()
{
  if(!this.isCmmsLoadedComponent)
  {
    const cont_ext_l = String(this.compdetailsadd.cntr_exterior_l).split('.');
    if(this.compdetailsadd.cntr_exterior_l==null || this.compdetailsadd.cntr_exterior_l==0)
    {
      this.compExtLInValid=true;
      this.compExtLInValidReason='Required';
    }
    else if(!String(this.compdetailsadd.cntr_exterior_l).includes('.'))
    {
    if(this.compdetailsadd.cntr_exterior_l<=0 || this.compdetailsadd.cntr_exterior_l>999.999 )
        {
          this.compExtLInValid=true;
          this.compExtLInValidReason='Should be of range 0 to 999.999';
        }
        /*else if( String(this.compdetailsadd.cntr_exterior_l).length>7)
        {
          this.compExtLInValid=true;
          this.compExtLInValidReason="Max Length Of Characters can be 7";
        }*/
        else
        {
          this.compExtLInValid=false;
          this.compExtLInValidReason=null;
        }
    }
    else if(String(this.compdetailsadd.cntr_exterior_l).includes('.'))
    {
        if(String(cont_ext_l[0]).length<0 || String(cont_ext_l[0]).length>3)
        {
          this.compExtLInValid=true;
          this.compExtLInValidReason='Should be of range 1 to 999.999';
        }
        else if(this.compdetailsadd.cntr_exterior_l<=0 || this.compdetailsadd.cntr_exterior_l>999.999 )
        {
          this.compExtLInValid=true;
          this.compExtLInValidReason='Should be of range 0 to 999.999';
        }
        else if(String(cont_ext_l[1]).length<0 || String(cont_ext_l[1]).length>3)
        {
          this.compExtLInValid=true;
          this.compExtLInValidReason='Max Length Of decimal values can be 3';
        }
        else
        {
          this.compExtLInValid=false;
          this.compExtLInValidReason=null;
      }
    }
    else
    {
    this.compExtLInValid=false;
    this.compExtLInValidReason=null;
    }
  }
}
validateCompExtWForAdd()
{
  if(!this.isCmmsLoadedComponent)
  {
    const cont_ext_w = String(this.compdetailsadd.cntr_exterior_w).split('.');
    if(this.compdetailsadd.cntr_exterior_w==null || this.compdetailsadd.cntr_exterior_w==0)
    {
      this.compExtWInValid=true;
      this.compExtWInValidReason='Required';
    }
    else if(!String(this.compdetailsadd.cntr_exterior_w).includes('.'))
    {
    if(this.compdetailsadd.cntr_exterior_w<=0 || this.compdetailsadd.cntr_exterior_w>999.999 )
        {
          this.compExtWInValid=true;
          this.compExtWInValidReason='Should be of range 0 to 999.999';
        }
        /*else if( String(this.compdetailsadd.cntr_exterior_w).length>7)
        {
          this.compExtWInValid=true;
          this.compExtWInValidReason="Max Length Of Characters can be 7";
        }*/
        else
        {
          this.compExtWInValid=false;
          this.compExtWInValidReason=null;
        }
    }
    else if(String(this.compdetailsadd.cntr_exterior_w).includes('.'))
    {

        if(String(cont_ext_w[0]).length<0 || String(cont_ext_w[0]).length>3)
        {
          this.compExtWInValid=true;
          this.compExtWInValidReason='Should be of range 0 to 999.999';
        }
        else if(this.compdetailsadd.cntr_exterior_w<=0 || this.compdetailsadd.cntr_exterior_w>999.999 )
        {
          this.compExtWInValid=true;
          this.compExtWInValidReason='Should be of range 0 to 999.999';
        }
        else if(String(cont_ext_w[1]).length<0 || String(cont_ext_w[1]).length>3)
        {
          this.compExtWInValid=true;
          this.compExtWInValidReason='Max Length Of decimal values can be 3';
        }
        else
        {
          this.compExtWInValid=false;
          this.compExtWInValidReason=null;
        }
    }
    else
    {
    this.compExtWInValid=false;
    this.compExtWInValidReason=null;
    }
  }
}
validateCompExtHForAdd()
{
  if(!this.isCmmsLoadedComponent)
  {
    const cont_ext_h = String(this.compdetailsadd.cntr_exterior_h).split('.');
  if(this.compdetailsadd.cntr_exterior_h==null || this.compdetailsadd.cntr_exterior_h==0)
    {
      this.compExtHInValid=true;
      this.compExtHInValidReason='Required';
    }
    else if(!String(this.compdetailsadd.cntr_exterior_h).includes('.'))
    {
    if(this.compdetailsadd.cntr_exterior_h<=0 || this.compdetailsadd.cntr_exterior_h>999.999 )
        {
          this.compExtHInValid=true;
          this.compExtHInValidReason='Should be of range 0 to 999.999';
        }
       /* else if( String(this.compdetailsadd.cntr_exterior_h).length>7)
        {
          this.compExtHInValid=true;
          this.compExtHInValidReason="Max Length Of Characters can be 7";
        }*/
        else
        {
          this.compExtHInValid=false;
          this.compExtHInValidReason=null;
          console.log('Split First else')
        }
    }
    else if(String(this.compdetailsadd.cntr_exterior_h).includes('.'))
    {
        if(String(cont_ext_h[0]).length<0 || String(cont_ext_h[0]).length>3)
        {
          this.compExtHInValid=true;
          this.compExtHInValidReason='Should be of range 0 to 999.999';
        }
        else if(this.compdetailsadd.cntr_exterior_h<=0 || this.compdetailsadd.cntr_exterior_h>999.999 )
        {
          this.compExtHInValid=true;
          this.compExtHInValidReason='Should be of range 0 to 999.999';
        }
        else if(String(cont_ext_h[1]).length<0 || String(cont_ext_h[1]).length>3)
        {
          this.compExtHInValid=true;
          this.compExtHInValidReason='Max Length Of decimal values can be 3';
        }
        else
        {
          this.compExtHInValid=false;
          this.compExtHInValidReason=null;
      }
    }
    else
    {
    this.compExtHInValid=false;
    this.compExtHInValidReason=null;
    }
  }
}



validateCompWareHourseStckHtForAdd()
{
  if(!this.isCmmsLoadedComponent)
  {
    if(this.compdetailsadd.warehouse_stack_ht==null || this.compdetailsadd.warehouse_stack_ht==0)
    {
      this.wareHourseStackHtCompInvaid=true;
      this.wareHourseStackHtCompInvaidReason='Required';
    }
    else if(this.compdetailsadd.warehouse_stack_ht<1 || this.compdetailsadd.warehouse_stack_ht>99 || String(this.compdetailsadd.warehouse_stack_ht).includes('.'))
    {
      this.wareHourseStackHtCompInvaid=true;
      this.wareHourseStackHtCompInvaidReason='Should be of range 1 to 99 and cannot contain Decimal';
    }
    else
    {
      this.wareHourseStackHtCompInvaid=false;
      this.wareHourseStackHtCompInvaidReason=null;
    }
  }
}




saveaddcomp()
{
    this.compdetailsadd.cd_region=this.current_region_code_prg;
    // this.compdetailsadd.function='A';
    this.compdetailsadd.extr_cntr_dsgn_img_ind='No';
    this.compdetailsadd.intr_cntr_dsgn_img_ind='No';
    this.compdetailsadd.shpg_unit_img_ind='No';
    this.compdetailsadd.cad_img_ind='No';
    this.compdetailsadd.nest_ratio=1;
    this.compdetailsadd.nest_ratio_1=1;
    this.compdetailsadd.collapsed_ht=1;
    this.compdetailsadd.shpg_unit_stack_ht=1;

    if(this.unitom=='E')
    {
      this.compdetailsadd.UoM='E';
      this.add_measure='lbs/inch';
    this.prev_add_measure='lbs/inch';
    }
    else{
      this.compdetailsadd.UoM='M';
      this.add_measure='kg/cm';
    this.prev_add_measure='kg/cm';
    }

/*
    if(this.compdetailsadd.iscontainercolapsble!=null && this.compdetailsadd.iscontainercolapsble.toLowerCase()=='n')
    {
      this.compdetailsadd.collapsed_ht=0;
    }*/

    this.compdetailsadd.cd_plant=this.selectedPlantListComp.plantGSDBCode.trim();
    this.compdetailsadd.cntr_grp_desc='';
    this.compdetailsadd.cntr_matr_desc=this.selected_cntr_material_comp.cntr_matr_name;
    this.compdetailsadd.cntr_mstr_catg=this.selected_cntr_master_category_comp.cntr_mstr_catg;
    this.compdetailsadd.cntr_typ_desc=String(this.selected_cntr_type_code_comp.cd_cntr_typ);
    this.compdetailsadd.typ_no=this.selected_cntr_type_code_comp.typ_no;
    this.compdetailsadd.currency_desc=this.selected_cntr_currency_list_comp.curr_desc;
    // this.compdetailsadd.ownrshp_catg="Ford"
    this.compdetailsadd.ownrshp_catg=this.selected_ownrship_catg_comp.ownrshp_catg_val;
    this.compdetailsadd.ownrshp_strat_code=this.selected_cntr_ownrship_strat_code_comp.ownrshp_strat_code;


    this.compdetailsadd.dbia_req_no=0;

    this.compdetailsadd.ts_last_update=this.date.transform(new Date(),'yyyy-MM-dd');
    this.compdetailsadd.req_stat='New';
    this.compdetailsadd.id_user=this.transactionService.getcdsid();

    if(this.compdetailsadd.cntr_desc != null)
    {
    this.compdetailsadd.cntr_desc=this.compdetailsadd.cntr_desc.trim();
    }
    if(this.compdetailsadd.img_notes != null)
    {
      this.compdetailsadd.img_notes=this.compdetailsadd.img_notes.trim();
    }

    if(this.compdetailsadd.ford_cntct != null)
    {
      this.compdetailsadd.ford_cntct=this.compdetailsadd.ford_cntct.trim();
    }
    if(this.compdetailsadd.cntct_phone != null)
    {
      this.compdetailsadd.cntct_phone=this.compdetailsadd.cntct_phone.trim();
    }




    if(this.compdetailsadd.cntr_drawing_no == null )
    {
      this.compdetailsadd.cntr_drawing_no=''
    }

    if(this.compdetailsadd.service_life == null)
    {
      this.compdetailsadd.service_life='';
    }

    this.compdetailsadd.cntr_suffix=this.selectedSuffixListComp.suppliergsdbcode;
    /*if(this.cntrdetailsadd.cntr_suffix.length>1)
    {
        this.cntrdetailsadd.cntr_suff_ind="Yes";
    }
    else
    {
        this.cntrdetailsadd.cntr_suff_ind="No";
    }
*/




  // else
  // {
  //   this.compdetailsadd.UoM="E"
  // }



  this.validateCompBaseForAdd()
this.validateCompDescForAdd()
// this.validateCompPlantCodeForAdd()
this.validateCompMastCatgForAdd()
this.validateCompRetTypeForAdd()
this.validateCompTypeCodeForAdd()
this.validateCompMaterialForAdd()
this.validateCompCOSForAdd()
this.validateCompOwnershpCatgForAdd()
// this.validateCompGrpForAdd()
this.validateCompContact()
this.validateCompContactPhone()
this.validateCompActive()
this.validateCompTareWeightForAdd()
this.validateCompCapWtForAdd()
this.validateCompQtPerMinSuForAdd()
this.validateCompExtLForAdd()
this.validateCompExtWForAdd()
this.validateCompExtHForAdd()
this.validateCompWareHourseStckHtForAdd()
this.validateCompSuffixIndForAdd()
this.validateCompSuffixForAdd()

  if(
    !this.compTareWtInValid && !this.compCapWtInValid &&
   !this.compQtPerMinSuInValid &&
   !this.compExtLInValid && !this.compExtWInValid && !this.compExtHInValid &&
   !this.wareHourseStackHtCompInvaid &&
   !this.compBaseInvalid && !this.compDescInvalid && !this.plantCodeCompInvalid &&
   !this.mastrCatgCompInvalid && !this.retTypeCompInvalid && !this.compTypeInvalid &&
  !this.compMaterialInvalid && !this.cosCompInvalid && !this.compActiveInvalid && !this.compCntctInvalid && !this.cntctPhoneCompInvalid && !this.compOwnerShpCatgInvalid && !this.compSuffixIndInvalid && !this.compSuffixInvalid )
  {
    console.log('details before adding',this.compdetailsadd);
    let suffix=''
    if(this.compdetailsadd.cntr_suff_ind != null && this.compdetailsadd.cntr_suff_ind.toLowerCase() == 'no')
    {
      suffix='%20%20%20'
    }
    else
    {
      suffix=this.compdetailsadd.cntr_suffix
    }

    if(this.isCmmsLoadedComponent)
    {
      console.log('component add - direct save ')
      console.log('compdetailsadd',this.compdetailsadd)



      if(this.compdetailsadd != null)
      {
        // service to call api to push data
        this.saveAddComponentDirectToCntrAdmin.cd_cntr=0;
        this.saveAddComponentDirectToCntrAdmin.cntr_base=this.compdetailsadd.cntr_base;
        this.saveAddComponentDirectToCntrAdmin.cntr_suff_ind= this.compdetailsadd.cntr_suff_ind;
        this.saveAddComponentDirectToCntrAdmin.exp_refundable=this.compdetailsadd.exp_refundable;
        this.saveAddComponentDirectToCntrAdmin.cntr_active_ind=this.compdetailsadd.cntr_active_ind
        this.saveAddComponentDirectToCntrAdmin.cntr_suffix=this.compdetailsadd.cntr_suffix;
        this.saveAddComponentDirectToCntrAdmin.cntr_desc=this.compdetailsadd.cntr_desc;
        this.saveAddComponentDirectToCntrAdmin.card_call=this.compdetailsadd.card_call
        this.saveAddComponentDirectToCntrAdmin.cntr_mstr_catg=this.compdetailsadd.cntr_mstr_catg;
        this.saveAddComponentDirectToCntrAdmin.cntr_typ_desc=this.compdetailsadd.cntr_typ_desc;
        this.saveAddComponentDirectToCntrAdmin.ownrshp_strat_code=this.compdetailsadd.ownrshp_strat_code;
        this.saveAddComponentDirectToCntrAdmin.ownrshp_catg=this.compdetailsadd.ownrshp_catg;
        this.saveAddComponentDirectToCntrAdmin.cntr_grp_desc=this.compdetailsadd.cntr_grp_desc;
        this.saveAddComponentDirectToCntrAdmin.cntr_matr_desc=this.compdetailsadd.cntr_matr_desc;
        this.saveAddComponentDirectToCntrAdmin.currency_desc=this.compdetailsadd.currency_desc;
        this.saveAddComponentDirectToCntrAdmin.iscontainercolapsble=this.compdetailsadd.iscontainercolapsble;
        this.saveAddComponentDirectToCntrAdmin.cntr_exterior_l=this.compdetailsadd.cntr_exterior_l;
        this.saveAddComponentDirectToCntrAdmin.cntr_exterior_w=this.compdetailsadd.cntr_exterior_w;
        this.saveAddComponentDirectToCntrAdmin.cntr_exterior_h=this.compdetailsadd.cntr_exterior_h;
        this.saveAddComponentDirectToCntrAdmin.collapsed_ht=this.compdetailsadd.collapsed_ht;
        this.saveAddComponentDirectToCntrAdmin.nest_ratio=this.compdetailsadd.nest_ratio;
        this.saveAddComponentDirectToCntrAdmin.cntr_interior_l=this.compdetailsadd.cntr_interior_l;
        this.saveAddComponentDirectToCntrAdmin.cntr_interior_w=this.compdetailsadd.cntr_interior_w;
        this.saveAddComponentDirectToCntrAdmin.cntr_interior_h=this.compdetailsadd.cntr_interior_h;
        this.saveAddComponentDirectToCntrAdmin.cntr_tare_wt=this.compdetailsadd.cntr_tare_wt;
        this.saveAddComponentDirectToCntrAdmin.cntr_capacity=this.compdetailsadd.cntr_capacity;
        this.saveAddComponentDirectToCntrAdmin.cntr_per_layer=this.compdetailsadd.cntr_per_layer;
        this.saveAddComponentDirectToCntrAdmin.max_layers_shpg_unit=this.compdetailsadd.max_layers_shpg_unit;
        this.saveAddComponentDirectToCntrAdmin.max_cntr_per_shpg_unit=this.compdetailsadd.max_cntr_per_shpg_unit;
        this.saveAddComponentDirectToCntrAdmin.cntr_drawing_no=this.compdetailsadd.cntr_drawing_no;
        this.saveAddComponentDirectToCntrAdmin.shpg_unit_exterior_l=this.compdetailsadd.shpg_unit_exterior_l;
        this.saveAddComponentDirectToCntrAdmin.shpg_unit_exterior_w=this.compdetailsadd.shpg_unit_exterior_w;
        this.saveAddComponentDirectToCntrAdmin.shpg_unit_exterior_h=this.compdetailsadd.shpg_unit_exterior_h;
        this.saveAddComponentDirectToCntrAdmin.shpg_unit_nstdorflded_ht=this.compdetailsadd.shpg_unit_nstdorflded_ht;
        this.saveAddComponentDirectToCntrAdmin.shpg_unit_tare_wt=this.compdetailsadd.shpg_unit_tare_wt;
        this.saveAddComponentDirectToCntrAdmin.shpg_unit_stack_ht=this.compdetailsadd.shpg_unit_stack_ht;
        this.saveAddComponentDirectToCntrAdmin.warehouse_stack_ht=this.compdetailsadd.warehouse_stack_ht;
        this.saveAddComponentDirectToCntrAdmin.service_life=this.compdetailsadd.service_life;
        this.saveAddComponentDirectToCntrAdmin.purchase_cost=this.compdetailsadd.purchase_cost;
        this.saveAddComponentDirectToCntrAdmin.rental_issue_cost=this.compdetailsadd.rental_issue_cost;
        this.saveAddComponentDirectToCntrAdmin.sup_rntl_dly_cost=this.compdetailsadd.sup_rntl_dly_cost;
        this.saveAddComponentDirectToCntrAdmin.ford_rntl_dly_cost=this.compdetailsadd.ford_rntl_dly_cost;
        this.saveAddComponentDirectToCntrAdmin.return_rntl_cost=this.compdetailsadd.return_rntl_cost;
        this.saveAddComponentDirectToCntrAdmin.tooling_dev_cost=this.compdetailsadd.tooling_dev_cost;
        this.saveAddComponentDirectToCntrAdmin.extr_cntr_dsgn_img_ind=this.compdetailsadd.extr_cntr_dsgn_img_ind;
        this.saveAddComponentDirectToCntrAdmin.intr_cntr_dsgn_img_ind=this.compdetailsadd.intr_cntr_dsgn_img_ind;
        this.saveAddComponentDirectToCntrAdmin.shpg_unit_img_ind=this.compdetailsadd.shpg_unit_img_ind
        this.saveAddComponentDirectToCntrAdmin.cad_img_ind=this.compdetailsadd.cad_img_ind;
        this.saveAddComponentDirectToCntrAdmin.img_notes=this.compdetailsadd.img_notes;
        this.saveAddComponentDirectToCntrAdmin.cd_region=this.compdetailsadd.cd_region;
        this.saveAddComponentDirectToCntrAdmin.cd_plant=this.compdetailsadd.cd_plant;
        this.saveAddComponentDirectToCntrAdmin.cnt_qt_per_min_su=this.compdetailsadd.cntr_qt_per_min_su;
        this.saveAddComponentDirectToCntrAdmin.nest_ratio_1=this.compdetailsadd.nest_ratio_1;
        this.saveAddComponentDirectToCntrAdmin.ford_cntct=this.compdetailsadd.ford_cntct;
        this.saveAddComponentDirectToCntrAdmin.cntct_phone=this.compdetailsadd.cntct_phone;
        this.saveAddComponentDirectToCntrAdmin.uom=this.compdetailsadd.UoM;
        this.saveAddComponentDirectToCntrAdmin.id_user= this.compdetailsadd.id_user
        this.saveAddComponentDirectToCntrAdmin.req_stat=this.compdetailsadd.req_stat;
        this.saveAddComponentDirectToCntrAdmin.ts_last_update=this.compdetailsadd.ts_last_update;

        this.saveAddComponentDirectToCntrAdmin.typ_no=this.compdetailsadd.typ_no;



        console.log('save direct to component admin post data', this.saveAddComponentDirectToCntrAdmin)

        const saveAddComponentDirectToCntrAdminArray=[];
        saveAddComponentDirectToCntrAdminArray.push(this.saveAddComponentDirectToCntrAdmin);
        // service call api
        const base=saveAddComponentDirectToCntrAdminArray[0].cntr_base;
        let suffix='-';
        if(saveAddComponentDirectToCntrAdminArray!=null && saveAddComponentDirectToCntrAdminArray[0].cntr_suff!=null && saveAddComponentDirectToCntrAdminArray[0].cntr_suff.trim()!='')
        {
          suffix=saveAddComponentDirectToCntrAdminArray[0].cntr_suff
        }
        this.transactionService.checkWhetherCntrOrCompInNgpp(base,suffix,saveAddComponentDirectToCntrAdminArray[0].ownrshp_strat_code,saveAddComponentDirectToCntrAdminArray[0].cd_region).subscribe(count=>{
          console.log(count,typeof(count));
          if(count==0)
          {
            this.enableCompExistInNgppNotification=false;
            this.transactionService.cntrAdminSaveCntrDetails(saveAddComponentDirectToCntrAdminArray).subscribe(comp_save_res=>{
            console.log('direct comp admin save add',comp_save_res)
            if(comp_save_res.toLowerCase() == 'success')
            {
              this.messageService.add({severity:'success', summary:'Success', detail:'Component Added Successfully'});
              this.DisplayGridComp(this.current_region_code_prg);
              this.addcompdetailsinit();
              // this.loadcompdetails();
              this.clear_add_comp();
              this.compdetails_display_popup_init();
            }


            else
            {
                this.messageService.add({severity:'error', summary: 'Error', detail: 'Component not Added,Please Try Again'});
                // this.loadcntrdetails();
                this.validationCompInit();
            }
           })
          }
          else
          {
            this.enableCompExistInNgppNotification=true;
          }

        });

      }
    }
    else
    {

      this.transactionService.getRequestStatusOfContainerByDesc(this.compdetailsadd.cntr_base,suffix,this.compdetailsadd.ownrshp_strat_code,this.compdetailsadd.cd_region, this.compdetailsadd.cntr_typ_desc).subscribe(requestStatus=>{
      console.log("Request Status",requestStatus);
      var requestStatusReceived=requestStatus;

      if(requestStatusReceived!= null && requestStatusReceived != '' && requestStatusReceived.toLowerCase() =='pending')
      {
        this.enableProcessingNotifiacationDialogAddComp=true;
      }
      else
      {

        if(this.compdetailsadd.function != null && this.compdetailsadd.function.trim() != '' && this.compdetailsadd.function.toLowerCase() == 'c')
        {

          this.enableFunctionChangeNotifiacationDialogComp=true;

        }

        else if(this.compdetailsadd.function != null && this.compdetailsadd.function.trim() != '' && this.compdetailsadd.function.toLowerCase() == 'a')
        {
          this.enableFunctionAddNotifiacationDialogComp=true;
          this.warningMsgForComp = this.compdetailsadd.cntr_base + "--" + suffix + "Component details change/update request submitted by other user and is in progress, please try after some time...";
        }

      }
      })
    }
  }

}

okCompExistInNGPPNotification()
{
  this.enableCompExistInNgppNotification=false;
}

ok_notification_processing_change_component_details()
{
  this.enableFunctionChangeNotifiacationDialogComp=false;
  this.ok_comp_save_add();
}
no_changecomp_details()
{
  this.enableFunctionChangeNotifiacationDialogComp=false;
}
ok_notification_processing_add_component_details()
{
  this.enableFunctionAddNotifiacationDialogComp=false;
  this.ok_comp_save_add();
}
no_addcomp_details()
{
  this.enableFunctionAddNotifiacationDialogComp=false;
}
ok_notification_processing_details()
{
  this.enableProcessingNotifiacationDialogAddComp=false;
}
cancel_processing_comp_details()
{
  this.enableProcessingNotifiacationDialogAddComp=false;
}


ok_comp_save_add()
{
  this.transactionService.savecntrdetails(this.compdetailsadd).subscribe(savecntr=>
    {
        const res=savecntr;
        console.log('successful',res);
        if(res.toLowerCase() == 'success')
        {
          this.messageService.add({severity:'success', summary:'Success', detail:'Component Added Successfully'});
          // this.getplantlist();
          this.DisplayGridComp(this.current_region_code_prg);
          this.addcompdetailsinit();
          this.compAddEnable=true;
          this.clear_add_comp();
          this.compAddDetailsEnable=false;
          this.validationCompInit();
          // this.loadcntrdetails();
        }
        else
        {
            this.messageService.add({severity:'error', summary: 'Error', detail: 'Component Not Added,Please Try Again'});
            // this.addplantdetailsinit();
            // this.loadcntrdetails();
            this.validationCompInit();
          /*  if(this.add_measure=='kg/cm')
            {
            if(this.compdetailsadd.collapsed_ht!=null)
            {
                this.compdetailsadd.collapsed_ht=Math.round((2.54*this.compdetailsadd.collapsed_ht)*1000)/1000;
            }
            if(this.compdetailsadd.cntr_interior_l!=null)
            {
                this.compdetailsadd.cntr_interior_l=Math.round((2.54*this.compdetailsadd.cntr_interior_l)*1000)/1000;
            }
            if(this.compdetailsadd.cntr_interior_w!=null)
            {
                    this.compdetailsadd.cntr_interior_w=Math.round((2.54*this.compdetailsadd.cntr_interior_w)*1000)/1000;
            }
            if(this.compdetailsadd.cntr_interior_h!=null)
            {
                    this.compdetailsadd.cntr_interior_h=Math.round((2.54*this.compdetailsadd.cntr_interior_h)*1000)/1000;
            }
            if(this.compdetailsadd.cntr_tare_wt!=null)
            {
                    this.compdetailsadd.cntr_tare_wt=Math.round((0.453592*this.compdetailsadd.cntr_tare_wt)*1000)/1000;
            }
            if(this.compdetailsadd.cntr_capacity!=null)
            {
                    this.compdetailsadd.cntr_capacity=Math.round((2.54*2.54*2.54*this.compdetailsadd.cntr_capacity)*1000)/1000;
            }

                if(this.compdetailsadd.cntr_exterior_l!=null)
                {
                         this.compdetailsadd.cntr_exterior_l=Math.round((2.54*this.compdetailsadd.cntr_exterior_l)*1000)/1000;
                }
                if(this.compdetailsadd.cntr_exterior_w!=null)
                {
                        this.compdetailsadd.cntr_exterior_w=Math.round((2.54*this.compdetailsadd.cntr_exterior_w)*1000)/1000;
                }
                if(this.compdetailsadd.cntr_exterior_h!=null)
                {
                        this.compdetailsadd.cntr_exterior_h=Math.round((2.54*this.compdetailsadd.cntr_exterior_h)*1000)/1000;
                }
                if(this.compdetailsadd.shpg_unit_exterior_l!=null)
                {
                       this.compdetailsadd.shpg_unit_exterior_l=Math.round((2.54*this.compdetailsadd.shpg_unit_exterior_l)*1000)/1000;
                }
                if(this.compdetailsadd.shpg_unit_exterior_w!=null)
                {
                        this.compdetailsadd.shpg_unit_exterior_w=Math.round((2.54*this.compdetailsadd.shpg_unit_exterior_w)*1000)/1000;
                }
                if(this.compdetailsadd.shpg_unit_exterior_h!=null)
                {
                        this.compdetailsadd.shpg_unit_exterior_h=Math.round((2.54*this.compdetailsadd.shpg_unit_exterior_h)*1000)/1000;
                }
                if(this.compdetailsadd.shpg_unit_nstdorflded_ht!=null)
                {
                        this.compdetailsadd.shpg_unit_nstdorflded_ht=Math.round((2.54*this.compdetailsadd.shpg_unit_nstdorflded_ht)*1000)/1000;
                }
                if(this.compdetailsadd.shpg_unit_tare_wt!=null)
                {
                        this.compdetailsadd.shpg_unit_tare_wt=Math.round((0.453592*this.compdetailsadd.shpg_unit_tare_wt)*1000)/1000;
                }
                if(this.compdetailsadd.shpg_unit_stack_ht!=null)
                {
                        this.compdetailsadd.shpg_unit_stack_ht=Math.round((2.54*this.compdetailsadd.shpg_unit_stack_ht)*1000)/1000;
                }
                if(this.compdetailsadd.warehouse_stack_ht!=null)
                {
                        this.compdetailsadd.warehouse_stack_ht=Math.round((2.54*this.compdetailsadd.warehouse_stack_ht)*1000)/1000;
                }
              }*/
            }

    }
)
}

cancel_add_comp()
{
    this.compAddDetailsEnable=false;
    this.compAddEnable=true;
    this.addcompdetailsinit();
    // this.validationCompInit();
    this.clear_add_comp();
    console.log('selectedCntrFromAutoCmplete while giving cancel',this.selectedCntrFromAutoCmplete);
    // this.loadcntrdetails();
}
/************************** Load from cmms for component *******************************  */
clear_add_comp()
  {
    this.compdetailsadd.cntr_base='';
    this.compdetailsadd.cntr_suffix='';
    this.selectedSuffixListComp={suppliergsdbcode:''}
    this.compdetailsadd.cntr_suff_ind='';
    this.cntrSuffixAdd='';
    this.compSuffixDisabled=true;
    this.disableCompDetailsWhenBaseOrSuffixChanged=true;
    this.prevCompSuffixInd=null;
    this.enteredCompBase=null;
    this.prevCompSuffixVal=null

    this.selected_component=null;
    this.table_selected_component=null;

    this.comp_retype='Returnable';
    this.tableSelectedReturnTypeComp=null;

    this.componentDetailsCopied=false;
    this.isCmmsLoadedContainer=false;
    this.isCmmsLoadedComponent=false;

    this.selected_comp_ownrship_strat_code_in_popup=this.cntrOwnershipstratcodelist[0];
    this.table_selected_comp_ownrship_strat_code_in_popup=null;

    this.selectedPlantListComp={cd_plant:'',plantGSDBCode:'',plant_active_stat:'',plant_name:'',plant_type_name:''};
    this.cntrdetailsadd.cd_plant='';

    this.clearCompDetailsExceptBaseAndSuffixAndPlantCode();
    this.validationCompInit();
  }

/************************** Load from cmms for component *******************************  */
onChangeRetTypeAddComp()
{
let ret_type='';

           this.selected_cntr_material_comp={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};

if(this.compdetailsadd.exp_refundable != '')
{
  if(this.selected_cntr_type_code_comp != null && this.selected_cntr_type_code_comp.cntr_typ_desc != '')
  {

    if(this.compdetailsadd.exp_refundable.toLowerCase() =='returnable')
    {
      ret_type= 'R';

    }
    else
    {
      ret_type= 'E';
    }

    // api to get material list


    this.transactionService.getContainerMaterial(this.selected_cntr_type_code_comp.typ_no,ret_type).subscribe(mat_list=>{

      this.cntrMaterial=[];
      this.cntrMaterial=mat_list;

    })
    this.enable_material_dropdown_comp=true;
  }
}



}

// material api changes

onChangeCntrTypAddComp()
{
  let ret_type='';
  console.log(this.selected_cntr_type_code_comp,this.compdetailsadd.exp_refundable)
  this.selected_cntr_material_comp={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
  if(this.selected_cntr_type_code_comp != null && this.selected_cntr_type_code_comp.cntr_typ_desc != '')
  {
    if(this.compdetailsadd.exp_refundable != '')
    {


    if(this.compdetailsadd.exp_refundable.toLowerCase() == 'returnable')
    {
      ret_type= 'R';

    }
    else
    {
      ret_type= 'E';
    }


    this.transactionService.getContainerMaterial(this.selected_cntr_type_code_comp.typ_no,ret_type).subscribe(mat_list=>{

      this.cntrMaterial=[];
      this.cntrMaterial=mat_list;

    })


    // api to get material list
    this.enable_material_dropdown_comp=true;
  }
}


}



// material api changes

getMaterialValueToDisplayComp()
{

let get_ret_type=''
  if(this.get_selected_cntr_type_code_comp != null && this.get_selected_cntr_type_code_comp.cntr_typ_desc != '')
  {
    if(this.compdetails.exp_refundable != '')
    {

    if(this.compdetails.exp_refundable.toLowerCase() =='returnable')
    {
      get_ret_type= 'R';

    }
    else
    {
      get_ret_type= 'E';
    }

     this.edit_selected_cntr_material_comp={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
    // api to get material list


    this.transactionService.getContainerMaterial(this.get_selected_cntr_type_code_comp.typ_no,get_ret_type).subscribe(mat_list=>{

      this.cntrMaterial=[];
      this.cntrMaterial=mat_list;


    if(this.cntrMaterial!= null && this.cntrMaterial.length!=0 && this.compdetails != null && this.compdetails.cntr_matr_desc != null)
    {
    for(const t of this.cntrMaterial)
    {
      if(t.cntr_matr_name.toLowerCase() == this.compdetails.cntr_matr_desc.toLowerCase())
      {
        this.get_selected_cntr_material_comp=t;
        break;
      }
    }
    }
  })
    this.enable_material_dropdown_comp=true;
  }
  else
  {
    this.enable_material_dropdown_comp=false;
     this.edit_selected_cntr_material_comp={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
  }


}

}
// material api changes

onChangeRetTypeEditComp()
{
  console.log(this.compdetailsedit.cntr_matr_desc);

  let edit_ret_type='';

  if(this.compdetailsedit.exp_refundable != '')
  {
  if(this.edit_selected_cntr_type_code_comp != null && this.edit_selected_cntr_type_code_comp.cntr_typ_desc != '')
  {

    if(this.compdetailsedit.exp_refundable.toLowerCase() =='returnable')
    {
      edit_ret_type= 'R';

    }
    else
    {
      edit_ret_type= 'E';
    }
   this.edit_selected_cntr_material_comp={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
    // api to get material list

    this.transactionService.getContainerMaterial(this.edit_selected_cntr_type_code_comp.typ_no,edit_ret_type).subscribe(mat_list=>{

      this.cntrMaterial=[];
      this.cntrMaterial=mat_list;


    if(this.cntrMaterial!= null && this.cntrMaterial.length!=0 && this.compdetailsedit!= null && this.compdetailsedit.cntr_matr_desc != null)
    {
    for(const t of this.cntrMaterial)
    {
      if(t.cntr_matr_name.toLowerCase() == this.compdetailsedit.cntr_matr_desc.toLowerCase())
      {
        this.edit_selected_cntr_material_comp=t;
        break;
      }
    }
    }

  })
    this.enable_material_dropdown_comp=true;
  }
    else
  {
    this.enable_material_dropdown_comp=false;
    this.edit_selected_cntr_material_comp={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
  }


}

else
{
  this.enable_material_dropdown_comp=false;
  this.edit_selected_cntr_material_comp={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
}




}

// material api changes

onChangeCntrTypEditComp()
{


  console.log(this.compdetailsedit.cntr_matr_desc);

  let edit_ret_type='';


  if(this.edit_selected_cntr_type_code_comp != null && this.edit_selected_cntr_type_code_comp.cntr_typ_desc != '')
  {
    if(this.compdetailsedit.exp_refundable != '')
    {

    if(this.compdetailsedit.exp_refundable.toLowerCase() =='returnable')
    {
      edit_ret_type= 'R';

    }
    else
    {
      edit_ret_type= 'E';
    }

     this.edit_selected_cntr_material_comp={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
    // api to get material list


    this.transactionService.getContainerMaterial(this.edit_selected_cntr_type_code_comp.typ_no,edit_ret_type).subscribe(mat_list=>{

      this.cntrMaterial=[];
      this.cntrMaterial=mat_list;


    if(this.cntrMaterial!= null && this.cntrMaterial.length!=0 && this.compdetailsedit!= null && this.compdetailsedit.cntr_matr_desc != null)
    {
    for(const t of this.cntrMaterial)
    {
      if(t.cntr_matr_name.toLowerCase() == this.compdetailsedit.cntr_matr_desc.toLowerCase())
      {
        this.edit_selected_cntr_material_comp=t;
        break;
      }
    }
    }
  })
    this.enable_material_dropdown_comp=true;
  }
  else
  {
    this.enable_material_dropdown_comp=false;
    this.edit_selected_cntr_material_comp={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
  }


}

else
{
  this.enable_material_dropdown_comp=false;
  this.edit_selected_cntr_material_comp={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
}


}

edit_comp_details(compData:any)
{
  this.enable_material_dropdown_comp=false;
  this.editcompdetailsinit();
  console.log(compData);

  // this.edit_measure='lbs/inch';
  // this.prev_edit_measure='lbs/inch'

  if(this.unitom=='E')
 {
   this.compdetailsedit.UoM='E';
   this.edit_measure='lbs/inch';
  this.prev_edit_measure='lbs/inch';
 }
 else{
   this.compdetailsedit.UoM='M';
   this.edit_measure='kg/cm';
  this.prev_edit_measure='kg/cm';
 }

  console.log('containe code in edit button', compData.cd_cntr);

  this.edit_selected_cntr_master_category_comp={cd_cntr_mstr_catg:0,cntr_mstr_catg_desc:'',updated_by:'',updated_dt:'',cntr_mstr_catg:'',active_stat:'',active_stat_display:false};
    this.edit_selected_cntr_type_code_comp={cd_cntr_typ:'',cntr_typ_desc:'',typ_no:0};
    this.edit_selected_cntr_ownrship_strat_code_comp={cd_region:'',ownrshp_strat_code:'',ownrshp_desc:'',cd_ownrshp_strat:0};
    this.edit_selected_ownrship_catg_comp={ownrshp_catg:'',ownrshp_catg_val:''};
    this.edit_selected_cntr_group_comp={cntr_grp:''}
    this.edit_selected_cntr_material_comp={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
    this.edit_selected_cntr_currency_list_comp={cd_curr:0,curr_desc:''};
    this.editSelectedPlantCodeComp={cd_plant:'',plantGSDBCode:'',plant_name:'',plant_active_stat:'',plant_type_name:''}

    if(compData.req_stat != null && compData.req_stat.toLowerCase() =='pending')
    {
      this.enableProcessingNotifiacationDialog=true;
    }

    else
    {

  if(this.compenabledetails==true)
  {
    this.compenabledetails=false
  }

  if(this.compenableeditgetdetails==true)
  {
    this.compenableeditgetdetails=false

  }

this.editing=false;

  if(this.compAddEnable=true)
  {
    this.compAddEnable=false;
  }
  if(this.compAddDetailsEnable=true)
  {
    this.compAddDetailsEnable=false;
  }

  this.compCmmsFieldsBeforeUpdateInit();
  this.transactionService.editbutton_get_contr_details(compData.cntr_base,compData.cd_cntr).subscribe(savecntrdetails=>{
    this.validationCompInit();
    this.enableeditcompdetails=true;
    this.compdetailsedit.function='C';
    this.compdetailsedit.cntr_base=savecntrdetails.cntr_base;
    this.compdetailsedit.cntr_suff_ind=savecntrdetails.cntr_suff_ind;
    this.compdetailsedit.exp_refundable=savecntrdetails.exp_refundable;
    this.compdetailsedit.cntr_active_ind=savecntrdetails.cntr_active_ind;
    this.compdetailsedit.cntr_suffix=savecntrdetails.cntr_suffix;
    if(savecntrdetails.cntr_desc != null)
    {
    this.compdetailsedit.cntr_desc=savecntrdetails.cntr_desc.trim();
    }
    this.compdetailsedit.card_call=savecntrdetails.card_call;
    this.compdetailsedit.cntr_mstr_catg=savecntrdetails.cntr_mstr_catg;
    this.compdetailsedit.cntr_typ_desc=savecntrdetails.cntr_typ_desc;
    this.compdetailsedit.typ_no=savecntrdetails.typ_no;
    this.compdetailsedit.ownrshp_strat_code=savecntrdetails.ownrshp_strat_code;
    this.compdetailsedit.ownrshp_catg=savecntrdetails.ownrshp_catg;
    this.compdetailsedit.cntr_grp_desc=savecntrdetails.cntr_grp_desc;
    this.compdetailsedit.cntr_matr_desc=savecntrdetails.cntr_matr_desc;
    this.compdetailsedit.currency_desc=savecntrdetails.currency_desc;
    this.compdetailsedit.iscontainercolapsble=savecntrdetails.iscontainercolapsble;

    this.view_modify_cd_cntr=savecntrdetails.cd_cntr;


    this.edit_comp_cd_cntr=savecntrdetails.cd_cntr;


  /* Removing add suffix to container
  if(this.cntrdetailsedit.cntr_suff_ind=='Yes')
  {
    this.cntrSuffixEditenabled=true;
  }
  else
  {
    this.cntrSuffixEditenabled=false;
  }*/

if(this.unitom=='E')
 {
   savecntrdetails.uom='E';
 }
 else{
  savecntrdetails.uom='M';

 }
      this.cntrSuffixEditenabled=false;

  if(this.cntrMasterCategory != null && this.cntrMasterCategory.length != 0)
  {
    for(let i=0;i<this.cntrMasterCategory.length;i++)
    {
       if(this.compdetailsedit.cntr_mstr_catg != null && this.compdetailsedit.cntr_mstr_catg==this.cntrMasterCategory[i].cntr_mstr_catg)
      {
        this.edit_selected_cntr_master_category_comp=this.cntrMasterCategory[i];
      }
    }
  }

  if(this.cntrTypeCodeComp != null && this.cntrTypeCodeComp.length !=0)
  {
    for(let i=0; i<this.cntrTypeCodeComp.length; i++)
    {
      if(this.compdetailsedit.typ_no != null && this.compdetailsedit.typ_no==this.cntrTypeCodeComp[i].typ_no)
      {
        this.edit_selected_cntr_type_code_comp=this.cntrTypeCodeComp[i];
        console.log('edit_selected_cntr_type_code',this.edit_selected_cntr_type_code_comp);
      }
    }
  }

  if(this.cntrOwnershipstratcode!= null && this.cntrOwnershipstratcode.length != 0)
  {
    for(let i=0;i<this.cntrOwnershipstratcode.length;i++)
    {
      if(this.compdetailsedit.ownrshp_strat_code != null && this.compdetailsedit.ownrshp_strat_code==this.cntrOwnershipstratcode[i].ownrshp_strat_code)
      {
        this.edit_selected_cntr_ownrship_strat_code_comp=this.cntrOwnershipstratcode[i];
      }
    }
  }

  if(this.ownrship_catg != null && this.ownrship_catg.length !=0)
  {
    for(let i=0;i<this.ownrship_catg.length;i++)
    {
      if(this.compdetailsedit.ownrshp_catg != null && this.compdetailsedit.ownrshp_catg.toLowerCase().indexOf(this.ownrship_catg[i].ownrshp_catg_val.toLowerCase())>-1)
      {
        this.edit_selected_ownrship_catg_comp=this.ownrship_catg[i];
      }
    }
  }

  if(this.cntrGrp != null && this.cntrGrp.length != 0)
  {
    for(let i=0;i<this.cntrGrp.length;i++)
    {
      if(this.compdetailsedit.cntr_grp_desc != null && this.compdetailsedit.cntr_grp_desc==this.cntrGrp[i].cntr_grp)
      {
        this.edit_selected_cntr_group_comp=this.cntrGrp[i];
      }
    }
  }

    // for(let i=0;i<this.cntrMaterial.length;i++)
    // {
    //   if(this.cntrdetailsedit.cntr_matr_desc==this.cntrMaterial[i].cntr_matr_name)
    //   {
    //     this.edit_selected_cntr_material=this.cntrMaterial[i];
    //   }
    // }
    this.onChangeCntrTypEditComp();

  if(this.cntrCurrencyList != null && this.cntrCurrencyList.length != 0)
  {
    for(let i=0;i<this.cntrCurrencyList.length;i++)
    {
      if(this.compdetailsedit.currency_desc != null && this.compdetailsedit.currency_desc==this.cntrCurrencyList[i].curr_desc)
      {
        this.edit_selected_cntr_currency_list_comp=this.cntrCurrencyList[i];
      }
    }
  }

    this.compdetailsedit.cntr_exterior_l=savecntrdetails.cntr_exterior_l;
    this.compdetailsedit.cntr_exterior_w=savecntrdetails.cntr_exterior_w;
    this.compdetailsedit.cntr_exterior_h=savecntrdetails.cntr_exterior_h;
    this.compdetailsedit.collapsed_ht=savecntrdetails.collapsed_ht;
    this.compdetailsedit.nest_ratio=savecntrdetails.nest_ratio;
    this.compdetailsedit.cntr_interior_l=savecntrdetails.cntr_interior_l;
    this.compdetailsedit.cntr_interior_w=savecntrdetails.cntr_interior_w;
    this.compdetailsedit.cntr_interior_h=savecntrdetails.cntr_interior_h;
    this.compdetailsedit.cntr_tare_wt=savecntrdetails.cntr_tare_wt;
    this.compdetailsedit.cntr_capacity=savecntrdetails.cntr_capacity;
    this.compdetailsedit.cntr_per_layer=savecntrdetails.cntr_per_layer;
    this.compdetailsedit.max_layers_shpg_unit=savecntrdetails.max_layers_shpg_unit;
    this.compdetailsedit.max_cntr_per_shpg_unit=savecntrdetails.max_cntr_per_shpg_unit;
    this.compdetailsedit.cntr_drawing_no=savecntrdetails.cntr_drawing_no;
    this.compdetailsedit.shpg_unit_exterior_l=savecntrdetails.shpg_unit_exterior_l;
    this.compdetailsedit.shpg_unit_exterior_w=savecntrdetails.shpg_unit_exterior_w;
    this.compdetailsedit.shpg_unit_exterior_h=savecntrdetails.shpg_unit_exterior_h;
    this.compdetailsedit.shpg_unit_nstdorflded_ht=savecntrdetails.shpg_unit_nstdorflded_ht;
    this.compdetailsedit.shpg_unit_tare_wt=savecntrdetails.shpg_unit_tare_wt;
    this.compdetailsedit.shpg_unit_stack_ht=savecntrdetails.shpg_unit_stack_ht;
    this.compdetailsedit.warehouse_stack_ht=savecntrdetails.warehouse_stack_ht;
    this.compdetailsedit.service_life=savecntrdetails.service_life;
    this.compdetailsedit.purchase_cost=savecntrdetails.purchase_cost;
    this.compdetailsedit.rental_issue_cost=savecntrdetails.rental_issue_cost;
    this.compdetailsedit.sup_rntl_dly_cost=savecntrdetails.sup_rntl_dly_cost;
    this.compdetailsedit.ford_rntl_dly_cost=savecntrdetails.ford_rntl_dly_cost;
    this.compdetailsedit.return_rntl_cost=savecntrdetails.return_rntl_cost;
    this.compdetailsedit.tooling_dev_cost=savecntrdetails.tooling_dev_cost;
    this.compdetailsedit.extr_cntr_dsgn_img_ind=savecntrdetails.extr_cntr_dsgn_img_ind;
    this.compdetailsedit.intr_cntr_dsgn_img_ind=savecntrdetails.intr_cntr_dsgn_img_ind;
    this.compdetailsedit.cad_img_ind=savecntrdetails.cad_img_ind;
    this.compdetailsedit.img_notes=savecntrdetails.img_notes;
    this.compdetailsedit.cd_region=savecntrdetails.cd_region;



    this.compCmmsFieldsBeforeUpdate.cntr_base=JSON.parse(JSON.stringify(this.compdetailsedit.cntr_base))
    this.compCmmsFieldsBeforeUpdate.cntr_suffix=JSON.parse(JSON.stringify(this.compdetailsedit.cntr_suffix))
    this.compCmmsFieldsBeforeUpdate.cntr_desc=JSON.parse(JSON.stringify(this.compdetailsedit.cntr_desc))
    this.compCmmsFieldsBeforeUpdate.cntr_typ_desc=JSON.parse(JSON.stringify(this.compdetailsedit.cntr_typ_desc))
    this.compCmmsFieldsBeforeUpdate.cntr_exterior_l=JSON.parse(JSON.stringify(this.compdetailsedit.cntr_exterior_l))
    this.compCmmsFieldsBeforeUpdate.cntr_exterior_w=JSON.parse(JSON.stringify(this.compdetailsedit.cntr_exterior_w))
    this.compCmmsFieldsBeforeUpdate.cntr_exterior_h=JSON.parse(JSON.stringify(this.compdetailsedit.cntr_exterior_h))
    this.compCmmsFieldsBeforeUpdate.cntr_capacity=JSON.parse(JSON.stringify(this.compdetailsedit.cntr_capacity))
    this.compCmmsFieldsBeforeUpdate.cntr_tare_wt=JSON.parse(JSON.stringify(this.compdetailsedit.cntr_tare_wt))
    this.compCmmsFieldsBeforeUpdate.warehouse_stack_ht=JSON.parse(JSON.stringify(this.compdetailsedit.warehouse_stack_ht))
    this.compCmmsFieldsBeforeUpdate.ownrshp_catg = JSON.parse(JSON.stringify(this.compdetailsedit.ownrshp_catg))
    






    if(savecntrdetails.cd_plant != null)
    {
    this.compdetailsedit.cd_plant= savecntrdetails.cd_plant;
    this.compCmmsFieldsBeforeUpdate.cd_plant=JSON.parse(JSON.stringify(this.compdetailsedit.cd_plant))
    for(let i=0;i<this.plantList.length;i++)
    {
      if(this.compdetailsedit.cd_plant.trim()==this.plantList[i].plantGSDBCode)
      {
        this.editSelectedPlantCodeComp=this.plantList[i];
        console.log('edit_selected_plant_code',this.editSelectedPlantCodeComp);
      }
    }
    }
    if(savecntrdetails.cnt_qt_per_min_su != null)
    {
      this.compdetailsedit.cntr_qt_per_min_su=savecntrdetails.cnt_qt_per_min_su;
      this.compCmmsFieldsBeforeUpdate.cntr_qt_per_min_su=JSON.parse(JSON.stringify(this.compdetailsedit.cntr_qt_per_min_su))
    }
    if(savecntrdetails.nest_ratio_1 != null)
    {
      this.compdetailsedit.nest_ratio_1=savecntrdetails.nest_ratio_1;
    }
    if(savecntrdetails.ford_cntct != null)
    {
      this.compdetailsedit.ford_cntct= savecntrdetails.ford_cntct.trim();
      this.compCmmsFieldsBeforeUpdate.ford_cntct=JSON.parse(JSON.stringify(this.compdetailsedit.ford_cntct))
    }
    if(savecntrdetails.cntct_phone != null)
    {
      this.compdetailsedit.cntct_phone=savecntrdetails.cntct_phone.trim();
      this.compCmmsFieldsBeforeUpdate.cntct_phone=JSON.parse(JSON.stringify(this.compdetailsedit.cntct_phone))
    }

    console.log('UOMCHECK',this.unitom,this.compdetailsedit.UoM,savecntrdetails.uom)

    // if(savecntrdetails.uom != null && savecntrdetails.uom.trim() != "")
    // {
    // this.compdetailsedit.UoM=savecntrdetails.uom;
    // }
    // else
    // {
    //   this.compdetailsedit.UoM = "E"
    // }

    // if(this.compdetailsedit.UoM.toLowerCase() == "m")
    // {

    //   this.edit_measure='kg/cm'
    //   this.prev_edit_measure='kg/cm'
    // }
    // else
    // {
    //   this.edit_measure='lbs/inch'
    //   this.prev_edit_measure='lbs/inch'
    // }

    console.log('cntrdetailsedit',this.compdetailsedit);
  })
}

}




geteditedcompdetails(editedcntrdetails:any)
{

  this.compdetailsinit();
  this.compenabledetails=true;
  // this.get_measure='lbs/inch';
  // this.prev_get_measure='lbs/inch'

  if(this.unitom=='E')
 {
   this.get_measure='lbs/inch';
 this.prev_get_measure='lbs/inch';
 }
 else{
   this.get_measure='kg/cm';
  this.prev_get_measure='kg/cm';
 }

  console.log()
  this.compdetailsedit.exp_refundable='Returnable';

this.get_selected_cntr_master_category_comp={cd_cntr_mstr_catg:0,cntr_mstr_catg_desc:'',updated_by:'',updated_dt:'',cntr_mstr_catg:'',active_stat:'',active_stat_display:false};
this.get_selected_cntr_type_code_comp={cd_cntr_typ:'',cntr_typ_desc:'',typ_no:0};
this.get_selected_cntr_ownrship_strat_code_comp={cd_region:'',ownrshp_strat_code:'',ownrshp_desc:'',cd_ownrshp_strat:0};
this.get_selected_ownrship_catg_comp={ownrshp_catg:'',ownrshp_catg_val:''};
this.get_selected_cntr_group_comp={cntr_grp:''}
this.get_selected_cntr_material_comp={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
this.get_selected_cntr_currency_list_comp={cd_curr:0,curr_desc:''};

  if(this.compenableeditgetdetails==true)
  {
    this.compenableeditgetdetails=false;

  }
  if(this.enableeditcompdetails==true)
  {
    this.enableeditcompdetails=false;
  }
  this.editing=false;

  if(this.compAddEnable==true)
  {
    this.compAddEnable=false;
  }

  if(this.compAddDetailsEnable==true)
  {
    this.compAddDetailsEnable=false;
  }

  this.validationCompInit();
  this.transactionService.editbutton_get_contr_details(editedcntrdetails.cntr_base,editedcntrdetails.cd_cntr).subscribe((getcntrdetails=>{
    this.compdetails=getcntrdetails;

   /* if(String(this.cntrdetails.cntr_typ_desc)=="01")
    {
      this.containerOrComponentInDetails="container"
    }
    else
    {
      this.containerOrComponentInDetails="component";
    }*/

  if(this.cntrMasterCategory != null && this.cntrMasterCategory.length != 0)
  {
    for(let i=0;i<this.cntrMasterCategory.length;i++)
    {
      if(this.compdetails.cntr_mstr_catg != null && this.compdetails.cntr_mstr_catg==this.cntrMasterCategory[i].cntr_mstr_catg)
      {
        this.get_selected_cntr_master_category_comp=this.cntrMasterCategory[i];
      }
    }
  }

  if(this.unmodifiedTypeCodes != null && this.unmodifiedTypeCodes.length != 0)
  {
    for(let i=0;i<this.unmodifiedTypeCodes.length;i++)
    {
      if(this.compdetails.typ_no != null && this.compdetails.typ_no==this.unmodifiedTypeCodes[i].typ_no)
      {
        this.get_selected_cntr_type_code_comp=this.unmodifiedTypeCodes[i];
        console.log('edit_selected_cntr_type_code',this.get_selected_cntr_type_code_comp);
      }
    }
  }

  if(this.cntrOwnershipstratcode != null && this.cntrOwnershipstratcode.length != 0)
  {
    for(let i=0;i<this.cntrOwnershipstratcode.length;i++)
    {
      if(this.compdetails.ownrshp_strat_code != null && this.compdetails.ownrshp_strat_code==this.cntrOwnershipstratcode[i].ownrshp_strat_code)
      {
        this.get_selected_cntr_ownrship_strat_code_comp=this.cntrOwnershipstratcode[i];
      }
    }
  }

  if(this.ownrship_catg != null && this.ownrship_catg.length != 0)
  {
    for(let i=0;i<this.ownrship_catg.length;i++)
    {
      if(this.compdetails.ownrshp_catg != null && this.compdetails.ownrshp_catg.toLowerCase().indexOf(this.ownrship_catg[i].ownrshp_catg_val.toLowerCase())>-1)
      {
        this.get_selected_ownrship_catg_comp=this.ownrship_catg[i];
      }
    }
  }

  if(this.cntrGrp != null && this.cntrGrp.length != 0)
  {
    for(let i=0;i<this.cntrGrp.length;i++)
    {
      if(this.compdetails.cntr_grp_desc != null && this.compdetails.cntr_grp_desc==this.cntrGrp[i].cntr_grp)
      {
        this.get_selected_cntr_group_comp=this.cntrGrp[i];
      }
    }
  }

    // for(let i=0;i<this.cntrMaterial.length;i++)
    // {
    //   if(this.cntrdetails.cntr_matr_desc==this.cntrMaterial[i].cntr_matr_name)
    //   {
    //     this.get_selected_cntr_material=this.cntrMaterial[i];
    //   }
    // }

    this.getMaterialValueToDisplayComp();

    if(this.cntrCurrencyList != null && this.cntrCurrencyList.length != 0)
    {
    for(let i=0;i<this.cntrCurrencyList.length;i++)
    {
      if(this.compdetails.currency_desc != null && this.compdetails.currency_desc==this.cntrCurrencyList[i].curr_desc)
      {
        this.get_selected_cntr_currency_list_comp=this.cntrCurrencyList[i];
      }
    }
  }


    if(this.compdetails.uom == null || this.compdetails.uom.trim() == '')
      {
        this.compdetails.uom = 'E'
      }

      if(this.compdetails.uom.toLowerCase() == 'm')
      {

        this.get_measure='kg/cm'
        this.prev_get_measure='kg/cm'
      }
      else
      {
        this.get_measure='lbs/inch'
        this.prev_get_measure='lbs/inch'
      }

  }))




}




getcompdetails_edit()
{
  if(this.compdetails.req_stat != null && this.compdetails.req_stat.toLowerCase() =='pending')
  {
    this.enableProcessingNotifiacationDialog=true;
  }


  else
  {
  this.compenabledetails=false;
  this.compenableeditgetdetails=true;
  this.editing=false;
  this.enable_material_dropdown_comp=false;

  this.editcompdetailsinit();
  this.validationCompInit();

this.compdetailsedit.exp_refundable='Returnable';

this.edit_selected_cntr_master_category_comp={cd_cntr_mstr_catg:0,cntr_mstr_catg_desc:'',updated_by:'',updated_dt:'',cntr_mstr_catg:'',active_stat:'',active_stat_display:false};
this.edit_selected_cntr_type_code_comp={cd_cntr_typ:'',cntr_typ_desc:'',typ_no:0};
this.edit_selected_cntr_ownrship_strat_code_comp={cd_region:'',ownrshp_strat_code:'',ownrshp_desc:'',cd_ownrshp_strat:0};
this.edit_selected_ownrship_catg_comp={ownrshp_catg:'',ownrshp_catg_val:''};
this.edit_selected_cntr_group_comp={cntr_grp:''}
this.edit_selected_cntr_material_comp={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
this.edit_selected_cntr_currency_list_comp={cd_curr:0,curr_desc:''};
this.editSelectedPlantCodeComp={cd_plant:'',plantGSDBCode:'',plant_name:'',plant_active_stat:'',plant_type_name:''}

  this.edit_measure=this.get_measure;
  this.prev_edit_measure=this.get_measure;


  this.compCmmsFieldsBeforeUpdateInit();

  console.log('container details on clicking edit after save button',this.compdetails);


   // this.cntrdetailsedit.cd_cntr=this.cntrdetails.cd_cntr;
   this.compdetailsedit.function='C';
    this.compdetailsedit.cntr_base=this.compdetails.cntr_base;
    this.compdetailsedit.cntr_suff_ind=this.compdetails.cntr_suff_ind;
    this.compdetailsedit.exp_refundable=this.compdetails.exp_refundable;
    this.compdetailsedit.cntr_active_ind=this.compdetails.cntr_active_ind;
    this.compdetailsedit.cntr_suffix=this.compdetails.cntr_suffix;
    if(this.compdetails.cntr_desc != null)
    {
    this.compdetailsedit.cntr_desc=this.compdetails.cntr_desc.trim();
    }
    this.compdetailsedit.card_call=this.compdetails.card_call;
    this.compdetailsedit.cntr_mstr_catg=this.compdetails.cntr_mstr_catg;
    this.compdetailsedit.cntr_typ_desc=this.compdetails.cntr_typ_desc;
    this.compdetailsedit.typ_no=this.compdetails.typ_no;
    this.compdetailsedit.ownrshp_strat_code=this.compdetails.ownrshp_strat_code;
    this.compdetailsedit.ownrshp_catg=this.compdetails.ownrshp_catg;
    this.compdetailsedit.cntr_grp_desc=this.compdetails.cntr_grp_desc;
    this.compdetailsedit.cntr_matr_desc=this.compdetails.cntr_matr_desc;
    this.compdetailsedit.currency_desc=this.compdetails.currency_desc;
    this.compdetailsedit.iscontainercolapsble=this.compdetails.iscontainercolapsble;
    this.view_modify_cd_cntr=this.compdetails.cd_cntr;
    this.edit_comp_cd_cntr=this.compdetails.cd_cntr;

    if(this.unitom=='E')
    {
      this.compdetailsedit.UoM='E';
      this.edit_measure='lbs/inch';
    this.prev_edit_measure='lbs/inch';
    }
    else{
      this.compdetailsedit.UoM='M';
      this.edit_measure='kg/cm';
    this.prev_edit_measure='kg/cm';
    }

    if(this.cntrMasterCategory != null && this.cntrMasterCategory.length != 0)
    {
    for(let i=0;i<this.cntrMasterCategory.length;i++)
    {
      if(this.compdetailsedit.cntr_mstr_catg!= null && this.compdetailsedit.cntr_mstr_catg==this.cntrMasterCategory[i].cntr_mstr_catg)
      {
        this.edit_selected_cntr_master_category_comp=this.cntrMasterCategory[i];
      }
    }
  }

  if(this.unmodifiedTypeCodes != null && this.unmodifiedTypeCodes.length != 0)
  {
    for(let i=0;i<this.unmodifiedTypeCodes.length;i++)
    {
      if(this.compdetailsedit.typ_no != null && this.compdetailsedit.typ_no==this.unmodifiedTypeCodes[i].typ_no)
      {
        this.edit_selected_cntr_type_code_comp=this.unmodifiedTypeCodes[i];
        console.log('edit_selected_cntr_type_code',this.edit_selected_cntr_type_code_comp);
      }
    }
  }

  if(this.cntrOwnershipstratcode != null && this.cntrOwnershipstratcode.length != 0)
  {
    for(let i=0;i<this.cntrOwnershipstratcode.length;i++)
    {
      if(this.compdetailsedit.ownrshp_strat_code != null && this.compdetailsedit.ownrshp_strat_code==this.cntrOwnershipstratcode[i].ownrshp_strat_code)
      {
        this.edit_selected_cntr_ownrship_strat_code_comp=this.cntrOwnershipstratcode[i];
      }
    }
  }

  if(this.ownrship_catg != null && this.ownrship_catg.length != 0)
  {
    for(let i=0;i<this.ownrship_catg.length;i++)
    {
      if(this.compdetailsedit.ownrshp_catg!= null && this.compdetailsedit.ownrshp_catg.toLowerCase().indexOf(this.ownrship_catg[i].ownrshp_catg_val.toLowerCase())>-1)
      {
        this.edit_selected_ownrship_catg_comp=this.ownrship_catg[i];
      }
    }
  }

  if(this.cntrGrp != null && this.cntrGrp.length != 0)
  {
    for(let i=0;i<this.cntrGrp.length;i++)
    {
      if(this.compdetailsedit.cntr_grp_desc != null && this.compdetailsedit.cntr_grp_desc==this.cntrGrp[i].cntr_grp)
      {
        this.edit_selected_cntr_group_comp=this.cntrGrp[i];
      }
    }
  }

    // for(let i=0;i<this.cntrMaterial.length;i++)
    // {
    //   if(this.cntrdetailsedit.cntr_matr_desc==this.cntrMaterial[i].cntr_matr_name)
    //   {
    //     this.edit_selected_cntr_material=this.cntrMaterial[i];
    //   }
    // }

    this.onChangeCntrTypEditComp()
  if(this.cntrCurrencyList != null && this.cntrCurrencyList.length != 0)
  {
    for(let i=0;i<this.cntrCurrencyList.length;i++)
    {
      if(this.compdetailsedit.currency_desc != null && this.compdetailsedit.currency_desc==this.cntrCurrencyList[i].curr_desc)
      {
        // alert("came here")
        this.edit_selected_cntr_currency_list_comp=this.cntrCurrencyList[i];
      }
    }
  }

    this.compdetailsedit.cntr_exterior_l=this.compdetails.cntr_exterior_l;
    this.compdetailsedit.cntr_exterior_w=this.compdetails.cntr_exterior_w;
    this.compdetailsedit.cntr_exterior_h=this.compdetails.cntr_exterior_h;
    this.compdetailsedit.collapsed_ht=this.compdetails.collapsed_ht;
    this.compdetailsedit.nest_ratio=this.compdetails.nest_ratio;
    this.compdetailsedit.cntr_interior_l=this.compdetails.cntr_interior_l;
    this.compdetailsedit.cntr_interior_w=this.compdetails.cntr_interior_w;
    this.compdetailsedit.cntr_interior_h=this.compdetails.cntr_interior_h;
    this.compdetailsedit.cntr_tare_wt=this.compdetails.cntr_tare_wt;
    this.compdetailsedit.cntr_capacity=this.compdetails.cntr_capacity;
    this.compdetailsedit.cntr_per_layer=this.compdetails.cntr_per_layer;
    this.compdetailsedit.max_layers_shpg_unit=this.compdetails.max_layers_shpg_unit;
    this.compdetailsedit.max_cntr_per_shpg_unit=this.compdetails.max_cntr_per_shpg_unit;
    this.compdetailsedit.cntr_drawing_no=this.compdetails.cntr_drawing_no;
    this.compdetailsedit.shpg_unit_exterior_l=this.compdetails.shpg_unit_exterior_l;
    this.compdetailsedit.shpg_unit_exterior_w=this.compdetails.shpg_unit_exterior_w;
    this.compdetailsedit.shpg_unit_exterior_h=this.compdetails.shpg_unit_exterior_h;
    this.compdetailsedit.shpg_unit_nstdorflded_ht=this.compdetails.shpg_unit_nstdorflded_ht;
    this.compdetailsedit.shpg_unit_tare_wt=this.compdetails.shpg_unit_tare_wt;
    this.compdetailsedit.shpg_unit_stack_ht=this.compdetails.shpg_unit_stack_ht;
    this.compdetailsedit.warehouse_stack_ht=this.compdetails.warehouse_stack_ht;
    this.compdetailsedit.service_life=this.compdetails.service_life;
    this.compdetailsedit.purchase_cost=this.compdetails.purchase_cost;
    this.compdetailsedit.rental_issue_cost=this.compdetails.rental_issue_cost;
    this.compdetailsedit.sup_rntl_dly_cost=this.compdetails.sup_rntl_dly_cost;
    this.compdetailsedit.ford_rntl_dly_cost=this.compdetails.ford_rntl_dly_cost;
    this.compdetailsedit.return_rntl_cost=this.compdetails.return_rntl_cost;
    this.compdetailsedit.tooling_dev_cost=this.compdetails.tooling_dev_cost;
    this.compdetailsedit.extr_cntr_dsgn_img_ind=this.compdetails.extr_cntr_dsgn_img_ind;
    this.compdetailsedit.intr_cntr_dsgn_img_ind=this.compdetails.intr_cntr_dsgn_img_ind;
    this.compdetailsedit.cad_img_ind=this.compdetails.cad_img_ind;
    this.compdetailsedit.img_notes=this.compdetails.img_notes;
    this.compdetailsedit.cd_region=this.compdetails.cd_region;
 // uom field added
 this.compdetailsedit.UoM=this.compdetails.uom;

 if(this.unitom=='E')
 {
   this.compdetailsedit.UoM='E';
   this.edit_measure='lbs/inch';
 this.prev_edit_measure='lbs/inch';
 }
 else{
   this.compdetailsedit.UoM='M';
   this.edit_measure='kg/cm';
 this.prev_edit_measure='kg/cm';
 }

    if(this.compdetailsedit.service_life==null)
    {
      this.compdetailsedit.service_life='';
    }


   // verify and add uom field
    this.compCmmsFieldsBeforeUpdate.cntr_base=JSON.parse(JSON.stringify(this.compdetailsedit.cntr_base))
    this.compCmmsFieldsBeforeUpdate.cntr_suffix=JSON.parse(JSON.stringify(this.compdetailsedit.cntr_suffix))
    this.compCmmsFieldsBeforeUpdate.cntr_desc=JSON.parse(JSON.stringify(this.compdetailsedit.cntr_desc))
    this.compCmmsFieldsBeforeUpdate.cntr_typ_desc=JSON.parse(JSON.stringify(this.compdetailsedit.cntr_typ_desc))
    this.compCmmsFieldsBeforeUpdate.cntr_exterior_l=JSON.parse(JSON.stringify(this.compdetailsedit.cntr_exterior_l))
    this.compCmmsFieldsBeforeUpdate.cntr_exterior_w=JSON.parse(JSON.stringify(this.compdetailsedit.cntr_exterior_w))
    this.compCmmsFieldsBeforeUpdate.cntr_exterior_h=JSON.parse(JSON.stringify(this.compdetailsedit.cntr_exterior_h))
    this.compCmmsFieldsBeforeUpdate.cntr_capacity=JSON.parse(JSON.stringify(this.compdetailsedit.cntr_capacity))
    this.compCmmsFieldsBeforeUpdate.cntr_tare_wt=JSON.parse(JSON.stringify(this.compdetailsedit.cntr_tare_wt))
    this.compCmmsFieldsBeforeUpdate.warehouse_stack_ht=JSON.parse(JSON.stringify(this.compdetailsedit.warehouse_stack_ht))
    this.compCmmsFieldsBeforeUpdate.ownrshp_catg=JSON.parse(JSON.stringify(this.compdetailsedit.ownrshp_catg))
    






    if(this.compdetails.cd_plant != null)
    {
    this.compdetailsedit.cd_plant= this.compdetails.cd_plant;
    this.compCmmsFieldsBeforeUpdate.cd_plant=JSON.parse(JSON.stringify(this.compdetailsedit.cd_plant))

    for(let i=0;i<this.plantList.length;i++)
    {
      if(this.compdetailsedit.cd_plant.trim()==this.plantList[i].plantGSDBCode)
      {
        this.editSelectedPlantCodeComp=this.plantList[i];
        console.log('edit_selected_plant_code',this.editSelectedPlantCodeComp);
      }
    }
    }
    if(this.compdetails.cnt_qt_per_min_su != null)
    {
      this.compdetailsedit.cntr_qt_per_min_su=this.compdetails.cnt_qt_per_min_su;
      this.compCmmsFieldsBeforeUpdate.cntr_qt_per_min_su=JSON.parse(JSON.stringify(this.compdetailsedit.cntr_qt_per_min_su))

    }
    if(this.compdetails.nest_ratio_1 != null)
    {
      this.compdetailsedit.nest_ratio_1=this.compdetails.nest_ratio_1;
    }
    if(this.compdetails.ford_cntct != null)
    {
      this.compdetailsedit.ford_cntct= this.compdetails.ford_cntct.trim();
      this.compCmmsFieldsBeforeUpdate.ford_cntct=JSON.parse(JSON.stringify(this.compdetailsedit.ford_cntct))

    }
    if(this.compdetails.cntct_phone != null)
    {
      this.compdetailsedit.cntct_phone=this.compdetails.cntct_phone.trim();
      this.compCmmsFieldsBeforeUpdate.cntct_phone=JSON.parse(JSON.stringify(this.compdetailsedit.cntct_phone))

    }




   /* Removing add suffix to container
    if(this.cntrdetailsedit.cntr_suff_ind=='Yes')

    {
      this.cntrSuffixEditenabled=true;
    }
    else
    {
      this.cntrSuffixEditenabled=false;
    }
    */


    this.cntrSuffixEditenabled=false;
  }

}

getcompdetails_cancel()
{
  this.compenabledetails=false;
  this.compAddEnable=true;
  this.compdetailsinit();

}





validateCompBaseForEdit()
{
  if(this.compdetailsedit.cntr_base==null || this.compdetailsedit.cntr_base.trim()=='')
  {
    this.compBaseInvalid=true;
    this.compBaseInvalidReason='Required'
  }
  else
  {
    this.compBaseInvalid=false;
    this.compBaseInvalidReason=null
  }
}

validateCompDescForEdit()
{
  if(this.compdetailsedit.cntr_desc==null || this.compdetailsedit.cntr_desc.trim()=='')
  {
    this.compDescInvalid=true;
    this.compDescInvalidReason='Required'
  }
  else
  {
    this.compDescInvalid=false;
    this.compDescInvalidReason=null
  }
}

validateCompPlantCodeForEdit()
{
  if(this.editSelectedPlantCodeComp.plantGSDBCode==null || this.editSelectedPlantCodeComp.plantGSDBCode.trim()=='')
  {
    this.plantCodeCompInvalid=true;
    this.plantCodeCompInvalidReason='Required'
  }
  else
  {
    this.plantCodeCompInvalid=false;
    this.plantCodeCompInvalidReason=null
  }
}




validateCompMastCatgForEdit()
{
  if(this.edit_selected_cntr_master_category_comp.cntr_mstr_catg==null || this.edit_selected_cntr_master_category_comp.cntr_mstr_catg.trim()=='')
  {
    this.mastrCatgCompInvalid=true;
    this.mastrCatgCompInvalidReason='Required'
  }
  else
  {
    this.mastrCatgCompInvalid=false;
    this.mastrCatgCompInvalidReason=null
  }
}

validateCompRetTypeForEdit()
{
  if(this.compdetailsedit.exp_refundable==null || this.compdetailsedit.exp_refundable.trim()=='')
  {
    this.retTypeCompInvalid=true;
    this.retTypeCompInvalidReason='Required'
  }
  else
  {
    this.retTypeCompInvalid=false;
    this.retTypeCompInvalidReason=null;
  }
}

validateCompTypeCodeForEdit()
{
  if(this.edit_selected_cntr_type_code_comp.cntr_typ_desc==null || this.edit_selected_cntr_type_code_comp.cntr_typ_desc.trim()=='')
  {
    this.compTypeInvalid=true;
    this.compTypeInvalidReason='Required';
  }
  else
  {
    this.compTypeInvalid=false;
    this.compTypeInvalidReason=null;
  }
}

validateCompMaterialForEdit()
{
  if(this.edit_selected_cntr_material_comp.cntr_matr_name==null || this.edit_selected_cntr_material_comp.cntr_matr_name.trim()=='')
  {
    this.compMaterialInvalid=true;
    this.compMaterialInvalidReason='Required';
  }
  else
  {
    this.compMaterialInvalid=false;
    this.compMaterialInvalidReason=null;
  }
}
validateCompOwnershpCatgForEdit()
{
  if(this.edit_selected_ownrship_catg_comp.ownrshp_catg_val==null || this.edit_selected_ownrship_catg_comp.ownrshp_catg_val.trim()=='')
  {
    this.compOwnerShpCatgInvalid=true;
    this.compOwnerShpCatgInvalidReason='Required';
  }
  else
  {
    this.compOwnerShpCatgInvalid=false;
    this.compOwnerShpCatgInvalidReason=null;
  }
}
validateCompCOSForEdit()
{
  if(this.edit_selected_cntr_ownrship_strat_code_comp.ownrshp_strat_code==null || this.edit_selected_cntr_ownrship_strat_code_comp.ownrshp_strat_code.trim()=='')
  {
    this.cosCompInvalid=true;
    this.cosCompInvalidReason='Required';
  }
  else
  {
    this.cosCompInvalid=false;
    this.cosCompInvalidReason=null;
  }
}

/*validateCompGrpForEdit()
{
  if(this.edit_selected_cntr_group_comp.cntr_grp==null || this.edit_selected_cntr_group_comp.cntr_grp.trim()=="")
  {
    this.compGrpInvalid=true;
    this.compGrpInvalidReason="Required";
  }
  else
  {
    this.compGrpInvalid=false;
    this.compGrpInvalidReason=null;
  }
}*/


validateCompContactEdit()
{
  if(this.compdetailsedit.ford_cntct==null || this.compdetailsedit.ford_cntct.trim()=='')
  {
    this.compCntctInvalid=true;
    this.compCntctInvalidReason='Required';
  }
  else
  {
    this.compCntctInvalid=false;
    this.compCntctInvalidReason=null;
  }
}

validateCompContactPhoneEdit()
{


  if(this.compdetailsedit.cntct_phone==null || this.compdetailsedit.cntct_phone.trim()=='')
  {
    this.cntctPhoneCompInvalid=true;
    this.cntctPhoneCompInvlidReason='Required';
  }
  else
  {
    this.cntctPhoneCompInvalid=false;
    this.cntctPhoneCompInvlidReason=null;
  }

}

validateCompActiveEdit()
{
  if(this.compdetailsedit.cntr_active_ind==null || this.compdetailsedit.cntr_active_ind.trim()=='')
  {
    this.compActiveInvalid=true;
    this.compActiveInvalidReason='Required';
  }
  else
  {
    this.compActiveInvalid=false;
    this.compActiveInvalidReason=null;
  }
}




validateCompTareWeightForEdit()
{

  const comp_tar_wt = String(this.compdetailsedit.cntr_tare_wt).split('.');
  if(this.compdetailsedit.cntr_tare_wt==null || this.compdetailsedit.cntr_tare_wt==0)
  {
    this.compTareWtInValid=true;
    this.compTareWtInValidReason='Required';
  }
  else if(!String(this.compdetailsedit.cntr_tare_wt).includes('.'))
  {
    /*if( String(this.compdetailsedit.cntr_tare_wt).length>10)
      {
        this.compTareWtInValid=true;
        this.compTareWtInValidReason="Max Length Of Characters can be 10";
      }
      else*/
       if(this.compdetailsedit.cntr_tare_wt<0 || this.compdetailsedit.cntr_tare_wt>99999.999)
      {
        this.compTareWtInValid=true;
        this.compTareWtInValidReason='Should be of range 0 to 99999.999';
      }
    else
      {
        this.compTareWtInValid=false;
        this.compTareWtInValidReason=null;
      }
  }
  else if(String(this.compdetailsedit.cntr_tare_wt).includes('.'))
  {
      if(String(comp_tar_wt[0]).length<0 || String(comp_tar_wt[0]).length>5)
      {
        this.compTareWtInValid=true;
        this.compTareWtInValidReason='Should be of range 0 to 99999.999';
      }
      else if(String(comp_tar_wt[1]).length<0 || String(comp_tar_wt[1]).length>3)
      {
        this.compTareWtInValid=true;
        this.compTareWtInValidReason='Max Length Of decimal values can be 3';
      }
      else
      {
        this.compTareWtInValid=false;
        this.compTareWtInValidReason=null;
      }
    }
  else
  {
  this.compTareWtInValid=false;
  this.compTareWtInValidReason=null;
  }
}
validateCompCapWtForEdit()
{
  const cont_cap_wt = String(this.compdetailsedit.cntr_capacity).split('.');
  if(this.compdetailsedit.cntr_capacity==null || this.compdetailsedit.cntr_capacity==0)
  {
    this.compCapWtInValid=true;
    this.compCapWtInValidReason='Required';
  }
  else if(this.compdetailsedit.cntr_capacity<=0 || this.compdetailsedit.cntr_capacity>99999.999)
  {
    this.compCapWtInValid=true;
    this.compCapWtInValidReason='Should be of range 0 to 99999.999';
  }
  else if(String(this.compdetailsedit.cntr_capacity).includes('.'))
    {
      if(cont_cap_wt[1].length > 3)
      {
        this.compCapWtInValid=true;
        this.compCapWtInValidReason='Max Length Of decimal values can be 3';
      }
      else
      {
        this.compCapWtInValid=false;
        this.compCapWtInValidReason=null;
      }
    }
  else
  {
    this.compCapWtInValid=false;
    this.compCapWtInValidReason=null;
  }
}

validateCompQtPerMinSuForEdit()
{
  if(this.compdetailsedit.cntr_qt_per_min_su==null || this.compdetailsedit.cntr_qt_per_min_su==0)
  {
    this.compQtPerMinSuInValid=true;
    this.compQtPerMinSuInvalidReason='Required';
  }
  else if(this.compdetailsedit.cntr_qt_per_min_su<1 || this.compdetailsedit.cntr_qt_per_min_su>99 || String(this.compdetailsedit.cntr_qt_per_min_su).includes('.'))
  {
    this.compQtPerMinSuInValid=true;
    this.compQtPerMinSuInvalidReason='Should be of range 1 to 99  and cannot contain Decimal';
  }
  else
  {
    this.compQtPerMinSuInValid=false;
    this.compQtPerMinSuInvalidReason=null;
  }
}

validateCompExtLForEdit()
{
  const cont_ext_l = String(this.compdetailsedit.cntr_exterior_l).split('.');
  if(this.compdetailsedit.cntr_exterior_l==null || this.compdetailsedit.cntr_exterior_l==0)
  {
    this.compExtLInValid=true;
    this.compExtLInValidReason='Required';
  }
  else if(!String(this.compdetailsedit.cntr_exterior_l).includes('.'))
    {
    if(this.compdetailsedit.cntr_exterior_l<0 || this.compdetailsedit.cntr_exterior_l>999.999 )
        {
          this.compExtLInValid=true;
          this.compExtLInValidReason='Should be of range 0 to 999.999';
        }
       /* else if( String(this.compdetailsedit.cntr_exterior_l).length>7)
        {
          this.compExtLInValid=true;
          this.compExtLInValidReason="Max Length Of Characters can be 7";
        }*/
        else
        {
          this.compExtLInValid=false;
          this.compExtLInValidReason=null;
        }
    }
    else if(String(this.compdetailsedit.cntr_exterior_l).includes('.'))
    {
        if(String(cont_ext_l[0]).length<0 || String(cont_ext_l[0]).length>3)
        {
          this.compExtLInValid=true;
          this.compExtLInValidReason='Should be of range 0 to 999.999';
        }
        else if(this.compdetailsedit.cntr_exterior_l<0 || this.compdetailsedit.cntr_exterior_l>999.999 )
        {
          this.compExtLInValid=true;
          this.compExtLInValidReason='Should be of range 0 to 999.999';
        }
        else if(String(cont_ext_l[1]).length<0 || String(cont_ext_l[1]).length>3)
        {
          this.compExtLInValid=true;
          this.compExtLInValidReason='Max Length Of decimal values can be 3';
        }
        else
        {
          this.compExtLInValid=false;
          this.compExtLInValidReason=null;
      }
    }
    else
    {
    this.compExtLInValid=false;
    this.compExtLInValidReason=null;
    }
}
validateCompExtWForEdit()
{
  const cont_ext_w = String(this.compdetailsedit.cntr_exterior_w).split('.');
  if(this.compdetailsedit.cntr_exterior_w==null || this.compdetailsedit.cntr_exterior_w==0)
  {
    this.compExtWInValid=true;
    this.compExtWInValidReason='Required';
  }
  else if(!String(this.compdetailsedit.cntr_exterior_w).includes('.'))
    {
   if(this.compdetailsedit.cntr_exterior_w<0 || this.compdetailsedit.cntr_exterior_w>999.999 )
        {
          this.compExtWInValid=true;
          this.compExtWInValidReason='Should be of range 0 to 999.999';
        }
        /*else if( String(this.compdetailsedit.cntr_exterior_w).length>7)
        {
          this.compExtWInValid=true;
          this.compExtWInValidReason="Max Length Of Characters can be 7";
        }*/
        else
        {
          this.compExtWInValid=false;
          this.compExtWInValidReason=null;
        }
    }
    else if(String(this.compdetailsedit.cntr_exterior_w).includes('.'))
    {

        if(String(cont_ext_w[0]).length<0 || String(cont_ext_w[0]).length>3)
        {
          this.compExtWInValid=true;
          this.compExtWInValidReason='Should be of range 0 to 999.999';
        }
        else if(this.compdetailsedit.cntr_exterior_w<0 || this.compdetailsedit.cntr_exterior_w>999.999 )
        {
          this.compExtWInValid=true;
          this.compExtWInValidReason='Should be of range 0 to 999.999';
        }
        else if(String(cont_ext_w[1]).length<0 || String(cont_ext_w[1]).length>3)
        {
          this.compExtWInValid=true;
          this.compExtWInValidReason='Max Length Of decimal values can be 3 ';
        }
        else
        {
          this.compExtWInValid=false;
          this.compExtWInValidReason=null;
      }
    }
    else
    {
    this.compExtWInValid=false;
    this.compExtWInValidReason=null;
    }
}
validateCompExtHForEdit()
{
  const cont_ext_h = String(this.compdetailsedit.cntr_exterior_h).split('.');
  if(this.compdetailsedit.cntr_exterior_h==null || this.compdetailsedit.cntr_exterior_h==0)
  {
    this.compExtHInValid=true;
    this.compExtHInValidReason='Required';
  }
  else if(!String(this.compdetailsedit.cntr_exterior_h).includes('.'))
  {
  if(this.compdetailsedit.cntr_exterior_h<0 || this.compdetailsedit.cntr_exterior_h>999.999 )
      {
        this.compExtHInValid=true;
        this.compExtHInValidReason='Should be of range 0 to 999.999';
      }
      /*else if( String(this.compdetailsedit.cntr_exterior_h).length>7)
      {
        this.compExtHInValid=true;
        this.compExtHInValidReason="Max Length Of Characters can be 7";
      }*/
      else
      {
        this.compExtHInValid=false;
        this.compExtHInValidReason=null;
      }
  }
  else if(String(this.compdetailsedit.cntr_exterior_h).includes('.'))
  {

      if(String(cont_ext_h[0]).length<0 || String(cont_ext_h[0]).length>3)
      {
        this.compExtHInValid=true;
        this.compExtHInValidReason='Should be of range 0 to 999.999';
      }
      else if(this.compdetailsedit.cntr_exterior_h<0 || this.compdetailsedit.cntr_exterior_h>999.999 )
      {
        this.compExtHInValid=true;
        this.compExtHInValidReason='Should be of range 0 to 999.999';
      }
      else if(String(cont_ext_h[1]).length<0 || String(cont_ext_h[1]).length>3)
      {
        this.compExtHInValid=true;
        this.compExtHInValidReason='Max Length Of decimal values can be 3';
      }
      else
      {
        this.compExtHInValid=false;
        this.compExtHInValidReason=null;
    }
  }
  else
  {
  this.compExtHInValid=false;
  this.compExtHInValidReason=null;
  }
}



validateCompWareHourseStckHtForEdit()
{
  if(this.compdetailsedit.warehouse_stack_ht==null || this.compdetailsedit.warehouse_stack_ht==0)
  {
    this.wareHourseStackHtCompInvaid=true;
    this.wareHourseStackHtCompInvaidReason='Required';
  }
  else if(this.compdetailsedit.warehouse_stack_ht<1 || this.compdetailsedit.warehouse_stack_ht>99 || String(this.compdetailsedit.warehouse_stack_ht).includes('.'))
  {
    this.wareHourseStackHtCompInvaid=true;
    this.wareHourseStackHtCompInvaidReason='Should be of range 1 to 99 and cannot contain Decimal';
  }
  else
  {
    this.wareHourseStackHtCompInvaid=false;
    this.wareHourseStackHtCompInvaidReason=null;
  }
}




save_editcompdetails()
{
  console.log('in save_editcompdetails',this.compdetailsedit);

    this.compdetailsedit.extr_cntr_dsgn_img_ind='No';
    this.compdetailsedit.intr_cntr_dsgn_img_ind='No';
    this.compdetailsedit.shpg_unit_img_ind='No';
    this.compdetailsedit.cad_img_ind='No';

    this.compdetailsedit.cd_plant=this.editSelectedPlantCodeComp.plantGSDBCode.trim();
    this.compdetailsedit.id_user=this.transactionService.getcdsid();
    this.compdetailsedit.req_stat='New';
    this.compdetailsedit.ts_last_update=this.date.transform(new Date(),'yyyy-MM-dd');
    // this.compdetailsedit.ownrshp_catg="Ford"

    this.compdetailsedit.cntr_grp_desc='';
    this.compdetailsedit.cntr_matr_desc=this.edit_selected_cntr_material_comp.cntr_matr_name;
    this.compdetailsedit.cntr_mstr_catg=this.edit_selected_cntr_master_category_comp.cntr_mstr_catg;
    this.compdetailsedit.cntr_typ_desc=String(this.edit_selected_cntr_type_code_comp.cd_cntr_typ);
    this.compdetailsedit.typ_no=this.edit_selected_cntr_type_code_comp.typ_no;
    this.compdetailsedit.currency_desc=this.edit_selected_cntr_currency_list_comp.curr_desc;
    this.cntrdetailsedit.ownrshp_catg=this.edit_selected_ownrship_catg.ownrshp_catg_val;
    this.compdetailsedit.ownrshp_strat_code=this.edit_selected_cntr_ownrship_strat_code_comp.ownrshp_strat_code;


    this.compdetailsedit.ford_rntl_dly_cost=0;
    this.compdetailsedit.purchase_cost=0;
    this.compdetailsedit.rental_issue_cost=0;
    this.compdetailsedit.return_rntl_cost=0;
    this.compdetailsedit.sup_rntl_dly_cost=0;
    this.compdetailsedit.tooling_dev_cost=0;


    this.compdetailsedit.collapsed_ht=1;
    this.compdetailsedit.nest_ratio_1=1;
    this.compdetailsedit.nest_ratio=1;
    this.compdetailsedit.shpg_unit_stack_ht=1;

    if(this.compdetailsedit.cntr_desc != null)
    {
    this.compdetailsedit.cntr_desc=this.compdetailsedit.cntr_desc.trim();
    }

    if(this.compdetailsedit.img_notes != null)
    {
      this.compdetailsedit.img_notes=this.compdetailsedit.img_notes.trim();
    }
    if(this.compdetailsedit.ford_cntct != null)
    {
    this.compdetailsedit.ford_cntct=this.compdetailsedit.ford_cntct.trim();
    }
    if(this.compdetailsedit.cntct_phone != null)
    {
    this.compdetailsedit.cntct_phone=this.compdetailsedit.cntct_phone.trim();
    }


    if(this.unitom=='E')
    {
      this.compdetailsedit.UoM='E';
      this.edit_measure='lbs/inch';
    this.prev_edit_measure='lbs/inch';
    }
    else{
      this.compdetailsedit.UoM='M';
      this.edit_measure='kg/cm';
    this.prev_edit_measure='kg/cm';
    }


   /* if(this.compdetailsedit.iscontainercolapsble!=null && this.compdetailsedit.iscontainercolapsble.toLowerCase()=='n')
    {
      this.compdetailsedit.collapsed_ht=0;
    }*/
  //   if(this.edit_measure=='kg/cm')
  // {

   /* if(this.compdetailsedit.collapsed_ht!=null)
    {
        this.compdetailsedit.collapsed_ht=Math.round((0.393701*this.compdetailsedit.collapsed_ht)*1000)/1000;
    }
    if(this.compdetailsedit.cntr_interior_l!=null)
    {
        this.compdetailsedit.cntr_interior_l=Math.round((0.393701*this.compdetailsedit.cntr_interior_l)*1000)/1000;
    }
    if(this.compdetailsedit.cntr_interior_w!=null)
    {
            this.compdetailsedit.cntr_interior_w=Math.round((0.393701*this.compdetailsedit.cntr_interior_w)*1000)/1000;
    }
    if(this.compdetailsedit.cntr_interior_h!=null)
    {
            this.compdetailsedit.cntr_interior_h=Math.round((0.393701*this.compdetailsedit.cntr_interior_h)*1000)/1000;
    }
    if(this.compdetailsedit.cntr_tare_wt!=null)
    {
            this.compdetailsedit.cntr_tare_wt=Math.round((2.20462*this.compdetailsedit.cntr_tare_wt)*1000)/1000;
    }
    if(this.compdetailsedit.cntr_capacity!=null)
    {
            this.compdetailsedit.cntr_capacity=Math.round((0.393701*0.393701*0.393701*this.compdetailsedit.cntr_capacity)*1000)/1000;
    }

    if(this.compdetailsedit.cntr_exterior_l!=null)
    {
             this.compdetailsedit.cntr_exterior_l=Math.round((0.393701*this.compdetailsedit.cntr_exterior_l)*1000)/1000;
    }
    if(this.compdetailsedit.cntr_exterior_w!=null)
    {
            this.compdetailsedit.cntr_exterior_w=Math.round((0.393701*this.compdetailsedit.cntr_exterior_w)*1000)/1000;
    }
    if(this.compdetailsedit.cntr_exterior_h!=null)
    {
            this.compdetailsedit.cntr_exterior_h=Math.round((0.393701*this.compdetailsedit.cntr_exterior_h)*1000)/1000;
    }
    if(this.compdetailsedit.shpg_unit_exterior_l!=null)
    {
           this.compdetailsedit.shpg_unit_exterior_l=Math.round((0.393701*this.compdetailsedit.shpg_unit_exterior_l)*1000)/1000;
    }
    if(this.compdetailsedit.shpg_unit_exterior_w!=null)
    {
            this.compdetailsedit.shpg_unit_exterior_w=Math.round((0.393701*this.compdetailsedit.shpg_unit_exterior_w)*1000)/1000;
    }
    if(this.compdetailsedit.shpg_unit_exterior_h!=null)
    {
            this.compdetailsedit.shpg_unit_exterior_h=Math.round((0.393701*this.compdetailsedit.shpg_unit_exterior_h)*1000)/1000;
    }
    if(this.compdetailsedit.shpg_unit_nstdorflded_ht!=null)
    {
            this.compdetailsedit.shpg_unit_nstdorflded_ht=Math.round((0.393701*this.compdetailsedit.shpg_unit_nstdorflded_ht)*1000)/1000;
    }
    if(this.compdetailsedit.shpg_unit_tare_wt!=null)
    {
            this.compdetailsedit.shpg_unit_tare_wt=Math.round((2.20462*this.compdetailsedit.shpg_unit_tare_wt)*1000)/1000;
    }
    if(this.compdetailsedit.shpg_unit_stack_ht!=null)
    {
            this.compdetailsedit.shpg_unit_stack_ht=Math.round((0.393701*this.compdetailsedit.shpg_unit_stack_ht)*1000)/1000;
    }
    if(this.compdetailsedit.warehouse_stack_ht!=null)
    {
            this.compdetailsedit.warehouse_stack_ht=Math.round((0.393701*this.compdetailsedit.warehouse_stack_ht)*1000)/1000;
    }
    */
  //  this.compdetailsedit.UoM="M"

  // }
  // else
  // {
  //   this.compdetailsedit.UoM="E"
  // }

  if(this.compdetailsedit.service_life==null)
  {
    this.compdetailsedit.service_life='';
  }


  if(this.compdetailsedit.cntr_drawing_no == null)
  {
    this.compdetailsedit.cntr_drawing_no=''
  }

/*
  console.log(this.compdetailsedit)

 if(this.componentsAssociatedToContainerForPost != null && this.componentsAssociatedToContainerForPost.length != 0)
 {
  this.compdetailsedit.components=[];
  this.compdetailsedit.components.push(...this.componentsAssociatedToContainerForPost);
 }*/

 console.log('components appended container',this.compdetailsedit);




 this.validateCompBaseForEdit()
 this.validateCompDescForEdit()
 // this.validateCompPlantCodeForEdit()
 this.validateCompMastCatgForEdit()
 this.validateCompRetTypeForEdit()
 this.validateCompTypeCodeForEdit()
 this.validateCompMaterialForEdit()
 this.validateCompCOSForEdit()
 this.validateCompOwnershpCatgForEdit()
 // this.validateCompGrpForEdit()
 this.validateCompContactEdit()
 this.validateCompContactPhoneEdit()
 this.validateCompActiveEdit()
 this.validateCompTareWeightForEdit()
 this.validateCompCapWtForEdit()
 this.validateCompQtPerMinSuForEdit()
 this.validateCompExtLForEdit()
 this.validateCompExtWForEdit()
 this.validateCompExtHForEdit()
 this.validateCompWareHourseStckHtForEdit()

 if(
   !this.compTareWtInValid && !this.compCapWtInValid &&
    !this.compQtPerMinSuInValid &&
   !this.compExtLInValid && !this.compExtWInValid && !this.compExtHInValid &&
   !this.wareHourseStackHtCompInvaid &&
    !this.compBaseInvalid && !this.compDescInvalid && !this.plantCodeCompInvalid &&
    !this.mastrCatgCompInvalid && !this.retTypeCompInvalid && !this.compTypeInvalid &&
   !this.compMaterialInvalid && !this.cosCompInvalid && !this.compActiveInvalid && !this.compCntctInvalid && !this.cntctPhoneCompInvalid && !this.compOwnerShpCatgInvalid)
 {



          // Not:********* if UOM field is not always E while comparing do measurement conversion and compare to check if any cmms fields changed or not

          this.compCmmsFieldsAfterUpdate.cntr_base=JSON.parse(JSON.stringify(this.compdetailsedit.cntr_base))
          this.compCmmsFieldsAfterUpdate.cntr_suffix=JSON.parse(JSON.stringify(this.compdetailsedit.cntr_suffix))
          this.compCmmsFieldsAfterUpdate.cntr_desc=JSON.parse(JSON.stringify(this.compdetailsedit.cntr_desc))
          this.compCmmsFieldsAfterUpdate.cntr_typ_desc=JSON.parse(JSON.stringify(this.compdetailsedit.cntr_typ_desc))
          this.compCmmsFieldsAfterUpdate.cntr_exterior_l=JSON.parse(JSON.stringify(this.compdetailsedit.cntr_exterior_l))
          this.compCmmsFieldsAfterUpdate.cntr_exterior_w=JSON.parse(JSON.stringify(this.compdetailsedit.cntr_exterior_w))
          this.compCmmsFieldsAfterUpdate.cntr_exterior_h=JSON.parse(JSON.stringify(this.compdetailsedit.cntr_exterior_h))
          this.compCmmsFieldsAfterUpdate.cntr_capacity=JSON.parse(JSON.stringify(this.compdetailsedit.cntr_capacity))
          this.compCmmsFieldsAfterUpdate.cntr_tare_wt=JSON.parse(JSON.stringify(this.compdetailsedit.cntr_tare_wt))
          this.compCmmsFieldsAfterUpdate.warehouse_stack_ht=JSON.parse(JSON.stringify(this.compdetailsedit.warehouse_stack_ht))
          this.compCmmsFieldsAfterUpdate.cd_plant=JSON.parse(JSON.stringify(this.compdetailsedit.cd_plant))
          this.compCmmsFieldsAfterUpdate.ford_cntct=JSON.parse(JSON.stringify(this.compdetailsedit.ford_cntct))
          this.compCmmsFieldsAfterUpdate.cntct_phone=JSON.parse(JSON.stringify(this.compdetailsedit.cntct_phone))
          this.compCmmsFieldsAfterUpdate.cntr_qt_per_min_su=JSON.parse(JSON.stringify(this.compdetailsedit.cntr_qt_per_min_su))
          this.compCmmsFieldsAfterUpdate.ownrshp_catg=JSON.parse(JSON.stringify(this.compdetailsedit.ownrshp_catg))
      
      
          console.log("cmms before update comp",this.compCmmsFieldsBeforeUpdate);
          console.log("cmms after update comp",this.compCmmsFieldsAfterUpdate);
          console.log("edit comp details", this.compdetailsedit);
      
      
                  var cmmsFieldsChanged=false;
                  const keys1 = Object.keys(this.compCmmsFieldsBeforeUpdate);
                  for (const key of keys1) {
                  if((typeof(this.compCmmsFieldsBeforeUpdate[key]) == 'number'  && (this.compCmmsFieldsBeforeUpdate[key] !== this.compCmmsFieldsAfterUpdate[key])) ||
                    (typeof(this.compCmmsFieldsBeforeUpdate[key]) == 'string'  && (this.compCmmsFieldsBeforeUpdate[key].trim() !== this.compCmmsFieldsAfterUpdate[key].trim())) )
                   {
                    cmmsFieldsChanged=true
                  }
                }

                console.log('cmms field vlaue change',cmmsFieldsChanged)
                   this.compAdmindirectSaveinit();

                if(cmmsFieldsChanged == false)
                {


                  // api integration for direct save to cntr admin


                  console.log('comp admin direct save edit')
                  console.log('cd_cntr',this.edit_comp_cd_cntr)

                  if(this.edit_comp_cd_cntr != 0 && this.compdetailsedit != null)
                  {
                    // service to call api to push data
                    this.saveEditComponentCntrAdmin.cd_cntr=this.edit_comp_cd_cntr;
                    this.saveEditComponentCntrAdmin.cntr_base=this.compdetailsedit.cntr_base;
                    this.saveEditComponentCntrAdmin.cntr_suff_ind= this.compdetailsedit.cntr_suff_ind;
                    this.saveEditComponentCntrAdmin.exp_refundable=this.compdetailsedit.exp_refundable;
                    this.saveEditComponentCntrAdmin.cntr_active_ind=this.compdetailsedit.cntr_active_ind
                    this.saveEditComponentCntrAdmin.cntr_suffix=this.compdetailsedit.cntr_suffix;
                    this.saveEditComponentCntrAdmin.cntr_desc=this.compdetailsedit.cntr_desc;
                    this.saveEditComponentCntrAdmin.card_call=this.compdetailsedit.card_call
                    this.saveEditComponentCntrAdmin.cntr_mstr_catg=this.compdetailsedit.cntr_mstr_catg;
                    this.saveEditComponentCntrAdmin.cntr_typ_desc=this.compdetailsedit.cntr_typ_desc;
                    this.saveEditComponentCntrAdmin.ownrshp_strat_code=this.compdetailsedit.ownrshp_strat_code;
                    this.saveEditComponentCntrAdmin.ownrshp_catg=this.compdetailsedit.ownrshp_catg;
                    this.saveEditComponentCntrAdmin.cntr_grp_desc=this.compdetailsedit.cntr_grp_desc;
                    this.saveEditComponentCntrAdmin.cntr_matr_desc=this.compdetailsedit.cntr_matr_desc;
                    this.saveEditComponentCntrAdmin.currency_desc=this.compdetailsedit.currency_desc;
                    this.saveEditComponentCntrAdmin.iscontainercolapsble=this.compdetailsedit.iscontainercolapsble;
                    this.saveEditComponentCntrAdmin.cntr_exterior_l=this.compdetailsedit.cntr_exterior_l;
                    this.saveEditComponentCntrAdmin.cntr_exterior_w=this.compdetailsedit.cntr_exterior_w;
                    this.saveEditComponentCntrAdmin.cntr_exterior_h=this.compdetailsedit.cntr_exterior_h;
                    this.saveEditComponentCntrAdmin.collapsed_ht=this.compdetailsedit.collapsed_ht;
                    this.saveEditComponentCntrAdmin.nest_ratio=this.compdetailsedit.nest_ratio;
                    this.saveEditComponentCntrAdmin.cntr_interior_l=this.compdetailsedit.cntr_interior_l;
                    this.saveEditComponentCntrAdmin.cntr_interior_w=this.compdetailsedit.cntr_interior_w;
                    this.saveEditComponentCntrAdmin.cntr_interior_h=this.compdetailsedit.cntr_interior_h;
                    this.saveEditComponentCntrAdmin.cntr_tare_wt=this.compdetailsedit.cntr_tare_wt;
                    this.saveEditComponentCntrAdmin.cntr_capacity=this.compdetailsedit.cntr_capacity;
                    this.saveEditComponentCntrAdmin.cntr_per_layer=this.compdetailsedit.cntr_per_layer;
                    this.saveEditComponentCntrAdmin.max_layers_shpg_unit=this.compdetailsedit.max_layers_shpg_unit;
                    this.saveEditComponentCntrAdmin.max_cntr_per_shpg_unit=this.compdetailsedit.max_cntr_per_shpg_unit;
                    this.saveEditComponentCntrAdmin.cntr_drawing_no=this.compdetailsedit.cntr_drawing_no;
                    this.saveEditComponentCntrAdmin.shpg_unit_exterior_l=this.compdetailsedit.shpg_unit_exterior_l;
                    this.saveEditComponentCntrAdmin.shpg_unit_exterior_w=this.compdetailsedit.shpg_unit_exterior_w;
                    this.saveEditComponentCntrAdmin.shpg_unit_exterior_h=this.compdetailsedit.shpg_unit_exterior_h;
                    this.saveEditComponentCntrAdmin.shpg_unit_nstdorflded_ht=this.compdetailsedit.shpg_unit_nstdorflded_ht;
                    this.saveEditComponentCntrAdmin.shpg_unit_tare_wt=this.compdetailsedit.shpg_unit_tare_wt;
                    this.saveEditComponentCntrAdmin.shpg_unit_stack_ht=this.compdetailsedit.shpg_unit_stack_ht;
                    this.saveEditComponentCntrAdmin.warehouse_stack_ht=this.compdetailsedit.warehouse_stack_ht;
                    this.saveEditComponentCntrAdmin.service_life=this.compdetailsedit.service_life;
                    this.saveEditComponentCntrAdmin.purchase_cost=this.compdetailsedit.purchase_cost;
                    this.saveEditComponentCntrAdmin.rental_issue_cost=this.compdetailsedit.rental_issue_cost;
                    this.saveEditComponentCntrAdmin.sup_rntl_dly_cost=this.compdetailsedit.sup_rntl_dly_cost;
                    this.saveEditComponentCntrAdmin.ford_rntl_dly_cost=this.compdetailsedit.ford_rntl_dly_cost;
                    this.saveEditComponentCntrAdmin.return_rntl_cost=this.compdetailsedit.return_rntl_cost;
                    this.saveEditComponentCntrAdmin.tooling_dev_cost=this.compdetailsedit.tooling_dev_cost;
                    this.saveEditComponentCntrAdmin.extr_cntr_dsgn_img_ind=this.compdetailsedit.extr_cntr_dsgn_img_ind;
                    this.saveEditComponentCntrAdmin.intr_cntr_dsgn_img_ind=this.compdetailsedit.intr_cntr_dsgn_img_ind;
                    this.saveEditComponentCntrAdmin.shpg_unit_img_ind=this.compdetailsedit.shpg_unit_img_ind
                    this.saveEditComponentCntrAdmin.cad_img_ind=this.compdetailsedit.cad_img_ind;
                    this.saveEditComponentCntrAdmin.img_notes=this.compdetailsedit.img_notes;
                    this.saveEditComponentCntrAdmin.cd_region=this.compdetailsedit.cd_region;
                    this.saveEditComponentCntrAdmin.cd_plant=this.compdetailsedit.cd_plant;
                    this.saveEditComponentCntrAdmin.cnt_qt_per_min_su=this.compdetailsedit.cntr_qt_per_min_su;
                    this.saveEditComponentCntrAdmin.nest_ratio_1=this.compdetailsedit.nest_ratio_1;
                    this.saveEditComponentCntrAdmin.ford_cntct=this.compdetailsedit.ford_cntct;
                    this.saveEditComponentCntrAdmin.cntct_phone=this.compdetailsedit.cntct_phone;
                    this.saveEditComponentCntrAdmin.uom=this.compdetailsedit.UoM;
                    this.saveEditComponentCntrAdmin.id_user= this.compdetailsedit.id_user
                    this.saveEditComponentCntrAdmin.req_stat=this.compdetailsedit.req_stat;
                    this.saveEditComponentCntrAdmin.ts_last_update=this.compdetailsedit.ts_last_update;
                    this.saveEditComponentCntrAdmin.typ_no=this.compdetailsedit.typ_no;



                    console.log('save direct to coomponent admin post data', this.saveEditComponentCntrAdmin)

                    const saveEditComponentCntrAdminArray=[];
                    saveEditComponentCntrAdminArray.push(this.saveEditComponentCntrAdmin);


                    // service call api


                    this.transactionService.cntrAdminSaveCntrDetails(saveEditComponentCntrAdminArray).subscribe(cntr_save_res=>{
                      console.log('direct cntr admin save edit',cntr_save_res)
                      if(cntr_save_res.toLowerCase() == 'success')
                      {
                        this.messageService.add({severity:'success', summary:'Success', detail:'Component Edited Successfully'});
                        this.enableeditcompdetails=false;
                        this.compenableeditgetdetails=false;
                        this.editing=false;
                        this.validationCompInit();
                        this.compAddEnable=true;
                        // this.componentsAssociatedToContainerForPost=[];
                        // Commented because details should not be shown until the record gets updated in cmms
                        // this.geteditedcntrdetails(this.cntrdetailsedit);
                        this.DisplayGridComp(this.current_region_code_prg);
                      }


                      else
                      {
                          this.messageService.add({severity:'error', summary: 'Error', detail: 'Component Not Edited,Please Try Again'});
                          // this.addplantdetailsinit();
                          this.validationCompInit();
                      /*  if(this.edit_measure=='kg/cm')
                        {
                          if(this.compdetailsedit.collapsed_ht!=null)
                          {
                              this.compdetailsedit.collapsed_ht=Math.round((2.54*this.compdetailsedit.collapsed_ht)*1000)/1000;
                          }
                          if(this.compdetailsedit.cntr_interior_l!=null)
                          {
                              this.compdetailsedit.cntr_interior_l=Math.round((2.54*this.compdetailsedit.cntr_interior_l)*1000)/1000;
                          }
                          if(this.compdetailsedit.cntr_interior_w!=null)
                          {
                                  this.compdetailsedit.cntr_interior_w=Math.round((2.54*this.compdetailsedit.cntr_interior_w)*1000)/1000;
                          }
                          if(this.compdetailsedit.cntr_interior_h!=null)
                          {
                                  this.compdetailsedit.cntr_interior_h=Math.round((2.54*this.compdetailsedit.cntr_interior_h)*1000)/1000;
                          }
                          if(this.compdetailsedit.cntr_tare_wt!=null)
                          {
                                  this.compdetailsedit.cntr_tare_wt=Math.round((0.453592*this.compdetailsedit.cntr_tare_wt)*1000)/1000;
                          }
                          if(this.compdetailsedit.cntr_capacity!=null)
                          {
                                  this.compdetailsedit.cntr_capacity=Math.round((2.54*2.54*2.54*this.compdetailsedit.cntr_capacity)*1000)/1000;
                          }

                          if(this.compdetailsedit.cntr_exterior_l!=null)
                          {
                                  this.compdetailsedit.cntr_exterior_l=Math.round((2.54*this.compdetailsedit.cntr_exterior_l)*1000)/1000;
                          }
                          if(this.compdetailsedit.cntr_exterior_w!=null)
                          {
                                  this.compdetailsedit.cntr_exterior_w=Math.round((2.54*this.compdetailsedit.cntr_exterior_w)*1000)/1000;
                          }
                          if(this.compdetailsedit.cntr_exterior_h!=null)
                          {
                                  this.compdetailsedit.cntr_exterior_h=Math.round((2.54*this.compdetailsedit.cntr_exterior_h)*1000)/1000;
                          }
                          if(this.compdetailsedit.shpg_unit_exterior_l!=null)
                          {
                                this.compdetailsedit.shpg_unit_exterior_l=Math.round((2.54*this.compdetailsedit.shpg_unit_exterior_l)*1000)/1000;
                          }
                          if(this.compdetailsedit.shpg_unit_exterior_w!=null)
                          {
                                  this.compdetailsedit.shpg_unit_exterior_w=Math.round((2.54*this.compdetailsedit.shpg_unit_exterior_w)*1000)/1000;
                          }
                          if(this.compdetailsedit.shpg_unit_exterior_h!=null)
                          {
                                  this.compdetailsedit.shpg_unit_exterior_h=Math.round((2.54*this.compdetailsedit.shpg_unit_exterior_h)*1000)/1000;
                          }
                          if(this.compdetailsedit.shpg_unit_nstdorflded_ht!=null)
                          {
                                  this.compdetailsedit.shpg_unit_nstdorflded_ht=Math.round((2.54*this.compdetailsedit.shpg_unit_nstdorflded_ht)*1000)/1000;
                          }
                          if(this.compdetailsedit.shpg_unit_tare_wt!=null)
                          {
                                  this.compdetailsedit.shpg_unit_tare_wt=Math.round((0.453592*this.compdetailsedit.shpg_unit_tare_wt)*1000)/1000;
                          }
                          if(this.compdetailsedit.shpg_unit_stack_ht!=null)
                          {
                                  this.compdetailsedit.shpg_unit_stack_ht=Math.round((2.54*this.compdetailsedit.shpg_unit_stack_ht)*1000)/1000;
                          }
                          if(this.compdetailsedit.warehouse_stack_ht!=null)
                          {
                                  this.compdetailsedit.warehouse_stack_ht=Math.round((2.54*this.compdetailsedit.warehouse_stack_ht)*1000)/1000;
                          }
                        }*/
                      }

                    })



                  }


                }
                else
                {


                  console.log('dbia save edit')

             this.transactionService.edit_savecntrdetails(this.compdetailsedit).subscribe(savecntr=>
              {
                const res=savecntr;
                console.log('successful',res);
                if(res.toLowerCase() == 'success')
                {
                  this.messageService.add({severity:'success', summary:'Success', detail:'Component Edited Successfully'});
                  this.enableeditcompdetails=false;
                  this.compenableeditgetdetails=false;
                  this.editing=false;
                  this.validationCompInit();
                  this.compAddEnable=true;
                  // this.componentsAssociatedToContainerForPost=[];
                  // Commented because details should not be shown until the record gets updated in cmms
                  // this.geteditedcntrdetails(this.cntrdetailsedit);
                  this.DisplayGridComp(this.current_region_code_prg);
                }
                else
                {
                    this.messageService.add({severity:'error', summary: 'Error', detail: 'Component Not Edited,Please Try Again'});
                    // this.addplantdetailsinit();
                    this.validationCompInit();
                  /*  if(this.edit_measure=='kg/cm')
                    {
                      if(this.compdetailsedit.collapsed_ht!=null)
                      {
                          this.compdetailsedit.collapsed_ht=Math.round((2.54*this.compdetailsedit.collapsed_ht)*1000)/1000;
                      }
                      if(this.compdetailsedit.cntr_interior_l!=null)
                      {
                          this.compdetailsedit.cntr_interior_l=Math.round((2.54*this.compdetailsedit.cntr_interior_l)*1000)/1000;
                      }
                      if(this.compdetailsedit.cntr_interior_w!=null)
                      {
                              this.compdetailsedit.cntr_interior_w=Math.round((2.54*this.compdetailsedit.cntr_interior_w)*1000)/1000;
                      }
                      if(this.compdetailsedit.cntr_interior_h!=null)
                      {
                              this.compdetailsedit.cntr_interior_h=Math.round((2.54*this.compdetailsedit.cntr_interior_h)*1000)/1000;
                      }
                      if(this.compdetailsedit.cntr_tare_wt!=null)
                      {
                              this.compdetailsedit.cntr_tare_wt=Math.round((0.453592*this.compdetailsedit.cntr_tare_wt)*1000)/1000;
                      }
                      if(this.compdetailsedit.cntr_capacity!=null)
                      {
                              this.compdetailsedit.cntr_capacity=Math.round((2.54*2.54*2.54*this.compdetailsedit.cntr_capacity)*1000)/1000;
                      }

                      if(this.compdetailsedit.cntr_exterior_l!=null)
                      {
                              this.compdetailsedit.cntr_exterior_l=Math.round((2.54*this.compdetailsedit.cntr_exterior_l)*1000)/1000;
                      }
                      if(this.compdetailsedit.cntr_exterior_w!=null)
                      {
                              this.compdetailsedit.cntr_exterior_w=Math.round((2.54*this.compdetailsedit.cntr_exterior_w)*1000)/1000;
                      }
                      if(this.compdetailsedit.cntr_exterior_h!=null)
                      {
                              this.compdetailsedit.cntr_exterior_h=Math.round((2.54*this.compdetailsedit.cntr_exterior_h)*1000)/1000;
                      }
                      if(this.compdetailsedit.shpg_unit_exterior_l!=null)
                      {
                            this.compdetailsedit.shpg_unit_exterior_l=Math.round((2.54*this.compdetailsedit.shpg_unit_exterior_l)*1000)/1000;
                      }
                      if(this.compdetailsedit.shpg_unit_exterior_w!=null)
                      {
                              this.compdetailsedit.shpg_unit_exterior_w=Math.round((2.54*this.compdetailsedit.shpg_unit_exterior_w)*1000)/1000;
                      }
                      if(this.compdetailsedit.shpg_unit_exterior_h!=null)
                      {
                              this.compdetailsedit.shpg_unit_exterior_h=Math.round((2.54*this.compdetailsedit.shpg_unit_exterior_h)*1000)/1000;
                      }
                      if(this.compdetailsedit.shpg_unit_nstdorflded_ht!=null)
                      {
                              this.compdetailsedit.shpg_unit_nstdorflded_ht=Math.round((2.54*this.compdetailsedit.shpg_unit_nstdorflded_ht)*1000)/1000;
                      }
                      if(this.compdetailsedit.shpg_unit_tare_wt!=null)
                      {
                              this.compdetailsedit.shpg_unit_tare_wt=Math.round((0.453592*this.compdetailsedit.shpg_unit_tare_wt)*1000)/1000;
                      }
                      if(this.compdetailsedit.shpg_unit_stack_ht!=null)
                      {
                              this.compdetailsedit.shpg_unit_stack_ht=Math.round((2.54*this.compdetailsedit.shpg_unit_stack_ht)*1000)/1000;
                      }
                      if(this.compdetailsedit.warehouse_stack_ht!=null)
                      {
                              this.compdetailsedit.warehouse_stack_ht=Math.round((2.54*this.compdetailsedit.warehouse_stack_ht)*1000)/1000;
                      }
                    }*/
                }

             })
        }
      }

}


cancel_editcompdetails()
  {
       this.enableeditcompdetails=false;
       this.compAddEnable=true;
       this.editing=false;
       this.editcompdetailsinit();
       this.validationCompInit();

  }

  cancel_edit_comp_get_details(){
    this.compenableeditgetdetails=false;
    this.editing=false;
this.compenabledetails=true;
    this.editcompdetailsinit();
    this.validationCompInit();
  }


  /* on clicking the container number from grid */

  getcompdetails_grid(containerdetailsfromgrid:any)
  {
    this.compenabledetails=true;
    this.compdetailsinit();

    if(this.unitom=='E')
    {
      this.compdetails.uom='E';
      this.get_measure='lbs/inch';
    this.prev_get_measure='lbs/inch';
    }
    else{
      this.compdetails.uom='M';
      this.get_measure='kg/cm';
    this.prev_get_measure='kg/cm';
    }

this.compdetailsedit.exp_refundable='Returnable';
    // this.get_measure='lbs/inch';
    // this.prev_get_measure='lbs/inch';

    this.get_selected_cntr_master_category_comp={cd_cntr_mstr_catg:0,cntr_mstr_catg_desc:'',updated_by:'',updated_dt:'',cntr_mstr_catg:'',active_stat:'',active_stat_display:false};
    this.get_selected_cntr_type_code_comp={cd_cntr_typ:'',cntr_typ_desc:'',typ_no:0};
    this.get_selected_cntr_ownrship_strat_code_comp={cd_region:'',ownrshp_strat_code:'',ownrshp_desc:'',cd_ownrshp_strat:0};
    this.get_selected_ownrship_catg_comp={ownrshp_catg:'',ownrshp_catg_val:''};
    this.get_selected_cntr_group_comp={cntr_grp:''}
    this.get_selected_cntr_material_comp={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};
    this.get_selected_cntr_currency_list_comp={cd_curr:0,curr_desc:''};

    if(this.compenableeditgetdetails==true)
    {
      this.compenableeditgetdetails=false;

    }
    if(this.enableeditcompdetails==true)
    {
      this.enableeditcompdetails=false;
    }
    this.editing=false;

    if(this.compAddEnable==true)
    {
      this.compAddEnable=false;
    }

    if(this.compAddDetailsEnable==true)
    {
      this.compAddDetailsEnable=false;
    }

    this.transactionService.editbutton_get_contr_details(containerdetailsfromgrid.cntr_base,containerdetailsfromgrid.cd_cntr).subscribe((getcntrdetails=>{
      this.compdetails=getcntrdetails;
      this.validationCompInit();

     /* if(String(this.cntrdetails.cntr_typ_desc)=="01")
      {
        this.containerOrComponentInDetails="container";
      }
      else
      {
        this.containerOrComponentInDetails="component";
      }*/

      console.log('cntrdetails',this.compdetails)

      for(let i=0;i<this.cntrMasterCategory.length;i++)
      {
        if(this.compdetails.cntr_mstr_catg==this.cntrMasterCategory[i].cntr_mstr_catg)
        {
          this.get_selected_cntr_master_category_comp=this.cntrMasterCategory[i];
        }
      }

      for(let i=0;i<this.unmodifiedTypeCodes.length;i++)
      {
        if(this.compdetails.typ_no != null && this.compdetails.typ_no==this.unmodifiedTypeCodes[i].typ_no)
        {
          this.get_selected_cntr_type_code_comp=this.unmodifiedTypeCodes[i];
          console.log('edit_selected_cntr_type_code',this.edit_selected_cntr_type_code_comp);
        }
      }

      for(let i=0;i<this.cntrOwnershipstratcode.length;i++)
      {
        if(this.compdetails.ownrshp_strat_code==this.cntrOwnershipstratcode[i].ownrshp_strat_code)
        {
          this.get_selected_cntr_ownrship_strat_code_comp=this.cntrOwnershipstratcode[i];
        }
      }

      for(let i=0;i<this.ownrship_catg.length;i++)
      {
        if(this.compdetails.ownrshp_catg.toLowerCase().indexOf(this.ownrship_catg[i].ownrshp_catg_val.toLowerCase())>-1)
        {
          this.get_selected_ownrship_catg_comp=this.ownrship_catg[i];
        }
      }

      for(let i=0;i<this.cntrGrp.length;i++)
      {
        if(this.compdetails.cntr_grp_desc==this.cntrGrp[i].cntr_grp)
        {
          this.get_selected_cntr_group_comp=this.cntrGrp[i];
        }
      }

      // for(let i=0;i<this.cntrMaterial.length;i++)
      // {
      //   if(this.cntrdetails.cntr_matr_desc==this.cntrMaterial[i].cntr_matr_name)
      //   {
      //     this.get_selected_cntr_material=this.cntrMaterial[i];
      //   }
      // }

      this.getMaterialValueToDisplayComp();

      for(let i=0;i<this.cntrCurrencyList.length;i++)
      {
        if(this.compdetails.currency_desc==this.cntrCurrencyList[i].curr_desc)
        {
          this.get_selected_cntr_currency_list_comp=this.cntrCurrencyList[i];
        }
      }


      // if(this.compdetails.uom == null || this.compdetails.uom.trim() == "")
      // {
      //   this.compdetails.uom = "E"
      // }

      // if(this.compdetails.uom.toLowerCase() == "m")
      // {

      //   this.get_measure='kg/cm'
      //   this.prev_get_measure='kg/cm'
      // }
      // else
      // {
      //   this.get_measure='lbs/inch'
      //   this.prev_get_measure='lbs/inch'
      // }

      console.log('containerdetailsfromgrid',this.compdetails);



    }))

  }
















  disableAddingComponent() {
      if (!(this.compdetailsadd.cntr_base) ||  !(this.compdetailsadd.cntr_desc) || !(this.selectedPlantListComp) ||
           !(this.selected_cntr_master_category_comp) || !(this.compdetailsadd.exp_refundable) ||
          !(this.selected_cntr_type_code_comp) || !(this.selected_cntr_ownrship_strat_code_comp) ||  !(this.selected_cntr_group_comp) ||
             !(this.compdetailsadd.cntr_tare_wt > 0) ||
          !(this.compdetailsadd.cntr_capacity>0) ||
          !(this.compdetailsadd.ford_cntct) || !(this.compdetailsadd.cntr_qt_per_min_su>0) || !(this.compdetailsadd.cntct_phone) ||
          !(this.compdetailsadd.cntr_exterior_l > 0) || !(this.compdetailsadd.cntr_exterior_w > 0) || !(this.compdetailsadd.cntr_exterior_h > 0) ||
          !(this.compdetailsadd.cntr_active_ind) ||
           !(this.compdetailsadd.warehouse_stack_ht >0)   || !(this.selected_cntr_material_comp.cntr_matr_name!='')
          ||  !(this.selected_ownrship_catg_comp)
  // || !(this.selected_cntr_material)    || !(this.selected_cntr_currency_list)
          ) {
          return true;
      } else {
          return false;
      }


      /* Removing add suffix to container
      * || !((this.cntrSuffixenabled==true && this.cntrdetailsadd.cntr_suffix)||
     (this.cntrSuffixenabled==false && this.cntrdetailsadd.cntr_suffix.trim()==''))
     * */

  }


  disabledSavingInEditModeComponent()
  {


    if (!(this.compdetailsedit.cntr_base) ||  !(this.compdetailsedit.cntr_desc) || !(this.editSelectedPlantCodeComp.plantGSDBCode!='') ||
          !(this.edit_selected_cntr_master_category_comp.cntr_mstr_catg!='') || !(this.compdetailsedit.exp_refundable) ||
          !(String(this.edit_selected_cntr_type_code_comp.cd_cntr_typ)!='') || !(this.edit_selected_cntr_ownrship_strat_code_comp.ownrshp_strat_code!='') ||  !(this.edit_selected_cntr_group_comp.cntr_grp!='') ||

          !(this.compdetailsedit.cntr_tare_wt > 0) ||
          !(this.compdetailsedit.cntr_capacity>0) ||
          !(this.compdetailsedit.ford_cntct) || !(this.compdetailsedit.cntr_qt_per_min_su>0) || !(this.compdetailsedit.cntct_phone) ||
          !(this.compdetailsedit.cntr_exterior_l > 0) || !(this.compdetailsedit.cntr_exterior_w > 0) || !(this.compdetailsedit.cntr_exterior_h > 0) ||
          !(this.compdetailsedit.cntr_active_ind) ||
          !(this.compdetailsedit.warehouse_stack_ht >0)   ||  !(this.edit_selected_cntr_material_comp.cntr_matr_name!='')
          ||  !(this.edit_selected_ownrship_catg_comp)  ||!(this.edit_selected_ownrship_catg_comp.ownrshp_catg_val != '')
        // || !(this.selected_cntr_material)    || !(this.selected_cntr_currency_list)
          )

 /*   if(!this.cntrdetailsedit.cntr_base || !this.cntrdetailsedit.cntr_desc|| !(this.cntrdetailsedit.cntr_per_layer>0) || !(this.cntrdetailsedit.shpg_unit_nstdorflded_ht>0) || !(this.cntrdetailsedit.cntr_tare_wt>0) ||
    !(this.cntrdetailsedit.max_layers_shpg_unit>0) || !(this.cntrdetailsedit.shpg_unit_exterior_l>0) ||
    !(this.cntrdetailsedit.shpg_unit_exterior_w>0) || !(this.cntrdetailsedit.shpg_unit_exterior_h>0) ||
    !(this.cntrdetailsedit.cntr_exterior_l>0) || !(this.cntrdetailsedit.cntr_exterior_w>0) ||
    !(this.cntrdetailsedit.cntr_exterior_h>0) || !(this.cntrdetailsedit.card_call=='Card' || this.cntrdetailsedit.card_call=='Call') || !this.cntrdetailsedit.exp_refundable || (this.edit_selected_cntr_master_category.cntr_mstr_catg=='') ||(this.edit_selected_cntr_type_code.cntr_typ_desc=='') ||
    (this.edit_selected_cntr_ownrship_strat_code.ownrshp_desc=='') || (this.edit_selected_ownrship_catg.ownrshp_catg=='') || (this.edit_selected_cntr_group.cntr_grp=='') || (this.edit_selected_cntr_material.cntr_matr_name=='') || (this.edit_selected_cntr_currency_list.curr_desc=='') ||
    !this.cntrdetailsedit.cntr_active_ind || !this.cntrdetailsedit.iscontainercolapsble ||
    !((this.cntrdetailsedit.iscontainercolapsble=='Y'&& (this.cntrdetailsedit.collapsed_ht>0)) || (this.cntrdetailsedit.iscontainercolapsble=='N')))*/
    {
      return true;
    }
    else
    {
      return false;
    }


    /* Removing add suffix to container
    || !((this.cntrSuffixEditenabled==true && this.cntrdetailsedit.cntr_suffix)||
    (this.cntrSuffixEditenabled==false && this.cntrdetailsedit.cntr_suffix.trim()==''))
     */
  }

  redirect_to_dbia_req_sts()
  {
    const routeid=202;

    console.log(routeid)


    if(this.current_region_prg=='Asia Pacific & Africa')
    {
      sessionStorage.setItem('aprouteid', JSON.stringify(routeid));
    }

    else if(this.current_region_prg=='Europe')
    {
      sessionStorage.setItem('eurouteid', JSON.stringify(routeid));
    }

    else if (this.current_region_prg=='North America')

    {

      sessionStorage.setItem('narouteid', JSON.stringify(routeid));
    }

    else if(this.current_region_prg=='South America')
    {
      sessionStorage.setItem('sarouteid', JSON.stringify(routeid));
    }



    let reg_url:string;
    reg_url=this.transactionService.getregionurlvalue(this.current_region_prg);
    reg_url+='packagingprocess/dbiaadmin';

    this.router.navigate([reg_url]);
  }



}
