/* tslint:disable */
import { Component, OnInit } from '@angular/core';


import {TransactionService} from '../loginpage/Transaction.service';
import { NavComponent } from '../nav/nav.component';

@Component({
  selector: 'app-packdesignsourceadmin',
  templateUrl: './packdesignsourceadmin.component.html',
  styleUrls: ['./packdesignsourceadmin.component.css']
})
export class PackdesignsourceadminComponent implements OnInit {

current_roleid:number;
region:string;


region_code='';
cdsid='';

  constructor(private transactionService: TransactionService,private navigation:NavComponent) { }

  ngOnInit(): void {


    this.current_roleid=2
    this.region=this.transactionService.getregion();
    this.cdsid=this.transactionService.getcdsid();
    this.region_code=this.transactionService.getregioncode(this.region);
    if(this.region=='Asia Pacific & Africa')
    {
      sessionStorage.setItem('aprole', JSON.stringify(this.current_roleid));
    }

    else if(this.region=='Europe')
    {
      sessionStorage.setItem('eurole', JSON.stringify(this.current_roleid));
    }

    else if (this.region=='North America')
    {
      sessionStorage.setItem('narole', JSON.stringify(this.current_roleid));
    }

    else if(this.region=='South America')
    {
      sessionStorage.setItem('sarole', JSON.stringify(this.current_roleid));
    }
  this.loadRoles();

    this.transactionService.getUserAccessRole(this.cdsid).subscribe(out=>{


      console.log(out);
      let roles=[];
      if(out!=null && out.length!=0)
      {
        let roleidString='';
        let glbIndexPresent=out.findIndex(elglbpres=>elglbpres.role_id==100);
        if(glbIndexPresent!=-1)
        {



          if(JSON.parse(sessionStorage.getItem('isGlobalAdmin'))==false)
					{
						sessionStorage.removeItem('isGlobalAdmin');
					sessionStorage.setItem('isGlobalAdmin', JSON.stringify(true));

					this.updateRegionList();
					}


          roles.push({roleid:100,roles:[]})
            roleidString='100';

        }



      for(let reg=0;reg<out.length;reg++)
      {
        if(out[reg].cd_region==this.region_code)
        {
          if(roles.length==0)
          {
            roles.push({roleid:out[reg].role_id,roles:[]})
            roleidString=out[reg].role_id.toString();
          }
          else{


            let role_present=false;

            for(let len=0;len<roles.length;len++)
            {


              if(out[reg].role_id==roles[len])
              {

                role_present=true;
                break;

              }

            }


            if(role_present==false)
            {
              roles.push({roleid:out[reg].role_id,roles:[]})
              roleidString+=',';
              roleidString+=out[reg].role_id.toString();
            }
          }
        }
      }


      console.log(roles);



      if(roles.length!=0)
      {



  this.transactionService.getuserrolefunctionality(roleidString,this.region_code).subscribe(userrolefuncaccess=>{


    console.log(userrolefuncaccess);

    if(userrolefuncaccess!=null || userrolefuncaccess.length!=0)
    {
    for(let ieu=0;ieu<roles.length;ieu++)
    {
      roles[ieu].roles=[]
      let rolefunc=[];
      for(let jeu=0;jeu<userrolefuncaccess.length;jeu++)
      {
        if(roles[ieu].roleid==userrolefuncaccess[jeu].role_id)
        {


          roles[ieu].roles.push(userrolefuncaccess[jeu]);


        }
      }
    }




    let name=this.region_code+'useraccess';
    sessionStorage.setItem(name,JSON.stringify(roles));


    let suppIndex=roles.findIndex(elsup=>elsup.roleid==2)

    if(suppIndex==-1)
    {
      this.routeRegion();
    }

    }


  })

      }
    }

      })
    }


    routeRegion()
    {

      console.log('regpck',this.region_code);

      if(this.region_code=='NA')

      {

     this.navigation.navigatena(true);

      }
      else if(this.region_code=='AP')
      {

     this.navigation.navigateap(true);

      }
      else if(this.region_code=='EU')
      {
     this.navigation.navigateeu(true);
      }
      else if(this.region_code=='SA')
      {
        console.log('insodesapack')
     this.navigation.navigatesa(true);
      }

    }



    loadRoles()
    {


      console.log('regpck',this.region_code);

      if(this.region_code=='NA')

      {
     this.navigation.navigateap(false);
     this.navigation.navigateeu(false);
     this.navigation.navigatesa(false);

      }
      else if(this.region_code=='AP')
      {

        this.navigation.navigatena(false);
        this.navigation.navigateeu(false);
        this.navigation.navigatesa(false);

      }
      else if(this.region_code=='EU')
      {

        this.navigation.navigatena(false);
        this.navigation.navigateap(false);
        this.navigation.navigatesa(false);
      }
      else if(this.region_code=='SA')
      {
        console.log('insodesapack')

        this.navigation.navigatena(false);
        this.navigation.navigateeu(false);
        this.navigation.navigateap(false);
      }

    }



    updateRegionList()
	{


		console.log('update')
		let glbadmin=JSON.parse(sessionStorage.getItem('isGlobalAdmin'))
		if(glbadmin==true)
		{


			let regionlistupdate=JSON.parse(sessionStorage.getItem('user_regionaccess'))


			if(regionlistupdate.length!=4)
			{
				let user_region=[];
				let regionListact=['AP','EU','NA','SA']

				user_region.push(...regionListact);


				sessionStorage.removeItem('user_regionaccess');
						   sessionStorage.setItem('user_regionaccess', JSON.stringify(user_region));

			}




		}
	}



}
