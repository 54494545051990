/* tslint:disable */
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TransactionService } from '../loginpage/Transaction.service';
import {archiveForm1271DataOutput,archiveForm1271Header,plantListGppArchivePackagingSummaryRep, programListGppArchivePackagingSummaryRep, suppListGppArchivePackagingSummaryRep } from '../loginpage/transactions';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { from } from 'rxjs';
import { concatMap, mergeMap } from 'rxjs/operators';

import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-gpparchiveform1271report',
  templateUrl: './gpparchiveform1271report.component.html',
  styleUrls: ['./gpparchiveform1271report.component.css']
})
export class Gpparchiveform1271reportComponent implements OnInit {

  cdsid:string;
  region:string;
  regionCode:string;

  plantcode='';
  selected_plant_code:plantListGppArchivePackagingSummaryRep={plantgsdbcode:''}
  selected_plant_codes_list:plantListGppArchivePackagingSummaryRep[]=[];
  plantcodelist:plantListGppArchivePackagingSummaryRep[]=[];
  all_selected_on_plant_code=false;
  plantcodeselected=false;

  programsList:programListGppArchivePackagingSummaryRep[]=[];
  programListalongwithPlants:programListGppArchivePackagingSummaryRep[]=[];
  selected_programs_list:programListGppArchivePackagingSummaryRep[]=[];
  programcodes='';
  all_selected_on_program=false;

  supplierList:suppListGppArchivePackagingSummaryRep[]=[];
  selected_supplier:suppListGppArchivePackagingSummaryRep[]=[];
  suppliercode='';
  all_selected_on_supplier_code=false;
  supplierCodeToAPI:string;



  currentDate:Date;
  selectedStartDate:Date;

  startDateString:string;
  plantCodeToAPI:string;
  programCodeToAPI:string;
  returnCodeToAPI:string;
  endDateString:string;

  archiveform1271ReportList:archiveForm1271DataOutput[]=[];
  archiveform1271HeaderList:archiveForm1271Header[]=[];
  errorLoadingReport='';
  successMessage='';

  enableExportButton=true;

  loaderform1271report=false;
  downloadType:string="csv";


  constructor(private transactionService:TransactionService,private date:DatePipe) { }

  ngOnInit(): void {
    this.downloadType="csv";
    this.cdsid=this.transactionService.getcdsid();
    this.region=this.transactionService.getregion();
    this.regionCode=this.transactionService.getregioncode(this.region);

    this.transactionService.getPlantCodesGppPackagingsummaryRep(this.regionCode).subscribe(plantco=>{
      this.plantcodelist=[];
      if(plantco != null && plantco.length != 0)
      {
      const resultplant:plantListGppArchivePackagingSummaryRep[]=plantco;
      const plantcodetemp:plantListGppArchivePackagingSummaryRep[]=[];

       // -- No all case for plant
       // plantcodetemp.push({'cd_plant':'0','plantGSDBCode':'ALL','plant_name':'All','plant_type_name':'All'});
      /*  for(var h=0;h<resultplant.length;h++)
         {
           plantcodetemp.push({'cd_plant':resultplant[h].cd_plant,'plantGSDBCode':resultplant[h].plantGSDBCode,'plant_name':resultplant[h].plant_name,'plant_type_name':resultplant[h].plant_type_name})
         } */
         this.plantcodelist=plantco
         this.selected_plant_codes_list=[];
         console.log('plantcodes from backend',this.plantcodelist);
    }
    })
 }

 onhide_plant_codes()
 {
   this.all_selected_on_plant_code=false;
   this.plantcode='';

   /* checking whether length of selected list is less than one */
   let plantslen=false;
   if(this.selected_plant_codes_list.length==this.plantcodelist.length-1)
   {
     plantslen=true;
   }

   for(let ptc=0;ptc<this.selected_plant_codes_list.length;ptc++)
   {
     this.plantcode+=this.selected_plant_codes_list[ptc].plantgsdbcode;
     if(ptc < this.selected_plant_codes_list.length-1)
     {
       this.plantcode+=',';
     }

   }


  if(this.plantcode.length>0)
  {
    this.plantcodeselected=true;
    this.loadprogramcodes();
    // this.loadSupplierCodes();
    console.log('plantcode selected is true')
  }
  else
  {
    this.plantcodeselected=false;
    this.programsList=[];
    this.selected_programs_list=[];
    this.programListalongwithPlants=[];
    this.programcodes='';
    this.suppliercode='';
    this.selected_supplier=[];
    this.supplierList=[];
  }
}



  change_plant_codes()
  {
    let all_selected_on_plant_code=false;
    let lenlessthan1_plant_code=false;
    this.plantcode='';

    // When plants is changed programs variable shd be emptied and
    // when click outside after selecting plants,these variables will have loaded data
    this.programsList=[];
    this.selected_programs_list=[];
    this.programcodes='';
    this.programListalongwithPlants=[];

    this.supplierList=[];
    this.suppliercode='';
    this.selected_supplier=[];


    if(this.selected_plant_codes_list.length==this.plantcodelist.length-1)
    {
      lenlessthan1_plant_code=true;
    }
    for(let j=0;j<this.selected_plant_codes_list.length;j++)
    {
      if(this.selected_plant_codes_list[j].plantgsdbcode=='ALL')
      {
        all_selected_on_plant_code=true;
      }
    }

    if(all_selected_on_plant_code==false && lenlessthan1_plant_code==true)
    {
      all_selected_on_plant_code=true;
    }

  }

  plantCodeChanged()
  {
    this.plantcodeselected=true;
    this.programsList=[];
    this.selected_programs_list=[];
    this.programcodes='';
    this.programListalongwithPlants=[];

    this.supplierList=[];
    this.suppliercode='';
    this.selected_supplier=[];
    this.plantcode=this.selected_plant_code.plantgsdbcode;
    this.loadprogramcodes();
  }
  loadprogramcodes()
  {
    const tempobj:programListGppArchivePackagingSummaryRep[]=[];
    const regval=this.transactionService.getregioncode(this.region);
    this.transactionService.getProgramCodesGppPackagingsummaryRep(regval,this.plantcode).subscribe(progs=>{
      this.programsList=[];
      this.programListalongwithPlants=[];
      this.programsList=progs;
      if(this.programsList.length>0)
      {
        tempobj.push({plantgsdbcode:'',programname:'All',programnamewithplant:'All'});
      }
      for(let l=0;l<this.programsList.length;l++)
      {
        let programwasalreadypresent=false;
        if(tempobj.length!=0)
        {
          if(tempobj.some(o=>o.programname.toLowerCase() == this.programsList[l].programname.toLowerCase()))
          {
            const index=tempobj.findIndex(object=>{return object.programname.toLowerCase()==this.programsList[l].programname.toLowerCase()});
            const newvalue=tempobj[index].programnamewithplant.slice(0,tempobj[index].programnamewithplant.length-1) ;
            tempobj[index].programnamewithplant=newvalue+', '+this.programsList[l].plantgsdbcode+')';
            programwasalreadypresent=true;
          }
        }
        if(!programwasalreadypresent)
        {
          tempobj.push({programname:this.programsList[l].programname,
            programnamewithplant:this.programsList[l].programname+' ('+this.programsList[l].plantgsdbcode+')',
            plantgsdbcode:this.programsList[l].plantgsdbcode});
        }

      }
      this.programListalongwithPlants=JSON.parse(JSON.stringify(tempobj));

      console.log('programList',this.programsList)
      console.log('programListalongwithPlants',this.programListalongwithPlants);
      this.loadSupplierCodes();
    })
  }

  onhide_program_codes()
  {
    this.all_selected_on_program=false;
    this.programcodes='';

    /* checking whether length of selected list is less than one */
    let prglen=false;
    if(this.selected_programs_list.length==this.programListalongwithPlants.length-1)
    {
      prglen=true;
    }

    for(let prgi=0;prgi<this.selected_programs_list.length;prgi++)
    {
      this.programcodes+=this.selected_programs_list[prgi].programname;
      if(prgi < this.selected_programs_list.length-1)
      {
        this.programcodes+=',';
      }

      if(this.selected_programs_list[prgi].programname=='All')
      {
        this.all_selected_on_program=true;
        this.programcodes='all';
        break;
      }
    }

    /* If selectedvariables length is one less than total length but all is not selected
       which means except all remaining are selected so make
       all_selected_on_program as true and make code  send all to backend */

    if(this.all_selected_on_program==false && prglen==true)
    {
      this.programcodes='all';
      this.all_selected_on_program=true;
    }
    console.log('program codes in Hide',this.programcodes,typeof(this.programcodes));

    this.loadSupplierCodes();




    // to add code changes to get supplier code ;
  }

  change_prgm_codes()
  {
    let all_selected_on_program=false;
    let lenlessthan1_prg_code=false;
    this.programcodes='';

    this.suppliercode='';
    this.selected_supplier=[];
    this.supplierList=[];

    if(this.selected_programs_list.length==this.programListalongwithPlants.length-1)
    {
      lenlessthan1_prg_code=true;
    }
    for(let j=0;j<this.selected_programs_list.length;j++)
    {
      if(this.selected_programs_list[j].programname=='All')
      {
        all_selected_on_program=true;
      }
    }

    if(all_selected_on_program==false && lenlessthan1_prg_code==true)
    {
      all_selected_on_program=true;
    }

    if(all_selected_on_program==true)
    {
      this.programcodes='all';
      this.selected_programs_list=[];
      const regval=this.transactionService.getregioncode(this.region);
      this.selected_programs_list.push({plantgsdbcode:'',programname:'All',programnamewithplant:'All'});
    }
  }

  loadSupplierCodes()
  {
    let programCodeTOSupplierAPI=''
    let plantCodeToSupplierAPI='';

    if(this.plantcode.length == 0 )
    {
      plantCodeToSupplierAPI='all';
    }
    else
    {
      plantCodeToSupplierAPI=this.plantcode;
    }

    if(this.programcodes.length == 0)
    {
      programCodeTOSupplierAPI='all'
    }
    else
    {
      programCodeTOSupplierAPI=this.programcodes;
    }

    this.transactionService.getSupplierBasedOnPlantProgramGppArchive(plantCodeToSupplierAPI,programCodeTOSupplierAPI).subscribe(suplis=>{
      console.log('suppliers',suplis,this.regionCode);
      this.supplierList=[];
      if(suplis != null && suplis.length != 0)
      {
        this.supplierList=suplis;
        let supplierListTemp=[];
        supplierListTemp=this.supplierList;
        this.supplierList=[];
        this.supplierList.push({suppliercode:'ALL'});
        this.supplierList.push(...supplierListTemp);
      }
    })
  }

  onhide_supplier_codes()
  {
    this.all_selected_on_supplier_code=false;
    this.suppliercode='';

    /* checking whether length of selected list is less than one */
    let supplen=false;
    if(this.selected_supplier.length == this.supplierList.length-1)
    {
      supplen=true;
    }

    for(let ptc=0;ptc<this.selected_supplier.length;ptc++)
    {
      this.suppliercode+=this.selected_supplier[ptc].suppliercode;
      if(ptc < this.selected_supplier.length-1)
      {
        this.suppliercode+=',';
      }

      if(this.selected_supplier[ptc].suppliercode.toLowerCase() == 'all')
      {
        this.all_selected_on_supplier_code=true;
        this.suppliercode='all';
        break;
      }
    }

    /* If selectedvariables length is one less than total length but all is not selected
       which means except all remaining are selected so make
       all_selected_on_plant_code as true and make code  send all to backend */

    if(this.all_selected_on_supplier_code == false && supplen == true)
    {
      this.suppliercode='all';
      this.all_selected_on_supplier_code=true;
    }
    console.log('supplier codes in Hide',this.suppliercode,typeof(this.suppliercode));

  }

  change_supplier_codes()
  {
    let all_selected_on_supplier_code=false;
    let lenlessthan1_supplier_code=false;
    this.suppliercode='';

    // When plants is changed programs variable shd be emptied and
    // when click outside after selecting plants,these variables will have loaded data


    if(this.selected_supplier.length == this.supplierList.length-1)
    {
      lenlessthan1_supplier_code=true;
    }
    for(let j=0;j<this.selected_supplier.length;j++)
    {
      if(this.selected_supplier[j].suppliercode.toLowerCase() == 'all')
      {
        all_selected_on_supplier_code=true;
      }
    }

    if(all_selected_on_supplier_code == false && lenlessthan1_supplier_code == true)
    {
      all_selected_on_supplier_code=true;
    }

    if(all_selected_on_supplier_code == true)
    {
      this.suppliercode='all';
      this.selected_supplier=[];
      this.selected_supplier.push({suppliercode:'ALL'});
    }
  }

  generateForm1271Report()
  {
    this.enableExportButton=false;
    this.loaderform1271report=true;
    if(this.selectedStartDate!= null)
    {
      this.startDateString=this.date.transform(this.selectedStartDate,'yyyy-MM-dd HH:mm:ss');
      this.endDateString=this.date.transform(new Date(),'yyyy-MM-dd HH:mm:ss');
    }
    else
    {
      this.startDateString='all';
      this.endDateString='all'
    }



    if(this.plantcode=='' )
    {
      this.plantCodeToAPI='all';
    }
    else
    {
      this.plantCodeToAPI=this.plantcode;
    }

    if(this.programcodes=='')
    {
      this.programCodeToAPI='all';
    }
    else
    {
      this.programCodeToAPI=this.programcodes;
    }

    if(this.suppliercode == '')
    {
      this.supplierCodeToAPI='all'
    }
    else
    {
      this.supplierCodeToAPI=this.suppliercode
    }

this.archiveform1271ReportList=[];
this.archiveform1271HeaderList=[];
this.errorLoadingReport='';
this.successMessage='Generating Report, Please Wait ...'
var totalRows=0;
var batchSize=5000;
var apiBatchCal=new Array();

var result=[];
var lastReturnedRowId=""; 

this.transactionService.getArchiveform1271ReportCount(this.plantCodeToAPI,this.programCodeToAPI,this.supplierCodeToAPI,this.startDateString,this.endDateString,this.cdsid,this.regionCode).subscribe((countdata)=>{
  if(countdata!=null && Number(countdata)>0)
  {
    totalRows=Number(countdata);

    for(var i=0; i<totalRows;i+=batchSize)
    {
      /* var j=i;
      if(j!=0)
      {
        j++;
      } 
        
      apiBatchCal.push(j);*/
      apiBatchCal.push(i);

    }

    this.transactionService.getArchiveform1271ReportHeader().subscribe((archiveform1271rephdr)=>{

      console.log("getArchiveform1271ReportHeader api o/p",archiveform1271rephdr);
      if(archiveform1271rephdr != null && archiveform1271rephdr.length != 0)
      {
        this.archiveform1271HeaderList=archiveform1271rephdr;
        console.log("before archiveForm1271DataToExcel fn call - ","header",this.archiveform1271HeaderList,"data",this.archiveform1271ReportList);
      
        from(apiBatchCal)
            .pipe(
              concatMap(currentItem => {
               console.log(currentItem);
               
                return this.transactionService.getArchiveForm1271Report(this.plantCodeToAPI,this.programCodeToAPI,this.supplierCodeToAPI,this.startDateString,this.endDateString,this.cdsid,this.regionCode,currentItem)
               })
            )
            .subscribe((data) => {
               result.push(...data) 
            },
             (error=>{
              console.error("Data API Failed",error)
              console.error('Error in Archive Form1271 Report Data API call - getArchiveForm1271Report',error)
              this.successMessage="";
              this.errorLoadingReport = "Error Occured in Getting Archive Form1271 Report, Please Try Again";
              this.enableExportButton=true;
              this.loaderform1271report=false;
            }),
            ()=>{
             
              this.archiveform1271ReportList=result;
              console.log("Total Rows: " ,this.archiveform1271ReportList.length);
              this.archiveForm1271DataToExcel();
            } ); 
      }
      else
      {
        this.enableExportButton=true;
        console.log("Archive Form 1271 Report - No header to load")
        this.successMessage="";
        this.errorLoadingReport="Error occured While Generating Report";
        this.loaderform1271report=false;
      }
    },
      (error) => {                              //Error callback
        console.error('Error in Header API call - getArchiveform1271ReportHeader',error)
        this.successMessage="";
        this.errorLoadingReport = "Error Occured in Getting getArchiveform1271Report, Please Try Again";
        this.enableExportButton=true;
        this.loaderform1271report=false;
      }
    )


  }
  else
  {
    console.error('Error in Archive Form1271 Report Count API call - getArchiveform1271ReportCount')
    this.successMessage="";
    this.errorLoadingReport = "Error Occured in Getting Archive Form1271 Report, Please Try Again";
    this.enableExportButton=true;
    this.loaderform1271report=false;
  }
  (error) => {                              //Error callback
    console.error('Error in Archive Form1271 Report Count API call - getArchiveform1271ReportCount',error)
    this.successMessage="";
    this.errorLoadingReport = "Error Occured in Getting Archive Form1271 Report, Please Try Again";
    this.enableExportButton=true;
    this.loaderform1271report=false;
  }
})
/*this.transactionService.getArchiveForm1271Report(this.plantCodeToAPI,this.programCodeToAPI,this.supplierCodeToAPI,this.startDateString,this.endDateString,this.cdsid,this.regionCode).subscribe((archiveform1271rep)=>{

  console.log('getArchiveForm1271Report api o/p',archiveform1271rep);
  if(archiveform1271rep != null && archiveform1271rep.length != 0)
  {
  this.archiveform1271ReportList=archiveform1271rep;

  this.transactionService.getArchiveform1271ReportHeader().subscribe((archiveform1271rephdr)=>{

    console.log('getArchiveform1271ReportHeader api o/p',archiveform1271rephdr);
    if(archiveform1271rephdr != null && archiveform1271rephdr.length != 0)
    {
      this.archiveform1271HeaderList=archiveform1271rephdr;
      console.log('before archiveForm1271DataToExcel fn call - ','header',this.archiveform1271HeaderList,'data',this.archiveform1271ReportList);
      this.archiveForm1271DataToExcel();
    }
    else
    {
      this.enableExportButton=true;
      console.log('Archive Form 1271 Report - No header to load')
      this.successMessage='';
      this.errorLoadingReport='Error occured While Generating Report';
      this.loaderform1271report=false;
    }
  },
    (error) => {                              // Error callback
      console.error('Error in Header API call - getArchiveform1271ReportHeader',error)
      this.successMessage='';
      this.errorLoadingReport = 'Error Occured in Getting getArchiveform1271Report, Please Try Again';
      this.enableExportButton=true;
      this.loaderform1271report=false;
    }
  )
  }
  else
  {
    this.enableExportButton=true;
    console.log('Archive Form1271 Report - No Data Found To Generate Report')
    this.successMessage='';
    this.errorLoadingReport='No Data Found To Generate Report'
    this.loaderform1271report=false
  }

},
    (error) => {                              // Error callback
      console.error('Error in Archive Form1271 Report Data API call - getArchiveForm1271Report',error)
      this.successMessage='';
      this.errorLoadingReport = 'Error Occured in Getting Archive Form1271 Report, Please Try Again';
      this.enableExportButton=true;
      this.loaderform1271report=false;
    }
)*/
}

clearFilterArchiveForm1271Report()
  {
    this.selected_plant_codes_list=[];
    this.downloadType="csv";
    this.selected_plant_code={plantgsdbcode:''}
    this.plantcode='';
    this.plantcodeselected=false;
    this.selected_programs_list=[];
    this.programcodes='';
    this.selected_supplier=[];
    this.suppliercode='';
    this.selectedStartDate=null;
    this.startDateString='';
    this.endDateString='';
    this.archiveform1271ReportList=[];
    this.archiveform1271HeaderList=[];
    this.errorLoadingReport='';
    this.successMessage=''
    this.enableExportButton=true;
  }


archiveForm1271DataToExcel()
  {
    console.log('inside Form1271DataToExcel fn ',this.archiveform1271ReportList,this.archiveform1271HeaderList)
    const title = 'Archive - Form 1271 Report';
    const header = this.archiveform1271HeaderList;
    const data = this.archiveform1271ReportList;

    console.log(header,data);

    if(this.downloadType=="xlsx")
    {
    // Create a workbook with a worksheet
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Archive - FORM 1271 Report');

    // Adding Header Row
    const arr=[];
    header.forEach((d: any) => {
      // console.log(d);
      arr.push(
        {header:d.col_header,key:d.col_field_name,color:d.col_color}
        )
    })

    worksheet.columns = arr;
    console.log(arr);
   const headerRow = worksheet.getRow(1)
    console.log(headerRow)
    headerRow.eachCell((cell, number) => {
      // console.log(cell)
      const findindex=arr.findIndex(ele=>ele.header.trim() == cell.value.toString().trim())
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: arr[findindex].color},
          bgColor: { argb: '' },
        };
        cell.font = {
          name:'Arial',
          bold: true,
          color: { argb: '000000' },
          size: 10,
        };
        cell.alignment = { vertical: 'bottom', horizontal: 'center', textRotation: 90 , wrapText: true };
        cell.border = {

          left: {style:'thin'},
          bottom: {style:'thin'},
          right: {style:'thin'}
        };
      });
      console.log("Added Header")

      // Adding Data
      /* data.forEach((d: any) => {
        worksheet.addRow(d);
      }); */

      worksheet.addRows(data);
      console.log("Added Data")
     // console.log(worksheet)

      //Generate & Save Excel File
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], {
          //type:'application/octet-stream'
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        fs.saveAs(blob, title + '.xlsx');

        this.successMessage="Report Generated Successfully !!!"
        this.enableExportButton=true;
        this.loaderform1271report=false;
      });
    }
    else if(this.downloadType=="csv")
    {
      //Create a workbook with a worksheet
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet('Archive - FORM 1271 Report');

      //Adding Header Row
      let arr=[];
      header.forEach((d: any) => {
        //console.log(d);
        arr.push(
          {header:d.col_header,key:d.col_field_name,color:d.col_color}
          )
      })

      worksheet.columns = arr;
      console.log(arr);
      let headerRow = worksheet.getRow(1)
     // console.log(headerRow)
      headerRow.eachCell((cell, number) => {
        //console.log(cell)
        var findindex=arr.findIndex(ele=>ele.header.trim() == cell.value.toString().trim())

      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: arr[findindex].color},
        bgColor: { argb: '' },
      };
      cell.font = {
        name:'Arial',
        bold: true,
        color: { argb: '000000' },
        size: 10,
      };
      cell.alignment = { vertical: 'bottom', horizontal: 'center', textRotation: 90 , wrapText: true };
      cell.border = {

        left: {style:'thin'},
        bottom: {style:'thin'},
        right: {style:'thin'}
      };
    });

      console.log("Added Header")

      // Adding Data
      /* data.forEach((d: any) => {
        worksheet.addRow(d);
      }); */

      worksheet.addRows(data);
      console.log("Added Data");
     // console.log(worksheet)

      //Generate & Save Excel File
      workbook.csv.writeBuffer().then((data) => {
        let blob = new Blob([data], {
          type:'application/octet-stream'
          //type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        fs.saveAs(blob, title + '.csv');

      this.successMessage='Report Generated Successfully !!!'
      this.enableExportButton=true;
      this.loaderform1271report=false;
    });
    }
    
   /* const workbookt: XLSX.WorkBook = XLSX.utils.book_new(); 
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.archiveform1271ReportList);
   // save to file
   XLSX.utils.book_append_sheet(workbookt, ws, 'Sheet1');

    var path= 'Form1271ArchiveRepo-' + new Date().getTime() + '.xlsx'
    console.log(workbookt);
   XLSX.writeFile(workbookt,path);
   this.successMessage="Report Generated Successfully !!!"
      this.enableExportButton=true;
      this.loaderform1271report=false;*/
  }


}


