import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ServiceResult} from '../home/model/service_result';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class ApicallerService {
    serviceUrl = 'http://localhost:8080/api-token/hello';
    // serviceUrl = 'https://token-service-dev.apps.pp01i.edc1.cf.ford.com/api-token/hello';
    constructor(private http: HttpClient) {
    }

    callService(): Observable<ServiceResult> {
        return this.http
            .get(this.serviceUrl)
            .pipe(map( (data) => {
                console.log(data)
                return new ServiceResult(data);
        }));
    }
}
