import { Component, OnInit } from '@angular/core';
import { TransactionService } from '../loginpage/Transaction.service';

@Component({
  selector: 'app-gpparchivereports',
  templateUrl: './gpparchivereports.component.html',
  styleUrls: ['./gpparchivereports.component.css']
})
export class GpparchivereportsComponent implements OnInit {


  region:string;
  regionCode:string;
  cdsid:string;

  viewArchivePackagingSummaryReport=false;
  viewArchiveLOMReport=false;
  viewArchivePfepDataExportReport=false;
  viewArchiveFORM1271Report=false;
  viewArchiveSupplierContact=false;
  constructor(private transactionService:TransactionService) { }

  ngOnInit(): void {

    this.region=this.transactionService.getregion();
    this.regionCode=this.transactionService.getregioncode(this.region);
    this.cdsid=this.transactionService.getcdsid();
    this.viewArchivePackagingSummaryReport=false;
    this.viewArchiveLOMReport=false;
    this.viewArchivePfepDataExportReport=false;
    this.viewArchiveFORM1271Report=false;
    this.viewArchiveSupplierContact=false;

  }
  onClickArchivePackagingSummaryReport()
  {
    this.viewArchivePfepDataExportReport=false;
    this.viewArchiveLOMReport=false;
    this.viewArchivePackagingSummaryReport=true;
    this.viewArchiveFORM1271Report=false;
    this.viewArchiveSupplierContact=false;
  }

  onClickArchiveLOMReport()
  {
    this.viewArchivePackagingSummaryReport=false;
    this.viewArchivePfepDataExportReport=false;
    this.viewArchiveLOMReport=true;
     this.viewArchiveFORM1271Report=false;
     this.viewArchiveSupplierContact=false;
  }

  onClickArchivePfepDataExportReport()
  {
    this.viewArchivePackagingSummaryReport=false;
    this.viewArchiveLOMReport=false;
    this.viewArchivePfepDataExportReport=true;
     this.viewArchiveFORM1271Report=false;
     this.viewArchiveSupplierContact=false;
  }

   onClickArchiveFORM1271Report()
  {
    this.viewArchivePfepDataExportReport=false;
    this.viewArchivePackagingSummaryReport=false;
    this.viewArchiveLOMReport=false;
    this.viewArchiveFORM1271Report=true;
    this.viewArchiveSupplierContact=false;
  }

  onClickArchiveSupplierContactReport()
  {
    this.viewArchivePfepDataExportReport=false;
    this.viewArchivePackagingSummaryReport=false;
    this.viewArchiveLOMReport=false;
    this.viewArchiveFORM1271Report=false;
    this.viewArchiveSupplierContact=true;
  }

  /*
  enableArchivePackagingsummaryReport():boolean
  {
      //this.sub_boolean=true;
      var user_roles=[];
    user_roles=this.transactionService.getroles(this.regionCode)

    var func_present=false;

    if(user_roles!=null && user_roles.length!=0)
    {

      for(var ni=0;ni<user_roles.length;ni++)
      {
        var sys_func_arr=[];
						for(var k=0;k<user_roles[ni].roles.length;k++)
						{
                            sys_func_arr.push(user_roles[ni].roles[k].sysfunc_id.toString())
						}
						if(sys_func_arr.findIndex(ele=>ele == '34') > -1)
						{
							func_present=true;
							break
						}
      }
    }
    return func_present;
  }*/



}
