/* tslint:disable */
import { ChangeDetectorRef, Component, HostListener, OnInit, SimpleChanges } from '@angular/core';


import {TransactionService} from '../loginpage/Transaction.service';
import { Router,ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import {plantlist,addplant,getplant,planttype,countrylist, planttypeForGet} from '../loginpage/transactions';
import { MessageService } from 'primeng/api';



import { FilterUtils } from './utils/filterutils';
import { ComponentCanDeactivate } from '../oauth/unsavedchanges.guard';
import { Observable } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';








@Component({
  selector: 'app-packplantadmin',
  templateUrl: './packplantadmin.component.html',
  styleUrls: ['./packplantadmin.component.css'],
  providers: [ConfirmationService,MessageService,]
})

export class PackplantadminComponent implements OnInit,ComponentCanDeactivate {

  

 

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
  if(this.enableadddetails == true)
  {
    // return !this.isAddPlantChanged();
    return true
  }
  else if(this.enableeditplantdetails == true || this.enableeditgetdetails == true)
  {
    // return !this.isEditPlantChanged();
    return true
  }
  else
  {
    return true;
  }
  }


  onDetect(changes: SimpleChanges) {

    // changes.prop contains the old and the new value...

    this.changes_made=true;
  }

private orginalAddPlant:addplant;
 private changedAddPlant:addplant;

  pltdetailsadd:addplant={
    plant_name:'',
    plant_type_name:'',
    cd_plant:0,
    plantgsdbcode:'',
    cd_region:'',
    assign_access:'N',
    address1:'',
    address2:'',
    city:'',
    state_province:'',
    postal_code: '',
    country_name:'',
    building_name:'',
    dock_ID:'',
    production_days:0,
    shipping_details:'',
    test_pack_mail_instns:'',
    plant_active_stat:''
    };

    private orginalEditPlant:addplant;
    private changedEditPlant:addplant;
    editdetails:addplant={
      plant_name:'',
      plant_type_name:'',
      cd_plant:0,
      plantgsdbcode:'',
      cd_region:'',
      assign_access:'N',
      address1:'',
      address2:'',
      city:'',
      state_province:'',
      postal_code: '',
      country_name:'',
      building_name:'',
      dock_ID:'',
      production_days:0,
      shipping_details:'',
      test_pack_mail_instns:'',
      plant_active_stat:''

    };


    getdetails:getplant={


      plantName:'',
      plantTypeName:'',
      cdPlant:0,
     plantGSDBCode:'',
      cdRegion:'',
      assignAccess:'N',
      address1:'',
      address2:'',
      city:'',
      stateProvince:'',
      postalCode: '',
      countryName:'',
      buildingName:'',
      dockID:'',
      productionDays:0,
      shippingDetails:'',
      testPackMailInstns:'',
      plantActiveStat:''

    }


  planttypelist:planttypeForGet[]=[];
  current_region:string;
  current_region_code:string;
  current_region_code_num:number


  selected_planttypecode:planttypeForGet={cd_plant_type:0,plant_type_name:'',active_stat:'',active_stat_display:false,updated_dt:'',updated_by:''};
  editselected_planttypecode:planttypeForGet={cd_plant_type:0,plant_type_name:'',active_stat:'',active_stat_display:false,updated_dt:'',updated_by:''};
  getselected_planttypecode:planttypeForGet={cd_plant_type:0,plant_type_name:'',active_stat:'',active_stat_display:false,updated_dt:'',updated_by:''};


  countrylist:countrylist[]=[];
  selected_countrylist:countrylist={country_name:' '}
  editselected_countrylist:countrylist={country_name:' '}
  getselected_countrylist:countrylist={country_name:' '};



  plantList:plantlist[]=[];
  public cols: any[];
  enableaddbutton:boolean;
  enableadddetails:boolean;
  enabledetails:boolean;
  enableeditgetdetails:boolean;
  enableeditplantdetails:boolean;

  enableUserAccessForPlantAdmin=false;
  plantOpenedForEdit:any;

  selectedProducts3:any[];


  cdsid_plt='';


  current_routeid=0;


  userRoles='';


  changes_made=false;


  activestatusfilter=[{label:'All',value:null},{label:'Active',value:'yes'},{label:'In-Active',value:'no'}]

  plantGSDBInValid=false;
  plantGSDBInValidReason:string=null;
  plantNameInValid=false;
  plantNameInValidReason:string=null;
  dockIDInValid=false;
  dockIdInValidReason:string=null;
  buildingNameInValid=false;
  buildingNameInValidReason:string=null;
  productionDaysInValid=false;
  productionDaysInValidReason:string=null;
  addressInValid=false;
  addressInValidReason:string=null;
  address2InValid=false;
  address2InValidReason:string=null;
  cityInValid=false;
  cityInValidReason:string=null;
  stateInValid=false;
  stateInValidReason:string=null;
  postalCodeInValid=false;
  postalCodeInValidReason:string=null;
  shippingdetsInValid=false;
  shippingdetsInValidReason:string=null;
  testPackMailInstInValid=false;
  testPackMailInstInValidReason:string=null;
  isPlantActiveInValid=false;
  isPlantActiveInValidReason: string=null;
 isAddPlantChanged():boolean{

   return (JSON.stringify(this.orginalAddPlant) !== JSON.stringify(this.changedAddPlant));
 }


 isEditPlantChanged():boolean{
   console.log('orginal',this.orginalEditPlant,this.changedEditPlant)
  return (JSON.stringify(this.orginalEditPlant) !== JSON.stringify(this.changedEditPlant));
 }

  constructor(private transactionService: TransactionService, private router:Router,private route: ActivatedRoute,private confirmationService: ConfirmationService,private messageService: MessageService,private ref: ChangeDetectorRef) 
  {

    FilterUtils['custom-equals'] = (value, filter): boolean => {
      if (filter === undefined || filter === null || filter.trim() === '') {
          return true;
      }

      if (value === undefined || value === null) {
          return false;
      }

      return value.toString() === filter.toString();
    }


   
 }

  ngOnInit(): void {
/*
this.planttypelist=[
  {plant_type_name:"ATO"},
  {plant_type_name:"VO"},
  {plant_type_name:"Eng"},
  {plant_type_name:"Stp"}

]
*/




this.current_region=this.transactionService.getregion();
this.transactionService.storepage(this.current_region,'packagingprocess/packadmin/packplantadmin');
this.current_region_code=this.transactionService.getregioncode(this.current_region);
console.log(this.current_region_code);


this.cdsid_plt=this.transactionService.getcdsid();





 this.enableaddbutton=true;
 this.enableadddetails=false;
 this.enabledetails=false;
 this.enableeditgetdetails=false;
 this.enableeditplantdetails=false;

 this.current_region_code_num=parseInt(this.current_region_code, 10)

 this.plantvalidationInint();

 console.log(this.current_region_code_num);
 console.log(typeof(this.current_region_code_num));


 this.current_routeid=101;

        console.log(this.current_routeid)


        if(this.current_region=='Asia Pacific & Africa')
        {
          sessionStorage.setItem('aprouteid', JSON.stringify(this.current_routeid));
        }

        else if(this.current_region=='Europe')
        {
          sessionStorage.setItem('eurouteid', JSON.stringify(this.current_routeid));
        }

        else if (this.current_region=='North America')

        {

          sessionStorage.setItem('narouteid', JSON.stringify(this.current_routeid));
        }

        else if(this.current_region=='South America')
        {
          sessionStorage.setItem('sarouteid', JSON.stringify(this.current_routeid));
        }



 this.transactionService.getCountryList( this.current_region_code).subscribe((ctylst => {

  this.countrylist=ctylst;
  console.log(this.countrylist);
  this.selected_countrylist=this.countrylist[0];



}

 ))



// old api call getPlantType - no input

/* this.transactionService.getPlantType().subscribe((pltty => {

  this.planttypelist=pltty;
  console.log(this.planttypelist);
  this.selected_planttypecode=this.planttypelist[0]





  this.getplantlist();



}

 ))*/


 this.transactionService.getActivePlantType().subscribe(activePlantList=>{
   console.log('active plant type list output from api',activePlantList)
   this.planttypelist=[];
   this.planttypelist=activePlantList
   this.selected_planttypecode=this.planttypelist[0];


   this.getplantlist();
 })




  }



  exportExcel() {
    import('xlsx').then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.plantList);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, 'primengTable');
    });
}

saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
}


  getplantlist()
  {



/*

    this.plantList=          [
      {
        "plant_name":"xxxx",
        "plant_type_name":"xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",
        "cd_plant":"xxxx",
        "assign_access":"yes"
      },
      {
        "plant_name":"xxxx",
        "plant_type_name":"xxxx",
        "cd_plant":"xxxx",
        "assign_access":"no"
      },
      {
        "plant_name":"xxxx",
        "plant_type_name":"xxxx",
        "cd_plant":"xxxx",
        "assign_access":"N"
      }
            ]
      ;
*/

// in future do gor global admin if its used


const roles=this.transactionService.getroles(this.current_region_code);


  const roleIndex=roles.findIndex(elroles=>(elroles.roleid==100 || elroles.roleid==12 || elroles.roleid==14))
  if(roleIndex!=-1)
  {

    this.getPlantListAdmin();

  }


  else
  {

    this.userRoles=''
const roleIndexPlt=roles.findIndex(elrolesplt=>elrolesplt.roleid==5)
if(roleIndexPlt!=-1)
{
  this.userRoles+='5,'
}


const roleIndexPltFin=roles.findIndex(elrolesplt=>elrolesplt.roleid==6)
if(roleIndexPltFin!=-1)
{
  this.userRoles+='6'
}



this.getPlantListApr();

  }






  }


  getPlantListApr()
  {

    // api has to be changed



    console.log(this.userRoles);

    this.transactionService.getPlantListonRoles(this.cdsid_plt,this.current_region_code,this.userRoles).subscribe(pltlistapr=>{


      this.plantList=pltlistapr


   console.log('successful',this.plantList);

    })


     this.cols = [
      { field: 'plantGSDBCode', header:'Plant GSDB Code'},
      {  field: 'plant_name', header:'Plant Name'},
      { field: 'plant_type_name', header:'Plant Type'},
      {field:'plant_active_stat',header:'Active'}
    ];


  }

  getPlantListAdmin()
  {

    this.transactionService.getPlantListonRegion(this.current_region_code).subscribe((pltlist => {

      this.plantList=pltlist



     // this.suppcode=[];
    /* if(this.suppget.length==0)
     {
       this.valueback=true;
     }
     else{
     this.valueback=false;
    }*/


  console.log('successful',this.plantList);
  }

     ))

     this.cols = [
      { field: 'plantGSDBCode', header:'Plant GSDB Code'},
      {  field: 'plant_name', header:'Plant Name'},
      { field: 'plant_type_name', header:'Plant Type'},
      {field:'plant_active_stat',header:'Active'}
    ];

  }


  addplantbutton()
  {
    this.enableaddbutton=false;
    this.enableadddetails=true;
    console.log(this.pltdetailsadd);


    this.orginalAddPlant=JSON.parse(JSON.stringify(this.pltdetailsadd));
    this.changedAddPlant=this.pltdetailsadd;



  }


  resetAddPlantDetails()
  {
    this.orginalAddPlant=null;
    this.changedAddPlant=null;
  }


  resetEditPlantDetails()
  {
    this.orginalEditPlant=null;
    this.changedEditPlant=null;
  }

addplantdetailsinit()
{
  this.pltdetailsadd={
    plant_name:'',
    plant_type_name:'',
    cd_plant:0,
    plantgsdbcode:'',
    cd_region:'',
    assign_access:'N',
    address1:'',
    address2:'',
    city:'',
    state_province:'',
    postal_code: '',
    country_name:'',
    building_name:'',
    dock_ID:'',
    production_days:0,
    shipping_details:'',
    test_pack_mail_instns:'',
    plant_active_stat:''
    }


   // this.selected_countrylist=this.countrylist[0];

}


editplantdetailsinit()
{


  this.editdetails={
    plant_name:'',
    plant_type_name:'',
    cd_plant:0,
    plantgsdbcode:'',
    cd_region:'',
    assign_access:'N',
    address1:'',
    address2:'',
    city:'',
    state_province:'',
    postal_code: '',
    country_name:'',
    building_name:'',
    dock_ID:'',
    production_days:0,
    shipping_details:'',
    test_pack_mail_instns:'',
    plant_active_stat:''
    }


    // this.editselected_countrylist.country_name=this.editdetails.country_name;


}

getplantdetailsinit()
{


  this.getdetails={

    plantName:'',
    plantTypeName:'',
    cdPlant:0,
    plantGSDBCode:'',
    cdRegion:'',
    assignAccess:'N',
    address1:'',
    address2:'',
    city:'',
    stateProvince:'',
    postalCode: '',
    countryName:'',
    buildingName:'',
    dockID:'',
    productionDays:0,
    shippingDetails:'',
    testPackMailInstns:'',
    plantActiveStat:''
    }

   // this.getselected_countrylist.country_name=this.getdetails.countryName;


}

plantvalidationInint(){
  this.plantGSDBInValid=false;
  this.plantGSDBInValidReason=null;
  this.plantNameInValid=false;
  this.plantNameInValidReason=null;
  this.dockIDInValid=false;
  this.dockIdInValidReason=null;
  this.buildingNameInValid=false;
  this.buildingNameInValidReason=null;
  this.productionDaysInValid=false;
  this.productionDaysInValidReason=null;
  this.addressInValid=false;
  this.addressInValidReason=null;
  this.address2InValid=false;
  this.address2InValidReason=null;
  this.cityInValid=false;
  this.cityInValidReason=null;
  this.stateInValid=false;
  this.stateInValidReason=null;
  this.postalCodeInValid=false;
  this.postalCodeInValidReason=null;
  this.shippingdetsInValid=false;
  this.shippingdetsInValidReason=null;
  this.testPackMailInstInValid=false;
  this.testPackMailInstInValidReason=null;
  this.isPlantActiveInValid=false;
  this.isPlantActiveInValidReason=null;

}
validatePlantGsdbForAdd()
{
  if(this.pltdetailsadd.plantgsdbcode.length>=11)
  {
    this.plantGSDBInValid=true;
    this.plantGSDBInValidReason='Only 10 characters are valid';
  }
  else if(this.pltdetailsadd.plantgsdbcode.length==null || this.pltdetailsadd.plantgsdbcode.trim()==''){
    this.plantGSDBInValid=true;
    this.plantGSDBInValidReason='Required';
  }
  else
  {
    this.plantGSDBInValid=false;
    this.plantGSDBInValidReason=null
  }
}
validatePlantNameForAdd()
{
  if(this.pltdetailsadd.plant_name.length>=33)
  {
    this.plantNameInValid=true;
    this.plantNameInValidReason='Only 32 characters are valid';
  }
  else if(this.pltdetailsadd.plant_name.length==null || this.pltdetailsadd.plant_name.trim()==''){
    this.plantNameInValid=true;
    this.plantNameInValidReason='Required';
  }
  else
  {
    this.plantNameInValid=false;
    this.plantNameInValidReason=null;
  }
}
validateDockIdForAdd()
{
  if(this.pltdetailsadd.dock_ID.length>=26)
  {
    this.dockIDInValid=true;
    this.dockIdInValidReason='Only 25 characters are valid';
  }
  else
  {
    this.dockIDInValid=false;
    this.dockIdInValidReason=null;
  }
}
validateBuildingNameForAdd()
{
  if(this.pltdetailsadd.building_name.length>=76)
  {
    this.buildingNameInValid=true;
    this.buildingNameInValidReason='Only 75 characters are valid';
  }
  else
  {
    this.buildingNameInValid=false;
    this.buildingNameInValidReason=null
  }
}
validateProductionDaysForAdd()
{
  if(this.pltdetailsadd.production_days<=0||this.pltdetailsadd.production_days>=8 || String(this.pltdetailsadd.production_days).includes('.'))
  {
    this.productionDaysInValid=true;
    this.productionDaysInValidReason='Only 1 to 7 numbers are valid and it cannot contain Decimal';
  }

  else
  {
    this.productionDaysInValid=false;
    this.productionDaysInValidReason=null
  }
}
validateAddressForAdd(){
  if(this.pltdetailsadd.address1.length>=76)
  {
    this.addressInValid=true;
    this.addressInValidReason='Only 75 characters are valid';
  }
  else
  {
    this.addressInValid=false;
    this.addressInValidReason=null
  }
}
validateAddress2ForAdd(){
  if(this.pltdetailsadd.address2.length>=76)
  {
    this.address2InValid=true;
    this.address2InValidReason='Only 75 characters are valid';
  }
  else
  {
    this.address2InValid=false;
    this.address2InValidReason=null
  }
}
validateCityForAdd(){
  if(this.pltdetailsadd.city.length>=76)
  {
    this.cityInValid=true;
    this.cityInValidReason='Only 75 characters are valid';
  }
  else
  {
    this.cityInValid=false;
    this.cityInValidReason=null
  }
}
validateStateForAdd(){
  if(this.pltdetailsadd.state_province.length>=76)
  {
    this.stateInValid=true;
    this.stateInValidReason='Only 75 characters are valid';
  }
  else
  {
    this.stateInValid=false;
    this.stateInValidReason=null
  }
}
validatePostalCodeForAdd(){
  if(this.pltdetailsadd.postal_code.length>=76)
  {
    this.postalCodeInValid=true;
    this.postalCodeInValidReason='Only 75 characters are valid';
  }
  else
  {
    this.postalCodeInValid=false;
    this.postalCodeInValidReason=null
  }
}
validateShippingDetsForAdd(){
  if(this.pltdetailsadd.shipping_details.length>=251)
  {
    this.shippingdetsInValid=true;
    this.shippingdetsInValidReason='Only 250 characters are valid';
  }
  else
  {
    this.shippingdetsInValid=false;
    this.shippingdetsInValidReason=null
  }
}
validateTestPackMailInstForAdd(){
  if(this.pltdetailsadd.test_pack_mail_instns.length>=251)
  {
    this.testPackMailInstInValid=true;
    this.testPackMailInstInValidReason='Only 250 characters are valid';
  }
  else
  {
    this.testPackMailInstInValid=false;
    this.testPackMailInstInValidReason=null
  }
}
validateIsPlantActiveForAdd(){
  if(this.pltdetailsadd.plant_active_stat==null || this.pltdetailsadd.plant_active_stat.trim()=='')
  {
    this.isPlantActiveInValid=true;
    this.isPlantActiveInValidReason='Required';
    console.log('validateIsPlantActiveForAdd',this.isPlantActiveInValid)
  }
  else
  {
    this.isPlantActiveInValid=false;
    this.isPlantActiveInValidReason=null;
  }
}



  saveaddplant()
  {

    console.log(this.pltdetailsadd);
    this.pltdetailsadd.cd_region=this.current_region_code;
    this.pltdetailsadd.plant_type_name=this.selected_planttypecode.plant_type_name;
    this.pltdetailsadd.country_name=this.selected_countrylist.country_name;
    console.log(this.pltdetailsadd);
  //  var res="successs";

  this.validatePlantGsdbForAdd();
  this.validatePlantNameForAdd();
  this.validateDockIdForAdd();
  this.validateProductionDaysForAdd();
  this.validateAddressForAdd();
  this.validateAddress2ForAdd();
  this.validateCityForAdd();
  this.validateStateForAdd();
  this.validatePostalCodeForAdd();
  this.validateShippingDetsForAdd();
  this.validateTestPackMailInstForAdd();
  this.validateIsPlantActiveForAdd();

  if(!this.plantGSDBInValid && !this.plantNameInValid && !this.dockIDInValid && !this.buildingNameInValid && !this.addressInValid && !this.address2InValid
    && !this.cityInValid && !this.stateInValid && !this.postalCodeInValid && !this.shippingdetsInValid && !this.testPackMailInstInValid && !this.isPlantActiveInValid && !this.productionDaysInValid)
    {
   this.transactionService.saveplantdetails(this.pltdetailsadd).subscribe((saveplt => {
      // this.resultst=output;
      const res=saveplt;
      console.log('successful',res);
      if(res == 'success'){

        this.messageService.add({severity:'success', summary:'Success', detail:'Plant Added Successfully'});
        this.getplantlist();
        this.addplantdetailsinit();
        this.plantvalidationInint();
       // this.resetAddPlantDetails();
      }

      else{
        this.plantvalidationInint();
        this.messageService.add({severity:'error', summary: 'Error', detail: 'Plant Not Added,Please Try Again'});
        // this.addplantdetailsinit();
       }

      }
      ))
    }
  }


  cancel_add()
  {

    this.enableadddetails=false;

    this.enableaddbutton=true;

    this.addplantdetailsinit();

    this.plantvalidationInint();


   // this.resetAddPlantDetails();
  }




  getplantdetails(plantdataid:any)
  {
    this.enabledetails=true;
    this.getplantdetailsinit();

    if(this.enableeditgetdetails==true)
    {
      this.enableeditgetdetails=false;
    }
    if(this.enableeditplantdetails==true)
    {
      this.enableeditplantdetails=false;
    }

    if(this.enableaddbutton==true)
    {
      this.enableaddbutton=false;
    }

    if(this.enableadddetails==true)
    {
      this.enableadddetails=false;
    }




// service


this.transactionService.getplantdetails(plantdataid.cd_plant).subscribe((getplt => {
  // this.resultst=output;
  this.getdetails=getplt;



    console.log(this.getdetails);

    console.log('get plant details');
    console.log(plantdataid.cd_plant);

    if(this.getdetails.countryName != null && this.getdetails.countryName.trim() != '')
    {
    for(let i=0;i <this.countrylist.length;i++)
    {
      console.log(this.countrylist[i].country_name)
      console.log(this.getdetails.countryName)
      if(this.getdetails.countryName==this.countrylist[i].country_name)
      {

      this.getselected_countrylist=this.countrylist[i];
      }

    }
  }
  else
  {
    this.getselected_countrylist={country_name:' '};
  }

console.log(this.getselected_countrylist);

if(this.getdetails.plantTypeName != null && this.getdetails.plantTypeName.trim() != '')
{
    for(let i=0;i <this.planttypelist.length;i++)
    {
      console.log(this.planttypelist[i].plant_type_name)
      console.log(this.getdetails.plantTypeName)
      if(this.getdetails.plantTypeName==this.planttypelist[i].plant_type_name)
      {

      this.getselected_planttypecode=this.planttypelist[i];
      }

    }
  }
  else
  {
    this.getselected_planttypecode={cd_plant_type:0,plant_type_name:'',active_stat:'',active_stat_display:false,updated_dt:'',updated_by:''}
  }


    console.log( this.getselected_planttypecode)

  }




  ))


  }

validatePlantNameForEdit()
{
  if(this.editdetails.plant_name.length>=33)
  {
    this.plantNameInValid=true;
    this.plantNameInValidReason='Only 32 characters are valid';
  }
  else if(this.editdetails.plant_name.length==null || this.editdetails.plant_name.trim()==''){
    this.plantNameInValid=true;
    this.plantNameInValidReason='Required';
  }
  else
  {
    this.plantNameInValid=false;
    this.plantNameInValidReason=null;
  }
}
validateDockIdForEdit()
{
  if(this.editdetails.dock_ID.length>=26)
  {
    this.dockIDInValid=true;
    this.dockIdInValidReason='Only 25 characters are valid';
  }
  else
  {
    this.dockIDInValid=false;
    this.dockIdInValidReason=null;
  }
}
validateBuildingNameForEdit()
{
  if(this.editdetails.building_name.length>=76)
  {
    this.buildingNameInValid=true;
    this.buildingNameInValidReason='Only 75 characters are valid';
  }
  else
  {
    this.buildingNameInValid=false;
    this.buildingNameInValidReason=null
  }
}
validateProductionDaysForEdit()
{
  if(this.editdetails.production_days<=0||this.editdetails.production_days>=8 || this.editdetails.production_days.toString.length>2 || String(this.editdetails.production_days).includes('.'))
  {
    this.productionDaysInValid=true;
    this.productionDaysInValidReason='Only 1 to 7 numbers are valid and it cannot contain Decimal';
  }
  else
  {
    this.productionDaysInValid=false;
    this.productionDaysInValidReason=null
  }
}
validateAddressForEdit(){
  if(this.editdetails.address1.length>=75)
  {
    this.addressInValid=true;
    this.addressInValidReason='Only 75 characters are valid';
  }
  else
  {
    this.addressInValid=false;
    this.addressInValidReason=null
  }
}
validateAddress2ForEdit(){
  if(this.editdetails.address2.length>=76)
  {
    this.address2InValid=true;
    this.address2InValidReason='Only 75 characters are valid';
  }
  else
  {
    this.address2InValid=false;
    this.address2InValidReason=null
  }
}
validateCityForEdit(){
  if(this.editdetails.city.length>=76)
  {
    this.cityInValid=true;
    this.cityInValidReason='Only 75 characters are valid';
  }
  else
  {
    this.cityInValid=false;
    this.cityInValidReason=null
  }
}
validateStateForEdit(){
  if(this.editdetails.state_province.length>=76)
  {
    this.stateInValid=true;
    this.stateInValidReason='Only 75 characters are valid';
  }
  else
  {
    this.stateInValid=false;
    this.stateInValidReason=null
  }
}
validatePostalCodeForEdit(){
  if(this.editdetails.postal_code.length>=76)
  {
    this.postalCodeInValid=true;
    this.postalCodeInValidReason='Only 75 characters are valid';
  }
  else
  {
    this.postalCodeInValid=false;
    this.postalCodeInValidReason=null
  }
}
validateShippingDetsForEdit(){
  if(this.editdetails.shipping_details.length>=251)
  {
    this.shippingdetsInValid=true;
    this.shippingdetsInValidReason='Only 250 characters are valid';
  }
  else
  {
    this.shippingdetsInValid=false;
    this.shippingdetsInValidReason=null
  }
}
validateTestPackMailInstForEdit(){
  if(this.editdetails.test_pack_mail_instns.length>=251)
  {
    this.testPackMailInstInValid=true;
    this.testPackMailInstInValidReason='Only 250 characters are valid';
  }
  else
  {
    this.testPackMailInstInValid=false;
    this.testPackMailInstInValidReason=null
  }
}
validateIsPlantActiveForEdit(){
  if(this.editdetails.plant_active_stat==null || this.editdetails.plant_active_stat.trim()=='')
  {
    this.isPlantActiveInValid=true;
    this.isPlantActiveInValidReason='Required';
  }
  else
  {
    this.isPlantActiveInValid=false;
    this.isPlantActiveInValidReason=null;
  }
}



  cancel_getplantdetails()
  {
    this.enabledetails=false;
    this.enableaddbutton=true;
    this.getplantdetailsinit();
  }


  viewOrDeleteAccess(plantcode:any,plantname:any)
  {
    this.enableUserAccessForPlantAdmin=true;
    this.plantOpenedForEdit={plantcode:plantcode,plantname:plantname,programcode:'',coscode:'',modify:true,calledFrom:'plantadmin'};
  }

  closeViewOrDeleteAccessDialog()
  {
    this.enableUserAccessForPlantAdmin=false;
    this.current_routeid=1;

    if(this.current_region=='Asia Pacific & Africa')
    {
      sessionStorage.setItem('aprouteid', JSON.stringify(this.current_routeid));
    }

    else if(this.current_region=='Europe')
    {
      sessionStorage.setItem('eurouteid', JSON.stringify(this.current_routeid));
    }

    else if (this.current_region=='North America')
    {
      sessionStorage.setItem('narouteid', JSON.stringify(this.current_routeid));
    }

    else if(this.current_region=='South America')
    {
      sessionStorage.setItem('sarouteid', JSON.stringify(this.current_routeid));
    }
  }

  editgetplantdetails()
  {
    this.enabledetails=false;
    this.enableeditgetdetails=true;

    console.log(this.editdetails);
    console.log(this.getdetails);
    this.editplantdetailsinit();


    this.editdetails.plant_name=this.getdetails.plantName;
    this.editdetails.plant_type_name=this.getdetails.plantTypeName;
    this.editdetails.cd_plant=this.getdetails.cdPlant;
    this.editdetails.plantgsdbcode=this.getdetails.plantGSDBCode;
    this.editdetails.cd_region=this.getdetails.cdRegion;
    this.editdetails.assign_access=this.getdetails.assignAccess;
    this.editdetails.address1=this.getdetails.address1;
    this.editdetails.address2=this.getdetails.address2;
    this.editdetails.city=this.getdetails.city;
    this.editdetails.state_province=this.getdetails.stateProvince;
    this.editdetails.postal_code=this.getdetails.postalCode;
    this.editdetails.country_name=this.getdetails.countryName;
    this.editdetails.building_name=this.getdetails.buildingName;
    this.editdetails.dock_ID=this.getdetails.dockID;
    this.editdetails.production_days=this.getdetails.productionDays;
    this.editdetails.shipping_details=this.getdetails.shippingDetails;
    this.editdetails.test_pack_mail_instns=this.getdetails.testPackMailInstns;
    this.editdetails.plant_active_stat=this.getdetails.plantActiveStat;


    this.orginalEditPlant=JSON.parse(JSON.stringify(this.editdetails));
    this.changedEditPlant=this.editdetails;


 if(this.editdetails.country_name != null && this.editdetails.country_name.trim() != '')
 {


    for(let i=0;i <this.countrylist.length;i++)
    {
      console.log(this.countrylist[i].country_name)
      console.log(this.editdetails.country_name)
      if(this.editdetails.country_name==this.countrylist[i].country_name)
      {

      this.editselected_countrylist=this.countrylist[i];
      }

    }
  }
  else
  {
    this.editselected_countrylist={country_name:''}
  }



if(this.editdetails.plant_type_name != null && this.editdetails.plant_type_name.trim() != '')
{
    for(let i=0;i <this.planttypelist.length;i++)
    {
      console.log(this.planttypelist[i].plant_type_name)
      console.log(this.editdetails.plant_type_name)
      if(this.editdetails.plant_type_name==this.planttypelist[i].plant_type_name)
      {

      this.editselected_planttypecode=this.planttypelist[i];
      }

    }
  }
  else
  {
    this.editselected_planttypecode={cd_plant_type:0,plant_type_name:'',active_stat:'',active_stat_display:false,updated_dt:'',updated_by:''}
  }

  }

  cancel_editgetplantdetails()
  {
    this.enableeditgetdetails=false;
    this.enabledetails=true;
    this.plantvalidationInint();
    // this.resetEditPlantDetails();
  }



  editplantdetails(plantdata:any)
  {

    this.editplantdetailsinit();
    this.plantvalidationInint();


    console.log(plantdata);
    console.log(plantdata.cd_plant);


    if(this.enabledetails==true)
    {
      this.enabledetails=false
    }




    if(this.enableeditgetdetails==true)
    {
      this.enableeditgetdetails=false
    }

    if(this.enableaddbutton==true)
    {
      this.enableaddbutton=false;
    }

    if(this.enableadddetails==true)
    {
      this.enableadddetails=false;
    }


this.transactionService.getplantdetails(plantdata.cd_plant).subscribe((getedplt => {
  // this.resultst=output;
  this.enableeditplantdetails=true;

  // this.editdetails=getedplt;



  this.editdetails.plant_name=getedplt.plantName;
  this.editdetails.plant_type_name=getedplt.plantTypeName;
  this.editdetails.cd_plant=getedplt.cdPlant;
  this.editdetails.plantgsdbcode=getedplt.plantGSDBCode;
  this.editdetails.cd_region=getedplt.cdRegion;
  this.editdetails.assign_access=getedplt.assignAccess;
  this.editdetails.address1=getedplt.address1;
  this.editdetails.address2=getedplt.address2;
  this.editdetails.city=getedplt.city;
  this.editdetails.state_province=getedplt.stateProvince;
  this.editdetails.postal_code=getedplt.postalCode;
  this.editdetails.country_name=getedplt.countryName;
  this.editdetails.building_name=getedplt.buildingName;
  this.editdetails.dock_ID=getedplt.dockID;
  this.editdetails.production_days=getedplt.productionDays;
  this.editdetails.shipping_details=getedplt.shippingDetails;
  this.editdetails.test_pack_mail_instns=getedplt.testPackMailInstns;
  this.editdetails.plant_active_stat=getedplt.plantActiveStat;


  this.orginalEditPlant=JSON.parse(JSON.stringify(this.editdetails));
  this.changedEditPlant=this.editdetails;


  console.log(this.editdetails);

  if(this.editdetails.country_name != null && this.editdetails.country_name.trim() != '')
  {
  for(let i=0;i <this.countrylist.length;i++)
  {
    console.log(this.countrylist[i].country_name)
    console.log(this.editdetails.country_name)
    if(this.editdetails.country_name==this.countrylist[i].country_name)
    {

    this.editselected_countrylist=this.countrylist[i];
    }

  }
}
else
{
  this.editselected_countrylist={country_name:''}
}




if(this.editdetails.plant_type_name != null && this.editdetails.plant_type_name.trim() != '')
{

      for(let i=0;i <this.planttypelist.length;i++)
      {
        if(this.editdetails.plant_type_name==this.planttypelist[i].plant_type_name)
        {
        this.editselected_planttypecode=this.planttypelist[i];
        }

      }
    }
    else
    {
      this.editselected_planttypecode={cd_plant_type:0,plant_type_name:'',active_stat:'',active_stat_display:false,updated_dt:'',updated_by:''}
    }




      console.log(this.editselected_planttypecode);



  }

  ))


  }

  cancel_editplantdetails()
  {
       this.enableeditplantdetails=false;
       this.enableaddbutton=true;
       this.editplantdetailsinit();
       this.plantvalidationInint();
      // this.resetEditPlantDetails();

  }


  geteditedplantdetails(editedplantdetails:any)
  {


    this.enabledetails=true;
    this.getplantdetailsinit();
    this.plantvalidationInint();

    if(this.enableeditgetdetails==true)
    {
      this.enableeditgetdetails=false;
    }
    if(this.enableeditplantdetails==true)
    {
      this.enableeditplantdetails=false;
    }

    if(this.enableaddbutton==true)
    {
      this.enableaddbutton=false;
    }

    if(this.enableadddetails==true)
    {
      this.enableadddetails=false;
    }




// service


this.transactionService.getplantdetails(editedplantdetails.cd_plant).subscribe((getplt => {
  // this.resultst=output;
  this.getdetails=getplt;



    console.log(this.getdetails);

    console.log('get plant details');
    console.log(editedplantdetails.cd_plant);

    if(this.getdetails.countryName != null && this.getdetails.countryName.trim() != '')
    {
    for(let i=0;i <this.countrylist.length;i++)
    {
      console.log(this.countrylist[i].country_name)
      console.log(this.getdetails.countryName)
      if(this.getdetails.countryName==this.countrylist[i].country_name)
      {

      this.getselected_countrylist=this.countrylist[i];
      }

    }
  }
  else
  {
    this.getselected_countrylist={country_name:''}
  }

if(this.getdetails.plantTypeName != null && this.getdetails.plantTypeName.trim() != '')
{

    for(let i=0;i <this.planttypelist.length;i++)
    {
      console.log(this.planttypelist[i].plant_type_name)
      console.log(this.getdetails.plantTypeName)
      if(this.getdetails.plantTypeName==this.planttypelist[i].plant_type_name)
      {

      this.getselected_planttypecode=this.planttypelist[i];
      }

    }
  }
  else
  {
    this.getselected_planttypecode={cd_plant_type:0,plant_type_name:'',active_stat:'',active_stat_display:false,updated_dt:'',updated_by:''}
  }

  }

  ))


  }


  save_editplantdetails()
  {


    console.log(this.editdetails);
    this.plantvalidationInint();

    this.editdetails.plant_type_name=this.editselected_planttypecode.plant_type_name
    if(this.editselected_countrylist.country_name != null)
    {
    this.editdetails.country_name=this.editselected_countrylist.country_name
    }
    else
    {
      this.editdetails.country_name=''
    }
  // this.editdetails.plant_type_name="vo";
  console.log('editdata',this.editdetails);

    this.validatePlantNameForEdit();
    this.validateDockIdForEdit();
    this.validateBuildingNameForEdit();
    this.validateProductionDaysForEdit();
    this.validateAddressForEdit();
    this.validateAddress2ForEdit();
    this.validateCityForEdit();
    this.validateStateForEdit();
    this.validatePostalCodeForEdit();
    this.validateShippingDetsForEdit();
    this.validateTestPackMailInstForEdit();
    this.validateIsPlantActiveForEdit();

    if(!this.plantNameInValid && !this.dockIDInValid && !this.buildingNameInValid && !this.addressInValid && !this.address2InValid
      && !this.cityInValid && !this.stateInValid && !this.postalCodeInValid && !this.shippingdetsInValid && !this.testPackMailInstInValid && !this.isPlantActiveInValid && !this.productionDaysInValid)
      {
        this.transactionService.saveplantdetails(this.editdetails).subscribe((saveplt => {
      // this.resultst=output;
      const res=saveplt;
      console.log('successful',res);
      if(res == 'success'){

        this.messageService.add({severity:'success', summary:'Success', detail:'Plant Edited Successfully'});
        this.enableeditplantdetails=false;
      this.enableeditgetdetails=false
      this.geteditedplantdetails(this.editdetails);
      this.getplantlist();
      this.plantvalidationInint();
      // this.resetEditPlantDetails();
      }

      else{
        this.plantvalidationInint();
        this.messageService.add({severity:'error', summary: 'Error', detail: 'Plant Not Edited,Please Try Again'});
        // this.addplantdetailsinit();
       }

      }

        ))
      }

  }

  onFilter(event:any,tbdata:any){
    console.log(event);
    console.log(tbdata);
  }

}
