/* tslint:disable */
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit,OnDestroy } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { MenuItem } from 'primeng';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ErrorHandlerService } from '../loginpage/error-handler.service';
import { TransactionService } from '../loginpage/Transaction.service';
import { NavComponent } from '../nav/nav.component';

@Component({
  selector: 'app-useradmin',
  templateUrl: './useradmin.component.html',
  styleUrls: ['./useradmin.component.css']
})
export class UseradminComponent implements OnInit,OnDestroy {


  tabUserAdminMenuItems: MenuItem[]=[];
  activeItemUserAdm: MenuItem;
  regionUserAdm:string;

  nameUserAdm:string;
  pendingAccesRequestPathUserAdm:string;
  editAccessUserAdm:string;


  regionCodeUserAdm:string="";
  cdsidUserAdm:string="";


  routingByRoleUserAdm:any[]=[];
  selectedRoutingByRoleUserAdm:any[]=[];
  currentRouteIdUserAdm:number=0;
  errorMessage: string;



  loaderusr:boolean=false;




  constructor(private transactionService: TransactionService,private router:Router,private navigation:NavComponent, private errorHandler:ErrorHandlerService) { }

  ngOnInit():void {


    this.regionUserAdm=this.transactionService.getregion();
    this.regionCodeUserAdm=this.transactionService.getregioncode(this.regionUserAdm);
    this.cdsidUserAdm=this.transactionService.getcdsid();




    console.log("inside user admin page")

    this.currentRouteIdUserAdm= this.transactionService.getrouteid(this.regionUserAdm);
    if(this.regionUserAdm=="Asia Pacific & Africa")
    {
      this.nameUserAdm="asiapacific";
    }

    else if(this.regionUserAdm=="Europe")
    {
      this.nameUserAdm="europe";
    }

    else if (this.regionUserAdm=="North America")
    {
     this.nameUserAdm="northamerica";
    }

    else if(this.regionUserAdm=="South America")
    {
     this.nameUserAdm="southamerica";
    }

    this.pendingAccesRequestPathUserAdm='/'+this.nameUserAdm+'/packagingprocess'+'/useradmin/PendingAccessRequest';
    this.editAccessUserAdm='/'+this.nameUserAdm+'/packagingprocess'+'/useradmin/editaccess';
    var val=false;


  /*  this.tabUserAdminMenuItems =
    [
      {label: 'Pending Access Requests',
      routerLink: this.pendingAccesRequestPath, icon: 'fas fa-industry'},
      {label: 'Edit Access',
      routerLink: this.editAccess, icon: 'pi pi-user-edit'}
    ];*/




    this.routingByRoleUserAdm=[
      {funcid:"45,46",value: {label: 'Pending Access Requests', routerLink: this.pendingAccesRequestPathUserAdm, icon: 'fas fa-industry'},id:51},
      {funcid:"45,46",value: {label: 'Edit Access',routerLink: this.editAccessUserAdm, icon: 'pi pi-user-edit'},id:52},

  ]

  this.selectedRoutingByRoleUserAdm=[];

  /*if(document.getElementById("overlay") != null )
  {
  document.getElementById("overlay").style.display = "block";
  }

  if(document.getElementById("loader") != null )
  {
  document.getElementById("loader").style.display = "block";
  }*/


  this.loaderusr=true;
  this.loadRoles();

  this.transactionService.getUserAccessRole(this.cdsidUserAdm).subscribe(out=>{
    /*{next :(out :any)=>{*/

    this.tabUserAdminMenuItems=[];
    console.log(out);
    var rolesUserAdm=[];
    if(out!=null && out.length!=0)
    {
      var roleidStringUserAdm="";


      var glbIndexPresentUserAdm=out.findIndex(elglbpres=>elglbpres.role_id==100);
      if(glbIndexPresentUserAdm!=-1)
      {


        if(JSON.parse(sessionStorage.getItem('isGlobalAdmin'))==false)
					{
						sessionStorage.removeItem('isGlobalAdmin');
					sessionStorage.setItem('isGlobalAdmin', JSON.stringify(true));

					this.updateRegionList();
					}



        rolesUserAdm.push({roleid:100,roles:[]})
        roleidStringUserAdm="100";

      }



    for(var regUserAdm=0;regUserAdm<out.length;regUserAdm++)
    {
      if(out[regUserAdm].cd_region==this.regionCodeUserAdm)
      {
        if(rolesUserAdm.length==0)
        {

            rolesUserAdm.push({roleid:out[regUserAdm].role_id,roles:[]})
             roleidStringUserAdm=out[regUserAdm].role_id.toString();
        }
        else{


          var role_presentUserAdm=false;

          for(var lenUserAdm=0;lenUserAdm<rolesUserAdm.length;lenUserAdm++)
          {


            if(out[regUserAdm].role_id==rolesUserAdm[lenUserAdm])
            {

              role_presentUserAdm=true;
              break;

            }

          }


          if(role_presentUserAdm==false)
          {
            rolesUserAdm.push({roleid:out[regUserAdm].role_id,roles:[]})
            roleidStringUserAdm+=",";
            roleidStringUserAdm+=out[regUserAdm].role_id.toString();
          }
        }
      }
    }


    console.log(rolesUserAdm);


    if(rolesUserAdm.length!=0)
    {



      this.transactionService.getuserrolefunctionality(roleidStringUserAdm,this.regionCodeUserAdm).subscribe(userrolefuncaccess=>{


        console.log(userrolefuncaccess);

        if(userrolefuncaccess!=null || userrolefuncaccess.length!=0)
        {
        for(var ieuUserAdm=0;ieuUserAdm<rolesUserAdm.length;ieuUserAdm++)
        {
          rolesUserAdm[ieuUserAdm].roles=[]
          var rolefuncUserAdm=[];
          for(var jeuUserAdm=0;jeuUserAdm<userrolefuncaccess.length;jeuUserAdm++)
          {
            if(rolesUserAdm[ieuUserAdm].roleid==userrolefuncaccess[jeuUserAdm].role_id)
            {


              rolesUserAdm[ieuUserAdm].roles.push(userrolefuncaccess[jeuUserAdm]);


            }
          }
        }


        var nameUserAdm=this.regionCodeUserAdm+"useraccess";
        sessionStorage.setItem(nameUserAdm,JSON.stringify(rolesUserAdm));


        console.log("usrrole",rolesUserAdm)


        this.selectedRoutingByRoleUserAdm=[];
        this.tabUserAdminMenuItems=[];


      var all_presentUserAdm=false

        for(var iUserAdm=0;iUserAdm<rolesUserAdm.length;iUserAdm++)
        {
          for(var jUserAdm=0; jUserAdm<this.routingByRoleUserAdm.length; jUserAdm++)
          {
            var roles_arrUserAdm=this.routingByRoleUserAdm[jUserAdm].funcid.split(',');
            for(var ikUserAdm of roles_arrUserAdm)
            {
              var routeIndexUserAdm=rolesUserAdm[iUserAdm].roles.findIndex(elrte=>elrte.sysfunc_id.toString()==ikUserAdm)


              if(routeIndexUserAdm!=-1)
              {
                break;
              }

            }

             if(routeIndexUserAdm!=-1)
             {
               if(this.tabUserAdminMenuItems.length!=0)
               {

                var prsntIndexUserAdm=this.selectedRoutingByRoleUserAdm.findIndex(elpre=>elpre.id==this.routingByRoleUserAdm[jUserAdm].id)

                if(prsntIndexUserAdm==-1)
                {
                  this.tabUserAdminMenuItems.push(this.routingByRoleUserAdm[jUserAdm].value);
                  this.selectedRoutingByRoleUserAdm.push(this.routingByRoleUserAdm[jUserAdm]);
                }


                if(this.tabUserAdminMenuItems.length==this.routingByRoleUserAdm.length)
                {
                  all_presentUserAdm=true;
                  break;
                }

               }
               else
               {
                 this.tabUserAdminMenuItems.push(this.routingByRoleUserAdm[jUserAdm].value);
                 this.selectedRoutingByRoleUserAdm.push(this.routingByRoleUserAdm[jUserAdm]);
               }
             }
          }


          if(all_presentUserAdm==true)
          {
            break;
          }
        }


        console.log("admin",this.tabUserAdminMenuItems);



        var route_presentUserAdm=false;

if(this.currentRouteIdUserAdm!=null && this.currentRouteIdUserAdm!= undefined)
{
  if(this.currentRouteIdUserAdm==51 || this.currentRouteIdUserAdm==52 )
  {

if(this.selectedRoutingByRoleUserAdm.length!=0)
{
    for(var rlsUserAdm=0; rlsUserAdm<this.selectedRoutingByRoleUserAdm.length; rlsUserAdm++)
    {

      if(this.selectedRoutingByRoleUserAdm[rlsUserAdm].id==this.currentRouteIdUserAdm)
      {


        var testUserAdm=this.selectedRoutingByRoleUserAdm[rlsUserAdm].value
        console.log(testUserAdm);
        this.activeItemUserAdm=testUserAdm;
        route_presentUserAdm=true;
        console.log("naviagteuseradm")
        //document.getElementById("loader").style.display = "none";
        //document.getElementById("overlay").style.display = "none";
        this.loaderusr=false;
        this.router.navigate([this.activeItemUserAdm.routerLink]);




       // this.activeItem.push(this.routing_by_role[rls].value);
        break;

      }

    }
  }



    if(route_presentUserAdm==false)
    {
      var test_newUserAdm:MenuItem
  test_newUserAdm=this.tabUserAdminMenuItems[0];

  if(this.tabUserAdminMenuItems.length==0)
{
  this.routeRegion();
}

else
{

  console.log(test_newUserAdm);
  this.activeItemUserAdm=test_newUserAdm;
  console.log("naviagteuseradm1")
  //document.getElementById("loader").style.display = "none";
  //document.getElementById("overlay").style.display = "none";
  this.loaderusr=false;
  this.router.navigate([this.activeItemUserAdm.routerLink]);
}

    }

  }
  else
{
  var test_newUserAdm:MenuItem
  test_newUserAdm=this.tabUserAdminMenuItems[0];
  if(this.tabUserAdminMenuItems.length==0)
  {
    this.routeRegion();
  }

  else
  {
  console.log(test_newUserAdm);
  this.activeItemUserAdm=test_newUserAdm;
  console.log("naviagteuseradm2")
  //document.getElementById("loader").style.display = "none";
  //document.getElementById("overlay").style.display = "none";
  this.loaderusr=false;
  this.router.navigate([this.activeItemUserAdm.routerLink]);
  }
}

}




else{

  var test_new_sUserAdm=this.tabUserAdminMenuItems[0];
  if(this.tabUserAdminMenuItems.length==0)
  {
    this.routeRegion();
  }

  else
  {
  console.log(test_new_sUserAdm);
  this.activeItemUserAdm=test_new_sUserAdm;
  console.log("naviagteuseradm3")
  //document.getElementById("loader").style.display = "none";
  //document.getElementById("overlay").style.display = "none";
  this.loaderusr=false;
  this.router.navigate([this.activeItemUserAdm.routerLink]);
  }
}

        }
        else
        {
          this.routeRegion();
        }





      })

}
else
{
  this.routeRegion();
}
}

else
{
  this.routeRegion();
}









/*
   },
   error: (err: HttpErrorResponse) => {

    this.errorHandler.handleError(err);
    this.errorMessage = this.errorHandler.errorMessage;
}*/
  })



  }

  routeRegion()
  {

    console.log("regpck",this.regionCodeUserAdm);

    if(this.regionCodeUserAdm=="NA")

    {

   this.navigation.navigatena(true);

    }
    else if(this.regionCodeUserAdm=="AP")
    {

   this.navigation.navigateap(true);

    }
    else if(this.regionCodeUserAdm=="EU")
    {
   this.navigation.navigateeu(true);
    }
    else if(this.regionCodeUserAdm=="SA")
    {
      console.log("insodesapack")
   this.navigation.navigatesa(true);
    }

  }



  loadRoles()
  {


    console.log("regpck",this.regionCodeUserAdm);

    if(this.regionCodeUserAdm=="NA")

    {
   this.navigation.navigateap(false);
   this.navigation.navigateeu(false);
   this.navigation.navigatesa(false);

    }
    else if(this.regionCodeUserAdm=="AP")
    {

      this.navigation.navigatena(false);
      this.navigation.navigateeu(false);
      this.navigation.navigatesa(false);

    }
    else if(this.regionCodeUserAdm=="EU")
    {

      this.navigation.navigatena(false);
      this.navigation.navigateap(false);
      this.navigation.navigatesa(false);
    }
    else if(this.regionCodeUserAdm=="SA")
    {
      console.log("insodesapack")

      this.navigation.navigatena(false);
      this.navigation.navigateeu(false);
      this.navigation.navigateap(false);
    }

  }



  updateRegionList()
	{


		console.log("update")
		var glbadminUserAdm=JSON.parse(sessionStorage.getItem('isGlobalAdmin'))
		if(glbadminUserAdm==true)
		{


			var regionlistupdateUserAdm=JSON.parse(sessionStorage.getItem('user_regionaccess'))


			if(regionlistupdateUserAdm.length!=4)
			{
				var user_regionUserAdm=[];
				var regionListactUserAdm=["AP","EU","NA","SA"]

				user_regionUserAdm.push(...regionListactUserAdm);


				sessionStorage.removeItem('user_regionaccess');
						   sessionStorage.setItem('user_regionaccess', JSON.stringify(user_regionUserAdm));

			}




		}
	}



  ngOnDestroy(){
    console.log("testuseradm");

  }



}
