import { Component, OnInit } from '@angular/core';
import { TransactionService } from '../loginpage/Transaction.service';

@Component({
  selector: 'app-inactiveuserpage',
  templateUrl: './inactiveuserpage.component.html',
  styleUrls: ['./inactiveuserpage.component.css']
})
export class InactiveuserpageComponent implements OnInit {

  userid:string;

  constructor(private transactionService:TransactionService) { }

  ngOnInit(): void {


   this.userid = this.transactionService.getcdsid();

  }

}
