/* tslint:disable */
import { Component, OnInit,ViewEncapsulation, ViewChild, ElementRef  } from '@angular/core';





import {TransactionService} from '../loginpage/Transaction.service';
import { Router,ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import {plantlist,addplant,getplant,planttype,countrylist, useraccessrole,subpltlist,subprglist,subcoslist, substatuslist, subgrid, subtradco, selec_prt_pro_details, subnewprt_latesbom, subnewpart_latestbom_designsource, supp_country,containerOwnershipList, approver_grid_list, fromdiplayvalues_all} from '../loginpage/transactions';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';



export interface test{
  plantGSDBCode:string;
  cd_plant:string;
  plant_name:string;
}


export interface RevisionObj
{
  rev_string:string;
  rev_html_string:string;
}

@Component({
  selector: 'app-testpage',
  templateUrl: './testpage.component.html',
  styles: ['./testpage.component.css'],

})






export class TestpageComponent {


  view_proposal:boolean=false;
  region:string;
  region_code:string;
  selectedsimpliedpartnodetails_prlim_apr:selec_prt_pro_details={simplifiedpartno:"-",bompartmasterid:0,proposalid:0,plantgsdbcode:"",proposallastsaved:0,part_status:"",supplier_code:"",proposallastsaved_ee:0,is_ee_proposal:false,currentview_ee:false,from_draft:false,propsl_type:"",imp_part_status:""};
  proposal_details_prelim:fromdiplayvalues_all;
  revision_obj:RevisionObj[]=[];

  enabledetails:boolean=false;



  constructor(private transactionService: TransactionService,private router:Router,private date:DatePipe,private route: ActivatedRoute) { }



  proposalview(prop:boolean)
  {
    console.log("prop",prop);
    this.view_proposal=prop



    this.region=this.transactionService.getregion();
this.region_code=this.transactionService.getregioncode(this.region);
this.selectedsimpliedpartnodetails_prlim_apr=this.transactionService.getpartno_packpro(this.region);

  }


  view_revision_history()
{


  this.enabledetails=true;
  console.log("revision history");
 // this.transactionService.loadtransacmasterdetails(this.selectedsimpliedpartnodetails_prlim_apr.proposalid).subscribe(transdet=>{

 var transdet="2/8/2022`submitted`pmonish1|5/8/2022`preliminary approved`sbalaj44|"
    console.log(transdet);
   // this.proposal_details_prelim=transdet;


   // console.log(this.proposal_details_prelim.revision_hstry);
    var revision_arr=[];
    this.revision_obj=[];

   // if(this.proposal_details_prelim.revision_hstry!=null)
   if(transdet!=null)
    {
      // revision_arr=this.proposal_details_prelim.revision_hstry.split(';')
      revision_arr=transdet.split('~^')
      console.log(revision_arr)
           for(var i=0;i<revision_arr.length-1;i++)
           {
             var rev_arr=[];
             var rev_htm_str="";
             rev_arr=revision_arr[i].split(',');


             rev_htm_str=rev_arr[0]+";"+rev_arr[2]+";"+"<br>"+rev_arr[1];


            this.revision_obj.push({rev_string:revision_arr[i],rev_html_string:rev_htm_str})

           }

    }


    console.log(this.revision_obj);


    this.revision_obj=this.revision_obj.reverse();
    console.log(this.revision_obj)

 // })
}


close_revision_history()
{
  this.enabledetails=false;

}











}
