/* tslint:disable */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TransactionService } from '../loginpage/Transaction.service';

@Component({
  selector: 'notaccesspage',
  templateUrl: './notaccesspage.component.html',
  styleUrls: ['./notaccesspage.component.scss']
})
export class NotaccesspageComponent implements OnInit {
 userid:string;
 isValid:boolean=false;
 url:string="";
  constructor(private router:Router,private transactionService:TransactionService) { }

  ngOnInit(): void {


   this.userid = this.transactionService.getcdsid();



   this.url=this.router.url;



  }

}
