import {
	Component,
	OnInit
} from '@angular/core';
import {environment} from '../../environments/environment';

/** This component handles redirecting users to the auth provider */
@Component({
	template: `
        <h3>Redirecting to login server</h3>
	`
})
export class LoginComponent implements OnInit {
	// @ts-ignore
    private tokenUrl = environment.tokenUrl;
    // @ts-ignore
	private clientId = environment.clientId;
    // @ts-ignore
	private redirectUrl = environment.redirectUrl;
    // @ts-ignore
	private resource = environment.resource;

	tokenFullUrl = this.tokenUrl + '?client_id=' + this.clientId + '&response_type=token+id_token&redirect_uri=' +
		this.redirectUrl + '&resource=' + this.resource;

	/** once the component is up and running, redirect the user to the auth provider */
	ngOnInit() {
		window.location.replace(this.tokenFullUrl);
	}
}
