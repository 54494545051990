/* tslint:disable */
import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { AppComponent } from './app.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-editor',
    templateUrl: './app.editor.component.html'
})
export class AppEditorComponent implements OnInit {

    sass: any;

    editorStyle: any;

    variables: string;

    originalVariables: string;

    compiling: boolean;

    @ViewChild('highlightsCode', {static: false}) highlightsCode: ElementRef;

    constructor(public app: AppComponent, private sanitizer: DomSanitizer) { }

    ngOnInit() {
this.variables = `
// ******************************************************
// ******     START PrimeNG Designer Variables     ******
// ******************************************************

//main color scheme
$primaryColor: $CTA-icon-cyan;
$primaryDarkColor: $FDS-uniting-blue;
$primaryDarkerColor: $FDS-ford-blue;
$primaryTextColor: $white;

//Global
$fontFamily:$fontFamilyDefault;
$fontSize:16px;
$textColor: $charcoal;
$textSecondaryColor: $FDS-dark-gray;
$borderRadius:3px;
$transitionDuration:.2s;
$primeIconFontSize:1em;

//anchors
$linkColor:$primaryDarkerColor;
$linkHoverColor: $primaryDarkColor;
$linkActiveColor:$primaryDarkerColor;

//highlight
$highlightBgColor:$primaryDarkColor;
$highlightColorText:$primaryTextColor;

//focus
$focusOutlineColor: lighten($primaryColor, 35%);
$focusOutline: 0 none;
$focusOutlineOffset: 0;
$focusShadow: 0 0 0 0.2em $focusOutlineColor;
//input field (e.g. inputtext, spinner, inputmask)
$inputPadding:0.429em;
$inputBgColor: $white;
$inputBorder:1px solid $FDS-disabled-dark_light-background;
$inputHoverBorderColor: $primaryDarkColor;
$inputFocusBorderColor:$primaryColor;

$inputErrorBorder:1px solid $FDS-error;
$inputPlaceholderTextColor: $FDS-dark-gray;
$inputTransition:border-color $transitionDuration;

//input groups
$inputGroupBorderColor: $primaryDarkerColor;
$inputGroupBgColor: $primaryDarkColor;
$inputGroupTextColor:$primaryTextColor;

//input lists (e.g. dropdown, autocomplete, multiselect, orderlist)
$inputListMinWidth:12em;
$inputListBgColor: $white;
$inputListPadding:0;
$inputListItemPadding:0.429em 0.857em;
$inputListItemBgColor:transparent;
$inputListItemTextColor:$textColor;
$inputListItemHoverBgColor: $primaryDarkColor;
$inputListItemHoverTextColor:$primaryTextColor;
$inputListItemHighlightBgColor:$highlightBgColor;
$inputListItemHighlightTextColor:$highlightColorText;
$inputListItemBorder:0 none;
$inputListItemMargin:0;
$inputListItemFocusShadow: inset 0 0 0 0.2em lighten($primaryColor, 35%);
$inputListItemTransition: background-color $transitionDuration, box-shadow $transitionDuration;
$inputListItemDividerColor: $FDS-disabled-dark_light-background;
$inputListHeaderPaddingTop:0.429em;
$inputListHeaderPaddingLeft:0.857em;
$inputListHeaderPaddingRight:0.857em;
$inputListHeaderPaddingBottom:0.429em;
$inputListHeaderMargin:0;
$inputListHeaderBgColor: $white;
$inputListHeaderTextColor:$textColor;
$inputListHeaderBorder:1px solid $FDS-disabled-dark_light-background;
$inputListHeaderSearchIconColor:$primaryColor;
$inputListHeaderCloseIconColor:$textSecondaryColor;
$inputListHeaderCloseIconHoverColor:$textColor;
$inputListHeaderCloseIconTransition:color $transitionDuration;

//inputs with panels (e.g. password)
$inputContentPanelPadding:12px;
$inputContentPanelBgColor: $white;
$inputContentPanelTextColor:$textColor;

//static positioned input lists like listbox
$inputListBorder:1px solid $FDS-disabled-dark_light-background;

//inputs with overlays (e.g. autocomplete, dropdown, multiselect)
$inputOverlayBorder:1px solid $FDS-surface;
$inputOverlayShadow:0 3px 6px 0 rgba(0, 0, 0, 0.16);

//input dropdowns (e.g. multiselect, dropdown)
$inputDropdownIconColor:$textSecondaryColor;

//button
$buttonTextOnlyPadding:0.429em 1em;
$buttonWithLeftIconPadding:0.429em 1em 0.429em 2em;
$buttonWithRightIconPadding:0.429em 2em 0.429em 1em;
$buttonIconOnlyPadding:0.429em;
$buttonIconOnlyWidth:2.357em;
$buttonBgColor:$primaryDarkerColor;
$buttonBorder:1px solid $primaryDarkerColor;
$buttonTextColor:$primaryTextColor;
$buttonHoverBgColor:$primaryDarkColor;
$buttonHoverTextColor:$primaryTextColor;
$buttonHoverBorderColor: $primaryDarkColor;
$buttonActiveBgColor: $primaryDarkColor;
$buttonActiveTextColor:$primaryTextColor;
$buttonActiveBorderColor:$primaryDarkColor;
$buttonFocusBorderColor: $primaryDarkColor;
$buttonFocusOutline:0 none;
$buttonFocusOutlineOffset:0px;
$buttonFocusShadow:0 0 0 0.1em lighten($primaryColor, 35%);
$buttonTransition:background-color $transitionDuration,box-shadow $transitionDuration;
$raisedButtonShadow:0 2px 3px 0 rgba(0, 0, 0, 0.15);
$roundedButtonBorderRadius:15px;

$secondaryButtonBgColor: $FDS-surface;
$secondaryButtonBorder:1px solid $FDS-disabled-dark_light-background;
$secondaryButtonTextColor: $charcoal;
$secondaryButtonHoverBgColor: darken($white, 10%);
$secondaryButtonHoverTextColor: $charcoal;
$secondaryButtonHoverBorderColor: $FDS-dark-gray;
$secondaryButtonActiveBgColor: $white;
$secondaryButtonActiveTextColor: $charcoal;
$secondaryButtonActiveBorderColor: $FDS-dark-gray;
$secondaryButtonFocusShadow:0 0 0 0.1em lighten($primaryColor, 35%);

$infoButtonBgColor:$primaryColor;
$infoButtonBorder:1px solid $primaryDarkColor;
$infoButtonTextColor:$primaryTextColor;
$infoButtonHoverBgColor:$primaryDarkColor;
$infoButtonHoverTextColor:$primaryTextColor;
$infoButtonHoverBorderColor:$primaryColor;
$infoButtonActiveBgColor:$primaryDarkColor;
$infoButtonActiveTextColor:$primaryTextColor;
$infoButtonActiveBorderColor:$primaryColor;
$infoButtonFocusShadow:0 0 0 0.1em lighten($infoButtonBgColor, 35%);

$successButtonBgColor: $FDS-success;
$successButtonBorder:1px solid $FDS-success;
$successButtonTextColor:$primaryTextColor;
$successButtonHoverBgColor:darken($successButtonBgColor, 10%);
$successButtonHoverTextColor:$primaryTextColor;
$successButtonHoverBorderColor: $FDS-success;
$successButtonActiveBgColor:darken($successButtonHoverBgColor, 10%);
$successButtonActiveTextColor:$primaryTextColor;
$successButtonActiveBorderColor: $FDS-success;
$successButtonFocusShadow:0 0 0 0.1em lighten($successButtonBgColor, 35%);

$warningButtonBgColor: $FDS-caution_graphical-objects;
$warningButtonBorder:1px solid $FDS-caution_graphical-objects;
$warningButtonTextColor:$primaryTextColor;
$warningButtonHoverBgColor:darken($warningButtonBgColor, 15%);
$warningButtonHoverTextColor:$primaryTextColor;
$warningButtonHoverBorderColor: $FDS-caution_graphical-objects;
$warningButtonActiveBgColor:darken($warningButtonHoverBgColor, 15%);
$warningButtonActiveTextColor:$primaryTextColor;
$warningButtonActiveBorderColor: $FDS-caution_graphical-objects;
$warningButtonFocusShadow:0 0 0 0.1em lighten($warningButtonBgColor, 35%);

$dangerButtonBgColor: $FDS-error;
$dangerButtonBorder:1px solid $FDS-error;
$dangerButtonTextColor:$primaryTextColor;
$dangerButtonHoverBgColor:darken($FDS-error, 10%);
$dangerButtonHoverTextColor:$primaryTextColor;
$dangerButtonHoverBorderColor: $FDS-error;
$dangerButtonActiveBgColor:darken($dangerButtonHoverBgColor, 10%);
$dangerButtonActiveTextColor:$primaryTextColor;
$dangerButtonActiveBorderColor: $FDS-error;
$dangerButtonFocusShadow:0 0 0 0.1em lighten($dangerButtonBgColor, 35%);

//checkbox
$checkboxWidth:20px;
$checkboxHeight:20px;
$checkboxActiveBorderColor:$primaryDarkColor;
$checkboxActiveBgColor:$primaryDarkColor;
$checkboxActiveTextColor:$primaryTextColor;
$checkboxActiveHoverBgColor:$primaryDarkColor;
$checkboxActiveHoverTextColor:$primaryTextColor;
$checkboxActiveHoverBorderColor:$primaryDarkColor;

$checkboxTransition:background-color $transitionDuration, border-color $transitionDuration, box-shadow $transitionDuration;

//radiobutton
$radiobuttonWidth:20px;
$radiobuttonHeight:20px;
$radiobuttonActiveBorderColor:$primaryDarkerColor;
$radiobuttonActiveBgColor:$primaryDarkColor;
$radiobuttonActiveTextColor:$primaryTextColor;
$radiobuttonActiveHoverBgColor:$primaryDarkColor;
$radiobuttonActiveHoverTextColor:$primaryTextColor;
$radiobuttonActiveHoverBorderColor:$primaryDarkColor;

$radiobuttonTransition:background-color $transitionDuration, border-color $transitionDuration, box-shadow $transitionDuration;

//togglebutton
$toggleButtonBgColor: $buttonBgColor;
$toggleButtonBorder: $buttonBorder;
$toggleButtonTextColor: $buttonTextColor;
$toggleButtonIconColor: $primaryTextColor;
$toggleButtonHoverBgColor: $buttonHoverBgColor;
$toggleButtonHoverBorderColor: $buttonHoverBorderColor;
$toggleButtonHoverTextColor: $buttonHoverTextColor;
$toggleButtonHoverIconColor: $primaryTextColor;
$toggleButtonActiveBgColor: $buttonActiveBgColor;
$toggleButtonActiveBorderColor: $buttonActiveBorderColor;
$toggleButtonActiveTextColor: $buttonActiveTextColor;
$toggleButtonActiveIconColor: $primaryTextColor;
$toggleButtonActiveHoverBgColor: $primaryDarkColor;
$toggleButtonActiveHoverBorderColor: $primaryDarkColor;
$toggleButtonActiveHoverTextColor: $primaryTextColor;
$toggleButtonActiveHoverIconColor: $primaryTextColor;


//inplace
$inplacePadding:$inputPadding;
$inplaceHoverBgColor: $FDS-surface;
$inplaceHoverTextColor:$textColor;
$inplaceTransition:background-color $transitionDuration;

//rating
$ratingTransition:color $transitionDuration;
$ratingCancelIconColor:$CTA-light-cyan;
$ratingCancelHoverIconColor:$primaryColor;
$ratingIconFontSize:20px;
$ratingStarIconColor:$textColor;
$ratingStarIconHoverColor:$primaryColor;

//slider
$sliderBgColor: $FDS-disabled-light_dark-background;
$sliderBorder:0 none;
$sliderHeight:.286em;
$sliderWidth:0.286em;
$sliderHandleWidth:1.143em;
$sliderHandleHeight:1.143em;
$sliderHandleBgColor: $white;
$sliderHandleBorder:2px solid $FDS-dark-gray;
$sliderHandleBorderRadius:100%;
$sliderHandleHoverBorder:2px solid $primaryColor;
$sliderHandleHoverBgColor:2px solid $FDS-dark-gray;
$sliderHandleTransition:border-color $transitionDuration;
$sliderRangeBgColor:$primaryDarkColor;

//calendar
$calendarWidth:20em;
$calendarNavIconColor: $FDS-disabled-dark_light-background;
$calendarNavIconHoverColor:$primaryColor;
$calendarNavIconTransition:color $transitionDuration;
$calendarPadding:0.857em;
$calendarTableMargin:0.857em 0 0 0;
$calendarHeaderCellPadding:.5em;
$calendarCellDatePadding:.5em;
$calendarCellDateHoverBgColor: $primaryDarkColor;
$calendarCellDateBorderRadius:$borderRadius;
$calendarCellDateSelectedBgColor:$primaryDarkColor;
$calendarCellDateSelectedTextColor:$primaryTextColor;
$calendarCellDateTodayBgColor: $primaryDarkerColor;
$calendarCellDateTodayTextColor:$primaryTextColor;
$calendarTimePickerDivider: 1px solid $FDS-dark-gray;
$calendarTimePickerPadding:0.857em;
$calendarTimePickerIconColor:$textColor;
$calendarTimePickerIconFontSize:1.286em;
$calendarTimePickerTimeFontSize:1.286em;
$calendarTimePickerIconHoverColor:$primaryColor;
$calendarButtonBarDivider: 1px solid $FDS-disabled-dark_light-background;
$calendarMultipleMonthDivider: 1px solid $FDS-disabled-dark_light-background;

//spinner
$spinnerButtonWidth:2em;

//input switch
$inputSwitchWidth:3em;
$inputSwitchHeight:1.75em;
$inputSwitchBorderRadius:30px;
$inputSwitchTransition:background-color $transitionDuration;
$inputSwitchSliderOffBgColor: $FDS-disabled-light_dark-background;
$inputSwitchHandleOffBgColor: $white;
$inputSwitchSliderOffHoverBgColor: $FDS-disabled-dark_light-background;
$inputSwitchSliderOffFocusBgColor: $FDS-disabled-dark_light-background;
$inputSwitchSliderOnBgColor:$primaryColor;
$inputSwitchSliderOnHoverBgColor:$primaryDarkColor;
$inputSwitchHandleOnBgColor: $FDS-disabled-dark_light-background;
$inputSwitchSliderOnFocusBgColor:$primaryDarkerColor;

//panel common (e.g. accordion, panel, tabview)
$panelHeaderBorder:1px solid $primaryDarkerColor;
$panelHeaderBgColor: $primaryDarkerColor;
$panelHeaderTextColor: $primaryTextColor;
$panelHeaderIconColor:$primaryTextColor;
$panelHeaderIconHoverColor: $primaryTextColor;
$panelHeaderIconTransition:color $transitionDuration;
$panelHeaderFontWeight:400;
$panelHeaderPadding:0.571em 1em;
$panelContentBorder:1px solid $primaryDarkerColor;
$panelContentBgColor: $white;
$panelContentTextColor:$textColor;
$panelContentPadding:0.571em 1em;
$panelFooterBorder:1px solid $primaryDarkerColor;
$panelFooterBgColor: $FDS-surface;
$panelFooterTextColor:$primaryTextColor;
$panelFooterPadding:0.571em 1em;
$panelHeaderHoverBgColor: $primaryDarkColor;
$panelHeaderHoverBorder:1px solid $FDS-disabled-dark_light-background;
$panelHeaderHoverTextColor: $primaryTextColor;
$panelHeaderHoverIconColor: $primaryTextColor;
$panelHeaderActiveBgColor:$primaryDarkColor;
$panelHeaderActiveBorder:1px solid $FDS-dark-gray;
$panelHeaderActiveTextColor:$primaryTextColor;
$panelHeaderActiveIconColor:$primaryTextColor;
$panelHeaderActiveHoverBgColor:$primaryDarkColor;
$panelHeaderActiveHoverBorder:1px solid $FDS-dark-gray;
$panelHeaderActiveHoverTextColor:$primaryTextColor;
$panelHeaderActiveHoverIconColor:$primaryTextColor;
$panelHeaderTransition:background-color $transitionDuration;

//accordion
$accordionSpacing:2px;

//tabview
$tabsNavBorder:0 none;
$tabsNavBgColor:transparent;
$tabHeaderSpacing: 2px;

//scrollpanel
$scrollPanelHandleBgColor: $FDS-surface;
$scrollPanelTrackBorder:0 none;
$scrollPanelTrackBgColor: $FDS-surface;

//card
$cardShadow:0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);

//paginator
$paginatorBgColor: $white;
$paginatorBorder: none;
$paginatorPadding:0;
$paginatorTextColor:$textColor;
$paginatorIconColor:$textColor;
$paginatorElementWidth:2.286em;
$paginatorElementHeight:2.286em;
$paginatorElementHoverBgColor: $primaryDarkerColor;
$paginatorElementHoverIconColor: $primaryTextColor;
$paginatorElementBorderRadius:3px;
$paginatorElementMargin:0;
$paginatorElementPadding:0;
$paginatorElementBorder:0 none;

//table
$tableCaptionFontWeight:700;
$tableSummaryFontWeight:700;
$tableHeaderCellPadding:0.571em 0.857em;
$tableHeaderCellBgColor:$primaryDarkerColor;
$tableHeaderCellTextColor:$primaryTextColor;
$tableHeaderCellFontWeight:700;
$tableHeaderCellBorder:1px solid $FDS-disabled-dark_light-background;
$tableHeaderCellHoverBgColor:$primaryDarkColor;
$tableHeaderCellHoverTextColor: $FDS-surface;
$tableHeaderCellIconColor:$primaryTextColor;
$tableHeaderCellHoverIconColor: $FDS-surface;
$tableBodyRowBgColor:$white;
$tableBodyRowTextColor:$textColor;
$tableBodyRowEvenBgColor:$even-row-color;
$tableBodyRowHoverBgColor: $FDS-disabled-dark_light-background;
$tableBodyRowHoverTextColor:$primaryTextColor;
$tableBodyCellBorder:1px solid $FDS-disabled-dark_light-background;
$tableBodyCellPadding:none;
$tableFooterCellPadding:none;
$tableFooterCellBgColor:$primaryDarkerColor;
$tableFooterCellTextColor:$primaryTextColor;
$tableFooterCellFontWeight:700;
$tableFooterCellBorder:1px solid $FDS-disabled-dark_light-background;
$tableResizerHelperBgColor:$primaryColor;

//schedule
$scheduleEventBgColor:$primaryDarkColor;
$scheduleEventBorder:1px solid $primaryDarkColor;
$scheduleEventTextColor:$primaryTextColor;

//tree
$treeNodePadding:0.143em 0;
$treeNodeLabelPadding:0.286em;
$treeNodeContentSpacing:0.143em;

//lightbox
$lightBoxNavIconFontSize:3em;
$lightBoxNavIconColor:$primaryTextColor;

//org chart
$organizationChartConnectorColor: $FDS-surface;

//messages
$messagesMargin:1em 0;
$messagesPadding:1em;
$messagesIconFontSize:2em;
$messageCloseIconFontSize:1.5em;

//message
$messagePadding:.429em;
$messageMargin: 0;
$messageIconFontSize: 1.25em;
$messageTextFontSize: 1em;

//toast
$toastShadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
$toastMessageMargin:0 0 1em 0;

//severities
$infoMessageBgColor:$CTA-light-cyan;
$infoMessageBorder:0 none;
$infoMessageTextColor: $primaryTextColor;
$infoMessageIconColor: $primaryTextColor;
$successMessageBgColor: $successButtonBgColor;
$successMessageBorder:0 none;
$successMessageTextColor: $primaryTextColor;
$successMessageIconColor: $primaryTextColor;
$warnMessageBgColor: $warningButtonBgColor;
$warnMessageBorder:0 none;
$warnMessageTextColor: $primaryTextColor;
$warnMessageIconColor: $primaryTextColor;
$errorMessageBgColor:$dangerButtonBgColor;
$errorMessageBorder:0 none;
$errorMessageTextColor: $primaryTextColor;
$errorMessageIconColor: $primaryTextColor;

//growl
$growlTopLocation:70px;
$growlIconFontSize:2.571em;
$growlMessageTextMargin: 0 0 0 4em;
$growlMargin:0 0 1em 0;
$growlPadding:1em;
$growlShadow:0 3px 6px 0 rgba(0, 0, 0, 0.16);
$growlOpacity:.9;

//overlays
$overlayContentBorderColor: $FDS-surface;
$overlayContentBorder:1px solid $overlayContentBorderColor;
$overlayContainerShadow:0 0 6px 0 rgba(0, 0, 0, 0.16);

//dialog
$dialogHeaderPadding:1em;
$confirmDialogPadding:1em;

//overlay panel
$overlayPanelCloseIconBgColor:$primaryColor;
$overlayPanelCloseIconColor:$primaryTextColor;
$overlayPanelCloseIconHoverBgColor:$primaryDarkerColor;
$overlayPanelCloseIconHoverColor:$primaryTextColor;

//tooltip
$tooltipBgColor: $primaryDarkColor;
$tooltipTextColor:$primaryTextColor;
$tooltipPadding:$inputPadding;

//steps
$stepsItemBgColor: $white;
$stepsItemBorder:1px solid $FDS-dark-gray;
$stepsItemNumberColor:$textColor;
$stepsItemTextColor: $textColor;
$stepsItemWidth:28px;
$stepsItemHeight:28px;

//progressbar
$progressBarHeight:24px;
$progressBarBorder:0 none;
$progressBarBgColor: $FDS-disabled-dark_light-background;
$progressBarValueBgColor:$primaryDarkColor;

//menu (e.g. menu, menubar, tieredmenu)
$menuBgColor:$primaryDarkerColor;
$menuBorder: none; //1px solid $primaryDarkerColor;
$menuPadding:0;
$menuTextColor:$primaryTextColor;
$menuitemPadding:1em 1.25em;
$menuitemMargin:0.1em;
$menuitemTextColor:$primaryTextColor;
$menuitemIconColor:$primaryTextColor;
$menuitemHoverTextColor:$primaryTextColor;
$menuitemHoverIconColor:$primaryTextColor;
$menuitemHoverBgColor: $primaryDarkColor;
$menuitemActiveTextColor:$primaryTextColor;
$menuitemActiveIconColor:$primaryTextColor;
$menuitemActiveBgColor: $primaryDarkColor;
$menuitemRouterLinkActiveTextColor:$primaryTextColor;
$menuitemRouterLinkActiveIconColor:$primaryTextColor;
$submenuHeaderMargin: 0;
$submenuPadding: 0;
$overlayMenuBorder:2px solid $FDS-surface;
$overlayMenuShadow: 0.2em 0.2em 0.15em rgba(0, 0, 0, 0.2);

//misc
$maskBgColor:rgba(0, 0, 0, 0.4);        //dialog mask
$inlineSpacing:.5em;                      //spacing between inline elements
$chipsItemMargin:0 .286em 0 0;            //autocomplete and chips token spacing
$dataIconColor:$textSecondaryColor;       //icon color of a data such as treetoggler, table expander
$dataIconHoverColor:$textColor;           //hover icon color of a data such as treetoggler, table expander

//general
$disabledOpacity:.5;                      //opacity of a disabled item

//carousel
$carouselNavButtonsBgColor: $buttonBgColor;
$carouselNavButtonsBorder: $buttonBorder;
$carouselNavButtonsBorderRadius: 50%;
$carouselNavButtonsMargin: .2em;
$carouselNavButtonsColor: $buttonTextColor;
$carouselNavButtonsHoverBgColor: $primaryDarkColor;
$carouselNavButtonsHoverColor: $buttonHoverTextColor;
$carouselNavButtonsHoverBorderColor: $buttonHoverBorderColor;
$carouselNavButtonsTransition: $buttonTransition;
$carouselDotIconWidth: 20px;
$carouselDotIconHeight: 6px;
$carouselDotIconBgColor: #b2c1cd;
$carouselDotIconMargin: 0 .2em;
$carouselActiveDotIconBgColor: $primaryColor;
// ******************************************************
// ******      END PrimeNG Designer Variables      ******
// ******************************************************

`;

        this.originalVariables = this.variables;
    }

    initiate() {
        const base1 = '../designer';
        const directory1 = 'designer';
        const files1 = [
            '_mixins.scss',
            '_common.scss',
            '/components/input/_autocomplete.scss',
            '/components/input/_calendar.scss',
            '/components/input/_checkbox.scss',
            '/components/input/_chips.scss',
            '/components/input/_dropdown.scss',
            '/components/input/_editor.scss',
            '/components/input/_inputgroup.scss',
            '/components/input/_inputswitch.scss',
            '/components/input/_inputtext.scss',
            '/components/input/_listbox.scss',
            '/components/input/_multiselect.scss',
            '/components/input/_password.scss',
            '/components/input/_radiobutton.scss',
            '/components/input/_rating.scss',
            '/components/input/_selectbutton.scss',
            '/components/input/_slider.scss',
            '/components/input/_spinner.scss',
            '/components/input/_togglebutton.scss',
            '/components/button/_button.scss',
            '/components/button/_splitbutton.scss',
            '/components/data/_carousel.scss',
            '/components/data/_dataview.scss',
            '/components/data/_fullcalendar.scss',
            '/components/data/_orderlist.scss',
            '/components/data/_organizationchart.scss',
            '/components/data/_paginator.scss',
            '/components/data/_picklist.scss',
            '/components/data/_table.scss',
            '/components/data/_tree.scss',
            '/components/data/_treetable.scss',
            '/components/data/_virtualscroller.scss',
            '/components/panel/_accordion.scss',
            '/components/panel/_card.scss',
            '/components/panel/_fieldset.scss',
            '/components/panel/_panel.scss',
            '/components/panel/_tabview.scss',
            '/components/panel/_toolbar.scss',
            '/components/overlay/_dialog.scss',
            '/components/overlay/_lightbox.scss',
            '/components/overlay/_overlaypanel.scss',
            '/components/overlay/_sidebar.scss',
            '/components/overlay/_tooltip.scss',
            '/components/file/_fileupload.scss',
            '/components/menu/_breadcrumb.scss',
            '/components/menu/_contextmenu.scss',
            '/components/menu/_megamenu.scss',
            '/components/menu/_menu.scss',
            '/components/menu/_menubar.scss',
            '/components/menu/_panelmenu.scss',
            '/components/menu/_slidemenu.scss',
            '/components/menu/_steps.scss',
            '/components/menu/_tabmenu.scss',
            '/components/menu/_tieredmenu.scss',
            '/components/messages/_message.scss',
            '/components/messages/_messages.scss',
            '/components/messages/_toast.scss',
            '/components/multimedia/_galleria.scss',
            '/components/misc/_inplace.scss',
            '/components/misc/_progressbar.scss',
            '/components/misc/_terminal.scss',
            '_components.scss'
        ];
        const base2 = '../webcore';
        const directory2 = 'webcore';
        const files2 = [
            'webcore-variables.scss',
            '_mixins.scss',
            '_fonts.scss',
            '_common.scss',
            '_components.scss',
            '/components/utilities.scss',
            '/components/input/_autocomplete.scss',
            '/components/input/_calendar.scss',
            '/components/input/_checkbox.scss',
            '/components/input/_chips.scss',
            '/components/input/_dropdown.scss',
            '/components/input/_editor.scss',
            '/components/input/_inputgroup.scss',
            '/components/input/_inputswitch.scss',
            '/components/input/_inputtext.scss',
            '/components/input/_listbox.scss',
            '/components/input/_multiselect.scss',
            '/components/input/_password.scss',
            '/components/input/_radiobutton.scss',
            '/components/input/_rating.scss',
            '/components/input/_selectbutton.scss',
            '/components/input/_slider.scss',
            '/components/input/_spinner.scss',
            '/components/input/_togglebutton.scss',
            '/components/button/_button.scss',
            '/components/button/_splitbutton.scss',
            '/components/data/_carousel.scss',
            '/components/data/_dataview.scss',
            '/components/data/_fullcalendar.scss',
            '/components/data/_orderlist.scss',
            '/components/data/_organizationchart.scss',
            '/components/data/_paginator.scss',
            '/components/data/_picklist.scss',
            '/components/data/_table.scss',
            '/components/data/_tree.scss',
            '/components/data/_treetable.scss',
            '/components/data/_virtualscroller.scss',
            '/components/panel/_accordion.scss',
            '/components/panel/_card.scss',
            '/components/panel/_fieldset.scss',
            '/components/panel/_panel.scss',
            '/components/panel/_tabview.scss',
            '/components/panel/_toolbar.scss',
            '/components/overlay/_dialog.scss',
            '/components/overlay/_lightbox.scss',
            '/components/overlay/_overlaypanel.scss',
            '/components/overlay/_sidebar.scss',
            '/components/overlay/_tooltip.scss',
            '/components/file/_fileupload.scss',
            '/components/menu/_breadcrumb.scss',
            '/components/menu/_contextmenu.scss',
            '/components/menu/_megamenu.scss',
            '/components/menu/_menu.scss',
            '/components/menu/_menubar.scss',
            '/components/menu/_panelmenu.scss',
            '/components/menu/_slidemenu.scss',
            '/components/menu/_steps.scss',
            '/components/menu/_tabmenu.scss',
            '/components/menu/_tieredmenu.scss',
            '/components/messages/_message.scss',
            '/components/messages/_messages.scss',
            '/components/messages/_toast.scss',
            '/components/multimedia/_galleria.scss',
            '/components/misc/_inplace.scss',
            '/components/misc/_progressbar.scss',
            '/components/misc/_terminal.scss',
            '/components/_forms.scss',
            '_extensions.scss',
            '/extensions/_common.scss',
            '/extensions/components/input/_autocomplete.scss',
            '/extensions/components/input/_calendar.scss',
            '/extensions/components/input/_checkbox.scss',
            '/extensions/components/input/_chips.scss',
            '/extensions/components/input/_dropdown.scss',
            '/extensions/components/input/_editor.scss',
            '/extensions/components/input/_inputgroup.scss',
            '/extensions/components/input/_inputswitch.scss',
            '/extensions/components/input/_inputtext.scss',
            '/extensions/components/input/_listbox.scss',
            '/extensions/components/input/_multiselect.scss',
            '/extensions/components/input/_password.scss',
            '/extensions/components/input/_radiobutton.scss',
            '/extensions/components/input/_rating.scss',
            '/extensions/components/input/_selectbutton.scss',
            '/extensions/components/input/_slider.scss',
            '/extensions/components/input/_spinner.scss',
            '/extensions/components/input/_togglebutton.scss',
            '/extensions/components/button/_button.scss',
            '/extensions/components/button/_splitbutton.scss',
            '/extensions/components/data/_carousel.scss',
            '/extensions/components/data/_dataview.scss',
            '/extensions/components/data/_fullcalendar.scss',
            '/extensions/components/data/_orderlist.scss',
            '/extensions/components/data/_organizationchart.scss',
            '/extensions/components/data/_paginator.scss',
            '/extensions/components/data/_picklist.scss',
            '/extensions/components/data/_table.scss',
            '/extensions/components/data/_tree.scss',
            '/extensions/components/data/_treetable.scss',
            '/extensions/components/data/_virtualscroller.scss',
            '/extensions/components/panel/_accordion.scss',
            '/extensions/components/panel/_card.scss',
            '/extensions/components/panel/_fieldset.scss',
            '/extensions/components/panel/_panel.scss',
            '/extensions/components/panel/_tabview.scss',
            '/extensions/components/panel/_toolbar.scss',
            '/extensions/components/overlay/_dialog.scss',
            '/extensions/components/overlay/_lightbox.scss',
            '/extensions/components/overlay/_overlaypanel.scss',
            '/extensions/components/overlay/_sidebar.scss',
            '/extensions/components/overlay/_tooltip.scss',
            '/extensions/components/file/_fileupload.scss',
            '/extensions/components/menu/_breadcrumb.scss',
            '/extensions/components/menu/_contextmenu.scss',
            '/extensions/components/menu/_megamenu.scss',
            '/extensions/components/menu/_menu.scss',
            '/extensions/components/menu/_menubar.scss',
            '/extensions/components/menu/_panelmenu.scss',
            '/extensions/components/menu/_slidemenu.scss',
            '/extensions/components/menu/_steps.scss',
            '/extensions/components/menu/_tabmenu.scss',
            '/extensions/components/menu/_tieredmenu.scss',
            '/extensions/components/messages/_message.scss',
            '/extensions/components/messages/_messages.scss',
            '/extensions/components/messages/_toast.scss',
            '/extensions/components/multimedia/_galleria.scss',
            '/extensions/components/misc/_inplace.scss',
            '/extensions/components/misc/_progressbar.scss',
            '/extensions/components/misc/_terminal.scss'
        ];

        const base3 = '../blue-oval-theme';
        const directory3 = 'blue-oval-theme';
        const files3 = [
            'theme.scss',
            '_variables.scss',
            '_components.scss',
            'components/header.scss',
            'components/main.scss',
            'components/footer.scss',
            'components/type.scss'
        ];

        this.sass = new window['Sass']();

        this.sass.preloadFiles(base1, directory1, files1);
        this.sass.preloadFiles(base2, directory2, files2);
        this.sass.preloadFiles(base3, directory3, files3);

        this.editorStyle = document.createElement('style');
        this.editorStyle.type = 'text/css';

        document.getElementsByTagName('head')[0].appendChild(this.editorStyle);
    }

    onEditorButtonClick() {
        if (!this.sass) {
            this.initiate();
        }

        this.app.onEditorButtonClick();
    }

    compile(event, resetting) {
        if (!this.sass) {
            this.initiate();
        }

        this.compiling = true;
        const $this = this;

        if (!resetting) {
            this.variables = this.highlightsCode.nativeElement && this.highlightsCode.nativeElement.innerText;
        }

        this.sass.compile(
            `@import "webcore/webcore-variables";${this.variables};@import "designer/_components";
            @import "webcore/_components";@import "blue-oval-theme/_components"`, (result) => {
            $this.compiling = false;

            if (result.status === 1) {
                console.error(result.message);
            }
            else {
                if ($this.editorStyle.styleSheet) {
                    $this.editorStyle.styleSheet.cssText = result.text;
                }
                else  {
                    if ($this.editorStyle.firstChild) {
                        $this.editorStyle.removeChild($this.editorStyle.firstChild);
                    }
                    $this.editorStyle.appendChild(document.createTextNode(result.text));
                }
            }
        });
    }

    reset(event) {
        this.variables = this.originalVariables;
        this.compile(event, true);
    }

    getUpdateButtonIconClass() {
        return this.compiling ? 'pi pi-refresh pi-spin' : 'pi pi-refresh';
    }

    highlightsHtml() {
        let replaceCallback = (val) => {
            window['onPrimeColorPickerInput'] = (e) => {
                let target = e.target;
                let parent = target.parentNode;
                let nextSibling = parent.nextSibling;
                let color = target.value;
                parent.style.backgroundColor = color;
                nextSibling.textContent = nextSibling.textContent.replace(/#(?:[0-9a-f]{3}){1,2}/gim, color);
            };

            return `<span onclick="event.currentTarget.children[0].click()" contenteditable="false" style="width:10px; height: 10px; display: inline-block; white-space: nowrap; overflow: hidden; cursor: pointer; margin-right: .2em; border: 1px solid #fff; background-color:${val}">
                <input type="color" value="${val}" oninput="onPrimeColorPickerInput(event)" style="visibility: hidden; height:1px; width: 1px;"></span>${val}`;
        };

        let variablesAsHtml = this.variables.replace(/^\$([a-zA-z])*:/gim, u => `<span class='scss-variable' style="margin-right: .2em;">` + u + `</span>`);
        variablesAsHtml = variablesAsHtml.replace(/\/\/(.*)/gim, u => `<span class='scss-comment'>` + u + `</span>`);
        variablesAsHtml = variablesAsHtml.replace(/#(?:[0-9a-f]{3}){1,2}/gim, replaceCallback);

        return this.sanitizer.bypassSecurityTrustHtml(variablesAsHtml);
    }

    editor(event, resetting) {
        this.onEditorButtonClick();
        this.compile(event, resetting);
    }
}
