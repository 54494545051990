/* tslint:disable */
import { Component, OnInit } from '@angular/core';





import { MenuItem, MegaMenuItem, MessageService } from 'primeng/api';


import {TransactionService} from '../loginpage/Transaction.service';
import {designsourcelist, fromdiplayvalues_all, RevHistFromAPi, RevisionObj, searchProposalGridFromAPI, selec_prt_pro_details, subnewpart_latestbom_designsource, supplist,suppStatistics,useraccessrole} from '../loginpage/transactions';
import { Router,ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-packdesignsource',
  templateUrl: './packdesignsource.component.html',
  styleUrls: ['./packdesignsource.component.css']
})
export class PackdesignsourceComponent implements OnInit {


  designsourceRegCode='';
  designSourceCdsid='';
  designSourceCodeList:designsourcelist[]=[];
  designSourceUserAccess:useraccessrole[]=[];
  selectedDesignSource:designsourcelist={designsourcecode:''}
  activeItem: MenuItem;
  region:string;
  name:string;
  subDash:string;
  prg:string;
  cos:string;
  cont:string;
  regionadmin:string;
  sub_boolean=false;
  savedDesignSourceCode:designsourcelist[]=[];
  current_roleid:number;







  designSourceStatistics:suppStatistics[]=[];
  designSourceStatisticsMig:suppStatistics[]=[];


  approvedForProdCountDesignSource='0';


  gridDisplayRecallDesignSource:searchProposalGridFromAPI[]=[];


  gridTextRecallDesignSource='';
  presentTableRecallDesignSource=false;
  public colsRecallDesignSource: any[];

  gridDisplayRecallDesignSourceMig:searchProposalGridFromAPI[]=[];


  gridTextRecallDesignSourceMig='';
  presentTableRecallDesignSourceMig=false;
  public colsRecallDesignSourceMig: any[];


  enableRecallDialogDesignSource=false;

  enableRecallDialogDesignSourceMig=false;


  enableRecallFormDesignSource=false;
  selectedSimpliedPartnoDetailsRecallDesignSource:selec_prt_pro_details={simplifiedpartno:'-',bompartmasterid:0,proposalid:0,plantgsdbcode:'',proposallastsaved:0,part_status:'',supplier_code:'',is_ee_proposal:false,proposallastsaved_ee:0,currentview_ee:false,from_draft:false,propsl_type:'',imp_part_status:''};


  cdProposalStatDesignSource='0';
  currentStatusDesignSource='';

  commentsRecallDesignSource='';


  enableDialogNotificationRecallDesignSource=false;



  proposalDetailsDesignSource:fromdiplayvalues_all;
  revisionObjDesignSource:RevisionObj[]=[];

  revHistFromServiceAPI:RevHistFromAPi[]=[];

  enableDetailsDesignSource=false;


  enableRecallButtonDesignSource=false;


  public colsDesignSource: any[];


  orderReport:any=[]
  orderReportMig:any[];

  migrationDataLoaded=false;


  new_part_list_count_dsg=0
  unsubmitted_part_list_count_dsg=0

  new_part_list_count_dsg_migration=0
  unsubmitted_part_list_count_dsg_migration=0

  constructor(private transactionService: TransactionService,private router:Router,private date:DatePipe,private route: ActivatedRoute,private messageService:MessageService) { }

  ngOnInit(): void {
    this.region=this.transactionService.getregion();
    this.designsourceRegCode=this.transactionService.getregioncode(this.region);
    this.designSourceCdsid=this.transactionService.getcdsid();
    // this.current_roleid=this.transactionService.getcurrentrole(this.region);
    this.current_roleid=2

    this.transactionService.storepage(this.region,'/packagingprocess/packdesignsourceadmin/packdesignsource');

    if(this.region=='Asia Pacific & Africa')
    {
      sessionStorage.setItem('aprole', JSON.stringify(this.current_roleid));
    }

    else if(this.region=='Europe')
    {
      sessionStorage.setItem('eurole', JSON.stringify(this.current_roleid));
    }

    else if (this.region=='North America')

    {
      console.log(this.current_roleid)
      sessionStorage.setItem('narole', JSON.stringify(this.current_roleid));
    }

    else if(this.region=='South America')
    {
      sessionStorage.setItem('sarole', JSON.stringify(this.current_roleid));
    }


    this.colsDesignSource=[
      { field: 'proposal_status', header: ' Stage in Approval Process' },
      { field: 'proposal_status_count', header: 'Number of Parts' },


    ]

    this.orderReport=[
      'Rejected',
      'Recalled',
      'Preliminary Approval',
      'Plant Approval',
      'Plant Final Approval',
      'Release For Evaluation',
      'Release for Production',
      'Approved for production',
      // "Plant Approver - Offsite",
      // "Plant Final Approver - Offsite",
      'Additional Approval',
      'Migration Approval'
    ]

    this.orderReportMig=[
      'Rejected',
      'Additional Approval',
      'Migration Approval'
    ]


    // this.subDash='/'+this.name+'packagingprocess/packsupplier/submiterdashboard';
   /* this.prg='/'+this.name+'/testpage/prgadmin';
    this.cos='/'+this.name+'/testpage/cosadmin';
    this.cont='/'+this.name+'/testpage/app-contadmin';
    this.regionadmin='/'+this.name+'/testpage/regionadmin';*/
    let val=false;



   let sav_designsource;
   sav_designsource=this.transactionService.get_designsource(this.region);
    this.savedDesignSourceCode.push({designsourcecode:sav_designsource});

    console.log(this.savedDesignSourceCode);






// in future do gor global admin if its used
  /*  this.transactionService.getUserAccessRole(this.designsource_cdsid).subscribe(useraccess=>{
      console.log("useracces",useraccess);
      var position:number;
      this.designsourceUserAccess=useraccess;
      this.designsourceCodeList=[]
      for(var temp=0;temp<this.designsourceUserAccess.length;temp++)
      {
        if(this.designsourceUserAccess[temp].role_id==this.current_roleid)
        {
          if(this.designsourceUserAccess[temp].cd_region==this.designsource_reg_code)
          {



      var temp_designsource;

      temp_designsource=this.designsourceUserAccess[temp].cd_supplier.split(',')
      for(var i=0;i<temp_designsource.length;i++)
      {
      this.designsourceCodeList.push({designsourcecode:temp_designsource[i]})
      }

      break;






        }
      }
      }*/

      this.transactionService.getUsersupplierdesignsourceList(this.designSourceCdsid,this.designsourceRegCode,2).subscribe(designlist=>{
        console.log(designlist);

        this.designSourceCodeList=[];

        for(let i=0;i<designlist.length;i++)
      {
      this.designSourceCodeList.push({designsourcecode:designlist[i].supcode})
      }




      console.log(this.designSourceCodeList);

      if(this.savedDesignSourceCode[0].designsourcecode!=null  && this.savedDesignSourceCode[0].designsourcecode!='')
      {
       // console.log("inside")
        for(let isupp=0; isupp<this.designSourceCodeList.length; isupp++)
        {
          // console.log(this.savedDesignSourceCode[0].designsourcecode);
          // console.log(this.designSourceCodeList[isupp])
          if(this.savedDesignSourceCode[0].designsourcecode==this.designSourceCodeList[isupp].designsourcecode)
          {
            // console.log("inside 2")
            this.selectedDesignSource=this.savedDesignSourceCode[0];
            break;
          }
        }
      }

      else{

      this.selectedDesignSource=this.designSourceCodeList[0];
      }
      this.ondesignsourcechange();


  })




  }


  ondesignsourcechange()
  {
    console.log(this.selectedDesignSource);

    this.new_part_list_count_dsg=0;
    this.unsubmitted_part_list_count_dsg=0;
    if(this.selectedDesignSource != null)
    {
    this.transactionService.getsubmittergrid_designsource('all','all','all','New Part,UnSubmitted','all','all','all','all','all','all',this.selectedDesignSource.designsourcecode,this.designSourceCdsid,2,this.designsourceRegCode).subscribe(prtgridlist => {
      console.log('getsubmittergrid_designsource api o/p',prtgridlist)

      if(prtgridlist != null && prtgridlist.length != 0)
      {

         let new_part_list=[]
         new_part_list = prtgridlist.filter(prt=>(prt.ngpp_part_status != null && prt.ngpp_part_status.toLowerCase()=='new part'));
         this.new_part_list_count_dsg=new_part_list.length;

         let unsubmitted_part_list=[]
         unsubmitted_part_list = prtgridlist.filter(prt=>(prt.ngpp_part_status != null && prt.ngpp_part_status.toLowerCase()=='unsubmitted'));
         this.unsubmitted_part_list_count_dsg=unsubmitted_part_list.length;

      }
    })
  }

    // migration
    this.new_part_list_count_dsg_migration=0;
    this.unsubmitted_part_list_count_dsg_migration=0;
    if(this.selectedDesignSource != null)
    {
    this.transactionService.getsubmittergrid_designsource_migration('all','all','all','New Part,UnSubmitted','all','all','all','all','all','all',this.selectedDesignSource.designsourcecode,this.designSourceCdsid,2,this.designsourceRegCode).subscribe(prtgridlist_migration => {
      console.log('getsubmittergrid_designsource_migration api o/p',prtgridlist_migration)

      if(prtgridlist_migration != null && prtgridlist_migration.length != 0)
      {

         let new_part_list_migration=[]
         new_part_list_migration = prtgridlist_migration.filter(prt=>(prt.ngpp_part_status != null && prt.ngpp_part_status.toLowerCase()=='new part'));
         this.new_part_list_count_dsg_migration=new_part_list_migration.length;

         let unsubmitted_part_list_migration=[]
         unsubmitted_part_list_migration = prtgridlist_migration.filter(prt=>(prt.ngpp_part_status != null && prt.ngpp_part_status.toLowerCase()=='unsubmitted'));
         this.unsubmitted_part_list_count_dsg_migration=unsubmitted_part_list_migration.length;

      }
    })
  }


    let route:string;
    if(this.selectedDesignSource!=null)
    {
      this.getDesignSourceReport();
      this.migrationDataLoaded=false;
    if(this.designsourceRegCode=='NA')
    {
      sessionStorage.setItem('nadesignsource', JSON.stringify(this.selectedDesignSource.designsourcecode));
    }


    if(this.designsourceRegCode=='EU')
    {
      sessionStorage.setItem('eudesignsource', JSON.stringify(this.selectedDesignSource.designsourcecode));
    }



    if(this.designsourceRegCode=='AP')
    {
      sessionStorage.setItem('apdesignsource', JSON.stringify(this.selectedDesignSource.designsourcecode));
    }



    if(this.designsourceRegCode=='SA')
    {
      sessionStorage.setItem('sadesignsource', JSON.stringify(this.selectedDesignSource.designsourcecode));
    }

  }
  }




  getDesignSourceReport()
  {


    console.log('suppstat')
    console.log(this.selectedDesignSource.designsourcecode);
    this.designSourceStatistics=[];
    if(this.selectedDesignSource != null)
    {
      this.transactionService.get_designsource_statistics(this.selectedDesignSource.designsourcecode,'normal',this.designsourceRegCode).subscribe(statisticsDsgSrc=>{
      this.designSourceStatistics=[];
      console.log('get_designsource_statistics api o/p',statisticsDsgSrc);
      if(statisticsDsgSrc!=null)
      {
        this.approvedForProdCountDesignSource='0';
        for(let j=0;j<this.orderReport.length;j++)
        {
        for (let labelkey in statisticsDsgSrc)
        {


          if (statisticsDsgSrc.hasOwnProperty(labelkey))
          {
            if(this.orderReport[j].toLowerCase() == labelkey.toLowerCase())
            {

            console.log(statisticsDsgSrc[labelkey]);


            if(labelkey.toLowerCase()!='approved for production' && labelkey.toLowerCase()!='rejection requested')
            {
            this.designSourceStatistics.push({proposal_status:labelkey,proposal_status_count:statisticsDsgSrc[labelkey]})
            }
            else if( labelkey.toLowerCase()!='rejection requested'){
              this.approvedForProdCountDesignSource=statisticsDsgSrc[labelkey];

            }
            break;
          }
          }

        }
      }


      console.log('List of statistics',this.designSourceStatistics);

      }

      if(!this.migrationDataLoaded)
      {
        this.loadMigrationDesignSourceReport();
      }


    })
  }
  }

  loadMigrationDesignSourceReport()
  {
    console.log('suppstat')
    console.log(this.selectedDesignSource.designsourcecode);
    this.designSourceStatisticsMig=[];
    this.migrationDataLoaded=true;
    if(this.selectedDesignSource != null)
    {
    this.transactionService.get_designsource_statistics(this.selectedDesignSource.designsourcecode,'Migration',this.designsourceRegCode).subscribe(statisticsDsgSrcMig=>{
      this.designSourceStatisticsMig=[];
      console.log('get_designsource_statistics Mig api o/p',statisticsDsgSrcMig);
      if(statisticsDsgSrcMig!=null)
      {
       // this.approvedForProdCountDesignSource="0";
        for(let j=0;j<this.orderReportMig.length;j++)
        {
        for (let labelkey in statisticsDsgSrcMig)
        {


          if (statisticsDsgSrcMig.hasOwnProperty(labelkey))
          {
            if(this.orderReportMig[j] == labelkey)
            {

            console.log(statisticsDsgSrcMig[labelkey]);



            this.designSourceStatisticsMig.push({proposal_status:labelkey,proposal_status_count:statisticsDsgSrcMig[labelkey]})

            break;
          }
          }

        }
      }


      console.log('List of statistics',this.designSourceStatisticsMig);

      }


    })
  }
  }


  getSelectedStatusDesignSource(rowdata:any)
  {
    console.log(rowdata);
    this.enableRecallButtonDesignSource=false;
    if(rowdata.proposal_status_count!=''&& rowdata.proposal_status_count!='0')
    {
       this.cdProposalStatDesignSource='0'
      if(rowdata.proposal_status.toLowerCase()=='preliminary approval')
      {
        this.cdProposalStatDesignSource='3';
        this.enableRecallButtonDesignSource=true;
      }
      else  if(rowdata.proposal_status.toLowerCase()=='additional approval')
      {
        this.cdProposalStatDesignSource='4';
        this.enableRecallButtonDesignSource=true;
      }
      else  if(rowdata.proposal_status.toLowerCase()=='plant approval')
      {
        this.cdProposalStatDesignSource='5';
        this.enableRecallButtonDesignSource=true;
      }
      else  if(rowdata.proposal_status.toLowerCase()=='plant final approval')
      {
        this.cdProposalStatDesignSource='6';
        this.enableRecallButtonDesignSource=true;
      }
      else  if(rowdata.proposal_status.toLowerCase()=='release for evaluation')
      {
        this.cdProposalStatDesignSource='7';
        this.enableRecallButtonDesignSource=true;
      }
      else  if(rowdata.proposal_status.toLowerCase()=='release for production')
      {
        this.cdProposalStatDesignSource='8';
        this.enableRecallButtonDesignSource=true;
      }
      else  if(rowdata.proposal_status.toLowerCase()=='rejected')
      {
        this.cdProposalStatDesignSource='11';
        this.enableRecallButtonDesignSource=false;

      }
      else  if(rowdata.proposal_status.toLowerCase()=='rejection requested')
      {
        this.cdProposalStatDesignSource='13';
        this.enableRecallButtonDesignSource=false;

      }
      else  if(rowdata.proposal_status.toLowerCase()=='recalled')
      {
        this.cdProposalStatDesignSource='15';
        this.enableRecallButtonDesignSource=false;

      }
      else  if(rowdata.proposal_status.toLowerCase()=='recall requested')
      {
        this.cdProposalStatDesignSource='14';
        this.enableRecallButtonDesignSource=false;

      }


      else  if(rowdata.proposal_status.toLowerCase()=='submitted')
      {
        this.cdProposalStatDesignSource='2';
        this.enableRecallButtonDesignSource=true;
      }

      else if(rowdata.proposal_status.toLowerCase()=='migration approval')
      {
        this.cdProposalStatDesignSource='19';
        this.enableRecallButtonDesignSource=false;
      }


      this.currentStatusDesignSource=rowdata.proposal_status


      this.getPartslistOnStatusDesignSource();








    }
  }

  getSelectedStatusDesignSourceMig(rowdata:any)
  {
    console.log(rowdata);
    this.enableRecallButtonDesignSource=false;
    if(rowdata.proposal_status_count!=''&& rowdata.proposal_status_count!='0')
    {
       this.cdProposalStatDesignSource='0'
      if(rowdata.proposal_status.toLowerCase()=='additional approval')
      {
        this.cdProposalStatDesignSource='4';
        // this.enableRecallButtonDesignSource=true;
      }


      else  if(rowdata.proposal_status.toLowerCase()=='rejected')
      {
        this.cdProposalStatDesignSource='11';
        // this.enableRecallButtonDesignSource=false;

      }


      else  if(rowdata.proposal_status.toLowerCase()=='submitted')
      {
        this.cdProposalStatDesignSource='2';
        // this.enableRecallButtonDesignSource=true;
      }

      else if(rowdata.proposal_status.toLowerCase()=='migration approval')
      {
        this.cdProposalStatDesignSource='19';
        // this.enableRecallButtonDesignSource=false;
      }


      this.currentStatusDesignSource=rowdata.proposal_status
      this.getPartslistOnStatusDesignSourceMig();

    }
  }



  getSelectedStatusApprvProdDesignSource()

      {

         this.cdProposalStatDesignSource='10';
         this.currentStatusDesignSource='Approved For Production';
         this.enableRecallButtonDesignSource=false;




      this.getPartslistOnStatusDesignSource();




      }





  getPartslistOnStatusDesignSource()
  {
    if(this.selectedDesignSource != null)
    {
    this.transactionService.getSuppDesgSearchProposalGridDetails(this.designsourceRegCode,this.cdProposalStatDesignSource,'all','all','all','all',
    'all',this.selectedDesignSource.designsourcecode,'%20','all','all','all','all','all','all','all','all','all',this.designSourceCdsid,'Dsg','normal').subscribe(griddetls_recall=>{


      console.log('getSuppDesgSearchProposalGridDetails api o/p',griddetls_recall);


      this.gridDisplayRecallDesignSource=[];
      this.gridDisplayRecallDesignSource=griddetls_recall;
      this.gridTextRecallDesignSource=''


      let grid_display_part_status='';
      if(this.gridDisplayRecallDesignSource!=null && this.gridDisplayRecallDesignSource.length!=0)
      {
        for(let i=0;i<this.gridDisplayRecallDesignSource.length;i++)
        {
       grid_display_part_status='';
       grid_display_part_status+=this.gridDisplayRecallDesignSource[i].partstatus;

       if(this.gridDisplayRecallDesignSource[i].isstatusurgent!=null && this.gridDisplayRecallDesignSource[i].isstatusurgent.toLowerCase()=='y')
       {
         grid_display_part_status+=' (Urgent)'
       }
       if(this.gridDisplayRecallDesignSource[i].isdeliquent!=null && this.gridDisplayRecallDesignSource[i].isdeliquent.toLowerCase()=='y')
       {
         grid_display_part_status+=' (Delinquent)'
       }


       this.gridDisplayRecallDesignSource[i].displaypartstatus=grid_display_part_status;
     }

        this.presentTableRecallDesignSource=true;






        this.colsRecallDesignSource=[
          { field: 'partnumbercommonized', header:'Simplified Part Number'},
          { field: 'partdesc', header:'Part Description'},
          { field: 'plantgsdbcode', header:'Plant GSDB Code'},
          { field: 'programname', header:'Program Name'},
          { field: 'cntr_ownrshp_strat_code', header:'Container Ownership Strategy'},
          { field: 'suppliergsdbcode', header:'Supplier GSDB Code'},
          { field: 'displaypartstatus', header:'Part Status'}
        ];

      }

      else
      {
        this.gridTextRecallDesignSource='No Match Found';
        this.presentTableRecallDesignSource=false;
        this.colsRecallDesignSource=[];
      }


      this.enableRecallDialogDesignSource=true;
      this.enableRecallDialogDesignSourceMig=false;

    })
  }
  }

  getPartslistOnStatusDesignSourceMig()
  {
    if(this.selectedDesignSource != null)
    {
    this.transactionService.getSuppDesgSearchProposalGridDetails(this.designsourceRegCode,this.cdProposalStatDesignSource,'all','all','all','all',
    'all',this.selectedDesignSource.designsourcecode,'%20','all','all','all','all','all','all','all','all','all',this.designSourceCdsid,'Dsg','migration').subscribe(griddetls_recallMig=>{


      console.log('getSuppDesgSearchProposalGridDetails api o/p',griddetls_recallMig);


      this.gridDisplayRecallDesignSourceMig=[];
      this.gridDisplayRecallDesignSourceMig=griddetls_recallMig;
      this.gridTextRecallDesignSourceMig=''


      let grid_display_part_status='';
      if(this.gridDisplayRecallDesignSourceMig!=null && this.gridDisplayRecallDesignSourceMig.length!=0)
      {
        for(let i=0;i<this.gridDisplayRecallDesignSourceMig.length;i++)
        {
       grid_display_part_status='';
       grid_display_part_status+=this.gridDisplayRecallDesignSourceMig[i].partstatus;

       if(this.gridDisplayRecallDesignSourceMig[i].isstatusurgent!=null && this.gridDisplayRecallDesignSourceMig[i].isstatusurgent.toLowerCase()=='y')
       {
         grid_display_part_status+=' (Urgent)'
       }
       if(this.gridDisplayRecallDesignSourceMig[i].isdeliquent!=null && this.gridDisplayRecallDesignSourceMig[i].isdeliquent.toLowerCase()=='y')
       {
         grid_display_part_status+=' (Delinquent)'
       }


       this.gridDisplayRecallDesignSourceMig[i].displaypartstatus=grid_display_part_status;
     }

        this.presentTableRecallDesignSourceMig=true;






        this.colsRecallDesignSourceMig=[
          { field: 'partnumbercommonized', header:'Simplified Part Number'},
          { field: 'partdesc', header:'Part Description'},
          { field: 'plantgsdbcode', header:'Plant GSDB Code'},
          { field: 'programname', header:'Program Name'},
          { field: 'cntr_ownrshp_strat_code', header:'Container Ownership Strategy'},
          { field: 'suppliergsdbcode', header:'Supplier GSDB Code'},
          { field: 'displaypartstatus', header:'Part Status'}
        ];

      }

      else
      {
        this.gridTextRecallDesignSourceMig='No Match Found';
        this.presentTableRecallDesignSourceMig=false;
        this.colsRecallDesignSourceMig=[];
      }


      this.enableRecallDialogDesignSourceMig=true;
      this.enableRecallButtonDesignSource=false;

    })
  }
  }




  getPartDetailsFormDesignSource(partData:searchProposalGridFromAPI)
  {


    console.log('getPartDetailsFormDesignSource fn',partData);
  // console.log(partData.partnumbercommonized);


  // var sub_exist_proposalid=0;

  // service to get proposal id





  this.selectedSimpliedPartnoDetailsRecallDesignSource.bompartmasterid=partData.bompartmasterid;
this.selectedSimpliedPartnoDetailsRecallDesignSource.simplifiedpartno=partData.partnumbercommonized;
this.selectedSimpliedPartnoDetailsRecallDesignSource.proposalid=partData.id_proposal;
this.selectedSimpliedPartnoDetailsRecallDesignSource.plantgsdbcode=partData.plantgsdbcode;
this.selectedSimpliedPartnoDetailsRecallDesignSource.proposallastsaved=4;
this.selectedSimpliedPartnoDetailsRecallDesignSource.part_status=partData.partstatus.trim();
this.selectedSimpliedPartnoDetailsRecallDesignSource.supplier_code=partData.suppliergsdbcode;
this.selectedSimpliedPartnoDetailsRecallDesignSource.proposallastsaved_ee=1;
this.selectedSimpliedPartnoDetailsRecallDesignSource.is_ee_proposal=false;
this.selectedSimpliedPartnoDetailsRecallDesignSource.currentview_ee=false;
this.selectedSimpliedPartnoDetailsRecallDesignSource.from_draft=false;
// improvement proposal changes to be revisited
this.selectedSimpliedPartnoDetailsRecallDesignSource.propsl_type=partData.propsl_type;
this.selectedSimpliedPartnoDetailsRecallDesignSource.imp_part_status=null




  this.storePrimaryPartRecallDesignSource();




  }


  storePrimaryPartRecallDesignSource()
{
if(this.designsourceRegCode=='NA')
{

  sessionStorage.setItem('napackpropart', JSON.stringify(this.selectedSimpliedPartnoDetailsRecallDesignSource));
}


if(this.designsourceRegCode=='EU')
{
  sessionStorage.setItem('eupackpropart', JSON.stringify(this.selectedSimpliedPartnoDetailsRecallDesignSource));
}



if(this.designsourceRegCode=='AP')
{
  sessionStorage.setItem('appackpropart', JSON.stringify(this.selectedSimpliedPartnoDetailsRecallDesignSource));
}



if(this.designsourceRegCode=='SA')
{
  sessionStorage.setItem('sapackpropart', JSON.stringify(this.selectedSimpliedPartnoDetailsRecallDesignSource));
}


this.enableDetailsDesignSource=false;


this.enableRecallFormDesignSource=true;






}
removePartSessionStorageSupplierRecall()
{
  this.transactionService.destroyPartnoSessionStorage(this.designsourceRegCode);
}

recallProposalDesignSource()
{
  console.log(this.selectedSimpliedPartnoDetailsRecallDesignSource);
  console.log(this.commentsRecallDesignSource);


  let cur_date_recall;
  let cur_date_transform_recall
  cur_date_recall=new Date();
  cur_date_transform_recall=this.date.transform(cur_date_recall, 'yyyy-MM-dd HH:mm:ss');


  console.log(cur_date_transform_recall);

  let alphabet_pres_recall:boolean;
  alphabet_pres_recall=/[a-z]/i.test(this.commentsRecallDesignSource);


  console.log(alphabet_pres_recall)

    if(this.commentsRecallDesignSource==null|| alphabet_pres_recall==false)
  {
    // toast stating enter comments
    this.enableDialogNotificationRecallDesignSource=true;
    this.messageService.add({severity:'error', summary: 'Error', detail: 'Please enter comments to recall packaging'});
  }
  else{
    // api to recall packagining proposal

 let replace_comments=this.commentsRecallDesignSource.replace('|','/').replace('`','\'')
 this.commentsRecallDesignSource=replace_comments;


    this.transactionService.request_recall(this.selectedSimpliedPartnoDetailsRecallDesignSource.proposalid,this.designSourceCdsid,cur_date_transform_recall,this.commentsRecallDesignSource).subscribe(recall_out=>{
      console.log(recall_out);
      if(recall_out.toLowerCase()=='success')
      {
        this.messageService.add({severity:'success', summary: 'Succcess', detail: 'Packaging Recall Requested'});
        this.enableRecallFormDesignSource=false;
        this.getPartslistOnStatusDesignSource();

      }
      else
      {
        this.messageService.add({severity:'error', summary: 'Error', detail: 'Please try again, to recall packaging'});

      }
    })
  }


}

okNotificationDialogRecallDesignSource()
{
  this.enableDialogNotificationRecallDesignSource=false;
}


onCloseRecallDialogDesignSource()
{
  // console.log("oncloserecall")
 this.getDesignSourceReport();
}

onCloseRecallDialogDesignSourceMig()
{
  this.loadMigrationDesignSourceReport();
}


viewRevisionHistoryDesignSource()
{


this.enableDetailsDesignSource=true;
console.log('revision history');
/*
this.transactionService.loadtransacmasterdetails(this.selectedSimpliedPartnoDetailsRecallDesignSource.proposalid).subscribe(transdet_supp=>{

  console.log("loadtransacmasterdetails api o/p",transdet_supp);
 this.proposalDetailsDesignSource=transdet_supp;


 console.log(this.proposalDetailsDesignSource.revision_hstry);
  var revision_arr=[];
  this.revisionObjDesignSource=[];

  if(this.proposalDetailsDesignSource.revision_hstry!=null)

 //if(transdet!=null)
  {
     revision_arr=this.proposalDetailsDesignSource.revision_hstry.split('|')
    //revision_arr=transdet.split('|')
    console.log(revision_arr)


         for(var i=0;i<revision_arr.length;i++)
         {
           var rev_arr=[];
           var rev_htm_str="";
           rev_arr=revision_arr[i].split('`');




           if(rev_arr.length==7)
           {

           rev_htm_str="<b>"+rev_arr[5]+";"+rev_arr[1]+"("+rev_arr[2]+")"+"</b>"+"<br>"+rev_arr[3]+"-"+rev_arr[4];
           if(rev_arr[6]!="-")
           {
             rev_htm_str+="<br>"+"Comments-"+rev_arr[6];
           }
          }


          this.revisionObjDesignSource.push({rev_string:revision_arr[i],rev_html_string:rev_htm_str})

         }

  }


  console.log(this.revisionObjDesignSource);


  this.revisionObjDesignSource=this.revisionObjDesignSource.reverse();
  console.log("Revision History Display",this.revisionObjDesignSource)

})*/

this.transactionService.getRevisionHistoryByProposalId(this.selectedSimpliedPartnoDetailsRecallDesignSource.proposalid).subscribe(revHistArray=>{

  this.revHistFromServiceAPI=[];
  this.revHistFromServiceAPI=revHistArray;
  this.revisionObjDesignSource=[];
  console.log('getRevisionHistoryByProposalId API o/p ',this.revHistFromServiceAPI);



  if(this.revHistFromServiceAPI != null && this.revHistFromServiceAPI.length != 0)
  {
    for(let i=0; i<this.revHistFromServiceAPI.length; i++)
    {
      let rev_htm_str='';


      if(this.revHistFromServiceAPI[i].evnttime != null && this.revHistFromServiceAPI[i].usrname != null)
      {
        rev_htm_str='<b>'+this.revHistFromServiceAPI[i].evnttime+';'+this.revHistFromServiceAPI[i].usrname+'('+this.revHistFromServiceAPI[i].email+')'+'</b>'+'<br>'+this.revHistFromServiceAPI[i].proposalstatuscurr+'-'+this.revHistFromServiceAPI[i].apprvractn;
      }
      else
      {
        rev_htm_str='<b>'+this.revHistFromServiceAPI[i].proposalstatuscurr+'-'+this.revHistFromServiceAPI[i].apprvractn+'</b>';
      }
      if(this.revHistFromServiceAPI[i].comments != null && this.revHistFromServiceAPI[i].comments != '-')
      {
        rev_htm_str+='<br>'+'Comments-'+this.revHistFromServiceAPI[i].comments;
      }

      this.revisionObjDesignSource.push({rev_obj:this.revHistFromServiceAPI[i],rev_html_string:rev_htm_str})
    }
    console.log('Revision History Display',this.revisionObjDesignSource)
  }

})
}


closeRevisionHistoryDesignSource()
{
this.enableDetailsDesignSource=false;

}






  redirectSubmitterDesignSource()
  {

    sessionStorage.setItem('proposaltypefor','normal')
    let reg_supp_sub_url:string;
    reg_supp_sub_url=this.transactionService.getregionurlvalue(this.region);
    reg_supp_sub_url+='packagingprocess/packdesignsourceadmin/submiterdashboard';

    this.router.navigate([reg_supp_sub_url]);
  }

  redirectMigrationSubmitterDesignSource()
  {
    sessionStorage.setItem('proposaltypefor','migartion')
    let reg_supp_sub_url:string;
    reg_supp_sub_url=this.transactionService.getregionurlvalue(this.region);
    reg_supp_sub_url+='packagingprocess/packdesignsourceadmin/migrationsubmitter';

    this.router.navigate([reg_supp_sub_url]);
  }


  redirect_designsource_profile()
  {


    let reg_supp_supp_url:string;
    reg_supp_supp_url=this.transactionService.getregionurlvalue(this.region);
    reg_supp_supp_url+='packagingprocess/packdesignsourceadmin/editsupplierprofile';

    this.router.navigate([reg_supp_supp_url]);

  }

  redirect_container_request_designsource()
  {


    let reg_supp_supp_url:string;
    reg_supp_supp_url=this.transactionService.getregionurlvalue(this.region);
    reg_supp_supp_url+='packagingprocess/packdesignsourceadmin/containerrequest';

    this.router.navigate([reg_supp_supp_url]);

  }

  redirect_container_request_status_designsource()
  {
    let reg_supp_supp_url:string;
    reg_supp_supp_url=this.transactionService.getregionurlvalue(this.region);
    reg_supp_supp_url+='packagingprocess/packdesignsourceadmin/containerreqsts'; // not added in routes.ts

    this.router.navigate([reg_supp_supp_url]);
  }
  redirect_packproposal()
  {

  }

  issubmitterdashboard():boolean{

    // this.sub_boolean=true;
    let user_roles=[];
    user_roles=this.transactionService.getroles(this.designsourceRegCode)

    let func_present=false;

    if(user_roles!=null && user_roles.length!=0)
    {
    for(let ni=0;ni<user_roles.length;ni++)
    {
      if(user_roles[ni].roleid==this.current_roleid)
      {
        // console.log(user_roles[ni].roles)
        let user_subroles=[];
        user_subroles=user_roles[ni].roles

        for(let nj=0;nj<user_subroles.length;nj++)
        {
          if(user_subroles[nj].sysfunc_id==5)
          {
            func_present=true
            break;
          }
        }

        break;
      }
    }
  }


  // func_present=true;


    return func_present;
  }



  activateMenu(event) {
    console.log('click on ' + event.tabpackadminMenuItem.label + ' tab!');
}

}
