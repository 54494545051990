import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {AuthGuardService} from '../oauth/auth-guard.service';
import {HomeComponent} from './home.component';
import {ServiceResolve} from './resolves/service.resolve';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    resolve: { result: ServiceResolve},
    canActivate: [AuthGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class HomeRoutingModule { }
