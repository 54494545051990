/* tslint:disable */
import { Component, OnDestroy, OnInit } from '@angular/core';



import { MenuItem, MegaMenuItem } from 'primeng/api';


import {TransactionService} from '../loginpage/Transaction.service';
import { Router,ActivatedRoute } from '@angular/router';
import { NavComponent } from '../nav/nav.component';


@Component({
  selector: 'app-packapprover',
  templateUrl: './packapprover.component.html',
  styleUrls: ['./packapprover.component.css']
})
export class PackapproverComponent implements OnInit,OnDestroy {


  tabapradminMenuItems: MenuItem[];
  activeItem: MenuItem;
  region:string;
  name:string;
  prmapr:string;
  addnlapr:string;
  pltapr:string;
  relforevlapr:string;
  pltfinalapr:string;
  relforprodapr:string;
  allAddlnApprvr:string;

  pltaproffsite:string;
  pltfinalaproffsite:string;






  cdsid:string;
  region_code:string;
  current_role_id=0;



  routing_by_role:any[]=[];
  selected_routing_by_role:any[]=[];


  loaderapp=false;


  routing_by_role_admin:any[]=[]


  current_route_id=0

  migrationapr=''


  constructor(private transactionService: TransactionService,private router:Router,private navigation:NavComponent) {




  }

  ngOnInit(): void {


    console.log('inside approver');

    this.region=this.transactionService.getregion();
    this.cdsid=this.transactionService.getcdsid();
    this.region_code=this.transactionService.getregioncode(this.region);
    // this.current_role_id=this.transactionService.getcurrentrole(this.region);
    this.current_route_id=this.transactionService.getrouteid(this.region);
    if(this.region=='Asia Pacific & Africa')
    {
      this.name='asiapacific';
    }

    else if(this.region=='Europe')
    {
      this.name='europe';
    }

    else if (this.region=='North America')
    {
     this.name='northamerica';
    }

    else if(this.region=='South America')
    {
     this.name='southamerica';
    }


    this.prmapr='/'+this.name+'/packagingprocess'+'/packapprover/preliminaryapp';
    this.addnlapr='/'+this.name+'/packagingprocess'+'/packapprover/additionalapp';
    this.pltapr='/'+this.name+'/packagingprocess'+'/packapprover/plantapp';
    this.relforevlapr='/'+this.name+'/packagingprocess'+'/packapprover/releasefrevalapp';
    this.pltfinalapr='/'+this.name+'/packagingprocess'+'/packapprover/plantfinalapp';
    this.relforprodapr='/'+this.name+'/packagingprocess'+'/packapprover/releasefrprodapp';
    this.pltaproffsite='/'+this.name+'/packagingprocess'+'/packapprover/plantappoffsite';
    this.pltfinalaproffsite='/'+this.name+'/packagingprocess'+'/packapprover/plantfinalappoffsite';
    this.migrationapr='/'+this.name+'/packagingprocess'+'/packapprover/migrationapproval';

    this.allAddlnApprvr='/'+this.name+'/packagingprocess'+'/packapprover/alladditionalapproval';

    let val=false;


    this.routing_by_role=[
      {role:'3,12',value: {label: 'Prelimnary Approval',routerLink: this.prmapr, icon: 'fas fa-industry'},id:251},
      {role:'4',value: {label: 'Additional Approval',routerLink: this.addnlapr, icon: 'fas fa-industry'},id:252},
      {role:'5',value: {label: 'Plant Approval',routerLink: this.pltapr, icon: 'fas fa-industry'},id:253},
      {role:'9,12',value: {label: 'Release For Evaluation', routerLink: this.relforevlapr, icon: 'fas fa-industry'},id:254},
      {role:'6',value:  {label: 'Plant Final Approval',routerLink: this.pltfinalapr, icon: 'fas fa-industry'},id:255},
      {role:'11,12',value: {label: 'Release For Production', routerLink: this.relforprodapr, icon: 'fas fa-industry'},id:256},
      {role:'19',value: {label: 'Plant Approval - Offsite', routerLink: this.pltaproffsite, icon: 'fas fa-industry'},id:257},
      {role:'20',value: {label: 'Plant Final Approval - Offsite', routerLink: this.pltfinalaproffsite, icon: 'fas fa-industry'},id:258},
      {role:'3,12',value: {label: 'Migration Approval', routerLink: this.migrationapr, icon: 'fas fa-industry'},id:259},
      {role:'12',value: {label: 'All Additional Approval', routerLink: this.allAddlnApprvr, icon: 'fas fa-industry'},id:260},

  ]






  this.selected_routing_by_role=[];

  /*if(document.getElementById("overlayapp") != null )
  {
  document.getElementById("overlayapp").style.display = "block";
  }
  if(document.getElementById("loaderapp") != null )
  {
  document.getElementById("loaderapp").style.display = "block";
  }
  */

     this.loaderapp=true;



   // var roles=this.transactionService.getroles(this.region_code);


   // global admin is not checked as approver roles will not be given to global admin

this.loadRoles();
   this.transactionService.getUserAccessRole(this.cdsid).subscribe(out=>{

    this.tabapradminMenuItems=[];
    console.log(out);
    let roles=[];
    if(out!=null && out.length!=0)
    {
      let roleidString='';
      let glbIndexPresent=out.findIndex(elglbpres=>elglbpres.role_id==100);
      if(glbIndexPresent!=-1)
      {


        if(JSON.parse(sessionStorage.getItem('isGlobalAdmin'))==false)
					{
						sessionStorage.removeItem('isGlobalAdmin');
					sessionStorage.setItem('isGlobalAdmin', JSON.stringify(true));

					this.updateRegionList();
					}



        roles.push({roleid:100,roles:[]})
          roleidString='100';

      }



    for(let reg=0;reg<out.length;reg++)
    {
      if(out[reg].cd_region==this.region_code)
      {
        if(roles.length==0)
        {
          roles.push({roleid:out[reg].role_id,roles:[]})
          roleidString=out[reg].role_id.toString();
        }
        else{


          let role_present=false;

          for(let len=0;len<roles.length;len++)
          {


            if(out[reg].role_id==roles[len])
            {

              role_present=true;
              break;

            }

          }


          if(role_present==false)
          {
            roles.push({roleid:out[reg].role_id,roles:[]})
            roleidString+=',';
            roleidString+=out[reg].role_id.toString();
          }
        }
      }
    }


    console.log('appr',roles);


    if(roles.length!=0)
    {



this.transactionService.getuserrolefunctionality(roleidString,this.region_code).subscribe(userrolefuncaccess=>{


  console.log(userrolefuncaccess);

  if(userrolefuncaccess!=null || userrolefuncaccess.length!=0)
  {
  for(let ieu=0;ieu<roles.length;ieu++)
  {
    roles[ieu].roles=[]
    let rolefunc=[];
    for(let jeu=0;jeu<userrolefuncaccess.length;jeu++)
    {
      if(roles[ieu].roleid==userrolefuncaccess[jeu].role_id)
      {


        roles[ieu].roles.push(userrolefuncaccess[jeu]);


      }
    }
  }


  let name=this.region_code+'useraccess';
  sessionStorage.setItem(name,JSON.stringify(roles));


let all_present=false

console.log('apprv',roles)

/*
for(var i=0;i<this.routing_by_role.length;i++)
{
    for(var j=0;j<roles.length;j++)
		{
      if(this.routing_by_role[i].role==roles[j].roleid)
      {


        this.tabapradminMenuItems.push(this.routing_by_role[i].value);
        this.selected_routing_by_role.push(this.routing_by_role[i]);
        break;

      }
    }
  }

*/

for(let i=0;i<this.routing_by_role.length;i++)
{
   let func_arr=this.routing_by_role[i].role.split(',');
   let route_req=false;

   for(let j=0;j<roles.length;j++)
   {
     // var sys_func_arr=[];
     // console.log("fun_arr",func_arr);
      // console.log(roles[j])
      // console.log(func_arr.includes(roles[j].roleid.toString()))
     if(func_arr.includes(roles[j].roleid.toString()))
     {

      route_req=true;
      break;
     }

  }

   if(route_req == true)
   {

    if(this.tabapradminMenuItems.length!=0)
         {

          let prsntIndex=this.selected_routing_by_role.findIndex(elpre=>elpre.id==this.routing_by_role[i].id)

          if(prsntIndex==-1)
          {
            this.tabapradminMenuItems.push(this.routing_by_role[i].value);
            this.selected_routing_by_role.push(this.routing_by_role[i]);
          }


          if(this.tabapradminMenuItems.length==this.routing_by_role.length)
          {
            all_present=true;
            break;
          }

         }
         else
         {
           this.tabapradminMenuItems.push(this.routing_by_role[i].value);
           this.selected_routing_by_role.push(this.routing_by_role[i]);
         }

   }
}


  console.log('apprrr',this.tabapradminMenuItems,this.selected_routing_by_role);




  }
  else
  {
    this.routeRegion();
  }



  console.log(this.current_route_id);

let  route_present=false;
if(this.current_route_id!=null && this.current_route_id!= undefined)
{
  if(this.current_route_id==251 || this.current_route_id==252 ||  this.current_route_id==253 ||  this.current_route_id==254 ||  this.current_route_id==255 ||  this.current_route_id==256 || this.current_route_id==257  ||  this.current_route_id==258 || this.current_route_id==259 || this.current_route_id==260)
  {

    if(this.selected_routing_by_role.length!=0)
    {

    for(let rls=0;rls<this.selected_routing_by_role.length;rls++)
    {

      if(this.selected_routing_by_role[rls].id==this.current_route_id)
      {


        let test=this.selected_routing_by_role[rls].value
        console.log(test);
        this.activeItem=test;
        this.router.navigate([this.activeItem.routerLink]);


        // document.getElementById("overlayapp").style.display = "none";
        // document.getElementById("loaderapp").style.display = "none";

        this.loaderapp=false;
        route_present=true;

       // this.activeItem.push(this.routing_by_role[rls].value);
        break;

      }

    }

    if(route_present == false)
    {
      let test_new_s=this.tabapradminMenuItems[0];

      console.log(test_new_s);
      this.activeItem=test_new_s;
     // document.getElementById("overlayapp").style.display = "none";
      // document.getElementById("loaderapp").style.display = "none";
      this.loaderapp=false
      this.router.navigate([this.activeItem.routerLink]);

    }
  }
  else
  {



      let test_new:MenuItem
      test_new=this.tabapradminMenuItems[0];


      if(this.tabapradminMenuItems.length==0)
      {
        this.routeRegion();
      }
      else
      {
      console.log(test_new);
      this.activeItem=test_new;
     // document.getElementById("overlayapp").style.display = "none";
      // document.getElementById("loaderapp").style.display = "none";
      this.loaderapp=false;
      this.router.navigate([this.activeItem.routerLink]);

      }



  }

  }
  else
{
  let test_new:MenuItem
  test_new=this.tabapradminMenuItems[0];


  if(this.tabapradminMenuItems.length==0)
  {
    this.routeRegion();
  }
  else
  {
  console.log(test_new);
  this.activeItem=test_new;
  // document.getElementById("overlayapp").style.display = "none";
  // document.getElementById("loaderapp").style.display = "none";
  this.loaderapp=false;
  this.router.navigate([this.activeItem.routerLink]);

  }
}

}


else
{
  let test_new_s=this.tabapradminMenuItems[0];
  if(this.tabapradminMenuItems.length==0)
  {
    this.routeRegion();
  }
  else
  {
  console.log(test_new_s);
  this.activeItem=test_new_s;
 // document.getElementById("overlayapp").style.display = "none";
  // document.getElementById("loaderapp").style.display = "none";
  this.loaderapp=false
  this.router.navigate([this.activeItem.routerLink]);
  }
}
})

}
else
{
  this.routeRegion();
}
}


    else
{
  this.routeRegion();
}








   })






 /*  for(var i=0;i<this.routing_by_role.length;i++)
   {
       for(var j=0;j<roles.length;j++)
       {
         if(this.routing_by_role[i].role==roles[j].roleid)
         {


           this.tabapradminMenuItems.push(this.routing_by_role[i].value);
           break;

         }
       }
     }


     console.log(this.tabapradminMenuItems);
   }*/



    /*this.tabapradminMenuItems = [


      //visible:val}


  ];*/


  // this.activeItem = this.tabapradminMenuItems[0];
  }



  routeRegion()
  {

    console.log('regpck',this.region_code);

    if(this.region_code=='NA')

    {

   this.navigation.navigatena(true);

    }
    else if(this.region_code=='AP')
    {

   this.navigation.navigateap(true);

    }
    else if(this.region_code=='EU')
    {
   this.navigation.navigateeu(true);
    }
    else if(this.region_code=='SA')
    {
      console.log('insodesapack')
   this.navigation.navigatesa(true);
    }

  }



  loadRoles()
  {


    console.log('regpck',this.region_code);

    if(this.region_code=='NA')

    {
   this.navigation.navigateap(false);
   this.navigation.navigateeu(false);
   this.navigation.navigatesa(false);

    }
    else if(this.region_code=='AP')
    {

      this.navigation.navigatena(false);
      this.navigation.navigateeu(false);
      this.navigation.navigatesa(false);

    }
    else if(this.region_code=='EU')
    {

      this.navigation.navigatena(false);
      this.navigation.navigateap(false);
      this.navigation.navigatesa(false);
    }
    else if(this.region_code=='SA')
    {
      console.log('insodesapack')

      this.navigation.navigatena(false);
      this.navigation.navigateeu(false);
      this.navigation.navigateap(false);
    }

  }



  updateRegionList()
	{


		console.log('update')
		let glbadmin=JSON.parse(sessionStorage.getItem('isGlobalAdmin'))
		if(glbadmin==true)
		{


			let regionlistupdate=JSON.parse(sessionStorage.getItem('user_regionaccess'))


			if(regionlistupdate.length!=4)
			{
				let user_region=[];
				let regionListact=['AP','EU','NA','SA']

				user_region.push(...regionListact);


				sessionStorage.removeItem('user_regionaccess');
						   sessionStorage.setItem('user_regionaccess', JSON.stringify(user_region));

			}




		}
	}




  activateMenu(event) {
    console.log('click on ' + event.tabapradminMenuItems.label + ' tab!');
}


  ngOnDestroy()
  {
    this.transactionService.destroyPartnoSessionStorage(this.region_code);
  }

}
