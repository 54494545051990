/* tslint:disable */
import { Component, OnInit,Output,EventEmitter } from '@angular/core';


import {TransactionService} from '../loginpage/Transaction.service';
import { Router,ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { selec_prt_pro_details,  fromdiplayvalues_all, RevisionObj} from '../loginpage/transactions';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';



@Component({
  selector: 'app-preliminaryapp',
  templateUrl: './preliminaryapp.component.html',
  styleUrls: ['./preliminaryapp.component.css']
})
export class PreliminaryappComponent implements OnInit {



  viewProposal=false;
  region:string;
  regionCode:string;
  selectedSimpliedPartnoDetailsPrlimApr:selec_prt_pro_details={simplifiedpartno:'-',bompartmasterid:0,proposalid:0,plantgsdbcode:'',proposallastsaved:0,part_status:'',supplier_code:'',proposallastsaved_ee:0,is_ee_proposal:false,currentview_ee:false,from_draft:false,propsl_type:'',imp_part_status:''};
  proposalDetailsPrelim:fromdiplayvalues_all;
  revisionObj:RevisionObj[]=[];

  enabledetails=false;


  viewPrelimApr=false;



  currentRoleid:number;


  current_routeid=0



  constructor(private transactionService: TransactionService,private router:Router,private date:DatePipe,private route: ActivatedRoute) { }

  ngOnInit(): void {


    this.region=this.transactionService.getregion();
this.regionCode=this.transactionService.getregioncode(this.region);


    this.currentRoleid=3;

    console.log(this.currentRoleid)


    if(this.region=='Asia Pacific & Africa')
    {
      sessionStorage.setItem('aprole', JSON.stringify(this.currentRoleid));
    }

    else if(this.region=='Europe')
    {
      sessionStorage.setItem('eurole', JSON.stringify(this.currentRoleid));
    }

    else if (this.region=='North America')

    {
      console.log(this.currentRoleid)
      sessionStorage.setItem('narole', JSON.stringify(this.currentRoleid));
    }

    else if(this.region=='South America')
    {
      sessionStorage.setItem('sarole', JSON.stringify(this.currentRoleid));
    }

    this.current_routeid=251;

    console.log(this.current_routeid)


    if(this.region=='Asia Pacific & Africa')
    {
      sessionStorage.setItem('aprouteid', JSON.stringify(this.current_routeid));
    }

    else if(this.region=='Europe')
    {
      sessionStorage.setItem('eurouteid', JSON.stringify(this.current_routeid));
    }

    else if (this.region=='North America')

    {

      sessionStorage.setItem('narouteid', JSON.stringify(this.current_routeid));
    }

    else if(this.region=='South America')
    {
      sessionStorage.setItem('sarouteid', JSON.stringify(this.current_routeid));
    }

    // this.transactionService.storepage(this.region,"/packagingprocess/packsupplieradmin/packsupplier");




   this.viewPrelimApr=true;






}

/*
proposalview_prelimapr(prop:boolean)
{
  console.log("prop",prop);
  this.view_proposal=prop




this.selectedsimpliedpartnodetails_prlim_apr=this.transactionService.getpartno_packpro(this.region);

}


view_revision_history()
{


this.enabledetails=true;
console.log("revision history");
// this.transactionService.loadtransacmasterdetails(this.selectedsimpliedpartnodetails_prlim_apr.proposalid).subscribe(transdet=>{

var transdet="2/8/2022`submitted`pmonish1|5/8/2022`preliminary approved`sbalaj44|"
  console.log(transdet);
 // this.proposal_details_prelim=transdet;


 // console.log(this.proposal_details_prelim.revision_hstry);
  var revision_arr=[];
  this.revision_obj=[];

 // if(this.proposal_details_prelim.revision_hstry!=null)
 if(transdet!=null)
  {
    // revision_arr=this.proposal_details_prelim.revision_hstry.split(';')
    revision_arr=transdet.split('|')
    console.log(revision_arr)
         for(var i=0;i<revision_arr.length-1;i++)
         {
           var rev_arr=[];
           var rev_htm_str="";
           rev_arr=revision_arr[i].split('`');


           rev_htm_str=rev_arr[0]+";"+rev_arr[2]+";"+"<br>"+rev_arr[1];


          this.revision_obj.push({rev_string:revision_arr[i],rev_html_string:rev_htm_str})

         }

  }


  console.log(this.revision_obj);


  this.revision_obj=this.revision_obj.reverse();
  console.log(this.revision_obj)

// })
}


close_revision_history()
{
this.enabledetails=false;

}


*/



}
