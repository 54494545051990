/* tslint:disable */
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TransactionService } from '../loginpage/Transaction.service';
import { countrylistreqacc, currencyreglist, namePrefix, postsupplierdetails, regionMapping, supplierdetails } from '../loginpage/transactions';

@Component({
  selector: 'app-editsupplierprofile',
  templateUrl: './editsupplierprofile.component.html',
  styleUrls: ['./editsupplierprofile.component.css']
})
export class EditsupplierprofileComponent implements OnInit {


  noDesignsourceDialog=false;
  contentDesignsource=''



  noRoleSelectedDialog=false;
  noRoleContent=''


  editSupplierProfileCurrentRegion=' ';
  currentRegionCodeSub:string;


  editSupplierProfileRoleid:number;


  noSupDialog=false;
  content='';


  editSupplierProfileCdsid:string;


  suppCode:string;

  designsourceCode:string;


  userCode:string;
  prefix='';
  country='';
  Default_Currency='';
  notificationText='';

  countryList:countrylistreqacc[]=[];
  country_name='';
  prefixList:namePrefix[]=[];
  currencyList:currencyreglist[] = [];

  enableNotificationDialog = false;

  selectedCountry:countrylistreqacc={cd_Country:'',country_name:'',active_stat:''};
  selectedPrefix:namePrefix={prefix:''};
  selectedCurrency:currencyreglist= {curr_desc:'',active:false,active_stat:'',cd_curr:'',updated_by:'',updated_dt:''};

  supInfoGetApi: postsupplierdetails= {
    supplierid: 0, suppliergsdbcode: '', cd_region: '', isdesignsrc: '', fullname: '', address1: '', address2: '', city: '', stateprovince: ' ', postalcode: '', countrycode: '',
    shipbldg: '', shipdock: '', shipdetails: '', contactnamefirst: '', contactname: '', contactfunctn: '', contactemail: '', contactphone: '', contactfax: '', defaultcurr: '',
    firstvalidateddate: '', updatedby: '', updatedtimestamp: '', isactive: '', offsitesupplier: ''  };
  supInfo: postsupplierdetails= {
    supplierid: 0, suppliergsdbcode: '', cd_region: '', isdesignsrc: '', fullname: '', address1: '', address2: '', city: '', stateprovince: ' ', postalcode: '', countrycode: '',
    shipbldg: '', shipdock: '', shipdetails: '', contactnamefirst: '', contactname: '', contactfunctn: '', contactemail: '', contactphone: '', contactfax: '', defaultcurr: '',
    firstvalidateddate: '', updatedby: '', updatedtimestamp: '', isactive: '', offsitesupplier: ''
  };


  postSupInfo:postsupplierdetails = {
    supplierid: 0, suppliergsdbcode: '', cd_region: '', isdesignsrc: '', fullname: '', address1: '', address2: '', city: '', stateprovince: ' ', postalcode: '', countrycode: '',
    shipbldg: '', shipdock: '', shipdetails: '', contactnamefirst: '', contactname: '', contactfunctn: '', contactemail: '', contactphone: '', contactfax: '', defaultcurr: '',
    firstvalidateddate: '', updatedby: '', updatedtimestamp: '', isactive: '', offsitesupplier: ''
  };

  acceptTermsConditions: boolean;
  supName_InValid  = false;
  supName_InValidReason = null;
  address1_InValid = false;
  address1_InValidReason= null;
  address2_InValid = false;
  address2_InValidReason= null;
  city_InValid =  false;
  city_InValidReason = null;
  state_InValid = false;
  state_InValidReason = null;
  postalCode_InValid = false;
  postalCode_InValidReason = null;
  country_InValid =  false;
  country_InValidReason = null;
  shipBldg_InValid =  false;
  shipBldg_InValidReason = null;
  shipDock_InValid = false;
  shipDock_InValidReason = null;
  prefix_InValid = false;
  prefix_InValidReason = null;
  contactName_InValid = false;
  contactName_InValidReason =  null;
  contactFunc_InValid = false;
  contactFunc_InValidReason = null;
  contactEmail_InValid = false;
  contactEmail_InValidReason = null;
  contactPhn_InValid = false;
  contactPhn_InValidReason = null;
  fax_InValid = false;
  fax_InValidReason = null;
  currency_InValid = false;
  currency_InValidReason = null;
  supDetails_InValid = false;
  supDetails_InValidReason = null;

  constructor(private router:Router,private transactionService:TransactionService, private date: DatePipe) { }

  ngOnInit(): void {


    this.editSupplierProfileCdsid=this.transactionService.getcdsid();
    this.editSupplierProfileCurrentRegion=this.transactionService.getregion();

    this.currentRegionCodeSub = this.transactionService.getregioncode(this.editSupplierProfileCurrentRegion)

    this.editSupplierProfileRoleid=this.transactionService.getcurrentrole(this.editSupplierProfileCurrentRegion)

    console.log('supp',this.editSupplierProfileRoleid);


    this.userCode='';

    if(this.editSupplierProfileRoleid==null && this.editSupplierProfileRoleid!=1 && this.editSupplierProfileRoleid!=2)
    {


      this.noRoleSelectedDialog=true;
      this.noRoleContent='No role selected '
    }


    if(this.editSupplierProfileRoleid==1)
    {

    this.userCode=this.transactionService.get_supplier(this.editSupplierProfileCurrentRegion);
    console.log(this.userCode);
    }

    else if(this.editSupplierProfileRoleid==2)
    {

      this.userCode=this.transactionService.get_designsource(this.editSupplierProfileCurrentRegion);
      console.log(this.userCode);
    }



    if((this.editSupplierProfileRoleid==1 ||this.editSupplierProfileRoleid==2) && this.userCode!=null)
  {
    this.prefixList=[{prefix:'Mr.'},{prefix:'Ms.'},{prefix:'Mrs.'}];

  // code here for get data of supplier details
   this.loadUserDetails()
  }


  else if(this.editSupplierProfileRoleid==1)
{

if(this.userCode==null)
{
  this.noSupDialog=true;
  this.content='Please select supplier';
}
}

else if(this.editSupplierProfileRoleid==2)
{
  if(this.userCode==null)
  {
    this.noDesignsourceDialog=true;
    this.contentDesignsource='Please select Design Source'
  }
}

// this.loadUserDetails()


  }
 loadUserDetails() {
   this.supInfo.suppliergsdbcode = this.userCode
  this.transactionService.get_region_currency_details().subscribe( cur_det => {

    if(cur_det != null)
    {
    const obj = cur_det;
    this.currencyList = obj.filter(curren => curren.active_stat.toLowerCase() == 'yes');
    this.transactionService.getCountryListReqAccess().subscribe(country => {
      console.log(country);
      this.countryList = [];
      if(country != null)
      {
      this.countryList = country;
      console.log('country');
      console.log(this.countryList);
      // alert(this.userCode)
      this.transactionService.getSupInfo(this.userCode).subscribe(sup_det =>{
        console.log('SupplierInfo',sup_det);
        if(sup_det != null)
        {
        this.supInfoGetApi = sup_det;

        this.supInfo = this.supInfoGetApi;
        // alert(JSON.stringify(this.supInfo));
        console.log(this.supInfo.countrycode);
        if(this.countryList!=null){
        let countryIndex = this.countryList.findIndex(elcntry => elcntry.cd_Country.toLowerCase() == this.supInfo.countrycode.toLowerCase())
        if(countryIndex!=-1){
          this.selectedCountry = this.countryList[countryIndex]
        }
        else{
          this.selectedCountry = this.countryList[0]
        }
      }
        console.log(this.supInfo.contactnamefirst);
        if(this.supInfo.contactnamefirst!=null){
      let prefixIndex = this.prefixList.findIndex(elpfx => elpfx.prefix.toLowerCase() == this.supInfo.contactnamefirst.toLowerCase())
      if(prefixIndex!=-1){
        this.selectedPrefix = this.prefixList[prefixIndex]
      }
     }
        if(this.currencyList!=null && this.currencyList.length!=0){
          if(this.supInfo.defaultcurr!=null){
            let currind = this.currencyList.findIndex(dcur => dcur.curr_desc.toLowerCase() == this.supInfo.defaultcurr.toLowerCase())
            if(currind != -1){
              this.selectedCurrency = this.currencyList[currind];
            }
            else{
              this.selectedCurrency = this.currencyList[0];
            }
          }
          else{
            this.selectedCurrency = this.currencyList[0];
          }
        }
      }
      })
    }

    } )
  }
  })

  }


  goToSuppMainPage()
  {
    let reg_supp_url:string;
    reg_supp_url=this.transactionService.getregionurlvalue(this.editSupplierProfileCurrentRegion);
    if(this.editSupplierProfileRoleid==1)
    {
    reg_supp_url+='packagingprocess/packsupplieradmin/packsupplier';
    }
    else if(this.editSupplierProfileRoleid==2){
      reg_supp_url+='packagingprocess/packdesignsourceadmin/packdesignsource';

    }
    this.router.navigate([reg_supp_url]);

  }


  okNoSupDialog()
  {
    console.log(this.editSupplierProfileCurrentRegion)
    let reg_url:string
    reg_url=this.transactionService.getregionurlvalue(this.editSupplierProfileCurrentRegion);
    reg_url+='packagingprocess/packsupplieradmin/packsupplier';

    this.router.navigate([reg_url]);

  }


  okNoDesignsourceDialog()
  {
    console.log(this.editSupplierProfileCurrentRegion)
    let reg_url:string
    reg_url=this.transactionService.getregionurlvalue(this.editSupplierProfileCurrentRegion);
    reg_url+='packagingprocess/packdesignsourceadmin/packdesignsource';

    this.router.navigate([reg_url]);

  }



  okNoRoleDialog()
  {
    let url
    url=this.transactionService.getregionurlvalue(this.editSupplierProfileCurrentRegion);
    url+='packagingprocessdashboard'
    this.router.navigate([url]);

  }

 submitSuprProfile(){

      this.notificationText = ''
      this.postSupInfo.supplierid = this.supInfo.supplierid
      this.postSupInfo.suppliergsdbcode = this.supInfo.suppliergsdbcode
      this.postSupInfo.cd_region = this.currentRegionCodeSub
      this.postSupInfo.isdesignsrc  = this.supInfo.isdesignsrc
      this.postSupInfo.fullname  = this.supInfo.fullname
      this.postSupInfo.address1  = this.supInfo.address1
      this.postSupInfo.address2  = this.supInfo.address2
      this.postSupInfo.city  = this.supInfo.city
      this.postSupInfo.stateprovince  = this.supInfo.stateprovince
      this.postSupInfo.postalcode  = this.supInfo.postalcode
      this.postSupInfo.countrycode  = this.selectedCountry.cd_Country
      this.postSupInfo.shipbldg  = this.supInfo.shipbldg
      this.postSupInfo.shipdock  = this.supInfo.shipdock
      this.postSupInfo.shipdetails  = this.supInfo.shipdetails
      this.postSupInfo.contactnamefirst  = this.selectedPrefix.prefix
      this.postSupInfo.contactname  = this.supInfo.contactname
      this.postSupInfo.contactfunctn  = this.supInfo.contactfunctn
      this.postSupInfo.contactemail  = this.supInfo.contactemail
      this.postSupInfo.contactphone  = this.supInfo.contactphone
      this.postSupInfo.contactfax  = this.supInfo.contactfax
      this.postSupInfo.defaultcurr  = this.selectedCurrency.curr_desc
      this.postSupInfo.firstvalidateddate  = this.supInfo.firstvalidateddate
      this.postSupInfo.updatedby  = this.editSupplierProfileCdsid
      this.postSupInfo.updatedtimestamp  = this.date.transform(new Date(),'yyyy-MM-ddTHH:MM:SS')
      this.postSupInfo.isactive  = this.supInfo.isactive
      this.postSupInfo.offsitesupplier  = this.supInfo.offsitesupplier

      console.log('postSupInfo',this.postSupInfo);
      this.transactionService.postsupInfo(this.postSupInfo).subscribe(postStatus => {
        console.log('success',postStatus);
        if(postStatus.toLowerCase() == 'success'){
          this.notificationText = 'Profile Updated Successfully!!'
          this.enableNotificationDialog = true
        }
        else{
          this.notificationText = 'Profile Not Updated Successfully!!, Try Again'
            this.enableNotificationDialog = true
        }
      })



 }
 isDisableEditSupProfile() : boolean{
  /*if(this.supInfo.supplierid==null || this.supInfo.suppliergsdbcode==""|| this.selectedPrefix.prefix==""||  this.supInfo.cd_region==null || this.supInfo.isdesignsrc=="" ||
  this.supInfo.fullname == null || this.supInfo.address1 == "" || this.supInfo.address2==null ||this.supInfo.city=="" ||this.supInfo.stateprovince==null ||this.supInfo.postalcode=="" ||
  this.supInfo.countrycode==null ||this.supInfo.shipbldg=="" || this.supInfo.shipdock==null ||this.supInfo.shipdetails=="" || this.supInfo.contactnamefirst==null || this.supInfo.contactfunctn=="" ||
  this.supInfo.contactemail==null || this.supInfo.contactphone=="" ||this.supInfo.contactfax==null || this.supInfo.defaultcurr==""|| this.supInfo.firstvalidateddate==null || this.supInfo.updatedby=="" ||
  this.supInfo.updatedtimestamp=="" || this.supInfo.isactive=="" || this.supInfo.offsitesupplier=="" ||this.selectedCountry.country_name=="" )
  */

   if(this.supInfo.supplierid == null ||
    this.supInfo.suppliergsdbcode == null || this.supInfo.suppliergsdbcode =='' ||
    // this.supInfo.cd_region == null || this.supInfo.cd_region =="" ||
    this.supInfo.isdesignsrc== null || this.supInfo.isdesignsrc =='' ||
    this.supInfo.fullname== null || this.supInfo.fullname =='' ||
    this.supInfo.address1== null || this.supInfo.address1 =='' ||
    // this.supInfo.address2== null || this.supInfo.address2 =="" ||
    this.supInfo.city== null || this.supInfo.city =='' ||
    this.supInfo.stateprovince== null || this.supInfo.stateprovince =='' ||
    this.supInfo.postalcode== null || this.supInfo.postalcode =='' ||
    this.selectedCountry.country_name== null || this.selectedCountry.country_name =='' ||
    // this.supInfo.shipbldg== null || this.supInfo.shipbldg =="" ||
    // this.supInfo.shipdock== null || this.supInfo.shipdock =="" ||
    this.supInfo.shipdetails== null || this.supInfo.shipdetails =='' ||
    // this.selectedPrefix.prefix== null ||
    this.selectedPrefix.prefix =='' ||
    this.supInfo.contactname== null || this.supInfo.contactname =='' ||
    // this.supInfo.contactfunctn== null || this.supInfo.contactfunctn =="" ||
    this.supInfo.contactemail== null || this.supInfo.contactemail =='' ||
    this.supInfo.contactphone== null || this.supInfo.contactphone =='' ||
    // this.supInfo.contactfax== null || this.supInfo.contactfax =="" ||
    this.selectedCurrency.curr_desc== null || this.selectedCurrency.curr_desc =='' ||
    this.acceptTermsConditions == null || this.acceptTermsConditions == false
    // this.supInfo.firstvalidateddate== null || this.supInfo.firstvalidateddate =="" ||
    // this.supInfo.updatedby== null || this.supInfo.updatedby =="" ||
    // this.supInfo.updatedtimestamp== null || this.supInfo.updatedtimestamp =="" ||
    // this.supInfo.isactive== null || this.supInfo.isactive =="" ||
    // this.supInfo.offsitesupplier== null || this.supInfo.offsitesupplier ==""
    )
  {
    return true;
  } else {
    return false;
  }

 }

 okEnbaleNotification()
  {
    this.enableNotificationDialog=false;
  }

  /* Changes for Edit Supplier Profile field validation */
  supplierNameValidation() {

    if(this.supInfo.fullname == null || this.supInfo.fullname.trim() == "") {
     this.supName_InValid = true;
     this.supName_InValidReason = "Required";
    } else if(String(this.supInfo.fullname).length <= 0 || String(this.supInfo.fullname).length > 100) {
     this.supName_InValid = true;
     this.supName_InValidReason = "Name can not exceed 100 characters";
    } else {
     this.supName_InValid = false;
     this.supName_InValidReason = null;
    }

  }

  address1Validation() {

   if(this.supInfo.address1 == null || this.supInfo.address1.trim() == "") {
    this.address1_InValid = true;
    this.address1_InValidReason = "Required";
   } else if(String(this.supInfo.address1).length <= 0 || String(this.supInfo.address1).length > 99) {
    this.address1_InValid = true;
    this.address1_InValidReason = "Address can not exceed 99 characters";
   } else {
    this.address1_InValid = false;
    this.address1_InValidReason = null;
   }

  }
  address2Validation() {

   if(String(this.supInfo.address2).length > 99) {
    this.address2_InValid = true;
    this.address2_InValidReason = "Address can not exceed 99 characters";
   } else {
    this.address2_InValid = false;
    this.address2_InValidReason = null;
   }
   
  }

  cityValidation() {
    if(this.supInfo.city == null || this.supInfo.city.trim() == "") {
     this.city_InValid = true;
     this.city_InValidReason = "Required";
    } else if(String(this.supInfo.city).length <= 0 || String(this.supInfo.city).length > 29) {
     this.city_InValid = true;
     this.city_InValidReason = "City can not exceed 29 characters";
    } else {
     this.city_InValid = false;
     this.city_InValidReason = null;
    }
    
   }

   stateValidation() {

   if(this.supInfo.stateprovince == null || this.supInfo.stateprovince.trim() == "") {
    this.state_InValid = true;
    this.state_InValidReason = "Required";
   } else if(String(this.supInfo.stateprovince).length <= 0 || String(this.supInfo.stateprovince).length > 29) {
    this.state_InValid = true;
    this.state_InValidReason = "State can not exceed 29 characters";
   } else {
     this.state_InValid = false;
    this.state_InValidReason = null;
   }

  }

  postalCodeValidation() {

   if(this.supInfo.postalcode == null || this.supInfo.postalcode.trim() == "") {
    this.postalCode_InValid = true;
    this.postalCode_InValidReason = "Required";
   } else if(String(this.supInfo.postalcode).length <= 0 || String(this.supInfo.postalcode).length > 24) {
    this.postalCode_InValid = true;
    this.postalCode_InValidReason = "Postal Code can not exceed 24 characters";
   } else {
    this.postalCode_InValid = false;
    this.postalCode_InValidReason = null;
   }

  }

  countryValidation() {
    
    if(this.selectedCountry == null || this.selectedCountry.country_name == null || this.selectedCountry.country_name.trim() == "") {
    this.country_InValid = true;
    this.country_InValidReason = "Required";
    } else {
    this.country_InValid = false;
    this.country_InValidReason = null;
   }

  }

  shipBldgValidation() {

    if(String(this.supInfo.shipbldg).length > 50) {
      this.shipBldg_InValid = true;
      this.shipBldg_InValidReason = "Ship building can not exceed 50 characters";
     } else {
      this.shipBldg_InValid = false;
      this.shipBldg_InValidReason = null;
     }

  }

  shipDockValidation() {

    if(String(this.supInfo.shipbldg).length > 25) {
      this.shipDock_InValid = true;
      this.shipDock_InValidReason = "Ship Dock can not exceed 25 characters";
     } else {
      this.shipDock_InValid = false;
      this.shipDock_InValidReason = null;
     }

  }

  prefixValidation() {

    if(this.selectedPrefix.prefix == null || this.selectedPrefix.prefix == null || this.selectedPrefix.prefix.trim() == "") {
      this.prefix_InValid = true;
      this.prefix_InValidReason = "Required";
      } else {
      this.prefix_InValid = false;
      this.prefix_InValidReason = null;
     }

  }

  contactNameValidation() {
    if(this.supInfo.contactname == null || this.supInfo.contactname.trim() == "") {
      this.contactName_InValid = true;
      this.contactName_InValidReason = "Required";
     } else if(String(this.supInfo.contactname).length <= 0 || String(this.supInfo.contactname).length > 49) {
      this.contactName_InValid = true;
      this.contactName_InValidReason = "Contact Name can not exceed 49 characters";
     } else {
      this.contactName_InValid = false;
      this.contactName_InValidReason = null;
     }
  }

  contactFuncValidation() {

    if(this.supInfo.contactfunctn == null || this.supInfo.contactfunctn.trim() == "") {
      this.contactFunc_InValid = true;
      this.contactFunc_InValidReason = "Required";
     } else if(String(this.supInfo.contactfunctn).length <= 0 || String(this.supInfo.contactfunctn).length > 49) {
      this.contactFunc_InValid = true;
      this.contactFunc_InValidReason = "Contact Function can not exceed 49 characters";
     } else {
      this.contactFunc_InValid = false;
      this.contactFunc_InValidReason = null;
     }

  }

  emailValidation() {

    if(this.supInfo.contactemail == null || this.supInfo.contactemail.trim() == "") {
    this.contactEmail_InValid = true;
    this.contactEmail_InValidReason = "Required";
    } else if(String(this.supInfo.contactemail).length <= 0 || String(this.supInfo.contactemail).length > 49) {
    this.contactEmail_InValid = true;
    this.contactEmail_InValidReason = "Email ID can not exceed 49 characters";
   } else if(!this.validateEmailFormat(this.supInfo.contactemail)) {
    this.contactEmail_InValid = true;
    this.contactEmail_InValidReason = "Not a valid email ID";
   } else {
    this.contactEmail_InValid = false;
    this.contactEmail_InValidReason = null;
   }
   
  }

  validateEmailFormat(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
  }

  phoneValidation() {

    if(this.supInfo.contactphone == null || this.supInfo.contactphone.trim() == "") {
    this.contactPhn_InValid = true;
    this.contactPhn_InValidReason = "Required";
    } else if(String(this.supInfo.contactphone).length <= 0 || String(this.supInfo.contactphone).length > 99) {
    this.contactPhn_InValid = true;
    this.contactPhn_InValidReason = "Phone Number can not exceed 99 characters";
    } else {
    this.contactPhn_InValid = false;
    this.contactPhn_InValidReason = null;
   }

  }

  faxValidation() {

    if(String(this.supInfo.contactfax).length > 19) {
    this.fax_InValid = true;
    this.fax_InValidReason = "Fax can not exceed 19 characters";
    } else if(!this.faxNumberValidation(this.supInfo.contactfax)) {
    this.fax_InValid = true;
    this.fax_InValidReason = "Not a valid fax number";
    } else {
    this.fax_InValid = false;
    this.fax_InValidReason = null;
   }

  }

  faxNumberValidation(faxNum) {
    return String(faxNum).toLowerCase().match(/^\+?[0-9]{6,}$/);
  }

  currencyValidation() {

    if(this.selectedCurrency == null || this.selectedCurrency.curr_desc == null || this.selectedCurrency.curr_desc.trim() == "") {
      this.currency_InValid = true;
      this.currency_InValidReason = "Required";
      } else {
      this.currency_InValid = false;
      this.currency_InValidReason = null;
     }

  }

  supDetailsValidation() {

    if(this.supInfo.shipdetails == null || this.supInfo.shipdetails.trim() == "") {
      this.supDetails_InValid = true;
      this.supDetails_InValidReason = "Required";
      } else {
      this.supDetails_InValid = false;
      this.supDetails_InValidReason = null;
     }

  }

  




}
