import {
    Component,
    OnInit
} from '@angular/core';
import {
    ActivatedRoute,
    NavigationStart,
    NavigationEnd,
    NavigationError,
    NavigationCancel,
    Router,
    RouterEvent
} from '@angular/router';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
    accessToken = sessionStorage.getItem('encodedAccessToken');
    serviceResult: any;
    loading = true;

    constructor(private router: Router, private route: ActivatedRoute) {
        router.events.subscribe((routerEvent: RouterEvent) => {
            this.checkRouterEvent(routerEvent);
        });
    }

    ngOnInit() {
        this.serviceResult = JSON.stringify(this.route.snapshot.data.result);
        if (this.serviceResult === 'null') {
            this.serviceResult = 'Downstream API is offline';

            this.router.navigateByUrl('/loginpage');
        }
    }

    checkRouterEvent(routerEvent: RouterEvent): void {
        if (routerEvent instanceof NavigationStart) {
            this.loading = true;
        }

        if (routerEvent instanceof NavigationEnd ||
            routerEvent instanceof NavigationCancel ||
            routerEvent instanceof NavigationError) {
            this.loading = false;
        }
    }
}
