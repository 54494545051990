/* tslint:disable */
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng';
import { TransactionService } from '../loginpage/Transaction.service';
import { ActivePlants, cosList, countrylist, countrylistreqacc, countryreglist, pendingAccessGridDetls, pendingAccessGridDetlsUI, programsbasedonplants, rolesDetails, saveUserRoleAccess, suppDesgnCodeDetailsList, userDetailsEmailTriggerPost, userRequestDetialsEmailTrigger } from '../loginpage/transactions';

@Component({
  selector: 'app-pendingaccessrequest',
  templateUrl: './pendingaccessrequest.component.html',
  styleUrls: ['./pendingaccessrequest.component.css']
})
export class PendingaccessrequestComponent implements OnInit {

  current_region:any;
  current_region_code:any;

  pendingAccessRequestGrid:pendingAccessGridDetls[]=[];
  pendingAccessRequestGridForUI:pendingAccessGridDetlsUI[]=[]
  pendingAccessRequestDetails:pendingAccessGridDetlsUI;
  cols:any;

  disableButtonUntilAPIReturnsReponse=false;

  prefix_list=[{prefix_name:'Mrs.'},{prefix_name:'Mr.'},{prefix_name:'Ms.'}];
  country_list:countrylistreqacc[]=[];
  selected_prefix:any;
  selected_countrylist:countrylistreqacc = {cd_Country:"",country_name:"",active_stat:""};

  rolesList:rolesDetails[]=[];
  requestedRoleForAccess='';

  enablePendingRequestGrid:boolean;
  enableRequestDetailsPopUp:boolean;

  plant_apply_limit='';
  enable_plant_dropdown=false;

  program_apply_limit='';
  enable_program_drop_down=false;

  cos_apply_limit='';
  enable_cos_drop_down=false;

  plantsListFromDB:ActivePlants[]=[];
  plantActualList:ActivePlants[]=[];
  plantSelectedList:ActivePlants[]=[];


  programListFromDb:programsbasedonplants[]=[];
  prgmActualList:programsbasedonplants[]=[];
  prgmSelectedList:programsbasedonplants[]=[];
  disbleProgramPickList=false;

  cosListFromDb:cosList[]=[];
  cosActualList:cosList[]=[];
  cosSelectedList:cosList[]=[];

  requestThatIsApproved:saveUserRoleAccess[]=[];
  /*requestThatIsApproved:saveUserRoleAccess={
           "gppuserid":"",
            "title":"",
            "name":"",
            "company":"",
            "designation":"",
            "email":"",
            "phone":"",
            "address1":"",
            "address2":"",
            "city":"",
            "state":"",
            "postalcode":"",
            "cntrycodeiso":"",
            "lastlogin":"",
            "isforduser":"",
            "isactive":"",
            "createddt":"",
            "createdby":"",
            "fax":"",
            "country":"",
            "usertype":"",
            "role_id":0,
            "cd_region":"",
            "supGSDBLst":"",
            "pltGSDBLst":"",
            "cd_program":0,
            "coslist":"",
            "pltNameLst":"",
            "progList":""
  }*/

  selected_plants='';
  selected_plants_name='';


  currentRouteid=0;


  disablePlantLimit=false;
  disableCosLimit=false;

  roleListforMapping=[{role_id:1,role_shot_name:'Supplier',rolefullname:'Supplier'},
  {role_id:2,role_shot_name:'design_src',rolefullname:'Design Source'},
  {role_id:3,role_shot_name:'prelimnry_appr',rolefullname:'Preliminary Approver'},
  {role_id:4,role_shot_name:'addntl_appvr',rolefullname:'Additional Approver'},
  {role_id:5,role_shot_name:'plt_appvr',rolefullname:'Plant Approver'},
  {role_id:6,role_shot_name:'plt_final_apprvr',rolefullname:'Plant Final Approver'},
  {role_id:7,role_shot_name:'concurent_user',rolefullname:'Concurrent User'},
  {role_id:8,role_shot_name:'reg_bom_upld',rolefullname:'Region Bom Upload'},
  {role_id:9,role_shot_name:'rel_for_eval',rolefullname:'Release for Evaluation'},
  {role_id:11,role_shot_name:'rel_for_prod',rolefullname:'Release for Production'},
  {role_id:12,role_shot_name:'regnl_admin',rolefullname:'Regional Admin'},
  {role_id:13,role_shot_name:'reg_usr_admin',rolefullname:'Regional User Admin'},
  {role_id:14,role_shot_name:'reg_admin_supvsr',rolefullname:'Regional Admin Supervisor'},
  {role_id:15,role_shot_name:'reg_news_authr',rolefullname:'Regional User Author'},
  {role_id:16,role_shot_name:'glbl_news_authr',rolefullname:'Global News Author'},
  {role_id:17,role_shot_name:'reg_prg_admin',rolefullname:'Regional Program Admin'},
  {role_id:18,role_shot_name:'bailment_supplier',rolefullname:'Bailment Supplier'},
  {role_id:21,role_shot_name:'eqo_admin',rolefullname:'EQO Admin'},
  {role_id:23,role_shot_name:"cntr_admin",rolefullname:"Container/Component Admin"},
  {role_id:99,role_shot_name:'view_only',rolefullname:'View Only'},
  {role_id:101,role_shot_name:'a_packaging',rolefullname:'Packaging Process'},
  {role_id:102,role_shot_name:'a_partplanning',rolefullname:'Part Planning'},
  {role_id:103,role_shot_name:'a_fedebom',rolefullname:'FEDEBOM View'}
  ]


  suppDsgnDetails:suppDesgnCodeDetailsList[]; // to be defined based on interface
  gsdbCodes='';
  isDesignSource='';
  enableSupplierDetailsDialog=false;


  public colsSuppDesgn:any[]=[];

  name_InValid : boolean = false;
  name_InValidReason: string = null;
  companyName_InValid : boolean = false;
  companyName_InValidReason: string = null;
  role_InValid : boolean = false;
  role_InValidReason: string = null;
  phone_InValid : boolean = false;
  phone_InValidReason: string = null;
  fax_InValid : boolean = false;
  fax_InValidReason: string = null;
  email_InValid : boolean = false;
  email_InValidReason: string = null;
  address_InValid : boolean = false;
  address_InValidReason: string = null;
  address2_InValid : boolean = false;
  address2_InValidReason: string = null;
  city_InValid : boolean = false;
  city_InValidReason: string = null;
  state_InValid : boolean = false;
  state_InValidReason: string = null;
  postalCode_InValid : boolean = false;
  postalCode_InValidReason: string = null;
  country_InValid : boolean = false;
  country_InValidReason: string = null;

  plantNotSelectedWhenNo : boolean = false;
  plantNotSelectedWhenNoReason : string = null;
  programNotSelectedWhenNo : boolean = false;
  programNotSelectedWhenNoReason : string = null;
  cosNotSelectedWhenNo : boolean = false;
  cosNotSelectedWhenNoReason : string = null;

  approvedRequestDetailsForEmail:userRequestDetialsEmailTrigger[];
  rejectedRequestDetailsForEmail:userRequestDetialsEmailTrigger[];
  userDetailsEmailPost:userDetailsEmailTriggerPost={
    gppuserid:"",
    function:0,
    userRequestAccessList:[]
  }

  rejectionComments:string = "";
  rejReqDetails:pendingAccessGridDetlsUI={
    reqno: 0,
		gppuserid: "",
		user_name: "",
		name_prefix:"",
		cmpny_name: "",
		desig: "",
		phone:"",
		fax: "",
		email: "",
		address1: "",
		address2: "",
		city: "",
		state: "",
		postcode: "",
		country: "",
		reqstd_access_role_name: "", // Roll_id  ex. Supplier -1, View Only – 99 as mentioned in ‘ngpp_pack_role_mstr’
		req_supgsdbcodes: "",
		req_dsgnsrcids: "",
		req_plantgsdbcodes: "",
		region: "",
		user_type: "",
		req_type: "",
		req_date: ""
  }

  enableCommentsPopUp:boolean=false;
  enable_dialog_notification:boolean=false;



  constructor(private transactionService:TransactionService,private messageService:MessageService,private date:DatePipe) { }

  ngOnInit(): void
  {
    this.current_region=this.transactionService.getregion();
    this.transactionService.storepage(this.current_region,'packagingprocess/useradmin/PendingAccessRequest');
    this.current_region_code=this.transactionService.getregioncode(this.current_region);
    this.enablePendingRequestGrid=false;
    this.enableRequestDetailsPopUp=false;
    this.disableButtonUntilAPIReturnsReponse=false;




 this.currentRouteid=51;

 console.log(this.currentRouteid)


 if(this.current_region=='Asia Pacific & Africa')
 {
   sessionStorage.setItem('aprouteid', JSON.stringify(this.currentRouteid));
 }

 else if(this.current_region=='Europe')
 {
   sessionStorage.setItem('eurouteid', JSON.stringify(this.currentRouteid));
 }

 else if (this.current_region=='North America')

 {

   sessionStorage.setItem('narouteid', JSON.stringify(this.currentRouteid));
 }

 else if(this.current_region=='South America')
 {
   sessionStorage.setItem('sarouteid', JSON.stringify(this.currentRouteid));
 }






    this.transactionService.getCountryListReqAccess().subscribe(cntryList=>{
      this.country_list=cntryList
      console.log('country',this.country_list)
    })
    this.requestDetailsInit();
    this.loadPlantsOfThisRegion();
    this.loadCOSOfThisRegion();
    this.populateGrid();

    
    
  }

  userInfoInitValidation()
{
  this.name_InValid = false;
  this.name_InValidReason = null;
  this.companyName_InValid = false;
  this.companyName_InValidReason = null;
  this.role_InValid = false;
  this.role_InValidReason = null;
  this.phone_InValid = false;
  this.phone_InValidReason = null;
  this.fax_InValid = false;
  this.fax_InValidReason = null;
  this.email_InValid = false;
  this.email_InValidReason = null;
  this.address_InValid  = false;
  this.address_InValidReason = null;
  this.address2_InValid = false;
  this.address2_InValidReason = null;
  this.city_InValid = false;
  this.city_InValidReason = null;
  this.state_InValid = false;
  this.state_InValidReason= null;
  this.postalCode_InValid = false;
  this.postalCode_InValidReason = null;
  this.country_InValid = false;
  this.country_InValidReason = null;

  this.plantNotSelectedWhenNo = false;
  this.plantNotSelectedWhenNoReason = null;
  this.programNotSelectedWhenNo = false;
  this.programNotSelectedWhenNoReason = null;
  this.cosNotSelectedWhenNo = false;
  this.cosNotSelectedWhenNoReason = null;

}

  loadPlantsOfThisRegion()
  {
    this.transactionService.getactiveplantslist(this.current_region_code).subscribe(plantslist=>{
      console.log('plants list',plantslist);
      this.plantsListFromDB=plantslist;
      this.plantActualList=JSON.parse(JSON.stringify(plantslist))// .map(e=>e.plant_name);
    })
  }

  loadCOSOfThisRegion()
  {
    this.transactionService.getCosListonRegion(this.current_region_code).subscribe(coslists=>{
      console.log('COS List',coslists);
      this.cosListFromDb=coslists;
      this.cosActualList=JSON.parse(JSON.stringify(coslists));
    })
  }

  checkIfAnyPlantsAreSelected()
  {
    this.selected_plants='';
    this.selected_plants_name='';
    if(this.plantSelectedList!=null && this.plantSelectedList.length>0)
    {
      for(let i=0; i<this.plantSelectedList.length;i++)
      {
        this.selected_plants+=this.plantSelectedList[i].plantGSDBCode;
        this.selected_plants_name+=this.plantSelectedList[i].plant_name;

        if(i!=this.plantSelectedList.length-1)
        {
          this.selected_plants+=',';
          this.selected_plants_name+=',';
        }
      }

      this.transactionService.getprogmlistbasedonplantgsdb(this.selected_plants,this.current_region_code).subscribe(progs=>{
        this.prgmActualList=JSON.parse(JSON.stringify(progs));

        this.programListFromDb=progs;
        this.disbleProgramPickList=false;

        if(this.plantSelectedList.length==this.plantsListFromDB.length)
        {
          this.prgmSelectedList=JSON.parse(JSON.stringify(progs));
          this.prgmActualList=[];
          this.disbleProgramPickList=true;
        }

        if(this.prgmSelectedList!=null && this.prgmSelectedList.length!=0 && this.prgmActualList!=null && this.prgmActualList.length!=0)
        {
          for(const sp of this.prgmSelectedList)
          {
            if(this.prgmActualList.some(ele=>ele.programname==sp.programname))
            {
              const index=this.prgmActualList.findIndex(e=>e.programname==sp.programname);
              this.prgmActualList.splice(index,1);
            }
          }
        }


        if(this.programListFromDb!=null && this.programListFromDb.length!=0 && this.prgmSelectedList!=null && this.prgmSelectedList.length!=0)
          {
            const tempList:programsbasedonplants[]=[];
            for(let k=0;k<this.prgmSelectedList.length;k++)
            {
              let flag =false;
              for(let l=0;l<this.programListFromDb.length;l++)
              {
                if(this.prgmSelectedList[k].programname==this.programListFromDb[l].programname)
                {
                  flag=true;
                  tempList.push(this.programListFromDb[l]);
                  break;
                }
              }

            }
            this.prgmSelectedList=tempList;
          }

        console.log('Program List',progs);
      });
    }
    else
    {
      this.selected_plants='';
      this.selected_plants_name=''
      this.prgmActualList=[];
      this.programListFromDb=[];
      this.prgmSelectedList=[];
    }

    // alert(this.selected_plants)
  }

  moveRecentAddedPlantToFirst()
  {
    const plantToBePoppedOut=this.plantActualList.pop();
    this.plantActualList.splice(0,0,plantToBePoppedOut)
  }

  moveRecentAddedPrgmToFirst()
  {
    const prgmToBePoppedOut=this.prgmActualList.pop();
    this.prgmActualList.splice(0,0,prgmToBePoppedOut);
  }

  moveRecentAddedCOSToFirst()
  {
    const cosToBePoppedOut=this.cosActualList.pop();
    this.cosActualList.splice(0,0,cosToBePoppedOut);
  }

  populateGrid()
  {
    this.transactionService.getRolesThatCanBeGivenAccess(this.current_region_code).subscribe(rolelist=>{
      this.rolesList=rolelist;

      this.transactionService.getPendingAccessRequestGrid(this.current_region_code).subscribe(pendingAccess=>{

        this.pendingAccessRequestGrid=[];
        this.pendingAccessRequestGridForUI=[];

        this.pendingAccessRequestGrid=pendingAccess;
        console.log('grid value - pending access request',this.pendingAccessRequestGrid)

        if(this.pendingAccessRequestGrid.length>=1)
        {
          this.enablePendingRequestGrid=true;

          for (const i of this.pendingAccessRequestGrid)
          {
            let role_name=i.reqstd_access;

            const inde=this.rolesList.findIndex(element=>Number(element.role_id)==Number(i.reqstd_access))

            if(inde!=-1)
            {
              role_name=this.rolesList[inde].role_name;
              const indRoleMap=this.roleListforMapping.findIndex(el=>el.role_shot_name.toLowerCase()==role_name.toLowerCase())

              if(indRoleMap>=0)
              {
                role_name=this.roleListforMapping[indRoleMap].rolefullname
              }
            }

            this.pendingAccessRequestGridForUI.push({
              reqno: i.reqno,
              gppuserid: i.gppuserid,
              user_name: i.user_name,
              name_prefix: i.name_prefix,
              cmpny_name: i.cmpny_name,
              desig: i.desig,
              phone: i.phone,
              fax: i.fax,
              email:i.email,
              address1: i.address1,
              address2: i.address2,
              city: i.city,
              state: i.state,
              postcode: i.postcode,
              country: i.country,
              reqstd_access_role_name: role_name, // Roll_id  ex. Supplier -1, View Only – 99 as mentioned in ‘ngpp_pack_role_mstr’
              req_supgsdbcodes: i.req_supgsdbcodes,
              req_dsgnsrcids: i.req_dsgnsrcids,
              req_plantgsdbcodes: i.req_plantgsdbcodes,
              region: i.region,
              user_type: i.user_type,
              req_type: i.req_type,
              req_date: i.req_date

            })
          }



          this.cols=[
            {field:'gppuserid',header:'GPP User ID'},
            {field:'user_name',header:'Name'},
            {field:'user_type',header:'User Type'},
            {field:'reqstd_access_role_name',header:'Requested For'},
            {field:'req_supgsdbcodes',header:'Req Supplier GSDB Codes'},
            {field:'req_dsgnsrcids',header:'Req Design Source ID\'S'},
            {field:'req_plantgsdbcodes',header:'Req Plant GSDB Codes'},
            {field:'req_type',header:'Request Type'},
            {field:'req_date',header:'Request Date'}
          ]
        }
        else
        {
          this.enablePendingRequestGrid=false;
        }
      })
    });
  }

  requestDetailsInit()
  {
    this.pendingAccessRequestDetails={
        "reqno": 0,
        "gppuserid": "",
        "user_name": "",
        "name_prefix": "",
        "cmpny_name": "",
        "desig": "",
        "phone": "",
        "fax": "",
        "email":"",
        "address1": "",
        "address2": "",
        "city": "",
        "state": "",
        "postcode": "",
        "country": "",
        "reqstd_access_role_name": "", //Roll_id  ex. Supplier -1, View Only – 99 as mentioned in ‘ngpp_pack_role_mstr’
        "req_supgsdbcodes": "",
        "req_dsgnsrcids": "",
        "req_plantgsdbcodes": "",
        "region": "",
        "user_type": "",
        "req_type": "",
        "req_date": ""
 
    }
  }

  rejReqDetailsInit()
  {
    this.rejReqDetails={
    reqno: 0,
		gppuserid: "",
		user_name: "",
		name_prefix:"",
		cmpny_name: "",
		desig: "",
		phone:"",
		fax: "",
		email: "",
		address1: "",
		address2: "",
		city: "",
		state: "",
		postcode: "",
		country: "",
		reqstd_access_role_name: "", // Roll_id  ex. Supplier -1, View Only – 99 as mentioned in ‘ngpp_pack_role_mstr’
		req_supgsdbcodes: "",
		req_dsgnsrcids: "",
		req_plantgsdbcodes: "",
		region: "",
		user_type: "",
		req_type: "",
		req_date: ""
  }
  }

  getRequestdetails(rowData:pendingAccessGridDetlsUI)
  {
    this.requestDetailsInit();
    this.rejectionComments="";
    this.pendingAccessRequestDetails=Object.assign({},rowData);

    const prefixIndex=this.prefix_list.findIndex(e=>e.prefix_name==this.pendingAccessRequestDetails.name_prefix);
    if(prefixIndex>=0)
    {
      this.selected_prefix=this.prefix_list[prefixIndex];
    }
    else{
      this.selected_prefix=null;
    }

    const countryIndex=this.country_list.findIndex(e=>e.country_name==this.pendingAccessRequestDetails.country);
    if(countryIndex>=0)
    {
      this.selected_countrylist=this.country_list[countryIndex];
    }
    else
    {
      this.selected_countrylist=null;
    }

    if(this.pendingAccessRequestDetails.reqstd_access_role_name.toLowerCase()=='supplier'|| this.pendingAccessRequestDetails.reqstd_access_role_name.toLowerCase()=='design source')
    {
    /*  this.plant_apply_limit="Yes";
      this.enable_plant_dropdown=true;
      this.disablePlantLimit=false;

      this.program_apply_limit="Yes";
      this.enable_program_drop_down=true;

      this.cos_apply_limit="Yes";
      this.enable_cos_drop_down=true;
      this.disableCosLimit=false;
      */

      // was requested by business that default value should be set no for supplier an design source

      this.plant_apply_limit='No';
      this.enable_plant_dropdown=false;
      this.disablePlantLimit=false;

      this.program_apply_limit='No';
      this.enable_program_drop_down=false;

      this.cos_apply_limit='No';
      this.enable_cos_drop_down=false;
      this.disableCosLimit=false;

    }


    else if(this.pendingAccessRequestDetails.reqstd_access_role_name.toLowerCase()=='regional admin'|| this.pendingAccessRequestDetails.reqstd_access_role_name.toLowerCase()=='regional user admin'
               || this.pendingAccessRequestDetails.reqstd_access_role_name.toLowerCase()=='regional admin supervisor'|| this.pendingAccessRequestDetails.reqstd_access_role_name.toLowerCase()=='regional program admin'
               || this.pendingAccessRequestDetails.reqstd_access_role_name.toLowerCase()=='eqo admin' || this.pendingAccessRequestDetails.reqstd_access_role_name.toLowerCase()=='view only' || this.pendingAccessRequestDetails.reqstd_access_role_name.toLowerCase()=='container/component admin')
    {


      this.plant_apply_limit='No';
      this.enable_plant_dropdown=false;
      this.disablePlantLimit=true;

      this.program_apply_limit='No';
      this.enable_program_drop_down=false;

      this.cos_apply_limit='No';
      this.enable_cos_drop_down=false;
      this.disableCosLimit=true;




    }
    else
    {
      if(this.pendingAccessRequestDetails.req_plantgsdbcodes!=null && this.pendingAccessRequestDetails.req_plantgsdbcodes.toLowerCase()=='all')
      {


        this.plant_apply_limit='No';
        this.enable_plant_dropdown=false;
        this.disablePlantLimit=false;

        this.program_apply_limit='No';
        this.enable_program_drop_down=false;

        this.cos_apply_limit='Yes';
        this.enable_cos_drop_down=true;
        this.disableCosLimit=false;


      }




      else
      {
      this.plant_apply_limit='Yes';
      this.enable_plant_dropdown=true;
      this.disablePlantLimit=false;

      if(this.pendingAccessRequestDetails.req_plantgsdbcodes!=null)
      {
        for(const plt of this.pendingAccessRequestDetails.req_plantgsdbcodes.split(','))
        {
          if(this.plantActualList.some(e=>e.plantGSDBCode.toLowerCase()==plt.toLowerCase()))
          {
            const indexOfPlantToBeAdded=this.plantActualList.findIndex(e=>e.plantGSDBCode.toLowerCase()==plt.toLowerCase());

            this.plantSelectedList.push(this.plantActualList[indexOfPlantToBeAdded]);
            this.plantActualList.splice(indexOfPlantToBeAdded,1);
          }
        }
      }
      this.program_apply_limit='No';
      this.enable_program_drop_down=false;
      // this.program_apply_limit="Yes";
      // this.enable_program_drop_down=true;
      this.checkIfAnyPlantsAreSelected();

      this.cos_apply_limit='Yes';
      this.enable_cos_drop_down=true;
      this.disableCosLimit=false;
    }
    }
    this.enableRequestDetailsPopUp=true;
    this.disableButtonUntilAPIReturnsReponse=false;


  }


  supplierDetailsDialog()
  {
    this.colsSuppDesgn=[];
    this.gsdbCodes='';
    this.suppDsgnDetails=[];

    if(this.pendingAccessRequestDetails.req_supgsdbcodes != null && this.pendingAccessRequestDetails.req_supgsdbcodes.trim() != '')
    {
    this.enableSupplierDetailsDialog=true;
    this.gsdbCodes=this.pendingAccessRequestDetails.req_supgsdbcodes;
    this.isDesignSource='N'


    this.colsSuppDesgn=[
            {field:'supgsdbcode',header:'Supplier GSDB Code'},
            {field:'cd_region',header:'Region'},
            {field:'fullname',header:'Supplier Name'},
            {field:'displayAddress',header:'Address'},
            {field:'contactname',header:'Contact Name'},
            {field:'contactemail',header:'Email'},
            {field:'contactphone',header:'Phone No'},
            {field:'displayContact',header:'Contact Fax'}
    ];

    this.getGsdbCodeDetails()
    }



  }
  designSourceDetailsDialog()
  {

    this.colsSuppDesgn=[];
    this.gsdbCodes='';
    this.suppDsgnDetails=[];

    if(this.pendingAccessRequestDetails.req_dsgnsrcids != null && this.pendingAccessRequestDetails.req_dsgnsrcids.trim() != '')
    {
    this.enableSupplierDetailsDialog=true;

    this.gsdbCodes=''
    this.gsdbCodes=this.pendingAccessRequestDetails.req_dsgnsrcids;
    this.isDesignSource='Y'

    this.colsSuppDesgn=[
      {field:'supgsdbcode',header:'DesignSource GSDB Code'},
      {field:'cd_region',header:'Region'},
      {field:'fullname',header:'DesignSource Name'},
      {field:'displayAddress',header:'Address'},
      {field:'contactname',header:'Contact Name'},
      {field:'contactemail',header:'Email'},
      {field:'contactphone',header:'Phone No'},
      {field:'displayContact',header:'Contact Fax'}
];

    this.getGsdbCodeDetails()
    }

  }

  getGsdbCodeDetails()
  {
    this.transactionService.getSuppDesgnDetailsForListOfCodes(this.gsdbCodes,this.isDesignSource).subscribe(suppdesgndet=>{
      console.log('Suplier DesignSource Details',suppdesgndet)
      this.suppDsgnDetails=[];
      if(suppdesgndet != null && suppdesgndet.length != 0)
      {
      this.suppDsgnDetails=suppdesgndet;
      for(let i = 0; i<this.suppDsgnDetails.length; i++)
      {
        this.suppDsgnDetails[i].displayAddress=''
      /*if(this.suppDsgnDetails[i].fullname != null && this.suppDsgnDetails[i].fullname.trim() != "")
      {
        this.suppDsgnDetails[i].displayAddress=this.suppDsgnDetails[i].fullname
      }*/
      if(this.suppDsgnDetails[i].address1 != null && this.suppDsgnDetails[i].address1.trim() != '')
      {
        this.suppDsgnDetails[i].displayAddress+=this.suppDsgnDetails[i].address1
      }
      if(this.suppDsgnDetails[i].address2 != null && this.suppDsgnDetails[i].address2.trim() != '')
      {
        this.suppDsgnDetails[i].displayAddress+=','+this.suppDsgnDetails[i].address2
      }
      if(this.suppDsgnDetails[i].city != null && this.suppDsgnDetails[i].city.trim() != '')
      {
        this.suppDsgnDetails[i].displayAddress+=','+this.suppDsgnDetails[i].city
      }
      if(this.suppDsgnDetails[i].stateprovince != null && this.suppDsgnDetails[i].stateprovince.trim() != '')
      {
        this.suppDsgnDetails[i].displayAddress+=','+this.suppDsgnDetails[i].stateprovince
      }
      if(this.suppDsgnDetails[i].countrycode != null && this.suppDsgnDetails[i].countrycode.trim() != '')
      {
        this.suppDsgnDetails[i].displayAddress+=','+this.suppDsgnDetails[i].countrycode
      }
      if(this.suppDsgnDetails[i].postalcode != null && this.suppDsgnDetails[i].postalcode.trim() != '')
      {
        this.suppDsgnDetails[i].displayAddress+=','+this.suppDsgnDetails[i].postalcode
      }

      this.suppDsgnDetails[i].displayName=''
      if(this.suppDsgnDetails[i].contactnamefirst != null && this.suppDsgnDetails[i].contactnamefirst.trim() != '')
      {
        this.suppDsgnDetails[i].displayName+=','+this.suppDsgnDetails[i].contactnamefirst
      }
      if(this.suppDsgnDetails[i].contactname != null && this.suppDsgnDetails[i].contactname.trim() != '')
      {
        this.suppDsgnDetails[i].displayName+=','+this.suppDsgnDetails[i].contactname
      }

      this.suppDsgnDetails[i].displayContact='';

      if(this.suppDsgnDetails[i].contactfunctn != null && this.suppDsgnDetails[i].contactfunctn.trim() != '')
      {
        this.suppDsgnDetails[i].displayContact+=','+this.suppDsgnDetails[i].countrycode
      }
      if(this.suppDsgnDetails[i].contactfax != null && this.suppDsgnDetails[i].contactfax.trim() != '')
      {
        this.suppDsgnDetails[i].displayContact+=','+this.suppDsgnDetails[i].contactfax
      }

      }
      }
      else
      {
        this.colsSuppDesgn=[];
      }
    })
  }

  approveRequest(request_details:pendingAccessGridDetlsUI)
  {
    // API Integration for save - TBD
    // API Integration for deleting request in user request table

    this.disableButtonUntilAPIReturnsReponse=true;

    let selected_programs='';
    let selected_cos='';

    // alert(this.plantsListFromDB.length+'---'+this.plantSelectedList.length)
    // alert(this.programListFromDb.length+'----'+this.prgmSelectedList.length)
    // alert(this.cosListFromDb.length+'-----'+this.cosSelectedList.length)

    if(this.plant_apply_limit.toLowerCase()=='no')
    {
      this.selected_plants='all';
      this.selected_plants_name='all';
    }
    else
    {
      if(this.plantSelectedList.length==this.plantsListFromDB.length)
      {
        this.selected_plants='all';
        this.selected_plants_name='all';
      }
    }

    if(this.program_apply_limit.toLowerCase()=='no')
    {
      selected_programs='all';
    }
    else
    {
      if(this.programListFromDb.length==this.prgmSelectedList.length)
      {
        selected_programs='all';
      }
      else
      {
        for(let k=0;k<this.prgmSelectedList.length;k++)
        {
          selected_programs+=this.prgmSelectedList[k].programname;

          if(k!=this.prgmSelectedList.length-1)
          {
            selected_programs+=',';
          }
        }
      }

    }

    if(this.cos_apply_limit.toLowerCase()=='no')
    {
      selected_cos='all';
    }
    else
    {
      if(this.cosListFromDb.length==this.cosSelectedList.length)
      {
        selected_cos='all';
      }
      else
      {
        for(let k=0;k<this.cosSelectedList.length;k++)
        {
          selected_cos+=this.cosSelectedList[k].ownrshp_strat_code.trim();

          if(k!=this.cosSelectedList.length-1)
          {
            selected_cos+=',';
          }
        }
      }

    }


    let suppDesgCodes:string;

    console.log(request_details.reqstd_access_role_name);
    console.log(request_details.req_supgsdbcodes)



    if(request_details.reqstd_access_role_name.toLowerCase()=='design source')
     {
       suppDesgCodes=request_details.req_dsgnsrcids;

     }

     else
     {
       suppDesgCodes=request_details.req_supgsdbcodes;

     }


    // alert(this.selected_plants);
    // alert(selected_programs);
    // alert(selected_cos);

    let isFordUser:string;

    const rl=this.roleListforMapping.find(e=>e.rolefullname.toLowerCase()==request_details.reqstd_access_role_name.toLowerCase())
    const roleIdForTheRequest=rl.role_id;





    if(request_details.user_type.toLowerCase()=='ford')
    {
      isFordUser='Yes';
    }
    else
    {
      isFordUser='No';
    }

    this.requestThatIsApproved=[];

    this.requestThatIsApproved.push({
      gppuserid:request_details.gppuserid,
            title:request_details.name_prefix,
            name:request_details.user_name,
            company:request_details.cmpny_name,
            designation:request_details.desig,
            email:request_details.email,
            phone:request_details.phone,
            address1:request_details.address1,
            address2:request_details.address2,
            city:request_details.city,
            state:request_details.state,
            postalcode:request_details.postcode,
            cntrycodeiso:this.selected_countrylist.cd_Country,
            "lastlogin":this.date.transform(new Date(),"yyyy-MM-dd HH:mm:ss"),
            isforduser:isFordUser,
            isactive:'Yes',
            createddt:this.date.transform(new Date(),'yyyy-MM-dd HH:mm:ss'),
            createdby:this.transactionService.getcdsid(),
            fax:request_details.fax,
            country:this.selected_countrylist.country_name,
            usertype:request_details.req_type,
            role_id:roleIdForTheRequest,
            cd_region:request_details.region,
            supGSDBLst:suppDesgCodes,
            pltGSDBLst:this.selected_plants,
            cd_program:101,
            coslist:selected_cos,
            pltNameLst:this.selected_plants_name,
            progList:selected_programs
    });

    this.approvedRequestDetailsForEmail=[];

    var comments_app = "Your access request has been approved.";
    if(this.rejectionComments != null && this.rejectionComments.trim() != "") 
    {
      comments_app+="<br> <br> <u> Comments: </u> <br>"+this.rejectionComments;
    }
    this.approvedRequestDetailsForEmail.push({
       region:request_details.region,
       roleRequested:request_details.reqstd_access_role_name,
       supGSDBcodes:request_details.req_supgsdbcodes,
       designSourceIDs:request_details.req_dsgnsrcids,
       plantCodes:this.selected_plants,
       programs:selected_programs,
       cos:selected_cos,
       comments:comments_app
    })

    const func_id_approve = 2 // for request approved the fuction id is 2

    this.userDetailsEmailPost.userRequestAccessList=[];
    this.userDetailsEmailPost={
      gppuserid:request_details.gppuserid,
      function: func_id_approve,
      userRequestAccessList:this.approvedRequestDetailsForEmail
    }
    

    console.log("requestThatIsApproved",this.requestThatIsApproved)
    console.log("approvedRequestDetailsForEmail", this.approvedRequestDetailsForEmail);
    console.log("approved request details for email",this.userDetailsEmailPost);

    this.nameValidation();
    this.companyNameValidation();
    this.roleValidation();
    this.phoneValidation();
    this.faxValidation();
    this.emailValidation();
    this.addressValidation();
    this.address2Validation();
    this.cityValidation();
    this.stateValidation();
    this.postalCodeValidation();
    this.countryValidation();

    this.plantNotSelectedValidation();
    this.programNotSelectedValidation();
    this.cosNotSelectedValidation();

    
    if(!this.name_InValid && !this.companyName_InValid && !this.role_InValid && !this.phone_InValid && !this.email_InValid &&
      !this.address_InValid && !this.city_InValid && !this.state_InValid && !this.postalCode_InValid && !this.country_InValid && 
      !this.plantNotSelectedWhenNo && !this.programNotSelectedWhenNo && !this.cosNotSelectedWhenNo)
      {
   this.transactionService.approveUserRequestedAccess(this.requestThatIsApproved).subscribe(approveStatus=>{
      if(approveStatus.toLowerCase()=='success')
      {
        this.messageService.add({severity:'success',summary:'Success',detail:'Request Approved Successfully'})

        this.transactionService.deleteUserAccessRequest(request_details.reqno).subscribe(deleteApprovedRequest=>{
          console.log('Success',deleteApprovedRequest);
          if(deleteApprovedRequest.toLowerCase()=='success')
          {
            console.log("Email for approved request status - userRequestEmailTrigger before api call ",this.userDetailsEmailPost);
           
            this.emailTriggerForAppOrRej();

            //this.messageService.add({severity:"success",summary:"Success",detail:"Approved Request Deleted Succesfully"});
            this.closeRequestDetailsPopUp();
            this.populateGrid();
          }
          else
          {
            this.messageService.add({severity:'error',summary:'Error',detail:'Approved Request not deleted, Try Again !!'})
            this.disableButtonUntilAPIReturnsReponse=false;
          }
        });
      }
      else
      {
        this.disableButtonUntilAPIReturnsReponse=false;
        this.messageService.add({severity:'error',summary:'Error',detail:'Request not Approved, Try Again !!'})

      }
    })
  }
    
  }


 
  rejectRequestCommentsValidate(request_details:pendingAccessGridDetlsUI)
  {
    
  var alphabet_pres_reject_denied:boolean;
  alphabet_pres_reject_denied=/[a-z]/i.test(this.rejectionComments);
  
  
  console.log(alphabet_pres_reject_denied)
  
    if(this.rejectionComments==null|| alphabet_pres_reject_denied==false)
  {
    //toast stating enter comments 

    this.enable_dialog_notification=true;
    this.messageService.add({severity:'error', summary: 'Error', detail: 'Please enter comments to reject'});

  }

  else
  {
    this.rejectRequest(request_details);
  }
  }

  rejectRequest(request_details:pendingAccessGridDetlsUI)
  {

    var selected_programs=""
    if(this.program_apply_limit.toLowerCase()=="no")
    {
      selected_programs="all";
    }
    else
    {
      if(this.programListFromDb.length==this.prgmSelectedList.length)
      {
        selected_programs="all";
      }
      else
      {
        for(var k=0;k<this.prgmSelectedList.length;k++)
        {
          selected_programs+=this.prgmSelectedList[k].programname;
  
          if(k!=this.prgmSelectedList.length-1)
          {
            selected_programs+=",";
          }
        }
      }
      
    }

    var selected_cos=""
    if(this.cos_apply_limit.toLowerCase()=="no")
    {
      selected_cos="all";
    }
    else
    {
      if(this.cosListFromDb.length==this.cosSelectedList.length)
      {
        selected_cos="all";
      }
      else
      {
        for(var k=0;k<this.cosSelectedList.length;k++)
        {
          selected_cos+=this.cosSelectedList[k].ownrshp_strat_code.trim();
  
          if(k!=this.cosSelectedList.length-1)
          {
            selected_cos+=",";
          }
        }
      }
      
    }

    var comments_rej ="Your access request has been Rejected. <br> <br> <u> Rejection Comments: </u> <br>"
    comments_rej+=this.rejectionComments;

    this.rejectedRequestDetailsForEmail=[];
    this.rejectedRequestDetailsForEmail.push({
       region:request_details.region,
       roleRequested:request_details.reqstd_access_role_name,
       supGSDBcodes:request_details.req_supgsdbcodes,
       designSourceIDs:request_details.req_dsgnsrcids,
       plantCodes:this.selected_plants,
       programs:selected_programs,
       cos:selected_cos,
       comments:comments_rej
    })

    const func_id_rej = 3 // for request rejected the fuction id is 3

    this.userDetailsEmailPost.userRequestAccessList=[];
    this.userDetailsEmailPost={
      gppuserid:request_details.gppuserid,
      function: func_id_rej,
      userRequestAccessList:this.rejectedRequestDetailsForEmail
    }

    this.disableButtonUntilAPIReturnsReponse=true;
    this.transactionService.deleteUserAccessRequest(request_details.reqno).subscribe(deletionStatus=>{
      console.log('Success',deletionStatus);
      if(deletionStatus.toLowerCase()=='success')
      {
        this.messageService.add({severity:'success',summary:'Success',detail:'Request Rejected Successfully'})
         // API Integration for sending Rejection Email - TBD
         this.emailTriggerForAppOrRej();
      }
      else
      {
        this.disableButtonUntilAPIReturnsReponse=false;
        this.messageService.add({severity:'error',summary:'Error',detail:'Request not rejected, Try Again !!'})

      }
    })
    this.closeRequestDetailsPopUp();
    this.populateGrid();
  }

  emailTriggerForAppOrRej()
  {
    console.log("emailTriggerForAppOrRej before email API call", this.userDetailsEmailPost)
  this.transactionService.userRequestEmailTrigger(this.userDetailsEmailPost).subscribe(userEmailReqStat=>{
              
    console.log("Email - userRequestEmailTrigger API o/p ",userEmailReqStat);
    if(userEmailReqStat.toLowerCase() == "success")
    {
      this.messageService.add({severity:'success', summary: 'success', detail: 'Email sent to the user successfully'});
    }
    else
    {
      this.messageService.add({severity:'error', summary: 'Error', detail: 'Email not sent to the user'});
    }
  })
}

ok_notification_dialog()
{
  this.enable_dialog_notification=false;
}


  closeRequestDetailsPopUp()
  {

    this.enableRequestDetailsPopUp=false;
    this.disableButtonUntilAPIReturnsReponse=false;
    this.populateGrid();
    this.requestDetailsInit();

    this.loadPlantsOfThisRegion();
    this.plantSelectedList=[];
    this.selected_plants='';
    this.selected_plants_name='';

    this.cosSelectedList=[];
    this.loadCOSOfThisRegion();

    this.prgmActualList=[];
    this.programListFromDb=[];
    this.prgmSelectedList=[];

    this.userInfoInitValidation();

    this.rejReqDetailsInit();
    this.rejectionComments="";
    this.enableCommentsPopUp=false;
    this.enable_dialog_notification=false;
  }

  plantApplyLimitChanged()
  {
    if(this.plant_apply_limit.toLowerCase()=='yes')
    {
      this.enable_plant_dropdown=true;
    }
    else
    {
      this.enable_plant_dropdown=false;
      this.program_apply_limit='No';
      this.enable_program_drop_down=false;
      this.plantNotSelectedValidation();
    }
  }
  programApplyLimitChanged()
  {
    if(this.program_apply_limit.toLowerCase()=='yes')
    {
      this.enable_program_drop_down=true;
    }
    else
    {
      this.enable_program_drop_down=false;
      this.programNotSelectedValidation();
    }
  }
  cosApplyLimitChanged()
  {
    if(this.cos_apply_limit.toLowerCase()=='yes')
    {
      this.enable_cos_drop_down=true;
    }
    else
    {
     this.enable_cos_drop_down=false;
     this.cosNotSelectedValidation();
    }
  }

  onPaste(event):void
  {
    event.preventDefault();

    const text = event.clipboardData?.getData('text/plain') || '';
    if(/^[A-Za-z .-]+$/.test(text))
    {
      this.pendingAccessRequestDetails.user_name=text;
    }
    else
    {
      console.log(text);
      this.pendingAccessRequestDetails.user_name=text.replace(/[^a-zA-Z.-\s]/g,'');
      console.log(this.pendingAccessRequestDetails.user_name);
    }
    
  }


  request_details_missing()
  {
    if(!this.pendingAccessRequestDetails.name_prefix ||!this.pendingAccessRequestDetails.phone ||
    !this.pendingAccessRequestDetails.user_name|| ! this.pendingAccessRequestDetails.cmpny_name ||
    !this.pendingAccessRequestDetails.desig || !this.pendingAccessRequestDetails.email||
    !this.pendingAccessRequestDetails.address1|| !this.pendingAccessRequestDetails.city||
    !this.pendingAccessRequestDetails.state|| !this.pendingAccessRequestDetails.postcode||
    !this.pendingAccessRequestDetails.country)
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  disableApproving()
  {
    if(this.plant_apply_limit==null || (this.plant_apply_limit.toLowerCase()=="yes" && this.plantSelectedList.length==0) ||
      this.program_apply_limit==null || (this.program_apply_limit.toLowerCase()=="yes" && this.prgmSelectedList.length==0) ||
      this.cos_apply_limit==null || (this.cos_apply_limit.toLowerCase()=="yes" && this.cosSelectedList.length==0) )
    {
        return true;
    }
    else
    {
      return false;
    }
  }

  nameValidation()
  {
   if(this.pendingAccessRequestDetails.user_name == null || this.pendingAccessRequestDetails.user_name.trim() == "")
   {
    this.name_InValid = true;
    this.name_InValidReason = "Required";
   }
   else if(String(this.pendingAccessRequestDetails.user_name).length <= 0 || String(this.pendingAccessRequestDetails.user_name).length > 49)
   {
    this.name_InValid = true;
    this.name_InValidReason = "Name can not exceed 49 characters";
   }
   else
   {
    this.name_InValid = false;
    this.name_InValidReason = null;
   }
  }
  companyNameValidation()
  {
   if(this.pendingAccessRequestDetails.cmpny_name == null || this.pendingAccessRequestDetails.cmpny_name.trim() == "")
   {
    this.companyName_InValid = true;
    this.companyName_InValidReason = "Required";
   }
   else if(String(this.pendingAccessRequestDetails.user_name).length <= 0 || String(this.pendingAccessRequestDetails.user_name).length > 49)
   {
    this.companyName_InValid = true;
    this.companyName_InValidReason = "Company Name can not exceed 49 characters";
   }
   else
   {
    this.companyName_InValid = false;
    this.companyName_InValidReason = null;
   }
  }
  roleValidation()
  { 
   if(this.pendingAccessRequestDetails.desig == null || this.pendingAccessRequestDetails.desig.trim() == "")
   {
    this.role_InValid = true;
    this.role_InValidReason = "Required";
   }
   else if(String(this.pendingAccessRequestDetails.desig).length <= 0 || String(this.pendingAccessRequestDetails.desig).length > 24)
   {
    this.role_InValid = true;
    this.role_InValidReason = "Role can not exceed 24 characters";
   }
   else
   {
    this.role_InValid = false;
    this.role_InValidReason = null;
   }
  }
  phoneValidation()
  {
   if(this.pendingAccessRequestDetails.phone == null || this.pendingAccessRequestDetails.phone.trim() == "")
   {
    this.phone_InValid = true;
    this.phone_InValidReason = "Required";
    console.log("Phone validation")
   }
   else if(String(this.pendingAccessRequestDetails.phone).length <= 0 || String(this.pendingAccessRequestDetails.phone).length > 99)
   {
    this.phone_InValid = true;
    this.phone_InValidReason = "Phone Number can not exceed 99 characters";
   }
   else
   {
    this.phone_InValid = false;
    this.phone_InValidReason = null;
   }
  }
  faxValidation()
  {
   if(String(this.pendingAccessRequestDetails.fax).length > 19)
   {
    this.fax_InValid = true;
    this.fax_InValidReason = "Fax can not exceed 19 characters";
   }
   else
   {
    this.fax_InValid = false;
    this.fax_InValidReason = null;
   }
  }
  emailValidation()
  {
   if(this.pendingAccessRequestDetails.email == null || this.pendingAccessRequestDetails.email.trim() == "")
   {
    this.email_InValid = true;
    this.email_InValidReason = "Required";
   }
   else if(String(this.pendingAccessRequestDetails.email).length <= 0 || String(this.pendingAccessRequestDetails.email).length > 49)
   {
    this.email_InValid = true;
    this.email_InValidReason = "Email ID can not exceed 49 characters";
   }
   else
   {
    this.email_InValid = false;
    this.email_InValidReason = null;
   }
  }
  addressValidation()
  {
   if(this.pendingAccessRequestDetails.address1 == null || this.pendingAccessRequestDetails.address1.trim() == "")
   {
    this.address_InValid = true;
    this.address_InValidReason = "Required";
   }
   else if(String(this.pendingAccessRequestDetails.address1).length <= 0 || String(this.pendingAccessRequestDetails.address1).length > 99)
   {
    this.address_InValid = true;
    this.address_InValidReason = "Address can not exceed 99 characters";
   }
   else
   {
    this.address_InValid = false;
    this.address_InValidReason = null;
   }
  }
  address2Validation()
  {
   if(String(this.pendingAccessRequestDetails.address2).length > 99)
   {
    this.address2_InValid = true;
    this.address2_InValidReason = "Address can not exceed 99 characters";
   }
   else
   {
    this.address2_InValid = false;
    this.address2_InValidReason = null;
   }
  }
  cityValidation()
  {
   if(this.pendingAccessRequestDetails.city == null || this.pendingAccessRequestDetails.city.trim() == "")
   {
    this.city_InValid = true;
    this.city_InValidReason = "Required";
   }
   else if(String(this.pendingAccessRequestDetails.city).length <= 0 || String(this.pendingAccessRequestDetails.city).length > 29)
   {
    this.city_InValid = true;
    this.city_InValidReason = "City can not exceed 29 characters";
   }
   else
   {
    this.city_InValid = false;
    this.city_InValidReason = null;
   }
  }
  stateValidation()
  {
   if(this.pendingAccessRequestDetails.state == null || this.pendingAccessRequestDetails.state.trim() == "")
   {
    this.state_InValid = true;
    this.state_InValidReason = "Required";
   }
   else if(String(this.pendingAccessRequestDetails.state).length <= 0 || String(this.pendingAccessRequestDetails.state).length > 29)
   {
    this.state_InValid = true;
    this.state_InValidReason = "State can not exceed 29 characters";
   }
   else
   {
    this.state_InValid = false;
    this.state_InValidReason = null;
   }
  }
  postalCodeValidation()
  {
   if(this.pendingAccessRequestDetails.postcode == null || this.pendingAccessRequestDetails.postcode.trim() == "")
   {
    this.postalCode_InValid = true;
    this.postalCode_InValidReason = "Required";
   }
   else if(String(this.pendingAccessRequestDetails.postcode).length <= 0 || String(this.pendingAccessRequestDetails.postcode).length > 24)
   {
    this.postalCode_InValid = true;
    this.postalCode_InValidReason = "Postal Code can not exceed 24 characters";
   }
   else
   {
    this.postalCode_InValid = false;
    this.postalCode_InValidReason = null;
   }
  }
  countryValidation()
  {
   if(this.selected_countrylist == null || this.selected_countrylist.country_name == null || this.selected_countrylist.country_name.trim() == "")
   {
    this.country_InValid = true;
    this.country_InValidReason = "Required";
   }
   else
   {
    this.country_InValid = false;
    this.country_InValidReason = null;
   }
  }

  plantNotSelectedValidation()
  {
    if(this.plant_apply_limit == null)
    {
      this.plantNotSelectedWhenNo = true;
      this.plantNotSelectedWhenNoReason = "Required"
    }
    else if(this.plant_apply_limit.toLowerCase() == "yes" && this.plantSelectedList.length == 0)
    {
      this.plantNotSelectedWhenNo = true;
      this.plantNotSelectedWhenNoReason = "Required atleast one plant as 'Select all' is No "
    }
    else
    {
      this.plantNotSelectedWhenNo = false;
      this.plantNotSelectedWhenNoReason = null;
    }
  }

  programNotSelectedValidation()
  {
    if(this.program_apply_limit == null)
    {
      this.programNotSelectedWhenNo = true;
      this.programNotSelectedWhenNoReason = "Required"
    }
    else if(this.program_apply_limit.toLowerCase() == "yes" && this.prgmSelectedList.length == 0)
    {
      this.programNotSelectedWhenNo = true;
      this.programNotSelectedWhenNoReason = "Required atleast one program as 'Select all' is No "
    }
    else
    {
      this.programNotSelectedWhenNo = false;
      this.programNotSelectedWhenNoReason = null;
    }
  }

  cosNotSelectedValidation()
  {
    if(this.cos_apply_limit == null)
    {
      this.cosNotSelectedWhenNo = true;
      this.cosNotSelectedWhenNoReason = "Required"
    }
    else if(this.cos_apply_limit.toLowerCase() == "yes" && this.cosSelectedList.length == 0)
    {
      this.cosNotSelectedWhenNo = true;
      this.cosNotSelectedWhenNoReason = "Required atleast one cos as 'Select all' is No "
    }
    else
    {
      this.cosNotSelectedWhenNo = false;
      this.cosNotSelectedWhenNoReason = null;
    }
  }

}
