/* tslint:disable */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TransactionService } from '../loginpage/Transaction.service';

@Component({
  selector: 'app-packagingprocesshome',
  templateUrl: './packagingprocesshome.component.html',
  styleUrls: ['./packagingprocesshome.component.css']
})
export class PackagingprocesshomeComponent implements OnInit {

  region:string;
  region_roles:any;
  region_dsdb:string;
  enableSupplierRedirection=false;
  enableApproverRedirection=false;

  constructor(private route:Router,private transactionService:TransactionService) { }

  ngOnInit()
  {
    this.region=this.transactionService.getregion();
     this.region_dsdb=this.transactionService.getregioncode(this.region);
    this.region_roles=this.transactionService.getroles(this.region_dsdb);

    let supp_pres=false;
    let app_pres=false;

    if(this.region_roles != null)
    {
      console.log('region role list', this.region_roles);
     // var index_supplier=this.region_roles.findIndex(elesupp=>elesupp.roleid == 1)


      if(this.region_roles != null && this.region_roles.length != 0)
      {
        for(let i=0; i< this.region_roles.length; i++)
        {
          if(supp_pres == false && this.region_roles[i].roleid == 1)
          {
            supp_pres= true;
          }

          else if(app_pres == false &&
             (this.region_roles[i].roleid == 3 || this.region_roles[i].roleid == 4 || this.region_roles[i].roleid == 5 || this.region_roles[i].roleid == 6 || this.region_roles[i].roleid == 9|| this.region_roles[i].roleid == 11 || this.region_roles[i].roleid == 19 || this.region_roles[i].roleid == 20 || this.region_roles[i].roleid == 12))
          {
            app_pres= true;
          }

          if(app_pres == true && supp_pres == true)
          {
            break;
          }

        }
      }




      this.enableSupplierRedirection=false;
      this.enableApproverRedirection=false;

      if(supp_pres == true)
      {
        this.enableSupplierRedirection=true;
      }

      if(app_pres == true)
      {
        this.enableApproverRedirection=true;
      }
    }
    this.transactionService.storepage(this.region,'packagingprocessdashboard/packagingprocesshome')

  }

  redirect_search_existing_prop()
  {
    let nav_to=this.transactionService.getregionurlvalue(this.region);
    nav_to+='/packagingprocessdashboard/searchexistingproposal';
    this.route.navigate([nav_to]);
  }

  redirect_search_part()
  {
    let nav_to=this.transactionService.getregionurlvalue(this.region);
    nav_to+='/packagingprocessdashboard/searchpart';
    this.route.navigate([nav_to]);
  }


  redirect_supplier_dashboard()
  {
    let nav_to=this.transactionService.getregionurlvalue(this.region);
    nav_to+='/packagingprocess/packsupplieradmin/packsupplier';
    this.route.navigate([nav_to]);
  }


  redirect_approver_dashboard()
  {
    let nav_to=this.transactionService.getregionurlvalue(this.region);
    nav_to+='/packagingprocess/packapprover';
    this.route.navigate([nav_to]);
  }

}
