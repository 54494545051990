/* tslint:disable */
import {NgModule} from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
/*import { SampleDemoComponent } from './demo/view/sampledemo.component';
import { FormsDemoComponent } from './demo/view/formsdemo.component';
import { DataDemoComponent } from './demo/view/datademo.component';
import { PanelsDemoComponent } from './demo/view/panelsdemo.component';
import { OverlaysDemoComponent } from './demo/view/overlaysdemo.component';
import { MenusDemoComponent } from './demo/view/menusdemo.component';
import { MessagesDemoComponent } from './demo/view/messagesdemo.component';
import { ChartsDemoComponent } from './demo/view/chartsdemo.component';
import { FileDemoComponent } from './demo/view/filedemo.component';
import { MiscDemoComponent } from './demo/view/miscdemo.component';
import { DocumentationComponent } from './demo/view/documentation.component';*/


import {ExamplePageComponent} from './example-page/example-page.component';
import {NotFoundComponent} from './not-found/not-found.component';

import { LoginpageComponent } from './loginpage/loginpage.component';

import { HomeComponent } from './home/home.component';
import { OAuthCallbackComponent} from './oauth/oauth-callback.component';
import {UrlConsumerService} from './oauth/url-consumer.service';
import {LoginComponent} from './oauth/login.component';



import { AuthGuard } from './oauth/auth.guard';




import { ExitpageComponent } from './exitpage/exitpage.component';
import { DashboardpageComponent } from './dashboardpage/dashboardpage.component';


import { NotaccesspageComponent } from './notaccesspage/notaccesspage.component';
import { ExamplePageResolve } from './example-page/example-page.resolve';



import { NorthamericaComponent } from './northamerica/northamerica.component';
import { SouthamericaComponent } from './southamerica/southamerica.component';


import { EuropeComponent } from './europe/europe.component';
import { AsiapacificComponent } from './asiapacific/asiapacific.component';


import { PackplantadminComponent } from './packplantadmin/packplantadmin.component';


import { PackadminComponent } from './packadmin/packadmin.component';



import { PrgadminComponent } from './prgadmin/prgadmin.component';




import { CosadminComponent } from './cosadmin/cosadmin.component';
import {ContadminComponent} from './contadmin/contadmin.component';


import { TestpageComponent } from './testpage/testpage.component';


import { RegionadminComponent } from './regionadmin/regionadmin.component';


import { SubmiterdashboardComponent } from './submiterdashboard/submiterdashboard.component';
import { PacksupplierComponent } from './packsupplier/packsupplier.component';
import { PackagingprocessComponent } from './packagingprocess/packagingprocess.component';
import { PackagingprocessdashboardComponent } from './packagingprocessdashboard/packagingprocessdashboard.component';

import {PackproposaltabtwoComponent} from './packproposaltabtwo/packproposaltabtwo.component';
import {PartsandatributesComponent} from './partsandatributes/partsandatributes.component';
import {PackproposaltabthreeComponent} from './packproposaltabthree/packproposaltabthree.component';
import {PackproposaltabfourComponent} from './packproposaltabfour/packproposaltabfour.component';
import {PackagingproposalComponent} from './packagingproposal/packagingproposal.component';

import { PacksupplieradminComponent } from './packsupplieradmin/packsupplieradmin.component';
import { PackdesignsourceadminComponent } from './packdesignsourceadmin/packdesignsourceadmin.component';
import { PackdesignsourceComponent } from './packdesignsource/packdesignsource.component';
import { FormtemplateComponent } from './formtemplate/formtemplate.component';
import { FormnewComponent } from './formnew/formnew.component';



import { ComponentadminComponent } from './componentadmin/componentadmin.component';


import { ApprovaladminComponent } from './approvaladmin/approvaladmin.component';
import { PreliminaryappComponent } from './preliminaryapp/preliminaryapp.component';
import { AdditionalappComponent } from './additionalapp/additionalapp.component';
import { PlantappComponent } from './plantapp/plantapp.component';
import { PlantfinalappComponent } from './plantfinalapp/plantfinalapp.component';
import { ReleasefrevalappComponent } from './releasefrevalapp/releasefrevalapp.component';
import { ReleasefrprodappComponent } from './releasefrprodapp/releasefrprodapp.component';


import { SearchexistingproposalComponent } from './searchexistingproposal/searchexistingproposal.component';
import { PackagingprocesshomeComponent } from './packagingprocesshome/packagingprocesshome.component';


import { PackapproverComponent } from './packapprover/packapprover.component';
import { EepartsandatributesComponent } from './eepartsandatributes/eepartsandatributes.component';
import { EepackproposaltabtwoComponent } from './eepackproposaltabtwo/eepackproposaltabtwo.component';
import { EepackproposaltabthreeComponent } from './eepackproposaltabthree/eepackproposaltabthree.component';
import { EepackproposaltabfourComponent } from './eepackproposaltabfour/eepackproposaltabfour.component';


import { RequestuseraccessComponent } from './requestuseraccess/requestuseraccess.component';


import { UseradminComponent } from './useradmin/useradmin.component';
import { PendingaccessrequestComponent } from './pendingaccessrequest/pendingaccessrequest.component';


import { EditaccessComponent } from './editaccess/editaccess.component';


import { EdituserprofileComponent } from './edituserprofile/edituserprofile.component';


import { AdditionalrequestaccessComponent } from './additionalrequestaccess/additionalrequestaccess.component';




import{AuthGuardService}from './oauth/auth-guard.service';


import { EditsupplierprofileComponent } from './editsupplierprofile/editsupplierprofile.component';
import { EqoconfigComponent } from './eqoconfig/eqoconfig.component';
import { MaterialmanagementComponent } from './materialmanagement/materialmanagement.component';
import { OtherconfigComponent } from './otherconfig/otherconfig.component';
import { PlantappoffsiteComponent } from './plantappoffsite/plantappoffsite.component';
import { PlantfinalappoffsiteComponent } from './plantfinalappoffsite/plantfinalappoffsite.component';
import { UnsavedchangesGuard } from './oauth/unsavedchanges.guard';


import { ImprovementtaboneComponent } from './improvementtabone/improvementtabone.component';
import { ImprovementtabtwoComponent } from './improvementtabtwo/improvementtabtwo.component';
import { ImprovementtabthreeComponent } from './improvementtabthree/improvementtabthree.component';
import { ImprovementtabfourComponent } from './improvementtabfour/improvementtabfour.component';


import { ImprovementeetaboneComponent } from './improvementeetabone/improvementeetabone.component';
import { ImprovementeetabtwoComponent } from './improvementeetabtwo/improvementeetabtwo.component';
import { ImprovementeetabthreeComponent } from './improvementeetabthree/improvementeetabthree.component';
import { ImprovementeetabfourComponent } from './improvementeetabfour/improvementeetabfour.component';
import { ImprovementpackagingproposalComponent } from './improvementpackagingproposal/improvementpackagingproposal.component';


import { MyuseraccessComponent } from './myuseraccess/myuseraccess.component';
import { DbiareqmgntComponent } from './dbiareqmgnt/dbiareqmgnt.component';
import { DbiareqstsComponent } from './dbiareqsts/dbiareqsts.component';


import { DbiaadminComponent } from './dbiaadmin/dbiaadmin.component';
import { ContainerrequestComponent } from './containerrequest/containerrequest.component';

import { LoginotheruserComponent } from './loginotheruser/loginotheruser.component';
import { ContainerreqstsComponent } from './containerreqsts/containerreqsts.component';

import { MigrationsubmitterdashboardComponent } from './migrationsubmitterdashboard/migrationsubmitterdashboard.component';
import { MigrationapprovalComponent } from './migrationapproval/migrationapproval.component';

import { MypendingaccessComponent } from './mypendingaccess/mypendingaccess.component';
import { HelpcomponentComponent } from './helpcomponent/helpcomponent.component';
import { PackaginglinkscomponentComponent } from './packaginglinkscomponent/packaginglinkscomponent.component';
import { InactiveuserpageComponent } from './inactiveuserpage/inactiveuserpage.component';
import { SearchPartsComponent } from './search-parts/search-parts.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportsadminComponent } from './reportsadmin/reportsadmin.component';
import { PfepdataexportreportComponent } from './pfepdataexportreport/pfepdataexportreport.component';
import { PackagingsummaryreportComponent } from './packagingsummaryreport/packagingsummaryreport.component';
import { AlladditionalapprovalComponent } from './alladditionalapproval/alladditionalapproval.component';
import { Form1271datareportComponent } from './form1271datareport/form1271datareport.component';
import { GpparchivesearchpartComponent } from './gpparchivesearchpart/gpparchivesearchpart.component';
import { LomreportComponent } from './lomreport/lomreport.component';
import { GpparchivereportsadminComponent } from './gpparchivereportsadmin/gpparchivereportsadmin.component';
import { GpparchivereportsComponent } from './gpparchivereports/gpparchivereports.component';
import { GpparchivepackagingsummaryreportComponent } from './gpparchivepackagingsummaryreport/gpparchivepackagingsummaryreport.component';
import { GpparchivelomreportComponent } from './gpparchivelomreport/gpparchivelomreport.component';
import { GpparchivepfepdataexportreportComponent } from './gpparchivepfepdataexportreport/gpparchivepfepdataexportreport.component';
import { Gpparchiveform1271reportComponent } from './gpparchiveform1271report/gpparchiveform1271report.component';
import { SuppliercontactreportComponent } from './suppliercontactreport/suppliercontactreport.component';
import { TraininglinksComponent } from './traininglinks/traininglinks.component';
import { ProgramlevelemailComponent } from './programlevelemail/programlevelemail.component';
import { GpparchivesuppliercontactreportComponent } from './gpparchivesuppliercontactreport/gpparchivesuppliercontactreport.component';

export const routes: Routes = [
   {
		path: 'access_token',
		component: OAuthCallbackComponent,
		canActivate: [UrlConsumerService]
	},
	{
		path: 'login',
		component: LoginComponent
	},

	{
		path: '',
		redirectTo: '/home',
		pathMatch: 'full'
	},
	{
		path: 'loginpage',
		component: LoginpageComponent
	},
	{
		path: '',
		redirectTo: '/loginpage',
		pathMatch: 'full'
	},




	/*
	{
		path: 'example-page',
		component: ExamplePageComponent,
		resolve:{
			examplepage:ExamplePageResolve
		}
	},*/
	/*{
		path: '',
		redirectTo: '/example-page',
		pathMatch: 'full'
	},*/


	{
		path: 'dashboardpage',
		component: DashboardpageComponent
	},

	{
		path: '',
		redirectTo: '/dashboardpage',
		pathMatch: 'full'
	},
	{
		path: 'asiapacific',
		component: AsiapacificComponent, canActivate: [AuthGuardService,AuthGuard],children: [
			{
				path: 'example-page',
				component: ExamplePageComponent,
				resolve:{
					examplepage:ExamplePageResolve
				}
			},




			{
				path: 'packagingprocessdashboard',
				component: PackagingprocessdashboardComponent,canActivate: [AuthGuardService,AuthGuard],children:[
					{path:'searchexistingproposal',component:SearchexistingproposalComponent,
					canActivate: [AuthGuardService,AuthGuard],

				},

				{path:'searchpart',component:SearchPartsComponent,
					canActivate: [AuthGuardService,AuthGuard]
				},

				{
					path: 'improvementproposalcreation',
					component: ImprovementpackagingproposalComponent,
					canActivate: [AuthGuardService,AuthGuard],children:[
						{path:'improvementpartsandattri',component:ImprovementtaboneComponent,canActivate: [AuthGuardService,AuthGuard]},
						{path:'improvementpackproposaltabtwo',component:ImprovementtabtwoComponent,canActivate: [AuthGuardService,AuthGuard]},
						{path:'improvementpackproposaltabthree',component:ImprovementtabthreeComponent,canActivate: [AuthGuardService,AuthGuard]},
						{path:'improvementpackproposaltabfour',component:ImprovementtabfourComponent,canActivate: [AuthGuardService,AuthGuard]},
						{path:'improvementeepartsandattri',component:ImprovementeetaboneComponent,canActivate: [AuthGuardService,AuthGuard]},
						{path:'improvementeepackproposaltabtwo',component:ImprovementeetabtwoComponent,canActivate: [AuthGuardService,AuthGuard]},
						{path:'improvementeepackproposaltabthree',component:ImprovementeetabthreeComponent,canActivate: [AuthGuardService,AuthGuard]},
						{path:'improvementeepackproposaltabfour',component:ImprovementeetabfourComponent,canActivate: [AuthGuardService,AuthGuard]},




					]
				},

					{path:'packagingprocesshome',component:PackagingprocesshomeComponent,
					canActivate: [AuthGuardService,AuthGuard],

				},
				{ path: '', pathMatch: 'full', redirectTo: 'packagingprocesshome',canActivate: [AuthGuardService]}
				]
			},


			{
				path: 'packagingprocess',
				component: PackagingprocessComponent,canActivate: [AuthGuardService,AuthGuard],children: [
			{
				path: 'packadmin',
				component: PackadminComponent,
				canActivate: [AuthGuardService,AuthGuard],
					data: { roles: 'any',funcid:'47,48,49,50,51,52,53,54,55,57',userType:'F'}
					,children: [
				 {path: 'packplantadmin',component: PackplantadminComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				// canDeactivate: [UnsavedchangesGuard],
				 data: { roles: 'any',funcid:'57',userType:'F'}
				},
				 {path: 'prgadmin',component: PrgadminComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: 'any',funcid:'55',userType:'F'}
				},
				 {path: 'cosadmin',component: CosadminComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: 'any',funcid:'52',userType:'F'}
				},
				 {path: 'app-contadmin',component: ContadminComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: 'any',funcid:'53',userType:'F'}
				},
				 {path:'regionadmin',component:RegionadminComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: 'any',funcid:'47,48,49,50,51',userType:'F'}
				},
				 {path:'componentadmin',component:ComponentadminComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: 'any',funcid:'54',userType:'F'}
				}
	                ]
			  },

			  {
				path: 'eqoconfig',
				component:EqoconfigComponent,
				children: [
					{path: 'materialmanagement',component: MaterialmanagementComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: 'any',funcid:'any',userType:'F'}
				},
				 {path: 'otherconfig',component: OtherconfigComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: 'any',funcid:'any',userType:'F'}
				}
				]
			  },

			  {
				path: 'dbiaadmin',
				component:DbiaadminComponent,
				canActivate: [AuthGuardService,AuthGuard],
					data: { roles: 'any',funcid:'53',userType:'F'},
				children: [
					{path:'dbiareqmgnt',component:DbiareqmgntComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: 'any',funcid:'53',userType:'F'}
				   },
				   {path:'dbiareqstsmgnt',component:DbiareqstsComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: 'any',funcid:'53',userType:'F'}
				   },
				]
			  },


			{path: 'formtemplate',component: FormtemplateComponent

			},
			{path: 'formnew',component: FormnewComponent

			},

			{
				path: 'packsupplieradmin',
				component: PacksupplieradminComponent,canActivate: [AuthGuardService,AuthGuard],children: [
				 {path: 'packsupplier',component: PacksupplierComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: '1',funcid:'any',userType:'F,S'}
				},
				{path: 'migrationsubmitter',component: MigrationsubmitterdashboardComponent,
				canActivate: [AuthGuardService,AuthGuard],
				data: { roles: '1',funcid:'any',userType:'F,S'}
			   },
				 {path: 'submiterdashboard',component: SubmiterdashboardComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: '1',funcid:'any',userType:'F,S'}
				},
				{
				path: 'editsupplierprofile',component: EditsupplierprofileComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: '1,2',funcid:'any',userType:'F,S'}

				},
				{
					path: 'containerrequest',component: ContainerrequestComponent,
					 canActivate: [AuthGuardService,AuthGuard],
					 data: { roles: '1',funcid:'any',userType:'F,S'}

				},
				{
					path: 'containerreqsts',component: ContainerreqstsComponent,
					 canActivate: [AuthGuardService,AuthGuard],
					 data: { roles: '1',funcid:'any',userType:'F,S'}

				},
				{ path: '', pathMatch: 'full', redirectTo: 'packsupplier',canActivate: [AuthGuardService]}
	                ]
			  },


			  {
				path: 'packdesignsourceadmin',
				component: PackdesignsourceadminComponent,canActivate: [AuthGuardService,AuthGuard],children: [
				 {path: 'packdesignsource',component: PackdesignsourceComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: '2',funcid:'any',userType:'F'}
				},
				{path: 'migrationsubmitter',component: MigrationsubmitterdashboardComponent,
				canActivate: [AuthGuardService,AuthGuard],
				data: { roles: '2',funcid:'any',userType:'F'}
			   },
				 {path: 'submiterdashboard',component: SubmiterdashboardComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: '2',funcid:'any',userType:'F'}
				},
				{
					path: 'editsupplierprofile',component: EditsupplierprofileComponent,
					 canActivate: [AuthGuardService,AuthGuard],
					 data: { roles: '2',funcid:'any',userType:'F'}

					},
				/*	{
						path: 'containerrequest',component: ContainerrequestComponent,
						 canActivate: [AuthGuardService,AuthGuard],
						 data: { roles: "2",funcid:"any"}

					},*/


				{ path: '', pathMatch: 'full', redirectTo: 'packdesignsource',canActivate: [AuthGuardService]}
	                ]
			  },
			  {
				path: 'proposalcreation',
				component: PackagingproposalComponent,
				canActivate: [AuthGuardService,AuthGuard],children:[
					{path:'partsandattri',component:PartsandatributesComponent,canActivate: [AuthGuardService,AuthGuard],data: { roles: '1,2',funcid:'any',userType:'F,S'}},
					{path:'packproposaltabtwo',component:PackproposaltabtwoComponent,canActivate: [AuthGuardService,AuthGuard],data: { roles: '1,2',funcid:'any',userType:'F,S'}},
					{path:'packproposaltabthree',component:PackproposaltabthreeComponent,canActivate: [AuthGuardService,AuthGuard],data: { roles: '1,2',funcid:'any',userType:'F,S'}},
					{path:'packproposaltabfour',component:PackproposaltabfourComponent,canActivate: [AuthGuardService,AuthGuard],data: { roles: '1,2',funcid:'any',userType:'F,S'}},
					{path:'eepartsandattri',component:EepartsandatributesComponent,canActivate: [AuthGuardService,AuthGuard],data: { roles: '1,2',funcid:'any',userType:'F,S'}},
					{path:'eepackproposaltabtwo',component:EepackproposaltabtwoComponent,canActivate: [AuthGuardService,AuthGuard],data: { roles: '1,2',funcid:'any',userType:'F,S'}},
					{path:'eepackproposaltabthree',component:EepackproposaltabthreeComponent,canActivate: [AuthGuardService,AuthGuard],data: { roles: '1,2',funcid:'any',userType:'F,S'}},
					{path:'eepackproposaltabfour',component:EepackproposaltabfourComponent,canActivate: [AuthGuardService,AuthGuard],data: { roles: '1,2',funcid:'any',userType:'F,S'}},




				]
			},


			{
				path:'packapprover',
				component:PackapproverComponent,canActivate: [AuthGuardService,AuthGuard],
				data: { roles: '3,4,5,6,9,11,19,20,12',funcid:'any',userType:'F,S'},
				children:[
					{path:'preliminaryapp',component:PreliminaryappComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: '3,12',funcid:'any',userType:'F'}
				},
					{path:'additionalapp',component:AdditionalappComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: '4',funcid:'any',userType:'F,S'}
				},
					{path:'plantapp',component:PlantappComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: '5',funcid:'any',userType:'F'}
				},
					{path:'releasefrevalapp',component:ReleasefrevalappComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: '9,12',funcid:'any',userType:'F'}
				},

					{path:'plantfinalapp',component:PlantfinalappComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: '6',funcid:'any',userType:'F'}
				},
					{path:'releasefrprodapp',component:ReleasefrprodappComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: '11,12',funcid:'any',userType:'F'}
				},
				{path:'migrationapproval',component:MigrationapprovalComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: '3,12',funcid:'any',userType:'F'}
				},
				{path:'alladditionalapproval',component:AlladditionalapprovalComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: '12',funcid:'any',userType:'F'}
				},


				{path:'plantappoffsite',component:PlantappoffsiteComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: '19',funcid:'any',userType:'F,S'}
				},

				{path:'plantfinalappoffsite',component:PlantfinalappoffsiteComponent,
				canActivate: [AuthGuardService,AuthGuard],
				data: { roles: '20',funcid:'any',userType:'F,S'}
			},

					// {path:'testpage',component:TestpageComponent}
				]
			},
			{
				path:'useradmin',

				component:UseradminComponent,
				canActivate: [AuthGuardService,AuthGuard],
				data: { roles: 'any',funcid:'45,46',userType:'F'},
				children:[
					{path:'PendingAccessRequest',component:PendingaccessrequestComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: 'any',funcid:'45,46',userType:'F'}
				},
					{path:'editaccess',component:EditaccessComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: 'any',funcid:'45,46',userType:'F'}

				}
				]
			},


			{
				path:'reportsadmin',

				component:ReportsadminComponent,
				canActivate: [AuthGuardService,AuthGuard],children: [
					{path: 'reports',component: ReportsComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: 'any',funcid:'33,34,32',userType:'F,S'},
				   },
				   {path: 'pfepdataexport',component: PfepdataexportreportComponent,
				   canActivate: [AuthGuardService,AuthGuard],
				   data: { roles: 'any',funcid:'33',userType:'F,S'},
				  },
				  {path: 'packagingsummaryrep',component: PackagingsummaryreportComponent,
				   canActivate: [AuthGuardService,AuthGuard],
				   data: { roles: 'any',funcid:'34',userType:'F,S'},
				  },
				  {path: 'form1271datarep',component: Form1271datareportComponent,
				  canActivate: [AuthGuardService,AuthGuard],
				  data: { roles: 'any',funcid:'32',userType:'F,S'},
				 },
				 {path: 'lomreport',component: LomreportComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: 'any',funcid:'32',userType:'F,S'},
				},
				{path: 'suppliercontact',component: SuppliercontactreportComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: "any",funcid:"any",userType:"F"},
				},
				   { path: '', pathMatch: 'full', redirectTo: 'reports',canActivate: [AuthGuardService]}
					   ]
			},

			{
				path: 'gpparchivesearchpart',
				component: GpparchivesearchpartComponent,
				  canActivate: [AuthGuardService,AuthGuard],
				  data: { roles: 'any',funcid:'any',userType:'F'},
			},
			//Revathy
			{	
				path: 'programlevelemail',
				component: ProgramlevelemailComponent,
				  canActivate: [AuthGuardService,AuthGuard],
				  data: { roles: "any",funcid:"any",userType:"F"},
			},
			{
				path:'archivereportsadmin',

				component:GpparchivereportsadminComponent,
				canActivate: [AuthGuardService,AuthGuard],children: [
					{path: 'archivereports',component: GpparchivereportsComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: 'any',funcid:'any',userType:'F'},
				   },
				   {path: 'archivepfepdataexprep',component: GpparchivepfepdataexportreportComponent,
				  canActivate: [AuthGuardService,AuthGuard],
				  data: { roles: 'any',funcid:'any',userType:'F'},
				 },

				  {path: 'archivepackagingsummaryrep',component: GpparchivepackagingsummaryreportComponent,
				   canActivate: [AuthGuardService,AuthGuard],
				   data: { roles: 'any',funcid:'any',userType:'F'},
				  },
				  {path: 'archivelomrep',component: GpparchivelomreportComponent,
				  canActivate: [AuthGuardService,AuthGuard],
				  data: { roles: 'any',funcid:'any',userType:'F'},
				 },

				  {path: 'archiveform1271rep',component: Gpparchiveform1271reportComponent,
				   canActivate: [AuthGuardService,AuthGuard],
				   data: { roles: 'any',funcid:'any',userType:'F'},
				  },
				  {path: 'archivesuppliercontact',component: GpparchivesuppliercontactreportComponent,
				   canActivate: [AuthGuardService,AuthGuard],
				   data: { roles: "any",funcid:"any",userType:"F"},
				  },
				  
				   { path: '', pathMatch: 'full', redirectTo: 'archivereports',canActivate: [AuthGuardService,AuthGuard],
				   data: { roles: 'any',funcid:'any',userType:'F'}}
					   ]
			},



			{ path: '', pathMatch: 'full', redirectTo: '/asiapacificamerica/packagingprocessdashboard/packagingprocesshome',canActivate: [AuthGuardService]}



			  // {path:'partsandattributes',component:PartsandatributesComponent}
			]},

			{
				path:'packaginglinksdocument',
				component:PackaginglinkscomponentComponent,
				canActivate: [AuthGuardService,AuthGuard]
			},

			{ path: '', pathMatch: 'full', redirectTo: 'packagingprocessdashboard',canActivate: [AuthGuardService]}
		  ],
	},

	{
		path: '',
		redirectTo: '/asiapacific',
		pathMatch: 'full'
	},
	{
		path: 'europe',
		component: EuropeComponent, canActivate: [AuthGuardService,AuthGuard],children: [
			{
				path: 'example-page',
				component: ExamplePageComponent,
				resolve:{
					examplepage:ExamplePageResolve
				}
			},




			{
				path: 'packagingprocessdashboard',
				component: PackagingprocessdashboardComponent,canActivate: [AuthGuardService,AuthGuard],children:[
					{path:'searchexistingproposal',component:SearchexistingproposalComponent,
					canActivate: [AuthGuardService,AuthGuard],

				},

				{path:'searchpart',component:SearchPartsComponent,
					canActivate: [AuthGuardService,AuthGuard]
				},

				{
					path: 'improvementproposalcreation',
					component: ImprovementpackagingproposalComponent,
					canActivate: [AuthGuardService,AuthGuard],children:[
						{path:'improvementpartsandattri',component:ImprovementtaboneComponent,canActivate: [AuthGuardService,AuthGuard]},
						{path:'improvementpackproposaltabtwo',component:ImprovementtabtwoComponent,canActivate: [AuthGuardService,AuthGuard]},
						{path:'improvementpackproposaltabthree',component:ImprovementtabthreeComponent,canActivate: [AuthGuardService,AuthGuard]},
						{path:'improvementpackproposaltabfour',component:ImprovementtabfourComponent,canActivate: [AuthGuardService,AuthGuard]},
						{path:'improvementeepartsandattri',component:ImprovementeetaboneComponent,canActivate: [AuthGuardService,AuthGuard]},
						{path:'improvementeepackproposaltabtwo',component:ImprovementeetabtwoComponent,canActivate: [AuthGuardService,AuthGuard]},
						{path:'improvementeepackproposaltabthree',component:ImprovementeetabthreeComponent,canActivate: [AuthGuardService,AuthGuard]},
						{path:'improvementeepackproposaltabfour',component:ImprovementeetabfourComponent,canActivate: [AuthGuardService,AuthGuard]},




					]
				},

					{path:'packagingprocesshome',component:PackagingprocesshomeComponent,
					canActivate: [AuthGuardService,AuthGuard],

				},
				{ path: '', pathMatch: 'full', redirectTo: 'packagingprocesshome',canActivate: [AuthGuardService]}
				]
			},


			{
				path: 'packagingprocess',
				component: PackagingprocessComponent,canActivate: [AuthGuardService,AuthGuard],children: [
			{
				path: 'packadmin',
				component: PackadminComponent,
				canActivate: [AuthGuardService,AuthGuard],
					data: { roles: 'any',funcid:'47,48,49,50,51,52,53,54,55,57',userType:'F'}
					,children: [
				 {path: 'packplantadmin',component: PackplantadminComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 // canDeactivate: [UnsavedchangesGuard],
				 data: { roles: 'any',funcid:'57',userType:'F'}
				},
				 {path: 'prgadmin',component: PrgadminComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: 'any',funcid:'55',userType:'F'}
				},
				 {path: 'cosadmin',component: CosadminComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: 'any',funcid:'52',userType:'F'}
				},
				 {path: 'app-contadmin',component: ContadminComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: 'any',funcid:'53',userType:'F'}
				},
				 {path:'regionadmin',component:RegionadminComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: 'any',funcid:'47,48,49,50,51',userType:'F'}
				},
				 {path:'componentadmin',component:ComponentadminComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: 'any',funcid:'54',userType:'F'}
				}
	                ]
			  },

			  {
				path: 'eqoconfig',
				component:EqoconfigComponent,
				children: [
					{path: 'materialmanagement',component: MaterialmanagementComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: 'any',funcid:'any',userType:'F'}},
				 {path: 'otherconfig',component: OtherconfigComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: 'any',funcid:'any',userType:'F'}}
				]
			  },

			  {
				path: 'dbiaadmin',
				component:DbiaadminComponent,
				canActivate: [AuthGuardService,AuthGuard],
					data: { roles: 'any',funcid:'53',userType:'F'},
				children: [
					{path:'dbiareqmgnt',component:DbiareqmgntComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: 'any',funcid:'53',userType:'F'}
				   },
				   {path:'dbiareqstsmgnt',component:DbiareqstsComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: 'any',funcid:'53',userType:'F'}
				   },
				]
			  },


			{path: 'formtemplate',component: FormtemplateComponent

			},
			{path: 'formnew',component: FormnewComponent

			},

			{
				path: 'packsupplieradmin',
				component: PacksupplieradminComponent,canActivate: [AuthGuardService,AuthGuard],children: [
				 {path: 'packsupplier',component: PacksupplierComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: '1',funcid:'any',userType:'F,S'}
				},
				{path: 'migrationsubmitter',component: MigrationsubmitterdashboardComponent,
				canActivate: [AuthGuardService,AuthGuard],
				data: { roles: '1',funcid:'any',userType:'F,S'}
			   },
				 {path: 'submiterdashboard',component: SubmiterdashboardComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: '1',funcid:'any',userType:'F,S'}
				},
				{
					path: 'editsupplierprofile',component: EditsupplierprofileComponent,
					 canActivate: [AuthGuardService,AuthGuard],
					 data: { roles: '1,2',funcid:'any',userType:'F,S'}

					},
					{
						path: 'containerrequest',component: ContainerrequestComponent,
						 canActivate: [AuthGuardService,AuthGuard],
						 data: { roles: '1',funcid:'any',userType:'F,S'}

					},
					{
						path: 'containerreqsts',component: ContainerreqstsComponent,
						 canActivate: [AuthGuardService,AuthGuard],
						 data: { roles: '1',funcid:'any',userType:'F,S'}

					},
				{ path: '', pathMatch: 'full', redirectTo: 'packsupplier',canActivate: [AuthGuardService]}
	                ]
			  },


			  {
				path: 'packdesignsourceadmin',
				component: PackdesignsourceadminComponent,canActivate: [AuthGuardService,AuthGuard],children: [
				 {path: 'packdesignsource',component: PackdesignsourceComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: '2',funcid:'any',userType:'F'}
				},
				{path: 'migrationsubmitter',component: MigrationsubmitterdashboardComponent,
				canActivate: [AuthGuardService,AuthGuard],
				data: { roles: '2',funcid:'any',userType:'F'}
			   },
				 {path: 'submiterdashboard',component: SubmiterdashboardComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: '2',funcid:'any',userType:'F'}
				},
				{
					path: 'editsupplierprofile',component: EditsupplierprofileComponent,
					 canActivate: [AuthGuardService,AuthGuard],
					 data: { roles: '2',funcid:'any',userType:'F'}

					},
				/*	{
						path: 'containerrequest',component: ContainerrequestComponent,
						 canActivate: [AuthGuardService,AuthGuard],
						 data: { roles: "2",funcid:"any"}

					},*/


				{ path: '', pathMatch: 'full', redirectTo: 'packdesignsource',canActivate: [AuthGuardService]}
	                ]
			  },
			  {
				path: 'proposalcreation',
				component: PackagingproposalComponent,
				canActivate: [AuthGuardService,AuthGuard],children:[
					{path:'partsandattri',component:PartsandatributesComponent,canActivate: [AuthGuardService,AuthGuard],data: { roles: '1,2',funcid:'any',userType:'F,S'}},
					{path:'packproposaltabtwo',component:PackproposaltabtwoComponent,canActivate: [AuthGuardService,AuthGuard],data: { roles: '1,2',funcid:'any',userType:'F,S'}},
					{path:'packproposaltabthree',component:PackproposaltabthreeComponent,canActivate: [AuthGuardService,AuthGuard],data: { roles: '1,2',funcid:'any',userType:'F,S'}},
					{path:'packproposaltabfour',component:PackproposaltabfourComponent,canActivate: [AuthGuardService,AuthGuard],data: { roles: '1,2',funcid:'any',userType:'F,S'}},
					{path:'eepartsandattri',component:EepartsandatributesComponent,canActivate: [AuthGuardService,AuthGuard],data: { roles: '1,2',funcid:'any',userType:'F,S'}},
					{path:'eepackproposaltabtwo',component:EepackproposaltabtwoComponent,canActivate: [AuthGuardService,AuthGuard],data: { roles: '1,2',funcid:'any',userType:'F,S'}},
					{path:'eepackproposaltabthree',component:EepackproposaltabthreeComponent,canActivate: [AuthGuardService,AuthGuard],data: { roles: '1,2',funcid:'any',userType:'F,S'}},
					{path:'eepackproposaltabfour',component:EepackproposaltabfourComponent,canActivate: [AuthGuardService,AuthGuard],data: { roles: '1,2',funcid:'any',userType:'F,S'}},




				]
			},


			{
				path:'packapprover',
				component:PackapproverComponent,canActivate: [AuthGuardService,AuthGuard],
				data: { roles: '3,4,5,6,9,11,19,20,12',funcid:'any',userType:'F,S'},
				children:[
					{path:'preliminaryapp',component:PreliminaryappComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: '3,12',funcid:'any',userType:'F'}
				},
					{path:'additionalapp',component:AdditionalappComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: '4',funcid:'any',userType:'F,S'}
				},
					{path:'plantapp',component:PlantappComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: '5',funcid:'any',userType:'F'}
				},
					{path:'releasefrevalapp',component:ReleasefrevalappComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: '9,12',funcid:'any',userType:'F'}
				},

					{path:'plantfinalapp',component:PlantfinalappComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: '6',funcid:'any',userType:'F'}
				},
					{path:'releasefrprodapp',component:ReleasefrprodappComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: '11,12',funcid:'any',userType:'F'}
				},


				{path:'plantappoffsite',component:PlantappoffsiteComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: '19',funcid:'any',userType:'F,S'}
				},

				{path:'plantfinalappoffsite',component:PlantfinalappoffsiteComponent,
				canActivate: [AuthGuardService,AuthGuard],
				data: { roles: '20',funcid:'any',userType:'F,S'}
			},
			{path:'migrationapproval',component:MigrationapprovalComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: '3,12',funcid:'any',userType:'F'}
				},

				{path:'alladditionalapproval',component:AlladditionalapprovalComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: '12',funcid:'any',userType:'F'}
				},




					// {path:'testpage',component:TestpageComponent}
				]
			},
			{
				path:'useradmin',

				component:UseradminComponent,
				canActivate: [AuthGuardService,AuthGuard],
				data: { roles: 'any',funcid:'45,46',userType:'F'},
				children:[
					{path:'PendingAccessRequest',component:PendingaccessrequestComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: 'any',funcid:'45,46',userType:'F'}
				},
					{path:'editaccess',component:EditaccessComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: 'any',funcid:'45,46',userType:'F'}

				}
				]
			},


			{
				path:'reportsadmin',

				component:ReportsadminComponent,
				canActivate: [AuthGuardService,AuthGuard],children: [
					{path: 'reports',component: ReportsComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: 'any',funcid:'33,34,32',userType:'F,S'},
				   },
				   {path: 'pfepdataexport',component: PfepdataexportreportComponent,
				   canActivate: [AuthGuardService,AuthGuard],
				   data: { roles: 'any',funcid:'33',userType:'F,S'},
				  },
				  {path: 'packagingsummaryrep',component: PackagingsummaryreportComponent,
				   canActivate: [AuthGuardService,AuthGuard],
				   data: { roles: 'any',funcid:'34',userType:'F,S'},
				  },
				  {path: 'form1271datarep',component: Form1271datareportComponent,
				   canActivate: [AuthGuardService,AuthGuard],
				   data: { roles: 'any',funcid:'32',userType:'F,S'},
				  },
				  {path: 'lomreport',component: LomreportComponent,
				  canActivate: [AuthGuardService,AuthGuard],
				  data: { roles: 'any',funcid:'32',userType:'F,S'},
				 },
				 {path: 'suppliercontact',component: SuppliercontactreportComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: "any",funcid:"any",userType:"F"},
				},
				   { path: '', pathMatch: 'full', redirectTo: 'reports',canActivate: [AuthGuardService]}
					   ]
			},

			{
				path: 'gpparchivesearchpart',
				component: GpparchivesearchpartComponent,
				  canActivate: [AuthGuardService,AuthGuard],
				  data: { roles: 'any',funcid:'any',userType:'F'},
			},
			//Revathy
			{	
				path: 'programlevelemail',
				component: ProgramlevelemailComponent,
				  canActivate: [AuthGuardService,AuthGuard],
				  data: { roles: "any",funcid:"any",userType:"F"},
			},

			{
				path:'archivereportsadmin',

				component:GpparchivereportsadminComponent,
				canActivate: [AuthGuardService,AuthGuard],children: [
					{path: 'archivereports',component: GpparchivereportsComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: 'any',funcid:'any',userType:'F'},
				   },
				   {path: 'archivepfepdataexprep',component: GpparchivepfepdataexportreportComponent,
				   canActivate: [AuthGuardService,AuthGuard],
				   data: { roles: 'any',funcid:'any',userType:'F'},
				  },

				  {path: 'archivepackagingsummaryrep',component: GpparchivepackagingsummaryreportComponent,
				   canActivate: [AuthGuardService,AuthGuard],
				   data: { roles: 'any',funcid:'any',userType:'F'},
				  },
				  {path: 'archivelomrep',component: GpparchivelomreportComponent,
				  canActivate: [AuthGuardService,AuthGuard],
				  data: { roles: 'any',funcid:'any',userType:'F'},
				 },

				 {path: 'archiveform1271rep',component: Gpparchiveform1271reportComponent,
				   canActivate: [AuthGuardService,AuthGuard],
				   data: { roles: 'any',funcid:'any',userType:'F'},
				  },
				  {path: 'archivesuppliercontact',component: GpparchivesuppliercontactreportComponent,
				   canActivate: [AuthGuardService,AuthGuard],
				   data: { roles: "any",funcid:"any",userType:"F"},
				  },
				  
				   { path: '', pathMatch: 'full', redirectTo: 'archivereports',canActivate: [AuthGuardService,AuthGuard],
				   data: { roles: 'any',funcid:'any',userType:'F'}}
					   ]
			},


			{ path: '', pathMatch: 'full', redirectTo: '/europe/packagingprocessdashboard/packagingprocesshome',canActivate: [AuthGuardService]}



			  // {path:'partsandattributes',component:PartsandatributesComponent}
			]},

			/*{
				path:'helpcomponent',
				component:HelpcomponentComponent,
			},*/
			{
				path:'packaginglinksdocument',
				component:PackaginglinkscomponentComponent,
				canActivate: [AuthGuardService,AuthGuard]
			},


			{ path: '', pathMatch: 'full', redirectTo: 'packagingprocessdashboard',canActivate: [AuthGuardService]}

		  ],
	},

	{
		path: '',
		redirectTo: '/europe',
		pathMatch: 'full'
	},
	{
		path: 'northamerica',
		component: NorthamericaComponent, canActivate: [AuthGuardService,AuthGuard],children: [
			{
				path: 'example-page',
				component: ExamplePageComponent,
				resolve:{
					examplepage:ExamplePageResolve
				}
			},
			{path:'testpage',component:TestpageComponent},




			{
				path: 'packagingprocessdashboard',
				component: PackagingprocessdashboardComponent,canActivate: [AuthGuardService,AuthGuard],children:[
					{path:'searchexistingproposal',component:SearchexistingproposalComponent,
					canActivate: [AuthGuardService,AuthGuard],

				},

				{path:'searchpart',component:SearchPartsComponent,
					canActivate: [AuthGuardService,AuthGuard]
				},

				{
					path: 'improvementproposalcreation',
					component: ImprovementpackagingproposalComponent,
					canActivate: [AuthGuardService,AuthGuard],children:[
						{path:'improvementpartsandattri',component:ImprovementtaboneComponent,canActivate: [AuthGuardService,AuthGuard]},
						{path:'improvementpackproposaltabtwo',component:ImprovementtabtwoComponent,canActivate: [AuthGuardService,AuthGuard]},
						{path:'improvementpackproposaltabthree',component:ImprovementtabthreeComponent,canActivate: [AuthGuardService,AuthGuard]},
						{path:'improvementpackproposaltabfour',component:ImprovementtabfourComponent,canActivate: [AuthGuardService,AuthGuard]},
						{path:'improvementeepartsandattri',component:ImprovementeetaboneComponent,canActivate: [AuthGuardService,AuthGuard]},
						{path:'improvementeepackproposaltabtwo',component:ImprovementeetabtwoComponent,canActivate: [AuthGuardService,AuthGuard]},
						{path:'improvementeepackproposaltabthree',component:ImprovementeetabthreeComponent,canActivate: [AuthGuardService,AuthGuard]},
						{path:'improvementeepackproposaltabfour',component:ImprovementeetabfourComponent,canActivate: [AuthGuardService,AuthGuard]},




					]
				},
					{path:'packagingprocesshome',component:PackagingprocesshomeComponent,
					canActivate: [AuthGuardService,AuthGuard],

				},

				{ path: '', pathMatch: 'full', redirectTo: 'packagingprocesshome',canActivate: [AuthGuardService]}
				]
			},


			{
				path: 'packagingprocess',
				component: PackagingprocessComponent,canActivate: [AuthGuardService,AuthGuard],children: [
			{
				path: 'packadmin',
				component: PackadminComponent,
				canActivate: [AuthGuardService,AuthGuard],
					data: { roles: 'any',funcid:'47,48,49,50,51,52,53,54,55,57',userType:'F'}
					,children: [
				 {path: 'packplantadmin',component: PackplantadminComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				// canDeactivate: [UnsavedchangesGuard],
				 data: { roles: 'any',funcid:'57',userType:'F'}
				},
				 {path: 'prgadmin',component: PrgadminComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: 'any',funcid:'55',userType:'F'}
				},
				 {path: 'cosadmin',component: CosadminComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: 'any',funcid:'52',userType:'F'}
				},
				 {path: 'app-contadmin',component: ContadminComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: 'any',funcid:'53',userType:'F'}
				},
				 {path:'regionadmin',component:RegionadminComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: 'any',funcid:'47,48,49,50,51',userType:'F'}
				},
				 {path:'componentadmin',component:ComponentadminComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: 'any',funcid:'54',userType:'F'}
				},

	                ]
			  },

			  {
				path: 'eqoconfig',
				component:EqoconfigComponent,
				children: [
					{path: 'materialmanagement',component: MaterialmanagementComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: 'any',funcid:'any',userType:'F'}},
				 {path: 'otherconfig',component: OtherconfigComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: 'any',funcid:'any',userType:'F'}}
				]
			  },

			  {
				path: 'dbiaadmin',
				component:DbiaadminComponent,
				canActivate: [AuthGuardService,AuthGuard],
					data: { roles: 'any',funcid:'53',userType:'F'},
				children: [
					{path:'dbiareqmgnt',component:DbiareqmgntComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: 'any',funcid:'53',userType:'F'}
				   },
				   {path:'dbiareqstsmgnt',component:DbiareqstsComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: 'any',funcid:'53',userType:'F'}
				   },
				]
			  },




			{path: 'formtemplate',component: FormtemplateComponent

			},
			{path: 'formnew',component: FormnewComponent

			},

			{
				path: 'packsupplieradmin',
				component: PacksupplieradminComponent,canActivate: [AuthGuardService,AuthGuard],children: [
				 {path: 'packsupplier',component: PacksupplierComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: '1',funcid:'any',userType:'F,S'}
				},
				{path: 'migrationsubmitter',component: MigrationsubmitterdashboardComponent,
				canActivate: [AuthGuardService,AuthGuard],
				data: { roles: '1',funcid:'any',userType:'F,S'}
			   },
				 {path: 'submiterdashboard',component: SubmiterdashboardComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: '1',funcid:'any',userType:'F,S'}
				},
				{
					path: 'editsupplierprofile',component: EditsupplierprofileComponent,
					 canActivate: [AuthGuardService,AuthGuard],
					 data: { roles: '1,2',funcid:'any',userType:'F,S'}

					},
					{
						path: 'containerrequest',component: ContainerrequestComponent,
						 canActivate: [AuthGuardService,AuthGuard],
						 data: { roles: '1',funcid:'any',userType:'F,S'}

					},
					{
						path: 'containerreqsts',component: ContainerreqstsComponent,
						 canActivate: [AuthGuardService,AuthGuard],
						 data: { roles: '1',funcid:'any',userType:'F,S'}

					},
				{ path: '', pathMatch: 'full', redirectTo: 'packsupplier',canActivate: [AuthGuardService]}
	                ]
			  },


			  {
				path: 'packdesignsourceadmin',
				component: PackdesignsourceadminComponent,canActivate: [AuthGuardService,AuthGuard],children: [
				 {path: 'packdesignsource',component: PackdesignsourceComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: '2',funcid:'any',userType:'F'}
				},
				{path: 'migrationsubmitter',component: MigrationsubmitterdashboardComponent,
				canActivate: [AuthGuardService,AuthGuard],
				data: { roles: '2',funcid:'any',userType:'F'}
			   },
				 {path: 'submiterdashboard',component: SubmiterdashboardComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: '2',funcid:'any',userType:'F'}
				},
				{
					path: 'editsupplierprofile',component: EditsupplierprofileComponent,
					 canActivate: [AuthGuardService,AuthGuard],
					 data: { roles: '2',funcid:'any',userType:'F'}

					},
					/*{
						path: 'containerrequest',component: ContainerrequestComponent,
						 canActivate: [AuthGuardService,AuthGuard],
						 data: { roles: "1",funcid:"any"}

					},*/


				{ path: '', pathMatch: 'full', redirectTo: 'packdesignsource',canActivate: [AuthGuardService]}
	                ]
			  },
			  {
				path: 'proposalcreation',
				component: PackagingproposalComponent,
				canActivate: [AuthGuardService,AuthGuard],children:[
					{path:'partsandattri',component:PartsandatributesComponent,canActivate: [AuthGuardService,AuthGuard],data: { roles: '1,2',funcid:'any',userType:'F,S'}},
					{path:'packproposaltabtwo',component:PackproposaltabtwoComponent,canActivate: [AuthGuardService,AuthGuard],data: { roles: '1,2',funcid:'any',userType:'F,S'}},
					{path:'packproposaltabthree',component:PackproposaltabthreeComponent,canActivate: [AuthGuardService,AuthGuard],data: { roles: '1,2',funcid:'any',userType:'F,S'}},
					{path:'packproposaltabfour',component:PackproposaltabfourComponent,canActivate: [AuthGuardService,AuthGuard],data: { roles: '1,2',funcid:'any',userType:'F,S'}},
					{path:'eepartsandattri',component:EepartsandatributesComponent,canActivate: [AuthGuardService,AuthGuard],data: { roles: '1,2',funcid:'any',userType:'F,S'}},
					{path:'eepackproposaltabtwo',component:EepackproposaltabtwoComponent,canActivate: [AuthGuardService,AuthGuard],data: { roles: '1,2',funcid:'any',userType:'F,S'}},
					{path:'eepackproposaltabthree',component:EepackproposaltabthreeComponent,canActivate: [AuthGuardService,AuthGuard],data: { roles: '1,2',funcid:'any',userType:'F,S'}},
					{path:'eepackproposaltabfour',component:EepackproposaltabfourComponent,canActivate: [AuthGuardService,AuthGuard],data: { roles: '1,2',funcid:'any',userType:'F,S'}},




				]
			},


			{
				path:'packapprover',
				component:PackapproverComponent,canActivate: [AuthGuardService,AuthGuard],
				data: { roles: '3,4,5,6,9,11,19,20,12',funcid:'any',userType:'F,S'},
				children:[
					{path:'preliminaryapp',component:PreliminaryappComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: '3,12',funcid:'any',userType:'F'}
				},
					{path:'additionalapp',component:AdditionalappComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: '4',funcid:'any',userType:'F,S'}
				},
					{path:'plantapp',component:PlantappComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: '5',funcid:'any',userType:'F'}
				},
					{path:'releasefrevalapp',component:ReleasefrevalappComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: '9,12',funcid:'any',userType:'F'}
				},

					{path:'plantfinalapp',component:PlantfinalappComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: '6',funcid:'any',userType:'F'}
				},
					{path:'releasefrprodapp',component:ReleasefrprodappComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: '11,12',funcid:'any',userType:'F'}
				},


				{path:'plantappoffsite',component:PlantappoffsiteComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: '19',funcid:'any',userType:'F,S'}
				},

				{path:'plantfinalappoffsite',component:PlantfinalappoffsiteComponent,
				canActivate: [AuthGuardService,AuthGuard],
				data: { roles: '20',funcid:'any',userType:'F,S'}
			},
			{path:'migrationapproval',component:MigrationapprovalComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: '3,12',funcid:'any',userType:'F'}
				},
				{path:'alladditionalapproval',component:AlladditionalapprovalComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: '12',funcid:'any',userType:'F'}
				},



				]
			},
			{	
				path: 'programlevelemail',
				component: ProgramlevelemailComponent,
				  canActivate: [AuthGuardService,AuthGuard],
				  data: { roles: "any",funcid:"any",userType:"F"},
			},
			{
				path:'useradmin',

				component:UseradminComponent,
				canActivate: [AuthGuardService,AuthGuard],
				data: { roles: 'any',funcid:'45,46',userType:'F'},
				children:[
					{path:'PendingAccessRequest',component:PendingaccessrequestComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: 'any',funcid:'45,46',userType:'F'}
				},
					{path:'editaccess',component:EditaccessComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: 'any',funcid:'45,46',userType:'F'}

				}
				]
			},

			{
				path:'reportsadmin',

				component:ReportsadminComponent,
				canActivate: [AuthGuardService,AuthGuard],children: [
					{path: 'reports',component: ReportsComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: 'any',funcid:'33,34,32',userType:'F,S'},
				   },
				   {path: 'pfepdataexport',component: PfepdataexportreportComponent,
				   canActivate: [AuthGuardService,AuthGuard],
				   data: { roles: 'any',funcid:'33',userType:'F,S'},
				  },
				  {path: 'packagingsummaryrep',component: PackagingsummaryreportComponent,
				   canActivate: [AuthGuardService,AuthGuard],
				   data: { roles: 'any',funcid:'34',userType:'F,S'},
				  },
				  {path: 'form1271datarep',component: Form1271datareportComponent,
				  canActivate: [AuthGuardService,AuthGuard],
				  data: { roles: 'any',funcid:'32',userType:'F,S'},
				 },
				 {path: 'lomreport',component: LomreportComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: 'any',funcid:'32',userType:'F,S'},
				},
				{path: 'suppliercontact',component: SuppliercontactreportComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: "any",funcid:"any",userType:"F"},
				},
				   { path: '', pathMatch: 'full', redirectTo: 'reports',canActivate: [AuthGuardService]}
					   ]
			},

			{
				path: 'gpparchivesearchpart',
				component: GpparchivesearchpartComponent,
				  canActivate: [AuthGuardService,AuthGuard],
				  data: { roles: 'any',funcid:'any',userType:'F'},
			},
			//Revathy
			

			{
				path:'archivereportsadmin',

				component:GpparchivereportsadminComponent,
				canActivate: [AuthGuardService,AuthGuard],children: [
					{path: 'archivereports',component: GpparchivereportsComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: 'any',funcid:'any',userType:'F'},
				   },
				   {path: 'archivepfepdataexprep',component: GpparchivepfepdataexportreportComponent,
				   canActivate: [AuthGuardService,AuthGuard],
				   data: { roles: 'any',funcid:'any',userType:'F'},
				  },

				  {path: 'archivepackagingsummaryrep',component: GpparchivepackagingsummaryreportComponent,
				   canActivate: [AuthGuardService,AuthGuard],
				   data: { roles: 'any',funcid:'any',userType:'F'},
				  },
				  {path: 'archivelomrep',component: GpparchivelomreportComponent,
				  canActivate: [AuthGuardService,AuthGuard],
				  data: { roles: 'any',funcid:'any',userType:'F'},
				 },

				 {path: 'archiveform1271rep',component: Gpparchiveform1271reportComponent,
				   canActivate: [AuthGuardService,AuthGuard],
				   data: { roles: 'any',funcid:'any',userType:'F'},
				  },
				  {path: 'archivesuppliercontact',component: GpparchivesuppliercontactreportComponent,
				   canActivate: [AuthGuardService,AuthGuard],
				   data: { roles: "any",funcid:"any",userType:"F"},
				  },
				  
				   { path: '', pathMatch: 'full', redirectTo: 'archivereports',canActivate: [AuthGuardService,AuthGuard],
				   data: { roles: 'any',funcid:'any',userType:'F'}}
					   ]
			},


			{ path: '', pathMatch: 'full', redirectTo: '/northamerica/packagingprocessdashboard/packagingprocesshome',canActivate: [AuthGuardService]}



			  // {path:'partsandattributes',component:PartsandatributesComponent}
			]},
			{
				path:'packaginglinksdocument',
				component:PackaginglinkscomponentComponent,
				canActivate: [AuthGuardService,AuthGuard]
			},


			{ path: '', pathMatch: 'full', redirectTo: 'packagingprocessdashboard',canActivate: [AuthGuardService]}
			/*  {
				path: 'testpage',
				component: TestpageComponent,children: [
					{path: 'packplantadmin',component: PackplantadminComponent},
					{path: 'prgadmin',component: PrgadminComponent},
					{path: 'cosadmin',component: CosadminComponent},
					{path: 'app-contadmin',component: ContadminComponent},
					{path:'regionadmin',component:RegionadminComponent}
					   ]
			}*/

		  ],
	},

	{
		path: '',
		redirectTo: '/northamerica',
		pathMatch: 'full'
	},
	{
		path: 'southamerica',
		component: SouthamericaComponent, canActivate: [AuthGuardService,AuthGuard],children: [
			{
				path: 'example-page',
				component: ExamplePageComponent,
				resolve:{
					examplepage:ExamplePageResolve
				}
			},






			{
				path: 'packagingprocessdashboard',
				component: PackagingprocessdashboardComponent,canActivate: [AuthGuardService,AuthGuard],children:[
					{path:'searchexistingproposal',component:SearchexistingproposalComponent,
					canActivate: [AuthGuardService,AuthGuard],

				},

				{path:'searchpart',component:SearchPartsComponent,
					canActivate: [AuthGuardService,AuthGuard]
				},

				{
					path: 'improvementproposalcreation',
					component: ImprovementpackagingproposalComponent,
					canActivate: [AuthGuardService,AuthGuard],children:[
						{path:'improvementpartsandattri',component:ImprovementtaboneComponent,canActivate: [AuthGuardService,AuthGuard]},
						{path:'improvementpackproposaltabtwo',component:ImprovementtabtwoComponent,canActivate: [AuthGuardService,AuthGuard]},
						{path:'improvementpackproposaltabthree',component:ImprovementtabthreeComponent,canActivate: [AuthGuardService,AuthGuard]},
						{path:'improvementpackproposaltabfour',component:ImprovementtabfourComponent,canActivate: [AuthGuardService,AuthGuard]},
						{path:'improvementeepartsandattri',component:ImprovementeetaboneComponent,canActivate: [AuthGuardService,AuthGuard]},
						{path:'improvementeepackproposaltabtwo',component:ImprovementeetabtwoComponent,canActivate: [AuthGuardService,AuthGuard]},
						{path:'improvementeepackproposaltabthree',component:ImprovementeetabthreeComponent,canActivate: [AuthGuardService,AuthGuard]},
						{path:'improvementeepackproposaltabfour',component:ImprovementeetabfourComponent,canActivate: [AuthGuardService,AuthGuard]},




					]
				},
					{path:'packagingprocesshome',component:PackagingprocesshomeComponent,
					canActivate: [AuthGuardService,AuthGuard],

				},
				{ path: '', pathMatch: 'full', redirectTo: 'packagingprocesshome',canActivate: [AuthGuardService]}
				]
			},


			{
				path: 'packagingprocess',
				component: PackagingprocessComponent,canActivate: [AuthGuardService,AuthGuard],children: [
			{
				path: 'packadmin',
				component: PackadminComponent,
				canActivate: [AuthGuardService,AuthGuard],
					data: { roles: 'any',funcid:'47,48,49,50,51,52,53,54,55,57',userType:'F'}
					,children: [
				 {path: 'packplantadmin',component: PackplantadminComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				// canDeactivate: [UnsavedchangesGuard],
				 data: { roles: 'any',funcid:'57',userType:'F'}
				},
				 {path: 'prgadmin',component: PrgadminComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: 'any',funcid:'55',userType:'F'}
				},
				 {path: 'cosadmin',component: CosadminComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: 'any',funcid:'52',userType:'F'}
				},
				 {path: 'app-contadmin',component: ContadminComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: 'any',funcid:'53',userType:'F'}
				},
				 {path:'regionadmin',component:RegionadminComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: 'any',funcid:'47,48,49,50,51',userType:'F'}
				},
				 {path:'componentadmin',component:ComponentadminComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: 'any',funcid:'54',userType:'F'}
				}

	                ]
			  },

			  {
				path: 'eqoconfig',
				component:EqoconfigComponent,
				children: [
					{path: 'materialmanagement',component: MaterialmanagementComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: 'any',funcid:'any',userType:'F'}},
				 {path: 'otherconfig',component: OtherconfigComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: 'any',funcid:'any',userType:'F'}}
				]
			  },

			  {
				path: 'dbiaadmin',
				component:DbiaadminComponent,
				canActivate: [AuthGuardService,AuthGuard],
					data: { roles: 'any',funcid:'53',userType:'F'},
				children: [
					{path:'dbiareqmgnt',component:DbiareqmgntComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: 'any',funcid:'53',userType:'F'}
				   },
				   {path:'dbiareqstsmgnt',component:DbiareqstsComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: 'any',funcid:'53',userType:'F'}
				   },
				]
			  },




			{path: 'formtemplate',component: FormtemplateComponent

			},
			{path: 'formnew',component: FormnewComponent

			},

			{
				path: 'packsupplieradmin',
				component: PacksupplieradminComponent,canActivate: [AuthGuardService,AuthGuard],children: [
				 {path: 'packsupplier',component: PacksupplierComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: '1',funcid:'any',userType:'F,S'}
				},
				{path: 'migrationsubmitter',component: MigrationsubmitterdashboardComponent,
				canActivate: [AuthGuardService,AuthGuard],
				data: { roles: '1',funcid:'any',userType:'F,S'}
			   },
				 {path: 'submiterdashboard',component: SubmiterdashboardComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: '1',funcid:'any',userType:'F,S'}
				},
				{
					path: 'editsupplierprofile',component: EditsupplierprofileComponent,
					 canActivate: [AuthGuardService,AuthGuard],
					 data: { roles: '1',funcid:'any',userType:'F,S'}

					},
					{
						path: 'containerrequest',component: ContainerrequestComponent,
						 canActivate: [AuthGuardService,AuthGuard],
						 data: { roles: '1',funcid:'any',userType:'F,S'}

					},
					{
						path: 'containerreqsts',component: ContainerreqstsComponent,
						 canActivate: [AuthGuardService,AuthGuard],
						 data: { roles: '1',funcid:'any',userType:'F,S'}

					},
				{ path: '', pathMatch: 'full', redirectTo: 'packsupplier',canActivate: [AuthGuardService]}
	                ]
			  },


			  {
				path: 'packdesignsourceadmin',
				component: PackdesignsourceadminComponent,canActivate: [AuthGuardService,AuthGuard],children: [
				 {path: 'packdesignsource',component: PackdesignsourceComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: '2',funcid:'any',userType:'F'}
				},
				 {path: 'submiterdashboard',component: SubmiterdashboardComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: '2',funcid:'any',userType:'F'}
				},
				{path: 'migrationsubmitter',component: MigrationsubmitterdashboardComponent,
				canActivate: [AuthGuardService,AuthGuard],
				data: { roles: '2',funcid:'any',userType:'F'}
			   },
				{
					path: 'editsupplierprofile',component: EditsupplierprofileComponent,
					 canActivate: [AuthGuardService,AuthGuard],
					 data: { roles: '2',funcid:'any',userType:'F'}

					},
				/*	{
						path: 'containerrequest',component: ContainerrequestComponent,
						 canActivate: [AuthGuardService,AuthGuard],
						 data: { roles: "2",funcid:"any"}

					},*/


				{ path: '', pathMatch: 'full', redirectTo: 'packdesignsource',canActivate: [AuthGuardService]}
	                ]
			  },
			  {
				path: 'proposalcreation',
				component: PackagingproposalComponent,
				canActivate: [AuthGuardService,AuthGuard],children:[
					{path:'partsandattri',component:PartsandatributesComponent,canActivate: [AuthGuardService,AuthGuard],data: { roles: '1,2',funcid:'any',userType:'F,S'}},
					{path:'packproposaltabtwo',component:PackproposaltabtwoComponent,canActivate: [AuthGuardService,AuthGuard],data: { roles: '1,2',funcid:'any',userType:'F,S'}},
					{path:'packproposaltabthree',component:PackproposaltabthreeComponent,canActivate: [AuthGuardService,AuthGuard],data: { roles: '1,2',funcid:'any',userType:'F,S'}},
					{path:'packproposaltabfour',component:PackproposaltabfourComponent,canActivate: [AuthGuardService,AuthGuard],data: { roles: '1,2',funcid:'any',userType:'F,S'}},
					{path:'eepartsandattri',component:EepartsandatributesComponent,canActivate: [AuthGuardService,AuthGuard],data: { roles: '1,2',funcid:'any',userType:'F,S'}},
					{path:'eepackproposaltabtwo',component:EepackproposaltabtwoComponent,canActivate: [AuthGuardService,AuthGuard],data: { roles: '1,2',funcid:'any',userType:'F,S'}},
					{path:'eepackproposaltabthree',component:EepackproposaltabthreeComponent,canActivate: [AuthGuardService,AuthGuard],data: { roles: '1,2',funcid:'any',userType:'F,S'}},
					{path:'eepackproposaltabfour',component:EepackproposaltabfourComponent,canActivate: [AuthGuardService,AuthGuard],data: { roles: '1,2',funcid:'any',userType:'F,S'}},



				]
			},


			{
				path:'packapprover',
				component:PackapproverComponent,canActivate: [AuthGuardService,AuthGuard],
				data: { roles: '3,4,5,6,9,11,19,20,12',funcid:'any',userType:'F,S'},
				children:[
					{path:'preliminaryapp',component:PreliminaryappComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: '3,12',funcid:'any',userType:'F'}
				},
					{path:'additionalapp',component:AdditionalappComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: '4',funcid:'any',userType:'F,S'}
				},
					{path:'plantapp',component:PlantappComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: '5',funcid:'any',userType:'F'}
				},
					{path:'releasefrevalapp',component:ReleasefrevalappComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: '9,12',funcid:'any',userType:'F'}
				},

					{path:'plantfinalapp',component:PlantfinalappComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: '6',funcid:'any',userType:'F'}
				},
					{path:'releasefrprodapp',component:ReleasefrprodappComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: '11,12',funcid:'any',userType:'F'}
				},
				{path:'plantappoffsite',component:PlantappoffsiteComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: '19',funcid:'any',userType:'F,S'}
				},

				{path:'plantfinalappoffsite',component:PlantfinalappoffsiteComponent,
				canActivate: [AuthGuardService,AuthGuard],
				data: { roles: '20',funcid:'any',userType:'F,S'}
			},
			{path:'migrationapproval',component:MigrationapprovalComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: '3,12',funcid:'any',userType:'F'}
				},
				{path:'alladditionalapproval',component:AlladditionalapprovalComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: '12',funcid:'any',userType:'F'}
				},


					// {path:'testpage',component:TestpageComponent}
				]
			},
			{
				path:'useradmin',

				component:UseradminComponent,
				canActivate: [AuthGuardService,AuthGuard],
				data: { roles: 'any',funcid:'45,46',userType:'F'},
				children:[
					{path:'PendingAccessRequest',component:PendingaccessrequestComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: 'any',funcid:'45,46',userType:'F'}
				},
					{path:'editaccess',component:EditaccessComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: 'any',funcid:'45,46',userType:'F'}

				}
				]
			},


			{
				path:'reportsadmin',

				component:ReportsadminComponent,
				canActivate: [AuthGuardService,AuthGuard],children: [
					{path: 'reports',component: ReportsComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: 'any',funcid:'33,34,32',userType:'F,S'},
				   },
				   {path: 'pfepdataexport',component: PfepdataexportreportComponent,
				   canActivate: [AuthGuardService,AuthGuard],
				   data: { roles: 'any',funcid:'33',userType:'F,S'},
				  },
				  {path: 'packagingsummaryrep',component: PackagingsummaryreportComponent,
				   canActivate: [AuthGuardService,AuthGuard],
				   data: { roles: 'any',funcid:'34',userType:'F,S'},
				  },
				  {path: 'form1271datarep',component: Form1271datareportComponent,
				  canActivate: [AuthGuardService,AuthGuard],
				  data: { roles: 'any',funcid:'32',userType:'F,S'},
				 },
				 {path: 'lomreport',component: LomreportComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: 'any',funcid:'32',userType:'F,S'},
				},
				{path: 'suppliercontact',component: SuppliercontactreportComponent,
				 canActivate: [AuthGuardService,AuthGuard],
				 data: { roles: "any",funcid:"any",userType:"F"},
				},
				   { path: '', pathMatch: 'full', redirectTo: 'reports',canActivate: [AuthGuardService]}
					   ]
			},

			{
				path: 'gpparchivesearchpart',
				component: GpparchivesearchpartComponent,
				  canActivate: [AuthGuardService,AuthGuard],
				  data: { roles: 'any',funcid:'any',userType:'F'},
			},
			//Revathy
			{	
				path: 'programlevelemail',
				component: ProgramlevelemailComponent,
				  canActivate: [AuthGuardService,AuthGuard],
				  data: { roles: "any",funcid:"any",userType:"F"},
			},

			{
				path:'archivereportsadmin',

				component:GpparchivereportsadminComponent,
				canActivate: [AuthGuardService,AuthGuard],children: [
					{path: 'archivereports',component: GpparchivereportsComponent,
					canActivate: [AuthGuardService,AuthGuard],
					data: { roles: 'any',funcid:'any',userType:'F'},
				   },
				   {path: 'archivepfepdataexprep',component: GpparchivepfepdataexportreportComponent,
				   canActivate: [AuthGuardService,AuthGuard],
				   data: { roles: 'any',funcid:'any',userType:'F'},
				  },

				  {path: 'archivepackagingsummaryrep',component: GpparchivepackagingsummaryreportComponent,
				   canActivate: [AuthGuardService,AuthGuard],
				   data: { roles: 'any',funcid:'any',userType:'F'},
				  },
				  {path: 'archivelomrep',component: GpparchivelomreportComponent,
				  canActivate: [AuthGuardService,AuthGuard],
				  data: { roles: 'any',funcid:'any',userType:'F'},
				 },

				  {path: 'archiveform1271rep',component: Gpparchiveform1271reportComponent,
				   canActivate: [AuthGuardService,AuthGuard],
				   data: { roles: 'any',funcid:'any',userType:'F'},
				  },

				  {path: 'archivesuppliercontact',component: GpparchivesuppliercontactreportComponent,
				   canActivate: [AuthGuardService,AuthGuard],
				   data: { roles: "any",funcid:"any",userType:"F"},
				  },
				  
				   { path: '', pathMatch: 'full', redirectTo: 'archivereports',canActivate: [AuthGuardService,AuthGuard],
				   data: { roles: 'any',funcid:'any',userType:'F'}}
					   ]
			},


			{ path: '', pathMatch: 'full', redirectTo: '/southamerica/packagingprocessdashboard/packagingprocesshome',canActivate: [AuthGuardService]}



			  // {path:'partsandattributes',component:PartsandatributesComponent}
			]},
			{
				path:'packaginglinksdocument',
				component:PackaginglinkscomponentComponent,
				canActivate: [AuthGuardService,AuthGuard]
			},




			{ path: '', pathMatch: 'full', redirectTo: 'packagingprocessdashboard',canActivate: [AuthGuardService]}

		  ],
	},

	{
		path: '',
		redirectTo: '/southamerica',
		pathMatch: 'full'
	},



	{
		path: 'exitpage',
		component: ExitpageComponent
	},

	{
		path: '',
		redirectTo: '/exitpage',
		pathMatch: 'full'
	},




	{
		path: 'notaccesspage',
		component: NotaccesspageComponent
	},





	{
		path: '',
		redirectTo: '/notaccesspage',
		pathMatch: 'full'
	},


		{
			path:'requestaccess',
			component:RequestuseraccessComponent,
			canActivate: [AuthGuardService]

		},



		{
			path:'additionalrequestaccess',
			component:AdditionalrequestaccessComponent,children:[{
				path:'requestaccess',
				component:RequestuseraccessComponent,
				canActivate: [AuthGuardService]

			},]
		},

	{
		path:'inactiveuser',
		component:InactiveuserpageComponent
	},
	{
		path:'editprofile',
		component:EdituserprofileComponent,
		canActivate: [AuthGuardService]


	},
	{
		path:'myuseraccess',
		component:MyuseraccessComponent,
		canActivate: [AuthGuardService]
	},
	{
		path:'anotherlogin',
		component:LoginotheruserComponent,
	},
	{
		path:'mypendingaccess',
		component:MypendingaccessComponent,
		canActivate: [AuthGuardService]
	},
	{
		path:'helpcomponent',
		component:HelpcomponentComponent,
		canActivate: [AuthGuardService]
	},
	{
		path:'packaginglinksdocument',
		component:PackaginglinkscomponentComponent,
		canActivate: [AuthGuardService,AuthGuard]
	},
	{
		path:'traininglinksdocument',
		component:TraininglinksComponent,
		canActivate: [AuthGuardService]
	},

	{
		path: 'notfoundpage', component: NotFoundComponent
	},
	{
		path: '**', redirectTo: '/notfoundpage'
	}



    // { path: '', component: SampleDemoComponent },
  /*  { path: 'forms', component: FormsDemoComponent },
    { path: 'data', component: DataDemoComponent },
    { path: 'panels', component: PanelsDemoComponent },
    { path: 'overlays', component: OverlaysDemoComponent },
    { path: 'menus', component: MenusDemoComponent },
    { path: 'messages', component: MessagesDemoComponent },
    { path: 'charts', component: ChartsDemoComponent },
    { path: 'file', component: FileDemoComponent },
    { path: 'misc', component: MiscDemoComponent },
    { path: 'documentation', component: DocumentationComponent },*/
];




export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes);
