/* tslint:disable */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TransactionService } from '../loginpage/Transaction.service';

export interface linksTemplate
{
  linkurl:string;
  name:string;
}


@Component({
  selector: 'app-packaginglinkscomponent',
  templateUrl: './packaginglinkscomponent.component.html',
  styleUrls: ['./packaginglinkscomponent.component.css']
})
export class PackaginglinkscomponentComponent implements OnInit {



  constructor(private transactionService:TransactionService, private router:Router) { }

  enableApLinks=false;
  enableEuLinks=false;
  enableNaLinks=false;
  enableSaLinks=false;

  linksList:linksTemplate[]=[];

  linksAp:linksTemplate[]=[];
  linksEu:linksTemplate[]=[];
  linksNa:linksTemplate[]=[];
  linksSa:linksTemplate[]=[];
  commonLinks:linksTemplate[]=[];

  regionName='';
  regionDisplay='';
  regionCode='';

  ngOnInit(): void {


    this.linksAp=[
      {linkurl:"https://www.fsp-wlp.ford.com/gtc/docs/napkgguide.pdf",name:"North American production part returnable guidelines"},
      {linkurl:"https://www.fsp-wlp.ford.com/gtc/docs/globalexppkg.pdf",name:"Export Packaging guidelines"},
      {linkurl:"https://www.fsp-wlp.ford.com/gtc/docs/nashipping.pdf",name:"Shipping Instructions"},
      {linkurl:"https://www.fsp-wlp.ford.com/gtc/docs/shipparts.pdf",name:"Label guidelines"},
     // {linkurl:"https://www.packaging.ford.com/SiteCollectionDocuments/IMG-%20Packaging%20Terms%20and%20Conditions%20-2022.pdf",name:"Packaging Terms & Conditions for Production Parts IMG (Excludes JV Plants)"}
    ];

    this.linksEu=[
      {linkurl:"https://www.fsp-wlp.ford.com/gtc/docs/globalexppkg.pdf",name:"Global Export Market Packaging Terms and Conditions"},
      {linkurl:"https://www.fsp-wlp.ford.com/gtc/docs/napkgguide.pdf",name:"North American production part returnable guidelines"},
      {linkurl:"https://www.fsp-wlp.ford.com/gtc/docs/eu1750pkg.pdf",name:"Europe Packaging Terms and Conditions"},
      {linkurl:"https://www.fsp-wlp.ford.com/gtc/docs/globalexppkg.pdf",name:"Export Packaging guidelines"},
      {linkurl:"https://www.fsp-wlp.ford.com/gtc/docs/nashipping.pdf",name:"Shipping Instructions"},
      {linkurl:"https://www.fsp-wlp.ford.com/gtc/docs/shipparts.pdf",name:"Label guidelines"},
      //{linkurl:"https://www.packaging.ford.com/SiteCollectionDocuments/IMG-%20Packaging%20Terms%20and%20Conditions%20-2022.pdf",name:"Packaging Terms & Conditions for Production Parts IMG (Excludes JV Plants)"}

    ];
    this.linksNa=[
      {linkurl:"https://www.fsp-wlp.ford.com/gtc/docs/napkgguide.pdf",name:"North American production part returnable guidelines"},
      {linkurl:"https://www.fsp-wlp.ford.com/gtc/docs/globalexppkg.pdf",name:"Export Packaging guidelines"},
      {linkurl:"https://www.fsp-wlp.ford.com/gtc/docs/nashipping.pdf",name:"Shipping Instructions"},
      {linkurl:"https://www.fsp-wlp.ford.com/gtc/docs/shipparts.pdf",name:"Label guidelines"},
      //{linkurl:"https://www.packaging.ford.com/SiteCollectionDocuments/IMG-%20Packaging%20Terms%20and%20Conditions%20-2022.pdf",name:"Packaging Terms & Conditions for Production Parts IMG (Excludes JV Plants)"}
    ];

    this.linksSa=[
      {linkurl:"https://www.fsp-wlp.ford.com/gtc/docs/napkgguide.pdf",name:"North American production part returnable guidelines"},
      {linkurl:"https://www.fsp-wlp.ford.com/gtc/docs/globalexppkg.pdf",name:"Export Packaging guidelines"},
      {linkurl:"https://www.fsp-wlp.ford.com/gtc/docs/nashipping.pdf",name:"Shipping Instructions"},
      {linkurl:"https://www.fsp-wlp.ford.com/gtc/docs/shipparts.pdf",name:"Label guidelines"},
      //{linkurl:"https://www.packaging.ford.com/SiteCollectionDocuments/SA%20Packaging%20Terms%20and%20Conditions%20v_2017.pdf",name:"Returnable Packaging Terms & Conditions"},
      //{linkurl:"https://www.packaging.ford.com/SiteCollectionDocuments/IMG-%20Packaging%20Terms%20and%20Conditions%20-2022.pdf",name:"Packaging Terms & Conditions for Production Parts IMG (Excludes JV Plants)"}
  
    ];
    this.commonLinks=[
      {linkurl:'',name:''}
    ];

    this.linksList=[];
    this.regionName=this.transactionService.getregion();
    this.regionCode=this.transactionService.getregioncode(this.regionName);
    this.regionDisplay=this.regionName;
    let url=this.router.url.split('/');

    if( this.regionCode.toLowerCase() == 'ap')
    {
      this.linksList=this.linksAp;


    }
    else if( this.regionCode.toLowerCase() == 'eu')
    {
      this.linksList=this.linksEu;
    }
    else if( this.regionCode.toLowerCase() == 'na')
    {
      this.linksList=this.linksNa;
    }
    else if( this.regionCode.toLowerCase() == 'sa')
		{
      this.linksList=this.linksSa;
    }

  }


  goToHomePage()
  {
    let cur_region=this.transactionService.getregion();
    if(cur_region != null )
    {
      let reg_map_url='';
      reg_map_url=this.transactionService.getregionurlvalue(cur_region);
      reg_map_url+='packagingprocessdashboard/packagingprocesshome';

      this.router.navigate([reg_map_url]);

    }

    else
    {
      this.router.navigate(['/exitpage']);
    }
  }

}
