/* tslint:disable */
import { Component, OnInit } from '@angular/core';


import {TransactionService} from '../loginpage/Transaction.service';
import { Router,ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import {cosList,addcos,cosApprovalstageapi,cosAprovalstage, cosownershiptype, addplant} from '../loginpage/transactions';
import { MessageService } from 'primeng/api';



import { FilterUtils } from './utils/filterutils';
import { NullTemplateVisitor } from '@angular/compiler';

@Component({
  selector: 'app-cosadmin',
  templateUrl: './cosadmin.component.html',
  styleUrls: ['./cosadmin.component.css'],
  providers: [ConfirmationService,MessageService,]
})
export class CosadminComponent implements OnInit {



  cosgrid:cosList[]=[];
  current_cos_region:string;
  current_cos_region_code:string;


  enablecosaddbutton=true;
  enablecosadddetails=false;
  cosadddetails:addcos={cd_ownrshp_strat:0,
    ownrshp_strat_code:'',
    ownrshp_desc:'',
    ownrshp_cat:' ',
    emg_exp_propsal:' ',
    ford_purchased_ind:' ',
    fleet_cost_info:' ',
    cost_info_entered_by:' ',
    pkg_sign_off:' ',
    sub_press_load_doc:' ',
    third_party_mngd_ind:' ',
    daysbfr_ttcutoff:0,
    maxdays_new_prt_sub:0,
    maxdays_prelim_resp:0,
    maxdays_add_aprvr_resp:0,
    maxdays_plt_resp:0,
    days_added_test_pack:0,
    maxdays_rel_eval_resp:0,
    maxdays_plt_final_resp:0,
    maxdays_rel_prod_resp:0,
    proposal_grp_aprd_ind:'No',
    acptnce_email_ind:'Yes',
    cos_active_ind:' ',
    cd_region:' ',
    rel_eval_mail_txt:' ',
    rel_prod_mail_txt:' '

  }
  cosgetdetails:addcos={
    cd_ownrshp_strat:0,
    ownrshp_strat_code:'',
    ownrshp_desc:'',
    ownrshp_cat:' ',
    emg_exp_propsal:' ',
    ford_purchased_ind:' ',
    fleet_cost_info:' ',
    cost_info_entered_by:' ',
    pkg_sign_off:' ',
    sub_press_load_doc:' ',
    third_party_mngd_ind:' ',
    daysbfr_ttcutoff:0,
    maxdays_new_prt_sub:0,
    maxdays_prelim_resp:0,
    maxdays_add_aprvr_resp:0,
    maxdays_plt_resp:0,
    days_added_test_pack:0,
    maxdays_rel_eval_resp:0,
    maxdays_plt_final_resp:0,
    maxdays_rel_prod_resp:0,
    proposal_grp_aprd_ind:'No',
    acptnce_email_ind:'Yes',
    cos_active_ind:' ',
    cd_region:' ',
    rel_eval_mail_txt:' ',
    rel_prod_mail_txt:' '

  }


  coseditdetails:addcos={cd_ownrshp_strat:0,
    ownrshp_strat_code:'',
    ownrshp_desc:'',
    ownrshp_cat:' ',
    emg_exp_propsal:' ',
    ford_purchased_ind:' ',
    fleet_cost_info:' ',
    cost_info_entered_by:' ',
    pkg_sign_off:' ',
    sub_press_load_doc:' ',
    third_party_mngd_ind:' ',
    daysbfr_ttcutoff:0,
    maxdays_new_prt_sub:0,
    maxdays_prelim_resp:0,
    maxdays_add_aprvr_resp:0,
    maxdays_plt_resp:0,
    days_added_test_pack:0,
    maxdays_rel_eval_resp:0,
    maxdays_plt_final_resp:0,
    maxdays_rel_prod_resp:0,
    proposal_grp_aprd_ind:'No',
    acptnce_email_ind:'Yes',
    cos_active_ind:' ',
    cd_region:' ',
    rel_eval_mail_txt:' ',
    rel_prod_mail_txt:' '

  }


enablecoseditdetails=false;
enablecosgeteditdetails=false;
enablecosgetdetails=false;


cosaprovalstagelistapi:cosApprovalstageapi[]=[];
cosaprovalstagelist:cosAprovalstage[]=[];
  selected_approvalstage:cosAprovalstage;
get_selected_approvalstage:cosAprovalstage={stagename:' '};
edit_selected_approvalstage:cosAprovalstage={stagename:' '};


  cosownershiplist:cosownershiptype[]=[];
  selected_ownership:cosownershiptype;
  get_selected_ownership:cosownershiptype={ownership:' '};
  edit_selected_ownership:cosownershiptype={ownership:' '};


  evalmailenable_button=false;
  prodmailenable_button=false;
  edit_evalmailenable_button=false;
  edit_prodmailenable_button=false;




  evalmailenable_dialog=false;
  prodmailenable_dialog=false;
  edit_evalmailenable_dialog=false;
  edit_prodmailenable_dialog=false;





  prod_mail_temp=' '
  eval_mail_temp=' '
  edit_prod_mail_temp=' '
  edit_eval_mail_temp=' '
  quillFormats = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    'block',
    'image',
    'video',
  ];
  /*modules = {
    toolbar: this.quillFormats
  }*/


  modules = {
    toolbar: [
      // ['background', 'font', 'size', 'script', 'header', 'indent', 'block', 'align', 'direction', 'code'],
      ['bold', 'italic', 'strike', 'underline', 'color', 'blockquote', 'link'],
      [{ list: 'ordered' }],
      ['image', 'video'],
    ],
  };

  enableUserAccessForCOSAdmin=false;
  cosOpenedForEdit:any;


  current_routeid=0;

  activestatusfilter=[{label:'All',value:null},{label:'Active',value:'Yes'},{label:'In-Active',value:'No'}]

  ownershipStratCodeInValid=false;
  ownershipStratCodeInValidReason:string=null;
  ownerDescInValid=false;
  ownerDescInValidReason:string=null;
  daysBefCutOffInValid=false;
  daysBefCutOffInValidReason:string=null;
  maxDaysForNewPartSubInValid=false;
  maxDaysForNewPartSubInValidReason:string=null;
  maxDaysForPreliminaryRespInValid=false;
  maxDaysForPreliminaryRespInValidReason:string=null;
  maxDaysForAddApproverRespInValid=false;
  maxDaysForAddApproverRespInValidReason:string=null;
  daysAddToPlntRespUponReqForTestPackInValid=false;
  daysAddToPlntRespUponReqForTestPackInValidReason:string=null;
  maxDaysForReleaseForEvalRespInValid=false;
  maxDaysForReleaseForEvalRespInValidReason:string=null;
  maxDaysForPlntRespInValid=false;
  maxDaysForPlntRespInValidReason:string=null;
  maxDaysForPlntFinalRespInValid=false;
  maxDaysForPlntFinalRespInValidReason:string=null;
  maxDaysForReleaseForProdRespInValid=false;
  maxDaysForReleaseForProdRespInValidReason:string=null;
  emergencyExpProposalInValid = false;
  emergencyExpProposalInValidReason:string=null;
  fleetCostInfoInValid=false;
  fleetCostInfoInValidReason:string=null;
  packSignOffInValid=false;
  packSignOffInValidReason:string=null;
  sbuPressLoadDocInValid=false;
  sbuPressLoadDocInValidReason:string=null;
  thirdPartyManagedInValid=false;
  thirdPartyManagedInValidReason:string=null;
  isCosActiveInValid=false;
  isCosActiveInvalidReason:string=null;




  public cols: any[];

  constructor(private transactionService: TransactionService,private router:Router,private route: ActivatedRoute,private confirmationService: ConfirmationService,private messageService: MessageService)
   {


    FilterUtils['custom-equals'] = (value, filter): boolean => {
      if (filter === undefined || filter === null || filter.trim() === '') {
          return true;
      }

      if (value === undefined || value === null) {
          return false;
      }

      return value.toString() === filter.toString();
    }



   }

  ngOnInit(): void {




this.current_cos_region=this.transactionService.getregion();
this.transactionService.storepage(this.current_cos_region,'packagingprocess/packadmin/cosadmin');
this.current_cos_region_code=this.transactionService.getregioncode(this.current_cos_region);
console.log(this.current_cos_region_code);


this.enablecosaddbutton=true;
this.enablecosadddetails=false;

this.cosInValidationInt();

this.cosownershiplist=[
{ownership:'Ford'},
{ownership:'Supplier'},
{ownership:'Third Party'},
]


this.selected_ownership=this.cosownershiplist[0];




this.current_routeid=103;

console.log(this.current_routeid)


if(this.current_cos_region=='Asia Pacific & Africa')
{
  sessionStorage.setItem('aprouteid', JSON.stringify(this.current_routeid));
}

else if(this.current_cos_region=='Europe')
{
  sessionStorage.setItem('eurouteid', JSON.stringify(this.current_routeid));
}

else if (this.current_cos_region=='North America')

{

  sessionStorage.setItem('narouteid', JSON.stringify(this.current_routeid));
}

else if(this.current_cos_region=='South America')
{
  sessionStorage.setItem('sarouteid', JSON.stringify(this.current_routeid));
}


this.transactionService.getAprovalStageList(this.current_cos_region_code).subscribe((cosaprvlst => {

  this.cosaprovalstagelistapi=cosaprvlst;
  this.cosaprovalstagelist=[];
  for(let i=0;i<this.cosaprovalstagelistapi.length;i++)
  {
   this.cosaprovalstagelist.push({stagename:this.cosaprovalstagelistapi[i].stagename});
  }
  console.log(this.cosaprovalstagelistapi);

  this.selected_approvalstage=this.cosaprovalstagelist[0];


  this.getcoslist();



}

 ))

  }
  getcoslist()
  {

this.transactionService.getCosListonRegion(this.current_cos_region_code).subscribe((cosgriddata => {

  this.cosgrid=cosgriddata
// alert(JSON.stringify(this.cosgrid))
console.log('successful',this.cosgrid);
}

 ))

 this.cols = [
  { field: 'ownrshp_strat_code', header:'Ownership Strategy Code'},
  {  field: 'ownrshp_desc', header:'Ownership Description'},
  { field: 'ownrshp_cat', header:'Ownership'},
  { field: 'cos_active_ind', header:'Active'}
];


  }



  exportExcel() {
    import('xlsx').then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.cosgrid);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, 'COS Table');
    });
}

saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
}


  addcosbutton()
  {


    this.enablecosaddbutton=false;
    this.cosadddetails_init();
    this.cosInValidationInt();
    this.enablecosadddetails=true;
    console.log(this.cosadddetails);


  }
  cosInValidationInt(){
    this.ownershipStratCodeInValid=false;
  this.ownershipStratCodeInValidReason=null;
  this.ownerDescInValid=false;
  this.ownerDescInValidReason=null;
  this.daysBefCutOffInValid=false;
  this.daysBefCutOffInValidReason=null;
  this.maxDaysForNewPartSubInValid=false;
  this.maxDaysForNewPartSubInValidReason=null;
  this.maxDaysForPreliminaryRespInValid=false;
  this.maxDaysForPreliminaryRespInValidReason=null;
  this.maxDaysForAddApproverRespInValid=false;
  this.maxDaysForAddApproverRespInValidReason=null;
  this.daysAddToPlntRespUponReqForTestPackInValid=false;
  this.daysAddToPlntRespUponReqForTestPackInValidReason=null;
  this.maxDaysForReleaseForEvalRespInValid=false;
  this.maxDaysForReleaseForEvalRespInValidReason=null;
  this.maxDaysForPlntRespInValid=false;
  this.maxDaysForPlntRespInValidReason=null;
  this.maxDaysForPlntFinalRespInValid=false;
  this.maxDaysForPlntFinalRespInValidReason=null;
  this.maxDaysForReleaseForProdRespInValid=false;
  this.maxDaysForReleaseForProdRespInValidReason=null;
  this.emergencyExpProposalInValid=false;
  this.emergencyExpProposalInValidReason=null;
  this.fleetCostInfoInValid=false;
  this.fleetCostInfoInValidReason=null;
  this.packSignOffInValid=false;
  this.packSignOffInValidReason=null;
  this.sbuPressLoadDocInValid=false;
  this.sbuPressLoadDocInValidReason=null;
  this.thirdPartyManagedInValid=false;
  this.thirdPartyManagedInValidReason=null;
  this.isCosActiveInValid=false;
  this.isCosActiveInvalidReason=null;

  }

  cosadddetails_init()
  {
    this.cosadddetails={
      cd_ownrshp_strat:0,
      ownrshp_strat_code:'',
      ownrshp_desc:'',
      ownrshp_cat:' ',
      emg_exp_propsal:' ',
      ford_purchased_ind:' ',
      fleet_cost_info:' ',
      cost_info_entered_by:' ',
      pkg_sign_off:' ',
      sub_press_load_doc:' ',
      third_party_mngd_ind:' ',
      daysbfr_ttcutoff:null,
      maxdays_new_prt_sub:null,
      maxdays_prelim_resp:null,
      maxdays_add_aprvr_resp:null,
      maxdays_plt_resp:null,
      days_added_test_pack:null,
      maxdays_rel_eval_resp:null,
      maxdays_plt_final_resp:null,
      maxdays_rel_prod_resp:null,
      proposal_grp_aprd_ind:'No',
      acptnce_email_ind:'Yes',
      cos_active_ind:'',
      cd_region:' ',
      rel_eval_mail_txt:' ',
      rel_prod_mail_txt:' '

    }

    this.prod_mail_temp='<p>test</p><p><em><u>abc</u></em></p><p><strong style="background-color: rgb(102, 185, 102);">test2</strong></p><pre class="ql-syntax" spellcheck="false">test in new </pre";'
    this.eval_mail_temp=' ';
  }

// COS Input validation
addOwnershipStratCodeValidation(){
  if(this.cosadddetails.ownrshp_strat_code.length>=4)
{
  this.ownershipStratCodeInValid=true;
  this.ownershipStratCodeInValidReason='Only 3 characters are valid';
}
else if(this.cosadddetails.ownrshp_strat_code.length==null || this.cosadddetails.ownrshp_strat_code.trim()==''){
  this.ownershipStratCodeInValid=true;
  this.ownershipStratCodeInValidReason='Required';
}
else
{
  this.ownershipStratCodeInValid=false;
  this.ownershipStratCodeInValidReason=null
}
}
addOwnerDescValidation(){
  if(this.cosadddetails.ownrshp_desc.length>=101)
{
  this.ownerDescInValid=true;
  this.ownerDescInValidReason='Only 100 characters are valid';
}
else if(this.cosadddetails.ownrshp_desc.length==null || this.cosadddetails.ownrshp_desc.trim()==''){
  this.ownerDescInValid=true;
  this.ownerDescInValidReason='Required';
}
else
{
  this.ownerDescInValid=false;
  this.ownerDescInValidReason=null
}
}
addDaysBfrCutOffValidation(){
  if(this.cosadddetails.daysbfr_ttcutoff<0 || this.cosadddetails.daysbfr_ttcutoff>=1000 || String(this.cosadddetails.daysbfr_ttcutoff).includes('.'))
{
  this.daysBefCutOffInValid=true;
  this.daysBefCutOffInValidReason='Only 1 to 999 numbers are allowed and it cannot contain Decimal';
}
else if(this.cosadddetails.daysbfr_ttcutoff==null || this.cosadddetails.daysbfr_ttcutoff==0){
  this.daysBefCutOffInValid=true;
  this.daysBefCutOffInValidReason='Required';
}
else
{
  this.daysBefCutOffInValid=false;
  this.daysBefCutOffInValidReason=null
}
}
addMaxDaysNewPartSubValidation(){
  if(this.cosadddetails.maxdays_new_prt_sub<0 || this.cosadddetails.maxdays_new_prt_sub>=100 || String(this.cosadddetails.maxdays_new_prt_sub).includes('.'))
{
  this.maxDaysForNewPartSubInValid=true;
  this.maxDaysForNewPartSubInValidReason='Only 1 to 99 numbers are allowed and it cannot contain Decimal';
}
else if(this.cosadddetails.maxdays_new_prt_sub==null || this.cosadddetails.maxdays_new_prt_sub==0){
  this.maxDaysForNewPartSubInValid=true;
  this.maxDaysForNewPartSubInValidReason='Required';
}
else
{
  this.maxDaysForNewPartSubInValid=false;
  this.maxDaysForNewPartSubInValidReason=null
}
}
addMaxDaysNewPreliRespValidation(){
  if(this.cosadddetails.maxdays_prelim_resp<0 || this.cosadddetails.maxdays_prelim_resp>=100 || String(this.cosadddetails.maxdays_prelim_resp).includes('.'))
{
  this.maxDaysForPreliminaryRespInValid=true;
  this.maxDaysForPreliminaryRespInValidReason='Only 1 to 99 numbers are allowed and it cannot contain Decimal';
}
else if(this.cosadddetails.maxdays_prelim_resp==null || this.cosadddetails.maxdays_prelim_resp==0){
  this.maxDaysForPreliminaryRespInValid=true;
  this.maxDaysForPreliminaryRespInValidReason='Required';
}
else
{
  this.maxDaysForPreliminaryRespInValid=false;
  this.maxDaysForPreliminaryRespInValidReason=null
}
}
addMaxDaysAddApproverRespValidation(){
  if(this.cosadddetails.maxdays_add_aprvr_resp<0 || this.cosadddetails.maxdays_add_aprvr_resp>=100 || String(this.cosadddetails.maxdays_add_aprvr_resp).includes('.'))
{
  this.maxDaysForAddApproverRespInValid=true;
  this.maxDaysForAddApproverRespInValidReason='Only 1 to 99 numbers are allowed and it cannot contain Decimal';
}
else if(this.cosadddetails.maxdays_add_aprvr_resp==null || this.cosadddetails.maxdays_add_aprvr_resp==0){
  this.maxDaysForAddApproverRespInValid=true;
  this.maxDaysForAddApproverRespInValidReason='Required';
}
else
{
  this.maxDaysForAddApproverRespInValid=false;
  this.maxDaysForAddApproverRespInValidReason=null
}
}
addDaysAddToPlntRespUponReqForTstPckValidation(){
  if(this.cosadddetails.days_added_test_pack<0 || this.cosadddetails.days_added_test_pack>=100 || String(this.cosadddetails.days_added_test_pack).includes('.'))
{
  this.daysAddToPlntRespUponReqForTestPackInValid=true;
  this.daysAddToPlntRespUponReqForTestPackInValidReason='Only 1 to 99 numbers are allowed and it cannot contain Decimal';
}
else if(this.cosadddetails.days_added_test_pack==null || this.cosadddetails.days_added_test_pack==0){
  this.daysAddToPlntRespUponReqForTestPackInValid=true;
  this.daysAddToPlntRespUponReqForTestPackInValidReason='Required';
}
else
{
  this.daysAddToPlntRespUponReqForTestPackInValid=false;
  this.daysAddToPlntRespUponReqForTestPackInValidReason=null
}
}
addMaxDaysForRelForEvalRespValidation(){
  if(this.cosadddetails.maxdays_rel_eval_resp<0 || this.cosadddetails.maxdays_rel_eval_resp>=100 || String(this.cosadddetails.maxdays_rel_eval_resp).includes('.'))
{
  this.maxDaysForReleaseForEvalRespInValid=true;
  this.maxDaysForReleaseForEvalRespInValidReason='Only 1 to 99 numbers are allowed and it cannot contain Decimal';
}
else if(this.cosadddetails.maxdays_rel_eval_resp==null || this.cosadddetails.maxdays_rel_eval_resp==0){
  this.maxDaysForReleaseForEvalRespInValid=true;
  this.maxDaysForReleaseForEvalRespInValidReason='Required';
}
else
{
  this.maxDaysForReleaseForEvalRespInValid=false;
  this.maxDaysForReleaseForEvalRespInValidReason=null
}
}
addMaxDaysForPlntRespValidation(){
  if(this.cosadddetails.maxdays_plt_resp<0 || this.cosadddetails.maxdays_plt_resp>=100 || String(this.cosadddetails.maxdays_plt_resp).includes('.'))
{
  this.maxDaysForPlntRespInValid=true;
  this.maxDaysForPlntRespInValidReason='Only 1 to 99 numbers are allowed and it cannot contain Decimal';
}
else if(this.cosadddetails.maxdays_plt_resp==null || this.cosadddetails.maxdays_plt_resp==0){
  this.maxDaysForPlntRespInValid=true;
  this.maxDaysForPlntRespInValidReason='Required';
}
else
{
  this.maxDaysForPlntRespInValid=false;
  this.maxDaysForPlntRespInValidReason=null
}
}
addMaxDaysForPlntFinalRespValidation(){
  if(this.cosadddetails.maxdays_plt_final_resp<0 || this.cosadddetails.maxdays_plt_final_resp>=100 || String(this.cosadddetails.maxdays_plt_final_resp).includes('.'))
{
  this.maxDaysForPlntFinalRespInValid=true;
  this.maxDaysForPlntFinalRespInValidReason='Only 1 to 99 numbers are allowed and it cannot contain Decimal';
}
else if(this.cosadddetails.maxdays_plt_final_resp==null || this.cosadddetails.maxdays_plt_final_resp==0){
  this.maxDaysForPlntFinalRespInValid=true;
  this.maxDaysForPlntFinalRespInValidReason='Required';
}
else
{
  this.maxDaysForPlntFinalRespInValid=false;
  this.maxDaysForPlntFinalRespInValidReason=null
}
}
addMaxDaysForRelForProdRespValidation(){
  if(this.cosadddetails.maxdays_rel_prod_resp<0 || this.cosadddetails.maxdays_rel_prod_resp>=100 || String(this.cosadddetails.maxdays_rel_prod_resp).includes('.'))
{
  this.maxDaysForReleaseForProdRespInValid=true;
  this.maxDaysForReleaseForProdRespInValidReason='Only 1 to 99 numbers are allowed and it cannot contain Decimal';
}
else if(this.cosadddetails.maxdays_rel_prod_resp==null || this.cosadddetails.maxdays_rel_prod_resp==0){
  this.maxDaysForReleaseForProdRespInValid=true;
  this.maxDaysForReleaseForProdRespInValidReason='Required';
}
else
{
  this.maxDaysForReleaseForProdRespInValid=false;
  this.maxDaysForReleaseForProdRespInValidReason=null
}
}
addEmergencyExpProposal(){
  if(this.cosadddetails.emg_exp_propsal==null || this.cosadddetails.emg_exp_propsal.trim()=='')
  {
    this.emergencyExpProposalInValid=true;
    this.emergencyExpProposalInValidReason='Required';
  }
  else{
    this.emergencyExpProposalInValid=false;
    this.emergencyExpProposalInValidReason=null;
  }
}
/* addFleetCostInfo(){
  if(this.cosadddetails.fleet_cost_info==null || this.cosadddetails.fleet_cost_info.trim()=="")
  {
    this.fleetCostInfoInValid=true;
    this.fleetCostInfoInValidReason="Required";
  }
  else{
    this.fleetCostInfoInValid=false;
    this.fleetCostInfoInValidReason=null;
  }
} */
addPackageSignOff(){
  if(this.cosadddetails.pkg_sign_off==null || this.cosadddetails.pkg_sign_off.trim()=='')
  {
    this.packSignOffInValid=true;
    this.packSignOffInValidReason='Required';
  }
  else{
    this.packSignOffInValid=false;
    this.packSignOffInValidReason=null;
  }
}
addSbuPressLoadDoc(){
  if(this.cosadddetails.sub_press_load_doc==null || this.cosadddetails.sub_press_load_doc.trim()=='')
  {
    this.sbuPressLoadDocInValid=true;
    this.sbuPressLoadDocInValidReason='Required';
  }
  else{
    this.sbuPressLoadDocInValid=false;
    this.sbuPressLoadDocInValidReason=null;
  }
}
addThirdPartyManaged(){
  if(this.cosadddetails.third_party_mngd_ind==null || this.cosadddetails.third_party_mngd_ind.trim()=='')
  {
    this.thirdPartyManagedInValid=true;
    this.thirdPartyManagedInValidReason='Required';
  }
  else{
    this.thirdPartyManagedInValid=false;
    this.thirdPartyManagedInValidReason=null;
  }
}
addIsCosActive(){
  if(this.cosadddetails.cos_active_ind==null || this.cosadddetails.cos_active_ind.trim()=='')
  {
    this.isCosActiveInValid=true;
    this.isCosActiveInvalidReason='Required';
  }
  else{
    this.isCosActiveInValid=false;
    this.isCosActiveInvalidReason=null;
  }
}

cancel_cosadd()
{

  this.enablecosaddbutton=true;
  this.enablecosadddetails=false;
  this.cosadddetails_init();
  this.cosInValidationInt();
  console.log(this.cosadddetails);

}


  isemailrequired()
  {
    console.log(this.cosadddetails.acptnce_email_ind)

    if(this.cosadddetails.acptnce_email_ind=='Yes')
    {
      this.evalmailenable_button=false;
      this.prodmailenable_button=false;
    }
    else{
      this.evalmailenable_button=true;
      this.prodmailenable_button=true;
    }
  }




evalmail_button()
{
  this.evalmailenable_dialog=true;
}


evalmail_ok()
{
  console.log(this.cosadddetails.rel_eval_mail_txt);
  this.cosadddetails.rel_eval_mail_txt=this.eval_mail_temp;
  console.log(this.cosadddetails.rel_eval_mail_txt);
  this.evalmailenable_dialog=false;
}

  evalmail_cancel()
  {
    this.evalmailenable_dialog=false;
  }





  prodmail_button()
  {
    this.prodmailenable_dialog=true;
  }


  prodmail_ok()
{
  console.log(this.cosadddetails.rel_prod_mail_txt);
  this.cosadddetails.rel_prod_mail_txt=this.prod_mail_temp;
  console.log(this.cosadddetails.rel_prod_mail_txt);
  this.prodmailenable_dialog=false;
}

    prodmail_cancel()
    {
      this.prodmailenable_dialog=false;
    }





  cosadddetails_save()
  {
    console.log(this.cosadddetails);
    this.cosadddetails.ownrshp_strat_code=this.cosadddetails.ownrshp_strat_code.toUpperCase();
    this.cosadddetails.cd_region=this.current_cos_region_code;
    // this.cosadddetails.cd_region="AP"
    this.cosadddetails.ownrshp_cat=this.selected_ownership.ownership;

    // this.cosadddetails.cost_info_entered_by=this.selected_approvalstage.stagename;
    this.cosadddetails.cost_info_entered_by=''; // emptying default value of costinfo entered by
    this.cosadddetails.fleet_cost_info=''; // emptying cost related info
    this.cosadddetails.proposal_grp_aprd_ind='No'; // setting proposal can be approved to no -- fix

    console.log(this.cosadddetails);
  //  var res="successs";

  this.addOwnershipStratCodeValidation();
  this.addOwnerDescValidation();
  this.addDaysBfrCutOffValidation();
  this.addMaxDaysNewPartSubValidation();
  this.addMaxDaysNewPreliRespValidation();
  this.addMaxDaysAddApproverRespValidation();
  this.addDaysAddToPlntRespUponReqForTstPckValidation();
  this.addMaxDaysForRelForEvalRespValidation();
  this.addMaxDaysForPlntRespValidation();
  this.addMaxDaysForPlntFinalRespValidation();
  this.addMaxDaysForRelForProdRespValidation();
  this.addEmergencyExpProposal();
  // this.addFleetCostInfo();
  this.addPackageSignOff();
  this.addIsCosActive();
  this.addSbuPressLoadDoc();
  this.addThirdPartyManaged();

  if(!this.ownershipStratCodeInValid && !this.ownerDescInValid && !this.daysBefCutOffInValid &&!this.maxDaysForNewPartSubInValid && !this.maxDaysForPreliminaryRespInValid
    && !this.maxDaysForAddApproverRespInValid && !this.daysAddToPlntRespUponReqForTestPackInValid && !this.maxDaysForReleaseForEvalRespInValid && !this.maxDaysForPlntRespInValid
    && !this.maxDaysForPlntFinalRespInValid && !this.maxDaysForReleaseForProdRespInValid && !this.emergencyExpProposalInValid && !this.packSignOffInValid
    && !this.sbuPressLoadDocInValid && !this.thirdPartyManagedInValid && !this.isCosActiveInValid) // removed && !this.fleetCostInfoInValid
    {
      this.transactionService.savecosdetails(this.cosadddetails).subscribe((saveplt => {
        // this.resultst=output;
        const res=saveplt;
        console.log('successful',res);
        if(res == 'Success'){

          this.messageService.add({severity:'success', summary:'Success', detail:'COS Added Successfully'});
          this.getcoslist();
          this.cosadddetails_init();
          this.cosInValidationInt();
        }

        else{
          this.cosInValidationInt();
          this.messageService.add({severity:'error', summary: 'Error', detail: 'COS Not Added,Please Try Again'});
          // this.addplantdetailsinit();
         }

        }

        ))
    }

  }


  disableadd():boolean // not used
  {
    let add_dis;
    let daysbfr_ttcutoff,maxdays_new_prt_sub,maxdays_prelim_resp,maxdays_add_aprvr_resp,maxdays_plt_resp,days_added_test_pack,maxdays_rel_eval_resp,maxdays_plt_final_resp,maxdays_rel_prod_resp;
    if(this.cosadddetails.daysbfr_ttcutoff!=null)
    {
      daysbfr_ttcutoff=true;
    }

    if(this.cosadddetails.maxdays_new_prt_sub!=null)
    {
      maxdays_new_prt_sub=true;
    }

    if(this.cosadddetails.maxdays_prelim_resp!=null)
    {
      maxdays_prelim_resp=true;
    }

    if(this.cosadddetails.maxdays_add_aprvr_resp!=null)
    {
      maxdays_add_aprvr_resp=true;
    }

    if(this.cosadddetails.maxdays_plt_resp!=null)
    {
      maxdays_plt_resp=true;
    }

    if(this.cosadddetails.days_added_test_pack!=null)
    {
      days_added_test_pack=true;
    }

    if(this.cosadddetails.maxdays_rel_eval_resp!=null)
    {
      maxdays_rel_eval_resp=true;
    }

    if(this.cosadddetails.maxdays_plt_final_resp!=null)
    {
      maxdays_plt_final_resp=true;
    }

    if(this.cosadddetails.maxdays_rel_prod_resp!=null)
    {
      maxdays_rel_prod_resp=true;
    }

    add_dis=!this.cosadddetails.ownrshp_strat_code || !this.cosadddetails.ownrshp_desc || !this.cosadddetails.ownrshp_cat||
    !this.cosadddetails.emg_exp_propsal || !this.cosadddetails.fleet_cost_info || !this.cosadddetails.cost_info_entered_by||
    !this.cosadddetails.pkg_sign_off || !this.cosadddetails.sub_press_load_doc || !this.cosadddetails.third_party_mngd_ind||!this.cosadddetails.cos_active_ind||
    !daysbfr_ttcutoff || !maxdays_new_prt_sub || !maxdays_prelim_resp||
    !maxdays_add_aprvr_resp || !maxdays_plt_resp || !days_added_test_pack||
    !maxdays_rel_eval_resp || !maxdays_plt_final_resp || !maxdays_rel_prod_resp;
// console.log(this.cosadddetails.cos_active_ind)
    // add_dis=!this.cosadddetails.cos_active_ind;
    console.log(add_dis);
    return add_dis

}



  cosgetdetails_init()
  {
    this.cosgetdetails={
      cd_ownrshp_strat:0,
      ownrshp_strat_code:'',
      ownrshp_desc:'',
      ownrshp_cat:' ',
      emg_exp_propsal:' ',
      ford_purchased_ind:' ',
      fleet_cost_info:' ',
      cost_info_entered_by:' ',
      pkg_sign_off:' ',
      sub_press_load_doc:' ',
      third_party_mngd_ind:' ',
      daysbfr_ttcutoff:null,
      maxdays_new_prt_sub:null,
      maxdays_prelim_resp:null,
      maxdays_add_aprvr_resp:null,
      maxdays_plt_resp:null,
      days_added_test_pack:null,
      maxdays_rel_eval_resp:null,
      maxdays_plt_final_resp:null,
      maxdays_rel_prod_resp:null,
      proposal_grp_aprd_ind:'No',
      acptnce_email_ind:'Yes',
      cos_active_ind:' ',
      cd_region:' ',
      rel_eval_mail_txt:' ',
      rel_prod_mail_txt:' '

    }
  }


  coseditdetails_init()
  {
    this.coseditdetails={
      cd_ownrshp_strat:0,
      ownrshp_strat_code:'',
      ownrshp_desc:' ',
      ownrshp_cat:' ',
      emg_exp_propsal:' ',
      ford_purchased_ind:' ',
      fleet_cost_info:' ',
      cost_info_entered_by:' ',
      pkg_sign_off:' ',
      sub_press_load_doc:' ',
      third_party_mngd_ind:' ',
      daysbfr_ttcutoff:0,
      maxdays_new_prt_sub:0,
      maxdays_prelim_resp:0,
      maxdays_add_aprvr_resp:0,
      maxdays_plt_resp:0,
      days_added_test_pack:0,
      maxdays_rel_eval_resp:0,
      maxdays_plt_final_resp:0,
      maxdays_rel_prod_resp:0,
      proposal_grp_aprd_ind:'No',
      acptnce_email_ind:'Yes',
      cos_active_ind:'',
      cd_region:' ',
      rel_eval_mail_txt:' ',
      rel_prod_mail_txt:' '

    }


    this.edit_prod_mail_temp=' ';
    this.edit_eval_mail_temp=' ';
  }


  getcosdetails(cosdata:any)
  {
    this.cosgetdetails_init();
    console.log(cosdata);
    this.enablecosaddbutton=false;
    this.enablecosadddetails=false;
    this.enablecosgeteditdetails=false;
    this.enablecoseditdetails=false;
    this.enablecosgetdetails=true;


    console.log(this.cosgetdetails);

    this.transactionService.getcosdetails(cosdata.ownrshp_strat_code,cosdata.cd_ownrshp_strat).subscribe((getcos => {
      // this.resultst=output;
      this.cosgetdetails=getcos;



        console.log(this.cosgetdetails);

        console.log('get plant details');
        console.log(cosdata.cd_ownrshp_strat);

        for(let i=0;i <this.cosaprovalstagelist.length;i++)
        {
          console.log(this.cosaprovalstagelist[i].stagename)
          console.log(this.cosgetdetails.cost_info_entered_by)
          if(this.cosgetdetails.cost_info_entered_by==this.cosaprovalstagelist[i].stagename)
          {

          this.get_selected_approvalstage=this.cosaprovalstagelist[i];
          }

        }

    console.log(this.get_selected_approvalstage);

        for(let i=0;i <this.cosownershiplist.length;i++)
        {
          console.log(this.cosownershiplist[i].ownership)
          console.log(this.cosgetdetails.ownrshp_cat)
          if(this.cosgetdetails.ownrshp_cat==this.cosownershiplist[i].ownership)
          {

          this.get_selected_ownership=this.cosownershiplist[i];
          }

        }


        console.log( this.get_selected_ownership)

      }




      ))





  }



cancel_getcosdetails()
{
  this.enablecosaddbutton=true;
  this.enablecosgetdetails=false;
  this.cosgetdetails_init();
  this.cosInValidationInt();
}


edit_getcosdetails()
{
  this.enablecosgetdetails=false;
  this.enablecosgeteditdetails=true;
this.coseditdetails_init();
this.cosInValidationInt();

console.log(this.cosgetdetails)
this.coseditdetails=JSON.parse(JSON.stringify(this.cosgetdetails))
 // this.coseditdetails=this.cosgetdetails;
  console.log(this.coseditdetails)

this.edit_eval_mail_temp=this.coseditdetails.rel_eval_mail_txt;
this.edit_prod_mail_temp=this.coseditdetails.rel_prod_mail_txt;


  console.log(this.coseditdetails.cd_ownrshp_strat);

        for(let i=0;i <this.cosaprovalstagelist.length;i++)
        {
          console.log(this.cosaprovalstagelist[i].stagename)
          console.log(this.coseditdetails.cost_info_entered_by)
          if(this.coseditdetails.cost_info_entered_by==this.cosaprovalstagelist[i].stagename)
          {

          this.edit_selected_approvalstage=this.cosaprovalstagelist[i];
          }

        }

    console.log(this.edit_selected_approvalstage);

        for(let i=0;i <this.cosownershiplist.length;i++)
        {
          console.log(this.cosownershiplist[i].ownership)
          console.log(this.coseditdetails.ownrshp_cat)
          if(this.coseditdetails.ownrshp_cat==this.cosownershiplist[i].ownership)
          {

          this.edit_selected_ownership=this.cosownershiplist[i];
          }

        }


        console.log( this.edit_selected_ownership)

        this.edit_isemailrequired()


}




viewOrDeleteAccess(ownrshpcode:any)
  {
    this.enableUserAccessForCOSAdmin=true;
    if(ownrshpcode!=null)
    {
      this.cosOpenedForEdit={plantcode:'',plantname:'',programcode:'',coscode:ownrshpcode,modify:true,calledFrom:'cosadmin'};
    }
  }

  closeViewOrDeleteAccessDialog()
  {
    this.enableUserAccessForCOSAdmin=false;
    this.current_routeid=3;

    if(this.current_cos_region=='Asia Pacific & Africa')
    {
      sessionStorage.setItem('aprouteid', JSON.stringify(this.current_routeid));
    }

    else if(this.current_cos_region=='Europe')
    {
      sessionStorage.setItem('eurouteid', JSON.stringify(this.current_routeid));
    }

    else if (this.current_cos_region=='North America')
    {
      sessionStorage.setItem('narouteid', JSON.stringify(this.current_routeid));
    }

    else if(this.current_cos_region=='South America')
    {
      sessionStorage.setItem('sarouteid', JSON.stringify(this.current_routeid));
    }
  }

edit_isemailrequired()
{
  console.log(this.cosadddetails.acptnce_email_ind)

  if(this.coseditdetails.acptnce_email_ind=='Yes')
  {
    this.edit_evalmailenable_button=false;
    this.edit_prodmailenable_button=false;
  }
  else{
    this.edit_evalmailenable_button=true;
    this.edit_prodmailenable_button=true;
  }
}




edit_evalmail_button()
{
this.edit_evalmailenable_dialog=true;
this.edit_eval_mail_temp=this.coseditdetails.rel_eval_mail_txt;

}


edit_evalmail_ok()
{
console.log(this.coseditdetails.rel_eval_mail_txt);
this.coseditdetails.rel_eval_mail_txt=this.edit_eval_mail_temp;
console.log(this.coseditdetails.rel_eval_mail_txt);
this.edit_evalmailenable_dialog=false;
}

edit_evalmail_cancel()
{
  this.edit_evalmailenable_dialog=false;
}





edit_prodmail_button()
{
  this.edit_prodmailenable_dialog=true;
  this.edit_prod_mail_temp=this.coseditdetails.rel_prod_mail_txt;
}


edit_prodmail_ok()
{
console.log(this.coseditdetails.rel_prod_mail_txt);
this.coseditdetails.rel_prod_mail_txt=this.edit_prod_mail_temp;
console.log(this.coseditdetails.rel_prod_mail_txt);
this.edit_prodmailenable_dialog=false;
}

  edit_prodmail_cancel()
  {
    this.edit_prodmailenable_dialog=false;
  }







edit_getcosdetails_cancel()
{
  this.enablecosgeteditdetails=false;
  this.coseditdetails_init();
  this.cosInValidationInt();
  this.enablecosgetdetails=true;
}


editcosdetails(cosrowdata:any)
{


  this.enablecosadddetails=false;
  this.enablecosaddbutton=false;
  this.enablecosgetdetails=false;
  this.enablecosgeteditdetails=false;
  this.enablecoseditdetails=true;

this.cosgetdetails_init();
this.cosInValidationInt();

  this.transactionService.getcosdetails(cosrowdata.ownrshp_strat_code,cosrowdata.cd_ownrshp_strat).subscribe((getcos => {
    // this.resultst=output;
    this.cosgetdetails=getcos;



      console.log(this.cosgetdetails);

      console.log('get plant details');
      console.log(cosrowdata.cd_ownrshp_strat);

      for(let i=0;i <this.cosaprovalstagelist.length;i++)
      {
        console.log(this.cosaprovalstagelist[i].stagename)
        console.log(this.cosgetdetails.cost_info_entered_by)
        if(this.cosgetdetails.cost_info_entered_by==this.cosaprovalstagelist[i].stagename)
        {

        this.get_selected_approvalstage=this.cosaprovalstagelist[i];
        }

      }

  console.log(this.get_selected_approvalstage);

      for(let i=0;i <this.cosownershiplist.length;i++)
      {
        console.log(this.cosownershiplist[i].ownership)
        console.log(this.cosgetdetails.ownrshp_cat)
        if(this.cosgetdetails.ownrshp_cat==this.cosownershiplist[i].ownership)
        {

        this.get_selected_ownership=this.cosownershiplist[i];
        }

      }


      console.log( this.get_selected_ownership);




    this.coseditdetails_init();

    console.log(this.cosgetdetails)
      this.coseditdetails=this.cosgetdetails;
      console.log(this.coseditdetails)

    this.edit_eval_mail_temp=this.coseditdetails.rel_eval_mail_txt;
    this.edit_prod_mail_temp=this.coseditdetails.rel_prod_mail_txt;


      console.log(this.coseditdetails.cd_ownrshp_strat);

            for(let i=0;i <this.cosaprovalstagelist.length;i++)
            {
              console.log(this.cosaprovalstagelist[i].stagename)
              console.log(this.coseditdetails.cost_info_entered_by)
              if(this.coseditdetails.cost_info_entered_by==this.cosaprovalstagelist[i].stagename)
              {

              this.edit_selected_approvalstage=this.cosaprovalstagelist[i];
              }

            }

        console.log(this.edit_selected_approvalstage);

            for(let i=0;i <this.cosownershiplist.length;i++)
            {
              console.log(this.cosownershiplist[i].ownership)
              console.log(this.coseditdetails.ownrshp_cat)
              if(this.coseditdetails.ownrshp_cat==this.cosownershiplist[i].ownership)
              {

              this.edit_selected_ownership=this.cosownershiplist[i];
              }

            }


            console.log( this.edit_selected_ownership)

            this.edit_isemailrequired()

    }
    ))
}

// Input Validation For Edit

editOwnerDescValidation(){
  if(this.coseditdetails.ownrshp_desc.length>=101)
{
  this.ownerDescInValid=true;
  this.ownerDescInValidReason='Only 100 characters are valid';
}
else if(this.coseditdetails.ownrshp_desc.length==null || this.coseditdetails.ownrshp_desc.trim()==''){
  this.ownerDescInValid=true;
  this.ownerDescInValidReason='Required';
}
else
{
  this.ownerDescInValid=false;
  this.ownerDescInValidReason=null
}
}
editDaysBfrCutOffValidation(){
  if(this.coseditdetails.daysbfr_ttcutoff<0 || this.coseditdetails.daysbfr_ttcutoff>=1000 || String(this.coseditdetails.daysbfr_ttcutoff).includes('.'))
{
  this.daysBefCutOffInValid=true;
  this.daysBefCutOffInValidReason='Only 1 to 999 numbers are allowed and it cannot contain Decimal';
}
else if(this.coseditdetails.daysbfr_ttcutoff==null || this.coseditdetails.daysbfr_ttcutoff==0){
  this.daysBefCutOffInValid=true;
  this.daysBefCutOffInValidReason='Required';
}
else
{
  this.daysBefCutOffInValid=false;
  this.daysBefCutOffInValidReason=null
}
}
editMaxDaysNewPartSubValidation(){
  if(this.coseditdetails.maxdays_new_prt_sub<0 || this.coseditdetails.maxdays_new_prt_sub>=100 || String(this.coseditdetails.maxdays_new_prt_sub).includes('.'))
{
  this.maxDaysForNewPartSubInValid=true;
  this.maxDaysForNewPartSubInValidReason='Only 1 to 99 numbers are allowed and it cannot contain Decimal';
}
else if(this.coseditdetails.maxdays_new_prt_sub==null || this.coseditdetails.maxdays_new_prt_sub==0){
  this.maxDaysForNewPartSubInValid=true;
  this.maxDaysForNewPartSubInValidReason='Required';
}
else
{
  this.maxDaysForNewPartSubInValid=false;
  this.maxDaysForNewPartSubInValidReason=null
}
}
editMaxDaysNewPreliRespValidation(){
  if(this.coseditdetails.maxdays_prelim_resp<0 || this.coseditdetails.maxdays_prelim_resp>=100 || String(this.coseditdetails.maxdays_prelim_resp).includes('.'))
{
  this.maxDaysForPreliminaryRespInValid=true;
  this.maxDaysForPreliminaryRespInValidReason='Only 1 to 99 numbers are allowed and it cannot contain Decimal';
}
else if(this.coseditdetails.maxdays_prelim_resp==null || this.coseditdetails.maxdays_prelim_resp==0){
  this.maxDaysForPreliminaryRespInValid=true;
  this.maxDaysForPreliminaryRespInValidReason='Required';
}
else
{
  this.maxDaysForPreliminaryRespInValid=false;
  this.maxDaysForPreliminaryRespInValidReason=null
}
}
editMaxDaysAddApproverRespValidation(){
  if(this.coseditdetails.maxdays_add_aprvr_resp<0 || this.coseditdetails.maxdays_add_aprvr_resp>=100 || String(this.coseditdetails.maxdays_add_aprvr_resp).includes('.'))
{
  this.maxDaysForAddApproverRespInValid=true;
  this.maxDaysForAddApproverRespInValidReason='Only 1 to 99 numbers are allowed and it cannot contain Decimal';
}
else if(this.coseditdetails.maxdays_add_aprvr_resp==null || this.coseditdetails.maxdays_add_aprvr_resp==0){
  this.maxDaysForAddApproverRespInValid=true;
  this.maxDaysForAddApproverRespInValidReason='Required';
}
else
{
  this.maxDaysForAddApproverRespInValid=false;
  this.maxDaysForAddApproverRespInValidReason=null
}
}
editDaysAddToPlntRespUponReqForTstPckValidation(){
  if(this.coseditdetails.days_added_test_pack<0 || this.coseditdetails.days_added_test_pack>=100 || String(this.coseditdetails.days_added_test_pack).includes('.'))
{
  this.daysAddToPlntRespUponReqForTestPackInValid=true;
  this.daysAddToPlntRespUponReqForTestPackInValidReason='Only 1 to 99 numbers are allowed and it cannot contain Decimal';
}
else if(this.coseditdetails.days_added_test_pack==null || this.coseditdetails.days_added_test_pack==0){
  this.daysAddToPlntRespUponReqForTestPackInValid=true;
  this.daysAddToPlntRespUponReqForTestPackInValidReason='Required';
}
else
{
  this.daysAddToPlntRespUponReqForTestPackInValid=false;
  this.daysAddToPlntRespUponReqForTestPackInValidReason=null
}
}
editMaxDaysForRelForEvalRespValidation(){
  if(this.coseditdetails.maxdays_rel_eval_resp<0 || this.coseditdetails.maxdays_rel_eval_resp>=100 || String(this.coseditdetails.maxdays_rel_eval_resp).includes('.'))
{
  this.maxDaysForReleaseForEvalRespInValid=true;
  this.maxDaysForReleaseForEvalRespInValidReason='Only 1 to 99 numbers are allowed and it cannot contain Decimal';
}
else if(this.coseditdetails.maxdays_rel_eval_resp==null || this.coseditdetails.maxdays_rel_eval_resp==0){
  this.maxDaysForReleaseForEvalRespInValid=true;
  this.maxDaysForReleaseForEvalRespInValidReason='Required';
}
else
{
  this.maxDaysForReleaseForEvalRespInValid=false;
  this.maxDaysForReleaseForEvalRespInValidReason=null
}
}
editMaxDaysForPlntRespValidation(){
  if(this.coseditdetails.maxdays_plt_resp<0 || this.coseditdetails.maxdays_plt_resp>=100 || String(this.coseditdetails.maxdays_plt_resp).includes('.'))
{
  this.maxDaysForPlntRespInValid=true;
  this.maxDaysForPlntRespInValidReason='Only 1 to 99 numbers are allowed and it cannot contain Decimal';
}
else if(this.coseditdetails.maxdays_plt_resp==null || this.coseditdetails.maxdays_plt_resp==0){
  this.maxDaysForPlntRespInValid=true;
  this.maxDaysForPlntRespInValidReason='Required';
}
else
{
  this.maxDaysForPlntRespInValid=false;
  this.maxDaysForPlntRespInValidReason=null
}
}
editMaxDaysForPlntFinalRespValidation(){
  if(this.coseditdetails.maxdays_plt_final_resp<0 || this.coseditdetails.maxdays_plt_final_resp>=100  || String(this.coseditdetails.maxdays_plt_final_resp).includes('.'))
{
  this.maxDaysForPlntFinalRespInValid=true;
  this.maxDaysForPlntFinalRespInValidReason='Only 1 to 99 numbers are allowed and it cannot contain Decimal';
}
else if(this.coseditdetails.maxdays_plt_final_resp==null || this.coseditdetails.maxdays_plt_final_resp==0){
  this.maxDaysForPlntFinalRespInValid=true;
  this.maxDaysForPlntFinalRespInValidReason='Required';
}
else
{
  this.maxDaysForPlntFinalRespInValid=false;
  this.maxDaysForPlntFinalRespInValidReason=null
}
}
editMaxDaysForRelForProdRespValidation(){
  if(this.coseditdetails.maxdays_rel_prod_resp<0 || this.coseditdetails.maxdays_rel_prod_resp>=100 || String(this.coseditdetails.maxdays_rel_prod_resp).includes('.'))
{
  this.maxDaysForReleaseForProdRespInValid=true;
  this.maxDaysForReleaseForProdRespInValidReason='Only 1 to 99 numbers are allowed and it cannot contain Decimal';
}
else if(this.coseditdetails.maxdays_rel_prod_resp==null || this.coseditdetails.maxdays_rel_prod_resp==0){
  this.maxDaysForReleaseForProdRespInValid=true;
  this.maxDaysForReleaseForProdRespInValidReason='Required';
}
else
{
  this.maxDaysForReleaseForProdRespInValid=false;
  this.maxDaysForReleaseForProdRespInValidReason=null
}
}
editEmergencyExpProposal(){
  if(this.coseditdetails.emg_exp_propsal==null || this.coseditdetails.emg_exp_propsal.trim()=='')
  {
    this.emergencyExpProposalInValid=true;
    this.emergencyExpProposalInValidReason='Required';
  }
  else{
    this.emergencyExpProposalInValid=false;
    this.emergencyExpProposalInValidReason=null;
  }
}
/* editFleetCostInfo(){
  if(this.coseditdetails.fleet_cost_info==null || this.coseditdetails.fleet_cost_info.trim()=="")
  {
    this.fleetCostInfoInValid=true;
    this.fleetCostInfoInValidReason="Required";
  }
  else{
    this.fleetCostInfoInValid=false;
    this.fleetCostInfoInValidReason=null;
  }
} */
editPackageSignOff(){
  if(this.coseditdetails.pkg_sign_off==null || this.coseditdetails.pkg_sign_off.trim()=='')
  {
    this.packSignOffInValid=true;
    this.packSignOffInValidReason='Required';
  }
  else{
    this.packSignOffInValid=false;
    this.packSignOffInValidReason=null;
  }
}
editSbuPressLoadDoc(){
  if(this.coseditdetails.sub_press_load_doc==null || this.coseditdetails.sub_press_load_doc.trim()=='')
  {
    this.sbuPressLoadDocInValid=true;
    this.sbuPressLoadDocInValidReason='Required';
  }
  else{
    this.sbuPressLoadDocInValid=false;
    this.sbuPressLoadDocInValidReason=null;
  }
}
editThirdPartyManaged(){
  if(this.coseditdetails.third_party_mngd_ind==null || this.coseditdetails.third_party_mngd_ind.trim()=='')
  {
    this.thirdPartyManagedInValid=true;
    this.thirdPartyManagedInValidReason='Required';
  }
  else{
    this.thirdPartyManagedInValid=false;
    this.thirdPartyManagedInValidReason=null;
  }
}
editIsCosActive(){
  if(this.coseditdetails.cos_active_ind==null || this.coseditdetails.cos_active_ind.trim()=='')
  {
    this.isCosActiveInValid=true;
    this.isCosActiveInvalidReason='Required';
  }
  else{
    this.isCosActiveInValid=false;
    this.isCosActiveInvalidReason=null;
  }
}



editcosdetails_cancel()
{
  this.enablecoseditdetails=false;
  this.coseditdetails_init();
  this.enablecosaddbutton=true;
  this.cosInValidationInt();
}

disableedit():boolean{
  let edit_dis;
  let daysbfr_ttcutoff,maxdays_new_prt_sub,maxdays_prelim_resp,maxdays_add_aprvr_resp,maxdays_plt_resp,days_added_test_pack,maxdays_rel_eval_resp,maxdays_plt_final_resp,maxdays_rel_prod_resp;
  if(this.coseditdetails.daysbfr_ttcutoff!=null)
  {
    daysbfr_ttcutoff=true;
  }

  if(this.coseditdetails.maxdays_new_prt_sub!=null)
  {
    maxdays_new_prt_sub=true;
  }

  if(this.coseditdetails.maxdays_prelim_resp!=null)
  {
    maxdays_prelim_resp=true;
  }

  if(this.coseditdetails.maxdays_add_aprvr_resp!=null)
  {
    maxdays_add_aprvr_resp=true;
  }

  if(this.coseditdetails.maxdays_plt_resp!=null)
  {
    maxdays_plt_resp=true;
  }

  if(this.coseditdetails.days_added_test_pack!=null)
  {
    days_added_test_pack=true;
  }

  if(this.coseditdetails.maxdays_rel_eval_resp!=null)
  {
    maxdays_rel_eval_resp=true;
  }

  if(this.coseditdetails.maxdays_plt_final_resp!=null)
  {
    maxdays_plt_final_resp=true;
  }

  if(this.coseditdetails.maxdays_rel_prod_resp!=null)
  {
    maxdays_rel_prod_resp=true;
  }

  edit_dis=!this.coseditdetails.ownrshp_strat_code || !this.coseditdetails.ownrshp_desc || !this.coseditdetails.ownrshp_cat||
  !this.coseditdetails.emg_exp_propsal || !this.coseditdetails.fleet_cost_info || !this.coseditdetails.cost_info_entered_by||
  !this.coseditdetails.pkg_sign_off || !this.coseditdetails.sub_press_load_doc || !this.coseditdetails.third_party_mngd_ind||!this.coseditdetails.cos_active_ind||
  !daysbfr_ttcutoff || !maxdays_new_prt_sub || !maxdays_prelim_resp||
  !maxdays_add_aprvr_resp || !maxdays_plt_resp || !days_added_test_pack||
  !maxdays_rel_eval_resp || !maxdays_plt_final_resp || !maxdays_rel_prod_resp;
console.log(this.coseditdetails.cos_active_ind)
  // add_dis=!this.cosadddetails.cos_active_ind;
  console.log(edit_dis);
  return edit_dis
}

editedcosdetails(cosalldata:any)
{


  console.log(cosalldata);
  this.enablecosaddbutton=false;
  this.enablecosadddetails=false;
  this.enablecosgeteditdetails=false;
  this.enablecoseditdetails=false;
  this.enablecosgetdetails=true;

  this.cosgetdetails_init();
  console.log(this.cosgetdetails);

  this.transactionService.getcosdetails(cosalldata.ownrshp_strat_code,cosalldata.cd_ownrshp_strat).subscribe((getcos => {
    // this.resultst=output;
    this.cosgetdetails=getcos;



      console.log(this.cosgetdetails);

      console.log('get plant details');
      console.log(cosalldata.cd_ownrshp_strat);

      for(let i=0;i <this.cosaprovalstagelist.length;i++)
      {
        console.log(this.cosaprovalstagelist[i].stagename)
        console.log(this.cosgetdetails.cost_info_entered_by)
        if(this.cosgetdetails.cost_info_entered_by==this.cosaprovalstagelist[i].stagename)
        {

        this.get_selected_approvalstage=this.cosaprovalstagelist[i];
        }

      }

  console.log(this.get_selected_approvalstage);

      for(let i=0;i <this.cosownershiplist.length;i++)
      {
        console.log(this.cosownershiplist[i].ownership)
        console.log(this.cosgetdetails.ownrshp_cat)
        if(this.cosgetdetails.ownrshp_cat==this.cosownershiplist[i].ownership)
        {

        this.get_selected_ownership=this.cosownershiplist[i];
        }

      }


      console.log( this.get_selected_ownership)

    }




    ))

}

edit_savecosdetails()
{

  console.log(this.coseditdetails);


  this.coseditdetails.ownrshp_cat=this.edit_selected_ownership.ownership
  // this.coseditdetails.cost_info_entered_by=this.edit_selected_approvalstage.stagename
  this.coseditdetails.cost_info_entered_by=''; // Emptying Cost Info Must be entered by field
  this.coseditdetails.fleet_cost_info='';// Emptying cost fields
  this.coseditdetails.proposal_grp_aprd_ind='No' // Setting default to no
// this.editdetails.plant_type_name="vo";
console.log('editdata',this.coseditdetails);


  this.editOwnerDescValidation();
  this.editDaysBfrCutOffValidation();
  this.editMaxDaysNewPartSubValidation();
  this.editMaxDaysNewPreliRespValidation();
  this.editMaxDaysAddApproverRespValidation();
  this.editDaysAddToPlntRespUponReqForTstPckValidation();
  this.editMaxDaysForRelForEvalRespValidation();
  this.editMaxDaysForPlntRespValidation();
  this.editMaxDaysForPlntFinalRespValidation();
  this.editMaxDaysForRelForProdRespValidation();
  this.editEmergencyExpProposal();
  // this.editFleetCostInfo();
  this.editPackageSignOff();
  this.editIsCosActive();
  this.editSbuPressLoadDoc();
  this.editThirdPartyManaged();


if(!this.ownershipStratCodeInValid && !this.ownerDescInValid && !this.daysBefCutOffInValid &&!this.maxDaysForNewPartSubInValid &&
  !this.maxDaysForPreliminaryRespInValid && !this.maxDaysForAddApproverRespInValid && !this.daysAddToPlntRespUponReqForTestPackInValid &&
  !this.maxDaysForReleaseForEvalRespInValid && !this.maxDaysForPlntRespInValid && !this.maxDaysForPlntFinalRespInValid &&
  !this.maxDaysForReleaseForProdRespInValid && !this.emergencyExpProposalInValid && !this.packSignOffInValid &&
  !this.sbuPressLoadDocInValid && !this.thirdPartyManagedInValid && !this.isCosActiveInValid) // removed  && !this.fleetCostInfoInValid
 {
  this.transactionService.savecosdetails(this.coseditdetails).subscribe((saveeditcos=> {
    // this.resultst=output;
    const res=saveeditcos;
    console.log('successful',res);
    if(res == 'Success'){

      this.messageService.add({severity:'success', summary:'Success', detail:'COS Edited Successfully'});
      this.enablecoseditdetails=false;
    this.enablecosgeteditdetails=false
    this.editedcosdetails(this.coseditdetails);
    this.getcoslist();
    this.cosInValidationInt();
    }

    else{
      this.cosInValidationInt();
      this.messageService.add({severity:'error', summary: 'Error', detail: 'COS Not Edited,Please Try Again'});
      // this.addplantdetailsinit();
     }

    }

    ))
  }

}



}
