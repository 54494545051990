/* tslint:disable */
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, Dropdown, MessageService, Table } from 'primeng';
import { TransactionService } from '../loginpage/Transaction.service';
import { containerGroupList, containerGroupWithActiveField, containerMasterCategory, contmastcateg, corrosionProtectionForGet, corrosionProtectionForPost, pinpallettype, pinPalletTypeForGet, planttype, planttypeForGet, planttypeForPost, transportListForUI, transportListWithActiveStatus } from '../loginpage/transactions';

@Component({
  selector: 'app-otherconfig',
  templateUrl: './otherconfig.component.html',
  styleUrls: ['./otherconfig.component.css'],
  providers: [ConfirmationService,MessageService,DatePipe]
})
export class OtherconfigComponent implements OnInit {




  cdsisdConfig='';
  regionConfig='';
  regionCodeConfig='';



  changeListValue='';
  showPlanttype=false;
  showCntrGrpGrid=false;
  showTransportGrid=false;
  showCorrosionProtection=false;
  showContainerMasterCategory=false;
  showPalletPinType=false;



  plantypeList:planttypeForGet[]=[];
  beforeUpdatePlanttypeList:planttypeForGet[]=[];
  updateActiveStatPlanttypeList:planttypeForPost[]=[];
  viewPlanttypeData:planttypeForGet={cd_plant_type:0,plant_type_name:'',active_stat:'',updated_by:'',updated_dt:'',active_stat_display:false};
  viewPlanttypeDialog=false;

  addPlanttypeData:planttypeForPost={cd_plant_type:0,plant_type_name:'',active_stat:'',updated_by:'',updated_dt:''};
  editPlanttypeData:planttypeForPost={cd_plant_type:0,plant_type_name:'',active_stat:'',updated_by:'',updated_dt:''};
  addPlanttypeDataForPost:planttypeForPost[]=[]
  editPlanttypeDataForPost:planttypeForPost[]=[]
  displayAddPlanttype=false;
  displayEditPlanttype=false;
  displayAddPlanttypeButtonEnable=false;
  displayUpdateActiveStatusPlanttypeButtonEnable=false;


  corrosionProtectionList:corrosionProtectionForGet[]=[];
  beforeUpdateCorrosionProtectionList:corrosionProtectionForGet[]=[];
  updateActiveStatCorrosionProtectionList:corrosionProtectionForPost[]=[];
  viewCorrosionProtectionData:corrosionProtectionForGet={cd_corsn_prot_type:0,corsn_prot_type:'',active_stat:'',updated_by:'',updated_dt:'',active_stat_display:false};
  viewCorrosionProtectionDialog=false;

  addCorrosionProtectionData:corrosionProtectionForPost={cd_corsn_prot_type:0,corsn_prot_type:'',active_stat:'',updated_by:'',updated_dt:''};
  editCorrosionProtectionData:corrosionProtectionForPost={cd_corsn_prot_type:0,corsn_prot_type:'',active_stat:'',updated_by:'',updated_dt:''};
  addCorrosionProtectionForPost:corrosionProtectionForPost[]=[]
  editCorrosionProtectionForPost:corrosionProtectionForPost[]=[]
  displayAddCorrosionProtection=false;
  displayEditCorrosionProtection=false;
  displayAddCorrosionProtectionButtonEnable=false;
  displayUpdateActiveStatusCorrosionProtectionButtonEnable=false;


  public planttypeCols:any[];
  public corrosionProtectionCols:any[];
  cntrGrpCols:any;
  transListCols:any;

  containerGroupGrid:containerGroupWithActiveField[];
  oldContainerGroup:containerGroupWithActiveField[];

  transportListGrid:transportListForUI[]=[];
  oldTransportListGrid:transportListForUI[]=[];

  addContainerGroupButtonEnable=false;
  addContainerGroupDetailsEnable=false;
  editContainerGroupEnable=false;
  containerGroupDetailsPopUpEnable=false;

  addTransportListButtonEnable=false;
  addTransportListDetailsEnable=false;
  editTransportListEnable=false;
  TransportListDetailsPopUpEnable=false;


  addContainerGroup:containerGroupList={cd_cntr_grp:'',cntr_grp:'',cntr_grp_desc:'',cd_region:'',active_stat:'',updated_by:'',updated_dt:''};
  editContainerGroup:containerGroupList={cd_cntr_grp:'',cntr_grp:'',cntr_grp_desc:'',cd_region:'',active_stat:'',updated_by:'',updated_dt:''};
  containerGroupDetails:containerGroupList={cd_cntr_grp:'',cntr_grp:'',cntr_grp_desc:'',cd_region:'',active_stat:'',updated_by:'',updated_dt:''};
  updateCntrGroup:containerGroupList[];

  addTransportList:transportListWithActiveStatus={cd_transport:0,transport_desc:'',active_stat:'',updated_by:'',updated_dt:''};
  editTransportList:transportListWithActiveStatus={cd_transport:0,transport_desc:'',active_stat:'',updated_by:'',updated_dt:''};
  transportDetails:transportListWithActiveStatus={cd_transport:0,transport_desc:'',active_stat:'',updated_by:'',updated_dt:''};
  updateTransportList:transportListWithActiveStatus[]=[];

  activeStatusFilterPlantType=[{label:'All',value:null},{label:'Active',value:true},{label:'In-Active',value:false}]
  activeStatusFilterCntrGrp=[{label:'All',value:null},{label:'Active',value:true},{label:'In-Active',value:false}]
  activeStatusFilterCorrProct=[{label:'All',value:null},{label:'Active',value:true},{label:'In-Active',value:false}]
  activeStatusFilterTransport=[{label:'All',value:null},{label:'Active',value:true},{label:'In-Active',value:false}]
  activeStatusFilterMastCatg=[{label:'All',value:null},{label:'Active',value:true},{label:'In-Active',value:false}]
  activeStatusFilterPalletPin=[{label:'All',value:null},{label:'Active',value:true},{label:'In-Active',value:false}]


  showcontmastcateg = false;
  showpinpallettype = false;

  public contmastcategcols: any[];
  public pinpalletypecols: any[];

  containergrid: containerMasterCategory[] = [];// var for grid
  containerdets: contmastcateg;// var for details
  showcontainerdets:contmastcateg={cd_cntr_mstr_catg:0,cntr_mstr_catg:'',cntr_mstr_catg_desc:'',updated_by:'',updated_dt:'',active_stat:''};
  showcontr: contmastcateg;
  addcontainerdets: contmastcateg;
  addcontainerdetsArray :contmastcateg[]=[];
  editcontainerdetsArray :contmastcateg[]=[];
  editcontainerdets: contmastcateg;
  copycontainerdets: contmastcateg;
  contAddEnable:boolean;
  contAddDetEnable:boolean;
  contDetEnable:boolean;
  enableEditGetDet:boolean;
  enableEditContDet:boolean;
  enabledets:boolean;
  beforeupdatedcontdets:containerMasterCategory[]=[];


  palletpingrid:pinPalletTypeForGet[]=[];
  showpalletpindets:pinpallettype={cd_pinpallettyp: 0,pinpallettyp: '',pinpallettyp_desc:'',active_stat:'',updated_by:'',updated_dt:''}
  showpallet: pinpallettype;
  editpalletdets: pinpallettype;
  addpalletdets: pinpallettype;
  editpalletdetsArray: pinpallettype[]=[];
  addpalletdetsArray: pinpallettype[]=[];
  PinPalletDetEnable: boolean;
  enableEditPalletDets: boolean;
  palletAddEnable: boolean;
  palletDetEnable: boolean;
  enableEditPalletGetEnable: boolean;
  palletAddDetEnable: boolean;
  beforeupdateddets: pinPalletTypeForGet[]=[];




  contmastcategPopUpEnable = false;
  PinPalletPopUpEnable = false;
 // enableNotificationDialog:boolean = false;
  notificationText='';

  cont_cdsid='';
  curr_cont_region='';
  current_reg_cont_code=''




  current_routeid=0;

  palletinitdets: { cd_pinpallettyp: number; pinpallettyp: string; pinpallettyp_desc: string; active_stat: string; updated_by: string; updated_dt: string; };






  constructor(private transactionService:TransactionService,private router:Router, private date:DatePipe, private messageService:MessageService, private confirmationService:ConfirmationService) { }

  ngOnInit(): void {



    this.cdsisdConfig=this.transactionService.getcdsid();
    this.regionConfig=this.transactionService.getregion();
    this.regionCodeConfig=this.transactionService.getregioncode(this.regionConfig);
    this.current_routeid=152;

    console.log(this.current_routeid)


    if(this.regionConfig=='Asia Pacific & Africa')
    {
      sessionStorage.setItem('aprouteid', JSON.stringify(this.current_routeid));
    }

    else if(this.regionConfig=='Europe')
    {
      sessionStorage.setItem('eurouteid', JSON.stringify(this.current_routeid));
    }

    else if (this.regionConfig=='North America')

    {

      sessionStorage.setItem('narouteid', JSON.stringify(this.current_routeid));
    }

    else if(this.regionConfig=='South America')
    {
      sessionStorage.setItem('sarouteid', JSON.stringify(this.current_routeid));
    }



    // till access is checked
    this.addContainerGroupInit();
    this.editContainerGroupInit();
    this.cntrGroupDtlsInit();

    this.addTransportListInit();
    this.editTransportListInit();
    this.transportDetailsInit();


    this.addPlanttypeDataInit();
    this.editPlanttypeDataInit();
    this.viewPlanttypeDataInit();


    this.addCorrosionProtectionDataInit();
    this.editCorrosionProtectionDataInit();
    this.viewCorrosionProtectionDataInit();


    this.containerdetailsinit();
    this.containeraddinit();
    this.containereditinit();










    this.palletinitdetails();
    this.palletaddinit();
    this.palleteditinit();

    this.changeListValue = 'containergroup';
    this.changeOfDisplay();







  }




  changeOfDisplay()
{
  console.log(this.changeListValue);

  if(this.changeListValue.toLowerCase()=='containergroup')
  {

    this.showCntrGrpGrid=true;
    this.showPlanttype=false;
    this.showTransportGrid=false;
    this.showCorrosionProtection=false;
    this.showContainerMasterCategory=false;
    this.showPalletPinType=false;

    this.addContainerGroupButtonEnable=true;
    this.addContainerGroupDetailsEnable=false;
    this.containerGroupDetailsPopUpEnable=false;
    this.editContainerGroupEnable=false;

    this.getContainerGroupGrid();
  }
  else if(this.changeListValue.toLowerCase() == 'planttype')
  {
    this.showPlanttype=true;
    // other show false
    this.getRegionPlanttypeData();
    this.displayAddPlanttypeButtonEnable=true;
    this.displayAddPlanttype=false;
     this.displayEditPlanttype=false;
     this.viewPlanttypeDialog=false;
     this.showContainerMasterCategory=false;
     this.showPalletPinType=false;


     this.showCorrosionProtection=false;
     this.showCntrGrpGrid=false;
     this.showTransportGrid=false;
  }
  // corrosion protection

  else if(this.changeListValue.toLowerCase() == 'corrosion protection')
  {
    this.showCorrosionProtection=true;
    // other show false
    this.getRegionCorrosionProtectionData();
    this.displayAddCorrosionProtectionButtonEnable=true;
    this.displayAddCorrosionProtection=false;
     this.displayEditCorrosionProtection=false;
     this.viewCorrosionProtectionDialog=false;


     this.showPlanttype=false;
     this.showCntrGrpGrid=false;
    this.showTransportGrid=false;
    this.showContainerMasterCategory=false;
    this.showPalletPinType=false;
  }


  else if(this.changeListValue.toLowerCase()=='transport')
  {
    this.showCntrGrpGrid=false;
    this.showPlanttype=false;
    this.showCorrosionProtection=false;
    this.showTransportGrid=true;
    this.showContainerMasterCategory=false;
    this.showPalletPinType=false;

    this.addTransportListButtonEnable=true;
    this.addTransportListDetailsEnable=false;
    this.TransportListDetailsPopUpEnable=false;
    this.editTransportListEnable=false;

    this.getTransportGrid();
  }
  else if(this.changeListValue.toLowerCase()=='container master category')
  {
    this.showCntrGrpGrid=false;
    this.showPlanttype=false;
    this.showCorrosionProtection=false;
    this.showTransportGrid=false;
    this.showContainerMasterCategory=true;
    this.showPalletPinType=false;


    // this.DisplayPopup(this.showcontainerdets);
    this.contAddDetEnable=false;
    this.contAddEnable=true;
    this.contDetEnable=false;
    this.enableEditGetDet=false;
    this.enableEditContDet=false;


    this.populategrid();


  }
  else if(this.changeListValue.toLowerCase()=='pallet pin type')
  {
    this.showCntrGrpGrid=false;
    this.showPlanttype=false;
    this.showCorrosionProtection=false;
    this.showTransportGrid=false;
    this.showContainerMasterCategory=false;
    this.showPalletPinType=true;




    this.palletAddEnable = true;
    this.palletAddDetEnable=false;
    this.palletDetEnable=false;
    this.enableEditPalletGetEnable=false;
    this.enableEditPalletDets=false;


    this.populatepalletgrid();
  }

}


/*planttype ------------------------------------------------------------------------------------------------*/
getRegionPlanttypeData()
{

this.transactionService.getAllPlantType().subscribe(outputPlanttypeList=>{

 this.plantypeList=[];
 this.plantypeList=outputPlanttypeList;

 console.log('List of plant type - get',this.plantypeList);
 if(this.plantypeList != null && this.plantypeList.length != 0)
 {
   this.displayUpdateActiveStatusPlanttypeButtonEnable=true;

   for(let ptlst = 0; ptlst < this.plantypeList.length; ptlst++)
   {



    if(this.plantypeList[ptlst].active_stat != null && this.plantypeList[ptlst].active_stat.toLowerCase() == 'yes')
    {
      this.plantypeList[ptlst].active_stat_display=true;
    }
    else
    {
      this.plantypeList[ptlst].active_stat_display=false;
    }

   }


   this.beforeUpdatePlanttypeList=JSON.parse(JSON.stringify(this.plantypeList));
 }


 else
 {
   this.displayUpdateActiveStatusPlanttypeButtonEnable=false;
 }


this.planttypeCols=[
  { field: 'plant_type_name', header:'Plant Type'},
  {field:'active_stat_display',header:'Active'}
];


})


}



updateActiveStatusPlanttypeList()
{
  this.updateActiveStatPlanttypeList=[];
  console.log(this.plantypeList);
  console.log(this.beforeUpdatePlanttypeList);



  for(let updplt = 0; updplt < this.plantypeList.length; updplt++)
  {

    if(this.plantypeList[updplt].active_stat_display != this.beforeUpdatePlanttypeList[updplt].active_stat_display)
    {
      if(this.plantypeList[updplt].active_stat_display == true)
      {
        this.plantypeList[updplt].active_stat='yes'
      }
      else
      {
        this.plantypeList[updplt].active_stat='no'
      }
      this.updateActiveStatPlanttypeList.push({
          cd_plant_type:this.plantypeList[updplt].cd_plant_type,
          plant_type_name:this.plantypeList[updplt].plant_type_name,
          active_stat:this.plantypeList[updplt].active_stat,
          updated_by:this.cdsisdConfig,
          updated_dt:this.date.transform(new Date(), 'yyyy-MM-dd hh:mm:ss')
      })
    }
  }


  console.log('Updated data for active status',this.updateActiveStatPlanttypeList);


  // api to call save


  this.transactionService.postPlanttypeDetails(this.updateActiveStatPlanttypeList).subscribe(updactivestatusPlanttypePostOutput=>{

    if(updactivestatusPlanttypePostOutput.toLowerCase() == 'success')
    {
    this.messageService.add({severity:'success', summary:'Success', detail:' Active Status Updated Successfully'});
    this.getRegionPlanttypeData();
    this.displayAddPlanttype=false;
    this.displayEditPlanttype=false;
    this.displayAddPlanttypeButtonEnable=true;
    this.displayUpdateActiveStatusPlanttypeButtonEnable=true;
    }
    else
    {
      this.messageService.add({severity:'error', summary: 'Error', detail: 'Please Try Again'});
      this.getRegionPlanttypeData();
      this.displayAddPlanttype=false;
      this.displayEditPlanttype=false;
      this.displayAddPlanttypeButtonEnable=true;
      this.displayUpdateActiveStatusPlanttypeButtonEnable=true;

    }

  })



}


cancelUpdateActiveStatusPlanttypeList()
{
  console.log(this.plantypeList);
  console.log(this.beforeUpdatePlanttypeList);
  let planttypeChangePresent=false;
  for(let updplt = 0; updplt < this.plantypeList.length; updplt++)
  {
   if(this.plantypeList[updplt].active_stat_display != this.beforeUpdatePlanttypeList[updplt].active_stat_display)
    {
          planttypeChangePresent=true;
          break;

    }
  }
  if(planttypeChangePresent == true)
  {
  this.confirmationService.confirm({
    message: 'Are you sure you want to cancel the changes made for active status ?',
    header: 'Confirm',
    icon: 'pi pi-exclamation-triangle',
    accept: () =>
    {

        this.getRegionPlanttypeData();
        this.displayAddPlanttype=false;
        this.displayEditPlanttype=false;
        this.displayAddPlanttypeButtonEnable=true;
        this.displayUpdateActiveStatusPlanttypeButtonEnable=true;
    }
  })
}


}


viewExistPlanttypeDataDialog(rowDataShowPlanttype:any)
{


  console.log('user data display in pop up planttype',rowDataShowPlanttype);
   this.viewPlanttypeDataInit();

  this.viewPlanttypeData.plant_type_name=rowDataShowPlanttype.plant_type_name;
  this.viewPlanttypeData.cd_plant_type=rowDataShowPlanttype.cd_plant_type;
  this.viewPlanttypeData.active_stat_display=rowDataShowPlanttype.active_stat_display;
  if(rowDataShowPlanttype.active_stat_display != null && rowDataShowPlanttype.active_stat_display == true)
  {
    this.viewPlanttypeData.active_stat='yes'
  }
  else
  {
    this.viewPlanttypeData.active_stat='no'

  }


  this.viewPlanttypeDialog=true;


}


onCloseviewExistPlanttypeDataDialog()
{

  this.viewPlanttypeDialog=false;
  this.viewPlanttypeDataInit();

}

addPlanttypeDataInit()
{
  this.addPlanttypeData=
  {
    cd_plant_type:0,
    plant_type_name:'',
    active_stat:'',
    updated_by:'',
    updated_dt:''
  };
}


editPlanttypeDataInit()
{
  this.editPlanttypeData=
  {
    cd_plant_type:0,
    plant_type_name:'',
    active_stat:'',
    updated_by:'',
    updated_dt:''
  };
}


viewPlanttypeDataInit()
{
  this.viewPlanttypeData=
  {
    cd_plant_type:0,
    plant_type_name:'',
    active_stat:'',
    updated_by:'',
    updated_dt:'',
    active_stat_display:false
  };
}


addPlanttypeButton()
{
  this.displayAddPlanttype=true;
  this.displayAddPlanttypeButtonEnable=false;
  this.displayEditPlanttype=false;
  this.displayUpdateActiveStatusPlanttypeButtonEnable=false;
  this.addPlanttypeDataInit();
}

saveNewPlanttype()
{
  console.log('User entered plant type save detail',this.addPlanttypeData);

  this.addPlanttypeData.updated_by=this.cdsisdConfig;
  this.addPlanttypeData.cd_plant_type=0;
 // this.addPlanttypeData.updated_dt=new Date().toISOString();   //output format :YYYY-MM-DDTHH:mm:ss. sssZ
  this.addPlanttypeData.updated_dt=this.date.transform(new Date(), 'yyyy-MM-dd hh:mm:ss');


  this.addPlanttypeDataForPost=[];
  this.addPlanttypeDataForPost.push(this.addPlanttypeData);


  console.log(this.addPlanttypeDataForPost)
  // service to post new plant type


  this.transactionService.postPlanttypeDetails(this.addPlanttypeDataForPost).subscribe(addPlanttypePostOutput=>{


    console.log('add plant type api output',addPlanttypePostOutput)
    if(addPlanttypePostOutput.toLowerCase() == 'success')
    {
    this.messageService.add({severity:'success', summary:'Success', detail:' Planttype Added Successfully'});
    this.getRegionPlanttypeData();
    this.addPlanttypeDataInit();
    this.displayAddPlanttype=false;
    this.displayEditPlanttype=false;
    this.displayAddPlanttypeButtonEnable=true;
    this.displayUpdateActiveStatusPlanttypeButtonEnable=true;
    }
    else
    {
      this.messageService.add({severity:'error', summary: 'Error', detail: 'Please Try Again'});

    }

  })





}


cancelNewPlanttype()
{
  this.addPlanttypeDataInit();
  this.displayAddPlanttype=false;
  this.displayEditPlanttype=false;
  this.displayAddPlanttypeButtonEnable=true;
  this.displayUpdateActiveStatusPlanttypeButtonEnable=true;

}


disableSaveNewPlanttype():boolean
{
  if(this.addPlanttypeData.plant_type_name != null && this.addPlanttypeData.plant_type_name.trim() != ''
     && this.addPlanttypeData.active_stat != null && this.addPlanttypeData.active_stat != '' )
  {
    return false;
  }
  else
  {
    return true;
  }
}


editPlanttypeButton(rowDataEditPlanttype:any)
{
  console.log('Row Data Plant type edit',rowDataEditPlanttype);
  this.displayEditPlanttype=true;
  this.displayAddPlanttypeButtonEnable=false;
  this.displayAddPlanttype=false;
  this.displayUpdateActiveStatusPlanttypeButtonEnable=false;
  this.editPlanttypeDataInit();
  this.editPlanttypeData.cd_plant_type=rowDataEditPlanttype.cd_plant_type;
  this.editPlanttypeData.plant_type_name=rowDataEditPlanttype.plant_type_name;
  // this.editPlanttypeData.active_stat=rowDataEditPlanttype.active_stat.toLowerCase();

  if(rowDataEditPlanttype.active_stat_display != null && rowDataEditPlanttype.active_stat_display == true)
  {
    this.editPlanttypeData.active_stat='yes'
  }
  else
  {
    this.editPlanttypeData.active_stat='no'

  }

}

saveEditExistPlanttype()
{
  console.log('User entered plant type edit save detail ',this.editPlanttypeData);

  this.editPlanttypeData.updated_by=this.cdsisdConfig;
 // this.addPlanttypeData.updated_dt=new Date().toISOString();   //output format :YYYY-MM-DDTHH:mm:ss. sssZ
  this.editPlanttypeData.updated_dt=this.date.transform(new Date(), 'yyyy-MM-dd hh:mm:ss');


  this.editPlanttypeDataForPost=[];
  this.editPlanttypeDataForPost.push(this.editPlanttypeData);


  // service to post new plant type


  this.transactionService.postPlanttypeDetails(this.editPlanttypeDataForPost).subscribe(editPlanttypePostOutput=>{


    if(editPlanttypePostOutput.toLowerCase() == 'success')
      {
      this.messageService.add({severity:'success', summary:'Success', detail:' Planttype Edited Successfully'});
      this.getRegionPlanttypeData();
      this.editPlanttypeDataInit();
      this.displayAddPlanttype=false;
      this.displayEditPlanttype=false;
      this.displayAddPlanttypeButtonEnable=true;
      this.displayUpdateActiveStatusPlanttypeButtonEnable=true;
      }
      else
      {
        this.messageService.add({severity:'error', summary: 'Error', detail: 'Please Try Again'});

      }

  })






}


cancelEditExistPlanttype()
{
  this.editPlanttypeDataInit();
  this.displayAddPlanttype=false;
  this.displayEditPlanttype=false;
  this.displayAddPlanttypeButtonEnable=true;
  this.displayUpdateActiveStatusPlanttypeButtonEnable=true;

}


disableSaveEditExistPlanttype():boolean
{
  if(this.editPlanttypeData.plant_type_name != null && this.editPlanttypeData.plant_type_name.trim() != ''
     && this.editPlanttypeData.active_stat != null && this.editPlanttypeData.active_stat != '' )
  {
    return false;
  }
  else
  {
    return true;
  }
}



deletePlanttypeButton(rowDataDeletePlanttype:any)
{
  console.log('Row Data Plant type delete',rowDataDeletePlanttype);
  this.displayAddPlanttype=false;
  this.displayEditPlanttype=false;
  this.displayAddPlanttypeButtonEnable=true;
  this.displayUpdateActiveStatusPlanttypeButtonEnable=true;

  this.confirmationService.confirm({
    message: 'Are you sure you want to delete Planttype ' + '"'+rowDataDeletePlanttype.plant_type_name+'"' + '?',
    header: 'Confirm',
    icon: 'pi pi-exclamation-triangle',
    accept: () =>
    {
     // api to delete plant type
        let deletePlanttypeOutput
        console.log('Success',deletePlanttypeOutput);

        if(deletePlanttypeOutput.toLowerCase() == 'success')
        {
          this.messageService.add({severity:'success',summary:'Success',detail:'Planttype Deleted Successfully'})
          this.getRegionPlanttypeData();
        }
        else
        {
          this.messageService.add({severity:'error',summary:'Error',detail:'Try Again!'});

        }

    }
  });

}



/*---------------------- corrosion protection type ---------------------------------------------------*/


getRegionCorrosionProtectionData()
{

// service to get plant type list



this.transactionService.getAllCorrosionProtectionType().subscribe(corrosionListOutput=>{

 this.corrosionProtectionList=[]
 this.corrosionProtectionList=corrosionListOutput;


 console.log('get corrosion details data from api',this.corrosionProtectionList);
 if(this.corrosionProtectionList != null && this.corrosionProtectionList.length != 0)
 {
   this.displayUpdateActiveStatusCorrosionProtectionButtonEnable=true;

   for(let cplst = 0; cplst < this.corrosionProtectionList.length; cplst++)
   {

    if(this.corrosionProtectionList[cplst].active_stat.toLowerCase() == 'yes')
    {
      this.corrosionProtectionList[cplst].active_stat_display=true;
    }
    else
    {
      this.corrosionProtectionList[cplst].active_stat_display=false;
    }

   }


   this.beforeUpdateCorrosionProtectionList=JSON.parse(JSON.stringify(this.corrosionProtectionList));
 }


 else
 {
   this.displayUpdateActiveStatusCorrosionProtectionButtonEnable=false;
 }


this.corrosionProtectionCols=[
  { field: 'corsn_prot_type', header:'Corrosion Protection Type'},
  {field:'active_stat_display',header:'Active'}
];


})


}



updateActiveStatusCorrosionProtectionList()
{
  this.updateActiveStatCorrosionProtectionList=[];
  console.log(this.corrosionProtectionList);
  console.log(this.beforeUpdateCorrosionProtectionList);



  for(let updcp = 0; updcp < this.corrosionProtectionList.length; updcp++)
  {

    if(this.corrosionProtectionList[updcp].active_stat_display != this.beforeUpdateCorrosionProtectionList[updcp].active_stat_display)
    {
      if(this.corrosionProtectionList[updcp].active_stat_display == true)
      {
        this.corrosionProtectionList[updcp].active_stat='yes'
      }
      else
      {
        this.corrosionProtectionList[updcp].active_stat='no'
      }
      this.updateActiveStatCorrosionProtectionList.push({
          cd_corsn_prot_type:this.corrosionProtectionList[updcp].cd_corsn_prot_type,
          corsn_prot_type:this.corrosionProtectionList[updcp].corsn_prot_type,
          active_stat:this.corrosionProtectionList[updcp].active_stat,
          updated_by:this.cdsisdConfig,
          updated_dt:this.date.transform(new Date(), 'yyyy-MM-dd hh:mm:ss')
      })
    }
  }


  console.log('Updated data for active status for corrosion protection',this.updateActiveStatCorrosionProtectionList);


  // api to call save
  this.transactionService.postCorrosionDetails(this.updateActiveStatCorrosionProtectionList).subscribe(updactivestatusCorrosionProtectionPostOutput=>{
        if(updactivestatusCorrosionProtectionPostOutput.toLowerCase() == 'success')
        {
        this.messageService.add({severity:'success', summary:'Success', detail:' Active Status Updated Successfully'});
        this.getRegionCorrosionProtectionData();
        this.displayAddCorrosionProtection=false;
        this.displayEditCorrosionProtection=false;
        this.displayAddCorrosionProtectionButtonEnable=true;
        this.displayUpdateActiveStatusCorrosionProtectionButtonEnable=true;
        }
        else
        {
          this.messageService.add({severity:'error', summary: 'Error', detail: 'Please Try Again'});
          this.getRegionCorrosionProtectionData();
          this.displayAddCorrosionProtection=false;
          this.displayEditCorrosionProtection=false;
          this.displayAddCorrosionProtectionButtonEnable=true;
          this.displayUpdateActiveStatusCorrosionProtectionButtonEnable=true;

        }
  })
}


cancelUpdateActiveStatusCorrosionProtectionList()
{

  console.log(this.corrosionProtectionList);
  console.log(this.beforeUpdateCorrosionProtectionList);
  let corrosionChangePresent=false
  for(let updcp = 0; updcp < this.corrosionProtectionList.length; updcp++)
  {
    if(this.corrosionProtectionList[updcp].active_stat_display != this.beforeUpdateCorrosionProtectionList[updcp].active_stat_display)
    {
    corrosionChangePresent=true;
    break;
    }
  }

  if(corrosionChangePresent == true)
  {

  this.confirmationService.confirm({
    message: 'Are you sure you want to cancel the changes made for active status ?',
    header: 'Confirm',
    icon: 'pi pi-exclamation-triangle',
    accept: () =>
    {
      this.getRegionCorrosionProtectionData();
        this.displayAddCorrosionProtection=false;
        this.displayEditCorrosionProtection=false;
        this.displayAddCorrosionProtectionButtonEnable=true;
        this.displayUpdateActiveStatusCorrosionProtectionButtonEnable=true;

    }
  })
}




}


viewExistCorrosionProtectionDataDialog(rowDataShowCorrosionProtection:any)
{


  console.log('user data display in pop up corosion protection ',rowDataShowCorrosionProtection);
   this.viewCorrosionProtectionDataInit();

  this.viewCorrosionProtectionData.corsn_prot_type=rowDataShowCorrosionProtection.corsn_prot_type;
  this.viewCorrosionProtectionData.cd_corsn_prot_type=rowDataShowCorrosionProtection.cd_corsn_prot_type;
  // this.viewCorrosionProtectionData.active_stat=rowDataShowCorrosionProtection.active_stat;
  this.viewCorrosionProtectionData.active_stat_display=rowDataShowCorrosionProtection.active_stat_display;
  if(rowDataShowCorrosionProtection.active_stat_display != null && rowDataShowCorrosionProtection.active_stat_display == true)
  {
    this.viewCorrosionProtectionData.active_stat='yes';
  }
  else
  {
    this.viewCorrosionProtectionData.active_stat='no';
  }


  this.viewCorrosionProtectionDialog=true;


}


onCloseviewExistCorrosionProtectionDataDialog()
{

  this.viewCorrosionProtectionDialog=false;
  this.viewCorrosionProtectionDataInit();

}

addCorrosionProtectionDataInit()
{
  this.addCorrosionProtectionData=
  {
    cd_corsn_prot_type:0,
    corsn_prot_type:'',
    active_stat:'',
    updated_by:'',
    updated_dt:''
  };
}


editCorrosionProtectionDataInit()
{
  this.editCorrosionProtectionData=
  {
    cd_corsn_prot_type:0,
    corsn_prot_type:'',
    active_stat:'',
    updated_by:'',
    updated_dt:''
  };
}


viewCorrosionProtectionDataInit()
{
  this.viewCorrosionProtectionData=
  {
    cd_corsn_prot_type:0,
    corsn_prot_type:'',
    active_stat:'',
    updated_by:'',
    updated_dt:'',
    active_stat_display:false
  };
}


addCorrosionProtectionButton()
{
  this.displayAddCorrosionProtection=true;
  this.displayAddCorrosionProtectionButtonEnable=false;
  this.displayEditCorrosionProtection=false;
  this.displayUpdateActiveStatusCorrosionProtectionButtonEnable=false;
  this.addCorrosionProtectionDataInit();
}

saveNewCorrosionProtection()
{
  console.log('User entered corrosion protection save detail',this.addCorrosionProtectionData);

  this.addCorrosionProtectionData.updated_by=this.cdsisdConfig;
  this.addCorrosionProtectionData.cd_corsn_prot_type=0;
 // this.addPlanttypeData.updated_dt=new Date().toISOString();   //output format :YYYY-MM-DDTHH:mm:ss. sssZ
  this.addCorrosionProtectionData.updated_dt=this.date.transform(new Date(), 'yyyy-MM-dd hh:mm:ss');


  this.addCorrosionProtectionForPost=[];
  this.addCorrosionProtectionForPost.push(this.addCorrosionProtectionData);


  console.log('corroison data',this.addCorrosionProtectionForPost)
  // service to post new plant type
  this.transactionService.postCorrosionDetails(this.addCorrosionProtectionForPost).subscribe(addCorrosionOutput=>{

      if(addCorrosionOutput.toLowerCase() == 'success')
      {
      this.messageService.add({severity:'success', summary:'Success', detail:' Corrosion Added Successfully'});
      this.getRegionCorrosionProtectionData();
      this.addCorrosionProtectionDataInit();
      this.displayAddCorrosionProtection=false;
      this.displayEditCorrosionProtection=false;
      this.displayAddCorrosionProtectionButtonEnable=true;
      this.displayUpdateActiveStatusCorrosionProtectionButtonEnable=true;
      }
      else
      {
        this.messageService.add({severity:'error', summary: 'Error', detail: 'Please Try Again'});

      }
    })



}


cancelNewCorrosionProtection()
{
  this.addCorrosionProtectionDataInit();
  this.displayAddCorrosionProtection=false;
  this.displayEditCorrosionProtection=false;
  this.displayAddCorrosionProtectionButtonEnable=true;
  this.displayUpdateActiveStatusCorrosionProtectionButtonEnable=true;

}


disableSaveNewCorrosionProtection():boolean
{
  if(this.addCorrosionProtectionData.corsn_prot_type != null && this.addCorrosionProtectionData.corsn_prot_type.trim() != ''
     && this.addCorrosionProtectionData.active_stat != null && this.addCorrosionProtectionData.active_stat != '' )
  {
    return false;
  }
  else
  {
    return true;
  }
}


editCorrosionProtectionButton(rowDataEditCorrosionProtection:any)
{
  console.log('Row Data Corrosion Protection edit',rowDataEditCorrosionProtection);
  this.displayEditCorrosionProtection=true;
  this.displayAddCorrosionProtectionButtonEnable=false;
  this.displayAddCorrosionProtection=false;
  this.displayUpdateActiveStatusCorrosionProtectionButtonEnable=false;
  this.editCorrosionProtectionDataInit();
  this.editCorrosionProtectionData.cd_corsn_prot_type=rowDataEditCorrosionProtection.cd_corsn_prot_type;
  this.editCorrosionProtectionData.corsn_prot_type=rowDataEditCorrosionProtection.corsn_prot_type;
  // this.editCorrosionProtectionData.active_stat=rowDataEditCorrosionProtection.active_stat;
  if(rowDataEditCorrosionProtection.active_stat_display != null && rowDataEditCorrosionProtection.active_stat_display == true)
  {
    this.editCorrosionProtectionData.active_stat='yes';
  }
  else
  {
    this.editCorrosionProtectionData.active_stat='no';
  }


}

saveEditExistCorrosionProtection()
{
  console.log('User entered corrosion protection edit save detail ',this.editCorrosionProtectionData);

  this.editCorrosionProtectionData.updated_by=this.cdsisdConfig;
 // this.addPlanttypeData.updated_dt=new Date().toISOString();   //output format :YYYY-MM-DDTHH:mm:ss. sssZ
  this.editCorrosionProtectionData.updated_dt=this.date.transform(new Date(), 'yyyy-MM-dd hh:mm:ss');


  this.editCorrosionProtectionForPost=[];
  this.editCorrosionProtectionForPost.push(this.editCorrosionProtectionData);


  // service to post new plant type
  this.transactionService.postCorrosionDetails(this.editCorrosionProtectionForPost).subscribe(editCorrosionOutput=>{

      if(editCorrosionOutput.toLowerCase() == 'success')
      {
      this.messageService.add({severity:'success', summary:'Success', detail:' Corrosion Protection Type Edited Successfully'});
      this.getRegionCorrosionProtectionData();
      this.editCorrosionProtectionDataInit();
      this.displayAddCorrosionProtection=false;
      this.displayEditCorrosionProtection=false;
      this.displayAddCorrosionProtectionButtonEnable=true;
      this.displayUpdateActiveStatusCorrosionProtectionButtonEnable=true;
      }
      else
      {
        this.messageService.add({severity:'error', summary: 'Error', detail: 'Please Try Again'});

      }
  })



}


cancelEditExistCorrosionProtection()
{
  this.editCorrosionProtectionDataInit();
  this.displayAddCorrosionProtection=false;
  this.displayEditCorrosionProtection=false;
  this.displayAddCorrosionProtectionButtonEnable=true;
  this.displayUpdateActiveStatusCorrosionProtectionButtonEnable=true;

}


disableSaveEditExistCorrosionProtection():boolean
{
  if(this.editCorrosionProtectionData.corsn_prot_type != null && this.editCorrosionProtectionData.corsn_prot_type.trim() != ''
     && this.editCorrosionProtectionData.active_stat != null && this.editCorrosionProtectionData.active_stat != '' )
  {
    return false;
  }
  else
  {
    return true;
  }
}



deleteCorrosionProtectionButton(rowDataDeleteCorrosionProtection:any)
{
  console.log('Row Data Corrosion delete',rowDataDeleteCorrosionProtection);
  this.displayAddCorrosionProtection=false;
  this.displayEditCorrosionProtection=false;
  this.displayAddCorrosionProtectionButtonEnable=true;
  this.displayUpdateActiveStatusCorrosionProtectionButtonEnable=true;

  this.confirmationService.confirm({
    message: 'Are you sure you want to delete Corrosion Protection Type ' + '"'+rowDataDeleteCorrosionProtection.corsn_prot_type+'"' + '?',
    header: 'Confirm',
    icon: 'pi pi-exclamation-triangle',
    accept: () =>
    {
     // api to delete plant type
        let deleteCorrosionOutput
        console.log('Success',deleteCorrosionOutput);

        if(deleteCorrosionOutput.toLowerCase() == 'success')
        {
          this.messageService.add({severity:'success',summary:'Success',detail:'Corrosion Protection Type Deleted Successfully'})
          this.getRegionCorrosionProtectionData();
        }
        else
        {
          this.messageService.add({severity:'error',summary:'Error',detail:'Try Again!'});

        }

    }
  });

}



/*-------------------------------------Container group--------------------------------------------------------*/



addContainerGroupInit()
{
  this.addContainerGroup=
  { cd_cntr_grp:'',
    cntr_grp:'',
    cntr_grp_desc:'',
    cd_region:'',
    active_stat:'',
    updated_by:'',
    updated_dt:''
  };

}

editContainerGroupInit()
{
  this.editContainerGroup=
  { cd_cntr_grp:'',
    cntr_grp:'',
    cntr_grp_desc:'',
    cd_region:'',
    active_stat:'',
    updated_by:'',
    updated_dt:''
  };

}

cntrGroupDtlsInit()
{

  this.containerGroupDetails=
  { cd_cntr_grp:'',
    cntr_grp:'',
    cntr_grp_desc:'',
    cd_region:'',
    active_stat:'',
    updated_by:'',
    updated_dt:''
  };

}


getContainerGroupGrid()
{

  this.transactionService.getContainerGroup(this.regionCodeConfig).subscribe(cntrgrpresult=>{
    console.log('container group',cntrgrpresult);
    this.containerGroupGrid=cntrgrpresult;

    for(let i=0;i<this.containerGroupGrid.length;i++)
    {
      if(this.containerGroupGrid[i].active_stat.toLowerCase()=='yes')
      {
        this.containerGroupGrid[i].active=true;
      }
      else
      {
        this.containerGroupGrid[i].active=false;
      }
    }
    this.oldContainerGroup=JSON.parse(JSON.stringify(this.containerGroupGrid));
    console.log('oldContainerGroup',this.oldContainerGroup)

    this.cntrGrpCols=[
      {field:'cntr_grp',header:'Container Group Name'},
      {field:'cd_cntr_grp',header:'Container Group Code'},
      {field:'cntr_grp_desc',header:'Container Group Description'},
      {field:'active',header:'Active'}
    ]
  })

}

disableCntrGrpAdd()
{
  if(!this.addContainerGroup.cd_cntr_grp || !this.addContainerGroup.cntr_grp || !this.addContainerGroup.cntr_grp_desc || !this.addContainerGroup.active_stat )
  {
    return true;
  }
  else
  {
    return false;
  }
}

addContainerGroupDetls()
{
  this.addContainerGroupInit();
  this.addContainerGroupButtonEnable=false;
  this.addContainerGroupDetailsEnable=true;
  this.editContainerGroupEnable=false;
}

saveContainerGroupAdd()
{
  this.addContainerGroup.cd_region=this.regionCodeConfig;
  this.addContainerGroup.updated_by=this.cdsisdConfig;
  this.addContainerGroup.updated_dt=new Date().toISOString();

  const addcntrgrp=[];
  addcntrgrp.push(this.addContainerGroup)

  console.log('addcntrgrp',addcntrgrp)

  this.transactionService.saveContainerGroupDetails(addcntrgrp).subscribe(addstatus=>{
    console.log('success',addstatus);

    if(addstatus.toLowerCase()=='success')
    {
      this.getContainerGroupGrid();
      this.addContainerGroupInit();
      this.messageService.add({severity:'success',summary:'Success',detail:'Container Group Added Successfully'})

    }
    else
    {
      this.messageService.add({severity:'error',summary:'Error',detail:'Container Group not Added, Try Again!'})
    }

  })

}

cancelContainerGroupAdd()
{
  this.addContainerGroupButtonEnable=true;
  this.addContainerGroupDetailsEnable=false;
  this.editContainerGroupEnable=false;
}

displaydetailsContainerGroup(cntrGrpRow:containerGroupWithActiveField)
{
  this.cntrGroupDtlsInit();
  if(this.containerGroupGrid.length!=0)
  {
    const cntrGrpInd=this.containerGroupGrid.findIndex(element=>element.cd_cntr_grp.toLowerCase()==cntrGrpRow.cd_cntr_grp.toLowerCase())
    if(cntrGrpInd!=-1)
    {
      // this.containerGroupDetails=JSON.parse(JSON.stringify(this.containerGroupGrid[cntrGrpInd]));
      console.log('containerGroupDetails',this.containerGroupDetails)
      this.containerGroupDetails.cd_cntr_grp=this.containerGroupGrid[cntrGrpInd].cd_cntr_grp
      this.containerGroupDetails.cntr_grp=this.containerGroupGrid[cntrGrpInd].cntr_grp
      this.containerGroupDetails.cntr_grp_desc=this.containerGroupGrid[cntrGrpInd].cntr_grp_desc
      this.containerGroupDetails.active_stat=this.containerGroupGrid[cntrGrpInd].active_stat
      this.containerGroupDetails.cd_region=this.containerGroupGrid[cntrGrpInd].cd_region
      this.containerGroupDetails.updated_by=this.containerGroupGrid[cntrGrpInd].updated_by
      this.containerGroupDetails.updated_dt=this.containerGroupGrid[cntrGrpInd].updated_dt

      this.containerGroupDetailsPopUpEnable=true;
    }
    else
    {
      this.messageService.add({severity:'error',summary:'Error',detail:'Container Group Details cannot be fetched!'})
    }
  }
}

closeCntrGrp()
{
  this.containerGroupDetailsPopUpEnable=false;
  this.cntrGroupDtlsInit();
}

editCntrGroupFromGrid(dataToBeEdited:containerGroupWithActiveField)
{
  this.addContainerGroupButtonEnable=false;
  this.addContainerGroupDetailsEnable=false;
  this.editContainerGroupEnable=true;

  this.editContainerGroup.cd_cntr_grp=dataToBeEdited.cd_cntr_grp
  this.editContainerGroup.cntr_grp=dataToBeEdited.cntr_grp
  this.editContainerGroup.cntr_grp_desc=dataToBeEdited.cntr_grp_desc
  this.editContainerGroup.active_stat=dataToBeEdited.active_stat
  this.editContainerGroup.cd_region=dataToBeEdited.cd_region
  this.editContainerGroup.updated_by=dataToBeEdited.updated_by
  this.editContainerGroup.updated_dt=dataToBeEdited.updated_dt

  console.log('editContainerGroup',this.editContainerGroup)

  // this.editContainerGroup=JSON.parse(JSON.stringify(dataToBeEdited));
}


saveEditCntrGroup()
{
  // this.editContainerGroup.cd_region=this.regionCodeConfig;
  this.editContainerGroup.updated_by=this.cdsisdConfig;
  this.editContainerGroup.updated_dt=new Date().toISOString();

  // alert(this.editContainerGroup)

  const editcntrgrp=[];
  editcntrgrp.push(this.editContainerGroup)

  this.transactionService.saveContainerGroupDetails(editcntrgrp).subscribe(editstatus=>{
    console.log('success',editstatus);

    if(editstatus.toLowerCase()=='success')
    {
      this.getContainerGroupGrid();
      this.editContainerGroupInit();

      this.editContainerGroupEnable=false;
      this.addContainerGroupDetailsEnable=false;
      this.addContainerGroupButtonEnable=true;

      this.messageService.add({severity:'success',summary:'Success',detail:'Container Group Edited Successfully'})

    }
    else
    {
      this.messageService.add({severity:'error',summary:'Error',detail:'Container Group not Updated, Try Again!'})
    }

  })

}

cancelEditCntrGroup()
{
  this.editContainerGroupEnable=false;
  this.addContainerGroupDetailsEnable=false;
  this.addContainerGroupButtonEnable=true;
}

saveRegionCntrGrpData()
{

  /*for(let i=0;i<this.containerGroupGrid.length;i++)
  {
    if(this.containerGroupGrid[i].active)
    {
      this.containerGroupGrid[i].active_stat="Yes";
    }
    else
    {
      this.containerGroupGrid[i].active_stat="No";
    }
  }*/

  this.updateCntrGroup=[];

  for(let j=0;j<this.containerGroupGrid.length;j++)
  {
    if(this.containerGroupGrid[j].cd_cntr_grp==this.oldContainerGroup[j].cd_cntr_grp &&
       this.containerGroupGrid[j].active!=this.oldContainerGroup[j].active)
    {
      this.updateCntrGroup.push({
        cd_cntr_grp:this.containerGroupGrid[j].cd_cntr_grp,
        cntr_grp:this.containerGroupGrid[j].cntr_grp,
        cntr_grp_desc:this.containerGroupGrid[j].cntr_grp_desc,
        cd_region:this.containerGroupGrid[j].cd_region,
        active_stat:this.containerGroupGrid[j].active_stat,
        updated_by:this.containerGroupGrid[j].updated_by,
        updated_dt:this.containerGroupGrid[j].updated_dt
      })
    }
  }

  this.transactionService.saveContainerGroupDetails(this.updateCntrGroup).subscribe(activeupdatestatus=>{
    console.log('success',activeupdatestatus);

    if(activeupdatestatus.toLowerCase()=='success')
    {
      this.getContainerGroupGrid();
      this.messageService.add({severity:'success',summary:'Success',detail:'Active Status Updated Successfully'})

    }
    else
    {
      this.messageService.add({severity:'error',summary:'Error',detail:'Active Status not Updated, Try Again!'})
    }

  })


}

cancelRegionCntrGrpData()
{



  let containerGroupChangePresent=false
  for(let updcp = 0; updcp < this.containerGroupGrid.length; updcp++)
  {
    if(this.containerGroupGrid[updcp].active != this.oldContainerGroup[updcp].active)
    {
      containerGroupChangePresent=true;
    break;
    }
  }

  if(containerGroupChangePresent == true)
  {

  this.confirmationService.confirm({
    message: 'Are you sure you want to cancel the changes made for active status ?',
    header: 'Confirm',
    icon: 'pi pi-exclamation-triangle',
    accept: () =>
    {
      this.getContainerGroupGrid();

    }
  })
}
}




changeActiveStatusCntrGrp(grpData:any)
{
  const index=this.containerGroupGrid.findIndex(e=>e.cd_cntr_grp==grpData.cd_cntr_grp);
  if(index!=-1)
  {
    if(grpData.active)
    {
      grpData.active_stat='Yes';
    }
    else
    {
      grpData.active_stat='No';
    }
    // alert(JSON.stringify(grpData))
    this.containerGroupGrid[index]=grpData;
  }
}

/*-------------------------------------Transport---------------------------------------------*/




addTransportListInit()
{
  this.addTransportList={cd_transport:0,transport_desc:'',active_stat:'',updated_by:'',updated_dt:''};
}

editTransportListInit()
{
  this.editTransportList={cd_transport:0,transport_desc:'',active_stat:'',updated_by:'',updated_dt:''};
}

transportDetailsInit()
{
  this.transportDetails={cd_transport:0,transport_desc:'',active_stat:'',updated_by:'',updated_dt:''};
}



getTransportGrid()
{
  this.transactionService.getAllTransport().subscribe(translist=>{
    console.log('Transport List Grid',translist);
    this.transportListGrid=translist;

    for(let i=0;i<this.transportListGrid.length;i++)
    {
      if(this.transportListGrid[i].active_stat!=null)
      {
        if( this.transportListGrid[i].active_stat.toLowerCase()=='yes')
        {
          this.transportListGrid[i].active=true;
        }
        else
        {
          this.transportListGrid[i].active=false;
        }
      }

    }
    this.oldTransportListGrid=JSON.parse(JSON.stringify(this.transportListGrid));
    console.log('oldTransportListGrid',this.oldTransportListGrid)

    this.transListCols=[
      {field:'transport_desc',header:'Transport Name'},
      // {field:'active_stat',header:'Active'},
      {field:'active',header:'Active'}
    ]
  })
}


disableTransportAdd()
{
  if(!this.addTransportList.transport_desc || !this.addTransportList.active_stat)
  {
    return true;
  }
  else
  {
    return false;
  }
}

addTransportDetls()
{
  this.addTransportListInit();
  this.addTransportListButtonEnable=false;
  this.addTransportListDetailsEnable=true;
  this.editTransportListEnable=false;
}

saveTransportAdd()
{
  this.addTransportList.updated_by=this.cdsisdConfig;
  this.addTransportList.updated_dt=this.date.transform(new Date(),'yyyy-MM-dd hh:mm:ss');

  const addtrans=[];
  addtrans.push(this.addTransportList)

  console.log('addtrans',addtrans)

  this.transactionService.saveTransport(addtrans).subscribe(addtransstatus=>{
    console.log('success',addtransstatus);

    if(addtransstatus.toLowerCase()=='success')
    {
      this.getTransportGrid();
      this.addTransportListInit();
      this.messageService.add({severity:'success',summary:'Success',detail:'Container Group Added Successfully'})

    }
    else
    {
      this.messageService.add({severity:'error',summary:'Error',detail:'Container Group not Added, Try Again!'})
    }

  })

}

cancelTransportAdd()
{
  this.addTransportListButtonEnable=true;
  this.addTransportListDetailsEnable=false;
  this.editTransportListEnable=false;
}

displayTransportDetails(transport:transportListForUI)
{
  this.transportDetailsInit();
  if(this.transportListGrid.length!=0)
  {
    const transGrdIndex=this.transportListGrid.findIndex(element=>element.cd_transport==transport.cd_transport)
    if(transGrdIndex!=-1)
    {
      // this.containerGroupDetails=JSON.parse(JSON.stringify(this.containerGroupGrid[cntrGrpInd]));

      this.transportDetails={
        cd_transport: this.transportListGrid[transGrdIndex].cd_transport,
        transport_desc:this.transportListGrid[transGrdIndex].transport_desc,
        active_stat:this.transportListGrid[transGrdIndex].active_stat,
        updated_by:this.transportListGrid[transGrdIndex].updated_by,
        updated_dt:this.transportListGrid[transGrdIndex].updated_dt,
      }
      console.log('Transport Details',this.transportDetails)
      this.TransportListDetailsPopUpEnable=true;
    }
    else
    {
      this.messageService.add({severity:'error',summary:'Error',detail:'Transport Details is not fetched!'})
    }
  }
}

closeTransDetPopUp()
{
  this.TransportListDetailsPopUpEnable=false;
  this.transportDetailsInit();
}

editTransportFromGrid(transportFromGrid:transportListForUI)
{
  this.addTransportListButtonEnable=false;
  this.addTransportListDetailsEnable=false;
  this.editTransportListEnable=true;

  this.editTransportList.cd_transport=transportFromGrid.cd_transport
  this.editTransportList.transport_desc=transportFromGrid.transport_desc
  this.editTransportList.active_stat=transportFromGrid.active_stat
  this.editTransportList.updated_by=transportFromGrid.updated_by
  this.editTransportList.updated_dt=transportFromGrid.updated_dt

  console.log('editTransportList',this.editTransportList)

}


saveEditTranpsList()
{
  this.editTransportList.updated_by=this.cdsisdConfig;
  this.editTransportList.updated_dt=this.date.transform(new Date(),'yyyy-MM-dd hh:mm:ss');

  const edittrans=[];
  edittrans.push(this.editTransportList)

  this.transactionService.saveTransport(edittrans).subscribe(edittransstatus=>{
    console.log('success',edittransstatus);

    if(edittransstatus.toLowerCase()=='success')
    {
      this.getTransportGrid();
      this.editTransportListInit();

      this.editTransportListEnable=false;
      this.addTransportListDetailsEnable=false;
      this.addTransportListButtonEnable=true;

      this.messageService.add({severity:'success',summary:'Success',detail:'Container Group Edited Successfully'})

    }
    else
    {
      this.messageService.add({severity:'error',summary:'Error',detail:'Container Group not Updated, Try Again!'})
    }

  })

}

cancelEditTransp()
{
  this.editTransportListEnable=false;
  this.addTransportListDetailsEnable=false;
  this.addTransportListButtonEnable=true;
}


saveRegionTranspData()
{

 /* for(let i=0;i<this.transportListGrid.length;i++)
  {
    if(this.transportListGrid[i].active)
    {
      this.transportListGrid[i].active_stat="Yes";
    }
    else
    {
      this.transportListGrid[i].active_stat="No";
    }
  }*/

  this.updateTransportList=[];

  for(let j=0;j<this.transportListGrid.length;j++)
  {
    if(this.transportListGrid[j].cd_transport==this.oldTransportListGrid[j].cd_transport &&
       this.transportListGrid[j].active!=this.oldTransportListGrid[j].active)
    {
      this.updateTransportList.push({
        cd_transport:this.transportListGrid[j].cd_transport,
        transport_desc:this.transportListGrid[j].transport_desc,
        active_stat:this.transportListGrid[j].active_stat,
        updated_by:this.cdsisdConfig,
        updated_dt:this.date.transform(new Date(),'yyyy-MM-dd hh:mm:ss')
      })
    }
  }

  console.log('updateTransportList',this.updateTransportList)

  this.transactionService.saveTransport(this.updateTransportList).subscribe(activeupdatestatus=>{
    console.log('success',activeupdatestatus);

    if(activeupdatestatus.toLowerCase()=='success')
    {
      this.getTransportGrid();
      this.messageService.add({severity:'success',summary:'Success',detail:'Active Status Updated Successfully'})

    }
    else
    {
      this.messageService.add({severity:'error',summary:'Error',detail:'Active Status not Updated, Try Again!'})
    }

  })


}

cancelRegionTranspData()
{


  let transportChangePresent=false
  for(let updcp = 0; updcp < this.transportListGrid.length; updcp++)
  {
    if(this.transportListGrid[updcp].active != this.oldTransportListGrid[updcp].active)
    {
      transportChangePresent=true;
    break;
    }
  }

  if(transportChangePresent == true)
  {

  this.confirmationService.confirm({
    message: 'Are you sure you want to cancel the changes made for active status ?',
    header: 'Confirm',
    icon: 'pi pi-exclamation-triangle',
    accept: () =>
    {
      this.getTransportGrid();

    }
  })
}

}

changeActiveStatusTransport(transData:any)
{
  const ind=this.transportListGrid.findIndex(e=>e.cd_transport==transData.cd_transport);
  if(ind!=-1)
  {
    if(transData.active)
    {
      transData.active_stat='Yes';
    }
    else
    {
      transData.active_stat='No';
    }
    // alert(JSON.stringify(transData))
    this.transportListGrid[ind]=transData;
  }
}



// container master category




containerdetailsinit() {
  this.containerdets={
  cd_cntr_mstr_catg: 0,
  cntr_mstr_catg: '',
  cntr_mstr_catg_desc: '',
  active_stat: '',
  updated_by: '',
  updated_dt: ''
  }
}

containeraddinit(){
  this.addcontainerdets={
    cd_cntr_mstr_catg: 0,
  cntr_mstr_catg: '',
  cntr_mstr_catg_desc: '',
  active_stat: '',
  updated_by: '',
  updated_dt: ''
  }
}

containereditinit(){
  this.editcontainerdets={
  cd_cntr_mstr_catg: 0,
  cntr_mstr_catg: '',
  cntr_mstr_catg_desc: '',
  active_stat: '',
  updated_by: '',
  updated_dt: ''
  }
}



// displaydetails(){

//   //this.containerdetailsinit();

//   this.contAddEnable=false;
//   this.contAddDetEnable=false;
//   this.enableEditContDet=false;
//   this.enableEditGetDet=false;

//   this.transactionService.getAllMastContCatg().subscribe(addcon =>{
//     this.containergrid = addcon;
//     console.log("Container Display",JSON.stringify(this.containergrid))


//     this.contDetEnable=true;

//   })

// }
populategrid() {
  this.transactionService.getAllMastContCatg().subscribe(popCont => {
    // this.containergrid = JSON.parse(JSON.stringify(popCont));
    this.containergrid = [];
    this.containergrid = popCont;
    console.log('Populategrid',this.containergrid);
    // for(var i=0;i<this.containergrid.length;i++){
    //   if(this.containergrid[i].cd_cntr_mstr_catg!=null){
    //     this.containergrid[i].cntr_mstr_catg;
    //   }
    // }
    if(this.containergrid!=null && this.containergrid.length!=0){
      for(let a=0; a<this.containergrid.length;a++){
        if(this.containergrid[a].active_stat!=null && this.containergrid[a].active_stat.toLowerCase() == 'yes')
      {
        this.containergrid[a].active_stat_display = true;
      }
      else
      {
        this.containergrid[a].active_stat_display = false;
      }
      }
      this.beforeupdatedcontdets = JSON.parse(JSON.stringify(this.containergrid));
    }
  })
  this.contmastcategcols=[
    {field: 'cntr_mstr_catg', header:'Container Master Category'},
    {field: 'cntr_mstr_catg_desc', header:'Description'},
    {field: 'active_stat_display', header: 'Active'}
  ];
}

UpdateActiveStatusContmast(){
  console.log('Active update',this.containergrid);
  const updatedactivestatuscont = [];
  if(this.containergrid!=null && this.containergrid.length!=0)
  {
    for(let i = 0; i < this.containergrid.length; i++)
    {
      if(this.beforeupdatedcontdets[i].active_stat_display != this.containergrid[i].active_stat_display)
      {
        if(this.containergrid[i].active_stat_display == true)
      {
        this.containergrid[i].active_stat = 'Yes';
        // console.log("Console");
      }
      else
      {
        this.containergrid[i].active_stat = 'No';
      }
      updatedactivestatuscont.push({
        cd_cntr_mstr_catg: this.containergrid[i].cd_cntr_mstr_catg,
        cntr_mstr_catg: this.containergrid[i].cntr_mstr_catg,
        cntr_mstr_catg_desc: this.containergrid[i].cntr_mstr_catg_desc,
        active_stat: this.containergrid[i].active_stat,
        updated_by: this.transactionService.getcdsid(),
        updated_dt: new Date().toISOString()
      })
    }
    }
    if(updatedactivestatuscont.length!=0)
  {
    console.log('Updated',updatedactivestatuscont);
    this.transactionService.postMastContCatg(updatedactivestatuscont).subscribe(saveaddcont =>{
      console.log('Test Container Save');
      const savecontainer = saveaddcont;
      console.log('Success',savecontainer);
      if(saveaddcont.toLowerCase() == 'success'){
        this.messageService.add({severity:'success', summary:'Success', detail:'Container Master Categ Updated Successfully!!'});

            this.containerdetailsinit();
            this.populategrid();
            this.contAddDetEnable=false;
            this.contAddEnable=true;
      }
      else{
        this.notificationText = 'Container Master Catge Not Updated Successfully!!, Try Again'
      }
    })
  }
  }
}
cancelUpdateActiveStatusCont(){



  let contmastercatChangePresent=false;
  for(let updplt = 0; updplt < this.containerGroupGrid.length; updplt++)
  {
   if(this.containergrid[updplt].active_stat_display != this.beforeupdatedcontdets[updplt].active_stat_display)
    {
      contmastercatChangePresent=true;
          break;

    }
  }
  if(contmastercatChangePresent == true)
  {
  this.confirmationService.confirm({
    message: 'Are you sure you want to cancel the changes made for active status ?',
    header: 'Confirm',
    icon: 'pi pi-exclamation-triangle',
    accept: () =>
    {

      this.populategrid();
      this.contAddDetEnable=false;
      this.contAddEnable=true;
      this.contDetEnable=false;
      this.enableEditGetDet=false;
      this.enableEditContDet=false;

    }
  })
}
}
 DisplayPopup(showcontr:any){
     console.log('testdisplay',showcontr);
      this.showcontainerdets.cd_cntr_mstr_catg = showcontr.cd_cntr_mstr_catg;
      this.showcontainerdets.cntr_mstr_catg = showcontr.cntr_mstr_catg;
      this.showcontainerdets.cntr_mstr_catg_desc = showcontr.cntr_mstr_catg_desc;
      // this.showcontainerdets.active_stat = showcontr.active_stat;
      this.showcontainerdets.updated_by = showcontr.updated_by;
      this.showcontainerdets.updated_dt = showcontr.updated_dt;

      // this.containerdetailsinit();
      // this.contDetEnable =true;
      if(showcontr.active_stat_display == true)
  {
      this.showcontainerdets.active_stat = 'Yes';
  }
  else
  {
    this.showcontainerdets.active_stat = 'No';
  }
      this.contDetEnable=true;
      this.contmastcategPopUpEnable = true;


      // if(this.containergrid.length!=0)
      // {
      //   var cntrmastInd=this.containergrid.findIndex(dispcontpop=>dispcontpop.cntr_mstr_catg.toLowerCase()==showcontr.cntr_mstr_catg.toLowerCase())
      //   if(cntrmastInd!=-1)
      //   {
      //     this.containerdets=JSON.parse(JSON.stringify(this.containergrid[cntrmastInd]));
      //     this.contDetEnable=true;
      //   }
      //   else
      //   {
      //     this.messageService.add({severity:'error',summary:'Error',detail:'Container Group Details cannot be fetched!'})
      //   }
      // }
  }

  closeCntrdisp(){
    this.contmastcategPopUpEnable = false;
    this.containerdetailsinit();
  }

  addcontainer(){
    this.containeraddinit();
    this.contAddDetEnable=true;
    this.contAddEnable=false;
    this.enableEditGetDet=false;
    this.enableEditContDet=false;
    this.contDetEnable=true;
  }

 saveaddcont(){

   this.notificationText = ''
   this.addcontainerdets.updated_by = this.transactionService.getcdsid();
   this.addcontainerdets.updated_dt = new Date().toISOString();

    console.log('Saving of Add Container Master Categ',this.addcontainerdets);

    this.addcontainerdetsArray=[];
    this.addcontainerdetsArray.push(this.addcontainerdets);


    console.log('array add container master cat',this.addcontainerdetsArray)

    this.transactionService.postMastContCatg(this.addcontainerdetsArray).subscribe(saveaddcont=>{
      console.log('Test save');
      const save = saveaddcont;
      console.log('success',save);
      if(save.toLowerCase()=='success')
      {
        this.messageService.add({severity:'success', summary:'Success', detail:'Additional Container Master Category Added Successfully!!'});

        this.containerdetailsinit();
        this.populategrid();
        this.contAddDetEnable=false;
        this.contAddEnable=true;
      }
      else{
        this.notificationText = 'Additional Container Master Category Not Added Successfully!!, Try Again'
      }

    })
 }

cancel_addcont(){
this.contAddEnable=true;
this.contAddDetEnable=false;
this.containerdetailsinit()
console.log(this.containerdets);
console.log('test cancel');
}
// This function is called when edit button is clicked from grid

editcontfromgrid(editgrid:contmastcateg){

  console.log(editgrid);
  console.log(editgrid.cd_cntr_mstr_catg);


  this.contAddEnable=false;
  this.contDetEnable=false;
  this.enableEditGetDet=false;
  this.contAddDetEnable=false;
  this.enableEditContDet=true;
  this.containereditinit();


  //  this.transactionService.getAllMastContCatg().subscribe(editcontgrid => {

    this.editcontainerdets.cd_cntr_mstr_catg=editgrid.cd_cntr_mstr_catg;
     this.editcontainerdets.cntr_mstr_catg = editgrid.cntr_mstr_catg;
     this.editcontainerdets.cntr_mstr_catg_desc = editgrid.cntr_mstr_catg_desc;
     this.editcontainerdets.active_stat = editgrid.active_stat;
    //  this.editcontainerdets.updated_by = editgrid.updated_by;
    //  this.editcontainerdets.updated_dt = editgrid.updated_dt;

     console.log('Edit details for grid test',this.editcontainerdets);
  //  })
}
// To save the edit details of grid fter clicking the save button
saveeditcont(){
  console.log(this.editcontainerdets);
  console.log('Edited Dets',this.editcontainerdets);

  this.editcontainerdets.updated_by = this.transactionService.getcdsid();
  this.editcontainerdets.updated_dt =  new Date().toISOString();
  this.editcontainerdetsArray = [];
  this.editcontainerdetsArray.push(this.editcontainerdets);

  this.transactionService.postMastContCatg(this.editcontainerdetsArray).subscribe((saveeditdets => {
    const res=saveeditdets;
    console.log('successful',res);
    if(res.toLowerCase()=='success')
      {
        this.messageService.add({severity:'success', summary:'Success', detail:'Container Master Category Edited Successfully!!'});

        this.containerdetailsinit();
        this.populategrid();
        this.enableEditContDet=false;
        this.contAddEnable=true;
      }
      else{
        this.notificationText = 'Container Master Category Not Edited Successfully!!, Try Again'
      }

  }))

}
canceleditcont()
{
     this.enableEditContDet=false;
     this.contAddEnable=true;
     this.containereditinit();
}

// *************Palletpintype code*****************//
palletinitdetails(){
this.palletinitdets={
  cd_pinpallettyp: 0,
  pinpallettyp: '',
  pinpallettyp_desc:'',
  active_stat:'',
  updated_by:'',
  updated_dt:''
}
}
palleteditinit(){
this.editpalletdets={
  cd_pinpallettyp: 0,
  pinpallettyp: '',
  pinpallettyp_desc:'',
  active_stat:'',
  updated_by:'',
  updated_dt:''
}
}
palletaddinit(){
this.addpalletdets={
  cd_pinpallettyp: 0,
  pinpallettyp: '',
  pinpallettyp_desc:'',
  active_stat:'',
  updated_by:'',
  updated_dt:''
}
}
populatepalletgrid(){
this.transactionService.getOtherConfigPinPallet().subscribe(popPallet => {
this.palletpingrid = JSON.parse(JSON.stringify(popPallet));

  console.log('Populate Pallet grid',this.palletpingrid);
  for(let i=0;i<this.palletpingrid.length;i++){
    if(this.palletpingrid[i].cd_pinpallettyp!=null){
      this.palletpingrid[i].pinpallettyp;
    }
  }

  if(this.palletpingrid!=null && this.palletpingrid.length!=0){
    for(let a=0;a<this.palletpingrid.length;a++){
      if(this.palletpingrid[a].active_stat!=null && this.palletpingrid[a].active_stat.toLowerCase() == 'yes')
      {
        this.palletpingrid[a].active_stat_display = true;
      }
      else
      {
        this.palletpingrid[a].active_stat_display = false;
      }
    }
    this.beforeupdateddets = JSON.parse(JSON.stringify(this.palletpingrid));
  }

})
this.pinpalletypecols=[
  {field: 'pinpallettyp', header:'Pin Pallet Type'},
  {field: 'pinpallettyp_desc', header:'Description'},
  {field: 'active_stat_display', header: 'Active'}
];
}

UpdateActiveStatusPalletPin()
{
console.log(this.palletpingrid);
const updatedactivestatuspinpallet = [];
if(this.palletpingrid!=null && this.palletpingrid.length!=0)
{

  for(let i=0;i<this.palletpingrid.length;i++){
    if(this.beforeupdateddets[i].active_stat_display != this.palletpingrid[i].active_stat_display)
    {
      if(this.palletpingrid[i].active_stat_display == true)
      {
        this.palletpingrid[i].active_stat = 'Yes';
      }
      else
      {
        this.palletpingrid[i].active_stat = 'No';
      }
      updatedactivestatuspinpallet.push({
        cd_pinpallettyp: this.palletpingrid[i].cd_pinpallettyp,
        pinpallettyp: this.palletpingrid[i].pinpallettyp,
        pinpallettyp_desc: this.palletpingrid[i].pinpallettyp_desc,
        active_stat: this.palletpingrid[i].active_stat,
        updated_by: this.transactionService.getcdsid(),
        updated_dt: new Date().toISOString()
      })
    }
  }
  if(updatedactivestatuspinpallet.length!=0)
  {
    console.log(updatedactivestatuspinpallet);
    this.transactionService.postOtherConfigPinPallet(updatedactivestatuspinpallet).subscribe(saveaddpin =>{
      console.log('Test Pin Save');
      const savepin = saveaddpin;
      console.log('Success',savepin);
      if(saveaddpin.toLowerCase() == 'success'){
        this.messageService.add({severity:'success', summary:'Success', detail:'Additional Pin Pallet Type Updated Successfully!!'});

            this.palletinitdetails();
            this.populatepalletgrid();
            this.palletAddDetEnable=false;
            this.palletAddEnable=true;
      }
      else{
        this.notificationText = 'Additional Pin Pallet Type Not Updated Successfully!!, Try Again'
      }
    })
  }
}
}

cancelUpdateActiveStatusPalletPin(){


let palletpintypeChangePresent=false;
  for(let updplt = 0; updplt < this.palletpingrid.length; updplt++)
  {
   if(this.palletpingrid[updplt].active_stat_display != this.beforeupdateddets[updplt].active_stat_display)
    {
      palletpintypeChangePresent=true;
          break;

    }
  }
  if(palletpintypeChangePresent == true)
  {
  this.confirmationService.confirm({
    message: 'Are you sure you want to cancel the changes made for active status ?',
    header: 'Confirm',
    icon: 'pi pi-exclamation-triangle',
    accept: () =>
    {

      this.populatepalletgrid();
      this.palletAddEnable = true;
      this.palletAddDetEnable=false;
      this.palletDetEnable=false;
      this.enableEditPalletGetEnable=false;
      this.enableEditPalletDets=false;

    }
  })
}
}
ShowPalletGrid(showpallet:any){

console.log('testpindisplaygrid', showpallet);


  // console.log("Pin Pallet Display",JSON.stringify(this.palletpingrid))

  this.showpalletpindets.cd_pinpallettyp = showpallet.cd_pinpallettyp;
  this.showpalletpindets.pinpallettyp = showpallet.pinpallettyp;
  this.showpalletpindets.pinpallettyp_desc = showpallet.pinpallettyp_desc;
  // this.showpalletpindets.active_stat = showpallet.active_stat;
  this.showpalletpindets.updated_by = showpallet.updated_by;
  this.showpalletpindets.updated_dt = showpallet.updated_dt;

  if(showpallet.active_stat_display == true)
  {
      this.showpalletpindets.active_stat = 'yes';
  }
  else
  {
    this.showpalletpindets.active_stat = 'no';
  }

  this.PinPalletDetEnable=true;
  this.PinPalletPopUpEnable = true;



}

closePalletdisp(){
this.PinPalletPopUpEnable = false;
this.palletinitdetails();
}
addPinPallet(){
this.palletaddinit();
this.palletAddDetEnable=true;
this.palletAddEnable=false;
this.enableEditPalletDets=false;
this.enableEditPalletGetEnable=false;
this.palletDetEnable=true;
}
saveaddpallet(){
this.notificationText = '';
this.addpalletdets.updated_by = this.transactionService.getcdsid();
this.addpalletdets.updated_dt = new Date().toISOString();

console.log('Saving of Add Pin Pallet Type', this.addpalletdets);
this.addpalletdetsArray=[];
this.addpalletdetsArray.push(this.addpalletdets);

console.log('array add Pin pallrt type', this.addpalletdetsArray);

this.transactionService.postOtherConfigPinPallet(this.addpalletdetsArray).subscribe(saveaddpin =>{
  console.log('Test Pin Save');
  const savepin = saveaddpin;
  console.log('Success',savepin);
  if(saveaddpin.toLowerCase() == 'success'){
    this.messageService.add({severity:'success', summary:'Success', detail:'Additional Pin Pallet Type Added Successfully!!'});

        this.palletinitdetails();
        this.populatepalletgrid();
        this.palletAddDetEnable=false;
        this.palletAddEnable=true;
  }
  else{
    this.notificationText = 'Additional Pin Pallet Type Not Added Successfully!!, Try Again'
  }
})
}
cancel_addpallet(){
this.palletAddEnable=true;
this.palletAddDetEnable=false;
this.palletinitdetails()
console.log(this.palletpingrid);
console.log('test pallet cancel');
}


editpalletfromgrid(editpalletgrid:pinpallettype){

console.log('Edit test',editpalletgrid);
console.log(editpalletgrid.cd_pinpallettyp);
this.palleteditinit();

this.palletAddEnable = false;
this.palletDetEnable = false;
this.enableEditPalletGetEnable = false;
this.palletAddDetEnable = false;
this.enableEditPalletDets=true;

this.editpalletdets.cd_pinpallettyp = editpalletgrid.cd_pinpallettyp;
this.editpalletdets.pinpallettyp = editpalletgrid.pinpallettyp;
this.editpalletdets.pinpallettyp_desc = editpalletgrid.pinpallettyp_desc;
this.editpalletdets.active_stat = editpalletgrid.active_stat;
// this.editpalletdets.updated_by = editpalletgrid.updated_by;
// this.editpalletdets.updated_dt = editpalletgrid.updated_dt;

}
saveeditpallet(){
console.log(this.editpalletdets);
console.log('Edited Pallet Dets', this.editpalletdets);

this.editpalletdets.updated_by = this.transactionService.getcdsid();
this.editpalletdets.updated_dt =  new Date().toISOString();
this.editpalletdetsArray = [];
this.editpalletdetsArray.push(this.editpalletdets);

this.transactionService.postOtherConfigPinPallet(this.editpalletdetsArray).subscribe(saveeditpindets =>{
  const palletsave = saveeditpindets;
  console.log('successful',palletsave);
    if(palletsave.toLowerCase()=='success')
      {
        this.messageService.add({severity:'success', summary:'Success', detail:'Pin Pallet Type Edited Successfully!!'});

        this.palletinitdetails();
        this.populatepalletgrid();
        this.enableEditPalletDets=false;
        this.palletAddEnable=true;
      }
      else{
        this.notificationText = 'Pin Pallet Type Not Edited Successfully!!, Try Again'
      }
})
}
canceleditpallet(){
this.enableEditPalletDets=false;
this.palletAddEnable=true;
this.palleteditinit();
}





}
