/* tslint:disable */
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TransactionService } from '../loginpage/Transaction.service';
import { ActivePlants, containerOwnershipList, countrylistreqacc, defaultUserDetails, language, namePrefix, postUserDetails, programsbasedonplants, regionMapping, TimeZone, UnitOfMeasure, userdetails } from '../loginpage/transactions';

@Component({
  selector: 'app-edituserprofile',
  templateUrl: './edituserprofile.component.html',
  styleUrls: ['./edituserprofile.component.css']
})
export class EdituserprofileComponent implements OnInit {

  prefix='';
  country='';
  company='';
  regionList:regionMapping[]=[];
  userRegionList:regionMapping[]=[];
  languaugeList:language[]=[];
  unitOfMeasureList:UnitOfMeasure[]=[];
  timeZoneList:TimeZone[]=[];
  userId='';
  notificationText='';

  enablePlantCos=false;
  enableProgram=false;
  enableNotificationDialog = false;

  countryList:countrylistreqacc[]=[];
  country_name='';
  prefixList:namePrefix[]=[];
  plantList:ActivePlants[]=[];
  cosList:containerOwnershipList[]=[];
  programList:programsbasedonplants[]=[];

  selectedCountry:countrylistreqacc={cd_Country:'',country_name:'',active_stat:''};
  selectedPrefix:namePrefix={prefix:''};
  selectedRegion:regionMapping={region:'',regioncode:''};
  selectedLanguauge:language={language:'',language_code:''};
  selectedUnitOfMeasure:UnitOfMeasure={measure:''};
  selectedTimeZone:TimeZone={timezone:''};
  selectedPlant:ActivePlants={cd_plant:'',plantGSDBCode:'',plant_name:'',plant_type_name:''};
  selectedCos:containerOwnershipList={cd_region:'',cd_ownrshp_strat:0,ownrshp_strat_code:'',ownrshp_desc:''};
  selectedProgram:programsbasedonplants={cd_region:'',plantgsdbcode:'',programname:''};

  userInfoGetApi:userdetails={gppuserid:'',name:'',title:'',company:'',email:'',city:'',cntrycodeiso:'',createdby:'',createddt:'',designation:'',isforduser:'',isactive:'',phone:'',postalcode:'',address1:'',address2:'',state:'',lastlogin:'',country:'',fax:'',usertype:''};
  userInfo:userdetails={gppuserid:'',name:'',title:'',company:'',email:'',city:'',cntrycodeiso:'',createdby:'',createddt:'',designation:'',isforduser:'',isactive:'',phone:'',postalcode:'',address1:'',address2:'',state:'',lastlogin:'',country:'',fax:'',usertype:''};
  defaultUserInfo:defaultUserDetails={gppuserid:'',timezone:'',default_curr:'',default_gsdbcode:'',default_lang:'',default_ownershp_strat_code:'',default_progname:'',default_region:'',uom:''}

  postUserInfo:postUserDetails={gppuserid:'',name:'',title:'',company:'',email:'',city:'',cntrycodeiso:'',createdby:'',designation:'',isforduser:'',isactive:'',phone:'',postalcode:'',address1:'',address2:'',state:'',country:'',fax:'',usertype:''}
  postDefaultUserInfo:defaultUserDetails={gppuserid:'',timezone:'',default_curr:'',default_gsdbcode:'',default_lang:'',default_ownershp_strat_code:'',default_progname:'',default_region:'',uom:''}


  constructor(private transactionService:TransactionService, private router:Router) { }

  ngOnInit(): void {

    this.userId = this.transactionService.getcdsid();
    this.prefixList=[{prefix:'Mr.'},{prefix:'Ms.'},{prefix:'Mrs.'}];

    this.regionList=[{region:'AsiaPacific',regioncode:'AP'},
                     {region:'NorthAmerica',regioncode:'NA'},
                     {region:'SouthAmerica',regioncode:'SA'},
                     {region:'Europe',regioncode:'EU'}]

    this.languaugeList=[{language:'English', language_code:'EN'}]
    this.unitOfMeasureList=[{measure:'lbs/in'},{measure:'kg/cm'}]
    this.timeZoneList=[{timezone:'GMT'},{timezone:'EST'},{timezone:'IST'},{timezone:'CST'},{timezone:'PST'},{timezone:'MST'},{timezone:'AKST'},{timezone:'HST'}]

    let storedRegionList=JSON.parse(sessionStorage.getItem('user_regionaccess'))
    this.userRegionList = [];
    this.userRegionList.push({region:'[No Default selection]', regioncode:'None'})

    if(storedRegionList.length!=0){
      for(let i of storedRegionList){
        let regionIndex = this.regionList.findIndex(elreg => elreg.regioncode.toLowerCase() == i.toLowerCase())
        if(regionIndex!=-1){
          this.userRegionList.push(this.regionList[regionIndex])
        }
      }
    }
    console.log('region list', this.userRegionList);
    this.selectedLanguauge = this.languaugeList[0];
    this.selectedUnitOfMeasure = this.unitOfMeasureList[0];
    this.selectedTimeZone = this.timeZoneList[0];
    this.loadUserDetails()
  }

  onBackOrCloseUserProfile()
  {
    console.log('inside close function');

    let cur_region=this.transactionService.getregion();
    if(cur_region != null )
    {
      let reg_map_url='';
      reg_map_url=this.transactionService.getregionurlvalue(cur_region);
      reg_map_url+='packagingprocessdashboard/packagingprocesshome';

      this.router.navigate([reg_map_url]);

    }

    else
    {
      this.router.navigate(['/exitpage']);
    }
  }

  loadUserDetails(){
    this.transactionService.getCountryListReqAccess().subscribe(country => {
      console.log(country);
      this.countryList = [];
      if(country != null)
      {
      this.countryList = country;
      console.log('country');
      console.log(this.countryList);
      this.transactionService.user_details(this.userId).subscribe(user_det=>{
      console.log('UserInfo',user_det);
      if(user_det != null)
      {
      this.userInfoGetApi = user_det;

      this.userInfo = this.userInfoGetApi;

      console.log(this.userInfo.country);
      let countryIndex = this.countryList.findIndex(elcntry => elcntry.country_name.toLowerCase() == this.userInfo.country.toLowerCase())
      if(countryIndex!=-1){
        this.selectedCountry = this.countryList[countryIndex]
      }

      console.log(this.userInfo.title);
      let prefixIndex = this.prefixList.findIndex(elpfx => elpfx.prefix.toLowerCase() == this.userInfo.title.toLowerCase())
      if(prefixIndex!=-1){
        this.selectedPrefix = this.prefixList[prefixIndex]
      }

      this.transactionService.getDefaultUserData(this.userId).subscribe(default_userdate=>{
        console.log('Default user data',default_userdate);
        this.defaultUserInfo = default_userdate;

      if(this.defaultUserInfo!=null){
        if(this.defaultUserInfo.default_lang!=null){
          console.log(this.defaultUserInfo.default_lang);
          let languageIndex = this.languaugeList.findIndex(eldll => eldll.language_code.toLowerCase() == this.defaultUserInfo.default_lang.toLocaleLowerCase())
          if(languageIndex!=-1){
            this.selectedLanguauge = this.languaugeList[languageIndex]
          }
          else{
            this.selectedLanguauge = this.languaugeList[0]
          }
        }
        else{
          this.selectedLanguauge = this.languaugeList[0]
        }

        if(this.defaultUserInfo.uom!=null){
          console.log(this.defaultUserInfo.uom);
          let uomIndex = this.unitOfMeasureList.findIndex(eluom => eluom.measure.toLowerCase() == this.defaultUserInfo.uom.toLocaleLowerCase())
          if(uomIndex!=-1){
            this.selectedUnitOfMeasure = this.unitOfMeasureList[uomIndex]
          }
          else{
            this.selectedUnitOfMeasure = this.unitOfMeasureList[0]
          }
        }
        else{
          this.selectedUnitOfMeasure = this.unitOfMeasureList[0]
        }

        if(this.defaultUserInfo.timezone!=null){
          console.log(this.defaultUserInfo.timezone);
          let timezoneIndex = this.timeZoneList.findIndex(eltzl => eltzl.timezone.toLowerCase() == this.defaultUserInfo.timezone.toLocaleLowerCase())
          if(timezoneIndex!=-1){
            this.selectedTimeZone = this.timeZoneList[timezoneIndex]
          }
          else{
            this.selectedTimeZone = this.timeZoneList[0]
          }
        }
        else{
          this.selectedTimeZone = this.timeZoneList[0]
        }

        if(this.defaultUserInfo.default_region!=null){
          console.log(this.defaultUserInfo.default_region);
          let regionIndex = this.userRegionList.findIndex(eldrl => eldrl.regioncode.toLowerCase() == this.defaultUserInfo.default_region.toLocaleLowerCase())
          if(regionIndex!=-1){
            this.selectedRegion = this.userRegionList[regionIndex]
          }
          else{
            this.selectedRegion= this.userRegionList[0]
          }
        }
        else{
          this.selectedRegion= this.userRegionList[0]
        }
        this.onChangeRegion()
      }

      else{
        this.selectedLanguauge = this.languaugeList[0]
        this.selectedUnitOfMeasure = this.unitOfMeasureList[0]
        this.selectedTimeZone = this.timeZoneList[0]
        this.selectedRegion= this.userRegionList[0]
        this.onChangeRegion()
      }


      })
    }
      })
      }

    })
  }

  onChangeRegion(){
    console.log('log region',this.selectedRegion);
    if(this.selectedRegion.regioncode.toLowerCase()!='none' && this.selectedRegion.regioncode!=''){
      this.enablePlantCos = true;
      this.transactionService.getactiveplantslist(this.selectedRegion.regioncode).subscribe(plant => {
        console.log(plant);
        this.plantList=[];
        this.plantList.push({cd_plant:'none', plantGSDBCode:'[No Default Selection]',plant_name:'none',plant_type_name:'none'})
        this.plantList.push(...plant)
        if(this.defaultUserInfo!=null){
          if(this.defaultUserInfo.default_gsdbcode!=null){
           console.log(this.defaultUserInfo.default_gsdbcode);
          let plantIndex = this.plantList.findIndex(elpll => elpll.plantGSDBCode.toLowerCase() == this.defaultUserInfo.default_gsdbcode.toLowerCase())
          if(plantIndex!=-1){
            this.selectedPlant = this.plantList[plantIndex]
          }
          else{
            this.selectedPlant= this.plantList[0]
          }
        }
        else{
          this.selectedPlant= this.plantList[0]
        }}
        else{
          this.selectedPlant= this.plantList[0]
        }
        this.onChangePlant()
      })
      this.transactionService.getOwnershipStrategyCode(this.selectedRegion.regioncode).subscribe(cos =>{
        console.log(cos);
        this.cosList=[];
        this.cosList.push({cd_region:'none',cd_ownrshp_strat:0,ownrshp_strat_code:'none',ownrshp_desc:'[No Default Selection]'})
        this.cosList.push(...cos)
        if(this.defaultUserInfo!=null){
          if(this.defaultUserInfo.default_ownershp_strat_code!=null){
          console.log(this.defaultUserInfo.default_ownershp_strat_code);
          let cosIndex = this.cosList.findIndex(elcos => elcos.ownrshp_strat_code.toLowerCase() == this.defaultUserInfo.default_ownershp_strat_code.toLowerCase())
          if(cosIndex!=-1){
            this.selectedCos = this.cosList[cosIndex]
          }
          else{
            this.selectedCos = this.cosList[0]
          }
        }
        else{
          this.selectedCos = this.cosList[0]
        }}
        else{
          this.selectedCos = this.cosList[0]
        }
      })
    }
    else{
      this.enablePlantCos = false;
      this.enableProgram = false;
      this.selectedPlant={cd_plant:'',plantGSDBCode:'',plant_name:'',plant_type_name:''};
      this.selectedCos={cd_region:'',cd_ownrshp_strat:0,ownrshp_strat_code:'',ownrshp_desc:''};
      this.selectedProgram={cd_region:'',plantgsdbcode:'',programname:''};
    }
  }
  onChangePlant(){
    if(this.selectedPlant.plantGSDBCode!='[No Default Selection]' && this.selectedPlant.plantGSDBCode!=''){
      this.enableProgram = true;
      this.transactionService.getprogmlistbasedonplantgsdb(this.selectedPlant.plantGSDBCode,this.selectedRegion.regioncode).subscribe(program => {
      console.log(program);
      this.programList=[];
      this.programList.push({cd_region:'none',plantgsdbcode:'none',programname:'[No Default Selection]'})
      this.programList.push(...program)
      if(this.defaultUserInfo!=null){
      if(this.defaultUserInfo.default_progname!=null){
        console.log(this.defaultUserInfo.default_progname);
        let programIndex = this.programList.findIndex(elpgl => elpgl.programname.toLowerCase() == this.defaultUserInfo.default_progname.toLowerCase())
        if(programIndex!=-1){
          this.selectedProgram = this.programList[programIndex]
        }
        else{
          this.selectedProgram = this.programList[0]
        }
      }
      else{
        this.selectedProgram = this.programList[0]
      }}
      else{
        this.selectedProgram = this.programList[0]
      }
      })
    }
    else{
      this.enableProgram = false;
      this.selectedProgram={cd_region:'',plantgsdbcode:'',programname:''};
    }
  }


  submitUserProfile(){

    this.notificationText = ''
    this.postUserInfo.gppuserid = this.userInfo.gppuserid
    this.postUserInfo.title = this.selectedPrefix.prefix
    this.postUserInfo.name = this.userInfo.name
    this.postUserInfo.company = this.userInfo.company
    this.postUserInfo.designation = this.userInfo.designation
    this.postUserInfo.email = this.userInfo.email
    this.postUserInfo.phone = this.userInfo.phone
    this.postUserInfo.address1 = this.userInfo.address1
    this.postUserInfo.address2 = this.userInfo.address2
    this.postUserInfo.city = this.userInfo.city
    this.postUserInfo.state = this.userInfo.state
    this.postUserInfo.postalcode = this.userInfo.postalcode
    this.postUserInfo.cntrycodeiso = this.selectedCountry.cd_Country
    this.postUserInfo.isforduser = this.userInfo.isforduser
    this.postUserInfo.country = this.selectedCountry.country_name
    this.postUserInfo.createdby = this.userInfo.createdby
    this.postUserInfo.fax = this.userInfo.fax
    this.postUserInfo.isactive = this.userInfo.isactive
    this.postUserInfo.usertype = this.userInfo.usertype

    this.postDefaultUserInfo.gppuserid = this.userInfo.gppuserid
    this.postDefaultUserInfo.default_lang = this.selectedLanguauge.language_code
    this.postDefaultUserInfo.uom = this.selectedUnitOfMeasure.measure
    this.postDefaultUserInfo.timezone = this.selectedTimeZone.timezone

    if(this.selectedRegion.regioncode.toLowerCase()!='none'  && this.selectedRegion.regioncode!=''){
      this.postDefaultUserInfo.default_region = this.selectedRegion.regioncode
    }
    else{
      this.postDefaultUserInfo.default_region = null
    }

    if(this.selectedPlant.cd_plant.toLowerCase()!='none'  && this.selectedPlant.cd_plant!=''){
      this.postDefaultUserInfo.default_gsdbcode = this.selectedPlant.plantGSDBCode
    }
    else{
      this.postDefaultUserInfo.default_gsdbcode = null
    }

    if(this.selectedCos.ownrshp_strat_code.toLowerCase()!='none'  && this.selectedCos.ownrshp_strat_code!=''){
      this.postDefaultUserInfo.default_ownershp_strat_code = this.selectedCos.ownrshp_strat_code
    }
    else{
      this.postDefaultUserInfo.default_ownershp_strat_code = null
    }

    if(this.selectedProgram.plantgsdbcode.toLowerCase()!='none'  && this.selectedProgram.plantgsdbcode!=''){
      this.postDefaultUserInfo.default_progname = this.selectedProgram.programname
    }
    else{
      this.postDefaultUserInfo.default_progname = null
    }
    console.log('postUserInfo', this.postUserInfo);
    console.log('postDefaultUserInfo', this.postDefaultUserInfo);
    this.transactionService.postUserInfo(this.postUserInfo).subscribe(response1 => {
      console.log(response1);
      if(response1.toLowerCase() == 'success'){
        this.transactionService.postDefaultUserData(this.postDefaultUserInfo).subscribe(response2 => {
          console.log(response2);
          if(response2.toLowerCase() == 'success'){
            this.notificationText = 'User Profile Edited Successfully!!'
            this.enableNotificationDialog = true
            this.loadUserDetails();
          }
          else{
            this.notificationText = 'User Profile Not Edited Successfully!!, Try Again'
            this.enableNotificationDialog = true
          }
        })
      }
      else{
        this.notificationText = 'User Profile Not Edited Successfully!!, Try Again'
        this.enableNotificationDialog = true
      }
    })
  }

  cancelUserProfile(){
    this.loadUserDetails()
  }

  isDisableEditUserProfile() : boolean
  {

    if(this.userId==null || this.userId==''|| this.selectedPrefix.prefix==''||  this.userInfo.name==null || this.userInfo.name=='' || this.userInfo.company == null || this.userInfo.company == '' ||
      this.userInfo.phone==null ||this.userInfo.phone=='' || this.userInfo.email==null ||this.userInfo.email=='' ||
      this.userInfo.address1==null ||this.userInfo.address1=='' || this.userInfo.city==null || this.userInfo.city=='' ||
      this.userInfo.state==null || this.userInfo.state=='' ||this.userInfo.designation==null || this.userInfo.designation==''|| this.userInfo.postalcode==null || this.userInfo.postalcode==''||
      this.selectedCountry.country_name=='')

      {
        return true;
      }

      else
      {
        return false;
      }

  }
  okEnbaleNotification()
  {
    this.enableNotificationDialog=false;
  }

}


