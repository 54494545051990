/* tslint:disable */
import { Component, OnInit,ViewChild, ElementRef,Input } from '@angular/core';

import { Router } from '@angular/router';
import {TransactionService} from '../loginpage/Transaction.service';
import {cntrandsudetailspost, componenttypewithcodeanddesc, containerTypeCode, fromdiplayvalues_all, fromdiplayvalues_components, fromdiplayvalues_parts, regiondetails, RevHistFromAPi, selec_prt_pro_details, supplierdetails} from '../loginpage/transactions';
import { DatePipe } from '@angular/common';
import html2pdf from'html2pdf.js';
import { environment } from 'src/environments/environment';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';




@Component({
  selector: '[app-formnew]',
  templateUrl: './formnew.component.html',
  styles: [`

  .wrapper {
    display: grid;
   
    word-wrap: break-word;
    word-break: break-all;
    overflow:hidden;


    border:1px solid black;
   
    
  }

  .box {
   
   
    padding: 0px;
    font-size: 10px;
    

  }

  .box .box {
    border:1px solid black;
  
  }

  .a {
    grid-column: 1 / span 21;
    grid-row: row 1 / span 8 ;
  }

 
  
  table.table1 {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    word-wrap: break-word;
    word-break: break-word;
    
  }
  
  table.table1 th {
    border-bottom:1px solid black;
    border-left:1px solid black;
    text-align: left;
    padding: 1px;
    background-color: #7DC3F7;
    word-wrap: break-word;
    word-break: break-word;
    font-weight: 800;
    font-size: 11px;
  }
  table.table1 td {
    
    border-left: 1px solid black;
    border-bottom:1px solid black;
    font-size: 10px;
    font-weight: 500;
    text-align: left;
    padding: 2px;
    

  }
  
  table.table1 tr:nth-child(even) {
    background-color: #dddddd;
  }



  table.table2 {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    
    word-wrap: break-word;
    word-break: break-word;

    
  }
  
  table.table2 th{
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    text-align: left;
    padding: 1px;
    word-wrap: break-word;
    word-break: break-word;

    font-size: 10px;


    background-color:#B4F9F4
  }
  table.table2 td {
    border-left: 1px solid black;
    font-weight: 600;
    text-align: left;
    padding: 1px;

    font-size: 9px;

  }
  
  table.table2 tr:nth-child(even) {
    background-color: #dddddd;
  }

  table.table2 thead tr{

    background-color:initial
  }
  .b {
    grid-column:  22 / span 5;
    grid-row: row 1 ;
    
    display:grid;
    border:2px solid black;
    text-align:center;
    grid-template-columns: repeat(5,1fr);
    grid-template-rows: subgrid;

  }

  .part-display
  {
   
    
    grid-column:   22 / span 5;
    grid-row: row  1 ; 
    display:grid;
    border-bottom:1px solid black;
    font-size: 11px;
    border-left: 1px solid black;
    text-align:center;
    background-color: #7DC3F7;
    font-weight: 800;

    
  
    
    grid-template-rows: minmax(7x,auto);

  }
  .part-image
  {
    
    grid-column:  22  / span 3;
    grid-row: row 2/ span 4; 
    display:grid;
    border-left:1px solid black;
    border-right:1px solid black;
    font-size: 9px;
    text-align:center;
    word-wrap: break-word;
    word-break: break-word;
    grid-template-rows: minmax(28px,auto);

  }

  .part-slflife
  {
    
    grid-column:  25  / span 2;
    grid-row: row 2; 
    background-color: #B4F9F4;
    display:grid;
    font-size: 10px;
    text-align:center;
    word-wrap: break-word;
    word-break: break-word;
    border-bottom:1px solid black;
    grid-template-rows: minmax(7px,auto);
    place-items: center;


  }
  .part-slflifevalue
  {
    
    grid-column:  25  / span 2;
    grid-row: row 3; 
    display:grid;
    font-size: 9px;
    text-align:center;
    word-wrap: break-word;
    word-break: break-word;
    border-bottom:1px solid black;
    grid-template-rows: minmax(7px,auto);
    place-items: center;


  }
  .part-tempsens
  {

    grid-column:  25  / span 2;
    grid-row: row 4; 
    background-color: #B4F9F4;
    display:grid;
    border-bottom:1px solid black;
    font-size: 10px;
    text-align:center;
    word-wrap: break-word;
    word-break: break-word;
    grid-template-rows: minmax(7px,auto);
 
    place-items: center;




  }
  .part-tempsensvalue
  {
    
    grid-column:  25  / span 2;
    grid-row: row 5; 
    display:grid;
   
    font-size: 9px;
    text-align:center;
    word-wrap: break-word;
    word-break: break-word;
    grid-template-rows: minmax(7px,auto);
    place-items: center;


  }

  .part-l
  {
   
    grid-column:  22/ span 1;
    grid-row: row 6; 
    background-color: #B4F9F4;
    display:grid;
    border-left: 1px solid black;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    font-size: 10px;
    text-align:center;
    word-wrap: break-word;
    word-break: break-word;
    grid-template-rows: minmax(7px,auto);
    place-items: center;



  }
  .part-lvalue
  {

    grid-column:  22/ span 1;
    grid-row: row 7; 
    display:grid;
    border-left:1px solid black;
    font-size: 9px;
    text-align:center;
    word-wrap: break-word;
    word-break: break-word;
    grid-template-rows: minmax(7px,auto);
    place-items: center;



  }


  .part-w
  {

    grid-column:  23/span 1 ;
    grid-row: row 6; 
    background-color: #B4F9F4;
    display:grid;
    border-top:1px solid black;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    font-size: 10px;
    text-align:center;
    word-wrap: break-word;
    word-break: break-word;
    grid-template-rows: minmax(7px,auto);
    place-items: center;



  }
  .part-wvalue
  {

    grid-column:  23 / span 1 ;
    grid-row: row 7;
    display:grid;
    border-left:1px solid black;
    font-size: 9px;
  
    text-align:center;
    word-wrap: break-word;
    word-break: break-word;
    grid-template-rows: minmax(7px,auto);
    place-items: center;

 

  }



  .part-h
  {
   
    grid-column:  24/span 1 ;
    grid-row: row 6; 
    background-color: #B4F9F4;
    display:grid;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    border-top: 1px solid black;
    border-right: 1px solid black;
    font-size: 10px;
    text-align:center;
    word-wrap: break-word;
    word-break: break-word;
    grid-template-rows: minmax(7px,auto);
    place-items: center;



  }
  .part-hvalue
  {
 
    grid-column:  24/ span 1 ;
    grid-row: row 7; 
    display:grid;
    border-left:1px solid black;
    border-right: 1px solid black;
    font-size: 9px;
    text-align:center;
    word-wrap: break-word;
    word-break: break-word;
    grid-template-rows: minmax(7px,auto);
    place-items: center;


  }


  .part-wt
  {
   
    grid-column:  25/ span 1 ;
    grid-row: row 6; 
    background-color: #B4F9F4;
    display:grid;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    font-size: 10px;
    text-align:center;
    word-wrap: break-word;
    word-break: break-word;
    grid-template-rows: minmax(7px,auto);
    place-items: center;

    


  }
  .part-wtvalue
  {

    grid-column:  25/span 1 ;
    grid-row: row 7; 
    display:grid;
  
    font-size: 9px;
    text-align:center;
    word-wrap: break-word;
    word-break: break-word;
    grid-template-rows: minmax(7px,auto);
    place-items: center;

  }


  .part-wtst
  {
  
    grid-column:  26/ span 1 ;
    grid-row: row 6; 
    background-color: #B4F9F4;
    border-top: 1px solid black;
    display:grid;
    border-left:1px solid black;
    border-bottom: 1px solid black;
    font-size: 10px;
    text-align:center;
    word-wrap: break-word;
    word-break: break-word;
    grid-template-rows: minmax(7px,auto);
    place-items: center;


  }
  .part-wtstvalue
  {
  
    grid-column:  26/ span 1 ;
    grid-row: row 7; 

    display:grid;
    border-left:1px solid black;
    font-size: 9px;
    text-align:center;
    word-wrap: break-word;
    word-break: break-word;
    grid-template-rows: minmax(7px,auto);
    place-items: center;


  }

  .part-crrprt
  {

    grid-column: 22/ span 3 ;
    grid-row: row 8/span 1; 
    background-color: #B4F9F4;
    border-right: 1px solid black;
    border-left:1px solid black;
    display:grid;
    border-top:1px solid black;
    font-size: 10px;
    text-align:center;
    word-wrap: break-word;
    word-break: break-word;
    grid-template-rows: minmax(7px,auto);
    place-items: center;

  }
  .part-crrprtvalue
  {

    grid-column: 25/span 2 ;
    grid-row: row 8/ span 1; 


    display:grid;
    border-top:1px solid black;
   
    font-size: 9px;
    text-align:center;
    word-wrap: break-word;
    word-break: break-word;
    grid-template-rows: minmax(7px,auto);
    place-items: center;


  }

  .container{
    grid-column:  14 / span 13;
    grid-row: row 9;
    
    text-align:center;
    font-size:11px;
    border-top:1px solid black;
    border-bottom:1px solid black;
    border-left: 1px solid black;
    background-color: #7DC3F7;
    font-weight: 800;


    grid-template-rows: minmax(7px,auto);
    place-items: center;
  }

  .c {
    grid-column:  14 / span 5;
    grid-row: row 3/span 6;
       
    display:grid;
    
    text-align:center;
    grid-template-columns: repeat(5,1fr);
    grid-template-rows: subgrid;
  }


  
  .cosname{



    grid-column: 14/span 5 ;
    grid-row: row 10/span 2; 


    display:grid;
    
    text-align:center;
    font-size:10px;
    place-items: center;
    border-left:1px solid black;
    border-bottom:1px solid black;
    word-wrap: normal;
    
    
    grid-template-rows: minmax(14px,auto);

  }


  .cmmscode{


   
    border-left:1px solid black;
    grid-column: 14/span 3 ;
    grid-row: row 12/span 1; 
    background-color: #B4F9F4;
    border-bottom: 1px solid black;

    display:grid;
   
    text-align:center;
    font-size:10px;
    place-items: center;
    grid-template-rows: minmax(7px,auto);

  }

  .cmmscodevalue{

    

    grid-column: 14/span 3 ;
    grid-row: row 13/span 1; 


    display:grid;
  
    text-align:center;
    font-size:9px;
    place-items: center;
    grid-template-rows: minmax(7px,auto);
    border-left:1px solid black;
    border-bottom:1px solid black;
    white-space: normal;
    word-wrap: break-word; 
    word-break: break-word;

  }

  .descrip{

    border-left:1px solid black;
    grid-column: 17/span 2 ;
    grid-row: row 12/span 1; 
    background-color: #B4F9F4;
    border-bottom: 1px solid black;

    display:grid;
    
    text-align:center;
    font-size:10px;
    place-items: center;
    grid-template-rows: minmax(7px,auto);


  }

  .descripvalue{


 
    grid-column: 17/span 2 ;
    grid-row: row 13/span 1; 

    border-left: 1px solid black;
    display:grid;
    border-bottom: 1px solid black;
    text-align:center;
    font-size:9px;
    place-items: center;
    word-wrap: break-word;
    word-break: break-word;
    grid-template-rows: minmax(7px,auto);
    grid-template-columns:minmax(100px,200px);

  }



  .contperlay{


    
    border-right:1px solid black;
    grid-column: 14/span 2 ;
    grid-row: row 14/span 2; 
    background-color: #B4F9F4;

    border-left: 1px solid black;
    display:grid;

    text-align:center;
    font-size:10px;
    place-items: center;
    grid-template-rows: minmax(14px,auto);


  }


  .contperlayvalue{


 
    grid-column: 16/span 1 ;
    grid-row: row 14/span 2; 


    display:grid;
   
    text-align:center;
    font-size:9px;
    place-items: center;
    grid-template-rows: minmax(14px,auto);


  }


  .maxlayers{


    border-left:1px solid black;
    border-right:1px solid black;    
    grid-column: 17/span 1 ;
    grid-row: row 14/span 2; 
    background-color: #B4F9F4;


    display:grid;
  
    text-align:center;
    font-size:10px;
    place-items: center;
    grid-template-rows: minmax(14px,auto);


  }


  .maxlayersvalue{



   
    grid-column: 18/span 1 ;
    grid-row: row 14/span 2; 


    display:grid;
   
    text-align:center;
    font-size:9px;
    place-items: center;
    grid-template-rows: minmax(14px,auto);


  }





  .h{

    grid-column:  19 / span 3;
    grid-row: row 3/span 6;
       
    display:grid;
    border:2px solid black;
    text-align:center;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: subgrid;
   

  }


  .rettype
  {
    grid-column:  19 / span 3;
    grid-row: row 10/span 2;
   

    border-bottom: 1px solid black;
    display:grid;
    border-left:1px solid black;
    text-align:center;
    font-size:10px;
    place-items: center;
    grid-template-rows: minmax(14px,auto);

  }


  .partsorcont
  {
    grid-column:  19 / span 3;
    grid-row: row 12/span 1;

   
    border-bottom: 1px solid black;

    background-color: #B4F9F4;

    border-left: 1px solid black;
    display:grid;

    text-align:center;
    font-size:10px;
    place-items: center;
    grid-template-rows: minmax(7px,auto);

  }


  .partscontvalue
  {

    grid-column:  19 / span 3;
    grid-row: row 13/span 1;

    border-left: 1px solid black;

    display:grid;
    border-bottom:1px solid black;
    text-align:center;
    font-size:9px;
    place-items: center;
    grid-template-rows: minmax(7px,auto);

  }


  .maxcontsu
  {

    grid-column:  19 / span 2;
    grid-row: row 14/span 2;

   


    background-color: #B4F9F4;


    display:grid;
    border-left:1px solid black;
    text-align:center;
    font-size:10px;
    
    place-items: center;
    grid-template-rows: minmax(14px,auto);

  }


  .maxcontsuvalue
  {

    grid-column:  21 / span 1;
    grid-row: row 14/span 2;

    


    display:grid;
   
    text-align:center;
    font-size:9px;
    border-left:1px solid black;
  
    place-items: center;
    grid-template-rows: minmax(14px,auto);

  }




  .i{

    grid-column:  22 / span 5;
    grid-row: row 3/span 6;
       font-size:7px;
    display:grid;
    border:2px solid black;
    text-align:center;
    grid-template-columns: repeat(5,1fr);
    grid-template-rows: subgrid;
   

  }


  .empty{


    
    grid-column:  22 / span 3;
    grid-row: row 10/span 1;

    display:grid;
    
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);
    border-bottom:1px solid black;
    border-left:1px solid black;
    place-items: center;
    background-color: #B4F9F4;

  }


  .emptyvalue{
    
    grid-column:  25 / span 2;
    grid-row: row 10/span 1;

    display:grid;
    border-bottom:1px solid black;
    border-left:1px solid black;
    text-align:center;
    font-size:9px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;

  }


  .contcolor
  {
    
    grid-column:  22 / span 3;
    grid-row: row 11/span 1;

    


    background-color: #B4F9F4;


    display:grid;
    border-bottom :1px solid black;
    border-left:1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;

  }


  .contcolorvalue
  {
    
    grid-column:  25 / span 2;
    grid-row: row 11/span 1;

    display:grid;
 
    text-align:center;
    font-size:9px;
    grid-template-rows: minmax(7px,auto);
    border-bottom:1px solid black;
    border-left:1px solid black;
    place-items: center;

    white-space: normal;
    word-wrap: break-word; 
    word-break: break-word;
  }

  .dimension{

    
    grid-column:  22 / span 4;
    grid-row: row 12/span 1;

   


    background-color: #B4F9F4;
    border-left: 1px solid black;

    display:grid;
    border-bottom:1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);


    place-items: center;

  }

  .diml
  {
    grid-column:  22 / span 1;
    grid-row: row 13/span 1;

    
    background-color: #B4F9F4;


    display:grid;
    border-left:1px solid black;
    border-bottom:1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);


    place-items: center;

  }


  .dimlvalue
  {
    grid-column:  22 / span 1;
    grid-row: row 14/span 1;

    display:grid;
    border-left: 1px solid black;
    text-align:center;
    font-size:9px;
    grid-template-rows: minmax(7px,auto);


    place-items: center;
    

  }


  .dimw
  {
    grid-column:  23 / span 1;
    grid-row: row 13/span 1;

    
    background-color: #B4F9F4;


    display:grid;
    border-left:1px solid black;
    border-bottom:1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);


    place-items: center;

  }


  .dimwvalue
  {
    grid-column:  23 / span 1;
    grid-row: row 14/span 1;

    display:grid;
    border-left:1px solid black;
    text-align:center;
    font-size:9px;
    grid-template-rows: minmax(7px,auto);


    place-items: center;
    
  }


  .dimh
  {
    grid-column:  24 / span 1;
    grid-row: row 13/span 1;

    
    background-color: #B4F9F4;


    display:grid;
    border-left:1px solid black;
    border-bottom:1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);


    place-items: center;

  }


  .dimhvalue
  {
    grid-column:  24 / span 1;
    grid-row: row 14/span 1;

    display:grid;
    border-left:1px solid black;
    text-align:center;
    font-size:9px;
    grid-template-rows: minmax(7px,auto);


    place-items: center;
    
  }


  .dimfld
  {
    grid-column:  25 / span 1;
    grid-row: row 13/span 1;

    
    background-color: #B4F9F4;
    border-bottom: 1px solid black;

    display:grid;
    border-left:1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);


    place-items: center;

  }


  .dimfldvalue
  {
    grid-column:  25 / span 1;
    grid-row: row 14/span 1;



    display:grid;
    border-left:1px solid black;
    font-size:9px;
    grid-template-rows: minmax(14px,auto);


    place-items: center;
    
  }


  .dimtarwt
  {
    grid-column:  26 / span 1;
    grid-row: row 12/span 2;

    
    background-color: #B4F9F4;


    display:grid;
    border-left:1px solid black;
    
    border-bottom:1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(14px,auto);


    place-items: center;

  }


  .dimtarwtvalue
  {
    grid-column:  26 / span 1;
    grid-row: row 14/span 1;



    display:grid;
    border-left:1px solid black;

    text-align:center;
    font-size:9px;
    grid-template-rows: minmax(7px,auto);


    place-items: center;
    
  }


  .contgrsswt
  {
    grid-column:  22 / span 3;
    grid-row: row 15/span 1;

    
    background-color: #B4F9F4;

    border-left: 1px solid black;
    display:grid;
    border-top:1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);


    place-items: center;


  
  }


  .contgrswtvalue
  {
    grid-column:  25 / span 2;
    grid-row: row 15/span 1;

    display:grid;
    border-left:1px solid black;
    border-top:1px solid black;
    text-align:center;
    font-size:9px;
    grid-template-rows: minmax(7px,auto);


    place-items: center;

  }



  .shippingunit{
    grid-column:  14 / span 13;
    grid-row: row 16;
    display:grid;
    text-align:center;
    font-size:11px;
    grid-template-rows: minmax(7px,auto);
    border-top: 1px solid black;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    background-color: #7DC3F7;
    font-weight: 800;


    place-items: center;
  }


  .j
  {
    grid-column:  14 / span 13;
    grid-row: row 17/ span 12;
    display:grid;
    text-align:left;
    grid-template-rows: minmax(84px,auto);

  }


  .shippingheader
  {

    grid-column:  14 / span 13;
    grid-row: row 29;
    display:grid;
    border-bottom: 1px solid black;
    border-left: 1px solid black;
    border-top: 1px solid black;
    text-align:center;
    font-size:11px;
    grid-template-rows: minmax(7px,auto);


    background-color: #7DC3F7;
    font-weight: 800;


    place-items: center;

  }


  .k
  {
    grid-column:  14 / span 13;
    grid-row: row 25/ span 7;
  
    font-size:7px;
    display:grid;
    border:2px solid black;
    text-align:center;
    grid-template-columns: repeat(13,1fr);
    grid-template-rows: subgrid;

  }


  .tested
  {
    grid-column:  14 / span 1;
    grid-row: row 30/span 1;

    
    background-color: #B4F9F4;

    border-left: 1px solid black;
    display:grid;
    border-right:1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);


    place-items: center;

  }

  .testedvalue
  {
    grid-column:  15 / span 1;
    grid-row: row 30/span 1;

    display:grid;
    border-right:1px solid black;
    text-align:center;
    font-size:9px;
    grid-template-rows: minmax(7px,auto);


    place-items: center;

  }

  .pkgsignoff
  {
    grid-column:  16 / span 1;
    grid-row: row 30/span 1;

   
    background-color: #B4F9F4;


    display:grid;
    border-right:1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);



    place-items: center;

  }
  .pkgsignoffvalue
  {
    grid-column:  17 / span 1;
    grid-row: row 30/span 1;

    display:grid;
 
    text-align:center;
    font-size:9px;
    grid-template-rows: minmax(7px,auto);


    place-items: center;

  }
  .sbupl
  {
    grid-column:  18 / span 1;
    grid-row: row 30/span 1;

    
    background-color: #B4F9F4;

    border-left: 1px solid black;
    display:grid;
    border-right:1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;



  }
  .sbuplvalue
  {
    grid-column:  19 / span 1;
    grid-row: row 30/span 1;

  


    display:grid;
   
    text-align:center;
    font-size:9px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;
  }
  .er
  {
    grid-column:  20 / span 1;
    grid-row: row 30/span 2;

    
    background-color: #B4F9F4;

    border-bottom: 1px solid black;
    display:grid;
    border-left:1px solid black;
    border-right:1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(14px,auto);
    place-items: center;


  }
  .ervalue
  {
    grid-column:  20 / span 1;
    grid-row: row 32/span 1;
    border-left:1px solid black;
    display:grid;
    border-right: 1px solid black;
    text-align:center;
    font-size:9px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;


  }

  
  .dimensionshp
  {
    grid-column:  21 / span 4;
    grid-row: row 30/span 1;

    
    background-color: #B4F9F4;


    display:grid;
    border-bottom:1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;


  }

  .dimensionl
  {
    grid-column:  21 / span 1;
    grid-row: row 31/span 1;

    
    background-color: #B4F9F4;
    display:grid;
    border-bottom:1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;


  }


  .dimensionlvalue
  {
    grid-column:  21 / span 1;
    grid-row: row 32/span 1;

   
    display:grid;
    text-align:center;
    font-size:9px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;


  }
  .dimensionw
  {
    grid-column:  22 / span 1;
    grid-row: row 31/span 1;

    
    background-color: #B4F9F4;


    display:grid;
    border-bottom:1px solid black;
    border-left:1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;


  }


  .dimensionwvalue
  {
    grid-column: 22 / span 1;
    grid-row: row 32/span 1;

    
    display:grid;
    border-left:1px solid black;
    text-align:center;
    font-size:9px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;

  }

  .dimensionh
  {
    grid-column:  23 / span 1;
    grid-row: row 31/span 1;

    
    background-color: #B4F9F4;

    border-bottom:1px solid black;
    display:grid;
    border-left:1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;


  }


  .dimensionhvalue
  {
    grid-column:  23 / span 1;
    grid-row: row 32/span 1;

    display:grid;
    border-left:1px solid black;
    text-align:center;
    font-size:9px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;

    
  }


  .dimensionnstfld
  {
    grid-column:  24 / span 1;
    grid-row: row 31/span 1;

    
    background-color: #B4F9F4;

    border-bottom:1px solid black;
    display:grid;
    border-left:1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;


  }


  .dimensionnstfldvalue
  {
    grid-column:  24 / span 1;
    grid-row: row 32/span 1;

    display:grid;
    border-left:1px solid black;
    text-align:center;
    font-size:9px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;

    
  }


  .tarwt
  {
    grid-column:  25 / span 1;
    grid-row: row 30/span 2;

    
    background-color: #B4F9F4;
    display:grid;
    border-left:1px solid black;
    border-bottom:1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(14px,auto);
    place-items: center;


  }
  .tarwtvalue
  {
    grid-column:  25 / span 1;
    grid-row: row 32/span 1;

    display:grid;
    border-left:1px solid black;
    text-align:center;
    font-size:9px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;


  }
  .grswt
  {
    grid-column:  26 / span 1;
    grid-row: row 30/span 2;

    
    background-color: #B4F9F4;

    border-bottom:1px solid black;
    display:grid;
    border-left:1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(14px,auto);
    place-items: center;


  }


  .grswtvalue
  {
    grid-column:  26 / span 1;
    grid-row: row 32/span 1;

    display:grid;
    border-left:1px solid black;
    text-align:center;
    font-size:9px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;


  }


  .cmmscddes
  {
    grid-column:  14 / span 4;
    grid-row: row 31/span 1;

    
    background-color: #B4F9F4;

    border-left: 1px solid black;
    display:grid;
    border-top:1px solid black;
    border-bottom:1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;


  }


  .cmmscddesvalue
  {
    grid-column:  14 / span 4;
    grid-row: row 32/span 1;

    display:grid;
    
    border-bottom:1px solid black;
    text-align:center;
    font-size:9px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;
    border-left: 1px solid black;
    white-space: normal;
    word-wrap: break-word; 
    word-break: break-word;


  }


  .partssu
  {
    grid-column:  18 / span 2;
    grid-row: row 31/span 1;

    
    background-color: #B4F9F4;

    border-top:1px solid black;
    display:grid;
    border-bottom:1px solid black;
    border-left:1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;


  }


  .partssuvalue
  {
    grid-column:  18 / span 2;
    grid-row: row 32/span 1;

    display:grid;
    border-bottom:1px solid black;
    border-left:1px solid black;
    text-align:center;
    font-size:9px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;


  }


  .mot
  {
    grid-column:  14 / span 4;
    grid-row: row 33/span 1;

    
    background-color: #B4F9F4;

    border-left: 1px solid black;
    display:grid;
    border-bottom:1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;


  }


  .motvalue
  {
    grid-column:  18 / span 2;
    grid-row: row 33/span 1;

    display:grid;
    border-left:1px solid black;
    border-bottom:1px solid black;
    text-align:center;
    font-size:9px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;

    white-space: normal;
    word-wrap: break-word; 
    word-break: break-word;


  }


  .pallettype
  {
    grid-column:  20 / span 2;
    grid-row: row 33/span 1;

    
    background-color: #B4F9F4;
    border-top:1px solid black;
    border-left:1px solid black;
    display:grid;
    border-bottom:1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;


  }


  .pallettypevalue
  {
    grid-column:  22 / span 4;
    grid-row: row 33/span 1;

    display:grid;
    border-left:1px solid black;
    text-align:center;
    font-size:9px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;
    border-top:1px solid black;
    border-bottom:1px solid black;
    white-space: normal;
    word-wrap: break-word; 
    word-break: break-word;


  }


  .wood
  {
    grid-column:  14 / span 4;
    grid-row: row 34/span 1;

    
    background-color: #B4F9F4;

    border-left: 1px solid black;
    display:grid;
    border-bottom:1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;


  }


  .woodvalue
  {
    grid-column:  18 / span 1;
    grid-row: row 34/span 1;

    display:grid;
    border-left:1px solid black;
    text-align:center;
    font-size:9px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;

    white-space: normal;
    word-wrap: break-word; 
    word-break: break-word;


  }


  .stamp
  {
    grid-column:  19 / span 6;
    grid-row: row 34/span 1;

    
    background-color: #B4F9F4;


    display:grid;
    border-left:1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;


  }


  .stampvalue
  {
    grid-column:  25 / span 1;
    grid-row: row 34/span 1;

    display:grid;
    border-left:1px solid black;
    text-align:center;
    font-size:9px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;

    white-space: normal;
    word-wrap: break-word; 
    word-break: break-word;

  }



  .release
  {
    grid-column:  26 / span 1;
    grid-row: row 33/span 2;

    
    background-color: #B4F9F4;
    border-top:1px solid black;
    border-bottom:1px solid black;
    display:grid;
    border-left:1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(14px,auto);
    place-items: center;


  }


  .releasevalue
  {
    grid-column:  26 / span 1;
    grid-row: row 35/span 2;

    display:grid;
    border-left:1px solid black;
    text-align:center;
    font-size:9px;
    grid-template-rows: minmax(14px,auto);
    place-items: center;


  }


  .dnggoods
  {
    grid-column:  14 / span 3;
    grid-row: row 35/span 1;

    
    background-color: #B4F9F4;

    border-left: 1px solid black;
    display:grid;
   
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;


  }


  .dnggoodsvalue
  {
    grid-column:  17 / span 1;
    grid-row: row 35/span 1;

    display:grid;
    border-left:1px solid black;
    text-align:center;
    font-size:9px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;

    white-space: normal;
    word-wrap: break-word; 
    word-break: break-word;


  }


  .shipdtls
  {
    grid-column:  18 / span 8;
    grid-row: row 35/span 1;

    
    background-color: #B4F9F4;


    display:grid;
    border-left:1px solid black;
    border-top:1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;


  }


  .shipdtlsvalue
  {
    grid-column:  14 / span 12;
    grid-row: row 36/span 1;

    display:grid;
    border-top: 1px solid black;
    border-left: 1px solid black;
    text-align:left;
    font-size:9px;
    grid-template-rows: minmax(7px,auto);
    grid-template-columns:minmax(20px,550px);
    place-items: center;
    white-space: normal;
    word-wrap: break-word; 
    word-break: break-word;


  }


  .l
  {
    grid-column:  1 / span 13;
    grid-row: row 2;

    font-size:7px;
    display:grid;
    border:2px solid black;
    text-align:center;
    grid-template-columns: repeat(13,1fr);
    grid-template-rows: subgrid;
    
  }


  .supplierheader
  {
    grid-column:1/ span 13;
    grid-row: 10/span 1;
    display:grid;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    text-align:center;
    font-size:11px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;
    background-color: #7DC3F7;
    font-weight: 800;


  }


  .m
  {
    grid-column:  1 / span 13;
    grid-row: row 3 / span 4;

    font-size:7px;
    display:grid;
    border:2px solid black;
    text-align:center;
    grid-template-columns: repeat(13,1fr);
    grid-template-rows: minmax(35px,auto);
    
  }


  .supplierdetails
  {
    grid-column:1/ span 13;
    grid-row: 11/span 4;
    display:grid;
    border-top: 1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(28px,auto);
    place-items: center;
    height:20px;

    white-space: normal;
    word-wrap: break-word; 
    word-break: break-word;
    

  }


  .suppliername
  {

    grid-column:1/ span 7;
    grid-row: 11;
    display:grid;
    text-align:left;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);
    height:20px;

    white-space: normal;
    word-wrap: break-word; 
    word-break: break-word;
  }

  


  .supplieraddress1
  {

    grid-column:1/ span 7;
    grid-row: 12;
    display:grid;
    text-align:left;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);

    white-space: normal;
    word-wrap: break-word; 
    word-break: break-word;
   

  }

  .supplierempty
  {

    grid-column:1/ span 7;
    grid-row: 13;
    display:grid;
    text-align:left;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);

  }

  .suppliercountry
  {

    grid-column:1/ span 7;
    grid-row: 14;
    display:grid;
    text-align:left;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);
    border-bottom: 1px solid black;
    white-space: normal;
    word-wrap: break-word; 
    word-break: break-word;

  }


  .suppliercontactname
  {

    grid-column:8/ span 6;
    grid-row: 11;
    display:grid;
    text-align:left;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);

    white-space: normal;
    word-wrap: break-word; 
    word-break: break-word;

  }
  .suppliercontactfunction
  {

    grid-column:8/ span 6;
    grid-row: 12;
    display:grid;
    text-align:left;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);

    white-space: normal;
    word-wrap: break-word; 
    word-break: break-word;
  }
  .supplieremail
  {

    grid-column:8/ span 6;
    grid-row: 13;
    display:grid;
    text-align:left;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);

    white-space: normal;
    word-wrap: break-word; 
    word-break: break-word;

  }
  .supplierphonefax
  {

    grid-column:8/ span 6;
    grid-row: 14;
    display:grid;
    text-align:left;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);
    border-bottom: 1px solid black;
    white-space: normal;
    word-wrap: break-word; 
    word-break: break-word;

  }




  .imageheader
  {
    grid-column:  1 / span 13;
    grid-row: row 7;

    font-size:9px;
    display:grid;
    border:2px solid black;
    text-align:center;
    grid-template-columns: repeat(13,1fr);
    grid-template-rows: subgrid;

  }



  .interiorphoto
  {
    grid-column:  1 / span 7;
    grid-row: row 14;
    display:grid;
  
    text-align:center;
    font-size:11px;
    grid-template-rows: minmax(7px,auto);


    background-color: #7DC3F7;
    font-weight: 800;

  }


  .exteriorphoto
  {
    grid-column:  8 / span 6;
    grid-row: row 14;
    display:grid;
    text-align:center;
    font-size:11px;
    grid-template-rows: minmax(7px,auto);
    border-left: 1px solid black;

    background-color: #7DC3F7;
    font-weight: 800;

  }


  .interiorphotovalue
  {
    grid-column:  1 / span 7;
    grid-row: row 15/ span 9;

    display:grid;
   

    border-top: 1px solid black;
    border-bottom:1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(63px,auto);
    
  }


  .exteriorphotovalue
  {
    grid-column:  8 / span 6;
    grid-row: row 15/ span 9;
    border-bottom: 1px solid black;
    display:grid;
    border-top: 1px solid black;
    border-left: 1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(63px,auto);
    
  }


  .photocomments
  {


    grid-column:  1/ span 7;
    grid-row: row 18/ span 2;

    font-size:10px;
    display:grid;

    text-align:center;
    grid-template-columns: repeat(7,1fr);
    grid-template-rows: subgrid
    place-items: center;


  }


  .photocommlabel
  {
    grid-column:  1 / span 3;
    grid-row: row 24/ span 1;
    
    background-color: #B4F9F4;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    display:grid;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,10px);
    place-items: center;


  }





  .photocommvalue
  {


    grid-column:  1 / span 7;
    grid-row: row 25/ span 1;
    display:grid;
    
    text-align:left;
    font-size:9px;
    grid-template-rows: minmax(7px,auto);
    grid-template-columns: minmax(10px,220px);


    place-items: center;

    white-space: normal;
    word-wrap: break-word; 
    word-break: break-word;

  }


  .n
  {


    grid-column:  8/ span 6;
    grid-row: row 18/ span 2;

    font-size:7px;
    display:grid;
    border:2px solid black;
    text-align:center;
    grid-template-columns: repeat(6,1fr);
    grid-template-rows: subgrid

  }


  .boxcompress
  {
    grid-column:  8 / span 3;
    grid-row: row 24/ span 1;
  
    
    background-color: #B4F9F4;

    display:grid;
    border-right: 1px solid black;
    border-left: 1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;


  }


  .boxcompressvalue
  {
    grid-column:  11 / span 3;
    grid-row: row 24/ span 1;
    display:grid;
   
    text-align:center;
    font-size:9px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;

  }


  .edgecrush
  {
    grid-column:  8 / span 3;
    grid-row: row 25/ span 1;
    
    background-color: #B4F9F4;
    border-left: 1px solid black;
    border-top: 1px solid black;
    display:grid;
    border-right: 1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;


  }


  .edgecrushvalue
  {
    grid-column:  11 / span 3;
    grid-row: row 25/ span 1;
    display:grid;
    border-top: 1px solid black;
    text-align:center;
    font-size:9px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;


  }


  .imageheader1
  {
    grid-column:  1 / span 13;
    grid-row: row 20;

    font-size:7px;
    display:grid;
    border:2px solid black;
    text-align:center;
    grid-template-columns: repeat(13,1fr);
    grid-template-rows: subgrid;

  }



  .labelreqphoto
  {
    grid-column:  1 / span 7;
    grid-row: row 26;
    display:grid;
    border-top: 1px solid black;
   
    text-align:center;
    font-size:11px;
    grid-template-rows: minmax(7px,auto);


    background-color: #7DC3F7;
    font-weight: 800;

  }


  .shpunitphoto
  {
    grid-column:  8 / span 6;
    grid-row: row 26;
    display:grid;
    border-top: 1px solid black;
    text-align:center;
    font-size:11px;
    grid-template-rows: minmax(7px,auto);
    border-left: 1px solid black;

    background-color: #7DC3F7;
    font-weight: 800;

  }


  .labelreqphotovalue
  {
    grid-column:  1 / span 7;
    grid-row: row 27/ span 9;
  
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    display:grid;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(63px,auto);
    
  }


  .shpunitphotovalue
  {
    grid-column:  8 / span 6;
    grid-row: row 27/ span 9;
    border-bottom: 1px solid black;
    display:grid;
    border-top: 1px solid black;
    border-left: 1px solid black;
    text-align:center;
    font-size:90px;
    grid-template-rows: minmax(63px,auto);
    
  }



  .approver
  {


    grid-column:  14 / span 13;
    grid-row: row 37;
    display:grid;
    border-top: 1px solid black;
    text-align:center;
    font-size:11px;
    grid-template-rows: minmax(7px,auto);
    border-bottom: 1px solid black;

    background-color: #7DC3F7;
    font-weight: 800;

  }


  .approverlist
  {
    grid-column:  14 / span 13;
    grid-row: row 33/span 4;

    font-size:9px;
    display:grid;
    border:2px solid black;
    text-align:center;
    grid-template-columns: repeat(13,1fr);
    grid-template-rows: subgrid;

  }


  .approverlistvalues
  {


    grid-column: 1 / span 8;
    grid-row: row 33/span 4;

    font-size:9px;
    display:grid;
    border-right: 1px solid black;
    text-align:center;
    grid-template-columns: repeat(4,2fr);
    grid-template-rows: subgrid;

  }

  .approverlistvalues1{



    grid-column:  14 / span 2;
    grid-row: row 38/span 1;

    display:grid;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;




  }


  .approverlistvalues2{



    grid-column: 16 / span 2;
    grid-row: row 38/span 1;

    display:grid;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;






  }






  .approverlistvalues3{



    grid-column: 18 / span 2;
    grid-row: row 38/span 1;

    display:grid;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);
    place-items: center;






  }






  .approverlistvalues4{



    grid-column: 20 / span 2;
    grid-row: row 38/span 1;

    display:grid;
    border-bottom: 1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);

    place-items: center;




  }






  .approverlistvalues5{



    grid-column: 14 / span 2;
    grid-row: row 39/span 1;

    display:grid;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);


    place-items: center;



  }






  .approverlistvalues6{



    grid-column: 16 / span 2;
    grid-row: row 39/span 1;

    display:grid;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);


    place-items: center;



  }






  .approverlistvalues7{



    grid-column: 18 / span 2;
    grid-row: row 39/span 1;

    display:grid;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);


    place-items: center;



  }






  .approverlistvalues8{



    grid-column: 20 / span 2;
    grid-row: row 39/span 1;

    display:grid;
    border-bottom: 1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);


    place-items: center;



  }






  .approverlistvalues9{



    grid-column: 14 / span 2;
    grid-row: row 40/span 1;

    display:grid;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);


    place-items: center;


  }






  .approverlistvalues10{



    grid-column: 16 / span 2;
    grid-row: row 40/span 1;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);


    place-items: center;



  }






  .approverlistvalues11{



    grid-column: 18 / span 2;
    grid-row: row 40/span 1;

    display:grid;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);



    place-items: center;


  }






  .approverlistvalues12{



    grid-column: 20 / span 2;
    grid-row: row 40/span 1;

    display:grid;
    border-bottom: 1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);

    place-items: center;




  }






  .approverlistvalues13{



    grid-column: 14 / span 2;
    grid-row: row 41/span 1;

    display:grid;
    border-right: 1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);


    place-items: center;



  }






  .approverlistvalues14{



    grid-column: 16 / span 2;
    grid-row: row 41/span 1;
    display:grid;
    border-right: 1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);

    place-items: center;




  }






  .approverlistvalues15{



    grid-column: 18 / span 2;
    grid-row: row 41/span 1;

    display:grid;
    border-right: 1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);


    place-items: center;



  }






  .approverlistvalues16{



    grid-column: 20 / span 2;
    grid-row: row 41/span 1;

    display:grid;
   
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,auto);


    place-items: center;


  }












  .lastapprovalstat
  {
    grid-column: 22/ span 5;
    grid-row: row 38/span 4;

    display:grid;
    border-left: 1px solid black;
    text-align:center;
    font-size:10px;
    font-weight:bold
    grid-template-rows: minmax(28px,auto);


    place-items: center;


  }


  .maincomments
  {

    grid-column: 1/ span 13;
    grid-row: row 31/span 6;

    font-size:9px;
    text-align:center;
   
    grid-template-columns: repeat(7,1fr);
    grid-template-rows: subgrid



    place-items: center;

    white-space: normal;
    word-wrap: break-word; 
    word-break: break-word;




    
  }


  .maincommentslabel
  {
    grid-column:  1 / span 3;
    grid-row: row 36/ span 1;
   
    background-color: #B4F9F4;


    display:grid;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    text-align:center;
    font-size:10px;
    grid-template-rows: minmax(7px,10px);


    place-items: center;



  }


  .maincommentsvalue
  {


    grid-column:  1 / span 13;
    grid-row: row 37/ span 5;
    display:grid;
    

    border-right:1px solid black;
    text-align:left;
    font-size:9px;
    grid-template-rows: minmax(35px,auto);
    grid-template-columns: minmax(30px,480px);


    place-items: center;

    white-space: normal;
    word-wrap: break-word; 
    word-break: break-word;


  }


  




  






  ::ng-deep .ql-toolbar.ql-snow {
    padding: 0px;
    border: none;
}

::ng-deep .ql-container.ql-snow {
    border-top: 1px solid #ccc;
    height: 320px; 
}






  .d{
    grid-column: col 3 / span 2;
    grid-row: row 2;
    grid-gap: 7px;
    grid-template-columns: 1fr 1fr;
  }

  .e {
    grid-column: 1 / 3;
    grid-row: 1;
  }

  .f {
    grid-column: 1;
    grid-row: 2;
  }

  .g {
    grid-column: 2;
    grid-row: 2;
  }
  `]
})
export class FormnewComponent implements OnInit {


 // @Input('app-formnew') proposal_id_in: any; //can be used if needed to pass proposal details from the parent componenet

  constructor(private transactionService: TransactionService , private router:Router,private date:DatePipe) { 
    this.numbers=[0];
  }


  display=true;
  popup1=true;
  cols = [
    { field: 'partnumbercommonized', header:'Ford Part Number(1)'},
    {field: 'ngpp_ref_part_no', header:'Ref Part Number(2)'},
    {field:'partdesc',header:'Part Name(3)'},
    { field: 'plantgsdbcode', header:'Plant(4)'},
    { field: 'programname', header:'Program Name(5)'},
    { field: 'offsitesupplierid', header:'Bailment(6)'},
 
  ];


  selected_supp_details:supplierdetails;
  selected_supp_details_trans:supplierdetails;

  numbers:number[]=[];

  agvUrls='';
  popup=false;
  values=[
    {
      partnumbercommonized: 'BK31-B406A74-AW-1',
      part_pre_bas_suf: 'BK31-B406A74-AB34X1',
      partdesc: 'PLG RR CNR PLR HLE',
      suppliergsdbcode: 'FWALC',
      suppliercountrycodeISO: 'TR',
      plantgsdbcode: 'AP06A',
      tradingcogsdbcode: 'FWALC'
    },
    {
      partnumbercommonized: 'BK31-B406A74-AW-2',
      part_pre_bas_suf: 'BK31-B406A74-AB34X1',
      partdesc: 'PLG RR CNR PLR HLE',
      suppliergsdbcode: 'FWALC',
      suppliercountrycodeISO: 'TR',
      plantgsdbcode: 'AP06A',
      tradingcogsdbcode: 'FWALC'
    },
    {
      partnumbercommonized: 'BK31-B406A74-AW-3',
      part_pre_bas_suf: 'BK31-B406A74-AB34X1',
      partdesc: 'PLG RR CNR PLR HLE',
      suppliergsdbcode: 'FWALC',
      suppliercountrycodeISO: 'TR',
      plantgsdbcode: 'AP06A',
      tradingcogsdbcode: 'FWALC'
    },
    {
      partnumbercommonized: 'BK31-B406A74-AW-4',
      part_pre_bas_suf: 'BK31-B406A74-AB34X1',
      partdesc: 'PLG RR CNR PLR HLE',
      suppliergsdbcode: 'FWALC',
      suppliercountrycodeISO: 'TR',
      plantgsdbcode: 'AP06A',
      tradingcogsdbcode: 'FWALC'
    },
    {
      partnumbercommonized: 'BK31-B406A74-AW-5',
      part_pre_bas_suf: 'BK31-B406A74-AB34X1',
      partdesc: 'PLG RR CNR PLR HLE',
      suppliergsdbcode: 'FWALC',
      suppliercountrycodeISO: 'TR',
      plantgsdbcode: 'AP06A',
      tradingcogsdbcode: 'FWALC'
    },
    {
      partnumbercommonized: 'BK31-B406A74-AW-6',
      part_pre_bas_suf: 'BK31-B406A74-AB34X1',
      partdesc: 'PLG RR CNR PLR HLE',
      suppliergsdbcode: 'FWALC',
      suppliercountrycodeISO: 'TR',
      plantgsdbcode: 'AP06A',
      tradingcogsdbcode: 'FWALC'
    },
    {
      partnumbercommonized: 'BK31-B406A74-AW-7',
      part_pre_bas_suf: 'BK31-B406A74-AB34X1',
      partdesc: 'PLG RR CNR PLR HLE',
      suppliergsdbcode: 'FWALC',
      suppliercountrycodeISO: 'TR',
      plantgsdbcode: 'AP06A',
      tradingcogsdbcode: 'FWALC'
    },

    {
      partnumbercommonized: 'BK31-B406A74-AW-8',
      part_pre_bas_suf: 'BK31-B406A74-AB34X1',
      partdesc: 'PLG RR CNR PLR HLE',
      suppliergsdbcode: 'FWALC',
      suppliercountrycodeISO: 'TR',
      plantgsdbcode: 'AP06A',
      tradingcogsdbcode: 'FWALC'
    },

    {
      partnumbercommonized: 'BK31-B406A74-AW-9',
      part_pre_bas_suf: 'BK31-B406A74-AB34X1',
      partdesc: 'PLG RR CNR PLR HLE',
      suppliergsdbcode: 'FWALC',
      suppliercountrycodeISO: 'TR',
      plantgsdbcode: 'AP06A',
      tradingcogsdbcode: 'FWALC'
    },

    {
      partnumbercommonized: 'BK31-B406A74-AW-10',
      part_pre_bas_suf: 'BK31-B406A74-AB34X1',
      partdesc: 'PLG RR CNR PLR HLE',
      suppliergsdbcode: 'FWALC',
      suppliercountrycodeISO: 'TR',
      plantgsdbcode: 'AP06A',
      tradingcogsdbcode: 'FWALC'
    },











  ]

  cols1:any[]=[]

  


  
  values1=[
    {
      partnumbercommonized: 'BK31-B406A74-AW',
      part_pre_bas_suf: 'BK31-B406A74-AB34X1',
      partdesc: 'PLG RR CNR PLR HLE',
      suppliergsdbcode: 'FWALC',
      suppliercountrycodeISO: 'TR',
      plantgsdbcode: 'AP06A',
      tradingcogsdbcode: 'FWALC',
      partnumbercommonized1: 'BK31-B406A74-AW',
      part_pre_bas_suf1: 'BK31-B406A74-AB34X1',
      partdesc1: 'PLG RR CNR PLR HLE',
      suppliergsdbcode1: 'FWALC',
      suppliercountrycodeISO1: 'TR',
      plantgsdbcode1: 'AP06A',
      tradingcogsdbcode1: 'FWALC'
    },
    {
      partnumbercommonized: 'BK31-B406A74-AW',
      part_pre_bas_suf: 'BK31-B406A74-AB34X1',
      partdesc: 'PLG RR CNR PLR HLE',
      suppliergsdbcode: 'FWALC',
      suppliercountrycodeISO: 'TR',
      plantgsdbcode: 'AP06A',
      tradingcogsdbcode: 'FWALC',
      partnumbercommonized1: 'BK31-B406A74-AW',
      part_pre_bas_suf1: 'BK31-B406A74-AB34X1',
      partdesc1: 'PLG RR CNR PLR HLE',
      suppliergsdbcode1: 'FWALC',
      suppliercountrycodeISO1: 'TR',
      plantgsdbcode1: 'AP06A',
      tradingcogsdbcode1: 'FWALC'
    },
    {
      partnumbercommonized: 'BK31-B406A74-AW',
      part_pre_bas_suf: 'BK31-B406A74-AB34X1',
      partdesc: 'PLG RR CNR PLR HLE',
      suppliergsdbcode: 'FWALC',
      suppliercountrycodeISO: 'TR',
      plantgsdbcode: 'AP06A',
      tradingcogsdbcode: 'FWALC',
      partnumbercommonized1: 'BK31-B406A74-AW',
      part_pre_bas_suf1: 'BK31-B406A74-AB34X1',
      partdesc1: 'PLG RR CNR PLR HLE',
      suppliergsdbcode1: 'FWALC',
      suppliercountrycodeISO1: 'TR',
      plantgsdbcode1: 'AP06A',
      tradingcogsdbcode1: 'FWALC'
    },
    {
      partnumbercommonized: 'BK31-B406A74-AW',
      part_pre_bas_suf: 'BK31-B406A74-AB34X1',
      partdesc: 'PLG RR CNR PLR HLE',
      suppliergsdbcode: 'FWALC',
      suppliercountrycodeISO: 'TR',
      plantgsdbcode: 'AP06A',
      tradingcogsdbcode: 'FWALC',
      partnumbercommonized1: 'BK31-B406A74-AW',
      part_pre_bas_suf1: 'BK31-B406A74-AB34X1',
      partdesc1: 'PLG RR CNR PLR HLE',
      suppliergsdbcode1: 'FWALC',
      suppliercountrycodeISO1: 'TR',
      plantgsdbcode1: 'AP06A',
      tradingcogsdbcode1: 'FWALC'
    },
    {
      partnumbercommonized: 'BK31-B406A74-AW',
      part_pre_bas_suf: 'BK31-B406A74-AB34X1',
      partdesc: 'PLG RR CNR PLR HLE',
      suppliergsdbcode: 'FWALC',
      suppliercountrycodeISO: 'TR',
      plantgsdbcode: 'AP06A',
      tradingcogsdbcode: 'FWALC',
      partnumbercommonized1: 'BK31-B406A74-AW',
      part_pre_bas_suf1: 'BK31-B406A74-AB34X1',
      partdesc1: 'PLG RR CNR PLR HLE',
      suppliergsdbcode1: 'FWALC',
      suppliercountrycodeISO1: 'TR',
      plantgsdbcode1: 'AP06A',
      tradingcogsdbcode1: 'FWALC'
    },
    {
      partnumbercommonized: 'BK31-B406A74-AW',
      part_pre_bas_suf: 'BK31-B406A74-AB34X1',
      partdesc: 'PLG RR CNR PLR HLE',
      suppliergsdbcode: 'FWALC',
      suppliercountrycodeISO: 'TR',
      plantgsdbcode: 'AP06A',
      tradingcogsdbcode: 'FWALC',
      partnumbercommonized1: 'BK31-B406A74-AW',
      part_pre_bas_suf1: 'BK31-B406A74-AB34X1',
      partdesc1: 'PLG RR CNR PLR HLE',
      suppliergsdbcode1: 'FWALC',
      suppliercountrycodeISO1: 'TR',
      plantgsdbcode1: 'AP06A',
      tradingcogsdbcode1: 'FWALC'
    },
    {
      partnumbercommonized: 'BK31-B406A74-AW',
      part_pre_bas_suf: 'BK31-B406A74-AB34X1',
      partdesc: 'PLG RR CNR PLR HLE',
      suppliergsdbcode: 'FWALC',
      suppliercountrycodeISO: 'TR',
      plantgsdbcode: 'AP06A',
      tradingcogsdbcode: 'FWALC',
      partnumbercommonized1: 'BK31-B406A74-AW',
      part_pre_bas_suf1: 'BK31-B406A74-AB34X1',
      partdesc1: 'PLG RR CNR PLR HLE',
      suppliergsdbcode1: 'FWALC',
      suppliercountrycodeISO1: 'TR',
      plantgsdbcode1: 'AP06A',
      tradingcogsdbcode1: 'FWALC'
    },
    {
      partnumbercommonized: 'BK31-B406A74-AW',
      part_pre_bas_suf: 'BK31-B406A74-AB34X1',
      partdesc: 'PLG RR CNR PLR HLE',
      suppliergsdbcode: 'FWALC',
      suppliercountrycodeISO: 'TR',
      plantgsdbcode: 'AP06A',
      tradingcogsdbcode: 'FWALC',
      partnumbercommonized1: 'BK31-B406A74-AW',
      part_pre_bas_suf1: 'BK31-B406A74-AB34X1',
      partdesc1: 'PLG RR CNR PLR HLE',
      suppliergsdbcode1: 'FWALC',
      suppliercountrycodeISO1: 'TR',
      plantgsdbcode1: 'AP06A',
      tradingcogsdbcode1: 'FWALC'
    },
    {
      partnumbercommonized: 'BK31-B406A74-AW',
      part_pre_bas_suf: 'BK31-B406A74-AB34X1',
      partdesc: 'PLG RR CNR PLR HLE',
      suppliergsdbcode: 'FWALC',
      suppliercountrycodeISO: 'TR',
      plantgsdbcode: 'AP06A',
      tradingcogsdbcode: 'FWALC',
      partnumbercommonized1: 'BK31-B406A74-AW',
      part_pre_bas_suf1: 'BK31-B406A74-AB34X1',
      partdesc1: 'PLG RR CNR PLR HLE',
      suppliergsdbcode1: 'FWALC',
      suppliercountrycodeISO1: 'TR',
      plantgsdbcode1: 'AP06A',
      tradingcogsdbcode1: 'FWALC'
    },
    {
      partnumbercommonized: 'BK31-B406A74-AW',
      part_pre_bas_suf: 'BK31-B406A74-AB34X1',
      partdesc: 'PLG RR CNR PLR HLE',
      suppliergsdbcode: 'FWALC',
      suppliercountrycodeISO: 'TR',
      plantgsdbcode: 'AP06A',
      tradingcogsdbcode: 'FWALC',
      partnumbercommonized1: 'BK31-B406A74-AW',
      part_pre_bas_suf1: 'BK31-B406A74-AB34X1',
      partdesc1: 'PLG RR CNR PLR HLE',
      suppliergsdbcode1: 'FWALC',
      suppliercountrycodeISO1: 'TR',
      plantgsdbcode1: 'AP06A',
      tradingcogsdbcode1: 'FWALC'
    },
    {
      partnumbercommonized: 'BK31-B406A74-AW',
      part_pre_bas_suf: 'BK31-B406A74-AB34X1',
      partdesc: 'PLG RR CNR PLR HLE',
      suppliergsdbcode: 'FWALC',
      suppliercountrycodeISO: 'TR',
      plantgsdbcode: 'AP06A',
      tradingcogsdbcode: 'FWALC',
      partnumbercommonized1: 'BK31-B406A74-AW',
      part_pre_bas_suf1: 'BK31-B406A74-AB34X1',
      partdesc1: 'PLG RR CNR PLR HLE',
      suppliergsdbcode1: 'FWALC',
      suppliercountrycodeISO1: 'TR',
      plantgsdbcode1: 'AP06A',
      tradingcogsdbcode1: 'FWALC'
    },
    {
      partnumbercommonized: 'BK31-B406A74-AW',
      part_pre_bas_suf: 'BK31-B406A74-AB34X1',
      partdesc: 'PLG RR CNR PLR HLE',
      suppliergsdbcode: 'FWALC',
      suppliercountrycodeISO: 'TR',
      plantgsdbcode: 'AP06A',
      tradingcogsdbcode: 'FWALC',
      partnumbercommonized1: 'BK31-B406A74-AW',
      part_pre_bas_suf1: 'BK31-B406A74-AB34X1',
      partdesc1: 'PLG RR CNR PLR HLE',
      suppliergsdbcode1: 'FWALC',
      suppliercountrycodeISO1: 'TR',
      plantgsdbcode1: 'AP06A',
      tradingcogsdbcode1: 'FWALC'
    },

    
  ]


  iscontainer=true;
  ispallet=false;
  isimc=false;


  iscontainer_ee=true;
  ispallet_ee=false;
  isimc_ee=false;


  htmlvalue:string;


  region:string;
  region_code:string;
  selected_submitter:selec_prt_pro_details={simplifiedpartno:'-',proposalid:0,bompartmasterid:0,plantgsdbcode:'',proposallastsaved:0,part_status:'',supplier_code:'',proposallastsaved_ee:0,is_ee_proposal:false,currentview_ee:false,from_draft:false,propsl_type:'',imp_part_status:''};
   corrosionprotect='';
   display_proposal_values:fromdiplayvalues_all;
   display_proposal_parts:fromdiplayvalues_parts[]=[];
   display_proposal_componenets:fromdiplayvalues_components[]=[];



   display_proposal_values_ee:fromdiplayvalues_all;
   display_proposal_componenets_ee:fromdiplayvalues_components[]=[];


   tab2data:cntrandsudetailspost;



  part_image_form=''
  interior_image_form='';
  exterior_image_form='';
  label_image_form='';
  shipping_image_form='';



  tab2data_ee:cntrandsudetailspost;



  part_image_form_ee=''
  interior_image_form_ee='';
  exterior_image_form_ee='';
  label_image_form_ee='';
  shipping_image_form_ee='';


  base_url_getimage='';


  part_popup=false;
  interior_popup=false;
  exterior_popup=false;
  label_popup=false;
  shpunit_popup=false;


  part_popup_ee=false;
  interior_popup_ee=false;
  exterior_popup_ee=false;
  label_popup_ee=false;
  shpunit_popup_ee=false;


curr_aprvl_status='';


submit_date:any='';

view_ee_proposal=false;



approver_list_array_form:string[]=[];


isImprovementProposal=false;


isuploadedtocmms=false;



regionAdminDetails:regiondetails={
  cd_region: '',
  cntr_ergo_limit:0,
  bom_err_thld: 0,
  allow_nonstd_comp_ind: '',
  urge_prt_notice_email_freq: 0,
  allow_multi_prt_prop_ind: '',
  limit_prts_recvd_plt: '',
  emg_exp_pkg_reqd_ind: '',
  pkg_rel_level_src: '',
  suspd_duratn_thld:0,
  uom:''
}

weightMeasurements='';
dimensionMeasurements='';


componentTypeList:componenttypewithcodeanddesc[]=[];
comp_type:containerTypeCode[]=[]

errorFormText:string=""
loaderForm:boolean=false;
successMessage:string="";

isExpendable:boolean=false;
 
  ngOnInit()
  {
   // console.log("prop",this.proposal_id_in);

this.htmlvalue='Supplier Provided Unique';
this.region=this.transactionService.getregion();
this.region_code=this.transactionService.getregioncode(this.region);
this.selected_submitter=this.transactionService.getpartno_packpro(this.region);
this.view_ee_proposal=false;
this.weightMeasurements='';
this.dimensionMeasurements='';

this.display_allvalues_init();
this.display_allvalues_init_ee();
this.display_proposal_parts=[];
this.display_proposal_componenets=[];
this.display_proposal_componenets_ee=[];

this.part_image_form='';
this.interior_image_form='';
this.exterior_image_form='';
this.label_image_form='';
this.shipping_image_form='';

this.cols1 = [
  { field: 'comp_cnt_no', header:'#'},
  {field: 'comp_Desc_display', header:'CMMS Code Description'},
  {field:'comp_ownrshp_display',header:'Owner'},
  { field: 'comp_material', header:'Materials'},
  { field: 'qt_per_min', header:'QTY'},
  { field: 'comp_type', header:'E/R'},
  { field: 'comp_l', header:'L'},
  {field: 'comp_w', header:'W'},
  {field:'comp_h',header:'H'},
  { field: 'nestedfoldedht', header:'Ntbl/Fld H'},
  { field: 'comp_tar_wt', header:'Tare Wt'},
  { field: 'total_wt_display', header:'Total Wt'},

];

this.transactionService.getContainerTypeCode().subscribe(typ=>{
  this.comp_type=typ;
  console.log(this.comp_type);


  const tempcompstypeanddesc:componenttypewithcodeanddesc[]=[];
  for(const i of this.comp_type)
  {
    tempcompstypeanddesc.push({cd_cntr_typ:i.cd_cntr_typ,cntr_typ_desc:i.cntr_typ_desc,cntr_typ_and_desc:i.cd_cntr_typ+'-'+i.cntr_typ_desc,typ_no:i.typ_no})
  }
  
  this.componentTypeList=tempcompstypeanddesc;

  
 
  
});


/*
this.transactionService.get_region_details(this.region_code).subscribe(regionOutput=>{

  console.log("Region Details Form Display Component", regionOutput)

  if(regionOutput != null)
  {
    this.regionAdminDetails=regionOutput;
    if(this.regionAdminDetails.uom != null && this.regionAdminDetails.uom.toLowerCase() == "e")
    {
      this.weightMeasurements="lbs";
      this.dimensionMeasurements="in";
      this.cols1 = [
        { field: 'comp_cnt_no', header:'#'},
        {field: 'comp_Desc_display', header:'CMMS Code Description'},
        {field:'comp_ownrshp_display',header:'Owner'},
        { field: 'comp_material', header:'Materials'},
        { field: 'qt_per_min', header:'QTY'},
        { field: 'comp_type', header:'E/R'},
        { field: 'comp_l', header:'L(in)'},
        {field: 'comp_w', header:'W(in)'},
        {field:'comp_h',header:'H(in)'},
        { field: 'nestedfoldedht', header:'Ntbl/Fld H(in)'},
        { field: 'comp_tar_wt', header:'Tare Wt(lbs)'},
        { field: 'total_wt_display', header:'Total Wt(lbs)'},
      
      ];
    }
    else if(this.regionAdminDetails.uom != null && this.regionAdminDetails.uom.toLowerCase() == "m")
    {
      this.weightMeasurements="kg";
      this.dimensionMeasurements="cm";
      this.cols1 = [
        { field: 'comp_cnt_no', header:'#'},
        {field: 'comp_Desc_display', header:'CMMS Code Description'},
        {field:'comp_ownrshp_display',header:'Owner'},
        { field: 'comp_material', header:'Materials'},
        { field: 'qt_per_min', header:'QTY'},
        { field: 'comp_type', header:'E/R'},
        { field: 'comp_l', header:'L(cm)'},
        {field: 'comp_w', header:'W(cm)'},
        {field:'comp_h',header:'H(cm)'},
        { field: 'nestedfoldedht', header:'Ntbl/Fld H(cm)'},
        { field: 'comp_tar_wt', header:'Tare Wt(kg)'},
        { field: 'total_wt_display', header:'Total Wt(kg)'},
      
      ];
    }
    else
    {
      this.weightMeasurements="";
      this.dimensionMeasurements="";
      
      
    }
  }

  else
  {
    this.weightMeasurements="";
    this.dimensionMeasurements="";
  }

  console.log("weightMeasurements",this.weightMeasurements);
  console.log("dimensionMeasurements",this.dimensionMeasurements);

 
  
  
})
*/


if(this.selected_submitter != null && this.selected_submitter.propsl_type != null && this.selected_submitter.propsl_type.toLowerCase() == 'improvement')
{
  this.isImprovementProposal=true;
}
else
{
  this.isImprovementProposal=false;
}




this.base_url_getimage=environment.nfsBaseAPIUrl+"/downloadFile?fPath=";


for(let l=0;l<12;l++)
{
this.display_proposal_componenets.push(

  {
    id_proposal:0,
 id_comp:0,
 comp_base:null,
 comp_suffix:null,
 comp_desc:null,
 comp_l:null,
 comp_w:null,
 comp_h:null,
 comp_type:null,
 qt_per_min:null,
 updated_by:null,
 updated_dt:null,
 comp_tar_wt:null,
 nestedfoldedht:null,
 comp_code:null,
 comp_ownershp:null,
 comp_material:null,
 tooledcomp:null,
 is_compulsary:null,
 comp_Desc_display:'',
 comp_ownrshp_display:'',
 total_wt_display:null,
  comp_cnt_no:(l+1),
  compselctnfor:null,
  material_flag:null,
  typ_no:0
  }

)



this.display_proposal_componenets_ee.push(

  {
    id_proposal:0,
 id_comp:0,
 comp_base:null,
 comp_suffix:null,
 comp_desc:null,
 comp_l:null,
 comp_w:null,
 comp_h:null,
 comp_type:null,
 qt_per_min:null,
 updated_by:null,
 updated_dt:null,
 comp_tar_wt:null,
 nestedfoldedht:null,
 comp_code:null,
 comp_ownershp:null,
 comp_material:null,
 tooledcomp:null,
 is_compulsary:null,
 comp_Desc_display:'',
 comp_ownrshp_display:'',
 total_wt_display:null,
  comp_cnt_no:(l+1),
  compselctnfor:null,
  material_flag:null,
  typ_no:0
  }

)





}



for(let ka=0;ka<16;ka++)
{
  this.approver_list_array_form.push('');
}




    //check for this container or pallet



   console.log(this.values.length) ;
   this.numbers=[];


   let total=0;
   let modulo=0;




   total=(this.display_proposal_parts.length)/9;
   total=this.float2int(total);
   console.log(total);
   


   modulo=(this.display_proposal_parts.length)%9;


   if(modulo!=0)
   {

    total=total+1;

   }






   this.numbers = Array(total).fill(0).map((x,i)=>i);



this.isuploadedtocmms=false;

if(this.selected_submitter != null && this.selected_submitter.from_draft==true)
{


//this.selected_submitter.proposalid=1656587760974
//this.selected_submitter.proposallastsaved=1;

  this.display_proposal_values.id_proposal=this.selected_submitter.proposalid;
  this.display_proposal_parts=[];

this.isuploadedtocmms=false;
  this.submit_date='';
 if(this.selected_submitter.proposallastsaved>=1)
 {
    this.transactionService.get_draft_Tab1_data(this.selected_submitter.proposalid).subscribe(tab1_data=>{


      console.log('tab1t,',tab1_data);

      this.display_proposal_values.corr_protc=tab1_data.corr_protc;
      this.display_proposal_values.lst_saved_tab=tab1_data.lst_saved_tab,
      this.display_proposal_values.seasnlreq=tab1_data.seasnlreq,
      this.display_proposal_values.shelf_life=tab1_data.shelf_life,
      this.display_proposal_values.sp_part_no=tab1_data.sp_part_no,
      this.display_proposal_values.submit_status=tab1_data.submit_status,
      this.display_proposal_values.temp_sensitivity=tab1_data.temp_sensitivity,
      this.display_proposal_values.temp_stor_spec=tab1_data.temp_stor_spec,
      this.display_proposal_values.time_stamp=tab1_data.timestamp,
      this.display_proposal_values.plantgsdbcode=tab1_data.plantgsdbcode,
      this.display_proposal_values.isstampblank=tab1_data.isstampblank;
      this.display_proposal_values.supgsdbcode=tab1_data.supgsdbcode;

      var is_design_src="N"
      if(this.display_proposal_values.supgsdbcode!=null && this.display_proposal_values.supgsdbcode!="")
      {
        this.transactionService.getsuppdet(this.display_proposal_values.supgsdbcode,is_design_src).subscribe(suppdet=>{
  
          console.log("supplier details",suppdet);
          this.selected_supp_details=suppdet

          if(this.selected_supp_details!=null)
          {
            if(this.selected_supp_details.fullname!=null)
            {
            this.display_proposal_values.supfullname=this.selected_supp_details.fullname;
            }
            else
            {
              this.display_proposal_values.supfullname="";
            }
            if(this.selected_supp_details.address1!=null)
            {
            this.display_proposal_values.supaddress1=this.selected_supp_details.address1;
            }
            else
            {
              this.display_proposal_values.supaddress1="";
            }
            if(this.selected_supp_details.city!=null)
            {
            this.display_proposal_values.supcity=this.selected_supp_details.city;
            }
            else
            {
              this.display_proposal_values.supcity="";
            }
            if(this.selected_supp_details.stateprovince!=null)
            {
            this.display_proposal_values.supstateprov=this.selected_supp_details.stateprovince;
            }
            else
            {
              this.display_proposal_values.supstateprov="";
            }
            if(this.selected_supp_details.countrycode!=null)
            {
            this.display_proposal_values.supcountrycode=this.selected_supp_details.countrycode;
            }
            else
            {
              this.display_proposal_values.supcountrycode="";
            }
            if(this.selected_supp_details.contactnamefirst!=null)
            {
            this.display_proposal_values.supcontactnamefirst=this.selected_supp_details.contactnamefirst;
            }
            else
            {
              this.display_proposal_values.supcontactnamefirst="";
            }
            if(this.selected_supp_details.contactname!=null)
            {
  
            this.display_proposal_values.supcontactname=this.selected_supp_details.contactname;
            }
            else
            {
              this.display_proposal_values.supcontactname="";
            }
            if(this.selected_supp_details.contactfunctn!=null)
            {
            this.display_proposal_values.supcontactfunctn=this.selected_supp_details.contactfunctn;
            }
            else
            {
              this.display_proposal_values.supcontactfunctn="";
            }
            if(this.selected_supp_details.contactemail!=null)
            {
            this.display_proposal_values.supcontactemail=this.selected_supp_details.contactemail;
            }
            else
            {
              this.display_proposal_values.supcontactemail="";
            }
            if(this.selected_supp_details.contactphone!=null)
            {
            this.display_proposal_values.supcontactphone=this.selected_supp_details.contactphone;
            }
            else
            {
              this.display_proposal_values.supcontactphone="        ";
            }
            if(this.selected_supp_details.contactfax!=null)
            {
            this.display_proposal_values.supcontactfax=this.selected_supp_details.contactfax;
            }
            else
            {
              this.display_proposal_values.supcontactfax="";
            }
  
            this.display_proposal_values.supp_city_prov_country=""
            if(this.display_proposal_values.supcity!="")
            {
            this.display_proposal_values.supp_city_prov_country+=this.display_proposal_values.supcity+',';
            }
            if(this.display_proposal_values.supstateprov!="")
            {
            this.display_proposal_values.supp_city_prov_country+=this.display_proposal_values.supstateprov+',';
            }
            if(this.display_proposal_values.supcountrycode!="")
            {
            this.display_proposal_values.supp_city_prov_country+=this.display_proposal_values.supcountrycode;
            }
          }
          else
          {
            console.log("problem in getting supplier details");
          }
        

        })
      }


      if(this.display_proposal_values.isstampblank.toLowerCase()=='yes')
      {
        this.ispallet=true;
        this.iscontainer=false;
        this.isimc=false;


        this.ispallet_ee=true;
        this.iscontainer_ee=false;
        this.isimc_ee=false;


      }


      else{


        this.iscontainer=true;
        this.ispallet=false;
        this.isimc=false;


        this.iscontainer_ee=true;
        this.ispallet_ee=false;
        this.isimc_ee=false;
      }
      



     


      this.transactionService.get_draft_selectedparts_data(this.selected_submitter.proposalid).subscribe(selectedparts_data=>{

        console.log(selectedparts_data)

      const grid1_parts:fromdiplayvalues_parts[]=[];
      let grid2_parts:fromdiplayvalues_parts[]=[];
        if(selectedparts_data.length!=0)
        {
         for(let i=0;i<selectedparts_data.length;i++)
         {
           if(selectedparts_data[i].isprimary.toLowerCase()=='yes')
           {
              grid1_parts.push(selectedparts_data[i]);
              this.display_proposal_values.p_part_len=selectedparts_data[i].ngpp_part_l
              this.display_proposal_values.p_part_wdt=selectedparts_data[i].ngpp_part_w
              this.display_proposal_values.p_part_ht=selectedparts_data[i].ngpp_part_h
              this.display_proposal_values.p_part_wt=selectedparts_data[i].ngpp_part_wt


              if(selectedparts_data[i].ngpp_part_wt_status!=null)
              {
                if(selectedparts_data[i].ngpp_part_wt_status.toLowerCase()=='actual')
                {
                  this.display_proposal_values.p_part_wt_stat='A'
                }
                else if(selectedparts_data[i].ngpp_part_wt_status.toLowerCase()=='estimate')
                {
                  this.display_proposal_values.p_part_wt_stat='E'
                }
              }

              let partRegion='';
              partRegion=selectedparts_data[i].cd_region;
              this.transactionService.get_region_details(partRegion).subscribe(regionOutput=>{

                console.log('Region Details Form Display Component', regionOutput)

                if(regionOutput != null)
                {
                  this.regionAdminDetails=regionOutput;
                  if(this.regionAdminDetails.uom != null && this.regionAdminDetails.uom.toLowerCase() == 'e')
                  {
                    this.weightMeasurements='lbs';
                    this.dimensionMeasurements='in';
                    this.cols1 = [
                      { field: 'comp_cnt_no', header:'#'},
                      {field: 'comp_Desc_display', header:'CMMS Code Description'},
                      {field:'comp_ownrshp_display',header:'Owner'},
                      { field: 'comp_material', header:'Materials'},
                      { field: 'qt_per_min', header:'QTY'},
                      { field: 'comp_type', header:'E/R'},
                      { field: 'comp_l', header:'L (in)'},
                      {field: 'comp_w', header:'W (in)'},
                      {field:'comp_h',header:'H (in)'},
                      { field: 'nestedfoldedht', header:'Ntbl/Fld H (in)'},
                      { field: 'comp_tar_wt', header:'Tare Wt (lbs)'},
                      { field: 'total_wt_display', header:'Total Wt (lbs)'},
                    
                    ];
                  }
                  else if(this.regionAdminDetails.uom != null && this.regionAdminDetails.uom.toLowerCase() == 'm')
                  {
                    this.weightMeasurements='kg';
                    this.dimensionMeasurements='cm';
                    this.cols1 = [
                      { field: 'comp_cnt_no', header:'#'},
                      {field: 'comp_Desc_display', header:'CMMS Code Description'},
                      {field:'comp_ownrshp_display',header:'Owner'},
                      { field: 'comp_material', header:'Materials'},
                      { field: 'qt_per_min', header:'QTY'},
                      { field: 'comp_type', header:'E/R'},
                      { field: 'comp_l', header:'L(cm)'},
                      {field: 'comp_w', header:'W(cm)'},
                      {field:'comp_h',header:'H(cm)'},
                      { field: 'nestedfoldedht', header:'Ntbl/Fld H(cm)'},
                      { field: 'comp_tar_wt', header:'Tare Wt(kg)'},
                      { field: 'total_wt_display', header:'Total Wt(kg)'},

                    ];
                  }
                  else
                  {
                    this.weightMeasurements='';
                    this.dimensionMeasurements='';


                  }
                }
              
                else
                {
                  this.weightMeasurements='';
                  this.dimensionMeasurements='';
                }

                console.log('weightMeasurements',this.weightMeasurements);
                console.log('dimensionMeasurements',this.dimensionMeasurements);




              })

           }
           else{
             grid2_parts.push(selectedparts_data[i]);
           }
         }

         if(grid1_parts.length != 0)
         {
         this.display_proposal_parts.push(grid1_parts[0]);
         }
         const sortedGrid2 = grid2_parts.sort((r1, r2) => (r1.selorder > r2.selorder) ? 1 : (r1.selorder < r2.selorder) ? -1 : 0);
         grid2_parts=[];
         grid2_parts.push(...sortedGrid2);
         this.display_proposal_parts.push(...grid2_parts);
         //console.log("parts",this.display_proposal_parts)

         this.numbers=[];


         let total=0;
         let modulo=0;




         total=(this.display_proposal_parts.length)/9;
         total=this.float2int(total);
         console.log(total);
         
      
      
         modulo=(this.display_proposal_parts.length)%9;
      
      
         if(modulo!=0)
         {
      
          total=total+1;
      
         }
      
      
      
      
      
      
         this.numbers = Array(total).fill(0).map((x,i)=>i);
        }

        



        if(this.selected_submitter.proposallastsaved>=2)
        {


          this.transactionService.loadtab2detailsifproposalexistalready(this.selected_submitter.proposalid).subscribe(cont_cos_data=>
            {
              console.log(cont_cos_data);
              this.tab2data=cont_cos_data


              console.log(this.tab2data,'tab2222')


              this.display_proposal_values.cntr_ret_type=this.tab2data.cntr_ret_type
              this.display_proposal_values.used_carry_ovr_pck=this.tab2data.used_carry_ovr_pck,
              this.display_proposal_values.cntr_base=this.tab2data.cntr_base,
              this.display_proposal_values.cntr_suff=this.tab2data.cntr_suff,
              this.display_proposal_values.cntr_desc=this.tab2data.cntr_desc,
              this.display_proposal_values.parts_per_cntr=this.tab2data.parts_per_cntr,
              this.display_proposal_values.cntr_ext_len=this.tab2data.cntr_ext_len,
              this.display_proposal_values.cntr_width=this.tab2data.cntr_width,
              this.display_proposal_values.cntr_height=this.tab2data.cntr_height,
              this.display_proposal_values.cntr_nsted_fld_height=this.tab2data.cntr_nsted_fld_height
              this.display_proposal_values.cntr_material=this.tab2data.cntr_material,
              this.display_proposal_values.cntr_tar_weight=this.tab2data.cntr_tar_weight,
              this.display_proposal_values.cntr_per_layer=this.tab2data.cntr_per_layer,
              this.display_proposal_values.max_layrs_shp_unt=this.tab2data.max_layrs_shp_unt,
              this.display_proposal_values.empty_trays_cntr_layrs=this.tab2data.empty_trays_cntr_layrs,
              this.display_proposal_values.max_cntr_per_shp_unt=this.tab2data.max_cntr_per_shp_unt,
              this.display_proposal_values.cntr_rck_colr=this.tab2data.cntr_rck_colr,
              this.display_proposal_values.shp_unt_base=this.tab2data.shp_unt_base,
              this.display_proposal_values.shp_unt_suff=this.tab2data.shp_unt_suff,
              this.display_proposal_values.shp_unt_desc=this.tab2data.shp_unt_desc,
              this.display_proposal_values.part_per_shp_unit=this.tab2data.part_per_shp_unit,
              this.display_proposal_values.shp_unt_ret_type=this.tab2data.shp_unt_ret_type,
              this.display_proposal_values.shp_unit_ext_len=this.tab2data.shp_unit_ext_len,
              this.display_proposal_values.shp_unt_ext_width=this.tab2data.shp_unt_ext_width,
              this.display_proposal_values.shp_unt_ext_height=this.tab2data.shp_unt_ext_height,
              this.display_proposal_values.shp_unt_nestd_fld_height=this.tab2data.shp_unt_nestd_fld_height,
              this.display_proposal_values.card_call=this.tab2data.card_call,
              this.display_proposal_values.cntr_grp_id=this.tab2data.cntr_grp_id,
              this.display_proposal_values.cntr_ownrshp_strat_code=this.tab2data.cntr_ownrshp_strat_code,
              this.display_proposal_values.cd_cntr=this.tab2data.cd_cntr,
              this.display_proposal_values.cd_ownrshp_strat=this.tab2data.cd_ownrshp_strat;
              this.display_proposal_values.cntr_bas_suff=this.tab2data.cntr_base;
              this.display_proposal_values.pallettype=this.tab2data.pallettype;
              this.display_proposal_values.woodispmcmplnt=this.tab2data.woodispmcmplnt;
              this.display_proposal_values.offstmpmartprsnt=this.tab2data.offstmpmartprsnt;
              this.display_proposal_values.blanksperstack=this.tab2data.blanksperstack;
              this.display_proposal_values.stacksperpallet=this.tab2data.stacksperpallet;
              this.display_proposal_values.palletpintyp=this.tab2data.palletpintyp;
              this.display_proposal_values.iscortonimcspec=this.tab2data.iscartonimcspec;
              this.display_proposal_values.cortonburststrnth=this.tab2data.cartonburststrnth;
              this.display_proposal_values.imcwtdisc=this.tab2data.imcwtdisc;


              this.display_proposal_values.cntrOwner=this.tab2data.cntrowner;
              this.display_proposal_values.bct=this.tab2data.bct;
              this.display_proposal_values.ect=this.tab2data.ect;

              if(this.tab2data.cntr_ret_type.toLowerCase()=='expendable')
              {
                this.isExpendable=true;
              }
              else
              {
                this.isExpendable=false;
              }

              if(this.display_proposal_values.isstampblank.toLowerCase()=='no')
              {
             if( this.tab2data.cntr_base.indexOf('IMC')>-1 && this.tab2data.cntr_ret_type.toLowerCase()=='expendable' && this.tab2data.cntr_grp_id != null && this.tab2data.cntr_grp_id.toLowerCase().indexOf('imc')>-1)
             {

              this.iscontainer=false;
              this.ispallet=false;
              this.isimc=true;
      
             }
             else
             {
              this.iscontainer=true;
              this.ispallet=false;
              this.isimc=false;

             }
            }





              if(this.display_proposal_values.shp_unt_ret_type.toLowerCase()=='returnable')
              {


                this.display_proposal_values.shp_unt_ret_type_fullform='R';


              }


              else if (this.display_proposal_values.shp_unt_ret_type.toLowerCase()=='expendable')
              {
            

                this.display_proposal_values.shp_unt_ret_type_fullform='E';

              }




              if(this.tab2data.cntr_suff!=null && this.tab2data.cntr_suff!='        ')
              {
                this.display_proposal_values.cntr_bas_suff+='-'+this.tab2data.cntr_suff;

              }
              
              this.display_proposal_values.shp_bas_suff_desc=this.tab2data.shp_unt_base;

              if(this.tab2data.shp_unt_suff!=null && this.tab2data.shp_unt_suff!='        ')
              {
                this.display_proposal_values.shp_bas_suff_desc+='-'+this.tab2data.shp_unt_suff;
              }
              if(this.tab2data.shp_unt_desc!=null)
              {
              this.display_proposal_values.shp_bas_suff_desc+='-'+this.tab2data.shp_unt_desc;

              }

              //check for shipping unit ret type exp or retun
              //if expend display pallet value from api
              //else dipaly pallet also returnable
              //wood complaint and oficial stamp identificaton mark value comes only for na others null 



              if(this.tab2data.cd_ownrshp_strat!=null && this.tab2data.cd_ownrshp_strat!=0 && this.tab2data.cntr_ownrshp_strat_code!=null && this.tab2data.cntr_ownrshp_strat_code!='')
              {

                this.transactionService.getcosdetails(this.tab2data.cntr_ownrshp_strat_code,this.tab2data.cd_ownrshp_strat).subscribe(cosdetails=>{
                  console.log(cosdetails);
                  


                  this.display_proposal_values.cos_name=cosdetails.ownrshp_desc


                  console.log('newform',cosdetails.ownrshp_desc);
                  console.log('newform1',this.display_proposal_values.cos_name);

                })

              }


              if(this.selected_submitter.proposallastsaved>=3)
              {
                //service to call tab 3 get details


                this.transactionService.getaddedcomponentdetailsfortab3(this.selected_submitter.proposalid).subscribe(complist=>{


                  
                  console.log(complist);
                  let temp;
                  temp=JSON.parse(JSON.stringify(complist))

                  console.log(temp);
                  if(temp!=null &&temp.length!=0)
                  {
                    this.display_proposal_componenets=[];
                    let ownershp='';
                    for(let j=0;j<temp.length;j++)
                    {
                        console.log(temp[j].comp_ownershp);
                     if(temp[j].comp_ownershp.toLowerCase()=='ford')
                     {
                       ownershp='F';
                     }
                     else if(temp[j].comp_ownershp.toLowerCase()=='supplier')
                     {
                      ownershp='S';
                     }
                     else if(temp[j].comp_ownershp.toLowerCase()=='third party')
                     {
                      ownershp='T';

                     }


                     let total_qty=0;
                     let total_wt=0;


                     if(temp[j].compselctnfor.toLowerCase()=='container')
                     {



                      let total_container_pershp=0;
                      total_container_pershp=this.tab2data.cntr_per_layer*(this.tab2data.max_layrs_shp_unt-this.tab2data.empty_trays_cntr_layrs)
                      total_qty=total_container_pershp*temp[j].qt_per_min_su
                      total_wt=total_qty*temp[j].comp_tare_wt
                      total_wt=this.transactionService.roundValueToNDecimal(total_wt,3);



                     }


                     else if(temp[j].compselctnfor.toLowerCase()=='shipping unit')
                     {


                      total_qty=temp[j].qt_per_min_su
                      total_wt=total_qty*temp[j].comp_tare_wt
                      total_wt=this.transactionService.roundValueToNDecimal(total_wt,3);

                     }



                     console.log('comp desc check', temp[j].comp_desc)
                      let compDisplayInGrid='';
                      compDisplayInGrid=temp[j].comp_base
                      if(temp[j].comp_suffix != null && temp[j].comp_suffix.trim() != '')
                      {
                        compDisplayInGrid+='-'+temp[j].comp_suffix;
                      }
                      else
                      {
                      compDisplayInGrid+='-'+' ';
                      }
                      if(temp[j].comp_desc != null && temp[j].comp_desc.trim() != '')
                      {
                        compDisplayInGrid+='-'+temp[j].comp_desc;
                      }
                      else
                      {
                      compDisplayInGrid+='-'+' ';
                      }
                      if(temp[j].typ_no != null)
                      {
                      const typIndex=this.componentTypeList.findIndex(ele=>ele.typ_no == temp[j].typ_no)
                      if(typIndex != -1)
                      {
                        compDisplayInGrid+='-'+' ('+this.componentTypeList[typIndex].cntr_typ_and_desc+')';
                      }
                      else
                      {
                        compDisplayInGrid+='-'+' ('+temp[j].comp_code+')';
                      }
                      }
                      else
                      {
                      compDisplayInGrid+='-'+' ('+temp[j].comp_code+')';
                      }


                      this.display_proposal_componenets.push(
                        {
                         id_proposal:this.selected_submitter.proposalid,
                         id_comp:temp[j].id_comp,
                         comp_base:temp[j].comp_base,
                         comp_suffix:temp[j].comp_suffix,
                         comp_desc:temp[j].comp_desc,
                         comp_l:temp[j].comp_l,
                         comp_w:temp[j].comp_w,
                         comp_h:temp[j].comp_h,
                         comp_type:temp[j].comp_ret_type,
                         qt_per_min:total_qty,
                         updated_by:temp[j].upated_by,
                         updated_dt:temp[j].updated_dt,
                         comp_tar_wt:temp[j].comp_tare_wt,
                         nestedfoldedht:temp[j].nestedfolderht,
                         comp_code:temp[j].comp_code,
                         comp_ownershp:temp[j].comp_ownershp,
                         comp_material:temp[j].comp_material,
                         tooledcomp:temp[j].tooledcomp,
                         is_compulsary:temp[j].is_compulsary,
                         comp_Desc_display:compDisplayInGrid,
                         comp_ownrshp_display:ownershp,
                         total_wt_display:total_wt,
                          comp_cnt_no:j+1,
                          compselctnfor:temp[j].compselctnfor,
                          material_flag:temp[j].material_flag,
                          typ_no:temp[j].typ_no
                         });

                    }



                    if(this.display_proposal_componenets.length<12)
                    {
                      const existcnt=this.display_proposal_componenets.length;
                      const empt_cnt=12-existcnt;
                      for(let jk=0;jk<empt_cnt;jk++)
                      {


                        this.display_proposal_componenets.push(

                          {
                            id_proposal:0,
                         id_comp:0,
                         comp_base:null,
                         comp_suffix:null,
                         comp_desc:null,
                         comp_l:null,
                         comp_w:null,
                         comp_h:null,
                         comp_type:null,
                         qt_per_min:null,
                         updated_by:null,
                         updated_dt:null,
                         comp_tar_wt:null,
                         nestedfoldedht:null,
                         comp_code:null,
                         comp_ownershp:null,
                         comp_material:null,
                         tooledcomp:null,
                         is_compulsary:null,
                         comp_Desc_display:'',
                         comp_ownrshp_display:'',
                         total_wt_display:null,
                          comp_cnt_no:(existcnt+jk+1),
                          compselctnfor:'',
                          material_flag:null,
                          typ_no:0
                          }

                        )
                        
                      }
                    }

                  }
                  else{
                    this.display_proposal_componenets=[];

                    for(let jl=0;jl<12;jl++)
                      {

       
                        this.display_proposal_componenets.push(

                          {
                            id_proposal:0,
                         id_comp:0,
                         comp_base:null,
                         comp_suffix:null,
                         comp_desc:null,
                         comp_l:null,
                         comp_w:null,
                         comp_h:null,
                         comp_type:null,
                         qt_per_min:null,
                         updated_by:null,
                         updated_dt:null,
                         comp_tar_wt:null,
                         nestedfoldedht:null,
                         comp_code:null,
                         comp_ownershp:null,
                         comp_material:null,
                         tooledcomp:null,
                         is_compulsary:null,
                         comp_Desc_display:'',
                         comp_ownrshp_display:'',
                         total_wt_display:null,
                          comp_cnt_no:(jl+1),
                          compselctnfor:'',
                          material_flag:null,
                          typ_no:0
                          }

                        )
                        
                      }
                  }
                })



               this.transactionService.loadtab3detailsfromAPI(this.selected_submitter.proposalid).subscribe(tab3_data=>{

                  console.log(tab3_data);


                  this.display_proposal_values.cntr_shp_unit=tab3_data.cntr_shp_unit;
                  this.display_proposal_values.shp_unt_tar_wt=tab3_data.shp_unt_tar_wt;
                  this.display_proposal_values.shp_unt_gross_wt=tab3_data.shp_unt_gross_wt;
                  this.display_proposal_values.cntr_gross_wt=tab3_data.cntr_gross_wt;
                  this.display_proposal_values.ld_unload_inst=tab3_data.ld_unload_inst;
                  this.display_proposal_values.cntr_automtn_intf=tab3_data.cntr_automtn_intf;
                  this.display_proposal_values.transp_tested=tab3_data.transp_tested;
                  this.display_proposal_values.tst_reprt_num=tab3_data.tst_reprt_num;
                  this.display_proposal_values.mot=tab3_data.mot;
                  this.display_proposal_values.dangrs_good=tab3_data.dangrs_good;

                  const htmlString=tab3_data.dangrs_good_ship_detls ;
                  console.log('htmltostring',this.stripHtml(htmlString));
                  const op_string=this.stripHtml(htmlString);

                  // this.display_proposal_values.dangrs_good_ship_detls=op_string;
                  this.display_proposal_values.dangrs_good_ship_detls=tab3_data.dangrs_good_ship_detls;
                  this.display_proposal_values.rel_level=tab3_data.rel_level;

               



                //inside that service 
                if(this.selected_submitter.proposallastsaved>=4)
                {
                  //service to get tab4 details 
                  //for images append the values with the base url if value is not null or ""
                  //in html add a senario when the value is so  to display empty without img tag 


                  this.transactionService.loadtab4details(this.selected_submitter.proposalid).subscribe(tab4_data=>{


                    console.log(tab4_data)
                    this.display_proposal_values.f_extcntrdsgnphoto=tab4_data.f_extcntrdsgnphoto,
                    this.display_proposal_values.f_intcntrdsgnphoto=tab4_data.f_intcntrdsgnphoto;
                    this.display_proposal_values.f_metelidplatephoto=tab4_data.f_metelidplatephoto;
                    this.display_proposal_values.f_partphoto=tab4_data.f_partphoto;
                    this.display_proposal_values.f_shpunitphoto=tab4_data.f_shpunitphoto;
                    this.display_proposal_values.gen_propslcommnts=tab4_data.gen_propslcommnts;
                    this.display_proposal_values.int_cntrdesgncommnts=tab4_data.int_cntrdesgncommnts;
                    this.display_proposal_values.createmgcyexpndpckgprop=tab4_data.createmgcyexpndpckgprop;
                    this.display_proposal_values.agrmntexpndpackreqmnts=tab4_data.agrmntexpndpackreqmnts;
                    this.display_proposal_values.subpresloaddoc=tab4_data.subpresloaddoc;


                    if(this.display_proposal_values.subpresloaddoc!=null && this.display_proposal_values.subpresloaddoc!='')
                    {
                      this.display_proposal_values.subpresloaddoc_present='Y'
                    }
                    else
                    {
                      this.display_proposal_values.subpresloaddoc_present='N'
                    }
                    this.get_part_image_form();
                   this.get_exterior_image_form();
                   this.get_interior_image_form();
                   this.get_label_image_form();
                   this.get_shipping_image_form();


                   if(this.display_proposal_values.createmgcyexpndpckgprop!=null && this.display_proposal_values.createmgcyexpndpckgprop!='' && this.display_proposal_values.createmgcyexpndpckgprop.toLowerCase()=='yes')
                   {
                     
                    this.view_ee_proposal=true;


                    if(this.selected_submitter.proposallastsaved_ee>=2)
                    {
            
            
                      this.transactionService.loadtab2detailsifproposalexistalready_ee(this.selected_submitter.proposalid).subscribe(cont_cos_data=>
                        {
                          console.log(cont_cos_data);
                          this.tab2data_ee=cont_cos_data


                          console.log(this.tab2data,'tab2222')


                          this.display_proposal_values_ee.cntr_ret_type=this.tab2data_ee.cntr_ret_type
                          this.display_proposal_values_ee.used_carry_ovr_pck=this.tab2data_ee.used_carry_ovr_pck,
                          this.display_proposal_values_ee.cntr_base=this.tab2data_ee.cntr_base,
                          this.display_proposal_values_ee.cntr_suff=this.tab2data_ee.cntr_suff,
                          this.display_proposal_values_ee.cntr_desc=this.tab2data_ee.cntr_desc,
                          this.display_proposal_values_ee.parts_per_cntr=this.tab2data_ee.parts_per_cntr,
                          this.display_proposal_values_ee.cntr_ext_len=this.tab2data_ee.cntr_ext_len,
                          this.display_proposal_values_ee.cntr_width=this.tab2data_ee.cntr_width,
                          this.display_proposal_values_ee.cntr_height=this.tab2data_ee.cntr_height,
                          this.display_proposal_values_ee.cntr_nsted_fld_height=this.tab2data_ee.cntr_nsted_fld_height
                          this.display_proposal_values_ee.cntr_material=this.tab2data_ee.cntr_material,
                          this.display_proposal_values_ee.cntr_tar_weight=this.tab2data_ee.cntr_tar_weight,
                          this.display_proposal_values_ee.cntr_per_layer=this.tab2data_ee.cntr_per_layer,
                          this.display_proposal_values_ee.max_layrs_shp_unt=this.tab2data_ee.max_layrs_shp_unt,
                          this.display_proposal_values_ee.empty_trays_cntr_layrs=this.tab2data_ee.empty_trays_cntr_layrs,
                          this.display_proposal_values_ee.max_cntr_per_shp_unt=this.tab2data_ee.max_cntr_per_shp_unt,
                          this.display_proposal_values_ee.cntr_rck_colr=this.tab2data_ee.cntr_rck_colr,
                          this.display_proposal_values_ee.shp_unt_base=this.tab2data_ee.shp_unt_base,
                          this.display_proposal_values_ee.shp_unt_suff=this.tab2data_ee.shp_unt_suff,
                          this.display_proposal_values_ee.shp_unt_desc=this.tab2data_ee.shp_unt_desc,
                          this.display_proposal_values_ee.part_per_shp_unit=this.tab2data_ee.part_per_shp_unit,
                          this.display_proposal_values_ee.shp_unt_ret_type=this.tab2data_ee.shp_unt_ret_type,
                          this.display_proposal_values_ee.shp_unit_ext_len=this.tab2data_ee.shp_unit_ext_len,
                          this.display_proposal_values_ee.shp_unt_ext_width=this.tab2data_ee.shp_unt_ext_width,
                          this.display_proposal_values_ee.shp_unt_ext_height=this.tab2data_ee.shp_unt_ext_height,
                          this.display_proposal_values_ee.shp_unt_nestd_fld_height=this.tab2data_ee.shp_unt_nestd_fld_height,
                          this.display_proposal_values_ee.card_call=this.tab2data_ee.card_call,
                          this.display_proposal_values_ee.cntr_grp_id=this.tab2data_ee.cntr_grp_id,
                          this.display_proposal_values_ee.cntr_ownrshp_strat_code=this.tab2data_ee.cntr_ownrshp_strat_code,
                          this.display_proposal_values_ee.cd_cntr=this.tab2data_ee.cd_cntr,
                          this.display_proposal_values_ee.cd_ownrshp_strat=this.tab2data_ee.cd_ownrshp_strat;
                          this.display_proposal_values_ee.cntr_bas_suff=this.tab2data_ee.cntr_base;
                          this.display_proposal_values_ee.pallettype=this.tab2data_ee.pallettype;
                          this.display_proposal_values_ee.woodispmcmplnt=this.tab2data_ee.woodispmcmplnt;
                          this.display_proposal_values_ee.offstmpmartprsnt=this.tab2data_ee.offstmpmartprsnt;
                          this.display_proposal_values_ee.blanksperstack=this.tab2data_ee.blanksperstack;
                          this.display_proposal_values_ee.stacksperpallet=this.tab2data_ee.stacksperpallet;
                          this.display_proposal_values_ee.palletpintyp=this.tab2data_ee.palletpintyp;
                          this.display_proposal_values_ee.iscortonimcspec=this.tab2data_ee.iscartonimcspec;
                          this.display_proposal_values_ee.cortonburststrnth=this.tab2data_ee.cartonburststrnth;
                          this.display_proposal_values_ee.imcwtdisc=this.tab2data_ee.imcwtdisc;
          
                          this.display_proposal_values_ee.cntrOwner=this.tab2data_ee.cntrowner;
            
                          this.display_proposal_values_ee.bct=this.tab2data_ee.bct;
                          this.display_proposal_values_ee.ect=this.tab2data_ee.ect;

                          if(this.display_proposal_values.isstampblank.toLowerCase()=='no')
                          {
                         if( this.tab2data_ee.cntr_base.indexOf('IMC')>-1 && this.tab2data_ee.cntr_ret_type.toLowerCase()=='expendable' && this.tab2data_ee.cntr_grp_id != null && this.tab2data_ee.cntr_grp_id.toLowerCase().indexOf('imc')>-1)
                         {
            
                          this.iscontainer_ee=false;
                          this.ispallet_ee=false;
                          this.isimc_ee=true;
                  
                         }
                         else
                         {
                          this.iscontainer_ee=true;
                          this.ispallet_ee=false;
                          this.isimc_ee=false;
            
                         }
                        }





                          if(this.display_proposal_values_ee.shp_unt_ret_type.toLowerCase()=='returnable')
                          {


                            this.display_proposal_values_ee.shp_unt_ret_type_fullform='R';


                          }


                          else if (this.display_proposal_values_ee.shp_unt_ret_type.toLowerCase()=='expendable')
                          {


                            this.display_proposal_values_ee.shp_unt_ret_type_fullform='E';

                          }




                          if(this.tab2data_ee.cntr_suff!=null && this.tab2data_ee.cntr_suff!='        ')
                          {
                            this.display_proposal_values_ee.cntr_bas_suff+='-'+this.tab2data_ee.cntr_suff;

                          }

                          this.display_proposal_values_ee.shp_bas_suff_desc=this.tab2data_ee.shp_unt_base;

                          if(this.tab2data_ee.shp_unt_suff!=null && this.tab2data_ee.shp_unt_suff!='        ')
                          {
                            this.display_proposal_values_ee.shp_bas_suff_desc+='-'+this.tab2data_ee.shp_unt_suff;
                          }
                          if(this.tab2data_ee.shp_unt_desc!=null)
                          {
                          this.display_proposal_values_ee.shp_bas_suff_desc+='-'+this.tab2data_ee.shp_unt_desc;

                          }

                          // check for shipping unit ret type exp or retun
                          // if expend display pallet value from api
                          // else dipaly pallet also returnable
                          // wood complaint and oficial stamp identificaton mark value comes only for na others null



                          if(this.tab2data_ee.cd_ownrshp_strat!=null && this.tab2data_ee.cd_ownrshp_strat!=0 && this.tab2data_ee.cntr_ownrshp_strat_code!=null && this.tab2data_ee.cntr_ownrshp_strat_code!='')
                          {

                            this.transactionService.getcosdetails(this.tab2data_ee.cntr_ownrshp_strat_code,this.tab2data_ee.cd_ownrshp_strat).subscribe(cosdetails=>{
                              console.log(cosdetails);
                              
            
            
                              this.display_proposal_values_ee.cos_name=cosdetails.ownrshp_desc


                              console.log('newform',cosdetails.ownrshp_desc);
                              console.log('newform1',this.display_proposal_values_ee.cos_name);

                            })
            
                          }
            
            
                          if(this.selected_submitter.proposallastsaved_ee>=3)
                          {
                            //service to call tab 3 get details
            
            
                            this.transactionService.getaddedcomponentdetailsfortab3_ee(this.selected_submitter.proposalid).subscribe(complist=>{
            
            
                              
                              console.log(complist);
                              let temp;
                              temp=JSON.parse(JSON.stringify(complist))
            
                              console.log(temp);
                              if(temp!=null &&temp.length!=0)
                              {
                                this.display_proposal_componenets_ee=[];
                                let ownershp='';
                                for(let j=0;j<temp.length;j++)
                                {
                                    console.log(temp[j].comp_ownershp);
                                 if(temp[j].comp_ownershp.toLowerCase()=='ford')
                                 {
                                   ownershp='F';
                                 }
                                 else if(temp[j].comp_ownershp.toLowerCase()=='supplier')
                                 {
                                  ownershp='S';
                                 }
                                 else if(temp[j].comp_ownershp.toLowerCase()=='third party')
                                 {
                                  ownershp='T';

                                 }


                                 let total_qty=0;
                                 let total_wt=0;


                                 if(temp[j].compselctnfor.toLowerCase()=='container')
                                 {



                                  let total_container_pershp=0;
                                  total_container_pershp=this.tab2data_ee.cntr_per_layer*(this.tab2data_ee.max_layrs_shp_unt-this.tab2data_ee.empty_trays_cntr_layrs)
                                  total_qty=total_container_pershp*temp[j].qt_per_min_su
                                  total_wt=total_qty*temp[j].comp_tare_wt
                                  total_wt=this.transactionService.roundValueToNDecimal(total_wt,3);
            
            
            
                                 }


                                 else if(temp[j].compselctnfor.toLowerCase()=='shipping unit')
                                 {
            
            
                                  total_qty=temp[j].qt_per_min_su
                                  total_wt=total_qty*temp[j].comp_tare_wt
                                  total_wt=this.transactionService.roundValueToNDecimal(total_wt,3);
            
                                 }


                                 let compDisplayInGrid_ee='';
                                 compDisplayInGrid_ee=temp[j].comp_base
                                 if(temp[j].comp_suffix != null && temp[j].comp_suffix.trim() != '')
                                 {
                                  compDisplayInGrid_ee+='-'+temp[j].comp_suffix;
                                 }
                                 else
                                  {
                                  compDisplayInGrid_ee+='-'+' ';
                                  }
                                 if(temp[j].comp_desc != null && temp[j].comp_desc.trim() != '')
                                 {
                                  compDisplayInGrid_ee+='-'+temp[j].comp_desc;
                                 }
                                 else
                                 {
                                 compDisplayInGrid_ee+='-'+' ';
                                 }

                                 if(temp[j].typ_no != null)
                                 {
                                 const typIndex=this.componentTypeList.findIndex(ele=>ele.typ_no == temp[j].typ_no)
                                 if(typIndex != -1)
                                 {
                                   compDisplayInGrid_ee+='-'+' ('+this.componentTypeList[typIndex].cntr_typ_and_desc+')';
                                 }
                                 else
                                 {
                                   compDisplayInGrid_ee+='-'+' ('+temp[j].comp_code+')';
                                 }
                                 }
                                 else
                                 {
                                 compDisplayInGrid_ee+='-'+' ('+temp[j].comp_code+')';
                                 }

                                  
                                  this.display_proposal_componenets_ee.push(
                                    {
                                     id_proposal:this.selected_submitter.proposalid,
                                     id_comp:temp[j].id_comp,
                                     comp_base:temp[j].comp_base,
                                     comp_suffix:temp[j].comp_suffix,
                                     comp_desc:temp[j].comp_desc,
                                     comp_l:temp[j].comp_l,
                                     comp_w:temp[j].comp_w,
                                     comp_h:temp[j].comp_h,
                                     comp_type:temp[j].comp_ret_type,
                                     qt_per_min:total_qty,
                                     updated_by:temp[j].upated_by,
                                     updated_dt:temp[j].updated_dt,
                                     comp_tar_wt:temp[j].comp_tare_wt,
                                     nestedfoldedht:temp[j].nestedfolderht,
                                     comp_code:temp[j].comp_code,
                                     comp_ownershp:temp[j].comp_ownershp,
                                     comp_material:temp[j].comp_material,
                                     tooledcomp:temp[j].tooledcomp,
                                     is_compulsary:temp[j].is_compulsary,
                                     comp_Desc_display:compDisplayInGrid_ee,
                                     comp_ownrshp_display:ownershp,
                                     total_wt_display:total_wt,
                                      comp_cnt_no:j+1,
                                      compselctnfor:temp[j].compselctnfor,
                                      material_flag:temp[j].material_flag,
                                      typ_no:temp[j].typ_no
                                     });
            
                                }
            
            
            
                                if(this.display_proposal_componenets_ee.length<12)
                                {
                                  const existcnt=this.display_proposal_componenets_ee.length;
                                  const empt_cnt=12-existcnt;
                                  for(let jk=0;jk<empt_cnt;jk++)
                                  {
            
                   
                                    this.display_proposal_componenets_ee.push(
            
                                      {
                                        id_proposal:0,
                                     id_comp:0,
                                     comp_base:null,
                                     comp_suffix:null,
                                     comp_desc:null,
                                     comp_l:null,
                                     comp_w:null,
                                     comp_h:null,
                                     comp_type:null,
                                     qt_per_min:null,
                                     updated_by:null,
                                     updated_dt:null,
                                     comp_tar_wt:null,
                                     nestedfoldedht:null,
                                     comp_code:null,
                                     comp_ownershp:null,
                                     comp_material:null,
                                     tooledcomp:null,
                                     is_compulsary:null,
                                     comp_Desc_display:'',
                                     comp_ownrshp_display:'',
                                     total_wt_display:null,
                                      comp_cnt_no:(existcnt+jk+1),
                                      compselctnfor:'',
                                      material_flag:null,
                                      typ_no:0
                                      }
            
                                    )
                                    
                                  }
                                }
            
                              }
                              else{
                                this.display_proposal_componenets_ee=[];

                                for(let jl=0;jl<12;jl++)
                                  {
            
                   
                                    this.display_proposal_componenets_ee.push(
            
                                      {
                                        id_proposal:0,
                                     id_comp:0,
                                     comp_base:null,
                                     comp_suffix:null,
                                     comp_desc:null,
                                     comp_l:null,
                                     comp_w:null,
                                     comp_h:null,
                                     comp_type:null,
                                     qt_per_min:null,
                                     updated_by:null,
                                     updated_dt:null,
                                     comp_tar_wt:null,
                                     nestedfoldedht:null,
                                     comp_code:null,
                                     comp_ownershp:null,
                                     comp_material:null,
                                     tooledcomp:null,
                                     is_compulsary:null,
                                     comp_Desc_display:'',
                                     comp_ownrshp_display:'',
                                     total_wt_display:null,
                                      comp_cnt_no:(jl+1),
                                      compselctnfor:'',
                                      material_flag:null,
                                      typ_no:0
                                      }
            
                                    )
                                    
                                  }
                              }
                            })
            
            
                           this.transactionService.loadtab3detailsfromAPI_ee(this.selected_submitter.proposalid).subscribe(tab3_data=>{
            
                              console.log(tab3_data);
            
            
                              this.display_proposal_values_ee.cntr_shp_unit=tab3_data.cntr_shp_unit;
                              this.display_proposal_values_ee.shp_unt_tar_wt=tab3_data.shp_unt_tar_wt;
                              this.display_proposal_values_ee.shp_unt_gross_wt=tab3_data.shp_unt_gross_wt;
                              this.display_proposal_values_ee.cntr_gross_wt=tab3_data.cntr_gross_wt;
                              this.display_proposal_values_ee.ld_unload_inst=tab3_data.ld_unload_inst;
                              this.display_proposal_values_ee.cntr_automtn_intf=tab3_data.cntr_automtn_intf;
                              this.display_proposal_values_ee.transp_tested=tab3_data.transp_tested;
                              this.display_proposal_values_ee.tst_reprt_num=tab3_data.tst_reprt_num;
                              this.display_proposal_values_ee.mot=tab3_data.mot;
                              this.display_proposal_values_ee.dangrs_good=tab3_data.dangrs_good;

                              const htmlString=tab3_data.dangrs_good_ship_detls ;
                              console.log('htmltostring',this.stripHtml(htmlString));
                              const op_string=this.stripHtml(htmlString);
                              // this.display_proposal_values_ee.dangrs_good_ship_detls=op_string;
                              this.display_proposal_values_ee.dangrs_good_ship_detls=tab3_data.dangrs_good_ship_detls;
                              this.display_proposal_values_ee.rel_level=tab3_data.rel_level;
            
                           
            
            
            
                            //inside that service 
                            if(this.selected_submitter.proposallastsaved_ee>=4)
                            {
                              //service to get tab4 details 
                              //for images append the values with the base url if value is not null or ""
                              //in html add a senario when the value is so  to display empty without img tag 
            
            
                              this.transactionService.loadtab4details_ee(this.selected_submitter.proposalid).subscribe(tab4_data=>{
            
            
                                console.log(tab4_data)
                                this.display_proposal_values_ee.f_extcntrdsgnphoto=tab4_data.f_extcntrdsgnphoto,
                                this.display_proposal_values_ee.f_intcntrdsgnphoto=tab4_data.f_intcntrdsgnphoto;
                                this.display_proposal_values_ee.f_metelidplatephoto=tab4_data.f_metelidplatephoto;
                                this.display_proposal_values_ee.f_partphoto=tab4_data.f_partphoto;
                                this.display_proposal_values_ee.f_shpunitphoto=tab4_data.f_shpunitphoto;
                                this.display_proposal_values_ee.gen_propslcommnts=tab4_data.gen_propslcommnts;
                                this.display_proposal_values_ee.int_cntrdesgncommnts=tab4_data.int_cntrdesgncommnts;
                                this.display_proposal_values_ee.createmgcyexpndpckgprop=tab4_data.createmgcyexpndpckgprop;
                                this.display_proposal_values_ee.agrmntexpndpackreqmnts=tab4_data.agrmntexpndpackreqmnts;
                                this.display_proposal_values_ee.subpresloaddoc=tab4_data.subpresloaddoc;


                                if(this.display_proposal_values_ee.subpresloaddoc!=null && this.display_proposal_values_ee.subpresloaddoc!='')
                                {
                                  this.display_proposal_values_ee.subpresloaddoc_present='Y'
                                }
                                else
                                {
                                  this.display_proposal_values_ee.subpresloaddoc_present='N'
                                }
                                this.get_part_image_form_ee();
                               this.get_exterior_image_form_ee();
                               this.get_interior_image_form_ee();
                               this.get_label_image_form_ee();
                               this.get_shipping_image_form_ee();
        
            
            
            
            
                              })
                            }
            
            
                          })
                          }
            
                        })  
            
                    }

                   }

                   else
                   {
                     this.view_ee_proposal=false;
                   }





                  })
                }


              })
              }

            })  

        }
      
      
      
      
      })
       



    })
  }


}


else if(this.selected_submitter != null) 
//if(this.selected_submitter.part_status=="Submitted")
{
  console.log('Submitted');
  this.display_allvalues_init();


  this.submit_date='';
  this.isuploadedtocmms=false;
  this.transactionService.loadtransacmasterdetails(this.selected_submitter.proposalid).subscribe(transdet=>{



    console.log('transactiondetails',transdet);
    this.display_proposal_values=transdet;
    console.log(this.display_proposal_values);

    this.display_proposal_values.ect=transdet.ect;
    this.display_proposal_values.bct=transdet.bct;

    if(this.display_proposal_values.cmmsuploadstat != null && this.display_proposal_values.cmmsuploadstat.toLowerCase() == 'x')
    {
      this.isuploadedtocmms=true;
    }
    else
    {
      this.isuploadedtocmms=false;
    }


    var is_design_src="N"
    if(this.display_proposal_values.supgsdbcode!=null && this.display_proposal_values.supgsdbcode!="")
    {
      var is_design_src="N"
      if(this.display_proposal_values.supgsdbcode!=null && this.display_proposal_values.supgsdbcode!="")
      {
        this.transactionService.getsuppdet(this.display_proposal_values.supgsdbcode,is_design_src).subscribe(suppdetails=>{
  
          console.log("supplier details",suppdetails);
          this.selected_supp_details_trans=suppdetails;

          if(this.selected_supp_details_trans!=null)
          {
            if(this.selected_supp_details_trans.fullname!=null)
            {
            this.display_proposal_values.supfullname=this.selected_supp_details_trans.fullname;
            }
            else
            {
              this.display_proposal_values.supfullname="";
            }
            if(this.selected_supp_details_trans.address1!=null)
            {
            this.display_proposal_values.supaddress1=this.selected_supp_details_trans.address1;
            }
            else
            {
              this.display_proposal_values.supaddress1="";
            }
            if(this.selected_supp_details_trans.city!=null)
            {
            this.display_proposal_values.supcity=this.selected_supp_details_trans.city;
            }
            else
            {
              this.display_proposal_values.supcity="";
            }
            if(this.selected_supp_details_trans.stateprovince!=null)
            {
            this.display_proposal_values.supstateprov=this.selected_supp_details_trans.stateprovince;
            }
            else
            {
              this.display_proposal_values.supstateprov="";
            }
            if(this.selected_supp_details_trans.countrycode!=null)
            {
            this.display_proposal_values.supcountrycode=this.selected_supp_details_trans.countrycode;
            }
            else
            {
              this.display_proposal_values.supcountrycode="";
            }
            if(this.selected_supp_details_trans.contactnamefirst!=null)
            {
            this.display_proposal_values.supcontactnamefirst=this.selected_supp_details_trans.contactnamefirst;
            }
            else
            {
              this.display_proposal_values.supcontactnamefirst="";
            }
            if(this.selected_supp_details_trans.contactname!=null)
            {
        
            this.display_proposal_values.supcontactname=this.selected_supp_details_trans.contactname;
            }
            else
            {
              this.display_proposal_values.supcontactname="";
            }
            if(this.selected_supp_details_trans.contactfunctn!=null)
            {
            this.display_proposal_values.supcontactfunctn=this.selected_supp_details_trans.contactfunctn;
            }
            else
            {
              this.display_proposal_values.supcontactfunctn="";
            }
            if(this.selected_supp_details_trans.contactemail!=null)
            {
            this.display_proposal_values.supcontactemail=this.selected_supp_details_trans.contactemail;
            }
            else
            {
              this.display_proposal_values.supcontactemail="";
            }
            if(this.selected_supp_details_trans.contactphone!=null)
            {
            this.display_proposal_values.supcontactphone=this.selected_supp_details_trans.contactphone;
            }
            else
            {
              this.display_proposal_values.supcontactphone="           ";
            }
            if(this.selected_supp_details_trans.contactfax!=null)
            {
            this.display_proposal_values.supcontactfax=this.selected_supp_details_trans.contactfax;
            }
            else
            {
              this.display_proposal_values.supcontactfax="";
            }
        
            this.display_proposal_values.supp_city_prov_country=""
            if(this.display_proposal_values.supcity!="")
            {
            this.display_proposal_values.supp_city_prov_country+=this.display_proposal_values.supcity+',';
            }
            if(this.display_proposal_values.supstateprov!="")
            {
            this.display_proposal_values.supp_city_prov_country+=this.display_proposal_values.supstateprov+',';
            }
            if(this.display_proposal_values.supcountrycode!="")
            {
            this.display_proposal_values.supp_city_prov_country+=this.display_proposal_values.supcountrycode;
            }
        
          }
        })
      }
    }

    



    if(this.display_proposal_values.isstampblank.toLowerCase()=='yes')
    {
      this.ispallet=true;
      this.iscontainer=false;
      this.isimc=false;


    }

    if(this.display_proposal_values.cntr_ret_type.toLowerCase()=='expendable')
    {
      this.isExpendable=true;
    }
    else
    {
      this.isExpendable=false;
    }

    if(this.display_proposal_values.isstampblank.toLowerCase()=='no')
    {
   if( this.display_proposal_values.cntr_base.indexOf('IMC')>-1 && this.display_proposal_values.cntr_ret_type.toLowerCase()=='expendable' && this.display_proposal_values.cntr_grp_id != null && this.display_proposal_values.cntr_grp_id.toLowerCase().indexOf('imc')>-1)
   {

    this.iscontainer=false;
    this.ispallet=false;
    this.isimc=true;

   }
   else
   {
    this.iscontainer=true;
    this.ispallet=false;
    this.isimc=false;

   }
  }


    if(this.display_proposal_values.shp_unt_ret_type.toLowerCase()=='returnable')
    {


      this.display_proposal_values.shp_unt_ret_type_fullform='R';


    }


    else if (this.display_proposal_values.shp_unt_ret_type.toLowerCase()=='expendable')
    {

      this.display_proposal_values.shp_unt_ret_type_fullform='E';

    }


    this.display_proposal_values.cntr_bas_suff=transdet.cntr_base
    if(transdet.cntr_suff!=null && transdet.cntr_suff!='        ')
    {
      this.display_proposal_values.cntr_bas_suff+='-'+transdet.cntr_suff;

    }
    
    this.display_proposal_values.shp_bas_suff_desc=transdet.shp_unt_base;

    if(transdet.shp_unt_suff!=null && transdet.shp_unt_suff!='        ')
    {
      this.display_proposal_values.shp_bas_suff_desc+='-'+transdet.shp_unt_suff;
    }
    if(transdet.shp_unt_desc!=null)
    {
    this.display_proposal_values.shp_bas_suff_desc+='-'+transdet.shp_unt_desc;

    }


   


    if(transdet.cd_ownrshp_strat!=null && transdet.cd_ownrshp_strat!=0 && transdet.cntr_ownrshp_strat_code!=null && transdet.cntr_ownrshp_strat_code!='')
    {

      this.transactionService.getcosdetails(transdet.cntr_ownrshp_strat_code,transdet.cd_ownrshp_strat).subscribe(cosdetails_1=>{
        console.log(cosdetails_1);
     


        this.display_proposal_values.cos_name=cosdetails_1.ownrshp_desc


        console.log('newform',cosdetails_1.ownrshp_desc);
        console.log('newform1',this.display_proposal_values.cos_name);

      })

    }





    const htmlString_1=transdet.dangrs_good_ship_detls ;
    console.log('htmltostring',this.stripHtml(htmlString_1));
    const op_string_1=this.stripHtml(htmlString_1);

    //this.display_proposal_values.dangrs_good_ship_detls=op_string_1;

    this.display_proposal_values.dangrs_good_ship_detls=transdet.dangrs_good_ship_detls;



    if(this.display_proposal_values.subpresloaddoc!=null && this.display_proposal_values.subpresloaddoc!='')
    {
      this.display_proposal_values.subpresloaddoc_present='Y'
    }
    else
    {
      this.display_proposal_values.subpresloaddoc_present='N'
    }


    if(this.display_proposal_values.pkgsignoff!=null && this.display_proposal_values.pkgsignoff!='')
                    {
                      this.display_proposal_values.pkgsignoff_present='Y'
                    }
                    else
                    {
                      this.display_proposal_values.pkgsignoff_present='N'
                    }



    this.get_part_image_form();
    this.get_exterior_image_form();
    this.get_interior_image_form();
    this.get_label_image_form();
    this.get_shipping_image_form();



    if(this.display_proposal_values.apprvr_cdsid==null)
    {
      this.display_proposal_values.apprvr_cdsid='';
    }
    if(this.display_proposal_values.apprvr_asgn_date==null)
    {
      this.display_proposal_values.apprvr_asgn_date='';
    }
    else
    {
      this.display_proposal_values.apprvr_asgn_date=this.date.transform( this.display_proposal_values.apprvr_asgn_date, 'yyyy-MM-dd');
    }

    const revision_arr_form=[];
    this.approver_list_array_form=[]
    this.transactionService.getRevisionHistoryByProposalId(this.selected_submitter.proposalid).subscribe(revhistapi=>{
      let revision_arr_form:RevHistFromAPi[]=[];

      revision_arr_form=revhistapi;
      this.submit_date='';
      this.curr_aprvl_status='';
      const rev_approver_list:RevHistFromAPi[]=[];
      this.approver_list_array_form=[]
      if(revision_arr_form != null && revision_arr_form.length != 0)
      {
        const submittedIndex=revision_arr_form.findIndex(ele => ele.proposalstatuscurr.toLowerCase().indexOf('submitted')>-1)
        if(submittedIndex != -1)
        {
          this.submit_date=revision_arr_form[submittedIndex].evnttime;
          console.log('submitdate',this.submit_date);
          console.log('rev',revision_arr_form[submittedIndex]);
        }

        this.curr_aprvl_status=revision_arr_form[0].proposalstatuscurr;

        for(let kj=0;kj<revision_arr_form.length;kj++)
        {

          if(revision_arr_form[kj].apprvractn != null && revision_arr_form[kj].apprvractn.toLowerCase().indexOf('approved')>-1 
          && !(revision_arr_form[kj].proposalstatuscurr.toLowerCase().indexOf('rejected')>-1))
          {
          rev_approver_list.push(revision_arr_form[kj])
          }

        }
        let max_appr_len;
        if(rev_approver_list.length!=0)
        {
          if(rev_approver_list.length<=8)
          {
          max_appr_len=rev_approver_list.length
          }
          else
          {
            max_appr_len=8;
          }

          for(let km=0;km<max_appr_len;km++)
          {

            let stage_date='';
            let cds_name='';
            let appr_disp_list;

            if(rev_approver_list[km].proposalstatuscurr != null)
            {
              if(rev_approver_list[km].proposalstatuscurr.toLowerCase().indexOf('additional approval')>-1 || rev_approver_list[km].proposalstatuscurr.toLowerCase().indexOf('additional approver')>-1)
              {
                stage_date='AP'
              }
              else if(rev_approver_list[km].proposalstatuscurr.toLowerCase().indexOf('preliminary approval')>-1)
              {
                stage_date='PA'
              }
              else if(rev_approver_list[km].proposalstatuscurr.toLowerCase().indexOf('plant approval')>-1 || rev_approver_list[km].proposalstatuscurr.toLowerCase().indexOf('plant approver')>-1)
              {
                stage_date='PL'
              }
              else if(rev_approver_list[km].proposalstatuscurr.toLowerCase().indexOf('release for evaluation')>-1)
              {
                stage_date='RE'
              }
              else if(rev_approver_list[km].proposalstatuscurr.toLowerCase().indexOf('plant final approval')>-1 || rev_approver_list[km].proposalstatuscurr.toLowerCase().indexOf('plant final approver')>-1)
              {
                stage_date='PF'
              }
              else if(rev_approver_list[km].proposalstatuscurr.toLowerCase().indexOf('release for production')>-1)
              {
                stage_date='RP'
              }
              else if(rev_approver_list[km].proposalstatuscurr.toLowerCase().indexOf('approved for production')>-1)
              {
                stage_date='APD'
              }
              else if(rev_approver_list[km].proposalstatuscurr.toLowerCase().indexOf('migration approval')>-1)
              {
                stage_date='MA'
              }



              let timestamp_apr;
              timestamp_apr=rev_approver_list[km].evnttime.split(' ');
              stage_date+=' '+timestamp_apr[0];


              let plantcode;

              plantcode=rev_approver_list[km].proposalstatuscurr.split(' ');


              cds_name=plantcode[0];
              cds_name+=' '+rev_approver_list[km].userid;


              this.approver_list_array_form[2*km]=stage_date;
              this.approver_list_array_form[(2*km)+1]=cds_name;
            }
          }
        }
    
      }
    })
 


    this.transactionService.loadtransacmasterparts(this.selected_submitter.proposalid).subscribe(transpart=>{
      console.log(transpart);


      this.display_proposal_parts=[];


      const grid1_parts_trans:fromdiplayvalues_parts[]=[];
      let grid2_parts_trans:fromdiplayvalues_parts[]=[];
        if(transpart.length!=0)
        {
         for(let i=0;i<transpart.length;i++)
         {
           if(transpart[i].isprimary.toLowerCase()=='yes')
           {
              grid1_parts_trans.push(transpart[i]);
              this.display_proposal_values.p_part_len=Math.round(transpart[i].ngpp_part_l * 1000) / 1000
              this.display_proposal_values.p_part_wdt= Math.round(transpart[i].ngpp_part_w * 1000) / 1000
              this.display_proposal_values.p_part_ht=Math.round(transpart[i].ngpp_part_h * 1000) / 1000
              this.display_proposal_values.p_part_wt=Math.round(transpart[i].ngpp_part_wt * 1000) / 1000
              if(transpart[i].ngpp_part_wt_status!=null)
              {
                if(transpart[i].ngpp_part_wt_status.toLowerCase()=='actual')
                {
                  this.display_proposal_values.p_part_wt_stat='A'
                }
                else if(transpart[i].ngpp_part_wt_status.toLowerCase()=='estimate')
                {
                  this.display_proposal_values.p_part_wt_stat='E'
                }
              }

              let partRegion='';
              partRegion=transpart[i].cd_region;
              this.transactionService.get_region_details(partRegion).subscribe(regionOutput=>{

                console.log('Region Details Form Display Component', regionOutput)

                if(regionOutput != null)
                {
                  this.regionAdminDetails=regionOutput;
                  if(this.regionAdminDetails.uom != null && this.regionAdminDetails.uom.toLowerCase() == 'e')
                  {
                    this.weightMeasurements='lbs';
                    this.dimensionMeasurements='in';
                    this.cols1 = [
                      { field: 'comp_cnt_no', header:'#'},
                      {field: 'comp_Desc_display', header:'CMMS Code Description'},
                      {field:'comp_ownrshp_display',header:'Owner'},
                      { field: 'comp_material', header:'Materials'},
                      { field: 'qt_per_min', header:'QTY'},
                      { field: 'comp_type', header:'E/R'},
                      { field: 'comp_l', header:'L (in)'},
                      {field: 'comp_w', header:'W (in)'},
                      {field:'comp_h',header:'H (in)'},
                      { field: 'nestedfoldedht', header:'Ntbl/Fld H (in)'},
                      { field: 'comp_tar_wt', header:'Tare Wt (lbs)'},
                      { field: 'total_wt_display', header:'Total Wt (lbs)'},
                    
                    ];
                  }
                  else if(this.regionAdminDetails.uom != null && this.regionAdminDetails.uom.toLowerCase() == 'm')
                  {
                    this.weightMeasurements='kg';
                    this.dimensionMeasurements='cm';
                    this.cols1 = [
                      { field: 'comp_cnt_no', header:'#'},
                      {field: 'comp_Desc_display', header:'CMMS Code Description'},
                      {field:'comp_ownrshp_display',header:'Owner'},
                      { field: 'comp_material', header:'Materials'},
                      { field: 'qt_per_min', header:'QTY'},
                      { field: 'comp_type', header:'E/R'},
                      { field: 'comp_l', header:'L (cm)'},
                      {field: 'comp_w', header:'W (cm)'},
                      {field:'comp_h',header:'H (cm)'},
                      { field: 'nestedfoldedht', header:'Ntbl/Fld H (cm)'},
                      { field: 'comp_tar_wt', header:'Tare Wt (kg)'},
                      { field: 'total_wt_display', header:'Total Wt (kg)'},
                    
                    ];
                  }
                  else
                  {
                    this.weightMeasurements='';
                    this.dimensionMeasurements='';


                  }
                }

                else
                {
                  this.weightMeasurements='';
                  this.dimensionMeasurements='';
                }

                console.log('weightMeasurements',this.weightMeasurements);
                console.log('dimensionMeasurements',this.dimensionMeasurements);




              })
           }
           else{
             grid2_parts_trans.push(transpart[i]);
           }
         }

         if(grid1_parts_trans.length != 0)
         {
         this.display_proposal_parts.push(grid1_parts_trans[0]);
         }
         const sortedGrid2Trans = grid2_parts_trans.sort((r1, r2) => (r1.selorder > r2.selorder) ? 1 : (r1.selorder < r2.selorder) ? -1 : 0);
         grid2_parts_trans=[];
         grid2_parts_trans.push(...sortedGrid2Trans);
         this.display_proposal_parts.push(...grid2_parts_trans);


         this.numbers=[];


         let total=0;
         let modulo=0;




         total=(this.display_proposal_parts.length)/9;
         total=this.float2int(total);
         console.log(total);
         
      
      
         modulo=(this.display_proposal_parts.length)%9;
      
      
         if(modulo!=0)
         {
      
          total=total+1;
      
         }
      
      
      
      
      
      
         this.numbers = Array(total).fill(0).map((x,i)=>i);
        }

        this.get_part_image_form();
    this.get_exterior_image_form();
    this.get_interior_image_form();
    this.get_label_image_form();
    this.get_shipping_image_form();


        this.transactionService.loadtransacmastercomp(this.selected_submitter.proposalid).subscribe(transcomp=>{
          console.log(transcomp);


          this.display_proposal_componenets=[];


          let temp_transac;
          temp_transac=JSON.parse(JSON.stringify(transcomp))

                  console.log(temp_transac);
                  if(temp_transac!=null &&temp_transac.length!=0)
                  {
                    this.display_proposal_componenets=[];
                    let ownershp='';
                    for(let j=0;j<temp_transac.length;j++)
                    {
                        console.log(temp_transac[j].comp_ownershp);
                     if(temp_transac[j].comp_ownershp.toLowerCase()=='ford')
                     {
                       ownershp='F';
                     }
                     else if(temp_transac[j].comp_ownershp.toLowerCase()=='supplier')
                     {
                      ownershp='S';
                     }
                     else if(temp_transac[j].comp_ownershp.toLowerCase()=='third party')
                     {
                      ownershp='T';

                     }


                     let total_qty=0;
                     let total_wt=0;


                     if(temp_transac[j].compselctnfor.toLowerCase()=='container')
                     {



                      let total_container_pershp=0;
                      total_container_pershp=this.display_proposal_values.cntr_per_layer*(this.display_proposal_values.max_layrs_shp_unt-this.display_proposal_values.empty_trays_cntr_layrs)
                      total_qty=total_container_pershp*temp_transac[j].qt_per_min
                      total_wt=total_qty*temp_transac[j].comp_tar_wt
                      total_wt=this.transactionService.roundValueToNDecimal(total_wt,3);



                     }


                     else if(temp_transac[j].compselctnfor.toLowerCase()=='shipping unit')
                     {


                      total_qty=temp_transac[j].qt_per_min
                      total_wt=total_qty*temp_transac[j].comp_tar_wt
                      total_wt=this.transactionService.roundValueToNDecimal(total_wt,3);
                      console.log(temp_transac[j].comp_tar_wt);
                      console.log(total_wt);


                      

                     }

                     console.log('comp desc transa',temp_transac[j].comp_desc);

                     let compDisplayInGrid='';
                     compDisplayInGrid=temp_transac[j].comp_base
                     if(temp_transac[j].comp_suffix != null && temp_transac[j].comp_suffix.trim() != '')
                     {
                      compDisplayInGrid+='-'+temp_transac[j].comp_suffix;
                     }
                     else
                    {
                    compDisplayInGrid+='-'+' ';
                    }
                     if(temp_transac[j].comp_desc != null && temp_transac[j].comp_desc.trim() != '')
                     {
                      compDisplayInGrid+='-'+temp_transac[j].comp_desc;
                     }
                     else
                    {
                    compDisplayInGrid+='-'+' ';
                    }
                    if(temp_transac[j].typ_no != null)
                    {
                    const typIndex=this.componentTypeList.findIndex(ele=>ele.typ_no == temp_transac[j].typ_no)
                    if(typIndex != -1)
                    {
                      compDisplayInGrid+='-'+' ('+this.componentTypeList[typIndex].cntr_typ_and_desc+')';
                    }
                    else
                    {
                      compDisplayInGrid+='-'+' ('+temp_transac[j].comp_code+')';
                    }
                    }
                    else
                    {
                    compDisplayInGrid+='-'+' ('+temp_transac[j].comp_code+')';
                    }


                      this.display_proposal_componenets.push(
                        {
                         id_proposal:this.selected_submitter.proposalid,
                         id_comp:temp_transac[j].id_comp,
                         comp_base:temp_transac[j].comp_base,
                         comp_suffix:temp_transac[j].comp_suffix,
                         comp_desc:temp_transac[j].comp_desc,
                         comp_l:temp_transac[j].comp_l,
                         comp_w:temp_transac[j].comp_w,
                         comp_h:temp_transac[j].comp_h,
                         comp_type:temp_transac[j].comp_type,
                         qt_per_min:total_qty,
                         updated_by:temp_transac[j].upated_by,
                         updated_dt:temp_transac[j].updated_dt,
                         comp_tar_wt:temp_transac[j].comp_tar_wt,
                         nestedfoldedht:temp_transac[j].nestedfoldedht,
                         comp_code:temp_transac[j].comp_code,
                         comp_ownershp:temp_transac[j].comp_ownershp,
                         comp_material:temp_transac[j].comp_material,
                         tooledcomp:temp_transac[j].tooledcomp,
                         is_compulsary:temp_transac[j].is_compulsary,
                         comp_Desc_display:compDisplayInGrid,
                         comp_ownrshp_display:ownershp,
                         total_wt_display:total_wt,
                          comp_cnt_no:j+1,
                          compselctnfor:temp_transac[j].compselctnfor,
                          material_flag:temp_transac[j].material_flag,
                          typ_no:temp_transac[j].typ_no
                         });

                    }



                    if(this.display_proposal_componenets.length<12)
                    {
                      const existcnt=this.display_proposal_componenets.length;
                      const empt_cnt=12-existcnt;
                      for(let jk=0;jk<empt_cnt;jk++)
                      {

       
                        this.display_proposal_componenets.push(

                          {
                            id_proposal:0,
                         id_comp:0,
                         comp_base:null,
                         comp_suffix:null,
                         comp_desc:null,
                         comp_l:null,
                         comp_w:null,
                         comp_h:null,
                         comp_type:null,
                         qt_per_min:null,
                         updated_by:null,
                         updated_dt:null,
                         comp_tar_wt:null,
                         nestedfoldedht:null,
                         comp_code:null,
                         comp_ownershp:null,
                         comp_material:null,
                         tooledcomp:null,
                         is_compulsary:null,
                         comp_Desc_display:'',
                         comp_ownrshp_display:'',
                         total_wt_display:null,
                          comp_cnt_no:(existcnt+jk+1),
                          compselctnfor:'',
                          material_flag:null,
                          typ_no:0
                          }

                        )
                        
                      }
                    }

                  }
                  else{
                    this.display_proposal_componenets=[];

                    for(let jl=0;jl<12;jl++)
                      {

       
                        this.display_proposal_componenets.push(

                          {
                            id_proposal:0,
                         id_comp:0,
                         comp_base:null,
                         comp_suffix:null,
                         comp_desc:null,
                         comp_l:null,
                         comp_w:null,
                         comp_h:null,
                         comp_type:null,
                         qt_per_min:null,
                         updated_by:null,
                         updated_dt:null,
                         comp_tar_wt:null,
                         nestedfoldedht:null,
                         comp_code:null,
                         comp_ownershp:null,
                         comp_material:null,
                         tooledcomp:null,
                         is_compulsary:null,
                         comp_Desc_display:'',
                         comp_ownrshp_display:'',
                         total_wt_display:null,
                          comp_cnt_no:(jl+1),
                          compselctnfor:'',
                          material_flag:null,
                          typ_no:0
                          }

                        )
                        
                      }
                  }



                    console.log(this.display_proposal_componenets);

                  if(this.display_proposal_values.createmgcyexpndpckgprop!=null && this.display_proposal_values.createmgcyexpndpckgprop!='' && this.display_proposal_values.createmgcyexpndpckgprop.toLowerCase()=='yes')
                  {
                    this.view_ee_proposal=true;
                    this.display_allvalues_init_ee();


                    //this.submit_date="";
                    this.transactionService.loadtransacmasterdetails_ee(this.selected_submitter.proposalid).subscribe(transdet_ee=>{



                      console.log('transactiondetails',transdet_ee);
                      this.display_proposal_values_ee=transdet_ee;
                      console.log(this.display_proposal_values_ee);
                  
                  
                      this.display_proposal_values_ee.bct=transdet_ee.bct;
                      this.display_proposal_values_ee.ect=transdet_ee.ect;



                      if(this.display_proposal_values.isstampblank.toLowerCase()=='yes')
                      {
                        this.ispallet_ee=true;
                        this.iscontainer_ee=false;
                        this.isimc_ee=false;
                  
                  
                      }


                      if(this.display_proposal_values.isstampblank.toLowerCase()=='no')
                      {
                     if( this.display_proposal_values_ee.cntr_base.indexOf('IMC')>-1 && this.display_proposal_values_ee.cntr_ret_type.toLowerCase()=='expendable' && this.display_proposal_values_ee.cntr_grp_id != null && this.display_proposal_values_ee.cntr_grp_id.toLowerCase().indexOf('imc')>-1)
                     {
                  
                      this.iscontainer_ee=false;
                      this.ispallet_ee=false;
                      this.isimc_ee=true;
                  
                     }
                     else
                     {
                      this.iscontainer_ee=true;
                      this.ispallet_ee=false;
                      this.isimc_ee=false;
                  
                     }
                    }


                      if(this.display_proposal_values_ee.shp_unt_ret_type.toLowerCase()=='returnable')
                      {


                        this.display_proposal_values_ee.shp_unt_ret_type_fullform='R';


                      }


                      else if (this.display_proposal_values_ee.shp_unt_ret_type.toLowerCase()=='expendable')
                      {

                        this.display_proposal_values_ee.shp_unt_ret_type_fullform='E';

                      }
                  
                  
                      this.display_proposal_values_ee.cntr_bas_suff=transdet_ee.cntr_base
                      if(transdet_ee.cntr_suff!=null && transdet_ee.cntr_suff!='        ')
                      {
                        this.display_proposal_values_ee.cntr_bas_suff+='-'+transdet_ee.cntr_suff;

                      }
                      
                      this.display_proposal_values_ee.shp_bas_suff_desc=transdet_ee.shp_unt_base;

                      if(transdet_ee.shp_unt_suff!=null && transdet_ee.shp_unt_suff!='        ')
                      {
                        this.display_proposal_values_ee.shp_bas_suff_desc+='-'+transdet_ee.shp_unt_suff;
                      }
                      if(transdet_ee.shp_unt_desc!=null)
                      {
                      this.display_proposal_values_ee.shp_bas_suff_desc+='-'+transdet_ee.shp_unt_desc;

                      }





                      if(transdet_ee.cd_ownrshp_strat!=null && transdet_ee.cd_ownrshp_strat!=0 && transdet_ee.cntr_ownrshp_strat_code!=null && transdet_ee.cntr_ownrshp_strat_code!='')
                      {
                  
                        this.transactionService.getcosdetails(transdet_ee.cntr_ownrshp_strat_code,transdet_ee.cd_ownrshp_strat).subscribe(cosdetails_1=>{
                          console.log(cosdetails_1);
                       
                  
                  
                          this.display_proposal_values_ee.cos_name=cosdetails_1.ownrshp_desc


                          console.log('newform',cosdetails_1.ownrshp_desc);
                          console.log('newform1',this.display_proposal_values_ee.cos_name);

                        })
                  
                      }





                      const htmlString_1=transdet_ee.dangrs_good_ship_detls ;
                      console.log('htmltostring',this.stripHtml(htmlString_1));
                      const op_string_1=this.stripHtml(htmlString_1);
                      // this.display_proposal_values_ee.dangrs_good_ship_detls=op_string_1;

                      this.display_proposal_values_ee.dangrs_good_ship_detls=transdet_ee.dangrs_good_ship_detls;




                      if(this.display_proposal_values_ee.subpresloaddoc!=null && this.display_proposal_values_ee.subpresloaddoc!='')
                      {
                        this.display_proposal_values_ee.subpresloaddoc_present='Y'
                      }
                      else
                      {
                        this.display_proposal_values_ee.subpresloaddoc_present='N'
                      }
                  
                  
                    
                  
                  
                      this.get_part_image_form_ee();
                      this.get_exterior_image_form_ee();
                      this.get_interior_image_form_ee();
                      this.get_label_image_form_ee();
                      this.get_shipping_image_form_ee();
                  
                  
                  
                  
                  
                  
                  
                     
                          
                  
                  
                          this.transactionService.loadtransacmastercomp_ee(this.selected_submitter.proposalid).subscribe(transcomp=>{
                            console.log(transcomp);
                  
                  
                            this.display_proposal_componenets_ee=[];


                            let temp_transac;
                            temp_transac=JSON.parse(JSON.stringify(transcomp))
                  
                                    console.log(temp_transac);
                                    if(temp_transac!=null &&temp_transac.length!=0)
                                    {
                                      this.display_proposal_componenets_ee=[];
                                      let ownershp='';
                                      for(let j=0;j<temp_transac.length;j++)
                                      {
                                          console.log(temp_transac[j].comp_ownershp);
                                       if(temp_transac[j].comp_ownershp.toLowerCase()=='ford')
                                       {
                                         ownershp='F';
                                       }
                                       else if(temp_transac[j].comp_ownershp.toLowerCase()=='supplier')
                                       {
                                        ownershp='S';
                                       }
                                       else if(temp_transac[j].comp_ownershp.toLowerCase()=='third party')
                                       {
                                        ownershp='T';

                                       }


                                       let total_qty=0;
                                       let total_wt=0;


                                       if(temp_transac[j].compselctnfor.toLowerCase()=='container')
                                       {



                                        let total_container_pershp=0;
                                        total_container_pershp=this.display_proposal_values_ee.cntr_per_layer*(this.display_proposal_values_ee.max_layrs_shp_unt-this.display_proposal_values_ee.empty_trays_cntr_layrs)
                                        total_qty=total_container_pershp*temp_transac[j].qt_per_min
                                        total_wt=total_qty*temp_transac[j].comp_tar_wt
                                        total_wt=this.transactionService.roundValueToNDecimal(total_wt,3);
                  
                  
                  
                                       }


                                       else if(temp_transac[j].compselctnfor.toLowerCase()=='shipping unit')
                                       {
                  
                  
                                        total_qty=temp_transac[j].qt_per_min
                                        total_wt=total_qty*temp_transac[j].comp_tar_wt
                                        total_wt=this.transactionService.roundValueToNDecimal(total_wt,3);
                                        console.log(temp_transac[j].comp_tar_wt);
                                        console.log(total_wt);
                  
                  
                                        
                  
                                       }


                                       let compDisplayInGrid_ee='';
                                       compDisplayInGrid_ee=temp_transac[j].comp_base
                                       if(temp_transac[j].comp_suffix != null && temp_transac[j].comp_suffix.trim() != '')
                                       {
                                        compDisplayInGrid_ee+='-'+temp_transac[j].comp_suffix;
                                       }
                                       else
                                       {
                                        compDisplayInGrid_ee+='-'+' ';
                                       }
                                       if(temp_transac[j].comp_desc != null && temp_transac[j].comp_desc.trim() != '')
                                       {
                                        compDisplayInGrid_ee+='-'+temp_transac[j].comp_desc;
                                       }
                                       else
                                       {
                                        compDisplayInGrid_ee+='-'+' ';
                                       }
                                       
                                       if(temp_transac[j].typ_no != null)
                                        {
                                        const typIndex=this.componentTypeList.findIndex(ele=>ele.typ_no == temp_transac[j].typ_no)
                                        if(typIndex != -1)
                                        {
                                          compDisplayInGrid_ee+='-'+' ('+this.componentTypeList[typIndex].cntr_typ_and_desc+')';
                                        }
                                        else
                                        {
                                          compDisplayInGrid_ee+='-'+' ('+temp_transac[j].comp_code+')';
                                        }
                                        }
                                        else
                                        {
                                          compDisplayInGrid_ee+='-'+' ('+temp_transac[j].comp_code+')';
                                        }

                                        
                                        this.display_proposal_componenets_ee.push(
                                          {
                                           id_proposal:this.selected_submitter.proposalid,
                                           id_comp:temp_transac[j].id_comp,
                                           comp_base:temp_transac[j].comp_base,
                                           comp_suffix:temp_transac[j].comp_suffix,
                                           comp_desc:temp_transac[j].comp_desc,
                                           comp_l:temp_transac[j].comp_l,
                                           comp_w:temp_transac[j].comp_w,
                                           comp_h:temp_transac[j].comp_h,
                                           comp_type:temp_transac[j].comp_type,
                                           qt_per_min:total_qty,
                                           updated_by:temp_transac[j].upated_by,
                                           updated_dt:temp_transac[j].updated_dt,
                                           comp_tar_wt:temp_transac[j].comp_tar_wt,
                                           nestedfoldedht:temp_transac[j].nestedfoldedht,
                                           comp_code:temp_transac[j].comp_code,
                                           comp_ownershp:temp_transac[j].comp_ownershp,
                                           comp_material:temp_transac[j].comp_material,
                                           tooledcomp:temp_transac[j].tooledcomp,
                                           is_compulsary:temp_transac[j].is_compulsary,
                                           comp_Desc_display:compDisplayInGrid_ee,
                                           comp_ownrshp_display:ownershp,
                                           total_wt_display:total_wt,
                                            comp_cnt_no:j+1,
                                            compselctnfor:temp_transac[j].compselctnfor,
                                            material_flag:temp_transac[j].material_flag,
                                            typ_no:temp_transac[j].typ_no
                                           });
                  
                                      }
                  
                  
                  
                                      if(this.display_proposal_componenets_ee.length<12)
                                      {
                                        const existcnt=this.display_proposal_componenets_ee.length;
                                        const empt_cnt=12-existcnt;
                                        for(let jk=0;jk<empt_cnt;jk++)
                                        {
                  
                         
                                          this.display_proposal_componenets_ee.push(
                  
                                            {
                                              id_proposal:0,
                                           id_comp:0,
                                           comp_base:null,
                                           comp_suffix:null,
                                           comp_desc:null,
                                           comp_l:null,
                                           comp_w:null,
                                           comp_h:null,
                                           comp_type:null,
                                           qt_per_min:null,
                                           updated_by:null,
                                           updated_dt:null,
                                           comp_tar_wt:null,
                                           nestedfoldedht:null,
                                           comp_code:null,
                                           comp_ownershp:null,
                                           comp_material:null,
                                           tooledcomp:null,
                                           is_compulsary:null,
                                           comp_Desc_display:'',
                                           comp_ownrshp_display:'',
                                           total_wt_display:null,
                                            comp_cnt_no:(existcnt+jk+1),
                                            compselctnfor:'',
                                            material_flag:null,
                                            typ_no:0
                                            }
                  
                                          )
                                          
                                        }
                                      }
                  
                                    }
                                    else{
                                      this.display_proposal_componenets_ee=[];

                                      for(let jl=0;jl<12;jl++)
                                        {
                  
                         
                                          this.display_proposal_componenets_ee.push(
                  
                                            {
                                              id_proposal:0,
                                           id_comp:0,
                                           comp_base:null,
                                           comp_suffix:null,
                                           comp_desc:null,
                                           comp_l:null,
                                           comp_w:null,
                                           comp_h:null,
                                           comp_type:null,
                                           qt_per_min:null,
                                           updated_by:null,
                                           updated_dt:null,
                                           comp_tar_wt:null,
                                           nestedfoldedht:null,
                                           comp_code:null,
                                           comp_ownershp:null,
                                           comp_material:null,
                                           tooledcomp:null,
                                           is_compulsary:null,
                                           comp_Desc_display:'',
                                           comp_ownrshp_display:'',
                                           total_wt_display:null,
                                            comp_cnt_no:(jl+1),
                                            compselctnfor:'',
                                            material_flag:null,
                                            typ_no:0
                                            }
                  
                                          )
                                          
                                        }
                                    }
                  
                  
                  
                  
                  
                               
                                  
                  
                  
                          })
                  
                  
                  
                      
                  
                    })
                  }

                  else
                  {
                    this.view_ee_proposal=false;
                  }
                


        })



    })

  })
}


  }
   float2int (value) {
    return value | 0;
}


stripHtml(html){
    // Create a new div element
    const temporalDivElement = document.createElement('div');
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || '';
}




      

display_allvalues_init()
{

  this.display_proposal_values={

    gppuserid:'',
        id_proposal:0,
        corr_protc:'',
        lst_saved_tab:null,
        seasnlreq:'',
        shelf_life:null,
        sp_part_no:'',
        submit_status:'',
        temp_sensitivity:'',
        temp_stor_spec:'',
        time_stamp:'',
        plantgsdbcode:'',
        isstampblank:'',


        p_part_len:null,
        p_part_wdt:null,
        p_part_ht:null,
        p_part_wt:null,
        p_part_wt_stat:'',


        cntr_ret_type:'',
      used_carry_ovr_pck:'',
      cntr_base:'',
      cntr_suff:'',
      cntr_desc:'',
      parts_per_cntr:null,
      cntr_ext_len:null,
      cntr_width:null,
      cntr_height:null,
      cntr_nsted_fld_height:null,
      cntr_material:'',
      cntr_tar_weight:null,
      cntr_per_layer:null,
      max_layrs_shp_unt:null,
      empty_trays_cntr_layrs:null,
      max_cntr_per_shp_unt:null,
      cntr_rck_colr:'',
      shp_unt_base:'',
      shp_unt_suff:'',
      shp_unt_desc:'',
      part_per_shp_unit:null,
      shp_unt_ret_type:'',
      shp_unit_ext_len:null,
      shp_unt_ext_width:null,
      shp_unt_ext_height:null,
      shp_unt_nestd_fld_height:null,
      card_call:'',
      cntr_grp_id:'',
      cntr_ownrshp_strat_code:'',
      cd_cntr:null,
      cd_ownrshp_strat:null,

      bct:0,
      ect:0,

      cos_name:'',


      cntr_bas_suff:'',
  shp_bas_suff_desc:'',


  shp_unt_ret_type_fullform:'',


      

       pkgsignoff:'',
       subpresloaddoc:'',
       subpresloaddoc_present:'',
       pkgsignoff_present:'',


       cntrOwner:'',


       chngdbase:'',


         cntr_shp_unit:'',
          shp_unt_tar_wt:null,
          shp_unt_gross_wt:null,
          cntr_gross_wt:null,
          ld_unload_inst:'',
          cntr_automtn_intf:'',
          transp_tested:'',
          tst_reprt_num:'',
          mot:'',
          dangrs_good:'',
          dangrs_good_ship_detls:'',
          rel_level:'',


          f_extcntrdsgnphoto:'',
        f_intcntrdsgnphoto:'',
        f_metelidplatephoto:'',
        f_partphoto:'',
        f_shpunitphoto:'',
        gen_propslcommnts:'',
        int_cntrdesgncommnts:'',
        createmgcyexpndpckgprop:'',
        agrmntexpndpackreqmnts:'',


     
    
    



    proposal_status_curr:'',
    proposal_status_prev:'',
    apprvr_cdsid:'',
    apprvr_asgn_date:'',
    revision_hstry:'',
    apprvr_email_sent:'',
    manualenteredconainerbase:' ',
    pallettype:'',
    woodispmcmplnt:' ',
    offstmpmartprsnt:' ',
    supgsdbcode:'',
    supfullname:'',
    supaddress1:'',
    supcity:'',
    supstateprov:'',
    supcountrycode:'',
    supcontactnamefirst:'',
    supcontactname:'',
    supcontactfunctn:' ',
    supcontactemail:' ',
    supcontactphone:' ',
    supcontactfax:' ',


    supp_city_prov_country:'',



    submitdate:'',
    iscortonimcspec:' ',
    cortonburststrnth:null,
    imcwtdisc:' ',
    blanksperstack:null,
    stacksperpallet:null,
    palletpintyp:'',


    isTstPckReqstd: '',
    isTstPckRecvd: '',
    isTstPckEvaltd: '',


    pressline: '',
    stmpdstackmthd: '',
    stmpwarehseloc: '',
    defaultcurr: '',
    cmmsuploadstat: '',
    hotstampcntrid: '',
    hotstampcntrcolor: '',
    hotstamppalletboxcolor: '',
    hotstamppalletbaselidcolor: '',
    hotstamidplatecolor: '',
    hotstampcolor: '',
    hotstampfontcolor: '',
    hotstampcomments: '',


    offstprtaprvd:'',
  is_bailmnt_prt_prsnt:'',


  propsl_type:'',
  effectdate:''


    







  }

}


     

display_allvalues_init_ee()
{

  this.display_proposal_values_ee={

    gppuserid:'',
        id_proposal:0,
        corr_protc:'',
        lst_saved_tab:null,
        seasnlreq:'',
        shelf_life:null,
        sp_part_no:'',
        submit_status:'',
        temp_sensitivity:'',
        temp_stor_spec:'',
        time_stamp:'',
        plantgsdbcode:'',
        isstampblank:'',


        p_part_len:null,
        p_part_wdt:null,
        p_part_ht:null,
        p_part_wt:null,
        p_part_wt_stat:'',


        cntr_ret_type:'',
      used_carry_ovr_pck:'',
      cntr_base:'',
      cntr_suff:'',
      cntr_desc:'',
      parts_per_cntr:null,
      cntr_ext_len:null,
      cntr_width:null,
      cntr_height:null,
      cntr_nsted_fld_height:null,
      cntr_material:'',
      cntr_tar_weight:null,
      cntr_per_layer:null,
      max_layrs_shp_unt:null,
      empty_trays_cntr_layrs:null,
      max_cntr_per_shp_unt:null,
      cntr_rck_colr:'',
      shp_unt_base:'',
      shp_unt_suff:'',
      shp_unt_desc:'',
      part_per_shp_unit:null,
      shp_unt_ret_type:'',
      shp_unit_ext_len:null,
      shp_unt_ext_width:null,
      shp_unt_ext_height:null,
      shp_unt_nestd_fld_height:null,
      card_call:'',
      cntr_grp_id:'',
      cntr_ownrshp_strat_code:'',
      cd_cntr:null,
      cd_ownrshp_strat:null,


      cos_name:'',


      cntr_bas_suff:'',
  shp_bas_suff_desc:'',


  shp_unt_ret_type_fullform:'',




       pkgsignoff:'',
       subpresloaddoc:'',
       subpresloaddoc_present:'',
       pkgsignoff_present:'',


       cntrOwner:'',
       bct:0,
       ect:0,


       chngdbase:'',


         cntr_shp_unit:'',
          shp_unt_tar_wt:null,
          shp_unt_gross_wt:null,
          cntr_gross_wt:null,
          ld_unload_inst:'',
          cntr_automtn_intf:'',
          transp_tested:'',
          tst_reprt_num:'',
          mot:'',
          dangrs_good:'',
          dangrs_good_ship_detls:'',
          rel_level:'',


          f_extcntrdsgnphoto:'',
        f_intcntrdsgnphoto:'',
        f_metelidplatephoto:'',
        f_partphoto:'',
        f_shpunitphoto:'',
        gen_propslcommnts:'',
        int_cntrdesgncommnts:'',
        createmgcyexpndpckgprop:'',
        agrmntexpndpackreqmnts:'',








    proposal_status_curr:'',
    proposal_status_prev:'',
    apprvr_cdsid:'',
    apprvr_asgn_date:'',
    revision_hstry:'',
    apprvr_email_sent:'',
    manualenteredconainerbase:' ',
    pallettype:'',
    woodispmcmplnt:' ',
    offstmpmartprsnt:' ',
    supgsdbcode:'',
    supfullname:'',
    supaddress1:'',
    supcity:'',
    supstateprov:'',
    supcountrycode:'',
    supcontactnamefirst:'',
    supcontactname:'',
    supcontactfunctn:' ',
    supcontactemail:' ',
    supcontactphone:' ',
    supcontactfax:' ',


    supp_city_prov_country:'',



    submitdate:'',
    iscortonimcspec:' ',
    cortonburststrnth:null,
    imcwtdisc:' ',
    blanksperstack:null,
    stacksperpallet:null,
    palletpintyp:'',


    isTstPckReqstd: '',
    isTstPckRecvd: '',
    isTstPckEvaltd: '',


    pressline: '',
    stmpdstackmthd: '',
    stmpwarehseloc: '',
    defaultcurr: '',
    cmmsuploadstat: '',
    hotstampcntrid: '',
    hotstampcntrcolor: '',
    hotstamppalletboxcolor: '',
    hotstamppalletbaselidcolor: '',
    hotstamidplatecolor: '',
    hotstampcolor: '',
    hotstampfontcolor: '',
    hotstampcomments: '',


    offstprtaprvd:'',
    is_bailmnt_prt_prsnt:'',


    propsl_type:'',
    effectdate:''










  }

}




get_part_image_form_ee()
{
  if(this.display_proposal_values_ee.f_partphoto!='' && this.display_proposal_values_ee.f_partphoto!=null)
  {
  let url =this.base_url_getimage+this.display_proposal_values_ee.f_partphoto;
  this.transactionService.getImageFile(url).subscribe((response: any) => {
    this.part_image_form_ee = URL.createObjectURL(response);
    let url = URL.createObjectURL(response);
    for(var i of this.numbers)
    {
      const img: any = document.querySelector('#part_image_form_ee'+i);
      img.src = url;
    }
    if(this.part_popup_ee)
    {
      const img1: any = document.querySelector('#part_image_form_ee_popup');
      img1.src = url;
    }
   
  });
  }
  else{
    this.part_image_form_ee='';
  }
}

get_interior_image_form_ee()
{


  if(this.display_proposal_values_ee.f_intcntrdsgnphoto!='' && this.display_proposal_values_ee.f_intcntrdsgnphoto!=null)
  {
    let url =this.base_url_getimage+this.display_proposal_values_ee.f_intcntrdsgnphoto;
    this.transactionService.getImageFile(url).subscribe((response: any) => {
      this.interior_image_form_ee = URL.createObjectURL(response);;
      const imageUrl = URL.createObjectURL(response);
      for(var i of this.numbers)
      {
        let imgTag: any = document.querySelector('#interior_image_form_ee'+i);
        imgTag.src = imageUrl;
      }
      if(this.interior_popup_ee)
      {
        let imgTag1: any = document.querySelector('#interior_image_form_ee_popup');
        imgTag1.src = imageUrl;
      }
     
  });
  }
  else{
    this.interior_image_form_ee='';
  }

}
get_exterior_image_form_ee()
{


  if(this.display_proposal_values_ee.f_extcntrdsgnphoto!='' && this.display_proposal_values_ee.f_extcntrdsgnphoto!=null)
  {
  let url =this.base_url_getimage+this.display_proposal_values_ee.f_extcntrdsgnphoto;
  this.transactionService.getImageFile(url).subscribe((response: any) => {
    this.exterior_image_form_ee = URL.createObjectURL(response);
    let url = URL.createObjectURL(response);
    for(var i of this.numbers)
    {
      const img: any = document.querySelector('#exterior_image_form_ee'+i);
      img.src = url;
    }
    if(this.exterior_popup_ee)
    {
      const img2: any = document.querySelector('#exterior_image_form_ee_popup');
      img2.src = url;
    }
  
  });
  }
  else{
    this.exterior_image_form_ee='';
  }

}
get_label_image_form_ee()
{


  if(this.display_proposal_values_ee.f_metelidplatephoto!='' && this.display_proposal_values_ee.f_metelidplatephoto!=null)
  {
  let url =this.base_url_getimage+this.display_proposal_values_ee.f_metelidplatephoto;
  this.transactionService.getImageFile(url).subscribe((response: any) => {
    this.label_image_form_ee = URL.createObjectURL(response);
    let url = URL.createObjectURL(response);
    for(var i of this.numbers)
    {
      const img: any = document.querySelector('#label_image_form_ee'+i);
      img.src = url;
    }
    if(this.label_popup_ee)
    {
      const img1: any = document.querySelector('#label_image_form_ee_popup');
      img1.src = url;
    }
  
  });
  }
  else{
    this.label_image_form_ee='';
  }

}
get_shipping_image_form_ee()
{


  if(this.display_proposal_values_ee.f_shpunitphoto!='' && this.display_proposal_values_ee.f_shpunitphoto!=null)
  {
  let url =this.base_url_getimage+this.display_proposal_values_ee.f_shpunitphoto;
  this.transactionService.getImageFile(url).subscribe((response: any) => {
    this.shipping_image_form_ee = URL.createObjectURL(response);
    let url = URL.createObjectURL(response);
    for(var i of this.numbers)
    {
      const img: any = document.querySelector('#shipping_image_form_ee'+i);
      img.src = url;
    }
    if(this.shpunit_popup_ee)
    {
      const img1: any = document.querySelector('#shipping_image_form_ee_popup');
      img1.src = url;
    }

  });
  }
  else{
    this.shipping_image_form_ee='';
  }

}




get_part_image_form()
{
  if(this.display_proposal_values.f_partphoto!='' && this.display_proposal_values.f_partphoto!=null)
  {
   // alert(JSON.stringify(this.numbers))
  let url =this.base_url_getimage+this.display_proposal_values.f_partphoto;
  this.transactionService.getImageFile(url).subscribe((response: any) => {
    this.part_image_form = URL.createObjectURL(response);
    let url = URL.createObjectURL(response);

    for(var i of this.numbers)
    {
      const img:any=document.querySelector('#part_image_form'+i);
      img.src=url;
    }
   /*  const img: any = document.querySelector('#part_image_form');
      img.src = url;   */  

    if(this.part_popup)
    {
      const img1: any = document.querySelector('#part_image_form_popup');
      img1.src = url; 
    }
  
  });
  }
  else{
    this.part_image_form='';
  }
}

get_interior_image_form()
{


  if(this.display_proposal_values.f_intcntrdsgnphoto!='' && this.display_proposal_values.f_intcntrdsgnphoto!=null)
  {
  let url =this.base_url_getimage+this.display_proposal_values.f_intcntrdsgnphoto;
  this.transactionService.getImageFile(url).subscribe((response: any) => {
    this.interior_image_form = URL.createObjectURL(response);
    let url = URL.createObjectURL(response);

    for(var i of this.numbers)
    {
    const img: any = document.querySelector('#interior_image_form'+i);
    img.src = url;
    }

    if(this.interior_popup)
    {
      const img2: any = document.querySelector('#interior_image_form_popup');
      img2.src = url;
    }
    
  });
  }
  else{
    this.interior_image_form='';
  }

}
get_exterior_image_form()
{


  if(this.display_proposal_values.f_extcntrdsgnphoto!='' && this.display_proposal_values.f_extcntrdsgnphoto!=null)
  {
  let url =this.base_url_getimage+this.display_proposal_values.f_extcntrdsgnphoto;
  this.transactionService.getImageFile(url).subscribe((response: any) => {
    this.exterior_image_form = URL.createObjectURL(response);
    let url = URL.createObjectURL(response);
    for(var i of this.numbers)
    {
    const img: any = document.querySelector('#exterior_image_form'+i);
    img.src = url;  
    } 

    if(this.exterior_popup)
    {
      const img1: any = document.querySelector('#exterior_image_form_popup');
      img1.src = url; 
    }
    
  });
  }
  else{
    this.exterior_image_form='';
  }

}
get_label_image_form()
{


  if(this.display_proposal_values.f_metelidplatephoto!='' && this.display_proposal_values.f_metelidplatephoto!=null)
  {
  let url =this.base_url_getimage+this.display_proposal_values.f_metelidplatephoto;
  this.transactionService.getImageFile(url).subscribe((response: any) => {
    this.label_image_form = URL.createObjectURL(response);
    let url = URL.createObjectURL(response);
    for(var i of this.numbers)
    {
    const img: any = document.querySelector('#label_image_form'+i);
    img.src = url;
    }

    if(this.label_popup)
    {
      const img1: any = document.querySelector('#label_image_form_popup');
      img1.src = url;
    }
 
  });
  }
  else{
    this.label_image_form='';
  }

}
get_shipping_image_form()
{


  if(this.display_proposal_values.f_shpunitphoto!='' && this.display_proposal_values.f_shpunitphoto!=null)
  {
  let url =this.base_url_getimage+this.display_proposal_values.f_shpunitphoto;
  this.transactionService.getImageFile(url).subscribe((response: any) => {
    this.shipping_image_form = URL.createObjectURL(response);
    let url = URL.createObjectURL(response);
    for(var i of this.numbers)
    {
    const img: any = document.querySelector('#shipping_image_form'+i);
    img.src = url;
    }

    if(this.shpunit_popup)
    {
      const img1: any = document.querySelector('#shipping_image_form_popup');
      img1.src = url;
    }
   
    
  });
  }
  else{
    this.shipping_image_form='';
  }

}

onclick_part()
{
  this.part_popup=true;
  this.get_part_image_form()
  console.log(this.part_image_form);
}


onclick_interiorphoto()
{
  this.interior_popup=true;
  this.get_interior_image_form();
  console.log(this.interior_image_form);
}


onclick_exteriorphoto()
{
  this.exterior_popup=true;
  this.get_exterior_image_form();
  console.log(this.exterior_image_form);
}

onclick_labelphoto()
{
  this.label_popup=true;
  this.get_label_image_form()
  console.log(this.label_image_form);
}

onclick_shpunitphoto()
{
  this.shpunit_popup=true;
  this.get_shipping_image_form();
  console.log(this.shipping_image_form);
}



onclick_part_ee()
{
  this.part_popup_ee=true;
  this.get_part_image_form_ee();
  console.log(this.part_image_form_ee);
}


onclick_interiorphoto_ee()
{
  this.interior_popup_ee=true;
  this.get_interior_image_form_ee();
  console.log(this.interior_image_form_ee);
}


onclick_exteriorphoto_ee()
{
  this.exterior_popup_ee=true;
  this.get_exterior_image_form_ee();
  console.log(this.exterior_image_form_ee);
}

onclick_labelphoto_ee()
{
  this.label_popup_ee=true;
  this.get_label_image_form_ee();
  console.log(this.label_image_form_ee);
}

onclick_shpunitphoto_ee()
{
  this.shpunit_popup_ee=true;
  this.get_shipping_image_form_ee();
  console.log(this.shipping_image_form_ee);
}
  

   onExportClick()
   {

    this.errorFormText=""
    this.loaderForm=true;
    this.successMessage="Zip file is downloading, Please wait it will take few minutes"
  
  const content=document.querySelector('#pdfTable1');

  var pdfName="";
  pdfName=String(this.selected_submitter.proposalid)+'.pdf'
  const options={
    //pdfCallback: myCallback,
     margin: [15, 13, -10, 13], //top, left, buttom, right
     filename:pdfName,
     image:{ type: 'jpg', quality: 0.98 },
     html2canvas: {
      dpi: 300,
      scale: 3,
      letterRendering: true,
      useCORS: true,
      },
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
     jsPDF:{unit: 'mm', format: [270,330],orientation:'landscape'},
     
   };

/* Commenting pdf file changes */
          //  html2pdf()
          //  .from(content)
          //  .set(options)
          //  .save().then(result => {
          //    this.loaderForm=false;
          //    this.successMessage="Downloaded PDF Successfully"
          //   //console.log('Success:', result);
          // })
  /*.catch(error => {
    this.errorFormText="Error Occured While Downloading Form 1271"
    this.loaderForm=false;
    console.error('Error Form 1271 download pdf:', error);
  }
  )*/
 
  //Compress pdf file into zip file
  let zip = new JSZip();
  zip.file(
    pdfName,
    html2pdf()
      .from(content)
      .set(options)
      .output('blob')
  );
  zip
    .generateAsync({
      type: "blob",
      compression: "DEFLATE",
      compressionOptions: { level: 9 }
    })
    .then(res => 
      {
        saveAs(res, String(this.selected_submitter.proposalid)+".zip");
        this.loaderForm=false;
        this.successMessage="Downloaded Zip File Successfully";
      });
  }
  
}


