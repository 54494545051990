/* tslint:disable */
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TransactionService } from '../loginpage/Transaction.service';
import { useraccessrole, subpltlist, subprglist, subcoslist, ActivePlants, containerOwnershipList, programsbasedonplants, subtradco, substatuslist, selec_prt_pro_details, subgrid, subnewprt_latesbom, subnewpart_latestbom_designsource, supp_country } from '../loginpage/transactions';

@Component({
  selector: 'app-migrationsubmitterdashboard',
  templateUrl: './migrationsubmitterdashboard.component.html',
  styleUrls: ['./migrationsubmitterdashboard.component.css']
})
export class MigrationsubmitterdashboardComponent implements OnInit {

  disableProgDrpMig=true;
  currentRegionSubMig=' ';
  currentRegionCodeSubMig:string;
  subUserAccessMig:useraccessrole[]=[];
  plantCodeListMig:subpltlist[]=[];
  selectedPlantListMig:subpltlist[]=[];


  progListMig:subprglist[]=[];
  selectedProgListMig:subprglist[]=[];

  cosListMig:subcoslist[]=[];
  selectedCosListMig:subcoslist[]=[];

/*
  statusList:substatuslist[]=[];
  selectedstatuslist:substatuslist[]=[];

  trdcmpyList:subtrdcmpylist[]=[];
  selectedtrdcmpylist:subtrdcmpylist[]=[];
  */

 selectedPartPrefixMig='';
 selectedPartBaseMig='';
 selectedPartSuffixMig='';

  partPrefixMig='';
  partBaseMig='';
  partSuffixMig='';



  plantcodeMig='';
  programcodeMig='';
  cos='';

  statusMig='';
  trdcmpyMig='';
  noSupDialogMig=false;
  contentMig='';

  subPrgUserAccess:useraccessrole[]=[];



  subAllPlantsMig:ActivePlants[]=[];
  subCosListMig:containerOwnershipList[]=[];
  progListOnPltMig:programsbasedonplants[]=[];



  tradCmpyListMig:subtradco[]=[];
  selectedTrdcmpyMig:subtradco[]=[]

  statusListMig:substatuslist[]=[];
  selectedStatusListMig:substatuslist[]=[];
  selectedSimpliedPartnoDetailsMig:selec_prt_pro_details={simplifiedpartno:'-',bompartmasterid:0,proposalid:0,plantgsdbcode:'',proposallastsaved:0,part_status:'',supplier_code:'',proposallastsaved_ee:0,is_ee_proposal:false,currentview_ee:false,from_draft:false,propsl_type:'',imp_part_status:''};


  submittergridListMig:subgrid[]=[];


  gridTextMig='';
  presentTableMig=false;
  subCurrentRoleidMig:number;
  displaylinkProposalCreateMig=false;
  displaylinkProposalNonSubmitMig=false;
  subUserRolesMig:any;


  displaySupplierMig=false;


  noRoleSelectedDialogMig=false;
  noRoleContentMig=''


  newprtDetailsOnsuppcodMig:subnewprt_latesbom={supCode:'',latestBOMDt:new Date,latestBOMDt_string:'',newPartCnt:0};
  newprtDetailsOnDesignsourceCodeMig:subnewpart_latestbom_designsource={ prtAwtngPropSubmn: 0,latestBOMDt: '',dsgnSrcId:''}


  gridLengthMig=0;


  loadersubMig=false



public cols: any[];

  subCdsidMig:string;

  @Input() suppliercodeMig:string;

  suppCodeMig:string;

  designsourceCodeMig:string;
  selectedSuppliergsdbcodeDesignsourceMig='';
  suppliergsdbcodeDesignsourceMig='';
  supplierCountryListMig:supp_country[]=[];
  selectedSupplierCountryMig:supp_country[]=[];
  supplierCountryMig=''
  noDesignsourceDialogMig=false;
  contentDesignsourceMig=''
  is_container_expendable_mig: boolean;

  constructor(private transactionService: TransactionService,private router:Router,private date:DatePipe,private route: ActivatedRoute) { }



  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.suppliercodeMig)
    this.ngOnInit();
    console.log('value changed');
  }

  ngOnInit(): void {


    this.subCdsidMig=this.transactionService.getcdsid();
    this.currentRegionSubMig=this.transactionService.getregion();

    this.subCurrentRoleidMig=this.transactionService.getcurrentrole(this.currentRegionSubMig)


    if(this.subCurrentRoleidMig==null)
    {


      this.noRoleSelectedDialogMig=true;
      this.noRoleContentMig='No role selected '
    }
    // get role
    this.currentRegionCodeSubMig=this.transactionService.getregioncode(this.currentRegionSubMig);
    if(this.subCurrentRoleidMig==1)
    {
      this.displaySupplierMig=false;
    this.transactionService.storepage(this.currentRegionSubMig,'packagingprocess/packsupplieradmin/submiterdashboard');
    this.suppCodeMig=this.transactionService.get_supplier(this.currentRegionSubMig);
    console.log(this.suppCodeMig);
    }

    else if(this.subCurrentRoleidMig==2)
    {

      this.designsourceCodeMig=this.transactionService.get_designsource(this.currentRegionSubMig);
      this.displaySupplierMig=true;
      this.transactionService.storepage(this.currentRegionSubMig,'packagingprocess/packdesignsourceadmin/submiterdashboard');
      console.log(this.designsourceCodeMig);
    }


    this.subUserRolesMig=this.transactionService.getroles(this.currentRegionCodeSubMig)


console.log(this.subUserRolesMig)
    this.displaylinkProposalCreateMig=false;
    this.displaylinkProposalNonSubmitMig=false;
    if(this.subUserRolesMig!=null && this.subUserRolesMig.length!=0)
    {
    for(let ni=0; ni<this.subUserRolesMig.length; ni++)
    {
      if(this.subUserRolesMig[ni].roleid==this.subCurrentRoleidMig)
      {
        console.log(this.subUserRolesMig[ni].roles)
        let user_subroles_func=[];
        user_subroles_func=this.subUserRolesMig[ni].roles

        for(let nj=0;nj<user_subroles_func.length;nj++)
        {
          if(user_subroles_func[nj].sysfunc_id==6)
          {
             this.displaylinkProposalCreateMig=true
             this.displaylinkProposalNonSubmitMig=true;
            break;
          }
        }

        break;
      }
    }
  }


console.log(this.displaylinkProposalCreateMig)
    console.log(this.submittergridListMig)

  /*  this.subUserAccess=[
      {
          "cd_region": "NA",
          "gppuserid": "pmonish1",
          "role_id": 1,
          "role_name": "Supplier",
          "plantgsdbcode": "AP06A",
          "cd_supplier": "0134A",
          "createdby": "kbalarev",
          "createddt": "2022-04-04T07:32:47.000+00:00",
          "plant_name": "plant name",
          "ownershp_strat_code": "FOS,FOU",
          "program_name": "prg,prg1"
      },
      {
          "cd_region": "NA",
          "gppuserid": "pmonish1",
          "role_id": 1,
          "role_name": "Supplier",
          "plantgsdbcode": "AP03A",
          "cd_supplier": "0134A",
          "createdby": "kbalarev",
          "createddt": "2022-04-04T07:32:47.000+00:00",
          "plant_name": "plant name",
          "ownershp_strat_code": "SPS,SPU",
          "program_name": "prg2"
      },
      {
        "cd_region": "NA",
        "gppuserid": "pmonish1",
        "role_id": 1,
        "role_name": "Supplier",
        "plantgsdbcode": "AP07A",
        "cd_supplier": "0134A",
        "createdby": "kbalarev",
        "createddt": "2022-04-04T07:32:47.000+00:00",
        "plant_name": "plant name",
        "ownershp_strat_code": "FOS1,FOU1",
        "program_name": "prg12,prg13"
    },
  ]


  this.subPrgUserAccess=[
    {
        "cd_region": "NA",
        "gppuserid": "pmonish1",
        "role_id": 1,
        "role_name": "Supplier",
        "plantgsdbcode": "AP06A",
        "cd_supplier": "0134A",
        "createdby": "kbalarev",
        "createddt": "2022-04-04T07:32:47.000+00:00",
        "plant_name": "plant name",
        "ownershp_strat_code": "FOS,FOU",
        "program_name": "prg,prg1"
    },
    {
        "cd_region": "NA",
        "gppuserid": "pmonish1",
        "role_id": 1,
        "role_name": "Supplier",
        "plantgsdbcode": "AP03A",
        "cd_supplier": "0134A",
        "createdby": "kbalarev",
        "createddt": "2022-04-04T07:32:47.000+00:00",
        "plant_name": "plant name",
        "ownershp_strat_code": "SPS,SPU",
        "program_name": "prg2"
    },
    {
      "cd_region": "NA",
      "gppuserid": "pmonish1",
      "role_id": 1,
      "role_name": "Supplier",
      "plantgsdbcode": "AP07A",
      "cd_supplier": "0134A",
      "createdby": "kbalarev",
      "createddt": "2022-04-04T07:32:47.000+00:00",
      "plant_name": "plant name",
      "ownershp_strat_code": "FOS1,FOU1",
      "program_name": "prg12,prg13"
  },
]*/


if((this.subCurrentRoleidMig==1 && this.suppCodeMig!=null)||(this.subCurrentRoleidMig==2 && this.designsourceCodeMig!=null))
{

  if(this.subCurrentRoleidMig==1)
  {
  this.transactionService.getprtcnt_latestbom_date(this.suppCodeMig).subscribe(newprt_details=>{
    console.log(newprt_details)
    if(newprt_details != null)
    {
      if(newprt_details.supCode != null && (newprt_details.supCode==this.suppCodeMig))
      {
      this.newprtDetailsOnsuppcodMig=newprt_details;
      if(this.newprtDetailsOnsuppcodMig.latestBOMDt!=null)
      {
        this.newprtDetailsOnsuppcodMig.latestBOMDt_string=this.date.transform(this.newprtDetailsOnsuppcodMig.latestBOMDt, 'dd-MM-yyyy');
      }
      console.log(this.newprtDetailsOnsuppcodMig);
      }
    }

  }
  )
}

  if(this.subCurrentRoleidMig==2)
  {
    // latest bom in iteration 11
    // get supplier  country list
    // enable the values to diplay has true (ie supllier and supplier country)


    this.transactionService.getprtcnt_latestbom_date_designsource(this.designsourceCodeMig).subscribe(newprt_details_desg=>{
      console.log(newprt_details_desg)
      if(newprt_details_desg != null)
      {
        if(newprt_details_desg.dsgnSrcId != null && (newprt_details_desg.dsgnSrcId==this.designsourceCodeMig))
        {
        this.newprtDetailsOnDesignsourceCodeMig=newprt_details_desg;
        if(this.newprtDetailsOnDesignsourceCodeMig.latestBOMDt!=null)
        {
          this.newprtDetailsOnDesignsourceCodeMig.latestBOMDt=this.date.transform(this.newprtDetailsOnDesignsourceCodeMig.latestBOMDt, 'dd-MM-yyyy');
        }
        console.log(this.newprtDetailsOnDesignsourceCodeMig);
        }
      }

    }
    )


    this.transactionService.getsupplier_country_list().subscribe(supp_country_list=>{
      console.log(supp_country_list)
      this.supplierCountryListMig=supp_country_list;
      if(this.supplierCountryListMig!=null && this.supplierCountryListMig.length!=0)
      {
        for(let s=0; s<this.supplierCountryListMig.length; s++)
        {
          if(this.supplierCountryListMig[s].cd_country_iso!=null)
          {
      this.supplierCountryListMig[s].code_name=this.supplierCountryListMig[s].cd_country_iso+'-'+this.supplierCountryListMig[s].country_name
          }
          else
          {
            this.supplierCountryListMig[s].code_name=this.supplierCountryListMig[s].country_name;

          }
        }
      }
    }
    )






  }
  this.transactionService.getpartstatus('Submitter').subscribe(sub_status=>{


    console.log(sub_status);
    this.statusListMig=[];
   // this.statusList=sub_status;
    for(let i=0;i<sub_status.length;i++)
    {
      if(sub_status[i].status!='Submitted')
      {
      this.statusListMig.push(sub_status[i]);
      }
    }
this.selectedStatusListMig=[];




this.transactionService.gettradingcompany().subscribe(sub_tradcmpy=>{


  console.log(sub_tradcmpy);

  this.tradCmpyListMig=[];
  this.tradCmpyListMig.push({tradingcogsdbcode:'[No Trading Company]'})
  for(let trd=0;trd<sub_tradcmpy.length;trd++)
  {
  this.tradCmpyListMig.push(sub_tradcmpy[trd]);
  }

console.log('trad',this.tradCmpyListMig)
  this.selectedTrdcmpyMig=[];





this.transactionService.getOwnershipStrategyCode(this.currentRegionCodeSubMig).subscribe(sub_coslist=>{

  console.log('cos',sub_coslist);
  this.subCosListMig=sub_coslist
  this.cosListMig=[];
  if(this.subCosListMig.length!=0)
  {
  for(let icos=0; icos<this.subCosListMig.length; icos++)
  {
  this.cosListMig.push({cos:this.subCosListMig[icos].ownrshp_strat_code,cos_name:this.subCosListMig[icos].ownrshp_desc});
  }
}


console.log(this.cosListMig)





this.listPlantcodeMig();


  })

})

})


}

else if(this.subCurrentRoleidMig==1)
{

if(this.suppCodeMig==null)
{
  this.noSupDialogMig=true;
  this.contentMig='Please select supplier';
}
}

else if(this.subCurrentRoleidMig==2)
{
  if(this.designsourceCodeMig==null)
  {
    this.noDesignsourceDialogMig=true;
    this.contentDesignsourceMig='Please select Design Source'
  }
}

    // service to get status

    // inside service call the plantcode list function this.list_plantcode()
    // if full load at first time call grid function
  }


  okNoRoleDialogMig()
  {
    let url
    url=this.transactionService.getregionurlvalue(this.currentRegionSubMig);
    url+='packagingprocessdashboard'
    this.router.navigate([url]);

  }



  okNoSupDialogMig()
  {
    console.log(this.currentRegionSubMig)
    let reg_url:string
    reg_url=this.transactionService.getregionurlvalue(this.currentRegionSubMig);
    reg_url+='packagingprocess/packsupplieradmin/packsupplier';

    this.router.navigate([reg_url]);

  }


  okNoDesignsourceDialogMig()
  {
    console.log(this.currentRegionSubMig)
    let reg_url:string
    reg_url=this.transactionService.getregionurlvalue(this.currentRegionSubMig);
    reg_url+='packagingprocess/packdesignsourceadmin/packdesignsource';

    this.router.navigate([reg_url]);

  }


  goToSuppMainPageMig()
  {
    let reg_supp_url:string;
    reg_supp_url=this.transactionService.getregionurlvalue(this.currentRegionSubMig);
    if(this.subCurrentRoleidMig==1)
    {
    reg_supp_url+='packagingprocess/packsupplieradmin/packsupplier';
    }
    else if(this.subCurrentRoleidMig==2){
      reg_supp_url+='packagingprocess/packdesignsourceadmin/packdesignsource';

    }
    this.router.navigate([reg_supp_url]);

  }


  partdataListMig()
  {

  }

  listPlantcodeMig()
  {
    console.log('get plant list')

const sub_allplants=[]
  this.transactionService.getactiveplantslist(this.currentRegionCodeSubMig).subscribe(sub_allplants=>{
      console.log('sub_useracces',sub_allplants);
     let position:number;
       this.subAllPlantsMig=sub_allplants;
       this.plantCodeListMig=[];
       this.progListMig=[];



       // this.plantCodeList.push({plantcode:"all",plant:"[ALL]"});


       if(this.subAllPlantsMig.length!=0)
       {
       for(let temp=0; temp<this.subAllPlantsMig.length; temp++)
       {
         console.log(this.subAllPlantsMig[temp]);
         let plt_name:string;
         plt_name=this.subAllPlantsMig[temp].plantGSDBCode+'-'+this.subAllPlantsMig[temp].plant_name;

          this.plantCodeListMig.push({plantcode:this.subAllPlantsMig[temp].plantGSDBCode,plant:plt_name})

       }
      }


       console.log(this.plantCodeListMig);







     // service to get trading company
     // assign slected trading company full list of trading companies;






 // this.selectedproglist=this.progList;
 // this.selectedcoslist=this.cosList;

 // this.selectedplantlist=this.plantCodeList;
 // this.disableProgDrp=false;


 this.selectedProgListMig=[];
 this.selectedCosListMig=[];

 this.selectedPlantListMig=[];
 this.disableProgDrpMig=true;


 this.applyFilterMig();



    })
    // in future do gor global admin if its used
 /*
   this.transactionService.getUserAccessRole(this.sub_cdsid).subscribe(sub_useraccess=>{
     console.log("sub_useracces",sub_useraccess);
    var position:number;
      this.subUserAccess=sub_useraccess;
      this.plantCodeList=[];
      this.progList=[];
      this.cosList=[];


      //this.plantCodeList.push({plantcode:"all",plant:"[ALL]"});

      for(var temp=0;temp<this.subUserAccess.length;temp++)
      {
        console.log(temp);
        console.log(this.subUserAccess[0].cd_region)
        if(this.subUserAccess[temp].cd_region==this.current_region_code_sub)
        {
        if(this.subUserAccess[temp].role_id==this.sub_current_roleid)
        {
          var plt_name:string;
          plt_name=this.subUserAccess[temp].plantgsdbcode+"-"+this.subUserAccess[temp].plant_name;

           this.plantCodeList.push({plantcode:this.subUserAccess[temp].plantgsdbcode,plant:plt_name})


if(this.subUserAccess[temp].program_name!=null)
{

    var prg_arr=[];
    prg_arr=this.subUserAccess[temp].program_name.split(',');

    console.log(prg_arr)
    for(var prg=0;prg<prg_arr.length;prg++)
    {
      var present_prg=false;
      for(var kprg=0;kprg<this.progList.length;kprg++)
                {
             if(prg_arr[prg]==this.progList[kprg].programcode)
              {
                   present_prg=true;
                                      break;
              }
       }


    if(present_prg==false)
            {
              this.progList.push({programcode:prg_arr[prg]})
                }
    }


    console.log("proglist",this.progList);
  }



  if(this.subUserAccess[temp].ownershp_strat_code!=null)
  {
    var cos_arr=[];
    cos_arr=this.subUserAccess[temp].ownershp_strat_code.split(',');


    for(var cos=0;cos<cos_arr.length;cos++)
    {
      var present_cos=false;
      for(var kcos=0;kcos<this.cosList.length;kcos++)
      {
        if(cos_arr[cos]==this.cosList[kcos].cos)
        {
          present_cos=true;
          break;
        }
      }


      if(present_cos==false)
      {
      this.cosList.push({cos:cos_arr[cos]});
      }
    }


    console.log("coslist",this.cosList);
  }



    //service to get trading company
    //assign slected trading company full list of trading companies;

  }
}


}

//this.selectedproglist=this.progList;
//this.selectedcoslist=this.cosList;

//this.selectedplantlist=this.plantCodeList;
//this.disableProgDrp=false;


this.selectedproglist=[];
this.selectedcoslist=[];

this.selectedplantlist=[];
this.disableProgDrp=true;


this.apply_filter();



    })*/

    // service

   // this.disableProgDrp=true;
  }


  onChangePlantMig()
  {


    console.log(this.selectedPlantListMig);

if(this.selectedPlantListMig.length!=0)
{
  this.disableProgDrpMig=false;


  let plantcodes_selected_commasep='';
  plantcodes_selected_commasep=this.selectedPlantListMig[0].plantcode;



  if(this.selectedPlantListMig.length>1)
  {
  for(let iconv=1; iconv<this.selectedPlantListMig.length; iconv++)
  {

    plantcodes_selected_commasep+=',';
    plantcodes_selected_commasep+=this.selectedPlantListMig[iconv].plantcode;

  }
}


console.log('plantcodes',plantcodes_selected_commasep);

const prog_output=[];
this.transactionService.getprogmlistbasedonplantgsdb(plantcodes_selected_commasep,this.currentRegionCodeSubMig).subscribe(prog_output=>{

  console.log('programs',prog_output);
  this.progListOnPltMig=prog_output
  this.progListMig=[];


 /*/ if(this.progListOnPlt.length!=0)
  {
    console.log(this.progListOnPlt);
    for(var it=0;it<this.progListOnPlt.length;it++)
    {
      this.progList.push({programcode:this.progListOnPlt[it].programname,programdisplay:this.progListOnPlt[it].programname})
    }
  }*/
  if(this.progListOnPltMig.length!=0)
 {

    for(let prg=0; prg<this.progListOnPltMig.length; prg++)
    {


      let present_prg=false;
      let prog_plt:string;

      for(let kprg=0; kprg<this.progListMig.length; kprg++)
      {
             if(this.progListOnPltMig[prg].programname.toLowerCase()==this.progListMig[kprg].programcode.toLowerCase())
              {


                prog_plt=this.progListMig[kprg].programdisplay;
                prog_plt+=',('+this.progListOnPltMig[prg].plantgsdbcode+')';

               this.progListMig[kprg].programdisplay= prog_plt

                   present_prg=true;
                                      break;
              }
       }


   if(present_prg==false)
            {
              prog_plt=this.progListOnPltMig[prg].programname+'('+this.progListOnPltMig[prg].plantgsdbcode+')';

      this.progListMig.push({programcode: this.progListOnPltMig[prg].programname,programdisplay:prog_plt})

                }



    }

  }


  console.log(this.progListMig);


  console.log(this.selectedProgListMig);


  if(this.progListMig.length!=0 && this.selectedProgListMig.length!=0)
{
  const selprgarr=[];
  console.log(this.selectedProgListMig);
  console.log(this.progListMig)
  const prog_plt='';
          for(let iprg=0; iprg<this.progListMig.length; iprg++)
          {
            console.log(this.progListMig[iprg])
          for(let jprg=0; jprg<this.selectedProgListMig.length; jprg++)
          {
            console.log(this.selectedProgListMig[jprg])

            if(this.progListMig[iprg].programcode.toLowerCase()==this.selectedProgListMig[jprg].programcode.toLowerCase())
            {
              this.selectedProgListMig[jprg].programdisplay=this.progListMig[iprg].programdisplay
              selprgarr.push(this.selectedProgListMig[jprg]);
              console.log(selprgarr)
              break;
            }
          }

          }




          this.selectedProgListMig=selprgarr;

          console.log(this.selectedProgListMig)
}


else if(this.progListMig.length==0)
{
  this.selectedProgListMig=[];
}



})



/*---------------------
//in future do gor global admin if its used
     this.transactionService.getUserAccessRole(this.sub_cdsid).subscribe(sub_prguseraccess=>{
      console.log("sub_useracces",sub_prguseraccess);
        var position:number;
      this.subPrgUserAccess=sub_prguseraccess;
        this.progList=[];
        this.cosList=[];
        ---------------*/

/*
        if(this.selectedplantlist.length==this.plantCodeList.length)
        {
        for(var prg_temp=0;prg_temp<this.subPrgUserAccess.length;prg_temp++)
        {
          if(this.subPrgUserAccess[prg_temp].role_id==1)
          {
            var prg_arr=[];
            if(this.subPrgUserAccess[prg_temp].program_name!=null)
            {
            prg_arr=this.subPrgUserAccess[prg_temp].program_name.split(',');
            for(var prg=0;prg<prg_arr.length;prg++)
            {
              this.progList.push({programcode:prg_arr[prg]})
            }


            console.log("proglist",this.progList);
          }

          if(this.subUserAccess[prg_temp].ownershp_strat_code!=null)
          {

            var cos_arr=[];
            cos_arr=this.subPrgUserAccess[prg_temp].ownershp_strat_code.split(',');



              for(var cos=0;cos<cos_arr.length;cos++)
    {
      var present_cos=false;
      for(var kcos=0;kcos<this.cosList.length;kcos++)
      {
        if(cos_arr[cos]==this.cosList[kcos].cos)
        {
          present_cos=true;
          break;
        }
      }


      if(present_cos==false)
      {
      this.cosList.push({cos:cos_arr[cos]});
      }
    }




            console.log("coslist",this.cosList);

          }

            //service to get trading company
            //assign slected trading company full list of trading companies;





          }

          }

        }




    else if(this.selectedplantlist.length<this.plantCodeList.length)
    {

*/
/*
var all_present_change=false;
for(var i=0;i<this.selectedplantlist.length;i++)
{
  if(this.selectedplantlist[i].plantcode=="all")
  {
    all_present_change=true;
    break;
  }
}
      console.log(this.selectedplantlist)


      if(all_present_change!=true)
      {*/

        /*---------------------------------------------
      for(var iplt=0;iplt<this.selectedplantlist.length;iplt++)
      {
      for(var prg_temp=0;prg_temp<this.subPrgUserAccess.length;prg_temp++)
      {
        if(this.subPrgUserAccess[prg_temp].cd_region==this.current_region_code_sub)
        {
        if(this.subPrgUserAccess[prg_temp].role_id==this.sub_current_roleid && this.subPrgUserAccess[prg_temp].plantgsdbcode==this.selectedplantlist[iplt].plantcode)
        {


          var prg_arr=[];
            if(this.subPrgUserAccess[prg_temp].program_name!=null)
            {
            prg_arr=this.subPrgUserAccess[prg_temp].program_name.split(',');
            for(var prg=0;prg<prg_arr.length;prg++)
            {


              var present_prg=false;
              for(var kprg=0;kprg<this.progList.length;kprg++)
                        {
                     if(prg_arr[prg]==this.progList[kprg].programcode)
                      {
                           present_prg=true;
                                              break;
                      }
               }


            if(present_prg==false)
                    {
                      this.progList.push({programcode:prg_arr[prg]})
                        }

            }


            console.log("proglist",this.progList);
          }

          if(this.subUserAccess[prg_temp].ownershp_strat_code!=null)
          {

            var cos_arr=[];
            cos_arr=this.subPrgUserAccess[prg_temp].ownershp_strat_code.split(',');


                            for(var cos=0;cos<cos_arr.length;cos++)
                                     {
                                    var present_cos=false;
                                       for(var kcos=0;kcos<this.cosList.length;kcos++)
                                                 {
                                              if(cos_arr[cos]==this.cosList[kcos].cos)
                                               {
                                                    present_cos=true;
                                                                       break;
                                               }
                                        }


                                     if(present_cos==false)
                                             {
                                       this.cosList.push({cos:cos_arr[cos]});
                                                 }
                                        }




            console.log("coslist",this.cosList);


          }

            //service to get trading company
            //assign slected trading company full list of trading companies;








        }
      }
     // }
      }


console.log(this.selectedproglist);



    }---------------------------------*/
/*  }*/
/*---------------------------------------------
if(this.progList.length!=0 && this.selectedproglist.length!=0)
{
  var selprgarr=[];
  console.log(this.selectedproglist);
  console.log(this.progList)
          for(var iprg=0;iprg<this.progList.length;iprg++)
          {

            console.log(this.progList[iprg])
          for(var jprg=0;jprg<this.selectedproglist.length;jprg++)
          {
            console.log(this.selectedproglist[jprg])

            if(this.progList[iprg].programcode==this.selectedproglist[jprg].programcode)
            {
              selprgarr.push(this.selectedproglist[jprg]);
              console.log(selprgarr)
              break;
            }
          }

          }




          this.selectedproglist=selprgarr;

          console.log(this.selectedproglist)
}


else if(this.progList.length==0)
{
  this.selectedproglist=[];
}



if(this.cosList.length!=0 && this.selectedcoslist.length!=0)
{
  var selcosarr=[];
          for(var icos=0;icos<this.cosList.length;icos++)
          {


          for(var jcos=0;jcos<this.selectedcoslist.length;jcos++)
          {

            if(this.cosList[icos].cos==this.selectedcoslist[jcos].cos)
            {
              selcosarr.push(this.selectedcoslist[jcos]);
              break;
            }
          }

          }


          this.selectedcoslist=selcosarr;
}


else if(this.cosList.length==0)
{
  this.selectedcoslist=[];
}












       })

      //service

     // this.disableProgDrp=true;

-------------------------------------*/

  }


  else{
    this.disableProgDrpMig=true;
    this.selectedPlantListMig=[];
   // this.selectedcoslist=[];
    this.selectedProgListMig=[];


  }

  }

/*
  check_all()
  {
    console.log("selectedplant",this.selectedplantlist)
    var all_present:boolean=false;
    for(var i=0;i<this.selectedplantlist.length;i++)
    {
      if(this.selectedplantlist[i].plantcode=="all")
      {all_present=true;
        break;

      }
    }


    if(all_present==true)
    {


      this.selectedplantlist=[];
      this.selectedplantlist.push({plantcode:"all",plant:"[ALL]"});

/*
      for(var i=0;i<this.plantCodeList.length;i++)
    {
      if(this.plantCodeList[i].plantcode!="all")
      {
        this.plantCodeList[i].disable=true;

      }
    }
*/



 /*   }


    else{

/*
      for(var i=0;i<this.plantCodeList.length;i++)
    {
      if(this.plantCodeList[i].plantcode!="all")
      {
        this.plantCodeList[i].disable=false;

      }
    }
    */






 /*   }
  }*/


  getSelectedPartMig(partData:any){


    console.log("partData",partData)
   // this.selectedsimpliedpartnodetails=partData.partnumbercommonized;

    this.transactionService.loadmenuwhenclickedfromdashboard('Yes');

    // this.pushdata();

    sessionStorage.setItem('offsitesupplierid',partData.offsitesupplierid);

    if(partData.ngpp_part_status=='New Part')
    {
this.transactionService.getproposalid().subscribe(sub_proposalid=>{


  console.log(sub_proposalid)


  this.selectedSimpliedPartnoDetailsMig.bompartmasterid=partData.bompartmasterid;
  this.selectedSimpliedPartnoDetailsMig.simplifiedpartno=partData.partnumbercommonized;
  this.selectedSimpliedPartnoDetailsMig.proposalid=sub_proposalid;
  this.selectedSimpliedPartnoDetailsMig.plantgsdbcode=partData.plantgsdbcode;
  this.selectedSimpliedPartnoDetailsMig.proposallastsaved=0;


  this.selectedSimpliedPartnoDetailsMig.part_status=partData.ngpp_part_status;


  this.selectedSimpliedPartnoDetailsMig.supplier_code=partData.suppliergsdbcode;
  this.selectedSimpliedPartnoDetailsMig.proposallastsaved_ee=1;
  this.selectedSimpliedPartnoDetailsMig.is_ee_proposal=false;
  this.selectedSimpliedPartnoDetailsMig.currentview_ee=false;
  this.selectedSimpliedPartnoDetailsMig.propsl_type='Migration';
  this.selectedSimpliedPartnoDetailsMig.imp_part_status=null;

  this.selectedSimpliedPartnoDetailsMig.from_draft=true;

  this.storePrimaryPartMig();

})

    }

    else{


      console.log(partData.plantgsdbcode);
      console.log(partData.partnumbercommonized);


      // var sub_exist_proposalid=0;

      // service to get proposal id



        this.transactionService.get_exist_proposalid(partData.partnumbercommonized,partData.plantgsdbcode,"Migration",partData.suppliergsdbcode).subscribe(sub_exist_proposalid=>{

          console.log('subexistpropolis',sub_exist_proposalid)
      this.selectedSimpliedPartnoDetailsMig.bompartmasterid=partData.bompartmasterid;
  this.selectedSimpliedPartnoDetailsMig.simplifiedpartno=partData.partnumbercommonized;
  this.selectedSimpliedPartnoDetailsMig.proposalid=sub_exist_proposalid.id_proposal;
  this.selectedSimpliedPartnoDetailsMig.plantgsdbcode=partData.plantgsdbcode;
  this.selectedSimpliedPartnoDetailsMig.proposallastsaved=sub_exist_proposalid.lst_saved_tab;
  this.selectedSimpliedPartnoDetailsMig.part_status=partData.ngpp_part_status;
  this.selectedSimpliedPartnoDetailsMig.supplier_code=partData.suppliergsdbcode;
  this.selectedSimpliedPartnoDetailsMig.propsl_type='Migration';
  this.selectedSimpliedPartnoDetailsMig.imp_part_status=null


  this.selectedSimpliedPartnoDetailsMig.from_draft=true;

  if(sub_exist_proposalid.lst_saved_tab_ee==0)
  {
    this.selectedSimpliedPartnoDetailsMig.proposallastsaved_ee=1;
  }
  else{
    this.selectedSimpliedPartnoDetailsMig.proposallastsaved_ee=sub_exist_proposalid.lst_saved_tab_ee;
  }
  if(this.selectedSimpliedPartnoDetailsMig.proposallastsaved==4)
  {






      this.transactionService.loadtab4details(this.selectedSimpliedPartnoDetailsMig.proposalid).subscribe(tab4det=>
        {
          console.log('tab4',tab4det);


          this.transactionService.loadtab2detailsifproposalexistalready(this.selectedSimpliedPartnoDetailsMig.proposalid).subscribe(tab2_details=>{
            console.log('tab2-det',tab2_details)
            console.log(tab2_details)



            if(tab2_details.cntr_ret_type!=null && tab2_details.cntr_ret_type!='' )
            {
              if(tab2_details.cntr_ret_type.toLowerCase()=='returnable')
              {
                this.is_container_expendable_mig=false;

              }


              else if(tab2_details.cntr_ret_type.toLowerCase()=='expendable')
              {
                this.is_container_expendable_mig=true;

              }
            }


            else
            {
              this.is_container_expendable_mig=false;
            }


            if(this.is_container_expendable_mig==true)
            {
              //region admin details


              this.transactionService.get_region_details(this.currentRegionCodeSubMig).subscribe(region_det=>{
                console.log('tab4-region',region_det);

                        if(region_det.emg_exp_pkg_reqd_ind!=null && region_det.emg_exp_pkg_reqd_ind!='' && region_det.emg_exp_pkg_reqd_ind.toLowerCase()=='no')
                        {

                          console.log('inside if tab4')
                          this.selectedSimpliedPartnoDetailsMig.is_ee_proposal=false;
                          this.selectedSimpliedPartnoDetailsMig.currentview_ee=false;



                          if(this.selectedSimpliedPartnoDetailsMig.part_status.toLowerCase()=='rejected' || this.selectedSimpliedPartnoDetailsMig.part_status.toLowerCase()=='recalled' )
                          {
                            this.selectedSimpliedPartnoDetailsMig.from_draft=true;

                          }


                          this.storePrimaryPartMig();

                        }


                        else
                        {

                                if(tab4det.createmgcyexpndpckgprop!=null &&  tab4det.createmgcyexpndpckgprop!='' && tab4det.createmgcyexpndpckgprop.toLowerCase()=='yes')
                                {


                                  if(tab4det.f_extcntrdsgnphoto==null|| tab4det.f_extcntrdsgnphoto==''||
                                  tab4det.f_intcntrdsgnphoto==null|| tab4det.f_intcntrdsgnphoto==''||
                                  tab4det.f_partphoto==null|| tab4det.f_partphoto==''||
                                  tab4det.f_metelidplatephoto==null|| tab4det.f_metelidplatephoto==''||
                                  tab4det.f_shpunitphoto==null|| tab4det.f_shpunitphoto=='' || tab4det.createmgcyexpndpckgprop==null || tab4det.createmgcyexpndpckgprop=='')



                                  {
                                    this.selectedSimpliedPartnoDetailsMig.is_ee_proposal=true;
                                    this.selectedSimpliedPartnoDetailsMig.currentview_ee=false;

                                  }



                                  else
                                  {
                                    this.selectedSimpliedPartnoDetailsMig.is_ee_proposal=true;
                                    console.log('entered if condn');
                                    if(this.selectedSimpliedPartnoDetailsMig.proposallastsaved_ee==4)
                                    {
                                      this.selectedSimpliedPartnoDetailsMig.currentview_ee=false;
                                    }
                                    else{

                                      this.selectedSimpliedPartnoDetailsMig.currentview_ee=true;

                                    }

                                  }




                                }
                                else{
                                  this.selectedSimpliedPartnoDetailsMig.is_ee_proposal=false;
                                  this.selectedSimpliedPartnoDetailsMig.currentview_ee=false;

                                }


                                if(this.selectedSimpliedPartnoDetailsMig.part_status.toLowerCase()=='rejected' || this.selectedSimpliedPartnoDetailsMig.part_status.toLowerCase()=='recalled' )
                                {
                                  this.selectedSimpliedPartnoDetailsMig.from_draft=true;

                                }


                                this.storePrimaryPartMig();
                        }



              })
            }


            else
            {
                        if(tab4det.createmgcyexpndpckgprop!=null &&  tab4det.createmgcyexpndpckgprop!='' && tab4det.createmgcyexpndpckgprop.toLowerCase()=='yes')
                        {
                          if(tab4det.f_extcntrdsgnphoto==null|| tab4det.f_extcntrdsgnphoto==''||
                          tab4det.f_intcntrdsgnphoto==null|| tab4det.f_intcntrdsgnphoto==''||
                          tab4det.f_partphoto==null|| tab4det.f_partphoto==''||
                          tab4det.f_metelidplatephoto==null|| tab4det.f_metelidplatephoto==''||
                          tab4det.f_shpunitphoto==null|| tab4det.f_shpunitphoto=='' || tab4det.createmgcyexpndpckgprop==null || tab4det.createmgcyexpndpckgprop=='')
                          {
                            this.selectedSimpliedPartnoDetailsMig.is_ee_proposal=true;
                            this.selectedSimpliedPartnoDetailsMig.currentview_ee=false;

                          }

                          else
                          {
                                this.selectedSimpliedPartnoDetailsMig.is_ee_proposal=true;
                                console.log('entered if condn');
                                if(this.selectedSimpliedPartnoDetailsMig.proposallastsaved_ee==4)
                                {
                                  this.selectedSimpliedPartnoDetailsMig.currentview_ee=false;
                                }
                                else
                                {

                                  this.selectedSimpliedPartnoDetailsMig.currentview_ee=true;

                                }
                        }
                        }
                        else{
                          this.selectedSimpliedPartnoDetailsMig.is_ee_proposal=false;
                          this.selectedSimpliedPartnoDetailsMig.currentview_ee=false;

                        }


                        if(this.selectedSimpliedPartnoDetailsMig.part_status.toLowerCase()=='rejected' || this.selectedSimpliedPartnoDetailsMig.part_status.toLowerCase()=='recalled' )
                        {
                          this.selectedSimpliedPartnoDetailsMig.from_draft=true;

                        }


                        this.storePrimaryPartMig();

            }



        })

    })

  }
  else
  {
    this.selectedSimpliedPartnoDetailsMig.is_ee_proposal=false;
    this.selectedSimpliedPartnoDetailsMig.currentview_ee=false;
    if(this.selectedSimpliedPartnoDetailsMig.part_status.toLowerCase()=='rejected' || this.selectedSimpliedPartnoDetailsMig.part_status.toLowerCase()=='recalled' )
    {
      this.selectedSimpliedPartnoDetailsMig.from_draft=true;

    }

    this.storePrimaryPartMig();
  }




      // this.store_primary_part();
        })

    }





  }


  /*pushdata():string
  {

return this.selectedsimpliedpartno;

  }*/


  storePrimaryPartMig()
  {
    console.log('selectedsimpliedpartnodetails',this.selectedSimpliedPartnoDetailsMig)
    if(this.currentRegionCodeSubMig=='NA')
    {
      console.log('before save',this.selectedSimpliedPartnoDetailsMig)

      sessionStorage.setItem('napackpropart', JSON.stringify(this.selectedSimpliedPartnoDetailsMig));
      console.log('After save',this.selectedSimpliedPartnoDetailsMig);

      console.log('After save test',sessionStorage.getItem('napackpropart'))
    }


    if(this.currentRegionCodeSubMig=='EU')
    {
      sessionStorage.setItem('eupackpropart', JSON.stringify(this.selectedSimpliedPartnoDetailsMig));
    }



    if(this.currentRegionCodeSubMig=='AP')
    {
      sessionStorage.setItem('appackpropart', JSON.stringify(this.selectedSimpliedPartnoDetailsMig));
    }



    if(this.currentRegionCodeSubMig=='SA')
    {
      sessionStorage.setItem('sapackpropart', JSON.stringify(this.selectedSimpliedPartnoDetailsMig));
    }
    const r=this.transactionService.getpartno_packpro(this.currentRegionSubMig);
    console.log('selectedsimpliedpartnodetails-after save',r);

    let reg_supp_sub_url:string;
    reg_supp_sub_url=this.transactionService.getregionurlvalue(this.currentRegionSubMig);
    reg_supp_sub_url+='packagingprocess/proposalcreation';

    this.router.navigate([reg_supp_sub_url]);




  }




  applyFilterMig()
  {


  /*  if(document.getElementById("overlaysub") != null )
    {
    document.getElementById("overlaysub").style.display = "block";
    }
    if(document.getElementById("loadersub") != null )
    {
    document.getElementById("loadersub").style.display = "block";
    }*/


    this.loadersubMig=true;

    this.gridTextMig='';
    console.log(this.suppCodeMig);
    console.log(this.selectedPartPrefixMig);
    console.log(this.selectedPartBaseMig);
    console.log(this.selectedPartSuffixMig);
    console.log(this.selectedPlantListMig);
    if(this.selectedPartPrefixMig!=null && this.selectedPartPrefixMig!='')
    {
      this.partPrefixMig=this.selectedPartPrefixMig

    }
    else{
      this.partPrefixMig='all'
    }

    if(this.selectedPartBaseMig!=null && this.selectedPartBaseMig!='')
    {
      this.partBaseMig=this.selectedPartBaseMig

    }
    else{
      this.partBaseMig='all'
    }



    if(this.selectedPartSuffixMig!=null && this.selectedPartSuffixMig!='')
    {
      this.partSuffixMig=this.selectedPartSuffixMig

    }
    else{
      this.partSuffixMig='all'
    }


    if(this.selectedPlantListMig.length!=0)
    {

      console.log('inside')
            if(this.selectedPlantListMig.length==this.plantCodeListMig.length)
            {
              this.plantcodeMig='all';
            }

            else
            {

              this.plantcodeMig='';
              let plt_count=0;
              for(let p_plt=0; p_plt<this.selectedPlantListMig.length; p_plt++)
              {
                 this.plantcodeMig+=this.selectedPlantListMig[p_plt].plantcode
                 plt_count+=1;
                 if(plt_count!=this.selectedPlantListMig.length)
                 {
                   this.plantcodeMig+=',';
                 }
              }



            }

            console.log(this.plantcodeMig);

    }

    else{

      this.plantcodeMig='all';

    }




    // program

    if(this.selectedProgListMig.length!=0)
    {
            if(this.selectedProgListMig.length==this.progListMig.length)
            {
              this.programcodeMig='all';
            }

            else
            {

              this.programcodeMig='';
              let prg_count=0;
              for(let p_prg=0; p_prg<this.selectedProgListMig.length; p_prg++)
              {
                 this.programcodeMig+=this.selectedProgListMig[p_prg].programcode
                 prg_count+=1;
                 if(prg_count!=this.selectedProgListMig.length)
                 {
                   this.programcodeMig+=',';
                 }
              }



            }

            console.log(this.programcodeMig);

    }

    else{
      this.programcodeMig='all';

    }



    // cos

    if(this.selectedCosListMig.length!=0)
    {
            if(this.selectedCosListMig.length==this.cosListMig.length)
            {
              this.cos='all';
            }

            else
            {

              this.cos='';
              let cos_count=0;
              for(let p_cos=0; p_cos<this.selectedCosListMig.length; p_cos++)
              {
                 this.cos+=this.selectedCosListMig[p_cos].cos
                 cos_count+=1;
                 if(cos_count!=this.selectedCosListMig.length)
                 {
                   this.cos+=',';
                 }
              }



            }

            console.log(this.cos);

    }

    else{
      this.cos='all';
    }


    // trading company

    if(this.selectedTrdcmpyMig.length!=0)
    {
            if(this.selectedTrdcmpyMig.length==this.tradCmpyListMig.length)
            {
              this.trdcmpyMig='all';
            }

            else
            {

              this.trdcmpyMig='';
              let trdcmpy_count=0;

              for(let p_trdcmpy=0; p_trdcmpy<this.selectedTrdcmpyMig.length; p_trdcmpy++)
              {
                if(this.selectedTrdcmpyMig[p_trdcmpy].tradingcogsdbcode=='[No Trading Company]')
                {
                  this.trdcmpyMig+='notradco'
                }
                else
                {
                 this.trdcmpyMig+=this.selectedTrdcmpyMig[p_trdcmpy].tradingcogsdbcode
                }
                 trdcmpy_count+=1;
                 if(trdcmpy_count!=this.selectedTrdcmpyMig.length)
                 {
                   this.trdcmpyMig+=',';
                 }
              }


            }


              console.log(this.trdcmpyMig);

    }

    else{
      this.trdcmpyMig='all'

    }


// status



if(this.selectedStatusListMig.length!=0)
{
        if(this.selectedStatusListMig.length==this.statusListMig.length)
        {
          this.statusMig='New Part,Rejected,UnSubmitted,Recalled';
        }

        else
        {

          this.statusMig='';
          let sts_count=0;
          for(let p_sts=0; p_sts<this.selectedStatusListMig.length; p_sts++)
          {
             this.statusMig+=this.selectedStatusListMig[p_sts].status;
             sts_count+=1;
             if(sts_count!=this.selectedStatusListMig.length)
             {
               this.statusMig+=',';
             }
          }


        }


          console.log(this.statusMig);


}

else{


  this.statusMig='New Part,Rejected,UnSubmitted,Recalled';

}


if(this.subCurrentRoleidMig==1)
{


console.log(this.plantcodeMig,this.programcodeMig,this.cos,this.statusMig,this.trdcmpyMig,this.partPrefixMig,this.partBaseMig,this.partSuffixMig,this.suppCodeMig)

this.transactionService.getsubmittergrid_migration(this.plantcodeMig,this.programcodeMig,this.cos,this.statusMig,this.trdcmpyMig,this.partPrefixMig,this.partBaseMig,this.partSuffixMig,this.suppCodeMig,this.subCdsidMig,1,this.currentRegionCodeSubMig).subscribe((subgridlist => {

  this.submittergridListMig=subgridlist



 // this.suppcode=[];
/* if(this.suppget.length==0)
 {
   this.valueback=true;
 }
 else{
 this.valueback=false;
}*/


console.log('successful',this.submittergridListMig);

this.submitterGridMig();

}))

}


else if(this.subCurrentRoleidMig==2)
{


  if(this.selectedSuppliergsdbcodeDesignsourceMig!=null && this.selectedSuppliergsdbcodeDesignsourceMig!='')

  {
    this.suppliergsdbcodeDesignsourceMig=this.selectedSuppliergsdbcodeDesignsourceMig;
  }

  else{
    this.suppliergsdbcodeDesignsourceMig='all'
  }


  console.log(this.designsourceCodeMig);

this.supplierCountryMig='';

  if(this.selectedSupplierCountryMig.length!=0)
{
        if(this.selectedSupplierCountryMig.length==this.supplierCountryListMig.length)
        {
          this.supplierCountryMig='all';
        }

        else
        {

          this.supplierCountryMig='';
          let supp_count=0;
          console.log('sss',this.selectedSupplierCountryMig)
          for(let p_sts=0; p_sts<this.selectedSupplierCountryMig.length; p_sts++)
          {
             this.supplierCountryMig+=this.selectedSupplierCountryMig[p_sts].cd_country
             supp_count+=1;
             if(supp_count!=this.selectedSupplierCountryMig.length)
             {
               this.supplierCountryMig+=',';
             }
          }


        }


          console.log('ooooooo',this.supplierCountryMig);


}
else{


  this.supplierCountryMig='all';

}

// temp
// this.suppliercountry="all";




  // service to get details


 /////// this.submittergridList=subgridlist



  // this.suppcode=[];
 /* if(this.suppget.length==0)
  {
    this.valueback=true;
  }
  else{
  this.valueback=false;
 }*/


 console.log('aaaaaa',this.plantcodeMig,this.programcodeMig,this.cos,this.statusMig,this.trdcmpyMig,this.partPrefixMig,this.partBaseMig,this.partSuffixMig,this.suppliergsdbcodeDesignsourceMig,this.supplierCountryMig,this.designsourceCodeMig,this.subCdsidMig,2,this.currentRegionCodeSubMig)

 this.transactionService.getsubmittergrid_designsource_migration(this.plantcodeMig,this.programcodeMig,this.cos,this.statusMig,this.trdcmpyMig,this.partPrefixMig,this.partBaseMig,this.partSuffixMig,this.suppliergsdbcodeDesignsourceMig,this.supplierCountryMig,this.designsourceCodeMig,this.subCdsidMig,2,this.currentRegionCodeSubMig).subscribe((subgridlist => {

  this.submittergridListMig=subgridlist


 console.log('successful',this.submittergridListMig);

 this.submitterGridMig();
 }))




}







    // service to call grid based on all the values
  }


  clearFilterValuesMig()
  {
    this.selectedPartPrefixMig='';
    this.selectedPartBaseMig='';
    this.selectedPartSuffixMig='';
    this.selectedPlantListMig=[];
    this.disableProgDrpMig=true;
    this.selectedProgListMig=[];
    this.selectedCosListMig=[];
    this.selectedStatusListMig=[];
    this.selectedTrdcmpyMig=[];
    this.selectedSuppliergsdbcodeDesignsourceMig='';
    this.selectedSupplierCountryMig=[];

    this.applyFilterMig();
  }



  submitterGridMig()
  {
    // document.getElementById("overlaysub").style.display = "none";
    // document.getElementById("loadersub").style.display = "none";


    this.loadersubMig=false;

    console.log(this.submittergridListMig);


if(this.submittergridListMig.length!=0 && this.submittergridListMig!=null)
{

  console.log(this.submittergridListMig[0].disable)
  this.gridTextMig='';
  this.presentTableMig=true;


  let prefix_inter='';
  let base_inter='';
  let suffix_inter='';


  this.gridLengthMig=this.submittergridListMig.length

let grid_display_part_status='';
  for(let isubtab=0; isubtab<this.submittergridListMig.length; isubtab++)
  {

    this.submittergridListMig[isubtab].part_pre_bas_suf='';
    if(this.submittergridListMig[isubtab].partprefix!=null)
    {
      prefix_inter=this.submittergridListMig[isubtab].partprefix
    }
    else
  {
    prefix_inter='         '
  }
  if(this.submittergridListMig[isubtab].partprefix!=null)
    {
      prefix_inter=this.submittergridListMig[isubtab].partprefix
    }
    else
  {
    prefix_inter='         '
  }
  if(this.submittergridListMig[isubtab].partbase!=null)
    {
      base_inter=this.submittergridListMig[isubtab].partbase
    }
    else
  {
    base_inter='         '
  }
  if(this.submittergridListMig[isubtab].partsuffix!=null)
  {
    suffix_inter=this.submittergridListMig[isubtab].partsuffix
  }
  else
{
  suffix_inter='         '
}
this.submittergridListMig[isubtab].part_pre_bas_suf= prefix_inter+'-'+base_inter+'-'+suffix_inter;

 if(this.submittergridListMig[isubtab].ngpp_part_status=='Submitted')
    {
    this.submittergridListMig[isubtab].non_submited=false;
    }

    else{
      this.submittergridListMig[isubtab].non_submited=true;
    }


    grid_display_part_status='';
    grid_display_part_status+=this.submittergridListMig[isubtab].ngpp_part_status;

    if(this.submittergridListMig[isubtab].isstatusurgent!=null && this.submittergridListMig[isubtab].isstatusurgent.toLowerCase()=='y')
    {
      grid_display_part_status+=' (Urgent)'
    }
    if(this.submittergridListMig[isubtab].isdeliquent!=null && this.submittergridListMig[isubtab].isdeliquent.toLowerCase()=='y')
    {
      grid_display_part_status+=' (Delinquent)'
    }


    this.submittergridListMig[isubtab].displaypartstatus=grid_display_part_status;


   // console.log(this.submittergridList[isubtab].ngpp_part_status);

    /*if(this.submittergridList[isubtab].ngpp_part_status=="1")
    {
    this.submittergridList[isubtab].ngpp_part_status_name="New Part"
    }

    else if(this.submittergridList[isubtab].ngpp_part_status=="2")
    {
    this.submittergridList[isubtab].ngpp_part_status_name="UnSubmitted"
    }

    else if(this.submittergridList[isubtab].ngpp_part_status=="3")
    {
    this.submittergridList[isubtab].ngpp_part_status_name="Rejected"
    }

    else if(this.submittergridList[isubtab].ngpp_part_status=="4")
    {
    this.submittergridList[isubtab].ngpp_part_status_name="Submitted"
    }*/

  }

this.cols = [
  { field: 'partnumbercommonized', header:'Simplified Part Number'},
  {field: 'part_pre_bas_suf', header:'Part Prefix-Base-Suffix'},
  {field:'partdesc',header:'Part Description'},
  { field: 'suppliergsdbcode', header:'Supplier GSDB'},
  { field: 'suppliercountrycodeISO', header:'Supplier Country'},
  { field: 'plantgsdbcode', header:'Plant'},
  { field: 'offsitesupplierid', header:'Bailment Supplier'},
  { field: 'tradingcogsdbcode', header:'Trading Company'},
  { field: 'programname', header:'Program(s)'},
  { field: 'parteffectiveindate', header:'Effective In Dates'},
  { field: 'cntrsuggestedownershpstrat', header:'COS'},
  {field: 'displaypartstatus', header:'Status'}
];
}


else{
  this.presentTableMig=false;
  this.cols=[];
  this.gridTextMig='No Match Found'
  this.gridLengthMig=0;
}

}

exportExcel() {
  import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.submittergridListMig);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'Part List');
  });
}

saveAsExcelFile(buffer: any, fileName: string): void {
  import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
          type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  });
}


  }



