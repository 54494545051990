/* tslint:disable */
import { DatePipe } from '@angular/common';
import { SourceMapGenerator } from '@angular/compiler/src/output/source_map';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, GalleriaThumbnails, MessageService } from 'primeng';
import { environment } from 'src/environments/environment';
import { threadId } from 'worker_threads';
import { EepackproposaltabtwoComponent } from '../eepackproposaltabtwo/eepackproposaltabtwo.component';
import { ImprovementeetabtwoComponent } from '../improvementeetabtwo/improvementeetabtwo.component';
import { ImprovementpackagingproposalComponent } from '../improvementpackagingproposal/improvementpackagingproposal.component';
import { ImprovementtabtwoComponent } from '../improvementtabtwo/improvementtabtwo.component';
import { TransactionService } from '../loginpage/Transaction.service';
import { ActivePlants, ApprovalStatus, changeTrackingForImprovement, cntrandsudetailspost, compmaterialpost, containerOwnershipList, fromdiplayvalues_all, myUserAccessRoles, partDetailForPost, primarypartdetails, programsbasedonplants, programsbasedonplantsforsearchprop, RevHistFromAPi, RevisionObj, searchProposalGrid, searchProposalGridExcelFormat, searchProposalGridFromAPI, selec_prt_pro_details, storecomponentforpost, subcoslist, substatuslist, subtradco, supplierforsearchproposal, supp_country, tab4details } from '../loginpage/transactions';
import { PackproposaltabtwoComponent } from '../packproposaltabtwo/packproposaltabtwo.component';
import { SubmiterdashboardComponent } from '../submiterdashboard/submiterdashboard.component';

@Component({
  selector: '[app-searchexistingproposal]',
  templateUrl: './searchexistingproposal.component.html',
  styleUrls: ['./searchexistingproposal.component.css'],
  providers:[MessageService,ConfirmationService]
})
export class SearchexistingproposalComponent implements OnInit {

  current_region:string;
  region_code:string
  search_exist_prop_current_roleid:number;
  supp_code:string;
  is_supplier=false;

  noroleselectedDialog:boolean;
  norolecontent:string;

  storedpart:selec_prt_pro_details={simplifiedpartno:'-',proposalid:0,bompartmasterid:0,plantgsdbcode:'',proposallastsaved:0,part_status:'',supplier_code:'',proposallastsaved_ee:1,is_ee_proposal:true,currentview_ee:true,from_draft:false,propsl_type:'',imp_part_status:''};

  noSupDialog:boolean;
  content:string;

  part_prefix='';
  part_base='';
  part_suffix='';
  part_desc='';
  part_sup_code='';
  part_sup_name='';
  apprv_stat_grid_inp='';
  part_trad_cmp='';
  part_status_grid_inp='';
  part_sp_cntry='';
  part_own_cat='';
  part_plant_code='';
  part_prg_code='';
  part_cos_grid_inp=''

  supplierGSDBcode='';
  supplierfullname='';
  regionlist:any=[];
  selected_region_from_list='';
  viewOnlyForSupplier:string="-";

  selectedpartprefix='';
  selectedpartbase='';
  selectedpartsuffix='';
  selectedpartdesc='';



  selectedcntrbase='';
  cntr_base='';
  selectedcntrsuffix='';
  cntr_suffix='';


  tradCmpyList:subtradco[]=[];
  selectedtrdcmpy:subtradco[]=[];
  all_selected_trad_comp=false;
  trdcmpy='';

  statusList:substatuslist[]=[];
  selectedstatuslist:substatuslist[]=[];
  part_status='';
  all_selected_part_status=false;

  suppliercountryList:supp_country[]=[];
  selected_suppliercountry:supp_country[]=[];
  suppliercountry='';
  all_selected_supp_cntry=false;

  tab4_output_det:tab4details;
  tab4_output:tab4details;


  expreturnList=[{type:'All',typ_b:'all'},{type:'Expendable',typ_b:'Expendable'},{type:'Returnable',typ_b:'Returnable'}];
  selected_expreturn=[];
  exp_return='';
  all_selected_exp_ret=false;

  ownrship_catg_list=[{catg:'All',disp:'all'},{catg:'Ford',disp:'Ford'},{catg:'Supplier',disp:'Supplier'},{catg:'Third Party',disp:'Third Party'}];
  selected_ownrshp_catg=[];
  ownrshipcategory='';
  all_selected_in_ownershp_catg=false;

  apprvr_status_list:ApprovalStatus[];
  selected_approval_status:ApprovalStatus[]=[];
  approval_status='';
  all_selected_in_approval_status=false;

  ownership_start_code_list:containerOwnershipList[]=[];
  selected_ownershp_start_code:containerOwnershipList[]=[];
  ownrshp_start_code='';
  all_selected_on_ownrshp_start_code=false;

  plantcode:string="";
  selected_plant_code:ActivePlants={
    plant_name: "",
    cd_plant: "",
    plant_type_name: "",
    plantGSDBCode: "",
  
    
  
  }
  selected_plant_codes_list:ActivePlants[]=[];
  plantcodelist:ActivePlants[]=[];
  all_selected_on_plant_code=false;
  plantcodeselected=false;

  programsList:programsbasedonplants[]=[];
  programListalongwithPlants:programsbasedonplantsforsearchprop[]=[];
  selected_programs_list:programsbasedonplantsforsearchprop[]=[];
  programcodes='';
  all_selected_on_program=false;

  userid:string;
  supplierList:supplierforsearchproposal[]=[];
  selected_supplier:supplierforsearchproposal={supcode:''};
  suupliercode:string="";
  supplierEntered:string="";
  fordPersonLoggedIn:boolean=true;

  searchpropgrid:searchProposalGrid[]=[];
  searchpropgridfromapi:searchProposalGridFromAPI[]=[];
  cols:any;
  view_proposal=false;
  search_result='';

  userAccessGrid:myUserAccessRoles[]=[];

  proposal_details_search:fromdiplayvalues_all;
  revision_obj_search:RevisionObj[]=[];

  revHistFromServiceAPI:RevHistFromAPi[]=[];

  enabledetails_search=false;

  selectedsimpliedpartnodetails:selec_prt_pro_details={simplifiedpartno:'-',bompartmasterid:0,proposalid:0,plantgsdbcode:'',proposallastsaved:0,part_status:'',supplier_code:'',proposallastsaved_ee:0,currentview_ee:false,is_ee_proposal:false,from_draft:false,propsl_type:'',imp_part_status:''};
  table_selected_cntr_from_copy_dialog:any;



  base_change='NA';
  comments='';
  upload_cmms_packaging='NA';
  apr_current_roleid:number;
  roles_passed:string;
  selected_propid: number;
  enable_dialog_notification = false;
  releaselevel: string;
  // apr_current_roleid:string;

  cmms_checked=false;

  


  rej_rel_for_prodappr_enable = false;


 email_list='';
 email_notification_eventid='';


  selected_proposal:any=null;

  improvementLabel='';
  improvementButtonEnable=false;

  showImprovementProposal=false;
  showImprovementProposalApiInput='No';

  enableEffectiveDateForImprovementProposal=false;
  effectiveDateForImprovementProposal:Date;
  effectiveDateInString:string=null;
  currentDate:Date;
  effectiveDateInvalidString='';

  showUpdateImprovementButton=false;
  showDeleteImprovementButton=false;


  improvementSelectedSimpliedPartnoDetails:selec_prt_pro_details={simplifiedpartno:'-',bompartmasterid:0,proposalid:0,plantgsdbcode:'',proposallastsaved:0,part_status:'',supplier_code:'',proposallastsaved_ee:0,currentview_ee:false,is_ee_proposal:false,from_draft:false,propsl_type:'',imp_part_status:''};

   storeTab1Data:primarypartdetails;
   storeTab1PartData:partDetailForPost[]=[];
   storeTab2Data:cntrandsudetailspost;
   storeTab3Data:compmaterialpost;
   storeTab4Data:tab4details;
   storeTab3CompData:storecomponentforpost[]=[];



   storeTab1Data_ee:primarypartdetails;
   storeTab1PartData_ee:partDetailForPost[]=[];
   storeTab2Data_ee:cntrandsudetailspost;
   storeTab3Data_ee:compmaterialpost;
   storeTab4Data_ee:tab4details;
   storeTab3CompData_ee:storecomponentforpost[]=[];

   getTab4data:tab4details;
   getTab4data_ee:tab4details;



  part_image=''
  interior_image='';
  exterior_image='';
  label_image='';
  shipping_image='';
  sbupl=''


  part_image_ee=''
  interior_image_ee='';
  exterior_image_ee='';
  label_image_ee='';
  shipping_image_ee='';
  sbupl_ee=''



  baseurl_get:any;
  imgsave_path:any='';
  baseurl_get_sbupl:any='';
  image_folder_path=''

  isloadersearchprop=false;

  UOM:string="";

  errorLoadingData:string="";
  
  constructor(private transactionService:TransactionService, private submiterdashboard:SubmiterdashboardComponent,private router:Router,private packproposaltab2:PackproposaltabtwoComponent,private packproposaltab2ee:EepackproposaltabtwoComponent,private improvementpackproposaltab2:ImprovementtabtwoComponent,private improvementpackproposaleetab2:ImprovementeetabtwoComponent,private messageService:MessageService,private confirmationService:ConfirmationService,private date:DatePipe,private improvementpackagingproposal:ImprovementpackagingproposalComponent) { }

  @Input('app-searchexistingproposal') ForCopyCntr:any;

  @Output() settingdialogvalue: EventEmitter<boolean>=new EventEmitter<boolean>();

  /*ngOnChanges(changes: SimpleChanges){
    if(changes.input){
      console.log('input changed',changes.input);
    }
  }*/

  ngOnInit(): void
  {

    // alert("search proposal called"+JSON.stringify(this.ForCopyCntr))
    this.current_region=this.transactionService.getregion();
    this.region_code=this.transactionService.getregioncode(this.current_region);
    this.search_exist_prop_current_roleid=this.transactionService.getcurrentrole(this.current_region);
    this.userid=this.transactionService.getcdsid();

    this.viewOnlyForSupplier="-";
  

    this.showImprovementProposal=false;
    this.showImprovementProposalApiInput='No';

    this.part_image="";
    this.interior_image="";
    this.exterior_image="";
    this.label_image="";
    this.shipping_image="";
    this.sbupl=""
    this.baseurl_get=environment.nfsBaseAPIUrl+"/downloadFile?fPath=";
    this.baseurl_get_sbupl=environment.nfsBaseAPIUrl+"/downloadNonImgFile?fPath="
    this.imgsave_path=environment.nfsBasePath;


    this.selected_expreturn.push({type:'All',typ_b:'all'});
    this.exp_return='all';

    this.cmms_checked = false;

    this.transactionService.get_region_details(this.region_code).subscribe(regionDetls=>{
      var regionDetails=regionDetls;

      if(regionDetails!=null)
      {
        if(regionDetails.uom!=null && regionDetails.uom.trim()!="" && regionDetails.uom.toLowerCase()=="e")
        {
          this.UOM="lbs/inch";
        }
        else
        {
          this.UOM="kg/cm";
        }
      }
    })

    let userType=JSON.parse(sessionStorage.getItem("userType"));

    if(userType!=null && userType.toLowerCase()=="no")
    {
      this.fordPersonLoggedIn=false;
    }
    else
    {
      this.fordPersonLoggedIn=true;
    }
   // this.transactionService.storepage(this.current_region,"packagingprocessdashboard/searchexistingproposal");


    /*  if(this.search_exist_prop_current_roleid==null)
    {
      this.noroleselectedDialog=true;
      this.norolecontent="No role selected "
    }

    if(this.search_exist_prop_current_roleid==1)
    {
      this.transactionService.storepage(this.current_region,"packagingprocessdashboard/searchexistingproposal");
      this.supp_code=this.transactionService.get_supplier(this.current_region);
      console.log(this.supp_code);

      if(this.search_exist_prop_current_roleid==1 && this.supp_code==null)
      {
      this.noSupDialog=true;
      //alert(this.supp_code)
      this.content="Please select supplier";
      }
      else
      {
    */

    if(this.ForCopyCntr==null)
    {
      this.ForCopyCntr={dialogstatus:false,suppliercode:'',id_proposal:0};
    }



    if(this.ForCopyCntr.dialogstatus==true)
    {
      const proposalIdForPartSlctdFrmDashBd=String(this.ForCopyCntr.id_proposal);

      if(sessionStorage.getItem(proposalIdForPartSlctdFrmDashBd)!=null)
      {
       this.selected_proposal=JSON.parse(sessionStorage.getItem(proposalIdForPartSlctdFrmDashBd));
      }
    }

        /* for region dropdown starts */

        if(this.ForCopyCntr.dialogstatus==true)
        {
          this.regionlist.push({label:this.current_region,value:this.current_region})
          this.selected_region_from_list=this.regionlist[0].value;
        }
        else
        {
          const reg_lst=this.transactionService.getuserregion();
          for(let i=0;i<reg_lst.length;i++)
          {
            const regcode=reg_lst[i].toString();
            const regname=this.transactionService.getregionnamebycode(regcode);
            this.regionlist.push({label:regname,value:regname});
          }

          if(this.regionlist.length!=0)
          {
            if(this.regionlist.filter(ep=>ep.value==this.current_region))
            {
              this.selected_region_from_list=this.current_region;
            }

            // alert(this.selected_region_from_list);
          }
        }

        /* for region dropdown ends */

        /*this.transactionService.getsuppdet(this.supp_code,"N").subscribe(supdet=>{
          this.supplierGSDBcode=supdet.supgsdbcode;
          this.supplierfullname=supdet.fullname;
          this.is_supplier=true;
        })*/

        // Supplier Details

        this.loadsupplierdetails();
        /*this.supplierGSDBcode="ABCDE";
        this.supplierfullname="Test Supplier"*
        this.is_supplier=true;*/


        // For Trading Company
        this.loadtradingcompany('all');

        // For part status
        this.transactionService.getpartstatus('approver').subscribe(sub_status=>{
          console.log(sub_status);
          this.statusList=[];
          this.statusList.push({status_id:0,status_desc:'all',status:'All',functions:'searchepro'});
          for(let i=0;i<sub_status.length;i++)
          {
            // if(sub_status[i].status!="Submitted")
            // {
            this.statusList.push(sub_status[i]);
            // }
          }
          this.selectedstatuslist=[];
        });

        // For Supplier country
        this.transactionService.getsupplier_country_list().subscribe(supp_country_list=>{

          const resultsuppcntry=supp_country_list;
          const suppcntrytemp:supp_country[]=[];
          // this.suppliercountryList=supp_country_list;
          if(resultsuppcntry!=null && resultsuppcntry.length!=0)
          {
            suppcntrytemp.push({cd_country:'all',cd_country_iso:'all',country_name:'all',code_name:'ALL'})

            for(let s=0;s<resultsuppcntry.length;s++)
            {
              suppcntrytemp[s+1]=resultsuppcntry[s];
              if(resultsuppcntry[s].cd_country_iso!=null)
              {
                suppcntrytemp[s+1].code_name=resultsuppcntry[s].cd_country_iso+'-'+resultsuppcntry[s].country_name
              }
              else
              {
                suppcntrytemp[s+1].code_name=resultsuppcntry[s].country_name;
              }
            }

            this.selected_suppliercountry=[];
            this.suppliercountryList=suppcntrytemp;
            console.log('countrylist',this.suppliercountryList);

          }
        });

        // For exp/Returnable
        if(this.ForCopyCntr.dialogstatus==true)
        {
        this.storedpart=this.transactionService.getpartno_packpro(this.transactionService.getregion());
          if(this.storedpart.is_ee_proposal && this.storedpart.currentview_ee)
          {
            this.selected_expreturn=[]
            this.selected_expreturn.push({type:'Expendable',typ_b:'Expendable'});
            this.exp_return='Expendable';
          }
          else
          {
            this.selected_expreturn=[]
            this.selected_expreturn.push({type:'All',typ_b:'all'});
            this.exp_return='all';
          }
        }
        else
        {
          this.selected_expreturn=[]

          this.selected_expreturn.push({type:'All',typ_b:'all'});
          this.exp_return='all';
        }

        // For Approval status dropdown

        this.transactionService.getapprovalstatuslist().subscribe(apprstat=>{
          // this.apprvr_status_list=apprstat;
          const result:ApprovalStatus[]=apprstat;
          const apprtemp:ApprovalStatus[]=[];


            apprtemp.push({cd_propsl:'',propsl_stat:'All',propsl_next_stat:'',propsl_stat_desc:''})
            for(let p=0;p<result.length;p++)
            {
              // Removing Submitted/ unsubmitted/from proposal status
              if(result[p].cd_propsl!='1' && result[p].cd_propsl!='2')
              {


                apprtemp.push({cd_propsl:result[p].cd_propsl,propsl_stat:result[p].propsl_stat,propsl_next_stat:result[p].propsl_next_stat,propsl_stat_desc:result[p].propsl_next_stat});

              }
            }

            this.apprvr_status_list=JSON.parse(JSON.stringify(apprtemp));
            if(this.ForCopyCntr.dialogstatus==true)
            {
              this.selected_approval_status=this.apprvr_status_list.filter(ele=>ele.cd_propsl=='10');
              this.approval_status='10';
            }
            else
            {
              this.selected_approval_status=[];
            }


        });

        // For Ownership Strategy Code

        /*if(this.ForCopyCntr.dialogstatus==true)
        {
          //var regforinput=this.transactionService.getregioncode(this.selected_region_from_list);
          this.transactionService.getOwnershipStrategyCode(this.region_code).subscribe(ownrshpstart=>{
            let resultownershpcode:containerOwnershipList[]=ownrshpstart;
            let ownrshpstarttemp:containerOwnershipList[]=[];
            ownrshpstarttemp.push({cd_ownrshp_strat:0,ownrshp_desc:"all",ownrshp_strat_code:"ALL",cd_region:this.region_code});
            for(var k=0;k<resultownershpcode.length;k++)
            {
              ownrshpstarttemp.push({cd_ownrshp_strat:resultownershpcode[k].cd_ownrshp_strat,ownrshp_desc:resultownershpcode[k].ownrshp_desc,ownrshp_strat_code:resultownershpcode[k].ownrshp_strat_code,cd_region:this.region_code});
            }
            this.ownership_start_code_list=ownrshpstarttemp;
            console.log("ownershipstartegycode",this.ownership_start_code_list);
          })
        }
        else
        {*/
          // @ts-ignore
      let regforinput=this.transactionService.getregioncode(this.selected_region_from_list);
          this.transactionService.getOwnershipStrategyCode(regforinput).subscribe(ownrshpstart=>{
            const resultownershpcode:containerOwnershipList[]=ownrshpstart;
            const ownrshpstarttemp:containerOwnershipList[]=[];
            ownrshpstarttemp.push({cd_ownrshp_strat:0,ownrshp_desc:'all',ownrshp_strat_code:'ALL',cd_region:regforinput});
            for(let k=0;k<resultownershpcode.length;k++)
            {
              ownrshpstarttemp.push({cd_ownrshp_strat:resultownershpcode[k].cd_ownrshp_strat,ownrshp_desc:resultownershpcode[k].ownrshp_desc,ownrshp_strat_code:resultownershpcode[k].ownrshp_strat_code,cd_region:regforinput});
            }
            this.ownership_start_code_list=ownrshpstarttemp;
            console.log('ownershipstartegycode',this.ownership_start_code_list);
          })

        // }

        // For plant

        /*if(this.ForCopyCntr.dialogstatus==true)
        {
          this.transactionService.getactiveplantslist(this.region_code).subscribe(plantco=>{

            let resultplant:ActivePlants[]=plantco;
            let plantcodetemp:ActivePlants[]=[];

            plantcodetemp.push({'cd_plant':'0','plantGSDBCode':'ALL','plant_name':'All','plant_type_name':'All'});
            for(var h=0;h<resultplant.length;h++)
            {
              plantcodetemp.push({'cd_plant':resultplant[h].cd_plant,'plantGSDBCode':resultplant[h].plantGSDBCode,'plant_name':resultplant[h].plant_name,'plant_type_name':resultplant[h].plant_type_name})
            }
            this.plantcodelist=plantcodetemp
            this.selected_plant_codes_list=[];
            console.log("plantcodes from backend",this.plantcodelist);
          })
        }
        else
        {*/
          // @ts-ignore
      const regforinput=this.transactionService.getregioncode(this.selected_region_from_list);
          this.transactionService.getactiveplantslist(regforinput).subscribe(plantco=>{

            const resultplant:ActivePlants[]=plantco;
            const plantcodetemp:ActivePlants[]=[];

            //plantcodetemp.push({'cd_plant':'0','plantGSDBCode':'ALL','plant_name':'All','plant_type_name':'All'});
            for(let h=0;h<resultplant.length;h++)
            {
              plantcodetemp.push({cd_plant:resultplant[h].cd_plant,plantGSDBCode:resultplant[h].plantGSDBCode,plant_name:resultplant[h].plant_name,plant_type_name:resultplant[h].plant_type_name})
            }
            this.plantcodelist=plantcodetemp
            this.selected_plant_codes_list=[];
            this.selected_plant_code={
              plant_name: "",
              cd_plant: "",
              plant_type_name: "",
              plantGSDBCode: "",
            
              
            
            }
            console.log("plantcodes from backend",this.plantcodelist);
          })
        // }


     /* }
    }*/
  }

  /*ok_noroledialog()
  {
    var url
    url=this.transactionService.getregionurlvalue(this.current_region);
    url+="packagingprocessdashboard"
    this.router.navigate([url]);
  }

  ok_nosupdialog()
  {
    console.log(this.current_region)
    var reg_url:string
    reg_url=this.transactionService.getregionurlvalue(this.current_region);
    reg_url+="packagingprocess/packsupplieradmin/packsupplier";

    this.router.navigate([reg_url]);
  }*/



/* Code Added for Effective Date*/
effectiveDateFromInput()
{
  if(this.effectiveDateForImprovementProposal!=null)
  {
    this.effectiveDateInString=this.date.transform(this.effectiveDateForImprovementProposal,'yyyy-MM-dd');
    this.effectiveDateInvalidString='';
    // alert(this.effectiveDateInString)
    this.transactionService.updateEffectiveDateForImprovementProposal(this.selectedsimpliedpartnodetails.proposalid,this.effectiveDateInString).subscribe(updEffDateResp=>{
      if(updEffDateResp.toLowerCase()=='success')
      {
        this.messageService.add({severity:'success', summary: 'success', detail: 'Effective Date Updated Successfully'});
      }
      else
      {
        this.messageService.add({severity:'error', summary: 'Error', detail: 'Effective Date not updated! Try Again'});

      }
    });
  }
  else
  {
    this.effectiveDateInString=null;

    this.effectiveDateInvalidString='Required';

  }
}
/* Code Added for Effective Date*/


  on_change_measure(rowvalue:any,indexoftherow:any)
  {

   /*  if(this.unitom == "E")
    {
      rowvalue.uom="lbs/inch";
      this.unitom_rep = "lbs/inch";
    }
    else
    {
      rowvalue.uom=="kg/cm";
      this.unitom_rep = "kg/cm";
    } */

    // if(rowvalue.uom=="lbs/inch")
    // {
    //   if(this.searchpropgrid[indexoftherow].ngpp_part_wt!=null)
    //   {
    //     this.searchpropgrid[indexoftherow].ngpp_part_wt=Math.round((2.20462*this.searchpropgrid[indexoftherow].ngpp_part_wt)*1000)/1000;
    //   }
    //   if(this.searchpropgrid[indexoftherow].shp_unt_gross_wt!=null)
    //   {
    //     this.searchpropgrid[indexoftherow].shp_unt_gross_wt=Math.round((2.20462*this.searchpropgrid[indexoftherow].shp_unt_gross_wt)*1000)/1000;
    //   }
    //   if(this.searchpropgrid[indexoftherow].cntr_gross_wt!=null)
    //   {
    //     this.searchpropgrid[indexoftherow].cntr_gross_wt=Math.round((2.20462*this.searchpropgrid[indexoftherow].cntr_gross_wt)*1000)/1000;
    //   }
    //   if(this.searchpropgrid[indexoftherow].cntr_dim!=null)
    //   {
    //     var indextoGetLengthAndWidth=this.searchpropgrid[indexoftherow].cntr_dim.indexOf('x');
    //     var indextoGetHeight=this.searchpropgrid[indexoftherow].cntr_dim.lastIndexOf('x');

    //     var cntrLength=this.searchpropgrid[indexoftherow].cntr_dim.substring(0,indextoGetLengthAndWidth);
    //     var cntrWidth=this.searchpropgrid[indexoftherow].cntr_dim.substring(indextoGetLengthAndWidth+1,indextoGetHeight);
    //     var cntrHeight=this.searchpropgrid[indexoftherow].cntr_dim.substring(indextoGetHeight+1,this.searchpropgrid[indexoftherow].cntr_dim.length);

    //     this.searchpropgrid[indexoftherow].cntr_dim=Math.round((0.393701*Number(cntrLength))*1000)/1000 + 'x' +
    //                                                 Math.round((0.393701*Number(cntrWidth))*1000)/1000  +'x' +
    //                                                 Math.round((0.393701*Number(cntrHeight))*1000)/1000

    //   }

    //   if(this.searchpropgrid[indexoftherow].shp_unit_dim!=null)
    //   {
    //     var indextoGetLengthAndWidth=this.searchpropgrid[indexoftherow].shp_unit_dim.indexOf('x');
    //     var indextoGetHeight=this.searchpropgrid[indexoftherow].shp_unit_dim.lastIndexOf('x');

    //     var SULength=this.searchpropgrid[indexoftherow].shp_unit_dim.substring(0,indextoGetLengthAndWidth);
    //     var SUWidth=this.searchpropgrid[indexoftherow].shp_unit_dim.substring(indextoGetLengthAndWidth+1,indextoGetHeight);
    //     var SUHeight=this.searchpropgrid[indexoftherow].shp_unit_dim.substring(indextoGetHeight+1,this.searchpropgrid[indexoftherow].shp_unit_dim.length);

    //     this.searchpropgrid[indexoftherow].shp_unit_dim=Math.round((0.393701*Number(SULength))*1000)/1000 + 'x' +
    //                                                 Math.round((0.393701*Number(SUWidth))*1000)/1000  +'x' +
    //                                                 Math.round((0.393701*Number(SUHeight))*1000)/1000

    //   }

    //   if(this.searchpropgrid[indexoftherow].su_per_part!=null)
    //   {
    //     this.searchpropgrid[indexoftherow].su_per_part=Math.round((0.393701*this.searchpropgrid[indexoftherow].su_per_part)*1000)/1000
    //   }
    // }

    // if(rowvalue.uom=="kg/cm")
    // {
    //   if(this.searchpropgrid[indexoftherow].ngpp_part_wt!=null)
    //   {
    //     this.searchpropgrid[indexoftherow].ngpp_part_wt=Math.round((0.453592*this.searchpropgrid[indexoftherow].ngpp_part_wt)*1000)/1000
    //   }
    //   if(this.searchpropgrid[indexoftherow].shp_unt_gross_wt!=null)
    //   {
    //     this.searchpropgrid[indexoftherow].shp_unt_gross_wt=Math.round((0.453592*this.searchpropgrid[indexoftherow].shp_unt_gross_wt)*1000)/1000
    //   }
    //   if(this.searchpropgrid[indexoftherow].cntr_gross_wt!=null)
    //   {
    //     this.searchpropgrid[indexoftherow].cntr_gross_wt=Math.round((0.453592*this.searchpropgrid[indexoftherow].cntr_gross_wt)*1000)/1000
    //   }

    //   if(this.searchpropgrid[indexoftherow].cntr_dim!=null)
    //   {
    //     var indextoGetLengthAndWidth=this.searchpropgrid[indexoftherow].cntr_dim.indexOf('x');
    //     var indextoGetHeight=this.searchpropgrid[indexoftherow].cntr_dim.lastIndexOf('x');

    //     var cntrLength=this.searchpropgrid[indexoftherow].cntr_dim.substring(0,indextoGetLengthAndWidth);
    //     var cntrWidth=this.searchpropgrid[indexoftherow].cntr_dim.substring(indextoGetLengthAndWidth+1,indextoGetHeight);
    //     var cntrHeight=this.searchpropgrid[indexoftherow].cntr_dim.substring(indextoGetHeight+1,this.searchpropgrid[indexoftherow].cntr_dim.length);

    //     this.searchpropgrid[indexoftherow].cntr_dim=Math.round((2.54*Number(cntrLength))*1000)/1000 + 'x' +
    //                                                 Math.round((2.54*Number(cntrWidth))*1000)/1000  +'x' +
    //                                                 Math.round((2.54*Number(cntrHeight))*1000)/1000

    //   }

    //   if(this.searchpropgrid[indexoftherow].shp_unit_dim!=null)
    //   {
    //     var indextoGetLengthAndWidth=this.searchpropgrid[indexoftherow].shp_unit_dim.indexOf('x');
    //     var indextoGetHeight=this.searchpropgrid[indexoftherow].shp_unit_dim.lastIndexOf('x');

    //     var SULength=this.searchpropgrid[indexoftherow].shp_unit_dim.substring(0,indextoGetLengthAndWidth);
    //     var SUWidth=this.searchpropgrid[indexoftherow].shp_unit_dim.substring(indextoGetLengthAndWidth+1,indextoGetHeight);
    //     var SUHeight=this.searchpropgrid[indexoftherow].shp_unit_dim.substring(indextoGetHeight+1,this.searchpropgrid[indexoftherow].shp_unit_dim.length);

    //     this.searchpropgrid[indexoftherow].shp_unit_dim=Math.round((2.54*Number(SULength))*1000)/1000 + 'x' +
    //                                                 Math.round((2.54*Number(SUWidth))*1000)/1000  +'x' +
    //                                                 Math.round((2.54*Number(SUHeight))*1000)/1000

    //   }

    //   if(this.searchpropgrid[indexoftherow].su_per_part!=null)
    //   {
    //     this.searchpropgrid[indexoftherow].su_per_part=Math.round((2.54*this.searchpropgrid[indexoftherow].su_per_part)*1000)/1000
    //   }
    // }
  }


  close_copy_cntr_dialog()
  {
    // alert("emiiting false")
    this.packproposaltab2.copy_cntr_dialog=false;
    this.packproposaltab2ee.copy_cntr_dialog_ee=false;
    this.improvementpackproposaltab2.copy_cntr_dialog=false;
    this.improvementpackproposaleetab2.copy_cntr_dialog_ee=false;
    this.selectedsimpliedpartnodetails.from_draft=true;
    this.store_primary_part();
    this.view_proposal=false;
    // this.settingdialogvalue.emit(false);
    // sessionStorage.setItem('sel_cntr_from_copy',JSON.stringify(this.selected_proposal));
    console.log('selectedproposal',this.selected_proposal);
  }

  proposal_selected_for_copy(proposal:any)
  {
    // alert(JSON.stringify(proposal));
    this.table_selected_cntr_from_copy_dialog=proposal;
    const proposalIdForPartSlctdFrmDashBd=String(this.ForCopyCntr.id_proposal);
    this.selectedsimpliedpartnodetails.from_draft=true;
    this.store_primary_part();
    sessionStorage.setItem(proposalIdForPartSlctdFrmDashBd,JSON.stringify(this.table_selected_cntr_from_copy_dialog));
    this.settingdialogvalue.emit(false);

  }

  go_to_packging_process_home()
  {
    let reg_url:string;
    reg_url=this.transactionService.getregionurlvalue(this.current_region);
    reg_url+='packagingprocessdashboard/packagingprocesshome';
    this.router.navigate([reg_url]);
  }

  loadsupplierdetails()
  {
    if(this.ForCopyCntr.dialogstatus==true)
    {
      this.transactionService.getSupplierListBasedOnUserIdAndRegion(this.userid,this.region_code).subscribe(suplis=>{
        console.log('suppliers',suplis,this.region_code);
        this.supplierList=suplis;
        const ind=this.supplierList.findIndex(e=>e.supcode.trim()==this.ForCopyCntr.suppliercode.trim())
        this.selected_supplier=this.supplierList[ind];

        this.transactionService.getsuppdet(this.selected_supplier.supcode,'N').subscribe(supdetls=>{
          console.log('supdetls',supdetls);
          this.supplierfullname=supdetls.fullname;
          this.suupliercode=supdetls.supgsdbcode;
          this.supplierEntered=supdetls.supgsdbcode;
          this.search();
        })
      })
    }
    else
    {
      const regcode=this.transactionService.getregioncode(this.selected_region_from_list);
      this.transactionService.getSupplierListBasedOnUserIdAndRegion(this.userid,regcode).subscribe(suplis=>{
        console.log('suppliers',suplis,this.region_code);
        this.supplierList=suplis;
      })
    }
  }

  loadtradingcompany(input:any)
  {
   // this.transactionService.gettradingcompany().subscribe(sub_tradcmpy=>{
    this.transactionService.getTradingCompaniesBasedOnPlantGSDBCode(input).subscribe(sub_tradcmpy=>{
      console.log(sub_tradcmpy);
      this.tradCmpyList=[];
      this.tradCmpyList.push({tradingcogsdbcode:'All'});
      this.tradCmpyList.push({tradingcogsdbcode:'[No Trading Company]'})
      for(let trd=0;trd<sub_tradcmpy.length;trd++)
      {
      this.tradCmpyList.push(sub_tradcmpy[trd]);
      }
      console.log('trad',this.tradCmpyList)
      this.selectedtrdcmpy=[];

    });
  }

  onchangeexp_ret()
  {
    this.all_selected_exp_ret=false;
    this.exp_return='';

    /* checking whether length of selected list is less than one */
    let exp_ret_len=false;
    if(this.selected_expreturn.length==this.expreturnList.length-1)
    {
      exp_ret_len=true;
    }

    for(let k=0;k<this.selected_expreturn.length;k++)
    {
      this.exp_return+=this.selected_expreturn[k].type;
      if(k < this.selected_expreturn.length-1)
      {
        this.exp_return+=',';
      }

      if(this.selected_expreturn[k].type=='All')
      {
        this.exp_return='all';
        this.all_selected_exp_ret=true;
        break;
      }
    }

    /* If selectedvariables length is one less than total length but all is not selected
       which means except all remaining (both expendable and returnable are selected) so make
       all_selected_exp_ret as true and make code  send all to backend */

    if(this.all_selected_exp_ret==false && exp_ret_len==true)
    {
      this.exp_return='all';
      this.all_selected_exp_ret=true;
    }
    console.log('exp_return',this.exp_return,typeof(this.exp_return));
  }

  change_exp_return()
  {
    this.exp_return='';
    let all_present=false;
    let lenlessthan1=false;
    if(this.selected_expreturn.length==this.expreturnList.length-1)
    {
      lenlessthan1=true;
    }
    for(let j=0;j<this.selected_expreturn.length;j++)
    {
      if(this.selected_expreturn[j].type=='All')
      {
        all_present=true;
      }
    }

    if(all_present==false && lenlessthan1==true)
    {
      all_present=true;
      this.exp_return='all';
    }

    if(all_present==true)
    {
      this.exp_return='all';
      this.selected_expreturn=[];
      this.selected_expreturn.push({type:'All',typ_b:'all'});
    }
  }

  change_ownrshp_catg()
  {
    let all_present_in_ownr=false;
    let lenlessthan1_ownr_catg=false;
    this.ownrshipcategory='';

    if(this.selected_ownrshp_catg.length==this.ownrship_catg_list.length-1)
    {
      lenlessthan1_ownr_catg=true;
    }
    for(let j=0;j<this.selected_ownrshp_catg.length;j++)
    {
      if(this.selected_ownrshp_catg[j].catg=='All')
      {
        all_present_in_ownr=true;
      }
    }

    if(all_present_in_ownr==false && lenlessthan1_ownr_catg==true)
    {
      all_present_in_ownr=true;
    }

    if(all_present_in_ownr==true)
    {
      this.ownrshipcategory='all';
      this.selected_ownrshp_catg=[];
      this.selected_ownrshp_catg.push({catg:'All',disp:'all'});
    }

  }

  onhide_ownrshp_catg()
  {
    this.all_selected_in_ownershp_catg=false;
    this.ownrshipcategory='';

    /* checking whether length of selected list is less than one */
    let ownrshp_cat_len=false;
    if(this.selected_ownrshp_catg.length==this.ownrship_catg_list.length-1)
    {
      ownrshp_cat_len=true;
    }

    for(let ct=0;ct<this.selected_ownrshp_catg.length;ct++)
    {

      this.ownrshipcategory+=this.selected_ownrshp_catg[ct].catg;
      if(ct < this.selected_ownrshp_catg.length-1)
      {
        this.ownrshipcategory+=',';
      }


      if(this.selected_ownrshp_catg[ct].catg=='All')
      {
        this.all_selected_in_ownershp_catg=true;
        this.ownrshipcategory='all';
        break;
      }
    }

    /* If selectedvariables length is one less than total length but all is not selected
       which means except all remaining (both expendable and returnable are selected) so make
       all_selected_in_ownrshp_catg as true and make code  send all to backend */

    if(this.all_selected_in_ownershp_catg==false && ownrshp_cat_len==true)
    {
      this.all_selected_in_ownershp_catg=true;
      this.ownrshipcategory='all';
    }
    console.log('ownershipcategory',this.ownrshipcategory,typeof(this.ownrshipcategory));
  }


  onhide_apprvl_status()
  {
    this.all_selected_in_approval_status=false;
    this.approval_status='';

    /* checking whether length of selected list is less than one */
    let approval_stat_len=false;
    if(this.selected_approval_status.length==this.apprvr_status_list.length-1)
    {
      approval_stat_len=true;
    }

    for(let apst=0;apst<this.selected_approval_status.length;apst++)
    {
      this.approval_status+=this.selected_approval_status[apst].cd_propsl;
      if(apst < this.selected_approval_status.length-1)
      {
        this.approval_status+=',';
      }

      if(this.selected_approval_status[apst].cd_propsl=='')
      {
        this.all_selected_in_approval_status=true;
        this.approval_status='all';
        break;
      }
    }

    /* If selectedvariables length is one less than total length but all is not selected
       which means except all remaining (both expendable and returnable are selected) so make
       all_selected_in_ownrshp_catg as true and make code  send all to backend */

    if(this.all_selected_in_approval_status==false && approval_stat_len==true)
    {
      this.approval_status='all';
      this.all_selected_in_approval_status=true;
    }
    console.log('Approval Status',this.approval_status,typeof(this.approval_status));

  }

  change_apprvl_status()
  {
    let all_present_in_apprvr_stat=false;
    let lenlessthan1_apprvr_stat=false;
    this.approval_status='';

    if(this.selected_approval_status.length==this.apprvr_status_list.length-1)
    {
      lenlessthan1_apprvr_stat=true;
    }
    for(let j=0;j<this.selected_approval_status.length;j++)
    {
      if(this.selected_approval_status[j].cd_propsl=='')
      {
        all_present_in_apprvr_stat=true;
      }
    }

    if(all_present_in_apprvr_stat==false && lenlessthan1_apprvr_stat==true)
    {
      all_present_in_apprvr_stat=true;
    }

    if(all_present_in_apprvr_stat==true)
    {
      this.approval_status='all';
      this.selected_approval_status=[];
      this.selected_approval_status.push({cd_propsl:'',propsl_stat:'All',propsl_next_stat:'',propsl_stat_desc:''});
    }

  }

  onhidesuppcountry()
  {
    this.all_selected_supp_cntry=false;
    this.suppliercountry='';

    /* checking whether length of selected list is less than one */
    let suppcntrylen=false;
    if(this.selected_suppliercountry.length==this.suppliercountryList.length-1)
    {
      suppcntrylen=true;
    }

    for(let spct=0;spct<this.selected_suppliercountry.length;spct++)
    {
      this.suppliercountry+=this.selected_suppliercountry[spct].cd_country;
      if(spct < this.selected_suppliercountry.length-1)
      {
        this.suppliercountry+=',';
      }

      if(this.selected_suppliercountry[spct].code_name=='ALL')
      {
        this.all_selected_supp_cntry=true;
        this.suppliercountry='all';
        break;
      }
    }

    /* If selectedvariables length is one less than total length but all is not selected
       which means except all remaining (both expendable and returnable are selected) so make
       all_selected_in_ownrshp_catg as true and make code  send all to backend */

    if(this.all_selected_supp_cntry==false && suppcntrylen==true)
    {
      this.suppliercountry='all';
      this.all_selected_supp_cntry=true;
    }
    console.log('Supplier Country',this.suppliercountry,typeof(this.suppliercountry));

  }

  change_supp_cntry()
  {
    let all_present_in_supp_cntry=false;
    let lenlessthan1_supp_cntry=false;
    this.suppliercountry='';

    if(this.selected_suppliercountry.length==this.suppliercountryList.length-1)
    {
      lenlessthan1_supp_cntry=true;
    }
    for(let j=0;j<this.selected_suppliercountry.length;j++)
    {
      if(this.selected_suppliercountry[j].code_name=='ALL')
      {
        all_present_in_supp_cntry=true;
      }
    }

    if(all_present_in_supp_cntry==false && lenlessthan1_supp_cntry==true)
    {
      all_present_in_supp_cntry=true;
    }

    if(all_present_in_supp_cntry==true)
    {
      this.suppliercountry='all';
      this.selected_suppliercountry=[];
      this.selected_suppliercountry.push({cd_country:'all',cd_country_iso:'all',country_name:'all',code_name:'ALL'});
    }
  }

  onhidepartstatus()
  {
    this.all_selected_part_status=false;
    this.part_status='';
    /* checking whether length of selected list is less than one */
    let partstatuslen=false;
    if(this.selectedstatuslist.length==this.statusList.length-1)
    {
      partstatuslen=true;
    }

    for(let prtst=0;prtst<this.selectedstatuslist.length;prtst++)
    {
      this.part_status+=this.selectedstatuslist[prtst].status;
      if(prtst < this.selectedstatuslist.length-1)
      {
        this.part_status+=',';
      }

      if(this.selectedstatuslist[prtst].status=='All')
      {
        this.all_selected_part_status=true;
        this.part_status='all';
        break;
      }
    }

    /* If selectedvariables length is one less than total length but all is not selected
       which means except all remaining (both expendable and returnable are selected) so make
       all_selected_in_ownrshp_catg as true and make code  send all to backend */

    if(this.all_selected_part_status==false && partstatuslen==true)
    {
      this.part_status='all';
      this.all_selected_part_status=true;
    }
    console.log('Part Status',this.part_status,typeof(this.part_status));

  }

  changepartstatus()
  {
    let all_selected_part_status=false;
    let lenlessthan1_prt_status=false;
    this.part_status='';

    if(this.selectedstatuslist.length==this.statusList.length-1)
    {
      lenlessthan1_prt_status=true;
    }
    for(let j=0;j<this.selectedstatuslist.length;j++)
    {
      if(this.selectedstatuslist[j].status=='All')
      {
        all_selected_part_status=true;
      }
    }

    if(all_selected_part_status==false && lenlessthan1_prt_status==true)
    {
      all_selected_part_status=true;
    }

    if(all_selected_part_status==true)
    {
      this.part_status='all';
      this.selectedstatuslist=[];
      this.selectedstatuslist.push({status_id:0,status_desc:'all',status:'All',functions:'searchepro'});
    }
  }

  onhidetradcomp()
  {
    this.all_selected_trad_comp=false;
    this.trdcmpy='';
    /* checking whether length of selected list is less than one */
    let tradcomplen=false;
    if(this.selectedtrdcmpy.length==this.tradCmpyList.length-1)
    {
      tradcomplen=true;
    }

    for(let trct=0;trct<this.selectedtrdcmpy.length;trct++)
    {
      if(this.selectedtrdcmpy[trct].tradingcogsdbcode=='[No Trading Company]')
      {
        this.trdcmpy+='notradco'
      }
      else
      {
        this.trdcmpy+=this.selectedtrdcmpy[trct].tradingcogsdbcode;
      }
      if (trct < this.selectedtrdcmpy.length-1)
      {
        this.trdcmpy+=',';
      }

      if(this.selectedtrdcmpy[trct].tradingcogsdbcode=='All')
      {
        this.all_selected_trad_comp=true;
        this.trdcmpy='all';
        break;
      }
    }

    /* If selectedvariables length is one less than total length but all is not selected
       which means except all remaining (both expendable and returnable are selected) so make
       all_selected_in_ownrshp_catg as true and make code  send all to backend */

    if(this.all_selected_trad_comp==false && tradcomplen==true)
    {
      this.trdcmpy='all';
      this.all_selected_trad_comp=true;
    }
    console.log('Trading Company',this.trdcmpy,typeof(this.trdcmpy));

  }

  chnagetradcomp()
  {
    let all_selected_trad_comp=false;
    let lenlessthan1_trad_cmp=false;
    this.trdcmpy='';

    if(this.selectedtrdcmpy.length==this.tradCmpyList.length-1)
    {
      lenlessthan1_trad_cmp=true;
    }
    for(let j=0;j<this.selectedtrdcmpy.length;j++)
    {
      if(this.selectedtrdcmpy[j].tradingcogsdbcode=='All')
      {
        all_selected_trad_comp=true;
      }
    }

    if(all_selected_trad_comp==false && lenlessthan1_trad_cmp==true)
    {
      all_selected_trad_comp=true;
    }

    if(all_selected_trad_comp==true)
    {
      this.trdcmpy='all';
      this.selectedtrdcmpy=[];
      this.selectedtrdcmpy.push({tradingcogsdbcode:'All'});
    }
  }

  onhide_ownrshp_start()
  {
    this.all_selected_on_ownrshp_start_code=false;
    this.ownrshp_start_code='';
    /* checking whether length of selected list is less than one */
    let ownrshplen=false;
    if(this.selected_ownershp_start_code.length==this.ownership_start_code_list.length-1)
    {
      ownrshplen=true;
    }

    for(let ownt=0;ownt<this.selected_ownershp_start_code.length;ownt++)
    {
      this.ownrshp_start_code+=this.selected_ownershp_start_code[ownt].ownrshp_strat_code;
      if(ownt < this.selected_ownershp_start_code.length-1)
      {
        this.ownrshp_start_code+=',';
      }

      if(this.selected_ownershp_start_code[ownt].ownrshp_strat_code=='ALL')
      {
        this.all_selected_on_ownrshp_start_code=true;
        this.ownrshp_start_code='all';
        break;
      }
    }

    /* If selectedvariables length is one less than total length but all is not selected
       which means except all remaining (both expendable and returnable are selected) so make
       all_selected_in_ownrshp_catg as true and make code  send all to backend */

    if(this.all_selected_on_ownrshp_start_code==false && ownrshplen==true)
    {
      this.ownrshp_start_code='all';
      this.all_selected_on_ownrshp_start_code=true;
    }
    console.log('Ownership Strategy code in Hide',this.ownrshp_start_code,typeof(this.ownrshp_start_code));

  }

  change_ownrshp_strat()
  {
    let all_selected_on_ownrshp_start_code=false;
    let lenlessthan1_ownrshp_start_code=false;
    this.ownrshp_start_code='';

    if(this.selected_ownershp_start_code.length==this.ownership_start_code_list.length-1)
    {
      lenlessthan1_ownrshp_start_code=true;
    }
    for(let j=0;j<this.selected_ownershp_start_code.length;j++)
    {
      if(this.selected_ownershp_start_code[j].ownrshp_strat_code=='ALL')
      {
        all_selected_on_ownrshp_start_code=true;
      }
    }

    if(all_selected_on_ownrshp_start_code==false && lenlessthan1_ownrshp_start_code==true)
    {
      all_selected_on_ownrshp_start_code=true;
    }

    if(all_selected_on_ownrshp_start_code==true)
    {
      this.ownrshp_start_code='all';
      this.selected_ownershp_start_code=[];
      const regionselected=this.transactionService.getregioncode(this.selected_region_from_list);
      this.selected_ownershp_start_code.push({cd_region:regionselected,ownrshp_strat_code:'ALL',ownrshp_desc:'all',cd_ownrshp_strat:0});
    }
  }

  /*onhide_plant_codes()
  {
    this.all_selected_on_plant_code=false;
    this.plantcode='';

    // checking whether length of selected list is less than one 
    var plantslen:boolean=false;
    if(this.selected_plant_codes_list.length==this.plantcodelist.length-1)
    {
      plantslen=true;
    }

    for(let ptc=0;ptc<this.selected_plant_codes_list.length;ptc++)
    {
      this.plantcode+=this.selected_plant_codes_list[ptc].plantGSDBCode;
      if(ptc < this.selected_plant_codes_list.length-1)
      {
        this.plantcode+=',';
      }

      if(this.selected_plant_codes_list[ptc].plantGSDBCode=='ALL')
      {
        this.all_selected_on_plant_code=true;
        this.plantcode='all';
        break;
      }
    }

    // If selectedvariables length is one less than total length but all is not selected
    //   which means except all remaining are selected so make 
     //  all_selected_on_plant_code as true and make code  send all to backend 

    if(this.all_selected_on_plant_code==false && plantslen==true)
    {
      this.plantcode='all';
      this.all_selected_on_plant_code=true;
    }
    console.log('plant codes in Hide',this.plantcode,typeof(this.plantcode));

    if(this.plantcode.length>0)
    {
      this.plantcodeselected=true;
      this.loadprogramcodes();
      this.loadtradingcompany(this.plantcode);
      console.log('plantcode selected is true')
    }
    else
    {
      this.plantcodeselected=false;
      this.programsList=[];
      this.selected_programs_list=[];
      this.programListalongwithPlants=[];
      this.programcodes='';
      this.loadtradingcompany('all');
    }
  }

  change_plant_codes()
  {
    let all_selected_on_plant_code=false;
    let lenlessthan1_plant_code=false;
    this.plantcode='';

    // When plants is changed programs variable shd be emptied and
    // when click outside after selecting plants,these variables will have loaded data
    this.programsList=[];
    this.selected_programs_list=[];
    this.programcodes='';
    this.programListalongwithPlants=[];


    if(this.selected_plant_codes_list.length==this.plantcodelist.length-1)
    {
      lenlessthan1_plant_code=true;
    }
    for(let j=0;j<this.selected_plant_codes_list.length;j++)
    {
      if(this.selected_plant_codes_list[j].plantGSDBCode=='ALL')
      {
        all_selected_on_plant_code=true;
      }
    }

    if(all_selected_on_plant_code==false && lenlessthan1_plant_code==true)
    {
      all_selected_on_plant_code=true;
    }

    if(all_selected_on_plant_code==true)
    {
      this.plantcode='all';
      this.selected_plant_codes_list=[];
      this.selected_plant_codes_list.push({cd_plant:'0',plantGSDBCode:'ALL',plant_name:'All',plant_type_name:'All'});
    }
  }*/

  plantCodeChanged()
  {
    this.plantcodeselected=true;
    this.programsList=[];
    this.selected_programs_list=[];
    this.programcodes="";
    this.programListalongwithPlants=[];
    
    this.plantcode=this.selected_plant_code.plantGSDBCode;
    this.loadprogramcodes();
    this.loadtradingcompany(this.plantcode)
  }

  loadprogramcodes()
  {
    const tempobj:programsbasedonplantsforsearchprop[]=[];

    // selected_region_from_list will have correct region even when the component is called from any parent
    // because selected_region_from_list will be set as current region when this component is called for copy container dialog

    const regval=this.transactionService.getregioncode(this.selected_region_from_list);
    this.transactionService.getprogmlistbasedonplantgsdb(this.plantcode,regval).subscribe(progs=>{
      this.programsList=progs;
      if(this.programsList.length>0)
      {
        tempobj.push({cd_region:regval,plantgsdbcode:'',programname:'All',programnamewithplant:'All'});
      }
      for(let l=0;l<this.programsList.length;l++)
      {
        let programwasalreadypresent=false;
        if(tempobj.length!=0)
        {
          if(tempobj.some(o=>o.programname.toLowerCase() == this.programsList[l].programname.toLowerCase()))
          {
            const index=tempobj.findIndex(object=>{return object.programname.toLowerCase()==this.programsList[l].programname.toLowerCase()});
            const newvalue=tempobj[index].programnamewithplant.slice(0,tempobj[index].programnamewithplant.length-1) ;
            tempobj[index].programnamewithplant=newvalue+', '+this.programsList[l].plantgsdbcode+')';
            programwasalreadypresent=true;
          }
        }
        if(!programwasalreadypresent)
        {
          tempobj.push({programname:this.programsList[l].programname,programnamewithplant:this.programsList[l].programname+' ('+this.programsList[l].plantgsdbcode+')',plantgsdbcode:this.programsList[l].plantgsdbcode,cd_region:this.programsList[l].cd_region});
        }

      }
      this.programListalongwithPlants=JSON.parse(JSON.stringify(tempobj));

      console.log('programList',this.programsList)
      console.log('programListalongwithPlants',this.programListalongwithPlants);
    })
  }

  onhide_program_codes()
  {
    this.all_selected_on_program=false;
    this.programcodes='';

    /* checking whether length of selected list is less than one */
    let prglen=false;
    if(this.selected_programs_list.length==this.programListalongwithPlants.length-1)
    {
      prglen=true;
    }

    for(let prgi=0;prgi<this.selected_programs_list.length;prgi++)
    {
      this.programcodes+=this.selected_programs_list[prgi].programname;
      if(prgi < this.selected_programs_list.length-1)
      {
        this.programcodes+=',';
      }

      if(this.selected_programs_list[prgi].programname=='All')
      {
        this.all_selected_on_program=true;
        this.programcodes='all';
        break;
      }
    }

    /* If selectedvariables length is one less than total length but all is not selected
       which means except all remaining are selected so make
       all_selected_on_program as true and make code  send all to backend */

    if(this.all_selected_on_program==false && prglen==true)
    {
      this.programcodes='all';
      this.all_selected_on_program=true;
    }
    console.log('program codes in Hide',this.programcodes,typeof(this.programcodes));

  }

  change_prgm_codes()
  {
    let all_selected_on_program=false;
    let lenlessthan1_prg_code=false;
    this.programcodes='';

    if(this.selected_programs_list.length==this.programListalongwithPlants.length-1)
    {
      lenlessthan1_prg_code=true;
    }
    for(let j=0;j<this.selected_programs_list.length;j++)
    {
      if(this.selected_programs_list[j].programname=='All')
      {
        all_selected_on_program=true;
      }
    }

    if(all_selected_on_program==false && lenlessthan1_prg_code==true)
    {
      all_selected_on_program=true;
    }

    if(all_selected_on_program==true)
    {
      this.programcodes='all';
      this.selected_programs_list=[];
      const regval=this.transactionService.getregioncode(this.selected_region_from_list);
      this.selected_programs_list.push({cd_region:regval,plantgsdbcode:'',programname:'All',programnamewithplant:'All'});
    }
  }

  regionchanged()
  {
    var newregion=this.transactionService.getregioncode(this.selected_region_from_list);
  
    this.transactionService.get_region_details(newregion).subscribe(regionDetls=>{
      var regionDetails=regionDetls;

      if(regionDetails!=null)
      {
        if(regionDetails.uom!=null && regionDetails.uom.trim()!="" && regionDetails.uom.toLowerCase()=="e")
        {
          this.UOM="lbs/inch";
        }
        else
        {
          this.UOM="kg/cm";
        }
      }
    })

    this.transactionService.getOwnershipStrategyCode(newregion).subscribe(newownrshpstrat=>{
      const resu_ownrshp_code=newownrshpstrat;
      const temp_new_ownrshpstrat:containerOwnershipList[]=[];
      temp_new_ownrshpstrat.push({cd_region:newregion,ownrshp_desc:'all',ownrshp_strat_code:'ALL',cd_ownrshp_strat:0});

      for ( const i of resu_ownrshp_code)
      {
        temp_new_ownrshpstrat.push({cd_region:newregion,ownrshp_desc:i.ownrshp_desc,ownrshp_strat_code:i.ownrshp_strat_code,cd_ownrshp_strat:i.cd_ownrshp_strat});

      }
      this.ownership_start_code_list=JSON.parse(JSON.stringify(temp_new_ownrshpstrat));
      this.selected_ownershp_start_code=[];
      this.ownrshp_start_code='';
    })

     // When region changes plant and program list shd be made empty

    this.transactionService.getactiveplantslist(newregion).subscribe(newplants=>{
      const res_new_plants=newplants;
      const temp_new_plant:ActivePlants[]=[];
      temp_new_plant.push({cd_plant:'0',plantGSDBCode:'ALL',plant_name:'All',plant_type_name:'All'});

      for(const j of res_new_plants)
      {
        temp_new_plant.push({cd_plant:j.cd_plant,plantGSDBCode:j.plantGSDBCode,plant_name:j.plant_name,plant_type_name:j.plant_type_name});
      }
      this.plantcodelist=JSON.parse(JSON.stringify(temp_new_plant));
      this.selected_plant_codes_list=[];
      this.selected_plant_code={
        plant_name: "",
        cd_plant: "",
        plant_type_name: "",
        plantGSDBCode: "",
      
        
      
      }
      this.plantcode="";
      this.programListalongwithPlants=[];
      this.programsList=[];
      this.selected_programs_list=[];
      this.programcodes='';
      this.plantcodeselected=false;
      this.loadtradingcompany('all');

    })

    // when region changed suppliers list changes
    this.supplierfullname='';
    this.selected_supplier={supcode:''};
    this.supplierEntered="";
    this.suupliercode="";
    this.loadsupplierdetails();
  }

  supplierchanged()
  {
    
    if(this.fordPersonLoggedIn!=null && this.fordPersonLoggedIn==true)
    {
      // Ford user
      if(this.supplierEntered!=null && this.supplierEntered.trim()!='')
      {
        this.transactionService.getsuppdet(this.supplierEntered.toUpperCase(),"N").subscribe(supdet=>{
          if(supdet!=null)
          {
            this.supplierfullname=supdet.fullname;
            this.suupliercode=this.selected_supplier.supcode;
          }
          else
          {
            this.messageService.add({severity:'error', summary: 'Error', detail: 'Not a Valid Supplier GSDB Code'});
          }
          
        })
      }
      else{
        
         this.suupliercode="";
        this.supplierEntered="";
        this.supplierfullname="";
       
      }
      
    }
    else
    {
      // Since the user is Supplier, restricting to dropdown with the list of suppliers they have access
      this.transactionService.getsuppdet(this.selected_supplier.supcode,"N").subscribe(supdet=>{
        this.supplierfullname=supdet.fullname;
        this.suupliercode=this.selected_supplier.supcode;
      })
    }
    
    
   
  }

  partDescriptionRemoveSpecialCharecter()
  {
    this.selectedpartdesc=this.selectedpartdesc.replace(/[^a-zA-Z0-9-_ ]/g,'');
  }

  SupplierRemoveSpecialCharecter()
  {
    this.supplierEntered=this.supplierEntered.replace(/[^a-zA-Z0-9 ]/g,'');
  }

  clearFilters()
  {
    this.selectedpartprefix="";
    this.selectedpartbase="";
    this.selectedpartsuffix="";
    this.selectedpartdesc="";
    
    this.errorLoadingData = "";
    
    this.selected_suppliercountry=[];
    this.suppliercountry="";
    this.selectedcntrbase="";
    this.selectedcntrsuffix="";
    this.selected_plant_codes_list=[];
    this.selected_plant_code={
      plant_name: "",
      cd_plant: "",
      plant_type_name: "",
      plantGSDBCode: "",
    
      
    
    }
    this.plantcode="";
    this.selected_programs_list=[];
    this.programcodes='';
    this.plantcodeselected=false;
    this.selectedtrdcmpy=[];
    this.trdcmpy='';
    this.showImprovementProposal=false;
    this.showImprovementProposalApiInput='No';

    this.search_result="";

    if(!(this.ForCopyCntr.dialogstatus==true && this.storedpart.is_ee_proposal && this.storedpart.currentview_ee))
    {
      this.selected_expreturn=[];
      this.selected_expreturn.push(this.expreturnList[0]);
      this.exp_return='';
    }
    if(this.ForCopyCntr.dialogstatus!=true)
    {
      this.selected_approval_status=[];
      this.approval_status='';

    }

    this.selectedstatuslist=[];
    this.part_status='';

    this.selected_ownrshp_catg=[];
    this.ownrshipcategory='';


    this.selected_ownershp_start_code=[];
    this.ownrshp_start_code='';

    if(this.ForCopyCntr.dialogstatus!=true)
    {
    this.selected_approval_status=[];
    this.approval_status='';
    this.selected_supplier={supcode:''};
    this.supplierEntered="";
    this.suupliercode=""
    this.supplierfullname="";
    this.regionchanged();
    this.searchpropgrid=[];
    }
    else
    {
      
      this.loadtradingcompany("all");

    const ind=this.supplierList.findIndex(e=>e.supcode.trim()==this.ForCopyCntr.suppliercode.trim())
    this.selected_supplier=this.supplierList[ind];

    this.transactionService.getsuppdet(this.selected_supplier.supcode,'N').subscribe(supdetls=>{
      console.log('supdetls',supdetls);
      this.supplierfullname=supdetls.fullname;
      this.supplierEntered=supdetls.supgsdbcode
      this.suupliercode=supdetls.supgsdbcode;

      this.search();
    })
  }
    
  
  }

  search()
  {
    this.errorLoadingData="";
    this.isloadersearchprop=true;
    this.search_result="";
    this.searchpropgrid=[];
    this.search_result="";
    console.log("Supplier code",this.supp_code);
    console.log(this.selectedpartprefix);
    console.log(this.selectedpartbase);
    console.log(this.selectedpartsuffix);

    if(this.selectedpartprefix!=null && this.selectedpartprefix!='')
    {
      this.part_prefix=this.selectedpartprefix

    }
    else{
      this.part_prefix='all'
    }

    if(this.selectedpartbase!=null && this.selectedpartbase!='')
    {
      this.part_base=this.selectedpartbase

    }
    else{
      this.part_base='all'
    }

    if(this.selectedpartsuffix!=null && this.selectedpartsuffix!='')
    {
      this.part_suffix=this.selectedpartsuffix

    }
    else{
      this.part_suffix='all'
    }

    if(this.selectedpartdesc!=null && this.selectedpartdesc!='')
    {
      this.part_desc=this.selectedpartdesc

    }
    else{
      this.part_desc='all'
    }

    if(this.selectedcntrbase!=null && this.selectedcntrbase!='')
    {
      this.cntr_base=this.selectedcntrbase
    }
    else
    {
      this.cntr_base='all'
    }

    if(this.selectedcntrsuffix!=null && this.selectedcntrsuffix!='')
    {
      this.cntr_suffix=this.selectedcntrsuffix
    }
    else
    {
      this.cntr_suffix='all'
    }

    // console.log("UOMLOOP")
    // if(this.unitom == "E")
    // {
    //   console.log("UOM1702")
    //   for(var i=0;i<2;i++)
    //   {
    //     console.log("UOM1705");
    //   this.searchpropgrid[i].uom == "lb/inch";
    //   console.log("UOMLG/IN",this.unitom,this.searchpropgrid[i].uom);
    //   }
    // }
    // else
    // {
    //   console.log("UOMWHY");
    //   for(var i=0;i<2;i++)
    //   {
    //     console.log("UOM1715")
    //   this.searchpropgrid[i].uom == "kg/cm";
    //   console.log("UOMKG/CM",this.unitom,this.searchpropgrid[i].uom);
    //   }
    // }

     /* trading company
     if(this.selectedtrdcmpy.length!=0)
     {
             if(this.selectedtrdcmpy.length==this.tradCmpyList.length)
             {
               this.trdcmpy="all";
             }
             else
             {
               this.trdcmpy="";
               for(var p_trdcmpy=0;p_trdcmpy<this.selectedtrdcmpy.length;p_trdcmpy++)
               {
                 if(this.selectedtrdcmpy[p_trdcmpy].tradingcogsdbcode=="[No Trading Company]")
                 {
                   this.trdcmpy+="notradco"
                 }
                 else
                 {
                  this.trdcmpy+=this.selectedtrdcmpy[p_trdcmpy].tradingcogsdbcode
                 }
                  if(p_trdcmpy<(this.selectedtrdcmpy.length-1))
                  {
                    this.trdcmpy+=",";
                  }
               }
             }
               console.log(this.trdcmpy);
     }
     else
     {
       this.trdcmpy="all"
     }*/


    /*For part status
    if(this.selectedstatuslist.length!=0)
    {
        if(this.selectedstatuslist.length==this.statusList.length)
        {
          this.status="all"
        }
        else
        {
          this.status="";
          for(var p_sts=0;p_sts<this.selectedstatuslist.length;p_sts++)
          {
             this.status+=this.selectedstatuslist[p_sts].status;
             if(p_sts<this.selectedstatuslist.length-1)
             {
               this.status+=",";
             }
          }
        }
        console.log(this.status);
    }
    else
    {
      this.status="all"
    }*/

    /* Supplier Country
    if(this.selected_suppliercountry.length!=0)
    {
      if(this.selected_suppliercountry.length==this.suppliercountryList.length)
      {
        this.suppliercountry="all";
      }
      else
      {
        this.suppliercountry="";
        var supp_count=0;
        console.log(this.selected_suppliercountry)
        for(var p_sts=0;p_sts<this.selected_suppliercountry.length;p_sts++)
        {
           this.suppliercountry+=this.selected_suppliercountry[p_sts].cd_country
           supp_count+=1;
           if(supp_count!=this.selected_suppliercountry.length)
           {
             this.suppliercountry+=",";
           }
        }
      }
      console.log("Supplier Country in search",this.suppliercountry);
    }
    else
    {
      this.suppliercountry="all";
    }*/

    // supplier code and name

  //  if(this.suupliercode=="")

    if(this.fordPersonLoggedIn)
    {
      if(this.supplierEntered==null || (this.supplierEntered!=null && this.supplierEntered.trim()==""))
      {
        this.part_sup_code="all";
        this.part_sup_name="all";
      }
      else{
        this.part_sup_code=this.supplierEntered.toUpperCase();

        // setting this fullname to all because when user is not able to go out of focus after entering supplier gsdb code and directly gives search(), we will not have supplier name loaded at that time, so sending all
        if(this.supplierfullname==null || this.supplierfullname.trim()=='')
        {
          this.part_sup_name="all";
        }
        else
        {
          this.part_sup_name=this.supplierfullname;
        }
      }
    }
    else
    {
      if(this.suupliercode=="")
      {
        this.part_sup_code="all";
        this.part_sup_name="all";
      }
      else{
        this.part_sup_code=this.suupliercode;
        this.part_sup_name=this.supplierfullname;
      }
    }

    // Trading company

    if(this.trdcmpy=='' )// || this.trdcmpy=="all" )
    {
      this.part_trad_cmp='all'
    }
    else
    {
      this.part_trad_cmp=this.trdcmpy;
    }

    // part status
    if(this.part_status=='')// || this.part_status=="all")
    {
      this.part_status_grid_inp='all';
    }
    else
    {
      this.part_status_grid_inp=this.part_status;
    }

    // Supplier country
    if(this.suppliercountry=='' )// || this.suppliercountry=="all")
    {
      this.part_sp_cntry='all';
    }
    else{
      this.part_sp_cntry=this.suppliercountry;
    }


    // ownershp catg
    if(this.ownrshipcategory=='')// || this.ownrshipcategory=="all")
    {
      this.part_own_cat='all';
    }
    else{
      this.part_own_cat=this.ownrshipcategory
    }

    // approval status
    if(this.approval_status=='')// || this.approval_status=="all")
    {
      this.apprv_stat_grid_inp='all';
    }
    else{
      this.apprv_stat_grid_inp=this.approval_status;
    }

    // ownership strategy code
    if(this.ownrshp_start_code=='' )// || this.ownrshp_start_code=="all")
    {
      this.part_cos_grid_inp='all';
    }
    else{
      this.part_cos_grid_inp=this.ownrshp_start_code.trim()
    }

    // plant

    if(this.plantcode=='' )// || this.plantcode=="all")
    {
      this.part_plant_code='all';
    }
    else{
      this.part_plant_code=this.plantcode;
    }

    // programcode

    if(this.programcodes=='')// || this.programcodes=="all")
    {
      this.part_prg_code='all';
    }
    else{
      this.part_prg_code=this.programcodes;
    }



    if(this.exp_return=='')
    {
      this.exp_return='all';
    }
    else
    {
      this.exp_return=this.exp_return
    }

    if(this.showImprovementProposal)
    {
      this.showImprovementProposalApiInput='Yes';
    }
    else
    {
      this.showImprovementProposalApiInput='No';
    }

    console.log('region in grid',this.transactionService.getregioncode(this.selected_region_from_list));
    console.log('prefix in grid',this.part_prefix);
    console.log('base in grid',this.part_base);
    console.log('suffix in grid',this.part_suffix);
    console.log('cntr base in grid',this.cntr_base);
    console.log('cntr suffix in grid',this.cntr_suffix);
    console.log('desc in grid',this.part_desc);
    console.log('supploercode in grid',this.part_sup_code);
    console.log('suuplier name in grid',this.part_sup_name);
    console.log('approval status in grid',this.apprv_stat_grid_inp);
    console.log('trading company in grid',this.part_trad_cmp);
    console.log('part status in grid',this.part_status_grid_inp);
    console.log('suppler country in grid',this.part_sp_cntry)
    console.log('exp_returnable in grid',this.exp_return);
    console.log('ownershpcatg in grid',this.part_own_cat);
    console.log('plant code in grid',this.part_plant_code);
    console.log('program code in grid',this.part_prg_code);
    console.log('ownership strategy code',this.part_cos_grid_inp);

    this.viewOnlyForSupplier="-";
    let isFordUser=JSON.parse(sessionStorage.getItem("userType"));

    if(isFordUser!=null && isFordUser.toLowerCase()=="no")
    {
      this.transactionService.getMyUserAccessRoles(this.transactionService.getcdsid()).subscribe(accessdetls=>{
        this.userAccessGrid=[];
        this.userAccessGrid=accessdetls;

        console.log(this.selected_region_from_list);
        let regionCode=this.transactionService.getregioncode(this.selected_region_from_list);
        let index=this.userAccessGrid.findIndex(e=>e.cd_region.toLowerCase()==regionCode.toLowerCase() && e.role_id==99);

        if(index!=-1)
        {
          this.viewOnlyForSupplier=this.userAccessGrid[index].cd_supplier;
          this.searchProposalGrid();
        }
        else
        {
          this.viewOnlyForSupplier="-";
          this.searchProposalGrid();
        }

        
      })
    }
    else
    {
      this.viewOnlyForSupplier="-"
      this.searchProposalGrid();
    }
    
  }

  searchProposalGrid()
  {
  
    this.transactionService.getSearchProposalGridDetails(this.transactionService.getregioncode(this.selected_region_from_list),this.apprv_stat_grid_inp,this.part_prefix,this.part_base,this.part_suffix,this.part_desc,

    this.part_status_grid_inp,this.part_sup_code,this.part_sup_name,this.part_plant_code,this.part_prg_code,this.part_cos_grid_inp,
    this.part_own_cat,this.cntr_base,this.cntr_suffix,this.exp_return,this.part_sp_cntry,this.part_trad_cmp,this.userid,this.showImprovementProposalApiInput,this.viewOnlyForSupplier).subscribe(griddetls=>{
    var temporaryObj:searchProposalGrid[]=[];

      // console.log("Grid output",searchpropgridfromapi);
      this.searchpropgridfromapi=JSON.parse(JSON.stringify(griddetls));
      var grid_display_part_status="";
      console.log("searchpropgridfromapi",this.searchpropgridfromapi);
      var proposalsNotMatchpropostat=[];
        for (var gd=0;gd<this.searchpropgridfromapi.length;gd++)
        {
          console.log("outputtesting",this.searchpropgridfromapi[gd]);
          var apprstatIndex=this.apprvr_status_list.findIndex(p=>Number(p.cd_propsl)==Number(this.searchpropgridfromapi[gd].proposal_status_curr))
          
          if(apprstatIndex ==-1)
          {
            proposalsNotMatchpropostat.push(this.searchpropgridfromapi[gd]);
            continue;
          }
        
       
          grid_display_part_status="";

          if(this.searchpropgridfromapi[gd].propsl_type != null && this.searchpropgridfromapi[gd].propsl_type.toLowerCase() == 'improvement')
          {
            grid_display_part_status+=this.searchpropgridfromapi[gd].imp_ngpp_part_status;
          }
          else
          {
            grid_display_part_status+=this.searchpropgridfromapi[gd].partstatus;

            if(this.searchpropgridfromapi[gd].isstatusurgent!=null && this.searchpropgridfromapi[gd].isstatusurgent.toLowerCase()=='y')
            {
              grid_display_part_status+=' (Urgent)'
            }
            if(this.searchpropgridfromapi[gd].isdeliquent!=null && this.searchpropgridfromapi[gd].isdeliquent.toLowerCase()=='y')
            {
              grid_display_part_status+=' (Delinquent)'
            }

          }
  
          var suPerPart=this.searchpropgridfromapi[gd].ngpp_part_l*this.searchpropgridfromapi[gd].ngpp_part_w*this.searchpropgridfromapi[gd].ngpp_part_h;
          suPerPart=this.transactionService.roundValueToNDecimal(suPerPart,3);

          temporaryObj.push({
            bompartmasterid: this.searchpropgridfromapi[gd].bompartmasterid,
            id_proposal: this.searchpropgridfromapi[gd].id_proposal,
            plantgsdbcode: this.searchpropgridfromapi[gd].plantgsdbcode,
            tradingcompany:this.searchpropgridfromapi[gd].tradingcogsdbcode,
            cos:this.searchpropgridfromapi[gd].cntr_ownrshp_strat_code,
            partnumber:this.searchpropgridfromapi[gd].partprefix+'-'+this.searchpropgridfromapi[gd].partbase+'-'+this.searchpropgridfromapi[gd].partsuffix,
            partdesc: this.searchpropgridfromapi[gd].partdesc,
            partstatus: this.searchpropgridfromapi[gd].partstatus.trim(),
            supplierid: this.searchpropgridfromapi[gd].suppliergsdbcode+' - '+this.searchpropgridfromapi[gd].supfullname,
            ngpp_part_wt: this.searchpropgridfromapi[gd].ngpp_part_wt,
            programname: this.searchpropgridfromapi[gd].programname,
            ngpp_part_l: this.searchpropgridfromapi[gd].ngpp_part_l,
            ngpp_part_w: this.searchpropgridfromapi[gd].ngpp_part_w,
            ngpp_part_h: this.searchpropgridfromapi[gd].ngpp_part_h,
            partnumbercommonized: this.searchpropgridfromapi[gd].partnumbercommonized,
            cntr_ret_typ: this.searchpropgridfromapi[gd].cntr_ret_typ,
            cntr_id:this.searchpropgridfromapi[gd].cntr_base+'-'+this.searchpropgridfromapi[gd].cntr_suff,
            parts_per_cntr: this.searchpropgridfromapi[gd].parts_per_cntr,
            cntr_dim:this.searchpropgridfromapi[gd].cntr_ext_len+'x'+this.searchpropgridfromapi[gd].cntr_width+'x'+this.searchpropgridfromapi[gd].cntr_height,
           cntr_gross_wt: this.searchpropgridfromapi[gd].cntr_gross_wt,
            shp_unt_id:this.searchpropgridfromapi[gd].shp_unt_base+'-'+this.searchpropgridfromapi[gd].shp_unt_suff,
            part_per_shp_unit: this.searchpropgridfromapi[gd].part_per_shp_unit,
            shp_unit_dim:this.searchpropgridfromapi[gd].shp_unit_ext_len+'x'+this.searchpropgridfromapi[gd].shp_unt_ext_width+'x'+this.searchpropgridfromapi[gd].shp_unt_ext_height,
            shp_unt_gross_wt: this.searchpropgridfromapi[gd].shp_unt_gross_wt,
            proposal_status_code:this.searchpropgridfromapi[gd].proposal_status_curr,
            "proposal_status_desc":this.apprvr_status_list[apprstatIndex].propsl_stat,
            f_partfilepath: this.searchpropgridfromapi[gd].f_partfilepath,
            comp2_qty: this.searchpropgridfromapi[gd].comp2_qty,
            comp1:this.searchpropgridfromapi[gd].comp1_Base+'-'+this.searchpropgridfromapi[gd].comp1_Suff,
            comp1_qty: this.searchpropgridfromapi[gd].comp1_qty,
            comp2:this.searchpropgridfromapi[gd].comp2_Base+'-'+this.searchpropgridfromapi[gd].comp2_Suff,
            "su_per_part":suPerPart,
            // "uom":"lbs/inch",
            "uom":this.UOM,
            sup_cntry:this.searchpropgridfromapi[gd].supcountrycode,
            isdeliquent:this.searchpropgridfromapi[gd].isdeliquent,
            isstatusurgent:this.searchpropgridfromapi[gd].isstatusurgent,
            urgentondate:this.searchpropgridfromapi[gd].urgentondate,
            deliquentondate:this.searchpropgridfromapi[gd].deliquentondate,
            displaypartstatus:grid_display_part_status,
            imp_ngpp_part_status:this.searchpropgridfromapi[gd].imp_ngpp_part_status,
            propsl_type:this.searchpropgridfromapi[gd].propsl_type,
            imp_propsal_flg:this.searchpropgridfromapi[gd].imp_propsal_flg,
            effectdate:this.searchpropgridfromapi[gd].effectdate,
            pfeppartstatus:this.searchpropgridfromapi[gd].pfeppartstatus
          })
        }
        this.searchpropgrid=JSON.parse(JSON.stringify(temporaryObj));
        console.log('searchpropgrid',this.searchpropgrid);
        console.log("Proposals not displayed in grid as proposal status does not match with the list",proposalsNotMatchpropostat);
        sessionStorage.setItem('partsfromcopycntrdialog',JSON.stringify(this.searchpropgrid))
        // alert("partsfromcopycntrdialog"+JSON.stringify(this.searchpropgrid))
        this.isloadersearchprop=false;
        if(this.ForCopyCntr.dialogstatus==true)
        {
          const proposalIdForPartSlctdFrmDashBd=String(this.ForCopyCntr.id_proposal);
          // alert("came here to check session storage");

          if(this.packproposaltab2.copy_cntr_dialog==true)
          {
            this.packproposaltab2.setCopyCntrPopUpWithSelectedDeatils();
            if(sessionStorage.getItem(proposalIdForPartSlctdFrmDashBd)!=null)
            {
              this.selected_proposal=JSON.parse(sessionStorage.getItem(proposalIdForPartSlctdFrmDashBd));
              // alert("came here to check session storage"+JSON.stringify(this.selected_proposal));
            }
          }

          if(this.packproposaltab2ee.copy_cntr_dialog_ee==true)
          {
            this.packproposaltab2ee.setCopyCntrPopUpWithSelectedDeatils_ee();
            if(sessionStorage.getItem(proposalIdForPartSlctdFrmDashBd)!=null)
            {
              this.selected_proposal=JSON.parse(sessionStorage.getItem(proposalIdForPartSlctdFrmDashBd));
              // alert("came here to check session storage"+JSON.stringify(this.selected_proposal));
            }
          }

          if(this.improvementpackproposaltab2.copy_cntr_dialog==true)
          {
            this.improvementpackproposaltab2.setCopyCntrPopUpWithSelectedDeatils();
            if(sessionStorage.getItem(proposalIdForPartSlctdFrmDashBd)!=null)
            {
              this.selected_proposal=JSON.parse(sessionStorage.getItem(proposalIdForPartSlctdFrmDashBd));
              // alert("came here to check session storage"+JSON.stringify(this.selected_proposal));
            }
          }

          if(this.improvementpackproposaleetab2.copy_cntr_dialog_ee==true)
          {
            this.improvementpackproposaleetab2.setCopyCntrPopUpWithSelectedDeatils_ee();
            if(sessionStorage.getItem(proposalIdForPartSlctdFrmDashBd)!=null)
            {
              this.selected_proposal=JSON.parse(sessionStorage.getItem(proposalIdForPartSlctdFrmDashBd));
              // alert("came here to check session storage"+JSON.stringify(this.selected_proposal));
            }
          }


        }

        if(this.searchpropgrid.length==0)
        {
          this.search_result='No Match Found'
        }

    this.cols=[
      { field: 'plantgsdbcode', header:'Plant GSDB Code'},
      {field:'propsl_type',header:'Proposal Type'},
      { field: 'partnumbercommonized', header:'Part Number'},
      { field: 'partdesc', header:'Part Description'},
      //{ field: 'displaypartstatus', header:'Part Status'},
      { field: 'pfeppartstatus', header:'Part Status'},
      { field: 'proposal_status_desc', header:'Approval Status'},
      { field: 'supplierid', header:'Supplier GSDB Code'},
      { field: 'sup_cntry', header:'Supplier Country'},
      {field:'tradingcompany', header:'Trading Company'},
      { field: 'cntr_id', header:'Container Number'},
      { field: 'cntr_ret_typ', header:'Container Return Type'},
      { field: 'cos', header:'Container Ownership Strategy'},
      { field: 'parts_per_cntr', header:'Parts Per Container'},
      { field: 'cntr_dim', header:'Container Dimension(LxWxH)'},
      { field: 'cntr_gross_wt', header:'Container Gross Weight'},
      { field: 'comp1', header:'Component 1'},
      { field: 'comp1_qty', header:'Component 1 Quantity'},
      { field: 'comp2', header:'Component 2'},
      { field: 'comp2_qty', header:'Component 2 Quantity'},
      { field: 'shp_unt_id', header:'Shipping Unit ID'},
      { field: 'part_per_shp_unit', header:'Parts Per Shipping Unit'},
      { field: 'shp_unit_dim', header:'Shipping Unit Dimension(LxWxH)'} ,
      { field: 'shp_unt_gross_wt', header:'Shipping Unit Gross Weight'},
      { field: 'ngpp_part_wt', header:'Part Weight'},
      { field: 'programname', header:'Program Name'},
      { field: 'su_per_part', header:'SU (in³ or cm³) Per Part'},
      { field: 'uom', header:'UOM'}
    ];
    },
    (error) => {                              //Error callback
      console.error('Error in Search Proposal API call - getSearchProposalGridDetails',error)
      this.errorLoadingData = "Error Occured, Please Try Again";
      this.isloadersearchprop=false;
  
    }
    )
  }

  exportExcel() {

    var searchPropDataForExcelDownload:searchProposalGridExcelFormat[]=[];

    console.log("search proposal api data",this.searchpropgridfromapi);

    
    this.searchpropgridfromapi.forEach(d=>{

      var propIndex=this.apprvr_status_list.findIndex(p=>Number(p.cd_propsl)==Number(d.proposal_status_curr))
      var shippingUnitId=d.cntr_base;

      var apprStat=null;
      if(propIndex!=-1)
      {
        apprStat=this.apprvr_status_list[propIndex].propsl_stat;
      }
      else
      {
        apprStat="";
      }

      if(d.cntr_suff!=null && d.cntr_suff.trim()!="")
      {
        shippingUnitId+="-";
        shippingUnitId+=d.cntr_suff;
      }
   
      searchPropDataForExcelDownload.push({
        "plantgsdbcode": d.plantgsdbcode,
        "tradingcompany": d.tradingcogsdbcode,
        "partprefix":d.partprefix,
        "partbase":d.partbase,
        "partsuffix":d.partsuffix,
        "partcontrol":"",
        "partdesc":d.partdesc,
        "partstatus":d.partstatus,
        "ngpp_part_wt": d.ngpp_part_wt,
        "supgsdbcode":d.suppliergsdbcode,
        "supname":d.supfullname,
        "sup_cntry":d.supcountrycode,
        "cos":d.cntr_ownrshp_strat_code,
        "cntr_ret_typ":d.cntr_ret_typ,
        "container_base":d.cntr_base,
        "container_suffix":d.cntr_suff,
        "parts_per_cntr": d.parts_per_cntr,
        "cntr_len":d.cntr_ext_len,
        "cntr_width":d.cntr_width,
        "cntr_height":d.cntr_height,
        "cntr_gross_wt":d.cntr_gross_wt,
        "comp1base":d.comp1_Base,
        "comp1suffix":d.comp1_Suff,
        "comp1qty":d.comp1_qty,
        "comp2base":d.comp2_Base,
        "comp2suffix":d.comp2_Suff,
        "comp2qty":d.comp2_qty,
        "shippingunitid":shippingUnitId,
        "partsPerShippingUnit":d.part_per_shp_unit,
        "suLen":d.shp_unit_ext_len,
        "suWidth":d.shp_unt_ext_width,
        "suHeight":d.shp_unt_ext_height,
        "suGrossWt":d.shp_unt_gross_wt,
        "approvalStatus":apprStat,
        "proposalType":d.propsl_type
      })
    })

    console.log(searchPropDataForExcelDownload)

    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(searchPropDataForExcelDownload,{header:[
          "plantgsdbcode",
          "tradingcompany",
          "partprefix",
          "partbase",
          "partsuffix",
          "partcontrol",
          "partdesc",
          "partstatus",
          "ngpp_part_wt",
          "supgsdbcode",
          "supname",
          "sup_cntry",
          "cos",
          "cntr_ret_typ",
          "container_base",
          "container_suffix",
          "parts_per_cntr",
          "cntr_len",
          "cntr_width",
          "cntr_height",
          "cntr_gross_wt",
          "comp1base",
          "comp1suffix",
          "comp1qty",
          "comp2base",
          "comp2suffix",
          "comp2qty",
          "shippingunitid",
          "partsPerShippingUnit",
          "suLen",
          "suWidth",
          "suHeight",
          "suGrossWt",
          "approvalStatus",
          "proposalType"
        ]});

        worksheet.A1.v="Plant GSDB";
        worksheet.B1.v="Trading Company";
        worksheet.C1.v="PartPrefix";
        worksheet.D1.v="PartBase";
        worksheet.E1.v="PartSuffix";
        worksheet.F1.v="PartControl";
        worksheet.G1.v="PartDescription";
        worksheet.H1.v="PartStatus";
        worksheet.I1.v="PartWeight";
        worksheet.J1.v="Supplier GSDB";
        worksheet.K1.v="Supplier Name";
        worksheet.L1.v="Supplier Country Code";
        worksheet.M1.v="Container Ownership";
        worksheet.N1.v="Expendable/Returnable";
        worksheet.O1.v="Container Base";
        worksheet.P1.v="Container Suffix";
        worksheet.Q1.v="Parts Per Container";
        worksheet.R1.v="Container Length";
        worksheet.S1.v="Container Width";
        worksheet.T1.v="Container Height";
        worksheet.U1.v="Container Gross Weight";
        worksheet.V1.v="Component1 Base";
        worksheet.W1.v="Component1 Suffix";
        worksheet.X1.v="Component1 QTY";
        worksheet.Y1.v="Component2 Base";
        worksheet.Z1.v="Component2 Suffix";
        worksheet.AA1.v="Component2 QTY";
        worksheet.AB1.v="Shipping Unit ID";
        worksheet.AC1.v="Parts Per Shipping Unit";
        worksheet.AD1.v="Shipping Unit Length";
        worksheet.AE1.v="Shipping Unit Width";
        worksheet.AF1.v="Shipping Unit Height";
        worksheet.AG1.v="Shipping Unit Gross Weight";
        worksheet.AH1.v="Approval Status";
        worksheet.AI1.v="Proposal Type";
       

        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, 'Proposal List');
    });

    /* import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.searchpropgrid);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "Proposal List");
    }); */
}

saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    });
}



  loadproposal(rowfromgrid:searchProposalGrid)
  {

    // below added to give access for create/edit improvement proposal
    // -- if supplier user type
        // - if - sps/spu/sos/sou - should have supplier access(role id -1) -- allow to create
        // --else - not allowed to create/ edit improvement proposal
    // -- if ford user type
        // - if - !(sps/spu/sos/sou) - should have approver access(role id -3/4/5/6/9/11/12(12 included as region admin has prelimnary,release for eval,release for prod access)) -- allow to create
        // --else - not allowed to create/ edit improvement proposal
    
    var roles=this.transactionService.getroles(this.transactionService.getregioncode(this.selected_region_from_list));
    let isFordUser=JSON.parse(sessionStorage.getItem('userType'));
    var supplierAccessExistForSupplierUSer:boolean=false
    var approverAccessExistForFordUser:boolean=false

    //approved for production proposal rejection
    this.rej_rel_for_prodappr_enable = false;
    this.comments = '';

    if(isFordUser!=null && isFordUser.toLowerCase()=='no') // supplier
    {
      const roleIndex=roles.findIndex(elroles=>(elroles.roleid == 1))
      if(roleIndex!=-1)
      {

        supplierAccessExistForSupplierUSer = true;

      }
    }
    else  if(isFordUser!=null && isFordUser.toLowerCase()=='yes') // ford
    {
      const roleIndex=roles.findIndex(elroles=>(elroles.roleid == 3 || elroles.roleid == 4 || elroles.roleid == 5 || elroles.roleid == 6 || elroles.roleid == 9 || elroles.roleid == 11 || elroles.roleid == 12))
      if(roleIndex!=-1)
      {

        approverAccessExistForFordUser = true;

      }

    }

    console.log(rowfromgrid);

      this.selectedsimpliedpartnodetails.bompartmasterid=rowfromgrid.bompartmasterid;
      this.selectedsimpliedpartnodetails.simplifiedpartno=rowfromgrid.partnumbercommonized;
      this.selectedsimpliedpartnodetails.proposalid=rowfromgrid.id_proposal;
      this.selectedsimpliedpartnodetails.plantgsdbcode=rowfromgrid.plantgsdbcode;
      this.selectedsimpliedpartnodetails.proposallastsaved=4;
    this.selectedsimpliedpartnodetails.propsl_type=rowfromgrid.propsl_type;

    if(this.selectedsimpliedpartnodetails.propsl_type==null || this.selectedsimpliedpartnodetails.propsl_type.toLowerCase()=='normal' || this.selectedsimpliedpartnodetails.propsl_type.toLowerCase()=='migration')
    {
      const ind=this.statusList.findIndex(p=>p.status==rowfromgrid.partstatus);
      this.selectedsimpliedpartnodetails.part_status=String(this.statusList[ind].status);
      this.selectedsimpliedpartnodetails.imp_part_status=rowfromgrid.imp_ngpp_part_status
    }
    else
    {
      const ind=this.statusList.findIndex(p=>p.status==rowfromgrid.imp_ngpp_part_status);
      if(ind!=-1)
      {
        this.selectedsimpliedpartnodetails.imp_part_status=String(this.statusList[ind].status);
     }
      else{
        this.selectedsimpliedpartnodetails.imp_part_status=null;

      }
      this.selectedsimpliedpartnodetails.part_status=rowfromgrid.partstatus
    }

      this.selectedsimpliedpartnodetails.supplier_code=rowfromgrid.supplierid.split('-')[0];
      this.selectedsimpliedpartnodetails.proposallastsaved_ee=1;
    this.selectedsimpliedpartnodetails.currentview_ee=false;
    this.selectedsimpliedpartnodetails.is_ee_proposal=false;
    this.selectedsimpliedpartnodetails.from_draft=false;
    this.selectedsimpliedpartnodetails.propsl_type=rowfromgrid.propsl_type;


    this.part_image="";
    this.interior_image="";
    this.exterior_image="";
    this.label_image="";
    this.shipping_image="";
    this.sbupl=""
    this.baseurl_get=environment.nfsBaseAPIUrl+"/downloadFile?fPath=";
    this.baseurl_get_sbupl=environment.nfsBaseAPIUrl+"/downloadNonImgFile?fPath="
    this.imgsave_path=environment.nfsBasePath;

    if(this.ForCopyCntr.dialogstatus!=true)
    {

      // if approved for production , either proposal status not improvement  or its improvement in progress/improvement rejected/improvement recalled allow to create improvement proposal
      // if user is supplier -> allow to create for cos spu and sps
      // if user is ford -> allow to create for all cos that are not spu and sps

      console.log('rowfromgrid.proposal_status_code',rowfromgrid.proposal_status_code);
      console.log('rowfromgrid.cos',rowfromgrid.cos,rowfromgrid.partstatus);
      console.log(JSON.parse(sessionStorage.getItem('userType')));
      console.log(rowfromgrid)

      // This if-else block is to enable editing effective Date for approved for production pending improvement proposal
      if(rowfromgrid.proposal_status_code=='18' && rowfromgrid.propsl_type.toLowerCase()=='improvement')
      {
        let userType=JSON.parse(sessionStorage.getItem('userType'));

        if(userType!=null && userType.toLowerCase()=="no") //supplier
        {
          if(supplierAccessExistForSupplierUSer)
          {
            this.enableEffectiveDateForImprovementProposal=true;
            this.effectiveDateInString=rowfromgrid.effectdate;
            if(this.effectiveDateInString!=null && this.effectiveDateInString!='')
            {
              this.effectiveDateForImprovementProposal=new Date(this.effectiveDateInString);
            }
            else
            {
              this.effectiveDateForImprovementProposal=null;
            }
           
            this.currentDate=new Date();
          }
          else
          {
            this.enableEffectiveDateForImprovementProposal=false;
            this.effectiveDateInString="";
            this.effectiveDateForImprovementProposal=null;
          }
        }
        else if(userType!=null && userType.toLowerCase()=="yes") //ford user
        {
          this.enableEffectiveDateForImprovementProposal=true;
            this.effectiveDateInString=rowfromgrid.effectdate;
            if(this.effectiveDateInString!=null && this.effectiveDateInString!='')
            {
              this.effectiveDateForImprovementProposal=new Date(this.effectiveDateInString);
            }
            else
            {
              this.effectiveDateForImprovementProposal=null;
            }
           
            this.currentDate=new Date();
        }

        else //unknown user type
        {
          this.enableEffectiveDateForImprovementProposal=false;
          this.effectiveDateInString="";
          this.effectiveDateForImprovementProposal=null;
        }
        
      }
      else
      {
        this.enableEffectiveDateForImprovementProposal=false;
        this.effectiveDateInString='';
        this.effectiveDateForImprovementProposal=null;
      }
      console.log(this.enableEffectiveDateForImprovementProposal)


      if(rowfromgrid.proposal_status_code=='11' && (rowfromgrid.propsl_type!=null && rowfromgrid.propsl_type.toLowerCase()=='improvement'))
      {
          // to be confirmed for rejection and update
        if(rowfromgrid.cos.toLowerCase()=='sps' || rowfromgrid.cos.toLowerCase()=='spu' || rowfromgrid.cos.toLowerCase()=='sou' || rowfromgrid.cos.toLowerCase()=='sos')
        {
          const userType=JSON.parse(sessionStorage.getItem('userType'));
          if(userType!=null && userType.toLowerCase()=='no' && supplierAccessExistForSupplierUSer) // supplier
          {
            this.showUpdateImprovementButton=true;
            this.showDeleteImprovementButton=true;
          }
          else
          {
            this.showUpdateImprovementButton=false;
            this.showDeleteImprovementButton=false;
          }
        }
        else
        {

          const userType=JSON.parse(sessionStorage.getItem('userType'));

          if(userType!=null && userType.toLowerCase()=='yes' && approverAccessExistForFordUser)
          {
            this.showUpdateImprovementButton=true;
            this.showDeleteImprovementButton=true;
          }
          else
          {
            this.showUpdateImprovementButton=false;
        this.showDeleteImprovementButton=false;
          }
        }

      }
      else
      {
        this.showUpdateImprovementButton=false;
        this.showDeleteImprovementButton=false;
      }

      // This if-else block is to create/edit improvement proposal from normal proposal
      if(rowfromgrid.proposal_status_code=='10' && 
        (rowfromgrid.propsl_type == null || rowfromgrid.propsl_type.toLowerCase() =="normal") && 
        (rowfromgrid.imp_propsal_flg == null || rowfromgrid.imp_propsal_flg.toLowerCase() == "n" || (rowfromgrid.imp_propsal_flg.toLowerCase() == "i" && rowfromgrid.imp_ngpp_part_status != null)))
      {
        console.log('inside improvement button enable')

        sessionStorage.setItem('Improvement flag',rowfromgrid.imp_propsal_flg);


        // on change of label make sure to revsist the codition given in confirmationLoadImprovementProposal() function

        if(rowfromgrid.imp_propsal_flg != null && rowfromgrid.imp_propsal_flg.toLowerCase() == "i")
        {
          this.improvementLabel='Edit Improvement Proposal'
        }
        else
        {
          this.improvementLabel='Create Improvement Proposal'
        }
        console.log(this.improvementLabel)

        if(rowfromgrid.cos.toLowerCase()=='sps' || rowfromgrid.cos.toLowerCase()=='spu' || rowfromgrid.cos.toLowerCase()=='sou' || rowfromgrid.cos.toLowerCase()=='sos')
        {
          const userType=JSON.parse(sessionStorage.getItem('userType'));
          if(userType!=null && userType.toLowerCase()=='no' && supplierAccessExistForSupplierUSer) // supplier
          {
            this.improvementButtonEnable=true;
          }
          else
          {
            this.improvementButtonEnable=false;
          }
        }
        else
        {

          const userType=JSON.parse(sessionStorage.getItem('userType'));
         // alert(sessionStorage.getItem('userType'))

          if(userType!=null && userType.toLowerCase()=='yes' && approverAccessExistForFordUser)
          {
            this.improvementButtonEnable=true;
            // alert(this.improvementButtonEnable);
          }
          else
          {
            this.improvementButtonEnable=false;
          }
        }


      }
      else
      {
        this.improvementButtonEnable=false;
      }

              //Approved for production Proposal Rejection - button enable/disable

        const userroles=this.transactionService.getroles(this.transactionService.getregioncode(this.selected_region_from_list));
        const isFord=JSON.parse(sessionStorage.getItem('userType'));
        let userAccessForRejButton = false;


        if(isFord!=null && isFord.toLowerCase()=='yes') // ford
        {
          const roleIndex=userroles.findIndex(elroles=>(elroles.roleid == 3 || elroles.roleid == 5 || elroles.roleid == 6 || elroles.roleid == 11))
          if(roleIndex!=-1)
          {

            userAccessForRejButton = true;

          }

        }

      if(
        (rowfromgrid.proposal_status_code == '10' &&  (rowfromgrid.propsl_type == null || rowfromgrid.propsl_type.toLowerCase() =="normal") && 
      (rowfromgrid.imp_propsal_flg == null || rowfromgrid.imp_propsal_flg.toLowerCase() == "n")) && userAccessForRejButton == true)
      {
       this.rej_rel_for_prodappr_enable = true;
      }
    }
     this.store_primary_part();
  }

  store_primary_part()
  {
    const regcode=this.region_code;
    // this.transactionService.getregioncode(this.selected_region_from_list);

    if(regcode=='NA')
    {
      sessionStorage.setItem('napackpropart', JSON.stringify(this.selectedsimpliedpartnodetails));
    }

    if(regcode=='EU')
    {
      sessionStorage.setItem('eupackpropart', JSON.stringify(this.selectedsimpliedpartnodetails));
    }

    if(regcode=='AP')
    {
      sessionStorage.setItem('appackpropart', JSON.stringify(this.selectedsimpliedpartnodetails));
    }

    if(regcode=='SA')
    {
      sessionStorage.setItem('sapackpropart', JSON.stringify(this.selectedsimpliedpartnodetails));
    }


    this.enabledetails_search=false;

    this.view_proposal=true;

  }

  updateImprovementProposal()
  {
    sessionStorage.setItem('Improvement flag','S');
    this.loadImprovementProposal();
  }

  deleteImprovementProposal()
  {
    const storedpartdetails=this.transactionService.getpartno_packpro(this.transactionService.getregion());
  this.improvementSelectedSimpliedPartnoDetails={simplifiedpartno:'-',bompartmasterid:0,proposalid:0,plantgsdbcode:'',proposallastsaved:0,part_status:'',supplier_code:'',proposallastsaved_ee:0,currentview_ee:false,is_ee_proposal:false,from_draft:false,propsl_type:'',imp_part_status:''};

    this.transactionService.get_exist_proposalid(storedpartdetails.simplifiedpartno,storedpartdetails.plantgsdbcode,"Improvement",storedpartdetails.supplier_code).subscribe(sub_exist_proposalid=>{


      const partNo=sub_exist_proposalid.sp_part_no;
      sessionStorage.setItem('primaryPartNo',partNo);

    this.improvementSelectedSimpliedPartnoDetails.bompartmasterid=storedpartdetails.bompartmasterid;
    this.improvementSelectedSimpliedPartnoDetails.simplifiedpartno=storedpartdetails.simplifiedpartno;
    this.improvementSelectedSimpliedPartnoDetails.proposalid=sub_exist_proposalid.id_proposal;
    this.improvementSelectedSimpliedPartnoDetails.plantgsdbcode=storedpartdetails.plantgsdbcode;
    this.improvementSelectedSimpliedPartnoDetails.proposallastsaved=sub_exist_proposalid.lst_saved_tab;


    this.improvementSelectedSimpliedPartnoDetails.part_status=storedpartdetails.part_status;


    this.improvementSelectedSimpliedPartnoDetails.supplier_code=storedpartdetails.supplier_code.trim();
    this.improvementSelectedSimpliedPartnoDetails.currentview_ee=false;
    this.improvementSelectedSimpliedPartnoDetails.from_draft=true;
    this.improvementSelectedSimpliedPartnoDetails.proposallastsaved_ee=sub_exist_proposalid.lst_saved_tab_ee


    this.improvementSelectedSimpliedPartnoDetails.propsl_type='Improvement'
    this.improvementSelectedSimpliedPartnoDetails.imp_part_status=storedpartdetails.imp_part_status.trim();

    if(sub_exist_proposalid.lst_saved_tab >=4)
    {
      this.transactionService.loadtab4details(this.improvementSelectedSimpliedPartnoDetails.proposalid).subscribe(tab4Data=>{

        console.log(tab4Data);
        if(tab4Data.createmgcyexpndpckgprop != null && tab4Data.createmgcyexpndpckgprop != '' && tab4Data.createmgcyexpndpckgprop.toLowerCase() == 'yes')
        {
          this.improvementSelectedSimpliedPartnoDetails.is_ee_proposal=true;
        }
        else
        {
          this.improvementSelectedSimpliedPartnoDetails.is_ee_proposal=false;
        }

      })

    }

      let messageDisplay=''
      if(this.improvementSelectedSimpliedPartnoDetails.is_ee_proposal==true)
      {
        messageDisplay='Are you sure you want to delete current proposal both normal and ee ?'
      }
      else
      {
        messageDisplay='Are you sure you want to delete current proposal ?'
      }



        this.confirmationService.confirm({
            message:messageDisplay,
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              this.improvementpackagingproposal.selectedpart_submitter_delete=this.improvementSelectedSimpliedPartnoDetails;

              this.deleteproposal_id(this.improvementSelectedSimpliedPartnoDetails.proposalid);


            }
        });

      });

    }

    deleteproposal_id(proposalid:any)
    {


        console.log('opnew',proposalid);


        console.log('deleteid',this.improvementSelectedSimpliedPartnoDetails);
        this.isloadersearchprop=true;


        if(this.improvementSelectedSimpliedPartnoDetails.proposallastsaved>=4)
        {


            if(this.improvementSelectedSimpliedPartnoDetails.proposallastsaved_ee>=4)
            {


                this.transactionService.loadtab4details_ee(this.improvementSelectedSimpliedPartnoDetails.proposalid).subscribe(tab4_api_op_ee=>{


                    console.log(tab4_api_op_ee);


                    this.tab4_output_det=tab4_api_op_ee;


                    if(this.tab4_output_det.f_partphoto!='' && this.tab4_output_det.f_partphoto!=null)
                    {
                      const prt=this.tab4_output_det.f_partphoto;
                      this.transactionService.delete_image(prt).subscribe(out_prt=>{

                        console.log(out_prt);

                      })

                    }


                if(this.tab4_output_det.f_intcntrdsgnphoto!='' && this.tab4_output_det.f_intcntrdsgnphoto!=null)
                {
                    const inter=this.tab4_output_det.f_intcntrdsgnphoto;
                    this.transactionService.delete_image(inter).subscribe(out_inter=>{

                        console.log(out_inter);

                      })

                }

                if(this.tab4_output_det.f_extcntrdsgnphoto!='' && this.tab4_output_det.f_extcntrdsgnphoto!=null)
                {
                    const exter=this.tab4_output_det.f_extcntrdsgnphoto;
                    this.transactionService.delete_image(exter).subscribe(out_exter=>{

                        console.log(out_exter);

                      })

                }


                if(this.tab4_output_det.f_metelidplatephoto!='' && this.tab4_output_det.f_metelidplatephoto!=null)
                {
                    const label=this.tab4_output_det.f_metelidplatephoto;
                    this.transactionService.delete_image(label).subscribe(out_label=>{

                        console.log('label',out_label);

                      })

                }


                if(this.tab4_output_det.f_shpunitphoto!='' && this.tab4_output_det.f_shpunitphoto!=null)
                {
                    const ship=this.tab4_output_det.f_shpunitphoto;
                    this.transactionService.delete_image(ship).subscribe(out_ship=>{

                        console.log(out_ship);

                      })

                }





                   this.deleteTab4NonEE();



                })







            }


            else
            {


                this.deleteTab4NonEE();

            }




        }


        else{
            this.delete_proposal_service()
        }





    }


    deleteTab4NonEE()
    {
        this.transactionService.loadtab4details(this.improvementSelectedSimpliedPartnoDetails.proposalid).subscribe(tab4_api_op=>{


            console.log(tab4_api_op);


            this.tab4_output=tab4_api_op;


            if(this.tab4_output.f_partphoto!='' && this.tab4_output.f_partphoto!=null)
            {
              const prt=this.tab4_output.f_partphoto;
              this.transactionService.delete_image(prt).subscribe(out_prt=>{

                console.log(out_prt);

              })

            }


        if(this.tab4_output.f_intcntrdsgnphoto!='' && this.tab4_output.f_intcntrdsgnphoto!=null)
        {
            const inter=this.tab4_output.f_intcntrdsgnphoto;
            this.transactionService.delete_image(inter).subscribe(out_inter=>{

                console.log(out_inter);

              })

        }

        if(this.tab4_output.f_extcntrdsgnphoto!='' && this.tab4_output.f_extcntrdsgnphoto!=null)
        {
            const exter=this.tab4_output.f_extcntrdsgnphoto;
            this.transactionService.delete_image(exter).subscribe(out_exter=>{

                console.log(out_exter);

              })

        }


        if(this.tab4_output.f_metelidplatephoto!='' && this.tab4_output.f_metelidplatephoto!=null)
        {
            const label=this.tab4_output.f_metelidplatephoto;
            this.transactionService.delete_image(label).subscribe(out_label=>{

                console.log('label',out_label);

              })

        }


        if(this.tab4_output.f_shpunitphoto!='' && this.tab4_output.f_shpunitphoto!=null)
        {
            const ship=this.tab4_output.f_shpunitphoto;
            this.transactionService.delete_image(ship).subscribe(out_ship=>{

                console.log(out_ship);

              })

        }


        if(this.tab4_output.subpresloaddoc!='' && this.tab4_output.subpresloaddoc!=null)
        {
            const sbu=this.tab4_output.subpresloaddoc;
            this.transactionService.delete_image(sbu).subscribe(out_sbu=>{

                console.log(out_sbu);

              })

        }



       this.delete_proposal_service();


        })
    }


    delete_proposal_service()
    {


        this.transactionService.delete_proposal(this.improvementSelectedSimpliedPartnoDetails.proposalid).subscribe(response=>{


            console.log(response);


            if(response=='Success')
            {
                this.messageService.add({severity:'success', summary:'Success', detail:'Proposal Deleted Successfully Successfully'});

                this.view_proposal=false;
                this.isloadersearchprop=false;
                this.search();

                /* var reg_url:string
                reg_url=this.transactionService.getregionurlvalue(this.transactionService.getregion());


                //this.remove_stored_part();



                    reg_url+="packagingprocessdashboard/searchexistingproposal";
                    this.router.navigate([reg_url]);  */





            }


            else{
              this.isloadersearchprop=false;
                this.messageService.add({severity:'error', summary: 'Error', detail: 'Proposal  Not Deleted,Please Try Again'})
            }

        })




    }

    deleteProposalServiceEE()
    {
        console.log(this.improvementSelectedSimpliedPartnoDetails);

        this.transactionService.flushcompandimagedata_ee(this.improvementSelectedSimpliedPartnoDetails.proposalid).subscribe(flush_out=>{


          console.log(flush_out);
          if(flush_out.toLowerCase()=='success')
          {


            this.messageService.add({severity:'success', summary: 'success', detail: 'Tab3 Tab 4 data deleted  successfully'});
            this.delete_proposal_service();


            }


            else{
              this.isloadersearchprop=false;
                this.messageService.add({severity:'Error', summary: 'error', detail: 'Tab3 Tab 4 data not deleted!'});

            }



        })

    }


       // Approved for Production Proposal Rejection - reject button call

    rejectReleasedForProdProp()
    {

      if(this.rej_rel_for_prodappr_enable)
      {


        let cur_date_reject;
        let cur_date_transform_reject
        cur_date_reject=new Date();
        cur_date_transform_reject=this.date.transform(cur_date_reject, 'yyyy-MM-dd HH:mm:ss');


        const cdsid=this.userid;

        console.log(cur_date_transform_reject);

        let alphabet_pres_reject_denied:boolean;
        alphabet_pres_reject_denied=/[a-z]/i.test(this.comments);

        this.transactionService.loadtransacmasterdetails( this.selectedsimpliedpartnodetails.proposalid).subscribe(transdet_search=>{


          console.log(transdet_search);
          this.releaselevel = transdet_search.rel_level;
          console.log(this.releaselevel)

        if(this.comments==null|| alphabet_pres_reject_denied==false)
        {
          // toast stating enter comments

          this.enable_dialog_notification=true;
          this.messageService.add({severity:'error', summary: 'Error', detail: 'Please enter comments to reject proposal'});
        }
        else
        {
          this.upload_cmms_packaging='NA'



          var replace_comments=this.comments.replace('|','/').replace('`',"'")
          this.comments=replace_comments;

          const commentsToAPI=this.comments;

          const base_change='-';
            console.log('comments',commentsToAPI);

            this.selected_propid = this.selectedsimpliedpartnodetails.proposalid;

            console.log('2918', this.selectedsimpliedpartnodetails.proposalid,cdsid,cur_date_transform_reject,commentsToAPI,'4',base_change,this.upload_cmms_packaging)

          this.transactionService.reject_proposal_fn(this.selectedsimpliedpartnodetails.proposalid,cdsid,cur_date_transform_reject,commentsToAPI,this.releaselevel,base_change,this.upload_cmms_packaging).subscribe(result_reject=>{

          console.log(result_reject);
          if(result_reject.toLowerCase()=='success')
          {

            // if output success
            this.messageService.add({severity:'success', summary: 'success', detail: 'Proposal rejected successfully'});


            // api to trigger email to reject proposal
            this.email_for_reject(this.comments);

            this.search();
            this.view_proposal = false;
        }

        else{
        this.messageService.add({severity:'error', summary: 'Error', detail: 'Proposal not rejected, Try again'});
        }
        })
        }

      })
      }


    }


        // Approved for Production Proposal Rejection - email call
    email_for_reject(commentsReceived:any)
    {
      console.log('inside emailrej')
      console.log(commentsReceived);
      console.log(this.selected_propid);
      console.log(this.improvementSelectedSimpliedPartnoDetails);
      console.log(this.userid);
      //console.log(this.base_change);
    
    
      this.email_list="NA";
    
    
      this.email_notification_eventid="11";
    
      var base_change='-'
    
      var cur_date_email_reject;
      var cur_date_transform_email_reject
      cur_date_email_reject=new Date();
      cur_date_transform_email_reject=this.date.transform(cur_date_email_reject, 'yyyy-MM-dd HH:mm:ss');

    let copy_me_reject
    copy_me_reject='N'
    const encodedcomments=encodeURIComponent(commentsReceived);



    this.transactionService.send_email_notification(this.selected_propid,base_change,encodedcomments,this.email_list,copy_me_reject,this.email_notification_eventid,this.userid,cur_date_transform_email_reject,this.transactionService.getregioncode(this.current_region)).subscribe(email_status_reject=>{

    console.log('Inside Rejemail API', email_status_reject)
        console.log(email_status_reject);


        if(email_status_reject.toLowerCase()=='success')
        {


          this.messageService.add({severity:'success', summary: 'success', detail: 'Email sent for rejection successfully'});
          // this.view_proposal = false;
        }


        else{


          this.messageService.add({severity:'error', summary: 'Error', detail: 'Error in Email sending rejection'});

        }
      })

    }

      // Approved for Production Proposal Rejection - ok button notification
       ok_notification_dialog()
       {
         this.enable_dialog_notification=false;
       }


  confirmationLoadImprovementProposal()
  {

   
    var storedpartdetails=this.transactionService.getpartno_packpro(this.transactionService.getregion());
    this.improvementSelectedSimpliedPartnoDetails={simplifiedpartno:"-",bompartmasterid:0,proposalid:0,plantgsdbcode:"",proposallastsaved:0,part_status:"",supplier_code:"",proposallastsaved_ee:0,currentview_ee:false,is_ee_proposal:false,from_draft:false,propsl_type:"",imp_part_status:""};
  
     console.log("loadImprovementProposal Stored part",storedpartdetails);

     const imp_flag=sessionStorage.getItem('Improvement flag');

     // create new improvement
     console.log(imp_flag)
     if(imp_flag == null || imp_flag == 'null' || imp_flag.toLowerCase() == 'n')
     {
        this.confirmationService.confirm({
        message: 'Do you want to Create Improvement Proposal ?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {


          this.loadImprovementProposal();


        }
    });
  }
  else
  {
    this.loadImprovementProposal();
  }

  }

loadImprovementProposal()
{

  const storedpartdetails=this.transactionService.getpartno_packpro(this.transactionService.getregion());
  this.improvementSelectedSimpliedPartnoDetails={simplifiedpartno:'-',bompartmasterid:0,proposalid:0,plantgsdbcode:'',proposallastsaved:0,part_status:'',supplier_code:'',proposallastsaved_ee:0,currentview_ee:false,is_ee_proposal:false,from_draft:false,propsl_type:'',imp_part_status:''};

   console.log('loadImprovementProposal Stored part',storedpartdetails);

   // create new improvement
   // change to improvement part status
   const imp_flag_propcreation=sessionStorage.getItem('Improvement flag');
   if(imp_flag_propcreation == null || imp_flag_propcreation == 'null' || imp_flag_propcreation.toLowerCase() == 'n')
   {

    this.transactionService.getproposalid().subscribe(sub_proposalid=>{

      this.isloadersearchprop=true;
     

      console.log('Inside exist new Proposal id - ', sub_proposalid)

      this.improvementSelectedSimpliedPartnoDetails.bompartmasterid=storedpartdetails.bompartmasterid;
      this.improvementSelectedSimpliedPartnoDetails.simplifiedpartno=storedpartdetails.simplifiedpartno;
      this.improvementSelectedSimpliedPartnoDetails.proposalid=sub_proposalid;
      this.improvementSelectedSimpliedPartnoDetails.plantgsdbcode=storedpartdetails.plantgsdbcode;
      this.improvementSelectedSimpliedPartnoDetails.proposallastsaved=4;


      this.improvementSelectedSimpliedPartnoDetails.part_status=storedpartdetails.part_status;


      this.improvementSelectedSimpliedPartnoDetails.supplier_code=storedpartdetails.supplier_code.trim();
      this.improvementSelectedSimpliedPartnoDetails.currentview_ee=false;
      this.improvementSelectedSimpliedPartnoDetails.from_draft=true;

      this.improvementSelectedSimpliedPartnoDetails.propsl_type='Improvement'
      this.improvementSelectedSimpliedPartnoDetails.imp_part_status=storedpartdetails.imp_part_status







        if(this.improvementSelectedSimpliedPartnoDetails.proposallastsaved>=1)
        {
           this.transactionService.get_draft_Tab1_data(storedpartdetails.proposalid).subscribe(tab1_data=>{


             console.log('tab1t,',tab1_data);
            this.storeTab1Data=JSON.parse(JSON.stringify(tab1_data));
            const partNo=this.storeTab1Data.sp_part_no;
            sessionStorage.setItem('primaryPartNo',partNo);

            this.storeTab1Data.id_proposal=this.improvementSelectedSimpliedPartnoDetails.proposalid





                this.storeTab1PartData=[];
             this.transactionService.get_draft_selectedparts_data(storedpartdetails.proposalid).subscribe(selectedparts_data=>{

               console.log(selectedparts_data)
               this.storeTab1PartData.push(...selectedparts_data)

               for(let i=0; i<this.storeTab1PartData.length; i++)
               {
                 this.storeTab1PartData[i].id_proposal=this.improvementSelectedSimpliedPartnoDetails.proposalid;
                 // added to get bailment supplier
                 if(this.storeTab1PartData[i].isprimary  != null && this.storeTab1PartData[i].isprimary.toLowerCase()=='yes')
                 {
                  sessionStorage.setItem('offsitesupplierid',this.storeTab1PartData[i].offsitesupplierid);
                 }

               }






               if(this.improvementSelectedSimpliedPartnoDetails.proposallastsaved>=2)
               {


                 this.transactionService.loadtab2detailsifproposalexistalready(storedpartdetails.proposalid).subscribe(cont_cos_data=>
                   {
                     console.log(cont_cos_data);
                     this.storeTab2Data=JSON.parse(JSON.stringify(cont_cos_data))

                     this.storeTab2Data.id_proposal=this.improvementSelectedSimpliedPartnoDetails.proposalid;
                  

                     this.transactionService.loadtransacmasterdetails(storedpartdetails.proposalid).subscribe(transdet_search=>{

                      console.log(transdet_search);
                     this.proposal_details_search=transdet_search;

                     this.releaselevel = this.proposal_details_search.rel_level;
                     })

                     if(this.improvementSelectedSimpliedPartnoDetails.proposallastsaved>=3)
                     {
                       // service to call tab 3 get details
                       this.storeTab3CompData=[];

                       this.transactionService.getaddedcomponentdetailsfortab3(storedpartdetails.proposalid).subscribe(complist=>{



                         console.log(complist);
                         let temp;
                         temp=JSON.parse(JSON.stringify(complist))

                         console.log(temp);
                         if(temp!=null &&temp.length!=0)
                         {
                             this.storeTab3CompData.push(...temp)
                             for(let j=0; j<this.storeTab3CompData.length ; j++)
                             {
                               this.storeTab3CompData[j].id_proposal=this.improvementSelectedSimpliedPartnoDetails.proposalid
                             }

                         }
                         else{
                          this.storeTab3CompData=[];
                         }
                       })


                      this.transactionService.loadtab3detailsfromAPI(storedpartdetails.proposalid).subscribe(tab3_data=>{

                         console.log(tab3_data);

                        this.storeTab3Data=JSON.parse(JSON.stringify(tab3_data))
                        this.storeTab3Data.id_proposal=this.improvementSelectedSimpliedPartnoDetails.proposalid

                       // inside that service
                       if(this.improvementSelectedSimpliedPartnoDetails.proposallastsaved>=4)
                       {
                         // service to get tab4 details
                         // for images append the values with the base url if value is not null or ""
                         // in html add a senario when the value is so  to display empty without img tag


                         this.transactionService.loadtab4details(storedpartdetails.proposalid).subscribe(tab4_data=>{

                           console.log(tab4_data)
                           this.storeTab4Data=JSON.parse(JSON.stringify(tab4_data));
                           this.getTab4data=JSON.parse(JSON.stringify(tab4_data));
                           this.storeTab4Data.id_proposal=this.improvementSelectedSimpliedPartnoDetails.proposalid
                           if(this.storeTab4Data.f_partphoto != null && this.storeTab4Data.f_partphoto != '')
                           {
                           const arr = this.storeTab4Data.f_partphoto.split('/');
                           const len= arr.length;
                           arr[len-2]=this.improvementSelectedSimpliedPartnoDetails.proposalid.toString();
                           const joinArray=arr.join('/') ;
                           this.storeTab4Data.f_partphoto=joinArray;
                           }
                           else
                           {
                            this.storeTab4Data.f_partphoto='';
                           }


                           // interior container
                           if(this.storeTab4Data.f_intcntrdsgnphoto != null && this.storeTab4Data.f_intcntrdsgnphoto != '')
                           {
                           const arr = this.storeTab4Data.f_intcntrdsgnphoto.split('/');
                           const len= arr.length;
                           arr[len-2]=this.improvementSelectedSimpliedPartnoDetails.proposalid.toString();
                           const intrCntr=arr.join('/') ;
                           this.storeTab4Data.f_intcntrdsgnphoto=intrCntr;
                           }
                           else
                           {
                            this.storeTab4Data.f_intcntrdsgnphoto='';
                           }

                           // exterior container
                           if(this.storeTab4Data.f_extcntrdsgnphoto != null && this.storeTab4Data.f_extcntrdsgnphoto != '')
                           {
                           const arr = this.storeTab4Data.f_extcntrdsgnphoto.split('/');
                           const len= arr.length;
                           arr[len-2]=this.improvementSelectedSimpliedPartnoDetails.proposalid.toString();
                           const extrContainer=arr.join('/') ;
                           this.storeTab4Data.f_extcntrdsgnphoto=extrContainer;
                           }
                           else
                           {
                            this.storeTab4Data.f_extcntrdsgnphoto='';
                           }

                           // metal id
                           if(this.storeTab4Data.f_metelidplatephoto != null && this.storeTab4Data.f_metelidplatephoto != '')
                           {
                           const arr = this.storeTab4Data.f_metelidplatephoto.split('/');
                           const len= arr.length;
                           arr[len-2]=this.improvementSelectedSimpliedPartnoDetails.proposalid.toString();
                           const metalid=arr.join('/') ;
                           this.storeTab4Data.f_metelidplatephoto=metalid;
                           }
                           else
                           {
                            this.storeTab4Data.f_metelidplatephoto='';
                           }

                           // shipping unit photo
                           if(this.storeTab4Data.f_shpunitphoto != null && this.storeTab4Data.f_shpunitphoto != '')
                           {
                           const arr = this.storeTab4Data.f_shpunitphoto.split('/');
                           const len= arr.length;
                           arr[len-2]=this.improvementSelectedSimpliedPartnoDetails.proposalid.toString();
                           const suPhoto=arr.join('/') ;
                           this.storeTab4Data.f_shpunitphoto=suPhoto;
                           }
                           else
                           {
                            this.storeTab4Data.f_shpunitphoto='';
                           }


                              // sbu pl doc
                              if(this.storeTab4Data.subpresloaddoc != null && this.storeTab4Data.subpresloaddoc != '')
                              {
                              const arr = this.storeTab4Data.subpresloaddoc.split('/');
                              const len= arr.length;
                              const doc_name=arr[len-1]
                              const arr1=doc_name.split('_');
                              arr1[0]=this.improvementSelectedSimpliedPartnoDetails.proposalid.toString();
                              const joined_name=arr1.join('_')
                              arr[len-1]=joined_name
                              const suPhoto=arr.join('/') ;
                              this.storeTab4Data.subpresloaddoc=suPhoto;
                              }
                              else
                              {
                               this.storeTab4Data.subpresloaddoc='';
                              }
                           this.get_part_image();
                          this.get_exterior_image();
                          this.get_interior_image();
                          this.get_label_image();
                          this.get_shipping_image();
                          this.get_sbupl();

//  get image and store image in nfs
                          
       
       
                          if(tab4_data.createmgcyexpndpckgprop != null && tab4_data.createmgcyexpndpckgprop != "" && tab4_data.createmgcyexpndpckgprop.toLowerCase() == 'yes')
                          {

                            this.improvementSelectedSimpliedPartnoDetails.proposallastsaved_ee=4;
                            this.improvementSelectedSimpliedPartnoDetails.is_ee_proposal=true;


                           if(this.improvementSelectedSimpliedPartnoDetails.proposallastsaved_ee>=2)
                           {


                             this.transactionService.loadtab2detailsifproposalexistalready_ee(storedpartdetails.proposalid).subscribe(cont_cos_data_ee=>
                               {
                                 console.log(cont_cos_data_ee);
                                 this.storeTab2Data_ee=JSON.parse(JSON.stringify(cont_cos_data_ee))
                                 this.storeTab2Data_ee.id_proposal=this.improvementSelectedSimpliedPartnoDetails.proposalid






                                 if(this.improvementSelectedSimpliedPartnoDetails.proposallastsaved_ee>=3)
                                 {
                                   // service to call tab 3 get details

                                  this.storeTab3CompData_ee=[];
                                   this.transactionService.getaddedcomponentdetailsfortab3_ee(storedpartdetails.proposalid).subscribe(complist_ee=>{



                                     console.log(complist_ee);
                                     let temp;
                                     temp=JSON.parse(JSON.stringify(complist_ee))

                                     console.log(temp);
                                     if(temp!=null &&temp.length!=0)
                                     {
                                      this.storeTab3CompData_ee.push(...temp);
                                      for(let k=0; k<this.storeTab3CompData_ee.length; k++)
                                      {
                                        this.storeTab3CompData_ee[k].id_proposal=this.improvementSelectedSimpliedPartnoDetails.proposalid
                                      }
                                     }
                                     else{

                                      this.storeTab3CompData_ee=[];

                                     }
                                   })


                                  this.transactionService.loadtab3detailsfromAPI_ee(storedpartdetails.proposalid).subscribe(tab3_data_ee=>{

                                     console.log(tab3_data_ee);

                                     this.storeTab3Data_ee=JSON.parse(JSON.stringify(tab3_data_ee))
                                     this.storeTab3Data_ee.id_proposal=this.improvementSelectedSimpliedPartnoDetails.proposalid



                                   // inside that service
                                   if(this.improvementSelectedSimpliedPartnoDetails.proposallastsaved_ee>=4)
                                   {
                                     // service to get tab4 details
                                     // for images append the values with the base url if value is not null or ""
                                     // in html add a senario when the value is so  to display empty without img tag


                                     this.transactionService.loadtab4details_ee(storedpartdetails.proposalid).subscribe(tab4_data_ee=>{
                                      console.log(tab4_data_ee)
                                      this.storeTab4Data_ee=JSON.parse(JSON.stringify(tab4_data_ee))
                                      this.getTab4data_ee=JSON.parse(JSON.stringify(tab4_data_ee));
                                      this.storeTab4Data_ee.id_proposal=this.improvementSelectedSimpliedPartnoDetails.proposalid
                                      if(this.storeTab4Data_ee.f_partphoto != null && this.storeTab4Data_ee.f_partphoto != '')
                                      {
                                      const arr = this.storeTab4Data_ee.f_partphoto.split('/');
                                      const len= arr.length;
                                      arr[len-2]=this.improvementSelectedSimpliedPartnoDetails.proposalid.toString();
                                      const joinArray=arr.join('/') ;
                                      this.storeTab4Data_ee.f_partphoto=joinArray;
                                      }
                                      else
                                      {
                                       this.storeTab4Data_ee.f_partphoto='';
                                      }


                                      // interior container
                                      if(this.storeTab4Data_ee.f_intcntrdsgnphoto != null && this.storeTab4Data_ee.f_intcntrdsgnphoto != '')
                                      {
                                      const arr = this.storeTab4Data_ee.f_intcntrdsgnphoto.split('/');
                                      const len= arr.length;
                                      arr[len-2]=this.improvementSelectedSimpliedPartnoDetails.proposalid.toString();
                                      const intrCntr=arr.join('/') ;
                                      this.storeTab4Data_ee.f_intcntrdsgnphoto=intrCntr;
                                      }
                                      else
                                      {
                                       this.storeTab4Data_ee.f_intcntrdsgnphoto='';
                                      }

                                      // exterior container
                                      if(this.storeTab4Data_ee.f_extcntrdsgnphoto != null && this.storeTab4Data_ee.f_extcntrdsgnphoto != '')
                                      {
                                      const arr = this.storeTab4Data_ee.f_extcntrdsgnphoto.split('/');
                                      const len= arr.length;
                                      arr[len-2]=this.improvementSelectedSimpliedPartnoDetails.proposalid.toString();
                                      const extrContainer=arr.join('/') ;
                                      this.storeTab4Data_ee.f_extcntrdsgnphoto=extrContainer;
                                      }
                                      else
                                      {
                                       this.storeTab4Data_ee.f_extcntrdsgnphoto='';
                                      }

                                      // metal id
                                      if(this.storeTab4Data_ee.f_metelidplatephoto != null && this.storeTab4Data_ee.f_metelidplatephoto != '')
                                      {
                                      const arr = this.storeTab4Data_ee.f_metelidplatephoto.split('/');
                                      const len= arr.length;
                                      arr[len-2]=this.improvementSelectedSimpliedPartnoDetails.proposalid.toString();
                                      const metalid=arr.join('/') ;
                                      this.storeTab4Data_ee.f_metelidplatephoto=metalid;
                                      }
                                      else
                                      {
                                       this.storeTab4Data_ee.f_metelidplatephoto='';
                                      }

                                      // shipping unit photo
                                      if(this.storeTab4Data_ee.f_shpunitphoto != null && this.storeTab4Data_ee.f_shpunitphoto != '')
                                      {
                                      const arr = this.storeTab4Data_ee.f_shpunitphoto.split('/');
                                      const len= arr.length;
                                      arr[len-2]=this.improvementSelectedSimpliedPartnoDetails.proposalid.toString();
                                      const suPhoto=arr.join('/') ;
                                      this.storeTab4Data_ee.f_shpunitphoto=suPhoto;
                                      }
                                      else
                                      {
                                       this.storeTab4Data_ee.f_shpunitphoto='';
                                      }
                                            // sbu pl doc
                                    if(this.storeTab4Data_ee.subpresloaddoc != null && this.storeTab4Data_ee.subpresloaddoc != '')
                                    {
                                    const arr = this.storeTab4Data_ee.subpresloaddoc.split('/');
                                    const len= arr.length;
                                    const doc_name=arr[len-1]
                                    const arr1=doc_name.split('_');
                                    arr1[0]=this.improvementSelectedSimpliedPartnoDetails.proposalid.toString();
                                    const joined_name=arr1.join('_')
                                    arr[len-1]=joined_name
                                    const suPhoto=arr.join('/') ;
                                    this.storeTab4Data_ee.subpresloaddoc=suPhoto;
                                    }
                                    else
                                    {
                                    this.storeTab4Data_ee.subpresloaddoc='';
                                    }
                                       this.get_part_image_ee();
                                      this.get_exterior_image_ee();
                                      this.get_interior_image_ee();
                                      this.get_label_image_ee();
                                      this.get_shipping_image_ee();
                                      this.get_sbupl_ee();


                   //  get image and store image in nfs

                                            this.postDraftTabData();


                                     })
                                   }


                                 })
                                 }

                               })

                           }

                          }

                          else
                          {
                          this.improvementSelectedSimpliedPartnoDetails.proposallastsaved_ee=1;
                          this.improvementSelectedSimpliedPartnoDetails.is_ee_proposal=false;


                          this.postDraftTabData();
                          }





                         })
                       }


                     })
                     }

                   })

               }




             })




           })
         }









     // this.storePrimaryPart();
    })

   }

   else
   {

    // exsisting proposal function
// in api input add "improvement" value after api changes 
    this.transactionService.get_exist_proposalid(storedpartdetails.simplifiedpartno,storedpartdetails.plantgsdbcode,"Improvement",storedpartdetails.supplier_code).subscribe(sub_exist_proposalid=>{

      var partNo=sub_exist_proposalid.sp_part_no;
      sessionStorage.setItem("primaryPartNo",partNo);

    this.improvementSelectedSimpliedPartnoDetails.bompartmasterid=storedpartdetails.bompartmasterid;
    this.improvementSelectedSimpliedPartnoDetails.simplifiedpartno=storedpartdetails.simplifiedpartno;
    this.improvementSelectedSimpliedPartnoDetails.proposalid=sub_exist_proposalid.id_proposal;
    this.improvementSelectedSimpliedPartnoDetails.plantgsdbcode=storedpartdetails.plantgsdbcode;
    this.improvementSelectedSimpliedPartnoDetails.proposallastsaved=sub_exist_proposalid.lst_saved_tab;


    this.improvementSelectedSimpliedPartnoDetails.part_status=storedpartdetails.part_status;


    this.improvementSelectedSimpliedPartnoDetails.supplier_code=storedpartdetails.supplier_code.trim();
    this.improvementSelectedSimpliedPartnoDetails.currentview_ee=false;
    this.improvementSelectedSimpliedPartnoDetails.from_draft=true;
    this.improvementSelectedSimpliedPartnoDetails.proposallastsaved_ee=sub_exist_proposalid.lst_saved_tab_ee


    this.improvementSelectedSimpliedPartnoDetails.propsl_type='Improvement'
    this.improvementSelectedSimpliedPartnoDetails.imp_part_status=storedpartdetails.imp_part_status.trim();

   // added to get bailment supplier

    this.transactionService.get_draft_selectedparts_data(sub_exist_proposalid.id_proposal).subscribe(selectedparts_data=>{

      console.log(selectedparts_data)


      for(let i=0; i<selectedparts_data.length; i++)
      {

        if(selectedparts_data[i].isprimary  != null && selectedparts_data[i].isprimary.toLowerCase()=='yes')
        {
         sessionStorage.setItem('offsitesupplierid',selectedparts_data[i].offsitesupplierid);
         break;
        }

      }


    if(sub_exist_proposalid.lst_saved_tab >=4)
    {
      this.transactionService.loadtab4details(this.improvementSelectedSimpliedPartnoDetails.proposalid).subscribe(tab4Data=>{

        console.log(tab4Data);
        if(tab4Data.createmgcyexpndpckgprop != null && tab4Data.createmgcyexpndpckgprop != '' && tab4Data.createmgcyexpndpckgprop.toLowerCase() == 'yes')
        {
          this.improvementSelectedSimpliedPartnoDetails.is_ee_proposal=true;
        }
        else
        {
          this.improvementSelectedSimpliedPartnoDetails.is_ee_proposal=false;
        }
        this.setChangestatusSession();
      })
    }
    else
    {
      this.improvementSelectedSimpliedPartnoDetails.is_ee_proposal=false;
      this.setChangestatusSession();
    }


    })
   })

   }


}


setChangestatusSession()
{
  this.transactionService.getImprovementChangeStatus(this.improvementSelectedSimpliedPartnoDetails.proposalid).subscribe(changeStatus=>{
    console.log('setChangestatusSession api o/p',changeStatus)
    console.log(changeStatus.status);
        if(changeStatus.status != null)
        {
          sessionStorage.setItem('ProposalChangeStatus',changeStatus.status);
          console.log(sessionStorage.getItem('ProposalChangeStatus'))
          this.improvement_store_primary_part();
        }
        else
        {
          sessionStorage.setItem('ProposalChangeStatus','unchanged');
          console.log(sessionStorage.getItem('ProposalChangeStatus'))
          this.improvement_store_primary_part();
        }




  })
}



postDraftTabData()
{
  // load image and stor image in nfs


  console.log('postDrafDatatab1',this.storeTab1Data)
  this.storeTab1Data.propsl_type='Improvement'
  console.log('postDrafDatatab1part',this.storeTab1PartData)
  console.log('postDrafDatatab2',this.storeTab2Data)
  console.log('postDrafDatatab3',this.storeTab3Data)
  console.log('postDrafDatatab3comp',this.storeTab3CompData)
  console.log('postDrafDatatab4',this.storeTab4Data)
  console.log('postDrafDatatab1ee',this.storeTab1Data_ee)
  // this.storeTab1Data_ee.propsl_type="Improvement"
  console.log('postDrafDatatab1partee',this.storeTab1PartData_ee)
  console.log('postDrafDatatab2ee',this.storeTab2Data_ee)
  console.log('postDrafDatatab3ee',this.storeTab3Data_ee)
  console.log('postDrafDatatab3compee',this.storeTab3CompData_ee)
  console.log('postDrafDatatab4ee',this.storeTab4Data_ee)
  this.transactionService.savePrimaryPartDetails(this.storeTab1Data).subscribe(saveandcloseattributes=>
    {
      const opsc=saveandcloseattributes;
      console.log('successful',opsc);
      if(saveandcloseattributes=='Success')
      {
        console.log('primarypart attributes in save',this.storeTab1Data);
        // this.packagingproposal.addtoast({severity:'success', summary:'Success', detail:'primary part and attributes Added Successfully'});

        this.transactionService.savePartDetails(this.storeTab1PartData).subscribe(getprimaryinsaveclose=>
          {
            let checkinsaveclose=getprimaryinsaveclose;
            if(checkinsaveclose='Success')
            {
              console.log('primarypart  in save',this.storeTab1PartData);
              this.transactionService.savetab2details(this.storeTab2Data).subscribe(result=>
                {
                  let msgs:any[];
                  if(result=='Success')
                  {
                    console.log('save tab 2 det')
                    this.transactionService.savetab3details(this.storeTab3Data).subscribe(response=>
                      {
                          let msgs:any[];
                          if(response=='Success')
                          {
                            console.log('save tab 3 details');
                            if(this.storeTab3CompData != null && this.storeTab3CompData.length != 0)
                            {
                              this.transactionService.storemultiplecomponentdetails(this.storeTab3CompData).subscribe(editout=>{
                                const editstatus=editout;
                                console.log('Success',editstatus);
                                if(editstatus=='Success')
                                {
                                  console.log('save tab3 comp');
                                }
                                else
                                {
                                  // error tab 3 comp save
                                  this.isloadersearchprop=false;
                                  this.messageService.add({severity:'error', summary: 'Error', detail: 'Tab3 Components not saved,Try Again'});

                                }
                              })
                            }


                            this.transactionService.savetab4details(this.storeTab4Data).subscribe(response=>
                              {
                                  let msgs:any[];
                                  if(response=='Success')
                                  {
                                    console.log('save tab 4 details');

                                    if(this.improvementSelectedSimpliedPartnoDetails.is_ee_proposal == true)
                                    {
                                      this.postDraftTabData_ee()
                                    }
                                    else
                                    {
                                      this.UpdateChangeApi();
                                    }

                                  }
                                  else
                                  {
                                    // error tab 4 save
                                    this.isloadersearchprop=false;
                                    this.messageService.add({severity:'error', summary: 'Error', detail: 'Tab4 not saved,Try Again'});

                                  }
                                })


                          }

                          else
                          {
                            // error for save tab3 details
                            this.isloadersearchprop=false;
                            this.messageService.add({severity:'error', summary: 'Error', detail: 'Tab3 not saved,Try Again'});

                          }
                        })

                  }

                  else
                  {
                    // error tab2 details failed
                    this.isloadersearchprop=false;
                    this.messageService.add({severity:'error', summary: 'Error', detail: 'Tab2 not saved,Try Again'});

                  }
                })
            }
            else
            {
              // error for part details store in selected parts
              this.isloadersearchprop=false;
              this.messageService.add({severity:'error', summary: 'Error', detail: 'Tab1 selected parts not saved,Try Again'});

            }

          })
        }


        else
        {
          // error for primary details
          this.isloadersearchprop=false;
          this.messageService.add({severity:'error', summary: 'Error', detail: 'Tab1 not saved,Try Again'});

        }
      })
}


postDraftTabData_ee()
{

  // load image and stor image in nfs for ee

  this.transactionService.savetab2details_ee(this.storeTab2Data_ee).subscribe(result_ee=>
    {
      let msgs:any[];
      if(result_ee=='Success')
      {
        console.log('save tab 2 ee det')
        this.transactionService.savetab3details_ee(this.storeTab3Data_ee).subscribe(response_ee=>
          {
              let msgs:any[];
              if(response_ee=='Success')
              {
                console.log('save tab 3 ee details');
               if(this.storeTab3CompData_ee != null && this.storeTab3CompData_ee.length != 0)
                {
                  this.transactionService.storemultiplecomponentdetails_ee(this.storeTab3CompData_ee).subscribe(editout_ee=>{
                    const editstatus=editout_ee;
                    console.log('Success',editstatus);
                    if(editstatus=='Success')
                    {
                      console.log('save tab3 ee comp');
                    }
                    else
                    {
                      // error tab 3 comp save
                      this.isloadersearchprop=false;
                      this.messageService.add({severity:'error', summary: 'Error', detail: 'EE Tab3 Components not saved,Try Again'});

                    }
                  })
                }

                this.transactionService.savetab4details_ee(this.storeTab4Data_ee).subscribe(response_ee=>
                  {
                      let msgs:any[];
                      if(response_ee=='Success')
                      {
                        console.log('save tab 4 ee details');

                        this.UpdateChangeApi();


                      }
                      else
                      {
                        // error tab 4 save
                        this.isloadersearchprop=false;
                        this.messageService.add({severity:'error', summary: 'Error', detail: 'EE Tab4 not saved,Try Again'});

                      }
                    })


              }

              else
              {
                // error for save tab3 details
                this.isloadersearchprop=false;
                this.messageService.add({severity:'error', summary: 'Error', detail: 'EE Tab3 not saved,Try Again'});

              }
            })

      }

      else
      {
        // error tab2 details failed
        this.isloadersearchprop=false;
        this.messageService.add({severity:'error', summary: 'Error', detail: 'EE Tab2 not saved,Try Again'});

      }
    })


}


UpdateChangeApi()
{

               // update change table - new api call - update status to "Unchanged"
               const setChangestatus:changeTrackingForImprovement={idproposal:0,chnge_timestamp:'',status:''}
               setChangestatus.idproposal=this.improvementSelectedSimpliedPartnoDetails.proposalid;
               setChangestatus.status='Unchanged';
              const cur_date=new Date();
              const cur_date_transform=this.date.transform(cur_date, 'yyyy-MM-dd HH:mm:ss');
               setChangestatus.chnge_timestamp=cur_date_transform
               console.log('update change status ',setChangestatus)
               this.transactionService.setImprovementChangeStatus(setChangestatus).subscribe(chang_stat=>{
                 console.log('change status update',chang_stat);

                 if(chang_stat.toLowerCase() == 'success')
                 {
                   sessionStorage.setItem('ProposalChangeStatus',setChangestatus.status);

                 }

                 /*if(chang_stat.toLowerCase() == "success")
                 {
                   //message service for success for change status
                   this.improvement_store_primary_part()

                 }
                 else
                 {
                   //message service for error for change status
                 }*/
                 this.improvementSelectedSimpliedPartnoDetails.imp_part_status='UnSubmitted';

                 this.isloadersearchprop=false;
                 this.improvement_store_primary_part()

               })




}


improvement_store_primary_part()
{
  const regcode=this.region_code;
  // this.transactionService.getregioncode(this.selected_region_from_list);
  console.log('imp_part_status', this.improvementSelectedSimpliedPartnoDetails.imp_part_status)

  if(regcode=='NA')
  {
    sessionStorage.setItem('napackpropart', JSON.stringify(this.improvementSelectedSimpliedPartnoDetails));
  }

  if(regcode=='EU')
  {
    sessionStorage.setItem('eupackpropart', JSON.stringify(this.improvementSelectedSimpliedPartnoDetails));
  }

  if(regcode=='AP')
  {
    sessionStorage.setItem('appackpropart', JSON.stringify(this.improvementSelectedSimpliedPartnoDetails));
  }

  if(regcode=='SA')
  {
    sessionStorage.setItem('sapackpropart', JSON.stringify(this.improvementSelectedSimpliedPartnoDetails));
  }

  const imp_pro_flag=sessionStorage.getItem('Improvement flag');

  if(imp_pro_flag == null || imp_pro_flag == 'null' || imp_pro_flag.toLowerCase() == 'n')
  {
    sessionStorage.setItem('Improvement flag','I')
  }


  const r=this.transactionService.getpartno_packpro(this.current_region);
  console.log('improvementSelectedSimpliedPartnoDetails-after save',r);

  let reg_supp_sub_url:string;
  reg_supp_sub_url=this.transactionService.getregionurlvalue(this.current_region);
  reg_supp_sub_url+='packagingprocessdashboard/improvementproposalcreation';

  this.transactionService.loadmenuwhenclickedfromdashboard('Yes');
  this.router.navigate([reg_supp_sub_url]);





}


get_part_image()
{



  if(this.getTab4data.f_partphoto!='' && this.getTab4data.f_partphoto!=null)
  {
    const prt=this.baseurl_get+this.getTab4data.f_partphoto;
  this.part_image=prt;

  console.log('part',prt)

  this.transactionService.getData(prt).subscribe(
    imgData => {
      console.log('image from url',imgData);
      this.image_folder_path=this.storeTab4Data.id_proposal.toString();

      this.transactionService.PartPhoto_postFile(imgData,this.image_folder_path).subscribe((data => {
        const res=data;

        console.log('Response',res);
           if(res == this.imgsave_path+this.image_folder_path+'/PartPhoto.png') {

            console.log('Upload is success '+ res);
             }
              else   {
             console.log('Upload is not success '+res);
             this.messageService.add({severity:'error', summary: 'Error', detail: 'Part image not uploaded,Please Try Again'});

                     }
        }));
              },

    err => console.log(err) )


  }
  else{
    this.part_image='';
  }
}

get_interior_image()
{


  if(this.getTab4data.f_intcntrdsgnphoto!='' && this.getTab4data.f_intcntrdsgnphoto!=null)
  {
  this.interior_image=this.baseurl_get+this.getTab4data.f_intcntrdsgnphoto;


  this.transactionService.getData(this.interior_image).subscribe(
    imgData => {
      console.log('image from url',imgData);
      this.image_folder_path=this.storeTab4Data.id_proposal.toString();

      this.transactionService.InteriorContainerPhoto_postFile(imgData,this.image_folder_path).subscribe((data => {
        const res=data;

        console.log('Response',res);
           if(res == this.imgsave_path+this.image_folder_path+'/intrCntnrDsgnPhoto.png') {

            console.log('Upload is success '+ res);

             }
              else   {
             console.log('Upload is not success '+res);
             this.messageService.add({severity:'error', summary: 'Error', detail: 'Interior Container image not uploaded,Please Try Again'});

                     }
        }));
              },

    err => console.log(err) )

  }
  else{
    this.interior_image='';
  }

}
get_exterior_image()
{


  if(this.getTab4data.f_extcntrdsgnphoto!='' && this.getTab4data.f_extcntrdsgnphoto!=null)
  {
  this.exterior_image=this.baseurl_get+this.getTab4data.f_extcntrdsgnphoto;


  this.transactionService.getData(this.exterior_image).subscribe(
    imgData => {
      console.log('image from url',imgData);
      this.image_folder_path=this.storeTab4Data.id_proposal.toString();

      this.transactionService.ExteriorContainerPhoto_postFile(imgData,this.image_folder_path).subscribe((data => {
        const res=data;

        console.log('Response',res);
           if(res == this.imgsave_path+this.image_folder_path+'/extrCntnrDsgnPhoto.png') {

            console.log('Upload is success '+ res);


             }
              else   {
             console.log('Upload is not success '+res);
             this.messageService.add({severity:'error', summary: 'Error', detail: 'Exterior Container image not uploaded,Please Try Again'});

                     }
        }));
              },

    err => console.log(err) )

  }
  else{
    this.exterior_image='';
  }

}
get_label_image()
{


  if(this.getTab4data.f_metelidplatephoto!='' && this.getTab4data.f_metelidplatephoto!=null)
  {
  this.label_image=this.baseurl_get+this.getTab4data.f_metelidplatephoto;

  this.transactionService.getData(this.label_image).subscribe(
    imgData => {
      console.log('image from url',imgData);
      this.image_folder_path=this.storeTab4Data.id_proposal.toString();

      this.transactionService.LabelMetalIDPhoto_postFile(imgData,this.image_folder_path).subscribe((data => {
        const res=data;

        console.log('Response',res);
           if(res == this.imgsave_path+this.image_folder_path+'/labelmetalID.png') {

            console.log('Upload is success '+ res);


             }
              else   {
             console.log('Upload is not success '+res);
             this.messageService.add({severity:'error', summary: 'Error', detail: 'label image not uploaded,Please Try Again'});

                     }
        }));
              },

    err => console.log(err) )

  }
  else{
    this.label_image='';
  }


  console.log(this.label_image);

}
get_shipping_image()
{


  if(this.getTab4data.f_shpunitphoto!='' && this.getTab4data.f_shpunitphoto!=null)
  {
  this.shipping_image=this.baseurl_get+this.getTab4data.f_shpunitphoto;

  this.transactionService.getData(this.shipping_image).subscribe(
    imgData => {
      console.log('image from url',imgData);
      this.image_folder_path=this.storeTab4Data.id_proposal.toString();

      this.transactionService.ShippingUnitPhoto_postFile(imgData,this.image_folder_path).subscribe((data => {
        const res=data;

        console.log('Response',res);
           if(res == this.imgsave_path+this.image_folder_path+'/shpngUntPhoto.png') {

            console.log('Upload is success '+ res);


             }
              else   {
             console.log('Upload is not success '+res);
             this.messageService.add({severity:'error', summary: 'Error', detail: 'shipping unit image not uploaded,Please Try Again'});

                     }
        }));
              },

    err => console.log(err) )

  }
  else{
    this.shipping_image='';
  }

}

// sbupl
get_sbupl()
{


  if(this.getTab4data.subpresloaddoc!='' && this.getTab4data.subpresloaddoc!=null)
  {
  this.sbupl=this.baseurl_get_sbupl+this.getTab4data.subpresloaddoc;

  this.transactionService.getData(this.sbupl).subscribe(
    imgData => {
      console.log('sbu pl image from url',imgData);
      this.image_folder_path=this.storeTab4Data.id_proposal.toString();
      const path_pressload='pressloaddoc/'
      const extention=imgData.name.split('?')[0].split('.').pop();

      this.transactionService.sbupl_postFile(imgData,this.image_folder_path).subscribe((data => {
        const res=data;



        console.log('Response',res);
           //if(res == this.imgsave_path+path_pressload+this.image_folder_path+'_presslinedoc.'+extention) {
            if(res == path_pressload+this.image_folder_path+'_presslinedoc.'+extention) {

            console.log('Upload is success '+ res);


             }
              else   {

             this.messageService.add({severity:'error', summary: 'Error', detail: 'sbupl not uploaded,Please Try Again'});




                     }
        }));
              },

    err => console.log(err) )

  }
  else{
    this.sbupl='';
  }

}





get_part_image_ee()
{



  if(this.getTab4data_ee.f_partphoto!='' && this.getTab4data_ee.f_partphoto!=null)
  {
    const prt=this.baseurl_get+this.getTab4data_ee.f_partphoto;
  this.part_image_ee=prt;

  console.log('part',prt)

  this.transactionService.getData(this.part_image_ee).subscribe(
    imgData => {
      console.log('image from url',imgData);
      this.image_folder_path=this.storeTab4Data_ee.id_proposal.toString();

      this.transactionService.PartPhoto_postFile_ee(imgData,this.image_folder_path).subscribe((data => {
        const res=data;

        console.log('Response',res);
           if(res == this.imgsave_path+this.image_folder_path+'/PartPhoto_ee.png') {

            console.log('Upload is success '+ res);
             }
              else   {
             console.log('Upload is not success '+res);
             this.messageService.add({severity:'error', summary: 'Error', detail: 'Part image EE not uploaded,Please Try Again'});

                     }
        }));
              },

    err => console.log(err) )


  }
  else{
    this.part_image_ee='';
  }
}

get_interior_image_ee()
{


  if(this.getTab4data_ee.f_intcntrdsgnphoto!='' && this.getTab4data_ee.f_intcntrdsgnphoto!=null)
  {
  this.interior_image_ee=this.baseurl_get+this.getTab4data_ee.f_intcntrdsgnphoto;


  this.transactionService.getData(this.interior_image_ee).subscribe(
    imgData => {
      console.log('image from url',imgData);
      this.image_folder_path=this.storeTab4Data_ee.id_proposal.toString();

      this.transactionService.InteriorContainerPhoto_postFile_ee(imgData,this.image_folder_path).subscribe((data => {
        const res=data;

        console.log('Response',res);
           if(res == this.imgsave_path+this.image_folder_path+'/intrCntnrDsgnPhoto_ee.png') {

            console.log('Upload is success '+ res);

             }
              else   {
             console.log('Upload is not success '+res);
             this.messageService.add({severity:'error', summary: 'Error', detail: 'Interior Container EE image not uploaded,Please Try Again'});

                     }
        }));
              },

    err => console.log(err) )

  }
  else{
    this.interior_image_ee='';
  }

}
get_exterior_image_ee()
{


  if(this.getTab4data_ee.f_extcntrdsgnphoto!='' && this.getTab4data_ee.f_extcntrdsgnphoto!=null)
  {
  this.exterior_image_ee=this.baseurl_get+this.getTab4data_ee.f_extcntrdsgnphoto;


  this.transactionService.getData(this.exterior_image_ee).subscribe(
    imgData => {
      console.log('image from url',imgData);
      this.image_folder_path=this.storeTab4Data_ee.id_proposal.toString();

      this.transactionService.ExteriorContainerPhoto_postFile_ee(imgData,this.image_folder_path).subscribe((data => {
        const res=data;

        console.log('Response',res);
           if(res == this.imgsave_path+this.image_folder_path+'/extrCntnrDsgnPhoto_ee.png') {

            console.log('Upload is success '+ res);


             }
              else   {
             console.log('Upload is not success '+res);
             this.messageService.add({severity:'error', summary: 'Error', detail: 'Exterior Container image not uploaded,Please Try Again'});

                     }
        }));
              },

    err => console.log(err) )

  }
  else{
    this.exterior_image_ee='';
  }

}
get_label_image_ee()
{


  if(this.getTab4data_ee.f_metelidplatephoto!='' && this.getTab4data_ee.f_metelidplatephoto!=null)
  {
  this.label_image_ee=this.baseurl_get+this.getTab4data_ee.f_metelidplatephoto;

  this.transactionService.getData(this.label_image_ee).subscribe(
    imgData => {
      console.log('image from url',imgData);
      this.image_folder_path=this.storeTab4Data_ee.id_proposal.toString();

      this.transactionService.LabelMetalIDPhoto_postFile_ee(imgData,this.image_folder_path).subscribe((data => {
        const res=data;

        console.log('Response',res);
           if(res == this.imgsave_path+this.image_folder_path+'/labelmetalID_ee.png') {

            console.log('Upload is success '+ res);


             }
              else   {
             console.log('Upload is not success '+res);
             this.messageService.add({severity:'error', summary: 'Error', detail: 'label image EE not uploaded,Please Try Again'});

                     }
        }));
              },

    err => console.log(err) )

  }
  else{
    this.label_image_ee='';
  }


  console.log(this.label_image_ee);

}
get_shipping_image_ee()
{


  if(this.getTab4data_ee.f_shpunitphoto!='' && this.getTab4data_ee.f_shpunitphoto!=null)
  {
  this.shipping_image_ee=this.baseurl_get+this.getTab4data_ee.f_shpunitphoto;

  this.transactionService.getData(this.shipping_image_ee).subscribe(
    imgData => {
      console.log('image from url',imgData);
      this.image_folder_path=this.storeTab4Data_ee.id_proposal.toString();

      this.transactionService.ShippingUnitPhoto_postFile_ee(imgData,this.image_folder_path).subscribe((data => {
        const res=data;

        console.log('Response',res);
           if(res == this.imgsave_path+this.image_folder_path+'/shpngUntPhoto_ee.png') {

            console.log('Upload is success '+ res);


             }
              else   {
             console.log('Upload is not success '+res);
             this.messageService.add({severity:'error', summary: 'Error', detail: 'shipping unit image not uploaded,Please Try Again'});

                     }
        }));
              },

    err => console.log(err) )

  }
  else{
    this.shipping_image_ee='';
  }

}


// sbupl
get_sbupl_ee()
{


  if(this.getTab4data_ee.subpresloaddoc!='' && this.getTab4data_ee.subpresloaddoc!=null)
  {
  this.sbupl_ee=this.baseurl_get_sbupl+this.getTab4data_ee.subpresloaddoc;

  this.transactionService.getData(this.sbupl_ee).subscribe(
    imgData => {
      console.log('sbu pl image from url',imgData);
      this.image_folder_path=this.storeTab4Data_ee.id_proposal.toString();
      const path_pressload='pressloaddoc/'
      const extention=imgData.name.split('?')[0].split('.').pop();

      this.transactionService.sbupl_postFile_ee(imgData,this.image_folder_path).subscribe((data => {
        const res=data;



        console.log('Response',res);
           //if(res == this.imgsave_path+path_pressload+this.image_folder_path+'_presslinedoc.'+extention) {
            if(res == path_pressload+this.image_folder_path+'_presslinedoc.'+extention) {

            console.log('Upload is success '+ res);


             }
              else   {

             this.messageService.add({severity:'error', summary: 'Error', detail: 'sbupl not uploaded,Please Try Again'});




                     }
        }));
              },

    err => console.log(err) )

  }
  else{
    this.sbupl_ee='';
  }

}


view_revision_history_search()
{


this.enabledetails_search=true;
console.log('revision history');
/*
this.transactionService.loadtransacmasterdetails(this.selectedsimpliedpartnodetails.proposalid).subscribe(transdet_search=>{

  console.log(transdet_search);
 this.proposal_details_search=transdet_search;


 console.log(this.proposal_details_search.revision_hstry);
  var revision_arr=[];
  this.revision_obj_search=[];

  if(this.proposal_details_search.revision_hstry!=null)

 //if(transdet!=null)
  {
     revision_arr=this.proposal_details_search.revision_hstry.split('|')
    //revision_arr=transdet.split('|')
    console.log(revision_arr)


         for(var i=0;i<revision_arr.length;i++)
         {
           var rev_arr=[];
           var rev_htm_str="";
           rev_arr=revision_arr[i].split('`');




           if(rev_arr.length==7)
           {

           rev_htm_str="<b>"+rev_arr[5]+";"+rev_arr[1]+"("+rev_arr[2]+")"+"</b>"+"<br>"+rev_arr[3]+"-"+rev_arr[4];
           if(rev_arr[6]!="-")
           {
             rev_htm_str+="<br>"+"Comments-"+rev_arr[6];
           }
          }


          this.revision_obj_search.push({rev_string:revision_arr[i],rev_html_string:rev_htm_str})

         }

  }


  console.log(this.revision_obj_search);


  this.revision_obj_search=this.revision_obj_search.reverse();
  console.log(this.revision_obj_search)

})
*/

this.transactionService.getRevisionHistoryByProposalId(this.selectedsimpliedpartnodetails.proposalid).subscribe(revHistArray=>{

  this.revHistFromServiceAPI=[];
  this.revHistFromServiceAPI=revHistArray;
  this.revision_obj_search=[];
  console.log('getRevisionHistoryByProposalId API o/p ',this.revHistFromServiceAPI);



  if(this.revHistFromServiceAPI != null && this.revHistFromServiceAPI.length != 0)
  {
    for(let i=0; i<this.revHistFromServiceAPI.length; i++)
    {
      let rev_htm_str='';


      if(this.revHistFromServiceAPI[i].evnttime != null && this.revHistFromServiceAPI[i].usrname != null)
      {
        rev_htm_str='<b>'+this.revHistFromServiceAPI[i].evnttime+';'+this.revHistFromServiceAPI[i].usrname+'('+this.revHistFromServiceAPI[i].email+')'+'</b>'+'<br>'+this.revHistFromServiceAPI[i].proposalstatuscurr+'-'+this.revHistFromServiceAPI[i].apprvractn;
      }
      else
      {
        rev_htm_str='<b>'+this.revHistFromServiceAPI[i].proposalstatuscurr+'-'+this.revHistFromServiceAPI[i].apprvractn+'</b>';
      }
      if(this.revHistFromServiceAPI[i].comments != null && this.revHistFromServiceAPI[i].comments != '-')
      {
        rev_htm_str+='<br>'+'Comments-'+this.revHistFromServiceAPI[i].comments;
      }

      this.revision_obj_search.push({rev_obj:this.revHistFromServiceAPI[i],rev_html_string:rev_htm_str})
    }
    console.log('Revision History Display',this.revision_obj_search)
  }

})


}


close_revision_history_search()
{
this.enabledetails_search=false;

}

}
