import { Component, OnInit } from '@angular/core';


import {TransactionService} from '../loginpage/Transaction.service';
import { NavComponent } from '../nav/nav.component';

@Component({
  selector: 'app-asiapacific',
  templateUrl: './asiapacific.component.html',
  styleUrls: ['./asiapacific.component.css']
})
export class AsiapacificComponent implements OnInit {


  cdsid='';
  region='';
    // tslint:disable-next-line:variable-name
  region_code='';

  constructor(private transactionService: TransactionService,private navigation: NavComponent){}
  ngOnInit(): void {

  }



}
