/* tslint:disable */
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Table } from 'primeng';
import { MessageService } from 'primeng/api';
import { TransactionService } from '../loginpage/Transaction.service';
import { partDetailForPost, primarypartdetails, partDetailsForGet, subgrid, corrosionProtectionForGet, useraccessrole, subpltlist, subprglist, ActivePlants, programsbasedonplants, selec_prt_pro_details, regiondetails, supplierdetails, changeTrackingForImprovement, partDetailForRequiredFields, shelfLifeForList } from '../loginpage/transactions';
//import { PackagingprocessComponent } from '../packagingprocess/packagingprocess.component';
//import { PackagingproposalComponent } from '../packagingproposal/packagingproposal.component';
import { ImprovementpackagingproposalComponent } from '../improvementpackagingproposal/improvementpackagingproposal.component';
import { PackagingprocessdashboardComponent } from '../packagingprocessdashboard/packagingprocessdashboard.component';

import { SubmiterdashboardComponent } from '../submiterdashboard/submiterdashboard.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-improvementeetabone',
  templateUrl: './improvementeetabone.component.html',
  styleUrls: ['./improvementeetabone.component.css'],
  providers:[MessageService]
})
export class ImprovementeetaboneComponent implements OnInit {

  
    @ViewChild("dt4", { static: false }) public dt4: Table;
  
    onClick() {
        this.dt4.reset();
    }
  
    tab2menuitem_ee: any;
    tab2_ee: string;
    name_ee: string;
    region_ee: string;
    regionforroute_ee:string;
    flag_ee: boolean;

    /* field added for proposal creation menubar*/
    savedtabfortesting_ee:number;
    tab1route_ee:string;
    tab2route_ee:string;
    tab3route_ee:string;
    tab4route_ee:string;

    /* field added for proposal creation menubar ends*/

    primarypartsbomid_ee='';
    id_proposal_ee:number;
    editingdetails_ee=0;
    primarypartgridforpost_ee:partDetailForPost;
    primarypartsgrid_ee:partDetailForPost[]=[];
    primarypartsattributes_ee:primarypartdetails;
    primarypartgridforget_ee:partDetailsForGet;

    secondarypartsgridforget_ee:partDetailsForGet[] = [];
    secondarypartsbomid_ee='';
    secondarypartreceivedfromgrid1_ee:subgrid[]=[];
    // secondarypartsgrid_ee:partDetailForPost[]=[];
    // secondarypartgridforpost_ee:partDetailForPost[]=[];
    // secondary parts validation
  secondarypartgridforpostAPI_ee:partDetailForPost[]=[];
  secondarypartsgrid_ee:partDetailForRequiredFields[]=[];
  secondarypartgridforpost_ee:partDetailForRequiredFields[]=[];
    partsdetailsbeforeediting_ee:partDetailForPost;
    enablegrid4_ee=false;

    clonedParts_ee: {[s:string]:partDetailForPost;}={};
    cols1_ee:any;
    cols2_ee:any;

    weight_status_ee=[{label:'Actual',value:'Actual'},{label:'Estimate',value:'Estimate'}];
    manufacturing_location_ee=[{label:'AP',value:'AP'},{label:'NA',value:'NA'},{label:'SA',value:'SA'},{label:'EU',value:'EU'}];
    // corrosion_protection=[{prot:"Oiling"},{prot:"N/A"},{prot:"Paper"},{prot:"Other"},{prot:"VCI"},{prot:"Silica-Gel (Desiccant)"}];
    // corrosion_protection_ee=[{label:"Oiling",value:"Oiling"},{label:"N/A",value:"N/A"},{label:"Paper",value:"Paper"},{label:"Other",value:"Other"},{label:"VCI",value:"VCI"},{label:"Silica-Gel (Desiccant)",value:"Silica-Gel (Desiccant)"}];
    corrosion_protection_ee:corrosionProtectionForGet[]=[];
    selected_corrosion_protection_ee:corrosionProtectionForGet={cd_corsn_prot_type:0,corsn_prot_type:"",active_stat:"",active_stat_display:false,updated_by:"",updated_dt:""};
  
    shelfLifeList_ee:shelfLifeForList[] = [];
    selectedShelfLife_ee:shelfLifeForList={label:'NA',value:0};
  
  
    disableProgDrp_prt_ee: boolean = true;
    current_region_prt_ee: string = " ";
    current_region_code_prt_ee: string;
    prtUserAccess_ee: useraccessrole[] = [];

    plantCodeList_prt_ee: subpltlist[] = [];
    selectedplantlist_prt_ee: subpltlist[] = [];


    progList_prt_ee: subprglist[] = [];
    selectedproglist_prt_ee: subprglist[] = [];


    subAllPlants_prt_ee:ActivePlants[]=[];
    progListOnPlt_prt_ee:programsbasedonplants[]=[];


    isStampingBlank_ee='No'


    edit_measure_part_ee='lbs/inch';

    unitom_ee = '';

    enablesave = true;

    /*
      statusList:substatuslist[]=[];
      selectedstatuslist:substatuslist[]=[];

      trdcmpyList:subtrdcmpylist[]=[];
      selectedtrdcmpylist:subtrdcmpylist[]=[];
      */

    selectedpartprefix_prt_ee = '';
    selectedpartbase_prt_ee = '';
    selectedpartsuffix_prt_ee = '';

    part_prefix_prt_ee = '';
    part_base_prt_ee = '';
    part_suffix_prt_ee = '';



    plantcode_prt_ee = '';
    programcode_prt_ee = '';


    noSupDialog_prt_ee = false;
    noPrtDialog_prt_ee = false;
    content_selectedprt_prt_ee = '';
    content_supp_prt_ee = '';
    // part selected from submitter dashboard
    // selectedpart_submitter: string = "";

    selectedpart_submitter_ee:selec_prt_pro_details={simplifiedpartno:'-',proposalid:0,bompartmasterid:0,plantgsdbcode:'',proposallastsaved:0,part_status:'',supplier_code:'',proposallastsaved_ee:1,is_ee_proposal:true,currentview_ee:true,from_draft:false,propsl_type:'',imp_part_status:''};
    prtPrgUserAccess_ee: useraccessrole[] = [];


    region_details_prt_ee:regiondetails;


    disablePlantDrp_prt_ee=false;

    // not used
    selectedsimpliedpartno_ee = '';


    // all parts data for the user
    allPartgridList_ee: subgrid[] = [];

    getPartgridList_ee: subgrid[] = []; // grid 1 data for user to add secondary parts
    selectedPartgridList_ee: subgrid[] = [];// grid 2 data display primary and seconday parts selected
    choosedGetPartGrid_ee: subgrid[] = []; // selected parts in grid 1 before clicking  add
    choosedSelectPartGrid_ee: subgrid[] = [];// selected parts in grid 2 before clicking remove

    // all parts for user when filter is selcted
    partgridList_ee: subgrid[] = [];


    //  primaryPartgridDetails:[]=[];
    // additionalPartgridDetails:


    getpart_grid_text_ee = '';
    getpart_present_table_ee = false;



    public getpart_cols_ee: any[];
    public selectpart_cols_ee: any[];

    prt_cdsid_ee: string;
    prt_roleid_ee:number;



    supp_code_prt_ee: string;
    designsource_code_prt_ee:string;


     noDesignsourceDialog_prt_ee=false;
    content_designsource_prt_ee=''

    noroleselectedDialog_ee=false;
    norolecontent_ee=''


    popup1_ee=false;


    selected_supp_details_ee:supplierdetails;
    disable_add_button_ee=false;



  existChangeStatusTab1_ee='';
  changeStatus_ee=false;


    originalTab1Details_ee:primarypartdetails
   changedTab1Details_ee:primarypartdetails


   originalTab1Primarygrid_ee:partDetailForPost[]=[];
   changedTab1Primarygrid_ee:partDetailForPost[]=[];

   originalTab1Secondarygrid_ee:partDetailForPost[]=[];
   changedTab1Secondarygrid_ee:partDetailForPost[]=[];

   pripartlenInvalid_ee=false;
  pripartlenInvalidReason_ee=null;
  pripartwidInvalid_ee=false;
  pripartwidInvalidReason_ee=null;
  priparthgtInvalid_ee=false;
  priparthgtInvalidReason_ee=null;
  pripartwgtInvalid_ee=false;
  pripartwgtInvalidReason_ee=null;

  corrosionProtectionInvalid_ee:boolean=false;
  corrosionProtectionInvalidReason_ee:string=null;

  shelfLifeInvalid_ee:boolean=false;
  shelfLifeInvalidReason_ee:string=null;

    constructor(private packagingproposal:ImprovementpackagingproposalComponent , private packagingprocess:PackagingprocessdashboardComponent , private submitterdashboard: SubmiterdashboardComponent, private router: Router, private transactionService: TransactionService, private messageService:MessageService,private date:DatePipe) { }
  
  ngOnInit(): void {
    /* code added for packaging proposal menubar*/

    this.regionforroute_ee=this.transactionService.getregion();
    this.tab1route_ee=this.transactionService.getregionurlvalue(this.regionforroute_ee)+'packagingprocessdashboard/improvementproposalcreation/improvementeepartsandattri';
    this.tab2route_ee=this.transactionService.getregionurlvalue(this.regionforroute_ee)+'packagingprocessdashboard/improvementproposalcreation/improvementeepackproposaltabtwo';
    this.tab3route_ee=this.transactionService.getregionurlvalue(this.regionforroute_ee)+'packagingprocessdashboard/improvementproposalcreation/improvementeepackproposaltabthree';
    this.tab4route_ee=this.transactionService.getregionurlvalue(this.regionforroute_ee)+'packagingprocessdashboard/improvementproposalcreation/improvementeepackproposaltabfour';

this.existChangeStatusTab1_ee=sessionStorage.getItem('ProposalChangeStatus');


    if(this.existChangeStatusTab1_ee == null || this.existChangeStatusTab1_ee == 'null' || this.existChangeStatusTab1_ee.trim() == '')
    {
      this.existChangeStatusTab1_ee='unchanged';
      sessionStorage.setItem('ProposalChangeStatus','unchanged');
    }


  console.log('parts and attribute initialised',this.packagingproposal.savedtab);
    if(this.packagingproposal.savedtab==1)
        {
          for(const i of this.packagingproposal.tabpackagingproposalmenuitems.values())
          {
                console.log(i);
                if(i.label!='EE-COS, Container & Shipping Unit')
                {
                    console.log('entered check condition');
                    this.packagingproposal.flag1_ee=true;
                }
                else{
                    this.packagingproposal.flag1_ee=false;
                    break;
                }
          }

          if(this.packagingproposal.flag1_ee)
          {
            this.packagingproposal.tabpackagingproposalmenuitems.push({label: 'EE-COS, Container & Shipping Unit', routerLink:this.tab2route_ee, icon: 'fas fa-cubes'});  }
        }

        else if(this.packagingproposal.savedtab==2 )
        {
          for(const i of this.packagingproposal.tabpackagingproposalmenuitems.values())
          {
                console.log(i);
                if(i.label!='EE-Component & Material')
                {
                    this.packagingproposal.flag2_ee=true;
                }
                else{
                    this.packagingproposal.flag2_ee=false;
                    break;
                }
          }

          if(this.packagingproposal.flag2_ee)
          {
            this.packagingproposal.tabpackagingproposalmenuitems.push({label: 'EE-COS, Container & Shipping Unit', routerLink:this.tab2route_ee, icon: 'fas fa-cubes'});
            this.packagingproposal.tabpackagingproposalmenuitems.push({label:'EE-Component & Material',routerLink:this.tab3route_ee, icon: 'fas fa-person-booth'});
          }
        }

        else if(this.packagingproposal.savedtab==3 || this.packagingproposal.savedtab==4)
        {
          for(const i of this.packagingproposal.tabpackagingproposalmenuitems.values())
          {
                console.log(i);
                if(i.label!='EE-Images/Photos & Comments')
                {
                    this.packagingproposal.flag3_ee=true;
                }
                else{
                    this.packagingproposal.flag3_ee=false;
                    break;
                }
          }

          if(this.packagingproposal.flag3_ee)
          {
            this.packagingproposal.tabpackagingproposalmenuitems.push({label: 'EE-COS, Container & Shipping Unit', routerLink:this.tab2route_ee, icon: 'fas fa-cubes'});
             this.packagingproposal.tabpackagingproposalmenuitems.push({label:'EE-Component & Material',routerLink:this.tab3route_ee, icon: 'fas fa-person-booth'});
             this.packagingproposal.tabpackagingproposalmenuitems.push({label:'EE-Images/Photos & Comments',routerLink:this.tab4route_ee, icon: 'pi pi-images'});
          }


        }



    /* code added for packaging proposal menubar ends*/



    this.primarypartsdetailsinit_ee();
    this.corrosionProtectionInvalid_ee=false;
    this.corrosionProtectionInvalidReason_ee=null;

    this.shelfLifeInvalid_ee=false;
    this.shelfLifeInvalidReason_ee=null;
  
  
   /* this.transactionService.getProposalId().subscribe(a=>
      {
        console.log("proposalid",a);
        this.id_proposal=Number(JSON.stringify(a));
        console.log("check",this.id_proposal);
      });*/

      this.primarypartsattributes_ee={
        gppuserid:this.transactionService.getcdsid(),
        id_proposal:this.id_proposal_ee,
        corr_protc:'',
        lst_saved_tab:0,
        seasnlreq:'',
        shelf_life:0,
        sp_part_no:'',
        submit_status:'not submitted',
        temp_sensitivity:'No',
        temp_stor_spec:'',
        timestamp:'',
        plantgsdbcode:'',
        isstampblank:'',
        supgsdbcode: '',
        supfullname: '',
         supaddress1: '',
         supcity: '',
         supstateprov: '',
          supcountrycode: '',
         supcontactnamefirst: '',
       supcontactname: '',
      supcontactfunctn: '',
        supcontactemail: '',
        supcontactphone: '',
        supcontactfax: '',
        defaultcurr:'',
        lst_saved_tab_ee:1,
        propsl_type:'Improvement'


      }


      this.transactionService.getActiveCorrosionProtectionType().subscribe(activeCorrosionList=>{
        console.log('corrosion protection list from api',activeCorrosionList);
        this.corrosion_protection_ee=[];
        this.corrosion_protection_ee=activeCorrosionList;
        if(this.corrosion_protection_ee != null && this.corrosion_protection_ee.length != 0)
        {
          this.selected_corrosion_protection_ee={cd_corsn_prot_type:0,corsn_prot_type:"",active_stat:"",active_stat_display:false,updated_by:"",updated_dt:""};
          //this.selected_corrosion_protection_ee=this.corrosion_protection_ee[0]
        }
    })

    this.shelfLifeOptions();
    this.prt_cdsid_ee = this.transactionService.getcdsid();
    this.current_region_prt_ee = this.transactionService.getregion();
 this.current_region_code_prt_ee = this.transactionService.getregioncode(this.current_region_prt_ee);
  //  this.transactionService.storepage(this.current_region_prt, "packagingprocess/proposalcreation/partsandattri");
    // this.selectedpart_submitter=this.submitterdashboard.pushdata();
    this.selectedpart_submitter_ee = this.transactionService.getpartno_packpro(this.current_region_prt_ee);
    console.log('suppop',this.selectedpart_submitter_ee)

    /*this.prt_roleid_ee=this.transactionService.getcurrentrole(this.current_region_prt_ee)


   if(this.prt_roleid_ee==null)
    {


      this.noroleselectedDialog_ee=true;
      this.norolecontent_ee="No role selected "
    }*/

   // Getting Region details for UOM
   this.transactionService.get_region_details(this.current_region_code_prt_ee).subscribe(unitype=>{

    this.unitom_ee = unitype.uom;
    console.log('UOMREG',this.unitom_ee);

    if(this.unitom_ee == 'E')
    {
      this.edit_measure_part_ee = 'lbs/inch';
      console.log('UOMLB', this.unitom_ee, this.edit_measure_part_ee);
    }
    else
    {
      this.edit_measure_part_ee = 'kg/cm';
      console.log('UOMKG',this.unitom_ee, this.edit_measure_part_ee);
    }
  });

 // for improvement to know if its design source or supplier from primary part

    this.designsource_code_prt_ee = ''
    this.supp_code_prt_ee=''

     if(this.selectedpart_submitter_ee != null && this.selectedpart_submitter_ee.proposalid !=0 && this.selectedpart_submitter_ee.simplifiedpartno != '-')
   {
    this.transactionService.get_draft_selectedparts_data(this.selectedpart_submitter_ee.proposalid).subscribe(selectedparts_data_role_ee=>{


      console.log(selectedparts_data_role_ee);
      this.designsource_code_prt_ee = ''
      this.supp_code_prt_ee=''

      const grid2_parts:subgrid[]=[];
      const grid1_parts:subgrid[]=[];
      if(selectedparts_data_role_ee != null && selectedparts_data_role_ee.length != 0)
      {
      for(let ki=0;ki<selectedparts_data_role_ee.length;ki++)
      {
        if(selectedparts_data_role_ee[ki].isprimary.toLowerCase()=='yes')
        {
          console.log(selectedparts_data_role_ee[ki].containerdesignsourceid )
          if(selectedparts_data_role_ee[ki].containerdesignsourceid != null && selectedparts_data_role_ee[ki].containerdesignsourceid.trim() != '')
          {
            this.prt_roleid_ee = 2;
            this.designsource_code_prt_ee = selectedparts_data_role_ee[ki].containerdesignsourceid;
          }
          else
          {
            this.prt_roleid_ee = 1;
            this.supp_code_prt_ee = selectedparts_data_role_ee[ki].suppliergsdbcode;
          }
          break;
        }
      }
            // if supplier code and part number is present load the page and get user wise plant and program data
        if(this.prt_roleid_ee==1 && this.supp_code_prt_ee != null && this.supp_code_prt_ee != '' && this.selectedpart_submitter_ee != null && this.selectedpart_submitter_ee.simplifiedpartno!='-' && this.selectedpart_submitter_ee.proposalid!=0 ) {

          this.id_proposal_ee=this.selectedpart_submitter_ee.proposalid;
          this.get_supp_details_ee();
          this.list_plantcode_ee();

        }
        else if( this.prt_roleid_ee==2 && this.designsource_code_prt_ee != null  && this.designsource_code_prt_ee != '' && this.selectedpart_submitter_ee != null && this.selectedpart_submitter_ee.simplifiedpartno!='-' && this.selectedpart_submitter_ee.proposalid!=0 && this.selectedpart_submitter_ee.plantgsdbcode!='') {

          this.id_proposal_ee=this.selectedpart_submitter_ee.proposalid;
          this.get_supp_details_ee();
          this.list_plantcode_ee();
        }
    }
    })
   }

   else
   {
     // open popup to say no part selected
     this.noPrtDialog_prt_ee = true;
      this.content_selectedprt_prt_ee = 'Please select part from Search Existing Proposal';
   }

}

primpartstValidation_ee()
  {
    this.pripartlenInvalid_ee=false;
    this.pripartlenInvalidReason_ee=null;
    this.pripartwidInvalid_ee=false;
    this.pripartwidInvalidReason_ee=null;
    this.priparthgtInvalid_ee=false;
    this.priparthgtInvalidReason_ee=null;
    this.pripartwgtInvalid_ee=false;
    this.pripartwgtInvalidReason_ee=null;
  }

  ok_noroledialog_ee()
  {
    let url
    url=this.transactionService.getregionurlvalue(this.current_region_prt_ee);
    url+='packagingprocessdashboard'
    this.router.navigate([url]);

  }




  // if no supplier present

  ok_nosupdialog_ee() {
    console.log(this.current_region_prt_ee)
    let reg_url: string
    reg_url = this.transactionService.getregionurlvalue(this.current_region_prt_ee);
    reg_url += 'packagingprocess/packsupplieradmin/packsupplier';

    this.router.navigate([reg_url]);

  }


  ok_noDesignsourcedialog_ee() {
    console.log(this.current_region_prt_ee)
    let reg_url: string
    reg_url = this.transactionService.getregionurlvalue(this.current_region_prt_ee);
    reg_url += 'packagingprocess/packdesignsourceadmin/packdesignsource';

    this.router.navigate([reg_url]);

  }


  go_to_supp_main_page_ee() {
    let reg_supp_url: string;
    reg_supp_url = this.transactionService.getregionurlvalue(this.current_region_prt_ee);
    if(this.prt_roleid_ee==1)
    {
    reg_supp_url += 'packagingprocess/packsupplieradmin/packsupplier';
    }
    else if(this.prt_roleid_ee==2)
    {
      reg_supp_url += 'packagingprocess/packdesignsourceadmin/packdesignsource';
    }
    this.router.navigate([reg_supp_url]);


  }

  // if no part present
  ok_noprtdialog_ee() {
    console.log(this.current_region_prt_ee)

    let reg_url_prt: string
    reg_url_prt = this.transactionService.getregionurlvalue(this.current_region_prt_ee);

    reg_url_prt += 'packagingprocessdashboard/searchexistingproposal';

    this.router.navigate([reg_url_prt]);


  }


  partdataList_ee() {

  }



  exist_ee()
  {
    this.popup1_ee=true;
  }


  get_supp_details_ee()
  {
    console.log(this.selectedpart_submitter_ee);
    const is_design_src='N'
    if(this.selectedpart_submitter_ee.supplier_code!=null && this.selectedpart_submitter_ee.supplier_code!='')
    {
      this.transactionService.getsuppdet(this.selectedpart_submitter_ee.supplier_code,is_design_src).subscribe(suppdet=>{

        console.log(suppdet);
        this.selected_supp_details_ee=suppdet
      })
    }
  }



  // only first time call to load user wise all plant and program data and get the part values by sending all parameters as all
  list_plantcode_ee() {
    console.log('get plant list')


    const sub_allplants=[]
  this.transactionService.getactiveplantslist(this.current_region_code_prt_ee).subscribe(sub_allplants=>{
      console.log('sub_useracces',sub_allplants);
     let position:number;
       this.subAllPlants_prt_ee=sub_allplants;
       this.plantCodeList_prt_ee=[];
       this.progList_prt_ee=[];



       // this.plantCodeList.push({plantcode:"all",plant:"[ALL]"});


       if(this.subAllPlants_prt_ee.length!=0)
       {
       for(let temp=0; temp<this.subAllPlants_prt_ee.length; temp++)
       {
         console.log(this.subAllPlants_prt_ee[temp]);
         let plt_name:string;
         plt_name=this.subAllPlants_prt_ee[temp].plantGSDBCode+'-'+this.subAllPlants_prt_ee[temp].plant_name;

          this.plantCodeList_prt_ee.push({plantcode:this.subAllPlants_prt_ee[temp].plantGSDBCode,plant:plt_name})

       }
      }


       console.log(this.plantCodeList_prt_ee);







     // service to get trading company
     // assign slected trading company full list of trading companies;






  // this.selectedproglist=this.progList;
  // this.selectedcoslist=this.cosList;

  // this.selectedplantlist=this.plantCodeList;
  // this.disableProgDrp=false;






  // ---------------------------------------------------------------------------------------------------------------------

  // in future do gor global admin if its used
  /*   this.transactionService.getUserAccessRole(this.prt_cdsid).subscribe(prt_useraccess => {
      console.log("sub_useracces", prt_useraccess);
      var position: number;
      this.prtUserAccess = prt_useraccess;
      this.plantCodeList_prt = [];
      this.progList_prt = [];


      for (var temp = 0; temp < this.prtUserAccess.length; temp++) {


        if (this.prtUserAccess[temp].cd_region == this.current_region_code_prt) {
          if (this.prtUserAccess[temp].role_id == this.prt_roleid) {
            var plt_name_prt: string;
            plt_name_prt = this.prtUserAccess[temp].plantgsdbcode + "-" + this.prtUserAccess[temp].plant_name;

            this.plantCodeList_prt.push({ plantcode: this.prtUserAccess[temp].plantgsdbcode, plant: plt_name_prt })


            if (this.prtUserAccess[temp].program_name != null) {

              var prg_arr_prt = [];
              prg_arr_prt = this.prtUserAccess[temp].program_name.split(',');

              console.log(prg_arr_prt)
              for (var prg = 0; prg < prg_arr_prt.length; prg++) {
                var present_prg=false;
                for(var kprg=0;kprg<this.progList_prt.length;kprg++)
                {
                       if(prg_arr_prt[prg]==this.progList_prt[kprg].programcode)
                        {
                             present_prg=true;
                                                break;
                        }
                 }


              if(present_prg==false)
                      {
                        this.progList_prt.push({programcode:prg_arr_prt[prg]})
                          }

              }


              console.log("proglist", this.progList_prt);
            }


          }
        }


      }*/
  // -------------------------------------------------------------------------------------------------------------------------------------
      // this.selectedproglist=this.progList;
      // this.selectedcoslist=this.cosList;

      // this.selectedplantlist=this.plantCodeList;
      // this.disableProgDrp=false;


      this.selectedproglist_prt_ee = [];

      this.selectedplantlist_prt_ee = [];
      this.disableProgDrp_prt_ee = true;


      // this.apply_filter();

      // to get all parts for that supplier and user wise plant and program


      this.transactionService.get_region_details( this.current_region_code_prt_ee).subscribe((regdtl_prt => {

        this.region_details_prt_ee=regdtl_prt;
        console.log(this.region_details_prt_ee);


        let plantcode_parameter='';


        if(this.region_details_prt_ee.limit_prts_recvd_plt.toLowerCase()=='yes')
        {


          let plant_present=false;
          for(let tplt=0; tplt<this.plantCodeList_prt_ee.length; tplt++)
          {
            if(this.plantCodeList_prt_ee[tplt].plantcode==this.selectedpart_submitter_ee.plantgsdbcode)
            {
              plant_present=true;
              // this.selectedplantlist_prt.push(this.plantCodeList_prt[tplt]);
              this.selectedplantlist_prt_ee=this.selectedplantlist_prt_ee.concat(this.plantCodeList_prt_ee[tplt]);
              break

            }
          }


          if(plant_present==false)
          {
            this.selectedplantlist_prt_ee.push({ plantcode: this.selectedpart_submitter_ee.plantgsdbcode, plant:this.selectedpart_submitter_ee.plantgsdbcode })
          }


          this.disablePlantDrp_prt_ee=true;





          plantcode_parameter=this.selectedplantlist_prt_ee[0].plantcode;


          console.log('qo/p',this.selectedplantlist_prt_ee);



          this.onchange_plant_ee();

        }
        else if(this.region_details_prt_ee.limit_prts_recvd_plt.toLowerCase()=='no')
        {


          plantcode_parameter='all'
          this.disablePlantDrp_prt_ee=false;

        }



        if(this.region_details_prt_ee.allow_multi_prt_prop_ind != null && this.region_details_prt_ee.allow_multi_prt_prop_ind.toLowerCase()=='one')
        {
          this.disable_add_button_ee=true;
        }

        else
        {
          this.disable_add_button_ee=false;
        }





  if(this.prt_roleid_ee==1)
  {
    let grid_display_part_status='';
  this.transactionService.getsubmittergrid(plantcode_parameter, 'all', 'all', 'New Part', 'all', 'all', 'all', 'all', this.selectedpart_submitter_ee.supplier_code, this.prt_cdsid_ee, 1, this.current_region_code_prt_ee).subscribe((prtgridlist => {
        // this.allPartgridList_ee = prtgridlist;
        this.allPartgridList_ee =[];

        const offsitesupplierid=sessionStorage.getItem('offsitesupplierid')
        if(prtgridlist != null && prtgridlist.length != 0)
        {
          if(offsitesupplierid == null || offsitesupplierid == 'null')
          {
            for(const prt of prtgridlist)
            {
              if(prt.offsitesupplierid == null)
              {
                this.allPartgridList_ee.push(prt)
              }
            }
          }
          else
          {
            for(const prt of prtgridlist)
            {
              if(prt.offsitesupplierid != null && prt.offsitesupplierid.trim().toLowerCase() == offsitesupplierid.trim().toLowerCase())
              {
                this.allPartgridList_ee.push(prt)
              }
            }
          }
        }
        if(this.allPartgridList_ee!=null && this.allPartgridList_ee.length!=0)
        {
          for(let i=0;i<this.allPartgridList_ee.length;i++)
          {

    grid_display_part_status='';
    grid_display_part_status+=this.allPartgridList_ee[i].ngpp_part_status;

    if(this.allPartgridList_ee[i].isstatusurgent!=null && this.allPartgridList_ee[i].isstatusurgent.toLowerCase()=='y')
    {
      grid_display_part_status+=' (Urgent)'
    }
    if(this.allPartgridList_ee[i].isdeliquent!=null && this.allPartgridList_ee[i].isdeliquent.toLowerCase()=='y')
    {
      grid_display_part_status+=' (Delinquent)'
    }


    this.allPartgridList_ee[i].displaypartstatus=grid_display_part_status;

          }
        }



  if(this.selectedpart_submitter_ee.proposallastsaved_ee>=1)
  {

  this.get_exist_proposal_details_tab1_ee()

  }


  else
  {
  this.getprimarypart_ee();
  }




      }))

    }

    else if(this.prt_roleid_ee==2)
    {

      // service to getdata


      let grid_display_part_status='';

      console.log(this.designsource_code_prt_ee);
      this.transactionService.getsubmittergrid_designsource(plantcode_parameter,'all','all','New Part','all','all','all','all',this.selectedpart_submitter_ee.supplier_code,'all',this.designsource_code_prt_ee,this.prt_cdsid_ee,2,this.current_region_code_prt_ee).subscribe((prtgridlist => {

      // this.allPartgridList_ee = prtgridlist;
      this.allPartgridList_ee =[];

      const offsitesupplierid=sessionStorage.getItem('offsitesupplierid')
      if(prtgridlist != null && prtgridlist.length != 0)
      {
        if(offsitesupplierid == null || offsitesupplierid == 'null')
        {
          for(const prt of prtgridlist)
          {
            if(prt.offsitesupplierid == null)
            {
              this.allPartgridList_ee.push(prt)
            }
          }
        }
        else
        {
          for(const prt of prtgridlist)
          {
            if(prt.offsitesupplierid != null && prt.offsitesupplierid.trim().toLowerCase() == offsitesupplierid.trim().toLowerCase())
            {
              this.allPartgridList_ee.push(prt)
            }
          }
        }
      }


      if(this.allPartgridList_ee!=null && this.allPartgridList_ee.length!=0)
        {
          for(let i=0;i<this.allPartgridList_ee.length;i++)
          {

    grid_display_part_status='';
    grid_display_part_status+=this.allPartgridList_ee[i].ngpp_part_status;

    if(this.allPartgridList_ee[i].isstatusurgent!=null && this.allPartgridList_ee[i].isstatusurgent.toLowerCase()=='y')
    {
      grid_display_part_status+=' (Urgent)'
    }
    if(this.allPartgridList_ee[i].isdeliquent!=null && this.allPartgridList_ee[i].isdeliquent.toLowerCase()=='y')
    {
      grid_display_part_status+=' (Delinquent)'
    }


    this.allPartgridList_ee[i].displaypartstatus=grid_display_part_status;

          }
        }


      console.log(this.allPartgridList_ee);


      if(this.selectedpart_submitter_ee.proposallastsaved_ee>=1)
  {

  this.get_exist_proposal_details_tab1_ee()

  }


  else
  {
  this.getprimarypart_ee();
  }

  }))

    }

  }
  ))


    })

  }








  getprimarypart_ee()
  {

    console.log(this.allPartgridList_ee)


    this.selectedPartgridList_ee = [];
    this.getPartgridList_ee = [];


    // one time done to check for the primary part from the list
    // if found push it to selected parts array else push other parts to get array where user can select the secondary/additional parts


    // disable parameter- flase for primary - to disbale checkbox



    if (this.allPartgridList_ee.length != 0 && this.allPartgridList_ee != null) {
      this.selectedPartgridList_ee = []


      console.log(this.allPartgridList_ee)
      for (let i = 0; i < this.allPartgridList_ee.length; i++) {
        if (this.allPartgridList_ee[i].partnumbercommonized == this.selectedpart_submitter_ee.simplifiedpartno && this.allPartgridList_ee[i].bompartmasterid==this.selectedpart_submitter_ee.bompartmasterid) {
          console.log('inside')
          console.log(i);
          console.log(this.allPartgridList_ee[i]);
          console.log(this.selectedPartgridList_ee)
          this.selectedPartgridList_ee.push(this.allPartgridList_ee[i]);
          console.log(this.selectedPartgridList_ee)
          this.selectedPartgridList_ee[0].disable = true;

        }

        else {
          this.getPartgridList_ee.push(this.allPartgridList_ee[i]);
        }
      }


      console.log(this.getPartgridList_ee);
      console.log(this.selectedPartgridList_ee)

      this.selectpart_cols_ee = [
        { field: 'partnumbercommonized', header: 'Simplified Part Number' },
        { field: 'plantgsdbcode', header: 'Plant' },
        { field: 'programname', header: 'Program(s)' },
        { field: 'suppliergsdbcode', header:'Supplier GSDB'},
        { field: 'parteffectiveindate', header: 'Effective In Dates' },

      ];



      this.getpart_cols_ee = [
        { field: 'partnumbercommonized', header: 'Simplified Part Number' },
        { field: 'plantgsdbcode', header: 'Plant' },
        { field: 'programname', header: 'Program(s)' },
        { field: 'suppliergsdbcode', header:'Supplier GSDB'},
        { field: 'parteffectiveindate', header: 'Effective In Dates' },

      ];


      // to push selectedPartgridList to grid 3
      // get details for primary part
      this.loadprimarypart_ee(this.selectedPartgridList_ee);
      console.log('selectedpartsgridlist', this.selectedPartgridList_ee);

    }









  }


  get_exist_proposal_details_tab1_ee()
    {


      console.log(this.selectedpart_submitter_ee.proposalid);
      this.primarypartsgrid_ee=[];
      this.selectedPartgridList_ee=[];
      this.secondarypartsgrid_ee=[];
      this.secondarypartgridforpost_ee=[];



      this.transactionService.get_draft_Tab1_data(this.selectedpart_submitter_ee.proposalid).subscribe(tab1_data=>{



        console.log('tab1data',tab1_data);

this.primarypartsattributes_ee=tab1_data;
        this.originalTab1Details_ee=JSON.parse(JSON.stringify(this.primarypartsattributes_ee));
        this.changedTab1Details_ee=this.primarypartsattributes_ee;

        this.transactionService.get_draft_selectedparts_data(this.selectedpart_submitter_ee.proposalid).subscribe(selectedparts_data=>{


          console.log(selectedparts_data);


          const grid2_parts:subgrid[]=[];
          const grid1_parts:subgrid[]=[];

      // To Populate existing proposal details.
      if(this.shelfLifeList_ee!= null && this.shelfLifeList_ee.length!= 0)
      {
        var shelflifeIndex
        shelflifeIndex=this.shelfLifeList_ee.findIndex(shelf=>shelf.value == tab1_data.shelf_life);
        console.log("Shelflife in getexistingproposal", this.shelfLifeList_ee, shelflifeIndex, tab1_data.shelf_life)
        if(shelflifeIndex != -1)
        {
          this.selectedShelfLife_ee =this.shelfLifeList_ee[shelflifeIndex];
        }
        else if(tab1_data.shelf_life != null)
        {
          this.selectedShelfLife_ee={label:"NA",value:0}
        }
      }
      else if(tab1_data.shelf_life != null)
      {
        this.selectedShelfLife_ee={label:"NA",value:0}
      }

        console.log("shelflife selected value", this.selectedShelfLife_ee);

          // this.primarypartsattributes_ee=tab1_data;
          this.selected_corrosion_protection_ee={cd_corsn_prot_type:0,corsn_prot_type:'',active_stat:'',active_stat_display:false,updated_by:'',updated_dt:''}

          if(this.corrosion_protection_ee != null && this.corrosion_protection_ee.length != 0)
          {
            let corrIndex
            corrIndex=this.corrosion_protection_ee.findIndex(cor=>cor.corsn_prot_type.toLowerCase() == tab1_data.corr_protc.toLowerCase())
            if(corrIndex != -1)
            {
              this.selected_corrosion_protection_ee=this.corrosion_protection_ee[corrIndex];
            }
            else if(tab1_data.corr_protc != null)
            {
              this.selected_corrosion_protection_ee={cd_corsn_prot_type:0,corsn_prot_type:'',active_stat:'',active_stat_display:false,updated_by:'',updated_dt:''}
            }

          }

          else if(tab1_data.corr_protc != null)
          {
            this.selected_corrosion_protection_ee={cd_corsn_prot_type:0,corsn_prot_type:'',active_stat:'',active_stat_display:false,updated_by:'',updated_dt:''}
          }
          console.log('corrosionprotection selected value', this.selected_corrosion_protection_ee);

          this.isStampingBlank_ee=tab1_data.isstampblank;



          for(let ki=0;ki<selectedparts_data.length;ki++)
          {
            if(selectedparts_data[ki].isprimary.toLowerCase()=='yes')
            {


              this.primarypartsgrid_ee.push(selectedparts_data[ki]);
              this.primarypartgridforpost_ee=selectedparts_data[ki];


              grid1_parts.push({
                bompartmasterid:selectedparts_data[ki].bompartmasterid,
                cntrsuggestedownershpstrat:selectedparts_data[ki].cntrsuggestedownershpstrat,
              disable:true,
              // dummy as no status stored in draft tab 1
              ngpp_part_status:tab1_data.submit_status,
              part_pre_bas_suf:selectedparts_data[ki].partprefix+'-'+selectedparts_data[ki].partbase+'-'+selectedparts_data[ki].partsuffix,
              partbase:selectedparts_data[ki].partbase,
              partcontrol:selectedparts_data[ki].partcontrol,
              partdesc:selectedparts_data[ki].partdesc,
              parteffectiveindate:selectedparts_data[ki].parteffectiveindate,
              partnumbercommonized:selectedparts_data[ki].partnumbercommonized,
              partprefix:selectedparts_data[ki].partprefix,
              partsuffix:selectedparts_data[ki].partsuffix,
              plantgsdbcode:selectedparts_data[ki].plantgsdbcode,
              plantname:selectedparts_data[ki].plantname,
              programname:selectedparts_data[ki].programname,
              suppliercountrycodeISO:selectedparts_data[ki].suppliercountrycodeiso,
              suppliergsdbcode:selectedparts_data[ki].suppliergsdbcode,
              tradingcogsdbcode:selectedparts_data[ki].tradingcogsdbcode,
              // as value req simple hardcoded
              ngpp_part_status_name:'1',
              non_submited:true,
              displaypartstatus:tab1_data.submit_status,
              isdeliquent:'',
              isstatusurgent:'',
              deliquentondate:'',
              urgentondate:'',
              imp_ngpp_part_status:'',
              offsitesupplierid:selectedparts_data[ki].offsitesupplierid
              })





            }

            else{

               // secondary parts validation
            this.secondarypartgridforpost_ee.push({
              bompartmasterid: selectedparts_data[ki].bompartmasterid,
              buyercode: selectedparts_data[ki].buyercode,
              id_proposal: selectedparts_data[ki].id_proposal,
            plantgsdbcode: selectedparts_data[ki].plantgsdbcode,
              supplierid: selectedparts_data[ki].supplierid,
                cd_region: selectedparts_data[ki].cd_region,
              partsubmitergsdbcodeid: selectedparts_data[ki].partsubmitergsdbcodeid,
              partintrobomuploadbatchid:selectedparts_data[ki].partintrobomuploadbatchid,
              lastseenonbomuploadbatchid:selectedparts_data[ki].lastseenonbomuploadbatchid,
              partintrodate: selectedparts_data[ki].partintrodate,
              plantname: selectedparts_data[ki].plantname,
              programname: selectedparts_data[ki].programname,
              partprefix: selectedparts_data[ki].partprefix,
              partbase: selectedparts_data[ki].partbase,
              partsuffix:selectedparts_data[ki].partsuffix,
              partcontrol: selectedparts_data[ki].partcontrol,
              cpsccode: selectedparts_data[ki].cpsccode,
              partdesc: selectedparts_data[ki].partdesc,
              partstatus: selectedparts_data[ki].partstatus,
              parttype: selectedparts_data[ki].parttype,
              controlitem: selectedparts_data[ki].controlitem,
              commonalitycode: selectedparts_data[ki].commonalitycode,
              partaddedfrom: selectedparts_data[ki].partaddedfrom,
              form1271requested: selectedparts_data[ki].form1271requested,
              cntrsuggestedownershpstrat: selectedparts_data[ki].cntrsuggestedownershpstrat,
              offsitesupplierid: selectedparts_data[ki].offsitesupplierid,
              containerdesignsourceid: selectedparts_data[ki].containerdesignsourceid,
              transtimeindays: selectedparts_data[ki].transtimeindays,
              parteffectiveindate: selectedparts_data[ki].parteffectiveindate,
              s4pincode: selectedparts_data[ki].s4pincode,
              parteffectiveoutdate: selectedparts_data[ki].parteffectiveoutdate,
              s4poutcode: selectedparts_data[ki].s4poutcode,
              carlinecode: selectedparts_data[ki].carlinecode,
              ptotype: selectedparts_data[ki].ptotype,
              cmmspackagingconfirmcode: selectedparts_data[ki].cmmspackagingconfirmcode,
              cmmscontainerbase: selectedparts_data[ki].cmmscontainerbase,
              cmmscontainersuffix: selectedparts_data[ki].cmmscontainersuffix,
              cmmscomponent1base: selectedparts_data[ki].cmmscomponent1base,
              cmmscomponent1suffix: selectedparts_data[ki].cmmscomponent1suffix,
              cmmscomponent2base: selectedparts_data[ki].cmmscomponent2base,
              cmmscomponent2suffix: selectedparts_data[ki].cmmscomponent2suffix,
              cmmscontainerpieses: selectedparts_data[ki].cmmscontainerpieses,
              cmmscontainerextl: selectedparts_data[ki].cmmscontainerextl,
              cmmscontainerextw: selectedparts_data[ki].cmmscontainerextw,
              cmmscontainerexth: selectedparts_data[ki].cmmscontainerexth,
              cmmscontainergrosswt:selectedparts_data[ki].cmmscontainergrosswt,
              cmmscontainersperlayer: selectedparts_data[ki].cmmscontainersperlayer,
              cmmslayerspershipunit: selectedparts_data[ki].cmmslayerspershipunit,
              cmmsshipunitnumpieses: selectedparts_data[ki].cmmsshipunitnumpieses,
              cmmsshipunitextl: selectedparts_data[ki].cmmsshipunitextl,
              cmmsshipunitextw: selectedparts_data[ki].cmmsshipunitextw,
              cmmsshipunitexth: selectedparts_data[ki].cmmsshipunitexth,
              cmmsshipunitgrosswt: selectedparts_data[ki].cmmsshipunitgrosswt,
              cmmspackagingreleaselevel: selectedparts_data[ki].cmmspackagingreleaselevel,
              partnumbercommonized: selectedparts_data[ki].partnumbercommonized,
              tradingcogsdbcode: selectedparts_data[ki].tradingcogsdbcode,
              ismissingpart: selectedparts_data[ki].ismissingpart,
              suppliercountrycodeiso: selectedparts_data[ki].suppliercountrycodeiso,
              suppliergsdbcode: selectedparts_data[ki].suppliergsdbcode,
              isprimary: selectedparts_data[ki].isprimary,
              selorder: selectedparts_data[ki].selorder,
              txntimestamp: selectedparts_data[ki].txntimestamp,
              ngpp_part_l: selectedparts_data[ki].ngpp_part_l,
              ngpp_part_w: selectedparts_data[ki].ngpp_part_w,
              ngpp_part_h: selectedparts_data[ki].ngpp_part_h,
              ngpp_part_wt: selectedparts_data[ki].ngpp_part_wt,
              ngpp_part_wt_status: selectedparts_data[ki].ngpp_part_wt_status,
              ngpp_ref_part_no: selectedparts_data[ki].ngpp_ref_part_no,
              ngpp_manf_loc: selectedparts_data[ki].ngpp_manf_loc,
              partid: selectedparts_data[ki].partid,
              ngpp_part_h_invalid:false,
              ngpp_part_l_invalid:false,
              ngpp_part_w_invalid:false,
              ngpp_part_wt_invalid:false,
              ngpp_part_h_invalid_reasn:'',
              ngpp_part_l_invalid_reasn:'',
              ngpp_part_w_invalid_reasn:'',
              ngpp_part_wt_invalid_reasn:''
            })
            this.secondarypartsgrid_ee.push({
              bompartmasterid: selectedparts_data[ki].bompartmasterid,
              buyercode: selectedparts_data[ki].buyercode,
              id_proposal: selectedparts_data[ki].id_proposal,
            plantgsdbcode: selectedparts_data[ki].plantgsdbcode,
              supplierid: selectedparts_data[ki].supplierid,
                cd_region: selectedparts_data[ki].cd_region,
              partsubmitergsdbcodeid: selectedparts_data[ki].partsubmitergsdbcodeid,
              partintrobomuploadbatchid:selectedparts_data[ki].partintrobomuploadbatchid,
              lastseenonbomuploadbatchid:selectedparts_data[ki].lastseenonbomuploadbatchid,
              partintrodate: selectedparts_data[ki].partintrodate,
              plantname: selectedparts_data[ki].plantname,
              programname: selectedparts_data[ki].programname,
              partprefix: selectedparts_data[ki].partprefix,
              partbase: selectedparts_data[ki].partbase,
              partsuffix:selectedparts_data[ki].partsuffix,
              partcontrol: selectedparts_data[ki].partcontrol,
              cpsccode: selectedparts_data[ki].cpsccode,
              partdesc: selectedparts_data[ki].partdesc,
              partstatus: selectedparts_data[ki].partstatus,
              parttype: selectedparts_data[ki].parttype,
              controlitem: selectedparts_data[ki].controlitem,
              commonalitycode: selectedparts_data[ki].commonalitycode,
              partaddedfrom: selectedparts_data[ki].partaddedfrom,
              form1271requested: selectedparts_data[ki].form1271requested,
              cntrsuggestedownershpstrat: selectedparts_data[ki].cntrsuggestedownershpstrat,
              offsitesupplierid: selectedparts_data[ki].offsitesupplierid,
              containerdesignsourceid: selectedparts_data[ki].containerdesignsourceid,
              transtimeindays: selectedparts_data[ki].transtimeindays,
              parteffectiveindate: selectedparts_data[ki].parteffectiveindate,
              s4pincode: selectedparts_data[ki].s4pincode,
              parteffectiveoutdate: selectedparts_data[ki].parteffectiveoutdate,
              s4poutcode: selectedparts_data[ki].s4poutcode,
              carlinecode: selectedparts_data[ki].carlinecode,
              ptotype: selectedparts_data[ki].ptotype,
              cmmspackagingconfirmcode: selectedparts_data[ki].cmmspackagingconfirmcode,
              cmmscontainerbase: selectedparts_data[ki].cmmscontainerbase,
              cmmscontainersuffix: selectedparts_data[ki].cmmscontainersuffix,
              cmmscomponent1base: selectedparts_data[ki].cmmscomponent1base,
              cmmscomponent1suffix: selectedparts_data[ki].cmmscomponent1suffix,
              cmmscomponent2base: selectedparts_data[ki].cmmscomponent2base,
              cmmscomponent2suffix: selectedparts_data[ki].cmmscomponent2suffix,
              cmmscontainerpieses: selectedparts_data[ki].cmmscontainerpieses,
              cmmscontainerextl: selectedparts_data[ki].cmmscontainerextl,
              cmmscontainerextw: selectedparts_data[ki].cmmscontainerextw,
              cmmscontainerexth: selectedparts_data[ki].cmmscontainerexth,
              cmmscontainergrosswt:selectedparts_data[ki].cmmscontainergrosswt,
              cmmscontainersperlayer: selectedparts_data[ki].cmmscontainersperlayer,
              cmmslayerspershipunit: selectedparts_data[ki].cmmslayerspershipunit,
              cmmsshipunitnumpieses: selectedparts_data[ki].cmmsshipunitnumpieses,
              cmmsshipunitextl: selectedparts_data[ki].cmmsshipunitextl,
              cmmsshipunitextw: selectedparts_data[ki].cmmsshipunitextw,
              cmmsshipunitexth: selectedparts_data[ki].cmmsshipunitexth,
              cmmsshipunitgrosswt: selectedparts_data[ki].cmmsshipunitgrosswt,
              cmmspackagingreleaselevel: selectedparts_data[ki].cmmspackagingreleaselevel,
              partnumbercommonized: selectedparts_data[ki].partnumbercommonized,
              tradingcogsdbcode: selectedparts_data[ki].tradingcogsdbcode,
              ismissingpart: selectedparts_data[ki].ismissingpart,
              suppliercountrycodeiso: selectedparts_data[ki].suppliercountrycodeiso,
              suppliergsdbcode: selectedparts_data[ki].suppliergsdbcode,
              isprimary: selectedparts_data[ki].isprimary,
              selorder: selectedparts_data[ki].selorder,
              txntimestamp: selectedparts_data[ki].txntimestamp,
              ngpp_part_l: selectedparts_data[ki].ngpp_part_l,
              ngpp_part_w: selectedparts_data[ki].ngpp_part_w,
              ngpp_part_h: selectedparts_data[ki].ngpp_part_h,
              ngpp_part_wt: selectedparts_data[ki].ngpp_part_wt,
              ngpp_part_wt_status: selectedparts_data[ki].ngpp_part_wt_status,
              ngpp_ref_part_no: selectedparts_data[ki].ngpp_ref_part_no,
              ngpp_manf_loc: selectedparts_data[ki].ngpp_manf_loc,
              partid: selectedparts_data[ki].partid,
              ngpp_part_h_invalid:false,
              ngpp_part_l_invalid:false,
              ngpp_part_w_invalid:false,
              ngpp_part_wt_invalid:false,
              ngpp_part_h_invalid_reasn:'',
              ngpp_part_l_invalid_reasn:'',
              ngpp_part_w_invalid_reasn:'',
              ngpp_part_wt_invalid_reasn:''
            })

              // this.secondarypartgridforpost_ee.push(selectedparts_data[ki]);
              // this.secondarypartsgrid_ee.push(selectedparts_data[ki]);
              grid2_parts.push({
                bompartmasterid:selectedparts_data[ki].bompartmasterid,
                cntrsuggestedownershpstrat:selectedparts_data[ki].cntrsuggestedownershpstrat,
              disable:false,
              ngpp_part_status:tab1_data.submit_status,
              part_pre_bas_suf:selectedparts_data[ki].partprefix+'-'+selectedparts_data[ki].partbase+'-'+selectedparts_data[ki].partsuffix,
              partbase:selectedparts_data[ki].partbase,
              partcontrol:selectedparts_data[ki].partcontrol,
              partdesc:selectedparts_data[ki].partdesc,
              parteffectiveindate:selectedparts_data[ki].parteffectiveindate,
              partnumbercommonized:selectedparts_data[ki].partnumbercommonized,
              partprefix:selectedparts_data[ki].partprefix,
              partsuffix:selectedparts_data[ki].partsuffix,
              plantgsdbcode:selectedparts_data[ki].plantgsdbcode,
              plantname:selectedparts_data[ki].plantname,
              programname:selectedparts_data[ki].programname,
              suppliercountrycodeISO:selectedparts_data[ki].suppliercountrycodeiso,
              suppliergsdbcode:selectedparts_data[ki].suppliergsdbcode,
              tradingcogsdbcode:selectedparts_data[ki].tradingcogsdbcode,
              ngpp_part_status_name:'1',
              non_submited:true,
              displaypartstatus:tab1_data.submit_status,
              isdeliquent:'',
              isstatusurgent:'',
              deliquentondate:'',
              urgentondate:'',
              imp_ngpp_part_status:'',
              offsitesupplierid:selectedparts_data[ki].offsitesupplierid
              })





            }
          }

          this.originalTab1Primarygrid_ee=JSON.parse(JSON.stringify(this.primarypartsgrid_ee));
          this.changedTab1Primarygrid_ee=this.primarypartsgrid_ee;

          this.originalTab1Secondarygrid_ee=JSON.parse(JSON.stringify(this.secondarypartsgrid_ee));
          this.changedTab1Secondarygrid_ee=this.secondarypartsgrid_ee


          console.log(grid2_parts);
          console.log(grid1_parts)


          if(grid1_parts.length != 0)
          {
            this.selectedPartgridList_ee.push(grid1_parts[0]);
          }

          this.selectedPartgridList_ee.push(...grid2_parts);


          this.getPartgridList_ee=this.allPartgridList_ee


          console.log(this.selectedPartgridList_ee);
          console.log(this.secondarypartsgrid_ee)


          if(this.secondarypartsgrid_ee.length!=0)
          {
            this.enablegrid4_ee=true;
          }


          else{
            this.enablegrid4_ee=false
          }


          this.on_change_isStampingBlank_ee();


          this.selectpart_cols_ee = [
            { field: 'partnumbercommonized', header: 'Simplified Part Number' },
            { field: 'plantgsdbcode', header: 'Plant' },
            { field: 'programname', header: 'Program(s)' },
            { field: 'suppliergsdbcode', header:'Supplier GSDB'},
            { field: 'parteffectiveindate', header: 'Effective In Dates' },

          ];



          this.getpart_cols_ee = [
            { field: 'partnumbercommonized', header: 'Simplified Part Number' },
            { field: 'plantgsdbcode', header: 'Plant' },
            { field: 'programname', header: 'Program(s)' },
            { field: 'suppliergsdbcode', header:'Supplier GSDB'},
            { field: 'parteffectiveindate', header: 'Effective In Dates' },

          ];





        })

      })





    }





    on_change_measure_part_ee()
    {

  console.log('onchange measure');
      console.log(this.edit_measure_part_ee);

      if(this.unitom_ee == 'E')
      {
        this.edit_measure_part_ee = 'lbs/inch';
      }
      else{
        this.edit_measure_part_ee = 'kg/cm';
      }
      // if(this.edit_measure_part_ee=="lbs/inch")
      // {
      //   console.log(this.primarypartsgrid_ee);
      //   console.log(this.secondarypartsgrid_ee);


      //   if(this.primarypartsgrid_ee[0].ngpp_part_l!=null)
      //   {
      //     this.primarypartsgrid_ee[0].ngpp_part_l=Math.round((0.393701*this.primarypartsgrid_ee[0].ngpp_part_l)*1000)/1000;

      //   }
      //   if(this.primarypartsgrid_ee[0].ngpp_part_w!=null)
      //   {
      //     this.primarypartsgrid_ee[0].ngpp_part_w=Math.round((0.393701*this.primarypartsgrid_ee[0].ngpp_part_w)*1000)/1000;

      //   }
      //   if(this.primarypartsgrid_ee[0].ngpp_part_h!=null)
      //   {
      //     this.primarypartsgrid_ee[0].ngpp_part_h=Math.round((0.393701*this.primarypartsgrid_ee[0].ngpp_part_h)*1000)/1000;

      //   }
      //   if(this.primarypartsgrid_ee[0].ngpp_part_wt!=null)
      //   {
      //     this.primarypartsgrid_ee[0].ngpp_part_wt=Math.round((2.20462*this.primarypartsgrid_ee[0].ngpp_part_wt)*1000)/1000;

      //   }



      //   if(this.secondarypartsgrid_ee.length!=0)
      //   {


      //     for(var sec=0; sec<this.secondarypartsgrid_ee.length; sec++)
      //     {


      //       if(this.secondarypartsgrid_ee[sec].ngpp_part_l!=null)
      //       {
      //         this.secondarypartsgrid_ee[sec].ngpp_part_l=Math.round((0.393701*this.secondarypartsgrid_ee[sec].ngpp_part_l)*1000)/1000;

      //       }
      //       if(this.secondarypartsgrid_ee[sec].ngpp_part_w!=null)
      //       {
      //         this.secondarypartsgrid_ee[sec].ngpp_part_w=Math.round((0.393701*this.secondarypartsgrid_ee[sec].ngpp_part_w)*1000)/1000;

      //       }
      //       if(this.secondarypartsgrid_ee[sec].ngpp_part_h!=null)
      //       {
      //         this.secondarypartsgrid_ee[sec].ngpp_part_h=Math.round((0.393701*this.secondarypartsgrid_ee[sec].ngpp_part_h)*1000)/1000;

      //       }
      //       if(this.secondarypartsgrid_ee[sec].ngpp_part_wt!=null)
      //       {
      //         this.secondarypartsgrid_ee[sec].ngpp_part_wt=Math.round((2.20462*this.secondarypartsgrid_ee[sec].ngpp_part_wt)*1000)/1000;

      //       }


      //     }

      //   }

      // }
      // else if(this.edit_measure_part_ee=="kg/cm")
      // {


      //   console.log(this.primarypartsgrid_ee);
      //   console.log(this.secondarypartsgrid_ee);


      //   if(this.primarypartsgrid_ee[0].ngpp_part_l!=null)
      //   {
      //     this.primarypartsgrid_ee[0].ngpp_part_l=Math.round((2.54*this.primarypartsgrid_ee[0].ngpp_part_l)*1000)/1000;

      //   }
      //   if(this.primarypartsgrid_ee[0].ngpp_part_w!=null)
      //   {
      //     this.primarypartsgrid_ee[0].ngpp_part_w=Math.round((2.54*this.primarypartsgrid_ee[0].ngpp_part_w)*1000)/1000;

      //   }
      //   if(this.primarypartsgrid_ee[0].ngpp_part_h!=null)
      //   {
      //     this.primarypartsgrid_ee[0].ngpp_part_h=Math.round((2.54*this.primarypartsgrid_ee[0].ngpp_part_h)*1000)/1000;

      //   }
      //   if(this.primarypartsgrid_ee[0].ngpp_part_wt!=null)
      //   {
      //     this.primarypartsgrid_ee[0].ngpp_part_wt=Math.round((0.453592*this.primarypartsgrid_ee[0].ngpp_part_wt)*1000)/1000;

      //   }



      //   if(this.secondarypartsgrid_ee.length!=0)
      //   {


      //     for(var sec=0; sec<this.secondarypartsgrid_ee.length; sec++)
      //     {


      //       if(this.secondarypartsgrid_ee[sec].ngpp_part_l!=null)
      //       {
      //         this.secondarypartsgrid_ee[sec].ngpp_part_l=Math.round((2.54*this.secondarypartsgrid_ee[sec].ngpp_part_l)*1000)/1000;

      //       }
      //       if(this.secondarypartsgrid_ee[sec].ngpp_part_w!=null)
      //       {
      //         this.secondarypartsgrid_ee[sec].ngpp_part_w=Math.round((2.54*this.secondarypartsgrid_ee[sec].ngpp_part_w)*1000)/1000;

      //       }
      //       if(this.secondarypartsgrid_ee[sec].ngpp_part_h!=null)
      //       {
      //         this.secondarypartsgrid_ee[sec].ngpp_part_h=Math.round((2.54*this.secondarypartsgrid_ee[sec].ngpp_part_h)*1000)/1000;

      //       }
      //       if(this.secondarypartsgrid_ee[sec].ngpp_part_wt!=null)
      //       {
      //         this.secondarypartsgrid_ee[sec].ngpp_part_wt=Math.round((0.453592*this.secondarypartsgrid_ee[sec].ngpp_part_wt)*1000)/1000;

      //       }


      //     }

      //   }


      // }

    }





  on_change_isStampingBlank_ee ()
    {


      console.log(this.isStampingBlank_ee)


      if(this.isStampingBlank_ee=='No')
      {


      this.cols1_ee = [
        { field: 'partnumbercommonized', header:'Primary Part Number'},
        {  field: 'ngpp_part_l', header:'Length'},
        { field: 'ngpp_part_w', header:'Width'},
        { field: 'ngpp_part_h', header:'Height'},
        { field: 'ngpp_part_wt', header:'Weight'},
        { field: 'ngpp_part_wt_status', header:'Weight Status'},
        { field: 'ngpp_ref_part_no', header:'Ref Part No'},
        { field: 'ngpp_manf_loc', header:'Manufacturing Location'}
      ];


      this.cols2_ee = [
        { field: 'partnumbercommonized', header:'Additional Part Number(s)'},
        { field: 'ngpp_part_l', header:'Length'},
        { field: 'ngpp_part_w', header:'Width'},
        { field: 'ngpp_part_h', header:'Height'},
        { field: 'ngpp_part_wt', header:'Weight'},
        { field: 'ngpp_part_wt_status', header:'Weight Status'},
        { field: 'ngpp_ref_part_no', header:'Ref Part No'},
        { field: 'ngpp_manf_loc', header:'Manufacturing Location'}
      ];

    }


    else{


      this.cols1_ee = [
        { field: 'partnumbercommonized', header:'Primary Part Number'},
        {  field: 'ngpp_part_l', header:'Pitch'},
        { field: 'ngpp_part_w', header:'Width'},
        { field: 'ngpp_part_h', header:'Thickness'},
        { field: 'ngpp_part_wt', header:'Weight'},
        { field: 'ngpp_part_wt_status', header:'Weight Status'},
        { field: 'ngpp_ref_part_no', header:'Ref Part No'},
        { field: 'ngpp_manf_loc', header:'Manufacturing Location'}
      ];


      this.cols2_ee = [
        { field: 'partnumbercommonized', header:'Additional Part Number(s)'},
        { field: 'ngpp_part_l', header:'Pitch'},
        { field: 'ngpp_part_w', header:'Width'},
        { field: 'ngpp_part_h', header:'Thickness'},
        { field: 'ngpp_part_wt', header:'Weight'},
        { field: 'ngpp_part_wt_status', header:'Weight Status'},
        { field: 'ngpp_ref_part_no', header:'Ref Part No'},
        { field: 'ngpp_manf_loc', header:'Manufacturing Location'}
      ];




    }
    }

  loadprimarypart_ee(primaryparts:subgrid[])
  {
    this.primarypartsbomid_ee=primaryparts[0].bompartmasterid.toString();
    console.log('primarypartsbomid',this.primarypartsbomid_ee);
    this.loadprimarypartgrid_ee();

  }

  primarypartsdetailsinit_ee()
  {
    this.primarypartgridforpost_ee=
    {
      id_proposal:0,
      bompartmasterid:0,
      plantgsdbcode:'',
      supplierid:'',
      cd_region:'',
      partsubmitergsdbcodeid:'',
      partintrobomuploadbatchid:0,
      lastseenonbomuploadbatchid:0,
      partintrodate:'',
      plantname:'',
      programname:'',
      partprefix:'',
      partbase:'',
      partsuffix:'',
      partcontrol:'',
      cpsccode:'',
      partdesc:'',
      partstatus:'',
      parttype:'',
      controlitem:'',
      commonalitycode:'',
      partaddedfrom:'',
      form1271requested:'',
      cntrsuggestedownershpstrat:'',
      offsitesupplierid:'',
      containerdesignsourceid:'',
      buyercode:'',
      transtimeindays:0,
      parteffectiveindate:'',
      s4pincode:'',
      parteffectiveoutdate:'',
      s4poutcode:'',
      carlinecode:'',
      ptotype:'',
      cmmspackagingconfirmcode:'',
      cmmscontainerbase:'',
      cmmscontainersuffix:'',
      cmmscomponent1base:'',
      cmmscomponent1suffix:'',
      cmmscomponent2base:'',
      cmmscomponent2suffix:'',
      cmmscontainerpieses:0,
      cmmscontainerextl:0,
      cmmscontainerextw:0,
      cmmscontainerexth:0,
      cmmscontainergrosswt:0,
      cmmscontainersperlayer:0,
      cmmslayerspershipunit:0,
      cmmsshipunitnumpieses:0,
      cmmsshipunitextl:0,
      cmmsshipunitextw:0,
      cmmsshipunitexth:0,
      cmmsshipunitgrosswt:0,
      cmmspackagingreleaselevel:'',
      partnumbercommonized:'',
      tradingcogsdbcode:'',
      ismissingpart:'',
      suppliercountrycodeiso:'',
      suppliergsdbcode:'',
      isprimary:'',
      selorder:0,
      txntimestamp:'',
      ngpp_part_l:0,
      ngpp_part_w:0,
      ngpp_part_h:0,
      ngpp_part_wt:0,
      ngpp_part_wt_status:'',
      ngpp_ref_part_no:'',
      ngpp_manf_loc:'',
      partid:''
  };
  }

  loadprimarypartgrid_ee()
  {




    this.transactionService.getSelectedPartsDetails(this.primarypartsbomid_ee).subscribe(ret=>{
      console.log(ret);
      this.primarypartgridforget_ee=ret[0];
      // this.primarypartgridforpost=JSON.parse(JSON.stringify(this.primarypartgridforget))
      this.primarypartgridforpost_ee.id_proposal=this.id_proposal_ee;

      this.primarypartgridforpost_ee.bompartmasterid=this.primarypartgridforget_ee.bompartmasterid;
      console.log('printing');
      this.primarypartgridforpost_ee.plantgsdbcode=this.primarypartgridforget_ee.plantgsdbcode;
      this.primarypartgridforpost_ee.supplierid=this.primarypartgridforget_ee.supplierid;
      this.primarypartgridforpost_ee.cd_region=this.primarypartgridforget_ee.cd_region;
      this.primarypartgridforpost_ee.partsubmitergsdbcodeid=this.primarypartgridforget_ee.partsubmitergsdbcodeid;
      this.primarypartgridforpost_ee.partintrobomuploadbatchid=this.primarypartgridforget_ee.partintrobomuploadbatchid;
      this.primarypartgridforpost_ee.lastseenonbomuploadbatchid=this.primarypartgridforget_ee.lastseenonbomuploadbatchid;
      this.primarypartgridforpost_ee.partintrodate=this.primarypartgridforget_ee.partintrodate;
      this.primarypartgridforpost_ee.plantname=this.primarypartgridforget_ee.plantname;
      this.primarypartgridforpost_ee.programname=this.primarypartgridforget_ee.programname;
      this.primarypartgridforpost_ee.partprefix=this.primarypartgridforget_ee.partprefix;
      this.primarypartgridforpost_ee.partbase=this.primarypartgridforget_ee.partbase;
      this.primarypartgridforpost_ee.partsuffix=this.primarypartgridforget_ee.partsuffix;
      this.primarypartgridforpost_ee.partcontrol=this.primarypartgridforget_ee.partcontrol;
      this.primarypartgridforpost_ee.cpsccode=this.primarypartgridforget_ee.cpsccode;
      this.primarypartgridforpost_ee.partdesc=this.primarypartgridforget_ee.partdesc;
      this.primarypartgridforpost_ee.partstatus=this.primarypartgridforget_ee.partstatus;
      this.primarypartgridforpost_ee.parttype=this.primarypartgridforget_ee.parttype;
      this.primarypartgridforpost_ee.controlitem=this.primarypartgridforget_ee.controlitem;
      this.primarypartgridforpost_ee.commonalitycode=this.primarypartgridforget_ee.commonalitycode;
      this.primarypartgridforpost_ee.partaddedfrom=this.primarypartgridforget_ee.partaddedfrom;
      this.primarypartgridforpost_ee.form1271requested=this.primarypartgridforget_ee.form1271requested;
      this.primarypartgridforpost_ee.cntrsuggestedownershpstrat=this.primarypartgridforget_ee.cntrsuggestedownershpstrat;
      this.primarypartgridforpost_ee.offsitesupplierid=this.primarypartgridforget_ee.offsitesupplierid;
      this.primarypartgridforpost_ee.containerdesignsourceid=this.primarypartgridforget_ee.containerdesignsourceid;
      this.primarypartgridforpost_ee.buyercode=this.primarypartgridforget_ee.buyercode;
      this.primarypartgridforpost_ee.transtimeindays=this.primarypartgridforget_ee.transtimeindays;
      this.primarypartgridforpost_ee.parteffectiveindate=this.primarypartgridforget_ee.parteffectiveindate;
      this.primarypartgridforpost_ee.s4pincode=this.primarypartgridforget_ee.s4pincode;
      this.primarypartgridforpost_ee.parteffectiveoutdate=this.primarypartgridforget_ee.parteffectiveoutdate;
      this.primarypartgridforpost_ee.s4poutcode=this.primarypartgridforget_ee.s4poutcode;
      this.primarypartgridforpost_ee.carlinecode=this.primarypartgridforget_ee.carlinecode;
      this.primarypartgridforpost_ee.ptotype=this.primarypartgridforget_ee.ptotype;
      this.primarypartgridforpost_ee.cmmspackagingconfirmcode=this.primarypartgridforget_ee.cmmspackagingconfirmcode;
      this.primarypartgridforpost_ee.cmmscontainerbase=this.primarypartgridforget_ee.cmmscontainerbase;
      this.primarypartgridforpost_ee.cmmscontainersuffix=this.primarypartgridforget_ee.cmmscontainersuffix;
      this.primarypartgridforpost_ee.cmmscomponent1base=this.primarypartgridforget_ee.cmmscomponent1base;
      this.primarypartgridforpost_ee.cmmscomponent1suffix=this.primarypartgridforget_ee.cmmscomponent1suffix;
      this.primarypartgridforpost_ee.cmmscomponent2base=this.primarypartgridforget_ee.cmmscomponent2base;
      this.primarypartgridforpost_ee.cmmscomponent2suffix=this.primarypartgridforget_ee.cmmscomponent2suffix;
      this.primarypartgridforpost_ee.cmmscontainerpieses=this.primarypartgridforget_ee.cmmscontainerpieses;
      this.primarypartgridforpost_ee.cmmscontainerextl=this.primarypartgridforget_ee.cmmscontainerextl;
      this.primarypartgridforpost_ee.cmmscontainerextw=this.primarypartgridforget_ee.cmmscontainerextw;
      this.primarypartgridforpost_ee.cmmscontainerexth=this.primarypartgridforget_ee.cmmsshipunitexth;
      this.primarypartgridforpost_ee.cmmscontainergrosswt=this.primarypartgridforget_ee.cmmscontainergrosswt;
      this.primarypartgridforpost_ee.cmmscontainersperlayer=this.primarypartgridforget_ee.cmmscontainersperlayer;
      this.primarypartgridforpost_ee.cmmslayerspershipunit=this.primarypartgridforget_ee.cmmslayerspershipunit;
      this.primarypartgridforpost_ee.cmmsshipunitnumpieses=this.primarypartgridforget_ee.cmmsshipunitnumpieses;
      this.primarypartgridforpost_ee.cmmsshipunitextl=this.primarypartgridforget_ee.cmmsshipunitextl;
      this.primarypartgridforpost_ee.cmmsshipunitextw=this.primarypartgridforget_ee.cmmsshipunitextw;
      this.primarypartgridforpost_ee.cmmsshipunitexth=this.primarypartgridforget_ee.cmmsshipunitexth;
      this.primarypartgridforpost_ee.cmmsshipunitgrosswt=this.primarypartgridforget_ee.cmmsshipunitgrosswt;
      this.primarypartgridforpost_ee.cmmspackagingreleaselevel=this.primarypartgridforget_ee.cmmspackagingreleaselevel;
      this.primarypartgridforpost_ee.partnumbercommonized=this.primarypartgridforget_ee.partnumbercommonized;
      this.primarypartgridforpost_ee.tradingcogsdbcode=this.primarypartgridforget_ee.tradingcogsdbcode;
      this.primarypartgridforpost_ee.ismissingpart=this.primarypartgridforget_ee.ismissingpart;
      this.primarypartgridforpost_ee.suppliercountrycodeiso=this.primarypartgridforget_ee.suppliercountrycodeiso;
      this.primarypartgridforpost_ee.suppliergsdbcode=this.primarypartgridforget_ee.suppliergsdbcode;
      this.primarypartgridforpost_ee.isprimary='yes';
      this.primarypartgridforpost_ee.selorder=0;
      this.primarypartgridforpost_ee.txntimestamp=new Date().toISOString().split('.')[0];
      this.primarypartgridforpost_ee.ngpp_part_l=0;
      this.primarypartgridforpost_ee.ngpp_part_w=0;
      this.primarypartgridforpost_ee.ngpp_part_h=0;
      this.primarypartgridforpost_ee.ngpp_part_wt=0;
      this.primarypartgridforpost_ee.ngpp_part_wt_status='';
      this.primarypartgridforpost_ee.ngpp_ref_part_no='';
      this.primarypartgridforpost_ee.ngpp_manf_loc='';
      this.primarypartgridforpost_ee.partid=this.primarypartgridforget_ee.partid;

this.primarypartsgrid_ee.push(this.primarypartgridforpost_ee);
  console.log('this.primarypartsgrid',this.primarypartsgrid_ee);

  this.originalTab1Primarygrid_ee=JSON.parse(JSON.stringify(this.primarypartsgrid_ee));
  this.changedTab1Primarygrid_ee=this.primarypartsgrid_ee;

  this.on_change_isStampingBlank_ee();



    });





  this.originalTab1Secondarygrid_ee=JSON.parse(JSON.stringify(this.secondarypartsgrid_ee));
  this.changedTab1Secondarygrid_ee=this.secondarypartsgrid_ee;

  this.loadprimarypartdetails_ee(this.id_proposal_ee);
  }


  loadprimarypartdetails_ee(proposal_id:number)
  {
    console.log('--------',this.primarypartgridforpost_ee.partnumbercommonized);
      this.primarypartsattributes_ee={
        id_proposal:proposal_id,
        temp_sensitivity:'No',
        corr_protc:'',
        seasnlreq:'',
        "shelf_life":null,
        sp_part_no:this.selectedPartgridList_ee[0].partnumbercommonized,
        temp_stor_spec:'',
       gppuserid:this.transactionService.getcdsid(),
        lst_saved_tab:0,
        submit_status:'not submitted',
        timestamp:new Date().toISOString().split('.')[0],
        plantgsdbcode:this.selectedpart_submitter_ee.plantgsdbcode,
        isstampblank:this.isStampingBlank_ee,
        supgsdbcode: '',
        supfullname: '',
         supaddress1: '',
         supcity: '',
         supstateprov: '',
          supcountrycode: '',
         supcontactnamefirst: '',
       supcontactname: '',
      supcontactfunctn: '',
        supcontactemail: '',
        supcontactphone: '',
        supcontactfax: '',
        defaultcurr:'',
        lst_saved_tab_ee:1,
        propsl_type:'Improvement'
    }
this.originalTab1Details_ee=JSON.parse(JSON.stringify(this.primarypartsattributes_ee));
    this.changedTab1Details_ee=this.primarypartsattributes_ee;
    console.log('in primarypart details',this.primarypartsattributes_ee);
  }

  onRowEditInit_ee(product: partDetailForPost) {
    this.editingdetails_ee++;
    this.partsdetailsbeforeediting_ee=product;
    this.clonedParts_ee[product.partnumbercommonized] = {...product};
    console.log('In row edit init', this.clonedParts_ee);
  }

  onRowEditSave_ee(product: partDetailForPost)
  {

    this.editingdetails_ee--;
    console.log('modified values',product);
    console.log('part attributes has been modified');
    if(this.primarypartsgrid_ee.includes(product))
    {
      console.log('primarypartsgrid',this.primarypartsgrid_ee);
      console.log('primarypartgridforpost',this.primarypartgridforpost_ee)
console.log('orgiignal primaryparts_ee',this.originalTab1Primarygrid_ee);

      console.log('changed primaryparts_ee',this.changedTab1Primarygrid_ee);
      delete this.clonedParts_ee[product.partnumbercommonized];

      this.messageService.add({severity:'success', summary: 'Success', detail:'Part Details Updated'});

    }
//     else
//     {
//       console.log("secndarypartsgridmodified");
//       console.log("secondarypartgridforpost",this.secondarypartgridforpost_ee)
//       console.log("secondarypartsgrid",this.secondarypartsgrid_ee);
// console.log("orgiignal secondary_ee",this.originalTab1Secondarygrid_ee);

//       console.log("changed secondary_ee",this.changedTab1Secondarygrid_ee);
//       delete this.clonedParts_ee[product.partnumbercommonized];
//       this.messageService.add({severity:'success', summary: 'Success', detail:'Part Details Updated'});

//     }

  }

  onRowEditCancel_ee(product: partDetailForPost, index: string)
  {
    this.editingdetails_ee--;
    if(this.primarypartsgrid_ee.includes(product))
    {
        this.primarypartsgrid_ee[index]=this.clonedParts_ee[product.partnumbercommonized];
    }
    else
    {
        this.secondarypartgridforpost_ee[index]=this.clonedParts_ee[product.partnumbercommonized];
        this.secondarypartsgrid_ee[index]=this.clonedParts_ee[product.partnumbercommonized];
    }

    this.messageService.add({severity:'error', summary: 'Cancelled', detail:'Edit has been cancelled'});

  }









  // while plant code changes get the user wise supplier wise plant wise program code list

  onchange_plant_ee() {


    console.log(this.selectedplantlist_prt_ee);

    if (this.selectedplantlist_prt_ee.length != 0) {
      this.disableProgDrp_prt_ee = false;


      let plantcodes_selected_commasep_prt='';
  plantcodes_selected_commasep_prt=this.selectedplantlist_prt_ee[0].plantcode;



  if(this.selectedplantlist_prt_ee.length>1)
  {
  for(let iconv=1; iconv<this.selectedplantlist_prt_ee.length; iconv++)
  {

    plantcodes_selected_commasep_prt+=',';
    plantcodes_selected_commasep_prt+=this.selectedplantlist_prt_ee[iconv].plantcode;

  }
  }


  console.log(plantcodes_selected_commasep_prt);

  const prog_output=[];
  this.transactionService.getprogmlistbasedonplantgsdb(plantcodes_selected_commasep_prt,this.current_region_code_prt_ee).subscribe(prog_output=>{

  console.log(prog_output);
  this.progListOnPlt_prt_ee=prog_output
  this.progList_prt_ee=[];
  if(this.progListOnPlt_prt_ee.length!=0)
  {
    let prog_plt='';
    for(let prg=0; prg<this.progListOnPlt_prt_ee.length; prg++)
    {


      let present_prg=false;
      prog_plt='';
      for(let kprg=0; kprg<this.progList_prt_ee.length; kprg++)
      {
             if(this.progListOnPlt_prt_ee[prg].programname.toLowerCase()==this.progList_prt_ee[kprg].programcode.toLowerCase() )
              {

               this.progList_prt_ee[kprg].programdisplay= this.progList_prt_ee[kprg].programdisplay+',('+this.progListOnPlt_prt_ee[prg].plantgsdbcode+')';

                   present_prg=true;
                                      break;
              }
       }


    if(present_prg==false)
            {
              prog_plt=this.progListOnPlt_prt_ee[prg].programname+'('+this.progListOnPlt_prt_ee[prg].plantgsdbcode+')';

      this.progList_prt_ee.push({programcode: this.progListOnPlt_prt_ee[prg].programname,programdisplay:prog_plt})

                }



    }

  }


  console.log(this.selectedproglist_prt_ee);


  if(this.progList_prt_ee.length!=0 && this.selectedproglist_prt_ee.length!=0)
  {
  const selprgarr=[];
  console.log(this.selectedproglist_prt_ee);
  console.log(this.progList_prt_ee)
  const prog_plt='';
          for(let iprg=0; iprg<this.progList_prt_ee.length; iprg++)
          {
            console.log(this.progList_prt_ee[iprg])
          for(let jprg=0; jprg<this.selectedproglist_prt_ee.length; jprg++)
          {
            console.log(this.selectedproglist_prt_ee[jprg])

            if(this.progList_prt_ee[iprg].programcode.toLowerCase()==this.selectedproglist_prt_ee[jprg].programcode.toLowerCase())
            {
              this.selectedproglist_prt_ee[jprg].programdisplay=this.progList_prt_ee[iprg].programdisplay
              selprgarr.push(this.selectedproglist_prt_ee[jprg]);
              console.log(selprgarr)
              break;
            }
          }

          }




          this.selectedproglist_prt_ee=selprgarr;

          console.log(this.selectedproglist_prt_ee)
  }


  else if(this.progList_prt_ee.length==0)
  {
  this.selectedproglist_prt_ee=[];
  }



  })


  // ----------------------------------------------------------------------------------------------------------------
  // in future do gor global admin if its used
   /*   this.transactionService.getUserAccessRole(this.prt_cdsid).subscribe(prt_prguseraccess => {
        console.log("prt_useracces", prt_prguseraccess);
        var position: number;
        this.prtPrgUserAccess = prt_prguseraccess;
        this.progList_prt = [];


        console.log(this.selectedplantlist_prt)

        for (var iplt = 0; iplt < this.selectedplantlist_prt.length; iplt++) {
          for (var prg_temp = 0; prg_temp < this.prtPrgUserAccess.length; prg_temp++) {


            if (this.prtUserAccess[prg_temp].cd_region == this.current_region_code_prt) {
              if (this.prtPrgUserAccess[prg_temp].role_id == 1 && this.prtPrgUserAccess[prg_temp].plantgsdbcode == this.selectedplantlist_prt[iplt].plantcode) {


                var prg_arr_prt = [];
                if (this.prtPrgUserAccess[prg_temp].program_name != null) {
                  prg_arr_prt = this.prtPrgUserAccess[prg_temp].program_name.split(',');
                  for (var prg = 0; prg < prg_arr_prt.length; prg++) {
                    var present_prg=false;
                    for(var kprg=0;kprg<this.progList_prt.length;kprg++)
                              {
                           if(prg_arr_prt[prg]==this.progList_prt[kprg].programcode)
                            {
                                 present_prg=true;
                                                    break;
                            }
                     }


                  if(present_prg==false)
                          {
                            this.progList_prt.push({programcode:prg_arr_prt[prg]})
                              }

                  }


                  console.log("proglist", this.progList_prt);
                }












              }
            }

          }


          console.log(this.selectedproglist_prt);



        }

        if (this.progList_prt.length != 0 && this.selectedproglist_prt.length != 0) {
          var selprgarr_prt = [];
          console.log(this.selectedproglist_prt);
          console.log(this.progList_prt)
          for (var iprg = 0; iprg < this.progList_prt.length; iprg++) {

            console.log(this.progList_prt[iprg])
            for (var jprg = 0; jprg < this.selectedproglist_prt.length; jprg++) {
              console.log(this.selectedproglist_prt[jprg])

              if (this.progList_prt[iprg].programcode == this.selectedproglist_prt[jprg].programcode) {
                selprgarr_prt.push(this.selectedproglist_prt[jprg]);
                console.log(selprgarr_prt)
                break;
              }
            }

          }




          this.selectedproglist_prt = selprgarr_prt;

          console.log(this.selectedproglist_prt)
        }


        else if (this.progList_prt.length == 0) {
          this.selectedproglist_prt = [];
        }




      })*/
  // -----------------------------------------------------------------------------------------------------------------------------
      // service

      // this.disableProgDrp=true;



    }


    else {
      this.disableProgDrp_prt_ee = true;
      this.selectedplantlist_prt_ee = [];
      this.selectedproglist_prt_ee = [];


    }

  }


  // while user selectes the filter values and apply filter to get parts based on filter


  // if no part is selected or all part selected parameter is "all"
  // else the the selected values as comma seperated values

  apply_filter_ee() {

    this.getpart_grid_text_ee = '';
    console.log(this.supp_code_prt_ee);
    console.log(this.selectedpartprefix_prt_ee);
    console.log(this.selectedpartbase_prt_ee);
    console.log(this.selectedpartsuffix_prt_ee);
    console.log(this.selectedplantlist_prt_ee);
    if (this.selectedpartprefix_prt_ee != null && this.selectedpartprefix_prt_ee != '') {
      this.part_prefix_prt_ee = this.selectedpartprefix_prt_ee

    }
    else {
      this.part_prefix_prt_ee = 'all'
    }

    if (this.selectedpartbase_prt_ee != null && this.selectedpartbase_prt_ee != '') {
      this.part_base_prt_ee = this.selectedpartbase_prt_ee

    }
    else {
      this.part_base_prt_ee = 'all'
    }



    if (this.selectedpartsuffix_prt_ee != null && this.selectedpartsuffix_prt_ee != '') {
      this.part_suffix_prt_ee = this.selectedpartsuffix_prt_ee

    }
    else {
      this.part_suffix_prt_ee = 'all'
    }


    if (this.selectedplantlist_prt_ee.length != 0) {

      console.log('inside')
      if (this.selectedplantlist_prt_ee.length == this.plantCodeList_prt_ee.length) {
        this.plantcode_prt_ee = 'all';
      }

      else {

        this.plantcode_prt_ee = '';
        let plt_count_prt = 0;
        for (let p_plt = 0; p_plt < this.selectedplantlist_prt_ee.length; p_plt++) {
          this.plantcode_prt_ee += this.selectedplantlist_prt_ee[p_plt].plantcode
          plt_count_prt += 1;
          if (plt_count_prt != this.selectedplantlist_prt_ee.length) {
            this.plantcode_prt_ee += ',';
          }
        }



      }

      console.log(this.plantcode_prt_ee);

    }

    else {

      this.plantcode_prt_ee = 'all';

    }




    // program

    if (this.selectedproglist_prt_ee.length != 0) {
      if (this.selectedproglist_prt_ee.length == this.progList_prt_ee.length) {
        this.programcode_prt_ee = 'all';
      }

      else {

        this.programcode_prt_ee = '';
        let prg_count_prt = 0;
        for (let p_prg = 0; p_prg < this.selectedproglist_prt_ee.length; p_prg++) {
          this.programcode_prt_ee += this.selectedproglist_prt_ee[p_prg].programcode
          prg_count_prt += 1;
          if (prg_count_prt != this.selectedproglist_prt_ee.length) {
            this.programcode_prt_ee += ',';
          }
        }



      }

      console.log(this.programcode_prt_ee);

    }

    else {
      this.programcode_prt_ee = 'all';

    }



    // cos

    console.log(this.getPartgridList_ee);
    console.log(this.selectedPartgridList_ee);


    if(this.prt_roleid_ee==1)
    {


      let grid_display_part_status='';
    console.log(this.plantcode_prt_ee, this.programcode_prt_ee, this.part_prefix_prt_ee, this.part_base_prt_ee, this.part_suffix_prt_ee, this.supp_code_prt_ee)

    this.transactionService.getsubmittergrid(this.plantcode_prt_ee, this.programcode_prt_ee, 'all', 'New Part', 'all', this.part_prefix_prt_ee, this.part_base_prt_ee, this.part_suffix_prt_ee, this.selectedpart_submitter_ee.supplier_code, this.prt_cdsid_ee, 1, this.current_region_code_prt_ee).subscribe((subgridlist => {
     // this.partgridList_ee = subgridlist
     this.partgridList_ee =[];

     const offsitesupplierid=sessionStorage.getItem('offsitesupplierid')
     if(subgridlist != null && subgridlist.length != 0)
     {
       if(offsitesupplierid == null || offsitesupplierid == 'null')
       {
         for(const prt of subgridlist)
         {
           if(prt.offsitesupplierid == null)
           {
             this.partgridList_ee.push(prt)
           }
         }
       }
       else
       {
         for(const prt of subgridlist)
         {
           if(prt.offsitesupplierid != null && prt.offsitesupplierid.trim().toLowerCase() == offsitesupplierid.trim().toLowerCase())
           {
             this.partgridList_ee.push(prt)
           }
         }
       }
     }


      if(this.partgridList_ee!=null && this.partgridList_ee.length!=0)
      {
        for(let i=0;i<this.partgridList_ee.length;i++)
        {

  grid_display_part_status='';
  grid_display_part_status+=this.partgridList_ee[i].ngpp_part_status;

  if(this.partgridList_ee[i].isstatusurgent!=null && this.partgridList_ee[i].isstatusurgent.toLowerCase()=='y')
  {
    grid_display_part_status+=' (Urgent)'
  }
  if(this.partgridList_ee[i].isdeliquent!=null && this.partgridList_ee[i].isdeliquent.toLowerCase()=='y')
  {
    grid_display_part_status+=' (Delinquent)'
  }


  this.partgridList_ee[i].displaypartstatus=grid_display_part_status;

        }
      }



  this.applyfilter_load_table1data_ee();
      // this.suppcode=[];
      /* if(this.suppget.length==0)
       {
         this.valueback=true;
       }
       else{
       this.valueback=false;
      }*/

    }

    ))


  }


  else if(this.prt_roleid_ee==2)
  {
    // service get all data similar to submitter dashboard
    // supplier code="all"
    // supplier country="all" send design source


    let grid_display_part_status='';

    this.transactionService.getsubmittergrid_designsource(this.plantcode_prt_ee,this.programcode_prt_ee,'all','New Part','all',this.part_prefix_prt_ee,this.part_base_prt_ee,this.part_suffix_prt_ee,this.selectedpart_submitter_ee.supplier_code,'all',this.designsource_code_prt_ee,this.prt_cdsid_ee,2,this.current_region_code_prt_ee).subscribe((subgridlist => {
    // this.partgridList_ee = subgridlist

    this.partgridList_ee =[];

    const offsitesupplierid=sessionStorage.getItem('offsitesupplierid')
    if(subgridlist != null && subgridlist.length != 0)
    {
      if(offsitesupplierid == null || offsitesupplierid == 'null')
      {
        for(const prt of subgridlist)
        {
          if(prt.offsitesupplierid == null)
          {
            this.partgridList_ee.push(prt)
          }
        }
      }
      else
      {
        for(const prt of subgridlist)
        {
          if(prt.offsitesupplierid != null && prt.offsitesupplierid.trim().toLowerCase() == offsitesupplierid.trim().toLowerCase())
          {
            this.partgridList_ee.push(prt)
          }
        }
      }
    }



    if(this.partgridList_ee!=null && this.partgridList_ee.length!=0)
    {
      for(let i=0;i<this.partgridList_ee.length;i++)
      {

  grid_display_part_status='';
  grid_display_part_status+=this.partgridList_ee[i].ngpp_part_status;

  if(this.partgridList_ee[i].isstatusurgent!=null && this.partgridList_ee[i].isstatusurgent.toLowerCase()=='y')
  {
  grid_display_part_status+=' (Urgent)'
  }
  if(this.partgridList_ee[i].isdeliquent!=null && this.partgridList_ee[i].isdeliquent.toLowerCase()=='y')
  {
  grid_display_part_status+=' (Delinquent)'
  }


  this.partgridList_ee[i].displaypartstatus=grid_display_part_status;

      }
    }


  this.applyfilter_load_table1data_ee();
  }))
  }







    // service
  }






  applyfilter_load_table1data_ee()
  {
    this.getPartgridList_ee = [];
    console.log('successful', this.partgridList_ee);


    // after we get part get data , we have to check if the data is present in selcted data as secondary
    // if present remove the data from get data
    // else push is to get data
    if (this.partgridList_ee.length != 0 && this.partgridList_ee != null) {

      this.getPartgridList_ee = [];
      if (this.selectedPartgridList_ee.length != 0) {
        for (let ki = 0; ki < this.partgridList_ee.length; ki++) {
          let prt_present = false
          for (let kj = 0; kj < this.selectedPartgridList_ee.length; kj++) {
            if (this.selectedPartgridList_ee[kj].bompartmasterid == this.partgridList_ee[ki].bompartmasterid) {
              prt_present = true;
              break;
            }
          }


          if (prt_present == false) {

            this.getPartgridList_ee.push(this.partgridList_ee[ki]);

          }
        }
      }


      else {
        this.getPartgridList_ee = this.partgridList_ee
      }


      this.getpart_grid_text_ee = '';
      this.getpart_present_table_ee = true;

      this.getpart_cols_ee = [
        { field: 'partnumbercommonized', header: 'Simplified Part Number' },
        { field: 'plantgsdbcode', header: 'Plant' },
        { field: 'programname', header: 'Program(s)' },
        { field: 'suppliergsdbcode', header:'Supplier GSDB'},
        { field: 'parteffectiveindate', header: 'Effective In Dates' },

      ];
    }


    else {
      this.getpart_present_table_ee = false;
      this.getpart_cols_ee = [];
      this.getpart_grid_text_ee = 'No Match Found'
    }


  }






  // while clicking add after selecting parts from get data
  // to remove selected parts from get data
  // to add data to select grid/data

  addSelectedParts_ee() {


    if (this.choosedGetPartGrid_ee.length != 0) {
      console.log(this.choosedGetPartGrid_ee);
      this.enablegrid4_ee=true;

      let tempGetPartList: subgrid[] = [];
      if (this.choosedGetPartGrid_ee.length == this.getPartgridList_ee.length) {
        this.getPartgridList_ee = [];

      }
      else if (this.choosedGetPartGrid_ee.length != this.getPartgridList_ee.length) {
        tempGetPartList = this.getPartgridList_ee;
        this.getPartgridList_ee = [];

        for (let mi = 0; mi < tempGetPartList.length; mi++) {

          let temp_present_part_value = false;

          for (let mj = 0; mj < this.choosedGetPartGrid_ee.length; mj++) {


            if (tempGetPartList[mi].bompartmasterid == this.choosedGetPartGrid_ee[mj].bompartmasterid) {
              temp_present_part_value = true;
              break;
            }

          }


          if (temp_present_part_value == false) {
            this.getPartgridList_ee.push(tempGetPartList[mi]);
          }
        }

      }

      console.log(this.selectedPartgridList_ee)




      for (let ni = 0; ni < this.choosedGetPartGrid_ee.length; ni++) {
        console.log(this.selectedPartgridList_ee)


        this.selectedPartgridList_ee.push(this.choosedGetPartGrid_ee[ni]);
        let select_prt_index;
        select_prt_index = this.selectedPartgridList_ee.length - 1;
        console.log(select_prt_index)
        this.selectedPartgridList_ee[select_prt_index].disable = false;

      }
      // push choosedGetPartGrid to grid4
      // call get details
      console.log('choosedgetpartgrid', this.choosedGetPartGrid_ee);
      this.loadsecondaryparts_ee(this.primarypartgridforpost_ee.id_proposal,this.choosedGetPartGrid_ee);

this.changeStatus_ee=true;
        if(this.existChangeStatusTab1_ee != null && this.existChangeStatusTab1_ee.toLowerCase()=='unchanged')
        {
          // alert("chnage data");
          const setChangestatusTab2:changeTrackingForImprovement={idproposal:0,chnge_timestamp:'',status:''}
          setChangestatusTab2.idproposal=this.selectedpart_submitter_ee.proposalid
          setChangestatusTab2.status='changed';
          const cur_date=new Date();
          const cur_date_transform=this.date.transform(cur_date, 'yyyy-MM-dd HH:mm:ss');
          setChangestatusTab2.chnge_timestamp=cur_date_transform
          console.log('update change status ',setChangestatusTab2)
          this.transactionService.setImprovementChangeStatus(setChangestatusTab2).subscribe(chang_stat=>{
            console.log('change status update',chang_stat);
            if(chang_stat.toLowerCase() == 'success')
            {
              sessionStorage.setItem('ProposalChangeStatus',setChangestatusTab2.status);
              this.existChangeStatusTab1_ee='changed';

            }
          })
        }


      this.choosedGetPartGrid_ee = [];

    }
  }
  loadsecondaryparts_ee(proposal_id:number, grid4values:any)
  {
      this.secondarypartreceivedfromgrid1_ee=grid4values;
      console.log('secondarypartreceivedfromgrid1',this.secondarypartreceivedfromgrid1_ee);

      let sec_part='';
      for(let part=0; part<this.secondarypartreceivedfromgrid1_ee.length; part++)
      {
        sec_part+=JSON.parse(JSON.stringify(this.secondarypartreceivedfromgrid1_ee[part])).bompartmasterid;

        if(part<this.secondarypartreceivedfromgrid1_ee.length-1)
        {
          sec_part+=',';
        }
      }
      this.secondarypartsbomid_ee=sec_part;

      console.log('secondarypartbomid',this.secondarypartsbomid_ee);

      this.transactionService.getSelectedPartsDetails(this.secondarypartsbomid_ee).subscribe(value=>{
        console.log('getsec_partsfromapi',value);
        this.secondarypartsgridforget_ee=value;
        console.log('secondarypartsgridforget',this.secondarypartsgridforget_ee);
        this.loadsecondarypartsforpost_ee(proposal_id);
      });

  }

  loadsecondarypartsforpost_ee(proposalid:number)
  {
    for(let i=this.secondarypartgridforpost_ee.length ,j=0; j<this.secondarypartsgridforget_ee.length && this.secondarypartsgridforget_ee.length>0; i++,j++)
        {

        this.secondarypartgridforpost_ee[i]=JSON.parse(JSON.stringify(this.secondarypartsgridforget_ee[j]));
         this.secondarypartgridforpost_ee[i].id_proposal=proposalid;
          this.secondarypartgridforpost_ee[i].isprimary='no';
          this.secondarypartgridforpost_ee[i].selorder=i+1;
          this.secondarypartgridforpost_ee[i].txntimestamp=new Date().toISOString().split('.')[0];
          this.secondarypartgridforpost_ee[i].ngpp_part_l=0;
          this.secondarypartgridforpost_ee[i].ngpp_part_w=0;
          this.secondarypartgridforpost_ee[i].ngpp_part_h=0;
          this.secondarypartgridforpost_ee[i].ngpp_part_wt=0;
          this.secondarypartgridforpost_ee[i].ngpp_part_wt_status='';
          this.secondarypartgridforpost_ee[i].ngpp_ref_part_no='';
          this.secondarypartgridforpost_ee[i].ngpp_manf_loc='';
        }
        console.log('this.secondarypartgridforpost',this.secondarypartgridforpost_ee);
   this.on_change_isStampingBlank_ee();

        this.secondarypartsgrid_ee=[...this.secondarypartgridforpost_ee];
  }






  // while clicking remove button
  // to remove data from selcected parts
  // to add parts to get parts list , removed parts should be added in the first of get data
  removeSelectedParts_ee() {


    console.log(this.choosedSelectPartGrid_ee)

    if (this.choosedSelectPartGrid_ee.length != 0) {
      let tempSelectPartGrid: subgrid[] = [];
      tempSelectPartGrid = this.selectedPartgridList_ee;
      this.selectedPartgridList_ee = [];
      this.selectedPartgridList_ee.push(tempSelectPartGrid[0]);
      this.selectedPartgridList_ee[0].disable = true;

      console.log(this.selectedPartgridList_ee);


      for (let oi = 1; oi < tempSelectPartGrid.length; oi++) {
        let temp_present_select_part_value = false;
        for (let oj = 0; oj < this.choosedSelectPartGrid_ee.length; oj++) {

          if (tempSelectPartGrid[oi].bompartmasterid == this.choosedSelectPartGrid_ee[oj].bompartmasterid) {
            temp_present_select_part_value = true;
            break;
          }

        }
        if (temp_present_select_part_value == false) {
          this.selectedPartgridList_ee.push(tempSelectPartGrid[oi]);

        }
      }


      console.log(this.getPartgridList_ee);


      let sort_temp_getpartgridlist: subgrid[] = [];
      sort_temp_getpartgridlist = this.getPartgridList_ee;
      this.getPartgridList_ee = [];


      for (let pi = 0; pi < this.choosedSelectPartGrid_ee.length; pi++) {
        this.getPartgridList_ee.push(this.choosedSelectPartGrid_ee[pi]);
      }





      for (let pj = 0; pj < sort_temp_getpartgridlist.length; pj++) {
        this.getPartgridList_ee.push(sort_temp_getpartgridlist[pj]);
      }

      // code to remove partnumbers(choosedSelectpartGrid) in db by calling API


      // write logic to remove the partnumbers(choosedSelectPartGrid) from grid4
      let idtoberemovedwithcommaseperated='';
      let partInDbRemoved = false;
      let iter=0;
      for(const partstoberemoved of this.choosedSelectPartGrid_ee)
      {
        let index;

        let removalflag=false;
        const partremoval=partstoberemoved.bompartmasterid;
        if(iter<this.choosedSelectPartGrid_ee.length)
        {
          idtoberemovedwithcommaseperated+=partremoval;
          iter=iter+1;
          if(iter<=this.choosedSelectPartGrid_ee.length-1)
          {
              idtoberemovedwithcommaseperated+=',';
          }
          if(partremoval==this.selectedpart_submitter_ee.bompartmasterid)
          {
            removalflag=true;
          }
        }



        let removingIdSelOrder:number;
        for(const valu of this.secondarypartgridforpost_ee)
        {
          if(valu.bompartmasterid == partremoval)
          {

            index=this.secondarypartgridforpost_ee.findIndex(a=>a==valu);

            removingIdSelOrder=-1
            if(index != -1 )
            {
              removingIdSelOrder=this.secondarypartgridforpost_ee[index].selorder;
            }



            this.secondarypartgridforpost_ee.splice(this.secondarypartgridforpost_ee.findIndex(a=>a==valu),1);

            // this.loadsecondarypartsforpost(this.id_proposal);

            console.log(index);
            // console.log(this.secondarypartgridforpost[index],this.secondarypartgridforpost[index].selorder);
            // console.log(this.secondarypartgridforpost[index].selorder-1);

            for(let i=0 ; i<=this.secondarypartgridforpost_ee.length-1;i++)
              {
                if(this.secondarypartgridforpost_ee[i].selorder > removingIdSelOrder)
                {
                this.secondarypartgridforpost_ee[i].selorder=this.secondarypartgridforpost_ee[i].selorder-1;
                }
              }

            console.log('finalvalues',this.secondarypartgridforpost_ee);
         }
        }
        if(this.secondarypartgridforpost_ee.length==0)
        {
          this.enablegrid4_ee=false;
        }

        if(!partInDbRemoved)
        {
            if(this.originalTab1Secondarygrid_ee != null && this.originalTab1Secondarygrid_ee.length != 0)
            {
             const indexPart = this.originalTab1Secondarygrid_ee.findIndex(ele => ele.bompartmasterid == partremoval)
             if(indexPart != -1)
             {
               partInDbRemoved = true;
             }
            }
        }

      }
      this.secondarypartgridforpost_ee=this.secondarypartgridforpost_ee;
      this.secondarypartsgrid_ee=[...this.secondarypartgridforpost_ee];
      console.log('after removal of secondary parts',this.secondarypartgridforpost_ee);
      console.log('idtoremovedwithcommaseperated',idtoberemovedwithcommaseperated);
this.changeStatus_ee=true;
      if(this.existChangeStatusTab1_ee != null && this.existChangeStatusTab1_ee.toLowerCase()=='unchanged')
        {
          // alert("chnage data");
          const setChangestatusTab2:changeTrackingForImprovement={idproposal:0,chnge_timestamp:'',status:''}
          setChangestatusTab2.idproposal=this.selectedpart_submitter_ee.proposalid
          setChangestatusTab2.status='changed';
          const cur_date=new Date();
          const cur_date_transform=this.date.transform(cur_date, 'yyyy-MM-dd HH:mm:ss');
          setChangestatusTab2.chnge_timestamp=cur_date_transform
          console.log('update change status ',setChangestatusTab2)
          this.transactionService.setImprovementChangeStatus(setChangestatusTab2).subscribe(chang_stat=>{
            console.log('change status update',chang_stat);
            if(chang_stat.toLowerCase() == 'success')
            {
              sessionStorage.setItem('ProposalChangeStatus',setChangestatusTab2.status);
              this.existChangeStatusTab1_ee='changed';

            }
          })
        }
        if(!partInDbRemoved)
        {
          if(idtoberemovedwithcommaseperated != null && idtoberemovedwithcommaseperated.trim() != '')
          {
              // @ts-ignore
            this.removeParts_ee(idtoberemovedwithcommaseperated,removalflag);
          }
        }

      this.choosedSelectPartGrid_ee = [];

    }





  }

  removeParts_ee(removalids:any, flag:boolean)
  {
    this.transactionService.removeParts(removalids,this.selectedpart_submitter_ee.proposalid).subscribe(remo=>{
      if(remo=='Success')
      {
        console.log('Success',remo);

        this.transactionService.get_draft_selectedparts_data(this.selectedpart_submitter_ee.proposalid).subscribe(selectedparts_data=>{


          console.log(selectedparts_data);
          // this.secondarypartgridforpost
          const secondarypartsAfterOrderChange:partDetailForPost[]=[]

          for(let ki=0;ki<selectedparts_data.length;ki++)
          {
            if(selectedparts_data[ki].isprimary.toLowerCase()!='yes')
            {
              const findSecPartIndex=this.secondarypartsgrid_ee.findIndex(ele => ele.bompartmasterid == selectedparts_data[ki].bompartmasterid)
              if(findSecPartIndex != -1)
              {
                selectedparts_data[ki].selorder = this.secondarypartsgrid_ee[findSecPartIndex].selorder;
                secondarypartsAfterOrderChange.push(selectedparts_data[ki]);
              }
            }
          }
          console.log(this.secondarypartgridforpost_ee,this.secondarypartsgrid_ee);
          console.log('secondarypartsAfterOrderChange',secondarypartsAfterOrderChange)
          if(secondarypartsAfterOrderChange != null && secondarypartsAfterOrderChange.length != 0)
          {
          this.transactionService.savePartDetails(secondarypartsAfterOrderChange).subscribe(saveattributes=>
            {
              const op=saveattributes;
              console.log('successful',op);
              if(saveattributes=='Success')
              {
                console.log('success while uploading sel order')
              }
              else
              {
                console.log('error while uploading sel order')
              }
            })
          }



        })
          /*this.transactionService.getproposalid().subscribe(sub_proposalid=>{


            console.log(sub_proposalid)
            this.selectedpart_submitter.proposalid=sub_proposalid;
            this.selectedpart_submitter.proposallastsaved=0;
            this.store_primary_part_exist();

            this.primarypartsgrid=[];
            this.primarypartsdetailsinit();

            this.secondarypartsgrid=[];
            this.secondarypartsgridforget=[];
            this.secondarypartgridforpost=[];
            this.enablegrid4=false;

            this.ngOnInit();
            this.packagingproposal.savedtab=0;
            this.packagingproposal.tabpackagingproposalmenuitems=[];
            this.packagingproposal.loadmenubar();


          });*/



        // this.packagingproposal.addtoast({severity:'success', summary:'Success', detail:'Successfully deleted part from db'});
      }
      else{
        this.packagingproposal.addtoast({severity:'error', summary:'Error', detail:'Error In removing additional part'});
      }
    })
  }


  isCopyPartsEnable_ee():boolean
  {


    if(this.primarypartsgrid_ee.length!=0 && this.secondarypartsgrid_ee.length !=0)
    {
      return true;
    }


    else{
      return false;
    }


  }



  copyPrimaryToSecondaryParts_ee()
  {
  if(this.secondarypartsgrid_ee.length!=0)
  {
    console.log('inside copy parts ',this.primarypartsgrid_ee)
    console.log(this.secondarypartsgrid_ee);


    for(let icopy=0; icopy<this.secondarypartsgrid_ee.length; icopy++)
    {


      this.secondarypartsgrid_ee[icopy].ngpp_part_l=this.primarypartsgrid_ee[0].ngpp_part_l;
      this.secondarypartsgrid_ee[icopy].ngpp_part_w=this.primarypartsgrid_ee[0].ngpp_part_w;
      this.secondarypartsgrid_ee[icopy].ngpp_part_h=this.primarypartsgrid_ee[0].ngpp_part_h;
      this.secondarypartsgrid_ee[icopy].ngpp_part_wt=this.primarypartsgrid_ee[0].ngpp_part_wt;
      this.secondarypartsgrid_ee[icopy].ngpp_part_wt_status=this.primarypartsgrid_ee[0].ngpp_part_wt_status;
      this.secondarypartsgrid_ee[icopy].ngpp_ref_part_no=this.primarypartsgrid_ee[0].ngpp_ref_part_no;
      this.secondarypartsgrid_ee[icopy].ngpp_manf_loc=this.primarypartsgrid_ee[0].ngpp_manf_loc;



    }
    this.messageService.add({severity:'success', summary:'Success', detail:'Successfully copied values'});
  }

  }


  store_primary_part_exist()
  {

    if(this.current_region_code_prt_ee=='NA')
    {

      sessionStorage.setItem('napackpropart', JSON.stringify(this.selectedpart_submitter_ee));
    }


    if(this.current_region_code_prt_ee=='EU')
    {
      sessionStorage.setItem('eupackpropart', JSON.stringify(this.selectedpart_submitter_ee));
    }



    if(this.current_region_code_prt_ee=='AP')
    {
      sessionStorage.setItem('appackpropart', JSON.stringify(this.selectedpart_submitter_ee));
    }



    if(this.current_region_code_prt_ee=='SA')
    {
      sessionStorage.setItem('sapackpropart', JSON.stringify(this.selectedpart_submitter_ee));
    }
  }

  
  corrosionProtectionValidation_ee()
  {
    if(this.selected_corrosion_protection_ee==null || this.selected_corrosion_protection_ee.corsn_prot_type.trim()=="")
    {
      this.corrosionProtectionInvalid_ee=true;
      this.corrosionProtectionInvalidReason_ee="Required";
    }
    else
    {
      this.corrosionProtectionInvalid_ee=false;
      this.corrosionProtectionInvalidReason_ee=null;
    }
  }

  shelfLifeValidation_ee()
  {
    if(this.primarypartsattributes_ee.shelf_life==null)
    {
      this.shelfLifeInvalid_ee=true;
      this.shelfLifeInvalidReason_ee="Required";
    }
    else
    {
      this.shelfLifeInvalid_ee=false;
      this.shelfLifeInvalidReason_ee=null;
    }
  }
  
  shelfLifeOptions(){
    this.shelfLifeList_ee = [];


      for (let i = 0; i <= 36; i++) {
        this.shelfLifeList_ee.push({ label: i.toString(), value: i });
      }
  
      // Change the first element's label to 'NA'
      if (this.shelfLifeList_ee.length > 0) {
        this.shelfLifeList_ee[0].label = 'NA';
      }
    console.log("Shelflife List", this.shelfLifeList_ee);
  }

  
  pripartlenValidation_ee()
  {
    const pripart_l = String(this.primarypartgridforpost_ee.ngpp_part_l).split('.');
    if(!String(this.primarypartgridforpost_ee.ngpp_part_l).includes('.'))
    {
     if(this.primarypartgridforpost_ee.ngpp_part_l==0 || this.primarypartgridforpost_ee.ngpp_part_l==null)
      {
        this.pripartlenInvalid_ee = true;
        this.pripartlenInvalidReason_ee = 'Required';
        console.log('LENPART');
      }
      else if(this.primarypartgridforpost_ee.ngpp_part_l<=0 || this.primarypartgridforpost_ee.ngpp_part_l>999.9999)
      {
        this.pripartlenInvalid_ee = true;
        this.pripartlenInvalidReason_ee = 'Should be between 0 to 999.9999';
      }
      else if( String(this.primarypartgridforpost_ee.ngpp_part_l).length>8)
      {
        this.pripartlenInvalid_ee = true;
        this.pripartwidInvalidReason_ee = 'Max Length Of Characters can be 8';
      }
      else
      {
        this.pripartlenInvalid_ee = false;
        this.pripartlenInvalidReason_ee = null;
        console.log('LENPART2');
      }
    }
    else if(String(this.primarypartgridforpost_ee.ngpp_part_l).includes('.'))
    {
        if(String(pripart_l[0]).length<0 || String(pripart_l[0]).length>3)
        {
          this.pripartlenInvalid_ee=true;
          this.pripartlenInvalidReason_ee='Should be between 0 to 999.9999';
        }
        else if(this.primarypartgridforpost_ee.ngpp_part_l<=0 || this.primarypartgridforpost_ee.ngpp_part_l>999.9999)
        {
          this.pripartlenInvalid_ee=true;
          this.pripartlenInvalidReason_ee='Should be between 0 to 999.9999';
        }
        else if(String(pripart_l[1]).length<0 || String(pripart_l[1]).length>4)
        {
          this.pripartlenInvalid_ee=true;
          this.pripartlenInvalidReason_ee='Max Length Of decimal values can be 4';
        }
        else
        {
          this.pripartlenInvalid_ee = false;
          this.pripartlenInvalidReason_ee = null;
        }
    }
    else
    {
      this.pripartlenInvalid_ee = false;
      this.pripartlenInvalidReason_ee = null;
    }
  }

     pripartwidValidation_ee()
     {
      const pripart_w = String(this.primarypartgridforpost_ee.ngpp_part_w).split('.');
      if(!String(this.primarypartgridforpost_ee.ngpp_part_w).includes('.'))
      {
      if(this.primarypartgridforpost_ee.ngpp_part_w==0 || this.primarypartgridforpost_ee.ngpp_part_w==null)
      {
        this.pripartwidInvalid_ee = true;
        this.pripartwidInvalidReason_ee = 'Required';
        console.log('WIDPART');
      }
      else if(this.primarypartgridforpost_ee.ngpp_part_w<=0 || this.primarypartgridforpost_ee.ngpp_part_w > 999.9999)
      {
        this.pripartwidInvalid_ee = true;
        this.pripartwidInvalidReason_ee = 'Should be between 0 to 999.9999';
        console.log('WIDPART');
      }
      else if( String(this.primarypartgridforpost_ee.ngpp_part_w).length>8)
      {
        this.pripartwidInvalid_ee = true;
        this.pripartwidInvalidReason_ee = 'Max Length Of Characters can be 8';
      }
      else
      {
        this.pripartwidInvalid_ee = false;
        this.pripartwidInvalidReason_ee = null;
        console.log('WIDPART2');
      }
     }
     else if(String(this.primarypartgridforpost_ee.ngpp_part_w).includes('.'))
    {
        if(String(pripart_w[0]).length<0 || String(pripart_w[0]).length>3)
        {
          this.pripartwidInvalid_ee=true;
          this.pripartwidInvalidReason_ee='Should be of between 0 to 999.9999';
        }
        else if(this.primarypartgridforpost_ee.ngpp_part_w<=0 || this.primarypartgridforpost_ee.ngpp_part_w>999.9999)
        {
          this.pripartwidInvalid_ee=true;
          this.pripartwidInvalidReason_ee='Should be between 0 to 999.9999';
        }
        else if(String(pripart_w[1]).length<0 || String(pripart_w[1]).length>4)
        {
          this.pripartwidInvalid_ee=true;
          this.pripartwidInvalidReason_ee='Max Length Of decimal values can be 4';
        }
        else
        {
          this.pripartwidInvalid_ee = false;
          this.pripartwidInvalidReason_ee = null;
        }
    }
    else
    {
      this.pripartwidInvalid_ee = false;
      this.pripartwidInvalidReason_ee = null;
    }
    }
    priparthgtValidation_ee()
    {
      const pripart_h = String(this.primarypartgridforpost_ee.ngpp_part_h).split('.');
      if(!String(this.primarypartgridforpost_ee.ngpp_part_h).includes('.'))
      {
      if(this.primarypartgridforpost_ee.ngpp_part_h==0 || this.primarypartgridforpost_ee.ngpp_part_h==null)
      {
        this.priparthgtInvalid_ee = true;
        this.priparthgtInvalidReason_ee = 'Required';
        console.log('HGTPART');
      }
      else if(this.primarypartgridforpost_ee.ngpp_part_h<=0 || this.primarypartgridforpost_ee.ngpp_part_h>999.9999)
      {
        this.priparthgtInvalid_ee = true;
        this.priparthgtInvalidReason_ee = 'Should be between 0 to 999.9999';
        console.log('HGTPART');
      }
      else if( String(this.primarypartgridforpost_ee.ngpp_part_h).length>8)
      {
        this.priparthgtInvalid_ee = true;
        this.priparthgtInvalidReason_ee = 'Max Length Of Characters can be 8';
      }
      else
      {
        this.priparthgtInvalid_ee = false;
        this.priparthgtInvalidReason_ee = null;
        console.log('HGTPART2');
      }
    }
    else if(String(this.primarypartgridforpost_ee.ngpp_part_h).includes('.'))
    {
        if(String(pripart_h[0]).length<0 || String(pripart_h[0]).length>3)
        {
          this.priparthgtInvalid_ee=true;
          this.priparthgtInvalidReason_ee='Should be between 0 to 999.9999';
        }
        else if(this.primarypartgridforpost_ee.ngpp_part_h<=0 || this.primarypartgridforpost_ee.ngpp_part_h>999.9999)
        {
          this.priparthgtInvalid_ee=true;
          this.priparthgtInvalidReason_ee='Should be between 0 to 999.9999';
        }
        else if(String(pripart_h[1]).length<0 || String(pripart_h[1]).length>4)
        {
          this.priparthgtInvalid_ee=true;
          this.priparthgtInvalidReason_ee='Max Length Of decimal values can be 4';
        }
        else
        {
          this.priparthgtInvalid_ee = false;
          this.priparthgtInvalidReason_ee = null;
        }
    }
    else
    {
      this.priparthgtInvalid_ee = false;
      this.priparthgtInvalidReason_ee = null;
    }
     }
     pripartwgtValidation_ee()
     {
      const pripart_wgt = String(this.primarypartgridforpost_ee.ngpp_part_wt).split('.');
      if(!String(this.primarypartgridforpost_ee.ngpp_part_wt).includes('.'))
      {
      if(this.primarypartgridforpost_ee.ngpp_part_wt==0 || this.primarypartgridforpost_ee.ngpp_part_wt==null)
      {
        this.pripartwgtInvalid_ee = true;
        this.pripartwgtInvalidReason_ee = 'Required';
        console.log('WGTPART');
      }
      else if(this.primarypartgridforpost_ee.ngpp_part_wt<=0 || this.primarypartgridforpost_ee.ngpp_part_wt>9999.99999)
      {
        this.pripartwgtInvalid_ee = true;
        this.pripartwgtInvalidReason_ee = 'Should be between 0 to 9999.99999';
      }
      else if( String(this.primarypartgridforpost_ee.ngpp_part_wt).length>11)
      {
        this.pripartwgtInvalid_ee = true;
        this.pripartwgtInvalidReason_ee = 'Max Length Of Characters can be 10';
      }
      else
      {
        this.pripartwgtInvalid_ee = false;
        this.pripartwgtInvalidReason_ee = null;
      }
     }
     else if(String(this.primarypartgridforpost_ee.ngpp_part_wt).includes('.'))
     {
         if(String(pripart_wgt[0]).length<0 || String(pripart_wgt[0]).length>4)
         {
           this.pripartwgtInvalid_ee=true;
           this.pripartwgtInvalidReason_ee='Should be between 0 to 9999.99999';
         }
         else if(this.primarypartgridforpost_ee.ngpp_part_wt<=0 || this.primarypartgridforpost_ee.ngpp_part_wt>9999.99999)
         {
           this.pripartwgtInvalid_ee=true;
           this.pripartwgtInvalidReason_ee='Should be between 0 to 9999.99999';
         }
         else if(String(pripart_wgt[1]).length<0 || String(pripart_wgt[1]).length>5)
         {
           this.pripartwgtInvalid_ee=true;
           this.pripartwgtInvalidReason_ee='Max Length Of decimal values can be 5';
         }
         else
         {
           this.pripartwgtInvalid_ee = false;
           this.pripartwgtInvalidReason_ee = null;
         }
     }
     else
     {
       this.pripartwgtInvalid_ee = false;
       this.pripartwgtInvalid_ee = null;
     }
    }

// secondary parts validation
secpartlenValidation_ee(product: any)
{
  // for(var i =0;i<this.secondarypartgridforpost.length;i++)
  // {
  const secpart_l = String(product.ngpp_part_l).split('.');
      if(!String(product.ngpp_part_l).includes('.'))
      {
          if(product.ngpp_part_l==0 || product.ngpp_part_l==null)
        {

          product.ngpp_part_l_invalid = true;
          product.ngpp_part_l_invalid_reasn = 'Required';
          this.enablesave = false;

        }
        else if(product.ngpp_part_l<=0 || product.ngpp_part_l>999.9999)
        {
          product.ngpp_part_l_invalid = true;
          product.ngpp_part_l_invalid_reasn = 'Should be between 0 to 999.9999';
          this.enablesave = false;
        }
        else if( String(product.ngpp_part_l).length>8)
        {
          product.ngpp_part_l_invalid = true;
          product.ngpp_part_l_invalid_reasn = 'Max Length Of Characters can be 8';
          this.enablesave = false;
        }
        else
        {
          product.ngpp_part_l_invalid = false;
          product.ngpp_part_l_invalid_reasn = null;
          this.enablesave = true;
        }
      }
      else if(String(product.ngpp_part_l).includes('.'))
      {
          if(String(secpart_l[0]).length<0 || String(secpart_l[0]).length>3)
          {
            product.ngpp_part_l_invalid=true;
            product.ngpp_part_l_invalid_reasn ='Should be between 0 to 999.9999';
            this.enablesave = false;
          }
          else if(product.ngpp_part_l<=0 || product.ngpp_part_l>999.9999)
          {
            product.ngpp_part_l_invalid=true;
            product.ngpp_part_l_invalid_reasn ='Should be between 0 to 999.9999';
            this.enablesave = false;
          }
          else if(String(secpart_l[1]).length<0 || String(secpart_l[1]).length>4)
          {
            product.ngpp_part_l_invalid=true;
            product.ngpp_part_l_invalid_reasn ='Max Length Of decimal values can be 4';
            this.enablesave = false;
          }
          else
          {
            product.ngpp_part_l_invalid = false;
            product.ngpp_part_l_invalid_reasn  = null;
            this.enablesave = true;
          }
      }
      else
      {
        product.ngpp_part_l_invalid = false;
        product.ngpp_part_l_invalid_reasn = null;
        this.enablesave = true;
      }
  // }
}

secpartwidValidation_ee(product: any)
 {
  const secpart_w = String(product.ngpp_part_w).split('.');
  if(!String(product.ngpp_part_w).includes('.'))
  {
    if(product.ngpp_part_w == 0 || product.ngpp_part_w == null)
    {

      product.ngpp_part_w_invalid = true;
      product.ngpp_part_w_invalid_reasn = 'Required';
      this.enablesave = false;

    }
    else if(product.ngpp_part_w<=0 || product.ngpp_part_w>999.9999)
    {
      product.ngpp_part_w_invalid = true;
      product.ngpp_part_w_invalid_reasn = 'Should be between 0 to 999.9999';
      this.enablesave = false;
    }
    else if( String(product.ngpp_part_w).length>8)
    {
      product.ngpp_part_w_invalid = true;
      product.ngpp_part_w_invalid_reasn = 'Max Length Of Characters can be 8';
      this.enablesave = false;
    }
    else
    {
      product.ngpp_part_w_invalid = false;
      product.ngpp_part_w_invalid_reasn = null;
      this.enablesave = true;
    }
  }
  else if(String(product.ngpp_part_w).includes('.'))
  {
      if(String(secpart_w[0]).length<0 || String(secpart_w[0]).length>3)
      {
        product.ngpp_part_w_invalid=true;
        product.ngpp_part_w_invalid_reasn ='Should be between 0 to 999.9999';
        this.enablesave = false;
      }
      else if(product.ngpp_part_w<=0 || product.ngpp_part_w>999.9999)
      {
        product.ngpp_part_w_invalid=true;
        product.ngpp_part_w_invalid_reasn ='Should be between 0 to 999.9999';
        this.enablesave = false;
      }
      else if(String(secpart_w[1]).length<0 || String(secpart_w[1]).length>4)
      {
        product.ngpp_part_w_invalid=true;
        product.ngpp_part_w_invalid_reasn ='Max Length Of decimal values can be 4';
        this.enablesave = false;
      }
      else
      {
        product.ngpp_part_w_invalid = false;
        product.ngpp_part_w_invalid_reasn  = null;
        this.enablesave = true;
      }
  }
else
{
  product.ngpp_part_w_invalid = false;
  product.ngpp_part_w_invalid_reasn = null;
  this.enablesave = true;
}
}
secparthgtValidation_ee(product:any)
 {

  const secpart_h = String(product.ngpp_part_h).split('.');
  if(!String(product.ngpp_part_h).includes('.'))
  {
    if(product.ngpp_part_h == 0 || product.ngpp_part_h == null)
    {
      product.ngpp_part_h_invalid = true;
      product.ngpp_part_h_invalid_reasn = 'Required';
      this.enablesave = false;
    }
    else if(product.ngpp_part_h<=0 || product.ngpp_part_h>999.9999)
    {
      product.ngpp_part_h_invalid = true;
      product.ngpp_part_h_invalid_reasn = 'Should be between 0 to 999.9999';
      this.enablesave = false;
    }
    else if( String(product.ngpp_part_h).length>8)
    {
      product.ngpp_part_h_invalid = true;
      product.ngpp_part_h_invalid_reasn = 'Max Length Of Characters can be 8';
      this.enablesave = false;
    }
    else
    {
      product.ngpp_part_h_invalid = false;
      product.ngpp_part_h_invalid_reasn = null;
      this.enablesave = true;
    }
  }
  else if(String(product.ngpp_part_h).includes('.'))
  {
      if(String(secpart_h[0]).length<0 || String(secpart_h[0]).length>3)
      {
        product.ngpp_part_h_invalid=true;
        product.ngpp_part_h_invalid_reasn ='Should be between 0 to 999.9999';
        this.enablesave = false;
      }
      else if(product.ngpp_part_h<=0 || product.ngpp_part_h>999.9999)
      {
        product.ngpp_part_h_invalid=true;
        product.ngpp_part_h_invalid_reasn ='Should be between 0 to 999.9999';
        this.enablesave = false;
      }
      else if(String(secpart_h[1]).length<0 || String(secpart_h[1]).length>4)
      {
        product.ngpp_part_h_invalid=true;
        product.ngpp_part_h_invalid_reasn ='Max Length Of decimal values can be 4';
        this.enablesave = false;
      }
      else
      {
        product.ngpp_part_h_invalid = false;
        product.ngpp_part_h_invalid_reasn  = null;
        this.enablesave = true;
      }
  }
else
{
  product.ngpp_part_h_invalid = false;
  product.ngpp_part_h_invalid_reasn = null;
  this.enablesave = true;
}
}
secpartwgtValidation_ee(product:any)
 {
  const secpart_wt = String(product.ngpp_part_wt).split('.');
      if(!String(product.ngpp_part_wt).includes('.'))
      {
        if(product.ngpp_part_wt == 0 || product.ngpp_part_wt == null)
        {

          product.ngpp_part_wt_invalid = true;
          product.ngpp_part_wt_invalid_reasn = 'Required';
          this.enablesave = false;

        }
        else if(product.ngpp_part_wt <=0 || product.ngpp_part_wt >9999.99999)
        {
          product.ngpp_part_wt_invalid = true;
          product.ngpp_part_wt_invalid_reasn = 'Should be between 0 to 9999.99999';
          this.enablesave = false;

        }
        else if( String(product.ngpp_part_wt).length>11)
        {
          product.ngpp_part_wt_invalid = true;
          product.ngpp_part_wt_invalid_reasn = 'Max Length Of Characters can be 10';
          this.enablesave = false;
        }
        else
        {
          product.ngpp_part_wt_invalid = false;
          product.ngpp_part_wt_invalid_reasn = null;
        }
      }
      else if(String(product.ngpp_part_wt).includes('.'))
      {
          if(String(secpart_wt[0]).length<0 || String(secpart_wt[0]).length>4)
          {
            product.ngpp_part_wt_invalid=true;
            product.ngpp_part_wt_invalid_reasn ='Should be between 0 to 9999.99999';
            this.enablesave = false;
          }
          else if(product.ngpp_part_wt<=0 || product.ngpp_part_wt>9999.99999)
          {
            product.ngpp_part_wt_invalid=true;
            product.ngpp_part_wt_invalid_reasn ='Should be between 0 to 9999.99999';
            this.enablesave = false;
          }
          else if(String(secpart_wt[1]).length<0 || String(secpart_wt[1]).length>5)
          {
            product.ngpp_part_wt_invalid=true;
            product.ngpp_part_wt_invalid_reasn ='Max Length Of decimal values can be 5';
            this.enablesave = false;
          }
          else
          {
            product.ngpp_part_wt_invalid = false;
            product.ngpp_part_wt_invalid_reasn  = null;
            this.enablesave = true;
          }
      }
    else
    {
      product.ngpp_part_wt_invalid = false;
      product.ngpp_part_wt_invalid_reasn = null;
      this.enablesave = true;
    }

 }





  saveThisAndEnableOtherForms_ee()
  {
    this.region_ee = this.transactionService.getregion();

    // this.primarypartsattributes.corr_protc=this.primarypartsattributes.corr_protc.prot;
    // console.log("this.primarypartsattributes.corr_protc",this.primarypartsattributes.corr_protc);
      if(this.selectedpart_submitter_ee.proposallastsaved_ee<1)
  {
  this.primarypartsattributes_ee.lst_saved_tab_ee=1;
  }

    this.primarypartsattributes_ee.submit_status='No';
    this.primarypartsattributes_ee.isstampblank=this.isStampingBlank_ee;
    this.primarypartsattributes_ee.corr_protc=this.selected_corrosion_protection_ee.corsn_prot_type ;
      this.primarypartsattributes_ee.propsl_type='Improvement'


    this.primarypartsattributes_ee.supgsdbcode=this.selected_supp_details_ee.supgsdbcode
    this.primarypartsattributes_ee.supfullname=this.selected_supp_details_ee.fullname;
    this.primarypartsattributes_ee.supaddress1=this.selected_supp_details_ee.address1;
    this.primarypartsattributes_ee.supcity=this.selected_supp_details_ee.city;
    this.primarypartsattributes_ee.supstateprov=this.selected_supp_details_ee.stateprovince;
    this.primarypartsattributes_ee.supcountrycode=this.selected_supp_details_ee.countrycode;
    this.primarypartsattributes_ee.supcontactnamefirst=this.selected_supp_details_ee.contactnamefirst;
    this.primarypartsattributes_ee.supcontactname=this.selected_supp_details_ee.contactname;
    this.primarypartsattributes_ee.supcontactfunctn=this.selected_supp_details_ee.contactfunctn;
    this.primarypartsattributes_ee.supcontactemail=this.selected_supp_details_ee.contactemail;
    this.primarypartsattributes_ee.supcontactphone=this.selected_supp_details_ee.contactphone;
    this.primarypartsattributes_ee.supcontactfax=this.selected_supp_details_ee.contactfax;
    this.primarypartsattributes_ee.defaultcurr=this.selected_supp_details_ee.defaultcurr;



    console.log('primarypart attributes',this.primarypartsattributes_ee);
    console.log('primaryparts',this.primarypartsgrid_ee);
    console.log('secondaryparts',this.secondarypartsgrid_ee);




    console.log(this.edit_measure_part_ee);

    if(this.unitom_ee == 'E')
    {
      this.edit_measure_part_ee = 'lbs/inch';
    }
    else{
      this.edit_measure_part_ee = 'kg/cm';
    }
    // if(this.edit_measure_part_ee=="kg/cm")
    //   {
    //     console.log(this.primarypartsgrid_ee);
    //     console.log(this.secondarypartsgrid_ee);


    //     if(this.primarypartsgrid_ee[0].ngpp_part_l!=null)
    //     {
    //       this.primarypartsgrid_ee[0].ngpp_part_l=Math.round((0.393701*this.primarypartsgrid_ee[0].ngpp_part_l)*1000)/1000;

    //     }
    //     if(this.primarypartsgrid_ee[0].ngpp_part_w!=null)
    //     {
    //       this.primarypartsgrid_ee[0].ngpp_part_w=Math.round((0.393701*this.primarypartsgrid_ee[0].ngpp_part_w)*1000)/1000;

    //     }
    //     if(this.primarypartsgrid_ee[0].ngpp_part_h!=null)
    //     {
    //       this.primarypartsgrid_ee[0].ngpp_part_h=Math.round((0.393701*this.primarypartsgrid_ee[0].ngpp_part_h)*1000)/1000;

    //     }
    //     if(this.primarypartsgrid_ee[0].ngpp_part_wt!=null)
    //     {
    //       this.primarypartsgrid_ee[0].ngpp_part_wt=Math.round((2.20462*this.primarypartsgrid_ee[0].ngpp_part_wt)*1000)/1000;

    //     }



    //     if(this.secondarypartsgrid_ee.length!=0)
    //     {


    //       for(var sec=0; sec<this.secondarypartsgrid_ee.length; sec++)
    //       {


    //         if(this.secondarypartsgrid_ee[sec].ngpp_part_l!=null)
    //         {
    //           this.secondarypartsgrid_ee[sec].ngpp_part_l=Math.round((0.393701*this.secondarypartsgrid_ee[sec].ngpp_part_l)*1000)/1000;

    //         }
    //         if(this.secondarypartsgrid_ee[sec].ngpp_part_w!=null)
    //         {
    //           this.secondarypartsgrid_ee[sec].ngpp_part_w=Math.round((0.393701*this.secondarypartsgrid_ee[sec].ngpp_part_w)*1000)/1000;

    //         }
    //         if(this.secondarypartsgrid_ee[sec].ngpp_part_h!=null)
    //         {
    //           this.secondarypartsgrid_ee[sec].ngpp_part_h=Math.round((0.393701*this.secondarypartsgrid_ee[sec].ngpp_part_h)*1000)/1000;

    //         }
    //         if(this.secondarypartsgrid_ee[sec].ngpp_part_wt!=null)
    //         {
    //           this.secondarypartsgrid_ee[sec].ngpp_part_wt=Math.round((2.20462*this.secondarypartsgrid_ee[sec].ngpp_part_wt)*1000)/1000;

    //         }


    //       }

    //     }

    //   }
this.primarypartsattributes_ee.timestamp=new Date().toISOString().split('.')[0];
this.originalTab1Details_ee.timestamp=this.primarypartsattributes_ee.timestamp;

    console.log('primarypart attributes',this.primarypartsattributes_ee);
    console.log('primaryparts',this.primarypartsgrid_ee);
    console.log('secondaryparts',this.secondarypartsgrid_ee);

    this.pripartlenValidation_ee();
  this.pripartwidValidation_ee();
  this.priparthgtValidation_ee();
  this.pripartwgtValidation_ee();
  this.corrosionProtectionValidation_ee();
  this.shelfLifeValidation_ee();

  // secondary parts validation
  this.enablesave = true;
    for(let i=0;i<this.secondarypartsgrid_ee.length;i++)
    {
      console.log('SECOPIP',this.secondarypartsgrid_ee[i]);

      this.secpartlenValidation_ee(this.secondarypartsgrid_ee[i]);
      this.secpartwidValidation_ee(this.secondarypartsgrid_ee[i]);
      this.secparthgtValidation_ee(this.secondarypartsgrid_ee[i]);
      this.secpartwgtValidation_ee(this.secondarypartsgrid_ee[i]);

      if(this.enablesave == true)
      {
        if(this.secondarypartsgrid_ee[i].ngpp_part_l_invalid || this.secondarypartsgrid_ee[i].ngpp_part_w_invalid
          || this.secondarypartsgrid_ee[i].ngpp_part_h_invalid || this.secondarypartsgrid_ee[i].ngpp_part_wt_invalid)
        {
          this.enablesave = false;
        }
      }

    //   if(this.secondarypartsgrid_ee[i].ngpp_part_l == 0 || this.secondarypartsgrid_ee[i].ngpp_part_l == null)
    // {
    //   // this.secondarypartgridforpost[i]=this.secondarypartsgrid[i];
    //   this.secondarypartsgrid_ee[i].ngpp_part_l_invalid = true;
    //   this.secondarypartsgrid_ee[i].ngpp_part_l_invalid_reasn = "Required";
    //   this.enablesave = false;
    // }
    // if(this.secondarypartsgrid_ee[i].ngpp_part_w == 0 || this.secondarypartsgrid_ee[i].ngpp_part_w == null)
    // {
    //   this.secondarypartsgrid_ee[i].ngpp_part_w_invalid = true;
    //   this.secondarypartsgrid_ee[i].ngpp_part_w_invalid_reasn = "Required";
    //   this.enablesave = false;
    // }
    // if(this.secondarypartsgrid_ee[i].ngpp_part_h == 0 || this.secondarypartsgrid_ee[i].ngpp_part_h == null)
    // {
    //   this.secondarypartsgrid_ee[i].ngpp_part_h_invalid = true;
    //   this.secondarypartsgrid_ee[i].ngpp_part_h_invalid_reasn = "Required";
    //   this.enablesave = false;
    // }
    // if(this.secondarypartsgrid_ee[i].ngpp_part_wt == 0 || this.secondarypartsgrid_ee[i].ngpp_part_wt == null)
    // {
    //   this.secondarypartsgrid_ee[i].ngpp_part_wt_invalid = true;
    //   this.secondarypartsgrid_ee[i].ngpp_part_wt_invalid_reasn = "Required";
    //   this.enablesave = false;
    // }

    }

  if(!this.pripartlenInvalid_ee && !this.pripartwidInvalid_ee && !this.priparthgtInvalid_ee && !this.pripartwgtInvalid_ee && this.enablesave && !this.corrosionProtectionInvalid_ee && !this.shelfLifeInvalid_ee)
    {
      // secondary parts validation
      this.secondarypartgridforpostAPI_ee=[];
      if(this.secondarypartsgrid_ee.length != 0)
      {
        for(let i=0;i<this.secondarypartsgrid_ee.length;i++)
        {
          console.log('SECHECK',this.secondarypartsgrid_ee)
        this.secondarypartgridforpostAPI_ee.push({
            bompartmasterid: this.secondarypartsgrid_ee[i].bompartmasterid,
            buyercode: this.secondarypartsgrid_ee[i].buyercode,
            id_proposal: this.secondarypartsgrid_ee[i].id_proposal,
          plantgsdbcode: this.secondarypartsgrid_ee[i].plantgsdbcode,
            supplierid: this.secondarypartsgrid_ee[i].supplierid,
              cd_region: this.secondarypartsgrid_ee[i].cd_region,
            partsubmitergsdbcodeid: this.secondarypartsgrid_ee[i].partsubmitergsdbcodeid,
            partintrobomuploadbatchid:this.secondarypartsgrid_ee[i].partintrobomuploadbatchid,
            lastseenonbomuploadbatchid:this.secondarypartsgrid_ee[i].lastseenonbomuploadbatchid,
            partintrodate: this.secondarypartsgrid_ee[i].partintrodate,
            plantname: this.secondarypartsgrid_ee[i].plantname,
            programname: this.secondarypartsgrid_ee[i].programname,
            partprefix: this.secondarypartsgrid_ee[i].partprefix,
            partbase: this.secondarypartsgrid_ee[i].partbase,
            partsuffix:this.secondarypartsgrid_ee[i].partsuffix,
            partcontrol: this.secondarypartsgrid_ee[i].partcontrol,
            cpsccode: this.secondarypartsgrid_ee[i].cpsccode,
            partdesc: this.secondarypartsgrid_ee[i].partdesc,
            partstatus: this.secondarypartsgrid_ee[i].partstatus,
            parttype: this.secondarypartsgrid_ee[i].parttype,
            controlitem: this.secondarypartsgrid_ee[i].controlitem,
            commonalitycode: this.secondarypartsgrid_ee[i].commonalitycode,
            partaddedfrom: this.secondarypartsgrid_ee[i].partaddedfrom,
            form1271requested: this.secondarypartsgrid_ee[i].form1271requested,
            cntrsuggestedownershpstrat: this.secondarypartsgrid_ee[i].cntrsuggestedownershpstrat,
            offsitesupplierid: this.secondarypartsgrid_ee[i].offsitesupplierid,
            containerdesignsourceid: this.secondarypartsgrid_ee[i].containerdesignsourceid,
            transtimeindays: this.secondarypartsgrid_ee[i].transtimeindays,
            parteffectiveindate: this.secondarypartsgrid_ee[i].parteffectiveindate,
            s4pincode: this.secondarypartsgrid_ee[i].s4pincode,
            parteffectiveoutdate: this.secondarypartsgrid_ee[i].parteffectiveoutdate,
            s4poutcode: this.secondarypartsgrid_ee[i].s4poutcode,
            carlinecode: this.secondarypartsgrid_ee[i].carlinecode,
            ptotype: this.secondarypartsgrid_ee[i].ptotype,
            cmmspackagingconfirmcode: this.secondarypartsgrid_ee[i].cmmspackagingconfirmcode,
            cmmscontainerbase: this.secondarypartsgrid_ee[i].cmmscontainerbase,
            cmmscontainersuffix: this.secondarypartsgrid_ee[i].cmmscontainersuffix,
            cmmscomponent1base: this.secondarypartsgrid_ee[i].cmmscomponent1base,
            cmmscomponent1suffix: this.secondarypartsgrid_ee[i].cmmscomponent1suffix,
            cmmscomponent2base: this.secondarypartsgrid_ee[i].cmmscomponent2base,
            cmmscomponent2suffix: this.secondarypartsgrid_ee[i].cmmscomponent2suffix,
            cmmscontainerpieses: this.secondarypartsgrid_ee[i].cmmscontainerpieses,
            cmmscontainerextl: this.secondarypartsgrid_ee[i].cmmscontainerextl,
            cmmscontainerextw: this.secondarypartsgrid_ee[i].cmmscontainerextw,
            cmmscontainerexth: this.secondarypartsgrid_ee[i].cmmscontainerexth,
            cmmscontainergrosswt:this.secondarypartsgrid_ee[i].cmmscontainergrosswt,
            cmmscontainersperlayer: this.secondarypartsgrid_ee[i].cmmscontainersperlayer,
            cmmslayerspershipunit: this.secondarypartsgrid_ee[i].cmmslayerspershipunit,
            cmmsshipunitnumpieses: this.secondarypartsgrid_ee[i].cmmsshipunitnumpieses,
            cmmsshipunitextl: this.secondarypartsgrid_ee[i].cmmsshipunitextl,
            cmmsshipunitextw: this.secondarypartsgrid_ee[i].cmmsshipunitextw,
            cmmsshipunitexth: this.secondarypartsgrid_ee[i].cmmsshipunitexth,
            cmmsshipunitgrosswt: this.secondarypartsgrid_ee[i].cmmsshipunitgrosswt,
            cmmspackagingreleaselevel: this.secondarypartsgrid_ee[i].cmmspackagingreleaselevel,
            partnumbercommonized: this.secondarypartsgrid_ee[i].partnumbercommonized,
            tradingcogsdbcode: this.secondarypartsgrid_ee[i].tradingcogsdbcode,
            ismissingpart: this.secondarypartsgrid_ee[i].ismissingpart,
            suppliercountrycodeiso: this.secondarypartsgrid_ee[i].suppliercountrycodeiso,
            suppliergsdbcode: this.secondarypartsgrid_ee[i].suppliergsdbcode,
            isprimary: this.secondarypartsgrid_ee[i].isprimary,
            selorder: this.secondarypartsgrid_ee[i].selorder,
            txntimestamp: this.secondarypartsgrid_ee[i].txntimestamp,
            ngpp_part_l: this.secondarypartsgrid_ee[i].ngpp_part_l,
            ngpp_part_w: this.secondarypartsgrid_ee[i].ngpp_part_w,
            ngpp_part_h: this.secondarypartsgrid_ee[i].ngpp_part_h,
            ngpp_part_wt: this.secondarypartsgrid_ee[i].ngpp_part_wt,
            ngpp_part_wt_status: this.secondarypartsgrid_ee[i].ngpp_part_wt_status,
            ngpp_ref_part_no: this.secondarypartsgrid_ee[i].ngpp_ref_part_no,
            ngpp_manf_loc: this.secondarypartsgrid_ee[i].ngpp_manf_loc,
            partid: this.secondarypartsgrid_ee[i].partid
          })
        }
      }
      console.log('secondarypartgridforpostAPI',this.secondarypartgridforpostAPI_ee)


      this.transactionService.savePrimaryPartDetails(this.primarypartsattributes_ee).subscribe(saveattributes=>
      {
        const op=saveattributes;
        console.log('successful',op);
        if(saveattributes=='Success')
        {
          console.log('primarypart attributes in save',this.primarypartsattributes_ee);
          // this.packagingproposal.addtoast({severity:'success', summary:'Success', detail:'primary part and attributes Added Successfully'});

          this.transactionService.savePartDetails(this.primarypartsgrid_ee).subscribe(getprimary=>
            {
              let check=getprimary;
              if(check='Success')
              {
                console.log('primarypart  in save',this.primarypartsgrid_ee);
                // this.packagingproposal.addtoast({severity:'success', summary:'Success', detail:'primary part  Added Successfully'});

                this.transactionService.savePartDetails(this.secondarypartgridforpostAPI_ee).subscribe(saveattributes=>
                  {
                    const op=saveattributes;
                    console.log('successful',op);
                    if(saveattributes=='Success')
                    {
                      console.log('secondary attributes in save',this.secondarypartgridforpostAPI_ee);
                      const currentab=1;
                      if(this.selectedpart_submitter_ee.proposallastsaved_ee<currentab)
                      {
                      this.selectedpart_submitter_ee.proposallastsaved_ee=1;
                      this.selectedpart_submitter_ee.imp_part_status='UnSubmitted'
                      this.store_primary_part_exist()
                      }



                      // this.existChangeStatusTab1_ee != null && this.existChangeStatusTab1_ee.toLowerCase()=="unchanged" &&
                      if(this.existChangeStatusTab1_ee != null && this.existChangeStatusTab1_ee.toLowerCase()=='unchanged' && this.changeStatus_ee==false)
                      {
                        console.log('original tab1 details_ee',this.originalTab1Details_ee)
                          console.log('chnaged tab1 details_ee',this.changedTab1Details_ee);

                        if(JSON.stringify(this.originalTab1Details_ee)==JSON.stringify(this.changedTab1Details_ee))
                        {


                          if(this.originalTab1Primarygrid_ee.length!=0 && this.changedTab1Primarygrid_ee.length!=0 && this.originalTab1Primarygrid_ee.length==this.changedTab1Primarygrid_ee.length && JSON.stringify(this.originalTab1Primarygrid_ee[0])==JSON.stringify(this.changedTab1Primarygrid_ee[0]))
                          {

                            if(this.originalTab1Secondarygrid_ee.length!=0 && this.changedTab1Secondarygrid_ee.length!=0 && this.originalTab1Secondarygrid_ee.length == this.changedTab1Secondarygrid_ee.length)
                            {
                              let chnagePresent_ee=false;
                              for(let i=0;i<this.originalTab1Secondarygrid_ee.length;i++)
                              {

                                  if(JSON.stringify(this.originalTab1Secondarygrid_ee[i])!==JSON.stringify(this.changedTab1Secondarygrid_ee[i]))
                                  {
                                    chnagePresent_ee=true;
                                    // alert("secondary grid details changed");
                                    this.updateChangeApi_ee();
                                    break;
                                  }


                              }
                              if(!chnagePresent_ee)
                              {
                                // alert("no chnge happened");
                                this.packagingproposal.addtoast({severity:'success', summary:'success', detail:'Parts and Attributes Saved Successfully'});
                                this.navigatetonexttab_ee();
                              }
                            }
                            else
                            {

                              if(this.originalTab1Secondarygrid_ee.length==0 && this.changedTab1Secondarygrid_ee.length==0)
                              {
                               // alert("no chnge happened");
                                this.packagingproposal.addtoast({severity:'success', summary:'success', detail:'Parts and Attributes Saved Successfully'});
                                this.navigatetonexttab_ee();
                              }
                              else
                              {
                               // alert("secondary grid count changed")
                                this.updateChangeApi_ee();
                              }

                            }


                          }
                          else
                          {
                            // alert("primary grid details chnaged");
                            this.updateChangeApi_ee();
                          }
                        }
                        else
                        {
                          // alert("primary details chnaged");
                          this.updateChangeApi_ee();
                        }


                      }
                      else
                      {
                        // alert("no chnages made")
                        this.packagingproposal.addtoast({severity:'success', summary:'success', detail:'Parts and Attributes Saved Successfully'});
                        this.navigatetonexttab_ee();
                      }





                    }
                    else
                    {
                      this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Additional Parts Not Added,Please Try Again'});

                      if(this.unitom_ee == 'E')
                      {
                        this.edit_measure_part_ee = 'lbs/inch';
                      }
                      else{
                        this.edit_measure_part_ee = 'kg/cm';
                      }
                      // if(this.edit_measure_part_ee=="kg/cm")
                      // {


                      //   console.log(this.primarypartsgrid_ee);
                      //   console.log(this.secondarypartsgrid_ee);


                      //   if(this.primarypartsgrid_ee[0].ngpp_part_l!=null)
                      //   {
                      //     this.primarypartsgrid_ee[0].ngpp_part_l=Math.round((2.54*this.primarypartsgrid_ee[0].ngpp_part_l)*1000)/1000;

                      //   }
                      //   if(this.primarypartsgrid_ee[0].ngpp_part_w!=null)
                      //   {
                      //     this.primarypartsgrid_ee[0].ngpp_part_w=Math.round((2.54*this.primarypartsgrid_ee[0].ngpp_part_w)*1000)/1000;

                      //   }
                      //   if(this.primarypartsgrid_ee[0].ngpp_part_h!=null)
                      //   {
                      //     this.primarypartsgrid_ee[0].ngpp_part_h=Math.round((2.54*this.primarypartsgrid_ee[0].ngpp_part_h)*1000)/1000;

                      //   }
                      //   if(this.primarypartsgrid_ee[0].ngpp_part_wt!=null)
                      //   {
                      //     this.primarypartsgrid_ee[0].ngpp_part_wt=Math.round((0.453592*this.primarypartsgrid_ee[0].ngpp_part_wt)*1000)/1000;

                      //   }



                      //   if(this.secondarypartsgrid_ee.length!=0)
                      //   {


                      //     for(var sec=0; sec<this.secondarypartsgrid_ee.length; sec++)
                      //     {


                      //       if(this.secondarypartsgrid_ee[sec].ngpp_part_l!=null)
                      //       {
                      //         this.secondarypartsgrid_ee[sec].ngpp_part_l=Math.round((2.54*this.secondarypartsgrid_ee[sec].ngpp_part_l)*1000)/1000;

                      //       }
                      //       if(this.secondarypartsgrid_ee[sec].ngpp_part_w!=null)
                      //       {
                      //         this.secondarypartsgrid_ee[sec].ngpp_part_w=Math.round((2.54*this.secondarypartsgrid_ee[sec].ngpp_part_w)*1000)/1000;

                      //       }
                      //       if(this.secondarypartsgrid_ee[sec].ngpp_part_h!=null)
                      //       {
                      //         this.secondarypartsgrid_ee[sec].ngpp_part_h=Math.round((2.54*this.secondarypartsgrid_ee[sec].ngpp_part_h)*1000)/1000;

                      //       }
                      //       if(this.secondarypartsgrid_ee[sec].ngpp_part_wt!=null)
                      //       {
                      //         this.secondarypartsgrid_ee[sec].ngpp_part_wt=Math.round((0.453592*this.secondarypartsgrid_ee[sec].ngpp_part_wt)*1000)/1000;

                      //       }


                      //     }

                      //   }


                      // }
                    }

                });
              }
              else
              {
                this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Primary Part  Not Added,Please Try Again'})

                if(this.unitom_ee == 'E')
                {
                  this.edit_measure_part_ee = 'lbs/inch';
                }
                else{
                  this.edit_measure_part_ee = 'kg/cm';
                }

                // if(this.edit_measure_part_ee=="kg/cm")
                // {


                //   console.log(this.primarypartsgrid_ee);
                //   console.log(this.secondarypartsgrid_ee);


                //   if(this.primarypartsgrid_ee[0].ngpp_part_l!=null)
                //   {
                //     this.primarypartsgrid_ee[0].ngpp_part_l=Math.round((2.54*this.primarypartsgrid_ee[0].ngpp_part_l)*1000)/1000;

                //   }
                //   if(this.primarypartsgrid_ee[0].ngpp_part_w!=null)
                //   {
                //     this.primarypartsgrid_ee[0].ngpp_part_w=Math.round((2.54*this.primarypartsgrid_ee[0].ngpp_part_w)*1000)/1000;

                //   }
                //   if(this.primarypartsgrid_ee[0].ngpp_part_h!=null)
                //   {
                //     this.primarypartsgrid_ee[0].ngpp_part_h=Math.round((2.54*this.primarypartsgrid_ee[0].ngpp_part_h)*1000)/1000;

                //   }
                //   if(this.primarypartsgrid_ee[0].ngpp_part_wt!=null)
                //   {
                //     this.primarypartsgrid_ee[0].ngpp_part_wt=Math.round((0.453592*this.primarypartsgrid_ee[0].ngpp_part_wt)*1000)/1000;

                //   }



                //   if(this.secondarypartsgrid_ee.length!=0)
                //   {


                //     for(var sec=0; sec<this.secondarypartsgrid_ee.length; sec++)
                //     {


                //       if(this.secondarypartsgrid_ee[sec].ngpp_part_l!=null)
                //       {
                //         this.secondarypartsgrid_ee[sec].ngpp_part_l=Math.round((2.54*this.secondarypartsgrid_ee[sec].ngpp_part_l)*1000)/1000;

                //       }
                //       if(this.secondarypartsgrid_ee[sec].ngpp_part_w!=null)
                //       {
                //         this.secondarypartsgrid_ee[sec].ngpp_part_w=Math.round((2.54*this.secondarypartsgrid_ee[sec].ngpp_part_w)*1000)/1000;

                //       }
                //       if(this.secondarypartsgrid_ee[sec].ngpp_part_h!=null)
                //       {
                //         this.secondarypartsgrid_ee[sec].ngpp_part_h=Math.round((2.54*this.secondarypartsgrid_ee[sec].ngpp_part_h)*1000)/1000;

                //       }
                //       if(this.secondarypartsgrid_ee[sec].ngpp_part_wt!=null)
                //       {
                //         this.secondarypartsgrid_ee[sec].ngpp_part_wt=Math.round((0.453592*this.secondarypartsgrid_ee[sec].ngpp_part_wt)*1000)/1000;

                //       }


                //     }

                //   }


                // }
              }
            });
        }
        else{
          this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Primary Part Attributes Not Added,Please Try Again'})
        }
      });


}
}
updateChangeApi_ee()
{
  // alert("chnage data");
                        const setChangestatusTab2:changeTrackingForImprovement={idproposal:0,chnge_timestamp:'',status:''}
                        setChangestatusTab2.idproposal=this.selectedpart_submitter_ee.proposalid
                        setChangestatusTab2.status='changed';
                        const cur_date=new Date();
                        const cur_date_transform=this.date.transform(cur_date, 'yyyy-MM-dd HH:mm:ss');
                        setChangestatusTab2.chnge_timestamp=cur_date_transform
                        console.log('update change status ',setChangestatusTab2)
                        this.transactionService.setImprovementChangeStatus(setChangestatusTab2).subscribe(chang_stat=>{
                          console.log('change status update',chang_stat);
                          if(chang_stat.toLowerCase() == 'success')
                          {

                            sessionStorage.setItem('ProposalChangeStatus',setChangestatusTab2.status);
                            this.existChangeStatusTab1_ee='changed';
                            this.packagingproposal.addtoast({severity:'success', summary:'success', detail:'Parts and Attributes Saved Successfully'});
                            this.navigatetonexttab_ee();

                          }
                        })
}

navigatetonexttab_ee()
{
   /* code added for packaging proposal menu creation */

   this.name_ee=this.transactionService.getregionNameInSmallLetters();
   this.tab2_ee='/'+this.name_ee+'/packagingprocessdashboard/improvementproposalcreation/improvementeepackproposaltabtwo';
   const currentab=1;
   if(currentab>this.packagingproposal.savedtab)
   {
     this.packagingproposal.savedtab=currentab;
     console.log('saved tab set as 1');
     // this.transactionService.storelastsavedtab(this.packagingproposal.savedtab,this.region);
     // this.transactionService.storepage(this.region,'packagingprocess/proposalcreation/partsandattri');
   }

   this.tab2menuitem_ee={label: 'EE-COS, Container & Shipping Unit', routerLink: this.tab2_ee, icon: 'fas fa-cubes'};
   for(const i of this.packagingproposal.tabpackagingproposalmenuitems.values())
   {
       console.log(i);
       if(i.label!='EE-COS, Container & Shipping Unit')
       {
           this.flag_ee=true;
       }
       else{
           this.flag_ee=false;
           break;
       }
   }

   if(this.flag_ee)
   {
     this.packagingproposal.tabpackagingproposalmenuitems.push(this.tab2menuitem_ee);
   }

   this.packagingproposal.setActiveItemDynamically(this.tab2_ee,this.tab2menuitem_ee);



  /* code added for packaging proposal menu creation ends */

  }
updateChangeApiForSaveAndClose_ee()
{
  // alert("chnage data");
  const setChangestatusTab2:changeTrackingForImprovement={idproposal:0,chnge_timestamp:'',status:''}
  setChangestatusTab2.idproposal=this.selectedpart_submitter_ee.proposalid
  setChangestatusTab2.status='changed';
  const cur_date=new Date();
  const cur_date_transform=this.date.transform(cur_date, 'yyyy-MM-dd HH:mm:ss');
  setChangestatusTab2.chnge_timestamp=cur_date_transform
  console.log('update change status ',setChangestatusTab2)
  this.transactionService.setImprovementChangeStatus(setChangestatusTab2).subscribe(chang_stat=>{
    console.log('change status update',chang_stat);
    if(chang_stat.toLowerCase() == 'success')
    {

      sessionStorage.setItem('ProposalChangeStatus',setChangestatusTab2.status);
      this.existChangeStatusTab1_ee='changed';

      this.packagingprocess.addtoastinpackagingprocess({severity:'success', summary:'success', detail:'Tab1 Saved Successfully'});

      this.router.navigate(['/'+this.name_ee+'/packagingprocessdashboard/searchexistingproposal'])

    }
  })
}

  SaveTab_ee()
  {
  this.region_ee = this.transactionService.getregion();
  this.name_ee=this.transactionService.getregionNameInSmallLetters();

if(this.selectedpart_submitter_ee.proposallastsaved_ee<1)
  {
    this.primarypartsattributes_ee.lst_saved_tab_ee=1;
}
    this.primarypartsattributes_ee.submit_status='No';
    this.primarypartsattributes_ee.isstampblank=this.isStampingBlank_ee;
    this.primarypartsattributes_ee.timestamp=new Date().toISOString().split('.')[0];
    this.primarypartsattributes_ee.corr_protc=this.selected_corrosion_protection_ee.corsn_prot_type;
    this.primarypartsattributes_ee.propsl_type='Improvement'


    this.primarypartsattributes_ee.supgsdbcode=this.selected_supp_details_ee.supgsdbcode
    this.primarypartsattributes_ee.supfullname=this.selected_supp_details_ee.fullname;
    this.primarypartsattributes_ee.supaddress1=this.selected_supp_details_ee.address1;
    this.primarypartsattributes_ee.supcity=this.selected_supp_details_ee.city;
    this.primarypartsattributes_ee.supstateprov=this.selected_supp_details_ee.stateprovince;
    this.primarypartsattributes_ee.supcountrycode=this.selected_supp_details_ee.countrycode;
    this.primarypartsattributes_ee.supcontactnamefirst=this.selected_supp_details_ee.contactnamefirst;
    this.primarypartsattributes_ee.supcontactname=this.selected_supp_details_ee.contactname;
    this.primarypartsattributes_ee.supcontactfunctn=this.selected_supp_details_ee.contactfunctn;
    this.primarypartsattributes_ee.supcontactemail=this.selected_supp_details_ee.contactemail;
    this.primarypartsattributes_ee.supcontactphone=this.selected_supp_details_ee.contactphone;
    this.primarypartsattributes_ee.supcontactfax=this.selected_supp_details_ee.contactfax;
    this.primarypartsattributes_ee.defaultcurr=this.selected_supp_details_ee.defaultcurr;


    console.log(this.edit_measure_part_ee);

    if(this.unitom_ee == 'E')
    {
      this.edit_measure_part_ee = 'lbs/inch';
    }
    else{
      this.edit_measure_part_ee = 'kg/cm';
    }

    // if(this.edit_measure_part_ee=="kg/cm")
    //   {
    //     console.log(this.primarypartsgrid_ee);
    //     console.log(this.secondarypartsgrid_ee);

    //     if(this.primarypartsgrid_ee[0].ngpp_part_l!=null)
    //     {
    //       this.primarypartsgrid_ee[0].ngpp_part_l=Math.round((0.393701*this.primarypartsgrid_ee[0].ngpp_part_l)*1000)/1000;

    //     }
    //     if(this.primarypartsgrid_ee[0].ngpp_part_w!=null)
    //     {
    //       this.primarypartsgrid_ee[0].ngpp_part_w=Math.round((0.393701*this.primarypartsgrid_ee[0].ngpp_part_w)*1000)/1000;

    //     }
    //     if(this.primarypartsgrid_ee[0].ngpp_part_h!=null)
    //     {
    //       this.primarypartsgrid_ee[0].ngpp_part_h=Math.round((0.393701*this.primarypartsgrid_ee[0].ngpp_part_h)*1000)/1000;

    //     }
    //     if(this.primarypartsgrid_ee[0].ngpp_part_wt!=null)
    //     {
    //       this.primarypartsgrid_ee[0].ngpp_part_wt=Math.round((2.20462*this.primarypartsgrid_ee[0].ngpp_part_wt)*1000)/1000;

    //     }

    //     if(this.secondarypartsgrid_ee.length!=0)
    //     {
    //       for(var sec=0; sec<this.secondarypartsgrid_ee.length; sec++)
    //       {
    //         if(this.secondarypartsgrid_ee[sec].ngpp_part_l!=null)
    //         {
    //           this.secondarypartsgrid_ee[sec].ngpp_part_l=Math.round((0.393701*this.secondarypartsgrid_ee[sec].ngpp_part_l)*1000)/1000;
    //         }
    //         if(this.secondarypartsgrid_ee[sec].ngpp_part_w!=null)
    //         {
    //           this.secondarypartsgrid_ee[sec].ngpp_part_w=Math.round((0.393701*this.secondarypartsgrid_ee[sec].ngpp_part_w)*1000)/1000;
    //         }
    //         if(this.secondarypartsgrid_ee[sec].ngpp_part_h!=null)
    //         {
    //           this.secondarypartsgrid_ee[sec].ngpp_part_h=Math.round((0.393701*this.secondarypartsgrid_ee[sec].ngpp_part_h)*1000)/1000;
    //         }
    //         if(this.secondarypartsgrid_ee[sec].ngpp_part_wt!=null)
    //         {
    //           this.secondarypartsgrid_ee[sec].ngpp_part_wt=Math.round((2.20462*this.secondarypartsgrid_ee[sec].ngpp_part_wt)*1000)/1000;
    //         }
    //       }
    //     }
    //   }
 this.originalTab1Details_ee.timestamp=this.primarypartsattributes_ee.timestamp;
    console.log('primarypart attributes',this.primarypartsattributes_ee);
    console.log('primaryparts',this.primarypartsgrid_ee);
    console.log('secondaryparts',this.secondarypartsgrid_ee);

    this.pripartlenValidation_ee();
  this.pripartwidValidation_ee();
  this.priparthgtValidation_ee();
  this.pripartwgtValidation_ee();
  this.corrosionProtectionValidation_ee();
  this.shelfLifeValidation_ee();

  this.onRowEditSave_ee(this.primarypartgridforpost_ee);


  // secondary parts validation
this.enablesave = true;
for(let i=0;i<this.secondarypartsgrid_ee.length;i++)
{
  console.log('SECOPIP',this.secondarypartsgrid_ee[i]);

  this.secpartlenValidation_ee(this.secondarypartsgrid_ee[i]);
  this.secpartwidValidation_ee(this.secondarypartsgrid_ee[i]);
  this.secparthgtValidation_ee(this.secondarypartsgrid_ee[i]);
  this.secpartwgtValidation_ee(this.secondarypartsgrid_ee[i]);

  if(this.enablesave == true)
  {
    if(this.secondarypartsgrid_ee[i].ngpp_part_l_invalid || this.secondarypartsgrid_ee[i].ngpp_part_w_invalid
      || this.secondarypartsgrid_ee[i].ngpp_part_h_invalid || this.secondarypartsgrid_ee[i].ngpp_part_wt_invalid)
    {
      this.enablesave = false;
    }
  }

//   if(this.secondarypartsgrid_ee[i].ngpp_part_l == 0 || this.secondarypartsgrid_ee[i].ngpp_part_l == null)
// {
//   this.secondarypartsgrid_ee[i].ngpp_part_l_invalid = true;
//   this.secondarypartsgrid_ee[i].ngpp_part_l_invalid_reasn = "Required";
//   this.enablesave = false;
// }
// if(this.secondarypartsgrid_ee[i].ngpp_part_w == 0 || this.secondarypartsgrid_ee[i].ngpp_part_w == null)
// {
//   this.secondarypartsgrid_ee[i].ngpp_part_w_invalid = true;
//   this.secondarypartsgrid_ee[i].ngpp_part_w_invalid_reasn = "Required";
//   this.enablesave = false;
// }
// if(this.secondarypartsgrid_ee[i].ngpp_part_h == 0 || this.secondarypartsgrid_ee[i].ngpp_part_h == null)
// {
//   this.secondarypartsgrid_ee[i].ngpp_part_h_invalid = true;
//   this.secondarypartsgrid_ee[i].ngpp_part_h_invalid_reasn = "Required";
//   this.enablesave = false;
// }
// if(this.secondarypartsgrid_ee[i].ngpp_part_wt == 0 || this.secondarypartsgrid_ee[i].ngpp_part_wt == null)
// {
//   this.secondarypartsgrid_ee[i].ngpp_part_wt_invalid = true;
//   this.secondarypartsgrid_ee[i].ngpp_part_wt_invalid_reasn = "Required";
//   this.enablesave = false;
// }

}

  if(!this.pripartlenInvalid_ee && !this.pripartwidInvalid_ee && !this.priparthgtInvalid_ee && !this.pripartwgtInvalid_ee && this.enablesave && !this.corrosionProtectionInvalid_ee && !this.shelfLifeInvalid_ee)
    {

        // secondary parts validation
    this.secondarypartgridforpostAPI_ee=[];
    if(this.secondarypartsgrid_ee.length != 0)
    {
      for(let i=0;i<this.secondarypartsgrid_ee.length;i++)
      {
        console.log('SECHECK',this.secondarypartsgrid_ee)
      this.secondarypartgridforpostAPI_ee.push({
          bompartmasterid: this.secondarypartsgrid_ee[i].bompartmasterid,
          buyercode: this.secondarypartsgrid_ee[i].buyercode,
          id_proposal: this.secondarypartsgrid_ee[i].id_proposal,
        plantgsdbcode: this.secondarypartsgrid_ee[i].plantgsdbcode,
          supplierid: this.secondarypartsgrid_ee[i].supplierid,
            cd_region: this.secondarypartsgrid_ee[i].cd_region,
          partsubmitergsdbcodeid: this.secondarypartsgrid_ee[i].partsubmitergsdbcodeid,
          partintrobomuploadbatchid:this.secondarypartsgrid_ee[i].partintrobomuploadbatchid,
          lastseenonbomuploadbatchid:this.secondarypartsgrid_ee[i].lastseenonbomuploadbatchid,
          partintrodate: this.secondarypartsgrid_ee[i].partintrodate,
          plantname: this.secondarypartsgrid_ee[i].plantname,
          programname: this.secondarypartsgrid_ee[i].programname,
          partprefix: this.secondarypartsgrid_ee[i].partprefix,
          partbase: this.secondarypartsgrid_ee[i].partbase,
          partsuffix:this.secondarypartsgrid_ee[i].partsuffix,
          partcontrol: this.secondarypartsgrid_ee[i].partcontrol,
          cpsccode: this.secondarypartsgrid_ee[i].cpsccode,
          partdesc: this.secondarypartsgrid_ee[i].partdesc,
          partstatus: this.secondarypartsgrid_ee[i].partstatus,
          parttype: this.secondarypartsgrid_ee[i].parttype,
          controlitem: this.secondarypartsgrid_ee[i].controlitem,
          commonalitycode: this.secondarypartsgrid_ee[i].commonalitycode,
          partaddedfrom: this.secondarypartsgrid_ee[i].partaddedfrom,
          form1271requested: this.secondarypartsgrid_ee[i].form1271requested,
          cntrsuggestedownershpstrat: this.secondarypartsgrid_ee[i].cntrsuggestedownershpstrat,
          offsitesupplierid: this.secondarypartsgrid_ee[i].offsitesupplierid,
          containerdesignsourceid: this.secondarypartsgrid_ee[i].containerdesignsourceid,
          transtimeindays: this.secondarypartsgrid_ee[i].transtimeindays,
          parteffectiveindate: this.secondarypartsgrid_ee[i].parteffectiveindate,
          s4pincode: this.secondarypartsgrid_ee[i].s4pincode,
          parteffectiveoutdate: this.secondarypartsgrid_ee[i].parteffectiveoutdate,
          s4poutcode: this.secondarypartsgrid_ee[i].s4poutcode,
          carlinecode: this.secondarypartsgrid_ee[i].carlinecode,
          ptotype: this.secondarypartsgrid_ee[i].ptotype,
          cmmspackagingconfirmcode: this.secondarypartsgrid_ee[i].cmmspackagingconfirmcode,
          cmmscontainerbase: this.secondarypartsgrid_ee[i].cmmscontainerbase,
          cmmscontainersuffix: this.secondarypartsgrid_ee[i].cmmscontainersuffix,
          cmmscomponent1base: this.secondarypartsgrid_ee[i].cmmscomponent1base,
          cmmscomponent1suffix: this.secondarypartsgrid_ee[i].cmmscomponent1suffix,
          cmmscomponent2base: this.secondarypartsgrid_ee[i].cmmscomponent2base,
          cmmscomponent2suffix: this.secondarypartsgrid_ee[i].cmmscomponent2suffix,
          cmmscontainerpieses: this.secondarypartsgrid_ee[i].cmmscontainerpieses,
          cmmscontainerextl: this.secondarypartsgrid_ee[i].cmmscontainerextl,
          cmmscontainerextw: this.secondarypartsgrid_ee[i].cmmscontainerextw,
          cmmscontainerexth: this.secondarypartsgrid_ee[i].cmmscontainerexth,
          cmmscontainergrosswt:this.secondarypartsgrid_ee[i].cmmscontainergrosswt,
          cmmscontainersperlayer: this.secondarypartsgrid_ee[i].cmmscontainersperlayer,
          cmmslayerspershipunit: this.secondarypartsgrid_ee[i].cmmslayerspershipunit,
          cmmsshipunitnumpieses: this.secondarypartsgrid_ee[i].cmmsshipunitnumpieses,
          cmmsshipunitextl: this.secondarypartsgrid_ee[i].cmmsshipunitextl,
          cmmsshipunitextw: this.secondarypartsgrid_ee[i].cmmsshipunitextw,
          cmmsshipunitexth: this.secondarypartsgrid_ee[i].cmmsshipunitexth,
          cmmsshipunitgrosswt: this.secondarypartsgrid_ee[i].cmmsshipunitgrosswt,
          cmmspackagingreleaselevel: this.secondarypartsgrid_ee[i].cmmspackagingreleaselevel,
          partnumbercommonized: this.secondarypartsgrid_ee[i].partnumbercommonized,
          tradingcogsdbcode: this.secondarypartsgrid_ee[i].tradingcogsdbcode,
          ismissingpart: this.secondarypartsgrid_ee[i].ismissingpart,
          suppliercountrycodeiso: this.secondarypartsgrid_ee[i].suppliercountrycodeiso,
          suppliergsdbcode: this.secondarypartsgrid_ee[i].suppliergsdbcode,
          isprimary: this.secondarypartsgrid_ee[i].isprimary,
          selorder: this.secondarypartsgrid_ee[i].selorder,
          txntimestamp: this.secondarypartsgrid_ee[i].txntimestamp,
          ngpp_part_l: this.secondarypartsgrid_ee[i].ngpp_part_l,
          ngpp_part_w: this.secondarypartsgrid_ee[i].ngpp_part_w,
          ngpp_part_h: this.secondarypartsgrid_ee[i].ngpp_part_h,
          ngpp_part_wt: this.secondarypartsgrid_ee[i].ngpp_part_wt,
          ngpp_part_wt_status: this.secondarypartsgrid_ee[i].ngpp_part_wt_status,
          ngpp_ref_part_no: this.secondarypartsgrid_ee[i].ngpp_ref_part_no,
          ngpp_manf_loc: this.secondarypartsgrid_ee[i].ngpp_manf_loc,
          partid: this.secondarypartsgrid_ee[i].partid
        })
      }
    }
 this.originalTab1Details_ee.timestamp=this.primarypartsattributes_ee.timestamp;
    console.log('primarypart attributes',this.primarypartsattributes_ee);

    this.transactionService.savePrimaryPartDetails(this.primarypartsattributes_ee).subscribe(saveandcloseattributes=>
      {
        const opsc=saveandcloseattributes;
        console.log('successful',opsc);
        if(saveandcloseattributes=='Success')
        {
          console.log('primarypart attributes in save',this.primarypartsattributes_ee);
          // this.packagingproposal.addtoast({severity:'success', summary:'Success', detail:'primary part and attributes Added Successfully'});

          this.transactionService.savePartDetails(this.primarypartsgrid_ee).subscribe(getprimaryinsaveclose=>
            {
              let checkinsaveclose=getprimaryinsaveclose;
              if(checkinsaveclose='Success')
              {
                console.log('primarypart  in save',this.primarypartsgrid_ee);
                // this.packagingproposal.addtoast({severity:'success', summary:'Success', detail:'primary part  Added Successfully'});

                this.transactionService.savePartDetails(this.secondarypartgridforpostAPI_ee).subscribe(saveattributesinsaveandclose=>
                  {
                    const opsavclos=saveattributesinsaveandclose;
                    console.log('successful',opsavclos);
                    if(saveattributesinsaveandclose=='Success')
                    {
                      console.log('secondary attributes in save',this.secondarypartgridforpostAPI_ee);
                      const currentab=1;
                      if(this.selectedpart_submitter_ee.proposallastsaved_ee<currentab)
                      {
                      this.selectedpart_submitter_ee.proposallastsaved_ee=1;
                      this.selectedpart_submitter_ee.imp_part_status='UnSubmitted'
                      this.store_primary_part_exist()
                      }

                      if(this.existChangeStatusTab1_ee != null && this.existChangeStatusTab1_ee.toLowerCase()=='unchanged' && this.changeStatus_ee==false)
                      {
                        console.log('original tab1 details_ee',this.originalTab1Details_ee)
                          console.log('chnaged tab1 details_ee',this.changedTab1Details_ee);

                        if(JSON.stringify(this.originalTab1Details_ee)==JSON.stringify(this.changedTab1Details_ee))
                        {


                          if(this.originalTab1Primarygrid_ee.length!=0 && this.changedTab1Primarygrid_ee.length!=0 && this.originalTab1Primarygrid_ee.length==this.changedTab1Primarygrid_ee.length && JSON.stringify(this.originalTab1Primarygrid_ee[0])==JSON.stringify(this.changedTab1Primarygrid_ee[0]))
                          {

                            if(this.originalTab1Secondarygrid_ee.length!=0 && this.changedTab1Secondarygrid_ee.length!=0 && this.originalTab1Secondarygrid_ee.length == this.changedTab1Secondarygrid_ee.length)
                            {
                              let chnagePresent_ee=false;
                              for(let i=0;i<this.originalTab1Secondarygrid_ee.length;i++)
                              {

                                  if(JSON.stringify(this.originalTab1Secondarygrid_ee[i])!==JSON.stringify(this.changedTab1Secondarygrid_ee[i]))
                                  {
                                    chnagePresent_ee=true;
                                    // alert("secondary grid details changed");
                                    this.updateChangeApiForSaveAndClose_ee();
                                    break;
                                  }


                              }
                              if(!chnagePresent_ee)
                              {
                                // alert("no chnge happened");
                                this.packagingprocess.addtoastinpackagingprocess({severity:'success', summary:'success', detail:'EE-Parts And Attributes Details Saved Successfully'});

                                this.router.navigate(['/'+this.name_ee+'/packagingprocessdashboard/searchexistingproposal'])

                              }
                            }
                            else
                            {

                              if(this.originalTab1Secondarygrid_ee.length==0 && this.changedTab1Secondarygrid_ee.length==0)
                              {
                               // alert("no chnge happened");
                               this.packagingprocess.addtoastinpackagingprocess({severity:'success', summary:'success', detail:'EE-Parts And Attributes Details Saved Successfully'});

                               this.router.navigate(['/'+this.name_ee+'/packagingprocessdashboard/searchexistingproposal'])

                              }
                              else
                              {
                               // alert("secondary grid count changed")
                                this.updateChangeApiForSaveAndClose_ee();
                              }

                            }


                          }
                          else
                          {
                            // alert("primary grid details chnaged");
                            this.updateChangeApiForSaveAndClose_ee();
                          }
                        }
                        else
                        {
                          // alert("primary details chnaged");
                          this.updateChangeApiForSaveAndClose_ee();
                        }


                      }

                      else
                      {
                        this.packagingprocess.addtoastinpackagingprocess({severity:'success', summary:'success', detail:'Tab1 Saved Successfully'});

                        this.router.navigate(['/'+this.name_ee+'/packagingprocessdashboard/searchexistingproposal'])

                      }


                    }
                    else
                    {
                      this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Additional Parts Not Added,Please Try Again'});

                      if(this.unitom_ee == 'E')
                      {
                        this.edit_measure_part_ee = 'lbs/inch';
                      }
                      else{
                        this.edit_measure_part_ee = 'kg/cm';
                      }

                      // if(this.edit_measure_part_ee=="kg/cm")
                      // {


                      //   console.log(this.primarypartsgrid_ee);
                      //   console.log(this.secondarypartsgrid_ee);


                      //   if(this.primarypartsgrid_ee[0].ngpp_part_l!=null)
                      //   {
                      //     this.primarypartsgrid_ee[0].ngpp_part_l=Math.round((2.54*this.primarypartsgrid_ee[0].ngpp_part_l)*1000)/1000;

                      //   }
                      //   if(this.primarypartsgrid_ee[0].ngpp_part_w!=null)
                      //   {
                      //     this.primarypartsgrid_ee[0].ngpp_part_w=Math.round((2.54*this.primarypartsgrid_ee[0].ngpp_part_w)*1000)/1000;

                      //   }
                      //   if(this.primarypartsgrid_ee[0].ngpp_part_h!=null)
                      //   {
                      //     this.primarypartsgrid_ee[0].ngpp_part_h=Math.round((2.54*this.primarypartsgrid_ee[0].ngpp_part_h)*1000)/1000;

                      //   }
                      //   if(this.primarypartsgrid_ee[0].ngpp_part_wt!=null)
                      //   {
                      //     this.primarypartsgrid_ee[0].ngpp_part_wt=Math.round((0.453592*this.primarypartsgrid_ee[0].ngpp_part_wt)*1000)/1000;

                      //   }



                      //   if(this.secondarypartsgrid_ee.length!=0)
                      //   {


                      //     for(var sec=0; sec<this.secondarypartsgrid_ee.length; sec++)
                      //     {


                      //       if(this.secondarypartsgrid_ee[sec].ngpp_part_l!=null)
                      //       {
                      //         this.secondarypartsgrid_ee[sec].ngpp_part_l=Math.round((2.54*this.secondarypartsgrid_ee[sec].ngpp_part_l)*1000)/1000;

                      //       }
                      //       if(this.secondarypartsgrid_ee[sec].ngpp_part_w!=null)
                      //       {
                      //         this.secondarypartsgrid_ee[sec].ngpp_part_w=Math.round((2.54*this.secondarypartsgrid_ee[sec].ngpp_part_w)*1000)/1000;

                      //       }
                      //       if(this.secondarypartsgrid_ee[sec].ngpp_part_h!=null)
                      //       {
                      //         this.secondarypartsgrid_ee[sec].ngpp_part_h=Math.round((2.54*this.secondarypartsgrid_ee[sec].ngpp_part_h)*1000)/1000;

                      //       }
                      //       if(this.secondarypartsgrid_ee[sec].ngpp_part_wt!=null)
                      //       {
                      //         this.secondarypartsgrid_ee[sec].ngpp_part_wt=Math.round((0.453592*this.secondarypartsgrid_ee[sec].ngpp_part_wt)*1000)/1000;

                      //       }


                      //     }

                      //   }


                      // }
                    }

                });
              }
              else
              {
                this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Primary Part  Not Added,Please Try Again'})

                if(this.unitom_ee == 'E')
                {
                  this.edit_measure_part_ee = 'lbs/inch';
                }
                else{
                  this.edit_measure_part_ee = 'kg/cm';
                }

                // if(this.edit_measure_part_ee=="kg/cm")
                // {


                //   console.log(this.primarypartsgrid_ee);
                //   console.log(this.secondarypartsgrid_ee);


                //   if(this.primarypartsgrid_ee[0].ngpp_part_l!=null)
                //   {
                //     this.primarypartsgrid_ee[0].ngpp_part_l=Math.round((2.54*this.primarypartsgrid_ee[0].ngpp_part_l)*1000)/1000;

                //   }
                //   if(this.primarypartsgrid_ee[0].ngpp_part_w!=null)
                //   {
                //     this.primarypartsgrid_ee[0].ngpp_part_w=Math.round((2.54*this.primarypartsgrid_ee[0].ngpp_part_w)*1000)/1000;

                //   }
                //   if(this.primarypartsgrid_ee[0].ngpp_part_h!=null)
                //   {
                //     this.primarypartsgrid_ee[0].ngpp_part_h=Math.round((2.54*this.primarypartsgrid_ee[0].ngpp_part_h)*1000)/1000;

                //   }
                //   if(this.primarypartsgrid_ee[0].ngpp_part_wt!=null)
                //   {
                //     this.primarypartsgrid_ee[0].ngpp_part_wt=Math.round((0.453592*this.primarypartsgrid_ee[0].ngpp_part_wt)*1000)/1000;

                //   }



                //   if(this.secondarypartsgrid_ee.length!=0)
                //   {


                //     for(var sec=0; sec<this.secondarypartsgrid_ee.length; sec++)
                //     {


                //       if(this.secondarypartsgrid_ee[sec].ngpp_part_l!=null)
                //       {
                //         this.secondarypartsgrid_ee[sec].ngpp_part_l=Math.round((2.54*this.secondarypartsgrid_ee[sec].ngpp_part_l)*1000)/1000;

                //       }
                //       if(this.secondarypartsgrid_ee[sec].ngpp_part_w!=null)
                //       {
                //         this.secondarypartsgrid_ee[sec].ngpp_part_w=Math.round((2.54*this.secondarypartsgrid_ee[sec].ngpp_part_w)*1000)/1000;

                //       }
                //       if(this.secondarypartsgrid_ee[sec].ngpp_part_h!=null)
                //       {
                //         this.secondarypartsgrid_ee[sec].ngpp_part_h=Math.round((2.54*this.secondarypartsgrid_ee[sec].ngpp_part_h)*1000)/1000;

                //       }
                //       if(this.secondarypartsgrid_ee[sec].ngpp_part_wt!=null)
                //       {
                //         this.secondarypartsgrid_ee[sec].ngpp_part_wt=Math.round((0.453592*this.secondarypartsgrid_ee[sec].ngpp_part_wt)*1000)/1000;

                //       }


                //     }

                //   }


                // }
              }
            });
        }
        else{
          this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Primary Part Attributes Not Added,Please Try Again'})
        }
      });
  }
}

  CloseTab_ee()
  {


    this.name_ee=this.transactionService.getregionNameInSmallLetters();



    this.router.navigate(['/'+this.name_ee+'/packagingprocessdashboard/searchexistingproposal'])

  }
}
