import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng';

@Component({
  selector: 'app-packagingprocess',
  templateUrl: './packagingprocess.component.html',
  styleUrls: ['./packagingprocess.component.css'],
  providers:[MessageService]
})
export class PackagingprocessComponent implements OnInit {

  constructor(private msgService:MessageService) { }

  ngOnInit(): void {
  }

  addtoastinpackagingprocess(testc:any)
    {
        this.msgService.add(testc);
    }
}
