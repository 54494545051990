/* tslint:disable */
import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';



import {TransactionService} from '../loginpage/Transaction.service';
import { Router,ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
// tslint:disable-next-line:max-line-length
import {plantlist,addplant,getplant,planttype,countrylist, useraccessrole,subpltlist,subprglist,subcoslist, substatuslist, subgrid, subtradco, selec_prt_pro_details, subnewprt_latesbom, subnewpart_latestbom_designsource, supp_country,containerOwnershipList, ActivePlants, programsbasedonplants} from '../loginpage/transactions';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';



@Component({
  selector: 'app-submiterdashboard',
  templateUrl: './submiterdashboard.component.html',
  styleUrls: ['./submiterdashboard.component.css']
})
export class SubmiterdashboardComponent implements OnInit, OnChanges {

  disableProgDrp=true;
  currentRegionSub=' ';
  currentRegionCodeSub:string;
  subUserAccess:useraccessrole[]=[];
  plantCodeList:subpltlist[]=[];
  selectedPlantList:subpltlist[]=[];


  progList:subprglist[]=[];
  selectedProgList:subprglist[]=[];

  cosList:subcoslist[]=[];
  selectedCosList:subcoslist[]=[];

/*
  statusList:substatuslist[]=[];
  selectedstatuslist:substatuslist[]=[];

  trdcmpyList:subtrdcmpylist[]=[];
  selectedtrdcmpylist:subtrdcmpylist[]=[];
  */

 selectedPartPrefix='';
 selectedPartBase='';
 selectedPartSuffix='';

  partPrefix='';
  partBase='';
  partSuffix='';



  plantcode='';
  programcode='';
  cos='';

  status='';
  trdcmpy='';
  noSupDialog=false;
  content='';

  subPrgUserAccess:useraccessrole[]=[];



  subAllPlants:ActivePlants[]=[];
  subCosList:containerOwnershipList[]=[];
  progListOnPlt:programsbasedonplants[]=[];



  tradCmpyList:subtradco[]=[];
  selectedTrdcmpy:subtradco[]=[]

  statusList:substatuslist[]=[];
  selectedStatusList:substatuslist[]=[];
    // tslint:disable-next-line:max-line-length
  selectedSimpliedPartnoDetails:selec_prt_pro_details={simplifiedpartno:'-',bompartmasterid:0,proposalid:0,plantgsdbcode:'',proposallastsaved:0,part_status:'',supplier_code:'',proposallastsaved_ee:0,is_ee_proposal:false,currentview_ee:false,from_draft:false,propsl_type:'',imp_part_status:''};


  submittergridList:subgrid[]=[];


  gridText='';
  presentTable=false;
  subCurrentRoleid:number;
  displaylinkProposalCreate=false;
  displaylinkProposalNonSubmit=false;
  subUserRoles:any;


  displaySupplier=false;


  noRoleSelectedDialog=false;
  noRoleContent=''


    // tslint:disable-next-line:new-parens
  newprtDetailsOnsuppcod:subnewprt_latesbom={supCode:'',latestBOMDt:new Date,latestBOMDt_string:'',newPartCnt:0};
  newprtDetailsOnDesignsourceCode:subnewpart_latestbom_designsource={ prtAwtngPropSubmn: 0,latestBOMDt: '',dsgnSrcId:''}


  gridLength=0;


  loadersub=false



public cols: any[];

  subCdsid:string;

  @Input() suppliercode:string;

  suppCode:string;

  designsourceCode:string;
  selectedSuppliergsdbcodeDesignsource='';
  suppliergsdbcodeDesignsource='';
  supplierCountryList:supp_country[]=[];
  selectedSupplierCountry:supp_country[]=[];
  supplierCountry=''
  noDesignsourceDialog=false;
  contentDesignsource=''
    // tslint:disable-next-line:variable-name
  is_container_expendable: boolean;

  constructor(private transactionService: TransactionService,private router:Router,private date:DatePipe,private route: ActivatedRoute) { }


  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.suppliercode)
    this.ngOnInit();
    console.log('value changed');
  }

  ngOnInit(): void {


    this.subCdsid=this.transactionService.getcdsid();
    this.currentRegionSub=this.transactionService.getregion();

    this.subCurrentRoleid=this.transactionService.getcurrentrole(this.currentRegionSub)


    if(this.subCurrentRoleid==null)
    {


      this.noRoleSelectedDialog=true;
      this.noRoleContent='No role selected '
    }
    // get role
    this.currentRegionCodeSub=this.transactionService.getregioncode(this.currentRegionSub);
    if(this.subCurrentRoleid===1)
    {
      this.displaySupplier=false;
    this.transactionService.storepage(this.currentRegionSub,'packagingprocess/packsupplieradmin/submiterdashboard');
    this.suppCode=this.transactionService.get_supplier(this.currentRegionSub);
    console.log(this.suppCode);
    }

    // tslint:disable-next-line:triple-equals
    else if(this.subCurrentRoleid==2)
    {

      this.designsourceCode=this.transactionService.get_designsource(this.currentRegionSub);
      this.displaySupplier=true;
      this.transactionService.storepage(this.currentRegionSub,'packagingprocess/packdesignsourceadmin/submiterdashboard');
      console.log(this.designsourceCode);
    }


    this.subUserRoles=this.transactionService.getroles(this.currentRegionCodeSub)


console.log(this.subUserRoles)
    this.displaylinkProposalCreate=false;
    this.displaylinkProposalNonSubmit=false;
      // tslint:disable-next-line:triple-equals
    if(this.subUserRoles!=null && this.subUserRoles.length!=0)
    {
        // tslint:disable-next-line:prefer-for-of
    for(let ni=0; ni<this.subUserRoles.length; ni++)
    {
        // tslint:disable-next-line:triple-equals
      if(this.subUserRoles[ni].roleid==this.subCurrentRoleid)
      {
        console.log(this.subUserRoles[ni].roles)
          // tslint:disable-next-line:variable-name
        let user_subroles_func=[];
        user_subroles_func=this.subUserRoles[ni].roles

          // tslint:disable-next-line:prefer-for-of
        for(let nj=0;nj<user_subroles_func.length;nj++)
        {
            // tslint:disable-next-line:triple-equals
          if(user_subroles_func[nj].sysfunc_id==6)
          {
             this.displaylinkProposalCreate=true
             this.displaylinkProposalNonSubmit=true;
            break;
          }
        }

        break;
      }
    }
  }


console.log(this.displaylinkProposalCreate)
    console.log(this.submittergridList)

  /*  this.subUserAccess=[
      {
          "cd_region": "NA",
          "gppuserid": "pmonish1",
          "role_id": 1,
          "role_name": "Supplier",
          "plantgsdbcode": "AP06A",
          "cd_supplier": "0134A",
          "createdby": "kbalarev",
          "createddt": "2022-04-04T07:32:47.000+00:00",
          "plant_name": "plant name",
          "ownershp_strat_code": "FOS,FOU",
          "program_name": "prg,prg1"
      },
      {
          "cd_region": "NA",
          "gppuserid": "pmonish1",
          "role_id": 1,
          "role_name": "Supplier",
          "plantgsdbcode": "AP03A",
          "cd_supplier": "0134A",
          "createdby": "kbalarev",
          "createddt": "2022-04-04T07:32:47.000+00:00",
          "plant_name": "plant name",
          "ownershp_strat_code": "SPS,SPU",
          "program_name": "prg2"
      },
      {
        "cd_region": "NA",
        "gppuserid": "pmonish1",
        "role_id": 1,
        "role_name": "Supplier",
        "plantgsdbcode": "AP07A",
        "cd_supplier": "0134A",
        "createdby": "kbalarev",
        "createddt": "2022-04-04T07:32:47.000+00:00",
        "plant_name": "plant name",
        "ownershp_strat_code": "FOS1,FOU1",
        "program_name": "prg12,prg13"
    },
  ]


  this.subPrgUserAccess=[
    {
        "cd_region": "NA",
        "gppuserid": "pmonish1",
        "role_id": 1,
        "role_name": "Supplier",
        "plantgsdbcode": "AP06A",
        "cd_supplier": "0134A",
        "createdby": "kbalarev",
        "createddt": "2022-04-04T07:32:47.000+00:00",
        "plant_name": "plant name",
        "ownershp_strat_code": "FOS,FOU",
        "program_name": "prg,prg1"
    },
    {
        "cd_region": "NA",
        "gppuserid": "pmonish1",
        "role_id": 1,
        "role_name": "Supplier",
        "plantgsdbcode": "AP03A",
        "cd_supplier": "0134A",
        "createdby": "kbalarev",
        "createddt": "2022-04-04T07:32:47.000+00:00",
        "plant_name": "plant name",
        "ownershp_strat_code": "SPS,SPU",
        "program_name": "prg2"
    },
    {
      "cd_region": "NA",
      "gppuserid": "pmonish1",
      "role_id": 1,
      "role_name": "Supplier",
      "plantgsdbcode": "AP07A",
      "cd_supplier": "0134A",
      "createdby": "kbalarev",
      "createddt": "2022-04-04T07:32:47.000+00:00",
      "plant_name": "plant name",
      "ownershp_strat_code": "FOS1,FOU1",
      "program_name": "prg12,prg13"
  },
]*/


// tslint:disable-next-line:triple-equals
if((this.subCurrentRoleid==1 && this.suppCode!=null)||(this.subCurrentRoleid==2 && this.designsourceCode!=null))
{

    // tslint:disable-next-line:triple-equals
  if(this.subCurrentRoleid==1)
  {
      // tslint:disable-next-line:variable-name
  this.transactionService.getprtcnt_latestbom_date(this.suppCode).subscribe(newprt_details=>{
    console.log(newprt_details)
    if(newprt_details != null)
    {
        // tslint:disable-next-line:triple-equals
      if(newprt_details.supCode != null && (newprt_details.supCode==this.suppCode))
      {
      this.newprtDetailsOnsuppcod=newprt_details;
      if(this.newprtDetailsOnsuppcod.latestBOMDt!=null)
      {
        this.newprtDetailsOnsuppcod.latestBOMDt_string=this.date.transform(this.newprtDetailsOnsuppcod.latestBOMDt, 'dd-MM-yyyy');
      }
      console.log(this.newprtDetailsOnsuppcod);
      }
    }

  }
  )
}

    // tslint:disable-next-line:triple-equals
  if(this.subCurrentRoleid==2)
  {
    // latest bom in iteration 11
    // get supplier  country list
    // enable the values to diplay has true (ie supllier and supplier country)


      // tslint:disable-next-line:variable-name
    this.transactionService.getprtcnt_latestbom_date_designsource(this.designsourceCode).subscribe(newprt_details_desg=>{
      console.log(newprt_details_desg)
      if(newprt_details_desg != null)
      {
          // tslint:disable-next-line:triple-equals
        if(newprt_details_desg.dsgnSrcId != null && (newprt_details_desg.dsgnSrcId==this.designsourceCode))
        {
        this.newprtDetailsOnDesignsourceCode=newprt_details_desg;
        if(this.newprtDetailsOnDesignsourceCode.latestBOMDt!=null)
        {
          this.newprtDetailsOnDesignsourceCode.latestBOMDt=this.date.transform(this.newprtDetailsOnDesignsourceCode.latestBOMDt, 'dd-MM-yyyy');
        }
        console.log(this.newprtDetailsOnDesignsourceCode);
        }
      }

    }
    )


      // tslint:disable-next-line:variable-name
    this.transactionService.getsupplier_country_list().subscribe(supp_country_list=>{
      console.log(supp_country_list)
      this.supplierCountryList=supp_country_list;
            // tslint:disable-next-line:triple-equals
      if(this.supplierCountryList!=null && this.supplierCountryList.length!=0)
      {
          // tslint:disable-next-line:prefer-for-of
        for(let s=0; s<this.supplierCountryList.length; s++)
        {
          if(this.supplierCountryList[s].cd_country_iso!=null)
          {
      this.supplierCountryList[s].code_name=this.supplierCountryList[s].cd_country_iso+'-'+this.supplierCountryList[s].country_name
          }
          else
          {
            this.supplierCountryList[s].code_name=this.supplierCountryList[s].country_name;

          }
        }
      }
    }
    )






  }
    // tslint:disable-next-line:variable-name
  this.transactionService.getpartstatus('Submitter').subscribe(sub_status=>{


    console.log(sub_status);
    this.statusList=[];
   // this.statusList=sub_status;
      // tslint:disable-next-line:prefer-for-of
    for(let i=0;i<sub_status.length;i++)
    {
      if(sub_status[i].status!=='Submitted')
      {
      this.statusList.push(sub_status[i]);
      }
    }
this.selectedStatusList=[];




// tslint:disable-next-line:variable-name
this.transactionService.gettradingcompany().subscribe(sub_tradcmpy=>{


  console.log(sub_tradcmpy);

  this.tradCmpyList=[];
  this.tradCmpyList.push({tradingcogsdbcode:'[No Trading Company]'})
    // tslint:disable-next-line:prefer-for-of
  for(let trd=0;trd<sub_tradcmpy.length;trd++)
  {
  this.tradCmpyList.push(sub_tradcmpy[trd]);
  }

console.log('trad',this.tradCmpyList)
  this.selectedTrdcmpy=[];





// tslint:disable-next-line:variable-name
this.transactionService.getOwnershipStrategyCode(this.currentRegionCodeSub).subscribe(sub_coslist=>{

  console.log('cos',sub_coslist);
  this.subCosList=sub_coslist
  this.cosList=[];
  if(this.subCosList.length!==0)
  {
      // tslint:disable-next-line:prefer-for-of
  for(let icos=0;icos<this.subCosList.length;icos++)
  {
  this.cosList.push({cos:this.subCosList[icos].ownrshp_strat_code,cos_name:this.subCosList[icos].ownrshp_desc});
  }
}


console.log(this.cosList)





this.listPlantcode();


  })

})

})


}

else if(this.subCurrentRoleid===1)
{

if(this.suppCode==null)
{
  this.noSupDialog=true;
  this.content='Please select supplier';
}
}

else if(this.subCurrentRoleid===2)
{
  if(this.designsourceCode==null)
  {
    this.noDesignsourceDialog=true;
    this.contentDesignsource='Please select Design Source'
  }
}

    // service to get status

    // inside service call the plantcode list function this.list_plantcode()
    // if full load at first time call grid function
  }


  okNoRoleDialog()
  {
    let url
    url=this.transactionService.getregionurlvalue(this.currentRegionSub);
    url+='packagingprocessdashboard'
    this.router.navigate([url]);

  }



  okNoSupDialog()
  {
    console.log(this.currentRegionSub)
      // tslint:disable-next-line:variable-name
    let reg_url:string
    reg_url=this.transactionService.getregionurlvalue(this.currentRegionSub);
    reg_url+='packagingprocess/packsupplieradmin/packsupplier';

    this.router.navigate([reg_url]);

  }


  okNoDesignsourceDialog()
  {
    console.log(this.currentRegionSub)
      // tslint:disable-next-line:variable-name
    let reg_url:string
    reg_url=this.transactionService.getregionurlvalue(this.currentRegionSub);
    reg_url+='packagingprocess/packdesignsourceadmin/packdesignsource';

    this.router.navigate([reg_url]);

  }


  goToSuppMainPage()
  {
      // tslint:disable-next-line:variable-name
    let reg_supp_url:string;
    reg_supp_url=this.transactionService.getregionurlvalue(this.currentRegionSub);
    if(this.subCurrentRoleid===1)
    {
    reg_supp_url+='packagingprocess/packsupplieradmin/packsupplier';
    }
    else if(this.subCurrentRoleid===2){
      reg_supp_url+='packagingprocess/packdesignsourceadmin/packdesignsource';

    }
    this.router.navigate([reg_supp_url]);

  }


  partdataList()
  {

  }

  listPlantcode()
  {
    console.log('get plant list')

// tslint:disable-next-line:variable-name
const sub_allplants=[]
      // tslint:disable-next-line:variable-name no-shadowed-variable
  this.transactionService.getactiveplantslist(this.currentRegionCodeSub).subscribe(sub_allplants=>{
      console.log('sub_useracces',sub_allplants);
      // tslint:disable-next-line:prefer-const
     let position:number;
       this.subAllPlants=sub_allplants;
       this.plantCodeList=[];
       this.progList=[];



       // this.plantCodeList.push({plantcode:"all",plant:"[ALL]"});


       if(this.subAllPlants.length!==0)
       {
           // tslint:disable-next-line:prefer-for-of
       for(let temp=0;temp<this.subAllPlants.length;temp++)
       {
         // console.log(this.subAllPlants[temp]);
           // tslint:disable-next-line:variable-name
         let plt_name:string;
         plt_name=this.subAllPlants[temp].plantGSDBCode+'-'+this.subAllPlants[temp].plant_name;

          this.plantCodeList.push({plantcode:this.subAllPlants[temp].plantGSDBCode,plant:plt_name})

       }
      }


       console.log(this.plantCodeList);







     // service to get trading company
     // assign slected trading company full list of trading companies;






 // this.selectedproglist=this.progList;
 // this.selectedcoslist=this.cosList;

 // this.selectedplantlist=this.plantCodeList;
 // this.disableProgDrp=false;


 this.selectedProgList=[];
 this.selectedCosList=[];

 this.selectedPlantList=[];
 this.disableProgDrp=true;


 this.applyFilter();



    })
    // in future do gor global admin if its used
 /*
   this.transactionService.getUserAccessRole(this.sub_cdsid).subscribe(sub_useraccess=>{
     console.log("sub_useracces",sub_useraccess);
    var position:number;
      this.subUserAccess=sub_useraccess;
      this.plantCodeList=[];
      this.progList=[];
      this.cosList=[];


      //this.plantCodeList.push({plantcode:"all",plant:"[ALL]"});

      for(var temp=0;temp<this.subUserAccess.length;temp++)
      {
        console.log(temp);
        console.log(this.subUserAccess[0].cd_region)
        if(this.subUserAccess[temp].cd_region==this.current_region_code_sub)
        {
        if(this.subUserAccess[temp].role_id==this.sub_current_roleid)
        {
          var plt_name:string;
          plt_name=this.subUserAccess[temp].plantgsdbcode+"-"+this.subUserAccess[temp].plant_name;

           this.plantCodeList.push({plantcode:this.subUserAccess[temp].plantgsdbcode,plant:plt_name})


if(this.subUserAccess[temp].program_name!=null)
{

    var prg_arr=[];
    prg_arr=this.subUserAccess[temp].program_name.split(',');

    console.log(prg_arr)
    for(var prg=0;prg<prg_arr.length;prg++)
    {
      var present_prg=false;
      for(var kprg=0;kprg<this.progList.length;kprg++)
                {
             if(prg_arr[prg]==this.progList[kprg].programcode)
              {
                   present_prg=true;
                                      break;
              }
       }


    if(present_prg==false)
            {
              this.progList.push({programcode:prg_arr[prg]})
                }
    }


    console.log("proglist",this.progList);
  }



  if(this.subUserAccess[temp].ownershp_strat_code!=null)
  {
    var cos_arr=[];
    cos_arr=this.subUserAccess[temp].ownershp_strat_code.split(',');


    for(var cos=0;cos<cos_arr.length;cos++)
    {
      var present_cos=false;
      for(var kcos=0;kcos<this.cosList.length;kcos++)
      {
        if(cos_arr[cos]==this.cosList[kcos].cos)
        {
          present_cos=true;
          break;
        }
      }


      if(present_cos==false)
      {
      this.cosList.push({cos:cos_arr[cos]});
      }
    }


    console.log("coslist",this.cosList);
  }



    //service to get trading company
    //assign slected trading company full list of trading companies;

  }
}


}

//this.selectedproglist=this.progList;
//this.selectedcoslist=this.cosList;

//this.selectedplantlist=this.plantCodeList;
//this.disableProgDrp=false;


this.selectedproglist=[];
this.selectedcoslist=[];

this.selectedplantlist=[];
this.disableProgDrp=true;


this.apply_filter();



    })*/

    // service

   // this.disableProgDrp=true;
  }


  onChangePlant()
  {


    console.log(this.selectedPlantList);

if(this.selectedPlantList.length!==0)
{
  this.disableProgDrp=false;


    // tslint:disable-next-line:variable-name
  let plantcodes_selected_commasep='';
  plantcodes_selected_commasep=this.selectedPlantList[0].plantcode;



  if(this.selectedPlantList.length>1)
  {
  for(let iconv=1; iconv<this.selectedPlantList.length; iconv++)
  {

    plantcodes_selected_commasep+=',';
    plantcodes_selected_commasep+=this.selectedPlantList[iconv].plantcode;

  }
}


console.log('plantcodes',plantcodes_selected_commasep);

// tslint:disable-next-line:variable-name
const prog_output=[];
// tslint:disable-next-line:variable-name no-shadowed-variable
this.transactionService.getprogmlistbasedonplantgsdb(plantcodes_selected_commasep,this.currentRegionCodeSub).subscribe(prog_output=>{

  console.log('programs',prog_output);
  this.progListOnPlt=prog_output
  this.progList=[];


 /*/ if(this.progListOnPlt.length!=0)
  {
    console.log(this.progListOnPlt);
    for(var it=0;it<this.progListOnPlt.length;it++)
    {
      this.progList.push({programcode:this.progListOnPlt[it].programname,programdisplay:this.progListOnPlt[it].programname})
    }
  }*/
  if(this.progListOnPlt.length!==0)
 {

     // tslint:disable-next-line:prefer-for-of
    for(let prg=0;prg<this.progListOnPlt.length;prg++)
    {


        // tslint:disable-next-line:variable-name
      let present_prg=false;
        // tslint:disable-next-line:variable-name
      let prog_plt:string;

        // tslint:disable-next-line:prefer-for-of
      for(let kprg=0;kprg<this.progList.length;kprg++)
      {
             if(this.progListOnPlt[prg].programname.toLowerCase()===this.progList[kprg].programcode.toLowerCase())
              {


                prog_plt=this.progList[kprg].programdisplay;
                prog_plt+=',('+this.progListOnPlt[prg].plantgsdbcode+')';

               this.progList[kprg].programdisplay= prog_plt

                   present_prg=true;
                                      break;
              }
       }


   if(present_prg===false)
            {
              prog_plt=this.progListOnPlt[prg].programname+'('+this.progListOnPlt[prg].plantgsdbcode+')';

      this.progList.push({programcode: this.progListOnPlt[prg].programname,programdisplay:prog_plt})

                }



    }

  }


  console.log(this.progList);


  console.log(this.selectedProgList);


  if(this.progList.length!==0 && this.selectedProgList.length!==0)
{
  const selprgarr=[];
  console.log(this.selectedProgList);
  console.log(this.progList)
    // tslint:disable-next-line:variable-name
  const prog_plt='';
    // tslint:disable-next-line:prefer-for-of
          for(let iprg=0;iprg<this.progList.length;iprg++)
          {
            console.log(this.progList[iprg])
              // tslint:disable-next-line:prefer-for-of
          for(let jprg=0; jprg<this.selectedProgList.length; jprg++)
          {
            console.log(this.selectedProgList[jprg])

            if(this.progList[iprg].programcode.toLowerCase()===this.selectedProgList[jprg].programcode.toLowerCase())
            {
              this.selectedProgList[jprg].programdisplay=this.progList[iprg].programdisplay
              selprgarr.push(this.selectedProgList[jprg]);
              console.log(selprgarr)
              break;
            }
          }

          }




          this.selectedProgList=selprgarr;

          console.log(this.selectedProgList)
}


else if(this.progList.length===0)
{
  this.selectedProgList=[];
}



})



/*---------------------
//in future do gor global admin if its used
     this.transactionService.getUserAccessRole(this.sub_cdsid).subscribe(sub_prguseraccess=>{
      console.log("sub_useracces",sub_prguseraccess);
        var position:number;
      this.subPrgUserAccess=sub_prguseraccess;
        this.progList=[];
        this.cosList=[];
        ---------------*/

/*
        if(this.selectedplantlist.length==this.plantCodeList.length)
        {
        for(var prg_temp=0;prg_temp<this.subPrgUserAccess.length;prg_temp++)
        {
          if(this.subPrgUserAccess[prg_temp].role_id==1)
          {
            var prg_arr=[];
            if(this.subPrgUserAccess[prg_temp].program_name!=null)
            {
            prg_arr=this.subPrgUserAccess[prg_temp].program_name.split(',');
            for(var prg=0;prg<prg_arr.length;prg++)
            {
              this.progList.push({programcode:prg_arr[prg]})
            }


            console.log("proglist",this.progList);
          }

          if(this.subUserAccess[prg_temp].ownershp_strat_code!=null)
          {

            var cos_arr=[];
            cos_arr=this.subPrgUserAccess[prg_temp].ownershp_strat_code.split(',');



              for(var cos=0;cos<cos_arr.length;cos++)
    {
      var present_cos=false;
      for(var kcos=0;kcos<this.cosList.length;kcos++)
      {
        if(cos_arr[cos]==this.cosList[kcos].cos)
        {
          present_cos=true;
          break;
        }
      }


      if(present_cos==false)
      {
      this.cosList.push({cos:cos_arr[cos]});
      }
    }




            console.log("coslist",this.cosList);

          }

            //service to get trading company
            //assign slected trading company full list of trading companies;





          }

          }

        }




    else if(this.selectedplantlist.length<this.plantCodeList.length)
    {

*/
/*
var all_present_change=false;
for(var i=0;i<this.selectedplantlist.length;i++)
{
  if(this.selectedplantlist[i].plantcode=="all")
  {
    all_present_change=true;
    break;
  }
}
      console.log(this.selectedplantlist)


      if(all_present_change!=true)
      {*/

        /*---------------------------------------------
      for(var iplt=0;iplt<this.selectedplantlist.length;iplt++)
      {
      for(var prg_temp=0;prg_temp<this.subPrgUserAccess.length;prg_temp++)
      {
        if(this.subPrgUserAccess[prg_temp].cd_region==this.current_region_code_sub)
        {
        if(this.subPrgUserAccess[prg_temp].role_id==this.sub_current_roleid && this.subPrgUserAccess[prg_temp].plantgsdbcode==this.selectedplantlist[iplt].plantcode)
        {


          var prg_arr=[];
            if(this.subPrgUserAccess[prg_temp].program_name!=null)
            {
            prg_arr=this.subPrgUserAccess[prg_temp].program_name.split(',');
            for(var prg=0;prg<prg_arr.length;prg++)
            {


              var present_prg=false;
              for(var kprg=0;kprg<this.progList.length;kprg++)
                        {
                     if(prg_arr[prg]==this.progList[kprg].programcode)
                      {
                           present_prg=true;
                                              break;
                      }
               }


            if(present_prg==false)
                    {
                      this.progList.push({programcode:prg_arr[prg]})
                        }

            }


            console.log("proglist",this.progList);
          }

          if(this.subUserAccess[prg_temp].ownershp_strat_code!=null)
          {

            var cos_arr=[];
            cos_arr=this.subPrgUserAccess[prg_temp].ownershp_strat_code.split(',');


                            for(var cos=0;cos<cos_arr.length;cos++)
                                     {
                                    var present_cos=false;
                                       for(var kcos=0;kcos<this.cosList.length;kcos++)
                                                 {
                                              if(cos_arr[cos]==this.cosList[kcos].cos)
                                               {
                                                    present_cos=true;
                                                                       break;
                                               }
                                        }


                                     if(present_cos==false)
                                             {
                                       this.cosList.push({cos:cos_arr[cos]});
                                                 }
                                        }




            console.log("coslist",this.cosList);


          }

            //service to get trading company
            //assign slected trading company full list of trading companies;








        }
      }
     // }
      }


console.log(this.selectedproglist);



    }---------------------------------*/
/*  }*/
/*---------------------------------------------
if(this.progList.length!=0 && this.selectedproglist.length!=0)
{
  var selprgarr=[];
  console.log(this.selectedproglist);
  console.log(this.progList)
          for(var iprg=0;iprg<this.progList.length;iprg++)
          {

            console.log(this.progList[iprg])
          for(var jprg=0;jprg<this.selectedproglist.length;jprg++)
          {
            console.log(this.selectedproglist[jprg])

            if(this.progList[iprg].programcode==this.selectedproglist[jprg].programcode)
            {
              selprgarr.push(this.selectedproglist[jprg]);
              console.log(selprgarr)
              break;
            }
          }

          }




          this.selectedproglist=selprgarr;

          console.log(this.selectedproglist)
}


else if(this.progList.length==0)
{
  this.selectedproglist=[];
}



if(this.cosList.length!=0 && this.selectedcoslist.length!=0)
{
  var selcosarr=[];
          for(var icos=0;icos<this.cosList.length;icos++)
          {


          for(var jcos=0;jcos<this.selectedcoslist.length;jcos++)
          {

            if(this.cosList[icos].cos==this.selectedcoslist[jcos].cos)
            {
              selcosarr.push(this.selectedcoslist[jcos]);
              break;
            }
          }

          }


          this.selectedcoslist=selcosarr;
}


else if(this.cosList.length==0)
{
  this.selectedcoslist=[];
}












       })

      //service

     // this.disableProgDrp=true;

-------------------------------------*/

  }


  else{
    this.disableProgDrp=true;
    this.selectedPlantList=[];
   // this.selectedcoslist=[];
    this.selectedProgList=[];


  }

  }

/*
  check_all()
  {
    console.log("selectedplant",this.selectedplantlist)
    var all_present:boolean=false;
    for(var i=0;i<this.selectedplantlist.length;i++)
    {
      if(this.selectedplantlist[i].plantcode=="all")
      {all_present=true;
        break;

      }
    }


    if(all_present==true)
    {


      this.selectedplantlist=[];
      this.selectedplantlist.push({plantcode:"all",plant:"[ALL]"});

/*
      for(var i=0;i<this.plantCodeList.length;i++)
    {
      if(this.plantCodeList[i].plantcode!="all")
      {
        this.plantCodeList[i].disable=true;

      }
    }
*/



 /*   }


    else{

/*
      for(var i=0;i<this.plantCodeList.length;i++)
    {
      if(this.plantCodeList[i].plantcode!="all")
      {
        this.plantCodeList[i].disable=false;

      }
    }
    */






 /*   }
  }*/


  getSelectedPart(partData:any){


   // this.selectedsimpliedpartnodetails=partData.partnumbercommonized;

    this.transactionService.loadmenuwhenclickedfromdashboard('Yes');

    // this.pushdata();

    sessionStorage.setItem('offsitesupplierid',partData.offsitesupplierid);

    if(partData.ngpp_part_status==='New Part')
    {
// tslint:disable-next-line:variable-name
this.transactionService.getproposalid().subscribe(sub_proposalid=>{


  console.log(sub_proposalid)


  this.selectedSimpliedPartnoDetails.bompartmasterid=partData.bompartmasterid;
  this.selectedSimpliedPartnoDetails.simplifiedpartno=partData.partnumbercommonized;
  this.selectedSimpliedPartnoDetails.proposalid=sub_proposalid;
  this.selectedSimpliedPartnoDetails.plantgsdbcode=partData.plantgsdbcode;
  this.selectedSimpliedPartnoDetails.proposallastsaved=0;


  this.selectedSimpliedPartnoDetails.part_status=partData.ngpp_part_status;


  this.selectedSimpliedPartnoDetails.supplier_code=partData.suppliergsdbcode;
  this.selectedSimpliedPartnoDetails.proposallastsaved_ee=1;
  this.selectedSimpliedPartnoDetails.is_ee_proposal=false;
  this.selectedSimpliedPartnoDetails.currentview_ee=false;
  this.selectedSimpliedPartnoDetails.propsl_type='Normal';
  this.selectedSimpliedPartnoDetails.imp_part_status=null;

  this.selectedSimpliedPartnoDetails.from_draft=true;

  this.storePrimaryPart();

})

    }

    else{


      console.log(partData.plantgsdbcode);
      console.log(partData.partnumbercommonized);


      // var sub_exist_proposalid=0;

      // service to get proposal id


        // tslint:disable-next-line:max-line-length variable-name
        this.transactionService.get_exist_proposalid(partData.partnumbercommonized,partData.plantgsdbcode,"Normal",partData.suppliergsdbcode).subscribe(sub_exist_proposalid=>{

          console.log('subexistpropolis',sub_exist_proposalid)
      this.selectedSimpliedPartnoDetails.bompartmasterid=partData.bompartmasterid;
  this.selectedSimpliedPartnoDetails.simplifiedpartno=partData.partnumbercommonized;
  this.selectedSimpliedPartnoDetails.proposalid=sub_exist_proposalid.id_proposal;
  this.selectedSimpliedPartnoDetails.plantgsdbcode=partData.plantgsdbcode;
  this.selectedSimpliedPartnoDetails.proposallastsaved=sub_exist_proposalid.lst_saved_tab;
  this.selectedSimpliedPartnoDetails.part_status=partData.ngpp_part_status;
  this.selectedSimpliedPartnoDetails.supplier_code=partData.suppliergsdbcode;
  this.selectedSimpliedPartnoDetails.propsl_type='Normal';
  this.selectedSimpliedPartnoDetails.imp_part_status=null


  this.selectedSimpliedPartnoDetails.from_draft=true;

  if(sub_exist_proposalid.lst_saved_tab_ee===0)
  {
    this.selectedSimpliedPartnoDetails.proposallastsaved_ee=1;
  }
  else{
    this.selectedSimpliedPartnoDetails.proposallastsaved_ee=sub_exist_proposalid.lst_saved_tab_ee;
  }
  if(this.selectedSimpliedPartnoDetails.proposallastsaved===4)
  {






      this.transactionService.loadtab4details(this.selectedSimpliedPartnoDetails.proposalid).subscribe(tab4det=>
        {
          console.log('tab4',tab4det);


            // tslint:disable-next-line:max-line-length variable-name
          this.transactionService.loadtab2detailsifproposalexistalready(this.selectedSimpliedPartnoDetails.proposalid).subscribe(tab2_details=>{
            console.log('tab2-det',tab2_details)
            console.log(tab2_details)



            if(tab2_details.cntr_ret_type!=null && tab2_details.cntr_ret_type!=='' )
            {
              if(tab2_details.cntr_ret_type.toLowerCase()==='returnable')
              {
                this.is_container_expendable=false;
              }
              else if(tab2_details.cntr_ret_type.toLowerCase()==='expendable')
              {
                this.is_container_expendable=true;

              }
            }
            else
            {
              this.is_container_expendable=false;
            }


            if(this.is_container_expendable===true)
            {
              //region admin details


              this.transactionService.get_region_details(this.currentRegionCodeSub).subscribe(region_det=>{
                console.log("tab4-region",region_det);
                
                        if(region_det.emg_exp_pkg_reqd_ind!=null && region_det.emg_exp_pkg_reqd_ind!="" && region_det.emg_exp_pkg_reqd_ind.toLowerCase()=="no")
                        {

                          console.log('inside if tab4')
                          this.selectedSimpliedPartnoDetails.is_ee_proposal=false;
                          this.selectedSimpliedPartnoDetails.currentview_ee=false;


                          
                          if(this.selectedSimpliedPartnoDetails.part_status.toLowerCase()=="rejected" || this.selectedSimpliedPartnoDetails.part_status.toLowerCase()=="recalled" )
                          {
                            this.selectedSimpliedPartnoDetails.from_draft=true;

                          }


                          this.storePrimaryPart();

                        }


                        else
                        {
                          
                                if(tab4det.createmgcyexpndpckgprop!=null &&  tab4det.createmgcyexpndpckgprop!="" && tab4det.createmgcyexpndpckgprop.toLowerCase()=="yes")
                                {


                                  if(tab4det.f_extcntrdsgnphoto==null|| tab4det.f_extcntrdsgnphoto==""||
                                  tab4det.f_intcntrdsgnphoto==null|| tab4det.f_intcntrdsgnphoto==""||
                                  tab4det.f_partphoto==null|| tab4det.f_partphoto==""||
                                  tab4det.f_metelidplatephoto==null|| tab4det.f_metelidplatephoto==""||
                                  tab4det.f_shpunitphoto==null|| tab4det.f_shpunitphoto=="" || tab4det.createmgcyexpndpckgprop==null || tab4det.createmgcyexpndpckgprop=="")



                                  {
                                    this.selectedSimpliedPartnoDetails.is_ee_proposal=true;
                                    this.selectedSimpliedPartnoDetails.currentview_ee=false;

                                  }



                                  else
                                  {
                                    this.selectedSimpliedPartnoDetails.is_ee_proposal=true;
                                    console.log('entered if condn');
                                    if(this.selectedSimpliedPartnoDetails.proposallastsaved_ee===4)
                                    {
                                      this.selectedSimpliedPartnoDetails.currentview_ee=false;
                                    }
                                    else{

                                      this.selectedSimpliedPartnoDetails.currentview_ee=true;

                                    }

                                  }




                                }
                                else{
                                  this.selectedSimpliedPartnoDetails.is_ee_proposal=false;
                                  this.selectedSimpliedPartnoDetails.currentview_ee=false;

                                }
                        
                        
                                if(this.selectedSimpliedPartnoDetails.part_status.toLowerCase()=="rejected" || this.selectedSimpliedPartnoDetails.part_status.toLowerCase()=="recalled" )
                                {
                                  this.selectedSimpliedPartnoDetails.from_draft=true;

                                }


                                this.storePrimaryPart();
                        }



              })
            }


            else
            {
                // tslint:disable-next-line:max-line-length
                        if(tab4det.createmgcyexpndpckgprop!=null &&  tab4det.createmgcyexpndpckgprop!=='' && tab4det.createmgcyexpndpckgprop.toLowerCase()==='yes')
                        {
                          if(tab4det.f_extcntrdsgnphoto==null|| tab4det.f_extcntrdsgnphoto==""||
                          tab4det.f_intcntrdsgnphoto==null|| tab4det.f_intcntrdsgnphoto==""||
                          tab4det.f_partphoto==null|| tab4det.f_partphoto==""||
                          tab4det.f_metelidplatephoto==null|| tab4det.f_metelidplatephoto==""||
                          tab4det.f_shpunitphoto==null|| tab4det.f_shpunitphoto=="" || tab4det.createmgcyexpndpckgprop==null || tab4det.createmgcyexpndpckgprop=="")
                          {
                            this.selectedSimpliedPartnoDetails.is_ee_proposal=true;
                            this.selectedSimpliedPartnoDetails.currentview_ee=false;

                          }

                          else
                          {
                                this.selectedSimpliedPartnoDetails.is_ee_proposal=true;
                                console.log('entered if condn');
                                if(this.selectedSimpliedPartnoDetails.proposallastsaved_ee===4)
                                {
                                  this.selectedSimpliedPartnoDetails.currentview_ee=false;
                                }
                                else
                                {

                                  this.selectedSimpliedPartnoDetails.currentview_ee=true;

                                }
                        }
                        }
                        else{
                          this.selectedSimpliedPartnoDetails.is_ee_proposal=false;
                          this.selectedSimpliedPartnoDetails.currentview_ee=false;

                        }


                // tslint:disable-next-line:max-line-length
                        if(this.selectedSimpliedPartnoDetails.part_status.toLowerCase()==='rejected' || this.selectedSimpliedPartnoDetails.part_status.toLowerCase()==='recalled' )
                        {
                          this.selectedSimpliedPartnoDetails.from_draft=true;

                        }


                        this.storePrimaryPart();

            }



        })

    })

  }
  else
  {
    this.selectedSimpliedPartnoDetails.is_ee_proposal=false;
    this.selectedSimpliedPartnoDetails.currentview_ee=false;
    if(this.selectedSimpliedPartnoDetails.part_status.toLowerCase()=="rejected" || this.selectedSimpliedPartnoDetails.part_status.toLowerCase()=="recalled" )
    {
      this.selectedSimpliedPartnoDetails.from_draft=true;

    }

    this.storePrimaryPart();
  }




      // this.store_primary_part();
        })

    }





  }


  /*pushdata():string
  {

return this.selectedsimpliedpartno;

  }*/


  storePrimaryPart()
  {
    console.log('selectedsimpliedpartnodetails',this.selectedSimpliedPartnoDetails)
    if(this.currentRegionCodeSub==='NA')
    {
      console.log('before save',this.selectedSimpliedPartnoDetails)

      sessionStorage.setItem('napackpropart', JSON.stringify(this.selectedSimpliedPartnoDetails));
      console.log('After save',this.selectedSimpliedPartnoDetails);

      console.log('After save test',sessionStorage.getItem('napackpropart'))
    }


    if(this.currentRegionCodeSub==='EU')
    {
      sessionStorage.setItem('eupackpropart', JSON.stringify(this.selectedSimpliedPartnoDetails));
    }



    if(this.currentRegionCodeSub==='AP')
    {
      sessionStorage.setItem('appackpropart', JSON.stringify(this.selectedSimpliedPartnoDetails));
    }



    if(this.currentRegionCodeSub==='SA')
    {
      sessionStorage.setItem('sapackpropart', JSON.stringify(this.selectedSimpliedPartnoDetails));
    }
    const r=this.transactionService.getpartno_packpro(this.currentRegionSub);
    console.log('selectedsimpliedpartnodetails-after save',r);

      // tslint:disable-next-line:variable-name
    let reg_supp_sub_url:string;
    reg_supp_sub_url=this.transactionService.getregionurlvalue(this.currentRegionSub);
    reg_supp_sub_url+='packagingprocess/proposalcreation';

    this.router.navigate([reg_supp_sub_url]);




  }




  applyFilter()
  {


  /*  if(document.getElementById("overlaysub") != null )
    {
    document.getElementById("overlaysub").style.display = "block";
    }
    if(document.getElementById("loadersub") != null )
    {
    document.getElementById("loadersub").style.display = "block";
    }*/


    this.loadersub=true;

    this.gridText='';
    console.log(this.suppCode);
    console.log(this.selectedPartPrefix);
    console.log(this.selectedPartBase);
    console.log(this.selectedPartSuffix);
    console.log(this.selectedPlantList);
    if(this.selectedPartPrefix!=null && this.selectedPartPrefix!=='')
    {
      this.partPrefix=this.selectedPartPrefix

    }
    else{
      this.partPrefix='all'
    }

    if(this.selectedPartBase!=null && this.selectedPartBase!=='')
    {
      this.partBase=this.selectedPartBase

    }
    else{
      this.partBase='all'
    }



    if(this.selectedPartSuffix!=null && this.selectedPartSuffix!=='')
    {
      this.partSuffix=this.selectedPartSuffix

    }
    else{
      this.partSuffix='all'
    }


    if(this.selectedPlantList.length!==0)
    {

      console.log('inside')
            if(this.selectedPlantList.length===this.plantCodeList.length)
            {
              this.plantcode='all';
            }

            else
            {

              this.plantcode='';
                // tslint:disable-next-line:variable-name
              let plt_count=0;
                // tslint:disable-next-line:variable-name prefer-for-of
              for(let p_plt=0; p_plt<this.selectedPlantList.length; p_plt++)
              {
                 this.plantcode+=this.selectedPlantList[p_plt].plantcode
                 plt_count+=1;
                 if(plt_count!==this.selectedPlantList.length)
                 {
                   this.plantcode+=',';
                 }
              }



            }

            console.log(this.plantcode);

    }

    else{

      this.plantcode='all';

    }




    // program

    if(this.selectedProgList.length!==0)
    {
            if(this.selectedProgList.length===this.progList.length)
            {
              this.programcode='all';
            }

            else
            {

              this.programcode='';
                // tslint:disable-next-line:variable-name
              let prg_count=0;
                // tslint:disable-next-line:prefer-for-of variable-name
              for(let p_prg=0; p_prg<this.selectedProgList.length; p_prg++)
              {
                 this.programcode+=this.selectedProgList[p_prg].programcode
                 prg_count+=1;
                 if(prg_count!==this.selectedProgList.length)
                 {
                   this.programcode+=',';
                 }
              }



            }

            console.log(this.programcode);

    }

    else{
      this.programcode='all';

    }



    // cos

    if(this.selectedCosList.length!==0)
    {
            if(this.selectedCosList.length===this.cosList.length)
            {
              this.cos='all';
            }

            else
            {

              this.cos='';
                // tslint:disable-next-line:variable-name
              let cos_count=0;
                // tslint:disable-next-line:prefer-for-of variable-name
              for(let p_cos=0; p_cos<this.selectedCosList.length; p_cos++)
              {
                 this.cos+=this.selectedCosList[p_cos].cos
                 cos_count+=1;
                 if(cos_count!==this.selectedCosList.length)
                 {
                   this.cos+=',';
                 }
              }



            }

            console.log(this.cos);

    }

    else{
      this.cos='all';
    }


    // trading company

    if(this.selectedTrdcmpy.length!==0)
    {
            if(this.selectedTrdcmpy.length===this.tradCmpyList.length)
            {
              this.trdcmpy='all';
            }

            else
            {

              this.trdcmpy='';
                // tslint:disable-next-line:variable-name
              let trdcmpy_count=0;

                // tslint:disable-next-line:prefer-for-of variable-name
              for(let p_trdcmpy=0; p_trdcmpy<this.selectedTrdcmpy.length; p_trdcmpy++)
              {
                if(this.selectedTrdcmpy[p_trdcmpy].tradingcogsdbcode==='[No Trading Company]')
                {
                  this.trdcmpy+='notradco'
                }
                else
                {
                 this.trdcmpy+=this.selectedTrdcmpy[p_trdcmpy].tradingcogsdbcode
                }
                 trdcmpy_count+=1;
                 if(trdcmpy_count!==this.selectedTrdcmpy.length)
                 {
                   this.trdcmpy+=',';
                 }
              }


            }


              console.log(this.trdcmpy);

    }

    else{
      this.trdcmpy='all'

    }


// status



if(this.selectedStatusList.length!==0)
{
        if(this.selectedStatusList.length===this.statusList.length)
        {
          this.status='New Part,Rejected,UnSubmitted,Recalled';
        }

        else
        {

          this.status='';
            // tslint:disable-next-line:variable-name
          let sts_count=0;
            // tslint:disable-next-line:prefer-for-of variable-name
          for(let p_sts=0; p_sts<this.selectedStatusList.length; p_sts++)
          {
             this.status+=this.selectedStatusList[p_sts].status;
             sts_count+=1;
             if(sts_count!==this.selectedStatusList.length)
             {
               this.status+=',';
             }
          }


        }


          console.log(this.status);


}

else{


  this.status='New Part,Rejected,UnSubmitted,Recalled';

}


if(this.subCurrentRoleid===1)
{


console.log(this.plantcode,this.programcode,this.cos,this.status,this.trdcmpy,this.partPrefix,this.partBase,this.partSuffix,this.suppCode)

// tslint:disable-next-line:max-line-length
this.transactionService.getsubmittergrid(this.plantcode,this.programcode,this.cos,this.status,this.trdcmpy,this.partPrefix,this.partBase,this.partSuffix,this.suppCode,this.subCdsid,1,this.currentRegionCodeSub).subscribe((subgridlist => {

  this.submittergridList=subgridlist



 // this.suppcode=[];
/* if(this.suppget.length==0)
 {
   this.valueback=true;
 }
 else{
 this.valueback=false;
}*/


console.log('successful- submitter grid output',this.submittergridList);

this.submitterGrid();

}))

}


else if(this.subCurrentRoleid===2)
{


  if(this.selectedSuppliergsdbcodeDesignsource!=null && this.selectedSuppliergsdbcodeDesignsource!=='')

  {
    this.suppliergsdbcodeDesignsource=this.selectedSuppliergsdbcodeDesignsource;
  }

  else{
    this.suppliergsdbcodeDesignsource='all'
  }


  console.log(this.designsourceCode);

this.supplierCountry='';

  if(this.selectedSupplierCountry.length!==0)
{
        if(this.selectedSupplierCountry.length===this.supplierCountryList.length)
        {
          this.supplierCountry='all';
        }

        else
        {

          this.supplierCountry='';
            // tslint:disable-next-line:variable-name
          let supp_count=0;
          console.log('sss',this.selectedSupplierCountry)
            // tslint:disable-next-line:prefer-for-of variable-name
          for(let p_sts=0; p_sts<this.selectedSupplierCountry.length; p_sts++)
          {
             this.supplierCountry+=this.selectedSupplierCountry[p_sts].cd_country
             supp_count+=1;
             if(supp_count!==this.selectedSupplierCountry.length)
             {
               this.supplierCountry+=',';
             }
          }


        }


          console.log('ooooooo',this.supplierCountry);


}
else{


  this.supplierCountry='all';

}

// temp
// this.suppliercountry="all";




  // service to get details


 /////// this.submittergridList=subgridlist



  // this.suppcode=[];
 /* if(this.suppget.length==0)
  {
    this.valueback=true;
  }
  else{
  this.valueback=false;
 }*/


 console.log("aaaaaa",this.plantcode,this.programcode,this.cos,this.status,this.trdcmpy,this.partPrefix,this.partBase,this.partSuffix,this.suppliergsdbcodeDesignsource,this.supplierCountry,this.designsourceCode,this.subCdsid,2,this.currentRegionCodeSub)

 this.transactionService.getsubmittergrid_designsource(this.plantcode,this.programcode,this.cos,this.status,this.trdcmpy,this.partPrefix,this.partBase,this.partSuffix,this.suppliergsdbcodeDesignsource,this.supplierCountry,this.designsourceCode,this.subCdsid,2,this.currentRegionCodeSub).subscribe((subgridlist => {

  this.submittergridList=subgridlist


 console.log('successful submitter grid output',this.submittergridList);

 this.submitterGrid();
 }))




}







    // service to call grid based on all the values
  }


  clearFilterValues()
  {
    this.selectedPartPrefix='';
    this.selectedPartBase='';
    this.selectedPartSuffix='';
    this.selectedPlantList=[];
    this.disableProgDrp=true;
    this.selectedProgList=[];
    this.selectedCosList=[];
    this.selectedStatusList=[];
    this.selectedTrdcmpy=[];
    this.selectedSuppliergsdbcodeDesignsource='';
    this.selectedSupplierCountry=[];

    this.applyFilter();
  }



  submitterGrid()
  {
    // document.getElementById("overlaysub").style.display = "none";
    // document.getElementById("loadersub").style.display = "none";


    this.loadersub=false;

    console.log(this.submittergridList);


if(this.submittergridList.length!==0 && this.submittergridList!=null)
{

  console.log(this.submittergridList[0].disable)
  this.gridText='';
  this.presentTable=true;


    // tslint:disable-next-line:variable-name
  let prefix_inter='';
    // tslint:disable-next-line:variable-name
  let base_inter='';
    // tslint:disable-next-line:variable-name
  let suffix_inter='';


  this.gridLength=this.submittergridList.length

// tslint:disable-next-line:variable-name
let grid_display_part_status='';
    // tslint:disable-next-line:prefer-for-of
  for(let isubtab=0;isubtab<this.submittergridList.length;isubtab++)
  {

    this.submittergridList[isubtab].part_pre_bas_suf='';
    if(this.submittergridList[isubtab].partprefix!=null)
    {
      prefix_inter=this.submittergridList[isubtab].partprefix
    }
    else
  {
    prefix_inter='         '
  }
  if(this.submittergridList[isubtab].partprefix!=null)
    {
      prefix_inter=this.submittergridList[isubtab].partprefix
    }
    else
  {
    prefix_inter='         '
  }
  if(this.submittergridList[isubtab].partbase!=null)
    {
      base_inter=this.submittergridList[isubtab].partbase
    }
    else
  {
    base_inter='         '
  }
  if(this.submittergridList[isubtab].partsuffix!=null)
  {
    suffix_inter=this.submittergridList[isubtab].partsuffix
  }
  else
{
  suffix_inter='         '
}
this.submittergridList[isubtab].part_pre_bas_suf= prefix_inter+'-'+base_inter+'-'+suffix_inter;

 if(this.submittergridList[isubtab].ngpp_part_status==='Submitted')
    {
    this.submittergridList[isubtab].non_submited=false;
    }

    else{
      this.submittergridList[isubtab].non_submited=true;
    }


    grid_display_part_status='';
    grid_display_part_status+=this.submittergridList[isubtab].ngpp_part_status;

    if(this.submittergridList[isubtab].isstatusurgent!=null && this.submittergridList[isubtab].isstatusurgent.toLowerCase()==='y')
    {
      grid_display_part_status+=' (Urgent)'
    }
    if(this.submittergridList[isubtab].isdeliquent!=null && this.submittergridList[isubtab].isdeliquent.toLowerCase()==='y')
    {
      grid_display_part_status+=' (Delinquent)'
    }


    this.submittergridList[isubtab].displaypartstatus=grid_display_part_status;


   // console.log(this.submittergridList[isubtab].ngpp_part_status);

    /*if(this.submittergridList[isubtab].ngpp_part_status=="1")
    {
    this.submittergridList[isubtab].ngpp_part_status_name="New Part"
    }

    else if(this.submittergridList[isubtab].ngpp_part_status=="2")
    {
    this.submittergridList[isubtab].ngpp_part_status_name="UnSubmitted"
    }

    else if(this.submittergridList[isubtab].ngpp_part_status=="3")
    {
    this.submittergridList[isubtab].ngpp_part_status_name="Rejected"
    }

    else if(this.submittergridList[isubtab].ngpp_part_status=="4")
    {
    this.submittergridList[isubtab].ngpp_part_status_name="Submitted"
    }*/

  }

this.cols = [
  { field: 'partnumbercommonized', header:'Simplified Part Number'},
  {field: 'part_pre_bas_suf', header:'Part Prefix-Base-Suffix'},
  {field:'partdesc',header:'Part Description'},
  { field: 'suppliergsdbcode', header:'Supplier GSDB'},
  { field: 'suppliercountrycodeISO', header:'Supplier Country'},
  { field: 'plantgsdbcode', header:'Plant'},
  { field: 'offsitesupplierid', header:'Bailment Supplier'},
  { field: 'tradingcogsdbcode', header:'Trading Company'},
  { field: 'programname', header:'Program(s)'},
  { field: 'parteffectiveindate', header:'Effective In Dates'},
  { field: 'cntrsuggestedownershpstrat', header:'COS'},
  {field: 'displaypartstatus', header:'Status'}
];
}


else{
  this.presentTable=false;
  this.cols=[];
  this.gridText='No Match Found'
  this.gridLength=0;
}

}

exportExcel() {
  import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.submittergridList);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'Part List');
  });
}

saveAsExcelFile(buffer: any, fileName: string): void {
  import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
          type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  });
}


  }

