/* tslint:disable */
import { Component, OnInit } from '@angular/core';



import { MenuItem, MegaMenuItem } from 'primeng/api';


import {TransactionService} from '../loginpage/Transaction.service';
import {fromdiplayvalues_all,  RevHistFromAPi,  RevisionObj,  searchProposalGridFromAPI, selec_prt_pro_details, subnewprt_latesbom, supplist,suppStatistics,useraccessrole} from '../loginpage/transactions';
import { Router,ActivatedRoute } from '@angular/router';


import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-packsupplier',
  templateUrl: './packsupplier.component.html',
  styleUrls: ['./packsupplier.component.css']
})
export class PacksupplierComponent implements OnInit {

  tabPackSupAdminMenuItems: MenuItem[];
  suppRegCode='';
  suppCdsid='';
  supplierCodeList:supplist[]=[];
  suppUserAccess:useraccessrole[]=[];
  selectedSupplier:supplist={suppliercode:''}
  activeItem: MenuItem;
  region:string;
  name:string;
  subDash:string;
  prg:string;
  cos:string;
  cont:string;
  regionadmin:string;
  subBoolean=false;
  savedSupplierCode:supplist[]=[];
  currentRoleid:number;


  suppStatistics:suppStatistics[]=[];
  suppStatisticsMigration:suppStatistics[]=[];
  migrationDataLoaded=false;


  approvedForProdCount='0';


  gridDisplayRecall:searchProposalGridFromAPI[]=[];


  gridTextRecall='';
  presentTableRecall=false;
  public colsRecall: any[];

  gridDisplayRecallMig:searchProposalGridFromAPI[]=[];


  gridTextRecallMig='';
  presentTableRecallMig=false;
  public colsRecallMig: any[];


  enableRecallDialog=false;
  enableRecallDialogMigration=false;

  enableRecallForm=false;
  selectedSimpliedPartnoDetailsRecall:selec_prt_pro_details={simplifiedpartno:'-',bompartmasterid:0,proposalid:0,plantgsdbcode:'',proposallastsaved:0,part_status:'',supplier_code:'',is_ee_proposal:false,proposallastsaved_ee:0,currentview_ee:false,from_draft:false,propsl_type:'',imp_part_status:''};


  cdProposal='0';
  currentStatus='';

  commentsRecall='';


  enableDialogNotificationRecall=false;



  proposalDetailsSupp:fromdiplayvalues_all;
  revisionObjSupp:RevisionObj[]=[];

  revHistFromServiceAPI:RevHistFromAPi[]=[];

  enableDetailsSupp=false;


  enableRecallButton=false;


  public colsSupp: any[];

  orderReport:any[];
  orderReportMigration:any[];


  new_part_list_count=0
  unsubmitted_part_list_count=0

  new_part_list_count_migration=0
  unsubmitted_part_list_count_migration=0




  constructor(private transactionService: TransactionService,private router:Router,private date:DatePipe,private route: ActivatedRoute,private messageService:MessageService) { }


  ngOnInit(): void {
    this.region=this.transactionService.getregion();
    this.suppRegCode=this.transactionService.getregioncode(this.region);
    this.suppCdsid=this.transactionService.getcdsid();
    // this.current_roleid=this.transactionService.getcurrentrole(this.region);
    this.currentRoleid=1;

    console.log(this.currentRoleid)


    if(this.region=='Asia Pacific & Africa')
    {
      sessionStorage.setItem('aprole', JSON.stringify(this.currentRoleid));
    }

    else if(this.region=='Europe')
    {
      sessionStorage.setItem('eurole', JSON.stringify(this.currentRoleid));
    }

    else if (this.region=='North America')

    {
      console.log(this.currentRoleid)
      sessionStorage.setItem('narole', JSON.stringify(this.currentRoleid));
    }

    else if(this.region=='South America')
    {
      sessionStorage.setItem('sarole', JSON.stringify(this.currentRoleid));
    }


    this.colsSupp=[
      { field: 'proposal_status', header: ' Stage in Approval Process' },
      { field: 'proposal_status_count', header: 'Number of Parts' },


    ]
    this.orderReport=[
      'Rejected',
      'Recalled',
      'Preliminary Approval',
      'Plant Approval',
      'Plant Final Approval',
      'Release For Evaluation',
      'Release for Production',
      'Approved for production',
      // "Plant Approver - Offsite",
      // "Plant Final Approver - Offsite",
      'Additional Approval'
      // "Migration Approval"



    ]

    this.orderReportMigration=[
      'Rejected',
      'Additional Approval',
      'Migration Approval'
    ]



    this.transactionService.storepage(this.region,'/packagingprocess/packsupplieradmin/packsupplier');




    // this.subDash='/'+this.name+'packagingprocess/packsupplier/submiterdashboard';
   /* this.prg='/'+this.name+'/testpage/prgadmin';
    this.cos='/'+this.name+'/testpage/cosadmin';
    this.cont='/'+this.name+'/testpage/app-contadmin';
    this.regionadmin='/'+this.name+'/testpage/regionadmin';*/
    let val=false;



   let sav_supp;
    sav_supp=this.transactionService.get_supplier(this.region);
    this.savedSupplierCode.push({suppliercode:sav_supp});

    console.log(this.savedSupplierCode);






// in future do gor global admin if its used
   /* this.transactionService.getUserAccessRole(this.supp_cdsid).subscribe(useraccess=>{
      console.log("useracces",useraccess);
      var position:number;
      this.suppUserAccess=useraccess;
      this.supplierCodeList=[]
      for(var temp=0;temp<this.suppUserAccess.length;temp++)
      {
        if(this.suppUserAccess[temp].role_id==this.current_roleid)
        {


          if(this.suppUserAccess[temp].cd_region==this.supp_reg_code)
          {



      var temp_supp;

      temp_supp=this.suppUserAccess[temp].cd_supplier.split(',')
      for(var i=0;i<temp_supp.length;i++)
      {
      this.supplierCodeList.push({suppliercode:temp_supp[i]})
      }

      break;






        }
      }
      }*/

      this.transactionService.getUsersupplierdesignsourceList(this.suppCdsid,this.suppRegCode,1).subscribe(supplist=>{
        console.log('getUsersupplierdesignsourceList api o/p',supplist);


        this.supplierCodeList=[];


        for(let i=0;i<supplist.length;i++)
      {
      this.supplierCodeList.push({suppliercode:supplist[i].supcode})
      }

      console.log(this.supplierCodeList);

      if(this.savedSupplierCode[0].suppliercode!=null  && this.savedSupplierCode[0].suppliercode!='')
      {
        // console.log("inside")
        for(let isupp=0;isupp<this.supplierCodeList.length;isupp++)
        {
          // console.log(this.savedSupplierCode[0].suppliercode);
          // console.log(this.supplierCodeList[isupp])
          if(this.savedSupplierCode[0].suppliercode==this.supplierCodeList[isupp].suppliercode)
          {
            // console.log("inside 2")
            this.selectedSupplier=this.savedSupplierCode[0];
            break;
          }
        }
      }

      else{

      this.selectedSupplier=this.supplierCodeList[0];
      }
      this.onsupplierchange();


  })



  }


  onsupplierchange()
  {
    console.log(this.selectedSupplier);

    this.new_part_list_count=0;
    this.unsubmitted_part_list_count=0;
    if(this.selectedSupplier != null)
    {
      this.transactionService.getsubmittergrid('all', 'all', 'all', 'New Part,UnSubmitted', 'all', 'all', 'all', 'all', this.selectedSupplier.suppliercode, this.suppCdsid, 1, this.suppRegCode).subscribe(prtgridlist => {
      console.log('getsubmittergrid api o/p',prtgridlist)

      if(prtgridlist != null && prtgridlist.length != 0)
      {

         let new_part_list=[]
         new_part_list = prtgridlist.filter(prt=>(prt.ngpp_part_status != null && prt.ngpp_part_status.toLowerCase()=='new part'));
         this.new_part_list_count=new_part_list.length;

         let unsubmitted_part_list=[]
         unsubmitted_part_list = prtgridlist.filter(prt=>(prt.ngpp_part_status != null && prt.ngpp_part_status.toLowerCase()=='unsubmitted'));
         this.unsubmitted_part_list_count=unsubmitted_part_list.length;

      }
      })
    }

    // migration
    this.new_part_list_count_migration=0;
    this.unsubmitted_part_list_count_migration=0;
    if(this.selectedSupplier != null)
    {
    this.transactionService.getsubmittergrid_migration('all', 'all', 'all', 'New Part,UnSubmitted', 'all', 'all', 'all', 'all', this.selectedSupplier.suppliercode, this.suppCdsid, 1, this.suppRegCode).subscribe(prtgridlist_migration => {
      console.log('getsubmittergrid_migration api o/p',prtgridlist_migration)

      if(prtgridlist_migration != null && prtgridlist_migration.length != 0)
      {

         let new_part_list_migration=[]
         new_part_list_migration = prtgridlist_migration.filter(prt=>(prt.ngpp_part_status != null && prt.ngpp_part_status.toLowerCase()=='new part'));
         this.new_part_list_count_migration=new_part_list_migration.length;

         let unsubmitted_part_list_migration=[]
         unsubmitted_part_list_migration = prtgridlist_migration.filter(prt=>(prt.ngpp_part_status != null && prt.ngpp_part_status.toLowerCase()=='unsubmitted'));
         this.unsubmitted_part_list_count_migration=unsubmitted_part_list_migration.length;

      }
    })
  }






    let route:string;
    if(this.selectedSupplier!=null)
    {
      this.get_supplier_report();
      this.migrationDataLoaded=false;
    if(this.suppRegCode=='NA')
    {
      sessionStorage.setItem('nasupplier', JSON.stringify(this.selectedSupplier.suppliercode));
    }


    if(this.suppRegCode=='EU')
    {
      sessionStorage.setItem('eusupplier', JSON.stringify(this.selectedSupplier.suppliercode));
    }



    if(this.suppRegCode=='AP')
    {
      sessionStorage.setItem('apsupplier', JSON.stringify(this.selectedSupplier.suppliercode));
    }



    if(this.suppRegCode=='SA')
    {
      sessionStorage.setItem('sasupplier', JSON.stringify(this.selectedSupplier.suppliercode));
    }

  }
  }


  get_supplier_report()
  {


    console.log('suppstat')
    console.log(this.selectedSupplier.suppliercode);
    this.suppStatistics=[];

    if(this.selectedSupplier != null)
    {
       this.transactionService.get_supplier_statistics(this.selectedSupplier.suppliercode,'normal',this.suppRegCode).subscribe(statistics=>{
        this.suppStatistics=[];
        console.log('get_supplier_statistics api o/p',statistics);
        if(statistics!=null)
        {
          this.approvedForProdCount='0';
          for(let j=0;j<this.orderReport.length;j++)
          {

            for (let labelkey in statistics)
            {
              // alert(labelkey)

              if (statistics.hasOwnProperty(labelkey)  )
              {
                if(this.orderReport[j].toLowerCase() == labelkey.toLowerCase())
                {

                  console.log(statistics[labelkey]);

                  if(labelkey.toLowerCase()!='approved for production' && labelkey.toLowerCase()!='rejection requested' )
                  {
                  this.suppStatistics.push({proposal_status:labelkey,proposal_status_count:statistics[labelkey]})
                  }
                  else  if(labelkey.toLowerCase()!='rejection requested' )
                  {
                    this.approvedForProdCount=statistics[labelkey];
                  }
                  break;
                }
              }

            }



          }
         console.log('List of statistics',this.suppStatistics);
        }

        if(!this.migrationDataLoaded)
        {
          this.loadStatisticsReportForMigration();
        }

      })
    }
  }

  loadStatisticsReportForMigration()
  {
    this.suppStatisticsMigration=[];
    this.migrationDataLoaded=true;
    if(this.selectedSupplier != null)
    {
      this.transactionService.get_supplier_statistics(this.selectedSupplier.suppliercode,'migration',this.suppRegCode).subscribe(statisticsMigration=>{
        this.suppStatisticsMigration=[];

        console.log('get_supplier_statistics api o/p',statisticsMigration);
        if(statisticsMigration!=null)
        {
          // this.approvedForProdCount="0";
          for(let j=0;j<this.orderReportMigration.length;j++)
          {

            for (let labelkey in statisticsMigration)
            {

              if (statisticsMigration.hasOwnProperty(labelkey)  )
              {
                if(this.orderReportMigration[j] == labelkey)
                {

                  console.log(statisticsMigration[labelkey]);

                  this.suppStatisticsMigration.push({proposal_status:labelkey,proposal_status_count:statisticsMigration[labelkey]})

                }
              }

            }



          }
        console.log('List of statistics',this.suppStatisticsMigration);
        }


      })
    }
  }


  getselectedstatus(rowdata:any)
  {
    console.log(rowdata);
    this.enableRecallButton=false;
    if(rowdata.proposal_status_count!=''&& rowdata.proposal_status_count!='0')
    {
       this.cdProposal='0'
      if(rowdata.proposal_status.toLowerCase()=='preliminary approval')
      {
        this.cdProposal='3';
        this.enableRecallButton=true;
      }
      else  if(rowdata.proposal_status.toLowerCase()=='additional approval')
      {
        this.cdProposal='4';
        this.enableRecallButton=true;
      }
      else  if(rowdata.proposal_status.toLowerCase()=='plant approval')
      {
        this.cdProposal='5';
        this.enableRecallButton=true;
      }
      else  if(rowdata.proposal_status.toLowerCase()=='plant final approval')
      {
        this.cdProposal='6';
        this.enableRecallButton=true;
      }
      else  if(rowdata.proposal_status.toLowerCase()=='release for evaluation')
      {
        this.cdProposal='7';
        this.enableRecallButton=true;
      }
      else  if(rowdata.proposal_status.toLowerCase()=='release for production')
      {
        this.cdProposal='8';
        this.enableRecallButton=true;
      }
      else  if(rowdata.proposal_status.toLowerCase()=='rejected')
      {
        this.cdProposal='11';
        this.enableRecallButton=false;

      }
      else  if(rowdata.proposal_status.toLowerCase()=='rejection requested')
      {
        this.cdProposal='13';
        this.enableRecallButton=false;

      }
      else  if(rowdata.proposal_status.toLowerCase()=='recalled')
      {
        this.cdProposal='15';
        this.enableRecallButton=false;

      }
      else  if(rowdata.proposal_status.toLowerCase()=='recall requested')
      {
        this.cdProposal='14';
        this.enableRecallButton=false;

      }


      else  if(rowdata.proposal_status.toLowerCase()=='submitted')
      {
        this.cdProposal='2';
        this.enableRecallButton=true;
      }


      else if(rowdata.proposal_status.toLowerCase()=='migration approval')
      {
        this.cdProposal='19';
        this.enableRecallButton=false;
      }


      this.currentStatus=rowdata.proposal_status


      this.get_partslist_on_status();








    }
  }

  getselectedstatusMigration(rowdata:any)
  {
    console.log(rowdata);
    this.enableRecallButton=false;
    if(rowdata.proposal_status_count!=''&& rowdata.proposal_status_count!='0')
    {
       this.cdProposal='0'

      if (rowdata.proposal_status.toLowerCase()=='rejected')
      {
        this.cdProposal='11';
        // this.enableRecallButton=false;

      }

      else  if(rowdata.proposal_status.toLowerCase()=='additional approval')
      {
        this.cdProposal='4';
        this.enableRecallButton=true;
      }


      else  if(rowdata.proposal_status.toLowerCase()=='submitted')
      {
        this.cdProposal='2';
        // this.enableRecallButton=true;
      }


      else if(rowdata.proposal_status.toLowerCase()=='migration approval')
      {
        this.cdProposal='19';
        // this.enableRecallButton=false;
      }


      this.currentStatus=rowdata.proposal_status


      this.get_partslist_on_status_Migration();








    }
  }



  getselectedstatus_apprv()

      {

         this.cdProposal='10';
         this.currentStatus='Approved For Production';
         this.enableRecallButton=false;




      this.get_partslist_on_status();




      }





  get_partslist_on_status()
  {
    if(this.selectedSupplier != null)
    {
    this.transactionService.getSuppDesgSearchProposalGridDetails(this.suppRegCode,this.cdProposal,'all','all','all','all',
    'all',this.selectedSupplier.suppliercode,'%20','all','all','all','all','all','all','all','all','all',this.suppCdsid,'Sup','normal').subscribe(griddetls_recall=>{


      console.log('getSuppDesgSearchProposalGridDetails api o/p',griddetls_recall);


      this.gridDisplayRecall=[];
      this.gridDisplayRecall=griddetls_recall;
      this.gridTextRecall=''


      let grid_display_part_status='';
      if(this.gridDisplayRecall!=null && this.gridDisplayRecall.length!=0)
      {

         for(let i=0;i<this.gridDisplayRecall.length;i++)
         {
        grid_display_part_status='';
        grid_display_part_status+=this.gridDisplayRecall[i].partstatus;

        if(this.gridDisplayRecall[i].isstatusurgent!=null && this.gridDisplayRecall[i].isstatusurgent.toLowerCase()=='y')
        {
          grid_display_part_status+=' (Urgent)'
        }
        if(this.gridDisplayRecall[i].isdeliquent!=null && this.gridDisplayRecall[i].isdeliquent.toLowerCase()=='y')
        {
          grid_display_part_status+=' (Delinquent)'
        }


        this.gridDisplayRecall[i].displaypartstatus=grid_display_part_status;
      }



        this.presentTableRecall=true;






        this.colsRecall=[
          { field: 'partnumbercommonized', header:'Simplified Part Number'},
          { field: 'partdesc', header:'Part Description'},
          { field: 'plantgsdbcode', header:'Plant GSDB Code'},
          { field: 'programname', header:'Program Name'},
          { field: 'cntr_ownrshp_strat_code', header:'Container Ownership Strategy'},
          { field: 'suppliergsdbcode', header:'Supplier GSDB Code'},
          { field: 'displaypartstatus', header:'Part Status'}
        ];

      }

      else
      {
        this.gridTextRecall='No Match Found';
        this.presentTableRecall=false;
        this.colsRecall=[];
      }


      this.enableRecallDialog=true;
      this.enableRecallDialogMigration=false;

    })
  }
  }

  get_partslist_on_status_Migration()
  {
    if(this.selectedSupplier != null)
    {
    this.transactionService.getSuppDesgSearchProposalGridDetails(this.suppRegCode,this.cdProposal,'all','all','all','all',
    'all',this.selectedSupplier.suppliercode,'%20','all','all','all','all','all','all','all','all','all',this.suppCdsid,'Sup','migration').subscribe(griddetls_recall=>{


      console.log('getSuppDesgSearchProposalGridDetails api o/p',griddetls_recall);


      this.gridDisplayRecallMig=[];
      this.gridDisplayRecallMig=griddetls_recall;
      this.gridTextRecallMig=''


      let grid_display_part_status='';
      if(this.gridDisplayRecallMig!=null && this.gridDisplayRecallMig.length!=0)
      {

         for(let i=0;i<this.gridDisplayRecallMig.length;i++)
         {
        grid_display_part_status='';
        grid_display_part_status+=this.gridDisplayRecallMig[i].partstatus;

        if(this.gridDisplayRecallMig[i].isstatusurgent!=null && this.gridDisplayRecallMig[i].isstatusurgent.toLowerCase()=='y')
        {
          grid_display_part_status+=' (Urgent)'
        }
        if(this.gridDisplayRecallMig[i].isdeliquent!=null && this.gridDisplayRecallMig[i].isdeliquent.toLowerCase()=='y')
        {
          grid_display_part_status+=' (Delinquent)'
        }


        this.gridDisplayRecallMig[i].displaypartstatus=grid_display_part_status;
      }



        this.presentTableRecallMig=true;






        this.colsRecallMig=[
          { field: 'partnumbercommonized', header:'Simplified Part Number'},
          { field: 'partdesc', header:'Part Description'},
          { field: 'plantgsdbcode', header:'Plant GSDB Code'},
          { field: 'programname', header:'Program Name'},
          { field: 'cntr_ownrshp_strat_code', header:'Container Ownership Strategy'},
          { field: 'suppliergsdbcode', header:'Supplier GSDB Code'},
          { field: 'displaypartstatus', header:'Part Status'}
        ];

      }

      else
      {
        this.gridTextRecallMig='No Match Found';
        this.presentTableRecallMig=false;
        this.colsRecallMig=[];
      }


      this.enableRecallDialogMigration=true;
      this.enableRecallDialog=false;

    })
  }
  }




  get_partdetails_form(partData:searchProposalGridFromAPI)
  {


    console.log('get_partdetails_form fn',partData);
  // console.log(partData.partnumbercommonized);


  // var sub_exist_proposalid=0;

  // service to get proposal id





  this.selectedSimpliedPartnoDetailsRecall.bompartmasterid=partData.bompartmasterid;
this.selectedSimpliedPartnoDetailsRecall.simplifiedpartno=partData.partnumbercommonized;
this.selectedSimpliedPartnoDetailsRecall.proposalid=partData.id_proposal;
this.selectedSimpliedPartnoDetailsRecall.plantgsdbcode=partData.plantgsdbcode;
this.selectedSimpliedPartnoDetailsRecall.proposallastsaved=4;
this.selectedSimpliedPartnoDetailsRecall.part_status=partData.partstatus.trim();
this.selectedSimpliedPartnoDetailsRecall.supplier_code=partData.suppliergsdbcode;
this.selectedSimpliedPartnoDetailsRecall.proposallastsaved_ee=1;
this.selectedSimpliedPartnoDetailsRecall.is_ee_proposal=false;
this.selectedSimpliedPartnoDetailsRecall.currentview_ee=false;
this.selectedSimpliedPartnoDetailsRecall.from_draft=false;
// improvement changes to be revisited
// changed for migration approval
this.selectedSimpliedPartnoDetailsRecall.propsl_type=partData.propsl_type;
this.selectedSimpliedPartnoDetailsRecall.imp_part_status=null




  this.store_primary_part_recall();




  }


  store_primary_part_recall()
{
if(this.suppRegCode=='NA')
{

  sessionStorage.setItem('napackpropart', JSON.stringify(this.selectedSimpliedPartnoDetailsRecall));
}


if(this.suppRegCode=='EU')
{
  sessionStorage.setItem('eupackpropart', JSON.stringify(this.selectedSimpliedPartnoDetailsRecall));
}



if(this.suppRegCode=='AP')
{
  sessionStorage.setItem('appackpropart', JSON.stringify(this.selectedSimpliedPartnoDetailsRecall));
}



if(this.suppRegCode=='SA')
{
  sessionStorage.setItem('sapackpropart', JSON.stringify(this.selectedSimpliedPartnoDetailsRecall));
}


this.enableDetailsSupp=false;


this.enableRecallForm=true;






}


removePartSessionStorageSupplierRecall()
{
  this.transactionService.destroyPartnoSessionStorage(this.suppRegCode);
}


recall_proposal()
{
  console.log('recall_proposal fn partno',this.selectedSimpliedPartnoDetailsRecall);
  console.log('recall_proposal fn comments',this.commentsRecall);


  let cur_date_recall;
  let cur_date_transform_recall
  cur_date_recall=new Date();
  cur_date_transform_recall=this.date.transform(cur_date_recall, 'yyyy-MM-dd HH:mm:ss');


  console.log(cur_date_transform_recall);

  let alphabet_pres_recall:boolean;
  alphabet_pres_recall=/[a-z]/i.test(this.commentsRecall);


  console.log(alphabet_pres_recall)

    if(this.commentsRecall==null|| alphabet_pres_recall==false)
  {
    // toast stating enter comments
    this.enableDialogNotificationRecall=true;
    this.messageService.add({severity:'error', summary: 'Error', detail: 'Please enter comments to recall packaging'});
  }
  else{
    //api to recall packagining proposal
    var replace_comments=this.commentsRecall.replace('|','/').replace('`',"'")
    this.commentsRecall=replace_comments;

    this.transactionService.request_recall(this.selectedSimpliedPartnoDetailsRecall.proposalid,this.suppCdsid,cur_date_transform_recall,this.commentsRecall).subscribe(recall_out=>{
      console.log(recall_out);
      if(recall_out.toLowerCase()=='success')
      {
        this.messageService.add({severity:'success', summary: 'Succcess', detail: 'Packaging Recall Requested'});
        this.enableRecallForm=false;
        this.get_partslist_on_status();

      }
      else
      {
        this.messageService.add({severity:'error', summary: 'Error', detail: 'Please try again, to recall packaging'});

      }
    })
  }


}

ok_notification_dialog_recall()
{
  this.enableDialogNotificationRecall=false;
}


on_close_recall_dialog()
{
  // console.log("oncloserecall")
 this.get_supplier_report();
}

on_close_recall_dialog_Mig()
{
  this.loadStatisticsReportForMigration();
}


view_revision_history_supp()
{


this.enableDetailsSupp=true;
console.log('revision history');
/*
this.transactionService.loadtransacmasterdetails(this.selectedSimpliedPartnoDetailsRecall.proposalid).subscribe(transdet_supp=>{

  console.log("loadtransacmasterdetails api o/p",transdet_supp);
 this.proposalDetailsSupp=transdet_supp;


 console.log(this.proposalDetailsSupp.revision_hstry);
  var revision_arr=[];
  this.revisionObjSupp=[];

  if(this.proposalDetailsSupp.revision_hstry!=null)

 //if(transdet!=null)
  {
     revision_arr=this.proposalDetailsSupp.revision_hstry.split('|')
    //revision_arr=transdet.split('|')
    console.log(revision_arr)


         for(var i=0;i<revision_arr.length;i++)
         {
           var rev_arr=[];
           var rev_htm_str="";
           rev_arr=revision_arr[i].split('`');




           if(rev_arr.length==7)
           {

           rev_htm_str="<b>"+rev_arr[5]+";"+rev_arr[1]+"("+rev_arr[2]+")"+"</b>"+"<br>"+rev_arr[3]+"-"+rev_arr[4];
           if(rev_arr[6]!="-")
           {
             rev_htm_str+="<br>"+"Comments-"+rev_arr[6];
           }
          }


          this.revisionObjSupp.push({rev_string:revision_arr[i],rev_html_string:rev_htm_str})

         }

  }


  console.log(this.revisionObjSupp);


  this.revisionObjSupp=this.revisionObjSupp.reverse();
  console.log("Revision History Display",this.revisionObjSupp)

})*/

this.transactionService.getRevisionHistoryByProposalId(this.selectedSimpliedPartnoDetailsRecall.proposalid).subscribe(revHistArray=>{

  this.revHistFromServiceAPI=[];
  this.revHistFromServiceAPI=revHistArray;
  this.revisionObjSupp=[];
  console.log('getRevisionHistoryByProposalId API o/p ',this.revHistFromServiceAPI);



  if(this.revHistFromServiceAPI != null && this.revHistFromServiceAPI.length != 0)
  {
    for(let i=0; i<this.revHistFromServiceAPI.length; i++)
    {
      let rev_htm_str='';


      if(this.revHistFromServiceAPI[i].evnttime != null && this.revHistFromServiceAPI[i].usrname != null)
      {
        rev_htm_str='<b>'+this.revHistFromServiceAPI[i].evnttime+';'+this.revHistFromServiceAPI[i].usrname+'('+this.revHistFromServiceAPI[i].email+')'+'</b>'+'<br>'+this.revHistFromServiceAPI[i].proposalstatuscurr+'-'+this.revHistFromServiceAPI[i].apprvractn;
      }
      else
      {
        rev_htm_str='<b>'+this.revHistFromServiceAPI[i].proposalstatuscurr+'-'+this.revHistFromServiceAPI[i].apprvractn+'</b>';
      }
      if(this.revHistFromServiceAPI[i].comments != null && this.revHistFromServiceAPI[i].comments != '-')
      {
        rev_htm_str+='<br>'+'Comments-'+this.revHistFromServiceAPI[i].comments;
      }

      this.revisionObjSupp.push({rev_obj:this.revHistFromServiceAPI[i],rev_html_string:rev_htm_str})
    }
    console.log('Revision History Display',this.revisionObjSupp)
  }

})
}


close_revision_history_supp()
{
this.enableDetailsSupp=false;

}



  redirect_submitter()
  {

    sessionStorage.setItem('proposaltypefor','normal')
    let reg_supp_sub_url:string;
    reg_supp_sub_url=this.transactionService.getregionurlvalue(this.region);
    reg_supp_sub_url+='packagingprocess/packsupplieradmin/submiterdashboard';
    this.router.navigate([reg_supp_sub_url]);
  }


  redirect_migration_submitter()
  {
    sessionStorage.setItem('proposaltypefor','migration')
    let reg_supp_sub_url:string;
    reg_supp_sub_url=this.transactionService.getregionurlvalue(this.region);
    reg_supp_sub_url+='packagingprocess/packsupplieradmin/migrationsubmitter';

    this.router.navigate([reg_supp_sub_url]);
  }


  redirect_supplier_profile()
  {


    let reg_supp_supp_url:string;
    reg_supp_supp_url=this.transactionService.getregionurlvalue(this.region);
    reg_supp_supp_url+='packagingprocess/packsupplieradmin/editsupplierprofile';

    this.router.navigate([reg_supp_supp_url]);

  }

  redirect_container_request_screen()
  {


    let reg_supp_supp_url:string;
    reg_supp_supp_url=this.transactionService.getregionurlvalue(this.region);
    reg_supp_supp_url+='packagingprocess/packsupplieradmin/containerrequest';

    this.router.navigate([reg_supp_supp_url]);

  }

  redirect_container_request_status_screen()
  {
    let reg_supp_supp_url:string;
    reg_supp_supp_url=this.transactionService.getregionurlvalue(this.region);
    reg_supp_supp_url+='packagingprocess/packsupplieradmin/containerreqsts';

    this.router.navigate([reg_supp_supp_url]);
  }
  redirect_packproposal()
  {

  }

  issubmitterdashboard():boolean{

    // this.sub_boolean=true;
    let user_roles=[];
    user_roles=this.transactionService.getroles(this.suppRegCode)

    let func_present=false;

    if(user_roles!=null && user_roles.length!=0)
    {
      // console.log("User Roles",user_roles)
    for(let ni=0;ni<user_roles.length;ni++)
    {
      if(user_roles[ni].roleid==this.currentRoleid)
      {
        // console.log(user_roles[ni].roles)
        let user_subroles=[];
        user_subroles=user_roles[ni].roles

        for(let nj=0;nj<user_subroles.length;nj++)
        {
          if(user_subroles[nj].sysfunc_id==5)
          {
            func_present=true
            break;
          }
        }

        break;
      }
    }
  }


  // func_present=true;


    return func_present;
  }



  activateMenu(event) {
    console.log('click on ' + event.tabpackadminMenuItem.label + ' tab!');
}

}
