/* tslint:disable */
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { TransactionService } from '../loginpage/Transaction.service';
import { ActivePlants, programsbasedonplants, programsbasedonplantsforsearchprop, archivePackagingSummaryDataOutput, archivePackagingSummaryHeader, suppListGppArchivePackagingSummaryRep, plantListGppArchivePackagingSummaryRep, programListGppArchivePackagingSummaryRep } from '../loginpage/transactions';

import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Component({
  selector: 'app-gpparchivepackagingsummaryreport',
  templateUrl: './gpparchivepackagingsummaryreport.component.html',
  styleUrls: ['./gpparchivepackagingsummaryreport.component.css']
})
export class GpparchivepackagingsummaryreportComponent implements OnInit {

  region:string;
  regionCode:string;
  cdsid:string;

  plantcode='';
  selected_plant_code:plantListGppArchivePackagingSummaryRep={plantgsdbcode:''};
  selected_plant_codes_list:plantListGppArchivePackagingSummaryRep[]=[];
  plantcodelist:plantListGppArchivePackagingSummaryRep[]=[];
  all_selected_on_plant_code=false;
  plantcodeselected=false;

  programsList:programListGppArchivePackagingSummaryRep[]=[];
  programListalongwithPlants:programListGppArchivePackagingSummaryRep[]=[];
  selected_programs_list:programListGppArchivePackagingSummaryRep[]=[];
  programcodes='';
  all_selected_on_program=false;

  supplierList:suppListGppArchivePackagingSummaryRep[]=[];
  selected_supplier:suppListGppArchivePackagingSummaryRep[]=[];
  suppliercode='';
  all_selected_on_supplier_code=false;


  currentDate:Date;
  selectedStartDateArchivePackagingSummary:Date;

  startDateArchivePackagingSummaryString:string;
  plantCodeToAPI:string;
  programCodeToAPI:string;
  supplierCodeToAPI:string;
  endDateArchivePackagingSummaryString:string;

  archivePackagingSummaryReportList:archivePackagingSummaryDataOutput[]=[];
  archivePackagingSummaryHeaderList:archivePackagingSummaryHeader[]=[];
  errorLoadingReportArchivePackagingSummary='';
  successMessageArchivePackagingSummary='';

  enableArchivePackagingSummaryExportButton=true;

  loaderArchivePackagingSummaryexp=false;
  constructor(private transactionService:TransactionService,private date:DatePipe) { }

  ngOnInit(): void {
    this.region=this.transactionService.getregion();
    this.regionCode=this.transactionService.getregioncode(this.region);
    this.cdsid=this.transactionService.getcdsid();

    this.currentDate=new Date();

    this.transactionService.getPlantCodesGppPackagingsummaryRep(this.regionCode).subscribe(plantco=>{
        this.plantcodelist=[];
      if(plantco != null && plantco.length != 0)
      {
     // let resultplant:plantListGppArchivePackagingSummaryRep[]=plantco;
     // let plantcodetemp:plantListGppArchivePackagingSummaryRep[]=[];
        // -- No all case for plant
     // plantcodetemp.push({'cd_plant':'0','plantGSDBCode':'ALL','plant_name':'All','plant_type_name':'All'});
      /*for(var h=0;h<resultplant.length;h++)
      {
        plantcodetemp.push({'cd_plant':resultplant[h].cd_plant,'plantGSDBCode':resultplant[h].plantGSDBCode,'plant_name':resultplant[h].plant_name,'plant_type_name':resultplant[h].plant_type_name})
      }*/
      this.plantcodelist=plantco
      this.selected_plant_codes_list=[];
      console.log('plantcodes from backend',this.plantcodelist);
    }
    })





  }


  onhide_plant_codes()
  {
    this.all_selected_on_plant_code=false;
    this.plantcode='';

    /* checking whether length of selected list is less than one */
    let plantslen=false;
    if(this.selected_plant_codes_list.length==this.plantcodelist.length-1)
    {
      plantslen=true;
    }

    for(let ptc=0;ptc<this.selected_plant_codes_list.length;ptc++)
    {
      this.plantcode+=this.selected_plant_codes_list[ptc].plantgsdbcode;
      if(ptc < this.selected_plant_codes_list.length-1)
      {
        this.plantcode+=',';
      }

      // -- No all case for plant
      /*if(this.selected_plant_codes_list[ptc].plantGSDBCode=='ALL')
      {
        this.all_selected_on_plant_code=true;
        this.plantcode="all";
        break;
      }*/
    }

    /* If selectedvariables length is one less than total length but all is not selected
       which means except all remaining are selected so make
       all_selected_on_plant_code as true and make code  send all to backend */
    // -- No all case for plant
    /*if(this.all_selected_on_plant_code==false && plantslen==true)
    {
      this.plantcode="all";
      this.all_selected_on_plant_code=true;
    }*/
    console.log('plant codes in Hide',this.plantcode,typeof(this.plantcode));

    if(this.plantcode.length>0)
    {
      this.plantcodeselected=true;
      this.loadprogramcodes();
      // this.loadSupplierCodes();
      console.log('plantcode selected is true')
    }
    else
    {
      this.plantcodeselected=false;
      this.programsList=[];
      this.selected_programs_list=[];
      this.programListalongwithPlants=[];
      this.programcodes='';
      this.suppliercode='';
      this.selected_supplier=[];
      this.supplierList=[];
    }
  }

  change_plant_codes()
  {
    let all_selected_on_plant_code=false;
    let lenlessthan1_plant_code=false;
    this.plantcode='';

    // When plants is changed programs variable shd be emptied and
    // when click outside after selecting plants,these variables will have loaded data
    this.programsList=[];
    this.selected_programs_list=[];
    this.programcodes='';
    this.programListalongwithPlants=[];

    this.supplierList=[];
    this.suppliercode='';
    this.selected_supplier=[];


    if(this.selected_plant_codes_list.length==this.plantcodelist.length-1)
    {
      lenlessthan1_plant_code=true;
    }
    for(let j=0;j<this.selected_plant_codes_list.length;j++)
    {
      if(this.selected_plant_codes_list[j].plantgsdbcode=='ALL')
      {
        all_selected_on_plant_code=true;
      }
    }

    if(all_selected_on_plant_code==false && lenlessthan1_plant_code==true)
    {
      all_selected_on_plant_code=true;
    }
    // -- No all case for plant
    /*if(all_selected_on_plant_code==true)
    {
      this.plantcode="all";
      this.selected_plant_codes_list=[];
      this.selected_plant_codes_list.push({'cd_plant':'0','plantGSDBCode':'ALL','plant_name':'All','plant_type_name':'All'});
    }*/
  }

  plantCodeChanged()
  {
    this.plantcodeselected=true;
    this.programsList=[];
    this.selected_programs_list=[];
    this.programcodes='';
    this.programListalongwithPlants=[];

    this.supplierList=[];
    this.suppliercode='';
    this.selected_supplier=[];
    this.plantcode=this.selected_plant_code.plantgsdbcode;
    this.loadprogramcodes();
  }

  loadprogramcodes()
  {
    const tempobj:programListGppArchivePackagingSummaryRep[]=[];
    const regval=this.transactionService.getregioncode(this.region);
    this.transactionService.getProgramCodesGppPackagingsummaryRep(regval,this.plantcode).subscribe(progs=>{
      this.programsList=[];
      this.programListalongwithPlants=[];
      this.programsList=progs;
      if(this.programsList.length>0)
      {
        tempobj.push({plantgsdbcode:'',programname:'All',programnamewithplant:'All'});
      }
      for(let l=0;l<this.programsList.length;l++)
      {
        let programwasalreadypresent=false;
        if(tempobj.length!=0)
        {
          if(tempobj.some(o=>o.programname.toLowerCase() == this.programsList[l].programname.toLowerCase()))
          {
            const index=tempobj.findIndex(object=>{return object.programname.toLowerCase()==this.programsList[l].programname.toLowerCase()});
            const newvalue=tempobj[index].programnamewithplant.slice(0,tempobj[index].programnamewithplant.length-1) ;
            tempobj[index].programnamewithplant=newvalue+', '+this.programsList[l].plantgsdbcode+')';
            programwasalreadypresent=true;
          }
        }
        if(!programwasalreadypresent)
        {
          tempobj.push({programname:this.programsList[l].programname,
            programnamewithplant:this.programsList[l].programname+' ('+this.programsList[l].plantgsdbcode+')',
            plantgsdbcode:this.programsList[l].plantgsdbcode});
        }

      }
      this.programListalongwithPlants=JSON.parse(JSON.stringify(tempobj));

      console.log('programList',this.programsList)
      console.log('programListalongwithPlants',this.programListalongwithPlants);
      this.loadSupplierCodes();
    })
  }

  onhide_program_codes()
  {
    this.all_selected_on_program=false;
    this.programcodes='';

    /* checking whether length of selected list is less than one */
    let prglen=false;
    if(this.selected_programs_list.length==this.programListalongwithPlants.length-1)
    {
      prglen=true;
    }

    for(let prgi=0;prgi<this.selected_programs_list.length;prgi++)
    {
      this.programcodes+=this.selected_programs_list[prgi].programname;
      if(prgi < this.selected_programs_list.length-1)
      {
        this.programcodes+=',';
      }

      if(this.selected_programs_list[prgi].programname=='All')
      {
        this.all_selected_on_program=true;
        this.programcodes='all';
        break;
      }
    }

    /* If selectedvariables length is one less than total length but all is not selected
       which means except all remaining are selected so make
       all_selected_on_program as true and make code  send all to backend */

    if(this.all_selected_on_program==false && prglen==true)
    {
      this.programcodes='all';
      this.all_selected_on_program=true;
    }
    console.log('program codes in Hide',this.programcodes,typeof(this.programcodes));

    this.loadSupplierCodes();




    // to add code changes to get supplier code ;
  }

  change_prgm_codes()
  {
    let all_selected_on_program=false;
    let lenlessthan1_prg_code=false;
    this.programcodes='';

    this.suppliercode='';
    this.selected_supplier=[];
    this.supplierList=[];

    if(this.selected_programs_list.length==this.programListalongwithPlants.length-1)
    {
      lenlessthan1_prg_code=true;
    }
    for(let j=0;j<this.selected_programs_list.length;j++)
    {
      if(this.selected_programs_list[j].programname=='All')
      {
        all_selected_on_program=true;
      }
    }

    if(all_selected_on_program==false && lenlessthan1_prg_code==true)
    {
      all_selected_on_program=true;
    }

    if(all_selected_on_program==true)
    {
      this.programcodes='all';
      this.selected_programs_list=[];
      const regval=this.transactionService.getregioncode(this.region);
      this.selected_programs_list.push({plantgsdbcode:'',programname:'All',programnamewithplant:'All'});
    }
  }


  loadSupplierCodes()
  {
    let programCodeTOSupplierAPI=''
    let plantCodeToSupplierAPI='';

    if(this.plantcode.length == 0 )
    {
      plantCodeToSupplierAPI='all';
    }
    else
    {
      plantCodeToSupplierAPI=this.plantcode;
    }

    if(this.programcodes.length == 0)
    {
      programCodeTOSupplierAPI='all'
    }
    else
    {
      programCodeTOSupplierAPI=this.programcodes;
    }

    this.transactionService.getSupplierBasedOnPlantProgramGppArchive(plantCodeToSupplierAPI,programCodeTOSupplierAPI).subscribe(suplis=>{
      console.log('suppliers',suplis,this.regionCode);
      this.supplierList=[];
      if(suplis != null && suplis.length != 0)
      {
        this.supplierList=suplis;
        let supplierListTemp=[];
        supplierListTemp=this.supplierList;
        this.supplierList=[];
        this.supplierList.push({suppliercode:'ALL'});
        this.supplierList.push(...supplierListTemp);
      }
    })
  }

  onhide_supplier_codes()
  {
    this.all_selected_on_supplier_code=false;
    this.suppliercode='';

    /* checking whether length of selected list is less than one */
    let supplen=false;
    if(this.selected_supplier.length == this.supplierList.length-1)
    {
      supplen=true;
    }

    for(let ptc=0;ptc<this.selected_supplier.length;ptc++)
    {
      this.suppliercode+=this.selected_supplier[ptc].suppliercode;
      if(ptc < this.selected_supplier.length-1)
      {
        this.suppliercode+=',';
      }

      if(this.selected_supplier[ptc].suppliercode.toLowerCase() == 'all')
      {
        this.all_selected_on_supplier_code=true;
        this.suppliercode='all';
        break;
      }
    }

    /* If selectedvariables length is one less than total length but all is not selected
       which means except all remaining are selected so make
       all_selected_on_plant_code as true and make code  send all to backend */

    if(this.all_selected_on_supplier_code == false && supplen == true)
    {
      this.suppliercode='all';
      this.all_selected_on_supplier_code=true;
    }
    console.log('supplier codes in Hide',this.suppliercode,typeof(this.suppliercode));

  }

  change_supplier_codes()
  {
    let all_selected_on_supplier_code=false;
    let lenlessthan1_supplier_code=false;
    this.suppliercode='';

    // When plants is changed programs variable shd be emptied and
    // when click outside after selecting plants,these variables will have loaded data


    if(this.selected_supplier.length == this.supplierList.length-1)
    {
      lenlessthan1_supplier_code=true;
    }
    for(let j=0;j<this.selected_supplier.length;j++)
    {
      if(this.selected_supplier[j].suppliercode.toLowerCase() == 'all')
      {
        all_selected_on_supplier_code=true;
      }
    }

    if(all_selected_on_supplier_code == false && lenlessthan1_supplier_code == true)
    {
      all_selected_on_supplier_code=true;
    }

    if(all_selected_on_supplier_code == true)
    {
      this.suppliercode='all';
      this.selected_supplier=[];
      this.selected_supplier.push({suppliercode:'ALL'});
    }
  }

  generateArchivePackagingSummaryReport()
  {

    this.enableArchivePackagingSummaryExportButton=false;
    this.loaderArchivePackagingSummaryexp=true;
    if(this.selectedStartDateArchivePackagingSummary != null)
    {
      this.startDateArchivePackagingSummaryString=this.date.transform(this.selectedStartDateArchivePackagingSummary,'yyyy-MM-dd HH:mm:ss');
      this.endDateArchivePackagingSummaryString=this.date.transform(new Date(),'yyyy-MM-dd HH:mm:ss')

    }
    else
    {
      this.startDateArchivePackagingSummaryString='all';
      this.endDateArchivePackagingSummaryString='all';
    }


    if(this.plantcode=='' )
    {
      this.plantCodeToAPI='all';
    }
    else
    {
      this.plantCodeToAPI=this.plantcode;
    }

    if(this.programcodes=='')
    {
      this.programCodeToAPI='all';
    }
    else
    {
      this.programCodeToAPI=this.programcodes;
    }

    if(this.suppliercode == '')
    {
      this.supplierCodeToAPI='all'
    }
    else
    {
      this.supplierCodeToAPI=this.suppliercode
    }

    console.log('before api call','startdate',this.startDateArchivePackagingSummaryString,'plantcode',this.plantCodeToAPI,'programcode',this.programCodeToAPI,'suppliercode',this.supplierCodeToAPI,'enddate',this.endDateArchivePackagingSummaryString)

    this.archivePackagingSummaryReportList=[];
    this.archivePackagingSummaryHeaderList=[];
    this.errorLoadingReportArchivePackagingSummary='';
    this.successMessageArchivePackagingSummary='Generating Report, Please Wait ...'

    /*this.transactionService.getArchivePackagingSummaryReport(this.plantCodeToAPI,this.programCodeToAPI,this.supplierCodeToAPI,this.startDateArchivePackagingSummaryString,this.endDateArchivePackagingSummaryString,this.cdsid,this.regionCode).subscribe((packagingsummrep)=>{

      console.log('getArchivePackagingSummaryReport api o/p',packagingsummrep);
      if(packagingsummrep != null && packagingsummrep.length != 0)
      {
      this.archivePackagingSummaryReportList=packagingsummrep;
    */
      this.transactionService.getArchivepackagingSummaryReportHeader().subscribe((packagingsummrephed)=>{

        console.log('getArchivepackagingSummaryReportHeader api o/p',packagingsummrephed);
        if(packagingsummrephed != null && packagingsummrephed.length != 0)
        {
          this.archivePackagingSummaryHeaderList=packagingsummrephed;
          console.log('before archivePackagingSummaryDataToExcel fn call - ','header',this.archivePackagingSummaryHeaderList,'data',this.archivePackagingSummaryReportList);
          this.downloadPackagingSummaryReportData();
          //this.archivePackagingSummaryDataToExcel();
        }
        else
        {
          this.enableArchivePackagingSummaryExportButton=true;
          console.log('Archive Packaging Summary Report - No header to load')
          this.successMessageArchivePackagingSummary='';
          this.errorLoadingReportArchivePackagingSummary='Error occured While Generating Report';
          this.loaderArchivePackagingSummaryexp=false;
        }
      },
        (error) => {                              // Error callback
          console.error('Error in Header API call - getArchivepackagingSummaryReportHeader',error)
          this.successMessageArchivePackagingSummary='';
          this.errorLoadingReportArchivePackagingSummary = 'Error Occured in Getting Archive Packaging Summary Report, Please Try Again';
          this.enableArchivePackagingSummaryExportButton=true;
          this.loaderArchivePackagingSummaryexp=false;
        }
      )
      /*}
      else
      {
        this.enableArchivePackagingSummaryExportButton=true;
        console.log('Archive Packaging Summary Report - No Data Found To Generate Report')
        this.successMessageArchivePackagingSummary='';
        this.errorLoadingReportArchivePackagingSummary='No Data Found To Generate Report'
        this.loaderArchivePackagingSummaryexp=false
      }

    },
        (error) => {                              // Error callback
          console.error('Error in Archive Packaging Summary Report Data API call - getArchivePackagingSummaryReport',error)
          this.successMessageArchivePackagingSummary='';
          this.errorLoadingReportArchivePackagingSummary = 'Error Occured in Getting Archive Packaging Summary Report, Please Try Again';
          this.enableArchivePackagingSummaryExportButton=true;
          this.loaderArchivePackagingSummaryexp=false;
        }
    )*/
  }

  async downloadPackagingSummaryReportData()
  {
    var i=0;
    var tempobject:any;

    do
    {
     if(i>=0)
     {
      let result:any= await this.transactionService.getArchivePackagingSummaryReport(this.plantCodeToAPI,this.programCodeToAPI,this.supplierCodeToAPI,this.startDateArchivePackagingSummaryString,this.endDateArchivePackagingSummaryString,this.cdsid,this.regionCode,i);

      console.log('getArchivePfepDataExportReport api o/p',result);
      tempobject=result.map(o => Object.assign(o)) 
      i=tempobject[tempobject.length-1].seq_no;
     this.archivePackagingSummaryReportList.push(...tempobject.map(o => Object.assign(o)));
      
      console.log(i,tempobject.length);
     }
     else
     {
      i=-1;
     }

    }while(i>=0 && tempobject.length==1000);

    this.archivePackagingSummaryDataToExcel();
  }

  clearFilterArchivePackagingSummaryReport()
  {
    this.selected_plant_codes_list=[];
    this.selected_plant_code={plantgsdbcode:''};
    this.plantcode='';
    this.plantcodeselected=false;
    this.selected_programs_list=[];
    this.programcodes='';
    this.selected_supplier=[];
    this.suppliercode='';
    this.selectedStartDateArchivePackagingSummary=null;
    this.startDateArchivePackagingSummaryString='';
    this.endDateArchivePackagingSummaryString='';
    this.archivePackagingSummaryReportList=[];
    this.archivePackagingSummaryHeaderList=[];
    this.errorLoadingReportArchivePackagingSummary='';
    this.successMessageArchivePackagingSummary=''
    this.enableArchivePackagingSummaryExportButton=true;
  }

  archivePackagingSummaryDataToExcel()
  {
    console.log('inside archivePackagingSummaryDataToExcel fn ',this.archivePackagingSummaryReportList,this.archivePackagingSummaryHeaderList)
    const title = 'Archive - Packaging Summary Report';
    const header = this.archivePackagingSummaryHeaderList;
    const data = this.archivePackagingSummaryReportList;

    console.log(header,data);

    // Create a workbook with a worksheet
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Archive - Packaging Summary Report');

    // Adding Header Row
    const arr=[];
    header.forEach((d: any) => {
      // console.log(d);
      arr.push(
        {header:d.col_header,key:d.col_field_name,color:d.col_color}
        )
    })

    worksheet.columns = arr;
    console.log(arr);
   const headerRow = worksheet.getRow(1)
    console.log(headerRow)
    headerRow.eachCell((cell, number) => {
      // console.log(cell)
      const findindex=arr.findIndex(ele=>ele.header.trim() == cell.value.toString().trim())

      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: arr[findindex].color},
        bgColor: { argb: '' },
      };
      cell.font = {
        name:'Arial',
        bold: true,
        color: { argb: '000000' },
        size: 10,
      };
      cell.alignment = { vertical: 'bottom', horizontal: 'center', textRotation: 90 , wrapText: true };
      cell.border = {

        left: {style:'thin'},
        bottom: {style:'thin'},
        right: {style:'thin'}
      };
    });

    // Adding Data
    data.forEach((d: any) => {
      worksheet.addRow(d);
    });
    console.log(worksheet)

    // Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, title + '.xlsx');

      this.successMessageArchivePackagingSummary='Report Generated Successfully !!!'
      this.enableArchivePackagingSummaryExportButton=true;
      this.loaderArchivePackagingSummaryexp=false;
    });
  }

}
