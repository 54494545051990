/* tslint:disable */
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TransactionService } from '../loginpage/Transaction.service';

@Component({
  selector: 'app-plantappoffsite',
  templateUrl: './plantappoffsite.component.html',
  styleUrls: ['./plantappoffsite.component.css']
})
export class PlantappoffsiteComponent implements OnInit {



  region:string;
  regionCode:string;



  viewPltAprOffsite=false;



  currentRoleidPltOffsite:number;

  current_routeid=0;


  constructor(private transactionService: TransactionService,private router:Router,private date:DatePipe,private route: ActivatedRoute) { }

  ngOnInit(): void {


    this.region=this.transactionService.getregion();
    this.regionCode=this.transactionService.getregioncode(this.region);


        this.currentRoleidPltOffsite=19;

        console.log(this.currentRoleidPltOffsite)


        if(this.region=='Asia Pacific & Africa')
        {
          sessionStorage.setItem('aprole', JSON.stringify(this.currentRoleidPltOffsite));
        }

        else if(this.region=='Europe')
        {
          sessionStorage.setItem('eurole', JSON.stringify(this.currentRoleidPltOffsite));
        }

        else if (this.region=='North America')

        {
          console.log(this.currentRoleidPltOffsite)
          sessionStorage.setItem('narole', JSON.stringify(this.currentRoleidPltOffsite));
        }

        else if(this.region=='South America')
        {
          sessionStorage.setItem('sarole', JSON.stringify(this.currentRoleidPltOffsite));
        }



        // this.transactionService.storepage(this.region,"/packagingprocess/packsupplieradmin/packsupplier");

        this.current_routeid=257;

        console.log(this.current_routeid)


        if(this.region=='Asia Pacific & Africa')
        {
          sessionStorage.setItem('aprouteid', JSON.stringify(this.current_routeid));
        }

        else if(this.region=='Europe')
        {
          sessionStorage.setItem('eurouteid', JSON.stringify(this.current_routeid));
        }

        else if (this.region=='North America')

        {

          sessionStorage.setItem('narouteid', JSON.stringify(this.current_routeid));
        }

        else if(this.region=='South America')
        {
          sessionStorage.setItem('sarouteid', JSON.stringify(this.current_routeid));
        }


       this.viewPltAprOffsite=true;


  }

}
