/* tslint:disable */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginpageComponent } from '../loginpage/loginpage.component';
import { TransactionService } from '../loginpage/Transaction.service';

@Component({
  selector: 'app-loginotheruser',
  templateUrl: './loginotheruser.component.html',
  styleUrls: ['./loginotheruser.component.css']
})
export class LoginotheruserComponent implements OnInit {

  userID=''
  invalidUserText=''
  loaderanotherlogin=false
  test=''

  constructor(private transactionService:TransactionService,private router:Router,private login:LoginpageComponent) { }

  ngOnInit(): void {
    this.userID=''
    this.invalidUserText=''
    this.loaderanotherlogin=false
    this.test='https://web.fsp.ford.com/gtc/docs/globalexppkg.pdf';
  }

  loginOtherUser()
  {
    console.log('UserId',this.userID)
    this.invalidUserText=''
    if(this.userID != null && this.userID.trim() != '' )
    {
      this.loaderanotherlogin=true

    this.transactionService.user_details(this.userID.trim().toLowerCase()).subscribe((UserDetails => {
      this.loaderanotherlogin=false

      console.log('UserDetails',UserDetails);


      // && this.UserDetails.isactive.toLowerCase()=="yes"
      if(UserDetails!=null )
      {
        this.login.loginByID(this.userID);
        this.invalidUserText=''
      }

      else
      {
        this.invalidUserText='User doesnt have access to NGPP , please enter valid cdsid'
      }
      }))
    }
    else
    {
      this.invalidUserText='Please enter valid cdsid'
    }
  }

  onBackOrCloselogin()
  {
    console.log('inside close function');

    const cur_region=this.transactionService.getregion();
    if(cur_region != null )
    {
      let reg_map_url='';
      reg_map_url=this.transactionService.getregionurlvalue(cur_region);
      reg_map_url+='packagingprocessdashboard/packagingprocesshome';

      this.router.navigate([reg_map_url]);

    }

    else
    {
      this.router.navigate(['/exitpage']);
    }
  }
}
