/* tslint:disable */
import { Component, OnInit } from '@angular/core';
import { TransactionService } from '../loginpage/Transaction.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  region:string;
  regionCode:string;
  cdsid:string;

  viewPfepDataExport=false;
  viewPackagingSummaryReport=false;
  viewForm1271DataReport=false;
  viewLOMReport=false;
  viewSupplierContact:boolean=false;

  constructor(private transactionService:TransactionService) { }

  ngOnInit(): void {

    this.region=this.transactionService.getregion();
    this.regionCode=this.transactionService.getregioncode(this.region);
    this.cdsid=this.transactionService.getcdsid();

    this.viewPfepDataExport=false;
    this.viewPackagingSummaryReport=false;
    this.viewLOMReport=false;
    this.viewForm1271DataReport=false;
    this.viewSupplierContact=false;

  }

  onClickPfepExportDataReport()
  {
    // sessionStorage.setItem('testwork', JSON.stringify("reg"));
    this.viewPackagingSummaryReport=false;
    this.viewForm1271DataReport=false;
    this.viewPfepDataExport=true;
    this.viewLOMReport=false;
    this.viewSupplierContact=false;
  }

  onClickPackagingSummaryReport()
  {
    this.viewPfepDataExport=false;
    this.viewForm1271DataReport=false;
    this.viewPackagingSummaryReport=true;
    this.viewLOMReport=false;
    this.viewSupplierContact=false;
  }

  onClickForm1271DataReport()
  {
    this.viewPfepDataExport=false;
    this.viewPackagingSummaryReport=false;
    this.viewForm1271DataReport=true;
    this.viewLOMReport=false;
    this.viewSupplierContact=false;
  }

  onClickLOMReport()
  {
    this.viewPfepDataExport=false;
    this.viewPackagingSummaryReport=false;
    this.viewForm1271DataReport=false;
    this.viewLOMReport=true;
    this.viewSupplierContact=false;
  }

  onClickSupplierContact()
  {
    this.viewPfepDataExport=false;
    this.viewPackagingSummaryReport=false;
    this.viewForm1271DataReport=false;
    this.viewLOMReport=false;
    this.viewSupplierContact=true;
  }

  enableSupplierContact():boolean
  {
    // As per plan Only Ensuring is the user is ford user which is done in authguard 
    //no roles or functionality validation required 
    return true;
  }

  enablePfepExportData():boolean
  {
    let user_roles=[];
    user_roles=this.transactionService.getroles(this.regionCode)

    let func_present=false;
    console.log(user_roles)

    if(user_roles!=null && user_roles.length!=0)
    {
      // console.log("User Roles",user_roles)
      for(let ni=0;ni<user_roles.length;ni++)
      {
        let sys_func_arr=[];
						for(let k=0;k<user_roles[ni].roles.length;k++)
						{

							sys_func_arr.push(user_roles[ni].roles[k].sysfunc_id.toString())
						}
						if(sys_func_arr.findIndex(ele=>ele == '33') > -1)
						{
							func_present=true;
							break
						}
      }
    }
    return func_present;
  }

  enablePackagingsummaryReport():boolean
  {
      // this.sub_boolean=true;
      let user_roles=[];
    user_roles=this.transactionService.getroles(this.regionCode)

    let func_present=false;

    if(user_roles!=null && user_roles.length!=0)
    {
      // console.log("User Roles",user_roles)
      for(let ni=0;ni<user_roles.length;ni++)
      {
        let sys_func_arr=[];
						for(let k=0;k<user_roles[ni].roles.length;k++)
						{

							sys_func_arr.push(user_roles[ni].roles[k].sysfunc_id.toString())
						}
						if(sys_func_arr.findIndex(ele=>ele == '34') > -1)
						{
							func_present=true;
							break
						}
      }
    }
    return func_present;
  }

  enableForm1271DataReport():boolean
  {
    let user_roles=[];
    user_roles=this.transactionService.getroles(this.regionCode)

    let func_present=false;
    console.log(user_roles)

    if(user_roles!=null && user_roles.length!=0)
    {
      // console.log("User Roles",user_roles)
      for(let ni=0;ni<user_roles.length;ni++)
      {
        let sys_func_arr=[];
						for(let k=0;k<user_roles[ni].roles.length;k++)
						{

							sys_func_arr.push(user_roles[ni].roles[k].sysfunc_id.toString())
						}
						if(sys_func_arr.findIndex(ele=>ele == '32') > -1)
						{
							func_present=true;
							break
						}
      }
    }
    return func_present;
  }

  enableLOMReport():boolean
  {
    let user_roles=[];
    user_roles=this.transactionService.getroles(this.regionCode)

    let func_present=false;
    console.log(user_roles)

    if(user_roles!=null && user_roles.length!=0)
    {
      // console.log("User Roles",user_roles)
      for(let ni=0;ni<user_roles.length;ni++)
      {
        let sys_func_arr=[];
						for(let k=0;k<user_roles[ni].roles.length;k++)
						{

							sys_func_arr.push(user_roles[ni].roles[k].sysfunc_id.toString())
						}
						if(sys_func_arr.findIndex(ele=>ele == '32') > -1)
						{
							func_present=true;
							break
						}
      }
    }
    return func_present;
  }

}
