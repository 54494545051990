/* tslint:disable */
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng';
import { TransactionService } from '../loginpage/Transaction.service';
import { ContainerReqStatus, ContainerReqStatusGridSUppDesg, DBIACompAssociatedToContainerRequests, DBIAContainerRequests } from '../loginpage/transactions';

@Component({
  selector: 'app-containerreqsts',
  templateUrl: './containerreqsts.component.html',
  styleUrls: ['./containerreqsts.component.css']
})
export class ContainerreqstsComponent implements OnInit {


  region_code='';
  region='';
  cdsid='';

  containerRequestStatusGrid:ContainerReqStatusGridSUppDesg[]=[]

  selectedRequestNumber=0;




  public cols:any[]=[]

  includeAllRegion=false;

  statusFilterList:ContainerReqStatus[]=[{statusLabel:'Active',status:'A'},{statusLabel:'Completed',status:'C'}]
  selectedStatus:ContainerReqStatus={statusLabel:'',status:''}



  viewRequestDetails=false;


  cntrDetailsOfRequest:DBIAContainerRequests={

    function:'A',
    cntr_suff_ind:'',
    exp_refundable:'',
    cntr_active_ind:'',
    cntr_base:'',
    cntr_suffix:'',
    cntr_desc:'',
    card_call:'',
    cntr_mstr_catg:'',
    cntr_typ_desc:'',
    ownrshp_strat_code:'',
    ownrshp_catg:'',
    cntr_grp_desc:'',
    cntr_exterior_l:0,
    cntr_exterior_w:0,
    cntr_exterior_h:0,
    collapsed_ht:0.0,
    nest_ratio:0,
    cntr_interior_l:0,
    cntr_interior_w:0,
    cntr_interior_h:0,
    cntr_tare_wt:0,
    cntr_capacity:0,
    cntr_per_layer:0,
    max_layers_shpg_unit:0,
    max_cntr_per_shpg_unit:0,
    cntr_matr_desc:'',
    cntr_drawing_no:'',
    shpg_unit_exterior_l:0,
    shpg_unit_exterior_w:0,
    shpg_unit_exterior_h:0,
    shpg_unit_nstdorflded_ht:0,
    shpg_unit_tare_wt:0,
    shpg_unit_stack_ht:0,
    warehouse_stack_ht:0,
    service_life:'',
    currency_desc:'',
    purchase_cost:0,
    rental_issue_cost:0,
    sup_rntl_dly_cost:0,
    ford_rntl_dly_cost:0,
    return_rntl_cost:0,
    tooling_dev_cost:0,
    extr_cntr_dsgn_img_ind:'No',
    intr_cntr_dsgn_img_ind:'No',
    shpg_unit_img_ind:'No',
    cad_img_ind:'No',
    img_notes:'',
    cd_region:'',
    iscontainercolapsble:'',
    cd_plant:'',
    cntr_qt_per_min_su:0,
    nest_ratio_1:0,
    ford_cntct:'',
    cntct_phone:'',
    id_user:'',
    ts_last_update:'',
    req_stat:'',
    uoM:'E',

    dbia_req_no: 0,

    return_code: '',
    return_mesg: '',
    cd_cntr_ownr: '',

    cd_cntr:0,
    typ_no:0
  }


  get_measure='lbs/inch'
  prev_get_measure='lbs/inch'

  containerRequestStsScreenRoleid=0


  screenName='';

  noRoleSelectedDialog=false;
  noRoleContent:string=null;
  noSupDialog=false;
  content:string=null;
  noDesignsourceDialog=false;
  contentDesignsource:string=null;


  userCode:string=null;

  loadercontreqsts=false

  isDataFound='';

  constructor(private transactionService:TransactionService,private router:Router,private messageService:MessageService,private date:DatePipe) { }

  ngOnInit(): void {


    this.region=this.transactionService.getregion();
    this.region_code=this.transactionService.getregioncode(this.region);
    this.cdsid=this.transactionService.getcdsid();

    this.containerRequestStsScreenRoleid=this.transactionService.getcurrentrole(this.region)

    console.log('containerRequestScreenRoleid',this.containerRequestStsScreenRoleid);

    this.viewRequestDetails=false;
    this.isDataFound='';

    if(this.containerRequestStsScreenRoleid==null && this.containerRequestStsScreenRoleid!=1 && this.containerRequestStsScreenRoleid!=2)
    {
      this.noRoleSelectedDialog=true;
      this.noRoleContent='No role selected '
    }


    if(this.containerRequestStsScreenRoleid==1)
    {
      this.userCode=this.transactionService.get_supplier(this.region);
      this.screenName="Supplier Container Request Status"
      console.log(this.userCode);
    }

    else if(this.containerRequestStsScreenRoleid==2)
    {
      this.userCode=this.transactionService.get_designsource(this.region);
      this.screenName="Design Source Container Request Status"
      console.log(this.userCode);
    }

    if(this.containerRequestStsScreenRoleid==1)
    {
      if(this.userCode==null)
      {
        this.noSupDialog=true;
        this.content='Please select supplier';
      }
    }

    else if(this.containerRequestStsScreenRoleid==2)
    {
      if(this.userCode==null)
      {
        this.noDesignsourceDialog=true;
        this.contentDesignsource='Please select Design Source'
      }
    }

   if(this.containerRequestStsScreenRoleid!=null && (this.containerRequestStsScreenRoleid==1 || this.containerRequestStsScreenRoleid==2) && this.userCode!=null)
    {

    this.selectedStatus=this.statusFilterList[0];
    this.includeAllRegion=false;
    this.populateContainerRequestStatusGrid()
    }



  }

  okNoSupDialog()
  {
    console.log(this.region)
    let reg_url:string
    reg_url=this.transactionService.getregionurlvalue(this.region);
    reg_url+='packagingprocess/packsupplieradmin/packsupplier';

    this.router.navigate([reg_url]);

  }


  okNoDesignsourceDialog()
  {
    console.log(this.region)
    let reg_url:string
    reg_url=this.transactionService.getregionurlvalue(this.region);
    reg_url+='packagingprocess/packdesignsourceadmin/packdesignsource';

    this.router.navigate([reg_url]);

  }



  okNoRoleDialog()
  {
    let url
    url=this.transactionService.getregionurlvalue(this.region);
    url+='packagingprocessdashboard'
    this.router.navigate([url]);

  }

  goToSuppMainPage()
  {
    let reg_supp_url:string;
    reg_supp_url=this.transactionService.getregionurlvalue(this.region);
    if(this.containerRequestStsScreenRoleid==1)
    {
    reg_supp_url+='packagingprocess/packsupplieradmin/packsupplier';
    }
    else if(this.containerRequestStsScreenRoleid==2){
      reg_supp_url+='packagingprocess/packdesignsourceadmin/packdesignsource';

    }
    this.router.navigate([reg_supp_url]);

  }



  onChangeStatus()
  {
    this.populateContainerRequestStatusGrid();
  }

  onchangeIncludeRegion()
  {
    this.populateContainerRequestStatusGrid();
  }

  // called only on apply filter
  populateContainerRequestStatusGrid()
  {
    this.viewRequestDetails=false;
    this.loadercontreqsts=true;
    this.isDataFound='';

    let regioncodeToApi

    if(this.includeAllRegion == false)
    {
      regioncodeToApi=this.region_code.toUpperCase();
    }
    else
    {
      regioncodeToApi='all';
    }
    // service to pass this input and get array as output
    this.transactionService.getContainerRequestStsListbysuppDesg(this.userCode,regioncodeToApi,this.selectedStatus.status).subscribe(containerreqstatuslist=>{

    this.containerRequestStatusGrid=[];

    this.containerRequestStatusGrid=containerreqstatuslist;
    console.log('containerRequestStatusGrid',this.containerRequestStatusGrid)
    if(this.containerRequestStatusGrid != null && this.containerRequestStatusGrid.length != 0)
    {
      this.isDataFound='';

      for(let i=0; i<this.containerRequestStatusGrid.length; i++)
      {
        this.containerRequestStatusGrid[i].cntr_base_suffix=this.containerRequestStatusGrid[i].cntr_base;
        if(this.containerRequestStatusGrid[i].cntr_suffix != null && this.containerRequestStatusGrid[i].cntr_suffix.trim() != '')
        {
          this.containerRequestStatusGrid[i].cntr_base_suffix+='-'+this.containerRequestStatusGrid[i].cntr_suffix;
        }
      }
    }
    else
    {
      this.isDataFound='No Data Found'
    }
    this.loadercontreqsts=false;

    this.cols=[
      {field:'cntr_base_suffix',header:'Container Number'},
      { field: 'cntr_desc', header:'Description'},
      { field: 'ownrshp_strat_code', header:'Ownership Strategy'},
      { field: 'exp_refundable', header:'E / R'},
      {field:'cd_region', header:'Region'},
      { field: 'req_stat', header:'Request Status'}
      ]
    })




  }


  cntrDetailsInit()
  {
    this.cntrDetailsOfRequest={

      function:'A',
      cntr_suff_ind:'',
      exp_refundable:'',
      cntr_active_ind:'',
      cntr_base:'',
      cntr_suffix:'',
      cntr_desc:'',
      card_call:'',
      cntr_mstr_catg:'',
      cntr_typ_desc:'',
      ownrshp_strat_code:'',
      ownrshp_catg:'',
      cntr_grp_desc:'',
      cntr_exterior_l:0,
      cntr_exterior_w:0,
      cntr_exterior_h:0,
      collapsed_ht:0.0,
      nest_ratio:0,
      cntr_interior_l:0,
      cntr_interior_w:0,
      cntr_interior_h:0,
      cntr_tare_wt:0,
      cntr_capacity:0,
      cntr_per_layer:0,
      max_layers_shpg_unit:0,
      max_cntr_per_shpg_unit:0,
      cntr_matr_desc:'',
      cntr_drawing_no:'',
      shpg_unit_exterior_l:0,
      shpg_unit_exterior_w:0,
      shpg_unit_exterior_h:0,
      shpg_unit_nstdorflded_ht:0,
      shpg_unit_tare_wt:0,
      shpg_unit_stack_ht:0,
      warehouse_stack_ht:0,
      service_life:'',
      currency_desc:'',
      purchase_cost:0,
      rental_issue_cost:0,
      sup_rntl_dly_cost:0,
      ford_rntl_dly_cost:0,
      return_rntl_cost:0,
      tooling_dev_cost:0,
      extr_cntr_dsgn_img_ind:'No',
      intr_cntr_dsgn_img_ind:'No',
      shpg_unit_img_ind:'No',
      cad_img_ind:'No',
      img_notes:'',
      cd_region:'',
      iscontainercolapsble:'',
      cd_plant:'',
      cntr_qt_per_min_su:0,
      nest_ratio_1:0,
      ford_cntct:'',
      cntct_phone:'',
      id_user:'',
      ts_last_update:'',
      req_stat:'',
      uoM:'E',

      dbia_req_no: 0,

      return_code: '',
      return_mesg: '',
      cd_cntr_ownr: '',

      cd_cntr:0,
      typ_no:0
    }

  }


  openRequestDetailsByClickingCntr(rowData:ContainerReqStatusGridSUppDesg)
  {
    this.selectedRequestNumber=rowData.dbia_req_no;
    this.enableCntrRequestDetails()
  }

  openRequestDetailsByClickingTableButton(rowData:ContainerReqStatusGridSUppDesg)
  {
    this.selectedRequestNumber=rowData.dbia_req_no;
    this.enableCntrRequestDetails()
  }






  enableCntrRequestDetails()
  {
    // service to get request details by passing req number
    this.cntrDetailsInit();
    console.log('Request number to get container details',this.selectedRequestNumber);
    this.transactionService.getContainerRequestDetailsByReqNo(this.selectedRequestNumber).subscribe(savecntrdetails=>{
      console.log('Container details by req no',savecntrdetails)
    this.viewRequestDetails=true;

    this.cntrDetailsOfRequest=savecntrdetails

    console.log('cntrdetails',this.cntrDetailsOfRequest)



    if(this.cntrDetailsOfRequest.uoM == null || this.cntrDetailsOfRequest.uoM.trim() == '')
    {
      this.cntrDetailsOfRequest.uoM = 'E'
    }

    if(this.cntrDetailsOfRequest.uoM.toLowerCase() == 'm')
    {

      this.get_measure='kg/cm'
      this.prev_get_measure='kg/cm'
    }
    else
    {
      this.get_measure='lbs/inch'
      this.prev_get_measure='lbs/inch'
    }


    console.log('containerdetailsfromgrid',this.cntrDetailsOfRequest);
  })
  }

  closeCntrReqDetails()
  {
    this.viewRequestDetails=false;
  }




}
