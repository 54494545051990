/* tslint:disable */
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng';
import { TransactionService } from '../loginpage/Transaction.service';
import { plantlist, containerMasterCategory, containerTypeCode, containerOwnershipList, containerGroupActiveList, containerMaterialList, containerCurrencyList, DBIAContainerRequestsStatusGrid, DBIAReqStatus, DBIAContainerRequests, DBIACompAssociatedToContainerRequests } from '../loginpage/transactions';

@Component({
  selector: 'app-dbiareqsts',
  templateUrl: './dbiareqsts.component.html',
  styleUrls: ['./dbiareqsts.component.css']
})

export class DbiareqstsComponent implements OnInit {

  region_code='';
  region='';
  cdsid='';

  containerRequestStatusGrid:DBIAContainerRequestsStatusGrid[]=[]

  enableApproveRejectRequest=false;
  selectedRequestNumber=0;



  edit_measure=''
  prev_edit_measure=''

  public cols:any[]=[]


  fromRejectButton=false;
  confirmPopUp=false


  startDateString:string;
  endDateString:string;

  currentDate:Date;
  selectedStartDate:Date;
  selectedEndDate:Date;

  statusFilterList:DBIAReqStatus[]=[{statusLabel:'All',status:'all'},{statusLabel:'New',status:'new'},{statusLabel:'Processed',status:'processed'},{statusLabel:'Success',status:'success'},{statusLabel:'Failed',status:'failed'}]
  selectedStatus:DBIAReqStatus={statusLabel:'',status:''}



  viewRequestDetails=false;


  cntrDetailsOfRequest:DBIAContainerRequests={

    function:'A',
    cntr_suff_ind:'',
    exp_refundable:'',
    cntr_active_ind:'',
    cntr_base:'',
    cntr_suffix:'',
    cntr_desc:'',
    card_call:'',
    cntr_mstr_catg:'',
    cntr_typ_desc:'',
    ownrshp_strat_code:'',
    ownrshp_catg:'',
    cntr_grp_desc:'',
    cntr_exterior_l:0,
    cntr_exterior_w:0,
    cntr_exterior_h:0,
    collapsed_ht:0.0,
    nest_ratio:0,
    cntr_interior_l:0,
    cntr_interior_w:0,
    cntr_interior_h:0,
    cntr_tare_wt:0,
    cntr_capacity:0,
    cntr_per_layer:0,
    max_layers_shpg_unit:0,
    max_cntr_per_shpg_unit:0,
    cntr_matr_desc:'',
    cntr_drawing_no:'',
    shpg_unit_exterior_l:0,
    shpg_unit_exterior_w:0,
    shpg_unit_exterior_h:0,
    shpg_unit_nstdorflded_ht:0,
    shpg_unit_tare_wt:0,
    shpg_unit_stack_ht:0,
    warehouse_stack_ht:0,
    service_life:'',
    currency_desc:'',
    purchase_cost:0,
    rental_issue_cost:0,
    sup_rntl_dly_cost:0,
    ford_rntl_dly_cost:0,
    return_rntl_cost:0,
    tooling_dev_cost:0,
    extr_cntr_dsgn_img_ind:'No',
    intr_cntr_dsgn_img_ind:'No',
    shpg_unit_img_ind:'No',
    cad_img_ind:'No',
    img_notes:'',
    cd_region:'',
    iscontainercolapsble:'',
    cd_plant:'',
    cntr_qt_per_min_su:0,
    nest_ratio_1:0,
    ford_cntct:'',
    cntct_phone:'',
    id_user:'',
    ts_last_update:'',
    req_stat:'',
    uoM:'E',

    dbia_req_no: 0,

    return_code: '',
    return_mesg: '',
    cd_cntr_ownr: '',

    cd_cntr:0,
    typ_no:0
  }


  get_measure='lbs/inch'
  prev_get_measure='lbs/inch'


  componentAssociatedToContainerList:DBIACompAssociatedToContainerRequests[]=[]
  enableCompDialog=false;
  public compCols:any[]


  comp_present_string='';
  pag_val=false;


  current_routeid=0

  constructor(private transactionService:TransactionService,private messageService:MessageService,private date:DatePipe) { }

  ngOnInit(): void {


    this.region=this.transactionService.getregion();
    this.region_code=this.transactionService.getregioncode(this.region);
    this.cdsid=this.transactionService.getcdsid();


    this.currentDate=new Date();


    this.current_routeid=202;

    console.log(this.current_routeid)


    if(this.region=='Asia Pacific & Africa')
    {
      sessionStorage.setItem('aprouteid', JSON.stringify(this.current_routeid));
    }

    else if(this.region=='Europe')
    {
      sessionStorage.setItem('eurouteid', JSON.stringify(this.current_routeid));
    }

    else if (this.region=='North America')

    {

      sessionStorage.setItem('narouteid', JSON.stringify(this.current_routeid));
    }

    else if(this.region=='South America')
    {
      sessionStorage.setItem('sarouteid', JSON.stringify(this.current_routeid));
    }




  }


  // called only on apply filter
  populateDbiaRequestStatusGrid()
  {
    this.viewRequestDetails=false;
    if(this.selectedStartDate != null)
    {
      this.startDateString=this.date.transform(this.selectedStartDate,'yyyy-MM-dd')
    }
    else
    {
      this.startDateString=''
    }

    if(this.selectedEndDate != null)
    {
      this.endDateString=this.date.transform(this.selectedEndDate,'yyyy-MM-dd')
    }
    else
    {
      this.endDateString=''
    }
    console.log('startdate',this.startDateString,'enddate',this.endDateString,'selectedStatus',this.selectedStatus.status)


    // service to pass this input and get array as output
    this.transactionService.getContainerRequestListOnDateStatus(this.selectedStatus.status,this.region_code,this.startDateString,this.endDateString).subscribe(containerreqstatuslist=>{

    this.containerRequestStatusGrid=[];

    this.containerRequestStatusGrid=containerreqstatuslist;
    console.log('containerRequestStatusGrid',this.containerRequestStatusGrid)
    if(this.containerRequestStatusGrid != null && this.containerRequestStatusGrid.length != 0)
    {
      for(let i=0; i<this.containerRequestStatusGrid.length; i++)
      {
        this.containerRequestStatusGrid[i].cntr_base_suffix=this.containerRequestStatusGrid[i].cntr_base;
        if(this.containerRequestStatusGrid[i].cntr_suffix != null && this.containerRequestStatusGrid[i].cntr_suffix.trim() != '')
        {
          this.containerRequestStatusGrid[i].cntr_base_suffix+='-'+this.containerRequestStatusGrid[i].cntr_suffix;
        }
      }
    }


    this.cols=[
      {field:'cntr_base_suffix',header:'Container Number'},
      { field: 'cntr_desc', header:'Description'},
      { field: 'ownrshp_strat_code', header:'Ownership Strategy'},
      { field: 'exp_refundable', header:'E / R'},
      { field: 'req_stat', header:'Request Status'}
      ]
    })




  }


  disableApplyFilter()
  {

    if(this.selectedStatus == null || this.selectedStatus.status.trim() == '' || this.selectedStartDate == null || this.selectedEndDate == null)
    {
      return true
    }
    else
    {
      return false
    }

  }


  cntrDetailsInit()
  {
    this.cntrDetailsOfRequest={

      function:'A',
      cntr_suff_ind:'',
      exp_refundable:'',
      cntr_active_ind:'',
      cntr_base:'',
      cntr_suffix:'',
      cntr_desc:'',
      card_call:'',
      cntr_mstr_catg:'',
      cntr_typ_desc:'',
      ownrshp_strat_code:'',
      ownrshp_catg:'',
      cntr_grp_desc:'',
      cntr_exterior_l:0,
      cntr_exterior_w:0,
      cntr_exterior_h:0,
      collapsed_ht:0.0,
      nest_ratio:0,
      cntr_interior_l:0,
      cntr_interior_w:0,
      cntr_interior_h:0,
      cntr_tare_wt:0,
      cntr_capacity:0,
      cntr_per_layer:0,
      max_layers_shpg_unit:0,
      max_cntr_per_shpg_unit:0,
      cntr_matr_desc:'',
      cntr_drawing_no:'',
      shpg_unit_exterior_l:0,
      shpg_unit_exterior_w:0,
      shpg_unit_exterior_h:0,
      shpg_unit_nstdorflded_ht:0,
      shpg_unit_tare_wt:0,
      shpg_unit_stack_ht:0,
      warehouse_stack_ht:0,
      service_life:'',
      currency_desc:'',
      purchase_cost:0,
      rental_issue_cost:0,
      sup_rntl_dly_cost:0,
      ford_rntl_dly_cost:0,
      return_rntl_cost:0,
      tooling_dev_cost:0,
      extr_cntr_dsgn_img_ind:'No',
      intr_cntr_dsgn_img_ind:'No',
      shpg_unit_img_ind:'No',
      cad_img_ind:'No',
      img_notes:'',
      cd_region:'',
      iscontainercolapsble:'',
      cd_plant:'',
      cntr_qt_per_min_su:0,
      nest_ratio_1:0,
      ford_cntct:'',
      cntct_phone:'',
      id_user:'',
      ts_last_update:'',
      req_stat:'',
      uoM:'E',

      dbia_req_no: 0,

      return_code: '',
      return_mesg: '',
      cd_cntr_ownr: '',

      cd_cntr:0,
      typ_no:0
    }

  }


  openRequestDetailsByClickingCntr(rowData:DBIAContainerRequestsStatusGrid)
  {
    this.selectedRequestNumber=rowData.dbia_req_no;
    this.enableCntrRequestDetails()
  }

  openRequestDetailsByClickingTableButton(rowData:DBIAContainerRequestsStatusGrid)
  {
    this.selectedRequestNumber=rowData.dbia_req_no;
    this.enableCntrRequestDetails()
  }

  openAssociatedComponentsByClickingTableButton(rowData:DBIAContainerRequestsStatusGrid)
  {
    this.selectedRequestNumber=rowData.dbia_req_no;
    this.viewComponentList();
  }




  enableCntrRequestDetails()
  {
    // service to get request details by passing req number
    this.cntrDetailsInit();
    console.log('Request number to get container details',this.selectedRequestNumber);
    this.transactionService.getContainerRequestDetailsByReqNo(this.selectedRequestNumber).subscribe(savecntrdetails=>{
      console.log('Container details by req no',savecntrdetails)
    this.viewRequestDetails=true;

    this.cntrDetailsOfRequest=savecntrdetails

    console.log('cntrdetails',this.cntrDetailsOfRequest)



    if(this.cntrDetailsOfRequest.uoM == null || this.cntrDetailsOfRequest.uoM.trim() == '')
    {
      this.cntrDetailsOfRequest.uoM = 'E'
    }

    if(this.cntrDetailsOfRequest.uoM.toLowerCase() == 'm')
    {

      this.get_measure='kg/cm'
      this.prev_get_measure='kg/cm'
    }
    else
    {
      this.get_measure='lbs/inch'
      this.prev_get_measure='lbs/inch'
    }


    console.log('containerdetailsfromgrid',this.cntrDetailsOfRequest);
  })
  }

  closeCntrReqDetails()
  {
    this.viewRequestDetails=false;
  }


  viewComponentList()
  {
    console.log('Request number to get component associated to container',this.selectedRequestNumber);
    this.componentAssociatedToContainerList=[];
    // service to get componenet details by req number
    this.transactionService.getComponentAssociatedToContainerRequestDetailsByReqNo(this.selectedRequestNumber).subscribe(compasstocntrlist=>{
    this.componentAssociatedToContainerList=[];
    this.componentAssociatedToContainerList=JSON.parse(JSON.stringify(compasstocntrlist));
    console.log('componentAssociatedToContainerList',this.componentAssociatedToContainerList)
    this.enableCompDialog=true;
    this.comp_present_string='';
    if(this.componentAssociatedToContainerList != null && this.componentAssociatedToContainerList.length != 0)
    {
      if(this.componentAssociatedToContainerList[0].mp_function != null && this.componentAssociatedToContainerList[0].mp_function.trim() != '' && this.componentAssociatedToContainerList[0].no_comp_base != null)
      {
        this.pag_val=true;
      for(let j=0; j<this.componentAssociatedToContainerList.length; j++)
      {
        if(this.componentAssociatedToContainerList[j].no_comp_base != null)
        {
        this.componentAssociatedToContainerList[j].no_comp_base=this.componentAssociatedToContainerList[j].no_comp_base.trim()
        }
        if(this.componentAssociatedToContainerList[j].no_comp_suffix != null)
        {
        this.componentAssociatedToContainerList[j].no_comp_suffix=this.componentAssociatedToContainerList[j].no_comp_suffix.trim()
        }
        if(this.componentAssociatedToContainerList[j].ds_comp != null)
        {
        this.componentAssociatedToContainerList[j].ds_comp=this.componentAssociatedToContainerList[j].ds_comp.trim()
        }
        if(this.componentAssociatedToContainerList[j].in_comp != null && this.componentAssociatedToContainerList[j].in_comp.trim() == '')
        {
        this.componentAssociatedToContainerList[j].in_comp='N'
        }
      }
      this.compCols=[
        { field: 'mp_function', header:'Function'},
        {field:'no_comp_base',header:'Component Base'},
        {field:'no_comp_suffix',header:'Component Suffix'},
        { field: 'ds_comp', header:'Description'},
        { field: 'comp_cd_type', header:'Component Type'},
        { field: 'in_comp', header:'Is Compulsory'},
        { field: 'comp_qt_per_min_su', header:'Qty Per Min S.U'}

      ]

    }
    else
    {
      this.compCols=[]
      this.comp_present_string='No Components Mapped';
      this.pag_val=false
    }
    }


    else
    {
      this.compCols=[]
      this.comp_present_string='No Components Mapped';
      this.pag_val=false
    }
  })
  }




}
