/* tslint:disable */
import { Component, OnInit } from '@angular/core';
import { TransactionService } from '../loginpage/Transaction.service';
import { NavComponent } from '../nav/nav.component';

@Component({
  selector: 'app-gpparchivereportsadmin',
  templateUrl: './gpparchivereportsadmin.component.html',
  styleUrls: ['./gpparchivereportsadmin.component.css']
})
export class GpparchivereportsadminComponent implements OnInit {

  currentRoleid:number;
  region:string;


  regionCode='';
  cdsid='';

  loaderArchiveReports=false;

  constructor(private transactionService: TransactionService,private navigation:NavComponent) { }

  ngOnInit(): void {


    this.region=this.transactionService.getregion();
    this.regionCode=this.transactionService.getregioncode(this.region);
    this.cdsid=this.transactionService.getcdsid();


this.loadRoles();

   this.transactionService.getUserAccessRole(this.cdsid).subscribe(out=>{


    this.loaderArchiveReports=true;


    console.log(out);
    const roles=[];
    if(out!=null && out.length!=0)
    {
      let roleidString='';
      const glbIndexPresent=out.findIndex(elglbpres=>elglbpres.role_id==100);
      if(glbIndexPresent!=-1)
      {


        if(JSON.parse(sessionStorage.getItem('isGlobalAdmin'))==false)
					{
						sessionStorage.removeItem('isGlobalAdmin');
					sessionStorage.setItem('isGlobalAdmin', JSON.stringify(true));

					this.updateRegionList();
					}



        roles.push({roleid:100,roles:[]})
          roleidString='100';

      }



    for(let reg=0;reg<out.length;reg++)
    {
      if(out[reg].cd_region==this.regionCode)
      {
        if(roles.length==0)
        {
          roles.push({roleid:out[reg].role_id,roles:[]})
          roleidString=out[reg].role_id.toString();
        }
        else{


          let role_present=false;

          for(let len=0;len<roles.length;len++)
          {


            if(out[reg].role_id==roles[len])
            {

              role_present=true;
              break;

            }

          }


          if(role_present==false)
          {
            roles.push({roleid:out[reg].role_id,roles:[]})
            roleidString+=',';
            roleidString+=out[reg].role_id.toString();
          }
        }
      }
    }


    console.log(roles);



    if(roles.length!=0)
    {
    this.transactionService.getuserrolefunctionality(roleidString,this.regionCode).subscribe(userrolefuncaccess=>{


      console.log(userrolefuncaccess);

      if(userrolefuncaccess!=null || userrolefuncaccess.length!=0)
      {
      for(let ieu=0;ieu<roles.length;ieu++)
      {
        roles[ieu].roles=[]
        const rolefunc=[];
        for(let jeu=0;jeu<userrolefuncaccess.length;jeu++)
        {
          if(roles[ieu].roleid==userrolefuncaccess[jeu].role_id)
          {


            roles[ieu].roles.push(userrolefuncaccess[jeu]);


          }
        }
      }




      const name=this.regionCode+'useraccess';
      sessionStorage.setItem(name,JSON.stringify(roles));



     /* var reportIndex=roles.findIndex(elsup=>(elsup.roleid == 3 || elsup.roleid == 5 || elsup.roleid == 6 || elsup.roleid == 9 || elsup.roleid == 11 || elsup.roleid == 12 || elsup.roleid == 99 || elsup.roleid == 100))
*/
      this.loaderArchiveReports=false;

      let isFordUser='';
      isFordUser=JSON.parse(sessionStorage.getItem('userType'));

      if(!(isFordUser != null && isFordUser.toLowerCase().indexOf('yes')>-1))
      {
        this.routeRegion();
      }

      }

      else
      {
        this.loaderArchiveReports=false;
      }


    })

    }
    else
    {
      this.loaderArchiveReports=false;
    }
  }

  else
  {
    this.loaderArchiveReports=false;
  }

    })
  }


  routeRegion()
  {

    console.log('regpck',this.regionCode);

    if(this.regionCode=='NA')

    {

   this.navigation.navigatena(true);

    }
    else if(this.regionCode=='AP')
    {

   this.navigation.navigateap(true);

    }
    else if(this.regionCode=='EU')
    {
   this.navigation.navigateeu(true);
    }
    else if(this.regionCode=='SA')
    {
      console.log('insodesapack')
   this.navigation.navigatesa(true);
    }

  }



  loadRoles()
  {


    console.log('regpck',this.regionCode);

    if(this.regionCode=='NA')

    {
   this.navigation.navigateap(false);
   this.navigation.navigateeu(false);
   this.navigation.navigatesa(false);

    }
    else if(this.regionCode=='AP')
    {

      this.navigation.navigatena(false);
      this.navigation.navigateeu(false);
      this.navigation.navigatesa(false);

    }
    else if(this.regionCode=='EU')
    {

      this.navigation.navigatena(false);
      this.navigation.navigateap(false);
      this.navigation.navigatesa(false);
    }
    else if(this.regionCode=='SA')
    {
      console.log('insodesapack')

      this.navigation.navigatena(false);
      this.navigation.navigateeu(false);
      this.navigation.navigateap(false);
    }

  }




  updateRegionList()
	{


		console.log('update')
		const glbadmin=JSON.parse(sessionStorage.getItem('isGlobalAdmin'))
		if(glbadmin==true)
		{


			const regionlistupdate=JSON.parse(sessionStorage.getItem('user_regionaccess'))


			if(regionlistupdate.length!=4)
			{
				const user_region=[];
				const regionListact=['AP','EU','NA','SA']

				user_region.push(...regionListact);


				sessionStorage.removeItem('user_regionaccess');
						   sessionStorage.setItem('user_regionaccess', JSON.stringify(user_region));

			}




		}
	}

}
