/* tslint:disable */
import { Component, HostListener, OnInit } from '@angular/core';


import {AppComponent} from '.././app.component';


import {TransactionService} from '.././loginpage/Transaction.service';
import { Router } from '@angular/router';
import { PackapproverComponent } from '../packapprover/packapprover.component';
import { PackadminComponent } from '../packadmin/packadmin.component';
import { UseradminComponent } from '../useradmin/useradmin.component';
import { DbiaadminComponent } from '../dbiaadmin/dbiaadmin.component';

@Component({
  selector: 'app-asiapacificsidebar',
  templateUrl: './asiapacificsidebar.component.html',
  styleUrls: ['./asiapacificsidebar.component.css']
})
export class AsiapacificsidebarComponent {

  Userid:string;
  adminvalue:string;
  adminvaluepl:string;
  adminaccess:string;
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {


	var dropdown = document.getElementsByClassName("dropdown-btn");
	var i;


	for (i = 0; i < dropdown.length; i++) {
	  dropdown[i].addEventListener("click", function() {
		  console.log(dropdown[i]);
		this.classList.toggle("active");
		var dropdownContent = this.nextElementSibling;
		if (dropdownContent.style.display === "block") {

		  dropdownContent.style.display = "none";
		} else  {
		  dropdownContent.style.display = "block";
		}
	  });
	}

  }
  visibility:boolean=false
  constructor(public app: AppComponent , private transactionService: TransactionService , private router:Router, private packapprover:PackapproverComponent,private packadmin:PackadminComponent, private useradmin:UseradminComponent,private dbiaadmin :DbiaadminComponent) {}

 userid():boolean{
	this.Userid=this.transactionService.getcdsid();
	console.log(this.Userid)
	if(this.Userid==null)
	{
		this.visibility=false;
		return false;
	}
	else{
		this.visibility=true;
		return true;
	}
}
  admin():boolean
		{
			this.adminvalue=this.transactionService.getadmin();
			if(this.adminvalue=="global_admin")
			{
				return true;
			}
			else{
				return false;
			}

		}
		plantadmin():boolean
		{
			this.adminvaluepl=this.transactionService.getadmin();
			if(this.adminvaluepl=="plant_admin")
			{
				return true;
			}
			else{
				return false;
			}

		}


		supp_admin():boolean
		{


			var supproles=this.transactionService.getroles("AP");
			console.log(supproles);
			var present_supp=false;
			if(supproles!=null)
			{
			if(supproles.length!=0)
			{
							for(var i=0;i<supproles.length;i++)
			{
				if(supproles[i].roleid==1)
				{
					present_supp=true;
					break;
				}
			}



			return present_supp;
		}
	}

		else{
			return false;
		}

	}


	designsource_admin():boolean
	{


		var desgroles=this.transactionService.getroles("AP");
		console.log(desgroles);
		var present_desg=false;
		if(desgroles!=null)
		{
		if(desgroles.length!=0)
		{
						for(var i=0;i<desgroles.length;i++)
		{
			if(desgroles[i].roleid==2)
			{
				present_desg=true;
				break;
			}
		}



		return present_desg;
	}
}

	else{
		return false;
	}
}


apr_admin():boolean
	{


		var aprroles=this.transactionService.getroles("AP");
		console.log(aprroles);
		var present_apr=false;
		if(aprroles!=null)
		{
		if(aprroles.length!=0)
		{
		for(var i=0;i<aprroles.length;i++)
		{
			if(aprroles[i].roleid==3 || aprroles[i].roleid==4 || aprroles[i].roleid==5 || aprroles[i].roleid==6 || aprroles[i].roleid==9|| aprroles[i].roleid==11|| aprroles[i].roleid==19 || aprroles[i].roleid==20 || aprroles[i].roleid==12)
			{
				present_apr=true;
				break;
			}
		}



		return present_apr;
	}
}

	else{
		return false;
	}

	//return true;
}




	pack_admin():boolean{


		var adminroles=this.transactionService.getroles("AP");
		console.log(adminroles);
		var present_admin=false;
		if(adminroles!=null)
		{
		if(adminroles.length!=0)
		{

		for(var i=0;i<adminroles.length;i++)
		{
			if(adminroles[i].roleid==5 || adminroles[i].roleid==6 || adminroles[i].roleid==14 || adminroles[i].roleid==12 || adminroles[i].roleid==17 || adminroles[i].roleid==100 || adminroles[i].roleid==23)
			{
				present_admin=true;
				break;
			}
		}



		return present_admin;
	}
}


	else{
		return present_admin;
	}

	}
	dbiaAdmin():boolean
		{
			var adminroles=this.transactionService.getroles("AP");
			console.log(adminroles);
			var present_admin=false;
			if(adminroles!=null)
			{
			if(adminroles.length!=0)
			{

				for(var j=0;j<adminroles.length;j++)
				{
				  var sys_func_arr=[];
				   for(var k=0;k<adminroles[j].roles.length;k++)
				   {

					 sys_func_arr.push(adminroles[j].roles[k].sysfunc_id.toString())
				   }
				   if(sys_func_arr.includes('53'))
				   {
					   present_admin=true;
					   break
				   }
				}



				return present_admin;
			}
			}

			else{
				return present_admin;
			}

		}


				eqoConfigAccess():boolean
				{
					//write code for eqo access restrisction
					var eqoadminroles=this.transactionService.getroles("AP");
					console.log(eqoadminroles);
					var present_eqo_admin=false;
					if(eqoadminroles!=null)
					{
					if(eqoadminroles.length!=0)
					{

					for(var i=0;i<eqoadminroles.length;i++)
					{
						if(eqoadminroles[i].roleid==21 || eqoadminroles[i].roleid==100 )
					{
						present_eqo_admin=true;
						break;
					}

					}



					return present_eqo_admin;
				}
			}

				else{
					return present_eqo_admin;
				}

				}


				reportsAccess():boolean
				{
					var reportsroles=this.transactionService.getroles("AP");
					//console.log(eqoadminroles);
					var present_reports=false;
					if(reportsroles != null)
					{
						if(reportsroles.length != 0)
						{
							for(var i=0;i<reportsroles.length;i++)
							{
								var sys_func_arr=[];
								for(var k=0;k<reportsroles[i].roles.length;k++)
								{

									sys_func_arr.push(reportsroles[i].roles[k].sysfunc_id.toString())
								}
								if(sys_func_arr.findIndex(ele=>ele == '33' || ele == '34' || ele == '32') > -1)
								{
									present_reports=true;
									break
								}
							}
						return present_reports;
						}
					}
					else
					{
						return present_reports;
					}
				}

				gppArchiveSearchPartAccess():boolean
				{

					var isFordUser="";
					isFordUser=JSON.parse(sessionStorage.getItem('userType'));

					if(isFordUser != null && isFordUser.toLowerCase().indexOf('yes')>-1)
					{

						return true;
					}
					else
					{
						return false;
					}
				}

				gppArchiveReportsAccess():boolean{

					var isFordUser="";
					isFordUser=JSON.parse(sessionStorage.getItem('userType'));

					if(isFordUser != null && isFordUser.toLowerCase().indexOf('yes')>-1)
					{
						
						return true;
					}
					else
					{
						return false;
					}
		
				}

		//Revathy

		programLevelEmailAccess():boolean{

			var isFordUser="";
			isFordUser=JSON.parse(sessionStorage.getItem('userType'));

			if(isFordUser != null && isFordUser.toLowerCase().indexOf('yes')>-1)
			{
				
				return true;
			}
			else
			{
				return false;
			}

				}


	user_admin()
		{
			var useradminroles=this.transactionService.getroles("AP");
			console.log(useradminroles);
			var present_user_admin=false;
			if(useradminroles!=null)
			{
			if(useradminroles.length!=0)
			{

			for(var i=0;i<useradminroles.length;i++)
			{
				if(useradminroles[i].roleid==12 || useradminroles[i].roleid==13 || useradminroles[i].roleid==14 || useradminroles[i].roleid==100 )
			{
				present_user_admin=true;
				break;
			}

			}



			return present_user_admin;
		}
	}

		else{
			return present_user_admin;
		}
		}


	ap_approver_call()
		{
			this.app.hideMobileMenu()
			this.packapprover.ngOnInit();

		}


		ap_admin_call()
		{
			this.app.hideMobileMenu()
			this.packadmin.ngOnInit();
		}
		apDbiaAdminCall()
		{
			this.app.hideMobileMenu()

			this.dbiaadmin.ngOnInit();
		}

		ap_user_admin_call()
		{
			this.app.hideMobileMenu()
			this.useradmin.ngOnInit();
		}



}
