/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { switchMap, map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
  })
  export class ImageService {

    constructor(private httpClient: HttpClient) { }
    getData(urls:string): Observable<string> {
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', 'Bearer ' + sessionStorage.getItem("encodedAccessToken"));    
      return this.httpClient.get(urls, { responseType: 'blob', headers: headers})
        .pipe(
          switchMap(response => this.readFile(response))
        );
    }

    private readFile(blob: Blob): Observable<string> {
      return Observable.create(obs => {
        const reader = new FileReader();

        reader.onerror = err => obs.error(err);
        reader.onabort = err => obs.error(err);
        reader.onload = () => obs.next(reader.result);
        reader.onloadend = () => obs.complete();

        return reader.readAsDataURL(blob);
      });
    }

  }
