/* tslint:disable */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  public errorMessage = '';

  constructor(private router: Router) { }

  public handleError = (error: HttpErrorResponse) => {
    console.log('test handle error', error)

    let err_value=error;
    if (err_value.status === 500) {
      this.handle500Error(error);
    }
    else if (error.status === 404) {
      this.handle404Error(error)
    }
    else {
      this.handleOtherError(error);
    }
  }
  private handle500Error = (error: HttpErrorResponse) => {
    this.createErrorMessage(error);
   // this.router.navigate(['/500']);
  }
  private handle404Error = (error: HttpErrorResponse) => {
    this.createErrorMessage(error);
   // this.router.navigate(['/notaccesspage']);
  }
  private handleOtherError = (error: HttpErrorResponse) => {
    this.createErrorMessage(error); // TODO: this will be fixed later;
  }
  private createErrorMessage = (error: HttpErrorResponse) => {
    this.errorMessage = error.error ? error.error : error.statusText;
  }
}
