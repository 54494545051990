/* tslint:disable */
import { Component, OnInit } from '@angular/core';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {TransactionService} from '../loginpage/Transaction.service';
import {Router} from '@angular/router';
import { fromdiplayvalues_all, RevHistFromAPi, RevisionObj, selec_prt_pro_details, tab4details } from '../loginpage/transactions';
import { PackagingprocessComponent } from '../packagingprocess/packagingprocess.component';

@Component({
  selector: 'app-packagingproposal',
  templateUrl: './packagingproposal.component.html',
  styleUrls: ['./packagingproposal.component.css'],
  providers:[ConfirmationService,MessageService]
})
export class PackagingproposalComponent implements OnInit {

    tabpackagingproposalmenuitems: MenuItem[];
    dummyitem:MenuItem[];
    activeItem: MenuItem;
    flag1:boolean;
    flag2:boolean;
    flag3:boolean;
    falg4:boolean;
    region:string;
    name:string;
    tab1:string;
    tab1route:string;
    tab2route:string;
    tab3route:string;
    tab4route:string;

    flag1_ee:boolean;
    flag2_ee:boolean;
    flag3_ee:boolean;
    falg4_ee:boolean;
    region_ee:string;
    name_ee:string;
    tab1_ee:string;
    tab1route_ee:string;
    tab2route_ee:string;
    tab3route_ee:string;
    tab4route_ee:string;

// Always set this "savedtab" in childcomponent, becuase menubar uses this variable to create dynamic menus
// we may use savedtab from submitterdashboard but when any child component other than
    savedtab:number;

    loadingfirstfromsubmitterdashboard:string;

    gsdb_code='';
    current_roleid:number;
    noroleselectedDialog_main=false;
    norolecontent_main=''

    selectedpart_submitter_contandcos:selec_prt_pro_details={proposalid:0,part_status:'',plantgsdbcode:'',proposallastsaved:0,bompartmasterid:0,simplifiedpartno:'',supplier_code:'',proposallastsaved_ee:0,currentview_ee:false,is_ee_proposal:false,from_draft:false,propsl_type:'',imp_part_status:''};
    selectedpart_submitter_delete:selec_prt_pro_details={proposalid:0,part_status:'',plantgsdbcode:'',proposallastsaved:0,bompartmasterid:0,simplifiedpartno:'',supplier_code:'',proposallastsaved_ee:0,currentview_ee:false,is_ee_proposal:false,from_draft:false,propsl_type:'',imp_part_status:''};
    selected_submitter_revision:selec_prt_pro_details={proposalid:0,part_status:'',plantgsdbcode:'',proposallastsaved:0,bompartmasterid:0,simplifiedpartno:'',supplier_code:'',proposallastsaved_ee:0,currentview_ee:false,is_ee_proposal:false,from_draft:false,propsl_type:'',imp_part_status:''};
    getlastsavedtabfromapi


    view_proposal=false;


    proposal_id=0;
    tab4_output:tab4details;


    tab4_output_det:tab4details;
    region_code:any;



    ee_text='';


    messageDisplay='';
    enableNotificationDialog=false;


    enabledetails_proposal=false;

    allow_revision_history=false;


    proposal_details_revision:fromdiplayvalues_all;
    revision_obj_search:RevisionObj[]=[];

    revHistFromServiceAPI:RevHistFromAPi[]=[]



    roleid:number;
    supp_code_contandcos:string;
    designsource_code_contandcos:string;


    noDesignsourceDialog_contandcos=false;
    noroleselectedDialog=false;
    noSupDialog_contandcos=false;
    noPrtDialog_proposal=false;


    content_Designsource_contandcos='';
    content_supp_contandcos='';
    norolecontent='';
    content_selectedprt_contandcos='';


  constructor(private transactionService: TransactionService,private router:Router,private msgService:MessageService,private confirmationService:ConfirmationService, private packagingprocess:PackagingprocessComponent) { }

  ngOnInit(): void
   {
      console.log('parent component initialied');
      this.name=this.transactionService.getregionNameInSmallLetters();
      this.region=this.transactionService.getregion();
      this.region_code=this.transactionService.getregioncode(this.region);
      this.roleid=this.transactionService.getcurrentrole(this.region)

      this.ee_text='Emergency Expendable View';




      this.loadingfirstfromsubmitterdashboard=this.transactionService.getwhethermenuclickfromsubdashboard();

        this.selectedpart_submitter_contandcos = this.transactionService.getpartno_packpro(this.transactionService.getregion());

        if(this.roleid==null ||(this.roleid != 1 && this.roleid != 2))
        {


          this.noroleselectedDialog=true;
          this.norolecontent='No role selected '
        }
        else
        {
            this.supp_code_contandcos=null
            this.designsource_code_contandcos=null
        if(this.roleid==1)
        {

        this.supp_code_contandcos = this.transactionService.get_supplier(this.region);
        console.log(this.supp_code_contandcos);
        if(this. supp_code_contandcos == null)
        {
            this.noSupDialog_contandcos = true;
            this.content_supp_contandcos = 'Please select supplier';
        }
        }
        else if( this.roleid==2)
        {

          this.designsource_code_contandcos = this.transactionService.get_designsource(this.region);
          console.log(this.designsource_code_contandcos)
          if(this.designsource_code_contandcos == null)
          {
            this.noDesignsourceDialog_contandcos = true;
            this.content_Designsource_contandcos = 'Please select Design source';
          }
        }

        console.log(this.selectedpart_submitter_contandcos)

        if(this.selectedpart_submitter_contandcos==null)
        {
        this.noPrtDialog_proposal = true;
        this.content_selectedprt_contandcos = 'Please select part from submitter dashboard';
        }


        else if( this.selectedpart_submitter_contandcos!=null)
        {
            console.log('lastsaved',this.selectedpart_submitter_contandcos.proposallastsaved);
        console.log('selectedpart_submitter_contandcos-packproosal',this.selectedpart_submitter_contandcos)

       // this.proposal_id=this.selectedpart_submitter_contandcos.proposalid;

            if(this.selectedpart_submitter_contandcos.currentview_ee==false)
            {


                if(this.selectedpart_submitter_contandcos.proposallastsaved>=0)
                {
                    /*this.transactionService.get_exist_proposalid(this.selectedpart_submitter_contandcos.simplifiedpartno,this.selectedpart_submitter_contandcos.plantgsdbcode).subscribe(result=>{
                        this.getlastsavedtabfromapi=result;
                        console.log("getlastsavedtabfromapi",this.getlastsavedtabfromapi);

                    if(result!=null)
                    {
                    this.savedtab=result.lst_saved_tab;
                    console.log(result.lst_saved_tab);
                    }
                    else{*/
                        this.savedtab=this.selectedpart_submitter_contandcos.proposallastsaved;

                // }
                    // this.transactionService.storelastsavedtab(this.savedtab,this.region);
                    this.loadmenubar();
                // });
                }
                else
                {
                    this.loadmenubar();
                }
            }
            else
            {
                // Emeregncy Expendable
                if(this.selectedpart_submitter_contandcos.proposallastsaved_ee>=1)
                {
                /*this.transactionService.get_exist_proposalid(this.selectedpart_submitter_contandcos.simplifiedpartno,this.selectedpart_submitter_contandcos.plantgsdbcode).subscribe(result=>{
                    this.getlastsavedtabfromapi=result;
                    console.log("getlastsavedtabfromapi",this.getlastsavedtabfromapi);

                if(result!=null)
                {
                this.savedtab=result.lst_saved_tab;
                console.log(result.lst_saved_tab);
                }
                else{*/
                    this.savedtab=this.selectedpart_submitter_contandcos.proposallastsaved_ee;

               // }
                // this.transactionService.storelastsavedtab(this.savedtab,this.region);
                this.loadmenubar_ee();
               // });
                }
                else
                {
                    this.loadmenubar_ee();
                }
            }

        }
    /*else if(this.selectedpart_submitter_contandcos==null || this.selectedpart_submitter_contandcos.proposallastsaved==0){
        console.log("called loadmenubar");
        this.loadmenubar();
    }*/

    }

  }
  ok_noPartDialog()
  {
    console.log(this.region)

    let reg_url_prt: string
    reg_url_prt = this.transactionService.getregionurlvalue(this.region);
    if(this.selectedpart_submitter_contandcos.propsl_type!=null && this.selectedpart_submitter_contandcos.propsl_type.toLowerCase()=='migration')
    {
      if(this.roleid==1)
      {
        this.router.navigate(['/'+this.name+'/packagingprocess/packsupplieradmin/migrationsubmitter'])
      }
      else if(this.roleid==2)
      {
        this.router.navigate(['/'+this.name+'/packagingprocess/packdesignsourceadmin/migrationsubmitter'])
      }
    }
    else // if(this.selectedpart_submitter.propsl_type!=null && this.selectedpart_submitter.propsl_type.toLowerCase()=="normal")
    {
    if(this.roleid==1)
    {
    reg_url_prt += 'packagingprocess/packsupplieradmin/submiterdashboard';
    }
    else if ( this.roleid==2)
    {
      reg_url_prt += 'packagingprocess/packdesignsourceadmin/submiterdashboard';
    }
  }
    this.router.navigate([reg_url_prt]);

  }

  ok_noroledialog()
  {
    let url
    url=this.transactionService.getregionurlvalue(this.region);
    url+='packagingprocessdashboard'
    this.router.navigate([url]);

  }




    // if no supplier present

    ok_nosupdialog() {
      console.log(this.region)
      let reg_url: string
      reg_url = this.transactionService.getregionurlvalue(this.region);
      reg_url += 'packagingprocess/packsupplieradmin/packsupplier';

      this.router.navigate([reg_url]);

    }


    ok_noDesignsourcedialog() {
      console.log(this.region)
      let reg_url: string
      reg_url = this.transactionService.getregionurlvalue(this.region);
      reg_url += 'packagingprocess/ppackdesignsourceadmin/packdesignsource';

      this.router.navigate([reg_url]);

    }

  loadmenubar()
  {
      this.tabpackagingproposalmenuitems=[];
        this.current_roleid=this.transactionService.getcurrentrole(this.region)
        this.loadingfirstfromsubmitterdashboard=this.transactionService.getwhethermenuclickfromsubdashboard();;
    console.log('called loadmenubar***');
    // alert (this.loadingfirst);
    if(this.current_roleid!=null)
    {

      if(this.current_roleid==1)
      {

      this.gsdb_code=this.transactionService.get_supplier(this.region);

      }

      else if(this.current_roleid==2)
      {

        this.gsdb_code=this.transactionService.get_designsource(this.region);

      }
    }




    this.tab1route=this.transactionService.getregionurlvalue(this.region)+'packagingprocess/proposalcreation/partsandattri';
    this.tab2route=this.transactionService.getregionurlvalue(this.region)+'packagingprocess/proposalcreation/packproposaltabtwo';
    this.tab3route=this.transactionService.getregionurlvalue(this.region)+'packagingprocess/proposalcreation/packproposaltabthree';
    this.tab4route=this.transactionService.getregionurlvalue(this.region)+'packagingprocess/proposalcreation/packproposaltabfour';


    this.tab1='/'+this.name+'/packagingprocess/proposalcreation/partsandattri';
    console.log(this.tab1);
    this.tabpackagingproposalmenuitems =
        [
            {label: 'PartsAndAttributes', routerLink: this.tab1, icon: 'fas fa-wrench'}
        ];


        // this.activeItem={label: 'PartsAndAttributes', routerLink:this.tab1, icon: 'fas fa-wrench'};



    // this.transactionService.storelastsavedtab(0,this.region);
    // this.savedtab=this.transactionService.getlastsavedtab(this.region);


    //  if(this.savedtab==1 || this.router.url == "/northamerica/proposalcreation/packproposaltabtwo")

    if(this.savedtab==0)
    {
        let path_tab1:string;
        // var item_tab1:any;
        path_tab1=this.tab1;


        this.setActiveItemDynamically(path_tab1,this.tabpackagingproposalmenuitems)
    }
    if(this.savedtab==1 )// || this.router.url == this.tab2route)

    {
        for(const i of this.tabpackagingproposalmenuitems.values())
        {
            console.log(i);
            if(i.label!='COS, Container & Shipping Unit')
            {
                this.flag1=true;
            }
            else{
                this.flag1=false;
                break;
            }
        }

        if(this.flag1)
        {
            this.tabpackagingproposalmenuitems.push({label: 'COS, Container & Shipping Unit', routerLink:this.tab2route, icon: 'fas fa-cubes'});
            console.log('setting active item');

            /*Checking this condition to ensure -
            1.  only when user select parts from submitter dashboard, active menu shd be last saved tab
            2.  when they refresh any tab this.loadingfirstfromsubmitterdashboard will be No , so active menu will not be lastsaved
              instead it will be the tab where they gave refresh*/

            if(this.loadingfirstfromsubmitterdashboard=='Yes')
            {
                this.router.navigate([this.tab2route]);
                this.activeItem={label: 'COS, Container & Shipping Unit', routerLink:this.tab2route, icon: 'fas fa-cubes'};
                this.transactionService.loadmenuwhenclickedfromdashboard('No');

            }
        }


    }

    else if(this.savedtab==2 )// || this.router.url== this.tab3route)
    {
        for(const i of this.tabpackagingproposalmenuitems.values())
        {
            console.log(i);
            if(i.label!='Component & Material')
            {
                this.flag2=true;
            }
            else{
                this.flag2=false;
                break;
            }
        }

        if(this.flag2){
            this.tabpackagingproposalmenuitems.push({label: 'COS, Container & Shipping Unit', routerLink:this.tab2route, icon: 'fas fa-person-booth'});
            this.tabpackagingproposalmenuitems.push({label:'Component & Material',routerLink:this.tab3route, icon: 'fas fa-person-booth'});


            /*Checking this condition to ensure-
            1. only when user select parts from submitter dashboard, active menu shd be last saved tab
            2.  when they refresh any tab this.loadingfirstfromsubmitterdashboard will be No , so active menu will not be lastsaved
              instead it will be the tab where they gave refresh*/

            if(this.loadingfirstfromsubmitterdashboard=='Yes')
            {
            this.router.navigate([this.tab3route]);
            this.activeItem={label:'Component & Material',routerLink:this.tab3route, icon: 'fas fa-person-booth'};
            this.transactionService.loadmenuwhenclickedfromdashboard('No');

            }
        }
        console.log('created tab2 page');



    }

    else if(this.savedtab==3 || this.savedtab==4 )// || this.router.url==this.tab4route)
    {
        for(const i of this.tabpackagingproposalmenuitems.values())
        {
            console.log(i);
            if(i.label!='Images/Photos & Comments')
            {
                this.flag3=true;
            }
            else{
                this.flag3=false;
                break;
            }
        }

        if(this.flag3)
        {
            this.tabpackagingproposalmenuitems.push({label: 'COS, Container & Shipping Unit', routerLink:this.tab2route, icon: 'fas fa-cubes'});
            this.tabpackagingproposalmenuitems.push({label:'Component & Material',routerLink:this.tab3route, icon: 'fas fa-person-booth'});
            this.tabpackagingproposalmenuitems.push({label:'Images/Photos & Comments',routerLink:this.tab4route, icon: 'pi pi-images'});


            /*Checking this condition to ensure
            1.only when user select parts from submitter dashboard, active menu shd be last saved tab
            2. when they refresh any tab this.loadingfirstfromsubmitterdashboard will be No , so active menu will not be lastsaved
              instead it will be the tab where they gave refresh*/

            if(this.loadingfirstfromsubmitterdashboard=='Yes')
            {
            this.router.navigate([this.tab4route]);
            this.activeItem={label:'Images/Photos & Comments',routerLink:this.tab4route, icon: 'pi pi-images'};
            this.transactionService.loadmenuwhenclickedfromdashboard('No');

            }
        }


    }

  }

  goToNormalProposal()
  {
      this.selectedpart_submitter_contandcos=this.transactionService.getpartno_packpro(this.region);
    this.selectedpart_submitter_contandcos.currentview_ee=false;
    this.transactionService.loadmenuwhenclickedfromdashboard('Yes');
    this.savedtab=this.selectedpart_submitter_contandcos.proposallastsaved;
    this.store_updated_submitter_contcos();
    this.loadmenubar();


  }

  goToEEProposal()
  {

    this.transactionService.loadtab4details(this.selectedpart_submitter_contandcos.proposalid).subscribe(tab4det=>
        {
          console.log('tab4',tab4det);


          if(tab4det.f_extcntrdsgnphoto==null|| tab4det.f_extcntrdsgnphoto==''||
          tab4det.f_intcntrdsgnphoto==null|| tab4det.f_intcntrdsgnphoto==''||
          tab4det.f_partphoto==null|| tab4det.f_partphoto==''||
          tab4det.f_metelidplatephoto==null|| tab4det.f_metelidplatephoto==''||
          tab4det.f_shpunitphoto==null|| tab4det.f_shpunitphoto=='' || tab4det.createmgcyexpndpckgprop==null || tab4det.createmgcyexpndpckgprop=='')
          {

            // enable notification dialog

            this.enableNotificationDialog=true;
          }

          else
          {
            this.selectedpart_submitter_contandcos=this.transactionService.getpartno_packpro(this.region);

            this.selectedpart_submitter_contandcos.currentview_ee=true;
            this.savedtab=this.selectedpart_submitter_contandcos.proposallastsaved_ee;
            this.transactionService.loadmenuwhenclickedfromdashboard('Yes');

            this.store_updated_submitter_contcos();
            this.loadmenubar_ee();

          }


        })

  }



  okNotificationDialog()
  {
      this.enableNotificationDialog=false;
  }

  store_updated_submitter_contcos()
  {
    if(this.region_code=='NA')
        {

          sessionStorage.setItem('napackpropart', JSON.stringify(this.selectedpart_submitter_contandcos));
        }


        if(this.region_code=='EU')
        {
          sessionStorage.setItem('eupackpropart', JSON.stringify(this.selectedpart_submitter_contandcos));
        }



        if(this.region_code=='AP')
        {
          sessionStorage.setItem('appackpropart', JSON.stringify(this.selectedpart_submitter_contandcos));
        }



        if(this.region_code=='SA')
        {
          sessionStorage.setItem('sapackpropart', JSON.stringify(this.selectedpart_submitter_contandcos));
        }
  }


  loadmenubar_ee()
  {
      this.tabpackagingproposalmenuitems=[];
        this.current_roleid=this.transactionService.getcurrentrole(this.region)
    console.log('called loadmenubar***');


    this.loadingfirstfromsubmitterdashboard=this.transactionService.getwhethermenuclickfromsubdashboard();
    // alert (this.loadingfirst);
    if(this.current_roleid!=null)
    {

      if(this.current_roleid==1)
      {

      this.gsdb_code=this.transactionService.get_supplier(this.region);

      }

      else if(this.current_roleid==2)
      {

        this.gsdb_code=this.transactionService.get_designsource(this.region);

      }
    }




    this.tab1route_ee=this.transactionService.getregionurlvalue(this.region)+'packagingprocess/proposalcreation/eepartsandattri';
    this.tab2route_ee=this.transactionService.getregionurlvalue(this.region)+'packagingprocess/proposalcreation/eepackproposaltabtwo';
    this.tab3route_ee=this.transactionService.getregionurlvalue(this.region)+'packagingprocess/proposalcreation/eepackproposaltabthree';
    this.tab4route_ee=this.transactionService.getregionurlvalue(this.region)+'packagingprocess/proposalcreation/eepackproposaltabfour';


    this.tab1_ee='/'+this.name+'/packagingprocess/proposalcreation/eepartsandattri';
    console.log(this.tab1);
    this.tabpackagingproposalmenuitems =
        [
            {label: 'EE-PartsAndAttributes', routerLink: this.tab1_ee, icon: 'fas fa-wrench'}
        ];



    // this.transactionService.storelastsavedtab(0,this.region);
    // this.savedtab=this.transactionService.getlastsavedtab(this.region);


    //  if(this.savedtab==1 || this.router.url == "/northamerica/proposalcreation/packproposaltabtwo")
    if(this.savedtab==1 )// || this.router.url == this.tab2route)

    {
        for(const i of this.tabpackagingproposalmenuitems.values())
        {
            console.log(i);
            if(i.label!='EE-COS, Container & Shipping Unit')
            {
                this.flag1_ee=true;
            }
            else{
                this.flag1_ee=false;
                break;
            }
        }

        if(this.flag1_ee)
        {
            this.tabpackagingproposalmenuitems.push({label: 'EE-COS, Container & Shipping Unit', routerLink:this.tab2route_ee, icon: 'fas fa-cubes'});
            console.log('setting active item');

            /*Checking this condition to ensure -
            1.  only when user select parts from submitter dashboard, active menu shd be last saved tab
            2.  when they refresh any tab this.loadingfirstfromsubmitterdashboard will be No , so active menu will not be lastsaved
              instead it will be the tab where they gave refresh*/

            if(this.loadingfirstfromsubmitterdashboard=='Yes')
            {
                this.router.navigate([this.tab2route_ee]);
                this.activeItem={label: 'EE-COS, Container & Shipping Unit', routerLink:this.tab2route_ee, icon: 'fas fa-cubes'};
                this.transactionService.loadmenuwhenclickedfromdashboard('No');

            }
        }


    }

    else if(this.savedtab==2 )// || this.router.url== this.tab3route)
    {
        for(const i of this.tabpackagingproposalmenuitems.values())
        {
            console.log(i);
            if(i.label!='EE-Component & Material')
            {
                this.flag2_ee=true;
            }
            else{
                this.flag2_ee=false;
                break;
            }
        }

        if(this.flag2_ee){
            this.tabpackagingproposalmenuitems.push({label: 'EE-COS, Container & Shipping Unit', routerLink:this.tab2route_ee, icon: 'fas fa-person-booth'});
            this.tabpackagingproposalmenuitems.push({label:'EE-Component & Material',routerLink:this.tab3route_ee, icon: 'fas fa-person-booth'});


            /*Checking this condition to ensure-
            1. only when user select parts from submitter dashboard, active menu shd be last saved tab
            2.  when they refresh any tab this.loadingfirstfromsubmitterdashboard will be No , so active menu will not be lastsaved
              instead it will be the tab where they gave refresh*/

            if(this.loadingfirstfromsubmitterdashboard=='Yes')
            {
            this.router.navigate([this.tab3route_ee]);
            this.activeItem={label:'EE-Component & Material',routerLink:this.tab3route_ee, icon: 'fas fa-person-booth'};
            this.transactionService.loadmenuwhenclickedfromdashboard('No');

            }
        }
        console.log('created tab2 page');



    }

    else if(this.savedtab==3 || this.savedtab==4 )// || this.router.url==this.tab4route)
    {
        for(const i of this.tabpackagingproposalmenuitems.values())
        {
            console.log(i);
            if(i.label!='EE-Images/Photos & Comments')
            {
                this.flag3_ee=true;
            }
            else{
                this.flag3_ee=false;
                break;
            }
        }

        if(this.flag3_ee)
        {
            this.tabpackagingproposalmenuitems.push({label: 'EE-COS, Container & Shipping Unit', routerLink:this.tab2route_ee, icon: 'fas fa-cubes'});
            this.tabpackagingproposalmenuitems.push({label:'EE-Component & Material',routerLink:this.tab3route_ee, icon: 'fas fa-person-booth'});
            this.tabpackagingproposalmenuitems.push({label:'EE-Images/Photos & Comments',routerLink:this.tab4route_ee, icon: 'pi pi-images'});


            /*Checking this condition to ensure
            1.only when user select parts from submitter dashboard, active menu shd be last saved tab
            2. when they refresh any tab this.loadingfirstfromsubmitterdashboard will be No , so active menu will not be lastsaved
              instead it will be the tab where they gave refresh*/
              console.log('this.loadingfirstfromsubmitterdashboard',this.loadingfirstfromsubmitterdashboard)

            if(this.loadingfirstfromsubmitterdashboard=='Yes')
            {
                console.log('inside load ee')
            this.router.navigate([this.tab4route_ee]);
            this.activeItem={label:'EE-Images/Photos & Comments',routerLink:this.tab4route_ee, icon: 'pi pi-images'};
            this.transactionService.loadmenuwhenclickedfromdashboard('No');

            }
        }


    }

  }

    setActiveItemDynamically(path:any,item:any)
    {
        this.activeItem=item;
        this.router.navigate([path]);
    }

    addtoast(test:any)
    {
      // alert(JSON.stringify(test));
        this.msgService.add(test);
    }


    delete_proposal()
    {


        this.selectedpart_submitter_delete=this.transactionService.getpartno_packpro(this.transactionService.getregion());


        this.messageDisplay=''
      if(this.selectedpart_submitter_delete.is_ee_proposal==true)
      {
        this.messageDisplay='Are you sure you want to delete current proposal both normal and ee ?'
      }
      else
      {
        this.messageDisplay='Are you sure you want to delete current proposal ?'
      }



        this.confirmationService.confirm({
            message: this.messageDisplay,
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              this.deleteproposal_id(this.selectedpart_submitter_delete.proposalid);


            }
        });

    }


    deleteproposal_id(proposalid:any)
    {


        console.log('opnew',proposalid);


        console.log('deleteid',this.selectedpart_submitter_delete);


        if(this.selectedpart_submitter_delete.proposallastsaved>=4)
        {


            if(this.selectedpart_submitter_delete.proposallastsaved_ee>=4)
            {


                this.transactionService.loadtab4details_ee(this.selectedpart_submitter_contandcos.proposalid).subscribe(tab4_api_op_ee=>{


                    console.log(tab4_api_op_ee);


                    this.tab4_output_det=tab4_api_op_ee;


                    if(this.tab4_output_det.f_partphoto!='' && this.tab4_output_det.f_partphoto!=null)
                    {
                      const prt=this.tab4_output_det.f_partphoto;
                      this.transactionService.delete_image(prt).subscribe(out_prt=>{

                        console.log(out_prt);

                      })

                    }


                if(this.tab4_output_det.f_intcntrdsgnphoto!='' && this.tab4_output_det.f_intcntrdsgnphoto!=null)
                {
                    const inter=this.tab4_output_det.f_intcntrdsgnphoto;
                    this.transactionService.delete_image(inter).subscribe(out_inter=>{

                        console.log(out_inter);

                      })

                }

                if(this.tab4_output_det.f_extcntrdsgnphoto!='' && this.tab4_output_det.f_extcntrdsgnphoto!=null)
                {
                    const exter=this.tab4_output_det.f_extcntrdsgnphoto;
                    this.transactionService.delete_image(exter).subscribe(out_exter=>{

                        console.log(out_exter);

                      })

                }


                if(this.tab4_output_det.f_metelidplatephoto!='' && this.tab4_output_det.f_metelidplatephoto!=null)
                {
                    const label=this.tab4_output_det.f_metelidplatephoto;
                    this.transactionService.delete_image(label).subscribe(out_label=>{

                        console.log('label',out_label);

                      })

                }


                if(this.tab4_output_det.f_shpunitphoto!='' && this.tab4_output_det.f_shpunitphoto!=null)
                {
                    const ship=this.tab4_output_det.f_shpunitphoto;
                    this.transactionService.delete_image(ship).subscribe(out_ship=>{

                        console.log(out_ship);

                      })

                }





                   this.deleteTab4NonEE();



                })







            }


            else
            {


                this.deleteTab4NonEE();

            }




        }


        else{
            this.delete_proposal_service()
        }





    }


    deleteTab4NonEE()
    {
        this.transactionService.loadtab4details(this.selectedpart_submitter_delete.proposalid).subscribe(tab4_api_op=>{


            console.log(tab4_api_op);


            this.tab4_output=tab4_api_op;


            if(this.tab4_output.f_partphoto!='' && this.tab4_output.f_partphoto!=null)
            {
              const prt=this.tab4_output.f_partphoto;
              this.transactionService.delete_image(prt).subscribe(out_prt=>{

                console.log(out_prt);

              })

            }


        if(this.tab4_output.f_intcntrdsgnphoto!='' && this.tab4_output.f_intcntrdsgnphoto!=null)
        {
            const inter=this.tab4_output.f_intcntrdsgnphoto;
            this.transactionService.delete_image(inter).subscribe(out_inter=>{

                console.log(out_inter);

              })

        }

        if(this.tab4_output.f_extcntrdsgnphoto!='' && this.tab4_output.f_extcntrdsgnphoto!=null)
        {
            const exter=this.tab4_output.f_extcntrdsgnphoto;
            this.transactionService.delete_image(exter).subscribe(out_exter=>{

                console.log(out_exter);

              })

        }


        if(this.tab4_output.f_metelidplatephoto!='' && this.tab4_output.f_metelidplatephoto!=null)
        {
            const label=this.tab4_output.f_metelidplatephoto;
            this.transactionService.delete_image(label).subscribe(out_label=>{

                console.log('label',out_label);

              })

        }


        if(this.tab4_output.f_shpunitphoto!='' && this.tab4_output.f_shpunitphoto!=null)
        {
            const ship=this.tab4_output.f_shpunitphoto;
            this.transactionService.delete_image(ship).subscribe(out_ship=>{

                console.log(out_ship);

              })

        }


        if(this.tab4_output.subpresloaddoc!='' && this.tab4_output.subpresloaddoc!=null)
        {
            const sbu=this.tab4_output.subpresloaddoc;
            this.transactionService.delete_image(sbu).subscribe(out_sbu=>{

                console.log(out_sbu);

              })

        }



       this.delete_proposal_service();


        })
    }


    delete_proposal_service()
    {


        this.transactionService.delete_proposal(this.selectedpart_submitter_delete.proposalid).subscribe(response=>{


            console.log(response);


            if(response=='Success')
            {
              this.packagingprocess.addtoastinpackagingprocess({severity:'success', summary:'Success', detail:'Proposal Deleted Successfully Successfully'});


                let reg_url:string
                reg_url=this.transactionService.getregionurlvalue(this.transactionService.getregion());


                this.remove_stored_part();

                if(this.selectedpart_submitter_delete.propsl_type!=null && this.selectedpart_submitter_delete.propsl_type.toLowerCase()=='migration')
                {
                  if(this.current_roleid==1)
                  {
                    this.router.navigate(['/'+this.name+'/packagingprocess/packsupplieradmin/migrationsubmitter'])
                  }
                  else if(this.current_roleid==2)
                  {
                    this.router.navigate(['/'+this.name+'/packagingprocess/packdesignsourceadmin/migrationsubmitter'])
                  }
                }
                else // if(this.selectedpart_submitter.propsl_type!=null && this.selectedpart_submitter.propsl_type.toLowerCase()=="normal")
                {
                  if(this.current_roleid==1)
                  {

                  reg_url+='packagingprocess/packsupplieradmin/submiterdashboard';
                  this.router.navigate([reg_url]);
                  }

                  else if(this.current_roleid==2)
                  {
                      reg_url+='packagingprocess/packdesignsourceadmin/submiterdashboard';
                      this.router.navigate([reg_url]);
                  }
                }




            }


            else{
              this.packagingprocess.addtoastinpackagingprocess({severity:'error', summary: 'Error', detail: 'Proposal  Not Deleted,Please Try Again'})
            }

        })




    }

    deleteProposalServiceEE()
    {
        console.log(this.selectedpart_submitter_delete);

        this.transactionService.flushcompandimagedata_ee(this.selectedpart_submitter_delete.proposalid).subscribe(flush_out=>{


          console.log(flush_out);
          if(flush_out.toLowerCase()=='success')
          {


            this.addtoast({severity:'success', summary: 'success', detail: 'Component and Image details deleted  successfully'});
            this.delete_proposal_service();


            }


            else{
                this.addtoast({severity:'Error', summary: 'error', detail: 'Component and Image details not deleted!'});

            }



        })

    }



    remove_stored_part()
    {


        const regioncode=this.transactionService.getregioncode(this.transactionService.getregion());
        if(regioncode=='NA')
        {

          sessionStorage.removeItem('napackpropart');
        }


        if(regioncode=='EU')
        {
          sessionStorage.removeItem('eupackpropart');
        }



        if(regioncode=='AP')
        {
          sessionStorage.removeItem('appackpropart');
        }



        if(regioncode=='SA')
        {
          sessionStorage.removeItem('sapackpropart');
        }
    }

    isdelete_isviewproposal_disable():boolean
    {

        let sub_stored_value
        sub_stored_value = this.transactionService.getpartno_packpro(this.transactionService.getregion());

        if(sub_stored_value!=null)
        {
            if(sub_stored_value.proposallastsaved>0)
            {
                return false
            }
            else{
                return true
            }
        }


        else{
            return true
        }
    }


    view_exist_proposal()
    {
        this.view_proposal=true;
        this.allow_revision_history=false;
        this.selected_submitter_revision=this.transactionService.getpartno_packpro(this.transactionService.getregion());

        this.transactionService.getRevisionHistoryByProposalId(this.selected_submitter_revision.proposalid).subscribe(revHistAPi=>{
          console.log('getRevisionHistoryByProposalId API o/p',revHistAPi)
          if(revHistAPi != null && revHistAPi.length !=0)
          {
            this.allow_revision_history=true;
            this.enabledetails_proposal=false;
          }

        })

    }



view_revision_history_proposal()
{


this.enabledetails_proposal=true;
console.log('revision history');
/*
this.transactionService.loadtransacmasterdetails(this.selected_submitter_revision.proposalid).subscribe(transdet_search=>{

  console.log(transdet_search);
 this.proposal_details_revision=transdet_search;


 console.log(this.proposal_details_revision.revision_hstry);
  var revision_arr=[];
  this.revision_obj_search=[];

  if(this.proposal_details_revision.revision_hstry!=null)

 //if(transdet!=null)
  {
     revision_arr=this.proposal_details_revision.revision_hstry.split('|')
    //revision_arr=transdet.split('|')
    console.log(revision_arr)


         for(var i=0;i<revision_arr.length;i++)
         {
           var rev_arr=[];
           var rev_htm_str="";
           rev_arr=revision_arr[i].split('`');




           if(rev_arr.length==7)
           {

           rev_htm_str="<b>"+rev_arr[5]+";"+rev_arr[1]+"("+rev_arr[2]+")"+"</b>"+"<br>"+rev_arr[3]+"-"+rev_arr[4];
           if(rev_arr[6]!="-")
           {
             rev_htm_str+="<br>"+"Comments-"+rev_arr[6];
           }
          }


          this.revision_obj_search.push({rev_string:revision_arr[i],rev_html_string:rev_htm_str})

         }

  }


  console.log(this.revision_obj_search);


  this.revision_obj_search=this.revision_obj_search.reverse();
  console.log(this.revision_obj_search)

})*/
this.transactionService.getRevisionHistoryByProposalId(this.selected_submitter_revision.proposalid).subscribe(revHistArray=>{

  this.revHistFromServiceAPI=[];
  this.revHistFromServiceAPI=revHistArray;
  this.revision_obj_search=[];
  console.log('getRevisionHistoryByProposalId API o/p ',this.revHistFromServiceAPI);



  if(this.revHistFromServiceAPI != null && this.revHistFromServiceAPI.length != 0)
  {
    for(let i=0; i<this.revHistFromServiceAPI.length; i++)
    {
      let rev_htm_str='';


      if(this.revHistFromServiceAPI[i].evnttime != null && this.revHistFromServiceAPI[i].usrname != null)
      {
        rev_htm_str='<b>'+this.revHistFromServiceAPI[i].evnttime+';'+this.revHistFromServiceAPI[i].usrname+'('+this.revHistFromServiceAPI[i].email+')'+'</b>'+'<br>'+this.revHistFromServiceAPI[i].proposalstatuscurr+'-'+this.revHistFromServiceAPI[i].apprvractn;
      }
      else
      {
        rev_htm_str='<b>'+this.revHistFromServiceAPI[i].proposalstatuscurr+'-'+this.revHistFromServiceAPI[i].apprvractn+'</b>';
      }
      if(this.revHistFromServiceAPI[i].comments != null && this.revHistFromServiceAPI[i].comments != '-')
      {
        rev_htm_str+='<br>'+'Comments-'+this.revHistFromServiceAPI[i].comments;
      }

      this.revision_obj_search.push({rev_obj:this.revHistFromServiceAPI[i],rev_html_string:rev_htm_str})
    }
    console.log('Revision History Display',this.revision_obj_search)
  }

})
}


close_revision_history_proposal()
{
this.enabledetails_proposal=false;

}






   ngOnDestroy(){
        this.transactionService.destroyPartnoSessionStorage(this.region_code);
    }

}
