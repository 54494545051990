/* tslint:disable */
import { Component, OnInit } from '@angular/core';
import { Workbook } from 'exceljs';
import { TransactionService } from '../loginpage/Transaction.service';
import { archiveSupplierContactHeader, archiveSupplierContactOutput, plantListGppArchivePackagingSummaryRep, programListGppArchivePackagingSummaryRep } from '../loginpage/transactions';
import * as fs from 'file-saver';

@Component({
  selector: 'app-gpparchivesuppliercontactreport',
  templateUrl: './gpparchivesuppliercontactreport.component.html',
  styleUrls: ['./gpparchivesuppliercontactreport.component.css']
})
export class GpparchivesuppliercontactreportComponent implements OnInit {


  region:string;
  regionCode:string;
  cdsid:string;

  plantcode='';
  selected_plant_code:plantListGppArchivePackagingSummaryRep={plantgsdbcode:''};
  selected_plant_codes_list:plantListGppArchivePackagingSummaryRep[]=[];
  plantcodelist:plantListGppArchivePackagingSummaryRep[]=[];
  all_selected_on_plant_code=false;
  plantcodeselected=false;

  programsList:programListGppArchivePackagingSummaryRep[]=[];
  programListalongwithPlants:programListGppArchivePackagingSummaryRep[]=[];
  selected_programs_list:programListGppArchivePackagingSummaryRep[]=[];
  programcodes='';
  all_selected_on_program=false;




  plantCodeToAPI:string;
  programCodeToAPI:string;

  archiveSupplierContactReportList:archiveSupplierContactOutput[]=[];
  archiveSupplierContactHeaderList:archiveSupplierContactHeader[]=[];
  errorLoadingReport='';
  successMessage='';

  enableArchiveExportButton=true;

  loaderarchivesuppliercontact=false;

  constructor(private transactionService:TransactionService) { }

  ngOnInit(): void {
       // console.log(sessionStorage.getItem("testwork"));
       this.region=this.transactionService.getregion();
       this.regionCode=this.transactionService.getregioncode(this.region);
       this.cdsid=this.transactionService.getcdsid();


       this.transactionService.getPlantCodesGppPackagingsummaryRep(this.regionCode).subscribe(plantco=>{
         this.plantcodelist=[];
         if(plantco != null && plantco.length != 0)
         {
         const resultplant:plantListGppArchivePackagingSummaryRep[]=plantco;
         const plantcodetemp:plantListGppArchivePackagingSummaryRep[]=[];

         // plantcodetemp.push({'plantgsdbcode':'ALL',});
         for(let h=0;h<resultplant.length;h++)
         {
           plantcodetemp.push({plantgsdbcode:resultplant[h].plantgsdbcode})
         }
         this.plantcodelist=plantcodetemp
         this.selected_plant_codes_list=[];
         console.log('plantcodes from backend',this.plantcodelist);
       }
       })
  }

  onhide_plant_codes()
  {
    this.all_selected_on_plant_code=false;
    this.plantcode='';

    /* checking whether length of selected list is less than one */
    let plantslen=false;
    if(this.selected_plant_codes_list.length==this.plantcodelist.length-1)
    {
      plantslen=true;
    }

    for(let ptc=0;ptc<this.selected_plant_codes_list.length;ptc++)
    {
      this.plantcode+=this.selected_plant_codes_list[ptc].plantgsdbcode;
      if(ptc < this.selected_plant_codes_list.length-1)
      {
        this.plantcode+=',';
      }

      if(this.selected_plant_codes_list[ptc].plantgsdbcode=='ALL')
      {
        this.all_selected_on_plant_code=true;
        this.plantcode='all';
        break;
      }
    }

    /* If selectedvariables length is one less than total length but all is not selected
       which means except all remaining are selected so make
       all_selected_on_plant_code as true and make code  send all to backend */

    if(this.all_selected_on_plant_code==false && plantslen==true)
    {
      this.plantcode='all';
      this.all_selected_on_plant_code=true;
    }
    console.log('plant codes in Hide',this.plantcode,typeof(this.plantcode));

    if(this.plantcode.length>0)
    {
      this.plantcodeselected=true;
      this.loadprogramcodes();
      console.log('plantcode selected is true')
    }
    else
    {
      this.plantcodeselected=false;
      this.programsList=[];
      this.selected_programs_list=[];
      this.programListalongwithPlants=[];
      this.programcodes='';
    }
  }

  change_plant_codes()
  {
    let all_selected_on_plant_code=false;
    let lenlessthan1_plant_code=false;
    this.plantcode='';

    // When plants is changed programs variable shd be emptied and
    // when click outside after selecting plants,these variables will have loaded data
    this.programsList=[];
    this.selected_programs_list=[];
    this.programcodes='';
    this.programListalongwithPlants=[];


    if(this.selected_plant_codes_list.length==this.plantcodelist.length-1)
    {
      lenlessthan1_plant_code=true;
    }
    for(let j=0;j<this.selected_plant_codes_list.length;j++)
    {
      if(this.selected_plant_codes_list[j].plantgsdbcode=='ALL')
      {
        all_selected_on_plant_code=true;
      }
    }

    if(all_selected_on_plant_code==false && lenlessthan1_plant_code==true)
    {
      all_selected_on_plant_code=true;
    }

    if(all_selected_on_plant_code==true)
    {
      this.plantcode='all';
      this.selected_plant_codes_list=[];
      this.selected_plant_codes_list.push({plantgsdbcode:'ALL'});
    }
  }

  plantCodeChanged()
  {
    this.plantcodeselected=true;
    this.programsList=[];
    this.selected_programs_list=[];
    this.programcodes='';
    this.programListalongwithPlants=[];

    this.plantcode=this.selected_plant_code.plantgsdbcode;
    this.loadprogramcodes();
  }

  loadprogramcodes()
  {
    const tempobj:programListGppArchivePackagingSummaryRep[]=[];

    // selected_region_from_list will have correct region even when the component is called from any parent
    // because selected_region_from_list will be set as current region when this component is called for copy container dialog

    const regval=this.transactionService.getregioncode(this.region);
    this.transactionService.getProgramCodesGppPackagingsummaryRep(regval,this.plantcode).subscribe(progs=>{
      this.programsList=[];
      this.programListalongwithPlants=[];
      this.programsList=progs;
      if(this.programsList.length>0)
      {
        tempobj.push({plantgsdbcode:'',programname:'All',programnamewithplant:'All'});
      }
      for(let l=0;l<this.programsList.length;l++)
      {
        let programwasalreadypresent=false;
        if(tempobj.length!=0)
        {
          if(tempobj.some(o=>o.programname.toLowerCase() == this.programsList[l].programname.toLowerCase()))
          {
            const index=tempobj.findIndex(object=>{return object.programname.toLowerCase()==this.programsList[l].programname.toLowerCase()});
            const newvalue=tempobj[index].programnamewithplant.slice(0,tempobj[index].programnamewithplant.length-1) ;
            tempobj[index].programnamewithplant=newvalue+', '+this.programsList[l].plantgsdbcode+')';
            programwasalreadypresent=true;
          }
        }
        if(!programwasalreadypresent)
        {
          tempobj.push({programname:this.programsList[l].programname,programnamewithplant:this.programsList[l].programname+' ('+this.programsList[l].plantgsdbcode+')',plantgsdbcode:this.programsList[l].plantgsdbcode});
        }

      }
      this.programListalongwithPlants=JSON.parse(JSON.stringify(tempobj));

      console.log('programList',this.programsList)
      console.log('programListalongwithPlants',this.programListalongwithPlants);
    })
  }

  onhide_program_codes()
  {
    this.all_selected_on_program=false;
    this.programcodes='';

    /* checking whether length of selected list is less than one */
    let prglen=false;
    if(this.selected_programs_list.length==this.programListalongwithPlants.length-1)
    {
      prglen=true;
    }

    for(let prgi=0;prgi<this.selected_programs_list.length;prgi++)
    {
      this.programcodes+=this.selected_programs_list[prgi].programname;
      if(prgi < this.selected_programs_list.length-1)
      {
        this.programcodes+=',';
      }

      if(this.selected_programs_list[prgi].programname=='All')
      {
        this.all_selected_on_program=true;
        this.programcodes='all';
        break;
      }
    }

    /* If selectedvariables length is one less than total length but all is not selected
       which means except all remaining are selected so make
       all_selected_on_program as true and make code  send all to backend */

    if(this.all_selected_on_program==false && prglen==true)
    {
      this.programcodes='all';
      this.all_selected_on_program=true;
    }
    console.log('program codes in Hide',this.programcodes,typeof(this.programcodes));

  }

  change_prgm_codes()
  {
    let all_selected_on_program=false;
    let lenlessthan1_prg_code=false;
    this.programcodes='';

    if(this.selected_programs_list.length==this.programListalongwithPlants.length-1)
    {
      lenlessthan1_prg_code=true;
    }
    for(let j=0;j<this.selected_programs_list.length;j++)
    {
      if(this.selected_programs_list[j].programname=='All')
      {
        all_selected_on_program=true;
      }
    }

    if(all_selected_on_program==false && lenlessthan1_prg_code==true)
    {
      all_selected_on_program=true;
    }

    if(all_selected_on_program==true)
    {
      this.programcodes='all';
      this.selected_programs_list=[];
      const regval=this.transactionService.getregioncode(this.region);
      this.selected_programs_list.push({plantgsdbcode:'',programname:'All',programnamewithplant:'All'});
    }
  }

  generateArchiveSupplierContactReport()
  {
    this.enableArchiveExportButton=false;
    this.loaderarchivesuppliercontact=true;

    if(this.plantcode=='' )
    {
      this.plantCodeToAPI='all';
    }
    else
    {
      this.plantCodeToAPI=this.plantcode;
    }

    if(this.programcodes=='')
    {
      this.programCodeToAPI='all';
    }
    else
    {
      this.programCodeToAPI=this.programcodes;
    }
    console.log('before api call','plantcode',this.plantCodeToAPI,'programcode',this.programCodeToAPI)

    this.archiveSupplierContactHeaderList=[];
    this.archiveSupplierContactReportList=[];
    this.errorLoadingReport='';
    this.successMessage='Generating Report, Please Wait ...'

    this.transactionService.getArchiveSupplierContactData(this.plantCodeToAPI,this.programCodeToAPI,this.regionCode).subscribe((archivesuppcntct)=>{

      console.log('getArchiveSupplierContactData api o/p',archivesuppcntct);
      if(archivesuppcntct != null && archivesuppcntct.length != 0)
      {
      this.archiveSupplierContactReportList=archivesuppcntct;

      this.transactionService.getArchiveSupplierContactHeader().subscribe((archivesuppcntcthdr)=>{

        console.log('getArchiveSupplierContactHeader api o/p',archivesuppcntcthdr);
        if(archivesuppcntcthdr != null && archivesuppcntcthdr.length != 0)
        {
          this.archiveSupplierContactHeaderList=archivesuppcntcthdr;
          console.log('before exportArchiveSupplietContactDataToExcel fn call - ','header',this.archiveSupplierContactHeaderList,'data',this.archiveSupplierContactReportList);
          this.exportArchiveSupplietContactDataToExcel();
        }
        else
        {
          this.enableArchiveExportButton=true;
          console.log('Archive Supplier Contact - No header to load')
          this.successMessage='';
          this.errorLoadingReport='Error occured While Generating Report';
          this.loaderarchivesuppliercontact=false;
        }
      },
        (error) => {                              // Error callback
          console.error('Error in Header API call - getArchiveSupplierContactHeader',error)
          this.successMessage='';
          this.errorLoadingReport = 'Error Occured in Getting Archive Supplier Contact Data, Please Try Again';
          this.enableArchiveExportButton=true;
          this.loaderarchivesuppliercontact=false;
        }
      )
      }
      else
      {
        this.enableArchiveExportButton=true;
        console.log('Archive Supplier Contact - No Data Found To Generate Report')
        this.successMessage='';
        this.errorLoadingReport='No Data Found To Generate Report'
        this.loaderarchivesuppliercontact=false
      }

    },
        (error) => {                              // Error callback
          console.error('Error in Archive Supplier Contact Data API call - getArchiveSupplierContactData',error)
          this.successMessage='';
          this.errorLoadingReport = 'Error Occured in Getting Archive Supplier Contact Data, Please Try Again';
          this.enableArchiveExportButton=true;
          this.loaderarchivesuppliercontact=false;
        }
    )
  }


  clearFilter()
  {
    this.selected_plant_codes_list=[];
    this.selected_plant_code={plantgsdbcode:''};
    this.plantcode='';
    this.plantcodeselected=false;
    this.selected_programs_list=[];
    this.programcodes='';
    this.archiveSupplierContactHeaderList=[];
    this.archiveSupplierContactReportList=[];
    this.errorLoadingReport='';
    this.successMessage=''
    this.enableArchiveExportButton=true;
  }

  exportArchiveSupplietContactDataToExcel()
  {
    console.log('inside exportArchiveSupplietContactDataToExcel fn ',this.archiveSupplierContactHeaderList,this.archiveSupplierContactReportList)
    const title = 'Archive - Supplier Contact Report';
    const header = this.archiveSupplierContactHeaderList;
    const data = this.archiveSupplierContactReportList;

    console.log(header,data);

    // Create a workbook with a worksheet
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Archive - Supplier Contact');

    // Adding Header Row
    const arr=[];
    header.forEach((d: any) => {
      // console.log(d);
      arr.push(
        {header:d.col_header,key:d.col_field_name,color:d.col_color}
        )
    })

    worksheet.columns = arr;
    console.log(arr);
   const headerRow = worksheet.getRow(1)
    console.log(headerRow)
    headerRow.eachCell((cell, number) => {
      // console.log(cell)
      const findindex=arr.findIndex(ele=>ele.header.trim() == cell.value.toString().trim())

      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: arr[findindex].color},
        bgColor: { argb: '' },
      };
      cell.font = {
        bold: true,
        color: { argb: '000000' },
        size: 12,
      };
      cell.alignment = { vertical: 'bottom', horizontal: 'left', textRotation: 90 , wrapText: true };
      cell.border = {

        left: {style:'thin'},
        bottom: {style:'thin'},
        right: {style:'thin'}
      };
    });

    // Adding Data
    data.forEach((d: any) => {
      worksheet.addRow(d);
    });
    console.log(worksheet)

    // Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, title + '.xlsx');

      this.successMessage='Report Generated Successfully !!!'
      this.enableArchiveExportButton=true;
      this.loaderarchivesuppliercontact=false;
    });
  }

}
