import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Location} from '@angular/common';
import {UserIdService} from '../services/user-id.service';

/** This guard is used in the oauth callback flow, consuming the URL before the Angular router can.
 * The URL built by the auth provider is technically invalid (no ? for the queryParams), so this guard
 * needs to consume the URL before Angular's router (which would fail to parse it).
 */
@Injectable()
export class UrlConsumerService implements CanActivate {

	/** Creates an instance of the UrlConsumerService
	 *
	 * @param router route instance for current routing params
	 * @param location the angular location service for interacting with the browser location object
	 * @param idService the angular service for handling user ID
	 */
	constructor(private router: Router, private location: Location, private idService: UserIdService) {
	}

	/** the actual guard fuction. Parses the queryString and stores the params in sessionStorage.
	 * Redirects the user to the default route, or to the route that was stored before the auth redirect.
	 *
	 * @param route the snapshot of the current ActivatedRoute
	 * @param state the snapshot of the current RouterState
	 * @returns whether route can be activated or not
	 */
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		const queryParamsObj = this.getQueryParams();

		// @ts-ignore
        if (queryParamsObj && queryParamsObj.access_token != null) {
			// token is part 2 of a JWT (index 1)
            // @ts-ignore
			const accessToken = atob(queryParamsObj.access_token.split('.')[1]);
			const jsonToken = JSON.parse(accessToken);
			// console.log(accessToken)
			// console.log(jsonToken);

			sessionStorage.setItem('strAccessToken', accessToken);
            // @ts-ignore
			sessionStorage.setItem('encodedAccessToken', queryParamsObj.access_token);

			sessionStorage.setItem('tokenIssue', jsonToken.iat);
			sessionStorage.setItem('tokenExp', jsonToken.exp);
			sessionStorage.setItem('userId', jsonToken.CommonName);
			console.log('Common Name' + jsonToken.CommonName);
			this.idService.setUserID(jsonToken.CommonName);

			// Redirect to originally requested URL
			if (!sessionStorage.getItem('redirectURL')) {
				sessionStorage.setItem('redirectURL', '/');
			}
			this.router.navigate([sessionStorage.redirectURL]);
			const redir: string = [sessionStorage.redirectURL][0];
			const [component, query] = redir.split('?');
			const queryObj = {};
			const queryArgs = query.split('&');
			queryArgs.forEach(item => {
				const pair = item.split('=');
				queryObj[pair[0]] = pair[1];
			});
			const queryParms = {};
            // @ts-ignore
			queryParms.queryParams = queryObj;
			this.router.navigate([component], queryParms);
			return true;
		} else {
			console.error('Invalid Token');
			console.log('urlconsumer');
		}

		return false;
	}

	/** Parses the technically malformed queryString to pick off the token and associated properties.
	 * @returns The queryString params in Object format, or null if the string was invalid.
	 */
	getQueryParams() {
		if (this.location.path(true).indexOf('access_token') === 0) {
			const queryString = this.location.path(true);

			// URLSearchParams should be the solution here. it's not working. so we did it manually
			const paramArray = queryString.split('&');
			const queryParamsObj = new Object();

			for (const param of paramArray) {
				// we can't use a simple split() call here as base64 allows for = padding
				const i = param.indexOf('=');
				const splitArray = [param.slice(0, i), param.slice(i + 1)];
				queryParamsObj[splitArray[0]] = splitArray[1];
			}
			return queryParamsObj;
		} else {
			return null;
		}

	}
}
