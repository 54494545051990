/* tslint:disable */
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { analyzeFileForInjectables, CompileShallowModuleMetadata } from '@angular/compiler';
import { temporaryAllocator } from '@angular/compiler/src/render3/view/util';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { defaultCipherList } from 'constants';
import { copyFile } from 'fs';
import { Table } from 'primeng';
import { ConfirmationService, MenuItem, MessageService, TreeNode } from 'primeng/api';
import { Observable } from 'rxjs';
import { TransactionService } from '../loginpage/Transaction.service';
import { addContainer, addlncompdetls, addlncomponentIds, changeTrackingForImprovement, cntrandsudetailspost, compdetailsfortype01to05, compmaterialpost, componentlist, componenttypewithcodeanddesc, containerDetailsCntrAdmin, containerMaterial, containerMaterialList, containerTypeCode, fromdiplayvalues_all, partDetailForPost, regiondetails, releaselevelList, selec_prt_pro_details, storecomponent, storecomponentforpost, transportList, transportListWithActiveStatus } from '../loginpage/transactions';
// import { PackagingprocessComponent } from '../packagingprocess/packagingprocess.component';
// import { PackagingproposalComponent } from '../packagingproposal/packagingproposal.component';
// import { PackproposaltabtwoComponent } from '../packproposaltabtwo/packproposaltabtwo.component';
// import { PartsandatributesComponent } from '../partsandatributes/partsandatributes.component';
import { ImprovementpackagingproposalComponent } from '../improvementpackagingproposal/improvementpackagingproposal.component';
import { PackagingprocessdashboardComponent } from '../packagingprocessdashboard/packagingprocessdashboard.component';
import { ImprovementtaboneComponent } from '../improvementtabone/improvementtabone.component';
import { ImprovementtabtwoComponent } from '../improvementtabtwo/improvementtabtwo.component';

@Component({
  selector: 'app-improvementtabthree',
  templateUrl: './improvementtabthree.component.html',
  styleUrls: ['./improvementtabthree.component.css'],
  providers:[MessageService,ImprovementtabtwoComponent,ConfirmationService]
})
export class ImprovementtabthreeComponent implements OnInit {



    name:string;
    tab4:string;
    tab4menuitem:MenuItem;
    flag:boolean;
    region:string;



    region_code:string;


    id_proposal_compmat:number;
    noSupDialog_compmat=false;
    content_supp_compmat:string;
    noPrtDialog_compmat=false;
    content_selectedprt_compmat:string;
    // roleid_compmat:number;
    // designsource_code_compmat:string;
    // supp_code_compmat:string;
    noDesignsourceDialog_compmat=false;
  content_Designsource_compmat='';


  noroleselectedDialog=false;
    norolecontent='';

    cntrbasedisp = '';
  cntrsuffixdisp = '';

  
  
  
    comp_material_forpost:compmaterialpost={
      id_proposal:0,
    cntr_shp_unit:'',
    shp_unt_tar_wt:0.000,
    shp_unt_gross_wt:0.000,
    cntr_gross_wt:0.000,
    ld_unload_inst:'',
    cntr_automtn_intf:'No',
    transp_tested:'No',
    tst_reprt_num:'',
    mot:'',
    dangrs_good:'No',
    dangrs_good_ship_detls:'',
    rel_level:''
  }


  disbale_gds_details_value=true;
  disable_reports_value=true;
  // selected_mode_transport:transportList={	cd_transport: "",transport_desc: ""};
  // transport_list:transportList[]=[];
  selected_mode_transport:transportListWithActiveStatus={	cd_transport: 0,transport_desc: '',active_stat:'',updated_dt:'',updated_by:''};
  transport_list:transportListWithActiveStatus[]=[];
  selected_release_level:releaselevelList={releaselevel:'',releaselevel_desc:''}
  releaselevel_list:releaselevelList[]=[];
  flagc=false;


    tab1route:string;
    tab2route:string;
    tab3route:string;
    tab4route:string;
    selectedpart_submitter_tab3:selec_prt_pro_details={simplifiedpartno:'-',proposalid:0,bompartmasterid:0,plantgsdbcode:'',proposallastsaved:0,part_status:'',supplier_code:'',proposallastsaved_ee:0,is_ee_proposal:false,currentview_ee:false,from_draft:false,propsl_type:'',imp_part_status:''};
    current_region_code_prt:any;
    erg_lim_frm_reg_admin:regiondetails={
      cd_region: '',
      cntr_ergo_limit:0,
      bom_err_thld: 0,
      allow_nonstd_comp_ind: '',
      urge_prt_notice_email_freq: 0,
      allow_multi_prt_prop_ind: '',
      limit_prts_recvd_plt: '',
      emg_exp_pkg_reqd_ind: '',
      pkg_rel_level_src: '',
      suspd_duratn_thld:0,
      uom:''
    }

    cntrorshpunitradio='Container';
    componentOrAid='Component';
    exp_returnable='E';
    rowadded:boolean;
    rowedited:boolean;


    region_Details_comp:regiondetails={
      cd_region: '',
      cntr_ergo_limit:0,
      bom_err_thld: 0,
      allow_nonstd_comp_ind: '',
      urge_prt_notice_email_freq: 0,
      allow_multi_prt_prop_ind: '',
      limit_prts_recvd_plt: '',
      emg_exp_pkg_reqd_ind: '',
      pkg_rel_level_src: '',
      suspd_duratn_thld:0,
      uom:''
    }


    is_bom_resource=false;

    /*********************************Use these details from API**************************************************************** */
    shippingunittareweight=0;
    shippingunitgrossweight=0;
    containergrossweight=0;
    temp_cntr_gross_weight=0;
    temp_shp_unt_tare_wt=0;
    no_of_container=0;
    deleteflagbeforesave=false;
    deleteflag=false; // variable will be set when the tab is already saved and they are deleting componnets
                              // so even when populate grid is called in delete function weight not will be calculated from grid

    containergrossweightInKGS=0;
    shippingunitgrossweightInKGS=0;
    /*********************************Use these details from API**************************************************************** */


    // cntrMatr:containerMaterial[]=[];
    // selected_material:containerMaterial;

   cntrMatr:containerMaterialList[]=[];
   selected_material:containerMaterialList;

    componenentassociatedwithcontainer:compdetailsfortype01to05[]=[];
    componentdetails:storecomponent={
      id_proposal:0,
      id_comp:0,
      comp_base:'',
      comp_suffix:'',
      comp_desc:'',
      qt_per_min_su:0,
      comp_ret_type:'',
      updated_by:'',
      updated_dt:'',
      comp_l:0,
      comp_w:0,
      comp_h:0,
      comp_tar_wt:0,
      nestedfolderHt:0,
      comp_code:'',
      comp_ownershp:'',
      comp_material:'',
      tooledcomp:'',
      is_compulsary:'',
      compselctnfor:'',

      /* added */
      comp_bsd:'',
      comp_lwh:'',
      typ_no:0

    };  // varaible to store componeny details when component is selected from component number list

    componentdetailsforedit:storecomponent={
      id_proposal:0,
      id_comp:0,
      comp_base:'',
      comp_suffix:'',
      comp_desc:'',
      qt_per_min_su:0,
      comp_ret_type:'',
      updated_by:'',
      updated_dt:'',
      comp_l:0,
      comp_w:0,
      comp_h:0,
      comp_tar_wt:0,
      nestedfolderHt:0,
      comp_code:'',
      comp_ownershp:'',
      comp_material:'',
      tooledcomp:'',
      is_compulsary:'',
      compselctnfor:'',

      /* added */
      comp_bsd:'',
      comp_lwh:'',
      typ_no:0

    };
    componentdetailsfordelete:storecomponent;
   componentdetailsgrid:storecomponent[]=[]; // variable used to populate rows in Grid
  // componentdetailsgrid:Observable<any>;

  componentsStoredInProposal:storecomponent[]=[];
  componentsFetchedFromCMMS:compdetailsfortype01to05[]=[];
  compulsoryComponentFromCMMS:storecomponentforpost[]=[];
  
  
    additionalcomponentIDs:addlncomponentIds[]=[];
    additionalcomponentDetails:addlncompdetls;
    alladdlncompdetls:addlncompdetls[]=[];

    http:HttpClient


    comp_typ_list:TreeNode[] // List of component type to be showed in componenttype dropdown
    items:TreeNode[]

    comp_type:containerTypeCode[]; // variable to get component type from API
    comp_type_and_desc:componenttypewithcodeanddesc[];
    comp_type_and_desc_master:componenttypewithcodeanddesc[];
    comp_type_desc=''

    val:TreeNode; // selected component type from UI
    component_type:componenttypewithcodeanddesc={cd_cntr_typ:'',cntr_typ_and_desc:'',cntr_typ_desc:'',typ_no:0}; // selected component type from UI is assigned here

    componentlistbasedontype:storecomponent[]=[]; // variable that has component number list
    componentnumberselected:storecomponent; // component number selected from component number list box
    tempcomps:storecomponent[]=[];
    tempcomplist:componentlist[]=[];
    tempo:storecomponentforpost[];

    displaydialog:boolean; // variable to control the component details dialog
    displayeditdialog:boolean;
    compulsorycompdialog:boolean;
    compulsorycomponents:storecomponentforpost[]=[];

    contadmindetls:containerDetailsCntrAdmin;

    cols:any;
    cols2:any;
    frozenCols:any;

    editcancel_click=false;

    variableforsave:storecomponentforpost;

    edit_measure_comp='lbs';
    edit_measure_comp_add='lbs/inch';
    edit_measure_comp_gridedit='lbs/inch';
    disableImprovementExpReturnable=false;

    unitom  = '';

    cntr_comp_wt=0;
  shp_comp_wt=0;
  temp_shp_compwt = 0;

  shp_comp_wt_add = 0;
  temp_shp_compwt_add = 0;

  shp_comp_wt_edit = 0;
  temp_shp_compwt_edit = 0;

  temp_comwgt_add = 0;
  temp_comwgt_edit = 0;

    comb_wgt: number;
    comb_wgtadd : number;

   wgt_flag = true;



    existChangeStatusTab3='';

    private originalTab3Details:compmaterialpost;
    private changedTab3Details:compmaterialpost;
    private originalTab3ComponentForEdit:storecomponent;
    private changedTab3ComponentForEdit:storecomponent;

    sucontarewtInvalid = false;
    sucontarewtInvalidReason: string = null;
    sugrosswtInvalid = false;
    sugrossewtInvalidReason: string = null;
    cntrgrosswtInvalid = false;
    cntrgrosswtInvalidReason: string = null;

  comp_descInValid = false;
  comp_descInValidReason: string = null;
  comp_l_InValid = false;
  comp_l_InValidReason: string = null;
  comp_w_InValid = false;
  comp_w_InValidReason: string = null;
  comp_h_InValid = false;
  comp_h_InValidReason: string = null;
  comp_tare_wgtInValid = false;
  comp_tare_wgtInValidReason: string = null;
  qt_pershp_untInValid = false;
  qt_pershp_untInValidReason: string= null;
  ownrshpInValid = false;
  ownrshpInValidReason: string = null;
  // tooledCompInValid: boolean = false;
  // tooledCompInValidReason: string = null;
  selectMaterialInValid: boolean = false;
  selectMaterialInValidReason: string = null;
  sumwgts_InValid = false;
  sumwgts_InValidReason: string = null;

  totl_wt_InValid = false;
  totl_wt_InValidReason: string = null;

  comp_totl_wt_InValid = false;
  comp_totl_wt_InValidReason: string = null;

  num_of_cntr_InValid = false;

  enable_savecomps = true;
  save_comdets = false;
  editComponentTypeDisplay='';

    shippingunittareweight_display:number=0;
    shippingunitgrossweight_display:number=0;
    containergrossweight_display:number=0;
    
    shippingUnitExteriorHeightDisplay:number=0;
  shippingUnitExteriorHeightCalculated:number=0;
  initialShippingUnitExteriorHeightCalculated:number=0;
  Tab2DataForPost:cntrandsudetailspost;
  noLayersPerShippingUnit:number=0;
  compAddedChangeHeight:boolean=false;
  compEdidtedChangeHeight:boolean=false;
  compCompulsuryAdded:boolean=false;
  suexthgtInvalid:boolean=false;
  suexthgtInValidReason="";

  shippinUnitNestedFoldedHeight:number=0;
  suNestedHtInValid:boolean=false;
  suNestedHtInValidReason:string=null;

    constructor(private transactionService:TransactionService, private router:Router,private packagingproposal:ImprovementpackagingproposalComponent ,private partsandattri:ImprovementtaboneComponent ,private packproposaltabtwo:ImprovementtabtwoComponent ,
      private confirmationService:ConfirmationService,private date:DatePipe,private packagingprocess:PackagingprocessdashboardComponent ){}


    ngOnInit(): void {
      this.region=this.transactionService.getregion();


      this.region_code=this.transactionService.getregioncode(this.region);

      this.editcancel_click=false;

      this.releaselevel_list=[
        {releaselevel_desc:'1-Shipping Unit',releaselevel:'1'},
        {releaselevel_desc:'2-Level Layer',releaselevel:'2'},
        {releaselevel_desc:'3-Container/Carton',releaselevel:'3'},
        {releaselevel_desc:'4-Released (ILVS/CBR/PIA)',releaselevel:'4'},
    ]





    this.selected_release_level=this.releaselevel_list[0];
    this.comp_material_forpost.rel_level=this.releaselevel_list[0].releaselevel

    console.log(this.selected_release_level)
    this.selectedpart_submitter_tab3 = this.transactionService.getpartno_packpro(this.transactionService.getregion());

    this.existChangeStatusTab3=sessionStorage.getItem('ProposalChangeStatus');


    if(this.existChangeStatusTab3 == null || this.existChangeStatusTab3 =='null' || this.existChangeStatusTab3.trim() == '')
    {
      this.existChangeStatusTab3='unchanged';
      sessionStorage.setItem('ProposalChangeStatus','unchanged');
    }

  console.log('this.selectedpart_submitter_tab3',this.selectedpart_submitter_tab3)

  console.log(this.selectedpart_submitter_tab3)


      this.tab1route=this.transactionService.getregionurlvalue(this.region)+'packagingprocessdashboard/improvementproposalcreation/partsandattri';
      this.tab2route=this.transactionService.getregionurlvalue(this.region)+'packagingprocessdashboard/improvementproposalcreation/improvementpackproposaltabtwo';
      this.tab3route=this.transactionService.getregionurlvalue(this.region)+'packagingprocessdashboard/improvementproposalcreation/improvementpackproposaltabthree';
      this.tab4route=this.transactionService.getregionurlvalue(this.region)+'packagingprocessdashboard/improvementproposalcreation/improvementpackproposaltabfour';
      this.selectedpart_submitter_tab3 = this.transactionService.getpartno_packpro(this.transactionService.getregion());

      console.log('this.selectedpart_submitter_tab3',this.selectedpart_submitter_tab3)
      console.log('this.savedtab',this.packagingproposal.savedtab);

      this.packagingproposal.savedtab=this.packagingproposal.savedtab;


          if(this.packagingproposal.savedtab==3 || this.packagingproposal.savedtab==4 )
          {
            for(const i of this.packagingproposal.tabpackagingproposalmenuitems.values())
            {
                  console.log(i);
                  if(i.label!='Images/Photos & Comments')
                  {
                      this.packagingproposal.flag3=true;
                  }
                  else{
                      this.packagingproposal.flag3=false;
                      break;
                  }
            }

            if(this.packagingproposal.flag3)
            {
              // this.packagingproposal.tabpackagingproposalmenuitems.push({label: 'COS, Container & Shipping Unit', routerLink:'/northamerica/proposalcreation/packproposaltabtwo', icon: 'fas fa-person-booth'});
             // this.packagingproposal.tabpackagingproposalmenuitems.push({label:'Component & Material',routerLink:'/northamerica/proposalcreation/packproposaltabthree', icon: 'fas fa-person-booth'});
             this.packagingproposal.tabpackagingproposalmenuitems.push({label:'Images/Photos & Comments',routerLink:this.tab4route, icon: 'pi pi-images'});}
          }


          /*this.roleid_compmat=this.transactionService.getcurrentrole(this.region)
          console.log(this.roleid_compmat)



          if(this.roleid_compmat==null)
          {


            this.noroleselectedDialog=true;
            this.norolecontent="No role selected "
          }
          if(this.roleid_compmat==1)
          {
          this.supp_code_compmat = this.transactionService.get_supplier(this.region);
          console.log(this.supp_code_compmat);
          }
          else if( this.roleid_compmat==2)
          {
            this.designsource_code_compmat = this.transactionService.get_designsource(this.region);
            console.log(this.designsource_code_compmat)
          }*/

          // this.transactionService.storepage(this.current_region_contandcos, "packagingprocess/proposalcreation/packproposaltabtwo");
          // this.selectedpart_submitter=this.submitterdashboard.pushdata();


          // Get details TAB2
          this.post_tab3init();

          /*if(document.getElementById("overlaycmp") != null )
          {
          document.getElementById("overlaycmp").style.display = "block";
          }
          if(document.getElementById("loadercmp") != null )
          {
          document.getElementById("loadercmp").style.display = "block";
          }  */
          console.log(this.selectedpart_submitter_tab3)

          /*if(this.roleid_compmat==1)
          {
          */
          if (this.selectedpart_submitter_tab3 != null && this.selectedpart_submitter_tab3.simplifiedpartno!='-' && this.selectedpart_submitter_tab3.proposalid!=0 )
          {

            this.id_proposal_compmat=this.selectedpart_submitter_tab3.proposalid;
            console.log(this.id_proposal_compmat)
            this.get_transport_list();
            this.get_erg_limit();

            this.compmateriallistinit();
            this.componentdetailsinit();
            this.componentdetailseditinit();
            this.componentdeletedetailsinit();
            this.displaydialog=false;
            this.displayeditdialog=false;
            this.compulsorycompdialog=false;
            this.getcntrtypelist();
            this.compInValidationInit();
            this.tab3InValidationInit();


            
            //get tab3 details if last saved proposal is greater than current
            if(this.selectedpart_submitter_tab3.proposallastsaved>2)
            {
              this.getcomponentassociatedforcntr();
              // this.populateListOfMaterialsGrid([]);

              this.transactionService.loadtab3detailsfromAPI(this.selectedpart_submitter_tab3.proposalid).subscribe(tab3output=>{
                let tab3detailsoutput:compmaterialpost;
                tab3detailsoutput=tab3output;



                console.log('Tab3output',tab3detailsoutput);
                this.cntrorshpunitradio=tab3detailsoutput.cntr_shp_unit;
                this.shippingunittareweight=tab3detailsoutput.shp_unt_tar_wt;
                this.shippingunitgrossweight=tab3detailsoutput.shp_unt_gross_wt;
                this.containergrossweight=tab3detailsoutput.cntr_gross_wt;

                this.comp_material_forpost.id_proposal=tab3detailsoutput.id_proposal;
                this.comp_material_forpost.cntr_shp_unit=this.cntrorshpunitradio;
                this.comp_material_forpost.shp_unt_tar_wt=this.shippingunittareweight;
                this.comp_material_forpost.shp_unt_gross_wt=this.shippingunitgrossweight;
                this.comp_material_forpost.cntr_gross_wt=this.containergrossweight;
                this.comp_material_forpost.cntr_automtn_intf=tab3detailsoutput.cntr_automtn_intf;
                this.comp_material_forpost.transp_tested=tab3detailsoutput.transp_tested;
                this.comp_material_forpost.tst_reprt_num=tab3detailsoutput.tst_reprt_num;
                this.comp_material_forpost.dangrs_good=tab3detailsoutput.dangrs_good;
                this.comp_material_forpost.dangrs_good_ship_detls=tab3detailsoutput.dangrs_good_ship_detls;
                this.comp_material_forpost.rel_level=tab3detailsoutput.rel_level;

               // document.getElementById("overlaycmp").style.display = "none";
                // document.getElementById("loadercmp").style.display = "none";


                this.disable_gds_details();
                this.disable_report();

                this.comp_material_forpost.mot=tab3detailsoutput.mot;


                this.comp_material_forpost.ld_unload_inst=tab3detailsoutput.ld_unload_inst;

                this.originalTab3Details=JSON.parse(JSON.stringify(this.comp_material_forpost));
                this.changedTab3Details=this.comp_material_forpost;

                for(let ir=0; ir<this.releaselevel_list.length;ir++)
                {
                  if(tab3detailsoutput.rel_level==this.releaselevel_list[ir].releaselevel)
                  {

                    this.selected_release_level=this.releaselevel_list[ir];
                  }
                }


                this.transactionService.get_region_details(this.region_code).subscribe(reg_det=>{
                  console.log(reg_det);


                  this.region_Details_comp=reg_det;
                  console.log(this.region_Details_comp.pkg_rel_level_src);
                  if(this.region_Details_comp.pkg_rel_level_src.toLowerCase()=='bom')
                  {
                    this.is_bom_resource=true;
                  }
                  else{
                    this.is_bom_resource=false;
                  }

                })
                // check and include code forload/unload and mode of transport alone


                for(let i=0;i<this.transport_list.length;i++)
                {

                  if(tab3detailsoutput.mot.toLowerCase()==this.transport_list[i].transport_desc.toLowerCase())
                  {

                  this.selected_mode_transport=this.transport_list[i];
                  }


                }


              })

            }
            else{

              this.originalTab3Details=JSON.parse(JSON.stringify(this.comp_material_forpost));
                this.changedTab3Details=this.comp_material_forpost;

              this.getcomponentassociatedforcntr();
              this.transactionService.get_region_details(this.region_code).subscribe(reg_det=>{
                console.log('regdet',reg_det);


                this.region_Details_comp=reg_det;
                console.log(this.region_Details_comp.pkg_rel_level_src);
                if(this.region_Details_comp.pkg_rel_level_src.toLowerCase()=='bom')
                {
                  const grid1_prim_parts:partDetailForPost[]=[];



                  this.transactionService.get_draft_selectedparts_data(this.selectedpart_submitter_tab3.proposalid).subscribe(selectedparts_data_comp=>{


                // document.getElementById("overlaycmp").style.display = "none";
                // document.getElementById("loadercmp").style.display = "none";
                    console.log('tab1data',selectedparts_data_comp);
                    if(selectedparts_data_comp!=null && selectedparts_data_comp.length!=0)
                    {



                      for(let pri=0;pri<selectedparts_data_comp.length;pri++)
                      {
                        if(selectedparts_data_comp[pri].isprimary.toLowerCase()=='yes')
                        {
                          grid1_prim_parts.push(selectedparts_data_comp[pri]);
                          break;

                        }
                      }

                      console.log(grid1_prim_parts[0].cmmspackagingreleaselevel);


                      if(grid1_prim_parts[0].cmmspackagingreleaselevel!=null && grid1_prim_parts[0].cmmspackagingreleaselevel!=' ')
                      {


                        this.is_bom_resource=true;


                        for(let ir=0; ir<this.releaselevel_list.length;ir++)
                       {
                         if(grid1_prim_parts[0].cmmspackagingreleaselevel==this.releaselevel_list[ir].releaselevel)
                           {

                             this.selected_release_level=this.releaselevel_list[ir];
                            }
                       }


                      }


                      else{


                        this.is_bom_resource=false;
                        this.selected_release_level=this.releaselevel_list[0];


                      }



                    }


                    else{


                      this.is_bom_resource=false;
                        this.selected_release_level=this.releaselevel_list[0];


                    }
                  })

                }
                else{
                  this.is_bom_resource=false;
                  this.selected_release_level=this.releaselevel_list[0];

                }

              })
            }


          }
          else
          {
            this.noPrtDialog_compmat = true;
            this.content_selectedprt_compmat = 'Please select part from Search Existing Proposal';
          }

          /*else if (this.supp_code_compmat == null) {
            this.noSupDialog_compmat = true;
            this.content_supp_compmat = "Please select supplier";

            //document.getElementById("overlaycmp").style.display = "none";
            //document.getElementById("loadercmp").style.display = "none";
          }


          else if (this.supp_code_compmat != null && (this.selectedpart_submitter_tab3 == null || this.selectedpart_submitter_tab3.simplifiedpartno=="-" || this.selectedpart_submitter_tab3.proposalid==0)) {
            this.noPrtDialog_compmat = true;
            this.content_selectedprt_compmat = "Please select part from submitter dashboard";

            //document.getElementById("overlaycmp").style.display = "none";
            //document.getElementById("loadercmp").style.display = "none";

          }
          }
          else if (this.roleid_compmat==2)
          {



          if (this.designsource_code_compmat != null && this.selectedpart_submitter_tab3 != null && this.selectedpart_submitter_tab3.simplifiedpartno!="-" && this.selectedpart_submitter_tab3.proposalid!=0 ) {

            this.id_proposal_compmat=this.selectedpart_submitter_tab3.proposalid;



            this.get_transport_list();
            this.get_erg_limit();
            this.compmateriallistinit();

            this.componentdetailsinit();
            this.componentdetailseditinit();
            this.componentdeletedetailsinit();
            this.displaydialog=false;
            this.displayeditdialog=false;
            this.compulsorycompdialog=false;
            this.getcntrtypelist();
            this.tab3InValidationInit();
            this.compInValidationInit()
            //this.getcomponentassociatedforcntr();

           //get tab3 details if last saved proposal is greater than current
          //get tab3 details if last saved proposal is greater than current
          if(this.selectedpart_submitter_tab3.proposallastsaved>2)
          {

            this.getcomponentassociatedforcntr();
            //this.populateListOfMaterialsGrid([]);

            this.transactionService.loadtab3detailsfromAPI(this.selectedpart_submitter_tab3.proposalid).subscribe(tab3output=>{
              let tab3detailsoutput:compmaterialpost;
              tab3detailsoutput=tab3output
              console.log("tab3 output",tab3detailsoutput)
              this.cntrorshpunitradio=tab3detailsoutput.cntr_shp_unit;
              this.shippingunittareweight=tab3detailsoutput.shp_unt_tar_wt;
              this.shippingunitgrossweight=tab3detailsoutput.cntr_gross_wt;
              this.containergrossweight=tab3detailsoutput.cntr_gross_wt;
              this.comp_material_forpost.cntr_automtn_intf=tab3detailsoutput.cntr_automtn_intf;
              this.comp_material_forpost.transp_tested=tab3detailsoutput.transp_tested;
              this.comp_material_forpost.tst_reprt_num=tab3detailsoutput.tst_reprt_num;
              this.comp_material_forpost.dangrs_good=tab3detailsoutput.dangrs_good;
              this.comp_material_forpost.dangrs_good_ship_detls=tab3detailsoutput.dangrs_good_ship_detls;
              this.comp_material_forpost.rel_level=tab3detailsoutput.rel_level;


              //document.getElementById("overlaycmp").style.display = "none";
              //document.getElementById("loadercmp").style.display = "none";
              this.disable_gds_details();
              this.disable_report();

              //check and include code forload/unload and mode of transport alone
              this.comp_material_forpost.mot=tab3detailsoutput.mot;

              this.comp_material_forpost.ld_unload_inst=tab3detailsoutput.ld_unload_inst;


              for(var ir=0; ir<this.releaselevel_list.length;ir++)
              {
                if(tab3detailsoutput.rel_level==this.releaselevel_list[ir].releaselevel)
                {

                  this.selected_release_level=this.releaselevel_list[ir];
                }
              }

              this.transactionService.get_region_details(this.region_code).subscribe(reg_det=>{
                console.log(reg_det);


                this.region_Details_comp=reg_det;
                console.log(this.region_Details_comp.pkg_rel_level_src);
                if(this.region_Details_comp.pkg_rel_level_src.toLowerCase()=="bom")
                {
                  this.is_bom_resource=true;
                }
                else{
                  this.is_bom_resource=false;
                }

              })

              //check and include code forload/unload and mode of transport alone


              for(var i=0;i<this.transport_list.length;i++)
              {

                if(tab3detailsoutput.mot.toLowerCase()==this.transport_list[i].transport_desc.toLowerCase())
                {

                this.selected_mode_transport=this.transport_list[i];
                }


              }




            })
          }
          else{
            this.getcomponentassociatedforcntr();


            this.transactionService.get_region_details(this.region_code).subscribe(reg_det=>{
              console.log(reg_det);


              this.region_Details_comp=reg_det;
              console.log(this.region_Details_comp.pkg_rel_level_src);
              if(this.region_Details_comp.pkg_rel_level_src.toLowerCase()=="bom")
              {
                var grid1_prim_parts:partDetailForPost[]=[];



                this.transactionService.get_draft_selectedparts_data(this.selectedpart_submitter_tab3.proposalid).subscribe(selectedparts_data_comp=>{

                //  document.getElementById("overlaycmp").style.display = "none";
                 // document.getElementById("loadercmp").style.display = "none";

                  console.log(selectedparts_data_comp);
                  if(selectedparts_data_comp!=null && selectedparts_data_comp.length!=0)
                  {



                    for(var pri=0;pri<selectedparts_data_comp.length;pri++)
                    {
                      if(selectedparts_data_comp[pri].isprimary.toLowerCase()=="yes")
                      {
                        grid1_prim_parts.push(selectedparts_data_comp[pri]);
                        break;

                      }
                    }

                    console.log(grid1_prim_parts[0].cmmspackagingreleaselevel);


                    if(grid1_prim_parts[0].cmmspackagingreleaselevel!=null && grid1_prim_parts[0].cmmspackagingreleaselevel!=" ")
                    {


                      this.is_bom_resource=true;


                      for(var ir=0; ir<this.releaselevel_list.length;ir++)
                     {
                       if(grid1_prim_parts[0].cmmspackagingreleaselevel==this.releaselevel_list[ir].releaselevel)
                         {

                           this.selected_release_level=this.releaselevel_list[ir];
                          }
                     }


                    }


                    else{


                      this.is_bom_resource=false;
                      this.selected_release_level=this.releaselevel_list[0];


                    }



                  }


                  else{


                    this.is_bom_resource=false;
                      this.selected_release_level=this.releaselevel_list[0];


                  }
                })

              }
              else{
                this.is_bom_resource=false;
                this.selected_release_level=this.releaselevel_list[0];

              }

            })

          }

          }

          else if (this.designsource_code_compmat == null) {
            this.noDesignsourceDialog_compmat = true;
            this.content_Designsource_compmat = "Please select Design source";

            //document.getElementById("overlaycmp").style.display = "none";
            //document.getElementById("loadercmp").style.display = "none";
          }


          else if (this.designsource_code_compmat != null && (this.selectedpart_submitter_tab3 == null || this.selectedpart_submitter_tab3.simplifiedpartno=="-" || this.selectedpart_submitter_tab3.proposalid==0)) {
            this.noPrtDialog_compmat = true;
            this.content_selectedprt_compmat = "Please select part from submitter dashboard";


               // document.getElementById("overlaycmp").style.display = "none";
                // document.getElementById("loadercmp").style.display = "none";
          }

          }*/

    }






         /* ok_noroledialog()
          {
            var url
            url=this.transactionService.getregionurlvalue(this.region);
            url+="packagingprocessdashboard"
            this.router.navigate([url]);

          }




            //if no supplier present

            ok_nosupdialog() {
              console.log(this.region)
              var reg_url: string
              reg_url = this.transactionService.getregionurlvalue(this.region);
              reg_url += "packagingprocess/packsupplieradmin/packsupplier";

              this.router.navigate([reg_url]);

            }


            ok_noDesignsourcedialog() {
              console.log(this.region)
              var reg_url: string
              reg_url = this.transactionService.getregionurlvalue(this.region);
              reg_url += "packagingprocess/ppackdesignsourceadmin/packdesignsource";

              this.router.navigate([reg_url]);

            }



            go_to_supp_main_page() {
              var reg_supp_url: string;
              reg_supp_url = this.transactionService.getregionurlvalue(this.region);
              if(this.roleid_compmat==1)
              {
                reg_supp_url += "packagingprocess/packsupplieradmin/packsupplier";
              }
              else if ( this.roleid_compmat==2)
              {
                reg_supp_url += "packagingprocess/packdesignsourceadmin/packdesignsource";
              }
              this.router.navigate([reg_supp_url]);

            }
            */

            tab3InValidationInit()
            {
                   this.sucontarewtInvalid = false;
                    this.sucontarewtInvalidReason= null;
                    this.sugrosswtInvalid = false;
                    this.sugrossewtInvalidReason= null;
                    this.cntrgrosswtInvalid = false;
                this.cntrgrosswtInvalidReason= null;
		this.suexthgtInvalid=false;
                this.suexthgtInValidReason=null;
		 this.suNestedHtInValid=false;
                this.suNestedHtInValidReason=null;
    
            }

            compInValidationInit()
            {
                    this.comp_descInValid = false;
                    this.comp_descInValidReason = null;
                    this.comp_l_InValid = false;
                    this.comp_l_InValidReason= null;
                    this.comp_w_InValid = false;
                    this.comp_w_InValidReason= null;
                    this.comp_h_InValid= false;
                    this.comp_h_InValidReason= null;
                    this.comp_tare_wgtInValid= false;
                    this.comp_tare_wgtInValidReason= null;
                    this.qt_pershp_untInValid = false;
                    this.qt_pershp_untInValidReason = null;
                    this.ownrshpInValid = false;
                    this.ownrshpInValidReason = null;
                    // this.tooledCompInValid = false;
                    // this.tooledCompInValidReason = null;
                    this.selectMaterialInValid = false;
                    this.selectMaterialInValidReason = null;
                    this.sumwgts_InValid = false;
                    this.sumwgts_InValidReason = null;
                    this.totl_wt_InValid = false;
                    this.totl_wt_InValidReason = null;
                    this.comp_totl_wt_InValid = false;
                    this.comp_totl_wt_InValidReason = null;
                    this.num_of_cntr_InValid = false;
            }

            // if no part present
            ok_noprtdialog() {
              console.log(this.region)

              let reg_url_prt: string
              reg_url_prt = this.transactionService.getregionurlvalue(this.region);

              reg_url_prt += 'packagingprocessdashboard/searchexistingproposal';

              this.router.navigate([reg_url_prt]);

            }



            post_tab3init()
            {


              this.comp_material_forpost={


                id_proposal:0,
            cntr_shp_unit:'',
            shp_unt_tar_wt:0.000,
            shp_unt_gross_wt:0.000,
            cntr_gross_wt:0.000,
            ld_unload_inst:'',
            cntr_automtn_intf:'No',
            transp_tested:'No',
            tst_reprt_num:'',
            mot:'',
            dangrs_good:'No',
            dangrs_good_ship_detls:'',
            rel_level:''

              }


              this.disable_gds_details();
              this.disable_report();
              this.comp_material_forpost.rel_level=this.releaselevel_list[0].releaselevel

            }


            get_transport_list()
            {


              this.transactionService.gettransportlist().subscribe(translist=>{

                this.transport_list=translist;
                this.selected_mode_transport=this.transport_list[0]

              })



            }


            disable_gds_details()
            {
              console.log(this.comp_material_forpost.dangrs_good);
              if(this.comp_material_forpost.dangrs_good.toLowerCase()=='yes')
              {
             this.disbale_gds_details_value=false;
              }
              else if(this.comp_material_forpost.dangrs_good.toLowerCase()=='no'){

                this.disbale_gds_details_value=true;
                this.comp_material_forpost.dangrs_good_ship_detls=''
              }
            }

            disable_report()
            {


              console.log('disable',this.comp_material_forpost.transp_tested);
              if(this.comp_material_forpost.transp_tested.toLowerCase()=='yes')
              {
             this.disable_reports_value=false;
              }
              else if(this.comp_material_forpost.transp_tested.toLowerCase()=='no'){

                this.disable_reports_value=true;
                this.comp_material_forpost.tst_reprt_num=''

              }

              // Assigning UOM based on the region
              this.transactionService.get_region_details(this.region_code).subscribe(unitype=>{
                this.unitom = unitype.uom;
                console.log('UOMREGTAB3IM',this.unitom);

                if(this.unitom == 'E')
                {
                  this.edit_measure_comp = "lbs/inch";
                  this.edit_measure_comp_add = 'lbs/inch';
                  this.edit_measure_comp_gridedit = 'lbs/inch';
                  console.log('UOMLB3IM',this.unitom,this.edit_measure_comp,this.edit_measure_comp_add,this.edit_measure_comp_gridedit);
                }
                else
                {
                  this.edit_measure_comp = "kg/cm";
                  this.edit_measure_comp_add = 'kg/cm';
                  this.edit_measure_comp_gridedit = 'kg/cm';
                  console.log('UOMKG3IM',this.unitom,this.edit_measure_comp,this.edit_measure_comp_add,this.edit_measure_comp_gridedit);
                }
              })



            }


    // Code ends

    get_erg_limit()
    {
      this.transactionService.get_region_details(this.region_code).subscribe(regdata=>
        {
          this.erg_lim_frm_reg_admin=regdata;
          console.log('erg_lim_frm_reg_admin',this.erg_lim_frm_reg_admin);
        });
    }



    componentdetailsinit()
    {
      this.componentdetails=
      {
        id_proposal:this.selectedpart_submitter_tab3.proposalid,
        id_comp:0,
        comp_base:'',
        comp_suffix:'',
        comp_desc:'',
        qt_per_min_su:0,
        comp_ret_type:'',
        updated_by:'',
        updated_dt:'',
        comp_l:0,
        comp_w:0,
        comp_h:0,
        comp_tar_wt:0,
        nestedfolderHt:0,
        comp_code:'',
        comp_ownershp:'',
        comp_material:'',
        tooledcomp:'',
        is_compulsary:'',
        compselctnfor:'',

        /* added */
        comp_bsd:'',
        comp_lwh:'',
        typ_no:0


      }
    }

    componentdetailseditinit()
    {
      this.componentdetailsforedit=
      {
        id_proposal:this.selectedpart_submitter_tab3.proposalid,
        id_comp:0,
        comp_base:'',
        comp_suffix:'',
        comp_desc:'',
        qt_per_min_su:0,
        comp_ret_type:'',
        updated_by:'',
        updated_dt:'',
        comp_l:0,
        comp_w:0,
        comp_h:0,
        comp_tar_wt:0,
        nestedfolderHt:0,
        comp_code:'',
        comp_ownershp:'',
        comp_material:'',
        tooledcomp:'',
        is_compulsary:'',
        comp_lwh:'',
        comp_bsd:'',
        compselctnfor:'',
        typ_no:0

      }
    }

    improvementExpendableContainerRestriction()
  {
    if(this.contadmindetls!=null && this.contadmindetls.exp_refundable.toLowerCase()=='expendable')
    {
      if(this.cntrorshpunitradio=='Container')
      {
        // alert("came here")

        this.exp_returnable='E'
        this.disableImprovementExpReturnable=true;
      }
      else
      {
        this.disableImprovementExpReturnable=false;
      }
    }
    else
    {
      this.disableImprovementExpReturnable=false;
    }
  }


    getcomponentassociatedforcntr()
    {

      this.transactionService.loadtab2detailsifproposalexistalready(this.selectedpart_submitter_tab3.proposalid).subscribe(results=>
        {
          let tab2_data=results;
          var cntr_suffix="";
          if(tab2_data.cntr_suff=="" || tab2_data.cntr_suff==null)
          {
            cntr_suffix="nosuffix";
          }
          else
          {
            cntr_suffix=tab2_data.cntr_suff;
          }
        console.log("tab 2 data",tab2_data);
        this.Tab2DataForPost=tab2_data;
        this.shippingUnitExteriorHeightDisplay=0;
        if(tab2_data.shp_unt_ext_height != null)
        {
        this.shippingUnitExteriorHeightDisplay=tab2_data.shp_unt_ext_height;
        }
        this.shippingUnitExteriorHeightDisplay=this.transactionService.roundValueToNDecimal(this.shippingUnitExteriorHeightDisplay,3);

        this.noLayersPerShippingUnit=tab2_data.max_layrs_shp_unt-tab2_data.empty_trays_cntr_layrs;
     
        this.shippingUnitExteriorHeightCalculated=tab2_data.cntr_height* this.noLayersPerShippingUnit;
        //this.shippingUnitExteriorHeightCalculated=this.transactionService.roundValueToNDecimal(this.shippingUnitExteriorHeightCalculated,3);
        this.initialShippingUnitExteriorHeightCalculated=this.shippingUnitExteriorHeightCalculated;

        this.shippinUnitNestedFoldedHeight=0;
        if(this.shippinUnitNestedFoldedHeight != null)
        {
        this.shippinUnitNestedFoldedHeight=this.Tab2DataForPost.shp_unt_nestd_fld_height;
        }

        
          this.cntrbasedisp = tab2_data.cntr_base;

           
            if(tab2_data.cntr_suff=="" || tab2_data.cntr_suff==null)
            {
              this.cntrsuffixdisp = '';
              console.log('SUFFIX',this.cntrsuffixdisp);
            }
            else
            {
              this.cntrsuffixdisp = tab2_data.cntr_suff;
              console.log('SUFFIX1',this.cntrsuffixdisp);
            }
          // setting shipping unit tare weight and container gross weight columns

          // if(this.selectedpart_submitter_tab3.proposallastsaved<=2)
          // {
            console.log('cd_cntr',tab2_data.cd_cntr);
            this.transactionService.editbutton_get_contr_details(tab2_data.cntr_base,tab2_data.cd_cntr).subscribe(contadminout=>{
              this.contadmindetls=contadminout;
              console.log('contadmindetls',this.contadmindetls);
              this.improvementExpendableContainerRestriction()
            //this.shippingunittareweight=this.contadmindetls.shpg_unit_tare_wt;

               /* In tab2 expendable contaiers are made editable so details has to be taken from tab 2 instead of container admin */
            // this.no_of_container=this.contadmindetls.cntr_per_layer*(this.contadmindetls.max_layers_shpg_unit-tab2_data.empty_trays_cntr_layrs);
            this.no_of_container=tab2_data.cntr_per_layer*(tab2_data.max_layrs_shp_unt-tab2_data.empty_trays_cntr_layrs);
            this.shippingunittareweight=this.no_of_container*tab2_data.cntr_tar_weight;
            this.shippingunittareweight_display=this.transactionService.roundValueToNDecimal(this.shippingunittareweight,3);
           
                this.transactionService.get_draft_selectedparts_data(this.selectedpart_submitter_tab3.proposalid).subscribe(tab1out=>{
                const tab1_data=tab1out;
                let partweight=0;
                for(const hk of tab1_data)
                {
                  if(hk.isprimary.toLowerCase() == 'yes')
                  {
                    partweight+=hk.ngpp_part_wt;
                    break;
                  }
                }
               this.containergrossweight=tab2_data.cntr_tar_weight+(tab2_data.parts_per_cntr*partweight);
                
               //trunc grossweight
               console.log("container grossweight before Round Off",this.containergrossweight)
             this.containergrossweight_display=this.transactionService.roundValueToNDecimal(this.containergrossweight,3)
               console.log("container grossweight after Round Off",this.containergrossweight)

             //this.shippingunitgrossweight=(this.no_of_container*this.containergrossweight)+this.shippingunittareweight;
             this.shippingunitgrossweight=(this.no_of_container*this.containergrossweight)

             console.log("shippingunitgrossweight grossweight before Round Off",this.shippingunitgrossweight_display)
               this.shippingunitgrossweight_display=this.transactionService.roundValueToNDecimal(this.shippingunitgrossweight,3)
             console.log("shippingunitgrossweight grossweight after Round Off",this.shippingunitgrossweight_display) 


               this.temp_cntr_gross_weight=this.containergrossweight;
               this.temp_shp_unt_tare_wt=this.shippingunittareweight;
               // alert("Container gross weight"+this.containergrossweight);
               // alert("Shipping unit gross weight"+this.shippingunitgrossweight);
                if(this.selectedpart_submitter_tab3.proposallastsaved<=2)
                {
                  // this.containergrossweight=tab2_data.cntr_tar_weight+(tab2_data.parts_per_cntr*partweight);

                 // this.temp_cntr_gross_weight=this.containergrossweight;

                // this.shippingunitgrossweight=(this.no_of_container*this.containergrossweight)+this.shippingunittareweight;
                }
              })
            
        
          //}
  
          //adding compulsory components if exists
  
            this.transactionService.getcomponentdetailsbybaseandsuffix(tab2_data.cntr_base,cntr_suffix,this.contadmindetls.ownrshp_strat_code,this.region_code).subscribe(out=>
            {

              this.componenentassociatedwithcontainer=out;

              console.log('componnetassociatewithcontainer',this.componenentassociatedwithcontainer);
              const compulsorycomponent:storecomponentforpost[]=[];
              let i=0;

              if(this.componenentassociatedwithcontainer)
              {
                for (const comp of this.componenentassociatedwithcontainer)
                {
                  let c;
                  // var componentweight=comp.comp_tare_wt*comp.qt_per_min_su;
                  if(comp.is_compulsary=='Y')
                  {
                    if(comp.comp_ret_type=='Returnable')
                  {
                    c='R';
                  }
                  else
                  {
                    c="E";
                  }
                  var ownershp;
                if(comp.comp_ownershp != null && comp.comp_ownershp.toLowerCase() == "ford")
                {
                  ownershp = "Ford";
                }
                else if(comp.comp_ownershp != null && comp.comp_ownershp.toLowerCase().indexOf("supp")>-1)
                {
                  ownershp = "Supplier";
                }
                else if(comp.comp_ownershp != null && (comp.comp_ownershp.toLowerCase() == "tpt" || comp.comp_ownershp.toLowerCase() == "third party"))
                {
                  ownershp = "Third Party";
                }
                    compulsorycomponent.push(
                      {
                        id_proposal:this.selectedpart_submitter_tab3.proposalid,
                        id_comp:0,
                        comp_base:comp.no_comp_base,
                        comp_suffix:comp.no_comp_suffix,
                        comp_desc:comp.comp_desc,
                        qt_per_min_su:comp.qt_per_min_su,
                        comp_ret_type:c,
                        upated_by:this.transactionService.getcdsid(),
                        updated_dt:new Date().toISOString().split('T')[0],
                        comp_l:comp.comp_l,
                        comp_w:comp.comp_w,
                        comp_h:comp.comp_h,
                        comp_tare_wt:comp.comp_tare_wt,
                        nestedfolderht:comp.nestedfolderht,
                        comp_code:comp.comp_code,
                        comp_ownershp:ownershp,
                        comp_material:comp.comp_material,
                        tooledcomp:"No",
                      
                        //comp_bsd:comp.no_comp_base+"-"+comp.no_comp_suffix+"-"+comp.comp_desc,
                      // comp_lwh:comp.comp_l+"x"+comp.comp_w+"x"+comp.comp_h
                     // is_compulsary:comp.is_compulsary,
                      is_compulsary:'Yes',
                      compselctnfor:'Shipping Unit',
                      material_flag:'',
                      typ_no:comp.typ_no
                      // material flag added
                      });
                      i++;
                    }

                  }
              }
              if(i>0) // && this.selectedpart_submitter_tab3.proposallastsaved<=2)
              {
                let addedcompslist:storecomponentforpost[]=[];
                this.compulsorycomponents=compulsorycomponent; // will have all compulsory components associated with selected container
                console.log('came in compulsory component',this.compulsorycomponents);

                /* calling getaddedcomponentdetailsfortab3 function to check whether this compulsory components
                  are already added to DB. This is done to ensure even when the same page reloads, the same compulsory component
                  will not be added to DB again
                */
                this.transactionService.getaddedcomponentdetailsfortab3(this.selectedpart_submitter_tab3.proposalid).subscribe(addedcompsout=>
                {
                  addedcompslist=addedcompsout;
                  console.log('addedcompslist',addedcompslist);
                  // alert("addedcompslist"+addedcompslist.length);
                  let ccomp;
                  for( ccomp=0;ccomp<this.compulsorycomponents.length;ccomp++)
                  {


                    if(addedcompslist.length==0 || ( addedcompslist.length>0 && (!(addedcompslist.some(el=> el.comp_base.toLowerCase()===this.compulsorycomponents[ccomp].comp_base.toLowerCase() && el.comp_suffix.toLowerCase()===this.compulsorycomponents[ccomp].comp_suffix.toLowerCase()))) ))// || addedcompslist.length==0)
                    {
                      console.log("comp comp before save",this.compulsorycomponents[ccomp]);
                    this.compCompulsuryAdded=true;
                      this.transactionService.storecomponentdetails(this.compulsorycomponents[ccomp]).subscribe(storecompout=>{
                        const compstorestatus=storecompout;
                        console.log('Success',compstorestatus);
                        if(compstorestatus=='Success')
                        {
                          this.packagingproposal.addtoast({severity:'success', summary:'Success', detail:'Compulsory Component saved Successfully'})
                          this.populateListOfMaterialsGrid(this.compulsorycomponents[ccomp]);
                          this.populateGrid();
                        }
                        else
                        {
                        this.packagingproposal.addtoast({severity:'error', summary:'Error', detail:' Component Details not saved'});
                        }
                      });
                    }

                    else
                    {
                      this.populateListOfMaterialsGrid([]);
                      this.populateGrid();
                    }

                  }

               });
              }
              else
              {
              this.populateGrid();
              }


            });
          });
        });
    }


    componentdeletedetailsinit()
    {
      this.componentdetailsforedit=
      {
        id_proposal:this.selectedpart_submitter_tab3.proposalid,
        id_comp:0,
        comp_base:'',
        comp_suffix:'',
        comp_desc:'',
        qt_per_min_su:0,
        comp_ret_type:'',
        updated_by:'',
        updated_dt:'',
        comp_l:0,
        comp_w:0,
        comp_h:0,
        comp_tar_wt:0,
        nestedfolderHt:0,
        comp_code:'',
        comp_ownershp:'',
        comp_material:'',
        tooledcomp:'',
        is_compulsary:'',
        comp_lwh:'',
        comp_bsd:'',
        compselctnfor:'',
        typ_no:0

      }
    }

    compmateriallistinit()
    {
      // below Not used
      /*this.transactionService.getAllMaterialList().subscribe(cntrmatrial=>
        {
          if(cntrmatrial.length!=0 && cntrmatrial!=null)
          {
            this.cntrMatr=cntrmatrial.filter(ele=>ele.active_stat.toLowerCase()=="yes");
            this.selected_material=this.cntrMatr[0];
          }

        }
      );*/
    }


    edit_comp_details(rowData:storecomponent)
    {
      this.edit_measure_comp_gridedit='lbs/inch'

      if(this.unitom == 'E')
    {
      this.edit_measure_comp_gridedit = 'lbs/inch';
    }
    else
    {
      this.edit_measure_comp_gridedit = 'kg/cm';
    }
      this.componentdetailsforedit=JSON.parse(JSON.stringify(rowData));

      this.comp_type_desc='';

      // added
      this.originalTab3ComponentForEdit=JSON.parse(JSON.stringify(this.componentdetailsforedit));
      this.changedTab3ComponentForEdit=this.componentdetailsforedit;

      console.log('rowData',rowData)

    this.comb_wgt = rowData.qt_per_min_su*rowData.comp_tar_wt;
    console.log('comb_wgt',this.comb_wgt);

      console.log('comp type list',this.comp_type_and_desc_master)
      if(this.comp_type_and_desc_master != null && this.comp_type_and_desc_master.length != 0)
      {
          if(rowData.typ_no != null)
          {
          const typeIndex=this.comp_type_and_desc_master.findIndex(elcmp=>elcmp.typ_no == rowData.typ_no)
          }
          else
          {
            const typeIndex=this.comp_type_and_desc_master.findIndex(elcmp=>String(elcmp.cd_cntr_typ) == String(rowData.comp_code))
          }

         this.selected_material={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''}
          // @ts-ignore
        if(typeIndex != -1)
        {
            // @ts-ignore
          this.comp_type_desc=this.comp_type_and_desc_master[typeIndex].cntr_typ_and_desc;
// @ts-ignore
            console.log('comp type no ',this.comp_type_and_desc_master[typeIndex])

// @ts-ignore
           this.transactionService.getContainerMaterial(this.comp_type_and_desc_master[typeIndex].typ_no,rowData.comp_ret_type).subscribe( cntr_mat=>{
             console.log(cntr_mat);
             this.cntrMatr=[];
             this.cntrMatr=cntr_mat;



             if(this.cntrMatr!= null && this.cntrMatr.length!=0)
             {

             for ( const i of this.cntrMatr)
             {
               if(i.cntr_matr_name==rowData.comp_material)
               {
                 this.selected_material=i;
                 this.componentdetailsforedit.comp_material=i.cntr_matr_name; // added
                 break;
               }

             }
           }

           })

           // use above two to get container material details and map with container list
           // api getContainerMaterial(type no, ret type)
        }
          else
        {
          this.selected_material={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''}
          this.componentdetailsforedit.comp_material=this.selected_material.cntr_matr_name; // added
        }
      }
      else
      {
        this.selected_material={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''}
        this.componentdetailsforedit.comp_material=this.selected_material.cntr_matr_name; // added
      }


        this.editComponentTypeDisplay='';
        if(this.comp_type_and_desc_master != null && this.comp_type_and_desc_master.length != 0)
        {
          if(rowData.typ_no != null)
          {
            const compTypeIndex = this.comp_type_and_desc_master.findIndex(elcmptyp=>elcmptyp.typ_no == rowData.typ_no)
          }
          else
          {
            const compTypeIndex=this.comp_type_and_desc_master.findIndex(elcmp=>String(elcmp.cd_cntr_typ) == String(rowData.comp_code))
          }
// @ts-ignore
          if(compTypeIndex != -1)
          {
              // @ts-ignore
            this.editComponentTypeDisplay=this.comp_type_and_desc_master[compTypeIndex].cntr_typ_desc
          }

        }


      console.log('componentdetailsforedit',this.componentdetailsforedit);
      this.displaydialog=false;
      this.displayeditdialog=true;

      this.editcancel_click=false;

      this.compInValidationInit();
    }


    closeAlertDialog()
    {
      this.enable_savecomps = true;

      console.log('Inside Enable_func',this.enable_savecomps)
    }


    comp_tarwgt_shpunitwgt_validation_add()
    {

      console.log('Code_check_in_save',this.componentdetails.compselctnfor)
      if(this.componentdetails.compselctnfor == 'Container')
      {
        this.comb_wgtadd = this.no_of_container*(this.componentdetails.comp_tar_wt * this.componentdetails.qt_per_min_su);
      }
      else
      {
       this.comb_wgtadd = this.componentdetails.comp_tar_wt * this.componentdetails.qt_per_min_su;
      }
      // console.log("Inside count",this.componentdetailsgrid.length)
      // if(this.comb_wgtadd < 1 || this.comb_wgtadd > 999.9999)
      // {
      //   this.sumwgts_InValid = true;
      //   // this.sumwgts_InValidReason = "The individual component weight should not exceed 999.9999";
      //   console.log("Check1",this.comb_wgtadd);
      // }
      // else
      // {
      //   this.sumwgts_InValid = false;
      //   this.sumwgts_InValidReason = null;
      //   console.log("CheckNot1",this.comb_wgtadd);
      // }

    }

    comp_tarwgt_shpunitwgt_validation_edit()
  {

    this.comb_wgt = this.componentdetailsforedit.comp_tar_wt * this.componentdetailsforedit.qt_per_min_su;

    console.log('Inside_compeditcombwgt',this.comb_wgt);
    // if(this.comb_wgt < 1 || this.comb_wgt > 9.999)
    // {
    //   this.sumwgts_InValid = true;
    //   // this.sumwgts_InValidReason = "The individual component weight should not exceed 999.9999";

    // }
    // else
    // {
    //   this.sumwgts_InValid = false;
    //   this.sumwgts_InValidReason = null;

    // }
  }

    on_change_measure_comp_add()
    {
      console.log(this.edit_measure_comp_add);
      console.log(this.componentdetails);

      if(this.unitom == 'E')
      {
        this.edit_measure_comp_add = 'lbs/inch'
      }
      else
      {
        this.edit_measure_comp_add = 'kg/cm'
      }

      // if(this.edit_measure_comp_add=="lbs/inch")
      // {
      //   if(this.componentdetails.comp_l!=null)
      //   {
      //     this.componentdetails.comp_l=Math.round((0.393701*this.componentdetails.comp_l)*1000)/1000;

      //   }
      //   if(this.componentdetails.comp_w!=null)
      //   {
      //     this.componentdetails.comp_w=Math.round((0.393701*this.componentdetails.comp_w)*1000)/1000;

      //   }
      //   if(this.componentdetails.comp_h!=null)
      //   {
      //     this.componentdetails.comp_h=Math.round((0.393701*this.componentdetails.comp_h)*1000)/1000;

      //   }
      //   if(this.componentdetails.nestedfolderHt!=null)
      //   {
      //     this.componentdetails.nestedfolderHt=Math.round((0.393701*this.componentdetails.nestedfolderHt)*1000)/1000;

      //   }
      //   if(this.componentdetails.comp_tar_wt!=null)
      //   {
      //     this.componentdetails.comp_tar_wt=Math.round((2.20462*this.componentdetails.comp_tar_wt)*1000)/1000;

      //   }


      // }

      // else if(this.edit_measure_comp_add=="kg/cm")
      // {



      //   if(this.componentdetails.comp_l!=null)
      //   {
      //     this.componentdetails.comp_l=Math.round((2.54*this.componentdetails.comp_l)*1000)/1000;

      //   }
      //   if(this.componentdetails.comp_w!=null)
      //   {
      //     this.componentdetails.comp_w=Math.round((2.54*this.componentdetails.comp_w)*1000)/1000;

      //   }
      //   if(this.componentdetails.comp_h!=null)
      //   {
      //     this.componentdetails.comp_h=Math.round((2.54*this.componentdetails.comp_h)*1000)/1000;

      //   }
      //   if(this.componentdetails.nestedfolderHt!=null)
      //   {
      //     this.componentdetails.nestedfolderHt=Math.round((2.54*this.componentdetails.nestedfolderHt)*1000)/1000;

      //   }
      //   if(this.componentdetails.comp_tar_wt!=null)
      //   {
      //     this.componentdetails.comp_tar_wt=Math.round((0.453592*this.componentdetails.comp_tar_wt)*1000)/1000;

      //   }



      // }


    }

    expAidswgtcalulation_add()
    {
      this.comp_totl_wt_InValid = false;
      // if(this.no_of_container<999.9999)
      // {
        if(this.wgt_flag)
           {
           this.shp_comp_wt_add = 0;
           this.temp_shp_compwt_add = 0;
           }
             if(this.component_type.cd_cntr_typ > '05' && this.component_type.cd_cntr_typ <'16')
             {
                   if(this.componentdetailsgrid.length == 0 || this.componentdetailsgrid.length == null || !this.componentdetailsgrid)
                   {

                          console.log('Inside First IF');

                         this.temp_comwgt_add = this.comb_wgtadd;
                         console.log('Inside The Nothing', this.temp_comwgt_add);
                         if(this.temp_comwgt_add > 999.9999)
                         {
                               this.comp_totl_wt_InValid = true;
                               console.log('Inside Grid 0', this.temp_comwgt_add)
                         }
                         else
                         {
                           this.comp_totl_wt_InValid = false;
                           this.comp_totl_wt_InValidReason = null;
                         }
                   }
               for( const i of this.componentdetailsgrid)
                 {

                  if(i.compselctnfor == 'Container' && (i.comp_code > '05' && i.comp_code < '16'))
                  {

                    this.cntr_comp_wt = 0;
                    this.cntr_comp_wt+=(i.qt_per_min_su *i.comp_tar_wt);
                    this.shp_comp_wt_add +=( this.no_of_container*(this.cntr_comp_wt));
                    this.temp_comwgt_add = this.shp_comp_wt_add + this.comb_wgtadd;

                  }

                  else if(i.compselctnfor== 'Shipping Unit' && (i.comp_code > '05' && i.comp_code < '16'))
                    {
                      this.temp_shp_compwt_add = 0;
                    this.shp_comp_wt_add+=(i.qt_per_min_su *i.comp_tar_wt);
                    this.temp_shp_compwt_add+= this.shp_comp_wt;
                    this.temp_comwgt_add = this.shp_comp_wt_add + this.comb_wgtadd;

                  }
                  else {
                  if (this.componentdetailsgrid.length == 1  &&  !(i.comp_code > '05' && i.comp_code < '16'))
                  {
                    if(this.component_type.cd_cntr_typ > '05' && this.component_type.cd_cntr_typ <'16')
                    {
                                this.temp_comwgt_add = this.comb_wgtadd;
                                console.log('Inside The NonCOmp', this.temp_comwgt_add);
                    }
                  }
                 }

                 if(this.temp_comwgt_add > 999.9999)
                 {
                       this.comp_totl_wt_InValid = true;
                       console.log('Inside came here on last validation check add');
                 }
                 else
                 {
                   this.comp_totl_wt_InValid = false;
                   this.comp_totl_wt_InValidReason = null;
                 }
                }

                if(this.no_of_container>999.9999)
                {

                if(this.componentdetails.compselctnfor == 'Container' && (this.component_type.cd_cntr_typ > '05' && this.component_type.cd_cntr_typ < '16')){
                  this.temp_comwgt_add = this.comb_wgtadd+this.shp_comp_wt_add;
                  console.log('Inside the else part - Cont', this.temp_comwgt_add , this.temp_comwgt_add)
                  if(this.temp_comwgt_add != 0 || this.temp_comwgt_add != null || this.temp_comwgt_add > 999.9999 || this.comp_totl_wt_InValid)
                  {
                    this.num_of_cntr_InValid = true;
                    this.comp_totl_wt_InValid = false;
                  }
                  else
                  {
                    this.num_of_cntr_InValid = false;
                  }

                }
                else
                {
                  console.log('Inside The Else part of no.of cntrs', this.no_of_container,this.temp_comwgt_add,this.comb_wgtadd);
                  if(this.componentdetails.compselctnfor == 'Shipping Unit' && (this.component_type.cd_cntr_typ > '05' && this.component_type.cd_cntr_typ < '16'))
                  {
                  this.temp_comwgt_add = this.comb_wgtadd;
                  console.log('Inside the else part - Shipunit', this.temp_comwgt_add)
                  if(this.temp_comwgt_add != 0 || this.temp_comwgt_add != null || this.temp_comwgt_add > 999.9999 || this.comp_totl_wt_InValid)
                  {
                    this.num_of_cntr_InValid = true;
                    this.comp_totl_wt_InValid = false;
                  }
                  else
                  {
                    this.num_of_cntr_InValid = false;
                  }
                 }
                }
                }

              }
              else
              {

                console.log('Inside Else Part', this.no_of_container)
                if(this.component_type.cd_cntr_typ > '05' && this.component_type.cd_cntr_typ < '16')
                {
                  this.temp_comwgt_add = this.comb_wgtadd;
                  console.log('Inside The else Part - No.of contrs', this.temp_comwgt_add);
                  if(this.temp_comwgt_add != 0 || this.temp_comwgt_add != null || this.temp_comwgt_add > 999.9999)
                  {
                    this.num_of_cntr_InValid = true;
                  }
                  else
                  {
                    this.num_of_cntr_InValid = false;
                  }

                }
              }
    // }

      }
    // expAidswgtcalulation_add()
    // {
    //     //  if(this.no_of_container<99.9)
    //     //  {
    //      if(this.wgt_flag)
    //      {
    //      this.shp_comp_wt_add = 0;
    //      this.temp_shp_compwt_add = 0;
    //      }
    //        if(this.component_type.cd_cntr_typ > "05" && this.component_type.cd_cntr_typ <"15")
    //        {
    //          for( var i of this.componentdetailsgrid)
    //            {

    //             if(i.compselctnfor == "Container" && (i.comp_code > "05" && i.comp_code < "15"))
    //             {

    //               this.cntr_comp_wt = 0;
    //               this.cntr_comp_wt+=(i.qt_per_min_su *i.comp_tar_wt);
    //               this.shp_comp_wt_add +=( this.no_of_container*(this.cntr_comp_wt));
    //               this.temp_comwgt_add = this.shp_comp_wt_add + this.comb_wgtadd;

    //               if(this.temp_comwgt_add > 99.9999)
    //               {
    //                     this.comp_totl_wt_InValid = true;
    //               }
    //               else
    //               {
    //                 this.comp_totl_wt_InValid = false;
    //                 this.comp_totl_wt_InValidReason = null;
    //               }

    //             }
    //             else
    //             {
    //               if(i.compselctnfor== "Shipping Unit" && (i.comp_code > "05" && i.comp_code < "15"))
    //               {
    //                 this.temp_shp_compwt_add = 0;
    //               this.shp_comp_wt_add+=(i.qt_per_min_su *i.comp_tar_wt);
    //               this.temp_shp_compwt_add+= this.shp_comp_wt;
    //               this.temp_comwgt_add = this.shp_comp_wt_add + this.comb_wgtadd;

    //               if(this.temp_comwgt_add > 99.9999)
    //               {
    //                     this.comp_totl_wt_InValid = true;
    //               }
    //               else
    //               {
    //                 this.comp_totl_wt_InValid = false;
    //                 this.comp_totl_wt_InValidReason = null;
    //               }


    //             }

    //             }
    //            }
    //         }

    //   // }
    //   // else
    //   // {

    //   // if(this.componentdetails.compselctnfor == "Container" && (this.component_type.cd_cntr_typ > "05" && this.component_type.cd_cntr_typ < "15")){
    //   //   this.temp_comwgt_add = this.comb_wgtadd;
    //   //   if(this.temp_comwgt_add != 0 || this.temp_comwgt_add != null)
    //   //   {
    //   //     this.num_of_cntr_InValid = true;
    //   //   }
    //   //   else
    //   //   {
    //   //     this.num_of_cntr_InValid = false;
    //   //   }

    //   // }
    //   // else
    //   // {
    //   //   if(this.componentdetails.compselctnfor == "Shipping Unit" && (this.component_type.cd_cntr_typ > "05" && this.component_type.cd_cntr_typ < "15"))
    //   //   {
    //   //   this.temp_comwgt_add = this.comb_wgtadd + this.no_of_container;
    //   //   if(this.temp_comwgt_add != 0 || this.temp_comwgt_add != null)
    //   //   {
    //   //     this.num_of_cntr_InValid = true;
    //   //   }
    //   //   else
    //   //   {
    //   //     this.num_of_cntr_InValid = false;
    //   //   }
    //   //  }
    //   // }
    //   // }

    //   }

    expAidsWgtValidation()
    {
      console.log('Inside Last Validtaion')
    if(this.wgt_flag)
    {
    this.shp_comp_wt = 0;
    this.temp_shp_compwt = 0;
    }
        for( const i of this.componentdetailsgrid)
          {
            console.log('Inside Total validation', this.shp_comp_wt,i.compselctnfor,i.comp_code)
            if(i.compselctnfor == 'Container' && (i.comp_code > '05' && i.comp_code < '16'))
            {

              this.cntr_comp_wt = 0;
              this.cntr_comp_wt+=(i.qt_per_min_su *i.comp_tar_wt);
              this.shp_comp_wt +=( this.no_of_container*(this.cntr_comp_wt));

              console.log('Inside Total validation - Cont', this.shp_comp_wt)
              if(this.shp_comp_wt > 999.9999)
              {
                    this.totl_wt_InValid = true;
                    console.log('Inside Total validation', this.shp_comp_wt)
                    // this.totl_wt_InValidReason = "The weights are exceeding 9999.999"
                    this.enable_savecomps = false;
              }
              else
              {
                this.totl_wt_InValid = false;
                this.enable_savecomps = true;
                this.totl_wt_InValidReason = null;
              }
            }
            else
            {
              if(i.compselctnfor== 'Shipping Unit' && (i.comp_code > '05' && i.comp_code < '16'))
              {
                this.temp_shp_compwt = 0;
              this.shp_comp_wt+=(i.qt_per_min_su *i.comp_tar_wt);
              this.temp_shp_compwt+= this.shp_comp_wt;

              console.log('Inside Total validation - Shpunit', this.shp_comp_wt)
              if(this.shp_comp_wt > 999.9999)
              {
                    this.totl_wt_InValid = true;
                    this.enable_savecomps = false;
                    // this.totl_wt_InValidReason = "The weights are exceeding 9999.999"
              }
              else
              {
                this.totl_wt_InValid = false;
                this.totl_wt_InValidReason = null;
                this.enable_savecomps = true;
              }

            }
            }
          }


    }

    save_componentdetails()
    {
      this.displaydialog=false;

      this.rowadded=true;
      this.componentdetails.comp_ret_type=this.exp_returnable;
      this.componentdetails.comp_lwh=this.componentdetails.comp_l+'x'+this.componentdetails.comp_w+'x'+this.componentdetails.comp_h;
      this.componentdetails.updated_dt=new Date().toISOString().split('T')[0];
      this.componentdetails.updated_by=this.transactionService.getcdsid();
      this.componentdetails.comp_material=this.selected_material.cntr_matr_name;
      
     if((this.componentdetails.typ_no == 2 || this.componentdetails.typ_no == 3 || this.componentdetails.typ_no == 4)
     && this.componentdetails.compselctnfor.toLowerCase() == 'shipping unit')
    {
      this.compAddedChangeHeight=true;
    }
    
      if(this.unitom == "E")
      {
        this.edit_measure_comp_add = 'lbs/inch'
      }
      else
      {
        this.edit_measure_comp_add = 'kg/cm'
      }

      // if(this.edit_measure_comp_add=="kg/cm")
      // {
      //   if(this.componentdetails.comp_l!=null)
      //   {
      //     this.componentdetails.comp_l=Math.round((0.393701*this.componentdetails.comp_l)*1000)/1000;

      //   }
      //   if(this.componentdetails.comp_w!=null)
      //   {
      //     this.componentdetails.comp_w=Math.round((0.393701*this.componentdetails.comp_w)*1000)/1000;

      //   }
      //   if(this.componentdetails.comp_h!=null)
      //   {
      //     this.componentdetails.comp_h=Math.round((0.393701*this.componentdetails.comp_h)*1000)/1000;

      //   }
      //   if(this.componentdetails.nestedfolderHt!=null)
      //   {
      //     this.componentdetails.nestedfolderHt=Math.round((0.393701*this.componentdetails.nestedfolderHt)*1000)/1000;

      //   }
      //   if(this.componentdetails.comp_tar_wt!=null)
      //   {
      //     this.componentdetails.comp_tar_wt=Math.round((2.20462*this.componentdetails.comp_tar_wt)*1000)/1000;

      //   }


      // }


      this.variableforsave=
      {
        id_proposal:this.componentdetails.id_proposal,
        id_comp:0,
        comp_base:this.componentdetails.comp_base,
        comp_suffix:this.componentdetails.comp_suffix,
        comp_desc:this.componentdetails.comp_desc,
        qt_per_min_su:this.componentdetails.qt_per_min_su,
        comp_ret_type:this.componentdetails.comp_ret_type,
        upated_by:this.componentdetails.updated_by,
        updated_dt:this.componentdetails.updated_dt,
        comp_l:Number(this.componentdetails.comp_l),
        comp_w:Number(this.componentdetails.comp_w),
        comp_h:Number(this.componentdetails.comp_h),
        comp_tare_wt:this.componentdetails.comp_tar_wt,
        nestedfolderht:Number(this.componentdetails.nestedfolderHt),
        comp_code:this.componentdetails.comp_code,
        comp_ownershp:this.componentdetails.comp_ownershp,
        comp_material:this.componentdetails.comp_material,
        tooledcomp:this.componentdetails.tooledcomp,
        is_compulsary:this.componentdetails.is_compulsary,
        compselctnfor:this.cntrorshpunitradio,
        material_flag:this.selected_material.comp_type_flag,
        typ_no:this.componentdetails.typ_no
        // material flag added
      }

      console.log('variableforsave',this.variableforsave);

    this.compDescInValidationForAdd();
    this.compLenInValidationForAdd();
    this.compWidInValidationForAdd();
    this.compHgtInValidationForAdd();
    this.compTareWgtInValidationForAdd();
    this.compQtPerShpUntInValidationForAdd();
    this.ownershpInValidationForAdd();
    //this.tooledCompInValidationForAdd();
    this.selectedMaterialInValidationForAdd();

    this.comp_tarwgt_shpunitwgt_validation_add();

    this.expAidswgtcalulation_add();

    console.log('Temp_comp_after_save',this.temp_comwgt_add,this.shp_comp_wt_add,this.comb_wgtadd)

    console.log('Inside EXPCAL_save',this.comp_totl_wt_InValid,this.num_of_cntr_InValid)

    if(!this.comp_descInValid && !this.comp_l_InValid && !this.comp_w_InValid && !this.comp_h_InValid && !this.comp_tare_wgtInValid && !this.qt_pershp_untInValid && !this.sumwgts_InValid && !this.ownrshpInValid && !this.selectMaterialInValid && !this.comp_totl_wt_InValid && !this.num_of_cntr_InValid)
    {
      this.transactionService.storecomponentdetails(this.variableforsave).subscribe(storeout=>{
        const res=storeout;
        console.log('Success',res);
        if(res=='Success')
        {
          this.packagingproposal.addtoast({severity:'success', summary:'Success', detail:' Component Details saved Successfully'})
          this.populateListOfMaterialsGrid(this.componentdetails);
          this.populateGrid();
          this.componentdetailsinit();
          this.compInValidationInit();

          // alert("value changed")
          if(this.existChangeStatusTab3 != null && this.existChangeStatusTab3.toLowerCase()=='unchanged')
          {
            const setChangestatusTab3:changeTrackingForImprovement={idproposal:0,chnge_timestamp:'',status:''}
            setChangestatusTab3.idproposal=this.selectedpart_submitter_tab3.proposalid
            setChangestatusTab3.status='changed';
            const cur_date=new Date();
            const cur_date_transform=this.date.transform(cur_date, 'yyyy-MM-dd HH:mm:ss');
            setChangestatusTab3.chnge_timestamp=cur_date_transform
              console.log('update change status ',setChangestatusTab3)
            this.transactionService.setImprovementChangeStatus(setChangestatusTab3).subscribe(chang_stat=>{
              console.log('change status update',chang_stat);

              if(chang_stat.toLowerCase() == 'success')
              {
                sessionStorage.setItem('ProposalChangeStatus',setChangestatusTab3.status);
                this.existChangeStatusTab3='changed';

                // alert("existChangeStatusTab3"+this.existChangeStatusTab3)
              }
            })
          }

        }
        else
        {
          this.packagingproposal.addtoast({severity:'error', summary:'Error', detail:' Component Details not saved'});
          this.compInValidationInit();
          if(this.unitom == 'E')
          {
            this.edit_measure_comp_add = 'lbs/inch'
          }
          else
          {
            this.edit_measure_comp_add = 'kg/cm'
          }

          // if(this.edit_measure_comp_add=="kg/cm")
          // {



          //   if(this.componentdetails.comp_l!=null)
          //   {
          //     this.componentdetails.comp_l=Math.round((2.54*this.componentdetails.comp_l)*1000)/1000;

          //   }
          //   if(this.componentdetails.comp_w!=null)
          //   {
          //     this.componentdetails.comp_w=Math.round((2.54*this.componentdetails.comp_w)*1000)/1000;

          //   }
          //   if(this.componentdetails.comp_h!=null)
          //   {
          //     this.componentdetails.comp_h=Math.round((2.54*this.componentdetails.comp_h)*1000)/1000;

          //   }
          //   if(this.componentdetails.nestedfolderHt!=null)
          //   {
          //     this.componentdetails.nestedfolderHt=Math.round((2.54*this.componentdetails.nestedfolderHt)*1000)/1000;

          //   }
          //   if(this.componentdetails.comp_tar_wt!=null)
          //   {
          //     this.componentdetails.comp_tar_wt=Math.round((0.453592*this.componentdetails.comp_tar_wt)*1000)/1000;

          //   }
          // }
        }
      });


    }
    else
    {
      this.displaydialog = true;
      this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Check the Component details and Try Again'});
    }
  }

    cancel_componentdetails()
    {
      this.displaydialog=false;

      // on cancelling we shd make the popup load the existing component details which we get from API
      this.loadComponentNumberbasedOnContainerSelected();
      this.componentdetailsinit();
      this.compInValidationInit();
    }

    on_change_measure_comp_gridedit()
    {


      console.log(this.edit_measure_comp_gridedit);
      console.log(this.componentdetailsforedit);


      if(this.unitom == 'E')
      {
        this.edit_measure_comp_gridedit = 'lbs/inch'
      }
      else
      {
        this.edit_measure_comp_gridedit = 'kg/cm'
      }

      // if(this.edit_measure_comp_gridedit=="lbs/inch")
      // {
      //   if(this.componentdetailsforedit.comp_l!=null)
      //   {
      //     this.componentdetailsforedit.comp_l=Math.round((0.393701*this.componentdetailsforedit.comp_l)*1000)/1000;

      //   }
      //   if(this.componentdetailsforedit.comp_w!=null)
      //   {
      //     this.componentdetailsforedit.comp_w=Math.round((0.393701*this.componentdetailsforedit.comp_w)*1000)/1000;

      //   }
      //   if(this.componentdetailsforedit.comp_h!=null)
      //   {
      //     this.componentdetailsforedit.comp_h=Math.round((0.393701*this.componentdetailsforedit.comp_h)*1000)/1000;

      //   }
      //   if(this.componentdetailsforedit.nestedfolderHt!=null)
      //   {
      //     this.componentdetailsforedit.nestedfolderHt=Math.round((0.393701*this.componentdetailsforedit.nestedfolderHt)*1000)/1000;

      //   }
      //   if(this.componentdetailsforedit.comp_tar_wt!=null)
      //   {
      //     this.componentdetailsforedit.comp_tar_wt=Math.round((2.20462*this.componentdetailsforedit.comp_tar_wt)*1000)/1000;

      //   }


      // }

      // else if(this.edit_measure_comp_gridedit=="kg/cm")
      // {



      //   if(this.componentdetailsforedit.comp_l!=null)
      //   {
      //     this.componentdetailsforedit.comp_l=Math.round((2.54*this.componentdetailsforedit.comp_l)*1000)/1000;

      //   }
      //   if(this.componentdetailsforedit.comp_w!=null)
      //   {
      //     this.componentdetailsforedit.comp_w=Math.round((2.54*this.componentdetailsforedit.comp_w)*1000)/1000;

      //   }
      //   if(this.componentdetailsforedit.comp_h!=null)
      //   {
      //     this.componentdetailsforedit.comp_h=Math.round((2.54*this.componentdetailsforedit.comp_h)*1000)/1000;

      //   }
      //   if(this.componentdetailsforedit.nestedfolderHt!=null)
      //   {
      //     this.componentdetailsforedit.nestedfolderHt=Math.round((2.54*this.componentdetailsforedit.nestedfolderHt)*1000)/1000;

      //   }
      //   if(this.componentdetailsforedit.comp_tar_wt!=null)
      //   {
      //     this.componentdetailsforedit.comp_tar_wt=Math.round((0.453592*this.componentdetailsforedit.comp_tar_wt)*1000)/1000;

      //   }



      // }
    }
    compDescInValidationForEdit()
  {
    if(this.componentdetailsforedit.comp_desc == null || this.componentdetailsforedit.comp_desc.trim() == '')
    {
      this.comp_descInValid=true;
      this.comp_descInValidReason='Required';
    }
    else if(this.componentdetailsforedit.comp_desc.length < 1 ||  this.componentdetailsforedit.comp_desc.length > 99.999)
    {
      this.comp_descInValid=true;
      this.comp_descInValidReason='Should be of range 1 to 99.999';
    }
    else
    {
      this.comp_descInValid=false;
      this.comp_descInValidReason=null;
    }
  }
  compLenInValidationForEdit()
  {
    const comp_l = String(this.componentdetailsforedit.comp_l).split('.');
    if(!String(this.componentdetailsforedit.comp_l).includes('.'))
    {
    if(this.componentdetailsforedit.comp_l==null || this.componentdetailsforedit.comp_l==0)
        {
          this.comp_l_InValid=true;
          this.comp_l_InValidReason='Required';
        }
       else if( String(this.componentdetailsforedit.comp_l).length>8)
        {
          this.comp_l_InValid=true;
          this.comp_l_InValidReason='Max Length Of Characters can be 8';
        }
        else if(this.componentdetailsforedit.comp_l<1 || this.componentdetailsforedit.comp_l>9999.999)
        {
          this.comp_l_InValid=true;
          this.comp_l_InValidReason='Should be of range 1 to 9999.999';
        }
      else
        {
          this.comp_l_InValid=false;
          this.comp_l_InValidReason=null;
        }
    }
    else if(String(this.componentdetailsforedit.comp_l).includes('.'))
    {
        if(String(comp_l[0]).length<0 || String(comp_l[0]).length>4)
        {
          this.comp_l_InValid=true;
          this.comp_l_InValidReason='Should be of range 1 to 9999.999';
        }
        else if(String(comp_l[1]).length<0 || String(comp_l[1]).length>3)
        {
          this.comp_l_InValid=true;
          this.comp_l_InValidReason='Max Length Of decimal values can be 3';
        }
        else
        {
          this.comp_l_InValid=false;
          this.comp_l_InValidReason=null;
        }
    }
    else
    {
    this.comp_l_InValid=false;
    this.comp_l_InValidReason=null;
    }
  }
  compWidInValidationForEdit()
  {
    const comp_w = String(this.componentdetailsforedit.comp_w).split('.');
    if(!String(this.componentdetailsforedit.comp_w).includes('.'))
    {
    if(this.componentdetailsforedit.comp_w==null || this.componentdetailsforedit.comp_w==0)
        {
          this.comp_w_InValid=true;
          this.comp_w_InValidReason='Required';
        }
       else if( String(this.componentdetailsforedit.comp_w).length>8)
        {
          this.comp_w_InValid=true;
          this.comp_w_InValidReason='Max Length Of Characters can be 8';
        }
        else if(this.componentdetailsforedit.comp_w<1 || this.componentdetailsforedit.comp_w>9999.999)
        {
          this.comp_w_InValid=true;
          this.comp_w_InValidReason='Should be of range 1 to 9999.999';
        }
      else
        {
          this.comp_w_InValid=false;
          this.comp_w_InValidReason=null;
        }
    }
    else if(String(this.componentdetailsforedit.comp_w).includes('.'))
    {
        if(String(comp_w[0]).length<0 || String(comp_w[0]).length>4)
        {
          this.comp_w_InValid=true;
          this.comp_w_InValidReason='Should be of range 1 to 9999.999';
        }
        else if(String(comp_w[1]).length<0 || String(comp_w[1]).length>3)
        {
          this.comp_w_InValid=true;
          this.comp_w_InValidReason='Max Length Of decimal values can be 3';
        }
        else
        {
          this.comp_w_InValid=false;
          this.comp_w_InValidReason=null;
        }
    }
    else
    {
    this.comp_w_InValid=false;
    this.comp_w_InValidReason=null;
    }
  }
  compHgtInValidationForEdit()
  {
    const comp_h = String(this.componentdetailsforedit.comp_h).split('.');
    if(!String(this.componentdetailsforedit.comp_h).includes('.'))
    {
    if(this.componentdetailsforedit.comp_h==null || this.componentdetailsforedit.comp_h==0)
        {
          this.comp_h_InValid=true;
          this.comp_h_InValidReason='Required';
        }
       else if( String(this.componentdetailsforedit.comp_h).length>8)
        {
          this.comp_h_InValid=true;
          this.comp_h_InValidReason='Max Length Of Characters can be 8';
        }
        else if(this.componentdetailsforedit.comp_h<1 || this.componentdetailsforedit.comp_h>9999.999)
        {
          this.comp_h_InValid=true;
          this.comp_h_InValidReason='Should be of range 1 to 9999.999';
        }
      else
        {
          this.comp_h_InValid=false;
          this.comp_h_InValidReason=null;
        }
    }
    else if(String(this.componentdetailsforedit.comp_h).includes('.'))
    {
        if(String(comp_h[0]).length<0 || String(comp_h[0]).length>4)
        {
          this.comp_h_InValid=true;
          this.comp_h_InValidReason='Should be of range 1 to 9999.999';
        }
        else if(String(comp_h[1]).length<0 || String(comp_h[1]).length>3)
        {
          this.comp_h_InValid=true;
          this.comp_h_InValidReason='Max Length Of decimal values can be 3';
        }
        else
        {
          this.comp_h_InValid=false;
          this.comp_h_InValidReason=null;
        }
    }
    else
    {
    this.comp_h_InValid=false;
    this.comp_h_InValidReason=null;
    }
  }
  compTareWgtInValidationForEdit()
  {
    const comp_tar_wt = String(this.componentdetailsforedit.comp_tar_wt).split('.');
    if(!String(this.componentdetailsforedit.comp_tar_wt).includes('.'))
    {
    if(this.componentdetailsforedit.comp_tar_wt==null || this.componentdetailsforedit.comp_tar_wt==0)
        {
          this.comp_tare_wgtInValid=true;
          this.comp_tare_wgtInValidReason='Required';
		   this.comp_totl_wt_InValid = false;
        }
       else if( String(this.componentdetailsforedit.comp_tar_wt).length>8)
        {
          this.comp_tare_wgtInValid=true;
          this.comp_tare_wgtInValidReason='Max Length Of Characters can be 8';
		   this.comp_totl_wt_InValid = false;
        }
        else if(this.componentdetailsforedit.comp_tar_wt<1 || this.componentdetailsforedit.comp_tar_wt>9999.999)
        {
          this.comp_tare_wgtInValid=true;
          this.comp_tare_wgtInValidReason='Should be of range 1 to 9999.999';
		   this.comp_totl_wt_InValid = false;
        }
      else
        {
          this.comp_tare_wgtInValid=false;
          this.comp_tare_wgtInValidReason=null;
		      this.comp_tarwgt_shpunitwgt_validation_edit();
        }
    }
    else if(String(this.componentdetailsforedit.comp_tar_wt).includes('.'))
    {
        if(String(comp_tar_wt[0]).length<0 || String(comp_tar_wt[0]).length>4)
        {
          this.comp_tare_wgtInValid=true;
          this.comp_tare_wgtInValidReason='Should be of range 1 to 9999.999';
		   this.comp_totl_wt_InValid = false;
        }
        else if(String(comp_tar_wt[1]).length<0 || String(comp_tar_wt[1]).length>3)
        {
          this.comp_tare_wgtInValid=true;
          this.comp_tare_wgtInValidReason='Max Length Of decimal values can be 3';
		   this.comp_totl_wt_InValid = false;
        }
        else
        {
          this.comp_tare_wgtInValid=false;
          this.comp_tare_wgtInValidReason=null;
		  this.comp_tarwgt_shpunitwgt_validation_edit();
        }
    }
    else
    {
    this.comp_tare_wgtInValid=false;
    this.comp_tare_wgtInValidReason=null;
	this.comp_tarwgt_shpunitwgt_validation_edit();
    }
  }
    compQtPerShpUntInValidationForEdit()
    {
      if(this.componentdetailsforedit.qt_per_min_su==0 || this.componentdetailsforedit.qt_per_min_su==null)
      {
        this.qt_pershp_untInValid=true;
        this.qt_pershp_untInValidReason='Required';
      }
      else if(this.componentdetailsforedit.qt_per_min_su<1 || this.componentdetailsforedit.qt_per_min_su>999  || String(this.componentdetailsforedit.qt_per_min_su).includes('.'))
      {
        this.qt_pershp_untInValid=true;
        this.qt_pershp_untInValidReason='Should be of range 1 to 999 and cannot contain Decimal';
        // this.comp_tarwgt_shpunitwgt_validation_edit();
      }
      else
      {
        this.qt_pershp_untInValid=false;
        this.qt_pershp_untInValidReason=null;
        // this.comp_tarwgt_shpunitwgt_validation_edit();
        console.log('CompQtShpUnt in validations', this.componentdetailsforedit.qt_per_min_su);
      }
    }
    ownershpInValidationForEdit()
    {
      if(this.componentdetailsforedit.comp_ownershp==null || this.componentdetailsforedit.comp_ownershp.trim()=='')
      {
        this.ownrshpInValid=true;
        this.ownrshpInValidReason='Required'
      }
      else
      {
        this.ownrshpInValid=false;
        this.ownrshpInValidReason=null
      }
    }
  
    // tooledCompInValidationForEdit()
    // {
    //   if(this.componentdetailsforedit.tooledcomp==null || this.componentdetailsforedit.tooledcomp.trim()=="")
    //   {
    //     this.tooledCompInValid=true;
    //     this.tooledCompInValidReason="Required"
    //   }
    //   else
    //   {
    //     this.tooledCompInValid=false;
    //     this.tooledCompInValidReason=null
    //   }
    // }
    selectedMaterialInValidationForEdit()
  {
    if(this.selected_material.cntr_matr_name==null || this.selected_material.cntr_matr_name.trim()=='')
    {
      this.selectMaterialInValid=true;
      this.selectMaterialInValidReason='Required';
    }
    else
    {
      this.selectMaterialInValid=false;
      this.selectMaterialInValidReason=null;
    }
  }
  expAidswgtcalulation_edit()
{
    if(this.wgt_flag)
    {
    this.shp_comp_wt_edit = 0;
    this.temp_shp_compwt_edit = 0;
    }
        for( const i of this.componentdetailsgrid)
          {

           if(i.compselctnfor == 'Container' && (i.comp_code > '05' && i.comp_code < '16'))
           {

             this.cntr_comp_wt = 0;
             const wtindex = (this.componentdetailsgrid.findIndex(ind => (ind.id_comp) == (this.componentdetailsforedit.id_comp)))
             if(this.componentdetailsgrid.indexOf(i) == wtindex)
             {
              i.comp_tar_wt = this.componentdetailsforedit.comp_tar_wt;
              i.qt_per_min_su = this.componentdetailsforedit.qt_per_min_su;
            }
             this.cntr_comp_wt+=(i.qt_per_min_su *i.comp_tar_wt);
             this.shp_comp_wt_edit +=( this.no_of_container*(this.cntr_comp_wt));
             this.temp_comwgt_edit = this.shp_comp_wt_edit;

             if(this.temp_comwgt_edit > 999.9999)
             {
                   this.comp_totl_wt_InValid = true;
             }
             else
             {
               this.comp_totl_wt_InValid = false;
               this.comp_totl_wt_InValidReason = null;
             }

           }
           else
           {
             if(i.compselctnfor== 'Shipping Unit' && (i.comp_code > '05' && i.comp_code < '16'))
             {
               this.temp_shp_compwt_edit = 0;
             const wtshpindex = (this.componentdetailsgrid.findIndex(ind => (ind.id_comp) == (this.componentdetailsforedit.id_comp)))

             if(this.componentdetailsgrid.indexOf(i) == wtshpindex)
             {
                i.comp_tar_wt = this.componentdetailsforedit.comp_tar_wt;
                i.qt_per_min_su = this.componentdetailsforedit.qt_per_min_su;
             }

             this.shp_comp_wt_edit+=(i.qt_per_min_su *i.comp_tar_wt);
            this.temp_comwgt_edit = this.shp_comp_wt_edit;
             if(this.temp_comwgt_edit > 999.9999)
             {
                   this.comp_totl_wt_InValid = true;
                   console.log('Inside Check edit ship',this.temp_comwgt_edit);
             }
             else
             {
               this.comp_totl_wt_InValid = false;
               this.comp_totl_wt_InValidReason = null;
             }
           }


           }
          }
}

    save_componentdetailsforedit()
    {

      this.displayeditdialog=false;
      this.rowedited=true;
      this.componentdetailsforedit.comp_lwh=this.componentdetailsforedit.comp_l+'x'+this.componentdetailsforedit.comp_w+'x'+this.componentdetailsforedit.comp_h;
      this.componentdetailsforedit.updated_dt=new Date().toISOString().split('.')[0];
      this.componentdetailsforedit.updated_by=this.transactionService.getcdsid();
      this.componentdetailsforedit.comp_material=this.selected_material.cntr_matr_name;
       
            if((this.componentdetailsforedit.typ_no == 2 || this.componentdetailsforedit.typ_no == 3 || this.componentdetailsforedit.typ_no == 4) 
     && (this.originalTab3ComponentForEdit.comp_h != this.componentdetailsforedit.comp_h || this.originalTab3ComponentForEdit.qt_per_min_su != this.componentdetailsforedit.qt_per_min_su)
     && this.componentdetails.compselctnfor.toLowerCase() == 'shipping unit')
    {
    this.compEdidtedChangeHeight=true;
    }
    
      if(this.unitom == "E")
      {
        this.edit_measure_comp_gridedit = 'lbs/inch'
      }
      else
      {
        this.edit_measure_comp_gridedit = 'kg/cm'
      }


      // if(this.edit_measure_comp_gridedit=="kg/cm")
      // {
      //   if(this.componentdetailsforedit.comp_l!=null)
      //   {
      //     this.componentdetailsforedit.comp_l=Math.round((0.393701*this.componentdetailsforedit.comp_l)*1000)/1000;

      //   }
      //   if(this.componentdetailsforedit.comp_w!=null)
      //   {
      //     this.componentdetailsforedit.comp_w=Math.round((0.393701*this.componentdetailsforedit.comp_w)*1000)/1000;

      //   }
      //   if(this.componentdetailsforedit.comp_h!=null)
      //   {
      //     this.componentdetailsforedit.comp_h=Math.round((0.393701*this.componentdetailsforedit.comp_h)*1000)/1000;

      //   }
      //   if(this.componentdetailsforedit.nestedfolderHt!=null)
      //   {
      //     this.componentdetailsforedit.nestedfolderHt=Math.round((0.393701*this.componentdetailsforedit.nestedfolderHt)*1000)/1000;

      //   }
      //   if(this.componentdetailsforedit.comp_tar_wt!=null)
      //   {
      //     this.componentdetailsforedit.comp_tar_wt=Math.round((2.20462*this.componentdetailsforedit.comp_tar_wt)*1000)/1000;

      //   }


      // }

      /* Even when compulsory component is edited by selecting container radio button it shd be saved
        as componnet for shipping unit only- code starts */
      let compissavedfor;
      if(this.componentdetailsforedit.is_compulsary=='Yes')
      {
        compissavedfor='Shipping Unit'
      }
      else
      {
        compissavedfor=this.componentdetailsforedit.compselctnfor;
      }

      /* Even when compulsory component is edited by selecting container radio button it shd be saved
        as componnet for shipping unit only - code ends*/

      this.variableforsave=
      {
        id_proposal:this.componentdetailsforedit.id_proposal,
        id_comp:Number(this.componentdetailsforedit.id_comp),
        comp_base:this.componentdetailsforedit.comp_base,
        comp_suffix:this.componentdetailsforedit.comp_suffix,
        comp_desc:this.componentdetailsforedit.comp_desc,
        qt_per_min_su:this.componentdetailsforedit.qt_per_min_su,
        comp_ret_type:this.componentdetailsforedit.comp_ret_type,
        upated_by:this.componentdetailsforedit.updated_by,
        updated_dt:this.componentdetailsforedit.updated_dt,
        comp_l:Number(this.componentdetailsforedit.comp_l),
        comp_w:Number(this.componentdetailsforedit.comp_w),
        comp_h:Number(this.componentdetailsforedit.comp_h),
        comp_tare_wt:this.componentdetailsforedit.comp_tar_wt,
        nestedfolderht:Number(this.componentdetailsforedit.nestedfolderHt),
        comp_code:this.componentdetailsforedit.comp_code,
        comp_ownershp:this.componentdetailsforedit.comp_ownershp,
        comp_material:this.componentdetailsforedit.comp_material,
        tooledcomp:this.componentdetailsforedit.tooledcomp,
        is_compulsary:this.componentdetailsforedit.is_compulsary,
        compselctnfor:compissavedfor,
        material_flag:this.selected_material.comp_type_flag,
        typ_no:this.componentdetailsforedit.typ_no
        // material flag added
      }

      this.originalTab3ComponentForEdit.updated_dt=this.componentdetailsforedit.updated_dt


    this.compDescInValidationForEdit();
    this.compLenInValidationForEdit();
    this.compWidInValidationForEdit();
    this.compHgtInValidationForEdit();
    this.compTareWgtInValidationForEdit();
    this.compQtPerShpUntInValidationForEdit();
    this.ownershpInValidationForEdit();
    //this.tooledCompInValidationForEdit();
    this.selectedMaterialInValidationForEdit();

    this.comp_tarwgt_shpunitwgt_validation_edit();


    this.expAidswgtcalulation_edit();
    
      if(!this.comp_descInValid && !this.comp_l_InValid && !this.comp_w_InValid && !this.comp_h_InValid && !this.comp_tare_wgtInValid && !this.qt_pershp_untInValid && !this.sumwgts_InValid && !this.ownrshpInValid  && !this.selectMaterialInValid && !this.comp_totl_wt_InValid)
      {

      this.transactionService.storecomponentdetails(this.variableforsave).subscribe(editout=>{
        const editstatus=editout;
        console.log('Success',editstatus);
        if(editstatus=='Success')
        {
          this.packagingproposal.addtoast({severity:'success', summary:'Success', detail:' Component Details Updated Successfully'})
          this.populateListOfMaterialsGrid([]);
          this.populateGrid();
          this.componentdetailseditinit();
          this.componentdetailsinit();
          this.compInValidationInit();

          if(JSON.stringify(this.originalTab3ComponentForEdit)!==JSON.stringify(this.changedTab3ComponentForEdit))
          {
           // alert("value changed")
            if(this.existChangeStatusTab3 != null && this.existChangeStatusTab3.toLowerCase()=='unchanged')
            {
              const setChangestatusTab3:changeTrackingForImprovement={idproposal:0,chnge_timestamp:'',status:''}
              setChangestatusTab3.idproposal=this.selectedpart_submitter_tab3.proposalid
              setChangestatusTab3.status='changed';
              const cur_date=new Date();
              const cur_date_transform=this.date.transform(cur_date, 'yyyy-MM-dd HH:mm:ss');
              setChangestatusTab3.chnge_timestamp=cur_date_transform
                console.log('update change status ',setChangestatusTab3)
              this.transactionService.setImprovementChangeStatus(setChangestatusTab3).subscribe(chang_stat=>{
                console.log('change status update',chang_stat);

                if(chang_stat.toLowerCase() == 'success')
                {
                  sessionStorage.setItem('ProposalChangeStatus',setChangestatusTab3.status);
                  this.existChangeStatusTab3='changed';

                  // alert("existChangeStatusTab3"+this.existChangeStatusTab3)
                }
              })
            }
          }


        }
        else{
          this.packagingproposal.addtoast({severity:'error', summary:'Error', detail:' Component Details not Updated'})

          if(this.unitom == 'E')
          {
            this.edit_measure_comp_gridedit = 'lbs/inch'
          }
          else
          {
            this.edit_measure_comp_gridedit = 'kg/cm'
          }

          // if(this.edit_measure_comp_gridedit=="kg/cm")
          // {



          //   if(this.componentdetailsforedit.comp_l!=null)
          //   {
          //     this.componentdetailsforedit.comp_l=Math.round((2.54*this.componentdetailsforedit.comp_l)*1000)/1000;

          //   }
          //   if(this.componentdetailsforedit.comp_w!=null)
          //   {
          //     this.componentdetailsforedit.comp_w=Math.round((2.54*this.componentdetailsforedit.comp_w)*1000)/1000;

          //   }
          //   if(this.componentdetailsforedit.comp_h!=null)
          //   {
          //     this.componentdetailsforedit.comp_h=Math.round((2.54*this.componentdetailsforedit.comp_h)*1000)/1000;

          //   }
          //   if(this.componentdetailsforedit.nestedfolderHt!=null)
          //   {
          //     this.componentdetailsforedit.nestedfolderHt=Math.round((2.54*this.componentdetailsforedit.nestedfolderHt)*1000)/1000;

          //   }
          //   if(this.componentdetailsforedit.comp_tar_wt!=null)
          //   {
          //     this.componentdetailsforedit.comp_tar_wt=Math.round((0.453592*this.componentdetailsforedit.comp_tar_wt)*1000)/1000;

          //   }



          // }
        }
      })

  }
    else
    {
      this.displayeditdialog= true;
      this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Check the Component details and Try Again'});

    }
    }

    cancel_componentdetailsforedit()
    {
      this.editcancel_click=true;
      this.displayeditdialog=false;
      this.populateListOfMaterialsGrid([]);
      this.populateGrid();
      this.componentdetailseditinit();
      this.componentdetailsinit();
      
      
    }
  
    updateCompulsoryComponents()
  {
    this.componentsStoredInProposal=JSON.parse(JSON.stringify(this.componentdetailsgrid));

    var cntr_base=this.Tab2DataForPost.cntr_base;
    var cntr_suffix="";

    if(this.Tab2DataForPost.cntr_suff=="" || this.Tab2DataForPost.cntr_suff==null)
    {
      cntr_suffix="nosuffix";
    }
    else
    {
      cntr_suffix=this.Tab2DataForPost.cntr_suff;
    }

    this.transactionService.getcomponentdetailsbybaseandsuffix(cntr_base,cntr_suffix,this.contadmindetls.ownrshp_strat_code,this.region_code).subscribe(compFromCMMS=>
    {
        
        this.componentsFetchedFromCMMS=compFromCMMS;
        
        console.log("componentsFetchedFromCMMS",this.componentsFetchedFromCMMS);

        const compulsorycomponent:storecomponentforpost[]=[];
        var i=0;
        
        if(this.componentsFetchedFromCMMS)
        {
          for (var comp of this.componentsFetchedFromCMMS)
          {
            var c;
            if(comp.is_compulsary=="Y")
            {
              if(comp.comp_ret_type=="Returnable")
              {
                c="R";
              }
              else
              {
                c="E";
              }

              var ownershp;
              if(comp.comp_ownershp != null && comp.comp_ownershp.toLowerCase() == "ford")
              {
                ownershp = "Ford";
              }
              else if(comp.comp_ownershp != null && comp.comp_ownershp.toLowerCase().indexOf("supp")>-1)
              {
                ownershp = "Supplier";
              }
              else if(comp.comp_ownershp != null && (comp.comp_ownershp.toLowerCase() == "tpt" || comp.comp_ownershp.toLowerCase() == "third party"))
              {
                ownershp = "Third Party";
              }
              compulsorycomponent.push(
              { 
                  id_proposal:this.selectedpart_submitter_tab3.proposalid,
                  id_comp:0,
                  comp_base:comp.no_comp_base,
                  comp_suffix:comp.no_comp_suffix,
                  comp_desc:comp.comp_desc,
                  qt_per_min_su:comp.qt_per_min_su,
                  comp_ret_type:c,
                  upated_by:this.transactionService.getcdsid(),
                  updated_dt:new Date().toISOString().split('T')[0],
                  comp_l:comp.comp_l,
                  comp_w:comp.comp_w,
                  comp_h:comp.comp_h,
                  comp_tare_wt:comp.comp_tare_wt,
                  nestedfolderht:comp.nestedfolderht,
                  comp_code:comp.comp_code,
                  comp_ownershp:ownershp,
                  comp_material:comp.comp_material,
                  tooledcomp:"No",
                  is_compulsary:"Yes",
                  compselctnfor:"Shipping Unit",
                  material_flag:"",
                  typ_no:comp.typ_no
                  //material flag added
              });
              i++;
            }
            
          }
        }
   
          this.compulsoryComponentFromCMMS=JSON.parse(JSON.stringify(compulsorycomponent)); // will have all compulsory components associated with selected container
          console.log("compulsoryComponentFromCMMS",this.compulsoryComponentFromCMMS);
          console.log("Components from draft proposal",this.componentsStoredInProposal);
          

          var compulsoryComponentToBeAdded:storecomponentforpost[]=[];
          var compulsoryComponentToBeMadeToNo:storecomponent[]=[];


          //case 1: compulsory component are same in draft and cmms
          for(var i=0;i<2;i++)
          {
            var componentExistInProposal=false;
            var componentNotInCMMS=false;

            //check if compulsory component present in cmms is not in draft, if yes add it to componenttobeadded list
           if(this.componentsStoredInProposal!=null && this.componentsStoredInProposal.length>0)
           {
            if(this.compulsoryComponentFromCMMS!=null && i<this.compulsoryComponentFromCMMS.length && this.compulsoryComponentFromCMMS.length>0 && this.compulsoryComponentFromCMMS.length<=2)
            {
              var component = this.componentsStoredInProposal.findIndex(e=>e.comp_base.trim().toLowerCase()== this.compulsoryComponentFromCMMS[i].comp_base.trim().toLowerCase() && e.comp_suffix.trim()==this.compulsoryComponentFromCMMS[i].comp_suffix.trim() && e.is_compulsary.toLowerCase()=="yes")
              if(component!=-1)
              {
                componentExistInProposal=true;
              }
              else{
                componentExistInProposal=false;
                //alert("came to add case");
                compulsoryComponentToBeAdded.push(this.compulsoryComponentFromCMMS[i]);
              }
            }
            else
            {    
              componentExistInProposal=true;    
            }
           }
           else
           {
            if(this.compulsoryComponentFromCMMS!=null && i<this.compulsoryComponentFromCMMS.length && this.compulsoryComponentFromCMMS.length>0 && this.compulsoryComponentFromCMMS.length<=2)
            {
              // If there is no components in draft but compuslory comp is in CMMS add all of them to proposal
             compulsoryComponentToBeAdded.push(this.compulsoryComponentFromCMMS[i])
            }
           }
        

            //check if compulsory component present in draft proposal is not present in CMMS, then add it to compulsoryComponentToBeMadeToNo list
            var compulsoryComponentsInDraftProposal=this.componentsStoredInProposal.filter(e=>e.is_compulsary.toLowerCase()=="yes");

            if(compulsoryComponentsInDraftProposal!=null && compulsoryComponentsInDraftProposal.length!=0 && i<compulsoryComponentsInDraftProposal.length && compulsoryComponentsInDraftProposal.length>0 && compulsoryComponentsInDraftProposal.length<=2 )
            {
              if(this.compulsoryComponentFromCMMS!=null && this.compulsoryComponentFromCMMS.length>0 && this.compulsoryComponentFromCMMS.length<=2 )
              {
                var componentPresentCheck=this.compulsoryComponentFromCMMS.findIndex(e=>e.comp_base.trim().toLowerCase()==compulsoryComponentsInDraftProposal[i].comp_base.trim().toLowerCase() && e.comp_suffix.trim()==compulsoryComponentsInDraftProposal[i].comp_suffix.trim())
           
                if(componentPresentCheck==-1)
                {
                  componentNotInCMMS=true;
                  //alert("came to make no case");
                  compulsoryComponentToBeMadeToNo.push(compulsoryComponentsInDraftProposal[i]);
                }
                else
                {
                  componentNotInCMMS=false;
                }
              }
              else
              {
                // Because in CMMS there is no compulsary component at all
                componentNotInCMMS=true;
                compulsoryComponentToBeMadeToNo=[];
                compulsoryComponentToBeMadeToNo.push(compulsoryComponentsInDraftProposal[i]);
                break;
              }
              
            }
          }

          if(compulsoryComponentToBeMadeToNo.length==0 && compulsoryComponentToBeAdded.length==0)
          {
            this.packagingproposal.addtoast({severity:'success', summary:'Success', detail:'Compulsory Component Updated Successfully!'})
          }

          // Making Components as Non Compulsory
          if(compulsoryComponentToBeMadeToNo!=null && compulsoryComponentToBeMadeToNo.length>0)
          {
            
            for(var comps of compulsoryComponentToBeMadeToNo)
            {
              var variableforsavecase1:storecomponentforpost;

              variableforsavecase1={ 
                id_proposal:comps.id_proposal,
                id_comp:comps.id_comp,
                comp_base:comps.comp_base,
                comp_suffix:comps.comp_suffix,
                comp_desc:comps.comp_desc,
                qt_per_min_su:comps.qt_per_min_su,
                comp_ret_type:comps.comp_ret_type,
                upated_by:comps.updated_by,
                updated_dt:new Date().toISOString().split('T')[0],
                comp_l:comps.comp_l,
                comp_w:comps.comp_w,
                comp_h:comps.comp_h,
                comp_tare_wt:comps.comp_tar_wt,
                nestedfolderht:comps.nestedfolderHt,
                comp_code:comps.comp_code,
                comp_ownershp:comps.comp_ownershp,
                comp_material:comps.comp_material,
                tooledcomp:"No",
                is_compulsary:"No",
                compselctnfor:"Shipping Unit",
                material_flag:"",
                typ_no:comp.typ_no
              }

              this.transactionService.storecomponentdetails(variableforsavecase1).subscribe(output=>{
                let editstatus=output;
                console.log("Success",editstatus);
                if(editstatus=="Success")
                {
                  this.packagingproposal.addtoast({severity:'success', summary:'Success', detail:'Compulsory Component Updated Successfully'})
                  this.populateListOfMaterialsGrid([]);
                  this.populateGrid();
                  this.componentdetailseditinit();
                  this.componentdetailsinit();
                  this.compInValidationInit(); 
          
          
                }
                else{
                  this.packagingproposal.addtoast({severity:'error', summary:'Error', detail:' Component Details not Updated'})
          
                }
              });          
            }
            
          }

          //Adding or Updating Compulsory Component
          if(compulsoryComponentToBeAdded!=null && compulsoryComponentToBeAdded.length>0)
          {
            for(let comp of compulsoryComponentToBeAdded)
            {
              let findInGrid=this.componentsStoredInProposal.findIndex(e=>e.comp_base.trim().toLowerCase()==comp.comp_base.trim().toLowerCase() && e.comp_suffix.trim()==comp.comp_suffix.trim());

              if(findInGrid==-1)
              {
                this.transactionService.storecomponentdetails(comp).subscribe(output2=>{
                  let editstatus2=output2;
                  console.log("Success",editstatus2);
                  if(editstatus2=="Success")
                  {
                    this.packagingproposal.addtoast({severity:'success', summary:'Success', detail:'Compulsory Component Updated Successfully'})
                    this.populateListOfMaterialsGrid([]);
                    this.populateGrid();
                    this.componentdetailseditinit();
                    this.componentdetailsinit();
                    this.compInValidationInit();
            
            
                  }
                  else{
                    this.packagingproposal.addtoast({severity:'error', summary:'Error', detail:' Component Details not Updated'})
            
                  }
                }); 
              }

              else  // When component already exist as non-compulsory component
              {

                let variableforsave:storecomponentforpost=
                {
                  id_proposal:this.componentsStoredInProposal[findInGrid].id_proposal,
                  comp_base:this.componentsStoredInProposal[findInGrid].comp_base,
                  comp_suffix:this.componentsStoredInProposal[findInGrid].comp_suffix,
                  comp_desc:this.componentsStoredInProposal[findInGrid].comp_desc,
                  qt_per_min_su:this.componentsStoredInProposal[findInGrid].qt_per_min_su,
                  comp_ret_type:this.componentsStoredInProposal[findInGrid].comp_ret_type,
                  upated_by:this.componentsStoredInProposal[findInGrid].updated_by,
                  updated_dt:this.componentsStoredInProposal[findInGrid].updated_dt,
                  comp_l:this.componentsStoredInProposal[findInGrid].comp_l,
                  comp_w:this.componentsStoredInProposal[findInGrid].comp_w,
                  comp_h:this.componentsStoredInProposal[findInGrid].comp_h,
                  comp_tare_wt:this.componentsStoredInProposal[findInGrid].comp_tar_wt,
                  nestedfolderht:this.componentsStoredInProposal[findInGrid].nestedfolderHt,
                  comp_code:this.componentsStoredInProposal[findInGrid].comp_code,
                  comp_ownershp:this.componentsStoredInProposal[findInGrid].comp_ownershp,
                  comp_material:this.componentsStoredInProposal[findInGrid].comp_material,
                  tooledcomp:this.componentsStoredInProposal[findInGrid].tooledcomp,
                  is_compulsary:"Yes",
                  id_comp:this.componentsStoredInProposal[findInGrid].id_comp,
                  compselctnfor:this.componentsStoredInProposal[findInGrid].compselctnfor,
                  material_flag:'',
                  typ_no:this.componentsStoredInProposal[findInGrid].typ_no
                }
                

                console.log(variableforsave)
                this.transactionService.storecomponentdetails(variableforsave).subscribe(output3=>{
                  let editstatus3=output3;
                  console.log("Success",editstatus3);
                  if(editstatus3=="Success")
                  {
                    this.packagingproposal.addtoast({severity:'success', summary:'Success', detail:'Compulsory Component Updated Successfully'})
                     this.populateListOfMaterialsGrid([]);
                    this.populateGrid();
                    this.componentdetailseditinit();
                    this.componentdetailsinit();
                    this.compInValidationInit(); 
            
            
                  }
                  else{
                    this.packagingproposal.addtoast({severity:'error', summary:'Error', detail:' Component Details not Updated'})
            
                  }
                }); 

              }
            }
          }

      });

  }
    
  
    deletecompdetails(rowData)
    {
      let flagfordelete=false;
    var ComponentHeightTobeChangedDeleteFlag=false;
      this.componentdetailsfordelete=rowData;
      this.confirmationService.confirm({
        message: 'Are you sure you want to delete component ' + this.componentdetailsfordelete.comp_base +this.componentdetailsfordelete.comp_suffix+ '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            console.log(this.componentdetailsfordelete);

            for(const i of this.componentdetailsgrid)
            {
              if(i.comp_base.trim()==this.componentdetailsfordelete.comp_base.trim())
              {
                if(this.componentdetailsfordelete.comp_suffix!=null && i.comp_suffix!=null)
                  {
                    if(this.componentdetailsfordelete.comp_suffix.trim()==i.comp_suffix.trim())
                    {
                      flagfordelete=true;
                    }
                  }
                  else if(this.componentdetailsfordelete.comp_suffix==null && i.comp_suffix==null)
                  {
                    flagfordelete=true;
                  }
              }

            }
              if(flagfordelete)
              {
                this.containergrossweightInKGS=this.containergrossweight;
                this.shippingunitgrossweightInKGS=this.shippingunitgrossweight;

                this.transactionService.deletecomponentdetails(this.componentdetailsfordelete.id_proposal,this.componentdetailsfordelete.id_comp).subscribe(delout=>{
                  const deletionstatus=delout;
                  console.log(deletionstatus,'Success');
                  if(deletionstatus=='Success')
                  {
                    this.packagingproposal.addtoast({severity:'success', summary:'success', detail:'Component Deleted Successfully'});
                    // alert("value changed")

                    if(this.existChangeStatusTab3 != null && this.existChangeStatusTab3.toLowerCase()=='unchanged')
                    {
                      const setChangestatusTab3:changeTrackingForImprovement={idproposal:0,chnge_timestamp:'',status:''}
                      setChangestatusTab3.idproposal=this.selectedpart_submitter_tab3.proposalid
                      setChangestatusTab3.status='changed';
                      const cur_date=new Date();
                      const cur_date_transform=this.date.transform(cur_date, 'yyyy-MM-dd HH:mm:ss');
                      setChangestatusTab3.chnge_timestamp=cur_date_transform
                        console.log('update change status ',setChangestatusTab3)
                      this.transactionService.setImprovementChangeStatus(setChangestatusTab3).subscribe(chang_stat=>{
                        console.log('change status update',chang_stat);

                        if(chang_stat.toLowerCase() == 'success')
                        {
                          sessionStorage.setItem('ProposalChangeStatus',setChangestatusTab3.status);
                          this.existChangeStatusTab3='changed';
                          // alert("existChangeStatusTab3"+this.existChangeStatusTab3)
                        }
                      })
                    }

                    if(this.componentdetailsfordelete.compselctnfor=='Container')
                    {
                     // alert("subtracted container gross weight"+this.containergrossweight)

                     if(this.unitom == 'E')
                      {
                        this.edit_measure_comp = 'lbs';
                      }
                      else
                      {
                        this.edit_measure_comp = 'kg';
                      }

                      // if(this.edit_measure_comp=="kg")
                      // {
                      //   this.containergrossweightInKGS-=Math.round(0.453592*(this.componentdetailsfordelete.qt_per_min_su*this.componentdetailsfordelete.comp_tar_wt)*1000)/1000;
                      //   this.shippingunitgrossweightInKGS-=Math.round(0.453592*(this.no_of_container*this.componentdetailsfordelete.qt_per_min_su*this.componentdetailsfordelete.comp_tar_wt)*1000)/1000;
                      // }

                      this.containergrossweight-=(this.componentdetailsfordelete.qt_per_min_su*this.componentdetailsfordelete.comp_tar_wt);
                      this.shippingunitgrossweight-=(this.no_of_container*this.componentdetailsfordelete.qt_per_min_su*this.componentdetailsfordelete.comp_tar_wt);
   	     	      this.shp_comp_wt-=(this.no_of_container*this.componentdetailsfordelete.qt_per_min_su*this.componentdetailsfordelete.comp_tar_wt)
                     
                      //trunc grossweight
                      console.log("container grossweight before Round Off",this.containergrossweight)
                      this.containergrossweight_display=this.transactionService.roundValueToNDecimal(this.containergrossweight,3)
                      console.log("container grossweight after Round Off",this.containergrossweight)

                      console.log("shippingunitgrossweight grossweight before Round Off",this.shippingunitgrossweight)
                      this.shippingunitgrossweight_display=this.transactionService.roundValueToNDecimal(this.shippingunitgrossweight,3)
                      console.log("shippingunitgrossweight grossweight after Round Off",this.shippingunitgrossweight) 
                    this.shippingunittareweight-=(this.no_of_container*this.componentdetailsfordelete.qt_per_min_su*this.componentdetailsfordelete.comp_tar_wt);
                    this.shippingunittareweight_display=this.transactionService.roundValueToNDecimal(this.shippingunittareweight,3);
                    /*if(this.componentdetailsfordelete.typ_no == 2 || this.componentdetailsfordelete.typ_no == 3 || this.componentdetailsfordelete.typ_no == 4)
                    {
                      ComponentHeightTobeChangedDeleteFlag=true;
                      this.shippingUnitExteriorHeightCalculated-=this.noLayersPerShippingUnit*this.componentdetailsfordelete.comp_h*this.componentdetailsfordelete.qt_per_min_su;
                      //this.shippingUnitExteriorHeightCalculated=this.transactionService.roundValueToNDecimal(this.shippingUnitExteriorHeightCalculated,3);
                    }*/
                    }
                    else
                    {
                      // alert("subtracted Shipping unit gross weight"+this.shippingunitgrossweight+'-'+this.componentdetailsfordelete.qt_per_min_su*this.componentdetailsfordelete.comp_tar_wt)

                      if(this.unitom == 'E')
                      {
                        this.edit_measure_comp = 'lbs';
                      }
                      else
                      {
                        this.edit_measure_comp = 'kg';
                      }

                      // if(this.edit_measure_comp=="kg")
                      // {
                      //   this.shippingunitgrossweightInKGS-=Math.round(0.453592*(this.componentdetailsfordelete.qt_per_min_su*this.componentdetailsfordelete.comp_tar_wt)*1000)/1000;
                      // }

                      this.shippingunitgrossweight-=(this.componentdetailsfordelete.qt_per_min_su*this.componentdetailsfordelete.comp_tar_wt);
          	      this.shp_comp_wt-=(this.componentdetailsfordelete.qt_per_min_su*this.componentdetailsfordelete.comp_tar_wt);

                      //trunc grossweight
                      console.log("shippingunitgrossweight grossweight before Round Off",this.shippingunitgrossweight)
                      this.shippingunitgrossweight_display=this.transactionService.roundValueToNDecimal(this.shippingunitgrossweight,3)
                      console.log("shippingunitgrossweight grossweight after Round Off",this.shippingunitgrossweight) 

                    this.shippingunittareweight-=(this.componentdetailsfordelete.qt_per_min_su*this.componentdetailsfordelete.comp_tar_wt);
                    this.shippingunittareweight_display=this.transactionService.roundValueToNDecimal(this.shippingunittareweight,3);
                    if(this.componentdetailsfordelete.typ_no == 2 || this.componentdetailsfordelete.typ_no == 3 || this.componentdetailsfordelete.typ_no == 4)
                    {
                      ComponentHeightTobeChangedDeleteFlag=true;
                      this.shippingUnitExteriorHeightCalculated-=this.componentdetailsfordelete.comp_h*this.componentdetailsfordelete.qt_per_min_su;
                      //this.shippingUnitExteriorHeightCalculated=this.transactionService.roundValueToNDecimal(this.shippingUnitExteriorHeightCalculated,3);
                    }
                  }
                  if(ComponentHeightTobeChangedDeleteFlag)
                  {
                    this.shippingUnitExteriorHeightDisplay=this.transactionService.roundValueToNDecimal(this.shippingUnitExteriorHeightCalculated,3);
                  }
                    if(this.selectedpart_submitter_tab3.proposallastsaved>=3)
                    {
                      this.deleteflag=true;
                      if(this.componentdetailsfordelete.compselctnfor=='Container')
                      {
                      this.comp_material_forpost.shp_unt_tar_wt=this.shippingunittareweight_display;
                      this.comp_material_forpost.cntr_gross_wt=this.containergrossweight_display;
                      this.comp_material_forpost.shp_unt_gross_wt=this.shippingunitgrossweight_display;
                      }
                      else
                      {
                      this.comp_material_forpost.shp_unt_tar_wt=this.shippingunittareweight_display;
                      this.comp_material_forpost.shp_unt_gross_wt=this.shippingunitgrossweight_display;
                      }
                      this.transactionService.savetab3details(this.comp_material_forpost).subscribe(weightupdd=>
                        {
                          const weightupdatestatfordelete=weightupdd;
                            let msgs:any[];
                            if(weightupdatestatfordelete=='Success')
                            {
                              // componentweightshdbeupdated=false;
                              this.packagingproposal.addtoast({severity:'success', summary: 'success', detail: 'Shipping Unit Gross Weight Updated'});
                            }
                            else{
                              this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Shipping Unit Gross weight not Updated'});
                            }
                        });
                        this.convertweightaftercomponentdeletion()
                    }
                    else
                    {
                      this.deleteflagbeforesave=true;
                      this.convertweightaftercomponentdeletion();
                    }
                    this.loadComponentNumberbasedOnContainerSelected();
                    this.populateListOfMaterialsGrid([]);
                    this.populateGrid();

                  }
                  else
                  {
                    this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Component Not Deleted,Please Try Again'})
                  }
                });
              }


        }
    });
    }

    convertweightaftercomponentdeletion()
    {

      if(this.unitom == 'E')
    {
      this.edit_measure_comp = 'lbs';
    }
    else
    {
      this.edit_measure_comp = 'kg';
    }
      if(this.edit_measure_comp=='kg')
      {
        this.containergrossweight=this.containergrossweightInKGS;
        this.shippingunitgrossweight=this.shippingunitgrossweightInKGS;
      }
    }

    getcntrtypelist() // function to populate dropdown
    {
      this.component_type={cd_cntr_typ:'',cntr_typ_and_desc:'',cntr_typ_desc:'select component type',typ_no:0};
      this.comp_typ_list=[];
      this.items=[];

      this.componentlistbasedontype=[];


      this.transactionService.getContainerTypeCode().subscribe(typ=>{
        this.comp_type=typ;
        console.log(this.comp_type);


        const tempcompstypeanddesc:componenttypewithcodeanddesc[]=[];
        for(const i of this.comp_type)
        {
          tempcompstypeanddesc.push({cd_cntr_typ:i.cd_cntr_typ,cntr_typ_desc:i.cntr_typ_desc,cntr_typ_and_desc:i.cd_cntr_typ+'-'+i.cntr_typ_desc,typ_no:i.typ_no})
        }

        this.comp_type_and_desc_master=tempcompstypeanddesc;

        if(this.exp_returnable!=null && this.componentOrAid!=null && this.comp_type_and_desc_master!=null )
        {
          if(this.componentOrAid.toLowerCase()=='component')
          {
            this.comp_type_and_desc=JSON.parse(JSON.stringify(this.comp_type_and_desc_master.filter(e=>['01','02','03'].includes(String(e.cd_cntr_typ)))))
          }

          if(this.componentOrAid.toLowerCase()=='aid')
          {
            if(this.exp_returnable.toLowerCase()=='e')
            {
              this.comp_type_and_desc=JSON.parse(JSON.stringify(this.comp_type_and_desc_master.filter(e=>(!['01','02','03'].includes(String(e.cd_cntr_typ))))))
            }
            if(this.exp_returnable.toLowerCase()=='r')
            {
              this.comp_type_and_desc=JSON.parse(JSON.stringify(this.comp_type_and_desc_master.filter(e=>(['04','05'].includes(String(e.cd_cntr_typ))))))
            }
          }
        }

        /*for(var i of this.comp_type)
        {
          console.log(i.cd_cntr_typ,typeof(i.cd_cntr_typ));
          if( ["1","2","3","4"].includes(i.cd_cntr_typ.toString()))
          {
            console.log(i.cntr_typ_desc);
            this.comp_typ_list.push(<TreeNode>{label:i.cd_cntr_typ+"-"+i.cntr_typ_desc,data:i.cd_cntr_typ+"-"+i.cntr_typ_desc,expandedIcon:"pi pi-folder-open" });
          }
          else if(!["5"].includes(i.cd_cntr_typ.toString())){
            this.items.push(<TreeNode>{label:i.cd_cntr_typ+"-"+i.cntr_typ_desc,data:i.cd_cntr_typ+"-"+i.cntr_typ_desc,expandedIcon:"pi pi-folder-open"});
          }
        }
        this.comp_typ_list.push(<TreeNode>{label:'Other',data:'Other',children:this.items,selectable:false});

        console.log(this.comp_typ_list);*/

      });
    }

    // getselectedtype(event,op) //function to set dropdown with selected value and load the component selected
    getselectedtype(op)
    {
      console.log('came here');
      /*console.log("selected component type is ",this.val.data,event.label,event.node.data);
      op.hide(event);

      var cnum=this.val.data.split("-")[0];
      var ctype=this.val.data.split("-")[1];
      this.component_type=this.val.data.split("-")[1];
    */

    console.log('op',op.cntr_typ_and_desc);

     this.loadComponentNumberbasedOnContainerSelected();

    }


    loadComponentNumberbasedOnContainerSelected()
    {

      this.transactionService.loadtab2detailsifproposalexistalready(this.selectedpart_submitter_tab3.proposalid).subscribe(results=>
        {
          const selected_container_from_tab2=results;

          if(this.component_type.cntr_typ_and_desc!=null && this.component_type.cntr_typ_desc != '')
          {
          const typecode=this.component_type.cntr_typ_and_desc.split('-')[0];
          const typedesc=this.component_type.cntr_typ_and_desc.split('-')[1];
          const typeNo=this.component_type.typ_no;
          }

          console.log('selected_container_from_tab2',selected_container_from_tab2);
          if(selected_container_from_tab2.cntr_suff=='' || selected_container_from_tab2.cntr_suff==null)
          {
            selected_container_from_tab2.cntr_suff='0';
          }


          if(this.component_type != null && this.component_type.cd_cntr_typ != '' && this.componentOrAid!=null)
          {// removed ['01','02','03','04].includes(this.component_type.cd_cntr_typ)
          if(this.componentOrAid.toLowerCase()=='component'  && this.componenentassociatedwithcontainer)
          {
            this.tempcomps=[];


            for(const compns of this.componenentassociatedwithcontainer)
            {
                let c;
                let compc;
                let suffix;

                if(compns.no_comp_suffix==undefined || compns.no_comp_suffix==null)
                {
                    suffix=null
                }
                else
                {
                  suffix=compns.no_comp_suffix;
                }
                if(compns.comp_ret_type=='Returnable')
                {
                  c='R';
                }
                else
                {
                  c='E';
                }
                if(compns.is_compulsary=='Y')
                {
                  compc='Yes'
                }
                else
                {
                  compc='No'
                }

                var ownershp;
                if(compns.comp_ownershp != null && compns.comp_ownershp.toLowerCase() == "ford")
                {
                  ownershp = "Ford";
                }
                else if(compns.comp_ownershp != null && compns.comp_ownershp.toLowerCase().indexOf("supp")>-1)
                {
                  ownershp = "Supplier";
                }
                else if(compns.comp_ownershp != null && (compns.comp_ownershp.toLowerCase() == "tpt" || compns.comp_ownershp.toLowerCase() == "third party"))
                {
                  ownershp = "Third Party";
                }

                if (c==this.exp_returnable)
                {
                  if(this.component_type.cd_cntr_typ=='01')
                  {

                    this.tempcomps.push
                    ({
                      id_proposal:this.selectedpart_submitter_tab3.proposalid,
                      id_comp:compns.id_comp,
                      comp_base:compns.no_comp_base,
                      comp_suffix:suffix,
                      comp_desc:compns.comp_desc,
                      qt_per_min_su:compns.qt_per_min_su,
                      comp_ret_type:compns.comp_ret_type,
                      updated_by:'',
                      updated_dt:'',
                      comp_l:compns.comp_l,
                      comp_w:compns.comp_w,
                      comp_h:compns.comp_h,
                      comp_tar_wt:compns.comp_tare_wt,
                      nestedfolderHt:compns.nestedfolderht,
                      comp_code:compns.comp_code,
                      comp_ownershp:ownershp,
                      comp_material:compns.comp_material,
                      tooledcomp:'',
                      is_compulsary:compc,
                      comp_bsd:compns.no_comp_base+'-'+suffix+'-'+compns.comp_desc,
                      comp_lwh:compns.comp_l+'x'+compns.comp_w+'x'+compns.comp_h,
                      compselctnfor:this.cntrorshpunitradio,
                      typ_no:compns.typ_no

                      });
                  }
                  else
                  {
// @ts-ignore
                  if(compns.typ_no==typeNo )// &&  compns.is_compulsary=="Y"))
                  {

                    this.tempcomps.push
                    ({
                      id_proposal:this.selectedpart_submitter_tab3.proposalid,
                      id_comp:compns.id_comp,
                      comp_base:compns.no_comp_base,
                      comp_suffix:suffix,
                      comp_desc:compns.comp_desc,
                      qt_per_min_su:compns.qt_per_min_su,
                      comp_ret_type:compns.comp_ret_type,
                      updated_by:'',
                      updated_dt:'',
                      comp_l:compns.comp_l,
                      comp_w:compns.comp_w,
                      comp_h:compns.comp_h,
                      comp_tar_wt:compns.comp_tare_wt,
                      nestedfolderHt:compns.nestedfolderht,
                      comp_code:compns.comp_code,
                      comp_ownershp:ownershp,
                      comp_material:compns.comp_material,
                      tooledcomp:'',
                      is_compulsary:compc,
                      comp_bsd:compns.no_comp_base+'-'+suffix+'-'+compns.comp_desc,
                      comp_lwh:compns.comp_l+'x'+compns.comp_w+'x'+compns.comp_h,
                      compselctnfor:this.cntrorshpunitradio,
                      typ_no:compns.typ_no

                      });
                    console.log('components for the container is ',this.tempcomps);


                  }
                }

                }

              }
              this.componentlistbasedontype=this.tempcomps;
              this.checkcomponentdetailsalreadypresentingrid();


          console.log('componenrtlistbasedontype in line 949'+JSON.stringify(this.componentlistbasedontype))


          }
          else if(this.componentOrAid.toLowerCase()=='aid')
          {
            this.tempcomps=[];
            this.tempcomps.push(
              {

                id_proposal:this.selectedpart_submitter_tab3.proposalid,
                id_comp:0,
                comp_base:this.component_type.cntr_typ_desc,
                comp_suffix:' ',
                comp_desc:'',
                qt_per_min_su:0,
                comp_ret_type:this.exp_returnable,
                updated_by:'',
                updated_dt:'',
                comp_l:0,
                comp_w:0,
                comp_h:0,
                comp_tar_wt:0,
                nestedfolderHt:0,
                comp_code:this.component_type.cd_cntr_typ,
                comp_ownershp:'',
                comp_material:'',
                tooledcomp:'',
                is_compulsary:'No',
                comp_bsd:this.component_type.cntr_typ_desc,
                comp_lwh:'0',
                compselctnfor:this.cntrorshpunitradio,
                typ_no:this.component_type.typ_no
              });

              this.componentlistbasedontype=JSON.parse(JSON.stringify(this.tempcomps));
              console.log('in load function',this.componentlistbasedontype);

            /*
            this.tempcomps=[];

            this.transactionService.getaddlncompids(this.component_type.cd_cntr_typ,this.exp_returnable).subscribe(ret=>
            {
              this.additionalcomponentIDs=ret;

              if(this.additionalcomponentIDs.length==1)
              {
                var lwh;
                var suffix;
                this.transactionService.getaddlncompdetls(this.additionalcomponentIDs[0].comp_id).subscribe(outputss=>
                  {
                    this.additionalcomponentDetails=outputss;

                    if(this.additionalcomponentDetails.comp_l!=null || this.additionalcomponentDetails.comp_w!=null || this.additionalcomponentDetails.comp_h!=null)
                      {
                        lwh="0x0x0";
                      }
                      else{
                        lwh=this.additionalcomponentDetails.comp_l+'x'+this.additionalcomponentDetails.comp_w+'x'+this.additionalcomponentDetails.comp_h;
                      }

                    if(this.additionalcomponentIDs[0].comp_suffix==undefined || this.additionalcomponentIDs[0].comp_suffix==null)
                    {
                      suffix=null;
                    }
                    else{
                      suffix=this.additionalcomponentIDs[0].comp_suffix;
                    }
                    this.tempcomps.push
                    ({
                      id_proposal:this.selectedpart_submitter_tab3.proposalid,
                      id_comp:0,
                      comp_base:this.additionalcomponentIDs[0].comp_base,
                      comp_suffix:suffix,
                      comp_desc:this.additionalcomponentIDs[0].comp_desc,
                      qt_per_min_su:Number(this.additionalcomponentDetails.qt_per_min_su),
                      comp_ret_type:this.additionalcomponentIDs[0].comp_ret_type,
                      updated_by:this.additionalcomponentDetails.updated_by,
                      updated_dt:this.additionalcomponentDetails.updated_dt,
                      comp_l:this.additionalcomponentDetails.comp_l,
                      comp_w:this.additionalcomponentDetails.comp_w,
                      comp_h:this.additionalcomponentDetails.comp_h,
                      comp_tar_wt:this.additionalcomponentDetails.comp_wt,
                      nestedfolderHt:this.additionalcomponentDetails.nestedfoldedht,
                      comp_code:this.additionalcomponentIDs[0].comp_type,
                      comp_ownershp:this.additionalcomponentDetails.comp_ownershp,
                      comp_material:this.additionalcomponentDetails.comp_material,
                      tooledcomp:this.additionalcomponentDetails.tooledcomp,
                      is_compulsary:"No",
                      comp_bsd:this.additionalcomponentIDs[0].comp_base+"-"+suffix+"-"+this.additionalcomponentIDs[0].comp_desc,
                      comp_lwh:lwh,
                      compselctnfor:this.cntrorshpunitradio


                    });

                    this.componentlistbasedontype=JSON.parse(JSON.stringify(this.tempcomps));
                    console.log("in load function",this.componentlistbasedontype);
                    this.checkcomponentdetailsalreadypresentingrid();

                  });



              }

              else if(this.additionalcomponentIDs.length>1)
              {


                for(let opl=0;opl<this.additionalcomponentIDs.length;opl++)
                {

                  this.transactionService.getaddlncompdetls(this.additionalcomponentIDs[opl].comp_id).subscribe(detlist=>
                  {

                    var lwh;
                    var suffix;

                    this.additionalcomponentDetails=detlist;

                    if(this.additionalcomponentDetails.comp_l!=null || this.additionalcomponentDetails.comp_w!=null || this.additionalcomponentDetails.comp_h!=null)
                    {
                      lwh="0x0x0";
                    }
                    else{
                      lwh=this.additionalcomponentDetails.comp_l+'x'+this.additionalcomponentDetails.comp_w+'x'+this.additionalcomponentDetails.comp_h;
                    }

                    if(this.additionalcomponentIDs[opl].comp_suffix==undefined || this.additionalcomponentIDs[opl].comp_suffix==null)
                    {
                    suffix=null
                    }
                    else{
                      suffix=this.additionalcomponentIDs[opl].comp_suffix;
                    }

                    this.tempcomps.push
                    ({
                      id_proposal:this.selectedpart_submitter_tab3.proposalid,
                      id_comp:0,
                      comp_base:this.additionalcomponentIDs[opl].comp_base,
                      comp_suffix:suffix,
                      comp_desc:this.additionalcomponentIDs[opl].comp_desc,
                      qt_per_min_su:Number(this.additionalcomponentDetails.qt_per_min_su),
                      comp_ret_type:this.additionalcomponentIDs[opl].comp_ret_type,
                      updated_by:this.additionalcomponentDetails.updated_by,
                      updated_dt:this.additionalcomponentDetails.updated_dt,
                      comp_l:this.additionalcomponentDetails.comp_l,
                      comp_w:this.additionalcomponentDetails.comp_w,
                      comp_h:this.additionalcomponentDetails.comp_h,
                      comp_tar_wt:this.additionalcomponentDetails.comp_wt,
                      nestedfolderHt:this.additionalcomponentDetails.nestedfoldedht,
                      comp_code:this.additionalcomponentIDs[opl].comp_type,
                      comp_ownershp:this.additionalcomponentDetails.comp_ownershp,
                      comp_material:this.additionalcomponentDetails.comp_material,
                      tooledcomp:this.additionalcomponentDetails.tooledcomp,
                      is_compulsary:"No",
                      comp_bsd:this.additionalcomponentIDs[opl].comp_base+"-"+suffix+"-"+this.additionalcomponentIDs[opl].comp_desc,
                      comp_lwh:lwh,
                      compselctnfor:this.cntrorshpunitradio
                      });//push ends

                      this.componentlistbasedontype=JSON.parse(JSON.stringify(this.tempcomps));
                      console.log("in load function",this.componentlistbasedontype);
                      this.checkcomponentdetailsalreadypresentingrid();

                  });
                }

              }
              else // when there is no additional component for the selected type
              {
                this.componentlistbasedontype=[];
              }
            });*/

          }
        }


        })
    }

    close_add_componnet_dialog()
    {
      this.displaydialog=false;
    }


    checkcomponentdetailsalreadypresentingrid()
    {

      if(this.componentdetailsgrid==null || this.componentdetailsgrid.length==0)
      {
        console.log('componentlistbaseddontype',this.componentlistbasedontype);
      }
      else if(this.componentlistbasedontype!=null && this.componentdetailsgrid!=null && this.componentdetailsgrid.length!=0 && this.componentlistbasedontype.length!=0)
      {
        const tempcomposs=this.componentlistbasedontype;

        const actualComps=[];

        for(let i=0;i<tempcomposs.length;i++)
        {

          let flag=false;
          if(['01','02','03'].includes(String(tempcomposs[i].comp_code)))
          {
          for(let j=0;j<this.componentdetailsgrid.length;j++)
          {
            if(tempcomposs[i].comp_base.trim()==this.componentdetailsgrid[j].comp_base.trim() )
            {
              if(tempcomposs[i].is_compulsary=='Yes' || tempcomposs[i].compselctnfor==this.componentdetailsgrid[j].compselctnfor)
              {
                if(tempcomposs[i].comp_suffix!=null && this.componentdetailsgrid[j].comp_suffix!=null)
                {
                  if(tempcomposs[i].comp_suffix.trim()==this.componentdetailsgrid[j].comp_suffix.trim())
                  {
                    console.log('came here to remove',tempcomposs[i]);
                    // tempcomposs.splice(i,1);
                    flag=true;
                    // alert(JSON.stringify(this.componentlistbasedontype));
                  }
                }
                else if(tempcomposs[i].comp_suffix==null && this.componentdetailsgrid[j].comp_suffix==null)
                {
                  // tempcomposs.splice(i,1);
                  flag=true;
                  // break;
                }

              }
            }
          }
        }

          if(flag==false)
          {
            actualComps.push(tempcomposs[i]);
          }

        }

        this.componentlistbasedontype=JSON.parse(JSON.stringify(actualComps));

        // alert(JSON.stringify(this.componentlistbasedontype)+this.componentlistbasedontype.length);


      }

    }

    // when add component is clicked
    // will be setting the component details dialog from componentlistbasedontype got from UI
    feeddetailsfortheselectedcomponent(componentnumber:storecomponent)
    {
      this.displaydialog=true;
      this.componentdetails=componentnumber;
      this.componentnumberselected=null;
      this.componentdetails.id_comp=0;
       this.componentdetails.tooledcomp = "No";
      // this.edit_measure_comp_add="lbs/inch";

      if(this.unitom == 'E')
      {
        this.edit_measure_comp_add = 'lbs/inch'
      }
      else
      {
        this.edit_measure_comp_add = 'kg/cm'
      }

      console.log(this.component_type)
  
      console.log("component details in feeddetailsforselectedcomponent",this.componentdetails);
      
  
  
  
  
        var selected_comp_type_no:number =0;
        selected_comp_type_no=this.component_type.typ_no


       this.selected_material={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''}
       this.transactionService.getContainerMaterial(selected_comp_type_no,this.exp_returnable).subscribe( cntr_mat=>{
        console.log(cntr_mat);
        this.cntrMatr=[];
        this.cntrMatr=cntr_mat;

  // service to get material list using return type and comp type code , inside the service add the below mapping code
  //
      if(this.cntrMatr!= null && this.cntrMatr.length!=0)
      {
      for(const t of this.cntrMatr)
      {
        if(t.cntr_matr_name==componentnumber.comp_material)
        {
          this.selected_material=t;
          break;
        }
      }
      }
    })




    }

    populateListOfMaterialsGrid(value)
    {
      const a=0;
      // alert("debug populateListOfMaterialsGrid "+a++);
      const templist=this.componentlistbasedontype;
      this.componentdetailsgrid=[];

      if((this.selectedpart_submitter_tab3.proposallastsaved<=2 && this.deleteflagbeforesave==false) || this.rowedited || this.rowadded)
      {
        this.containergrossweight=this.temp_cntr_gross_weight;
        this.shippingunittareweight=this.temp_shp_unt_tare_wt;
      this.shippingUnitExteriorHeightCalculated=this.initialShippingUnitExteriorHeightCalculated;
    //this.shippingunitgrossweight=(this.no_of_container*this.containergrossweight)+this.shippingunittareweight;
    this.shippingunitgrossweight=(this.no_of_container*this.containergrossweight);

            //trunc grossweight
            console.log("shippingunitgrossweight grossweight before Round Off",this.shippingunitgrossweight)
            this.shippingunitgrossweight_display=this.transactionService.roundValueToNDecimal(this.shippingunitgrossweight,3)
             console.log("shippingunitgrossweight grossweight after Round Off",this.shippingunitgrossweight) 

            console.log("container grossweight before Round Off",this.containergrossweight)
            this.containergrossweight_display=this.transactionService.roundValueToNDecimal(this.containergrossweight,3)
             console.log("container grossweight after Round Off",this.containergrossweight)

    console.log("shipping unit tare weight before Round Off",this.shippingunittareweight)
    this.shippingunittareweight_display=this.transactionService.roundValueToNDecimal(this.shippingunittareweight,3)
    console.log("shipping unit tare weight after Round Off",this.shippingunittareweight)
      console.log("shipping unit gross weight in 1389",this.shippingunitgrossweight)//+this.containergrossweight,this.shippingunittareweight,this.shippingunitgrossweight);
      }

      // checking value.comp_base is not null because when there is value in componentlistbasedontype and
      // if this function is called in delete or anyoother place where we send empty object it will throw error
      // so validating the passed parameter to ensure it is not empty

      if(this.componentlistbasedontype!=null && this.componentlistbasedontype.length!=0 && value.comp_base!=null)
      {


        let flag=false;
        const templist:storecomponent[]=this.componentlistbasedontype;
        for(let kj=0;kj<templist.length;kj++)
        {
          if(['01','02','03'].includes(String(templist[kj].comp_code)))
          {
            if (templist[kj].comp_base.trim()==value.comp_base.trim())
            {
            if(templist[kj].comp_suffix!=null && value.comp_suffix!=null)
                  {
                    if(templist[kj].comp_suffix.trim()==value.comp_suffix.trim())
                      {

                        templist.splice(kj,1);
                        flag=true;
                      }
                  }

                  else
                  {
                    flag=true;
                    templist.splice(kj,1);
                  }
            }
        }
        }
        if(flag)
        {
          this.componentlistbasedontype=JSON.parse(JSON.stringify(templist));
        }
      }


    }

    populateGrid()
    {
      // this.componentdetailsgrid=[];

      this.transactionService.getaddedcomponentdetailsfortab3(this.selectedpart_submitter_tab3.proposalid).subscribe(gridout=>
      {

       this.tempo=JSON.parse(JSON.stringify(gridout))
       const tempobj=this.tempo;
       console.log(this.tempo,'tempo');
       let componentweight;
       let componentweightshdbeupdated=false;

       var compulsoryCount=0
     var onlyCompulsoryComponentPresent=false;
      
       //console.log("tempo len"+tempobj.length+this.tempo[0].comp_tare_wt);
       
       for(var ki=0;ki<tempobj.length;ki++)
       {
          if(tempobj[ki].is_compulsary.toLowerCase() == "yes")
          {
            compulsoryCount+=1;
          }
          if(!(this.componentdetailsgrid.some(ele=>ele.id_comp==tempobj[ki].id_comp)))
          {

            // rowadded and rowedited condition is checked to know whether the user have added components or edited components,accordingly shippingunit gross weight will be updated
            if((this.selectedpart_submitter_tab3.proposallastsaved<=2 && this.deleteflagbeforesave==false )|| (this.selectedpart_submitter_tab3.proposallastsaved>=3 && this.deleteflag==false && this.editcancel_click==false) ||this.rowedited ||this.rowadded)
            {

              componentweight=tempobj[ki].qt_per_min_su*tempobj[ki].comp_tare_wt;
              console.log('component weight fetched',componentweight)
              if(tempobj[ki].compselctnfor=='Shipping Unit')
              {
              this.shippingunittareweight+=componentweight
                this.shippingunitgrossweight+=componentweight;
              console.log("Shipping unit weight in if part populate grid",this.shippingunitgrossweight);

              if(tempobj[ki].typ_no == 2 || tempobj[ki].typ_no == 3 || tempobj[ki].typ_no == 4)
              {
                this.shippingUnitExteriorHeightCalculated+=tempobj[ki].comp_h*tempobj[ki].qt_per_min_su;
              }
            }
              else
              {
                this.containergrossweight+=componentweight;
              this.shippingunittareweight+=(this.no_of_container*componentweight);
                this.shippingunitgrossweight+=(this.no_of_container*componentweight);
             /* if(tempobj[ki].typ_no == 2 || tempobj[ki].typ_no == 3 || tempobj[ki].typ_no == 4)
              {
                this.shippingUnitExteriorHeightCalculated+=tempobj[ki].comp_h*tempobj[ki].qt_per_min_su*this.noLayersPerShippingUnit;
              }*/
              }
              console.log('came into 1436'+this.shippingunitgrossweight);


              // when they have saved that tab already and now if they have added/Edited new componnet,
              // shipping unit gross weight shd be updated in DB too
              if(this.selectedpart_submitter_tab3.proposallastsaved>=3 && (this.rowadded || this.rowedited ))
              {
                this.comp_material_forpost.cntr_gross_wt=this.containergrossweight;
                this.comp_material_forpost.shp_unt_gross_wt=this.shippingunitgrossweight;
              this.comp_material_forpost.shp_unt_tar_wt=this.shippingunittareweight;
                //alert(JSON.stringify(this.comp_material_forpost));
                console.log(this.comp_material_forpost);
                componentweightshdbeupdated=true;
              }
            }


            this.componentdetailsgrid.push(
              {
              id_proposal:this.selectedpart_submitter_tab3.proposalid,
              id_comp:tempobj[ki].id_comp,
              comp_base:tempobj[ki].comp_base,
              comp_suffix:tempobj[ki].comp_suffix,
              comp_desc:tempobj[ki].comp_desc,
              comp_l:tempobj[ki].comp_l,
              comp_w:tempobj[ki].comp_w,
              comp_h:tempobj[ki].comp_h,
              comp_ret_type:tempobj[ki].comp_ret_type,
              qt_per_min_su:tempobj[ki].qt_per_min_su,
              updated_by:tempobj[ki].upated_by,
              updated_dt:tempobj[ki].updated_dt,
              comp_tar_wt:tempobj[ki].comp_tare_wt,
              nestedfolderHt:tempobj[ki].nestedfolderht,
              comp_code:tempobj[ki].comp_code,
              comp_ownershp:tempobj[ki].comp_ownershp,
              comp_material:tempobj[ki].comp_material,
              tooledcomp:tempobj[ki].tooledcomp,
              is_compulsary:tempobj[ki].is_compulsary,
              comp_lwh:tempobj[ki].comp_l+'x'+tempobj[ki].comp_w+'x'+tempobj[ki].comp_h,
              comp_bsd:tempobj[ki].comp_base+'-'+tempobj[ki].comp_suffix+'-'+tempobj[ki].comp_desc,
              compselctnfor:tempobj[ki].compselctnfor,
              typ_no:tempobj[ki].typ_no
              });
              this.componentdetailsgrid.sort((a,b)=>(a.comp_bsd<b.comp_bsd)?-1:1)
              console.log('componentdetailsgrid',this.componentdetailsgrid);

              this.checkcomponentdetailsalreadypresentingrid();

              console.log('componeent buuton diable')
              console.log(this.componentOrAid)
              console.log(this.componentlistbasedontype)
              console.log(this.component_type.cntr_typ_desc)
          }
          
  
        }
  
          //below not used
      if(compulsoryCount == tempobj.length && this.selectedpart_submitter_tab3.proposallastsaved<=2)
      {
        onlyCompulsoryComponentPresent=true;
      }
      if(this.compAddedChangeHeight || this.compEdidtedChangeHeight || this.compCompulsuryAdded || this.selectedpart_submitter_tab3.proposallastsaved<=2)
      {
         this.shippingUnitExteriorHeightDisplay=this.transactionService.roundValueToNDecimal(this.shippingUnitExteriorHeightCalculated,3);;
      }
        if(this.unitom == "E")
        {
          this.edit_measure_comp = 'lbs';
        }
        else
        {
          this.edit_measure_comp = 'kg';
        }

        // if(this.edit_measure_comp=='kg')
        // {

        //   console.log("shipping unit tare weight",this.shippingunittareweight);
        //   console.log("shippingunit gross weight",this.shippingunitgrossweight);
        //   console.log("container gross wt",this.containergrossweight)
        //   //alert(this.shippingunittareweight+" "+this.shippingunitgrossweight+" "+this.containergrossweight)
        //   if(this.shippingunittareweight!=null)
        //   {
        //     this.shippingunittareweight=Math.round((0.453592*this.shippingunittareweight)*1000)/1000
        //   }
        //   if(this.shippingunitgrossweight!=null)
        //   {
        //     this.shippingunitgrossweight=Math.round((0.453592*this.shippingunitgrossweight)*1000)/1000;
        //   }
        //   if(this.containergrossweight!=null)
        //   {
        //     this.containergrossweight=Math.round((0.453592*this.containergrossweight)*1000)/1000;
        //   }
        // }

        //trunc grossweight
               console.log("shippingunitgrossweight grossweight before Round Off",this.shippingunitgrossweight)
               this.shippingunitgrossweight_display=this.transactionService.roundValueToNDecimal(this.shippingunitgrossweight,3)
             console.log("shippingunitgrossweight grossweight after Round Off",this.shippingunitgrossweight) 

              console.log("container grossweight before Round Off",this.containergrossweight)
              this.containergrossweight_display=this.transactionService.roundValueToNDecimal(this.containergrossweight,3)
             console.log("container grossweight after Round Off",this.containergrossweight)
      console.log("shipping unit tare weight before Round Off",this.shippingunittareweight)
      this.shippingunittareweight_display=this.transactionService.roundValueToNDecimal(this.shippingunittareweight,3)
      console.log("shipping unit tare weight after Round Off",this.shippingunittareweight)
      
        if(componentweightshdbeupdated)
        {
          this.comp_material_forpost.cntr_gross_wt=this.containergrossweight_display;
          this.comp_material_forpost.shp_unt_gross_wt=this.shippingunitgrossweight_display;
        this.comp_material_forpost.shp_unt_tar_wt=this.shippingunittareweight_display;
          this.transactionService.savetab3details(this.comp_material_forpost).subscribe(weightupd=>
            {
              const weightupdatestat=weightupd;
                let msgs:any[];
                if(weightupdatestat=='Success')
                {
                  componentweightshdbeupdated=false;
                  this.packagingproposal.addtoast({severity:'success', summary: 'success', detail: 'Shipping Unit Gross Weight Updated'});
                }
                else{
                  this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Shipping Unit Gross weight not Updated'});
                }
            });
        }

        if(this.edit_measure_comp=='kg')
        {

          console.log('shipping unit tare weight',this.shippingunittareweight);
          console.log('shippingunit gross weight',this.shippingunitgrossweight);
          console.log('container gross wt',this.containergrossweight)
          // alert(this.shippingunittareweight+" "+this.shippingunitgrossweight+" "+this.containergrossweight)
          if(this.shippingunittareweight!=null)
          {
            this.shippingunittareweight=Math.round((0.453592*this.shippingunittareweight)*1000)/1000
          }
          if(this.shippingunitgrossweight!=null)
          {
            this.shippingunitgrossweight=Math.round((0.453592*this.shippingunitgrossweight)*1000)/1000;
          }
          if(this.containergrossweight!=null)
          {
            this.containergrossweight=Math.round((0.453592*this.containergrossweight)*1000)/1000;
          }
        }

        this.rowedited=false;
        this.rowadded=false;
        this.deleteflag=false;
        this.deleteflagbeforesave=false;
      this.compAddedChangeHeight=false;
      this.compEdidtedChangeHeight=false;
      this.compCompulsuryAdded=false;
      }


      );




      this.cols=[
        { field: 'comp_bsd', header:'Base-Suffix-Description'},
        { field: 'compselctnfor', header:'Component Selection For'},
        // { field: 'comp_suffix', header:'Component Suffix'},
        // { field: 'comp_desc', header:'Component Description'},
        { field: 'comp_lwh', header:'LxWxH'},
       // { field: 'comp_w', header:'Compoenent Width'}  ,
       // { field: 'comp_h', header:'Compoenent Height'},
        { field: 'qt_per_min_su', header:'Quantity Per Shipping Unit'},
        { field: 'comp_tar_wt', header:'Component Tare Weight'},
        { field: 'nestedfolderHt', header:'Nested Folded Height'},
        { field: 'comp_ret_type', header:'E/R'}    ,
        { field: 'comp_code', header:'Component Code'},
        { field: 'comp_ownershp', header:'Component Ownership'},
        { field: 'comp_material', header:'Component Material'},
        { field: 'tooledcomp', header:'Tooled Component'} ,
        // { field: 'is_compulsary', header:'Is Compulsory'},
        // {field:'',header:'Edit/Delete Component'}
      ];

      this.frozenCols=[
        {field:'',header:'Edit/Delete Component'},
        {field:'is_compulsary', header:'Is Compulsory'}
    ]
    }

    store_primary_part_exist()
    {

      this.current_region_code_prt=this.transactionService.getregioncode(this.region);
      if(this.current_region_code_prt=='NA')
      {
        sessionStorage.setItem('napackpropart', JSON.stringify(this.selectedpart_submitter_tab3));
      }

      if(this.current_region_code_prt=='EU')
      {
        sessionStorage.setItem('eupackpropart', JSON.stringify(this.selectedpart_submitter_tab3));
      }

      if(this.current_region_code_prt=='AP')
      {
        sessionStorage.setItem('appackpropart', JSON.stringify(this.selectedpart_submitter_tab3));
      }

      if(this.current_region_code_prt=='SA')
      {
         sessionStorage.setItem('sapackpropart', JSON.stringify(this.selectedpart_submitter_tab3));
      }
    }


    on_change_measure_comp()
    {


      console.log(this.edit_measure_comp);
      console.log(this.shippingunittareweight)
      console.log(this.shippingunitgrossweight);
      console.log(this.containergrossweight);

      if(this.unitom == 'E')
    {
      this.edit_measure_comp = 'lbs';
    }
    else
    {
      this.edit_measure_comp = 'kg';
    }

      // if(this.edit_measure_comp=="lbs")
      // {


      //   if(this.shippingunittareweight!=null)
      //   {

      //     this.shippingunittareweight=Math.round((2.20462*this.shippingunittareweight)*1000)/1000;

      //   }
      //   if(this.shippingunitgrossweight!=null)
      //   {

      //     this.shippingunitgrossweight=Math.round((2.20462*this.shippingunitgrossweight)*1000)/1000;


      //   }
      //   if(this.containergrossweight!=null)
      //   {



      //     this.containergrossweight=Math.round((2.20462*this.containergrossweight)*1000)/1000;



      //   }

      // }
      // else if(this.edit_measure_comp=="kg")
      // {



      //   if(this.shippingunittareweight!=null)
      //   {

      //     this.shippingunittareweight=Math.round((0.453592*this.shippingunittareweight)*1000)/1000;

      //   }
      //   if(this.shippingunitgrossweight!=null)
      //   {

      //     this.shippingunitgrossweight=Math.round((0.453592*this.shippingunitgrossweight)*1000)/1000;


      //   }
      //   if(this.containergrossweight!=null)
      //   {



      //     this.containergrossweight=Math.round((0.453592*this.containergrossweight)*1000)/1000;



      //   }


      // }

    }
  calculateShippingUnitExtHeight()
  {
     var shpExthgt=0;
     shpExthgt=this.Tab2DataForPost.cntr_height*(this.Tab2DataForPost.max_layrs_shp_unt-this.Tab2DataForPost.empty_trays_cntr_layrs);

     if(this.componentdetailsgrid != null && this.componentdetailsgrid.length != 0)
     {
       for(var i of this.componentdetailsgrid)
       {
         if(i.typ_no == 2 || i.typ_no == 3 || i.typ_no == 4)
         {
           if(i.compselctnfor.toLowerCase() == "shipping unit")
           {
             shpExthgt+=i.qt_per_min_su*i.comp_h;
           }
           /*else
           {
             shpExthgt+=(this.Tab2DataForPost.max_layrs_shp_unt-this.Tab2DataForPost.empty_trays_cntr_layrs)*i.qt_per_min_su*i.comp_h;
           }*/
         }
       }
     }

     this.shippingUnitExteriorHeightDisplay=this.transactionService.roundValueToNDecimal(shpExthgt,3);
     this.suExtHgtValidation();
  }
    suGrossWtInValidation()
    {
      if(this.shippingunitgrossweight_display<=0 || this.shippingunitgrossweight_display==null || this.shippingunitgrossweight_display>999999.999)
      {
        this.sugrosswtInvalid=true;
        this.sugrossewtInvalidReason="Should be of between 0 to 999999.999";
        console.log("SUGROSSWGT");
      }
      else
      {
        this.sugrosswtInvalid=false;
        this.sugrossewtInvalidReason=null;
        console.log('SUGROSSWGTNOT');
      }
    }
    suTareWtInValidation()
    {
      if(this.shippingunittareweight_display<=0 || this.shippingunittareweight_display==null ||this.shippingunittareweight_display>999999.999)
      {
        this.sucontarewtInvalid=true;
        this.sucontarewtInvalidReason="Should be of between 0 to 999999.999";
      }
      else
      {
        this.sucontarewtInvalid=false;
        this.sucontarewtInvalidReason=null;
      }
    }
    contGrossWtInValidation()
    {
      if(this.containergrossweight_display<=0 || this.containergrossweight_display==null || this.containergrossweight_display>999999.999)
      {
        this.cntrgrosswtInvalid=true;
        this.cntrgrosswtInvalidReason="Should be of between 0 to 999999.999";
      }
      else
      {
        this.cntrgrosswtInvalid=false;
        this.cntrgrosswtInvalidReason=null;
      }
    }
    suExtHgtValidation()
    {
      let shp_ext_h = String(this.shippingUnitExteriorHeightDisplay).split(".");
     /* if(this.cntrdetforpost.shp_unt_ext_height<this.cntrdetforpost.cntr_height)
      {
        this.suexthgtInvalid=true;
        this.suexthgtInValidReason="Shipping Unit Exterior Height cannot be less than  Container Exterior Height";
      } */
      if(this.shippingUnitExteriorHeightDisplay==null || this.shippingUnitExteriorHeightDisplay==0)
      {
        this.suexthgtInvalid=true;
        this.suexthgtInValidReason="Required";    
      }
      else
      {
        if(!String(this.shippingUnitExteriorHeightDisplay).includes("."))
        {
        
           
            if(this.shippingUnitExteriorHeightDisplay<1 || this.shippingUnitExteriorHeightDisplay>9999.999 )
            {
              this.suexthgtInvalid=true;
              this.suexthgtInValidReason="Should be of range 1 to 9999.999";
            }
            else if( String(this.shippingUnitExteriorHeightDisplay).length>8)
            {
              this.suexthgtInvalid=true;
              this.suexthgtInValidReason="Max Length Of Characters can be 8";
            }
            else if(this.shippingUnitExteriorHeightDisplay>0 && this.shippingUnitExteriorHeightDisplay<this.Tab2DataForPost.cntr_height)
            {
            this.suexthgtInvalid=true;
            this.suexthgtInValidReason="Shipping Unit Exterior Height cannot be less than  Container Exterior Height";
            }
    
            else
            {
              this.suexthgtInvalid=false;
              this.suexthgtInValidReason=null;
            }
        }
        else if(String(this.shippingUnitExteriorHeightDisplay).includes("."))
        {
            if(String(shp_ext_h[0]).length<0 || String(shp_ext_h[0]).length>4)
            {
              this.suexthgtInvalid=true;
              this.suexthgtInValidReason="Should be of range 1 to 9999.999";
            }
            else if(this.shippingUnitExteriorHeightDisplay<1 || this.shippingUnitExteriorHeightDisplay>9999.999)
            {
              this.suexthgtInvalid=true;
              this.suexthgtInValidReason="Should be of range 1 to 9999.999";
            }
            else if(String(shp_ext_h[1]).length<0 || String(shp_ext_h[1]).length>3)
            {
              this.suexthgtInvalid=true;
              this.suexthgtInValidReason="Max Length Of decimal values can be 3";
            }
            else if(this.shippingUnitExteriorHeightDisplay>0 && this.shippingUnitExteriorHeightDisplay<this.Tab2DataForPost.cntr_height)
            {
              this.suexthgtInvalid=true;
              this.suexthgtInValidReason="Shipping Unit Exterior Height cannot be less than  Container Exterior Height";
            }
            else
            {
              this.suexthgtInvalid=false;
              this.suexthgtInValidReason=null;
            }
        }   
        
      }
      
    }
    
    
    suNestedFldedHtValidation()
    {
      if(this.shippinUnitNestedFoldedHeight==null || this.shippinUnitNestedFoldedHeight==0)
      {
        this.suNestedHtInValid=true;
        this.suNestedHtInValidReason="Required";
      }
      else if(this.shippinUnitNestedFoldedHeight<1 || this.shippinUnitNestedFoldedHeight>99999)
      {
        this.suNestedHtInValid=true;
        this.suNestedHtInValidReason="Should be of range 1 to 99999";
      }
      else if(this.shippinUnitNestedFoldedHeight>0 && this.shippingUnitExteriorHeightDisplay>0 && this.shippinUnitNestedFoldedHeight>this.shippingUnitExteriorHeightDisplay)
      {
        this.suNestedHtInValid=true;
        this.suNestedHtInValidReason="Nested Folded Height cannot be Greater than Exterior height";
      }
      else
      {
        this.suNestedHtInValid=false;
        this.suNestedHtInValidReason=null;
      }
    }
    compDescInValidationForAdd()
    {
      if(this.componentdetails.comp_desc == null || this.componentdetails.comp_desc.trim() == '')
      {
        this.comp_descInValid=true;
        this.comp_descInValidReason='Required';
      }
      else if(this.componentdetails.comp_desc.length < 1 ||  this.componentdetails.comp_desc.length > 99.999)
      {
        this.comp_descInValid=true;
        this.comp_descInValidReason='Should be of range 1 to 99.999';
      }
      else
      {
        this.comp_descInValid=false;
        this.comp_descInValidReason=null;
      }
    }
    compLenInValidationForAdd()
  {
    const comp_l = String(this.componentdetails.comp_l).split('.');
    if(!String(this.componentdetails.comp_l).includes('.'))
    {
    if(this.componentdetails.comp_l==null || this.componentdetails.comp_l==0)
        {
          this.comp_l_InValid=true;
          this.comp_l_InValidReason='Required';
        }
       else if( String(this.componentdetails.comp_l).length>8)
        {
          this.comp_l_InValid=true;
          this.comp_l_InValidReason='Max Length Of Characters can be 8';
        }
        else if(this.componentdetails.comp_l<1 || this.componentdetails.comp_l>9999.999)
        {
          this.comp_l_InValid=true;
          this.comp_l_InValidReason='Should be of range 1 to 9999.999';
        }
      else
        {
          this.comp_l_InValid=false;
          this.comp_l_InValidReason=null;
        }
    }
    else if(String(this.componentdetails.comp_l).includes('.'))
    {
        if(String(comp_l[0]).length<0 || String(comp_l[0]).length>4)
        {
          this.comp_l_InValid=true;
          this.comp_l_InValidReason='Should be of range 1 to 9999.999';
        }
        else if(String(comp_l[1]).length<0 || String(comp_l[1]).length>3)
        {
          this.comp_l_InValid=true;
          this.comp_l_InValidReason='Max Length Of decimal values can be 3';
        }
        else
        {
          this.comp_l_InValid=false;
          this.comp_l_InValidReason=null;
        }
    }
    else
    {
    this.comp_l_InValid=false;
    this.comp_l_InValidReason=null;
    }
  }
  compWidInValidationForAdd()
  {

    const comp_w = String(this.componentdetails.comp_w).split('.');
    if(!String(this.componentdetails.comp_w).includes('.'))
    {
    if(this.componentdetails.comp_w==null || this.componentdetails.comp_w==0)
        {
          this.comp_w_InValid=true;
          this.comp_w_InValidReason='Required';
        }
       else if( String(this.componentdetails.comp_w).length>8)
        {
          this.comp_w_InValid=true;
          this.comp_w_InValidReason='Max Length Of Characters can be 8';
        }
        else if(this.componentdetails.comp_w<1 || this.componentdetails.comp_w>9999.999)
        {
          this.comp_w_InValid=true;
          this.comp_w_InValidReason='Should be of range 1 to 9999.999';
        }
      else
        {
          this.comp_w_InValid=false;
          this.comp_w_InValidReason=null;
        }
    }
    else if(String(this.componentdetails.comp_w).includes('.'))
    {
        if(String(comp_w[0]).length<0 || String(comp_w[0]).length>4)
        {
          this.comp_w_InValid=true;
          this.comp_w_InValidReason='Should be of range 1 to 9999.999';
        }
        else if(String(comp_w[1]).length<0 || String(comp_w[1]).length>3)
        {
          this.comp_w_InValid=true;
          this.comp_w_InValidReason='Max Length Of decimal values can be 3';
        }
        else
        {
          this.comp_w_InValid=false;
          this.comp_w_InValidReason=null;
        }
    }
    else
    {
    this.comp_w_InValid=false;
    this.comp_w_InValidReason=null;
    }
  }
  compHgtInValidationForAdd()
  {
    const comp_h = String(this.componentdetails.comp_h).split('.');
    if(!String(this.componentdetails.comp_h).includes('.'))
    {
    if(this.componentdetails.comp_h==null || this.componentdetails.comp_h==0)
        {
          this.comp_h_InValid=true;
          this.comp_h_InValidReason='Required';
        }
       else if( String(this.componentdetails.comp_h).length>8)
        {
          this.comp_h_InValid=true;
          this.comp_h_InValidReason='Max Length Of Characters can be 8';
        }
        else if(this.componentdetails.comp_h<1 || this.componentdetails.comp_h>9999.999)
        {
          this.comp_h_InValid=true;
          this.comp_h_InValidReason='Should be of range 1 to 9999.999';
        }
      else
        {
          this.comp_h_InValid=false;
          this.comp_h_InValidReason=null;
        }
    }
    else if(String(this.componentdetails.comp_h).includes('.'))
    {
        if(String(comp_h[0]).length<0 || String(comp_h[0]).length>4)
        {
          this.comp_h_InValid=true;
          this.comp_h_InValidReason='Should be of range 1 to 9999.999';
        }
        else if(String(comp_h[1]).length<0 || String(comp_h[1]).length>3)
        {
          this.comp_h_InValid=true;
          this.comp_h_InValidReason='Max Length Of decimal values can be 3';
        }
        else
        {
          this.comp_h_InValid=false;
          this.comp_h_InValidReason=null;
        }
    }
    else
    {
    this.comp_h_InValid=false;
    this.comp_h_InValidReason=null;
    }
  }
  compTareWgtInValidationForAdd()
  {
    const comp_tar_wt = String(this.componentdetails.comp_tar_wt).split('.');
    if(!String(this.componentdetails.comp_tar_wt).includes('.'))
    {
    if(this.componentdetails.comp_tar_wt==null || this.componentdetails.comp_tar_wt==0)
        {
          this.comp_tare_wgtInValid=true;
          this.comp_tare_wgtInValidReason='Required';
		   this.comp_totl_wt_InValid = false;
        }
       else if( String(this.componentdetails.comp_tar_wt).length>8)
        {
          this.comp_tare_wgtInValid=true;
          this.comp_tare_wgtInValidReason='Max Length Of Characters can be 8';
		   this.comp_totl_wt_InValid = false;
        }
        else if(this.componentdetails.comp_tar_wt<1 || this.componentdetails.comp_tar_wt>9999.999)
        {
          this.comp_tare_wgtInValid=true;
          this.comp_tare_wgtInValidReason='Should be of range 1 to 9999.999';
		   this.comp_totl_wt_InValid = false;
        }
      else
        {
          this.comp_tare_wgtInValid=false;
          this.comp_tare_wgtInValidReason=null;
		  this.comp_tarwgt_shpunitwgt_validation_add();
        }
    }
    else if(String(this.componentdetails.comp_tar_wt).includes('.'))
    {
        if(String(comp_tar_wt[0]).length<0 || String(comp_tar_wt[0]).length>4)
        {
          this.comp_tare_wgtInValid=true;
          this.comp_tare_wgtInValidReason='Should be of range 1 to 9999.999';
		   this.comp_totl_wt_InValid = false;
        }
        else if(String(comp_tar_wt[1]).length<0 || String(comp_tar_wt[1]).length>3)
        {
          this.comp_tare_wgtInValid=true;
          this.comp_tare_wgtInValidReason='Max Length Of decimal values can be 3';
		   this.comp_totl_wt_InValid = false;
        }
        else
        {
          this.comp_tare_wgtInValid=false;
          this.comp_tare_wgtInValidReason=null;
		  this.comp_tarwgt_shpunitwgt_validation_add();
        }
    }
    else
    {
    this.comp_tare_wgtInValid=false;
    this.comp_tare_wgtInValidReason=null;
	this.comp_tarwgt_shpunitwgt_validation_add();
    }
  }
    compQtPerShpUntInValidationForAdd()
    {
      if(this.componentdetails.qt_per_min_su==0 || this.componentdetails.qt_per_min_su==null)
      {
        this.qt_pershp_untInValid=true;
        this.qt_pershp_untInValidReason='Required';
      }
      else if(this.componentdetails.qt_per_min_su<1 || this.componentdetails.qt_per_min_su>999  || String(this.componentdetails.qt_per_min_su).includes('.'))
      {
        this.qt_pershp_untInValid=true;
        this.qt_pershp_untInValidReason='Should be of range 1 to 999and cannot contain Decimal';
      }
      else
      {
        this.qt_pershp_untInValid=false;
        this.qt_pershp_untInValidReason=null;
        this.comp_tarwgt_shpunitwgt_validation_add();
        console.log('CompQtShpUnt in validations', this.componentdetails.qt_per_min_su);
      }
    }
    ownershpInValidationForAdd()
    {
      if(this.componentdetails.comp_ownershp==null || this.componentdetails.comp_ownershp.trim()=='')
      {
        this.ownrshpInValid=true;
        this.ownrshpInValidReason='Required'
      }
      else
      {
        this.ownrshpInValid=false;
        this.ownrshpInValidReason=null
      }
    }
  
    // tooledCompInValidationForAdd()
    // {
    //   if(this.componentdetails.tooledcomp==null || this.componentdetails.tooledcomp.trim()=="")
    //   {
    //     this.tooledCompInValid=true;
    //     this.tooledCompInValidReason="Required"
    //   }
    //   else
    //   {
    //     this.tooledCompInValid=false;
    //     this.tooledCompInValidReason=null
    //   }
    // }
    selectedMaterialInValidationForAdd()
  {
    if(this.selected_material.cntr_matr_name==null || this.selected_material.cntr_matr_name.trim()=='')
    {
      this.selectMaterialInValid=true;
      this.selectMaterialInValidReason='Required';
    }
    else
    {
      this.selectMaterialInValid=false;
      this.selectMaterialInValidReason=null;
    }
  }

    saveThisAndEnableFourthForm()
    {
  
      if(this.componentdetailsgrid.some(element=>element.is_compulsary=='Yes' && (element.qt_per_min_su==0 || element.comp_tar_wt==0)))
      {
        this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Enter Compulsory Component Details and then Save !!'});

      }
      else
      {
        console.log(this.comp_material_forpost);
        this.comp_material_forpost.mot=this.selected_mode_transport.transport_desc;
        this.comp_material_forpost.rel_level=this.selected_release_level.releaselevel;
        this.comp_material_forpost.id_proposal=this.id_proposal_compmat
        console.log(this.id_proposal_compmat)
        console.log(this.comp_material_forpost.id_proposal);

        // for now manually intitialize and assign calculated values
        // this.comp_material_forpost.cntr_shp_unit="Container";

        if(this.unitom == 'E')
        {
          this.edit_measure_comp = 'lbs';
        }
        else
        {
          this.edit_measure_comp = 'kg';
        }

        // if(this.edit_measure_comp=="kg")
        // {


        //   if(this.shippingunittareweight!=null)
        //   {

        //     this.shippingunittareweight=Math.round((2.20462*this.shippingunittareweight)*1000)/1000;

        //   }
        //   if(this.shippingunitgrossweight!=null)
        //   {

        //     this.shippingunitgrossweight=Math.round((2.20462*this.shippingunitgrossweight)*1000)/1000;


        //   }
        //   if(this.containergrossweight!=null)
        //   {



        //     this.containergrossweight=Math.round((2.20462*this.containergrossweight)*1000)/1000;



        //   }

        // }

        this.comp_material_forpost.cntr_shp_unit=this.cntrorshpunitradio;
        this.comp_material_forpost.cntr_gross_wt=this.containergrossweight_display;
        this.comp_material_forpost.shp_unt_gross_wt=this.shippingunitgrossweight_display;
        this.comp_material_forpost.shp_unt_tar_wt=this.shippingunittareweight_display;
        
        //alert(JSON.stringify(this.comp_material_forpost));

        this.suGrossWtInValidation();
        this.suTareWtInValidation();
        this.contGrossWtInValidation();


        this.expAidsWgtValidation();
	
	 this.suExtHgtValidation();
      this.suNestedFldedHtValidation();
        
        console.log("Inside Save_next last console",this.shp_comp_wt,this.temp_shp_compwt,this.totl_wt_InValid)
      
        if(!this.sugrosswtInvalid  && !this.sucontarewtInvalid && !this.cntrgrosswtInvalid && !this.totl_wt_InValid && !this.suexthgtInvalid && !this.suNestedHtInValid)
        {
       this.transactionService.savetab3details(this.comp_material_forpost).subscribe(response=>
        {
            let msgs:any[];
            if(response=='Success')
            {
              this.packagingproposal.addtoast({severity:'success', summary: 'success', detail: 'Component and Material details saved successfully'});
             this.postTab2AndMovetONextTab();
            }
            else
            {
              this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Component and Material details is not saved! try again'});
      
              /*if(this.edit_measure_comp=="kg")
              {
          
          
                
                if(this.shippingunittareweight!=null)
                {
          
                  this.shippingunittareweight=Math.round((0.453592*this.shippingunittareweight)*1000)/1000;
          
                }
                if(this.shippingunitgrossweight!=null)
                {
                  
                  this.shippingunitgrossweight=Math.round((0.453592*this.shippingunitgrossweight)*1000)/1000;
                  
          
                }
                if(this.containergrossweight!=null)
                {
          
          
          
                  this.containergrossweight=Math.round((0.453592*this.containergrossweight)*1000)/1000;
          
          
                  
                }
              }*/
            }
           
      
        })
      }
      /*else
      {
        this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Check the Container Gross weight, Shipping unit weights and Try again'});
      }*/
      }
    }

    postTab2AndMovetONextTab()
    {
      this.Tab2DataForPost.shp_unt_ext_height=this.shippingUnitExteriorHeightDisplay;
    this.Tab2DataForPost.shp_unt_nestd_fld_height=this.shippinUnitNestedFoldedHeight;
    this.transactionService.savetab2details(this.Tab2DataForPost).subscribe(tab2_output=>{
      if(tab2_output.toLowerCase() == "success")
      {
        this.packagingproposal.addtoast({severity:'success', summary: 'success', detail: 'Height Updated Successfully'});
        var check=false;
        console.log("comdet",this.originalTab3Details,this.changedTab3Details)
          if(JSON.stringify(this.originalTab3Details) !== JSON.stringify(this.changedTab3Details))
          {
            //update change status
            
            //alert("change in data"+this.existChangeStatusTab3)
            if(this.existChangeStatusTab3 != null && this.existChangeStatusTab3.toLowerCase()=="unchanged")
            {
              var setChangestatusTab3:changeTrackingForImprovement={idproposal:0,chnge_timestamp:"",status:""}
              setChangestatusTab3.idproposal=this.selectedpart_submitter_tab3.proposalid
              setChangestatusTab3.status="changed";
              var cur_date=new Date();
              var cur_date_transform=this.date.transform(cur_date, 'yyyy-MM-dd HH:mm:ss');
              setChangestatusTab3.chnge_timestamp=cur_date_transform
                console.log("update change status ",setChangestatusTab3)
                this.transactionService.setImprovementChangeStatus(setChangestatusTab3).subscribe(chang_stat=>{
                  console.log("change status update",chang_stat);

                      if(chang_stat.toLowerCase() == 'success')
                      {
                        sessionStorage.setItem('ProposalChangeStatus',setChangestatusTab3.status);
                        this.existChangeStatusTab3='changed';

                        // alert("existChangeStatusTab3"+this.existChangeStatusTab3)

                        if(check==false)
                        {
                          this.routeTab4()
                        }
                      }
                    })
                  }

                  else
                  {
                    if(check==false)
                    {
                      this.routeTab4()
                    }

                  }
                }

                else
                {
                  if(check==false)
                  {
                    this.routeTab4()
                  }
                }
            }
            else
            {
        this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Height not saved! try again'});
      
              if(this.unitom == "E")
              {
                this.edit_measure_comp = 'lbs';
              }
              else
              {
                this.edit_measure_comp = 'kg';
              }

              // if(this.edit_measure_comp=="kg")
              // {



              //   if(this.shippingunittareweight!=null)
              //   {

              //     this.shippingunittareweight=Math.round((0.453592*this.shippingunittareweight)*1000)/1000;

              //   }
              //   if(this.shippingunitgrossweight!=null)
              //   {

              //     this.shippingunitgrossweight=Math.round((0.453592*this.shippingunitgrossweight)*1000)/1000;


              //   }
              //   if(this.containergrossweight!=null)
              //   {



              //     this.containergrossweight=Math.round((0.453592*this.containergrossweight)*1000)/1000;



              //   }
              // }
            }
           
      
    
      // else
      // {
      //   this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Check the Container Gross weight, Shipping unit weights and Try again'});
      // }
    })
  }

    routeTab4()
    {

        this.name=this.transactionService.getregionNameInSmallLetters();
        this.tab4='/'+this.name+'/packagingprocessdashboard/improvementproposalcreation/improvementpackproposaltabfour';
        this.tab4menuitem={label: 'Images/Photos & Comments', routerLink: this.tab4, icon: 'pi pi-images'};
        const currentab=3;
        console.log('in save of tab3 component',this.selectedpart_submitter_tab3.proposallastsaved);

        if(currentab>this.selectedpart_submitter_tab3.proposallastsaved)
        {
          this.selectedpart_submitter_tab3.proposallastsaved=3;
          this.store_primary_part_exist();
          console.log('After saving the tab','')
        }

        console.log('savedtab',this.packagingproposal.savedtab);
        console.log(this.partsandattri.savedtabfortesting);

        for(const i of this.packagingproposal.tabpackagingproposalmenuitems.values())
        {
            console.log(i);
            if(i.label!='Images/Photos & Comments')
            {
                this.flag=true;
            }
          else{
              this.flag=false;
              break;
          }
        }

        if(this.flag)
        {
            this.packagingproposal.tabpackagingproposalmenuitems.push(this.tab4menuitem);
        }

        this.packagingproposal.setActiveItemDynamically(this.tab4,this.tab4menuitem);



    }


    savetab3()
    {
      if(this.componentdetailsgrid.some(element=>element.is_compulsary=='Yes' && (element.qt_per_min_su==0 || element.comp_tar_wt==0)))
      {
        this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Enter Compulsory Component Details and then Save !!'});

      }
      else
      {
        console.log(this.comp_material_forpost);
        this.comp_material_forpost.mot=this.selected_mode_transport.transport_desc;
        this.comp_material_forpost.rel_level=this.selected_release_level.releaselevel;
        this.comp_material_forpost.id_proposal=this.id_proposal_compmat
        console.log(this.id_proposal_compmat)
        console.log(this.comp_material_forpost.id_proposal);

        // for now manually intitialize and assign calculated values
        // this.comp_material_forpost.cntr_shp_unit="Container";

        if(this.unitom == 'E')
        {
          this.edit_measure_comp = 'lbs';
        }
        else
        {
          this.edit_measure_comp = 'kg';
        }

        // if(this.edit_measure_comp=="kg")
        // {


        //   if(this.shippingunittareweight!=null)
        //   {

        //     this.shippingunittareweight=Math.round((2.20462*this.shippingunittareweight)*1000)/1000;

        //   }
        //   if(this.shippingunitgrossweight!=null)
        //   {

        //     this.shippingunitgrossweight=Math.round((2.20462*this.shippingunitgrossweight)*1000)/1000;


        //   }
        //   if(this.containergrossweight!=null)
        //   {



        //     this.containergrossweight=Math.round((2.20462*this.containergrossweight)*1000)/1000;



        //   }

        // }

        this.comp_material_forpost.cntr_shp_unit=this.cntrorshpunitradio;
        this.comp_material_forpost.cntr_gross_wt=this.containergrossweight_display;
        this.comp_material_forpost.shp_unt_gross_wt=this.shippingunitgrossweight_display;
        this.comp_material_forpost.shp_unt_tar_wt=this.shippingunittareweight_display;
       
        this.suGrossWtInValidation();
        this.suTareWtInValidation();
        this.contGrossWtInValidation();

        this.expAidsWgtValidation();
	 this.suExtHgtValidation();
      this.suNestedFldedHtValidation();


        console.log('Inside Save_close',this.sugrosswtInvalid,this.sucontarewtInvalid,this.cntrgrosswtInvalid,this.totl_wt_InValid);

        if(!this.sugrosswtInvalid  && !this.sucontarewtInvalid && !this.cntrgrosswtInvalid && !this.totl_wt_InValid && !this.suexthgtInvalid && !this.suNestedHtInValid)
        {

       this.transactionService.savetab3details(this.comp_material_forpost).subscribe(response=>
        {
            let msgs:any[];
            if(response=='Success')
            {
              this.packagingprocess.addtoastinpackagingprocess({severity:'success', summary: 'success', detail: 'Component and Material details saved successfully'});
              this.postTab2SaveAndClose();
            }
            else
            {
              this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Component and Material details is not saved! try again'});
              /*if(this.edit_measure_comp=="kg")
              {
          
          
                
                if(this.shippingunittareweight!=null)
                {
          
                  this.shippingunittareweight=Math.round((0.453592*this.shippingunittareweight)*1000)/1000;
          
                }
                if(this.shippingunitgrossweight!=null)
                {
                  
                  this.shippingunitgrossweight=Math.round((0.453592*this.shippingunitgrossweight)*1000)/1000;
                  
          
                }
                if(this.containergrossweight!=null)
                {
          
          
          
                  this.containergrossweight=Math.round((0.453592*this.containergrossweight)*1000)/1000;
          
          
                  
                }
              }*/
            }
         
      
        })
      }
      /*else
      {
        this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Check the Container Gross weight, Shipping unit weights and Try again'});
      }*/
      }
    }

    postTab2SaveAndClose()
    {
      this.Tab2DataForPost.shp_unt_ext_height=this.shippingUnitExteriorHeightDisplay;
      this.Tab2DataForPost.shp_unt_nestd_fld_height=this.shippinUnitNestedFoldedHeight;
      this.transactionService.savetab2details(this.Tab2DataForPost).subscribe(tab2_output=>{
      if(tab2_output.toLowerCase() == "success")
      {
        this.packagingproposal.addtoast({severity:'success', summary: 'success', detail: 'Height Updated Successfully'});
        var check=false;
        if(JSON.stringify(this.originalTab3Details) !== JSON.stringify(this.changedTab3Details))
        {
          //update change status
          
          //alert("change in data"+this.existChangeStatusTab3)
          if(this.existChangeStatusTab3 != null && this.existChangeStatusTab3.toLowerCase()=="unchanged")
          {
            var setChangestatusTab3:changeTrackingForImprovement={idproposal:0,chnge_timestamp:"",status:""}
            setChangestatusTab3.idproposal=this.selectedpart_submitter_tab3.proposalid
            setChangestatusTab3.status="changed";
            var cur_date=new Date();
            var cur_date_transform=this.date.transform(cur_date, 'yyyy-MM-dd HH:mm:ss');
            setChangestatusTab3.chnge_timestamp=cur_date_transform
              console.log("update change status ",setChangestatusTab3)
            this.transactionService.setImprovementChangeStatus(setChangestatusTab3).subscribe(chang_stat=>{
              console.log("change status update",chang_stat);

                      if(chang_stat.toLowerCase() == 'success')
                      {
                        sessionStorage.setItem('ProposalChangeStatus',setChangestatusTab3.status);
                        this.existChangeStatusTab3='changed';

                        // alert("existChangeStatusTab3"+this.existChangeStatusTab3)

                        if(check==false)
                        {
                          this.routeToSearchProposal()
                        }
                      }
                    })
                  }

                  else
                  {
                    if(check==false)
                    {
                      this.routeToSearchProposal()
                    }

                  }
                }

                else
                {
                  if(check==false)
                  {
                    this.routeToSearchProposal()
                  }
                }
            }
            else
            {
        this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Height not saved! try again'});
              
              if(this.unitom == "E")
              {
                this.edit_measure_comp = 'lbs';
              }
              else
              {
                this.edit_measure_comp = 'kg';
              }

              // if(this.edit_measure_comp=="kg")
              // {



              //   if(this.shippingunittareweight!=null)
              //   {

              //     this.shippingunittareweight=Math.round((0.453592*this.shippingunittareweight)*1000)/1000;

              //   }
              //   if(this.shippingunitgrossweight!=null)
              //   {

              //     this.shippingunitgrossweight=Math.round((0.453592*this.shippingunitgrossweight)*1000)/1000;


              //   }
              //   if(this.containergrossweight!=null)
              //   {



              //     this.containergrossweight=Math.round((0.453592*this.containergrossweight)*1000)/1000;



              //   }
              // }
            }
         
      
   
      // else
      // {
      //   this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Check the Container Gross weight, Shipping unit weights and Try again'});
      // }
    })
  }
  

    routeToSearchProposal()
    {
        this.name=this.transactionService.getregionNameInSmallLetters();
        const currentab=3;
        console.log('in save of tab3 component',this.selectedpart_submitter_tab3.proposallastsaved);

        if(currentab>this.selectedpart_submitter_tab3.proposallastsaved)
        {
          this.selectedpart_submitter_tab3.proposallastsaved=3;
          this.store_primary_part_exist();
          console.log('After saving the tab','')
        }

        console.log('savedtab',this.packagingproposal.savedtab);
        console.log(this.partsandattri.savedtabfortesting);

        this.router.navigate(['/'+this.name+'/packagingprocessdashboard/searchexistingproposal'])


      }
     
}

