/* tslint:disable */
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { from,Observable,of, throwError } from 'rxjs';
import { retry, catchError, tap, map } from 'rxjs/operators';
import { threadId } from 'worker_threads';
import {TransactionService} from '../loginpage/Transaction.service';


import { useraccessrole,subroles,user_roles } from '../loginpage/transactions';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    UserAccess:useraccessrole[]=[];
    cdsid='';
    data:any
    regioncode='';
    currentRegion='';
    present_func=false;
    present_roleid=false;
    present_userType=false;
    all_roles:any;


    constructor(
        private router: Router,
        private transactionService: TransactionService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
        const currentUser = this.transactionService.getcdsid();

        console.log('user',currentUser);
        console.log('state',state.url);
        const url_arr=state.url.split('/');
        console.log(url_arr[1]);
        this.currentRegion=this.transactionService.getRegionNameInBigLetters(url_arr[1]);
        console.log(this.currentRegion);
        this.data=route.data;
        this.cdsid=currentUser

        console.log(currentUser)
        const currentRegionCode=this.transactionService.getregioncode(this.currentRegion);
        this.regioncode=currentRegionCode
        const userrole=this.transactionService.getadmin();

         if(currentUser)
         {

                     return this.access();

     }



      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } })
        // this.router.navigate(['/exitpage']);
        return false




        // not logged in so redirect to login page with the return url

    }




// assuming if no roles, no func, no user type given, give access to all
    access():boolean{

    this.present_roleid=false;
    this.present_func=false;
    this.present_userType=false;
    const isFordUser=JSON.parse(sessionStorage.getItem('userType'));
    let userType='';
    if(isFordUser != null && isFordUser.toLowerCase() == 'yes')
    {
        userType='F'
    }
    else if(isFordUser != null && isFordUser.toLowerCase() == 'no')
    {
        userType='S'
    }
    else
    {
        userType='No Type Found';
    }

    if(userType != 'No Type Found')
    {
      if(this.data.userType)
      {
        const userType_arr=this.data.userType.split(',');
        const userTypeindex=userType_arr.findIndex(elrusrtyp=>elrusrtyp.toLowerCase() == userType.toLowerCase())
        if(userTypeindex != -1)
        {
            this.checkRoleFunc();
            this.present_userType=true;
        }
      }
      else
      {
        this.checkRoleFunc();
        this.present_userType=true;
      }
        // @ts-ignore
      console.log('data',this.data,userType_arr,this.present_roleid ,this.present_func ,this.present_userType)
      if(this.present_roleid && this.present_func && this.present_userType)
      {
          return true;
      }
      else
      {
          this.router.navigate(['/notaccesspage']);
          return false;
      }
    }
    else
    {
        console.log('No saved session Storage')
        this.router.navigate(['/notaccesspage']);
        return false;
    }

    }


    checkRoleFunc()
    {
        this.all_roles=this.transactionService.getroles(this.regioncode);
        console.log('all_roles',this.all_roles);
        if(this.all_roles!=null)
        {
            sessionStorage.removeItem('region');

            sessionStorage.setItem('region', JSON.stringify(this.currentRegion));
            if(this.data.roles && this.data.roles!='any')
            {
                console.log('any')
                const roles_ids_arr=this.data.roles.split(',');
                for(const il of roles_ids_arr)
                {
                    const roleindex=this.all_roles.findIndex(elrteid=>elrteid.roleid.toString()==il)
                    if(roleindex!=-1)
                    {
                        this.present_roleid=true;
                        // return true;
                        break;
                    }
                }
                this.present_func= this.ifFuncPresent();
            }
            else
            {
                this.present_roleid=true;
                this.present_func= this.ifFuncPresent();
            }
        }
        else
        {
            this.present_roleid=false;
            this.present_func= false;
        }
    }

    ifFuncPresent():boolean
    {
        let func_pres=false;
        if(this.data.funcid && this.data.funcid!='any')
        {
            for(let i=0;i<this.all_roles.length;i++)
            {
                const roles_arr=this.data.funcid.split(',');
                for(const ik of roles_arr)
                {
                const routeIndex=this.all_roles[i].roles.findIndex(elrte=>elrte.sysfunc_id.toString()==ik)
                    console.log('routeindex',routeIndex);
                    if(routeIndex!=-1)
                    {
                        func_pres=true;
                        // return true;
                    }
                }
            }
        }
        else
        {
            func_pres=true;
        }
        return func_pres;
    }

}


