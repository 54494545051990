/* tslint:disable */
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TransactionService } from '../loginpage/Transaction.service';
import { ActivePlants, pfepDataExportHeader, pfepDataExportOutput, programsbasedonplants, programsbasedonplantsforsearchprop } from '../loginpage/transactions';

import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Component({
  selector: 'app-pfepdataexportreport',
  templateUrl: './pfepdataexportreport.component.html',
  styleUrls: ['./pfepdataexportreport.component.css']
})
export class PfepdataexportreportComponent implements OnInit {

  region:string;
  regionCode:string;
  cdsid:string;

  plantcode='';
  selected_plant_code:ActivePlants={
    plant_name: '',
    cd_plant: '',
    plant_type_name: '',
    plantGSDBCode: '',



  }
  selected_plant_codes_list:ActivePlants[]=[];
  plantcodelist:ActivePlants[]=[];
  all_selected_on_plant_code=false;
  plantcodeselected=false;

  programsList:programsbasedonplants[]=[];
  programListalongwithPlants:programsbasedonplantsforsearchprop[]=[];
  selected_programs_list:programsbasedonplantsforsearchprop[]=[];
  programcodes='';
  all_selected_on_program=false;

  currentDate:Date;
  selectedStartDatePfepExportData:Date;

  startDatePfepExportDataString:string;
  plantCodeToAPI:string;
  programCodeToAPI:string;
  endDatePfepExportDataString:string;

  pfepDataExportReportList:pfepDataExportOutput[]=[];
  pfepDataExportHeaderList:pfepDataExportHeader[]=[];
  errorLoadingReport='';
  successMessage='';

  enableExportButton=true;

  loaderpfepdataexp=false;

  constructor(private transactionService:TransactionService,private date:DatePipe) { }

  ngOnInit(): void {

    // console.log(sessionStorage.getItem("testwork"));
    this.region=this.transactionService.getregion();
    this.regionCode=this.transactionService.getregioncode(this.region);
    this.cdsid=this.transactionService.getcdsid();

    this.currentDate=new Date();

    this.transactionService.getactiveplantslist(this.regionCode).subscribe(plantco=>{
      this.plantcodelist=[];
      if(plantco != null && plantco.length != 0)
      {
      const resultplant:ActivePlants[]=plantco;
      const plantcodetemp:ActivePlants[]=[];

      // plantcodetemp.push({'cd_plant':'0','plantGSDBCode':'ALL','plant_name':'All','plant_type_name':'All'});
      for(let h=0;h<resultplant.length;h++)
      {
        plantcodetemp.push({cd_plant:resultplant[h].cd_plant,plantGSDBCode:resultplant[h].plantGSDBCode,plant_name:resultplant[h].plant_name,plant_type_name:resultplant[h].plant_type_name})
      }
      this.plantcodelist=plantcodetemp
      this.selected_plant_codes_list=[];
      console.log('plantcodes from backend',this.plantcodelist);
    }
    })

  }


  onhide_plant_codes()
  {
    this.all_selected_on_plant_code=false;
    this.plantcode='';

    /* checking whether length of selected list is less than one */
    let plantslen=false;
    if(this.selected_plant_codes_list.length==this.plantcodelist.length-1)
    {
      plantslen=true;
    }

    for(let ptc=0;ptc<this.selected_plant_codes_list.length;ptc++)
    {
      this.plantcode+=this.selected_plant_codes_list[ptc].plantGSDBCode;
      if(ptc < this.selected_plant_codes_list.length-1)
      {
        this.plantcode+=',';
      }

      if(this.selected_plant_codes_list[ptc].plantGSDBCode=='ALL')
      {
        this.all_selected_on_plant_code=true;
        this.plantcode='all';
        break;
      }
    }

    /* If selectedvariables length is one less than total length but all is not selected
       which means except all remaining are selected so make
       all_selected_on_plant_code as true and make code  send all to backend */

    if(this.all_selected_on_plant_code==false && plantslen==true)
    {
      this.plantcode='all';
      this.all_selected_on_plant_code=true;
    }
    console.log('plant codes in Hide',this.plantcode,typeof(this.plantcode));

    if(this.plantcode.length>0)
    {
      this.plantcodeselected=true;
      this.loadprogramcodes();
      console.log('plantcode selected is true')
    }
    else
    {
      this.plantcodeselected=false;
      this.programsList=[];
      this.selected_programs_list=[];
      this.programListalongwithPlants=[];
      this.programcodes='';
    }
  }

  change_plant_codes()
  {
    let all_selected_on_plant_code=false;
    let lenlessthan1_plant_code=false;
    this.plantcode='';

    // When plants is changed programs variable shd be emptied and
    // when click outside after selecting plants,these variables will have loaded data
    this.programsList=[];
    this.selected_programs_list=[];
    this.programcodes='';
    this.programListalongwithPlants=[];


    if(this.selected_plant_codes_list.length==this.plantcodelist.length-1)
    {
      lenlessthan1_plant_code=true;
    }
    for(let j=0;j<this.selected_plant_codes_list.length;j++)
    {
      if(this.selected_plant_codes_list[j].plantGSDBCode=='ALL')
      {
        all_selected_on_plant_code=true;
      }
    }

    if(all_selected_on_plant_code==false && lenlessthan1_plant_code==true)
    {
      all_selected_on_plant_code=true;
    }

    if(all_selected_on_plant_code==true)
    {
      this.plantcode='all';
      this.selected_plant_codes_list=[];
      this.selected_plant_codes_list.push({cd_plant:'0',plantGSDBCode:'ALL',plant_name:'All',plant_type_name:'All'});
    }
  }

  loadprogramcodes()
  {
    const tempobj:programsbasedonplantsforsearchprop[]=[];

    // selected_region_from_list will have correct region even when the component is called from any parent
    // because selected_region_from_list will be set as current region when this component is called for copy container dialog

    const regval=this.transactionService.getregioncode(this.region);
    this.transactionService.getprogmlistbasedonplantgsdb(this.plantcode,regval).subscribe(progs=>{
      this.programsList=[];
      this.programListalongwithPlants=[];
      this.programsList=progs;
      if(this.programsList.length>0)
      {
        tempobj.push({cd_region:regval,plantgsdbcode:'',programname:'All',programnamewithplant:'All'});
      }
      for(let l=0;l<this.programsList.length;l++)
      {
        let programwasalreadypresent=false;
        if(tempobj.length!=0)
        {
          if(tempobj.some(o=>o.programname.toLowerCase() == this.programsList[l].programname.toLowerCase()))
          {
            const index=tempobj.findIndex(object=>{return object.programname.toLowerCase()==this.programsList[l].programname.toLowerCase()});
            const newvalue=tempobj[index].programnamewithplant.slice(0,tempobj[index].programnamewithplant.length-1) ;
            tempobj[index].programnamewithplant=newvalue+', '+this.programsList[l].plantgsdbcode+')';
            programwasalreadypresent=true;
          }
        }
        if(!programwasalreadypresent)
        {
          tempobj.push({programname:this.programsList[l].programname,programnamewithplant:this.programsList[l].programname+' ('+this.programsList[l].plantgsdbcode+')',plantgsdbcode:this.programsList[l].plantgsdbcode,cd_region:this.programsList[l].cd_region});
        }

      }
      this.programListalongwithPlants=JSON.parse(JSON.stringify(tempobj));

      console.log('programList',this.programsList)
      console.log('programListalongwithPlants',this.programListalongwithPlants);
    })
  }

  onhide_program_codes()
  {
    this.all_selected_on_program=false;
    this.programcodes='';

    /* checking whether length of selected list is less than one */
    let prglen=false;
    if(this.selected_programs_list.length==this.programListalongwithPlants.length-1)
    {
      prglen=true;
    }

    for(let prgi=0;prgi<this.selected_programs_list.length;prgi++)
    {
      this.programcodes+=this.selected_programs_list[prgi].programname;
      if(prgi < this.selected_programs_list.length-1)
      {
        this.programcodes+=',';
      }

      if(this.selected_programs_list[prgi].programname=='All')
      {
        this.all_selected_on_program=true;
        this.programcodes='all';
        break;
      }
    }

    /* If selectedvariables length is one less than total length but all is not selected
       which means except all remaining are selected so make
       all_selected_on_program as true and make code  send all to backend */

    if(this.all_selected_on_program==false && prglen==true)
    {
      this.programcodes='all';
      this.all_selected_on_program=true;
    }
    console.log('program codes in Hide',this.programcodes,typeof(this.programcodes));

  }

  change_prgm_codes()
  {
    let all_selected_on_program=false;
    let lenlessthan1_prg_code=false;
    this.programcodes='';

    if(this.selected_programs_list.length==this.programListalongwithPlants.length-1)
    {
      lenlessthan1_prg_code=true;
    }
    for(let j=0;j<this.selected_programs_list.length;j++)
    {
      if(this.selected_programs_list[j].programname=='All')
      {
        all_selected_on_program=true;
      }
    }

    if(all_selected_on_program==false && lenlessthan1_prg_code==true)
    {
      all_selected_on_program=true;
    }

    if(all_selected_on_program==true)
    {
      this.programcodes='all';
      this.selected_programs_list=[];
      const regval=this.transactionService.getregioncode(this.region);
      this.selected_programs_list.push({cd_region:regval,plantgsdbcode:'',programname:'All',programnamewithplant:'All'});
    }
  }

  plantCodeChanged()
  {
    this.plantcodeselected=true;
    this.programsList=[];
    this.selected_programs_list=[];
    this.programcodes='';
    this.programListalongwithPlants=[];

    this.plantcode=this.selected_plant_code.plantGSDBCode;
    this.loadprogramcodes();
  }

  generatePfepDataExportReport()
  {
    this.enableExportButton=false;
    this.loaderpfepdataexp=true;
    if(this.selectedStartDatePfepExportData != null)
    {
      this.startDatePfepExportDataString=this.date.transform(this.selectedStartDatePfepExportData,'yyyy-MM-dd HH:mm:ss')
      this.endDatePfepExportDataString=this.date.transform(new Date(),'yyyy-MM-dd HH:mm:ss')

    }
    else
    {
      this.startDatePfepExportDataString='all'
      this.endDatePfepExportDataString='all'
    }


    if(this.plantcode=='' )
    {
      this.plantCodeToAPI='all';
    }
    else
    {
      this.plantCodeToAPI=this.plantcode;
    }

    if(this.programcodes=='')
    {
      this.programCodeToAPI='all';
    }
    else
    {
      this.programCodeToAPI=this.programcodes;
    }
    console.log('before api call','startdate',this.startDatePfepExportDataString,'plantcode',this.plantCodeToAPI,'programcode',this.programCodeToAPI,'enddate',this.endDatePfepExportDataString)

    this.pfepDataExportReportList=[];
    this.pfepDataExportHeaderList=[];
    this.errorLoadingReport='';
    this.successMessage='Generating Report, Please Wait ...'

    this.transactionService.getPfepDataExportReport(this.plantCodeToAPI,this.programCodeToAPI,this.startDatePfepExportDataString,this.endDatePfepExportDataString,this.cdsid,this.regionCode).subscribe((pfepdataexprep)=>{

      console.log('getPfepDataExportReport api o/p',pfepdataexprep);
      if(pfepdataexprep != null && pfepdataexprep.length != 0)
      {
      this.pfepDataExportReportList=pfepdataexprep;

      this.transactionService.getpfepDataExportHeader().subscribe((pfepdataexphed)=>{

        console.log('getpfepDataExportHeader api o/p',pfepdataexphed);
        if(pfepdataexphed != null && pfepdataexphed.length != 0)
        {
          this.pfepDataExportHeaderList=pfepdataexphed;
          console.log('before exportPFEPExportDataToExcel fn call - ','header',this.pfepDataExportHeaderList,'data',this.pfepDataExportReportList);
          this.exportPFEPExportDataToExcel();
        }
        else
        {
          this.enableExportButton=true;
          console.log('PFEP Data Export - No header to load')
          this.successMessage='';
          this.errorLoadingReport='Error occured While Generating Report';
          this.loaderpfepdataexp=false;
        }
      },
        (error) => {                              // Error callback
          console.error('Error in Header API call - getpfepDataExportHeader',error)
          this.successMessage='';
          this.errorLoadingReport = 'Error Occured in Getting PFEP Export Data, Please Try Again';
          this.enableExportButton=true;
          this.loaderpfepdataexp=false;
        }
      )
      }
      else
      {
        this.enableExportButton=true;
        console.log('PFEP Data Export - No Data Found To Generate Report')
        this.successMessage='';
        this.errorLoadingReport='No Data Found To Generate Report'
        this.loaderpfepdataexp=false
      }

    },
        (error) => {                              // Error callback
          console.error('Error in PFEP Export Data API call - getPfepDataExportReport',error)
          this.successMessage='';
          this.errorLoadingReport = 'Error Occured in Getting PFEP Export Data, Please Try Again';
          this.enableExportButton=true;
          this.loaderpfepdataexp=false;
        }
    )
  }

  clearFilter()
  {
    this.selected_plant_codes_list=[];
    this.selected_plant_code={
      plant_name: '',
      cd_plant: '',
      plant_type_name: '',
      plantGSDBCode: '',



    }
    this.plantcode='';
    this.plantcodeselected=false;
    this.selected_programs_list=[];
    this.programcodes='';
    this.selectedStartDatePfepExportData=null;
    this.startDatePfepExportDataString='';
    this.endDatePfepExportDataString='';
    this.pfepDataExportReportList=[];
    this.pfepDataExportHeaderList=[];
    this.errorLoadingReport='';
    this.successMessage=''
    this.enableExportButton=true;
  }


  exportPFEPExportDataToExcel() {

    console.log('inside exportPFEPExportDataToExcel fn ',this.pfepDataExportHeaderList,this.pfepDataExportReportList)
    const title = 'PFEP Data Export Report';
    const header = this.pfepDataExportHeaderList;
    const data = this.pfepDataExportReportList;

    console.log(header,data);

    // Create a workbook with a worksheet
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('PFEP Export Data');

    // Adding Header Row
    const arr=[];
    header.forEach((d: any) => {
      // console.log(d);
      arr.push(
        {header:d.col_header,key:d.col_field_name,color:d.col_color}
        )
    })

    worksheet.columns = arr;
    console.log(arr);
   const headerRow = worksheet.getRow(1)
    console.log(headerRow)
    headerRow.eachCell((cell, number) => {
      // console.log(cell)
      const findindex=arr.findIndex(ele=>ele.header.trim() == cell.value.toString().trim())

      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: arr[findindex].color},
        bgColor: { argb: '' },
      };
      cell.font = {
        bold: true,
        color: { argb: '000000' },
        size: 12,
      };
      cell.alignment = { vertical: 'bottom', horizontal: 'left', textRotation: 90 , wrapText: true };
      cell.border = {

        left: {style:'thin'},
        bottom: {style:'thin'},
        right: {style:'thin'}
      };
    });

    // Adding Data
    data.forEach((d: any) => {
      worksheet.addRow(d);
    });
    console.log(worksheet)

    // Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, title + '.xlsx');

      this.successMessage='Report Generated Successfully !!!'
      this.enableExportButton=true;
      this.loaderpfepdataexp=false;
    });
  }

}
