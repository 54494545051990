/* tslint:disable */
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TransactionService } from '../loginpage/Transaction.service';
import { selec_prt_pro_details, fromdiplayvalues_all, RevisionObj } from '../loginpage/transactions';

@Component({
  selector: 'app-migrationapproval',
  templateUrl: './migrationapproval.component.html',
  styleUrls: ['./migrationapproval.component.css']
})
export class MigrationapprovalComponent implements OnInit {

  viewProposal=false;
  region:string;
  regionCode:string;
    // tslint:disable-next-line:max-line-length
  selectedSimpliedPartnoDetailsPrlimApr:selec_prt_pro_details={simplifiedpartno:'-',bompartmasterid:0,proposalid:0,plantgsdbcode:'',proposallastsaved:0,part_status:'',supplier_code:'',proposallastsaved_ee:0,is_ee_proposal:false,currentview_ee:false,from_draft:false,propsl_type:'',imp_part_status:''};
  proposalDetailsPrelim:fromdiplayvalues_all;
  revisionObj:RevisionObj[]=[];

  enabledetails=false;


  viewMigApr=false;



  currentRoleid:number;


  current_routeid=0



  constructor(private transactionService: TransactionService,private router:Router,private date:DatePipe,private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.region=this.transactionService.getregion();
this.regionCode=this.transactionService.getregioncode(this.region);


    this.currentRoleid=21;

    console.log(this.currentRoleid)


    if(this.region=='Asia Pacific & Africa')
    {
      sessionStorage.setItem('aprole', JSON.stringify(this.currentRoleid));
    }

    else if(this.region=='Europe')
    {
      sessionStorage.setItem('eurole', JSON.stringify(this.currentRoleid));
    }

    else if (this.region=='North America')

    {
      console.log(this.currentRoleid)
      sessionStorage.setItem('narole', JSON.stringify(this.currentRoleid));
    }

    else if(this.region=='South America')
    {
      sessionStorage.setItem('sarole', JSON.stringify(this.currentRoleid));
    }

    this.current_routeid=259;

    console.log(this.current_routeid)


    if(this.region=='Asia Pacific & Africa')
    {
      sessionStorage.setItem('aprouteid', JSON.stringify(this.current_routeid));
    }

    else if(this.region=='Europe')
    {
      sessionStorage.setItem('eurouteid', JSON.stringify(this.current_routeid));
    }

    else if (this.region=='North America')

    {

      sessionStorage.setItem('narouteid', JSON.stringify(this.current_routeid));
    }

    else if(this.region=='South America')
    {
      sessionStorage.setItem('sarouteid', JSON.stringify(this.current_routeid));
    }

    // this.transactionService.storepage(this.region,"/packagingprocess/packsupplieradmin/packsupplier");




   this.viewMigApr=true;






}

}
