/* tslint:disable */
import { Component, OnInit,OnDestroy } from '@angular/core';

import {TransactionService} from '../loginpage/Transaction.service';
import { Router } from '@angular/router';
//import {agvorddet,getlist,plantdetails,userdetails,plant,route,liststatus,role,userpostres,plantlist,agvdetails,agvmission,trackingdet, agvdetdash,agvdashgrid,agverrordetdash,agverrorcomments} from '../loginpage/transactions';
import { HttpClient } from '@angular/common/http';
import { Subscription, timer,interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {Directive, Input, HostListener} from "@angular/core";

import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'dashboardpage',
  templateUrl: './dashboardpage.component.html',
  styleUrls: ['./dashboardpage.component.scss']
})
export class DashboardpageComponent {


}
