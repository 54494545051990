import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HomeComponent} from './home.component';
import {HomeRoutingModule} from './home-routing.module';
import {ServiceResolve} from './resolves/service.resolve';
import {ApicallerService} from '../services/apicaller.service';

@NgModule({
	declarations: [
		HomeComponent
	],
	imports: [
		CommonModule,
		HomeRoutingModule
	],
	providers: [
		ServiceResolve,
		ApicallerService
	]
})
export class HomeModule {
}
