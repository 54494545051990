/* tslint:disable */
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import { PrgType,Prg,programadd,programeditsave,plantlist,GridPlantCode,populateGrid,PrgEditType,Prgpicklist,ParteffectiveDate,PartEffIntr,PTOtypeInr,S4PIntypeInr } from '../loginpage/transactions';
import {TransactionService} from '../loginpage/Transaction.service';
import { DatePipe } from '@angular/common';
import { ConfirmationService } from 'primeng/api';
import { clear, Console, timeStamp } from 'console';
import { WindowScrollController } from '@fullcalendar/core';
import { MessageService} from 'primeng/api';
import { ITS_JUST_ANGULAR } from '@angular/core/src/r3_symbols';
import { analyzeAndValidateNgModules, ThrowStmt } from '@angular/compiler';
import { Observable } from 'rxjs';
import { findIndex } from 'rxjs/operators';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Component({
  selector: 'app-prgadmin',
  templateUrl: './prgadmin.component.html',
  styleUrls: ['./prgadmin.component.css'],
  providers: [ConfirmationService,DatePipe,MessageService]
})


export class PrgadminComponent implements OnInit {
  prespace;
  prespace1;
  prespace2;
  prespace3;

  divED;
  prgtypButton;
  typelable;
  divenableadd:boolean;
  divenabledAddprg:boolean;
  divenabledPlntDtl:boolean;
  divenabledEditsaveDtl:boolean;
  divGridSave:boolean;
  btenabled:boolean;
  prgramtypedropdown:boolean;


  prgTypeDtls:PrgType[]=[];
  prgTypeEditDtls:PrgEditType[]=[];
  ProgList:Prg[]=[];
  ProgEditList:PrgEditType[]=[];



  PlantName
  PrgName
  selectedPrgType
  EditedPrgType

  PrgTypeTextArea:any;
  PrgSTDTextArea:any;
  PrgSTDADDTextArea:any;

  ContainerTexArea
  PlantActive;
  SavePlantActive;
  botton_variable;


  PADate:Date;
  savePADate:any;
  dialogsavePADate:any;
  TTDate:Date;
  saveTTDate:any;
  dialogsaveTTDate:any;
  PPDate:Date;
  savePPDate:any;
  dialogsavePPDate:any;
  Job1Date:Date;
  saveJob1Date:any;
  dialogsaveJob1Date:any;
  TTIPDate:Date;
  saveTTIPDate:any
  dialogsaveTTIPDate:any
  MPIPDate:Date;
  saveMPIPDate:any;
  PARTeffDate:any;
  dialogsaveMPIPDate:any;
  savePartEff:any;
  dialogsavePartEff:any;


  PrgNameInValid:boolean=false;
  PrgNameInValidReason:string=null;

  PADateInValid:boolean=false;
  PADateInValidReason:string=null;


  TTIpDateInvalid:boolean=false;
  TTIpDateInvalidReason:string=null;

  TTDateInvalid:boolean=false;
  TTDateInvalidReason:string=null;

  PPDateInvalid:boolean=false;
  PPDateInvalidReason:string=null;

  Mp1DateInvalid:boolean=false;
  Mp1DateInvalidReason:string=null;

  Mp2DateInvalid:boolean=false;
  Mp2DateInvalidReason:string=null;

  programActiveInvalid:boolean=false;
  programActiveInvalidReason:string=null;

  programTypeInvalid:boolean=false;
  programTypeInvalidReason:string=null;

  programTypeTextAreaInvalid:boolean=false;
  programTypeTextAreaInvalidReason:string=null;




// Grid table varibales
public cols:any[];
plants:plantlist[];
plantcodeList:GridPlantCode[]=[];
selectedPlantCode:GridPlantCode={'plantCode':'','plant_active_stat':''};


selectedPlantName:string;
programGrid:populateGrid[];
CurrentPlantCode:string;
addPlantDisplay:string;

PlantCode:string;


editDetailsCdPrgm:number;
editDetailsCdPlant:string;
editDetailsPlantName:string;
editDetailsPaDate:Date;
editDetailsPpDate:Date;
editDetailsProgramType:string;
editDetailsProgramName:string;
editDetailsTtDate:Date;
editDetailsJob1Date:Date;
editDetailsTtIpDate:Date;
editDetailsMp1IpDate:Date;
editDetailsStdCntrAssoProgram:string;
editDetailsProgramActiveStat:string;
editDetailsS4pInCode:string;
editDetailsPartEffInDate:string;
editDetailsPtoTypeCode:string;

editDetailsCommanAssocicated:string

saveEditDetailsCdPrgm;
saveEditDetailsCdPlant;
SaveEditDetailsPlantName;
SaveEditDetailsPaDate;
SaveEditDetailsPpDate;
SaveEditDetailsProgramType;
SaveEditDetailsProgramName;
SaveEditDetailsTtDate;
SaveEditDetailsJob1Date;
SaveEditDetailsTtIpDate;
SaveEditDetailsMp1IpDate;
SaveEditDetailsStdCntrAssoProgram;
SaveEditDetailsProgramActiveStat;
SaveEditDetailsS4pInCode;
SaveEditDetailsPartEffInDate;
SaveEditDetailsPtoTypeCode;

cdPrgm:number;

nowdate:Date;
pastdate:Date;
pastdateTest:any;

Test:string;

padate:any;
ttdate:any;
ppdate:any;
job1date:any;
ttipdate:any;
mpipdate:any;
programtype:any;
//plantactive:any;
partefectdate:any;

selectedProducts:Prgpicklist[];
//selectedPtoProducts:PTOTypeInrt[];
TestProducts:Prgpicklist[];
selectedPtoProducts:any;
selectedS4PProducts:any;
getSelectedS4PProducts:any;
getStdEditselectedProducts:any;
getAddEditselectedProducts:any;


partEffictiveProducts:any;
partEffictive_Save_Products:any;
getPartEffectexistValues:any;
getStdExistValues:any;
getPtoExistValues:any;
getS4pExistValues:any;
stdContExistValues:any;
stdContAddExistValues:any;
getSelectedSaveProducts:any;

ptoProducts:any;
ptoSaveProducts:any;

s4pProducts:any;
s4PSaveProducts:any;
stdSaveProducts:any;
stdProducts:any;

prgTypDates:any;
ptoTypeCode:any
s4pTypeCode:any;
stdBase:any;
stdSuffix:any;
stdDesc:any;

addStdBase:any;
addStdSuffix:any;
addStdDesc:any;


effctiveAddDialog:boolean;
effctiveSaveAddDialog:boolean;
ptoAddDialog:boolean;
ptoSaveAdddialog:boolean;
s4pAddDialog:boolean;
s4pSaveAddDialog:boolean;
hideDivPlantDetails:boolean;
enablePlantDetails:boolean;
standardContEditDialog:boolean;
standardContDialog:boolean;


dateValue:Date;

prgAdd:programadd={cd_prgm:0,cd_plant:"",plant_name:"",program_name:"", program_type:"", s4p_in_code:"", pto_type_code:"",std_cntr_asso_program:null, program_active_stat:"",part_eff_in_date:null,pa_date:null, pp_date:null, tt_date:null, job1_date:null,tt_ip_date:null, mp1_ip_date:null};
prgDtl:programadd={cd_prgm:null,cd_plant:"",plant_name:"",program_name:"", program_type:"",s4p_in_code:"", pto_type_code:"",std_cntr_asso_program:null, program_active_stat:"",part_eff_in_date:null,pa_date:null, pp_date:null, tt_date:null, job1_date:null,tt_ip_date:null, mp1_ip_date:null};
prgEdtSave:programeditsave={cd_prgm:null,cd_plant:"",plant_name:"",program_name:"", program_type:"", s4p_in_code:"", pto_type_code:"",std_cntr_asso_program:null, program_active_stat:"",part_eff_in_date:null,pa_date:null, pp_date:null, tt_date:null, job1_date:null,tt_ip_date:null, mp1_ip_date:null};

pickListPartEffectiveEdit: any[] = [];
pickListPartEffectiveAdd:  any[] = [];
pickListPtoEdit: any[] = [];
pickListS4PEdit: any[] = [];
stdContEdit:any[]=[];
stdContAdd:any[]=[];

//PartEffDate:ParteffectiveDate={PartInEffDate:null};
partEffDate:ParteffectiveDate[]=[];

//PartEff_save_Date:ParteffectiveDate={PartInEffDate:null};
//selcPartEffDate:PartEffIntr={PartInEffDate:null};
partEffSaveDate:ParteffectiveDate[]=[];

//PtoTypeSearch:PTOtypeInr={PTOType:""};
ptoTypeSearch:PTOtypeInr[]=[]
//PtoType_save_Search:PTOtypeInr={PTOType:""};
ptoTypeSaveSearch:PTOtypeInr[]=[];
tempSelectedPlantcode:string=" ";


//S4PTypeSerch:S4PIntypeInr={S4PCode:""};
S4PTypeSerch:S4PIntypeInr[]=[];

//S4P_Save_TypeSerch:S4PIntypeInr={S4PCode:""};
s4pSaveTypeSerch:S4PIntypeInr[]=[];
public Months;
public Years;

MothsDropdown:any;
YearDropdown:any;

selectedMonth:any
getSelectedMonth:any;
selectedYear:any
getSelectedYear:number;

set:any;
//obj:any;

ptoDesc;
savePtoDesc;
s4pDec:any
s4pSaveDec:any

partEffetive:any;


enableUserAccessForPrgAdmin:boolean=false;
prgOpenedForEdit:any;

enableViewOrDeleteAccess:boolean=true;


constructor(private date:DatePipe ,private transactionService: TransactionService,private messageService: MessageService,private confirmationService: ConfirmationService) {

  //this.PTOadddialog=true;

  this.Years= new Date();
  this.Months=new Date();

 // this.obj={};
 //this.PartEff_save_Date

// example this.arrayName = this.arrayName || [];

 this.partEffictiveProducts=[];
 this.partEffictive_Save_Products=[];

 this.ptoSaveProducts=[];
 this.ptoProducts=[];
 this.s4pProducts=[];
 this.s4PSaveProducts=[];
 this.stdSaveProducts=[];
 this.stdProducts=[];


 this.prgTypDates=[
  {PartInEffDate: '24-12-2022'},
  {PartInEffDate: '25-12-2023'},
  {PartInEffDate: '26-12-2024'},
  {PartInEffDate: '27-12-2025'},
 ],

this.MothsDropdown=[{Month:"Jan"},{Month:"Feb"},{Month:"Mar"},{Month:"Apr"},{Month:"May"},{Month:"Jun"},{Month:"Jul"},{Month:"Aug"},{Month:"Sep"},{Month:"Oct"},{Month:"Nov"},{Month:"Dec"}],
this.YearDropdown=[{Years:"2000"}, {Years:"2001"}, {Years:"2002"}, {Years:"2003"}, {Years:"2004"}, {Years:"2005"}, {Years:"2006"}, {Years:"2007"}, {Years:"2008"}, {Years:"2009"}, {Years:"2010"}, {Years:"2011"}, {Years:"2012"}, {Years:"2013"}, {Years:"2014"}, {Years:"2015"}, {Years:"2016"}, {Years:"2017"}, {Years:"2018"}, {Years:"2019"}, {Years:"2020"}, {Years:"2021"}, {Years:"2022"}, {Years:"2023"}, {Years:"2024"}, {Years:"2025"}, {Years:"2026"}, {Years:"2027"}, {Years:"2028"}, {Years:"2029"}, {Years:"2030"}, {Years:"2031"}, {Years:"2032"}, {Years:"2033"}, {Years:"2034"}, {Years:"2035"}, {Years:"2036"}, {Years:"2037"}, {Years:"2038"}, {Years:"2039"}, {Years:"2040"}, {Years:"2041"}, {Years:"2042"}, {Years:"2043"}, {Years:"2044"}, {Years:"2045"}, {Years:"2046"}, {Years:"2047"}, {Years:"2048"}, {Years:"2049"}, {Years:"2050"}],



this.prgTypeDtls=[
      {PrgTypecode: 'S4P-in-Code'},
      {PrgTypecode: 'PTO'},
      {PrgTypecode: 'Part Effective in Dates'}
      ],

  this.prgTypeEditDtls=[
    {PrgEditTypecode: 'S4P-in-Code'},
    {PrgEditTypecode: 'PTO'},
    {PrgEditTypecode: 'Part Effective in Dates'}
    ];

   }
   Prgtypedialog:boolean;


   current_region_prg:string;
   current_region_code_prg:string;


   prg_present_table:boolean=false;
   activestatusfilter:any;
   prg_grid_text:string="";


   prg_grid_view_text:string="";
   enable_add_button:boolean=true;


   current_routeid:number=0;

  ngOnInit(): void {


    this.prgramtypedropdown=false;
    this.effctiveAddDialog=false;
    this.effctiveSaveAddDialog=false;
    this.current_region_prg=this.transactionService.getregion();
    this.transactionService.storepage(this.current_region_prg,"packagingprocess/packadmin/prgadmin");
    this.current_region_code_prg=this.transactionService.getregioncode(this.current_region_prg);
    console.log(this.current_region_code_prg);

    this.prespace="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp";
    this.prespace2="&nbsp";
    this.prespace3="&nbsp;&nbsp;&nbsp;&nbsp";

    this.prespace1="-";
    this.divED='false';
    this.prgtypButton='false';
    this.loadPlant();
    this.getProgramType();
    this.loadPrgramType();

    this.divenableadd=true;
    this.divenabledAddprg=true;
    this.divenabledPlntDtl=true;
    this.divenabledEditsaveDtl=true;
    this.divenabledPlntDtl=true;
    this.divGridSave=true;
    this.enablePlantDetails=true;
    this.hideDivPlantDetails=true;
    this.btenabled=false;
    this.selectedProducts=[];
    this.selectedPtoProducts=[];
    this.selectedS4PProducts=[];
    this.s4pDec="";
    this.PrgTypeTextArea="";
    this.PrgSTDTextArea="";
    this.PrgSTDADDTextArea="";
    this.stdBase="";
    this.stdSuffix="";
    this.stdDesc="";
    this.addStdBase="";
    this.addStdSuffix="";
    this.addStdDesc="";


    this.prg_grid_view_text="Please Select Plant to see Program List"


    this.current_routeid=102;

        console.log(this.current_routeid)


        if(this.current_region_prg=="Asia Pacific & Africa")
        {
          sessionStorage.setItem('aprouteid', JSON.stringify(this.current_routeid));
        }

        else if(this.current_region_prg=="Europe")
        {
          sessionStorage.setItem('eurouteid', JSON.stringify(this.current_routeid));
        }

        else if (this.current_region_prg=="North America")

        {

          sessionStorage.setItem('narouteid', JSON.stringify(this.current_routeid));
        }

        else if(this.current_region_prg=="South America")
        {
          sessionStorage.setItem('sarouteid', JSON.stringify(this.current_routeid));
        }

  }

  validationInit()
  {
    this.PrgNameInValid=false;
    this.PrgNameInValidReason=null;
    this.PADateInValid=false;
    this.PADateInValidReason=null;
    this.TTIpDateInvalid=false;
    this.TTIpDateInvalidReason=null;
    this.TTDateInvalid=false;
    this.TTDateInvalidReason=null;
    this.PPDateInvalid=false;
    this.PPDateInvalidReason=null;
    this.Mp1DateInvalid=false;
    this.Mp1DateInvalidReason=null;
    this.Mp2DateInvalid=false;
    this.Mp2DateInvalidReason=null;
    this.programActiveInvalid=false;
    this.programActiveInvalidReason=null;
    this.programTypeInvalid=false;
    this.programTypeInvalidReason=null;
    this.programTypeTextAreaInvalid=false;
    this.programTypeTextAreaInvalidReason=null;
  }

  loadPrgramType()
  {
      this.prgTypeDtls=[
      {PrgTypecode: 'S4P-in-Code'},
      {PrgTypecode: 'PTO'},
      {PrgTypecode: 'Part Effective in Dates'}
      ]
  }

  loadPlant()
  {
  this.transactionService.getPlantListonRegion(this.current_region_code_prg).subscribe((Plt => {
  this.plants=Plt
  this.plantcodeList=[];
  console.log("getPlantListonRegion api o/p",this.plants);
  //console.log(this.plants)
    if(this.plants != null && this.plants.length != 0)
    {
          for(var i=0;i<this.plants.length;i++)
          {
          this.plantcodeList.push({plantCode:this.plants[i].plantGSDBCode,plant_active_stat:this.plants[i].plant_active_stat});

          }
          console.log("All plants list",this.plantcodeList);
          var activePlantcodeList=[];
          var allPlantcodeList=[];
          activePlantcodeList=this.plantcodeList.filter(ele=>(ele.plant_active_stat != null && ele.plant_active_stat.toLowerCase() == "yes"))
          allPlantcodeList=this.plantcodeList;
          this.plantcodeList=[];
          this.plantcodeList.push(...activePlantcodeList)
          //this.selectedPlantCode=this.plantcodeList[0];
          //this.loadPlantName();
          this.divenableadd=false;
          //this.enable_add_button=true;
          console.log("Active Plant code list",this.plantcodeList);
    }
  }
  ));
  }

 loadPlantName()
{
  //this.divenableadd=false
  this.divenabledAddprg=true;
  this.divenabledPlntDtl=true;
  this.divenabledEditsaveDtl=true;
  this.divGridSave=true;
  this.divenableadd=true;
  this.enable_add_button=false;

for(var j=0;j<this.plants.length;j++)
{
if(this.selectedPlantCode.plantCode == this.plants[j].plantGSDBCode)
{
this.selectedPlantName=this.plants[j].plant_name;
this.tempSelectedPlantcode=this.selectedPlantCode.plantCode;
}
}
this.populateGrid()
}


  populateGrid()
    {
      this.prg_grid_view_text="";
      console.log("Populate Grids is working");
     // this.PlantCode=this.selectedPlantCode;
      console.log("Plant code is ",this.selectedPlantCode.plantCode);
      console.log("Plant name is ", this.selectedPlantName);

      console.log(this.plantcodeList);
     this.transactionService.populateGrid(this.selectedPlantCode.plantCode).subscribe(grid => {
          this.programGrid=grid;
      console.log("This from server for Grid data",this.programGrid)


      if(this.programGrid.length!=0)
      {
        this.prg_present_table=true;
        this.activestatusfilter=[{label:'All',value:null},{label:'Active',value:"Yes"},{label:'In-Active',value:"No"}]

        this.prg_grid_text=""
      this.cols=[
          { field: 'program_name', header:'Program Name'},
          { field: 'pa_date', header:'PA Date'},
          { field: 'tt_date', header:'TT Date'},
          { field: 'pp_date', header:'PP Date'},
          { field: 'job1_date', header:'MP1 Date'},
          { field: 'program_type', header:'Program Type'},
          { field: 'program_active_stat', header:'Active State'},
         // { field:'cd_prgm'},
         // { field: 'plantGSDBCode'}



      ];
    }


    else{
      this.cols=[];
      this.prg_present_table=false;
      this.prg_grid_text="No Data Found"
    }
  });
  }


  exportExcel() {

   /*var header:any=[
      {header:"Program Name",field:"program_name"},
      {header:"PA Date",field:"pa_date"},
      {header:"TT Date",field:"tt_date"},
      {header:"PP Date",field:"pp_date"},
      {header:"MP1 Date",field:"job1_date"},
      {header:"Program Type",field:"program_type"},
      {header:"Active Status",field:"program_active_stat"},
    ]

    console.log(header,this.programGrid); // header and data

    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Program Admin');
  
    //Adding Header Row
    let arr=[];
    header.forEach((d: any) => {
      //console.log(d);
      arr.push(
        {header:d.header,key:d.field,color:"A9A9A9"}
        )
    })
  
    worksheet.columns = arr;
    console.log(arr);
   let headerRow = worksheet.getRow(1)
    console.log(headerRow)
    headerRow.eachCell((cell, number) => {
      //console.log(cell)
      var findindex=arr.findIndex(ele=>ele.header.trim() == cell.value.toString().trim())
  
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: arr[findindex].color},
        bgColor: { argb: '' },
      };
      cell.font = {
        bold: true,
        color: { argb: '000000' },
        size: 12,
      };
      cell.border = {
        
        left: {style:'thin'},
        bottom: {style:'thin'},
        right: {style:'thin'}
      };
    });  

    // Adding Data
    this.programGrid.forEach((d: any) => {
      worksheet.addRow(d);
    });
    console.log(worksheet)
  
    //Generate & Save Excel File
    var title="Program Admin"
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, title + '.xlsx');

    });*/

    // Below is excel format download using xlsx
    var programDataForExcelDownload=[];

   
    this.programGrid.forEach(d=>{
      programDataForExcelDownload.push((({cd_prgm,...o})=>o)(d))
    })

    console.log(programDataForExcelDownload)
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(programDataForExcelDownload,{header:[
          'program_name',
          'pa_date',
          'tt_date',
          'pp_date',
          'job1_date',
          'program_type',
          'program_active_stat'
        ]});

        worksheet.A1.v="Program Name";
        worksheet.B1.v="PA Date";
        worksheet.C1.v="TT Date";
        worksheet.D1.v="PP Date";
        worksheet.E1.v="Job1 Date";
        worksheet.F1.v="Program Type";
        worksheet.G1.v="Active Status";

        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "Program Table");
    });
}

saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
}

  isPlantActive()
  {
    if(this.selectedPlantCode.plant_active_stat!=null && this.selectedPlantCode.plant_active_stat.toLowerCase()=='yes')
    {
      return true;
    }
    else
    {
      return false;
    }
  }


  getPlantGrid(grid:any)
  {

   this.prgDtl.plant_name=grid.program_name;

   var prgName=grid.program_name;

   this.cdPrgm=grid.cd_prgm;


   // alert("Grid Edit Deatils working with PLANT "+this.prgdtl.plant_name);
   // alert("Grid Edit Deatils working with CD_PRGM "+this.cd_prgm)
    console.log("From Grid",grid);
    //alert(this.selectedPlantCode.plant_active_stat);

    if(this.selectedPlantCode.plant_active_stat!=null && this.selectedPlantCode.plant_active_stat.toLowerCase()=='yes')
    {
      if(grid.program_active_stat!=null && grid.program_active_stat.toLowerCase()=='yes')
      {
        this.enableViewOrDeleteAccess=true;
      }
      else
      {
        this.enableViewOrDeleteAccess=false;
      }
    }
    else
    {
      this.enableViewOrDeleteAccess=false;
    }

    this.prgramDetails(this.selectedPlantCode.plantCode,this.cdPrgm)
    this.divGridSave=true;
   // this.divenabledPlntDtl=false;
    this.divenableadd=false;
    this.divenabledAddprg=true;
   // this.divGridSave=true;
   //this.editPrgramType();
  }

  divAddPlant()  {
   this.addFormClear();
   this.divenableadd=false;
   this.divenabledAddprg=false;
   this.divenabledPlntDtl=true;
   this.validationInit();

  }
  savePlantActive(){

    this.SavePlantActive;
   // alert(" Program Active Selection is "+this.editDetailsProgramActiveStat);

  }
  addPlantActive(){
    this.PlantActive;
   // alert(" Program Active Selection is "+this.PlantActive);


  }
  getPlantActive()
  {
   // alert(" Program Active Selection is "+this.editDetailsProgramActiveStat);
  }


  prgramDetails(PlantCode:string,Cd_prg:number){

    if ( this.divenabledEditsaveDtl)
    {    //  alert(" current save window "+this.divenabledEditsaveDtl);
        }else{
      //alert("current save window "+this.divenabledEditsaveDtl);
     this.divenabledEditsaveDtl=true;
    }


    this.transactionService.getprgEditDlt(PlantCode,Cd_prg).subscribe(prgedit => {
     console.log("getprgEditDlt Received from server ",prgedit);

this.prgDtl=prgedit;

this.editDetailsCdPrgm=this.prgDtl.cd_prgm
this.editDetailsCdPlant=this.prgDtl.cd_plant
this.editDetailsPlantName=this.prgDtl.plant_name;
this.editDetailsPaDate=new Date(this.prgDtl.pa_date);
//this.padate=this.prgdtl.pa_date;
//this.padate=this.date.transform(this.prgdtl.pa_date, 'yyyy-MM-dd');
this.padate=this.date.transform(this.prgDtl.pa_date, 'MM-dd-yyyy');

this.editDetailsPpDate=new Date(this.prgDtl.pp_date);
this.ppdate=this.date.transform(this.prgDtl.pp_date, 'MM-dd-yyyy');
this.editDetailsProgramType=this.prgDtl.program_type;
this.typelable=this.prgDtl.program_type+'\xa0'+'codes associated with program';

this.editDetailsProgramName=this.prgDtl.program_name;
this.editDetailsTtDate=new Date(this.prgDtl.tt_date);
this.ttdate=this.date.transform(this.prgDtl.tt_date, 'MM-dd-yyyy');
this.editDetailsJob1Date=new Date(this.prgDtl.job1_date);
this.job1date=this.date.transform(this.prgDtl.job1_date, 'MM-dd-yyyy');
this.editDetailsTtIpDate=new Date(this.prgDtl.tt_ip_date);
this.ttipdate=this.date.transform(this.prgDtl.tt_ip_date, 'MM-dd-yyyy');
this.editDetailsMp1IpDate=new Date(this.prgDtl.mp1_ip_date);
this.mpipdate=this.date.transform(this.prgDtl.mp1_ip_date, 'MM-dd-yyyy');
this.editDetailsStdCntrAssoProgram=this.prgDtl.std_cntr_asso_program;
this.editDetailsProgramActiveStat=this.prgDtl.program_active_stat;
this.editDetailsS4pInCode=this.prgDtl.s4p_in_code;

//this.partefectdate=this.date.transform(this.prgdtl.part_eff_in_date, 'MM-dd-yyyy');

this.editDetailsPartEffInDate=this.prgDtl.part_eff_in_date;
this.editDetailsPtoTypeCode=this.prgDtl.pto_type_code;

if(this.prgDtl.program_type==="PTO")
{
  this.editDetailsCommanAssocicated=this.editDetailsPtoTypeCode;
  console.log("pto details "+this.editDetailsPtoTypeCode);

  this.botton_variable= 'Edit'+'\xa0'+this.prgDtl.program_type+'\xa0';
  this.prgtypButton="yes";
  this.divED="true";
  this.PrgTypeTextArea=this.editDetailsPtoTypeCode;
}
if(this.prgDtl.program_type==="Part Effective in Dates")
{
  this.editDetailsCommanAssocicated=this.editDetailsPartEffInDate;
  console.log("Effective details "+this.editDetailsPartEffInDate);
  this.botton_variable= 'Edit'+'\xa0'+this.prgDtl.program_type+'\xa0';
  this.PrgTypeTextArea=this.editDetailsPartEffInDate;
  this.divED="true";
  this.prgtypButton="yes";
}
if(this.prgDtl.program_type==="S4P-in-Code")
{
  this.editDetailsCommanAssocicated=this.editDetailsS4pInCode;
  console.log("S4p details "+this.editDetailsS4pInCode);
  this.botton_variable= 'Edit'+'\xa0'+this.prgDtl.program_type+'\xa0';
  this.PrgTypeTextArea=this.editDetailsS4pInCode;
  this.divED="true";
  this.prgtypButton="yes";
}
this.PrgSTDTextArea=this.editDetailsStdCntrAssoProgram;
console.log("STD existing values "+this.PrgSTDTextArea)
console.log("common print details is "+this.editDetailsCommanAssocicated)


this.divenabledPlntDtl=false;

/*if(this.EditDetails_std_cntr_asso_program!=null && this.EditDetails_std_cntr_asso_program==="")
{
  this.PrgSTDTextArea=this.EditDetails_std_cntr_asso_program;

  console.log("Prgoram STD existing values ",this.PrgSTDTextArea);
} */

});
//this.divenableadd=true;
    }
    editProgramDetailsCancel()
  {
    this.divenabledPlntDtl=true;
    this.divenableadd=true;

  }

  viewOrDeleteAccess(plantcode:any,prgcode:any)
  {
    this.enableUserAccessForPrgAdmin=true;
    //alert(plantcode+prgcode)
    if(plantcode!=null && prgcode!= null)
    {
      this.prgOpenedForEdit={"plantcode":plantcode,"plantname":"","programcode":prgcode,"coscode":"","modify":true,"calledFrom":"programadmin"};
    }
  }

  closeViewOrDeleteAccessDialog()
  {
    this.enableUserAccessForPrgAdmin=false;
    this.current_routeid=2;

    if(this.current_region_prg=="Asia Pacific & Africa")
    {
      sessionStorage.setItem('aprouteid', JSON.stringify(this.current_routeid));
    }
    else if(this.current_region_prg=="Europe")
    {
      sessionStorage.setItem('eurouteid', JSON.stringify(this.current_routeid));
    }
    else if (this.current_region_prg=="North America")
    {
      sessionStorage.setItem('narouteid', JSON.stringify(this.current_routeid));
    }

    else if(this.current_region_prg=="South America")
    {
      sessionStorage.setItem('sarouteid', JSON.stringify(this.current_routeid));
    }

  }

  programPlantDetails()
  {
    this.divenabledPlntDtl=true;
    this.divenabledEditsaveDtl=false;
    this.validationInit();
  }

  programEditSave()
  {


    this.editValidationForPrgName();
  this.editValidationForPADate();
  this.editValidationForTTIpDate();
  this.editValidationForTTDate();
  this.editValidationForPPDate();
  this.editValidationForMp1Date();
  this.editValidationForMp2Date();
  //this.addValidationForProgramType();
  this.editValidationForProgramTypeTextArea();
  this.editValidationForProgramActive();


this.prgEdtSave.cd_plant=this.editDetailsCdPlant;
this.prgEdtSave.cd_prgm=this.editDetailsCdPrgm;
this.prgEdtSave.plant_name=this.editDetailsPlantName
this.savePADate=this.date.transform(this.editDetailsPaDate,'yyyy-MM-dd');
this.prgEdtSave.pa_date=this.savePADate;
this.savePPDate=this.date.transform(this.editDetailsPpDate,'yyyy-MM-dd');
this.prgEdtSave.pp_date=this.savePPDate;
//this.prgedtsave.program_type=this.EditDetails_program_type; this.selectedPrgType.PrgTypecode
//this.prgedtsave.program_type=this.selectedPrgType.PrgTypecode;
this.prgEdtSave.program_type=this.editDetailsProgramType;

if(this.editDetailsProgramType==="PTO")
{
  this.prgEdtSave.pto_type_code=this.PrgTypeTextArea.toString();

}
if(this.editDetailsProgramType==="Part Effective in Dates")
{
  this.prgEdtSave.part_eff_in_date=this.PrgTypeTextArea.toString();
}
if(this.editDetailsProgramType==="S4P-in-Code")
{
  this.prgEdtSave.s4p_in_code=this.PrgTypeTextArea.toString();
}


this.prgEdtSave.program_name=this.editDetailsProgramName;
this.saveTTDate=this.date.transform(this.editDetailsTtDate,'yyyy-MM-dd');
this.prgEdtSave.tt_date=this.saveTTDate;
this.saveJob1Date=this.date.transform(this.editDetailsJob1Date,'yyyy-MM-dd');
this.prgEdtSave.job1_date=this.saveJob1Date;
this.saveTTIPDate=this.date.transform(this.editDetailsTtIpDate,'yyyy-MM-dd');
this.prgEdtSave.tt_ip_date=this.saveTTIPDate;
this.saveMPIPDate=this.date.transform(this.editDetailsMp1IpDate,'yyyy-MM-dd');
this.prgEdtSave.mp1_ip_date=this.saveMPIPDate;
//this.prgedtsave.std_cntr_asso_program=this.EditDetails_std_cntr_asso_program;
if(this.PrgSTDTextArea!=null)
{
  this.prgEdtSave.std_cntr_asso_program=this.PrgSTDTextArea.toString();
}

this.prgEdtSave.program_active_stat=this.editDetailsProgramActiveStat;
//this.prgedtsave.s4p_in_code=this.EditDetails_s4p_in_code;
//this.savePartEff=this.date.transform(this.EditDetails_part_eff_in_date,'yyyy-MM-dd');

this.savePartEff=this.editDetailsPartEffInDate;
//this.prgedtsave.part_eff_in_date=this.savePartEff;
//this.prgedtsave.pto_type_code=this.EditDetails_pto_type_code;
console.log("Program_Edit_save() saving sending to server ",this.prgEdtSave);

if(!this.PrgNameInValid && !this.PADateInValid && !this.TTIpDateInvalid && !this.TTDateInvalid &&
  !this.PPDateInvalid && !this.Mp1DateInvalid && !this.Mp2DateInvalid && !this.programTypeInvalid &&
  !this.programTypeTextAreaInvalid)
{
  this.transactionService.editsaveprogram(this.prgEdtSave).subscribe((output => {
    let res=output;
    if(res == "Success"){
    this.messageService.add({severity:'success', summary:'Success', detail:'Program Edited Successfully'});
      this.populateGrid();
      this.prgramDetails(this.prgEdtSave.cd_plant,this.prgEdtSave.cd_prgm);
      this.divenabledEditsaveDtl=true;
      this.validationInit();

    }
    else{
      this.messageService.add({severity:'error', summary: 'Error', detail: 'Program Not Edited,Please Try Again'});
      //this.addformclear();
      this.populateGrid();
      this.validationInit();
    }

  }));
}

 // window.location.reload();
 this.prgtypButton==='yes'
// this.divenabledEditsaveDtl=true;  Hided Program Details
//this.PickListPartEffectiveEdit=null;
this.Months=null;
this.Years=null;
this.selectedMonth=null;
this.selectedYear=null
this.getSelectedMonth=null;
this.getSelectedYear=null;

if(this.editDetailsProgramType==="Part Effective in Dates"){
while(this.pickListPartEffectiveEdit.length){
  this.pickListPartEffectiveEdit.pop();
  }  }

  if(this.editDetailsProgramType==="PTO")  {
  while(this.pickListPtoEdit.length){
    this.pickListPtoEdit.pop();}}

}
  programEditCancel()
  {
    //this.divenabledEditsaveDtl=true;
    this.validationInit();
    this.prgramDetails(this.editDetailsCdPlant,this.editDetailsCdPrgm);
    this.divenabledPlntDtl=false;
    this.divenableadd=false;

if(this.editDetailsProgramType==="Part Effective in Dates"){
this.PrgTypeTextArea=this.editDetailsPartEffInDate;
    while(this.pickListPartEffectiveEdit.length){
      this.pickListPartEffectiveEdit.pop();
      }
this.Months=null;
this.Years=null;
this.selectedMonth=null;
this.selectedYear=null
this.getSelectedMonth=null;
this.getSelectedYear=null;  }

if(this.editDetailsProgramType==="PTO")
{
  this.PrgTypeTextArea=this.editDetailsPtoTypeCode;
    while(this.pickListPtoEdit.length){
      this.pickListPtoEdit.pop();
      }

}

if(this.editDetailsProgramType==="S4P-in-Code")
{
  this.PrgTypeTextArea=this.pickListS4PEdit;
  while(this.pickListS4PEdit.length){
    this.pickListS4PEdit.pop();
    }

}


}
 getProgramType() {
    this.ProgList=[];

    for(var i=0;i<this.prgTypeDtls.length;i++)
    {
        this.ProgList.push({PrgTypecode:this.prgTypeDtls[i].PrgTypecode});

    }

    console.log(" programList ",this.ProgList);

 }
editProgramType()
{
  this.ProgEditList=[];
  for(var i=0;i<this.prgTypeEditDtls.length;i++)
  {
    this.ProgEditList.push({PrgEditTypecode:this.prgTypeEditDtls[i].PrgEditTypecode})
  }

}

 changeProgramType()
 {
   console.log(" selected program type is working ", this.selectedPrgType.PrgTypecode);
   this.divED='true';
   this.prgtypButton='yes';
   this.btenabled=true;

   if('Part Effective in Dates'==='Part Effective in Dates')
   {
    this.botton_variable= 'Select'+'\xa0'+this.selectedPrgType.PrgTypecode+'\xa0';
   }
   else{
    this.botton_variable= 'Select'+'\xa0'+this.selectedPrgType.PrgTypecode+'\xa0'+'codes';

   }
   this.typelable=this.selectedPrgType.PrgTypecode +'\xa0'+'codes associated with program';

 }
 PrgDialogEnable()
 {
  if(this.selectedPrgType.PrgTypecode==='Part Effective in Dates')
  {
    this.effctiveAddDialog=true;
    this.PrgTypeTextArea=this.partEffictiveProducts;
  }

  if(this.selectedPrgType.PrgTypecode==='PTO')
  {
    this.ptoAddDialog=true;
    this.PrgTypeTextArea=this.ptoProducts;
  }
  if(this.selectedPrgType.PrgTypecode==='S4P-in-Code')
  {
    this.s4pAddDialog=true;
    this.PrgTypeTextArea=this.s4pProducts;
  }
 }

 changeEditprogramType()
 {
  // alert(" edit dialog works now");
  }


  addValidationForPrgName()
  {
    if(this.PrgName==null || this.PrgName.trim()=="")
    {
      this.PrgNameInValid=true;
      this.PrgNameInValidReason="Required"
    }
    else
    {
      this.PrgNameInValid=false;
      this.PrgNameInValidReason=null
    }
  }


  addValidationForPADate()
  {
    if(this.PADate==null)
    {
      this.PADateInValid=true;
      this.PADateInValidReason="Required"
    }
    else
    {
      this.PADateInValid=false;
      this.PADateInValidReason=null
    }
  }

  addValidationForTTIpDate()
  {
    if(this.TTIPDate==null)
    {
      this.TTIpDateInvalid=true;
      this.TTIpDateInvalidReason="Required"
    }
    else if(this.TTIPDate < this.PADate)
    {
      this.TTIpDateInvalid=true;
      this.TTIpDateInvalidReason="TT IP Date should be greater than PA Date"
    }
    else
    {
      this.TTIpDateInvalid=false;
      this.TTIpDateInvalidReason=null
    }
  }

  addValidationForTTDate()
  {
    if(this.TTDate==null)
    {
      this.TTDateInvalid=true;
      this.TTDateInvalidReason="Required"
    }
    else if(this.TTDate < this.TTIPDate || this.TTDate< this.PADate)
    {
      this.TTDateInvalid=true;
      this.TTDateInvalidReason="TT Date should be greater than PA Date and TT IP Date"
    }
    else
    {
      this.TTDateInvalid=false;
      this.TTDateInvalidReason=null
    }
  }

  addValidationForPPDate()
  {
    if(this.PPDate==null)
    {
      this.PPDateInvalid=true;
      this.PPDateInvalidReason="Required"
    }
    else if(this.PPDate < this.TTDate || this.PPDate<this.TTIPDate ||
           this.PPDate<this.PADate)
    {
      this.PPDateInvalid=true;
      this.PPDateInvalidReason="PP Date should be greater than PA Date, TT IP Date and TT Date"
    }
    else
    {
      this.PPDateInvalid=false;
      this.PPDateInvalidReason=null
    }
  }

  addValidationForMp1Date()
  {
    if(this.Job1Date==null)
    {
      this.Mp1DateInvalid=true;
      this.Mp1DateInvalidReason="Required"
    }
    else if(this.Job1Date < this.PPDate || this.Job1Date < this.TTDate || this.Job1Date<this.TTIPDate ||
      this.Job1Date<this.PADate)
    {
      this.Mp1DateInvalid=true;
      this.Mp1DateInvalidReason="MP1 Date should be greater than PA Date, TT IP Date, TT Date and PP Date"
    }
    else
    {
      this.Mp1DateInvalid=false;
      this.Mp1DateInvalidReason=null
    }
  }


  addValidationForMp2Date()
  {
    if(this.MPIPDate==null)
    {
      this.Mp2DateInvalid=true;
      this.Mp2DateInvalidReason="Required"
    }
    else if(this.MPIPDate < this.Job1Date || this.MPIPDate < this.PPDate || this.MPIPDate < this.TTDate || this.MPIPDate<this.TTIPDate ||
      this.MPIPDate<this.PADate)
    {
      this.Mp2DateInvalid=true;
      this.Mp2DateInvalidReason="MP2 Date should be greater than PA Date, TT IP Date, TT Date, PP Date, MP1 Date"
    }
    else
    {
      this.Mp2DateInvalid=false;
      this.Mp2DateInvalidReason=null
    }
  }

  addValidationForProgramActive()
  {
    //alert(this.PlantActive);
    if(this.PlantActive==null)
    {
      this.programActiveInvalid=true;
      this.programActiveInvalidReason="Required"
    }
    else
    {
      this.programActiveInvalid=false;
      this.programActiveInvalidReason=null
    }
  }

  addValidationForProgramType()
  {
    if(this.selectedPrgType==null)
    {
      this.programTypeInvalid=true;
      this.programTypeInvalidReason="Required"
    }
    else
    {
      this.programTypeInvalid=false;
      this.programTypeInvalidReason=null;
    }
  }



  addValidationForProgramTypeTextArea()
  {
    if(this.selectedPrgType!=null && this.selectedPrgType.PrgTypecode=='Part Effective In Dates')
    {
      if(this.PrgTypeTextArea==null || this.PrgTypeTextArea=='')
      {
        this.programTypeTextAreaInvalid=true;
        this.programTypeTextAreaInvalidReason="Required";
      }
      else
      {
        this.programTypeTextAreaInvalid=false;
        this.programTypeTextAreaInvalidReason=null;
      }

    }

    else if(this.selectedPrgType!=null && this.selectedPrgType.PrgTypecode==='PTO')
    {
      if(this.PrgTypeTextArea==null || this.PrgTypeTextArea=='')
      {
      this.programTypeTextAreaInvalid=true;
      this.programTypeTextAreaInvalidReason="Required";
      }
      else
      {
        this.programTypeTextAreaInvalid=false;
        this.programTypeTextAreaInvalidReason=null;
      }
    }

    else if(this.selectedPrgType!=null && this.selectedPrgType.PrgTypecode==='S4P-in-Code')
    {
      if(this.PrgTypeTextArea==null || this.PrgTypeTextArea=='')
      {
      this.programTypeTextAreaInvalid=true;
      this.programTypeTextAreaInvalidReason="Required";
      }
      else
      {
        this.programTypeTextAreaInvalid=false;
        this.programTypeTextAreaInvalidReason=null;
      }
    }
    else
    {
      this.programTypeTextAreaInvalid=false;
      this.programTypeTextAreaInvalidReason=null;
    }
  }

 ProgramAdd()
 {

  this.addValidationForPrgName();
  this.addValidationForPADate();
  this.addValidationForTTIpDate();
  this.addValidationForTTDate();
  this.addValidationForPPDate();
  this.addValidationForMp1Date();
  this.addValidationForMp2Date();
  this.addValidationForProgramType();
  this.addValidationForProgramTypeTextArea();
  this.addValidationForProgramActive();




  console.log("Add Prgram Method is calling now for registration");
   //this.prgadd.cd_plant="this.CurrentPlantCode"



   console.log("Prg add details ",this.prgAdd);

   if(!this.PrgNameInValid && !this.PADateInValid && !this.TTIpDateInvalid && !this.TTDateInvalid &&
      !this.PPDateInvalid && !this.Mp1DateInvalid && !this.Mp2DateInvalid && !this.programTypeInvalid &&
      !this.programTypeTextAreaInvalid && !this.programActiveInvalid)
      {




            //this.prgadd.cd_plant="this.CurrentPlantCode"

       this.prgAdd.cd_plant=this.selectedPlantCode.plantCode
       // this.prgadd.cd_prgm=this.PrgName;
        //this.prgadd.plant_name=this.selectedPlantName;
        this.prgAdd.plant_name=this.selectedPlantName;
        this.prgAdd.program_name=this.PrgName;

        //this.PADate=this.date.transform(this.PADate, 'yyyy-MM-dd');
        //this.prgAdd.pa_date=this.PADate;
        this.savePADate=this.date.transform(this.PADate, 'yyyy-MM-dd')
        this.prgAdd.pa_date=this.savePADate;

        //this.TTDate=this.date.transform(this.TTDate, 'yyyy-MM-dd');
        //this.prgAdd.tt_date=this.TTDate;
        this.saveTTDate=this.date.transform(this.TTDate, 'yyyy-MM-dd');
        this.prgAdd.tt_date=this.saveTTDate;

        //this.PPDate=this.date.transform(this.PPDate, 'yyyy-MM-dd');
        //this.prgAdd.pp_date=this.PPDate;
        this.savePPDate=this.date.transform(this.PPDate, 'yyyy-MM-dd');
        this.prgAdd.pp_date=this.savePPDate;

        //this.Job1Date=this.date.transform(this.Job1Date, 'yyyy-MM-dd');
        //this.prgAdd.job1_date=this.Job1Date;
        this.saveJob1Date=this.date.transform(this.Job1Date, 'yyyy-MM-dd');
        this.prgAdd.job1_date=this.saveJob1Date;

        //this.TTIPDate=this.date.transform(this.TTIPDate, 'yyyy-MM-dd');
        //this.prgAdd.tt_ip_date=this.TTIPDate;
        this.saveTTIPDate=this.date.transform(this.TTIPDate, 'yyyy-MM-dd');
        this.prgAdd.tt_ip_date=this.saveTTIPDate;

        //this.MPIPDate=this.date.transform(this.MPIPDate, 'yyyy-MM-dd');
        //this.prgAdd.mp1_ip_date=this.MPIPDate;
        this.saveMPIPDate=this.date.transform(this.MPIPDate, 'yyyy-MM-dd');
        this.prgAdd.mp1_ip_date=this.saveMPIPDate;

        this.prgAdd.program_active_stat=this.PlantActive;
        if(this.selectedPrgType!=null)
        {
         this.prgAdd.program_type=this.selectedPrgType.PrgTypecode;
        }

        if(this.PrgSTDADDTextArea!=null)
        {
         this.prgAdd.std_cntr_asso_program=this.PrgSTDADDTextArea.toString();
        }

        //this.prgadd.part_eff_in_date=this.PrgTypeTextArea;
        if(this.selectedPrgType.PrgTypecode==='Part Effective in Dates')
        {
         console.log("PrgTypeTextArea  --- ", this.PrgTypeTextArea.length);
         this.PARTeffDate=this.PrgTypeTextArea;
        // this.PARTeffDate=this.date.transform(this.PrgTypeTextArea, 'yyyy-MM-dd');
         this.prgAdd.part_eff_in_date=this.PARTeffDate.toString();
         console.log("PartEffective add ",this.PARTeffDate);
        }
        if(this.selectedPrgType.PrgTypecode==='PTO')
        {
          this.ptoTypeCode=this.PrgTypeTextArea;
          this.prgAdd.pto_type_code=this.ptoTypeCode.toString();
          console.log("PTO add ",this.ptoTypeCode);
        }
        if(this.selectedPrgType.PrgTypecode==='S4P-in-Code')
        {
          this.s4pTypeCode=this.PrgTypeTextArea;
          this.prgAdd.s4p_in_code=this.s4pTypeCode.toString();
          console.log("PTO add ",this.ptoTypeCode);
        }


        console.log("Prg add details ",this.prgAdd);



      this.transactionService.registerprogram(this.prgAdd).subscribe((output => {
        let res=output;

      console.log(res);

      if(res == "Success"){
        this.messageService.add({severity:'success', summary:'Success', detail:'Program Added Successfully'});
      // alert("Program added successfully");


      this.populateGrid();
      this.addFormClear();
      this.validationInit();

      this.prgTypeDtls=[
        {PrgTypecode: 'S4P-in-Code'},
        {PrgTypecode: 'PTO'},
        {PrgTypecode: 'Part Effective in Dates'}
        ]




      }
        else{
          this.messageService.add({severity:'error', summary: 'Error', detail: 'Program Not Added,Please Try Again'});
          //alert(" Prgram not added, pls contact admin");
          this.PrgSTDADDTextArea=null;
          this.validationInit();
        }
      }
      ));
    }
    //window.location.reload();
 }
 cancelAdd()
 {
  this.divenableadd=true;
  this.divenabledAddprg=true;
  this.PrgSTDADDTextArea=null;
  this.PrgTypeTextArea=null;
  this.validationInit();
 }

 editValidationForPrgName()
  {
    if(this.editDetailsProgramName==null || this.editDetailsProgramName.trim()=="")
    {
      this.PrgNameInValid=true;
      this.PrgNameInValidReason="Required"
    }
    else
    {
      this.PrgNameInValid=false;
      this.PrgNameInValidReason=null
    }
  }


  editValidationForPADate()
  {
    if(this.editDetailsPaDate==null)
    {
      this.PADateInValid=true;
      this.PADateInValidReason="Required"
    }
    else
    {
      this.PADateInValid=false;
      this.PADateInValidReason=null
    }
  }

  editValidationForTTIpDate()
  {
    if(this.editDetailsTtIpDate==null)
    {
      this.TTIpDateInvalid=true;
      this.TTIpDateInvalidReason="Required"
    }
    else if(this.editDetailsTtIpDate < this.editDetailsPaDate)
    {
      this.TTIpDateInvalid=true;
      this.TTIpDateInvalidReason="TT IP Date should be greater than PA Date"
    }
    else
    {
      this.TTIpDateInvalid=false;
      this.TTIpDateInvalidReason=null
    }
  }

  editValidationForTTDate()
  {
    if(this.editDetailsTtDate==null)
    {
      this.TTDateInvalid=true;
      this.TTDateInvalidReason="Required"
    }
    else if(this.editDetailsTtDate < this.editDetailsTtIpDate || this.editDetailsTtDate< this.editDetailsTtIpDate)
    {
      this.TTDateInvalid=true;
      this.TTDateInvalidReason="TT Date should be greater than PA Date and TT IP Date"
    }
    else
    {
      this.TTDateInvalid=false;
      this.TTDateInvalidReason=null
    }
  }

  editValidationForPPDate()
  {
    if(this.editDetailsPpDate==null)
    {
      this.PPDateInvalid=true;
      this.PPDateInvalidReason="Required"
    }
    else if(this.editDetailsPpDate < this.editDetailsTtDate || this.editDetailsPpDate<this.editDetailsTtIpDate || this.editDetailsPpDate<this.editDetailsPaDate)
    {
      this.PPDateInvalid=true;
      this.PPDateInvalidReason="PP Date should be greater than PA Date, TT IP Date and TT Date"
    }
    else
    {
      this.PPDateInvalid=false;
      this.PPDateInvalidReason=null
    }
  }

  editValidationForMp1Date()
  {
    if(this.editDetailsJob1Date==null)
    {
      this.Mp1DateInvalid=true;
      this.Mp1DateInvalidReason="Required"
    }
    else if(this.editDetailsJob1Date < this.editDetailsPpDate || this.editDetailsJob1Date <this.editDetailsPaDate || this.editDetailsJob1Date <this.editDetailsTtIpDate || this.editDetailsJob1Date<this.editDetailsTtDate )
    {
      this.Mp1DateInvalid=true;
      this.Mp1DateInvalidReason="MP1 Date should be greater than PA Date,TT IP Date, TT Date and PP Date"
    }
    else
    {
      this.Mp1DateInvalid=false;
      this.Mp1DateInvalidReason=null
    }
  }


  editValidationForMp2Date()
  {
    if(this.editDetailsMp1IpDate==null)
    {
      this.Mp2DateInvalid=true;
      this.Mp2DateInvalidReason="Required"
    }
    else if(this.editDetailsMp1IpDate < this.editDetailsJob1Date || this.editDetailsMp1IpDate < this.editDetailsPaDate ||
            this.editDetailsMp1IpDate<this.editDetailsTtIpDate || this.editDetailsMp1IpDate < this.editDetailsTtDate ||
            this.editDetailsMp1IpDate < this.editDetailsPpDate)
    {
      this.Mp2DateInvalid=true;
      this.Mp2DateInvalidReason="MP2 Date should be greater than PA Date, TT IP Date, TT Date, PP Date and MP1 Date"
    }
    else
    {
      this.Mp2DateInvalid=false;
      this.Mp2DateInvalidReason=null
    }
  }

  editValidationForProgramActive()
  {
    //alert(this.PlantActive);
    if(this.editDetailsProgramActiveStat==null)
    {
      this.programActiveInvalid=true;
      this.programActiveInvalidReason="Required"
    }
    else
    {
      this.programActiveInvalid=false;
      this.programActiveInvalidReason=null
    }
  }

  editValidationForProgramType()
  {
    if(this.editDetailsProgramType==null)
    {
      this.programTypeInvalid=true;
      this.programTypeInvalidReason="Required"
    }
    else
    {
      this.programTypeInvalid=false;
      this.programTypeInvalidReason=null;
    }
  }



  editValidationForProgramTypeTextArea()
  {
    if(this.editDetailsProgramType!=null && this.editDetailsProgramType=='Part Effective In Dates')
    {
      if(this.PrgTypeTextArea==null || this.PrgTypeTextArea=='')
      {
        this.programTypeTextAreaInvalid=true;
        this.programTypeTextAreaInvalidReason="Required";
      }
      else
      {
        this.programTypeTextAreaInvalid=false;
        this.programTypeTextAreaInvalidReason=null;
      }

    }

    else if(this.editDetailsProgramType!=null && this.editDetailsProgramType==='PTO')
    {
      if(this.PrgTypeTextArea==null || this.PrgTypeTextArea=='')
      {
      this.programTypeTextAreaInvalid=true;
      this.programTypeTextAreaInvalidReason="Required";
      }
      else
      {
        this.programTypeTextAreaInvalid=false;
        this.programTypeTextAreaInvalidReason=null;
      }
    }

    else if(this.editDetailsProgramType!=null && this.editDetailsProgramType==='S4P-in-Code')
    {
      //alert(this.PrgTypeTextArea)
      if(this.PrgTypeTextArea==null || this.PrgTypeTextArea=='')
      {
      this.programTypeTextAreaInvalid=true;
      this.programTypeTextAreaInvalidReason="Required";
      }
      else
      {
        this.programTypeTextAreaInvalid=false;
        this.programTypeTextAreaInvalidReason=null;
      }
    }
    else
    {
      this.programTypeTextAreaInvalid=false;
      this.programTypeTextAreaInvalidReason=null;
    }
  }

 editDilogPlantDetails(row:any)
 {
   this.divenabledAddprg=true;
   this.divenabledPlntDtl=true;
   this.divenabledEditsaveDtl=true;
  var Plant_code=this.selectedPlantCode.plantCode;
  var cdprgm=row.cd_prgm;

  this.transactionService.getprgEditDlt(Plant_code,cdprgm).subscribe(prgedit => {
  console.log("getprgEditDlt Received from server ",prgedit);

this.prgDtl=prgedit;


this.editDetailsCdPrgm=this.prgDtl.cd_prgm
this.editDetailsCdPlant=this.prgDtl.cd_plant
this.editDetailsPlantName=this.prgDtl.plant_name;
this.editDetailsPaDate=new Date(this.prgDtl.pa_date);
//this.padate=this.prgdtl.pa_date;
//this.padate=this.date.transform(this.prgdtl.pa_date, 'yyyy-MM-dd');
this.padate=this.date.transform(this.prgDtl.pa_date, 'MM-dd-yyyy');

this.editDetailsPpDate=new Date(this.prgDtl.pp_date);
this.ppdate=this.date.transform(this.prgDtl.pp_date, 'MM-dd-yyyy');
this.editDetailsProgramType=this.prgDtl.program_type;
this.typelable=this.prgDtl.program_type+'\xa0'+'codes associated with program';

this.editDetailsProgramName=this.prgDtl.program_name;
this.editDetailsTtDate=new Date(this.prgDtl.tt_date);
this.ttdate=this.date.transform(this.prgDtl.tt_date, 'MM-dd-yyyy');
this.editDetailsJob1Date=new Date(this.prgDtl.job1_date);
this.job1date=this.date.transform(this.prgDtl.job1_date, 'MM-dd-yyyy');
this.editDetailsTtIpDate=new Date(this.prgDtl.tt_ip_date);
this.ttipdate=this.date.transform(this.prgDtl.tt_ip_date, 'MM-dd-yyyy');
this.editDetailsMp1IpDate=new Date(this.prgDtl.mp1_ip_date);
this.mpipdate=this.date.transform(this.prgDtl.mp1_ip_date, 'MM-dd-yyyy');
this.editDetailsStdCntrAssoProgram=this.prgDtl.std_cntr_asso_program;
this.editDetailsProgramActiveStat=this.prgDtl.program_active_stat;
this.editDetailsS4pInCode=this.prgDtl.s4p_in_code;

//this.partefectdate=this.date.transform(this.prgdtl.part_eff_in_date, 'MM-dd-yyyy');

this.editDetailsPartEffInDate=this.prgDtl.part_eff_in_date;
this.editDetailsPtoTypeCode=this.prgDtl.pto_type_code;

if(this.prgDtl.program_type==="PTO")
{
  this.editDetailsCommanAssocicated=this.editDetailsPtoTypeCode;
  console.log("pto details "+this.editDetailsPtoTypeCode);

  this.botton_variable= 'Edit'+'\xa0'+this.prgDtl.program_type+'\xa0';
  this.prgtypButton="yes";
  this.divED="true";
  this.PrgTypeTextArea=this.editDetailsPtoTypeCode;
}
if(this.prgDtl.program_type==="Part Effective in Dates")
{
  this.editDetailsCommanAssocicated=this.editDetailsPartEffInDate;
  console.log("Effective details "+this.editDetailsPartEffInDate);
  this.botton_variable= 'Edit'+'\xa0'+this.prgDtl.program_type+'\xa0';
  this.PrgTypeTextArea=this.editDetailsPartEffInDate;
  this.divED="true";
  this.prgtypButton="yes";
}
if(this.prgDtl.program_type==="S4P-in-Code")
{
  this.editDetailsCommanAssocicated=this.editDetailsS4pInCode;
  console.log("S4p details "+this.editDetailsS4pInCode);
  this.botton_variable= 'Edit'+'\xa0'+this.prgDtl.program_type+'\xa0';
  this.PrgTypeTextArea=this.editDetailsS4pInCode;
  this.divED="true";
  this.prgtypButton="yes";
}
this.PrgSTDTextArea=this.editDetailsStdCntrAssoProgram;
console.log("common print details is "+this.editDetailsCommanAssocicated)
this.divenableadd=false;
this.divGridSave=false;

});

  //this.divenabledPlntDtl=true;
  this.divenableadd=false;

}
 editDialogCancel()
  {
   this.divGridSave=true;
   this.divenabledPlntDtl=true;
   this.divenableadd=true;
   this.validationInit();

   if(this.editDetailsProgramType==="Part Effective in Dates"){
    this.PrgTypeTextArea=this.editDetailsPartEffInDate;
        while(this.pickListPartEffectiveEdit.length){
          this.pickListPartEffectiveEdit.pop();
          }
        }
          if(this.editDetailsProgramType==="PTO")
          {
            this.PrgTypeTextArea=this.editDetailsPtoTypeCode;
              while(this.pickListPtoEdit.length){
                this.pickListPtoEdit.pop();
                }

          }


if(this.editDetailsProgramType==="S4P-in-Code")
{
  this.PrgTypeTextArea=this.pickListS4PEdit;
  while(this.pickListS4PEdit.length){
    this.pickListS4PEdit.pop();
    }

}


          this.Months=null;
          this.Years=null;
          this.selectedMonth=null;
          this.selectedYear=null
          this.getSelectedMonth=null;
          this.getSelectedYear=null;


  }

 Edit_Grid_Cancel()
 {
   this.divenableadd=true;
   //this.divenabledEditsaveDtl=false;

 }

 addFormClear()
{
  this.PlantName=null;
   this.PrgName=null;
   this.PADate=null;
   this.PADate=null;
   this.TTDate=null;
   this.TTDate=null;
   this.PPDate=null;
   this.PPDate=null;
   this.Job1Date=null;
   this.Job1Date=null;
   this.TTIPDate=null;
   this.MPIPDate=null;
   this.PlantActive=null;
   this.PARTeffDate=null
   this.selectedPrgType=null;
   this.ptoTypeCode=null;
  this.PrgTypeTextArea=null;
  this.PrgSTDADDTextArea=null;
  this.selectedPrgType=null;
  this.prgtypButton=null;

  this.prgAdd.part_eff_in_date=null;
  this.prgAdd.pto_type_code=null;


}

partEffecitveSearch()
{
  this.transactionService.getEffectiveDateServ(this.selectedPlantCode.plantCode,this.getSelectedMonth,this.getSelectedYear).subscribe(effDates => {
    console.log("getprgEditDlt Received from server ",effDates);
    //this.PartEffDate=effDates;
    this.pickListPartEffectiveAdd=effDates;

    if(effDates.length===0)
    {  this.messageService.add({severity:'success', summary:'Info Message', detail:'No Search Result'});  }
    else{this.messageService.add({severity:'success', summary:'Info Message', detail:'Search Completed'}); }

    console.log("Part Effective ADD dates recevied",this.partEffDate );

    const obj={};

    for (let i = 0, len = this.pickListPartEffectiveAdd.length; i < len; i++) {
      obj[this.pickListPartEffectiveAdd[i]['PartInEffDate']] = this.pickListPartEffectiveAdd[i];
      console.log("Part Effective ADD "+[this.pickListPartEffectiveAdd[i]['PartInEffDate']]);}

        while(this.pickListPartEffectiveAdd.length){
          this.pickListPartEffectiveAdd.pop();}

          for (const key in obj) {
            this.pickListPartEffectiveAdd.push(obj[key]);
          }

          this.pickListPartEffectiveAdd.forEach(i => {
            this.partEffictiveProducts.forEach(j => {
            if(j.PartInEffDate===i.PartInEffDate){
              console.log("Duplicate is "+i.PartInEffDate)
              this.pickListPartEffectiveAdd=this.pickListPartEffectiveAdd.filter(item => {  return item.PartInEffDate !== i.PartInEffDate.toString()})
            }
            });
        });




  }); // End Promise

 // this.parteffetive= this.PartEffDate;
}
partEffctiveCheck()
{
  //alert(this.PartEffictiveProducts);
  this.selectedProducts=this.partEffictiveProducts;
  console.log("this.selectedProducts ",this.selectedProducts);
  var names=this.selectedProducts.map(function(selectedProducts){
    return selectedProducts['PartInEffDate']
  })

 // console.log("selected list ",this.selectedProducts);
 // console.log("Names   -- >", names);
  this.PrgTypeTextArea=names;
  this.partEffDate=null;
  this.getSelectedMonth=null;
  this.getSelectedYear=null;
  this.selectedMonth=null;
  this.selectedYear=null;
  //this.selectedProducts=null;


  while(this.partEffictiveProducts.length){
  this.partEffictiveProducts.pop();  }

  while(this.pickListPartEffectiveAdd.length){
    this.pickListPartEffectiveAdd.pop();  }

  //this.Products.pop();
  //console.log("After clear list ",this.selectedProducts);
  this.effctiveAddDialog=false;
}
partEffctiveCheckCancel()
{
   this.selectedMonth=null;
  this.selectedYear=null;


 // this.selectedPtoProducts=null;
  this.effctiveAddDialog=false;
  this.partEffDate=null;
  while(this.partEffictiveProducts.length){
    this.partEffictiveProducts.pop(); }

}
loadMonthName()
{
  this.getSelectedMonth=this.selectedMonth.Month;

}
loadYearName()
{
  this.getSelectedYear=this.selectedYear.Years

}
ptoSearch()
{
  this.transactionService.getPtoSerDecs(this.selectedPlantCode.plantCode,this.ptoDesc).subscribe(Ptoserch => {
    //console.log("PTO serach Received from server ",Ptoserch);
    this.ptoTypeSearch=Ptoserch;
    if(Ptoserch.length===0)
    {  //alert("Therer is no search result for this program type");
      this.messageService.add({severity:'success', summary:'Info Message', detail:'No Search Result'});
    }
  });
}
ptoCheck()
{
//this.PTOadddialog=true;

 this.selectedPtoProducts=this.ptoProducts;


 var pto=this.selectedPtoProducts.map(function(selectedPtoProducts){
    return selectedPtoProducts['PTOType'];
  });
  this.PrgTypeTextArea=pto;
  this.ptoDesc=null;
  this.ptoTypeSearch=null;
  while(this.ptoProducts.length){
    this.ptoProducts.pop();
  }
  this.ptoAddDialog=false;
}
ptoCancel()
{
  this.ptoAddDialog=false;
  this.ptoDesc=null;
  while(this.ptoProducts.length){
    this.ptoProducts.pop();
  }
 this.ptoTypeSearch=null;
}
s4pInSearch()
{

  this.transactionService.getS4pSearchDecs(this.selectedPlantCode.plantCode,this.s4pDec).subscribe(S4Pserch => {
       this.S4PTypeSerch=S4Pserch;
    if(S4Pserch.length===0)
    {   this.messageService.add({severity:'success', summary:'Info Message', detail:'No Search Result'});   }
  });

}
s4pCheck()
{
 this.selectedS4PProducts=this.s4pProducts;
 var s4p=this.selectedS4PProducts.map(function(selectedS4PProducts){
    return selectedS4PProducts['S4PCode'];
  });
  this.PrgTypeTextArea=s4p;
  this.s4pDec=null;
  this.S4PTypeSerch=null;
  while(this.s4pProducts.length){
    this.s4pProducts.pop();
  }
  this.s4pAddDialog=false;

}
s4pCancel()
{
  this.S4PTypeSerch=null
  this.s4pAddDialog=false;
  this.s4pDec=null;
  while(this.s4pProducts.length){
    this.s4pProducts.pop();
  }
}

// Mahe Check
editProgramTypeDialog()
{
  if(this.editDetailsProgramType==="Part Effective in Dates")
  {
 var partEff;
 this.effctiveSaveAddDialog=true;
 //this.getparteffectexistvalues=this.EditDetails_part_eff_in_date;
 this.getPartEffectexistValues=this.PrgTypeTextArea;
 //alert(" exsting value is "+this.getparteffectexistvalues);
 //if( this.EditDetails_part_eff_in_date!=null){
  if( this.PrgTypeTextArea!=null && this.PrgTypeTextArea.length>0 ){
  partEff= this.getPartEffectexistValues.toString().split(',').map(function(item) {
 return {PartInEffDate: item};});
 this.partEffictive_Save_Products=partEff;
   }else{}
 //this.PartEffictive_Save_Products=partEff;
 console.log("Mahe checking",this.partEffictive_Save_Products);
}

if (this.editDetailsProgramType==="PTO")
{
  var ptodes
  this.ptoSaveAdddialog=true;
  //this.getptoexistvalues=this.EditDetails_pto_type_code;
  this.getPtoExistValues=this.PrgTypeTextArea;
  //alert(" exsting value is "+this.getPtoExistValues.length);
  if( this.PrgTypeTextArea!=null && this.PrgTypeTextArea.length>0){
    ptodes=this.getPtoExistValues.toString().split(',').map(function(item) {
      return {PTOType: item};});
      this.ptoSaveProducts=ptodes;
  }else{
   }
   //this.Pto_save_Products=ptodes;
}

if(this.editDetailsProgramType==="S4P-in-Code")
{
  var s4pdes
  this.s4pSaveAddDialog=true;
 // this.gets4pexistvales=this.EditDetails_s4p_in_code;
  this.getS4pExistValues=this.PrgTypeTextArea;
  //alert(" exsting value is "+this.gets4pexistvales);
  if( this.PrgTypeTextArea!=null && this.PrgTypeTextArea.length>0){
  s4pdes=this.getS4pExistValues.toString().split(',').map(function(item) {
    return {S4PCode: item};});
    this.s4PSaveProducts=s4pdes;
  }else{}
   // this.S4P_Save_Products=s4pdes;
}

}
partEffecitveSaveSearch(){


  this.transactionService.getEffectiveDateServ(this.editDetailsCdPlant,this.getSelectedMonth,this.getSelectedYear).subscribe(effDates => {
    console.log("getprgEditDlt Received from server ",effDates);
    this.pickListPartEffectiveEdit=effDates
    console.log("Mahe check source after added ",this.pickListPartEffectiveEdit);
    if(effDates.length===0)
    {   this.messageService.add({severity:'success', summary:'Info Message', detail:'No Search Result'});   }
    else{this.messageService.add({severity:'success',  summary:'Info Message', detail:'Search Completed'}); }
    const obj={}

  /*  for (let i = 0, len = effDates.length; i < len; i++) {
      obj[effDates[i]['PartInEffDate']] = effDates[i];
      console.log("Testing "+[effDates[i]['PartInEffDate']]);
    } */



    for (let i = 0, len = this.pickListPartEffectiveEdit.length; i < len; i++) {
      obj[this.pickListPartEffectiveEdit[i]['PartInEffDate']] = this.pickListPartEffectiveEdit[i];
      console.log("Testing "+[this.pickListPartEffectiveEdit[i]['PartInEffDate']]);}

        while(this.pickListPartEffectiveEdit.length){
          this.pickListPartEffectiveEdit.pop();}


console.log("Pick list length "+this.pickListPartEffectiveEdit.length);

for (const key in obj) {
  this.pickListPartEffectiveEdit.push(obj[key]);
}

/*
console.log("Final Display to picklist length "+this.PickListPartEffectiveEdit.length);

console.log("Final Display to picklist length "+this.PartEffictive_Save_Products.length);

let PickListPartEffectiveEdit_len=this.PickListPartEffectiveEdit.length;
let PartEffictive_Save_Products_len=this.PartEffictive_Save_Products.length;
  //for (let i = 0, len = this.PickListPartEffectiveEdit.length; i < len; i++) {
  // for (let j = 0, len = this.PartEffictive_Save_Products.length; j < len; j++) {

    for (var i=0; i< PickListPartEffectiveEdit_len; i++) {
      for(var j=0; j<PartEffictive_Save_Products_len; j++){

              //if([this.PartEffictive_Save_Products[i]['PartInEffDate'].trim()].toString()===Test2.toString())
              if([this.PartEffictive_Save_Products[j]['PartInEffDate'].trim()].toString()===[this.PickListPartEffectiveEdit[i]['PartInEffDate'].trim()].toString())
              {
               // console.log("Duplicte is "+[this.PartEffictive_Save_Products[i]['PartInEffDate']].toString())
               console.log("Duplicte is "+[this.PickListPartEffectiveEdit[i]['PartInEffDate']].toString());
              this.Test=this.PickListPartEffectiveEdit[i]['PartInEffDate'].toString();
              alert(this.Test);
             this.PickListPartEffectiveEdit=this.PickListPartEffectiveEdit.filter(item => {  return item.PartInEffDate !== this.Test.toString()})
               console.log("PickList Lenth",this.PickListPartEffectiveEdit.length);
               console.log("PickList removed ",this.PickListPartEffectiveEdit);

              }

          } //inner for loop

        }// first for loop

              */







        this.pickListPartEffectiveEdit.forEach(i => {
          this.partEffictive_Save_Products.forEach(j => {
          if(j.PartInEffDate===i.PartInEffDate){
            console.log("Duplicate is "+i.PartInEffDate)
            this.pickListPartEffectiveEdit=this.pickListPartEffectiveEdit.filter(item => {  return item.PartInEffDate !== i.PartInEffDate.toString()})
          }
          });
      });
     /* if(this.PickListPartEffectiveEdit.length===0 && this.PartEffictive_Save_Products.length >=1)
      {
       this.messageService.add({severity:'success', summary:'Info Message', detail:'Record Already Exist'});
      } */
  });



  }
  partEffctiveSaveCheck()
  {
    if(this.partEffictive_Save_Products!=null)
    {
    var partEff=this.partEffictive_Save_Products.map(function(PartEffictive_Save_Products){
      return PartEffictive_Save_Products['PartInEffDate'];
    });}
    this.PrgTypeTextArea=partEff;
   // this.PartEff_save_Date=null;
   //this.PickListPartEffectiveEdit=null;
   this.effctiveSaveAddDialog=false;
   this.selectedMonth=null;
   this.selectedYear=null
   this.getSelectedMonth=null;
   this.getSelectedYear=null;


   while(this.pickListPartEffectiveEdit.length){
    this.pickListPartEffectiveEdit.pop();}



  }
  partEffctiveSaveCheckCancel()
  {
    this.partEffSaveDate=null;
    this.effctiveSaveAddDialog=false;
    this.selectedMonth=null;
    this.selectedYear=null;
    this.getSelectedMonth=null;
    this.getSelectedYear=null;
    while(this.pickListPartEffectiveEdit.length){
    this.pickListPartEffectiveEdit.pop();}

  }
  ptoSaveSearch()
  {

  this.transactionService.getPtoSerDecs(this.editDetailsCdPlant,this.savePtoDesc).subscribe(Ptoserch => {

    if(Ptoserch.length===0)
    {   this.messageService.add({severity:'success', summary:'Info Message', detail:'No Search Result'});   }
    else{this.messageService.add({severity:'success', summary:'Info Message', detail:'Search Completed'}); }

    this.pickListPtoEdit=Ptoserch;
    console.log("PTO Edit recevied from server ",Ptoserch);


  console.log("Now PickListPtoEdit",this.pickListPtoEdit);

  const obj={}
  for (let i = 0, len = this.pickListPtoEdit.length; i < len; i++) {
    obj[this.pickListPtoEdit[i]['PTOType']] = this.pickListPtoEdit[i];
    console.log(" PTO Testing "+[this.pickListPtoEdit[i]['PTOType']]);}

    while(this.pickListPtoEdit.length){
      this.pickListPtoEdit.pop();}
  console.log("Pick list PTO removed "+this.pickListPtoEdit);

    for (const key in obj) {
      this.pickListPtoEdit.push(obj[key]);
    }
    console.log("Pick list PTO Testing "+this.pickListPtoEdit);

   // if(this.Pto_save_Products!=null){
    this.pickListPtoEdit.forEach(i => {
      this.ptoSaveProducts.forEach(j => {
      if(j.PTOType===i.PTOType){
        console.log("PTO Duplicate is "+i.PTOType)
        this.pickListPtoEdit=this.pickListPtoEdit.filter(item => {  return item.PTOType !== i.PTOType.toString()})
      }
      });
  });
 // }//if end PartEffictive_Save_Products
/*if(this.PickListPtoEdit.length===0 && this.Pto_save_Products.length>=1 )
 {
  this.messageService.add({severity:'success', summary:'Info Message', detail:'Record Already Exist'});
 } */

});


  }
  ptoSaveCheck()
  {


 if(this.ptoSaveProducts!=null){
  this.getSelectedSaveProducts=this.ptoSaveProducts;
 var pto=this.getSelectedSaveProducts.map(function(getselected_Save_Products){
    return getselected_Save_Products['PTOType'];
  });}

  this.ptoTypeSaveSearch=null;
  this.PrgTypeTextArea=pto;
  this.savePtoDesc="";
  this.ptoSaveAdddialog=false;
  //this.Pto_save_Products=null;
  while(this.pickListPtoEdit.length){
    this.pickListPtoEdit.pop();}

  }
  ptoSaveCancel()
  {
    this.ptoTypeSaveSearch=null;
    this.savePtoDesc="";
    this.ptoSaveAdddialog=false;

    while(this.pickListPtoEdit.length){
      this.pickListPtoEdit.pop();}

  }
  s4pSaveInSearch()
  {

  this.transactionService.getS4pSearchDecs(this.editDetailsCdPlant,this.s4pSaveDec).subscribe(S4Pserch => {
  // this.S4P_Save_TypeSerch=S4Pserch;
  this.pickListS4PEdit=S4Pserch;

  if(S4Pserch.length===0)
   {   this.messageService.add({severity:'success', summary:'Info Message', detail:'No Search Result'});   }
    else{this.messageService.add({severity:'success', summary:'Info Message', detail:'Search Completed'}); }

  const obj={}
  for (let i = 0, len = this.pickListS4PEdit.length; i < len; i++) {
    obj[this.pickListS4PEdit[i]['S4PCode']] = this.pickListS4PEdit[i];
    console.log(" S4PCode Testing "+[this.pickListS4PEdit[i]['S4PCode']]);}

    while(this.pickListS4PEdit.length){
      this.pickListS4PEdit.pop();}
  console.log("Pick list S4P removed "+this.pickListS4PEdit);

  for (const key in obj) {
    this.pickListS4PEdit.push(obj[key]);
  }
//  console.log("S4P "+this.PickListS4PEdit);


    this.pickListS4PEdit.forEach(i => {
      this.s4PSaveProducts.forEach(j => {
      if(j.S4PCode===i.S4PCode){
        console.log("S4P Duplicate is "+i.S4PCode)
        this.pickListS4PEdit=this.pickListS4PEdit.filter(item => {  return item.S4PCode !== i.S4PCode.toString()})
      }
      });
  });
 /* if(this.PickListS4PEdit.length===0 && this.S4P_Save_Products.length>=1 )
  {
   this.messageService.add({severity:'success', summary:'Info Message', detail:'Record Already Exist'});
  } */

});  // promise end




  }
  s4pSaveCheck()
  {

    if(this.s4PSaveProducts!=null){
      this.getSelectedS4PProducts=this.s4PSaveProducts;
       var s4p=this.getSelectedS4PProducts.map(function(get_selectedS4PProducts){
       return get_selectedS4PProducts['S4PCode'];
     });}
     this.PrgTypeTextArea=s4p;
     this.s4pSaveDec=null;
     this.s4pSaveTypeSerch=null;
     while(this.s4PSaveProducts.length){
       this.s4PSaveProducts.pop();
     }
     while(this.pickListS4PEdit.length){
      this.pickListS4PEdit.pop();
    }


     this.s4pSaveAddDialog=false;

  }
  s4pSaveCancel()
  {
    this.s4pSaveDec=null;
    this.s4pSaveTypeSerch=null;
    this.s4pSaveAddDialog=false;
    while(this.pickListS4PEdit.length){
      this.pickListS4PEdit.pop();
    }

  }

  programEditSave2()
  {

    this.editValidationForPrgName();
    this.editValidationForPADate();
    this.editValidationForTTIpDate();
    this.editValidationForTTDate();
    this.editValidationForPPDate();
    this.editValidationForMp1Date();
    this.editValidationForMp2Date();
    this.editValidationForProgramType();
    this.editValidationForProgramTypeTextArea();
    this.editValidationForProgramActive();

this.prgEdtSave.cd_plant=this.editDetailsCdPlant;
this.prgEdtSave.cd_prgm=this.editDetailsCdPrgm;
this.prgEdtSave.plant_name=this.editDetailsPlantName
this.savePADate=this.date.transform(this.editDetailsPaDate,'yyyy-MM-dd');
this.prgEdtSave.pa_date=this.savePADate;
this.savePPDate=this.date.transform(this.editDetailsPpDate,'yyyy-MM-dd');
this.prgEdtSave.pp_date=this.savePPDate;
//this.prgedtsave.program_type=this.EditDetails_program_type; this.selectedPrgType.PrgTypecode
//this.prgedtsave.program_type=this.selectedPrgType.PrgTypecode;
this.prgEdtSave.program_type=this.editDetailsProgramType;

if(this.editDetailsProgramType==="PTO")
{
  this.prgEdtSave.pto_type_code=this.PrgTypeTextArea.toString();

}
if(this.editDetailsProgramType==="Part Effective in Dates")
{
  this.prgEdtSave.part_eff_in_date=this.PrgTypeTextArea.toString();
}
if(this.editDetailsProgramType==="S4P-in-Code")
{
  this.prgEdtSave.s4p_in_code=this.PrgTypeTextArea.toString();
}


this.prgEdtSave.program_name=this.editDetailsProgramName;
this.saveTTDate=this.date.transform(this.editDetailsTtDate,'yyyy-MM-dd');
this.prgEdtSave.tt_date=this.saveTTDate;
this.saveJob1Date=this.date.transform(this.editDetailsJob1Date,'yyyy-MM-dd');
this.prgEdtSave.job1_date=this.saveJob1Date;
this.saveTTIPDate=this.date.transform(this.editDetailsTtIpDate,'yyyy-MM-dd');
this.prgEdtSave.tt_ip_date=this.saveTTIPDate;
this.saveMPIPDate=this.date.transform(this.editDetailsMp1IpDate,'yyyy-MM-dd');
this.prgEdtSave.mp1_ip_date=this.saveMPIPDate;
//this.prgedtsave.std_cntr_asso_program=this.EditDetails_std_cntr_asso_program;
if(this.PrgSTDTextArea!=null)
{
  this.prgEdtSave.std_cntr_asso_program=this.PrgSTDTextArea.toString();
}

//alert(this.prgEdtSave.std_cntr_asso_program);
this.prgEdtSave.program_active_stat=this.editDetailsProgramActiveStat;
//this.prgedtsave.s4p_in_code=this.EditDetails_s4p_in_code;
//this.savePartEff=this.date.transform(this.EditDetails_part_eff_in_date,'yyyy-MM-dd');

this.savePartEff=this.editDetailsPartEffInDate;
//this.prgedtsave.part_eff_in_date=this.savePartEff;
//this.prgedtsave.pto_type_code=this.EditDetails_pto_type_code;
console.log("Program_Edit_save() saving sending to server ",this.prgEdtSave);

if(!this.PrgNameInValid && !this.PADateInValid && !this.TTIpDateInvalid && !this.TTDateInvalid &&
  !this.PPDateInvalid && !this.Mp1DateInvalid && !this.Mp2DateInvalid && !this.programTypeInvalid &&
  !this.programTypeTextAreaInvalid && !this.programActiveInvalid)
  {

    this.transactionService.editsaveprogram(this.prgEdtSave).subscribe((output => {
      let res=output;
      if(res == "Success"){
      this.messageService.add({severity:'success', summary:'Success', detail:'Program Edited Successfully'});
      this.prgramDetails(this.prgEdtSave.cd_plant,this.prgEdtSave.cd_prgm)
      this.populateGrid();
      this.validationInit();
      this.divGridSave=true;

    }
    else{
      this.messageService.add({severity:'error', summary: 'Error', detail: 'Program Not Edited,Please Try Again'});
      //this.addformclear();
      this.validationInit();
      this.populateGrid();

    }

    }    ));
  }
 // window.location.reload();
 this.prgtypButton==='yes'
// this.divenabledEditsaveDtl=true;  Hided Program Details

// Clear Edit Part Effective screen
//this.divenabledPlntDtl=true;
//this.PickListPartEffectiveEdit=null;
this.Months=null;
this.Years=null;
this.selectedMonth=null;
this.selectedYear=null
this.getSelectedMonth=null;
this.getSelectedYear=null;

if(this.editDetailsProgramType==="Part Effective in Dates"){
  while(this.pickListPartEffectiveEdit.length){
    this.pickListPartEffectiveEdit.pop();
    }  }


  if(this.editDetailsProgramType==="PTO")
{
  this.PrgTypeTextArea=this.editDetailsPtoTypeCode;
    while(this.pickListPtoEdit.length){
      this.pickListPtoEdit.pop();
      }}


}


movesource()
{
 console.log("On Move source is working");
 //this.PickListPartEffectiveEdit=null;

}


// editStandardContainer()
// {
//   var stdSelcData;
//   this.standardContEditDialog=true;
//  // this.PrgSTDTextArea=this.editDetailsStdCntrAssoProgram;

//  //this.PrgSTDTextArea=this.stdSaveProducts;
//  // this.PrgSTDTextArea=this.EditDetails_std_cntr_asso_program;
//   this.stdContExistValues=this.PrgSTDTextArea;


//   console.log("STD Existing values getting 2 ",this.PrgSTDTextArea);


//   if( this.PrgSTDTextArea!=null && this.PrgSTDTextArea.length>0){
//     stdSelcData=this.stdContExistValues.toString().split(',').map(function(item) {
//       return {cntr_base: item};});
//       this.stdSaveProducts=stdSelcData;
//     }else{}

//     console.log("STD Existing values final ",this.stdSaveProducts);
// }

// stdEditSearch()
// {

// console.log(" STD Region search input is "+this.current_region_code_prg);
// console.log(" STD Base search input is "+this.stdBase);
// console.log(" STD Suffix search input is "+this.stdSuffix);
// console.log(" STD Desc search input is "+this.stdDesc);

// var base;
// var suffix;
// var desc;

// if(this.stdBase!=null && this.stdBase===undefined)
// {
//   base=this.stdBase;
// }else{base=0}
// if(this.stdSuffix!=null && this.stdSuffix===undefined)
// {
//  suffix=this.stdSuffix;
// }else{suffix=0}


// if(this.stdDesc!=null && this.stdDesc===undefined)
// {
//  desc=this.stdDesc;
// }else
// {desc=0}

// console.log(" STD1 Base search input is "+base);
// console.log(" STD1 Suffix search input is "+suffix);
// console.log(" STD1 Desc search input is "+desc);


// this.transactionService.getstdSearchDecs(base,suffix,desc,this.current_region_code_prg).subscribe(stdserch => {
// // this.S4P_Save_TypeSerch=S4Pserch;
// this.stdContEdit=stdserch;

// if(stdserch.length===0)
//  {   this.messageService.add({severity:'success', summary:'Info Message', detail:'No Search Result'});   }
//   else{this.messageService.add({severity:'success', summary:'Info Message', detail:'Search Completed'}); }

// const obj={}
// for (let i = 0, len = this.stdContEdit.length; i < len; i++) {
//   obj[this.stdContEdit[i]['cntr_base']] = this.stdContEdit[i];
//   console.log(" STD search Testing "+[this.stdContEdit[i]['cntr_base']]);}

//   while(this.stdContEdit.length){
//     this.stdContEdit.pop();}
// console.log("Pick list STD removed "+this.stdContEdit);

// for (const key in obj) {
//   this.stdContEdit.push(obj[key]);
// }
// //  console.log("S4P "+this.STDContEdit);


//   this.stdContEdit.forEach(i => {
//     this.stdSaveProducts.forEach(j => {
//     if(j.cntr_base===i.cntr_base){
//       console.log("STD Duplicate is "+i.cntr_base)
//       this.stdContEdit=this.stdContEdit.filter(item => {  return item.cntr_base !== i.cntr_base.toString()})
//     }
//     });
// });
//     console.log("STD container existing value display ",this.stdSaveProducts);
// }); // End method

// this.stdBase=null;
// this.stdSuffix=null;
// this.stdDesc=null;

// }
// stdSaveCheck()
// {
//   if(this.stdSaveProducts!=null){
//     this.getStdEditselectedProducts=this.stdSaveProducts;
//      var stdEdit=this.getStdEditselectedProducts.map(function(get_stdEditselectedProducts){
//      return get_stdEditselectedProducts['cntr_base'];
//    });}
//    this.PrgSTDTextArea=stdEdit;
//    console.log(" Now Search STD save Check  ",this.PrgSTDTextArea);

//    this.stdDesc=null;
//   // this.S4P_Save_TypeSerch=null;
//   /* while(this.STD_Save_Products.length){
//      this.STD_Save_Products.pop();
//    }  */
//    while(this.stdContEdit.length){
//     this.stdContEdit.pop();
//   }

//   //alert(" STD Values "+this.PrgSTDTextArea.length);

//   this.standardContEditDialog=false;

//   this.stdBase=null;
// this.stdSuffix=null;
// this.stdDesc=null;

// }
// stdSaveCancel()
// {
//   this.standardContEditDialog=false;
//   //this.stddesc="";
//   while(this.stdSaveProducts.length){
//      this.stdSaveProducts.pop();
//    }
//    while(this.stdContEdit.length){
//     this.stdContEdit.pop();
//   }
//   this.stdBase=null;
// this.stdSuffix=null;
// this.stdDesc=null;
// }

// addContainer()
// {
//   this.standardContDialog=true;

// }

// stdAddSearch()
// {

// console.log(" STD add Region search input is "+this.current_region_code_prg);
// console.log(" STD add Base search input is "+this.stdBase);
// console.log(" STD add Suffix search input is "+this.stdSuffix);
// console.log(" STD add Desc search input is "+this.stdDesc);

// var base;
// var suffix;
// var desc;

// if(this.addStdBase!=null && this.addStdBase===undefined)
// {
//   base=this.addStdBase;
// }else{base=0}
// if(this.addStdSuffix!=null && this.addStdSuffix===undefined)
// {
//  suffix=this.addStdSuffix;
// }else{suffix=0}


// if(this.addStdDesc!=null && this.addStdDesc===undefined)
// {
//  desc=this.addStdDesc;
// }else
// {desc=0}

// console.log(" STD1 add Base search input is "+base);
// console.log(" STD1 add Suffix search input is "+suffix);
// console.log(" STD1 add Desc search input is "+desc);


// this.transactionService.getstdSearchDecs(base,suffix,desc,this.current_region_code_prg).subscribe(stdserch => {
// // this.S4P_Save_TypeSerch=S4Pserch;
// //this.stdcontexistvalues=stdserch;

// this.stdContAdd=stdserch;

// if(stdserch.length===0)
//  {   this.messageService.add({severity:'success', summary:'Info Message', detail:'No Search Result'});   }
//   else{this.messageService.add({severity:'success', summary:'Info Message', detail:'Search Completed'}); }

// const obj={}
// for (let i = 0, len = this.stdContAdd.length; i < len; i++) {
//   obj[this.stdContAdd[i]['cntr_base']] = this.stdContAdd[i];
//   console.log(" STD search Testing "+[this.stdContAdd[i]['cntr_base']]);}

//   while(this.stdContAdd.length){
//     this.stdContAdd.pop();}
// console.log("Pick list STD removed "+this.stdContAdd);

// for (const key in obj) {
//   this.stdContAdd.push(obj[key]);
// }
// //  console.log("S4P "+this.STDContEdit);


//   this.stdContAdd.forEach(i => {
//     this.stdProducts.forEach(j => {
//     if(j.cntr_base===i.cntr_base){
//       console.log("STD Duplicate is "+i.cntr_base)
//       this.stdContAdd=this.stdContAdd.filter(item => {  return item.cntr_base !== i.cntr_base.toString()})
//     }
//     });
// });

// }); // End method
// }

// stdAddCheck()
// {
//   if(this.stdProducts!=null){
//     this.getAddEditselectedProducts=this.stdProducts;
//      var stdEdit=this.getAddEditselectedProducts.map(function(get_addEditselectedProducts){
//      return get_addEditselectedProducts['cntr_base'];
//    });}
//    this.PrgSTDADDTextArea=stdEdit;
//    console.log(" Now container add value is  ",this.PrgSTDADDTextArea);

//    while(this.stdProducts.length){
//     this.stdProducts.pop();
//   }
//   while(this.stdProducts.length){
//    this.stdProducts.pop();
//  }
//  this.addStdBase="";
//  this.addStdSuffix="";
//  this.addStdDesc="";
//  this.standardContDialog=false;
//   }

//   stdAddCancel()
//   {
//     while(this.stdProducts.length){
//       this.stdProducts.pop();
//     }
//     while(this.stdProducts.length){
//      this.stdProducts.pop();
//    }
//    this.addStdBase="";
//    this.addStdSuffix="";
//    this.addStdDesc="";
//    this.standardContDialog=false;
//   }

}



