/* tslint:disable */
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TransactionService } from '../loginpage/Transaction.service';
import { selec_prt_pro_details, fromdiplayvalues_all, RevisionObj } from '../loginpage/transactions';

@Component({
  selector: 'app-alladditionalapproval',
  templateUrl: './alladditionalapproval.component.html',
  styleUrls: ['./alladditionalapproval.component.css']
})
export class AlladditionalapprovalComponent implements OnInit {
  region:string;
  region_code:string;
  selectedsimpliedpartnodetails_all_addnl_apr:selec_prt_pro_details={simplifiedpartno:"-",bompartmasterid:0,proposalid:0,plantgsdbcode:"",proposallastsaved:0,part_status:"",supplier_code:"",proposallastsaved_ee:0,is_ee_proposal:false,currentview_ee:false,from_draft:false,propsl_type:"",imp_part_status:""};
  proposal_details_all_addnl:fromdiplayvalues_all;
  revision_obj:RevisionObj[]=[];

  enabledetails_all_addnl:boolean=false;


  view_all_addnl_apr:boolean=false;

  current_roleid_all_addnl:number;

  current_routeid:number=0;

  constructor(private transactionService: TransactionService,private router:Router,private date:DatePipe,private route: ActivatedRoute) {}

  ngOnInit(): void {

    this.region=this.transactionService.getregion();
    this.region_code=this.transactionService.getregioncode(this.region);


        this.current_roleid_all_addnl=22;

        console.log(this.current_roleid_all_addnl)


        if(this.region=="Asia Pacific & Africa")
        {
          sessionStorage.setItem('aprole', JSON.stringify(this.current_roleid_all_addnl));
        }

        else if(this.region=="Europe")
        {
          sessionStorage.setItem('eurole', JSON.stringify(this.current_roleid_all_addnl));
        }

        else if (this.region=="North America")

        {
          console.log(this.current_roleid_all_addnl)
          sessionStorage.setItem('narole', JSON.stringify(this.current_roleid_all_addnl));
        }

        else if(this.region=="South America")
        {
          sessionStorage.setItem('sarole', JSON.stringify(this.current_roleid_all_addnl));
        }



        //this.transactionService.storepage(this.region,"/packagingprocess/packsupplieradmin/packsupplier");

        this.current_routeid=260;

        console.log(this.current_routeid)


        if(this.region=='Asia Pacific & Africa')
        {
          sessionStorage.setItem('aprouteid', JSON.stringify(this.current_routeid));
        }

        else if(this.region=='Europe')
        {
          sessionStorage.setItem('eurouteid', JSON.stringify(this.current_routeid));
        }

        else if (this.region=='North America')

        {

          sessionStorage.setItem('narouteid', JSON.stringify(this.current_routeid));
        }

        else if(this.region=='South America')
        {
          sessionStorage.setItem('sarouteid', JSON.stringify(this.current_routeid));
        }


       this.view_all_addnl_apr=true;



  }

}
