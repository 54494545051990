/* tslint:disable */
import { Component, OnInit } from '@angular/core';
// import{User} from '../model/user.model';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import {TransactionService} from './Transaction.service';
import {user, navstatus, useraccessrole, userdetails} from './transactions';
import {result} from './transactions';
import { NavComponent } from '../nav/nav.component';
@Component({
  selector: 'app-loginpage',
  // templateUrl: './loginpage.component.html',
  // styleUrls: ['./loginpage.component.css']

  template:  `
  <h3 style="background-color:white;">Application is initializing….</h3>
`


})
export class LoginpageComponent {

username:string=null;
 password:string=null;
// User:user ;
User:user ={username:this.username,
	password:this.password
}
// result : string;
// Status="success";
logstat:string;
Results:result ;
Result:result;// = {status:null,plantaccess:null};
Navstatus: navstatus ={navactive:'not'};
plantaccess='yes';
UserAccess:useraccessrole[]=[];
TestID:String;
UserDetails:userdetails;


regionList=[];
constructor(private transactionService: TransactionService , private router:Router,private navigation:NavComponent) {}

ngOnInit()
{
	// this.User.username='sbalaj44';
	this.regionList=['NA','EU','AP','SA']
	this.login();
}




/*public login():void{
authService:AuthService
	//alert("I am in login");
	let user:User ={username:this.username,
	password:this.password
}

this.authService.login(user).then((result:boolean)=> {
	if(result){
		alert("I am in login");
		this.router.navigate(['/agvmonitoring-page'])
	}else{alert("try again");}
})

	/** empty constructor */

	/** used for functionality that doesn't belong in the constructor but still
	 * needs to be run at the beginning of the lifecycle of the component.


}*/
login() {
	// Update item by taking id and updated data object
	// console.log(this.username);
	let userid=this.TestID=sessionStorage.getItem('userId');
	console.log(' Test User ID ==========  >'+this.TestID);
	// Update item by taking id and updated data object
	this.loginByID(userid);

}


loginByID(userID:string)
{
	// Update item by taking id and updated data object

	// Update item by taking id and updated data object
	this.username=userID;
	// console.log(this.username);
	this.User.username= this.username.toLowerCase();
	// this.User.username='p-nkaliya1';//p-chmb23
	// this.User.username='testf4';//testid



	// this.User.username= this.username;
	this.User.password= this.password;
	// console.log(this.User);


	let cdsid_user=this.User.username


	let regvar_name='user_regionaccess';
	sessionStorage.setItem(regvar_name,JSON.stringify(null));

	// get user details check if user present
	this.transactionService.user_details(cdsid_user).subscribe((output => {

	this.UserDetails=output
	console.log('UserDetails',this.UserDetails);


	// && this.UserDetails.isactive.toLowerCase()=="yes"
	if(this.UserDetails!=null)
	{
		sessionStorage.removeItem('Cdsid');
		this.User.username=this.User.username.toLowerCase();
		sessionStorage.setItem('Cdsid', JSON.stringify(this.User.username));

		sessionStorage.removeItem('userDetails');
		sessionStorage.setItem('userDetails',JSON.stringify(this.UserDetails))

		sessionStorage.removeItem('userType');
		sessionStorage.setItem('userType',JSON.stringify(this.UserDetails.isforduser.toLowerCase()));

		sessionStorage.removeItem('isValidCdsid');
		sessionStorage.setItem('isValidCdsid', JSON.stringify(true));

		this.transactionService.destroyApprovalFiltersSessionStorage();
		if(this.UserDetails.isactive!=null && this.UserDetails.isactive.toLowerCase()=='yes')
		{

			sessionStorage.removeItem('isActiveUser');
			sessionStorage.setItem('isActiveUser',JSON.stringify(true));

			this.transactionService.getUserAccessRole(cdsid_user).subscribe(useraccess=>{
				console.log('useracces',useraccess);
				this.UserAccess=useraccess;
				if(this.UserAccess!=null)
				{
					if(this.UserAccess.length!=0)
					{
						let user_region=[];
						let regIndex=this.UserAccess.findIndex(elreg=>elreg.role_id==100)

						if(regIndex!=-1)
						{
							sessionStorage.removeItem('isGlobalAdmin');
							sessionStorage.setItem('isGlobalAdmin', JSON.stringify(true));
							user_region.push(...this.regionList);
							console.log('user reg',user_region);
						}

						else
						{
							user_region.push(this.UserAccess[0].cd_region);
							sessionStorage.removeItem('isGlobalAdmin');
							sessionStorage.setItem('isGlobalAdmin', JSON.stringify(false));

							for(let mi=1;mi<this.UserAccess.length;mi++)
							{
								if(user_region.length!=4)
								{
									let region_present=false;
									for(let mj=0;mj<user_region.length;mj++)
									{
										if(this.UserAccess[mi].cd_region==user_region[mj])
										{
											region_present=true;
											break;
										}
									}

									if(region_present==false)
									{
										user_region.push(this.UserAccess[mi].cd_region)
									}
								}
							}
						}
						sessionStorage.setItem(regvar_name,JSON.stringify(user_region));
						console.log(user_region[0]);
						let current_region=user_region[0];
						let roles=[];

						// later add default region code

						if(current_region=='NA')
						{
							this.navigation.navigatena(true);
						}
						else if(current_region=='AP')
						{
							this.navigation.navigateap(true);
						}
						else if(current_region=='EU')
						{
							this.navigation.navigateeu(true);
						}
						else if(current_region=='SA')
						{
							this.navigation.navigatesa(true);
						}

					}

					else
					{
						// redirect to exit page or request access as no user role present
						sessionStorage.removeItem('isGlobalAdmin');
						sessionStorage.setItem('isGlobalAdmin', JSON.stringify(false));
		
						this.router.navigate(['/requestaccess']);
					}

				}


				else
				{
					sessionStorage.removeItem('isGlobalAdmin');
					sessionStorage.setItem('isGlobalAdmin', JSON.stringify(false));
					// this.transactionService.logout();
					this.router.navigate(['/requestaccess']);
				}

			})
		}

		else
		{

			sessionStorage.removeItem('isActiveUser');
			sessionStorage.setItem('isActiveUser',JSON.stringify(false));

			sessionStorage.removeItem('tokenExp');
			sessionStorage.removeItem('tokenIssue');
			sessionStorage.removeItem('strAccessToken');
			sessionStorage.removeItem('encodedAccessToken');

			this.router.navigate(['/inactiveuser']);
		}



			// for now NA in future get role wise region and test

			let roles
		let user;

/*		user=this.transactionService.getcdsid();
		//pass value for eavh rwgion and for each region store the roles


		//in future do gor global admin if its used
		this.transactionService.getUserAccessRole(cdsid_user,"NA").subscribe(useraccess=>{
			console.log("useracces",useraccess);
			this.UserAccess=useraccess;
			if(this.UserAccess!=null)
			{
			if(this.UserAccess.length!=0)
			{
				roles=[];
				roles.push(this.UserAccess[0].role_id);

			for(var temp=1;temp<this.UserAccess.length;temp++)
			{
				var present=false;

			 for(var i=0;i<roles.length;i++)
			 {
				 if(roles[i]==this.UserAccess[temp].role_id)
				 {
					 present=true;
					 break;
				 }
			 }

			 if(present=false)
			 {
				 roles.push(this.UserAccess[temp].role_id);
			 }

			}


			console.log(roles);
			var region_code="NA"
			var name=region_code+"useraccess";
			sessionStorage.setItem(name,JSON.stringify(roles));


			this.router.navigate(['/northamerica/packadmin/packplantadmin'])
			}

			else{
				//redirect to exit page or request access as no user role present




			}

		}

		})

	*/


	}



	else
	{

	sessionStorage.removeItem('InvalidCdsid');
	sessionStorage.setItem('InvalidCdsid', JSON.stringify(this.User.username));


	sessionStorage.removeItem('isValidCdsid');
	sessionStorage.setItem('isValidCdsid', JSON.stringify(false));


	sessionStorage.removeItem('isGlobalAdmin');
	sessionStorage.setItem('isGlobalAdmin', JSON.stringify(false));

	// When user itself is not present setting this as inactive user, once user details is received it will be made active
	sessionStorage.removeItem('isActiveUser');
	sessionStorage.setItem('isActiveUser',JSON.stringify(false));

	// this.transactionService.logout();
	this.router.navigate(['/requestaccess']);


}



}))









	// this.Status.result="Passed";
	/*if(this.Status == "success"){
		//alert("I am in login");
		sessionStorage.removeItem('Cdsid');
		sessionStorage.setItem('Cdsid', JSON.stringify(this.User.username));
		sessionStorage.setItem('admin', JSON.stringify(this.plantaccess));
			if(this.plantaccess=="yes")
			{
			//console.log(this.Navstatus.navactive);
			//this.Navstatus.navactive = "active";
			this.router.navigate(['/adminpage'])
		}
		else if(this.plantaccess=="no")
		{
			this.router.navigate(['/listpage'])
		}

	}else{
		//alert("invalid user");
		var r = confirm("invalid user");
        if (r == true){
	  window.location.reload();
		}
	}
	//this.Navstatus.navactive = "notactive"; }*/

// **************
/*	this.transactionService.loginItem(this.User.username).subscribe((output => {
		this.Results=output;
		console.log("successful",this.Results);
		console.log(this.Results[0].status);
		 let sts=this.Results[0].status;
		// let plantaccess=this.Results[0].plantaccess;
		let maxaccess=this.Results[0].maxAccess;
        let plantaccess;
		//if(sts == "success"){
		if(sts == "found"){
			//alert("I am in login");
			console.log("success");
			sessionStorage.removeItem('Cdsid');
			sessionStorage.setItem('Cdsid', JSON.stringify(this.User.username));
			sessionStorage.setItem('Selectedplant',JSON.stringify(null));

			if(maxaccess == 0)
			{
			  plantaccess ="plant_user";
			}
			else if(maxaccess==1)
			{
				plantaccess="plant_admin";
			}
			else if( maxaccess==2)
			{
				plantaccess="global_admin";
			}

			sessionStorage.setItem('admin', JSON.stringify(plantaccess));
			if(plantaccess=="global_admin")
			{
				console.log("admin")
			//console.log(this.Navstatus.navactive);
			//this.Navstatus.navactive = "active";
			//this.router.navigate(['/adminpage'])
			this.router.navigate(['/example-page'])
		}
		else if(plantaccess=="plant_admin")

		{
                console.log("plant_admin");
			//this.router.navigate(['/plantadmin'])
			this.router.navigate(['/example-page'])
		}

		else if(plantaccess=="plant_user")
		{
			this.router.navigate(['/example-page'])
		}}
		else{
			sessionStorage.removeItem('InvalidCdsid');
			sessionStorage.setItem('InvalidCdsid', JSON.stringify(this.User.username));
			sessionStorage.removeItem('tokenExp');
			sessionStorage.removeItem('tokenIssue');
			sessionStorage.removeItem('strAccessToken');
			sessionStorage.removeItem('encodedAccessToken');
			this.transactionService.logout();
			this.router.navigate(['/notaccesspage']);

			//var r = confirm("invalid user and currently in devlopment, close your window");
			//if (r == true){
			//window.location.reload();
			//window.closed; }
		}

		}


		))
		*/

	  // console.log("successful",response);
	  // alert("success,refresh to see updates");

	  // alert("wait for completion");
	 // this.delay(15000);
	 // setTimeout(function(){ if(response=="success")
	// {
	 /*  for(var key in this.selectedtransac)
	   {
		 console.log(this.selectedtransac[key].history);
	   }
	 for(var key in this.selectedtransac)
	 {
	   console.log(this.selectedtransac[key].history);
	   this.selectedtransac[key].history+='<br/>Notification sent to ford and supplier<br/>';
	 }
	 for(var key in this.selectedtransac)
	 {
	   console.log(this.selectedtransac[key].history);
	 }
	 this.transactionService.updateItem(this.selectedtransac).subscribe((response:string) =>{
	   console.log("successful",response);

	   (async () => {
		 // Do something before delay
		 console.log('before delay')

		 await this.delay(1000000);

		 // Do something after
		 console.log('after delay')
	 })();
	  // alert("success,emailsent");



	// }  }, 3000);

  })*/
	 // this.selectedtransac = [];

}

}
