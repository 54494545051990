import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

/** This class is a service to handle holding and distributing user ID to be displayed
 */
@Injectable({
  providedIn: 'root'
})
export class UserIdService {

	/** The User ID to be stored from authentication and distributed to other components in the app */
	userID: BehaviorSubject<string> = new BehaviorSubject<string>('Not Logged In');

	/** creates a new instance of the UserIdService
	 */
	constructor() {
	  if (sessionStorage.getItem('userId')) {
	    this.setUserID(sessionStorage.getItem('userId'));
		console.log('Mahe ADFS intiall cdsid ----->  '+sessionStorage.getItem('userId'))
		// Mahendran Test case
		}
	}

	/** Stores user id
	 *
	 * @param userId the user ID to be stored on in the userID property of this service
	 */
	setUserID(userId: string) {
		this.userID.next(userId);
	}

	/** gets the user ID stored in this service
	 *
	 * @returns the userID cast as an observable to be subscribed to by other components
	 */
	getUserID(): Observable<string> {
		return this.userID;
	}
}
