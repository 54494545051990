/* tslint:disable */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import { TransactionService } from '../loginpage/Transaction.service';
import { selec_prt_pro_details, containerOwnershipList, contoncoslist, addContainer, cntrandsudetailspost, colorreglist, primarypartdetails, fromdiplayvalues_all, tab4details, searchProposalGrid, pinPalletTypeForGet, containerDetailsCntrAdmin, changeTrackingForImprovement, containerMaterialList, storecomponentforpost } from '../loginpage/transactions';
//import { PackagingprocessComponent } from '../packagingprocess/packagingprocess.component';
//import { PackagingproposalComponent } from '../packagingproposal/packagingproposal.component';
//import { PartsandatributesComponent } from '../partsandatributes/partsandatributes.component';
import { SubmiterdashboardComponent } from '../submiterdashboard/submiterdashboard.component';
import { ImprovementpackagingproposalComponent } from '../improvementpackagingproposal/improvementpackagingproposal.component';
import { PackagingprocessdashboardComponent } from '../packagingprocessdashboard/packagingprocessdashboard.component';
import { ImprovementtaboneComponent } from '../improvementtabone/improvementtabone.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-improvementtabtwo',
  templateUrl: './improvementtabtwo.component.html',
  styleUrls: ['./improvementtabtwo.component.css'],
  providers:[ConfirmationService,MessageService]
})
export class ImprovementtabtwoComponent implements OnInit {


  tab3menuitem:MenuItem;
  name:string;
  tab3:string;
  flag:boolean;
  region:string;
  tab1route:string;
  tab2route:string;
  tab3route:string;
  tab4route:string;

  containerdetails:containerDetailsCntrAdmin;
  iscntrselected:boolean;
  cntrdetforpost:cntrandsudetailspost;
  colorforcntrorrack:colorreglist[]=[];
  selectedcolor:colorreglist;

  select_contcos_dialog=false;
  contandcos_cdsid:string;
  current_region_contandcos:string;
  current_region_code_contandcos:string;
  // supp_code_contandcos:string;
  selectedpart_submitter_contandcos:selec_prt_pro_details={simplifiedpartno:'-',proposalid:0,bompartmasterid:0,plantgsdbcode:'',proposallastsaved:0,part_status:'',supplier_code:'',proposallastsaved_ee:0,is_ee_proposal:false,currentview_ee:false,from_draft:false,propsl_type:'',imp_part_status:''};
  id_proposal_contandcos:number;
  noSupDialog_contandcos=false;
  content_supp_contandcos:string;
  noPrtDialog_contandcos=false;
  content_selectedprt_contandcos:string;
  nocosdialog=false;
  content_cos_contandcos:string;


  disable_cos_dropdown=false;


  enable_details_dialog=false;
  current_region_code_prt:string;


  cos_from_pfep='';
  deviate_from_pfep=false;
  cntrOwnershipstratcodelist:containerOwnershipList[]=[];
  selected_cntr_ownrship_strat_code:containerOwnershipList;


  selected_container:contoncoslist=null;
  table_selected_container:contoncoslist=null;
  exsisting_value_from_pfep:containerOwnershipList;


  selected_container_cos:containerOwnershipList;
  selected_container_deviate_from_pfep:boolean;



  contlist_oncos:contoncoslist[];
  cont_grid_text='';
  cont_present_table=false;
  time='first';
  dropdown_time='first'
  disable_deviate=false;
  public cont_cols:any
  // roleid_contandcos:number;
 // designsource_code_contandcos:string;


  coscodes:containerOwnershipList[]=[];
  existingtab2details:cntrandsudetailspost;
  getlastsavedtabfromapi:primarypartdetails;
  containerlist:contoncoslist[]=[];

noDesignsourceDialog_contandcos=false;
content_Designsource_contandcos='';


noroleselectedDialog=false;
  norolecontent=''


  edit_measure_contcos = 'lbs/inch';
  edit_measure_contcos_carton='PSI';

  get_measure='lbs/inch';

  unitom = '';

  enabledetails_cntr=false;
  cntrdetails_display:containerDetailsCntrAdmin;
  stampingprop=false;
  pinpallettyp=[{label:'N/A',value:'N/A'},{label:'Hexagon',value:'Hexagon'},{label:'Square',value:'Square'},{label:'Round',value:'Round'}]
  pinpallettypeList:pinPalletTypeForGet[]=[];
  selectedPinPalletType:pinPalletTypeForGet={cd_pinpallettyp:0,pinpallettyp:'',pinpallettyp_desc:'',active_stat:'',updated_by:'',updated_dt:'',active_stat_display:false};
  bct=0;
  ect=0;

  // To get container material
  cont_typ_no = 1;
  ret_type: string;

  pallet_type='';


  copy_cntr_dialog:boolean=false;
  copy_cntr_input:any;
  cntr_dtls_to_be_copied:fromdiplayvalues_all;
  enable_cntr_selectn_from_copy_cntr_dilg_notice=false;


  tab4_output_cntr:tab4details;


  tab4_output_det:tab4details;


  saveandnextenable=false;
  saveandcloseenable=false;

  disp_lbs = false;
  disp_kg = false;

  messageDisplay='';


  existChangeStatusTab2='';


  private originalTab2Details:cntrandsudetailspost
  private changedTab2Details:cntrandsudetailspost



  cont_retype='';
  selected_cont_retype=''

  cntrMat:containerMaterialList[]=[];
  selected_cntr_mat:containerMaterialList={cntr_matr_desc:'',cntr_matr_name:'',comp_type_flag:''};


 contextlenInvalid=false;
 contextlenInValidReason:string=null;
 contextwidInvalid=false;
 contextwidInValidReason:string=null;
 contexthgtInvalid=false;
 contexthgtInValidReason:string=null;
 contarewgtInvalid=false;
 contarewgtInValidReason:string=null;
 contNestedHtInValid=false;
 contNestedHtInValidReason:string=null;
 partsprcntrInValid=false;
 partsprcntrInValidReason:string=null;
 maxlayerprshpInValid=false;
 maxlayerprshpInValidReason:string=null;
 cntrperlaysInValid=false;
 cntrperlaysInValidReason:string=null;
 suextlenInvalid=false;
 suextlenInValidReason:string=null;
 suextwidInvalid=false;
 suextwidInValidReason:string=null;
 suexthgtInvalid=false;
 suexthgtInValidReason:string=null;
 suNestedHtInValid=false;
 suNestedHtInValidReason:string=null;


 tab3Data:storecomponentforpost[]=[];

 enableExteriorHeightPopUp:boolean=false;
 savedSuExteriorHeight:number=0;
 isSaveAndNext:boolean=false;
 
  emptyTraysList:any[]=[];
 selectedEmptyTrays:any={label:'0',value:0};

 enable_updt_cntr: boolean = false;
 containerdetails_updated: containerDetailsCntrAdmin;

 cntrColorRackDisable: boolean = false;

  constructor(private submitterdashboard: SubmiterdashboardComponent,private messageService:MessageService,private transactionService:TransactionService, private router:Router,private packagingproposal:ImprovementpackagingproposalComponent,private partsandattri:ImprovementtaboneComponent,private packagingprocess:PackagingprocessdashboardComponent,private confirmationService:ConfirmationService,private date:DatePipe) { }

  ngOnInit(): void {
    /* code for menu creation starts*/
    this.region=this.transactionService.getregion();
this.current_region_code_prt=this.transactionService.getregioncode(this.region);

    this.tab1route=this.transactionService.getregionurlvalue(this.region)+'packagingprocessdashboard/improvementproposalcreation/improvementpartsandattri';
    this.tab2route=this.transactionService.getregionurlvalue(this.region)+'packagingprocessdashboard/improvementproposalcreation/improvementpackproposaltabtwo';
    this.tab3route=this.transactionService.getregionurlvalue(this.region)+'packagingprocessdashboard/improvementproposalcreation/improvementpackproposaltabthree';
    this.tab4route=this.transactionService.getregionurlvalue(this.region)+'packagingprocessdashboard/improvementproposalcreation/improvementpackproposaltabfour';


    console.log('tab2 initialised');
   // this.packagingproposal.savedtab=this.packagingproposal.getlastsavedtabfromapi.lst_saved_tab
    console.log('savedtab',this.packagingproposal.savedtab);

   // sessionStorage.setItem("ProposalChangeStatus","unchanged");
    this.existChangeStatusTab2=sessionStorage.getItem('ProposalChangeStatus');


    if(this.existChangeStatusTab2 == null || this.existChangeStatusTab2 =='null' || this.existChangeStatusTab2.trim() == '')
    {
      this.existChangeStatusTab2='unchanged';
      sessionStorage.setItem('ProposalChangeStatus','unchanged');
    }

    this.cont_retype='Returnable';
    this.selected_cont_retype='Returnable'


    if(this.packagingproposal.savedtab==2 )
    {
      for(const i of this.packagingproposal.tabpackagingproposalmenuitems.values())
      {
            console.log(i);
            if(i.label!='Component & Material' )
            {
                this.packagingproposal.flag2=true;
            }
            else{
                this.packagingproposal.flag2=false;
                break;
            }
      }

      if(this.packagingproposal.flag2)
      {
        console.log('tab2 component added component material');
         this.packagingproposal.tabpackagingproposalmenuitems.push({label:'Component & Material',routerLink:this.tab3route, icon: 'fas fa-person-booth'});
        }
    }

    else if(this.packagingproposal.savedtab==3 || this.packagingproposal.savedtab==4)
    {
      for(const i of this.packagingproposal.tabpackagingproposalmenuitems.values())
      {
            console.log(i);
            if(i.label!='Component & Material' && i.label!='Images/Photos & Comments')
            {
                this.packagingproposal.flag3=true;
            }
            else{
                this.packagingproposal.flag3=false;
                break;
            }
      }

      if(this.packagingproposal.flag3)
      {
        console.log('tab2 component loaded images/photos And Comments');
        this.packagingproposal.tabpackagingproposalmenuitems.push({label:'Component & Material',routerLink:this.tab3route, icon: 'fas fa-person-booth'});
        this.packagingproposal.tabpackagingproposalmenuitems.push({label:'Images/Photos & Comments',routerLink:this.tab4route, icon: 'pi pi-images'});}
    }

    this.select_contcos_dialog=false;

/* code for menu creation ends*/


this.contandcos_cdsid = this.transactionService.getcdsid();
this.current_region_contandcos = this.transactionService.getregion();
// this.roleid_contandcos=this.transactionService.getcurrentrole(this.current_region_contandcos)



/* if(this.roleid_contandcos==null||(this.roleid_contandcos != 1 && this.roleid_contandcos != 2))
{


  this.noroleselectedDialog=true;
  this.norolecontent="No role selected "
}
if(this.roleid_contandcos==1)
{

this.supp_code_contandcos = this.transactionService.get_supplier(this.current_region_contandcos);
console.log(this.supp_code_contandcos);*/

/* }
else if( this.roleid_contandcos==2)
{
  this.designsource_code_contandcos = this.transactionService.get_designsource(this.current_region_contandcos);
  console.log(this.designsource_code_contandcos)
} */


this.current_region_code_contandcos = this.transactionService.getregioncode(this.current_region_contandcos);
// this.transactionService.storepage(this.current_region_contandcos, "packagingprocess/proposalcreation/packproposaltabtwo");
// this.selectedpart_submitter=this.submitterdashboard.pushdata();
this.selectedpart_submitter_contandcos = this.transactionService.getpartno_packpro(this.current_region_contandcos);

console.log(this.selectedpart_submitter_contandcos)



 // Getting Region details for UOM
 this.transactionService.get_region_details(this.current_region_code_prt).subscribe(unitype=>{

  this.unitom = unitype.uom;
  console.log('UOMREG',this.unitom);

  if(this.unitom == 'E')
  {
    this.get_measure = 'lbs/inch';
    this.edit_measure_contcos = 'lbs/inch';
    this.disp_lbs = true;
    console.log('UOMLB', this.unitom, this.edit_measure_contcos);
  }
  else
  {
    this.get_measure = 'kg/cm';
    this.edit_measure_contcos = 'kg/cm';
    this.disp_kg = true;
    console.log('UOMKG',this.unitom, this.edit_measure_contcos);
  }
});


// Get details TAB2
this.cntrdetailsinit();

this.cntrdetails_display_popup_init();
this.cntrdetforpostinit();
this.getactivecolorforregion();
this.getActivePinPalletType();
this.tab2InValidationInit();


this.iscntrselected=false;
this.enable_updt_cntr = false;

/*  if(document.getElementById("overlaycntrcos") != null )
  {
  document.getElementById("overlaycntrcos").style.display = "block";
  }
  if(document.getElementById("loadercntrcos") != null )
  {
  document.getElementById("loadercntrcos").style.display = "block";
  }

  if(this.roleid_contandcos==1)
{ */

  if (this.selectedpart_submitter_contandcos != null && this.selectedpart_submitter_contandcos.simplifiedpartno!="-" && this.selectedpart_submitter_contandcos.proposalid!=0 ) 
  {

    this.id_proposal_contandcos=this.selectedpart_submitter_contandcos.proposalid;

      this.messageDisplay=''
      if(this.selectedpart_submitter_contandcos.is_ee_proposal==true)
      {
        this.messageDisplay='Are you sure you want to change container and flush EE proposal, Component, Image details associated with the container ?'
      }
      else
      {
        this.messageDisplay='Are you sure you want to change container and flush Component and Image details associated with the container ?'
      }
    this.transactionService.get_draft_Tab1_data(this.id_proposal_contandcos).subscribe(dtab1out=>{
      if(dtab1out.isstampblank=='Yes' || dtab1out.isstampblank=='yes')
      {
        this.stampingprop=true;
      }
    })

    if(this.selectedpart_submitter_contandcos.proposallastsaved>=2)
    {
      console.log('this.selectedpart_submitter_contandcos',this.selectedpart_submitter_contandcos.proposalid);
      this.loadtab2detlsifexist();
    }
    else
    {
      if(sessionStorage.getItem(String(this.selectedpart_submitter_contandcos.proposalid))!=null)
      {
        sessionStorage.removeItem(String(this.selectedpart_submitter_contandcos.proposalid))
      }

      this.originalTab2Details=JSON.parse(JSON.stringify(this.cntrdetforpost));
      this.changedTab2Details=this.cntrdetforpost;
    }
  }
  else
  {
    if(this.selectedpart_submitter_contandcos == null || this.selectedpart_submitter_contandcos.simplifiedpartno=='-' || this.selectedpart_submitter_contandcos.proposalid==0) {
      this.noPrtDialog_contandcos = true;
      this.content_selectedprt_contandcos = 'Please select part from Search Existing Proposal';
    }
      // document.getElementById("overlaycntrcos").style.display = "none";
  }
    /*
      document.getElementById("overlaycntrcos").style.display = "none";
        document.getElementById("loadercntrcos").style.display = "none";






  else if (this.supp_code_contandcos == null) {
    this.noSupDialog_contandcos = true;
    this.content_supp_contandcos = "Please select supplier";
  // document.getElementById("overlaycntrcos").style.display = "none";
    //document.getElementById("loadercntrcos").style.display = "none";
  }


  else if (this.supp_code_contandcos != null && (this.selectedpart_submitter_contandcos == null || this.selectedpart_submitter_contandcos.simplifiedpartno=="-" || this.selectedpart_submitter_contandcos.proposalid==0)) {
    this.noPrtDialog_contandcos = true;
    this.content_selectedprt_contandcos = "Please select part from submitter dashboard";
    //document.getElementById("overlaycntrcos").style.display = "none";
    //document.getElementById("loadercntrcos").style.display = "none";

  }

  }
  else if (this.roleid_contandcos==2)
{



if (this.designsource_code_contandcos != null && this.selectedpart_submitter_contandcos != null && this.selectedpart_submitter_contandcos.simplifiedpartno!="-" && this.selectedpart_submitter_contandcos.proposalid!=0 ) {

  this.id_proposal_contandcos=this.selectedpart_submitter_contandcos.proposalid;
  this.transactionService.get_draft_Tab1_data(this.id_proposal_contandcos).subscribe(dtab1outs=>{
    if(dtab1outs.isstampblank=="Yes" || dtab1outs.isstampblank=="yes")
    {
      this.stampingprop=true;
    }
  })

  if(this.selectedpart_submitter_contandcos.proposallastsaved>=2)
  {
    console.log("this.selectedpart_submitter_contandcos",this.selectedpart_submitter_contandcos.proposalid);
  this.loadtab2detlsifexist();
  }
  else
  {
    if(sessionStorage.getItem(String(this.selectedpart_submitter_contandcos.proposalid))!=null)
    {
      sessionStorage.removeItem(String(this.selectedpart_submitter_contandcos.proposalid))
    }
   // document.getElementById("overlaycntrcos").style.display = "none";
    //document.getElementById("loadercntrcos").style.display = "none";
  }


}

else if (this.designsource_code_contandcos == null) {
  this.noDesignsourceDialog_contandcos = true;
  this.content_Designsource_contandcos = "Please select Design source";
 // document.getElementById("overlaycntrcos").style.display = "none";
  //document.getElementById("loadercntrcos").style.display = "none";
}


else if (this.designsource_code_contandcos != null && (this.selectedpart_submitter_contandcos == null || this.selectedpart_submitter_contandcos.simplifiedpartno=="-" || this.selectedpart_submitter_contandcos.proposalid==0)) {
  this.noPrtDialog_contandcos = true;
  this.content_selectedprt_contandcos = "Please select part from submitter dashboard";
  //document.getElementById("overlaycntrcos").style.display = "none";
  //document.getElementById("loadercntrcos").style.display = "none";
}

} */



 }
 tab2InValidationInit()
  {
    this.contextlenInvalid=false;
    this.contextlenInValidReason=null;
    this.contextwidInvalid=false;
    this.contextwidInValidReason=null;
    this.contexthgtInvalid=false;
    this.contexthgtInValidReason=null;
    this.contarewgtInvalid=false;
    this.contarewgtInValidReason=null;
    this.contNestedHtInValid=false;
    this.contNestedHtInValidReason=null;
    this.partsprcntrInValid=false;
    this.partsprcntrInValidReason=null;
    this.maxlayerprshpInValid=false;
    this.maxlayerprshpInValidReason=null;
    this.cntrperlaysInValid=false;
    this.cntrperlaysInValidReason=null;
    this.suextlenInvalid=false;
    this.suextlenInValidReason=null;
    this.suextwidInvalid=false;
    this.suextwidInValidReason=null;
    this.suexthgtInvalid=false;
    this.suexthgtInValidReason=null;
    this.suNestedHtInValid=false;
    this.suNestedHtInValidReason=null;
  }

  emptyTraysChange()
  {
    this.cntrdetforpost.empty_trays_cntr_layrs=this.selectedEmptyTrays;
    console.log("emptyTraysChange()",this.cntrdetforpost.empty_trays_cntr_layrs,this.selectedEmptyTrays)
  }

  getEmptyTraysList()
  {
    var maxTraysValue=this.cntrdetforpost.max_layrs_shp_unt < 9 ? this.cntrdetforpost.max_layrs_shp_unt : 8; 
    this.emptyTraysList=[];
    if(this.cntrdetforpost.max_layrs_shp_unt != null && this.cntrdetforpost.max_layrs_shp_unt != 0)
    {
    for(var i=0; i<maxTraysValue; i++)
    {
      this.emptyTraysList.push({label:i.toString(),value:i});
    }
    }
    else
    {
      this.emptyTraysList.push({label:"0",value:0})
    }
    return this.emptyTraysList;
  }
  
 getContainerMaterial(){

  if(this.selected_cont_retype=='Expendable'){
    this.ret_type = 'E';
  }
  else{
    this.ret_type = 'R';
  }

  this.transactionService.getContainerMaterial(this.cont_typ_no,this.ret_type).subscribe(contmat=>{
    this.cntrMat=[];
    this.cntrMat=contmat;

    console.log('Cont Material',this.ret_type,this.cntrMat)
    console.log('post value',this.cntrdetforpost.cntr_material);


  if(this.cntrMat != null && this.cntrMat.length !=0  && this.cntrdetforpost.cntr_material != null)
  {
    for(const t of this.cntrMat)
    {
     if(t.cntr_matr_name.toLowerCase() == this.cntrdetforpost.cntr_material.toLowerCase())
     {
        this.selected_cntr_mat=t;
        console.log('Material in existingdets',this.selected_cntr_mat,this.cntrdetforpost.cntr_material)
        break;
     }
    }
   }
	this.cntrdetforpost.cntr_material=this.selected_cntr_mat.cntr_matr_desc
      
       if(this.selected_cntr_mat.cntr_matr_desc == "Corrugated Cardboard")
       {
         this.cntrColorRackDisable = true;
         console.log("Mat for disable", this.selected_cntr_mat);
       }
       else
       {
         this.cntrColorRackDisable = false;
       }
      console.log("cntr mat",this.selected_cntr_mat)
     
  })

}


  copy_cntr()
  {
    this.copy_cntr_dialog=true;
    // this.selectedpart_submitter_contandcos
    this.copy_cntr_input={dialogstatus:this.copy_cntr_dialog,suppliercode:this.selectedpart_submitter_contandcos.supplier_code,id_proposal:this.selectedpart_submitter_contandcos.proposalid};
    // this.setCopyCntrPopUpWithSelectedDeatils();


  }

  setCopyCntrPopUpWithSelectedDeatils()
  {
    if(sessionStorage.getItem('partsfromcopycntrdialog')!=null)
    {
      let proposalInApproveForProductionStatus:searchProposalGrid[]
      proposalInApproveForProductionStatus=JSON.parse(sessionStorage.getItem('partsfromcopycntrdialog'));

      if(this.cntrdetforpost.ref_bompartmasterid!=null && proposalInApproveForProductionStatus.length!=0)
      {
        for(const i of proposalInApproveForProductionStatus)
        {
          if(i.bompartmasterid==this.cntrdetforpost.ref_bompartmasterid)
          {
            sessionStorage.setItem(String(this.selectedpart_submitter_contandcos.proposalid),JSON.stringify(i));
            break;
          }
        }
      }

    }
  }

  copy_cntr_dialog_close()
  {
    this.copy_cntr_dialog=false;
    this.selectedpart_submitter_contandcos.from_draft=true;
    this.store_primary_part_exist();
    this.copy_cntr_input={dialogstatus:this.copy_cntr_dialog,suppliercode:this.selectedpart_submitter_contandcos.supplier_code,id_proposal:this.selectedpart_submitter_contandcos.proposalid};
    // alert("copy_cntr_dialog_close"+this.copy_cntr_dialog);
  }

  changedialogvalue(event:boolean)
  {
    // alert("came here"+event)

    this.copy_cntr_input={dialogstatus:this.copy_cntr_dialog,suppliercode:this.selectedpart_submitter_contandcos.supplier_code,id_proposal:this.selectedpart_submitter_contandcos.proposalid};

    /* Selected Proposal From Copy container dialog (entire row) is saved sessionly with key as proposalid
    so fetching the proposal from session storage and sending it's proposal id to api to fetch container details */

    if(sessionStorage.getItem(String(this.selectedpart_submitter_contandcos.proposalid))!=null)
    {
      this.cntrdetforpost.cntrselfrm='copycntr';
      this.enable_cntr_selectn_from_copy_cntr_dilg_notice=true;
      const proposalidtobecopiedfrom=JSON.parse(sessionStorage.getItem(String(this.selectedpart_submitter_contandcos.proposalid)));
      this.transactionService.loadtransacmasterdetails(proposalidtobecopiedfrom.id_proposal).subscribe(cntrdetfromtransmas=>{
       // alert(JSON.stringify(proposalidtobecopiedfrom));
        this.cntr_dtls_to_be_copied=cntrdetfromtransmas

        this.iscntrselected=true;
        console.log('cntrdetailstobecopied',this.cntr_dtls_to_be_copied);

        this.cntrdetforpost.id_proposal=this.selectedpart_submitter_contandcos.proposalid;
        this.cntrdetforpost.cntr_ret_type=this.cntr_dtls_to_be_copied.cntr_ret_type;
        this.cntrdetforpost.used_carry_ovr_pck=this.cntr_dtls_to_be_copied.used_carry_ovr_pck
        this.cntrdetforpost.cntr_base =this.cntr_dtls_to_be_copied.cntr_base;
        this.cntrdetforpost.cntr_suff=this.cntr_dtls_to_be_copied.cntr_suff;
        this.cntrdetforpost.cntr_desc=this.cntr_dtls_to_be_copied.cntr_desc;
        this.cntrdetforpost.cntr_ext_len=this.cntr_dtls_to_be_copied.cntr_ext_len;
        this.cntrdetforpost.cntr_width=this.cntr_dtls_to_be_copied.cntr_width;
        this.cntrdetforpost.cntr_height=this.cntr_dtls_to_be_copied.cntr_height;
        this.cntrdetforpost.cntr_material=this.cntr_dtls_to_be_copied.cntr_material;
        console.log('copy cont',this.cntr_dtls_to_be_copied.cntr_material)
        this.cntrdetforpost.cntr_tar_weight=this.cntr_dtls_to_be_copied.cntr_tar_weight;
       // this.cntrdetforpost.cntr_per_layer=this.cntr_dtls_to_be_copied.cntr_per_layer;
        //this.cntrdetforpost.max_layrs_shp_unt=this.cntr_dtls_to_be_copied.max_layrs_shp_unt;
        this.cntrdetforpost.empty_trays_cntr_layrs=this.cntr_dtls_to_be_copied.empty_trays_cntr_layrs;
        this.selectedEmptyTrays=this.cntrdetforpost.empty_trays_cntr_layrs;
        this.cntrdetforpost.max_cntr_per_shp_unt=this.cntr_dtls_to_be_copied.max_cntr_per_shp_unt;
        this.cntrdetforpost.shp_unt_desc=this.cntr_dtls_to_be_copied.shp_unt_desc;
        this.cntrdetforpost.shp_unt_ext_height=this.cntr_dtls_to_be_copied.shp_unit_ext_len;
        this.cntrdetforpost.shp_unit_ext_len=this.cntr_dtls_to_be_copied.shp_unt_ext_width;
        this.cntrdetforpost.shp_unt_ext_width=this.cntr_dtls_to_be_copied.shp_unt_ext_height;
        this.cntrdetforpost.shp_unt_nestd_fld_height=this.cntr_dtls_to_be_copied.shp_unt_nestd_fld_height;
        this.cntrdetforpost.card_call=this.cntr_dtls_to_be_copied.card_call;
        this.cntrdetforpost.cntr_grp_id=this.cntr_dtls_to_be_copied.cntr_grp_id;
        this.cntrdetforpost.cntr_ownrshp_strat_code=this.cntr_dtls_to_be_copied.cntr_ownrshp_strat_code;
        this.cntrdetforpost.cd_ownrshp_strat=this.cntr_dtls_to_be_copied.cd_ownrshp_strat;
        this.cntrdetforpost.cd_cntr=this.cntr_dtls_to_be_copied.cd_cntr;

        for(let color=0;color<this.colorforcntrorrack.length;color++)
        {
          if(this.colorforcntrorrack[color].color_desc==this.cntr_dtls_to_be_copied.cntr_rck_colr)
          {
            this.selectedcolor=this.colorforcntrorrack[color];
            this.cntrdetforpost.cntr_rck_colr=this.selectedcolor.color_desc;

          }
        }
        this.getContainerMaterial();

        this.cntrdetforpost.cntr_nsted_fld_height=this.cntr_dtls_to_be_copied.cntr_nsted_fld_height;
        this.cntrdetforpost.shp_unt_base=this.cntr_dtls_to_be_copied.shp_unt_base;
        this.cntrdetforpost.shp_unt_suff=this.cntr_dtls_to_be_copied.shp_unt_suff;
        this.cntrdetforpost.part_per_shp_unit=this.cntr_dtls_to_be_copied.part_per_shp_unit;
        this.cntrdetforpost.parts_per_cntr=this.cntr_dtls_to_be_copied.parts_per_cntr;
         // <!-- New changes are here  -->
         this.cntrdetforpost.cntr_per_layer=this.cntr_dtls_to_be_copied.cntr_per_layer;
         this.cntrdetforpost.max_layrs_shp_unt=this.cntr_dtls_to_be_copied.max_layrs_shp_unt;
         // Ends here
        this.cntrdetforpost.shp_unt_ret_type=this.cntr_dtls_to_be_copied.shp_unt_ret_type;
        this.cntrdetforpost.pallettype=this.cntr_dtls_to_be_copied.pallettype,
        this.pallet_type=this.cntrdetforpost.pallettype
        this.cntrdetforpost.woodispmcmplnt= this.cntr_dtls_to_be_copied.woodispmcmplnt,
        this.cntrdetforpost.offstmpmartprsnt= this.cntr_dtls_to_be_copied.offstmpmartprsnt,
        this.cntrdetforpost.blanksperstack= this.cntr_dtls_to_be_copied.blanksperstack,
        this.cntrdetforpost.stacksperpallet=this.cntr_dtls_to_be_copied.stacksperpallet,
        
        this.cntrdetforpost.iscartonimcspec= null,
        this.cntrdetforpost.cartonburststrnth=null,
        this.cntrdetforpost.imcwtdisc= null,
        // ADD for cntr owner also
        this.cntrdetforpost.cntrowner=this.cntr_dtls_to_be_copied.cntrOwner;
        this.cntrdetforpost.ref_bompartmasterid=proposalidtobecopiedfrom.bompartmasterid;

        this.transactionService.getActivePinPalletTypeList().subscribe(pin=>{

          this.pinpallettypeList=[];
          this.pinpallettypeList=pin;

          // return this.colorforcntrorrack;
           // this.colorforcntrorrack=colr;
           console.log('Pin Pallet tYpe List',this.pinpallettypeList)


        if(this.pinpallettypeList.length !=0 && this.cntr_dtls_to_be_copied.palletpintyp != null && this.cntr_dtls_to_be_copied.palletpintyp != '')
        {
          const pintypeIndex = this.pinpallettypeList.findIndex(pintyp=>pintyp.pinpallettyp.toLowerCase() == this.cntr_dtls_to_be_copied.palletpintyp.toLowerCase())

          if(pintypeIndex != -1)
          {
            this.selectedPinPalletType=this.pinpallettypeList[pintypeIndex];
            this.cntrdetforpost.palletpintyp= this.selectedPinPalletType.pinpallettyp;
          }
          else
          {
            this.selectedPinPalletType={cd_pinpallettyp:0,pinpallettyp:'',pinpallettyp_desc:'',active_stat:'',updated_by:'',updated_dt:'',active_stat_display:false};
          }

        }
        else
          {
            this.selectedPinPalletType={cd_pinpallettyp:0,pinpallettyp:'',pinpallettyp_desc:'',active_stat:'',updated_by:'',updated_dt:'',active_stat_display:false};
          }

        })


        if(this.cntr_dtls_to_be_copied.bct==null)
        {
          this.cntrdetforpost.bct=null;
        }
        else
        {
          this.cntrdetforpost.bct=this.cntr_dtls_to_be_copied.bct;
        }

        if(this.cntr_dtls_to_be_copied.ect==null)
        {
          this.cntrdetforpost.ect=null;
        }
        else
        {
          this.cntrdetforpost.bct=this.cntr_dtls_to_be_copied.ect;
        }


        this.containerdetails.cd_cntr=this.cntr_dtls_to_be_copied.cd_cntr;
        this.containerdetails.cntr_base=this.cntr_dtls_to_be_copied.cntr_base;
        this.containerdetails.cntr_suffix=this.cntr_dtls_to_be_copied.cntr_suff;
        this.containerdetails.cntr_desc=this.cntr_dtls_to_be_copied.cntr_desc;
        this.containerdetails.cntr_tare_wt=this.cntr_dtls_to_be_copied.cntr_tar_weight;
        this.containerdetails.exp_refundable=this.cntr_dtls_to_be_copied.cntr_ret_type;
        this.containerdetails.cntr_matr_desc=this.cntr_dtls_to_be_copied.cntr_material;
        this.containerdetails.cntr_grp_desc=this.cntr_dtls_to_be_copied.cntr_grp_id;
        this.containerdetails.card_call=this.cntr_dtls_to_be_copied.card_call;
        this.containerdetails.cntr_exterior_l=this.cntr_dtls_to_be_copied.cntr_ext_len;
        this.containerdetails.cntr_exterior_w=this.cntr_dtls_to_be_copied.cntr_width;
        this.containerdetails.cntr_exterior_h=this.cntr_dtls_to_be_copied.cntr_height;
        // this.containerdetails.max_layers_shpg_unit=this.cntr_dtls_to_be_copied.max_layrs_shp_unt;
        // this.containerdetails.cntr_per_layer=this.cntr_dtls_to_be_copied.cntr_per_layer;
        this.containerdetails.max_cntr_per_shpg_unit=this.cntr_dtls_to_be_copied.max_cntr_per_shp_unt;
        this.containerdetails.max_cntr_per_shpg_unit=this.cntr_dtls_to_be_copied.max_cntr_per_shp_unt;
        this.containerdetails.shpg_unit_exterior_l=this.cntr_dtls_to_be_copied.shp_unit_ext_len;
        this.containerdetails.shpg_unit_exterior_w=this.cntr_dtls_to_be_copied.shp_unt_ext_width;
        this.containerdetails.shpg_unit_exterior_h=this.cntr_dtls_to_be_copied.shp_unt_ext_height;
        this.containerdetails.shpg_unit_nstdorflded_ht=this.cntr_dtls_to_be_copied.shp_unt_nestd_fld_height;

        console.log('ctrdetailscopied',this.cntrdetforpost);
      });
      this.selected_container=null;
      this.table_selected_container=null;
      this.selected_container_cos=null;
      this.selected_cntr_ownrship_strat_code=null;
      this.copy_cntr_dialog=event;
    }
    else
    {
      this.copy_cntr_dialog=event;
    }
  }


  maxLayerChange()
  {
    if(this.cntrdetforpost.max_layrs_shp_unt == null || this.cntrdetforpost.max_layrs_shp_unt <= this.cntrdetforpost.empty_trays_cntr_layrs)
    {
      this.cntrdetforpost.empty_trays_cntr_layrs=0;
      this.selectedEmptyTrays=0;
    }
  }


  loadtab2detlsifexist()
  {

    /* check if proposal already exists and tab2 has been already saved in proposal*/

    console.log('entered into loadtab2detailsifexits',this.packagingproposal.savedtab);

    /*
    this.transactionService.get_exist_proposalid(this.selectedpart_submitter_contandcos.simplifiedpartno,this.selectedpart_submitter_contandcos.plantgsdbcode).subscribe(result=>
      {

        //when this component is refreshed, packagingpropsal.saved tab will become undefined, so setting it by calling api

        this.getlastsavedtabfromapi=result;
        console.log("getlastsavedtabfromapi",this.getlastsavedtabfromapi);
        //console.log(result.lst_saved_tab);
        this.packagingproposal.savedtab=result.lst_saved_tab;

       // if(this.selectedpart_submitter_contandcos.proposalexist=="Yes" &&
      */ if(this.selectedpart_submitter_contandcos.proposallastsaved>=2)
        {
          console.log('inside if condition');

          this.transactionService.loadtab2detailsifproposalexistalready(this.selectedpart_submitter_contandcos.proposalid).subscribe(check=>
          {
            this.existingtab2details=check;
            console.log(this.existingtab2details)

            this.containerdetails.cd_cntr=this.existingtab2details.cd_cntr;
            this.containerdetails.cntr_base=this.existingtab2details.cntr_base;
            this.containerdetails.cntr_suffix=this.existingtab2details.cntr_suff;
            this.containerdetails.cntr_desc=this.existingtab2details.cntr_desc;
            this.containerdetails.cntr_tare_wt=this.existingtab2details.cntr_tar_weight;
            this.containerdetails.exp_refundable=this.existingtab2details.cntr_ret_type;
            if(this.containerdetails.exp_refundable != null && this.containerdetails.exp_refundable.toLowerCase() == 'expendable')
            {
              // this.cont_retype="Expendable"
              this.selected_cont_retype='Expendable'
            }
            else if(this.containerdetails.exp_refundable != null && this.containerdetails.exp_refundable.toLowerCase() == 'returnable')
            {
              // this.cont_retype="Returnable"
              this.selected_cont_retype='Returnable'
            }
            this.containerdetails.cntr_matr_desc=this.existingtab2details.cntr_material;
            this.containerdetails.cntr_grp_desc=this.existingtab2details.cntr_grp_id;
            this.containerdetails.card_call=this.existingtab2details.card_call;
           // this.selectedcolor.color_desc=this.existingtab2details.cntr_rck_colr;

           for(let color=0;color<this.colorforcntrorrack.length;color++)
           {
              if(this.colorforcntrorrack[color].color_desc==this.existingtab2details.cntr_rck_colr)
              {
                this.selectedcolor=this.colorforcntrorrack[color];
                this.cntrdetforpost.cntr_rck_colr=this.selectedcolor.color_desc;

              }
           }

           this.cntrdetforpost.cntr_material=this.existingtab2details.cntr_material;
           this.containerdetails.cntr_matr_desc=this.existingtab2details.cntr_material;
           console.log('CONT MAT IN EXISTING', this.existingtab2details.cntr_material);
           this.getContainerMaterial();

            this.cntrdetforpost.cntr_nsted_fld_height=this.existingtab2details.cntr_nsted_fld_height;
            this.containerdetails.cntr_exterior_l=this.existingtab2details.cntr_ext_len;
            this.containerdetails.cntr_exterior_w=this.existingtab2details.cntr_width;
            this.containerdetails.cntr_exterior_h=this.existingtab2details.cntr_height;
            this.cntrdetforpost.parts_per_cntr=this.existingtab2details.parts_per_cntr;
            this.containerdetails.max_layers_shpg_unit=this.existingtab2details.max_layrs_shp_unt;
            this.containerdetails.cntr_per_layer=this.existingtab2details.cntr_per_layer;
            this.cntrdetforpost.empty_trays_cntr_layrs=this.existingtab2details.empty_trays_cntr_layrs;
            this.selectedEmptyTrays=this.cntrdetforpost.empty_trays_cntr_layrs;
            this.containerdetails.max_cntr_per_shpg_unit=this.existingtab2details.max_cntr_per_shp_unt;
            this.cntrdetforpost.used_carry_ovr_pck=this.existingtab2details.used_carry_ovr_pck;
            this.cntrdetforpost.shp_unt_base=this.existingtab2details.shp_unt_base;
            this.cntrdetforpost.shp_unt_desc=this.existingtab2details.shp_unt_desc;
            this.cntrdetforpost.shp_unt_suff=this.existingtab2details.shp_unt_suff;
            this.containerdetails.shpg_unit_exterior_l=this.existingtab2details.shp_unit_ext_len;
            this.containerdetails.shpg_unit_exterior_w=this.existingtab2details.shp_unt_ext_width;
            this.containerdetails.shpg_unit_exterior_h=this.existingtab2details.shp_unt_ext_height;
            this.cntrdetforpost.shp_unt_ret_type=this.existingtab2details.shp_unt_ret_type;
            this.containerdetails.shpg_unit_nstdorflded_ht=this.existingtab2details.shp_unt_nestd_fld_height;
            this.iscntrselected=true;

            this.cntrdetforpost.id_proposal=this.selectedpart_submitter_contandcos.proposalid;
          this.cntrdetforpost.cntr_ret_type=this.containerdetails.exp_refundable;
          this.cntrdetforpost.cntr_base=this.containerdetails.cntr_base;
          this.cntrdetforpost.cntr_suff=this.containerdetails.cntr_suffix;
          this.cntrdetforpost.cntr_desc=this.containerdetails.cntr_desc;
          this.cntrdetforpost.cntr_ext_len=this.containerdetails.cntr_exterior_l;
          this.cntrdetforpost.cntr_width=this.containerdetails.cntr_exterior_w;
          this.cntrdetforpost.cntr_height=this.containerdetails.cntr_exterior_h;
          // this.cntrdetforpost.cntr_material=this.containerdetails.cntr_matr_desc;
          this.cntrdetforpost.cntr_tar_weight=this.containerdetails.cntr_tare_wt;
          this.cntrdetforpost.cntr_per_layer=this.containerdetails.cntr_per_layer;
          this.cntrdetforpost.max_layrs_shp_unt=this.containerdetails.max_layers_shpg_unit;
          this.cntrdetforpost.max_cntr_per_shp_unt=this.containerdetails.max_cntr_per_shpg_unit;
          this.cntrdetforpost.shp_unt_nestd_fld_height=this.containerdetails.shpg_unit_nstdorflded_ht;
          this.cntrdetforpost.card_call=this.containerdetails.card_call;
          this.cntrdetforpost.cntr_grp_id=this.containerdetails.cntr_grp_desc;
          this.cntrdetforpost.shp_unt_ext_height=this.containerdetails.shpg_unit_exterior_h;
          this.cntrdetforpost.shp_unt_ext_width=this.containerdetails.shpg_unit_exterior_w;
          this.cntrdetforpost.shp_unit_ext_len=this.containerdetails.shpg_unit_exterior_l;
          this.cntrdetforpost.cntr_ownrshp_strat_code=this.existingtab2details.cntr_ownrshp_strat_code;
          this.cntrdetforpost.cd_cntr=this.existingtab2details.cd_cntr;
          this.cntrdetforpost.cd_ownrshp_strat=this.existingtab2details.cd_ownrshp_strat;

          this.savedSuExteriorHeight=this.existingtab2details.shp_unt_ext_height;

          this.cntrdetforpost.part_per_shp_unit=this.existingtab2details.part_per_shp_unit
          this.cntrdetforpost.cntr_rck_colr=this.existingtab2details.cntr_rck_colr

          console.log('cntrpostdets', this.cntrdetforpost);
          // Fields added
          this.cntrdetforpost.cntrowner=this.existingtab2details.cntrowner;
          this.cntrdetforpost.cntrselfrm=this.existingtab2details.cntrselfrm;
          this.cntrdetforpost.ref_bompartmasterid=this.existingtab2details.ref_bompartmasterid;

          this.cntrdetforpost.pallettype=this.existingtab2details.pallettype;
          if(this.containerdetails.exp_refundable.toLowerCase()=='expendable')
          {
            this.pallet_type=this.cntrdetforpost.pallettype;

          }
          else{
            this.pallet_type='';
          }
          this.cntrdetforpost.woodispmcmplnt=this.existingtab2details.woodispmcmplnt;
          this.cntrdetforpost.offstmpmartprsnt=this.existingtab2details.offstmpmartprsnt;
          this.cntrdetforpost.blanksperstack=this.existingtab2details.blanksperstack;
          this.cntrdetforpost.stacksperpallet=this.existingtab2details.stacksperpallet;
          
          this.cntrdetforpost.iscartonimcspec=null;
          this.cntrdetforpost.cartonburststrnth=null;
          this.cntrdetforpost.imcwtdisc=null;

          // Fields added
          this.cntrdetforpost.bct=this.existingtab2details.bct;
          this.cntrdetforpost.ect=this.existingtab2details.ect;

          this.transactionService.getActivePinPalletTypeList().subscribe(pin=>{

            this.pinpallettypeList=[];
            this.pinpallettypeList=pin;

            // return this.colorforcntrorrack;
             // this.colorforcntrorrack=colr;
             console.log('Pin Pallet tYpe List',this.pinpallettypeList)

          if(this.pinpallettypeList.length !=0  && this.existingtab2details.palletpintyp != null && this.existingtab2details.palletpintyp != '')
          {
            const pintypeIndex = this.pinpallettypeList.findIndex(pintyp=>pintyp.pinpallettyp.toLowerCase() == this.existingtab2details.palletpintyp.toLowerCase())

            if(pintypeIndex != -1)
            {
              this.selectedPinPalletType=this.pinpallettypeList[pintypeIndex];
              this.cntrdetforpost.palletpintyp=this.selectedPinPalletType.pinpallettyp;
            }
            else
            {
              this.selectedPinPalletType={cd_pinpallettyp:0,pinpallettyp:'',pinpallettyp_desc:'',active_stat:'',updated_by:'',updated_dt:'',active_stat_display:false};
            }

          }
          else
            {
              this.selectedPinPalletType={cd_pinpallettyp:0,pinpallettyp:'',pinpallettyp_desc:'',active_stat:'',updated_by:'',updated_dt:'',active_stat_display:false};
            }
            this.originalTab2Details=JSON.parse(JSON.stringify(this.cntrdetforpost));
            this.changedTab2Details=this.cntrdetforpost;

              console.log('selected_container_cos',this.selected_container_cos);
              console.log('existingtab2details',this.existingtab2details);
          })

          // this.selected_container_cos=null;

          if(this.cntrdetforpost.cntrselfrm=='copycntr')
          {
            if(sessionStorage.getItem(String(this.selectedpart_submitter_contandcos.proposalid))!=null)
            {
              sessionStorage.removeItem(String(this.selectedpart_submitter_contandcos.proposalid))
            }
            // Remove the previous lines and add code to set the session storage for the bompartmasterid they have selected for copy

            this.enable_cntr_selectn_from_copy_cntr_dilg_notice=true;
            this.selected_container_cos=null;
            this.selected_container=null;
          }
          else
          {
            this.enable_cntr_selectn_from_copy_cntr_dilg_notice=false;


            this.transactionService.getOwnershipStrategyCode(this.current_region_code_contandcos).subscribe(cntrownrshipcode=>{
              this.coscodes=cntrownrshipcode;
              console.log('coscodes',this.coscodes);

              for(let l=0;l<this.coscodes.length;l++)
              {
                if(this.coscodes[l].ownrshp_strat_code==this.existingtab2details.cntr_ownrshp_strat_code)
                {
                  this.selected_container_cos=this.coscodes[l];
                  console.log('selected_container_cos',this.selected_container_cos);
                }
              }

              if(this.selectedpart_submitter_contandcos.proposallastsaved>=2 && this.existingtab2details.cntr_ret_type.toLowerCase() == "returnable")
              {
                this.enable_updt_cntr = true;
              }

              // document.getElementById("overlaycntrcos").style.display = "none";
              // document.getElementById("loadercntrcos").style.display = "none";
            });

          }


         });
        }
     // });

  }

  cntrdetailsinit()
  {
    this.containerdetails=
    {
        cd_cntr:0,
        cntr_suff_ind:'',
        exp_refundable:'',
        cntr_active_ind:'',
        cntr_base:'',
        cntr_suffix:'',
        cntr_desc:'',
        card_call:'',
        cntr_mstr_catg:'',
        cntr_typ_desc:'',
        ownrshp_strat_code:'',
        ownrshp_catg:'',
        cntr_grp_desc:'',
        cntr_exterior_l:0,
	      cntr_exterior_w:0,
	      cntr_exterior_h:0,
        collapsed_ht:0.0,
        nest_ratio:0,
        cntr_interior_l:0,
        cntr_interior_w:0,
        cntr_interior_h:0,
        cntr_tare_wt:0,
        cntr_capacity:0,
        cntr_per_layer:0,
        max_layers_shpg_unit:0,
        max_cntr_per_shpg_unit:0,
        cntr_matr_desc:'',
        cntr_drawing_no:'',
        shpg_unit_exterior_l:0,
        shpg_unit_exterior_w:0,
        shpg_unit_exterior_h:0,
        shpg_unit_nstdorflded_ht:0,
        shpg_unit_tare_wt:0,
        shpg_unit_stack_ht:0,
        warehouse_stack_ht:0,
        service_life:'',
        currency_desc:'',
        purchase_cost:0,
        rental_issue_cost:0,
        sup_rntl_dly_cost:0,
        ford_rntl_dly_cost:0,
        return_rntl_cost:0,
        tooling_dev_cost:0,
        extr_cntr_dsgn_img_ind:'',
        intr_cntr_dsgn_img_ind:'',
        shpg_unit_img_ind:'',
        cad_img_ind:'',
        img_notes:'',
        cd_region:'',
        iscontainercolapsble:'',
        cd_plant: '',
        cnt_qt_per_min_su: 0,
        nest_ratio_1: 0,
        ford_cntct: '',
        cntct_phone: '',
        req_stat: '',
        id_user: '',
        ts_last_update: '',
        uom:'E',
        typ_no:0
      }
  }

  cntrdetforpostinit()
  {
    this.cntrdetforpost={
      id_proposal:0,
      cntr_ret_type:'',
      used_carry_ovr_pck:'No',
      cntr_base:'',
      cntr_suff:'',
      cntr_desc:'',
      parts_per_cntr:0,
      cntr_ext_len:0,
      cntr_width:0,
      cntr_height:0,
      cntr_nsted_fld_height:0,
      cntr_material:'',
      cntr_tar_weight:0,
      cntr_per_layer:0,
      max_layrs_shp_unt:0,
      empty_trays_cntr_layrs:0,
      max_cntr_per_shp_unt:0,
      cntr_rck_colr:'',
      shp_unt_base:'',
      shp_unt_suff:'',
      shp_unt_desc:'',
      part_per_shp_unit:0,
      shp_unt_ret_type:'',
      shp_unit_ext_len:0,
      shp_unt_ext_width:0,
      shp_unt_ext_height:0,
      shp_unt_nestd_fld_height:0,
      card_call:'',
      cntr_grp_id:'',
      cntr_ownrshp_strat_code:'',
      cd_cntr:0,
      cd_ownrshp_strat:0,
      pallettype: "",
      woodispmcmplnt: "No",
      offstmpmartprsnt: "No",
      blanksperstack: 0,
      stacksperpallet: 0,
      palletpintyp: '',
      iscartonimcspec: '',
      cartonburststrnth:0,
      imcwtdisc: null,
      cntrowner:null,
      cntrselfrm:null,
      ref_bompartmasterid:0,
      bct:null,
      ect:null
    }
  }

  cntrdetails_display_popup_init()
  {


    this.cntrdetails_display=
    {
        cd_cntr:0,
        cntr_suff_ind:'',
        exp_refundable:'',
        cntr_active_ind:'',
        cntr_base:'',
        cntr_suffix:'',
        cntr_desc:'',
        card_call:'',
        cntr_mstr_catg:'',
        cntr_typ_desc:'',
        ownrshp_strat_code:'',
        ownrshp_catg:'',
        cntr_grp_desc:'',
        cntr_exterior_l:0,
	      cntr_exterior_w:0,
	      cntr_exterior_h:0,
        collapsed_ht:0.0,
        nest_ratio:0,
        cntr_interior_l:0,
        cntr_interior_w:0,
        cntr_interior_h:0,
        cntr_tare_wt:0,
        cntr_capacity:0,
        cntr_per_layer:0,
        max_layers_shpg_unit:0,
        max_cntr_per_shpg_unit:0,
        cntr_matr_desc:'',
        cntr_drawing_no:'',
        shpg_unit_exterior_l:0,
        shpg_unit_exterior_w:0,
        shpg_unit_exterior_h:0,
        shpg_unit_nstdorflded_ht:0,
        shpg_unit_tare_wt:0,
        shpg_unit_stack_ht:0,
        warehouse_stack_ht:0,
        service_life:'',
        currency_desc:'',
        purchase_cost:0,
        rental_issue_cost:0,
        sup_rntl_dly_cost:0,
        ford_rntl_dly_cost:0,
        return_rntl_cost:0,
        tooling_dev_cost:0,
        extr_cntr_dsgn_img_ind:'',
        intr_cntr_dsgn_img_ind:'',
        shpg_unit_img_ind:'',
        cad_img_ind:'',
        img_notes:'',
        cd_region:'',
        iscontainercolapsble:'',
        cd_plant: '',
        cnt_qt_per_min_su: 0,
        nest_ratio_1: 0,
        ford_cntct: '',
        cntct_phone: '',
        req_stat: '',
        id_user: '',
        ts_last_update: '',
        uom:'E',
        typ_no:0
      }

  }


  getactivecolorforregion()
  {
    this.transactionService.get_region_color_details(this.current_region_code_contandcos).subscribe(colr=>{
     /* for(let v of colr.values())
      {
        if(v.active_stat=="Yes")
        {
          this.colorforcntrorrack.push(v);
        }
     }*/

     this.colorforcntrorrack=colr.filter(colrs=>colrs.active_stat.toLowerCase()=='yes');
     this.selectedcolor=this.colorforcntrorrack[0];

     // return this.colorforcntrorrack;
      // this.colorforcntrorrack=colr;
      console.log('colors for rack',this.colorforcntrorrack)
    })

  }


  getActivePinPalletType()
  {

    this.transactionService.getActivePinPalletTypeList().subscribe(pin=>{

      this.pinpallettypeList=[];
      this.pinpallettypeList=pin;

      // return this.colorforcntrorrack;
       // this.colorforcntrorrack=colr;
       console.log('Pin Pallet tYpe List',this.pinpallettypeList)
     })

  }


  /*ok_noroledialog()
  {
    var url
    url=this.transactionService.getregionurlvalue(this.current_region_contandcos);
    url+="packagingprocessdashboard"
    this.router.navigate([url]);

  }




    //if no supplier present

    ok_nosupdialog() {
      console.log(this.current_region_contandcos)
      var reg_url: string
      reg_url = this.transactionService.getregionurlvalue(this.current_region_contandcos);
      reg_url += "packagingprocess/packsupplieradmin/packsupplier";

      this.router.navigate([reg_url]);

    }


    ok_noDesignsourcedialog() {
      console.log(this.current_region_contandcos)
      var reg_url: string
      reg_url = this.transactionService.getregionurlvalue(this.current_region_contandcos);
      reg_url += "packagingprocess/ppackdesignsourceadmin/packdesignsource";

      this.router.navigate([reg_url]);

    }



    go_to_supp_main_page() {
      var reg_supp_url: string;
      reg_supp_url = this.transactionService.getregionurlvalue(this.current_region_contandcos);
      if(this.roleid_contandcos==1)
      {
        reg_supp_url += "packagingprocess/packsupplieradmin/packsupplier";
      }
      else if ( this.roleid_contandcos==2)
      {
        reg_supp_url += "packagingprocess/packdesignsourceadmin/packdesignsource";
      }
      this.router.navigate([reg_supp_url]);

    }
    */

    // if no part present
    ok_noprtdialog() {
      console.log(this.current_region_contandcos)

      let reg_url_prt: string
      reg_url_prt = this.transactionService.getregionurlvalue(this.current_region_contandcos);

      reg_url_prt += 'packagingprocessdashboard/searchexistingproposal';

      this.router.navigate([reg_url_prt]);

    }




  // in main page click of select button
    select_contcos_button()
    {

      this.select_contcos_dialog=true;
      this.time='first'
      this.dropdown_time='first';



      let primary_partid:string;
      this.transactionService.get_draft_selectedparts_data(this.selectedpart_submitter_contandcos.proposalid).subscribe(list_of_parts=>{

        console.log('list of parts',list_of_parts);

        if(list_of_parts != null && list_of_parts.length !=0)
        {
          const index_primary=list_of_parts.findIndex(eleprt=>eleprt.isprimary.toLowerCase() == 'yes')
          if(index_primary != -1)
          {
            primary_partid=list_of_parts[index_primary].bompartmasterid.toString();
          }
          else
          {
            primary_partid =this.selectedpart_submitter_contandcos.bompartmasterid .toString();
          }
        }
        else
        {
          primary_partid =this.selectedpart_submitter_contandcos.bompartmasterid .toString();
        }
      this.transactionService.getSelectedPartsDetails(primary_partid).subscribe(ppartdet=>{
        console.log(ppartdet);
        console.log(ppartdet[0].cntrsuggestedownershpstrat);
        this.cos_from_pfep=ppartdet[0].cntrsuggestedownershpstrat;


       // this.deviate_from_pfep=false;

      this.transactionService.getOwnershipStrategyCode(this.current_region_code_contandcos).subscribe(cntrownrshipcode=>{
       // this.cntrOwnershipstratcodelist=cntrownrshipcode;
       console.log(cntrownrshipcode)
       this.cntrOwnershipstratcodelist=[];
       this.cntrOwnershipstratcodelist.push({cd_region:this.current_region_code_contandcos,ownrshp_strat_code:'Sel',ownrshp_desc:'Select a COS from List',cd_ownrshp_strat:0});

       for(let ilist=0;ilist<cntrownrshipcode.length;ilist++)
       {


        this.cntrOwnershipstratcodelist.push(cntrownrshipcode[ilist]);

       }





        console.log(this.cntrOwnershipstratcodelist);
        // only if container already doesnt exist
        // if(this.selected_cntr_ownrship_strat_code==null && this.selected_container==null)
        if(this.selected_container_cos==null)
      {
        if(this.cos_from_pfep!=null)
        {
          console.log(this.cos_from_pfep);
          let cos_present=false;
          let cos_index;
          for(let icos=0;icos<this.cntrOwnershipstratcodelist.length;icos++)
          {
            if(this.cntrOwnershipstratcodelist[icos].ownrshp_strat_code==this.cos_from_pfep)
            {
              cos_present=true;
              cos_index=icos;
              break;

            }

          }
          if(cos_present==true)
          {
            this.selected_cntr_ownrship_strat_code=this.cntrOwnershipstratcodelist[cos_index];
            this.exsisting_value_from_pfep=this.selected_cntr_ownrship_strat_code;
            this.deviate_from_pfep=false;
            this.on_change_deviate();

          }

          else{


           // this.nocosdialog=true;
            this.content_cos_contandcos='COS Not prsent,Please add cos in COS Admin'
            this.deviate_from_pfep=true;
        this.selected_cntr_ownrship_strat_code=this.cntrOwnershipstratcodelist[0];
        this.exsisting_value_from_pfep=this.selected_cntr_ownrship_strat_code;
        this.disable_deviate=true;
        this.on_change_deviate();

          }


          console.log(this.deviate_from_pfep)

        }
        else{
          this.deviate_from_pfep=true;
        this.selected_cntr_ownrship_strat_code=this.cntrOwnershipstratcodelist[0];
        this.exsisting_value_from_pfep=this.selected_cntr_ownrship_strat_code
        this.disable_deviate=true;
        this.on_change_deviate();
        }


      }
      // Get details TAB 2
// for get details api if implementated in ng on init
// inside the below else ---assign the selected cos to the selected_container_cos by comparing the cos we got from get details with cos List only when getdetails is present and firsttime
// and check if the cos is same as from pfep then make selected_container_deviate_from_pfep true or false
// assign the selected container by comparing with table in cos on_dropdown_Change function and assign it to table_selected_container
// if the container ownership already exist


      else
      {

        console.log('entered else part in 664');
        console.log(this.cntrOwnershipstratcodelist);
        // console.log("this.existingtab2details.cntr_ownrshp_strat_code",this.existingtab2details.cntr_ownrshp_strat_code);
        const cos_present=false;
        const pfep_cos_present=false;

        console.log('cos_from_pfep',this.cos_from_pfep);
        if(this.cos_from_pfep!=null)
        {
        for(let h=0;h<this.cntrOwnershipstratcodelist.length;h++)
        {
          if(this.cntrOwnershipstratcodelist[h].ownrshp_strat_code.toLowerCase()==this.cos_from_pfep.toLowerCase())
          {
            const pfep_cos_present=true;
            this.exsisting_value_from_pfep=this.cntrOwnershipstratcodelist[h];
          }

          }
        }

        if(pfep_cos_present==false)
        {
          this.exsisting_value_from_pfep=this.cntrOwnershipstratcodelist[0];
        }

/*
        for(var k=0;k<this.cntrOwnershipstratcodelist.length;k++)
        {
          console.log(this.cntrOwnershipstratcodelist[k]);
          if(this.time=="first" && this.existingtab2details!=null)
          {
          if(this.cntrOwnershipstratcodelist[k].ownrshp_strat_code.toLowerCase()==this.existingtab2details.cntr_ownrshp_strat_code.toLowerCase())
          {

              console.log("entered in 695");
              this.selected_container_cos=this.cntrOwnershipstratcodelist[k];
              //this.exsisting_value_from_pfep=this.selected_container_cos;
              cos_present=true;
               console.log("exsisting_value_from_pfep",this.exsisting_value_from_pfep);
           }
          }
        }
        */


            console.log('exsisting_value_from_pfep in line 705',this.exsisting_value_from_pfep);



          if(this.exsisting_value_from_pfep==this.selected_container_cos)
          {
          this.selected_container_deviate_from_pfep=false;
          }
            else{
              console.log(this.exsisting_value_from_pfep);
              this.selected_container_deviate_from_pfep=true;

            }

        console.log(this.exsisting_value_from_pfep);
        console.log('selected_container_cos in line 725',this.selected_container_cos)
        this.selected_cntr_ownrship_strat_code= this.selected_container_cos
        this.deviate_from_pfep=this.selected_container_deviate_from_pfep
        console.log(this.selected_cntr_ownrship_strat_code);
        console.log('executed line 729');


        this.cont_retype=this.selected_cont_retype;

        this.on_change_deviate();

      }

    });


      })
      })





    }



  // on chagne of check box
    on_change_deviate()
    {
      console.log('deviate_from_pfep',this.deviate_from_pfep)



    if(this.deviate_from_pfep==true)
    {
      this.disable_cos_dropdown=false;
      // when value is sel for it only first time call dropdown
      if(this.time=='first')
  {
    this.time='second';

   this.on_change_cos_dropdown();
  }
    }
    else{
      // each time deviate is made false assign the cos from pfep and load the table
      this.disable_cos_dropdown=true;
      this.time='second'
      this.selected_cntr_ownrship_strat_code=this.exsisting_value_from_pfep;
      console.log('exsisting_value_from_pfep',this.exsisting_value_from_pfep);
      console.log('selected_cntr_ownrshp_code',this.selected_cntr_ownrship_strat_code);
      this.cntrdetforpost.cntr_ownrshp_strat_code=this.selected_cntr_ownrship_strat_code.ownrshp_strat_code;
      this.on_change_cos_dropdown();

    }



    }




    onChangeExpRet(){


      // this.cont_retype = "Returnable"
      console.log('Return Type value change',this.cont_retype);


     /* if(this.cont_retype.toLowerCase() == "Returnable")
      {
        this.containerdetails.exp_refundable="Returnable";
        this.select_contcos_dialog=false;
        this.enable_cntr_selectn_from_copy_cntr_dilg_notice=false;
        this.cont_present_table=true;
        this.selected_container=null;



      }
      else if(this.cont_retype.toLowerCase() == "Expendable")
      {
        this.containerdetails.exp_refundable="Expendable";
        this.select_contcos_dialog=false;
        this.enable_cntr_selectn_from_copy_cntr_dilg_notice=false;
        this.cont_present_table=true;
        this.selected_container=null;
      }
*/

       this.on_change_cos_dropdown();
    }



  // on change of dropdown value
    on_change_cos_dropdown()
    {



      console.log(this.selected_cntr_ownrship_strat_code);
      console.log(this.current_region_code_contandcos);






     // onchange of value in dropdown to value to cos from pfep disable drop down
      if(this.selected_cntr_ownrship_strat_code==this.exsisting_value_from_pfep && this.exsisting_value_from_pfep.ownrshp_strat_code!='Sel')
      {
        this.deviate_from_pfep=false;
        this.disable_cos_dropdown=true;
      }






// each time change of selection clear the selected value
      this.selected_container=null

// only first time opening of dialog , assign already selected container

     if(this.dropdown_time=='first')
      {
        this.dropdown_time='second';


        console.log('selectedcontainer')
        console.log(this.table_selected_container)
        console.log(this.selected_container)
        this.selected_container=this.table_selected_container
      }




      const supplier_gsdb_code=this.selectedpart_submitter_contandcos.supplier_code
      // service call to get table data passing cos and regioncode



this.transactionService.getcontoncoslist(this.selected_cntr_ownrship_strat_code.ownrshp_strat_code,this.current_region_code_contandcos,this.cont_retype,supplier_gsdb_code).subscribe((contlist => {

  this.contlist_oncos=contlist

  // Get details TAB2
  // only wen the details exist and first time load of page compare the contlist_oncos with the container base from the get details and assign it to selected_container and table_selected_container
  // code here ...

 // this.suppcode=[];
/* if(this.suppget.length==0)
 {
   this.valueback=true;
 }
 else{
 this.valueback=false;
}*/



console.log('successful',this.contlist_oncos);



if(this.contlist_oncos.length!=0 && this.contlist_oncos!=null)
{


  this.cont_grid_text='';
  this.cont_present_table=true;
  for(let i=0;i<this.contlist_oncos.length;i++)
  {

    if(this.contlist_oncos[i].cntr_suffix!=null)
    {
    this.contlist_oncos[i].cntr_base_suffix=this.contlist_oncos[i].cntr_base+' - '+this.contlist_oncos[i].cntr_suffix
    }
    else{
      this.contlist_oncos[i].cntr_base_suffix=this.contlist_oncos[i].cntr_base
    }
    let length='';
    let width='';
    let height='';
    if(this.contlist_oncos[i].cntr_exterior_l!=null)
    {
      length=this.contlist_oncos[i].cntr_exterior_l.toString();
    }
    if(this.contlist_oncos[i].cntr_exterior_w!=null)
    {
      width=this.contlist_oncos[i].cntr_exterior_w.toString();
    }
    if(this.contlist_oncos[i].cntr_exterior_l!=null)
    {
      height=this.contlist_oncos[i].cntr_exterior_h.toString();
    }

    this.contlist_oncos[i].external_l_w_h=length+'x'+width+'x'+height;

console.log('oppppp',this.containerdetails);
    // if(this.contlist_oncos[i].cntr_base==this.containerdetails.cntr_base)
    if(this.contlist_oncos[i].cd_cntr==this.containerdetails.cd_cntr && this.enable_cntr_selectn_from_copy_cntr_dilg_notice==false)
    {
      this.table_selected_container=this.contlist_oncos[i];
      this.selected_container=this.contlist_oncos[i];
    }
  }

this.cont_cols = [
  { field: 'cntr_base_suffix', header:'Container Base - Suffix'},
  {field:'cntr_desc',header:'Container Description'},
  { field: 'exp_refundable', header:'E/R'},
  { field: 'external_l_w_h', header:'External LxWxH'},
  { field: 'card_call', header:'Card or Call'},
  { field: 'cntr_grp_desc', header:'Container Group ID'},

];
}


else{
  this.cont_present_table=false;
  this.cont_cols=[];
  this.cont_grid_text='No Container Found'
}

}

 ))



    }

/*
//each time when the value is selected store its cos , so used while reloading
    assign_selected_container_cos()
    {
      on-click="assign_selected_container_cos()" --- html code in p-table radio button
      console.log("insidetest");
      this.selected_container_cos=this.selected_cntr_ownrship_strat_code;
      this.selected_container_deviate_from_pfep=this.deviate_from_pfep;
    }
    */

  // clicking ok in dialog after seleing container
    onclick_ok_select_contandcos()
    {
      console.log('selected_container',this.selected_container);
      this.select_contcos_dialog=false;
      this.enable_cntr_selectn_from_copy_cntr_dilg_notice=false;


      if(sessionStorage.getItem(String(this.selectedpart_submitter_contandcos.proposalid))!=null)
    {

      this.enable_cntr_selectn_from_copy_cntr_dilg_notice=false;
      sessionStorage.removeItem(String(this.selectedpart_submitter_contandcos.proposalid))
      this.cntrdetforpost.parts_per_cntr=0;
      this.cntrdetforpost.part_per_shp_unit=0;
      this.cntrdetforpost.empty_trays_cntr_layrs=0;
      this.selectedEmptyTrays=0;
      this.cntrdetforpost.used_carry_ovr_pck="No";
      this.cntrdetforpost.max_layrs_shp_unt=0;
      this.cntrdetforpost.cntr_per_layer=0;
      this.cntrdetforpost.max_cntr_per_shp_unt=0;

      this.getactivecolorforregion();
      this.getActivePinPalletType();

      this.cntrdetforpost.cntr_material=this.selected_cntr_mat.cntr_matr_desc;
      console.log('Material in onclick_changeCOS',this.cntrdetforpost.cntr_material);
      this.cntrdetforpost.cntr_rck_colr=this.selectedcolor.color_desc;
      this.cntrdetforpost.pallettype="",
      this.cntrdetforpost.blanksperstack=0,
      this.cntrdetforpost.stacksperpallet=0,
      this.cntrdetforpost.palletpintyp="",
      this.cntrdetforpost.iscartonimcspec=null,
      this.cntrdetforpost.cartonburststrnth=null,
      this.cntrdetforpost.imcwtdisc=null,
      this.cntrdetforpost.shp_unt_desc="",
      this.selectedPinPalletType={cd_pinpallettyp:0,pinpallettyp:"",pinpallettyp_desc:"",active_stat:"",updated_by:"",updated_dt:"",active_stat_display:false};
        

      this.cntrdetforpost.bct=null;
      this.cntrdetforpost.ect=null;

    }
      this.cntrdetforpost.cntrselfrm='cntradm';
     const prev=this.table_selected_container;
      this.table_selected_container=this.selected_container;
      if(this.table_selected_container!=prev && prev!=null)
      {
        this.cntrdetforpost.parts_per_cntr=0;
       this.cntrdetforpost.part_per_shp_unit=0;
       this.cntrdetforpost.empty_trays_cntr_layrs=0;
       this.selectedEmptyTrays=0;
       this.cntrdetforpost.used_carry_ovr_pck="No";
       this.cntrdetforpost.max_layrs_shp_unt=0;
      this.cntrdetforpost.cntr_per_layer=0;
      this.cntrdetforpost.max_cntr_per_shp_unt=0;

       this.getactivecolorforregion();
       this.getActivePinPalletType();

       this.cntrdetforpost.cntr_material=this.selected_cntr_mat.cntr_matr_desc;
      console.log('Material in onclick_changeCOS2',this.cntrdetforpost.cntr_material,this.selected_cntr_mat);
       this.cntrdetforpost.cntr_rck_colr=this.selectedcolor.color_desc;
       this.cntrdetforpost.pallettype="";
       this.cntrdetforpost.blanksperstack=0,
       this.cntrdetforpost.stacksperpallet=0,
       this.cntrdetforpost.palletpintyp="",
       this.cntrdetforpost.iscartonimcspec=null,
       this.cntrdetforpost.cartonburststrnth=null,
       this.cntrdetforpost.imcwtdisc=null,
       this.cntrdetforpost.shp_unt_desc=""
       this.cntrdetforpost.bct=null;
       this.cntrdetforpost.ect=null;
       this.selectedPinPalletType={cd_pinpallettyp:0,pinpallettyp:"",pinpallettyp_desc:"",active_stat:"",updated_by:"",updated_dt:"",active_stat_display:false};
       this.selected_cntr_mat={cntr_matr_desc:"",cntr_matr_name:"",comp_type_flag:""}
      }
      this.selected_container_cos=this.selected_cntr_ownrship_strat_code;
      this.selected_container_deviate_from_pfep=this.deviate_from_pfep;
      this.selected_cont_retype=this.cont_retype

      if(this.table_selected_container != null )
      {

        if(this.table_selected_container.exp_refundable.toLowerCase() == "expendable")
	   {
			this.cntrdetforpost.woodispmcmplnt="Yes";
			this.cntrdetforpost.offstmpmartprsnt="Yes";
     }
     
     // if((prev!=null && this.table_selected_container!=prev) || prev == null )
      //  {
        console.log(this.table_selected_container.cntr_base,this.table_selected_container.cd_cntr);
        this.transactionService.editbutton_get_contr_details(this.table_selected_container.cntr_base,this.table_selected_container.cd_cntr).subscribe(ret=>{
          this.containerdetails=ret;
          this.iscntrselected=true;
          console.log('cntrdetails',this.containerdetails);
          this.tab2InValidationInit();
          this.cntrdetforpost.id_proposal=this.id_proposal_contandcos;
          this.cntrdetforpost.cntr_ret_type=this.containerdetails.exp_refundable;
          this.cntrdetforpost.cntr_base=this.containerdetails.cntr_base;
          this.cntrdetforpost.cntr_suff=this.containerdetails.cntr_suffix;
          this.cntrdetforpost.cntr_desc=this.containerdetails.cntr_desc;
          this.cntrdetforpost.cntr_ext_len=this.containerdetails.cntr_exterior_l;
          this.cntrdetforpost.cntr_width=this.containerdetails.cntr_exterior_w;
          this.cntrdetforpost.cntr_height=this.containerdetails.cntr_exterior_h;
          this.cntrdetforpost.cntr_material=this.containerdetails.cntr_matr_desc;
          console.log("material desc",this.cntrdetforpost.cntr_material)
          this.getContainerMaterial();
          this.cntrdetforpost.cntr_tar_weight=this.containerdetails.cntr_tare_wt;
          this.cntrdetforpost.cntr_per_layer=this.containerdetails.cntr_per_layer;
          this.cntrdetforpost.max_layrs_shp_unt=this.containerdetails.max_layers_shpg_unit;
          this.cntrdetforpost.max_cntr_per_shp_unt=this.containerdetails.max_cntr_per_shpg_unit;
          this.cntrdetforpost.shp_unt_ext_height=this.containerdetails.shpg_unit_exterior_h;
          this.cntrdetforpost.shp_unit_ext_len=this.containerdetails.shpg_unit_exterior_l;
          this.cntrdetforpost.shp_unt_ext_width=this.containerdetails.shpg_unit_exterior_w;
          this.cntrdetforpost.shp_unt_nestd_fld_height=this.containerdetails.shpg_unit_nstdorflded_ht;
          this.cntrdetforpost.card_call=this.containerdetails.card_call;
          this.cntrdetforpost.cntr_grp_id=this.containerdetails.cntr_grp_desc;
          this.cntrdetforpost.cntr_ownrshp_strat_code=this.selected_container_cos.ownrshp_strat_code;
          this.cntrdetforpost.cntrowner=this.containerdetails.ownrshp_catg;
          this.cntrdetforpost.ref_bompartmasterid=null;

          this.cntrdetforpost.cd_ownrshp_strat=this.selected_container_cos.cd_ownrshp_strat;
          this.cntrdetforpost.cd_cntr=this.containerdetails.cd_cntr;
          this.cntrdetforpost.cntr_rck_colr=this.selectedcolor.color_desc;


          this.cntrdetforpost.cntr_nsted_fld_height=this.containerdetails.collapsed_ht;
          this.cntrdetforpost.shp_unt_base=this.containerdetails.cntr_base;
          this.cntrdetforpost.shp_unt_suff=this.containerdetails.cntr_suffix;

          this.selectedEmptyTrays=0;
          this.cntrdetforpost.empty_trays_cntr_layrs=0;
          
          if(this.packagingproposal.savedtab<2)
          {
          this.cntrdetforpost.part_per_shp_unit=0;
          this.cntrdetforpost.parts_per_cntr=0;

          }
          this.cntrdetforpost.shp_unt_ret_type=this.containerdetails.exp_refundable;

          console.log("selected ownership",this.cntrdetforpost.cntr_ownrshp_strat_code);
          console.log("partspersu",this.cntrdetforpost.part_per_shp_unit);
          console.log("cntrdetpost-cd_cntr",this.cntrdetforpost.cd_cntr);
          console.log("cntrdetforpost-cd_ownsershipstrat",this.cntrdetforpost.cd_ownrshp_strat);
            this.enable_updt_cntr = false;
        
          if(this.selected_cntr_mat.cntr_matr_desc == "Corrugated Cardboard")
          {
            this.cntrColorRackDisable = true;
            console.log("Mat for disable - onclick", this.selected_cntr_mat);
          }
          else
          {
            this.cntrColorRackDisable = false;
          }

        })
     // }
      }
      else{

        this.iscntrselected=false;
        this.cntrdetailsinit();
        this.cntrdetforpostinit();
        this.tab2InValidationInit();
      }

      // call function to get cd_cntr using table_selected_container
    }

  // cancel in dialog
    onclick_cancel_select_contandcos()
    {
      this.select_contcos_dialog=false;
      if(this.table_selected_container!=undefined && this.iscntrselected==false)
      {
      this.iscntrselected=false;
      this.cntrdetailsinit();
      this.cntrdetforpostinit();
      this.tab2InValidationInit();
      }
      console.log('seleted container in cancel',this.table_selected_container);
    }

    updateRetCntrDets()
    {
      this.transactionService.editbutton_get_contr_details(this.containerdetails.cntr_base,this.containerdetails.cd_cntr).subscribe(ret=>{
        this.containerdetails_updated=ret;
      this.transactionService.loadtab2detailsifproposalexistalready(this.selectedpart_submitter_contandcos.proposalid).subscribe(check=>
        {
          this.existingtab2details=check;
          this.containerdetails.cntr_tare_wt = this.containerdetails_updated.cntr_tare_wt;
          this.containerdetails.cntr_desc = this.containerdetails_updated.cntr_desc;
          this.containerdetails.cntr_exterior_l = this.containerdetails_updated.cntr_exterior_l; 
          this.containerdetails.cntr_exterior_w = this.containerdetails_updated.cntr_exterior_w;
          this.containerdetails.cntr_exterior_h = this.containerdetails_updated.cntr_exterior_h;
          this.containerdetails.collapsed_ht = this.containerdetails_updated.collapsed_ht;
          this.containerdetails.max_cntr_per_shpg_unit = this.containerdetails_updated.max_cntr_per_shpg_unit

          console.log("Details", this.containerdetails);

          this.cntrdetforpost.cntr_tar_weight = this.containerdetails.cntr_tare_wt;
          this.cntrdetforpost.cntr_desc = this.containerdetails_updated.cntr_desc;
          this.cntrdetforpost.cntr_ext_len = this.containerdetails_updated.cntr_exterior_l;
          this.cntrdetforpost.cntr_width = this.containerdetails_updated.cntr_exterior_w;
          this.cntrdetforpost.cntr_height = this.containerdetails_updated.cntr_exterior_h;
          this.cntrdetforpost.cntr_nsted_fld_height = this.containerdetails_updated.collapsed_ht;
          this.cntrdetforpost.max_cntr_per_shp_unt = this.containerdetails_updated.max_cntr_per_shpg_unit;

          this.saveThisAndEnableThirdForm();
          
        })
        
      
      })

    }
    
    store_primary_part_exist()
    {

        if(this.current_region_code_prt=='NA')
        {

          sessionStorage.setItem('napackpropart', JSON.stringify(this.selectedpart_submitter_contandcos));
        }


        if(this.current_region_code_prt=='EU')
        {
          sessionStorage.setItem('eupackpropart', JSON.stringify(this.selectedpart_submitter_contandcos));
        }



        if(this.current_region_code_prt=='AP')
        {
          sessionStorage.setItem('appackpropart', JSON.stringify(this.selectedpart_submitter_contandcos));
        }



        if(this.current_region_code_prt=='SA')
        {
          sessionStorage.setItem('sapackpropart', JSON.stringify(this.selectedpart_submitter_contandcos));
        }
    }

    colorchanged()
    {
      this.cntrdetforpost.cntr_rck_colr=this.selectedcolor.color_desc;
    }


    palletPinTypeChange()
    {
      this.cntrdetforpost.palletpintyp=this.selectedPinPalletType.pinpallettyp;
    }

    Change_pallet_type()
    {

    if(this.cntrdetforpost.shp_unt_ret_type.toLowerCase()=='expendable')
    {
      this.cntrdetforpost.pallettype=this.pallet_type

    }
    else  if(this.cntrdetforpost.shp_unt_ret_type.toLowerCase()=='returnable'){
      this.cntrdetforpost.pallettype='Returnable'
    }
  }


  on_cntr_mat_change()
    {
      this.cntrdetforpost.cntr_material=this.selected_cntr_mat.cntr_matr_desc;
      console.log("Container Mat on click",this.cntrdetforpost.cntr_material)
      if(this.selected_cntr_mat.cntr_matr_desc == "Corrugated Cardboard")
      {
        this.cntrColorRackDisable = true;
        console.log("Mat for disable - onchnagw", this.selected_cntr_mat);
      }
      else
      {
        this.cntrColorRackDisable = false;
      }
    }

    on_change_measure_contcos()
    {


      console.log(this.edit_measure_contcos);
      console.log(this.containerdetails);

      if(this.unitom == 'E')
      {
        // this.get_measure = "lbs/inch";
        this.edit_measure_contcos = 'lbs/inch';
        this.disp_lbs = true;
      }
      else{
        // this.get_measure = "kg/cm";
        this.edit_measure_contcos = 'kg/cm';
        this.disp_kg = true;
      }

      // if(this.edit_measure_contcos=="lbs/inch")
      // {
      //   if(this.containerdetails.cntr_exterior_l!=null)
      //   {
      //     this.containerdetails.cntr_exterior_l=Math.round((0.393701*this.containerdetails.cntr_exterior_l)*1000)/1000;
      //   }
      //   if(this.containerdetails.cntr_exterior_w!=null)
      //   {
      //     this.containerdetails.cntr_exterior_w=Math.round((0.393701*this.containerdetails.cntr_exterior_w)*1000)/1000;
      //   }
      //   if(this.containerdetails.cntr_exterior_h!=null)
      //   {
      //     this.containerdetails.cntr_exterior_h=Math.round((0.393701*this.containerdetails.cntr_exterior_h)*1000)/1000;
      //   }
      //   if(this.containerdetails.shpg_unit_exterior_l!=null)
      //   {
      //     this.containerdetails.shpg_unit_exterior_l=Math.round((0.393701*this.containerdetails.shpg_unit_exterior_l)*1000)/1000;
      //   }
      //   if(this.containerdetails.shpg_unit_exterior_w!=null)
      //   {
      //     this.containerdetails.shpg_unit_exterior_w=Math.round((0.393701*this.containerdetails.shpg_unit_exterior_w)*1000)/1000;
      //   }
      //   if(this.containerdetails.shpg_unit_exterior_h!=null)
      //   {
      //     this.containerdetails.shpg_unit_exterior_h=Math.round((0.393701*this.containerdetails.shpg_unit_exterior_h)*1000)/1000;
      //   }
      //   if(this.containerdetails.shpg_unit_nstdorflded_ht!=null)
      //   {
      //     this.containerdetails.shpg_unit_nstdorflded_ht=Math.round((0.393701*this.containerdetails.shpg_unit_nstdorflded_ht)*1000)/1000;
      //   }
      //   if(this.containerdetails.cntr_tare_wt!=null)
      //   {
      //     this.containerdetails.cntr_tare_wt=Math.round((2.20462*this.containerdetails.cntr_tare_wt)*1000)/1000;
      //   }
      //   if(this.cntrdetforpost.cntr_nsted_fld_height!=null)
      //   {
      //     this.cntrdetforpost.cntr_nsted_fld_height=Math.round((0.393701*this.cntrdetforpost.cntr_nsted_fld_height)*1000)/1000;

      //   }



      // }

      // else if(this.edit_measure_contcos=="kg/cm")
      // {


      //   if(this.containerdetails.cntr_exterior_l!=null)
      //   {
      //     this.containerdetails.cntr_exterior_l=Math.round((2.54*this.containerdetails.cntr_exterior_l)*1000)/1000;
      //   }
      //   if(this.containerdetails.cntr_exterior_w!=null)
      //   {
      //     this.containerdetails.cntr_exterior_w=Math.round((2.54*this.containerdetails.cntr_exterior_w)*1000)/1000;
      //   }
      //   if(this.containerdetails.cntr_exterior_h!=null)
      //   {
      //     this.containerdetails.cntr_exterior_h=Math.round((2.54*this.containerdetails.cntr_exterior_h)*1000)/1000;
      //   }
      //   if(this.containerdetails.shpg_unit_exterior_l!=null)
      //   {
      //     this.containerdetails.shpg_unit_exterior_l=Math.round((2.54*this.containerdetails.shpg_unit_exterior_l)*1000)/1000;
      //   }
      //   if(this.containerdetails.shpg_unit_exterior_w!=null)
      //   {
      //     this.containerdetails.shpg_unit_exterior_w=Math.round((2.54*this.containerdetails.shpg_unit_exterior_w)*1000)/1000;
      //   }
      //   if(this.containerdetails.shpg_unit_exterior_h!=null)
      //   {
      //     this.containerdetails.shpg_unit_exterior_h=Math.round((2.54*this.containerdetails.shpg_unit_exterior_h)*1000)/1000;
      //   }
      //   if(this.containerdetails.shpg_unit_nstdorflded_ht!=null)
      //   {
      //     this.containerdetails.shpg_unit_nstdorflded_ht=Math.round((2.54*this.containerdetails.shpg_unit_nstdorflded_ht)*1000)/1000;
      //   }
      //   if(this.containerdetails.cntr_tare_wt!=null)
      //   {
      //     this.containerdetails.cntr_tare_wt=Math.round((0.453592*this.containerdetails.cntr_tare_wt)*1000)/1000;
      //   }
      //   if(this.cntrdetforpost.cntr_nsted_fld_height!=null)
      //   {
      //     this.cntrdetforpost.cntr_nsted_fld_height=Math.round((2.54*this.cntrdetforpost.cntr_nsted_fld_height)*1000)/1000;

      //   }

      // }





    }



    on_change_measure_contcos_carton()
    {
      console.log(this.edit_measure_contcos_carton);
      // after carton is added do it for kpato psi and psi to kpa
    }



    on_click_cntrdetails(cntrdata:any)
    {
      this.enabledetails_cntr=true;
      // this.get_measure="lbs/inch";

      if(this.unitom == 'E')
      {
        this.get_measure = 'lbs/inch';
        this.disp_lbs = true;
        // this.edit_measure_contcos = "lbs/inch";
      }
      else{
        this.get_measure = 'kg/cm';
        this.disp_kg = true;
        // this.edit_measure_contcos = "kg/cm";
      }


      console.log(cntrdata);

      this.transactionService.editbutton_get_contr_details(cntrdata.cntr_base,cntrdata.cd_cntr).subscribe(cntval=>{
        this.cntrdetails_display=cntval;
      })


    }



on_change_get_measure()
{


  console.log(this.get_measure);

  if(this.unitom == 'E')
  {
    this.get_measure = 'lbs/inch';
    // this.edit_measure_contcos = "lbs/inch";
  }
  else{
    this.get_measure = 'kg/cm';
    // this.edit_measure_contcos = "kg/cm";
  }

//   if(this.get_measure=="lbs/inch")
//   {

//       console.log("inside lbs")
// if(this.cntrdetails_display.collapsed_ht!=null)
// {
//     this.cntrdetails_display.collapsed_ht=Math.round((0.393701*this.cntrdetails_display.collapsed_ht)*1000)/1000;
// }
// if(this.cntrdetails_display.cntr_interior_l!=null)
// {
//     this.cntrdetails_display.cntr_interior_l=Math.round((0.393701*this.cntrdetails_display.cntr_interior_l)*1000)/1000;
// }
// if(this.cntrdetails_display.cntr_interior_w!=null)
// {
//         this.cntrdetails_display.cntr_interior_w=Math.round((0.393701*this.cntrdetails_display.cntr_interior_w)*1000)/1000;
// }
// if(this.cntrdetails_display.cntr_interior_h!=null)
// {
//         this.cntrdetails_display.cntr_interior_h=Math.round((0.393701*this.cntrdetails_display.cntr_interior_h)*1000)/1000;
// }
// if(this.cntrdetails_display.cntr_tare_wt!=null)
// {
//         this.cntrdetails_display.cntr_tare_wt=Math.round((2.20462*this.cntrdetails_display.cntr_tare_wt)*1000)/1000;
// }
// if(this.cntrdetails_display.cntr_capacity!=null)
// {
//         this.cntrdetails_display.cntr_capacity=Math.round((0.393701*0.393701*0.393701*this.cntrdetails_display.cntr_capacity)*1000)/1000;
// }

// if(this.cntrdetails_display.cntr_exterior_l!=null)
// {
//          this.cntrdetails_display.cntr_exterior_l=Math.round((0.393701*this.cntrdetails_display.cntr_exterior_l)*1000)/1000;
// }
// if(this.cntrdetails_display.cntr_exterior_w!=null)
// {
//         this.cntrdetails_display.cntr_exterior_w=Math.round((0.393701*this.cntrdetails_display.cntr_exterior_w)*1000)/1000;
// }
// if(this.cntrdetails_display.cntr_exterior_h!=null)
// {
//         this.cntrdetails_display.cntr_exterior_h=Math.round((0.393701*this.cntrdetails_display.cntr_exterior_h)*1000)/1000;
// }
// if(this.cntrdetails_display.shpg_unit_exterior_l!=null)
// {
//        this.cntrdetails_display.shpg_unit_exterior_l=Math.round((0.393701*this.cntrdetails_display.shpg_unit_exterior_l)*1000)/1000;
// }
// if(this.cntrdetails_display.shpg_unit_exterior_w!=null)
// {
//         this.cntrdetails_display.shpg_unit_exterior_w=Math.round((0.393701*this.cntrdetails_display.shpg_unit_exterior_w)*1000)/1000;
// }
// if(this.cntrdetails_display.shpg_unit_exterior_h!=null)
// {
//         this.cntrdetails_display.shpg_unit_exterior_h=Math.round((0.393701*this.cntrdetails_display.shpg_unit_exterior_h)*1000)/1000;
// }
// if(this.cntrdetails_display.shpg_unit_nstdorflded_ht!=null)
// {
//         this.cntrdetails_display.shpg_unit_nstdorflded_ht=Math.round((0.393701*this.cntrdetails_display.shpg_unit_nstdorflded_ht)*1000)/1000;
// }
// if(this.cntrdetails_display.shpg_unit_tare_wt!=null)
// {
//         this.cntrdetails_display.shpg_unit_tare_wt=Math.round((2.20462*this.cntrdetails_display.shpg_unit_tare_wt)*1000)/1000;
// }
// if(this.cntrdetails_display.shpg_unit_stack_ht!=null)
// {
//         this.cntrdetails_display.shpg_unit_stack_ht=Math.round((0.393701*this.cntrdetails_display.shpg_unit_stack_ht)*1000)/1000;
// }
// if(this.cntrdetails_display.warehouse_stack_ht!=null)
// {
//         this.cntrdetails_display.warehouse_stack_ht=Math.round((0.393701*this.cntrdetails_display.warehouse_stack_ht)*1000)/1000;
// }



// }

//   else if(this.get_measure=="kg/cm")
//   {


//       console.log("inside kg")

//     if(this.cntrdetails_display.collapsed_ht!=null)
//     {
//         this.cntrdetails_display.collapsed_ht=Math.round((2.54*this.cntrdetails_display.collapsed_ht)*1000)/1000;
//     }
//     if(this.cntrdetails_display.cntr_interior_l!=null)
//     {
//         this.cntrdetails_display.cntr_interior_l=Math.round((2.54*this.cntrdetails_display.cntr_interior_l)*1000)/1000;
//     }
//     if(this.cntrdetails_display.cntr_interior_w!=null)
//     {
//             this.cntrdetails_display.cntr_interior_w=Math.round((2.54*this.cntrdetails_display.cntr_interior_w)*1000)/1000;
//     }
//     if(this.cntrdetails_display.cntr_interior_h!=null)
//     {
//             this.cntrdetails_display.cntr_interior_h=Math.round((2.54*this.cntrdetails_display.cntr_interior_h)*1000)/1000;
//     }
//     if(this.cntrdetails_display.cntr_tare_wt!=null)
//     {
//             this.cntrdetails_display.cntr_tare_wt=Math.round((0.453592*this.cntrdetails_display.cntr_tare_wt)*1000)/1000;
//     }
//     if(this.cntrdetails_display.cntr_capacity!=null)
//     {
//             this.cntrdetails_display.cntr_capacity=Math.round((2.54*2.54*2.54*this.cntrdetails_display.cntr_capacity)*1000)/1000;
//     }

//     if(this.cntrdetails_display.cntr_exterior_l!=null)
//     {
//              this.cntrdetails_display.cntr_exterior_l=Math.round((2.54*this.cntrdetails_display.cntr_exterior_l)*1000)/1000;
//     }
//     if(this.cntrdetails_display.cntr_exterior_w!=null)
//     {
//             this.cntrdetails_display.cntr_exterior_w=Math.round((2.54*this.cntrdetails_display.cntr_exterior_w)*1000)/1000;
//     }
//     if(this.cntrdetails_display.cntr_exterior_h!=null)
//     {
//             this.cntrdetails_display.cntr_exterior_h=Math.round((2.54*this.cntrdetails_display.cntr_exterior_h)*1000)/1000;
//     }
//     if(this.cntrdetails_display.shpg_unit_exterior_l!=null)
//     {
//            this.cntrdetails_display.shpg_unit_exterior_l=Math.round((2.54*this.cntrdetails_display.shpg_unit_exterior_l)*1000)/1000;
//     }
//     if(this.cntrdetails_display.shpg_unit_exterior_w!=null)
//     {
//             this.cntrdetails_display.shpg_unit_exterior_w=Math.round((2.54*this.cntrdetails_display.shpg_unit_exterior_w)*1000)/1000;
//     }
//     if(this.cntrdetails_display.shpg_unit_exterior_h!=null)
//     {
//             this.cntrdetails_display.shpg_unit_exterior_h=Math.round((2.54*this.cntrdetails_display.shpg_unit_exterior_h)*1000)/1000;
//     }
//     if(this.cntrdetails_display.shpg_unit_nstdorflded_ht!=null)
//     {
//             this.cntrdetails_display.shpg_unit_nstdorflded_ht=Math.round((2.54*this.cntrdetails_display.shpg_unit_nstdorflded_ht)*1000)/1000;
//     }
//     if(this.cntrdetails_display.shpg_unit_tare_wt!=null)
//     {
//             this.cntrdetails_display.shpg_unit_tare_wt=Math.round((0.453592*this.cntrdetails_display.shpg_unit_tare_wt)*1000)/1000;
//     }
//     if(this.cntrdetails_display.shpg_unit_stack_ht!=null)
//     {
//             this.cntrdetails_display.shpg_unit_stack_ht=Math.round((2.54*this.cntrdetails_display.shpg_unit_stack_ht)*1000)/1000;
//     }
//     if(this.cntrdetails_display.warehouse_stack_ht!=null)
//     {
//             this.cntrdetails_display.warehouse_stack_ht=Math.round((2.54*this.cntrdetails_display.warehouse_stack_ht)*1000)/1000;
//     }




//   }
}
 conTareWgtValidation()
{
  const cntr_tar_wt = String(this.cntrdetforpost.cntr_tar_weight).split('.');
  if(this.cntrdetforpost.cntr_tar_weight==null || this.cntrdetforpost.cntr_tar_weight==0)
  {
    this.contarewgtInvalid=true;
    this.contarewgtInValidReason='Required';
  }
    else if(!String(this.cntrdetforpost.cntr_tar_weight).includes('.'))
    {
        /* else if( String(this.cntrdetforpost.cntr_tar_weight).length>11)
        {
          this.contarewgtInvalid=true;
          this.contarewgtInValidReason="Max Length Of Characters can be 10";
        } */
        if(this.cntrdetforpost.cntr_tar_weight<0 || this.cntrdetforpost.cntr_tar_weight>999999.999)
        {
          this.contarewgtInvalid=true;
          this.contarewgtInValidReason='Should be of range 0 to 999999.999';
        }
      else
        {
          this.contarewgtInvalid=false;
          this.contarewgtInValidReason=null;
        }
    }
	else if(String(this.cntrdetforpost.cntr_tar_weight).includes('.'))
  	  {

        /* if(String(cntr_tar_wt[0]).length<0 || String(cntr_tar_wt[0]).length>6)
        {
          this.contarewgtInvalid=true;
          this.contarewgtInValidReason="Should be of range 1 to 999999.999";
        }
        else*/ if(String(cntr_tar_wt[1]).length<0 || String(cntr_tar_wt[1]).length>3)
        {
          this.contarewgtInvalid=true;
          this.contarewgtInValidReason='Max Length Of decimal values can be 3';
        }
        else
        {
          this.contarewgtInvalid=false;
          this.contarewgtInValidReason=null;
        }
    }
    else
    {
    this.contarewgtInvalid=false;
    this.contarewgtInValidReason=null;
    }
}
conExtLenValidation()
{
  const cont_ext_l = String(this.cntrdetforpost.cntr_ext_len).split('.');
  if(this.cntrdetforpost.cntr_ext_len==null || this.cntrdetforpost.cntr_ext_len==0)
  {
    this.contextlenInvalid=true;
    this.contextlenInValidReason='Required';
  }
  else
   if(!String(this.cntrdetforpost.cntr_ext_len).includes('.'))
    {
     if(this.cntrdetforpost.cntr_ext_len>this.cntrdetforpost.shp_unit_ext_len)
        {
        this.contextlenInvalid=true;
        this.contextlenInValidReason='Container Exterior Length cannot be greater than Shipping Unit Exterior Length';
        }
        else if(this.cntrdetforpost.cntr_ext_len<0 || this.cntrdetforpost.cntr_ext_len>9999.999 )
        {
          this.contextlenInvalid=true;
          this.contextlenInValidReason='Should be of range 0 to 9999.999';
        }
        /*else if( String(this.cntrdetforpost.cntr_ext_len).length>8)
        {
          this.contextlenInvalid=true;
          this.contextlenInValidReason="Max Length Of Characters can be 8";
        }*/

        else
        {
          this.contextlenInvalid=false;
          this.contextlenInValidReason=null;
        }
    }
    else if(String(this.cntrdetforpost.cntr_ext_len).includes('.'))
    {
        if(String(cont_ext_l[0]).length<0 || String(cont_ext_l[0]).length>4)
        {
          this.contextlenInvalid=true;
          this.contextlenInValidReason='Should be of range 0 to 9999.999';
        }
        else if(this.cntrdetforpost.cntr_ext_len<0 || this.cntrdetforpost.cntr_ext_len>9999.999)
        {
          this.contextlenInvalid=true;
          this.contextlenInValidReason='Should be of range 0 to 9999.999';
        }
        else if(String(cont_ext_l[1]).length<0 || String(cont_ext_l[1]).length>3)
        {
          this.contextlenInvalid=true;
          this.contextlenInValidReason='Max Length Of decimal values can be 3';
        }
        else if(this.cntrdetforpost.cntr_ext_len>this.cntrdetforpost.shp_unit_ext_len)
        {
          this.contextlenInvalid=true;
          this.contextlenInValidReason='Container Exterior Length cannot be greater than Shipping Unit Exterior Length';
        }
        else
        {
          this.contextlenInvalid=false;
          this.contextlenInValidReason=null;
        }
    }
    else
    {
    this.contextlenInvalid=false;
    this.contextlenInValidReason=null;
  }
}
conExtWidValidation()
{
  const cont_ext_w = String(this.cntrdetforpost.cntr_width).split('.');
  if(this.cntrdetforpost.cntr_width==null || this.cntrdetforpost.cntr_width==0)
  {
    this.contextwidInvalid=true;
    this.contextwidInValidReason='Required';
  }
  else
   if(!String(this.cntrdetforpost.cntr_width).includes('.'))
    {
     if(this.cntrdetforpost.cntr_width>this.cntrdetforpost.shp_unt_ext_width)
        {
        this.contextwidInvalid=true;
        this.contextwidInValidReason='Container Exterior Width cannot be greater than Shipping Unit Exterior Width';
        }
        else if(this.cntrdetforpost.cntr_width<0 || this.cntrdetforpost.cntr_width>9999.999 )
        {
          this.contextwidInvalid=true;
          this.contextwidInValidReason='Should be of range 0 to 9999.999';
        }
       /* else if( String(this.cntrdetforpost.cntr_width).length>8)
        {
          this.contextwidInvalid=true;
          this.contextwidInValidReason="Max Length Of Characters can be 8";
        }*/

        else
        {
          this.contextwidInvalid=false;
          this.contextwidInValidReason=null;
        }
    }
    else if(String(this.cntrdetforpost.cntr_width).includes('.'))
    {
        if(String(cont_ext_w[0]).length<0 || String(cont_ext_w[0]).length>4)
        {
          this.contextwidInvalid=true;
          this.contextwidInValidReason='Should be of range 0 to 9999.999';
        }
        else if(this.cntrdetforpost.cntr_width<0 || this.cntrdetforpost.cntr_width>9999.999)
        {
          this.contextwidInvalid=true;
          this.contextwidInValidReason='Should be of range 0 to 9999.999';
        }
        else if(String(cont_ext_w[1]).length<0 || String(cont_ext_w[1]).length>3)
        {
          this.contextwidInvalid=true;
          this.contextwidInValidReason='Max Length Of decimal values can be 3';
        }
        else if(this.cntrdetforpost.cntr_width>this.cntrdetforpost.shp_unt_ext_width)
        {
          this.contextwidInvalid=true;
          this.contextwidInValidReason='Container Exterior Width cannot be greater than Shipping Unit Exterior Width';
        }
        else
        {
          this.contextwidInvalid=false;
          this.contextwidInValidReason=null;
        }
    }
    else
    {
    this.contextwidInvalid=false;
    this.contextwidInValidReason=null;
  }
}
conExtHgtValidation()
{
  const cont_ext_h = String(this.cntrdetforpost.cntr_height).split('.');
  if(this.cntrdetforpost.cntr_height==null || this.cntrdetforpost.cntr_height==0)
  {
    this.contexthgtInvalid=true;
    this.contexthgtInValidReason='Required';
  }
   else if(!String(this.cntrdetforpost.cntr_height).includes('.'))
    {
    /*if(this.cntrdetforpost.cntr_height>this.cntrdetforpost.shp_unt_ext_height)
        {
        this.contexthgtInvalid=true;
        this.contexthgtInValidReason='Container Exterior Height cannot be greater than Shipping Unit Exterior Heighth';
        }
        else*/
         if(this.cntrdetforpost.cntr_height<0 || this.cntrdetforpost.cntr_height>9999.999 )
        {
          this.contexthgtInvalid=true;
          this.contexthgtInValidReason='Should be of range 0 to 9999.999';
        }
        /*else if( String(this.cntrdetforpost.cntr_height).length>9)
        {
          this.contexthgtInvalid=true;
          this.contexthgtInValidReason="Max Length Of Characters can be 8";
        }*/
        else
        {
          this.contexthgtInvalid=false;
          this.contexthgtInValidReason=null;
        }
    }
    else if(String(this.cntrdetforpost.cntr_height).includes('.'))
    {
        if(String(cont_ext_h[0]).length<0 || String(cont_ext_h[0]).length>4)
        {
          this.contexthgtInvalid=true;
          this.contexthgtInValidReason='Should be of range 0 to 9999.999';
        }
        else if(this.cntrdetforpost.cntr_height<0 || this.cntrdetforpost.cntr_height>9999.999)
        {
          this.contexthgtInvalid=true;
          this.contexthgtInValidReason='Should be of range 0 to 9999.999';
        }
        else if(String(cont_ext_h[1]).length<0 || String(cont_ext_h[1]).length>3)
        {
          this.contexthgtInvalid=true;
          this.contexthgtInValidReason='Max Length Of decimal values can be 3';
        }
        /*else if(this.cntrdetforpost.cntr_height>this.cntrdetforpost.shp_unt_ext_height)
        {
          this.contexthgtInvalid=true;
          this.contexthgtInValidReason="Container Exterior LHeight cannot be greater than Shipping Unit Exterior Height"; 
        }*/
        else
        {
          this.contexthgtInvalid=false;
          this.contexthgtInValidReason=null;
        }
    }
    else
    {
    this.contexthgtInvalid=false;
    this.contexthgtInValidReason=null;
    }
}
contNestedFldedHtValidation()
{
  if(this.cntrdetforpost.cntr_nsted_fld_height==null || this.cntrdetforpost.cntr_nsted_fld_height==0)
  {
    this.contNestedHtInValid=true;
    this.contNestedHtInValidReason='Required';
  }
  else if(this.cntrdetforpost.cntr_nsted_fld_height<0 || this.cntrdetforpost.cntr_nsted_fld_height>999.999)
  {
    this.contNestedHtInValid=true;
    this.contNestedHtInValidReason='Should be of range 0 to 999.999';// took validation from collapsed height in container admin have to verify and might change
  }
 /* else if(this.cntrdetforpost.cntr_nsted_fld_height>this.cntrdetforpost.cntr_height)
  {
    this.contNestedHtInValid=true;
    this.contNestedHtInValidReason="Nested Folded Height cannot be Greater than Exterior height";
  }*/
  else
  {
    this.contNestedHtInValid=false;
    this.contNestedHtInValidReason=null;
  }
}
partsPerContainerValidation()
{
  if(this.cntrdetforpost.parts_per_cntr == null || this.cntrdetforpost.parts_per_cntr == 0)
  {
    this.partsprcntrInValid = true;
    this.partsprcntrInValidReason = 'Required';
  }
  else
  {
    this.partsprcntrInValid = false;
    this.partsprcntrInValidReason = null;
  }
}
maxlayprshpunitValidation()
{
  if(this.cntrdetforpost.max_layrs_shp_unt == null || this.cntrdetforpost.max_layrs_shp_unt == 0)
  {
    this.maxlayerprshpInValid = true;
    this.maxlayerprshpInValidReason = 'Required';
  }
  else
  {
    this.maxlayerprshpInValid = false;
    this.maxlayerprshpInValidReason = null;
  }
}

cntrPerLayersValidation()
{
  if(this.cntrdetforpost.cntr_per_layer == null || this.cntrdetforpost.cntr_per_layer == 0)
  {
    this.cntrperlaysInValid = true;
    this.cntrperlaysInValidReason = 'Required';
  }
  else
  {
    this.cntrperlaysInValid = false;
    this.cntrperlaysInValidReason = null;
  }
}
suExtLenValidation()
{
  const shp_ext_l = String(this.cntrdetforpost.shp_unit_ext_len).split('.');
  /* if(this.cntrdetforpost.shp_unit_ext_len<this.cntrdetforpost.cntr_ext_len)
	{
	this.suextlenInvalid=true;
	this.suextlenInValidReason="Shipping Unit Exterior Length cannot be less than  Container Exterior Length";
  } */
  if(this.cntrdetforpost.shp_unit_ext_len==null || this.cntrdetforpost.shp_unit_ext_len==0)
  {
    this.suextlenInvalid=true;
    this.suextlenInValidReason='Required';
  }
  else
  {
   if(!String(this.cntrdetforpost.shp_unit_ext_len).includes('.'))
    {
        if(this.cntrdetforpost.shp_unit_ext_len<1 || this.cntrdetforpost.shp_unit_ext_len>9999.999 )
        {
          this.suextlenInvalid=true;
          this.suextlenInValidReason='Should be of range 1 to 9999.999';
        }
        else if( String(this.cntrdetforpost.shp_unit_ext_len).length>8)
        {
          this.suextlenInvalid=true;
          this.suextlenInValidReason='Max Length Of Characters can be 8';
        }
        else if(this.cntrdetforpost.shp_unit_ext_len>0 &&this.cntrdetforpost.shp_unit_ext_len<this.cntrdetforpost.cntr_ext_len)
		{
		this.suextlenInvalid=true;
		this.suextlenInValidReason='Shipping Unit Exterior Length cannot be less than  Container Exterior Length';
		}

        else
        {
          this.suextlenInvalid=false;
          this.suextlenInValidReason=null;
        }
    }
    else if(String(this.cntrdetforpost.shp_unit_ext_len).includes('.'))
    {
        if(String(shp_ext_l[0]).length<0 || String(shp_ext_l[0]).length>4)
        {
          this.suextlenInvalid=true;
          this.suextlenInValidReason='Should be of range 1 to 9999.999';
        }
        else if(this.cntrdetforpost.shp_unit_ext_len<1 || this.cntrdetforpost.shp_unit_ext_len>9999.999)
        {
          this.suextlenInvalid=true;
          this.suextlenInValidReason='Should be of range 1 to 9999.999';
        }
        else if(String(shp_ext_l[1]).length<0 || String(shp_ext_l[1]).length>3)
        {
          this.suextlenInvalid=true;
          this.suextlenInValidReason='Max Length Of decimal values can be 3';
        }
        else if(this.cntrdetforpost.shp_unit_ext_len>0 && this.cntrdetforpost.shp_unit_ext_len<this.cntrdetforpost.cntr_ext_len)
        {
        this.suextlenInvalid=true;
        this.suextlenInValidReason='Shipping Unit Exterior Length cannot be less than  Container Exterior Length';
        }
        else
        {
          this.suextlenInvalid=false;
          this.suextlenInValidReason=null;
        }
    }

  }
}
suExtWidValidation()
{
  const shp_ext_w = String(this.cntrdetforpost.shp_unt_ext_width).split('.');
 /* if(this.cntrdetforpost.shp_unt_ext_width<this.cntrdetforpost.cntr_width)
  {
    this.suextwidInvalid=true;
    this.suextwidInValidReason="Shipping Unit Exterior Width cannot be less than  Container Exterior Width";
	}
   else */
   if(this.cntrdetforpost.shp_unt_ext_width==null || this.cntrdetforpost.shp_unt_ext_width==0)
    {
      this.suextwidInvalid=true;
      this.suextwidInValidReason='Required';
    }
    else{
      if(!String(this.cntrdetforpost.shp_unt_ext_width).includes('.'))
        {

         if(this.cntrdetforpost.shp_unt_ext_width<1 || this.cntrdetforpost.shp_unt_ext_width>9999.999 )
          {
            this.suextwidInvalid=true;
            this.suextwidInValidReason='Should be of range 1 to 9999.999';
          }
        else if( String(this.cntrdetforpost.shp_unt_ext_width).length>8)
        {
          this.suextwidInvalid=true;
          this.suextwidInValidReason='Max Length Of Characters can be 8';
        }
        else if(this.cntrdetforpost.shp_unt_ext_width>0 && this.cntrdetforpost.shp_unt_ext_width<this.cntrdetforpost.cntr_width)
		{
		this.suextwidInvalid=true;
		this.suextwidInValidReason='Shipping Unit Exterior Width cannot be less than  Container Exterior Width';
        }

        else
        {
          this.suextwidInvalid=false;
          this.suextwidInValidReason=null;
        }
    }
    else if(String(this.cntrdetforpost.shp_unt_ext_width).includes('.'))
    {
        if(String(shp_ext_w[0]).length<0 || String(shp_ext_w[0]).length>4)
        {
          this.suextwidInvalid=true;
          this.suextwidInValidReason='Should be of range 1 to 9999.999';
        }
        else if(this.cntrdetforpost.shp_unt_ext_width<1 || this.cntrdetforpost.shp_unt_ext_width>9999.999)
        {
          this.suextwidInvalid=true;
          this.suextwidInValidReason='Should be of range 1 to 9999.999';
        }
        else if(String(shp_ext_w[1]).length<0 || String(shp_ext_w[1]).length>3)
        {
          this.suextwidInvalid=true;
          this.suextwidInValidReason='Max Length Of decimal values can be 3';
        }
        else if(this.cntrdetforpost.shp_unt_ext_width>0 && this.cntrdetforpost.shp_unt_ext_width<this.cntrdetforpost.cntr_width)
		{
		this.suextwidInvalid=true;
		this.suextwidInValidReason='Shipping Unit Exterior Width cannot be less than  Container Exterior Width';
        }
        else
        {
          this.suextwidInvalid=false;
          this.suextwidInValidReason=null;
        }
    }
  }
}
suExtHgtValidation()
{
  const shp_ext_h = String(this.cntrdetforpost.shp_unt_ext_height).split('.');
 /* if(this.cntrdetforpost.shp_unt_ext_height<this.cntrdetforpost.cntr_height)
  {
    this.suexthgtInvalid=true;
    this.suexthgtInValidReason="Shipping Unit Exterior Height cannot be less than  Container Exterior Height";
  } */
  if(this.cntrdetforpost.shp_unt_ext_height==null || this.cntrdetforpost.shp_unt_ext_height==0)
  {
    this.suexthgtInvalid=true;
    this.suexthgtInValidReason='Required';
  }
  else
  {
   if(!String(this.cntrdetforpost.shp_unt_ext_height).includes('.'))
    {
         if(this.cntrdetforpost.shp_unt_ext_height<1 || this.cntrdetforpost.shp_unt_ext_height>9999.999 )
        {
          this.suexthgtInvalid=true;
          this.suexthgtInValidReason='Should be of range 1 to 9999.999';
        }
        else if( String(this.cntrdetforpost.shp_unt_ext_height).length>8)
        {
          this.suexthgtInvalid=true;
          this.suexthgtInValidReason='Max Length Of Characters can be 8';
        }
        else if(this.cntrdetforpost.shp_unt_ext_height>0 && this.cntrdetforpost.shp_unt_ext_height<this.cntrdetforpost.cntr_height)
        {
        this.suexthgtInvalid=true;
        this.suexthgtInValidReason='Shipping Unit Exterior Height cannot be less than  Container Exterior Height';
        }

        else
        {
          this.suexthgtInvalid=false;
          this.suexthgtInValidReason=null;
        }
    }
    else if(String(this.cntrdetforpost.shp_unt_ext_height).includes('.'))
    {
        if(String(shp_ext_h[0]).length<0 || String(shp_ext_h[0]).length>4)
        {
          this.suexthgtInvalid=true;
          this.suexthgtInValidReason='Should be of range 1 to 9999.999';
        }
        else if(this.cntrdetforpost.shp_unt_ext_height<1 || this.cntrdetforpost.shp_unt_ext_height>9999.999)
        {
          this.suexthgtInvalid=true;
          this.suexthgtInValidReason='Should be of range 1 to 9999.999';
        }
        else if(String(shp_ext_h[1]).length<0 || String(shp_ext_h[1]).length>3)
        {
          this.suexthgtInvalid=true;
          this.suexthgtInValidReason='Max Length Of decimal values can be 3';
        }
        else if(this.cntrdetforpost.shp_unt_ext_height>0 && this.cntrdetforpost.shp_unt_ext_height<this.cntrdetforpost.cntr_height)
        {
        this.suexthgtInvalid=true;
        this.suexthgtInValidReason='Shipping Unit Exterior Height cannot be less than  Container Exterior Height';
        }
        else
        {
          this.suexthgtInvalid=false;
          this.suexthgtInValidReason=null;
        }
    }
  }
}


suNestedFldedHtValidation()
{
  if(this.cntrdetforpost.shp_unt_nestd_fld_height==null || this.cntrdetforpost.shp_unt_nestd_fld_height==0)
  {
    this.suNestedHtInValid=true;
    this.suNestedHtInValidReason='Required';
  }
  else if(this.cntrdetforpost.shp_unt_nestd_fld_height<1 || this.cntrdetforpost.shp_unt_nestd_fld_height>99999)
  {
    this.suNestedHtInValid=true;
    this.suNestedHtInValidReason='Should be of range 1 to 99999';
  }
  else if(this.cntrdetforpost.shp_unt_nestd_fld_height>0 && this.cntrdetforpost.shp_unt_ext_height>0 && this.cntrdetforpost.shp_unt_nestd_fld_height>this.cntrdetforpost.shp_unt_ext_height)
  {
    this.suNestedHtInValid=true;
    this.suNestedHtInValidReason='Nested Folded Height cannot be Greater than Exterior height';
  }
  else
  {
    this.suNestedHtInValid=false;
    this.suNestedHtInValidReason=null;
  }
}

checkAndSaveNext()
{
  console.log(this.cntrdetforpost);
  console.log(this.selectedpart_submitter_contandcos)
  this.conTareWgtValidation();
  this.conExtLenValidation();
  this.conExtWidValidation();
  this.conExtHgtValidation();
  this.partsPerContainerValidation();
  this.maxlayprshpunitValidation();
  this.cntrPerLayersValidation();
  this.suExtLenValidation();
  this.suExtWidValidation();
  //this.suExtHgtValidation();
  //this.suNestedFldedHtValidation();
  this.contNestedFldedHtValidation();
  
  console.log("cntrsupost",this.cntrdetforpost);
  console.log(!this.contarewgtInvalid  , !this.contextlenInvalid ,!this.contextwidInvalid ,
    !this.contexthgtInvalid ,  !this.suextlenInvalid ,!this.suextwidInvalid ,
    !this.suexthgtInvalid ,!this.suNestedHtInValid , !this.contNestedHtInValid)

  if(!this.contarewgtInvalid  &&  !this.contextlenInvalid && !this.contextwidInvalid && !this.partsprcntrInValid && 
    !this.maxlayerprshpInValid && !this.cntrperlaysInValid  && !this.contexthgtInvalid &&  !this.suextlenInvalid && !this.suextwidInvalid &&
    !this.contNestedHtInValid)
    {
  if(this.selectedpart_submitter_contandcos.proposallastsaved>=2)
  {
    console.log(this.existingtab2details);
    if(this.existingtab2details.cd_cntr!=this.cntrdetforpost.cd_cntr)
    {
      this.confirmationService.confirm({
        message: this.messageDisplay,
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.tab2InValidationInit();
          this.saveandnextenable=true;
          this.flushdata();
            },
         reject: ()=>{


          console.log('reject')

          this.tab2InValidationInit();
          this.loadtab2detlsifexist();


         }
    });


    }
    else
    {
      this.tab2InValidationInit();
      this.saveThisAndEnableThirdForm();
      // this.tab2InValidationInit();
    }
  }


  else{
    this.tab2InValidationInit();
    this.saveThisAndEnableThirdForm();
    // this.tab2InValidationInit();
  }
}



}


checkAndSaveClose()
{


  console.log(this.cntrdetforpost);
  console.log(this.selectedpart_submitter_contandcos)
  this.conTareWgtValidation();
  this.conExtLenValidation();
  this.conExtWidValidation();
  this.conExtHgtValidation();
  this.partsPerContainerValidation();
  this.maxlayprshpunitValidation();
  this.cntrPerLayersValidation();
  this.suExtLenValidation();
  this.suExtWidValidation();
  //this.suExtHgtValidation();
  //this.suNestedFldedHtValidation();
  this.contNestedFldedHtValidation();
  
  console.log("cntrsupost",this.cntrdetforpost);
  console.log(!this.contarewgtInvalid  , !this.contextlenInvalid ,!this.contextwidInvalid ,
    !this.contexthgtInvalid ,  !this.suextlenInvalid ,!this.suextwidInvalid ,
    !this.suexthgtInvalid ,!this.suNestedHtInValid , !this.contNestedHtInValid)

  if(!this.contarewgtInvalid  &&  !this.contextlenInvalid && !this.contextwidInvalid && !this.partsprcntrInValid && 
    !this.maxlayerprshpInValid && !this.cntrperlaysInValid  && !this.contexthgtInvalid &&  !this.suextlenInvalid && !this.suextwidInvalid &&
    !this.contNestedHtInValid)
    {
  if(this.selectedpart_submitter_contandcos.proposallastsaved>=2)
  {
    console.log(this.existingtab2details);
    if(this.existingtab2details.cd_cntr!=this.cntrdetforpost.cd_cntr)
    {




      this.confirmationService.confirm({
        message: this.messageDisplay,
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.saveandcloseenable=true;
          this.tab2InValidationInit();
          this.flushdata();
          // this.tab2InValidationInit();
            },
         reject: ()=>{
          this.tab2InValidationInit();
          this.loadtab2detlsifexist();
          // this.tab2InValidationInit();

         }
    });
    }
    else
    {
      this.tab2InValidationInit();
      this.save_tab2();
      // this.tab2InValidationInit();
    }
  }


  else{
    this.tab2InValidationInit();
    this.save_tab2();
    // this.tab2InValidationInit();
  }
}




}


flushdata()
{
  if(this.selectedpart_submitter_contandcos.proposallastsaved>=4)
        {

          if(this.selectedpart_submitter_contandcos.proposallastsaved_ee>=4 && this.selectedpart_submitter_contandcos.imp_part_status.toLowerCase()!='rejected')
          {
            this.transactionService.loadtab4details_ee(this.selectedpart_submitter_contandcos.proposalid).subscribe(tab4_api_op_ee=>{


              console.log(tab4_api_op_ee);


              this.tab4_output_det=tab4_api_op_ee;


              if(this.tab4_output_det.f_partphoto!='' && this.tab4_output_det.f_partphoto!=null)
              {
                const prt=this.tab4_output_det.f_partphoto;
                this.transactionService.delete_image(prt).subscribe(out_prt=>{

                  console.log(out_prt);

                })

              }


          if(this.tab4_output_det.f_intcntrdsgnphoto!='' && this.tab4_output_det.f_intcntrdsgnphoto!=null)
          {
              const inter=this.tab4_output_det.f_intcntrdsgnphoto;
              this.transactionService.delete_image(inter).subscribe(out_inter=>{

                  console.log(out_inter);

                })

          }

          if(this.tab4_output_det.f_extcntrdsgnphoto!='' && this.tab4_output_det.f_extcntrdsgnphoto!=null)
          {
              const exter=this.tab4_output_det.f_extcntrdsgnphoto;
              this.transactionService.delete_image(exter).subscribe(out_exter=>{

                  console.log(out_exter);

                })

          }


          if(this.tab4_output_det.f_metelidplatephoto!='' && this.tab4_output_det.f_metelidplatephoto!=null)
          {
              const label=this.tab4_output_det.f_metelidplatephoto;
              this.transactionService.delete_image(label).subscribe(out_label=>{

                  console.log('label',out_label);

                })

          }


          if(this.tab4_output_det.f_shpunitphoto!='' && this.tab4_output_det.f_shpunitphoto!=null)
          {
              const ship=this.tab4_output_det.f_shpunitphoto;
              this.transactionService.delete_image(ship).subscribe(out_ship=>{

                  console.log(out_ship);

                })

          }





             this.flushTab4NonEE();



          })




          }


          else
          {


            this.flushTab4NonEE();

          }





          }


          else{


            this.deleteTab3Tab4data();

          }



}



flushTab4NonEE()
{
    if(this.selectedpart_submitter_contandcos.imp_part_status.toLowerCase()!='rejected')
  {
    this.transactionService.loadtab4details(this.selectedpart_submitter_contandcos.proposalid).subscribe(tab4_api_op=>{


      console.log(tab4_api_op);


      this.tab4_output_cntr=tab4_api_op;


      if(this.tab4_output_cntr.f_partphoto!='' && this.tab4_output_cntr.f_partphoto!=null)
      {
        const prt=this.tab4_output_cntr.f_partphoto;
        this.transactionService.delete_image(prt).subscribe(out_prt=>{

          console.log(out_prt);

        })

      }


  if(this.tab4_output_cntr.f_intcntrdsgnphoto!='' && this.tab4_output_cntr.f_intcntrdsgnphoto!=null)
  {
      const inter=this.tab4_output_cntr.f_intcntrdsgnphoto;
      this.transactionService.delete_image(inter).subscribe(out_inter=>{

          console.log(out_inter);

        })

  }

  if(this.tab4_output_cntr.f_extcntrdsgnphoto!='' && this.tab4_output_cntr.f_extcntrdsgnphoto!=null)
  {
      const exter=this.tab4_output_cntr.f_extcntrdsgnphoto;
      this.transactionService.delete_image(exter).subscribe(out_exter=>{

          console.log(out_exter);

        })

  }


  if(this.tab4_output_cntr.f_metelidplatephoto!='' && this.tab4_output_cntr.f_metelidplatephoto!=null)
  {
      const label=this.tab4_output_cntr.f_metelidplatephoto;
      this.transactionService.delete_image(label).subscribe(out_label=>{

          console.log('label',out_label);

        })

  }


  if(this.tab4_output_cntr.f_shpunitphoto!='' && this.tab4_output_cntr.f_shpunitphoto!=null)
  {
      const ship=this.tab4_output_cntr.f_shpunitphoto;
      this.transactionService.delete_image(ship).subscribe(out_ship=>{

          console.log(out_ship);

        })

  }
  if(this.selectedpart_submitter_contandcos.proposallastsaved_ee>=4)
  {
    this.deleteTab3Tab4DataEE();
  }
  else
  {
    this.deleteTab3Tab4data();
  }


  })
}
else
{
  if(this.selectedpart_submitter_contandcos.proposallastsaved_ee>=4)
  {
    this.deleteTab3Tab4DataEE();
  }
  else
  {
    this.deleteTab3Tab4data();
  }

}
}

deleteTab3Tab4DataEE()
{
  console.log(this.selectedpart_submitter_contandcos);

  this.transactionService.flushcompandimagedata_ee(this.selectedpart_submitter_contandcos.proposalid).subscribe(flush_out=>{


    console.log(flush_out);
    if(flush_out.toLowerCase()=='success')
    {


      this.packagingproposal.addtoast({severity:'success', summary: 'success', detail: 'Component and Image details deleted  successfully'});
      this.deleteTab3Tab4data();


      }


      else{
        this.saveandnextenable=false;
                this.saveandcloseenable=false;
      }



  })

}

deleteTab3Tab4data()
{
  console.log(this.selectedpart_submitter_contandcos);

  this.transactionService.flushcompandimagedata(this.selectedpart_submitter_contandcos.proposalid).subscribe(flush_out=>{


    console.log(flush_out);
    if(flush_out.toLowerCase()=='success')
    {
      // alert("change in data"+this.existChangeStatusTab2)
      if(this.existChangeStatusTab2 != null && this.existChangeStatusTab2.toLowerCase()=='unchanged')
      {
        const setChangestatusTab2:changeTrackingForImprovement={idproposal:0,chnge_timestamp:'',status:''}
        setChangestatusTab2.idproposal=this.selectedpart_submitter_contandcos.proposalid
        setChangestatusTab2.status='changed';
       const cur_date=new Date();
       const cur_date_transform=this.date.transform(cur_date, 'yyyy-MM-dd HH:mm:ss');
       setChangestatusTab2.chnge_timestamp=cur_date_transform
        console.log('update change status ',setChangestatusTab2)
        this.transactionService.setImprovementChangeStatus(setChangestatusTab2).subscribe(chang_stat=>{
          console.log('change status update',chang_stat);

          if(chang_stat.toLowerCase() == 'success')
          {
            sessionStorage.setItem('ProposalChangeStatus',setChangestatusTab2.status);
            this.existChangeStatusTab2='changed';
          }
        })
      }

      this.packagingproposal.addtoast({severity:'success', summary: 'success', detail: 'Component and Image details deleted  successfully'});
      this.selectedpart_submitter_contandcos.proposallastsaved=1;
      this.selectedpart_submitter_contandcos.proposallastsaved_ee=1;
      this.selectedpart_submitter_contandcos.currentview_ee=false;
      this.selectedpart_submitter_contandcos.is_ee_proposal=false;

      console.log(this.transactionService.getpartno_packpro(this.current_region_contandcos))
      this.store_primary_part_exist();
      this.transactionService.loadmenuwhenclickedfromdashboard('Yes');

    this.packagingproposal.ngOnInit();

      this.saveTAb2Flush();


      }


      else{
        this.saveandnextenable=false;
                this.saveandcloseenable=false;
      }



  })

}


saveTAb2Flush()
{


  this.cntrdetforpost.empty_trays_cntr_layrs=Number(this.cntrdetforpost.empty_trays_cntr_layrs);
        this.cntrdetforpost.part_per_shp_unit=(this.cntrdetforpost.cntr_per_layer* (this.cntrdetforpost.max_layrs_shp_unt-this.cntrdetforpost.empty_trays_cntr_layrs))* this.cntrdetforpost.parts_per_cntr;

        this.cntrdetforpost.max_cntr_per_shp_unt=this.cntrdetforpost.cntr_per_layer* (this.cntrdetforpost.max_layrs_shp_unt-this.cntrdetforpost.empty_trays_cntr_layrs);



  if(this.colorforcntrorrack.some(e=>e.color_desc==this.selectedcolor.color_desc))
  {
    this.cntrdetforpost.cntr_rck_colr=this.selectedcolor.color_desc;
  }


  if(this.pinpallettypeList.some(e=>e.pinpallettyp.toLowerCase() == this.selectedPinPalletType.pinpallettyp.toLowerCase() ))
  {
    this.cntrdetforpost.palletpintyp=this.selectedPinPalletType.pinpallettyp;
  }

  if(this.cntrdetforpost.shp_unt_ret_type.toLowerCase()=='expendable')
  {
    this.cntrdetforpost.pallettype=this.pallet_type

  }
  else  if(this.cntrdetforpost.shp_unt_ret_type.toLowerCase()=='returnable'){
    this.cntrdetforpost.pallettype='Returnable'
  }


  if(this.unitom == 'E')
      {
        // this.get_measure = "lbs/inch";
        this.edit_measure_contcos = 'lbs/inch';
        this.disp_lbs = true;
      }
      else{
        // this.get_measure = "kg/cm";
        this.edit_measure_contcos = 'kg/cm';
        this.disp_kg = true;
      }

  // if(this.edit_measure_contcos=="kg/cm")
  // {
  //   if(this.cntrdetforpost.cntr_nsted_fld_height!=null)
  // {
  //   this.cntrdetforpost.cntr_nsted_fld_height=Math.round((0.393701*this.cntrdetforpost.cntr_nsted_fld_height)*1000)/1000;

  // }
  // }

      var shpUntHgtOnlyCntr=0;
      shpUntHgtOnlyCntr=this.cntrdetforpost.cntr_height*(this.cntrdetforpost.max_layrs_shp_unt-this.cntrdetforpost.empty_trays_cntr_layrs);
        console.log("Calculated Shipping unit exterior height before post last saved tab 2",shpUntHgtOnlyCntr)
        this.cntrdetforpost.shp_unt_ext_height=this.transactionService.roundValueToNDecimal(shpUntHgtOnlyCntr,3);
  console.log("cntrsupost-saveTAb2Flush",this.cntrdetforpost);

  if(this.cntrdetforpost.cntr_ret_type.toLowerCase() == "returnable")
  {
    this.cntrdetforpost.woodispmcmplnt = "N/A";
    this.cntrdetforpost.offstmpmartprsnt = "N/A";
  }
  
  this.transactionService.savetab2details(this.cntrdetforpost).subscribe(result=>
    {
      let msgs:any[];
      if(result=='Success')
      {

        this.packagingproposal.addtoast({severity:'success', summary: 'success', detail: 'COS,Container and Shipping Unit details saved successfully'});
        this.selectedpart_submitter_contandcos.proposallastsaved=2;
        this.selectedpart_submitter_contandcos.proposallastsaved_ee=1;
        this.selectedpart_submitter_contandcos.currentview_ee=false;
        this.selectedpart_submitter_contandcos.is_ee_proposal=false;

        this.store_primary_part_exist();
        this.tab2InValidationInit();
        if(this.saveandnextenable==true)
        {
          this.saveandnextenable=false;
          console.log('output printing')
          console.log(this.transactionService.getpartno_packpro(this.current_region_contandcos))
          this.transactionService.loadmenuwhenclickedfromdashboard('Yes');

        this.packagingproposal.ngOnInit();
        let reg_supp_cntr_url:string;
        reg_supp_cntr_url=this.transactionService.getregionurlvalue(this.current_region_contandcos);
        reg_supp_cntr_url+='packagingprocessdashboard/improvementproposalcreation/improvementpackproposaltabthree';

        this.router.navigate([reg_supp_cntr_url]);

        }


       else  if(this.saveandcloseenable==true)
        {


          this.saveandcloseenable=false;

          // this.store_primary_part_exist();
            console.log(this.selectedpart_submitter_contandcos);
            this.region=this.transactionService.getregion();
            this.name=this.transactionService.getregionNameInSmallLetters();



              this.router.navigate(['/'+this.name+'/packagingprocessdashboard/searchexistingproposal'])



        }


      }


      else
        {

            this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'COS,Container and Shipping Unit details is not saved! try again'});

             this.saveandnextenable=false;
            this.saveandcloseenable=false;
            this.tab2InValidationInit();

        }
    })
}

exteriorHeightConfirmationPopUp()
{
 this.enableExteriorHeightPopUp=true;
}

okUpdateExteriorHeight()
{
  this.enableExteriorHeightPopUp=false;

  console.log(this.isSaveAndNext);
  
  var shpUntHgtOnlyCntr=0;
  shpUntHgtOnlyCntr=this.cntrdetforpost.cntr_height*(this.cntrdetforpost.max_layrs_shp_unt-this.cntrdetforpost.empty_trays_cntr_layrs);
        
  this.tab3Data=[];
  this.transactionService.getaddedcomponentdetailsfortab3(this.selectedpart_submitter_contandcos.proposalid).subscribe(gridout=>
    {
      this.tab3Data=JSON.parse(JSON.stringify(gridout))
      if(this.tab3Data != null && this.tab3Data.length != 0)
      {
        for(var i=0; i<this.tab3Data.length; i++ )
        {
          if(this.tab3Data[i].typ_no == 2 || this.tab3Data[i].typ_no == 3 || this.tab3Data[i].typ_no == 4)
          {
            if(this.tab3Data[i].compselctnfor.toLowerCase() == "shipping unit")
            {
              shpUntHgtOnlyCntr+=this.tab3Data[i].comp_h*this.tab3Data[i].qt_per_min_su;
            }
            /*else
            {
              shpUntHgtOnlyCntr+=this.tab3Data[i].comp_h*this.tab3Data[i].qt_per_min_su*(this.cntrdetforpost.max_layrs_shp_unt-this.cntrdetforpost.empty_trays_cntr_layrs);
            }*/
          }
        }

        console.log("Calculated Shipping unit exterior height before post with components data",shpUntHgtOnlyCntr)
        this.cntrdetforpost.shp_unt_ext_height=this.transactionService.roundValueToNDecimal(shpUntHgtOnlyCntr,3);
        if(this.isSaveAndNext)
        {
        this.postDataToTab2MoveToNextTab();
        }
        else
        {
          this.postDataToTAb2SaveAndClose();
        }

      }
      else
      {
        console.log("Calculated Shipping unit exterior height before post without components data",shpUntHgtOnlyCntr)
        this.cntrdetforpost.shp_unt_ext_height=this.transactionService.roundValueToNDecimal(shpUntHgtOnlyCntr,3);
        if(this.isSaveAndNext)
        {
        this.postDataToTab2MoveToNextTab();
        }
        else
        {
          this.postDataToTAb2SaveAndClose();
        }
      }
    },
    (error) => {                              //Error callback
      console.error('Error in Loading components API call - getaddedcomponentdetailsfortab3',error)
      console.log("Calculated Shipping unit exterior height before post error call back",shpUntHgtOnlyCntr);
      this.cntrdetforpost.shp_unt_ext_height=this.transactionService.roundValueToNDecimal(shpUntHgtOnlyCntr,3);
      if(this.isSaveAndNext)
        {
        this.postDataToTab2MoveToNextTab();
        }
        else
        {
          this.postDataToTAb2SaveAndClose();
        }
    }
    )
}
noUpdateExteriorHeight()
{
  this.enableExteriorHeightPopUp=false;
  console.log(this.savedSuExteriorHeight)
  this.cntrdetforpost.shp_unt_ext_height=this.savedSuExteriorHeight;
  if(this.isSaveAndNext)
  {
     this.postDataToTab2MoveToNextTab();
  }
  else
  {
    this.postDataToTAb2SaveAndClose();
  }
}


      saveThisAndEnableThirdForm()
      {

        this.enableExteriorHeightPopUp=false;
        this.isSaveAndNext=true;

        this.cntrdetforpost.empty_trays_cntr_layrs=Number(this.cntrdetforpost.empty_trays_cntr_layrs);
        this.cntrdetforpost.part_per_shp_unit=(this.cntrdetforpost.cntr_per_layer* (this.cntrdetforpost.max_layrs_shp_unt-this.cntrdetforpost.empty_trays_cntr_layrs))* this.cntrdetforpost.parts_per_cntr;
        this.cntrdetforpost.max_cntr_per_shp_unt=this.cntrdetforpost.cntr_per_layer* (this.cntrdetforpost.max_layrs_shp_unt-this.cntrdetforpost.empty_trays_cntr_layrs);

        if(this.colorforcntrorrack.some(e=>e.color_desc==this.selectedcolor.color_desc))
        {
          this.cntrdetforpost.cntr_rck_colr=this.selectedcolor.color_desc;
        }



        if(this.pinpallettypeList.some(e=>e.pinpallettyp.toLowerCase() == this.selectedPinPalletType.pinpallettyp.toLowerCase() ))
        {
          this.cntrdetforpost.palletpintyp=this.selectedPinPalletType.pinpallettyp;
        }

        if(this.cntrdetforpost.shp_unt_ret_type.toLowerCase()=='expendable')
        {
          this.cntrdetforpost.pallettype=this.pallet_type

        }
        else  if(this.cntrdetforpost.shp_unt_ret_type.toLowerCase()=='returnable'){
          this.cntrdetforpost.pallettype='Returnable'
        }

        if(this.unitom == 'E')
      {
        // this.get_measure = "lbs/inch";
        this.edit_measure_contcos = 'lbs/inch';
        this.disp_lbs = true;
      }
      else{
        // this.get_measure = "kg/cm";
        this.edit_measure_contcos = 'kg/cm';
        this.disp_kg = true;
      }

        // if(this.edit_measure_contcos=="kg/cm")
        // {
        //   if(this.cntrdetforpost.cntr_nsted_fld_height!=null)
        // {
        //   this.cntrdetforpost.cntr_nsted_fld_height=Math.round((0.393701*this.cntrdetforpost.cntr_nsted_fld_height)*1000)/1000;

        // }
        // }

        var shpUntHgtOnlyCntr=0;
        shpUntHgtOnlyCntr=this.cntrdetforpost.cntr_height*(this.cntrdetforpost.max_layrs_shp_unt-this.cntrdetforpost.empty_trays_cntr_layrs);
        
        
        if(this.selectedpart_submitter_contandcos.proposallastsaved >= 2) 
        {
        if(this.originalTab2Details.max_layrs_shp_unt != this.cntrdetforpost.max_layrs_shp_unt || this.originalTab2Details.empty_trays_cntr_layrs != this.cntrdetforpost.empty_trays_cntr_layrs || this.originalTab2Details.cntr_height != this.cntrdetforpost.cntr_height)
        {
          this.exteriorHeightConfirmationPopUp()
        }
        else
        {
          this.postDataToTab2MoveToNextTab();
        }
      }
        else 
        {
          console.log("Calculated Shipping unit exterior height before post last saved tab 2",shpUntHgtOnlyCntr)
          this.cntrdetforpost.shp_unt_ext_height=this.transactionService.roundValueToNDecimal(shpUntHgtOnlyCntr,3);
          this.postDataToTab2MoveToNextTab();
        }
        
        
      }

      postDataToTab2MoveToNextTab()
      {

        this.conTareWgtValidation();
        this.conExtLenValidation();
        this.conExtWidValidation();
        this.conExtHgtValidation();
        this.partsPerContainerValidation();
        this.maxlayprshpunitValidation();
        this.cntrPerLayersValidation();
        this.suExtLenValidation();
        this.suExtWidValidation();
        //this.suExtHgtValidation();
        //this.suNestedFldedHtValidation();
        this.contNestedFldedHtValidation();
        
        console.log("cntrsupost",this.cntrdetforpost);
		
		if(this.cntrdetforpost.cntr_ret_type.toLowerCase() == "returnable")
        {
          this.cntrdetforpost.woodispmcmplnt = "N/A";
          this.cntrdetforpost.offstmpmartprsnt = "N/A";
        }

        if(!this.contarewgtInvalid  &&  !this.contextlenInvalid && !this.contextwidInvalid && !this.contexthgtInvalid && !this.partsprcntrInValid &&
          !this.maxlayerprshpInValid && !this.cntrperlaysInValid  &&  !this.suextlenInvalid && !this.suextwidInvalid &&
          !this.contNestedHtInValid)
          {

        this.transactionService.savetab2details(this.cntrdetforpost).subscribe(result=>
          {
            let msgs:any[];
            if(result=='Success')
            {

              this.packagingproposal.addtoast({severity:'success', summary: 'success', detail: 'COS,Container and Shipping Unit details saved successfully'});
                const check=false;
                this.tab2InValidationInit();

                console.log('comdet',this.originalTab2Details,this.changedTab2Details)
                if(JSON.stringify(this.originalTab2Details) !== JSON.stringify(this.changedTab2Details))
                {
                  // update change status

                  // alert("change in data"+this.existChangeStatusTab2)
                  if(this.existChangeStatusTab2 != null && this.existChangeStatusTab2.toLowerCase()=='unchanged')
                  {
                    const setChangestatusTab2:changeTrackingForImprovement={idproposal:0,chnge_timestamp:'',status:''}
                    setChangestatusTab2.idproposal=this.selectedpart_submitter_contandcos.proposalid
                    setChangestatusTab2.status='changed';
                  const cur_date=new Date();
                  const cur_date_transform=this.date.transform(cur_date, 'yyyy-MM-dd HH:mm:ss');
                  setChangestatusTab2.chnge_timestamp=cur_date_transform
                    console.log('update change status ',setChangestatusTab2)
                    this.transactionService.setImprovementChangeStatus(setChangestatusTab2).subscribe(chang_stat=>{
                      console.log('change status update',chang_stat);

                      if(chang_stat.toLowerCase() == 'success')
                      {
                        sessionStorage.setItem('ProposalChangeStatus',setChangestatusTab2.status);
                        this.existChangeStatusTab2='changed';

                        if(check==false)
                        {
                          this.routeToTab3()
                        }
                      }
                    })
                  }

                  else
                  {
                    if(check==false)
                    {
                      this.routeToTab3()
                    }

                  }
                }

                else
                {
                  if(check==false)
                  {
                    this.routeToTab3()
                  }
                }


            }

            else
            {
                console.log(result,'Success');
                this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'COS,Container and Shipping Unit details is not saved! try again'});
                this.tab2InValidationInit();
            }
          }
        );

          }
      }



      routeToTab3()
      {

              this.region=this.transactionService.getregion();
            this.name=this.transactionService.getregionNameInSmallLetters();

            this.tab3='/'+this.name+'/packagingprocessdashboard/improvementproposalcreation/improvementpackproposaltabthree';
            this.tab3menuitem={label: 'Component & Material', routerLink: this.tab3, icon: 'fas fa-person-booth'};
            const currentab=2;
            console.log('insave of tab2 ',this.packagingproposal.savedtab,this.selectedpart_submitter_contandcos.proposallastsaved)

            // if(currentab>this.packagingproposal.savedtab)
            // {
              // this.packagingproposal.savedtab=currentab;
              if(currentab>this.selectedpart_submitter_contandcos.proposallastsaved)
              {

              this.selectedpart_submitter_contandcos.proposallastsaved=2;
              this.store_primary_part_exist();
              console.log(this.selectedpart_submitter_contandcos);
              }


              // this.transactionService.storelastsavedtab(this.packagingproposal.savedtab,this.region);
              // this.transactionService.storepage(this.region,'packagingprocess/proposalcreation/packproposaltabtwo');
            // }
            console.log('savedtabfortesting',this.partsandattri.savedtabfortesting);
            for(const i of this.packagingproposal.tabpackagingproposalmenuitems.values())
            {
                console.log(i);
                if(i.label!='Component & Material')
                {
                    this.flag=true;
                }
                else{
                    this.flag=false;
                    break;
                }
            }

            if(this.flag)
            {
                this.packagingproposal.tabpackagingproposalmenuitems.push(this.tab3menuitem);
            }

            this.packagingproposal.setActiveItemDynamically(this.tab3,this.tab3menuitem);


      }

      save_tab2()
      {
        this.enableExteriorHeightPopUp=false;
        this.isSaveAndNext=false;

        this.cntrdetforpost.empty_trays_cntr_layrs=Number(this.cntrdetforpost.empty_trays_cntr_layrs);
        this.cntrdetforpost.part_per_shp_unit=(this.cntrdetforpost.cntr_per_layer* (this.cntrdetforpost.max_layrs_shp_unt-this.cntrdetforpost.empty_trays_cntr_layrs))* this.cntrdetforpost.parts_per_cntr;
        this.cntrdetforpost.max_cntr_per_shp_unt=this.cntrdetforpost.cntr_per_layer* (this.cntrdetforpost.max_layrs_shp_unt-this.cntrdetforpost.empty_trays_cntr_layrs);
        
        if(this.cntrdetforpost.cntr_material == "Corrugated Cardboard")
        {
          this.cntrdetforpost.cntr_rck_colr=this.selectedcolor.color_desc;
        }
        if(this.colorforcntrorrack.some(e=>e.color_desc==this.selectedcolor.color_desc))
        {
          this.cntrdetforpost.cntr_rck_colr=this.selectedcolor.color_desc;
        }

        if(this.pinpallettypeList.some(e=>e.pinpallettyp.toLowerCase() == this.selectedPinPalletType.pinpallettyp.toLowerCase() ))
        {
          this.cntrdetforpost.palletpintyp=this.selectedPinPalletType.pinpallettyp;
        }
        if(this.cntrdetforpost.shp_unt_ret_type.toLowerCase()=='expendable')
        {
          this.cntrdetforpost.pallettype=this.pallet_type

        }
        else  if(this.cntrdetforpost.shp_unt_ret_type.toLowerCase()=='returnable'){
          this.cntrdetforpost.pallettype='Returnable'
        }

        if(this.unitom == 'E')
      {
        // this.get_measure = "lbs/inch";
        this.edit_measure_contcos = 'lbs/inch';
        this.disp_lbs = true;
      }
      else{
        // this.get_measure = "kg/cm";
        this.edit_measure_contcos = 'kg/cm';
        this.disp_kg = true;
      }

        // if(this.edit_measure_contcos=="kg/cm")
        // {
        //   if(this.cntrdetforpost.cntr_nsted_fld_height!=null)
        // {
        //   this.cntrdetforpost.cntr_nsted_fld_height=Math.round((0.393701*this.cntrdetforpost.cntr_nsted_fld_height)*1000)/1000;

        // }
        // }
        var shpUntHgtOnlyCntr=0;
        shpUntHgtOnlyCntr=this.cntrdetforpost.cntr_height*(this.cntrdetforpost.max_layrs_shp_unt-this.cntrdetforpost.empty_trays_cntr_layrs);
        

        if((this.selectedpart_submitter_contandcos.proposallastsaved >= 2) && (this.originalTab2Details.max_layrs_shp_unt != this.cntrdetforpost.max_layrs_shp_unt || this.originalTab2Details.empty_trays_cntr_layrs != this.cntrdetforpost.empty_trays_cntr_layrs || this.originalTab2Details.cntr_height != this.cntrdetforpost.cntr_height))
        {
          this.exteriorHeightConfirmationPopUp()
        }
        else
        {
          console.log("Calculated Shipping unit exterior height before post last saved tab 2",shpUntHgtOnlyCntr)
          this.cntrdetforpost.shp_unt_ext_height=this.transactionService.roundValueToNDecimal(shpUntHgtOnlyCntr,3);
          this.postDataToTAb2SaveAndClose();
        }
        
      }

      postDataToTAb2SaveAndClose()
      {
        this.conTareWgtValidation();
        this.conExtLenValidation();
        this.conExtWidValidation();
        this.conExtHgtValidation();
        this.partsPerContainerValidation();
        this.maxlayprshpunitValidation();
        this.cntrPerLayersValidation();
        this.suExtLenValidation();
        this.suExtWidValidation();
        //this.suExtHgtValidation();
        //this.suNestedFldedHtValidation();
        this.contNestedFldedHtValidation();
        console.log("cntrsupost",this.cntrdetforpost);
		
		if(this.cntrdetforpost.cntr_ret_type.toLowerCase() == "returnable")
        {
          this.cntrdetforpost.woodispmcmplnt = "N/A";
          this.cntrdetforpost.offstmpmartprsnt = "N/A";
        }

        if(!this.contarewgtInvalid  &&  !this.contextlenInvalid && !this.contextwidInvalid && !this.contexthgtInvalid && !this.partsprcntrInValid &&
          !this.maxlayerprshpInValid && !this.cntrperlaysInValid  &&  !this.suextlenInvalid && !this.suextwidInvalid &&
          !this.contNestedHtInValid)
          {


        this.transactionService.savetab2details(this.cntrdetforpost).subscribe(result=>
          {
            let msgs:any[];
            if(result=='Success')
            {
              this.packagingprocess.addtoastinpackagingprocess({severity:'success', summary: 'success', detail: 'COS,Container and Shipping Unit details saved successfully'});
              const check=false;
              this.tab2InValidationInit();

              if(JSON.stringify(this.originalTab2Details) !== JSON.stringify(this.changedTab2Details))
              {
                // update change status
                // alert("change in data"+this.existChangeStatusTab2)
                if(this.existChangeStatusTab2 != null && this.existChangeStatusTab2.toLowerCase()=='unchanged')
                {
                  const setChangestatusTab2:changeTrackingForImprovement={idproposal:0,chnge_timestamp:'',status:''}
                  setChangestatusTab2.idproposal=this.selectedpart_submitter_contandcos.proposalid
                  setChangestatusTab2.status='changed';
                const cur_date=new Date();
                const cur_date_transform=this.date.transform(cur_date, 'yyyy-MM-dd HH:mm:ss');
                setChangestatusTab2.chnge_timestamp=cur_date_transform
                  console.log('update change status ',setChangestatusTab2)
                  this.transactionService.setImprovementChangeStatus(setChangestatusTab2).subscribe(chang_stat=>{
                    console.log('change status update',chang_stat);

                    if(chang_stat.toLowerCase() == 'success')
                    {
                      sessionStorage.setItem('ProposalChangeStatus',setChangestatusTab2.status);
                      this.existChangeStatusTab2='changed';

                      if(check==false)
                      {
                        this.routeToStartPageFromTab2()
                      }
                    }
                  })
                }

                else
                {
                  if(check==false)
                  {
                    this.routeToStartPageFromTab2()
                  }

                }

              }

              else
              {
                if(check==false)
                {
                  this.routeToStartPageFromTab2()
                }
              }
            }

            else
            {
                console.log(result,'Success');
                this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'COS,Container and Shipping Unit details is not saved! try again'});
                this.tab2InValidationInit();
            }
          }
        );
        }
      }


      routeToStartPageFromTab2()
      {
          this.region=this.transactionService.getregion();
          this.name=this.transactionService.getregionNameInSmallLetters();

          const currentab=2;
          console.log('insave of tab2 ',this.packagingproposal.savedtab,this.selectedpart_submitter_contandcos.proposallastsaved)


            if(currentab>this.selectedpart_submitter_contandcos.proposallastsaved)
            {

            this.selectedpart_submitter_contandcos.proposallastsaved=2;
            this.store_primary_part_exist();
            console.log(this.selectedpart_submitter_contandcos);
            }




              this.router.navigate(['/'+this.name+'/packagingprocessdashboard/searchexistingproposal'])

      }


      disable_save_func()
      {      //  // <!-- New changes are here, just added --  || !this.cntrdetforpost.cntr_per_layer || !this.cntrdetforpost.max_layrs_shp_unt ||  -->
        if( !this.iscntrselected || !this.cntrdetforpost.used_carry_ovr_pck  || !this.cntrdetforpost.parts_per_cntr || !this.cntrdetforpost.cntr_per_layer || !this.cntrdetforpost.max_layrs_shp_unt ||
            !(this.stampingprop==false || ((this.stampingprop==true) && ((this.cntrdetforpost.blanksperstack>0) && (this.cntrdetforpost.stacksperpallet>0) && (this.selectedPinPalletType.pinpallettyp!=='')))) ||
            !(this.containerdetails.exp_refundable=='Returnable' || (this.containerdetails.exp_refundable=='Expendable' && this.pallet_type!==''))  ||
            !(
                !((this.cntrdetforpost.cntr_base != null && this.cntrdetforpost.cntr_base.indexOf('IMC')>-1 && this.containerdetails.exp_refundable=='Expendable' && this.containerdetails.cntr_grp_desc != null && this.containerdetails.cntr_grp_desc.toLowerCase().indexOf('imc')>-1)) ||

                (
                  (this.cntrdetforpost.cntr_base != null && this.cntrdetforpost.cntr_base.indexOf('IMC')>-1) && (this.containerdetails.exp_refundable=='Expendable') && (this.containerdetails.cntr_grp_desc != null && this.containerdetails.cntr_grp_desc.toLowerCase().indexOf('imc')>-1) 
                   //Removed && this.cntrdetforpost.iscartonimcspec && this.cntrdetforpost.imcwtdisc  && this.cntrdetforpost.cartonburststrnth>0
                )
             ) ||
            !(
                !((this.containerdetails.exp_refundable=='Expendable' && (this.selected_cntr_mat.cntr_matr_desc!=null && this.selected_cntr_mat.cntr_matr_desc.toLowerCase().includes('cardboard')))) ||

                 (
                    (this.containerdetails.exp_refundable=='Expendable') && (this.selected_cntr_mat.cntr_matr_desc!=null && this.selected_cntr_mat.cntr_matr_desc.toLowerCase().includes('cardboard'))  &&
                    ((this.cntrdetforpost.bct != null && this.cntrdetforpost.bct>0) || (this.cntrdetforpost.ect != null && this.cntrdetforpost.ect>0)) //&& this.cntrdetforpost.imcwtdisc && this.cntrdetforpost.iscartonimcspec && this.cntrdetforpost.cartonburststrnth>0
                  )
            )//this.containerdetails.exp_refundable=='Expendable' && (
            ||!(this.cntrdetforpost.cntr_material!=null && this.cntrdetforpost.cntr_material!="")
            || !(this.cntrdetforpost.cntr_tar_weight) || !(this.cntrdetforpost.cntr_ext_len) || !(this.cntrdetforpost.cntr_width) || !(this.cntrdetforpost.cntr_height) || !(this.cntrdetforpost.shp_unit_ext_len) || !(this.cntrdetforpost.shp_unt_ext_width) || !(this.cntrdetforpost.cntr_nsted_fld_height)
          )
        {
          return true;
        }
        else
        {
          return false;
        }
      }

}
