/* tslint:disable */
import { Injectable } from '@angular/core';


export enum LogLevel {
  All = 0,
  Debug = 1,
  Info = 2,
  Warn = 3,
  Error = 4,
  Fatal = 5,
  Off = 6
}

/*
export class LogLevel {
  None = 0;
  Info = 1;
  Verbose = 2;
  Warn = 3;
  Error = 4;
}*/


export class LogEntry {
  // Public Properties
  entryDate: Date = new Date();
  message = '';
  level: LogLevel = LogLevel.Debug;
  extraInfo: any[] = [];
  logWithDate = true;

  buildLogString(): string {
      let ret = '';

      if (this.logWithDate) {
          ret = new Date() + ' - ';
      }

      ret += 'Type: ' + LogLevel[this.level];
      ret += ' - Message: ' + this.message;
      if (this.extraInfo.length) {
          ret += ' - Extra Info: ' + this.formatParams(this.extraInfo);
      }

      return ret;
  }

  private formatParams(params: any[]): string {
      let ret: string = params.join(',');

      // Is there at least one object in the array?
      if (params.some(p => typeof p == 'object')) {
          ret = '';

          // Build comma-delimited string
          for (const item of params) {
              ret += JSON.stringify(item) + ',';
          }
      }

      return ret;
  }
}

@Injectable({
  providedIn: 'root'
})
export class LoggerService {


  level: LogLevel = LogLevel.All;

logWithDate = true;

  constructor() { }


  debug(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Debug, optionalParams);
}

info(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Info, optionalParams);
}

warn(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Warn, optionalParams);
}

error(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Error, optionalParams);
}

fatal(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Fatal, optionalParams);
}

log(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.All, optionalParams,);
}


private writeToLog(msg: string, level: LogLevel, params: any[]) {
  if (this.shouldLog(level)) {
      const entry: LogEntry = new LogEntry();
      entry.message = msg;
      entry.level = level;
      entry.extraInfo = params;
      entry.logWithDate = this.logWithDate;
       const built_string=entry.buildLogString()
      switch (level) {
        case LogLevel.Debug:
          return console.log(built_string);
        case LogLevel.Info:
          return console.info('%c' + built_string, 'color: #6495ED');
        case LogLevel.Warn:
          return console.warn('%c' + built_string, 'color: #FF8C00');
        case LogLevel.Error:
          return console.error('%c' + built_string, 'color: #DC143C');
        default:
          console.debug(built_string);
      }


      // console.log(entry.buildLogString());
  }
}


private shouldLog(level: LogLevel): boolean {
  let ret = false;
  if ((level >= this.level && level !== LogLevel.Off) || this.level === LogLevel.All) {
      ret = true;
  }
  return ret;
}


/*
logLevel: LogLevel=new LogLevel();

info(msg: string): void {
    this.logWith(this.logLevel.Info, msg);
  }
warn(msg: string): void {
     console.log(msg)
    this.logWith(this.logLevel.Warn, msg);
  }
error(msg: string): void {
    this.logWith(this.logLevel.Error, msg);
  }

private logWith(level: any, msg: string): void {
  if (level <= this.logLevel.Error) {
    switch (level) {
      case this.logLevel.None:
        return console.log(msg);
      case this.logLevel.Info:
        return console.info('%c' + msg, 'color: #6495ED');
      case this.logLevel.Warn:
        return console.warn('%c' + msg, 'color: #FF8C00');
      case this.logLevel.Error:
        return console.error('%c' + msg, 'color: #DC143C');
      default:
        console.debug(msg);
    }
  }
}
*/



}
